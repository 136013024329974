import styled from "styled-components";
import {
  DoxleFont,
  DoxleThemeColor,
} from "../../../../DoxleGeneralStore/useDoxleThemeStore";
import { editRgbaAlpha } from "../../../../Utilities/FunctionUtilities";

export const MainPageContainer = styled.div`
  display: flex;
  width: calc(100% - 100px);
  height: 100%;
  justify-content: center;
  padding: 0px 50px;
  align-items: center;
  @media (max-width: 600px){
    flex-direction: column;
    overflow-y: scroll;
    overflow-x: hidden;
  }
`;

export const StyledBannerContainer = styled.div`
width: 100%;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
 @media (max-width: 600px){
    width: 50%;
  }
`

export const MainPageLeft = styled.div`
  width: calc(40%);
  padding-right: 5%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  align-items: center;
  @media (max-width: 600px){
    width: 100%;
    height: auto;
  }
`;

export const MainPageRight = styled.div`
  width: calc(55%);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
   @media (max-width: 600px){
    width: 100%;
    height: auto;
  }
`;

export const ImageText = styled.div<{
  $themeColor: DoxleThemeColor;
}>`
  color: ${(p) => p.$themeColor.primaryFontColor};
  font-size: 1.8rem;
  font-family: "Indie Flower";
`;

export const StyledTitle = styled.h1<{
  $themeColor: DoxleThemeColor;
}>`
  font-size: 5.4rem;
  margin: 0;
  color: ${(p) => p.$themeColor.primaryFontColor};
`;

export const StyledPurple = styled.span<{
  $themeColor: DoxleThemeColor;
}>`
  color: ${(p) => p.$themeColor.doxleColor};
`;

export const StyledDescription = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  color: ${(p) =>
    editRgbaAlpha({ rgbaColor: p.$themeColor.primaryFontColor, alpha: "0.8" })};
  font-size: 1.6rem;
  width: 75%;
  font-family: ${(p) => p.$doxleFont.primaryFont};
  max-width: 55rem;
  @media (max-width: 600px){
    width: 100%;
  }
`;

export const ButtonArea = styled.div`
  margin-top: 5%;
  text-align: center;
`;

export const JoinBtn = styled.button<{
  $themeColor: DoxleThemeColor;
}>`
  background-color: ${(p) => p.$themeColor.primaryFontColor};
  color: ${(p) => p.$themeColor.primaryBackgroundColor};
  width: 8rem;
  height: 3.9rem;
  border-radius: 3px;
  border: none;
  cursor: pointer;
  font-size: 1.4rem;

  &:hover {
    transform: scale(1.03) translateY(-0.3rem);
    opacity: 0.9;
    transition: 0.2s all;
  }
  &:active {
    transform: scale(0.98) translateY(0.2rem);
    opacity: 1;
    transition: 0.2s all;
  }
`;

export const AboutBtn = styled.button<{
  $themeColor: DoxleThemeColor;
}>`
  color: ${(p) => p.$themeColor.primaryFontColor};
  background-color: ${(p) => p.$themeColor.primaryContainerColor};
  width: 8rem;
  height: 3.9rem;
  border: 1px solid ${(p) => p.$themeColor.borderColor};
  border-radius: 3px;
  cursor: pointer;
  margin: 10px;
  font-size: 1.4rem;

  &:hover {
    transform: scale(1.03) translateY(-0.3rem);
    opacity: 0.9;
    transition: 0.2s all;
  }
  &:active {
    transform: scale(0.98) translateY(0.2rem);
    opacity: 1;
    transition: 0.2s all;
  }
`;
