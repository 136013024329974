import { shallow } from "zustand/shallow";
import React, { useCallback, useEffect, useState } from "react";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import { StyledMailSideScreenContainer } from "./Components/StyledMailSideScreen";
import { TopMenu } from "./Components/TopMenu";
import { MailCommentScreen } from "./Components/MailComment/MailCommentScreen";
import { MailComments } from "../Models/mail";
import { useMailSideScreenStore } from "./MailSideScreenStore/UseMailSideScreenStore";
import { MailReplyScreen } from "./Components/MailReply/MailReplyScreen";
import NewMail from "../Content/SendNewMail/NewMail";

type Props = {};

const MailFullScreenMode = ({}: Props) => {
  const { currentMail, currentDisplay } = useMailSideScreenStore(
    (state) => ({
      currentMail: state.currentMail,
      resetStore: state.resetStore,
      currentDisplay: state.currentDisplay,
    }),
    shallow
  );
  const { doxleThemeColor, doxleFont, doxleTheme } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
      doxleTheme: state.currentTheme,
    }),
    shallow
  );

  return (
    <StyledMailSideScreenContainer
      $doxleFont={doxleFont}
      $themeColor={doxleThemeColor}
      $theme={doxleTheme}
    >
      <TopMenu />
      {currentDisplay === "Mail" && currentMail && (
        <MailReplyScreen currentMail={currentMail} />
      )}
      {currentDisplay === "Comments" && <MailCommentScreen />}
      {/* {currentDisplay === "New" && <NewMail />} */}
    </StyledMailSideScreenContainer>
  );
};

export default MailFullScreenMode;
