import React, { useCallback, useEffect, useState } from "react";
import { useDoxleCurrentContextStore } from "../../DoxleGeneralStore/useDoxleCurrentContext";
import { useShallow } from "zustand/react/shallow";
import { useEditDocketSideScreenStore } from "../../CoreContent/EditDocketSideScreen/EditDocketSideScreenStore/useEditDocketSideScreenStore";
import { useFileStore } from "../Store/useFileStore";
import useDoxleNotificationStore from "../../DoxleGeneralStore/useDoxleNotificationStore";
import FilesQueryAPI, {
  IAddFileMutateProps,
} from "../QueryHooks/FileStorageQueryAPI";
import { DoxleFolder } from "../../Models/files";
import { DropEvent, FileRejection, useDropzone } from "react-dropzone";
import { DOXLE_ACCEPTED_MIME } from "../../Utilities/MimeFileType";

type Props = {
  overrideAnchorEl?: HTMLDivElement | HTMLButtonElement | null;
  overrideHandleClose?: React.Dispatch<
    React.SetStateAction<HTMLDivElement | HTMLButtonElement | null>
  >;
};

const useFilePopover = ({ overrideAnchorEl, overrideHandleClose }: Props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(
    overrideAnchorEl ?? null
  );
  const { company, currentProject, addCurrentErrorFiles } =
    useDoxleCurrentContextStore(
      useShallow((state) => ({
        company: state.currentCompany,
        currentProject: state.currentProject,
        addCurrentErrorFiles: state.addCurrentErrorFiles,
      }))
    );

  const { edittedDocket } = useEditDocketSideScreenStore(
    useShallow((state) => ({ edittedDocket: state.edittedDocket }))
  );
  const {
    currentFolder,
    setCurrentFolder,
    filterRootFolderQuery,
    filterRootFileQuery,
    currentLevel,
  } = useFileStore(
    useShallow((state) => ({
      currentFolder: state.currentFolder,
      setCurrentFolder: state.setCurrentFolder,
      filterRootFolderQuery: state.filterRootFolderQuery,
      filterRootFileQuery: state.filterRootFileQuery,
      currentLevel: state.currentLevel,
    }))
  );

  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification
  );

  const addFileQuery = FilesQueryAPI.useAddFileQuery({
    company,
    showNotification,
    filter: currentFolder
      ? {
          folderId: currentFolder.folderId,
        }
      : filterRootFileQuery,
  });
  useEffect(() => {
    if (overrideAnchorEl) setAnchorEl(overrideAnchorEl);
  }, [overrideAnchorEl]);
  const onAddFolderSuccessCallback = (newFolder?: DoxleFolder) => {
    if (newFolder) {
      sessionStorage.setItem("currentFolderId", newFolder.folderId);
      setCurrentFolder(newFolder);
    }
  };
  const addFolderQuery = FilesQueryAPI.useAddFolderQuery({
    company,
    showNotification,
    onAddFolderSuccessCallback,
    filter: filterRootFolderQuery,
  });

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    if (overrideHandleClose) overrideHandleClose(null);
    setAnchorEl(null);
  };

  const addFolderClicked = () => {
    setAnchorEl(null);
    //* NEED TO ADD NEW FOLDER AND RERENDER UI
    const addFolderObject: DoxleFolder = {
      folderId: "",
      folderName: "New Folder",
      lastModified: "",
      docket:
        currentLevel === "Docket" && edittedDocket
          ? edittedDocket.docketPk
          : null,
      project:
        currentLevel === "Project" && currentProject
          ? currentProject.projectId
          : currentLevel === "Docket" && edittedDocket
          ? edittedDocket.project
          : null,
      company: company?.companyId,
    };
    addFolderQuery.mutate(addFolderObject);
  };

  const addFileClicked = () => {
    setAnchorEl(null);
  };

  const onDrop = useCallback(
    <T extends File>(
      acceptedFiles: T[],
      fileRejections: FileRejection[],
      event: DropEvent
    ) => {
      const uploadedData: IAddFileMutateProps = { files: acceptedFiles };
      if (currentFolder) uploadedData.folderId = currentFolder.folderId;
      else if (currentLevel === "Docket" && edittedDocket) {
        uploadedData.docketId = edittedDocket.docketPk;
        uploadedData.projectId = edittedDocket.project ?? undefined;
      } else if (currentLevel === "Project" && currentProject)
        uploadedData.projectId = currentProject.projectId;
      addFileQuery.mutate(uploadedData);
      if (fileRejections.length > 0) addCurrentErrorFiles(fileRejections);
    },
    [currentFolder, currentLevel, edittedDocket, currentProject]
  );

  const { getRootProps } = useDropzone({
    onDrop,
    accept: DOXLE_ACCEPTED_MIME,
    maxSize: 50000000,
  });

  const isAddingFolderOrFiles =
    addFileQuery.isLoading || addFolderQuery.isLoading;
  return {
    getRootProps,
    addFileClicked,
    handleClick,
    handleClose,
    anchorEl,
    addFolderClicked,
    isAddingFolderOrFiles,
  };
};

export default useFilePopover;
