import React from "react";
import { StyledNotesContainer } from "./StyledNotes";
import useNotes from "../Hooks/useNotes";
import NoteList from "./NoteList/NoteList";
import { useNoteStore } from "../Store/useNoteStore";
import { shallow } from "zustand/shallow";
import EditNote from "./EditNote/EditNote";

type Props = {
  scope: "Project" | "Docket";
};

const Notes = ({ scope }: Props) => {
  const {} = useNotes({ scope });
  return (
    <StyledNotesContainer>
      <EditNote />
      <NoteList />
    </StyledNotesContainer>
  );
};

export default Notes;
