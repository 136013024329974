import React, { useState } from "react";
import {
  StyledAddBookingContentWrapper,
  StyledAddBookingDocketAndDateContainer,
  StyledAddBookingLabelText,
} from "./StyledComponentAddBookingDocket";
import { useDoxleThemeStore } from "../../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";

import dayjs from "dayjs";

import useAddBookingDocketAndDate from "./Hooks/useAddBookingDocketAndDate";
import { useAddDocketBookingContext } from "./AddBookingDocket";
import { useEditDocketSideScreenStore } from "../../../../CoreContent/EditDocketSideScreen/EditDocketSideScreenStore/useEditDocketSideScreenStore";
import BookingDateRangePicker from "../../../CommonComponents/BookingDateRangePicker";
import CustomDatePicker from "../../../../DoxleDesignPattern/CustomDatePicker/CustomDatePicker";
import DoxleIconButton from "../../../../DoxleDesignPattern/DoxleButtons/DoxleIconButton";
import HighlightOffSharpIcon from "@mui/icons-material/HighlightOffSharp";

type Props = {};

const AddBookingDocketAndDate = (props: Props) => {
  const [onHoverStartDate, setOnHoverStartDate] = useState(false);
  const [onHoverEndDate, setOnHoverEndDate] = useState(false);
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
      currentTheme: state.currentTheme,
    }),
    shallow
  );
  const {
    handleSelectDateRange,
    handleOpenDocketList,
    handleCloseDocketList,
    anchorDocketList,
  } = useAddBookingDocketAndDate({});
  const { startDate, endDate, setStartDate, setEndDate } = useAddDocketBookingContext();
  const { edittedDocket } = useEditDocketSideScreenStore(
    (state) => ({ edittedDocket: state.edittedDocket }),
    shallow
  );
  return (
    <StyledAddBookingDocketAndDateContainer>
      <div className="field-wrapper">
        <StyledAddBookingLabelText
          $themeColor={doxleThemeColor}
          $doxleFont={doxleFont}
        >
          Docket
        </StyledAddBookingLabelText>

        <StyledAddBookingContentWrapper
          $themeColor={doxleThemeColor}
          $doxleFont={doxleFont}
          $isNull={Boolean(!edittedDocket)}
          layout
          onClick={handleOpenDocketList}
          style={{ overflow: "visible" }}
        >
          {edittedDocket?.docketName ?? "Select Docket"}

          <span className="require-symbol">*</span>
        </StyledAddBookingContentWrapper>
      </div>

      <div className="field-wrapper">
        <StyledAddBookingLabelText
          $themeColor={doxleThemeColor}
          $doxleFont={doxleFont}
        >
          Start Date
        </StyledAddBookingLabelText>
        <div
          className="date-picker-wrapper"
          onMouseEnter={(e) => {
            e.stopPropagation();
            setOnHoverStartDate(true);
          }}
          onMouseLeave={(e) => {
            setOnHoverStartDate(false);
          }}
        >
          <CustomDatePicker
            value={startDate ? dayjs(startDate).format("YYYY/MM/DD") : null}
            onChange={(value: dayjs.Dayjs | null) => {
              setStartDate(value ? value.toDate() : null);
            }}
          />
          {startDate && onHoverStartDate && (
            <DoxleIconButton
              iconSource={
                <HighlightOffSharpIcon
                  htmlColor={doxleThemeColor.primaryFontColor}
                  sx={{
                    fontSize: "2rem",
                  }}
                />
              }
              buttonSize={30}
              onClick={() => {
                setStartDate(null);
              }}
            />
          )}
        </div>
      </div>

      <div className="field-wrapper">
        <StyledAddBookingLabelText
          $themeColor={doxleThemeColor}
          $doxleFont={doxleFont}
        >
          End Date
        </StyledAddBookingLabelText>
        <div
          className="date-picker-wrapper"
          onMouseEnter={(e) => {
            e.stopPropagation();
            setOnHoverEndDate(true);
          }}
          onMouseLeave={(e) => {
            setOnHoverEndDate(false);
          }}
        >
          <CustomDatePicker
            value={endDate ? dayjs(endDate).format("YYYY/MM/DD") : null}
            onChange={(value: dayjs.Dayjs | null) => {
              setEndDate(value ? value.toDate() : null);
            }}
          />
          {endDate && onHoverEndDate && (
            <DoxleIconButton
              iconSource={
                <HighlightOffSharpIcon
                  htmlColor={doxleThemeColor.primaryFontColor}
                  sx={{
                    fontSize: "2rem",
                  }}
                />
              }
              buttonSize={30}
              onClick={() => {
                setEndDate(null);
              }}
            />
          )}
        </div>
      </div>
    </StyledAddBookingDocketAndDateContainer>
  );
};

export default AddBookingDocketAndDate;
