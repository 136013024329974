import styled from "styled-components";
import { DoxleFont, DoxleThemeColor } from "../../../../DoxleGeneralStore/useDoxleThemeStore";
import { editRgbaAlpha } from "../../../../Utilities/FunctionUtilities";


export const MainContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

export const SelectRoleContainer = styled.div`
  border: 1px solid #e6e6e6;
  width: 39rem;
  height: 44.7rem;
  border-radius: 5px;
`

export const PromptArea = styled.div`
  border-bottom: 1px solid #e6e6e6;
  padding-bottom: 20px;
`;

export const WelcomeDoxle = styled.div<{
  $themeColor: DoxleThemeColor;
}>`
  font-size: 1.6rem;
  color: ${(p) => p.$themeColor.doxleColor};
  margin-left: 4rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
  font-weight: 500;
`;

export const StyledDescription = styled.div<{
  $themeColor: DoxleThemeColor;
}>`
  font-size: 1.4rem;
  color: ${(p) => editRgbaAlpha({rgbaColor: p.$themeColor.primaryFontColor, alpha: '0.7'})};
  margin-left: 4rem;
`;

export const NextBtn = styled.button<{
  $themeColor: DoxleThemeColor;
}>`
  background-color: ${(p) => p.$themeColor.primaryFontColor};
  color: ${(p) => p.$themeColor.primaryBackgroundColor};
  width: 8rem;
  height: 3rem;
  border-radius: 3px;
  border: none;
  cursor: pointer;
  margin-top: 5rem;
`;

export const SelectArea = styled.div<{
    $themeColor: DoxleThemeColor;
    $doxleFont: DoxleFont;
  }>`
    padding: 30px 40px;
  
    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
    }
  
    .container {
      display: block;
      position: relative;
      padding-left: 35px;
      margin-bottom: 12px;
      cursor: pointer;
      font-size: 1.4rem;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      font-family: ${(p) => p.$doxleFont.subTitleFont};
      color: ${(p) => p.$themeColor.primaryFontColor};
    }
  
    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 1.4rem;
      width: 1.4rem;
      border: solid 1px ${(p) => p.$themeColor.borderColor};
      border-radius: 50%;
    }
  
    /* On mouse-over, add a grey background color */
    .container:hover input ~ .checkmark {
    }
  
    /* When the radio button is checked, add a blue background */
    .container input:checked ~ .checkmark {
      background-color: #ffffff;
    }
  
    /* Create the indicator (the dot/circle - hidden when not checked) */
    .checkmark:after {
      content: "";
      position: absolute;
      display: none;
    }
  
    /* Show the indicator (dot/circle) when checked */
    .container input:checked ~ .checkmark:after {
      display: block;
    }
  
    /* Style the indicator (dot/circle) */
    .container .checkmark:after {
      top: 4px;
      left: 4px;
      width: 0.6rem;
      height: 0.6rem;
      border-radius: 50%;
      background: #000000;
    }
  `;

//================================================================
//================================================================
//================================================================
export const StyledDetailTitle = styled.div<{
    $themeColor: DoxleThemeColor;
    $doxleFont: DoxleFont;
}>`
    color: ${(p) => p.$themeColor.doxleColor};
    font-size: 1.6rem;
    margin-left: 50px;
    margin-top: 15px;
    font-weight: 500;

`

export const StyledWarning = styled.div<{
    $doxleFont: DoxleFont;
  }>`
    color: #ea1515;
    font-size: 1.2rem;
    font-family: ${(p) => p.$doxleFont.subTitleFont};
    position: relative;
    top: 2px;
    height: 3px;
    left: 20px;
  `;
  
  export const StyledFormArea = styled.div`
    padding: 10px 30px;
  `;
  
  export const StyledInput = styled.input<{
    $themeColor: DoxleThemeColor;
  }>`
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    box-sizing: border-box;
    border: none;
    border-bottom: 2px solid #e6e6e6;
    background: transparent;
    height: 30px;
    color: ${(p) => p.$themeColor.primaryFontColor};
  
    &:focus {
      outline: none;
    }
  
    &::placeholder {
      color: ${(p) => editRgbaAlpha({ rgbaColor: p.$themeColor.primaryFontColor, alpha: "0.7" })};
      opacity: 1; 
      font-size: 1.4rem;
    }
  
    &::-ms-input-placeholder {
      color: ${(p) => editRgbaAlpha({ rgbaColor: p.$themeColor.primaryFontColor, alpha: "0.7" })};
      font-size: 1.4rem;
    }
  `;
  

  
  export const DetailsFormContainer = styled.div<{
    $themeColor: DoxleThemeColor;
  }>`
    border: 1px solid ${(p) => p.$themeColor.borderColor};
    max-width: 32.8rem;
    height: 398px;
    border-radius: 5px;
  `;
  
  export const ContinueBtn = styled.button<{
    $themeColor: DoxleThemeColor;
  }>`
    background-color: ${(p) => p.$themeColor.primaryFontColor};
    color: ${(p) => p.$themeColor.primaryBackgroundColor};
    width: 8rem;
    height: 3rem;
    border-radius: 3px;
    border: none;
    cursor: pointer;
    margin-top: 5rem;
    margin-right: 10px;
  `;
