export const toggleQuote = (
  givenString: string,
  selectedString: string
): string => {
  // If the selected string starts and ends with a quote, remove them
  if (selectedString.startsWith(`"`) && selectedString.endsWith(`"`)) {
    return givenString.replace(selectedString, selectedString.slice(1, -1));
  }
  // If not, add quotes around the selected string
  else {
    return givenString.replace(selectedString, `"${selectedString}"`);
  }
};
