import {useState} from "react";
import {useEstimatesStore} from "../Store/useEstimatesStore";
import {shallow} from "zustand/shallow";
import {useDoxleThemeStore} from "../../../DoxleGeneralStore/useDoxleThemeStore";
import {StyledEstimateTableCell, StyledEstimateTableRow} from "../StyledComponents";
import {CONTACTS_TABLE_HEADER_LIST} from "../../Models/estimatesTable";
import DoxleCheckbox from "../../../DoxleDesignPattern/DoxleCheckbox/DoxleCheckbox";
import {Estimate} from "../../Models/estimatesFilters";
import {useEditEstimatesStore} from "../Store/useEditEstimateStore";

interface Props {
  estimate: Estimate
}

const EstimateTableRow = ({
  estimate
}: Props) => {
  const [isHover, setIsHover] = useState<boolean>(false);
  const queryFilter = useEstimatesStore(state=>state.estimatesQueryFilter, shallow)
  const {setSidePeekEstimate, setEstimatesQueryFilter} = useEditEstimatesStore(state=>({
    setSidePeekEstimate: state.setSidePeekEstimate,
    setEstimatesQueryFilter: state.setEstimatesQueryFilter
  }), shallow)

  const { doxleThemeColor, doxleFont, currentTheme } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
      currentTheme: state.currentTheme,
    }),
    shallow
  );

  const handleOrderRowClick = () => {
    setSidePeekEstimate(estimate)
    setEstimatesQueryFilter(queryFilter)
  }


  const handleUpdateIsAllowance = (event: React.ChangeEvent<HTMLInputElement>, value: boolean) =>{

  }

  return (
    <StyledEstimateTableRow
      themeColor={doxleThemeColor}
      onMouseOver={() => setIsHover(true)}
      onMouseOut={() => setIsHover(false)}
      onClick={handleOrderRowClick}
    >
      {CONTACTS_TABLE_HEADER_LIST.map((header, idx) => {
        if (header.isDisplayed) {
          if (header.headerName === "#") {
            return (
              <StyledEstimateTableCell
                hoverStatus={isHover}
                key={`docketCell#${estimate.estimateId}#${idx}`}
                widthInPixel={null}
                horizontalAlign="flex-start"
                doxleFont={doxleFont}
                themeColor={doxleThemeColor}
                currentTheme={currentTheme}
                fontWeight="400"
                letterSpacing="-0.28px"
                paddingLeft={"40px"}
              >
                {}
              </StyledEstimateTableCell>
            );
          }
          if (header.headerName === "Description") {
            return (
              <StyledEstimateTableCell
                hoverStatus={isHover}
                key={`docketCell#${estimate.estimateId}#${idx}`}
                widthInPixel={null}
                horizontalAlign="flex-start"
                doxleFont={doxleFont}
                themeColor={doxleThemeColor}
                currentTheme={currentTheme}
                fontWeight="400"
                letterSpacing="-0.28px"
              >
                {estimate.description}
              </StyledEstimateTableCell>
            );
          }
          if (header.headerName === "Qty") {
            return (
              <StyledEstimateTableCell
                hoverStatus={isHover}
                key={`docketCell#${estimate.estimateId}#${idx}`}
                widthInPixel={null}
                horizontalAlign="center"
                doxleFont={doxleFont}
                themeColor={doxleThemeColor}
                currentTheme={currentTheme}
                fontWeight="400"
                letterSpacing="-0.28px"
              >
                {estimate.quantity}
              </StyledEstimateTableCell>
            );
          }
          if (header.headerName === "Unit") {
            return (
              <StyledEstimateTableCell
                hoverStatus={isHover}
                key={`docketCell#${estimate.estimateId}#${idx}`}
                widthInPixel={null}
                horizontalAlign="center"
                doxleFont={doxleFont}
                themeColor={doxleThemeColor}
                currentTheme={currentTheme}
                fontWeight="400"
                letterSpacing="-0.28px"
              >
                {estimate.unit}
              </StyledEstimateTableCell>
            );
          }
          if (header.headerName === "Item Cost") {
            return (
              <StyledEstimateTableCell
                hoverStatus={isHover}
                key={`docketCell#${estimate.estimateId}#${idx}`}
                widthInPixel={null}
                horizontalAlign="center"
                doxleFont={doxleFont}
                themeColor={doxleThemeColor}
                currentTheme={currentTheme}
                fontWeight="400"
                letterSpacing="-0.28px"
              >
                {estimate.unitCost}
              </StyledEstimateTableCell>
            );
          }
          if (header.headerName === "Line Cost") {
            return (
              <StyledEstimateTableCell
                hoverStatus={isHover}
                key={`docketCell#${estimate.estimateId}#${idx}`}
                widthInPixel={null}
                horizontalAlign="center"
                doxleFont={doxleFont}
                themeColor={doxleThemeColor}
                currentTheme={currentTheme}
                fontWeight="400"
                letterSpacing="-0.28px"
              >
                {estimate.lineCost}
              </StyledEstimateTableCell>
            );
          }
          if (header.headerName === "Allowance") {
            return (
              <StyledEstimateTableCell
                hoverStatus={isHover}
                key={`docketCell#${estimate.estimateId}#${idx}`}
                widthInPixel={"60px"}
                horizontalAlign="center"
                doxleFont={doxleFont}
                themeColor={doxleThemeColor}
                currentTheme={currentTheme}
                fontWeight="400"
                letterSpacing="-0.28px"
              >
                <DoxleCheckbox
                  checkColor={doxleThemeColor.doxleColor}
                  defaultChecked={estimate.isAllowance}
                  onChange={handleUpdateIsAllowance}
                />
              </StyledEstimateTableCell>
            );
          }
        }
      })}
    </StyledEstimateTableRow>
  );
};

export default EstimateTableRow