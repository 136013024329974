import { useQuery, useQueryClient } from "@tanstack/react-query";
import DoxleAPI from "../../Services/DoxleAPI";
import { BaseAPIProps } from "../../Models/baseAPIProps";
import { IDocketStatusList } from "../Models/BudgetDocket";
import { Docket, IFullDocketDetailQueryFilterProp } from "../../Models/dockets";
import { formDocketListQueryKey } from "../../Services/QueryHooks/docketQueryAPI";
import { AxiosBackendErrorReturn } from "../../Models/axiosReturn";
import { isAxiosError } from "axios";
import { Project } from "../../Models/project";
import useSetDocketListQueryData from "../../CoreContent/QueryDataHooks/SetQueryDataHooks/useSetDocketListQueryData";
import { getStageListQueryKey } from "../../Services/QueryHooks/stageQueryAPI";
import { getProjectTotalQKey } from "../../Services/QueryHooks/projectQueryAPI";

const useRetrieveBudgetStatusList = ({
  company,
  showNotification,
}: BaseAPIProps) => {
  const qKey = ["docket-status"];
  let docketURL = `/dockets/status/`;
  if (company) qKey.push(company.companyId);
  qKey.push("is_budget");
  return useQuery(
    qKey,
    () =>
      DoxleAPI.get<IDocketStatusList[]>(docketURL, {
        headers: {
          "User-Company": company!.companyId,
        },
        params: {
          company: company?.companyId,
          is_budget: "true",
        },
      }),
    {
      enabled: company !== undefined,
      retry: 1,
      staleTime: 10 * 60 * 1000,
      cacheTime: 15 * 60 * 1000,
      refetchInterval: 14 * 60 * 1000,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      onSuccess: (res) => {
        console.log();
      },
      onError: (error: any) => {
        if (showNotification) {
          if (isAxiosError<AxiosBackendErrorReturn>(error)) {
            showNotification(
              `${error?.response?.status ?? "ERROR"}: ${
                error.response?.data.detail ?? "UNKNOWN ERROR"
              }`,
              "error",
              String(
                error?.response?.data?.detail ??
                  "Error fetching docket status List"
              ).substring(0, 300)
            );
          } else {
            showNotification(
              "Something Wrong!",
              "error",
              "Error fetching docket status List"
            );
          }
        }
      },
    }
  );
};

interface RetrieveProfitAndLossQuery extends BaseAPIProps {
  enabled?: boolean;
  filter: IFullDocketDetailQueryFilterProp;
  paymentsOnly: boolean;
}
export interface ProjectWithDockets extends Project {
  dockets: Array<Docket>;
}
const useRetrieveProjectProfitAndLoss = ({
  filter,
  company,
  showNotification,
  enabled,
  paymentsOnly,
}: RetrieveProfitAndLossQuery) => {
  const qKey = getProjectProfitAndLossQKey(filter.project, paymentsOnly);
  const { handleUpdateMultipleDocket } = useSetDocketListQueryData({ filter });
  const queryClient = useQueryClient();
  return useQuery(
    qKey,
    () =>
      DoxleAPI.get<ProjectWithDockets>(`/project/pl/${filter.project}/`, {
        headers: { "User-Company": company!.companyId },
        params: { payments_only: paymentsOnly },
      }),
    {
      // staleTime: 5 * 60 * 1000,
      refetchOnWindowFocus: false,
      refetchInterval: false,
      refetchOnMount: false,
      enabled: Boolean(
        company && filter.project && enabled && filter.project !== "null"
      ),
      retry: 1,
      onSuccess: (response) => {
        // Set Budget Dockets Query for Docket Values
        handleUpdateMultipleDocket(response.data.dockets);
        // Set Project List Query For Totals
        if (filter.project) {
          const totalsKey = getProjectTotalQKey(filter?.project);
          const stagesKey = getStageListQueryKey({
            projectId: filter?.project,
          });
          queryClient.refetchQueries({
            type: "all",
            predicate: (query) =>
              totalsKey.every((item) => query.queryKey.includes(item)),
          });
          queryClient.refetchQueries({
            type: "all",
            predicate: (query) =>
              stagesKey.every((item) => query.queryKey.includes(item)),
          });
        }
      },
      onError: (error: any) => {
        if (showNotification) {
          if (isAxiosError<AxiosBackendErrorReturn>(error)) {
            showNotification(
              `${error?.response?.status ?? "ERROR"}: ${
                error.response?.data.detail ?? "UNKNOWN ERROR"
              }`,
              "error",
              String(
                error?.response?.data?.detail ?? "Error Updating Xero Values"
              ).substring(0, 300)
            );
          } else {
            showNotification(
              "Something Wrong!",
              "error",
              "Error Updating Xero Values"
            );
          }
        }
      },
    }
  );
};

//# HELPER FUNCTIONS
export const getProjectProfitAndLossQKey = (
  projectId: string | undefined,
  paymentsOnly?: boolean
) => {
  let qKey = ["project-p&l"];
  if (projectId) qKey.push(projectId);
  if (paymentsOnly) qKey.push(`payments=${paymentsOnly}`);
  return qKey;
};
const budgetsAPI = {
  useRetrieveBudgetStatusList,
  useRetrieveProjectProfitAndLoss,
};

export default budgetsAPI;
