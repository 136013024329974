import React from "react";
import { useDoxleThemeStore } from "../../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import { StyledQAEditFieldWrapper } from "./StyledComponentQAItemDetail";
import { useProjectQAStore } from "../../Store/useProjectQAStore";
import QACustomDatePicker from "../CommonComponents/QACustomDatePicker";
import useEditQaDueDate from "./Hooks/useEditQaDueDate";
import { useShallow } from "zustand/react/shallow";

type Props = {};

const EditQaDueDate = (props: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    useShallow((state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }))
  );
  const { selectedQAItem } = useProjectQAStore(
    useShallow((state) => ({
      selectedQAItem: state.selectedQAItem,
    }))
  );

  const { handleUpdateDueDate, isUpdatingDueDate } = useEditQaDueDate({});
  return (
    <StyledQAEditFieldWrapper className="field-wrapper">
      <span className="field-label-text">Due Date</span>

      <QACustomDatePicker
        value={selectedQAItem?.dueDate ?? null}
        onChange={handleUpdateDueDate}
        isUpdating={isUpdatingDueDate}
      />
    </StyledQAEditFieldWrapper>
  );
};

export default EditQaDueDate;
