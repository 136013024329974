import { Docket } from "../../Models/dockets";
import LinkOffIcon from "@mui/icons-material/LinkOff";
import LinkIcon from "@mui/icons-material/Link";
import {
  StyledUnlinkAccountButton,
  StyledUnlinkAccountText,
  StyledUnlinkSpacer,
} from "./styledComponents";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import useLinkUnlinkAccountButton from "../Hooks/useLinkUnlinkAccountButton";
type Props = {
  isLinked: boolean;
  docket: Docket;
};

const LinkUnlinkAccountButton = ({ isLinked, docket }: Props) => {
  const styleProps = useDoxleThemeStore(
    (state) => ({
      $themeColor: state.doxleThemeColor,
      $doxleFont: state.doxleFont,
      $currentTheme: state.currentTheme,
    }),
    shallow
  );
  const { unSavedAccount, handleUnlinkDocket, handleLinkDocket } =
    useLinkUnlinkAccountButton({ docket });

  return isLinked ? (
    <StyledUnlinkAccountButton {...styleProps} onClick={handleUnlinkDocket}>
      <LinkOffIcon />
      <StyledUnlinkAccountText {...styleProps}>
        Clear Link
      </StyledUnlinkAccountText>
    </StyledUnlinkAccountButton>
  ) : unSavedAccount ? (
    <StyledUnlinkAccountButton {...styleProps} onClick={handleLinkDocket}>
      <LinkIcon />
      <StyledUnlinkAccountText {...styleProps}>Link</StyledUnlinkAccountText>
    </StyledUnlinkAccountButton>
  ) : (
    <StyledUnlinkSpacer {...styleProps} />
  );
};

export default LinkUnlinkAccountButton;
