import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import { useDoxleCurrentContextStore } from "../../DoxleGeneralStore/useDoxleCurrentContext";
import {
  StyledProjectDropdownContainer,
  StyledProjectMenuContainer,
  StyledSelectedProjectDisplay,
} from "./StyledProjectComponents";
import { useShallow } from "zustand/react/shallow";
import useProjectSelectDropDown from "./Hooks/useProjectSelectDropdown";
import Popover from "@mui/material/Popover";
import ProjectDropdownList from "./ProjectDropdownList";
import Skeleton from "@mui/material/Skeleton";
import ProjectStatusList from "./ProjectStatusList";

const ProjectSelectDropDown = () => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    useShallow((state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }))
  );
  const { currentProject, setShowProjectSideList, currentCompany } =
    useDoxleCurrentContextStore(
      useShallow((state) => ({
        currentProject: state.currentProject,
        showProjectSideList: state.showProjectSideList,
        setShowProjectSideList: state.setShowProjectSideList,
        currentCompany: state.currentCompany,
      }))
    );
  const {
    projectDropdownAnchor,
    setProjectDropdownAnchor,

    handleCloseProjectDropdown,

    handleSelectProject,
    showProjectList,
    isFetchingProject,
    isErrorFetchingProject,
  } = useProjectSelectDropDown({});
  const isDropdownOpen = Boolean(projectDropdownAnchor);
  const projectDropdownId = projectDropdownAnchor
    ? "project-dropdown"
    : undefined;
  return (
    <StyledProjectMenuContainer>
      {isFetchingProject && (
        <Skeleton variant="rounded" width="20vw" height="1.4rem" />
      )}
      {!isFetchingProject && (
        <StyledSelectedProjectDisplay
          onClick={(event) => {
            setProjectDropdownAnchor(event.currentTarget);
          }}
          initial={{
            y: -10,
            opacity: 0,
          }}
          animate={{
            y: 0,
            opacity: 1,
            transition: {
              duration: 0.4,
            },
          }}
        >
          <span style={{ fontFamily: doxleFont.secondaryTitleFont }}>
            {isErrorFetchingProject
              ? "Something wrong!"
              : currentProject
              ? currentProject.siteAddress
              : "Select Or Add A Project"}
          </span>
        </StyledSelectedProjectDisplay>
      )}

      <Popover
        id={projectDropdownId}
        open={isDropdownOpen}
        anchorEl={projectDropdownAnchor}
        onClose={handleCloseProjectDropdown}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        slotProps={{
          paper: {
            sx: { marginTop: "8px", borderRadius: "14px", overflow: "hidden" },
          },
        }}
      >
        <StyledProjectDropdownContainer layout>
          <span className="dropdown-project-title">
            {currentCompany && `${currentCompany.name}'s`} Projects
          </span>

          {showProjectList && (
            <ProjectDropdownList
              handleSelectProject={handleSelectProject}
              handleCloseProjectDropdown={handleCloseProjectDropdown}
            />
          )}
        </StyledProjectDropdownContainer>
      </Popover>
    </StyledProjectMenuContainer>
  );
};

export default ProjectSelectDropDown;
