import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { motion } from "framer-motion";
import styled from "styled-components";

export const StyledDoxleErrorFileWarningContainer = styled(Dialog)<{}>`
  && {
    background-color: ${(p) => p.theme.color.primaryBackdropColor};
    box-shadow: 1px 1px 2px ${(p) => p.theme.color.primaryBoxShadowColor};
  }
  & .MuiDialog-paper {
    background-color: ${(p) => p.theme.color.primaryBackdropColor};
    box-shadow: 0px 0px 14px 0px ${(p) => p.theme.color.primaryBoxShadowColor};
  }
`;
export const StyledDoxleErrorWarningTitle = styled(DialogTitle)<{}>`
  && {
    font-family: ${(p) => p.theme.font.primaryFont};
    font-style: normal;
    font-weight: 600;
    font-size: 20px;

    color: ${(p) => p.theme.color.primaryFontColor};
    border-bottom: 1px solid ${(p) => p.theme.color.primaryDividerColor};
    background-color: ${(p) => p.theme.color.primaryContainerColor};
    padding: 7px 14px !important;
  }
`;
export const StyledDoxleErrorWarningContent = styled(DialogContent)<{}>`
  && {
    background-color: ${(p) => p.theme.color.primaryContainerColor};
    box-shadow: 1px 1px 2px ${(p) => p.theme.color.primaryBoxShadowColor};

    word-break: break-word;
    padding: 8px 14px !important;
  }
`;
export const StyledDoxleErrorFilesContainer = styled.div<{}>`
  width: calc(100% - 16px);
  display: flex;
  align-items: center;
  padding: 8px;
  border-bottom: 1px solid ${(p) => p.theme.color.primaryDividerColor};

  .error-wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-left: 14px;
  }
  .image-holder {
    width: 35px;
    aspect-ratio: 1;
  }
  .text {
    width: 100%;
    overflow: hidden;
    white-space: pre-wrap;

    font-family: ${(p) => p.theme.font.primaryFont};
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: normal;
    letter-spacing: 0.05em;
    color: ${(p) => p.theme.color.primaryFontColor};
    margin-bottom: 2px;
  }
  .file-name {
    width: 100%;
    overflow: hidden;
    white-space: pre-wrap;

    font-family: ${(p) => p.theme.font.primaryFont};
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: normal;
    letter-spacing: 0.05em;
    color: ${(p) => p.theme.color.primaryFontColor};
    margin-bottom: 5px;
  }
`;
export const StyledDoxleErrorWarningActions = styled(DialogActions)<{}>`
  && {
    background-color: ${(p) => p.theme.color.primaryContainerColor};
  }
`;
export const StyledDoxleErrorActionButton = styled(motion(Button))<{}>`
  && {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0px 4px;
    border-radius: 4px !important;
    text-transform: capitalize;
    background-color: ${(p) => p.theme.color.primaryFontColor};

    &:hover {
      background-color: ${(p) => p.theme.color.primaryFontColor} !important;
      opacity: 0.9;
      transform: translateY(-1px) scale(1.05);
      transition: 0.4s;
    }
    .button-text {
      font-family: ${(p) => p.theme.font.primaryFont};
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: normal;
      letter-spacing: 0.05em;
      color: ${(p) => p.theme.color.primaryContainerColor};
    }
  }
`;
