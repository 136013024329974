import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import React from "react";
import {
  StyledQuoteResponseDateSection,
  StyledQuoteResponseFieldWrapper,
  StyledQuoteResponseLabelFieldText,
} from "./StyledComponentQuoteResponse";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { useQuoteContext } from "./QuoteResponse";
import { produce } from "immer";
import { formatTISODate } from "../../../Utilities/FunctionUtilities";
import dayjs from "dayjs";
import AddQuoteResponseDatePicker from "../AddQuoteResponse/AddQuoteResponseDatePicker";

type Props = {};

const QuoteResponseDateSection = (props: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore((state) => ({
    doxleThemeColor: state.doxleThemeColor,
    doxleFont: state.doxleFont,
  }));

  const { quoteResponse, setQuoteResponse } = useQuoteContext();

  const handlePickExpiryDate = (value: dayjs.Dayjs | null) => {
    if (value)
      setQuoteResponse((prev) =>
        produce(prev, (draft) => {
          draft.expiryDate = formatTISODate(value.toDate());
        })
      );
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <StyledQuoteResponseDateSection layout>
        <StyledQuoteResponseFieldWrapper
          style={{
            width: "50%",
            maxWidth: 400,
          }}
        >
          <StyledQuoteResponseLabelFieldText
            $themeColor={doxleThemeColor}
            $doxleFont={doxleFont}
          >
            INITIAL DATE
          </StyledQuoteResponseLabelFieldText>
          <AddQuoteResponseDatePicker
            value={dayjs(new Date())}
            disabled={true}
          />
        </StyledQuoteResponseFieldWrapper>

        <StyledQuoteResponseFieldWrapper
          style={{
            flex: 1,
          }}
        >
          <StyledQuoteResponseLabelFieldText
            $themeColor={doxleThemeColor}
            $doxleFont={doxleFont}
          >
            Expiry DATE
          </StyledQuoteResponseLabelFieldText>
          <AddQuoteResponseDatePicker
            value={
              quoteResponse.expiryDate ? dayjs(quoteResponse.expiryDate) : null
            }
            onChange={handlePickExpiryDate}
          />
        </StyledQuoteResponseFieldWrapper>
      </StyledQuoteResponseDateSection>
    </LocalizationProvider>
  );
};

export default QuoteResponseDateSection;
