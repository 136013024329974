import React from "react";
import { useDoxleThemeStore } from "../../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import useEditQAListTitle from "./Hooks/useEditQAListTitle";
import {
  StyledEditQAListTextField,
  StyledQAListEditFieldWrapper,
  StyledTitleInputEndornment,
} from "./StyledComponentQAListEdit";
import CircularProgress from "@mui/material/CircularProgress";
import { useShallow } from "zustand/react/shallow";

type Props = {};

const EditQAListTitle = (props: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    useShallow((state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }))
  );
  const {
    newQAListTitleText,
    setNewQAListTitleText,
    handleKeydownInput,
    inputRef,
    handleUpdateQAListTitle,
    isUpdatingTitle,
  } = useEditQAListTitle({});
  return (
    <StyledQAListEditFieldWrapper>
      <span className="field-label-text">Title</span>

      <StyledEditQAListTextField
        placeholder="New description..."
        value={newQAListTitleText}
        onChange={(e) => setNewQAListTitleText(e.target.value)}
        onFocus={(e) => e.target.select()}
        onKeyDown={handleKeydownInput}
        inputRef={inputRef}
        onBlur={handleUpdateQAListTitle}
        InputProps={{
          endAdornment: isUpdatingTitle && (
            <StyledTitleInputEndornment position="end">
              <CircularProgress
                style={{ color: doxleThemeColor.primaryFontColor }}
                size="14px"
              />
            </StyledTitleInputEndornment>
          ),
        }}
      />
    </StyledQAListEditFieldWrapper>
  );
};

export default EditQAListTitle;
