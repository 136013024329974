import React, { useEffect } from "react";
import { useDoxleCurrentContextStore } from "../../../../DoxleGeneralStore/useDoxleCurrentContext";
import useDoxleNotificationStore from "../../../../DoxleGeneralStore/useDoxleNotificationStore";
import { shallow } from "zustand/shallow";
import { useEditDocketSideScreenStore } from "../../../../CoreContent/EditDocketSideScreen/EditDocketSideScreenStore/useEditDocketSideScreenStore";
import { useProjectStore } from "../../../../DoxleGeneralStore/useprojectStore";
import { useNoteStore } from "../../../Store/useNoteStore";
import { NoteQueryAPI } from "../../../QueryAPI/notesQueryAPI";
import { useIsMutating } from "@tanstack/react-query";

type Props = { noteId: string };

const useNoteLineSection = ({ noteId }: Props) => {
  const { company } = useDoxleCurrentContextStore(
    (state) => ({
      company: state.currentCompany,
    }),
    shallow
  );
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification
  );
  const { setHandleAddButtonFn } = useEditDocketSideScreenStore(
    (state) => ({
      setHandleAddButtonFn: state.setHandleAddButtonFn,
    }),
    shallow
  );
  const setProjectAddButtonFn = useProjectStore(
    (state) => state.setFunctionNewButton,
    shallow
  );
  const { noteScope } = useNoteStore(
    (state) => ({
      noteScope: state.noteScope,
    }),
    shallow
  );

  const { add } = NoteQueryAPI.useMutateNoteLineQuery({
    company,
    noteId: noteId,
    showNotification,
  });

  const handleAddNoteLine = () => {
    add.mutate({ text: "New Line Description" });
  };
  const isAddingItem = useIsMutating({ mutationKey: ["add-note-line"] });
  useEffect(() => {
    if (noteScope === "Project") {
      setProjectAddButtonFn([
        {
          btnFunction: handleAddNoteLine,
          btnText: "",
          isLoading: isAddingItem > 0,
        },
      ]);
    } else {
      setHandleAddButtonFn([
        {
          btnFunction: handleAddNoteLine,
          tooltipText: "Add quick line",
          isLoading: isAddingItem > 0,
        },
      ]);
    }
  }, [
    setHandleAddButtonFn,
    noteScope,
    handleAddNoteLine,
    setProjectAddButtonFn,
    isAddingItem,
  ]);

  useEffect(() => {
    return () => {
      setHandleAddButtonFn([]);
      setProjectAddButtonFn([]);
    };
  }, []);

  return {};
};

export default useNoteLineSection;
