import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDoxleCurrentContextStore } from "../../../DoxleGeneralStore/useDoxleCurrentContext";
import {useShallow} from "zustand/react/shallow";
import {Room, Floor, FloorFilters, RoomFilters} from "../../../QA/Models/locations";
import LocationQueryAPI from "../../../Services/QueryHooks/LocationQueryAPI";

type Props = {
  variant?: TSelectLocationMode;
  onSelectRoom?: (item: Room) => void;
  onSelectFloor?: (item: Floor) => void;
  onClosePopover?: () => void;
  closeOnSelect?: boolean;
  floorFilter?: string;
};

export type TSelectLocationMode = "room" | "floor";
interface DoxleSelectLocationDropdown {
  mode: TSelectLocationMode;
  setMode: React.Dispatch<React.SetStateAction<TSelectLocationMode>>;
  showAddLocationForm: boolean;
  setShowAddLocationForm: React.Dispatch<React.SetStateAction<boolean>>;
  showLocationList: boolean;
  setShowLocationList: React.Dispatch<React.SetStateAction<boolean>>;

  floorList: Floor[];

  isFetchingFloorList: boolean;
  isSuccessFetchingFloorList: boolean;
  isErrorFetchingFloorList: boolean;
  searchText: string;
  setSearchText: React.Dispatch<React.SetStateAction<string>>;
  handleFetchNextPageFloor: () => void;
  isFetchingNextPageFloorList: boolean;

  roomList: Room[];

  isFetchingRoomList: boolean;
  isSuccessFetchingRoomList: boolean;
  isErrorFetchingRoomList: boolean;

  handleFetchNextPageRoom: () => void;
  isFetchingNextPageRoomList: boolean;

  handleSelectFloor: (floor: Floor) => void;
  handleSelectRoom: (room: Room) => void;
  handleFetchNextPage: () => void;
}
const useDoxleSelectLocationDropdown = ({
  variant,
  onSelectFloor,
  onSelectRoom,
  onClosePopover,
  closeOnSelect,
  floorFilter
}: Props): DoxleSelectLocationDropdown => {
  const project = useDoxleCurrentContextStore(useShallow(state => state.currentProject?.projectId)) ?? ""
  const [mode, setMode] = useState<TSelectLocationMode>(variant ?? "room");
  const [showAddLocationForm, setShowAddLocationForm] = useState(false);
  const [showLocationList, setShowLocationList] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [filterFloor, setfilterFloor] = useState<FloorFilters>({project});
  const [filterRoom, setfilterRoom] = useState<RoomFilters>({floor: floorFilter, project});

  const retrieveFloorQuery =
    LocationQueryAPI.useRetrieveFloorsQuery({
      ...filterFloor,
      enabled: Boolean(variant === "floor"),
    });
  const floorList = useMemo(
    () =>
      retrieveFloorQuery.isSuccess
        ? retrieveFloorQuery.data.pages.flatMap(
            (page) => (page.data.results as Floor[]) ?? []
          ) ?? []
        : [],
    [retrieveFloorQuery.data]
  );
  const handleFetchNextPageFloor = () => {
    if (retrieveFloorQuery.hasNextPage)
      retrieveFloorQuery.fetchNextPage();
  };

  const retrieveRoomQuery = LocationQueryAPI.useRetrieveRoomsQuery({
    ...filterRoom,
    enabled: Boolean(variant === "room"),
  });
  const roomList = useMemo(
    () =>
      retrieveRoomQuery.isSuccess
        ? retrieveRoomQuery.data.pages.flatMap(
            (page) => page.data.results ?? []
          ) ?? []
        : [],
    [retrieveRoomQuery.data]
  );
  const handleFetchNextPageRoom = () => {
    if (retrieveRoomQuery.hasNextPage) retrieveRoomQuery.fetchNextPage();
  };

  const handleSelectFloor = useCallback(
    (floor: Floor) => {
      if (onSelectFloor) onSelectFloor(floor);
      if (closeOnSelect && onClosePopover) onClosePopover();
    },
    [onSelectFloor, onClosePopover, closeOnSelect]
  );

  const handleSelectRoom = useCallback(
    (room: Room) => {
      if (onSelectRoom) onSelectRoom(room);
      if (closeOnSelect && onClosePopover) onClosePopover();
    },
    [onSelectRoom, onClosePopover, closeOnSelect]
  );

  const handleFetchNextPage = () => {
    if (mode === "floor") handleFetchNextPageFloor();
    else handleFetchNextPageRoom();
  };
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (searchText) {
        setfilterFloor({ search: searchText, project });
        setfilterRoom({ search: searchText, floor: floorFilter, project });
      } else {
        setfilterFloor({project});
        setfilterRoom({floor: floorFilter, project});
      }
    }, 300);

    return () => clearTimeout(timeout);
  }, [searchText, floorFilter, project]);
  return {
    mode,
    setMode,
    showAddLocationForm,
    setShowAddLocationForm,
    showLocationList,
    setShowLocationList,

    floorList,
    isFetchingFloorList: retrieveFloorQuery.isLoading,
    isSuccessFetchingFloorList: retrieveFloorQuery.isSuccess,
    isErrorFetchingFloorList: retrieveFloorQuery.isError,
    searchText,
    setSearchText,
    handleFetchNextPageFloor,
    isFetchingNextPageFloorList: retrieveFloorQuery.isFetchingNextPage,

    roomList,
    isFetchingRoomList: retrieveRoomQuery.isLoading,
    isSuccessFetchingRoomList: retrieveRoomQuery.isSuccess,
    isErrorFetchingRoomList: retrieveRoomQuery.isError,
    handleFetchNextPageRoom,
    isFetchingNextPageRoomList: retrieveRoomQuery.isFetchingNextPage,
    handleSelectFloor,
    handleSelectRoom,
    handleFetchNextPage,
  };
};

export default useDoxleSelectLocationDropdown;
