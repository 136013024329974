import { motion } from "framer-motion";
import styled from "styled-components";
import {
  DoxleFont,
  DoxleThemeColor,
} from "../../../../DoxleGeneralStore/useDoxleThemeStore";
import { editRgbaAlpha } from "../../../../Utilities/FunctionUtilities";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";

export const StyledQAListEditContainer = styled(motion.div)`
  width: 100%;

  display: flex;
  flex-direction: column;

  padding-top: 1.4rem;

  .date-field-wrapper {
    width: 45%;
    max-width: 14.4rem;
  }
  .assignee-field-wrapper {
    width: 45%;
    max-width: 30rem;
  }
`;

export const StyledQAListEditFieldWrapper = styled.div<{}>`
  width: 100%;
  max-width: 70rem;
  display: flex;
  flex-direction: column;
  margin-bottom: 4rem;

  .field-label-text {
    color: ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.theme.color.primaryFontColor,
        alpha: "0.6",
      })};
    font-family: ${(p) => p.theme.font.primaryFont};
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    text-transform: capitalize;
    margin-left: 14px;
    margin-bottom: 4px;
  }
`;

interface ICustomTextFieldProps
  extends Omit<
    TextFieldProps<"standard">,
    "onDragStart" | "onAnimationStart" | "onDragEnd" | "onDrag"
  > {}
const AnimatedTextField = motion(TextField);
export const StyledEditQAListTextField = styled(
  ({ ...props }: ICustomTextFieldProps) => (
    <AnimatedTextField {...props} layout />
  )
)`
  && {
    width: calc(100% - 16px);
    background-color: ${(p) => p.theme.color.primaryContainerColor};

    padding: 5px 8px;
    border: 1px solid
      ${(p) =>
        editRgbaAlpha({
          rgbaColor: p.theme.color.primaryDividerColor,
          alpha: "0.5",
        })};
  }
  input {
    color: ${(p) => p.theme.color.primaryFontColor};
    font-size: 16px;
    font-family: ${(p) => p.theme.font.primaryFont};
    font-style: normal;
    font-weight: 500;
    line-height: 110%;

    &::placeholder {
      color: ${(p) => p.theme.color.primaryInputPlaceholderColor};
      font-size: 16px;
      opacity: 1;
      font-family: ${(p) => p.theme.font.primaryFont};
      font-style: normal;
      font-weight: 500;
      line-height: 110%;

      padding: 0px !important;
    }
  }
  & .MuiOutlinedInput-root {
    padding: 0px !important;
  }
  & .MuiOutlinedInput-input {
    padding: 0px !important;
  }
  & .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }

  & .MuiInput-root:before {
    border: none !important;
  }
  & .MuiInput-root:after {
    border: none !important;
  }
`;
export const StyledTitleInputEndornment = styled(InputAdornment)`
  && {
    margin-left: 0.8rem;
    justify-content: center;
    align-items: center;
  }
`;

export const StyledQaListAssigneeDisplayer = styled.div<{}>`
  width: calc(100% - 16px);
  background-color: ${(p) => p.theme.color.primaryContainerColor};

  padding: 5px 8px;
  border: 1px solid
    ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.theme.color.primaryDividerColor,
        alpha: "0.5",
      })};
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  color: ${(p) => p.theme.color.primaryFontColor};
  font-size: 16px;
  font-family: ${(p) => p.theme.font.primaryFont};
  font-style: normal;
  font-weight: 500;
  line-height: 110%;
  text-transform: capitalize;

  .null-text {
    color: ${(p) => p.theme.color.primaryInputPlaceholderColor};
    font-size: 16px;

    font-family: ${(p) => p.theme.font.primaryFont};
    font-style: normal;
    font-weight: 500;
    line-height: 110%;
  }
`;
