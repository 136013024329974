import { useDoxleCurrentContextStore } from "../../DoxleGeneralStore/useDoxleCurrentContext";
import { useQueryClient } from "@tanstack/react-query";
import {
  basePricebookListQKey,
  baseSupplierRateListQKey,
  getPricebookDetailQKey,
} from "./PricebookQueryAPI";
import { produce } from "immer";
import {
  IApiPaginatedData,
  DefiniteAxiosQueryData,
} from "../../Models/axiosReturn";
import {
  PricebookItem,
  PricebookSupplierRate,
  PricebookSwatch,
} from "../Models/Pricebook";
import { useShallow } from "zustand/react/shallow";

interface props {
  itemId: string;
}

const useSetPricebookQueryData = ({ itemId }: props) => {
  const company = useDoxleCurrentContextStore(
    useShallow((state) => state.currentCompany)
  );
  const queryClient = useQueryClient();

  //*<--- query keys related --->
  //pricebook item details
  const detailQKey = getPricebookDetailQKey(itemId);

  //pricebook rate list
  const pricebookRateListMainQkey = company
    ? baseSupplierRateListQKey.concat(company?.companyId)
    : baseSupplierRateListQKey;

  //pricebook item list
  const pricebookItemListMainQkey = company
    ? basePricebookListQKey.concat(company?.companyId)
    : basePricebookListQKey;

  const handleRemovePricebookItemImage = (swatchId: string) => {
    const dataPricebookCardListCacheActive = queryClient
      .getQueryCache()
      .findAll({
        predicate: (query) =>
          pricebookItemListMainQkey.every((key) =>
            query.queryKey.includes(key)
          ) && query.isActive(),
      });
    const dataPricebookCardListCacheInactive = queryClient
      .getQueryCache()
      .findAll({
        predicate: (query) =>
          pricebookItemListMainQkey.every((key) =>
            query.queryKey.includes(key)
          ) && !query.isActive(),
      });
    //update pricebook item detail in dialog
    queryClient.setQueryData<DefiniteAxiosQueryData<PricebookItem>>(
      detailQKey,
      (data) => {
        if (data) {
          return produce(data, (draft) => {
            draft.data.swatches = draft.data.swatches.filter(
              (item) => item.swatchId !== swatchId
            );
            return draft;
          });
        } else queryClient.invalidateQueries(detailQKey);
      }
    );

    //optimistic update pricebook item in card list view which are active
    dataPricebookCardListCacheActive.forEach((query) => {
      queryClient.setQueryData<
        DefiniteAxiosQueryData<IApiPaginatedData<PricebookItem>>
      >(query.queryKey, (data) => {
        if (data) {
          return produce(data, (draft) => {
            const pricebookCardItem = draft.data.results.find((item) =>
              item.swatches.find((item) => item.swatchId === swatchId)
            );
            if (pricebookCardItem)
              pricebookCardItem.swatches = pricebookCardItem.swatches.filter(
                (item) => item.swatchId !== swatchId
              );
            return draft;
          });
        } else queryClient.invalidateQueries(query.queryKey);
      });
    });

    //remove all the queries which are not active
    dataPricebookCardListCacheInactive.forEach((query) => {
      queryClient.removeQueries(query.queryKey);
    });
  };

  const handleAddPricebookItemImage = (newItem: PricebookSwatch[]) => {
    //update pricebook item detail in dialog
    queryClient.setQueryData<DefiniteAxiosQueryData<PricebookItem>>(
      detailQKey,
      (data) => {
        if (data) {
          return produce(data, (draft) => {
            draft.data.swatches.concat(newItem);
            return draft;
          });
        } else queryClient.removeQueries(detailQKey);
      }
    );

    const dataPricebookCardListCacheActive = queryClient
      .getQueryCache()
      .findAll({
        predicate: (query) =>
          pricebookItemListMainQkey.every((key) =>
            query.queryKey.includes(key)
          ) && query.isActive(),
      });
    const dataPricebookCardListCacheInactive = queryClient
      .getQueryCache()
      .findAll({
        predicate: (query) =>
          pricebookItemListMainQkey.every((key) =>
            query.queryKey.includes(key)
          ) && !query.isActive(),
      });
    //optimistic update pricebook item in card list view which are active
    dataPricebookCardListCacheActive.forEach((query) => {
      queryClient.setQueryData<
        DefiniteAxiosQueryData<IApiPaginatedData<PricebookItem>>
      >(query.queryKey, (data) => {
        if (data) {
          return produce(data, (draft) => {
            const pricebookCardItem = draft.data.results.find(
              (item) => item.pricebookId === itemId
            );
            if (pricebookCardItem) pricebookCardItem.swatches.concat(newItem);
            return draft;
          });
        } else queryClient.invalidateQueries(query.queryKey);
      });
    });

    //remove all the queries which are not active
    dataPricebookCardListCacheInactive.forEach((query) => {
      queryClient.removeQueries(query.queryKey);
    });
  };

  const handleUpdatePricebookItem = (updated: PricebookItem) => {
    queryClient.setQueryData<DefiniteAxiosQueryData<PricebookItem>>(
      detailQKey,
      (data) => {
        if (data) {
          console.log(data);
          return produce(data, (draft) => {
            Object.assign(draft.data, updated);
            return draft;
          });
        } else queryClient.invalidateQueries(detailQKey);
      }
    );

    //* update card list
    const dataPricebookCardListCacheActive = queryClient
      .getQueryCache()
      .findAll({
        predicate: (query) =>
          pricebookItemListMainQkey.every((key) =>
            query.queryKey.includes(key)
          ) && query.isActive(),
      });
    const dataPricebookCardListCacheInactive = queryClient
      .getQueryCache()
      .findAll({
        predicate: (query) =>
          pricebookItemListMainQkey.every((key) =>
            query.queryKey.includes(key)
          ) && !query.isActive(),
      });
    //optimistic update pricebook item in card list view which are active
    dataPricebookCardListCacheActive.forEach((query) => {
      queryClient.setQueryData<
        DefiniteAxiosQueryData<IApiPaginatedData<PricebookItem>>
      >(query.queryKey, (data) => {
        if (data) {
          return produce(data, (draft) => {
            const pricebookCardItem = draft.data.results.find(
              (item) => item.pricebookId === updated.pricebookId
            );
            if (pricebookCardItem) Object.assign(pricebookCardItem, updated);
            return draft;
          });
        } else queryClient.invalidateQueries(query.queryKey);
      });
    });

    //remove all the queries which are not active
    dataPricebookCardListCacheInactive.forEach((query) => {
      queryClient.removeQueries(query.queryKey);
    });
    //* <--- end update card list --->

    //* update rate list
    const dataPricebookRateListCacheActive = queryClient
      .getQueryCache()
      .findAll({
        predicate: (query) =>
          pricebookRateListMainQkey.every((key) =>
            query.queryKey.includes(key)
          ) && query.isActive(),
      });
    const dataPricebookRateListCacheInactive = queryClient
      .getQueryCache()
      .findAll({
        predicate: (query) =>
          pricebookRateListMainQkey.every((key) =>
            query.queryKey.includes(key)
          ) && !query.isActive(),
      });

    //optimistic update pricebook rate list view which are active
    dataPricebookRateListCacheActive.forEach((query) => {
      queryClient.setQueryData<
        DefiniteAxiosQueryData<IApiPaginatedData<PricebookSupplierRate>>
      >(query.queryKey, (data) => {
        if (data) {
          return produce(data, (draft) => {
            updated.supplierRates.forEach((rate) => {
              const index = draft.data.results.findIndex(
                (item) => item.rateId === rate.rateId
              );
              if (index !== -1) {
                draft.data.results[index] = rate;
              } else {
                //find the position of the last rate of the same pricebook item
                const lastRateIndex = draft.data.results.findLastIndex(
                  (item) => item.pricebookItem === rate.pricebookItem
                );
                draft.data.results.splice(lastRateIndex + 1, 0, rate); //insert the new rate after the last rate of the same pricebook item
              }
            });
            return draft;
          });
        } else queryClient.invalidateQueries(query.queryKey);
      });
    });

    //remove all the queries which are not active
    dataPricebookRateListCacheInactive.forEach((query) => {
      queryClient.removeQueries(query.queryKey);
    });
  };

  const handleUpdatePricebookItemSuppier = (updated: PricebookSupplierRate) => {
    //update pricebook item detail in dialog
    queryClient.setQueryData<DefiniteAxiosQueryData<PricebookItem>>(
      detailQKey,
      (data) => {
        if (data) {
          return produce(data, (draft) => {
            const updatedRate = draft.data.supplierRates.find(
              (item) => item.rateId === updated.rateId
            );
            if (updatedRate) Object.assign(updatedRate, updated);
            return draft;
          });
        } else queryClient.invalidateQueries(detailQKey);
      }
    );
  };

  const handleRemovePricebookItem = (deletedId: string) => {
    const filterDetail = getPricebookDetailQKey(deletedId);
    queryClient.removeQueries(filterDetail);

    //* update card list
    const dataPricebookCardListCacheActive = queryClient
      .getQueryCache()
      .findAll({
        predicate: (query) =>
          pricebookItemListMainQkey.every((key) =>
            query.queryKey.includes(key)
          ) && query.isActive(),
      });
    const dataPricebookCardListCacheInactive = queryClient
      .getQueryCache()
      .findAll({
        predicate: (query) =>
          pricebookItemListMainQkey.every((key) =>
            query.queryKey.includes(key)
          ) && !query.isActive(),
      });
    //optimistic update pricebook item in card list view which are active
    dataPricebookCardListCacheActive.forEach((query) => {
      queryClient.setQueryData<
        DefiniteAxiosQueryData<IApiPaginatedData<PricebookItem>>
      >(query.queryKey, (data) => {
        if (data) {
          return produce(data, (draft) => {
            draft.data.results = draft.data.results.filter(
              (item) => item.pricebookId !== deletedId
            );
            return draft;
          });
        } else queryClient.invalidateQueries(query.queryKey);
      });
    });

    //remove all the queries which are not active
    dataPricebookCardListCacheInactive.forEach((query) => {
      queryClient.removeQueries(query.queryKey);
    });
    //* <--- end update card list --->

    //* update rate list
    const dataPricebookRateListCacheActive = queryClient
      .getQueryCache()
      .findAll({
        predicate: (query) =>
          pricebookRateListMainQkey.every((key) =>
            query.queryKey.includes(key)
          ) && query.isActive(),
      });
    const dataPricebookRateListCacheInactive = queryClient
      .getQueryCache()
      .findAll({
        predicate: (query) =>
          pricebookRateListMainQkey.every((key) =>
            query.queryKey.includes(key)
          ) && !query.isActive(),
      });

    //optimistic update pricebook rate list view which are active
    dataPricebookRateListCacheActive.forEach((query) => {
      queryClient.setQueryData<
        DefiniteAxiosQueryData<IApiPaginatedData<PricebookSupplierRate>>
      >(query.queryKey, (data) => {
        if (data) {
          return produce(data, (draft) => {
            draft.data.results = draft.data.results.filter(
              (item) => item.pricebookItem !== deletedId
            );
            return draft;
          });
        } else queryClient.invalidateQueries(query.queryKey);
      });
    });

    //remove all the queries which are not active
    dataPricebookRateListCacheInactive.forEach((query) => {
      queryClient.removeQueries(query.queryKey);
    });
  };

  const handleRemovePricebookRate = (deletedId: string) => {
    //update pricebook item detail in dialog
    queryClient.setQueryData<DefiniteAxiosQueryData<PricebookItem>>(
      detailQKey,
      (data) => {
        if (data) {
          return produce(data, (draft) => {
            draft.data.supplierRates = draft.data.supplierRates.filter(
              (item) => item.rateId !== deletedId
            );
            return draft;
          });
        } else queryClient.invalidateQueries(detailQKey);
      }
    );

    //* update card list
    const dataPricebookCardListCacheActive = queryClient
      .getQueryCache()
      .findAll({
        predicate: (query) =>
          pricebookItemListMainQkey.every((key) =>
            query.queryKey.includes(key)
          ) && query.isActive(),
      });
    const dataPricebookCardListCacheInactive = queryClient
      .getQueryCache()
      .findAll({
        predicate: (query) =>
          pricebookItemListMainQkey.every((key) =>
            query.queryKey.includes(key)
          ) && !query.isActive(),
      });
    //optimistic update pricebook item in card list view which are active
    dataPricebookCardListCacheActive.forEach((query) => {
      queryClient.setQueryData<
        DefiniteAxiosQueryData<IApiPaginatedData<PricebookItem>>
      >(query.queryKey, (data) => {
        if (data) {
          return produce(data, (draft) => {
            const pricebookCardItem = draft.data.results.find(
              (item) => item.pricebookId === itemId
            );
            if (pricebookCardItem)
              pricebookCardItem.supplierRates =
                pricebookCardItem.supplierRates.filter(
                  (item) => item.rateId !== deletedId
                );
            return draft;
          });
        } else queryClient.invalidateQueries(query.queryKey);
      });
    });

    //remove all the queries which are not active
    dataPricebookCardListCacheInactive.forEach((query) => {
      queryClient.removeQueries(query.queryKey);
    });
    //* <--- end update card list --->

    //* update rate list
    const dataPricebookRateListCacheActive = queryClient
      .getQueryCache()
      .findAll({
        predicate: (query) =>
          pricebookRateListMainQkey.every((key) =>
            query.queryKey.includes(key)
          ) && query.isActive(),
      });
    const dataPricebookRateListCacheInactive = queryClient
      .getQueryCache()
      .findAll({
        predicate: (query) =>
          pricebookRateListMainQkey.every((key) =>
            query.queryKey.includes(key)
          ) && !query.isActive(),
      });

    //optimistic update pricebook rate list view which are active
    dataPricebookRateListCacheActive.forEach((query) => {
      queryClient.setQueryData<
        DefiniteAxiosQueryData<IApiPaginatedData<PricebookSupplierRate>>
      >(query.queryKey, (data) => {
        if (data) {
          if (data.data.results.find((rate) => rate.rateId === deletedId))
            return produce(data, (draft) => {
              draft.data.results = draft.data.results.filter(
                (item) => item.rateId !== deletedId
              );
              return draft;
            });
        } else queryClient.invalidateQueries(query.queryKey);
      });
    });

    //remove all the queries which are not active
    dataPricebookRateListCacheInactive.forEach((query) => {
      queryClient.removeQueries(query.queryKey);
    });
  };

  return {
    handleRemovePricebookItemImage,
    handleUpdatePricebookItem,
    handleRemovePricebookItem,
    handleRemovePricebookRate,
    handleUpdatePricebookItemSuppier,
    handleAddPricebookItemImage,
  };
};

export default useSetPricebookQueryData;
