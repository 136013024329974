import React from "react";
import useSelectSharedAssignee from "./Hooks/useSelectSharedAssignee";
import { StyledSelectSharedAssignee } from "./StyledComponentQASharePdf";
import { useDoxleThemeStore } from "../../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import { Contact } from "../../../../Models/addressBook";
import SelectAssigneeDropdown from "../CommonComponents/SelectAssigneeDropdown";
import { FaChevronDown } from "react-icons/fa6";
import { IoCloseCircleSharp } from "react-icons/io5";
import { useShallow } from "zustand/react/shallow";
type Props = {
  handleGenerateAssigneePdf: (assignee?: Contact | undefined) => void;
};

const SelectSharedAssignee = ({ handleGenerateAssigneePdf }: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    useShallow((state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }))
  );
  const {
    anchorAssigneeList,
    setAnchorAssigneeList,
    selectedAssignee,
    setselectedAssignee,
    handleCloseAssigneeList,
  } = useSelectSharedAssignee({});

  const onSelectAssignee = (contact: Contact) => {
    setselectedAssignee(contact);
    handleGenerateAssigneePdf(contact);
    handleCloseAssigneeList();
  };
  const onClickRemoveAssignee = () => {
    handleGenerateAssigneePdf();
    setselectedAssignee(undefined);
  };
  return (
    <>
      <StyledSelectSharedAssignee
        $isNull={Boolean(!selectedAssignee)}
        onClick={(e) => setAnchorAssigneeList(e.currentTarget)}
      >
        <span className="assignee-text">
          {selectedAssignee
            ? `${selectedAssignee.firstName} ${selectedAssignee.lastName}`
            : "Select Assignee to share"}
        </span>

        {selectedAssignee && (
          <IoCloseCircleSharp
            className="icon"
            size="16px"
            onClick={(e) => {
              e.stopPropagation();
              onClickRemoveAssignee();
            }}
          />
        )}
        <FaChevronDown size="1.4rem" className="icon" />
      </StyledSelectSharedAssignee>

      <SelectAssigneeDropdown
        anchorDropdown={anchorAssigneeList}
        onCloseDropdown={handleCloseAssigneeList}
        selectedAssigneeId={selectedAssignee?.contactId}
        onSelectAssignee={onSelectAssignee}
      />
    </>
  );
};

export default SelectSharedAssignee;
