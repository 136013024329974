import {nanoid} from "nanoid";
import {DoxLine, XY} from "../Models/takeOffs";
import {toFloat} from "../konvaFunctions/generalFunctions";
import {useMeasurementsStore} from "../MeasurementsStore/useMeasurementsStore";
import {shallow} from "zustand/shallow";
import {add} from "lodash";

interface RectangleProps {}
const useLine = ({}: RectangleProps) => {
  const {
    currentShape,
    setCurrentShape,
    deduction,
    currentColor,
    currentUnit,
    zHeight,
    currentSheet,
    currentTakeOff,
    addMeasurement,
    scalingMode,
    setScaleLine,
    scaleLine
  } = useMeasurementsStore(state => ({
    currentShape: state.currentShape,
    setCurrentShape: state.setCurrentShape,
    deduction: state.deduction,
    currentColor: state.currentColor,
    currentUnit: state.currentUnit,
    zHeight: state.zHeight,
    currentSheet: state.currentSheet,
    currentTakeOff: state.currentTakeOff,
    addMeasurement: state.addMeasurement,
    scalingMode: state.isScaling,
    setScaleLine: state.setScaleLine,
    scaleLine: state.scaleLine
  }), shallow)
  const scale: number = toFloat(currentSheet?.scale)

  const mouseMove = (pos: XY) => {
    const currentLine = currentShape as DoxLine;
    let rawValue: number = Math.sqrt(
      Math.pow(toFloat(currentLine.start.x) - pos.x, 2) +
      Math.pow(toFloat(currentLine.start.y) - pos.y, 2)
    );
    setCurrentShape({
      ...currentLine,
      end: { x: pos.x, y: pos.y },
      rawValue: scalingMode ? rawValue : rawValue * deduction,
      scaledValue: scalingMode ? rawValue : rawValue * deduction * scale,
      actualValue: scalingMode
        ? rawValue * toFloat(currentLine.rawValue) * scale
        : currentUnit === "LM"
          ? rawValue * toFloat(currentLine.rawValue) * scale * deduction
          : rawValue * toFloat(currentLine.rawValue) * scale * deduction * toFloat(currentLine.zMultiplier),
    } as DoxLine);
  }

  const mouseUp = (pos: XY) => {
    if (!currentSheet?.sheetId) return
    if (scalingMode && scaleLine) return
    if (!currentShape) {
      setCurrentShape({
        borderColor: deduction < 0 && !scalingMode ? "#000" : currentColor,
        bgColor: deduction < 0 && !scalingMode ? "#000" : currentColor,
        borderThickness: 2,
        shape: "line",
        id: nanoid(),
        zMultiplier: currentUnit === "M2" && zHeight ? zHeight : 1.0,
        rawValue: 0.0,
        scaledValue: 0.0,
        actualValue: 0.0,
        sheet: currentSheet?.sheetId,
        takeOff: currentTakeOff?.takeOffId,
        start: {x: pos.x, y: pos.y},
        end: {x: pos.x, y: pos.y},
      });
    } else {
      const currentLine = currentShape as DoxLine;
      setCurrentShape(null);
      let rawValue: number =
        Math.sqrt(
          Math.pow(toFloat(currentLine.start.x) - pos.x, 2) +
          Math.pow(toFloat(currentLine.start.y) - pos.y, 2)
        ) * deduction;
      const finishedShape: DoxLine = {
        ...currentLine,
        end: {x: pos.x, y: pos.y},
        rawValue: rawValue,
        scaledValue: rawValue * scale,
        actualValue: rawValue * toFloat(currentLine.zMultiplier) * scale,
      } as DoxLine;
      if (scalingMode) {
        setScaleLine(finishedShape);
        setCurrentShape(null);
      }
      else addMeasurement(finishedShape);
    }
  }

  return {mouseMove, mouseUp}
}

export default useLine