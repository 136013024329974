import React, { useState } from "react";
import { PreviewedDrawingSet, useDrawingStore } from "../Store/useDrawingStore";
import { pdfjs } from "react-pdf";
import { shallow } from "zustand/shallow";

type Props = {
  previewedDrawingSet: PreviewedDrawingSet;
};

interface DrawingsPreviewPage {
  backdropDisplay: boolean;
  currentIndex: number;
  setCurrentIndex: React.Dispatch<React.SetStateAction<number>>;
  setBackdropDisplay: React.Dispatch<React.SetStateAction<boolean>>;
  handleClosePreviewScreen: () => void;
  handleClickPrevBtn: () => void;
  isDisablePrevBtn: boolean;
  isDisableNextBtn: boolean;
  isCurrentImgErr: boolean;
  setIsCurrentImgErr: React.Dispatch<React.SetStateAction<boolean>>;
  handleClickNextBtn: () => void;
}
const useDrawingsPreviewPage = ({
  previewedDrawingSet,
}: Props): DrawingsPreviewPage => {
  const [currentIndex, setCurrentIndex] = useState<number>(
    previewedDrawingSet.currentIndex ?? 0
  );
  const [backdropDisplay, setBackdropDisplay] = useState<boolean>(false);
  const [isCurrentImgErr, setIsCurrentImgErr] = useState<boolean>(false);

  const { setPreviewedDrawingSet } = useDrawingStore(
    (state) => ({
      setPreviewedDrawingSet: state.setPreviewedDrawingSet,
    }),
    shallow
  );

  const handleClosePreviewScreen = () => setPreviewedDrawingSet(undefined);

  const handleClickPrevBtn = () => {
    if (currentIndex !== 0) {
      setCurrentIndex((prev) => prev - 1);
      setIsCurrentImgErr(false);
    }
  };
  const handleClickNextBtn = () => {
    if (currentIndex !== previewedDrawingSet.drawingSet.sheets.length - 1){
      setCurrentIndex((prev) => prev + 1);
      setIsCurrentImgErr(false);
    }
  };
  const isDisablePrevBtn = currentIndex === 0;
  const isDisableNextBtn =
    currentIndex === previewedDrawingSet.drawingSet.sheets.length - 1;
  return {
    backdropDisplay,
    currentIndex,
    setCurrentIndex,
    setBackdropDisplay,
    handleClosePreviewScreen,
    handleClickPrevBtn,
    isDisablePrevBtn,
    isDisableNextBtn,
    handleClickNextBtn,
    isCurrentImgErr,
    setIsCurrentImgErr,
  };
};

export default useDrawingsPreviewPage;
