import Switch from "@mui/material/Switch";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import { usePricebookStore } from "../Store/usePricebookStore";
import { useShallow } from "zustand/react/shallow";

type Props = {};

const PricebookViewSwitch = (props: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore((state) => ({
    doxleThemeColor: state.doxleThemeColor,
    doxleFont: state.doxleFont,
  }));
  const { view, setView } = usePricebookStore(
    useShallow((state) => ({
      view: state.view,
      setView: state.setView,
    }))
  );
  const isChecked = view === "Table";
  return (
    <Switch
      checked={isChecked}
      onChange={(e, checked) => {
        if (checked) setView("Table");
        else setView("List");
      }}
      sx={{
        padding: "8px",
        width: "90px",
        height: "38px",
        "& .MuiSwitch-track": {
          width: "100%",
          backgroundColor: doxleThemeColor.doxleColor,
          borderRadius: 22 / 2,
          opacity: 1,
          "&::before, &::after": {
            content: '""',
            position: "absolute",
            top: "50%",
            transform: "translateY(-50%)",
            width: "65px",
            height: "30px",
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
            fontSize: "14px",
            lineHeight: "14px",
            color: "white",
            fontFamily: doxleFont.primaryFont,
            pointerEvents: "none",
          },
          "&::before": {
            content: isChecked ? `"Table"` : `""`,

            left: 8,
          },
          "&::after": {
            content: !isChecked ? `"Card"` : `""`,

            right: 8,
          },
          "&.Mui-checked": {
            top: "50%",
            transform: "translate(64px, -50%)",
            color: "white",
          },
        },
        "& .MuiSwitch-thumb": {
          boxShadow: "none",
          width: "17px",
          height: "17px",
          margin: "2px",
        },
        "& .MuiSwitch-switchBase": {
          padding: "0px",
          "&.Mui-checked": {
            top: "50%",
            transform: "translate(60px, -50%)",
            color: "white",
          },
          transform: "translate(9px, -50%)",
          top: "50%",
        },
        "& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track": {
          backgroundColor: doxleThemeColor.doxleColor,
          opacity: 1,
        },
      }}
    />
  );
};

export default PricebookViewSwitch;
