import styled from "styled-components";
import {
  DoxleFont,
  DoxleThemeColor,
  useDoxleThemeStore,
} from "../../DoxleGeneralStore/useDoxleThemeStore";
import { TextField, TextFieldProps } from "@mui/material";

interface Props extends Omit<TextFieldProps, "variant" | "multiline"> {
  // customStyle?: IMultilineTextFieldStyle;
}

export default function DoxleMultilineTextField({ ...props }: Props) {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore((state) => ({
    doxleThemeColor: state.doxleThemeColor,
    doxleFont: state.doxleFont,
  }));

  return (
    <StyledMutilineTextField
      $themeColor={doxleThemeColor}
      $doxleFont={doxleFont}
      // $customStyle={customStyle}
      variant="standard"
      multiline
      {...props}
    />
  );
}

export const StyledMutilineTextField = styled(TextField)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
  // $customStyle?: IMultilineTextFieldStyle;
}>`
  && {
    width: 100%;
    max-width: 100%;
    min-width: 0px;
    height: 100%;
    min-height: 0px;

    border-radius: 0px;
    background-color: ${(p) => p.$themeColor.primaryTextFieldBackgroundColor};
    border: none;
    margin: 0px;
  }
  & .MuiOutlinedInput-root {
    padding: 0px !important;
  }

  textarea {
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-weight: 400;
    font-size: 14px;
    line-height: normal;

    color: ${(p) => p.$themeColor.primaryFontColor};
    padding: 0 10px;
    &::placeholder {
      font-family: ${(p) => p.$doxleFont.primaryFont};
      font-weight: 400;
      font-size: 14px;
      line-height: normal;
      color: ${(p) => p.$themeColor.primaryInputPlaceholderColor};
      padding: 0px !important;
      opacity: 1;
    }
    &::-webkit-scrollbar {
      display: none;
    }
  }
  & .MuiInput-root:after {
    border-bottom: none !important;
  }
  & .MuiInput-root:before {
    border-bottom: none !important;
  }
  & .MuiInput-root {
    border-bottom: none !important;
  }
  & .MuiInput-root:hover:not(.Mui-disabled, .Mui-error):before {
    border-bottom: none !important;
  }
  & .MuiInputBase-root {
    width: 100%;
  }
`;
