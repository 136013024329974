import {
  Dispatch,
  RefObject,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";

import { ScopeOfWorkItem } from "../Model/scopeOfWorks";
import { UpdateScopeItemData } from "../QueryAPI/scopeOfWorkQueryAPI";
import { useScopeOfWorkStore } from "../Store/useScopeOfWorkStore";
import { shallow } from "zustand/shallow";

interface Props {
  scopeItem: ScopeOfWorkItem;
  setDeletedScopeItem: React.Dispatch<
    React.SetStateAction<ScopeOfWorkItem | undefined>
  >;
  handleUpdateScopeItem: (data: UpdateScopeItemData) => void;
}

interface ScopeItemRow {
  setShowAddScopeImageSection: React.Dispatch<React.SetStateAction<boolean>>;

  showAddScopeImageSection: boolean;
  handleCheckboxScopeItem: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleCloseAddScopeImgSection: () => void;
  handleClickAddSpecImageBtn: () => void;
  handleClickDeleteButton: () => void;

  isScopeItemSelected: boolean;

  newItemDescription: string;
  setNewItemDescription: Dispatch<SetStateAction<string>>;
  onBlurTextField: () => void;
  isFocusTextfield: boolean;
  setIsFocusTextfield: Dispatch<SetStateAction<boolean>>;
  descriptionRef: RefObject<HTMLInputElement>;
  onKeyDownDescriptionField: (
    event: React.KeyboardEvent<HTMLDivElement>
  ) => void;
  isGroupExpanded: boolean;
  showHoveringMenu: boolean;
  setShowHoveringMenu: Dispatch<SetStateAction<boolean>>;
}
export const useScopeItemRow = ({
  scopeItem,
  setDeletedScopeItem,
  handleUpdateScopeItem,
}: Props): ScopeItemRow => {
  const [showAddScopeImageSection, setShowAddScopeImageSection] =
    useState<boolean>(false);

  const [newItemDescription, setNewItemDescription] = useState(
    scopeItem.scopeItemDescription
  );
  const [isFocusTextfield, setIsFocusTextfield] = useState(false);
  const [showHoveringMenu, setShowHoveringMenu] = useState(false);
  const { expandedSOWGroupIds } = useScopeOfWorkStore(
    (state) => ({
      expandedSOWGroupIds: state.expandedSOWGroupIds,
    }),
    shallow
  );

  const isScopeItemSelected: boolean = false;

  const handleClickDeleteButton = () => setDeletedScopeItem(scopeItem);

  const handleCheckboxScopeItem = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    handleUpdateScopeItem({
      scopeItemId: scopeItem.scopeItemId,
      completed: event.target.checked,
    });
  };

  const handleClickAddSpecImageBtn = () => {
    setShowAddScopeImageSection(true);
  };

  const handleCloseAddScopeImgSection = () => {
    setShowAddScopeImageSection(false);
  };

  const onBlurTextField = () => {
    if (!newItemDescription)
      setNewItemDescription(scopeItem.scopeItemDescription);
    else if (newItemDescription !== scopeItem.scopeItemDescription) {
      handleUpdateScopeItem({
        scopeItemId: scopeItem.scopeItemId,
        scopeItemDescription: newItemDescription,
      });
    }
    setIsFocusTextfield(false);
  };
  const descriptionRef = useRef<HTMLInputElement>(null);
  const onKeyDownDescriptionField = (
    event: React.KeyboardEvent<HTMLDivElement>
  ) => {
    if (
      (event.nativeEvent.key === "Enter" &&
        !event.shiftKey &&
        !event.ctrlKey) ||
      event.nativeEvent.key === "Escape"
    ) {
      event.preventDefault();
      descriptionRef.current?.blur();
      setIsFocusTextfield(false);
    }
  };

  const isGroupExpanded = expandedSOWGroupIds.includes(scopeItem.scopeGroup);

  //toggle when group expanded
  useEffect(() => {
    if (!isGroupExpanded) setShowAddScopeImageSection(false);
  }, [isGroupExpanded]);

  return {
    setShowAddScopeImageSection,

    showAddScopeImageSection,
    handleCheckboxScopeItem,
    handleCloseAddScopeImgSection,
    handleClickAddSpecImageBtn,
    handleClickDeleteButton,

    isScopeItemSelected,

    newItemDescription,
    setNewItemDescription,
    onBlurTextField,
    isFocusTextfield,
    setIsFocusTextfield,
    descriptionRef,
    onKeyDownDescriptionField,
    isGroupExpanded,
    showHoveringMenu,
    setShowHoveringMenu,
  };
};
