import React, { useCallback, useEffect, useMemo, useState } from "react";

import { shallow } from "zustand/shallow";
import { QA, QAList, QAWithFirstImg } from "../../../../Models/qa";
import { useProjectQAStore } from "../../../Store/useProjectQAStore";
import useDoxleNotificationStore from "../../../../../DoxleGeneralStore/useDoxleNotificationStore";
import { useDoxleAuthStore } from "../../../../../DoxleGeneralStore/useDoxleAuthStore";
import { useDoxleCurrentContextStore } from "../../../../../DoxleGeneralStore/useDoxleCurrentContext";
import QAQueryAPI from "../../../../QueryAPI/qaQueryAPI";

type Props = {};
interface QAItemList {
  qaItemList: QAWithFirstImg[];
  isFetchingQAItemList: boolean;
  isSuccessFetchingQAItemList: boolean;
  isErrorFetchingQAItemList: boolean;
  hasNextPage: boolean;
  handleFetchNextPage: () => void;
  isFetchingNextPage: boolean;
  handleRefetchQAList: () => void;
  isRefetchingQAList: boolean;
}
const useGetQAItemList = ({}: Props): QAItemList => {
  const { selectedQAList, selectedQAListStatus, filter } = useProjectQAStore(
    (state) => ({
      selectedQAList: state.selectedQAList,
      selectedQAListStatus: state.selectedQAListStatus,
      filter: state.filterQAItemQuery,
    }),
    shallow
  );
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );
  const { company, currentSearchText } = useDoxleCurrentContextStore(
    (state) => ({
      company: state.currentCompany,
      currentSearchText: state.currentSearchText,
    }),
    shallow
  );

  const getQAItemListQuery = QAQueryAPI.useRetrieveQAItemsQuery({
    showNotification,
    company,
    defectListId: selectedQAList?.defectListId ?? "",
    search: currentSearchText,
    enableQuery: Boolean(selectedQAList),
    filter
  });

  const qaItemList = useMemo(
    () =>
      getQAItemListQuery.isSuccess
        ? getQAItemListQuery.data.pages
            .reduce((acc, data) => {
              return acc.concat(data.data.results);
            }, [] as QAWithFirstImg[])
            .filter((item) =>
              Boolean(
                (selectedQAListStatus === "completed" &&
                  item.status === "Completed") ||
                  (selectedQAListStatus === "unattended" &&
                    item.status !== "Completed")
              )
            )
        : [],
    [getQAItemListQuery.data, selectedQAListStatus]
  );

  const handleRefetchQAList = () => {
    getQAItemListQuery.refetch();
  };
  const handleFetchNextPage = () => {
    if (getQAItemListQuery.hasNextPage) getQAItemListQuery.fetchNextPage();
  };
  return {
    qaItemList,
    isFetchingQAItemList: getQAItemListQuery.isLoading,
    isSuccessFetchingQAItemList: getQAItemListQuery.isSuccess,
    isErrorFetchingQAItemList: getQAItemListQuery.isError,
    hasNextPage: Boolean(getQAItemListQuery.hasNextPage),
    handleFetchNextPage,
    isFetchingNextPage: getQAItemListQuery.isFetchingNextPage,
    handleRefetchQAList,
    isRefetchingQAList: getQAItemListQuery.isRefetching,
  };
};

export default useGetQAItemList;
