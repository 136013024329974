import { StyledMenuShareUser } from "./StyledMenuShareUser";
import { useDoxleCurrentContextStore } from "../../../DoxleGeneralStore/useDoxleCurrentContext";
import { shallow } from "zustand/shallow";
import { useMemo } from "react";
import { generateUniqueNumbers } from "../../../Utilities/FunctionUtilities";
import useGetSharePermission from "../../QueryDataHooks/GetQueryDataHooks/useGetSharePermission";
import CircularProgress from "@mui/material/CircularProgress";
import {
  RANDOM_DOXLE_COLORS,
  useDoxleThemeStore,
} from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { BiErrorCircle } from "react-icons/bi";
import Tooltip from "@mui/material/Tooltip";
import { DoxleMUITooltipSx } from "../../../DoxleDesignPattern/MUIOverwriteSx";
import DoxlePermissionAvatar from "../../../DoxleDesignPattern/DoxlePermissionAvatar/DoxlePermissionAvatar";
import { useShallow } from "zustand/react/shallow";

type Props = {};

const MenuShareUser = (props: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }),
    shallow
  );
  const { currentProject } = useDoxleCurrentContextStore(
    useShallow((state) => ({
      currentProject: state.currentProject,
    }))
  );
  const { permissions, isFetchingPermissions, isErrorFetchingPermissions } =
    useGetSharePermission({ project: currentProject?.projectId });

  const autoColorsIdxArray = useMemo(
    () => generateUniqueNumbers(0, RANDOM_DOXLE_COLORS.length - 1, 6),
    []
  );

  return (
    <StyledMenuShareUser>
      {isFetchingPermissions && (
        <CircularProgress
          style={{ color: doxleThemeColor.primaryReverseFontColor }}
          size={20}
        />
      )}
      {!isFetchingPermissions && (
        <>
          {isErrorFetchingPermissions ? (
            <Tooltip
              PopperProps={{
                disablePortal: true,
                sx: DoxleMUITooltipSx(doxleThemeColor, doxleFont),
              }}
              title={"Unable to get shared users"}
            >
              <div>
                <BiErrorCircle color="red" size={20} />
              </div>
            </Tooltip>
          ) : (
            <>
              {permissions
                .filter((item) => item.contact)
                .filter((_, idx) => idx < 5)
                .map((item, idx) => (
                  <DoxlePermissionAvatar
                    permissionItem={item}
                    color={
                      RANDOM_DOXLE_COLORS[autoColorsIdxArray[idx]] ?? "#000"
                    }
                    key={idx}
                  />
                ))}

              {permissions.length > 5 && (
                <span className="plus-text">+{permissions.length - 5}</span>
              )}
            </>
          )}
        </>
      )}
    </StyledMenuShareUser>
  );
};

export default MenuShareUser;
