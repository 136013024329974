import { useQueryClient } from "@tanstack/react-query";

import { produce } from "immer";
import { FilterGetNoteQuery, getNoteListQKey } from "../QueryAPI/notesQueryAPI";
import { Note } from "../Models/note";
import { useDoxleCurrentContextStore } from "../../DoxleGeneralStore/useDoxleCurrentContext";
import { shallow } from "zustand/shallow";

type Props = {
  filter: FilterGetNoteQuery;
  appendPos?: "start" | "end";
  overwrite?: boolean;
};

interface SetNoteQueryDataQuery {
  handleAddNoteQueryData: (addedNote: Note) => void;
  handleDeleteNoteQueryData: (deletednoteId: string) => void;
  handleEditNoteQueryData: (edittedNote: Note) => void;
}
const useSetNoteQueryDataQuery = ({
  filter,
  appendPos = "end",
  overwrite = true,
}: Props): SetNoteQueryDataQuery => {
  const queryClient = useQueryClient();
  const { company } = useDoxleCurrentContextStore(
    (state) => ({ company: state.currentCompany }),
    shallow
  );
  const qKey = getNoteListQKey(filter, company);

  const handleAddNoteQueryData = (addedNote: Note) => {
    const oldServerData = queryClient.getQueryData(qKey);
    if (oldServerData && overwrite) {
      queryClient.setQueryData(qKey, (old: any) => {
        if (old) {
          const lengthOfPages = old.pages.length;
          return produce(old, (draftOld: any) => {
            draftOld.pages = produce(draftOld.pages, (draftPages: any) => {
              if (appendPos === "start") {
                (draftPages[0].data.results as Note[]).unshift(addedNote);
              } else {
                (draftPages[lengthOfPages - 1].data.results as Note[]).push(
                  addedNote
                );
              }

              return draftPages;
            });

            return draftOld;
          });
        } else return old;
      });
    } else
      queryClient.refetchQueries({
        type: "all",
        predicate: (query) =>
          qKey.every((item) => query.queryKey.includes(item)),
      });
  };
  const handleDeleteNoteQueryData = (deletednoteId: string) => {
    const oldServerData = queryClient.getQueryData(qKey);
    if (oldServerData && overwrite) {
      queryClient.setQueryData(qKey, (old: any) => {
        if (old) {
          //find page contain deleted item
          let pageContainGroup: number = (old.pages as Array<any>).findIndex(
            (page) =>
              (page.data.results as Note[]).find(
                (note) => note.noteId === deletednoteId
              )
          );
          if (pageContainGroup !== -1) {
            const targetOrderItemIndex = (
              (old.pages as Array<any>)[pageContainGroup].data.results as Note[]
            ).findIndex((note) => note.noteId === deletednoteId);
            return produce(old, (draftOld: any) => {
              draftOld.pages = produce(draftOld.pages, (draftPages: any) => {
                draftPages[pageContainGroup].data.results = produce(
                  draftPages[pageContainGroup].data.results,
                  (draftTargetPageData: Note[]) => {
                    draftTargetPageData.splice(targetOrderItemIndex, 1);

                    return draftTargetPageData;
                  }
                );
                return draftPages;
              });

              return draftOld;
            });
          } else queryClient.invalidateQueries(qKey);
        } else queryClient.invalidateQueries(qKey);
      });
    } else queryClient.invalidateQueries(qKey);
  };

  const handleEditNoteQueryData = (edittedNote: Note) => {
    const oldServerData = queryClient.getQueryData(qKey);

    if (oldServerData && overwrite) {
      queryClient.setQueryData(qKey, (old: any) => {
        if (old) {
          //find page contain deleted item
          let pageContainGroup: number = (old.pages as Array<any>).findIndex(
            (page) =>
              (page.data.results as Note[]).find(
                (note) => note.noteId === edittedNote.noteId
              )
          );
          if (pageContainGroup !== -1) {
            const targetOrderItemIndex = (
              (old.pages as Array<any>)[pageContainGroup].data.results as Note[]
            ).findIndex((note) => note.noteId === edittedNote.noteId);
            return produce(old, (draftOld: any) => {
              draftOld.pages[pageContainGroup].data.results = produce(
                draftOld.pages[pageContainGroup].data.results,
                (draftTargetPageData: Note[]) => {
                  const item = draftTargetPageData.find(
                    (note) => note.noteId === edittedNote.noteId
                  );
                  if (item) Object.assign(item, edittedNote);
                  return draftTargetPageData;
                }
              );

              return draftOld;
            });
          } else queryClient.invalidateQueries(qKey);
        } else queryClient.invalidateQueries(qKey);
      });
    } else queryClient.invalidateQueries(qKey);
  };
  return {
    handleAddNoteQueryData,
    handleDeleteNoteQueryData,
    handleEditNoteQueryData,
  };
};

export default useSetNoteQueryDataQuery;
