import { motion } from "framer-motion";
import { useLottie } from "lottie-react";
import styled from "styled-components";
// import animationData from "./lotties/doxle-loader.json"; // wagging tail
import animationData from "./lotties/addItem.json";

interface props {
  loadingMessage?: string;
  animationSize?: number;
  containerStyle?: React.CSSProperties;
  textStyle?: React.CSSProperties;
}
const StyledLottiesView = styled(motion.div)`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
`;
const StyledLoadingMessage = styled.span`
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.05em;

  color: #000000;
  position: absolute;

  left: 0;
  right: 0;
  text-align: center;
`;
const ScreenContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;

  align-items: center;
  justify-content: center;
  background-color: transparent;
  z-index: 10;
  flex-direction: column;
`;
const AddItemLoadingScreen: React.FC<props> = ({
  loadingMessage,
  animationSize,
  containerStyle,
  textStyle,
}) => {
  const options = {
    animationData: animationData,
    loop: true,
    autoplay: true,
  };

  const { View } = useLottie(options, {
    width: animationSize || 200,
    height: animationSize || 200,
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
  });
  //################## ANIMATION HANDILNG ########
  const lottieViewAnimatedVariants = {
    initial: {
      scale: 1,
    },
    animate: {
      scale: [0.9, 1, 0.9],
    },
  };
  return (
    <ScreenContainer style={{ ...containerStyle }}>
      <StyledLottiesView
        variants={lottieViewAnimatedVariants}
        initial="initial"
        animate="animate"
        transition={{ repeat: Infinity, repeatType: "loop", duration: 1 }}
      >
        {View}
      </StyledLottiesView>
      <StyledLoadingMessage
        style={{
          top: animationSize
            ? `calc(50% + ${14 + (1 / 2) * animationSize})`
            : `calc(50% + 114px)`,
          ...textStyle,
        }}
      >
        {loadingMessage ? loadingMessage : "Loading..."}
      </StyledLoadingMessage>
    </ScreenContainer>
  );
};

export default AddItemLoadingScreen;
