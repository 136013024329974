import { StateCreator, create } from "zustand";
import { produce } from "immer";
import {FilterGetQAItemQuery, FilterGetQAListQuery} from "../../QueryAPI/qaQueryAPI";
import {
  QA,
  QAImage,
  QAList,
  QAMarkupArrow,
  QAMarkupCircle,
  QAMarkupLabel,
  QAMarkupRectangle,
  QAMarkupStraightLine,
  QAWithFirstImg,
} from "../../Models/qa";

interface IQAImageMarkup {
  qaImage: QAImage;
  markupList: (
    | QAMarkupRectangle
    | QAMarkupCircle
    | QAMarkupStraightLine
    | QAMarkupLabel
    | QAMarkupArrow
  )[];
}
export type IQAListTableDisplayName =
  | "Title"
  | "Working"
  | "Completed"
  | "Assignee"
  | "Due Date";
export type IQAListDisplayField = {
  headerName: IQAListTableDisplayName;
  cellSize: `${number}%` | null;
};
export const QA_LIST_TABLE_FIELD: IQAListDisplayField[] = [
  {
    headerName: "Title",
    cellSize: "41%",
  },
  {
    headerName: "Working",
    cellSize: "12%",
  },
  {
    headerName: "Completed",
    cellSize: "12%",
  },
  {
    headerName: "Assignee",
    cellSize: "20%",
  },
  {
    headerName: "Due Date",
    cellSize: "15%",
  },
];
interface QAGeneralStoreValue {
  filterQAListQuery: FilterGetQAListQuery;
  setFilterQAListQuery: (filter: FilterGetQAListQuery) => void;

  filterQAItemQuery: FilterGetQAItemQuery;
  setFilterQAItemQuery: (filter: FilterGetQAItemQuery) => void;

  selectedQAList: QAList | undefined;
  setSelectedQAList: (item: QAList | undefined) => void;

  edittedQAListItem: QAList | undefined;
  setEdittedQAListItem: (item: QAList | undefined) => void;
  updateEdittedQAListItem: (data: Partial<QAList>) => void;

  selectedQAItem: QAWithFirstImg | undefined;
  setSelectedQAItem: (item: QAWithFirstImg | undefined) => void;
  updateSelectedQAItem: (data: Partial<QAWithFirstImg>) => void;

  sharedQAList: QAList | undefined;
  setSharedQAList: (item: QAList | undefined) => void;

  edittedSignatureQAList: QAList | undefined;
  setEdittedSignatureQAList: (item: QAList | undefined) => void;

  markupQAImage: IQAImageMarkup | undefined;
  setMarkupQAImage: (item: IQAImageMarkup | undefined) => void;

  selectedQAListStatus: "completed" | "unattended";
  setSelectedQAListStatus: (status: "completed" | "unattended") => void;
  resetGeneralQAStore: () => void;
}

const createQAStoreGeneralValue: StateCreator<
  QAGeneralStoreValue,
  [["zustand/immer", never], never],
  [],
  QAGeneralStoreValue
> = (set, get) => ({
  filterQAListQuery: {orderBy: ['completed', '-created_on']},
  setFilterQAListQuery: (filter: FilterGetQAListQuery) =>
    set((state) =>
      produce(state, (draftState) => {
        draftState.filterQAListQuery = {
          ...get().filterQAListQuery,
          ...filter,
        };

        return draftState;
      })
    ),

  filterQAItemQuery: {},
  setFilterQAItemQuery: (filter: FilterGetQAItemQuery) =>
    set((state) =>
      produce(state, (draftState) => {
        draftState.filterQAItemQuery = {
          ...get().filterQAItemQuery,
          ...filter,
        };

        return draftState;
      })
    ),

  selectedQAList: undefined,
  setSelectedQAList: (item: QAList | undefined) =>
    set((state) =>
      produce(state, (draftState) => {
        draftState.selectedQAList = item;

        return draftState;
      })
    ),

  edittedQAListItem: undefined,
  setEdittedQAListItem: (item: QAList | undefined) =>
    set((state) =>
      produce(state, (draftState) => {
        draftState.edittedQAListItem = item;

        return draftState;
      })
    ),
  updateEdittedQAListItem: (data: Partial<QAWithFirstImg>) =>
    set((state) =>
      produce(state, (draftState) => {
        if (draftState.edittedQAListItem)
          Object.assign(draftState.edittedQAListItem, data);

        return draftState;
      })
    ),

  selectedQAItem: undefined,
  setSelectedQAItem: (item: QAWithFirstImg | undefined) =>
    set((state) =>
      produce(state, (draftState) => {
        draftState.selectedQAItem = item;

        return draftState;
      })
    ),
  updateSelectedQAItem: (data: Partial<QAWithFirstImg>) =>
    set((state) =>
      produce(state, (draftState) => {
        if (draftState.selectedQAItem)
          Object.assign(draftState.selectedQAItem, data);

        return draftState;
      })
    ),

  sharedQAList: undefined,
  setSharedQAList: (item: QAList | undefined) =>
    set((state) =>
      produce(state, (draftState) => {
        draftState.sharedQAList = item;

        return draftState;
      })
    ),

  edittedSignatureQAList: undefined,
  setEdittedSignatureQAList: (item: QAList | undefined) =>
    set((state) =>
      produce(state, (draftState) => {
        draftState.edittedSignatureQAList = item;

        return draftState;
      })
    ),

  selectedQAListStatus: "unattended",
  setSelectedQAListStatus: (status: "completed" | "unattended") =>
    set((state) =>
      produce(state, (draftState) => {
        draftState.selectedQAListStatus = status;

        return draftState;
      })
    ),

  markupQAImage: undefined,
  setMarkupQAImage: (item: IQAImageMarkup | undefined) =>
    set((state) =>
      produce(state, (draftState) => {
        draftState.markupQAImage = item;

        return draftState;
      })
    ),

  resetGeneralQAStore: () =>
    set((state) =>
      produce(state, (draftState) => {
        draftState.filterQAListQuery = {orderBy: ['completed', '-created_on']};

        draftState.selectedQAItem = undefined;
        draftState.selectedQAList = undefined;
        return draftState;
      })
    ),
});

export const useProjectQAStore = create<QAGeneralStoreValue>()((...a) => ({
  ...createQAStoreGeneralValue(...a),
}));
