import React, { useMemo } from "react";
import { User } from "../../../Models/user";
import UserQueryAPI from "../../../Services/QueryHooks/userQueryAPI";
import { useDoxleCurrentContextStore } from "../../../DoxleGeneralStore/useDoxleCurrentContext";
import { useShallow } from "zustand/react/shallow";

type Props = {
  searchText?: string;
};

interface IGetUserList {
  userList: User[];
  isFetchingUserList: boolean;
  isSuccessFetchingUserList: boolean;
  isErrorFetchingUserList: boolean;
  refetchUserList: () => void;
  isRefetchingUserList: boolean;
  fetchNextPageUser: () => void;
  isFetchingNextPageUser: boolean;
}
const useGetUserList = ({ searchText }: Props): IGetUserList => {
  const { company } = useDoxleCurrentContextStore(
    useShallow((state) => ({
      company: state.currentCompany,
    }))
  );

  const getUser = UserQueryAPI.useGetUserList({
    company,
    search: searchText,
  });

  const userList: User[] = useMemo(
    () =>
      getUser.isSuccess
        ? getUser.data.pages.flatMap((data) => data.data.results)
        : [],
    [getUser.data]
  );
  const fetchNextPageUser = () => {
    if (getUser.hasNextPage) getUser.fetchNextPage();
  };
  return {
    userList,
    isFetchingUserList: getUser.isLoading,
    isSuccessFetchingUserList: getUser.isSuccess,
    isErrorFetchingUserList: getUser.isError,
    refetchUserList: getUser.refetch,
    isRefetchingUserList: getUser.isRefetching,
    fetchNextPageUser,
    isFetchingNextPageUser: getUser.isFetchingNextPage,
  };
};

export default useGetUserList;
