import { useMemo, useState } from "react";

import { useDoxleThemeStore } from "../../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import {
  StyledQuoteSOWItem,
  StyledSOWImgWrapper,
  StyledSOWItemHoverEffect,
  StyledSOWItemTitle,
} from "../StyledComponentQuoteResponse";
import { LuView } from "react-icons/lu";
import { AnimatePresence } from "framer-motion";
import {
  DoxleImageGalleryItem,
  useDoxleImageGalleryStore,
} from "../../../../DoxleDesignPattern/DoxleGallery/Store/useDoxleImageGalleryStore";
import { QuoteScopeOfWorkItem } from "../../../../ScopeOfWorks/Model/scopeOfWorks";
type Props = {
  scopeItem: QuoteScopeOfWorkItem;
};

const QuoteSOWItem = ({ scopeItem }: Props) => {
  const [onHoverImg, setOnHoverImg] = useState(false);
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,

      doxleFont: state.doxleFont,
    }),
    shallow
  );
  const imgList: DoxleImageGalleryItem[] = useMemo(
    () =>
      scopeItem.images.map((img) => ({
        uri: img.url ?? "",
        thumbnailUrl: img.thumbUrl ?? "",
        name: img.scopeImageDescription,
      })),
    [scopeItem.images]
  );
  const { setImageList } = useDoxleImageGalleryStore(
    (state) => ({
      imageList: state.imageList,
      setImageList: state.setImageList,
      setCurrentImageIndex: state.setCurrentImageIndex,
    }),
    shallow
  );

  const handleClickHover = () => setImageList(imgList);
  return (
    <StyledQuoteSOWItem
      $themeColor={doxleThemeColor}
      layout
      initial={{
        scale: 0,
      }}
      animate={{ scale: 1, transition: { duration: 0.4 } }}
    >
      <div
        className="item-content-wrapper"
        onMouseEnter={() => setOnHoverImg(true)}
        onMouseLeave={() => setOnHoverImg(false)}
      >
        <StyledSOWImgWrapper
          $themeColor={doxleThemeColor}
          $doxleFont={doxleFont}
        >
          {scopeItem.images.map(
            (img, idx) =>
              idx < 3 && (
                <img className="img-holder" key={img.imageId} src={img.url} />
              )
          )}
          {scopeItem.images.length > 3 && (
            <div className="more-item-text">
              +{scopeItem.images.length - 3} item
              {scopeItem.images.length - 3 > 1 ? "s" : ""}
            </div>
          )}
        </StyledSOWImgWrapper>

        <StyledSOWItemTitle
          $themeColor={doxleThemeColor}
          $doxleFont={doxleFont}
        >
          {scopeItem.scopeItemDescription}
        </StyledSOWItemTitle>

        <AnimatePresence>
          {onHoverImg && (
            <StyledSOWItemHoverEffect
              $themeColor={doxleThemeColor}
              initial={{ y: 100, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: 100, opacity: 0 }}
              onClick={handleClickHover}
            >
              <LuView
                size="3rem"
                color={doxleThemeColor.primaryContainerColor}
              />
            </StyledSOWItemHoverEffect>
          )}
        </AnimatePresence>
      </div>
    </StyledQuoteSOWItem>
  );
};

export default QuoteSOWItem;
