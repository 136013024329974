import { useEffect } from "react";
import { useEditDocketSideScreenStore } from "../../../../CoreContent/EditDocketSideScreen/EditDocketSideScreenStore/useEditDocketSideScreenStore";
import { shallow } from "zustand/shallow";
import { useEditOrderStore } from "../../../OrdersStore/useEditOrderStore";
import { useOrdersStore } from "../../../OrdersStore/useOrdersStore";

const useDocketOrderTab = () => {
  const { docket, tab } = useEditDocketSideScreenStore(
    (state) => ({
      docket: state.edittedDocket,
      tab: state.selectedFunctionMenu,
    }),
    shallow
  );
  const { resetStore } = useEditOrderStore(
    (state) => ({
      setEditedOrder: state.setEditedOrder,
      resetStore: state.resetStore,
    }),
    shallow
  );
  const docketView = tab === "Invoices" ? "Invoices" : "Orders";
  const {
    setView,
    setQueryFilter,
    resetOrderStore,
  } = useOrdersStore(
    (state) => ({
      setView: state.setView,
      setQueryFilter: state.setQueryFilter,
      resetOrderStore: state.resetOrderStore,
    }),
    shallow
  );

  useEffect(() => {
    return () => {
      resetOrderStore();
      resetStore();
    };
  }, []);

  useEffect(() => {
    setView(docketView);
  }, [setView, docketView]);

  useEffect(() => {
    if (docket) setQueryFilter({ docket: docket.docketPk });
  }, [setQueryFilter, docket]);

  return {};
};

export default useDocketOrderTab;
