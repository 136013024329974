import {
  StyledFolderTreeLine,
  StyledListNewFolderPlaceholderRow,
  StyledNewFolderTextField,
} from "../Content/StyledFilesBodyComponent";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import { FolderNewIcon } from "../Content/FileIcons";
import useListNewFolderPlaceholder from "../Hooks/useListNewFolderPlaceholder";

const ListNewFolderPlaceholder = () => {
  const styleProps = useDoxleThemeStore((state) => ({
    $themeColor: state.doxleThemeColor,
    $doxleFont: state.doxleFont,
    $currentTheme: state.currentTheme,
  }));
  const { handleRowClick, handleKeyDown, inputRef } =
    useListNewFolderPlaceholder();
  return (
    <StyledListNewFolderPlaceholderRow onClick={handleRowClick}>
      <StyledFolderTreeLine />
      <div className="row-content-wrapper">
        <FolderNewIcon themeColor={styleProps.$themeColor} />
        <StyledNewFolderTextField
          placeholder={"New Folder"}
          onKeyDown={handleKeyDown}
          ref={inputRef}
        />
      </div>
    </StyledListNewFolderPlaceholderRow>
  );
};
export default ListNewFolderPlaceholder;
