import { Variants } from "framer-motion";
import React, { useCallback, useEffect, useState } from "react";
import { useQRLogSummaryContext } from "../QRLogSummary";
import { User } from "../../../../Models/user";
import { set } from "lodash";
import { produce } from "immer";

type Props = {};

const useQRLogSummaryFilter = () => {
  const [anchorFilterMenu, setAnchorFilterMenu] = useState<HTMLElement | null>(
    null
  );
  const [showUserList, setShowUserList] = useState(false);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [rangePickedDate, setRangePickedDate] = useState<{
    start: Date | null;
    end: Date | null;
  }>({
    start: null,
    end: null,
  });
  const closeFilterMenu = () => {
    setAnchorFilterMenu(null);

    setShowUserList(false);
    setShowDatePicker(false);
  };
  const { filteredUser, setFilteredUser, dateFilterRange, setDateFilterRange } =
    useQRLogSummaryContext();

  const contentAnimateVariants: Variants = {
    initial: {
      height: "80px",
      width: "200px",
    },
    animate: {
      height: showUserList ? "600px" : showDatePicker ? "500px" : "100px",
      width: showUserList || showDatePicker ? "300px" : "200px",
      transition: {
        damping: 16,
        mass: 0.1,
        stiffness: 120,
      },
    },
  };

  const handleSelectFilterUser = useCallback((user: User) => {
    setFilteredUser(user);
    closeFilterMenu();
  }, []);
  const onDateChange = (
    dateRange: [Date | null, Date | null],
    e: React.SyntheticEvent<any, Event> | undefined
  ) => {
    setRangePickedDate(
      produce((draft) => {
        draft.start = (dateRange as [Date | null, Date | null])[0];
        draft.end = (dateRange as [Date | null, Date | null])[1];

        return draft;
      })
    );
  };

  useEffect(() => {
    if (rangePickedDate.start && rangePickedDate.end) {
      setDateFilterRange({
        start: rangePickedDate.start,
        end: rangePickedDate.end,
      });
      closeFilterMenu();
    }
  }, [rangePickedDate]);
  return {
    anchorFilterMenu,
    setAnchorFilterMenu,
    closeFilterMenu,
    contentAnimateVariants,
    handleSelectFilterUser,
    filteredUser,
    showUserList,
    setShowUserList,
    showDatePicker,
    setShowDatePicker,
    rangePickedDate,
    onDateChange,
  };
};

export default useQRLogSummaryFilter;
