import styled from "styled-components";
import {Button, Checkbox, Chip, Dialog, Menu, TextField, Typography} from "@mui/material";
import {DoxleFont, DoxleTheme, DoxleThemeColor} from "../../DoxleGeneralStore/useDoxleThemeStore";
import {motion} from "framer-motion";
import React from "react";
import {editRgbaAlpha} from "../../Utilities/FunctionUtilities";
import DoxleStatus from "../../DoxleDesignPattern/DoxleStatus/DoxleStatus";

export const StyledShareDocketFullScreenBorder = styled.div<{
  $themeColor: DoxleThemeColor,
}>`
  height: Calc(100vh - 1.5rem);
  width: Calc(100vw - 3rem);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 5;
  border: 1.5rem ${(p) => p.$themeColor.primaryContainerColor} solid;
  border-bottom: none;
`

export const StyledBackButton = styled.button<{
  $doxleFont: DoxleFont;
  $themeColor: DoxleThemeColor;
  $currentTheme: DoxleTheme;
}>`
  color: ${(p)=>p.$themeColor.primaryFontColor};
  position: absolute;
  left: calc(100% - 50px -  4rem);
  top: 3rem;
  width: 50px;
  height: 50px;
  background-color: ${(p)=>p.$themeColor.primaryBackgroundColor};
  border-radius: 2rem;
  // box-shadow: 1px 1px 2px ${(p) => p.$themeColor.primaryBoxShadowColor};
  border: none;
  padding: 0;
  overflow: visible;
  cursor: pointer;
  :hover {
    transform: scale(1.04);
    // background-color: $ {(p)=> editRgbaAlpha({
    //   rgbaColor: p.$themeColor.primaryContainerColor,
    //   alpha: '0.4'
    // })};
  }
  :active {
    transform: scale(0.97) translateY(-1px);
  }
  transition: 0.4s;
`

export const StyledShareDocketFullScreenContainer = styled.div<{
  $doxleFont?: DoxleFont;
  $themeColor?: DoxleThemeColor;
  $currentTheme?: DoxleTheme;
  $isMobile?:boolean;
}>`
  //height: Calc(100vh - 300px) !important;
  height: Calc(100% - 6rem) !important;
  display: flex;
  flex-direction:  ${(p) => p?.$isMobile ? "column" : "row"};
  background-color: ${(p) => p?.$themeColor?.primaryBackgroundColor ?? 'rgb(239, 240, 244)'} !important;
  padding: 3rem;
  justify-content: space-around;
  width: Calc(100% - 6rem);
`;


export const StyledShareDocketTabContainer = styled.div<{
  $doxleFont?: DoxleFont,
  $themeColor?: DoxleThemeColor,
  $currentTheme?: DoxleTheme
  $percentageWidth?: number;
  $alignCentre?: true;
}>`
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${(p) => p?.$themeColor?.primaryBackgroundColor ?? 'rgb(245, 245, 244)'} !important;
  padding: 8em 2.5em 2.5em;
  width: Calc(${(p) => p?.$percentageWidth ?? 100}% - 5em) !important;
  max-width: ${(p) => p?.$percentageWidth ?? 100}% !important;
  align-items: ${(p) => p?.$alignCentre ? "center" : "unset"};
`;

export const StyledSelectedContactsContainer = styled.div<{
  $doxleFont?: DoxleFont,
  $themeColor?: DoxleThemeColor,
  $currentTheme?: DoxleTheme
}>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  min-height: 35px;
  padding: 20px;
`

export const StyledContactChip = styled(Chip)<{
  $doxleFont?: DoxleFont,
  $themeColor?: DoxleThemeColor,
  $currentTheme?: DoxleTheme
}>`
  background-color: ${(p) => p?.$themeColor?.doxleColor ?? '#7070ff'} !important;
  color: white !important;
  font-size: 14px !important;
  margin-right: 5px !important;
  margin-top: 5px !important;
`

export const StyledShareLabel = styled(Typography)<{
  $doxleFont?: DoxleFont,
  $themeColor?: DoxleThemeColor,
  $currentTheme?: DoxleTheme
}>`
  font-family: ${(p) => p?.$doxleFont?.primaryFont ?? "Inter"};
  font-size: 14px !important;
  color: ${(p) => p?.$themeColor?.primaryFontColor ?? 'rgba(0,0,0,1)'};
  font-weight: 600 !important;
`

export const StyledSelectContactRow = styled.div<{
  $doxleFont?: DoxleFont,
  $themeColor?: DoxleThemeColor,
  $currentTheme?: DoxleTheme
  $expanded: boolean
}>`
  display: flex;
  flex-direction: column;
  margin-bottom: 60px;
  background-color: ${(p) => editRgbaAlpha({
    rgbaColor: p?.$themeColor?.primaryReverseFontColor ?? "rgba(255,255,255,1)",
    alpha: "0.7"
  })} !important;
  min-height: ${(p) => p?.$expanded ? "425px": "unset"};
  width: 100%;
  border-radius: 0px 4px 4px 4px;
  padding: 18px;
  transition: 0.4s;
`

export const StyledInputRow = styled.div<{
  $doxleFont?: DoxleFont,
  $themeColor?: DoxleThemeColor,
  $currentTheme?: DoxleTheme
}>`
  display: flex;
  flex-direction: row;
  width: 90%;
`

export const StyledSelectContactContainer = styled.div<{
  $doxleFont?: DoxleFont,
  $themeColor?: DoxleThemeColor,
  $currentTheme?: DoxleTheme
}>`
  width: 60%;
  max-width: 408px;
`

export const StyledAddContactButtonContainer = styled.div<{
  $doxleFont?: DoxleFont,
  $themeColor?: DoxleThemeColor,
  $currentTheme?: DoxleTheme
}>`
  width: 40%;
  min-width: 60px;
  padding-left: 25px;
`

export const StyledShareButton = styled(Button)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
  $bgColor?: string;
}>`
  && {
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.05em;
    color: ${(p) => p.$themeColor.primaryReverseFontColor};
    background-color: ${(p) => p.$bgColor ? p.$bgColor : p.$themeColor.doxleColor};
    margin: 0px 4px;
    text-transform: capitalize;
    min-width: 60px;
    border-radius: 0.3rem !important;
    &:hover {
      background-color: ${(p) => p.$bgColor ? p.$bgColor : p.$themeColor.doxleColor} !important;
      transform: translateY(-2px) scale(1.05);
      transition: 0.4s;
      opacity: 0.8;
    }
  };
  &.Mui-disabled {
    background-color: ${(p) => p.$themeColor.primaryBoxShadowColor} !important;
  }
`;

export const StyledSharedContactRow = styled.div<{
  $doxleFont?: DoxleFont,
  $themeColor?: DoxleThemeColor,
  $currentTheme?: DoxleTheme
}>`
  display: flex;
  flex-direction: row;
  width: 100%;
  min-height:40px;
  max-height: 45px;
  border-bottom: 1px solid ${(p) => p?.$themeColor?.primaryDividerColor ?? "rgba(230,232,232,1)"};
  overflow-y: hidden;
  padding-bottom: 5px;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
`

export const StyledSharedContactInfo = styled.div<{
  $doxleFont?: DoxleFont;
  $themeColor?: DoxleThemeColor;
  $currentTheme?: DoxleTheme;
  $fullWidth?: boolean;
}>`
  display: flex;
  flex-direction: column;
  width: ${(p) => p?.$fullWidth ? "100%" : "35%"};
  min-width: 150px;
  padding: ${(p) => p?.$fullWidth ? "3px 0px" : "8px 0px"};
`

export const StyledPermissionContainer = styled.div<{
$doxleFont?: DoxleFont,
$themeColor?: DoxleThemeColor,
$currentTheme?: DoxleTheme
}>`
  display: flex;
  flex-direction: row;
  width: 65%;
  min-width: 150px;
  padding: 10px 0px;
  justify-content: center;
`

export const StyledContactName= styled.p<{
  $doxleFont?: DoxleFont,
  $themeColor?: DoxleThemeColor,
  $currentTheme?: DoxleTheme
}>`
  font-family: ${(p) => p?.$doxleFont?.primaryFont ?? "Inter"};
  font-size: 14px !important;
  color: ${(p) => p?.$themeColor?.primaryFontColor ?? 'rgba(0,0,0,1)'};
  font-weight: 600 !important;
  padding: 0px !important;
  margin: 0px !important;
`

export const StyledContactEmail =  styled.p<{
  $doxleFont?: DoxleFont,
  $themeColor?: DoxleThemeColor,
  $currentTheme?: DoxleTheme
}>`
  font-family: ${(p) => p?.$doxleFont?.primaryFont ?? "Inter"};
  font-size: 12px !important;
  color: ${(p) => p?.$themeColor?.primaryFontColor ?? 'rgba(0,0,0,1)'};
  font-weight: 600 !important;
  padding: 0px !important;
  margin: 0px !important;
`

export const StyledPermissionSelect = styled.div<{
  $doxleFont?: DoxleFont,
  $themeColor?: DoxleThemeColor,
  $currentTheme?: DoxleTheme
}>`
  height: 20px;
  max-height: 27px;
  background-color: ${(p) => editRgbaAlpha({
    rgbaColor: p?.$themeColor?.primaryReverseFontColor ?? "rgba(255,255,255,1)",
    alpha: "0.7"
  })} !important;
  color: ${(p) =>  p?.$themeColor?.primaryFontColor ?? "rgba(0,0,0,1)"} !important;
  border: 1px solid ${(p) => p?.$themeColor?.primaryDividerColor ?? "rgba(230,232,232,1)"};
  border-radius: 0px 4px 4px 4px;
  min-width: 140px;
  //width: 140px;
  max-width: 400px;
  font-family: ${(p) => p.$doxleFont?.subTitleFont ?? "IBM Plex Mono" } !important;
  font-size: 14px !important;
  font-weight: 500;
  line-height: 17px;
  padding: 5px;
  text-align: center;
`

export const StyledEditPermissionsDropDownMenu = styled.div<{
  $doxleFont?: DoxleFont,
  $themeColor?: DoxleThemeColor,
  $currentTheme?: DoxleTheme
  $top: number;
  $left: number;
}>`
  display: ${(p) => p.$top && p.$left ? 'flex' : 'none'};
  flex-direction: column;
  position: absolute;
  top: ${(p) => `calc(${p.$top}px -  1.5rem)`};
  left: ${(p) => `calc(${p.$left}px -  1.5rem)`};
  width: 300px;
  max-height: 600px;
  justify-content: center;
  background-color: ${(p) => editRgbaAlpha({
    rgbaColor: p?.$themeColor?.primaryReverseFontColor ?? "rgba(255,255,255,1)",
    alpha: "1"
  })} !important;
  border-radius: 0px 4px 4px 4px !important;
  box-shadow: 0px 0px 8px 0px ${(p) => p.$themeColor?.primaryBoxShadowColor ?? "rgba(128,128,128,0.2)"};

.MuiList-root {
    width: Calc(100% - 20px);
  }
  .MuiMenuItem-root {
    border-bottom:  1px solid ${(p) => p?.$themeColor?.primaryDividerColor ?? "#EFF0F4"} !important;
    font-family: ${(p) => p?.$doxleFont?.primaryFont ?? "Inter"};
    font-size: 12px;
    color: ${(p) => editRgbaAlpha({
      rgbaColor: p?.$themeColor?.primaryFontColor ?? "rgba(0,0,0,1)",
      alpha: "0.7"
    })} !important;
  }
  .MuiMenuItem-root:hover {
    //background-color: ${(p) => p?.$themeColor?.primaryDividerColor ?? "red"} !important;
  }
`

export const StyledMenuItem = styled.div<{
  $doxleFont?: DoxleFont,
  $themeColor?: DoxleThemeColor,
  $currentTheme?: DoxleTheme
  $color?: string
  $clickable?: boolean
}>`
  display: flex;
  width: Calc(100% - 20px);
  min-height: 46px;
  flex-direction: column;
  font-family: ${(p) => p?.$doxleFont?.subTitleFont ?? 'IBM Plex Mono'};
  font-size: 13px;
  color: ${(p) => p?.$color ?? p?.$themeColor?.primaryFontColor ?? "rgba(0,0,0,1)"};
  padding: 5px 10px;
  font-weight: 600;
  border-bottom:  1px solid ${(p) => p?.$themeColor?.primaryDividerColor ?? "#EFF0F4"};
  justify-content: center;
  cursor: ${(p) => p?.$clickable ? "pointer" : "unset"};
  &&:hover {
    background-color: ${(p) => p?.$clickable ? p?.$themeColor?.hoverColor ?? "rgba(126, 150, 187, 0.12)" : "unset"} !important;
  }
`
export const StyledCheckBoxColumnContainer = styled.div<{
  $doxleFont?: DoxleFont,
  $themeColor?: DoxleThemeColor,
  $currentTheme?: DoxleTheme
}>`
  display: flex;
  width: 100%;
  flex-direction: row;
`
export const StyledCheckBoxColumn = styled.div<{
  $doxleFont?: DoxleFont,
  $themeColor?: DoxleThemeColor,
  $currentTheme?: DoxleTheme
}>`
  display: flex;
  flex-direction: column;
  width: 50%;
`
export const StyledPermissionCheckboxRow  = styled.div<{
  $doxleFont?: DoxleFont,
  $themeColor?: DoxleThemeColor,
  $currentTheme?: DoxleTheme
}>`
  display: flex;
  width: 100%;
  flex-direction: row;
  color: ${(p) => editRgbaAlpha({
    rgbaColor: p?.$themeColor?.primaryFontColor ?? 'rgba(0,0,0,1)',
    alpha: "0.7"
  })};
  font-weight: 400;
  padding: 2px 0px;
  font-size: 13px;
  font-family: ${(p) => p?.$doxleFont?.primaryFont ?? "Inter"};
  cursor: pointer;
  border-radius: 0px 4px 4px 4px;
  &&:hover {
    background-color: ${(p) => p?.$themeColor?.hoverColor ?? "rgba(126, 150, 187, 0.12)"};
  }
`
export const StyledPermissionCheckbox = styled(Checkbox)<{
  $doxleFont?: DoxleFont,
  $themeColor?: DoxleThemeColor,
  $currentTheme?: DoxleTheme
}>`
  padding: 0px 5px !important;
`

export const StyledShareInput = styled(TextField)<{
  $doxleFont?: DoxleFont,
  $themeColor?: DoxleThemeColor,
  $currentTheme?: DoxleTheme
}>`
  "& .MuiInputBase-root" : {
    background-color: ${(p) => p.$themeColor?.primaryBackgroundColor ?? "white"};
    border: 1px solid #D5D7D9;
    border-radius: 0px 4px 4px 4px;
  },
  "& .MuiInputBase-root:before" : {
    border-bottom: none;
  },
`

export const StyledCopyLinkButton = styled(Button)<{
  $doxleFont?: DoxleFont,
  $themeColor?: DoxleThemeColor,
  $currentTheme?: DoxleTheme
  $marginRight?: number
}>`
  && {
    margin: 5px ${(p) => p.$marginRight + 'px' ?? '5px'} 5px 5px;
    padding: 10px 5px;
    background-color: ${(p) => p.$themeColor?.primaryBackgroundColor ?? "#7070ff"};
    color: ${(p) => p.$themeColor?.doxleColor ?? "#7070ff"} !important;
  }
  &:hover{
    background-color: ${(p) => p.$themeColor?.doxleColor ?? "#7070ff"} !important;
    color: ${(p) => p.$themeColor?.primaryReverseFontColor ?? "white"} !important;
  }
`

export const StyledShareDocketTitleRow= styled.div<{
  $doxleFont?: DoxleFont,
  $themeColor?: DoxleThemeColor,
  $currentTheme?: DoxleTheme
}>`
  margin-top: 50px;
  display: flex;
  flex-direction: row;
  width: 100%;
  color:  ${(p) => p.$themeColor?.primaryFontColor ?? "rgba(0,0,0,1)"};
  font-family: ${(p) => p.$doxleFont?.primaryTitleFont ?? "Inter"};;
`

export const StyledBoldHeader= styled.div<{
  $doxleFont?: DoxleFont;
  $themeColor?: DoxleThemeColor;
  $currentTheme?: DoxleTheme;
  $displayWidth: number;
}>`
  font-family: ${(p) => p.$doxleFont?.primaryFont ?? "Inter"};
  font-weight: 900;
  font-size: ${(p) => p.$displayWidth >= 1920  ? "2.7rem" : "2.0rem"};
  margin-right: 20px;
  white-space: nowrap;
`

export const StyledSemiBoldHeader= styled.div<{
  $doxleFont?: DoxleFont;
  $themeColor?: DoxleThemeColor;
  $currentTheme?: DoxleTheme;
  $displayWidth: number;
}>`
  font-family: ${(p) => p.$doxleFont?.secondaryFont ?? "Work Sans"};
  font-weight: 400;
  font-size: ${(p) => p.$displayWidth >= 1920  ? "1.6rem" : "1.2rem"};
  margin-left: 10px;
  flex-direction: row;
  display: flex;
  background-color: ${(p)=>
    editRgbaAlpha({
      rgbaColor: p.$themeColor?.doxleColor ?? "rgba(112,112,239,0.1)",
      alpha: '0.1'
    })};
  border-radius: 0 12px 0 12px;
  padding: 8px 8px 0 8px;
  line-height: 22px;
  white-space: nowrap;
`

export const StyledSubtitleContainer= styled.div<{
  $doxleFont?: DoxleFont,
  $themeColor?: DoxleThemeColor,
  $currentTheme?: DoxleTheme
  $displayWidth: number;
}>`
  display: flex;
  flex-direction: row;
  width: 100%;
  font-family: ${(p) => p.$doxleFont?.subTitleFont ?? "IBM Plex Mono"};;
  font-weight: 500;
  font-size: ${(p) => p.$displayWidth >= 1920  ? "1.6rem" : "1.2rem"};
  color:  ${(p) => editRgbaAlpha({ 
    rgbaColor: p.$themeColor?.primaryFontColor ?? "rgba(0,0,0,1)",
    alpha: '0.6'
  })};
`
export const StyledButtonContainer= styled.div<{
  $doxleFont?: DoxleFont,
  $themeColor?: DoxleThemeColor,
  $currentTheme?: DoxleTheme
}>`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  margin-top: 3rem;
`
export const StyledBottomBanner = styled.div<{
  $themeColor: DoxleThemeColor,
}>`
  display: flex;
  flex-direction: row;
  width: Calc(100% - 7.5rem);
  justify-content: space-between;
  background-color: ${(p) => p.$themeColor.primaryContainerColor};
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 1.8rem 4.5rem 1rem 2.5rem;
`
export const StyledBottomBannerBreadcrumbs = styled.div<{
  $themeColor: DoxleThemeColor,
  $doxleFont: DoxleFont,
}>`
  text-align: left;
  font-size: 1.2rem;
  font-family: ${(p) => p.$doxleFont.primaryFont};
  font-weight: 400;
  color: ${(p) => editRgbaAlpha({
    rgbaColor:p.$themeColor.primaryFontColor, 
    alpha: '0.5'
  })};
`

export const StyledBottomBannerEmail = styled.div<{
  $themeColor: DoxleThemeColor,
  $doxleFont: DoxleFont,
}>`
  text-align: right;
  font-size: 1.2rem;
  font-family: ${(p) => p.$doxleFont.primaryFont};
  font-weight: 400;
  color: ${(p) => p.$themeColor.doxleColor};
`
export const StyledLeftScreenShareableTitle=styled.div`
  margin-top: 4rem;
  width: Calc(100% + 5em);
  //position: relative;
  //left: 0;
  align-self: start;
`
export const StyledShareLogoSection = styled.div`
  position: fixed;
  left: 3.5em;
  top: 3.5em;
`

export const StyledFileButton = styled.div<{
  $themeColor: DoxleThemeColor,
  $doxleFont: DoxleFont,
  $selected: boolean
  $hPadding?: string
}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  border-radius: 0.5rem;
  background-color: ${(p) => p.$selected ? editRgbaAlpha({
    rgbaColor: p.$themeColor.doxleColor,
    alpha: '0.5'
  }) : 'transparent'};
  &:hover{
    background-color: ${(p) => p.$themeColor.hoverColor};
    transition: 0.4s;
  }
  padding: 1.5rem ${(p) => p.$hPadding ?? '0.4rem'};
}
`
export const StyledFileButtonText = styled.span<{
  $themeColor: DoxleThemeColor,
  $doxleFont: DoxleFont,
}>`
  border-radius: 0.5em;
  background-color: ${(p) => p.$themeColor.primaryContainerColor};
  color: ${(p) => p.$themeColor.primaryFontColor};
  font-weight: 600;
  font-family: ${(p) => p.$doxleFont.subTitleFont};
  font-size: 1.1em;
  line-height: 1.8em;
  padding: 0.1rem 0.8rem 0.1rem 0.8rem;
`
export const StyledFileIcon = styled.img`
  height: 5.5em;
  max-height: 5vh;
  max-width: 8vh;
  aspect-ratio: 205/128  !important;
`
// export const CopyIcon = styled.img`
//   width: 1rem;
//   height: 1rem;
//   fill: rgba(0, 0, 0, 0.70);
//   margin-right: 0.4rem;
// `;
export const StyleLeftScreenCopyLinkText=styled.span`
color: rgba(0, 0, 0, 0.70);
font-family: Inter;
font-size: 1rem;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.05rem;
`
export const StyledLink = styled.span`
  color: #7070ef;
  font-family: Inter;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.07rem;
  width: 100%;
  height: 3.2rem;
  flex-shrink: 0;
  border-radius: 0.3rem;
  border: 0.1rem solid #d5d7d9;
  display: block;
  line-height: 3.2rem;
  text-decoration: none; /* Remove underline */
  overflow:hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
export const ShareableLink = styled.span`
  display: flex;
  align-items: center;
  color: #6461FB;
  font-family: Inter;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.028rem;
  margin-right: 2.6rem;
`;
