import { shallow } from "zustand/shallow";
import DoxleCheckbox from "../../DoxleDesignPattern/DoxleCheckbox/DoxleCheckbox";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import { useFileStore } from "../Store/useFileStore";
import {
  CreatedByIcon,
  CreatedTimeIcon,
  FolderIcon,
  LastEditedByIcon,
} from "./FileIcons";
import {
  StyledFileHeaderIcons,
  StyledFileHeaderLabel,
  StyledRightFileTableHeaderCell,
  StyledFileNameTableHeaderDiv,
  StyledCreatedByTableHeaderDiv,
  StyledFileSizeTableHeaderDiv,
  StyledLastEditedByTableHeaderDiv,
  StyledCreatedTimeTableHeaderDiv,
  StyledLastEditedTimeTableHeaderDiv,
} from "./StyledFilesBodyComponent";
import useGetFileInsideFolder from "../Hooks/useGetFileInsideFolder";
import { ChangeEvent } from "react";

export const FILE_TABLE_HEADER_COLUMNS: string[] = [
  "File Name",
  "File Size",
  "Created by",
  "Created time",
  "Last edited by",
  "Last edited time",
];

type Props = {};

const RightSectionFileTableHeader = (props: Props) => {
  // console.log("File Table Header");
  const styleProps = useDoxleThemeStore((state) => ({
    $themeColor: state.doxleThemeColor,
    $doxleFont: state.doxleFont,
  }));
  const {
    setSelectedFile,
    setSelectedMultipleFiles,
    selectedFiles,
    clearSelectedFiles,
  } = useFileStore(
    (state) => ({
      setSelectedFile: state.setSelectedFile,
      setUnselectedFile: state.setUnselectedFile,
      selectedFiles: state.selectedFiles,
      clearSelectedFiles: state.clearSelectedFiles,
      setSelectedMultipleFiles: state.setSelectedMultipleFiles,
    }),
    shallow
  );
  const { filesInsideFolderList } = useGetFileInsideFolder({});

  const isCheckboxChecked =
    selectedFiles.length === filesInsideFolderList.length;
  const handleCheckbox = (event: ChangeEvent<HTMLInputElement>) => {
    console.log("CHECL:", event.target.checked);
    if (event.target.checked) {
      setSelectedMultipleFiles(filesInsideFolderList);
    } else clearSelectedFiles();
  };
  return (
    <StyledRightFileTableHeaderCell {...styleProps}>
      {/* <DoxleCheckbox
        style={{ marginLeft: "14px" }}
        checked={isCheckboxChecked}
        onChangeCallback={handleCheckbox}
        alternateIcon={true}
      /> */}
      <StyledFileNameTableHeaderDiv {...styleProps}>
        Name
      </StyledFileNameTableHeaderDiv>
      <StyledFileSizeTableHeaderDiv {...styleProps}>
        Size
      </StyledFileSizeTableHeaderDiv>
      <StyledFileHeaderLabel
        {...styleProps}
        style={{ textAlign: "center", width: "20%", minWidth: 120 }}
      >
        Date Added
      </StyledFileHeaderLabel>

      <StyledFileHeaderLabel
        {...styleProps}
        style={{ width: "15%", textAlign: "center" }}
      >
        Created By
      </StyledFileHeaderLabel>
    </StyledRightFileTableHeaderCell>
  );
};

export default RightSectionFileTableHeader;
