import React from "react";
import DoxleDialogHelper from "../../DoxleDesignPattern/DoxleDialogHelper/DoxleDialogHelper";
import { useNoteStore } from "../Store/useNoteStore";
import { shallow } from "zustand/shallow";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import useDeleteNoteConfirm from "../Hooks/useDeleteNoteConfirm";

type Props = {};

const DeleteNoteConfirm = (props: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }),
    shallow
  );
  const { setDeletedNote, deletedNote } = useNoteStore(
    (state) => ({
      setDeletedNote: state.setDeletedNote,
      deletedNote: state.deletedNote,
    }),
    shallow
  );
  const { handleDeleteNote } = useDeleteNoteConfirm({});
  return (
    <DoxleDialogHelper
      open={Boolean(deletedNote)}
      onClose={() => setDeletedNote(undefined)}
      title="Confirm delete note!"
      description={`All data belong to note ***${deletedNote?.title}*** will be deleted permanently, are you sure to proceed?`}
      dialogButtons={[
        {
          buttonText: "Cancel",
          buttonFunction: () => setDeletedNote(undefined),
          buttonColor: doxleThemeColor.primaryContainerColor,
          btnStyleSx: {
            border: `1px solid ${doxleThemeColor.primaryDividerColor}`,
          },
          buttonTextStyle: {
            color: doxleThemeColor.primaryFontColor,
          },
        },
        {
          buttonText: "Confirm",
          buttonFunction: handleDeleteNote,
          btnStyleSx: {
            border: `1px solid ${doxleThemeColor.primaryDividerColor}`,
          },
        },
      ]}
    />
  );
};

export default DeleteNoteConfirm;
