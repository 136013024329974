import FileListView from "./FileListView";
import GridFolderFilesView from "./GridFolderFilesView";
import useFileBody from "../Hooks/useFileBody";

type Props = {};

const FileBody = (props: Props) => {
  const { urlPath, currentView } = useFileBody({});

  return (
    <>
      {currentView === "ListView" ? (
        <FileListView />
      ) : (
        <GridFolderFilesView urlPath={urlPath} />
      )}
    </>
  );
};

export default FileBody;
