import React, { useCallback, useMemo, useRef, useState } from "react";
import { PricebookItem } from "../../../Models/Pricebook";
import { usePricebookStore } from "../../../Store/usePricebookStore";
import { useShallow } from "zustand/react/shallow";
import { useDoxleCurrentContextStore } from "../../../../DoxleGeneralStore/useDoxleCurrentContext";
import useDoxleNotificationStore from "../../../../DoxleGeneralStore/useDoxleNotificationStore";
import { useDropzone } from "react-dropzone";
import useSetPricebookQueryData from "../../../QueryHooks/useSetPricebookQueryData";
import PricebookQueryAPI from "../../../QueryHooks/PricebookQueryAPI";
import {
  DoxleImageGalleryItem,
  useDoxleImageGalleryStore,
} from "../../../../DoxleDesignPattern/DoxleGallery/Store/useDoxleImageGalleryStore";

type Props = {
  pricebookItem: PricebookItem;
};

interface IPricebookCard {
  currentViewSwatchIdx: number;
  switchNextSwatch: (e: React.MouseEvent<SVGElement, MouseEvent>) => void;
  switchPrevSwatch: (e: React.MouseEvent<SVGElement, MouseEvent>) => void;
  onDrop: (acceptedFiles: File[]) => void;
  handleClickSwatchImg: (
    e: React.MouseEvent<HTMLImageElement, MouseEvent>
  ) => void;

  isPrevSwatchAvailable: boolean;
  isNextSwatchAvailable: boolean;
  handleDeletePricebookItem: () => void;
  isDeletingPricebook: boolean;
}
const usePricebookCard = ({ pricebookItem }: Props): IPricebookCard => {
  const [currentViewSwatchIdx, setcurrentViewSwatchIdx] = useState(0);
  const [pricebookTitle, setPricebookTitle] = useState<string>(
    pricebookItem.description
  );
  const { filter } = usePricebookStore(
    useShallow((state) => ({
      filter: state.pricebookItemQueryFilter,
    }))
  );
  const company = useDoxleCurrentContextStore(
    useShallow((state) => state.currentCompany)
  );
  const showNotification = useDoxleNotificationStore(
    useShallow((state) => state.showNotification)
  );
  const { setImageList, setCurrentImageIndex } = useDoxleImageGalleryStore(
    useShallow((state) => ({
      setImageList: state.setImageList,
      setCurrentImageIndex: state.setCurrentImageIndex,
    }))
  );

  const imgList: DoxleImageGalleryItem[] = useMemo(
    () =>
      pricebookItem.swatches.map((swatch, idx) => ({
        uri: swatch.url,
        thumbnailUrl: swatch.thumbUrl,
        name: `Image#${idx + 1}`,
      })),
    [pricebookItem.swatches]
  );
  const handleClickSwatchImg = (
    e: React.MouseEvent<HTMLImageElement, MouseEvent>
  ) => {
    e.stopPropagation();
    setImageList(imgList);
    setCurrentImageIndex(currentViewSwatchIdx);
  };
  const onDrop = useCallback((acceptedFiles: File[]) => {
    handleAddSwatch(acceptedFiles);
  }, []);

  const {
    handleUpdatePricebookItem,
    handleAddPricebookItemImage,
    handleRemovePricebookItem,
  } = useSetPricebookQueryData({
    itemId: pricebookItem.pricebookId,
  });
  const pricebookItemMutation = PricebookQueryAPI.usePricebookItemMutation({
    company,
    showNotification,
    filter,
    onSuccessUpdateCb(updated) {
      handleUpdatePricebookItem(updated);
      setPricebookTitle(updated.description);
    },
    onSuccessRemoveCb(deletedId: string) {
      handleRemovePricebookItem(deletedId);
    },
  });

  const handleDeletePricebookItem = () => {
    pricebookItemMutation.deletePricebookItem.mutate(pricebookItem.pricebookId);
  };

  const swatchMutation = PricebookQueryAPI.useSwatchMutationQuery({
    company,
    showNotification,
    filter,
    onSuccessAddCb(newItem) {
      handleAddPricebookItemImage(newItem);
    },
  });

  const handleAddSwatch = (files: File[]) => {
    swatchMutation.addSwatch.mutate({
      pricebookItem: pricebookItem.pricebookId,
      files,
    });
  };
  const switchNextSwatch = (e: React.MouseEvent<SVGElement, MouseEvent>) => {
    e.stopPropagation();
    setcurrentViewSwatchIdx((prev) => prev + 1);
  };

  const switchPrevSwatch = (e: React.MouseEvent<SVGElement, MouseEvent>) => {
    e.stopPropagation();
    setcurrentViewSwatchIdx((prev) => prev - 1);
  };

  const isPrevSwatchAvailable = currentViewSwatchIdx > 0;

  const isNextSwatchAvailable =
    currentViewSwatchIdx < pricebookItem.swatches.length - 1;
  return {
    currentViewSwatchIdx,
    switchNextSwatch,
    switchPrevSwatch,
    onDrop,
    handleClickSwatchImg,

    isPrevSwatchAvailable,
    isNextSwatchAvailable,
    handleDeletePricebookItem,
    isDeletingPricebook: pricebookItemMutation.deletePricebookItem.isLoading,
  };
};

export default usePricebookCard;
