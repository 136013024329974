import { StateCreator, create } from "zustand";
import { Mail } from "../../Models/mail";



interface MailSideScreenStore {

  currentMail: Mail | undefined;
  setCurrentMail: (item: Mail | undefined) => void;
  isFullScreenMode: boolean;
  setIsFullScreenMode: (value: boolean) => void;
  resetStore: () => void;
  currentDisplay: "Mail" | "Comments" | "New" | null;
  setCurrentDisplay: (value: "Mail" | "Comments" | "New" | null) => void
}


const createMailSideScreenStore: StateCreator<
  MailSideScreenStore,
  [["zustand/immer", never], never],
  [],
  MailSideScreenStore
> = (set, get) => ({

  currentMail: undefined,
  currentDisplay: null,
  setCurrentMail: (item: Mail | undefined) =>
    set({
      currentMail: item,

    }),
  setCurrentDisplay: (value: "Mail" | "Comments" | "New" | null) => {
    set({currentDisplay: value});
  },

  isFullScreenMode: false,
  setIsFullScreenMode: (value: boolean) => set({ isFullScreenMode: value }),

  resetStore: () =>
    set((state) => ({
      ...state,
      currentMail: undefined,
      isFullScreenMode: false,
      currentDisplay: null
    })),
});


export const useMailSideScreenStore = create<
  MailSideScreenStore
>()((...a) => ({
  ...createMailSideScreenStore(...a),
}));
