import React, { useRef, useState } from "react";
import ReactSignatureCanvas from "react-signature-canvas";
import { useDoxleCurrentContextStore } from "../../../DoxleGeneralStore/useDoxleCurrentContext";
import { shallow } from "zustand/shallow";
import useDoxleNotificationStore from "../../../DoxleGeneralStore/useDoxleNotificationStore";
import UserQueryAPI from "../../../Services/QueryHooks/userQueryAPI";
import { User } from "../../../Models/user";
import { useDoxleAuthStore } from "../../../DoxleGeneralStore/useDoxleAuthStore";
import useGetUserInfo from "../../../CoreContent/QueryDataHooks/GetQueryDataHooks/useGetUserInfo";

type Props = { onCompleteEdit?: (newUser?: User) => void };

interface DoxleEditSignaturePatch {
  finalSignaturePath: string | undefined;
  setFinalSignaturePath: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
  signPadRef: React.RefObject<ReactSignatureCanvas>;
  onFinishDraw: () => void;
  handleClearSignature: () => void;
  handleSaveSignature: () => void;
  isSavingSignature: boolean;
}
const useDoxleEditSignaturePatch = ({
  onCompleteEdit,
}: Props): DoxleEditSignaturePatch => {
  const { user: userServer } = useGetUserInfo();
  const [finalSignaturePath, setFinalSignaturePath] = useState<
    string | undefined
  >(userServer?.signature ?? undefined);
  const signPadRef = useRef<ReactSignatureCanvas>(null);

  const onFinishDraw = () => {
    const uri = signPadRef.current
      ?.getTrimmedCanvas()
      .toDataURL("image/png", 1);
    if (uri) setFinalSignaturePath(uri);
  };
  const handleClearSignature = () => {
    setFinalSignaturePath(undefined);
    signPadRef.current?.clear();
  };
  //*update api
  const company = useDoxleCurrentContextStore(
    (state) => state.currentCompany,
    shallow
  );
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );
  const { setUser, user } = useDoxleAuthStore((state) => ({
    setUser: state.setUser,
    user: state.user,
  }));

  const onUpdateSuccessCb = (newUser?: User) => {
    if (newUser) {
      setUser(newUser);
      if (onCompleteEdit) onCompleteEdit(newUser);
    }
  };
  const updateUserSignature = UserQueryAPI.useUpdateUserInfo({
    showNotification,
    company,
    onSuccessCB: onUpdateSuccessCb,
  });

  const handleSaveSignature = () => {
    if (!signPadRef.current?.isEmpty()) {
      const blob = signPadRef.current?.getTrimmedCanvas().toBlob(
        (blob) => {
          if (blob && user)
            updateUserSignature.mutate({
              userId: user.userId!,
              signature: blob,
            });
        },
        "image/png",
        1
      );
    }
  };
  return {
    finalSignaturePath,
    setFinalSignaturePath,
    signPadRef,
    onFinishDraw,
    handleClearSignature,
    handleSaveSignature,
    isSavingSignature: updateUserSignature.isLoading,
  };
};

export default useDoxleEditSignaturePatch;
