import { shallow } from "zustand/shallow";
import { useEditDocketSideScreenStore } from "../../EditDocketSideScreenStore/useEditDocketSideScreenStore";
import { StyledDocketInfoContainer } from "../../StyledComponentsEditDocketSideScreen";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useEffect, useMemo } from "react";
import { Components, Virtuoso } from "react-virtuoso";
import {
  StyledDiscussionListScroller,
  StyledDocketInfoFooter,
  StyledDocketInfoHeader,
} from "./StyledComponentDocketInfo";
import React from "react";
import DocketNameSection from "./DocketNameSection";
import { LayoutGroup, motion } from "framer-motion";
import AssigneeSection from "./AssigneeSection";
import WatcherSection from "./WatcherSection";
import DocketDatePickerSection from "./DocketDatePickerSection";
import ProjectDisplaySelection from "./ProjectDisplaySelection";
import { Docket } from "../../../../Models/dockets";
import { useDoxleThemeStore } from "../../../../DoxleGeneralStore/useDoxleThemeStore";
import StatusAndPercentSection from "./StatusAndPercentSection";
import ToggleFunctionalMenuSection from "./ToggleFunctionalMenuSection";
import LinkDocketSection from "./LinkDocketSection";

type Props = {};

interface DocketInfoListContext {
  edittedDocket: Docket | undefined;
}
const DocketInfo = ({}: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore((state) => ({
    doxleThemeColor: state.doxleThemeColor,
    doxleFont: state.doxleFont,
  }));
  const { edittedDocket, setHandleAddButtonFn } = useEditDocketSideScreenStore(
    (state) => ({
      edittedDocket: state.edittedDocket,
      setHandleAddButtonFn: state.setHandleAddButtonFn,
    }),
    shallow
  );
  useEffect(() => {
    setHandleAddButtonFn([]);
  }, []);

  const components: Components<number, DocketInfoListContext> = useMemo(
    () => ({
      Scroller: React.forwardRef((props, ref) => (
        <StyledDiscussionListScroller
          style={{
            ...props.style,
          }}
          ref={ref}
          {...props}
        />
      )),

      Header: (props) => (
        <StyledDocketInfoHeader>
          {props.context?.edittedDocket && (
            <>
              {/* <EditDocketGeneralInfoSection
                docketItem={props.context?.edittedDocket}
              /> */}
              <DocketNameSection />
              <motion.div className="row-field-wrapper">
                <LayoutGroup>
                  <AssigneeSection />

                  <WatcherSection />

                  <DocketDatePickerSection dateType="start" />
                  <DocketDatePickerSection dateType="end" />
                  <ProjectDisplaySelection />
                </LayoutGroup>
              </motion.div>
            </>
          )}
        </StyledDocketInfoHeader>
      ),
      Footer: (props) => (
        <StyledDocketInfoFooter>
          {props.context?.edittedDocket && (
            <>
              <ToggleFunctionalMenuSection />
              <StatusAndPercentSection />
              <LinkDocketSection />
            </>
          )}
        </StyledDocketInfoFooter>
      ),
    }),
    [doxleThemeColor]
  );
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <StyledDocketInfoContainer
        layout="position"
        transition={{
          duration: 0.2,
          damping: 16,
        }}
      >
        <Virtuoso
          style={{ height: "100%", width: "100%" }}
          data={[]}
          components={components}
          context={{
            edittedDocket,
          }}
        />
      </StyledDocketInfoContainer>
    </LocalizationProvider>
  );
};

export default DocketInfo;
