import React, { useEffect, useState } from "react";
import { useProjectStore } from "../../../DoxleGeneralStore/useprojectStore";
import { shallow } from "zustand/shallow";
import useBookingProjectStore from "../../store/useBookingProjectStore";
import { BookingQueryAPI, getBookingMutationKey } from "../../QueryAPI/bookingQueryAPI";
import { useDoxleAuthStore } from "../../../DoxleGeneralStore/useDoxleAuthStore";
import { useDoxleCurrentContextStore } from "../../../DoxleGeneralStore/useDoxleCurrentContext";
import useDoxleNotificationStore from "../../../DoxleGeneralStore/useDoxleNotificationStore";
import { useIsMutating } from "@tanstack/react-query";

type Props = {};

interface BookingProject {
  handleDeleteBooking: () => void;
  handleCloseDeleteDialog: () => void;
}
const useBookingProject = (props: Props): BookingProject => {
  const { setFunctionNewButton } = useProjectStore(
    (state) => ({
      setFunctionNewButton: state.setFunctionNewButton,
    }),
    shallow
  );
  const {
    setOnAddBookingMode,
    setEdittedBooking,
    deletedProjectBooking,
    setDeletedProjectBooking,
    filterBookingProjectQuery,
  } = useBookingProjectStore(
    (state) => ({
      setOnAddBookingMode: state.setOnAddBookingMode,
      setEdittedBooking: state.setEdittedBooking,
      deletedProjectBooking: state.deletedProjectBooking,
      setDeletedProjectBooking: state.setDeletedProjectBooking,
      filterBookingProjectQuery: state.filterBookingProjectQuery,
    }),
    shallow
  );
  
  const { company, currentProject } = useDoxleCurrentContextStore(
    (state) => ({
      company: state.currentCompany,
      currentProject: state.currentProject,
    }),
    shallow
  );
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );
  const { destroy } = BookingQueryAPI.useMutateBookingQuery({
    company,

  
    filter: filterBookingProjectQuery,
    showNotification,
  });
  const handleCloseDeleteDialog = () => {
    setDeletedProjectBooking(undefined);
  };
  const handleDeleteBooking = () => {
    if (deletedProjectBooking) destroy.mutate(deletedProjectBooking.bookingId);
    handleCloseDeleteDialog();
  };
  const isAddingItem = useIsMutating({mutationKey: getBookingMutationKey("add")});

  useEffect(() => {
    setFunctionNewButton([{
      btnFunction: () => {
        setOnAddBookingMode(true);
        setEdittedBooking(undefined);
      },
      isLoading: isAddingItem > 0,
  }]);

    return () => {
      setFunctionNewButton([]);
    };
  }, [isAddingItem]);

  return { handleDeleteBooking, handleCloseDeleteDialog };
};

export default useBookingProject;
