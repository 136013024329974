import { motion } from "framer-motion";
import styled from "styled-components";
import {
  DoxleFont,
  DoxleThemeColor,
} from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { Button, InputAdornment, TextField, Typography } from "@mui/material";
import { editRgbaAlpha } from "../../../Utilities/FunctionUtilities";

export const StyledAddQuoteResponseContainer = styled(motion.div)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  .banner-company-name {
    padding: 0.4rem 0.8rem;
    border-radius: 0.4rem;
    color: ${(p) => p.$themeColor.primaryContainerColor};
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-size: 1.8rem;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    letter-spacing: 0.09rem;
    background-color: ${(p) => p.$themeColor.primaryFontColor};
    margin-bottom: 1rem;
    align-self: flex-start;
  }

  .quote-title-text {
    color: ${(p) => p.$themeColor.primaryFontColor};
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-size: 2.7rem;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    letter-spacing: 0.135rem;
    margin-bottom: 1rem;
  }

  .quote-notice-text {
    color: ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.$themeColor.primaryFontColor,
        alpha: "0.7",
      })};
    font-family: ${(p) => p.$doxleFont.secondaryTitleFont};
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.07rem;
    margin-bottom: 3rem;
  }

  .add-quote-content {
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;

    .add-quote-btn {
      width: calc(100% - 6rem);
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 2rem 3rem;
    }
  }
`;

export const StyledAddResponseLabelFieldText = styled(Typography)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  &.MuiTypography-root {
    font-family: ${(p) => p.$doxleFont.primaryTitleFont};
    color: ${(p) => p.$themeColor.primaryFontColor};
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: normal;
    letter-spacing: 0.05rem;
    white-space: nowrap;
    text-transform: uppercase;
  }
`;
export const StyledAddResponseFieldWrapper = styled(motion.div)`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;

  .field-row-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .attachment-displayer-section {
    min-width: 30%;
    max-width: 70%;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 1.4rem;
  }
`;
export const AddQuoteResponseAutocompleteSx = (
  themeColor: DoxleThemeColor,
  doxleFont: DoxleFont
) => {
  return {
    display: "flex",
    background: themeColor.primaryContainerColor,
    maxWidth: 266,
    "&.MuiOutlinedInput-root": {
      borderRadius: 0,
      height: "3.6rem",
      border: "none",
    },
    "&.MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "&.MuiOutlinedInput-input": {
      color: "white",
      border: "none",
    },
    "&.MuiInputBase-root": {
      border: 0,
      borderRadius: 0,
    },
    ".MuiAutocomplete-inputRoot": {
      borderRadius: "0px 0.9rem 0.9rem 1.2rem",
      height: 36,
      border: `1px solid ${themeColor.rowBorderColor}`,
      padding: "0px 0.3rem",
      color: themeColor.primaryFontColor,
      fontFamily: doxleFont.primaryFont,
      fontSize: "1.4rem",
      fontWeight: 500,
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "&.MuiAutocomplete-input": {
      padding: "0px !important",
      fontFamily: doxleFont.secondaryFont,
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "1.6rem",
      lineHeight: "1.9rem",

      marginLeft: "0.5rem",
      border: "0px !important",
    },
  };
};

export const StyledQuoteResponseListItem = styled.li<{
  $doxleFont: DoxleFont;
  $themeColor: DoxleThemeColor;
  $selected: boolean;
}>`
  margin-bottom: 0.4rem;
  padding: 0.4rem 0.8rem !important;
  width: calc(100% - 16px);
  display: flex;
  font-family: ${(p) => p.$doxleFont.primaryTitleFont};
  color: ${(p) =>
    p.$selected ? p.$themeColor.doxleColor : p.$themeColor.primaryFontColor};
  font-style: normal;
  font-weight: ${(p) => (p.$selected ? 500 : 400)};
  font-size: 1.2rem;
  line-height: normal;
  letter-spacing: 0.05rem;
  white-space: nowrap;

  &:hover {
    background-color: ${(p) => p.$themeColor.rowHoverColor};
    transition: 0.4s;
  }
`;
export const StyledAddResponseDatePickerCustomContainer = styled(motion.div)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  border-radius: 0.2rem;
  background-color: ${(p) => p.$themeColor.primaryContainerColor};
  color: ${(p) => p.$themeColor.primaryFontColor};
  cursor: pointer;
  min-width: 14rem;
  justify-content: center;
  font-size: 1.3rem;
  font-weight: 500;
  align-self: flex-start;
  font-family: ${(p) => p.$doxleFont.primaryFont};
  align-items: center;
  flex-direction: row;
  display: flex;
  border: 1px solid ${(p) => p.$themeColor.rowBorderColor};
  padding: 0.2rem 1.2rem;
  height: 2.6rem;
`;
export const StyledSelectQuoteResponseDate = styled(motion.div)`
  width: 100%;
  display: flex;
`;

export const StyledAddQuoteResponseTextField = styled(TextField)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
  $textAlign?: "left" | "center" | "right";
}>`
  && {
    padding: 0.2rem 0.8rem !important;

    min-width: 50px;
    width: 20rem;
    background-color: ${(p) => p.$themeColor.primaryContainerColor};
    border-radius: 0.4rem;
    height: 2.6rem;
    display: flex;
    flex-direction: row;
    align-items: center;

    border: 1px solid ${(p) => p.$themeColor.rowBorderColor};
  }
  input {
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-weight: 500;
    font-size: 1.3rem;
    line-height: normal;
    text-align: ${(p) => p.$textAlign ?? "left"};
    color: ${(p) => p.$themeColor.primaryFontColor};
    padding: 0px !important;
  }
  & .MuiInput-root:after {
    border-bottom: none !important;
  }
  & .MuiInput-root:before {
    border-bottom: none !important;
  }
  & .MuiInput-root {
    border-bottom: none !important;
  }
  & .MuiInput-root:hover:not(.Mui-disabled, .Mui-error):before {
    border-bottom: none !important;
  }
  & .MuiInputBase-root {
    width: 100%;
  }
`;

export const StyledErrorText = styled(motion.span)<{
  $doxleFont: DoxleFont;
}>`
  font-family: ${(p) => p.$doxleFont.primaryFont};
  font-weight: 500;
  font-size: 1rem;
  line-height: normal;
  color: red;
  margin-top: 0.4rem;
`;

export const StyledAddResponseInputAdornment = styled(InputAdornment)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  & .MuiTypography-root {
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-weight: 500;
    font-size: 1rem;
    line-height: normal;
    color: ${(p) => p.$themeColor.primaryFontColor};
    cursor: pointer;
  }
`;
export const StyledPaymentSuffixPopupContainer = styled.div<{
  $doxleFont: DoxleFont;
  $themeColor: DoxleThemeColor;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${(p) => p.$themeColor.primaryContainerColor};
  border-radius: 0px 0.4rem;
  padding: 0.8rem;
  .suffix-choice {
    padding: 0.4rem 0.8rem;
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-weight: 500;
    font-size: 1rem;
    line-height: normal;
    color: ${(p) => p.$themeColor.primaryFontColor};
    border-radius: 4px;
    cursor: pointer;
    text-transform: uppercase;
    &:hover {
      background-color: ${(p) => p.$themeColor.rowHoverColor};
      transition: 0.4s;
      font-weight: 600;
    }
  }
`;
export const StyledResponseNoticeText = styled.span<{
  $doxleFont: DoxleFont;
  $themeColor: DoxleThemeColor;
}>`
  font-family: ${(p) => p.$doxleFont.primaryFont};
  font-weight: 500;
  font-size: 1.2rem;
  line-height: normal;
  color: ${(p) => p.$themeColor.primaryFontColor};
  margin-left: 0.8rem;
`;
export const StyledAddedResponseAttachmentItem = styled(motion.div)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.4rem 0.8rem;

  margin: 0.4rem 0.7rem;
  cursor: pointer;
  position: relative;
  color: ${(p) => p.$themeColor.primaryFontColor};

  font-family: ${(p) => p.$doxleFont.primaryFont};
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  .img-wrapper {
    width: 2rem;
    aspect-ratio: 1;
    margin-right: 1rem;
  }
`;
export const StyledAddedAttachmentItem = styled(motion.div)`
  aspect-ratio: 1;
  border-radius: 0.4rem;
  position: relative;
  display: inline-block;
  justify-content: center;
  align-items: center;
  margin: 0.4rem;
  height: auto;
  width: 100px;
`;
export const StyledAttachmentName = styled.div`
  color: ${(p) => p.theme.color.primaryFontColor};
  width: 100px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 12px;
`;
export const StyledImageContainer = styled.div`
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  border: 1px solid ${(p) => p.theme.color.primaryDividerColor};
  border-radius: 4px;
`;
export const StyledFileImage = styled.img`
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
`;
export const StyledAddResponseLineSection = styled.div<{
  $themeColor: DoxleThemeColor;
}>`
  padding-top: 0.8rem;
  width: 100%;
  display: flex;
  flex-direction: column;

  margin-top: 1.4rem;
`;
export const StyledAddQuoteLinePlaceholder = styled.div`
  cursor: pointer;
  width: 100%;
  border-top: 1px solid ${(p) => p.theme.color.primaryDividerColor};
  border-bottom: 1px solid ${(p) => p.theme.color.primaryDividerColor};
  font-weight: 400;
  font-size: 1.3rem;
  color: ${(p) => p.theme.color.primaryInputPlaceholderColor};
  display: flex;
  align-items: center;
  padding: 0.8rem;
`;
export const StyledAddQuoteLineTextField = styled(TextField)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  && {
    overflow: hidden;
    min-width: 100px;
    width: calc(100% - 1.6rem);
    padding: 0.8rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-top: 1px solid ${(p) => p.$themeColor.primaryDividerColor};
    border-bottom: 1px solid ${(p) => p.$themeColor.primaryDividerColor};
  }
  input {
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-weight: 400;
    font-size: 1.3rem;
    line-height: normal;
    width: 100% !important;
    color: ${(p) => p.$themeColor.primaryFontColor};
    padding: 0px !important;
    &::placeholder {
      font-family: ${(p) => p.$doxleFont.primaryFont};
      font-weight: 400;
      font-size: 1.3rem;
      line-height: normal;
      color: ${(p) => p.$themeColor.primaryInputPlaceholderColor};
      padding: 0px !important;
      opacity: 1;
    }
  }
  & .MuiInput-root:after {
    border-bottom: none !important;
  }
  & .MuiInput-root:before {
    border-bottom: none !important;
  }
  & .MuiInput-root {
    border-bottom: none !important;
  }
  & .MuiInput-root:hover:not(.Mui-disabled, .Mui-error):before {
    border-bottom: none !important;
  }
  & .MuiInputBase-root {
    width: 100%;
  }
`;
export const StyledAddQuoteLineHeader = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  width: calc(100% - 1.6rem);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 0.8rem;
  margin-bottom: 0.4rem;
  .header-field-wrapper {
    color: ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.$themeColor.primaryFontColor,
        alpha: "0.7",
      })};
    font-family: ${(p) => p.$doxleFont.primaryTitleFont};
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    text-align: center;
  }
  .icon-space-header {
    width: 2rem;
  }
`;
export const StyledAddedQuoteLineItem = styled(motion.div)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  width: calc(100% - 1.6rem);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 0.8rem;
  background-color: ${(p) =>
    editRgbaAlpha({ rgbaColor: p.$themeColor.doxleColor, alpha: "0.08" })};
  margin-bottom: 0.4rem;
  .line-field-wrapper {
    color: ${(p) => p.$themeColor.primaryFontColor};
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0.8rem 0px;
  }
  .icon-close-wrapper {
    width: 2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;

export const StyledQuoteLineItemTextField = styled(TextField)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
  $textAlign?: "center" | "left";
}>`
  && {
    overflow: hidden;

    width: calc(100% - 0.8rem);
    padding: 0px 0.4rem;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  input {
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-weight: 400;
    font-size: 1.4rem;
    line-height: normal;
    width: 100% !important;
    color: ${(p) => p.$themeColor.primaryFontColor};
    padding: 0px !important;
    text-align: ${(p) => p.$textAlign ?? "center"};
    &::placeholder {
      font-family: ${(p) => p.$doxleFont.primaryFont};
      font-weight: 400;
      font-size: 1.4rem;
      line-height: normal;
      color: ${(p) => p.$themeColor.primaryInputPlaceholderColor};
      padding: 0px !important;
      opacity: 1;
    }
  }
  & .MuiInput-root:after {
    border-bottom: none !important;
  }
  & .MuiInput-root:before {
    border-bottom: none !important;
  }
  & .MuiInput-root {
    border-bottom: none !important;
  }
  & .MuiInput-root:hover:not(.Mui-disabled, .Mui-error):before {
    border-bottom: none !important;
  }
  & .MuiInputBase-root {
    width: 100%;
  }
`;
export const StyledQuoteLineInputAdornment = styled(InputAdornment)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  && {
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-weight: 400;
    font-size: 1.4rem;
    line-height: normal;

    color: ${(p) => p.$themeColor.primaryFontColor};
  }
`;
export const StyledQuoteTotalFieldWrapper = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: center;
  padding: 0.8rem 0px;
  width: 18rem;
  .total-label {
    color: ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.$themeColor.primaryFontColor,
        alpha: "0.7",
      })};
    font-family: ${(p) => p.$doxleFont.primaryTitleFont};
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.05rem;
    display: flex;
    justify-content: flex-end;
    text-transform: uppercase;
    width: 8rem;
  }
  .total-value {
    color: ${(p) => p.$themeColor.primaryFontColor};
    font-family: ${(p) => p.$doxleFont.primaryTitleFont};
    font-size: 1.3rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.05rem;
    margin-left: 8px;
    flex: 1;
    display: flex;
  }
`;
export const StyledQuoteTotalSection = styled(motion.div)`
  padding: 2rem 3rem;
  width: calc(100% - 6rem);
  align-items: flex-end;

  display: flex;
  flex-direction: column;
`;
export const StyledAddQuotesButton = styled(Button)<{
  $doxleFont: DoxleFont;
  $bgColor: string;
  $textColor: string;
  $borderColor?: string;
}>`
  && {
    min-width: 7.2rem;
    height: 2.3rem;
    ${(p) => p.$borderColor && `border:1px solid ${p.$borderColor};`}
    text-transform: capitalize;
    background-color: ${(p) => p.$bgColor};
    color: ${(p) => p.$textColor};
    font-family: ${(p) => p.$doxleFont.primaryFont};
    letter-spacing: 0.06rem;
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: center;
    padding: 0 0.5rem;
    cursor: pointer;
    border-radius: 0.4rem !important;
    font-size: 1.4rem;
    font-weight: 600;
    margin: 0px 4px;

    &:hover {
      background-color: ${(p) => p.$bgColor} !important;
      opacity: 0.8;
    }
  }
  &.MuiButton-root.Mui-disabled {
    background-color: ${(p) =>
      editRgbaAlpha({ rgbaColor: p.$bgColor as any, alpha: "0.3" })} !important;
  }
`;
