import {useRef, useState} from "react";
import {useDoxleThemeStore} from "../../../DoxleGeneralStore/useDoxleThemeStore";
import {shallow} from "zustand/shallow";
import {
  StyledEstimateTableCell,
  StyledEstimateTableRow,
  StyledQuickEstimateEstimateRow,
  StyledQuickEstimateTextField
} from "../StyledComponents";
import {Estimate} from "../../Models/estimatesFilters";

import {
  IQuickEstimatesTableHeaders,
  QUICK_ESTIMATE_TABLE_HEADER_LIST,
  TQuickEstimatesTableHeaders
} from "../../Models/estimatesTable";
import {Docket} from "../../../Models/dockets";
import EstimatesQueryAPI from "../../QueryHooks/EstimatesQueryAPI";
import {useDoxleAuthStore} from "../../../DoxleGeneralStore/useDoxleAuthStore";
import {useDoxleCurrentContextStore} from "../../../DoxleGeneralStore/useDoxleCurrentContext";
import useDoxleNotificationStore from "../../../DoxleGeneralStore/useDoxleNotificationStore";
import {useEstimatesStore} from "../Store/useEstimatesStore";
import {formatter, toNum} from "../../../Utilities/FunctionUtilities";

interface Props {
  estimate: Estimate|null,
  docket: Docket,
}

const QuickEstimateEstimateRow = ({
  estimate,
  docket
}: Props) => {
  const [isHover, setIsHover] = useState<boolean>(false);
  const company = useDoxleCurrentContextStore(state => state.currentCompany, shallow)
  const showNotification = useDoxleNotificationStore(state => state.showNotification, shallow)
  const filter = useEstimatesStore(state => state.estimatesQueryFilter, shallow)


  const { doxleThemeColor, doxleFont, currentTheme } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
      currentTheme: state.currentTheme,
    }),
    shallow
  );

  const descriptionRef: React.MutableRefObject<HTMLInputElement|undefined> = useRef()
  const quantityRef: React.MutableRefObject<HTMLInputElement|undefined> = useRef()
  const unitRef: React.MutableRefObject<HTMLInputElement|undefined> = useRef()
  const itemCostRef: React.MutableRefObject<HTMLInputElement|undefined> = useRef()
  const lineCostRef: React.MutableRefObject<HTMLInputElement|undefined> = useRef()

  const estimateMutateQuery = EstimatesQueryAPI.useMutateEstimate({

    company,
    showNotification,
    filter,
    docketId: docket.docketId
  })
  const handleSubmitEstimateField = (headerName: TQuickEstimatesTableHeaders) => {
    const updateBody: Partial<Estimate> = {}
    if (headerName === "Description" && descriptionRef?.current?.value && descriptionRef.current.value !== estimate?.description)
      updateBody.description = descriptionRef.current.value
    if (headerName === "Qty" && quantityRef?.current?.value && quantityRef.current.value !== estimate?.quantity)
      updateBody.quantity = quantityRef.current.value
    if (headerName === "Unit" && unitRef?.current?.value && unitRef.current.value !== estimate?.unit)
      updateBody.unit = unitRef.current.value
    if (headerName === "Item Cost" && itemCostRef?.current?.value && itemCostRef.current.value !== estimate?.unitCost)
      updateBody.unitCost = itemCostRef.current.value
    if (estimate && !!Object.keys(updateBody).length) estimateMutateQuery.update.mutate({
      estimateId: estimate.estimateId,
      updateBody
    })
    if (estimate === null && !!Object.keys(updateBody).length) estimateMutateQuery.add.mutate({
      ... updateBody,
      docket: docket.docketPk,
    })
  }

  const resetField = (headerName: TQuickEstimatesTableHeaders) => {
    if (headerName === "Description" && descriptionRef?.current) descriptionRef.current.value = estimate?.description ?? ""
    if (headerName === "Qty" && quantityRef?.current) quantityRef.current.value = formatter.format(toNum(estimate?.quantity)).substring(1) ?? ""
    if (headerName === "Unit" && unitRef?.current?.value) unitRef.current.value = estimate?.unit ?? ""
    if (headerName === "Item Cost" && itemCostRef?.current?.value )itemCostRef.current.value = formatter.format(toNum(estimate?.quantity)) ?? ""
  }

  const handleKeyDown = (e: React.KeyboardEvent, headerName: TQuickEstimatesTableHeaders) => {
    if (e.key === "Enter") handleSubmitEstimateField(headerName)
    if (e.key === "Escape") resetField(headerName)
  }

  return (
    <StyledQuickEstimateEstimateRow
      themeColor={doxleThemeColor}
      onMouseOver={() => setIsHover(true)}
      onMouseOut={() => setIsHover(false)}
    >
      {QUICK_ESTIMATE_TABLE_HEADER_LIST.map((header, idx) => {
        if (header.isDisplayed) {
          if (header.headerName === "#") {
            return (
              <StyledEstimateTableCell
                hoverStatus={isHover}
                key={`estimateCell#${docket.docketId}${estimate?.estimateId ?? "new"}#${idx}`}
                widthInPixel={"80px"}
                horizontalAlign="flex-start"
                doxleFont={doxleFont}
                themeColor={doxleThemeColor}
                currentTheme={currentTheme}
                fontWeight="400"
                letterSpacing="-0.28px"
              >
                {}
              </StyledEstimateTableCell>
            );
          }
          if (header.headerName === "Description") {
            return (
              <StyledEstimateTableCell
                hoverStatus={isHover}
                key={`estimateCell#${docket.docketId}${estimate?.estimateId ?? "new"}#${idx}`}
                widthInPixel={null}
                horizontalAlign="flex-start"
                doxleFont={doxleFont}
                themeColor={doxleThemeColor}
                currentTheme={currentTheme}
                fontWeight="400"
                letterSpacing="-0.28px"
              >
                <StyledQuickEstimateTextField
                  doxleFont={doxleFont}
                  themeColor={doxleThemeColor}
                  defaultValue={estimate?.description ?? ""}
                  placeholder={"New Estimate Description"}
                  inputRef={descriptionRef}
                  onBlur={() => handleSubmitEstimateField(header.headerName)}
                  onKeyDown={(e) => handleKeyDown(e, header.headerName)}
                />
              </StyledEstimateTableCell>
            );
          }
          if (header.headerName === "Qty") {
            return (
              <StyledEstimateTableCell
                hoverStatus={isHover}
                key={`estimateCell#${docket.docketId}${estimate?.estimateId ?? "new"}#${idx}`}
                widthInPixel={null}
                horizontalAlign="flex-start"
                doxleFont={doxleFont}
                themeColor={doxleThemeColor}
                currentTheme={currentTheme}
                fontWeight="400"
                letterSpacing="-0.28px"
                paddingLeft={"40px"}
              >
                <StyledQuickEstimateTextField
                  doxleFont={doxleFont}
                  themeColor={doxleThemeColor}
                  defaultValue={estimate?.quantity ?? ""}
                  placeholder={"0.00"}
                  inputRef={quantityRef}
                  onBlur={() => handleSubmitEstimateField(header.headerName)}
                />
              </StyledEstimateTableCell>
            );
          }
          if (header.headerName === "Unit") {
            return (
              <StyledEstimateTableCell
                hoverStatus={isHover}
                key={`estimateCell#${docket.docketId}${estimate?.estimateId ?? "new"}#${idx}`}
                widthInPixel={"100px"}
                horizontalAlign="center"
                doxleFont={doxleFont}
                themeColor={doxleThemeColor}
                currentTheme={currentTheme}
                fontWeight="400"
                letterSpacing="-0.28px"
              >
                <StyledQuickEstimateTextField
                  doxleFont={doxleFont}
                  themeColor={doxleThemeColor}
                  defaultValue={estimate?.unit ?? ""}
                  placeholder={"ea"}
                  inputRef={unitRef}
                  onBlur={() => handleSubmitEstimateField(header.headerName)}
                />
              </StyledEstimateTableCell>
            );
          }
          if (header.headerName === "Item Cost") {
            return (
              <StyledEstimateTableCell
                hoverStatus={isHover}
                key={`estimateCell#${docket.docketId}${estimate?.estimateId ?? "new"}#${idx}`}
                widthInPixel={"100px"}
                horizontalAlign="center"
                doxleFont={doxleFont}
                themeColor={doxleThemeColor}
                currentTheme={currentTheme}
                fontWeight="400"
                letterSpacing="-0.28px"
              >
                <StyledQuickEstimateTextField
                  doxleFont={doxleFont}
                  themeColor={doxleThemeColor}
                  defaultValue={estimate?.unitCost ?? ""}
                  placeholder={"$0.00"}
                  inputRef={itemCostRef}
                  onBlur={() => handleSubmitEstimateField(header.headerName)}
                />
              </StyledEstimateTableCell>
            );
          }
          if (header.headerName === "Line Cost") {
            return (
              <StyledEstimateTableCell
                hoverStatus={isHover}
                key={`estimateCell#${docket.docketId}${estimate?.estimateId ?? "new"}#${idx}`}
                widthInPixel={"100px"}
                horizontalAlign="center"
                doxleFont={doxleFont}
                themeColor={doxleThemeColor}
                currentTheme={currentTheme}
                fontWeight="400"
                letterSpacing="-0.28px"
              >
                <StyledQuickEstimateTextField
                  doxleFont={doxleFont}
                  themeColor={doxleThemeColor}
                  value={estimate?.lineCost ?? ""}
                  placeholder={"$0.00"}
                  inputRef={lineCostRef}
                />
              </StyledEstimateTableCell>
            );
          }
        }
      })}
    </StyledQuickEstimateEstimateRow>
  );
};

export default QuickEstimateEstimateRow
