import { MenuItem, Select, TextField} from "@mui/material";
import TouchAppOutlinedIcon from "@mui/icons-material/TouchAppOutlined";
import ColourMenu from "./ColourMenu";
import React, {useState} from "react";
import {useMeasurementsStore} from "../MeasurementsStore/useMeasurementsStore";
import {shallow} from "zustand/shallow";
import {ICONS} from "../Assets/icons";



const ToolBar: React.FC = () => {
  const [heightUnit, setHeightUnit] = useState<"mm"|"m">("mm");
  const {
    selectedTakeOff,
    tool,
    setTool,
    currentUnit,
    deduction,
    toggleDeduction,
    displayLabels,
    toggleDisplayTags,
    currentColour,
    setCurrentColour,
    zHeight,
    setZHeight
  } = useMeasurementsStore(state => ({
    selectedTakeOff: state.currentTakeOff,
    tool: state.currentTool,
    setTool: state.setCurrentTool,
    currentUnit: state.currentUnit,
    deduction: state.deduction,
    toggleDeduction: state.toggleDeduction,
    displayLabels: state.displayTags,
    toggleDisplayTags: state.toggleDisplayTags,
    currentColour: state.currentColor,
    setCurrentColour: state.setCurrentColor,
    zHeight: state.zHeight,
    setZHeight: state.setZHeight,
  }), shallow);

  return(
    <div className="measurement-tools">
      <div className="measurement-tools">

        {selectedTakeOff ?
          <div className="measurement-tools-container">
            <ul>
              {currentUnit !== "EA" &&
                <><li
                  onClick={() => {
                    setTool("Polygon");
                  }}
                  className={`measurement-tools-list ${tool === "Polygon" && "active"}`}
                >
                  <img src={ICONS.Polygon} className="measurement-icons" alt="" />
                  <b className="icon1">Polygon</b>
                </li>
                  <li
                    onClick={() => {
                      setTool("Rectangle");
                    }}
                    className={`measurement-tools-list ${tool === "Rectangle"?"active":""}`}
                  >
                    <img src={ICONS.StopSquare} className="measurement-icons" alt="" />
                    <b className="icon2">Square</b>
                  </li>
                  <li
                    onClick={() => {
                      setTool("Circle");
                    }}
                    className={`measurement-tools-list ${tool === "Circle"?"active":""}`}
                  >
                    <img src={ICONS.Ellipse} className="measurement-icons" alt="" />
                    <b className="icon3">&nbsp;&nbsp;&nbsp;Circle</b>
                  </li>
                  {currentUnit !== "M3" && <li
                    onClick={() => {
                      setTool("Line");
                    }}
                    className={`measurement-tools-list ${tool === "Line"?"active":""}`}
                  >
                    <img src={ICONS.LineSvg} className="measurement-icons" alt="" />
                    <b className="icon4">&nbsp;&nbsp;&nbsp;&nbsp;Line</b>
                  </li>}

                </>
              }

              {currentUnit === "EA" &&
                <li
                  onClick={() => {
                    setTool("Selector");
                  }}
                  className={`measurement-tools-list ${tool === "Selector"?"active":""}`}
                >
                  <TouchAppOutlinedIcon color="action" className="measurement-icons" sx={{pt:"3px", pl:"15px"}}/>
                </li>
              }

              <li
                className={`measurement-tools-list`}
                style={{background: currentColour}}
              >
                <b className="icon5">&nbsp;&nbsp;&nbsp;Colour</b>
                <ColourMenu toolColor={currentColour} setToolColor={setCurrentColour}/>
              </li>

              <li onClick={() => toggleDisplayTags()}
                  className={`measurement-tools-list ${displayLabels?"active":""}`}
              >
                <img src={ICONS.SwatchBook} className="measurement-icons" alt="" />
                <b className="icon6">&nbsp;&nbsp;Labels</b>
              </li>
              <li
                onClick={() => toggleDeduction()}
                style={{textAlign: "center", color: "#9974fe"}}
                className={`measurement-tools-list ${deduction < 0 ? "active" : ""}`}
              >
                ±
                <b className="icon4" style={{textAlign: "left", color: "black"}}>Deduction</b>
              </li>
              {
                currentUnit === "M3" || (currentUnit === "M2" && tool === "Line") ?
                  <li className="measurement-tools-list input-height">
                    &nbsp;&nbsp;Height:&nbsp;&nbsp;&nbsp;&nbsp;
                    <TextField
                      variant="standard"
                      sx={{width: "40px", height: "100%"}}
                      value={heightUnit === "m" ? zHeight : zHeight * 1000}
                      InputProps={{sx: {fontSize: "13px", fontFamily: "Roboto"}, disableUnderline: true}}
                      onChange={(e) => {
                        setZHeight(heightUnit === "m"
                          ? !isNaN(parseFloat(e.target.value))
                            ? parseFloat(e.target.value)
                            : 1
                          : !isNaN(parseFloat(e.target.value))
                            ? parseFloat(e.target.value) / 1000
                            : 1
                        )
                      }}
                    />
                    <Select
                      sx={{width: "60px", height: "100%"}}
                      variant="standard"
                      value={heightUnit}
                      disableUnderline={true}
                      onChange={(e) => setHeightUnit(e.target.value === "mm" || e.target.value === "m" ? e.target.value : "mm")}
                      displayEmpty
                      inputProps={{ sx: {fontSize: "13px", fontFamily: "Roboto"}, 'aria-label': 'Without label' }}
                    >
                      <MenuItem value={"mm"}>mm</MenuItem>
                      <MenuItem value={"m"}>m</MenuItem>
                    </Select>
                  </li>
                  :
                  null
              }
            </ul>
          </div>
          : null
        }
      </div>
    </div>
  )
}

export default ToolBar