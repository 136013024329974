import {
  StyledEditProjectAddressTextField,
  StyledProjectGroupTitleContainer,
} from "./StyledNoticeBoardContent";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import { FiEdit3 } from "react-icons/fi";
import { SlTrash } from "react-icons/sl";
import DoxleDialogHelper from "../../../DoxleDesignPattern/DoxleDialogHelper/DoxleDialogHelper";
import CircularProgress from "@mui/material/CircularProgress";
import { DoxleNBProjectIcon } from "../../../DoxleDesignPattern/DoxleGeneralIcons";
import useNBProjectGroupHeader from "./Hooks/useNBProjectGroupHeader";

type Props = {
  projectId: string | null;
  projectSiteAddress: string;
};

const NBProjectGroupHeader = ({ projectId, projectSiteAddress }: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }),
    shallow
  );

  const {
    showDeleteDialog,
    setShowDeleteDialog,
    handleClickDeleteIcon,
    handleCloseDeleteDialog,
    handleClickEditBtn,
    onEditProject,
    handleDeleteNBProject,
    isDeletingProject,
    isUpdatingProject,
    newAddressText,
    setnewAddressText,
    handleInputKeyDown,
  } = useNBProjectGroupHeader({
    projectId,
    projectSiteAddress,
  });

  return (
    <>
      {projectId && (
        <StyledProjectGroupTitleContainer
          $themeColor={doxleThemeColor}
          $doxleFont={doxleFont}
        >
          {!isDeletingProject && !isUpdatingProject ? (
            <DoxleNBProjectIcon
              themeColor={doxleThemeColor}
              containerStyle={{
                width: 20,
              }}
            />
          ) : (
            <CircularProgress
              size="2rem"
              style={{
                color: isDeletingProject
                  ? "red"
                  : doxleThemeColor.primaryFontColor,
              }}
            />
          )}

          {!onEditProject ? (
            <span className="project-address-title">{projectSiteAddress}</span>
          ) : (
            <StyledEditProjectAddressTextField
              variant="standard"
              $themeColor={doxleThemeColor}
              $doxleFont={doxleFont}
              value={newAddressText}
              onChange={(e) => setnewAddressText(e.target.value)}
              placeholder="New project address..."
              autoFocus
              onFocus={(e) => e.currentTarget.select()}
              onKeyDown={handleInputKeyDown}
            />
          )}

          <FiEdit3 className="menu-icon" onClick={handleClickEditBtn} />

          <SlTrash className="menu-icon" onClick={handleClickDeleteIcon} />
        </StyledProjectGroupTitleContainer>
      )}
      <DoxleDialogHelper
        open={showDeleteDialog}
        onClose={handleCloseDeleteDialog}
        title="Confirm delete project!"
        description={`All notice items belong to project ***${projectSiteAddress}*** will be deleted permanently, are you sure to proceed?`}
        dialogButtons={[
          {
            buttonText: "Delete",
            buttonFunction: handleDeleteNBProject,
            btnStyleSx: {
              border: `1px solid ${doxleThemeColor.primaryDividerColor}`,
            },
          },
          {
            buttonText: "Cancel",
            buttonFunction: handleCloseDeleteDialog,
            buttonColor: doxleThemeColor.primaryContainerColor,
            btnStyleSx: {
              border: `1px solid ${doxleThemeColor.primaryDividerColor}`,
            },
            buttonTextStyle: {
              color: doxleThemeColor.primaryFontColor,
            },
          },
        ]}
      />
    </>
  );
};

export default NBProjectGroupHeader;
