import React from "react";
import { StyledQuoteRejectPage } from "./StyledComponentQuoteResponse";
import rejectImg from "../../../Assets/QuoteReject.png";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import { useNavigate } from "react-router-dom";

type Props = {};

const QuoteRejectPage = (props: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,

      doxleFont: state.doxleFont,
    }),
    shallow
  );
  const navigate = useNavigate();
  const handleNavigateToDoxle = () => navigate("/");
  return (
    <StyledQuoteRejectPage
      $themeColor={doxleThemeColor}
      $doxleFont={doxleFont}
      initial={{ x: -500 }}
      animate={{ x: 0 }}
    >
      <img className="reject-img" src={rejectImg} />
      <div className="reject-text">
        Your quote response has been submitted...
      </div>
      <div className="navigate-text" onClick={handleNavigateToDoxle}>
        Go to Doxle
      </div>
    </StyledQuoteRejectPage>
  );
};

export default QuoteRejectPage;
