import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import { StyledEditDocketFullScreenModeContainer } from "../StyledComponentsEditDocketSideScreen";
import TopGeneralInfo from "../EditDocketSideScreenCommonContent/TopGeneralInfo";
import EditDocketFullScreenContentSection from "./EditDocketFullScreenContentSection";
import { useIsMutating } from "@tanstack/react-query";
import { useEditDocketSideScreenStore } from "../EditDocketSideScreenStore/useEditDocketSideScreenStore";
import { getDocketMutationKey } from "../../../Services/QueryHooks/docketQueryAPI";
import Loading from "../../../Utilities/Lottie/Loading";

type Props = {
  tab?: string;
};

const EditDocketFullScreenMode = ({ tab }: Props) => {
  const { doxleThemeColor, staticMenuColor } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      staticMenuColor: state.staticMenuColor,
    }),
    shallow
  );

  const containerAnimatedVariants = {
    entering: {
      y: [10, 0],
      opacity: [0, 1],
      transition: {
        duration: 0.4,
      },
    },
  };
  const { edittedDocket } = useEditDocketSideScreenStore(
    (state) => ({
      edittedDocket: state.edittedDocket,
    }),
    shallow
  );
  const isDeletingDocket =
    useIsMutating({
      mutationKey: getDocketMutationKey("delete"),
      exact: true,
      predicate: (query) =>
        Boolean(query.state.variables === edittedDocket?.docketPk),
    }) > 0;
  return (
    <StyledEditDocketFullScreenModeContainer
      $themeColor={doxleThemeColor}
      variants={containerAnimatedVariants}
      initial={false}
      animate="entering"
    >
      <TopGeneralInfo />

      <EditDocketFullScreenContentSection />

      {isDeletingDocket && (
        <Loading
          text="Deleting..."
          textStyle={{
            color: staticMenuColor.staticFontColor,
          }}
          containerStyle={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: 100000,
            backgroundColor: staticMenuColor.staticBackdrop,
          }}
        />
      )}
    </StyledEditDocketFullScreenModeContainer>
  );
};

export default EditDocketFullScreenMode;
