import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import DoxleAPI from "../../Services/DoxleAPI";
import { BaseAPIProps } from "../../Models/baseAPIProps";
import { Company } from "../../Models/company";
import {
  ScopeOfWorkGroup,
  ScopeOfWorkImage,
  ScopeOfWorkItem,
} from "../Model/scopeOfWorks";
import {
  AxiosBackendErrorReturn,
  IApiPaginatedData,
} from "../../Models/axiosReturn";
import useSetScopeOfWorkGroupQueryData from "../Hooks/useSetScopeOfWorkGroupQueryData";
import useSetSOWItemQueryData from "../Hooks/useSetSOWItemQueryData";
import useSetSOWImgQueryData from "../Hooks/useSetSOWImgQueryData";
import { isAxiosError } from "axios";

// const baseUrlSOWItem = ""/scope/";

export interface FilterRetrieveScopeOfWorkQuery {
  docketId?: string;
  projectId?: string;
}
interface RetrieveScopeOfWorkGroupProps extends BaseAPIProps {
  filter: FilterRetrieveScopeOfWorkQuery;
  onSuccessCb?: Function;
}

const useRetrieveScopeGroupList = ({
  company,
  filter,
  showNotification,
  onSuccessCb,
}: RetrieveScopeOfWorkGroupProps) => {
  const qKey = formScopeOfWorkGroupQkey(filter, company);
  const { docketId, projectId } = filter;
  let getParams: any = {};
  if (company) getParams.company = company.companyId;
  if (docketId) {
    getParams.docket = docketId;
  }
  if (projectId) {
    getParams.project = projectId;
  }

  const baseUrlSOWGroup = "/scope/group/?page=1";
  const getScopeOfWorkGroupQuery = useInfiniteQuery({
    queryKey: qKey,
    queryFn: async ({ pageParam = baseUrlSOWGroup }) => {
      return DoxleAPI.get<IApiPaginatedData<ScopeOfWorkGroup>>(pageParam, {
        headers: {
          "User-Company": company?.companyId || "",
        },
        params: getParams,
      });
    },
    getNextPageParam: (prevData) => prevData.data?.next,
    onSuccess: () => {
      if (onSuccessCb) onSuccessCb();
    },

    onError: (error) => {
      if (showNotification) {
        if (isAxiosError<AxiosBackendErrorReturn>(error)) {
          showNotification(
            `${error?.response?.status ?? "ERROR"}: ${
              error.response?.data.detail ?? "UNKNOWN ERROR"
            }`,
            "error",
            String(
              error?.response?.data?.detail ??
                "Failed to get scope of work list"
            ).substring(0, 300)
          );
        } else {
          showNotification(
            "Something Wrong!",
            "error",
            "Failed to get scope of work list"
          );
        }
      }
    },
    enabled: Boolean(company && (docketId || projectId)),
    retry: 1,
    refetchInterval: 4 * 60 * 1000,
    // refetchInterval: 5 * 60 * 1000,

    refetchIntervalInBackground: false,
    refetchOnWindowFocus: false,
    // refetchOnReconnect: true,
  });
  return getScopeOfWorkGroupQuery;
};

interface AddScopeGroupAPIProps extends BaseAPIProps {
  filter: FilterRetrieveScopeOfWorkQuery;
  onSuccessCb?: Function;
}
const useAddScopeGroupQuery = ({
  showNotification,

  company,
  filter,
  onSuccessCb,
}: AddScopeGroupAPIProps) => {
  const { handleAddSOWGroup } = useSetScopeOfWorkGroupQueryData({ filter });
  const baseUrlSOWGroup = "/scope/group/";
  const addScopeGroupQuery = useMutation({
    mutationFn: async (data: ScopeOfWorkGroup) => {
      return DoxleAPI.post<ScopeOfWorkGroup>(baseUrlSOWGroup, data, {
        headers: {
          "User-Company": company?.companyId ?? "",
        },
      });
    },
    onSuccess(result, variables, context) {
      handleAddSOWGroup({ ...result.data, isNew: true });

      if (onSuccessCb) onSuccessCb();
    },
    onError(error, variables, context) {
      console.log("PostTaskGroupERROR");
      console.log(error);
      if (showNotification) {
        if (isAxiosError<AxiosBackendErrorReturn>(error)) {
          showNotification(
            `${error?.response?.status ?? "ERROR"}: ${
              error.response?.data.detail ?? "UNKNOWN ERROR"
            }`,
            "error",
            String(
              error?.response?.data?.detail ?? "Failed to add item"
            ).substring(0, 300)
          );
        } else {
          showNotification("Something Wrong!", "error", "Failed to add item");
        }
      }
    },
  });
  const mutate = (data: ScopeOfWorkGroup) => addScopeGroupQuery.mutate(data);
  return { ...addScopeGroupQuery, mutate };
};

interface UpdateScopeGroupAPIProps extends BaseAPIProps {
  filter: FilterRetrieveScopeOfWorkQuery;
  onSuccessCb?: (
    sowGroup?: ScopeOfWorkGroup,
    lastPayload?: UpdateScopeGroupData
  ) => void;
}
export interface UpdateScopeGroupData
  extends Pick<ScopeOfWorkGroup, "scopeGroupId">,
    Partial<
      Pick<
        ScopeOfWorkGroup,
        | "scopeGroupTitle"
        | "assignedContactCompany"
        | "assignedContacts"
        | "docket"
        | "project"
      >
    > {
  completed?: boolean;
}
const useUpdateScopeGroupQuery = ({
  showNotification,
  company,
  filter,
  onSuccessCb,
}: UpdateScopeGroupAPIProps) => {
  const { handleUpdateSOWGroup } = useSetScopeOfWorkGroupQueryData({ filter });
  const baseUrlSOWGroup = "/scope/group/";
  const updateSOWGroupQuery = useMutation({
    mutationKey: getSOWGroupMutationKey("update"),
    mutationFn: async (data: UpdateScopeGroupData) => {
      const { scopeGroupId, ...rest } = data;
      return DoxleAPI.patch<ScopeOfWorkGroup>(
        baseUrlSOWGroup + `${scopeGroupId}/`,
        rest,
        {
          headers: {
            "User-Company": company?.companyId ?? "",
          },
        }
      );
    },
    onSuccess(result, variables, context) {
      handleUpdateSOWGroup(result.data);

      // if (showNotification) showNotification("Item Updated", "success");

      if (onSuccessCb) onSuccessCb(result.data, variables);
      //console.log(result);
    },
    onError(error, variables, context) {
      console.log("PostTaskGroupERROR");
      console.log(error);
      if (showNotification) {
        if (isAxiosError<AxiosBackendErrorReturn>(error)) {
          showNotification(
            `${error?.response?.status ?? "ERROR"}: ${
              error.response?.data.detail ?? "UNKNOWN ERROR"
            }`,
            "error",
            String(
              error?.response?.data?.detail ?? "Failed to update item"
            ).substring(0, 300)
          );
        } else {
          showNotification(
            "Something Wrong!",
            "error",
            "Failed to update item"
          );
        }
      }
    },
  });
  const mutate = (data: UpdateScopeGroupData) =>
    updateSOWGroupQuery.mutate(data);
  return { ...updateSOWGroupQuery, mutate };
};

interface DeleteScopeGroupAPIProps extends BaseAPIProps {
  filter: FilterRetrieveScopeOfWorkQuery;
  onSuccessCb?: Function;
}
const useDeleteScopeGroupQuery = ({
  showNotification,

  company,
  filter,
  onSuccessCb,
}: DeleteScopeGroupAPIProps) => {
  const baseUrlSOWGroup = "/scope/group/";
  const updateSOWGroupQuery = useMutation({
    mutationFn: async (groupId: string) => {
      return DoxleAPI.delete(baseUrlSOWGroup + `${groupId}/`, {
        headers: {
          "User-Company": company?.companyId ?? "",
        },
      });
    },
    onSuccess(result, variables, context) {
      // if (showNotification) showNotification("Item Updated", "success");

      if (onSuccessCb) onSuccessCb();
      //console.log(result);
    },
    onError(error, variables, context) {
      console.log("PostTaskGroupERROR");
      console.log(error);
      if (showNotification) {
        if (isAxiosError<AxiosBackendErrorReturn>(error)) {
          showNotification(
            `${error?.response?.status ?? "ERROR"}: ${
              error.response?.data.detail ?? "UNKNOWN ERROR"
            }`,
            "error",
            String(
              error?.response?.data?.detail ?? "Failed to delete item"
            ).substring(0, 300)
          );
        } else {
          showNotification(
            "Something Wrong!",
            "error",
            "Failed to delete item"
          );
        }
      }
    },
  });
  const mutate = (groupId: string) => updateSOWGroupQuery.mutate(groupId);
  return { ...updateSOWGroupQuery, mutate };
};
//#SCOPE ITEMS SECTION
export interface FilterRetrieveScopeItemQuery {
  scopeGroupId: string;
}
interface RetrieveScopeItemQueryProps extends BaseAPIProps {
  filter: FilterRetrieveScopeItemQuery;
  enabled: boolean;
  onSuccessCb?: Function;
}
const useRetrieveScopeItemListQuery = ({
  filter,
  enabled,
  showNotification,
  company,
  onSuccessCb,
}: RetrieveScopeItemQueryProps) => {
  const qKey = formScopeOfWorkItemQKey(filter, company);
  const { scopeGroupId } = filter;
  let getParams: any = {};
  // if (company) getParams.company = company.companyId;
  // if (docket) {
  //   getParams.docket = docket;
  // }
  // if (project) {
  //   getParams.project = project;
  // }
  getParams.scope_group = scopeGroupId;

  const baseUrlSOWGroup = "/scope/";
  const getScopeOfWorkItemQuery = useQuery({
    queryKey: qKey,
    queryFn: async () => {
      return DoxleAPI.get<ScopeOfWorkItem[]>(baseUrlSOWGroup, {
        headers: {
          "User-Company": company?.companyId || "",
        },
        params: getParams,
      });
    },
    onSuccess: () => {
      if (onSuccessCb) onSuccessCb();
    },

    onError: (error) => {
      if (showNotification) {
        if (isAxiosError<AxiosBackendErrorReturn>(error)) {
          showNotification(
            `${error?.response?.status ?? "ERROR"}: ${
              error.response?.data.detail ?? "UNKNOWN ERROR"
            }`,
            "error",
            String(
              error?.response?.data?.detail ??
                "Failed to get scope of work list"
            ).substring(0, 300)
          );
        } else {
          showNotification(
            "Something Wrong!",
            "error",
            "Failed to get scope of work list"
          );
        }
      }
    },
    enabled: Boolean(company && enabled),
    retry: 1,

    // refetchIntervalInBackground: true,
    refetchIntervalInBackground: false,
    refetchOnWindowFocus: false,
    staleTime: 4 * 60 * 1000,
    cacheTime: 6 * 60 * 1000,
    refetchInterval: 5 * 60 * 1000,
    // refetchOnReconnect: true,
  });
  return getScopeOfWorkItemQuery;
};

interface AddScopeItemQueryProps extends BaseAPIProps {
  filter: FilterRetrieveScopeItemQuery;
  onSuccessCb?: (newItem: ScopeOfWorkItem) => void;
}
const useAddScopeItemQuery = ({
  showNotification,

  company,
  filter,
  onSuccessCb,
}: AddScopeItemQueryProps) => {
  const baseUrlSOWItem = "/scope/new/";
  const { handleAddSOWItem } = useSetSOWItemQueryData({ filter });
  const addScopeItemQuery = useMutation({
    mutationFn: async (data: ScopeOfWorkItem) => {
      return DoxleAPI.post<ScopeOfWorkItem>(baseUrlSOWItem, data, {
        headers: {
          "User-Company": company?.companyId ?? "",
        },
      });
    },
    onSuccess(result, variables, context) {
      handleAddSOWItem(result.data);

      if (onSuccessCb) onSuccessCb(result.data);
      //console.log(result);
    },
    onError(error, variables, context) {
      console.log("PostTaskItemERROR");
      console.log(error);
      if (showNotification) {
        if (isAxiosError<AxiosBackendErrorReturn>(error)) {
          showNotification(
            `${error?.response?.status ?? "ERROR"}: ${
              error.response?.data.detail ?? "UNKNOWN ERROR"
            }`,
            "error",
            String(
              error?.response?.data?.detail ?? "Failed to add task item"
            ).substring(0, 300)
          );
        } else {
          showNotification(
            "Something Wrong!",
            "error",
            "Failed to add task item"
          );
        }
      }
    },
  });
  const mutate = (data: ScopeOfWorkItem) => addScopeItemQuery.mutate(data);
  return { ...addScopeItemQuery, mutate };
};

interface DeleteScopeItemQueryProps extends BaseAPIProps {
  filter: FilterRetrieveScopeItemQuery;
  onSuccessCb?: Function;
}

const useDeleteScopeItemQuery = ({
  showNotification,

  company,
  filter,
  onSuccessCb,
}: DeleteScopeItemQueryProps) => {
  const addScopeItemQuery = useMutation({
    mutationFn: async (deletedScopeItemId: string) => {
      const baseUrlSOWItem = `/scope/${deletedScopeItemId}/`;
      return DoxleAPI.delete(baseUrlSOWItem, {
        headers: {
          "User-Company": company?.companyId ?? "",
        },
      });
    },
    onSuccess(result, variables, context) {
      // if (showNotification) showNotification("Deleted Scope ITem", "success");

      if (onSuccessCb) onSuccessCb();
    },
    onError(error, variables, context) {
      console.log(error);
      if (showNotification) {
        if (isAxiosError<AxiosBackendErrorReturn>(error)) {
          showNotification(
            `${error?.response?.status ?? "ERROR"}: ${
              error.response?.data.detail ?? "UNKNOWN ERROR"
            }`,
            "error",
            String(
              error?.response?.data?.detail ?? "FAILED TO DELETE Item"
            ).substring(0, 300)
          );
        } else {
          showNotification(
            "Something Wrong!",
            "error",
            "FAILED TO DELETE Item"
          );
        }
      }
    },
  });
  const mutate = (deletedScopeItemId: string) =>
    addScopeItemQuery.mutate(deletedScopeItemId);
  return { ...addScopeItemQuery, mutate };
};

interface MultiDeleteScopeItemQueryProps extends BaseAPIProps {
  filter: FilterRetrieveScopeOfWorkQuery;
  onSuccessCb?: Function;
}

const useMultiDeleteScopeItemQuery = ({
  showNotification,

  company,
  filter,
  onSuccessCb,
}: MultiDeleteScopeItemQueryProps) => {
  const queryClient = useQueryClient();
  const multiDeleteScopeItemQuery = useMutation({
    mutationFn: async (deletedScopeItemList: (ScopeOfWorkItem | string)[]) => {
      const deletedIds = deletedScopeItemList.map((item) => {
        if (typeof item === "string") return item;
        else return item.scopeItemId;
      });
      const baseUrlSOWItem = `/scope/scope_item_multi_delete/`;
      return DoxleAPI.post(baseUrlSOWItem, deletedIds, {
        headers: {
          "User-Company": company?.companyId ?? "",
        },
      });
    },
    onSuccess(result, variables, context) {
      console.log("delete result", result);
      // if (showNotification) showNotification("Deleted Scope ITem", "success");
      if (onSuccessCb) onSuccessCb();
      const successfullyDeleted: string[] = result?.data?.successful ?? [];
      console.log(successfullyDeleted);
      if (successfullyDeleted.length) {
        const qKey = ["scope_items_list"];
        queryClient
          .getQueryCache()
          .findAll(qKey)
          .forEach(({ queryKey }) => {
            queryClient.setQueryData(queryKey, (old: any) => {
              console.log("old", old);
              if (!old) return old;
              return {
                ...old,
                data: (old?.data ?? []).filter(
                  (item: ScopeOfWorkItem) =>
                    !successfullyDeleted.includes(item.scopeItemId)
                ),
              };
            });
          });
      }
    },
    onError(error, variables, context) {
      console.log("PostTaskGroupERROR");
      console.log(error);
      if (showNotification) {
        if (isAxiosError<AxiosBackendErrorReturn>(error)) {
          showNotification(
            `${error?.response?.status ?? "ERROR"}: ${
              error.response?.data.detail ?? "UNKNOWN ERROR"
            }`,
            "error",
            String(
              error?.response?.data?.detail ?? "FAILED TO DELETE Item"
            ).substring(0, 300)
          );
        } else {
          showNotification(
            "Something Wrong!",
            "error",
            "FAILED TO DELETE Item"
          );
        }
      }
    },
  });
  const mutate = (data: (ScopeOfWorkItem | string)[]) =>
    multiDeleteScopeItemQuery.mutate(data);
  return { ...multiDeleteScopeItemQuery, mutate };
};

export interface UpdateScopeItemData
  extends Partial<
    Pick<
      ScopeOfWorkItem,
      "completed" | "approved" | "scopeItemDescription" | "assignedContacts"
    >
  > {
  scopeItemId: string;
}
interface UpdateScopeItemQueryProps extends BaseAPIProps {
  filter: FilterRetrieveScopeItemQuery;
  onSuccessCb?: (updatedItem?: ScopeOfWorkItem) => void;
}
const useUpdateScopeItemQuery = ({
  showNotification,

  company,
  filter,
  onSuccessCb,
}: UpdateScopeItemQueryProps) => {
  const { handleEditSOWItem } = useSetSOWItemQueryData({ filter });
  const updateScopeItemQuery = useMutation({
    mutationFn: async (data: UpdateScopeItemData) => {
      const { scopeItemId, ...rest } = data;
      const baseUrlSOWItem = `/scope/${scopeItemId}/`;
      return DoxleAPI.patch<ScopeOfWorkItem>(baseUrlSOWItem, rest, {
        headers: {
          "User-Company": company?.companyId ?? "",
        },
      });
    },
    onSuccess(result, variables, context) {
      handleEditSOWItem(result.data);
      if (onSuccessCb) onSuccessCb(result.data);
    },
    onError(error, variables, context) {
      console.log("PostTaskGroupERROR");
      console.log(error);
      if (showNotification) {
        if (isAxiosError<AxiosBackendErrorReturn>(error)) {
          showNotification(
            `${error?.response?.status ?? "ERROR"}: ${
              error.response?.data.detail ?? "UNKNOWN ERROR"
            }`,
            "error",
            String(
              error?.response?.data?.detail ?? "FAILED TO update Item"
            ).substring(0, 300)
          );
        } else {
          showNotification(
            "Something Wrong!",
            "error",
            "FAILED TO update Item"
          );
        }
      }
    },
  });
  const mutate = (data: UpdateScopeItemData) =>
    updateScopeItemQuery.mutate(data);
  return { ...updateScopeItemQuery, mutate };
};
//# SCOPE IMAGE SECTION
interface RetrieveScopeImageQueryProps extends BaseAPIProps {
  scopeItemId: string;
  enabled: boolean;
  onSuccessCb?: Function;
}
const useRetrieveScopeItemImage = ({
  showNotification,
  company,
  onSuccessCb,
  scopeItemId,
  enabled,
}: RetrieveScopeImageQueryProps) => {
  const qKey = formScopeOfWorkImageQueryKey(scopeItemId, company);

  let getParams: any = {
    paginated: true,
  };
  getParams.scope_item = scopeItemId;

  const baseUrlSOWImage = "/scope/img_list/?page=1";
  const getScopeOfWorkItemQuery = useInfiniteQuery({
    queryKey: qKey,
    queryFn: async ({ pageParam = baseUrlSOWImage }) => {
      return DoxleAPI.get<IApiPaginatedData<ScopeOfWorkImage>>(pageParam, {
        headers: {
          "User-Company": company?.companyId || "",
        },
        params: getParams,
      });
    },
    getNextPageParam: (prev) => prev.data.next,
    onSuccess: () => {
      if (onSuccessCb) onSuccessCb();
    },

    onError: (error) => {
      if (showNotification) {
        if (isAxiosError<AxiosBackendErrorReturn>(error)) {
          showNotification(
            `${error?.response?.status ?? "ERROR"}: ${
              error.response?.data.detail ?? "UNKNOWN ERROR"
            }`,
            "error",
            String(
              error?.response?.data?.detail ??
                "Failed to get scope of work image"
            ).substring(0, 300)
          );
        } else {
          showNotification(
            "Something Wrong!",
            "error",
            "Failed to get scope of work image"
          );
        }
      }
    },
    enabled: Boolean(company && enabled),
    retry: 1,

    refetchInterval: false,
    refetchIntervalInBackground: true,
    refetchOnWindowFocus: false,
    // refetchOnReconnect: true,
  });
  return getScopeOfWorkItemQuery;
};

export interface AddedScopeItemImage {
  uri: string;
  name: string;
  type: string;
}

interface AddMultiScopeImageQueryProps extends BaseAPIProps {
  scopeItemId: string;
  onSuccessCb?: (successData?: ISuccessAddImageReturn) => void;
}

export interface AddMultiScopeImageData extends ScopeOfWorkImage {
  imageFile: AddedScopeItemImage;
}

export interface ISuccessAddImageReturn {
  errors: any[];
  scopeImages: ScopeOfWorkImage[];
}
const useAddMultiScopeImageQuery = ({
  showNotification,

  company,
  scopeItemId,
  onSuccessCb,
}: AddMultiScopeImageQueryProps) => {
  const baseUrlSOWImg = "/scope/img_multi_add/";

  const { handleAddMultiSOWImg } = useSetSOWImgQueryData({ scopeItemId });
  const mutationKey = getScopeImgMutationKey("add", scopeItemId);
  const addScopeImgQuery = useMutation({
    mutationKey: mutationKey,
    mutationFn: async (data: Array<AddMultiScopeImageData>) => {
      let formData = new FormData();
      formData.append("scopeItem", scopeItemId);
      for (const scopeImage of data) {
        const { imageFile, ...rest } = scopeImage;
        formData.append("scope_images", JSON.stringify(rest));

        //generate blob
        const blobResult = await fetch(imageFile.uri)
          .then((res) => res.blob())
          .catch((err) => undefined);
        if (blobResult)
          formData.append(
            "files",
            blobResult,
            `${imageFile.name}.${imageFile.type.split("/")[1]}`
          );
      }
      return DoxleAPI.post<ISuccessAddImageReturn>(baseUrlSOWImg, formData, {
        headers: {
          "User-Company": company?.companyId ?? "",
        },
      });
    },
    onSuccess(result, variables, context) {
      handleAddMultiSOWImg(result.data.scopeImages);

      // if (showNotification) showNotification("Added Scope Images", "success");

      if (onSuccessCb) onSuccessCb(result.data);
    },
    onError(error, variables, context) {
      console.log("PostTaskGroupERROR");
      console.log(error);
      if (showNotification) {
        if (isAxiosError<AxiosBackendErrorReturn>(error)) {
          showNotification(
            `${error?.response?.status ?? "ERROR"}: ${
              error.response?.data.detail ?? "UNKNOWN ERROR"
            }`,
            "error",
            String(
              error?.response?.data?.detail ?? "Failed To Upload Images"
            ).substring(0, 300)
          );
        } else {
          showNotification(
            "Something Wrong!",
            "error",
            "Failed To Upload Images"
          );
        }
      }
    },
  });
  const mutate = (data: Array<AddMultiScopeImageData>) =>
    addScopeImgQuery.mutate(data);
  return { ...addScopeImgQuery, mutate };
};

interface EditScopeImageQueryProps extends BaseAPIProps {
  scopeItemId: string;

  onSuccessCb?: Function;
}

export interface EditScopeImgData
  extends Partial<
    Pick<ScopeOfWorkImage, "scopeImageTitle" | "scopeImageDescription">
  > {
  scopeImgId: string;
}
const useUpdateScopeImageQuery = ({
  showNotification,

  company,
  scopeItemId,

  onSuccessCb,
}: EditScopeImageQueryProps) => {
  const { handleEditSOWImg } = useSetSOWImgQueryData({ scopeItemId });
  const updateScopeImgQuery = useMutation({
    mutationKey: getScopeImgMutationKey("update", scopeItemId),
    mutationFn: async ({ scopeImgId, ...data }: EditScopeImgData) => {
      const baseUrlSOWImg = `/scope/img/${scopeImgId}/`;
      return DoxleAPI.patch<ScopeOfWorkImage>(baseUrlSOWImg, data, {
        headers: {
          "User-Company": company?.companyId ?? "",
        },
      });
    },
    onSuccess(result, variables, context) {
      handleEditSOWImg(result.data);
      // if (showNotification) showNotification("Updated Scope Images", "success");

      if (onSuccessCb) onSuccessCb(result.data);
    },
    onError(error, variables, context) {
      console.log("PostTaskGroupERROR");
      console.log(error);
      if (showNotification) {
        if (isAxiosError<AxiosBackendErrorReturn>(error)) {
          showNotification(
            `${error?.response?.status ?? "ERROR"}: ${
              error.response?.data.detail ?? "UNKNOWN ERROR"
            }`,
            "error",
            String(
              error?.response?.data?.detail ?? "Failed To Upload Images"
            ).substring(0, 300)
          );
        } else {
          showNotification(
            "Something Wrong!",
            "error",
            "Failed To Upload Images"
          );
        }
      }
    },
  });
  const mutate = (data: EditScopeImgData) => updateScopeImgQuery.mutate(data);
  return { ...updateScopeImgQuery, mutate };
};

interface DeleteMultiScopeImageQueryProps extends BaseAPIProps {
  scopeItemId: string;
  onSuccessCb?: (deletedIds?: string[]) => void;
}
const useMultiDeleteScopeImageQuery = ({
  showNotification,

  company,
  scopeItemId,
  onSuccessCb,
}: DeleteMultiScopeImageQueryProps) => {
  const { handleDeleteMultipleSOWImgs } = useSetSOWImgQueryData({
    scopeItemId,
  });
  const baseUrlSOWImg = `/scope/img_multi_delete/`;
  const updateScopeImgQuery = useMutation({
    mutationKey: getScopeImgMutationKey("delete", scopeItemId),
    mutationFn: async (data: string[]) => {
      return DoxleAPI.post(baseUrlSOWImg, data, {
        headers: {
          "User-Company": company?.companyId ?? "",
        },
      });
    },
    onSuccess(result, variables, context) {
      handleDeleteMultipleSOWImgs(variables);

      // if (showNotification) showNotification("Updated Scope Images", "success");

      if (onSuccessCb) onSuccessCb(variables);
      //console.log(result);
    },
    onError(error, variables, context) {
      console.log("PostTaskGroupERROR");
      console.log(error);
      if (showNotification) {
        if (isAxiosError<AxiosBackendErrorReturn>(error)) {
          showNotification(
            `${error?.response?.status ?? "ERROR"}: ${
              error.response?.data.detail ?? "UNKNOWN ERROR"
            }`,
            "error",
            String(
              error?.response?.data?.detail ?? "Failed To delete Images"
            ).substring(0, 300)
          );
        } else {
          showNotification(
            "Something Wrong!",
            "error",
            "Failed To delete Images"
          );
        }
      }
    },
  });
  const mutate = (data: string[]) => updateScopeImgQuery.mutate(data);
  return { ...updateScopeImgQuery, mutate };
};
//# HELPER FUNCTIONS

export const formScopeOfWorkGroupQkey = (
  filter: FilterRetrieveScopeOfWorkQuery,
  company: Company | undefined
) => {
  let baseQKey = ["scope_of_works_group"];
  const { docketId, projectId } = filter;
  if (company) baseQKey.push(company.companyId);
  if (docketId) baseQKey.push(docketId);
  if (projectId) baseQKey.push(projectId);
  return baseQKey;
};

export const formScopeOfWorkItemQKey = (
  filter: FilterRetrieveScopeItemQuery,
  company: Company | undefined
) => {
  let baseQKey = ["scope_items_list"];
  const { scopeGroupId } = filter;
  if (company) baseQKey.push(company.companyId);

  baseQKey.push(scopeGroupId);
  return baseQKey;
};

export const formScopeOfWorkImageQueryKey = (
  scopeItemId: string,
  company: Company | undefined
) => {
  let baseQKey = ["scope_item_image_list"];
  if (company) baseQKey.push(company.companyId);
  baseQKey.push(scopeItemId);
  return baseQKey;
};

export const getScopeImgMutationKey = (
  action: "add" | "delete" | "update",
  scopeItemId?: string
) => {
  if (action === "add") return ["add-scope-image", scopeItemId];
  else if (action === "update") return ["update-scope-image", scopeItemId];
  else return ["delete-scope-image", scopeItemId];
};

export const getSOWGroupMutationKey = (action: "delete" | "add" | "update") => [
  `${action}-SOWGroup`,
];
const ScopeOfWorkAPI = {
  useRetrieveScopeGroupList,
  useAddScopeGroupQuery,
  useUpdateScopeGroupQuery,
  useRetrieveScopeItemListQuery,
  useAddScopeItemQuery,
  useRetrieveScopeItemImage,
  useDeleteScopeItemQuery,
  useUpdateScopeItemQuery,
  useAddMultiScopeImageQuery,
  useUpdateScopeImageQuery,
  useMultiDeleteScopeImageQuery,
  useMultiDeleteScopeItemQuery,
  useDeleteScopeGroupQuery,
};

export default ScopeOfWorkAPI;
