import React from "react";

import {
  StyledDialogHelperPaper,
  StyledDoxleDialogHelperActions,
  StyledDoxleDialogHelperContainer,
  StyledDoxleDialogHelperContent,
  StyledDoxleDialogHelperEditButton,
  StyledDoxleDialogHelperText,
  StyledDoxleDialogHelperTitle,
} from "./StyledComponentDoxleDialogHelper";
import { DialogProps, SxProps, Theme } from "@mui/material";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import { editRgbaAlpha } from "../../Utilities/FunctionUtilities";

export interface DoxleDialogButton {
  buttonText: string;
  buttonFunction: Function;
  buttonColor?: string;
  iconButton?: React.ReactNode;
  buttonTextStyle?: React.CSSProperties;
  btnStyleSx?: SxProps<Theme>;
}
interface Props extends DialogProps {
  title: string;
  description: string;
  dialogButtons: DoxleDialogButton[];
}

const DoxleDialogHelper = React.forwardRef<any, Props>(
  ({ title, description, dialogButtons, ...rest }: Props, ref) => {
    const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
      (state) => ({
        doxleThemeColor: state.doxleThemeColor,
        doxleFont: state.doxleFont,
      }),
      shallow
    );

    return (
      <StyledDoxleDialogHelperContainer
        $themeColor={doxleThemeColor}
        {...rest}
        ref={ref}
        PaperProps={{
          sx: {
            bgColor: doxleThemeColor.primaryContainerColor,
            borderRadius: "0.9rem",
            border: `1px solid ${doxleThemeColor.primaryDividerColor}`,
          },
          elevation: 2,
        }}
        slotProps={{
          backdrop: {
            sx: {
              bgcolor: editRgbaAlpha({
                rgbaColor: doxleThemeColor.primaryBackgroundColor,
                alpha: "0.8",
              }),
            },
          },
        }}
      >
        <StyledDoxleDialogHelperTitle
          $themeColor={doxleThemeColor}
          $doxleFont={doxleFont}
        >
          {title}
        </StyledDoxleDialogHelperTitle>

        <StyledDoxleDialogHelperContent $themeColor={doxleThemeColor}>
          <StyledDoxleDialogHelperText
            $themeColor={doxleThemeColor}
            $doxleFont={doxleFont}
          >
            {description.split("\n").map((text, i) => {
              if (i === 0) return <span key={i}>{text}</span>;
              else
                return (
                  <span key={i}>
                    <br />
                    {text}
                  </span>
                );
            })}
          </StyledDoxleDialogHelperText>
        </StyledDoxleDialogHelperContent>

        <StyledDoxleDialogHelperActions $themeColor={doxleThemeColor}>
          {dialogButtons.map((button, idx) => (
            <StyledDoxleDialogHelperEditButton
              key={`helperBtn#${idx}`}
              className={`doxleDialogBtn-${idx}-${description}`}
              $bgColor={button.buttonColor}
              $themeColor={doxleThemeColor}
              $dialogDescription={description}
              $buttonIndex={idx}
              $doxleFont={doxleFont}
              onClick={() => button.buttonFunction()}
              layout
              sx={button.btnStyleSx}
            >
              {button.iconButton && button.iconButton}
              <span style={button.buttonTextStyle} className="button-text">
                {button.buttonText}
              </span>
            </StyledDoxleDialogHelperEditButton>
          ))}
        </StyledDoxleDialogHelperActions>
      </StyledDoxleDialogHelperContainer>
    );
  }
);

export default React.memo(DoxleDialogHelper);
