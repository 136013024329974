import { useCallback, useEffect, useState } from "react";
import VirtualMeasureElement from "../VirtualMeasureElement";

type MeasureItemContentProps<TItem> = {
  data: TItem[];
  renderItem: (item: TItem, itemIndex: number) => JSX.Element;
};

export type ElementRenderSize<TItem> = {
  width: number;
  height: number;
  item: TItem;
};
type MeasureItemContent<TItem> = {
  itemSizeCollection: ElementRenderSize<TItem>[];
  virtualElementRenderer: React.ReactNode;
};
export function useMeasureItemContent<TItem>({
  data,
  renderItem,
}: MeasureItemContentProps<TItem>): MeasureItemContent<TItem> {
  const [itemSize, setItemSize] = useState<ElementRenderSize<TItem>[]>([]);
  const handleMeasureItems = useCallback(() => {
    setItemSize([]);
    return data.map((item, index) => {
      return (
        <VirtualMeasureElement
          data={item}
          itemIndex={index}
          renderItem={renderItem}
          setItemSize={setItemSize}
        />
      );
    });
  }, [data, renderItem]);

  useEffect(() => {
    handleMeasureItems();
  }, [handleMeasureItems]);
  return {
    itemSizeCollection: itemSize,
    virtualElementRenderer: (
      <>
        {data.map((item, index) => {
          return (
            <VirtualMeasureElement
              key={`virtualEl#${index}`}
              data={item}
              itemIndex={index}
              renderItem={renderItem}
              setItemSize={setItemSize}
            />
          );
        })}
      </>
    ),
  };
}
