import styled from "styled-components";
import { DoxleFont, DoxleThemeColor } from "../DoxleGeneralStore/useDoxleThemeStore";

export const StyledInventoryRootContainer = styled.div<{
  $doxleFont: DoxleFont;
}>`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  font-family: ${(p) => p.$doxleFont.primaryFont};
`;

export const StyledInventoryScreenContainer = styled.div`
  margin-top: 7rem;
  width: 90%;
  max-width: 70rem;
  display: flex;
  padding-bottom: 6rem;
`;

export const StyledInventoryList = styled.div<{
  $themeColor: DoxleThemeColor;
}>`
  border-radius: 0px 12px 12px 12px;
  padding: 0;
  margin-top: 0px;
`;

export const StyledInventoryListScroller = styled.div`
  width: 100%;
  height: 100% !important;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;
