import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { motion } from "framer-motion";
import styled from "styled-components";
import { editRgbaAlpha } from "../../../Utilities/FunctionUtilities";

export const StyledQRLogSummaryBtn = styled(motion(Button))`
  && {
    min-width: 0px !important;
    padding: 0px 8px !important;
  }
`;
export const StyledQRSummaryDialog = styled(Dialog)<{}>`
  && {
  }
  & .MuiDialog-paper {
    background-color: ${(p) => p.theme.color.primaryContainerColor};
    box-shadow: 0px 0px 14px 0px ${(p) => p.theme.color.primaryBoxShadowColor};
    min-width: 300px;
    max-width: 700px;

    border-radius: 12px;
    border: 1px solid ${(p) => p.theme.color.primaryDividerColor};
    elevation: 2;
    padding: 14px 20px;
    width: 80vw;
    position: relative;
  }
`;
export const StyledQRSummaryDialogTitle = styled(DialogTitle)<{}>`
  && {
    background-color: ${(p) => p.theme.color.primaryContainerColor};
    padding: 0px !important;

    width: 100%;
    display: flex;
    align-items: center;
  }
  .title-wrapper {
    font-family: ${(p) => p.theme.font.primaryFont};
    font-style: normal;
    font-weight: 700;
    font-size: 25px;

    color: ${(p) => p.theme.color.doxleColor};
    display: inline-block;
    white-space: normal;
    word-wrap: break-word;
    flex: 1;
    display: flex;
    align-items: center;
  }
`;
export const StyledQRSummaryDialogContent = styled(motion(DialogContent))<{}>`
  && {
    background-color: ${(p) => p.theme.color.primaryContainerColor};
    padding: 10px 0px 0px 0px !important;
    display: flex;
    flex-direction: column;
    overflow: auto;
    max-height: 800px;
    height: 80vh;
    position: relative;
  }
`;
export const StyledSummaryTitleSection = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px 0px;

  .title-section {
    flex: 1;
    display: flex;
    flex-direction: column;

    .site-address-text {
      font-size: 18px;
      font-weight: 600;
      color: ${(p) => p.theme.color.primaryFontColor};
      line-height: 18px;
    }
    .date-filter-text {
      margin-top: 4px;
      font-size: 16px;
      font-weight: 500;
      color: ${(p) =>
        editRgbaAlpha({
          rgbaColor: p.theme.color.primaryFontColor,
          alpha: "0.4",
        })};
      line-height: 16px;
    }
  }
`;
export const StyledQRSummaryGroupHeader = styled(motion.div)`
  width: calc(100% - 32px);
  display: flex;
  flex: 1;
  align-items: center;
  background-color: ${(p) => p.theme.staticMenuColor.staticBg};
  padding: 8px 16px;
  cursor: pointer;
  .user-name {
    font-size: 16px;
    font-weight: 700;
    color: ${(p) => p.theme.staticMenuColor.staticFontColor};
    line-height: 16px;
    flex: 1;
  }

  .time-field {
    font-size: 16px;
    font-weight: 400;
    color: ${(p) => p.theme.staticMenuColor.staticFontColor};
    line-height: 14px;
    width: 15%;
    min-width: 100px;
    max-width: 140px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: center;
  }
  .total-field {
    font-size: 16px;
    font-weight: 700;
    color: ${(p) => p.theme.staticMenuColor.staticFontColor};
    line-height: 14px;
    width: 15%;
    min-width: 120px;
    max-width: 160px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: right;
  }
`;
export const StyledQRSummaryItem = styled.div<{
  $isInvalid?: boolean;
}>`
  width: calc(100% - 32px);
  display: flex;
  min-height: 20px;
  align-items: center;
  border-bottom: 1px solid ${(p) => p.theme.color.primaryDividerColor};
  padding: 8px 16px;
  transition: all 0.3s ease;
  cursor: pointer;
  &:hover {
    background-color: ${(p) => p.theme.color.primaryHoverColor};
  }
  .date-text {
    font-size: 16px;
    font-weight: 400;
    color: ${(p) => p.theme.color.primaryFontColor};
    line-height: 16px;
    flex: 1;
  }

  .time-field {
    font-size: 16px;
    font-weight: 400;
    color: ${(p) => p.theme.color.primaryFontColor};
    line-height: 16px;
    width: 15%;
    min-width: 100px;
    max-width: 140px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: center;
  }
  .total-field {
    font-size: 16px;
    font-weight: 700;
    color: ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.theme.color.primaryFontColor,
        alpha: p.$isInvalid ? "0.4" : "1",
      })};
    line-height: 16px;
    width: 15%;
    min-width: 120px;
    max-width: 160px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: right;
  }
`;

export const StyledQRSummaryGroupFooter = styled(motion.div)`
  width: calc(100% - 32px);
  padding: 8px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  span {
    font-size: 16px;
    font-weight: 700;
    color: ${(p) => p.theme.color.primaryFontColor};
    line-height: 16px;
  }
`;
export const StyledWrapperWithFooter = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
export const StyledQRSummaryFilterBtn = styled(motion(Button))`
  && {
    min-width: 0px !important;
    padding: 2px !important;
  }
`;

export const StyledQRSummaryFilterContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  padding: 14px;
  border-radius: 8px;
  background-color: ${(p) => p.theme.color.primaryContainerColor};
  border: 1px solid ${(p) => p.theme.color.primaryDividerColor};
  max-height: 60vh;
  .title-text {
    font-size: 16px;
    font-weight: 700;
    color: ${(p) => p.theme.color.primaryFontColor};
    line-height: 16px;
    margin-bottom: 14px;
  }
  .list-wrapper {
    width: 100%;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 8px;
  }
`;

export const StyledFilterQRSummaryBtn = styled(motion(Button))<{
  $hasBorderBtm?: boolean;
}>`
  && {
    min-width: 0px !important;
    padding: 8px !important;
    width: 100%;
    font-size: 16px;
    font-weight: 400;
    border-radius: 0px !important;
    color: ${(p) => p.theme.color.primaryFontColor};
    line-height: 16px;
    transition: all 0.3s ease;
    text-align: left;
    text-transform: capitalize;
    justify-content: flex-start;
    border-bottom: ${(p) => (p.$hasBorderBtm ? 1 : 0)}px solid
      ${(p) => p.theme.color.primaryDividerColor};
    &:hover {
      background-color: ${(p) => p.theme.color.primaryHoverColor} !important;
    }
  }
`;
