import React, { useEffect, useState } from "react";
import { useProjectStore } from "../../DoxleGeneralStore/useprojectStore";
import { shallow } from "zustand/shallow";
import { useIsMutating } from "@tanstack/react-query";

type Props = {};

interface DrawingsHeader {
  showAddDrawingZone: boolean;
  setshowAddDrawingZone: React.Dispatch<React.SetStateAction<boolean>>;
}

const useDrawingsTabDisplayer = (props: Props): DrawingsHeader => {
  const [showAddDrawingZone, setshowAddDrawingZone] = useState(false);
  const { setFunctionNewButton } = useProjectStore(
    (state) => ({ setFunctionNewButton: state.setFunctionNewButton }),
    shallow
  );
  const isAddingItem = useIsMutating({mutationKey: ["Add-Drawings-Mutation"]});

  useEffect(() => {
    setFunctionNewButton([
      {
        btnFunction: () => setshowAddDrawingZone(true),
        isLoading: isAddingItem > 0,
      },
    ]);
  }, [setFunctionNewButton, isAddingItem]);

  useEffect(() => {
    return () => {
      setFunctionNewButton([]);
    };
  }, []);

  return {
    showAddDrawingZone,
    setshowAddDrawingZone,
  };
};

export default useDrawingsTabDisplayer;
