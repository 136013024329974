import React from "react";
import {
  FilterGetDiscussionQuery,
  formDiscussionQueryKey,
} from "../../../Services/QueryHooks/discussionsAPI";
import { useDoxleCurrentContextStore } from "../../../DoxleGeneralStore/useDoxleCurrentContext";
import { useQueryClient } from "@tanstack/react-query";
import { Discussion } from "../../../Models/discussion";
import { produce } from "immer";

type Props = {
  filter: FilterGetDiscussionQuery;
  appendPos?: "start" | "end";
  overwrite?: boolean;
};
interface SetDiscussionQueryData {
  handleAddDiscussion: (addedDiscussion: Discussion) => void;
}
const useSetDiscussionQueryData = ({
  filter,
  appendPos = "end",
  overwrite = true,
}: Props): SetDiscussionQueryData => {
  const { company } = useDoxleCurrentContextStore((state) => ({
    company: state.currentCompany,
  }));
  const qKey = formDiscussionQueryKey({ filter, company });
  const queryClient = useQueryClient();
  const handleAddDiscussion = (addedDiscussion: Discussion) => {
    const queryData = queryClient.getQueryData(qKey);
    if (queryData && overwrite) {
      queryClient.setQueryData(qKey, (old: any) => {
        return produce(old, (draft: any) => {
          if (appendPos === "start") {
            (
              draft.pages[old.pages.length - 1].data.results as Discussion[]
            ).unshift(addedDiscussion);
          } else
            (draft.pages[0].data.results as Discussion[]).push(addedDiscussion);

          return draft;
        });
      });
    } else queryClient.invalidateQueries(qKey);
  };
  return { handleAddDiscussion };
};

export default useSetDiscussionQueryData;
