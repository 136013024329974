import React, { useCallback, useState } from "react";
import { useProjectQAStore } from "../../../Store/useProjectQAStore";
import { shallow } from "zustand/shallow";
import { useDoxleAuthStore } from "../../../../../DoxleGeneralStore/useDoxleAuthStore";
import { useDoxleCurrentContextStore } from "../../../../../DoxleGeneralStore/useDoxleCurrentContext";
import useDoxleNotificationStore from "../../../../../DoxleGeneralStore/useDoxleNotificationStore";
import useSetQAListQueryData from "../../../../CustomQueryHooks/useSetQAListQueryData";
import QAQueryAPI from "../../../../QueryAPI/qaQueryAPI";
import { QAList } from "../../../../Models/qa";
import { Contact } from "../../../../../Models/addressBook";

type Props = {};

interface EditQAListAssignee {
  anchorAssigneeList: HTMLElement | null;
  setAnchorAssigneeList: React.Dispatch<
    React.SetStateAction<HTMLElement | null>
  >;
  handleUpdateAssignee: (selectedAssignee: Contact | undefined) => void;
  handleCloseAssigneeList: () => void;
  isUpdatingAssignee: boolean;
  isHovering: boolean;
  setIsHovering: React.Dispatch<React.SetStateAction<boolean>>;
}
const useEditQAListAssignee = (props: Props): EditQAListAssignee => {
  const [isHovering, setIsHovering] = useState(false);
  const [anchorAssigneeList, setAnchorAssigneeList] =
    useState<HTMLElement | null>(null);
  const { edittedQAListItem, updateEdittedQAListItem, filterQAListQuery } =
    useProjectQAStore(
      (state) => ({
        edittedQAListItem: state.edittedQAListItem,
        updateEdittedQAListItem: state.updateEdittedQAListItem,
        filterQAListQuery: state.filterQAListQuery,
      }),
      shallow
    );

  const company = useDoxleCurrentContextStore(
    (state) => state.currentCompany,
    shallow
  );
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );

  const { handleEditQAList } = useSetQAListQueryData({
    filter: filterQAListQuery,
  });

  const onUpdateSuccessCb = (newQAList?: QAList) => {
    if (newQAList) {
      handleEditQAList(newQAList);
      updateEdittedQAListItem({
        assignee: newQAList.assignee,
        assigneeName: newQAList.assigneeName,
        assigneeJson: newQAList.assigneeJson,
      });
    }
  };
  const updateQAListQuery = QAQueryAPI.useUpdateQAListQuery({
    company,
    showNotification,
    onSuccessCB: onUpdateSuccessCb,
  });

  const handleUpdateAssignee = useCallback(
    (selectedAssignee: Contact | undefined) => {
      if (edittedQAListItem) {
        if (selectedAssignee)
          updateQAListQuery.mutate({
            qaList: edittedQAListItem,
            updateParams: {
              assignee: selectedAssignee.contactId,
              assigneeName: `${selectedAssignee.firstName} ${selectedAssignee.lastName}`,
            },
          });
        else
          updateQAListQuery.mutate({
            qaList: edittedQAListItem,
            updateParams: {
              assignee: null,
              assigneeName: "",
            },
          });
      }

      setAnchorAssigneeList(null);
    },
    []
  );
  const handleCloseAssigneeList = useCallback(() => {
    setAnchorAssigneeList(null);
  }, []);
  return {
    anchorAssigneeList,
    setAnchorAssigneeList,
    handleUpdateAssignee,
    handleCloseAssigneeList,
    isUpdatingAssignee: updateQAListQuery.isLoading,
    isHovering,
    setIsHovering,
  };
};

export default useEditQAListAssignee;
