import React from "react";
import { usePricebookStore } from "../../../Store/usePricebookStore";
import { useShallow } from "zustand/react/shallow";

type Props = {};

interface IAddEditPricebookDialog {
  isOpenDialog: boolean;
  handleCloseEditDialog: () => void;
}
const useAddEditPricebookDialog = (): IAddEditPricebookDialog => {
  const {
    editedPricebookItemId,
    setEditedPricebookItemId,
    showAddPricebookDialog,
    setShowAddPricebookDialog,
  } = usePricebookStore(
    useShallow((state) => ({
      editedPricebookItemId: state.editedPricebookItemId,
      setEditedPricebookItemId: state.setEditedPricebookItemId,
      showAddPricebookDialog: state.showAddPricebookDialog,
      setShowAddPricebookDialog: state.setShowAddPricebookDialog,
    }))
  );

  const isOpenDialog = !!editedPricebookItemId || showAddPricebookDialog;
  const handleCloseEditDialog = () => {
    setEditedPricebookItemId(null);
    setShowAddPricebookDialog(false);
  };
  return {
    isOpenDialog,
    handleCloseEditDialog,
  };
};

export default useAddEditPricebookDialog;
