import { useCallback, useMemo, useRef } from "react";
import { useDoxleThemeStore } from "../../../../DoxleGeneralStore/useDoxleThemeStore";
import EditDocketCommentInputSection from "./EditDocketCommentInputSection";
import DiscussionItem from "./DiscussionItem";
import { StyledCommentDisplaySectionContainer } from "../../StyledComponentsEditDocketSideScreen";
import { Discussion } from "../../../../Models/discussion";
import {
  Components,
  ItemContent,
  ItemProps,
  Virtuoso,
  VirtuosoHandle,
} from "react-virtuoso";
import useCommentDisplaySection from "./Hooks/useCommentDisplaySection";
import React from "react";
import {
  StyledDiscussionListScroller,
  StyledDiscussionRowWrapper,
} from "./StyledComponentDocketInfo";
import DoxleEmptyPlaceHolder from "../../../../DoxleDesignPattern/DoxleEmptyPlaceHolder/DoxleEmptyPlaceHolder";
import { AnimatePresence } from "framer-motion";
import { HiChevronDoubleDown } from "react-icons/hi";
import DoxleIconButton from "../../../../DoxleDesignPattern/DoxleButtons/DoxleIconButton";
import { useEditDocketSideScreenStore } from "../../EditDocketSideScreenStore/useEditDocketSideScreenStore";
import { shallow } from "zustand/shallow";
import { Docket } from "../../../../Models/dockets";
import { EmptyCommentBanner } from "../../EditDocketSideScreenIcons";
import Loading from "../../../../Utilities/Lottie/Loading";
import { Error404Banner } from "../../../../DoxleDesignPattern/DoxleIcons/CommonIcons";
import DoxleGallery from "../../../../DoxleDesignPattern/DoxleGallery/DoxleGallery";

type Props = {};
interface CommentListContext {
  isFetchingNextPage: boolean;
  edittedDocket: Docket | undefined;
  isFetchingDiscussion: boolean;
  isErrorFetchingDiscussion: boolean;
}

const CommentDisplaySection = () => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore((state) => ({
    doxleThemeColor: state.doxleThemeColor,
    doxleFont: state.doxleFont,
  }));
  const { edittedDocket } = useEditDocketSideScreenStore(
    (state) => ({
      edittedDocket: state.edittedDocket,
      setHandleAddButtonFn: state.setHandleAddButtonFn,
    }),
    shallow
  );
  const {
    discussionList,
    isFetchingDiscussion,
    isErrorFetchingDiscussion,
    fetchNextPage,
    isFetchingNextPage,
    setCurrentVisibleRange,
    shouldShowIconScrollToBottom,
  } = useCommentDisplaySection({});

  const commentListRef = useRef<VirtuosoHandle>(null);
  const handleScrollToBottom = () => {
    commentListRef.current?.scrollToIndex({
      index: discussionList.length - 1,
      behavior: "smooth",
    });
  };
  //* render list
  const itemContent: ItemContent<Discussion, CommentListContext> = useCallback(
    (index, item) => (
      <DiscussionItem
        discussionItem={item}
        key={`discussion#${item.commentId}`}
      />
    ),
    []
  );

  //*render list
  const components: Components<Discussion, CommentListContext> = useMemo(
    () => ({
      Scroller: React.forwardRef((props, ref) => (
        <StyledDiscussionListScroller
          style={{
            ...props.style,
          }}
          ref={ref}
          {...props}
        />
      )),

      Item: React.forwardRef<HTMLDivElement, ItemProps<Discussion>>(
        (props, ref) => (
          <StyledDiscussionRowWrapper
            {...props}
            ref={ref}
            animate={{ y: [5, 0], opacity: [0, 1] }}
            viewport={{ once: true }}
            whileInView="visible"
          />
        )
      ),
      EmptyPlaceholder: (props) =>
        !props.context?.isFetchingDiscussion ? (
          <DoxleEmptyPlaceHolder
            headTitleText={
              props.context?.isErrorFetchingDiscussion
                ? "Something wrong..."
                : "No comments"
            }
            subTitleText={
              props.context?.isErrorFetchingDiscussion
                ? "Your session might be expired, please reload the page..."
                : "Add your comments to communicate with your colleagues..."
            }
            containerBgColor="transparent"
            headTitleTextStyle={{ fontSize: "30px" }}
            subTitleTextStyle={{ fontSize: "18px" }}
            containerStyle={{ minHeight: "40vh", flex: 1 }}
            illustration={
              props.context?.isErrorFetchingDiscussion ? (
                <Error404Banner
                  themeColor={doxleThemeColor}
                  containerStyle={{
                    marginBottom: 30,
                    marginTop: 30,
                    width: "70%",
                  }}
                />
              ) : (
                <EmptyCommentBanner
                  themeColor={doxleThemeColor}
                  containerStyle={{ marginBottom: 30, marginTop: 30 }}
                />
              )
            }
          />
        ) : (
          <Loading
            containerStyle={{
              flex: 1,
            }}
          />
        ),
    }),
    [doxleThemeColor]
  );

  return (
    <StyledCommentDisplaySectionContainer
      $themeColor={doxleThemeColor}
      layout="position"
      transition={{
        duration: 0.2,
        damping: 12,
      }}
    >
      <div className="comment-list-wrapper">
        <Virtuoso
          style={{ height: "100%", width: "100%" }}
          data={discussionList}
          itemContent={itemContent}
          totalCount={discussionList.length}
          components={components}
          ref={commentListRef}
          context={{
            isFetchingNextPage,
            edittedDocket,
            isFetchingDiscussion,
            isErrorFetchingDiscussion,
          }}
          overscan={200}
          atTopStateChange={fetchNextPage}
          atTopThreshold={0.4}
          followOutput="smooth"
          alignToBottom
          initialTopMostItemIndex={discussionList.length - 1}
          rangeChanged={setCurrentVisibleRange}
        />

        <AnimatePresence>
          {shouldShowIconScrollToBottom && (
            <DoxleIconButton
              onClick={handleScrollToBottom}
              iconSource={
                <HiChevronDoubleDown
                  color={doxleThemeColor.primaryFontColor}
                  size="3rem"
                />
              }
              buttonSize={40}
              buttonWrapperStyle={{
                position: "absolute",
                bottom: 0,
                marginBottom: "10px",
                left: "calc(50% - 2rem)",

                zIndex: 99,
                boxShadow: `0px 0px 4px 0px ${doxleThemeColor.primaryBoxShadowColor}`,
              }}
              bgColor={doxleThemeColor.primaryContainerColor}
            />
          )}
        </AnimatePresence>
      </div>

      <EditDocketCommentInputSection
        commentListRef={commentListRef}
        discussionList={discussionList}
      />
    </StyledCommentDisplaySectionContainer>
  );
};

export default CommentDisplaySection;
