import { motion } from "framer-motion";
import styled from "styled-components";
import { editRgbaAlpha } from "../../../Utilities/FunctionUtilities";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

export const StyledBookingCalendarContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  background-color: ${({ theme }) => theme.color.primaryBackgroundColor};
`;
export const StyledTopMenuCalendar = styled.div`
  width: calc(100% - 14px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  padding-left: 14px;
  .period-title-text {
    font-size: 30px;
    font-weight: 700;
    color: ${({ theme }) => theme.color.doxleColor};
    font-family: ${({ theme }) => theme.font.primaryFont};
    display: flex;
    align-items: center;
  }

  .menu-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }
`;
export const StyledBookingScroller = styled.div`
  // width: 100%;
  // height: 100%;

  // &::-webkit-scrollbar {
  //   display: none;
  // }
  // -ms-overflow-style: none;
  // scrollbar-width: none;
`;

export const StyledBookingWrapperWithFooter = styled.div`
  min-height: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 30px;
`;

export const StyledBookingDateHeader = styled.div<{
  $isToday: boolean;
}>`
  width: calc(100% - 28px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 10px;
  padding: 8px 14px;
  background-color: ${(p) => p.theme.color.primaryBackgroundColor};
  border-bottom: 2px solid ${(p) => p.theme.color.primaryFontColor};
  .date-month-text {
    font-size: 25px;
    font-weight: 700;
    color: ${({ theme, $isToday }) =>
      $isToday
        ? editRgbaAlpha({
            rgbaColor: theme.color.doxleColor,
            alpha: "0.8",
          })
        : theme.color.primaryFontColor};
    font-family: ${({ theme }) => theme.font.primaryFont};
    text-transform: capitalize;
  }

  .week-day-text {
    font-size: 20px;
    font-weight: 500;
    color: ${({ theme, $isToday }) =>
      $isToday
        ? editRgbaAlpha({
            rgbaColor: theme.color.doxleColor,
            alpha: "0.5",
          })
        : editRgbaAlpha({
            rgbaColor: theme.color.primaryFontColor,
            alpha: "0.4",
          })};
    font-family: ${({ theme }) => theme.font.primaryFont};
    text-transform: capitalize;
  }
`;

export const StyledBookingRowContainer = styled(motion.div)<{
  $isCompleted: boolean;
  $isStartOrEnd: boolean;
}>`
  width: calc(100% - 28px);
  border-bottom: 1px solid ${(p) => p.theme.color.primaryDividerColor};
  min-height: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 14px;

  overflow: hidden;
  cursor: pointer;
  .menu-hover {
    display: flex;
    align-items: center;

    cursor: pointer;
    transform: translateX(30%);
    opacity: 0;
    transition: transform 0.2s ease, opacity 0.5s ease;
    padding-left: 5px;
    cursor: pointer;
    white-space: nowrap;
  }
  .title-text {
    color: ${(p) =>
      p.$isCompleted || !p.$isStartOrEnd
        ? editRgbaAlpha({
            rgbaColor: p.theme.color.primaryFontColor,
            alpha: "0.4",
          })
        : p.theme.color.primaryFontColor};
    font-size: 16px;
    font-family: ${(p) => p.theme.font.primaryTitleFont};
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.65px;
    flex: 1;
    text-decoration: ${(p) => (p.$isCompleted ? "line-through" : "none")};
    display: inline-block;
    white-space: normal;
    word-wrap: break-word;
    max-width: 100%;
  }
  &:hover {
    .menu-hover {
      transform: translateX(0);
      opacity: 1;
    }
    .title-text {
      max-width: calc(100% - 100px);
    }
  }
`;

export const StyledBookingContentTextField = styled(motion(TextField))<{}>`
  && {
    flex: 1;
    padding: 0px !important;
    display: flex;
    align-items: center;
    flex-direction: row;
  }
  input {
    color: ${(p) => p.theme.color.primaryFontColor};
    font-size: 16px;
    font-family: ${(p) => p.theme.font.primaryTitleFont};
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.65px;
    padding: 0px !important;
    &::placeholder {
      color: ${(p) =>
        editRgbaAlpha({
          rgbaColor: p.theme.color.primaryFontColor,
          alpha: "0.2",
        })};
      font-size: 16px;
      font-family: ${(p) => p.theme.font.primaryFont};
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.65px;
      padding: 0px !important;
    }
  }
  & .MuiInputBase-multiline {
    color: ${(p) => p.theme.color.primaryFontColor};
    font-size: 16px;
    font-family: ${(p) => p.theme.font.primaryTitleFont};
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.65px;
    padding: 0px !important;
    width: 100%;
    textarea {
      &::placeholder {
        color: ${(p) =>
          editRgbaAlpha({
            rgbaColor: p.theme.color.primaryFontColor,
            alpha: "0.2",
          })};
        font-size: 16px;
        font-family: ${(p) => p.theme.font.primaryFont};
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.65px;
        padding: 0px !important;
        opacity: 1 !important;
      }
    }
  }
  & .MuiOutlinedInput-root {
    padding: 0px !important;
  }
  & .MuiOutlinedInput-input {
    padding: 0px !important;
  }
  & .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }
  &.MuiInputBase-root-MuiInput-root:before {
    border: none !important;
  }
`;

export const StyledBookingItemWrapper = styled(motion.div)<{
  $numOfCol: number;
}>`
  width: calc(${(p) => 100 / p.$numOfCol}% - 20px);
  aspect-ratio: 0.85;
  padding: 10px;
`;
export const StyledBookingListWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const StyledBookingDateCell = styled(motion.div)`
  width: calc(100% - 22px);
  padding: 10px;
  border-radius: 8px;
  border: 1px solid ${(p) => p.theme.color.primaryDividerColor};
  display: flex;
  flex-direction: column;
  height: calc(100% - 22px);

  .item-wrapper {
    flex-shrink: 1;
    width: 100%;

    flex-direction: column;
    overflow-y: auto;
  }
`;
export const StyledBookingFooterContainer = styled(motion.div)<{}>`
  width: calc(100% - 28px);
  border-bottom: 1px solid ${(p) => p.theme.color.primaryDividerColor};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 14px;
  min-height: 10px;
  overflow: hidden;
`;

export const StyledFilterBookingContainer = styled(motion.div)`
  width: 280px;
  display: flex;
  flex-direction: column;
  padding: 10px 0px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.color.primaryContainerColor};
  border: 1px solid ${({ theme }) => theme.color.primaryDividerColor};
`;

export const StyledFilterBookingBtn = styled(Button)<{
  $selected?: boolean;
  $borderBottom?: boolean;
}>`
  && {
    width: calc(100%);
    padding: 7px 14px;

    border-radius: 0px !important;
    background-color: transparent;
    color: ${({ theme, $selected }) =>
      $selected ? theme.color.doxleColor : theme.color.primaryFontColor};
    border-bottom: ${({ theme, $borderBottom }) =>
      $borderBottom ? `1px solid ${theme.color.primaryDividerColor}` : "none"};
    font-family: ${({ theme }) => theme.font.primaryFont};
    font-size: 16px;
    font-weight: ${(p) => (p.$selected ? 600 : 500)};
    text-transform: capitalize;
    align-items: center;
    justify-content: flex-start;
    transition: all 0.4s ease;

    &:hover {
      background-color: ${({ theme }) =>
        theme.color.primaryFontColor} !important;
      color: ${({ theme }) => theme.color.primaryContainerColor};
    }
  }
`;
