import {
  Button,
  Checkbox,
  DialogContent,
  DialogTitle,
  Menu,
  Paper,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import styled from "styled-components";
import {
  DoxleFont,
  DoxleThemeColor,
} from "../../DoxleGeneralStore/useDoxleThemeStore";
import { motion } from "framer-motion";
import { editRgbaAlpha } from "../../Utilities/FunctionUtilities";
import { LazyLoadImage } from "react-lazy-load-image-component";
// import { DropzoneDialog } from "material-ui-dropzone";

export const StyledDrawingsTableHeaderContainer = styled(TableRow)<{
  $themeColor: DoxleThemeColor;
}>`
  && {
    width: calc(100% - 2rem) !important;

    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: ${(p) => p.$themeColor.primaryBackgroundColor};
    padding: 14px 1rem;
  }
`;

export const StyledDrawingsCheckBox = styled(Checkbox)`
  &.MuiCheckbox-root {
    padding: 9px !important;
  }
  & .MuiSvgIcon-root {
  }
  &.MuiCheckbox-root:hover {
  }
`;

export const StyledDrawingsDataRow = styled(motion(TableCell))<{
  $themeColor: DoxleThemeColor;
}>`
  && {
    width: calc(100% - 2rem - 2px);
    display: flex;
    flex-direction: row;

    height: 100%;
    border: 1px solid ${(p) => p.$themeColor.rowBorderColor};
    cursor: pointer;
    position: relative;
    border-radius: 0px 0.9rem 0.9rem 0.9rem;
    background-color: ${(p) => p.$themeColor.primaryTableRowColor};
    padding: 1rem !important;
  }
`;

export const StyledDrawingsTableCell = styled.div<{
  $widthInRatio: `${number}%` | null;
  $horizontalAlign?: "flex-start" | "center" | "flex-end";
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  ${(p) => (p.$widthInRatio ? `width: ${p.$widthInRatio};` : "flex:1;")}
  display:flex;
  height: 100%;
  justify-content: ${(p) =>
    p.$horizontalAlign ? p.$horizontalAlign : "center"};
  align-items: center;
  overflow: hidden;
  .drawing-name {
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-style: normal;
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 1.8rem;
    letter-spacing: -0.028rem;
    color: ${(p) => p.$themeColor.primaryFontColor};
  }

  .history-text {
    color: ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.$themeColor.primaryFontColor,
        alpha: "0.7",
      })};
    font-family: ${(p) => p.$doxleFont.subTitleFont};
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 400;
    line-height: 118.9%; /* 14.268px */
    letter-spacing: -0.024rem;
    margin-bottom: 4px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    text-align: center;
  }
`;

export const StyledDrawingTableInput = styled(TextField)<{
  $widthInPixel: `${number}px` | null;

  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  && {
    // ${(p) => (p.$widthInPixel ? `width: ${p.$widthInPixel};` : "flex:1;")}
    display: flex;
    justify-content: center;
    padding: 0px 8px;
    width: auto;
    max-width: 100%;
  }
  & .MuiInput-input {
    cursor: text;
    border: none !important;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: ${(p) => p.$doxleFont.subTitleFont};
    font-size: 18px;
  }
  input {
    color: ${(p) => p.$themeColor.primaryFontColor};
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    text-align: center;
  }
`;

export const StyledDrawingsHeaderCell = styled(TableCell)<{
  $widthInRatio: `${number}%` | null;
  $horizontalAlign?: "flex-start" | "center" | "flex-end";
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  && {
    display: flex;
    ${(p) => (p.$widthInRatio ? `width: ${p.$widthInRatio};` : "flex:1;")}
    justify-content: ${(p) =>
      p.$horizontalAlign ? p.$horizontalAlign : "center"};
    align-items: center;
    padding: 0px !important;
    border: none !important;
    .header-text {
      font-family: ${(p) => p.$doxleFont.primaryFont};
      font-style: normal;
      font-weight: 400;
      font-size: 1.2rem;
      line-height: 1.6rem;
      letter-spacing: 0.06rem;
      color: ${(p) => p.$themeColor.primaryFontColor};
      text-transform: capitalize;
    }

    .add-drawing-btn {
      cursor: pointer;
      transition: 0.4s;
      &:hover {
        transition: 0.4s;
        transform: scale(1.2);
      }
    }
  }
`;

export const StyledDrawingsButton = styled(Button)<{
  backgroundColor: string;
}>`
  && {
    margin-left: 11px;
  }
  &.MuiButton-root {
    background-color: ${(p) => p.backgroundColor};
    width: 50px;
    height: 33px;
  }
  &.MuiButtonBase-root {
    border-radius: 17px;
  }
  &.MuiButton-root:hover {
    background-color: ${(p) => p.backgroundColor};
  }
`;

export const StyledDrawingsVerticalDotButton = styled(Button)`
  && {
    width: 0px;
    min-width: 0px;
    max-width: 0px;
    height: 0px;
    background-color: transparent;
    margin-left: 18px;
  }
  &.MuiButton-root:hover {
    background-color: transparent;
  }
`;

export const StyledDrawingsImg = styled(LazyLoadImage)`
  width: 100%; /* or any custom size */
  height: 100%;
  object-fit: contain;

  border-radius: 0px;
  transition: 0.4s;
  &:hover {
    transform: scale(1.01);
  }
`;

export const StyledDrawingsDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 303px;
  height: 213px;
  border-radius: 0px;
`;

export const StyledDrawingsHamburgerMenu = styled(Menu)`
  & .MuiMenu-list {
    background-color: white;
  }
  & .MuiMenuItem-root {
    font-family: "IBM Plex Sans";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.05em;
  }
`;

// export const StyledDrawingsDropzoneMenu = styled(DropzoneDialog)`
//   && {
//     box-shadow: none !important;
//   }
//   & .MuiDropzoneArea {
//     font-family: "IBM Plex Sans";
//     font-style: normal;
//     font-weight: 500;
//     font-size: 14px;
//     line-height: 18px;
//   }
//   & .MuiPaper-elevation24 {
//     box-shadow: none !important;
//   }
// `;

export const StyledDrawingsDropzoneText = styled(Typography)`
  & {
    font-family: "IBM Plex Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.05em;
  }
`;

export const StyledDrawingsDropzoneCheckbox = styled(Checkbox)`
  &.MuiCheckbox-root {
    padding: 0px !important;
  }
  & .MuiSvgIcon-root {
  }
  &.MuiCheckbox-root:hover {
  }
`;
export const StyledDrawingListScroller = styled.div`
  width: 100%;
  height: 100% !important;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;
export const StyledDrawingTableBody = styled(TableBody)`
  && {
    width: 100% !important;
    min-width: 40rem !important;
  }
`;
export const StyledDrawingTableRow = styled(motion(TableRow))<{
  $themeColor: DoxleThemeColor;
}>`
  && {
    width: 100% !important;

    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    padding-bottom: 1.4rem;
  }
`;

export const StyledDrawingPopperMenu = styled(Paper)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  && {
    width: 150px;
    min-height: 100px !important;
    border: 1px solid ${(p) => p.theme.color.primaryDividerColor};
    border-radius: 0px 0px 0px 9px;
    background-color: ${(p) => p.$themeColor.primaryContainerColor};
    padding: 14px 8px;
    display: flex;
    flex-direction: column;
    .drawing-title {
      color: ${(p) => p.$themeColor.primaryFontColor};
      font-family: ${(p) => p.$doxleFont.primaryFont};
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;

      align-self: flex-start;
      border-radius: 0px 0px 0px 4px;
      padding: 2px 8px;
      background-color: ${(p) =>
        editRgbaAlpha({ rgbaColor: p.$themeColor.doxleColor, alpha: "0.1" })};
      margin-bottom: 20px;
    }
    .edit-menu-button {
      width: calc(100% - 16px);
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 4px 8px;
      transition: 0.4s;
      border-radius: 4px;
      color: ${(p) =>
        editRgbaAlpha({
          rgbaColor: p.$themeColor.primaryFontColor,
          alpha: "0.6",
        })};
      font-family: ${(p) => p.$doxleFont.primaryFont};
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      cursor: pointer;
      margin-bottom: 8px;
      &:hover {
        transition: 0.4s;
        color: ${(p) => p.$themeColor.primaryFontColor};
        background-color: ${(p) => p.$themeColor.hoverColor};
      }
    }

    .delete-menu-button {
      width: calc(100% - 16px);
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 4px 8px;
      transition: 0.4s;
      border-radius: 4px;
      color: ${(p) =>
        editRgbaAlpha({
          rgbaColor: p.$themeColor.primaryFontColor,
          alpha: "0.6",
        })};
      font-family: ${(p) => p.$doxleFont.primaryFont};
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      cursor: pointer;
      margin-bottom: 4px;
      &:hover {
        transition: 0.4s;
        color: rgba(255, 0, 0, 1);
        background-color: rgba(255, 0, 0, 0.1);
      }
    }
  }
`;
export const StyledDrawingsRootContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const StyledAddDrawingTitle = styled(DialogTitle)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  && {
    color: ${(p) => p.$themeColor.primaryFontColor};
    font-family: ${(p) => p.$doxleFont.primaryTitleFont};
    font-size: 1.8rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border-bottom: 1px solid ${(p) => p.$themeColor.primaryDividerColor};
  }
`;

export const StyledAddDrawingButton = styled(motion(Button))<{
  $bgColor: string;
  $textColor: string;
  $doxleFont: DoxleFont;
  $themeColor: DoxleThemeColor;
}>`
  && {
    color: ${(p) => p.$textColor};
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    background-color: ${(p) => p.$bgColor};
    box-shadow: 0px 0px 8px 0px ${(p) => p.$themeColor.primaryBoxShadowColor};
    text-transform: capitalize;
    margin: 0px 4px;
    &:hover {
      background-color: ${(p) => p.$bgColor} !important;
      opacity: 0.8;
      transition: 0.4s;
    }
    &.MuiButton-root.Mui-disabled {
      color: ${(p) => p.$textColor} !important;
      background-color: grey !important;
    }
  }
`;

export const StyledAddDrawingDropzone = styled(motion(DialogContent))<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  && {
    width: calc(100% - 48px);
    height: 60rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position: relative;
    .dropzone {
      color: ${(p) => p.$themeColor.primaryFontColor};
      font-family: ${(p) => p.$doxleFont.primaryFont};
      font-size: clamp(20px, 2rem, 24px);
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex: 1;
      width: 100%;
    }
    .error-text {
      color: red;
      font-family: ${(p) => p.$doxleFont.primaryTitleFont};
      font-size: 1.2rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      width: 100%;
      text-align: left;
    }
  }
`;

export const StyledAddedFileSection = styled(motion.div)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  width: 100%;
  display: flex;
  flex-direction: column;
  max-height: 30%;

  .attachment-section {
    width: calc(100% - 28px);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 14px;
  }
`;
export const StyledAddedDrawingFile = styled(motion.div)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 4px 8px;
  overflow: visible;
  width: calc(15% - 16px);

  .file-name {
    color: ${(p) => p.$themeColor.primaryFontColor};
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }
  .pdf-image {
    width: 100%;
    aspect-ratio: ${60 / 57.69};
  }
  .remove-icon {
    position: absolute;
    top: -0.7rem;
    right: 0px;
    z-index: 10;
    &:hover {
      transition: 0.4s;
      transform: scale(1.2);
    }
  }
`;
export const StyledNewDrawingNameTextField = styled(TextField)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  && {
    width: 100%;
    margin-top: 8px;
  }
  input {
    padding: 0px 0px 0.2rem 0px !important;
    font-family: ${(p) => p.$doxleFont.primaryTitleFont};
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.028rem;
    color: ${(p) => p.$themeColor.primaryFontColor};

    &::placeholder {
      font-family: ${(p) => p.$doxleFont.primaryTitleFont};
      font-size: 1.4rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.028rem;
      color: ${(p) => p.$themeColor.primaryInputPlaceholderColor} !important;
      opacity: 1 !important;
    }
  }
  & .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }
  & .MuiInputBase-root {
    height: 100%;
  }
`;
export const StyledDrawingsTopMenuContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  justify-content: space-between;

  .drawing-nav-container {
    display: flex;
    align-items: center;
    flex-direction: row;
    overflow: visible;
  }
`;

export const StyledBreadCrumbDrawingNavItem = styled(motion.div)<{
  $doxleFont: DoxleFont;
}>`
  width: 8.8rem;
  height: 2.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-transform: capitalize;

  clip-path: polygon(0 0, 85% 0, 100% 50%, 85% 100%, 0 100%, 15% 50%, 0 0);

  font-family: ${(p) => p.$doxleFont.primaryFont};
  font-style: normal;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.3rem;
  transition: 0.4s;
  text-decoration: underline;
  &:hover {
    opacity: 0.8;
    transition: 0.4s;
  }
`;

export const StyledDrawingTopMenuButton = styled(motion(Button))<{
  $doxleFont: DoxleFont;
  $themeColor: DoxleThemeColor;
}>`
  && {
    display: flex;
    flex-direction: row;
    min-width: 0px !important;
    padding: 6px 12px !important;
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-style: normal;
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 1.3rem;
    transition: 0.4s;
    color: red;
    text-transform: none;
    background-color: ${(p) => p.$themeColor.primaryContainerColor};
    transition: 0.4s;
    box-shadow: 0px 0px 8px 0px ${(p) => p.$themeColor.primaryBoxShadowColor};
    &:hover {
      background-color: ${(p) =>
        p.$themeColor.primaryContainerColor} !important;
      transition: 0.4s;
      transform: translateY(-2px);
    }
  }
`;
export const StyledAddSheetTitle = styled(DialogTitle)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  && {
    color: ${(p) => p.$themeColor.primaryFontColor};
    font-family: ${(p) => p.$doxleFont.primaryTitleFont};
    font-size: 1.8rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border-bottom: 1px solid ${(p) => p.$themeColor.primaryDividerColor};
  }
`;
export const StyledAddSheetDropzone = styled(motion(DialogContent))<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  && {
    width: calc(100% - 48px);
    height: 60rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    .dropzone {
      color: ${(p) => p.$themeColor.primaryFontColor};
      font-family: ${(p) => p.$doxleFont.primaryFont};
      font-size: 1.4rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex: 1;
      width: 100%;
    }
    .error-text {
      color: red;
      font-family: ${(p) => p.$doxleFont.primaryTitleFont};
      font-size: 1.2rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      width: 100%;
      text-align: left;
    }
  }
`;

export const StyledDrawingWrapper = styled.div<{ $isClone: boolean }>`
  min-height: 0px;
  width: 100%;

  display: flex;
  padding-bottom: 60px;
  align-items: center;
  transition: transform 0.6s ease;
  transform: ${(p) => (p.$isClone ? "scale(0.4)" : "scale(1)")};
`;

export const StyledDrawingNameContainer = styled.div``;

export const StyledRightMainDrawingContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  justify-content: center;
  align-items: center;
`;

export const StyledDrawingsImageContainer = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  width: 100%;
  height: 40rem;
  display: flex;
  align-items: center;
  justify-content: center;
  postion: relative;
  .empty-sheet-display {
    width: 100%;
    height: 50rem;
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-style: normal;
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 1.8rem;
    letter-spacing: -0.028rem;
    color: ${(p) => p.$themeColor.primaryFontColor};
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .error-sheet-display {
    width: 100%;
    height: 50rem;
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-style: normal;
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 1.8rem;
    letter-spacing: -0.028rem;
    color: red;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const StyledPageInformation = styled.div<{
  $themeColor: DoxleThemeColor;
}>`
  font-size: 13px;
  color: ${(p) =>
    editRgbaAlpha({ rgbaColor: p.$themeColor.primaryFontColor, alpha: "0.6" })};
  display: flex;
  justify-content: center;
`;

export const StyledRightCornerMenuContainer = styled.div`
  position: absolute;
  right: 0px;
  top: 0px;
  bottom: 0px;
  display: flex;
  align-items: center;
`;

export const StyledDrawingInfoContainer = styled.div`
  max-width: 100%;
  min-width: 40%;
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
`;

export const StyledBottomContainer = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  justify-content: center;
`;

export const StyledDrawingListContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
`;

export const StyledDrawingsContainer = styled(motion.div)`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;

  .height-preserving-container:empty {
    min-height: calc(var(--child-height));
    box-sizing: border-box;
  }
`;
