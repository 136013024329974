import React, { useMemo } from "react";

import ScopeGroupTitle from "./ScopeGroupTitle";
import AddScopeItem from "./AddScopeItem";

import ScopeItemRow from "./ScopeItemRow";
import { AnimatePresence, LayoutGroup } from "framer-motion";
import { ScopeOfWorkGroup } from "../Model/scopeOfWorks";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import DoxleDialogHelper from "../../DoxleDesignPattern/DoxleDialogHelper/DoxleDialogHelper";
import useGetScopeItemList from "../Hooks/useGetScopeItemList";
import useScopeGroupRow from "../Hooks/useScopeGroupRow";
import DoxleSelectContactDropdown from "../../DoxleDesignPattern/DoxleSelectContactDropdown/DoxleSelectContactDropdown";

type Props = {
  scopeGroup: ScopeOfWorkGroup;
};

const ScopeGroupRow = ({ scopeGroup }: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore((state) => ({
    doxleThemeColor: state.doxleThemeColor,
    doxleFont: state.doxleFont,
  }));

  const {
    scopeItemList,
    isFetchingScopeItemList,
    isSuccessFetchingScopeItemList,
    isErrorFetchingScopeItemList,
  } = useGetScopeItemList({ scopeGroup });

  const {
    handleCloseDialogConfirmDelete,
    deletedScopeItem,
    handleDeleteScopeItem,
    setDeletedScopeItem,
    handleUpdateScopeItem,
    anchorAssigneeSOWItemPopover,
    handleToggleSOWItemAssigneePopover,

    editedSOWItem,
    handleCloseSOWItemAssigneePopover,
    handleUpdateAssigneeSOWItem,
  } = useScopeGroupRow({ scopeGroup });

  //# Dialog Delete Helper props
  const openDialogDelete = useMemo(
    () => Boolean(deletedScopeItem),
    [deletedScopeItem]
  );

  return (
    <>
      <DoxleDialogHelper
        open={openDialogDelete}
        title={"Confirm Delete Scope Item!!!"}
        description={`All Data belong to  docket ${deletedScopeItem?.scopeItemDescription} will be deleted PERMANENTLY, Are you sure to proceed?`}
        dialogButtons={[
          {
            buttonText: "Cancel",
            buttonFunction: handleCloseDialogConfirmDelete,
            buttonColor: doxleThemeColor.primaryContainerColor,
            btnStyleSx: {
              border: `1px solid ${doxleThemeColor.primaryDividerColor}`,
            },
            buttonTextStyle: {
              color: doxleThemeColor.primaryFontColor,
            },
          },
          {
            buttonText: "Confirm",
            buttonFunction: handleDeleteScopeItem,
          },
        ]}
        onClose={handleCloseDialogConfirmDelete}
      />
      <ScopeGroupTitle scopeGroup={scopeGroup} />
      {isSuccessFetchingScopeItemList && (
        <AnimatePresence>
          <LayoutGroup id={`layout#${scopeGroup.scopeGroupId}`}>
            {scopeItemList.length > 0 &&
              scopeItemList.map((scopeItem, index) => (
                <ScopeItemRow
                  key={scopeItem.scopeItemId}
                  scopeItem={scopeItem}
                  setDeletedScopeItem={setDeletedScopeItem}
                  handleUpdateScopeItem={handleUpdateScopeItem}
                  handleToggleSOWItemAssigneePopover={
                    handleToggleSOWItemAssigneePopover
                  }
                  editedSOWItem={editedSOWItem}
                />
              ))}
          </LayoutGroup>
        </AnimatePresence>
      )}
      <AddScopeItem
        filterScopeItemQuery={{ scopeGroupId: scopeGroup.scopeGroupId }}
        scopeGroup={scopeGroup}
      />

      <DoxleSelectContactDropdown
        popoverAnchor={anchorAssigneeSOWItemPopover}
        onClosePopover={handleCloseSOWItemAssigneePopover}
        variants="contact"
        selectedContactItemIds={editedSOWItem?.assignedContacts ?? undefined}
        onSelectContact={handleUpdateAssigneeSOWItem}
        closeOnSelect={false}
        containerStyle={{
          borderRadius: 9,
        }}
      />
    </>
  );
};

export default React.memo(ScopeGroupRow);
