import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Slide,
} from "@mui/material";
import { TransitionProps } from "notistack";
import React from "react";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import {
  StyledAddDrawingButton,
  StyledAddDrawingDropzone,
  StyledAddDrawingTitle,
  StyledAddedDrawingFile,
  StyledAddedFileSection,
  StyledNewDrawingNameTextField,
} from "./DrawingsStyledComponent";
import useAddDrawingDropZone from "../Hooks/useAddDrawingDropZone";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import { AnimatePresence, LayoutGroup, motion } from "framer-motion";
import PdfImg from "../Asset/PDF.png";
import CancelIcon from "@mui/icons-material/Cancel";
import { useDropzone } from "react-dropzone";
import { AddDrawingDropzoneIcon } from "./DrawingsIcon";
import { editRgbaAlpha } from "../../Utilities/FunctionUtilities";
import AddItemLoadingScreen from "../../Utilities/Lottie/AddItemLoadingScreen";

type Props = {
  open: boolean;
  onClose: () => void;
};

const AddDrawingDropZone = ({ open, onClose }: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }),
    shallow
  );

  const {
    addedFile,
    newDrawingTitle,
    setNewDrawingTitle,
    handleRemoveAddedFile,
    showErrorText,
    handleAddDrawing,
    onDrop,
    isAddingDrawing,
  } = useAddDrawingDropZone({ openDropzone: open, onClose });
  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    onDrop,
    accept: {
      "application/pdf": [".pdf"],
    },
    maxFiles: 1,
    multiple: false,
  });

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      onClose={onClose}
      aria-describedby="add-drawing-dialog-slide"
      slotProps={{
        backdrop: {
          sx: {
            bgcolor: editRgbaAlpha({
              rgbaColor: doxleThemeColor.primaryBackgroundColor,
              alpha: "0.8",
            }),
          },
        },
      }}
      PaperProps={{
        sx: {
          bgcolor: doxleThemeColor.primaryContainerColor,
          display: "flex !important",
          width: "100vw",
          height: "70rem",
          borderRadius: "0.9rem",
          border: `1px solid ${doxleThemeColor.primaryDividerColor}`,
        },
        elevation: 8,
      }}
    >
      <StyledAddDrawingTitle
        $themeColor={doxleThemeColor}
        $doxleFont={doxleFont}
      >
        Add Drawings
      </StyledAddDrawingTitle>
      <StyledAddDrawingDropzone
        $themeColor={doxleThemeColor}
        $doxleFont={doxleFont}
      >
        <motion.div layout className="dropzone" {...getRootProps({})}>
          <AddDrawingDropzoneIcon
            themeColor={doxleThemeColor}
            containerStyle={{
              width: "70%",
              marginBottom: 14,
            }}
          />
          Drop or click on this zone to add file!
        </motion.div>

        {addedFile && (
          <StyledAddedFileSection
            $themeColor={doxleThemeColor}
            $doxleFont={doxleFont}
            layout
            initial={false}
            animate={{ y: [500, 0] }}
          >
            <motion.div className="attachment-section">
              <AddedDrawingFile
                file={addedFile}
                handleRemoveAddedFile={handleRemoveAddedFile}
              />
            </motion.div>

            <StyledNewDrawingNameTextField
              $themeColor={doxleThemeColor}
              $doxleFont={doxleFont}
              value={newDrawingTitle}
              onChange={(event) => setNewDrawingTitle(event.target.value)}
              placeholder="Drawing name..."
              autoFocus
              onKeyDown={(event) => {
                if (event.nativeEvent.key === "Enter") {
                  event.stopPropagation();
                  handleAddDrawing();
                }
                if (event.nativeEvent.key === "Escape") {
                  event.stopPropagation();
                  setNewDrawingTitle("");
                }
              }}
            />
          </StyledAddedFileSection>
        )}

        {isAddingDrawing && (
          <AddItemLoadingScreen
            containerStyle={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              zIndex: 10,
              backgroundColor: editRgbaAlpha({
                rgbaColor: doxleThemeColor.primaryContainerColor,
                alpha: "0.8",
              }),
            }}
          />
        )}

        <AnimatePresence>
          {showErrorText && (
            <motion.span
              className="error-text"
              initial={false}
              animate={{ x: [-10, 0], opacity: [0, 1] }}
              exit={{
                x: [0, -10],
                opacity: [1, 0],
              }}
            >
              Please enter drawing name...
            </motion.span>
          )}
        </AnimatePresence>
      </StyledAddDrawingDropzone>
      <DialogActions
        sx={{
          width: "calc(100% - 28px)",
          flexDirection: "row",
          display: "flex",
          justifyContent: "flex-end",
          padding: "8px 14px",
        }}
      >
        <StyledAddDrawingButton
          $doxleFont={doxleFont}
          $bgColor={doxleThemeColor.primaryFontColor}
          $textColor={doxleThemeColor.primaryContainerColor}
          $themeColor={doxleThemeColor}
          layout
          disabled={Boolean(!addedFile || isAddingDrawing)}
          onClick={handleAddDrawing}
        >
          {isAddingDrawing && (
            <CircularProgress
              size="1.2rem"
              style={{
                color: doxleThemeColor.primaryContainerColor,
                marginRight: 4,
              }}
            />
          )}
          {isAddingDrawing ? "Processing..." : "Add"}
        </StyledAddDrawingButton>

        <StyledAddDrawingButton
          $doxleFont={doxleFont}
          $bgColor={doxleThemeColor.primaryContainerColor}
          $textColor={doxleThemeColor.primaryFontColor}
          $themeColor={doxleThemeColor}
          layout
          onClick={onClose}
          style={{
            border: `1px solid ${doxleThemeColor.primaryDividerColor}`,
          }}
        >
          Cancel
        </StyledAddDrawingButton>
      </DialogActions>
    </Dialog>
  );
};

export default AddDrawingDropZone;

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AddedDrawingFile = (props: {
  file: File;
  handleRemoveAddedFile: () => void;
}) => {
  const { file, handleRemoveAddedFile } = props;
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }),
    shallow
  );

  return (
    <StyledAddedDrawingFile
      $themeColor={doxleThemeColor}
      $doxleFont={doxleFont}
      layout
      initial={{ opacity: 0, scale: 0 }}
      animate={{ opacity: 1, scale: 1 }}
      //   exit={{ opacity: [1, 0] }}
      transition={{
        duration: 0.2,
      }}
    >
      <img className="pdf-image" src={PdfImg} alt="pdfLogo" />
      <span className="file-name">{file.name}</span>

      <div className="remove-icon" onClick={handleRemoveAddedFile}>
        <CancelIcon
          style={{
            fontSize: "1.4rem",
            color: doxleThemeColor.primaryFontColor,
          }}
        />
      </div>
    </StyledAddedDrawingFile>
  );
};
