import { useEditDocketSideScreenStore } from "../EditDocketSideScreenStore/useEditDocketSideScreenStore";
import { shallow } from "zustand/shallow";
import { StyledCustomiseFunctionalMenu } from "../StyledComponentsEditDocketSideScreen";
import { AnimatePresence } from "framer-motion";
import DoxleAddNewButton from "../../../DoxleDesignPattern/DoxleButtons/DoxleAddNewButton";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";

import DoxleDeleteManyButton from "../../../DoxleDesignPattern/DoxleButtons/DoxleDeleteManyButton";
import { useShallow } from "zustand/react/shallow";
type Props = {};

const CustomiseFunctionalMenu = (props: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    useShallow((state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }))
  );
  const {
    handleAddBtn,

    selectedFunctionMenu,
    handleCloseButtonFn,
    handleDeleteBtnFn,
    quoteDeleteBtnFn,
    emailQuoteBtnFn,
  } = useEditDocketSideScreenStore(
    useShallow((state) => ({
      handleAddBtn: state.handleAddButtonFn,

      selectedFunctionMenu: state.selectedFunctionMenu,
      handleCloseButtonFn: state.handleCloseButtonFn,
      handleDeleteBtnFn: state.handleDeleteManyButtonFn,
      quoteDeleteBtnFn: state.quoteDeleteBtnFn,
      emailQuoteBtnFn: state.emailQuoteBtnFn,
    }))
  );

  return (
    <StyledCustomiseFunctionalMenu $themeColor={doxleThemeColor}>
      {handleDeleteBtnFn ? (
        <AnimatePresence>
          <DoxleDeleteManyButton
            onClick={(e) => {
              handleDeleteBtnFn?.btnFunction(e);
            }}
            btnText={handleDeleteBtnFn?.btnText}
            btnIcon={Boolean(handleDeleteBtnFn?.btnIcon)}
          />
        </AnimatePresence>
      ) : (
        handleAddBtn?.map((btnFn, i) => (
          <AnimatePresence key={`btn#${i}`}>
            <DoxleAddNewButton
              onClick={(e) => {
                btnFn.btnFunction(e);
              }}
              btnText={btnFn?.btnText}
              showBtnIcon={btnFn.showBtnIcon}
              btnIcon={btnFn.btnIcon}
              iconOnly={!btnFn.showBtnIcon}
              btnBaseColor={btnFn.btnBaseColor}
              tooltipText={btnFn.tooltipText}
              isLoading={btnFn.isLoading}
            />
          </AnimatePresence>
        ))
      )}
      <AnimatePresence>
        {handleCloseButtonFn && (
          <AnimatePresence>
            <DoxleAddNewButton
              onClick={(e) => {
                handleCloseButtonFn.btnFunction(e);
              }}
              btnText={handleCloseButtonFn.btnText ?? "Close"}
              showBtnIcon={"start"}
              btnIcon={"/assets/icons/close-icon.svg"}
              btnBaseColor={doxleThemeColor.primaryContainerColor}
            />
          </AnimatePresence>
        )}
      </AnimatePresence>
    </StyledCustomiseFunctionalMenu>
  );
};

export default CustomiseFunctionalMenu;
