import React from "react";

interface props{}

const DrawingPlaceholder: React.FC<props> = () => {

    return(
        <div className="drawing-placeholder">
            {/*<Grid container>*/}
            {/*    <Grid item xs={12}>*/}
            {/*        <Grid container>*/}
            {/*            <Grid item xs={4}>*/}
            {/*                <NativeSelect*/}
            {/*                    style={{ marginTop: "10px", marginLeft: "20px" }}*/}
            {/*                    onChange={handleDrawingsSelect}*/}
            {/*                >*/}
            {/*                    {drawings.map((element: any, idx: any) => {*/}
            {/*                        return (*/}
            {/*                            <option key={idx} value={element.setId}>{element.name}</option>*/}
            {/*                        );*/}
            {/*                    })}*/}
            {/*                </NativeSelect>*/}
            {/*            </Grid>*/}
            {/*            <Grid item xs={1} style={{ marginTop: "13px" }}>*/}
            {/*                <small>*/}
            {/*                    <NativeSelect*/}
            {/*                        value={activeSheetId}*/}
            {/*                        onChange={handleSheetsSelect}*/}
            {/*                    >*/}
            {/*                        {sheets.map((element: any, idx: any) => {*/}
            {/*                            return (*/}
            {/*                                <option key={idx} value={element.sheetId}>{element.title}</option>*/}
            {/*                            );*/}
            {/*                        })}*/}
            {/*                    </NativeSelect>*/}
            {/*                </small>*/}
            {/*            </Grid>*/}
            {/*            <Grid item xs={1} style={{ marginTop: "15px" }}>*/}
            {/*                <IconButton*/}
            {/*                    aria-label="prev"*/}
            {/*                    sx={{ padding: "0", mx: "8px"}}*/}
            {/*                    onClick={()=>handleNextSheet(false)}*/}
            {/*                    disabled={!isPrevOn}*/}
            {/*                >*/}
            {/*                    <img src={isPrevOn?*/}
            {/*                        ICONS.ArrowLeftCircle:*/}
            {/*                        ICONS.ArrowLeftCircleOff}*/}
            {/*                         alt="prev" />*/}
            {/*                </IconButton>*/}

            {/*                <IconButton*/}
            {/*                    aria-label="next"*/}
            {/*                    sx={{ padding: "0" }}*/}
            {/*                    onClick={()=>handleNextSheet(true)}*/}
            {/*                    disabled={!isNextOn}*/}
            {/*                >*/}
            {/*                    <img src={isNextOn?*/}
            {/*                        ICONS.ArrowRightCircle:*/}
            {/*                        ICONS.ArrowRightCircleOff}*/}
            {/*                         alt="next" />*/}
            {/*                </IconButton>*/}
            {/*            </Grid>*/}
            {/*            <Grid item xs={1} style={{ marginTop: "15px" }}>*/}
            {/*                <img src={ICONS.Maximize} alt="Maximize" />*/}
            {/*            </Grid>*/}
            {/*            <Grid item xs={4} style={{ float: "right" }}>*/}
            {/*                <div style={{ position: "relative", float: "right" }}>*/}
            {/*                    <ul className="measurement-header-icon-box">*/}
            {/*                        <li*/}
            {/*                            onClick={handleUndo}*/}
            {/*                            className="icon-line-above clickable-icon">*/}
            {/*                            <Tooltip title="Undo">*/}
            {/*                                <UndoIcon style={{color:"#1521B6"}}/>*/}
            {/*                            </Tooltip>*/}
            {/*                        </li>*/}
            {/*                        <li className="icon-line-above">*/}
            {/*                            <img src={ICONS.Linehorizontal} alt="" />*/}
            {/*                        </li>*/}
            {/*                        <li className="icon-line-above clickable-icon">*/}
            {/*                            <Tooltip title="Add">*/}
            {/*                                <img*/}
            {/*                                    src={ICONS.Plus}*/}
            {/*                                    style={{*/}
            {/*                                        width: "19px",*/}
            {/*                                        height: "19px",*/}
            {/*                                        marginTop: "3px",*/}
            {/*                                    }}*/}
            {/*                                    alt=""*/}
            {/*                                />*/}
            {/*                            </Tooltip>*/}
            {/*                        </li>*/}
            {/*                        <li className="icon-line-above">*/}
            {/*                            <img src={ICONS.Linehorizontal} alt="" />*/}
            {/*                        </li>*/}
            {/*                        <li*/}
            {/*                            onClick={handleRulerIcon}*/}
            {/*                            className="icon-line-above clickable-icon"*/}
            {/*                        >*/}
            {/*                            <Tooltip title="Scale">*/}
            {/*                                <img src={ICONS.Ruler} alt="" />*/}
            {/*                            </Tooltip>*/}
            {/*                        </li>*/}
            {/*                        <li className="icon-line-above">*/}
            {/*                            <img src={ICONS.Linehorizontal} alt="" />*/}
            {/*                        </li>*/}
            {/*                        <li className="icon-line-above clickable-icon">*/}
            {/*                            <Tooltip title="Edit">*/}
            {/*                                <img*/}
            {/*                                    src={ICONS.EditSvgIcon}*/}
            {/*                                    style={{ width: "23px", height: "23px" }}*/}
            {/*                                    alt=""*/}
            {/*                                />*/}
            {/*                            </Tooltip>*/}
            {/*                        </li>*/}
            {/*                        <li className="icon-line-above">*/}
            {/*                            <img src={ICONS.Linehorizontal} alt="" />*/}
            {/*                        </li>*/}
            {/*                        <li*/}
            {/*                            onClick={() => handleSaveTakeOffWithMeasurement()}*/}
            {/*                            className="icon-line-above clickable-icon">*/}
            {/*                            <Tooltip title="Save">*/}
            {/*                                <ICONS.checkIcon style={{color: "#1521B6"}} />*/}
            {/*                            </Tooltip>*/}
            {/*                        </li>*/}
            {/*                    </ul>*/}
            {/*                </div>*/}
            {/*            </Grid>*/}
            {/*            <Grid item xs={1}></Grid>*/}
            {/*        </Grid>*/}
            {/*    </Grid>*/}
            {/*    <Grid item xs={12}>*/}
            {/*        <Divider />*/}
            {/*    </Grid>*/}

            {/*    {selectedMeasurement !== ""?*/}
            {/*        <div className="measurement-tools-container">*/}
            {/*            <ul>*/}
            {/*                {currUnit !== "EA" &&*/}
            {/*                    <><li*/}
            {/*                        onClick={() => {*/}
            {/*                            setTool("Polygon");*/}
            {/*                        }}*/}
            {/*                        className={`measurement-tools-list ${tool === "Polygon" && "active"}`}*/}
            {/*                    >*/}
            {/*                        <img src={ICONS.Polygon} className="measurement-icons" alt="" />*/}
            {/*                        <b className="icon1">Polygon</b>*/}
            {/*                    </li>*/}
            {/*                        <li*/}
            {/*                            onClick={() => {*/}
            {/*                                setTool("Square");*/}
            {/*                            }}*/}
            {/*                            className={`measurement-tools-list ${tool === "Square"?"active":""}`}*/}
            {/*                        >*/}
            {/*                            <img src={ICONS.StopSquare} className="measurement-icons" alt="" />*/}
            {/*                            <b className="icon2">Square</b>*/}
            {/*                        </li>*/}
            {/*                        <li*/}
            {/*                            onClick={() => {*/}
            {/*                                setTool("Circle");*/}
            {/*                            }}*/}
            {/*                            className={`measurement-tools-list ${tool === "Circle"?"active":""}`}*/}
            {/*                        >*/}
            {/*                            <img src={ICONS.Ellipse} className="measurement-icons" alt="" />*/}
            {/*                            <b className="icon3">Circle</b>*/}
            {/*                        </li>*/}
            {/*                        {currUnit !== "M3" && <li*/}
            {/*                            onClick={() => {*/}
            {/*                                setTool("Line");*/}
            {/*                            }}*/}
            {/*                            className={`measurement-tools-list ${tool === "Line"?"active":""}`}*/}
            {/*                        >*/}
            {/*                            <img src={ICONS.LineSvg} className="measurement-icons" alt="" />*/}
            {/*                            <b className="icon4">Line</b>*/}
            {/*                        </li>}*/}

            {/*                    </>*/}
            {/*                }*/}

            {/*                {currUnit === "EA" &&*/}
            {/*                    <li*/}
            {/*                        onClick={() => {*/}
            {/*                            setTool("Selector");*/}
            {/*                        }}*/}
            {/*                        className={`measurement-tools-list ${tool === "Selector"?"active":""}`}*/}
            {/*                    >*/}
            {/*                        <TouchAppOutlinedIcon color="action" className="measurement-icons" sx={{pt:"3px", pl:"15px"}}/>*/}
            {/*                    </li>*/}
            {/*                }*/}

            {/*                <li*/}
            {/*                    className={`measurement-tools-list`}*/}
            {/*                    style={{background: toolColor}}*/}
            {/*                >*/}
            {/*                    <b className="icon5">Colour</b>*/}
            {/*                    <ColourMenu toolColor={toolColor} setToolColor={setToolColor}/>*/}
            {/*                </li>*/}

            {/*                <li onClick={() => {*/}
            {/*                    setIsLabelOn((prevLabel=>!prevLabel));*/}
            {/*                }}*/}
            {/*                    className={`measurement-tools-list ${isLabelOn?"active":""}`}*/}
            {/*                >*/}
            {/*                    <img src={ICONS.SwatchBook} className="measurement-icons" alt="" />*/}
            {/*                    <b className="icon6">Labels</b>*/}
            {/*                </li>*/}

            {/*                {*/}
            {/*                    currUnit === "M3" || (currUnit === "M2" && tool === "Line") ?*/}
            {/*                        <li className="measurement-tools-list input-height">*/}
            {/*                            <small className="right-end-text">Height</small>*/}
            {/*                            <input*/}
            {/*                                className="icon-text-field"*/}
            {/*                                type={"text"}*/}
            {/*                                onChange={(e) => handlingZAxis(e.target.value)}*/}
            {/*                            />*/}
            {/*                        </li>*/}
            {/*                        :*/}
            {/*                        null*/}
            {/*                }*/}
            {/*            </ul>*/}
            {/*        </div>*/}
            {/*        : null*/}
            {/*    }*/}
            {/*</Grid>*/}
        </div>
    )
}

export default DrawingPlaceholder