import React from "react";
import PDFIcon from "../../Assets/adobePdfIcon.svg";
import MicrosoftIcon from "../../Assets/microsoftIcon.svg";
import ExcelIcon from "../../Assets/excelIcon.svg";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { QuoteStorage } from "../../models/quote";
import { StyledQuoteRequestAttachmentItem } from "../SharedDocketStyledComponents";

type Props = {
  attachment: QuoteStorage;
};

export const FILETYPE = {
  pdf: PDFIcon,
  docx: MicrosoftIcon,
  xlsx: ExcelIcon,
};
const QuoteRequestAttachmentItem = ({ attachment }: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore((state) => ({
    doxleThemeColor: state.doxleThemeColor,
    doxleFont: state.doxleFont,
  }));
  const fileExtensionSrc = attachment.fileType.toLowerCase().includes("pdf")
    ? FILETYPE.pdf
    : attachment.fileType.toLowerCase().includes("docx") ||
      attachment.fileType.toLowerCase().includes("doc")
    ? FILETYPE.docx
    : attachment.fileType.toLowerCase().includes("xlsx")
    ? FILETYPE.xlsx
    : attachment.url;

  const onClickAttachment = () => window.open(attachment.url);
  return (
    <StyledQuoteRequestAttachmentItem
      key={attachment.attachmentId}
      $themeColor={doxleThemeColor}
      $doxleFont={doxleFont}
      onClick={onClickAttachment}
    >
      <img className="img-wrapper" src={fileExtensionSrc} alt="files" />

      {attachment.fileName}
    </StyledQuoteRequestAttachmentItem>
  );
};

export default QuoteRequestAttachmentItem;
