import styled from "styled-components";
import {
  DoxleFont,
  DoxleTheme,
  DoxleThemeColor,
} from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { editRgbaAlpha } from "../../../Utilities/FunctionUtilities";

export const StyledMailSideScreenContainer = styled.div<{
  $doxleFont: DoxleFont;
  $themeColor: DoxleThemeColor;
  $theme: DoxleTheme;
}>`
  font-family: ${(p) => p.$doxleFont.secondaryTitleFont};
  width: 100%;
  height: 100%;
  background-color: ${(p) =>
    p.$theme === "light"
      ? "rgba(241, 242, 245, 1)"
      : p.$theme === "dark"
      ? "rgba(0,0,0,0.9)"
      : "rgba(246, 246, 246, 1)"};
  display: flex;
  flex-direction: column;
`;

export const StyledMailGeneralSection = styled.div`
  display: flex;
`;

export const StyledMainInfoContainer = styled.div`
  padding-left: 8rem;
  padding-top: 5rem;
  padding-right: 8rem;
  height: calc(100% - 5rem);
  display: flex;
  flex-direction: column;
  max-width: 110rem;
  position: relative;
`;

export const StyledMailBodySection = styled.div`
  margin-top: 3rem;
  margin-bottom: 3rem;
  flex: 1;

  &.mailBodySection {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  &.mailBodySection::-webkit-scrollbar {
    display: none;
  }
`;

export const StyledMailCommentsSection = styled.div`
  overflow-y: scroll;
  flex: 1;
  position: relative;

  &.mailCommentsSection {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  &.mailCommentsSection::-webkit-scrollbar {
    display: none;
  }
`;

export const StyledMailResponseSection = styled.div`
  display: flex;
  margin-bottom: 3rem;
  margin-top: 3rem;
`;

export const StyledMailReplySection = styled.div`
  position: relative;
  height: 100%;
`;

export const StyledEmailInfo = styled.div<{
  $themeColor: DoxleThemeColor;
  $currentTheme: DoxleTheme;
}>`
  color: ${(p) => p.$themeColor.primaryFontColor};
  font-size: 1.3rem;
  background-color: ${(p) =>
    p.$currentTheme === "light"
      ? "white"
      : p.$themeColor.primaryTextFieldBackgroundColor};
  padding: 0.5rem 0.5rem;
  min-width: 100px;
  overflow: hidden;

  &.fromName {
    white-space: nowrap;
  }
`;

export const StyledTopIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 8rem;
  padding-right: 2rem;
  padding-top: 0.5rem;
`;

export const StyledTopLeftSection = styled.div`
  display: flex;
`;

export const StyledTopRightSection = styled.div`
  display: flex;
`;

export const StyledMailBtn = styled.div<{
  $themeColor: DoxleThemeColor;
  $isActivated: boolean;
}>`
  background-color: ${(p) =>
    p.$isActivated
      ? editRgbaAlpha({
          rgbaColor: p.$themeColor.doxleColor,
          alpha: "0.3",
        })
      : editRgbaAlpha({
          rgbaColor: p.$themeColor.primaryReverseFontColor,
          alpha: "0.6",
        })};
  color: ${(p) =>
    editRgbaAlpha({ rgbaColor: p.$themeColor.primaryFontColor, alpha: "0.6" })};
  font-size: 1.2rem;
  cursor: pointer;
  padding: 0.5rem 1rem;
  margin-right: 0.5rem;
`;

export const StyledCommentsBtn = styled.div<{
  $themeColor: DoxleThemeColor;
  $isActivated: boolean;
}>`
  background-color: ${(p) =>
    p.$isActivated
      ? editRgbaAlpha({
          rgbaColor: p.$themeColor.doxleColor,
          alpha: "0.3",
        })
      : editRgbaAlpha({
          rgbaColor: p.$themeColor.primaryReverseFontColor,
          alpha: "0.6",
        })};
  color: ${(p) =>
    editRgbaAlpha({ rgbaColor: p.$themeColor.primaryFontColor, alpha: "0.6" })};
  font-size: 1.2rem;
  cursor: pointer;
  padding: 0.5rem 1rem;
`;

export const StyleShareBtn = styled.div<{
  $themeColor: DoxleThemeColor;
}>`
  font-size: 1.6rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-weight: 500;
  margin-right: 1rem;
`;

export const StyledResponseInput = styled.input<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  background-color: ${(p) =>
    editRgbaAlpha({ rgbaColor: p.$themeColor.doxleColor, alpha: "0.1" })};
  height: 3.5rem;
  border: none;
  font-family: ${(p) => p.$doxleFont.secondaryTitleFont};
  padding-left: 10px;
  font-size: 1.2rem;
  color: ${(p) => p.$themeColor.primaryFontColor};

  &:focus {
    outline: none;
  }
`;

export const StyledReplyInput = styled.input<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  background-color: rgba(0, 0, 0, 0);
  height: 3.7rem;
  width: 100%;
  border: none;
  font-family: ${(p) => p.$doxleFont.secondaryTitleFont};
  padding-left: 10px;
  font-size: 1.2rem;
  color: ${(p) => p.$themeColor.primaryFontColor};

  &:focus {
    outline: none;
  }
`;

export const StyledVirtuosoScroller = styled.div`
  width: 100%;
  height: 100% !important;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

export const StyledMailInfoContainer = styled.div<{
  $width: string;
}>`
  display: flex;
  flex-direction: column;
  width: ${(p) => p.$width};
  height: 100%;

  &.fromContainer {
    padding-right: 7rem;
  }
`;

export const StyledInfoTitle = styled.div<{
  $themeColor: DoxleThemeColor;
}>`
  color: ${(p) =>
    editRgbaAlpha({ rgbaColor: p.$themeColor.primaryFontColor, alpha: "0.7" })};
  font-size: 1rem;
  padding-left: 0.5rem;
  margin-bottom: 3px;
`;

export const StyledEmailBody = styled.div<{
  $themeColor: DoxleThemeColor;
  $currentTheme?: DoxleTheme;
}>`
  color: ${(p) =>
    editRgbaAlpha({
      rgbaColor: p.$themeColor.primaryFontColor,
      alpha: "0.7",
    })};
  font-size: 1.2rem;
  background-color: ${(p) =>
    p.$currentTheme === "light"
      ? p.$themeColor.primaryBackgroundColor
      : p.$themeColor.primaryTextFieldBackgroundColor};
  padding: 5px;
  /* margin: 5px; */
  height: 100%;
  word-wrap: break-word;
  white-space: pre-line;
`;

export const StyledTopEmailInfo = styled.div<{
  $themeColor: DoxleThemeColor;
  $currentTheme?: DoxleTheme;
}>`
  color: ${(p) =>
    editRgbaAlpha({
      rgbaColor: p.$themeColor.primaryFontColor,
      alpha: "0.7",
    })};
  font-size: 1.2rem;
  background-color: ${(p) =>
    p.$currentTheme === "light"
      ? p.$themeColor.primaryReverseFontColor
      : p.$themeColor.primaryTextFieldBackgroundColor};
  padding: 0.5rem 0.5rem;
  /* max-height: 100px; */
  word-break: break-all;
  /* overflow-y: scroll; */
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;
