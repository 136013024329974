import React, {
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Contact } from "../../Models/addressBook";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import { AnimatePresence } from "framer-motion";
import {
  StyledAddContactsDialog,
  StyledContactOption,
  StyledContactsAutocompleteTextField,
  StyledContactsMailAutocompleteTextField,
  StyledDialogBackdrop,
  StyledErrorHelperText,
} from "./StyledComponentDoxleAutocomplete";
import { useDoxleAuthStore } from "../../DoxleGeneralStore/useDoxleAuthStore";
import { shallow } from "zustand/shallow";
import { useDoxleCurrentContextStore } from "../../DoxleGeneralStore/useDoxleCurrentContext";
import ContactsAPI from "../../Services/QueryHooks/contactsAPI";
import {
  Autocomplete,
  CircularProgress,
  FormControl,
  InputAdornment,
} from "@mui/material";
import { createFilterOptions } from "@mui/material/Autocomplete";
import AutocompleteList from "./AutocompleteList";
import { ContactsFilters } from "../../Services/QueryHooks/contactsFilters";
import { useQueryClient } from "@tanstack/react-query";
import { FilterOptionsState } from "@mui/base/useAutocomplete/useAutocomplete";

//!!! EXTERNAL REPO !!!//
import AddContactDialog from "../../Contacts/Content/AddContactDialog/AddContactDialog";
import { useProjectStore } from "../../DoxleGeneralStore/useprojectStore";

interface customSxProps extends React.CSSProperties {
  "& .MuiFormControl-root"?: React.CSSProperties;
  "& .MuiInputBase-root"?: React.CSSProperties;
  "& .MuiInputBase-input"?: React.CSSProperties;
  "& .MuiInputBase-root:before"?: React.CSSProperties;
  "& .MuiInputBase-root:hover"?: React.CSSProperties;
  "& .MuiInputLabel-root"?: React.CSSProperties;
}

type Props = {
  currentValue: Contact | undefined;
  setNewContact: (newValue: Contact) => void;
  overwrittenContactList?: Contact[]; //!this is to pass the contact list instead of data from the autocomplete itself
  onRemoveValue?: () => void;
  required?: boolean;
  autocompleteWrapperStyle?: customSxProps;
  labelStyle?: React.CSSProperties;
  labelText?: string;
  optionStyle?: React.CSSProperties;
  isError?: boolean;
  focusHandler?: () => void;
  textFieldInputRef?: MutableRefObject<HTMLInputElement | null>;
  keyDownHandler?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
};

const DoxleContactAutocomplete = ({
  currentValue,
  setNewContact,
  onRemoveValue,
  labelText,
  labelStyle,
  autocompleteWrapperStyle,
  optionStyle,
  isError,
  overwrittenContactList,
  focusHandler,
  textFieldInputRef,
  keyDownHandler,
}: Props) => {
  const [searchInput, setSearchInput] = useState<string>("");
  const [searchTextQuery, setSearchTextQuery] = useState<string>("");
  const [displayAddContactDialog, setDisplayAddContactDialog] =
    useState<boolean>(false);
  const [addedContacts, setAddedContacts] = useState<Contact[]>([]);
  const [selectedValue, setSelectedValue] = useState<Contact | undefined>(
    currentValue
  );
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore((state) => ({
    doxleThemeColor: state.doxleThemeColor,
    doxleFont: state.doxleFont,
  }));
  const { selectedProjectTab } = useProjectStore(
    (state) => ({
      selectedProjectTab: state.selectedProjectTab,
    }),
    shallow
  );

  const company = useDoxleCurrentContextStore(
    (state) => state.currentCompany,
    shallow
  );
  const filterRetrieveContactListQuery: ContactsFilters = useMemo(
    () => ({
      search: searchInput,
    }),
    [searchInput]
  );

  const getContactQuery = ContactsAPI.useRetrieveContactsQuery({
    company,
    filter: filterRetrieveContactListQuery,
    enable: true,
  });

  const addNewContactPlaceholder: Contact = {
    contactId: "add-new-contact-placeholder",
    userCompany: company?.companyId ?? "",
    user: "",
    firstName: "Add New",
    lastName: "Contact",
    email: "new@contact",
    phone: "",
    contactCompany: null,
    isPrimary: false,
    sendQuotes: false,
  };

  const contactList: Contact[] = useMemo(() => {
    if (!getContactQuery.isSuccess)
      return currentValue
        ? [currentValue, ...addedContacts]
        : [...addedContacts];
    if (currentValue)
      return [
        currentValue,
        ...addedContacts,
        ...(getContactQuery.data.pages.flatMap(
          (page) => page.data.results ?? []
        ) ?? []),
      ];
    else
      return (
        getContactQuery.data.pages.flatMap(
          (page) => page.data.results ?? [...addedContacts]
        ) ?? [...addedContacts]
      );
  }, [getContactQuery.data]);

  const handleAutoCompleteChange = useCallback((newValue: Contact) => {
    if (newValue.contactId === "add-new-contact-placeholder")
      setDisplayAddContactDialog(true);
    else setSelectedValue(newValue);
  }, []);

  const provisionalInputRef = useRef<HTMLInputElement>(null);
  const inputRef = textFieldInputRef ?? provisionalInputRef;
  const queryCleint = useQueryClient();
  const onNewContactSuccess = (newContact: Contact) => {
    setSearchInput(`${newContact.firstName} ${newContact.lastName}`);
    setAddedContacts((prev) => [newContact, ...prev]);
    setSelectedValue(newContact);
    setDisplayAddContactDialog(false);
  };

  useEffect(() => {
    const timeout = setTimeout(() => setSearchInput(searchTextQuery), 400);

    return () => clearTimeout(timeout);
  }, [searchTextQuery]);

  useEffect(() => {
    if (selectedValue) setNewContact(selectedValue);
  }, [selectedValue]);

  const _filterOptions = createFilterOptions();
  const filterOptions = (
    options: Contact[],
    state: FilterOptionsState<Contact>
  ) => {
    const results = _filterOptions(
      options,
      state as FilterOptionsState<unknown>
    );
    // if (!results?.length) return [addNewContactPlaceholder]
    results.push(addNewContactPlaceholder);
    return results as Contact[];
  };

  // useEffect(() => {
  //   if (currentValue && !selectedValue) setSelectedValue(currentValue);
  // }, [selectedValue, currentValue]);

  //* animation
  const errorHelperTextVariants = {
    entering: {
      x: [-5, 0],
      opacity: [0, 1],
      transition: {
        duration: 0.3,
      },
    },
    exiting: {
      x: [0, -5],
      opacity: [1, 0],
      transition: {
        duration: 0.3,
      },
    },
  };
  return (
    <>
      <FormControl
        sx={{
          width: autocompleteWrapperStyle?.width ?? "100%",
          display: "flex",
          flexDirection: "column",
          ...autocompleteWrapperStyle,
        }}
      >
        <Autocomplete
          PaperComponent={AutocompleteList}
          onFocus={focusHandler}
          noOptionsText={"Not Found"}
          disablePortal
          loadingText="Looking For Contact..."
          options={overwrittenContactList ?? contactList}
          getOptionLabel={(option) =>
            typeof option === "string"
              ? option
              : `${option.firstName} ${option.lastName}`
          }
          value={selectedValue}
          onChange={(event, newValue, reason) => {
            if (newValue) {
              if (typeof newValue !== "string")
                handleAutoCompleteChange(newValue);
            } else {
              if (onRemoveValue) onRemoveValue();
            }
          }}
          filterOptions={filterOptions}
          placeholder={"Click to select a contact"}
          renderOption={(props, option, context) => {
            return (
              <li
                {...props}
                key={`listItem-` + context.index + `-` + option.contactId}
              >
                <StyledContactOption
                  $themeColor={doxleThemeColor}
                  $doxleFont={doxleFont}
                  style={
                    optionStyle
                      ? { ...optionStyle }
                      : {
                          fontFamily: doxleFont.primaryFont,
                          fontWeight: 500,
                          fontSize: "1.2rem",
                          lineHeight: "normal",
                          letterSpacing: "0.0075em",
                          height: 30,

                          display: "flex",
                          alignItems: "center",
                          width: "100%",
                        }
                  }
                  animate={{
                    color: context.selected
                      ? doxleThemeColor.doxleColor
                      : doxleThemeColor.primaryFontColor,
                  }}
                  whileHover={{
                    backgroundColor: doxleThemeColor.hoverColor,
                  }}
                  transition={{ duration: 0.2 }}
                >
                  {option.firstName} {option.lastName}
                </StyledContactOption>
              </li>
            );
          }}
          freeSolo
          isOptionEqualToValue={(option, value) =>
            option.contactId === value.contactId
          }
          loading={getContactQuery.isLoading && !overwrittenContactList}
          renderInput={(params) => {
            if (selectedProjectTab === "Mail") {
              return (
                <StyledContactsMailAutocompleteTextField
                  {...params}
                  $themeColor={doxleThemeColor}
                  $doxleFont={doxleFont}
                  variant="standard"
                  label={labelText}
                  onKeyDown={keyDownHandler}
                  sx={{
                    "& .MuiInputLabel-root": {
                      ...labelStyle,
                    },
                  }}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <InputAdornment position="end">
                        {getContactQuery.isLoading ? (
                          <CircularProgress
                            size={20}
                            sx={{ color: doxleThemeColor.doxleColor }}
                          />
                        ) : null}
                      </InputAdornment>
                    ),
                  }}
                  inputRef={inputRef}
                  onChange={(
                    event: React.ChangeEvent<
                      HTMLInputElement | HTMLTextAreaElement
                    >
                  ) => setSearchTextQuery(event.target.value)}
                />
              );
            } else {
              return (
                <StyledContactsAutocompleteTextField
                  {...params}
                  $themeColor={doxleThemeColor}
                  $doxleFont={doxleFont}
                  variant="standard"
                  label={labelText}
                  onKeyDown={keyDownHandler}
                  sx={{
                    "& .MuiInputLabel-root": {
                      ...labelStyle,
                    },
                  }}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <InputAdornment position="end">
                        {getContactQuery.isLoading ? (
                          <CircularProgress
                            size={20}
                            sx={{ color: doxleThemeColor.doxleColor }}
                          />
                        ) : null}
                      </InputAdornment>
                    ),
                  }}
                  inputRef={inputRef}
                  onChange={(
                    event: React.ChangeEvent<
                      HTMLInputElement | HTMLTextAreaElement
                    >
                  ) => setSearchTextQuery(event.target.value)}
                />
              );
            }
          }}
        />

        <AnimatePresence>
          {isError && (
            <StyledErrorHelperText
              $themeColor={doxleThemeColor}
              $doxleFont={doxleFont}
              variants={errorHelperTextVariants}
              initial={false}
              animate="entering"
              exit="exiting"
            >
              Please Select A Contact
            </StyledErrorHelperText>
          )}
        </AnimatePresence>
      </FormControl>
      {/*!!! EXTERNAL REPO !!!*/}
      {displayAddContactDialog && (
        <StyledDialogBackdrop $themeColor={doxleThemeColor}>
          <StyledAddContactsDialog $themeColor={doxleThemeColor}>
            <AddContactDialog onSuccessCb={onNewContactSuccess} />
          </StyledAddContactsDialog>
        </StyledDialogBackdrop>
      )}
    </>
  );
};

export default DoxleContactAutocomplete;
