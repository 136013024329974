import React, { useEffect, useState } from "react";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import {
  StyledAddCompanyErrorToolTip,
  StyledAddCompanyLoginTextField,
} from "./StyledComponentAddCompanyScreen";
import { Zoom } from "@mui/material";

type Props = {
  setCurrentValue: (value: string) => void;
  placeholderText?: string;
  hasBorderBottom?: boolean;
  heightRatio: `${number}%`;
  errorToggle: boolean;
  errorText: string;
  handleCloseToolTip: () => void;
  passwordMode?: boolean;
  keyDownFunction?: Function;
  maxLength?: number;
  initialValue?: string;
};

const AddCompanyTextField = ({
  setCurrentValue,
  placeholderText,
  hasBorderBottom,
  heightRatio,
  errorToggle,
  errorText,
  handleCloseToolTip,
  passwordMode,
  keyDownFunction,
  maxLength,
  initialValue
}: Props) => {
  // const [textInputQuery, setTextInputQuery] = useState<string>(initialValue ?? "");

  
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }),
    shallow
  );


  return (
    <StyledAddCompanyErrorToolTip
      $themeColor={doxleThemeColor}
      $doxleFont={doxleFont}
      TransitionComponent={Zoom}
      TransitionProps={{ timeout: 600 }}
      PopperProps={{
        disablePortal: true,
        sx: {
          "& .MuiTooltip-tooltip": {
            fontFamily: doxleFont.primaryTitleFont,
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: " 14px",
            lineHeight: " 16px",
            color: `${doxleThemeColor.errorColor} !important`,
            textTransform: " none",
            backgroundColor: `${doxleThemeColor.primaryContainerColor} !important`,
            boxShadow: `0px 0px 4px 2px ${doxleThemeColor.primaryBoxShadowColor} !important`,
            borderRadius: "4px",

            padding: "4px 14px",
          },
        },
      }}
      onClose={handleCloseToolTip}
      open={errorToggle}
      title={errorText}
      placement="right"
    >
      <StyledAddCompanyLoginTextField
        $heightRatio={heightRatio}
        $themeColor={doxleThemeColor}
        $doxleFont={doxleFont}
        placeholder={placeholderText || ""}
        $hasBorderBottom={hasBorderBottom || false}
        required
        variant="outlined"
        value={initialValue}
        onChange={(
          event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
        ) => {
          const newValue = event.target.value;
          if (!maxLength || newValue.length <= maxLength) setCurrentValue(newValue);
        }}
        type={passwordMode ? "password" : undefined}
        onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => {
          if (event.nativeEvent.key === "Enter") {
            if (keyDownFunction) keyDownFunction();
          }

          if (event.nativeEvent.key === "Escape") event.currentTarget.blur();
        }}
      />
    </StyledAddCompanyErrorToolTip>
  );
};

export default AddCompanyTextField;
