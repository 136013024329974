import { useShallow } from "zustand/react/shallow";
import { FileLevel, useFileStore } from "../Store/useFileStore";
import { useDoxleCurrentContextStore } from "../../DoxleGeneralStore/useDoxleCurrentContext";
import { useEditDocketSideScreenStore } from "../../CoreContent/EditDocketSideScreen/EditDocketSideScreenStore/useEditDocketSideScreenStore";
import { useEffect } from "react";
import useEffectAfterMount from "../../CoreContent/UtilityHooks/useEffectAfterMount";

const useFiles = (level?: FileLevel) => {
  const {
    setFileLevel,
    setWholeFilterRootFolderQuery,
    setWholeFilterRootFileQuery,
    clearFileStore,
  } = useFileStore(
    useShallow((state) => ({
      setFileLevel: state.setCurrentLevel,
      setWholeFilterRootFolderQuery: state.setWholeFilterRootFolderQuery,
      setWholeFilterRootFileQuery: state.setWholeFilterRootFileQuery,
      clearFileStore: state.clearFileStore,
    }))
  );
  const { currentProject, currentSearchText } = useDoxleCurrentContextStore(
    useShallow((state) => ({
      currentProject: state.currentProject,
      currentSearchText: state.currentSearchText,
    }))
  );
  const edittedDocket = useEditDocketSideScreenStore(
    useShallow((state) => state.edittedDocket)
  );

  //*TO USEEFECT SET FILTER FOR GETTING CORRECT FILE AND FOLDER BASED ON LEVEL
  useEffect(() => {
    if (level === "Docket" && edittedDocket) {
      setWholeFilterRootFolderQuery({
        docketId: edittedDocket.docketPk,
      });
      setWholeFilterRootFileQuery({
        docketId: edittedDocket.docketPk,
      });
    } else if (level === "Project" && currentProject) {
      setWholeFilterRootFolderQuery({
        projectId: currentProject.projectId,
        search: currentSearchText,
      });
      setWholeFilterRootFileQuery({
        projectId: currentProject.projectId,
        search: currentSearchText,
      });
    } else {
      setWholeFilterRootFolderQuery({ search: currentSearchText });
      setWholeFilterRootFileQuery({ search: currentSearchText });
    }

    setFileLevel(level ?? "Company");
  }, [level, edittedDocket, currentProject, currentSearchText]);

  useEffectAfterMount(() => {
    return () => {
      clearFileStore();
    };
  }, []);

  return {};
};

export default useFiles;
