import React from "react";
import { Docket } from "../../Models/dockets";
import { useDoxleCurrentContextStore } from "../../DoxleGeneralStore/useDoxleCurrentContext";
import { shallow } from "zustand/shallow";
import {
  IFilterQuoteRequestQuery,
  getQuoteRequestQKey,
} from "../QueryAPI/quotesQuery";
import { useQueryClient } from "@tanstack/react-query";
import { QuoteRequest } from "../models/quote";
import { produce } from "immer";
import { IApiPaginatedData } from "../../Models/axiosReturn";

type Props = {
  filter: IFilterQuoteRequestQuery;
  appendPos?: "start" | "end";
  overwrite?: boolean;
};
interface SetQuoteRequetQueryData {
  handleAddQuoteRequest: (newQuoteRequest: QuoteRequest) => void;
  handleRemoveMultiQuoteRequest: (deletedQuoteRequestIds: string[]) => void;
  handleUpdateQuoteRequest: (updatedQuoteRequest: QuoteRequest) => void;
  handleUpdatePartialQuoteRequest: (
    updateID: string,
    payload: Partial<QuoteRequest>
  ) => void;
  handleMultiAddQuoteRequest: (newQuoteRequests: QuoteRequest[]) => void;
}
const useSetQuoteRequestQueryData = ({
  filter,
  appendPos = "end",
  overwrite = true,
}: Props): SetQuoteRequetQueryData => {
  const { currentCompany } = useDoxleCurrentContextStore(
    (state) => ({
      currentCompany: state.currentCompany,
    }),
    shallow
  );
  const qKey = getQuoteRequestQKey(filter, currentCompany);
  const queryClient = useQueryClient();
  const handleRemoveMultiQuoteRequest = (deletedQuoteRequestIds: string[]) => {
    const queryData = queryClient.getQueryData(qKey);
    if (queryData && overwrite) {
      queryClient.setQueryData(qKey, (old: any) => {
        let pageIndexContainItems: Array<{
          pageIndex: number;
          lisOfIds: string[];
        }> = [];
        old.pages.forEach((page: any, index: number) => {
          const requestData = page.data.results as QuoteRequest[];

          let listOfMatchedIds: string[] = [];
          deletedQuoteRequestIds.forEach((deletedId) => {
            if (requestData.find((request) => request.requestId === deletedId))
              listOfMatchedIds.push(deletedId);
          });

          if (listOfMatchedIds.length > 0)
            pageIndexContainItems.push({
              pageIndex: index,
              lisOfIds: listOfMatchedIds,
            });
        });
        // (old.pages as any[]).forEach((page) => {
        //   if (page.data.results.find((requestItem:QuoteRequest)=>requestItem.requestId===))
        // });

        return produce(old, (draftOld: any) => {
          if (pageIndexContainItems.length > 0) {
            draftOld.pages = produce(draftOld.pages, (draftPages: any) => {
              pageIndexContainItems.forEach((pageContainItem) => {
                const { pageIndex, lisOfIds } = pageContainItem;

                draftPages[pageIndex].data.results = produce(
                  draftPages[pageIndex].data.results,
                  (draftResults: QuoteRequest[]) => {
                    draftResults = old.pages[pageIndex].data.results.filter(
                      (requestItem: QuoteRequest) =>
                        !lisOfIds.includes(requestItem.requestId)
                    );

                    return draftResults;
                  }
                );
              });

              return draftPages;
            });
          } else queryClient.invalidateQueries(qKey);
          return draftOld;
        });
      });
    } else queryClient.invalidateQueries(qKey);
  };

  const handleUpdateQuoteRequest = (updatedQuoteRequest: QuoteRequest) => {
    const queryData = queryClient.getQueryData(qKey);
    if (overwrite && queryData) {
      queryClient.setQueryData(qKey, (old: any) => {
        let pageIndexContainItem: number = old.pages.findIndex((page: any) =>
          Boolean(
            page.data.results.find(
              (quoteRq: QuoteRequest) =>
                quoteRq.requestId === updatedQuoteRequest.requestId
            ) !== undefined
          )
        );
        if (pageIndexContainItem !== -1) {
          const itemIndex = (
            old.pages[pageIndexContainItem]
              .data as IApiPaginatedData<QuoteRequest>
          ).results.findIndex(
            (quoteRq: QuoteRequest) =>
              quoteRq.requestId === updatedQuoteRequest.requestId
          );
          return produce(old, (draft: any) => {
            if (itemIndex !== -1) {
              (
                draft.pages[pageIndexContainItem]
                  .data as IApiPaginatedData<QuoteRequest>
              ).results[itemIndex] = updatedQuoteRequest;
              return draft;
            } else queryClient.invalidateQueries(qKey);
          });
        } else queryClient.invalidateQueries(qKey);
      });
    } else queryClient.invalidateQueries(qKey);
  };

  const handleUpdatePartialQuoteRequest = (
    updateID: string,
    payload: Partial<QuoteRequest>
  ) => {
    const queryData = queryClient.getQueryData(qKey);
    if (overwrite && queryData) {
      queryClient.setQueryData(qKey, (old: any) => {
        let pageIndexContainItem: number = old.pages.findIndex((page: any) =>
          Boolean(
            page.data.results.find(
              (quoteRq: QuoteRequest) => quoteRq.requestId === updateID
            ) !== undefined
          )
        );
        if (pageIndexContainItem !== -1) {
          const itemIndex = (
            old.pages[pageIndexContainItem]
              .data as IApiPaginatedData<QuoteRequest>
          ).results.findIndex(
            (quoteRq: QuoteRequest) => quoteRq.requestId === updateID
          );
          return produce(old, (draft: any) => {
            if (itemIndex !== -1) {
              const item = (
                draft.pages[pageIndexContainItem]
                  .data as IApiPaginatedData<QuoteRequest>
              ).results[itemIndex];
              Object.assign(item, payload);
              return draft;
            } else queryClient.invalidateQueries(qKey);
          });
        } else queryClient.invalidateQueries(qKey);
      });
    } else queryClient.invalidateQueries(qKey);
  };
  const handleAddQuoteRequest = (newQuoteRequest: QuoteRequest) => {
    const quoteRequestData = queryClient.getQueryData(qKey);
    if (quoteRequestData && overwrite) {
      queryClient.setQueryData(qKey, (old: any) => {
        if (old) {
          console.log("OLD RQ DATA:", old);
          const lengthOfPages = old.pages.length;
          return produce(old, (draftOld: any) => {
            draftOld.pages = produce(draftOld.pages, (draftPages: any) => {
              if (appendPos === "start") {
                (
                  draftPages[0].data as IApiPaginatedData<QuoteRequest>
                ).results.unshift(newQuoteRequest);
              } else {
                (
                  draftPages[lengthOfPages - 1]
                    .data as IApiPaginatedData<QuoteRequest>
                ).results.push(newQuoteRequest);
              }

              return draftPages;
            });

            return draftOld;
          });
        } else return queryClient.invalidateQueries(qKey);
      });
    } else queryClient.invalidateQueries(qKey);
  };

  const handleMultiAddQuoteRequest = (newQuoteRequests: QuoteRequest[]) => {
    const quoteRequestData = queryClient.getQueryData(qKey);
    if (quoteRequestData && overwrite) {
      queryClient.setQueryData(qKey, (old: any) => {
        if (old) {
          console.log("OLD RQ DATA:", old);
          const lengthOfPages = old.pages.length;
          return produce(old, (draftOld: any) => {
            draftOld.pages = produce(draftOld.pages, (draftPages: any) => {
              if (appendPos === "start") {
                (
                  draftPages[0].data as IApiPaginatedData<QuoteRequest>
                ).results.unshift(...newQuoteRequests);
              } else {
                (
                  draftPages[lengthOfPages - 1]
                    .data as IApiPaginatedData<QuoteRequest>
                ).results.push(...newQuoteRequests);
              }

              return draftPages;
            });

            return draftOld;
          });
        } else return queryClient.invalidateQueries(qKey);
      });
    } else queryClient.invalidateQueries(qKey);
  };
  return {
    handleAddQuoteRequest,
    handleRemoveMultiQuoteRequest,
    handleUpdateQuoteRequest,
    handleUpdatePartialQuoteRequest,
    handleMultiAddQuoteRequest,
  };
};

export default useSetQuoteRequestQueryData;
