import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import React, { createContext, useContext, useMemo } from "react";
import {
  StyledQuoteReviewFieldWrapper,
  StyledQuoteStatusContainer,
  StyledRVQuoteAttachmentWrapper,
  StyledRVQuoteEmailBodyTextField,
  StyledReviewQuote,
  StyledReviewQuoteButton,
  StyledReviewQuoteDatePickerSection,
  StyledReviewQuoteEmailBodySection,
  StyledSelectedScopeGroupContainer,
  StyledViewQSButton,
} from "./StyledComponentReviewQuote";
import { QuoteRequest, QuoteStorage } from "../../models/quote";
import EditSupplierQuoteRequest from "./EditSupplierQuoteRequest";
import useReviewQuoteRequest from "./Hooks/useReviewQuoteRequest";
import { StyledQuoteDetailLabelText } from "../QuoteRequestStyledComponents";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import ReviewQuoteDatePicker from "./ReviewQuoteDatePicker";
import dayjs from "dayjs";
import QuoteAttachmentItem from "./QuoteAttachmentItem";
import AddQuoteAttachment from "./AddQuoteAttachment";
import AddedQuoteAttachmentItem from "./AddedQuoteAttachmentItem";
import DoxleFilterTag from "../../../DoxleDesignPattern/DoxleFilterTag/DoxleFilterTag";
import SelectScopeGroup from "./SelectScopeGroup";
import DeleteQuoteAttachment from "./DeleteQuoteAttachment";
import QuoteStatus from "./QuoteStatus";
import CircularProgress from "@mui/material/CircularProgress";
import ViewQuoteResponse from "./ViewQuoteResponse";

type Props = { reviewedQuoteRequest: QuoteRequest };

interface ReviewQuoteRequestContextValue {
  edittedQuoteRequest: QuoteRequest;
  setEdittedQuoteRequest: React.Dispatch<React.SetStateAction<QuoteRequest>>;
  addedAttachments: File[];
  setAddedAttachments: React.Dispatch<React.SetStateAction<File[]>>;
  deletedQuoteAttachment: QuoteStorage | undefined;
  setDeletedQuoteAttachment: React.Dispatch<
    React.SetStateAction<QuoteStorage | undefined>
  >;
}

const ReviewQuoteRequestContext =
  createContext<ReviewQuoteRequestContextValue | null>(null);
const ReviewQuoteRequest = ({ reviewedQuoteRequest }: Props) => {
  const {
    edittedQuoteRequest,
    setEdittedQuoteRequest,
    handleUpdateDueDate,
    handleEmailBodyChange,
    addedAttachments,
    setAddedAttachments,
    handleRemoveScopeGroup,
    deletedQuoteAttachment,
    setDeletedQuoteAttachment,
    isQuoteChanged,
    handleClickUpdateBtn,
    handleClickCancelButton,
    isUpdatingQRInProgress,
    quoteResponseDetail,
    showQSDialog,
    setshowQSDialog,
  } = useReviewQuoteRequest({ reviewedQuoteRequest });
  const { doxleThemeColor, doxleFont, currentTheme } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
      currentTheme: state.currentTheme,
    })
  );
  const contextValue: ReviewQuoteRequestContextValue = useMemo(
    () => ({
      edittedQuoteRequest,
      setEdittedQuoteRequest,
      addedAttachments,
      setAddedAttachments,
      deletedQuoteAttachment,
      setDeletedQuoteAttachment,
    }),
    [edittedQuoteRequest, addedAttachments, deletedQuoteAttachment]
  );
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ReviewQuoteRequestContext.Provider value={contextValue}>
        <StyledReviewQuote
          initial={{
            y: 10,
            opacity: 0,
          }}
          animate={{
            y: 0,
            opacity: 1,
          }}
          $themeColor={doxleThemeColor}
          $doxleFont={doxleFont}
        >
          {quoteResponseDetail && (
            <ViewQuoteResponse
              open={showQSDialog}
              quoteResponse={quoteResponseDetail}
              onClose={() => setshowQSDialog(false)}
            />
          )}
          <div className="rv-quote-title-text">
            Edit Quote Request
            {isUpdatingQRInProgress && (
              <CircularProgress
                size="1.6rem"
                style={{
                  marginLeft: 4,
                  color: doxleThemeColor.primaryFontColor,
                }}
              />
            )}
          </div>
          <StyledQuoteStatusContainer>
            <StyledQuoteDetailLabelText
              $themeColor={doxleThemeColor}
              $doxleFont={doxleFont}
              $opacity={0.7}
            >
              Quote Status
            </StyledQuoteDetailLabelText>
            <div className="quote-status-wrapper">
              <QuoteStatus status={edittedQuoteRequest.status} />

              {quoteResponseDetail && (
                <StyledViewQSButton
                  $themeColor={doxleThemeColor}
                  $doxleFont={doxleFont}
                  layout
                  initial={{ scaleX: 0 }}
                  animate={{ scaleX: 1 }}
                  onClick={() => setshowQSDialog(true)}
                >
                  View QS
                </StyledViewQSButton>
              )}
            </div>
          </StyledQuoteStatusContainer>
          <EditSupplierQuoteRequest />

          <StyledReviewQuoteDatePickerSection layout>
            <StyledQuoteReviewFieldWrapper
              style={{ marginRight: "10%", width: "30%" }}
            >
              <StyledQuoteDetailLabelText
                $themeColor={doxleThemeColor}
                $doxleFont={doxleFont}
                $opacity={0.7}
              >
                INITIATED DATE
              </StyledQuoteDetailLabelText>
              <ReviewQuoteDatePicker
                disabled
                value={dayjs(new Date(edittedQuoteRequest.createdOn))}
              />
            </StyledQuoteReviewFieldWrapper>
            <StyledQuoteReviewFieldWrapper style={{ width: "unset" }}>
              <StyledQuoteDetailLabelText
                $themeColor={doxleThemeColor}
                $doxleFont={doxleFont}
                $opacity={0.7}
              >
                DUE DATE
              </StyledQuoteDetailLabelText>
              <ReviewQuoteDatePicker
                value={
                  edittedQuoteRequest.dueDate
                    ? dayjs(new Date(edittedQuoteRequest.dueDate))
                    : dayjs(new Date())
                }
                onChange={handleUpdateDueDate}
              />
            </StyledQuoteReviewFieldWrapper>
          </StyledReviewQuoteDatePickerSection>
          <StyledReviewQuoteEmailBodySection layout>
            <StyledQuoteDetailLabelText
              $themeColor={doxleThemeColor}
              $doxleFont={doxleFont}
              $opacity={0.7}
            >
              Email body
            </StyledQuoteDetailLabelText>
            <StyledRVQuoteEmailBodyTextField
              $themeColor={doxleThemeColor}
              $doxleFont={doxleFont}
              multiline
              fullWidth
              value={edittedQuoteRequest.emailBody}
              onFocus={(e) => e.currentTarget.select()}
              placeholder="Could you please provide a quote at your earliest convenience. Please find the attached link\n` +
                   `below and let me when I can expect the quote to be submitted? "
              onChange={(e) => handleEmailBodyChange(e.target.value)}
              // defaultValue={
              //   quoteRequest?.emailBody ??
              //   `Could you please provide a quote at your earliest convenience. Please find the attached link\n` +
              //     `below and let me when I can expect the quote to be submitted? `
              // }
            />
          </StyledReviewQuoteEmailBodySection>

          <StyledQuoteReviewFieldWrapper>
            <StyledQuoteDetailLabelText
              $themeColor={doxleThemeColor}
              $doxleFont={doxleFont}
              $opacity={0.7}
            >
              Attachments
            </StyledQuoteDetailLabelText>

            <StyledRVQuoteAttachmentWrapper
              style={{
                borderBottom: `1px solid ${doxleThemeColor.primaryDividerColor}`,
              }}
            >
              {edittedQuoteRequest.attachments.map((attachment) => (
                <QuoteAttachmentItem
                  attachment={attachment}
                  key={attachment.attachmentId}
                />
              ))}
            </StyledRVQuoteAttachmentWrapper>

            {addedAttachments.length > 0 && (
              <StyledRVQuoteAttachmentWrapper>
                {addedAttachments.map((attachment, index) => (
                  <AddedQuoteAttachmentItem
                    attachment={attachment}
                    index={index}
                    key={`${attachment.name}`}
                  />
                ))}
              </StyledRVQuoteAttachmentWrapper>
            )}
            <AddQuoteAttachment />
          </StyledQuoteReviewFieldWrapper>

          <StyledQuoteReviewFieldWrapper>
            <StyledSelectedScopeGroupContainer>
              <StyledQuoteDetailLabelText
                $themeColor={doxleThemeColor}
                $doxleFont={doxleFont}
                $opacity={0.7}
              >
                Task
              </StyledQuoteDetailLabelText>
              <div className="scope-group-item-wrapper">
                {edittedQuoteRequest.scopeGroupsJson &&
                  edittedQuoteRequest.scopeGroupsJson.map((scopeGroup) => (
                    <DoxleFilterTag
                      itemData={scopeGroup}
                      itemDisplayText={scopeGroup.scopeGroupTitle}
                      removeItemFunction={handleRemoveScopeGroup}
                      key={scopeGroup.scopeGroupId}
                    />
                  ))}
              </div>
            </StyledSelectedScopeGroupContainer>

            <SelectScopeGroup />
          </StyledQuoteReviewFieldWrapper>

          <DeleteQuoteAttachment />

          <div className="button-wrapper">
            <StyledReviewQuoteButton
              $doxleFont={doxleFont}
              $themeColor={doxleThemeColor}
              $bgColor={doxleThemeColor.primaryContainerColor}
              $textColor={doxleThemeColor.primaryFontColor}
              onClick={handleClickCancelButton}
            >
              Cancel
            </StyledReviewQuoteButton>
            <StyledReviewQuoteButton
              $doxleFont={doxleFont}
              $themeColor={doxleThemeColor}
              $bgColor={doxleThemeColor.primaryFontColor}
              $textColor={doxleThemeColor.primaryContainerColor}
              disabled={Boolean(!isQuoteChanged)}
              onClick={handleClickUpdateBtn}
            >
              Update
            </StyledReviewQuoteButton>
          </div>
        </StyledReviewQuote>
      </ReviewQuoteRequestContext.Provider>
    </LocalizationProvider>
  );
};

export const useReviewQuoteRequestContext = () =>
  useContext(ReviewQuoteRequestContext) as ReviewQuoteRequestContextValue;
export default ReviewQuoteRequest;
