import { Popover } from "@mui/material";
import React, { useEffect } from "react";
import { useDoxleQuoteStore } from "../../store/useDoxleQuoteStore";
import { shallow } from "zustand/shallow";
import { StyledAddQuotesMenuPopper } from "./StyledComponentAddQuotesMenuPopper";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { MdOutlineRequestQuote } from "react-icons/md";
import { TbReportMoney } from "react-icons/tb";
import { QuoteTableView } from "../QuotesPage";
type Props = {};

const AddQuotesMenuPopper = (props: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore((state) => ({
    doxleThemeColor: state.doxleThemeColor,
    doxleFont: state.doxleFont,
  }));

  const {
    anchorAddQuotesMenuPopover,
    clearAnchorAddQuotesMenuPopover,
    setView,
  } = useDoxleQuoteStore(
    (state) => ({
      anchorAddQuotesMenuPopover: state.anchorAddQuotesMenuPopover,
      clearAnchorAddQuotesMenuPopover: state.clearAnchorAddQuotesMenuPopover,
      setView: state.setView,
    }),
    shallow
  );
  const isOpenPopper = Boolean(anchorAddQuotesMenuPopover);
  const id = isOpenPopper ? "status-list-popper" : undefined;

  const handleClickPopperItems = (view: QuoteTableView) => {
    setView(view);
    clearAnchorAddQuotesMenuPopover();
  };
  return (
    <Popover
      id={id}
      open={Boolean(isOpenPopper)}
      anchorEl={anchorAddQuotesMenuPopover}
      disableScrollLock
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      onClose={clearAnchorAddQuotesMenuPopover}
      sx={{
        "& .MuiPopover-paper": {
          backgroundColor: "transparent",
          overflow: "visible",
          boxShadow: "none",
        },
      }}
      elevation={4}
    >
      <StyledAddQuotesMenuPopper
        $themeColor={doxleThemeColor}
        $doxleFont={doxleFont}
      >
        <div
          className="quote-menu-item"
          onClick={() => handleClickPopperItems("AddQuoteRequest")}
        >
          <MdOutlineRequestQuote
            style={{
              color: doxleThemeColor.primaryFontColor,
              marginRight: "0.4rem",
              fontSize: "2rem",
            }}
          />
          Add Quote Request
        </div>

        <div
          className="quote-menu-item"
          onClick={() => handleClickPopperItems("AddQuoteResponse")}
        >
          <TbReportMoney
            style={{
              color: doxleThemeColor.primaryFontColor,
              marginRight: "0.4rem",
              fontSize: "2rem",
            }}
          />
          Add Quote Response
        </div>
      </StyledAddQuotesMenuPopper>
    </Popover>
  );
};

export default AddQuotesMenuPopper;
