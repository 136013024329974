import { useEffect, useMemo } from "react";
import { TMonthDate } from "../../../Models/dateFormat";
import { IBooking } from "../../Models/booking";
import useBookingCalendarStore from "../../store/useBookingCalendarStore";
import { useShallow } from "zustand/react/shallow";
import {
  BookingQueryAPI,
  baseBookingListWithoutPaginationQKey,
} from "../../QueryAPI/bookingQueryAPI";
import { useDoxleCurrentContextStore } from "../../../DoxleGeneralStore/useDoxleCurrentContext";
import { useQueryClient } from "@tanstack/react-query";

type Props = {
  currentMonth?: TMonthDate;
};

interface IGetProjectBookingList {
  bookingCalendarList: IBooking[];
  isFetchingBookingCalendarList: boolean;
  isSuccessFetchingBookingCalendarList: boolean;
  isErrorFetchingBookingCalendarList: boolean;
  isRefetchingBookingCalendarList: boolean;

  refetchBookingCalendarList: () => void;
}
const useGetBookingCalendarList = ({
  currentMonth,
}: Props): IGetProjectBookingList => {
  const { filterBookingProjectQuery } = useBookingCalendarStore(
    useShallow((state) => ({
      filterBookingProjectQuery: state.filterBookingProjectQuery,
    }))
  );
  const { company, currentProject } = useDoxleCurrentContextStore(
    useShallow((state) => ({
      company: state.currentCompany,
      currentProject: state.currentProject,
    }))
  );
  const getBookingQuery =
    BookingQueryAPI.useRetrieveBookingListWithoutPagination({
      company,
      filter: currentMonth
        ? { ...filterBookingProjectQuery, currentMonthDate: currentMonth }
        : filterBookingProjectQuery,
      enableQuery: Boolean(
        filterBookingProjectQuery.project &&
          (filterBookingProjectQuery.currentMonthDate ||
            (filterBookingProjectQuery.startDate &&
              filterBookingProjectQuery.endDate) ||
            filterBookingProjectQuery.due)
      ),
    });

  const bookingCalendarList = useMemo(
    () => (getBookingQuery.isSuccess ? getBookingQuery.data.data : []),
    [getBookingQuery.data]
  );

  const queryClient = useQueryClient();
  const refetchBookingCalendarList = () => {
    queryClient.refetchQueries({
      predicate: (query) =>
        baseBookingListWithoutPaginationQKey.every((key) =>
          query.queryKey.includes(key)
        ) && query.queryKey.includes(company?.companyId),
    });
  };

  return {
    bookingCalendarList,
    isFetchingBookingCalendarList: getBookingQuery.isLoading,
    isSuccessFetchingBookingCalendarList: getBookingQuery.isSuccess,
    isErrorFetchingBookingCalendarList: getBookingQuery.isError,
    isRefetchingBookingCalendarList: getBookingQuery.isRefetching,

    refetchBookingCalendarList,
  };
};

export default useGetBookingCalendarList;
