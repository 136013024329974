import React, { useEffect, useState } from "react";
import { useProjectStore } from "../../DoxleGeneralStore/useprojectStore";
import { useEditDocketSideScreenStore } from "../../CoreContent/EditDocketSideScreen/EditDocketSideScreenStore/useEditDocketSideScreenStore";
import { usePricebookStore } from "../Store/usePricebookStore";
import { useDoxleCurrentContextStore } from "../../DoxleGeneralStore/useDoxleCurrentContext";
import { useIsMutating } from "@tanstack/react-query";
import { useShallow } from "zustand/react/shallow";

type Props = { context?: "Project" | "DocketFullScreen" | "DocketHalfScreen" };
interface IPriceBook {
  displayDropDown: DOMRect | null;
  setDisplayDropDown: React.Dispatch<React.SetStateAction<DOMRect | null>>;
  isAddingItem: boolean;
}
const usePricebook = ({ context }: Props): IPriceBook => {
  const [displayDropDown, setDisplayDropDown] = useState<DOMRect | null>(null);

  const { company, currentSearchText } = useDoxleCurrentContextStore(
    useShallow((state) => ({
      company: state.currentCompany,
      currentSearchText: state.currentSearchText,
    }))
  );

  const { setDocketAddButton, editDocket } = useEditDocketSideScreenStore(
    useShallow((state) => ({
      setDocketAddButton: state.setHandleAddButtonFn,
      editDocket: state.edittedDocket,
    }))
  );
  const {
    setPricebookRateQueryFilter,
    setPricebookItemQueryFilter,
    clearShrinkedPricebookSections,
  } = usePricebookStore(
    useShallow((state) => ({
      setPricebookRateQueryFilter: state.setPricebookRateQueryFilter,
      setPricebookItemQueryFilter: state.setPricebookItemQueryFilter,
      clearShrinkedPricebookSections: state.clearShrinkedPricebookSections,
    }))
  );

  const handleShowDropDown = (
    e: React.MouseEvent<HTMLButtonElement | HTMLDivElement>
  ) => {
    const bounding = e.currentTarget.getBoundingClientRect();
    setDisplayDropDown(bounding);
  };
  const isAddingItem = useIsMutating({ mutationKey: ["add-pricebook"] }) > 0;
  useEffect(() => {
    if (context === "DocketFullScreen" || context === "DocketHalfScreen")
      setDocketAddButton([
        {
          btnFunction: handleShowDropDown,
          btnText: "New",
          showBtnIcon: false,
          isLoading: isAddingItem,
        },
      ]);
  }, [isAddingItem, context]);

  useEffect(() => {
    setPricebookRateQueryFilter({
      core_docket:
        (context === "DocketFullScreen" || context === "DocketHalfScreen") &&
        editDocket?.coreDocket
          ? editDocket?.coreDocket
          : undefined,
    });
  }, [context, editDocket?.coreDocket]);

  useEffect(() => {
    setPricebookItemQueryFilter({
      page: 1,
      search: currentSearchText,
    });
    setPricebookRateQueryFilter({
      search: currentSearchText,
      page: 1,
    });
    clearShrinkedPricebookSections();
  }, [currentSearchText]);

  return { displayDropDown, setDisplayDropDown, isAddingItem };
};

export default usePricebook;
