import React from "react";
import { useDoxleCurrentContextStore } from "../../../DoxleGeneralStore/useDoxleCurrentContext";
import { shallow } from "zustand/shallow";
import { useDoxleAuthStore } from "../../../DoxleGeneralStore/useDoxleAuthStore";
import useDoxleNotificationStore from "../../../DoxleGeneralStore/useDoxleNotificationStore";
import OrdersQueryAPI from "../../QueryHooks/OrdersQueryAPI";
import { useOrdersStore } from "../../OrdersStore/useOrdersStore";
import DoxleDialogHelper from "../../../DoxleDesignPattern/DoxleDialogHelper/DoxleDialogHelper";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import useSetOrderQueryData from "../../Hooks/useSetOrderQueryData";

type Props = {};

const DeleteOrderConfirm = (props: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }),
    shallow
  );
  const company = useDoxleCurrentContextStore(
    (state) => state.currentCompany,
    shallow
  );
  
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );

  const { queryFilter, deletedOrder, setDeletedOrder } = useOrdersStore(
    (state) => ({
      queryFilter: state.queryFilter,
      deletedOrder: state.deletedOrder,
      setDeletedOrder: state.setDeletedOrder,
    }),
    shallow
  );
  const { handleRemoveOrder } = useSetOrderQueryData({ filter: queryFilter });
  const { destroyOrder } = OrdersQueryAPI.useMutateOrderQuery({
  
    company,
    showNotification,

    filter: queryFilter,
  });
  const handleDeleteOrder = () => {
    setDeletedOrder(undefined);
    if (deletedOrder) {
      destroyOrder.mutate({orderId: deletedOrder.orderId, docketPk: deletedOrder.docket ?? undefined, projectId: deletedOrder.project ?? undefined});
      handleRemoveOrder(deletedOrder.orderId);
    }
  };
  return (
    <DoxleDialogHelper
      open={Boolean(deletedOrder)}
      onClose={() => setDeletedOrder(undefined)}
      title={`Confirm delete order!`}
      description={`All data belong to order ***${deletedOrder?.orderNumber}*** will be deleted permanently,are you sure to proceed?`}
      dialogButtons={[
        {
          buttonText: "Cancel",
          buttonFunction: () => setDeletedOrder(undefined),
          buttonColor: doxleThemeColor.primaryContainerColor,
          btnStyleSx: {
            border: `1px solid ${doxleThemeColor.primaryDividerColor}`,
          },
          buttonTextStyle: {
            color: doxleThemeColor.primaryFontColor,
          },
        },
        {
          buttonText: "Confirm",
          buttonFunction: handleDeleteOrder,
          btnStyleSx: {
            border: `1px solid ${doxleThemeColor.primaryDividerColor}`,
          },
        },
      ]}
    />
  );
};

export default DeleteOrderConfirm;
