import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import ProjectSelectDropDown from "./ProjectSelectDropDown";
import {
  StyledProjectContainer,
  StyledProjectRootContainer,
  StyledProjectTabDisplayer,
} from "./StyledProjectComponents";
import ProjectMenu from "./ProjectMenu";
import { useProjectStore } from "../../DoxleGeneralStore/useprojectStore";
import { Helmet } from "react-helmet";
import BudgetsProjectTab from "../../Budgets/Table/BudgetsProjectTab";
import DrawingsTabDisplayer from "../../Drawings/Content/DrawingsTabDisplayer";
import Mail from "../../Mail/Content/Mail";
import Files from "../../Files/Content/Files";
import QAProject from "../../QA/QAProject/QAProject";
import { useDoxleCurrentContextStore } from "../../DoxleGeneralStore/useDoxleCurrentContext";
import DoxleEmptyPlaceHolder from "../../DoxleDesignPattern/DoxleEmptyPlaceHolder/DoxleEmptyPlaceHolder";
import { NonSelectedProjectBanner } from "./ProjectIcon";
import { useNavigate, useParams } from "react-router-dom";
import useGetProjectList from "./Hooks/useGetProjectList";
import { useEffect, useState } from "react";
import Notes from "../../Notes/Components/Notes";
import Loading from "../../Utilities/Lottie/Loading";
import AccountMapping from "../../Xero/AccountMapping";
import DoxleErrorScreen from "../../DoxleDesignPattern/DoxleErrorScreen/DoxleErrorScreen";
import { motion } from "framer-motion";
import { useShallow } from "zustand/react/shallow";
import BookingCalendar from "../../Bookings/BookingCalendar/Components/BookingCalendar";
import QR from "../../QR/Components/QR";

const Project = () => {
  const [isErrorFetchingProject, setIsErrorFetchinProject] =
    useState<boolean>(false);
  const urlParams = useParams();
  const selectedProjectTab = useProjectStore(
    useShallow((state) => state.selectedProjectTab)
  );
  const { currentProject, setCurrentProject } = useDoxleCurrentContextStore(
    useShallow((state) => ({
      currentProject: state.currentProject,

      setCurrentProject: state.setCurrentProject,
    }))
  );
  const doxleThemeColor = useDoxleThemeStore(
    useShallow((state) => state.doxleThemeColor)
  );
  const navigation = useNavigate();

  const { projectList, isRefetchingProject, isFetchingProject } =
    useGetProjectList({});

  useEffect(() => {
    localStorage.setItem("lastUsedApp", "Projects");
  }, []);

  useEffect(() => {
    const urlProject = projectList.filter(
      (proj) => proj.projectId === urlParams?.projectId
    )?.[0];
    if (urlProject) setCurrentProject(urlProject);
  }, [urlParams?.projectId, isFetchingProject]);

  if (
    urlParams?.projectId &&
    currentProject !== currentProject?.projectId &&
    isErrorFetchingProject
  )
    return <DoxleErrorScreen />;
  else if (
    urlParams?.projectId &&
    currentProject !== currentProject?.projectId
  ) {
    setTimeout(() => {
      if (urlParams?.projectId && currentProject !== currentProject?.projectId)
        setIsErrorFetchinProject(true);
      else setIsErrorFetchinProject(false);
    }, 2500);
    return <Loading />;
  }
  return (
    <StyledProjectContainer>
      <Helmet>
        <title>Doxle - Projects</title>
      </Helmet>
      {/* <ProjectSideMenu /> */}

      <motion.div
        className="content-wrapper"
        layout
        transition={{
          duration: 0.4,
          damping: 16,
        }}
      >
        <StyledProjectRootContainer
          layout
          transition={{
            duration: 0.2,
            damping: 16,
          }}
        >
          <ProjectSelectDropDown />

          {!isRefetchingProject && !isFetchingProject && (
            <>
              {currentProject && (
                <>
                  <ProjectMenu />
                  <StyledProjectTabDisplayer
                    layout
                    transition={{
                      duration: 0.2,
                      damping: 16,
                    }}
                  >
                    {/* !!! EXTERNAL REPOS !!! */}
                    {selectedProjectTab === "Drawings" && (
                      <DrawingsTabDisplayer />
                    )}

                    {selectedProjectTab === "Files" && (
                      <Files level={"Project"} />
                    )}

                    {selectedProjectTab === "QA" && <QAProject />}
                    {selectedProjectTab === "Bookings" && <BookingCalendar />}
                    {selectedProjectTab === "Mail" && <Mail project />}
                    {selectedProjectTab === "Budgets" && <BudgetsProjectTab />}

                    {selectedProjectTab === "Notes" && (
                      <Notes scope="Project" />
                    )}
                    {selectedProjectTab === "Accounts" && <AccountMapping />}
                    {selectedProjectTab === "QR" && <QR />}
                  </StyledProjectTabDisplayer>
                </>
              )}

              {!currentProject && (
                <DoxleEmptyPlaceHolder
                  headTitleText="No Project Selected"
                  subTitleText="Select or add a project to start your journey..."
                  illustration={
                    <NonSelectedProjectBanner
                      themeColor={doxleThemeColor}
                      containerStyle={{
                        width: "50%",
                        minWidth: 400,
                      }}
                    />
                  }
                  containerStyle={{
                    width: "100%",
                    flex: 1,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    backgroundColor: "transparent",
                  }}
                  addBtn={{
                    btnText: "Add Project",
                    btnFunction: () => {
                      navigation("/AddProject/");
                    },
                    btnStyle: {
                      p: "10px 20px !important",

                      borderRadius: 4,
                      color: `${doxleThemeColor.primaryContainerColor} !important`,
                      fontSize: "max(18px, 1.8rem)",
                    },
                    btnColor: doxleThemeColor.primaryFontColor,
                    btnTextColor: doxleThemeColor.primaryContainerColor,
                    btnWidthInPixel: "180px",
                  }}
                />
              )}
            </>
          )}

          {(isRefetchingProject || isFetchingProject) && (
            <Loading
              containerStyle={{
                width: "100%",
                flex: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                backgroundColor: "transparent",
              }}
            />
          )}
        </StyledProjectRootContainer>
      </motion.div>
    </StyledProjectContainer>
  );
};

export default Project;
