import React, { useEffect, useRef, useState } from "react";
import {
  StyledProjectDrawerContainer,
  StyledProjectDrawerLeft,
  StyledProjectDrawerRootContainer,
  StyledProjectTabDisplayer,
} from "./Style/StyledProjectDrawer";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import { useDoxleCurrentContextStore } from "../../../DoxleGeneralStore/useDoxleCurrentContext";
import ProjectDrawerMenu from "./Component/ProjectDrawerMenu";
import QAProject from "../../../QA/QAProject/QAProject";
import DrawingsTabDisplayer from "../../../Drawings/Content/DrawingsTabDisplayer";
import { useProjectStore } from "../../../DoxleGeneralStore/useprojectStore";
import Files from "../../../Files/Content/Files";
import Contacts from "../../../Contacts/Content/Contacts";
import BookingProject from "../../../Bookings/BookingProject/Components/BookingProject";
import Mail from "../../../Mail/Content/Mail";
import BudgetsProjectTab from "../../../Budgets/Table/BudgetsProjectTab";
import Notes from "../../../Notes/Components/Notes";
import useGetProjectList from "../Hooks/useGetProjectList";
import { useParams } from "react-router-dom";
import DoxleErrorScreen from "../../../DoxleDesignPattern/DoxleErrorScreen/DoxleErrorScreen";
import Loading from "../../../Utilities/Lottie/Loading";
import { Enable, Resizable } from "re-resizable";
import useRefComponentDimension from "../../UtilityHooks/useRefComponentDimension";
import Pricebook from "../../../Pricebook/Content/Pricebook";
import { Height } from "@mui/icons-material";
import ProjectDrawerSideMenu from "./Component/ProjectDrawerSideMenu";

type Props = {};

const ProjectDrawer = (props: Props) => {
  const doxleThemeColor = useDoxleThemeStore(
    (state) => state.doxleThemeColor,
    shallow
  );

  const rootRef = useRef<HTMLDivElement>(null);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  const { width, height } = useRefComponentDimension({
    componentRef: rootRef,
  });

  const enableValues: Enable = {
    top: false,
    right: false,
    bottom: false,
    left: true,
    topRight: false,
    bottomRight: false,
    bottomLeft: false,
    topLeft: false,
  };

  useEffect(() => {
    if (rootRef.current) {
      const { width, height } = rootRef.current.getBoundingClientRect();
      setDimensions({ width: width / 2, height });
    }
  }, [rootRef, height]);

  const [isErrorFetchingProject, setIsErrorFetchinProject] =
    useState<boolean>(false);
  const urlParams = useParams();

  const selectedProjectTab = useProjectStore(
    (state) => state.selectedProjectTab,
    shallow
  );

  const { projectList, isRefetchingProject, isFetchingProject } =
    useGetProjectList({});

  const { currentProject, currentCompany, setCurrentProject } =
    useDoxleCurrentContextStore(
      (state) => ({
        currentProject: state.currentProject,
        currentCompany: state.currentCompany,
        setCurrentProject: state.setCurrentProject,
      }),
      shallow
    );

  useEffect(() => {
    localStorage.setItem("lastUsedApp", "Projects");
  }, []);

  useEffect(() => {
    const urlProject = projectList.filter(
      (proj) => proj.projectId === urlParams?.projectId
    )?.[0];
    if (urlProject) setCurrentProject(urlProject);
  }, [urlParams?.projectId, isFetchingProject]);

  if (
    urlParams?.projectId &&
    currentProject !== currentProject?.projectId &&
    isErrorFetchingProject
  )
    return <DoxleErrorScreen />;
  else if (
    urlParams?.projectId &&
    currentProject !== currentProject?.projectId
  ) {
    setTimeout(() => {
      if (urlParams?.projectId && currentProject !== currentProject?.projectId)
        setIsErrorFetchinProject(true);
      else setIsErrorFetchinProject(false);
    }, 2500);
    return <Loading />;
  }
  const maxWidth = width * 0.9;

  return (
    <StyledProjectDrawerContainer ref={rootRef}>
      <StyledProjectDrawerLeft $themeColor={doxleThemeColor}>
        <ProjectDrawerSideMenu />
      </StyledProjectDrawerLeft>
      <Resizable
        maxWidth={maxWidth}
        enable={enableValues}
        size={{ width: dimensions.width, height: dimensions.height }}
        onResizeStop={(e, direction, ref, d) => {
          setDimensions({
            width: dimensions.width + d.width,
            height: dimensions.height + d.height,
          });
        }}
      >
        {!isRefetchingProject && !isFetchingProject && (
          <>
            {currentProject && (
              <StyledProjectDrawerRootContainer>
                <ProjectDrawerMenu />
                <StyledProjectTabDisplayer>
                  {selectedProjectTab === "Drawings" && (
                    <DrawingsTabDisplayer />
                  )}
                  {selectedProjectTab === "Files" && (
                    <Files level={"Project"} />
                  )}

                  {selectedProjectTab === "Contacts" && (
                    <Contacts context={"Project"} />
                  )}
                  {selectedProjectTab === "QA" && <QAProject />}
                  {selectedProjectTab === "Bookings" && <BookingProject />}
                  {selectedProjectTab === "Mail" && <Mail project />}
                  {selectedProjectTab === "Budgets" && <BudgetsProjectTab />}
                  {selectedProjectTab === "Pricebook" && (
                    <Pricebook context={"Project"} />
                  )}
                  {selectedProjectTab === "Notes" && <Notes scope="Project" />}
                </StyledProjectTabDisplayer>
              </StyledProjectDrawerRootContainer>
            )}
          </>
        )}
        {(isRefetchingProject || isFetchingProject) && <Loading />}
      </Resizable>
    </StyledProjectDrawerContainer>
  );
};

export default ProjectDrawer;
