import { useCallback } from "react";

import { shallow } from "zustand/shallow";
import { ScopeOfWorkImage } from "../Model/scopeOfWorks";
import { useScopeOfWorkStore } from "../Store/useScopeOfWorkStore";
import { useIsMutating } from "@tanstack/react-query";
import { getScopeImgMutationKey } from "../QueryAPI/scopeOfWorkQueryAPI";

type Props = {
  scopeImgItem: ScopeOfWorkImage;
};

interface ScopeImageGalleryItem {
  handleClickScopeImgItem: () => void;
  isDeletingScopeImg: boolean;
  handleDeleteScopeImg: () => void;
}
const useScopeImgGalleryItem = ({
  scopeImgItem,
}: Props): ScopeImageGalleryItem => {
  const { deletedScopeImage, setDeletedScopeImage } = useScopeOfWorkStore(
    (state) => ({
      deletedScopeImage: state.deletedScopeImage,
      setDeletedScopeImage: state.setDeletedScopeImage,
    }),
    shallow
  );
  const handleClickScopeImgItem = useCallback(() => {}, []);

  const isDeletingScopeImg: boolean =
    useIsMutating({
      mutationKey: getScopeImgMutationKey("delete", scopeImgItem.scopeItem),
      predicate: (query) =>
        Boolean(
          (query.state.variables as string[]).includes(scopeImgItem.imageId)
        ),
    }) > 0;

  const handleDeleteScopeImg = () => {
    setDeletedScopeImage(scopeImgItem);
  };
  return {
    handleClickScopeImgItem,
    isDeletingScopeImg,
    handleDeleteScopeImg,
  };
};

export default useScopeImgGalleryItem;
