import { ActionList } from "../../../IFCViewer/middleware/actions";
import { SetStateAction } from "react";
import {
  StyledCompanyTab,
  StyledCompanyTabMenuContainer,
} from "../styledComponents";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";

export const COMPANY_SETTINGS_TABS = [
  "Company",
  "Statuses",
  "Permissions",
  "Xero",
  "Reports",
  "Notification",
] as const;
type CompanySettingsTabList = typeof COMPANY_SETTINGS_TABS;
export type CompanySettingsTabOptions = CompanySettingsTabList[number];
interface Props {
  selectedTab: CompanySettingsTabOptions;
  setSelectedTab: React.Dispatch<SetStateAction<CompanySettingsTabOptions>>;
}
const CompanySettingsTabMenu = ({ selectedTab, setSelectedTab }: Props) => {
  const styleProps = useDoxleThemeStore(
    (state) => ({
      $themeColor: state.doxleThemeColor,
      $doxleFont: state.doxleFont,
    }),
    shallow
  );
  return (
    <StyledCompanyTabMenuContainer {...styleProps}>
      {COMPANY_SETTINGS_TABS.map((tab, idx) => (
        <StyledCompanyTab
          {...styleProps}
          key={`company-setting#${idx}`}
          $selected={tab === selectedTab}
          onClick={() => setSelectedTab(tab)}
        >
          {tab}
        </StyledCompanyTab>
      ))}
    </StyledCompanyTabMenuContainer>
  );
};

export default CompanySettingsTabMenu;
