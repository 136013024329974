import React from "react";
import useNoteLineListFooter from "./Hooks/useNoteLineListFooter";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import {
  StyledAddNoteLineBtn,
  StyledNewLineDescriptionTextField,
  StyledNoteLineListFooter,
} from "./StyledEditNote";
import { GoPlus } from "react-icons/go";
import CircularProgress from "@mui/material/CircularProgress";
import { editRgbaAlpha } from "../../../Utilities/FunctionUtilities";
import { AnimatePresence, Variants } from "framer-motion";
import ToolModeDisplay from "./ToolModeDisplay";
import { ClickAwayListener } from "@mui/material";
type Props = { noteId: string };

const NoteLineListFooter = ({ noteId }: Props) => {
  const { doxleThemeColor, doxleFont, transparentMode } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
      transparentMode: state.transparentMode,
    }),
    shallow
  );
  const {
    lineEditTools,
    handleToggleLineEditTool,
    newLineDescription,
    setNewLineDescription,
    handleKeydownDescriptionTextfield,
    handleAddNoteLine,
    isAddingLine,
    setIsFocused,
    isFocused,
    checkLineEditToolEnable,
    newLineInputRef,
    handleNewLineDescriptionChange,
  } = useNoteLineListFooter({ noteId });

  const containerAnimatedVariant: Variants = {
    initial: {
      height: 30,
    },
    focused: {
      height: 120,
    },
  };
  return (
    <ClickAwayListener onClickAway={() => setIsFocused(false)}>
      <StyledNoteLineListFooter
        $themeColor={doxleThemeColor}
        $doxleFont={doxleFont}
        variants={containerAnimatedVariant}
        initial="initial"
        animate={isFocused ? "focused" : "initial"}
        layout
        transition={{
          damping: 16,
          duration: 0.3,
          ease: "easeInOut",
        }}
      >
        <StyledNewLineDescriptionTextField
          $themeColor={doxleThemeColor}
          $doxleFont={doxleFont}
          placeholder="New title..."
          multiline
          $isBold={checkLineEditToolEnable("bold")}
          $isItalic={checkLineEditToolEnable("italic")}
          inputRef={newLineInputRef}
          value={
            checkLineEditToolEnable("bullet")
              ? `*${newLineDescription}`
              : newLineDescription
          }
          onChange={(event) => {
            handleNewLineDescriptionChange(event.target.value);
          }}
          InputProps={{
            startAdornment: isAddingLine ? (
              <CircularProgress
                size="1.4rem"
                style={{
                  color: doxleThemeColor.primaryInputPlaceholderColor,

                  marginRight: "0.4rem",
                }}
              />
            ) : (
              <StyledAddNoteLineBtn
                initial={{
                  backgroundColor: editRgbaAlpha({
                    rgbaColor: doxleThemeColor.primaryFontColor,
                    alpha: "0",
                  }),
                }}
                animate={
                  newLineDescription
                    ? {
                        backgroundColor: doxleThemeColor.primaryFontColor,
                      }
                    : {
                        backgroundColor: editRgbaAlpha({
                          rgbaColor: doxleThemeColor.primaryFontColor,
                          alpha: "0",
                        }),
                      }
                }
                onClick={handleAddNoteLine}
              >
                <GoPlus
                  style={{
                    color: !newLineDescription
                      ? doxleThemeColor.primaryInputPlaceholderColor
                      : doxleThemeColor.primaryContainerColor,
                    fontSize: "1.8rem",
                  }}
                />
              </StyledAddNoteLineBtn>
            ),
          }}
          onFocus={() => {
            setIsFocused(true);
            newLineInputRef.current?.select();
          }}
          //   onBlur={handleAddNoteLine}
          onKeyDown={handleKeydownDescriptionTextfield}
        />

        <AnimatePresence>
          {isFocused && (
            <ToolModeDisplay
              toolModes={lineEditTools}
              handleToggleTool={handleToggleLineEditTool}
              containerStyle={{
                position: "absolute",
                bottom: 4,
                left: 0,
                zIndex: 1,
              }}
            />
          )}
        </AnimatePresence>
      </StyledNoteLineListFooter>
    </ClickAwayListener>
  );
};

export default NoteLineListFooter;
