import React from "react";
import OrderFilters from "../Models/orderFilters";
import { useDoxleCurrentContextStore } from "../../DoxleGeneralStore/useDoxleCurrentContext";
import { shallow } from "zustand/shallow";
import { getOrderListQKey } from "../QueryHooks/OrdersQueryAPI";
import { useQueryClient } from "@tanstack/react-query";
import { produce } from "immer";
import Order from "../Models/order";
import {
  IApiPaginatedData,
  InfiniteAxiosQueryData,
} from "../../Models/axiosReturn";

type Props = {
  filter: OrderFilters;
  appendPos?: "start" | "end";
  overwrite?: boolean;
};

interface SetOrderQueryData {
  handleRemoveOrder: (deletedOrderId: string) => void;
  handleRemoveMultiOrders: (deletedOrderIds: string[]) => void;
  handleUpdateOrder: (updatedOrder: Order) => void;
  handleAddOrder: (newOrder: Order) => void;
  handleUpdateMultiOrders: (orders: Order[]) => void;
}
const useSetOrderQueryData = ({
  filter,
  appendPos = "start",
  overwrite = true,
}: Props): SetOrderQueryData => {
  const { currentCompany } = useDoxleCurrentContextStore(
    (state) => ({
      currentCompany: state.currentCompany,
    }),
    shallow
  );
  const qKey = getOrderListQKey(filter, currentCompany);
  const queryClient = useQueryClient();
  const handleRemoveOrder = (deletedOrderId: string) => {
    const queryData = queryClient.getQueryData(qKey);
    if (overwrite) {
      if (queryData)
        queryClient.setQueryData(qKey, (old: any) =>
          produce(old, (draft: any) => {
            let pageIndexContainItem: number = old.pages.findIndex(
              (page: any) =>
                Boolean(
                  page.data.results.find(
                    (order: Order) => order.orderId === deletedOrderId
                  ) !== undefined
                )
            );
            if (pageIndexContainItem !== -1)
              draft.pages[pageIndexContainItem].data.results = draft.pages[
                pageIndexContainItem
              ].data.results.filter(
                (order: Order) => order.orderId !== deletedOrderId
              );
          })
        );
      else queryClient.invalidateQueries(qKey);
    } else queryClient.invalidateQueries(qKey);
  };

  const handleRemoveMultiOrders = (orderIds: string[]) => {
    const queryData = queryClient.getQueryData(qKey);
    if (overwrite) {
      if (queryData)
        queryClient.setQueryData<InfiniteAxiosQueryData<Order>>(qKey, (old) => {
          if (old)
            return produce(old, (draft) => {
              for (let i = 0; i < draft.pages.length; i++) {
                draft.pages[i].data.results = produce(
                  draft.pages[i].data.results,
                  (draftResult) => {
                    return draftResult.filter((order) => {
                      return !orderIds.includes(order.orderId);
                    });
                  }
                );
              }

              return draft;
            });
        });
      else queryClient.invalidateQueries(qKey);
    } else queryClient.invalidateQueries(qKey);
  };

  const handleUpdateOrder = (updatedOrder: Order) => {
    const queryData = queryClient.getQueryData(qKey);
    if (overwrite && queryData) {
      queryClient.setQueryData(qKey, (old: any) => {
        let pageIndexContainItem: number = old.pages.findIndex((page: any) =>
          Boolean(
            page.data.results.find(
              (order: Order) => order.orderId === updatedOrder.orderId
            ) !== undefined
          )
        );
        if (pageIndexContainItem !== -1) {
          const itemIndex = (
            old.pages[pageIndexContainItem].data as IApiPaginatedData<Order>
          ).results.findIndex(
            (order) => order.orderId === updatedOrder.orderId
          );
          return produce(old, (draft: any) => {
            const item = (
              draft.pages[pageIndexContainItem].data as IApiPaginatedData<Order>
            ).results.findIndex(
              (order) => order.orderId === updatedOrder.orderId
            );
            if (item) {
              console.log("UPDATE ITEM INDEX:", itemIndex);
              Object.assign(item, updatedOrder);
            }

            return draft;
          });
        } else queryClient.invalidateQueries(qKey);
      });
    } else queryClient.invalidateQueries(qKey);
  };

  const handleUpdateMultiOrders = (orders: Order[]) => {
    const queryData = queryClient.getQueryData(qKey);
    if (overwrite) {
      if (queryData)
        queryClient.setQueryData(qKey, (old: any) => {
          let dataPos: Array<{
            pageIndex: number;
            itemIndex: number;
          }> = [];
          orders.forEach((item) => {
            (old.pages as any[]).forEach((page, pageIdx) => {
              const itemIndex = (page.data.results as Order[]).findIndex(
                (order) => order.orderId === item.orderId
              );
              if (itemIndex !== -1)
                dataPos.push({
                  pageIndex: pageIdx,
                  itemIndex,
                });
            });
          });
          console.log("dataPos:", dataPos);

          return produce(old, (draft: any) => {
            dataPos.forEach((data, i) => {
              draft.pages[data.pageIndex].data.results[data.itemIndex] =
                orders[i];
            });
            return draft;
          });
        });
      else queryClient.invalidateQueries(qKey);
    } else queryClient.invalidateQueries(qKey);
  };

  const handleAddOrder = (newOrder: Order) => {
    const budgetData = queryClient.getQueryData(qKey);
    if (budgetData && overwrite) {
      queryClient.setQueryData(qKey, (old: any) => {
        if (old) {
          const lengthOfPages = old.pages.length;
          return produce(old, (draftOld: any) => {
            draftOld.pages = produce(draftOld.pages, (draftPages: any) => {
              if (appendPos === "start") {
                (
                  draftPages[0].data as IApiPaginatedData<Order>
                ).results.unshift(newOrder);
              } else {
                (
                  draftPages[lengthOfPages - 1].data as IApiPaginatedData<Order>
                ).results.push(newOrder);
              }

              return draftPages;
            });

            return draftOld;
          });
        } else return queryClient.invalidateQueries(qKey);
      });
    } else queryClient.invalidateQueries(qKey);
  };
  return {
    handleRemoveOrder,
    handleUpdateOrder,
    handleAddOrder,
    handleRemoveMultiOrders,
    handleUpdateMultiOrders,
  };
};

export default useSetOrderQueryData;
