import styled from "styled-components";
import {
  DoxleFont,
  DoxleTheme,
  DoxleThemeColor,
} from "../../DoxleGeneralStore/useDoxleThemeStore";

import { motion } from "framer-motion";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import { editRgbaAlpha } from "../../Utilities/FunctionUtilities";

export const StyledPageContainer = styled(motion.div)<{}>`
  width: 100%;
  height: 100%;
  background-color: ${(p) => p.theme.color.primaryBackgroundColor};
  display: flex;
  flex-direction: column;
  min-width: 500px;
  .header-section {
    font-family: ${(p) => p.theme.font.primaryTitleFont};
    font-size: 30px;
    line-height: 30px;
    font-weight: 600;
    color: ${(p) => p.theme.color.primaryFontColor};
    text-align: center;
    margin: 14px 0px;
    text-transform: capitalize;
    width: calc(100% - 40px);
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 20px;

    div {
      display: flex;
      align-items: center;
    }
  }
`;

export const StyledContactPageHeaderTitle = styled(Typography)<{
  $themeColor: DoxleThemeColor;
  $font: DoxleFont;
}>`
  && {
    color: ${(p) => p.$themeColor.primaryFontColor};
    font-family: ${(p) => p.$font.primaryTitleFont};
    font-size: 3rem;
    font-weight: 600;
  }
`;

export const StyledContactPageHeaderSubTitle = styled.div<{
  $themeColor: DoxleThemeColor;
  $font: DoxleFont;
}>`
  text-align: left;
  color: ${(p) => p.$themeColor.primaryFontColor};
  font-family: ${(p) => p.$font.primaryTitleFont};
  font-size: 1.1rem;
  width: 50rem;
  align-self: center;
`;
interface ICustomTextFieldProps
  extends Omit<
    TextFieldProps<"standard">,
    "onDragStart" | "onAnimationStart" | "onDragEnd" | "onDrag"
  > {
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
  $context?: string;
}
const AnimatedTextField = motion(TextField);
export const StyledContactPageSearchField = styled(
  ({ $themeColor, $doxleFont, ...rest }: ICustomTextFieldProps) => (
    <AnimatedTextField
      {...rest}
      initial={{
        x: -5,
        opacity: 0,
      }}
      animate={{
        x: 0,
        opacity: 1,
      }}
    />
  )
)`
  && {
    min-width: 200px;
    width: 36.1rem;
    height: 3rem;
    min-height: 25px;
    background: ${(p) => p.$themeColor.primaryInputPlaceholderColor};
    border-radius: 1.3rem;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    align-self: center;
    padding: 0 0.8rem;
    margin: ${(p) => (p.$context ? "0.5rem" : "1.9rem")};
  }
  input {
    padding: 0 !important;
    height: 100%;
    font-family: "IBM Plex Mono";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.05em;
    color: #000000;
  }
  & .MuiInput-root:after {
    border-bottom: none !important;
  }
  & .MuiInput-root:before {
    border-bottom: none !important;
  }
  & .MuiInput-root {
    border-bottom: none !important;
  }
  & .MuiInput-root:hover {
    border-bottom: none !important;
  }
  & .MuiInput-root:hover:not(.Mui-disabled, .Mui-error):before {
    border-bottom: none;
  }
  & .MuiInputLabel-root.Mui-focused {
    display: none;
  }
  & .MuiInputLabel-root {
    display: none;
  }
  & .MuiInputBase-root-MuiInput-root:after {
    border-bottom: none !important;
  }
`;

export const StyledContactPageHeaderContainer = styled.div<{
  $themeColor: DoxleThemeColor;
  $context?: string;
}>`
  width: 100%;
  margin: ${(p) => (p.$context ? 0 : "3rem 0")};
  background-color: ${(p) => p.$themeColor.primaryBackgroundColor};
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  min-width: 500px;
`;

export const StyledContactTableRow = styled(motion(TableCell))<{
  $themeColor: DoxleThemeColor;
  $currentTheme: DoxleTheme;
  $transparentMode: boolean;
}>`
  && {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    padding: 0px !important;
    &:hover {
      background-color: ${(p) => p.$themeColor.rowHoverColor};
      transition: 0.4s;
    }
    background-color: ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.$themeColor.primaryContainerColor,
        alpha: p.$transparentMode
          ? "0"
          : p.$currentTheme === "light"
          ? "0.3"
          : "0.5",
      })};
  }
`;

export const StyledContactTableCell = styled.div<{
  $widthInRem: `${number}rem` | null;

  $horizontalAlign?: "flex-start" | "center" | "flex-end";
  $paddingLeft?: string;
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  ${(p) => (p.$widthInRem ? `width: ${p.$widthInRem};` : "flex:1;")}
  display: flex;
  height: 100%;
  border-bottom: none;
  justify-content: ${(p) =>
    p.$horizontalAlign ? p.$horizontalAlign : "center"};
  align-items: center;
  font-family: ${(p) => p.$doxleFont.primaryFont};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.07rem;
  color: ${(p) => p.$themeColor.primaryFontColor};
  text-transform: capitalize;
  padding: 0px 1rem;
  padding-left: ${(p) => (p.$paddingLeft ? p.$paddingLeft : "3rem")};

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  span {
    display: inline-block;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.07rem;
    text-transform: capitalize !important;
    color: ${(p) => p.$themeColor.primaryFontColor};
  }
`;

export const StyledContactTableHeaderRow = styled(TableRow)`
  && {
    width: 100% !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: ${(p) => p.theme.staticMenuColor.staticBg};
    height: 30px;
    color: ${(p) => p.theme.staticMenuColor.staticFontColor};
  }
`;

export const StyledContactTableHeaderCell = styled(TableHead)<{
  $doxleFont: DoxleFont;
  widthInPixel: `${number}px` | null;
  horizontalAlign?: "flex-start" | "center" | "flex-end";
  $themeColor: DoxleThemeColor;
  paddingLeft?: number;
}>`
  && {
    ${(p) => (p.widthInPixel ? `width: ${p.widthInPixel};` : "flex:1;")}
    display: flex;
    justify-content: ${(p) =>
      p.horizontalAlign ? p.horizontalAlign : "center"};
    align-items: center;
    padding: 0px 30px;
    color: ${(p) => p.$themeColor.primaryFontColor};
    text-transform: capitalize;
    font-family: ${(p) => p.$doxleFont.primaryTitleFont} !important;
  }
`;

export const StyledContactTableHeaderText = styled(Typography)<{
  $doxleFont: DoxleFont;
  $fontWeight?: number;
}>`
  && {
    padding-left: 5px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.28px;
    color: ${(p) => p.theme.staticMenuColor.staticFontColor};
    font-family: ${(p) => p.theme.font.primaryTitleFont};
    font-weight: ${(p) => p.$fontWeight ?? 400};
    cursor: pointer;
  }
`;

export const RootContactTabMenu = styled(motion.div)`
  display: flex;
  flex-direction: row;
  margin: 20px 0px 20px 0px;
  height: 30px;
  align-self: center;
`;

export const StyledContactMenuItemBtn = styled(motion(Button))<{
  themecolor: DoxleThemeColor;
  selected: boolean;
  $doxleFont: DoxleFont;
}>`
  && {
    font-family: ${(p) => p.$doxleFont.primaryTitleFont};
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.05em;
    min-width: 0 !important;
    padding: 5px 14px !important;
    margin: 0 4px;
    border-radius: 20px !important;
    color: ${(p) => (p.selected ? "white" : p.themecolor.primaryFontColor)};
    background-color: ${(p) =>
      p.selected
        ? p.themecolor.doxleColor
        : p.themecolor.primaryContainerColor};
    text-transform: capitalize;
  }
`;

export const StyledContactKebabMenu = styled(Menu)`
  & .MuiMenu-list {
    background-color: white;
    opacity: 1;
    transform-origin: 168.188px 0px;
    right: 10px;
  }
  & .MuiMenuItem-root {
    font-family: "IBM Plex Sans";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.05em;
  }
`;

export const StyledContactsPageContainer = styled(motion.div)`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 1.4rem;
  position: relative;
`;
export const StyledContactCompanyPageContainer = styled(motion.div)`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 1.4rem;
  position: relative;
`;

export const StyledContactListItemWrapper = styled(TableRow)<{
  $themeColor: DoxleThemeColor;
}>`
  && {
    display: flex;
    flex-direction: row;
    width: 100%;
    border-bottom: 1px solid ${(p) => p.$themeColor.primaryDividerColor};

    height: 4.4rem;
  }
`;

export const StyledContextDropDownContainer = styled.div<{
  $top: number;
  $right: number;
  $context?: "Project" | "DocketFullScreen" | "DocketHalfScreen";
}>`
  display: flex;
  flex-direction: column;
  z-index: 100;
  position: fixed;
  top: ${(p) =>
    `calc(${p.$top}px${
      p.$context !== "DocketFullScreen" && p.$context !== "DocketHalfScreen"
        ? " - 3.6rem"
        : ""
    })`};
  right: calc(100% - ${(p) => p.$right}px);
  background-color: ${(p) => p.theme.color.primaryContainerColor};
  border-radius: 4px 0 4px 4px;
  padding: 1rem;
  box-shadow: 0px 0px 0.8rem 0px rgba(0, 0, 0, 0.2);
`;
export const StyledDropDownItem = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  width: Calc(100% - 2rem);
  font-family: ${(p) => p.$doxleFont.primaryFont};
  font-size: 1.3rem;
  color: ${(p) => p.$themeColor.primaryFontColor};
  border-bottom: 1px solid ${(p) => p.$themeColor.primaryDividerColor};
  &:hover {
    background-color: ${(p) => p.$themeColor.primaryHoverColor};
  }
  padding: 0.2rem 1rem;
  cursor: pointer;
`;
