import React, { useCallback, useState } from "react";
import {FileRejection, useDropzone} from 'react-dropzone'
import { Mesh }  from "../svg/mesh";
import { UploadFloorPlanIcon } from "../svg/uploadFloorplanIcon";
import { useUploadFloorplanContext } from "../Pages/uploadFloorplan";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { DrawingUploadPageContainer, FileUploadSubmitBtn, SizeDescription, StyledPrompt, StyledTitle, UploadContainer } from "./StyledFloorplanUploadPage";



export default function FileUpload() {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore((state) => ({
    doxleThemeColor: state.doxleThemeColor,
    doxleFont: state.doxleFont
  }));

  const [file, setFile] = useState<File | null>();
  const [filename, setFilename] = useState("");
  const {stage, setStage, floorplanFormData, setFloorplanFormData} = useUploadFloorplanContext();

  const onDrop = useCallback(<T extends File>(acceptedFiles: T[], fileRejections: FileRejection[]) => {
    const file = acceptedFiles[0];
    if(file){
      setFile(file);
      setFilename(file.name);
      const floorplanData = floorplanFormData;
      floorplanData['file'] = file;
      setFloorplanFormData(floorplanData);
    }else{
    }
  }, []);

  const handleClick = () => {
    if(file){
      setStage(stage + 1);
    }
  }

  const {getRootProps, getInputProps} = useDropzone({onDrop, maxSize: 10000000, maxFiles: 1, 
  accept:{'image/jpeg': [],
  'image/png': []}})
 
  return (
    <DrawingUploadPageContainer>
      <Mesh themeColor={doxleThemeColor}/>
      <UploadContainer>
      <div {...getRootProps()} style={{cursor: "pointer"}}>
        <input {...getInputProps()} />
        <UploadFloorPlanIcon themeColor={doxleThemeColor}/>
      </div>

      <StyledTitle $themeColor={doxleThemeColor} $doxleFont={doxleFont}>{filename?filename:'Upload your drawings'}</StyledTitle>
      <StyledPrompt $themeColor={doxleThemeColor} $doxleFont={doxleFont}>Drag & drop your files here or choose file</StyledPrompt>
      <SizeDescription $themeColor={doxleThemeColor} $doxleFont={doxleFont}>(10 MB Max Size)</SizeDescription>
      <FileUploadSubmitBtn $themeColor={doxleThemeColor} onClick={handleClick}>
        Submit
      </FileUploadSubmitBtn>
      </UploadContainer>
    </DrawingUploadPageContainer>
  );
}

