import React, { useEffect, useState } from "react";
import { useProjectQAStore } from "../../../Store/useProjectQAStore";
import { shallow } from "zustand/shallow";
import useDoxleNotificationStore from "../../../../../DoxleGeneralStore/useDoxleNotificationStore";
import { useDoxleAuthStore } from "../../../../../DoxleGeneralStore/useDoxleAuthStore";
import { useDoxleCurrentContextStore } from "../../../../../DoxleGeneralStore/useDoxleCurrentContext";
import QAQueryAPI, {
  formDefectListDetailQKey,
} from "../../../../QueryAPI/qaQueryAPI";
import { NEW_QA_ITEM_TEMPLATE, QA } from "../../../../Models/qa";
import { useProjectStore } from "../../../../../DoxleGeneralStore/useprojectStore";
import useSetQAQueryData from "../../../../CustomQueryHooks/useSetQAQueryData";
import { ListRange } from "react-virtuoso";
import { useQueryClient } from "@tanstack/react-query";

type Props = {};

interface QAListDetail {
  isAddingQAItem: boolean;
  deletedQAItem: QA | undefined;
  setdeletedQAItem: React.Dispatch<React.SetStateAction<QA | undefined>>;
  handleDeleteQAItem: () => void;
  currentVisibleRange: ListRange;
  setCurrentVisibleRange: React.Dispatch<React.SetStateAction<ListRange>>;
}
const useQAListDetail = (props: Props): QAListDetail => {
  const [currentVisibleRange, setCurrentVisibleRange] = useState<ListRange>({
    startIndex: 0,
    endIndex: 0,
  });
  const [deletedQAItem, setdeletedQAItem] = useState<QA | undefined>(undefined);
  const { selectedQAList } = useProjectQAStore(
    (state) => ({
      selectedQAList: state.selectedQAList,
    }),
    shallow
  );
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );
  const { setFunctionNewButton } = useProjectStore(
    (state) => ({
      setFunctionNewButton: state.setFunctionNewButton,
    }),
    shallow
  );
  const { user } = useDoxleAuthStore(
    (state) => ({ user: state.user }),
    shallow
  );
  const { company } = useDoxleCurrentContextStore(
    (state) => ({
      company: state.currentCompany,
    }),
    shallow
  );
  //add qa item query
  const queryClient = useQueryClient();
  const onAddSuccess = (newQA?: QA) => {
    if (newQA) {
      const qaListDetailQKey = formDefectListDetailQKey(
        newQA.defectList,
        company
      );
      queryClient.invalidateQueries(qaListDetailQKey);
    }
  };
  const addQaItemQuery = QAQueryAPI.useAddQAItemQuery({
    showNotification,
    company,
    defectListId: selectedQAList?.defectListId ?? "",
    onSuccessCB: onAddSuccess,
  });

  const handleAddQaItemQuery = () => {
    const newQa = NEW_QA_ITEM_TEMPLATE({
      defectList: selectedQAList?.defectListId ?? "",
      project: selectedQAList?.project,
      company: company?.companyId || "",
      createdBy: user?.userId,
    });
    addQaItemQuery.mutate(newQa);
  };

  useEffect(() => {
    setFunctionNewButton([
      { btnFunction: handleAddQaItemQuery, isLoading: addQaItemQuery.isLoading },
    ]);

    return () => {
      setFunctionNewButton([]);
    };
  }, [addQaItemQuery.isLoading]);

  //* delete qa item
  const { handleDeleteQAQueryData } = useSetQAQueryData({
    defectListId: selectedQAList?.defectListId ?? "",
  });
  const deleteQAQuery = QAQueryAPI.useDeleteQAQuery({
    company,
    qaListId: selectedQAList?.defectListId ?? "",

    // showNotification,
  });

  const handleDeleteQAItem = () => {
    if (deletedQAItem) {
      handleDeleteQAQueryData(deletedQAItem.defectId);
      deleteQAQuery.mutate(deletedQAItem.defectId);
    }
    setdeletedQAItem(undefined);
  };
  return {
    isAddingQAItem: addQaItemQuery.isLoading,
    deletedQAItem,
    setdeletedQAItem,
    handleDeleteQAItem,
    currentVisibleRange,
    setCurrentVisibleRange,
  };
};

export default useQAListDetail;
