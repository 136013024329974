import { create } from "zustand";
import { InboxFilterOption } from "../../Inbox/InboxStore/useInboxStore";
import Order, { OrderStatus } from "../Models/order";
import OrderFilters from "../Models/orderFilters";
import { produce } from "immer";

interface OrdersStore {
  view: "Invoices" | "Orders";
  setView: (view: "Invoices" | "Orders") => void;
  showPayments: boolean;
  toggleShowPayments: (value: boolean) => void;
  selectedStatus: OrderStatus | undefined;
  setSelectedStatus: (item: OrderStatus | undefined) => void;
  // ordersList: Order[]
  // setOrdersList: (list: Order[]) => void;
  queryFilter: OrderFilters;
  setQueryFilter: (filter: Partial<OrderFilters>) => void;

  onAddOrderMode: boolean;
  setOnAddOrderMode: (value: boolean) => void;

  deletedOrder: Order | undefined;
  setDeletedOrder: (item: Order | undefined) => void;

  selectedOrders: Order[];
  toggleSelectedOrder: (item: Order) => void;
  setSelectedOrder: (list: Order[]) => void;

  clearSelectedOrders: () => void;

  resetOrderStore: () => void;

  selectAllOrder: boolean;
  toggleSelectAllOrder: () => void;
  setSelectAllOrder: (flag: boolean) => void;
}

export const useOrdersStore = create<OrdersStore>((set, get) => ({
  view: "Orders",
  setView: (value: "Invoices" | "Orders") => set({ view: value }),
  showPayments: false,
  toggleShowPayments: (value: boolean) => set({ showPayments: value }),
  // ordersList: [],
  // setOrdersList: (list: Order[]) => set({ ordersList: list }),
  queryFilter: { is_invoice: false },
  setQueryFilter: (filter: Partial<OrderFilters>) =>
    set((state) => ({ queryFilter: { ...state.queryFilter, ...filter } })),

  selectedStatus: undefined,
  setSelectedStatus: (item: OrderStatus | undefined) => {
    if (item) sessionStorage.setItem("lastOrderStatusId", item.statusId);
    set({ selectedStatus: item });
  },

  onAddOrderMode: false,
  setOnAddOrderMode: (value: boolean) => set({ onAddOrderMode: value }),

  deletedOrder: undefined,
  setDeletedOrder: (item: Order | undefined) => set({ deletedOrder: item }),

  selectedOrders: [],
  toggleSelectedOrder: (item: Order) => {
    set((state) =>
      produce(state, (draft) => {
        const matchedOrder = draft.selectedOrders.find(
          (selectedOrder) => selectedOrder.orderId === item.orderId
        );
        if (!matchedOrder) draft.selectedOrders.push(item);
        else {
          draft.selectedOrders = draft.selectedOrders.filter(
            (selectedOrder) => selectedOrder.orderId !== item.orderId
          );
          draft.selectAllOrder = false;
        }

        return draft;
      })
    );
  },
  

  setSelectedOrder: (list: Order[]) => {
    set({ selectedOrders: list });
    if (list.length === 0) {
      set({ selectAllOrder: false });
    }
  },

  clearSelectedOrders: () => {
    set((state) =>
      produce(state, (draft) => {
        draft.selectedOrders = [];
        return draft;
      })
    );
    set({ selectAllOrder: false });
  },

  selectAllOrder: false,

  toggleSelectAllOrder: () => {
    if(get().selectAllOrder === true){
     get().clearSelectedOrders();
     return;
    }
    set({ selectAllOrder: !get().selectAllOrder });
  },

  setSelectAllOrder: (flag: boolean) => {
    set({ selectAllOrder: flag });
  },

  resetOrderStore: () =>
    set({
      view: "Orders",
      queryFilter: { is_invoice: false },
      selectedStatus: undefined,
      onAddOrderMode: false,
      deletedOrder: undefined,
      selectedOrders: [],
      selectAllOrder: false,
    }),
}));
