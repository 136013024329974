import {useMutation} from "@tanstack/react-query";
import {DoxleAnonymousAPI} from "../../../Services/DoxleAPI";
import {AxiosError, isAxiosError} from "axios";
import useDoxleNotificationStore from "../../../DoxleGeneralStore/useDoxleNotificationStore";
import {shallow} from "zustand/shallow";
import { AxiosBackendErrorReturn } from "../../../Models/axiosReturn";

interface ContactUsAPIProps {
  onSuccessCb?: () => void;
  onErrorCb?: () => void;
}

export interface SendEnquiry {
  name: string;
  mobile?: string;
  email: string;
  message: string;
}
export interface JoinEnquiry {
  role: string;
  name: string;
  mobile: string;
  email: string;
  company?: string;
  reference: string;
}
const useSendEnquiry = ({onSuccessCb, onErrorCb}:ContactUsAPIProps) => {
  const showNotification = useDoxleNotificationStore(state => state.showNotification, shallow);
  return useMutation({
    mutationFn: (body:SendEnquiry) => DoxleAnonymousAPI.post(`/contact_us/`, body),
    onSuccess: (result, variables, context) => {
      if (showNotification)
        showNotification(
          "Email Successfully sent",
          "success",
          "We will endeavour to get back to you within seven days"
        );
      if (onSuccessCb) onSuccessCb();
    },
    onError: (error, variables, context) => {
      if (showNotification) {
        if (isAxiosError<AxiosBackendErrorReturn>(error)) {
          showNotification(
            `${error?.response?.status ?? "ERROR"}: ${
              error.response?.data.detail ?? "UNKNOWN ERROR"
            }`,
            "error",
            String(
              error?.response?.data?.detail ?? "Email Failed to send"
            ).substring(0, 300)
          );
        } else {
          showNotification(
            "Something Wrong!",
            "error",
            "Email Failed to send"
          );
        }
      }
      if (onErrorCb) onErrorCb();
    },
  });
}

const useJoinRequest = ({onSuccessCb, onErrorCb}:ContactUsAPIProps) => {
  const showNotification = useDoxleNotificationStore(state => state.showNotification, shallow);
  return useMutation({
    mutationFn: (body:JoinEnquiry) => DoxleAnonymousAPI.post(`/contact_us/join/`, body),
    onSuccess: (result, variables, context) => {
      if (showNotification)
        showNotification(
          "Email Successfully sent",
          "success",
          "We will endeavour to get back to you within seven days"
        );
      if (onSuccessCb) onSuccessCb();
    },
    onError: (error, variables, context) => {
      if (showNotification) {
        if (isAxiosError<AxiosBackendErrorReturn>(error)) {
          showNotification(
            `${error?.response?.status ?? "ERROR"}: ${
              error.response?.data.detail ?? "UNKNOWN ERROR"
            }`,
            "error",
            String(
              error?.response?.data?.detail ?? "Email Failed to send"
            ).substring(0, 300)
          );
        } else {
          showNotification(
            "Something Wrong!",
            "error",
            "Email Failed to send"
          );
        }
      }
      if (onErrorCb) onErrorCb();
    },
  });
}

const ContactUsAPI =  {
  useSendEnquiry,
  useJoinRequest
}

export default ContactUsAPI
