import { Button, Select, TableRow, TextField, Typography } from "@mui/material";
import { motion } from "framer-motion";
import styled from "styled-components";

// export const StyledDrawingsSheetsRootContainer = styled(motion.div)`
//   width: 100%;
//   height: 100%;
//   display: flex;
//   background-color: #eff0f4;
//   flex-direction: row;
// `;
//
// export const StyledDrawingsSheetsTitle = styled(Typography)`
//   && {
//     font-family: "Work Sans";
//     font-style: normal;
//     font-weight: 300;
//     font-size: 50px;
//     line-height: 59px;
//     margin-top: 90px;
//     letter-spacing: 0.05em;
//   }
// `;

//Empty Div for spacing when link is gone
export const EmptyDivForLink = styled.div`
  width: 100%;
  height: 24px;
`;

export const StyledDrawingsSheetsBreadcrumbDiv = styled.div`
  margin-left: 20px;
  //top: 325px;
  //left: 25px;
  //position: absolute;
`;

export const StyledDrawingsSheetsBreadcrumbText = styled(Typography)`
  && {
    font-family: "IBM Plex Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0.05em;
  }
`;

export const StyledDrawingsSheetsBreadcrumbLink = styled.link`
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  cursor: pointer;
`;

export const StyledDrawingsSheetsDialogNamePageDivContainer = styled.div`
  && {
    display: flex;
    flex-direction: column;
  }
`;

export const StyledDrawingsSheetsDialogNamePageDiv = styled.div`
  && {
    display: flex;
    flex-direction: row;
    width: 100%;
  }
`;

export const StyledDrawingsSheetsDialogText = styled(TextField)`
  && {
    width: 100%;
    height: 50px;
  }
`;

export const StyledDrawingsSheetsDialogSelectMenu = styled(Select)`
  && {
    width: 100px;
    height: 55px;
  }
`;

export const StyledDrawingsSheetsDialogButton = styled(Button)`
  && {
    background-color: grey;
    min-width: 40px;
    max-width: 40px;
    min-height: 30px;
    max-height: 30px;
    margin-right: 5px;
    margin-bottom: 10px;
    margin-top: 10px;
  }
  &.MuiButtonBase-root {
    border-radius: 17px;
  }
  &.MuiButton-root:hover {
    background-color: grey;
  }
`;

export const StyledLoadingScreen = styled(motion.div)`
  height: 100%;
  width: 100%;
  background-color: transparent;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
`;

export const DrawingsVerticalDotButton = styled(Button)`
  && {
    width: 0px;
    min-width: 0px;
    max-width: 0px;
    height: 0px;
    background-color: transparent;
    margin-left: 15px;
    // position: absolute;
    // top: 325px;
    // right: 100px;
  }
  &.MuiButton-root:hover {
    background-color: transparent;
  }
`;

export const DrawingsAddButton = styled(Button)`
  && {
    width: 0px;
    min-width: 0px;
    max-width: 0px;
    height: 0px;
    background-color: transparent;
    margin-left: 135px;
  }
  &.MuiButton-root:hover {
    background-color: transparent;
  }
`;

export const StyledSheetTableRow = styled(motion(TableRow))<{}>`
  && {
    width: 100% !important;

    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    padding-bottom: 1.4rem;
  }
`;
