import React from "react";
import useQAListToggleMenu from "./Hooks/useQAListToggleMenu";
import {
  StyledQAListStatusItem,
  StyledQAListToggleMenu,
} from "./StyledComponentQAProject";
import { AnimatePresence } from "framer-motion";
import QAStatus from "./Content/CommonComponents/QAStatus";
import CircularProgress from "@mui/material/CircularProgress";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import { useProjectQAStore } from "./Store/useProjectQAStore";
import ExportQaPdf from "./Content/CommonComponents/ExportQaPdf";
import ContactFilterToggle from "./Content/CommonComponents/ContactFilterToggle";
import { useShallow } from "zustand/react/shallow";

type Props = {};

const QAListToggleMenu = (props: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    useShallow((state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }))
  );
  const { selectedQAItem, setSelectedQAListStatus, selectedQAListStatus } =
    useProjectQAStore(
      useShallow((state) => ({
        selectedQAItem: state.selectedQAItem,
        setSelectedQAListStatus: state.setSelectedQAListStatus,
        selectedQAListStatus: state.selectedQAListStatus,
      }))
    );

  const {
    qaItemDetail,
    isFetchingQAListDetail,
    isSuccessFetchingQAListDetail,
    isErrorFetchingQAListDetail,
  } = useQAListToggleMenu({});
  if (qaItemDetail && !selectedQAItem)
    return (
      <StyledQAListToggleMenu
        $themeColor={doxleThemeColor}
        initial={{ x: 10, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        exit={{ scale: 0, opacity: 0 }}
        transition={{
          duration: 0.4,
        }}
      >
        {isFetchingQAListDetail && (
          <CircularProgress className="loader-spinner" size="1.4rem" />
        )}
        <ExportQaPdf />
        <ContactFilterToggle />
        <StyledQAListStatusItem
          $selected={selectedQAListStatus === "unattended"}
          style={{ marginRight: 8 }}
          onClick={() => setSelectedQAListStatus("unattended")}
        >
          <QAStatus
            statusType="unattended"
            statusCount={qaItemDetail.unattendedCount}
            marginRight={4}
          />

          <span className="text-status">Unattended</span>
        </StyledQAListStatusItem>

        <StyledQAListStatusItem
          $selected={selectedQAListStatus === "completed"}
          onClick={() => setSelectedQAListStatus("completed")}
        >
          <QAStatus
            statusType="completed"
            statusCount={qaItemDetail.completedCount}
            marginRight={4}
          />
          <span className="text-status">Completed</span>
        </StyledQAListStatusItem>
      </StyledQAListToggleMenu>
    );

  return <></>;
};

export default QAListToggleMenu;
