import { DamagesPeriod, OrderLine, PaymentTermsSuffix } from "./order";

import { v4 as uuid4 } from "uuid";
interface IPOTableHeader {
  headerName: string;
  itemKeyProp: keyof OrderLine;
  display: boolean;
}

export const PAYMENT_TERM_SUFFIX_LIST: PaymentTermsSuffix[] = [
  "Days",
  "NET",
  "EOM",
];

export const ORDER_LINE_TEMPLATE = (orderId: string) => {
  return {
    orderLineId: uuid4(),
    order: orderId,
    index: 0,
    description: "",
    itemCost: 0,
    quantity: 0,
    lineCost: 0,
    unit: "ea",
  } as OrderLine;
};

export const DAMAGE_PERIOD_LIST: DamagesPeriod[] = ["Day", "Week", "Month"];

export const ORDER_DETAIL_TABLE_HEADER_LIST: IPOTableHeader[] = [
  {
    headerName: "DESCRIPTION",
    itemKeyProp: "description",
    display: true,
  },
  {
    headerName: "QTY",
    itemKeyProp: "quantity",
    display: true,
  },
  {
    headerName: "UNIT",
    itemKeyProp: "unit",
    display: true,
  },
  {
    headerName: "UNIT PRICE",
    itemKeyProp: "itemCost",
    display: true,
  },
  {
    headerName: "SUB TOTAL",
    itemKeyProp: "lineCost",
    display: true,
  },
];
