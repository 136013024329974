import * as React from "react"
import { CustomSVGProps } from "../../UpdatedGreetingScreen/pages/svg/mainBanner"

export const UploadFloorPlanIcon = ({ themeColor, ...props }: CustomSVGProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={248}
    height={159}
    fill="none"
    {...props}
  >
    <path
      stroke="#7070EF"
      strokeOpacity={0.5}
      strokeWidth={5}
      d="M2.5 2.5h243v154H2.5z"
    />
    <path
      stroke="#7070EF"
      strokeOpacity={0.5}
      strokeWidth={3}
      d="M245.5 0v157"
    />
    <mask id="a" fill="#fff">
      <path d="M172 0h23v62h-23V0Z" />
    </mask>
    <path
      fill="#7070EF"
      fillOpacity={0.5}
      d="M195 0h2v-2h-2v2Zm0 62v2h2v-2h-2ZM172 2h23v-4h-23v4Zm21-2v62h4V0h-4Zm2 60h-23v4h23v-4Z"
      mask="url(#a)"
    />
    <mask id="b" fill="#fff">
      <path d="M121 0h52v12h-52V0Z" />
    </mask>
    <path
      fill="#7070EF"
      fillOpacity={0.5}
      d="M121 12h-2v2h2v-2Zm52-2h-52v4h52v-4Zm-50 2V0h-4v12h4Z"
      mask="url(#b)"
    />
    <path
      stroke="#7070EF"
      strokeOpacity={0.5}
      strokeWidth={2}
      d="M173 12v50M176 40h16v17h-16z"
    />
    <path fill="#000" d="M188 43h2v11h-2z" />
    <path stroke="#7070EF" strokeOpacity={0.5} strokeWidth={2} d="M125 6h43" />
    <mask id="c" fill="#fff">
      <path d="M195 1h49v11h-49V1Z" />
    </mask>
    <path
      fill="#7070EF"
      fillOpacity={0.5}
      d="M244 11h-49v2h49v-2Z"
      mask="url(#c)"
    />
    <g filter="url(#d)">
      <path
        stroke="#7070EF"
        strokeOpacity={0.5}
        d="M214.5 22c0 3-2.648 5.5-6 5.5s-6-2.5-6-5.5 2.648-5.5 6-5.5 6 2.5 6 5.5Z"
      />
    </g>
    <g filter="url(#e)">
      <path
        stroke="#7070EF"
        strokeOpacity={0.5}
        strokeWidth={2}
        d="M165 29c0 5.666-3.938 10-8.5 10-4.562 0-8.5-4.334-8.5-10s3.938-10 8.5-10c4.562 0 8.5 4.334 8.5 10Z"
      />
    </g>
    <g filter="url(#f)">
      <path
        stroke="#7070EF"
        strokeOpacity={0.5}
        d="M237.5 22c0 3-2.648 5.5-6 5.5s-6-2.5-6-5.5 2.648-5.5 6-5.5 6 2.5 6 5.5Z"
      />
    </g>
    <g filter="url(#g)">
      <path
        stroke="#7070EF"
        strokeOpacity={0.5}
        d="M202.5 108.5c0 2.686-2.605 5-6 5s-6-2.314-6-5 2.605-5 6-5 6 2.314 6 5Z"
      />
    </g>
    <g filter="url(#h)">
      <path
        stroke="#7070EF"
        strokeOpacity={0.5}
        d="M202.5 148c0 3.001-2.648 5.5-6 5.5s-6-2.499-6-5.5 2.648-5.5 6-5.5 6 2.499 6 5.5Z"
      />
    </g>
    <g filter="url(#i)">
      <path
        stroke="#7070EF"
        strokeOpacity={0.5}
        d="M220.5 148c0 3.001-2.648 5.5-6 5.5s-6-2.499-6-5.5 2.648-5.5 6-5.5 6 2.499 6 5.5Z"
      />
    </g>
    <g filter="url(#j)">
      <path
        stroke="#7070EF"
        strokeOpacity={0.5}
        d="M220.5 108.5c0 2.686-2.605 5-6 5s-6-2.314-6-5 2.605-5 6-5 6 2.314 6 5Z"
      />
    </g>
    <g filter="url(#k)">
      <path
        stroke="#7070EF"
        strokeOpacity={0.5}
        d="M129.5 93.5c0 2.686-2.605 5-6 5s-6-2.314-6-5 2.605-5 6-5 6 2.314 6 5Z"
      />
    </g>
    <g filter="url(#l)">
      <path
        stroke="#7070EF"
        strokeOpacity={0.5}
        d="M129.5 109c0 3.001-2.648 5.5-6 5.5s-6-2.499-6-5.5 2.648-5.5 6-5.5 6 2.499 6 5.5Z"
      />
    </g>
    <g filter="url(#m)">
      <path
        stroke="#7070EF"
        strokeOpacity={0.5}
        d="M174.5 109c0 3.001-2.648 5.5-6 5.5s-6-2.499-6-5.5 2.648-5.5 6-5.5 6 2.499 6 5.5Z"
      />
    </g>
    <g filter="url(#n)">
      <path
        stroke="#7070EF"
        strokeOpacity={0.5}
        d="M174.5 93.5c0 2.686-2.605 5-6 5s-6-2.314-6-5 2.605-5 6-5 6 2.314 6 5Z"
      />
    </g>
    <g filter="url(#o)">
      <path
        stroke="#7070EF"
        strokeOpacity={0.5}
        d="M174.5 123.5c0 2.686-2.605 5-6 5s-6-2.314-6-5 2.605-5 6-5 6 2.314 6 5Z"
      />
    </g>
    <g filter="url(#p)">
      <path
        stroke="#7070EF"
        strokeOpacity={0.5}
        d="M129.5 123.5c0 2.686-2.605 5-6 5s-6-2.314-6-5 2.605-5 6-5 6 2.314 6 5Z"
      />
    </g>
    <path
      stroke="#7070EF"
      strokeOpacity={0.5}
      strokeWidth={3}
      d="M107.5 1v67M99.5 0v58M0 56.5h77"
    />
    <path
      stroke="#7070EF"
      strokeOpacity={0.5}
      strokeWidth={2}
      d="M77 58c1.533-12.927 13.305-18.053 19-19M97 39v19"
    />
    <path stroke="#7070EF" strokeOpacity={0.5} d="M96 57.5h2" />
    <mask id="q" fill="#fff">
      <path d="M0 0h49v16H0V0Z" />
    </mask>
    <path
      fill="#7070EF"
      fillOpacity={0.5}
      d="M47 0v16h4V0h-4Z"
      mask="url(#q)"
    />
    <mask id="r" fill="#fff">
      <path d="M0 16h22v42H0V16Z" />
    </mask>
    <path
      fill="#7070EF"
      fillOpacity={0.5}
      d="M20 16v42h4V16h-4Z"
      mask="url(#r)"
    />
    <path
      stroke="#7070EF"
      strokeOpacity={0.5}
      strokeWidth={2}
      d="M22 15h27M25 4h20v9H25z"
    />
    <path
      stroke="#7070EF"
      strokeOpacity={0.5}
      d="M32 11.5h8M36.5 9v3M6.5 20.5h12v13h-12zM5.5 20.5h1v13h-1zM5.5 36.5h1v12h-1z"
    />
    <ellipse cx={9} cy={24} fill="#000" fillOpacity={0.61} rx={1} ry={2} />
    <ellipse cx={13} cy={24} fill="#000" fillOpacity={0.61} rx={1} ry={2} />
    <ellipse
      cx={16.5}
      cy={30.5}
      fill="#000"
      fillOpacity={0.61}
      rx={1.5}
      ry={2.5}
    />
    <ellipse cx={13} cy={30.5} fill="#000" fillOpacity={0.61} rx={1} ry={2.5} />
    <ellipse cx={9} cy={30.5} fill="#000" fillOpacity={0.61} rx={1} ry={2.5} />
    <ellipse
      cx={16.5}
      cy={39.5}
      fill="#000"
      fillOpacity={0.61}
      rx={1.5}
      ry={2.5}
    />
    <ellipse cx={13} cy={39.5} fill="#000" fillOpacity={0.61} rx={1} ry={2.5} />
    <ellipse cx={9} cy={39.5} fill="#000" fillOpacity={0.61} rx={1} ry={2.5} />
    <ellipse cx={16.5} cy={46} fill="#000" fillOpacity={0.61} rx={1.5} ry={2} />
    <ellipse cx={13} cy={46} fill="#000" fillOpacity={0.61} rx={1} ry={2} />
    <ellipse cx={9} cy={46} fill="#000" fillOpacity={0.61} rx={1} ry={2} />
    <ellipse cx={16.5} cy={24} fill="#000" fillOpacity={0.61} rx={1.5} ry={2} />
    <path stroke="#7070EF" strokeOpacity={0.5} d="M6.5 36.5h12v12h-12z" />
    <path fill="#000" fillOpacity={0.76} d="M26 28h5v14h-5z" />
    <path
      stroke="#7070EF"
      strokeOpacity={0.5}
      strokeWidth={2}
      d="M0 83h96M97 84v37M97 136v20"
    />
    <path
      stroke="#7070EF"
      strokeOpacity={0.5}
      d="M96 121.5H81M81 121c1.523 10.982 4.62 13.32 15 15"
    />
    <mask id="s" fill="#fff">
      <path d="M19 84h30v8H19v-8Z" />
    </mask>
    <path
      fill="#7070EF"
      fillOpacity={0.5}
      d="M47 84v8h4v-8h-4Zm-26 8v-8h-4v8h4Z"
      mask="url(#s)"
    />
    <mask id="t" fill="#fff">
      <path d="M44 92.5c0 1.773-.259 3.528-.761 5.166-.503 1.638-1.24 3.126-2.168 4.38-.928 1.254-2.03 2.248-3.244 2.926C36.614 105.651 35.313 106 34 106c-1.313 0-2.614-.349-3.827-1.028-1.213-.678-2.315-1.672-3.244-2.926-.929-1.254-1.665-2.742-2.168-4.38A17.684 17.684 0 0 1 24 92.5h20Z" />
    </mask>
    <path
      stroke="#7070EF"
      strokeOpacity={0.5}
      strokeWidth={4}
      d="M44 92.5c0 1.773-.259 3.528-.761 5.166-.503 1.638-1.24 3.126-2.168 4.38-.928 1.254-2.03 2.248-3.244 2.926C36.614 105.651 35.313 106 34 106c-1.313 0-2.614-.349-3.827-1.028-1.213-.678-2.315-1.672-3.244-2.926-.929-1.254-1.665-2.742-2.168-4.38A17.684 17.684 0 0 1 24 92.5h20Z"
      mask="url(#t)"
    />
    <path
      stroke="#7070EF"
      strokeOpacity={0.5}
      strokeWidth={2}
      d="M49 93h-6M19 92h6"
    />
    <mask id="u" fill="#fff">
      <path d="M2 117h22v31H2v-31Z" />
    </mask>
    <path fill="#D9D9D9" fillOpacity={0.03} d="M2 117h22v31H2v-31Z" />
    <path
      fill="#7070EF"
      fillOpacity={0.5}
      d="M24 117h2v-2h-2v2Zm0 31v2h2v-2h-2ZM2 119h22v-4H2v4Zm20-2v31h4v-31h-4Zm2 29H2v4h22v-4Z"
      mask="url(#u)"
    />
    <path
      stroke="#7070EF"
      strokeOpacity={0.5}
      strokeWidth={2}
      d="M20 126v14M19 133h-3"
    />
    <path
      stroke="#7070EF"
      strokeOpacity={0.5}
      d="M57.5 88c0 1.311-1.268 2.5-3 2.5s-3-1.189-3-2.5 1.268-2.5 3-2.5 3 1.189 3 2.5ZM134.5 84.5h25v47h-25zM188.5 82.5h36v18h-36zM188.5 121.5h36v18h-36z"
    />
    <defs>
      <filter
        id="d"
        width={13}
        height={16}
        x={202}
        y={16}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={4} />
        <feGaussianBlur stdDeviation={2} />
        <feComposite in2="hardAlpha" k2={-1} k3={1} operator="arithmetic" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend in2="shape" result="effect1_innerShadow_1442_787" />
      </filter>
      <filter
        id="e"
        width={19}
        height={26}
        x={147}
        y={18}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={4} />
        <feGaussianBlur stdDeviation={2} />
        <feComposite in2="hardAlpha" k2={-1} k3={1} operator="arithmetic" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend in2="shape" result="effect1_innerShadow_1442_787" />
      </filter>
      <filter
        id="f"
        width={13}
        height={16}
        x={225}
        y={16}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={4} />
        <feGaussianBlur stdDeviation={2} />
        <feComposite in2="hardAlpha" k2={-1} k3={1} operator="arithmetic" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend in2="shape" result="effect1_innerShadow_1442_787" />
      </filter>
      <filter
        id="g"
        width={13}
        height={15}
        x={190}
        y={103}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={4} />
        <feGaussianBlur stdDeviation={2} />
        <feComposite in2="hardAlpha" k2={-1} k3={1} operator="arithmetic" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend in2="shape" result="effect1_innerShadow_1442_787" />
      </filter>
      <filter
        id="h"
        width={13}
        height={16}
        x={190}
        y={142}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={4} />
        <feGaussianBlur stdDeviation={2} />
        <feComposite in2="hardAlpha" k2={-1} k3={1} operator="arithmetic" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend in2="shape" result="effect1_innerShadow_1442_787" />
      </filter>
      <filter
        id="i"
        width={13}
        height={16}
        x={208}
        y={142}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={4} />
        <feGaussianBlur stdDeviation={2} />
        <feComposite in2="hardAlpha" k2={-1} k3={1} operator="arithmetic" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend in2="shape" result="effect1_innerShadow_1442_787" />
      </filter>
      <filter
        id="j"
        width={13}
        height={15}
        x={208}
        y={103}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={4} />
        <feGaussianBlur stdDeviation={2} />
        <feComposite in2="hardAlpha" k2={-1} k3={1} operator="arithmetic" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend in2="shape" result="effect1_innerShadow_1442_787" />
      </filter>
      <filter
        id="k"
        width={13}
        height={15}
        x={117}
        y={88}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={4} />
        <feGaussianBlur stdDeviation={2} />
        <feComposite in2="hardAlpha" k2={-1} k3={1} operator="arithmetic" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend in2="shape" result="effect1_innerShadow_1442_787" />
      </filter>
      <filter
        id="l"
        width={13}
        height={16}
        x={117}
        y={103}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={4} />
        <feGaussianBlur stdDeviation={2} />
        <feComposite in2="hardAlpha" k2={-1} k3={1} operator="arithmetic" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend in2="shape" result="effect1_innerShadow_1442_787" />
      </filter>
      <filter
        id="m"
        width={13}
        height={16}
        x={162}
        y={103}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={4} />
        <feGaussianBlur stdDeviation={2} />
        <feComposite in2="hardAlpha" k2={-1} k3={1} operator="arithmetic" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend in2="shape" result="effect1_innerShadow_1442_787" />
      </filter>
      <filter
        id="n"
        width={13}
        height={15}
        x={162}
        y={88}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={4} />
        <feGaussianBlur stdDeviation={2} />
        <feComposite in2="hardAlpha" k2={-1} k3={1} operator="arithmetic" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend in2="shape" result="effect1_innerShadow_1442_787" />
      </filter>
      <filter
        id="o"
        width={13}
        height={15}
        x={162}
        y={118}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={4} />
        <feGaussianBlur stdDeviation={2} />
        <feComposite in2="hardAlpha" k2={-1} k3={1} operator="arithmetic" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend in2="shape" result="effect1_innerShadow_1442_787" />
      </filter>
      <filter
        id="p"
        width={13}
        height={15}
        x={117}
        y={118}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={4} />
        <feGaussianBlur stdDeviation={2} />
        <feComposite in2="hardAlpha" k2={-1} k3={1} operator="arithmetic" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend in2="shape" result="effect1_innerShadow_1442_787" />
      </filter>
    </defs>
  </svg>
)
