import {memo, useRef, useState} from "react"
import {shallow} from "zustand/shallow";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import {useEstimatesStore} from "./Store/useEstimatesStore";
import {
  StyledEstimatePageHeaderContainer,
  StyledEstimatePageHeaderSubTitle,
  StyledEstimatePageHeaderTitle, StyledEstimatePageSearchField
} from "./StyledComponents";
import EstimatesTabMenu from "./EstimatesTabMenu";
import {InputAdornment} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";


const EstimatesPageHeader = ()=> {
  const [timer, setTimer] = useState<null|NodeJS.Timeout>(null)
  const themeColor = useDoxleThemeStore(state => state.doxleThemeColor, shallow)
  const font = useDoxleThemeStore(state => state.doxleFont, shallow)
  const searchInputRef: React.MutableRefObject<undefined|HTMLInputElement> = useRef()
  const {
    view,
    setEstimatesQueryFilter,
    isSearching,
    setIsSearching
  } = useEstimatesStore(state => ({
    view: state.view,
    setEstimatesQueryFilter: state.setEstimatesQueryFilter,
    isSearching: state.isSearching,
    setIsSearching: state.setIsSearching
  }), shallow)

  const handleCloseSearch = () => {
    setIsSearching(false)
  }

  const handleSearchChange = () => {
    if (view === "Estimates" && searchInputRef.current) setTimer(setTimeout(()=>{
      setEstimatesQueryFilter({search: searchInputRef?.current?.value})
    }, 400))
    else setTimer(null)
  }

  return (
    <StyledEstimatePageHeaderContainer themeColor={themeColor}>
      <StyledEstimatePageHeaderTitle themeColor={themeColor} font={font}>
        {view}
      </StyledEstimatePageHeaderTitle>
      <StyledEstimatePageHeaderSubTitle themeColor={themeColor} font={font}>
        {view === "Estimates"
          ? "A Estimate is anyone you know, someone in your team, a client, contractor or advisor." +
          "Keep Track of their contact details and permissions here so you can share information with them later"
          : "A Company is the businesses you deal with, link them to your accounts to make sharing purchase orders," +
          "defects or quote request a breeze."}
      </StyledEstimatePageHeaderSubTitle>
      {
        isSearching
          ?
            <StyledEstimatePageSearchField
              inputRef={searchInputRef}
              fullWidth
              placeholder={"Search..."}
              onChange={handleSearchChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    style={{ cursor: "pointer" }}
                    position="end"
                    onClick={handleCloseSearch}
                  >
                    <CloseIcon
                      htmlColor={themeColor.primaryFontColor}
                      sx={{
                        "&.MuiSvgIcon-root": {
                          margin: `0px 0px 0px 2px !important`,
                        },
                      }}
                    />
                  </InputAdornment>
                ),
              }}
            />
          :
          <EstimatesTabMenu />
      }
    </StyledEstimatePageHeaderContainer>
  )
}

export default EstimatesPageHeader