import React from "react";
import {
  StyledFilterQRLogDialog,
  StyledFilterQRLogDialogContent,
  StyledFilterQRLogDialogTitle,
  StyledQRLogFilterBtn,
} from "./StyledQRLogFilter";
import { LuListFilter } from "react-icons/lu";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import useQRLogFilter from "./Hooks/useQRLogFilter";
import DoxleCloseBtn from "../../../DoxleDesignPattern/DoxleButtons/DoxleCloseBtn";
import { IoClose } from "react-icons/io5";
import QRLogFilterUser from "./QRLogFilterUser";
import QRLogFilterDate from "./QRLogFilterDate";

type Props = {};

const QRLogFilter = (props: Props) => {
  const { doxleThemeColor, staticMenuColor } = useDoxleThemeStore();
  const {
    setShowFilterDialog,
    showFilterDialog,
    contentAnimateVariants,
    showDatePicker,
    setShowDatePicker,
  } = useQRLogFilter();
  return (
    <>
      <StyledQRLogFilterBtn onClick={() => setShowFilterDialog(true)}>
        <LuListFilter size={25} color={doxleThemeColor.primaryFontColor} />
      </StyledQRLogFilterBtn>

      <StyledFilterQRLogDialog
        open={showFilterDialog}
        onClose={() => setShowFilterDialog(false)}
        slotProps={{
          backdrop: {
            sx: {
              bgcolor: staticMenuColor.staticBackdrop,
            },
          },
        }}
      >
        <StyledFilterQRLogDialogTitle>
          <div className="title-wrapper">Access Log Filter</div>

          <DoxleCloseBtn
            size={"medium"}
            sx={{
              padding: "2px !important",
            }}
            closeIcon={
              <IoClose color={doxleThemeColor.primaryFontColor} size={30} />
            }
          />
        </StyledFilterQRLogDialogTitle>

        <StyledFilterQRLogDialogContent
          variants={contentAnimateVariants}
          initial="initial"
          animate="animate"
        >
          <QRLogFilterUser />

          <QRLogFilterDate
            setShowDatePicker={setShowDatePicker}
            showDatePicker={showDatePicker}
          />
        </StyledFilterQRLogDialogContent>
      </StyledFilterQRLogDialog>
    </>
  );
};

export default QRLogFilter;
