import React, { useCallback, useEffect, useState } from "react";
import { useDoxleCurrentContextStore } from "../../../DoxleGeneralStore/useDoxleCurrentContext";
import { shallow } from "zustand/shallow";
import { Project } from "../../../Models/project";
import { useShallow } from "zustand/react/shallow";
import { useIsFetching, useQueryClient } from "@tanstack/react-query";
import { formFullDetailProjectListQKey } from "../../../Services/QueryHooks/projectQueryAPI";

type Props = {};

interface ProjectSelectDropDown {
  projectDropdownAnchor: HTMLElement | null;
  setProjectDropdownAnchor: React.Dispatch<
    React.SetStateAction<HTMLElement | null>
  >;

  handleCloseProjectDropdown: () => void;

  handleSelectProject: (item: Project) => void;
  showProjectList: boolean;
  isFetchingProject: boolean;
  isErrorFetchingProject: boolean;
}
const useProjectSelectDropDown = (props: Props): ProjectSelectDropDown => {
  // Anchor Element for Main Filter Menu Dropdown
  const [projectDropdownAnchor, setProjectDropdownAnchor] =
    React.useState<null | HTMLElement>(null);

  const [showProjectList, setShowProjectList] = useState<boolean>(false);
  const { setCurrentProject, currentCompany } = useDoxleCurrentContextStore(
    useShallow((state) => ({
      setCurrentProject: state.setCurrentProject,
      currentCompany: state.currentCompany,
    }))
  );

  useEffect(() => {
    if (projectDropdownAnchor) {
      const timeout = setTimeout(() => {
        setShowProjectList(true);
      }, 300);

      return () => clearTimeout(timeout);
    } else setShowProjectList(false);
  }, [projectDropdownAnchor]);

  const handleCloseProjectDropdown = () => {
    setProjectDropdownAnchor(null);
  };
  const handleSelectProject = useCallback(
    (item: Project) => {
      setCurrentProject(item);
      setProjectDropdownAnchor(null);
    },
    [setCurrentProject, setProjectDropdownAnchor]
  );
  const queryClient = useQueryClient();
  const projectQKey = formFullDetailProjectListQKey(
    currentCompany,
    {},
    "budget"
  );
  const fetchingProjectState = queryClient.getQueryState(projectQKey, {
    predicate: (query) =>
      projectQKey.every((key) => query.queryKey.includes(key)),
  });
  return {
    projectDropdownAnchor,
    setProjectDropdownAnchor,

    handleCloseProjectDropdown,

    handleSelectProject,
    showProjectList,
    isFetchingProject: fetchingProjectState?.status === "loading" || false,
    isErrorFetchingProject: fetchingProjectState?.status === "error" || false,
  };
};

export default useProjectSelectDropDown;
