import React, { useCallback, useMemo, useRef } from "react";
import useGetNoteLineList from "../../Hooks/useGetNoteLineList";
import DoxleListSkeleton from "../../../DoxleDesignPattern/DoxleSkeleton/DoxleListSkeleton";
import {
  Components,
  ItemContent,
  Virtuoso,
  VirtuosoHandle,
} from "react-virtuoso";
import { NoteLine } from "../../Models/note";
import NoteLineListFooter from "./NoteLineListFooter";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import {
  StyledNoteLineListScroller,
  StyledNoteLineSection,
} from "./StyledEditNote";
import DoxleEmptyPlaceHolder from "../../../DoxleDesignPattern/DoxleEmptyPlaceHolder/DoxleEmptyPlaceHolder";
import { Error404Banner } from "../../../DoxleDesignPattern/DoxleIcons/CommonIcons";
import { EmptyNoteLineBanner } from "../NoteIcons";
import NoteLineItem from "./NoteLineItem";
import useNoteLineSection from "./Hooks/useNoteLineSection";

type Props = {
  noteId: string;
};

interface ListContext {
  isFetchingNoteLineList: boolean;
}
const NoteLineSection = ({ noteId }: Props) => {
  const { doxleThemeColor, doxleFont, transparentMode } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
      transparentMode: state.transparentMode,
    }),
    shallow
  );
  const {} = useNoteLineSection({ noteId });
  const {
    noteLineList,
    isFetchingNoteLineList,
    isSuccessFetchingNoteLineList,
    isErrorFetchingNoteLineList,
    isRefetchingNoteLineList,
    isFetchingMoreNoteLineList,
    refetchNoteLine,
    fetchMoreNoteLine,
  } = useGetNoteLineList({ noteId });
  const listStyle: React.CSSProperties = {
    width: "100%",
    height: "100%",
    display: "flex",
  };
  const lineListRef = useRef<VirtuosoHandle>(null);
  const components: Components<NoteLine, ListContext> = useMemo(
    () => ({
      Scroller: React.forwardRef((props, ref) => (
        <StyledNoteLineListScroller ref={ref} {...props} />
      )),
      Footer: React.forwardRef((props, ref) => (
        <NoteLineListFooter noteId={noteId} />
      )),

      //   EmptyPlaceholder: ({ context, ...rest }) => (
      //     <DoxleEmptyPlaceHolder
      //       headTitleText={
      //         context?.isFetchingNoteLineList ? "Something wrong!" : "Empty Note"
      //       }
      //       subTitleText={
      //         context?.isFetchingNoteLineList
      //           ? "Please try to refresh the page, we are sorry for this!"
      //           : "add more lines for more note details"
      //       }
      //       containerBgColor="transparent"
      //       containerStyle={{
      //         height: "auto",
      //       }}
      //       illustration={
      //         context?.isFetchingNoteLineList ? (
      //           <Error404Banner
      //             themeColor={doxleThemeColor}
      //             containerStyle={{
      //               marginBottom: 20,
      //             }}
      //           />
      //         ) : (
      //           <EmptyNoteLineBanner
      //             themeColor={doxleThemeColor}
      //             containerStyle={{
      //               marginBottom: 20,
      //               width: "30%",
      //             }}
      //           />
      //         )
      //       }
      //     />
      //   ),
    }),
    [doxleThemeColor, doxleFont, transparentMode, noteId]
  );
  const itemContent: ItemContent<NoteLine, ListContext> = useCallback(
    (index, data, context) => {
      return <NoteLineItem lineItem={data} />;
    },
    []
  );
  return (
    <StyledNoteLineSection>
      {isFetchingNoteLineList && (
        <DoxleListSkeleton skeletonType="noteLineRow" />
      )}


      {!isFetchingNoteLineList && (
        <Virtuoso
          ref={lineListRef}
          style={listStyle}
          data={noteLineList}
          context={{ isFetchingNoteLineList }}
          components={components}
          itemContent={itemContent}
        />
      )}
    </StyledNoteLineSection>
  );
};

export default NoteLineSection;
