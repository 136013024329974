import { useCallback } from "react";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import { StyledFullScreenFunctionMenuButton } from "../StyledComponentsEditDocketSideScreen";
import {
  IEditDocketMenuTab,
  useEditDocketSideScreenStore,
} from "../EditDocketSideScreenStore/useEditDocketSideScreenStore";
import { motion } from "framer-motion";

type Props = {
  item: IEditDocketMenuTab;
  menuItemRefs: React.MutableRefObject<any[]>;
  itemIndex: number;
};

const FullScreenFunctionMenuButton = ({
  item,
  menuItemRefs,
  itemIndex,
}: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }),
    shallow
  );
  const { selectedFunctionMenu, setSelectedFunctionMenu } =
    useEditDocketSideScreenStore(
      (state) => ({
        selectedFunctionMenu: state.selectedFunctionMenu,
        setSelectedFunctionMenu: state.setSelectedFunctionMenu,
      }),
      shallow
    );
  const handleClickMenuItem = useCallback(() => {
    setSelectedFunctionMenu(item.tabName);
  }, []);
  return (
    <StyledFullScreenFunctionMenuButton
      $doxleFont={doxleFont}
      $themeColor={doxleThemeColor}
      onClick={handleClickMenuItem}
      $selected={Boolean(selectedFunctionMenu === item.tabName)}
      layout="position"
      transition={{
        duration: 0.2,
        damping: 16,
      }}
      ref={(el) => (menuItemRefs.current[itemIndex] = el)}
    >
      <span>
        {item.tabName === "Detail"
          ? "General"
          : item.tabName === "Scope Of Works"
          ? "Tasks"
          : item.tabName}
      </span>

      {selectedFunctionMenu === item.tabName && (
        <motion.div
          className="selected-effect"
          layoutId="editDocketFunctionMenu"
        />
      )}
    </StyledFullScreenFunctionMenuButton>
  );
};

export default FullScreenFunctionMenuButton;
