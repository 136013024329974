import React from "react";
import {useDoxleThemeStore} from "../../../DoxleGeneralStore/useDoxleThemeStore";
import {shallow} from "zustand/shallow";
import {TableVirtuoso} from "react-virtuoso";
import {Table} from "@mui/material";
import DoxleEmptyPlaceHolder from "../../../DoxleDesignPattern/DoxleEmptyPlaceHolder/DoxleEmptyPlaceHolder";
import QuickEstimateEstimateRow from "./QuickEstimateEstimateRow";
import QuickEstimateTableHeader from "./QuickEstimateTableHeader";
import {
  StyledListScroller
} from "../../../DoxleDesignPattern/ProjectSelectWithDropdown/StyledComponentsProjectSelectWithDropdown";
import {Estimate} from "../../Models/estimatesFilters";
import {Docket} from "../../../Models/dockets";
import QuickEstimateTableRow from "./QuickEstimateTableRow";

interface Props {
  docketList: Docket[];
  estimateList: Estimate[];
  handleFetchNext: () => void;
}

const EstimateTable = ({
  docketList,
  handleFetchNext
}: Props) => {
  const doxleThemeColor = useDoxleThemeStore(state => state.doxleThemeColor, shallow)

  return (
    <TableVirtuoso
      data={docketList}
      style={{
        width: "100%",
        height: "100%",
      }}
      components={{
        Table: (props) => <Table {...props} />,
        EmptyPlaceholder: (props) => (
          <DoxleEmptyPlaceHolder
            {...props}
            headTitleText={"No Contact Companies"}
            subTitleText={"Add a contact to get started"}
            containerHeightRatio="100%"
            containerWidthRatio="100%"
            containerHeightInPixel={`${window.innerHeight - 400}px`}
            addBtn={{
              btnText: "Add",
              btnColor: doxleThemeColor.doxleColor,
              btnHeightInPixel: "50px",
              btnWidthInPixel: "164px",
              btnFunction: () => {console.log("NOTHING HERE YET")}
            }}
          />
        ),
        Scroller: React.forwardRef((props, ref) => (
          <StyledListScroller
            style={{
              ...props.style,
            }}
            ref={ref}
            {...props}
          />
        )),
      }}
      endReached={handleFetchNext}
      fixedHeaderContent={() =>  <QuickEstimateTableHeader /> }
      itemContent={(index, item) => (<QuickEstimateTableRow docket={item} />)}
    />
  );
}

export default React.memo(EstimateTable)