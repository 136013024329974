import React from "react";
import { TransitionProps } from "@mui/material/transitions";
import Grow from "@mui/material/Grow";
import {
  StyledSOWMenuBtn,
  StyledSOWPopoverArrow,
  StyledScopeGroupMenuContainer,
} from "./StyledScopeGroupMenuPopover";
import { AiOutlineUsergroupAdd } from "react-icons/ai";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { DoxleDeleteIcon } from "../../../DoxleDesignPattern/DoxleGeneralIcons";
import useScopeGroupMenuPopover from "./Hooks/useScopeGroupMenuPopover";
import { ScopeOfWorkGroup } from "../../Model/scopeOfWorks";
import {
  bindFocus,
  bindHover,
  bindTrigger,
} from "material-ui-popup-state/hooks";
import NestedDoxleSelectContactDropdown from "../../../DoxleDesignPattern/DoxleSelectContactDropdown/NestedDoxleSelectContactDropdown";
import DoxleSelectContactDropdown from "../../../DoxleDesignPattern/DoxleSelectContactDropdown/DoxleSelectContactDropdown";
import { useScopeOfWorkStore } from "../../Store/useScopeOfWorkStore";
import { shallow } from "zustand/shallow";
type Props = {};

const ScopeGroupMenuPopover = ({}: Props) => {
  const { doxleThemeColor, staticMenuColor } = useDoxleThemeStore((state) => ({
    doxleThemeColor: state.doxleThemeColor,
    staticMenuColor: state.staticMenuColor,
  }));

  const {
    handleUpdateContactAssignee,
    handleUpdateCompanyAssignee,
    handleRemoveContactAssignee,
    handleRemoveCompanyAssignee,
  } = useScopeGroupMenuPopover({});
  const { setFocusedSOWGroup, focusedSOWGroup } = useScopeOfWorkStore(
    (state) => ({
      setFocusedSOWGroup: state.setFocusedSOWGroup,
      focusedSOWGroup: state.focusedSOWGroup,
    }),
    shallow
  );

  if (focusedSOWGroup)
    return (
      <DoxleSelectContactDropdown
        popoverAnchor={focusedSOWGroup.anchorItem}
        onClosePopover={() => setFocusedSOWGroup(undefined)}
        selectedCompanyItemIds={
          focusedSOWGroup.sowItem.assignedContactCompany
            ? [focusedSOWGroup.sowItem.assignedContactCompany]
            : undefined
        }
        selectedContactItemIds={
          focusedSOWGroup.sowItem.assignedContacts ?? undefined
        }
        onSelectContact={handleUpdateContactAssignee}
        onSelectContactCompany={handleUpdateCompanyAssignee}
        closeOnSelect={false}
        onRemoveContact={handleRemoveContactAssignee}
        onRemoveContactCompany={handleRemoveCompanyAssignee}
      />
    );
  return (
    <>
      {/* <StyledSOWPopoverArrow />
      <StyledScopeGroupMenuContainer>
        <StyledSOWMenuBtn {...bindHover(popupState)} {...bindFocus(popupState)}>
          <AiOutlineUsergroupAdd
            color={staticMenuColor.staticFontColor}
            size={22}
          />
        </StyledSOWMenuBtn>
        <StyledSOWMenuBtn onClick={handleClickDeleteBtn}>
          <DoxleDeleteIcon
            themeColor={doxleThemeColor}
            containerStyle={{
              width: 20,
            }}
            iconColor="rgba(255,0,0,1)"
          />
        </StyledSOWMenuBtn>
      </StyledScopeGroupMenuContainer> */}
    </>
  );
};

export default ScopeGroupMenuPopover;
