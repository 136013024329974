import React, { useEffect, useMemo, useState } from "react";
import { useDoxleAuthStore } from "../../../DoxleGeneralStore/useDoxleAuthStore";
import { useDoxleCurrentContextStore } from "../../../DoxleGeneralStore/useDoxleCurrentContext";
import useDoxleNotificationStore from "../../../DoxleGeneralStore/useDoxleNotificationStore";
import ProjectQueryAPI, {
  FilterGetProjectQuery,
} from "../../../Services/QueryHooks/projectQueryAPI";
import { Project } from "../../../Models/project";

type Props = {};

interface ProjectSelectWithDropdown {
  anchorProjectDropdown: HTMLElement | null;
  setAnchorProjectDropdown: React.Dispatch<
    React.SetStateAction<HTMLElement | null>
  >;
  projectList: Project[];
  isFetchingProject: boolean;
  isSuccessFetchingProject: boolean;
  isErrorFetchingProject: boolean;
  isFetchingNextPage: boolean;

  handleOpenDropdown: (event: React.MouseEvent<HTMLDivElement>) => void;
  handleCloseDropdown: () => void;
  showProjectList: boolean;

  setSearchProjectText: React.Dispatch<React.SetStateAction<string>>;
  handleFetchMoreProject: () => void;
}
const useProjectSelectWithDropdown = (
  props: Props
): ProjectSelectWithDropdown => {
  const [searchProjectText, setSearchProjectText] = useState<string>("");

  const [anchorProjectDropdown, setAnchorProjectDropdown] =
    useState<HTMLElement | null>(null);
  const [showProjectList, setShowProjectList] = useState(false);


  const { company } = useDoxleCurrentContextStore((state) => ({
    company: state.currentCompany,
  }));
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification
  );
  const filterProjectListQuery: FilterGetProjectQuery = useMemo(
    () => ({
      searchText: searchProjectText,
      view:
        window.location.host.includes('projects') ? "budget"
          : window.location.host.includes('noticeboard') ? "noticeboard"
            : undefined
    }),
    [searchProjectText]
  );
  const projectQuery = ProjectQueryAPI.useRetrieveFullProjectListQuery({
    enable: true,
    company,

    showNotification,
    filter: filterProjectListQuery,
  });

  const projectList: Project[] = useMemo(
    () =>
      projectQuery.isSuccess
        ? projectQuery.data.pages.reduce((acc, data) => {
            return acc.concat(data.data.results);
          }, [] as Project[])
        : [],
    [projectQuery.data, projectQuery.isSuccess]
  );

  const handleOpenDropdown = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    setAnchorProjectDropdown(event.currentTarget);
  };
  const handleCloseDropdown = () => {
    setAnchorProjectDropdown(null);
    setShowProjectList(false);
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (anchorProjectDropdown) setShowProjectList(true);
    }, 300);

    return () => clearTimeout(timeout);
  }, [anchorProjectDropdown]);

  const handleFetchMoreProject = () => {
    if (projectQuery.hasNextPage) projectQuery.fetchNextPage();
  };
  return {
    anchorProjectDropdown,
    setAnchorProjectDropdown,
    projectList,
    isFetchingProject: projectQuery.isLoading,
    isSuccessFetchingProject: projectQuery.isSuccess,
    isErrorFetchingProject: projectQuery.isError,
    isFetchingNextPage: projectQuery.isFetchingNextPage,

    handleOpenDropdown,
    handleCloseDropdown,
    showProjectList,
    setSearchProjectText,
    handleFetchMoreProject,
  };
};

export default useProjectSelectWithDropdown;
