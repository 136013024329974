import { StyledAddedFileItem } from "../Content/StyledFilesBodyComponent";
import { checkSupportedFileType } from "../../Utilities/MimeFileType";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import {
  DoxleCSVIcon,
  DoxleExcelIcon,
  DoxlePDFIcon,
  DoxleWordIcon,
} from "../../DoxleDesignPattern/DoxleGeneralIcons";
import { DoxleTextIcon } from "../../CoreContent/DoxleTopMenu/DoxleTopMenuIcon";
import { IoCloseCircleOutline, IoVideocamOutline } from "react-icons/io5";

type Props = {
  file: File;
  handleRemoveFile: (index: number) => void;
  index: number;
};

const AddedFileItem = ({ file, handleRemoveFile, index }: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore((state) => ({
    doxleThemeColor: state.doxleThemeColor,
    doxleFont: state.doxleFont,
  }));
  const supportedFileType = checkSupportedFileType({ type: file.type });
  const imgUrl = URL.createObjectURL(file);
  return (
    <StyledAddedFileItem layout>
      {supportedFileType === "pdf" ? (
        <DoxlePDFIcon
          themeColor={doxleThemeColor}
          containerStyle={{
            width: 18,
          }}
        />
      ) : supportedFileType === "doc" ? (
        <DoxleWordIcon
          themeColor={doxleThemeColor}
          containerStyle={{
            width: 18,
          }}
        />
      ) : supportedFileType === "xls" ? (
        <DoxleExcelIcon
          themeColor={doxleThemeColor}
          containerStyle={{
            width: 18,
          }}
        />
      ) : supportedFileType === "csv" ? (
        <DoxleCSVIcon
          themeColor={doxleThemeColor}
          containerStyle={{
            width: 18,
          }}
        />
      ) : supportedFileType === "text" ? (
        <DoxleTextIcon
          themeColor={doxleThemeColor}
          containerStyle={{
            width: 18,
          }}
        />
      ) : supportedFileType === "video" ? (
        <IoVideocamOutline color={doxleThemeColor.primaryFontColor} size={18} />
      ) : (
        supportedFileType === "image" && (
          <img className="image-holder" src={imgUrl} />
        )
      )}

      <span className="file-name">{file.name}</span>

      <IoCloseCircleOutline
        color={doxleThemeColor.primaryFontColor}
        size={18}
        className="icon-remove"
        onClick={(e) => handleRemoveFile(index)}
      />
    </StyledAddedFileItem>
  );
};

export default AddedFileItem;
