import DoxleDialogHelper from "../../DoxleDesignPattern/DoxleDialogHelper/DoxleDialogHelper";
import React, { useRef } from "react";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import ProjectQueryAPI from "../../Services/QueryHooks/projectQueryAPI";
import { StyledBackdrop } from "../../Xero/Content/styledComponents";
import Loading from "../../Utilities/Lottie/Loading";
import { Project } from "../../Models/project";
import { useNavigate } from "react-router-dom";
import { ImportDialogOptions } from "./ProjectFilter";

interface Props {
  displayImportDialog: ImportDialogOptions;
  setDisplayImportDialog: React.Dispatch<
    React.SetStateAction<ImportDialogOptions>
  >;
}
const ImportProjectDialog = ({
  displayImportDialog,
  setDisplayImportDialog,
}: Props) => {
  const styleProps = useDoxleThemeStore(
    (state) => ({
      $themeColor: state.doxleThemeColor,
      $doxleFont: state.doxleFont,
      $currentTheme: state.currentTheme,
    }),
    shallow
  );
  const navigate = useNavigate();
  const handleImportProjectSuccess = (result: Project) => {
    setDisplayImportDialog(false);
    if (displayImportDialog) navigate(`/Project/${result.projectId}/`);
  };
  const importProjectQuery = ProjectQueryAPI.useImportProject({
    onSuccessCb: handleImportProjectSuccess,
  });
  const importInputRef = useRef<HTMLInputElement>(null);
  const handleUploadProject = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget.files?.[0]) {
      importProjectQuery.mutate(e.currentTarget.files[0]);
      e.currentTarget.value = "";
    }
  };
  return (
    <>
      <input
        hidden
        type={"file"}
        ref={importInputRef}
        accept={".xlsx"}
        onChange={handleUploadProject}
      />
      {displayImportDialog && importProjectQuery.isLoading ? (
        <StyledBackdrop
          {...styleProps}
          onClick={() => setDisplayImportDialog(false)}
        >
          <Loading
            text={
              "This may take a couple of minutes. Click to close and continue working in the meantime."
            }
          />
        </StyledBackdrop>
      ) : (
        <DoxleDialogHelper
          open={Boolean(displayImportDialog)}
          onClose={() => setDisplayImportDialog(false)}
          title="Import a Project"
          description={`Download the template below, fill it out and re-upload here`}
          dialogButtons={[
            {
              buttonText: "Download Template",
              buttonFunction: () =>
                window.open(
                  "https://storage.doxle.com/Project%20Template.xlsx"
                ),
              buttonColor: styleProps.$themeColor.primaryContainerColor,
              btnStyleSx: {
                border: `1px solid ${styleProps.$themeColor.primaryDividerColor}`,
              },
              buttonTextStyle: {
                color: styleProps.$themeColor.primaryFontColor,
              },
            },
            {
              buttonText: "Upload",
              buttonFunction: () => {
                if (importInputRef?.current) importInputRef?.current.click();
              },
              btnStyleSx: {
                border: `1px solid ${styleProps.$themeColor.primaryDividerColor}`,
              },
            },
          ]}
        />
      )}
    </>
  );
};
export default ImportProjectDialog;
