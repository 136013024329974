import { CircularProgress } from "@mui/material";
import {
  StyledNBFooterContainer,
  StyledNBFooterTextField,
} from "./StyledNoticeBoardContent";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import useNoticeBoardContentBodyFooter from "./Hooks/useNoticeBoardContentBodyFooter";
import { shallow } from "zustand/shallow";
import { AiOutlinePlus } from "react-icons/ai";
import { NB_ROW_SIZE } from "../../InboxStore/useInboxStore";
export interface INoticeBoardContentBodyFooterProps {
  scrollToBottom: () => void;
}

export function NoticeBoardContentBodyFooter({
  scrollToBottom,
}: INoticeBoardContentBodyFooterProps) {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }),
    shallow
  );

  const {
    isAddingdocket,
    handleAddNewDocket,
    handleInputKeyDown,
    handleInputChange,
    newNbTitleText,
    newTitleInputRef,
  } = useNoticeBoardContentBodyFooter({ scrollToBottom });

  return (
    <StyledNBFooterTextField
      $height={NB_ROW_SIZE}
      layout="position"
      transition={{
        duration: 0.2,
        damping: 12,
      }}
      inputRef={newTitleInputRef}
      placeholder="New"
      onChange={handleInputChange}
      value={newNbTitleText}
      onKeyDown={handleInputKeyDown}
      InputProps={{
        startAdornment: isAddingdocket ? (
          <CircularProgress
            sx={{ color: doxleThemeColor.doxleColor, marginRight: "4px" }}
            size={12}
          />
        ) : (
          <AiOutlinePlus
            size={14}
            style={{ marginRight: 4 }}
            color={doxleThemeColor.primaryInputPlaceholderColor}
            onClick={handleAddNewDocket}
          />
        ),
      }}
      $doxleFont={doxleFont}
      $themeColor={doxleThemeColor}
      multiline
    />
  );
}
