import React, { useState } from "react";
import { Contact } from "../../../Models/addressBook";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";

import { AnimatePresence, easeInOut, motion } from "framer-motion";
import { IoCloseCircleSharp } from "react-icons/io5";
import { StyledSelectedContactItem } from "./StyledEmailOrder";
import { Tooltip } from "@mui/material";
import { editRgbaAlpha } from "../../../Utilities/FunctionUtilities";
type Props = {
  item: Contact;
  onRemoveItem?: (item: Contact) => void;
};

const SelectedContactItem = ({ item, onRemoveItem }: Props) => {
  const [onHover, setOnHover] = useState(false);
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }),
    shallow
  );
  return (
    <Tooltip
      PopperProps={{
        disablePortal: true,
        sx: {
          "& .MuiTooltip-tooltip": {
            fontFamily: doxleFont.primaryFont,
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "max(1.2rem,12px)",

            color: `${editRgbaAlpha({
              rgbaColor: doxleThemeColor.primaryFontColor,
              alpha: "0.8",
            })} !important`,
            textTransform: " capitalize",
            backgroundColor: `${doxleThemeColor.primaryTextFieldBackgroundColor} !important`,
            border: `1px solid ${doxleThemeColor.primaryDividerColor} !important`,
            borderRadius: "4px",

            padding: "4px 8px",
          },
        },
      }}
      placement="bottom-end"
      title={item.email || "No email"}
    >
      <StyledSelectedContactItem
        $themeColor={doxleThemeColor}
        $doxleFont={doxleFont}
        onMouseEnter={() => setOnHover(true)}
        onMouseLeave={() => setOnHover(false)}
        animate={{
          x: [-5, 0],
          opacity: [0, 1],
        }}
        transition={{
          ease: easeInOut,
          damping: 16,
        }}
        layout
        exit={{
          x: [0, -5],
          opacity: [1, 0],
        }}
      >
        <div className="info-wrapper">
          {item.firstName} {item.lastName}
        </div>

        <AnimatePresence>
          {onRemoveItem && (
            <IoCloseCircleSharp
              size="18px"
              color={doxleThemeColor.primaryFontColor}
              onClick={(e) => {
                e.stopPropagation();
                onRemoveItem(item);
              }}
              style={{
                marginLeft: 4,
                cursor: "pointer",
              }}
            />
          )}
        </AnimatePresence>
      </StyledSelectedContactItem>
    </Tooltip>
  );
};

export default SelectedContactItem;
