import {
  StyledAssigneeAndWatcherPicker,
  StyledDocketInfoEditFieldWrapper,
  StyledEditGeneralFieldLabel,
} from "./StyledComponentDocketInfo";
import { useDoxleThemeStore } from "../../../../DoxleGeneralStore/useDoxleThemeStore";
import useAssigneeSection from "./Hooks/useAssigneeSection";
import ContactSelect from "./ContactSelect";
import { useEditDocketSideScreenStore } from "../../EditDocketSideScreenStore/useEditDocketSideScreenStore";
import { shallow } from "zustand/shallow";
import { AnimatePresence } from "framer-motion";
import DoxleFilterTag from "../../../../DoxleDesignPattern/DoxleFilterTag/DoxleFilterTag";
import DoxleSelectContactDropdown from "../../../../DoxleDesignPattern/DoxleSelectContactDropdown/DoxleSelectContactDropdown";

import { IoChevronDownOutline } from "react-icons/io5";
type Props = {};

const AssigneeSection = (props: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore((state) => ({
    doxleThemeColor: state.doxleThemeColor,
    doxleFont: state.doxleFont,
  }));
  const {
    handleUpdateAssignee,
    handleOpenAssigneeList,
    handleCloseAssigneeList,
    anchorAssigneeList,
  } = useAssigneeSection({});
  const { edittedDocket } = useEditDocketSideScreenStore(
    (state) => ({
      edittedDocket: state.edittedDocket,
    }),
    shallow
  );
  return (
    <StyledDocketInfoEditFieldWrapper
      layout="position"
      transition={{
        duration: 0.2,
        damping: 16,
      }}
    >
      <StyledEditGeneralFieldLabel
        $doxleFont={doxleFont}
        $themeColor={doxleThemeColor}
      >
        Assignee
      </StyledEditGeneralFieldLabel>
      {/* <ContactSelect
        onSelectContact={(item) => handleUpdateAssignee(item)}
        labelText="Select Assignee"
        selectedValue={
          edittedDocket?.ballInCourt ? [edittedDocket?.ballInCourt] : []
        }
        disableCloseOnSelect={false}
      /> */}

      <StyledAssigneeAndWatcherPicker
        $doxleFont={doxleFont}
        $themeColor={doxleThemeColor}
        onClick={handleOpenAssigneeList}
      >
        Select Assignee
        <IoChevronDownOutline
          className="icon-dropdown"
          color={doxleThemeColor.primaryFontColor}
          size={14}
        />
      </StyledAssigneeAndWatcherPicker>
      <DoxleSelectContactDropdown
        popoverAnchor={anchorAssigneeList}
        onClosePopover={handleCloseAssigneeList}
        variants="contact"
        selectedContactItemIds={
          edittedDocket && edittedDocket.ballInCourt
            ? [edittedDocket.ballInCourt]
            : undefined
        }
        onSelectContact={handleUpdateAssignee}
        containerStyle={{
          borderRadius: 9,
        }}
      />
      <AnimatePresence>
        {edittedDocket?.ballInCourt && (
          <DoxleFilterTag
            itemData={edittedDocket.ballInCourt}
            itemDisplayText={edittedDocket.ballInCourtName}
            removeItemFunction={(item: string) => {
              handleUpdateAssignee(null);
            }}
            tagStyle={{
              marginTop: "14px",
            }}
          />
        )}
      </AnimatePresence>
    </StyledDocketInfoEditFieldWrapper>
  );
};

export default AssigneeSection;
