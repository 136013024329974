import React from "react";
import useAddQuoteContractor from "./Hooks/useAddQuoteContractor";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { useAddQuoteResponseContext } from "./AddQuoteResponse";
import {
  AddQuoteResponseAutocompleteSx,
  StyledAddResponseFieldWrapper,
  StyledAddResponseLabelFieldText,
  StyledErrorText,
  StyledQuoteResponseListItem,
} from "./StyledComponentsAddQuoteResponse";
import { Autocomplete, TextField } from "@mui/material";
import AutocompleteList from "../../../DoxleDesignPattern/DoxleAutoComplete/AutocompleteList";
import { ExpandMore } from "@mui/icons-material";
import { AnimatePresence } from "framer-motion";
type Props = {};

const AddQuoteContractor = (props: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore((state) => ({
    doxleThemeColor: state.doxleThemeColor,
    doxleFont: state.doxleFont,
  }));
  const { newQuoteResponse, contractorWarning } = useAddQuoteResponseContext();
  const {
    searchInput,
    setSearchInput,
    handleSelectContractor,
    contactList,
    isFetchingContractor,
    isErrorFetchingContractor,
  } = useAddQuoteContractor({});
  return (
    <StyledAddResponseFieldWrapper layout>
      <StyledAddResponseLabelFieldText
        $themeColor={doxleThemeColor}
        $doxleFont={doxleFont}
      >
        Contractor
      </StyledAddResponseLabelFieldText>
      <Autocomplete
        fullWidth
        clearIcon
        popupIcon
        autoHighlight
        PaperComponent={AutocompleteList}
        noOptionsText={
          isFetchingContractor
            ? "Loading..."
            : isErrorFetchingContractor
            ? "Error Fetching Contractor Lists..."
            : "No Quote Request Matching"
        }
        style={{
          background: doxleThemeColor.primaryContainerColor,
          borderRadius: "0px 0.9rem 0.9rem 1.2rem",
          color: doxleThemeColor.primaryFontColor,
        }}
        placeholder="Select Contractor"
        options={contactList}
        sx={AddQuoteResponseAutocompleteSx(doxleThemeColor, doxleFont)}
        getOptionLabel={(option) => option.name}
        isOptionEqualToValue={(option, value) =>
          Boolean(newQuoteResponse.supplier === option.contactCompanyId!)
        }
        selectOnFocus
        renderOption={(props, option, state) => (
          <StyledQuoteResponseListItem
            {...props}
            key={option.contactCompanyId}
            $themeColor={doxleThemeColor}
            $doxleFont={doxleFont}
            $selected={state.selected}
          >
            {option.name}
          </StyledQuoteResponseListItem>
        )}
        clearOnBlur
        renderInput={(params) => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  <ExpandMore
                    style={{
                      position: "absolute",
                      right: "10px",
                      width: "20px",
                      height: "20px",
                    }}
                    color="disabled"
                  />
                  {params.InputProps.startAdornment}
                </>
              ),
            }}
            onFocus={(e) => e.currentTarget.select()}
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
            placeholder="Select Contractor..."
            sx={{
              fontSize: "1.4rem",
              fontFamily: doxleFont.primaryFont,
              fontWeight: "500",
            }}
          />
        )}
        onChange={(event, value, reason, details) => {
          if (reason === "selectOption" && value) handleSelectContractor(value);
        }}
      />

      <AnimatePresence>
        {contractorWarning && (
          <StyledErrorText
            $doxleFont={doxleFont}
            initial={{ x: -5, opacity: 0 }}
            animate={{
              x: 0,
              opacity: 1,
            }}
            exit={{ x: -5, opacity: 0 }}
          >
            Please select a contractor...
          </StyledErrorText>
        )}
      </AnimatePresence>
    </StyledAddResponseFieldWrapper>
  );
};

export default AddQuoteContractor;
