import { useDoxleCurrentContextStore } from "../../../../DoxleGeneralStore/useDoxleCurrentContext";
import { shallow } from "zustand/shallow";
import { useDoxleAuthStore } from "../../../../DoxleGeneralStore/useDoxleAuthStore";
import OrdersQueryAPI from "../../../QueryHooks/OrdersQueryAPI";
import { useEffect, useMemo, useState } from "react";
import { useEditOrderStore } from "../../../OrdersStore/useEditOrderStore";
import { EditOrderContextValue } from "../../EditOrder/EditOrder";
import Order from "../../../Models/order";
import { Company } from "../../../../Models/company";
import {
  IDoxleError,
  doxleAPIErrorConverter,
} from "../../../../Models/doxleError";

interface ISharedOrder {
  order: Order | undefined;
  company: Company | undefined;
  isUpdatingOrder: boolean;
  setShowAcceptOrderDialog: (value: "accept" | "reject" | undefined) => void;
  contextValue: EditOrderContextValue;
  getOrderQueryError: IDoxleError | undefined;
}
const useSharedOrder = (linkId: string | undefined): ISharedOrder => {
  const [updatedOrder, setUpdatedOrder] = useState<
    Omit<Partial<Order>, "orderId"> | undefined
  >(undefined);
  const [isOrderLineUpdated, setIsOrderLineUpdated] = useState<boolean>(false);
  const setCurrentCompany = useDoxleCurrentContextStore(
    (state) => state.setCurrentCompany,
    shallow
  );
  const setUser = useDoxleAuthStore((state) => state.setUser, shallow);
  const sharedOrderQuery = OrdersQueryAPI.useSharedOrderLink({ linkId });
  const { setOrder, setReadOnly, setShowAcceptOrderDialog } = useEditOrderStore(
    (state) => ({
      setReadOnly: state.setReadOnly,
      setOrder: state.setEditedOrder,
      setShowAcceptOrderDialog: state.setShowAcceptOrderDialog,
    }),
    shallow
  );
  const getOrderQueryError = useMemo(
    () =>
      sharedOrderQuery.error
        ? doxleAPIErrorConverter(sharedOrderQuery.error)
        : undefined,
    [sharedOrderQuery.error]
  );

  const { order, user, company } = useMemo(() => {
    if (sharedOrderQuery.isSuccess && sharedOrderQuery.data.data)
      return {
        order: sharedOrderQuery.data.data.order,
        user: sharedOrderQuery.data.data.user,
        company: sharedOrderQuery.data.data.company,
      };
    else
      return {
        order: undefined,
        user: undefined,
        company: undefined,
      };
  }, [sharedOrderQuery.data, sharedOrderQuery.isSuccess]);
  useEffect(() => {
    console.log("sharedOrderQuery.data:", sharedOrderQuery.data);
  }, [sharedOrderQuery.data]);

  useEffect(() => {
    if (order) {
      setOrder(order);
      setUpdatedOrder(order);
      setReadOnly(true);
    }
  }, [order]);
  useEffect(() => {
    if (company) setCurrentCompany(company);
  }, [company]);
  useEffect(() => {
    if (user) setUser(user);
  }, [user]);

  const contextValue: EditOrderContextValue = useMemo(
    () => ({
      updatedOrder: updatedOrder,
      setUpdatedOrder: setUpdatedOrder,
      isFetchingOrderDetail: sharedOrderQuery.isFetching,
      isErrorFetchingOrderDetail: sharedOrderQuery.isError,
      isOrderLineUpdated,
      setIsOrderLineUpdated,
    }),
    [order, sharedOrderQuery.isError, sharedOrderQuery.isFetching]
  );

  return {
    order,
    company,
    isUpdatingOrder: false,
    setShowAcceptOrderDialog,
    contextValue,
    getOrderQueryError,
  };
};

export default useSharedOrder;
