import {memo, useEffect, useMemo} from "react";
import {shallow} from "zustand/shallow";
import {useEstimatesStore} from "../Store/useEstimatesStore";
import {Docket} from "../../../Models/dockets";
import {Virtuoso} from "react-virtuoso";
import QuickEstimateEstimateRow from "./QuickEstimateEstimateRow";
import QuickEstimateDocketRow from "./QuickEstimateDocketRow";
import {UseInfiniteQueryResult, useQueryClient} from "@tanstack/react-query";
import {AxiosResponse} from "axios";
import {Estimate} from "../../Models/estimatesFilters";

interface Props {
  docket: Docket
}

const QuickEstimateTableRow = ({
                                 docket
                               }: Props) => {
  const {filter} = useEstimatesStore(state=>({
    filter: state.estimatesQueryFilter,
    estimateList: state.estimatesList
  }), shallow)
  const qKey = ['estimates-list']
  for (const [key, value] of Object.entries(filter)) {
    qKey.push(value)
  }
  const queryClient = useQueryClient()
  const queryData: any = queryClient.getQueryData(qKey)
  console.log('queryData', queryData)
  const filteredEstimates : Estimate[] = useMemo(
    () => (queryData?.pages
        ? queryData?.pages.flatMap((page: any) => page?.data?.results ?? [])
      ?? []
      : []).filter((estimate: Estimate)=> estimate.docket === docket.docketPk)
    ,
    [queryData]
  )

  // const filteredEstimates =  useMemo(
  //   ()=> estimateList.filter(estimate=> estimate.docket === docket.docketPk),
  //   [docket.docketPk, estimateList]
  // )

  const calculateListHeight = useMemo(()=>{
    return (45 * filteredEstimates.length) + 0.5
  }, [filteredEstimates.length])


  return (
    <>
      <QuickEstimateDocketRow docket={docket}/>
      <Virtuoso
          style={{ height: calculateListHeight, width: "100%" }}
          data={filteredEstimates}
          itemContent={(index, item) => (
            <QuickEstimateEstimateRow estimate={item} docket={docket} />
          )}
      />
      <QuickEstimateEstimateRow estimate={null} docket={docket} />
    </>
  );
};

export default memo(QuickEstimateTableRow)