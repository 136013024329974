import { RefObject, useEffect, useState } from "react";
import { useResizeDetector } from "react-resize-detector";
import { produce } from "immer";
type Props = {
  componentRef: RefObject<HTMLElement>;
};

interface RefComponentDimension {
  width: number;
  height: number;
  isOverflow: boolean;
}
const useRefComponentDimension = ({
  componentRef,
}: Props): RefComponentDimension => {
  const [componentDimension, setComponentDimension] =
    useState<RefComponentDimension>({
      width: 0,
      height: 0,
      isOverflow: false,
    });

  const { width: wrapperWidth, height } = useResizeDetector({
    handleHeight: true,
    handleWidth: true,
    refreshMode: "throttle",
    refreshRate: 100,

    onResize: (width, height) => {
      if (width && height)
        setComponentDimension(
          produce((draft) => {
            draft.width = width;
            draft.height = height;

            return draft;
          })
        );
    },
    targetRef: componentRef,
  });
  return componentDimension;
};

export default useRefComponentDimension;
