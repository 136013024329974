import React, { useEffect, useRef, useState } from "react";
import { useNoteStore } from "../../../Store/useNoteStore";
import { shallow } from "zustand/shallow";
import { useDoxleCurrentContextStore } from "../../../../DoxleGeneralStore/useDoxleCurrentContext";
import useDoxleNotificationStore from "../../../../DoxleGeneralStore/useDoxleNotificationStore";
import { useEditDocketSideScreenStore } from "../../../../CoreContent/EditDocketSideScreen/EditDocketSideScreenStore/useEditDocketSideScreenStore";
import { NoteQueryAPI } from "../../../QueryAPI/notesQueryAPI";

type Props = {};

interface IEditNote {
  handleBackBtnClick: () => void;
  newNoteTitle: string;
  setNewNoteTitle: React.Dispatch<React.SetStateAction<string>>;
  handleKeydownTitleTextField: (
    event: React.KeyboardEvent<HTMLDivElement>
  ) => void;
  handleUpdateNote: () => void;
  titleInputRef: React.RefObject<HTMLInputElement>;
}
const useEditNote = (props: Props): IEditNote => {
  const { filterNoteQuery, setEdittedNote, edittedNote } = useNoteStore(
    (state) => ({
      filterNoteQuery: state.filterNoteQuery,
      setEdittedNote: state.setEdittedNote,
      edittedNote: state.edittedNote,
    }),
    shallow
  );
  const [newNoteTitle, setNewNoteTitle] = useState(edittedNote?.title ?? "");
  useEffect(() => {
    setNewNoteTitle(edittedNote?.title ?? "");
  }, [edittedNote]);

  const titleInputRef = useRef<HTMLInputElement>(null);
  const { company, currentProject } = useDoxleCurrentContextStore(
    (state) => ({
      company: state.currentCompany,
      currentProject: state.currentProject,
    }),
    shallow
  );
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification
  );

  const { update } = NoteQueryAPI.useMutateNoteQuery({
    company,
    filter: filterNoteQuery,
    showNotification,
  });

  const handleUpdateNote = () => {
    if (edittedNote) {
      if (newNoteTitle && newNoteTitle !== edittedNote.title) {
        update.mutate({
          noteId: edittedNote.noteId,
          title: newNoteTitle,
        });
      } else if (!newNoteTitle) {
        setNewNoteTitle("Untitled Note");
        update.mutate({
          noteId: edittedNote.noteId,
          title: "Untitled Note",
        });
      }
    }
  };
  const handleKeydownTitleTextField = (
    event: React.KeyboardEvent<HTMLDivElement>
  ) => {
    if (event.nativeEvent.key === "Enter") {
      event.preventDefault();

      handleUpdateNote();
      titleInputRef.current?.blur();
    }

    if (event.nativeEvent.key === "Escape") {
      event.preventDefault();

      titleInputRef.current?.blur();
    }
  };

  const handleBackBtnClick = () => {
    setEdittedNote(undefined);
  };

  return {
    handleBackBtnClick,
    newNoteTitle,
    setNewNoteTitle,
    handleKeydownTitleTextField,
    handleUpdateNote,
    titleInputRef,
  };
};

export default useEditNote;
