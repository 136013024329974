import { useEffect, useMemo } from "react";
import { useDoxleCurrentContextStore } from "../../DoxleGeneralStore/useDoxleCurrentContext";
import { shallow } from "zustand/shallow";
import useDoxleNotificationStore from "../../DoxleGeneralStore/useDoxleNotificationStore";
import { usePricebookStore } from "../Store/usePricebookStore";
import PricebookQueryAPI from "../QueryHooks/PricebookQueryAPI";
import { PricebookSupplierRate } from "../Models/Pricebook";
import { useShallow } from "zustand/react/shallow";
import {
  IPricebookItemGroupInfo,
  IPricebookRateGroup,
} from "../Models/PricebookTable";
import { groupBy } from "lodash";

type Props = {};

interface IGetRateList {
  rateList: PricebookSupplierRate[];
  isFetchingRateList: boolean;
  isSuccessFetchingRateList: boolean;
  isErrorFetchingRateList: boolean;
  totalPage: number;
  totalItem: number;
  removeQueryRateList: () => void;
}
const useGetRateList = (props: Props): IGetRateList => {
  const { company } = useDoxleCurrentContextStore(
    useShallow((state) => ({
      company: state.currentCompany,
    }))
  );
  const showNotification = useDoxleNotificationStore(
    useShallow((state) => state.showNotification)
  );
  const { pricebookRateQueryFilter } = usePricebookStore(
    useShallow((state) => ({
      pricebookRateQueryFilter: state.pricebookRateQueryFilter,
    }))
  );

  const rateListQuery = PricebookQueryAPI.useRetrieveSupplierRateList({
    company,
    showNotification,
    filter: pricebookRateQueryFilter,
  });
  const rateList: PricebookSupplierRate[] = useMemo(
    () => rateListQuery.data?.data.results ?? [],
    [rateListQuery.data]
  );

  const totalPage = useMemo(
    () => rateListQuery.data?.data.pages ?? 0,
    [rateListQuery.data]
  );
  const totalItem = useMemo(
    () => (rateListQuery.isSuccess ? rateListQuery.data.data.count : 0),
    [rateListQuery.data]
  );
  const removeQueryRateList = () => {
    rateListQuery.remove();
  };

  return {
    rateList,
    isFetchingRateList: rateListQuery.isLoading,
    isSuccessFetchingRateList: rateListQuery.isSuccess,
    isErrorFetchingRateList: rateListQuery.isError,
    totalPage,
    totalItem,
    removeQueryRateList,
  };
};

export default useGetRateList;
