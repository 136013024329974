interface Props {
  errorTitle?: string;
  errorSubTitle?: string;
  errorType?: 401|403|404|500|undefined
}
const DoxleErrorScreen = () => {
  // TODO
  return (
    <></>
  )
};
export default DoxleErrorScreen;
