import React, { useState } from "react";

type Props = {};
interface AddMailCCSection {
  handleClickContact: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  anchorContactListPopover: HTMLDivElement | null;
  handleCloseContactList: () => void;
}
const useAddMailCCSection = (props: Props): AddMailCCSection => {
  const [anchorContactListPopover, setAnchorContactListPopover] =
    useState<HTMLDivElement | null>(null);

  const handleClickContact = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    setAnchorContactListPopover(e.currentTarget);
  };
  const handleCloseContactList = () => {
    setAnchorContactListPopover(null);
  };

  return {
    handleClickContact,
    anchorContactListPopover,
    handleCloseContactList,
  };
};

export default useAddMailCCSection;
