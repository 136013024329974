import styled from "styled-components";

import { motion } from "framer-motion";

import { FormControlLabel, MenuItem, Switch, TextField } from "@mui/material";
import {
  DoxleFont,
  DoxleThemeColor,
} from "../../DoxleGeneralStore/useDoxleThemeStore";
import { TRgbaFormat, editRgbaAlpha } from "../../Utilities/FunctionUtilities";
export const StyledInboxContainer = styled.div<{
  $themeColor: DoxleThemeColor;
}>`
  width: calc(100% - 160px);
  height: 100%;
  background-color: ${(p) => p.$themeColor.primaryBackgroundColor};
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 0px 80px;
  @media (max-width: 767px) {
    width: calc(100% - 200px);
    padding: 0px 100px;
  }
  @media (min-width: 768px) {
    width: calc(100% - 240px);
    padding: 0px 120px;
  }
  @media (min-width: 1024px) {
    width: calc(100% - 280px);
    padding: 0px 140px;
  }
  @media (min-width: 1280px) {
    padding: 0px 150px;
    width: calc(100% - 300px);
  }
`;

export const StyledInboxBodySectionContainer = styled.div<{
  $themeColor: DoxleThemeColor;
}>`
  height: calc(100% - 14px);
  display: flex;
  flex-direction: column;
  padding: 0px 0px 14px 0px;
  background-color: ${(p) => p.$themeColor.primaryBackgroundColor};
  // background-color: blue;
  overflow: visible;
  position: relative;
  width: 100%;
  min-width: 500px;
  max-width: 1280px;
`;
export const StyledInboxGeneralInfoSectionContainer = styled(motion.div)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-bottom: 1px solid ${(p) => p.$themeColor.primaryDividerColor};
  height: 80px;
  padding-top: 30px;
  width: 100%;
  padding-bottom: 4px;
`;
export const StyledInboxUserTitle = styled(motion.span)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  color: ${(p) => p.$themeColor.primaryFontColor};
  font-size: 2.7rem;
  font-family: ${(p) => p.$doxleFont.primaryFont};
  font-style: normal;
  font-weight: 700;
  line-height: 118.9%;
  letter-spacing: -0.54px;
  max-width: 350px;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
export const StyledFilterDisplaySection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  overflow: visible;
  margin: 0px 14px;

  padding: 0px !important;
  align-items: center;
  justify-content: center;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;
export const StyledInboxFilterTagContainer = styled(motion.div)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  position: relative;
  padding: 4px 8px;
  margin: 0px 8px;
  color: ${(p) => p.$themeColor.primaryFontColor};
  font-size: 12px;
  font-family: ${(p) => p.$doxleFont.primaryFont};
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.6px;
  text-transform: capitalize;
  border-radius: 6px;
  background-color: ${(p) => p.$themeColor.hoverColor};
  overflow-y: visible !important;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const StyledInboxGroupedRowDataContainer = styled(motion.div)`
  width: 100%;
  display: flex;

  flex-direction: column;
  padding-bottom: 14px;
  overflow: visible;
`;
export const StyledProjectSiteAddressHeaderText = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  color: ${(p) =>
    editRgbaAlpha({
      rgbaColor: p.$themeColor.primaryFontColor as TRgbaFormat,
      alpha: "0.8",
    })};
  font-size: 12px;
  font-family: ${(p) => p.$doxleFont.primaryFont};
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.6px;
  margin-bottom: 2px;
  text-transform: capitalize;
  width: calc(100% - 50px);
  text-align: left;
  height: 20px;
  padding-left: 50px;
`;
export const StyledInboxDocketRow = styled(motion.div)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
  $isCompact: boolean;
}>`
  width: calc(100% - 2px);
  display: flex;
  flex-direction: row;
  align-items: center;

  margin-bottom: ${(p) => (p.$isCompact ? 1 : 9)}px;

  height: ${(p) => (p.$isCompact ? 36 : 45)}px;
  cursor: pointer;
  border-radius: ${(p) =>
    p.$isCompact ? "0px 4px 4px 4px" : "0px 9px 9px 9px"};
  overflow: visible;
  transition: 0.4s;
  border: 1px solid ${(p) => p.$themeColor.rowBorderColor};
`;

export const StyledDocketStatus = styled(motion.div)<{ $statusColor: string }>`
  width: 12px;
  height: 12px;
  border-radius: 6px;
  border: 2.2px solid ${(p) => p.$statusColor};
  cursor: pointer;
  &:hover {
    transition: 0.4s;
    transform: rotateZ(180deg);
  }
`;
export const StyledDocketDescription = styled(motion.div)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  flex: 1;
  margin-left: 14px;
  color: ${(p) => p.$themeColor.primaryFontColor};
  font-size: 12px;
  font-family: ${(p) => p.$doxleFont.primaryFont};
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0.6px;
  display: inline-block;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  text-align: left;

  text-transform: capitalize;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
export const StyledCompressViewSwitchContainer = styled.div`
  margin: 0px 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const StyledCompressViewText = styled(motion.span)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
  checked: boolean;
}>`
  font-size: 14px;
  font-family: ${(p) => p.$doxleFont.primaryFont};
  font-style: normal;
  font-weight: 500;
  line-height: 118.9%;
  letter-spacing: -0.28px;
  margin-left: 4px;
  color: ${(p) =>
    p.checked
      ? p.$themeColor.doxleColor
      : p.$themeColor.primaryInputPlaceholderColor};
`;
// export const StyledCompressViewSwitch = MUIStyled(Switch)<{
//   $themeColor: DoxleThemeColor;
// }>(({ theme, $themeColor }) => ({
//   width: 28,
//   height: 16,
//   padding: 0,
//   display: "flex",
//   "&:active": {
//     "& .MuiSwitch-thumb": {
//       width: 15,
//       backgroundColor: $themeColor.primaryFontColor,
//     },
//     "& .MuiSwitch-switchBase.Mui-checked": {
//       transform: "translateX(9px)",
//     },
//   },
//   "& .MuiSwitch-switchBase": {
//     padding: 2,
//     "&.Mui-checked": {
//       transform: "translateX(12px)",
//       color: "#fff",
//       "& + .MuiSwitch-track": {
//         opacity: 1,
//         backgroundColor: $themeColor.doxleColor,
//       },
//     },
//   },
//   "& .MuiSwitch-thumb": {
//     boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
//     width: 12,
//     height: 12,
//     borderRadius: 6,
//     transition: theme.transitions.create(["width"], {
//       duration: 200,
//     }),
//   },
//   "& .MuiSwitch-track": {
//     borderRadius: 16 / 2,
//     opacity: 1,
//     backgroundColor: $themeColor.primaryInputPlaceholderColor,
//     boxSizing: "border-box",
//   },
// }));
export const StyledAddDocketTextField = styled(motion(TextField))<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  && {
    margin-top: 4px;
  }
  input {
    color: ${(p) => p.$themeColor.primaryFontColor};
    font-size: 14px;
    font-family: ${(p) => p.$doxleFont.primaryTitleFont};
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.65px;
    &::placeholder {
      color: ${(p) => p.$themeColor.bolderDividerColor};
      font-size: 14px;
      font-family: ${(p) => p.$doxleFont.primaryFont};
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.65px;
      padding: 0px !important;
    }
  }
  & .MuiOutlinedInput-root {
    padding: 0px 10px 0px 38px !important;
  }
  & .MuiOutlinedInput-input {
    padding: 0px !important;
  }
  & .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }
`;
export const StyledListScroller = styled.div`
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;
export const StyledDocketRowHoverMenu = styled(motion.div)`
  display: flex;
  flex-direction: row;
  width: 80px;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  margin-right: 2px;
`;

export const StyledInboxSearchSectionContainer = styled.div<{
  $themeColor: DoxleThemeColor;
}>`
  background-color: ${(p) => p.$themeColor.primaryTextFieldBackgroundColor};
  width: 30%;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 0.4rem;
  padding: 4px 8px;
  min-width: 144px;
`;
export const StyledInboxSearchTextField = styled(TextField)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  && {
    flex: 1;
  }
  input {
    color: ${(p) => p.$themeColor.primaryFontColor};
    font-size: 1.4rem;
    font-family: ${(p) => p.$doxleFont.primaryTitleFont};
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.65px;
    &::placeholder {
      color: ${(p) => p.$themeColor.bolderDividerColor};
      font-size: 1.4rem;
      font-family: ${(p) => p.$doxleFont.primaryFont};
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      padding: 0px !important;
    }
  }
  & .MuiOutlinedInput-root {
    padding: 0px !important;
  }
  & .MuiOutlinedInput-input {
    padding: 0px !important;
  }
  & .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }
`;
export const StyledInboxMenuCheckboxFormControl = styled(FormControlLabel)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  && {
  }
  & .MuiFormControlLabel-label {
    color: ${(p) => p.$themeColor.primaryFontColor};
    font-family: ${(p) => p.$doxleFont.subTitleFont};
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
  }
`;

export const StyledInboxFilterMenuItem = styled(MenuItem)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
  $fontColor?: string;
  $fontWeight?: string;
  $marginTop?: string;
  $padding?: string;
}>`
  && {
    border-radius: 3px;
    padding: 4px 8px !important;
    margin: 8px 2px !important;
    color: ${(p) =>
      p.$fontColor ? p.$fontColor : p.$themeColor.primaryFontColor};
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-size: 1.5rem;
    font-style: normal;
    font-weight: ${(p) => (p.$fontWeight ? p.$fontWeight : 500)};
    line-height: normal;
    letter-spacing: -0.28px;
    width: calc(100% - 4px);
  }
`;
export const StyledInboxFilterTitleText = styled.span<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  color: ${(p) => p.$themeColor.primaryFontColor};
  font-size: 18px;
  font-family: ${(p) => p.$doxleFont.primaryTitleFont};
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-left: 10px;
`;

export const StyledInboxProjectTabContainer = styled(motion.div)`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
`;