import { DoxleThemeColor } from "../../DoxleGeneralStore/useDoxleThemeStore";
import { ISVGResponsiveCustom } from "../DoxleIcons/CommonIcons";

export const FilterRemoveIcon = ({
  themeColor,
  containerStyle,
  ...props
}: ISVGResponsiveCustom) => (
  <div
    style={{ width: 11, ...containerStyle, aspectRatio: 1, display: "flex" }}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 11 11"
      fill="none"
      {...props}
    >
      <path
        fill={themeColor.primaryFontColor}
        d="M5.5 0a5.5 5.5 0 1 1 0 11 5.5 5.5 0 0 1 0-11ZM3.818 3.332l-.028-.025a.344.344 0 0 0-.43 0l-.028.025-.025.028c-.101.125-.101.305 0 .43l.025.028L5.014 5.5 3.332 7.182l-.025.028c-.101.125-.101.305 0 .43l.025.028.028.025c.125.101.305.101.43 0l.028-.025L5.5 5.986l1.682 1.682.028.025c.125.101.305.101.43 0l.028-.025.025-.028c.101-.125.101-.305 0-.43l-.025-.028L5.986 5.5l1.682-1.682.025-.028c.101-.125.101-.305 0-.43l-.025-.028-.028-.025a.344.344 0 0 0-.43 0l-.028.025L5.5 5.014 3.818 3.332l-.028-.025.028.025Z"
      />
    </svg>
  </div>
);
