import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import React, { useEffect, useRef, useState } from "react";
import {
  StyledProcessingLoaderWrapper,
  StyledQAMarkupContentSection,
  StyledQAMarkupDialog,
  StyledQAMarkupDialogContent,
  StyledQAMarkupTextField,
  StyledSaveQAMarkupButton,
} from "./StyledComponentQAMarkup";
import { useDoxleThemeStore } from "../../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import { useProjectQAStore } from "../../Store/useProjectQAStore";
import { editRgbaAlpha } from "../../../../Utilities/FunctionUtilities";
import DoxleCloseBtn from "../../../../DoxleDesignPattern/DoxleButtons/DoxleCloseBtn";
import { Layer, Stage } from "react-konva";
import useQAMarkup, { TEXT_FIELD_WIDTH } from "./Hooks/useQAMarkup";

import { useQAMarkupStore } from "./Store/useQAMarkupStore";
import QAMarkupToolBar from "./QAMarkupToolBar";
import QAMarkupShape from "./QAMarkupShape";
import Konva from "konva";
import ImageCustom from "./ImageCustom";
import { AnimatePresence } from "framer-motion";
import LinearProgress from "@mui/material/LinearProgress";
type Props = {};

const QAMarkup = (props: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }),
    shallow
  );
  const { stageState, currentShape, addedMarkups, oldMarkup } =
    useQAMarkupStore(
      (state) => ({
        stageState: state.stageState,

        currentShape: state.currentShape,
        addedMarkups: state.addedMarkups,
        oldMarkup: state.oldMarkup,
      }),
      shallow
    );
  const { markupQAImage, setMarkupQAImage } = useProjectQAStore(
    (state) => ({
      markupQAImage: state.markupQAImage,
      setMarkupQAImage: state.setMarkupQAImage,
    }),
    shallow
  );
  const openDialog = Boolean(markupQAImage);

  const {
    isDragging,
    setIsDragging,
    wrapperRef,
    layoutStage,
    handleMouseUp,
    handleMouseMove,
    handleMouseWheel,
    stageRef,
    shouldShowSaveBtn,
    isSavingMarkup,
    onSaveAnnotationImage,
  } = useQAMarkup({});

  return (
    <StyledQAMarkupDialog
      TransitionComponent={Transition}
      $themeColor={doxleThemeColor}
      open={openDialog}
      onClose={() => {
        setMarkupQAImage(undefined);
      }}
      PaperProps={{
        sx: {
          bgColor: doxleThemeColor.primaryContainerColor,
          borderRadius: "0.9rem",
        },
        elevation: 4,
      }}
      slotProps={{
        backdrop: {
          sx: {
            bgcolor: editRgbaAlpha({
              rgbaColor: doxleThemeColor.primaryBackgroundColor,
              alpha: "0.4",
            }),
          },
        },
      }}
      aria-describedby="share-qa-pdf-dialog"
    >
      <StyledQAMarkupDialogContent $themeColor={doxleThemeColor}>
        {isSavingMarkup && (
          <StyledProcessingLoaderWrapper
            $themeColor={doxleThemeColor}
            $doxleFont={doxleFont}
          >
            <LinearProgress
              className="loader-bar"
              sx={{
                "&.MuiLinearProgress-root": {
                  backgroundColor: editRgbaAlpha({
                    rgbaColor: doxleThemeColor.doxleColor,
                    alpha: "0.3",
                  }),
                },
                "& .MuiLinearProgress-bar": {
                  backgroundColor: doxleThemeColor.doxleColor,
                },
                width: "50% !important",
              }}
            />
            <span className="loader-text">Uploading Image...</span>
          </StyledProcessingLoaderWrapper>
        )}
        <AnimatePresence>
          {shouldShowSaveBtn && (
            <StyledSaveQAMarkupButton
              $themeColor={doxleThemeColor}
              $doxleFont={doxleFont}
              initial={{ scale: 0, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0, opacity: 0 }}
              whileHover={{
                scale: 1.1,
              }}
              whileTap={{
                scale: 1,
              }}
              onClick={onSaveAnnotationImage}
            >
              Save
            </StyledSaveQAMarkupButton>
          )}
        </AnimatePresence>
        <DoxleCloseBtn
          onClick={() => {
            setMarkupQAImage(undefined);
          }}
          sx={{
            width: "4rem",
            height: "4rem",
            borderRadius: "50%",
            padding: "0.2rem !important",
            position: "absolute",
            top: "2rem",
            right: "2rem",
            zIndex: 4,
          }}
        />
        <StyledQAMarkupContentSection
          $themeColor={doxleThemeColor}
          ref={wrapperRef}
        >
          {markupQAImage && (
            <Stage
              ref={stageRef}
              className="stage-markup"
              style={{ cursor: "crosshair" }}
              width={layoutStage.width}
              height={layoutStage.height}
              onWheel={handleMouseWheel}
              onMouseUp={handleMouseUp}
              onMouseMove={handleMouseMove}
              scaleX={stageState.stageScale}
              scaleY={stageState.stageScale}
              x={stageState.stageX}
              y={stageState.stageY}
              draggable
              onDragStart={(e: any) => {
                setIsDragging(true);
              }}
              onDragEnd={() => {
                /*setDraggable(false);*/
                setIsDragging(false);
              }}
            >
              <Layer>
                {markupQAImage.qaImage.url && (
                  <ImageCustom
                    src={markupQAImage.qaImage.url}
                    height={markupQAImage.qaImage.imageHeight}
                    width={markupQAImage.qaImage.imageWidth}
                  />
                )}
              </Layer>
              {oldMarkup.length > 0 && (
                <Layer>
                  {oldMarkup.map((item, idx) => (
                    <QAMarkupShape
                      shape={item}
                      key={`addedMarkup#${item.type}#${idx}`}
                    />
                  ))}
                </Layer>
              )}
              <Layer>
                {addedMarkups.length > 0 &&
                  addedMarkups.map((item, idx) => (
                    <QAMarkupShape
                      shape={item}
                      key={`addedMarkup#${item.type}#${idx}`}
                    />
                  ))}
              </Layer>
              <Layer>
                {currentShape &&
                  (currentShape.type !== "Text" ? (
                    <QAMarkupShape shape={currentShape} />
                  ) : (
                    <></>
                  ))}
              </Layer>
            </Stage>
          )}
        </StyledQAMarkupContentSection>
        <QAMarkupToolBar />
      </StyledQAMarkupDialogContent>
    </StyledQAMarkupDialog>
  );
};

export default QAMarkup;
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
