import { ClickAwayListener, IconButton } from "@mui/material";
import { AnimatePresence, Variants, motion } from "framer-motion";
import React, { useState, MouseEvent, useRef } from "react";
import DownloadIcon from '@mui/icons-material/Download';
import {
  StyledDividerLine,
  StyledDropdownListItemContainer,
  StyledFilterDropdownContainer,
  StyledFilterDropdownListItem,
  StyledIconContainer,
  StyledIconSpan,
  StyledToastContainer,
} from "./StyledProjectComponents";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import { useNavigate } from "react-router-dom";
import { useDoxleCurrentContextStore } from "../../DoxleGeneralStore/useDoxleCurrentContext";
import ConfirmDeleteDialog from "../AddCompanyScreen/DeleteCompanyDialog";
import ProjectQueryAPI from "../../Services/QueryHooks/projectQueryAPI";
import useDoxleNotificationStore from "../../DoxleGeneralStore/useDoxleNotificationStore";
import { useProjectStore } from "../../DoxleGeneralStore/useprojectStore";
import {
  StyledDeleteProjectIcon,
  StyledDownloadExcelIcon,
  StyledDownloadPDFIcon,
  StyledDropDownEllipsis,
  StyledEditProjectIcon,
  StyledInviteIcon,
  StyledXeroSettingsIcon,
} from "./ProjectIcon";
import { IoIosAddCircleOutline } from "react-icons/io";
import { Slide, toast } from "react-toastify";
import DownloadNotification from "../../DoxleDesignPattern/Notification/DownloadNotification";
import {
  DoxleExcelIcon,
  DoxlePDFIcon,
} from "../../DoxleDesignPattern/DoxleGeneralIcons";
import ImportExportDialogDisplayer from "./ImportExportDialogDisplayer";

interface Coordinates {
  x: number;
  y: number;
}

type Props = {};
const AnimatedIconButton = motion(IconButton);

const PROJECT_MENU_OPTIONS: string[] = [
  "Export Project",
  "Import Excel",
  "Add Project",
  "Edit Project",
  "Delete Project",
  "Map Accounts",
  "Accounting Basis",
  "Invite to Doxle",
];
type ProjectMenuOptions = (typeof PROJECT_MENU_OPTIONS)[number];
export type ImportDialogOptions = 'import'|'export'|false
const ProjectFilter = (props: Props) => {
  const [displayDeleteDialog, setDisplayDeleteDialog] =
    useState<boolean>(false);
  const [displayImportDialog, setDisplayImportDialog] =
    useState<ImportDialogOptions>(false);
  const [coords, setCoords] = useState<null | Coordinates>(null);
  const { showNotification, showDownloadResourceNotification } =
    useDoxleNotificationStore(
      (state) => ({
        showDownloadResourceNotification:
          state.showDownloadResourceNotification,
        showNotification: state.showNotification,
      }),
      shallow
    );
  const { projectList, accountingBasis, toggleAccountingBasis, setProjectTab } =
    useProjectStore(
      (state) => ({
        projectList: state.projectList,
        accountingBasis: state.accountingBasis,
        toggleAccountingBasis: state.toggleAccountingBasis,
        setProjectTab: state.setSelectedProjectTab,
      }),
      shallow
    );
  const { company, currentProject, setCurrentProject } =
    useDoxleCurrentContextStore(
      (state) => ({
        currentProject: state.currentProject,
        setCurrentProject: state.setCurrentProject,
        company: state.currentCompany,
      }),
      shallow
    );
  const projectId = currentProject?.projectId;
  const styleProps = useDoxleThemeStore(
    (state) => ({
      $themeColor: state.doxleThemeColor,
      $doxleFont: state.doxleFont,
      $currentTheme: state.currentTheme,
    }),
    shallow
  );
  const navigate = useNavigate();

  const handleIconClick = (e: React.MouseEvent) => {
    // console.log("e", e);
    setCoords({ x: e.clientX, y: e.clientY });
  };
  const onProjectDeleted = () => {
    setDisplayDeleteDialog(false);
    setCurrentProject(
      projectList.filter(
        (project) => project.projectId !== currentProject?.projectId
      )?.[0]
    );
    navigate("/");
  };
  const deleteProjectQuery = ProjectQueryAPI.useDeleteProjectQuery({
    company,
    filter: {},
    onSuccessCb: onProjectDeleted,
  });
  const handleDeleteProject = (deleteProjectId: string) => {
    deleteProjectQuery.mutate(deleteProjectId);
  };

  const toastId = React.useRef<string | number | null>(null);
  const toastContainerRef = React.useRef(null);

  const handleDismissToast = () => {
    if (toastId.current) {
      toast.dismiss(toastId.current);
    }
  };
  interface IShowNotiProps {
    message: string;
    status: "success" | "error" | "loading";
    extraContent?: string;
    duration?: number;
  }
  const showDownloadNoti = (
    message: string,
    status: "success" | "error" | "loading",
    extraContent?: string,
    duration?: number
  ) => {
    // if(toastId.current){
    //   toast.update(toastId.current, {autoClose: 3000 ,render: <DownloadNotification msgTitle={message} msgContent={extraContent} status={status} />});
    //   toastId.current = null;
    // }else{
    //   toastId.current = null;
    //   toastId.current = toast(
    //     <DownloadNotification msgTitle={message} msgContent={extraContent} status={status} />,

    //     {

    //     }
    //   ) as any;

    if (!toastId.current) {
      toastId.current = toast<IShowNotiProps>(
        ({ data, closeToast = handleDismissToast }) => (
          <DownloadNotification
            msgTitle={data?.message ?? ""}
            msgContent={data?.extraContent ?? ""}
            status={data?.status ?? "loading"}
            onClick={closeToast}
          />
        ),
        {
          data: { message, extraContent, status, duration },
          autoClose: 8000,
        }
      );
    } else {
      toast.update(toastId.current, {
        data: { message, extraContent, status, duration },
        render: ({ data, closeToast = handleDismissToast }) => (
          <DownloadNotification
            msgTitle={data?.message ?? ""}
            msgContent={data?.extraContent ?? ""}
            status={data?.status ?? "loading"}
            onClick={handleDismissToast}
          />
        ),
        autoClose: 3000,
      });
    }
  };

  const { exportProject } = ProjectQueryAPI.useShareProjectLink({
    company,
    showNotification,
    showDownloadResourceNotification: showDownloadNoti,
  });

  const handleProjectMenuOptionClicked = (e: MouseEvent<HTMLDivElement>) => {
    if (e.currentTarget.innerText === "Share Project" && projectId)
      navigate(`/ShareProject/${projectId}/`);
    else if (e.currentTarget.innerText === "Add Project")
      navigate(`/AddProject/`);
    else if (e.currentTarget.innerText === "Edit Project" && projectId)
      navigate(`/EditProject/${projectId}/`);
    else if (e.currentTarget.innerText === "Delete Project")
      setDisplayDeleteDialog(true);
    else if (e.currentTarget.innerText.includes("Accounting Basis"))
      toggleAccountingBasis();
    else if (e.currentTarget.innerText.includes("Import Excel"))
      setDisplayImportDialog('import');
    else if (e.currentTarget.innerText.includes("Map Accounts"))
      setProjectTab("Accounts");
    else if (e.currentTarget.innerText === "Export Project") {
      setDisplayImportDialog('export');
    }

    setCoords(null);
  };

  const getProjectMenuOptionIcon = (option: string) => {
    switch (option) {
      case "Export Project": {
        return <DownloadIcon style={{color: styleProps.$themeColor.primaryFontColor, height: 20}}/>
      }
      case "Download PDF": {
        return <DoxlePDFIcon themeColor={styleProps.$themeColor} />;
      }
      case "Download Excel": {
        return <img src={"/assets/icons/excel.png"} height={16} />;
      }
      case "Import Excel": {
        return <StyledDownloadExcelIcon {...styleProps.$themeColor} />;
      }
      case "Add Project": {
        return (
          <IoIosAddCircleOutline
            style={{
              fontSize: 19,
              ...(styleProps.$currentTheme === "dark"
                ? { color: "white" }
                : {}),
            }}
          />
        );
      }
      case "Edit Project": {
        return <StyledEditProjectIcon {...styleProps.$themeColor} />;
      }
      case "Delete Project": {
        return <StyledDeleteProjectIcon {...styleProps.$themeColor} />;
      }
      case "Map Accounts": {
        return <StyledXeroSettingsIcon {...styleProps.$themeColor} />;
      }
      case "Accounting Basis": {
        return (
          <StyledIconSpan {...styleProps}>
            {accountingBasis.substring(0, 4)}
          </StyledIconSpan>
        );
      }
      case "Invite to Doxle": {
        return <StyledInviteIcon {...styleProps.$themeColor} />;
      }
    }
  };
  // useEffect(() => {
  //   console.log("coords", coords);
  // }, [coords]);

  const popoverAnimatedVariants: Variants = {
    entering: {
      y: [-10, 0],
      opacity: [0, 1],
    },
    exiting: {
      y: [0, -10],
      opacity: [1, 0],
    },
  };
  return (
    <>
      <StyledToastContainer
        ref={toastContainerRef}
        position="bottom-right"
        autoClose={false}
        hideProgressBar={true}
        closeButton={false}
        transition={Slide}
      />
      <ImportExportDialogDisplayer
        displayImportDialog={displayImportDialog}
        setDisplayImportDialog={setDisplayImportDialog}
      />
      {displayDeleteDialog && (
        <ConfirmDeleteDialog
          deleteType="Project"
          deleteItemName={currentProject?.siteAddress ?? ""}
          deleteItemId={projectId ?? ""}
          deleteFunction={handleDeleteProject}
          closeDialog={() => setDisplayDeleteDialog(false)}
        />
      )}
      <AnimatedIconButton
        aria-label="more"
        id="long-button"
        layout
        onClick={handleIconClick}
        sx={{
          paddingTop: 0,
          paddingBottom: 0,
        }}
      >
        <StyledDropDownEllipsis {...styleProps.$themeColor} />
      </AnimatedIconButton>
      <AnimatePresence>
        {coords && (
          <ClickAwayListener
            onClickAway={(event) => {
              event.stopPropagation();
              setCoords(null);
              // setAnchorEl(null);
            }}
          >
            <StyledFilterDropdownContainer
              {...styleProps}
              $coordinates={coords}
              variants={popoverAnimatedVariants}
              animate="entering"
              exit="exiting"
              initial="initial"
              transition={{
                duration: 0.2,
                damping: 16,
              }}
            >
              {PROJECT_MENU_OPTIONS.map((option: ProjectMenuOptions, idx) => (
                <div key={idx}>
                  <StyledDropdownListItemContainer
                    {...styleProps}
                    onClick={handleProjectMenuOptionClicked}
                  >
                    <StyledIconContainer>
                      {getProjectMenuOptionIcon(option)}
                    </StyledIconContainer>
                    <StyledFilterDropdownListItem {...styleProps}>
                      {option}
                    </StyledFilterDropdownListItem>
                  </StyledDropdownListItemContainer>
                  {option === "Delete Project" && (
                    <StyledDividerLine {...styleProps} />
                  )}
                </div>
              ))}
            </StyledFilterDropdownContainer>
          </ClickAwayListener>
        )}
      </AnimatePresence>
    </>
  );
};

export default ProjectFilter;
