import React, { useMemo, useState } from "react";
import {
  QuoteRequest,
  QuoteResponse,
  QuoteStorage,
} from "../../../models/quote";
import dayjs from "dayjs";
import { produce } from "immer";
import { formatTISODate } from "../../../../Utilities/FunctionUtilities";

import { shallow } from "zustand/shallow";
import { useDoxleCurrentContextStore } from "../../../../DoxleGeneralStore/useDoxleCurrentContext";
import QuotesQueryAPI, {
  AddQuoteRequestStorageParams,
  AddQuoteRequestStorageResult,
  UpdateQuoteRequestParams,
  getQRequestMutationKey,
  getQStorageMutationKey,
} from "../../../QueryAPI/quotesQuery";
import { useDoxleQuoteStore } from "../../../store/useDoxleQuoteStore";
import useSetQuoteRequestQueryData from "../../../CustomQueryHooks/useSetQuoteRequestQueryData";
import { useIsMutating } from "@tanstack/react-query";
import { ScopeOfWorkGroup } from "../../../../ScopeOfWorks/Model/scopeOfWorks";

type Props = { reviewedQuoteRequest: QuoteRequest };

interface ReviewQuoteRequest {
  edittedQuoteRequest: QuoteRequest;
  setEdittedQuoteRequest: React.Dispatch<React.SetStateAction<QuoteRequest>>;
  handleUpdateDueDate: (value: dayjs.Dayjs | null) => void;
  handleEmailBodyChange: (value: string) => void;
  addedAttachments: File[];
  setAddedAttachments: React.Dispatch<React.SetStateAction<File[]>>;
  handleRemoveScopeGroup: (item: ScopeOfWorkGroup) => void;
  deletedQuoteAttachment: QuoteStorage | undefined;
  setDeletedQuoteAttachment: React.Dispatch<
    React.SetStateAction<QuoteStorage | undefined>
  >;
  isQuoteChanged: boolean;
  handleClickUpdateBtn: () => void;
  handleClickCancelButton: () => void;
  isUpdatingQRInProgress: boolean;
  quoteResponseDetail: QuoteResponse | undefined;
  showQSDialog: boolean;
  setshowQSDialog: React.Dispatch<React.SetStateAction<boolean>>;
}
const useReviewQuoteRequest = ({
  reviewedQuoteRequest,
}: Props): ReviewQuoteRequest => {
  const [edittedQuoteRequest, setEdittedQuoteRequest] = useState<QuoteRequest>({
    ...reviewedQuoteRequest,
  });
  const [addedAttachments, setAddedAttachments] = useState<File[]>([]);
  const [deletedQuoteAttachment, setDeletedQuoteAttachment] = useState<
    QuoteStorage | undefined
  >(undefined);
  const [showQSDialog, setshowQSDialog] = useState(false);
  const handleUpdateDueDate = (value: dayjs.Dayjs | null) => {
    if (value)
      setEdittedQuoteRequest((prev) =>
        produce(prev, (draft) => {
          draft.dueDate = formatTISODate(value.toDate());
          return draft;
        })
      );
  };

  const handleEmailBodyChange = (value: string) => {
    setEdittedQuoteRequest((prev) =>
      produce(prev, (draft) => {
        draft.emailBody = value;
        return draft;
      })
    );
  };

  const handleRemoveScopeGroup = (item: ScopeOfWorkGroup) => {
    setEdittedQuoteRequest((prev) =>
      produce(prev, (draft) => {
        draft.scopeGroups = draft.scopeGroups.filter(
          (group) => group !== item.scopeGroupId
        );
        if (draft.scopeGroupsJson)
          draft.scopeGroupsJson = draft.scopeGroupsJson.filter(
            (group) => group.scopeGroupId !== item.scopeGroupId
          );
        return draft;
      })
    );
  };

  const changesQuoteRequest = checkChanges(
    reviewedQuoteRequest,
    edittedQuoteRequest
  );

  const company = useDoxleCurrentContextStore(
    (state) => state.currentCompany,
    shallow
  );
  const { filterGetQuoteRequest, setView, setReviewedQuoteRequests } =
    useDoxleQuoteStore(
      (state) => ({
        filterGetQuoteRequest: state.filterGetQuoteRequest,
        setView: state.setView,
        setReviewedQuoteRequests: state.setReviewedQuoteRequest,
      }),
      shallow
    );

  const getResponseDetailQuery = QuotesQueryAPI.useRetrieveQuoteResponseDetail({
    company,
    requestId: edittedQuoteRequest.requestId,
    enabled:
      reviewedQuoteRequest.status === "Received" ||
      reviewedQuoteRequest.status === "Accepted",
  });

  const quoteResponseDetail = useMemo(
    () =>
      getResponseDetailQuery.isSuccess
        ? getResponseDetailQuery.data.data.results[0] ?? undefined
        : undefined,
    [getResponseDetailQuery.data]
  );

  const { handleUpdateQuoteRequest, handleUpdatePartialQuoteRequest } =
    useSetQuoteRequestQueryData({
      filter: filterGetQuoteRequest,
    });
  const onSuccessUpdateQuote = (newQuote?: QuoteRequest) => {
    if (newQuote) handleUpdateQuoteRequest(newQuote);
  };
  const updateQuoteRequestQuery = QuotesQueryAPI.useUpdateQuoteRequest({
    onSuccessCb: onSuccessUpdateQuote,
  });

  const onSucessAddCb = (data?: AddQuoteRequestStorageResult) => {
    if (data)
      handleUpdatePartialQuoteRequest(edittedQuoteRequest.requestId, {
        attachments: data.createdFiles,
      });
  };
  const addQuoteStorageQuery = QuotesQueryAPI.useAddQuoteRequestStorage({
    company,

    onSuccessCb: onSucessAddCb,
  });

  const handleClickCancelButton = () => {
    setView("QuoteRequestsTable");
    setReviewedQuoteRequests(undefined);
  };
  const handleClickUpdateBtn = () => {
    if (changesQuoteRequest)
      updateQuoteRequestQuery.mutate({
        company,
        quoteRequestId: edittedQuoteRequest.requestId,
        updateBody: changesQuoteRequest,
      });

    if (addedAttachments.length > 0)
      addQuoteStorageQuery.mutate({
        requestId: edittedQuoteRequest.requestId,
        files: addedAttachments,
      });
    handleClickCancelButton();
  };

  const isUpdatingQuoteRequest =
    useIsMutating({
      mutationKey: getQRequestMutationKey("update"),
      predicate: (query) =>
        (query.state.variables as UpdateQuoteRequestParams).quoteRequestId ===
        edittedQuoteRequest.requestId,
    }) > 0;
  const isAddingQRStorage =
    useIsMutating({
      mutationKey: getQStorageMutationKey("add"),
      predicate: (query) =>
        (query.state.variables as AddQuoteRequestStorageParams).requestId ===
        edittedQuoteRequest.requestId,
    }) > 0;

  const isUpdatingQRInProgress = isUpdatingQuoteRequest || isAddingQRStorage;

  return {
    edittedQuoteRequest,
    setEdittedQuoteRequest,
    handleUpdateDueDate,
    handleEmailBodyChange,
    addedAttachments,
    setAddedAttachments,
    handleRemoveScopeGroup,
    deletedQuoteAttachment,
    setDeletedQuoteAttachment,
    isQuoteChanged: Boolean(changesQuoteRequest || addedAttachments.length > 0),
    handleClickUpdateBtn,
    handleClickCancelButton,
    isUpdatingQRInProgress,
    quoteResponseDetail,
    showQSDialog,
    setshowQSDialog,
  };
};

export default useReviewQuoteRequest;

const checkChanges = (original: QuoteRequest, editted: QuoteRequest) => {
  const updatedBody: Partial<QuoteRequest> = {};
  if (original.supplier !== editted.supplier)
    updatedBody.supplier = editted.supplier;
  if (original.supplierContacts.length !== editted.supplierContacts.length)
    updatedBody.supplierContacts = editted.supplierContacts;
  else {
    let isEqualSupplierContacts = original.supplierContacts.every((supplier) =>
      editted.supplierContacts.includes(supplier)
    );
    if (!isEqualSupplierContacts)
      updatedBody.supplierContacts = editted.supplierContacts;
  }
  if (original.dueDate !== editted.dueDate)
    updatedBody.dueDate = editted.dueDate;
  if (original.emailBody !== editted.emailBody)
    updatedBody.emailBody = editted.emailBody;

  if (original.scopeGroups.length !== editted.scopeGroups.length)
    updatedBody.scopeGroups = editted.scopeGroups;
  else {
    let isEqualGroup = original.scopeGroups.every((supplier) =>
      editted.scopeGroups.includes(supplier)
    );
    if (!isEqualGroup) updatedBody.scopeGroups = editted.scopeGroups;
  }

  if (Object.keys(updatedBody).length > 0) return updatedBody;
  else return undefined;
};
