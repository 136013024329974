import React from "react";

import ScopeItemImageSection from "./ScopeItemImageSection";
import { ClickAwayListener } from "@mui/material";
import { AnimatePresence, motion } from "framer-motion";
import AddScopeItemImageSection from "./AddScopeImage/AddScopeItemImageSection";
import { useScopeItemRow } from "../Hooks/useScopeItemRow";
import { ScopeOfWorkItem } from "../Model/scopeOfWorks";
import { UpdateScopeItemData } from "../QueryAPI/scopeOfWorkQueryAPI";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import { TRgbaFormat, editRgbaAlpha } from "../../Utilities/FunctionUtilities";
import {
  StyledScopeItemDescriptionText,
  StyledScopeItemDescriptionTextField,
  StyledScopeItemRowCheckbox,
  StyledScopeItemRowContainer,
  StyledScopeItemRowDetailSection,
  StyledScopeItemRowHoverMenu,
} from "./StyledComponentScopeOfWorkFullScreen";
import {
  CheckedScopeItemCheckbox,
  UncheckedScopeItemCheckbox,
} from "./ScopeOfWorkFullScreenIcons";
import DoxleIconButton from "../../DoxleDesignPattern/DoxleButtons/DoxleIconButton";
import {
  DoxleCheckedIcon,
  DoxleDeleteIcon,
  DoxleImageGalleryIcon,
  DoxleUncheckedIcon,
} from "../../DoxleDesignPattern/DoxleGeneralIcons";

import { AiOutlineUsergroupAdd } from "react-icons/ai";
import ScopeItemAssigneeDisplay from "./ScopeItemAssigneeDisplay";
import { useScopeOfWorkStore } from "../Store/useScopeOfWorkStore";
import { shallow } from "zustand/shallow";
type Props = {
  scopeItem: ScopeOfWorkItem;
  setDeletedScopeItem: React.Dispatch<
    React.SetStateAction<ScopeOfWorkItem | undefined>
  >;
  handleUpdateScopeItem: (data: UpdateScopeItemData) => void;
  handleToggleSOWItemAssigneePopover: (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    item: ScopeOfWorkItem
  ) => void;
  editedSOWItem: ScopeOfWorkItem | undefined;
};

const ScopeItemRow = ({
  scopeItem,
  setDeletedScopeItem,
  handleUpdateScopeItem,
  handleToggleSOWItemAssigneePopover,
  editedSOWItem,
}: Props) => {
  const { doxleThemeColor, doxleFont, transparentMode, currentTheme } =
    useDoxleThemeStore((state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
      transparentMode: state.transparentMode,
      currentTheme: state.currentTheme,
    }));
  const {
    setShowAddScopeImageSection,

    showAddScopeImageSection,
    handleCheckboxScopeItem,
    handleCloseAddScopeImgSection,
    handleClickAddSpecImageBtn,
    handleClickDeleteButton,

    isScopeItemSelected,

    newItemDescription,
    setNewItemDescription,
    onBlurTextField,
    isFocusTextfield,
    setIsFocusTextfield,
    descriptionRef,
    onKeyDownDescriptionField,
    isGroupExpanded,
    showHoveringMenu,
    setShowHoveringMenu,
  } = useScopeItemRow({
    scopeItem,
    setDeletedScopeItem,
    handleUpdateScopeItem,
  });

  //# Animation

  const detailSectionAnimatedVariants = {
    initial: {
      backgroundColor: editRgbaAlpha({
        rgbaColor: doxleThemeColor.primaryContainerColor,
        alpha: "0",
      }),
      x: 0,
      scaleX: 1,
    },
    addImageMode: {
      backgroundColor: editRgbaAlpha({
        rgbaColor: doxleThemeColor.doxleColor as TRgbaFormat,
        alpha: "0.2",
      }),
      scaleX: 0.95,
      transition: {
        duration: 0.2,
      },
    },
    selected: {
      x: 20,
      backgroundColor: editRgbaAlpha({
        rgbaColor: doxleThemeColor.doxleColor as TRgbaFormat,
        alpha: "0.4",
      }),
      transition: {
        duration: 0.2,
      },
    },
    hovering: {
      backgroundColor: doxleThemeColor.hoverColor,
      transition: {
        duration: 0.2,
      },
    },
  };

  return (
    <ClickAwayListener
      onClickAway={(event) => {
        event.preventDefault();
        event.stopPropagation();
        setShowAddScopeImageSection(false);
      }}
    >
      <StyledScopeItemRowContainer
        layout="position"
        onMouseOver={() => setShowHoveringMenu(true)}
        onMouseLeave={() => setShowHoveringMenu(false)}
        transition={{
          duration: 0.2,
          damping: 12,
        }}
      >
        <StyledScopeItemRowDetailSection
          $themeColor={doxleThemeColor}
          $transparentMode={transparentMode}
          $currentTheme={currentTheme}
          variants={detailSectionAnimatedVariants}
          initial="initial"
          animate={
            showAddScopeImageSection
              ? "addImageMode"
              : isScopeItemSelected
              ? "selected"
              : "initial"
          }
          whileHover={"hovering"}
          // onClick={handleClickScopeItemRow}
        >
          <StyledScopeItemRowCheckbox
            checked={scopeItem.completed}
            icon={
              <DoxleUncheckedIcon
                themeColor={doxleThemeColor}
                containerStyle={{
                  width: 20,
                }}
              />
            }
            checkedIcon={
              <DoxleCheckedIcon
                themeColor={doxleThemeColor}
                containerStyle={{
                  width: 20,
                }}
              />
            }
            onChange={handleCheckboxScopeItem}
          />
          <motion.div className="main-content-wrapper" layout="position">
            {!isFocusTextfield ? (
              <StyledScopeItemDescriptionText
                $themeColor={doxleThemeColor}
                $doxleFont={doxleFont}
                style={
                  scopeItem.completed === true
                    ? { textDecoration: "line-through" }
                    : undefined
                }
                onClick={() => setIsFocusTextfield(true)}
              >
                {scopeItem.scopeItemDescription}
              </StyledScopeItemDescriptionText>
            ) : (
              <StyledScopeItemDescriptionTextField
                inputRef={descriptionRef}
                $themeColor={doxleThemeColor}
                $doxleFont={doxleFont}
                value={newItemDescription}
                placeholder="Task description..."
                onChange={(e) => setNewItemDescription(e.target.value)}
                onBlur={onBlurTextField}
                autoFocus
                onFocus={(e) => {
                  e.target.select();
                }}
                onKeyDown={onKeyDownDescriptionField}
                sx={
                  scopeItem.completed === true
                    ? { textDecoration: "line-through" }
                    : undefined
                }
                multiline
              />
            )}

            <ScopeItemAssigneeDisplay scopeItem={scopeItem} />
            <ScopeItemImageSection
              scopeItem={scopeItem}
              handleClickAddSpecImageBtn={handleClickAddSpecImageBtn}
            />

            <AnimatePresence>
              {(showHoveringMenu ||
                editedSOWItem?.scopeItemId === scopeItem.scopeItemId) && (
                <StyledScopeItemRowHoverMenu>
                  <DoxleIconButton
                    iconSource={
                      <AiOutlineUsergroupAdd
                        color={doxleThemeColor.primaryFontColor}
                        size={20}
                      />
                    }
                    buttonSize={20}
                    onClick={(e) =>
                      handleToggleSOWItemAssigneePopover(e, scopeItem)
                    }
                  />

                  <DoxleIconButton
                    iconSource={
                      <DoxleDeleteIcon
                        themeColor={doxleThemeColor}
                        containerStyle={{
                          width: 14,
                        }}
                      />
                    }
                    buttonSize={20}
                    onClick={handleClickDeleteButton}
                  />
                </StyledScopeItemRowHoverMenu>
              )}
            </AnimatePresence>
          </motion.div>
        </StyledScopeItemRowDetailSection>

        <AnimatePresence>
          {(showAddScopeImageSection || isGroupExpanded) && (
            <AddScopeItemImageSection
              scopeItem={scopeItem}
              handleCloseAddScopeImgSection={handleCloseAddScopeImgSection}
            />
          )}
        </AnimatePresence>
      </StyledScopeItemRowContainer>
    </ClickAwayListener>
  );
};

export default React.memo(ScopeItemRow);
