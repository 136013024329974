import React from "react";
import PDFIcon from "../../Assets/adobePdfIcon.svg";
import MicrosoftIcon from "../../Assets/microsoftIcon.svg";
import ExcelIcon from "../../Assets/excelIcon.svg";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { useQuoteContext } from "./QuoteResponse";
import { StyledAddedResponseAttachmentItem } from "../AddQuoteResponse/StyledComponentsAddQuoteResponse";
import { AiFillCloseCircle } from "react-icons/ai";
import { ImageOutlined } from "@mui/icons-material";
import { produce } from "immer";

type Props = {
  attachment: File;
  index: number;
};

export const FILETYPE = {
  pdf: PDFIcon,
  docx: MicrosoftIcon,
  xlsx: ExcelIcon,
};
const AddedResponseAttachment = ({ attachment, index }: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore((state) => ({
    doxleThemeColor: state.doxleThemeColor,
    doxleFont: state.doxleFont,
  }));
  const fileExtension = (attachment.name.split(".").pop() || "").toLowerCase();
  const { setAddedAttachments } = useQuoteContext();
  const imgLocalURL = URL.createObjectURL(attachment);
  return (
    <StyledAddedResponseAttachmentItem
      key={index}
      $themeColor={doxleThemeColor}
      $doxleFont={doxleFont}
      layout
      initial={{ x: -10, opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
    >
      <AiFillCloseCircle
        style={{
          fontSize: "1.6rem",
          color: doxleThemeColor.primaryFontColor,
          position: "absolute",
          zIndex: 3,
          top: "-0.8rem",
          right: 0,
        }}
        onClick={() =>
          setAddedAttachments((prevState) =>
            produce(prevState, (draft) => {
              draft.splice(index, 1);
              return draft;
            })
          )
        }
      />

      {["pdf", "xlsx", "docx"].includes(fileExtension) ? (
        <img
          className="img-wrapper "
          src={FILETYPE[fileExtension as keyof typeof FILETYPE]}
          alt="files"
        />
      ) : (
        <img className="img-wrapper " src={imgLocalURL} alt="files" />
      )}
      {attachment.name}
    </StyledAddedResponseAttachmentItem>
  );
};

export default AddedResponseAttachment;
