import { StyledPageContainer } from "./StyledComponents";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import OrdersTable from "./OrdersTable";
import OrdersHeader from "./OrderPageHeader";
import { useDoxleCurrentContextStore } from "../../../DoxleGeneralStore/useDoxleCurrentContext";
import { useDoxleAuthStore } from "../../../DoxleGeneralStore/useDoxleAuthStore";
import OrdersQueryAPI from "../../QueryHooks/OrdersQueryAPI";
import useDoxleNotificationStore from "../../../DoxleGeneralStore/useDoxleNotificationStore";
import { shallow } from "zustand/shallow";
import { useOrdersStore } from "../../OrdersStore/useOrdersStore";
import React, { useEffect, useMemo } from "react";
import Order from "../../Models/order";
import EditOrderSideScreen from "../EditOrderSideScreen/EditOrderSideScreen";
import DoxleListSkeleton from "../../../DoxleDesignPattern/DoxleSkeleton/DoxleListSkeleton";
import {Helmet} from "react-helmet";

const OrdersPage = () => {
  const doxleThemeColor = useDoxleThemeStore(
    (state) => state.doxleThemeColor,
    shallow
  );
  const company = useDoxleCurrentContextStore(
    (state) => state.currentCompany,
    shallow
  );

  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );

  const { storeView, setView, queryFilter, setQueryFilter } = useOrdersStore(
    (state) => ({
      storeView: state.view,
      setView: state.setView,
      queryFilter: state.queryFilter,
      setQueryFilter: state.setQueryFilter,
    }),
    shallow
  );

  const orderListQuery = OrdersQueryAPI.useRetrieveOrderList({

    company,
    showNotification,
    enabled: true,
    filter: queryFilter,
  });

  const ordersList: Order[] = useMemo(
    () =>
      orderListQuery.data?.pages
        ? orderListQuery.data?.pages.flatMap(
            (page: any) => page?.data?.results ?? []
          ) ?? []
        : [],
    [orderListQuery.data]
  );

  const handleFetchNext = () => {
    if (orderListQuery.hasNextPage) orderListQuery.fetchNextPage();
  };
  const view = window.location.href.toUpperCase().includes("ORDER")
    ? "Orders"
    : "Invoices";
  useEffect(() => {
    if (view !== storeView) setView(view);
    if (queryFilter.is_invoice && view === "Orders")
      setQueryFilter({ is_invoice: false });
    else if (!queryFilter.is_invoice && view === "Invoices")
      setQueryFilter({ is_invoice: true });
  }, []);

  return (
    <StyledPageContainer themeColor={doxleThemeColor}>
      <Helmet>
        <title>Doxle - Orders</title>
      </Helmet>
      <div className="order-content-wrapper">
        <OrdersHeader />
        {orderListQuery.isError && <>Error fetching the data.</>}
        {orderListQuery.isLoading && (
          <DoxleListSkeleton skeletonType={"orderRow"} />
        )}
        {orderListQuery.isSuccess && <OrdersTable />}
      </div>

      <EditOrderSideScreen />
    </StyledPageContainer>
  );
};
export default OrdersPage;
