import { TableContainer } from "@mui/material";
import React, { useCallback, useMemo } from "react";
import { ItemContent, TableComponents, TableVirtuoso } from "react-virtuoso";
import { shallow } from "zustand/shallow";

import DoxleListSkeleton from "../../DoxleDesignPattern/DoxleSkeleton/DoxleListSkeleton";
import { useDoxleCurrentContextStore } from "../../DoxleGeneralStore/useDoxleCurrentContext";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import { DoxleFile, DoxleFolder } from "../../Models/files";
import { useFileStore } from "../Store/useFileStore";
import ListViewLeftHeader from "./ListViewLeftHeader";

import {
  StyledBodyLeftSection,
  StyledFileFolderTableRow,
  StyledFileTableBody,
  StyledLeftFileTable,
  StyledLeftFileTableHeader,
  StyledListLeftOuterWrapper,
  StyledTableFileListScroller,
} from "./StyledFilesBodyComponent";
import useGetRootFolderQuery from "../Hooks/useGetRootFolderQuery";
import useGetFileQueryRoot from "../Hooks/useGetFileQueryRoot";
import ListLoadingMore from "../../Utilities/Lottie/ListLoadingMore";
import ListNewFolderPlaceholder from "../Components/ListNewFolderPlaceholder";
import { AnimatePresence } from "framer-motion";
import FilesRowLeft from "./FilesRowLeft";
import FoldersRowLeft from "./FoldersRowLeft";
import { useShallow } from "zustand/react/shallow";
import useListViewLeft from "../Hooks/useListViewLeft";
import ConfirmFolderUploadDialog from "../Components/ConfirmFolderUploadDialog";

type Props = {};

interface TableFileContext {
  currentFolder: undefined | DoxleFolder;
  fileFolderLength: number;
}
const ListViewLeft = ({}: Props) => {
  const {
    folderListSuccess,
    isFetchingFolderList,
    isSuccessFetchingFolderList,
    isErrorFetchingFolderList,
  } = useGetRootFolderQuery({});
  const { currentFolder, currentLevel } = useFileStore(
    useShallow((state) => ({
      currentFolder: state.currentFolder,
      currentLevel: state.currentLevel,
    }))
  );
  const {
    fileListSuccess,
    isFetchingFileList,
    isSuccessFetchingFileList,
    isErrorFetchingFileList,
    handleFetchNextPage,
    isFetchingNextPage,
  } = useGetFileQueryRoot({});
  const {
    handleFileFolderDrop,
    pendingFolderUpload,
    setPendingFolderUpload,
    handleUploadFiles,
    isAddingFileRoot,
  } = useListViewLeft();
  const combinedFileFolders: Array<DoxleFile | DoxleFolder> = useMemo(
    () =>
      !currentFolder
        ? [
            ...folderListSuccess.sort((a, b) =>
              a.folderName < b.folderName ? -1 : 1
            ),
            ...fileListSuccess.sort((a, b) =>
              a.fileName < b.fileName ? -1 : 1
            ),
          ]
        : [
            ...folderListSuccess.sort((a, b) =>
              a.folderName < b.folderName ? -1 : 1
            ),
          ],
    [folderListSuccess, fileListSuccess, currentFolder]
  );

  const { doxleThemeColor } = useDoxleThemeStore((state) => ({
    doxleThemeColor: state.doxleThemeColor,
  }));

  //*list render
  const tableComponents: TableComponents<
    DoxleFile | DoxleFolder,
    TableFileContext
  > = useMemo(
    () => ({
      Table: (props) => (
        <StyledLeftFileTable {...props} $themeColor={doxleThemeColor} />
      ),
      TableHead: (props) => (
        <StyledLeftFileTableHeader {...props} $themeColor={doxleThemeColor} />
      ),

      TableRow: (props) => (
        <StyledFileFolderTableRow
          $themeColor={doxleThemeColor}
          {...props}
          key={
            (props.item as DoxleFile).fileId
              ? (props.item as DoxleFile).fileId
              : (props.item as DoxleFolder).folderId
          }
        />
      ),
      Scroller: React.forwardRef((props, ref) => (
        <TableContainer
          ref={ref}
          {...props}
          component={StyledTableFileListScroller}
        />
      )),
      TableBody: React.forwardRef((props, ref) => (
        <StyledFileTableBody {...props} ref={ref} />
      )),
    }),
    [doxleThemeColor]
  );

  const itemContent: ItemContent<DoxleFile | DoxleFolder, TableFileContext> =
    useCallback(
      (index, item, context) => (
        <AnimatePresence
          key={
            (item as DoxleFile).fileId
              ? (item as DoxleFile).fileId
              : (item as DoxleFolder).folderId
          }
        >
          {(item as DoxleFile).fileId && (
            <FilesRowLeft
              fileItem={item as DoxleFile}
              lastRow={Boolean(
                index === combinedFileFolders.length - 1 ||
                  (index === folderListSuccess.length - 1 && currentFolder)
              )}
            />
          )}
          {(item as DoxleFolder).folderId && (
            <FoldersRowLeft
              folderItem={item as DoxleFolder}
              lastRow={Boolean(index === combinedFileFolders.length - 1)}
            />
          )}
        </AnimatePresence>
      ),
      [combinedFileFolders]
    );
  const tableListStyle: React.CSSProperties = useMemo(
    () => ({
      width: "100%",
      height: "100%",
      zIndex: 4,
      display: "flex",
    }),
    []
  );

  return (
    <StyledBodyLeftSection
      $topPadding={currentLevel !== "Company"}
      initial={{ x: -100, opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      transition={{ duration: 0.4 }}
      onDrop={handleFileFolderDrop}
    >
      <ConfirmFolderUploadDialog
        folder={pendingFolderUpload}
        setFolder={setPendingFolderUpload}
        uploadFn={handleUploadFiles}
      />
      {(isFetchingFileList || isFetchingFolderList) && (
        <DoxleListSkeleton
          containerHeightInRatio="100%"
          containerWidthInRatio="90%"
          containerStyle={{
            position: "absolute",
            zIndex: 0,
            top: currentLevel !== "Company" ? "max(3rem, 27px)" : 0,
            left: 0,
          }}
        />
      )}
      {(isSuccessFetchingFolderList || isSuccessFetchingFileList) && (
        <TableVirtuoso
          data={combinedFileFolders}
          context={{
            currentFolder,
            fileFolderLength: combinedFileFolders.length,
          }}
          style={tableListStyle}
          components={tableComponents}
          fixedHeaderContent={() => (
            <ListViewLeftHeader
              showAddFolder={combinedFileFolders.length === 0}
            />
          )}
          itemContent={itemContent}
          endReached={handleFetchNextPage}
        />
      )}

      {(isFetchingNextPage || isAddingFileRoot) && (
        <ListLoadingMore
          containerStyle={{
            position: "absolute",
            zIndex: 10,
            bottom: 0,
            left: 0,
            width: "100%",
            justifyContent: "center",
            display: "flex",
          }}
          animationSize={80}
        />
      )}
    </StyledBodyLeftSection>
  );
};

export default ListViewLeft;
