import { SVGProps } from "react";
import { DoxleThemeColor } from "../../DoxleGeneralStore/useDoxleThemeStore";
import { ISVGResponsiveCustom } from "../DoxleIcons/CommonIcons";

export const UncheckedIcon = (props: DoxleThemeColor) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none">
    <rect
      width={14.8}
      height={14.8}
      x={0.6}
      y={0.6}
      stroke={props.primaryFontColor}
      strokeOpacity={0.6}
      strokeWidth={1.2}
      rx={5.4}
    />
  </svg>
);
export const CheckedIcon = (props: DoxleThemeColor) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none">
    <rect width={16} height={16} fill="#7070EF" rx={6} />
    <path
      fill="#fff"
      d="M14.837 4.16a.523.523 0 0 1-.005.764l-8.384 7.92a.584.584 0 0 1-.791.003L2.17 9.607a.523.523 0 0 1-.01-.764.584.584 0 0 1 .798-.01l3.089 2.87 7.99-7.547a.584.584 0 0 1 .799.004Z"
    />
  </svg>
);

export const AlternateUncheckedIcon = (props: DoxleThemeColor) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={11}
    height={11}
    viewBox="0 0 10 10"
    fill="none"
  >
    <rect
      x="0.5"
      y="0.5"
      width={9}
      height={9}
      rx="1.5"
      stroke="#7070EF"
      strokeOpacity="0.5"
    />
  </svg>
);

export const AlternateCheckedIcon = (props: DoxleThemeColor) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={11}
    height={11}
    viewBox="0 0 10 10"
    fill="none"
  >
    <rect width="10" height="10" rx="2" fill="#7070EF" fillOpacity="0.7" />
    <rect
      x="0.5"
      y="0.5"
      width={9}
      height={9}
      rx="1.5"
      stroke="#7070EF"
      strokeOpacity="0.5"
    />
    <path
      d="M8.89951 1.1247C9.03458 1.28964 9.03333 1.55557 8.89673 1.71866L3.73731 7.87866C3.60258 8.03952 3.386 8.04057 3.2502 7.88102L1.10527 5.36102C0.967586 5.19927 0.964569 4.93336 1.09853 4.76711C1.23249 4.60086 1.4527 4.59722 1.59038 4.75898L3.49079 6.9917L8.40762 1.12134C8.54422 0.958251 8.76444 0.959754 8.89951 1.1247Z"
      fill="white"
    />
  </svg>
);

export const UncheckedLightIcon = ({
  themeColor,
  containerStyle,
  ...props
}: ISVGResponsiveCustom) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={21}
    height={21}
    fill="none"
    {...props}
  >
    <rect
      width={21}
      height={21}
      fill={themeColor.primaryTextFieldBackgroundColor}
      rx={4}
    />
  </svg>
);

export const CheckLightIcon = ({
  themeColor,
  containerStyle,
  ...props
}: ISVGResponsiveCustom) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={21}
    height={21}
    fill="none"
    {...props}
  >
    <rect
      width={21}
      height={21}
      fill={themeColor.primaryTextFieldBackgroundColor}
      rx={4}
    />
    <path
      fill={themeColor.primaryFontColor}
      d="M16.837 5.196a.74.74 0 0 1-.005.933l-8.384 9.68a.51.51 0 0 1-.791.004l-3.486-3.96a.74.74 0 0 1-.01-.933.51.51 0 0 1 .798-.013l3.089 3.508 7.99-9.224a.51.51 0 0 1 .799.005Z"
    />
  </svg>
);
