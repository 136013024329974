import { useCallback } from "react";
import { TNavBarMenu } from "../../../../DoxleGeneralStore/useDoxleCurrentContext";
import { useLocation, useNavigate } from "react-router-dom";

type Props = {};

interface IMenuToggledApp {
  isSelected: (menu: TNavBarMenu) => boolean;
  handlePressMenuItem: (nav: TNavBarMenu) => void;
}
const useMenuToggledApp = (): IMenuToggledApp => {
  const location = useLocation();
  const urlString = window.location.host;
  const currentRouteName = location.pathname.replace(/\//g, "");

  const navigate = useNavigate();

  const isSelected = useCallback(
    (menu: TNavBarMenu) =>
      !currentRouteName
        ? (urlString.toLowerCase().includes("project") && menu === "Budgets") ||
          (urlString.toLowerCase().includes("files") && menu === "Files") ||
          (urlString.toLowerCase().includes("pricebook") &&
            menu === "Pricebook") ||
          (urlString.toLowerCase().includes("contacts") && menu === "Contacts")
        : (currentRouteName.toLowerCase().includes("projects") &&
            menu === "Budgets") ||
          (currentRouteName.toLowerCase().includes("files") &&
            menu === "Files") ||
          (currentRouteName.toLowerCase().includes("pricebook") &&
            menu === "Pricebook") ||
          (currentRouteName.toLowerCase().includes("contacts") &&
            menu === "Contacts"),
    [currentRouteName, urlString]
  );
  const handlePressMenuItem = (nav: TNavBarMenu) => {
    // if (item.text === "Xero Settings") {
    //   navigate("/Xero/");
    // }
    if (nav === "Budgets") {
      navigate("/Projects/");

      // if url host is doxle.com and url does not include project then open projects.doxle.com
      if (
        window.location.host.includes("doxle.com") &&
        !urlString.toLowerCase().includes("project")
      )
        window.open("https://projects.doxle.com/");
      else navigate("/Projects/");
    } else if (nav === "Files") {
      navigate("/Files/");
    } else if (nav === "Pricebook") {
      // if (window.location.host.includes("doxle.com"))
      //   window.open("https://noticeboard.doxle.com/");
      // else
      navigate("/Pricebook/");
    } else if (nav === "Contacts") {
      // if (window.location.host.includes("doxle.com"))
      //   window.open("https://noticeboard.doxle.com/");
      // else
      navigate("/Contacts/");
    }
  };
  return {
    isSelected,
    handlePressMenuItem,
  };
};

export default useMenuToggledApp;
