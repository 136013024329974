import React, { useMemo } from "react";
import {
  StyledQuoteTotalFieldWrapper,
  StyledQuoteTotalSection,
} from "./StyledComponentsAddQuoteResponse";
import { useAddQuoteResponseContext } from "./AddQuoteResponse";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import { formatter } from "../../../Utilities/FunctionUtilities";

type Props = {};

const QuoteTotalSection = (props: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }),
    shallow
  );
  const { newQuoteResponse } = useAddQuoteResponseContext();
  const subTotalValue = useMemo(
    () =>
      newQuoteResponse.lines.reduce((acc, line) => {
        return (acc += Number(line.lineCost));
      }, 0),
    [newQuoteResponse.lines]
  );

  const tax = 0.1 * subTotalValue;
  const total = subTotalValue + tax;
  return (
    <StyledQuoteTotalSection layout>
      <StyledQuoteTotalFieldWrapper
        $themeColor={doxleThemeColor}
        $doxleFont={doxleFont}
      >
        <div className="total-label">Subtotal</div>
        <div className="total-value">{formatter.format(subTotalValue)}</div>
      </StyledQuoteTotalFieldWrapper>

      <StyledQuoteTotalFieldWrapper
        $themeColor={doxleThemeColor}
        $doxleFont={doxleFont}
      >
        <div className="total-label">GST</div>
        <div className="total-value">{formatter.format(tax)}</div>
      </StyledQuoteTotalFieldWrapper>
      <StyledQuoteTotalFieldWrapper
        $themeColor={doxleThemeColor}
        $doxleFont={doxleFont}
        style={{
          borderTop: `1px solid ${doxleThemeColor.primaryDividerColor}`,
          borderBottom: `1px solid ${doxleThemeColor.primaryDividerColor}`,
        }}
      >
        <div className="total-label">TOTAL</div>
        <div className="total-value">{formatter.format(total)}</div>
      </StyledQuoteTotalFieldWrapper>
    </StyledQuoteTotalSection>
  );
};

export default QuoteTotalSection;
