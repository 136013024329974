import React, { useMemo } from "react";
import { StyledOrderStatusMenuItemBtn } from "../OrderPage/StyledComponents";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import { useOrdersStore } from "../../OrdersStore/useOrdersStore";
import { OrderStatus } from "../../Models/order";
import { motion } from "framer-motion";
import useGetOrderList from "../../Hooks/useGetOrderList";
import { useEditOrderStore } from "../../OrdersStore/useEditOrderStore";

type Props = {
  orderStatus: OrderStatus;
};

const OrderStatusItem = ({ orderStatus }: Props) => {
  const { setSelectedStatus, selectedStatus, view, setView } = useOrdersStore(
    (state) => ({
      selectedStatus: state.selectedStatus,
      setSelectedStatus: state.setSelectedStatus,
      view: state.view,
      setView: state.setView,
    }),
    shallow
  );
  const setEditedOrder = useEditOrderStore(
    (state) => state.setEditedOrder,
    shallow
  );
  const { selectAllOrder, setSelectedOrder } = useOrdersStore(
    (state) => ({
      selectAllOrder: state.selectAllOrder,
      setSelectedOrder: state.setSelectedOrder,
    }),
    shallow
  );

  const { ordersList } = useGetOrderList({});
  const statusCount = useMemo(
    () =>
      ordersList.filter((order) => order.status === orderStatus.statusId)
        .length,
    [ordersList, orderStatus]
  );

  return (
    <StyledOrderStatusMenuItemBtn
      onClick={() => {
        if (selectedStatus != orderStatus) {
          setSelectedOrder([]);
        }
        setEditedOrder(undefined);
        setSelectedStatus(orderStatus);
        setView("Orders");
      }}
      $selected={Boolean(
        view === "Orders" &&
          selectedStatus &&
          selectedStatus.statusId === orderStatus.statusId
      )}
    >
      <span className="order-name">
        {orderStatus.statusName}({statusCount})
      </span>

      {view === "Orders" &&
        selectedStatus &&
        selectedStatus.statusId === orderStatus.statusId && (
          <motion.div
            layoutId="selectedOrderStatus"
            className="selected-effect"
          />
        )}
    </StyledOrderStatusMenuItemBtn>
  );
};

export default React.memo(OrderStatusItem);
