import React, { useCallback, useMemo, useState } from "react";
import { useInboxStore } from "../../../InboxStore/useInboxStore";
import { shallow } from "zustand/shallow";
import { Docket, LightDocket } from "../../../../Models/dockets";

type Props = {};

interface NoticeBoardContentBody {
  isFilteredByProject: boolean;
  anchorNbStatusMenuEl: HTMLElement | null;
  handleClickStatusNbItem: (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    item: LightDocket
  ) => void;
  currentFocusedNB: LightDocket | undefined;
  handleClosePopoverStatusMenu: () => void;
}
const useNoticeBoardContentBody = (props: Props): NoticeBoardContentBody => {
  const [anchorNbStatusMenuEl, setAnchorNbStatusMenuEl] =
    React.useState<null | HTMLElement>(null);
  const [currentFocusedNB, setCurrentFocusedNB] = useState<
    LightDocket | undefined
  >(undefined);
  const { inboxViewMode } = useInboxStore(
    (state) => ({
      inboxViewMode: state.inboxViewMode,
    }),
    shallow
  );
  //# filter by project section
  const isFilteredByProject: boolean = useMemo(
    () => inboxViewMode !== "My Notices",
    [inboxViewMode]
  );

  const handleClickStatusNbItem = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>, item: LightDocket) => {
      setAnchorNbStatusMenuEl(e.currentTarget);
      setCurrentFocusedNB(item);
    },
    []
  );
  const handleClosePopoverStatusMenu = () => {
    setAnchorNbStatusMenuEl(null);
    setCurrentFocusedNB(undefined);
  };
  return {
    isFilteredByProject,
    handleClickStatusNbItem,
    anchorNbStatusMenuEl,
    currentFocusedNB,
    handleClosePopoverStatusMenu,
  };
};

export default useNoticeBoardContentBody;
