import { useDoxleCurrentContextStore } from "../../DoxleGeneralStore/useDoxleCurrentContext";
import { shallow } from "zustand/shallow";
import { useXeroStore } from "../Store/useXeroStore";
import useDoxleNotificationStore from "../../DoxleGeneralStore/useDoxleNotificationStore";
import DocketQueryAPI from "../../Services/QueryHooks/docketQueryAPI";
import { Docket } from "../../Models/dockets";
import { useMemo } from "react";
import { Account, DocketAccountLink } from "../Models/account";
import {useIsMutating, useQueryClient, UseQueryResult} from "@tanstack/react-query";
import DocketQuery from "../../Services/QueryHooks/docketQueryAPI";
import {AxiosResponse} from "axios";
import budgetAPI from "../../Budgets/QueryHooks/BudgetAPI";
import {useProjectStore} from "../../DoxleGeneralStore/useprojectStore";
import {flatMap} from "lodash";
import {formatter} from "../../Utilities/FunctionUtilities";
import {toFloat} from "../../Measurements/konvaFunctions/generalFunctions";

interface IAccountMapping {
  unSavedAccountLinks: DocketAccountLink[];
  docketList: Docket[];
  handleLinkAll: () => Promise<void>;
  isMutating: boolean;
  unmappedAccountsWithCost: string[];
  sortedUnmatchedAccounts: Account[];
  isFetchingDocketList: boolean;
  isSuccessFetchingDocketList: boolean;
  isErrorFetchingDocketList: boolean;
}
export interface AccountWithCost extends Account {cost?: string;}

const useAccountMapping = (): IAccountMapping => {
  const { company, project, searchText } = useDoxleCurrentContextStore(
    (state) => ({
      company: state.currentCompany,
      project: state.currentProject,
      searchText: state.currentSearchText
    }),
    shallow
  );
  const { unSavedAccountLinks } = useXeroStore(
    (state) => ({
      unSavedAccountLinks: state.unSavedAccountLinks,
      selectAccountDropDownProps: state.selectAccountDropDownProps,
    }),
    shallow
  );
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );
  const paymentsOnly = useProjectStore(state=> state.accountingBasis==='CASH', shallow);
  const queryClient = useQueryClient()
  const xeroAccountsQuery: AxiosResponse<Account[]>|undefined = queryClient.getQueryData(["account-list", company?.companyId]);
  // const docketQuery = DocketQueryAPI.useRetrieveFullDetailDocketList({
  //   company,
  //   showNotification,
  //   filter: { project: project?.projectId, view: "budget" },
  //   enabled: Boolean(project?.projectId),
  // });
  const docketQuery = budgetAPI.useRetrieveProjectProfitAndLoss({
    company,
    showNotification,
    filter: { project: project?.projectId, view: "budget" },
    enabled: Boolean(project?.projectId),
    paymentsOnly
  });
  interface ProjectData {
    docketList: Docket[];
    unmappedAccountsWithCost: string[];
    sortedUnmatchedAccounts: AccountWithCost[];
  }

  const { docketList, unmappedAccountsWithCost, sortedUnmatchedAccounts, }: ProjectData = useMemo(()=> {
    const docketList =  (docketQuery.data?.data?.dockets ?? []).filter((docket: Docket) =>
      docket.docketName.toLowerCase().includes(searchText)
      || docket.accountName?.toLowerCase()?.includes(searchText)
    )

    const unmappedAccountsWithCost = [
      ...(docketQuery.data?.data?.unmappedCostAccounts
        ? docketQuery.data?.data?.unmappedCostAccounts?.split(',')
        : []
      ),
      ...(docketQuery.data?.data?.unmappedIncomeAccounts
        ? docketQuery.data?.data?.unmappedIncomeAccounts?.split(',')
        : []
      ),
    ];
    const accountsList: Account[] = xeroAccountsQuery?.data ?? [];

    const unmatchedAccounts: AccountWithCost[] = accountsList.filter(account => (
        account.accountCode?.includes('dx') ||
        unmappedAccountsWithCost.filter(str=> str.includes(account.accountId))?.[0]
      ) && !docketList.some(docket => (
        docket.accountTrackingId === account.accountId || docket.accountCode === account.accountCode
    ))).map(acc=> {
      const costStr = unmappedAccountsWithCost.filter(str => str.includes(acc.accountId))?.[0]?.split('|')?.[1]
      const cost = costStr ? formatter.format(toFloat(costStr)) : undefined;
      return {cost, ...acc}
    });
    const sortedUnmatchedAccounts = unmatchedAccounts.sort((a, b) => {
      if (a.cost && !b.cost) return -1;
      if (!a.cost && b.cost) return 1;
      return 0;
    })
    return {
      docketList,
      unmappedAccountsWithCost,
      sortedUnmatchedAccounts
    }
  },[
    docketQuery.data?.data?.dockets,
    docketQuery.data?.data?.unmappedCostAccounts,
    docketQuery.data?.data?.unmappedIncomeAccounts,
    searchText,
    xeroAccountsQuery?.data
  ])


  // const { accountsList } = useGetAccounts({});

  // const handleEndReached = () => {
  //   if (docketQuery.hasNextPage) docketQuery.fetchNextPage();
  // };
  const updateManyDocketsQuery = DocketQuery.useUpdateManyDockets({
    company,
    showNotification,
    filter: { project: project?.projectId, view: "budget" },
  });
  const handleLinkAll = async () => {
    updateManyDocketsQuery.mutate(unSavedAccountLinks);
  };
  const isMutating = Boolean(useIsMutating());

  return {
    unSavedAccountLinks,
    docketList,
    handleLinkAll,
    isMutating,
    unmappedAccountsWithCost,
    sortedUnmatchedAccounts,
    isFetchingDocketList: docketQuery.isLoading,
    isSuccessFetchingDocketList: docketQuery.isSuccess,
    isErrorFetchingDocketList: docketQuery.isError,
  };
};

export default useAccountMapping;
