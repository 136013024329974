import React, { useCallback, useMemo, useRef } from "react";
import {
  Components,
  ItemContent,
  ItemProps,
  Virtuoso,
  VirtuosoHandle,
} from "react-virtuoso";
import ScopeGroupRow from "./ScopeGroupRow";
import ScopeImageGallery from "./ScopeImageGallery/ScopeImageGallery";
import EditSOWGroup from "./EditSOWGroup";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import DoxleEmptyPlaceHolder from "../../DoxleDesignPattern/DoxleEmptyPlaceHolder/DoxleEmptyPlaceHolder";
import DoxleListSkeleton from "../../DoxleDesignPattern/DoxleSkeleton/DoxleListSkeleton";
import {
  StyledSOWGroupListScroller,
  StyledScopeGroupRowContainer,
  StyledScopeOfWorksContainer,
} from "./StyledComponentScopeOfWorkFullScreen";
import { useScopeOfWork } from "../Hooks/useScopeOfWork";
import { ScopeOfWorkGroup } from "../Model/scopeOfWorks";
import { Error404Banner } from "../../DoxleDesignPattern/DoxleIcons/CommonIcons";
import { EmptyWorkBanner } from "./ScopeOfWorkIcon";
import ErrorDroppedFileWarning from "./ErrorDroppedFileWarning";
import ScopeGroupMenuPopover from "./ScopeGroupMenuPopover/ScopeGroupMenuPopover";
import { useScopeOfWorkStore } from "../Store/useScopeOfWorkStore";
import { shallow } from "zustand/shallow";
import DoxleNestedPopover from "../../DoxleDesignPattern/DoxleNestedPopover";
import DoxleSelectContactDropdown from "../../DoxleDesignPattern/DoxleSelectContactDropdown/DoxleSelectContactDropdown";

type Props = {};

interface ScopeOfWorkListContext {
  isErrorFetchingScopeGroupList: boolean;
}
const ScopeOfWork = (props: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore((state) => ({
    doxleThemeColor: state.doxleThemeColor,
    doxleFont: state.doxleFont,
  }));
  const taskListRef = useRef<VirtuosoHandle>(null);
  const {
    isSuccessFetchingScopeGroupList,
    scopeGroupList,
    handleAddScopeGroup,
    isFetchingScopeGroupList,
    isErrorFetchingScopeGroupList,
    hasNextPage,
    fetchNextPage,
  } = useScopeOfWork({ taskListRef });

  const { setFocusedSOWGroup, focusedSOWGroup } = useScopeOfWorkStore(
    (state) => ({
      setFocusedSOWGroup: state.setFocusedSOWGroup,
      focusedSOWGroup: state.focusedSOWGroup,
    }),
    shallow
  );
  //*render list
  const components: Components<ScopeOfWorkGroup, ScopeOfWorkListContext> =
    useMemo(
      () => ({
        EmptyPlaceholder: ({ context }) => (
          <DoxleEmptyPlaceHolder
            containerBgColor="transparent"
            containerHeightRatio="100%"
            containerWidthRatio="100%"
            headTitleText={
              context?.isErrorFetchingScopeGroupList
                ? "Something Wrong"
                : "No Tasks!"
            }
            subTitleText={
              context?.isErrorFetchingScopeGroupList
                ? "Failed to get data, please try to reload the page"
                : "Let's Add More Task To Manage Your Work"
            }
            illustration={
              context?.isErrorFetchingScopeGroupList ? (
                <Error404Banner
                  themeColor={doxleThemeColor}
                  containerStyle={{ width: "30%" }}
                />
              ) : (
                <EmptyWorkBanner
                  themeColor={doxleThemeColor}
                  containerStyle={{ marginBottom: 12 }}
                />
              )
            }
          />
        ),

        Scroller: React.forwardRef((props, ref) => (
          <StyledSOWGroupListScroller ref={ref} {...props} />
        )),
        Item: React.forwardRef<HTMLDivElement, ItemProps<ScopeOfWorkGroup>>(
          (props, ref) => (
            <StyledScopeGroupRowContainer
              $themeColor={doxleThemeColor}
              {...props}
              ref={ref}
            />
          )
        ),
      }),
      [doxleThemeColor]
    );

  const itemContent: ItemContent<ScopeOfWorkGroup, ScopeOfWorkListContext> =
    useCallback(
      (index, item) => (
        <ScopeGroupRow
          scopeGroup={item}
          key={`scopeGroup#${item.scopeGroupId}`}
        />
      ),
      []
    );

  return (
    <StyledScopeOfWorksContainer $themeColor={doxleThemeColor}>
      {!isFetchingScopeGroupList && (
        <Virtuoso
          style={{
            width: "100%",
            height: "100%",
          }}
          ref={taskListRef}
          context={{ isErrorFetchingScopeGroupList }}
          data={scopeGroupList}
          components={components}
          overscan={200}
          itemContent={itemContent}
          endReached={() => {
            if (hasNextPage) fetchNextPage();
          }}
        />
      )}
      {isFetchingScopeGroupList && (
        <DoxleListSkeleton
          containerBgColor={doxleThemeColor.primaryBackgroundColor}
          containerHeightInRatio="100%"
          containerWidthInRatio="100%"
          skeletonType="scopeOfWork"
        />
      )}
      <ScopeGroupMenuPopover />
      <ErrorDroppedFileWarning />
      <ScopeImageGallery />
      <EditSOWGroup />
    </StyledScopeOfWorksContainer>
  );
};

export default ScopeOfWork;
