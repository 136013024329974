import dayjs, { Dayjs } from "dayjs";
import React from "react";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import {
  BaseSingleInputFieldProps,
  DateValidationError,
  DesktopDatePicker,
  FieldSection,
  DesktopDatePickerProps,
} from "@mui/x-date-pickers";
import { shallow } from "zustand/shallow";
import { StyledDatePickerCustomContainer } from "./AddStyledComponents";
import { editRgbaAlpha } from "../../../Utilities/FunctionUtilities";
import { customDatePickerStyle } from "../../../Models/modelHelper";

interface ButtonFieldProps
  extends BaseSingleInputFieldProps<
    Dayjs | null,
    Dayjs,
    FieldSection,
    DateValidationError
  > {
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

function ButtonField(props: ButtonFieldProps) {
  const {
    setOpen,
    label,
    InputProps: { ref } = {},
    inputProps: { "aria-label": ariaLabel } = {},
    ...rest
  } = props;
  const { doxleThemeColor, doxleFont, currentTheme } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
      currentTheme: state.currentTheme,
    }),
    shallow
  );
  return (
    <StyledDatePickerCustomContainer
      $themeColor={doxleThemeColor}
      $doxleFont={doxleFont}
      style={
        rest.disabled
          ? {
              backgroundColor: editRgbaAlpha({
                rgbaColor: doxleThemeColor.primaryContainerColor,
                alpha: "0.2",
              }),
            }
          : undefined
      }
      onClick={() => {
        if (!rest.disabled) setOpen?.((prev) => !prev);
      }}
      ref={ref}
      aria-label={ariaLabel}
      layout
    >
      {label}
    </StyledDatePickerCustomContainer>
  );
}

function ButtonDatePicker(
  props: Omit<DesktopDatePickerProps<Dayjs>, "open" | "onOpen" | "onClose">
) {
  const [open, setOpen] = React.useState(false);

  const { doxleThemeColor } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
    }),
    shallow
  );
  const { field, ...rest } = customDatePickerStyle<any>(doxleThemeColor);

  return (
    <DesktopDatePicker
      slots={{ field: ButtonField as any, ...props.slots }}
      slotProps={{ field: { setOpen } as any, ...rest }}
      {...props}
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => {
        setOpen(true);
      }}
    />
  );
}
type Props = {
  onChange?: (value: dayjs.Dayjs | null) => void;
  value: dayjs.Dayjs | null;
  disabled?: boolean;
};

const QuoteDatePicker = ({
  onChange,

  value,
  disabled,
}: Props) => {
  return (
    <ButtonDatePicker
      label={`${
        dayjs(value) == null
          ? "null"
          : dayjs(value).format("MMMM DD, YYYY h:mm A")
      }`}
      disabled={disabled}
      value={dayjs(value)}
      onChange={(newValue) => {
        onChange && onChange(newValue);
      }}
    />
  );
  // return (
  //   <DesktopDateTimePicker
  //     value={value}
  //     format="MMMM DD, YYYY hh:mm A"
  //     disabled={disabled}
  //     onChange={(value: unknown) => onChange(value as Dayjs | null)}
  //     onAccept={(value: unknown) => onAccept(value as Dayjs | null)}
  //     sx={{
  //       background: THEME_COLOR.primaryContainerColor,
  //       color: THEME_COLOR.primaryFontColor,
  //       "& .MuiInputBase-input.Mui-disabled": {
  //         WebkitTextFillColor: THEME_COLOR.primaryFontColor,
  //       },
  //       "& .MuiOutlinedInput-root": {
  //         paddingRight: "0px ",
  //         fontFamily: doxleFont.primaryFont,
  //         fontSize: "1.3rem",
  //         fontWeight: "500",
  //       },
  //     }}
  //   />
  // );
};

export default QuoteDatePicker;
