import { BaseAPIProps } from "../../Models/baseAPIProps";
import {
  PricebookSupplierRateFilters,
  PricebookItemFilters,
} from "../Models/PricebookFilters";
import DoxleAPI from "../../Services/DoxleAPI";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  PricebookItem,
  PricebookSupplierRate,
  PricebookSwatch,
} from "../Models/Pricebook";
import { useDoxleCurrentContextStore } from "../../DoxleGeneralStore/useDoxleCurrentContext";
import useDoxleNotificationStore from "../../DoxleGeneralStore/useDoxleNotificationStore";
import {
  AxiosBackendErrorReturn,
  IApiPaginatedData,
} from "../../Models/axiosReturn";
import { isAxiosError } from "axios";
import { Company } from "../../Models/company";
import { removeFalsyProps } from "../../Utilities/FunctionUtilities";
import { useShallow } from "zustand/react/shallow";

interface RetrieveSupplierRateListInterface extends BaseAPIProps {
  filter: PricebookSupplierRateFilters;
  onSuccessRemoveCb?: (deletedId: string) => void;
  onSuccessUpdateCb?: (updated: PricebookSupplierRate) => void;
}
const useRetrieveSupplierRateList = ({
  company,
  showNotification,
  filter,
}: RetrieveSupplierRateListInterface) => {
  let qKey = getSupplierRateListQKey(filter, company);
  let docketURL = `/pricebook/rate/`;

  return useQuery(
    qKey,
    ({ pageParam = docketURL }) =>
      DoxleAPI.get<IApiPaginatedData<PricebookSupplierRate>>(pageParam, {
        headers: {
          "User-Company": company!.companyId,
        },
        params: removeFalsyProps(filter),
      }),
    {
      enabled: Boolean(company),
      retry: 1,
      // refetchInterval: 2 * 60 * 1000,
      // refetchIntervalInBackground: true,
      refetchOnWindowFocus: false,
      getNextPageParam: (prevData) => prevData.data?.next,
      getPreviousPageParam: (prev) => prev.data.previous,
      onError: (error: any) => {
        if (showNotification) {
          if (isAxiosError<AxiosBackendErrorReturn>(error)) {
            showNotification(
              `${error?.response?.status ?? "ERROR"}: ${
                error.response?.data.detail ?? "UNKNOWN ERROR"
              }`,
              "error",
              String(
                error?.response?.data?.detail ??
                  "Error Fetching Contact Pricebook List"
              ).substring(0, 300)
            );
          } else {
            showNotification(
              "Something Wrong!",
              "error",
              "Error Fetching Contact Pricebook List"
            );
          }
        }
      },
    }
  );
};

interface PricebookItemListQueryInterface extends BaseAPIProps {
  filter: PricebookItemFilters;
  onSuccessCb?: () => void;
  onSuccessUpdateCb?: (updated: PricebookItem) => void;
  onSuccessAddCb?: (newItem: PricebookItem) => void;
  onSuccessRemoveCb?: (itemId: string) => void;
}
const useRetrievePricebookItemList = ({
  company,
  showNotification,
  filter,
}: PricebookItemListQueryInterface) => {
  let qKey = getPricebookListQKey(filter, company);
  let docketURL = `/pricebook/?page=1`;
  return useQuery(
    qKey,
    ({ pageParam = docketURL }) =>
      DoxleAPI.get<IApiPaginatedData<PricebookItem>>(pageParam, {
        headers: {
          "User-Company": company!.companyId,
        },
        params: removeFalsyProps(filter),
      }),
    {
      enabled: Boolean(company),
      retry: 1,
      staleTime: 0,
      // refetchInterval: 2 * 60 * 1000,
      // refetchIntervalInBackground: true,
      refetchOnWindowFocus: false,
      getNextPageParam: (prevData) => prevData.data?.next,
      getPreviousPageParam: (prev) => prev.data.previous,
      onError: (error: any) => {
        if (showNotification) {
          if (isAxiosError<AxiosBackendErrorReturn>(error)) {
            showNotification(
              `${error?.response?.status ?? "ERROR"}: ${
                error.response?.data.detail ?? "UNKNOWN ERROR"
              }`,
              "error",
              String(
                error?.response?.data?.detail ??
                  "Error Fetching Contact Pricebook List"
              ).substring(0, 300)
            );
          } else {
            showNotification(
              "Something Wrong!",
              "error",
              "Error Fetching Contact Pricebook List"
            );
          }
        }
      },
    }
  );
};

export interface AddPricebookBody {
  coreDockets?: string[];
  company?: string;
  description: string;
  supplierRates?: PricebookSupplierRate[];
  swatches?: File[];
}

export interface PatchPricebookBody {
  pricebookId: string;
  coreDockets?: string[];
  description?: string;
  supplierRates?: PricebookSupplierRate[];
  swatches?: File[];
}

const usePricebookItemMutation = ({
  company,
  showNotification,
  filter,
  onSuccessUpdateCb,
  onSuccessAddCb,
  onSuccessRemoveCb,
}: PricebookItemListQueryInterface) => {
  const queryClient = useQueryClient();
  const addPricebookItem = useMutation({
    mutationKey: ["add-pricebook"],
    mutationFn: async ({
      coreDockets,
      description,
      supplierRates,
      swatches,
    }: AddPricebookBody) => {
      const formData = new FormData();
      formData.append("description", description);

      if (company) formData.append("company", company.companyId);
      coreDockets?.forEach((coreDocketId: string) =>
        formData.append("coreDockets", coreDocketId)
      );
      supplierRates?.forEach((rate: PricebookSupplierRate) =>
        formData.append("supplierRates", JSON.stringify(rate))
      );
      swatches?.forEach((file: File) => formData.append("swatches", file));

      return DoxleAPI.post<PricebookItem>(`/pricebook/`, formData, {
        headers: {
          "User-Company": company?.companyId!,
        },
      });
    },
    onSuccess: (result, variables, context) => {
      queryClient.invalidateQueries(["supplier-rate-list", company?.companyId]);
      queryClient.invalidateQueries(["pricebook-list", company?.companyId]);
      if (onSuccessAddCb) onSuccessAddCb(result.data);
      if (showNotification)
        showNotification("Successfully added item!", "success", "", 1000);
    },
    onError: (error: any, variables, context) => {
      if (showNotification) {
        if (isAxiosError<AxiosBackendErrorReturn>(error)) {
          showNotification(
            `${error?.response?.status ?? "ERROR"}: ${
              error.response?.data.detail ?? "UNKNOWN ERROR"
            }`,
            "error",
            String(
              error?.response?.data?.detail ?? "Error adding Pricebook Item"
            ).substring(0, 300)
          );
        } else {
          showNotification(
            "Something Wrong!",
            "error",
            "Error adding Pricebook Item"
          );
        }
      }
    },
  });

  const updatePricebookItem = useMutation({
    mutationFn: async ({
      pricebookId,
      coreDockets,
      description,
      supplierRates,
      swatches,
    }: PatchPricebookBody) => {
      const formData = new FormData();
      if (description) formData.append("description", description);
      supplierRates?.forEach((sr) =>
        formData.append("supplierRates", JSON.stringify(sr))
      );
      coreDockets?.forEach((cd) => formData.append("coreDockets", cd));
      swatches?.forEach((swatch) => formData.append("swatches", swatch));
      return DoxleAPI.patch<PricebookItem>(
        `/pricebook/${pricebookId}/`,
        formData,
        {
          headers: {
            "User-Company": company?.companyId!,
          },
        }
      );
    },
    onSuccess: (result, variables, context) => {
      if (onSuccessUpdateCb) onSuccessUpdateCb(result.data);
    },
    onError: (error: any, variables, context) => {
      if (showNotification) {
        if (isAxiosError<AxiosBackendErrorReturn>(error)) {
          showNotification(
            `${error?.response?.status ?? "ERROR"}: ${
              error.response?.data.detail ?? "UNKNOWN ERROR"
            }`,
            "error",
            String(
              error?.response?.data?.detail ?? "Error update Pricebook Item"
            ).substring(0, 300)
          );
        } else {
          showNotification(
            "Something Wrong!",
            "error",
            "Error update Pricebook Item"
          );
        }
      }
    },
  });

  const deletePricebookItem = useMutation({
    mutationFn: async (pricebookId: string) => {
      return DoxleAPI.delete(`/pricebook/${pricebookId}/`, {
        headers: {
          "User-Company": company?.companyId!,
        },
      });
    },
    onSuccess: (result, variables, context) => {
      if (onSuccessRemoveCb) onSuccessRemoveCb(variables);
    },
    onError: (error, variables, context) => {
      if (showNotification) {
        if (isAxiosError<AxiosBackendErrorReturn>(error)) {
          showNotification(
            `${error?.response?.status ?? "ERROR"}: ${
              error.response?.data.detail ?? "UNKNOWN ERROR"
            }`,
            "error",
            String(
              error?.response?.data?.detail ?? "Error delete Pricebook Item"
            ).substring(0, 300)
          );
        } else {
          showNotification(
            "Something Wrong!",
            "error",
            "Error delete Pricebook Item"
          );
        }
      }
    },
  });

  return {
    addPricebookItem,
    updatePricebookItem,
    deletePricebookItem,
  };
};

interface AddSwatchBody {
  pricebookItem: string;
  files: File[];
}
interface PricebookSwatchQueryInterface extends BaseAPIProps {
  filter: PricebookItemFilters;
  onSuccessAddCb?: (newItem: PricebookSwatch[]) => void;
  onSuccessRemoveCb?: (itemId: string) => void;
}
interface AddSwatchReturnData {
  errors: any;
  swatches: PricebookSwatch[];
}

const useSwatchMutationQuery = ({
  company,
  showNotification,
  onSuccessRemoveCb,
  onSuccessAddCb,
}: PricebookSwatchQueryInterface) => {
  const addSwatch = useMutation({
    mutationFn: async ({ pricebookItem, files }: AddSwatchBody) => {
      const formData = new FormData();
      formData.append("pricebookItem", pricebookItem);
      for (var i = 0; i < files.length; i++) {
        formData.append("files", files[i]);
      }
      return DoxleAPI.post<AddSwatchReturnData>(
        `/pricebook/` + "swatch/",
        formData,
        {
          headers: {
            "User-Company": company?.companyId!,
          },
        }
      );
    },
    onSuccess: (result, variables, context) => {
      if (onSuccessAddCb) onSuccessAddCb(result.data.swatches);
    },
    onError: (error, variables, context) => {
      if (showNotification) {
        if (isAxiosError<AxiosBackendErrorReturn>(error)) {
          showNotification(
            `${error?.response?.status ?? "ERROR"}: ${
              error.response?.data.detail ?? "UNKNOWN ERROR"
            }`,
            "error",
            String(
              error?.response?.data?.detail ?? "Fail to Add Swatch"
            ).substring(0, 300)
          );
        } else {
          showNotification("Something Wrong!", "error", "Fail to Add Swatch");
        }
      }
    },
  });

  const deleteSwatch = useMutation({
    mutationFn: async (swatchId: string) => {
      return DoxleAPI.delete(`/pricebook/swatch/${swatchId}/`, {
        headers: {
          "User-Company": company?.companyId!,
        },
      });
    },
    onSuccess: (result, variables, context) => {
      if (onSuccessRemoveCb) onSuccessRemoveCb(variables);
    },
    onError: (error: any, variables, context) => {
      if (showNotification) {
        if (isAxiosError<AxiosBackendErrorReturn>(error)) {
          showNotification(
            `${error?.response?.status ?? "ERROR"}: ${
              error.response?.data.detail ?? "UNKNOWN ERROR"
            }`,
            "error",
            String(
              error?.response?.data?.detail ?? "Fail to delete Swatch"
            ).substring(0, 300)
          );
        } else {
          showNotification(
            "Something Wrong!",
            "error",
            "Fail to delete Swatch"
          );
        }
      }
    },
  });

  return {
    addSwatch,
    deleteSwatch,
  };
};

export interface AddSupplierBody {
  pricebookItem: string;
  supplier: string | null;
  uom?: string;
  unit_price?: string;
  supplierName?: string;
  timeStamp?: string;
}
const useSupplierRateMutationQuery = ({
  company,
  showNotification,
  filter,
  onSuccessRemoveCb,
  onSuccessUpdateCb,
}: RetrieveSupplierRateListInterface) => {
  let qKey = ["pricebook-list"];
  for (const [key, value] of Object.entries(filter)) {
    qKey.push(value);
  }
  const queryClient = useQueryClient();
  const addSuppplierRate = useMutation({
    mutationFn: async ({
      pricebookItem,
      supplier,
      uom,
      unit_price,
      supplierName,
      timeStamp,
    }: AddSupplierBody) => {
      const body = {
        pricebookItem,
        supplier,
        uom,
        unit_price,
        supplierName,
        timeStamp,
      };
      return DoxleAPI.post(`/pricebook/rate/`, body, {
        headers: {
          "User-Company": company?.companyId!,
        },
      });
    },
    onSuccess: (result, variables, context) => {
      queryClient.setQueryData(qKey, (oldData: any) => {
        return oldData
          ? {
              ...oldData,
              pages: oldData.pages.map((page: any, index: number) => ({
                ...page,
                data: {
                  ...page.data,
                  results: [
                    ...page.data.results.map((pricebook: PricebookItem) => {
                      if (pricebook.pricebookId === variables.pricebookItem) {
                        const newPricebook = {
                          ...pricebook,
                          supplierRates: [
                            ...pricebook.supplierRates,
                            result.data,
                          ],
                        };
                        return newPricebook;
                      } else return pricebook;
                    }),
                  ],
                },
              })),
            }
          : oldData;
      });

      // if (showNotification)
      //   showNotification(
      //     "Swatch Added",
      //     "success",
      //     "Success: Added Supplier Rate"
      //   );
    },
    onError: (error: any, variables, context) => {
      if (showNotification)
        if (showNotification) {
          if (isAxiosError<AxiosBackendErrorReturn>(error)) {
            showNotification(
              `${error?.response?.status ?? "ERROR"}: ${
                error.response?.data.detail ?? "UNKNOWN ERROR"
              }`,
              "error",
              String(
                error?.response?.data?.detail ?? "Fail to Add supplier rate"
              ).substring(0, 300)
            );
          } else {
            showNotification(
              "Something Wrong!",
              "error",
              "Fail to Add supplier rate"
            );
          }
        }
    },
  });

  const updateSupplierRate = useMutation({
    mutationFn: async ({
      rateId,
      supplierName,
      uom,
      unitCost,
      timeStamp,
    }: Partial<PricebookSupplierRate>) => {
      let body = {
        supplierName,
        uom,
        unitCost,
        timeStamp,
      };
      return DoxleAPI.patch(`/pricebook/rate/${rateId}/`, body, {
        headers: {
          "User-Company": company?.companyId!,
        },
      });
    },
    onSuccess: (result, variables, context) => {
      if (onSuccessUpdateCb) onSuccessUpdateCb(result.data);
    },
    onError: (error: any, variables, context) => {
      if (showNotification) {
        if (isAxiosError<AxiosBackendErrorReturn>(error)) {
          showNotification(
            `${error?.response?.status ?? "ERROR"}: ${
              error.response?.data.detail ?? "UNKNOWN ERROR"
            }`,
            "error",
            String(
              error?.response?.data?.detail ?? "Fail to update supplier rate"
            ).substring(0, 300)
          );
        } else {
          showNotification(
            "Something Wrong!",
            "error",
            "Fail to update supplier rate"
          );
        }
      }
    },
  });

  const deleteSupplierRate = useMutation({
    mutationKey: getSupplierRateMutationKey("delete"),
    mutationFn: async (rateId: string) => {
      return DoxleAPI.delete(`/pricebook/rate/${rateId}/`, {
        headers: {
          "User-Company": company?.companyId!,
        },
      });
    },
    onSuccess: (result, variables, context) => {
      if (onSuccessRemoveCb) onSuccessRemoveCb(variables);
    },
    onError: (error: any, variables, context) => {
      if (showNotification) {
        if (isAxiosError<AxiosBackendErrorReturn>(error)) {
          showNotification(
            `${error?.response?.status ?? "ERROR"}: ${
              error.response?.data.detail ?? "UNKNOWN ERROR"
            }`,
            "error",
            String(
              error?.response?.data?.detail ?? "Fail to delete supplier rate"
            ).substring(0, 300)
          );
        } else {
          showNotification(
            "Something Wrong!",
            "error",
            "Fail to delete supplier rate"
          );
        }
      }
    },
  });

  return {
    addSuppplierRate,
    updateSupplierRate,
    deleteSupplierRate,
  };
};

export interface PatchSupplierBody {
  rateId: string;
  supplierName?: string;
  uom?: string;
  unitCost?: string;
  timeStamp?: string;
}

const useRetrievePricebookItemDetail = (itemId: string) => {
  const company = useDoxleCurrentContextStore(
    useShallow((state) => state.currentCompany)
  );
  const showNotification = useDoxleNotificationStore(
    useShallow((state) => state.showNotification)
  );
  let qKey = getPricebookDetailQKey(itemId);
  let url = `/pricebook/${itemId}/`;
  return useQuery(
    qKey,
    () =>
      DoxleAPI.get<PricebookItem>(url, {
        headers: {
          "User-Company": company?.companyId,
        },
      }),
    {
      enabled: Boolean(company),
      staleTime: 5 * 60 * 1000,
      cacheTime: 6 * 60 * 1000,
      refetchInterval: false,
      onError: (error: any) => {
        if (showNotification) {
          if (isAxiosError<AxiosBackendErrorReturn>(error)) {
            showNotification(
              `${error?.response?.status ?? "ERROR"}: ${
                error.response?.data.detail ?? "UNKNOWN ERROR"
              }`,
              "error",
              String(
                error?.response?.data?.detail ?? "Error Fetching Pricebook Item"
              ).substring(0, 300)
            );
          } else {
            showNotification(
              "Something Wrong!",
              "error",
              "Error Fetching Pricebook Item"
            );
          }
        }
      },
    }
  );
};

//# helper functions
export const baseSupplierRateListQKey = ["supplier-rate-list"];
export const getSupplierRateListQKey = (
  filter: PricebookSupplierRateFilters,
  company: Company | undefined
) => {
  let qKey = ["supplier-rate-list"];
  if (company) qKey.push(company.companyId);
  for (const [key, value] of Object.entries(filter)) {
    if (value) qKey.push(value);
  }

  return qKey;
};
export const basePricebookListQKey = ["pricebook-list"];
export const getPricebookListQKey = (
  filter: PricebookItemFilters,
  company: Company | undefined
) => {
  let qKey = ["pricebook-list"];
  if (company) qKey.push(company.companyId);
  for (const [key, value] of Object.entries(filter)) {
    qKey.push(value);
  }

  return qKey;
};

export const getPricebookDetailQKey = (pricebookId: string | null) => {
  return ["pricebook-detail", pricebookId];
};

export const getSupplierRateMutationKey = (
  action: "update" | "delete" | "add"
) => [`${action}-supplier-rate`];
const PricebookQueryAPI = {
  useRetrieveSupplierRateList,
  useRetrievePricebookItemList,
  useRetrievePricebookItemDetail,
  usePricebookItemMutation,
  useSwatchMutationQuery,
  useSupplierRateMutationQuery,
};
export default PricebookQueryAPI;
