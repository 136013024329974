import {
  StyledContextDropDownContainer,
  StyledDropDownItem,
} from "./StyledComponents";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import { MouseEvent } from "react";
import { ClickAwayListener } from "@mui/material";
import { usePricebookStore } from "../Store/usePricebookStore";
import { useShallow } from "zustand/react/shallow";
interface Props {
  buttonBounding: DOMRect | null;
  handleClose: () => void;
  context: string;
}
const ContextPageNewDropDown = ({
  buttonBounding,
  handleClose,
  context,
}: Props) => {
  const { view, setView, setShowAddPricebookDialog } = usePricebookStore(
    useShallow((state) => ({
      view: state.view,
      setView: state.setView,
      setShowAddPricebookDialog: state.setShowAddPricebookDialog,
    }))
  );

  const top: number = buttonBounding?.bottom ?? 0;
  const right: number = buttonBounding?.right ?? 0;
  const dropDownItems: {
    text: string;
    function: (e: MouseEvent<HTMLDivElement>) => void;
  }[] = [
    {
      text: view === "List" ? "Switch to Table View" : "Switch to List View",
      function: (e: MouseEvent<HTMLDivElement>) => {
        setView(view === "List" ? "Table" : "List");
        handleClose();
      },
    },
    {
      text: "Add New Item",
      function: (e: MouseEvent<HTMLDivElement>) => {
        setShowAddPricebookDialog(true);
        handleClose();
      },
    },
  ];
  return (
    <ClickAwayListener onClickAway={handleClose}>
      <StyledContextDropDownContainer
        $top={top}
        $right={right}
        $context={context}
      >
        {dropDownItems.map((dropDownItem, idx) => (
          <StyledDropDownItem
            key={"pricebookDropDown#" + dropDownItem.text + `#${idx}`}
            onClick={dropDownItem.function}
          >
            {dropDownItem.text}
          </StyledDropDownItem>
        ))}
      </StyledContextDropDownContainer>
    </ClickAwayListener>
  );
};
export default ContextPageNewDropDown;
