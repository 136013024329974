import React, { useState } from "react";
import { DoxleFile, DoxleFolder } from "../../Models/files";
import { useDoxleCurrentContextStore } from "../../DoxleGeneralStore/useDoxleCurrentContext";
import { useShallow } from "zustand/react/shallow";
import useDoxleNotificationStore from "../../DoxleGeneralStore/useDoxleNotificationStore";
import { useFileStore } from "../Store/useFileStore";
import FilesQueryAPI, {
  IDeleteFileQueryParams,
  getFileMutateKey,
  getFolderMutateQKey,
} from "../QueryHooks/FileStorageQueryAPI";
import { useIsMutating } from "@tanstack/react-query";

type Props = {
  fileItem?: DoxleFile;
  folderItem?: DoxleFolder;
};

const useFileHoverMenu = ({ fileItem, folderItem }: Props) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const { company } = useDoxleCurrentContextStore(
    useShallow((state) => ({
      company: state.currentCompany,
    }))
  );
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification
  );

  const {
    currentFolder,
    setCurrentFolder,
    filterRootFolderQuery,
    filterRootFileQuery,
  } = useFileStore(
    useShallow((state) => ({
      currentFolder: state.currentFolder,
      setCurrentFolder: state.setCurrentFolder,
      clearSelectedFiles: state.clearSelectedFiles,
      setCurrentView: state.setCurrentView,
      currentView: state.currentView,
      filterRootFolderQuery: state.filterRootFolderQuery,
      filterRootFileQuery: state.filterRootFileQuery,
    }))
  );
  const onUpdateFolderSuccessCallback = (updatedFolder: DoxleFolder) => {
    setCurrentFolder(updatedFolder);
  };

  const updateFolderQuery = FilesQueryAPI.useUpdateFolderQuery({
    company,
    showNotification,
    onUpdateFolderSuccessCallback,
    filter: filterRootFolderQuery,
  });

  const updateFileQuery = FilesQueryAPI.useUpdateFileQuery({
    company,
    showNotification,
    filter: currentFolder
      ? {
          folderId: currentFolder.folderId,
        }
      : filterRootFileQuery,
  });

  const onDeleteFolderCallback = () => {
    setCurrentFolder(undefined);
  };

  const deleteFileQuery = FilesQueryAPI.useDeleteFileQuery({
    company,
    showNotification,
    filter: currentFolder
      ? {
          folderId: currentFolder.folderId,
        }
      : filterRootFileQuery,
  });

  const deleteFolderQuery = FilesQueryAPI.useDeleteFolderQuery({
    company,
    showNotification,
    onDeleteFolderCallback: onDeleteFolderCallback,
    filter: filterRootFolderQuery,
  });

  const pencilIconClicked = () => {
    setDialogOpen(true);
  };

  const trashIconClicked = () => {
    if (fileItem !== undefined) {
      deleteFileQuery.mutate({
        files: [fileItem],
        currentFolderId: currentFolder?.folderId,
        currentFolderName: currentFolder?.folderName,
      });
    }
    if (folderItem !== undefined) {
      deleteFolderQuery.mutate([folderItem.folderId]);
      //*  ONCE A FOLDER IS DELETED SET THE ROOT FOLDER TO BE TRUE
      setCurrentFolder(undefined);
    }
  };

  const handleDialogKeyDown = (newName: string) => {
    setDialogOpen(false);
    if (folderItem) {
      updateFolderQuery.mutate({
        folderId: folderItem.folderId,
        folderName: newName,
      });
    }
    if (fileItem) {
      updateFileQuery.mutate({
        fileId: fileItem.fileId,
        fileName: newName,
        currentFolderId: currentFolder ? currentFolder?.folderId : undefined,
        currentFolderName: currentFolder
          ? currentFolder?.folderName
          : undefined,
      });
    }
  };

  const handleDialogTick = (newName: string) => {
    setDialogOpen(false);
    if (folderItem) {
      updateFolderQuery.mutate({
        folderId: folderItem.folderId,
        folderName: newName,
      });
    }
    if (fileItem) {
      updateFileQuery.mutate({
        fileId: fileItem.fileId,
        fileName: newName,
        currentFolderId: currentFolder ? currentFolder?.folderId : undefined,
        currentFolderName: currentFolder
          ? currentFolder?.folderName
          : undefined,
      });
    }
  };
  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  return {
    dialogOpen,
    setDialogOpen,
    pencilIconClicked,
    trashIconClicked,
    handleDialogKeyDown,
    handleDialogTick,
    handleDialogClose,
  };
};

export default useFileHoverMenu;
