import React from "react";
import { StyledQRSummaryGroupHeader } from "./StyledQRLogSummary";
import { useQRLogSummaryContext } from "./QRLogSummary";

type Props = {
  userId: string;
  userName: string;
};

const QRSummaryGroupHeader = ({ userId, userName }: Props) => {
  const { handleHideUserGroup } = useQRLogSummaryContext();
  return (
    <StyledQRSummaryGroupHeader onClick={() => handleHideUserGroup(userId)}>
      <span className="user-name">{userName}</span>
      <span className="time-field">In</span>
      <span className="time-field">Out</span>
      <span className="total-field">Total Hours</span>
    </StyledQRSummaryGroupHeader>
  );
};

export default QRSummaryGroupHeader;
