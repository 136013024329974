import styled from "styled-components";
import {
  DoxleFont,
  DoxleTheme,
  DoxleThemeColor,
} from "../DoxleGeneralStore/useDoxleThemeStore";
import { editRgbaAlpha } from "../Utilities/FunctionUtilities";

export const StyledInventoryItemContainer = styled.div<{
  $themeColor: DoxleThemeColor;
  $themeFont: DoxleFont;
  isDeleting: boolean;
  $currentTheme: DoxleTheme;
}>`
  height: 30rem;
  /* max-width: 65.9rem; */
  border: 1px solid
    ${(p) =>
      p.$currentTheme === "dark" ? "rgba(122, 122, 122, 1)" : "#f2f2f2"};
  border-radius: 3px;
  margin-bottom: 2rem;
  padding-bottom: 5px;
  padding-top: 10px;
  position: relative;
  padding-left: 2rem;
  padding-right: 2rem;
  display: flex;
  flex-direction: column;
  width: calc(100% - 4rem - 4px);

  opacity: ${(p) => (p.isDeleting ? "0.5" : "1.0")};
`;

export const StyledInventoryTitle = styled.div<{
  $themeColor: DoxleThemeColor;
  $themeFont: DoxleFont;
}>`
  font-size: 1.6rem;
  font-weight: 400;
  color: ${(p) => p.$themeColor.primaryFontColor};
  width: 90%;
`;

export const StyledInventorySubtitle = styled.div<{
  $themeColor: DoxleThemeColor;
  $themeFont: DoxleFont;
}>`
  font-size: 1rem;
  font-weight: 300;
  color: ${(p) =>
    editRgbaAlpha({ rgbaColor: p.$themeColor.primaryFontColor, alpha: "0.6" })};
  font-family: ${(p) => p.$themeFont.secondaryTitleFont};
`;

export const StyledInventoryBtmContainer = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  overflow: hidden;
`;

export const StyledInventoryImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30%;
  height: 100%;
  overflow: hidden;
  img {
    width: 90%;
    &:hover {
      cursor: pointer;
    }
  }
`;

export const StyledInventoryDetailsContainer = styled.div<{
  $themeColor: DoxleThemeColor;
  $currentTheme: DoxleTheme;
}>`
  border-left: 1px solid
    ${(p) =>
      p.$currentTheme === "dark" ? "rgba(122, 122, 122, 1)" : "#f2f2f2"};
  width: 70%;
  height: 100%;
`;

export const StyledInventoryStatsContainer = styled.div<{
  $themeColor: DoxleThemeColor;
  $currentTheme: DoxleTheme;
}>`
  border-bottom: 1px solid
    ${(p) =>
      p.$currentTheme === "dark" ? "rgba(122, 122, 122, 1)" : "#f2f2f2"};
  padding-left: 2rem;
  padding-bottom: 1rem;
  margin-top: 5rem;
`;
export const StyledInventoryStats = styled.span<{
  $themeColor: DoxleThemeColor;
  isTotalPrice?: boolean;
}>`
  font-size: 2.7rem;
  color: ${(p) =>
    p.isTotalPrice ? p.$themeColor.doxleColor : p.$themeColor.primaryFontColor};
`;

export const StyledCalculateSymbol = styled.span<{
  $themeColor: DoxleThemeColor;
}>`
  font-size: 1.8rem;
  color: ${(p) =>
    editRgbaAlpha({ rgbaColor: p.$themeColor.primaryFontColor, alpha: "0.4" })};
  position: relative;
  bottom: 0.4rem;
  margin-left: 1rem;
  margin-right: 1rem;
`;

export const StyledInventoryCommentContainer = styled.div<{
  $themeColor: DoxleThemeColor;
  $currentTheme: DoxleTheme;
}>`
  margin-top: 2rem;
  background-color: ${(p) =>
    p.$currentTheme === "dark" ? "#121218" : "#f2f2f2"};
  height: 6rem;
  max-width: 26.7rem;
  margin-left: 2rem;
  padding: 0.5rem;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const StyledCommentText = styled.div<{
  $themeColor: DoxleThemeColor;
}>`
  font-size: 1.2rem;
  color: ${(p) =>
    editRgbaAlpha({ rgbaColor: p.$themeColor.primaryFontColor, alpha: "0.8" })};
`;

export const StyledCommentDetails = styled.div<{
  $themeColor: DoxleThemeColor;
}>`
  font-size: 1rem;
  color: ${(p) =>
    editRgbaAlpha({ rgbaColor: p.$themeColor.primaryFontColor, alpha: "0.4" })};
`;
export const StyledEditIconContainer = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
  padding-right: 2rem;
  padding-top: 1rem;
`;

export const StyledDeletingPrompt = styled.span`
  color: red;
  font-size: 1.2rem;
  padding-left: 0.5rem;
`;
