import React from "react";
import {
  StyledAddQuoteLineHeader,
  StyledAddQuoteLinePlaceholder,
  StyledAddQuoteLineTextField,
  StyledAddResponseLineSection,
} from "./StyledComponentsAddQuoteResponse";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import useAddResponseLineSection from "./Hooks/useAddResponseLineSection";
import { GoPlus } from "react-icons/go";
import { QuoteLine } from "../../models/quote";
import { useAddQuoteResponseContext } from "./AddQuoteResponse";
import AddedQuoteLineItem from "./AddedQuoteLineItem";
type Props = {};
interface AddQuoteLineFields {
  fieldName: "Qty" | "Unit" | "Unit Price" | "Description" | "Subtotal";
  lineProp: keyof QuoteLine;
}
export const QUOTE_LINE_FIELDS: AddQuoteLineFields[] = [
  {
    fieldName: "Description",
    lineProp: "description",
  },
  {
    fieldName: "Qty",
    lineProp: "quantity",
  },
  {
    fieldName: "Unit",
    lineProp: "unit",
  },
  {
    fieldName: "Unit Price",
    lineProp: "itemCost",
  },
  {
    fieldName: "Subtotal",
    lineProp: "lineCost",
  },
];
const AddResponseLineSection = (props: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore((state) => ({
    doxleThemeColor: state.doxleThemeColor,
    doxleFont: state.doxleFont,
  }));
  const {
    newQuoteLineDescription,
    setNewQuoteLineDescription,
    handleKeydownDescription,
    handleAddNewLine,
  } = useAddResponseLineSection({});
  const { newQuoteResponse } = useAddQuoteResponseContext();
  return (
    <StyledAddResponseLineSection $themeColor={doxleThemeColor}>
      <StyledAddQuoteLineHeader
        $themeColor={doxleThemeColor}
        $doxleFont={doxleFont}
      >
        {QUOTE_LINE_FIELDS.map((header, idx) => (
          <div
            key={`header#${header.lineProp + idx}`}
            className="header-field-wrapper"
            style={{
              width:
                header.fieldName !== "Description"
                  ? header.fieldName !== "Subtotal"
                    ? "8rem"
                    : "14rem"
                  : undefined,
              flex: header.fieldName === "Description" ? 1 : undefined,
            }}
          >
            {header.fieldName !== "Description" && header.fieldName}
          </div>
        ))}
        <div className="icon-space-header" />
      </StyledAddQuoteLineHeader>
      {newQuoteResponse.lines.map((line, idx) => (
        <AddedQuoteLineItem
          lineItem={line}
          itemIndex={idx}
          key={`line#${idx}`}
        />
      ))}
      <StyledAddQuoteLinePlaceholder onClick={handleAddNewLine}>
        <GoPlus
          style={{
            color: doxleThemeColor.primaryInputPlaceholderColor,
            fontSize: "1.8rem",
            marginRight: "0.4rem",
          }}
        />
        New
      </StyledAddQuoteLinePlaceholder>
      {/* <StyledAddQuoteLineTextField
          $themeColor={doxleThemeColor}
          $doxleFont={doxleFont}
          placeholder="New"
          variant="standard"
          value={newQuoteLineDescription}
          disabled
          onChange={(event) => setNewQuoteLineDescription(event.target.value)}
          InputProps={{
            startAdornment: (
              <GoPlus
                style={{
                  color: doxleThemeColor.primaryInputPlaceholderColor,
                  fontSize: "1.8rem",
                  marginRight: "0.4rem",
                }}
              />
            ),
          }}
        /> */}
    </StyledAddResponseLineSection>
  );
};

export default AddResponseLineSection;
