import React, { useMemo } from "react";
import { useDoxleAuthStore } from "../../../../DoxleGeneralStore/useDoxleAuthStore";
import { useDoxleCurrentContextStore } from "../../../../DoxleGeneralStore/useDoxleCurrentContext";
import useDoxleNotificationStore from "../../../../DoxleGeneralStore/useDoxleNotificationStore";
import ScopeOfWorkAPI, {
  FilterRetrieveScopeItemQuery,
} from "../../../QueryAPI/scopeOfWorkQueryAPI";
import {
  QuoteScopeOfWorkItem,
  ScopeOfWorkGroup,
} from "../../../../ScopeOfWorks/Model/scopeOfWorks";

type Props = { sowGroup: ScopeOfWorkGroup };
interface QuoteSOWGroupItem {
  scopeItemList: QuoteScopeOfWorkItem[];
  isFetchingScopeItems: boolean;
  isSuccessFetchingScopeItems: boolean;
  isErrorFetchingScopeItems: boolean;
}
const useQuoteSOWGroupItem = ({ sowGroup }: Props): QuoteSOWGroupItem => {
  const { company } = useDoxleCurrentContextStore((state) => ({
    company: state.currentCompany,
  }));
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification
  );
  //# fetch scope item list query
  const filterScopeItemQuery: FilterRetrieveScopeItemQuery = useMemo(
    () => ({
      scopeGroupId: sowGroup.scopeGroupId,
      quote_mode: true,
    }),
    [sowGroup]
  );
  const getScopeItemQuery = ScopeOfWorkAPI.useRetrieveScopeItemListQuery({
    showNotification,

    company,
    filter: filterScopeItemQuery,
  });

  const scopeItemList: QuoteScopeOfWorkItem[] = useMemo(
    () => (getScopeItemQuery.isSuccess ? getScopeItemQuery.data.data : []),
    [getScopeItemQuery.isSuccess, getScopeItemQuery.data]
  );
  return {
    scopeItemList,
    isFetchingScopeItems: getScopeItemQuery.isLoading,
    isSuccessFetchingScopeItems: getScopeItemQuery.isSuccess,
    isErrorFetchingScopeItems: getScopeItemQuery.isError,
  };
};

export default useQuoteSOWGroupItem;
