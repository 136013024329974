import React from "react";
import useAddFilePopover from "../Hooks/useAddFilePopover";
import { useDropzone } from "react-dropzone";
import {
  StyledPopover,
  StyledPopoverItem,
  StyledPopoverSection,
} from "./StyledFilesBodyComponent";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import { AddFileIcon, AddFolderIcon } from "./FileIcons";
import { useFileStore } from "../Store/useFileStore";
import { shallow } from "zustand/shallow";
import { useShallow } from "zustand/react/shallow";
import { DOXLE_ACCEPTED_MIME } from "../../Utilities/MimeFileType";

type Props = {};

const AddFilePopover = (props: Props) => {
  const { doxleThemeColor, doxleFont, currentTheme } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
      currentTheme: state.currentTheme,
    })
  );
  const { currentFolder } = useFileStore(
    useShallow((state) => ({
      currentFolder: state.currentFolder,
    }))
  );
  const {
    anchorAddPopover,
    onDrop,
    handleClosePopper,
    addFolderClicked,
    addFileClicked,
  } = useAddFilePopover({});
  const open = Boolean(anchorAddPopover);
  const id = open ? "simple-popover" : undefined;
  const { getRootProps } = useDropzone({
    onDrop,
    accept: DOXLE_ACCEPTED_MIME,
  });
  return (
    <StyledPopover
      id={id}
      open={open}
      anchorEl={anchorAddPopover}
      onClose={handleClosePopper}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        horizontal: "right",
        vertical: "top",
      }}
    >
      <StyledPopoverSection>
        {!currentFolder && (
          <StyledPopoverItem onClick={addFolderClicked}>
            <AddFolderIcon
              themeColor={doxleThemeColor}
              containerStyle={{ marginRight: "6px" }}
            />
            Add Folder
          </StyledPopoverItem>
        )}
        <StyledPopoverItem
          {...getRootProps({
            onClick: addFileClicked,
          })}
          style={{ borderBottom: "none" }}
        >
          <AddFileIcon
            themeColor={doxleThemeColor}
            containerStyle={{ marginRight: "6px" }}
          />
          Add File
        </StyledPopoverItem>
      </StyledPopoverSection>
    </StyledPopover>
  );
};

export default AddFilePopover;
