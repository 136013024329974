import React from "react";
import useGetUserInfo from "../../CoreContent/QueryDataHooks/GetQueryDataHooks/useGetUserInfo";
import SignaturePad from "react-signature-canvas";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import useDoxleEditSignaturePatch from "./Hooks/useDoxleEditSignaturePatch";
import {
  StyledAddSignatureContentWrapper,
  StyledAddSignatureTitle,
  StyledDoxleEditSignaturePatch,
  StyledEditSignatureBtnWrapper,
  StyledEditSignatureButton,
  StyledSaveSignatureLoaderWrapper,
} from "./StyledDoxleEditSignaturePatch";
import LinearProgress from "@mui/material/LinearProgress";
import { editRgbaAlpha } from "../../Utilities/FunctionUtilities";
import DoxleCloseBtn from "../DoxleButtons/DoxleCloseBtn";
import { User } from "../../Models/user";

type Props = {
  containerStyle?: React.CSSProperties;
  onClose?: () => void;
  onCompleteEdit?: (newUser?: User) => void;
};

const DoxleEditSignaturePatch = ({
  containerStyle,
  onClose,
  onCompleteEdit,
}: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }),
    shallow
  );

  const {
    finalSignaturePath,
    signPadRef,
    onFinishDraw,
    handleClearSignature,
    handleSaveSignature,
    isSavingSignature,
  } = useDoxleEditSignaturePatch({ onCompleteEdit });
  return (
    <StyledDoxleEditSignaturePatch
      style={containerStyle}
      $themeColor={doxleThemeColor}
      layout
      animate={{
        scale: [0, 1],
      }}
    >
      {isSavingSignature && (
        <StyledSaveSignatureLoaderWrapper
          $themeColor={doxleThemeColor}
          $doxleFont={doxleFont}
        >
          <span className="loader-text">Saving signature...</span>
          <LinearProgress
            className="loader-bar"
            sx={{
              "&.MuiLinearProgress-root": {
                backgroundColor: editRgbaAlpha({
                  rgbaColor: doxleThemeColor.doxleColor,
                  alpha: "0.3",
                }),
              },
              "& .MuiLinearProgress-bar": {
                backgroundColor: doxleThemeColor.doxleColor,
              },
              width: "50% !important",
            }}
          />
        </StyledSaveSignatureLoaderWrapper>
      )}

      {onClose && (
        <StyledAddSignatureTitle
          $themeColor={doxleThemeColor}
          $doxleFont={doxleFont}
        >
          {onClose && (
            <DoxleCloseBtn
              onClick={() => {
                onClose();
              }}
              sx={{
                width: "3rem",
                height: "3rem",
                borderRadius: "50%",
                padding: "0.2rem !important",
                marginRight: "4px",
              }}
            />
          )}
        </StyledAddSignatureTitle>
      )}

      <StyledAddSignatureContentWrapper
        $themeColor={doxleThemeColor}
        $doxleFont={doxleFont}
      >
        <div className="preview-wrapper">
          <span className="preview-text">Preview</span>

          {finalSignaturePath && (
            <img className="image-signature" src={finalSignaturePath} />
          )}
        </div>
        <div className="canvas-wrapper">
          <span className="sign-text">Sign zone</span>

          <SignaturePad
            ref={signPadRef}
            canvasProps={{
              className: "signature-pad",
            }}
            onEnd={onFinishDraw}
            clearOnResize
          />
        </div>
      </StyledAddSignatureContentWrapper>

      <StyledEditSignatureBtnWrapper>
        <StyledEditSignatureButton
          $themeColor={doxleThemeColor}
          $doxleFont={doxleFont}
          $bgColor={doxleThemeColor.primaryContainerColor}
          $textColor={doxleThemeColor.primaryFontColor}
          $hasBorder
          whileHover={{
            scale: 1.1,
          }}
          whileTap={{ scale: 1 }}
          onClick={handleClearSignature}
        >
          Clear
        </StyledEditSignatureButton>

        <StyledEditSignatureButton
          $themeColor={doxleThemeColor}
          $doxleFont={doxleFont}
          $bgColor={doxleThemeColor.doxleColor}
          $textColor={"white"}
          whileHover={{
            scale: 1.1,
          }}
          whileTap={{ scale: 1 }}
          onClick={handleSaveSignature}
          disabled={signPadRef.current?.isEmpty()}
        >
          Save
        </StyledEditSignatureButton>
      </StyledEditSignatureBtnWrapper>
    </StyledDoxleEditSignaturePatch>
  );
};

export default DoxleEditSignaturePatch;
