import React, { useState } from "react";
import { InventoryItem } from "../Models/Inventory";
import {
  StyledDetailTitle,
  StyledEditInventoryInput,
  StyledSubititleTextArea,
} from "./StyledEditInventory";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import InventoryQueryAPI from "../QueryAPIHooks/InventoryQueryAPI";
import useDoxleNotificationStore from "../../DoxleGeneralStore/useDoxleNotificationStore";
import { shallow } from "zustand/shallow";
import { useDoxleCurrentContextStore } from "../../DoxleGeneralStore/useDoxleCurrentContext";

type Props = {
  editField: keyof Pick<
    InventoryItem,
    "title" | "subtitle" | "quantity" | "cost"
  >;
  inventoryItem: InventoryItem;
};

export const countDecimals = (numStr: string) => {
  if (!numStr.includes(".")) {
    return 0;
  }
  return numStr.split(".")[1].length || 0;
};

const EditInventoryField = ({ editField, inventoryItem }: Props) => {
  const [prevInputVal, setPrevInputVal] = useState<string>(
    inventoryItem[editField]
  );
  const [inputValue, setInputValue] = useState<string>(
    inventoryItem[editField]
  );
  const { doxleThemeColor, doxleFont, currentTheme } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
      currentTheme: state.currentTheme,
    })
  );
  const company = useDoxleCurrentContextStore(
    (state) => state.currentCompany,
    shallow
  );
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );

  const updateInventoryQuery = InventoryQueryAPI.useUpdateInventoryQuery({
    company,
    showNotification,
    onSuccessUpdateCb: () => {
      setPrevInputVal(inputValue);
    },
  });

  const handleInputChange = () => {
    if (prevInputVal === inputValue) {
      return;
    }
    if (editField === "title") {
      if (inputValue.length === 0) {
        setInputValue(prevInputVal);
        return;
      }
    } else if (editField === "quantity" || editField === "cost") {
      if (isNaN(Number(inputValue)) || inputValue === "") {
        setInputValue(prevInputVal);
        return;
      } else {
        if (countDecimals(inputValue) > 4) {
          setInputValue(prevInputVal);
          return;
        }
      }
    }

    updateInventoryQuery.mutate({
      inventoryId: inventoryItem.inventoryId,
      updatedData: {
        [editField]: inputValue,
      },
    });
  };

  return (
    <>
      <StyledDetailTitle $themeColor={doxleThemeColor}>
        {editField === "cost"
          ? "Price (ex gst)"
          : editField.charAt(0).toUpperCase() + editField.slice(1)}
      </StyledDetailTitle>
      {editField === "title" && (
        <StyledEditInventoryInput
          $currentTheme={currentTheme}
          $themeColor={doxleThemeColor}
          value={inputValue}
          onChange={(e) => {
            setInputValue(e.target.value);
          }}
          onBlur={handleInputChange}
        />
      )}
      {(editField === "quantity" || editField === "cost") && (
        <StyledEditInventoryInput
          width="9.9"
          $currentTheme={currentTheme}
          $themeColor={doxleThemeColor}
          value={inputValue}
          onChange={(e) => {
            setInputValue(e.target.value);
          }}
          onBlur={handleInputChange}
        />
      )}
      {editField === "subtitle" && (
        <StyledSubititleTextArea
          $currentTheme={currentTheme}
          $themeColor={doxleThemeColor}
          $doxleFont={doxleFont}
          value={inputValue}
          onChange={(e) => {
            setInputValue(e.target.value);
          }}
          onBlur={handleInputChange}
        />
      )}
    </>
  );
};

export default EditInventoryField;
