import React from "react";
import {
  StyledInviteBtn,
  StyledSearchInputAdornment,
  StyledSearchShareContactField,
  StyledShareProjectSearchSection,
} from "./StyledShareProjectDialog";
import useShareProjectSearchSection from "./Hooks/useShareProjectSearchSection";
import { IoChevronDownOutline } from "react-icons/io5";
import { editRgbaAlpha } from "../../../Utilities/FunctionUtilities";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
type Props = {};

const ShareProjectSearchSection = (props: Props) => {
  const { doxleThemeColor, staticMenuColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      staticMenuColor: state.staticMenuColor,
      doxleFont: state.doxleFont,
    }),
    shallow
  );
  const {
    searchText,
    setSearchText,
    anchorCustomPermissionEl,
    openPopover,
    closePopover,
  } = useShareProjectSearchSection({});

  return (
    <StyledShareProjectSearchSection>
      <StyledSearchShareContactField
        variant="outlined"
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
        placeholder="Email, comma separated"
        onFocus={(e) => e.target.select()}
        InputProps={{
          endAdornment: (
            <StyledSearchInputAdornment position="end" onClick={(e) => {}}>
              <span>can edit</span>
              <IoChevronDownOutline
                className="icon-dropdown"
                color={editRgbaAlpha({
                  rgbaColor: staticMenuColor.staticFontColor,
                  alpha: "0.7",
                })}
                size={14}
                style={{ marginLeft: 4 }}
              />
            </StyledSearchInputAdornment>
          ),
        }}
      />

      <StyledInviteBtn disabled={!searchText}>Invite</StyledInviteBtn>
    </StyledShareProjectSearchSection>
  );
};

export default ShareProjectSearchSection;
