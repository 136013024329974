import React from "react";
import { StyledBookingProjectFilterMenuContainer } from "./StyledComponentBookingProject";
import BookingFilterOption from "./BookingFilterOption";

type Props = {};

const BookingProjectFilterMenu = (props: Props) => {
  return (
    <StyledBookingProjectFilterMenuContainer>
      <BookingFilterOption dueType="week" />
      <BookingFilterOption dueType="fortnight" />
      <BookingFilterOption dueType="month" />
      <BookingFilterOption dueType="completed" />
    </StyledBookingProjectFilterMenuContainer>
  );
};

export default BookingProjectFilterMenu;
