import {useMeasurementsStore} from "../MeasurementsStore/useMeasurementsStore";
import {shallow} from "zustand/shallow";
import {toFloat} from "../konvaFunctions/generalFunctions";
import {DoxPolygon, XY} from "../Models/takeOffs";
import {useMemo} from "react";
import {nanoid} from "nanoid";

interface RectangleProps {}
const usePolygon = ({}: RectangleProps) => {

  const calculatePolygonPerimeter = (points: XY[]) => {
    let perimeter = 0;
    for (let i = 1; i < points.length; i++){
      perimeter += Math.sqrt(Math.pow(toFloat(points[i].x) - toFloat(points[i-1].x),2) + Math.pow(toFloat(points[i].y) - toFloat(points[i-1].y) ,2));
    }
    perimeter += Math.sqrt(Math.pow(toFloat(points[points.length - 1].x) - toFloat(points[0].x),2) + Math.pow(toFloat(points[points.length - 1].y) - toFloat(points[0].y) ,2));
    return perimeter
  }

  const calculatePolygonArea = (points: XY[]) => {
    let area = 0;
    for (let i = 1; i < points.length; i++){
      area += toFloat(points[i-1].x) * toFloat(points[i].y);
      area -= toFloat(points[i-1].y) * toFloat(points[i].x);
    }
    area += toFloat(points[points.length -1].x) * toFloat(points[0].y);
    area -= toFloat(points[points.length -1].y) * toFloat(points[0].x);
    area = area / 2;
    return Math.abs(area)
  }


  const {
    currentShape, setCurrentShape, deduction, currentColor, currentUnit, zHeight, currentSheet, currentTakeOff, addMeasurement
  } = useMeasurementsStore(state => ({
    currentShape: state.currentShape,
    setCurrentShape: state.setCurrentShape,
    deduction: state.deduction,
    currentColor: state.currentColor,
    currentUnit: state.currentUnit,
    zHeight: state.zHeight,
    currentSheet: state.currentSheet,
    currentTakeOff: state.currentTakeOff,
    addMeasurement: state.addMeasurement
  }), shallow)
  const scale: number = toFloat(currentSheet?.scale)
  const mouseMove = (pos: XY) => {
    if (!currentShape) return;
    const currentPolygon = currentShape as DoxPolygon
    let newPoints: XY[] = currentPolygon.points
    newPoints.splice(currentPolygon.points.length -1, 1, pos)
    let rawValue:number = 0;
    let scaledValue : number = 0;
    let actualValue : number = 0;
    if (currentUnit === "M2"){
      rawValue = calculatePolygonArea(newPoints) * deduction
      scaledValue = rawValue * Math.pow(scale, 2)
      actualValue = scaledValue;
    } else if (currentUnit === "M3"){
      rawValue = calculatePolygonArea(newPoints) * deduction
      scaledValue = rawValue * toFloat(currentShape.zMultiplier) * Math.pow(scale, 2)
      actualValue = scaledValue;
    } else if (currentUnit === "LM") {
      rawValue = calculatePolygonPerimeter(newPoints) * deduction
      scaledValue = rawValue * scale;
      actualValue = scaledValue * toFloat(currentShape.zMultiplier);
    } else return

    setCurrentShape({
      ...currentPolygon,
      points: newPoints,
      rawValue: rawValue,
      scaledValue: scaledValue,
      actualValue: actualValue
    } as DoxPolygon)
  }

  const mouseUp = (pos: XY) => {
    if (!currentShape){
      setCurrentShape({
        borderColor: deduction < 0 ? "#000" : currentColor,
        bgColor: deduction < 0 ? "#000" : currentColor,
        borderThickness: 2,
        shape: "polygon",
        id: nanoid(),
        zMultiplier: zHeight && currentUnit === "M3" ? zHeight : 1.0,
        rawValue: 0.0,
        scaledValue: 0.0,
        actualValue: 0.0,
        sheet: currentSheet?.sheetId,
        takeOff: currentTakeOff?.takeOffId,
        points: [pos, pos]
      } as DoxPolygon)
    } else {
      let currentPolygon = currentShape as DoxPolygon
      const complete = Math.abs(toFloat(currentPolygon.points[0].x) - pos.x) <=10 && Math.abs(toFloat(currentPolygon.points[0].y) - pos.y) <=10
      setCurrentShape(null)
      let newPoints: XY[] = currentPolygon.points
      complete
        ? newPoints.splice(currentPolygon.points.length -1, 0, currentPolygon.points[0])
        : newPoints.splice(currentPolygon.points.length -1, 0, pos)
      let rawValue: number = 0;
      let scaledValue: number = 0;
      let actualValue: number = 0;
      if (currentUnit === "LM") {
        rawValue = calculatePolygonPerimeter(newPoints) * deduction;
        scaledValue = rawValue * scale;
        actualValue = scaledValue;
      } else if (currentUnit === "M2"){
        rawValue = calculatePolygonArea(newPoints) * deduction;
        scaledValue = rawValue * Math.pow(scale, 2);
        actualValue = scaledValue;
      } else if (currentUnit === "M3"){
        rawValue = calculatePolygonArea(newPoints) * deduction;
        scaledValue = rawValue * Math.pow(scale, 2);
        actualValue = scaledValue * zHeight;
      } else return

      currentPolygon.points = newPoints
      currentPolygon.rawValue = rawValue
      currentPolygon.scaledValue = scaledValue
      currentPolygon.actualValue = actualValue

      if (complete) {
        addMeasurement(currentPolygon);
      } else { setCurrentShape({ ...currentPolygon}) }
    }
  }

  return {mouseMove, mouseUp}
}

export default usePolygon