import { motion } from "framer-motion";
import styled from "styled-components";

import { FormControl, TextField } from "@mui/material";
import {
  DoxleFont,
  DoxleThemeColor,
} from "../../../DoxleGeneralStore/useDoxleThemeStore";
import {
  TRgbaFormat,
  editRgbaAlpha,
} from "../../../Utilities/FunctionUtilities";

export const StyledScopeImageGallery = styled(motion.div)<{
  $themeColor: DoxleThemeColor;
}>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100%);
  display: flex;
  background-color: ${(p) => p.$themeColor.primaryContainerColor};
  z-index: 100;

  .gallery-content-wrapper {
    width: calc(100% - 4rem);
    height: calc(100% - 6.4rem);
    display: flex;
    position: relative;
    padding: 5rem 2rem 1.4rem 2rem;
  }
`;

export const StyledScopeImgContainer = styled(motion.div)`
  width: calc(25% - 2rem);
  aspect-ratio: 1;
  padding: 1rem;
  display: flex;
  flex: none;
  align-content: stretch;
  box-sizing: border-box;

  @media screen and (max-width: 767px) {
    width: calc(50% - 2rem);
  }
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    width: calc(50% - 2rem);
  }
  @media screen and (min-width: 1024px) and (max-width: 1279px) {
    width: calc(${100 / 3}% - 2rem);
  }
  @media screen and (min-width: 1280px) and (max-width: 1559px) {
    width: calc(25% - 2rem);
  }
  @media (min-width: 1560px) {
    width: calc(20% - 2rem);
  }
`;
export const StyledScopeImgListContainer = styled(motion.div)`
  display: flex;
  flex-wrap: wrap;

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;
export const StyledScopeImageGalleryItem = styled(motion.div)`
  width: 100%;
  height: 100%;
  display: flex;

  flex-direction: column;
  position: relative;

  overflow: hidden;
`;
export const StyledListScroller = styled.div`
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;
export const StyledScopeImageHolder = styled.img`
  width: 100%;
  height: fit-content;
`;
export const StyledScopeImageInfoTextField = styled(TextField)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
  $isTitle: boolean;
}>`
  && {
    width: 100%;
    margin-top: ${(p) => (p.$isTitle ? "0.8rem" : "0.4rem")};
    padding-bottom: 2px;
  }
  input {
    color: ${(p) => p.$themeColor.primaryFontColor};
    font-size: ${(p) => (p.$isTitle ? "1.1rem" : "1.4rem")};
    font-family: ${(p) => p.$doxleFont.secondaryFont};
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: ${(p) => (p.$isTitle ? "0.055rem" : "0.09rem")};
    padding: 0px 0px 1px 0px !important;
  }
  & .MuiInput-input.Mui-disabled {
    -webkit-text-fill-color: ${(p) => p.$themeColor.primaryFontColor};
  }

  & .MuiInput-root.Mui-disabled:before {
    border: none !important;
  }

  & .MuiInput-root:after {
    border-bottom: 1px solid ${(p) => p.$themeColor.primaryFontColor};
  }
  & .MuiInput-root:hover:not(.Mui-disabled, .Mui-error):before {
    border-bottom: 1px solid
      ${(p) =>
        editRgbaAlpha({
          rgbaColor: p.$themeColor.primaryFontColor as TRgbaFormat,
          alpha: "0.4",
        })};
  }
  & .MuiInput-root:before {
    border-bottom: 1px solid
      ${(p) =>
        editRgbaAlpha({
          rgbaColor: p.$themeColor.primaryFontColor as TRgbaFormat,
          alpha: "0.4",
        })};
  }
`;
export const StyledScopeImageInfoForm = styled(FormControl)`
  && {
    width: 100%;
    display: flex;
  }
`;
export const StyledScopeImageToggleMenu = styled(motion.div)`
  display: flex;
  position: absolute;
  top: 0.8rem;
  right: 0.8rem;
  z-index: 3;
`;

export const StyledSelectedScopeImageEffect = styled(motion.div)<{
  $themeColor: DoxleThemeColor;
}>`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 5;
  background-color: ${(p) => p.$themeColor.primaryBackdropColor};
  display: flex;
  justify-content: center;
  align-items: center;
`;
