import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { PricebookSupplierRate } from "../../../Models/Pricebook";
import { useEditPricebookItemContext } from "../EditPricebookItem";
import { produce } from "immer";
import useEffectAfterMount from "../../../../CoreContent/UtilityHooks/useEffectAfterMount";
import { useDoxleCurrentContextStore } from "../../../../DoxleGeneralStore/useDoxleCurrentContext";
import PricebookQueryAPI from "../../../QueryHooks/PricebookQueryAPI";
import useDoxleNotificationStore from "../../../../DoxleGeneralStore/useDoxleNotificationStore";
import { usePricebookStore } from "../../../Store/usePricebookStore";
import { useShallow } from "zustand/react/shallow";
import useSetPricebookQueryData from "../../../QueryHooks/useSetPricebookQueryData";

type Props = {
  rate: PricebookSupplierRate;
  index: number;
};

interface IEditSupplierRateLine {
  handleUpdateUnitCost: (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  handleUpdateUnit: (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  handleDeleteRate: () => void;
  showWarningTooltip: boolean;
  setShowWarningTooltip: React.Dispatch<React.SetStateAction<boolean>>;
  rateSupplierRef: React.RefObject<HTMLInputElement>;
  unitCostRef: React.RefObject<HTMLInputElement>;
  handleClickSupplierName: (e: React.MouseEvent<HTMLInputElement>) => void;
  handleBlurSupplierName: (e: React.FocusEvent<HTMLInputElement>) => void;
  handleUpdateSupplierName: (e: React.ChangeEvent<HTMLInputElement>) => void;
}
const useEditSupplierRateLine = ({
  rate,
  index,
}: Props): IEditSupplierRateLine => {
  const [showWarningTooltip, setShowWarningTooltip] = useState(false);
  const { setShowSupplierList, pricebookId, setUpdatedPricebookData } =
    useEditPricebookItemContext();
  const company = useDoxleCurrentContextStore((state) => state.currentCompany);
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification
  );
  const { rateFilter } = usePricebookStore(
    useShallow((state) => ({
      rateFilter: state.pricebookRateQueryFilter,
    }))
  );

  const { handleRemovePricebookRate } = useSetPricebookQueryData({
    itemId: pricebookId,
  });
  const mutateSupplierRate = PricebookQueryAPI.useSupplierRateMutationQuery({
    company,
    showNotification,
    filter: rateFilter,
    onSuccessRemoveCb: (id) => {
      handleRemovePricebookRate(id);
    },
  });
  const handleUpdateSupplierName = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setUpdatedPricebookData(
      produce((draft) => {
        draft.supplierRates[index].supplierName = e.target.value;
        draft.supplierRates[index].supplier = null;
        return draft;
      })
    );
  };
  const handleUpdateUnitCost = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (!isNaN(Number(e.target.value))) {
      setUpdatedPricebookData(
        produce((draft) => {
          draft.supplierRates[index].unitCost = e.target.value;
          return draft;
        })
      );
    }
  };

  const handleUpdateUnit = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setUpdatedPricebookData(
      produce((draft) => {
        draft.supplierRates[index].uom = e.target.value;
        return draft;
      })
    );
  };

  const handleDeleteRate = () => {
    setUpdatedPricebookData(
      produce((draft) => {
        if (draft.supplierRates.length === 1) setShowWarningTooltip(true);
        else {
          draft.supplierRates.splice(index, 1);
          // if the rate item has id then delete it from the server and update the query data as well
          if (rate.rateId) {
            mutateSupplierRate.deleteSupplierRate.mutate(rate.rateId);
          }
        }
        return draft;
      })
    );
  };
  const rateSupplierRef = useRef<HTMLInputElement>(null);
  const unitCostRef = useRef<HTMLInputElement>(null);
  const handleClickSupplierName = (e: React.MouseEvent<HTMLInputElement>) => {
    setShowSupplierList({
      anchorListEl: e.currentTarget,
      rateIndex: index,
      handlePostAdd: () => {
        setTimeout(() => {
          unitCostRef.current?.focus();
        }, 400);
      },
    });
  };
  const handleBlurSupplierName = () => {
    setShowSupplierList(undefined);
  };
  useEffectAfterMount(() => {
    if (showWarningTooltip) {
      const timeout = setTimeout(() => {
        setShowWarningTooltip(false);
      }, 3000);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [showWarningTooltip]);

  useEffect(() => {
    if (rate.isNew) {
      const timeout = setTimeout(() => {
        rateSupplierRef.current?.click();
        setUpdatedPricebookData(
          produce((draft) => {
            draft.supplierRates[index].isNew = false;
            return draft;
          })
        );
      }, 300);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [rateSupplierRef.current]);

  return {
    handleUpdateUnitCost,
    handleUpdateUnit,
    handleUpdateSupplierName,
    handleDeleteRate,
    showWarningTooltip,
    setShowWarningTooltip,
    rateSupplierRef,
    unitCostRef,
    handleClickSupplierName,
    handleBlurSupplierName,
  };
};

export default useEditSupplierRateLine;
