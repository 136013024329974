import React, { createContext, useContext, useMemo } from "react";
import useAddPricebookItem from "./Hooks/useAddPricebookItem";
import { IAddPricebookItemContextValue } from "./Hooks/useAddPricebookItem";
import Loading from "../../../Utilities/Lottie/Loading";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { useShallow } from "zustand/react/shallow";
import { editRgbaAlpha } from "../../../Utilities/FunctionUtilities";
import DoxleSelectContactDropdown from "../../../DoxleDesignPattern/DoxleSelectContactDropdown/DoxleSelectContactDropdown";
import { useDropzone } from "react-dropzone";
import {
  StyledBadgeListContainer,
  StyledFormInputSection,
  StyledPricebookAddEditHeadTitle,
  StyledPricebookAddNewContainer,
  StyledPricebookAddNewInputDiv,
  StyledPricebookAddNewInputText,
  StyledPricebookAddNewInputTitleText,
  StyledWarningText,
} from "../PricebookList/StyledComponents";
import SelectedCoreDocketItem from "./SelectedCoreDocketItem";
import Autocomplete from "@mui/material/Autocomplete";
import {
  StyledAddSwatchDropzone,
  StyledCoreDocketOption,
  StyledPricebookAddNewSupplierBtn,
  StyledPricebookDialogSubmitButton,
  StyledPricebookSwatchContainer,
} from "./StyledAddEditPricebookItem";
import AddedSwatchItem from "./AddedSwatchItem";
import SupplierRateHeader from "../PricebookList/SupplierRateHeader";
import { AttachmentUploadIcon } from "../../../CoreContent/EditDocketSideScreen/EditDocketSideScreenIcons";
import EditSupplierRateLine from "./EditSupplierRateLine";
import AddSupplierRateLine from "./AddSupplierRateLine";

type Props = {};

const EditPricebookItemContext =
  createContext<IAddPricebookItemContextValue | null>(null);
const AddPricebookItem = (props: Props) => {
  const styleProps = useDoxleThemeStore(
    useShallow((state) => ({
      $themeColor: state.doxleThemeColor,
      $doxleFont: state.doxleFont,
      $currentTheme: state.currentTheme,
    }))
  );
  const {
    addPricebookData,
    setAddPricebookData,
    showSupplierList,
    addPricebookItemContextValue,
    handleAddPricebook,
    selectedCoreDockets,
    handleSelectSupplier,
    handleSelectCoreDockets,
    handleAddNewSupplierRate,
    handleUpdateDescription,
    onDrop,
    isAddingPricebook,
    setShowSupplierList,
    shouldShowWarning,
    coreDocketList,
    handleRemoveAddedSwatch,
    handleRemoveAddedCoreDocket,
  } = useAddPricebookItem();
  const selectedSupplierRate = useMemo(
    () => addPricebookData.supplierRates.map((item) => item.supplier ?? ""),
    [addPricebookData.supplierRates]
  );
  const { getRootProps } = useDropzone({
    accept: {
      "image/png": [".png"],
      "image/jpg": [".jpg"],
      "image/jpeg": [".jpeg"],
    },
    onDrop,
  });
  const contactDropdownStyle: React.CSSProperties = {
    borderRadius: 9,
  };
  return (
    <EditPricebookItemContext.Provider value={addPricebookItemContextValue}>
      {isAddingPricebook && (
        <Loading
          containerStyle={{
            backgroundColor: editRgbaAlpha({
              rgbaColor: styleProps.$themeColor.primaryBackgroundColor,
              alpha: "0.8",
            }),
            position: "absolute",
          }}
          text={"Adding item, please wait..."}
        />
      )}

      <DoxleSelectContactDropdown
        popoverAnchor={showSupplierList ? showSupplierList?.anchorListEl : null}
        onClosePopover={() => {
          setShowSupplierList(undefined);
        }}
        variants="company"
        onSelectContactCompany={handleSelectSupplier}
        selectedCompanyItemIds={selectedSupplierRate}
        containerStyle={contactDropdownStyle}
      />
      <StyledPricebookAddNewContainer>
        <StyledPricebookAddEditHeadTitle>
          ADD PRICEBOOK ITEM
        </StyledPricebookAddEditHeadTitle>

        <StyledFormInputSection>
          <StyledPricebookAddNewInputDiv $width={"100%"}>
            <StyledPricebookAddNewInputTitleText>
              Item Description
            </StyledPricebookAddNewInputTitleText>
            <StyledPricebookAddNewInputText
              required
              $width="100%"
              placeholder="Description"
              value={addPricebookData.description}
              onChange={handleUpdateDescription}
            />
            <StyledWarningText
              style={{
                opacity:
                  !addPricebookData.description && shouldShowWarning ? 1 : 0,
                marginTop: 4,
              }}
            >
              Please Enter Description
            </StyledWarningText>
          </StyledPricebookAddNewInputDiv>
          <StyledPricebookAddNewInputDiv $width={"100%"} layout="position">
            <StyledPricebookAddNewInputTitleText>
              Template Dockets
            </StyledPricebookAddNewInputTitleText>
            <StyledBadgeListContainer>
              {selectedCoreDockets.map((coreDocket, i) => (
                <SelectedCoreDocketItem
                  item={coreDocket}
                  itemIndex={i}
                  key={coreDocket.coreDocketId}
                  onRemoveDocket={handleRemoveAddedCoreDocket}
                />
              ))}
            </StyledBadgeListContainer>
            <Autocomplete
              sx={{
                width: "100% !important",
                display: "flex",
                alignItems: "center",
                backgroundColor: styleProps.$themeColor.primaryContainerColor,
                "&.MuiAutocomplete-root .MuiOutlinedInput-root": {
                  padding: "0px !important",
                },
              }}
              slotProps={{
                paper: {
                  sx: {
                    backgroundColor:
                      styleProps.$themeColor.primaryContainerColor,
                  },
                },
              }}
              renderOption={({ style, ...rest }, option, state) => (
                <StyledCoreDocketOption
                  $selected={addPricebookData.coreDockets.includes(
                    option.coreDocketId
                  )}
                  {...rest}
                >
                  {option.coreDocketNumber + " " + option.coreDocketName}
                </StyledCoreDocketOption>
              )}
              placeholder="Find a core docket"
              options={coreDocketList}
              getOptionLabel={(option) =>
                option.coreDocketNumber + " " + option.coreDocketName
              }
              onChange={(event, value) => {
                if (value) handleSelectCoreDockets(value.coreDocketId);
              }}
              clearText="Clear"
              renderInput={(params) => (
                <StyledPricebookAddNewInputText
                  {...params}
                  $width="100%"
                  style={{
                    height: "100% !important",
                    margin: "0px !important",
                  }}
                />
              )}
            />
          </StyledPricebookAddNewInputDiv>

          <StyledPricebookAddNewInputDiv $width={"100%"} layout="position">
            <StyledPricebookAddNewInputTitleText>
              Swatches
            </StyledPricebookAddNewInputTitleText>

            <StyledPricebookSwatchContainer>
              {addPricebookData.swatches.map((swatch, index) => (
                <AddedSwatchItem
                  item={swatch}
                  itemIndex={index}
                  key={`addedSwatch#${index}`}
                  onRemoveAddedSwatch={handleRemoveAddedSwatch}
                />
              ))}
            </StyledPricebookSwatchContainer>

            <StyledAddSwatchDropzone {...getRootProps()}>
              <AttachmentUploadIcon
                themeColor={styleProps.$themeColor}
                containerStyle={{
                  width: "40%",
                  marginBottom: 14,
                  maxWidth: 120,
                }}
              />
              Click or drop image to upload
            </StyledAddSwatchDropzone>
          </StyledPricebookAddNewInputDiv>
          <SupplierRateHeader />
          {addPricebookData.supplierRates.map((item, index) => {
            return (
              <AddSupplierRateLine
                rate={item}
                index={index}
                key={`rate#${index}`}
              />
            );
          })}

          <StyledPricebookAddNewSupplierBtn onClick={handleAddNewSupplierRate}>
            Add Another Supplier
          </StyledPricebookAddNewSupplierBtn>
        </StyledFormInputSection>
        <StyledPricebookDialogSubmitButton
          type="submit"
          onClick={handleAddPricebook}
        >
          SUBMIT
        </StyledPricebookDialogSubmitButton>
      </StyledPricebookAddNewContainer>
    </EditPricebookItemContext.Provider>
  );
};

export const useAddPricebookItemContext = () =>
  useContext(EditPricebookItemContext) as IAddPricebookItemContextValue;

export default AddPricebookItem;
