import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Project } from "../../Models/project";
import { PaperProps } from "@mui/material";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import { Components, ItemContent, Virtuoso } from "react-virtuoso";
import {
  StyledAddAddProjectButton,
  StyledListScroller,
  StyledProjectListContainer,
  StyledProjectSearchFieldWrapper,
  StyledProjectSearchTextField,
  StyledSearchProjectWrapper,
} from "./StyledComponentsProjectSelectWithDropdown";
import DoxleEmptyPlaceHolder from "../DoxleEmptyPlaceHolder/DoxleEmptyPlaceHolder";
import ListLoadingMore from "../../Utilities/Lottie/ListLoadingMore";
import ProjectItemSelection from "./ProjectItemSelection";
import { useProjectSelectDropDownContext } from "./ProjectSelectWithDropdown";
import AddProjectForm from "./AddProjectForm";

type Props = {
  handleCloseProjectDropdown: () => void;
  isFetchingProject: boolean;
  isSuccessFetchingProject: boolean;
  isErrorFetchingProject: boolean;
  isFetchingNextPage: boolean;
  projectList: Project[];
  handleSelectProject: (item: Project) => void;

  selectedProject?: Project | undefined;
  dropdownWrapperStyle?: PaperProps["sx"];
};
interface ProjectListContext {}
const ProjectDropdownList = ({
  handleCloseProjectDropdown,
  isFetchingProject,
  isSuccessFetchingProject,
  isErrorFetchingProject,
  isFetchingNextPage,
  projectList,
  handleSelectProject,
  dropdownWrapperStyle,
  selectedProject,
}: Props) => {
  const [searchInput, setSearchInput] = useState("");
  const [showAddProjectForm, setShowAddProjectForm] = useState(false);

  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }),
    shallow
  );
  const { setSearchProjectText, handleFetchMoreProject } =
    useProjectSelectDropDownContext();
  useEffect(() => {
    const timeout = setTimeout(() => {
      setSearchProjectText(searchInput);
    }, 200);

    return () => clearTimeout(timeout);
  }, [searchInput]);

  //*render list
  const components: Components<Project, ProjectListContext> = useMemo(
    () => ({
      Scroller: React.forwardRef((props, ref) => (
        <StyledListScroller ref={ref} {...props} />
      )),
      EmptyPlaceholder: () => (
        <DoxleEmptyPlaceHolder
          headTitleText="No Projects"
          subTitleText="Add more projects to work..."
          containerStyle={{
            width: "100%",
            height: "100%",
          }}
          headTitleTextStyle={{ fontSize: "16px" }}
          subTitleTextStyle={{ fontSize: "12px" }}
        />
      ),
    }),
    []
  );

  const itemContent: ItemContent<Project, ProjectListContext> = useCallback(
    (index, data, context) => (
      <ProjectItemSelection
        projectItem={data}
        handleSelectProject={handleSelectProject}
        key={data.projectId}
        selectedProject={selectedProject}
      />
    ),
    [handleSelectProject, selectedProject]
  );

  const projectListStyle = useMemo(
    () => ({
      height: projectList.length > 30 ? "35rem" : "30rem",
      width: "100%",
    }),
    [projectList]
  );
  return (
    <StyledProjectListContainer
      $themeColor={doxleThemeColor}
      elevation={2}
      sx={dropdownWrapperStyle}
    >
      <StyledSearchProjectWrapper
        $themeColor={doxleThemeColor}
        $doxleFont={doxleFont}
      >
        {!showAddProjectForm ? (
          <StyledProjectSearchFieldWrapper>
            <StyledProjectSearchTextField
              $themeColor={doxleThemeColor}
              $doxleFont={doxleFont}
              variant="standard"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
              onFocus={(e) => e.currentTarget.select()}
              placeholder="Search Project..."
              autoFocus
            />
          </StyledProjectSearchFieldWrapper>
        ) : (
          <span className="form-title">Add Project</span>
        )}
        <StyledAddAddProjectButton
          $themeColor={doxleThemeColor}
          $doxleFont={doxleFont}
          onClick={() => {
            setShowAddProjectForm((prev) => !prev);
            setSearchInput("");
          }}
        >
          {!showAddProjectForm ? "Add Project" : "Close"}
        </StyledAddAddProjectButton>
      </StyledSearchProjectWrapper>

      {isSuccessFetchingProject && !showAddProjectForm && (
        <Virtuoso
          data={projectList}
          style={projectListStyle}
          components={components}
          atBottomThreshold={0.2}
          itemContent={itemContent}
          endReached={handleFetchMoreProject}
        />
      )}

      {isErrorFetchingProject && !showAddProjectForm && (
        <DoxleEmptyPlaceHolder
          headTitleText="Something wrong!"
          subTitleText="Failed to get projects..."
          containerStyle={{
            width: "100%",
            height: "25rem",
          }}
          headTitleTextStyle={{ fontSize: "1.6rem" }}
          subTitleTextStyle={{ fontSize: "1.2rem" }}
        />
      )}

      {isFetchingNextPage && !showAddProjectForm && (
        <ListLoadingMore
          animationSize={60}
          containerStyle={{
            position: "absolute",
            bottom: 0,
            left: 0,
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 10,
          }}
        />
      )}

      {showAddProjectForm && (
        <AddProjectForm handleCloseForm={() => setShowAddProjectForm(false)} />
      )}
    </StyledProjectListContainer>
  );
};

export default ProjectDropdownList;
