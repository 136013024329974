import React from "react";
import { TPOPaymentTermsSuffix } from "../../models/quote";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { useQuoteContext } from "./QuoteResponse";
import {
  StyledPaymentSuffixPopupContainer,
  StyledQuoteResponseErrorText,
  StyledQuoteResponseFieldWrapper,
  StyledQuoteResponseInputAdornment,
  StyledQuoteResponseLabelFieldText,
  StyledQuoteResponseTextField,
  StyledResponseNoticeText,
} from "./StyledComponentQuoteResponse";
import useQuoteResponsePaymentTerms from "./Hooks/useQuoteResponsePaymentTerms";
import { AnimatePresence } from "framer-motion";
import { Popover } from "@mui/material";

type Props = {};
const SUFFIX_LIST: TPOPaymentTermsSuffix[] = ["Days", "NET", "EOM"];
const QuoteResponsePaymentTerms = (props: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore((state) => ({
    doxleThemeColor: state.doxleThemeColor,
    doxleFont: state.doxleFont,
  }));

  const { quoteResponse } = useQuoteContext();
  const {
    newPaymentTerm,
    setNewPaymentTerm,
    anchorPopoverTermSuffix,
    handleClickInputAdornment,
    handleClosePopoverSuffix,
    handleSelectSuffix,
  } = useQuoteResponsePaymentTerms({});
  const isOpenPaymentSuffixPopper = Boolean(anchorPopoverTermSuffix);
  const id = isOpenPaymentSuffixPopper ? "suffix-list-popper" : undefined;
  return (
    <StyledQuoteResponseFieldWrapper>
      <StyledQuoteResponseLabelFieldText
        $themeColor={doxleThemeColor}
        $doxleFont={doxleFont}
      >
        PREFERRED PAYMENT TERMS
      </StyledQuoteResponseLabelFieldText>
      <div className="field-row-wrapper">
        <StyledQuoteResponseTextField
          $themeColor={doxleThemeColor}
          $doxleFont={doxleFont}
          variant="standard"
          style={{ width: 80 }}
          onFocus={(e) => e.currentTarget.select()}
          inputMode="numeric"
          $textAlign="center"
          onChange={(e) => setNewPaymentTerm(e.target.value)}
          value={newPaymentTerm}
          InputProps={{
            endAdornment: (
              <StyledQuoteResponseInputAdornment
                $themeColor={doxleThemeColor}
                $doxleFont={doxleFont}
                position="end"
                onClick={handleClickInputAdornment}
              >
                {quoteResponse.paymentTermsSuffix}
              </StyledQuoteResponseInputAdornment>
            ),
          }}
        />

        <StyledResponseNoticeText
          $themeColor={doxleThemeColor}
          $doxleFont={doxleFont}
        >
          (Days within our payment cycle which is 15th and 30th)
        </StyledResponseNoticeText>
      </div>

      <AnimatePresence>
        {!quoteResponse.paymentTerms && (
          <StyledQuoteResponseErrorText
            $doxleFont={doxleFont}
            initial={{ x: -5, opacity: 0 }}
            animate={{
              x: 0,
              opacity: 1,
            }}
            exit={{ x: -5, opacity: 0 }}
          >
            Please enter payment term...
          </StyledQuoteResponseErrorText>
        )}
      </AnimatePresence>
      <Popover
        id={id}
        open={Boolean(isOpenPaymentSuffixPopper)}
        anchorEl={anchorPopoverTermSuffix}
        disableScrollLock
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handleClosePopoverSuffix}
        sx={{
          "& .MuiPopover-paper": {
            backgroundColor: "transparent",
            overflow: "visible",
          },
        }}
        elevation={4}
      >
        <StyledPaymentSuffixPopupContainer
          $themeColor={doxleThemeColor}
          $doxleFont={doxleFont}
        >
          {SUFFIX_LIST.map((suffix, idx) => (
            <div
              className="suffix-choice"
              key={suffix + idx}
              style={{
                color:
                  suffix === quoteResponse.paymentTermsSuffix
                    ? doxleThemeColor.doxleColor
                    : doxleThemeColor.primaryFontColor,
              }}
              onClick={() => handleSelectSuffix(suffix)}
            >
              {suffix}
            </div>
          ))}
        </StyledPaymentSuffixPopupContainer>
      </Popover>
    </StyledQuoteResponseFieldWrapper>
  );
};

export default QuoteResponsePaymentTerms;
