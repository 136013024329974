import React, { useState } from "react";
import { WomanWithCalculator } from "../svg/womanCalculator";
import { useUploadFloorplanContext } from "../Pages/uploadFloorplan";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import {
  BudgetResult,
  ButtonArea,
  FloorplanPricePageContainer,
  FloorplanPricePageLeft,
  FloorplanPricePageRight,
  FloorplanPricePageRightInnerContainer,
  FormArea,
  StyledBudgetPrice,
  StyledDescription,
  StyledInput,
  StyledWarning,
  UserDetailSubmitBtn,
} from "./StyledFloorplanUploadPage";
import { StyledBannerContainer } from "../../UpdatedGreetingScreen/pages/styles/StyledMainPage";

const NAME_TRIGGER_ID = 0;
const MOBILE_TRIGGER_ID = 1;
const EMAIL_TRIGGER_ID = 2;

const mobileRegex = new RegExp(
  /^(?:\+?(61))? ?(?:\((?=.*\)))?(0?[2-57-8])\)? ?(\d\d(?:[- ](?=\d{3})|(?!\d\d[- ]?\d[- ]))\d\d[- ]?\d[- ]?\d{3})$/
);

const emailRegex = new RegExp(
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);

export default function FloorplanPriceResult() {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore((state) => ({
    doxleThemeColor: state.doxleThemeColor,
    doxleFont: state.doxleFont,
  }));

  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [prompt, setPrompt] = useState<string[]>(["", "", ""]);
  const [price, setPrice] = useState<number>(345897.45);
  const { stage, setStage, floorplanFormData, setFloorplanFormData } =
    useUploadFloorplanContext();

  const handleSubmit = () => {
    let valid = true;
    let newPrompt = [...prompt];

    if (!emailRegex.test(email)) {
      if (email === "") {
        newPrompt[EMAIL_TRIGGER_ID] = "Required";
      } else {
        newPrompt[EMAIL_TRIGGER_ID] = "Invalid Email";
      }
      valid = false;
    } else {
      newPrompt[EMAIL_TRIGGER_ID] = "";
    }

    if (name === "") {
      newPrompt[NAME_TRIGGER_ID] = "Required";
      valid = false;
    } else {
      newPrompt[NAME_TRIGGER_ID] = "";
    }

    if (!mobileRegex.test(mobile)) {
      if (mobile === "") {
        newPrompt[MOBILE_TRIGGER_ID] = "Required";
      } else {
        newPrompt[MOBILE_TRIGGER_ID] = "Invalid Mobile";
      }
      valid = false;
    } else {
      newPrompt[MOBILE_TRIGGER_ID] = "";
    }

    setPrompt(newPrompt);

    if (valid) {
      const floorplanData = floorplanFormData;
      floorplanData["email"] = email;
      floorplanData["name"] = name;
      floorplanData["mobile"] = mobile;
      setFloorplanFormData(floorplanData);
    }
  };

  return (
    <FloorplanPricePageContainer>
      <FloorplanPricePageLeft>
        <StyledBannerContainer>
          <WomanWithCalculator themeColor={doxleThemeColor} />
        </StyledBannerContainer>

        <BudgetResult $doxleFont={doxleFont} $themeColor={doxleThemeColor}>
          The budget for your build is{" "}
          <StyledBudgetPrice $themeColor={doxleThemeColor}>
            $
            {Number(parseFloat(price.toString()).toFixed(2)).toLocaleString(
              "en",
              { minimumFractionDigits: 2 }
            )}
          </StyledBudgetPrice>
        </BudgetResult>
      </FloorplanPricePageLeft>

      <FloorplanPricePageRight>
        <FloorplanPricePageRightInnerContainer $themeColor={doxleThemeColor}>
          <StyledDescription
            $themeColor={doxleThemeColor}
            $doxleFont={doxleFont}
          >
            Enter your details, so we can email your budget.
          </StyledDescription>
          <FormArea>
            <StyledWarning $doxleFont={doxleFont} $themeColor={doxleThemeColor}>
              {prompt[NAME_TRIGGER_ID]}
            </StyledWarning>
            <StyledInput
              $themeColor={doxleThemeColor}
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Name *"
            />
            <br />
            <StyledWarning $doxleFont={doxleFont} $themeColor={doxleThemeColor}>
              {prompt[MOBILE_TRIGGER_ID]}
            </StyledWarning>
            <StyledInput
              $themeColor={doxleThemeColor}
              type="text"
              value={mobile}
              onChange={(e) => setMobile(e.target.value)}
              placeholder="Mobile *"
            />
            <br />
            <StyledWarning $doxleFont={doxleFont} $themeColor={doxleThemeColor}>
              {prompt[EMAIL_TRIGGER_ID]}
            </StyledWarning>
            <StyledInput
              $themeColor={doxleThemeColor}
              placeholder="Email *"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <ButtonArea>
              <UserDetailSubmitBtn
                $themeColor={doxleThemeColor}
                onClick={handleSubmit}
              >
                Submit
              </UserDetailSubmitBtn>
            </ButtonArea>
          </FormArea>
        </FloorplanPricePageRightInnerContainer>
      </FloorplanPricePageRight>
    </FloorplanPricePageContainer>
  );
}
