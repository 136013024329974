import { SVGProps } from "react";
import { DoxleThemeColor } from "../../DoxleGeneralStore/useDoxleThemeStore";

interface AutocompleteSVGProps extends SVGProps<SVGSVGElement> {
  $themeColor: DoxleThemeColor;
}
export const TagRemoveIcon = ({
  $themeColor,
  ...rest
}: AutocompleteSVGProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={25}
    height={25}
    fill="none"
    {...rest}
  >
    <g clipPath="url(#a)">
      <path
        fill={$themeColor.doxleColor}
        d="M3.816 21.491A12.5 12.5 0 1 1 21.185 3.508 12.5 12.5 0 0 1 3.816 21.491Zm10.588-8.837 3.537-3.538-1.762-1.762-3.525 3.537-3.538-3.537-1.762 1.762 3.537 3.538-3.537 3.537 1.762 1.763 3.538-3.538 3.537 3.538 1.763-1.763-3.538-3.537h-.012Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill={$themeColor.primaryFontColor} d="M0 0h25v25H0z" />
      </clipPath>
    </defs>
  </svg>
);
