import { styled } from "styled-components";
import { DoxleFont, DoxleTheme, DoxleThemeColor } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { TextareaAutosize } from '@mui/base';
import {FullMailThemeColor} from "../../Store/mailStore";
import {TextField} from "@mui/material";
export const StyledDoxleMailScreenContainer = styled.div<{
  $themeColor?: FullMailThemeColor,
  $project?: boolean,
}>`
    min-height: ${(p) => p.$project ? "Calc(100vh - 220px)" : "Calc(100vh - 150px)"};
    padding: 25px 90px;
    border-radius:12px;
    position: relative;
    margin: ${(p) => p.$project ? "0 " : "80px "} auto 0;
    background-color: ${(p) => p?.$themeColor?.primaryReverseFontColor};
    border:1px solid ${(p) => p?.$themeColor?.bolderBorderColor};
    max-width: 1100px;
`
export const StyledMailConversationHead = styled.div`
  display:flex;
  margin-bottom: 15px;
  word-break: break-word;
`
export const StyledMailConversationFrom = styled.div`
display:flex;
padding-top: 20px;
`
export const StyledMailConversationInputControl = styled.div<{
  width?: number
}>`
  width:${(p) => p?.width}%;
`
export const StyledMailConversationInput = styled.div<{
  $themeColor?: FullMailThemeColor,
}>`
.MuiFormControl-fullWidth{
  max-width:204px;
}
.MuiInput-root{
  border:0px;
  &:after,&:hover:not(.Mui-disabled, .Mui-error):before{
  border:0px;
  }
}
input{
  height:29px;
  padding: 4px 5px !important;
  border:0;
  &[type="text" i]{
    font-weight: 600;
    font-size: 12px;
    line-height: 12.12px;
  }
}
`
export const StyledMailConversationInputLabel = styled.label<{
  $themeColor?: FullMailThemeColor,
}>`
  font-size:12px;
  font-weight:600;
  display:block;
  line-height:12.12px;
  margin-bottom: 5px;
  color:${(p) => p?.$themeColor?.mailConversationInputLabel};
`
export const StyledBadgeListContainer = styled.div`
display:flex;
flex-wrap:wrap;
align-items: center;
`

export const StyledBadgeList = styled.div<{
  $themeColor?: FullMailThemeColor,
}>`
background-color:${(p) => p?.$themeColor?.badgeColor ?? ""};
  color:${(p) => p?.$themeColor?.primaryFontColor};
  padding: 3px 10px;
  position:relative;
  font-size:12px;
  font-weight:500;
  margin-right:10px;
  margin-top:0px;
  border-radius:9px;
  img{
    position:absolute;
    top: -5px;
    right: -3px;
  }
  cursor: pointer;
`
export const StyledMailConversationButtonWatchersAdd = styled.button`
  background:transparent;
  border:unset;
`
export const StyledTabList = styled.ul<{
  $themeColor?: FullMailThemeColor,
}>`
margin:0px;
padding:0;
padding-top:25px;
display:flex;
border-bottom:1px solid ${(p) => p?.$themeColor?.semiBoldDividerColor};
`
export const StyledTabListItem = styled.li<{
  $themeColor?: FullMailThemeColor,
}>`
  list-style:none;
  button{
    background-color:transparent;
    border:unset;
    cursor:pointer;
    font-weight:500;
    font-size:13px;
    line-height:15.46px;
    color:${(p) => p?.$themeColor?.mailConversationTabColor};
    padding:7px 15px;
    &.active{
      font-weight:600;
      position:relative;
      color:${(p) => p?.$themeColor?.primaryFontColor};
      &:before{
        content:'';
        position:absolute;
        width: 100%;
        height: 1px;
        left: 0px;
        bottom: -1px;
        background-color:${(p) => p?.$themeColor?.doxleColor};
      }
    }
  }
`
export const StyledCommentsListItem = styled.div<{
  $themeColor?: FullMailThemeColor,
  $doxleFont?: DoxleFont
}>`
  border:1px solid ${(p) => p?.$themeColor?.bolderBorderColor};
  background: rgb(245 246 247 / 25%);
  border-radius:10px;
  padding:10px 20px;
  margin-top:40px;
`
export const StyledReplyListItem = styled.div<{
  $themeColor?: DoxleThemeColor,
}>`
  margin-top:40px;
  
`
export const StyledReplyListItemContainer = styled.div<{
  $themeColor?: FullMailThemeColor,
  $doxleFont?: DoxleFont
}>`
  background:${(p) => p?.$themeColor?.lightDividerColor};
  border-radius:10px;
  padding:10px 20px;
  &:hover{
    .checkMark{
      visibility:visible;
    }
  }
  p{
    color:${(p) => p?.$themeColor?.replyTextColor};
  }
  &.marked-official{
    p{
      color:${(p) => p?.$themeColor?.primaryFontColor};
    }
  }
  &.marked-official{
    border:1px solid ${(p) => p?.$themeColor?.bolderBorderColor};
    background:rgba(112, 112, 239, 0.25);
  }
`
export const StyledCommentsDateLabel = styled.label<{
  $themeColor?: FullMailThemeColor,
  $doxleFont?: DoxleFont
}>`
  color:${(p) => p?.$themeColor?.mailConversationInputLabel};
  &.marked-official{
    color:${(p) => p?.$themeColor?.primaryFontColor};
  }
`
export const StyleCommentTextBody = styled.p<{
  $themeColor?: FullMailThemeColor,
}>`
  font-weight:700;
    font-size:12px;
    display:flex;
    align-items: flex-start;
    justify-content:space-between;
    line-height:12.12px;
    color:${(p) => p?.$themeColor?.mailsCommentColor};
    img{
      opacity: 0.5;
      visibility:hidden;
    }
`
export const StyledCommentUserName = styled.div<{
  $themeColor?: FullMailThemeColor,
  $doxleFont?: DoxleFont
}>`
    color:${(p) => p?.$themeColor?.mailConversationTabColor};
    font-size:10px;
    font-family:${(p) => p?.$doxleFont?.subTitleFont};
    font-weight:500;
    line-height:10.1px;
    margin-bottom:5px;
    &.marked-official{
      color:${(p) => p?.$themeColor?.primaryFontColor};
    }
`
export const StyleCommentPDF = styled.div<{
  $themeColor?: DoxleThemeColor,
  $doxleFont?: DoxleFont
}>`
    padding-right:20px;
    display: flex;
    font-weight:400;
    font-size:10px;
    line-height:13px;
    align-items: center;
    color:${(p) => p?.$themeColor?.primaryFontColor};
    font-family:${(p) => p?.$doxleFont?.subTitleFont};
    cursor: pointer;
    img{
      padding-right:10px
    }
`
export const StyleWriteMail = styled(TextField)<{
  $themeColor?: DoxleThemeColor,
  $doxleFont?: DoxleFont
}>`
    background:rgba(112, 112, 239, 0.1);
    border-radius:0px 8px 8px 8px;
    height:auto;
    width:100%;
    font-family:${(p) => p?.$doxleFont?.primaryFont};
    color:${(p) => p?.$themeColor?.primaryFontColor};
    border:1px solid rgba(223, 226, 246, 1);
    box-sizing: border-box;
    resize: none;
    padding: 15px;
    & .MuiOutlinedInput-notchedOutline {
      border: none !important;
    }
    & .MuiInputBase-root{
      border: none !important;
    }
    &::placeholder{
      font-size:14px;
      font-weight:500;
      color:${(p) => p?.$themeColor?.primaryFontColor};
    }
`
export const StyleReplyMarkedofficial = styled.div<{
  $themeColor?: DoxleThemeColor,
  $doxleFont?: DoxleFont
}>`
  font-size:10px;
  font-weight:400;
  margin-bottom:10px;
  display:flex;
  align-items:center;
  line-height:13px;
  color:${(p) => p?.$themeColor?.primaryFontColor};
  font-family:${(p) => p?.$doxleFont?.subTitleFont};
  img{
    margin-right:5px;
  }
`
export const StyleScrollComment = styled.div`
  width: 100%;
  height: calc(100vh - 500px);
  overflow:auto;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`
export const StyledAttachmentsArea = styled.div<{
  $themeColor: DoxleThemeColor,
  $doxleFont: DoxleFont
}>`
  margin-top:30px;
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: end;
  flex-direction: row;
  align-items: center;
  font-weight: 400;
  font-size: 11px;
  font-family: ${(p) => p.$doxleFont.subTitleFont};
`

export const StyledAttachmentContainer = styled.div`
  flex-direction: row;
  display: flex;
  justify-content: end;
  margin: 0px 5px;
  overflow: hidden;
  max-height: 25px;
  cursor: pointer;
`
