import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import { Contact, ContactCompany } from "../../Models/addressBook";
import { StyledContactItem } from "./StyledDoxleSelectContactDropdown";
import { HiOutlineUserRemove } from "react-icons/hi";
import { useShallow } from "zustand/react/shallow";
interface BaseProps {
  selected: boolean;
}

interface CompanyProps extends BaseProps {
  company: ContactCompany;
  onSelect: (item: ContactCompany) => void;

  onRemoveContactCompany?: (item: ContactCompany) => void;
  variants: "company";
}

interface ContactProps extends BaseProps {
  contact: Contact;
  onSelect: (item: Contact) => void;
  onRemoveContact?: (item: Contact) => void;
  variants: "contact";
}
type ContactItemProps = CompanyProps | ContactProps;

function ContactItem({
  variants,
  selected,
  onSelect,
  ...props
}: ContactItemProps): JSX.Element {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    useShallow((state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }))
  );
  const handleClick = () => {
    if (variants === "company" && "company" in props) {
      onSelect(props.company);
    } else if (variants === "contact" && "contact" in props) {
      onSelect(props.contact);
    }
  };
  return (
    <StyledContactItem
      onClick={handleClick}
      animate={{
        color: selected
          ? doxleThemeColor.doxleColor
          : doxleThemeColor.primaryFontColor,
      }}
      transition={{ duration: 0.2 }}
    >
      <div className="info-wrapper">
        <span className="contact-name">
          {variants === "company" && "company" in props
            ? props.company.name
            : variants === "contact" && "contact" in props
            ? `${props.contact.firstName} ${props.contact.lastName}`
            : ""}
        </span>
        <span className="email-text">
          {variants === "company" && "company" in props
            ? props.company.email
            : variants === "contact" && "contact" in props
            ? `${props.contact.email}`
            : ""}
        </span>
      </div>

      {selected &&
        variants === "company" &&
        "company" in props &&
        props.onRemoveContactCompany && (
          <HiOutlineUserRemove
            className="remove-icon"
            size={20}
            onClick={(e) => {
              e.stopPropagation();
              if (props.onRemoveContactCompany)
                props.onRemoveContactCompany(props.company);
            }}
          />
        )}

      {selected &&
        variants === "contact" &&
        "contact" in props &&
        props.onRemoveContact && (
          <HiOutlineUserRemove
            className="remove-icon"
            size={20}
            onClick={(e) => {
              e.stopPropagation();
              if (props.onRemoveContact) props.onRemoveContact(props.contact);
            }}
          />
        )}
    </StyledContactItem>
  );
}

export default ContactItem;
