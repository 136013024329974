import React, { forwardRef } from "react";
import DoxleIconButton from "../../../DoxleDesignPattern/DoxleButtons/DoxleIconButton";
import { BsFilter } from "react-icons/bs";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import useBookingCalendarFilter from "../Hooks/useBookingCalendarFilter";
import Popover from "@mui/material/Popover";
import {
  StyledFilterBookingBtn,
  StyledFilterBookingContainer,
} from "./StyledBookingCalendar";
import "react-datepicker/dist/react-datepicker.css";
import "../../CommonComponents/datePicker.css";
import ReactDatePicker from "react-datepicker";
import dayjs from "dayjs";
type Props = {};

const BookingCalendarFilter = (props: Props) => {
  const { doxleThemeColor } = useDoxleThemeStore();
  const {
    filterDropdownAnchor,
    setFilterDropdownAnchor,
    onClosePopover,
    filterStartDate,

    filterEndDate,

    onSelectFilterDateRange,
    handleSelectFilter,
    currentFilter,
  } = useBookingCalendarFilter();
  const isDropdownOpen = Boolean(filterDropdownAnchor);
  const filterDropdownId = filterDropdownAnchor
    ? "filter-booking-dropdown"
    : undefined;

  const CustomInput = forwardRef<
    HTMLButtonElement,
    React.HTMLProps<HTMLButtonElement>
  >(({ onClick }, ref) => (
    <StyledFilterBookingBtn
      onClick={onClick}
      ref={ref}
      $selected={currentFilter === "custom"}
    >
      {filterEndDate || filterStartDate
        ? `${
            filterStartDate ? dayjs(filterStartDate).format("DD MMM YY") : "*"
          } - ${filterEndDate ? dayjs(filterEndDate).format("DD MMM YY") : "*"}`
        : "Filter by date range"}
    </StyledFilterBookingBtn>
  ));
  return (
    <>
      <DoxleIconButton
        onClick={(e) => setFilterDropdownAnchor(e.currentTarget)}
        iconSource={
          <BsFilter color={doxleThemeColor.primaryFontColor} size={30} />
        }
        buttonSize={30}
        sx={{
          padding: "0 !important",
        }}
      />

      <Popover
        id={filterDropdownId}
        open={isDropdownOpen}
        anchorEl={filterDropdownAnchor}
        onClose={onClosePopover}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        slotProps={{
          paper: {
            sx: { marginTop: "8px", borderRadius: "14px", overflow: "hidden" },
          },
        }}
      >
        <StyledFilterBookingContainer>
          <StyledFilterBookingBtn
            $borderBottom={true}
            onClick={() => handleSelectFilter("2weeks")}
            $selected={currentFilter === "2weeks"}
          >
            Next 2 weeks
          </StyledFilterBookingBtn>
          <StyledFilterBookingBtn
            $borderBottom={true}
            onClick={() => handleSelectFilter("4weeks")}
            $selected={currentFilter === "4weeks"}
          >
            Next 4 weeks
          </StyledFilterBookingBtn>

          <ReactDatePicker
            selected={filterStartDate}
            onChange={onSelectFilterDateRange}
            startDate={filterStartDate}
            endDate={filterEndDate}
            selectsRange={true}
            showIcon={false}
            popperPlacement="top-end"
            customInput={<CustomInput />}
            withPortal={true}
          />
        </StyledFilterBookingContainer>
      </Popover>
    </>
  );
};

export default BookingCalendarFilter;
