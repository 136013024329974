import {
  StyledConfirmOrderButton,
  StyledEditOrderActionButton,
  StyledEditOrderContainer,
} from "./StyledComponentEditOrder";
import EditOrderGeneralInfo from "../EditOrderSideScreen/EditOrderGeneralInfo";
import EditOrderLineTable from "../EditOrderSideScreen/EditOrderLineTable";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import { useEditOrderStore } from "../../OrdersStore/useEditOrderStore";
import { useDoxleCurrentContextStore } from "../../../DoxleGeneralStore/useDoxleCurrentContext";
import useEditOrder from "./Hooks/useEditOrder";
import Loading from "../../../Utilities/Lottie/Loading";
import OrdersTabMenu from "../OrderDocketTab/OrdersTabMenu";
import { AnimatePresence, motion } from "framer-motion";
import { CircularProgress } from "@mui/material";
import EmailOrder from "../EmailOrder/EmailOrder";
import AcceptOrderDialog from "./AcceptOrderDialog";
import {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useMemo,
} from "react";
import Order from "../../Models/order";
import { editRgbaAlpha } from "../../../Utilities/FunctionUtilities";
import DoxleDialogHelper from "../../../DoxleDesignPattern/DoxleDialogHelper/DoxleDialogHelper";
import ConfirmOverBudgetDialog from "./ConfirmOverBudgetDialog";
import { useShallow } from "zustand/react/shallow";
import SubmitPaymentDialog from "./SubmitPaymentDialog";

type Props = {};

export interface EditOrderContextValue {
  setUpdatedOrder: Dispatch<
    SetStateAction<Omit<Partial<Order>, "orderId"> | undefined>
  >;
  isFetchingOrderDetail: boolean;
  isErrorFetchingOrderDetail: boolean;
  updatedOrder: Omit<Partial<Order>, "orderId"> | undefined;
  isOrderLineUpdated: boolean;
  setIsOrderLineUpdated: Dispatch<SetStateAction<boolean>>;
}

const EditOrderContext = createContext<EditOrderContextValue | null>(null);
const EditOrder = (props: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    useShallow((state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }))
  );
  const company = useDoxleCurrentContextStore(
    useShallow((state) => state.currentCompany)
  );
  const { editedOrder, setShowAcceptOrderDialog, readOnly, setEditedOrder, showPaymentOptions } =
    useEditOrderStore(
      useShallow((state) => ({
        editedOrder: state.editedOrder,
        setShowAcceptOrderDialog: state.setShowAcceptOrderDialog,
        readOnly: state.readOnly,
        setEditedOrder: state.setEditedOrder,
        showPaymentOptions: state.showPaymentOptions
      }))
    );

  const {
    isSendingOrder,
    isAddingOrder,
    isGeneratingPdf,
    updatedOrder,
    setUpdatedOrder,
    isFetchingOrderDetail,
    isErrorFetchingOrderDetail,
    handleClickSaveBtn,
    isUpdatingOrder,
    isOrderLineUpdated,
    setIsOrderLineUpdated,
    handleClickCancelBtn,
    showWarningChangesDialog,
    setShowWarningChangesDialog,
  } = useEditOrder({});

  const rootVariants = {
    initial: {
      x: -10,
      opacity: 0,
    },
    entering: {
      x: 0,
      opacity: 1,
    },
  };

  const contextValue: EditOrderContextValue = useMemo(
    () => ({
      updatedOrder,
      setUpdatedOrder,
      isFetchingOrderDetail,
      isErrorFetchingOrderDetail,
      isOrderLineUpdated,
      setIsOrderLineUpdated,
    }),
    [
      updatedOrder,
      isFetchingOrderDetail,
      isErrorFetchingOrderDetail,
      isOrderLineUpdated,
    ]
  );
  return (
    <EditOrderContext.Provider value={contextValue}>
      <StyledEditOrderContainer
        variants={rootVariants}
        initial="initial"
        animate="entering"
        $themeColor={doxleThemeColor}
        $doxleFont={doxleFont}
      >
        <div className="title-edit-order">
          {company?.name} Order - {editedOrder?.orderNumber}
        </div>
        <div className="order-tab-menu">
          <OrdersTabMenu />
        </div>
        {typeof editedOrder?.accepted === "boolean" && (
          <div
            className={
              editedOrder.accepted ? "order-accepted" : "order-rejected"
            }
          >
            This order has been {editedOrder.accepted ? "accepted" : "rejected"}{" "}
            by {editedOrder.acceptedBy}
          </div>
        )}
        {editedOrder && (
          <>
            <EmailOrder />
            <ConfirmOverBudgetDialog
              updatedOrder={updatedOrder}
              isOrderLineUpdated={isOrderLineUpdated}
            />
            {readOnly && <AcceptOrderDialog />}
            {!readOnly && showPaymentOptions &&  <SubmitPaymentDialog />}
            {!readOnly && (
              <DoxleDialogHelper
                open={showWarningChangesDialog}
                onClose={() => setShowWarningChangesDialog(false)}
                title="Confirm changes!"
                description="You have changed some fields in this order, do you wanna save?"
                dialogButtons={[
                  {
                    buttonFunction: handleClickSaveBtn,
                    buttonText: "Save",
                    btnStyleSx: {
                      borderRadius: "4px !important",

                      border: `1px solid ${doxleThemeColor.primaryDividerColor} !important`,
                      minWidth: "80px !important",
                    },
                    buttonTextStyle: {
                      color: `${doxleThemeColor.primaryContainerColor} !important`,
                    },
                    buttonColor: doxleThemeColor.primaryFontColor,
                  },
                  {
                    buttonFunction: () => {
                      setShowWarningChangesDialog(false);
                      setEditedOrder(undefined);
                    },
                    buttonText: "Cancel",
                    buttonColor: doxleThemeColor.primaryContainerColor,
                    btnStyleSx: {
                      borderRadius: "4px !important",
                      backgroundColor: `${doxleThemeColor.primaryContainerColor} !important`,

                      border: `1px solid ${doxleThemeColor.primaryDividerColor} !important`,
                      minWidth: "80px !important",
                    },
                    buttonTextStyle: {
                      color: `${doxleThemeColor.primaryFontColor}`,
                    },
                  },
                ]}
              />
            )}
            <div className="order-edit-content-wrapper">
              <EditOrderGeneralInfo />
              <EditOrderLineTable />
            </div>

            {readOnly && (
              <div className="action-btn-wrapper">
                {!editedOrder.accepted && (
                  <StyledConfirmOrderButton
                    $themeColor={doxleThemeColor}
                    $doxleFont={doxleFont}
                    onClick={() => setShowAcceptOrderDialog("accept")}
                  >
                    <span className="btn-text"> Accept</span>
                  </StyledConfirmOrderButton>
                )}
                {editedOrder.accepted !== null && (
                  <StyledConfirmOrderButton
                    $themeColor={doxleThemeColor}
                    $doxleFont={doxleFont}
                    $reverseColor={true}
                    onClick={() => setShowAcceptOrderDialog("reject")}
                  >
                    <span
                      className="btn-text"
                      style={{ color: "red !important" }}
                    >
                      Reject
                    </span>
                  </StyledConfirmOrderButton>
                )}
              </div>
            )}

            {!readOnly && (
              <div className="action-btn-wrapper">
                <StyledEditOrderActionButton
                  $themeColor={doxleThemeColor}
                  $doxleFont={doxleFont}
                  onClick={handleClickSaveBtn}
                >
                  <span className="btn-text"> Save</span>
                </StyledEditOrderActionButton>

                <StyledEditOrderActionButton
                  $themeColor={doxleThemeColor}
                  $doxleFont={doxleFont}
                  $reverseColor={true}
                  onClick={handleClickCancelBtn}
                  sx={{
                    color: `${doxleThemeColor.primaryFontColor} !important`,
                  }}
                >
                  <span
                    className="btn-text"
                    style={{
                      color: `${doxleThemeColor.primaryFontColor} !important`,
                    }}
                  >
                    Cancel
                  </span>
                </StyledEditOrderActionButton>
              </div>
            )}
          </>
        )}
        {/* <EditOrderTopButtonContainer /> */}

        <AnimatePresence>
          {isGeneratingPdf && (
            <motion.div
              className="pdf-loader"
              animate={{
                scaleX: [0, 1],
              }}
            >
              <CircularProgress
                size="50px"
                sx={{ color: doxleThemeColor.primaryReverseBackdropColor }}
              />

              <span>Generating pdf file, please wait...</span>
            </motion.div>
          )}
          {(isAddingOrder || isSendingOrder !== 0 || isUpdatingOrder) && (
            <Loading
              containerStyle={{
                position: "absolute",
                left: 0,
                top: 0,
                bottom: 0,
                right: 0,
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: editRgbaAlpha({
                  rgbaColor: doxleThemeColor.primaryBackgroundColor,
                  alpha: "0.8",
                }),
              }}
            />
          )}
        </AnimatePresence>
      </StyledEditOrderContainer>
    </EditOrderContext.Provider>
  );
};

export const useEditOrderContext = () =>
  useContext(EditOrderContext) as EditOrderContextValue;
export default EditOrder;
