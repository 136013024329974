import React from "react";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import {
  StyledAddedQuoteLineItem,
  StyledQuoteLineInputAdornment,
  StyledQuoteLineItemTextField,
} from "../SharedDocketStyledComponents";
import { QuoteLine } from "../../models/quote";
import { QUOTE_LINE_FIELDS } from "./QuoteResponseLineSection";
import useAddQuoteResponseLineItem from "./Hooks/useAddQuoteResponseLineItem";
import { editRgbaAlpha, formatter } from "../../../Utilities/FunctionUtilities";
import { CgClose } from "react-icons/cg";

type Props = { lineItem: QuoteLine; itemIndex: number };

const AddQuoteResponseLineItem = ({ lineItem, itemIndex }: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore((state) => ({
    doxleThemeColor: state.doxleThemeColor,
    doxleFont: state.doxleFont,
  }));
  const {
    handleLineDescriptionChange,
    handleLineQTyChange,
    handleLineUnitChange,
    handleUnitPriceChange,
    handleRemoveLine,
  } = useAddQuoteResponseLineItem({ itemIndex });
  return (
    <StyledAddedQuoteLineItem
      $themeColor={doxleThemeColor}
      $doxleFont={doxleFont}
      initial={{
        x: -5,
        opacity: 0,
      }}
      animate={{ x: 0, opacity: 1 }}
      exit={{ x: -5, opacity: 0 }}
      layout
    >
      {QUOTE_LINE_FIELDS.map((field, idx) => (
        <div
          className="line-field-wrapper"
          key={`line#${idx}`}
          style={{
            width:
              field.fieldName !== "Description"
                ? field.fieldName !== "Subtotal"
                  ? "8rem"
                  : "14rem"
                : undefined,
            flex: field.fieldName === "Description" ? 1 : undefined,
            borderRight:
              idx !== QUOTE_LINE_FIELDS.length - 1
                ? `1px solid ${editRgbaAlpha({
                    rgbaColor: doxleThemeColor.doxleColor,
                    alpha: "0.15",
                  })}`
                : undefined,
          }}
        >
          {field.fieldName !== "Subtotal" && (
            <StyledQuoteLineItemTextField
              onBlur={() => {
                if (lineItem[field.lineProp] === "") {
                  if (field.fieldName === "Description")
                    handleLineDescriptionChange("Untitled Line");
                  if (field.fieldName === "Qty") handleLineQTyChange("0");
                  if (field.fieldName === "Unit") handleLineUnitChange("each");
                  if (field.fieldName === "Unit Price")
                    handleUnitPriceChange("0.00");
                }
              }}
              onFocus={(e) => e.currentTarget.select()}
              $themeColor={doxleThemeColor}
              $doxleFont={doxleFont}
              variant="standard"
              value={
                field.fieldName === "Description"
                  ? lineItem.description
                  : field.fieldName === "Qty"
                  ? lineItem.quantity
                  : field.fieldName === "Unit"
                  ? lineItem.unit
                  : lineItem.itemCost
              }
              onChange={(event) =>
                field.fieldName === "Description"
                  ? handleLineDescriptionChange(event.target.value)
                  : field.fieldName === "Qty"
                  ? handleLineQTyChange(event.target.value)
                  : field.fieldName === "Unit"
                  ? handleLineUnitChange(event.target.value)
                  : handleUnitPriceChange(event.target.value)
              }
              InputProps={{
                startAdornment: (field.fieldName === "Description" ||
                  field.fieldName === "Unit Price") && (
                  <StyledQuoteLineInputAdornment
                    $themeColor={doxleThemeColor}
                    $doxleFont={doxleFont}
                    position="start"
                  >
                    {field.fieldName === "Description" && `${itemIndex + 1}. `}
                    {field.fieldName === "Unit Price" && "$ "}
                  </StyledQuoteLineInputAdornment>
                ),
              }}
              $textAlign={field.fieldName === "Description" ? "left" : "center"}
            />
          )}

          {field.fieldName === "Subtotal" &&
            formatter.format(Number(lineItem.lineCost))}
        </div>
      ))}

      <div className="icon-close-wrapper">
        <CgClose
          style={{
            color: editRgbaAlpha({
              rgbaColor: doxleThemeColor.primaryFontColor,
              alpha: "0.4",
            }),
            fontSize: "1.6rem",
            cursor: "pointer",
          }}
          onClick={handleRemoveLine}
        />
      </div>
    </StyledAddedQuoteLineItem>
  );
};

export default AddQuoteResponseLineItem;
