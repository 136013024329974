import DoxleListSkeleton from "../../../DoxleDesignPattern/DoxleSkeleton/DoxleListSkeleton";
import React, {useEffect, useMemo} from "react";
import {useDoxleThemeStore} from "../../../DoxleGeneralStore/useDoxleThemeStore";
import {shallow} from "zustand/shallow";
import {useDoxleCurrentContextStore} from "../../../DoxleGeneralStore/useDoxleCurrentContext";
import {useDoxleAuthStore} from "../../../DoxleGeneralStore/useDoxleAuthStore";
import useDoxleNotificationStore from "../../../DoxleGeneralStore/useDoxleNotificationStore";
import {useEstimatesStore} from "../Store/useEstimatesStore";
import EstimatesQueryAPI from "../../QueryHooks/EstimatesQueryAPI";
import EstimatesTable from "./EstimatesTable";
import {StyledPageContainer} from "../StyledComponents";
import {Estimate} from "../../Models/estimatesFilters";

const EstimatesPage = () => {
  const doxleThemeColor = useDoxleThemeStore((state) => state.doxleThemeColor, shallow);
  const company = useDoxleCurrentContextStore((state)=>state.currentCompany, shallow)
  const showNotification = useDoxleNotificationStore((state) => state.showNotification, shallow);
  const estimatesQueryFilter = useEstimatesStore(state => state.estimatesQueryFilter, shallow)


  const estimatesListQuery = EstimatesQueryAPI.useRetrieveEstimatesList({

    company,
    showNotification,
    filter: estimatesQueryFilter
  })

  const estimatesList: Estimate[] = useMemo(
    () =>
      estimatesListQuery.data?.pages
        ? estimatesListQuery.data?.pages.flatMap(
        (page: any) => page?.data?.results ?? []
      ) ?? []
        : [],
    [estimatesListQuery.data]
  );

  const handleFetchNextEstimatesPage = () => {
    if (estimatesListQuery.hasNextPage) estimatesListQuery.fetchNextPage()
  }

  return (
    <StyledPageContainer themeColor={doxleThemeColor}>
      {estimatesListQuery.isError && <>Error fetching the data.</>}
      {estimatesListQuery.isLoading && <DoxleListSkeleton skeletonType={"orderRow"} />}
      {estimatesListQuery.isSuccess && (
        <EstimatesTable estimateList={estimatesList} handleFetchNext={handleFetchNextEstimatesPage} />
      )}
    </StyledPageContainer>
  )
}

export default EstimatesPage
