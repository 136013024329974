import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import ListViewLeft from "./ListViewLeft";
import ListViewRight from "./ListViewRight";

import { StyledBodyContainer } from "./StyledFilesBodyComponent";
import { useFileStore } from "../Store/useFileStore";
import { useShallow } from "zustand/react/shallow";

type Props = {};
function FileListView({}: Props) {
  const currentLevel = useFileStore(useShallow((state) => state.currentLevel));
  return (
    <StyledBodyContainer $currentLevel={currentLevel}>
      <ListViewLeft />

      <ListViewRight />
    </StyledBodyContainer>
  );
}

export default FileListView;
