import React from "react";
import { ContactCompany } from "../../../Models/addressBook";
import { StyledContractorItem } from "./StyledSelectContractorPopover";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";

type Props = {
  contractor: ContactCompany;
  selected: boolean;
  handleSelectContractor: (contractor: ContactCompany) => void;
};

const ContractorItem = ({
  contractor,
  selected,
  handleSelectContractor,
}: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }),
    shallow
  );

  return (
    <StyledContractorItem
      onClick={() => handleSelectContractor(contractor)}
      $themeColor={doxleThemeColor}
      $doxleFont={doxleFont}
      animate={{
        color: selected
          ? doxleThemeColor.doxleColor
          : doxleThemeColor.primaryFontColor,
      }}
      transition={{ duration: 0.2 }}
    >
      {contractor.name}
    </StyledContractorItem>
  );
};

export default ContractorItem;
