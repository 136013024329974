import {
  Autocomplete,
  CircularProgress,
  FormControl,
  InputAdornment,
} from "@mui/material";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { shallow } from "zustand/shallow";
import React from "react";
import { ContactCompany } from "../../../Models/addressBook";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { useDoxleAuthStore } from "../../../DoxleGeneralStore/useDoxleAuthStore";
import { useDoxleCurrentContextStore } from "../../../DoxleGeneralStore/useDoxleCurrentContext";
import ContactsAPI from "../../../Services/QueryHooks/contactsAPI";
import {
  StyledContactsAddNewInputTitleText,
  StyledContactsAddNewTextField,
} from "../AddContactDialog/styledComponents";
import { ContactCompanyFilters } from "../../../Services/QueryHooks/contactsFilters";

type Props = {
  currentValue: ContactCompany | undefined;
  setNewContactCompany: (newValue: ContactCompany) => void;
};

const CompanyAutoCompleteSelection = ({
  currentValue,
  setNewContactCompany,
}: Props) => {
  const [searchInput, setSearchInput] = useState<string>("");
  const [searchTextQuery, setSearchTextQuery] = useState<string>("");
  const styleProps = useDoxleThemeStore(
    (state) => ({
      $doxleFont: state.doxleFont,
      $themeColor: state.doxleThemeColor,
      $currentTheme: state.currentTheme,
    }),
    shallow
  );
  const company = useDoxleCurrentContextStore(
    (state) => state.currentCompany,
    shallow
  );
  const filterRetrieveContactCompanyListQuery: ContactCompanyFilters = useMemo(
    () => ({
      search: searchInput,
    }),
    [searchInput]
  );

  const retrievedCompanies = ContactsAPI.useRetrieveContactsCompanyQuery({
    company,
    filter: filterRetrieveContactCompanyListQuery,
    enable: true,
  });

  const companiesList = useMemo(
    () =>
      retrievedCompanies.isSuccess
        ? retrievedCompanies.data.pages.flatMap(
            (page) => (page.data.results as ContactCompany[]) ?? []
          ) ?? []
        : [],
    [retrievedCompanies.data]
  );

  const handleAutoCompleteChange = useCallback((newValue: ContactCompany) => {
    setNewContactCompany(newValue);
  }, []);

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const timeout = setTimeout(() => setSearchInput(searchTextQuery), 400);

    return () => clearTimeout(timeout);
  }, [searchTextQuery]);
  useEffect(() => {
    console.log("currentValue:", currentValue);
  }, [currentValue]);

  return (
    <FormControl
      style={{ display: "flex", flexDirection: "column", width: "100%" }}
    >
      <StyledContactsAddNewInputTitleText {...styleProps}>
        COMPANY
      </StyledContactsAddNewInputTitleText>
      <Autocomplete
        sx={{
          "& .MuiOutlinedInput-root": {
            maxHeight: "50px !important",
            minHeight: " 50px !important",
            borderRadius: "0px",
            padding: "0px 20px 0px 20px",
            backgroundColor:
              styleProps.$themeColor.primaryTextFieldBackgroundColor,
          },
          input: {
            fontFamily: styleProps.$doxleFont.secondaryFont,
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "14px",
            color: styleProps.$themeColor.primaryFontColor,
            padding: "0px !important",
          },
          // "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
          //   border:
          //     companyMenuErrorDisplay.companyError.company === true
          //       ? ""
          //       : "none !important",
          // },
        }}
        noOptionsText={"Not Found"}
        disablePortal
        disableClearable
        loadingText="Looking For Company..."
        options={companiesList}
        getOptionLabel={(option) =>
          typeof option === "string" ? option : option.name
        }
        value={currentValue}
        onChange={(event, newValue, reason) => {
          // setCompanyMenuErrorDisplay({
          //   ...companyMenuErrorDisplay,
          //   companyError: { company: false },
          // });
          console.log(newValue);
          if (typeof newValue !== "string") handleAutoCompleteChange(newValue);
          else {
            if (currentValue)
              setNewContactCompany({ ...currentValue, name: newValue });
          }
        }}
        placeholder={"Click to select a company"}
        renderOption={(props, option, index) => {
          return (
            <span
              {...props}
              key={`listItem-` + index + `-` + option.contactCompanyId}
            >
              {option.name}
            </span>
          );
        }}
        freeSolo
        isOptionEqualToValue={(option, value) =>
          option.contactCompanyId === value.contactCompanyId
        }
        loading={retrievedCompanies.isLoading}
        renderInput={(params) => (
          <StyledContactsAddNewTextField
            {...params}
            {...styleProps}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <InputAdornment position="end">
                  {retrievedCompanies.isLoading ? (
                    <CircularProgress
                      size={20}
                      sx={{ color: styleProps.$themeColor.doxleColor }}
                    />
                  ) : null}
                </InputAdornment>
              ),
            }}
            inputRef={inputRef}
            onBlur={() => {
              if (currentValue && inputRef.current) {
                setNewContactCompany({
                  ...currentValue,
                  name: inputRef.current.value,
                });
              }
            }}
            onChange={(event) => setSearchTextQuery(event.target.value)}
          />
        )}
      />
    </FormControl>
  );
};

export default CompanyAutoCompleteSelection;
