import { useDoxleThemeStore } from "../../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import { StyledFilterIconButton } from "./StyledComponentCommonQA";
import { useState } from "react";
import { Contact } from "../../../../Models/addressBook";
import { useProjectQAStore } from "../../Store/useProjectQAStore";
import { FilterListIcon } from "../../../QAIcons";
import DoxleSelectContactDropdown from "../../../../DoxleDesignPattern/DoxleSelectContactDropdown/DoxleSelectContactDropdown";

interface Props {}
const ContactFilterToggle = ({}: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);
  const { filter, setFilter } = useProjectQAStore(
    (state) => ({
      filter: state.filterQAItemQuery,
      setFilter: state.setFilterQAItemQuery,
    }),
    shallow
  );

  const styleProps = useDoxleThemeStore(
    (state) => ({
      $currentTheme: state.currentTheme,
      $themeColor: state.doxleThemeColor,
      $doxleFont: state.doxleFont,
    }),
    shallow
  );

  const handleSelectAssignee = (assignee?: Contact | undefined) => {
    if (assignee?.contactId === filter?.assignee)
      setFilter({ ...filter, assignee: undefined });
    else setFilter({ ...filter, assignee: assignee?.contactId });
  };

  return (
    <>
      <StyledFilterIconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
        <FilterListIcon themeColor={styleProps.$themeColor} />
      </StyledFilterIconButton>
      {anchorEl && (
        // <SelectAssigneeDropdown
        //   anchorDropdown={anchorEl}
        //   onCloseDropdown={() => setAnchorEl(null)}
        //   selectedAssigneeId={filter?.assignee}
        //   onSelectAssignee={handleSelectAssignee}
        // />
        <DoxleSelectContactDropdown
          popoverAnchor={anchorEl}
          onClosePopover={() => setAnchorEl(null)}
          variants="contact"
          selectedContactItemIds={
            filter?.assignee ? [filter?.assignee] : undefined
          }
          onSelectContact={handleSelectAssignee}
          containerStyle={{
            borderRadius: 9,
          }}
        />
      )}
    </>
  );
};

export default ContactFilterToggle;
