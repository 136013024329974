import React from "react";
import { StyledQRLogGroupHeader } from "./StyledQRUserLog";
import dayjs from "dayjs";

type Props = {
  date: Date;
};

const QRLogGroupHeader = ({ date }: Props) => {
  return (
    <StyledQRLogGroupHeader>
      <span className="date-title">{dayjs(date).format("DD MMM YYYY")}</span>
      <span className="field-header">In</span>
      <span className="field-header">Out</span>
    </StyledQRLogGroupHeader>
  );
};

export default QRLogGroupHeader;
