import styled from "styled-components";
import { motion } from "framer-motion";
import {
  DoxleFont,
  DoxleThemeColor,
} from "../../../DoxleGeneralStore/useDoxleThemeStore";
import {
  Button,
  InputAdornment,
  Menu,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { DateCalendar } from "@mui/x-date-pickers";
import { editRgbaAlpha } from "../../../Utilities/FunctionUtilities";

export const StyledEditOrderSideScreenContainer = styled(motion.div)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  width: calc(100% - 2.8rem);
  height: calc(100% - 2.8rem);
  padding: 1.4rem;
  background-color: ${(p) => p.$themeColor.primaryBackgroundColor};
  box-shadow: 0px -2px 8px 2px ${(p) => p.$themeColor.primaryBoxShadowColor};
  display: flex;
  flex-direction: column;
`;

export const EditOrderSideScreenBodyContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const StyledOrderTableHeader = styled(TableHead)<{
  $themeColor: DoxleThemeColor;
}>`
  && {
    display: flex;
    width: 100%;
    border: none;
    background-color: ${(p) => p.$themeColor.primaryBackgroundColor};
    flex-direction: row;
  }
`;
export const StyledEditOrderTableBody = styled(TableBody)`
  && {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
`;
export const StyledOrderTableRow = styled(motion(TableRow))<{
  $themeColor: DoxleThemeColor;
}>`
  && {
    display: flex;
    flex-direction: row;
    width: 100%;
    //border-bottom: 2px solid $ {(p) => p.$themeColor.primaryBackgroundColor};
    height: 2.8rem;
    min-height: 29px;
    padding-bottom: max(0.2rem, 2px);
  }
`;

export const StyledOrderTableFooter = styled(motion(TableFooter))<{
  $themeColor: DoxleThemeColor;
}>`
  && {
    display: flex;
    flex-direction: column;
    width: 100%;

    align-items: center;
    background-color: ${(p) => p.$themeColor.primaryBackgroundColor};
  }
`;

export const StyledOrderTableHeaderCell = styled(TableCell)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
  $width?: string;
  $horizontalAlign?: "center" | "flex-start" | "flex-end";
  $paddingLeft?: string;
}>`
  && {
    padding: 0px 0px 0px ${(p) => p.$paddingLeft ?? "0px"} !important;
    color: ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.$themeColor.primaryFontColor,
        alpha: "0.7",
      })};
    font-family: ${(p) => p.$doxleFont.primaryTitleFont};
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.05rem;
    text-transform: uppercase;
    justify-content: ${(p) => p.$horizontalAlign ?? "center"};
    border: none !important;
    display: flex;

    ${(p) => (p.$width ? `width:${p.$width};` : "flex:1;")}
  }
`;

export const StyledOrderLineCell = styled.div<{
  $themeColor: DoxleThemeColor;
  $width?: string;
  $hasBorderRight: boolean;
  $doxleFont: DoxleFont;
}>`
  border-bottom: none !important;

  height: 100%;
  display: flex;
  align-items: center;
  padding: 0px !important;

  border-right: ${(p) => (p.$hasBorderRight ? 1 : 0)}px solid
    ${(p) => p.$themeColor.primaryDividerColor};
  ${(p) =>
    p.$width
      ? `width:${p.$hasBorderRight ? `calc(${p.$width} - 1px)` : p.$width};`
      : "flex:1;"}

  .line-cost-cell {
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-weight: 500;
    font-size: max(1.3rem, 13px);
    line-height: normal;
    //width: 100% !important;
    color: ${(p) => p.$themeColor.primaryFontColor};
    width: calc(100% - 2rem);
    padding-left: 2rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .delete-icon-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .description-cell {
    min-width: 40rem;
  }
`;

export const StyledOrderLineTextField = styled(TextField)<{
  themeColor: DoxleThemeColor;
  textAlign: "left" | "center" | "right";
  widthInPixel: `${number}px` | null;
}>`
  && {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    height: 100%;
    ${(p) => (p.widthInPixel ? `width:${p.widthInPixel};` : `flex:1;`)}
    display: flex;
    justify-content: center;
  }
  input {
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.05em;
    color: ${(p) => p.themeColor.primaryFontColor};
    padding: 0 !important;
    text-align: ${(p) => p.textAlign};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  & .MuiOutlinedInput-notchedOutline {
    border: none !important;
    padding: 0 !important;
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;
export const StyledOrderLineTextFieldAdornment = styled(InputAdornment)<{
  themeColor: DoxleThemeColor;
}>`
  && {
    cursor: pointer;
    text-transform: capitalize !important;
  }
  & .MuiTypography-root {
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.05em;
    color: ${(p) => p.themeColor.primaryFontColor};

    text-align: center;
    padding: 0 !important;
    text-transform: lowercase;
  }

  &.MuiInputAdornment-root {
    margin: 1px !important;
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.05em;
    color: ${(p) => p.themeColor.primaryFontColor};
    text-align: center;
    padding: 0 !important;
  }
`;

export const StyledOrderDetailTableFooterCell = styled(TableFooter)<{
  themeColor: DoxleThemeColor;
}>`
  && {
    display: flex;
    width: calc(100% - 40px);
    height: calc(100% - 8px);
    padding: 4px 20px;
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.05em;
    color: #5b5be9;
    border: none !important;
    justify-content: flex-start;
    align-items: center;
  }
`;

export const RootPurchaseOrderDetailGeneralInfo = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;

  .space-between-field {
    width: 100%;
    margin-bottom: 3rem;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    height: 40px;
  }
  .date-picker-wrapper {
    display: flex;
    flex-direction: row;
    width: 150px;
  }
`;

export const StyledContractorAndTitleContainer = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: row;
`;

export const StyledPurchaseOrderTitleContainer = styled.div<{
  marginTop?: string;
}>`
  height: 50%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: ${(p) => p?.marginTop ?? "unset"};
`;

export const StyledOrderTitleTextField = styled(TextField)<{
  themeColor: DoxleThemeColor;
}>`
  && {
    flex: 1;
    display: flex;
    width: 100%;
    margin-top: 0px;
  }

  & .MuiOutlinedInput-notchedOutline {
    border: none !important;
    display: none;
  }
  & .MuiOutlinedInput-root {
    padding: 0 !important;
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.05em;
    color: rgba(0, 0, 0, 1);
    text-align: left;
    text-overflow: ellipsis;
  }
  & .MuiInputBase-input {
    padding: 0 !important;
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.05em;
    color: rgba(0, 0, 0, 1);
    text-align: left;
    text-overflow: ellipsis;
  }
`;

export const StyledOrderFieldLabel = styled.span<{
  themeColor: DoxleThemeColor;
  fixedWidth?: `${number}px`;
}>`
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: ${(p) => p.themeColor.primaryFontColor};
  ${(p) => p.fixedWidth && `min-width:${p.fixedWidth};`}
  text-align:left;
`;

export const StyledDateAndPaymentInfoContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 50%;
  justify-content: start;
  align-items: center;
  padding-right: 20px;
`;
export const StyledPOInfoRow = styled.div`
  height: 25%;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;
export const StyledPOGeneralInfoText = styled(motion.span)<{
  themeColor: DoxleThemeColor;
}>`
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.05em;
  color: ${(p) => p.themeColor.primaryFontColor};
  flex: 1;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
`;
export const StyledDateCalendar = styled(DateCalendar)<{
  themeColor: DoxleThemeColor;
}>`
  &.MuiDateCalendar-root {
    border-radius: 4px;
    background-color: ${(p) => p.themeColor.primaryTableRowColor};
    box-shadow: 1px 1px 1px 1px grey;
    margin-top: 8px;
  }
  & .MuiPickersCalendarHeader-labelContainer {
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: 0.05em;

    color: ${(p) => p.themeColor.primaryFontColor};
  }
  & .MuiDayCalendar-weekDayLabel {
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.05em;
    color: rgba(0, 0, 0, 0.6);
  }
`;

export const StyledLiquidDamageTextField = styled(TextField)<{
  themeColor: DoxleThemeColor;
  widthInPixel: `${number}px`;
}>`
  && {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    height: 100%;
    width: ${(p) => p.widthInPixel};
    display: flex;
    justify-content: center;
  }
  input {
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.05em;
    color: ${(p) => p.themeColor.primaryFontColor};
    padding: 0 !important;
  }
  & .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }
  & .MuiOutlinedInput-root {
    padding: 0 !important;
  }
`;
export const StyledPurchaseOrderInputAdornment = styled(
  motion(InputAdornment)
)<{
  themeColor: DoxleThemeColor;
}>`
  && {
    cursor: pointer;
    text-transform: capitalize !important;
  }
  & .MuiTypography-root {
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.05em;
    color: ${(p) => p.themeColor.primaryFontColor};

    text-align: center;
    padding: 0 !important;
  }

  &.MuiInputAdornment-root {
    margin: 1px !important;
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.05em;
    color: ${(p) => p.themeColor.primaryFontColor};

    text-align: center;
    padding: 0 !important;
  }
`;
export const StyledPaymentTermTextField = styled(TextField)<{
  themeColor: DoxleThemeColor;
  widthInPixel: `${number}px`;
}>`
  && {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    height: 100%;
    width: ${(p) => p.widthInPixel};
    display: flex;
    justify-content: center;
  }
  input {
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.05em;
    color: ${(p) => p.themeColor.primaryFontColor};
    padding: 0 !important;
  }
  & .MuiOutlinedInput-notchedOutline {
    border: none !important;
    padding: 0 !important;
  }
`;

export const RootPaymentTermSuffixList = styled.div<{
  themeColor: DoxleThemeColor;
}>`
  border-radius: 4px;
  box-shadow: 1px 1px 1px 1px grey;
  background-color: ${(p) => p.themeColor.primaryTableRowColor};
  display: flex;
  flex-direction: row;
  padding: 4px;
  margin-left: 8px;
`;

export const StyledPaymentTermSuffixListItem = styled(Button)<{
  themeColor: DoxleThemeColor;
  selected: boolean;
}>`
  && {
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.05em;
    color: ${(p) => p.themeColor.primaryFontColor};
    min-width: 0 !important;
    margin: 0px 2px;
    background-color: ${(p) => (p.selected ? "#7B7BFE30" : "transparent")};
    border-radius: 8px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: capitalize;
  }
  &:hover {
    transform: scale(0.88) translateY(-4px);
    transition: 0.2s;
  }
`;
export const StyledRootLiquidDamagePeriodList = styled.div<{
  themeColor: DoxleThemeColor;
}>`
  border-radius: 4px;
  box-shadow: 1px 1px 1px 1px grey;
  background-color: ${(p) => p.themeColor.primaryTableRowColor};
  display: flex;
  flex-direction: row;
  padding: 4px;
  margin-left: 8px;
`;
export const StyledDamagePeriodListItem = styled(Button)<{
  themeColor: DoxleThemeColor;
  selected: boolean;
}>`
  && {
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.05em;
    color: ${(p) => p.themeColor.primaryFontColor};
    min-width: 0 !important;
    margin: 0px 2px;
    background-color: ${(p) => (p.selected ? "#7B7BFE30" : "transparent")};
    border-radius: 8px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: capitalize;
  }
  &:hover {
    transform: scale(0.88) translateY(-4px);
    transition: 0.2s;
  }
`;

export const StyledOrderTobButtonContainer = styled.div<{}>`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: right;
`;

export const StyledOrderStatusMenu = styled(Menu)`
  & .MuiMenu-list {
    background-color: white;
    opacity: 1;
    transform-origin: 168.188px 0px;
    right: 10px;
  }
  & .MuiMenuItem-root {
    font-family: "IBM Plex Sans";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.05em;
  }
`;

export const StyledDocketOrdersTabContainer = styled(motion.div)`
  width: calc(100%);
  height: calc(100% - 30px);

  padding: 20px 0px 10px 0px;

  position: relative;

  display: flex;
  flex-direction: column;
`;

export const StyledConditionsTextarea = styled(TextField)<{
  $themeColor: DoxleThemeColor;
}>`
  & .MuiInputBase-input {
    color: ${(p) => p.$themeColor.doxleColor};
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    border: none !important;
  }
  & .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }
`;

export const StyledNotesAdornment = styled(InputAdornment)<{
  $themeColor: DoxleThemeColor;
}>`
  & .MuiTypography-root {
    color: ${(p) => p.$themeColor.doxleColor};
    font-family: "Work Sans";
    font-style: normal;
    font-size: large;
    font-size: 14px;
    line-height: 16px;
    margin-top: -16px;
  }
`;

export const StyledOrderLineTableRowWrapper = styled(TableRow)`
  overflow-anchor: none;
  display: flex;
  flex-direction: row;
  width: 100% !important;
  //border-bottom: 1px solid rgb(252, 252, 253);
  height: 3rem;
  max-height: 30px;
  padding-bottom: 0.2rem;
`;
export const StyledOrderLineTableRow = styled(motion(TableCell))<{
  $themeColor: DoxleThemeColor;
}>`
  && {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    border: none !important;
    padding: 0 !important;
    cursor: pointer;
    overflow: hidden;
    background-color: ${(p) => p.$themeColor.primaryTextFieldBackgroundColor};
  }
  &:hover {
    background-color: ${(p) => p.$themeColor.rowHoverColor};
    transition: 0.4s;
  }
`;
export const StyledOrderLineFooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;
export const StyledAddOrderLineTextField = styled(TextField)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  && {
    overflow: hidden;
    min-width: 100px;
    width: calc(100% - 1.6rem);
    padding: 0.8rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-top: 1px solid ${(p) => p.$themeColor.primaryDividerColor};
    border-bottom: 1px solid ${(p) => p.$themeColor.primaryDividerColor};
  }
  input {
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-weight: 400;
    font-size: max(1.3rem, 13px);
    line-height: normal;
    width: 100% !important;
    color: ${(p) => p.$themeColor.primaryFontColor};
    padding: 0px !important;
    &::placeholder {
      font-family: ${(p) => p.$doxleFont.primaryFont};
      font-weight: 400;
      font-size: max(1.3rem, 13px);
      line-height: normal;
      color: ${(p) => p.$themeColor.primaryInputPlaceholderColor};
      padding: 0px !important;
      opacity: 1;
    }
  }
  & .MuiInput-root:after {
    border-bottom: none !important;
  }
  & .MuiInput-root:before {
    border-bottom: none !important;
  }
  & .MuiInput-root {
    border-bottom: none !important;
  }
  & .MuiInput-root:hover:not(.Mui-disabled, .Mui-error):before {
    border-bottom: none !important;
  }
  & .MuiInputBase-root {
    width: 100%;
  }
`;

export const StyledOrderFooterContent = styled.div`
  //padding: 2rem 3rem;
  display: flex;
  flex-direction: row;
  //width: calc(100% - 6rem);
  padding: 2rem 0;
  width: 100%;
  //align-items: flex-end;
  justify-content: space-between;
`;

export const StyledOrderTotalSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-right: 2rem;
  /* width: 20%; */
  /* overflow: hidden;
  position: absolute;
  right: 50px; */
  /* background-color: aliceblue; */
`;

export const StyledDividerLine = styled.div<{
  $themeColor: DoxleThemeColor;
}>`
  height: 1px;
  border-bottom: 1px solid ${(p) => p.$themeColor.primaryDividerColor};
  width: 26rem;
`;

export const StyledOrderTotalFieldWrapper = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  display: flex;
  flex-direction: row;
  position: relative;
  /* right: 7rem; */

  align-items: center;
  justify-content: center;
  padding: 0.4rem 0px;
  width: 100%;
  .total-label {
    color: ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.$themeColor.primaryFontColor,
        alpha: "0.7",
      })};
    font-family: ${(p) => p.$doxleFont.primaryTitleFont};
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.05rem;
    display: flex;
    justify-content: flex-end;
    text-transform: uppercase;
    flex: 1;
    /* border-bottom: 1px solid black; */
  }
  .total-value {
    color: ${(p) => p.$themeColor.primaryFontColor};
    font-family: ${(p) => p.$doxleFont.primaryTitleFont};
    font-size: 1.3rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.05rem;

    display: flex;
    width: 14rem;
    padding-left: 2rem;
  }
`;

export const StyledSpecialConditionSection = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  width: 75%;
  display: flex;
  flex-direction: column;
  padding-top: 3.5rem;
  .label-title {
    color: ${(p) => p.$themeColor.primaryFontColor};
    font-family: ${(p) => p.$doxleFont.primaryTitleFont};
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.05rem;
    margin-bottom: 0.4rem;
  }

  .condition-texfield-wrapper {
    width: calc(100% - 2.8rem);
    background-color: ${(p) => p.$themeColor.primaryTextFieldBackgroundColor};
    border-radius: 0px 4px;
    display: flex;
    height: 20rem;
    padding: 1.4rem;
    align-items: flex-start;
    cursor: text;
  }
`;
export const StyledSpecialConditionTextField = styled(TextField)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  && {
    overflow: scroll;
    width: calc(100%);
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  textarea {
    font-family: ${(p) => p.$doxleFont.secondaryTitleFont};
    font-weight: 600;
    font-size: 1.2rem;
    line-height: normal;
    width: 100% !important;
    color: ${(p) => p.$themeColor.primaryFontColor};
    padding: 0px !important;
    letter-spacing: 0.1rem;
    &::placeholder {
      font-family: ${(p) => p.$doxleFont.secondaryTitleFont};
      font-weight: 400;
      font-size: 1.3rem;
      line-height: normal;
      color: ${(p) => p.$themeColor.primaryInputPlaceholderColor};
      padding: 0px !important;
      opacity: 1;
    }
  }
  & .MuiInput-root:after {
    border-bottom: none !important;
  }
  & .MuiInput-root:before {
    border-bottom: none !important;
  }
  & .MuiInput-root {
    border-bottom: none !important;
  }
  & .MuiInput-root:hover:not(.Mui-disabled, .Mui-error):before {
    border-bottom: none !important;
  }
  & .MuiInputBase-root {
    width: 100%;
  }
  & .MuiInputBase-input {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
export const StyledEditOrderFullScreenModeContainer = styled(motion.div)<{
  $themeColor: DoxleThemeColor;
}>`
  width: calc(100% - 2.8rem);
  height: calc(100% - 2.8rem);
  padding: 1.4rem;
  background-color: ${(p) => p.$themeColor.primaryBackgroundColor};
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledAddLineBtn = styled(motion.div)`
  padding: 1px;
  border-radius: 50%;
  margin-right: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
export const StyledLoadingLineText = styled.span<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  font-family: ${(p) => p.$doxleFont.primaryTitleFont};
  font-weight: 600;
  font-size: max(1.8rem, 18px);
  line-height: normal;
  align-self: center;
  color: ${(p) => p.$themeColor.primaryFontColor};
  padding: 14px 0px !important;
  max-width: 700px;
`;

export const StyledOrderHistoryTitle = styled.div`
  color: ${(p) =>
    editRgbaAlpha({ rgbaColor: p.theme.color.primaryFontColor, alpha: "0.7" })};
  font-family: ${(p) => p.theme.font.primaryFont};
  font-size: max(1rem, 10px);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 20px;
  text-transform: uppercase;
  margin-left: 0.4rem;
`;


export const StyledOrderHistoryHeaderCell = styled(TableCell)<{
  $width?: string;
}>`
  && {
    padding: 8px !important;
    color: ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.theme.color.primaryFontColor,
        alpha: "0.7",
      })};
    font-family: ${(p) => p.theme.font.primaryTitleFont};
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.05rem;
    text-transform: uppercase;
    border: none !important;
  }
`;
export const StyledOrderHostoryTableHeader = styled(TableHead)`
  && {
    display: flex;
    width: 100%;
    border: none;
    background-color: ${(p) => p.theme.color.primaryBackgroundColor};
    flex-direction: row;
  }
`;
export const StyledOrderHistoryTableBody = styled(TableBody)`
  && {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
`;
export const StyledOrderHistoryLineCell = styled(TableCell)<{
  $width?:string;
}>`
   && {
    padding: 8px;
    color: ${(p) => p.theme.color.primaryFontColor};
    font-family: ${(p) => p.theme.font.primaryFont};
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.05rem;
    width: ${(p) => p.$width?p.$width:"auto"};
    border-right: 1px solid ${(p) => p.theme.color.primaryDividerColor};
  }
`;

export const StyledOrderHistoryLineRow = styled(motion(TableRow))`
  && {
    background-color: ${(p) => p.theme.color.primaryTextFieldBackgroundColor};
    &:hover {
      background-color: ${(p) => editRgbaAlpha({rgbaColor: p.theme.color.doxleColor, alpha:"0.1"})};
      transition: 0.2s;
    }
  }
`;

export const StyledOrderHistoryTableContainer = styled.div`
  margin-bottom: 50px;
  width: 100%;
  font-size: 1.4rem;
  .display-all-tag {
    color: ${(p) => p.theme.color.doxleColor};
  }
`