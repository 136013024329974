import { SxProps, Theme } from "@mui/material/styles";
import {
  DoxleFont,
  DoxleThemeColor,
} from "../DoxleGeneralStore/useDoxleThemeStore";

export const DoxleMUITooltipSx = (
  themeColor: DoxleThemeColor,
  doxleFont: DoxleFont
): SxProps<Theme> => {
  return {
    "& .MuiTooltip-tooltip": {
      fontFamily: doxleFont.primaryFont,
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "14px",

      color: `${themeColor.primaryFontColor} !important`,
      textTransform: " capitalize",
      backgroundColor: `${themeColor.primaryTextFieldBackgroundColor} !important`,
      border: `1px solid ${themeColor.primaryDividerColor} !important`,
      borderRadius: "4px",

      padding: "4px 8px",
    },
  };
};
