import { useMemo } from "react";
import DocketQuery from "../../../Services/QueryHooks/docketQueryAPI";
import { BasicCoreDocket } from "../../../Models/dockets";

type Props = {};

interface IGetCoreDockets {
  coreDocketList: BasicCoreDocket[];
  isSuccessFetchingCoreDockets: boolean;
  isFetchingCoreDockets: boolean;
  isErrorFetchingCoreDockets: boolean;
}
const useGetCoreDockets = (props?: Props): IGetCoreDockets => {
  const coreDocketsQuery = DocketQuery.useRetrieveBasicCoreDocketList();
  const coreDocketList: BasicCoreDocket[] = useMemo(
    () =>
      coreDocketsQuery.isSuccess && coreDocketsQuery.data?.data
        ? (coreDocketsQuery.data?.data as BasicCoreDocket[])
        : [],
    [coreDocketsQuery.data, coreDocketsQuery.isSuccess]
  );
  return {
    coreDocketList,
    isSuccessFetchingCoreDockets: coreDocketsQuery.isSuccess,
    isFetchingCoreDockets: coreDocketsQuery.isFetching,
    isErrorFetchingCoreDockets: coreDocketsQuery.isError,
  };
};

export default useGetCoreDockets;
