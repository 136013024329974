import React from "react";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import { StyledAddedAttachmentItem } from "./styledComponents";
import {
  DoxleExcelIcon,
  DoxlePDFIcon,
  DoxleWordIcon,
} from "../../../DoxleDesignPattern/DoxleGeneralIcons";
import { DOXLE_MIME_TYPE } from "../../../Utilities/MimeFileType";
import { easeInOut } from "framer-motion";
import { useAddMailContext } from "./AddMail";
import { IoCloseCircleSharp } from "react-icons/io5";
import { produce } from "immer";
type Props = {
  item: File;
  itemIndex: number;
};

const AddedAttachmentItem = ({ item, itemIndex }: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }),
    shallow
  );
  const { setAttachments } = useAddMailContext();
  return (
    <StyledAddedAttachmentItem
      $themeColor={doxleThemeColor}
      $doxleFont={doxleFont}
      animate={{
        y: [100, 0],
        opacity: [0, 1],
      }}
      layout
      transition={{
        duration: 0.2,
        ease: easeInOut,
      }}
    >
      {item.type.toLowerCase().includes("image") ? (
        <img
          className="img-holder"
          src={URL.createObjectURL(item)}
          alt={`file${itemIndex}`}
        />
      ) : (
        <div className="icon-wrapper">
          {item.type.toLowerCase().includes(DOXLE_MIME_TYPE.pdf) && (
            <DoxlePDFIcon
              themeColor={doxleThemeColor}
              containerStyle={{
                width: "60%",
              }}
            />
          )}
          {(item.type.toLowerCase().includes(DOXLE_MIME_TYPE.doc) ||
            item.type.toLowerCase().includes(DOXLE_MIME_TYPE.docx)) && (
            <DoxleWordIcon
              themeColor={doxleThemeColor}
              containerStyle={{
                width: "60%",
              }}
            />
          )}
          {(item.type.toLowerCase().includes(DOXLE_MIME_TYPE.xls) ||
            item.type.toLowerCase().includes(DOXLE_MIME_TYPE.xlsx)) && (
            <DoxleExcelIcon
              themeColor={doxleThemeColor}
              containerStyle={{
                width: "60%",
              }}
            />
          )}
        </div>
      )}

      <span className="file-name">{item.name}</span>

      <IoCloseCircleSharp
        color={doxleThemeColor.primaryFontColor}
        size={"25px"}
        className="close-icon"
        onClick={() => {
          setAttachments((prev) =>
            produce(prev, (draft) => {
              draft.splice(itemIndex, 1);
              return draft;
            })
          );
        }}
      />
    </StyledAddedAttachmentItem>
  );
};

export default React.memo(AddedAttachmentItem);
