import React, { useMemo, useState } from "react";
import { useDoxleAuthStore } from "../../../../../DoxleGeneralStore/useDoxleAuthStore";
import { shallow } from "zustand/shallow";
import { useDoxleCurrentContextStore } from "../../../../../DoxleGeneralStore/useDoxleCurrentContext";
import QAQueryAPI from "../../../../QueryAPI/qaQueryAPI";
import { useProjectQAStore } from "../../../Store/useProjectQAStore";
import { QAComment } from "../../../../Models/qa";

type Props = {};

interface IQACommentSection {
  commentList: QAComment[];
  isFetchingComment: boolean;
  isErrorFetchingComment: boolean;
  isSuccessFetchingComment: boolean;
  editComment: QAComment | undefined;
  setEditComment: React.Dispatch<React.SetStateAction<QAComment | undefined>>;
  isFetchingMoreComment: boolean;
  fetchMoreComment: () => void;
  hasNextPageComment: boolean;
}
const useQACommentSection = (props: Props): IQACommentSection => {
  const [editComment, setEditComment] = useState<QAComment | undefined>(
    undefined
  );
  const { company } = useDoxleCurrentContextStore(
    (state) => ({
      company: state.currentCompany,
    }),
    shallow
  );
  const { selectedQAItem } = useProjectQAStore(
    (state) => ({
      selectedQAItem: state.selectedQAItem,
    }),
    shallow
  );
  const getQACommentQuery = QAQueryAPI.useRetrieveQACommentList({
    company,
    qaItem: selectedQAItem!,
  });

  const commentList: QAComment[] = useMemo(
    () =>
      getQACommentQuery.isSuccess
        ? getQACommentQuery.data.pages.reduce((acc, data) => {
            return data.data.results.concat(acc);
          }, [] as QAComment[])
        : [],
    [getQACommentQuery.data]
  );
  const fetchMoreComment = () => {
    if (getQACommentQuery.hasNextPage) getQACommentQuery.fetchNextPage();
  };
  return {
    commentList,
    editComment,
    setEditComment,
    isFetchingComment: getQACommentQuery.isLoading,
    isErrorFetchingComment: getQACommentQuery.isError,
    isSuccessFetchingComment: getQACommentQuery.isSuccess,
    isFetchingMoreComment: getQACommentQuery.isFetchingNextPage,
    fetchMoreComment,
    hasNextPageComment: Boolean(getQACommentQuery.hasNextPage),
  };
};

export default useQACommentSection;
