import React from "react";
import {AccountsQueryAPI} from "../../../../Services/QueryHooks/AccountsQueryAPI";
import {StyledAttachmentSpan, StyledFileName} from "./StyledComponentReviewPaymentClaim";
import {useDoxleThemeStore} from "../../../../DoxleGeneralStore/useDoxleThemeStore";
import {shallow} from "zustand/shallow";

export interface XeroAttachment {
  AttachmentID: string;
  Url: string;
  MimeType: string;
  FileName: string;
  ContentLength: string|number;
}

interface Props {
  attachment: XeroAttachment
  i: number
}
const XeroAttachment = ({attachment, i}: Props) => {
  const xeroAttachmentQuery = AccountsQueryAPI.useRetrieveAttachment()
  const styleProps = useDoxleThemeStore(state => ({
    $doxleFont: state.doxleFont,
    $themeColor: state.doxleThemeColor,
    $currentTheme: state.currentTheme,
  }), shallow);
  const handleGetXeroUrl = (url: string|undefined) => {
    if (url) xeroAttachmentQuery.mutate(url)
  }
  return (
    <StyledAttachmentSpan {...styleProps}
      key={attachment?.AttachmentID ?? `xero-attachment-${i}`}
      onClick={() => handleGetXeroUrl(attachment.Url)}
    >
    <img height={40}
         src={
           attachment.MimeType.toLowerCase() === "application/pdf"
             ? "/assets/icons/adobe-pdf-icon.svg"
             : attachment.MimeType.toLowerCase() === "image/jpeg"
               ? "/assets/icons/jpeg.png"
               : attachment.MimeType.toLowerCase() === "image/png"
                 ? "/assets/icons/png.png"
                 : attachment.MimeType.includes('word')
                   ? "/assets/icons/word.png"
                   : attachment.MimeType.includes('excel')
                     ? "/assets/icons/excel.png"
                     : "/assets/icons/file.png"
         }
    />
      <StyledFileName>{attachment.FileName ?? "File Name Not Found"}</StyledFileName>
    </StyledAttachmentSpan>
  )
}
export default XeroAttachment
