import { GridComponents, GridItemContent, VirtuosoGrid } from "react-virtuoso";
import useBookingCalendar, {
  IBookingCalendarGroup,
} from "../Hooks/useBookingCalendar";
import {
  StyledBookingCalendarContainer,
  StyledBookingItemWrapper,
  StyledBookingListWrapper,
  StyledBookingScroller,
} from "./StyledBookingCalendar";
import TopMenuCalendar from "./TopMenuCalendar";
import { useCallback, useMemo } from "react";
import React from "react";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import BookingDateCell from "./BookingDateCell";
import EditBookingDialog from "../../EditBookingDialog/Components/EditBookingDialog";
import DoxleDialogHelper from "../../../DoxleDesignPattern/DoxleDialogHelper/DoxleDialogHelper";
import SendBooking from "../../EmailBookings/SendBooking";

type Props = {};

const BookingCalendar = () => {
  const { doxleThemeColor } = useDoxleThemeStore();
  const {
    bookingDataDateGroup,
    isErrorFetchingBookingCalendarList,
    wrapperRef,
    numOfCol,
    handleCloseDeleteDialog,
    handleDeleteBooking,
    deletedBooking,
  } = useBookingCalendar();
  const groupBookingItemContent: GridItemContent<
    IBookingCalendarGroup,
    IBookingListContext
  > = useCallback(
    (index, item) => (
      <BookingDateCell
        key={`${item.dateValue.toLocaleTimeString()}-${index}`}
        dateValue={item.dateValue}
        bookingItems={item.bookingList}
      />
    ),
    []
  );
  const components: GridComponents<IBookingListContext> = useMemo(
    () => ({
      Item: ({ context, ...props }) => (
        <StyledBookingItemWrapper
          {...props}
          $numOfCol={context?.numOfCol ?? 3}
        />
      ),
      List: React.forwardRef(({ context, children, ...props }, ref) => (
        <StyledBookingListWrapper {...props} ref={ref}>
          {children}
        </StyledBookingListWrapper>
      )),

      Scroller: React.forwardRef(({ context, ...props }, ref) => (
        <StyledBookingScroller ref={ref} {...props} />
      )),
    }),
    []
  );
  const listStyle: React.CSSProperties = { width: "100%", flex: 1 };

  return (
    <StyledBookingCalendarContainer ref={wrapperRef}>
      <TopMenuCalendar />

      <VirtuosoGrid
        context={{
          isErrorFetchingBookingCalendarList,
          numOfCol,
        }}
        style={listStyle}
        data={bookingDataDateGroup}
        components={components}
        itemContent={groupBookingItemContent}
        overscan={300}
      />

      <EditBookingDialog />
      <SendBooking />
      <DoxleDialogHelper
        open={Boolean(deletedBooking)}
        onClose={handleCloseDeleteDialog}
        title="Confirm delete booking!"
        description={`All data belong to booking item ***${deletedBooking?.title}*** will be deleted permanently,are you sure to proceed?`}
        dialogButtons={[
          {
            buttonText: "Cancel",
            buttonFunction: handleCloseDeleteDialog,
            buttonColor: doxleThemeColor.primaryContainerColor,
            btnStyleSx: {
              border: `1px solid ${doxleThemeColor.primaryDividerColor}`,
            },
            buttonTextStyle: {
              color: doxleThemeColor.primaryFontColor,
            },
          },
          {
            buttonText: "Confirm",
            buttonFunction: handleDeleteBooking,
            btnStyleSx: {
              border: `1px solid ${doxleThemeColor.primaryDividerColor}`,
            },
          },
        ]}
      />
    </StyledBookingCalendarContainer>
  );
};

export default BookingCalendar;

interface IBookingListContext {
  isErrorFetchingBookingCalendarList: boolean;
  numOfCol: number;
}
