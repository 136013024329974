import { useMemo } from "react";
import PermissionsAPI from "../../../Services/QueryHooks/permissionsAPI";
import { useDoxleCurrentContextStore } from "../../../DoxleGeneralStore/useDoxleCurrentContext";
import useDoxleNotificationStore from "../../../DoxleGeneralStore/useDoxleNotificationStore";
import { shallow } from "zustand/shallow";
import {
  DocketPermission,
  ProjectPermission,
} from "../../../Models/permissions";

type Props = {
  project?: string;
  docket?: string;
};

interface IGetSharePermission {
  permissions: Array<ProjectPermission | DocketPermission>;
  permittedContactIds: string[];
  isFetchingPermissions: boolean;
  isSuccessFetchingPermissions: boolean;
  isErrorFetchingPermissions: boolean;
  isFetchingNextPermissions: boolean;
  fetchNextPermissions: () => void;
}
const useGetSharePermission = ({
  project,
  docket,
}: Props): IGetSharePermission => {
  const { company } = useDoxleCurrentContextStore(
    (state) => ({
      company: state.currentCompany,
      currentProject: state.currentProject,
    }),
    shallow
  );
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );
  const contactPermissionQuery =
    PermissionsAPI.useRetrieveContactsPermissionsQuery({
      company,
      showNotification,
      filter: { project, docket },
      enable: Boolean(project || docket),
    });
  const permissions = useMemo(
    () =>
      contactPermissionQuery.isSuccess
        ? contactPermissionQuery.data?.pages
          ? contactPermissionQuery.data?.pages
              .flatMap((page) => page?.data?.results ?? [])
              .filter((item) => item.contact) ?? []
          : []
        : [],
    [contactPermissionQuery.data, contactPermissionQuery.isSuccess]
  );

  const permittedContactIds: string[] = useMemo(
    () =>
      permissions.reduce((acc, data) => {
        if (data.contact) return acc.concat(data.contact.contactId);
        else return acc;
      }, [] as string[]),
    [permissions]
  );

  const fetchNextPermissions = () => {
    if (contactPermissionQuery.hasNextPage)
      contactPermissionQuery.fetchNextPage();
  };
  return {
    permissions,
    permittedContactIds,
    isFetchingPermissions: contactPermissionQuery.isLoading,
    isSuccessFetchingPermissions: contactPermissionQuery.isSuccess,
    isErrorFetchingPermissions: contactPermissionQuery.isError,
    isFetchingNextPermissions: contactPermissionQuery.isFetchingNextPage,
    fetchNextPermissions,
  };
};

export default useGetSharePermission;
