import {
  Virtuoso,
  Components,
  ItemContent,
  VirtuosoHandle,
  GroupedVirtuoso,
  GroupItemContent,
  GroupContent,
} from "react-virtuoso";
import {
  StyledNoticeBoardContentBodySection,
  StyledScroller,
  StyledNBItemWrapper,
  StyledProjectGroupTitleContainer,
  StyledRowWrapperWithFooter,
} from "./StyledNoticeBoardContent";

import React, { useMemo, useCallback, useRef, useEffect } from "react";
import ListLoadingMore from "../../../Utilities/Lottie/ListLoadingMore";
import useGetInboxDockets from "../../Hooks/useGetInboxDockets";
import NoticeBoardRow from "./NoticeBoardRow";
import { Docket, LightDocket } from "../../../Models/dockets";
import DoxleListSkeleton from "../../../DoxleDesignPattern/DoxleSkeleton/DoxleListSkeleton";
import DoxleEmptyPlaceHolder from "../../../DoxleDesignPattern/DoxleEmptyPlaceHolder/DoxleEmptyPlaceHolder";
import { NoticeBoardContentBodyFooter } from "./NoticeBoardContentBodyFooter";
import { AnimatePresence, Variants } from "framer-motion";
import NoticeBoardEmptyScreen from "./NoticeBoardEmptyScreen";
import useNoticeBoardContentBody from "./Hooks/useNoticeBoardContentBody";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import { useInboxStore } from "../../InboxStore/useInboxStore";
import NBStatusPopover from "../NBStatusPopover/NBStatusPopover";
import NBGroupContentFooter from "./NBGroupContentFooter";
import NBProjectGroupHeader from "./NBProjectGroupHeader";
import { Error404Banner } from "../../../DoxleDesignPattern/DoxleIcons/CommonIcons";

export interface INoticeBoardContentBodyProps {}

interface ListContext {
  scrollToBottom: () => void;
  animatedVariants: Variants;
  isErrorFetchingDocketList: boolean;
}

export default function NoticeBoardContentBody(
  props: INoticeBoardContentBodyProps
) {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }),
    shallow
  );
  const { inboxGroupMode } = useInboxStore(
    (state) => ({
      inboxGroupMode: state.inboxGroupMode,
      inboxViewMode: state.inboxViewMode,
    }),
    shallow
  );
  const {
    isFilteredByProject,
    handleClickStatusNbItem,
    anchorNbStatusMenuEl,
    currentFocusedNB,
    handleClosePopoverStatusMenu,
  } = useNoticeBoardContentBody({});
  const {
    docketList,
    isErrorFetchingDocketList,
    isFetchingDocketList,
    isSuccessFetchingDocketList,
    isFetchingNextPageDocketList,
    hasNextPageDocket,
    handleFetchNextPageDocket,

    docketSortedByStatus,
    generateGroupNbByAssignee,
    generateGroupNbByProject,
  } = useGetInboxDockets({});

  const { nbItemsByAssignee, assigneeGroupIds, groupAssigneeNbCount } =
    generateGroupNbByAssignee;
  const { nbItemsByProject, projectGroupIds, groupProjectNbCount } =
    generateGroupNbByProject;
  const tableRef = useRef<VirtuosoHandle>(null);
  const scrollToBottom = useCallback(() => {
    setTimeout(() => {
      tableRef.current?.scrollToIndex({
        index: docketList.length - 1,
        behavior: "smooth",
      });
    }, 500);
  }, [tableRef.current]);
  const animatedVariants: Variants = {
    initial: {
      x: -5,
      opacity: 0,
    },
    entering: {
      x: 0,
      opacity: 1,
    },
    exiting: {
      x: 10,
      opacity: 0,
    },
  };
  const components: Components<LightDocket, ListContext> = useMemo(
    () => ({
      Scroller: React.forwardRef((props, ref) => (
        <StyledScroller ref={ref} {...props} />
      )),

      Footer: React.forwardRef((props, ref) =>
        props.context?.scrollToBottom ? (
          <NoticeBoardContentBodyFooter
            {...props}
            scrollToBottom={props.context.scrollToBottom}
          />
        ) : null
      ),
      Item: ({ context, ...rest }) => (
        <AnimatePresence key={rest.item.docketPk}>
          <StyledNBItemWrapper
            {...rest}
            layout
            variants={context?.animatedVariants}
            initial="initial"
            animate="entering"
            exit="exiting"
            transition={{
              duration: 0.2,
            }}
          />
        </AnimatePresence>
      ),
      EmptyPlaceholder: ({ context, ...rest }) =>
        context?.isErrorFetchingDocketList ? (
          <DoxleEmptyPlaceHolder
            headTitleText="Something wrong!"
            subTitleText="Failed to get notice board data, we are sorry! Please try to reload page..."
          />
        ) : (
          <NoticeBoardEmptyScreen />
        ),
    }),
    []
  );

  const itemContent: ItemContent<LightDocket, ListContext> = useCallback(
    (index, data, context) => {
      return (
        <NoticeBoardRow
          data={data}
          handleClickStatusNbItem={handleClickStatusNbItem}
        />
      );
    },
    [handleClickStatusNbItem]
  );

  const listStyle: React.CSSProperties = { width: "100%", height: "100%" };

  // //* group list content
  const groupListComponents: Components<LightDocket, ListContext> = useMemo(
    () => ({
      Scroller: React.forwardRef((props, ref) => (
        <StyledScroller ref={ref} {...props} />
      )),

      EmptyPlaceholder: ({ context, ...rest }) =>
        context?.isErrorFetchingDocketList ? (
          <DoxleEmptyPlaceHolder
            illustration={
              <Error404Banner
                themeColor={doxleThemeColor}
                containerStyle={{
                  width: "70%",
                }}
              />
            }
            headTitleText="Something wrong!"
            subTitleText="Failed to get notice board data, we are sorry! Please try to reload page..."
          />
        ) : (
          <NoticeBoardEmptyScreen />
        ),
    }),
    []
  );

  //* group user
  const groupUserItemContent: GroupItemContent<LightDocket, ListContext> =
    useCallback(
      (itemIndex: number, groupIdx: number) => {
        const lastIndexOfGroup = nbItemsByAssignee.reduce(
          (acc, item, index) => {
            if (
              ((item.ballInCourt !== null &&
                item.ballInCourt === assigneeGroupIds[groupIdx]) ||
                (assigneeGroupIds[groupIdx] === "null" &&
                  item.ballInCourt === null)) &&
              index >= acc
            )
              return index;
            return acc;
          },
          -1
        );
        if (lastIndexOfGroup !== -1 && itemIndex === lastIndexOfGroup)
          return (
            <StyledRowWrapperWithFooter
              key={nbItemsByAssignee[itemIndex].docketPk}
            >
              <NoticeBoardRow
                data={nbItemsByAssignee[itemIndex]}
                handleClickStatusNbItem={handleClickStatusNbItem}
              />

              <NBGroupContentFooter
                assigneeId={
                  assigneeGroupIds[groupIdx] !== "null"
                    ? assigneeGroupIds[groupIdx]
                    : null
                }
              />
            </StyledRowWrapperWithFooter>
          );
        else
          return (
            <NoticeBoardRow
              data={nbItemsByAssignee[itemIndex]}
              handleClickStatusNbItem={handleClickStatusNbItem}
              key={nbItemsByAssignee[itemIndex].docketPk}
            />
          );
      },
      [nbItemsByAssignee, assigneeGroupIds, handleClickStatusNbItem]
    );

  const groupUserContent: GroupContent<ListContext> = useCallback(
    (idx, context) => {
      const assigneeId = assigneeGroupIds[idx];
      const firstItem = assigneeId
        ? nbItemsByAssignee.find(
            (nb) =>
              (assigneeId !== "null" && nb.ballInCourt === assigneeId) ||
              (assigneeId === "null" && nb.ballInCourt === null)
          )
        : undefined;
      if (assigneeId !== "null")
        return (
          <StyledProjectGroupTitleContainer
            $themeColor={doxleThemeColor}
            $doxleFont={doxleFont}
          >
            <span className="project-address-title">
              {firstItem?.ballInCourtName}
            </span>
          </StyledProjectGroupTitleContainer>
        );
      else return <div style={{ height: 1 }} />;
    },
    [assigneeGroupIds, nbItemsByAssignee, doxleThemeColor, doxleFont]
  );

  //*group project
  const groupProjectContent: GroupContent<ListContext> = useCallback(
    (idx, context) => {
      const projectId = projectGroupIds[idx];
      const firstItem = projectId
        ? nbItemsByAssignee.find(
            (nb) =>
              (projectId !== "null" && nb.project === projectId) ||
              (projectId === "null" && nb.project === null)
          )
        : undefined;
      if (projectId !== "null")
        return (
          <NBProjectGroupHeader
            projectId={projectId}
            projectSiteAddress={firstItem?.projectSiteAddress ?? ""}
          />
        );
      else return <div style={{ height: 1 }} />;
    },
    [projectGroupIds, nbItemsByProject, doxleThemeColor, doxleFont]
  );

  const groupProjectItemContent: GroupItemContent<LightDocket, ListContext> =
    useCallback(
      (itemIndex: number, groupIdx: number) => {
        const lastIndexOfGroup = nbItemsByProject.reduce((acc, item, index) => {
          if (
            ((item.project !== null &&
              item.project === projectGroupIds[groupIdx]) ||
              (assigneeGroupIds[groupIdx] === "null" &&
                item.project === null)) &&
            index >= acc
          )
            return index;
          return acc;
        }, -1);

        if (lastIndexOfGroup !== -1 && itemIndex === lastIndexOfGroup)
          return (
            <StyledRowWrapperWithFooter
              key={nbItemsByProject[itemIndex].docketPk}
            >
              <NoticeBoardRow
                data={nbItemsByProject[itemIndex]}
                handleClickStatusNbItem={handleClickStatusNbItem}
              />

              {lastIndexOfGroup !== -1 && itemIndex === lastIndexOfGroup && (
                <NBGroupContentFooter
                  projectId={
                    projectGroupIds[groupIdx] !== "null"
                      ? projectGroupIds[groupIdx]
                      : null
                  }
                />
              )}
            </StyledRowWrapperWithFooter>
          );
        else
          return (
            <NoticeBoardRow
              key={nbItemsByProject[itemIndex].docketPk}
              data={nbItemsByProject[itemIndex]}
              handleClickStatusNbItem={handleClickStatusNbItem}
            />
          );
      },
      [nbItemsByProject, projectGroupIds, handleClickStatusNbItem]
    );
  return (
    <StyledNoticeBoardContentBodySection>
      {!isFetchingDocketList && (
        <>
          {!isFilteredByProject && (
            <Virtuoso
              style={listStyle}
              context={{
                scrollToBottom,
                animatedVariants,
                isErrorFetchingDocketList,
              }}
              ref={tableRef}
              data={docketSortedByStatus}
              components={components}
              itemContent={itemContent}
              endReached={() => {
                handleFetchNextPageDocket();
              }}
            />
          )}

          {isFilteredByProject && (
            <>
              {inboxGroupMode === "Projects" && (
                <GroupedVirtuoso
                  style={listStyle}
                  context={{
                    scrollToBottom,
                    animatedVariants,
                    isErrorFetchingDocketList,
                  }}
                  ref={tableRef}
                  groupCounts={groupProjectNbCount}
                  components={groupListComponents}
                  itemContent={groupProjectItemContent}
                  groupContent={groupProjectContent}
                  endReached={() => {
                    handleFetchNextPageDocket();
                  }}
                />
              )}
              {inboxGroupMode === "Assignee" && (
                // <Virtuoso
                //   style={listStyle}
                //   context={{
                //     scrollToBottom,
                //     animatedVariants,
                //     isErrorFetchingDocketList,
                //   }}
                //   ref={tableRef}
                //   data={groupDocketsByAssignee}
                //   components={groupUserListComponents}
                //   itemContent={groupUserItemContent}
                //   endReached={() => {
                //     if (hasNextPageDocket) handleFetchNextPageDocket();
                //   }}
                // />
                <GroupedVirtuoso
                  style={listStyle}
                  context={{
                    scrollToBottom,
                    animatedVariants,
                    isErrorFetchingDocketList,
                  }}
                  ref={tableRef}
                  groupCounts={groupAssigneeNbCount}
                  components={groupListComponents}
                  itemContent={groupUserItemContent}
                  groupContent={groupUserContent}
                  endReached={() => {
                    handleFetchNextPageDocket();
                  }}
                />
              )}
            </>
          )}
        </>
      )}

      {isFetchingDocketList && (
        <DoxleListSkeleton
          containerWidthInRatio="100%"
          containerHeightInRatio="100%"
          skeletonType="docketRow"
        />
      )}

      {isFetchingNextPageDocketList && (
        <ListLoadingMore
          animationSize={144}
          containerStyle={{
            bottom: 0,
            left: "50%",
            transform: "translateX(-50%)",
            display: "flex",
            justifyContent: "center",
            position: "absolute",
            zIndex: 10,
          }}
        />
      )}

      <NBStatusPopover
        anchorNbStatusMenuEl={anchorNbStatusMenuEl}
        currentFocusedNB={currentFocusedNB}
        handleClosePopoverStatusMenu={handleClosePopoverStatusMenu}
      />
    </StyledNoticeBoardContentBodySection>
  );
}
