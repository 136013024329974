import React, { useState } from "react";
import ProjectQueryAPI from "../../../../Services/QueryHooks/projectQueryAPI";
import { useDoxleCurrentContextStore } from "../../../../DoxleGeneralStore/useDoxleCurrentContext";
import useDoxleNotificationStore from "../../../../DoxleGeneralStore/useDoxleNotificationStore";
import { shallow } from "zustand/shallow";
import { useInboxStore } from "../../../InboxStore/useInboxStore";
import useSetDocketListQueryData from "../../../../CoreContent/QueryDataHooks/SetQueryDataHooks/useSetDocketListQueryData";
import { Project } from "../../../../Models/project";
import { useDoxleAuthStore } from "../../../../DoxleGeneralStore/useDoxleAuthStore";

type Props = {
  projectId: string | null;
  projectSiteAddress: string;
};

interface NBGroupItem {
  showDeleteDialog: boolean;
  setShowDeleteDialog: React.Dispatch<React.SetStateAction<boolean>>;
  handleClickDeleteIcon: () => void;
  handleCloseDeleteDialog: () => void;
  onEditProject: boolean;
  handleClickEditBtn: () => void;
  handleDeleteNBProject: () => void;
  isDeletingProject: boolean;
  isUpdatingProject: boolean;
  newAddressText: string;
  setnewAddressText: React.Dispatch<React.SetStateAction<string>>;
  handleInputKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => void;
}
const useNBProjectGroupHeader = ({
  projectId,
  projectSiteAddress,
}: Props): NBGroupItem => {
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [onEditProject, setOnEditProject] = useState(false);
  const [newAddressText, setnewAddressText] = useState(projectSiteAddress);
  const { company } = useDoxleCurrentContextStore((state) => ({
    company: state.currentCompany,
  }));
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );
  const { user } = useDoxleAuthStore(
    (state) => ({ user: state.user }),
    shallow
  );
  const { filterDocketListQuery } = useInboxStore(
    (state) => ({
      filterDocketListQuery: state.filterDocketListQuery,
    }),
    shallow
  );

  const {
    handleRemoveDocketWithProjectId: handleRemoveMyNoticeDockets,
    handleUpdateProjectAddress: handleUpdateMyNbProjectAddress,
  } = useSetDocketListQueryData({
    filter: {
      ...filterDocketListQuery,
      ball_in_court: user?.userId,
      archived: false,
    },
  });

  const {
    handleRemoveDocketWithProjectId: handleRemoveAllNoticeDockets,
    handleUpdateProjectAddress: handleUpdateAllNbProjectAddress,
  } = useSetDocketListQueryData({
    filter: {
      ...filterDocketListQuery,
      ball_in_court: undefined,
      archived: false,
    },
  });

  const {
    handleRemoveDocketWithProjectId: handleRemoveArchiveDockets,
    handleUpdateProjectAddress: handleUpdateArchivedNbProjectAddress,
  } = useSetDocketListQueryData({
    filter: {
      ...filterDocketListQuery,
      ball_in_court: undefined,
      archived: true,
    },
  });

  const onSuccessDeleteCb = (deletedProjectId?: string) => {
    if (deletedProjectId) {
      handleRemoveMyNoticeDockets(deletedProjectId);
      handleRemoveAllNoticeDockets(deletedProjectId);
      handleRemoveArchiveDockets(deletedProjectId);
    }
  };
  const deleteProjectQuery = ProjectQueryAPI.useClearProjectNbQuery({
    showNotification,
    company,

    onSuccessCb: onSuccessDeleteCb,
  });

  const onSuccessUpdate = (newProject?: Project) => {
    if (newProject) {
      setnewAddressText(newProject.siteAddress);
      handleUpdateMyNbProjectAddress(
        newProject.projectId,
        newProject.siteAddress
      );
      handleUpdateAllNbProjectAddress(
        newProject.projectId,
        newProject.siteAddress
      );
      handleUpdateArchivedNbProjectAddress(
        newProject.projectId,
        newProject.siteAddress
      );
    }
  };
  const updateProjectQuery = ProjectQueryAPI.useUpdateProjectQuery({
    showNotification,
    company,

    onSuccessUpdateCb: onSuccessUpdate,
    filter: {},
  });
  const handleDeleteNBProject = () => {
    if (projectId) deleteProjectQuery.mutate(projectId);
    setShowDeleteDialog(false);
  };

  const handleClickEditBtn = () => {
    setOnEditProject(true);
  };
  const handleEditAddress = () => {
    if (newAddressText && projectId) {
      updateProjectQuery.mutate({
        projectId: projectId,
        updateData: {
          siteAddress: newAddressText,
        },
      });
    }

    setOnEditProject(false);
  };
  const handleInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.nativeEvent.key === "Enter") {
      if (newAddressText) {
        handleEditAddress();
      } else {
        event.currentTarget.blur();
      }
    }

    if (event.nativeEvent.key === "Escape") {
      setnewAddressText("");
      event.currentTarget.blur();
    }
  };
  const handleClickDeleteIcon = () => {
    setShowDeleteDialog(true);
  };
  const handleCloseDeleteDialog = () => {
    setShowDeleteDialog(false);
  };

  return {
    showDeleteDialog,
    setShowDeleteDialog,
    handleClickDeleteIcon,
    handleCloseDeleteDialog,
    handleClickEditBtn,
    onEditProject,
    handleDeleteNBProject,
    isDeletingProject: deleteProjectQuery.isLoading,
    isUpdatingProject: updateProjectQuery.isLoading,
    newAddressText,
    setnewAddressText,
    handleInputKeyDown,
  };
};

export default useNBProjectGroupHeader;
