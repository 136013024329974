import React, { useCallback, useState } from "react";
import { useDoxleCurrentContextStore } from "../../../../DoxleGeneralStore/useDoxleCurrentContext";
import useDoxleNotificationStore from "../../../../DoxleGeneralStore/useDoxleNotificationStore";
import { useScopeOfWorkStore } from "../../../Store/useScopeOfWorkStore";
import { shallow } from "zustand/shallow";
import useSetSOWItemQueryData from "../../../Hooks/useSetSOWItemQueryData";
import { ScopeOfWorkGroup } from "../../../Model/scopeOfWorks";
import ScopeOfWorkAPI, {
  UpdateScopeGroupData,
} from "../../../QueryAPI/scopeOfWorkQueryAPI";
import { Contact, ContactCompany } from "../../../../Models/addressBook";
import useSetScopeOfWorkGroupQueryData from "../../../Hooks/useSetScopeOfWorkGroupQueryData";
import { useRootNavBarPopoverMenuContext } from "../../../../DoxleDesignPattern/DoxleNestedPopover";
import { PopupState, usePopupState } from "material-ui-popup-state/hooks";

type Props = {};

interface IScopeGroupMenuPopover {
  handleUpdateContactAssignee: (item: Contact) => void;
  handleUpdateCompanyAssignee: (item: ContactCompany) => void;
  handleRemoveContactAssignee: (item: Contact) => void;
  handleRemoveCompanyAssignee: (item: ContactCompany) => void;
}
const useScopeGroupMenuPopover = ({}: Props): IScopeGroupMenuPopover => {
  const { company } = useDoxleCurrentContextStore((state) => ({
    company: state.currentCompany,
  }));
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification
  );
  const {
    filterScopeOfWorkGroup,
    setDeletedSOWGroup,

    setFocusedSOWGroup,
    focusedSOWGroup,
    updateFocusedSOWGroup,
  } = useScopeOfWorkStore(
    (state) => ({
      filterScopeOfWorkGroup: state.filterScopeOfWorkGroup,
      setDeletedSOWGroup: state.setDeletedSOWGroup,

      setFocusedSOWGroup: state.setFocusedSOWGroup,
      focusedSOWGroup: state.focusedSOWGroup,
      updateFocusedSOWGroup: state.updateFocusedSOWGroup,
    }),
    shallow
  );

  const { handleToggleCompleteAllSOW } = useSetSOWItemQueryData({
    filter: {
      scopeGroupId: focusedSOWGroup?.sowItem.scopeGroupId ?? "",
    },
  });
  const onUpdateSuccessCb = (
    updatedItem?: ScopeOfWorkGroup,
    lastPayload?: UpdateScopeGroupData
  ) => {
    if (lastPayload && lastPayload.completed !== undefined) {
      handleToggleCompleteAllSOW(lastPayload.completed);
    }
    if (updatedItem) {
      console.log("UPDATE SOW");
      updateFocusedSOWGroup(updatedItem);
    }
  };

  const updateSOWGroupQuery = ScopeOfWorkAPI.useUpdateScopeGroupQuery({
    showNotification,

    company,
    filter: filterScopeOfWorkGroup,
    onSuccessCb: onUpdateSuccessCb,
  });

  const handleUpdateContactAssignee = useCallback(
    (item: Contact) => {
      if (focusedSOWGroup) {
        if (focusedSOWGroup.sowItem.assignedContacts) {
          const isExist = focusedSOWGroup.sowItem.assignedContacts.includes(
            item.contactId
          );
          if (!isExist)
            updateSOWGroupQuery.mutate({
              scopeGroupId: focusedSOWGroup.sowItem.scopeGroupId,
              assignedContacts: [
                ...focusedSOWGroup.sowItem.assignedContacts,
                item.contactId,
              ],
            });
          else
            updateSOWGroupQuery.mutate({
              scopeGroupId: focusedSOWGroup.sowItem.scopeGroupId,
              assignedContacts: focusedSOWGroup.sowItem.assignedContacts.filter(
                (selectedItem) => selectedItem !== item.contactId
              ),
            });
        } else {
          updateSOWGroupQuery.mutate({
            scopeGroupId: focusedSOWGroup.sowItem.scopeGroupId,
            assignedContacts: [item.contactId],
          });
        }
      }
    },
    [focusedSOWGroup]
  );

  const handleUpdateCompanyAssignee = useCallback(
    (item: ContactCompany) => {
      if (focusedSOWGroup) {
        if (focusedSOWGroup.sowItem.assignedContactCompany) {
          updateSOWGroupQuery.mutate({
            scopeGroupId: focusedSOWGroup.sowItem.scopeGroupId,
            assignedContactCompany: null,
          });
        } else {
          updateSOWGroupQuery.mutate({
            scopeGroupId: focusedSOWGroup.sowItem.scopeGroupId,
            assignedContactCompany: item.contactCompanyId,
          });
        }
      }
    },
    [focusedSOWGroup]
  );

  const handleRemoveContactAssignee = (item: Contact) => {
    if (focusedSOWGroup) {
      if (focusedSOWGroup.sowItem.assignedContacts) {
        updateSOWGroupQuery.mutate({
          scopeGroupId: focusedSOWGroup.sowItem.scopeGroupId,
          assignedContacts: focusedSOWGroup.sowItem.assignedContacts.filter(
            (selectedItem) => selectedItem !== item.contactId
          ),
        });
      }
    }
  };

  const handleRemoveCompanyAssignee = (item: ContactCompany) => {
    if (focusedSOWGroup) {
      if (focusedSOWGroup.sowItem.assignedContactCompany) {
        updateSOWGroupQuery.mutate({
          scopeGroupId: focusedSOWGroup.sowItem.scopeGroupId,
          assignedContactCompany: null,
        });
      }
    }
  };

  return {
    handleUpdateContactAssignee,
    handleUpdateCompanyAssignee,
    handleRemoveContactAssignee,
    handleRemoveCompanyAssignee,
  };
};

export default useScopeGroupMenuPopover;
