import React from "react";
import PDFIcon from "../../Assets/adobePdfIcon.svg";
import MicrosoftIcon from "../../Assets/microsoftIcon.svg";
import ExcelIcon from "../../Assets/excelIcon.svg";

import { HiTrash } from "react-icons/hi2";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { QuoteStorage } from "../../models/quote";
import {
  StyledAddedAttachmentItem,
  StyledAttachmentName,
  StyledFileImage,
  StyledImageContainer,
  StyledQuoteAttachmentItem,
  StyledQuoteAttachmentsIconWrapper,
} from "./StyledComponentReviewQuote";
import { StyledQuoteDetailLabelText } from "../QuoteRequestStyledComponents";
import { useIsMutating } from "@tanstack/react-query";
import { getQStorageMutationKey } from "../../QueryAPI/quotesQuery";
import CircularProgress from "@mui/material/CircularProgress";
import { useReviewQuoteRequestContext } from "./ReviewQuoteRequest";
import {
  getFileIconUrl,
  isImageFile,
} from "../../../Utilities/FunctionUtilities";
import DoxleIconButton from "../../../DoxleDesignPattern/DoxleButtons/DoxleIconButton";

type Props = {
  attachment: QuoteStorage;
};

export const FILETYPE = {
  pdf: PDFIcon,
  docx: MicrosoftIcon,
  xlsx: ExcelIcon,
};
const QuoteAttachmentItem = ({ attachment }: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore((state) => ({
    doxleThemeColor: state.doxleThemeColor,
    doxleFont: state.doxleFont,
  }));
  const { setDeletedQuoteAttachment } = useReviewQuoteRequestContext();
  const isDeletingAttachment =
    useIsMutating({
      mutationKey: getQStorageMutationKey("delete"),
      predicate: (query) =>
        Boolean(query.state.variables === attachment.attachmentId),
    }) > 0;
  return (
    // <StyledQuoteAttachmentItem
    //   $themeColor={THEME_COLOR}
    //   layout
    //   initial={{ x: -10, opacity: 0 }}
    //   animate={{ x: 0, opacity: 1 }}
    // >
    //   <StyledQuoteAttachmentsIconWrapper>
    //     <img className="img-wrapper" src={fileExtension} />
    //     <StyledQuoteDetailLabelText
    //       $paddingRight={"6px"}
    //       $fontSize={"1.4rem"}
    //       $themeColor={THEME_COLOR}
    //       $doxleFont={DOXLE_FONT}
    //     >
    //       {attachment.fileName}
    //     </StyledQuoteDetailLabelText>
    //   </StyledQuoteAttachmentsIconWrapper>

    //   {isDeletingAttachment ? (
    //     <CircularProgress
    //       color="error"
    //       size="1.6rem"
    //       style={{ marginInline: 4 }}
    //     />
    //   ) : (
    //     <HiTrash
    //       size="1.6rem"
    //       color={THEME_COLOR.primaryFontColor}
    //       style={{ marginInline: 4 }}
    //       onClick={() => setDeletedQuoteAttachment(attachment)}
    //     />
    //   )}
    // </StyledQuoteAttachmentItem>
    <StyledAddedAttachmentItem
      layout
      initial={false}
      animate={{ y: [10, 0], opacity: [0, 1] }}
      exit={{
        y: [0, 10],
        opacity: [1, 0],
      }}
      transition={{
        duration: 0.2,
      }}
    >
      <DoxleIconButton
        iconSource={
          isDeletingAttachment ? (
            <CircularProgress
              color="error"
              size="1.6rem"
              style={{ marginInline: 4 }}
            />
          ) : (
            <HiTrash
              size="1.6rem"
              color={doxleThemeColor.primaryFontColor}
              style={{ marginInline: 4 }}
              onClick={() => setDeletedQuoteAttachment(attachment)}
            />
          )
        }
        buttonSize={30}
        disableAnimation
        onClick={() => setDeletedQuoteAttachment(attachment)}
        buttonWrapperStyle={{
          position: "absolute",
          zIndex: 10,
          top: "-1.5rem",
          right: "-0.5rem",
        }}
      />
      <StyledImageContainer>
        <StyledFileImage
          src={
            isImageFile(attachment.fileName)
              ? attachment.url
              : getFileIconUrl(attachment.fileName)
          }
        />
      </StyledImageContainer>
      <StyledAttachmentName>{attachment.fileName}</StyledAttachmentName>
    </StyledAddedAttachmentItem>
  );
};

export default QuoteAttachmentItem;
