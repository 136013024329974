import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  Box,
} from "@mui/material";
import { randomUUID } from "crypto";
import React, { useEffect, useRef } from "react";
import { shallow } from "zustand/shallow";
import { useDoxleAuthStore } from "../../DoxleGeneralStore/useDoxleAuthStore";
import { useDoxleCurrentContextStore } from "../../DoxleGeneralStore/useDoxleCurrentContext";
import useDoxleNotificationStore from "../../DoxleGeneralStore/useDoxleNotificationStore";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import { DoxleFile, DoxleFolder } from "../../Models/files";
import { PencilIcon } from "../Content/FileIcons";
import {
  StyledFileRenameField,
  StyledIconButton,
} from "../Content/StyledFilesBodyComponent";
import { useFileStore } from "../Store/useFileStore";
import { v4 as uuidv4 } from "uuid";
import { useShallow } from "zustand/react/shallow";

const checkIcon = require("../Assets/check.png");
const crossIcon = require("../Assets/cross.png");

type Props = {
  dialogOpen: boolean;
  handleTick: (value: string) => void;
  handleKeyDown: (value: string) => void;
  handleClose: Function;
  currentHoverItem?: string;
};

function FileRenameDialog({
  dialogOpen,
  handleKeyDown,
  handleTick,
  handleClose,
  currentHoverItem,
}: Props) {
  const inputRef: React.MutableRefObject<undefined | HTMLInputElement> =
    useRef();

  const { doxleThemeColor, doxleFont } = useDoxleThemeStore((state) => ({
    doxleThemeColor: state.doxleThemeColor,
    doxleFont: state.doxleFont,
  }));

  const currentFolder = useFileStore(
    useShallow((state) => state.currentFolder)
  );

  useEffect(() => {
    if (inputRef.current && dialogOpen) inputRef.current.focus();

    return () => {};
  }, [dialogOpen, inputRef.current]);

  return (
    <Dialog
      sx={{
        "& .MuiDialog-paper": {
          backgroundColor: "transparent",
        },
      }}
      // disableRestoreFocus
      open={dialogOpen}
      onClose={(event, reason) => {
        if (reason === "backdropClick" || "escapeKeyDown") {
          handleClose();
        }
      }}
    >
      <DialogContent
        sx={{
          width: "40rem",
          height: "5rem",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "9px",
          backgroundColor: doxleThemeColor.primaryContainerColor,
          flexDirection: "row",
          display: "flex",
        }}
      >
        <>
          <StyledFileRenameField
            key={currentFolder ? currentFolder?.folderId : uuidv4()}
            autoFocus={true}
            focused={true}
            $themeColor={doxleThemeColor}
            $doxleFont={doxleFont}
            placeholder={currentHoverItem}
            defaultValue={currentHoverItem}
            variant={"standard"}
            inputRef={inputRef}
            onClick={(event: React.MouseEvent<HTMLInputElement>) => {
              if (inputRef.current) inputRef.current.value = "";
            }}
            onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => {
              if (event.nativeEvent.key === "Enter") {
                event.preventDefault();
                if (inputRef.current) handleKeyDown(inputRef.current?.value);
              }
            }}
            style={{
              backgroundColor: doxleThemeColor.lightDividerColor,
              color: doxleThemeColor.bolderDividerColor,
              borderRadius: "0px",
              height: "45px",
              border: "1px",
              // padding: "8px",
            }}
          />
          <span style={{ marginRight: "6px" }}></span>
          <StyledIconButton
            $themeColor={doxleThemeColor}
            onClick={() => {
              if (inputRef.current) handleTick(inputRef.current?.value);
            }}
          >
            <img
              src={checkIcon}
              style={{
                color: "black",
                width: "30px",
              }}
            />
          </StyledIconButton>
          <span style={{ marginRight: "6px" }}></span>
          <StyledIconButton
            $themeColor={doxleThemeColor}
            style={{ backgroundColor: doxleThemeColor.primaryDividerColor }}
            onClick={() => handleClose()}
          >
            <img
              src={crossIcon}
              style={{
                width: "30px",
              }}
            />
          </StyledIconButton>
        </>
      </DialogContent>
    </Dialog>
  );
}

export default FileRenameDialog;
