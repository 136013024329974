import { motion } from "framer-motion";
import styled from "styled-components";

export const StyledDoxlePermissionAvatar = styled(motion.div)<{
  $bgColor: string;
  $size?: number;
}>`
  font-size: ${(p) => (p.$size ? p.$size / 2 : 12)}px;
  font-family: ${(p) => p.theme.font.primaryFont};
  font-style: normal;
  font-weight: 400;
  line-height: ${(p) => (p.$size ? p.$size / 2 : 12)}px;
  color: #000;
  cursor: pointer;
  width: ${(p) => p.$size ?? 24}px;
  height: ${(p) => p.$size ?? 24}px;
  border-radius: 50%;
  background-color: ${(p) => p.$bgColor};
  margin: 0px 2px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
