import { Variants, motion } from "framer-motion";
import styled from "styled-components";
import {
  DoxleFont,
  DoxleThemeColor,
} from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { editRgbaAlpha } from "../../../Utilities/FunctionUtilities";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import Paper, { PaperProps } from "@mui/material/Paper";

export const StyledBookingProjectContainer = styled(motion.div)<{
  $themeColor: DoxleThemeColor;
}>`
  width: 100%;
  height: 100%;
  display: flex;
  background-color: ${(p) => p.$themeColor.primaryBackgroundColor};
  flex-direction: column;
`;

export const StyledBookingProjectFilterMenuContainer = styled.div`
  width: 100%;
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
`;

export const StyledBookingProjectFilterOption = styled(motion.div)<{
  $selected: boolean;
}>`
  padding: 4px 8px;
  cursor: pointer;
  display: flex;
  font-family: ${(p) => p.theme.font.primaryFont};
  font-style: normal;
  font-weight: ${(p) => (p.$selected ? 600 : 400)};
  font-size: 14px;
  color: ${(p) => p.theme.color.primaryFontColor};
  position: relative;
  text-transform: capitalize;
  transition: 0.4s;
  &:hover {
    transform: translateY(-2px);
    transition: 0.4s;
  }
  .selected-filter-effect {
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    z-index: 0;
    background-color: ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.theme.color.doxleColor,
        alpha: "0.15",
      })};
  }
  .close-icon {
    top: 0px;
    right: 0px;
    transform: translate(25%, -40%);
    position: absolute;
    z-index: 1;
  }
`;
export const StyledBookingDocketNumberContainer = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  position: relative;
  padding: 0px 4px;

  .docket-number-text {
    position: absolute;
    z-index: 4;
    color: ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.$themeColor.primaryFontColor,
        alpha: "0.7",
      })};

    font-family: ${(p) => p.$doxleFont.plexSanCondensed};
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;

    letter-spacing: -0.12rem;
    bottom: -0.1rem;
    left: 2px;
    background-color: ${(p) => p.$themeColor.primaryBackgroundColor};
  }
  .require-symbol {
    position: absolute;
    z-index: 4;
    color: ${(p) => p.$themeColor.doxleColor};

    font-family: ${(p) => p.$doxleFont.plexSanCondensed};
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;

    letter-spacing: -0.12rem;
    top: 0px;
    right: 7px;
  }
`;
interface ICustomTextFieldProps
  extends Omit<
    TextFieldProps<"standard">,
    "onDragStart" | "onAnimationStart" | "onDragEnd" | "onDrag"
  > {
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}

export const StyledBookingDateRangePickerWrapper = styled(motion.div)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
  $isNull: boolean;
}>`
  align-self: flex-start !important;
  min-width: 14.4rem !important;
  max-width: 100% !important;
  height: 3rem;
  max-height: 34px;
  background-color: ${(p) =>
    editRgbaAlpha({
      rgbaColor: p.$themeColor.doxleColor,
      alpha: "0.08",
    })};
  display: flex;
  align-items: center;

  border: 1px solid
    ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.$themeColor.doxleColor,
        alpha: "0.15",
      })};
  padding: 0px 4px;

  font-family: ${(p) => p.$doxleFont.primaryFont};
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;
  color: ${(p) =>
    p.$isNull
      ? editRgbaAlpha({
          rgbaColor: p.$themeColor.primaryFontColor,
          alpha: "0.5",
        })
      : p.$themeColor.primaryFontColor};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  position: relative;
  .date-difference-text {
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-style: normal;
    font-weight: 500;
    font-size: 1.2rem;
    color: ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.$themeColor.primaryFontColor,
        alpha: "0.5",
      })};
    margin-left: 4px;
  }
`;

interface IDoxlePaperCustomProps
  extends Omit<
    PaperProps,
    "onDragStart" | "onAnimationStart" | "onDragEnd" | "onDrag"
  > {
  $themeColor: DoxleThemeColor;
  $height?: number;
}
const AnimatedPaper = motion(Paper);
const paperAnimatedVariants: Variants = {
  entering: {
    y: [-10, 0],
    scale: [0, 1],
    opacity: [0, 1],
    transition: {
      duration: 0.4,
    },
  },
  exiting: {
    y: [0, -10],
    scale: [1, 0],
    opacity: [1, 0],
    transition: {
      duration: 0.4,
    },
  },
};
export const StyledBookingDocketPopperContainer = styled(
  ({ $themeColor, $height, ...props }: IDoxlePaperCustomProps) => (
    <AnimatedPaper
      {...props}
      variants={paperAnimatedVariants}
      animate="entering"
      exit="exiting"
      initial="initial"
      layout
      elevation={4}
    />
  )
)`
  && {
    background-color: ${(p) => p.$themeColor.primaryContainerColor};
    width: 30rem;
    min-height: 14rem;
    ${(p) => p.$height && `height:${p.$height / 10}rem;`}
    border-radius: 0px 0.9rem 0.9rem 0.9rem;
    position: relative;
    padding: 1rem 4px;
    box-shadow: 0px 0px 8px 0px ${(p) => p.$themeColor.primaryBoxShadowColor};
    display: flex;
    flex-direction: column;
    margin-top: 8px;
  }
`;

export const StyledSearchDocketTextField = styled(
  ({ $themeColor, $doxleFont, ...props }: ICustomTextFieldProps) => (
    <TextField {...props} />
  )
)`
  && {
    width: calc(100%);
    padding-bottom: 0.8rem;
    border-bottom: 1px solid ${(p) => p.$themeColor.primaryDividerColor};
  }
  input {
    color: ${(p) => p.$themeColor.primaryFontColor};
    font-size: 1.4rem;
    font-family: ${(p) => p.$doxleFont.primaryTitleFont};
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.65px;
    padding: 0px !important;
    &::placeholder {
      color: ${(p) => p.$themeColor.primaryFontColor};
      font-size: 1.4rem;
      font-family: ${(p) => p.$doxleFont.primaryFont};
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.65px;
      padding: 0px !important;
    }
  }
  & .MuiOutlinedInput-root {
    padding: 0px 0.8rem !important;
  }
  & .MuiOutlinedInput-input {
    padding: 0px !important;
  }
  & .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }

  & .MuiInput-root:before {
    border: none !important;
  }
  & .MuiInput-root:after {
    border: none !important;
  }
`;

export const StyledBookingDocketWrapper = styled(motion.div)<{
  $themeColor: DoxleThemeColor;
}>`
  width: calc(100% - 1.6rem);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.8rem;
  height: 2rem;
  border-bottom: 1px solid ${(p) => p.$themeColor.primaryDividerColor};
  cursor: pointer;
  transition: 0.4s;
  &:hover {
    transition: 0.4s;
    background-color: ${(p) => p.$themeColor.rowHoverColor};
  }
`;

export const StyledDocketListHeader = styled(motion.div)`
  width: 100%;
`;

export const StyledBookingDocketNameText = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
  $selected: boolean;
}>`
  font-family: ${(p) => p.$doxleFont.primaryFont};
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;
  color: ${(p) =>
    p.$selected ? p.$themeColor.doxleColor : p.$themeColor.primaryFontColor};
  margin-left: 4px;
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const StyledBookingOrderNumberContainer = styled(motion.div)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  position: relative;
  padding: 0px 4px;
`;

export const StyledOrderInfoWrapper = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 1;
  background-color: ${(p) => p.$themeColor.primaryBackgroundColor};
  bottom: 0px;
  left: 4px;
  transform: translate(-33%, 33%);
  .order-number-text {
    font-family: ${(p) => p.$doxleFont.plexSanCondensed};
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    color: ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.$themeColor.primaryFontColor,
        alpha: "0.7",
      })};
    margin-bottom: 2px;
  }

  .order-signed-text {
    color: #f90;

    font-family: ${(p) => p.$doxleFont.sarina};
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    letter-spacing: -0.064rem;
  }
`;

export const StyledAddBookingLoaderContainer = styled(motion.div)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${(p) =>
    editRgbaAlpha({
      rgbaColor: p.$themeColor.primaryBackgroundColor,
      alpha: "0.9",
    })};
  .loading-text {
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-style: normal;
    font-weight: 500;
    font-size: 2rem;
    color: ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.$themeColor.doxleColor,
        alpha: "0.7",
      })};
    margin-top: 1.4rem;
  }
`;
