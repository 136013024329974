import React, { useContext, useMemo, useState } from "react";
import SelectRolePage from "../components/FirstLoginPage/SelectRolePage";
import DetailsFormPage from "../components/FirstLoginPage/DetailsFormPage";

export type TUserFormData = {
    role: string;
    name: string;
    mobile: string;
    email: string;
    company: string;
    comment: string;
}

export const emptyUserData: TUserFormData = {role: '', name: '',mobile: '',email: '',company: '',comment: ''}

type TWelcomeContextValue = {
  stage: number;
  setStage: React.Dispatch<React.SetStateAction<number>>;
  userFormData: TUserFormData;
  setUserFormData: React.Dispatch<React.SetStateAction<TUserFormData>>
};

const SELECT_TYPE_STAGE = 1;
const FILL_FORM_STAGE = 2;

const UserFormContext = React.createContext<TWelcomeContextValue | null>(null);

export default function Welcome() {

  const [stage, setStage] = useState(SELECT_TYPE_STAGE);
  const [userFormData, setUserFormData] = useState<TUserFormData>(emptyUserData);

  const contextValue: TWelcomeContextValue = useMemo(
    () => ({
      stage,
      setStage,
      userFormData,
      setUserFormData
    }),
    [stage]
  );
 if(stage === FILL_FORM_STAGE){
    return (
        <UserFormContext.Provider value={contextValue}>
        <DetailsFormPage />
        </UserFormContext.Provider>
    )
  }

  return (
    <UserFormContext.Provider value={contextValue}>
    <SelectRolePage />
    </UserFormContext.Provider>
    )
}

export const useWelcomeContext = () =>
  useContext(UserFormContext) as TWelcomeContextValue;
