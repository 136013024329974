import { Box, Skeleton } from "@mui/material";
import { motion } from "framer-motion";
import styled from "styled-components";
import { DoxleThemeColor } from "../../DoxleGeneralStore/useDoxleThemeStore";
import { editRgbaAlpha } from "../../Utilities/FunctionUtilities";

// export const RootSkeletonList = styled(motion.div)`
//   width: calc(80% - 16px);
//   height: calc(100% - 16px);
//   display: flex;
//   flex-direction: column;
//   padding: 8px 8px;
//   justify-content: center;
//   align-items: center;
// `;
// export const StyledSkeletonRow = styled.div`
//   display: flex;
//   flex-direction: row;
//   justify-content: space-between;
//   align-items: center;
//   width: 100%;
//   height: 50px;
// `;
// export const StyledTopSkeletonSection = styled.div`
//   width: 100%;
//   height: 30%;
//   display: flex;
//   flex-direction: column;
//   justify-content: flex-end;
// `;
// export const StyledBottomSkeletonSection = styled.div`
//   width: 100%;
//   height: 60%;
//   margin-top: 10%;
//   display: flex;
//   flex-direction: row;
//   justify-content: space-between;
// `;
// export const RootImageSkeleton = styled.div<{ sizeInPixel: `${number}px` }>`
//   width: ${(p) => p.sizeInPixel};
//   height: ${(p) => p.sizeInPixel};
//   display: flex;
//   flex-direction: column;
// `;

export const StyledSkeletonBox = styled(Box)<{ $themeColor: DoxleThemeColor }>`
  display: flex;
  flex-direction: row;

  align-items: center;

  width: calc(100% - 2rem);
  padding: 1rem;
  margin-bottom: 1.4rem;

  .history-skel-wrapper {
    width: 40%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .right-skel-section {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    padding: 30px;
    margin-left: 40px;
  }
`;
export const RootDrawingSkeleton = styled(motion.div)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const DrawingSkeleton = styled(Skeleton)`
  &.MuiSkeleton-root {
    display: flex;
  }
`;
