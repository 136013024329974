import { motion } from "framer-motion";
import React from "react";
import styled from "styled-components";
import { useLottie } from "lottie-react";
import animationData from "./lotties/deleteItem.json";
import {
  DoxleThemeColor,
  useDoxleThemeStore,
} from "../../DoxleGeneralStore/useDoxleThemeStore";
interface Props {
  loadingMessage?: string;
  animationSize?: number;
  hasBackdrop?: boolean;
  containerStyle?: React.CSSProperties;
}
const StyledLottiesView = styled(motion.div)`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
`;
const StyledLoadingMessage = styled.span`
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.05em;

  color: #000000;
  position: absolute;

  left: 0;
  right: 0;
  text-align: center;
`;
const ScreenContainer = styled.div<{
  $themeColor: DoxleThemeColor;
  $hasBackdrop: boolean;
}>`
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  background-color: ${(p) =>
    p.$hasBackdrop ? p.$themeColor.primaryBackdropColor : "transparent"};
  z-index: 10;
  flex-direction: column;
`;

const DeleteItemLoadingScreen = ({
  loadingMessage,
  animationSize,
  containerStyle,
  hasBackdrop = false,
}: Props) => {
  const { doxleThemeColor } = useDoxleThemeStore((state) => ({
    doxleThemeColor: state.doxleThemeColor,
    doxleFont: state.doxleFont,
  }));
  const options = {
    animationData: animationData,
    loop: true,
    autoplay: true,
  };

  const { View } = useLottie(options, {
    width: animationSize ? `${animationSize / 10}rem` : "20rem",
    // height: animationSize || 200,
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
  });
  //################## ANIMATION HANDILNG ########
  const lottieViewAnimatedVariants = {
    initial: {
      scale: 1,
    },
    animate: {
      scale: [0.9, 1, 0.9],
    },
  };
  return (
    <ScreenContainer
      $themeColor={doxleThemeColor}
      $hasBackdrop={hasBackdrop}
      style={{ ...containerStyle }}
    >
      <StyledLottiesView
        variants={lottieViewAnimatedVariants}
        initial="initial"
        animate="animate"
        transition={{ repeat: Infinity, repeatType: "loop", duration: 1 }}
      >
        {View}
      </StyledLottiesView>
      {loadingMessage && (
        <StyledLoadingMessage
          style={{
            top: animationSize
              ? `calc(50% + ${14 + (1 / 2) * animationSize})`
              : `calc(50% + 114px)`,
          }}
        >
          {loadingMessage}
        </StyledLoadingMessage>
      )}
    </ScreenContainer>
  );
};

export default DeleteItemLoadingScreen;
