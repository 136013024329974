import React, { useEffect, useMemo, useState } from "react";
import useDoxleNotificationStore from "../../../../../DoxleGeneralStore/useDoxleNotificationStore";
import { shallow } from "zustand/shallow";
import { useDoxleAuthStore } from "../../../../../DoxleGeneralStore/useDoxleAuthStore";
import { useDoxleCurrentContextStore } from "../../../../../DoxleGeneralStore/useDoxleCurrentContext";
import QAQueryAPI from "../../../../QueryAPI/qaQueryAPI";
import { useProjectQAStore } from "../../../Store/useProjectQAStore";
import { Contact } from "../../../../../Models/addressBook";

type Props = {};

interface QASharePdf {
  pdfPath: string | undefined;
  isGeneratingPdf: boolean;
  pageNumber: number;
  setPageNumber: React.Dispatch<React.SetStateAction<number>>;
  onDocumentLoadSuccess: ({ numPages }: { numPages: number }) => void;
  numPages: number;
  handleGenerateAssigneePdf: (assignee?: Contact | undefined) => void;
  isGeneratingAssigneePdf: boolean;
}
const useQASharePdf = (props: Props): QASharePdf => {
  const [numPages, setNumPages] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pdfPath, setPdfPath] = useState<string | undefined>(undefined);
  const { sharedQAList, setSharedQAList } = useProjectQAStore(
    (state) => ({
      sharedQAList: state.sharedQAList,
      setSharedQAList: state.setSharedQAList,
    }),
    shallow
  );
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );

  const { company, currentProject } = useDoxleCurrentContextStore(
    (state) => ({
      currentProject: state.currentProject,
      company: state.currentCompany,
    }),
    shallow
  );
  const onSuccessGeneratePdf = (path?: string) => {
    if (path) setPdfPath(path);
  };
  const generatePdfQuery = QAQueryAPI.useGenerateDefectListPdfQuery({
    company,
    showNotification,
    defectListId: sharedQAList?.defectListId ?? "",
    toggleEnableValue: Boolean(sharedQAList),
    onSuccessCb: onSuccessGeneratePdf,
  });

  const onSuccessGenerateAssigneePdf = (url: string) => {
    setPdfPath(url);
  };
  const sharePdfQuery = QAQueryAPI.useCreateQAPdfForAssigneeQuery({
    company,
    showNotification,
    onSuccessCb: onSuccessGenerateAssigneePdf,
  });

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }): void => {
    setNumPages(numPages);
  };
  const handleGenerateAssigneePdf = (assignee?: Contact | undefined) => {
    setNumPages(0);
    setPageNumber(1);
    if (sharedQAList)
      sharePdfQuery.mutate({
        qaListId: sharedQAList?.defectListId,
        assigneeId: assignee ? assignee.contactId : null,
      });
  };

  useEffect(() => {
    setNumPages(0);
    setPageNumber(1);
  }, [sharedQAList]);

  return {
    pdfPath,
    isGeneratingPdf: generatePdfQuery.isLoading,
    pageNumber,
    setPageNumber,
    onDocumentLoadSuccess,
    numPages,
    handleGenerateAssigneePdf,
    isGeneratingAssigneePdf: sharePdfQuery.isLoading,
  };
};

export default useQASharePdf;
