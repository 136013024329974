import React, { useCallback, useMemo, useRef } from "react";
import { Order } from "../../../Models/orders";
import useBookingDocketComment from "../BookingDocketDetail/Hooks/useBookingDocketComment";
import {
  Components,
  ItemContent,
  ItemProps,
  Virtuoso,
  VirtuosoHandle,
} from "react-virtuoso";
import { IBooking } from "../../../Models/booking";
import { Discussion } from "../../../../Models/discussion";
import useBookingViewStore, {
  DocketInfoMenuValue,
} from "../../../store/useBookingViewStore";
import { shallow } from "zustand/shallow";
import DiscussionItem from "../BookingDocketDetail/DiscussionItem";
import BookingDocketOrder from "../BookingDocketDetail/BookingDocketOrder";
import { StyledVirtuosoListScroller } from "../../../CommonComponents/StyledComponentBookings";
import {
  StyledDiscussionListHeader,
  StyledDiscussionRowWrapper,
} from "../BookingDocketDetail/StyledComponentBookingDocketDetail";
import DoxleEmptyPlaceHolder from "../../../../DoxleDesignPattern/DoxleEmptyPlaceHolder/DoxleEmptyPlaceHolder";
import { AnimatePresence } from "framer-motion";
import CircularProgress from "@mui/material/CircularProgress";
import { useDoxleThemeStore } from "../../../../DoxleGeneralStore/useDoxleThemeStore";
import EditBookingInputSection from "./EditBookingInputSection";
import BookingDocketMenu from "../BookingDocketMenu";
import { EmptyResponseBanner } from "../../../../Quotes/Content/QuotesIcon";
import { Error404Banner } from "../../../../DoxleDesignPattern/DoxleIcons/CommonIcons";
import CommentInputSection from "../../../BookingDocket/Components/BookingDocketDetail/CommentInputSection";

type Props = {
  edittedBooking: IBooking;
};

interface ListContext {
  currentMenuTab: DocketInfoMenuValue;
  docketId: string;
  isErrorFetchingDiscussion: boolean;
  isFetchingNextPage: boolean;
}
const EditBookingDocketPortrait = ({ edittedBooking }: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
      currentTheme: state.currentTheme,
    }),
    shallow
  );
  const { currentMenuTab, setCurrentMenuTab } = useBookingViewStore(
    (state) => ({
      setCurrentMenuTab: state.setCurrentMenuTab,
      currentMenuTab: state.currentMenuTab,
    }),
    shallow
  );
  const {
    discussionList,
    isFetchingDiscussion,
    isSuccessFetchingDiscussion,
    isErrorFetchingDiscussion,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    currentVisibleRange,
    setCurrentVisibleRange,
    shouldShowIconScrollToBottom,
    filterDiscussionList,
    totalItemCount,
  } = useBookingDocketComment({ docketId: edittedBooking.docket });
  const commentListRef = useRef<VirtuosoHandle>(null);

  const handleScrollToBottom = () => {
    commentListRef.current?.scrollToIndex({
      index: discussionList.length - 1,
      behavior: "smooth",
    });
  };
  //* render list
  const itemContent: ItemContent<Discussion | string, ListContext> =
    useCallback(
      (index, item, context) =>
        context.currentMenuTab === "Comment" ? (
          <DiscussionItem
            discussionItem={item as Discussion}
            key={`discussion#${(item as Discussion).commentId}`}
          />
        ) : (
          <BookingDocketOrder docketId={context.docketId} mode={"edit"} />
        ),
      []
    );
  const components: Components<Discussion | string, ListContext> = useMemo(
    () => ({
      Scroller: React.forwardRef((props, ref) => (
        <StyledVirtuosoListScroller ref={ref} {...props} />
      )),
      Item: React.forwardRef<HTMLDivElement, ItemProps<Discussion | string>>(
        (props, ref) =>
          (props.item as Discussion).commentId !== undefined ? (
            <StyledDiscussionRowWrapper
              {...props}
              ref={ref}
              key={(props.item as Discussion).commentId}
            />
          ) : (
            <div {...props} ref={ref} key={"orderItem"}></div>
          )
      ),
      EmptyPlaceholder: (props) =>
        props.context?.isErrorFetchingDiscussion ? (
          <DoxleEmptyPlaceHolder
            headTitleText="Something wrong!"
            subTitleText="Failed to get comments..."
            containerStyle={{
              height: "fit-content",
              marginTop: 50,
            }}
            illustration={
              <Error404Banner
                themeColor={doxleThemeColor}
                containerStyle={{ width: "30%" }}
              />
            }
            containerBgColor="transparent"
            headTitleTextStyle={{ fontSize: "3rem" }}
            subTitleTextStyle={{ fontSize: "1.8rem" }}
          />
        ) : (
          <DoxleEmptyPlaceHolder
            headTitleText="No comments"
            subTitleText="Add your comments to communicate with your colleagues..."
            illustration={<EmptyResponseBanner themeColor={doxleThemeColor} />}
            containerBgColor="transparent"
            headTitleTextStyle={{ fontSize: "3rem" }}
            subTitleTextStyle={{ fontSize: "1.8rem" }}
            containerStyle={{
              height: "fit-content",
              marginTop: 50,
            }}
          />
        ),
      Header: React.forwardRef((props, ref) => (
        <div
          style={{
            width: "100%",
            height: "auto",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <EditBookingInputSection />
          <BookingDocketMenu />
          <AnimatePresence>
            {props.context?.isFetchingNextPage && (
              <StyledDiscussionListHeader
                initial={false}
                animate={{
                  y: [-10, 0],
                  opacity: [0, 1],
                }}
                exit={{
                  y: [0, -10],
                  opacity: [1, 0],
                }}
                transition={{
                  duration: 0.2,
                }}
              >
                <CircularProgress
                  size="1.4rem"
                  style={{
                    color: doxleThemeColor.primaryFontColor,

                    alignSelf: "center",
                    justifySelf: "center",
                  }}
                />
              </StyledDiscussionListHeader>
            )}
          </AnimatePresence>
        </div>
      )),
    }),
    []
  );
  const listStyle: React.CSSProperties = {
    flex: 1,
    width: "100%",
  };
  return (
    <div style={{height: "100%", display:"flex", flexDirection:"column"}}>
      <Virtuoso
        style={listStyle}
        data={currentMenuTab === "Comment" ? discussionList : ["order"]}
        itemContent={itemContent}
        components={components}
        ref={commentListRef}
        atTopStateChange={fetchNextPage}
        context={{
          isFetchingNextPage,
          isErrorFetchingDiscussion,
          docketId: edittedBooking.docket,
          currentMenuTab,
        }}
        atTopThreshold={0.4}
        rangeChanged={setCurrentVisibleRange}
      />
      {currentMenuTab === "Comment" && (
        <CommentInputSection
          commentListRef={commentListRef}
          discussionList={discussionList}
          filterDiscussionList={filterDiscussionList}
        />
      )}
    </div>
  );
};

export default EditBookingDocketPortrait;
