import React, { useCallback, useMemo } from "react";
import {
  StyledQRUserList,
  StyledSearchUserTextfield,
  StyledSearchUserWrapper,
  StyledUserListScroller,
} from "./StyledQRCommonComponents";
import useQRUserList from "./Hooks/useQRUserList";
import useThrottlingSearch from "../../../CoreContent/UtilityHooks/useThrottlingSearch";
import useGetUserList from "../../../CoreContent/QueryDataHooks/GetQueryDataHooks/useGetUserList";
import { Components, ItemContent, Virtuoso } from "react-virtuoso";
import { User } from "../../../Models/user";
import DoxleEmptyPlaceHolder from "../../../DoxleDesignPattern/DoxleEmptyPlaceHolder/DoxleEmptyPlaceHolder";
import { Error404Banner } from "../../../DoxleDesignPattern/DoxleIcons/CommonIcons";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { DoxleEmptyUserListBanner } from "../../../DoxleDesignPattern/DoxleGeneralIcons";
import QRUserItem from "./QRUserItem";

type Props = {
  selectedIds?: string[];
  onSelectUser: (user: User) => void;
};
interface UserListContext {
  isFetchingUserList: boolean;
  isErrorFetchingUserList: boolean;
}
const QRUserList = ({ selectedIds, onSelectUser }: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore((state) => ({
    doxleThemeColor: state.doxleThemeColor,
    doxleFont: state.doxleFont,
  }));
  const { searchText, setSearchText } = useQRUserList();
  const { searchThrottleValue } = useThrottlingSearch({
    controlledValue: searchText,
  });
  const {
    userList,
    isFetchingUserList,
    isErrorFetchingUserList,
    fetchNextPageUser,
    isFetchingNextPageUser,
    isRefetchingUserList,
    refetchUserList,
  } = useGetUserList({ searchText: searchThrottleValue });
  //* render list
  const components: Components<User, UserListContext> = useMemo(
    () => ({
      Scroller: React.forwardRef((props, ref) => (
        <StyledUserListScroller
          ref={ref}
          {...props}
          animate={{ y: [10, 0], opacity: [0, 1] }}
        />
      )),
      EmptyPlaceholder: (props) => (
        <DoxleEmptyPlaceHolder
          headTitleText={
            props.context?.isErrorFetchingUserList
              ? "Something wrong!"
              : `No contact found!`
          }
          subTitleText={
            props.context?.isErrorFetchingUserList
              ? "Failed to get users, we are sorry for this!"
              : "Add more users to your list..."
          }
          containerBgColor="transparent"
          headTitleTextStyle={{
            fontSize: "20px",
          }}
          subTitleTextStyle={{
            fontSize: "14px",
          }}
          illustration={
            props.context?.isErrorFetchingUserList ? (
              <Error404Banner
                themeColor={doxleThemeColor}
                containerStyle={{
                  width: "50%",
                  marginBottom: 14,
                }}
              />
            ) : (
              <DoxleEmptyUserListBanner
                themeColor={doxleThemeColor}
                containerStyle={{
                  width: "50%",
                  marginBottom: 14,
                }}
              />
            )
          }
        />
      ),
    }),
    [doxleThemeColor]
  );

  const itemContent: ItemContent<User, UserListContext> = useCallback(
    (_index, item, context) => (
      <QRUserItem
        item={item}
        selected={selectedIds ? selectedIds.includes(item.userId!) : false}
        onSelectUser={onSelectUser}
      />
    ),
    [selectedIds, onSelectUser]
  );

  const listStyle = {
    flex: 1,
    width: "100%",
  };
  return (
    <StyledQRUserList>
      <StyledSearchUserWrapper>
        <StyledSearchUserTextfield
          variant="standard"
          value={searchText}
          // autoFocus
          onChange={(e) => setSearchText(e.target.value)}
          placeholder="Search contact..."
        />
      </StyledSearchUserWrapper>

      <Virtuoso
        style={listStyle}
        itemContent={itemContent}
        data={userList}
        components={components}
        context={{
          isFetchingUserList,
          isErrorFetchingUserList,
        }}
        endReached={fetchNextPageUser}
        atBottomThreshold={40}
      />
    </StyledQRUserList>
  );
};

export default QRUserList;
