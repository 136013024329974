import React from "react";

import {
  StyledDrawingsTableHeaderContainer,
  StyledDrawingsHeaderCell,
  StyledDrawingsCheckBox,
} from "./DrawingsStyledComponent";
import { VirtuosoHandle } from "react-virtuoso";
import { DrawingSet } from "../Models/drawings";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import {
  DrawingsCheckBoxIcon,
  DrawingsCheckCheckBoxIcon,
} from "./DrawingsIcon";
import useSheetsHeader from "../Hooks/useSheetsHeader";

type Props = {};
interface SheetsHeader {
  headerName: "Check" | "Sheets" | "Title" | "History" | "MoreIcon";
  isDisplayed: Boolean;
}
export const SHEETS_HEADER_LIST: SheetsHeader[] = [
  {
    headerName: "Check",
    isDisplayed: true,
  },
  {
    headerName: "Sheets",
    isDisplayed: true,
  },
  {
    headerName: "Title",
    isDisplayed: true,
  },
  {
    headerName: "History",
    isDisplayed: true,
  },
  {
    headerName: "MoreIcon",
    isDisplayed: true,
  },
];
function SheetsHeader({}: Props) {
  const { THEME_COLOR, doxleFont } = useDoxleThemeStore(
    (state) => ({
      THEME_COLOR: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }),
    shallow
  );
  const { handleToggleCheckboxSelectAll, isCheckboxTotalChecked } =
    useSheetsHeader({});

  return (
    <StyledDrawingsTableHeaderContainer $themeColor={THEME_COLOR}>
      {SHEETS_HEADER_LIST.map(
        (header, index) =>
          header.isDisplayed && (
            <StyledDrawingsHeaderCell
              $doxleFont={doxleFont}
              $widthInRatio={
                header.headerName === "Sheets"
                  ? "30%"
                  : header.headerName === "Title"
                  ? "20%"
                  : header.headerName === "Check" ||
                    header.headerName === "MoreIcon"
                  ? "5%"
                  : null
              }
              sx={{
                minWidth: header.headerName === "Check" ? " 50px" : undefined,
                maxWidth:
                  header.headerName === "Sheets"
                    ? " 300px !important"
                    : undefined,
              }}
              $horizontalAlign={
                header.headerName === "MoreIcon" ? "flex-end" : "center"
              }
              $themeColor={THEME_COLOR}
              key={`header#${index}`}
            >
              <span className="header-text">
                {header.headerName !== "Check" &&
                  header.headerName !== "MoreIcon" &&
                  header.headerName}
              </span>

              {header.headerName === "MoreIcon" && (
                <div className="add-drawing-btn"></div>
              )}

              {header.headerName === "Check" && (
                <StyledDrawingsCheckBox
                  sx={{
                    zIndex: 0,
                  }}
                  disableRipple
                  icon={<DrawingsCheckBoxIcon />}
                  checkedIcon={<DrawingsCheckCheckBoxIcon />}
                  checked={isCheckboxTotalChecked}
                  // onClick={handleClick}
                  onChange={(event) => {
                    handleToggleCheckboxSelectAll(event.target.checked);
                  }}
                />
              )}
            </StyledDrawingsHeaderCell>
          )
      )}
    </StyledDrawingsTableHeaderContainer>
  );
}

export default SheetsHeader;
