import { getDocketDetailQKey } from "../../../Services/QueryHooks/docketQueryAPI";
import { useQueryClient } from "@tanstack/react-query";
import { Docket } from "../../../Models/dockets";
import { produce } from "immer";
import { DefiniteAxiosQueryData } from "../../../Models/axiosReturn";

type Props = {
  docketPk: string;
  overwrite?: boolean;
};

interface ISetDocketDetailQueryData {
  handleUpdateDocket: (data: Partial<Docket>) => void;
}
const useSetDocketDetailQueryData = ({
  docketPk,
  overwrite = true,
}: Props): ISetDocketDetailQueryData => {
  const qKey = getDocketDetailQKey(docketPk);
  const queryClient = useQueryClient();

  const handleUpdateDocket = (edittedItem: Partial<Docket>) => {
    const docketQueryData = queryClient.getQueryData(qKey);

    if (docketQueryData && overwrite) {
      queryClient.setQueryData<DefiniteAxiosQueryData<Docket>>(qKey, (old) => {
        if (old)
          return produce(old, (draftOld) => {
            if (draftOld.data) Object.assign(draftOld.data, edittedItem);
            return draftOld;
          });
        else return old;
      });
    } else queryClient.invalidateQueries(qKey);
  };
  return {
    handleUpdateDocket,
  };
};

export default useSetDocketDetailQueryData;
