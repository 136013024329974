import React, {
  useCallback,
  useRef,
  useState,
  MouseEvent,
  ChangeEvent,
  useMemo,
  useEffect,
} from "react";
import {
  StyledAddCompanyButton,
  StyledAddCompanyFormContainer,
  StyledAddCompanyImg,
  StyledAddCompanyScreenContainer,
  StyledAddCompanySubTitleText,
  StyledAddCompanyTitleText,
  StyledAddLogoButton,
  StyledCancelButton,
  StyledShowMoreButton,
} from "./StyledComponentAddCompanyScreen";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import AddCompanyImage from "../../Assets/Images/LightTheme/addCompanyImg.jpg";
import AddCompanyTextField from "./AddCompanyTextField";
import useDoxleNotificationStore from "../../DoxleGeneralStore/useDoxleNotificationStore";
import { useDoxleCurrentContextStore } from "../../DoxleGeneralStore/useDoxleCurrentContext";
import Loading from "../../Utilities/Lottie/Loading";
import DoxleEmptyPlaceHolder from "../../DoxleDesignPattern/DoxleEmptyPlaceHolder/DoxleEmptyPlaceHolder";
import AddCompanySplitTextField from "./AddCompanySplitTextField";
import CompanyQueryAPI from "../../Services/QueryHooks/companyQueryAPI";
import { Company } from "../../Models/company";
import { useNavigate } from "react-router-dom";
import { useIsMutating } from "@tanstack/react-query";
import ConfirmDeleteDialog from "./DeleteCompanyDialog";
import { AddCompanyBanner } from "./AddCompanyBanner";
import useGetCompanyList from "../QueryDataHooks/GetQueryDataHooks/useGetCompanyList";
import { useDoxleMainMenuStore } from "../../DoxleGeneralStore/useDoxleMainMenuStore";
type Props = {};
export interface AddCompanyInputValue {
  name: string;
  email: string;
  phone: string;
  abn: string;
  address_l1: string;
  address_l2: string;
  addressCity: string;
  addressState: string;
  addressPostCode: string;
  logo: File | null;
}

const AddCompanyScreen = (props: Props) => {
  const { currentCompany, setCurrentCompany, currentEditCompany } =
    useDoxleCurrentContextStore(
      (state) => ({
        setCurrentCompany: state.setCurrentCompany,
        currentCompany: state.currentCompany,
        currentEditCompany: state.currentEditCompany,
      }),
      shallow
    );

  const [companyDetail, setCompanyDetail] = useState<AddCompanyInputValue>({
    name: currentEditCompany?.name ?? "",
    email: currentEditCompany?.email ?? "",
    phone: currentEditCompany?.phone ?? "",
    abn: currentEditCompany?.abn ?? "",
    address_l1: currentEditCompany?.addressL1 ?? "",
    address_l2: currentEditCompany?.addressL2 ?? "",
    addressCity: currentEditCompany?.addressCity ?? "",
    addressState: currentEditCompany?.addressState ?? "",
    addressPostCode: currentEditCompany?.addressPostCode ?? "",
    logo: null,
  });

  const [errorNameField, setErrorNameField] = useState<string | undefined>(
    undefined
  );
  const [errorPhoneField, setErrorPhoneField] = useState<string | undefined>(
    undefined
  );
  const [errorEmailField, setErrorEmailField] = useState<string | undefined>(
    undefined
  );
  const [showMore, setShowMore] = useState<boolean>(false);
  const [errorABNField, setErrorABNField] = useState<string | undefined>(
    undefined
  );
  const [displayDeleteDialog, setDisplayDeleteDialog] =
    useState<boolean>(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }),
    shallow
  );
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );
  const { setShowSideMenu } = useDoxleMainMenuStore(
    (state) => ({
      setShowSideMenu: state.setShowSideMenu,
    }),
    shallow
  );

  const { companyList, isErrorFetchingCompanyList, isFetchingCompanyList } =
    useGetCompanyList({});
  const handleCancelAddCompany = () => {
    const localStorageCompany = localStorage.getItem("currentCompanyId");
    if (localStorageCompany)
      setCurrentCompany(
        companyList.filter(
          (company) => company.companyId === localStorageCompany
        )?.[0] ?? companyList?.[0]
      );
    else setCurrentCompany(companyList?.[0]);
    navigate(-1);
  };
  const titleText = currentEditCompany ? "Edit Company Details" : "New Company";
  const subTitleText = currentEditCompany
    ? "Edit yourcompany details to ensure they are correct on purchase orders and other documents being sent out."
    : "To start using Doxle, you need to create a new company. You can onboard your team by sending invitations. Further details can be added later in company settings.";
  const checkValidEmail = useCallback((value: string) => {
    const emailRegex = /.+@.+\..+/;
    return emailRegex.test(value);
  }, []);

  const checkValidABN = useCallback((value: string) => {
    if (value === "") return true;
    const intABN: number = parseInt(value.replaceAll(" ", ""));
    return !isNaN(intABN) && intABN > 1000000000 && intABN < 100000000000;
  }, []);

  const handleCloseToolTipEmail = useCallback(() => {
    setErrorEmailField(undefined);
  }, []);

  const handleCloseToolTipName = useCallback(() => {
    setErrorNameField(undefined);
  }, []);

  const handleCloseToolTipPhone = useCallback(() => {
    setErrorPhoneField(undefined);
  }, []);
  const handleCloseToolTipABN = useCallback(() => {
    setErrorABNField(undefined);
  }, []);

  const handleChangeEmail = useCallback((value: string) => {
    setCompanyDetail((prev) => ({ ...prev, email: value }));
  }, []);

  const handleChangeName = useCallback((value: string) => {
    setCompanyDetail((prev) => ({ ...prev, name: value }));
  }, []);

  const handleChangePhone = useCallback((value: string) => {
    setCompanyDetail((prev) => ({ ...prev, phone: value }));
  }, []);

  const handleChangeABN = useCallback((value: string) => {
    setCompanyDetail((prev) => ({ ...prev, abn: value }));
  }, []);

  const handleChangeAddressL1 = useCallback((value: string) => {
    setCompanyDetail((prev) => ({ ...prev, addressL1: value }));
  }, []);

  const handleChangeAddressL2 = useCallback((value: string) => {
    setCompanyDetail((prev) => ({ ...prev, addressL2: value }));
  }, []);

  const handleChangeCity = useCallback((value: string) => {
    setCompanyDetail((prev) => ({ ...prev, addressCity: value }));
  }, []);

  const handleChangeState = useCallback((value: string) => {
    setCompanyDetail((prev) => ({ ...prev, addressState: value }));
  }, []);
  const handleChangePostCode = useCallback((value: string) => {
    setCompanyDetail((prev) => ({ ...prev, addressPostCode: value }));
  }, []);

  useEffect(() => {
    handleChangeEmail(currentEditCompany?.email ?? "");
    handleChangeABN(currentEditCompany?.abn ?? "");
    handleChangeName(currentEditCompany?.name ?? "");
    handleChangeAddressL1(currentEditCompany?.addressL1 ?? "");
    handleChangeAddressL2(currentEditCompany?.addressL2 ?? "");
    handleChangeCity(currentEditCompany?.addressCity ?? "");
    handleChangePhone(currentEditCompany?.phone ?? "");
    handleChangePostCode(currentEditCompany?.addressPostCode ?? "");
    handleChangeState(currentEditCompany?.addressPostCode ?? "");
  }, [currentEditCompany]);

  const handleLogoButtonClicked = (e: MouseEvent) => {
    if (fileInputRef.current) fileInputRef.current.click();
  };

  const imgURL = useMemo(() => {
    if (companyDetail.logo) return URL.createObjectURL(companyDetail.logo);
    else return "";
  }, [companyDetail.logo]);

  const handleFileChanged = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setCompanyDetail((prevState) => ({
      ...prevState,
      logo: e.target?.files?.[0] ?? null,
    }));
  }, []);

  const mutating = Boolean(
    useIsMutating({
      mutationKey: ["mutate-company"],
      exact: true,
    })
  );
  const navigate = useNavigate();
  const onCompanyDeleted = () => {
    setDisplayDeleteDialog(false);
    setShowSideMenu(false);
    setCurrentCompany(
      companyList.filter(
        (company) => company.companyId !== currentCompany?.companyId
      )?.[0]
    );
    navigate("/");
  };
  const onNewCompanySaved = (newCompany: Company) => {
    setCurrentCompany(newCompany);
    navigate("/AddProject");
    console.log("TRIGGER NAV ADD PROJ");
  };
  const onSuccessEditCompany = (newCompany: Company) => {
    setCurrentCompany(newCompany);
    navigate("/project/");
  };

  const deleteCompanyQuery = CompanyQueryAPI.useDeleteCompany(onCompanyDeleted);

  const addCompanyQuery = CompanyQueryAPI.useAddCompany(onNewCompanySaved);
  const editCompanyQuery =
    CompanyQueryAPI.usePatchCompany(onSuccessEditCompany);
  const handleDeleteConfirmed = (deleteCompanyId: string) => {
    deleteCompanyQuery.mutate(deleteCompanyId);

  };
  const handleClickSubmitButton = useCallback(async () => {
    try {
      console.log("TRIGGER");
      if (!companyDetail.email)
        setErrorEmailField("Please enter your company email");
      else if (!checkValidEmail(companyDetail.email))
        setErrorEmailField("Please enter a valid email");
      if (!companyDetail.name)
        setErrorNameField("Please enter your company name");
      if (!checkValidABN(companyDetail.abn))
        setErrorABNField("Please enter a valid ABN");
      if (!companyDetail.phone)
        setErrorPhoneField("Please enter a company phone number");
      if (
        companyDetail.email &&
        companyDetail.name &&
        checkValidEmail(companyDetail.email) &&
        checkValidABN(companyDetail.abn)
      ) {
        currentEditCompany
          ? editCompanyQuery.mutate(companyDetail)
          : addCompanyQuery.mutate(companyDetail);
      }
    } catch (error) {
      showNotification("handleClickSubmitButton Error", "error", "", 1500);
    }
  }, [companyDetail, checkValidEmail, checkValidABN, currentEditCompany]);

  if (isFetchingCompanyList || mutating) return <Loading />;

  if (isErrorFetchingCompanyList)
    return (
      <DoxleEmptyPlaceHolder
        headTitleText="Something Wrong!!!"
        subTitleText="Unable To Find Company, Please Try To Reload Page..."
      />
    );
  return (
    <StyledAddCompanyScreenContainer>
      {displayDeleteDialog && (
        <ConfirmDeleteDialog
          deleteType={"Company"}
          deleteItemName={currentEditCompany?.name ?? ""}
          deleteItemId={currentEditCompany?.companyId ?? ""}
          deleteFunction={handleDeleteConfirmed}
          closeDialog={() => setDisplayDeleteDialog(false)}
        />
      )}
      <StyledAddCompanyTitleText
        $themeColor={doxleThemeColor}
        $doxleFont={doxleFont}
      >
        {titleText}
      </StyledAddCompanyTitleText>
      <StyledAddCompanySubTitleText
        $themeColor={doxleThemeColor}
        $doxleFont={doxleFont}
      >
        {subTitleText}
      </StyledAddCompanySubTitleText>
      <StyledAddCompanyFormContainer
        $themeColor={doxleThemeColor}
        $doxleFont={doxleFont}
        $height={showMore ? 450 : 150}
      >
        <AddCompanyTextField
          setCurrentValue={handleChangeName}
          placeholderText="Company name"
          hasBorderBottom={true}
          heightRatio={`${showMore ? 450 / 8 : 100 / 3}%`}
          errorToggle={Boolean(errorNameField !== undefined)}
          errorText={errorNameField || ""}
          handleCloseToolTip={handleCloseToolTipName}
          maxLength={511}
          initialValue={companyDetail.name}
        />
        <AddCompanyTextField
          setCurrentValue={handleChangeEmail}
          placeholderText="Email"
          hasBorderBottom={true}
          heightRatio={`${showMore ? 450 / 9 : 100 / 3}%`}
          errorToggle={Boolean(errorEmailField !== undefined)}
          errorText={errorEmailField || ""}
          handleCloseToolTip={handleCloseToolTipEmail}
          maxLength={511}
          initialValue={companyDetail.email}
        />
        <AddCompanyTextField
          setCurrentValue={handleChangePhone}
          placeholderText="Phone"
          hasBorderBottom={showMore}
          heightRatio={`${showMore ? 450 / 9 : 100 / 3}%`}
          errorToggle={Boolean(errorPhoneField !== undefined)}
          errorText={errorPhoneField || ""}
          handleCloseToolTip={handleCloseToolTipPhone}
          keyDownFunction={handleClickSubmitButton}
          maxLength={16}
          initialValue={companyDetail.phone}
        />
        {showMore && (
          <>
            <AddCompanyTextField
              setCurrentValue={handleChangeABN}
              placeholderText="ABN"
              hasBorderBottom={true}
              heightRatio={`${showMore ? 450 / 9 : 100 / 3}%`}
              errorToggle={Boolean(errorABNField !== undefined)}
              errorText={errorABNField || ""}
              handleCloseToolTip={handleCloseToolTipABN}
              keyDownFunction={handleClickSubmitButton}
              maxLength={16}
              initialValue={companyDetail.abn}
            />
            <AddCompanyTextField
              setCurrentValue={handleChangeAddressL1}
              placeholderText="Address Line 1"
              hasBorderBottom={true}
              heightRatio={`${showMore ? 450 / 9 : 100 / 3}%`}
              errorToggle={false}
              errorText={""}
              handleCloseToolTip={() => {}}
              keyDownFunction={handleClickSubmitButton}
              maxLength={511}
              initialValue={companyDetail.address_l1}
            />
            <AddCompanyTextField
              setCurrentValue={handleChangeAddressL2}
              placeholderText="Address Line 2"
              hasBorderBottom={true}
              heightRatio={`${showMore ? 450 / 9 : 100 / 3}%`}
              errorToggle={false}
              errorText={""}
              handleCloseToolTip={() => {}}
              keyDownFunction={handleClickSubmitButton}
              maxLength={511}
              initialValue={companyDetail.address_l2}
            />
            <AddCompanyTextField
              setCurrentValue={handleChangeCity}
              placeholderText="City"
              hasBorderBottom={true}
              heightRatio={`${showMore ? 450 / 9 : 100 / 3}%`}
              errorToggle={false}
              errorText={""}
              handleCloseToolTip={() => {}}
              keyDownFunction={handleClickSubmitButton}
              maxLength={511}
              initialValue={companyDetail.addressCity}
            />
            <AddCompanySplitTextField
              setCurrentValue={[handleChangeState, handleChangePostCode]}
              placeholderText={["State", "Post Code"]}
              maxLengths={[511, 5]}
              hasBorderBottom={true}
              heightRatio={`${showMore ? 450 / 9 : 100 / 3}%`}
              errorToggle={false}
              errorText={""}
              handleCloseToolTip={() => {}}
              keyDownFunction={handleClickSubmitButton}
              initialValues={[
                companyDetail.addressState,
                companyDetail.addressPostCode,
              ]}
            />
            <input
              type={"file"}
              style={{ display: "none" }}
              ref={fileInputRef}
              accept="image/*"
              onChange={handleFileChanged}
            />
            <StyledAddLogoButton
              $themeColor={doxleThemeColor}
              $doxleFont={doxleFont}
              onClick={handleLogoButtonClicked}
              // $heightRatio={`${showMore ? (450 / 9) : (100 / 3)}%`}
            >
              {imgURL || currentCompany?.logo ? "Change Logo" : "Upload a logo"}
              &nbsp;
              {imgURL && <img src={imgURL} alt={"uploaded_logo"} height={20} />}
              {!imgURL && currentCompany?.logo && (
                <img
                  src={currentCompany.logo}
                  alt={"uploaded_logo"}
                  height={20}
                />
              )}
            </StyledAddLogoButton>
          </>
        )}
      </StyledAddCompanyFormContainer>
      <StyledShowMoreButton
        $themeColor={doxleThemeColor}
        $doxleFont={doxleFont}
        onClick={() => setShowMore((prev) => !prev)}
      >
        {showMore ? "Hide optional fields..." : "Show optional fields..."}
      </StyledShowMoreButton>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        {currentEditCompany && (
          <>
            <StyledAddCompanyButton
              $themeColor={doxleThemeColor}
              $doxleFont={doxleFont}
              onClick={() => setDisplayDeleteDialog(true)}
              $delete
            >
              Delete Company
            </StyledAddCompanyButton>
            &nbsp;&nbsp;
          </>
        )}
        {Boolean(!currentEditCompany && companyList.length) && (
          <>
            <StyledCancelButton
              $themeColor={doxleThemeColor}
              $doxleFont={doxleFont}
              onClick={handleCancelAddCompany}
            >
              Cancel
            </StyledCancelButton>
            &nbsp;&nbsp;
          </>
        )}
        <StyledAddCompanyButton
          $themeColor={doxleThemeColor}
          $doxleFont={doxleFont}
          onClick={handleClickSubmitButton}
        >
          Save
        </StyledAddCompanyButton>
      </div>
      <AddCompanyBanner themeColor={doxleThemeColor} />
    </StyledAddCompanyScreenContainer>
  );
};

export default AddCompanyScreen;
