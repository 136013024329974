import styled from "styled-components";
import {
  DoxleFont,
  DoxleThemeColor,
} from "../../DoxleGeneralStore/useDoxleThemeStore";
import { motion } from "framer-motion";
import { editRgbaAlpha } from "../../Utilities/FunctionUtilities";
import { Paper, TextField } from "@mui/material";
import { DesktopDatePicker, DesktopDatePickerProps } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";

export const StyledAddProjectScreenContainer = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  width: calc(100% - 3rem);
  height: calc(100% - 3rem);
  border: 1.5rem solid ${(p) => p.$themeColor.primaryContainerColor};

  overflow: hidden;
  background-color: ${(p) => p.$themeColor.primaryBackgroundColor};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  .content-wrapper {
    width: calc(100% - 6rem);
    flex: 1;
    padding: 15px 3rem;
    max-width: 1280px;
    min-width: 500px;
    min-height: 850px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
  .bottom-navigate {
    width: calc(100% - 28px);
    height: 5rem;
    padding: 0px 14px;
    align-items: center;
    display: flex;
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.$themeColor.primaryFontColor,
        alpha: "0.5",
      })};
    background-color: ${(p) => p.$themeColor.primaryContainerColor};
  }
`;
export const StyledHeaderImage = styled(motion.img)`
  width: 50%;
  max-width: 512px;
  min-width: 200px;
  aspect-ratio: ${512 / 275};
`;

export const StyledAddProjectHeadTitleText = styled(motion.span)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  color: ${(p) => p.$themeColor.primaryFontColor};
  font-family: ${(p) => p.$doxleFont.primaryFont};
  font-size: clamp(45px, 5rem, 55px);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 14px;
`;
export const StyledAddProjectContent = styled(motion.div)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 1.4rem;
  .row-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-bottom: 2rem;
  }
  .note-text {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    color: ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.$themeColor.primaryFontColor,
        alpha: "0.7",
      })};
    font-family: ${(p) => p.$doxleFont.secondaryTitleFont};
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`;

export const StyledAddProjectTextFieldContainer = styled.div<{
  $widthRatio: `${number}%`;

  $doxleFont: DoxleFont;
  $isFocused: boolean;
}>`
  width: ${(p) => p.$widthRatio};
  display: flex;
  flex-direction: column;

  .textfield-label {
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
    margin-bottom: 4px;
  }
  .error-text {
    margin-top: 4px;
    color: red;
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`;

export const StyledAddProjectTextField = styled(TextField)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  && {
    width: calc(100% - 2px);
    height: clamp(30px, 3rem, 35px);
    border-radius: 0px 4px 4px 4px;
    background-color: ${(p) => p.$themeColor.primaryContainerColor};
    border: 1px solid ${(p) => p.$themeColor.rowBorderColor};
  }
  input {
    padding: 0px 4px !important;
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.28px;
    color: ${(p) => p.$themeColor.primaryFontColor};

    &::placeholder {
      font-family: ${(p) => p.$doxleFont.primaryFont};
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.28px;
      color: ${(p) =>
        editRgbaAlpha({
          rgbaColor: p.$themeColor.primaryFontColor,
          alpha: "0.5",
        })};
    }
  }
  & .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }
  & .MuiInputBase-root {
    height: 100%;

    &.MuiInput-root:hover:not(.Mui-disabled, .Mui-error):before {
      border: none !important;
    }
    &.MuiInput-root:before {
      border: none !important;
    }
    &.MuiInput-root:after {
      border: none !important;
    }
  }
  & .MuiInputBase-input {
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
export const StyledAddProjectDatePickerContainer = styled.div<{
  $doxleFont: DoxleFont;
  $themeColor: DoxleThemeColor;
}>`
  width: 25%;
  display: flex;
  flex-direction: column;

  .textfield-label {
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
    margin-bottom: 4px;
  }
  .date-displayer {
    width: 100%;
    height: 3rem;
    border-radius: 0px 4px 4px 4px;
    background-color: ${(p) => p.$themeColor.primaryContainerColor};
    border: 1px solid ${(p) => p.$themeColor.rowBorderColor};
    display: flex;
    flex-direction: row;
  }
`;

export const StyledAddProjectDateTextField = styled(TextField)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  && {
    width: calc(100% - 2px);
    height: 3rem;
    border-radius: 0px 4px 4px 4px;
    background-color: ${(p) => p.$themeColor.primaryContainerColor};
    border: 1px solid ${(p) => p.$themeColor.rowBorderColor};
  }
  input {
    padding: 0px 4px !important;
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    color: ${(p) => p.$themeColor.primaryFontColor};

    &::placeholder {
      font-family: ${(p) => p.$doxleFont.primaryFont};
      font-size: 1.4rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      color: ${(p) =>
        editRgbaAlpha({
          rgbaColor: p.$themeColor.primaryFontColor,
          alpha: "0.5",
        })};
    }
  }
  & .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }
  & .MuiInputBase-root {
    height: 100%;
  }
`;

export const StyledAddProjectStatusContainer = styled.div<{
  $doxleFont: DoxleFont;
}>`
  width: 30%;
  margin-left: 10%;
  display: flex;
  flex-direction: column;

  .textfield-label {
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
    margin-bottom: 4px;
  }
  .error-text {
    margin-top: 4px;
    color: red;
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`;
export const StyledAddProjectStatusDisplay = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
  $isNull: boolean;
}>`
  width: calc(100% - 18px);
  height: 3rem;
  border-radius: 0px 4px 4px 4px;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0px 8px;
  background-color: ${(p) => p.$themeColor.primaryContainerColor};
  border: 1px solid ${(p) => p.$themeColor.rowBorderColor};
  font-family: ${(p) => p.$doxleFont.primaryFont};
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.28px;
  color: ${(p) =>
    p.$isNull
      ? editRgbaAlpha({
          rgbaColor: p.$themeColor.primaryFontColor,
          alpha: "0.2",
        })
      : p.$themeColor.primaryFontColor};
`;
export const StyledProjectStatusListContainer = styled(motion(Paper))<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  && {
    width: 30vw;
    max-width: 20rem;
    min-width: 10rem;
    height: 20vh;
    min-height: 18rem;
    max-height: 30rem;
    background-color: ${(p) => p.$themeColor.primaryContainerColor};
    display: flex;
    flex-direction: column;
    border-radius: 0px 9px 9px 9px !important;
    overflow: hidden;

    .status-list-header {
      padding: 8px;
      background-color: ${(p) => p.$themeColor.doxleColor};
      border-radius: 0px 4px 4px 4px;
      font-family: ${(p) => p.$doxleFont.primaryFont};
      font-size: 1.4rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      color: white;
      text-transform: capitalize;
    }
    .list-content {
      flex: 1;
      display: flex;
      width: 100%;
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
  }
`;
export const StyledProjectStatusItemContainer = styled(motion.div)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
  $selected: boolean;
}>`
  width: calc(100% - 16px);
  height: 3rem;
  font-family: ${(p) => p.$doxleFont.primaryFont};
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: ${(p) =>
    p.$selected
      ? p.$themeColor.doxleColor
      : editRgbaAlpha({
          rgbaColor: p.$themeColor.primaryFontColor,
          alpha: "0.5",
        })};

  border-bottom: 1px solid ${(p) => p.$themeColor.primaryDividerColor};
  text-transform: capitalize;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0px 8px;
  transition: 0.4s;
  &:hover {
    background-color: ${(p) => p.$themeColor.rowHoverColor};
    transition: 0.4s;
    color: ${(p) => p.$themeColor.primaryFontColor};
  }
`;

export const StyledAddProjectUserContainer = styled.div<{
  $doxleFont: DoxleFont;
}>`
  width: 70%;

  display: flex;
  flex-direction: column;

  .textfield-label {
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
    margin-bottom: 4px;
  }
`;
export const StyledAddProjectUserDisplay = styled(motion.div)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  width: calc(100% - 18px);
  min-height: calc(3rem - 16px);
  border-radius: 0px 4px 4px 4px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  cursor: pointer;
  padding: 8px;
  background-color: ${(p) => p.$themeColor.primaryContainerColor};
  border: 1px solid ${(p) => p.$themeColor.rowBorderColor};
  font-family: ${(p) => p.$doxleFont.primaryFont};
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.28px;
  color: ${(p) =>
    editRgbaAlpha({
      rgbaColor: p.$themeColor.primaryFontColor,
      alpha: "0.2",
    })};
`;

export const StyledUserListContainer = styled(motion(Paper))<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  && {
    margin-top: 4px;
    width: 30vw;
    max-width: 30rem;
    min-width: 18rem;
    height: 30vh;
    min-height: 18rem;
    max-height: 40rem;
    background-color: ${(p) => p.$themeColor.primaryContainerColor};
    display: flex;
    flex-direction: column;
    border-radius: 0px 9px 9px 9px !important;
    overflow: hidden;

    .user-list-header {
      padding: 8px;
      background-color: ${(p) => p.$themeColor.doxleColor};
      border-radius: 0px 4px 4px 4px;
      font-family: ${(p) => p.$doxleFont.primaryFont};
      font-size: 1.4rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      color: white;
      text-transform: capitalize;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
    .list-content {
      flex: 1;
      display: flex;
      width: 100%;
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
  }
`;

export const StyledUserItemContainer = styled(motion.div)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
  $selected: boolean;
}>`
  width: calc(100% - 16px);
  height: 3rem;
  font-family: ${(p) => p.$doxleFont.primaryFont};
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: ${(p) =>
    p.$selected
      ? p.$themeColor.doxleColor
      : editRgbaAlpha({
          rgbaColor: p.$themeColor.primaryFontColor,
          alpha: "0.5",
        })};

  border-bottom: 1px solid ${(p) => p.$themeColor.primaryDividerColor};
  text-transform: capitalize;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0px 8px;
  transition: 0.4s;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  &:hover {
    background-color: ${(p) => p.$themeColor.rowHoverColor};
    transition: 0.4s;
    color: ${(p) => p.$themeColor.primaryFontColor};
  }
`;

export const StyledAddProjectXeroContainer = styled.div<{
  $doxleFont: DoxleFont;
}>`
  width: 70%;

  display: flex;
  flex-direction: column;

  .textfield-label {
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
    margin-bottom: 4px;
  }
`;
export const StyledAddProjectXeroDisplay = styled(motion.div)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  width: calc(100% - 18px);
  height: calc(3rem - 16px);
  border-radius: 0px 4px 4px 4px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  cursor: pointer;
  padding: 8px;
  background-color: ${(p) => p.$themeColor.primaryContainerColor};
  border: 1px solid ${(p) => p.$themeColor.rowBorderColor};
  font-family: ${(p) => p.$doxleFont.primaryFont};
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.28px;
  color: ${(p) =>
    editRgbaAlpha({
      rgbaColor: p.$themeColor.primaryFontColor,
      alpha: "0.2",
    })};
`;
export const StyledTrackingOptionItemContainer = styled(motion.div)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  width: calc(100% - 16px);
  height: 3rem;
  font-family: ${(p) => p.$doxleFont.primaryFont};
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  border-bottom: 1px solid ${(p) => p.$themeColor.primaryDividerColor};
  text-transform: capitalize;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0px 8px;
  transition: 0.4s;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-transform: capitalize;
`;

export const StyledAddProjectButtonSection = styled(motion.div)<{
  $doxleFont: DoxleFont;
}>`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  .menu-button {
    width: clamp(115px, 12rem, 130px);
    height: clamp(30px, 3rem, 32px);
    display: flex;
    border-radius: 13px;
    justify-content: center;
    align-items: center;
    transition: 0.4s;
    margin: 0px 8px;
    cursor: pointer;
    font-family: ${(p) => p.$doxleFont.primaryTitleFont};
    font-size: clamp(12.5px, 1.3rem, 13.5px);
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.06rem;
    text-transform: capitalize;
    transition: all 0.4s ease;
    &:hover {
      opacity: 0.7;
      transition: all 0.4s ease;
    }
    &:active {
      transform: scale(0.9);
      transition: all 0.4s ease;
    }
  }
`;

type IDesktopDatePickerCustom = DesktopDatePickerProps<Dayjs | null> &
  React.RefAttributes<HTMLDivElement> & {
    $themeColor: DoxleThemeColor;
    $doxleFont: DoxleFont;
  };
export const StyledAddProjectDatePicker = styled(
  ({ $themeColor, $doxleFont, ...props }: IDesktopDatePickerCustom) => (
    <DesktopDatePicker {...props} />
  )
)`
  && {
    & .MuiPickersPopper-paper {
      color: blue !important;
    }
  }
  &.MuiPickersPopper-paper {
    background-color: blue !important;
  }
`;
