import { Paper, PaperProps, TextField } from "@mui/material";
import { motion } from "framer-motion";
import styled from "styled-components";
import { editRgbaAlpha } from "../../Utilities/FunctionUtilities";
interface ITaskListContainerProps
  extends Omit<
    PaperProps,
    "onDragStart" | "onAnimationStart" | "onDragEnd" | "onDrag"
  > {
}
const AnimatedPaper = motion(Paper);
const statusListAnimatedVariants = {
  entering: {
    y: [-10, 0],
    opacity: [0, 1],
  },
  exiting: {
    y: [0, -10],
    opacity: [1, 0],
  },
};
export const StyledTaskPopover = styled(
  (props: ITaskListContainerProps) => (
    <AnimatedPaper
      {...props}
      variants={statusListAnimatedVariants}
      animate="entering"
      exit="exiting"
      initial="initial"
      transition={{
        duration: 0.1,
        damping: 16,
      }}
    />
  )
)`
  && {
    background-color: ${(p) => p.theme.color.primaryContainerColor};
    width: 250px;
    height: 300px;
    border-radius: 0px 9px 9px 9px;
    position: relative;
    padding: 1rem;
    box-shadow: 0px 0px 8px 0px ${(p) => p.theme.color.primaryBoxShadowColor};
    border: 1px solid ${(p) => p.theme.color.primaryDividerColor};
    display: flex;
    flex-direction: column;
  }
`;

export const StyledSearchTaskWrapper = styled.div`
  width: calc(100%);
  display: flex;
  align-items: center;
  padding: 4px 0px !important;
  margin-bottom: 4px;
  border-bottom: 1px solid ${(p) => p.theme.color.primaryDividerColor};

  .form-title {
    font-family: ${(p) => p.theme.font.primaryFont};
    font-size: max(1.8rem, 18px);
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    color: ${(p) => p.theme.color.primaryFontColor} !important;
    flex: 1;
  }
`;
export const StyledSearchTaskTextfield = styled(TextField)`
  && {
    overflow: hidden;
    flex: 1;

    height: max(2.7rem, 27px);
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  & .MuiInputBase-input.Mui-disabled {
    -webkit-text-fill-color: ${(p) => p.theme.color.primaryFontColor};
  }
  input {
    font-family: ${(p) => p.theme.font.primaryFont};
    font-weight: 500;
    font-size: max(1.3rem, 13px);
    line-height: normal;
    color: ${(p) => p.theme.color.primaryFontColor};
    padding: 0px !important;

    &::placeholder {
      font-family: ${(p) => p.theme.font.primaryFont};
      font-weight: 500;
      font-size: max(1.3rem, 13px);
      line-height: normal;
      padding: 0px !important;
      color: ${(p) =>
        editRgbaAlpha({
          rgbaColor: p.theme.color.primaryFontColor,
          alpha: "0.5",
        })};
    }
  }
  & .MuiInput-root:after {
    border-bottom: none !important;
  }
  & .MuiInput-root:before {
    border-bottom: none !important;
  }
  & .MuiInput-root {
    border-bottom: none !important;
  }
  & .MuiInput-root:hover:not(.Mui-disabled, .Mui-error):before {
    border-bottom: none !important;
  }
  & .MuiInputBase-root {
    width: 100%;
  }
`;
export const StyledTaskItem = styled(motion.div)`
  background-color: transparent;

  width: calc(100% - 16px);
  padding: 8px;
  border-bottom: 1px solid ${(p) => p.theme.color.primaryDividerColor};

  display: flex;
  align-items: center;
  color: ${(p) => p.theme.color.primaryFontColor};
  cursor: pointer;
  transition: 0.4s ease all;
  border-radius: 2px;
  &:hover {
    background-color: ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.theme.color.doxleColor,
        alpha: "0.2",
      })} !important;
    color: white !important;
    transition: 0.4s ease all;
    .remove-icon {
      color: white !important;
    }
  }

  .remove-icon {
    color: ${(p) => p.theme.color.primaryFontColor};
    cursor: pointer;
  }
  .info-wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;

    .task-name {
      flex-shrink: 1;
      white-space: pre-wrap;
      overflow: hidden;
      font-family: ${(p) => p.theme.font.primaryFont};
      font-weight: 500;
      font-size: 13px;
      line-height: normal;
      text-transform: capitalize;
    }
  }
`;
export const StyledTaskListScroller = styled(motion.div)`
  width: 100%;
  height: 100%;

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;
