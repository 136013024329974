import React from "react";
import { QAImage } from "../../../Models/qa";
import { useDoxleThemeStore } from "../../../../DoxleGeneralStore/useDoxleThemeStore";
import { StyledQAImageItemContainer } from "./StyledComponentQAItemDetail";
import { Variants } from "framer-motion";
import useQAImageItem from "./Hooks/useQAImageItem";
import { LuImageOff } from "react-icons/lu";
import CircularProgress from "@mui/material/CircularProgress";
import { CgTrashEmpty } from "react-icons/cg";
import { FaPen } from "react-icons/fa6";
import DoxleIconButton from "../../../../DoxleDesignPattern/DoxleButtons/DoxleIconButton";
import { editRgbaAlpha } from "../../../../Utilities/FunctionUtilities";
import { RiMarkupLine } from "react-icons/ri";

type Props = {
  imageItem: QAImage;
  imgIndex: number;
  handleClickImage: (imgIndex: number) => void;
  setdeletedQAImg: React.Dispatch<React.SetStateAction<QAImage | undefined>>;
};

const QAImageItem = ({
  imageItem,
  imgIndex,
  handleClickImage,
  setdeletedQAImg,
}: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore((state) => ({
    doxleThemeColor: state.doxleThemeColor,
    doxleFont: state.doxleFont,
  }));
  const {
    isLoadingImg,
    isImgError,
    onLoadImgStart,
    onLoadImgEnd,
    onLoadImgError,
    isDeletingImg,
    handleClickEditIcon,
  } = useQAImageItem({ imageItem });
  const rootAnimatedVariants: Variants = {
    initial: {
      opacity: 0,
    },
    entering: {
      opacity: 1,
    },
    exit: {
      opacity: 0,
    },
    hovering: {
      scale: [1, 0.95],
      opacity: [1, 0.95],
    },
  };
  return (
    <StyledQAImageItemContainer
      variants={rootAnimatedVariants}
      initial="initial"
      animate="entering"
      transition={{
        duration: 0.4,
      }}
      onClick={() => handleClickImage(imgIndex)}
    >
      {isLoadingImg && (
        <CircularProgress className="img-loader" size="1.6rem" />
      )}
      {!isImgError ? (
        <img
          className="img-holder"
          src={imageItem.thumbUrl ?? imageItem.url}
          onLoadStart={onLoadImgStart}
          onError={onLoadImgError}
          onLoadedData={onLoadImgEnd}
        />
      ) : (
        <LuImageOff color={doxleThemeColor.primaryFontColor} size="3rem" />
      )}

      <div className="menu-wrapper">
        <DoxleIconButton
          iconSource={
            <FaPen
              style={{ color: doxleThemeColor.primaryFontColor }}
              size="20px"
            />
          }
          buttonWrapperStyle={{
            margin: "0px 4px",
          }}
          bgColor={editRgbaAlpha({
            rgbaColor: doxleThemeColor.primaryContainerColor,
            alpha: "0.8",
          })}
          buttonSize={30}
          onClick={handleClickEditIcon}
        />
        <DoxleIconButton
          iconSource={
            !isDeletingImg ? (
              <CgTrashEmpty style={{ color: "red" }} size="20px" />
            ) : (
              <CircularProgress style={{ color: "red" }} size="20px" />
            )
          }
          buttonWrapperStyle={{
            margin: "0px 4px",
          }}
          bgColor={editRgbaAlpha({
            rgbaColor: doxleThemeColor.primaryContainerColor,
            alpha: "0.8",
          })}
          buttonSize={30}
          onClick={(e) => {
            e.stopPropagation();
            setdeletedQAImg(imageItem);
          }}
        />
      </div>

      {imageItem.markup && imageItem.markup.length > 0 && (
        <div className="markup-icon-wrapper">
          <RiMarkupLine size="1.4rem" color={doxleThemeColor.doxleColor} />
        </div>
      )}
    </StyledQAImageItemContainer>
  );
};

export default React.memo(QAImageItem);
