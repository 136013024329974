import { motion } from "framer-motion";
import styled from "styled-components";
import {
  DoxleFont,
  DoxleThemeColor,
} from "../../DoxleGeneralStore/useDoxleThemeStore";
import { editRgbaAlpha } from "../../Utilities/FunctionUtilities";
import Button from "@mui/material/Button";

export const StyledDoxleGalleryRootContainer = styled(motion.div)<{
  //   widthInPixel?: `${number}px`;
  //   widthInRatio?: `${number}%`;
}>`
  height: 100%;

  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export const StyledGallerySection = styled.div<{
  $themeColor: DoxleThemeColor;
}>`
  width: 100%;
  height: 100%;
  min-width: 500px;
  min-height: 300px;
  // max-width: 1280px;
  // max-height: 1000px;
  display: flex;
  background-color: ${(p) => p.$themeColor.primaryContainerColor};
  position: relative;
  overflow: hidden;
  border-radius: 4px;
  color: ${(p) => p.$themeColor.primaryFontColor};
  border: 1px solid ${(p) => p.$themeColor.primaryDividerColor};
`;

export const StyledDoxleGalleryPageControlSection = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  .page-number-text {
    color: ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.$themeColor.primaryFontColor,
        alpha: "0.6",
      })};
    font-family: ${(p) => p.$doxleFont.secondaryFont};
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: normal;
    align-self: center;
  }
`;

export const StyledGalleryPageControlButton = styled(Button)`
  && {
    display: flex;

    min-width: 40px;
    max-width: 40px;
    min-height: 40px;
    max-height: 40px;
    transition: 0.2s;
    &:hover {
      transform: scale(1.1);
      transition: 0.2s;
    }
  }
`;
export const StyledGalleryContentContainer = styled.div`
  height: calc(100% - 28px);
  padding: 14px;
  display: flex;
  width: calc(100% - 28px);
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;
export const StyledGalleryImageHolder = styled.img<{}>`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;
export const StyledDoxleGalleryTopMenu = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 28px);
  position: absolute;
  padding: 14px;
  z-index: 10;
  top: 0;
  left: 0;

  transition: 0.4s ease all;

  .wrapper {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;
export const StyledGalleryItemTitle = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  font-family: ${(p) => p.$doxleFont.secondaryFont};
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  color: white;

  align-self: flex-start;

  position: absolute;
  left: 14px;
  background-color: ${(p) =>
    editRgbaAlpha({ rgbaColor: p.$themeColor.doxleColor, alpha: "0.7" })};
  border-radius: 4px;
  padding: 4px 8px;
  max-width: 20vw;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
