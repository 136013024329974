import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDoxleThemeStore } from "../../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import { useDoxleAuthStore } from "../../../../DoxleGeneralStore/useDoxleAuthStore";
import { useDoxleCurrentContextStore } from "../../../../DoxleGeneralStore/useDoxleCurrentContext";
import useDoxleNotificationStore from "../../../../DoxleGeneralStore/useDoxleNotificationStore";
import { useEditDocketSideScreenStore } from "../../EditDocketSideScreenStore/useEditDocketSideScreenStore";
import ProjectQueryAPI, {
  FilterGetProjectQuery,
} from "../../../../Services/QueryHooks/projectQueryAPI";
import { Project } from "../../../../Models/project";
import { StyledProjectDisplaySectionContainer } from "../../../../DoxleDesignPattern/CommentUserInitial/StyledComponentCommentUserInitial";
import AddProjectDocket from "./AddProjectDocket";
import {
  StyledProjectFetchingLoadingContainer,
  StyledProjectFetchingLoadingText,
  StyledSelectProjectLabelText,
} from "../../StyledComponentsEditDocketSideScreen";
import ProjectSelectWithDropdown from "../../../../DoxleDesignPattern/ProjectSelectWithDropdown/ProjectSelectWithDropdown";
import CompleteCircle from "../../../../Utilities/Lottie/CompleteCircle";
import { CircularProgress } from "@mui/material";
import { motion } from "framer-motion";
import ListLoadingMore from "../../../../Utilities/Lottie/ListLoadingMore";
import {
  TRgbaFormat,
  editRgbaAlpha,
} from "../../../../Utilities/FunctionUtilities";
import HighlightOffSharpIcon from "@mui/icons-material/HighlightOffSharp";
import { Docket } from "../../../../Models/dockets";
import DoxleIconButton from "../../../../DoxleDesignPattern/DoxleButtons/DoxleIconButton";
import DocketQuery from "../../../../Services/QueryHooks/docketQueryAPI";
import { StyledEditGeneralFieldLabel } from "./StyledComponentDocketInfo";
import useSetDocketDetailQueryData from "../../../QueryDataHooks/SetQueryDataHooks/useSetDocketDetailQueryData";

type Props = {};

const ProjectDisplaySelection = (props: Props) => {
  const [searchProjectText, setSearchProjectText] = useState<string>("");
  const [showCompleteUpdateCircle, setShowCompleteUpdateCircle] =
    useState<boolean>(false);
  const [showRemoveIcon, setshowRemoveIcon] = useState<boolean>(false);
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore((state) => ({
    doxleThemeColor: state.doxleThemeColor,
    doxleFont: state.doxleFont,
  }));
  const { company } = useDoxleCurrentContextStore((state) => ({
    company: state.currentCompany,
  }));
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification
  );
  const {
    edittedDocket,
    setEdittedDocket,
    filterDocketListQuery,
    handlePostUpdateDocket,
  } = useEditDocketSideScreenStore(
    (state) => ({
      edittedDocket: state.edittedDocket,
      setEdittedDocket: state.setEdittedDocket,
      filterDocketListQuery: state.filterDocketListQuery,
      handlePostUpdateDocket: state.handlePostUpdateDocket,
    }),
    shallow
  );
  //# FETCHING PROJECTS
  const filterProjectListQuery: FilterGetProjectQuery = useMemo(
    () => ({
      searchText: searchProjectText,
      view: window.location.host.toLowerCase().includes("projects")
        ? "budget"
        : window.location.host.toLowerCase().includes("noticeboard")
        ? "noticeboard"
        : undefined,
    }),
    [searchProjectText]
  );
  const projectQuery = ProjectQueryAPI.useRetrieveFullProjectListQuery({
    enable: true,
    company,

    showNotification,
    filter: filterProjectListQuery,
  });

  const projectList: Project[] = useMemo(
    () =>
      projectQuery.isSuccess
        ? projectQuery.data?.pages.flatMap(
            (page) => (page.data.results as Project[]) ?? []
          ) ?? []
        : [],
    [projectQuery.data]
  );

  useEffect(() => {
    if (showCompleteUpdateCircle) {
      const timeout = setTimeout(
        () => setShowCompleteUpdateCircle(false),
        2000
      );

      return () => clearTimeout(timeout);
    }
  }, [showCompleteUpdateCircle]);
  const { handleUpdateDocket } = useSetDocketDetailQueryData({
    docketPk: edittedDocket?.docketPk ?? "",
  });

  const onSuccessUpdateCb = (updatedDocket?: Docket) => {
    setShowCompleteUpdateCircle(true);
    if (updatedDocket) {
      setEdittedDocket({ ...updatedDocket });
      handleUpdateDocket(updatedDocket);
      if (handlePostUpdateDocket) handlePostUpdateDocket(updatedDocket);
    }
  };
  const updateDocketQuery = DocketQuery.useUpdateDocketQuery({
    filter: filterDocketListQuery,

    showNotification,
    company,
    onSuccessCb: onSuccessUpdateCb,
  });

  const handleSelectProject = useCallback(
    (project: Project) => {
      if (edittedDocket)
        updateDocketQuery.mutate({
          docketPk: edittedDocket.docketPk,
          project: project.projectId,
          projectSiteAddress: project.siteAddress,
        });
      // setEdittedDocket({
      //   ...edittedDocket,
      //   project: project.projectId,
      //   projectSiteAddress: project.siteAddress,
      // });
    },
    [edittedDocket]
  );

  const handleRemoveProject = useCallback(() => {
    if (edittedDocket)
      updateDocketQuery.mutate({
        docketPk: edittedDocket.docketPk,
        project: null,
        projectSiteAddress: "",
      });
  }, [edittedDocket]);

  return (
    <StyledProjectDisplaySectionContainer
      onMouseEnter={() => setshowRemoveIcon(true)}
      onMouseLeave={() => setshowRemoveIcon(false)}
      layout="position"
      transition={{
        duration: 0.1,
        damping: 12,
      }}
    >
      {(projectQuery.isError ||
        (projectQuery.isSuccess && projectList.length === 0)) && (
        <AddProjectDocket filterProjectListQuery={filterProjectListQuery} />
      )}
      {projectQuery.isSuccess && projectList.length > 0 && (
        <motion.div
          className="project-displayer-section"
          initial={{ x: -20, opacity: 0 }}
          animate={{ x: 0, opacity: 1, transition: { duration: 0.4 } }}
        >
          <StyledEditGeneralFieldLabel
            $doxleFont={doxleFont}
            $themeColor={doxleThemeColor}
          >
            Project
          </StyledEditGeneralFieldLabel>
          <div className="row-wrapper">
            {showRemoveIcon && edittedDocket?.project && (
              <DoxleIconButton
                iconSource={
                  <HighlightOffSharpIcon
                    htmlColor={doxleThemeColor.primaryFontColor}
                    sx={{
                      fontSize: "2rem",
                    }}
                  />
                }
                buttonSize={30}
                onClick={handleRemoveProject}
              />
            )}

            <ProjectSelectWithDropdown
              selectedProject={
                edittedDocket
                  ? projectList.find(
                      (project) => project.projectId === edittedDocket.project
                    )
                  : undefined
              }
              handleSelectProject={handleSelectProject}
              projectDisplayWrapperStyle={{
                maxWidth: "calc(100% - 14px - 3px)",
              }}
            />

            {showCompleteUpdateCircle && <CompleteCircle animationSize={35} />}
            {updateDocketQuery.isLoading && (
              <CircularProgress
                sx={{ color: doxleThemeColor.doxleColor, marginLeft: "8px" }}
                size={14}
              />
            )}
          </div>
        </motion.div>
      )}

      {projectQuery.isLoading && <ProjectFetchingLoader />}
    </StyledProjectDisplaySectionContainer>
  );
};

export default ProjectDisplaySelection;

const ProjectFetchingLoader = () => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }),
    shallow
  );

  return (
    <StyledProjectFetchingLoadingContainer>
      <ListLoadingMore animationSize={80} />

      <StyledProjectFetchingLoadingText
        $doxleFont={doxleFont}
        animate={{
          color: [
            editRgbaAlpha({
              rgbaColor: doxleThemeColor.primaryFontColor as TRgbaFormat,
              alpha: "0.4",
            }),
            doxleThemeColor.primaryFontColor,
          ],
        }}
        transition={{
          duration: 0.4,
          repeat: Infinity,
          repeatType: "reverse",
        }}
      >
        Fetching Project
      </StyledProjectFetchingLoadingText>
    </StyledProjectFetchingLoadingContainer>
  );
};
