import {
  StyledDocketInfoEditFieldWrapper,
  StyledEditGeneralFieldLabel,
} from "./StyledComponentDocketInfo";
import { useEditDocketSideScreenStore } from "../../EditDocketSideScreenStore/useEditDocketSideScreenStore";
import { shallow } from "zustand/shallow";
import CustomDatePicker from "./CustomDatePicker";
import dayjs from "dayjs";
import useDocketDatePickerSection from "./Hooks/useDocketDatePickerSection";
import { useDoxleThemeStore } from "../../../../DoxleGeneralStore/useDoxleThemeStore";
import DoxleIconButton from "../../../../DoxleDesignPattern/DoxleButtons/DoxleIconButton";
import HighlightOffSharpIcon from "@mui/icons-material/HighlightOffSharp";

type Props = {
  dateType: "start" | "end";
};

const DocketDatePickerSection = ({ dateType }: Props) => {
  const { edittedDocket } = useEditDocketSideScreenStore(
    (state) => ({
      edittedDocket: state.edittedDocket,
    }),
    shallow
  );
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore((state) => ({
    doxleThemeColor: state.doxleThemeColor,
    doxleFont: state.doxleFont,
  }));
  const {
    handleUpdateStartDate,
    handleUpdateEndDate,
    onHover,
    setOnHover,
    handleRemoveStartDate,
    handleRemoveEndDate,
  } = useDocketDatePickerSection({});
  return (
    <StyledDocketInfoEditFieldWrapper
      layout="position"
      transition={{
        duration: 0.1,
        damping: 12,
      }}
    >
      <StyledEditGeneralFieldLabel
        $doxleFont={doxleFont}
        $themeColor={doxleThemeColor}
      >
        {dateType === "start" ? "Start Date" : "End Date"}
      </StyledEditGeneralFieldLabel>

      <div
        className="date-picker-wrapper"
        onMouseEnter={(e) => {
          e.stopPropagation();

          setOnHover(true);
        }}
        onMouseLeave={(e) => {
          setOnHover(false);
        }}
      >
        <CustomDatePicker
          value={
            dateType === "start"
              ? edittedDocket?.startDate
                ? dayjs(edittedDocket?.startDate).format("YYYY/MM/DD")
                : null
              : edittedDocket?.endDate
              ? dayjs(edittedDocket?.endDate).format("YYYY/MM/DD")
              : null
          }
          onChange={
            dateType === "start" ? handleUpdateStartDate : handleUpdateEndDate
          }
        />
        {((edittedDocket?.startDate && dateType === "start") ||
          (edittedDocket?.endDate && dateType === "end")) &&
          onHover && (
            <DoxleIconButton
              iconSource={
                <HighlightOffSharpIcon
                  htmlColor={doxleThemeColor.primaryFontColor}
                  sx={{
                    fontSize: "2rem",
                  }}
                />
              }
              buttonSize={30}
              onClick={() => {
                if (dateType === "start") handleRemoveStartDate();
                else handleRemoveEndDate();
              }}
            />
          )}
      </div>
    </StyledDocketInfoEditFieldWrapper>
  );
};

export default DocketDatePickerSection;
