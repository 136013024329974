import { create } from "zustand";
import {
  ContactCompanyFilters,
  ContactsFilters,
} from "../../../Services/QueryHooks/contactsFilters";
import { Contact, ContactCompany } from "../../../Models/addressBook";

type AddDialogType = null | "Contact" | "ContactCompany" | "Xero";

interface OrdersStore {
  view: "Contacts" | "Companies";
  setView: (view: "Contacts" | "Companies") => void;
  contactsQueryFilter: ContactsFilters;
  setContactQueryFilter: (filter: Partial<ContactsFilters>) => void;
  contactCompanyQueryFilter: ContactCompanyFilters;
  setContactCompanyQueryFilter: (
    filter: Partial<ContactCompanyFilters>
  ) => void;
  isSearching: boolean;
  setIsSearching: (value: boolean) => void;
  addDialogType: AddDialogType;

  setAddDialogType: (value: AddDialogType) => void;
  edittedContact: Contact | undefined;
  setEdittedContact: (contact: Contact | undefined) => void;
  edittedCompany: ContactCompany | undefined;
  setEdittedCompany: (contact: ContactCompany | undefined) => void;
  resetStore: () => void;
}

export const useContactsStore = create<OrdersStore>((set, get) => ({
  view: "Companies",
  setView: (value: "Contacts" | "Companies") => set({ view: value }),
  contactsQueryFilter: {},
  setContactQueryFilter: (filter: Partial<ContactsFilters>) =>
    set({ contactsQueryFilter: { ...get().contactsQueryFilter, ...filter } }),
  contactCompanyQueryFilter: {},
  setContactCompanyQueryFilter: (filter: Partial<ContactCompanyFilters>) =>
    set({
      contactCompanyQueryFilter: {
        ...get().contactCompanyQueryFilter,
        ...filter,
      },
    }),
  isSearching: false,
  setIsSearching: (value: boolean) => {
    if (!value && get().contactsQueryFilter.search)
      set({
        contactsQueryFilter: {
          ...get().contactsQueryFilter,
          search: undefined,
        },
      });
    if (!value && get().contactCompanyQueryFilter.search)
      set({
        contactCompanyQueryFilter: {
          ...get().contactCompanyQueryFilter,
          search: undefined,
        },
      });
    set({ isSearching: value });
  },
  addDialogType: null,
  setAddDialogType: (value: AddDialogType) => set({ addDialogType: value }),
  edittedContact: undefined,
  setEdittedContact: (contact: Contact | undefined) =>
    set({ edittedContact: contact }),
  edittedCompany: undefined,
  setEdittedCompany: (company: ContactCompany | undefined) =>
    set({ edittedCompany: company }),
  resetStore: () =>
    set({
      contactsQueryFilter: {},
      contactCompanyQueryFilter: {},
      isSearching: false,
      addDialogType: null,
      edittedContact: undefined,
      edittedCompany: undefined,
    }),
}));
