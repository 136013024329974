import React from "react";
import {
  StyleQuoteRequestAutocompleteSx,
  StyledQuoteSOWgroupAutocompleteWrapper,
  StyledSelectTaskContainer,
} from "./StyledComponentReviewQuote";
import {
  StyledAutocompleteListItem,
  StyledQuoteAutocompleteTextField,
} from "../MainStyledComponents";
import AutocompleteList from "../../../DoxleDesignPattern/DoxleAutoComplete/AutocompleteList";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import useSelectScopeGroup from "./Hooks/useSelectScopeGroup";
import Autocomplete from "@mui/material/Autocomplete";
import { useReviewQuoteRequestContext } from "./ReviewQuoteRequest";
import InputAdornment from "@mui/material/InputAdornment";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { produce } from "immer";
import DoxleSelectTaskDropdown from "../../../DoxleDesignPattern/DoxleSelectTaskDropdown/DoxleSelectTaskDropdown";

type Props = {};

const SelectScopeGroup = (props: Props) => {
  const { THEME_COLOR, DOXLE_FONT, currentTheme } = useDoxleThemeStore(
    (state) => ({
      THEME_COLOR: state.doxleThemeColor,
      DOXLE_FONT: state.doxleFont,
      currentTheme: state.currentTheme,
    })
  );

  const {
    taskGroupList,
    isFetchingSOWGroupList,
    isSuccessFetchingSOWGroupList,
    isErrorFetchingSOWGroupList,
    searchInput,
    setSearchInput,
    anchorTaskListPopover,
    setAnchorTaskListPopover,
  } = useSelectScopeGroup({});
  const { edittedQuoteRequest, setEdittedQuoteRequest } =
    useReviewQuoteRequestContext();
  const scopeGroups = edittedQuoteRequest.scopeGroupsJson ?? [];
  return (
    <>
      {" "}
      <StyledSelectTaskContainer
        onClick={(e) => setAnchorTaskListPopover(e.currentTarget)}
        style={{
          color:
            scopeGroups.length === 0
              ? THEME_COLOR.primaryInputPlaceholderColor
              : "",
        }}
      >
        {scopeGroups.length > 0
          ? scopeGroups[scopeGroups.length - 1].scopeGroupTitle
          : "Select Task Group..."}
      </StyledSelectTaskContainer>
      <DoxleSelectTaskDropdown
        popoverAnchor={anchorTaskListPopover}
        onClosePopover={() => {
          setAnchorTaskListPopover(null);
        }}
        selectedTaskItemIds={scopeGroups.map((item) => item.scopeGroupId)}
        onSelectTask={(task) => {
          if(scopeGroups.map((item) => item.scopeGroupId).includes(task.scopeGroupId)){
            return;
          }
          setEdittedQuoteRequest((prevState) =>
            produce(prevState, (draft) => {
              draft.scopeGroups.push(task.scopeGroupId);
              if (draft.scopeGroupsJson) draft.scopeGroupsJson.push(task);
              return draft;
            })
          );
        }}
        containerStyle={{
          borderRadius: 9,
        }}
      />
    </>

    // <StyledQuoteSOWgroupAutocompleteWrapper>
    //   <Autocomplete
    //     fullWidth
    //     autoHighlight
    //     PaperComponent={AutocompleteList}
    //     noOptionsText={
    //       isFetchingSOWGroupList
    //         ? "Loading..."
    //         : isErrorFetchingSOWGroupList
    //         ? "Error Fetching Scope of work"
    //         : "No Scope of Works"
    //     }
    //     options={taskGroupList}
    //     style={{
    //       background: THEME_COLOR.primaryContainerColor,
    //       borderRadius: "0px 0.9rem 0.9rem 1.2rem",
    //       color: THEME_COLOR.primaryFontColor,
    //     }}
    //     sx={StyleQuoteRequestAutocompleteSx(THEME_COLOR, DOXLE_FONT)}
    //     getOptionLabel={(option) => option.scopeGroupTitle}
    //     isOptionEqualToValue={(option, value) =>
    //       Boolean(
    //         edittedQuoteRequest.scopeGroups.find(
    //           (group) => group === option.scopeGroupId
    //         )
    //       )
    //     }
    //     renderOption={(props, option, state) => (
    //       <StyledAutocompleteListItem
    //         $themeColor={THEME_COLOR}
    //         $doxleFont={DOXLE_FONT}
    //         {...props}
    //         key={option.scopeGroupId}
    //         $selected={state.selected}
    //       >
    //         {option.scopeGroupTitle}
    //       </StyledAutocompleteListItem>
    //     )}
    //     renderInput={(params) => (
    //       <StyledQuoteAutocompleteTextField
    //         onFocus={(e) => e.currentTarget.select()}
    //         value={searchInput}
    //         onChange={(e) => setSearchInput(e.target.value)}
    //         placeholder="Select SOW Groups..."
    //         {...params}
    //         $themeColor={THEME_COLOR}
    //         $doxleFont={DOXLE_FONT}
    //         variant="standard"
    //         InputProps={{
    //           ...params.InputProps,
    //           endAdornment: (
    //             <InputAdornment position="end">
    //               <ExpandMore
    //                 style={{
    //                   position: "absolute",
    //                   right: "10px",
    //                   width: "20px",
    //                   height: "20px",
    //                 }}
    //                 color="disabled"
    //               />
    //             </InputAdornment>
    //           ),
    //         }}
    //       />
    //     )}
    //     onChange={(event, value, reason, details) => {
    //       if (
    //         reason === "selectOption" &&
    //         value &&
    //         !edittedQuoteRequest.scopeGroups.find(
    //           (group) => group === value?.scopeGroupId
    //         )
    //       )
    //         setEdittedQuoteRequest((prevState) =>
    //           produce(prevState, (draft) => {
    //             draft.scopeGroups.push(value.scopeGroupId);
    //             if (draft.scopeGroupsJson) draft.scopeGroupsJson.push(value);
    //             return draft;
    //           })
    //         );
    //     }}
    //   />
    // </StyledQuoteSOWgroupAutocompleteWrapper>
  );
};

export default SelectScopeGroup;
