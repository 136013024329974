import React, { useState } from "react";
import { useEditDocketSideScreenStore } from "../../../EditDocketSideScreenStore/useEditDocketSideScreenStore";
import { shallow } from "zustand/shallow";
import { useDoxleCurrentContextStore } from "../../../../../DoxleGeneralStore/useDoxleCurrentContext";
import { Docket } from "../../../../../Models/dockets";
import {
  UpdateDocketParams,
  getDocketMutationKey,
} from "../../../../../Services/QueryHooks/docketQueryAPI";
import { useIsMutating, useMutation } from "@tanstack/react-query";
import DoxleAPI from "../../../../../Services/DoxleAPI";
import { AxiosProgressEvent, isAxiosError } from "axios";
import useDoxleNotificationStore from "../../../../../DoxleGeneralStore/useDoxleNotificationStore";
import useSetDocketDetailQueryData from "../../../../QueryDataHooks/SetQueryDataHooks/useSetDocketDetailQueryData";
import { AxiosBackendErrorReturn } from "../../../../../Models/axiosReturn";

type Props = {};

interface ArchivePinSection {
  handleUpdateArchive: () => void;
  handleUpdatePin: () => void;
  isUpdatingArchive: number;
  isUpdatingPin: number;
  onHoverPin: boolean;
  setOnHoverPin: React.Dispatch<React.SetStateAction<boolean>>;
  onHoverArchive: boolean;
  setOnHoverArchive: React.Dispatch<React.SetStateAction<boolean>>;
}
const useArchivePinSection = (props: Props): ArchivePinSection => {
  const [onHoverPin, setOnHoverPin] = useState(false);
  const [onHoverArchive, setOnHoverArchive] = useState(false);
  const {
    edittedDocket,
    updateEdittedDocket,
    filterDocketListQuery,
    handlePostUpdateDocket,
    resetStore,
  } = useEditDocketSideScreenStore(
    (state) => ({
      edittedDocket: state.edittedDocket,
      updateEdittedDocket: state.updateEdittedDocket,
      filterDocketListQuery: state.filterDocketListQuery,
      handlePostUpdateDocket: state.handlePostUpdateDocket,
      resetStore: state.resetStore,
    }),
    shallow
  );

  const { company } = useDoxleCurrentContextStore((state) => ({
    company: state.currentCompany,
  }));

  const { showNotification } = useDoxleNotificationStore();
  interface IUpdateArchiveAndPinParams extends UpdateDocketParams {
    handlePostUpdateDocket?: (newDocket: Docket) => void;
  }
  const { handleUpdateDocket } = useSetDocketDetailQueryData({
    docketPk: edittedDocket?.docketPk ?? "",
    overwrite: true,
  });
  const mutation = useMutation({
    mutationKey: getDocketMutationKey("update"),
    mutationFn: (updateData: IUpdateArchiveAndPinParams) => {
      const {
        docketPk,
        setUpdateProgressValue,
        handlePostUpdateDocket,
        ...rest
      } = updateData;
      let updateDocketURL = `/dockets/${updateData.docketPk}/`;
      return DoxleAPI.patch<Docket>(updateDocketURL, rest, {
        headers: {
          "User-Company": company?.companyId || "",
        },
        onUploadProgress: (progressEvent: AxiosProgressEvent) => {
          if (progressEvent.total) {
            let percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            if (setUpdateProgressValue)
              setUpdateProgressValue(percentCompleted);
          }
        },
      });
    },

    onSuccess: (result, variables, context) => {
      if (variables.handlePostUpdateDocket)
        variables.handlePostUpdateDocket(result.data);
    },
    onError: (err, variables, context) => {
      if (showNotification) {
        if (isAxiosError<AxiosBackendErrorReturn>(err)) {
          showNotification(
            `${err?.response?.status ?? "ERROR"}: ${
              err.response?.data.detail ?? "UNKNOWN ERROR"
            }`,
            "error",
            String(
              err?.response?.data ?? "Fail To Update Action Timeline"
            ).substring(0, 300)
          );
        } else {
          showNotification(
            "Something Wrong!",
            "error",
            "Fail To Update Action Timeline"
          );
        }
      }
    },
  });

  const handleUpdateArchive = () => {
    if (edittedDocket) {
      mutation.mutateAsync({
        docketPk: edittedDocket.docketPk,
        isArchived: !edittedDocket.isArchived,
        handlePostUpdateDocket: (newDocket) => {
          if (handlePostUpdateDocket) handlePostUpdateDocket(newDocket);
          handleUpdateDocket(newDocket);
        },
      });
      updateEdittedDocket({
        isArchived: !edittedDocket.isArchived,
      });
    }
    resetStore();
  };
  const handleUpdatePin = () => {
    if (edittedDocket) {
      mutation.mutateAsync({
        docketPk: edittedDocket.docketPk,
        isSticky: !edittedDocket.isSticky,
        handlePostUpdateDocket: (newDocket) => {
          if (handlePostUpdateDocket) handlePostUpdateDocket(newDocket);
          handleUpdateDocket(newDocket);
        },
      });
      updateEdittedDocket({
        isSticky: !edittedDocket.isSticky,
      });
    }
    resetStore();
  };

  const isUpdatingArchive = useIsMutating({
    mutationKey: getDocketMutationKey("update"),
    predicate: (qr) =>
      Boolean(
        (qr.state.variables as IUpdateArchiveAndPinParams).docketPk ===
          edittedDocket?.docketPk &&
          (qr.state.variables as IUpdateArchiveAndPinParams).isArchived !==
            undefined
      ),
  });

  const isUpdatingPin = useIsMutating({
    mutationKey: getDocketMutationKey("update"),
    predicate: (qr) =>
      Boolean(
        (qr.state.variables as IUpdateArchiveAndPinParams).docketPk ===
          edittedDocket?.docketPk &&
          (qr.state.variables as IUpdateArchiveAndPinParams).isSticky !==
            undefined
      ),
  });
  return {
    handleUpdateArchive,
    handleUpdatePin,
    isUpdatingArchive,
    isUpdatingPin,
    onHoverPin,
    setOnHoverPin,
    onHoverArchive,
    setOnHoverArchive,
  };
};

export default useArchivePinSection;
