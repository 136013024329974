import { useRef } from "react";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import {
  StyledAddLineBtn,
  StyledAddOrderLineTextField,
  StyledDividerLine,
  StyledOrderFooterContent,
  StyledOrderLineFooterContainer,
  StyledOrderTotalFieldWrapper,
  StyledOrderTotalSection,
  StyledSpecialConditionSection,
  StyledSpecialConditionTextField,
} from "./StyledComponents";
import { editRgbaAlpha, formatter } from "../../../Utilities/FunctionUtilities";
import useOrderLineFooter from "../EditOrder/Hooks/useOrderLineFooter";
import { GoPlus } from "react-icons/go";
import { CircularProgress } from "@mui/material";
import { useEditOrderStore } from "../../OrdersStore/useEditOrderStore";
import { useEditOrderContext } from "../EditOrder/EditOrder";
type Props = {};

const OrderLineFooter = ({}: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }),
    shallow
  );
  const {
    newOrderDescription,
    setNewOrderDescription,

    newSpecialCondition,

    handleKeydownSpecialCondition,

    handleOrderSpecialConditionChange,
    handleKeydownNewLineDescription,
  } = useOrderLineFooter({});

  const editOrderContext = useEditOrderContext();
  const { setIsOrderLineUpdated } = editOrderContext
    ? editOrderContext : {setIsOrderLineUpdated: (bool: boolean)=>{}}

  const { editedOrder, readOnly, addNewOrderLine, updateEditedOrder } =
    useEditOrderStore(
      (state) => ({
        editedOrder: state.editedOrder,
        readOnly: state.readOnly,
        addNewOrderLine: state.addNewOrderLine,
        updateEditedOrder: state.updateEditedOrder,
      }),
      shallow
    );
  const specialConditionRef = useRef<HTMLInputElement>(null);
  const subTotal =
    editedOrder?.lines?.reduce((acc, line) => {
      return (acc += Number(line.lineCost));
    }, 0) || 0;
  const tax = 0.1 * subTotal;
  const total = subTotal + tax;
  return (
    <StyledOrderLineFooterContainer>
      {!readOnly && (
        <StyledAddOrderLineTextField
          $themeColor={doxleThemeColor}
          $doxleFont={doxleFont}
          placeholder="New"
          variant="standard"
          value={newOrderDescription}
          onChange={(event) => setNewOrderDescription(event.target.value)}
          InputProps={{
            startAdornment: (
              <StyledAddLineBtn
                initial={{
                  backgroundColor: editRgbaAlpha({
                    rgbaColor: doxleThemeColor.primaryFontColor,
                    alpha: "0",
                  }),
                }}
                animate={
                  newOrderDescription
                    ? {
                        backgroundColor: doxleThemeColor.primaryFontColor,
                      }
                    : {
                        backgroundColor: editRgbaAlpha({
                          rgbaColor: doxleThemeColor.primaryFontColor,
                          alpha: "0",
                        }),
                      }
                }
              >
                <GoPlus
                  style={{
                    color: !newOrderDescription
                      ? doxleThemeColor.primaryInputPlaceholderColor
                      : doxleThemeColor.primaryContainerColor,
                    fontSize: "1.8rem",
                  }}
                />
              </StyledAddLineBtn>
            ),
          }}
          onClick={(e) => {
            e.preventDefault();
            addNewOrderLine();
            setIsOrderLineUpdated(true);
          }}
          onKeyDown={handleKeydownNewLineDescription}
        />
      )}

      <StyledOrderFooterContent>
        {!readOnly ? (
          <StyledSpecialConditionSection
            $themeColor={doxleThemeColor}
            $doxleFont={doxleFont}
          >
            <div className="label-title">
              {" "}
              SPECIAL CONDITIONS ON THIS PURCHASE ORDER (Please read carefully)
            </div>
            <div
              className="condition-texfield-wrapper"
              onClick={() => specialConditionRef.current?.focus()}
            >
              <StyledSpecialConditionTextField
                $themeColor={doxleThemeColor}
                $doxleFont={doxleFont}
                multiline
                inputRef={specialConditionRef}
                variant="standard"
                value={newSpecialCondition}
                onChange={(e) =>
                  handleOrderSpecialConditionChange(e.target.value)
                }
                onKeyDown={handleKeydownSpecialCondition}
                onFocus={(e) => e.currentTarget.select()}
              />
            </div>
          </StyledSpecialConditionSection>
        ) : (
          <div />
        )}
        {/* <div style={{width: "80%"}}></div> */}
        <StyledOrderTotalSection>
          <StyledOrderTotalFieldWrapper
            $themeColor={doxleThemeColor}
            $doxleFont={doxleFont}
          >
            <div className="total-label">Subtotal</div>
            <div className="total-value">
              {/* {editedOrder?.subTotal
                ? formatter.format(Number(editedOrder.subTotal))
                : "$0.00"} */}
              {formatter.format(subTotal)}
            </div>
          </StyledOrderTotalFieldWrapper>

          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <StyledDividerLine $themeColor={doxleThemeColor} />
          </div>

          <StyledOrderTotalFieldWrapper
            $themeColor={doxleThemeColor}
            $doxleFont={doxleFont}
          >
            <div className="total-label">GST</div>
            <div className="total-value">
              {/* {editedOrder?.tax
                ? formatter.format(Number(editedOrder.tax))
                : "$0.00"} */}
              {formatter.format(tax)}
            </div>
          </StyledOrderTotalFieldWrapper>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <StyledDividerLine $themeColor={doxleThemeColor} />
          </div>
          <StyledOrderTotalFieldWrapper
            $themeColor={doxleThemeColor}
            $doxleFont={doxleFont}
            style={
              {
                // borderTop: `1px solid ${doxleThemeColor.primaryDividerColor}`,
                // borderBottom: `1px solid ${doxleThemeColor.primaryDividerColor}`,
              }
            }
          >
            <div className="total-label">TOTAL</div>
            <div className="total-value">
              {/* {editedOrder?.total
                ? formatter.format(Number(editedOrder.total))
                : "$0.00"} */}
              {formatter.format(total)}
            </div>
          </StyledOrderTotalFieldWrapper>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <StyledDividerLine $themeColor={doxleThemeColor} />
          </div>
          {readOnly &&
            editedOrder?.totalPaymentsMade !== undefined &&
            editedOrder?.totalOutstanding !== undefined && (
              <>
                <StyledOrderTotalFieldWrapper
                  $themeColor={doxleThemeColor}
                  $doxleFont={doxleFont}
                >
                  <div className="total-label">PAYMENTS MADE</div>
                  <div className="total-value">
                    {editedOrder?.total
                      ? formatter.format(Number(editedOrder.totalPaymentsMade))
                      : "$0.00"}
                  </div>
                </StyledOrderTotalFieldWrapper>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <StyledDividerLine $themeColor={doxleThemeColor} />
                </div>
                <StyledOrderTotalFieldWrapper
                  $themeColor={doxleThemeColor}
                  $doxleFont={doxleFont}
                  style={
                    {
                      // borderTop: `1px solid ${doxleThemeColor.primaryDividerColor}`,
                      // borderBottom: `1px solid ${doxleThemeColor.primaryDividerColor}`,
                    }
                  }
                >
                  <div className="total-label">DUE</div>
                  <div className="total-value">
                    {editedOrder?.total
                      ? formatter.format(Number(editedOrder.totalOutstanding))
                      : "$0.00"}
                  </div>
                </StyledOrderTotalFieldWrapper>
              </>
            )}
        </StyledOrderTotalSection>
      </StyledOrderFooterContent>
    </StyledOrderLineFooterContainer>
  );
};

export default OrderLineFooter;
