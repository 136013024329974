import { PricebookSupplierRate } from "../../Models/Pricebook";
import {
  StyledPricebookSupplierRateContainer,
  StyledRatePriceText,
  StyledSupplierRateCell,
} from "./StyledComponents";
import { formatter } from "../../../Utilities/FunctionUtilities";

type Props = {
  index: number;
  supplierRates: PricebookSupplierRate;
  pricebookId: string;
};

function PricebookSupplierRateLine({
  index,
  supplierRates,
  pricebookId,
}: Props) {
  return (
    <StyledPricebookSupplierRateContainer>
      <StyledRatePriceText>
        {formatter.format(Number(supplierRates.unitCost))}
      </StyledRatePriceText>

      <StyledSupplierRateCell>
        ({supplierRates ? supplierRates.supplierName : "Unknown Supplier"})
      </StyledSupplierRateCell>
    </StyledPricebookSupplierRateContainer>
  );
}

export default PricebookSupplierRateLine;
