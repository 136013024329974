import { useEffect, useMemo, useState } from "react";
import { useDoxleCurrentContextStore } from "../../../../../DoxleGeneralStore/useDoxleCurrentContext";
import { shallow } from "zustand/shallow";
import ContactsAPI from "../../../../../Services/QueryHooks/contactsAPI";
import { Contact } from "../../../../../Models/addressBook";
import { ContactsFilters } from "../../../../../Services/QueryHooks/contactsFilters";

type Props = {
  searchInput?: string;
};

interface GetContactList {
  contactList: Contact[];
  isFetchingContact: boolean;
}
const useGetContactList = ({ searchInput }: Props): GetContactList => {
  const [filterContact, setFilterContact] = useState<ContactsFilters>({});
  const company = useDoxleCurrentContextStore(
    (state) => state.currentCompany,
    shallow
  );
  const getContactQuery = ContactsAPI.useRetrieveContactsQuery({
    company,
    filter: filterContact,
    enable: true,
  });
  const contactList: Contact[] = useMemo(
    () =>
      getContactQuery.isSuccess
        ? getContactQuery.data.pages.reduce((acc, data) => {
            return acc.concat(data.data.results);
          }, [] as Contact[])
        : [],
    [getContactQuery.data]
  );
  // TODO: change to search and fetch one page
  useEffect(() => {
    if (getContactQuery.hasNextPage) getContactQuery.fetchNextPage();
  }, [getContactQuery.hasNextPage]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setFilterContact((prev) => ({ ...prev, searchInput }));
    }, 300);

    return () => {
      clearTimeout(timeout);
    };
  }, [searchInput]);
  return { contactList, isFetchingContact: getContactQuery.isLoading };
};

export default useGetContactList;
