import { useDoxleCurrentContextStore } from "../../DoxleGeneralStore/useDoxleCurrentContext";
import { shallow } from "zustand/shallow";
import { useDoxleAuthStore } from "../../DoxleGeneralStore/useDoxleAuthStore";
import useDoxleNotificationStore from "../../DoxleGeneralStore/useDoxleNotificationStore";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import DoxleAPI from "../../Services/DoxleAPI";
import { useMemo } from "react";
import { DrawingSet, Sheet, TakeOff } from "../Models/takeOffs";
import { DisplayTakeOff } from "../../Estimates/Content/Store/useEditEstimateStore";
import { AxiosResponse, isAxiosError } from "axios";
import { AxiosBackendErrorReturn } from "../../Models/axiosReturn";

const useRetrieveTakeOffDetail = (takeOffId: string | undefined) => {
  const company = useDoxleCurrentContextStore(
    (state) => state.currentCompany,
    shallow
  );

  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );

  return useQuery(
    ["takeOffId-detail", takeOffId ?? ""],
    () =>
      DoxleAPI.get<any>(`/takeoff/${takeOffId ?? ""}/`, {
        headers: {
          "User-Company": company!.companyId,
        },
      }),
    {
      enabled: Boolean(company && takeOffId),
      retry: 1,
      // staleTime: 10 * 60 * 1000,
      refetchInterval: false,
      refetchIntervalInBackground: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      onError: (error) => {
        if (showNotification) {
          if (isAxiosError<AxiosBackendErrorReturn>(error)) {
            showNotification(
              `${error?.response?.status ?? "ERROR"}: ${
                error.response?.data.detail ?? "UNKNOWN ERROR"
              }`,
              "error",
              String(
                error?.response?.data?.detail ?? "Failed to get takeoff details"
              ).substring(0, 300)
            );
          } else {
            showNotification(
              "Something Wrong!",
              "error",
              "Failed to get takeoff details"
            );
          }
        }
      },
    }
  );
};
interface UpdateScaleProps {
  id: string;
  scale: number;
}

interface props {
  onSuccessCb?: (displayTakeOff: DisplayTakeOff) => void;
}
const useCreateNewTakeOff = ({ onSuccessCb }: props) => {
  const company = useDoxleCurrentContextStore(
    (state) => state.currentCompany,
    shallow
  );

  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );
  return useMutation({
    mutationFn: (takeOff: TakeOff) =>
      DoxleAPI.post(`/takeoff/new/`, takeOff, {
        headers: { "User-Company": company?.companyId },
      }),
    onSuccess: (data: AxiosResponse<any>) => {
      // showNotification(`Successfully Added Take Off`, "success");
      // queryClient.setQueryData(
      //   ["takeOffId-detail", data?.data?.takeOffId ?? ""],
      //   data
      // )
      if (onSuccessCb)
        onSuccessCb({
          takeOffId: data?.data?.takeOffId,
          unit: data?.data?.unit,
        });
    },
    onError: (error: any) => {
      if (showNotification) {
        if (isAxiosError<AxiosBackendErrorReturn>(error)) {
          showNotification(
            `${error?.response?.status ?? "ERROR"}: ${
              error.response?.data.detail ?? "UNKNOWN ERROR"
            }`,
            "error",
            String(
              error?.response?.data?.detail ?? "Error Saving Takeoff"
            ).substring(0, 300)
          );
        } else {
          showNotification(
            "Something Wrong!",
            "error",
            "Error Saving Takeoff"
          );
        }
      }
    },
  });
};

const DrawingsQueryAPI = {
  useRetrieveTakeOffDetail,
  useCreateNewTakeOff,
};
export default DrawingsQueryAPI;
