import React from "react";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import {
  StyledAddProjectTextField,
  StyledAddProjectXeroContainer,
  StyledAddProjectXeroDisplay,
  StyledTrackingOptionItemContainer,
} from "./StyledComponentAddProjectScreen";
import { editRgbaAlpha } from "../../Utilities/FunctionUtilities";
import { motion } from "framer-motion";
import useAddProjectXeroTracking from "./Hooks/useAddProjectXeroTracking";
import { Autocomplete, CircularProgress, InputAdornment } from "@mui/material";
import AutocompleteList from "../../DoxleDesignPattern/DoxleAutoComplete/AutocompleteList";
import { NewProject } from "../../Models/project";
import { produce } from "immer";

type Props = {
  projectTrackingId: string | null | undefined;
  setNewProject: React.Dispatch<React.SetStateAction<NewProject>>;
};

const AddProjectXeroTracking = ({
  projectTrackingId,
  setNewProject,
}: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }),
    shallow
  );
  const {
    trackingOptions,
    isFetchingTrackingOptions,
    isSuccessFetchingTrackingOptions,
    isErrorFetchingTrackingOptions,
    setSearchTrackingText,
  } = useAddProjectXeroTracking({ setNewProject });
  const trackingValue = projectTrackingId
    ? trackingOptions.find(
        (option) => option.trackingOptionId === projectTrackingId
      )
    : undefined;
  //* animation
  const labelAnimatedVariants = {
    initial: {
      color: editRgbaAlpha({
        rgbaColor: doxleThemeColor.primaryFontColor,
        alpha: "0.4",
      }),
    },
    focused: {
      color: doxleThemeColor.primaryFontColor,
      transition: {
        duration: 0.4,
      },
    },
  };
  return (
    <StyledAddProjectXeroContainer $doxleFont={doxleFont}>
      <motion.span
        className="textfield-label"
        variants={labelAnimatedVariants}
        initial="initial"
        animate={"initial"}
      >
        Xero Tracking Option
      </motion.span>

      <Autocomplete
        PaperComponent={AutocompleteList}
        noOptionsText={"Not Found"}
        disablePortal
        sx={{
          "& + .MuiAutocomplete-popper .MuiAutocomplete-option:hover": {
            // 👇 Customize the hover bg color here
            backgroundColor: doxleThemeColor.hoverColor,
          },
          "& + .MuiAutocomplete-popper .MuiAutocomplete-option": {
            // 👇 Customize the hover bg color here
            textTransform: "lowercase",
            height: "3rem",
            padding: "0px !important",
          },
          "& + .MuiAutocomplete-popper .MuiAutocomplete-option[aria-selected='true']":
            {
              color: doxleThemeColor.doxleColor,
            },
        }}
        value={trackingValue}
        loadingText="Looking For Options..."
        options={trackingOptions}
        getOptionLabel={(option) =>
          typeof option === "string" ? option : option.trackingOptionName
        }
        placeholder={"Create a new xero link"}
        freeSolo
        renderOption={(props, option, context) => {
          return (
            <li
              {...props}
              key={
                `trackingItem-` + context.index + `-` + option.trackingOptionId
              }
              style={{
                textTransform: "capitalize",
              }}
            >
              <StyledTrackingOptionItemContainer
                $themeColor={doxleThemeColor}
                $doxleFont={doxleFont}
                initial={{
                  color: editRgbaAlpha({
                    rgbaColor: doxleThemeColor.primaryFontColor,
                    alpha: "0.5",
                  }),
                }}
                animate={{
                  color: context.selected
                    ? doxleThemeColor.doxleColor
                    : editRgbaAlpha({
                        rgbaColor: doxleThemeColor.primaryFontColor,
                        alpha: "0.5",
                      }),
                }}
                whileHover={{
                  backgroundColor: doxleThemeColor.hoverColor,
                  color: doxleThemeColor.primaryFontColor,
                }}
                transition={{ duration: 0.2 }}
              >
                {option.trackingOptionName}
              </StyledTrackingOptionItemContainer>
            </li>
          );
        }}
        renderInput={(params) => (
          <StyledAddProjectTextField
            {...params}
            $themeColor={doxleThemeColor}
            $doxleFont={doxleFont}
            variant="standard"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <InputAdornment position="end">
                  {isFetchingTrackingOptions ? (
                    <CircularProgress
                      size={"2rem"}
                      sx={{ color: doxleThemeColor.doxleColor }}
                    />
                  ) : null}
                </InputAdornment>
              ),
            }}
            placeholder={"Create a new xero link"}
            onChange={(
              event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            ) => setSearchTrackingText(event.target.value)}
          />
        )}
        onChange={(event, newValue, reason) => {
          if (newValue) {
            if (typeof newValue !== "string") {
              setNewProject(
                produce((draft) => {
                  draft.trackingId = newValue.trackingOptionId;
                  draft.trackingName = newValue.trackingOptionName;
                  return draft;
                })
              );
              setSearchTrackingText(newValue.trackingOptionName);
            }
          } else {
            setNewProject(
              produce((draft) => {
                draft.trackingId = null;
                draft.trackingName = newValue;
                return draft;
              })
            );
            setSearchTrackingText("");
          }
        }}
      />
    </StyledAddProjectXeroContainer>
  );
};

export default AddProjectXeroTracking;
