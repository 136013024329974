import React from "react";
import { StyledCommentSkeletonRowContainer } from "./StyledComponentDoxleSkeleton";
import { Skeleton } from "@mui/material";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";

type Props = {};
const ROW_HEIGHT = 100;
const MARGIN_ROW_VERTICAL = 14;
const CommentSkeletonRow = (props: Props) => {
  const { doxleThemeColor } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
    }),
    shallow
  );
  return (
    <StyledCommentSkeletonRowContainer
      $marginVertical={MARGIN_ROW_VERTICAL}
      $rowHeight={ROW_HEIGHT}
    >
      <Skeleton
        variant="text"
        width="20%"
        height={10}
        sx={{ marginBottom: "14px" }}
      />

      <Skeleton
        variant="rounded"
        width="100%"
        height={10}
        sx={{ marginBottom: "4px" }}
      />
      <Skeleton
        variant="rounded"
        width="90%"
        height={10}
        sx={{ marginBottom: "4px" }}
      />
    </StyledCommentSkeletonRowContainer>
  );
};

export default React.memo(CommentSkeletonRow);
