import React, { useMemo, useState } from "react";
import {
  CompanyPermission,
  DocketPermission,
  ProjectPermission,
} from "../../../../Models/permissions";

type Props = {};

interface IShareProjectDialog {
  showShareDialog: boolean;
  setShowShareDialog: React.Dispatch<React.SetStateAction<boolean>>;
  contextValue: IShareProjectDialogContext;
}
export interface IShareProjectDialogContext {
  editedPermissionItem:
    | ProjectPermission
    | CompanyPermission
    | DocketPermission
    | undefined;
  setEditedPermissionItem: React.Dispatch<
    React.SetStateAction<
      ProjectPermission | CompanyPermission | DocketPermission | undefined
    >
  >;
  anchorPopoverForm: HTMLElement | null;
  setAnchorPopoverForm: React.Dispatch<
    React.SetStateAction<HTMLElement | null>
  >;
}
const useShareProjectDialog = (props: Props): IShareProjectDialog => {
  const [showShareDialog, setShowShareDialog] = useState(false);
  const [editedPermissionItem, setEditedPermissionItem] = useState<
    ProjectPermission | CompanyPermission | DocketPermission | undefined
  >(undefined);
  const [anchorPopoverForm, setAnchorPopoverForm] =
    useState<HTMLElement | null>(null);

  const contextValue: IShareProjectDialogContext = useMemo(
    () => ({
      editedPermissionItem,
      setEditedPermissionItem,
      anchorPopoverForm,
      setAnchorPopoverForm,
    }),
    [editedPermissionItem, anchorPopoverForm]
  );
  return {
    showShareDialog,
    setShowShareDialog,
    contextValue,
  };
};

export default useShareProjectDialog;
