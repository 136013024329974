import {
  Input,
  InputAdornment,
  InputAdornmentProps,
  InputProps,
  styled as MUIStyled,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
  TextFieldProps,
} from "@mui/material";
import {
  DoxleFont,
  DoxleTheme,
  DoxleThemeColor,
} from "../../../../DoxleGeneralStore/useDoxleThemeStore";
import styled from "styled-components";
import { motion } from "framer-motion";
import { editRgbaAlpha } from "../../../../Utilities/FunctionUtilities";

export const StyledBudgetListScroller = styled.div`
  width: 100%;
  height: 100%;

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

export const StyledInputElement = MUIStyled("input")(
  ({ theme }) => `
  font-size: 1.4rem;
  font-family: Inter;
  font-weight: 400;
  line-height: 1.5;
  flex-grow: 1;
  background: transparent;
  border: none;
  border-radius: inherit;
  padding: 8px 4px;
  outline: 0;
`
);

export const StyledBudgetRowContainer = styled(TableCell)`
  display: flex !important;
  flex-direction: row;
  width: calc(100%);

  align-items: center;
  padding: 0px !important;
  height: 40px;
  position: relative;
  .order-cell {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(213, 215, 227, 1);
    height: 100%;
    color: ${(p) => p.theme.color.primaryFontColor};
    font-family: ${(p) => p.theme.font.primaryFont};
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
  }
  .content-cell {
    background-color: ${(p) => p.theme.color.primaryContainerColor} !important;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid ${(p) => p.theme.color.primaryDividerColor};
    border-bottom: 1px solid ${(p) => p.theme.color.primaryDividerColor};
    height: 100%;

    .line-cost {
      color: ${(p) => p.theme.color.primaryFontColor};
      font-family: ${(p) => p.theme.font.primaryFont};
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      max-width: 100%;
      text-align: left;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
`;

export const StyledEstimateIdText = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  color: ${(p) => p.$themeColor.primaryFontColor};
  font-size: 1.4rem;
  font-family: ${(p) => p.$doxleFont.primaryFont};
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.006rem;
  width: 12%;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
export const StyledEditDocketBudgetEstimateContainer = styled(motion.div)`
  width: calc(100%);
  margin: 30px 0px 30px 0px;
  padding: 0px;
  flex-direction: column;
  height: calc(100% - 60px);
  min-width: 480px;
  display: flex;
  position: relative;
`;

interface IEditBudgetCustomInput extends InputProps {
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}

export const StyledEditBudgetCustomInput = styled(Input)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  && {
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    color: ${(p) => p.$themeColor.primaryFontColor};
    border-bottom: none !important;

    margin: 0px 2px;
    &.MuiInput-root:before {
      border-bottom: none !important;
    }
    &.MuiInput-root:after {
      border-bottom: none;
    }
    & .MuiInput-input {
      padding: 0px 4px !important;
      text-overflow: ${(p) =>
        p.id === "description" ? "ellipsis !important" : ""};
    }
  }
`;
// export const StyledEditBudgetCustomInput = React.forwardRef<
//   HTMLInputElement,
//   IEditBudgetCustomInput
// >(({ $themeColor, $doxleFont, sx, ...props }, ref) => {
//   return (
//     <Input
//       sx={{
//         fontFamily: $doxleFont.primaryFont,
//         fontSize: 14,
//         fontWeight: "500",
//         lineHeight: "normal",
//         color: $themeColor.primaryFontColor,
//         borderBottom: "none !important",
//         backgroundColor: $themeColor.primaryTextFieldBackgroundColor,
//         marginInline: "2px",
//         "&.MuiInput-root:before": {
//           borderBottom: "none !important",
//         },
//         "&.MuiInput-root:after": {
//           borderBottom: `none`,
//         },
//         "& .MuiInput-input": {
//           padding: "0px 0.4rem !important",
//           textOverflow: props.id === "description" ? "ellipsis !important" : "",
//         },
//         ...sx,
//       }}
//       {...props}
//       ref={ref}
//     />
//   );
// });

interface ICustomTextFieldProps extends TextFieldProps<"standard"> {
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}

export const StyledBudgetMutilineTextField = styled(
  ({ $themeColor, $doxleFont, ...rest }: ICustomTextFieldProps) => (
    <TextField {...rest} />
  )
)`
  && {
    width: calc(20% - 1.6rem);
    min-width: 20%;
    border-radius: 0px;
    background-color: ${(p) => p.$themeColor.primaryTextFieldBackgroundColor};
  }
  & .MuiOutlinedInput-root {
    padding: 0px !important;
  }
  input {
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-weight: 400;
    font-size: max(1.4rem, 14px);
    line-height: normal;
    color: ${(p) => p.$themeColor.primaryFontColor};
    padding: 0px !important;
    margin-left: 2px;
    &::placeholder {
      font-family: ${(p) => p.$doxleFont.primaryFont};
      font-weight: 400;
      font-size: max(1.4rem, 14px);
      line-height: normal;
      color: ${(p) => p.$themeColor.primaryInputPlaceholderColor};
      padding: 0px !important;
      opacity: 1;
    }
  }
  textarea {
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-weight: 400;
    font-size: max(1.4rem, 14px);
    line-height: normal;

    color: ${(p) => p.$themeColor.primaryFontColor};
    padding: 0px !important;
    &::placeholder {
      font-family: ${(p) => p.$doxleFont.primaryFont};
      font-weight: 400;
      font-size: max(1.4rem, 14px);
      line-height: normal;
      color: ${(p) => p.$themeColor.primaryInputPlaceholderColor};
      padding: 0px !important;
      opacity: 1;
    }
  }
  & .MuiInput-root:after {
    border-bottom: none !important;
  }
  & .MuiInput-root:before {
    border-bottom: none !important;
  }
  & .MuiInput-root {
    border-bottom: none !important;
  }
  & .MuiInput-root:hover:not(.Mui-disabled, .Mui-error):before {
    border-bottom: none !important;
  }
  & .MuiInputBase-root {
    width: 100%;
  }
`;

export const StyledInputEndorment = styled(
  ({
    $themeColor,
    $doxleFont,
    ...props
  }: InputAdornmentProps & {
    $themeColor: DoxleThemeColor;
    $doxleFont: DoxleFont;
  }) => <InputAdornment {...props} />
)`
  && {
    color: ${(p) => p.$themeColor.primaryFontColor};
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    & .MuiTypography-root {
      color: ${(p) => p.$themeColor.primaryFontColor};
      font-family: ${(p) => p.$doxleFont.primaryFont};
      font-size: 1.4rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.07rem;
    }
  }
`;
export const StyledEditEstimateStaticWrapper = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  flex: 1;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  .quantity-wrapper {
    width: 25%;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .math-symbol {
    color: ${(p) => p.$themeColor.primaryFontColor};
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.7px;
  }
  .line-cost {
    color: ${(p) => p.$themeColor.primaryFontColor};
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.7px;
    width: 30%;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;
export const StyledBudgetEstimateListFooter = styled(motion(TableRow))`
  && {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 40px;
    background-color: ${(p) => p.theme.color.primaryContainerColor};
  }
`;

export const StyledAddEstimateWrapper = styled.div<{
  $themeColor: DoxleThemeColor;
}>`
  display: flex;
  flex-direction: row;
  width: calc(100% - 1.6rem);

  align-items: center;
  background-color: ${(p) => p.$themeColor.primaryContainerColor};
  border-radius: 0px 0px 0px 0.9rem;
  height: 4rem;
  padding: 0px 0.8rem;
`;

export const StyledBudgetEstimateTableRow = styled(motion(TableRow))`
  && {
    width: 100% !important;
    display: flex;
    flex-direction: column;

    overflow: hidden;
    min-height: 1px;
  }
`;

export const StyledBudgetEstimateTable = styled(Table)`
  && {
    width: 100% !important;

    min-width: 40rem !important;
    display: flex !important;
    flex-direction: column;
  }
`;
export const StyledBudgetEstimateTableHead = styled(TableHead)`
  && {
    width: 100% !important;
  }
`;
export const StyledBudgetEstimateTableBody = styled(TableBody)`
  && {
    width: 100% !important;
    min-width: 40rem !important;
  }
`;
export const StyledBudgetEstimateHeader = styled(TableRow)`
  && {
    width: 100%;
    min-height: 1px;
    display: flex;
    flex-direction: column;
    background-color: rgba(213, 215, 227, 1);
  }
`;
export const StyledBudgetEstimateUpperHeader = styled(TableCell)`
  && {
    padding: 0px !important;
    width: 100%;
    display: flex;
    align-items: center;

    height: 30px;
    border-bottom: none !important;
  }
  .header-text {
    color: ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.theme.color.primaryFontColor,
        alpha: "0.5",
      })};
    font-family: ${(p) => p.theme.font.primaryFont};
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
export const StyledBudgetEstimateLowerHeader = styled(TableCell)`
  && {
    padding: 0px !important;
    width: 100%;
    display: flex;
    align-items: center;

    height: 30px;

    .header-text {
      color: ${(p) => p.theme.color.primaryFontColor};
      font-family: ${(p) => p.theme.font.primaryFont};
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;
export const StyledBudgetEstimateFooter = styled(TableFooter)`
  && {
    padding: 0px !important;
    width: 100%;
    display: flex;
    align-items: center;
  }
`;
