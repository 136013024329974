import React, { createContext, useCallback, useContext } from "react";
import {
  StyledProjectSelectPopover,
  StyledProjectSelectWithDropdownContainer,
} from "./StyledComponentsProjectSelectWithDropdown";
import { Project } from "../../Models/project";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import { PaperProps } from "@mui/material";
import useProjectSelectWithDropdown from "./Hooks/useProjectSelectWithDropdown";
import ProjectDropdownList from "./ProjectDropdownList";
import { editRgbaAlpha } from "../../Utilities/FunctionUtilities";
import { IoChevronDownOutline } from "react-icons/io5";

type Props = {
  selectedProject?: Project | undefined;
  handleSelectProject?: (item: Project) => void; //!CALL BACK TO GET THE SELECTED PROJECT, PASS IN THIS FUNCTION TO GET THE SELECTED ITEM AND PROCEED FURTHER
  projectDisplayWrapperStyle?: React.CSSProperties;
  projectTextStyle?: React.CSSProperties;
  dropdownWrapperStyle?: PaperProps["sx"];
};

interface ProjectSelectWithDropdownContext {
  setSearchProjectText: React.Dispatch<React.SetStateAction<string>>;
  handleFetchMoreProject: () => void;
}

const ProjectSelectWithDropdownContext =
  createContext<ProjectSelectWithDropdownContext | null>(null);
const ProjectSelectWithDropdown = ({
  selectedProject,

  handleSelectProject,
  projectDisplayWrapperStyle,
  projectTextStyle,
  dropdownWrapperStyle,
}: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore((state) => ({
    doxleThemeColor: state.doxleThemeColor,
    doxleFont: state.doxleFont,
  }));
  const {
    anchorProjectDropdown,
    setAnchorProjectDropdown,
    projectList,
    isFetchingProject,
    isSuccessFetchingProject,
    isErrorFetchingProject,
    isFetchingNextPage,

    handleOpenDropdown,
    handleCloseDropdown,
    showProjectList,
    setSearchProjectText,
    handleFetchMoreProject,
  } = useProjectSelectWithDropdown({});

  const canBeOpen = Boolean(anchorProjectDropdown);
  const id = canBeOpen ? "project-menu-popper" : undefined;

  const handleClickProjectItem = useCallback(
    (item: Project) => {
      if (handleSelectProject) handleSelectProject(item);
      handleCloseDropdown();
    },
    [handleSelectProject, setAnchorProjectDropdown]
  );

  const contextValue: ProjectSelectWithDropdownContext = {
    setSearchProjectText,
    handleFetchMoreProject,
  };
  return (
    <ProjectSelectWithDropdownContext.Provider value={contextValue}>
      <>
        <StyledProjectSelectWithDropdownContainer
          $themeColor={doxleThemeColor}
          $doxleFont={doxleFont}
          onClick={handleOpenDropdown}
          style={projectDisplayWrapperStyle}
          aria-describedby={id}
          layout="position"
          transition={{
            duration: 0.2,
            damping: 12,
          }}
        >
          <span
            style={{
              ...projectTextStyle,
              color: editRgbaAlpha({
                rgbaColor: doxleThemeColor.primaryFontColor,
                alpha: selectedProject ? "1" : "0.4",
              }),
            }}
          >
            {selectedProject ? selectedProject.siteAddress : "Select A Project"}
          </span>
          <IoChevronDownOutline
            className="icon-dropdown"
            color={doxleThemeColor.primaryFontColor}
            size={14}
          />
        </StyledProjectSelectWithDropdownContainer>

        <StyledProjectSelectPopover
          id={id}
          open={canBeOpen}
          anchorEl={anchorProjectDropdown}
          $themeColor={doxleThemeColor}
          disableScrollLock
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          onClose={handleCloseDropdown}
        >
          {showProjectList && (
            <ProjectDropdownList
              isFetchingProject={isFetchingProject}
              isSuccessFetchingProject={isSuccessFetchingProject}
              isErrorFetchingProject={isErrorFetchingProject}
              isFetchingNextPage={isFetchingNextPage}
              projectList={projectList}
              handleSelectProject={handleClickProjectItem}
              handleCloseProjectDropdown={handleCloseDropdown}
              selectedProject={selectedProject}
              dropdownWrapperStyle={dropdownWrapperStyle}
            />
          )}
        </StyledProjectSelectPopover>
      </>
    </ProjectSelectWithDropdownContext.Provider>
  );
};

export const useProjectSelectDropDownContext = () =>
  useContext(
    ProjectSelectWithDropdownContext
  ) as ProjectSelectWithDropdownContext;
export default ProjectSelectWithDropdown;
