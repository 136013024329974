import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import { AnimatePresence } from "framer-motion";
import SMSSelectedContactItem from "./SelectedContactItem";
import SMSContactPopoverList from "./SMSContactPopoverList";
import { StyledSMSFieldWrapper } from "./StyledSMSComponents";
import {useSMSBookingContext} from "../SMSBooking";
import useSMSAddMailToSection from "../Hooks/useAddMailToSection";

type Props = {};

const AddSMSToSection = (props: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }),
    shallow
  );

  const {
    handleClickContact,
    anchorContactListPopover,
    handleCloseContactList,
  } = useSMSAddMailToSection({});
  const {
    ballInCourt,
    setBallInCourt,
  } = useSMSBookingContext();

  return (
    <StyledSMSFieldWrapper
      $themeColor={doxleThemeColor}
      $doxleFont={doxleFont}
      layout
    >
      <span className="label-field"> To:</span>

      <div className="add-content-wrapper" onClick={handleClickContact}>
        <AnimatePresence>
          {ballInCourt &&
            <SMSSelectedContactItem
              item={ballInCourt}
              onRemoveItem={() => setBallInCourt(undefined)}
              key={ballInCourt.contactId}
              sms
            />
          }
        </AnimatePresence>
      </div>

      <SMSContactPopoverList
        anchorContactListPopover={anchorContactListPopover}
        handleCloseContactList={handleCloseContactList}
        selectedItem={ballInCourt}
        onSelectItem={setBallInCourt}
        closeOnSelect={false}
      />
    </StyledSMSFieldWrapper>
  );
};

export default AddSMSToSection;
