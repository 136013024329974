import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { DoxleIconPlain } from "../../../DoxleDesignPattern/DoxleGeneralIcons";
import { IoChevronDownOutline } from "react-icons/io5";
import useNavBarEllipsis from "./Hooks/useNavBarEllipsis";
import {
  StyledMainMenuPopover,
  StyledMainPopoverArrow,
  StyledNavBarEllipsisBtn,
} from "./StyledNavBarEllipsis";

import {
  bindFocus,
  bindTrigger,
  usePopupState,
} from "material-ui-popup-state/hooks";

import NavMenuItem from "./NavMenuItem";
import CompanySubMenu from "./CompanySubMenu";
import DoxleNestedPopover from "../../../DoxleDesignPattern/DoxleNestedPopover";
import NavFontSubMenu from "./NavFontSubMenu";

export interface INavBarProps {}

export default function NavBarEllipsis(props: INavBarProps) {
  const { doxleThemeColor, staticMenuColor } = useDoxleThemeStore((state) => ({
    doxleThemeColor: state.doxleThemeColor,
    staticMenuColor: state.staticMenuColor,
  }));
  const { handlePressMenuItem, MAIN_MENU } = useNavBarEllipsis({});

  const popupState = usePopupState({
    variant: "popover",
    popupId: "root-menu-doxle",
  });

  return (
    <>
      <StyledNavBarEllipsisBtn
        {...bindTrigger(popupState)}
        {...bindFocus(popupState)}
        // onClick={handleClick}
      >
        <DoxleIconPlain themeColor={doxleThemeColor} />
        <IoChevronDownOutline
          className="icon-dropdown"
          color={staticMenuColor.staticFontColor}
          size={14}
        />
      </StyledNavBarEllipsisBtn>

      <DoxleNestedPopover
        sx={{
          "& .MuiPopover-paper": {
            backgroundColor: "transparent",
            overflow: "visible",
            boxShadow: "none",
            marginTop: "8px",
          },
        }}
        elevation={4}
        popupState={popupState}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        keepMounted={false}
      >
        <>
          <StyledMainPopoverArrow />

          <StyledMainMenuPopover>
            <CompanySubMenu popupId="company-select-hover-list" />
            {MAIN_MENU.map((menu, idx) =>
              menu !== "Font" ? (
                <NavMenuItem
                  key={`app_menu#${idx}`}
                  menu={menu}
                  handlePressMenuItem={handlePressMenuItem}
                />
              ) : (
                <NavFontSubMenu
                  key={`app_menu#${idx}`}
                  popupId="font-select-hover-list"
                />
              )
            )}
          </StyledMainMenuPopover>
        </>
      </DoxleNestedPopover>
    </>
  );
}
