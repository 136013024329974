import React from "react";
import useBookingCalendarStore from "../../store/useBookingCalendarStore";
import { useShallow } from "zustand/react/shallow";
import { StyledTopMenuCalendar } from "./StyledBookingCalendar";
import DoxleIconButton from "../../../DoxleDesignPattern/DoxleButtons/DoxleIconButton";
import { FaAngleLeft } from "react-icons/fa6";
import { FaAngleRight } from "react-icons/fa6";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { editRgbaAlpha } from "../../../Utilities/FunctionUtilities";
import BookingCalendarFilter from "./BookingCalendarFilter";
import dayjs from "dayjs";
import DoxleFilterTag from "../../../DoxleDesignPattern/DoxleFilterTag/DoxleFilterTag";
import { AnimatePresence } from "framer-motion";
type Props = {};

const TopMenuCalendar = (props: Props) => {
  const { doxleThemeColor } = useDoxleThemeStore();
  const {
    currentWeek,
    goBackWeek,
    goForwardWeek,
    filterBookingPeriod,
    setFilterBookingPeriod,
  } = useBookingCalendarStore(
    useShallow((state) => ({
      currentWeek: state.currentWeek,
      goBackWeek: state.goBackWeek,
      goForwardWeek: state.goForwardWeek,
      filterBookingPeriod: state.filterBookingPeriod,
      setFilterBookingPeriod: state.setFilterBookingPeriod,
    }))
  );
  const currentMonthYear = `${currentWeek[0].toLocaleString("default", {
    month: "short",
  })} ${currentWeek[0].getFullYear()}`;

  const dateFilterText = filterBookingPeriod
    ? typeof filterBookingPeriod === "string"
      ? `Next ${filterBookingPeriod === "2weeks" ? 2 : 4} weeks`
      : `
  ${dayjs(filterBookingPeriod.start).format("DD MMM YY")} - ${dayjs(
          filterBookingPeriod.end
        ).format("DD MMM YY")}
  `
    : "";
  return (
    <StyledTopMenuCalendar>
      <div className="period-title-text">{currentMonthYear}</div>

      <div className="menu-wrapper">
        <AnimatePresence>
          {filterBookingPeriod && (
            <DoxleFilterTag
              itemData={filterBookingPeriod}
              itemDisplayText={dateFilterText}
              removable={true}
              removeItemFunction={() => {
                setFilterBookingPeriod(undefined);
              }}
              tagStyle={{
                fontSize: 16,
                alignSelf: "center",
                marginRight: 30,
              }}
              removeIconStyle={{
                width: 30,
              }}
            />
          )}
        </AnimatePresence>
        <BookingCalendarFilter />
        <DoxleIconButton
          onClick={goBackWeek}
          iconSource={
            <FaAngleLeft
              color={doxleThemeColor.primaryContainerColor}
              size={20}
            />
          }
          buttonSize={30}
          sx={{
            backgroundColor: doxleThemeColor.primaryFontColor,
            padding: "0 !important",
            "&:hover": {
              backgroundColor: `${editRgbaAlpha({
                rgbaColor: doxleThemeColor.primaryFontColor,
                alpha: "0.7",
              })}!important`,
              transition: "all 0.4s ease",
            },
          }}
        />
        <DoxleIconButton
          onClick={goForwardWeek}
          iconSource={
            <FaAngleRight
              color={doxleThemeColor.primaryContainerColor}
              size={20}
            />
          }
          buttonSize={30}
          sx={{
            backgroundColor: doxleThemeColor.primaryFontColor,
            padding: "0 !important",
            "&:hover": {
              backgroundColor: `${editRgbaAlpha({
                rgbaColor: doxleThemeColor.primaryFontColor,
                alpha: "0.7",
              })}!important`,
              transition: "all 0.4s ease",
            },
          }}
        />
      </div>
    </StyledTopMenuCalendar>
  );
};

export default TopMenuCalendar;
