import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import {
  StyledBannerText,
  StyledDoxleLoginScreenTest,
  StyledHeaderLeft,
  StyledHeaderRight,
  StyledLinkText,
  StyledLoginButton,
  StyledLoginField,
  StyledLoginHeader,
  StyledPromptError,
} from "./styles/StyledDoxleLoginScreenTest";
import {
  DoxleMainIcon,
  HeaderIcon,
} from "../../../DoxleDesignPattern/Doxle404Screen/Doxle404ScreenIcons";

import useLoginScreen from "../Hooks/useLoginScreen";
import { CircularProgress } from "@mui/material";
import { AnimatePresence } from "framer-motion";
import { shallow } from "zustand/shallow";

type Props = {};

const DoxleLoginScreen = ({}: Props) => {
  const doxleThemeColor = useDoxleThemeStore(
    (state) => state.doxleThemeColor,
    shallow
  );

  const {
    isLoading,
    email,
    password,
    prompt,
    loginMode,
    setEmail,
    setLoginMode,
    setPassword,
    handleSubmit,
    handleKeyDown,
  } = useLoginScreen();

  return (
    <StyledDoxleLoginScreenTest>
      <StyledLoginHeader>
        <StyledHeaderRight>
          <HeaderIcon
            themeColor={doxleThemeColor}
            containerStyle={{ width: 32 }}
          />
          <p className="text"> Doxle Login</p>
        </StyledHeaderRight>
        <StyledHeaderLeft>login.doxle.com</StyledHeaderLeft>
      </StyledLoginHeader>
      <DoxleMainIcon themeColor={doxleThemeColor}></DoxleMainIcon>
      <StyledBannerText>
        {loginMode === "email"
          ? "Sign in to Doxle"
          : loginMode === "link"
          ? "Magic Link"
          : "Reset password"}
      </StyledBannerText>

      <AnimatePresence>
        {prompt[0] && (
          <StyledPromptError
            animate={{
              x: [-10, 0],
              opacity: [0, 1],
            }}
            exit={{
              x: [0, -10],
              opacity: [1, 0],
            }}
          >
            {prompt[0]}
          </StyledPromptError>
        )}
      </AnimatePresence>
      <StyledLoginField
        placeholder="Email"
        variant="outlined"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        onKeyDown={handleKeyDown}
        disabled={isLoading}
      />
      {loginMode === "email" && (
        <>
          <AnimatePresence>
            {prompt[1] && (
              <StyledPromptError
                animate={{
                  x: [-10, 0],
                  opacity: [0, 1],
                }}
                exit={{
                  x: [0, -10],
                  opacity: [1, 0],
                }}
              >
                {prompt[1]}
              </StyledPromptError>
            )}
          </AnimatePresence>
          <StyledLoginField
            variant="outlined"
            placeholder="Password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            onKeyDown={handleKeyDown}
            disabled={isLoading}
          ></StyledLoginField>
        </>
      )}
      {isLoading && (
        <CircularProgress
          size={20}
          style={{ color: doxleThemeColor.primaryFontColor, marginTop: 14 }}
        />
      )}
      <StyledLoginButton
        onClick={handleSubmit}
        layout="position"
        transition={{
          duration: 0.4,
        }}
      >
        {" "}
        Log in{" "}
      </StyledLoginButton>
      <p>
        {loginMode === "email" && (
          <>
            <StyledLinkText onClick={() => setLoginMode("link")}>
              Login via magic link
            </StyledLinkText>
            <span> | </span>
            <StyledLinkText onClick={() => setLoginMode("reset")}>
              Forgot password
            </StyledLinkText>
          </>
        )}
        {loginMode === "link" && (
          <>
            <StyledLinkText onClick={() => setLoginMode("email")}>
              Login with Password
            </StyledLinkText>
            <span> | </span>
            <StyledLinkText onClick={() => setLoginMode("reset")}>
              Forgot password
            </StyledLinkText>
          </>
        )}
        {loginMode === "reset" && (
          <>
            <StyledLinkText onClick={() => setLoginMode("email")}>
              Login with Password
            </StyledLinkText>
            <span> | </span>
            <StyledLinkText onClick={() => setLoginMode("link")}>
              Login via magic link
            </StyledLinkText>
          </>
        )}
      </p>
    </StyledDoxleLoginScreenTest>
  );
};
export default DoxleLoginScreen;
