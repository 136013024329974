import { motion } from "framer-motion";
import styled from "styled-components";
import {
  DoxleFont,
  DoxleThemeColor,
} from "../../../../DoxleGeneralStore/useDoxleThemeStore";
import { editRgbaAlpha } from "../../../../Utilities/FunctionUtilities";
import { MentionsInput } from "react-mentions";
import { ScreenDisplayMode } from "../../../store/useBookingViewStore";

export const StyledBookingDocketDetailsContainer = styled(motion.div)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  .empty-title-text {
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-style: normal;
    font-weight: 800;
    font-size: 2.3rem;
    color: ${(p) => p.$themeColor.primaryFontColor};
    margin: 1.4rem 0px;
  }

  .empty-subtitle-text {
    font-family: ${(p) => p.$doxleFont.secondaryTitleFont};
    font-style: normal;
    font-weight: 400;
    font-size: 1.2rem;
    color: ${(p) => p.$themeColor.primaryFontColor};
  }

  .docket-content-wrapper {
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
`;

export const StyledDocketDetailMenu = styled.div<{
  $themeColor: DoxleThemeColor;
}>`
  width: 100%;

  display: flex;
  align-items: center;
  border-bottom: 1px solid ${(p) => p.$themeColor.primaryDividerColor};
`;

export const StyledDocketDetailMenuItem = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
  $selected: boolean;
}>`
  font-family: ${(p) => p.$doxleFont.primaryFont};
  font-style: normal;
  font-weight: ${(p) => (p.$selected ? 600 : 400)};
  font-size: 1.4rem;
  color: ${(p) => p.$themeColor.primaryFontColor};
  padding: 4px 8px;
  cursor: pointer;

  position: relative;
  display: flex;
  align-items: center;
  text-transform: capitalize;

  transition: 0.4s;
  &:hover {
    transition: 0.4s;
    transform: translateY(-2px);
  }
  .selected-effect {
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    z-index: 1;
    height: 1px;
    background-color: ${(p) => p.$themeColor.doxleColor};
  }
`;

export const StyledBookingDocketOrderContainer = styled(motion.div)`
  width: 100%;
  height: calc(100% - 3rem);
  display: flex;
  padding-bottom: 3rem;
  flex-direction: column;

  .order-content-wrapper {
    width: 100%;
    flex: 1;
    min-height: 0px;
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
  }
`;

export const StyledDocketOrderTitleSection = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  width: 100%;
  display: flex;
  align-items: center;
  margin: 14px 0px;

  .order-title-text {
    font-family: ${(p) => p.$doxleFont.secondaryTitleFont};
    font-style: normal;
    font-weight: 500;
    font-size: 2.1rem;
    color: ${(p) => p.$themeColor.primaryFontColor};
    margin: 0px 4px;
  }

  .icon-style {
    transition: 0.2s;
    margin-left: 4px;
    cursor: pointer;
    &:hover {
      transform: scale(1.1);
      transition: 0.2s;
      opacity: 0.8;
    }
  }
`;
export const StyledOrderRowWrapper = styled(motion.div)<{
  $themeColor: DoxleThemeColor;
}>`
  width: calc(100% - 1.6rem);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.8rem;
  height: 3rem;
  border-bottom: 1px solid ${(p) => p.$themeColor.primaryDividerColor};
  cursor: pointer;
  transition: 0.4s;
  &:hover {
    transition: 0.4s;
    background-color: ${(p) => p.$themeColor.rowHoverColor};
  }

  .order-num-wrapper {
    width: 10rem;
  }
`;

export const StyledOrderRowContentText = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
  $variants: "lineCost" | "contractor";
}>`
  font-family: ${(p) => p.$doxleFont.primaryFont};
  font-style: normal;
  font-weight: ${(p) => (p.$variants === "contractor" ? "500" : "600")};
  font-size: 1.4rem;
  color: ${(p) => p.$themeColor.primaryFontColor};
  letter-spacing: -0.026rem;

  ${(p) =>
    p.$variants === "lineCost"
      ? `width: 10rem;text-align:left;`
      : "flex:1;text-align:center"}
`;

export const StyledEmptyOrderScreen = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .empty-title-text {
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-style: normal;
    font-weight: 800;
    font-size: 2.3rem;
    color: ${(p) => p.$themeColor.primaryFontColor};
    margin: 1.4rem 0px;
  }

  .empty-subtitle-text {
    font-family: ${(p) => p.$doxleFont.secondaryTitleFont};
    font-style: normal;
    font-weight: 400;
    font-size: 1.2rem;
    color: ${(p) => p.$themeColor.primaryFontColor};
  }
`;

export const StyledSelectedOrderViewContainer = styled(motion.div)<{
  $wrapperHeight: number;
  $displayMode: ScreenDisplayMode;
}>`
  width: 100%;
  /* height: ${(p) => p.$wrapperHeight}px; */
  /* height: ${(p) =>
    p.$displayMode === "Landscape" ? p.$wrapperHeight + "px" : "100%"}; */
  /* overflow: auto; */
  .space-between-wrapper {
    width: 90%;
    display: flex;
    justify-content: space-between;
  }
`;

export const StyledOrderDetailFieldWrapper = styled(motion.div)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  width: 100%;
  display: flex;
  margin-bottom: 1.4rem;
  flex-direction: column;

  .label-text {
    font-family: ${(p) => p.$doxleFont.primaryTitleFont};
    font-style: normal;
    font-weight: 500;
    font-size: 1.2rem;
    text-transform: uppercase;
    color: ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.$themeColor.primaryFontColor,
        alpha: "0.7",
      })};
    letter-spacing: 0.06rem;
    margin-bottom: 2px;
    margin-left: 8px;
  }
`;
export const StyledOrderDetailContent = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  border: 1px solid ${(p) => p.$themeColor.primaryDividerColor};
  background-color: ${(p) => p.$themeColor.primaryContainerColor};
  align-self: flex-start;
  padding: 8px;
  min-width: 5rem;
  border-radius: 0px 4px 4px 4px;
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  align-items: center;
  justify-content: center;
  font-family: ${(p) => p.$doxleFont.primaryFont};
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;

  color: ${(p) => p.$themeColor.primaryFontColor};
  letter-spacing: 0.065rem;
`;

export const StyledOrderBudgetDisplayer = styled.div`
  width: 100%;
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  padding-top: 20px;
`;

export const StyledOrderBudgetFieldWrapper = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  display: flex;
  align-self: flex-end;
  flex-direction: column;
  padding: 4px 10px 4px 14px;
  border-bottom: 1px solid ${(p) => p.$themeColor.primaryDividerColor};
  .row-content-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 4px;

    .budget-label-text {
      font-family: ${(p) => p.$doxleFont.primaryFont};
      font-style: normal;
      font-weight: 400;
      font-size: 1.2rem;
      color: ${(p) => p.$themeColor.primaryFontColor};
      text-transform: uppercase;
      width: 6rem;

      text-align: right;
    }

    .budget-content-text {
      font-family: ${(p) => p.$doxleFont.primaryFont};
      font-style: normal;
      font-weight: 600;
      font-size: 1.4rem;
      color: ${(p) => p.$themeColor.primaryFontColor};
      width: 12rem;
      text-align: right;
    }
  }
`;
export const StyledViewOrderLineSection = styled.div<{
  $themeColor: DoxleThemeColor;
}>`
  display: flex;
  flex-direction: column;
  padding-top: 4px;
  margin-bottom: 20px;
  border-top: 1px solid ${(p) => p.$themeColor.primaryDividerColor};
  align-items: center;
  justify-content: center;
`;

export const StyledOrderLineListHeaderContainer = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  width: 100%;
  margin-bottom: 2px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${(p) =>
    editRgbaAlpha({
      rgbaColor: p.$themeColor.doxleColor,
      alpha: "0.1",
    })};
  padding: 8px 0px;

  .header-cell-text {
    font-family: ${(p) => p.$doxleFont.secondaryTitleFont};
    font-style: normal;
    font-weight: 500;
    font-size: 1.4rem;
    color: ${(p) => p.$themeColor.primaryFontColor};
    text-transform: capitalize;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;
export const StyledOrderLineItemContainer = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  width: 100%;
  display: flex;
  flex-direction: row;
  background-color: ${(p) => p.$themeColor.primaryContainerColor};
  border-top: 1px solid
    ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.$themeColor.primaryDividerColor,
        alpha: "0.6",
      })};
  align-items: center;
  height: 5rem;
`;

export const StyledOrderLineCellData = styled.div<{
  $hasBorderRight: boolean;
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
  $cellWidth?: number;
  $horizontalAlign?: "flex-start" | "center" | "flex-end";
}>`
  padding: 4px;
  border-right: ${(p) => (p.$hasBorderRight ? 1 : 0)}px solid
    ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.$themeColor.primaryDividerColor,
        alpha: "0.6",
      })};

  display: flex;

  flex-direction: row;
  align-items: center;
  justify-content: ${(p) => p.$horizontalAlign ?? "center"};
  height: calc(100% - 8px);
  ${(p) => (p.$cellWidth ? `width: calc(${p.$cellWidth}% - 9px);` : "flex:1;")}
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  .data-text {
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-style: normal;
    font-weight: 500;
    font-size: 1.4rem;
    color: ${(p) => p.$themeColor.primaryFontColor};
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const StyledBookingDocketCommentContainer = styled(motion.div)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .comment-list-wrapper {
    flex: 1;
    display: flex;
    width: 100%;
    padding-bottom: 1.4rem;
    position: relative;
    /* height: 100%; */
  }
`;
export const StyledDiscussionRowWrapper = styled(motion.div)`
  width: 100%;
  padding-bottom: 3rem;
`;

export const StyledDiscussionListHeader = styled(motion.div)`
  width: 100%;
  padding: 1rem 0px;
  display: flex;
  justify-content: center;
`;
export const StyledDiscussionItemContainer = styled(motion.div)<{
  $horizontalAlign: "flex-start" | "flex-end";
  $themeColor: DoxleThemeColor;
}>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: ${(p) => p.$horizontalAlign};

  background-color: ${(p) => p.$themeColor.primaryContainerColor};
  border-radius: 0px 1.2rem;
  padding: 0.8rem 0px;
`;

export const StyledCommentAuthorInfoContainer = styled.div<{
  $themeColor: DoxleThemeColor;
}>`
  width: calc(100% - 2.8rem);
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.8rem;
  border-bottom: 1px solid ${(p) => p.$themeColor.doxleColor};
  padding: 0.4rem 1.4rem;
`;

export const StyledCommentAuthorText = styled.span<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
  $isAuthor: boolean;
}>`
  color: ${(p) => p.$themeColor.primaryFontColor};
  font-size: 1.4rem;
  font-family: ${(p) => p.$doxleFont.primaryTitleFont};
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  text-transform: capitalize;
`;

export const StyledDiscussionTimestampText = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  color: ${(p) => p.$themeColor.doxleColor};
  font-size: 1.4rem;
  font-family: ${(p) => p.$doxleFont.robotoMonoFont};
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const StyledCommentContentText = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  color: ${(p) => p.$themeColor.primaryFontColor};
  font-size: 1.4rem;
  font-family: ${(p) => p.$doxleFont.primaryFont};
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.24px;
  white-space: pre-wrap;
  padding: 0px 1.4rem;
  width: calc(100% - 2.8rem);
`;

export const StyledDiscussionAttachmentDisplayerContainer = styled.div<{
  $themeColor: DoxleThemeColor;
}>`
  position: relative;
  display: flex;
  flex-direction: column;

  width: calc(100% - 2.8rem);
  overflow: hidden;
  padding: 0px 1.4rem;
  border-radius: 1.2rem;
  cursor: pointer;
  .attachment-list-wrapper {
    max-width: 80%;
    display: flex;
    flex-direction: row;
    margin-bottom: 1.4rem;
    flex-wrap: wrap;
    border-radius: 0px 0.9rem;
  }
`;
export const StyledDiscussionAttachmentWrapper = styled.div`
  width: 8rem;
  aspect-ratio: 1;
  display: flex;
  border-radius: 0.4rem;
  margin: 0.2rem;
  position: relative;
`;

export const StyledCommentAttachmentImage = styled.img`
  height: 100%;
  width: 100%;
`;
export const StyledFilePickerDisplayerContainer = styled(motion.div)`
  width: calc(100% - 4rem);
  margin: 0px 2rem 0.8rem 2rem;

  align-items: center;

  display: flex;

  flex-direction: row;
  flex-wrap: wrap;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;
export const StyledFileItemContainer = styled(motion.div)`
  height: 10rem;
  aspect-ratio: 1;
  border-radius: 0.4rem;
  position: relative;
  display: inline-block;
  justify-content: center;
  align-items: center;
  margin: 0.4rem;
`;
export const StyledFileImage = styled.img<{
  $isImgFile?: boolean;
}>`
  width: 100%;
  border-radius: 4px;
`;

export const StyledEditDocketCommentInputSectionContainer = styled(
  motion.div
)<{}>`
  width: calc(100% - 30px);
  display: flex;

  align-items: center;

  border-radius: 8px;
  background-color: ${(p) =>
    editRgbaAlpha({ rgbaColor: p.theme.color.doxleColor, alpha: "0.05" })};
  border: 1px solid ${(p) => p.theme.color.primaryDividerColor};
  padding: 14px;

  .input-wrapper {
    flex: 1;
    display: flex;

    align-items: center;
    min-height: 20px;
    align-items: center;
    max-height: 120px;
    overflow: hidden;
  }
`;

export const StyledCommentTextField = styled(MentionsInput)<{}>`
  textarea:focus,
  input:focus {
    outline: none;
  }
  input {
    border: none !important;
    border-width: 0px;

    max-height: 144px;
    min-height: 20px;
  }
  textarea {
    border: none !important;
    border-width: 0px;
    height: fit-content;
    max-height: 144px;
    min-height: 20px;
  }
`;

export const StyledDiscussionMention = styled(motion.div)<{
  $themeColor: DoxleThemeColor;
}>`
  height: 204px;
  width: 200px;
  position: relative;
  z-index: 10;
  background-color: ${(p) => p.$themeColor.primaryContainerColor};
  top: 0px;
  overflow-y: scroll;
  border-radius: 4px 4px 0px 0px;
  box-shadow: 0px 0px 4px 4px ${(p) => p.$themeColor.primaryBoxShadowColor};
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${(p) => p.$themeColor.primaryFontColor};
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;
