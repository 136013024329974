import React, { useState } from "react";

type Props = {};

const useQRUserList = () => {
  const [searchText, setSearchText] = useState("");

  return {
    searchText,
    setSearchText,
  };
};

export default useQRUserList;
