import React from "react";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import { IoDocumentOutline } from "react-icons/io5";
import { editRgbaAlpha } from "../../Utilities/FunctionUtilities";
import { Docket } from "../../Models/dockets";
import { StyledDocketNumberContainer } from "./styledComponents";
import { IconBaseProps } from "react-icons";
type Props = {
  docket: Docket;
  handleOpenBudgetMenu: (
    event: React.MouseEvent<HTMLDivElement>,
    item: Docket
  ) => void;
  containerStyle?: React.CSSProperties;
  iconProps?: IconBaseProps;
  docketNumberStyle?: React.CSSProperties;
};

const DoxleDocketNumber = ({
  docket,
  handleOpenBudgetMenu,
  containerStyle,
  iconProps,
  docketNumberStyle,
}: Props) => {
  const { doxleThemeColor, doxleFont, currentTheme } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
      currentTheme: state.currentTheme,
    }),
    shallow
  );
  return (
    <StyledDocketNumberContainer
      $themeColor={doxleThemeColor}
      $doxleFont={doxleFont}
      onClick={(event) => handleOpenBudgetMenu(event, docket)}
      style={{ ...containerStyle }}
    >
      <IoDocumentOutline
        fontSize="2.5rem"
        color={editRgbaAlpha({
          rgbaColor: doxleThemeColor.primaryFontColor,
          alpha: "0.7",
        })}
        {...iconProps}
      />

      <div className="docket-number-text" style={{ ...docketNumberStyle }}>
        {docket.docketIdNum}
      </div>
    </StyledDocketNumberContainer>
  );
};

export default DoxleDocketNumber;
