import { BaseAPIProps } from "../../Models/baseAPIProps";
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from "@tanstack/react-query";
import DoxleAPI from "../../Services/DoxleAPI";
import OrderFilters from "../Models/orderFilters";
import Order, {
  NewOrder,
  OrderLine,
  OrderLinkReturn,
  OrderStatus,
} from "../Models/order";
import {
  AxiosBackendErrorReturn,
  IApiPaginatedData,
} from "../../Models/axiosReturn";
import { Company } from "../../Models/company";
import useSetOrderQueryData from "../Hooks/useSetOrderQueryData";
import DocketQuery from "../../Services/QueryHooks/docketQueryAPI";
import { AxiosError, AxiosResponse, isAxiosError } from "axios";
import { useDoxleCurrentContextStore } from "../../DoxleGeneralStore/useDoxleCurrentContext";
import useDoxleNotificationStore from "../../DoxleGeneralStore/useDoxleNotificationStore";
import { shallow } from "zustand/shallow";
import { baseQKeyMailList } from "../../Mail/Hooks/mailAPI";
import useSetStatusQueryData from "../../CoreContent/CompanySettingsScreen/Hooks/useSetStatusQueryData";
import {
  baseProjectTotalQKey,
  getProjectTotalQKey,
} from "../../Services/QueryHooks/projectQueryAPI";
import {
  baseStageQKey,
  getStageListQueryKey,
} from "../../Services/QueryHooks/stageQueryAPI";

interface InfiniteOrderQueryProps extends BaseAPIProps {
  filter: OrderFilters;
  enabled: boolean;
}
const useRetrieveOrderList = ({
  filter,
  company,
  showNotification,
  enabled,
}: InfiniteOrderQueryProps) => {
  if (filter.search === "") delete filter.search;
  let qKey = getOrderListQKey(filter, company);
  let docketURL = `/orders/?page=1`;
  return useInfiniteQuery(
    qKey,
    ({ pageParam = docketURL }) =>
      DoxleAPI.get<IApiPaginatedData<Order>>(pageParam, {
        headers: {
          "User-Company": company!.companyId,
        },
        params: filter,
      }),
    {
      enabled: Boolean(company && enabled),
      retry: 1,
      cacheTime: 1 * 60 * 1000,
      staleTime: 0,
      refetchInterval: 1 * 60 * 1000,
      refetchIntervalInBackground: true,
      refetchOnWindowFocus: false,
      getNextPageParam: (prevData) => prevData.data?.next,
      onError: (error) => {
        if (showNotification) {
          if (isAxiosError<AxiosBackendErrorReturn>(error)) {
            showNotification(
              `${error?.response?.status ?? "ERROR"}: ${
                error.response?.data.detail ?? "UNKNOWN ERROR"
              }`,
              "error",
              String(
                error?.response?.data?.detail ?? "FAILED TO GET ORDER LIST"
              ).substring(0, 300)
            );
          } else {
            showNotification(
              "Something Wrong!",
              "error",
              "FAILED TO GET ORDER LIST"
            );
          }
        }
      },
    }
  );
};

interface RetrieveOrderDetailProps extends BaseAPIProps {
  orderId: string | undefined;
  onSuccessCb?: (order?: Order) => void;
  enabled: boolean;
}
const useRetrieveOrderDetail = ({
  orderId,

  company,
  showNotification,
  enabled,
  onSuccessCb,
}: RetrieveOrderDetailProps) => {
  const qKey = getOrderDetailQKey(orderId, company);
  let docketURL = `/orders/${orderId}/`;

  return useQuery(
    qKey,
    () =>
      DoxleAPI.get<Order>(docketURL, {
        headers: {
          "User-Company": company!.companyId,
        },
      }),
    {
      enabled: Boolean(company && orderId && enabled),
      retry: 1,
      refetchInterval: false,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      retryOnMount: false,
      staleTime: 0,
      cacheTime: 0,
      onError: (error) => {
        if (showNotification) {
          if (isAxiosError<AxiosBackendErrorReturn>(error)) {
            showNotification(
              `${error?.response?.status ?? "ERROR"}: ${
                error.response?.data.detail ?? "UNKNOWN ERROR"
              }`,
              "error",
              String(
                error?.response?.data?.detail ?? "FAILED TO GET ORDER DETAIL"
              ).substring(0, 300)
            );
          } else {
            showNotification(
              "Something Wrong!",
              "error",
              "FAILED TO GET ORDER DETAIL"
            );
          }
        }
      },
      onSuccess: (response) => {
        if (onSuccessCb) onSuccessCb(response.data);
      },
    }
  );
};
interface RetrieveOrderPdfProps extends BaseAPIProps {
  orderId: string | undefined;
  onSuccessCb?: (url?: string) => void;
}

const useRetrieveOrderPDF = ({
  orderId,
  company,
  showNotification,
  onSuccessCb,
}: RetrieveOrderPdfProps) => {
  const qKey = getOrderDetailQKey(orderId, company);
  let docketURL = `/orders/${orderId}/pdf/`;

  return useMutation(
    qKey,
    () =>
      DoxleAPI.get(docketURL, {
        headers: {
          "User-Company": company!.companyId,
        },
      }),
    {
      retry: 1,
      onError: (error) => {
        if (showNotification) {
          if (isAxiosError<AxiosBackendErrorReturn>(error)) {
            showNotification(
              `${error?.response?.status ?? "ERROR"}: ${
                error.response?.data.detail ?? "UNKNOWN ERROR"
              }`,
              "error",
              String(
                error?.response?.data?.detail ?? "FAILED TO GET ORDER FILE"
              ).substring(0, 300)
            );
          } else {
            showNotification(
              "Something Wrong!",
              "error",
              "FAILED TO GET ORDER FILE"
            );
          }
        }
      },
      onSuccess: (response: AxiosResponse<any>) => {
        console.log("PDF RESPONSE", response);
        if (onSuccessCb) onSuccessCb(response.data.url);
      },
    }
  );
};

interface OrderStatusQueryProps extends BaseAPIProps {
  view?: "Orders" | "Invoices";
  onSuccessCb?: (list: OrderStatus[]) => void;
}

const useRetrieveOrderStatusList = ({
  company,
  showNotification,
  view,
  onSuccessCb,
}: OrderStatusQueryProps) => {
  const qKey = ["order-status-list", company?.companyId ?? ""];
  if (view) qKey.push(view);
  const params: any = {};
  if (view === "Orders") params.is_order_status = true;
  if (view === "Invoices") params.is_invoice_status = true;
  const docketURL = `/orders/status/`;
  return useQuery(
    qKey,
    ({ pageParam = docketURL }) =>
      DoxleAPI.get<OrderStatus[]>(pageParam, {
        headers: {
          "User-Company": company!.companyId,
        },
        params,
      }),
    {
      enabled: Boolean(company),
      retry: 1,
      refetchInterval: 5 * 60 * 1000,
      staleTime: 5 * 60 * 1000,
      cacheTime: 6 * 60 * 1000,
      refetchIntervalInBackground: true,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      onSuccess: (res) => {
        if (onSuccessCb) onSuccessCb(res.data);
      },
      onError: (error) => {
        if (showNotification) {
          if (isAxiosError<AxiosBackendErrorReturn>(error)) {
            showNotification(
              `${error?.response?.status ?? "ERROR"}: ${
                error.response?.data.detail ?? "UNKNOWN ERROR"
              }`,
              "error",
              String(
                error?.response?.data?.detail ??
                  "FAILED TO GET ORDER STATUS LIST"
              ).substring(0, 300)
            );
          } else {
            showNotification(
              "Something Wrong!",
              "error",
              "FAILED TO GET ORDER STATUS LIST"
            );
          }
        }
      },
    }
  );
};

interface MutateOrderQueryProps extends BaseAPIProps {
  filter: OrderFilters;
  onAddSuccessCb?: (result?: Order) => void;
  onUpdateSuccessCb?: (result?: Order) => void;
  onUpdateManySuccessCb?: (result?: Order[]) => void;
  onRemoveSuccessCb?: (deletedId?: string[]) => void;
  onSuccessShareOrder?: (order?: Order) => void;
  onOverBudget?: () => void;
}
interface OrderWithAllowOverBudget extends Order {
  allowOverBudget: boolean;
}
export interface PatchOrderProps {
  orderId: string;
  order: Partial<OrderWithAllowOverBudget>;
}
export interface ShareOrderProps {
  orderId: string;
  toRecipients: string[];
  ccRecipients: string[];
  attachments: File[];
  subject: string;
  textBody: string;
}
export interface UpdateOrderSignOff {
  orderIds: string[];
  signed: boolean;
  docketPk?: string;
  projectId?: string;
}
const useMutateOrderQuery = ({
  filter,
  company,
  showNotification,
  onAddSuccessCb,
  onUpdateSuccessCb,
  onUpdateManySuccessCb,
  onRemoveSuccessCb,
  onSuccessShareOrder,
  onOverBudget,
}: MutateOrderQueryProps) => {
  const purchaseOrderURL = "/orders/";
  const queryClient = useQueryClient();
  const { handleUpdateOrder, handleAddOrder, handleUpdateMultiOrders } =
    useSetOrderQueryData({
      filter,
    });
  const updateDocket = DocketQuery.useUpdateDocketValues();
  const addOrder = useMutation(["add-order"], {
    mutationFn: async (body: NewOrder) => {
      return DoxleAPI.post<{ order: OrderWithAllowOverBudget }>(
        purchaseOrderURL,
        body,
        {
          headers: {
            "User-Company": company?.companyId!,
          },
        }
      );
    },
    onSuccess: (result, variables, context) => {
      handleAddOrder(result.data.order);
      if (onAddSuccessCb) onAddSuccessCb(result.data.order);
    },
    onError: (error: any, variables, context) => {
      console.log("error?.response", error?.response);
      if (error?.response?.status === 406 && onOverBudget) onOverBudget();
      if (showNotification)
        showNotification(
          `${error?.response?.status ?? "ERROR"}: ${
            error?.response?.statusText ?? "UNKNOWN ERROR"
          }`,
          "error",
          error?.response?.data.substring(0, 300) ?? "Error Adding Order"
        );
    },
    onSettled: (result, variables, context) => {
      // console.log(`[345] addOrder onSettled`)
      if (result?.data.order.project) {
        const totalsKey = getProjectTotalQKey(result?.data.order.project);
        const stagesKey = getStageListQueryKey({
          projectId: result?.data.order.project,
        });
        // console.log(`[349] stagesKey`, stagesKey)
        queryClient.refetchQueries({
          type: "all",
          predicate: (query) =>
            totalsKey.every((item) => query.queryKey.includes(item)),
        });
        queryClient.refetchQueries({
          type: "all",
          predicate: (query) =>
            stagesKey.every((item) => query.queryKey.includes(item)),
        });
      }
      if (result?.data.order.docket)
        updateDocket.mutate(result?.data.order.docket);
    },
  });

  const patchOrder = useMutation({
    mutationKey: getMutateOrderQKey("update"),
    mutationFn: async ({ orderId, order }: PatchOrderProps) => {
      return DoxleAPI.patch<Order>(purchaseOrderURL + orderId + "/", order, {
        headers: {
          "User-Company": company?.companyId!,
        },
      });
    },
    onSuccess: (result, variables, context) => {
      handleUpdateOrder(result.data);

      if (onUpdateSuccessCb) onUpdateSuccessCb(result.data);
    },
    onError: (error: any, variables, context) => {
      console.log("error?.response", error?.response);
      if (error?.response?.status === 406 && onOverBudget) onOverBudget();
      if (showNotification)
        showNotification(
          error?.response?.status === 406
            ? "Warning over budget"
            : "SOMETHING WRONG!",
          "error",
          "Fail To Update Purchase Order",
          error?.response?.status === 406 ? 1200 : undefined
        );
    },

    onSettled: (result, variables, context) => {
      // console.log('[395] patchOrder Settled');
      if (result) {
        if (result.data.project) {
          const totalsKey = getProjectTotalQKey(result?.data.project);
          const stagesKey = getStageListQueryKey({
            projectId: result?.data.project,
          });
          // console.log('[400] stagesKey', stagesKey);
          queryClient.refetchQueries({
            type: "all",
            predicate: (query) =>
              totalsKey.every((item) => query.queryKey.includes(item)),
          });
          queryClient.refetchQueries({
            type: "all",
            predicate: (query) =>
              stagesKey.every((item) => query.queryKey.includes(item)),
          });
        }

        if (result?.data.docket) updateDocket.mutate(result?.data.docket);
      }
    },
  });

  const updateOrderSignoff = useMutation({
    mutationKey: ["update-many-orders"],
    mutationFn: async ({ orderIds, signed }: UpdateOrderSignOff) => {
      return DoxleAPI.post<Order[]>(
        purchaseOrderURL + "update_orders/",
        {
          orderIds: orderIds,
          signed: signed,
        },
        {
          headers: {
            "User-Company": company?.companyId!,
          },
        }
      );
    },
    onSuccess: (result, variables, context) => {
      handleUpdateMultiOrders(result.data);
      if (onUpdateManySuccessCb) onUpdateManySuccessCb(result.data);
    },
    onError: (error, variables, context) => {
      if (showNotification) {
        if (isAxiosError<AxiosBackendErrorReturn>(error)) {
          showNotification(
            `${error?.response?.status ?? "ERROR"}: ${
              error.response?.data.detail ?? "UNKNOWN ERROR"
            }`,
            "error",
            String(
              error?.response?.data?.detail ?? "Fail To Update Order Signoff"
            ).substring(0, 300)
          );
        } else {
          showNotification(
            "Something Wrong!",
            "error",
            "Fail To Update Order Signoff"
          );
        }
      }
    },
  });
  interface DestroyOrder {
    orderId: string;
    docketPk: string | undefined;
    projectId: string | undefined;
  }
  const destroyOrder = useMutation({
    mutationFn: async ({ orderId, docketPk, projectId }: DestroyOrder) => {
      return DoxleAPI.delete(purchaseOrderURL + orderId + "/", {
        headers: {
          "User-Company": company?.companyId!,
        },
      });
    },
    onSuccess: (result, variables, context) => {
      if (onRemoveSuccessCb) onRemoveSuccessCb([variables.orderId]);
      const totalsKey = [...baseProjectTotalQKey];
      const stagesKey = [...baseStageQKey];
      if (variables.projectId) {
        totalsKey.push(variables.projectId);
        stagesKey.push(variables.projectId);
      }
      console.log("[481] destroyOrder", stagesKey);
      queryClient.invalidateQueries({ queryKey: totalsKey });
      queryClient.invalidateQueries({ queryKey: stagesKey });
      if (variables?.docketPk) updateDocket.mutate(variables?.docketPk);
      // if (showNotification)
      //   showNotification(
      //     "Purchase Order Remove",
      //     "success",
      //     "SUCCESSFULLY Removed Purchase Order"
      //   );
    },
    onError: (error: any, variables, context) => {
      if (showNotification) {
        if (isAxiosError<AxiosBackendErrorReturn>(error)) {
          showNotification(
            `${error?.response?.status ?? "ERROR"}: ${
              error.response?.data.detail ?? "UNKNOWN ERROR"
            }`,
            "error",
            String(error?.response?.data ?? "Error Deleting Order").substring(
              0,
              300
            )
          );
        } else {
          showNotification("Something Wrong!", "error", "Error Deleting Order");
        }
      }
    },

    onSettled: (result, variables, context) => {
      console.log("[512] destroyOrder settled");
      if (result) {
        if (variables?.projectId) {
          const totalsKey = getProjectTotalQKey(variables?.projectId);
          const stagesKey = getStageListQueryKey({
            projectId: variables?.projectId,
          });
          console.log("[517] stagesKey", stagesKey);
          queryClient.refetchQueries({
            type: "all",
            predicate: (query) =>
              totalsKey.every((item) => query.queryKey.includes(item)),
          });
          queryClient.refetchQueries({
            type: "all",
            predicate: (query) =>
              stagesKey.every((item) => query.queryKey.includes(item)),
          });
        }
        if (variables?.docketPk) updateDocket.mutate(variables?.docketPk);
      }
    },
  });

  interface DestroyMutipleOrders {
    orderIds: string[];
    docketPk: string | undefined;
    projectId: string | undefined;
  }
  const destroyMutipleOrders = useMutation({
    mutationFn: async ({
      orderIds,
      docketPk,
      projectId,
    }: DestroyMutipleOrders) => {
      return DoxleAPI.post(
        purchaseOrderURL + "delete_orders/",
        { order_ids: orderIds },
        {
          headers: {
            "User-Company": company?.companyId!,
          },
        }
      );
    },
    onSuccess: (result, variables, context) => {
      if (onRemoveSuccessCb) onRemoveSuccessCb(variables.orderIds);
      const totalsKey = [...baseProjectTotalQKey];
      const stagesKey = [...baseStageQKey];
      if (variables.projectId) {
        totalsKey.push(variables.projectId);
        stagesKey.push(variables.projectId);
      }
      console.log("[563]", stagesKey);
      queryClient.invalidateQueries({ queryKey: totalsKey });
      queryClient.invalidateQueries({ queryKey: stagesKey });
      if (variables?.docketPk) updateDocket.mutate(variables?.docketPk);
      // if (showNotification)
      //   showNotification(
      //     "Purchase Order Remove",
      //     "success",
      //     "SUCCESSFULLY Removed Purchase Order"
      //   );
    },
    onError: (error: any, variables, context) => {
      if (showNotification) {
        if (isAxiosError<AxiosBackendErrorReturn>(error)) {
          showNotification(
            `${error?.response?.status ?? "ERROR"}: ${
              error.response?.data.detail ?? "UNKNOWN ERROR"
            }`,
            "error",
            String(error?.response?.data ?? "Error Deleting Order").substring(
              0,
              300
            )
          );
        } else {
          showNotification("Something Wrong!", "error", "Error Deleting Order");
        }
      }
    },
  });

  const shareOrder = useMutation(["share-order"], {
    mutationFn: async (body: ShareOrderProps) => {
      let formData = new FormData();
      for (const [key, value] of Object.entries(body)) {
        if (typeof value === "string") formData.append(key, value);
        else if (Array.isArray(value))
          value.forEach((item, i) => formData.append(key, item));
      }
      return DoxleAPI.post(
        `${purchaseOrderURL}${body.orderId}/share/`,
        formData,
        {
          headers: {
            "User-Company": company?.companyId!,
            "Content-Type": "multipart/form-data",
          },
        }
      );
    },
    onSuccess: (result, variables, context) => {
      handleUpdateOrder(result.data);
      if (onSuccessShareOrder) onSuccessShareOrder(result.data);
      if (showNotification) showNotification("Purchase Order Sent", "success");
    },
    onError: (error: any, variables, context) => {
      if (showNotification) {
        if (isAxiosError<AxiosBackendErrorReturn>(error)) {
          showNotification(
            `${error?.response?.status ?? "ERROR"}: ${
              error.response?.data.detail ?? "UNKNOWN ERROR"
            }`,
            "error",
            String(error?.response?.data ?? "Error Sharing Order").substring(
              0,
              300
            )
          );
        } else {
          showNotification("Something Wrong!", "error", "Error Sharing Order");
        }
      }
    },
    onSettled: () => {
      //! reset all mail query
      queryClient.invalidateQueries(baseQKeyMailList);
    },
  });

  return {
    addOrder,
    patchOrder,
    updateOrderSignoff,
    destroyOrder,
    destroyMutipleOrders,
    shareOrder,
  };
};

//*-----> ORDER LINES <------
interface UpdateOrdeLineQueryProps {
  lineId: string;
  updateBody: Partial<OrderLine>;
}

export interface AxiosAddUpdateOrderLineReturn {
  line: OrderLine;
  order: Order;
}
export interface AxiosDeleteOrderLineReturn {
  updatedOrder: Order;
}
interface MutateOrderLineQueryProps
  extends Omit<InfiniteOrderQueryProps, "enabled"> {
  onUpdateSuccess?: (data?: AxiosAddUpdateOrderLineReturn) => void;
  onDeleteSuccess?: (data?: AxiosDeleteOrderLineReturn) => void;
  onAddSuccess?: (data?: AxiosAddUpdateOrderLineReturn) => void;
}
const useMutateOrderLineList = ({
  showNotification,

  company,
  filter,
  onUpdateSuccess,
  onDeleteSuccess,
  onAddSuccess,
}: MutateOrderLineQueryProps) => {
  const { handleUpdateOrder } = useSetOrderQueryData({ filter });
  const queryClient = useQueryClient();
  const updateDocket = DocketQuery.useUpdateDocketValues();
  const update = useMutation({
    mutationFn: async ({ lineId, updateBody }: UpdateOrdeLineQueryProps) => {
      return DoxleAPI.patch<AxiosAddUpdateOrderLineReturn>(
        "/orders/line/" + lineId + "/",
        updateBody,
        {
          headers: {
            "User-Company": company?.companyId ?? "",
          },
        }
      );
    },
    onSuccess: (result, variables, context) => {
      handleUpdateOrder(result.data.order);
      const totalsKey = [...baseProjectTotalQKey];
      const stagesKey = [...baseStageQKey];
      if (result.data.order.project) {
        totalsKey.push(result.data.order.project);
        stagesKey.push(result.data.order.project);
      }
      queryClient.invalidateQueries({ queryKey: totalsKey });
      queryClient.invalidateQueries({ queryKey: stagesKey });
      console.log("[705]", stagesKey);
      if (result.data.order.docket)
        updateDocket.mutate(result.data.order.docket);
      if (onUpdateSuccess) onUpdateSuccess(result.data);
      // if (showNotification)
      //   showNotification(
      //     'Item Updated',
      //     'success',
      //     'SUCCESSFULLY Updated Estimate',
      //   );
    },
    onError: (error, variables, context) => {
      if (showNotification) {
        if (isAxiosError<AxiosBackendErrorReturn>(error)) {
          showNotification(
            `${error?.response?.status ?? "ERROR"}: ${
              error.response?.data.detail ?? "UNKNOWN ERROR"
            }`,
            "error",
            String(error?.response?.data ?? "Fail To update Order").substring(
              0,
              300
            )
          );
        } else {
          showNotification("Something Wrong!", "error", "Fail To update Order");
        }
      }
    },
  });

  const add = useMutation({
    mutationFn: async (newLine: OrderLine) => {
      return DoxleAPI.post<AxiosAddUpdateOrderLineReturn>(
        "/orders/add_line/",
        newLine,
        {
          headers: {
            "User-Company": company?.companyId ?? "",
          },
        }
      );
    },
    onSuccess: (result, variables, context) => {
      handleUpdateOrder(result.data.order);
      const totalsKey = [...baseProjectTotalQKey];
      const stagesKey = [...baseStageQKey];
      if (result.data.order.project) {
        totalsKey.push(result.data.order.project);
        stagesKey.push(result.data.order.project);
      }
      queryClient.invalidateQueries({ queryKey: totalsKey });
      queryClient.invalidateQueries({ queryKey: stagesKey });
      console.log("[758]", stagesKey);
      if (result.data.order.docket)
        updateDocket.mutate(result.data.order.docket);
      // if (showNotification)
      //   showNotification(
      //     "Item Added",
      //     "success",
      //     "SUCCESSFULLY Updated Estimate"
      //   );
    },
    onError: (error, variables, context) => {
      if (showNotification) {
        if (isAxiosError<AxiosBackendErrorReturn>(error)) {
          showNotification(
            `${error?.response?.status ?? "ERROR"}: ${
              error.response?.data.detail ?? "UNKNOWN ERROR"
            }`,
            "error",
            String(error?.response?.data ?? "Fail To add Order").substring(
              0,
              300
            )
          );
        } else {
          showNotification("Something Wrong!", "error", "Fail To add Order");
        }
      }
    },
  });

  const destroy = useMutation({
    mutationFn: async (deletedId: string) => {
      return DoxleAPI.delete<AxiosDeleteOrderLineReturn>(
        "/orders/line/" + deletedId + "/",

        {
          headers: {
            "User-Company": company?.companyId ?? "",
          },
        }
      );
    },
    onSuccess: (result, variables, context) => {
      handleUpdateOrder(result.data.updatedOrder);
      if (onDeleteSuccess) onDeleteSuccess(result.data);
      const totalsKey = [...baseProjectTotalQKey];
      const stagesKey = [...baseStageQKey];
      if (result.data.updatedOrder.project) {
        totalsKey.push(result.data.updatedOrder.project);
        stagesKey.push(result.data.updatedOrder.project);
      }
      console.log("[809]", stagesKey);
      queryClient.invalidateQueries({ queryKey: totalsKey });
      queryClient.invalidateQueries({ queryKey: stagesKey });
      if (result.data.updatedOrder.docket)
        updateDocket.mutate(result.data.updatedOrder.docket);
      // if (showNotification)
      //   showNotification(
      //     "Item Deleted",
      //     "success",
      //     "SUCCESSFULLY Updated Estimate"
      //   );
    },
    onError: (error, variables, context) => {
      if (showNotification) {
        if (isAxiosError<AxiosBackendErrorReturn>(error)) {
          showNotification(
            `${error?.response?.status ?? "ERROR"}: ${
              error.response?.data.detail ?? "UNKNOWN ERROR"
            }`,
            "error",
            String(error?.response?.data ?? "Fail To delete Order").substring(
              0,
              300
            )
          );
        } else {
          showNotification("Something Wrong!", "error", "Fail To delete Order");
        }
      }
    },
  });

  const mutateUpdate = (data: UpdateOrdeLineQueryProps) => update.mutate(data);
  const mutateAdd = (newLine: OrderLine) => add.mutate(newLine);
  const mutateDelete = (deletedId: string) => destroy.mutate(deletedId);
  return {
    update: { ...update, mutate: mutateUpdate },
    add: { ...add, mutate: mutateAdd },
    destroy: { ...destroy, mutate: mutateDelete },
  };
};
//#HELPER FUNCTIONS -----
export const getOrderListQKey = (
  filter: OrderFilters,
  company: Company | undefined
) => {
  let qKey = ["order-list"];
  if (company) qKey.push(company.companyId);
  for (const [key, value] of Object.entries(filter)) {
    qKey.push(value);
  }

  return qKey;
};

export const getOrderDetailQKey = (
  orderId: string | undefined,
  company: Company | undefined
) => {
  const qKey = ["order-detail"];
  if (company) qKey.push(company.companyId);
  if (orderId) qKey.push(orderId);

  return qKey;
};
export const getMutateOrderQKey = (action: "add" | "update" | "delete") => {
  if (action === "add") return ["add-order"];
  else if (action === "update") return ["update-order"];
  else return ["delete-order"];
};

interface UpdateStatusProps {
  statusId: string;
  updateStatus: Omit<Partial<OrderStatus>, "statusId">;
}
const useMutateOrderStatus = () => {
  const companyId = useDoxleCurrentContextStore(
    (state) => state.currentCompany?.companyId,
    shallow
  );
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );
  const queryClient = useQueryClient();
  const updateStatus = useMutation(
    ["update-order-status", companyId],
    ({ statusId, updateStatus }: UpdateStatusProps) => {
      return DoxleAPI.patch<OrderStatus>(
        `/orders/status/${statusId}/`,
        updateStatus,
        { headers: { "User-Company": companyId ?? "" } }
      );
    },
    {
      retry: 1,
      onSuccess: (data, variables, context) => {
        queryClient.setQueryData<AxiosResponse<OrderStatus[]> | undefined>(
          ["order-status-list", companyId ?? ""],
          (old) => {
            if (!old || !old?.data) return old;
            return {
              ...old,
              data: [
                ...old.data.map((status) =>
                  status.statusId === data.data.statusId ? data.data : status
                ),
              ],
            };
          }
        );
        // queryClient.setQueryData<UseQueryResult<AxiosResponse<OrderStatus[]>>|undefined>(["order-status-list", companyId ?? ""],
        //   (old) => {
        //     console.log('old data', old)
        //     if (!old || !old?.data?.data) return old;
        //     return {
        //       ...old,
        //       data: {
        //         ...old.data,
        //         data: old.data.data.map(status =>
        //           status.statusId === data.data.statusId
        //             ? data.data
        //             : status)
        //       },
        //     }
        //   })
      },
      onError: (error: any) => {
        if (showNotification) {
          if (isAxiosError<AxiosBackendErrorReturn>(error)) {
            showNotification(
              `${error?.response?.status ?? "ERROR"}: ${
                error.response?.data.detail ?? "UNKNOWN ERROR"
              }`,
              "error",
              String(
                error?.response?.data?.detail ?? "Fail To update Order Status"
              ).substring(0, 300)
            );
          } else {
            showNotification(
              "Something Wrong!",
              "error",
              "Fail To update Order Status"
            );
          }
        }
      },
    }
  );
  interface UpdateStatusIndexProps {
    statusId: string;
    source: number;
    dest: number;
  }
  const { handleUpdateStatusOrder } = useSetStatusQueryData();

  const updateStatusIndex = useMutation(
    ["update-order-status", companyId],
    ({ statusId, source, dest }: UpdateStatusIndexProps) => {
      return DoxleAPI.patch<OrderStatus>(
        `/orders/status/${statusId}/`,
        {
          index: dest,
        },
        { headers: { "User-Company": companyId ?? "" } }
      );
    },
    {
      retry: 1,
      onSuccess: (data, variables, context) => {
        const docketQueryCache = queryClient.getQueryCache();
      },
      onError: (error: any, variables) => {
        handleUpdateStatusOrder(variables.dest, variables.source, [
          "order-status-list",
          companyId ?? "",
        ]);
        if (showNotification) {
          if (isAxiosError<AxiosBackendErrorReturn>(error)) {
            showNotification(
              `${error?.response?.status ?? "ERROR"}: ${
                error.response?.data.detail ?? "UNKNOWN ERROR"
              }`,
              "error",
              String(
                error?.response?.data?.detail ?? "Fail To update Order status"
              ).substring(0, 300)
            );
          } else {
            showNotification(
              "Something Wrong!",
              "error",
              "Fail To update Order status"
            );
          }
        }
      },
      onSettled: (data, err, variables, context) => {},
    }
  );
  const addStatus = useMutation(
    ["add-order-status", companyId],
    (newStatus: Omit<OrderStatus, "statusId" | "totalCount">) => {
      return DoxleAPI.post<OrderStatus>(`/orders/status/`, newStatus, {
        headers: { "User-Company": companyId ?? "" },
      });
    },
    {
      retry: 1,
      onSuccess: (data, variables, context) => {
        queryClient.setQueryData<AxiosResponse<OrderStatus[]> | undefined>(
          ["order-status-list", companyId ?? ""],
          (old) => {
            if (!old || !old?.data) return old;
            return {
              ...old,
              data: [...old.data, data.data],
            };
          }
        );
      },
      onError: (error: any) => {
        if (showNotification) {
          if (isAxiosError<AxiosBackendErrorReturn>(error)) {
            showNotification(
              `${error?.response?.status ?? "ERROR"}: ${
                error.response?.data.detail ?? "UNKNOWN ERROR"
              }`,
              "error",
              String(
                error?.response?.data?.detail ?? "Fail To add Order status"
              ).substring(0, 300)
            );
          } else {
            showNotification(
              "Something Wrong!",
              "error",
              "Fail To add Order status"
            );
          }
        }
      },
    }
  );
  interface DeleteProps {
    statusId: string;
    newId?: string | null;
  }
  const deleteStatus = useMutation(
    ["delete-order-status", companyId],
    ({ statusId, newId }: DeleteProps) => {
      let url = `/orders/status/${statusId}/`;
      if (newId) url += `?new_status_id=${newId}`;
      else if (newId === null) url += "?cascade=true";
      return DoxleAPI.delete(url, {
        headers: { "User-Company": companyId ?? "" },
      });
    },
    {
      retry: 1,
      onSuccess: (data, { statusId }, context) => {
        queryClient.setQueryData<AxiosResponse<OrderStatus[]> | undefined>(
          ["order-status-list", companyId ?? ""],
          (old) => {
            if (!old || !old?.data) return old;
            return {
              ...old,
              data: old.data.filter((status) => status.statusId !== statusId),
            };
          }
        );
      },
      onError: (error: any) => {
        if (showNotification) {
          if (isAxiosError<AxiosBackendErrorReturn>(error)) {
            showNotification(
              `${error?.response?.status ?? "ERROR"}: ${
                error.response?.data.detail ?? "UNKNOWN ERROR"
              }`,
              "error",
              String(
                error?.response?.data?.detail ?? "Fail To delete Order status"
              ).substring(0, 300)
            );
          } else {
            showNotification(
              "Something Wrong!",
              "error",
              "Fail To delete Order status"
            );
          }
        }
      },
    }
  );
  return {
    updateStatus: updateStatus.mutate,
    deleteStatus: deleteStatus.mutate,
    addStatus: addStatus.mutate,
    updateStatusIndex: updateStatusIndex.mutate,
  };
};
interface UseSharedOrderLinkProps {
  linkId: string | undefined;
}
const useSharedOrderLink = ({
  linkId,
}: UseSharedOrderLinkProps): UseQueryResult<
  AxiosResponse<OrderLinkReturn>,
  AxiosError<AxiosBackendErrorReturn> | unknown
> => {
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );
  const qKey = ["order-link", linkId];
  let URL = `/orders/exchange/${linkId}/`;

  return useQuery(
    qKey,
    () =>
      DoxleAPI.get<
        AxiosResponse<OrderLinkReturn>,
        AxiosError<AxiosBackendErrorReturn>
      >(URL, {}),
    {
      enabled: Boolean(linkId),
      retry: 1,
      refetchInterval: false,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      retryOnMount: false,
      staleTime: 0,
      cacheTime: 0,
      onError: (error) => {
        if (showNotification) {
          if (isAxiosError<AxiosBackendErrorReturn>(error)) {
            showNotification(
              `${error?.response?.status ?? "ERROR"}: ${
                error.response?.data.detail ?? "UNKNOWN ERROR"
              }`,
              "error",
              String(
                error?.response?.data ?? "Fail To get Order link"
              ).substring(0, 300)
            );
          } else {
            showNotification(
              "Something Wrong!",
              "error",
              "Fail To get Order link"
            );
          }
        }
      },
      // onSuccess: (response) => {
      //   if (onSuccessCb) onSuccessCb(response.data);
      // },
    }
  );
};

const OrdersQueryAPI = {
  useRetrieveOrderList,
  useRetrieveOrderDetail,
  useRetrieveOrderPDF,
  useRetrieveOrderStatusList,
  useMutateOrderQuery,
  useMutateOrderLineList,
  useMutateOrderStatus,
  useSharedOrderLink,
};
export default OrdersQueryAPI;
