import React, { useRef, useState } from "react";
import { useDoxleAuthStore } from "../../../../../DoxleGeneralStore/useDoxleAuthStore";
import { shallow } from "zustand/shallow";
import { useDoxleCurrentContextStore } from "../../../../../DoxleGeneralStore/useDoxleCurrentContext";
import useDoxleNotificationStore from "../../../../../DoxleGeneralStore/useDoxleNotificationStore";
import { BookingQueryAPI } from "../../../../QueryAPI/bookingQueryAPI";
import { IBooking } from "../../../../Models/booking";
import useBookingDocketStore from "../../../../store/useBookingDocketStore";

type Props = {};

interface EditBookingTitle {
  setNewTitleInput: React.Dispatch<React.SetStateAction<string>>;
  newTitleInput: string;
  handleEditUpdateBookingTitle: () => void;
  isUpdatingTitle: boolean;
  onKeydownTitleInput: (e: React.KeyboardEvent<HTMLDivElement>) => void;
  inputRef: React.RefObject<HTMLInputElement>;
}
const useEditBookingTitle = (props: Props): EditBookingTitle => {
  const { filterBookingDocketQuery, edittedBooking } = useBookingDocketStore(
    (state) => ({
      filterBookingDocketQuery: state.filterBookingDocketQuery,
      edittedBooking: state.edittedBooking,
    }),
    shallow
  );
  const [newTitleInput, setNewTitleInput] = useState(
    edittedBooking?.title ?? ""
  );
  const { company } = useDoxleCurrentContextStore(
    (state) => ({
      company: state.currentCompany,
    }),
    shallow
  );
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );

  const onErrorCb = () => {
    setNewTitleInput(edittedBooking?.title ?? "");
  };

  const onSuccessUpdate = (newBooking?: IBooking) => {
    if (newBooking && !newTitleInput) setNewTitleInput(newBooking.title);
  };
  const { update } = BookingQueryAPI.useMutateBookingQuery({
    company,

    showNotification,
    filter: filterBookingDocketQuery,
    onErrorCb,
    onSuccessUpdateCb: onSuccessUpdate,
  });

  const handleEditUpdateBookingTitle = () => {
    // if (edittedBooking) {
    //   if (!newTitleInput)
    //     update.mutate({
    //       bookingId: edittedBooking.bookingId,
    //       updateData: {
    //         title: "Untitled Booking",
    //       },
    //     });
    //   else {
    //     if (newTitleInput !== edittedBooking.title)
    //       update.mutate({
    //         bookingId: edittedBooking.bookingId,
    //         updateData: {
    //           title: newTitleInput,
    //         },
    //       });
    //   }
    // }
  };
  const inputRef = useRef<HTMLInputElement>(null);
  const onKeydownTitleInput = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.nativeEvent.key === "Enter") {
      e.preventDefault();
      inputRef.current?.blur();
    }
    if (e.nativeEvent.key === "Escape") {
      e.preventDefault();
      setNewTitleInput("");
    }
  };
  return {
    setNewTitleInput,
    newTitleInput,
    handleEditUpdateBookingTitle,
    isUpdatingTitle: update.isLoading,
    onKeydownTitleInput,
    inputRef,
  };
};

export default useEditBookingTitle;
