import { create } from "zustand";
import {
  IFullDocketDetailQueryFilterProp,
  LightDocket,
} from "../../Models/dockets";
import { produce } from "immer";

interface BudgetCurrentContextStore {
  currentBudget: LightDocket | undefined;
  setCurrentBudget: (budget: LightDocket | undefined) => void;

  filterDocketListQuery: IFullDocketDetailQueryFilterProp;
  setFilterDocketListQuery: (
    item: Partial<IFullDocketDetailQueryFilterProp>
  ) => void;

  isCompact: boolean;
  setIsCompact: (value: boolean) => void;
  isFullStatus: boolean;
  setIsFullStatus: (value: boolean) => void;

  confirmDeleteBudget: LightDocket | undefined;
  setConfirmDeleteBudget: (item: LightDocket | undefined) => void;

  isBudgetProjectLevel: boolean;
  setIsBudgetProjectLevel: (value: boolean) => void;

  shrinkedStageSections: Array<string | "unstaged">; //ids of all the stage that is shrinked, to handle ui
  setShrinkedStageSections: (stageId: string | "unstaged") => void;
  resetBudgetStore: () => void;
}

const sessionStorageFilter = sessionStorage.getItem("budgetFilters");
const initialBudgetFilters = sessionStorageFilter
  ? JSON.parse(sessionStorageFilter)
  : { budget: "true" };

const isCompactViewLocalStorage = localStorage.getItem("isCompact")
  ? Boolean(localStorage.getItem("isCompact")! === "true")
  : false;

const isFullStatusViewLocalStorage = localStorage.getItem("isFullStatus")
  ? Boolean(localStorage.getItem("isFullStatus")! === "true")
  : true;

export const useBudgetStore = create<BudgetCurrentContextStore>((set, get) => ({
  currentBudget: undefined,
  setCurrentBudget: (budget: LightDocket | undefined) => {
    set((state) => ({ currentBudget: budget }));
  },

  filterDocketListQuery: { ...initialBudgetFilters, order_by: ["stage_model__index", "-is_sticky"], page_size: 100 },
  setFilterDocketListQuery: (
    filter: Partial<IFullDocketDetailQueryFilterProp>
  ) => {
    const newFilter: IFullDocketDetailQueryFilterProp = {
      ...get().filterDocketListQuery,
      ...filter,
    };
    set({ filterDocketListQuery: newFilter });
    sessionStorage.setItem("budgetFilters", JSON.stringify(newFilter));
  },
  isCompact: isCompactViewLocalStorage,
  setIsCompact: (value: boolean) => {
    localStorage.setItem("isCompact", JSON.stringify(value));
    return set((state) => ({ isCompact: value }));
  },
  isFullStatus: isFullStatusViewLocalStorage,
  setIsFullStatus: (value: boolean) => {
    localStorage.setItem("isFullStatus", JSON.stringify(value));
    return set((state) => ({ isFullStatus: value }));
  },

  confirmDeleteBudget: undefined,
  setConfirmDeleteBudget: (item: LightDocket | undefined) =>
    set((state) => ({ confirmDeleteBudget: item })),

  isBudgetProjectLevel: false,
  setIsBudgetProjectLevel: (value: boolean) =>
    set((state) => ({ isBudgetProjectLevel: value })),

  shrinkedStageSections: [],
  setShrinkedStageSections: (stageId: string | "unstaged") =>
    set((state) =>
      produce(state, (draftState) => {
        if (state.shrinkedStageSections.includes(stageId)) {
          draftState.shrinkedStageSections = state.shrinkedStageSections.filter(
            (id) => id !== stageId
          );
        } else {
          draftState.shrinkedStageSections.push(stageId);
        }

        return draftState;
      })
    ),
  resetBudgetStore: () =>
    set((state) => ({
      isBudgetProjectLevel: true,
      confirmDeleteBudget: undefined,
      currentBudget: undefined,
      shrinkedStageSections: [],
    })),
}));
