import React, { useEffect, useMemo, useState } from "react";
import { useDoxleCurrentContextStore } from "../../../DoxleGeneralStore/useDoxleCurrentContext";
import { useShallow } from "zustand/react/shallow";
import useBookingCalendarStore from "../../store/useBookingCalendarStore";
import dayjs from "dayjs";
import { Variants } from "framer-motion";
import useDoxleNotificationStore from "../../../DoxleGeneralStore/useDoxleNotificationStore";
import {
  BookingQueryAPI,
  UpdateBookingQueryParams,
} from "../../QueryAPI/bookingQueryAPI";
import { produce } from "immer";
import { IBooking } from "../../Models/booking";
import {
  formatTISODate,
  formatTISODateTime,
} from "../../../Utilities/FunctionUtilities";
import { IntRange } from "../../../Models/IntRangeType";

type Props = {};

export interface IEditBookingContextValue {
  editBookingData: TEditBookingData | undefined;
  setEditBookingData: React.Dispatch<
    React.SetStateAction<TEditBookingData | undefined>
  >;
  shouldShowError: boolean;
}
type TEditBookingData = Partial<Omit<IBooking, "bookingId">> &
  Pick<IBooking, "bookingId">;
const useEditBookingDialog = () => {
  const [showCommentSection, setShowCommentSection] = useState(false);
  const [editBookingData, setEditBookingData] = useState<
    TEditBookingData | undefined
  >(undefined);

  const [shouldShowError, setShouldShowError] = useState(false);
  const { company, currentProject } = useDoxleCurrentContextStore(
    useShallow((state) => ({
      company: state.currentCompany,
      currentProject: state.currentProject,
    }))
  );
  const {
    editedBooking,
    setEditedBooking,
    filterBookingProjectQuery,
    setDeletedBooking,
  } = useBookingCalendarStore(
    useShallow((state) => ({
      editedBooking: state.editedBooking,
      setEditedBooking: state.setEditedBooking,
      filterBookingProjectQuery: state.filterBookingProjectQuery,
      setDeletedBooking: state.setDeletedBooking,
    }))
  );
  const { showNotification } = useDoxleNotificationStore();
  const { update } = BookingQueryAPI.useMutateBookingQuery({
    company,
    filter: filterBookingProjectQuery,
    showNotification,
  });
  const onClickDelete = () => {
    setDeletedBooking(editedBooking);
  };
  const isOpen = Boolean(editedBooking);
  const closeDialog = () => {
    setEditedBooking(undefined);
  };

  const titleDialog = editedBooking
    ? `
  ${
    dayjs(editedBooking.startDate).diff(editedBooking.endDate, "day") === 0
      ? dayjs(editedBooking.startDate).format("ddd, DD MMM YYYY")
      : `${dayjs(editedBooking.startDate).format("ddd, DD MMM YYYY")} - ${dayjs(
          editedBooking.endDate
        ).format("ddd, DD MMM YYYY")}`
  }
  `
    : "";
  const onPickDate = (
    date: [Date | null, Date | null],
    event: React.SyntheticEvent<any, Event> | undefined
  ) => {
    setEditBookingData(
      produce(editBookingData, (draft) => {
        if (draft) {
          draft.startDate = date[0] ? formatTISODate(date[0]) : null;
          draft.endDate = date[1] ? formatTISODate(date[1]) : null;
        }
      })
    );
  };

  const onPercentageChange = (value: number) => {
    setEditBookingData(
      produce(editBookingData, (draft) => {
        if (draft) {
          draft.percentageCompleted = value as IntRange<0, 101>;
        }
      })
    );
  };

  const onToggleComplete = () => {
    if (editedBooking)
      update.mutate({
        bookingId: editedBooking.bookingId,
        updateData: {
          completed: editedBooking.completed
            ? null
            : formatTISODateTime(new Date()),
        },
      });
    setEditedBooking(undefined);
  };
  const contentAnimateVariants: Variants = {
    initial: {
      height: "250px",
    },
    animate: {
      height: showCommentSection ? "90vh" : "250px",

      transition: {
        damping: 16,
        mass: 0.1,
        stiffness: 120,
      },
    },
  };

  const onClickSubmit = () => {
    if (editBookingData && editBookingData) {
      if (
        !editBookingData.title ||
        (editBookingData.startDate &&
          editBookingData.endDate &&
          dayjs(editBookingData.startDate).isAfter(editBookingData.endDate))
      ) {
        setShouldShowError(true);
        return;
      }
      let updateDate: UpdateBookingQueryParams = {
        bookingId: editBookingData.bookingId,
        updateData: {
          title: editBookingData.title,
          startDate: editBookingData.startDate,
          endDate: editBookingData.endDate,
          percentageCompleted: editBookingData.percentageCompleted,
        },
      };
      update.mutate(updateDate);
      closeDialog();
    }
  };

  const contextValue: IEditBookingContextValue = useMemo(
    () => ({
      editBookingData,
      setEditBookingData,
      shouldShowError,
    }),
    [editBookingData, shouldShowError]
  );
  useEffect(() => {
    setEditBookingData(editedBooking);
    if (!editedBooking) {
      setShowCommentSection(false);
      setShouldShowError(false);
    }
  }, [editedBooking]);

  return {
    isOpen,
    closeDialog,
    editBookingData,
    titleDialog,
    contentAnimateVariants,
    contextValue,
    onPickDate,
    onPercentageChange,
    onToggleComplete,
    onClickDelete,
    setShowCommentSection,
    showCommentSection,
    isUpdating: update.isLoading,
    onClickSubmit,
  };
};

export default useEditBookingDialog;
