import React, { useCallback } from "react";
import {
  StyledContactCompanyFormContainer,
  StyledContactsAddNewInputDiv,
} from "./styledComponents";
import CompanyAutoCompleteSelection from "../GeneralComponents/CompanyAutoCompleteSelection";
import ContactsTextField from "../GeneralComponents/ContactsTextField";
import { ContactCompany } from "../../../Models/addressBook";
type Props = {
  newContactCompany: ContactCompany;
  setNewContactCompany: React.Dispatch<React.SetStateAction<ContactCompany>>;
  action: "edit" | "add";
};

const ContactCompanyForm = ({
  newContactCompany,
  setNewContactCompany,
  action,
}: Props) => {
  const handleCompanyNameChange = useCallback((value: string) => {
    setNewContactCompany((prev) => ({ ...prev, name: value }));
  }, []);
  const handleCompanyABNChange = useCallback((value: string) => {
    setNewContactCompany((prev) => ({ ...prev, abn: value }));
  }, []);
  const handleCompanyAddressChange = useCallback((value: string) => {
    setNewContactCompany((prev) => ({ ...prev, address: value }));
  }, []);
  const checkAbnValid = useCallback((abn: string) => {
    const numberRegex =
      /^(\+?\(61\)|\(\+?61\)|\+?61|\(0[1-9]\)|0[1-9])?( ?-?[0-9]){7,9}$/m;
    return numberRegex.test(abn);
  }, []);
  return (
    <StyledContactCompanyFormContainer>
      <StyledContactsAddNewInputDiv marginTop="0px" width="100%">
        {action === "add" ? (
          <ContactsTextField
            fieldLabelText="Company"
            required={true}
            fieldValue={newContactCompany.name}
            setFieldValue={handleCompanyNameChange}
            errorToggle={!newContactCompany.name}
            errorText="Please Enter Company Name"
          />
        ) : (
          <CompanyAutoCompleteSelection
            currentValue={newContactCompany}
            setNewContactCompany={setNewContactCompany}
          />
        )}
      </StyledContactsAddNewInputDiv>
      <StyledContactsAddNewInputDiv width="100%">
        <ContactsTextField
          fieldLabelText="Company ABN"
          fieldValue={newContactCompany.abn}
          setFieldValue={handleCompanyABNChange}
          errorToggle={Boolean(
            newContactCompany.abn
              ? !checkAbnValid(newContactCompany.abn)
              : false
          )}
          errorText={
            Boolean(
              newContactCompany.abn !== "" &&
                !checkAbnValid(newContactCompany.abn)
            )
              ? "Please Enter Valid ABN"
              : undefined
          }
        />
      </StyledContactsAddNewInputDiv>
      <StyledContactsAddNewInputDiv width="100%">
        <ContactsTextField
          fieldLabelText="Company Address"
          required={true}
          fieldValue={newContactCompany.address}
          setFieldValue={handleCompanyAddressChange}
          textFieldHeightInPixel="110px"
          multiline={true}
          errorToggle={!newContactCompany.address}
          errorText={
            !newContactCompany.address
              ? "Please Enter Company Address"
              : undefined
          }
        />
      </StyledContactsAddNewInputDiv>
    </StyledContactCompanyFormContainer>
  );
};

export default ContactCompanyForm;
