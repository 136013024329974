import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import { DoxleFile } from "../../Models/files";
import {
  StyledCreatedByTableDiv,
  StyledCreatedTimeTableDiv,
  StyledFileNameTableDiv,
  StyledFileSizeTableDiv,
  StyledFileTableRowCell,
  StyledImageDiv,
} from "./StyledFilesBodyComponent";
import FileHoverMenu from "./FileHoverMenu";
import {
  DoxleCSVIcon,
  DoxleExcelIcon,
  DoxlePDFIcon,
  DoxleWordIcon,
} from "../../DoxleDesignPattern/DoxleGeneralIcons";
import { DoxleTextIcon } from "../../CoreContent/DoxleTopMenu/DoxleTopMenuIcon";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import useFilesRowRight from "../Hooks/useFilesRowRight";
import { IoVideocamOutline } from "react-icons/io5";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";

dayjs.extend(advancedFormat);
type Props = {
  file: DoxleFile;
};

const FilesRowRight = ({ file }: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore((state) => ({
    doxleThemeColor: state.doxleThemeColor,
    doxleFont: state.doxleFont,
  }));
  const {
    onFileRowHover,
    setOnFileRowHover,
    supportedFileType,
    openFile,
    isDeletingFileInsideFolder,
  } = useFilesRowRight({ file });

  return (
    <StyledFileTableRowCell
      $themeColor={doxleThemeColor}
      onPointerEnter={() => setOnFileRowHover(true)}
      onPointerLeave={() => setOnFileRowHover(false)}
    >
      {/* <DoxleCheckbox
        style={{ marginLeft: "14px" }}
        onChangeCallback={onSelectCheckbox}
        checked={isChecked}
        alternateIcon={true}
      /> */}
      <div className="image-name-wrapper">
        <StyledImageDiv>
          {supportedFileType === "pdf" ? (
            <DoxlePDFIcon
              themeColor={doxleThemeColor}
              containerStyle={{
                width: 25,
              }}
            />
          ) : supportedFileType === "doc" ? (
            <DoxleWordIcon
              themeColor={doxleThemeColor}
              containerStyle={{
                width: 25,
              }}
            />
          ) : supportedFileType === "xls" ? (
            <DoxleExcelIcon
              themeColor={doxleThemeColor}
              containerStyle={{
                width: 25,
              }}
            />
          ) : supportedFileType === "csv" ? (
            <DoxleCSVIcon
              themeColor={doxleThemeColor}
              containerStyle={{
                width: 25,
              }}
            />
          ) : supportedFileType === "text" ? (
            <DoxleTextIcon
              themeColor={doxleThemeColor}
              containerStyle={{
                width: 25,
              }}
            />
          ) : supportedFileType === "video" ? (
            <IoVideocamOutline
              color={doxleThemeColor.primaryFontColor}
              size={25}
            />
          ) : (
            supportedFileType === "image" && (
              <img className="image-holder" src={file.thumbUrl} />
            )
          )}
        </StyledImageDiv>
        <Tooltip title={<Typography fontSize={16}>{file.fileName}</Typography>}>
          <StyledFileNameTableDiv
            $themeColor={doxleThemeColor}
            $doxleFont={doxleFont}
            onClick={openFile}
          >
            {file.fileName}
          </StyledFileNameTableDiv>
        </Tooltip>

        {isDeletingFileInsideFolder ? (
          <CircularProgress color="error" size={14} style={{ marginLeft: 8 }} />
        ) : (
          onFileRowHover && <FileHoverMenu fileItem={file} />
        )}
      </div>

      <StyledFileSizeTableDiv
        $themeColor={doxleThemeColor}
        $doxleFont={doxleFont}
      >
        {(parseFloat(file.fileSize) / 1024 / 1024).toFixed(2)}MB
      </StyledFileSizeTableDiv>
      <StyledCreatedTimeTableDiv
        $themeColor={doxleThemeColor}
        $doxleFont={doxleFont}
      >
        {dayjs(file.created).format("MMM DD, YYYY")} at{" "}
        {dayjs(file.created).format("hh:mma")}
      </StyledCreatedTimeTableDiv>
      <StyledCreatedByTableDiv
        $themeColor={doxleThemeColor}
        $doxleFont={doxleFont}
      >
        {file.ownerName}
      </StyledCreatedByTableDiv>
    </StyledFileTableRowCell>
  );
};

export default FilesRowRight;
