import { create } from "zustand";
import PaymentClaim, { PaymentClaimBackCharge } from "../Models/PaymentClaim";
import Order from "../Models/order";

interface PaymentClaimStore {
  action: null | "new" | "review" | "backcharge" | "viewXero";
  setAction: (action: null | "new" | "review" | "backcharge" | "viewXero") => void;
  relatedOrder: Order | undefined;
  setRelatedOrder: (item: Order | undefined) => void;
  reviewPaymentClaim: PaymentClaim | undefined;
  setReviewPaymentClaim: (item: PaymentClaim | undefined) => void;
  backCharges: PaymentClaimBackCharge[];
  appendBackCharge: (item: PaymentClaimBackCharge) => void;
  setBackCharges: (items: PaymentClaimBackCharge[]) => void;
  paymentClaimQueryFilter: Object;
  setPaymentClaimQueryFilter: (item: Object) => void;
  resetStore: () => void;
}

export const usePaymentClaimStore = create<PaymentClaimStore>((set, get) => ({
  action: null,
  setAction: (action: null | "new" | "review" | "backcharge" | "viewXero") =>
    set({ action: action }),
  relatedOrder: undefined,
  setRelatedOrder: (item: Order | undefined) => set({ relatedOrder: item }),
  reviewPaymentClaim: undefined,
  setReviewPaymentClaim: (item: PaymentClaim | undefined) =>
    set({
      reviewPaymentClaim: item,
      action: !item ? null
        : item?.status === "Pending"
          ? "review"
          : "viewXero",
    }),
  backCharges: [],
  appendBackCharge: (item: PaymentClaimBackCharge) =>
    set({ backCharges: [...get().backCharges, item] }),
  setBackCharges: (items: PaymentClaimBackCharge[]) =>
    set({ backCharges: items }),
  paymentClaimQueryFilter: {},
  setPaymentClaimQueryFilter: (item: Object) =>
    set({
      paymentClaimQueryFilter: { ...get().paymentClaimQueryFilter, ...item },
    }),
  resetStore: () =>
    set((state) => ({
      ...state,
      action: null,
      relatedOrder: undefined,
      reviewPaymentClaim: undefined,
      backCharges: [],
      paymentClaimQueryFilter: {},
    })),
}));
