import PaymentClaim, {
  PAYMENT_CLAIM_TABLE_HEADER_LIST,
} from "../../../Models/PaymentClaim";
import React, { useMemo, useState } from "react";
import { StyledPaymentClaimMessageText } from "../StyledPaymentClaimComponents";
import { useDoxleThemeStore } from "../../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import { Typography } from "@mui/material";
import {
  formatDate,
  formatter,
  toNum,
} from "../../../../Utilities/FunctionUtilities";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import PendingIcon from "@mui/icons-material/Pending";
import { usePaymentClaimStore } from "../../../OrdersStore/usePaymentClaimStore";
import {
  StyledPaymentClaimCell,
  StyledPaymentClaimItemRow,
} from "./StyledComponentPaymentClaimList";
import { IoDocumentOutline } from "react-icons/io5";
import dayjs from "dayjs";
interface Props {
  claim: PaymentClaim;
}

const PaymentClaimRow = ({ claim }: Props) => {
  const { doxleThemeColor, doxleFont, transparentMode, currentTheme } =
    useDoxleThemeStore(
      (state) => ({
        doxleThemeColor: state.doxleThemeColor,
        doxleFont: state.doxleFont,
        transparentMode: state.transparentMode,
        currentTheme: state.currentTheme,
      }),
      shallow
    );
  const setClaim = usePaymentClaimStore(
    (state) => state.setReviewPaymentClaim,
    shallow
  );
  const statusText = useMemo(() => {
    if (claim.status === "Paid") return "Paid";
    if (claim.status === "Partially Paid") return "Partial$";
    if (claim.status === "Rejected") return "Void";
    return "Pending";
  }, [claim.status]);

  const statusColor = useMemo(() => {
    if (claim.status === "Pending") return "rgba(253, 183, 4, 0.9)";
    if (claim.status === "Rejected") return "rgba(255, 50, 50, 0.9)";
    return "rgba(17, 183, 24, 0.9)";
  }, [claim.status]);

  const handleClaimClick = (event: React.MouseEvent) => {
    setClaim(claim);
  };
  let descriptionString: string = `${claim.supplierName}${
    claim.supplierName ? " - " : ""
  }${claim.orderDescription}`;
  if (descriptionString.length > 100)
    descriptionString = descriptionString.substring(0, 97) + "...";

  const approvedText = useMemo(() => {
    let returnText: string = "";
    if (claim.approved) {
      returnText = "This payment claim has been approved";
      if (claim.approvedByJson)
        returnText += ` by  ${claim.approvedByJson.firstName} ${claim.approvedByJson.lastName},`;
      if (claim.plannedPaymentDate)
        returnText += ` and is scheduled for payment on ${dayjs(
          claim.plannedPaymentDate
        ).format("MMM DD, YYYY")}`;
      else if (claim.approvedOn)
        returnText += ` on ${dayjs(claim.approvedOn).format(
          "MMM DD, YYYY"
        )} and is awaiting payment.`;
      else returnText += `and is awaiting payment.`;
    } else if (claim.approved === false) {
      returnText = "This payment claim was rejected";
      if (claim.approvedByJson)
        returnText += ` by  ${claim.approvedByJson.firstName} ${claim.approvedByJson.lastName},`;
      else returnText += ` by Doxle`;
    } else if (claim.approved === null)
      returnText =
        "This payment claim is being reviewed, you will be alerted by email when approved or rejected.";
    return returnText;
  }, [
    claim.approved,
    claim.approvedByJson,
    claim.approvedOn,
    claim.plannedPaymentDate,
  ]);

  return (
    <StyledPaymentClaimItemRow
      $themeColor={doxleThemeColor}
      $doxleFont={doxleFont}
      $transparentMode={transparentMode}
      $currentTheme={currentTheme}
      onClick={handleClaimClick}
    >
      {PAYMENT_CLAIM_TABLE_HEADER_LIST.map((header, idx) => {
        if (!header.display) return null;
        return (
          <StyledPaymentClaimCell
            $themeColor={doxleThemeColor}
            $doxleFont={doxleFont}
            key={`header#${idx}#${claim.paymentId}`}
            $width={
              header.headerName !== "DESCRIPTION"
                ? header.headerName === "ICON"
                  ? "5rem"
                  : "12%"
                : "48%"
            }
            $horizontalAlign={
              header.headerName === "INVOICE NUMBER" ||
              header.headerName === "ICON"
                ? "flex-start"
                : "center"
            }
          >
            {header.display && header.headerName === "ICON" && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  position: "relative",
                  top: "-5px",
                  paddingLeft: "5px",
                }}
              >
                <IoDocumentOutline color={statusColor} fontSize="2rem" />
                <div
                  style={{
                    position: "relative",
                    top: "10px",
                    left: "-10px",
                    backgroundColor: statusColor,
                    padding: "0px 2px",
                    borderRadius: "3px",
                    fontSize: "11px",
                    height: "15px",
                  }}
                >
                  {statusText}
                </div>
              </div>
            )}
            {header.display && header.headerName === "ORDER NUMBER" && (
              <span className="order-number">
                {claim.orderJson?.orderNumber ?? "-"}
              </span>
            )}
            {header.display && header.headerName === "INVOICE NUMBER" && (
              <span className="invoice-number">{claim.invoiceNumber}</span>
            )}

            {header.display && header.headerName === "DESCRIPTION" && (
              <span className="general-text">{descriptionString}</span>
            )}
            {header.display && header.headerName === "CLAIM AMOUNT" && (
              <span className="invoice-number">
                {formatter.format(toNum(claim.filteredSubTotal))}
                {toNum(claim?.orderJson?.subTotal) !== 0 &&
                  ` (${
                    Math.round(
                      (toNum(claim.filteredSubTotal) /
                        toNum(claim?.orderJson?.subTotal)) *
                        10000
                    ) / 100
                  }%)`}
              </span>
            )}
            {header.display && header.headerName === "AMOUNT PAID" && (
              <span className="invoice-number">
                {formatter.format(toNum(claim.totalPayments))}
              </span>
            )}
          </StyledPaymentClaimCell>
        );
      })}
    </StyledPaymentClaimItemRow>
  );
};

export default PaymentClaimRow;
