import React, { useEffect, useState } from "react";
import "./Measurement.css";
import { Box, Typography } from "@mui/material";
import { Stage, Layer } from "react-konva";
import Konva from 'konva';
import ImageCustom from "./ImageCustom";
import { nanoid } from "nanoid";
import DoxleShape from "./DoxleShape";
import KonvaInteractiveLabel from "./KonvaInteractiveLabel";
import usePolygon from "../Hooks/usePolygon";
import useCircle from "../Hooks/useCircle";
import useRectangle from "../Hooks/useRectangle";
import useLine from "../Hooks/useLine";
import useEachPicker from "../Hooks/useEachPicker";
import {useMeasurementsStore} from "../MeasurementsStore/useMeasurementsStore";
import {shallow} from "zustand/shallow";
import {toFloat} from "../konvaFunctions/generalFunctions";
import {TDoxleShape, XY} from "../Models/takeOffs";

const KonvaComponent: React.FC = () => {
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const {
    sheet,
    measurements,
    isScaling,
    currentTool,
    currentShape,
    stageState,
    setStageState,
    displayTags,
    scaleLine
  } = useMeasurementsStore(state => ({
    sheet: state.currentSheet,
    measurements: state.measurements,
    isScaling: state.isScaling,
    currentTool: state.currentTool,
    currentShape: state.currentShape,
    stageState: state.stageState,
    setStageState: state.setStageState,
    displayTags: state.displayTags,
    scaleLine: state.scaleLine
  }), shallow)

  const polygon = usePolygon({})
  const circle = useCircle({})
  const rectangle = useRectangle({})
  const line = useLine({})
  const eachPicker = useEachPicker({})
  const sheetHeight = toFloat(sheet?.height)
  const sheetWidth = toFloat(sheet?.width)

  // const handleMouseUp = (e: Konva.KonvaEventObject<MouseEvent>) => {
  const handleMouseUp = (e: any) => {
    if (isDragging) return;
    if (e?.evt?.button && e?.evt?.button !== 0) return;
    if (!sheet?.sheetId) return;
    const pos = e.target.getStage().getRelativePointerPosition() as XY;
    if (pos.x < 0 || pos.x > sheetWidth || pos.y < 0 || pos.y > sheetHeight) return;
    if (isScaling || currentTool === "Line") line.mouseUp(pos)
    else if (currentTool === "Rectangle") rectangle.mouseUp(pos)
    else if (currentTool === "Circle") circle.mouseUp(pos)
    else if (currentTool === "Polygon") polygon.mouseUp(pos)
    else if (currentTool === "Selector") eachPicker.mouseUp(pos)
  };

  // const handleMouseMove = (e: Konva.KonvaEventObject<MouseEvent>) => {
  const handleMouseMove = (e: any) => {
    if (!currentShape || isDragging || !sheet?.sheetId) return;
    const stage = e.target.getStage();
    const pos = stage.getRelativePointerPosition() as XY;
    if (pos.x < 0 || pos.x > sheetWidth || pos.y < 0 || pos.y > sheetHeight) return;
    if (isScaling || currentTool === "Line") line.mouseMove(pos)
    else if (currentTool === "Rectangle") rectangle.mouseMove(pos)
    else if (currentTool === "Circle") circle.mouseMove(pos)
    else if (currentTool === "Polygon") polygon.mouseMove(pos)
  };

  // const handleMouseWheel = (e: Konva.KonvaEventObject<WheelEvent>) => {
  const handleMouseWheel = (e: any) => {
    e.evt.preventDefault();
    const scaleBy = 1.05;
    const stage = e.target.getStage();
    const oldScale = stage.scaleX();
    const mousePointTo = {
      x: stage.getPointerPosition().x / oldScale - stage.x() / oldScale,
      y: stage.getPointerPosition().y / oldScale - stage.y() / oldScale,
    };
    const newScale = e.evt.deltaY < 0 ? oldScale * scaleBy : oldScale / scaleBy;
    setStageState({
      stageScale: newScale,
      stageX: -(mousePointTo.x - stage.getPointerPosition().x / newScale) * newScale,
      stageY: -(mousePointTo.y - stage.getPointerPosition().y / newScale) * newScale,
    });
  };


  return (
    <>
      {sheet?.url ? (
        <Box
          className="stage-canvas"
          // onKeyDown={handleKeyDown}
          style={{ height: "85vh" }}
          // ref={stageCanvasRef}
        >

          <Stage
            style={{ cursor: "crosshair" }}
            width={toFloat(sheet.width)}
            height={toFloat(sheet.height)}
            onWheel={handleMouseWheel}
            onMouseUp={handleMouseUp}
            onMouseMove={handleMouseMove}
            scaleX={stageState.stageScale}
            scaleY={stageState.stageScale}
            x={stageState.stageX}
            y={stageState.stageY}
            draggable
            onDragStart={(e: any) => {
              setIsDragging(true);
            }}
            onDragEnd={() => {
              /*setDraggable(false);*/
              setIsDragging(false);
            }}
          >
            <Layer>
              <ImageCustom src={sheet.url} height={sheetHeight} width={sheetWidth} />
              {!isScaling && measurements.map((shape: TDoxleShape, index: number) => (
                 <DoxleShape key={shape.id} shape={shape}/>
              ))}
              {currentShape && <DoxleShape key={"currentShape"} shape={currentShape} current /> }
              {isScaling && scaleLine && <DoxleShape key={"currentShape"} shape={scaleLine} current /> }
            </Layer>
            {displayTags ? (
              <Layer>
                {!isScaling && measurements.map((shape: TDoxleShape, index: number) => (
                  <DoxleShape key={shape.id + "_Label"} shape={shape} label={true}  />
                ))}
                {currentShape && (
                  <DoxleShape key={currentShape.id + "_Label"} shape={currentShape} label={true} current />
                )}
              </Layer>
            ) : null}
            {/*{showLabelIndex > 0 ? (*/}
            {/*  <KonvaInteractiveLabel shape={measurements?.[showLabelIndex]} />*/}
            {/*) : null}*/}
          </Stage>
        </Box>
      ) : (
        <Typography>Upload a plan</Typography>
      )}
    </>
  );
};

export default KonvaComponent;
