import { MoreVert } from "@mui/icons-material";
import { AnimatePresence } from "framer-motion";
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  StyledDrawingsDataRow,
  StyledDrawingsTableCell,
  StyledDrawingsCheckBox,
  StyledDrawingsImg,
  StyledDrawingsButton,
  StyledDrawingsVerticalDotButton,
  StyledDrawingTableInput,
  StyledDrawingsHamburgerMenu,
  StyledDrawingPopperMenu,
} from "./DrawingsStyledComponent";
import { SHEETS_HEADER_LIST } from "./SheetsHeader";
import {
  PenIcon,
  DrawingsTagIcon,
  DrawingsCheckBoxIcon,
  DrawingsCheckCheckBoxIcon,
} from "./DrawingsIcon";
import {
  CircularProgress,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Popper,
} from "@mui/material";
import { IDrawingDisplayMenu } from "./DrawingsTabDisplayer";
import { shallow } from "zustand/shallow";
import { DrawingSet, Sheet } from "../Models/drawings";
import { useDoxleCurrentContextStore } from "../../DoxleGeneralStore/useDoxleCurrentContext";
import useDoxleNotificationStore from "../../DoxleGeneralStore/useDoxleNotificationStore";
import { useDoxleAuthStore } from "../../DoxleGeneralStore/useDoxleAuthStore";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import DrawingQueryAPI, {
  ISheetDeleteQueryProps,
  ISheetUpdateQueryProps,
} from "../QueryHooks/drawingQueryAPI";

import dayjs from "dayjs";
import useSheetRow from "../Hooks/useSheetRow";
import DoxleIconButton from "../../DoxleDesignPattern/DoxleButtons/DoxleIconButton";
import MoreVertRoundedIcon from "@mui/icons-material/MoreVertRounded";
import { editRgbaAlpha } from "../../Utilities/FunctionUtilities";
import ModeIcon from "@mui/icons-material/Mode";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import { useDrawingStore } from "../Store/useDrawingStore";

type Props = {
  drawing: DrawingSet;
  sheet: Sheet;
  sheetIndex: number;
};

function SheetRow({ drawing, sheet, sheetIndex }: Props) {
  const nameRef = useRef<HTMLInputElement>(null);

  const { THEME_COLOR, doxleFont } = useDoxleThemeStore(
    (state) => ({
      THEME_COLOR: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }),
    shallow
  );
  const { setPreviewedDrawingSet } = useDrawingStore(
    (state) => ({
      setPreviewedDrawingSet: state.setPreviewedDrawingSet,
    }),
    shallow
  );
  const handleClickImage = () =>
    setPreviewedDrawingSet({
      drawingSet: drawing,
      currentIndex: sheetIndex,
    });

  const {
    newSheetTitle,
    setNewSheetTitle,
    handleUpdateSheetTitle,
    isUpdatingSheet,
    anchorSheetPoperMenu,
    handleClickMoreIcon,
    handleClosePoperMenu,
    handleDeleteSheet,
    handleToggleCheckBox,
    isDrawingChecked,
  } = useSheetRow({ sheet, drawing });
  const openPopper = Boolean(anchorSheetPoperMenu);
  const id = openPopper ? `drawing-poper#${drawing.setId}` : undefined;

  return (
    <StyledDrawingsDataRow $themeColor={THEME_COLOR}>
      {SHEETS_HEADER_LIST.map((header, index) => {
        if (header.headerName === "Check") {
          return (
            <StyledDrawingsTableCell
              key={`drawingsCell#${index}#${drawing.setId}`}
              $widthInRatio={"5%"}
              $horizontalAlign={"center"}
              $doxleFont={doxleFont}
              $themeColor={THEME_COLOR}
              style={{ minWidth: "50px" }}
            >
              <StyledDrawingsCheckBox
                sx={{
                  zIndex: 0,
                }}
                disableRipple
                icon={<DrawingsCheckBoxIcon />}
                checkedIcon={<DrawingsCheckCheckBoxIcon />}
                checked={isDrawingChecked}
                onChange={(event) => {
                  handleToggleCheckBox(event.target.checked);
                }}
              />
            </StyledDrawingsTableCell>
          );
        }
        if (header.headerName === "Sheets") {
          return (
            <StyledDrawingsTableCell
              key={`sheetCell#${index}#${drawing.setId}`}
              $widthInRatio={"30%"}
              $horizontalAlign={"center"}
              $doxleFont={doxleFont}
              $themeColor={THEME_COLOR}
              style={{ maxWidth: 300 }}
            >
              <StyledDrawingsImg
                style={{ cursor: "pointer" }}
                onClick={(event) => {
                  event.stopPropagation();
                  handleClickImage();
                }}
                src={sheet.imageUrl}
                alt={`sheetThumb#${sheet.sheetId}`}
              />
            </StyledDrawingsTableCell>
          );
        }

        if (header.headerName === "Title") {
          // return !isNameFocused ? (
          //   <StyledDrawingsTableCell

          //     key={`drawingsCell#${index}#${drawing.setId}`}
          //     $widthInRatio={"25%"}
          //     $horizontalAlign={"flex-start"}
          //     $doxleFont={doxleFont}
          //     $themeColor={THEME_COLOR}
          //     onClick={handleClickRow}
          //   >
          //     {drawing.name}
          //   </StyledDrawingsTableCell>
          // ) : (

          // );

          return (
            <StyledDrawingsTableCell
              key={`drawingsCell#${index}#${drawing.setId}`}
              $widthInRatio={"20%"}
              $doxleFont={doxleFont}
              $themeColor={THEME_COLOR}
            >
              <StyledDrawingTableInput
                InputProps={{ disableUnderline: true }}
                key={`drawingsCell#${index}`}
                $widthInPixel={null}
                $themeColor={THEME_COLOR}
                $doxleFont={doxleFont}
                inputRef={nameRef}
                value={newSheetTitle}
                onChange={(event) => setNewSheetTitle(event.target.value)}
                variant="standard"
                onKeyUpCapture={(event) => {
                  if (event.nativeEvent.key === "Enter") {
                    handleUpdateSheetTitle();
                    nameRef.current?.blur();
                  } else if (event.nativeEvent.key === "Escape") {
                    setNewSheetTitle(drawing.name);
                    nameRef.current?.blur();
                  }
                }}
                onFocus={(event) => event.target.select()}
              />
            </StyledDrawingsTableCell>
          );
        }

        if (header.headerName === "History") {
          return (
            <StyledDrawingsTableCell
              key={`drawingsCell#${index}#${drawing.setId}`}
              $widthInRatio={null}
              $horizontalAlign={"center"}
              $doxleFont={doxleFont}
              $themeColor={THEME_COLOR}
              style={{ flexDirection: "column", alignItems: "center" }}
            >
              {sheet.history.map(
                (history, idx) =>
                  idx < 4 && (
                    <div className="history-text" key={history.id}>
                      {dayjs(history.timeStamp).format("MMM DD, YYYY")} -{" "}
                      {history.type} by {history.userName}
                    </div>
                  )
              )}

              {sheet.history.length > 4 && (
                <div
                  className="history-text"
                  style={{
                    color: editRgbaAlpha({
                      rgbaColor: THEME_COLOR.primaryFontColor,
                      alpha: "0.4",
                    }),
                    marginTop: "0.2rem",
                  }}
                >
                  ...+ {drawing.history.length - 4} modified
                </div>
              )}
            </StyledDrawingsTableCell>
          );
        }

        if (header.headerName === "MoreIcon") {
          return (
            <StyledDrawingsTableCell
              key={`drawingsCell#${index}#${drawing.setId}`}
              $widthInRatio={"10%"}
              $horizontalAlign={"flex-end"}
              $doxleFont={doxleFont}
              $themeColor={THEME_COLOR}
            >
              {!isUpdatingSheet && (
                <DoxleIconButton
                  iconSource={
                    <MoreVertRoundedIcon
                      style={{
                        color: editRgbaAlpha({
                          rgbaColor: THEME_COLOR.primaryFontColor,
                          alpha: "0.5",
                        }),
                        fontSize: "2.5rem",
                      }}
                    />
                  }
                  sx={{
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                  }}
                  buttonSize={40}
                  onClick={handleClickMoreIcon}
                />
              )}

              {isUpdatingSheet && (
                <CircularProgress
                  style={{ color: THEME_COLOR.primaryFontColor }}
                  size="2rem"
                />
              )}
            </StyledDrawingsTableCell>
          );
        }
      })}

      <Popper
        id={id}
        open={openPopper}
        anchorEl={anchorSheetPoperMenu}
        transition
        // disablePortal
        placement="auto-end"
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={handleClosePoperMenu}>
            <Grow {...TransitionProps} timeout={350}>
              <StyledDrawingPopperMenu
                $themeColor={THEME_COLOR}
                elevation={4}
                $doxleFont={doxleFont}
              >
                <div className="drawing-title">Sheet Menu</div>

                <div
                  className="edit-menu-button"
                  onClick={(event) => {
                    event.stopPropagation();
                    nameRef.current?.focus();
                    handleClosePoperMenu();
                  }}
                >
                  <ModeIcon
                    sx={{
                      color: "inherit",
                      mr: "0.8rem",
                      fontSize: "1.6rem",
                    }}
                  />
                  Edit Name
                </div>

                <div className="delete-menu-button" onClick={handleDeleteSheet}>
                  <DeleteOutlineRoundedIcon
                    sx={{
                      color: "inherit",
                      mr: "0.8rem",
                      fontSize: "1.6rem",
                    }}
                  />
                  Delete Drawing
                </div>
              </StyledDrawingPopperMenu>
            </Grow>
          </ClickAwayListener>
        )}
      </Popper>
    </StyledDrawingsDataRow>
  );
}

export default SheetRow;
