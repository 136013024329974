import { Modal, Popover } from "@material-ui/core";
import styled from "styled-components";

import { editRgbaAlpha } from "../../Utilities/FunctionUtilities";
import { Fa9 } from "react-icons/fa6";
import {
  DoxleFont,
  DoxleTheme,
  DoxleThemeColor,
} from "../../DoxleGeneralStore/useDoxleThemeStore";
import { motion } from "framer-motion";
import Button from "@mui/material/Button";

export const StyledInventoryScreenPopover = styled(Popover)<{
  $themeColor: DoxleThemeColor;
  $currentTheme: DoxleTheme;
  $height?: string;
  $marginTop?: string;
}>`
  && {
  }

  & .MuiPopover-paper {
    box-shadow: none;
    border: 1px solid ${(p) => p.$themeColor.primaryDividerColor};
    overflow: hidden;

    background-color: ${(p) => p.$themeColor.primaryContainerColor};
    width: 30vw;
    min-width: 350px;
    max-width: 400px;
    height: 50vh;
    display: flex;
    flex-direction: column;
    min-height: 400px;
    max-height: 600px;
    padding: 2rem;
  }

  & .MuiPaper-elevation8 {
    box-shadow: none;
  }
`;

export const StyledAddInventoryScreenModal = styled(Modal)<{
  $themeColor: DoxleThemeColor;
  $currentTheme: DoxleTheme;
  $height?: string;
  $marginTop?: string;
}>`
  && {
  }

  & .MuiBox-root {
    box-shadow: none;
    border: 1px solid ${(p) => p.$themeColor.primaryDividerColor};
    width: 34.8rem;
    background-color: ${(p) =>
      p.$currentTheme === "dark" ? "#222222" : "white"};
    height: ${(p) => (p.$height ? p.$height : "auto")};
    margin-top: ${(p) => (p.$marginTop ? p.$marginTop : 0)};
    padding: 2rem;
  }
`;

export const StyledDetailTitle = styled.div<{
  $themeColor: DoxleThemeColor;
}>`
  font-size: max(12px, 1.2rem);
  color: ${(p) =>
    editRgbaAlpha({ rgbaColor: p.$themeColor.primaryFontColor, alpha: "0.4" })};
  margin-bottom: 3px;
`;

export const StyledEditInventoryInput = styled.input<{
  $themeColor: DoxleThemeColor;
  $currentTheme: DoxleTheme;
  width?: string;
  height?: string;
}>`
  border: ${(p) => (p.$currentTheme !== "dark" ? "1px solid #f9f9f9" : "none")};
  width: ${(p) => (p.width ? p.width : "17.8rem")};

  height: max(18px, 1.8rem);
  border-radius: 1px;
  font-size: max(14px, 1.4rem);
  margin-bottom: 1.5rem;
  padding: 0.5rem;
  color: ${(p) => p.$themeColor.primaryFontColor};
  background-color: ${(p) => p.$themeColor.primaryTextFieldBackgroundColor};
  &:focus {
    outline: none;
  }
`;

export const StyledSubititleTextArea = styled.textarea<{
  $themeColor: DoxleThemeColor;
  $currentTheme: DoxleTheme;
  $doxleFont: DoxleFont;
}>`
  border: none;
  width: max(178px, 17.8rem);
  height: max(4.8rem, 48px);
  resize: none;
  border-radius: 1px;
  font-size: max(14px, 1.4rem);
  margin-bottom: 1.5rem;
  padding: 0.5rem;
  color: ${(p) => p.$themeColor.primaryFontColor};
  font-family: ${(p) => p.$doxleFont.primaryFont};
  background-color: ${(p) => p.$themeColor.primaryTextFieldBackgroundColor};
  &:focus {
    outline: none;
  }
`;

export const StyledCommentTextArea = styled.textarea<{
  $themeColor: DoxleThemeColor;
  $currentTheme: DoxleTheme;
  $doxleFont: DoxleFont;
}>`
  border: none;
  border-radius: 1px;
  width: calc(100% - 1rem);
  height: max(54px, 5.4rem);
  resize: none;
  background-color: ${(p) => p.$themeColor.primaryTextFieldBackgroundColor};
  font-size: max(14px, 1.4rem);
  padding: 0.5rem;
  color: ${(p) => p.$themeColor.primaryFontColor};
  font-family: ${(p) => p.$doxleFont.primaryFont};
  &::placeholder {
    color: ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.$themeColor.primaryFontColor,
        alpha: "0.3",
      })};
  }
  &:focus {
    outline: none;
  }
`;

export const StyledInputContainer = styled.div``;

export const StyledDeleteBtn = styled(motion(Button))<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  && {
    border: none;
    color: white;
    border-radius: 3px !important;
    padding: 17px 0px !important;
    margin-top: 2rem;
    text-transform: capitalize !important;
    width: 100%;
    height: max(25px, 2.5rem);
    background: #ff2121;
    font-size: max(16px, 1.6rem);
    cursor: pointer;

    &:hover {
      background: #ff2121 !important;
      opacity: 0.8;
    }
  }
`;
export const StyledAddInventoryFileContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const StyledEditInventoryFileContainer = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
  $currentTheme: DoxleTheme;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: calc(${100 / 3}% - 0.8rem - 2px);
  aspect-ratio: 1;
  border: 1px solid ${(p) => p.$themeColor.primaryDividerColor};
  border-radius: 4px;
  margin: 0.4rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  position: relative;
  cursor: pointer;
`;

export const StyledAddNewImagePlaceholder = styled.div<{
  $currentTheme: DoxleTheme;
  $themeColor: DoxleThemeColor;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: calc(30% - 0.8rem - 2px);
  aspect-ratio: 1;
  border: 1px dashed ${(p) => p.$themeColor.primaryDividerColor};
  margin: 0.4rem;
  border-radius: 4px;
  padding-top: 1rem;
  padding-bottom: 1rem;
  position: relative;
`;

export const StyledInventoryFieldContainer = styled.div`
  overflow-y: scroll;
  width: 100%;
  flex: 1;

  &::-webkit-scrollbar {
    display: none;
  }
`;
