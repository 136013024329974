import {
  DoxleFont,
  DoxleThemeColor,
} from "../../../DoxleGeneralStore/useDoxleThemeStore";
import styled from "styled-components";
import { motion } from "framer-motion";
import FormHelperText from "@mui/material/FormHelperText";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import { DropzoneArea } from "material-ui-dropzone";
import {
  TRgbaFormat,
  editRgbaAlpha,
} from "../../../Utilities/FunctionUtilities";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import Paper, { PaperProps } from "@mui/material/Paper";

export const StyledEmailOrderDialog = styled(Dialog)<{
  $themeColor: DoxleThemeColor;
}>`
  && {
    box-shadow: 1px 1px 2px ${(p) => p.$themeColor.primaryBoxShadowColor};
  }
  & .MuiDialog-paper {
    background-color: ${(p) => p.$themeColor.primaryContainerColor};
    box-shadow: 0px 0px 12px 0px ${(p) => p.$themeColor.primaryBoxShadowColor};
    display: flex !important;
    flex-direction: column;
    width: calc(80vw - 68px) !important;
    height: calc(80vh - 28px);
    max-width: 1000px;
    min-width: 500px;
    min-height: 400px;
    max-height: 900px;
    border-radius: 9px !important;
    padding: 14px 34px;
  }
`;

export const StyledEmailOrderTitle = styled(DialogTitle)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  && {
    color: ${(p) => p.$themeColor.primaryFontColor};
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-size: max(1.6rem, 16px);
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 14px 0px;
  }
`;

export const StyledEmailContentWrapper = styled(DialogContent)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  && {
    width: calc(100%);
    margin-top: 20px;
    flex: 1;
    display: flex;
    flex-direction: column;

    overflow: auto;
    position: relative;
    padding: 0px 0px 14px 0px !important;
  }
`;

export const StyledProjectSearchTextField = styled(motion(TextField))<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  && {
    width: calc(100% - 1.6rem) !important;
    padding: 0 0.8rem;
  }
  input {
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-weight: 400;
    font-size: max(1.4rem, 14px);
    line-height: normal;

    color: ${(p) => p.$themeColor.primaryFontColor};

    &::placeholder {
      font-family: ${(p) => p.$doxleFont.primaryTitleFont};
      font-size: max(1.4rem, 14px);
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      color: ${(p) => p.$themeColor.primaryInputPlaceholderColor} !important;
      opacity: 1 !important;
    }
  }
  & .MuiInput-root:after {
    border: none !important;
  }
  & .MuiInput-root:before {
    border: none !important;
  }
  & .MuiInput-root {
    border: none !important;
  }
  & .MuiInput-root:hover {
    border: none !important;
  }
  & .MuiInput-root:hover:not(.Mui-disabled, .Mui-error):before {
    border: none !important;
  }
  & .MuiInputLabel-root.Mui-focused {
    display: none;
  }
  & .MuiInputLabel-root {
    display: none;
  }
  & .MuiInputBase-root {
    width: 100%;
  }
  & .MuiInputBase-root-MuiInput-root:after {
    border-bottom: none !important;
  }
  & .MuiInputBase-input {
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const StyledEmailFieldWrapper = styled(motion.div)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  width: calc(100% - 8px);
  padding: 8px 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid ${(p) => p.$themeColor.primaryDividerColor};
  margin-bottom: 8px;
  .label-field {
    font-family: ${(p) => p.$doxleFont.primaryTitleFont};
    font-size: max(1.4rem, 14px);
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    color: ${(p) => p.$themeColor.primaryFontColor} !important;
    margin-right: 8px;
  }
  .email-text {
    font-family: ${(p) => p.$doxleFont.primaryTitleFont};
    font-size: max(1.4rem, 14px);
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    color: ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.$themeColor.primaryFontColor,
        alpha: "0.4",
      })} !important;
    margin-left: 8px;
  }
  .add-content-wrapper {
    flex: 1;
    cursor: pointer;
    min-height: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: ${(p) => p.$doxleFont.primaryTitleFont};
    font-size: max(1.4rem, 14px);
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    color: ${(p) => p.$themeColor.primaryFontColor} !important;
  }
`;
export const StyledMailContactOption = styled(motion.div)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  width: calc(100% - 8px);
  font-family: ${(p) => p.$doxleFont.primaryTitleFont};
  font-size: max(1.4rem, 14px);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
  color: ${(p) => p.$themeColor.primaryFontColor} !important;
  padding: 4px;
  transition: all 0.4s ease;
  display: flex;
  flex-direction: column;
  align-items: flex-start !important;
  text-align: left;
  .email-text {
    font-family: ${(p) => p.$doxleFont.primaryTitleFont};
    font-size: max(1.4rem, 14px);
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    color: ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.$themeColor.primaryFontColor,
        alpha: "0.4",
      })} !important;
    margin-top: 4px;
    white-space: wrap;
    margin-left: 0px !important;
  }
`;
export const StyledSearchContactTextField = styled(TextField)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  && {
    padding: 8px 0px !important;
    border: none;

    width: calc(100% - 16px);
    min-width: 90px;
    margin-bottom: 8px;
    border-bottom: 1px solid ${(p) => p.$themeColor.primaryDividerColor};
  }
  input {
    font-family: ${(p) => p.$doxleFont.secondaryTitleFont};
    font-weight: 400;
    font-size: 1.4rem;
    line-height: normal;
    color: ${(p) => p.$themeColor.primaryFontColor};
    padding: 0px !important;
  }
  & .MuiInput-root:after {
    border-bottom: none !important;
  }
  & .MuiInput-root:before {
    border-bottom: none !important;
  }
`;
interface IContactListContainerProps
  extends Omit<
    PaperProps,
    "onDragStart" | "onAnimationStart" | "onDragEnd" | "onDrag"
  > {
  $themeColor: DoxleThemeColor;
}
const AnimatedPaper = motion(Paper);

export const StyledContactListContainer = styled(AnimatedPaper)<{
  $themeColor: DoxleThemeColor;
}>`
  && {
    background-color: ${(p) => p.$themeColor.primaryContainerColor};
    width: 300px;
    height: 30vh;
    max-height: 400px;
    min-height: 200px;
    border-radius: 0px 0px 0px9px;
    position: relative;
    padding: 1rem;
    box-shadow: 0px 0px 8px 0px ${(p) => p.$themeColor.primaryBoxShadowColor};
    display: flex;
    flex-direction: column;
    position: relative;
    border: 1px solid ${(p) => p.$themeColor.primaryDividerColor};
  }
`;
export const StyledContactListTitle = styled.span<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  color: ${(p) => p.$themeColor.primaryFontColor};
  font-family: ${(p) => p.$doxleFont.primaryFont};
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 1rem;
  text-transform: capitalize;
  padding-left: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
export const StyledSelectedContactItem = styled(motion.div)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 8px;
  border-radius: 4px;
  margin: 0px 4px;
  color: ${(p) => p.$themeColor.primaryFontColor};
  border: 1px solid ${(p) => p.$themeColor.primaryDividerColor};

  .info-wrapper {
    display: flex;
    flex-direction: column;
    color: ${(p) => p.$themeColor.primaryFontColor};
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-size: max(1.4rem, 14px);
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    text-transform: capitalize !important;
    .contact-email-text {
      font-family: ${(p) => p.$doxleFont.primaryTitleFont};
      font-size: max(1.2rem, 12px);
      font-style: normal;
      font-weight: 400;
      color: ${(p) =>
        editRgbaAlpha({
          rgbaColor: p.$themeColor.primaryFontColor,
          alpha: "0.4",
        })} !important;
      margin: 4px 0px 0px 0px;
    }
  }
`;

export const StyledMailOrderSubjectTextField = styled(TextField)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
  $boldMode?: boolean;
  $italicMode?: boolean;
}>`
  && {
    padding: 0px !important;
    border: none;

    width: calc(100% - 16px);
    min-width: 90px;
    margin-top: 2px;
  }
  input {
    font-family: ${(p) => p.$doxleFont.secondaryTitleFont};
    font-weight: 400;
    font-size: max(1.4rem, 14px);

    color: ${(p) => p.$themeColor.primaryFontColor};
    padding: 0px !important;
  }
  & .MuiInput-root:after {
    border-bottom: none !important;
  }
  & .MuiInput-root:before {
    border-bottom: none !important;
  }
  & .MuiInputBase-multiline {
    font-family: ${(p) => p.$doxleFont.secondaryTitleFont};
    font-weight: ${(p) => (p.$boldMode ? "bold" : "400")};
    font-style: ${(p) => (p.$italicMode ? "italic" : "")};
    font-size: max(1.4rem, 14px);

    color: ${(p) => p.$themeColor.primaryFontColor};
    padding: 0px !important;
  }
`;

export const StyledMailTextBodySection = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  cursor: text;
`;

export const StyledDialogActionBtnWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
export const StyledMailActionSubmitButton = styled(motion(Button))<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
  $reverseColor?: boolean;
}>`
  && {
    display: flex;
    flex-direction: row;
    min-width: 60px !important;
    cursor: pointer;
    margin: 0px 4px;
    padding: 4px 8px !important;
    border-radius: 6px !important;
    background-color: ${(p) =>
      p.$reverseColor
        ? p.$themeColor.primaryContainerColor
        : p.$themeColor.primaryFontColor};
    text-transform: capitalize !important;
    ${(p) =>
      p.$reverseColor
        ? `border:1px solid ${p.$themeColor.primaryDividerColor};`
        : ""}
    .btn-text {
      font-family: ${(p) => p.$doxleFont.primaryFont};
      font-size: max(1.4rem, 14px);
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      color: ${(p) =>
        p.$reverseColor
          ? p.$themeColor.primaryFontColor
          : p.$themeColor.primaryContainerColor} !important;
    }

    &:hover {
      background-color: ${(p) =>
        p.$reverseColor
          ? p.$themeColor.primaryContainerColor
          : p.$themeColor.primaryFontColor} !important;
      opacity: 0.95;
      transition: all 0.2s ease;
    }
  }
`;

export const StyledTextModeButton = styled(motion(Button))<{
  $themeColor: DoxleThemeColor;
  $selected?: boolean;
}>`
  && {
    display: flex;
    flex-direction: row;
    min-width: 0px !important;
    cursor: pointer;
    margin: 0px 4px;
    padding: 5px !important;
    border-radius: 4px !important;
    background-color: ${(p) => p.$themeColor.primaryTextFieldBackgroundColor};
    text-transform: capitalize !important;
    border: 1px solid
      ${(p) => (p.$selected ? p.$themeColor.doxleColor : "rgba(0,0,0,0)")};
    &:hover {
      background-color: ${(p) =>
        p.$themeColor.primaryTextFieldBackgroundColor} !important;
      opacity: 0.95;
      transition: all 0.2s ease;
    }
  }
`;

export const StyledAddAttachmentButton = styled(motion(Button))<{
  $themeColor: DoxleThemeColor;

  $doxleFont: DoxleFont;
}>`
  && {
    display: flex;
    flex-direction: row;
    min-width: 0px !important;
    cursor: pointer;
    align-self: flex-start;
    margin: 0px 4px;
    padding: 4px 8px !important;
    border-radius: 4px !important;
    background-color: ${(p) => p.$themeColor.primaryTextFieldBackgroundColor};
    text-transform: capitalize !important;
    border: 1px solid ${(p) => p.$themeColor.primaryDividerColor};
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-size: max(1.4rem, 14px);
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: ${(p) => p.$themeColor.primaryFontColor} !important;
    transition: all 0.2s ease;
    &:hover {
      background-color: ${(p) =>
        p.$themeColor.primaryTextFieldBackgroundColor} !important;
      opacity: 0.95;
      transition: all 0.2s ease;
      transform: translateY(-2px);
    }
  }
`;

export const StyledAddedAttachmentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  max-height: 300px;
  overflow: auto;
`;

export const StyledAddedAttachmentItem = styled(motion.div)<{
  $themeColor: DoxleThemeColor;

  $doxleFont: DoxleFont;
}>`
  border-radius: 4px;
  margin: 8px;
  position: relative;
  overflow: hidden;
  width: calc(20% - 18px);
  max-width: 100px;
  display: flex;
  flex-direction: column;
  border: 1px solid ${(p) => p.$themeColor.primaryDividerColor};
  .file-name {
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-size: max(1.2rem, 12px);
    font-style: normal;
    font-weight: 400;

    color: ${(p) => p.$themeColor.primaryFontColor} !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .img-holder {
    width: 100%;
    aspect-ratio: 1;
  }
  .icon-wrapper {
    width: 100%;
    aspect-ratio: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .close-icon {
    position: absolute;
    z-index: 5;
    top: 4px;
    right: 4px;
    cursor: pointer;
  }
`;
