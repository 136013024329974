import { AxiosError } from "axios";

export interface IDoxleError {
  status: 401 | 403 | 404 | 500 | 501; //!500 internal backend, 501 internal frontend (error caused by code in frontend)
  statusMessage: string;
}

export function doxleAPIErrorConverter<T>(
  error: AxiosError<T> | unknown
): IDoxleError {
  if (error instanceof AxiosError)
    return {
      status:
        error.status === 403 ||
        error.status === 404 ||
        error.status === 401 ||
        error.status === 500
          ? error.status
          : 501,
      statusMessage:
        error.status === 403 || error.status === 401
          ? "Unauthorised Action"
          : error.status === 404
          ? "Item not found"
          : "Something wrong",
    };
  else
    return {
      status: 501,
      statusMessage: "Something wrong",
    };
}
