import React from "react";
import { useProjectQAStore } from "../Store/useProjectQAStore";
import { shallow } from "zustand/shallow";

type Props = {};

interface QAProjectNav {
  handleClickRootNav: () => void;
  handleClickQAListNav: () => void;
}
const useQAProjectNav = (props: Props): QAProjectNav => {
  const { setSelectedQAList, setSelectedQAItem, setEdittedQAListItem } =
    useProjectQAStore(
      (state) => ({
        setSelectedQAList: state.setSelectedQAList,
        setSelectedQAItem: state.setSelectedQAItem,
        setEdittedQAListItem: state.setEdittedQAListItem,
      }),
      shallow
    );

  const handleClickRootNav = () => {
    setSelectedQAList(undefined);
    setSelectedQAItem(undefined);
    setEdittedQAListItem(undefined);
  };

  const handleClickQAListNav = () => {
    setSelectedQAItem(undefined);
  };
  return {
    handleClickRootNav,
    handleClickQAListNav,
  };
};

export default useQAProjectNav;
