import React from "react";
import styled from "styled-components";
import useQR from "../Hooks/useQR";
import useQRLogStore from "../Store/useQRLogStore";
import { useShallow } from "zustand/react/shallow";
import QRUserLog from "./QRUserLog/QRUserLog";
import { StyledQR, StyledTopQRSection } from "./StyledQR";
import QRViewSwitch from "./QRViewSwitch";
import QRLogFilter from "./QRLogFilter/QRLogFilter";
import QRLogSummary from "./QRLogSummary/QRLogSummary";
type Props = {};

const QR = (props: Props) => {
  const { qrView } = useQRLogStore(
    useShallow((state) => ({ qrView: state.qrView }))
  );
  const { isSuperUser } = useQR();
  return (
    <StyledQR>
      <StyledTopQRSection>
        {isSuperUser && (
          <>
            <QRViewSwitch />
            <QRLogSummary />
          </>
        )}

        <QRLogFilter />
      </StyledTopQRSection>
      <QRUserLog />
    </StyledQR>
  );
};

export default QR;
