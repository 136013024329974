import { SVGProps } from "react";
import { DoxleThemeColor } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import {
  editRgbaAlpha,
  TRgbaAlpha,
  TRgbaFormat,
} from "../../../Utilities/FunctionUtilities";
interface CustomSVGProps extends SVGProps<SVGSVGElement> {
  themeColor: DoxleThemeColor;
}

export const HashIcon = ({ themeColor, ...props }: CustomSVGProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    viewBox="0 0 12 12"
    fill="none"
    {...props}
  >
    <path
      d="M4.24347 3.31211V1H3.52344V3.31211L3.88345 3.67213L4.24347 3.31211Z"
      fill={themeColor.primaryFontColor}
      stroke={themeColor.primaryFontColor}
      strokeWidth="0.3"
    />
    <path
      d="M4.45377 4.24152H7.54991L7.90993 3.8815L7.54991 3.52148H4.45377L4.09375 3.8815L4.45377 4.24152Z"
      fill={themeColor.primaryFontColor}
      stroke={themeColor.primaryFontColor}
      strokeWidth="0.3"
    />
    <path
      d="M8.48566 3.31211V1H7.76562V3.31211L8.12564 3.67213L8.48566 3.31211Z"
      fill={themeColor.primaryFontColor}
      stroke={themeColor.primaryFontColor}
      strokeWidth="0.3"
    />
    <path
      d="M3.31211 3.52148H1V4.24152H3.31211L3.67213 3.8815L3.31211 3.52148Z"
      fill={themeColor.primaryFontColor}
      stroke={themeColor.primaryFontColor}
      strokeWidth="0.3"
    />
    <path
      d="M3.52344 8.69595V11.0001H4.24347V8.69595L3.88345 8.33594L3.52344 8.69595Z"
      fill={themeColor.primaryFontColor}
      stroke={themeColor.primaryFontColor}
      strokeWidth="0.3"
    />
    <path
      d="M8.69595 8.48761H11.0001V7.76758H8.69595L8.33594 8.1276L8.69595 8.48761Z"
      fill={themeColor.primaryFontColor}
      stroke={themeColor.primaryFontColor}
      strokeWidth="0.3"
    />
    <path
      d="M7.76562 4.45572V7.55187L8.12564 7.91188L8.48566 7.55187V4.45572L8.12564 4.0957L7.76562 4.45572Z"
      fill={themeColor.primaryFontColor}
      stroke={themeColor.primaryFontColor}
      strokeWidth="0.3"
    />
    <path
      d="M11.0001 3.52148H8.69595L8.33594 3.8815L8.69595 4.24152H11.0001V3.52148Z"
      fill={themeColor.primaryFontColor}
      stroke={themeColor.primaryFontColor}
      strokeWidth="0.3"
    />
    <path
      d="M3.31211 7.76758H1V8.48761H3.31211L3.67213 8.1276L3.31211 7.76758Z"
      fill={themeColor.primaryFontColor}
      stroke={themeColor.primaryFontColor}
      strokeWidth="0.3"
    />
    <path
      d="M4.24347 7.55187V4.45572L3.88345 4.0957L3.52344 4.45572V7.55187L3.88345 7.91188L4.24347 7.55187Z"
      fill={themeColor.primaryFontColor}
      stroke={themeColor.primaryFontColor}
      strokeWidth="0.3"
    />
    <path
      d="M7.54991 7.76758H4.45377L4.09375 8.1276L4.45377 8.48761H7.54991L7.90993 8.1276L7.54991 7.76758Z"
      fill={themeColor.primaryFontColor}
      stroke={themeColor.primaryFontColor}
      strokeWidth="0.3"
    />
    <path
      d="M7.76562 8.69595V11.0001H8.48566V8.69595L8.12564 8.33594L7.76562 8.69595Z"
      fill={themeColor.primaryFontColor}
      stroke={themeColor.primaryFontColor}
      strokeWidth="0.3"
    />
  </svg>
);

export const StatusKebabIcon = ({
  statusColor,
  ...props
}: SVGProps<SVGSVGElement> & {
  statusColor: TRgbaFormat;
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="16"
    viewBox="0 0 48 48"
    fill="none"
    {...props}
    style={{ cursor: "pointer", ...props?.style }}
  >
    <path
      d="M24.002 15.75C22.207 15.75 20.752 14.2949 20.752 12.5C20.752 10.7051 22.207 9.25 24.002 9.25C25.7969 9.25 27.252 10.7051 27.252 12.5C27.252 14.2949 25.7969 15.75 24.002 15.75ZM24.002 27.25C22.207 27.25 20.752 25.7949 20.752 24C20.752 22.2051 22.207 20.75 24.002 20.75C25.7969 20.75 27.252 22.2051 27.252 24C27.252 25.7949 25.7969 27.25 24.002 27.25ZM20.752 35.5C20.752 37.2949 22.207 38.75 24.002 38.75C25.7969 38.75 27.252 37.2949 27.252 35.5C27.252 33.7051 25.7969 32.25 24.002 32.25C22.207 32.25 20.752 33.7051 20.752 35.5Z"
      fill={editRgbaAlpha({ rgbaColor: statusColor, alpha: "0.6" })}
    />
  </svg>
);

export const DragIcon = ({ themeColor, ...props }: CustomSVGProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 8 14"
    height={18}
    fill="none"
    {...props}
  >
    <path
      fill={editRgbaAlpha({
        rgbaColor: themeColor.bolderDividerColor as TRgbaFormat,
        alpha: "1",
      })}
      fillOpacity={0.25}
      d="M6.8 11.53c.663 0 1.2.553 1.2 1.235S7.463 14 6.8 14c-.663 0-1.2-.553-1.2-1.235 0-.683.537-1.236 1.2-1.236Zm-5.6 0c.663 0 1.2.553 1.2 1.235S1.863 14 1.2 14C.537 14 0 13.447 0 12.765c0-.683.537-1.236 1.2-1.236Zm5.6-5.765C7.463 5.765 8 6.318 8 7s-.537 1.235-1.2 1.235c-.663 0-1.2-.553-1.2-1.235s.537-1.235 1.2-1.235Zm-5.6 0c.663 0 1.2.553 1.2 1.235s-.537 1.235-1.2 1.235C.537 8.235 0 7.682 0 7s.537-1.235 1.2-1.235ZM6.8 0C7.463 0 8 .553 8 1.235c0 .683-.537 1.236-1.2 1.236-.663 0-1.2-.553-1.2-1.236C5.6.553 6.137 0 6.8 0ZM1.2 0c.663 0 1.2.553 1.2 1.235 0 .683-.537 1.236-1.2 1.236-.663 0-1.2-.553-1.2-1.236C0 .553.537 0 1.2 0Z"
    />
  </svg>
);
