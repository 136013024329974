import { Popover } from "@mui/material";
import React, { useCallback, useEffect, useMemo } from "react";
import useEditDocketStatusPopover from "./Hooks/useEditDocketStatusPopover";
import {
  StyledProjectListContainer,
  StyledStatusListItemWrapper,
  StyledStatusListTitle,
} from "./StyledComponentEditDocketStatusPopover";
import { useDoxleThemeStore } from "../../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import { Components, ItemContent, Virtuoso } from "react-virtuoso";
import { DocketStatus } from "../../../../Models/dockets";
import { motion } from "framer-motion";
import DoxleEmptyPlaceHolder from "../../../../DoxleDesignPattern/DoxleEmptyPlaceHolder/DoxleEmptyPlaceHolder";
import DocketStatusItem from "./DocketStatusItem";
import { useShallow } from "zustand/react/shallow";

type Props = {
  anchorStatusListPopover: HTMLDivElement | null;

  handleCloseStatusList: () => void;
};

interface DocketStatusListContext {}
const EditDocketStatusPopover = ({
  anchorStatusListPopover,

  handleCloseStatusList,
}: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    useShallow((state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }))
  );
  const {
    docketStatusList,
    isFetchingStatusList,
    isSuccessFetchingStatusList,
    isErrorFetchingStatusList,
    showStatusList,
    setshowStatusList,
    handleClickStatus,
  } = useEditDocketStatusPopover({ handleCloseStatusList });
  const canBeOpen = Boolean(anchorStatusListPopover);
  const id = canBeOpen ? "status-menu-popper" : undefined;
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (anchorStatusListPopover) setshowStatusList(true);
      else setshowStatusList(false);
    }, 300);

    return () => clearTimeout(timeout);
  }, [anchorStatusListPopover]);

  //*render list
  const components: Components<DocketStatus, DocketStatusListContext> = useMemo(
    () => ({
      Item: (props) => (
        <StyledStatusListItemWrapper
          {...props}
          initial={{
            y: -5,
            opacity: 0,
          }}
          animate={{
            y: 0,
            opacity: 1,
          }}
          transition={{
            duration: 0.3,
          }}
        />
      ),
      EmptyPlaceholder: () => (
        <DoxleEmptyPlaceHolder
          headTitleText="No Status Item!"
          subTitleText="Add more items to manage you work..."
          containerBgColor="transparent"
          headTitleTextStyle={{
            fontSize: 14,
          }}
          subTitleTextStyle={{
            fontSize: 11,
          }}
        />
      ),
    }),
    []
  );

  const itemContent: ItemContent<DocketStatus, DocketStatusListContext> =
    useCallback(
      (_index, item, context) => {
        return (
          <DocketStatusItem
            key={`booking_${item.statusId}`}
            statusItem={item}
            handleClickStatus={handleClickStatus}
          />
        );
      },
      [handleClickStatus]
    );

  const listStyle = {
    flex: 1,
    width: "100%",
  };
  return (
    <Popover
      id={id}
      open={canBeOpen}
      anchorEl={anchorStatusListPopover}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      onClose={handleCloseStatusList}
      sx={{
        "& .MuiPopover-paper": {
          backgroundColor: "rgba(0,0,0,0) !important",
          overflow: "visible",
          boxShadow: "none",
        },
      }}
    >
      <StyledProjectListContainer $themeColor={doxleThemeColor}>
        {/* <StyledStatusListTitle
          $themeColor={doxleThemeColor}
          $doxleFont={doxleFont}
        >
          # Docket Status
        </StyledStatusListTitle> */}

        {showStatusList && (
          <Virtuoso
            style={listStyle}
            itemContent={itemContent}
            data={docketStatusList}
            components={components}
          />
        )}
      </StyledProjectListContainer>
    </Popover>
  );
};

export default EditDocketStatusPopover;
