import React from "react";
import { StyledSideMenuContainer } from "../Style/StyledProjectDrawerSideMenu";

type Props = {
  title: string;
};
const ProjectDrawerSideMenu = () => {
  return (
    <StyledSideMenuContainer> ProjectDrawerSideMenu </StyledSideMenuContainer>
  );
};

export default ProjectDrawerSideMenu;
