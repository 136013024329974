import { produce } from "immer";
import React, { useState } from "react";

type Props = {};

interface IDoxleVideoControllerProps {
  playing: boolean;
}
interface IDoxleVideoPlayer {
  videoControlProps: IDoxleVideoControllerProps;
  togglePlaying: () => void;
  handlePlay: () => void;
  handlePause: () => void;
}
const useDoxleVideoPlayer = (props?: Props): IDoxleVideoPlayer => {
  const [videoControlProps, setVideoControlProps] =
    useState<IDoxleVideoControllerProps>({
      playing: true,
    });

  const togglePlaying = () => {
    setVideoControlProps(
      produce((draft) => {
        draft.playing = !draft.playing;

        return draft;
      })
    );
    console.log("TOGGLE PLAY");
  };
  const handlePlay = () => {
    setVideoControlProps(
      produce((draft) => {
        draft.playing = true;

        return draft;
      })
    );
  };
  const handlePause = () => {
    setVideoControlProps(
      produce((draft) => {
        draft.playing = false;

        return draft;
      })
    );
  };
  return {
    videoControlProps,
    togglePlaying,
    handlePlay,
    handlePause,
  };
};

export default useDoxleVideoPlayer;
