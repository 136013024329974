import React from "react";
import {
  CompanyPermission,
  DocketPermission,
  ProjectPermission,
} from "../../../Models/permissions";
import { StyledShareUserItem } from "./StyledMenuShareUser";
import { generateInitials } from "../../../Utilities/FunctionUtilities";
import Tooltip from "@mui/material/Tooltip";
import { DoxleMUITooltipSx } from "../../../DoxleDesignPattern/MUIOverwriteSx";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";

type Props = {
  permissionItem: ProjectPermission | DocketPermission | CompanyPermission;
  color: string;
  size?: number;
};

const ShareUserItem = ({ permissionItem, color, size }: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }),
    shallow
  );
  const initialString = generateInitials(
    `${permissionItem.contact.firstName} ${permissionItem.contact.lastName}`
  );
  return (
    <Tooltip
      PopperProps={{
        sx: DoxleMUITooltipSx(doxleThemeColor, doxleFont),
      }}
      title={`${permissionItem.contact.firstName} ${permissionItem.contact.lastName}`}
    >
      <StyledShareUserItem $bgColor={color} $size={size}>
        {initialString}
      </StyledShareUserItem>
    </Tooltip>
  );
};

export default ShareUserItem;
