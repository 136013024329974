import { create } from "zustand";
import { immer } from "zustand/middleware/immer";

export interface DoxleImageGalleryItem {
  uri: string;
  thumbnailUrl?: string;
  name: string;
  size?: string;
}
interface DoxleImageGalleryStore {
  imageList: DoxleImageGalleryItem[];
  setImageList: (list: DoxleImageGalleryItem[]) => void;

  videoUrl: string | undefined;
  setVideoUrl: (url: string | undefined) => void;

  currentImageIndex: number;
  setCurrentImageIndex: (index: number) => void;

  handleSwitchPreviousImg: () => void;
  handleSwitchNextImg: () => void;

  resetGallery: () => void;
}
export const useDoxleImageGalleryStore = create(
  immer<DoxleImageGalleryStore>((set) => ({
    imageList: [],
    setImageList: (list: DoxleImageGalleryItem[]) =>
      set((state) => {
        state.imageList = list;
      }),
    currentImageIndex: 0,
    setCurrentImageIndex: (index: number) =>
      set((state) => {
        state.currentImageIndex = index;
      }),

    videoUrl: undefined,
    setVideoUrl: (url: string | undefined) =>
      set((state) => {
        state.videoUrl = url;
      }),
    handleSwitchPreviousImg: () =>
      set((state) => {
        if (state.currentImageIndex !== 0 && state.imageList.length > 0)
          state.currentImageIndex -= 1;
      }),
    handleSwitchNextImg: () =>
      set((state) => {
        if (
          state.imageList.length > 0 &&
          state.currentImageIndex !== state.imageList.length - 1
        )
          state.currentImageIndex += 1;
      }),

    resetGallery: () =>
      set((state) => {
        state.imageList = [];
        state.videoUrl = undefined;
        state.currentImageIndex = 0;
      }),
  }))
);
