import {
  DoxleFont,
  DoxleTheme,
  DoxleThemeColor,
} from "../../../DoxleGeneralStore/useDoxleThemeStore";
import styled from "styled-components";
import React from "react";
import {
  editRgbaAlpha,
  TRgbaFormat,
} from "../../../Utilities/FunctionUtilities";
import { Button, Checkbox, Dialog, TextField, Typography } from "@mui/material";
import { motion } from "framer-motion";

export const StyledAddContactBackdrop = styled.div<{
  $themeColor?: DoxleThemeColor;
  $doxleFont?: DoxleFont;
  $currentTheme?: DoxleTheme;
}>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${(p) =>
    editRgbaAlpha({
      rgbaColor:
        p?.$themeColor?.primaryReverseBackdropColor ?? "rgba(0,0,0,0.6)",
      alpha: "0.4",
    })};
  position: absolute;
  z-index: 50;
  top: 0;
  left: 0;
`;

export const StyledAddContactsDialog = styled.div<{
  $themeColor?: DoxleThemeColor;
  $doxleFont?: DoxleFont;
  $currentTheme?: DoxleTheme;
  view?: "Contact" | "ContactCompany" | "Xero";
}>`
  width: ${(p) =>
    p?.view === "ContactCompany" ? "Min(80vw, 800px)" : "Min(80vw, 600px)"};
  height: ${(p) =>
    p?.view === "ContactCompany"
      ? "Min(85vw, 1000px)"
      : p?.view === "Contact"
      ? "Min(80vh, 600px)"
      : "Min(80vh, 750px)"};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${(p) =>
    p?.$themeColor?.primaryContainerColor ?? "rgba(252, 252, 253, 1)"};
  padding: 40px;
  border-radius: 8px;
  z-index: 100;
  border: 1px solid ${(p) => p.$themeColor?.primaryDividerColor};
`;

export const StyledTitleContainer = styled.div<{
  $themeColor?: DoxleThemeColor;
  $doxleFont?: DoxleFont;
  $currentTheme?: DoxleTheme;
}>`
  width: 100%;
  font-size: 30px;
  font-family: ${(p) => p?.$doxleFont?.primaryTitleFont ?? "Inter"};
  font-weight: 800;
  color: ${(p) => p?.$themeColor?.primaryFontColor ?? "rgba(0, 0, 0, 1)"};
  text-align: center;
  margin-bottom: 2rem;
`;
export const StyledSubTitleContainer = styled.div<{
  $themeColor?: DoxleThemeColor;
  $doxleFont?: DoxleFont;
  $currentTheme?: DoxleTheme;
}>`
  width: 100%;
  font-size: 13px;
  font-family: ${(p) => p?.$doxleFont?.subTitleFont ?? "IBM Plex Mono"};
  font-weight: 500;
  color: ${(p) =>
    editRgbaAlpha({
      rgbaColor: p?.$themeColor?.primaryFontColor ?? "rgba(0, 0, 0, 1)",
      alpha: "0.7",
    })};
  margin-bottom: 1rem;
`;
export const StyledFormContainer = styled.div<{
  $themeColor?: DoxleThemeColor;
  $doxleFont?: DoxleFont;
  $currentTheme?: DoxleTheme;
}>`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
export const StyledFormHeader = styled.div<{
  $themeColor?: DoxleThemeColor;
  $doxleFont?: DoxleFont;
  $currentTheme?: DoxleTheme;
}>`
  margin-top: 2rem;
  font-size: 14px;
  font-family: ${(p) => p?.$doxleFont?.primaryTitleFont ?? "Inter"};
  font-weight: 600;
  color: ${(p) => p?.$themeColor?.primaryFontColor ?? "rgba(0, 0, 0, 1)"};
`;
export const StyledFormSubHeader = styled.div<{
  $themeColor?: DoxleThemeColor;
  $doxleFont?: DoxleFont;
  $currentTheme?: DoxleTheme;
}>`
  margin-bottom: 0.5rem;
  font-size: 11px;
  font-family: ${(p) => p?.$doxleFont?.subTitleFont ?? "IBM Plex Mono"};
  font-weight: 400;
  color: ${(p) =>
    editRgbaAlpha({
      rgbaColor: p?.$themeColor?.primaryFontColor ?? "rgba(0, 0, 0, 1)",
      alpha: "0.7",
    })};
`;

export const StyledSyncControllerContainer = styled.div<{
  $themeColor?: DoxleThemeColor;
  $doxleFont?: DoxleFont;
  $currentTheme?: DoxleTheme;
}>`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-content: start;
  justify-content: center;
  height: 100px;
`;
export const StyledSyncText = styled.div<{
  $themeColor?: DoxleThemeColor;
  $doxleFont?: DoxleFont;
  $currentTheme?: DoxleTheme;
}>`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-content: start;
  justify-content: center;
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 5rem;
  font-size: 12px;
  font-family: "Inter";
  font-weight: 500;
  color: ${(p) => p?.$themeColor?.doxleColor ?? "#7070ff"};
`;

export const StyledSyncDoxleLogoContainer = styled.div<{
  $themeColor?: DoxleThemeColor;
  $doxleFont?: DoxleFont;
  $currentTheme?: DoxleTheme;
}>`
  width: 30%;
  max-width: 30%;
  display: flex;
  flex-direction: row;
  align-content: end;
  justify-content: end;
  height: 100px;
`;
export const StyledSyncButtonContainer = styled.div<{
  $themeColor?: DoxleThemeColor;
  $doxleFont?: DoxleFont;
  $currentTheme?: DoxleTheme;
}>`
  width: 30%;
  max-width: 30%;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  height: 100px;
  cursor: pointer;
`;

export const StyledSyncXeroLogoContainer = styled.div<{
  $themeColor?: DoxleThemeColor;
  $doxleFont?: DoxleFont;
  $currentTheme?: DoxleTheme;
}>`
  width: 30%;
  max-width: 30%;
  display: flex;
  flex-direction: row;
  align-content: start;
  justify-content: start;
  height: 100px;
`;

export const StyledButtonContainer = styled.div<{
  $themeColor?: DoxleThemeColor;
  $doxleFont?: DoxleFont;
  $currentTheme?: DoxleTheme;
}>`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const StyledCancelButton = styled(Button)<{
  $themeColor?: DoxleThemeColor;
  $doxleFont?: DoxleFont;
  $currentTheme?: DoxleTheme;
}>`
  && {
    min-width: 100px;
    height: 35px;
    background-color: ${(p) =>
      p?.$themeColor?.primaryFontColor ?? "rgba(0,0,0,1)"};
    border-radius: 8px;
    padding: 2px 14px;
    font-family: ${(p) => p?.$doxleFont?.primaryFont ?? "Inter"};
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.05em;
    color: ${(p) =>
      p?.$themeColor?.primaryReverseFontColor ?? "rgba(255,255,255,1)"};
    text-transform: capitalize;
    margin-top: 14px;
    margin: 10px;
  }
  &&:hover {
    background-color: ${(p) =>
      p?.$themeColor?.primaryFontColor ?? "rgba(0,0,0,1)"};
    opacity: 0.8;
    transition: 0.5s;
    transform: translateY(-5px) scale(1.05);
  }
`;

export const StyledSyncButton = styled(Button)<{
  $themeColor?: DoxleThemeColor;
  $doxleFont?: DoxleFont;
  $currentTheme?: DoxleTheme;
}>`
  && {
    min-width: 100px;
    height: 35px;
    background-color: ${(p) => p?.$themeColor?.doxleColor ?? "#7070ff"};
    border-radius: 8px;
    padding: 2px 14px;
    font-family: ${(p) => p?.$doxleFont?.primaryFont ?? "Inter"};
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.05em;
    color: ${(p) =>
      p?.$themeColor?.primaryReverseFontColor ?? "rgba(255,255,255,1)"};
    text-transform: capitalize;
    margin: 10px;
  }
  &&:hover {
    background-color: ${(p) => p?.$themeColor?.doxleColor ?? "#7070ff"};
    opacity: 0.8;
    transition: 0.5s;
    transform: translateY(-5px) scale(1.05);
  }
`;

export const StyledContactAddNewWithoutCompanyDialog = styled(Dialog)`
  & .MuiDialog-paper {
    width: 800px;
    min-width: 700px;
    max-width: none;
    height: fit-content;
    box-shadow: none !important;
  }
`;

export const StyledContactsAddNewRootContainer = styled.div<{
  $themeColor: DoxleThemeColor;
}>`
  && {
    width: calc(100% - 40px);
    height: calc(100% - 40px);
    display: flex;
    padding: 20px;
    flex-direction: column;
    position: relative;
  }
`;

export const StyledAddContactTopLeftDiv = styled.div`
  && {
    width: 153px;
    height: 28px;
    position: absolute;
    top: -40px;
    left: -40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #7070ff;
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    color: white;
    letter-spacing: 0.05em;
  }
`;

export const StyledContactsAddNewInputDiv = styled.div<{
  width: string;
  marginTop?: string;
}>`
  && {
    width: ${(p) => p.width};
    display: flex;
    flex-direction: column;
    margin-top: ${(p) => (p.marginTop ? p.marginTop : "35px")};
  }
`;

export const StyledContactsAddNewInputTitleText = styled(Typography)<{
  marginBottom?: string;
  paddingRight?: string;
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  && {
    font-family: ${(p) => p.$doxleFont.secondaryFont};
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.05em;
    margin-bottom: ${(p) => (p.marginBottom ? p.marginBottom : "3px")};
    padding-right: ${(p) => (p.paddingRight ? p.paddingRight : "0px")};
    color: ${(p) => p.$themeColor.primaryFontColor};
    text-transform: uppercase;
  }
`;

export const StyledContactsAddNewPrimaryContactDiv = styled.div`
  && {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
`;

export const StyledContactsAddToCompanyButton = styled(Button)<{
  bgColor: string;
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  && {
    display: flex;
    height: 20px;
    font-family: ${(p) => p.$doxleFont.secondaryFont};
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.05em;
    color: ${(p) => (p.bgColor === "#f7f7f7" ? "black" : "white")};
    margin-right: 10px;
  }
  &.MuiButton-root {
    background-color: ${(p) => p.bgColor};
  }
  &.MuiButtonBase-root {
  }
  &.MuiButton-root:hover {
    background: ${(p) => p.bgColor};
  }
`;
export const StyledContactsAddNewPrimaryContactTitleDiv = styled.div`
  && {
    display: flex;
    padding-top: 20px;
    padding-bottom: 20px;
    width: 100%;
  }
`;
export const StyledContactsAddNewTopButtonsDiv = styled.div`
  && {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
`;
export const ContactsAddMoreCheckBox = styled(Checkbox)`
  && {
    width: 10px;
    height: 10px;
    margin-right: 5px;
  }
  input {
    opacity: 100%;
  }
`;
export const StyledContactsSubmitButton = styled(Button)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  && {
    display: flex;
    width: 100%;
    height: 40px;
    font-family: ${(p) => p.$doxleFont.secondaryFont};
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.05em;
    color: ${(p) => p.$themeColor.primaryReverseFontColor};
    margin-top: 30px;
  }
  &.MuiButton-root {
    background-color: ${(p) => p.$themeColor.primaryReverseContainerColor};
  }
  &.MuiButtonBase-root {
  }
  &.MuiButton-root:hover {
    background: ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.$themeColor.primaryReverseContainerColor as TRgbaFormat,
        alpha: "0.8",
      })};
    transition: 0.4s;
  }
`;

export const StyledContactsAddNewTextField = styled(TextField)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  && {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  & .MuiAutocomplete-option {
    font-family: ${(p) => p.$doxleFont.secondaryFont};
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: ${(p) => p.$themeColor.primaryFontColor};
  }
`;

export const StyledContactsAddNewInputText = styled(TextField)<{
  width: string;
  height?: string;
  inputHeight?: string;
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  && {
    width: ${(p) => p.width};
    height: ${(p) => (p.height ? p.height : "50px")};
    background-color: ${(p) => p.$themeColor.primaryTextFieldBackgroundColor};

    display: flex;
  }
  & .MuiOutlinedInput-input {
    padding: 0 !important;
    font-family: ${(p) => p.$doxleFont.secondaryFont};
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: ${(p) => p.$themeColor.primaryFontColor};
  }
  & .MuiOutlinedInput-notchedOutline {
    border: ${(p) =>
      p.error ? `1px solid ${p.$themeColor.errorColor} ` : "none !important"};
  }
  input {
    width: ${(p) => p.width};
    height: ${(p) => (p.inputHeight ? p.inputHeight : "50px")};

    outline: none !important;
    margin-left: 20px;
    margin-right: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &::placeholder {
      text-transform: capitalize !important;
    }
  }
`;
export const StyledTextfieldErrorText = styled(motion.span)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  font-family: ${(p) => p.$doxleFont.secondaryFont};
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.05em;
  color: ${(p) => p.$themeColor.errorColor};
  margin-left: 14px;
  text-transform: capitalize;
  margin-top: 4px;
`;
export const StyledContactsAddMoreCheckBox = styled(Checkbox)`
  && {
    width: 10px;
    height: 10px;
    margin-right: 5px;
  }
  input {
    opacity: 100%;
  }
`;

export const StyledContactForm = styled.form`
  width: 100%;
  height: 100%;
`;
export const StyledContactCompanyFormContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
export const StyledContactAddContactSection = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  overflow-y: scroll;
  margin: 8px 0px;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;
export const StyledContactsDeleteButton = styled(Button)`
  && {
    height: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
  }
  &.MuiButton-root {
    color: grey;
  }
  &.MuiButtonBase-root {
  }
  &.MuiButton-root:hover {
    background-color: transparent;
  }
`;
export const StyledContactsAddAnotherContactButton = styled(Button)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  && {
    display: flex;
    width: 300px;
    font-family: ${(p) => p.$doxleFont.secondaryFont};
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: ${(p) => p.$themeColor.doxleColor};
    letter-spacing: 0.05em;
    text-decoration-line: underline;
    text-align: left;
    justify-content: flex-start;
    text-transform: capitalize;
  }
  &.MuiButton-root {
    background-color: transparent;
    padding: 0px !important;
  }
  &.MuiButtonBase-root {
    padding: 0px !important;
  }
  &.MuiButton-root:hover {
    background: transparent;
  }
`;
export const StyledLoadingBottomContainer = styled.div`
  width: 100%;
  bottom: 0;
  left: 0;
  position: absolute;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
`;
