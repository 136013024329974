import React, { useEffect, useState } from "react";
import { useEditOrderStore } from "../../../OrdersStore/useEditOrderStore";
import { shallow } from "zustand/shallow";
import { useDoxleCurrentContextStore } from "../../../../DoxleGeneralStore/useDoxleCurrentContext";
import useDoxleNotificationStore from "../../../../DoxleGeneralStore/useDoxleNotificationStore";
import { useOrdersStore } from "../../../OrdersStore/useOrdersStore";
import useSetOrderDetailQueryData from "../../../Hooks/useSetOrderDetailQueryData";
import Order from "../../../Models/order";
import OrdersQueryAPI from "../../../QueryHooks/OrdersQueryAPI";
import useGetUserInfo from "../../../../CoreContent/QueryDataHooks/GetQueryDataHooks/useGetUserInfo";

type Props = {};

interface AcceptOrderDialog {
  onEditSignatureMode: boolean;
  setOnEditSignatureMode: React.Dispatch<React.SetStateAction<boolean>>;
  handleAcceptOrder: () => void;
  isUpdatingOrder: boolean;
  shouldShowError: boolean;
}
const useAcceptOrderDialog = (props: Props): AcceptOrderDialog => {
  const [onEditSignatureMode, setOnEditSignatureMode] = useState(false);
  const [shouldShowError, setShouldShowError] = useState(false);
  const {
    updateEditedOrder,
    editedOrder,
    setShowAcceptOrderDialog,
    showAcceptOrderDialog,
  } = useEditOrderStore(
    (state) => ({
      updateEditedOrder: state.updateEditedOrder,
      editedOrder: state.editedOrder,
      setShowAcceptOrderDialog: state.setShowAcceptOrderDialog,
      showAcceptOrderDialog: state.showAcceptOrderDialog,
    }),
    shallow
  );
  const company = useDoxleCurrentContextStore(
    (state) => state.currentCompany,
    shallow
  );

  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );
  const { queryFilter } = useOrdersStore(
    (state) => ({ queryFilter: state.queryFilter }),
    shallow
  );
  const { handleUpdatePartialOrder } = useSetOrderDetailQueryData({
    orderId: editedOrder?.orderId ?? "",
  });
  const onSuccessUpdateOrder = (order?: Order) => {
    if (order) {
      updateEditedOrder(order);
      handleUpdatePartialOrder(order);
    }
    setShowAcceptOrderDialog(undefined);
  };

  const { patchOrder } = OrdersQueryAPI.useMutateOrderQuery({
    company,
    showNotification,
    filter: queryFilter,
    onUpdateSuccessCb: onSuccessUpdateOrder,
  });
  const { user } = useGetUserInfo();
  const handleAcceptOrder = () => {
    if (!user || !user.signature) {
      setShouldShowError(true);
    } else if (editedOrder) {
      patchOrder.mutate({
        orderId: editedOrder.orderId,
        order: { accepted: showAcceptOrderDialog === "accept" ? true : false },
      });
    }
  };
  useEffect(() => {
    if (onEditSignatureMode) setShouldShowError(false);
  }, [onEditSignatureMode]);
  useEffect(() => {
    if (!showAcceptOrderDialog) {
      setOnEditSignatureMode(false);
      setShouldShowError(false);
    }
  }, [showAcceptOrderDialog]);
  return {
    onEditSignatureMode,
    setOnEditSignatureMode,
    handleAcceptOrder,
    isUpdatingOrder: patchOrder.isLoading,
    shouldShowError,
  };
};

export default useAcceptOrderDialog;
