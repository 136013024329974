import DialogTitle from "@mui/material/DialogTitle";
import styled from "styled-components";
import {
  DoxleFont,
  DoxleThemeColor,
} from "../../../../DoxleGeneralStore/useDoxleThemeStore";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { editRgbaAlpha } from "../../../../Utilities/FunctionUtilities";
import { motion } from "framer-motion";

export const StyledQASharedDialog = styled(Dialog)<{}>`
  && {
    background-color: rgba(0, 0, 0, 0.8);
  }
  & .MuiDialog-paper {
    background-color: ${(p) => p.theme.color.primaryContainerColor};
    box-shadow: 0px 0px 14px 0px ${(p) => p.theme.color.primaryBoxShadowColor};
    border: 1px solid ${(p) => p.theme.color.primaryDividerColor};
    max-width: 90rem !important;
    min-width: 400px !important;
    width: 72vw !important;
  }
`;
export const StyledQAShareDialogTitle = styled(DialogTitle)<{}>`
  && {
    font-family: ${(p) => p.theme.font.primaryFont};
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 30px;

    color: ${(p) => p.theme.color.primaryFontColor};
    border-bottom: 1px solid ${(p) => p.theme.color.primaryDividerColor};
    background-color: ${(p) => p.theme.color.primaryContainerColor};
    width: calc(100% - 28px);
    padding: 14px !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

export const StyledQAShareDialogContent = styled(DialogContent)<{
  $themeColor: DoxleThemeColor;
}>`
  && {
    background-color: ${(p) => p.$themeColor.primaryContainerColor};

    word-break: break-word;
    width: calc(100% - 2.8rem) !important;
    padding: 0.8rem 1.4rem !important;
    display: flex;
    flex-direction: column;
  }
`;

export const StyledQAShareDialogActions = styled(DialogActions)<{
  $themeColor: DoxleThemeColor;
}>`
  && {
    background-color: ${(p) => p.$themeColor.primaryContainerColor};
    width: 100%;
    padding: 0px !important;
    display: flex;
  }
`;

export const StyledSharedPdfContentSection = styled.div<{}>`
  height: 72vh;
  min-height: 500px;
  padding-bottom: 30px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  .content-wrapper {
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .loader-bar {
    width: 100%;
    max-width: 40rem;
    margin-bottom: 1.4rem;
  }

  .loader-text {
    font-family: ${(p) => p.theme.font.primaryFont};
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: ${(p) => p.theme.color.primaryFontColor};
    display: flex;
  }

  .page-switcher {
    position: absolute;
    bottom: 14px;
    z-index: 10;
    display: flex;
    align-items: center;
    border-radius: 0.4rem;
    padding: 0.8rem;
    background-color: ${(p) => p.theme.color.primaryContainerColor};
    box-shadow: 0px 0px 4px 0px ${(p) => p.theme.color.primaryBoxShadowColor};
    .page-num-text {
      font-family: ${(p) => p.theme.font.primaryFont};
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      color: ${(p) => p.theme.color.primaryFontColor};
      display: flex;
      margin: 0px 14px;
    }

    .icon-switcher {
      cursor: pointer;
      transition: 0.2s;
      cursor: pointer;
      &:hover {
        transform: scale(1.1);
        transition: 0.2s;
        color: ${(p) => p.theme.color.doxleColor} !important;
      }
    }
  }
`;

export const StyledSelectSharedAssignee = styled.div<{
  $isNull: boolean;
}>`
  max-width: 70%;
  align-self: flex-start;
  cursor: pointer;
  display: flex;
  padding: 8px;
  background-color: ${(p) => p.theme.color.primaryContainerColor};
  box-shadow: 0px 0px 4px 0px ${(p) => p.theme.color.primaryBoxShadowColor};

  border-radius: 4px;
  align-items: center;
  margin: 8px 0px;
  .assignee-text {
    font-family: ${(p) => p.theme.font.primaryFont};
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: ${(p) =>
      p.$isNull
        ? editRgbaAlpha({
            rgbaColor: p.theme.color.primaryFontColor,
            alpha: "0.4",
          })
        : p.theme.color.primaryFontColor};
    margin-right: 8px;
  }
  .icon {
    color: ${(p) =>
      p.$isNull
        ? editRgbaAlpha({
            rgbaColor: p.theme.color.primaryFontColor,
            alpha: "0.4",
          })
        : p.theme.color.primaryFontColor};
    margin-right: 4px;
    cursor: pointer;
  }
`;
export const StyledPdfPage = styled.div<{ $themeColor: DoxleThemeColor }>`
  width: 100%;
  height: 100%;
  border-bottom: 1px solid ${(p) => p.$themeColor.primaryDividerColor};
  background-color: ${(p) => p.$themeColor.primaryContainerColor};
`;

export const StyledSharedMenuWrapper = styled(motion.div)`
  width: 100%;
  padding: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: center;

  .share-button {
    padding: 0.2rem;
    width: 4.5rem;
    height: 4.5rem;
    margin: 0.2rem;
    border-radius: 50%;
    transition: 0.2s;
    &:hover {
      transform: scale(1.2);
      transition: 0.2s;
    }
  }
`;
