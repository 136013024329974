import {
  Button,
  Chip,
  FormHelperText,
  Paper,
  PaperProps,
  TextField,
  Typography,
} from "@mui/material";
import styled from "styled-components";
import {
  DoxleFont,
  DoxleTheme,
  DoxleThemeColor,
} from "../../DoxleGeneralStore/useDoxleThemeStore";
import { TRgbaFormat, editRgbaAlpha } from "../../Utilities/FunctionUtilities";
import { motion } from "framer-motion";

export const StyledContactAutocompleteTitleText = styled(Typography)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  && {
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-weight: 500;
    font-size: 1.4rem;
    line-height: normal;
    letter-spacing: 0.0075em;
    color: ${(p) => p.$themeColor.primaryFontColor};
  }
`;
export const StyledContactsAutocompleteTextField = styled(TextField)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
  $bottomMargin?: number;
  $hidden?: boolean;
}>`
  && {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    margin-bottom: ${(p) => p?.$bottomMargin ?? 0}px;
    max-height: ${(p) => (p?.$hidden ? 0 : 600)}px;
    overflow-y: hidden;
    transition: max-height 700ms;
  }
  input {
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-weight: 500;
    font-size: 1.4rem;
    line-height: normal;
    color: ${(p) => p.$themeColor.primaryFontColor};
  }
  & .MuiInput-root:after {
    border-bottom: none !important;
  }
  & .MuiInput-root:before {
    border-bottom: none !important;
  }
  & .MuiInput-root {
    border-bottom: 0.5px solid
      ${(p) =>
        editRgbaAlpha({
          rgbaColor: p.$themeColor.primaryFontColor as TRgbaFormat,
          alpha: "0.4",
        })};
  }
  & .MuiInput-root:hover:not(.Mui-disabled, .Mui-error):before {
    border-bottom: 0.5px solid ${(p) => p.$themeColor.primaryFontColor};
  }
  & .MuiInputLabel-root.Mui-focused {
    color: ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.$themeColor.primaryFontColor as TRgbaFormat,
        alpha: "0.4",
      })};
    font-size: 1.2rem !important;
  }
  & .MuiInputLabel-root {
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 110%;
    letter-spacing: 0.0075em;
    transition: 0.4s !important;
    color: ${(p) => p.$themeColor.primaryInputPlaceholderColor};
  }
`;

export const StyledContactsMailAutocompleteTextField = styled(TextField)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
  $bottomMargin?: number;
  $hidden?: boolean;
}>`
  && {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    margin-bottom: ${(p) => p?.$bottomMargin ?? 0}px;
    max-height: ${(p) => (p?.$hidden ? 0 : 600)}px;
    overflow-y: hidden;
    transition: max-height 700ms;
    background-color: ${(p) => p.$themeColor.primaryBackgroundColor};
    border: none;
    padding-left: 10px;
    width: calc(100% - 10px);
    min-width: 90px;
  }
  input {
    font-family: ${(p) => p.$doxleFont.secondaryTitleFont};
    font-weight: 500;
    font-size: 1.4rem;
    line-height: normal;
    color: ${(p) => p.$themeColor.primaryFontColor};
  }
  & .MuiInput-root:after {
    border-bottom: none !important;
  }
  & .MuiInput-root:before {
    border-bottom: none !important;
  }

  & .MuiInput-root:hover:not(.Mui-disabled, .Mui-error):before {
    border-bottom: 0.5px solid ${(p) => p.$themeColor.primaryFontColor};
  }
  & .MuiInputLabel-root.Mui-focused {
    color: ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.$themeColor.primaryFontColor as TRgbaFormat,
        alpha: "0.4",
      })};
    font-size: 1.2rem !important;
  }
  & .MuiInputLabel-root {
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 110%;
    letter-spacing: 0.0075em;
    transition: 0.4s !important;
    color: ${(p) => p.$themeColor.primaryInputPlaceholderColor};
  }
`;

export const StyledAlternateContactsAutocompleteTextField = styled(TextField)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  && {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    height: 100%;
  }
  input {
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-weight: 500;
    font-size: 1.4rem;
    line-height: normal;
    letter-spacing: 0.0075em;
    height: 100%;
    color: ${(p) => p.$themeColor.primaryFontColor};
  }
  & .MuiInputLabel-root.Mui-focused {
    color: ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.$themeColor.primaryFontColor as TRgbaFormat,
        alpha: "0.4",
      })};
    font-size: 1.2rem !important;
  }
  & .MuiInputLabel-root {
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 110%;
    letter-spacing: 0.0075em;
    transition: 0.4s !important;
    color: ${(p) => p.$themeColor.primaryInputPlaceholderColor};
  }
`;

export const StyledContactCompanyList = styled(motion(Paper))<{
  $themeColor: DoxleThemeColor;
}>`
  && {
    border-radius: 4px;
    max-height: 500px;

    background-color: ${(p) => p.$themeColor.primaryContainerColor} !important;
    box-shadow: 1px 1px 4px ${(p) => p.$themeColor.primaryBoxShadowColor};
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
`;
export const StyledContactCompanyOption = styled(motion.li)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  background-color: transparent;
  text-transform: capitalize !important;
  &:hover {
    background-color: ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.$themeColor.doxleColor,
        alpha: "0.4",
      })} !important;
    color: white !important;
  }
`;
interface AutocompleteProps
  extends Omit<
    PaperProps,
    "onDragStart" | "onAnimationStart" | "onDragEnd" | "onDrag"
  > {
  $themeColor: DoxleThemeColor;
}
const MotionPaper = motion(Paper);
export const StyledAutocompleteList = styled(
  ({ $themeColor, ...props }: AutocompleteProps) => (
    <MotionPaper
      variants={{
        entering: {
          y: [10, 0],
          opacity: [0, 1],
          transition: {
            duration: 0.4,
          },
        },
        exiting: {
          y: [0, 10],
          opacity: [1, 0],
          transition: {
            duration: 0.4,
          },
        },
      }}
      initial={false}
      animate="entering"
      layout
      exit="exiting"
      {...props}
    />
  )
)`
  && {
    border-radius: 0px 1.4rem;
    max-height: 500rem;
    width: 100%;
    background-color: ${(p) => p.$themeColor.primaryContainerColor} !important;
    box-shadow: 0px 0px 8px 0px ${(p) => p.$themeColor.primaryBoxShadowColor};
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
`;
export const StyledContactOption = styled(motion.li)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>``;
export const StyledErrorHelperText = styled(motion(FormHelperText))<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  && {
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 0.05em;
    text-transform: capitalize;
    color: ${(p) => p.$themeColor.errorColor};
    margin: 4px;
  }
`;
export const StyledAutocompleteContactValueTagContainer = styled(motion(Chip))<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  && {
    height: 35px;
    border-radius: 14px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.05em;
    text-transform: capitalize;
    color: ${(p) => p.$themeColor.primaryReverseBackdropColor};
    margin: 2px;
    border-color: ${(p) => p.$themeColor.doxleColor} !important;
  }
`;

export const StyledInviteContainer = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 530px;
  height: 15rem;
  border: none;
  border-radius: 0px 4px 4px 4px;
  padding: 20px;
  font-weight: 600;
  color: ${(p) => p.$themeColor.primaryFontColor};
  font-family: ${(p) => p.$doxleFont.primaryFont};
  font-size: 12px;
  text-align: left;
  justify-content: start;
  overflow-y: hidden;
  margin-bottom: 20px;
  background-position: 0 -102%;
  &.focussed {
    height: 50rem;
    background-position: 0 0;
    background-color: ${(p) =>
      editRgbaAlpha({
        rgbaColor: p?.$themeColor.primaryReverseFontColor,
        alpha: "0.7",
      })} !important;
  }
  -webkit-transition: all 0.3s linear;
  -ms-transition: all 0.3s linear;
  transition: all 0.3s linear;
`;

export const StyledInviteContentContainer = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  height: 50rem;
  width: 100%;
`;

export const StyledHeaderText = styled.p<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  margin-bottom: 15px;
  font-weight: 600;
  color: ${(p) => p.$themeColor.primaryFontColor};
  font-family: ${(p) => p.$doxleFont.primaryFont};
  font-size: 12px;
`;

export const StyledLowerIconSection = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
  $emailShown: boolean;
}>`
  display: flex;
  flex-direction: column;
  border: none;
  border-radius: 0px 4px 4px 4px;
  padding: 20px;
  color: ${(p) => p.$themeColor.primaryFontColor};
  font-family: ${(p) => p.$doxleFont.primaryFont};
  font-weight: 500;
  font-size: 13px;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin-top: ${(p) => (p.$emailShown ? "0px" : "20px")};
  transition: margin-top 500ms;
`;

export const StyledInputRow = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  width: 100%;
`;
export const StyledInputColumn = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: Calc(100% - 77px);
`;
export const StyledAddContactButton = styled(Button)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
  $wide?: boolean;
}>`
  border: 1px solid
    ${(p) =>
      p.disabled
        ? p.$themeColor.primaryBackgroundColor
        : p.$themeColor.doxleColor} !important;
  background-color: ${(p) =>
    p.disabled
      ? p.$themeColor.primaryBackgroundColor
      : p.$themeColor.doxleColor} !important;
  color: white !important;
  text-transform: none !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  font-family: ${(p) => p.$doxleFont.primaryFont} !important;
  width: ${(p) => (p.$wide ? 110 : 60)}px;
  height: 25px;
  cursor: ${(p) => (p.disabled ? "default" : "pointer")} !important;
  transition: width 70ms;
`;

export const StyledFoundContactsColumn = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: start;
  width: 100%;
`;
export const StyledContactEmailCard = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  display: flex;
  flex-direction: row;
  justify-content: start;
  background-color: ${(p) => p.$themeColor.primaryBackgroundColor};
  color: ${(p) =>
    editRgbaAlpha({ rgbaColor: p.$themeColor.doxleColor, alpha: "0.7" })};
  font-family: ${(p) => p.$doxleFont.primaryFont};
  width: 100%;
  font-weight: 500;
  font-size: 10px;
  height: 17px;
  border: none;
  border-radius: 0px 4px 4px 4px;
  line-height: 12px;
  padding: 3px 5px 0px 5px;
  margin: 2px;
  cursor: pointer;
`;

export const StyledContactsTextField = styled(TextField)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
  $bottomMargin?: number;
  $hidden?: boolean;
}>`
  && {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    margin-bottom: ${(p) => p?.$bottomMargin ?? 0}px;
    max-height: ${(p) => (p?.$hidden ? 0 : 60)}px;
    // display: ${(p) => (p?.$hidden ? "none" : "unset")};
    overflow-y: hidden;
    transition: max-height 700ms;
    margin-right: 20px !important;
    border-radius: 0px 4px 4px 4px;
    border: ${(p) =>
      p?.$hidden ? "none" : "1px solid " + p.$themeColor.primaryDividerColor};
    background-color: ${(p) => p.$themeColor.primaryContainerColor};
  }
  input {
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-weight: 500;
    font-size: 1.4rem;
    line-height: normal;
    letter-spacing: 0.0075em;
    color: ${(p) => p.$themeColor.primaryFontColor};
  }

  & .MuiInput-root {
    border-bottom: none !important;
  }
  & .MuiInputLabel-root.Mui-focused {
    color: ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.$themeColor.primaryFontColor as TRgbaFormat,
        alpha: "0.4",
      })};
    font-size: 1.2rem !important;
  }
  & .MuiInputLabel-root {
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 110%;
    letter-spacing: 0.0075em;
    transition: 0.4s !important;
    color: ${(p) => p.$themeColor.primaryInputPlaceholderColor};
  }
  & .MuiInput-root:hover:not(.Mui-disabled, .Mui-error):before {
    border-bottom: none !important;
    ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.$themeColor.primaryFontColor as TRgbaFormat,
        alpha: "0.4",
      })};
  }
  & .MuiInputLabel-root.Mui-focused {
    border-bottom: none !important;
    color: ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.$themeColor.primaryFontColor as TRgbaFormat,
        alpha: "0.4",
      })};
    font-size: 1.2rem !important;
  }
  & .MuiInput-root:after {
    border-bottom: none !important;
  }
  & .MuiInput-root:hover {
    border-bottom: none !important;
  }
  & .MuiInput-underline:after {
    border-bottom: none !important;
  }
  & .MuiInput-underline::after {
    border-bottom: none !important;
  }
  & .MuiInput-underline:before {
    border-bottom: none !important;
  }
  & .MuiInput-underline::before {
    border-bottom: none !important;
  }
`;

export const StyledContactCardText = styled.div`
  padding-left: 10px;
  width: 50%;
  text-overflow: ellipsis;
  overflow-x: hidden;
`;

export const StyledDialogBackdrop = styled.div<{
  $themeColor: DoxleThemeColor;
}>`
  height: 100vh;
  width: 100vw;
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  background-color: ${(p) => p.$themeColor.primaryBackdropColor};
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
export const StyledAddContactsDialog = styled.div<{
  $themeColor?: DoxleThemeColor;
  $doxleFont?: DoxleFont;
  $currentTheme?: DoxleTheme;
  view?: "Contact" | "ContactCompany" | "Xero";
}>`
  width: ${(p) =>
    p?.view === "ContactCompany" ? "Min(80vw, 800px)" : "Min(80vw, 600px)"};
  height: ${(p) =>
    p?.view === "ContactCompany"
      ? "Min(85vw, 1000px)"
      : p?.view === "Contact"
      ? "Min(80vh, 600px)"
      : "Min(80vh, 750px)"};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${(p) =>
    p?.$themeColor?.primaryContainerColor ?? "rgba(252, 252, 253, 1)"};
  padding: 40px;
  border-radius: 0px 12px 12px 12px;
  z-index: 101;
  margin: auto;
`;
export const StyledOverlayContainer = styled.div<{
  $themeColor: DoxleThemeColor;
  $top: number;
  $left: number;
  $width: number;
  $height: number;
}>`
  position: absolute;
  top: ${(p) => p.$top}px;
  left: ${(p) => p.$left}px;
  width: ${(p) => p.$width}px;
  height: ${(p) => p.$height}px;
  background-color: ${(p) => editRgbaAlpha({
    rgbaColor: p?.$themeColor?.primaryContainerColor,
    alpha: '0.6'
  })};
  z-index: 1000;
  display: flex;
  justify-content: center;
  & span {
    margin: auto;
  }
`
