import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { BaseAPIProps } from "../../Models/baseAPIProps";
import DoxleAPI from "../../Services/DoxleAPI";
import {
  AxiosBackendErrorReturn,
  IApiPaginatedData,
} from "../../Models/axiosReturn";
import { User } from "../../Models/user";
import useSetUserInfoQueryData from "../../CoreContent/QueryDataHooks/SetQueryDataHooks/useSetUserInfoQueryData";
import { isAxiosError } from "axios";
export const baseQKeyUserInfo = ["user-info"];
interface GetUserListQueryProps extends BaseAPIProps {
  project?: string; //!filter with project id
  search?: string;
  onSuccessRetrieveCB?: (data: User[]) => void;
}

const useGetUserList = ({
  showNotification,

  company,
  project,
  onSuccessRetrieveCB,
  search,
}: GetUserListQueryProps) => {
  const qKey = ["user-list"];
  const getParams: any = {};
  if (company) {
    qKey.push(company.companyId);
    getParams.company = company.companyId;
  }
  if (project !== undefined) {
    qKey.push(project);
    getParams.project = project;
  }
  if (search !== undefined) {
    qKey.push(search);
    getParams.search = search;
  }
  let userURL = `/user/?page=1`;

  const defectQuery = useInfiniteQuery(
    qKey,
    ({ pageParam = userURL }) =>
      DoxleAPI.get<IApiPaginatedData<User>>(pageParam, {
        headers: {
          "User-Company": company!.companyId,
        },
        params: getParams,
      }),
    {
      enabled: company !== undefined,
      getNextPageParam: (prev) => prev.data.next,
      getPreviousPageParam: (prev) => prev.data.previous,
      retry: 1,
      staleTime: Infinity,
      onSuccess: (res) => {
        if (onSuccessRetrieveCB)
          onSuccessRetrieveCB(
            res.pages.reduce((acc, data) => {
              return acc.concat(data.data.results);
            }, [] as User[])
          );
      },
      onError: (error) => {
        if (showNotification) {
          if (isAxiosError<AxiosBackendErrorReturn>(error)) {
            showNotification(
              `${error?.response?.status ?? "ERROR"}: ${
                error.response?.data.detail ?? "UNKNOWN ERROR"
              }`,
              "error",
              String(
                error?.response?.data?.detail ?? "Failed to get user list"
              ).substring(0, 300)
            );
          } else {
            showNotification(
              "Something Wrong!",
              "error",
              "Failed to get user list"
            );
          }
        }
      },
    }
  );
  return defectQuery;
};

interface GetUserDetailQueryProps extends BaseAPIProps {
  onSuccessCB?: (user?: User) => void;
  enabled?: boolean;
}
const useRetrieveUserInfo = ({
  showNotification,
  company,
  onSuccessCB,
  enabled,
}: GetUserDetailQueryProps) => {
  let userURL = `/user/self/`;

  const defectQuery = useQuery(
    baseQKeyUserInfo,
    () =>
      DoxleAPI.get<User>(userURL, {
        headers: {
          "User-Company": company!.companyId,
        },
      }),
    {
      enabled: company !== undefined && enabled !== false,
      retry: 1,
      staleTime: Infinity,
      onSuccess: (res) => {
        if (onSuccessCB) onSuccessCB(res.data);
      },
      onError: () => {
        //   if (showNotification)
        //     showNotification(
        //       'SOMETHING WRONG',
        //       'error',
        //       'Failed to get defect detail',
        //     );
      },
    }
  );
  return defectQuery;
};
export interface UpdateUserFields {
  userId: string;
  userName?: string;
  email?: string;
  phone?: string;
  firstName?: string;
  lastName?: string;
  signature?: Blob; //local path
}
interface EditUserQueryProps extends BaseAPIProps {
  onSuccessCB?: (newUser?: User) => void;
}

const useUpdateUserInfo = ({
  showNotification,
  company,

  onSuccessCB,
}: EditUserQueryProps) => {
  const queryClient = useQueryClient();
  const { handleUpdateProjectQueryData } = useSetUserInfoQueryData({});
  const mutation = useMutation({
    mutationFn: async ({ userId, ...data }: UpdateUserFields) => {
      const addDefectURL = `/user/${userId}/`;
      const updateFormData = new FormData();
      Object.keys(data).map(
        (key) =>
          key !== "signature" &&
          updateFormData.append(
            key,
            data[key as keyof Omit<UpdateUserFields, "userId">] as string
          )
      );

      if (data.signature) {
        updateFormData.append("signature", data.signature);
      }

      return DoxleAPI.post<User>(addDefectURL, updateFormData, {
        headers: {
          "User-Company": company?.companyId || "",
          "Content-Type": "multipart/form-data",
        },
      });
    },

    onSuccess: (result, variables, context) => {
      // if (showNotification)
      //   showNotification(
      //     "Data Updated...",
      //     "success",
      //     "SUCCESSFULLY UPDATED DATA"
      //   );
      console.log("UPDATE USER RESULT:", result.data);
      handleUpdateProjectQueryData(result.data);
      if (onSuccessCB) {
        onSuccessCB(result.data);
      }
    },
    onError: (error, variables, context) => {
      if (showNotification) {
        if (isAxiosError<AxiosBackendErrorReturn>(error)) {
          showNotification(
            `${error?.response?.status ?? "ERROR"}: ${
              error.response?.data.detail ?? "UNKNOWN ERROR"
            }`,
            "error",
            String(
              error?.response?.data?.detail ??
                "Failed to update user information"
            ).substring(0, 300)
          );
        } else {
          showNotification(
            "Something Wrong!",
            "error",
            "Failed to update user information"
          );
        }
      }

      console.log("ERROR:", (error as any).message);
    },
  });
  const mutate = (data: UpdateUserFields) => mutation.mutate(data);
  return { ...mutation, mutate: mutate };
};

const UserQueryAPI = {
  useGetUserList,
  useUpdateUserInfo,
  useRetrieveUserInfo,
};
export default UserQueryAPI;
