import React from "react";
import { StyledScopeOfWorkSkeletonRowContainer } from "./StyledComponentDoxleSkeleton";
import { Skeleton } from "@mui/material";

type Props = {};

const ScopeOfWorkSkeletonRow = (props: Props) => {
  return (
    <StyledScopeOfWorkSkeletonRowContainer $marginVertical={20} $rowHeight={80}>
      <Skeleton
        variant="rounded"
        width="15%"
        height={15}
        style={{
          borderRadius: "10px",

          marginBottom: "20px",
        }}
      />

      <Skeleton
        variant="rounded"
        width="calc(100% - 60px)"
        height={5}
        style={{
          borderRadius: "10px",

          marginLeft: "60px",
          marginBottom: 14,
        }}
      />
      <Skeleton
        variant="rounded"
        width="calc(100% - 60px)"
        height={5}
        style={{
          borderRadius: "10px",

          marginLeft: "60px",
        }}
      />
    </StyledScopeOfWorkSkeletonRowContainer>
  );
};

export default ScopeOfWorkSkeletonRow;
