import React from "react";
import { useDoxleCurrentContextStore } from "../../DoxleGeneralStore/useDoxleCurrentContext";
import { shallow } from "zustand/shallow";
import useDoxleNotificationStore from "../../DoxleGeneralStore/useDoxleNotificationStore";
import { useDoxleAuthStore } from "../../DoxleGeneralStore/useDoxleAuthStore";
import { useDrawingStore } from "../Store/useDrawingStore";
import DrawingQueryAPI from "../QueryHooks/drawingQueryAPI";

type Props = {};

interface SheetDeleteConfirmDialog {
  handleDeleteSheet: () => void;
  handleCloseDeleteDialog: () => void;
  isDeletingSheet: boolean;
}
const useSheetDeleteConfirmDialog = (
  props: Props
): SheetDeleteConfirmDialog => {
  const { company, selectedProject } = useDoxleCurrentContextStore(
    (state) => ({
      company: state.currentCompany,
      selectedProject: state.currentProject,
    }),
    shallow
  );

  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );
  

  const {
    selectedSheetForDelete,
    setSelectedSheetForDelete,
    viewedDrawingSet,
    setViewedDrawingSet,
  } = useDrawingStore(
    (state) => ({
      selectedSheetForDelete: state.selectedSheetForDelete,
      setSelectedSheetForDelete: state.setSelectedSheetForDelete,
      viewedDrawingSet: state.viewedDrawingSet,
      setViewedDrawingSet: state.setViewedDrawingSet,
    }),
    shallow
  );
  const onSuccessDelete = (data?: {
    deletedSheetIds: string[];
    drawingSetId: string;
  }) => {
    setSelectedSheetForDelete(undefined);
    if (data && viewedDrawingSet) {
      setViewedDrawingSet({
        ...viewedDrawingSet,
        sheets: viewedDrawingSet.sheets.filter(
          (oldSheet) =>
            !data.deletedSheetIds.some(
              (deletedId) => deletedId === oldSheet.sheetId
            )
        ),
      });
    }
  };

  const deleteSheetQuery = DrawingQueryAPI.useDeleteMultiSheetQuery({
    showNotification,
    company,
    projectId: selectedProject?.projectId || "",

    onSuccessDeleteCb: onSuccessDelete,
  });

  const handleDeleteSheet = () => {
    if (selectedSheetForDelete)
      deleteSheetQuery.mutate({
        deletedSheetIds: [selectedSheetForDelete.sheetId!],
        drawingSetId: selectedSheetForDelete.drawingSet,
      });
  };

  const handleCloseDeleteDialog = () => setSelectedSheetForDelete(undefined);
  return {
    handleDeleteSheet,
    handleCloseDeleteDialog,
    isDeletingSheet: deleteSheetQuery.isLoading,
  };
};

export default useSheetDeleteConfirmDialog;
