import React, { useEffect, useMemo, useState } from "react";
import { shallow } from "zustand/shallow";
import { useDoxleCurrentContextStore } from "../../../../DoxleGeneralStore/useDoxleCurrentContext";
import ScopeOfWorkAPI from "../../../QueryAPI/scopeOfWorkQueryAPI";
import { useEditDocketSideScreenStore } from "../../../../CoreContent/EditDocketSideScreen/EditDocketSideScreenStore/useEditDocketSideScreenStore";
import { ScopeOfWorkGroup } from "../../../../ScopeOfWorks/Model/scopeOfWorks";

type Props = {};

interface SelectScopeGroup {
  taskGroupList: ScopeOfWorkGroup[];
  isFetchingSOWGroupList: boolean;
  isSuccessFetchingSOWGroupList: boolean;
  isErrorFetchingSOWGroupList: boolean;
  searchInput: string;
  setSearchInput: React.Dispatch<React.SetStateAction<string>>;
  anchorTaskListPopover: HTMLDivElement | null;
  setAnchorTaskListPopover: React.Dispatch<React.SetStateAction<HTMLDivElement | null>>;
}
const useSelectScopeGroup = (props: Props): SelectScopeGroup => {
  const [searchInput, setSearchInput] = useState("");
  const [anchorTaskListPopover, setAnchorTaskListPopover] =
    useState<HTMLDivElement | null>(null);

  const company = useDoxleCurrentContextStore(
    (state) => state.currentCompany,
    shallow
  );
  const docket = useEditDocketSideScreenStore(
    (state) => state.edittedDocket,
    shallow
  );
  const retrieveTaskGroupsQuery = ScopeOfWorkAPI.useRetrieveScopeGroupList({
    company: company,
    filter: { docketId: docket?.docketPk, projectId: docket?.project || "" },
  });

  const taskGroupList = useMemo(
    () =>
      retrieveTaskGroupsQuery.isSuccess
        ? retrieveTaskGroupsQuery.data.pages.reduce((acc, data) => {
            return acc.concat(data.data.results);
          }, [] as ScopeOfWorkGroup[])
        : [],
    [retrieveTaskGroupsQuery.data]
  );
  useEffect(() => {
    if (retrieveTaskGroupsQuery.hasNextPage)
      retrieveTaskGroupsQuery.fetchNextPage();
  }, [retrieveTaskGroupsQuery.data, retrieveTaskGroupsQuery.hasNextPage]);

  return {
    taskGroupList,
    isFetchingSOWGroupList: retrieveTaskGroupsQuery.isLoading,
    isSuccessFetchingSOWGroupList: retrieveTaskGroupsQuery.isSuccess,
    isErrorFetchingSOWGroupList: retrieveTaskGroupsQuery.isError,
    searchInput,
    setSearchInput,
    anchorTaskListPopover,
    setAnchorTaskListPopover,
  };
};

export default useSelectScopeGroup;
