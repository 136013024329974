import React, { useMemo } from "react";
import { useDoxleCurrentContextStore } from "../../DoxleGeneralStore/useDoxleCurrentContext";
import { shallow } from "zustand/shallow";
import useDoxleNotificationStore from "../../DoxleGeneralStore/useDoxleNotificationStore";
import { useContactsStore } from "../Content/Store/useContactsStore";

import { ContactCompany } from "../../Models/addressBook";
import ContactsQueryAPI from "../QueryHooks/ContactsQueryAPI";

type Props = {};
interface IGetContactCompanyList {
  contactCompanyList: ContactCompany[];
  isFetchingContactCompanyList: boolean;
  isSuccessFetchingContactCompanyList: boolean;
  isErrorFetchingContactCompanyList: boolean;
  isFetchingNextPageContactCompanyList: boolean;
  handleFetchNextpageContactCompany: () => void;
}
const useGetContactCompanyList = (props: Props): IGetContactCompanyList => {
  const company = useDoxleCurrentContextStore(
    (state) => state.currentCompany,
    shallow
  );

  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );
  const contactCompanyQueryFilter = useContactsStore(
    (state) => state.contactCompanyQueryFilter,
    shallow
  );

  const contactCompanyListQuery =
    ContactsQueryAPI.useRetrieveContactCompanyList({
      company,
      showNotification,
      filter: contactCompanyQueryFilter,
    });

  const contactCompanyList: ContactCompany[] = useMemo(
    () =>
      contactCompanyListQuery.data?.pages
        ? contactCompanyListQuery.data?.pages.flatMap(
            (page: any) => page?.data?.results ?? []
          ) ?? []
        : [],
    [contactCompanyListQuery.data, company]
  );

  const handleFetchNextpageContactCompany = () => {
    if (contactCompanyListQuery.hasNextPage)
      contactCompanyListQuery.fetchNextPage();
  };
  return {
    contactCompanyList,
    isFetchingContactCompanyList: contactCompanyListQuery.isLoading,
    isSuccessFetchingContactCompanyList: contactCompanyListQuery.isSuccess,
    isErrorFetchingContactCompanyList: contactCompanyListQuery.isError,
    isFetchingNextPageContactCompanyList:
      contactCompanyListQuery.isFetchingNextPage,
    handleFetchNextpageContactCompany,
  };
};

export default useGetContactCompanyList;
