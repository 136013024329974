import React from "react";
import { useDoxleCurrentContextStore } from "../../DoxleGeneralStore/useDoxleCurrentContext";
import { shallow } from "zustand/shallow";
import { useQueryClient } from "@tanstack/react-query";
import { original, produce } from "immer";
import { IApiPaginatedData } from "../../Models/axiosReturn";
import {
  InventoryComment,
  InventoryImage,
  InventoryItem,
  NewComment,
  NewInventoryItem,
} from "../Models/Inventory";
import { getInventoryQKey } from "./InventoryQueryAPI";

type Props = {
  appendPos?: "start" | "end";
  overwrite?: boolean;
};

interface SetInventoryQueryData {
  handleAddComment: (inventoryComment: NewComment, inventoryId: string) => void;
  handleUpdateInventoryList: (newInvenory: InventoryItem) => void;
  handleAddInventory: (newInvenory: InventoryItem) => void;
  handleDeleteInventory: (inventoryId: string) => void;
  handleUpdateInventoryImage: (inventoryId: string, imagePath: string) => void;
  handleDeleteInventoryImage: (imageId: string, inventoryId: string) => void;
  handleAddInventoryImage: (
    newImgs: InventoryImage[],
    inventoryId: string
  ) => void;
}
const useSetInventoryQueryData = ({
  overwrite = true,
}: Props): SetInventoryQueryData => {
  const { currentCompany } = useDoxleCurrentContextStore(
    (state) => ({
      currentCompany: state.currentCompany,
    }),
    shallow
  );
  const qKey = getInventoryQKey(currentCompany);
  const queryClient = useQueryClient();

  const handleAddComment = (newComment: NewComment, inventoryId: string) => {
    const queryData = queryClient.getQueryData(qKey);
    if (overwrite) {
      if (queryData)
        queryClient.setQueryData(qKey, (old: any) => {
          let pageIndexContainItem: number = old.pages.findIndex((page: any) =>
            Boolean(
              page.data.results.find(
                (inventory: InventoryItem) =>
                  inventory.inventoryId === inventoryId
              ) !== undefined
            )
          );
          if (pageIndexContainItem !== -1) {
            return produce(old, (draft: any) => {
              draft.pages[pageIndexContainItem].data.results = produce(
                draft.pages[pageIndexContainItem].data.results,
                (draftResult: InventoryItem[]) => {
                  const data = original(draftResult);
                  const inventoryItem = data!.find(
                    (inventory) => inventory.inventoryId === inventoryId
                  );

                  return draftResult;
                }
              );

              return draft;
            });
          } else queryClient.invalidateQueries(qKey);
        });
      else queryClient.invalidateQueries(qKey);
    } else queryClient.invalidateQueries(qKey);
  };

  const handleUpdateInventoryList = (newInventory: InventoryItem) => {
    const queryData = queryClient.getQueryData(qKey);
    if (overwrite && queryData) {
      queryClient.setQueryData(qKey, (old: any) => {
        let pageIndexContainItem: number = old.pages.findIndex((page: any) =>
          Boolean(
            page.data.results.find(
              (inventory: InventoryItem) =>
                inventory.inventoryId === newInventory.inventoryId
            ) !== undefined
          )
        );
        if (pageIndexContainItem !== -1) {
          return produce(old, (draft: any) => {
            draft.pages[pageIndexContainItem].data.results = produce(
              draft.pages[pageIndexContainItem].data.results,
              (draftResult: InventoryItem[]) => {
                const inventoryItem = draftResult!.find(
                  (inventory) =>
                    inventory.inventoryId === newInventory.inventoryId
                );
                if (inventoryItem) Object.assign(inventoryItem, newInventory);
                return draftResult;
              }
            );
            return draft;
          });
        } else
          queryClient.refetchQueries({
            type: "all",
            predicate: (query) =>
              qKey.every((item) => query.queryKey.includes(item)),
          });
      });
    } else
      queryClient.refetchQueries({
        type: "all",
        predicate: (query) =>
          qKey.every((item) => query.queryKey.includes(item)),
      });
  };

  const handleAddInventory = (newInventory: InventoryItem) => {
    const budgetData = queryClient.getQueryData(qKey);
    if (overwrite && budgetData) {
      queryClient.setQueryData(qKey, (old: any) => {
        if (old) {
          const lengthOfPages = old.pages.length;
          return produce(old, (draftOld: any) => {
            draftOld.pages = produce(draftOld.pages, (draftPages: any) => {
              (
                draftPages[0].data as IApiPaginatedData<InventoryItem>
              ).results.unshift(newInventory);
              return draftPages;
            });

            return draftOld;
          });
        } else return queryClient.invalidateQueries(qKey);
      });
    } else queryClient.invalidateQueries(qKey);
  };

  const handleDeleteInventory = (deletedInventoryId: string) => {
    const queryData = queryClient.getQueryData(qKey);
    if (queryData && overwrite)
      queryClient.setQueryData(qKey, (old: any) =>
        produce(old, (draft: any) => {
          let pageIndexContainItem: number = old.pages.findIndex((page: any) =>
            Boolean(
              page.data.results.find(
                (inventory: InventoryItem) =>
                  inventory.inventoryId === deletedInventoryId
              ) !== undefined
            )
          );
          if (pageIndexContainItem !== -1)
            draft.pages[pageIndexContainItem].data.results = produce(
              draft.pages[pageIndexContainItem].data.results,
              (draftResult: InventoryItem[]) => {
                draftResult.splice(
                  draftResult.findIndex(
                    (inventory) => inventory.inventoryId === deletedInventoryId
                  ),
                  1
                );

                return draftResult;
              }
            );

          return draft;
        })
      );
    else queryClient.invalidateQueries(qKey);
  };

  const handleUpdateInventoryImage = (
    inventoryId: string,
    imagePath: string
  ) => {
    const queryData = queryClient.getQueryData(qKey);
    if (overwrite && queryData) {
      queryClient.setQueryData(qKey, (old: any) => {
        let pageIndexContainItem: number = old.pages.findIndex((page: any) =>
          Boolean(
            page.data.results.find(
              (inventory: InventoryItem) =>
                inventory.inventoryId === inventoryId
            ) !== undefined
          )
        );
        if (pageIndexContainItem !== -1) {
          return produce(old, (draft: any) => {
            draft.pages[pageIndexContainItem].data.results = produce(
              draft.pages[pageIndexContainItem].data.results,
              (draftResult: InventoryItem[]) => {
                const inventoryItem = draftResult!.find(
                  (inventory) => inventory.inventoryId === inventoryId
                );
                if (inventoryItem) inventoryItem.defaultImage = imagePath;
                return draftResult;
              }
            );
            return draft;
          });
        } else
          queryClient.refetchQueries({
            type: "all",
            predicate: (query) =>
              qKey.every((item) => query.queryKey.includes(item)),
          });
      });
    } else
      queryClient.refetchQueries({
        type: "all",
        predicate: (query) =>
          qKey.every((item) => query.queryKey.includes(item)),
      });
  };

  const handleDeleteInventoryImage = (imageId: string, inventoryId: string) => {
    const queryData: any = queryClient.getQueryData([
      "inventoryId",
      "images",
      inventoryId,
    ]);
    if (queryData && overwrite)
      queryClient.setQueryData(
        ["inventoryId", "images", inventoryId],
        (old: any) =>
          produce(old, (draft: any) => {
            draft.data.results = produce(
              draft.data.results,
              (draftResult: InventoryImage[]) => {
                draftResult.splice(
                  draftResult.findIndex(
                    (inventoryImages) => inventoryImages.imageId === imageId
                  ),
                  1
                );

                return draftResult;
              }
            );

            return draft;
          })
      );
    else queryClient.invalidateQueries(qKey);
  };

  const handleAddInventoryImage = (
    newImgs: InventoryImage[],
    inventoryId: string
  ) => {
    console.log(newImgs);
    const queryData: any = queryClient.getQueryData([
      "inventoryId",
      "images",
      inventoryId,
    ]);
    if (queryData && overwrite)
      queryClient.setQueryData(
        ["inventoryId", "images", inventoryId],
        (old: any) =>
          produce(old, (draft: any) => {
            draft.data.results = produce(
              draft.data.results,
              (draftResult: InventoryImage[]) => {
                return draftResult.concat(newImgs);
              }
            );

            return draft;
          })
      );
    else queryClient.invalidateQueries(qKey);
  };

  return {
    handleAddComment,
    handleUpdateInventoryList,
    handleAddInventory,
    handleDeleteInventory,
    handleUpdateInventoryImage,
    handleDeleteInventoryImage,
    handleAddInventoryImage,
  };
};

export default useSetInventoryQueryData;
