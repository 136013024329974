import React from "react";
import { Order } from "../Models/orders";
import { HiOutlineDocument } from "react-icons/hi2";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";

import { MotionProps } from "framer-motion";
import {
  StyledBookingOrderNumberContainer,
  StyledOrderInfoWrapper,
} from "./StyledComponentBookings";
type Props = {
  order: Order;
  orderIconSize?: string | number;
  orderNumTextStyle?: React.CSSProperties;
  orderSignTextStyle?: React.CSSProperties;
} & MotionProps;

const BookingOrderNumber = ({
  order,
  orderIconSize,
  orderNumTextStyle,
  orderSignTextStyle,
  ...props
}: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }),
    shallow
  );
  const orderSignedOffSplit = order.signedOffName.split(" ");
  return (
    <StyledBookingOrderNumberContainer
      $doxleFont={doxleFont}
      $themeColor={doxleThemeColor}
      {...props}
    >
      <HiOutlineDocument
        fontSize={orderIconSize ?? "3rem"}
        color={doxleThemeColor.primaryFontColor}
      />
      <StyledOrderInfoWrapper
        $doxleFont={doxleFont}
        $themeColor={doxleThemeColor}
      >
        <span className="order-number-text" style={orderNumTextStyle}>
          {order.orderNumber.substring(0, 4)}
        </span>
        <span className="order-signed-text" style={orderSignTextStyle}>
          {orderSignedOffSplit[0] &&
            orderSignedOffSplit[0][0] &&
            orderSignedOffSplit[0][0]}

          {orderSignedOffSplit[1] &&
            orderSignedOffSplit[1][0] &&
            orderSignedOffSplit[1][0]}
        </span>
      </StyledOrderInfoWrapper>
    </StyledBookingOrderNumberContainer>
  );
};

export default BookingOrderNumber;
