import { AnimatePresence } from "framer-motion";
import React from "react";
import { useEditDocketSideScreenStore } from "../../CoreContent/EditDocketSideScreen/EditDocketSideScreenStore/useEditDocketSideScreenStore";
import { shallow } from "zustand/shallow";
import { StyledCustomiseQuotesFunctionBtn } from "../../CoreContent/EditDocketSideScreen/StyledComponentsEditDocketSideScreen";
import { SlTrash } from "react-icons/sl";
import { CircularProgress } from "@mui/material";
import { editRgbaAlpha } from "../../Utilities/FunctionUtilities";
import { HiOutlineMail } from "react-icons/hi";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";

type Props = {};

const QuoteFunctionalMenu = (props: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }),
    shallow
  );
  const { quoteDeleteBtnFn, emailQuoteBtnFn } = useEditDocketSideScreenStore(
    (state) => ({
      quoteDeleteBtnFn: state.quoteDeleteBtnFn,
      emailQuoteBtnFn: state.emailQuoteBtnFn,
    }),
    shallow
  );
  return (
    <div style={{display:"flex", justifyContent:"end"}}>
      <AnimatePresence>
        {quoteDeleteBtnFn && (
          <StyledCustomiseQuotesFunctionBtn
            $themeColor={doxleThemeColor}
            $doxleFont={doxleFont}
            layout
            initial={{
              y: 5,
              opacity: 0,
            }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: 5, opacity: 0 }}
            onClick={(e) => quoteDeleteBtnFn.btnFunction(e)}
          >
            {!quoteDeleteBtnFn.isProcessing ? (
              <SlTrash
                size="1.4rem"
                style={{
                  color: doxleThemeColor.primaryFontColor,
                  marginRight: "0.4rem",
                }}
              />
            ) : (
              <CircularProgress
                size="1.4rem"
                style={{
                  color: doxleThemeColor.primaryFontColor,
                  marginRight: "0.4rem",
                }}
              />
            )}
            {!quoteDeleteBtnFn.isProcessing
              ? quoteDeleteBtnFn.btnText
              : "Deleting..."}
          </StyledCustomiseQuotesFunctionBtn>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {emailQuoteBtnFn && (
          <StyledCustomiseQuotesFunctionBtn
            $themeColor={doxleThemeColor}
            $doxleFont={doxleFont}
            layout
            initial={{
              y: 5,
              opacity: 0,
              backgroundColor: doxleThemeColor.primaryContainerColor,
            }}
            animate={
              emailQuoteBtnFn.disable
                ? {
                    y: 0,
                    opacity: 1,
                    backgroundColor: editRgbaAlpha({
                      rgbaColor: doxleThemeColor.primaryContainerColor,
                      alpha: "0.4",
                    }),
                  }
                : {
                    y: 0,
                    opacity: 1,
                    backgroundColor: doxleThemeColor.primaryContainerColor,
                  }
            }
            exit={{ y: 5, opacity: 0 }}
            onClick={(e) => emailQuoteBtnFn.btnFunction(e)}
            disabled={emailQuoteBtnFn.disable}
          >
            {!emailQuoteBtnFn.isProcessing ? (
              <HiOutlineMail
                size="1.4rem"
                style={{
                  color: !emailQuoteBtnFn.disable
                    ? doxleThemeColor.primaryFontColor
                    : editRgbaAlpha({
                        rgbaColor: doxleThemeColor.primaryFontColor,
                        alpha: "0.26",
                      }),
                  marginRight: "0.4rem",
                }}
              />
            ) : (
              <CircularProgress
                size="1.4rem"
                style={{
                  color: doxleThemeColor.primaryFontColor,
                  marginRight: "0.4rem",
                }}
              />
            )}
            {!emailQuoteBtnFn.isProcessing
              ? emailQuoteBtnFn.btnText
              : "Emailing..."}
          </StyledCustomiseQuotesFunctionBtn>
        )}
      </AnimatePresence>
    </div>
  );
};

export default QuoteFunctionalMenu;
