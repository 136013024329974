import { produce } from "immer";
import React, { useCallback, useEffect, useState } from "react";
import {
  DropEvent,
  DropzoneRootProps,
  FileRejection,
  useDropzone,
} from "react-dropzone";
import { useDoxleCurrentContextStore } from "../../DoxleGeneralStore/useDoxleCurrentContext";
import { shallow } from "zustand/shallow";
import useDoxleNotificationStore from "../../DoxleGeneralStore/useDoxleNotificationStore";
import { useDoxleAuthStore } from "../../DoxleGeneralStore/useDoxleAuthStore";
import DrawingQueryAPI from "../QueryHooks/drawingQueryAPI";

type Props = { openDropzone: boolean; onClose: () => void };

interface AddDrawingDropZone {
  addedFile: File | undefined;
  newDrawingTitle: string;
  setNewDrawingTitle: React.Dispatch<React.SetStateAction<string>>;
  handleRemoveAddedFile: () => void;
  showErrorText: boolean;
  handleAddDrawing: () => void;
  onDrop: <T extends File>(
    acceptedFiles: T[],
    fileRejections: FileRejection[],
    event: DropEvent
  ) => void;
  isAddingDrawing: boolean;
}
const useAddDrawingDropZone = ({
  openDropzone,
  onClose,
}: Props): AddDrawingDropZone => {
  const [addedFile, setaddedFile] = useState<File | undefined>(undefined);
  const [newDrawingTitle, setNewDrawingTitle] = useState<string>("");
  const [showErrorText, setShowErrorText] = useState<boolean>(false);
  const { company, selectedProject } = useDoxleCurrentContextStore(
    (state) => ({
      company: state.currentCompany,
      selectedProject: state.currentProject,
    }),
    shallow
  );

  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );

  const onAddSuccessCb = () => {
    setNewDrawingTitle("");
    setaddedFile(undefined);
    onClose();
  };
  const addDrawing = DrawingQueryAPI.useAddDrawingQuery({
    showNotification,
    company,
    projectId: selectedProject?.projectId || "",
    onSuccessAddCb: onAddSuccessCb,
  });

  const handleAddDrawing = () => {
    if (addedFile) {
      if (newDrawingTitle) {
        addDrawing.mutate({
          drawingName: newDrawingTitle,
          file: addedFile,
        });
      } else setShowErrorText(true);
    }
  };
  //# Dropzone
  const onDrop = useCallback(
    <T extends File>(
      acceptedFiles: T[],
      fileRejections: FileRejection[],
      event: DropEvent
    ) => {
      console.log("FILE SIZE:", acceptedFiles[0].size);
      setaddedFile(acceptedFiles[0]);
    },
    []
  );

  const handleRemoveAddedFile = () => {
    setaddedFile(undefined);
  };

  useEffect(() => {
    if (!addedFile) setNewDrawingTitle("");
  }, [addedFile]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (showErrorText) setShowErrorText(false);
    }, 2000);

    return () => {
      clearTimeout(timeout);
    };
  }, [showErrorText]);

  useEffect(() => {
    if (!openDropzone) {
      setaddedFile(undefined);
      setNewDrawingTitle("");
    }
  }, [openDropzone]);

  return {
    addedFile,
    newDrawingTitle,
    setNewDrawingTitle,
    handleRemoveAddedFile,
    showErrorText,
    handleAddDrawing,
    onDrop,
    isAddingDrawing: addDrawing.isLoading,
  };
};

export default useAddDrawingDropZone;
