import { StyledPageContainer } from "./StyledComponents";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import { useEffect } from "react";
import { useEstimatesStore } from "./Store/useEstimatesStore";
import EstimatesPage from "./EstimatesTable/EstimatesPage";

import EstimatesPageHeader from "./EstimatesPageHeader";
import QuickEstimatePage from "./QuickEstimateTable/QuickEstimatePage";

const Estimates = () => {
  const doxleThemeColor = useDoxleThemeStore(
    (state) => state.doxleThemeColor,
    shallow
  );
  const urlView = window.location.href.toUpperCase().includes("QUICK")
    ? "QuickTender"
    : "Estimates";
  const { view, setView } = useEstimatesStore(
    (state) => ({
      view: state.view,
      setView: state.setView,
    }),
    shallow
  );

  useEffect(() => {
    if (view !== urlView) setView(urlView);
  }, []);

  return (
    <StyledPageContainer themeColor={doxleThemeColor}>
      <EstimatesPageHeader />
      {view === "Estimates" ? <EstimatesPage /> : <QuickEstimatePage />}
    </StyledPageContainer>
  );
};
export default Estimates;
