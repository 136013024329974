import DocketQueryAPI, {
  formDocketStatusQKey,
} from "../../../Services/QueryHooks/docketQueryAPI";
import useDoxleNotificationStore from "../../../DoxleGeneralStore/useDoxleNotificationStore";
import { shallow } from "zustand/shallow";
import { useDoxleCurrentContextStore } from "../../../DoxleGeneralStore/useDoxleCurrentContext";
import { useCallback, useMemo, useState } from "react";
import useStatusColorPickerStore from "../Store/StatusColorPickerStore";
import { ProjectStatus } from "../../../Models/project";
import { TRgbaFormat } from "../../../Utilities/FunctionUtilities";
import { DocketStatus } from "../../../Models/dockets";
import { OrderStatus } from "../../../Orders/Models/order";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import useSetStatusQueryData from "./useSetStatusQueryData";

interface StatusMenu {
  status: DocketStatus;
  anchorEl: SVGElement;
}
const useDocketStatuses = () => {
  const [statusMenu, setStatusMenu] = useState<StatusMenu | null>(null);
  const [deleteStatusDialog, setDeleteStatusDialog] =
    useState<DocketStatus | null>(null);
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );
  const company = useDoxleCurrentContextStore(
    (state) => state.currentCompany,
    shallow
  );
  const retrieveDocketStatusQuery = DocketQueryAPI.useRetrieveDocketStatusList({
    showNotification,
    company,
    filter: {},
  });

  const doxleColor = useDoxleThemeStore(
    (state) => state.doxleThemeColor.doxleColor,
    shallow
  );
  const openColorPicker = useStatusColorPickerStore(
    (state) => state.openPicker,
    shallow
  );
  const mutateDocketStatusQuery = DocketQueryAPI.useMutateDocketStatus();
  const docketStatusList = useMemo(
    () =>
      retrieveDocketStatusQuery.isSuccess
        ? retrieveDocketStatusQuery.data.data
        : [],
    [retrieveDocketStatusQuery.data, company]
  );

  const handleStatusColorClick = (
    e: React.MouseEvent<HTMLDivElement>,
    status: DocketStatus
  ) => {
    openColorPicker({
      anchorEl: e.currentTarget,
      handleSave: (newColor: TRgbaFormat) =>
        mutateDocketStatusQuery.updateStatus({
          statusId: status.statusId,
          updateStatus: { color: newColor },
        }),
      color: status.color ?? "rgba(0,0,0,1)",
    });
  };
  const handleKebabIconClick = (
    e: React.MouseEvent<SVGElement>,
    status: DocketStatus
  ) => {
    setStatusMenu({
      anchorEl: e.currentTarget,
      status: status,
    });
  };
  const handleDelete = (status: DocketStatus) => {
    console.log(status.totalCount);
    if (!status.totalCount)
      mutateDocketStatusQuery.deleteStatus({
        statusId: status.statusId,
      });
    else setDeleteStatusDialog(status);
  };
  const handleDeleteConfirmed = (newStatusId: string | null) => {
    if (deleteStatusDialog)
      mutateDocketStatusQuery.deleteStatus({
        statusId: deleteStatusDialog.statusId,
        newId: newStatusId,
      });
    setDeleteStatusDialog(null);
  };
  const showDropDown = useMemo(() => Boolean(statusMenu), [statusMenu]);
  interface Position {
    $top: number;
    $left: number;
  }
  const dropDownPosition: Position = useMemo(() => {
    const boundingRect = statusMenu?.anchorEl.getBoundingClientRect();
    if (!boundingRect) return { $top: 0, $left: 0 };
    return {
      $top: (boundingRect.top + boundingRect.bottom) / 2,
      $left: (boundingRect.left + boundingRect.right) / 2,
    };
  }, [statusMenu]);
  interface StatusDropDownOption {
    text: string;
    handleClick: () => void;
    disabled: boolean;
    selected?: boolean;
  }
  const docketStatusDropdownOptions: StatusDropDownOption[] = useMemo(() => {
    if (!statusMenu?.status) return [];
    return [
      {
        text: "Set Status to Default",
        disabled: statusMenu.status?.isDefault,
        handleClick: () => {
          mutateDocketStatusQuery.updateStatus({
            statusId: statusMenu?.status.statusId,
            updateStatus: { isDefault: true },
          });
          setStatusMenu(null);
        },
      },
      {
        text: "Allow Status On Budgets",
        selected: Boolean(statusMenu?.status?.isBudgetStatus),
        disabled: statusMenu.status?.isDefault,
        handleClick: () => {
          mutateDocketStatusQuery.updateStatus({
            statusId: statusMenu?.status.statusId,
            updateStatus: {
              isBudgetStatus: !Boolean(statusMenu?.status?.isBudgetStatus),
            },
          });
          setStatusMenu(null);
        },
      },
      {
        text: "Allow Status On Noticeboard",
        selected: Boolean(statusMenu?.status?.isNoticeboardStatus),
        disabled: statusMenu.status?.isDefault,
        handleClick: () => {
          mutateDocketStatusQuery.updateStatus({
            statusId: statusMenu?.status.statusId,
            updateStatus: {
              isNoticeboardStatus: !Boolean(
                statusMenu?.status?.isNoticeboardStatus
              ),
            },
          });
          setStatusMenu(null);
        },
      },
      {
        text: "Bold Text On Docket Row",
        disabled: false,
        selected: Boolean(statusMenu?.status?.bold),
        handleClick: () => {
          mutateDocketStatusQuery.updateStatus({
            statusId: statusMenu?.status.statusId,
            updateStatus: { bold: !Boolean(statusMenu?.status?.bold) },
          });
          setStatusMenu(null);
        },
      },
      {
        text: "Italicise Text On Docket Row",
        disabled: false,
        selected: Boolean(statusMenu?.status?.italics),
        handleClick: () => {
          mutateDocketStatusQuery.updateStatus({
            statusId: statusMenu?.status.statusId,
            updateStatus: { italics: !Boolean(statusMenu?.status?.italics) },
          });
          setStatusMenu(null);
        },
      },
      {
        text: "Underline Text On Docket Row",
        disabled: false,
        selected: Boolean(statusMenu?.status?.underline),
        handleClick: () => {
          mutateDocketStatusQuery.updateStatus({
            statusId: statusMenu?.status.statusId,
            updateStatus: {
              underline: !Boolean(statusMenu?.status?.underline),
            },
          });
          setStatusMenu(null);
        },
      },
      {
        text: "Strikethrough Docket Row",
        disabled: false,
        selected: Boolean(statusMenu?.status?.strikethrough),
        handleClick: () => {
          mutateDocketStatusQuery.updateStatus({
            statusId: statusMenu?.status.statusId,
            updateStatus: {
              strikethrough: !Boolean(statusMenu?.status?.strikethrough),
            },
          });
          setStatusMenu(null);
        },
      },
      {
        text: "Coloured Text On Docket Row",
        disabled: false,
        selected: Boolean(statusMenu?.status?.colorText),
        handleClick: () => {
          mutateDocketStatusQuery.updateStatus({
            statusId: statusMenu?.status.statusId,
            updateStatus: {
              colorText: !Boolean(statusMenu?.status?.colorText),
            },
          });
          setStatusMenu(null);
        },
      },
      {
        text: "Coloured Background On Docket Row",
        disabled: false,
        selected: Boolean(statusMenu?.status?.colorBackground),
        handleClick: () => {
          mutateDocketStatusQuery.updateStatus({
            statusId: statusMenu?.status.statusId,
            updateStatus: {
              colorBackground: !Boolean(statusMenu?.status?.colorBackground),
            },
          });
          setStatusMenu(null);
        },
      },
      {
        text: "Blinking Animation on Icon",
        disabled: false,
        selected: Boolean(statusMenu?.status?.animateIcon),
        handleClick: () => {
          mutateDocketStatusQuery.updateStatus({
            statusId: statusMenu?.status.statusId,
            updateStatus: {
              animateIcon: !Boolean(statusMenu?.status?.animateIcon),
            },
          });
          setStatusMenu(null);
        },
      },
      {
        text: "Delete Status",
        disabled: statusMenu.status?.isDefault,
        handleClick: () => {
          handleDelete(statusMenu.status);
          setStatusMenu(null);
        },
      },
    ];
  }, [mutateDocketStatusQuery, statusMenu?.status]);
  const handleCloseDropDown = () => {
    setStatusMenu(null);
  };

  const handleAddStatus = useCallback(
    (statusName: string) => {
      if (company)
        mutateDocketStatusQuery.addStatus({
          statusName: statusName,
          company: company.companyId,
          index: docketStatusList.length,
          color: doxleColor,
          isDefault: docketStatusList.length === 0,
          isBudgetStatus: true,
          bold: false,
          italics: false,
          underline: false,
          strikethrough: false,
          colorText: false,
          colorBackground: false,
          animateIcon: false,
        });
    },
    [company, docketStatusList.length, doxleColor, mutateDocketStatusQuery]
  );

  const handleUpdateStatusName = useCallback(
    (
      status: ProjectStatus | DocketStatus | OrderStatus,
      statusName: string
    ) => {
      mutateDocketStatusQuery.updateStatus({
        statusId: status.statusId,
        updateStatus: { statusName: statusName },
      });
    },
    [mutateDocketStatusQuery]
  );

  const { handleUpdateStatusOrder } = useSetStatusQueryData();

  const handleUpdateStatusIndex = (
    status: DocketStatus,
    source: number,
    dest: number
  ) => {
    handleUpdateStatusOrder(source, dest, formDocketStatusQKey({}, company));
    mutateDocketStatusQuery.updateStatusIndex({
      statusId: status.statusId,
      source: source,
      dest: dest,
    });
  };

  return {
    docketStatusList,
    mutateDocketStatusQuery,
    handleStatusColorClick,
    handleKebabIconClick,
    showDropDown,
    dropDownPosition,
    docketStatusDropdownOptions,
    handleCloseDropDown,
    handleUpdateStatusName,
    handleAddStatus,
    deleteStatusDialog,
    handleDeleteConfirmed,
    setDeleteStatusDialog,
    handleUpdateStatusIndex,
    retrieveDocketStatusQuery,
  };
};

export default useDocketStatuses;
