import {
  QueryClient,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import DoxleAPI from "../../Services/DoxleAPI";
import { BaseAPIProps } from "../../Models/baseAPIProps";
import { AddContact, Contact, ContactCompany } from "../../Models/addressBook";
import { Company } from "../../Models/company";
import {
  AxiosBackendErrorReturn,
  IApiPaginatedData,
} from "../../Models/axiosReturn";
import { ContactCompanyFilters, ContactsFilters } from "./contactsFilters";
import { produce } from "immer";
import { AxiosResponse, isAxiosError } from "axios";
import useSetCompanyContactQueryData from "../../Contacts/QueryHooks/useSetCompanyContactQueryData";
import useSetContactsQueryData from "../../CoreContent/QueryDataHooks/SetQueryDataHooks/useSetContactsQueryData";

interface RetrieveContactsQueryProps extends BaseAPIProps {
  filter: ContactsFilters;
  enable?: boolean;
  onSuccessCb?: Function;
}
const useRetrieveContactsQuery = ({
  company,
  showNotification,
  filter,
  enable,
  onSuccessCb,
}: RetrieveContactsQueryProps) => {
  const { search } = filter;
  const qKey = formContactListQKey(filter, company);
  const getParams: any = {};
  if (search) getParams.search = search;

  const contactsUrl = `/contact/?page=1`;
  const contactsQuery = useInfiniteQuery({
    queryKey: qKey,
    queryFn: async ({ pageParam = contactsUrl }) => {
      return DoxleAPI.get<IApiPaginatedData<Contact>>(pageParam, {
        headers: {
          "User-Company": company?.companyId || "",
        },
        params: getParams,
      });
    },
    getNextPageParam: (prevData) => prevData.data?.next,
    onSuccess: () => {
      if (onSuccessCb) onSuccessCb();
    },

    onError: (error) => {
      if (showNotification) {
        if (isAxiosError<AxiosBackendErrorReturn>(error)) {
          showNotification(
            `${error?.response?.status ?? "ERROR"}: ${
              error.response?.data.detail ?? "UNKNOWN ERROR"
            }`,
            "error",
            String(
              error?.response?.data?.detail ?? "Failed to get contacts list"
            ).substring(0, 300)
          );
        } else {
          showNotification(
            "Something Wrong!",
            "error",
            "Failed to get contacts list"
          );
        }
      }
    },
    enabled: Boolean(
      company !== undefined && (enable ?? true)
      // &&  retrieveContactsQuery.contactPageDisplay === "Contact"
    ),
    retry: 1,

    refetchOnWindowFocus: false,
    staleTime: 8 * 60 * 1000,
    refetchOnMount: false,
    cacheTime: 10 * 60 * 1000,
    refetchInterval: false,
    // refetchOnReconnect: true,
  });
  return contactsQuery;
};

// interface IContactAddQuery {
//   userCompany: string;
//   searchInput: string;
//   sendQuotes: boolean;
//   email: string;
//   firstName: string;
//   lastName: string;
//   phone: string;
//   contactCompany?: string;
//   companyName?: string;
//   companyAbn?: string;
//   companyAddress?: string;
// }

// interface addContactQueryProps {
//   queryProps: IContactAddQuery;
// }
interface AddContactQueryProps extends BaseAPIProps {
  onSuccessCb?: (addedContact: Contact) => void;
  filter: ContactsFilters;
}
const useAddContactQuery = ({
  showNotification,
  company,
  onSuccessCb,
  filter,
}: AddContactQueryProps) => {
  const { handleAddNewContact } = useSetContactsQueryData({
    filter,
    addPos: "end",
  });

  let contactsUrl = `/contact/`;
  const mutation = useMutation({
    mutationKey: ["add-contact"],
    mutationFn: async (data: AddContact) => {
      return DoxleAPI.post<Contact>(contactsUrl, data, {
        headers: {
          "User-Company": company?.companyId || "",
        },
      });
    },
    onSuccess: (result, variables, context) => {
      handleAddNewContact(result.data);

      // if (showNotification)
      //   showNotification(
      //     "Contact's Company Uploaded",
      //     "success",
      //     "SUCCESSFULLY Added Contact"
      //   );
      if (onSuccessCb) onSuccessCb(result.data);
    },
    onError: (error, variables, context) => {
      if (showNotification) {
        if (isAxiosError<AxiosBackendErrorReturn>(error)) {
          showNotification(
            `${error?.response?.status ?? "ERROR"}: ${
              error.response?.data.detail ?? "UNKNOWN ERROR"
            }`,
            "error",
            String(
              error?.response?.data?.detail ?? "Fail To Add Contact"
            ).substring(0, 300)
          );
        } else {
          showNotification("Something Wrong!", "error", "Fail To Add Contact");
        }
      }
    },
  });

  return mutation;
};

interface PatchContactQueryProps extends BaseAPIProps {
  filter: ContactsFilters;
  onSuccessCb?: Function;
}
const usePatchContactQuery = ({
  filter,
  onSuccessCb,
  showNotification,
  company,
}: PatchContactQueryProps) => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async (props: {
      data: Partial<Omit<Contact, "contactId">>;
      contactId: string;
    }) => {
      const { data, contactId } = props;
      let contactsUrl = `/contact/`;
      return DoxleAPI.patch(contactsUrl + contactId + "/", data, {
        headers: {
          "User-Company": company?.companyId || "",
        },
      });
    },
    onSuccess: (result, variables, context) => {
      console.log("RESULT:", result.data);
      console.log("variables", variables);
      console.log("CONTEXT", context);
      handleOverwriteContactQueryData({
        queryClient,
        company,
        filter,
        action: "edit",
        edittedContact: result.data,
      });

      // if (showNotification)
      //   showNotification(
      //     "Contact's Company Uploaded",
      //     "success",
      //     "SUCCESSFULLY Edited Contact"
      //   );
      if (onSuccessCb) onSuccessCb();
    },
    onError: (error, variables, context) => {
      if (showNotification) {
        if (isAxiosError<AxiosBackendErrorReturn>(error)) {
          showNotification(
            `${error?.response?.status ?? "ERROR"}: ${
              error.response?.data.detail ?? "UNKNOWN ERROR"
            }`,
            "error",
            String(
              error?.response?.data?.detail ?? "Fail To Edit Contact"
            ).substring(0, 300)
          );
        } else {
          showNotification("Something Wrong!", "error", "Fail To Edit Contact");
        }
      }
    },
  });
  const mutate = (props: { data: Partial<Contact>; contactId: string }) =>
    mutation.mutate(props);
  return { ...mutation, mutate };
};

interface RemoveContactQueryProps extends BaseAPIProps {
  filter: ContactsFilters;
  onSuccessCb?: Function;
}
const useRemoveContactsQuery = ({
  filter,
  onSuccessCb,
  showNotification,
  company,
}: RemoveContactQueryProps) => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async (contactId: string) => {
      let contactsUrl = `/contact/`;
      return DoxleAPI.delete(contactsUrl + contactId + "/", {
        headers: {
          "User-Company": company?.companyId || "",
        },
      });
    },
    onSuccess: (result, variables, context) => {
      console.log("RESULT:", result);
      console.log("variables", variables);
      console.log("CONTEXT", context);
      queryClient.setQueryData(
        formContactListQKey(filter, company),
        (oldResponse: any) => {
          const old: any = oldResponse?.data;
          if (!old) return oldResponse;
          const data: any = produce(old, (draft: any) => {
            let pageIndexContainItem: number = old.pages.findIndex(
              (page: any) =>
                Boolean(
                  page.data.results.find(
                    (contact: Contact) => contact.contactId === variables
                  ) !== undefined
                )
            );
            if (pageIndexContainItem !== -1)
              draft.pages[pageIndexContainItem].data.results = draft.pages[
                pageIndexContainItem
              ].data.results.filter(
                (contact: Contact) => contact.contactId !== variables
              );
          });
          return { ...oldResponse, data };
        }
      );

      // if (showNotification)
      //   showNotification(
      //     "Contact Deleted",
      //     "success",
      //     "SUCCESSFULLY Delete Contact"
      //   );
      if (onSuccessCb) onSuccessCb();
    },
    onError: (error: any, variables, context) => {
      if (showNotification) {
        if (isAxiosError<AxiosBackendErrorReturn>(error)) {
          showNotification(
            `${error?.response?.status ?? "ERROR"}: ${
              error.response?.data.detail ?? "UNKNOWN ERROR"
            }`,
            "error",
            String(
              error?.response?.data?.detail ?? "Fail To Delete Contact"
            ).substring(0, 300)
          );
        } else {
          showNotification(
            "Something Wrong!",
            "error",
            "Fail To Delete Contact"
          );
        }
      }
    },
  });
  const mutate = (contactId: string) => mutation.mutate(contactId);
  return { ...mutation, mutate };
};

interface RemoveMultipleContactQueryProps extends BaseAPIProps {
  filter: ContactsFilters;
  onSuccessCb?: Function;
}
const useRemoveMultipleContactsQuery = ({
  filter,
  onSuccessCb,
  showNotification,
  company,
}: RemoveMultipleContactQueryProps) => {
  const queryClient = useQueryClient();
  const multiDeleteURL = `/contact/contact_delete_multi/`;
  const mutation = useMutation({
    mutationFn: async (contactIds: string[]) => {
      return DoxleAPI.post(multiDeleteURL, contactIds, {
        headers: {
          "User-Company": company?.companyId || "",
        },
      });
    },
    onSuccess: (result: AxiosResponse<any>, variables, context) => {
      console.log("RESULT:", result);
      console.log("variables", variables);
      console.log("CONTEXT", context);
      handleOverwriteContactQueryData({
        queryClient,
        action: "delete",
        company,
        filter,
        deletedContactIds: variables,
      });
      // queryClient.setQueryData(
      //   ["companies", variables.companyId],
      //   (oldData: any) =>
      //     oldData
      //       ? {
      //           ...oldData,
      //           data: [
      //             ...oldData.data.map((company: IContactCompanies) => {
      //               if (company.contacts?.includes(variables.contactId)) {
      //                 company.contacts.filter(
      //                   (value: string) => value !== variables.contactId
      //                 );
      //                 return company;
      //               } else return company;
      //             }),
      //           ],
      //         }
      //       : oldData
      // );

      // queryClient.setQueryData(
      //   ["contacts", variables.companyId, variables.searchInput],
      //   (oldData: any) =>
      //     oldData
      //       ? {
      //           ...oldData,
      //           data: [
      //             ...oldData.data.filter(
      //               (contact: Contact) =>
      //                 contact.contactId !== variables.contactId
      //             ),
      //           ],
      //         }
      //       : oldData
      // );

      // if (showNotification)
      //   showNotification(
      //     "Contact Deleted",
      //     "success",
      //     "SUCCESSFULLY Delete Contact"
      //   );
      if (onSuccessCb) onSuccessCb();
    },
    onError: (error, variables, context) => {
      if (showNotification) {
        if (isAxiosError<AxiosBackendErrorReturn>(error)) {
          showNotification(
            `${error?.response?.status ?? "ERROR"}: ${
              error.response?.data.detail ?? "UNKNOWN ERROR"
            }`,
            "error",
            String(
              error?.response?.data?.detail ?? "Fail To Delete Contact"
            ).substring(0, 300)
          );
        } else {
          showNotification(
            "Something Wrong!",
            "error",
            "Fail To Delete Contact"
          );
        }
      }
    },
  });
  const mutate = (contactIds: string[]) => mutation.mutate(contactIds);
  return { ...mutation, mutate };
};

//###### CONTACT COMPANY SECTION
export interface FilterGetContactCompanyDetailQuery {
  contactCompany: ContactCompany | undefined;
}
interface GetContactCompanyDetailQueryProps extends BaseAPIProps {
  contactCompanyId: string;
  onSuccessCb?: Function;
  enable?: boolean;
}

const useGetContactCompanyDetailQuery = ({
  contactCompanyId,
  onSuccessCb,
  showNotification,
  enable,
  company,
}: GetContactCompanyDetailQueryProps) => {
  const qKey = formContactCompanyDetailQKey(contactCompanyId, company);
  const contactCompanyDetailQuery = useQuery(
    qKey,
    async () => {
      return await DoxleAPI.get<ContactCompany>(
        `/contact/company/${contactCompanyId}/`,
        {
          headers: {
            "User-Company": company?.companyId || "",
          },
        }
      );
    },
    {
      onSuccess: (res) => {},

      onError: (error) => {
        if (showNotification) {
          if (isAxiosError<AxiosBackendErrorReturn>(error)) {
            showNotification(
              `${error?.response?.status ?? "ERROR"}: ${
                error.response?.data.detail ?? "UNKNOWN ERROR"
              }`,
              "error",
              String(
                error?.response?.data?.detail ??
                  "Failed to retrieve contact company details"
              ).substring(0, 300)
            );
          } else {
            showNotification(
              "Something Wrong!",
              "error",
              "Failed to retrieve contact company details"
            );
          }
        }
      },
      enabled: Boolean(company && (enable || false)),
      // staleTime: Infinity,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      refetchInterval: false,
      refetchIntervalInBackground: true,
      cacheTime: 3 * 60 * 1000,
    }
  );

  return contactCompanyDetailQuery;
};

export interface FilterGetContactCompanyList {
  searchInput?: string;
  hasContact?: boolean;
}

interface RetrieveContactCompanyListQueryProps extends BaseAPIProps {
  filter: ContactCompanyFilters;
  onSuccessCb?: Function;
  enable?: boolean;
}
const useRetrieveContactsCompanyQuery = ({
  filter,
  onSuccessCb,
  enable,
  showNotification,
  company,
}: RetrieveContactCompanyListQueryProps) => {
  const params: any = {};
  if (filter.search) params.search = filter.search;
  if (filter.has_contact) params.has_contact = "true";
  const qKey = formContactCompanyListQKey(filter, company);
  const contactsUrl = `/contact/company/?page=1`;
  const contactsQuery = useInfiniteQuery({
    queryKey: qKey,
    queryFn: async ({ pageParam = contactsUrl }) => {
      return DoxleAPI.get(pageParam, {
        headers: {
          "User-Company": company?.companyId || "",
        },
        params,
      });
    },
    getNextPageParam: (prevData) => prevData.data?.next,
    onSuccess: () => {
      if (onSuccessCb) onSuccessCb();
    },

    onError: (error) => {
      if (showNotification) {
        if (isAxiosError<AxiosBackendErrorReturn>(error)) {
          showNotification(
            `${error?.response?.status ?? "ERROR"}: ${
              error.response?.data.detail ?? "UNKNOWN ERROR"
            }`,
            "error",
            String(
              error?.response?.data?.detail ?? "Failed to get contacts list"
            ).substring(0, 300)
          );
        } else {
          showNotification(
            "Something Wrong!",
            "error",
            "Failed to get contacts list"
          );
        }
      }
    },
    enabled: Boolean(
      company !== undefined && (enable ?? true)
      // &&  retrieveContactsQuery.contactPageDisplay === "Contact"
    ),
    retry: true,
    cacheTime: 10 * 60 * 1000,
    refetchInterval: 9 * 60 * 1000,
    refetchOnWindowFocus: false,
    refetchIntervalInBackground: true,
    // refetchOnReconnect: true,
  });
  return contactsQuery;
};

interface AddContactCompanyQueryProps extends BaseAPIProps {
  filter: ContactCompanyFilters;
  onSuccessCb?: (newContact?: ContactCompany) => void;
}
const useAddContactCompanyQuery = ({
  filter,
  onSuccessCb,
  showNotification,

  company,
}: AddContactCompanyQueryProps) => {
  const { handleAddNewCompanyContact } = useSetCompanyContactQueryData({
    filter,
    addPos: "start",
  });
  const mutation = useMutation({
    mutationKey: ["add-contact-company"],
    mutationFn: async (newCompany: {
      name: string;
      abn: string;
      address: string;
      contacts: Contact[];
    }) => {
      let { contacts, ...rest } = newCompany;
      let data: any = { ...rest };
      data.contactsJson = contacts;
      let companiesUrl = "/contact/company/";
      return DoxleAPI.post<ContactCompany>(companiesUrl, data, {
        headers: {
          "User-Company": company?.companyId || "",
        },
      });
    },
    onSuccess: (result, variables, context) => {
      handleAddNewCompanyContact(result.data);

      if (onSuccessCb) onSuccessCb(result.data);
    },
    onError: (error, variables, context) => {
      if (showNotification) {
        if (isAxiosError<AxiosBackendErrorReturn>(error)) {
          showNotification(
            `${error?.response?.status ?? "ERROR"}: ${
              error.response?.data.detail ?? "UNKNOWN ERROR"
            }`,
            "error",
            String(
              error?.response?.data?.detail ?? "Fail To Add Contact's Company"
            ).substring(0, 300)
          );
        } else {
          showNotification(
            "Something Wrong!",
            "error",
            "Fail To Add Contact's Company"
          );
        }
      }
    },
  });

  return mutation;
};

const useRemoveContactsCompanyQuery = (
  company: Company | undefined,
  showNotification?: (
    message: string,
    messageType: "success" | "error",
    extraMessage?: string
  ) => void,
  onSuccessCb?: Function
) => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async (deleteCompany: {
      companyId: string;
      searchInput: string;
      contactCompanyId: string;
    }) => {
      let companiesUrl = "/contact/company/";
      return DoxleAPI.delete(
        companiesUrl + deleteCompany.contactCompanyId + "/",
        {
          headers: {
            "User-Company": company?.companyId ?? "",
          },
        }
      );
    },
    onSuccess: (result, variables, context) => {
      console.log("RESULT:", result);
      console.log("variables", variables);
      console.log("CONTEXT", context);

      queryClient.setQueryData(
        ["companies", variables.companyId, variables.searchInput],
        (oldData: any) =>
          oldData
            ? {
                ...oldData,
                data: [
                  ...oldData.data.filter(
                    (company: ContactCompany) =>
                      company.contactCompanyId !== variables.contactCompanyId
                  ),
                ],
              }
            : oldData
      );

      queryClient.setQueryData(
        ["contacts", variables.companyId, variables.searchInput],
        (oldData: any) =>
          oldData
            ? {
                ...oldData,
                data: [
                  ...oldData.data.filter(
                    (contact: Contact) =>
                      contact.contactCompany !== variables.contactCompanyId
                  ),
                ],
              }
            : oldData
      );

      // if (showNotification)
      //   showNotification(
      //     "Contact's Company Deleted",
      //     "success",
      //     "SUCCESSFULLY Delete Contact's Company"
      //   );
      if (onSuccessCb) onSuccessCb();
    },
    onError: (error, variables, context) => {
      if (showNotification) {
        if (isAxiosError<AxiosBackendErrorReturn>(error)) {
          showNotification(
            `${error?.response?.status ?? "ERROR"}: ${
              error.response?.data.detail ?? "UNKNOWN ERROR"
            }`,
            "error",
            String(
              error?.response?.data?.detail ??
                "Fail To delete Contact's Company"
            ).substring(0, 300)
          );
        } else {
          showNotification(
            "Something Wrong!",
            "error",
            "Fail To delete Contact's Company"
          );
        }
      }
    },
  });

  return mutation;
};

interface RemoveMultipleContactCompanyQueryProps extends BaseAPIProps {
  filter: ContactCompanyFilters;
  onSuccessCb?: Function;
}

const useRemoveMultiContactCompany = ({
  filter,
  onSuccessCb,
  showNotification,

  company,
}: RemoveMultipleContactCompanyQueryProps) => {
  const queryClient = useQueryClient();
  const companiesUrl = "/contact/company_delete_multi/";
  const mutation = useMutation({
    mutationFn: async (deletedIds: string[]) => {
      return DoxleAPI.post(companiesUrl, deletedIds, {
        headers: {
          "User-Company": company?.companyId || "",
        },
      });
    },
    onSuccess: (result, variables, context) => {
      console.log("FRESH RESULT:", result.data);
      console.log("variables", variables);
      console.log("CONTEXT", context);
      handleOverwriteContactCompanyListQueryData({
        queryClient,
        filter,
        company,
        action: "delete",
        deletedContactCompanyIds: variables,
      });

      // if (showNotification)
      //   showNotification(
      //     "Contacts Uploaded",
      //     "success",
      //     "SUCCESSFULLY Updated Contacts"
      //   );
      if (onSuccessCb) onSuccessCb();
    },
    onError: (error, variables, context) => {
      if (showNotification) {
        if (isAxiosError<AxiosBackendErrorReturn>(error)) {
          showNotification(
            `${error?.response?.status ?? "ERROR"}: ${
              error.response?.data.detail ?? "UNKNOWN ERROR"
            }`,
            "error",
            String(
              error?.response?.data?.detail ??
                "Fail To Update Contact's Company"
            ).substring(0, 300)
          );
        } else {
          showNotification(
            "Something Wrong!",
            "error",
            "Fail To update Contact's Company"
          );
        }
      }
    },
  });

  return mutation;
};
export interface IPatchExistingCompanyContacts {
  contactCompany: string;
  name?: string;
  abn?: string;
  address?: string;
  contacts?: Contact[];
}

interface PatchExistingCompanyQueryProps extends BaseAPIProps {
  filter: ContactCompanyFilters;

  onSuccessCb?: Function;
}
const usePatchExistingCompany = ({
  filter,
  onSuccessCb,
  showNotification,

  company,
}: PatchExistingCompanyQueryProps) => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async (newContacts: IPatchExistingCompanyContacts) => {
      let body: any = {};
      if (newContacts.name) {
        body.name = newContacts.name;
      }
      if (newContacts.abn) {
        body.abn = newContacts.abn;
      }
      if (newContacts.address) {
        body.address = newContacts.address;
      }
      body.contactsJson = [];
      if (newContacts.contacts && newContacts.contacts.length > 0) {
        body.contactsJson = newContacts.contacts;
      }
      let companiesUrl = "/contact/company/";
      return DoxleAPI.patch(
        companiesUrl + newContacts.contactCompany + "/",
        body,
        {
          headers: {
            "User-Company": company?.companyId || "",
          },
        }
      );
    },
    onSuccess: (result, variables, context) => {
      console.log("FRESH RESULT:", result.data);
      console.log("variables", variables);
      console.log("CONTEXT", context);
      handleOverwriteContactCompanyListQueryData({
        queryClient,
        filter,
        company,
        action: "edit",
        edittedContactCompany: result.data,
      });
      // let companiesResult = result.data;
      // let contactsResults = result.data.contacts;
      // companiesResult.contacts = contactsResults.map(
      //   (contact: Contact, indexOf: number) => contact.contactId
      // );

      // let contactsResultsInserted: Contact[] = [];
      // let contactsResultsNew: Contact[] = [];

      // Changing the values of Existing Company
      // queryClient.setQueryData(
      //   ["companies", variables.companyId, variables.searchInput],
      //   (oldData: any) =>
      //     oldData
      //       ? {
      //           ...oldData,
      //           data: [
      //             ...oldData.data.map((company: ContactCompany) => {
      //               if (
      //                 company.contactCompanyId ===
      //                 companiesResult.contactCompanyId
      //               ) {
      //                 return companiesResult;
      //               } else return company;
      //             }),
      //           ],
      //         }
      //       : oldData
      // );

      // console.log("inserted before funct ", contactsResultsInserted);
      // console.log("new before funct ", contactsResultsNew);

      // queryClient.setQueryData(
      //   ["contacts", variables.companyId, variables.searchInput],
      //   (oldData: any) =>
      //     oldData
      //       ? {
      //           ...oldData,
      //           data: [
      //             ...oldData.data.map((contact: Contact) => {
      //               if (
      //                 contactsResults.some(
      //                   (result: Contact) =>
      //                     result.contactId === contact.contactId
      //                 )
      //               ) {
      //                 console.log("running inside cont");
      //                 const index = contactsResults
      //                   .map((result: Contact) => result.contactId)
      //                   .indexOf(contact.contactId);
      //                 contactsResultsInserted.push(contactsResults[index]);

      //                 return contactsResults[index];
      //               } else return contact;
      //             }),
      //           ],
      //         }
      //       : oldData
      // );

      // contactsResults.map((contact: Contact) => {
      //   if (!contactsResultsInserted.includes(contact)) {
      //     contactsResultsNew.push(contact);
      //   }
      // });

      // console.log("inserted after funct ", contactsResultsInserted);
      // console.log("new after funct ", contactsResultsNew);

      // if (contactsResultsNew) {
      //   queryClient.setQueryData(
      //     ["contacts", variables.companyId, variables.searchInput],
      //     (oldData: any) =>
      //       oldData
      //         ? {
      //             ...oldData,
      //             data: [...contactsResultsNew, ...oldData.data],
      //           }
      //         : oldData
      //   );
      // }

      // if (showNotification)
      //   showNotification(
      //     "Contacts Uploaded",
      //     "success",
      //     "SUCCESSFULLY Updated Contacts"
      //   );
      if (onSuccessCb) onSuccessCb();
    },
    onError: (error, variables, context) => {
      if (showNotification) {
        if (isAxiosError<AxiosBackendErrorReturn>(error)) {
          showNotification(
            `${error?.response?.status ?? "ERROR"}: ${
              error.response?.data.detail ?? "UNKNOWN ERROR"
            }`,
            "error",
            String(
              error?.response?.data?.detail ?? "Fail To Update Contacts"
            ).substring(0, 300)
          );
        } else {
          showNotification(
            "Something Wrong!",
            "error",
            "Fail To Update Contacts"
          );
        }
      }
    },
  });

  return mutation;
};

interface ImportCSVQueryProps extends BaseAPIProps {
  filterContact: ContactsFilters;
  filterCompanyContact: ContactCompanyFilters;
  onSuccessCb?: Function;
}
const useImportCSVQuery = ({
  showNotification,

  company,
  filterContact,
  filterCompanyContact,
  onSuccessCb,
}: ImportCSVQueryProps) => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async (files: File[]) => {
      const formData = new FormData();
      files.forEach((file) => formData.append("csv", file));
      let contactsUrl = `/contact/`;
      return DoxleAPI.post(contactsUrl + "csv/", formData, {
        headers: {
          "User-Company": company?.companyId || "",
        },
      });
    },
    onSuccess: (result, variables, context) => {
      console.log("FRESH RESULT:", result);
      console.log("variables", variables);
      console.log("CONTEXT", context);

      if (result.data.createdContacts.length !== 0) {
        const createdContacts: Contact[] = result.data.createdContacts;

        // console.log("this is created con", createdContacts);

        // queryClient.setQueryData(
        //   ["contacts", variables.companyId, variables.searchInput],
        //   (oldData: any) =>
        //     oldData
        //       ? {
        //           ...oldData,
        //           data: [...createdContacts, ...oldData.data],
        //         }
        //       : oldData
        // );
        handleOverwriteContactQueryData({
          queryClient,
          company,
          filter: filterContact,
          action: "add",
          appendPos: "start",
          addedContact: [...createdContacts],
        });
      }

      if (result.data.createdCompanies.length !== 0) {
        const createdCompanies: ContactCompany[] = result.data.createdCompanies;

        // console.log("this is created companies", createdCompanies);

        // queryClient.setQueryData(
        //   ["companies", variables.companyId, variables.searchInput],
        //   (oldData: any) =>
        //     oldData
        //       ? {
        //           ...oldData,
        //           data: [...createdCompanies, ...oldData.data],
        //         }
        //       : oldData
        // );
        handleOverwriteContactCompanyListQueryData({
          action: "add",
          appendPos: "start",
          queryClient,
          company,
          filter: filterCompanyContact,
          addedContactCompany: [...createdCompanies],
        });
      }

      // if (showNotification)
      //   showNotification(
      //     "Contacts Uploaded",
      //     "success",
      //     "SUCCESSFULLY Imported CSV"
      //   );
      if (onSuccessCb) onSuccessCb();
    },
    onError: (error, variables, context) => {
      if (showNotification) {
        if (isAxiosError<AxiosBackendErrorReturn>(error)) {
          showNotification(
            `${error?.response?.status ?? "ERROR"}: ${
              error.response?.data.detail ?? "UNKNOWN ERROR"
            }`,
            "error",
            String(
              error?.response?.data?.detail ?? "Fail To Import CSV"
            ).substring(0, 300)
          );
        } else {
          showNotification("Something Wrong!", "error", "Fail To Import CSV");
        }
      }
    },
  });
  const mutate = (files: File[]) => mutation.mutate(files);
  return { ...mutation, mutate };
};

export interface ContactsExportCSVQuery {
  ids?: string[];
  template?: boolean;
}

interface ExportCSVQueryProps extends BaseAPIProps {
  onSuccessCb?: Function;
}
const useExportCSVQuery = ({
  showNotification,

  company,
  onSuccessCb,
}: ExportCSVQueryProps) => {
  // const queryClient = useQueryClient();

  let url = `/contact/csv/`;
  const mutation = useMutation({
    mutationFn: async (body: ContactsExportCSVQuery) => {
      // let getParam: any = {};
      // getParam.template = body.template || false;
      // if (body.ids) {
      //   body.ids.forEach((id) => (getParam.id = id));
      // }
      if (body.template) url += `?template=${body.template}`;
      if (body.ids) {
        body.ids.forEach((id, index) =>
          index === 0
            ? !body.template
              ? (url += `?id=${id}`)
              : (url += `&id=${id}`)
            : (url += `&id=${id}`)
        );
      }
      return DoxleAPI.get(url, {
        headers: {
          "User-Company": company?.companyId || "",
        },
        // params: getParam,
      });
    },
    onSuccess: (result, variables, context) => {
      console.log("FRESH RESULT:", result.data);
      console.log("variables", variables);
      console.log("CONTEXT", context);

      var binaryData = [];
      binaryData.push(result.data);
      const href = window.URL.createObjectURL(
        new Blob(binaryData, { type: "text/csv" })
      );

      // create "a" HTML element with href to file & click
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute("download", "file.csv"); //or any other extension
      document.body.appendChild(link);
      link.click();

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);

      // if (showNotification)
      //   showNotification(
      //     "Contacts Uploaded",
      //     "success",
      //     "SUCCESSFULLY Exported CSV"
      //   );
      if (onSuccessCb) onSuccessCb();
    },
    onError: (error, variables, context) => {
      if (showNotification) {
        if (isAxiosError<AxiosBackendErrorReturn>(error)) {
          showNotification(
            `${error?.response?.status ?? "ERROR"}: ${
              error.response?.data.detail ?? "UNKNOWN ERROR"
            }`,
            "error",
            String(
              error?.response?.data?.detail ?? "Fail To Export CSV"
            ).substring(0, 300)
          );
        } else {
          showNotification("Something Wrong!", "error", "Fail To Export CSV");
        }
      }
    },
  });

  return mutation;
};

//####### HELPER FUNCTIONS #####

//* CONTACT SECTION
interface OverwriteContactQueryData {
  queryClient: QueryClient;
  action: "edit" | "add" | "delete";
  appendPos?: "start" | "end";
  filter: ContactsFilters;
  company: Company | undefined;
  addedContact?: Contact[];
  edittedContact?: Contact;
  deletedContactIds?: string[];
}

const handleOverwriteContactQueryData = ({
  queryClient,
  action,
  filter,
  company,
  addedContact,
  edittedContact,
  deletedContactIds,
  appendPos,
}: OverwriteContactQueryData) => {
  const qKey = formContactListQKey(filter, company);
  if (action === "edit" && edittedContact) {
    return queryClient.setQueryData(qKey, (old: any) =>
      old
        ? {
            ...old,
            pages: [
              ...old.pages.map((page: any) => {
                const isContactInPage: boolean =
                  (page.data.results as Contact[]).filter(
                    (oriContact) =>
                      oriContact.contactId === edittedContact.contactId
                  )[0] !== undefined;
                if (isContactInPage)
                  return {
                    ...page,
                    data: {
                      ...page.data,
                      results: [
                        ...(page.data.results as Contact[]).map(
                          (oriContact) => {
                            if (
                              oriContact.contactId === edittedContact.contactId
                            )
                              return edittedContact;
                            else return oriContact;
                          }
                        ),
                      ],
                    },
                  };
                else return page;
              }),
            ],
          }
        : old
    );
  }

  if (action === "add" && addedContact) {
    return queryClient.setQueryData(qKey, (old: any) =>
      old
        ? {
            ...old,
            pages: [
              ...old.pages.map((page: any, pageIndex: number) => {
                if (pageIndex === 0 && appendPos && appendPos === "start")
                  return {
                    ...page,
                    data: {
                      ...page.data,
                      results: [
                        ...addedContact,
                        ...(page.data.results as Contact[]),
                      ],
                    },
                  };
                else if (
                  pageIndex === old.pages.length - 1 &&
                  (!appendPos || (appendPos && appendPos === "end"))
                )
                  return {
                    ...page,
                    data: {
                      ...page.data,
                      results: [
                        ...(page.data.results as ContactCompany[]),
                        ...addedContact,
                      ],
                    },
                  };
                else return page;
              }),
            ],
          }
        : old
    );
  }
  if (action === "delete" && deletedContactIds) {
    return queryClient.setQueryData(qKey, (old: any) =>
      old
        ? {
            ...old,
            pages: [
              ...old.pages.map((page: any) => {
                const contactsInPage: Contact[] = [];
                deletedContactIds.forEach((id) => {
                  const matchedContact: Contact = (
                    page.data.results as Contact[]
                  ).filter((oriContact) => oriContact.contactId === id)[0];
                  if (matchedContact) contactsInPage.push(matchedContact);
                });

                if (contactsInPage.length > 0) {
                  let newContactList: Contact[] = [
                    ...(page.data.results as Contact[]),
                  ];
                  contactsInPage.forEach(
                    (contact) =>
                      (newContactList = [
                        ...newContactList.filter(
                          (newContact) =>
                            newContact.contactId !== contact.contactId
                        ),
                      ])
                  );
                  return {
                    ...page,
                    data: {
                      ...page.data,
                      results: [...newContactList],
                    },
                  };
                } else return page;
              }),
            ],
          }
        : old
    );
  }
};

export const formContactListQKey = (
  filter: ContactsFilters,
  company: Company | undefined
) => {
  const baseQkey = ["contacts"];
  if (company) baseQkey.push(company.companyId);
  if (filter.search) baseQkey.push(`search:${filter.search}`);
  if (filter.is_primary) baseQkey.push(`isPrimary:${filter.is_primary}`);
  if (filter.send_quotes) baseQkey.push(`sendQuotes:${filter.send_quotes}`);
  if (filter.contact_company)
    baseQkey.push(`contactCompany:${filter.contact_company}`);
  if (filter.order_by) baseQkey.push(`orderBy:${filter.order_by}`);
  return baseQkey;
};
//* END CONTACT SECITON

//* CONTACT COMPANY SECTION
interface OverwriteContactCompanyListQueryData {
  queryClient: QueryClient;
  action: "edit" | "add" | "delete";
  appendPos?: "start" | "end";
  filter: ContactCompanyFilters;
  company: Company | undefined;
  addedContactCompany?: ContactCompany[];
  edittedContactCompany?: ContactCompany;
  deletedContactCompanyIds?: string[];
}

const handleOverwriteContactCompanyListQueryData = ({
  queryClient,
  action,
  filter,
  company,
  addedContactCompany,
  edittedContactCompany,
  deletedContactCompanyIds,
  appendPos,
}: OverwriteContactCompanyListQueryData) => {
  const qKey = formContactCompanyListQKey(filter, company);

  if (action === "edit" && edittedContactCompany) {
    return queryClient.setQueryData(qKey, (old: any) =>
      old
        ? {
            ...old,
            pages: [
              ...old.pages.map((page: any) => {
                const isContactInPage: boolean =
                  (page.data.results as ContactCompany[]).filter(
                    (oriContact) =>
                      oriContact.contactCompanyId ===
                      edittedContactCompany.contactCompanyId
                  )[0] !== undefined;
                if (isContactInPage)
                  return {
                    ...page,
                    data: {
                      ...page.data,
                      results: [
                        ...(page.data.results as ContactCompany[]).map(
                          (oriContact) => {
                            if (
                              oriContact.contactCompanyId ===
                              edittedContactCompany.contactCompanyId
                            )
                              return edittedContactCompany;
                            else return oriContact;
                          }
                        ),
                      ],
                    },
                  };
                else return page;
              }),
            ],
          }
        : old
    );
  }

  if (action === "add" && addedContactCompany) {
    return queryClient.setQueryData(qKey, (old: any) =>
      old
        ? {
            ...old,
            pages: [
              ...old.pages.map((page: any, pageIndex: number) => {
                if (pageIndex === 0 && appendPos && appendPos === "start")
                  return {
                    ...page,
                    data: {
                      ...page.data,
                      results: [
                        ...addedContactCompany,
                        ...(page.data.results as ContactCompany[]),
                      ],
                    },
                  };
                else if (
                  pageIndex === old.pages.length - 1 &&
                  (!appendPos || (appendPos && appendPos === "end"))
                )
                  return {
                    ...page,
                    data: {
                      ...page.data,
                      results: [
                        ...(page.data.results as ContactCompany[]),
                        ...addedContactCompany,
                      ],
                    },
                  };
                else return page;
              }),
            ],
          }
        : old
    );
  }
  if (action === "delete" && deletedContactCompanyIds) {
    return queryClient.setQueryData(qKey, (old: any) =>
      old
        ? {
            ...old,
            pages: [
              ...old.pages.map((page: any) => {
                const contactsInPage: ContactCompany[] = [];
                deletedContactCompanyIds.forEach((id) => {
                  const matchedContact: ContactCompany = (
                    page.data.results as ContactCompany[]
                  ).filter(
                    (oriContact) => oriContact.contactCompanyId === id
                  )[0];
                  if (matchedContact) contactsInPage.push(matchedContact);
                });

                if (contactsInPage.length > 0) {
                  let newContactList: ContactCompany[] = [
                    ...(page.data.results as ContactCompany[]),
                  ];
                  contactsInPage.forEach(
                    (contact) =>
                      (newContactList = [
                        ...newContactList.filter(
                          (newContact) =>
                            newContact.contactCompanyId !==
                            contact.contactCompanyId
                        ),
                      ])
                  );
                  return {
                    ...page,
                    data: {
                      ...page.data,
                      results: [...newContactList],
                    },
                  };
                } else return page;
              }),
            ],
          }
        : old
    );
  }
};
const formContactCompanyDetailQKey = (
  contactCompanyId: string,
  company: Company | undefined
) => {
  const baseQKey = ["contact-company-details"];
  if (company) baseQKey.push(company.companyId);

  baseQKey.push(contactCompanyId);
  return baseQKey;
};

export const formContactCompanyListQKey = (
  filter: ContactCompanyFilters,
  company: Company | undefined
) => {
  const baseQkey = ["contact-company-list"];
  if (company) baseQkey.push(company.companyId);
  if (filter.search) baseQkey.push(`search:${filter.search}`);
  if (filter.has_contact) baseQkey.push(`hasContact`);
  if (filter.order_by) baseQkey.push(`orderBy:${filter.order_by}`);
  return baseQkey;
};
//#### END HELPER FuNCTioNS #####
const ContactsAPI = {
  useRetrieveContactsQuery,
  useRetrieveContactsCompanyQuery,
  useAddContactQuery,
  usePatchContactQuery,
  useAddContactCompanyQuery,
  useRemoveContactsQuery,
  useRemoveMultipleContactsQuery,
  useRemoveContactsCompanyQuery,
  usePatchExistingCompany,
  useImportCSVQuery,
  useExportCSVQuery,
  useGetContactCompanyDetailQuery,
  useRemoveMultiContactCompany,
};
export default ContactsAPI;
