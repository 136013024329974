import React, { useState } from "react";
import { useDoxleCurrentContextStore } from "../../DoxleGeneralStore/useDoxleCurrentContext";
import { shallow } from "zustand/shallow";
import useDoxleNotificationStore from "../../DoxleGeneralStore/useDoxleNotificationStore";
import { useDoxleAuthStore } from "../../DoxleGeneralStore/useDoxleAuthStore";
import DrawingQueryAPI from "../QueryHooks/drawingQueryAPI";
import { DrawingSet } from "../Models/drawings";
import { useDrawingStore } from "../Store/useDrawingStore";
import useSetDrawingsQueryData from "../QueryHooks/useSetDrawingsQueryData";

type Props = {
  drawingItem: DrawingSet;
};

interface DrawingsRow {
  newDrawingSetName: string;
  setNewDrawingSetName: React.Dispatch<React.SetStateAction<string>>;
  handleUpdateDrawingName: () => void;
  isUpdatingDrawing: boolean;
  handleClickMoreIcon: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
  anchorDrawingPoperMenu: HTMLButtonElement | null;
  handleClosePoperMenu: () => void;
  handleClickDeleteDrawing: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => void;
  handleToggleCheckBox: (value: boolean) => void;
  isDrawingChecked: boolean;
  handleClickRow: () => void;
  handleClickImage: () => void;
  handleShareURL: (e: React.MouseEvent<HTMLDivElement>) => void;
}
const useDrawingsRow = ({ drawingItem }: Props): DrawingsRow => {
  const [newDrawingSetName, setNewDrawingSetName] = useState(drawingItem.name);
  const [anchorDrawingPoperMenu, setAnchorDrawingPoperMenu] =
    useState<HTMLButtonElement | null>(null);
  const { company, selectedProject } = useDoxleCurrentContextStore(
    (state) => ({
      company: state.currentCompany,
      selectedProject: state.currentProject,
    }),
    shallow
  );

  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );

  const {
    handleAddDrawingId,
    handleRemoveDrawingId,
    selectedDrawingIds,
    setViewedDrawingSet,
  } = useDrawingStore(
    (state) => ({
      handleAddDrawingId: state.handleAddDrawingId,
      handleRemoveDrawingId: state.handleRemoveDrawingId,
      selectedDrawingIds: state.selectedDrawingIds,
      setViewedDrawingSet: state.setViewedDrawingSet,
    }),
    shallow
  );
  const handleClickRow = () => setViewedDrawingSet(drawingItem);

  const updateDrawings = DrawingQueryAPI.useUpdateDrawingQuery({
    showNotification,

    company,
    projectId: selectedProject?.projectId || "",
  });
  const { setSelectedDrawingForDelete, setPreviewedDrawingSet } =
    useDrawingStore(
      (state) => ({
        setSelectedDrawingForDelete: state.setSelectedDrawingForDelete,
        setPreviewedDrawingSet: state.setPreviewedDrawingSet,
      }),
      shallow
    );
  const handleUpdateDrawingName = () => {
    if (newDrawingSetName !== drawingItem.name) {
      updateDrawings.mutate({
        drawingId: drawingItem.setId!,
        name: newDrawingSetName,
      });
    }
  };

  const handleClickMoreIcon = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.stopPropagation();

    setAnchorDrawingPoperMenu(event.currentTarget);
  };

  const handleClosePoperMenu = () => setAnchorDrawingPoperMenu(null);

  const handleClickDeleteDrawing = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    event.stopPropagation();
    setSelectedDrawingForDelete(drawingItem);
    handleClosePoperMenu();
  };

  const handleToggleCheckBox = (value: boolean) => {
    if (!value) handleRemoveDrawingId(drawingItem.setId!);
    else handleAddDrawingId(drawingItem.setId!);
  };

  const isDrawingChecked = selectedDrawingIds.includes(drawingItem.setId!);
  const handleShareURL = (e:React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    navigator.clipboard.writeText(drawingItem.url);
    showNotification('URL copied to clipboard', 'success');
    handleClosePoperMenu();
  }
  const handleClickImage = () =>
    setPreviewedDrawingSet({
      drawingSet: drawingItem,
    });
  return {
    newDrawingSetName,
    setNewDrawingSetName,
    handleUpdateDrawingName,
    isUpdatingDrawing: updateDrawings.isLoading,
    handleClickMoreIcon,
    anchorDrawingPoperMenu,
    handleClosePoperMenu,
    handleClickDeleteDrawing,
    handleToggleCheckBox,
    isDrawingChecked,
    handleClickRow,
    handleClickImage,
    handleShareURL
  };
};

export default useDrawingsRow;
