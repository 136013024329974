import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { motion } from "framer-motion";
import styled from "styled-components";
import {
  DoxleFont,
  DoxleTheme,
  DoxleThemeColor,
} from "../../../../DoxleGeneralStore/useDoxleThemeStore";
import TableCell from "@mui/material/TableCell";
import Typography from "@mui/material/Typography";
import { editRgbaAlpha } from "../../../../Utilities/FunctionUtilities";

export const StyledBookingDocketTableContainer = styled(motion.div)``;
export const StyledBookingTable = styled(Table)`
  && {
    width: 100% !important;
    height: 100% !important;
    display: flex;
    flex-direction: column;
  }
`;
export const StyledBookingTableHead = styled(TableHead)`
  && {
    width: 100% !important;
    z-index: 1000;
  }
`;
export const StyledBookingTableBody = styled(TableBody)`
  && {
    width: 100% !important;

    display: flex;
    flex-direction: column;
  }
`;

export const StyledBookingTableRow = styled(motion(TableRow))<{
  $themeColor: DoxleThemeColor;
}>`
  && {
    width: 100% !important;

    display: flex;
    flex-direction: row !important;
    align-items: center;
    overflow: hidden;
  }
`;
export const StyledBookingTableRowContainer = styled(motion(TableCell))<{
  $themeColor: DoxleThemeColor;
  $transparentMode: boolean;
  $currentTheme: DoxleTheme;
}>`
  && {
    width: calc(100% - 2px) !important;
    display: flex;
    flex-direction: row;
    padding: 0.4rem 0rem;

    height: calc(4rem - 0.8rem);
    border-bottom: 1px solid ${(p) => p.$themeColor.primaryDividerColor};

    cursor: pointer;
    position: relative;
    &:hover {
      background-color: ${(p) => p.$themeColor.rowHoverColor};
      transition: 0.4s;
    }

    background-color: ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.$themeColor.primaryContainerColor,
        alpha: p.$transparentMode
          ? "0"
          : p.$currentTheme === "light"
          ? "0.3"
          : "0.5",
      })};
  }
`;

export const StyleBookingTableDataCell = styled.div<{
  $widthRatio: `${number}%` | null;

  $horizontalAlign?: "flex-start" | "center" | "flex-end";

  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  height: 100%;
  display: flex;
  justify-content: ${(p) =>
    p.$horizontalAlign ? p.$horizontalAlign : "center"};
  padding: 0px 1rem;
  align-items: center;
  font-family: ${(p) => p.$doxleFont.primaryFont};
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.07rem;
  color: ${(p) => p.$themeColor.primaryFontColor};
  text-transform: capitalize;

  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  ${(p) =>
    p.$widthRatio ? `width: calc(${p.$widthRatio} - 2rem);` : "flex:1;"}

  .title-text-content {
    color: ${(p) => p.$themeColor.primaryFontColor};
    font-family: ${(p) => p.$doxleFont.secondaryTitleFont};
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 500;

    letter-spacing: -0.024rem;
    flex-shrink: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .date-text-content {
    color: ${(p) => p.theme.color.primaryFontColor};
    font-family: ${(p) => p.theme.font.primaryFont};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    flex-shrink: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .date-period-content {
    color: ${(p) => p.$themeColor.doxleColor};
    font-family: ${(p) => p.$doxleFont.secondaryTitleFont};
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 500;

    letter-spacing: -0.024rem;
    flex-shrink: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .date-period-content-overdue {
    color: ${(p) => p.$themeColor.errorColor};
    font-family: ${(p) => p.$doxleFont.secondaryTitleFont};
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 500;

    letter-spacing: -0.024rem;
    flex-shrink: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .delete-img-holder {
    width: 1.4rem;
    margin-right: 8px;
  }
`;
export const StyledBookingTableContainer = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  position: relative;
  overflow: hidden;
`;

export const StyledBookingTableHeaderContainer = styled(TableRow)<{
  $themeColor: DoxleThemeColor;
}>`
  && {
    width: 100% !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: ${(p) => p.$themeColor.primaryBackgroundColor};
    height: 4.4rem;
    z-index: 1000;
  }
`;
export const StyledBookingTableHeaderCell = styled(TableCell)<{
  $widthRatio: `${number}%` | null;
  $horizontalAlign?: "flex-start" | "center" | "flex-end";
  $padding?: string;
  $themeColor: DoxleThemeColor;
}>`
  && {
    ${(p) => (p.$widthRatio ? `width: ${p.$widthRatio};` : "flex:1;")}
    display: flex;
    height: 100%;
    padding: 0px !important;
    justify-content: ${(p) =>
      p.$horizontalAlign ? p.$horizontalAlign : "center"};
    align-items: center;
    cursor: pointer;
    color: ${(p) => p.$themeColor.primaryFontColor};
    text-transform: capitalize;
    ${(p) => (p.$padding ? `padding: ${p.$padding}` : "")};

    border: none !important;
  }
`;

export const StyledBookingHeaderText = styled(Typography)<{
  $doxleFont: DoxleFont;
  $themeColor: DoxleThemeColor;
}>`
  && {
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.028rem;
    font-family: ${(p) => p.$doxleFont.primaryFont};
    color: ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.$themeColor.primaryFontColor,
        alpha: "0.6",
      })};
  }
`;
