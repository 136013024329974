import React, { useMemo } from "react";
import { useMailStore } from "../Store/mailStore";
import { shallow } from "zustand/shallow";
import MailQueryAPI from "./mailAPI";
import { Mail } from "../Models/mail";

type Props = {};
interface ParsedMailData {
  mailList: Mail[];
  mailCount: number;
}
interface IGetMailList {
  mailList: Mail[];
  mailCount: number;
  isFetchingMailList: boolean;
  isSuccessFetchingMailList: boolean;
  isErrorFetchingMailList: boolean;
  isFetchingMoreMailList: boolean;
  fetchMoreMailList: () => void;
}
const useGetMailList = ({}: Props): IGetMailList => {
  const { queryProps, setQueryProps } = useMailStore(
    (state) => ({
      queryProps: state.queryFilter,
      setQueryProps: state.setQueryFilter
    }),
    shallow
  );
  const mailQuery = MailQueryAPI.useRetrieveMailList(queryProps);
  const { mailList, mailCount }: ParsedMailData = useMemo(() => {
    let mailList: Mail[] = [];
    let mailCount = 0;
    if (mailQuery.isSuccess && mailQuery.data?.pages) {
      mailList = mailQuery.data.pages.reduce((acc, dataPage) => {
        mailCount = dataPage.data.count;
        return acc.concat(dataPage.data.results);
      }, [] as Mail[]);
      if (mailList.some(mail => mail.sendStatus === "Sending")) setQueryProps({pollingRate: 7})
      else setQueryProps({pollingRate: 60})
    }
    return { mailList, mailCount };
  }, [mailQuery.data?.pages, mailQuery.isSuccess]);
  const fetchMoreMailList = () => {
    if (mailQuery.hasNextPage) mailQuery.fetchNextPage();
  };
  return {
    mailList,
    mailCount,
    isFetchingMailList: mailQuery.isLoading,
    isSuccessFetchingMailList: mailQuery.isSuccess,
    isErrorFetchingMailList: mailQuery.isError,
    isFetchingMoreMailList: mailQuery.isFetchingNextPage,
    fetchMoreMailList,
  };
};

export default useGetMailList;
