import styled from "styled-components";

import { motion } from "framer-motion";

import { styled as MUIStyled } from "@mui/material/styles";
import { Button, Switch, TextField } from "@mui/material";
import {
  DoxleFont,
  DoxleThemeColor,
} from "../../DoxleGeneralStore/useDoxleThemeStore";

export const StyledFilesContainer = styled.div<{}>`
  width: 100%;
  height: calc(100%);
  background-color: ${(p) => p.theme.color.primaryBackgroundColor};
  display: flex;
  flex-direction: column;
  align-items: center;

  .file-content-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
`;

export const StyledHeaderContainer = styled.div<{
  $reduce: boolean;
}>`
  margin-top: ${(p) => (p.$reduce ? "5px" : "30px")};

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const StyledSubHeaderFirstRow = styled.div`
  margin-top: 0px;
  margin-bottom: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
`;

export const StyledHeaderTitle = styled.span<{
  $reduced: boolean;
}>`
  margin-top: 3px;
  margin-left: 8px;
  font-family: ${(p) => p.theme.font.primaryFont};
  font-style: normal;
  font-weight: 600;
  font-size: ${(p) => (p.$reduced ? "20px" : "40px")};
  text-align: center;
  align-self: center;
  color: ${(p) => p.theme.color.primaryFontColor};
  text-transform: capitalize;
`;

export const StyledSubHeaderSecondRow = styled.div`
  margin-top: 0px;
  margin-bottom: 18px;
  display: flex;
  align-items: center;
`;

export const StyledHeaderSubtitle = styled.span<{}>`
  margin-top: 3px;
  font-family: ${(p) => p.theme.font.secondaryTitleFont};
  font-weight: 500;
  font-size: 16px;

  text-align: center;
  align-self: center;
  color: ${(p) => p.theme.color.primaryFontColor};
`;

export const StyledSubHeaderThirdRow = styled.div`
  margin-top: 0px;
  margin-bottom: 4px;
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  z-index: 3;
`;
export const StyledSubHeaderThirdRowLeft = styled.div`
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 6px;
  flex: 1;
  // width: 20%;
  // height: 100%;
  // background-color: blue;
  // display: flex;
  align-items: center;
  justify-content: start;
`;
export const StyledSubHeaderThirdRowRight = styled.div`
  margin-top: 0px;
  margin-bottom: 0px;
  margin-right: 6px;

  // background-color: yellow;

  height: 30px;
  display: flex;
  // flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

export const StyledAddButton = styled.div<{
  $hidden?: boolean;
}>`
  justify-content: center;
  align-items: center;

  display: ${(p) => (p.$hidden ? "none" : "flex")};
  flex-direction: row;
  margin-right: 8px;
  width: 70px;
  height: 30px;
  border-radius: 4px;
  background-color: ${(p) => p.theme.color.doxleColor};
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  color: ${(p) => p.theme.color.primaryReverseFontColor};
  span {
    color: ${(p) => p.theme.color.primaryReverseFontColor};
    font-size: 14px;
    font-family: ${(p) => p.theme.font.primaryFont};
    font-style: normal;
    font-weight: 600;
    flex: 1;
    text-transform: capitalize;
    text-align: center;
  }
  &:hover {
    opacity: 0.6;
    transition: 0.4s;
  }
`;

export const StyledTextButton = styled.div<{
  $isDelete: boolean;
}>`
  justify-content: center;
  align-items: center;
  display: flex;
  margin-right: 8px;
  width: 50px;
  height: 30px;

  border-radius: 4px;
  background-color: ${(p) => p.theme.color.hoverColor};
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;

  border: 1px solid ${(p) => p.theme.color.primaryDividerColor};
  color: ${(p) => p.theme.color.primaryFontColor};

  &:hover {
    transition: 0.4s;
    background-color: ${(p) =>
      p.$isDelete ? p.theme.color.errorColor : p.theme.color.doxleColor};
    color: ${(p) =>
      p.$isDelete
        ? p.theme.color.primaryFontColor
        : p.theme.color.primaryReverseFontColor};
  }
`;

export const StyledGridListViewButton = styled(Button)<{
  $selected: boolean;
}>`
  && {
    padding: 12px;
    align-self: center;
    align-items: center;
    border-radius: 8px;
    height: 30px;
    transition: all 0.2s ease;
    font-weight: ${(p) => (p.$selected ? 600 : 500)};
    font-size: 14px;
    font-family: ${(p) => p.theme.font.primaryFont};
    color: ${(p) => p.theme.color.primaryReverseFontColor};
    text-transform: none;
    background-color: ${(p) =>
      p.$selected === true ? p.theme.color.doxleColor : "transparent"};
    color: ${(p) =>
      p.$selected === true ? "#fff" : p.theme.color.primaryFontColor};

    &:hover {
      opacity: 1;
      background-color: ${(p) =>
        p.$selected === true ? p.theme.color.doxleColor : "transparent"};
      font-weight: 600;
      cursor: pointer;
    }
  }
  .menu-icon {
    font-size: 16px;
    margin-right: 6px;
    color: ${(p) => (p.$selected ? "#fff" : p.theme.color.primaryFontColor)};
  }
`;

export const StyledAddFileButton = styled(Button)<{
  $themeColor: DoxleThemeColor;
}>`
  && {
    // margin-top: 0px;

    // margin-right: 9px;
    border-radius: 9px;
    display: flex;

    font-weight: 600;
    font-size: 1.5rem;
    text-transform: none;
    padding: 0.4rem 0.8rem;
    height: 3.3rem;

    background-color: ${(p) => p.$themeColor.doxleColor};
    color: ${(p) => p.$themeColor.primaryContainerColor};
  }
`;

export const StyledFolderHeaderImg = styled.img`
  width: 249px;
  height: 378px;
  position: absolute;
  z-index: 0;
  bottom: 0;
  right: -10px;
  @media (max-width: 767px) {
    width: 200px;
    height: 329px;
  }
  @media (min-width: 768px) {
    width: 200px;
    height: 329px;
  }
  @media (min-width: 1024px) {
    width: 219px;
    height: 348px;
  }
  @media (min-width: 1280px) {
    width: 249px;
    height: 378px;
  }
`;
