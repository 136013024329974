import {AnimatePresence} from "framer-motion";
import {ClickAwayListener} from "@mui/material";
import {Virtuoso} from "react-virtuoso";
import React, {useCallback, useMemo, useRef, useState} from "react";
import DoxleEmptyPlaceHolder from "../../../DoxleDesignPattern/DoxleEmptyPlaceHolder/DoxleEmptyPlaceHolder";
import ListLoadingMore from "../../../Utilities/Lottie/ListLoadingMore";
import DoxleListSkeleton from "../../../DoxleDesignPattern/DoxleSkeleton/DoxleListSkeleton";
import {StyledMailConversationButtonWatchersAdd} from "./styledCompnents";
import {shallow} from "zustand/shallow";
import {useDoxleAuthStore} from "../../../DoxleGeneralStore/useDoxleAuthStore";
import {useDoxleThemeStore} from "../../../DoxleGeneralStore/useDoxleThemeStore";
import {useDoxleCurrentContextStore} from "../../../DoxleGeneralStore/useDoxleCurrentContext";
import {Contact} from "../../../Models/addressBook";
import ContactsAPI from "../../../Services/QueryHooks/contactsAPI";
import {Mail} from "../../Models/mail";
import {editRgbaAlpha, TRgbaFormat} from "../../../Utilities/FunctionUtilities";
import {
  StyledContactListScroller,
  StyledSearchContactTextField,
  StyledUserDropdownListContainer,
  StyledUserListItem
} from "../styledCompnents";
import MailAPI from "../../Hooks/mailAPI";
import {ContactsFilters} from "../../../Services/QueryHooks/contactsFilters";

interface Props {
  mail: Mail;
}
interface Coordinates {
  x: number;
  y: number;
}
const AddMailWatchers = ({mail}: Props) => {
  const [showUserListDropdown, setShowUserListDropdown] = useState<Coordinates|null>(null)
  const [assigneeSearchText, setAssigneeSearchText] = useState<string>("");
  const updateMail = MailAPI.useUpdateMail(mail?.mailId ?? "")

  const handleClickUserItem = (contactItem: Contact) => {
    handleCloseUserList();
    const contactId = contactItem.contactId
    if ((mail?.watchers ?? []).includes(contactId)) return;
    let body: Partial<Mail> = {}
    body.watchers = [...(mail?.watchers ?? []), contactId]
    updateMail.mutate(body)
  }
  const company = useDoxleCurrentContextStore(state => state.currentCompany, shallow);
  const searchAssignTextfieldRef = useRef<HTMLInputElement>(null)
  const handleCloseUserList = () => {
    setShowUserListDropdown(null);
  };
  const handleClickAssignWatcherBtn = (e: React.MouseEvent) => {
    setShowUserListDropdown({x: e.clientX, y: e.clientY});
  };
  const isUserSelected = useCallback(
    (item: Contact) => {
      return Boolean(
        (mail?.watchersJson ?? []).find((watcher) => watcher.contactId === item.contactId) !==
        undefined
      );
    },
    [mail.watchersJson]
  );

  const handleSearchAssigneeTextChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setAssigneeSearchText(event.target.value);
  };
  const filterRetrieveContactQuery: ContactsFilters = useMemo(
    () => ({
      search: assigneeSearchText,
    }),
    [assigneeSearchText]
  );

  const retrievedContacts = ContactsAPI.useRetrieveContactsQuery({
    company,
    filter: filterRetrieveContactQuery,
    enable: true,
  });
  const contactList: Contact[] = useMemo(
    () =>
      retrievedContacts.isSuccess
        ? retrievedContacts.data.pages.flatMap(
        (page) => page.data.results ?? []
      ) ?? []
        : [],
    [retrievedContacts.data]
  );
  const handleFetchNextPage = () => {
    retrievedContacts.fetchNextPage();
  };
  const styleProps = useDoxleThemeStore(
    (state) => ({
      $doxleFont: state.doxleFont,
      $themeColor: {...state.doxleThemeColor},
      $currentTheme: state.currentTheme,
    }),
    shallow
  );

  const userListVariants = {
    entering: {
      y: [-10, 0],
      opacity: [0, 1],
      transition: {
        duration: 0.2,
      },
    },
    exiting: {
      y: [0, -10],
      opacity: [1, 0],
      transition: {
        duration: 0.2,
      },
    },
  };
  const userListItemVariants = {
    initial: {
      backgroundColor: styleProps.$themeColor.primaryContainerColor,
      color: styleProps.$themeColor.primaryFontColor,
    },
    selected: {
      color: styleProps.$themeColor.doxleColor,
    },
    hovering: {
      backgroundColor: editRgbaAlpha({
        rgbaColor: styleProps.$themeColor.doxleColor as TRgbaFormat,
        alpha: "0.4",
      }),
      color: styleProps.$themeColor.primaryReverseFontColor,
    },
  };

  return (
    <StyledMailConversationButtonWatchersAdd type="button">
      <img
        src='/assets/icons/watchers_add.svg'
        onClick={handleClickAssignWatcherBtn}
        style={{cursor: "pointer"}}
      />
      <AnimatePresence>
        {showUserListDropdown && (
          <ClickAwayListener
            onClickAway={(event) => {
              event.stopPropagation();
              handleCloseUserList();
            }}
          >
            <StyledUserDropdownListContainer
              {...styleProps}
              $coordinates={showUserListDropdown}
              variants={userListVariants}
              initial={false}
              animate="entering"
              exit="exiting"
            >
              <StyledSearchContactTextField
                {...styleProps}
                variant="standard"
                placeholder="Search Contact..."
                value={assigneeSearchText}
                onChange={handleSearchAssigneeTextChange}
                inputRef={searchAssignTextfieldRef}
              />
              {!retrievedContacts.isFetching && retrievedContacts.isSuccess && (
                <Virtuoso
                  style={{
                    flex: 1,
                    width: "100%",
                    paddingBottom: 20,
                  }}
                  data={contactList}
                  components={{
                    EmptyPlaceholder: React.forwardRef((props, ref) => (
                      <DoxleEmptyPlaceHolder
                        {...props}
                        headTitleText="No Contacts"
                        subTitleText=""
                        containerHeightRatio="100%"
                        containerWidthRatio="100%"
                        headTitleTextStyle={{ fontSize: "2.8rem" }}
                      />
                    )),
                    Scroller: React.forwardRef((props, ref) => (
                      <StyledContactListScroller
                        style={{
                          ...props.style,
                          overflow: "visible",
                        }}
                        ref={ref}
                        {...props}
                      />
                    )),
                  }}
                  itemContent={(index, item) => (
                    <StyledUserListItem
                      {...styleProps}
                      key={`userItem#${item.contactId}`}
                      variants={userListItemVariants}
                      initial="initial"
                      animate={
                        isUserSelected(item) ? "selected" : "initial"
                      }
                      whileHover="hovering"
                      onClick={(
                        event: React.MouseEvent<HTMLDivElement>
                      ) => {
                        event.stopPropagation();
                        handleClickUserItem(item);
                      }}
                    >
                      {item.firstName} {item.lastName}
                    </StyledUserListItem>
                  )}
                  endReached={() => {
                    if (retrievedContacts.hasNextPage) {
                      handleFetchNextPage();
                    }
                  }}
                  atBottomThreshold={0.01}
                />
              )}
              {retrievedContacts.isError && (
                <DoxleEmptyPlaceHolder
                  containerStyle={{ flex: 1 }}
                  headTitleText="Something Wrong!"
                  headTitleTextStyle={{ fontSize: "2.8rem" }}
                />
              )}

              {retrievedContacts.isFetchingNextPage && (
                <ListLoadingMore
                  containerStyle={{
                    position: "absolute",
                    bottom: 0,
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    zIndex: 5,
                  }}
                  animationSize={60}
                />
              )}

              {retrievedContacts.isFetching && (
                <DoxleListSkeleton
                  skeletonType="comment"
                  containerStyle={{
                    flex: 1,
                    width: "calc(100% - 16px)",
                    padding: "4px 8px",
                  }}
                />
              )}
            </StyledUserDropdownListContainer>
          </ClickAwayListener>
        )}
      </AnimatePresence>
    </StyledMailConversationButtonWatchersAdd>
  )
}

export default AddMailWatchers
