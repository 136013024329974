import { shallow } from "zustand/shallow";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import { useProjectStore } from "../../DoxleGeneralStore/useprojectStore";

import {
  StyledMenuBookingsIcon,
  StyledMenuBudgetsIcon,
  StyledMenuContactsIcon,
  StyledMenuDrawingIcon,
  StyledMenuFilesIcon,
  StyledMenuPricebookIcon,
  StyledMenuQAIcon,
} from "./ProjectIcon";
import { FaRegNoteSticky } from "react-icons/fa6";
import { CiMail } from "react-icons/ci";
import {
  StyledMenuItem,
  StyledProjectPopover,
  StyledProjectPopoverMenu,
  StyledProjectTabIcon,
} from "./StyledProjectComponents";

interface Props {
  anchorEl: HTMLDivElement | null;
  setAnchorEl: React.Dispatch<React.SetStateAction<HTMLDivElement | null>>;
}

export default function ProjectPopoverMenu({ anchorEl, setAnchorEl }: Props) {
  const { doxleThemeColor, currentTheme, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
      currentTheme: state.currentTheme,
    })
  );

  const {
    projectTabs,
    selectedProjectTab,
    setSelectedProjectTab,
    permissions,
  } = useProjectStore(
    (state) => ({
      projectTabs: state.projectTabs,
      selectedProjectTab: state.selectedProjectTab,
      setSelectedProjectTab: state.setSelectedProjectTab,
      permissions: state.projectPermissions,
    }),
    shallow
  );

  const getIcon = (tab: string) => {
    const iconColor =
      selectedProjectTab === tab
        ? doxleThemeColor.primaryReverseFontColor
        : doxleThemeColor.primaryFontColor;
    switch (tab) {
      case "Drawings": {
        return (
          <StyledMenuDrawingIcon
            themeColor={doxleThemeColor}
            selected={tab === selectedProjectTab}
          />
        );
      }
      case "Files": {
        return (
          <StyledMenuFilesIcon
            themeColor={doxleThemeColor}
            selected={tab === selectedProjectTab}
          />
        );
      }
      case "Budgets": {
        return (
          <StyledMenuBudgetsIcon
            themeColor={doxleThemeColor}
            selected={tab === selectedProjectTab}
          />
        );
      }
      case "Bookings": {
        return (
          <StyledMenuBookingsIcon
            themeColor={doxleThemeColor}
            selected={tab === selectedProjectTab}
          />
        );
      }
      case "QA": {
        return (
          <StyledMenuQAIcon
            themeColor={doxleThemeColor}
            selected={tab === selectedProjectTab}
          />
        );
      }
      case "Mail": {
        return <CiMail style={{ color: iconColor }} />;
      }
      case "Pricebook": {
        return (
          <StyledMenuPricebookIcon
            themeColor={doxleThemeColor}
            selected={tab === selectedProjectTab}
          />
        );
      }
      case "Contacts": {
        return (
          <StyledMenuContactsIcon
            themeColor={doxleThemeColor}
            selected={tab === selectedProjectTab}
          />
        );
      }
      case "Notes": {
        return <FaRegNoteSticky style={{ color: iconColor }} />;
      }
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <StyledProjectPopoverMenu
      open={open}
      onClose={handleClose}
      $themeColor={doxleThemeColor}
      $currentTheme={currentTheme}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transitionDuration={{ appear: 100, exit: 200 }}
      elevation={4}
    >
      <StyledProjectPopover $themeColor={doxleThemeColor}>
        {projectTabs.map((tab, idx) => (
          <StyledMenuItem
            key={`project_popover_item#${idx}`}
            $themeColor={doxleThemeColor}
            $doxleFont={doxleFont}
            $selected={tab === selectedProjectTab}
            onClick={() => {
              setSelectedProjectTab(tab);
              handleClose();
            }}
          >
            <StyledProjectTabIcon>{getIcon(tab)}</StyledProjectTabIcon>
            {tab}
          </StyledMenuItem>
        ))}
      </StyledProjectPopover>
    </StyledProjectPopoverMenu>
  );
}
