import styled from "styled-components";
import {
  DoxleFont,
  DoxleThemeColor,
} from "../../DoxleGeneralStore/useDoxleThemeStore";
import Button from "@mui/material/Button";

export const StyledDoxle404Screen = styled.div`
  background-color: ${(p) => p.theme.color.primaryBackgroundColor};
  color: ${(p) => p.theme.color.primaryFontColor};
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  row-gap: 2rem;
  flex-direction: column;
`;

export const StyledGoHomepageButton = styled(Button)`
  && {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    flex-direction: row;
    margin-right: 8px;
    width: 145px;
    height: 35px;
    border-radius: 18px !important;
    text-transform: none !important;
    cursor: pointer;
    font-size: 10px;
    background-color: #6c7fff;
    color: #ffffff;
    gap: 4px;
  }
`;
