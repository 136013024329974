import React, { useMemo } from "react";
import { Order } from "../../../Models/orders";
import {
  StyledOrderDetailContent,
  StyledOrderDetailFieldWrapper,
  StyledSelectedOrderViewContainer,
} from "./StyledComponentBookingDocketDetail";
import { useDoxleThemeStore } from "../../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import useSelectedOrderView from "./Hooks/useSelectedOrderView";
import dayjs from "dayjs";
import OrderBudgetDisplayer from "./OrderBudgetDisplayer";
import ViewOrderLineSection from "./ViewOrderLineSection";
import useBookingViewStore from "../../../store/useBookingViewStore";

type Props = {
  selectedOrder: Order;
  contentWrapperHeight: number;
};

const SelectedOrderView = ({ selectedOrder, contentWrapperHeight }: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }),
    shallow
  );
  const {
    orderDetail,
    isFetchingOrderDetail,
    isSuccessFetchingOrderDetail,
    isErrorFetchingOrderDetail,
    refetchOrderDetail,
    isRefetchingOrderDetail,
  } = useSelectedOrderView({ orderItem: selectedOrder });
  const orderLines = useMemo(
    () => orderDetail.lines ?? [],
    [orderDetail.lines]
  );
  const { screenMode } = useBookingViewStore(
    (state) => ({
      screenMode: state.currentScreenDisplay,
    }),
    shallow
  );


  return (
    <StyledSelectedOrderViewContainer
      initial={{ y: -5, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      $wrapperHeight={contentWrapperHeight}
      $displayMode={screenMode}
    >
      <StyledOrderDetailFieldWrapper
        $doxleFont={doxleFont}
        $themeColor={doxleThemeColor}
      >
        <span className="label-text">Contractor</span>

        <StyledOrderDetailContent
          $doxleFont={doxleFont}
          $themeColor={doxleThemeColor}
        >
          {orderDetail.supplier ? orderDetail.supplier : "Unknown Contractor"}
        </StyledOrderDetailContent>
      </StyledOrderDetailFieldWrapper>

      <StyledOrderDetailFieldWrapper
        $doxleFont={doxleFont}
        $themeColor={doxleThemeColor}
      >
        <span className="label-text">Description</span>

        <StyledOrderDetailContent
          $doxleFont={doxleFont}
          $themeColor={doxleThemeColor}
        >
          {orderDetail.description}
        </StyledOrderDetailContent>
      </StyledOrderDetailFieldWrapper>

      <div className="space-between-wrapper">
        <StyledOrderDetailFieldWrapper
          $doxleFont={doxleFont}
          $themeColor={doxleThemeColor}
          style={{ maxWidth: "70%" }}
        >
          <span className="label-text">Date</span>

          <StyledOrderDetailContent
            $doxleFont={doxleFont}
            $themeColor={doxleThemeColor}
          >
            {orderDetail.endDate
              ? dayjs(orderDetail.endDate).format("MMMM DD, YYYY h:mm A")
              : "-"}
          </StyledOrderDetailContent>
        </StyledOrderDetailFieldWrapper>

        <StyledOrderDetailFieldWrapper
          $doxleFont={doxleFont}
          $themeColor={doxleThemeColor}
          style={{ maxWidth: "30%" }}
        >
          <span className="label-text">Payment</span>

          <StyledOrderDetailContent
            $doxleFont={doxleFont}
            $themeColor={doxleThemeColor}
          >
            {orderDetail.paymentTerms}
          </StyledOrderDetailContent>
        </StyledOrderDetailFieldWrapper>
      </div>
      <ViewOrderLineSection
        isFetchingOrderDetail={isFetchingOrderDetail}
        isSuccessFetchingOrderDetail={isSuccessFetchingOrderDetail}
        isErrorFetchingOrderDetail={isErrorFetchingOrderDetail}
        orderLines={orderLines}
      />

      <OrderBudgetDisplayer orderDetail={orderDetail} />
    </StyledSelectedOrderViewContainer>
  );
};

export default SelectedOrderView;
