import React, { useState } from "react";
import { useQuoteContext } from "../QuoteResponse";
import { produce } from "immer";
import { QuoteLine } from "../../../models/quote";

type Props = {};

interface QuoteResponseLineSection {
  newQuoteLineDescription: string;
  setNewQuoteLineDescription: React.Dispatch<React.SetStateAction<string>>;
  handleKeydownDescription: (
    event: React.KeyboardEvent<HTMLDivElement>
  ) => void;
}
const useQuoteResponseLineSection = (
  props: Props
): QuoteResponseLineSection => {
  const [newQuoteLineDescription, setNewQuoteLineDescription] = useState("");
  const { setQuoteResponse, quoteRequestData } = useQuoteContext();

  const handleKeydownDescription = (
    event: React.KeyboardEvent<HTMLDivElement>
  ) => {
    if (event.nativeEvent.key === "Enter") {
      event.preventDefault();
      if (newQuoteLineDescription) {
        setQuoteResponse((prev) =>
          produce(prev, (draft) => {
            const newOrderLine: QuoteLine = {
              lineId: "",
              company: quoteRequestData?.company.companyId,

              index: prev.lines.length + 1,
              description: newQuoteLineDescription,
              itemCost: "0",
              quantity: "0",
              lineCost: "0",
              unit: "each",
            };
            draft.lines.push(newOrderLine);
            return draft;
          })
        );
        setNewQuoteLineDescription("");
      } else event.currentTarget.blur();
    }

    if (event.nativeEvent.key === "Escape") {
      event.preventDefault();
      setNewQuoteLineDescription("");
      event.currentTarget.blur();
    }
  };
  return {
    newQuoteLineDescription,
    setNewQuoteLineDescription,
    handleKeydownDescription,
  };
};

export default useQuoteResponseLineSection;
