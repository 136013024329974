import { motion } from "framer-motion";
import styled from "styled-components";
import {
  DoxleFont,
  DoxleThemeColor,
} from "../../../DoxleGeneralStore/useDoxleThemeStore";
import {
  TRgbaFormat,
  editRgbaAlpha,
} from "../../../Utilities/FunctionUtilities";
import Paper, { PaperProps } from "@mui/material/Paper";

export const StyledNBStatusItemWrapper = styled(motion.div)`
  padding-bottom: 4px;
`;
export const StyledNbStatusItem = styled.div<{
  $doxleFont: DoxleFont;
  $statusColor: string;
  $selected: boolean;
}>`
  width: calc(100% - 1.6rem);
  padding: 0.4rem 0.8rem;
  border-radius: 0.4rem;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  background-color: ${(p) =>
    p.$selected
      ? editRgbaAlpha({
          rgbaColor: p.$statusColor as TRgbaFormat,
          alpha: "0.4",
        })
      : "transparent"};
  .status-text {
    color: ${(p) => (p.$selected ? "white" : p.$statusColor)};
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-size: 1.4rem;
    font-style: normal;
    font-weight: ${(p) => (p.$selected ? 600 : 400)};
    line-height: normal;
  }
  transition: 0.4s;

  &:hover {
    background-color: ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.$statusColor as TRgbaFormat,
        alpha: "0.2",
      })};
    transition: 0.4s;
    .status-text {
      color: ${(p) => p.$statusColor} !important;
    }
  }
`;
interface IStatusListContainerProps
  extends Omit<
    PaperProps,
    "onDragStart" | "onAnimationStart" | "onDragEnd" | "onDrag"
  > {
  $themeColor: DoxleThemeColor;
}
const AnimatedPaper = motion(Paper);
const statusListAnimatedVariants = {
  entering: {
    y: [-10, 0],
    opacity: [0, 1],
    transition: {
      duration: 0.4,
    },
  },
  exiting: {
    y: [0, -10],
    opacity: [1, 0],
    transition: {
      duration: 0.4,
    },
  },
};
export const StyledNbStatusListContainer = styled(
  ({ $themeColor, ...rest }: IStatusListContainerProps) => (
    <AnimatedPaper
      {...rest}
      variants={statusListAnimatedVariants}
      animate="entering"
      exit="exiting"
      initial="initial"
      layout
      elevation={4}
    />
  )
)`
  && {
    background-color: ${(p) => p.$themeColor.primaryContainerColor};
    width: 18rem;
    min-height: 18rem;
    border-radius: 0px 9px 9px 9px;
    position: relative;
    padding: 1rem;
    box-shadow: 0px 0px 8px 0px ${(p) => p.$themeColor.primaryBoxShadowColor};
    border: 1px solid ${(p) => p.$themeColor.primaryDividerColor};
    display: flex;
    flex-direction: column;
  }
`;
export const StyledNbStatusListTitle = styled.span<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  color: ${(p) => p.$themeColor.primaryFontColor};
  font-family: ${(p) => p.$doxleFont.primaryFont};
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 1rem;
  text-transform: capitalize;
  padding-left: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
