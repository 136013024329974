import React, { useCallback, useMemo, useState } from "react";
import {
  CREATE_NEW_CONTACT_COMPANY_TEMPLATE,
  CREATE_NEW_CONTACT_TEMPLATE,
  Contact,
  ContactCompany,
} from "../../../Models/addressBook";
import { useDoxleCurrentContextStore } from "../../../DoxleGeneralStore/useDoxleCurrentContext";
import { shallow } from "zustand/shallow";
import useDoxleNotificationStore from "../../../DoxleGeneralStore/useDoxleNotificationStore";
import {
  checkEmailValid,
  checkPhoneValid,
  checkValidABN,
} from "../../../Utilities/FunctionUtilities";
import ContactsAPI from "../../../Services/QueryHooks/contactsAPI";

type Props = { handleCloseForm: () => void };

interface IAddContactForm {
  shouldShowError: boolean;
  handleAddContactCompany: () => void;
  newCompanyContact: ContactCompany;
  setNewCompanyContact: React.Dispatch<React.SetStateAction<ContactCompany>>;
  isAddingContact: boolean;

  newContact: Contact;
  setNewContact: React.Dispatch<React.SetStateAction<Contact>>;
  handleAddContact: (event: React.FormEvent<HTMLElement>) => void;
}
const useAddContactForm = ({ handleCloseForm }: Props): IAddContactForm => {
  const initialContact: Contact = useMemo(
    () => ({
      ...CREATE_NEW_CONTACT_TEMPLATE({}),
    }),
    []
  );
  const [shouldShowError, setShouldShowError] = useState(false);
  const [newCompanyContact, setNewCompanyContact] = useState<ContactCompany>({
    ...CREATE_NEW_CONTACT_COMPANY_TEMPLATE({}),
  });
  const [newContact, setNewContact] = useState<Contact>({ ...initialContact });
  const company = useDoxleCurrentContextStore(
    (state) => state.currentCompany,
    shallow
  );
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );
  const companyContactsRegexCheck = useCallback(() => {
    let errorCounter = 0;
    //# CHECK COMPANY
    Object.keys(newCompanyContact).map((key) => {
      let keyItem = key as keyof ContactCompany;
      if (keyItem === "name" || keyItem === "address") {
        if (!newCompanyContact[keyItem]) errorCounter += 1;
      } else if (keyItem === "email") {
        if (
          newCompanyContact[keyItem] &&
          !checkEmailValid(newCompanyContact[keyItem])
        )
          errorCounter += 1;
      } else if (keyItem === "phone") {
        if (!newCompanyContact[keyItem]) errorCounter += 1;
        else if (
          newCompanyContact[keyItem] &&
          !checkPhoneValid(newCompanyContact[keyItem])
        )
          errorCounter += 1;
      } else if (keyItem === "abn") {
        if (
          newCompanyContact[keyItem] &&
          !checkValidABN(newCompanyContact[keyItem])
        )
          errorCounter += 1;
      }
    });

    if (errorCounter === 0) {
      return true;
    } else {
      setShouldShowError(true);
      return false;
    }
  }, [newCompanyContact]);
  const contactsRegexCheck = () => {
    let errorCounter = 0;
    if (!newContact.firstName) {
      errorCounter = errorCounter + 1;
    }
    if (!newContact.lastName) {
      errorCounter = errorCounter + 1;
    }
    if (
      (newContact.email && !checkEmailValid(newContact.email)) ||
      !newContact.email
    ) {
      errorCounter = errorCounter + 1;
    }
    if (newContact.phone && !checkPhoneValid(newContact.phone)) {
      errorCounter = errorCounter + 1;
    }
    if (errorCounter === 0) {
      return true;
    } else {
      setShouldShowError(true);
      return false;
    }
  };
  const postCompanyContacts = ContactsAPI.useAddContactCompanyQuery({
    showNotification,

    company,
    filter: {},
    onSuccessCb: () => {
      handleCloseForm();
    },
  });

  const handleAddContactCompany = () => {
    const checkValid = companyContactsRegexCheck();

    if (checkValid)
      postCompanyContacts.mutate({
        name: newCompanyContact.name,
        abn: newCompanyContact.abn,
        address: newCompanyContact.address,
        contacts: [],
      });
  };

  const postContact = ContactsAPI.useAddContactQuery({
    showNotification,
    company,
    filter: {},
    onSuccessCb: () => {
      handleCloseForm();
    },
  });

  const handleAddContact = (event: React.FormEvent<HTMLElement>) => {
    event.preventDefault();
    const contactsRegex = contactsRegexCheck();

    if (contactsRegex) {
      postContact.mutate({
        contactId: "",
        firstName: newContact.firstName,
        lastName: newContact.lastName,
        email: newContact.email,
        phone: newContact.phone,
        contactCompany: undefined,
        companyName: undefined,
        companyAbn: undefined,
        companyAddress: undefined,
        sendQuotes: newContact.sendQuotes,
        userCompany: company?.companyId || "",
      });
    }
  };
  return {
    shouldShowError,
    handleAddContactCompany,
    newCompanyContact,
    setNewCompanyContact,
    isAddingContact:
      postCompanyContacts.isLoading || postCompanyContacts.isLoading,
    newContact,
    setNewContact,
    handleAddContact,
  };
};

export default useAddContactForm;
