import { memo } from "react";
import { shallow } from "zustand/shallow";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { useContactsStore } from "../Store/useContactsStore";
import {
  CONTACTS_TABLE_HEADER_LIST,
  TContactsTableHeaders,
} from "../../Models/contactsTable";
import {
  CONTACTS_HEADERS_MAP,
  ContactsOrderByFields,
  RevContactsOrderByFields,
} from "../../../Services/QueryHooks/contactsFilters";
import {
  StyledContactTableHeaderCell,
  StyledContactTableHeaderRow,
  StyledContactTableHeaderText,
} from "../StyledComponents";

const ContactsTableHeader = () => {
  const { orderBy, setQueryFilter } = useContactsStore(
    (state) => ({
      orderBy: state.contactsQueryFilter.order_by,
      setQueryFilter: state.setContactQueryFilter,
    }),
    shallow
  );
  const { doxleThemeColor, doxleFont, staticMenuColor } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
      staticMenuColor: state.staticMenuColor,
    }),
    shallow
  );
  const handleHeaderClick = (header: TContactsTableHeaders) => {
    let newOrderByParam: ContactsOrderByFields | RevContactsOrderByFields =
      CONTACTS_HEADERS_MAP[header];
    if (orderBy && newOrderByParam.includes(orderBy))
      newOrderByParam = `-${newOrderByParam}`;
    setQueryFilter({ order_by: newOrderByParam });
  };

  const checkHeaderMatch = (header: TContactsTableHeaders) => {
    return !!orderBy?.includes(CONTACTS_HEADERS_MAP[header]);
  };

  return (
    <StyledContactTableHeaderRow>
      {CONTACTS_TABLE_HEADER_LIST.map(
        (header, idx) =>
          header.isDisplayed && (
            <StyledContactTableHeaderCell
              $themeColor={doxleThemeColor}
              widthInPixel={
                header.headerName !== "Primary Contact" &&
                header.headerName !== "Send Quotes"
                  ? null
                  : "60px"
              }
              $doxleFont={doxleFont}
              horizontalAlign={
                header.headerName === "Company Name" ||
                header.headerName === "First Name" ||
                header.headerName === "Last Name"
                  ? "flex-start"
                  : "center"
              }
              key={`header#${idx}`}
              onClick={() => handleHeaderClick(header.headerName)}
            >
              <StyledContactTableHeaderText
                $fontWeight={checkHeaderMatch(header.headerName) ? 800 : 400}
                $doxleFont={doxleFont}
              >
                {header.headerName}
              </StyledContactTableHeaderText>
              {checkHeaderMatch(header.headerName) &&
                orderBy?.startsWith("-") && (
                  <ArrowDropDownIcon
                    style={{ color: staticMenuColor.staticFontColor }}
                  />
                )}
              {checkHeaderMatch(header.headerName) &&
                !orderBy?.startsWith("-") && (
                  <ArrowDropUpIcon
                    style={{ color: staticMenuColor.staticFontColor }}
                  />
                )}
            </StyledContactTableHeaderCell>
          )
      )}
      <StyledContactTableHeaderCell
        $themeColor={doxleThemeColor}
        widthInPixel={"25px"}
        $doxleFont={doxleFont}
        horizontalAlign={"center"}
        key={`header#delete`}
      >
        &nbsp;
      </StyledContactTableHeaderCell>
    </StyledContactTableHeaderRow>
  );

  return <></>;
};

export default memo(ContactsTableHeader);
