import React, { useState } from "react";
import woman from "../images/woman_with_dog.png";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import { editRgbaAlpha } from "../../../Utilities/FunctionUtilities";
import GirlWithDog from "../components/svg/GirlWithDog";
import {
  ButtonContainer,
  ContactPageContainer,
  ContactPageLeft,
  ContactPageRight,
  FormArea,
  StyledInput,
  StyledTextArea,
  StyledTitle,
  StyledWarning,
  SubmitBtn,
  StyledErrorText,
} from "./styles/StyledConactUsPage";
import useContactUs from "../Hooks/useContactUs";
import JoinSuccessPage from "../components/JoinPage/JoinSuccessPage";
import Loading from "../../../Utilities/Lottie/Loading";

const NAME_TRIGGER_ID = 0;
const EMAIL_TRIGGER_ID = 1;
const MESSAGE_TRIGGER_ID = 2;

const emailRegex = new RegExp(
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);

export default function ContactUs() {
  const { doxleThemeColor, doxleFont, currentTheme } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
      currentTheme: state.currentTheme,
    })
  );
  const {
    NAME_TRIGGER_ID,
    EMAIL_TRIGGER_ID,
    MESSAGE_TRIGGER_ID,
    prompt,
    name,
    setName,
    email,
    setEmail,
    message,
    setMessage,
    handleSubmit,
    sendState,
  } = useContactUs();
  if (sendState === "success") return <JoinSuccessPage />;
  return (
    <ContactPageContainer>
      <ContactPageLeft>
        <GirlWithDog
          currentTheme={currentTheme}
          style={{ minWidth: "30vw", maxWidth: "40vw" }}
        />
      </ContactPageLeft>
      <ContactPageRight $themeColor={doxleThemeColor}>
        <StyledTitle $themeColor={doxleThemeColor} $doxleFont={doxleFont}>
          Email us
        </StyledTitle>
        {sendState === "sending" ? (
          <Loading />
        ) : (
          <FormArea>
            <StyledWarning>{prompt[NAME_TRIGGER_ID]}</StyledWarning>
            <StyledInput
              $themeColor={doxleThemeColor}
              $doxleFont={doxleFont}
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Name"
            />
            <br />
            <StyledWarning>{prompt[EMAIL_TRIGGER_ID]}</StyledWarning>
            <StyledInput
              $themeColor={doxleThemeColor}
              $doxleFont={doxleFont}
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email"
            />
            <br />
            <StyledWarning>{prompt[MESSAGE_TRIGGER_ID]}</StyledWarning>
            <StyledTextArea
              $themeColor={doxleThemeColor}
              $doxleFont={doxleFont}
              placeholder="Message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
            <ButtonContainer>
              <SubmitBtn $themeColor={doxleThemeColor} onClick={handleSubmit}>
                Submit
              </SubmitBtn>
            </ButtonContainer>
          </FormArea>
        )}
        {sendState === "error" && (
          <StyledErrorText $themeColor={doxleThemeColor}>
            An Error Occurred Please Try Again
          </StyledErrorText>
        )}
      </ContactPageRight>
    </ContactPageContainer>
  );
}
