import React, { useEffect, useRef, useState } from "react";
import {
  CircularProgress,
  ClickAwayListener,
  Grow,
  Popper,
} from "@mui/material";
import {
  StyledDrawingsImg,
  StyledDrawingTableInput,
  StyledDrawingPopperMenu,
  StyledRightMainDrawingContainer,
  StyledRightCornerMenuContainer,
  StyledBottomContainer,
  StyledPageInformation,
  StyledDrawingInfoContainer,
  StyledDrawingsImageContainer,
  StyledDrawingNameContainer,
  StyledDrawingWrapper,
} from "./DrawingsStyledComponent";
import { DrawingContainerDragIcon, DrawingDropDownIcon } from "./DrawingsIcon";
import { DrawingSet } from "../Models/drawings";
import { shallow } from "zustand/shallow";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import DoxleIconButton from "../../DoxleDesignPattern/DoxleButtons/DoxleIconButton";
import useDrawingsRow from "../Hooks/useDrawingsRow";
import ModeIcon from "@mui/icons-material/Mode";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import { DraggableProvided } from "@hello-pangea/dnd";
import DoxleEmptyPlaceHolder from "../../DoxleDesignPattern/DoxleEmptyPlaceHolder/DoxleEmptyPlaceHolder";
import { Error404Banner } from "../../DoxleDesignPattern/DoxleIcons/CommonIcons";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import useDoxleNotificationStore from "../../DoxleGeneralStore/useDoxleNotificationStore";
type Props = {
  drawing: DrawingSet;

  provided: DraggableProvided;
  isClone?: boolean;
};

const DrawingsRow = ({ drawing, provided, isClone = false }: Props) => {
  const nameRef = useRef<HTMLInputElement>(null);
  const { THEME_COLOR, doxleFont } = useDoxleThemeStore(
    (state) => ({
      THEME_COLOR: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }),
    shallow
  );
  const {
    newDrawingSetName,
    setNewDrawingSetName,
    handleUpdateDrawingName,
    isUpdatingDrawing,
    handleClickMoreIcon,
    anchorDrawingPoperMenu,
    handleClosePoperMenu,
    handleClickDeleteDrawing,
    handleToggleCheckBox,
    isDrawingChecked,
    handleClickRow,
    handleClickImage,
    handleShareURL,
  } = useDrawingsRow({ drawingItem: drawing });
  const openPopper = Boolean(anchorDrawingPoperMenu);
  const id = openPopper ? `drawing-poper#${drawing.setId}` : undefined;

  const [isImgErr, setIsImgErr] = useState(false);

  return (
    <StyledDrawingWrapper
      $isClone={isClone}
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
    >
      <DrawingContainerDragIcon themeColor={THEME_COLOR} />

      <StyledRightMainDrawingContainer>
        <StyledDrawingsImageContainer
          key={`drawingImage#${drawing.setId}`}
          $themeColor={THEME_COLOR}
          $doxleFont={doxleFont}
        >
          {isImgErr && (
            <DoxleEmptyPlaceHolder
              headTitleText="Failed to load image, please try again later"
              containerBgColor="transparent"
              headTitleTextStyle={{
                fontSize: 25,
                color: "red",
              }}
              illustration={
                <Error404Banner
                  themeColor={THEME_COLOR}
                  containerStyle={{
                    width: "40%",
                    maxWidth: 200,
                    marginBottom: 14,
                  }}
                />
              }
              containerStyle={{
                position: "absolute",
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 1,
              }}
            />
          )}
          {drawing.sheets.length > 0 ? (
            <StyledDrawingsImg
              onClick={(event) => {
                event.stopPropagation();
                handleClickImage();
              }}
              alt={`drawingThumb#${drawing.setId}`}
              src={
                drawing.sheets[0]
                  ? drawing.sheets[0].imageUrl
                    ? drawing.sheets[0].imageUrl
                    : drawing.sheets[0].thumbUrl
                  : ""
              }
              onError={() => {
                setIsImgErr(true);
              }}
            />
          ) : (
            <div className="empty-sheet-display">No sheet</div>
          )}
        </StyledDrawingsImageContainer>

        <StyledBottomContainer>
          <StyledDrawingInfoContainer>
            <StyledDrawingTableInput
              InputProps={{ disableUnderline: true }}
              key={`drawingName#${drawing.setId}`}
              $widthInPixel={null}
              $themeColor={THEME_COLOR}
              $doxleFont={doxleFont}
              inputRef={nameRef}
              onClick={(event) => event.stopPropagation()}
              value={newDrawingSetName}
              onChange={(event) => setNewDrawingSetName(event.target.value)}
              variant="standard"
              onKeyUpCapture={(event) => {
                if (event.nativeEvent.key === "Enter") {
                  handleUpdateDrawingName();
                  nameRef.current?.blur();
                } else if (event.nativeEvent.key === "Escape") {
                  setNewDrawingSetName(drawing.name);
                  nameRef.current?.blur();
                }
              }}
              onFocus={(event) => event.target.select()}
            />

            <StyledPageInformation $themeColor={THEME_COLOR}>
              {drawing.sheets.length} Pages
            </StyledPageInformation>

            <StyledRightCornerMenuContainer>
              {!isUpdatingDrawing && (
                <DoxleIconButton
                  onClick={handleClickMoreIcon}
                  iconSource={<DrawingDropDownIcon {...THEME_COLOR} />}
                  sx={{
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                  }}
                  buttonSize={40}
                />
              )}

              {isUpdatingDrawing && (
                <CircularProgress
                  style={{ color: THEME_COLOR.primaryFontColor }}
                  size="2rem"
                />
              )}
            </StyledRightCornerMenuContainer>
          </StyledDrawingInfoContainer>
        </StyledBottomContainer>
      </StyledRightMainDrawingContainer>

      <Popper
        id={id}
        open={openPopper}
        anchorEl={anchorDrawingPoperMenu}
        transition
        // disablePortal
        placement="auto-end"
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={handleClosePoperMenu}>
            <Grow {...TransitionProps} timeout={350}>
              <StyledDrawingPopperMenu
                $themeColor={THEME_COLOR}
                elevation={4}
                $doxleFont={doxleFont}
              >
                <div className="drawing-title">Drawing Menu</div>
                <div className="edit-menu-button" onClick={handleShareURL}>
                  <ContentCopyIcon
                    sx={{
                      color: "inherit",
                      mr: "0.8rem",
                      fontSize: "1.6rem",
                    }}
                  />
                  Share URL
                </div>
                <div
                  className="edit-menu-button"
                  onClick={(event) => {
                    event.stopPropagation();
                    nameRef.current?.focus();
                    handleClosePoperMenu();
                  }}
                >
                  <ModeIcon
                    sx={{
                      color: "inherit",
                      mr: "0.8rem",
                      fontSize: "1.6rem",
                    }}
                  />
                  Edit Name
                </div>

                <div
                  className="delete-menu-button"
                  onClick={handleClickDeleteDrawing}
                >
                  <DeleteOutlineRoundedIcon
                    sx={{
                      color: "inherit",
                      mr: "0.8rem",
                      fontSize: "1.6rem",
                    }}
                  />
                  Delete Drawing
                </div>
              </StyledDrawingPopperMenu>
            </Grow>
          </ClickAwayListener>
        )}
      </Popper>
    </StyledDrawingWrapper>
  );
};

export default React.memo(DrawingsRow);
