import { shallow } from "zustand/shallow";
import { useEffect, useMemo } from "react";
import { StyledPaymentClaimTabContainer } from "./StyledPaymentClaimComponents";

import { useEditDocketSideScreenStore } from "../../../CoreContent/EditDocketSideScreen/EditDocketSideScreenStore/useEditDocketSideScreenStore";
import PaymentClaimAPI from "../../QueryHooks/PaymentClaimAPI";
import useDoxleNotificationStore from "../../../DoxleGeneralStore/useDoxleNotificationStore";
import { useDoxleAuthStore } from "../../../DoxleGeneralStore/useDoxleAuthStore";
import { useDoxleCurrentContextStore } from "../../../DoxleGeneralStore/useDoxleCurrentContext";
import PaymentClaim from "../../Models/PaymentClaim";
import { usePaymentClaimStore } from "../../OrdersStore/usePaymentClaimStore";
import PaymentClaimList from "./PaymentClaimList/PaymentClaimList";
import ReviewPaymentClaim from "./ReviewPaymentClaim/ReviewPaymentClaim";
import SubmitNewPaymentClaim from "./SubmitNewPaymentClaim";
import ViewXeroBill from "./ReviewPaymentClaim/ViewXeroBill";
import { useEditOrderStore } from "../../OrdersStore/useEditOrderStore";
import { useIsMutating } from "@tanstack/react-query";

interface Props {}
interface IDroppedFile {
  name: string;
  sizeInBytes: number;
  type: string;
  index: number;
}

const PaymentClaimTab = ({}: Props) => {
  const {
    action,
    setAction,
    reviewPaymentClaim,
    resetStore,
    setReviewPaymentClaim,
  } = usePaymentClaimStore(
    (state) => ({
      action: state.action,
      setAction: state.setAction,
      reviewPaymentClaim: state.reviewPaymentClaim,
      setReviewPaymentClaim: state.setReviewPaymentClaim,
      resetStore: state.resetStore,
    }),
    shallow
  );
  const resetOrderStore = useEditOrderStore(
    (state) => state.resetStore,
    shallow
  );
  const { setHandleAddButton } = useEditDocketSideScreenStore(
    (state) => ({
      setHandleAddButton: state.setHandleAddButtonFn,
    }),
    shallow
  );

  const handleAddButton = () => {
    setAction("new");
  };
  const isAddingItem = useIsMutating({ mutationKey: ["add-payment"] });
  useEffect(() => {
    setHandleAddButton([
      { btnFunction: handleAddButton, isLoading: isAddingItem > 0 },
    ]);
  }, [setHandleAddButton, handleAddButton, isAddingItem]);

  useEffect(() => {
    resetStore();
    resetOrderStore();
  }, []);

  return (
    <StyledPaymentClaimTabContainer
      layout
      initial={{
        y: 10,
        opacity: 0,
      }}
      animate={{
        y: 0,
        opacity: 1,
      }}
    >
      {!action && <PaymentClaimList />}
      {action === "viewXero" && reviewPaymentClaim && (
        <ViewXeroBill claim={reviewPaymentClaim} />
      )}
      {action === "review" && reviewPaymentClaim && (
        <ReviewPaymentClaim claim={reviewPaymentClaim} />
      )}
      {(action === "new" || action === "backcharge") && (
        <SubmitNewPaymentClaim />
      )}
    </StyledPaymentClaimTabContainer>
  );
};
export default PaymentClaimTab;
