import {
  Button,
  FormControlLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableContainerProps,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import styled from "styled-components";
import {
  DoxleFont,
  DoxleTheme,
  DoxleThemeColor,
  useDoxleThemeStore,
} from "../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import { editRgbaAlpha, TRgbaFormat } from "../../Utilities/FunctionUtilities";
import { motion } from "framer-motion";

export const StyledBudgetRootContainer = styled.div<{
  $themeColor: DoxleThemeColor;
}>`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px !important;
  background-color: ${(p) => p.$themeColor.primaryBackgroundColor};
  .content-wrapper {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    max-width: 1280px;
    min-width: 500px;
  }
`;

export const StyledBudgetHeaderContainer = styled.div`
  display: flex;
  margin: 3rem 0px;
  width: 100%;
  align-items: center;
`;

export const StyledBudgetHeaderSpaceBetweenDiv = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #c3c3cf;
  width: 100%;
  min-width: 600px;
  align-items: center;
  padding-bottom: 4px;
`;

export const StyledBudgetHeaderSpan = styled.span`
  display: flex;
  align-items: center;
`;

export const StyledBudgetTableHeaderRootContainer = styled.div<{}>`
  width: 100% !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 30px;
  background-color: ${(p) => p.theme.color.primaryBackgroundColor};
`;

export const StyledBudgetTableHeaderCell = styled.div<{
  $widthRatio: `${number}%` | null;
  $horizontalAlign?: "flex-start" | "center" | "flex-end";
  $padding?: string;
  $themeColor: DoxleThemeColor;
}>`
  ${(p) => (p.$widthRatio ? `width: ${p.$widthRatio};` : "flex:1;")}
  display: flex;
  height: 100%;
  padding: 0px !important;
  justify-content: ${(p) =>
    p.$horizontalAlign ? p.$horizontalAlign : "center"};
  align-items: center;
  cursor: pointer;
  color: ${(p) => p.$themeColor.primaryFontColor};
  text-transform: capitalize;
  ${(p) => (p.$padding ? `padding: ${p.$padding}` : "")};

  border: none !important;
`;

export const StyledBudgetHeaderTitleText = styled(Typography)<{}>`
  && {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    font-family: ${(p) => p.theme.font.primaryFont};
    color: ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.theme.color.primaryFontColor,
        alpha: "0.4",
      })};
  }
`;

export const StyledBudgetTableDataRootContainer = styled.div<{
  $themeColor: DoxleThemeColor;
}>`
  display: flex;
  width: 100%;
  align-items: center;
  /* &:hover {
      background-color: ${(p) => p.$themeColor.rowHoverColor};
      transition: 0.4s;
    } */
`;
export const StyledBudgetMenuIconContainer = styled.div`
  position: relative;
  cursor: pointer;

  margin-right: 5px;
`;

export const StyledBudgetTableDataContainer = styled(motion.div)<{}>`
  width: calc(100%) !important;
  display: flex;
  flex-direction: row;
  padding: 0px !important;
  height: 40px;
  align-items: center;
  border-bottom: 1px solid ${(p) => p.theme.color.primaryDividerColor};
  z-index: 0;
  cursor: pointer;
  position: relative;
  background-color: ${(p) =>
    editRgbaAlpha({
      rgbaColor: p.theme.color.primaryContainerColor,
      alpha: p.theme.transparentMode ? "0" : "1",
    })};

  &:hover {
    background-color: ${(p) => p.theme.color.rowHoverColor};
    transition: 0.4s;
  }
  .status-background {
    position: absolute;
    top: 4px;
    bottom: 4px;
    left: 5px;
    right: 5px;
    border-radius: 4px;
    z-index: 0;
  }
  .completed-line-through {
    height: 1px;

    background-color: ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.theme.color.primaryFontColor,
        alpha: "0.2",
      })};
    position: absolute;
    top: calc(50% - 0.5px);
    left: 10px;
    right: 10px;
    z-index: 1;
  }
  .row-content {
    flex: 1;
    display: flex;
    align-items: center;
  }
`;

export const StyleBudgetTableDataCell = styled.div<{
  $widthRatio: `${number}%` | null;

  $horizontalAlign?: "flex-start" | "center" | "flex-end";
  $hasBorderRight: boolean;
  $completed?: boolean;
}>`
  ${(p) =>
    p.$widthRatio &&
    `width: calc(${p.$widthRatio} - 20px - ${p.$hasBorderRight ? 1 : 0}px);`}

  ${(p) => !p.$widthRatio && "flex:1;"}
  z-index:2;
  display: flex;
  height: calc(100%);
  justify-content: ${(p) =>
    p.$horizontalAlign ? p.$horizontalAlign : "center"};
  padding: 0px 10px;
  align-items: center;
  font-family: ${(p) => p.theme.font.primaryFont};
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.7px;
  color: ${(p) => p.theme.color.primaryFontColor};
  text-transform: capitalize;
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  border-right: ${(p) => (p.$hasBorderRight ? 1 : 0)}px solid
    ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.theme.color.primaryDividerColor,
        alpha: p.theme.isGridMode ? "1" : "0",
      })};
  .cell-text-content {
    color: ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.theme.color.primaryFontColor,
        alpha: p.$completed ? "0.5" : "1",
      })};
    font-family: ${(p) => p.theme.font.secondaryTitleFont};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;

    letter-spacing: -0.24px;
    flex-shrink: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    min-width: 35px;
  }
`;

export const StyleBudgetTableFinalCell = styled.div<{
  widthInPixel: `${number}px` | null;
  hoverStatus: boolean;
  horizontalAlign?: "flex-start" | "center" | "flex-end";
  fontFamily?: string;
  letterSpacing?: string;
  paddingLeft?: string;
  fontWeight?: string;
  fontColor?: string;
  themeColor: DoxleThemeColor;
  doxleFont: DoxleFont;
  currentTheme: string;
}>`
  ${(p) => (p.widthInPixel ? `width: ${p.widthInPixel};` : "flex: 1;")}
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  font-family: ${(p) =>
    p.fontFamily ? p.fontFamily : p.doxleFont.primaryFont};
  font-size: 14px;
  font-style: normal;
  font-weight: ${(p) => (p.fontWeight ? p.fontWeight : "500")};
  line-height: normal;
  letter-spacing: ${(p) => (p.letterSpacing ? p.letterSpacing : "0.7px")};
  color: ${(p) => (p.fontColor ? p.fontColor : p.themeColor.primaryFontColor)};
  text-transform: capitalize;

  padding-left: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const StyledBudgetRowPadding = styled.span<{
  $paddingLeft?: string;
  $paddingRight?: string;
}>`
  padding-left: ${(p) => (p.$paddingLeft ? p.$paddingLeft : "0px")};
  padding-right: ${(p) => (p.$paddingRight ? p.$paddingRight : "0px")};
`;

export const StyledBudgetTableSpan = styled.span<{
  $font: DoxleFont;
  $textColor: string;
  $bgColor: string;
}>`
  padding: 0.2rem 0.8rem;
  height: 1.8rem;
  font-family: ${(p) => p.$font.primaryFont};
  font-style: normal;
  font-weight: 400;
  font-size: 1.3rem;
  line-height: 1.7rem;
  letter-spacing: 0.05em;
  color: ${(p) => p.$textColor};
  background-color: ${(p) => p.$bgColor};
  border-radius: 1.2rem;
`;

export const StyledBudgetFinalSpan = styled.span<{
  font: DoxleFont;
  textColor: string;
  bgColor: string;
}>`
  display: flex;
  padding: 2px 8px;
  height: 18px;
  font-family: ${(p) => p.font.primaryFont};
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 17px;
  letter-spacing: 0.05em;
  color: ${(p) => p.textColor};
  background-color: ${(p) => p.bgColor};
  border-radius: 12px;
`;

export const StyledBudgetFooterContainer = styled(motion.div)`
  && {
    width: 100%;
    display: flex;
    min-height: 6em;
    max-height: 6em;
    z-index: 10;
    background-color: ${(p) => p.theme.color.primaryContainerColor};
  }
`;

export const StyleBudgetFooterRow = styled.div<{
  $widthInRem: `${number}rem` | null | `${number}%`;
  $horizontalAlign?: "flex-start" | "center" | "flex-end";
  $padding?: string;
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
  $textColor?: string;
}>`
  && {
    ${(p) =>
      p.$widthInRem ? `width: Calc(${p.$widthInRem} - 2rem);` : "flex:1;"}
    display: flex;
    height: 5.5em;
    justify-content: ${(p) =>
      p.$horizontalAlign ? p.$horizontalAlign : "center"};
    align-items: center;
    font-family: ${(p) => p.$doxleFont.subTitleFont};
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.75px;

    padding: 0 1rem;
    color: ${(p) => p?.$textColor ?? p.$themeColor.primaryFontColor};
    background-color: ${(p) => p.$themeColor.primaryContainerColor};
  }
`;

export const StyledBudgetHeaderButton = styled(Button)<{
  bgColor: string;
  fontColor: string;
}>`
  && {
    height: 26px;
    border-radius: 3px;
    background-color: ${(p) => (p.bgColor ? p.bgColor : "")};
    color: ${(p) => (p.fontColor ? p.fontColor : "")};
    padding: 0px 0px 0px 0px !important;
    margin: 0px !important;
    justify-content: center;
  }
`;

export const StyledBudgetRowButton = styled(Button)<{
  widthInPixel: string;
  heightInPixel: string;
}>`
  && {
    min-width: ${(p) => p.widthInPixel};
    max-width: ${(p) => p.widthInPixel};
    height: ${(p) => p.heightInPixel};
    background-color: transparent;
    padding: 0px !important;
    margin: 0px !important;
  }
`;

export const StyledBudgetViewsSpan = styled.span<{
  borderRight?: string;
}>`
  display: flex;
  align-items: center;
  ${(p) => (p.borderRight ? `border-right: ${p.borderRight}` : "")};
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 118.9%;
  letter-spacing: -0.28px;
  text-transform: capitalize;
`;

export const StyledBudgetTitleOptionsText = styled(Typography)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  && {
    color: ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.$themeColor.primaryFontColor,
        alpha: "0.6",
      })};
    background-color: transparent;
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 500;

    letter-spacing: -0.028rem;
    margin-right: 2rem;
    cursor: pointer;
  }
`;

export const StyledBudgetHeaderMenuTextField = styled(TextField)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  && {
    width: 25rem;
    height: 3rem;
    border-radius: 0.3rem;
    background-color: ${(p) => p.$themeColor.primaryTextFieldBackgroundColor};
    margin: 1rem 1rem 2rem 1rem;
  }
  input {
    padding: 1rem !important;
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.028rem;
    color: ${(p) => p.$themeColor.primaryFontColor};

    &::placeholder {
      font-family: ${(p) => p.$doxleFont.primaryFont};
      font-size: 1.4rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.028rem;
      color: ${(p) => p.$themeColor.primaryInputPlaceholderColor};
    }
  }
  & .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }
  & .MuiInputBase-root {
    height: 100%;
  }
`;

export const StyledBudgetHeaderMenuMenuItem = styled(MenuItem)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  && {
    border-radius: 3px;
    padding: 0.5rem 0.5rem 0.5rem 0.7rem !important;
    margin: 0.4rem 0px;
    width: 100%;
    color: ${(p) => p.$themeColor.primaryFontColor};
    font-family: ${(p) => p.$doxleFont.primaryFont};

    font-size: 1.4rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.028rem;

    .status-text {
      font-family: ${(p) => p.$doxleFont.primaryFont};

      font-size: 1.4rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.028rem;
    }
  }
`;

export const StyledBudgetFilterMenuLine = styled.div`
  width: 100%;
  border-bottom: 1px solid #f0f0f0;
`;

export const StyledBudgetProjectDropdown = styled(Select)<{
  themeColor: DoxleThemeColor;
  doxleFont: DoxleFont;
}>`
  & .MuiSelect-select {
    min-height: 40px;
    max-height: 40px;
    padding: 0px 30px 0px 0px !important;
    box-shadow: none;
    margin: 0px !important;
    font-family: ${(p) => p.doxleFont.primaryFont};
    font-size: 27px;
    font-style: normal;
    font-weight: 700;
    line-height: 118.9%;
    letter-spacing: -0.54px;
    color: ${(p) => p.themeColor.primaryFontColor};
    height: 50px !important;
    background-color: ${(p) => p.themeColor.primaryBackgroundColor};
    display: flex;
    align-items: center;
    border: none;
  }
  & .MuiFilledInput-input:focus {
    background-color: ${(p) => p.themeColor.primaryBackgroundColor};
  }
  & .MuiSelect-root.Mui-focused {
    color: #f7f7f7;
    background-color: #f7f7f7;
  }
`;

export const StyledBudgetProjectTextField = styled(TextField)<{
  themeColor: DoxleThemeColor;
  doxleFont: DoxleFont;
}>`
  && {
    background-color: ${(p) => p.themeColor.primaryTextFieldBackgroundColor};
    margin: 0px;
  }
  input {
    padding: 0px 12px !important;
    color: ${(p) => p.themeColor.primaryFontColor};
    // font-family: ${(p) => p.doxleFont.primaryFont};
    font-family: "IBM Plex Mono";
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 118.9%;
    letter-spacing: -0.54px;

    &::placeholder {
      color: ${(p) => p.themeColor.primaryFontColor};
      // font-family: ${(p) => p.doxleFont.primaryFont};
      font-family: "IBM Plex Mono";
      font-size: 13px;
      font-style: normal;
      font-weight: 700;
      line-height: 118.9%;
      letter-spacing: -0.54px;
    }
  }
  & .MuiInputBase-root {
    height: 100%;
  }
`;

export const StyledBudgetTableRow = styled(motion(TableRow))<{
  $themeColor: DoxleThemeColor;
}>`
  && {
    width: 100% !important;

    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
  }
`;
export const StyledBudgetWrapper = styled.div`
  height: calc(100% - 8px);
  margin-top: 8px;
  width: 100%;
  display: flex;
  justify-content: center;
  overflow: hidden;
  position: relative;
  flex-direction: column;
  background-color: ${(p) => p.theme.color.primaryContainerColor};
`;
export const StyledCheckboxFormControl = styled(FormControlLabel)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  && {
  }
  & .MuiFormControlLabel-label {
    color: ${(p) => p.$themeColor.primaryFontColor};
    font-family: ${(p) => p.$doxleFont.subTitleFont};
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 14.268px;
  }
`;

export const StyledProjectListGroup = styled.li<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  color: ${(p) => p.$themeColor.doxleColor};
  font-family: ${(p) => p.$doxleFont.primaryTitleFont};
  font-size: 14px;
  font-weight: 600;
  padding: 5px 0px 2px 0px;
`;
export const StyledProjectListOption = styled.li<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  color: ${(p) => p.$themeColor.primaryFontColor};
  font-family: ${(p) => p.$doxleFont.primaryFont};
  font-size: 13px;
  cursor: pointer;
  font-weight: 400;
  padding: 2px 0px;
  &:hover {
    background-color: ${(p) => p.$themeColor.primaryHoverColor} !important;
  }
`;

export const StyledBudgetSearchField = styled(TextField)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  && {
    min-width: 100px;
    width: 20%;
    max-width: 450px;
    height: 3rem;
    border-radius: 0.3rem;
    background-color: ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.$themeColor.doxleColor,
        alpha: "0.1",
      })};
  }
  input {
    padding: 1rem !important;
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.028rem;
    color: ${(p) => p.$themeColor.primaryFontColor};

    &::placeholder {
      font-family: ${(p) => p.$doxleFont.primaryFont};
      font-size: 1.4rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.028rem;
      color: ${(p) =>
        editRgbaAlpha({
          rgbaColor: p.$themeColor.primaryFontColor,
          alpha: "0.8",
        })};
    }
  }
  & .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }
  & .MuiInputBase-root {
    height: 100%;
  }
`;
export const StyledBudgetTableHead = styled(TableHead)`
  && {
    width: 100% !important;
  }
`;
export const StyledBudgetTableBody = styled(TableBody)`
  && {
    width: 100% !important;
    min-width: 40rem !important;
  }
`;
export const StyledBudgetPersonFieldButton = styled(motion(Button))<{}>`
  && {
    display: flex;
    flex-direction: row;
    min-width: 0px !important;
    cursor: pointer;
    margin-right: 0.4rem;
    padding: 2px !important;
    color: ${(p) => p.theme.color.primaryFontColor};
    .person-button-text {
      font-family: ${(p) => p.theme.font.primaryFont};
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.06rem;
      color: inherit;
    }

    &:hover {
      background-color: ${(p) => p.theme.color.hoverColor} !important;
    }
  }
`;

export const StyledBudgetRowPopperMenu = styled(motion(Paper))<{}>`
  && {
    width: 183px;
    min-height: 147px;
    border-radius: 0px 18px 12px 12px;
    padding: 12px;
    display: flex;
    flex-direction: column;
    background-color: ${(p) => p.theme.color.primaryContainerColor};
    margin-top: 24px;
    border: 1px solid ${(p) => p.theme.color.primaryDividerColor};
    .menu-title-text {
      color: ${(p) => p.theme.color.primaryFontColor};
      font-family: ${(p) => p.theme.font.primaryFont};
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-bottom: 20px;
      text-transform: capitalize;
      padding-left: 8px;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
`;

export const StyledBudgetPoperMenuItem = styled.div<{
  $hoverColor?: string;
}>`
  width: calc(100% - 16px);
  padding: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.4s ease;
  margin-bottom: 8px;
  &:hover {
    background-color: ${(p) =>
      p.$hoverColor ? p.$hoverColor : p.theme.color.hoverColor};
    color: ${(p) => p.theme.color.primaryFontColor};
  }

  .menu-item-text {
    margin-left: 4px;
    flex-shrink: 1;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    color: ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.theme.color.primaryFontColor,
        alpha: "0.7",
      })};
    font-family: ${(p) => p.theme.font.primaryFont};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;

    letter-spacing: -0.028rem;
  }
`;
export const StyledBudgetRenameTextField = styled(motion(TextField))<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  && {
    width: calc(100% - 16px);
    height: 3rem;
    margin-bottom: 0.5rem;
    border-radius: 0.3rem;
    padding: 0px 8px;
  }
  input {
    padding: 0px !important;
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.028rem;
    color: ${(p) => p.$themeColor.primaryFontColor};

    &::placeholder {
      font-family: ${(p) => p.$doxleFont.primaryFont};
      font-size: 1.2rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.028rem;
      color: ${(p) =>
        editRgbaAlpha({
          rgbaColor: p.$themeColor.primaryFontColor,
          alpha: "0.8",
        })};
    }
  }
  & .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }
  & .MuiInputBase-root {
    height: 100%;
  }
`;
export const StyledBudgetsProjectTabContainer = styled(motion.div)`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${(p) => p.theme.color.primaryBackgroundColor};
`;

export const StyledBudgetTable = styled(Table)`
  && {
    width: 100% !important;

    min-width: 40rem !important;
    display: flex !important;
    flex-direction: column;
  }
`;
export const StyledListScroller = styled.div`
  width: 100%;
  height: 100%;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;
export const StyledBudgetTableContainer = styled(
  (props: TableContainerProps) => (
    <TableContainer {...props} component={StyledListScroller} />
  )
)`
  &.MuiTableContainer-root {
    width: 100% !important;
    height: 100% !important;
    minwidth: "40rem !important";
  }
`;

export const StyledDocketNumberContainer = styled.div<{
  $isCompleted: boolean;
  $statusColor: string;
}>`
  padding: 0px 4px;
  position: relative;
  z-index: 1;
  .docket-number-text {
    color: ${(p) =>
      editRgbaAlpha({
        rgbaColor: "rgba(0,0,0,1)",
        alpha: p.$isCompleted ? "0.4" : "0.7",
      })};

    font-family: ${(p) => p.theme.font.plexSanCondensed};
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    z-index: 0;
    position: absolute;
    bottom: 0px;
    left: 0px;
    transform: translate(min(-10%, 10px), min(2%, 10px));
    // background-color: ${(p) => p.$statusColor};
    // border-radius: 50%;
  }
`;

export const StyledCompleteTickContainer = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  padding: 0px 4px;
  position: relative;
  left: -1.2rem;

  .docket-number-text {
    color: ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.$themeColor.primaryFontColor,
        alpha: "0.7",
      })};

    font-family: ${(p) => p.$doxleFont.plexSanCondensed};
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;

    letter-spacing: -0.064rem;
    position: absolute;
    bottom: -0.04rem;
    left: calc(8px + 1rem);
    z-index: 0;
    background-color: ${(p) => p.$themeColor.primaryBackgroundColor};
  }
`;

export const StyledBudgetStatus = styled(motion.div)<{
  $statusColor: string;
  $size?: number;
}>`
  width: ${(p) => p.$size ?? 10}px;
  height: ${(p) => p.$size ?? 10}px;
  border-radius: ${(p) => (p.$size ? p.$size / 3 : 10 / 3)}px;
  border: 2px solid ${(p) => p.$statusColor};
  // background-color: ${(p) => p.$statusColor};
  flex-shrink: 0;

  margin-right: 8px;
`;

export const StyledBottomFiller = styled.div<{
  $themeColor: DoxleThemeColor;
}>`
  background-color: ${(p) => p.$themeColor.primaryBackgroundColor};
  height: 8em;
  position: absolute;
  bottom: -20px;
  width: 100%;
  z-index: 2;
`;

export const StyledTickContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin: 0px 4px;
`;

export const StyledAssigneeDisplay = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  flex-shrink: 0;
  border: 0.7px solid ${(p) => p.$themeColor.primaryFontColor};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 8px;
  color: ${(p) => p.$themeColor.primaryFontColor};

  font-family: ${(p) => p.$doxleFont.robotoMonoFont};
  font-size: 9px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.27px;
  text-transform: uppercase;
`;

export const StyledStageHeaderWrapper = styled(motion.div)`
  width: 100% !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${(p) => p.theme.staticMenuColor.staticBg};
  height: 35px;
  border-bottom: 1px solid ${(p) => p.theme.color.primaryDividerColor};
  cursor: pointer;
  z-index: 100;
`;
export const StyledStageHeaderCell = styled.div<{
  $widthRatio: `${number}%` | null;
  $horizontalAlign?: "flex-start" | "center" | "flex-end";
  $padding?: string;
}>`
  ${(p) => (p.$widthRatio ? `width: ${p.$widthRatio};` : "flex:1;")}
  display: flex;
  height: 100%;
  padding: 0px !important;
  justify-content: ${(p) =>
    p.$horizontalAlign ? p.$horizontalAlign : "center"};
  align-items: center;
  cursor: pointer;
  color: ${(p) => p.theme.color.primaryFontColor};
  text-transform: capitalize;
  ${(p) => (p.$padding ? `padding: ${p.$padding}` : "")};
  border: none !important;
`;
export const StyledStageHeaderTitleText = styled(Typography)<{}>`
  && {
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    font-family: ${(p) => p.theme.font.primaryFont};
    color: ${(p) => p.theme.staticMenuColor.staticFontColor};
  }
`;
