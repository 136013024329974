import { useState } from "react";
import DoxleIconButton from "../DoxleButtons/DoxleIconButton";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import CloseFullscreenOutlinedIcon from "@mui/icons-material/CloseFullscreenOutlined";
import { ExpandSideScreenIcon } from "../../CoreContent/EditDocketSideScreen/EditDocketSideScreenIcons";
import { IconButtonProps } from "@mui/material/IconButton";
type Props = {
  expandToggleFunction: () => void;
  minimizeToggleFunction: () => void;

  fullScreenInitial?: boolean;
  expandBtnStyle?: IconButtonProps["sx"];
  maximizeIcon?: React.ReactNode;
  minimizeIcon?: React.ReactNode;
};

const ScreenSizeControl = ({
  expandToggleFunction,
  minimizeToggleFunction,
  fullScreenInitial,
  expandBtnStyle,
  maximizeIcon,
  minimizeIcon,
}: Props) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(
    fullScreenInitial ?? false
  );
  const doxleThemeColor = useDoxleThemeStore(
    (state) => state.doxleThemeColor,
    shallow
  );

  const handleClickBtn = () => {
    if (isExpanded) {
      minimizeToggleFunction();
      setIsExpanded(false);
    } else {
      expandToggleFunction();
      setIsExpanded(true);
    }
  };
  return (
    <DoxleIconButton
      sx={{
        position: "absolute",
        top: 5,
        left: 10,
        zIndex: 1000,
        ...expandBtnStyle,
      }}
      iconSource={
        !isExpanded
          ? // <OpenInFullOutlinedIcon
            //   style={{
            //     fontSize: "2rem",
            //     color: doxleThemeColor.primaryFontColor,
            //   }}
            // />
            maximizeIcon ?? (
              <ExpandSideScreenIcon doxleTheme={doxleThemeColor} height={19} />
            )
          : minimizeIcon ?? (
              <CloseFullscreenOutlinedIcon
                style={{
                  fontSize: "2rem",
                  color: doxleThemeColor.primaryFontColor,
                }}
              />
            )
      }
      buttonSize={30}
      onClick={handleClickBtn}
    />
  );
};

export default ScreenSizeControl;
