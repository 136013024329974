import React, { useCallback, useMemo } from "react";
import {
  StyledNoteListContainer,
  StyledNoteRowContainer,
} from "./StyledNoteList";
import useGetNoteList from "../../Hooks/useGetNoteList";
import DoxleListSkeleton from "../../../DoxleDesignPattern/DoxleSkeleton/DoxleListSkeleton";
import DoxleEmptyPlaceHolder from "../../../DoxleDesignPattern/DoxleEmptyPlaceHolder/DoxleEmptyPlaceHolder";
import { EmptyNoteBanner } from "../NoteIcons";
import {
  DoxleThemeColor,
  useDoxleThemeStore,
} from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import { Components, ItemContent, Virtuoso } from "react-virtuoso";
import { Note } from "../../Models/note";
import { StyledNoteListScroller } from "../../../DoxleDesignPattern/DoxleSkeleton/StyledComponentDoxleSkeleton";
import { Error404Banner } from "../../../DoxleDesignPattern/DoxleIcons/CommonIcons";
import NoteRow from "./NoteRow";
import { AnimatePresence, Variants } from "framer-motion";
import useNoteList from "./Hooks/useNoteList";
import NoteListFooter from "./NoteListFooter";
import DeleteNoteConfirm from "../DeleteNoteConfirm";
import { DoxleAddIcon } from "../../../DoxleDesignPattern/DoxleGeneralIcons";
import { FaPlus } from "react-icons/fa6";
import CircularProgress from "@mui/material/CircularProgress";
type Props = {};
interface ListContext {
  isErrorFetchingNoteList: boolean;
  isAddingNote: boolean;
  doxleThemeColor: DoxleThemeColor;

  handleAddNote: () => void;
}
const NoteList = (props: Props) => {
  const { doxleThemeColor, doxleFont, transparentMode } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
      transparentMode: state.transparentMode,
    }),
    shallow
  );
  const {
    noteList,
    isFetchingNoteList,
    isSuccessFetchingNoteList,
    isErrorFetchingNoteList,
    isRefetchingNoteList,
    isFetchingMoreNoteList,
    refetchNoteList,
    fetchMoreNoteList,
  } = useGetNoteList({});
  const { handleAddNote, isAddingNote } = useNoteList({});
  //*render list

  const listStyle: React.CSSProperties = { width: "100%", height: "100%" };

  const components: Components<Note, ListContext> = useMemo(
    () => ({
      Scroller: React.forwardRef((props, ref) => (
        <StyledNoteListScroller ref={ref} {...props} />
      )),
      Footer: React.forwardRef((props, ref) => <NoteListFooter />),

      EmptyPlaceholder: ({ context, ...rest }) => (
        <DoxleEmptyPlaceHolder
          headTitleText={
            context?.isErrorFetchingNoteList
              ? "Something wrong!"
              : "No note found"
          }
          subTitleText={
            context?.isErrorFetchingNoteList
              ? "Please try to refresh the page, we are sorry for this!"
              : "add more notes to manage your tasks"
          }
          containerBgColor="transparent"
          illustration={
            context?.isErrorFetchingNoteList ? (
              <Error404Banner
                themeColor={doxleThemeColor}
                containerStyle={{
                  marginBottom: 20,
                }}
              />
            ) : (
              <EmptyNoteBanner
                themeColor={doxleThemeColor}
                containerStyle={{
                  marginBottom: 20,
                  width: "40%"
                }}
              />
            )
          }
          addBtn={
            !context?.isErrorFetchingNoteList
              ? {
                  btnFunction: () => {
                    if (context?.handleAddNote) context.handleAddNote();
                  },
                  btnText: "Add",
                  btnColor: context?.doxleThemeColor.primaryFontColor,
                  btnTextColor: context?.doxleThemeColor.primaryContainerColor,
                  btnStyle: {
                    marginTop: "14px",
                    paddingInline: "14px !important",
                  },
                  btnWidthInPixel: "170px",
                  btnIcon: context?.isAddingNote ? (
                    <CircularProgress
                      size={20}
                      sx={{
                        color: context?.doxleThemeColor.primaryContainerColor,
                        marginRight: "7px",
                      }}
                    />
                  ) : (
                    <FaPlus
                      size={20}
                      color={context?.doxleThemeColor.primaryContainerColor}
                      style={{ marginRight: "7px" }}
                    />
                  ),
                }
              : undefined
          }
          enterContainerAnimation={false}
          exitContainerAnimation={false}
        />
      ),
    }),
    []
  );
  const itemContent: ItemContent<Note, ListContext> = useCallback(
    (index, data, context) => {
      return <NoteRow noteItem={data} />;
    },
    []
  );
  return (
    <StyledNoteListContainer
      initial={{
        opacity: 0,
        x: -10,
      }}
      animate={{
        opacity: 1,
        x: 0,
      }}
    >
      {isFetchingNoteList && (
        <DoxleListSkeleton
          containerStyle={{ width: "100%", height: "100%", display: "flex" }}
          skeletonType="noteRow"
        />
      )}

      {!isFetchingNoteList && (
        <Virtuoso
          style={listStyle}
          data={noteList}
          context={{
            isErrorFetchingNoteList,
            isAddingNote,
            doxleThemeColor,

            handleAddNote,
          }}
          components={components}
          itemContent={itemContent}
        />
      )}

      <DeleteNoteConfirm />
    </StyledNoteListContainer>
  );
};

export default NoteList;
