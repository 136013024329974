import React, { useState } from "react";
import { useAddProjectBookingContext } from "../AddBookingProject";
import { Docket } from "../../../../../Models/dockets";

type Props = {};

interface AddBookingDocketAndDate {
  handleSelectDateRange: (value: [Date | null, Date | null]) => void;
  handleOpenDocketList: (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => void;
  handleCloseDocketList: () => void;
  anchorDocketList: HTMLElement | null;

  handleSelectDocket: (docket: Docket) => void;
}
const useAddBookingDocketAndDate = (props: Props): AddBookingDocketAndDate => {
  const { setStartDate, setEndDate, setSelectedDocket } =
    useAddProjectBookingContext();
  const [anchorDocketList, setanchorDocketList] = useState<HTMLElement | null>(
    null
  );

  const handleSelectDateRange = (value: [Date | null, Date | null]) => {
    const [start, end] = value;
    setStartDate(start);
    setEndDate(end);
  };

  const handleOpenDocketList = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    setanchorDocketList(e.currentTarget);
  };
  const handleCloseDocketList = () => {
    console.log("CLICK CLOSE");
    setanchorDocketList(null);
  };

  const handleSelectDocket = (docket: Docket) => {
    setSelectedDocket(docket);
    handleCloseDocketList();
  };
  return {
    handleSelectDateRange,
    handleOpenDocketList,
    handleCloseDocketList,
    anchorDocketList,

    handleSelectDocket,
  };
};

export default useAddBookingDocketAndDate;
