import useAddProjectTextField from "./Hooks/useAddProjectTextField";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import {
  StyledAddProjectTextField,
  StyledAddProjectTextFieldContainer,
} from "./StyledComponentAddProjectScreen";
import { AnimatePresence, motion } from "framer-motion";
import { editRgbaAlpha } from "../../Utilities/FunctionUtilities";
import { Project } from "../../Models/project";
import { isValidPhoneNumber, validateEmail } from "./Hooks/useAddProjectScreen";

type Props = {
  retrievedProjectData: Project | undefined;
  wrapperWidthRatio: `${number}%`;
  labelText: string;
  placeholderText?: string;
  onTextChangeCb: (value: string) => void;
  isRequired?: boolean;
  numericField: boolean;
  isEmailField?: boolean;
  projectKey: keyof Project;
  shouldShowError?: boolean;
};

const AddProjectTextField = ({
  wrapperWidthRatio,
  labelText,
  onTextChangeCb,
  isRequired,
  placeholderText,
  numericField,
  isEmailField = false,
  retrievedProjectData,
  projectKey,
  shouldShowError,
}: Props) => {
  const { isTextFieldFocused, setisTextFieldFocused, setTextInput, textInput } =
    useAddProjectTextField({
      onTextChangeCb,
      retrievedProjectData,
      projectKey,
    });
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }),
    shallow
  );

  const labelAnimatedVariants = {
    initial: {
      color: editRgbaAlpha({
        rgbaColor: doxleThemeColor.primaryFontColor,
        alpha: "0.4",
      }),
    },
    focused: {
      color: doxleThemeColor.primaryFontColor,
      transition: {
        duration: 0.4,
      },
    },
  };
  return (
    <StyledAddProjectTextFieldContainer
      $widthRatio={wrapperWidthRatio}
      $doxleFont={doxleFont}
      $isFocused={isTextFieldFocused}
    >
      <motion.span
        className="textfield-label"
        variants={labelAnimatedVariants}
        initial="initial"
        animate={isTextFieldFocused ? "focused" : "initial"}
      >
        {labelText}
        {isRequired && ` (*)`}
      </motion.span>

      <StyledAddProjectTextField
        $themeColor={doxleThemeColor}
        $doxleFont={doxleFont}
        value={textInput}
        placeholder={placeholderText}
        onChange={(event) => setTextInput(event.target.value)}
        onFocus={() => setisTextFieldFocused(true)}
        onBlur={() => setisTextFieldFocused(false)}
      />

      <AnimatePresence>
        {shouldShowError && (
          <>
            {isRequired &&
              (!textInput ? (
                <motion.span
                  className="error-text"
                  initial={{
                    x: -5,
                    opacity: 0,
                  }}
                  animate={{
                    x: 0,
                    opacity: 1,
                  }}
                  transition={{
                    duration: 0.3,
                  }}
                  exit={{
                    x: -5,
                    opacity: 0,
                  }}
                >
                  This field is required, please fill up!
                </motion.span>
              ) : (
                <motion.span
                  className="error-text"
                  initial={{
                    x: -5,
                    opacity: 0,
                  }}
                  animate={{
                    x: 0,
                    opacity: 1,
                  }}
                  transition={{
                    duration: 0.3,
                  }}
                  exit={{
                    x: -5,
                    opacity: 0,
                  }}
                >
                  {Boolean(numericField && !isValidPhoneNumber(textInput)) &&
                    "Invalid phone number value..."}
                  {Boolean(isEmailField && !validateEmail(textInput)) &&
                    "Invalid Email, please enter correct email..."}
                </motion.span>
              ))}

            {Boolean(
              !isRequired &&
                textInput &&
                numericField &&
                !isValidPhoneNumber(textInput)
            ) && (
              <motion.span
                className="error-text"
                initial={{
                  x: -5,
                  opacity: 0,
                }}
                animate={{
                  x: 0,
                  opacity: 1,
                }}
                transition={{
                  duration: 0.3,
                }}
                exit={{
                  x: -5,
                  opacity: 0,
                }}
              >
                Input is not a number, please enter number value...
              </motion.span>
            )}
            {Boolean(
              !isRequired &&
                textInput &&
                isEmailField &&
                !validateEmail(textInput)
            ) && (
              <motion.span
                className="error-text"
                initial={{
                  x: -5,
                  opacity: 0,
                }}
                animate={{
                  x: 0,
                  opacity: 1,
                }}
                transition={{
                  duration: 0.3,
                }}
                exit={{
                  x: -5,
                  opacity: 0,
                }}
              >
                Invalid Email, please enter correct email...
              </motion.span>
            )}
          </>
        )}
      </AnimatePresence>
    </StyledAddProjectTextFieldContainer>
  );
};

export default AddProjectTextField;
