import React from "react";
import useGetOrderStatus from "../../Hooks/useGetOrderStatus";
import { useEditOrderStore } from "../../OrdersStore/useEditOrderStore";
import { shallow } from "zustand/shallow";
import {
  StyledEditOrderLabelField,
  StyledEditOrderWrapperField,
  StyledOrderStatusContainer,
  StyledOrderStatusItem,
  StyledOrderStatusPopupContainer,
} from "./StyledComponentEditOrder";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import useEditOrderStatus from "./Hooks/useEditOrderStatus";
import { CircularProgress, Popover } from "@mui/material";
import { VscChromeClose } from "react-icons/vsc";
type Props = {};

const EditOrderStatus = (props: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }),
    shallow
  );
  const { orderStatuses } = useGetOrderStatus({});

  const { editedOrder, readOnly } = useEditOrderStore(
    (state) => ({
      editedOrder: state.editedOrder,
      readOnly: state.readOnly,
    }),
    shallow
  );
  const {
    handleSelectOrderStatus,
    statusListAnchor,
    setStatusListAnchor,
    isUpdatingStatus,
  } = useEditOrderStatus({});

  const isOpenStatusListPopper = Boolean(statusListAnchor);
  const id = isOpenStatusListPopper ? "status-list-popper" : undefined;
  return (
    <StyledEditOrderWrapperField>
      <StyledEditOrderLabelField
        $themeColor={doxleThemeColor}
        $doxleFont={doxleFont}
      >
        Order Status
      </StyledEditOrderLabelField>

      <StyledOrderStatusContainer
        $themeColor={doxleThemeColor}
        $doxleFont={doxleFont}
        layout
        $statusColor={editedOrder?.statusColor ?? undefined}
        onClick={(e) => setStatusListAnchor(e.currentTarget)}
      >
        {editedOrder && editedOrder.statusName}

        {isUpdatingStatus && (
          <CircularProgress
            style={{
              color: doxleThemeColor.primaryFontColor,
              marginLeft: "0.8rem",
            }}
            size="1.2rem"
          />
        )}
      </StyledOrderStatusContainer>

      <Popover
        id={id}
        open={Boolean(isOpenStatusListPopper && !readOnly)}
        anchorEl={statusListAnchor}
        disableScrollLock
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={() => setStatusListAnchor(null)}
        sx={{
          "& .MuiPopover-paper": {
            backgroundColor: "transparent",
            overflow: "visible",
          },
        }}
        elevation={4}
      >
        <StyledOrderStatusPopupContainer
          $themeColor={doxleThemeColor}
          $doxleFont={doxleFont}
        >
          <div className="status-title-wrapper">
            Order Status
            <VscChromeClose
              size="1.6rem"
              style={{
                color: doxleThemeColor.primaryFontColor,
                cursor: "pointer",
              }}
              onClick={() => setStatusListAnchor(null)}
            />
          </div>

          <div className="status-item-wrapper">
            {orderStatuses.map((status, idx) => (
              <StyledOrderStatusItem
                key={status.statusId}
                $doxleFont={doxleFont}
                $statusColor={status.color}
                onClick={() => handleSelectOrderStatus(status)}
              >
                {status.statusName}
              </StyledOrderStatusItem>
            ))}
          </div>
        </StyledOrderStatusPopupContainer>
      </Popover>
    </StyledEditOrderWrapperField>
  );
};

export default EditOrderStatus;
