import { motion } from "framer-motion";
import styled from "styled-components";
import {
  DoxleFont,
  DoxleThemeColor,
} from "../../../../DoxleGeneralStore/useDoxleThemeStore";
import { editRgbaAlpha } from "../../../../Utilities/FunctionUtilities";

export const StyledQAListDetailContainer = styled(motion.div)`
  width: 100%;
  height: calc(100% - 3.4rem);
  padding: 1.4rem 0px 2rem 0px;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
`;
export const StyledQAItemListSkeleton = styled(motion.div)`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
export const StyledSkeletonBox = styled.div<{ $themeColor: DoxleThemeColor }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 2rem);
  padding: 1rem;
  margin-bottom: 1.4rem;

  height: 10rem;

  border-radius: 1.3rem;

  .history-skel-wrapper {
    flex: 1;
    margin-left: 2rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
`;

export const StyledQAItemRowWrapper = styled(motion.div)<{
  $themeColor: DoxleThemeColor;
}>`
  padding: 10px 0px;
  width: 100%;
  height: 10rem;
  display: flex;
  flex-direction: row;
  align-items: center;

  border-bottom: 1px solid ${(p) => p.$themeColor.primaryDividerColor};
  cursor: pointer;
`;

export const StyledQAItemContentWrapper = styled(motion.div)<{}>`
  flex: 1;
  height: 100%;
  margin-left: 14px;
  display: flex;
  flex-direction: column;
  .qa-title {
    font-family: ${(p) => p.theme.font.secondaryTitleFont};
    font-style: normal;
    font-weight: 500;
    font-size: 18px;

    color: ${(p) => p.theme.color.primaryFontColor};
    text-transform: none;
    width: 100%;
    max-height: 33%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .assignee-date-section {
    width: 100%;
    max-width: 40rem;
    margin-top: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .text-content {
      padding: 2px 8px;
      font-family: ${(p) => p.theme.font.secondaryTitleFont};

      font-style: normal;
      font-weight: 400;
      font-size: 16px;

      color: ${(p) => p.theme.color.primaryFontColor};

      max-width: 45%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      background-color: ${(p) =>
        editRgbaAlpha({
          rgbaColor: p.theme.color.doxleColor,
          alpha: "0.15",
        })};
    }
  }
  .comment-section {
    margin-top: 8px;
    font-family: ${(p) => p.theme.font.secondaryTitleFont};
    font-style: normal;
    font-weight: 400;
    font-size: 16px;

    color: ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.theme.color.primaryFontColor,
        alpha: "0.4",
      })};
    text-transform: none;
    width: 100%;
    max-width: 60rem;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const StyledQAItemImageWrapper = styled(motion.div)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  height: 100%;
  aspect-ratio: 1.2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 0.6rem;
  overflow: hidden;
  border: 1px solid
    ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.$themeColor.doxleColor,
        alpha: "0.4",
      })};
  .img-loader {
    position: absolute;
    z-index: 10;
    color: ${(p) => p.$themeColor.primaryFontColor};
  }
  .empty-placeholder-text {
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-style: normal;
    font-weight: 400;
    font-size: 1.4rem;

    color: ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.$themeColor.doxleColor,
        alpha: "0.4",
      })};
    text-transform: capitalize;
    margin-top: 4px;
  }

  .qa-image-holder {
    width: 100%;
    height: 100%;
  }
`;

export const StyledQAItemToggleMenuWrapper = styled.div<{
  $themeColor: DoxleThemeColor;
}>`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  margin-left: 8px;
  width: 15%;
  min-width: 8rem;
  overflow: hidden;

  .menu-button {
    display: flex;

    justify-content: center;
    align-items: center;

    .menu-icon {
      color: ${(p) => p.$themeColor.primaryFontColor};
    }
    .delete-icon {
      color: red;
    }
  }
`;
