import ProjectQueryAPI from "../../../Services/QueryHooks/projectQueryAPI";
import useDoxleNotificationStore from "../../../DoxleGeneralStore/useDoxleNotificationStore";
import { shallow } from "zustand/shallow";
import { useDoxleCurrentContextStore } from "../../../DoxleGeneralStore/useDoxleCurrentContext";
import { useCallback, useMemo, useState } from "react";
import useStatusColorPickerStore from "../Store/StatusColorPickerStore";
import { ProjectStatus } from "../../../Models/project";
import { TRgbaFormat } from "../../../Utilities/FunctionUtilities";
import { DocketStatus } from "../../../Models/dockets";
import { OrderStatus } from "../../../Orders/Models/order";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import useSetStatusQueryData from "./useSetStatusQueryData";

interface StatusMenu {
  status: ProjectStatus;
  anchorEl: SVGElement;
}
const useProjectStatuses = () => {
  const [statusMenu, setStatusMenu] = useState<StatusMenu | null>(null);
  const [deleteStatusDialog, setDeleteStatusDialog] =
    useState<ProjectStatus | null>(null);
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );
  const company = useDoxleCurrentContextStore(
    (state) => state.currentCompany,
    shallow
  );
  const retrieveProjectStatusQuery =
    ProjectQueryAPI.useRetrieveProjectStatusList({
      showNotification,
      company,
      filter: {},
    });
  const doxleColor = useDoxleThemeStore(
    (state) => state.doxleThemeColor.doxleColor,
    shallow
  );
  const openColorPicker = useStatusColorPickerStore(
    (state) => state.openPicker,
    shallow
  );
  const mutateProjectStatusQuery = ProjectQueryAPI.useMutateProjectStatus();
  const projectStatusList = useMemo(
    () =>
      retrieveProjectStatusQuery.isSuccess
        ? retrieveProjectStatusQuery.data.data
        : [],
    [retrieveProjectStatusQuery.data, company]
  );

  const handleStatusColorClick = (
    e: React.MouseEvent<HTMLDivElement>,
    status: ProjectStatus
  ) => {
    openColorPicker({
      anchorEl: e.currentTarget,
      handleSave: (newColor: TRgbaFormat) =>
        mutateProjectStatusQuery.updateStatus({
          statusId: status.statusId,
          updateStatus: { statusColor: newColor },
        }),
      color: status.statusColor ?? "rgba(0,0,0,1)",
    });
  };
  const handleKebabIconClick = (
    e: React.MouseEvent<SVGElement>,
    status: ProjectStatus
  ) => {
    setStatusMenu({
      anchorEl: e.currentTarget,
      status: status,
    });
  };
  const showDropDown = useMemo(() => Boolean(statusMenu), [statusMenu]);

  const handleDelete = (status: ProjectStatus) => {
    console.log(status.totalCount);
    if (!status.totalCount)
      mutateProjectStatusQuery.deleteStatus({
        statusId: status.statusId,
      });
    else setDeleteStatusDialog(status);
  };
  const handleDeleteConfirmed = (newStatusId: string | null) => {
    if (deleteStatusDialog)
      mutateProjectStatusQuery.deleteStatus({
        statusId: deleteStatusDialog.statusId,
        newId: newStatusId,
      });
    setDeleteStatusDialog(null);
  };
  interface Position {
    $top: number;
    $left: number;
  }
  const dropDownPosition: Position = useMemo(() => {
    const boundingRect = statusMenu?.anchorEl.getBoundingClientRect();
    if (!boundingRect) return { $top: 0, $left: 0 };
    return {
      $top: (boundingRect.top + boundingRect.bottom) / 2,
      $left: (boundingRect.left + boundingRect.right) / 2,
    };
  }, [statusMenu]);
  interface StatusDropDownOption {
    text: string;
    handleClick: () => void;
    disabled: boolean;
    selected?: boolean;
  }
  const projectStatusDropdownOptions: StatusDropDownOption[] = useMemo(() => {
    if (!statusMenu?.status) return [];
    return [
      {
        text: "Set Status to Default",
        disabled: statusMenu.status?.isDefault,
        handleClick: () => {
          mutateProjectStatusQuery.updateStatus({
            statusId: statusMenu?.status.statusId,
            updateStatus: { isDefault: true },
          });
          setStatusMenu(null);
        },
      },
      {
        text: "Allow Status on Noticeboard",
        disabled: false,
        selected: statusMenu?.status.isNoticeboardProjectStatus,
        handleClick: () => {
          mutateProjectStatusQuery.updateStatus({
            statusId: statusMenu?.status.statusId,
            updateStatus: {
              isNoticeboardProjectStatus:
                !statusMenu?.status.isNoticeboardProjectStatus,
            },
          });
          setStatusMenu(null);
        },
      },
      {
        text: "Allow Status on Budget",
        disabled: false,
        selected: statusMenu?.status.isBudgetProjectStatus,
        handleClick: () => {
          mutateProjectStatusQuery.updateStatus({
            statusId: statusMenu?.status.statusId,
            updateStatus: {
              isBudgetProjectStatus: !statusMenu?.status.isBudgetProjectStatus,
            },
          });
          setStatusMenu(null);
        },
      },
      {
        text: "Delete Status",
        disabled: statusMenu.status?.isDefault,
        handleClick: () => {
          handleDelete(statusMenu.status);
          setStatusMenu(null);
        },
      },
    ];
  }, [statusMenu?.status]);
  const handleCloseDropDown = () => {
    setStatusMenu(null);
  };

  const handleAddStatus = useCallback(
    (statusName: string) => {
      if (company)
        mutateProjectStatusQuery.addStatus({
          statusName: statusName,
          company: company.companyId,
          index: projectStatusList.length,
          statusColor: doxleColor,
          isDefault: projectStatusList.length === 0,
          isBudgetProjectStatus: window.location.host.includes("project"),
          isNoticeboardProjectStatus:
            window.location.host.includes("noticeboard"),
        });
    },
    [company, projectStatusList, doxleColor]
  );

  const handleUpdateStatusName = useCallback(
    (
      status: ProjectStatus | DocketStatus | OrderStatus,
      statusName: string
    ) => {
      mutateProjectStatusQuery.updateStatus({
        statusId: status.statusId,
        updateStatus: { statusName: statusName },
      });
    },
    [mutateProjectStatusQuery]
  );

  const { handleUpdateStatusOrder } = useSetStatusQueryData();

  const handleUpdateStatusIndex = (
    status: ProjectStatus,
    source: number,
    dest: number
  ) => {
    handleUpdateStatusOrder(source, dest, [
      "project-status-list",
      company?.companyId ?? "",
    ]);
    mutateProjectStatusQuery.updateStatusIndex({
      statusId: status.statusId,
      source: source,
      dest: dest,
    });
  };

  return {
    projectStatusList,
    mutateProjectStatusQuery,
    handleStatusColorClick,
    handleKebabIconClick,
    showDropDown,
    dropDownPosition,
    projectStatusDropdownOptions,
    handleCloseDropDown,
    handleUpdateStatusName,
    handleAddStatus,
    deleteStatusDialog,
    handleDeleteConfirmed,
    setDeleteStatusDialog,
    handleUpdateStatusIndex,
    retrieveProjectStatusQuery,
  };
};

export default useProjectStatuses;
