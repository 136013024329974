import { motion } from "framer-motion";
import styled from "styled-components";
import {
  DoxleFont,
  DoxleTheme,
  DoxleThemeColor,
} from "../../DoxleGeneralStore/useDoxleThemeStore";
import { editRgbaAlpha } from "../../Utilities/FunctionUtilities";

export const StyledDoxleFilterTagContainer = styled(motion.div)<{}>`
  position: relative;
  border-radius: 0.6rem;
  padding: 0px 0.8rem;
  align-self: flex-start;
  justify-content: center;
  height: 20px;
  display: flex;
  align-items: center;
  background-color: ${(p) =>
    editRgbaAlpha({
      rgbaColor: p.theme.color.doxleColor,
      alpha: p.theme.currentTheme !== "dark" ? "0.5" : "0.1",
    })};
  margin: 0px 2px;
  span {
    color: ${(p) => p.theme.color.primaryFontColor};
    font-size: max(1.2rem, 12px);
    font-family: ${(p) => p.theme.font.primaryFont};
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.06rem;
    text-transform: capitalize;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  border: 1px solid ${(p) => p.theme.color.primaryDividerColor};
`;
export const StyledEmailTag = styled.div`
  color: ${(p) =>
    editRgbaAlpha({ rgbaColor: p.theme.color.primaryFontColor, alpha: "0.6" })};
  position: absolute;
  top: 20px;
  left: 0;
`;
