import React from "react";
import { useDoxleThemeStore } from "../../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";

import {
  StyledBookingHeaderText,
  StyledBookingTableHeaderCell,
  StyledBookingTableHeaderContainer,
} from "./StyledComponentBookingDocketTable";
import { BOOKING_DOCKET_TABLE_FIELD } from "../../../store/useBookingDocketStore";

type Props = {};

const BookingDocketTableHeader = (props: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }),
    shallow
  );

  return (
    <StyledBookingTableHeaderContainer $themeColor={doxleThemeColor}>
      {BOOKING_DOCKET_TABLE_FIELD.map((header, idx) => {
        if (header === "Days" && window.innerWidth < 1300) return null;
        if (header === "Due" && window.innerWidth < 1100) return null;
        return(
          <StyledBookingTableHeaderCell
            $themeColor={doxleThemeColor}
            $widthRatio={
              header === "Title"
                ? null
                : header === "Due" || header === "Days"
                  ? "10%"
                  : header === "Share"
                    ? "5%"
                    : header === "Progress"
                      ? "15%"
                      : `12%`
            }
            $horizontalAlign={header === "Title" ? "flex-start" : "center"}
            key={`header#${idx}`}
          >
            <StyledBookingHeaderText
              $doxleFont={doxleFont}
              $themeColor={doxleThemeColor}
              sx={{
                paddingLeft: header === "Title" ? "5rem" : 0,
              }}
            >
              {header !== "Share" ? header : ""}
            </StyledBookingHeaderText>
          </StyledBookingTableHeaderCell>
      )
      })}
    </StyledBookingTableHeaderContainer>
  );
};

export default BookingDocketTableHeader;
