import {
  FilterRetrieveScopeItemQuery,
  formScopeOfWorkItemQKey,
} from "../QueryAPI/scopeOfWorkQueryAPI";
import { useDoxleCurrentContextStore } from "../../DoxleGeneralStore/useDoxleCurrentContext";
import { useQueryClient } from "@tanstack/react-query";
import { ScopeOfWorkItem } from "../Model/scopeOfWorks";
import { produce } from "immer";
import { AxiosResponse } from "axios";

type Props = {
  filter: FilterRetrieveScopeItemQuery;
  appendPos?: "start" | "end";
  overwrite?: boolean;
};
interface SetSOWItemQueryData {
  handleAddSOWItem: (newItem: ScopeOfWorkItem) => void;
  handleEditSOWItem: (newItem: ScopeOfWorkItem) => void;
  handleDeleteMultipleSOWItems: (deletedIds: string[]) => void;
  handleToggleCompleteAllSOW: (value: boolean) => void;
  handleUpdateSOWItemImgCount: (itemId: string, countUpdate: number) => void;
}
const useSetSOWItemQueryData = ({
  filter,
  appendPos = "end",
  overwrite = true,
}: Props): SetSOWItemQueryData => {
  const { company } = useDoxleCurrentContextStore((state) => ({
    company: state.currentCompany,
  }));
  const queryClient = useQueryClient();
  const qKey = formScopeOfWorkItemQKey(filter, company);

  const handleAddSOWItem = (newItem: ScopeOfWorkItem) => {
    const queryData = queryClient.getQueryData(qKey);
    if (queryData && overwrite) {
      queryClient.setQueryData<AxiosResponse<ScopeOfWorkItem[]>>(
        qKey,
        (old) => {
          if (old)
            return produce(old, (draft) => {
              if (appendPos === "start")
                (draft.data as ScopeOfWorkItem[]).unshift(newItem);
              else (draft.data as ScopeOfWorkItem[]).push(newItem);

              return draft;
            });
          else queryClient.invalidateQueries(qKey);
        }
      );
    } else queryClient.invalidateQueries(qKey);
  };

  const handleEditSOWItem = (newItem: ScopeOfWorkItem) => {
    const queryData = queryClient.getQueryData(qKey);

    if (queryData && overwrite) {
      queryClient.setQueryData<AxiosResponse<ScopeOfWorkItem[]>>(
        qKey,
        (old) => {
          if (old)
            return produce(old, (draft) => {
              const item = (draft.data as ScopeOfWorkItem[]).find(
                (item) => item.scopeItemId === newItem.scopeItemId
              );
              if (item) Object.assign(item, newItem);
              return draft;
            });
          else queryClient.invalidateQueries(qKey);
        }
      );
    } else queryClient.invalidateQueries(qKey);
  };
  const handleDeleteMultipleSOWItems = (deletedIds: string[]) => {
    const queryData = queryClient.getQueryData(qKey);
    if (queryData && overwrite) {
      queryClient.setQueryData<AxiosResponse<ScopeOfWorkItem[]>>(
        qKey,
        (old) => {
          if (old)
            return produce(old, (draft) => {
              draft.data = (draft.data as ScopeOfWorkItem[]).filter(
                (oriItem) =>
                  !deletedIds.some(
                    (deletedId) => deletedId === oriItem.scopeItemId
                  )
              );
              return draft;
            });
          else queryClient.invalidateQueries(qKey);
        }
      );
    } else queryClient.invalidateQueries(qKey);
  };

  const handleToggleCompleteAllSOW = (value: boolean) => {
    const queryData = queryClient.getQueryData(qKey);

    if (queryData && overwrite) {
      queryClient.setQueryData<AxiosResponse<ScopeOfWorkItem[]>>(
        qKey,
        (old) => {
          if (old)
            return produce(old, (draft) => {
              (draft.data as ScopeOfWorkItem[]).forEach((item) => {
                item.completed = value;
              });
              return draft;
            });
          else queryClient.invalidateQueries(qKey);
        }
      );
    } else queryClient.invalidateQueries(qKey);
  };

  const handleUpdateSOWItemImgCount = (itemId: string, countUpdate: number) => {
    const queryData = queryClient.getQueryData(qKey);

    if (queryData && overwrite) {
      queryClient.setQueryData<AxiosResponse<ScopeOfWorkItem[]>>(
        qKey,
        (old) => {
          if (old)
            return produce(old, (draft) => {
              const item = (draft.data as ScopeOfWorkItem[]).find(
                (item) => item.scopeItemId === itemId
              );
              if (item) item.imageCount += countUpdate;
              return draft;
            });
          else queryClient.invalidateQueries(qKey);
        }
      );
    } else queryClient.invalidateQueries(qKey);
  };

  return {
    handleAddSOWItem,
    handleEditSOWItem,
    handleDeleteMultipleSOWItems,
    handleToggleCompleteAllSOW,
    handleUpdateSOWItemImgCount,
  };
};

export default useSetSOWItemQueryData;
