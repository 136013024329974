import {
  Components,
  GroupContent,
  GroupItemContent,
  GroupedVirtuoso,
} from "react-virtuoso";
import BudgetTableHeader from "./BudgetTableHeader";
import { useBudgetStore } from "../Store/useBudgetStore";
import BudgetTableRow from "./BudgetTableRow";
import DoxleEmptyPlaceHolder from "../../DoxleDesignPattern/DoxleEmptyPlaceHolder/DoxleEmptyPlaceHolder";
import React, { useCallback, useMemo } from "react";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";

import {
  StyledBudgetWrapper,
  StyledListScroller,
} from "./BudgetStyleComponent";

import useBudgetTable from "./Hooks/useBudgetTable";
import BudgetRowPopperMenu from "./BudgetRowPopperMenu";
import ListLoadingMore from "../../Utilities/Lottie/ListLoadingMore";
import DoxleDialogHelper from "../../DoxleDesignPattern/DoxleDialogHelper/DoxleDialogHelper";
import BudgetFooter from "./BudgetFooter";
import { EmptyBudgetBanner } from "./BudgetIcon";
import { Error404Banner } from "../../DoxleDesignPattern/DoxleIcons/CommonIcons";
import { LightDocket } from "../../Models/dockets";
import { IBudgetGroupListContext } from "../Models/TableTypes";
import BudgetStageHeader from "./BudgetStageHeader";
import DoxleListSkeleton from "../../DoxleDesignPattern/DoxleSkeleton/DoxleListSkeleton";

interface BudgetTableContext {
  isErrorFetchingBudgetList: boolean;
  budgetLength: number;
}
const BudgetTable = () => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }),
    shallow
  );

  const {
    anchorBudgetMenuEl,
    handleOpenBudgetMenu,
    handleCloseBudgetMenu,
    currentFocusedDocket,
    isScrollingTable,
    setIsScrollingTable,
    handleDeleteBudget,
    handleCloseConfirmDeleteDialog,
    stageDocketGroup,
    isFetchingBudgetData,
    isSuccessFetchingBudgetData,
    isErrorFetchingBudgetData,
    handleFetchNextPageBudgetData,
    isFetchingNextPageBudgetData,
  } = useBudgetTable({});

  const { confirmDeleteBudget } = useBudgetStore((state) => ({
    confirmDeleteBudget: state.confirmDeleteBudget,
  }));
  const isDeleteDialogOpen = Boolean(confirmDeleteBudget !== undefined);

  //*render group list
  const groupListComponents: Components<LightDocket, IBudgetGroupListContext> =
    useMemo(
      () => ({
        Scroller: React.forwardRef((props, ref) => (
          <StyledListScroller ref={ref} {...props} />
        )),

        EmptyPlaceholder: ({ context, ...rest }) => (
          <DoxleEmptyPlaceHolder
            headTitleText={
              context?.isErrorFetchingBudgetData
                ? "Something Wrong"
                : "No Budgets"
            }
            subTitleText={
              context?.isErrorFetchingBudgetData
                ? "Failed to get data, please try to reload the page"
                : "Add Some Budgets To Work!"
            }
            containerStyle={{
              width: "100%",
              flexGrow: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
            containerBgColor="transparent"
            illustration={
              context?.isErrorFetchingBudgetData ? (
                <Error404Banner themeColor={doxleThemeColor} />
              ) : (
                <EmptyBudgetBanner themeColor={doxleThemeColor} />
              )
            }
          />
        ),

        Header: ({ context }) =>
          !context?.isEmpty ? <BudgetTableHeader /> : <></>,
      }),
      []
    );
  const itemContent: GroupItemContent<LightDocket, IBudgetGroupListContext> =
    useCallback(
      (index, groupIdx, data, context) => {
        const item = stageDocketGroup.budgetItems[index];
        if (item)
          return (
            <BudgetTableRow
              key={item.docketPk}
              docket={item}
              handleOpenBudgetMenu={handleOpenBudgetMenu}
            />
          );
      },
      [stageDocketGroup.budgetItems]
    );

  const groupBudgetItemContent: GroupContent<IBudgetGroupListContext> =
    useCallback(
      (groupIdx, context) => {
        const stageItem = stageDocketGroup.stageItems[groupIdx];
        return (
          <BudgetStageHeader
            stageItem={stageItem !== "unstaged" ? stageItem : undefined}
            key={stageItem !== "unstaged" ? stageItem.stageId : "unstaged"}
          />
        );
      },
      [stageDocketGroup.stageItems]
    );
  const tableStyle: React.CSSProperties = {
    width: "100%",
    height: "100%",
  };
  return (
    <StyledBudgetWrapper>
      {isFetchingBudgetData && (
        <DoxleListSkeleton
          skeletonType={"budgetRow"}
          containerStyle={{
            width: "100%",
            height: "100%",
            display: "flex",
            overflow: "hidden",
          }}
        />
      )}
      {!isFetchingBudgetData && (
        <>
          <GroupedVirtuoso
            style={tableStyle}
            context={{
              isErrorFetchingBudgetData,
              isEmpty: Boolean(
                stageDocketGroup.stageItems.length === 0 &&
                  stageDocketGroup.budgetItems.length === 0
              ),
            }}
            groupCounts={stageDocketGroup.budgetItemsCount}
            components={groupListComponents}
            itemContent={itemContent}
            groupContent={groupBudgetItemContent}
            endReached={() => {
              handleFetchNextPageBudgetData();
            }}
            scrollSeekConfiguration={{
              enter: (velocity) => {
                setIsScrollingTable(Math.abs(velocity) > 70);
                return false;
              },
              exit: (velocity) => {
                const shouldExit = Math.abs(velocity) < 10;
                if (shouldExit) {
                  setIsScrollingTable(false);
                }
                return shouldExit;
              },
            }}
            increaseViewportBy={300}
          />
        </>
      )}
      {/* <TableVirtuoso
        context={tableVirtuosoContext}
        data={budgetList}
        overscan={100}
        style={tableStyle}
        components={TableComponents}
        endReached={() => {
          if (hasNextPageBudgetList) {
            handleFetchNextPageBudgetList();
          }
        }}
        // fixedFooterContent={() => <BudgetFooter />}
        fixedHeaderContent={() => {
          return <BudgetTableHeader />;
        }}
        itemContent={itemContent}
        scrollSeekConfiguration={{
          enter: (velocity) => {
            setIsScrollingTable(Math.abs(velocity) > 70);
            return false;
          },
          exit: (velocity) => {
            const shouldExit = Math.abs(velocity) < 10;
            if (shouldExit) {
              setIsScrollingTable(false);
            }
            return shouldExit;
          },
          // change: (props, range) => setIsScrollingTable(true),
        }}
      /> */}

      <BudgetRowPopperMenu
        currentFocusedDocket={currentFocusedDocket}
        anchorBudgetMenuEl={anchorBudgetMenuEl}
        handleCloseBudgetMenu={handleCloseBudgetMenu}
      />

      {isFetchingNextPageBudgetData && (
        <ListLoadingMore
          containerStyle={{
            position: "absolute",
            bottom: 0,
            left: 0,
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 20,
          }}
          animationSize={120}
        />
      )}

      <DoxleDialogHelper
        open={isDeleteDialogOpen}
        onClose={handleCloseBudgetMenu}
        title="Confirm Delete Budget!"
        description={`All data belong to this budget ***${confirmDeleteBudget?.docketName}*** will be deleted permanently, are you sure to proceed?`}
        dialogButtons={[
          {
            buttonText: "Cancel",
            buttonFunction: handleCloseConfirmDeleteDialog,
            buttonColor: doxleThemeColor.primaryContainerColor,
            btnStyleSx: {
              border: `1px solid ${doxleThemeColor.primaryDividerColor}`,
            },
            buttonTextStyle: {
              color: doxleThemeColor.primaryFontColor,
            },
          },
          {
            buttonText: "Confirm",
            buttonFunction: handleDeleteBudget,
            btnStyleSx: {
              border: `1px solid ${doxleThemeColor.primaryDividerColor}`,
            },
          },
        ]}
      />
      <BudgetFooter isScrolling={isScrollingTable} />
    </StyledBudgetWrapper>
  );
};

export default BudgetTable;
