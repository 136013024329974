import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { StyledReplyListItem, StyledCommentUserName, StyledReplyListItemContainer, StyledCommentsDateLabel,StyleReplyMarkedofficial, StyleCommentTextBody, StyleCommentPDF } from "./styledCompnents"
import { shallow } from "zustand/shallow";
import { Mail, MailComments, ReplyMail } from "../../Models/mail";
import moment from "moment";
import { StyledMailListInfoPDF } from "../styledCompnents";
import {useMailStore} from "../../Store/mailStore";
import MailConversationAPI from "../../Hooks/mailConversationAPI";
import {useEffect, useMemo} from "react";
interface Props {
  conversation: ReplyMail
}
const MailReplyList = ({ conversation }: Props) => {
  const mailTheme = useMailStore(state => state.mailTheme, shallow)
  const styleProps = useDoxleThemeStore(
    (state) => ({
      $doxleFont: state.doxleFont,
      $themeColor: {...state.doxleThemeColor, ...mailTheme},
      $currentTheme: state.currentTheme,
    }),
    shallow
  );
  const className = conversation?.isOfficialResponse ? 'marked-official' : ''

  const updateReplyMail = MailConversationAPI.useUpdateReplyMail({
    mailId: conversation?.mail
  })
  const handleSetOfficialReply = () => {
    updateReplyMail.mutate({replyId: conversation.replyId, isOfficialResponse: !conversation.isOfficialResponse})
  }

  // const replyTextArray = useMemo(()=> (conversation?.textBody ?? "").split(/'\n'/), [conversation?.textBody])
  // useEffect(()=>{
  //   console.log('replyTextArray', replyTextArray)
  // },[])

  return <StyledReplyListItem {...styleProps}>
    {conversation?.isOfficialResponse && <StyleReplyMarkedofficial {...styleProps}><img src='/assets/icons/check-marked.svg' /> Marked as official response</StyleReplyMarkedofficial>}
    <StyledReplyListItemContainer className={className} {...styleProps}>
      <StyledCommentUserName className={className} {...styleProps}>Message from {conversation?.fromString} <StyledCommentsDateLabel {...styleProps} className={className}>{moment(conversation?.timeStamp).format('MMMM DD, YYYY - h:MM a')}</StyledCommentsDateLabel></StyledCommentUserName>
      <StyleCommentTextBody {...styleProps}>
        {conversation?.textBody}
        <img src='/assets/icons/check-marked.svg' onClick={handleSetOfficialReply} className="checkMark" style={{cursor: "pointer"}}/>
        </StyleCommentTextBody>
      {conversation?.attachments?.length ? <StyledMailListInfoPDF $themeColor={styleProps.$themeColor}>
        {conversation?.attachments?.map(item => <StyleCommentPDF {...styleProps}><img src='/assets/icons/adobe-pdf-icon.svg' width='36px' height='38px'/>{item?.fileName}</StyleCommentPDF>)}
      </StyledMailListInfoPDF> : null}
    </StyledReplyListItemContainer>
  </StyledReplyListItem>
}
export default MailReplyList
