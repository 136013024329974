import { useMemo } from "react";
import AccountMappingQueryAPI from "../QueryHooks/AccountsQueryAPI";
import { Account } from "../Models/account";

type Props = {};

interface IGetAccounts {
  accountsList: Account[];
  isFetchingAccountList: boolean;
  isSuccessFetchingAccountList: boolean;
  isErrorFetchingAccountList: boolean;
}
const useGetAccounts = (props: Props): IGetAccounts => {
  const accountsQuery = AccountMappingQueryAPI.useRetrieveAccounts();
  const accountsList: Account[] = useMemo(
    () => (accountsQuery.isSuccess ? accountsQuery.data?.data : []),
    [accountsQuery.data]
  );
  return {
    accountsList,
    isFetchingAccountList: accountsQuery.isLoading,
    isSuccessFetchingAccountList: accountsQuery.isSuccess,
    isErrorFetchingAccountList: accountsQuery.isError,
  };
};

export default useGetAccounts;
