import { useState } from "react";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import {
  StyledEditIconContainer,
  StyledEditOrderLabelField,
  StyledEditOrderWrapperField,
  StyledOrderSignoffContainer,
  StyledOrderSignoffPlaceholder,
} from "./StyledComponentEditOrder";
import { CircularProgress } from "@mui/material";
import { useEditOrderStore } from "../../OrdersStore/useEditOrderStore";
import { DoxleEditIcon } from "../../../DoxleDesignPattern/DoxleGeneralIcons";
import OrdersQueryAPI, {
  UpdateOrderSignOff,
} from "../../QueryHooks/OrdersQueryAPI";
import useDoxleNotificationStore from "../../../DoxleGeneralStore/useDoxleNotificationStore";
import { useOrdersStore } from "../../OrdersStore/useOrdersStore";
import { useDoxleCurrentContextStore } from "../../../DoxleGeneralStore/useDoxleCurrentContext";
import { BsEraser } from "react-icons/bs";
import { useIsMutating } from "@tanstack/react-query";
import Order from "../../Models/order";
import useSetOrderDetailQueryData from "../../Hooks/useSetOrderDetailQueryData";
import { useEditOrderContext } from "./EditOrder";
import { produce } from "immer";
import { useDoxleAuthStore } from "../../../DoxleGeneralStore/useDoxleAuthStore";

type Props = {};

const EditSignatureSignoff = (props: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }),
    shallow
  );
  const editOrderContext = useEditOrderContext();
  const { setUpdatedOrder } = editOrderContext
    ? editOrderContext : {setUpdatedOrder: (x: any)=>{}}
  const { editedOrder, setEditedOrder, readOnly, updateEditedOrder } =
    useEditOrderStore(
      (state) => ({
        editedOrder: state.editedOrder,
        setEditedOrder: state.setEditedOrder,
        readOnly: state.readOnly,
        updateEditedOrder: state.updateEditedOrder,
      }),
      shallow
    );
  const company = useDoxleCurrentContextStore(
    (state) => state.currentCompany,
    shallow
  );
  const { user } = useDoxleAuthStore();
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );
  const { queryFilter } = useOrdersStore(
    (state) => ({ queryFilter: state.queryFilter }),
    shallow
  );
  const isSignedOff = editedOrder?.signedOff !== null;

  const { handleUpdatePartialOrder } = useSetOrderDetailQueryData({
    orderId: editedOrder?.orderId ?? "",
  });

  const { updateOrderSignoff } = OrdersQueryAPI.useMutateOrderQuery({
    company,
    showNotification,
    filter: queryFilter,
    onUpdateManySuccessCb: (orders: Order[] | undefined) => {
      if (orders) {
        setEditedOrder(orders[0]);
        handleUpdatePartialOrder(orders[0]);
      }
    },
  });

  const isUpdatingSignOff =
    useIsMutating({
      mutationKey: ["update-many-orders"],
      predicate: (query) =>
        Boolean(
          (query.state.variables as UpdateOrderSignOff).orderIds.includes(
            editedOrder?.orderId ?? ""
          )
        ),
    }) > 0;

  const [displayEditIcon, setDisplayEditIcon] = useState<boolean>(false);
  const handleSignoff = () => {
    if (editedOrder) {
      // updateOrderSignoff.mutate({
      //   orderIds: [editedOrder.orderId],
      //   signed: !isSignedOff,
      // });

      setUpdatedOrder(
        produce((draft) => {
          if (draft) {
            draft.signedOff = isSignedOff ? null : user?.userId;
          } else
            draft = {
              signedOff: isSignedOff ? null : user?.userId,
            };
          return draft;
        })
      );
      updateEditedOrder({
        signedOff: isSignedOff ? null : user?.userId,
        signedOffName: isSignedOff
          ? ""
          : `${user ? user.firstName + " " + user.lastName : ""}`,
      });
    }
  };

  return (
    <StyledEditOrderWrapperField
      $width="35%"
      onMouseEnter={() => setDisplayEditIcon(true)}
      onMouseLeave={() => setDisplayEditIcon(false)}
    >
      <StyledEditOrderLabelField
        $themeColor={doxleThemeColor}
        $doxleFont={doxleFont}
      >
        Signoff
      </StyledEditOrderLabelField>

      <StyledOrderSignoffContainer
        $themeColor={doxleThemeColor}
        $doxleFont={doxleFont}
      >
        <StyledOrderSignoffPlaceholder
          $themeColor={doxleThemeColor}
          $doxleFont={doxleFont}
          $signed={isSignedOff}
        >
          {!isSignedOff ? "Signoff Here" : editedOrder?.signedOffName}
        </StyledOrderSignoffPlaceholder>
        {displayEditIcon && !isUpdatingSignOff && (
          <StyledEditIconContainer onClick={handleSignoff}>
            {!isSignedOff ? (
              <DoxleEditIcon themeColor={doxleThemeColor} />
            ) : (
              <BsEraser size={20} />
            )}
          </StyledEditIconContainer>
        )}
        {isUpdatingSignOff && (
          <StyledEditIconContainer>
            <CircularProgress size={20} />
          </StyledEditIconContainer>
        )}
      </StyledOrderSignoffContainer>
    </StyledEditOrderWrapperField>
  );
};

export default EditSignatureSignoff;
