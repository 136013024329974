import React, { useCallback, useEffect, useMemo, useState } from "react";
import { shallow } from "zustand/shallow";
import { useDoxleQuoteStore } from "../../../store/useDoxleQuoteStore";
import useGetQuoteRequestList from "../../../CustomQueryHooks/useGetQuoteRequestList";
import useGetQuoteResponseList from "../../../CustomQueryHooks/useGetQuoteResponseList";
import { useEditDocketSideScreenStore } from "../../../../CoreContent/EditDocketSideScreen/EditDocketSideScreenStore/useEditDocketSideScreenStore";

type Props = {};

interface QuoteRequestsTable {
  handleSelectAllQuoteRequests: () => void;

  handleSelectAllQuoteResponse: () => void;

  selectedAttachment: string | null;
  setSelectedAttachment: React.Dispatch<React.SetStateAction<string | null>>;
}
const useQuoteRequestsTable = (props: Props): QuoteRequestsTable => {
  const [selectedAttachment, setSelectedAttachment] = useState<string | null>(
    ""
  );

  const {
    quoteRequestIds,
    setQuoteRequestIds,
    quoteResponseIds,
    setQuoteResponseIds,
    setFilterGetQuoteRequest,
    setOnDeleteQuoteMode,
  } = useDoxleQuoteStore(
    (state) => ({
      quoteRequestIds: state.quoteRequestIds,
      setQuoteRequestIds: state.setQuoteRequestIds,
      quoteResponseIds: state.quoteResponseIds,
      setQuoteResponseIds: state.setQuoteResponseIds,
      setFilterGetQuoteRequest: state.setFilterGetQuoteRequest,
      setOnDeleteQuoteMode: state.setOnDeleteQuoteMode,
    }),
    shallow
  );
  useEffect(() => {
    setFilterGetQuoteRequest({ withAttachment: true });
  }, []);

  const { quoteRequestList } = useGetQuoteRequestList({});

  const { quoteResponseList } = useGetQuoteResponseList({});
  const handleSelectAllQuoteRequests = () => {
    const quoteReqIds =
      quoteRequestIds.length === quoteRequestList.length
        ? []
        : quoteRequestList.map((item) => item.requestId);
    setQuoteRequestIds(quoteReqIds);
  };

  const handleSelectAllQuoteResponse = () => {
    const quoteResIds =
      quoteResponseIds.length === quoteResponseList.length
        ? []
        : quoteResponseList.map((item) => item.responseId);
    setQuoteResponseIds(quoteResIds);
  };

  return {
    handleSelectAllQuoteRequests,
    handleSelectAllQuoteResponse,

    selectedAttachment,
    setSelectedAttachment,
  };
};

export default useQuoteRequestsTable;
