import React, { useEffect, useMemo } from "react";
import ProjectQueryAPI from "../../../Services/QueryHooks/projectQueryAPI";
import useDoxleNotificationStore from "../../../DoxleGeneralStore/useDoxleNotificationStore";
import { shallow } from "zustand/shallow";
import { useDoxleCurrentContextStore } from "../../../DoxleGeneralStore/useDoxleCurrentContext";
import { ProjectStatus } from "../../../Models/project";

type Props = {};

interface IGetProjectStatus {
  projectStatusList: ProjectStatus[];

  isFetchingProjectStatus: boolean;
  isErrorFetchingProjectStatus: boolean;
  isSuccessFetchingProjectStatus: boolean;
}
const useGetProjectStatus = (props: Props): IGetProjectStatus => {
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );
  const { company, setCurrentProjectStatusId } = useDoxleCurrentContextStore(
    (state) => ({
      company: state.currentCompany,
      setCurrentProjectStatusId: state.setCurrentProjectStatusId,
    }),
    shallow
  );
  const retrieveProjectStatusQuery =
    ProjectQueryAPI.useRetrieveProjectStatusList({
      showNotification,
      company,
      filter: {
        is_budget_project_status: true,
      }
    });
  const projectStatusList = useMemo(
    () =>
      retrieveProjectStatusQuery.isSuccess
        ? retrieveProjectStatusQuery.data.data
        : [],
    [retrieveProjectStatusQuery.data]
  );

  useEffect(() => {
    if (company && projectStatusList.length > 0)
      setCurrentProjectStatusId(projectStatusList[0].statusId);
  }, [projectStatusList, company]);

  return {
    projectStatusList,
    isFetchingProjectStatus: retrieveProjectStatusQuery.isLoading,
    isErrorFetchingProjectStatus: retrieveProjectStatusQuery.isError,
    isSuccessFetchingProjectStatus: retrieveProjectStatusQuery.isSuccess,
  };
};

export default useGetProjectStatus;
