import { Autocomplete, TextField } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import AutocompleteList from "../../../DoxleDesignPattern/DoxleAutoComplete/AutocompleteList";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { useDoxleAuthStore } from "../../../DoxleGeneralStore/useDoxleAuthStore";
import { shallow } from "zustand/shallow";
import { useDoxleCurrentContextStore } from "../../../DoxleGeneralStore/useDoxleCurrentContext";
import useDoxleNotificationStore from "../../../DoxleGeneralStore/useDoxleNotificationStore";
import ContactsAPI from "../../../Services/QueryHooks/contactsAPI";
import { ContactCompany } from "../../../Models/addressBook";
import { QuoteRequestAutocompleteSx } from "./AddStyledComponents";
import { ExpandMore } from "@mui/icons-material";
import {ContactCompanyFilters} from "../../../Services/QueryHooks/contactsFilters";

type Props = {
  suppliers: ContactCompany[];
  setSuppliers: React.Dispatch<React.SetStateAction<ContactCompany[]>>;
};

const QuoteSelectContractor = ({ suppliers, setSuppliers }: Props) => {
  const [searchInput, setSearchInput] = useState("");
  const [filterContractorList, setFilterContractorList] =
    useState<ContactCompanyFilters>({has_contact: 'true'});
  const { THEME_COLOR, DOXLE_FONT, currentTheme } = useDoxleThemeStore(
    (state) => ({
      THEME_COLOR: state.doxleThemeColor,
      DOXLE_FONT: state.doxleFont,
      currentTheme: state.currentTheme,
    })
  );
  
  const company = useDoxleCurrentContextStore(
    (state) => state.currentCompany,
    shallow
  );
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );
  const retrieveContactCompanyQuery =
    ContactsAPI.useRetrieveContactsCompanyQuery({
      filter: filterContractorList,

      enable: true,
      showNotification,
      company,
    
    });
  const contactList = useMemo(
    () =>
      retrieveContactCompanyQuery.isSuccess
        ? retrieveContactCompanyQuery.data.pages.flatMap(
            (page) => (page.data.results as ContactCompany[]) ?? []
          ) ?? []
        : [],
    [retrieveContactCompanyQuery.data]
  );

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (searchInput) setFilterContractorList({has_contact: 'true', search: searchInput });
      else setFilterContractorList({has_contact: 'true'});
    }, 200);
    return () => clearTimeout(timeout);
  }, [searchInput]);
  return (
    <Autocomplete
      fullWidth
      autoHighlight
      PaperComponent={AutocompleteList}
      noOptionsText={
        retrieveContactCompanyQuery.isLoading
          ? "Loading..."
          : retrieveContactCompanyQuery.isError
          ? "Error Fetching Suppliers"
          : "No Suppliers Found. Check that the Supplier has a Contact"
      }
      style={{
        background: THEME_COLOR.primaryContainerColor,
        borderRadius: "0px 0.9rem 0.9rem 1.2rem",
        color: THEME_COLOR.primaryFontColor,
      }}
      placeholder="Select Contractor"
      options={contactList as ContactCompany[]}
      sx={QuoteRequestAutocompleteSx(THEME_COLOR, DOXLE_FONT)}
      getOptionLabel={(option) => option.name}
      isOptionEqualToValue={(option, value) =>
        Boolean(
          suppliers.find(
            (supplier) => supplier.contactCompanyId === option.contactCompanyId
          )
        )
      }
      selectOnFocus
      disableCloseOnSelect
      renderOption={(props, option, state) => (
        <li
          {...props}
          key={option.contactCompanyId}
          style={{
            marginBottom: "0.4rem",
            fontSize: "1.4rem",
            color: state.selected
              ? THEME_COLOR.doxleColor
              : THEME_COLOR.primaryFontColor,
            background: "transparent",
          }}
        >
          {option.name}
        </li>
      )}
      clearOnBlur
      renderInput={(params) => (
        <TextField
          {...params}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                <ExpandMore
                  style={{
                    position: "absolute",
                    right: "10px",
                    width: "20px",
                    height: "20px",
                  }}
                  color="disabled"
                />
                {params.InputProps.startAdornment}
              </>
            ),
          }}
          onFocus={(e) => e.currentTarget.select()}
          value={searchInput}
          onChange={(e) => setSearchInput(e.target.value)}
          placeholder="Select Contractor"
          sx={{
            fontSize: "1.4rem",
            fontFamily: DOXLE_FONT.primaryFont,
            fontWeight: "500",
          }}
        />
      )}
      onChange={(event, value, reason, details) => {
        if (
          reason === "selectOption" &&
          value &&
          !suppliers.find(
            (opt) => opt.contactCompanyId === value?.contactCompanyId
          )
        )
          setSuppliers((prevState) => [...prevState, value]);
      }}
    />
  );
};

export default React.memo(QuoteSelectContractor);
