import { motion } from "framer-motion";
import styled from "styled-components";
import {
  DoxleFont,
  DoxleThemeColor,
} from "../../DoxleGeneralStore/useDoxleThemeStore";
import { editRgbaAlpha } from "../../Utilities/FunctionUtilities";

export const StyledDoxlePaginationControl = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 8px 0px;
`;
export const StyledDoxlePageSizeSection = styled(motion.div)<{}>`
  display: flex;
  align-items: center;
  padding: 4px 8px;
  border-radius: 4px;
  border: 1px solid ${(p) => p.theme.color.primaryDividerColor};
  .page-size-title {
    font-family: ${(p) => p.theme.font.primaryFont};
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    color: ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.theme.color.primaryFontColor,
        alpha: "0.7",
      })};
  }
  .page-size-summary {
    font-family: ${(p) => p.theme.font.primaryFont};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    color: ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.theme.color.primaryFontColor,
        alpha: "0.7",
      })};
  }

  .page-size-indicator {
    font-family: ${(p) => p.theme.font.primaryFont};
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    color: ${(p) => p.theme.color.doxleColor};
    margin: 0px 14px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: 0.2s all ease;
    padding: 2px;
    border-radius: 2px;
    &:hover {
      transition: 0.2s all ease;
      background-color: ${(p) =>
        editRgbaAlpha({
          rgbaColor: p.theme.color.doxleColor,
          alpha: "0.2",
        })};
    }
  }
`;
export const StyledDoxlePageNumberSection = styled(motion.div)<{}>`
  display: flex;
  align-items: center;
  padding: 4px 8px;
  border-radius: 4px;
  border: 1px solid ${(p) => p.theme.color.primaryDividerColor};
  color: ${(p) => p.theme.color.doxleColor};
  .page-num-indicator {
    font-family: ${(p) => p.theme.font.primaryFont};
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    margin: 0px 14px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: 0.2s all ease;
    padding: 2px;
    border-radius: 2px;
    &:hover {
      transition: 0.2s all ease;
      background-color: ${(p) =>
        editRgbaAlpha({
          rgbaColor: p.theme.color.doxleColor,
          alpha: "0.2",
        })};
    }
  }
  .icon {
    cursor: pointer;
    transition: 0.2s all ease;
    &:hover {
      transition: 0.2s all ease;
      transform: scale(1.1);
    }
  }
`;
export const StyledDoxlePopoverPaginationList = styled(motion.div)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  max-height: 300px;
  width: 80px;
  display: flex;
  flex-direction: column;
  overflow: auto;
  margin-bottom: 20px;
  border: 1px solid ${(p) => p.$themeColor.primaryDividerColor};
  background-color: ${(p) => p.$themeColor.primaryContainerColor};
  border-radius: 4px;

  .list-item {
    font-family: ${(p) => p.$doxleFont.primaryFont};
    color: ${(p) => p.$themeColor.primaryFontColor};
    font-size: 12px;
    width: calc(100%);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
    transition: 0.2s all ease;
    padding: 8px 0px;
    display: flex;
    justify-content: center;
    border-radius: 2px;
    &:hover {
      transition: 0.2s all ease;
      background-color: ${(p) =>
        editRgbaAlpha({
          rgbaColor: p.$themeColor.doxleColor,
          alpha: "0.2",
        })};
      color: white;
    }
  }
  .selected {
    color: ${(p) => p.$themeColor.doxleColor};
  }
`;
