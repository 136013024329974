import { produce } from "immer";
import { create } from "zustand";
import { startOfWeek, endOfWeek, eachDayOfInterval, addDays } from "date-fns";

import { immer } from "zustand/middleware/immer";
import { IBooking } from "../Models/booking";
import { IFilterBookingQueryProps } from "../QueryAPI/bookingQueryAPI";

export type TFilterBookingPeriod =
  | {
      start: Date;
      end: Date;
    }
  | "2weeks"
  | "4weeks";

type BookingDateArray = Date[];
type TUpdatedBookingField = keyof Pick<
  IBooking,
  "title" | "percentageCompleted" | "docket" | "order"
>;
type ProjectBookingCalendarStore = {
  currentWeek: BookingDateArray;
  goBackWeek: () => void;
  goForwardWeek: () => void;
  getWeekFromDate: (date: Date) => Date[];

  filterBookingProjectQuery: IFilterBookingQueryProps;
  setFilterBookingProjectQuery: (
    filter: Partial<IFilterBookingQueryProps>
  ) => void;

  editedBooking: IBooking | undefined;
  setEditedBooking: (booking: IBooking | undefined) => void;
  updateEditedBooking: <T extends TUpdatedBookingField>(
    updatedField: T,
    value: IBooking[T]
  ) => void;

  deletedBooking: IBooking | undefined;
  setDeletedBooking: (item: IBooking | undefined) => void;

  filterBookingPeriod: TFilterBookingPeriod | undefined;
  setFilterBookingPeriod: (filter: TFilterBookingPeriod | undefined) => void;
};

const getCurrentWeekDates = (): BookingDateArray => {
  const currentDate = new Date();
  const currentDay = currentDate.getDay();
  const firstDateOfWeek = new Date(currentDate);
  const adjustment = currentDay === 0 ? 6 : currentDay - 1; // if currentDay is 0 (Sunday), set adjustment to 6, else currentDay - 1
  firstDateOfWeek.setDate(currentDate.getDate() - adjustment);
  const dates = Array.from({ length: 7 }, (_, index) => {
    const date = new Date(firstDateOfWeek);
    date.setDate(date.getDate() + index);
    return date;
  });
  return dates;
};

const useBookingCalendarStore = create<ProjectBookingCalendarStore>()(
  immer((set, get) => ({
    currentWeek: getCurrentWeekDates(),
    goBackWeek: () => {
      set((state) => {
        const firstDateOfPrevWeek = new Date(get().currentWeek[0]);
        firstDateOfPrevWeek.setDate(firstDateOfPrevWeek.getDate() - 7);
        state.currentWeek = get().getWeekFromDate(firstDateOfPrevWeek);
      });
    },
    goForwardWeek: () => {
      set((state) => {
        const firstDateOfNextWeek = new Date(get().currentWeek[0]);
        firstDateOfNextWeek.setDate(firstDateOfNextWeek.getDate() + 7);
        state.currentWeek = get().getWeekFromDate(firstDateOfNextWeek);
      });
    },
    getWeekFromDate: (date: Date): BookingDateArray => {
      const start = startOfWeek(date, { weekStartsOn: 1 }); // week starts on Monday
      const end = endOfWeek(date, { weekStartsOn: 1 }); // week starts on Monday
      return eachDayOfInterval({ start, end });
    },

    filterBookingProjectQuery: { pagination: "none", orderBy: ['completed', 'start_date', 'title', 'created'] },
    setFilterBookingProjectQuery: (filter: Partial<IFilterBookingQueryProps>) =>
      set((state) => {
        Object.assign(state.filterBookingProjectQuery, filter);
      }),
    editedBooking: undefined,
    setEditedBooking: (booking: IBooking | undefined) =>
      set((state) => {
        state.editedBooking = booking;
      }),
    updateEditedBooking: (updatedField, value) =>
      set((state) => {
        if (state.editedBooking) {
          state.editedBooking[updatedField] = value;
        }
      }),

    deletedBooking: undefined,
    setDeletedBooking: (item: IBooking | undefined) =>
      set((state) => {
        state.deletedBooking = item;
      }),
    filterBookingPeriod: undefined as TFilterBookingPeriod | undefined,
    setFilterBookingPeriod: (filter: TFilterBookingPeriod | undefined) =>
      set((state) => {
        state.filterBookingPeriod = filter;
      }),
  }))
);

export default useBookingCalendarStore;

export const generatePeriodArray = (filter: TFilterBookingPeriod): Date[] => {
  let startDate = new Date();
  let endDate = new Date();

  if (typeof filter === "string") {
    const days = filter === "2weeks" ? 14 : 28;
    endDate.setDate(endDate.getDate() + days);
  } else {
    startDate = new Date(filter.start);
    endDate = new Date(filter.end);
  }

  const dates: Date[] = [];
  for (
    let date = startDate;
    date <= endDate;
    date.setDate(date.getDate() + 1)
  ) {
    dates.push(new Date(date));
  }

  return dates;
};
