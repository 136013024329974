import React, { useEffect, useState } from "react";
import { DoxleFolder } from "../../Models/files";
import { useFileStore } from "../Store/useFileStore";
import { useShallow } from "zustand/react/shallow";
import { useIsMutating } from "@tanstack/react-query";
import { getFolderMutateQKey } from "../QueryHooks/FileStorageQueryAPI";

type Props = {
  folderItem: DoxleFolder;
};

const useFoldersRowLeft = ({ folderItem }: Props) => {
  const [onFolderRowHover, setOnFolderRowHover] = useState<boolean>(false);

  const { currentFolder, setCurrentFolder, clearSelectedFiles } = useFileStore(
    useShallow((state) => ({
      currentFolder: state.currentFolder,
      clearSelectedFiles: state.clearSelectedFiles,
      setCurrentFolder: state.setCurrentFolder,
    }))
  );
  //! CHECKING THE CURRENT FOLDER WITH THE ALL FOLDERS TO SET THE BG COLOR
  useEffect(() => {
    if (currentFolder) {
      if (currentFolder?.folderId !== folderItem.folderId) {
        setOnFolderRowHover(false);
      }
    } else setOnFolderRowHover(false);
  }, [currentFolder, folderItem]);

  //* CURRENT FOLDER CLICKED
  const folderClicked = () => {
    //! NEED TO CLEAR PREVIOUS SELECTED FILES FROM ANOTHER FOLDER
    clearSelectedFiles();
    //! SETTING THE CURRENT FOLDER
    setCurrentFolder(folderItem);
    sessionStorage.setItem("currentFolderId", folderItem.folderId);

    //* NEED TO MAKE AN API CALL
  };
  const isDeletingFolder =
    useIsMutating({
      mutationKey: getFolderMutateQKey("delete"),
      predicate: (query) =>
        Boolean(
          (query.state.variables as string[]).includes(folderItem.folderId)
        ),
    }) > 0;
  return {
    folderClicked,
    onFolderRowHover,
    currentFolder,
    setOnFolderRowHover,
    isDeletingFolder,
  };
};

export default useFoldersRowLeft;
