import {  useEffect, useMemo, useState } from "react";
import { Contact } from "../../../Models/addressBook";
import { useEditDocketSideScreenStore } from "../../../CoreContent/EditDocketSideScreen/EditDocketSideScreenStore/useEditDocketSideScreenStore";
import { shallow } from "zustand/shallow";
import { useDoxleCurrentContextStore } from "../../../DoxleGeneralStore/useDoxleCurrentContext";
import useDoxleNotificationStore from "../../../DoxleGeneralStore/useDoxleNotificationStore";
import useGetContactCompanyDetail from "../../../CoreContent/QueryDataHooks/GetQueryDataHooks/useGetContactCompanyDetail";
import {IBooking} from "../../Models/booking";
import {BookingQueryAPI} from "../../QueryAPI/bookingQueryAPI";
import useBookingViewStore, {ISendBookingDialog} from "../../store/useBookingViewStore";
import {useDoxleAuthStore} from "../../../DoxleGeneralStore/useDoxleAuthStore";

interface ISMSBooking {
  booking: IBooking|undefined
  setBooking: (val: ISendBookingDialog|undefined) => void;
  handleClickAddBtn: () => void;
  message: string;
  setMessage: React.Dispatch<React.SetStateAction<string>>;
  ballInCourt: Contact|undefined;
  setBallInCourt: React.Dispatch<React.SetStateAction<Contact|undefined>>;
  isSendingSMS: boolean;
}
const useSMSBooking = (): ISMSBooking => {
  const [ballInCourt, setBallInCourt] = useState<Contact|undefined>(undefined);
  const [message, setMessage] = useState<string>("");

  const {sendBooking, setBooking} = useBookingViewStore(state=> ({
    sendBooking: state.sendBooking,
    setBooking: state.setSendBooking
  }), shallow)
  const booking: IBooking|undefined = sendBooking?.booking
  const edittedDocket = useEditDocketSideScreenStore(
    (state) => state.edittedDocket,
    shallow
  );
  const currentProject = useDoxleCurrentContextStore(
    (state) => state.currentProject,
    shallow
  );
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );
  const user = useDoxleAuthStore(
    (state) => state.user,
    shallow
  );


  const { contactCompanyDetails } = useGetContactCompanyDetail({
    contactCompanyId: booking?.assignedContractor ?? undefined
  });

  useEffect(() => {
    if (contactCompanyDetails && contactCompanyDetails.contactsJson && !ballInCourt) {
      const primaryContact = contactCompanyDetails.contactsJson.find(
        (contact) => contact.isPrimary
      );
      if (primaryContact) setBallInCourt(primaryContact);
      else {
        if (contactCompanyDetails.contactsJson.length > 0)
          setBallInCourt(contactCompanyDetails.contactsJson[0]);
      }
    }
  }, [contactCompanyDetails]);

  const resetInputEmail = () => {
    setMessage("");
    setBallInCourt(undefined);
  };

  const company = useDoxleCurrentContextStore(
    (state) => state.currentCompany,
    shallow
  );
  const {sms} = BookingQueryAPI.useMutateBookingQuery({
    filter: {},
    company,
    showNotification,
    onSuccessShareCb: () => setBooking(undefined),
  });

  const handleClickAddBtn = () => {
    if (booking && ballInCourt && message)
      sms.mutate({
        bookingId: booking.bookingId,
        recipient: ballInCourt.contactId,
        message: message
      });
    else showNotification("Please Fill In All Value", "error");
  };


  //! this useEffect is to prefill to contact when api call to get full detail of the edited order
  useEffect(() => {
    if (booking?.assignedContact) setBallInCourt({
      contactId: booking.assignedContact,
      userCompany: company!.companyId,
      user: '',
      firstName: booking.assignedContactName?.split(' ')?.[0] ?? "",
      lastName: booking.assignedContactName?.split(' ')?.[1] ?? "",
      email: booking?.assignedContactEmail ?? "",
      phone: booking?.assignedContactPhone ?? "",
      contactCompany: booking?.assignedContractor ?? null,
      isPrimary: true,
      sendQuotes: true,
    });
    let message: string = "You have been booked "
    if (booking?.title) message += 'for ' + booking.title
    if (booking?.startDate) message += ` on ${booking.startDate.substring(8,10)}.${booking.startDate.substring(5,7)}.${booking.startDate.substring(2,4)}`
    if (currentProject) message += ` @ ${currentProject.siteAddress}.`
    if (user?.phone) message += `Any issues: Contact ${user.firstName} on ${user.phone}`
    setMessage(message.substring(0, Math.min(125, message.length)));
  }, [booking]);

  //! reset all fields when close dialog
  useEffect(() => {
    if (!booking) resetInputEmail();
  }, [booking]);

  return {
    handleClickAddBtn,
    ballInCourt,
    setBallInCourt,
    message,
    setMessage,
    isSendingSMS: sms.isLoading,
    booking,
    setBooking,
  };
};

export default useSMSBooking;
