import { Contact } from "../../../Models/addressBook";
import { useState } from "react";
import { useContactsStore } from "../Store/useContactsStore";
import { shallow } from "zustand/shallow";
import { useEditContactsStore } from "../Store/useEditOrderStore";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { MouseEvent, ChangeEvent } from "react";
import {
  StyledContactTableCell,
  StyledContactTableRow,
} from "../StyledComponents";
import { CONTACTS_TABLE_HEADER_LIST } from "../../Models/contactsTable";
import DoxleCheckbox from "../../../DoxleDesignPattern/DoxleCheckbox/DoxleCheckbox";
import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton } from "@mui/material";
import ContactsAPI from "../../../Services/QueryHooks/contactsAPI";
import useDoxleNotificationStore from "../../../DoxleGeneralStore/useDoxleNotificationStore";
import { useDoxleCurrentContextStore } from "../../../DoxleGeneralStore/useDoxleCurrentContext";
import { useDoxleAuthStore } from "../../../DoxleGeneralStore/useDoxleAuthStore";
import { DeleteContactIcon } from "../ContactsIcons";

interface Props {
  contact: Contact;
}

const ContactTableRow = ({ contact }: Props) => {
  const { setEdittedContact, setAddDialogType, filter } = useContactsStore(
    (state) => ({
      setEdittedContact: state.setEdittedContact,
      setAddDialogType: state.setAddDialogType,
      filter: state.contactsQueryFilter,
    }),
    shallow
  );
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );
  const company = useDoxleCurrentContextStore(
    (state) => state.currentCompany,
    shallow
  );

  const { doxleThemeColor, doxleFont, currentTheme, transparentMode } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
      currentTheme: state.currentTheme,
      transparentMode: state.transparentMode,
    }),
    shallow
  );

  const handleContactRowClick = () => {
    setEdittedContact(contact);
    setAddDialogType("Contact");
  };

  const handleUpdateIsPrimary = (
    event: ChangeEvent<HTMLInputElement>,
    value: boolean
  ) => {};

  const handleUpdateSendQuotes = (
    event: ChangeEvent<HTMLInputElement>,
    value: boolean
  ) => {};

  const deleteContactQuery = ContactsAPI.useRemoveContactsQuery({
    filter,
    showNotification,
    company,
  });

  const handleDelteContact = (
    e: MouseEvent<HTMLButtonElement>,
    contactId: string
  ) => {
    e.preventDefault();
    e.stopPropagation();
    deleteContactQuery.mutate(contactId);
  };

  return (
    <StyledContactTableRow
      $themeColor={doxleThemeColor}
      $transparentMode={transparentMode}
      $currentTheme={currentTheme}
      onClick={handleContactRowClick}
      initial={{
        y: 10,
        opacity: 0,
      }}
      animate={{ y: 0, opacity: 1 }}
    >
      {CONTACTS_TABLE_HEADER_LIST.map((header, idx) => {
        if (header.isDisplayed) {
          if (header.headerName === "Company Name") {
            return (
              <StyledContactTableCell
                key={`docketCell#${contact.contactId}#${idx}`}
                $widthInRem={null}
                $horizontalAlign="flex-start"
                $doxleFont={doxleFont}
                $themeColor={doxleThemeColor}
              >
                <span> {contact.companyName}</span>
              </StyledContactTableCell>
            );
          }
          if (header.headerName === "Company ABN") {
            return (
              <StyledContactTableCell
                key={`docketCell#${contact.contactId}#${idx}`}
                $widthInRem={null}
                $horizontalAlign="center"
                $doxleFont={doxleFont}
                $themeColor={doxleThemeColor}
              >
                <span> {contact.companyAbn}</span>
              </StyledContactTableCell>
            );
          }
          if (header.headerName === "First Name") {
            return (
              <StyledContactTableCell
                key={`docketCell#${contact.contactId}#${idx}`}
                $widthInRem={null}
                $horizontalAlign="flex-start"
                $doxleFont={doxleFont}
                $themeColor={doxleThemeColor}
              >
                <span> {contact.firstName}</span>
              </StyledContactTableCell>
            );
          }
          if (header.headerName === "Last Name") {
            return (
              <StyledContactTableCell
                key={`docketCell#${contact.contactId}#${idx}`}
                $widthInRem={null}
                $horizontalAlign="flex-start"
                $doxleFont={doxleFont}
                $themeColor={doxleThemeColor}
              >
                <span>{contact.lastName}</span>
              </StyledContactTableCell>
            );
          }
          if (header.headerName === "Email") {
            return (
              <StyledContactTableCell
                key={`docketCell#${contact.contactId}#${idx}`}
                $widthInRem={null}
                $horizontalAlign="center"
                $doxleFont={doxleFont}
                $themeColor={doxleThemeColor}
              >
                <span> {contact.email}</span>
              </StyledContactTableCell>
            );
          }
          if (header.headerName === "Phone") {
            return (
              <StyledContactTableCell
                key={`docketCell#${contact.contactId}#${idx}`}
                $widthInRem={null}
                $horizontalAlign="center"
                $doxleFont={doxleFont}
                $themeColor={doxleThemeColor}
              >
                <span> {contact.phone}</span>
              </StyledContactTableCell>
            );
          }
          if (header.headerName === "Company Address") {
            return (
              <StyledContactTableCell
                key={`docketCell#${contact.contactId}#${idx}`}
                $widthInRem={null}
                $horizontalAlign="center"
                $doxleFont={doxleFont}
                $themeColor={doxleThemeColor}
              >
                <span> {contact.companyAddress}</span>
              </StyledContactTableCell>
            );
          }
          if (header.headerName === "Primary Contact") {
            return (
              <StyledContactTableCell
                key={`docketCell#${contact.contactId}#${idx}`}
                $widthInRem={"6rem"}
                $horizontalAlign="center"
                $doxleFont={doxleFont}
                $themeColor={doxleThemeColor}
              >
                <DoxleCheckbox
                  checked={contact.isPrimary}
                  onChange={handleUpdateIsPrimary}
                />
              </StyledContactTableCell>
            );
          }
          if (header.headerName === "Send Quotes") {
            return (
              <StyledContactTableCell
                key={`docketCell#${contact.contactId}#${idx}`}
                $widthInRem={"6rem"}
                $horizontalAlign="center"
                $doxleFont={doxleFont}
                $themeColor={doxleThemeColor}
              >
                <DoxleCheckbox
                  checkColor={doxleThemeColor.doxleColor}
                  defaultChecked={contact.sendQuotes}
                  onChange={handleUpdateSendQuotes}
                />
              </StyledContactTableCell>
            );
          }
        }
      })}
      <StyledContactTableCell
        key={`docketCell#${contact.contactId}#delete`}
        $widthInRem={"1rem"}
        $horizontalAlign="center"
        $doxleFont={doxleFont}
        $themeColor={doxleThemeColor}
      >
        <IconButton onClick={(e) => handleDelteContact(e, contact.contactId)}>
          <DeleteContactIcon themeColor={doxleThemeColor} />
        </IconButton>
      </StyledContactTableCell>
    </StyledContactTableRow>
  );
};

export default ContactTableRow;
