import React, { useContext } from "react";
import {
  PopupState as IPopupState,
  bindPopover,
} from "material-ui-popup-state/hooks";
import { PopoverProps } from "@mui/material";
import HoverPopover from "material-ui-popup-state/HoverPopover";

type Props = Omit<PopoverProps, keyof IPopupState> & {
  popupState: IPopupState; //!COMPULSORY TO USE THE popupState
};
interface IRootNavBarPopoverMenuContext {
  parentPopupState: null | IPopupState;
  rootPopupState: null | IPopupState;
}

const RootNavBarPopoverMenuContext =
  React.createContext<IRootNavBarPopoverMenuContext>({
    parentPopupState: null,
    rootPopupState: null,
  });
const DoxleNestedPopover = ({ popupState, ...props }: Props) => {
  const { rootPopupState } = React.useContext(RootNavBarPopoverMenuContext);
  const context: IRootNavBarPopoverMenuContext = React.useMemo(
    () => ({
      rootPopupState: rootPopupState || popupState,
      parentPopupState: popupState,
    }),
    [rootPopupState, popupState]
  );
  return (
    <RootNavBarPopoverMenuContext.Provider value={context}>
      <HoverPopover {...props} {...bindPopover(popupState)} />
    </RootNavBarPopoverMenuContext.Provider>
  );
};

export const useRootNavBarPopoverMenuContext = () =>
  useContext(RootNavBarPopoverMenuContext) as IRootNavBarPopoverMenuContext;
export default DoxleNestedPopover;
