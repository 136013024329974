import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { InventoryImage, InventoryItem } from "../Models/Inventory";
import {
  StyledAddInventoryFileContainer,
  StyledAddNewImagePlaceholder,
  StyledCommentTextArea,
  StyledDeleteBtn,
  StyledDetailTitle,
  StyledEditInventoryInput,
  StyledInventoryFieldContainer,
  StyledInventoryScreenPopover,
  StyledSubititleTextArea,
} from "./StyledEditInventory";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import InventoryQueryAPI from "../QueryAPIHooks/InventoryQueryAPI";
import { useCallback, useEffect, useMemo, useState } from "react";
import EditInventoryField from "./EditInventoryField";
import InventoryCommentsQueryAPI from "../QueryAPIHooks/InventoryCommentAPI";
import useDoxleNotificationStore from "../../DoxleGeneralStore/useDoxleNotificationStore";
import { useDoxleCurrentContextStore } from "../../DoxleGeneralStore/useDoxleCurrentContext";
import { shallow } from "zustand/shallow";
import AddInventoryComment from "./AddInventoryComment";
import { CircularProgress, Fade } from "@mui/material";
import { useInventoryStore } from "../Store/useInventoryStore";
import InventoryImageQueryAPI from "../QueryAPIHooks/InventoryImageAPI";
import {
  StyledAddButton,
  StyledButtonContainer,
  StyledImageUploadZone,
  StyledInventoryFileContainer,
  StyledUploadPrompt,
} from "../AddInventoryScreen/StyledAddInventory";
import AddedImageItems from "../AddInventoryScreen/AddedImageItems";
import InventoryImageItems from "./InventoryImageItems";
import { AttachmentUploadIcon } from "../../Mail/Svg/MailAttachmentUploadIcon";
import { FileRejection, useDropzone } from "react-dropzone";
import InventoryImageAPI from "../QueryAPIHooks/InventoryImageAPI";
import useSetInventoryQueryData from "../QueryAPIHooks/useSetInventoryQueryData";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  right: 0,
  transform: "translate(-0%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

interface Props {
  anchorEl: HTMLDivElement | null;
  setAnchorEl: React.Dispatch<React.SetStateAction<HTMLDivElement | null>>;
}

export default function EditInventoryScreen({ anchorEl, setAnchorEl }: Props) {
  const [images, setImages] = useState<File[]>([]);
  const [isAdding, setIsAdding] = useState<boolean>(false);

  const { doxleThemeColor, currentTheme, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
      currentTheme: state.currentTheme,
    })
  );

  const company = useDoxleCurrentContextStore(
    (state) => state.currentCompany,
    shallow
  );
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );

  const { handleAddInventoryImage } = useSetInventoryQueryData({});

  const { setCurrentEditInventory, currentEditInventory } = useInventoryStore(
    (state) => ({
      setCurrentEditInventory: state.setCurrentEditInventory,
      currentEditInventory: state.currentEditInventory,
    }),
    shallow
  );

  const handleClose = () => {
    setAnchorEl(null);
    // setCurrentEditInventory(undefined);
  };

  const handleClickDelete = () => {
    setAnchorEl(null);
    setInventoryForDelete(currentEditInventory);
  };

  const addImageQuery = InventoryImageAPI.useUploadInventoryImageQuery({
    company,
    showNotification,
    onSuccessAddCb(newImages) {
      setImages([]);
      setIsAdding(false);
      if (currentEditInventory && newImages) {
        handleAddInventoryImage(newImages, currentEditInventory?.inventoryId);
      }
    },
  });

  const { setImageIdForDelete, setInventoryForDelete } = useInventoryStore(
    (state) => ({
      inventoryForDelete: state.inventoryForDelete,
      setInventoryForDelete: state.setInventoryForDelete,
      setImageIdForDelete: state.setImageIdForDelete,
    }),
    shallow
  );

  const onDrop = useCallback(
    <T extends File>(acceptedFiles: T[], fileRejections: FileRejection[]) => {
      const file = acceptedFiles[0];
      if (file) {
        setImages((prevState) => [...prevState, ...acceptedFiles]);
      }
    },
    []
  );

  useEffect(() => {
    if (currentEditInventory) {
      if (images.length > 0) {
        setIsAdding(true);
        addImageQuery.mutate({
          imageFiles: images,
          inventoryId: currentEditInventory.inventoryId,
        });
      }
    }
  }, [images]);

  const singleInventoryQuery = InventoryQueryAPI.useGetSingleInventoryById(
    currentEditInventory?.inventoryId
  );
  const inventoryItem: InventoryItem | undefined = useMemo(() => {
    return singleInventoryQuery.isSuccess
      ? singleInventoryQuery.data.data
      : currentEditInventory;
  }, [
    singleInventoryQuery.data,
    singleInventoryQuery.isSuccess,
    currentEditInventory,
  ]);

  const inventoryImageQuery = InventoryImageQueryAPI.useGetInventoryImageById(
    inventoryItem?.inventoryId
  );

  const inventoryImg: InventoryImage[] = useMemo(() => {
    return inventoryImageQuery.data?.data.results ?? [];
  }, [inventoryImageQuery.data]);

  const deleteInventoryImageQuery =
    InventoryImageQueryAPI.useDeleteInventoryImageQuery({
      company,
      showNotification,
      onSuccessDeleteCb: () => {},
    });

  const deleteInventoryImage = (index: number) => {
    if (currentEditInventory) {
      setImageIdForDelete(inventoryImg[index].imageId);
      deleteInventoryImageQuery.mutate({
        inventoryId: currentEditInventory.inventoryId,
        imageIds: [inventoryImg[index].imageId],
      });
    }
  };

  const open = Boolean(anchorEl);
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "image/jpeg": [".jpeg", ".png"],
    },

    disabled: isAdding,
  });
  return (
    <StyledInventoryScreenPopover
      open={open}
      onClose={handleClose}
      $themeColor={doxleThemeColor}
      $currentTheme={currentTheme}
      anchorEl={anchorEl}
      BackdropProps={{
        style:
          currentTheme === "dark"
            ? { backgroundColor: "black", opacity: "0.4" }
            : { backgroundColor: "white", opacity: "0.4" },
      }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
    >
      {inventoryItem ? (
        <>
          <StyledInventoryFieldContainer>
            <EditInventoryField
              inventoryItem={inventoryItem}
              editField="title"
            />
            <EditInventoryField
              inventoryItem={inventoryItem}
              editField="subtitle"
            />
            <EditInventoryField
              inventoryItem={inventoryItem}
              editField="quantity"
            />
            <EditInventoryField
              inventoryItem={inventoryItem}
              editField="cost"
            />
            <StyledAddInventoryFileContainer>
              {inventoryImg.map((file, index) => (
                <InventoryImageItems
                  img={file}
                  index={index}
                  key={file.imageId}
                  handleDeleteFile={deleteInventoryImage}
                />
              ))}

              <StyledAddNewImagePlaceholder
                $currentTheme={currentTheme}
                $themeColor={doxleThemeColor}
                {...getRootProps()}
                style={{ cursor: "pointer" }}
              >
                {isAdding ? (
                  <>
                    <CircularProgress />
                    <StyledUploadPrompt
                      $themeColor={doxleThemeColor}
                      $doxleFont={doxleFont}
                      style={{ fontSize: "1.0rem" }}
                    >
                      Uploading ...
                    </StyledUploadPrompt>
                  </>
                ) : (
                  <>
                    <input {...getInputProps()} />
                    <AttachmentUploadIcon themeColor={doxleThemeColor} />
                    <StyledUploadPrompt
                      $themeColor={doxleThemeColor}
                      $doxleFont={doxleFont}
                      style={{ fontSize: "1.0rem" }}
                    >
                      Upload image
                    </StyledUploadPrompt>
                  </>
                )}
              </StyledAddNewImagePlaceholder>
            </StyledAddInventoryFileContainer>
          </StyledInventoryFieldContainer>
          <AddInventoryComment inventoryItem={inventoryItem} />
          <div style={{ textAlign: "center" }}>
            <StyledDeleteBtn
              onClick={handleClickDelete}
              $themeColor={doxleThemeColor}
              $doxleFont={doxleFont}
            >
              Delete
            </StyledDeleteBtn>
          </div>
        </>
      ) : (
        <></>
      )}
    </StyledInventoryScreenPopover>
  );
}
