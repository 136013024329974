import React, { useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { shallow } from "zustand/shallow";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import { DoxleFolder } from "../../Models/files";
import folderImage from "../Assets/folder.svg";
import folderOpenImage from "../Assets/folder-open.svg";
import { useFileStore } from "../Store/useFileStore";
import FileHoverMenu from "./FileHoverMenu";
import {
  StyledGridFolderItem,
  StyledGridFolderName,
} from "./StyledFilesBodyComponent";
import { StyledHeaderSubtitle } from "./StyledFilesHeaderComponent";

type Props = {
  gridFolderItem: DoxleFolder;
};

export function useGetParams() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

function GridFolderItem({ gridFolderItem }: Props) {
  const navigate = useNavigate();
  const [onFolderRowHover, setOnFolderRowHover] = useState<boolean>(false);
  const [folderCicked, setFolderClicked] = useState<boolean>(false);

  const { doxleThemeColor, doxleFont } = useDoxleThemeStore((state) => ({
    doxleThemeColor: state.doxleThemeColor,
    doxleFont: state.doxleFont,
  }));

  const {
    currentView,
    setCurrentView,
    currentFolder,
    setCurrentFolder,
    clearSelectedFiles,
  } = useFileStore(
    (state) => ({
      setCurrentFolder: state.setCurrentFolder,
      clearSelectedFiles: state.clearSelectedFiles,
      setCurrentView: state.setCurrentView,
      currentView: state.currentView,
      currentFolder: state.currentFolder,
      // setRootFolder: state.setRootFolder,
    }),
    shallow
  );
  const lastModifiedFormat = new Date(gridFolderItem.lastModified);
  const formattedLastModified = lastModifiedFormat.toLocaleString("en-EN", {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });

  const query = useGetParams();
  const view = query.get("view");
  // console.log("%c view", "background:blue;", view);

  //* CURRENT FOLDER CLICKED
  const folderClicked = () => {
    // setFolderClicked(true);
    // console.log(
    //   "Folder Clicked " +
    //     gridFolderItem.folderId +
    //     " " +
    //     gridFolderItem.folderName
    // );

    //! NEED TO CLEAR PREVIOUS SELECTED FILES FROM ANOTHER FOLDER
    clearSelectedFiles();

    sessionStorage.setItem("currentFolderId", gridFolderItem.folderId);

    //! SETTING THE CURRENT FOLDER
    setCurrentFolder(gridFolderItem);

    // //! SETTING THE CURRENT VIEW
    // setCurrentView("GridView");

    // const folderUrl =
    //   window.location.host + "/Files/" + gridFolderItem.folderId;
    // window.open(folderUrl, "_self");

    //* NEED TO MAKE AN API CALL

    navigate("/Files/" + gridFolderItem.folderId + "/");
  };

  //* ITS ROOT FOLDER

  return (
    <StyledGridFolderItem
      $themeColor={doxleThemeColor}
      onMouseEnter={(event: React.MouseEvent<HTMLDivElement>) => {
        if (!onFolderRowHover) setOnFolderRowHover(true);
      }}
      onMouseLeave={(event: React.MouseEvent<HTMLDivElement>) => {
        setOnFolderRowHover(false);
      }}
    >
      {!onFolderRowHover ? (
        <img
          style={{ width: "50%" }}
          src={folderImage}
          onClick={folderClicked}
        />
      ) : (
        <img
          style={{ width: "50%" }}
          src={folderOpenImage}
          onClick={folderClicked}
        />
      )}
      <StyledGridFolderName
        $themeColor={doxleThemeColor}
        $doxleFont={doxleFont}
      >
        {gridFolderItem.folderName}
      </StyledGridFolderName>

      <StyledHeaderSubtitle>{formattedLastModified}</StyledHeaderSubtitle>
      {onFolderRowHover && (
        <div style={{ marginTop: "10px" }}>
          <FileHoverMenu folderItem={gridFolderItem} />
        </div>
      )}
    </StyledGridFolderItem>
  );
}

export default GridFolderItem;
