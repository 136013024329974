import React, { useState } from "react";
import { InventoryItem } from "../Models/Inventory";
import {
  StyledCommentTextArea,
  StyledDetailTitle,
  StyledEditInventoryInput,
  StyledSubititleTextArea,
} from "./StyledEditInventory";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import InventoryQueryAPI from "../QueryAPIHooks/InventoryQueryAPI";
import useDoxleNotificationStore from "../../DoxleGeneralStore/useDoxleNotificationStore";
import { shallow } from "zustand/shallow";
import { useDoxleCurrentContextStore } from "../../DoxleGeneralStore/useDoxleCurrentContext";
import InventoryCommentsQueryAPI from "../QueryAPIHooks/InventoryCommentAPI";

type Props = {
  inventoryItem: InventoryItem;
};

const AddInventoryComment = ({ inventoryItem }: Props) => {
  const [comment, setComment] = useState<string>("");

  const { doxleThemeColor, doxleFont, currentTheme } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
      currentTheme: state.currentTheme,
    })
  );
  const company = useDoxleCurrentContextStore(
    (state) => state.currentCompany,
    shallow
  );
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );

  const addInventoryCommentQuery =
    InventoryCommentsQueryAPI.useAddInventoryCommentQuery({
      company,
      showNotification,
      inventoryId: inventoryItem.inventoryId,
    });

  const handleAddComment = () => {
    if (comment.length > 0) {
      addInventoryCommentQuery.mutate({ commentText: comment });
      setComment("");
    }
  };

  return (
    <>
      <StyledDetailTitle $themeColor={doxleThemeColor} style={{ marginTop: 8 }}>
        Comment
      </StyledDetailTitle>
      <StyledCommentTextArea
        placeholder="Type new comment here ..."
        $currentTheme={currentTheme}
        $themeColor={doxleThemeColor}
        $doxleFont={doxleFont}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
            handleAddComment();
          }
        }}
        value={comment}
        onChange={(e) => {
          setComment(e.target.value);
        }}
      />
    </>
  );
};

export default AddInventoryComment;
