import { useCallback, useEffect, useMemo, useState } from "react";
import { Contact } from "../../../../Models/addressBook";
import { DropEvent, FileRejection } from "react-dropzone";
import { produce } from "immer";
import { useEditDocketSideScreenStore } from "../../../../CoreContent/EditDocketSideScreen/EditDocketSideScreenStore/useEditDocketSideScreenStore";
import { shallow } from "zustand/shallow";
import { useDoxleCurrentContextStore } from "../../../../DoxleGeneralStore/useDoxleCurrentContext";
import { useEditOrderStore } from "../../../OrdersStore/useEditOrderStore";
import { NewMail } from "../../../../Mail/Models/mail";
import useDoxleNotificationStore from "../../../../DoxleGeneralStore/useDoxleNotificationStore";
import { TISODate } from "../../../../Models/dateFormat";
import { useOrdersStore } from "../../../OrdersStore/useOrdersStore";
import OrdersQueryAPI from "../../../QueryHooks/OrdersQueryAPI";
import useGetContactCompanyDetail from "../../../../CoreContent/QueryDataHooks/GetQueryDataHooks/useGetContactCompanyDetail";

interface Props {}
interface ISelectedInputText {
  start: number;
  end: number;
}

interface IEmailOrder {
  attachments: File[];
  setAttachments: React.Dispatch<React.SetStateAction<File[]>>;
  watchers: Contact[];
  handleUpdateNewMail: (props: {
    key: keyof NewMail;
    value: TISODate | string;
  }) => void;
  handleAddWatcher: (value: Contact) => void;
  handleRemoveWatcher: (value: Contact) => void;
  handleClickAddBtn: () => void;
  newMail: NewMail;
  ballInCourt: Contact[];
  setBallInCourt: React.Dispatch<React.SetStateAction<Contact[]>>;
  boldMode: boolean;
  setBoldMode: React.Dispatch<React.SetStateAction<boolean>>;
  italicMode: boolean;
  setItalicMode: React.Dispatch<React.SetStateAction<boolean>>;
  onDrop: <T extends File>(
    acceptedFiles: T[],
    fileRejections: FileRejection[],
    event: DropEvent
  ) => void;
  isAddingEmail: boolean;
  selectedText: ISelectedInputText;
  setSelectedText: React.Dispatch<React.SetStateAction<ISelectedInputText>>;
  handleAddBallInCourt: (value: Contact) => void;
  handleRemoveBallInCourt: (value: Contact) => void;
}
const useEmailOrder = ({}: Props): IEmailOrder => {
  const [attachments, setAttachments] = useState<File[]>([]);
  const [watchers, setWatchers] = useState<Contact[]>([]);
  const [ballInCourt, setBallInCourt] = useState<Contact[]>([]);
  const [boldMode, setBoldMode] = useState(false);
  const [italicMode, setItalicMode] = useState(false);
  const [selectedText, setSelectedText] = useState<ISelectedInputText>({
    start: 0,
    end: 0,
  });
  const edittedDocket = useEditDocketSideScreenStore(
    (state) => state.edittedDocket,
    shallow
  );
  const currentProject = useDoxleCurrentContextStore(
    (state) => state.currentProject,
    shallow
  );
  const { editedOrder, setShowEmailOrderDialog, showEmailOrderDialog } =
    useEditOrderStore(
      (state) => ({
        editedOrder: state.editedOrder,
        setShowEmailOrderDialog: state.setShowEmailOrderDialog,
        showEmailOrderDialog: state.showEmailOrderDialog,
      }),
      shallow
    );
  const initialMail: NewMail = useMemo(
    () => ({
      dueDate: null,
      textBody: "",
      subject: "",
      project: currentProject
        ? currentProject.projectId
        : edittedDocket
        ? edittedDocket.project
        : null,
      docket: edittedDocket ? edittedDocket?.docketPk : null,
      ballInCourt: "",
      watchers: [],
      attachments: [],
      order: editedOrder?.orderId ?? null,
    }),
    [currentProject, edittedDocket, editedOrder]
  );
  const [newMail, setnewMail] = useState<NewMail>({ ...initialMail });
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );

  const { contactCompanyDetails } = useGetContactCompanyDetail({
    contactCompanyId: editedOrder?.contactCompany ?? undefined,
  });

  useEffect(() => {
    if (contactCompanyDetails && contactCompanyDetails.contactsJson) {
      const primaryContact = contactCompanyDetails.contactsJson.find(
        (contact) => contact.isPrimary
      );

      if (primaryContact) setBallInCourt([primaryContact]);
      else {
        if (contactCompanyDetails.contactsJson.length > 0)
          setBallInCourt([contactCompanyDetails.contactsJson[0]]);
        else setBallInCourt([]);
      }
    } else setBallInCourt([]);
  }, [contactCompanyDetails]);

  const resetInputEmail = () => {
    setnewMail(initialMail);
    setAttachments([]);
    setWatchers([]);
    setBallInCourt([]);
    setBoldMode(false);
    setItalicMode(false);
  };

  const handleUpdateNewMail = (props: {
    key: keyof NewMail;
    value: TISODate | string;
  }) => {
    const { key, value } = props;
    setnewMail((prev) =>
      produce(prev, (draft) => {
        if (key === "subject") draft.subject = value;
        else if (key === "textBody") draft.textBody = value;
        else if (key === "ballInCourt") draft.ballInCourt = value;
        return draft;
      })
    );
  };

  const handleAddWatcher = (value: Contact) => {
    setWatchers((prevState) =>
      produce(prevState, (draft) => {
        if (!prevState.map((c) => c.contactId).includes(value.contactId)) {
          draft.push(value);
        }
        return draft;
      })
    );
  };

  const handleRemoveWatcher = (value: Contact) => {
    setWatchers((prevState) =>
      produce(prevState, (draft) => {
        draft = draft.filter((c) => c.contactId !== value.contactId);
        return draft;
      })
    );
  };

  const handleAddBallInCourt = (value: Contact) => {
    setBallInCourt((prevState) =>
      produce(prevState, (draft) => {
        if (!prevState.find((c) => c.contactId === value.contactId)) {
          draft.push(value);
        }
        return draft;
      })
    );
  };

  const handleRemoveBallInCourt = (value: Contact) => {
    setBallInCourt((prevState) =>
      produce(prevState, (draft) => {
        draft = draft.filter((c) => c.contactId !== value.contactId);
        return draft;
      })
    );
  };
  const company = useDoxleCurrentContextStore(
    (state) => state.currentCompany,
    shallow
  );
  const orderFilter = useOrdersStore((state) => state.queryFilter, shallow);
  const mutateOrderQuery = OrdersQueryAPI.useMutateOrderQuery({
    filter: orderFilter,

    company,
    showNotification,
    onSuccessShareOrder: () => setShowEmailOrderDialog(false),
    // onAddSuccessCb: handleAddNewOrderSuccess
  });

  const handleClickAddBtn = () => {
    if (!editedOrder) {
      showNotification("Unable to send, unrecognized order!", "error");
      return;
    }
    if (ballInCourt.length > 0 && newMail.subject && newMail.textBody)
      mutateOrderQuery.shareOrder.mutate({
        orderId: editedOrder.orderId,
        toRecipients: ballInCourt.map((contact) => contact.contactId),
        attachments: attachments,
        ccRecipients: watchers.map((contact) => contact.contactId),
        subject: newMail.subject,
        textBody: newMail.textBody,
      });
    else showNotification("Please Fill In All Value", "error");
  };

  const onDrop = useCallback(
    <T extends File>(
      acceptedFiles: T[],
      fileRejections: FileRejection[],
      event: DropEvent
    ) => {
      setAttachments(
        produce((prev) => {
          prev.push(...acceptedFiles);
          return prev;
        })
      );
    },
    []
  );

  //! this useefect is to prefill to contact when api call to get full detail of the edited order
  useEffect(() => {
    if (editedOrder)
      setBallInCourt(
        produce((prev) => {
          const isItemAdded = prev.find(
            (item) => item.contactId === editedOrder.primaryContact?.contactId
          );
          if (editedOrder.primaryContact && !isItemAdded)
            prev.push(editedOrder.primaryContact);
          return prev;
        })
      );
  }, [editedOrder]);

  //! reset all fields when close dialog
  useEffect(() => {
    if (!showEmailOrderDialog) resetInputEmail();
  }, [showEmailOrderDialog]);

  return {
    attachments,
    setAttachments,
    watchers,
    handleUpdateNewMail,
    handleAddWatcher,
    handleRemoveWatcher,
    handleClickAddBtn,
    newMail,
    ballInCourt,
    setBallInCourt,
    boldMode,
    setBoldMode,
    italicMode,
    setItalicMode,
    onDrop,
    isAddingEmail: mutateOrderQuery.shareOrder.isLoading,
    selectedText,
    setSelectedText,
    handleAddBallInCourt,
    handleRemoveBallInCourt,
  };
};

export default useEmailOrder;
