import React, {memo} from "react";
import {shallow} from "zustand/shallow";
import {useDoxleThemeStore} from "../../../DoxleGeneralStore/useDoxleThemeStore";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import {useEstimatesStore} from "../Store/useEstimatesStore";
import {ESTIMATES_HEADERS_MAP, EstimatesOrderByFields, RevEstimatesOrderByFields} from "../../Models/estimatesFilters";
import {
  QUICK_ESTIMATE_TABLE_HEADER_LIST,
  TQuickEstimatesTableHeaders
} from "../../Models/estimatesTable";
import {StyledEstimateTableHeaderCell, StyledEstimateTableHeaderRow, StyledEstimateTableHeaderText} from "../StyledComponents";


const QuickEstimateTableHeader = () => {
  const {orderBy, setQueryFilter} = useEstimatesStore(state => ({
    orderBy: state.estimatesQueryFilter.order_by,
    setQueryFilter:  state.setEstimatesQueryFilter
  }), shallow)
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }),
    shallow
  );
  const handleHeaderClick = (header:TQuickEstimatesTableHeaders) => {
    let newOrderByParam: EstimatesOrderByFields|RevEstimatesOrderByFields = ESTIMATES_HEADERS_MAP[header];
    if (orderBy && newOrderByParam.includes(orderBy)) newOrderByParam = `-${newOrderByParam}`
    setQueryFilter({order_by: newOrderByParam});
  }

  const checkHeaderMatch = (header:TQuickEstimatesTableHeaders) => {
    return !!(orderBy?.includes(ESTIMATES_HEADERS_MAP[header]));
  }

  return (
    <StyledEstimateTableHeaderRow themeColor={doxleThemeColor}>
      {QUICK_ESTIMATE_TABLE_HEADER_LIST.map(
        (header, idx) =>
          header.isDisplayed && (
            <StyledEstimateTableHeaderCell
              themeColor={doxleThemeColor}
              widthInPixel={header.headerName !== "#" ? null : "80px"}
              doxleFont={doxleFont}
              horizontalAlign={!(header.headerName === "Description" || header.headerName === "#") ? "center" : "flex-start"}
              key={`header#${idx}`}
              onClick={()=> handleHeaderClick(header.headerName)}
            >
              <StyledEstimateTableHeaderText
                fontWeight={checkHeaderMatch(header.headerName) ? 800 : 400}
              >
                {header.headerName}
              </StyledEstimateTableHeaderText>
              {checkHeaderMatch(header.headerName) && orderBy?.startsWith('-') && <ArrowDropDownIcon /> }
              {checkHeaderMatch(header.headerName) && !orderBy?.startsWith('-') && <ArrowDropUpIcon /> }
            </StyledEstimateTableHeaderCell>
          )
      )}
    </StyledEstimateTableHeaderRow>
  );
  return (
    <></>
  )
}

export default memo(QuickEstimateTableHeader);