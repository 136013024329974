import React, { useCallback, useEffect, useMemo, useState } from "react";
import useGetRateList from "../../../Hooks/useGetRateList";
import { usePricebookStore } from "../../../Store/usePricebookStore";
import { shallow } from "zustand/shallow";
import { useShallow } from "zustand/react/shallow";

type Props = {};

interface IPricebookPaginationControl {
  currentPage: number;
  handleSetPage: (pageNum: number) => void;
  handleSetPageSize: (pageSize: number) => void;
  totalPage: number;
  currentPageSize: number;
  totalItem: number;

  hasPreviousPage: boolean;
  hasNextPage: boolean;
  fetchNextRateList: () => void;
  fetchPreviousRateList: () => void;
}
const usePricebookRateTablePagination = (
  props: Props
): IPricebookPaginationControl => {
  const {
    pricebookRateQueryFilter,
    setPricebookRateQueryFilter,
    clearShrinkedPricebookSections,
  } = usePricebookStore(
    useShallow((state) => ({
      pricebookRateQueryFilter: state.pricebookRateQueryFilter,
      setPricebookRateQueryFilter: state.setPricebookRateQueryFilter,
      clearShrinkedPricebookSections: state.clearShrinkedPricebookSections,
    }))
  );

  const { totalPage, totalItem, removeQueryRateList } = useGetRateList({});

  const currentPage = pricebookRateQueryFilter.page;
  const currentPageSize = pricebookRateQueryFilter.page_size;

  const hasPreviousPage = () => {
    return pricebookRateQueryFilter.page > 1;
  };

  const hasNextPage = () => {
    return pricebookRateQueryFilter.page < totalPage;
  };

  const fetchNextRateList = () => {
    if (hasNextPage()) {
      setPricebookRateQueryFilter({ page: pricebookRateQueryFilter.page + 1 });
      clearShrinkedPricebookSections();
    }
  };
  const fetchPreviousRateList = () => {
    if (hasPreviousPage()) {
      setPricebookRateQueryFilter({ page: pricebookRateQueryFilter.page - 1 });
      clearShrinkedPricebookSections();
    }
  };

  const handleSetPage = useCallback(
    (pageNum: number) => {
      if (pageNum > 0 && pageNum <= totalPage) {
        setPricebookRateQueryFilter({
          page: pageNum,
        });
        clearShrinkedPricebookSections();
      }
    },
    [totalPage]
  );

  const handleSetPageSize = (pageSize: number) => {
    setPricebookRateQueryFilter({
      page_size: pageSize,
      page: 1,
    });
    clearShrinkedPricebookSections();
  };

  return {
    currentPage,
    handleSetPage,
    handleSetPageSize,
    totalPage,
    currentPageSize,
    totalItem,
    hasPreviousPage: hasPreviousPage(),
    hasNextPage: hasNextPage(),
    fetchNextRateList,
    fetchPreviousRateList,
  };
};

export default usePricebookRateTablePagination;
