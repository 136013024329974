import { create } from "zustand";
import { Project } from "../Models/project";
import { ReactElement } from "react";
import { IFullDocketDetailQueryFilterProp } from "../Models/dockets";
// import { TakeOffUnits } from "../Measurements/Models/takeOffs";
import { ProjectPermission } from "../Models/permissions";
import { MouseEvent } from "react";
import { TRgbaFormat } from "../Utilities/FunctionUtilities";

interface IEditProjectBtn {
  btnFunction: (e: MouseEvent<HTMLDivElement | HTMLButtonElement>) => void;
  btnText?: string;
  showBtnIcon?: false | "start" | "end";
  btnIcon?: string;
  btnBaseColor?: TRgbaFormat;
  isLoading?: boolean;
}

export const defaultProjectTabs: string[] = [
  "Drawings",
  // "Actions",
  "Budgets",
  "Bookings",
  "Files",
  "QA",
  "Mail",
  // "Contacts",
  "Notes",
  "QR",
];

type ProjectTabOptions = (typeof defaultProjectTabs)[number] | "Accounts";
interface ProjectStore {
  projectList: Project[];
  setProjectList: (projects: Project[]) => void;
  projectTabs: ProjectTabOptions[];
  setProjectTabs: (tabs: ProjectTabOptions[]) => void;
  selectedProjectTab: ProjectTabOptions;
  setSelectedProjectTab: (selectedTab: ProjectTabOptions) => void;
  headerComponents: ReactElement[];
  setHeaderComponents: (menu: ReactElement[]) => void;
  kebabMenuItems: ReactElement[];
  setKebabMenuItems: (menuItems: ReactElement[]) => void;
  projectSearchBarText: string;
  setProjectSearchBarText: (value: string) => void;

  // Project permissions to know what can be changed
  projectPermissions: ProjectPermission | undefined;
  setProjectPermissions: (permissions: ProjectPermission | undefined) => void;

  //display toggle
  showFullStatus: boolean;
  setShowFullStatus: (value: boolean) => void;

  //filter for budget
  filterBudgetListQuery: IFullDocketDetailQueryFilterProp;
  setFilterBudgetListQuery: (item: IFullDocketDetailQueryFilterProp) => void;

  //* for add new in any tab
  buttonNewFunction: IEditProjectBtn[];
  setFunctionNewButton: (passedFunctions: IEditProjectBtn[]) => void;

  accountingBasis: "CASH" | "ACCRUAL";
  toggleAccountingBasis: () => void;
}
const getInitialProjectTab = () => {
  const sessionTab = sessionStorage.getItem(
    "setSelectedProjectTab"
  ) as ProjectTabOptions;
  if (sessionTab && defaultProjectTabs.includes(sessionTab)) return sessionTab;
  return "Drawings";
};

const sessionStorageFilter = sessionStorage.getItem("budgetFiltersProject");
const initialBudgetFilters = sessionStorageFilter
  ? JSON.parse(sessionStorageFilter)
  : { budget: "true" };
export const useProjectStore = create<ProjectStore>((set, get) => ({
  projectList: [],
  setProjectList: (projects: Project[]) => set({ projectList: projects }),
  projectTabs: defaultProjectTabs,
  setProjectTabs: (tabs: ProjectTabOptions[]) => set({ projectTabs: tabs }),
  selectedProjectTab: getInitialProjectTab(),
  setSelectedProjectTab: (selectedTab: ProjectTabOptions) => {
    set({ selectedProjectTab: selectedTab });
    sessionStorage.setItem("setSelectedProjectTab", selectedTab);
  },
  headerComponents: [],
  setHeaderComponents: (components: ReactElement[]) =>
    set({ headerComponents: components }),
  kebabMenuItems: [],
  setKebabMenuItems: (menuItems: ReactElement[]) =>
    set({ kebabMenuItems: menuItems }),
  projectSearchBarText: "",
  setProjectSearchBarText: (value: string) =>
    set({
      projectSearchBarText: value,
    }),
  projectPermissions: undefined,
  setProjectPermissions: (permissions: ProjectPermission | undefined) =>
    set({ projectPermissions: permissions }),
  showFullStatus: false,
  setShowFullStatus: (value: boolean) => set({ showFullStatus: value }),

  filterBudgetListQuery: initialBudgetFilters,
  setFilterBudgetListQuery: (
    filter: Partial<IFullDocketDetailQueryFilterProp>
  ) => {
    const newFilter: IFullDocketDetailQueryFilterProp = {
      ...get().filterBudgetListQuery,
      ...filter,
    };
    set({ filterBudgetListQuery: newFilter });
    sessionStorage.setItem("budgetFiltersProject", JSON.stringify(newFilter));
  },
  accountingBasis:
    localStorage.getItem("accountingBasis") === "ACCRUAL" ? "ACCRUAL" : "CASH",
  toggleAccountingBasis: () => {
    const newBasis = get().accountingBasis === "CASH" ? "ACCRUAL" : "CASH";
    localStorage.setItem("accountingBasis", newBasis);
    set({ accountingBasis: newBasis });
  },
  buttonNewFunction: [],
  setFunctionNewButton: (passedFunction: IEditProjectBtn[]) => {
    return set({ buttonNewFunction: passedFunction });
  },
}));
