import styled from "styled-components";
import { motion } from "framer-motion";
import {
  Button,
  IconButton,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import {
  DoxleFont,
  DoxleThemeColor,
} from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { editRgbaAlpha } from "../../../Utilities/FunctionUtilities";

export const RootInvoiceTabPanelDisplayer = styled(motion.div)`
  min-width: 300px;
  width: 100%;
  //max-width: 600px;
  height: calc(100% - 20px);
  padding: 0 0 20px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

export const StyledAddedFileDisplaySection = styled.div`
  flex: 1 1 0;
  width: calc(100% - 28px);
  padding: 0px 14px;
  overflow: scroll;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

export const StyledDroppedFileErrorTextSpan = styled.span`
  margin-top: 8px;
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 13px;
  letter-spacing: 0.05em;
  color: #000000;
`;
export const StyledDroppedFileItem = styled.div`
  margin: 4px 0px;
  width: calc(100% - 40px);
  padding: 8px 20px;
  background: rgba(185, 185, 255, 0.3);
  border-radius: 3px;
  display: flex;
  flex-direction: column;
`;
export const StyledDroppedFileItemFileInfo = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
export const StyledDroppedFileItemTextSpan = styled.span<{
  widthInPixel: number | null;
}>`
  ${(p) => (p.widthInPixel ? `width: ${p.widthInPixel}px;` : "flex:1;")}
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 0 4px;
`;
export const StyledDropzone = styled.div<{
  $themecolor: DoxleThemeColor;
}>`
  border: 1px dashed ${(p) => p.$themecolor.primaryDividerColor};
  width: 85%;
  height: 85%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: copy;
`;
export const StyledDroppedFileItemButton = styled(IconButton)`
  && {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 4px !important;
  }
`;
export const StyledDropzoneButton = styled(Button)`
  && {
    width: 120px;
    height: 27px;

    background: #7b7bfe;
    border-radius: 13px !important;
    font-family: "IBM Plex Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 14px;
    letter-spacing: 0.05em;

    color: #ffffff;
    margin-top: 14px;
    text-transform: capitalize;
    transition: 0.8s;
  }
  &:hover {
    background-color: #7b7bfe !important;
    opacity: 0.5;
  }
`;

export const StyledDropzoneSection = styled.div<{
  $themecolor: DoxleThemeColor;
  $doxleFont: DoxleFont;
  $height?: string;
  $width?: string;
  $borderRadius?: string;
}>`
  width: ${p => p.$width ?? 'Calc(100% - 16px)'};
  height: ${p => p.$height ?? '50%'};
  max-height: 250px;
  min-height: ${p => p.$height ?? '150px'};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${p => p.$borderRadius ?? '8px'};
  background-color: ${(p) => p.$themecolor.primaryBackgroundColor};
`;

export const StyledHeadTitleDropzone = styled.span`
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.05em;
  color: #5e52a7;
  text-transform: none;
`;

export const StyledInvoiceTabHeadTitle = styled.h1<{
  $themecolor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 39px;
  letter-spacing: 0.05em;
  margin: 4px 0px !important;
  color: ${(p) => p.$themecolor.primaryFontColor};
`;

export const StyledInvoiceTabSubTitle = styled.span<{
  color?: string;
}>`
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.05em;
  text-transform: none;
  color: ${(p) => p.color ?? "#7572ff"};
`;

export const StyledPurchaseOrderInput = styled(TextField)<{
  $themecolor: DoxleThemeColor;
}>`
  && {
    height: 30px !important;
    margin: 15px 8px;
    width: 100%;
    background-color: ${(p) => p.$themecolor.primaryBackgroundColor};
    border-radius: 4px;
    display: flex;
    align-items: flex-start;
  }

  input {
    margin-top: 7px;
    padding: 0 4px !important;
    font-family: "IBM Plex Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.05em;
    border-radius: 3px;
    color: ${(p) => p.$themecolor.primaryFontColor};
    &::placeholder {
      font-family: "IBM Plex Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      text-align: left;
      letter-spacing: 0.05em;
      color: ${(p) =>
        editRgbaAlpha({
          rgbaColor: p.$themecolor.primaryFontColor,
          alpha: "0.7",
        })};
    }
  }
  &.MuiOutlinedInput-root {
    height: 100%;
  }
  & .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }
  & label {
    color: ${(p) => p.$themecolor.doxleColor} !important;
    font-size: 16px !important;
    margin-top: -8px !important;
  }
  & .MuiFormHelperText-root {
    color: red !important;
    font-size: 11px !important;
  }
`;

export const StyledSubTitleDropzone = styled.span`
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 13px;
  text-align: center;
  letter-spacing: 0.05em;

  color: #564a78;
`;

export const StyledPaymentInputContainer = styled.div<{
  marginTop?: `${number}px`;
}>`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: ${(p) => p.marginTop ?? "-20px"};
`;

export const StyledPaymentClaimMessageText = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  width: 100%;
  padding: 5px;
  text-align: center;
  margin: 5px 0px;
  font-family: ${(p) => p.$doxleFont.primaryFont};
  font-size: 12px;
`;

export const StyledPaymentClaimTabContainer = styled(motion.div)`
  width: 100%;
  flex: 1;
  padding: 20px 0px;
  text-align: center;
  display: flex;
  flex-direction: column;
`;

export const StyledAttachedHeaderText = styled.div<{
  $themecolor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  width: 100%;
  padding-left: 20px;
  text-align: left;
  color: ${(p) => p.$themecolor.primaryFontColor};
  font-family: ${(p) => p.$doxleFont.primaryFont};
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 15px;
`;

export const StyledPaymentClaimTextArea = styled(TextField)<{
  $themecolor: DoxleThemeColor;
}>`
  && {
    margin: 15px 8px;
    width: Calc(100% - 16px);
    background-color: ${(p) => p.$themecolor.primaryBackgroundColor};
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }
  input {
    padding: 0 4px !important;
    font-family: "IBM Plex Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.05em;
    border-radius: 3px;

    color: ${(p) => p.$themecolor.primaryFontColor};
    &::placeholder {
      font-family: "IBM Plex Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      text-align: left;
      letter-spacing: 0.05em;
      color: ${(p) =>
        editRgbaAlpha({
          rgbaColor: p.$themecolor.primaryFontColor,
          alpha: "0.7",
        })};
    }
  }
  &.MuiOutlinedInput-root {
    height: 100%;
  }
  & .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }
  & label {
    color: ${(p) => p.$themecolor.doxleColor} !important;
    font-size: 16px !important;
    margin-top: -8px !important;
  }
  & .MuiFormHelperText-root {
    color: red !important;
    font-size: 11px !important;
  }
`;

export const StyledPaymentStatusSelect = styled(Select)<{
  $themecolor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  && {
    height: 30px !important;
    margin: 15px 8px;
    width: Calc(100% - 16px);
    background-color: ${(p) => p.$themecolor.primaryBackgroundColor};
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-top: 0px !important;
  }
  input {
    padding: 0 4px !important;
    font-family: "IBM Plex Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.05em;
    border-radius: 3px;

    color: ${(p) => p.$themecolor.primaryFontColor};
    &::placeholder {
      font-family: "IBM Plex Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      text-align: left;
      letter-spacing: 0.05em;
      color: ${(p) =>
        editRgbaAlpha({
          rgbaColor: p.$themecolor.primaryFontColor,
          alpha: "0.7",
        })};
    }
  }
  &.MuiOutlinedInput-root {
    height: 100%;
  }
  & .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }
  & label {
    color: ${(p) => p.$themecolor.doxleColor} !important;
    font-size: 16px !important;
    margin-top: -8px !important;
  }
  & .MuiFormHelperText-root {
    color: red !important;
    font-size: 11px !important;
  }
`;
