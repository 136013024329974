import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ContactCompany } from "../../../../Models/addressBook";
import ContactsAPI from "../../../../Services/QueryHooks/contactsAPI";
import { shallow } from "zustand/shallow";
import { useDoxleAuthStore } from "../../../../DoxleGeneralStore/useDoxleAuthStore";
import { useDoxleCurrentContextStore } from "../../../../DoxleGeneralStore/useDoxleCurrentContext";
import { useEditOrderStore } from "../../../OrdersStore/useEditOrderStore";
import { useOrdersStore } from "../../../OrdersStore/useOrdersStore";
import OrdersQueryAPI from "../../../QueryHooks/OrdersQueryAPI";
import Order from "../../../Models/order";
import useDoxleNotificationStore from "../../../../DoxleGeneralStore/useDoxleNotificationStore";
import useSetOrderDetailQueryData from "../../../Hooks/useSetOrderDetailQueryData";
import { ContactCompanyFilters } from "../../../../Services/QueryHooks/contactsFilters";
import { useEditOrderContext } from "../EditOrder";
import { produce } from "immer";

type Props = {};
interface EditContrractorOrder {
  handleClickContractorField: (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => void;
  handleCloseContractorPopover: () => void;
  anchorContractorListEl: HTMLElement | null;
}
const useEditContrractorOrder = (props: Props): EditContrractorOrder => {
  const [anchorContractorListEl, setAnchorContractorListEl] =
    React.useState<null | HTMLElement>(null);
  const handleClickContractorField = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      setAnchorContractorListEl(e.currentTarget);
    },
    []
  );
  const handleCloseContractorPopover = () => {
    setAnchorContractorListEl(null);
  };
  return {
    handleClickContractorField,
    handleCloseContractorPopover,
    anchorContractorListEl,
  };
};

export default useEditContrractorOrder;
