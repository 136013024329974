import {
  DoxleFont,
  DoxleThemeColor,
} from "../../../DoxleGeneralStore/useDoxleThemeStore";
import styled from "styled-components";
import { motion } from "framer-motion";
import FormHelperText from "@mui/material/FormHelperText";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import { DropzoneArea } from "material-ui-dropzone";
import {
  TRgbaFormat,
  editRgbaAlpha,
} from "../../../Utilities/FunctionUtilities";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import Paper, { PaperProps } from "@mui/material/Paper";

export const StyledAddMailFormContainer = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  max-width: 110rem;
  border-radius: 4px;
  min-height: 0px;
  display: flex;
  flex-direction: column;
  overflow: auto;

  margin-top: 5rem;
  padding-left: 8rem;
  padding-right: 8rem;
  margin-bottom: 5rem;

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;
export const StyledAddMailFormTopTitle = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  width: 100%;
  padding: 8px 0px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: ${(p) => p.$doxleFont.primaryFont};
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 30px;
  letter-spacing: 0.05em;
  color: ${(p) => p.$themeColor.primaryFontColor};
  text-transform: capitalize;
  border-bottom: 1px solid ${(p) => p.$themeColor.primaryDividerColor};
`;
export const StyledBackdropAddMailForm = styled.div<{
  $themeColor: DoxleThemeColor;
}>`
  width: 100%;
  height: 100%;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${(p) => p.$themeColor.primaryBackdropColor};
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
`;
export const StyledErrorHelperText = styled(motion(FormHelperText))<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  && {
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 0.05em;
    text-transform: capitalize;
    color: ${(p) => p.$themeColor.errorColor};
    margin: 4px;
  }
`;
export const StyledEventScreen = styled(motion.div)<{
  $themeColor: DoxleThemeColor;
}>`
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${(p) => p.$themeColor.primaryBackdropColor};
`;
export const StyledMailAuthorFieldView = styled.div`
  width: 40%;
  display: flex;
`;
export const StyledMailButton = styled(Button)<{
  $doxleFont: DoxleFont;
  $bgColor: string;
  $textColor: string;
}>`
  && {
    min-width: 80px;
    border-radius: 4px;
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.05em;
    text-transform: capitalize;
    color: ${(p) => p.$textColor};
    background-color: ${(p) => p.$bgColor};
    margin: 8px;
    elevation: 4;
    box-shadow: 0px 0px 4px grey;
  }
  &:hover {
    background-color: ${(p) => p.$bgColor} !important;
    opacity: 0.9;
    transform: translateY(-5px);
    transition: 0.4s;
  }
`;
export const StyledMailFormButtonSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;
export const StyledMailFormContentSection = styled.div`
  flex: 1;
  width: calc(100% - 28px);
  display: flex;
  flex-direction: column;
  padding: 14px;
  justify-content: flex-start;
  align-items: center;
  overflow: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;
export const StyledMailFormFieldView = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  width: 100%;
  display: flex;
  min-height: 80px;
  margin-bottom: 35px;
  font-family: ${(p) => p.$doxleFont.primaryFont};
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.05em;
  text-transform: capitalize;
  color: ${(p) => p.$themeColor.primaryFontColor};
  align-items: flex-start;
  justify-content: space-between;
`;

export const StyledMailFormFieldViewWithTagsBelow = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  width: 100%;
  display: flex;
  min-height: 80px;
  font-family: ${(p) => p.$doxleFont.primaryFont};
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.05em;
  text-transform: capitalize;
  color: ${(p) => p.$themeColor.primaryFontColor};
  align-items: flex-start;
  justify-content: space-between;
`;

export const StyledMailFormFieldViewWithTags = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
  $bottomMargin?: number;
  $minHeight?: number;
}>`
  width: 100%;
  display: flex;
  min-height: ${(p) => (p.$minHeight !== undefined ? p.$minHeight : 80)}px;
  margin-bottom: ${(p) =>
    p.$bottomMargin !== undefined ? p.$bottomMargin : 35}px;
  font-family: ${(p) => p.$doxleFont.primaryFont};
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.05em;
  text-transform: capitalize;
  color: ${(p) => p.$themeColor.primaryFontColor};
  align-items: flex-start;
  justify-content: start;
  margin-top: 10px;
`;
export const StyledMailDropzoneArea = styled(DropzoneArea)<{
  $themeColor: DoxleThemeColor;
}>`
  && {
    min-height: 80px !important;
  }
  & .MuiDropzoneArea-root {
    min-height: 80px !important;
  }
  & .MuiDropzoneArea-icon {
    display: none !important;
  }
`;

export const StyledMailSideScreenContainer = styled.div<{
  $doxleFont: DoxleFont;
  $themeColor: DoxleThemeColor;
}>`
  font-family: ${(p) => p.$doxleFont.secondaryTitleFont};
  width: 100%;
  height: 100%;
  background-color: ${(p) => p.$themeColor.MailSideScreenColor};
`;

export const StyledMailGeneralSection = styled.div`
  display: flex;
`;

export const StyledMainInfoContainer = styled.div`
  padding-left: 8rem;
  padding-top: 5rem;
  padding-right: 8rem;
  height: 80%;
  display: flex;
  flex-direction: column;
`;

export const StyledMailBodySection = styled.div`
  overflow-y: scroll;
  margin-top: 6rem;
  height: 90%;

  &.mailBodySection {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  &.mailBodySection::-webkit-scrollbar {
    display: none;
  }
`;

export const StyledMailCommentsSection = styled.div`
  overflow-y: scroll;
  height: 90%;

  &.mailCommentsSection {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  &.mailCommentsSection::-webkit-scrollbar {
    display: none;
  }
`;

export const StyledMailResponseSection = styled.div`
  align-items: end;
  display: flex;
`;

export const StyledInputFieldContainer = styled.div<{
  $width?: string;
}>`
  display: flex;
  flex-direction: column;
  width: ${(p) => (p.$width ? p.$width : "100%")};

  &.fromContainer {
    padding-right: 5rem;
  }
`;

export const StyledInputLabel = styled.div<{
  $themeColor: DoxleThemeColor;
}>`
  color: ${(p) =>
    editRgbaAlpha({ rgbaColor: p.$themeColor.primaryFontColor, alpha: "0.7" })};
  font-size: 1rem;
  padding-left: 0.5rem;
  margin-bottom: 3px;
  font-weight: 500;
`;

export const StyledEmailInfo = styled.div<{
  $themeColor: DoxleThemeColor;
}>`
  color: ${(p) => p.$themeColor.primaryFontColor};
  font-size: 1.3rem;
  background-color: ${(p) => p.$themeColor.primaryBackgroundColor};
  padding: 0.5rem 0.5rem;
  min-width: 100px;
  overflow: hidden;

  &.fromName {
    white-space: nowrap;
  }
`;

export const StyledEmailBody = styled.div<{
  $themeColor: DoxleThemeColor;
}>`
  color: ${(p) =>
    editRgbaAlpha({ rgbaColor: p.$themeColor.primaryFontColor, alpha: "0.7" })};
  font-size: 1.2rem;
  background-color: ${(p) => p.$themeColor.primaryBackgroundColor};
  padding: 0.5rem 0.5rem;
`;

export const StyledMailInput = styled.input<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  background-color: ${(p) => p.$themeColor.primaryBackgroundColor};
  height: 2.7rem;
  border: none;
  font-family: ${(p) => p.$doxleFont.secondaryTitleFont};
  padding-left: 10px;
  width: calc(100% - 10px);
  font-size: 1.3rem;
  font-weight: 400;
  min-width: 90px;
  text-overflow: ellipsis;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.$themeColor.primaryFontColor,
        alpha: "0.3",
      })};
  }
`;

export const StyledMailTextArea = styled.textarea<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  background-color: ${(p) => p.$themeColor.primaryBackgroundColor};
  height: 21.7rem;
  border: none;
  font-family: ${(p) => p.$doxleFont.secondaryTitleFont};
  padding-top: 1rem;
  padding-left: 10px;
  font-size: 1.3rem;
  font-weight: 400;
  resize: none;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.$themeColor.primaryFontColor,
        alpha: "0.3",
      })};
  }
`;

export const StyledWatcherSelect = styled.select<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  background-color: ${(p) => p.$themeColor.primaryBackgroundColor};
  height: 2.7rem;
  border: none;
  font-family: ${(p) => p.$doxleFont.secondaryTitleFont};
  padding-top: 1rem;
  padding-left: 10px;
  font-size: 1.3rem;
  font-weight: 400;
  resize: none;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.$themeColor.primaryFontColor,
        alpha: "0.3",
      })};
  }
`;

export const StyledWatchersContainer = styled.div`
  margin-top: 6rem;
  display: flex;
`;

export const StyledMessageContainer = styled.div`
  margin-top: 6rem;
`;

export const StyledAddMailBottomContainer = styled.div`
  display: flex;
  margin-top: 2rem;
`;

export const StyledMailSubmitButton = styled.button<{
  $themeColor: DoxleThemeColor;
}>`
  width: 8rem;
  height: 3.9rem;
  border-radius: 0.3rem;
  margin: 10px;
  background-color: ${(p) => p.$themeColor.primaryFontColor};
  color: ${(p) => p.$themeColor.primaryReverseFontColor};
  border: none;
  cursor: pointer;
  font-size: 1.4rem;
`;

export const StyledMailCancelButton = styled.button<{
  $themeColor: DoxleThemeColor;
}>`
  width: 8rem;
  height: 3.9rem;
  border-radius: 0.3rem;
  background-color: ${(p) => p.$themeColor.primaryReverseFontColor};
  color: ${(p) => p.$themeColor.primaryFontColor};
  cursor: pointer;
  border: 1px solid #dddddd;
  font-size: 1.4rem;
`;

export const StyledAttachmentUploadZone = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 26rem;
  min-height: 13.1rem;
  border-radius: 0.8rem;
  border: 1px dashed #a9b0c2;
`;

export const StyledUploadPrompt = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  margin-top: 10px;
  font-size: 1.2rem;
  font-family: ${(p) => p.$doxleFont.primaryFont};
  color: ${(p) =>
    editRgbaAlpha({ rgbaColor: p.$themeColor.primaryFontColor, alpha: "0.7" })};
  font-weight: 500;
  display: flex;
  flex-direction: column;
`;

export const StyledMailFileContainer = styled.div`
  display: flex;
  width: 60%;
  margin-top: 2rem;
  flex-wrap: wrap;
`;

export const StyledFileInfoContainer = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 2rem;
  margin-top: 1rem;
`;

export const StyledCloseButton = styled.div`
  position: relative;
  left: 0.5rem;
  top: 0.2rem;
  cursor: pointer;
`;

export const StyledFileName = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  font-weight: 500;
  font-size: 1.2rem;
  color: ${(p) => p.$themeColor.primaryFontColor};
  font-family: ${(p) => p.$doxleFont.primaryFont};
  overflow-x: hidden;
  white-space: nowrap;
  margin-left: 0.5rem;
  max-width: 15rem;
  text-overflow: ellipsis;
`;

export const StyledAddMailDialog = styled(Dialog)<{
  $themeColor: DoxleThemeColor;
}>`
  && {
    box-shadow: 1px 1px 2px ${(p) => p.$themeColor.primaryBoxShadowColor};
  }
  & .MuiDialog-paper {
    background-color: ${(p) => p.$themeColor.primaryContainerColor};
    box-shadow: 0px 0px 12px 0px ${(p) => p.$themeColor.primaryBoxShadowColor};
    display: flex !important;
    flex-direction: column;
    width: calc(80vw - 68px) !important;
    height: calc(80vh - 28px);
    max-width: 1000px;
    min-width: 500px;
    min-height: 400px;
    max-height: 900px;
    border-radius: 9px !important;
    padding: 14px 34px;
  }
`;

export const StyledAddMailTitle = styled(DialogTitle)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  && {
    color: ${(p) => p.$themeColor.primaryFontColor};
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-size: max(1.6rem, 16px);
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 14px 0px;
  }
`;

export const StyledAddMailContentWrapper = styled(DialogContent)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  && {
    width: calc(100%);
    margin-top: 20px;
    flex: 1;
    display: flex;
    flex-direction: column;

    overflow: auto;
    position: relative;
    padding: 0px 0px 14px 0px !important;
  }
`;

export const StyledProjectSearchTextField = styled(motion(TextField))<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  && {
    width: calc(100% - 1.6rem) !important;
    padding: 0 0.8rem;
  }
  input {
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-weight: 400;
    font-size: max(1.4rem, 14px);
    line-height: normal;

    color: ${(p) => p.$themeColor.primaryFontColor};

    &::placeholder {
      font-family: ${(p) => p.$doxleFont.primaryTitleFont};
      font-size: max(1.4rem, 14px);
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      color: ${(p) => p.$themeColor.primaryInputPlaceholderColor} !important;
      opacity: 1 !important;
    }
  }
  & .MuiInput-root:after {
    border: none !important;
  }
  & .MuiInput-root:before {
    border: none !important;
  }
  & .MuiInput-root {
    border: none !important;
  }
  & .MuiInput-root:hover {
    border: none !important;
  }
  & .MuiInput-root:hover:not(.Mui-disabled, .Mui-error):before {
    border: none !important;
  }
  & .MuiInputLabel-root.Mui-focused {
    display: none;
  }
  & .MuiInputLabel-root {
    display: none;
  }
  & .MuiInputBase-root {
    width: 100%;
  }
  & .MuiInputBase-root-MuiInput-root:after {
    border-bottom: none !important;
  }
  & .MuiInputBase-input {
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const StyledAddMailFieldWrapper = styled(motion.div)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  width: calc(100% - 8px);
  padding: 8px 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid ${(p) => p.$themeColor.primaryDividerColor};
  margin-bottom: 8px;
  .label-field {
    font-family: ${(p) => p.$doxleFont.primaryTitleFont};
    font-size: max(1.4rem, 14px);
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    color: ${(p) => p.$themeColor.primaryFontColor} !important;
    margin-right: 8px;
  }
  .email-text {
    font-family: ${(p) => p.$doxleFont.primaryTitleFont};
    font-size: max(1.4rem, 14px);
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    color: ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.$themeColor.primaryFontColor,
        alpha: "0.4",
      })} !important;
    margin-left: 8px;
  }
  .add-content-wrapper {
    flex: 1;
    cursor: pointer;
    min-height: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: ${(p) => p.$doxleFont.primaryTitleFont};
    font-size: max(1.4rem, 14px);
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    color: ${(p) => p.$themeColor.primaryFontColor} !important;
  }
`;
export const StyledMailContactOption = styled(motion.div)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  width: calc(100% - 8px);
  font-family: ${(p) => p.$doxleFont.primaryTitleFont};
  font-size: max(1.4rem, 14px);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
  color: ${(p) => p.$themeColor.primaryFontColor} !important;
  padding: 4px;
  transition: all 0.4s ease;
  display: flex;
  flex-direction: column;
  align-items: flex-start !important;
  text-align: left;
  .email-text {
    font-family: ${(p) => p.$doxleFont.primaryTitleFont};
    font-size: max(1.4rem, 14px);
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    color: ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.$themeColor.primaryFontColor,
        alpha: "0.4",
      })} !important;
    margin-top: 4px;
    white-space: wrap;
    margin-left: 0px !important;
  }
`;
export const StyledSearchContactTextField = styled(TextField)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  && {
    padding: 8px 0px !important;
    border: none;

    width: calc(100% - 16px);
    min-width: 90px;
    margin-bottom: 8px;
    border-bottom: 1px solid ${(p) => p.$themeColor.primaryDividerColor};
  }
  input {
    font-family: ${(p) => p.$doxleFont.secondaryTitleFont};
    font-weight: 400;
    font-size: 1.4rem;
    line-height: normal;
    color: ${(p) => p.$themeColor.primaryFontColor};
    padding: 0px !important;
  }
  & .MuiInput-root:after {
    border-bottom: none !important;
  }
  & .MuiInput-root:before {
    border-bottom: none !important;
  }
`;
interface IContactListContainerProps
  extends Omit<
    PaperProps,
    "onDragStart" | "onAnimationStart" | "onDragEnd" | "onDrag"
  > {
  $themeColor: DoxleThemeColor;
}
const AnimatedPaper = motion(Paper);

export const StyledContactListContainer = styled(AnimatedPaper)<{
  $themeColor: DoxleThemeColor;
}>`
  && {
    background-color: ${(p) => p.$themeColor.primaryContainerColor};
    width: 300px;
    height: 30vh;
    max-height: 400px;
    min-height: 200px;
    border-radius: 0px 0px 0px9px;
    position: relative;
    padding: 1rem;
    box-shadow: 0px 0px 8px 0px ${(p) => p.$themeColor.primaryBoxShadowColor};
    display: flex;
    flex-direction: column;
    position: relative;
  }
`;
export const StyledContactListTitle = styled.span<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  color: ${(p) => p.$themeColor.primaryFontColor};
  font-family: ${(p) => p.$doxleFont.primaryFont};
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 1rem;
  text-transform: capitalize;
  padding-left: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
export const StyledSelectedContactItem = styled(motion.div)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  color: ${(p) => p.$themeColor.primaryFontColor};
  font-family: ${(p) => p.$doxleFont.primaryFont};
  font-size: max(1.4rem, 14px);
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  text-transform: capitalize !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 8px;
  border-radius: 4px;
  margin: 0px 4px;
  color: ${(p) => p.$themeColor.primaryFontColor};
  border: 1px solid ${(p) => p.$themeColor.primaryDividerColor};

  .email-text {
  }
`;

export const StyledMailSubjectTextField = styled(TextField)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
  $boldMode?: boolean;
  $italicMode?: boolean;
}>`
  && {
    padding: 0px !important;
    border: none;
    width: calc(100% - 16px);
    min-width: 90px;
    margin-top: 2px;
  }
  input {
    font-family: ${(p) => p.$doxleFont.secondaryTitleFont};
    font-weight: 400;
    font-size: max(1.4rem, 14px);

    color: ${(p) => p.$themeColor.primaryFontColor};
    padding: 0px !important;
  }
  & .MuiInput-root:after {
    border-bottom: none !important;
  }
  & .MuiInput-root:before {
    border-bottom: none !important;
  }
  & .MuiInputBase-multiline {
    font-family: ${(p) => p.$doxleFont.secondaryTitleFont};
    font-weight: ${(p) => (p.$boldMode ? "bold" : "400")};
    font-style: ${(p) => (p.$italicMode ? "italic" : "")};
    font-size: max(1.4rem, 14px);

    color: ${(p) => p.$themeColor.primaryFontColor};
    padding: 0px !important;
  }
`;

export const StyledMailBodyTextField = styled(TextField)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
  $boldMode?: boolean;
  $italicMode?: boolean;
}>`
  && {
    padding: 0px !important;
    border: none;
    width: calc(100% - 16px);
    min-width: 90px;
    margin-top: 2px;
    height: 100%;
  }
  input {
    font-family: ${(p) => p.$doxleFont.secondaryTitleFont};
    font-weight: 400;
    font-size: max(1.4rem, 14px);

    color: ${(p) => p.$themeColor.primaryFontColor};
    padding: 0px !important;
  }
  & .MuiInput-root:after {
    border-bottom: none !important;
  }
  & .MuiInput-root:before {
    border-bottom: none !important;
  }
  & .MuiInputBase-multiline {
    font-family: ${(p) => p.$doxleFont.secondaryTitleFont};
    font-weight: ${(p) => (p.$boldMode ? "bold" : "400")};
    font-style: ${(p) => (p.$italicMode ? "italic" : "")};
    font-size: max(1.4rem, 14px);

    color: ${(p) => p.$themeColor.primaryFontColor};
    padding: 0px !important;
  }
`;

export const StyledMailTextBodySection = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  cursor: text;
`;

export const StyledDialogActionBtnWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
export const StyledMailActionSubmitButton = styled(motion(Button))<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
  $reverseColor?: boolean;
}>`
  && {
    display: flex;
    flex-direction: row;
    min-width: 60px !important;
    cursor: pointer;
    margin: 0px 4px;
    padding: 4px 8px !important;
    border-radius: 6px !important;
    background-color: ${(p) =>
      p.$reverseColor
        ? p.$themeColor.primaryContainerColor
        : p.$themeColor.primaryFontColor};
    text-transform: capitalize !important;
    ${(p) =>
      p.$reverseColor
        ? `border:1px solid ${p.$themeColor.primaryDividerColor};`
        : ""}
    .btn-text {
      font-family: ${(p) => p.$doxleFont.primaryFont};
      font-size: max(1.4rem, 14px);
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      color: ${(p) =>
        p.$reverseColor
          ? p.$themeColor.primaryFontColor
          : p.$themeColor.primaryContainerColor} !important;
    }

    &:hover {
      background-color: ${(p) =>
        p.$reverseColor
          ? p.$themeColor.primaryContainerColor
          : p.$themeColor.primaryFontColor} !important;
      opacity: 0.95;
      transition: all 0.2s ease;
    }
  }
`;

export const StyledTextModeButton = styled(motion(Button))<{
  $themeColor: DoxleThemeColor;
  $selected?: boolean;
}>`
  && {
    display: flex;
    height: 30px;
    flex-direction: row;
    min-width: 0px !important;
    cursor: pointer;
    margin: 0px 4px;
    padding: 5px !important;
    border-radius: 4px !important;
    background-color: ${(p) => p.$themeColor.primaryTextFieldBackgroundColor};
    text-transform: capitalize !important;
    border: 1px solid
      ${(p) => (p.$selected ? p.$themeColor.doxleColor : "rgba(0,0,0,0)")};
    &:hover {
      background-color: ${(p) =>
        p.$themeColor.primaryTextFieldBackgroundColor} !important;
      opacity: 0.95;
      transition: all 0.2s ease;
    }
  }
`;

export const StyledAddAttachmentButton = styled(motion(Button))<{
  $themeColor: DoxleThemeColor;

  $doxleFont: DoxleFont;
}>`
  && {
    display: flex;
    flex-direction: row;
    min-width: 0px !important;
    cursor: pointer;
    align-self: flex-start;
    margin: 0px 4px;
    padding: 4px 8px !important;
    border-radius: 4px !important;
    background-color: ${(p) => p.$themeColor.primaryTextFieldBackgroundColor};
    text-transform: capitalize !important;
    border: 1px solid ${(p) => p.$themeColor.primaryDividerColor};
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-size: max(1.4rem, 14px);
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: ${(p) => p.$themeColor.primaryFontColor} !important;
    transition: all 0.2s ease;
    &:hover {
      background-color: ${(p) =>
        p.$themeColor.primaryTextFieldBackgroundColor} !important;
      opacity: 0.95;
      transition: all 0.2s ease;
      /* transform: translateY(-2px); */
    }
  }
`;

export const StyledAddedAttachmentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  max-height: 300px;
  /* overflow: auto; */
  align-self: baseline;

`;

export const StyledAddedAttachmentItem = styled(motion.div)<{
  $themeColor: DoxleThemeColor;

  $doxleFont: DoxleFont;
}>`
  border-radius: 4px;
  margin: 4px;
  position: relative;
  overflow: hidden;
  width: 20%;
  max-width: 100px;
  display: flex;
  flex-direction: column;
  .file-name {
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-size: max(1.2rem, 12px);
    font-style: normal;
    font-weight: 400;

    color: ${(p) => p.$themeColor.primaryFontColor} !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .img-holder {
    width: 100%;
    aspect-ratio: 1;
  }
  .icon-wrapper {
    width: 100%;
    aspect-ratio: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .close-icon {
    position: absolute;
    z-index: 5;
    top: 4px;
    right: 4px;
    cursor: pointer;
  }
`;
