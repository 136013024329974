import { BaseAPIProps } from "../../Models/baseAPIProps";
import DoxleAPI from "../../Services/DoxleAPI";
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { User } from "../../Models/user";
import {
  CompanyPermission,
  DocketPermission,
  ProjectPermission,
} from "../../Models/permissions";
import { IApiPaginatedData } from "../../Models/axiosReturn";

interface FilterContactPermissionQuery {
  project?: string;
  docket?: string;
  user?: string;
}
interface RetrieveOwnPermissionsQueryProps extends BaseAPIProps {
  user: User;
}
interface RetrievePermissionsQueryProps extends BaseAPIProps {
  filter: FilterContactPermissionQuery;
  enable?: boolean;
}

const useRetrieveUserPermissionsQuery = ({
  company,
  showNotification,
  user,
}: RetrieveOwnPermissionsQueryProps) => {
  const qKey = ["user-permissions", user.userId];
  let URL = `/permissions/${user.userId}`;

  const query = useQuery(
    qKey,
    () =>
      DoxleAPI.get(URL, {
        headers: {
          "User-Company": company!.companyId,
        },
      }),
    {
      enabled: Boolean(company && user.userId),
      retry: 1,
      staleTime: 4 * 60 * 1000,
      onError: (err: any) => {
        if (err?.response?.status && showNotification)
          showNotification(
            `${err?.response?.status ?? "ERROR"}: ${
              err?.response?.statusText ?? "UNKNOWN ERROR"
            }`,
            "error",
            String(
              err?.response?.data ?? "Error Fetching Permissions"
            ).substring(0, 300)
          );
      },
    }
  );
  return query;
};

const useRetrieveContactsPermissionsQuery = ({
  company,
  showNotification,
  filter,
  enable,
}: RetrievePermissionsQueryProps) => {
  const qKey = ["permissions-list"];
  let URL = `/permissions/?page=1`;
  if (filter.docket) qKey.push(filter.docket);
  else if (filter.project) qKey.push(filter.project);
  else if (company?.companyId) qKey.push(company?.companyId);
  return useInfiniteQuery(
    qKey,
    ({ pageParam = URL }) =>
      DoxleAPI.get<IApiPaginatedData<ProjectPermission | DocketPermission>>(
        pageParam,
        {
          headers: {
            "User-Company": company?.companyId,
          },
          params: filter,
        }
      ),
    {
      enabled: Boolean(company?.companyId && enable !== false),
      refetchInterval: Infinity,
      refetchOnWindowFocus: false,

      getNextPageParam: (prevData) => prevData.data?.next,
      onError: (err: any) => {
        if (err?.response?.status && showNotification)
          showNotification(
            `${err?.response?.status ?? "ERROR"}: ${
              err?.response?.statusText ?? "UNKNOWN ERROR"
            }`,
            "error",
            String(
              err?.response?.data ?? "Error Fetching Permissions"
            ).substring(0, 300)
          );
      },
    }
  );
};

const useRetrieveRelatedPermissionsContactsQuery = ({
  company,
  showNotification,
  filter,
}: RetrievePermissionsQueryProps) => {
  const qKey = ["related-contacts"];
  if (filter.docket) qKey.push(filter.docket);
  else if (filter.project) qKey.push(filter.project);
  let URL = `/permissions/rel/`;
  return useQuery(
    qKey,
    () =>
      DoxleAPI.get(URL, {
        headers: {
          "User-Company": company!.companyId,
        },
        params: filter,
      }),
    {
      enabled: Boolean(company && (filter?.docket || filter?.project)),
      retry: 1,
      staleTime: 4 * 60 * 1000,
      onError: (err: any) => {
        if (err?.response?.status && showNotification)
          showNotification(
            `${err?.response?.status ?? "ERROR"}: ${
              err?.response?.statusText ?? "UNKNOWN ERROR"
            }`,
            "error",
            String(
              err?.response?.data ?? "Error Fetching Related Permissions"
            ).substring(0, 300)
          );
      },
    }
  );
};

interface MutationAPIProps extends BaseAPIProps {
  onSuccessCb?: () => void;
}
const useMutatePermission = ({
  company,
  showNotification,
  onSuccessCb,
}: MutationAPIProps) => {
  const queryClient = useQueryClient();
  const mutationURL = `/permissions/mutate/`;
  const updatePermission = useMutation({
    mutationFn: async (
      permission: Partial<
        DocketPermission | ProjectPermission | CompanyPermission
      >
    ) => {
      return DoxleAPI.patch(mutationURL, permission, {
        headers: {
          "User-Company": company?.companyId || "",
        },
      });
    },
    onSuccess: (result, variables, context) => {
      const type = variables?.docket
        ? "DOCKET"
        : variables?.project
        ? "PROJECT"
        : "COMPANY";
      queryClient.setQueryData(
        [
          "permissions-list",
          variables?.docket ?? variables?.project ?? company?.companyId,
        ],
        (oldData: any) => {
          return oldData
            ? {
                ...oldData,
                pages: oldData.pages.map((page: any, index: number) => ({
                  ...page,
                  data: {
                    ...page.data,
                    results: [
                      ...page.data.results.map(
                        (
                          oldPermission:
                            | DocketPermission
                            | ProjectPermission
                            | CompanyPermission
                        ) =>
                          oldPermission?.contact?.contactId ==
                          variables?.contact?.contactId
                            ? result.data
                            : oldPermission
                      ),
                    ],
                  },
                })),
              }
            : oldData;
        }
      );

      // if (showNotification)
      //   showNotification(
      //     "Permission Updated Successfully",
      //     "success",
      //   );
      if (onSuccessCb) onSuccessCb();
    },
    onError: (err: any) => {
      if (err?.response?.status && showNotification)
        showNotification(
          `${err?.response?.status ?? "ERROR"}: ${
            err?.response?.statusText ?? "UNKNOWN ERROR"
          }`,
          "error",
          String(err?.response?.data ?? "Error Updating Permissions").substring(
            0,
            300
          )
        );
    },
  });

  const deletePermission = useMutation({
    mutationFn: async (
      permission: DocketPermission | ProjectPermission | CompanyPermission
    ) => {
      let data: any = { user: permission.user };
      if (permission.docket) data.docket = permission.docket;
      if (permission.project) data.project = permission.project;
      if (permission.company) data.company = permission.company;
      return DoxleAPI.post(mutationURL, data, {
        headers: {
          "User-Company": company?.companyId || "",
        },
      });
    },
    onSuccess: (result, variables, context) => {
      const type = variables?.docket
        ? "DOCKET"
        : variables?.project
        ? "PROJECT"
        : "COMPANY";
      queryClient.setQueryData(
        [
          "permissions-list",
          variables?.docket ?? variables?.project ?? company?.companyId,
        ],
        (oldData: any) => {
          return oldData
            ? {
                ...oldData,
                pages: oldData.pages.map((page: any, index: number) => ({
                  ...page,
                  data: {
                    ...page.data,
                    results: [
                      ...page.data.results.filter(
                        (
                          oldPermission:
                            | DocketPermission
                            | ProjectPermission
                            | CompanyPermission
                        ) =>
                          oldPermission.contact.contactId !==
                          variables.contact.contactId
                      ),
                    ],
                  },
                })),
              }
            : oldData;
        }
      );

      // if (showNotification)
      //   showNotification(
      //     "Permission Removed Successfully",
      //     "success",
      //   );
      if (onSuccessCb) onSuccessCb();
    },
    onError: (err: any) => {
      if (err?.response?.status && showNotification)
        showNotification(
          `${err?.response?.status ?? "ERROR"}: ${
            err?.response?.statusText ?? "UNKNOWN ERROR"
          }`,
          "error",
          String(err?.response?.data ?? "Error Removing Permissions").substring(
            0,
            300
          )
        );
    },
  });

  return {
    updatePermission,
    deletePermission,
  };
};

const PermissionsAPI = {
  useRetrieveUserPermissionsQuery,
  useRetrieveContactsPermissionsQuery,
  useRetrieveRelatedPermissionsContactsQuery,
  useMutatePermission,
};
export default PermissionsAPI;
