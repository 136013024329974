import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import { useEditOrderStore } from "../../OrdersStore/useEditOrderStore";
import {
  StyledEditOrderErrorText,
  StyledEditOrderLabelField,
  StyledEditOrderTextfield,
  StyledEditOrderWrapperField,
} from "./StyledComponentEditOrder";
import { AnimatePresence } from "framer-motion";
import useEditDescriptionOrder from "./Hooks/useEditDescriptionOrder";

type Props = {};

const EditDescriptionOrder = (props: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }),
    shallow
  );
  const readOnly = useEditOrderStore((state) => state.readOnly, shallow);
  const {
    handleBlurDescriptionTextField,
    descriptionInput,
    setDescriptionInput,
    handleOrderDescription,
  } = useEditDescriptionOrder({});
  const inputRef = useRef<HTMLInputElement>(null);
  const wrapStyle: React.CSSProperties = useMemo(() => ({ flex: 1 }), []);
  return (
    <StyledEditOrderWrapperField style={wrapStyle} layout="position">
      <StyledEditOrderLabelField
        $themeColor={doxleThemeColor}
        $doxleFont={doxleFont}
      >
        Description
      </StyledEditOrderLabelField>

      <StyledEditOrderTextfield
        $themeColor={doxleThemeColor}
        $doxleFont={doxleFont}
        value={descriptionInput}
        disabled={readOnly}
        variant="standard"
        placeholder="Description"
        onChange={(event) => handleOrderDescription(event.target.value)}
        onBlur={handleBlurDescriptionTextField}
        inputRef={inputRef}
        onKeyDown={(e) => {
          if (e.nativeEvent.key === "Enter") {
            e.preventDefault();

            inputRef.current?.blur();
          }
        }}
        onFocus={(e) => e.target.select()}
      />
      <AnimatePresence>
        {!descriptionInput && !readOnly && (
          <StyledEditOrderErrorText
            $themeColor={doxleThemeColor}
            $doxleFont={doxleFont}
            initial={{
              x: -10,
              opacity: 0,
            }}
            animate={{ x: 0, opacity: 1 }}
            exit={{
              x: -10,
              opacity: 0,
            }}
          >
            Description can't be empty!
          </StyledEditOrderErrorText>
        )}
      </AnimatePresence>
    </StyledEditOrderWrapperField>
  );
};

export default EditDescriptionOrder;
