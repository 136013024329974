import {
  StyledDoxleDialogHelperActions,
  StyledDoxleDialogHelperContainer,
  StyledDoxleDialogHelperContent,
  StyledDoxleDialogHelperEditButton,
  StyledDoxleDialogHelperText,
  StyledDoxleDialogHelperTitle
} from "../../../DoxleDesignPattern/DoxleDialogHelper/StyledComponentDoxleDialogHelper";
import {editRgbaAlpha} from "../../../Utilities/FunctionUtilities";
import React, {useCallback, useEffect, useState} from "react";
import {useDoxleThemeStore} from "../../../DoxleGeneralStore/useDoxleThemeStore";
import {useShallow} from "zustand/react/shallow";
import {useEditOrderStore} from "../../OrdersStore/useEditOrderStore";
import {
  StyledDroppedFileItem, StyledDroppedFileItemButton, StyledDroppedFileItemFileInfo, StyledDroppedFileItemTextSpan,
  StyledDropzone,
  StyledDropzoneSection,
  StyledHeadTitleDropzone
} from "../SubmitPaymentClaim/StyledPaymentClaimComponents";
import {DropEvent, FileRejection, useDropzone} from "react-dropzone";
import {InvoiceFileRemoveIcon, InvoicePDFIcon} from "../SubmitPaymentClaim/Icons";
import {StyledAdornment, StyledDialogInput, StyledDialogInputContent} from "./StyledComponentEditOrder";
import paymentClaimAPI from "../../QueryHooks/PaymentClaimAPI";
import {useDoxleCurrentContextStore} from "../../../DoxleGeneralStore/useDoxleCurrentContext";
import useDoxleNotificationStore from "../../../DoxleGeneralStore/useDoxleNotificationStore";

const SubmitPaymentDialog = () => {
  const {order, setShowPaymentOptions, setOrder} = useEditOrderStore(useShallow(state => ({
    setShowPaymentOptions: state.setShowPaymentOptions,
    order: state.editedOrder,
    setOrder: state.setEditedOrder
  })));
  const maxValue = parseFloat(String(order?.total) ?? '0') - parseFloat(order?.totalApprovedPayments ?? '0');
  const maxPercentage = maxValue / parseFloat(String(order?.total) ?? '0') * 100
  const [addedFiles, setAddedFiles] = useState<File[]>([]);
  const [percentageInput, setPercentageInput] = useState<boolean>(false);
  const [invoiceRef, setInvoiceRef] = useState<string>('');
  const [value, setValue] = useState<number|''>(maxValue);
  const [showErrors, setShowErrors] = useState<[null|string, null|string, null|string]>([null, null, null]);
  const styleProps = useDoxleThemeStore(useShallow(state => ({
    $themeColor: state.doxleThemeColor,
    $doxleFont: state.doxleFont
  })));
  const company = useDoxleCurrentContextStore(useShallow(state => state.currentCompany));
  const showNotification = useDoxleNotificationStore(useShallow(state => state.showNotification));
  const onDrop = useCallback(
    <T extends File>(
      acceptedFiles: T[],
      fileRejections: FileRejection[],
      event: DropEvent
    ) => {
      setAddedFiles(prev => [...prev, ...acceptedFiles]);
      setShowErrors(prev => [null, prev[1], prev[2]])
    },
    [addedFiles]
  );
  const { getRootProps } = useDropzone({
    onDrop,
    accept: { "application/pdf": [".pdf"],},
  });

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => e.target.select();
  const numberRegex = /^(\d|\.)$/;
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = parseFloat(e.target.value);
    if (!isNaN(val) && (
      (val <= maxValue && !percentageInput) || (val <= maxPercentage && percentageInput)
    )) {
      setValue(val);
      setShowErrors(prev => [prev[0], prev[1], null]);
    }
    else if (!isNaN(val) && (
      (val > maxValue && !percentageInput) || (val > maxPercentage && percentageInput)
    )) setShowErrors(prev => [
      prev[0],
      prev[1],
      `You cannot set a value greater than the outstanding amount (${percentageInput ? maxPercentage+'%' : '$'+maxValue})`
    ]);
    else if (e.target.value === '') setValue('')
    else if (isNaN(val)) setShowErrors(prev => [prev[0], prev[1], 'Amount must be a number']);
  }
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    console.log(e.key)
    if (e.key === '%') {
      setPercentageInput(true);
      setValue(prev => typeof prev === 'number' && prev > maxPercentage ? maxPercentage : prev);
    }
    if (e.key === '$') {
      setPercentageInput(false);
      setValue(prev => typeof prev === 'number' && prev > maxValue  ? maxValue : prev);
    }
    if (!(numberRegex.test(e.key)||e.key === 'Backspace'||e.key === 'Delete')) {
      e.preventDefault();
      e.stopPropagation();
    }
  }
  const handleSuccess = () => {
    if(order) setOrder({
      ...order,
      totalApprovedPayments: String(Number(order.totalApprovedPayments) + Number(value)),
      totalOutstanding: String(Number(order.totalOutstanding) - Number(value)),
    })
    setShowPaymentOptions(false)
  }
  const mutateQuery = paymentClaimAPI.useMutateQuery({
    company,
    showNotification,
    filter: {project: order?.project},
    onSuccessCb: handleSuccess
  })
  const handleSubmit = (status?:'SUBMITTED'|'AUTHORISED') => {
    let errors = showErrors
    if (!order) return
    if (addedFiles.length === 0) errors.splice(0, 1, 'Attach an invoice file');
    if (invoiceRef.length === 0) errors.splice(1, 1, 'Please enter an Invoice Reference');
    if (value === '' || value === 0) errors.splice(2, 1, 'Please enter a valid Amount');
    if (errors.some(err => err !== null)) setShowErrors([...errors])
    else mutateQuery.addPaymentClaim.mutate({
      order: order.orderId,
      amount: percentageInput ? Number(value) / 100 * parseFloat(String(order.total)) : Number(value),
      invoiceNumber: invoiceRef,
      files: addedFiles,
      status
    });
  }

  useEffect(()=>{
    console.log('addedFiles', addedFiles)
  },[addedFiles])
  return (
    <StyledDoxleDialogHelperContainer
      open={true}
      {...styleProps}
      PaperProps={{
        sx: {
          bgColor: styleProps.$themeColor.primaryContainerColor,
          borderRadius: "0.9rem",
          border: `1px solid ${styleProps.$themeColor.primaryDividerColor}`,
        },
        elevation: 2,
      }}
      slotProps={{
        backdrop: {
          sx: {
            bgcolor: editRgbaAlpha({
              rgbaColor: styleProps.$themeColor.primaryBackgroundColor,
              alpha: "0.8",
            }),
          },
        },
      }}
    >
      <StyledDoxleDialogHelperTitle {...styleProps}>
        Submit for Payment
      </StyledDoxleDialogHelperTitle>

      <StyledDoxleDialogHelperContent {...styleProps}>
        <StyledDoxleDialogHelperText {...styleProps}>
          <span>
            Create a bill in xero
          </span>
        </StyledDoxleDialogHelperText>

      </StyledDoxleDialogHelperContent>

      <StyledDoxleDialogHelperContent {...styleProps}>
        <StyledDropzoneSection
          $themecolor={styleProps.$themeColor}
          $doxleFont={styleProps.$doxleFont}
          $width="100%"
          $height="50px"
          $borderRadius="3px"
        >
          <StyledDropzone {...getRootProps({ $themecolor: styleProps.$themeColor })}>
            <StyledHeadTitleDropzone>
              Drag & drop your invoice attachment(s)
            </StyledHeadTitleDropzone>
          </StyledDropzone>
        </StyledDropzoneSection>
        {addedFiles.map((file, index) => (
          <StyledDroppedFileItem key={`droppedFile#${index}`}>
            <StyledDroppedFileItemFileInfo>
              <InvoicePDFIcon />
              <StyledDroppedFileItemTextSpan widthInPixel={null}>
                {file.name}
              </StyledDroppedFileItemTextSpan>
              <StyledDroppedFileItemTextSpan widthInPixel={100}>
                {(file.size * 0.000001).toFixed(2)}Mb
              </StyledDroppedFileItemTextSpan>
              <StyledDroppedFileItemButton
                onClick={() =>
                  setAddedFiles((prevState) => {
                    prevState.splice(index, 1);
                    return [...prevState];
                  })
                }
              >
                <InvoiceFileRemoveIcon />
              </StyledDroppedFileItemButton>
            </StyledDroppedFileItemFileInfo>
          </StyledDroppedFileItem>
        ))}
        {showErrors[0] && <span style={{color: 'red'}}>{showErrors[0]}</span>}
      </StyledDoxleDialogHelperContent>
      <StyledDialogInputContent {...styleProps}>
        Invoice Number / Reference
          <StyledDialogInput
            value={invoiceRef}
            onFocus={handleFocus}
            onChange={(e) => {
              setInvoiceRef(e.target.value);
              setShowErrors(prev => [prev[0], e.target.value ? null : 'Please enter a reference', prev[2]])
            }}
          />
        {showErrors[1] && <span style={{color: 'red'}}>{showErrors[1]}</span>}
      </StyledDialogInputContent>
      <StyledDialogInputContent {...styleProps}>
        Invoice Amount
        <div style={{width: '100%', display: 'flex', flexDirection: 'row'}}>
          <StyledAdornment
            $selected={!percentageInput}
            $first
            onClick={()=> {
              setPercentageInput(false);
              setValue(maxValue);
            }}
          >$</StyledAdornment>
          <StyledDialogInput
            value={value}
            onFocus={handleFocus}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
          />
          <StyledAdornment
            $selected={percentageInput}
            onClick={()=> {
              setPercentageInput(true);
              setValue(maxPercentage);
            }}
          >%</StyledAdornment>
        </div>
        {showErrors[2] && <span style={{color: 'red'}}>{showErrors[2]}</span>}
      </StyledDialogInputContent>

      <StyledDoxleDialogHelperActions {...styleProps}>
          <StyledDoxleDialogHelperEditButton
            key={`helperBtn#0`}
            className={`doxleDialogBtn-0-submitPayment`}
            {...styleProps}
            $buttonIndex={0}
            onClick={() => setShowPaymentOptions(false)}
            layout
          >
            <span className="button-text">
              Cancel
            </span>
          </StyledDoxleDialogHelperEditButton>

        <StyledDoxleDialogHelperEditButton
          key={`helperBtn#0`}
          className={`doxleDialogBtn-0-submitPayment`}
          {...styleProps}
          $buttonIndex={0}
          onClick={() => handleSubmit('SUBMITTED')}
          $bgColor={editRgbaAlpha({rgbaColor: styleProps.$themeColor.doxleColor, alpha: '0.7'})}
          layout
        >
            <span className="button-text">
              Submit For Approval
            </span>
        </StyledDoxleDialogHelperEditButton>

        <StyledDoxleDialogHelperEditButton
          key={`helperBtn#0`}
          className={`doxleDialogBtn-0-submitPayment`}
          {...styleProps}
          $buttonIndex={0}
          $bgColor={editRgbaAlpha({rgbaColor: styleProps.$themeColor.doxleColor, alpha: '0.7'})}
          onClick={() => handleSubmit('AUTHORISED')}
          layout
        >
            <span className="button-text">
              Submit And Approve
            </span>
        </StyledDoxleDialogHelperEditButton>
      </StyledDoxleDialogHelperActions>
    </StyledDoxleDialogHelperContainer>
  )
}

export default SubmitPaymentDialog
