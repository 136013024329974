import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import { useDoxleCurrentContextStore } from "../../../DoxleGeneralStore/useDoxleCurrentContext";
import useDoxleNotificationStore from "../../../DoxleGeneralStore/useDoxleNotificationStore";
import { useEstimatesStore } from "../Store/useEstimatesStore";
import EstimatesQueryAPI from "../../QueryHooks/EstimatesQueryAPI";
import { useEffect, useMemo } from "react";
import DoxleListSkeleton from "../../../DoxleDesignPattern/DoxleSkeleton/DoxleListSkeleton";
import { StyledPageContainer } from "../StyledComponents";
import QuickEstimateTable from "./QuickEstimateTable";
import { Estimate } from "../../Models/estimatesFilters";
import DocketsQueryAPI from "../../QueryHooks/DocketQueryAPI";
import {
  Docket,
  IFullDocketDetailQueryFilterProp,
} from "../../../Models/dockets";

const EstimatePage = () => {
  const doxleThemeColor = useDoxleThemeStore(
    (state) => state.doxleThemeColor,
    shallow
  );
  const company = useDoxleCurrentContextStore(
    (state) => state.currentCompany,
    shallow
  );
  const project = useDoxleCurrentContextStore(
    (state) => state.currentProject,
    shallow
  );
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );
  const { setEstimateList, estimateQueryFilter } = useEstimatesStore(
    (state) => ({
      setEstimateList: state.setEstimateList,
      estimateQueryFilter: state.estimatesQueryFilter,
    }),
    shallow
  );

  const docketFilter: IFullDocketDetailQueryFilterProp = {
    search: estimateQueryFilter?.search,
    project: project?.projectId,
    view: "budget",
  };

  const docketQuery = DocketsQueryAPI.useRetrieveDocketList({
    company,
    showNotification,
    filter: docketFilter,
  });

  const estimateQuery = EstimatesQueryAPI.useRetrieveEstimatesList({
    company,
    showNotification,
    filter: estimateQueryFilter,
  });

  const estimateList: Estimate[] = useMemo(
    () =>
      estimateQuery.data?.pages
        ? estimateQuery.data?.pages.flatMap(
            (page: any) => page?.data?.results ?? []
          ) ?? []
        : [],
    [estimateQuery.data]
  );

  const docketList: Docket[] = useMemo(
    () =>
      docketQuery.data?.pages
        ? docketQuery.data?.pages.flatMap(
            (page: any) => page?.data?.results ?? []
          ) ?? []
        : [],
    [docketQuery.data]
  );

  const handleFetchNextEstimatePage = () => {
    if (docketQuery.hasNextPage) docketQuery.fetchNextPage();
  };

  useEffect(() => {
    if (
      estimateQuery.isSuccess &&
      !estimateQuery.isFetching &&
      !estimateQuery.isFetchingNextPage &&
      estimateQuery.hasNextPage
    )
      estimateQuery.fetchNextPage();
    // if (estimateQuery.isSuccess) setEstimateList(estimateList)
  }, [
    estimateList,
    setEstimateList,
    estimateQuery.isFetching,
    estimateQuery.isFetchingNextPage,
    estimateQuery.isSuccess,
    estimateQuery.hasNextPage,
  ]);

  return (
    <StyledPageContainer themeColor={doxleThemeColor}>
      {docketQuery.isError && <>Error fetching the data.</>}
      {docketQuery.isLoading && <DoxleListSkeleton skeletonType={"orderRow"} />}
      {docketQuery.isSuccess && (
        <QuickEstimateTable
          docketList={docketList}
          estimateList={estimateList}
          handleFetchNext={handleFetchNextEstimatePage}
        />
      )}
    </StyledPageContainer>
  );
};

export default EstimatePage;
