import useDoxleNotificationStore from "../../../DoxleGeneralStore/useDoxleNotificationStore";
import { shallow } from "zustand/shallow";
import { useDoxleCurrentContextStore } from "../../../DoxleGeneralStore/useDoxleCurrentContext";
import { useCallback, useMemo, useState } from "react";
import useStatusColorPickerStore from "../Store/StatusColorPickerStore";
import { TRgbaFormat } from "../../../Utilities/FunctionUtilities";
import { DocketStatus } from "../../../Models/dockets";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { OrderStatus } from "../../../Orders/Models/order";
import { ProjectStatus } from "../../../Models/project";
import OrdersQueryAPI from "../../../Orders/QueryHooks/OrdersQueryAPI";
import useSetStatusQueryData from "./useSetStatusQueryData";

interface StatusMenu {
  status: OrderStatus;
  anchorEl: SVGElement;
}
const useOrderStatuses = () => {
  const [statusMenu, setStatusMenu] = useState<StatusMenu | null>(null);
  const [deleteStatusDialog, setDeleteStatusDialog] =
    useState<OrderStatus | null>(null);
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );
  const company = useDoxleCurrentContextStore(
    (state) => state.currentCompany,
    shallow
  );
  const retrieveOrderStatusQuery = OrdersQueryAPI.useRetrieveOrderStatusList({
    showNotification,
    company,
  });
  const doxleColor = useDoxleThemeStore(
    (state) => state.doxleThemeColor.doxleColor,
    shallow
  );
  const openColorPicker = useStatusColorPickerStore(
    (state) => state.openPicker,
    shallow
  );
  const mutateOrderStatusQuery = OrdersQueryAPI.useMutateOrderStatus();
  const orderStatusList = useMemo(() => {
    return retrieveOrderStatusQuery.isSuccess
      ? retrieveOrderStatusQuery.data.data
      : [];
  }, [retrieveOrderStatusQuery.data, company]);

  const handleStatusColorClick = (
    e: React.MouseEvent<HTMLDivElement>,
    status: OrderStatus
  ) => {
    openColorPicker({
      anchorEl: e.currentTarget,
      handleSave: (newColor: TRgbaFormat) =>
        mutateOrderStatusQuery.updateStatus({
          statusId: status.statusId,
          updateStatus: { color: newColor },
        }),
      color: status.color ?? "rgba(0,0,0,1)",
    });
  };
  const handleKebabIconClick = (
    e: React.MouseEvent<SVGElement>,
    status: OrderStatus
  ) => {
    setStatusMenu({
      anchorEl: e.currentTarget,
      status: status,
    });
  };
  const showDropDown = useMemo(() => Boolean(statusMenu), [statusMenu]);

  const handleDelete = (status: OrderStatus) => {
    console.log(status.totalCount);
    if (!status.totalCount)
      mutateOrderStatusQuery.deleteStatus({
        statusId: status.statusId,
      });
    else setDeleteStatusDialog(status);
  };
  const handleDeleteConfirmed = (newStatusId: string | null) => {
    if (deleteStatusDialog)
      mutateOrderStatusQuery.deleteStatus({
        statusId: deleteStatusDialog.statusId,
        newId: newStatusId,
      });
    setDeleteStatusDialog(null);
  };

  interface Position {
    $top: number;
    $left: number;
  }
  const dropDownPosition: Position = useMemo(() => {
    const boundingRect = statusMenu?.anchorEl.getBoundingClientRect();
    if (!boundingRect) return { $top: 0, $left: 0 };
    return {
      $top: (boundingRect.top + boundingRect.bottom) / 2,
      $left: (boundingRect.left + boundingRect.right) / 2,
    };
  }, [statusMenu]);
  interface StatusDropDownOption {
    text: string;
    handleClick: () => void;
    disabled: boolean;
    selected?: boolean;
  }
  const orderStatusDropdownOptions: StatusDropDownOption[] = useMemo(() => {
    if (!statusMenu?.status) return [];
    return [
      {
        text: "Set Status to Default",
        disabled: statusMenu.status?.isDefault,
        handleClick: () => {
          mutateOrderStatusQuery.updateStatus({
            statusId: statusMenu?.status.statusId,
            updateStatus: { isDefault: true },
          });
          setStatusMenu(null);
        },
      },
      {
        text: "Order Status",
        disabled: false,
        selected: Boolean(statusMenu?.status?.isOrderStatus),
        handleClick: () => {
          mutateOrderStatusQuery.updateStatus({
            statusId: statusMenu?.status.statusId,
            updateStatus: {
              isOrderStatus: !Boolean(statusMenu?.status?.isOrderStatus),
            },
          });
          setStatusMenu(null);
        },
      },
      {
        text: "Invoice Status",
        disabled: false,
        selected: Boolean(statusMenu?.status?.isInvoiceStatus),
        handleClick: () => {
          mutateOrderStatusQuery.updateStatus({
            statusId: statusMenu?.status.statusId,
            updateStatus: {
              isInvoiceStatus: !Boolean(statusMenu?.status?.isInvoiceStatus),
            },
          });
          setStatusMenu(null);
        },
      },
      {
        text: "Adds To Totals",
        disabled: false,
        selected: Boolean(statusMenu?.status?.isSummed),
        handleClick: () => {
          mutateOrderStatusQuery.updateStatus({
            statusId: statusMenu?.status.statusId,
            updateStatus: { isSummed: !Boolean(statusMenu?.status?.isSummed) },
          });
          setStatusMenu(null);
        },
      },
      {
        text: "Auto Sign Order",
        disabled: false,
        selected: Boolean(statusMenu?.status?.setSigned),
        handleClick: () => {
          mutateOrderStatusQuery.updateStatus({
            statusId: statusMenu?.status.statusId,
            updateStatus: {
              setSigned: !Boolean(statusMenu?.status?.setSigned),
            },
          });
          setStatusMenu(null);
        },
      },
      {
        text: "Delete Status",
        disabled: statusMenu.status?.isDefault,
        handleClick: () => {
          handleDelete(statusMenu.status);
          setStatusMenu(null);
        },
      },
    ];
  }, [statusMenu?.status]);
  const handleCloseDropDown = () => {
    setStatusMenu(null);
  };

  const handleAddStatus = useCallback(
    (statusName: string) => {
      if (company)
        mutateOrderStatusQuery.addStatus({
          statusName: statusName,
          company: company.companyId,
          index: orderStatusList.length,
          color: doxleColor,
          isDefault: orderStatusList.length === 0,
          isInvoiceStatus: true,
          isOrderStatus: true,
          isSummed: false,
          setSigned: false,
        });
    },
    [company, mutateOrderStatusQuery, orderStatusList.length, doxleColor]
  );

  const handleUpdateStatusName = useCallback(
    (
      status: ProjectStatus | DocketStatus | OrderStatus,
      statusName: string
    ) => {
      mutateOrderStatusQuery.updateStatus({
        statusId: status.statusId,
        updateStatus: { statusName: statusName },
      });
    },
    [mutateOrderStatusQuery]
  );

  const { handleUpdateStatusOrder } = useSetStatusQueryData();

  const handleUpdateStatusIndex = (
    status: OrderStatus,
    source: number,
    dest: number
  ) => {
    handleUpdateStatusOrder(source, dest, [
      "order-status-list",
      company?.companyId ?? "",
    ]);
    mutateOrderStatusQuery.updateStatusIndex({
      statusId: status.statusId,
      source: source,
      dest: dest,
    });
  };

  return {
    orderStatusList,
    mutateOrderStatusQuery,
    handleStatusColorClick,
    handleKebabIconClick,
    showDropDown,
    dropDownPosition,
    orderStatusDropdownOptions,
    handleCloseDropDown,
    handleUpdateStatusName,
    handleAddStatus,
    deleteStatusDialog,
    handleDeleteConfirmed,
    setDeleteStatusDialog,
    handleUpdateStatusIndex,
    retrieveOrderStatusQuery,
  };
};

export default useOrderStatuses;
