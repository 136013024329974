import { TextField, TextFieldProps, styled } from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import {
  DoxleFont,
  DoxleThemeColor,
  useDoxleThemeStore,
} from "../../DoxleGeneralStore/useDoxleThemeStore";
import { TRgbaFormat, editRgbaAlpha } from "../../Utilities/FunctionUtilities";
export const StyledDoxleTextField = styled(TextField)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  && {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  input {
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-weight: 500;
    font-size: 1.4rem;
    line-height: normal;
    letter-spacing: 0.0075em;

    color: ${(p) => p.$themeColor.primaryFontColor};
  }
  & .MuiInputBase-multiline {
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-weight: 500;
    font-size: 1.4rem;
    line-height: normal;
    letter-spacing: 0.0075em;

    color: ${(p) => p.$themeColor.primaryFontColor};
  }
  & .MuiInput-root:after {
    border-bottom: 1.5px solid ${(p) => p.$themeColor.primaryFontColor};
  }
  & .MuiInput-root {
    border-bottom: 1px solid ${(p) => p.$themeColor.primaryFontColor};
  }
  & .MuiInput-root:hover {
    border-bottom: none !important;
  }
  & .MuiInput-root:hover:not(.Mui-disabled, .Mui-error):before {
    border-bottom: 1px solid
      ${(p) =>
        editRgbaAlpha({
          rgbaColor: p.$themeColor.primaryFontColor as TRgbaFormat,
          alpha: "0.4",
        })};
  }
  & .MuiInputLabel-root.Mui-focused {
    color: ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.$themeColor.primaryFontColor as TRgbaFormat,
        alpha: "0.4",
      })};
    font-size: 1.2rem !important;
  }
  & .MuiInputLabel-root {
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 110%;
    letter-spacing: 0.0075em;
    transition: 0.4s !important;
    color: ${(p) => p.$themeColor.primaryInputPlaceholderColor};
  }
`;
interface Props extends Omit<TextFieldProps<"standard">, "label" | "variant"> {
  labelText?: string;
  valueText?: string;
  setValue?: (value: string) => void;
  variant?: "standard" | "outlined" | "filled";
}

const DoxleTextField = ({
  labelText,
  setValue,
  valueText,
  variant,
  ...rest
}: Props) => {
  const [textQuery, setTextQuery] = useState<string>(valueText || "");
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore((state) => ({
    doxleThemeColor: state.doxleThemeColor,
    doxleFont: state.doxleFont,
  }));

  useEffect(() => {
    const timeOut = setTimeout(() => {
      if (setValue) setValue(textQuery);
    }, 500);

    return () => clearTimeout(timeOut);
  }, [textQuery]);

  useEffect(() => {
    if (valueText && valueText !== textQuery) {
      setTextQuery(valueText);
    }
  }, [valueText]);
  return (
    <StyledDoxleTextField
      variant="standard"
      $themeColor={doxleThemeColor}
      $doxleFont={doxleFont}
      {...rest}
      value={rest.value ? rest.value : valueText}
      onChange={
        rest.onChange
          ? rest.onChange
          : (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
              setTextQuery(event.target.value)
      }
      label={labelText}
    />
  );
};

export default DoxleTextField;
