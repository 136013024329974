import { RgbaColorPicker } from "react-colorful";
import { useEffect, useMemo, useState } from "react";
import useStatusColorPickerStore from "../Store/StatusColorPickerStore";
import { shallow } from "zustand/shallow";
import {
  StyledColorPickerButton,
  StyledStatusPicker,
} from "../styledComponents";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { ClickAwayListener, Popover } from "@mui/material";
import ColorPicker, { useColorPicker } from "react-best-gradient-color-picker";
import { TRgbaFormat } from "../../../Utilities/FunctionUtilities";
import useEffectAfterMount from "../../UtilityHooks/useEffectAfterMount";
import { useShallow } from "zustand/react/shallow";

interface RGBAColor {
  r: number;
  g: number;
  b: number;
  a: number;
}

interface Position {
  $top: number;
  $left: number;
}
const StatusColorPicker = () => {
  const { anchorEl, initColor, handleSave, handleClose } =
    useStatusColorPickerStore(
      useShallow((state) => ({
        anchorEl: state.anchorEl,
        initColor: state.color,
        handleSave: state.handleSave,
        handleClose: state.handleClose,
      }))
    );
  const [color, setColor] = useState<string>(initColor ?? "rgba(82,82,255,1)");
  const canBeOpen = Boolean(anchorEl);
  const id = canBeOpen ? "status-change-popover" : undefined;
  const { rgbaArr } = useColorPicker(color, setColor);
  const styleProps = useDoxleThemeStore((state) => ({
    $themeColor: state.doxleThemeColor,
    $doxleFont: state.doxleFont,
  }));

  const position: Position = useMemo(() => {
    const boundingRect = anchorEl?.getBoundingClientRect();
    if (!boundingRect) return { $top: 0, $left: 0 };
    return {
      $top: (boundingRect.top + boundingRect.bottom) / 2,
      $left: (boundingRect.left + boundingRect.right) / 2,
    };
  }, [anchorEl]);

  const handleSaveButton = () => {
    if (handleSave)
      handleSave(
        `rgba(${rgbaArr[0]},${rgbaArr[1]},${rgbaArr[2]},${rgbaArr[3]})`
      );

    handleClose();
  };

  useEffect(() => {
    if (!initColor) setColor("rgba(82,82,255,1)");
    else setColor(initColor);
  }, [initColor]);

  if (!anchorEl) return null;
  return (
    <Popover
      id={id}
      open={canBeOpen}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      sx={{
        "& .MuiPopover-paper": {
          backgroundColor: "transparent",
          overflow: "visible",
          boxShadow: "none",
          border: `1px solid ${styleProps.$themeColor.primaryDividerColor}`,
        },
      }}
      elevation={8}
      onClose={handleClose}
    >
      <StyledStatusPicker>
        <ColorPicker
          width={250}
          height={200}
          value={color}
          onChange={setColor}
          presets={[
            "rgba(255,255,255,1)",
            "rgba(205,44,85,1)",
            "rgba(255,239,94,1)",
            "rgba(0,0,0,1)",
            "rgba(194,194,194,1)",
            "rgba(81,102,243,1)",
            "rgba(255,175,36,1)",
            "rgba(34,255,160,1)",
            "rgba(217,92,254,1)",
          ]}
          hideGradientType
          hideEyeDrop
          hideGradientAngle
          hideGradientControls
          hideGradientStop
          hideColorTypeBtns
          hideControls
        />
        <StyledColorPickerButton onClick={handleSaveButton} {...styleProps}>
          Save
        </StyledColorPickerButton>
      </StyledStatusPicker>
    </Popover>
  );
};
export default StatusColorPicker;
