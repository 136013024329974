import React, { useEffect, useMemo, useState } from "react";
import { useBudgetStore } from "../../Store/useBudgetStore";
import { useLocation } from "react-router-dom";

type Props = {};

type RemoveFilterOption = "Due Date" | "Over Budget" | "Status" | "Person";
interface BudgetHeaderHook {
  searchInput: string;
  handleRemoveFilter: (filterType: RemoveFilterOption) => void;
  handleSearchInputTextChange: (value: string) => void;
  mainFilterAnchor: HTMLElement | null;
  statusFilterAnchor: HTMLElement | null;
  setMainFilterAnchor: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
  setStatusFilterAnchor: React.Dispatch<
    React.SetStateAction<HTMLElement | null>
  >;
}
const useBudgetHeader = (props: Props): BudgetHeaderHook => {
  const [searchInput, setSearchInput] = useState("");

  // Anchor Element for Main Filter Menu Dropdown
  const [mainFilterAnchor, setMainFilterAnchor] =
    React.useState<null | HTMLElement>(null);

  // Anchor Element for Status Filter Menu Dropdown
  const [statusFilterAnchor, setStatusFilterAnchor] =
    React.useState<null | HTMLElement>(null);

  // Stores
  const { filterDocketListQuery, setFilterDocketListQuery } = useBudgetStore(
    (state) => ({
      filterDocketListQuery: state.filterDocketListQuery,
      setFilterDocketListQuery: state.setFilterDocketListQuery,
    })
  );

  const location = useLocation();

  const currentRouteName = location.pathname.replace("/", "");

  const handleSearchInputTextChange = (value: string) => setSearchInput(value);

  const handleRemoveFilter = (filterType: RemoveFilterOption) => {
    if (filterType === "Due Date") {
      setFilterDocketListQuery({ ...filterDocketListQuery, due: undefined });
    }
    if (filterType === "Over Budget") {
      setFilterDocketListQuery({ ...filterDocketListQuery, budget: undefined });
    }
    if (filterType === "Status") {
      setFilterDocketListQuery({ ...filterDocketListQuery, status: undefined });
    }
    if (filterType === "Person") {
      setFilterDocketListQuery({
        ...filterDocketListQuery,
        ball_in_court: undefined,
      });
    }
  };

  return {
    searchInput,

    handleRemoveFilter,
    handleSearchInputTextChange,
    mainFilterAnchor,
    statusFilterAnchor,
    setMainFilterAnchor,
    setStatusFilterAnchor,
  };
};

export default useBudgetHeader;
