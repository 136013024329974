import React, { useEffect } from "react";
import { useProjectQAStore } from "../../Store/useProjectQAStore";
import { shallow } from "zustand/shallow";
import { TransitionProps } from "@mui/material/transitions";
import Zoom from "@mui/material/Zoom";
import Dialog from "@mui/material/Dialog";
import { useDoxleThemeStore } from "../../../../DoxleGeneralStore/useDoxleThemeStore";
import {
  StyledPdfPage,
  StyledQAShareDialogActions,
  StyledQAShareDialogContent,
  StyledQAShareDialogTitle,
  StyledQASharedDialog,
  StyledSharedMenuWrapper,
  StyledSharedPdfContentSection,
} from "./StyledComponentQASharePdf";
import { editRgbaAlpha } from "../../../../Utilities/FunctionUtilities";
import DoxleCloseBtn from "../../../../DoxleDesignPattern/DoxleButtons/DoxleCloseBtn";
import { Page, pdfjs, Document } from "react-pdf";
import useQASharePdf from "./Hooks/useQASharePdf";
import LinearProgress from "@mui/material/LinearProgress";
import { FaAngleLeft } from "react-icons/fa6";
import { FaAngleRight } from "react-icons/fa6";
import "./sharePDf.css";
import { useQueryClient } from "@tanstack/react-query";
import { formQAListPDFQKey } from "../../../QueryAPI/qaQueryAPI";
import SelectSharedAssignee from "./SelectSharedAssignee";
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import "react-pdf/dist/esm/Page/TextLayer.css";
import { useDoxleCurrentContextStore } from "../../../../DoxleGeneralStore/useDoxleCurrentContext";
import { useShallow } from "zustand/react/shallow";

// Brought a copy of the pdf.worker into public asbuilding a URL to the dist folder would throw an error in production
pdfjs.GlobalWorkerOptions.workerSrc = "/workers/pdf.worker.js";

// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//   "pdfjs-dist/build/pdf.worker.min.js",
//   import.meta.url
// ).toString();

type Props = {};

const QASharePdf = (props: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    useShallow((state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }))
  );
  const { currentProject } = useDoxleCurrentContextStore(
    useShallow((state) => ({
      currentProject: state.currentProject,
    }))
  );
  const queryClient = useQueryClient();

  const {
    pdfPath,
    isGeneratingPdf,
    pageNumber,
    setPageNumber,
    onDocumentLoadSuccess,
    numPages,
    handleGenerateAssigneePdf,
    isGeneratingAssigneePdf,
  } = useQASharePdf({});

  const { sharedQAList, setSharedQAList } = useProjectQAStore(
    useShallow((state) => ({
      sharedQAList: state.sharedQAList,
      setSharedQAList: state.setSharedQAList,
    }))
  );
  const qKey = formQAListPDFQKey(sharedQAList?.defectListId ?? "");
  const openDialog = Boolean(sharedQAList);

  return (
    <StyledQASharedDialog
      TransitionComponent={Transition}
      open={openDialog}
      onClose={() => {
        queryClient.invalidateQueries(qKey);
        setSharedQAList(undefined);
      }}
      PaperProps={{
        sx: {
          bgColor: doxleThemeColor.primaryContainerColor,
          borderRadius: "0.9rem",
        },
        elevation: 4,
      }}
      slotProps={{
        backdrop: {
          sx: {
            bgcolor: editRgbaAlpha({
              rgbaColor: doxleThemeColor.primaryBackgroundColor,
              alpha: "0.4",
            }),
          },
        },
      }}
      aria-describedby="share-qa-pdf-dialog"
    >
      <StyledQAShareDialogTitle>
        Share QA PDF
        <DoxleCloseBtn
          onClick={() => {
            queryClient.invalidateQueries(qKey);
            setSharedQAList(undefined);
          }}
          sx={{
            width: "40px",
            height: "40px",
            borderRadius: "50%",
            padding: "2px !important",
          }}
        />
      </StyledQAShareDialogTitle>

      <StyledQAShareDialogContent $themeColor={doxleThemeColor}>
        <SelectSharedAssignee
          handleGenerateAssigneePdf={handleGenerateAssigneePdf}
        />
        <StyledSharedPdfContentSection>
          {(isGeneratingPdf || isGeneratingAssigneePdf) && (
            <>
              <LinearProgress
                className="loader-bar"
                sx={{
                  "&.MuiLinearProgress-root": {
                    backgroundColor: editRgbaAlpha({
                      rgbaColor: doxleThemeColor.doxleColor,
                      alpha: "0.3",
                    }),
                  },
                  "& .MuiLinearProgress-bar": {
                    backgroundColor: doxleThemeColor.doxleColor,
                  },
                }}
              />
              <span className="loader-text">
                Generating PDF File, please wait...
              </span>
            </>
          )}

          {!isGeneratingPdf && !isGeneratingAssigneePdf && (
            <>
              {pdfPath && (
                <>
                  <Document
                    file={pdfPath}
                    onLoadSuccess={onDocumentLoadSuccess}
                  >
                    <Page
                      pageNumber={pageNumber}
                      renderTextLayer={false}
                      renderAnnotationLayer={false}
                    />
                  </Document>

                  <div className="page-switcher">
                    <FaAngleLeft
                      size="2rem"
                      color={doxleThemeColor.primaryFontColor}
                      onClick={() =>
                        setPageNumber((prev) => (prev > 1 ? prev - 1 : prev))
                      }
                      className="icon-switcher"
                    />
                    <span className="page-num-text">Page {pageNumber}</span>

                    <FaAngleRight
                      size="2rem"
                      color={doxleThemeColor.primaryFontColor}
                      onClick={() =>
                        setPageNumber((prev) =>
                          prev < numPages ? prev + 1 : prev
                        )
                      }
                      className="icon-switcher"
                    />
                  </div>
                </>
              )}
            </>
          )}
        </StyledSharedPdfContentSection>
      </StyledQAShareDialogContent>

      <StyledQAShareDialogActions $themeColor={doxleThemeColor}>
        {pdfPath && !isGeneratingPdf && !isGeneratingAssigneePdf && (
          <StyledSharedMenuWrapper
            initial={{ y: -5, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
          >
            <WhatsappShareButton
              url={pdfPath}
              title={`${
                currentProject ? currentProject.siteAddress + "-" : ""
              }QA Action Required - Powered by Doxle`}
              className="share-button"
            >
              <WhatsappIcon
                size="3.5rem"
                round={true}
                iconFillColor={doxleThemeColor.primaryContainerColor}
              />
            </WhatsappShareButton>

            <FacebookShareButton
              url={pdfPath}
              title={`${
                currentProject ? currentProject.siteAddress + "-" : ""
              }QA Action Required - Powered by Doxle`}
              className="share-button"
            >
              <FacebookIcon
                size="3.5rem"
                round={true}
                iconFillColor={doxleThemeColor.primaryContainerColor}
              />
            </FacebookShareButton>

            <EmailShareButton
              url={pdfPath}
              title={`${
                currentProject ? currentProject.siteAddress + "-" : ""
              }QA Action Required - Powered by Doxle`}
              className="share-button"
            >
              <EmailIcon
                size="3.5rem"
                round={true}
                iconFillColor={doxleThemeColor.primaryContainerColor}
              />
            </EmailShareButton>
          </StyledSharedMenuWrapper>
        )}
      </StyledQAShareDialogActions>
    </StyledQASharedDialog>
  );
};

export default QASharePdf;

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Zoom ref={ref} {...props} />;
});
