import React from "react";
import { User } from "../../../Models/user";
import { StyledQRUserItem } from "./StyledQRCommonComponents";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";

type Props = {
  item: User;
  selected: boolean;
  onSelectUser: (user: User) => void;
};

const QRUserItem = ({ item, selected, onSelectUser }: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore((state) => ({
    doxleThemeColor: state.doxleThemeColor,
    doxleFont: state.doxleFont,
  }));
  return (
    <StyledQRUserItem
      onClick={() => onSelectUser(item)}
      animate={{
        color: selected
          ? doxleThemeColor.doxleColor
          : doxleThemeColor.primaryFontColor,
      }}
      transition={{ duration: 0.2 }}
    >
      <div className="info-wrapper">
        <span className="contact-name">
          {item.firstName} {item.lastName}
        </span>
        <span className="email-text">{item.email}</span>
      </div>
    </StyledQRUserItem>
  );
};

export default QRUserItem;
