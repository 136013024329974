import { BaseAPIProps } from "../../Models/baseAPIProps";
import {
  ContactCompanyFilters,
  ContactsFilters,
} from "../../Services/QueryHooks/contactsFilters";
import {
  useInfiniteQuery,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import DoxleAPI from "../../Services/DoxleAPI";
import { useDoxleCurrentContextStore } from "../../DoxleGeneralStore/useDoxleCurrentContext";
import { shallow } from "zustand/shallow";
import { useDoxleAuthStore } from "../../DoxleGeneralStore/useDoxleAuthStore";
import useDoxleNotificationStore from "../../DoxleGeneralStore/useDoxleNotificationStore";
import {
  AxiosBackendErrorReturn,
  IApiPaginatedData,
} from "../../Models/axiosReturn";
import { Contact, ContactCompany } from "../../Models/addressBook";
import { AxiosResponse, isAxiosError } from "axios";
import { formContactCompanyListQKey } from "../../Services/QueryHooks/contactsAPI";
import { Company } from "../../Models/company";
import useSetCompanyContactQueryData from "./useSetCompanyContactQueryData";

interface RetrieveContactsListInterface extends BaseAPIProps {
  filter: ContactsFilters;
}
const useRetrieveContactsList = ({
  company,
  showNotification,
  filter,
}: RetrieveContactsListInterface) => {
  let qKey = ["contacts-list"];
  let docketURL = `/contact/?page=1`;
  for (const [key, value] of Object.entries(filter)) {
    qKey.push(value);
  }
  return useInfiniteQuery(
    qKey,
    ({ pageParam = docketURL }) =>
      DoxleAPI.get<IApiPaginatedData<Contact>>(pageParam, {
        headers: {
          "User-Company": company!.companyId,
        },
        params: filter,
      }),
    {
      enabled: Boolean(company),
      retry: 1,
      // refetchInterval: 2 * 60 * 1000,
      // refetchIntervalInBackground: true,
      refetchOnWindowFocus: false,
      getNextPageParam: (prevData) => prevData.data?.next,
      onError: (err: any) => {
        if (showNotification) {
          if (isAxiosError<AxiosBackendErrorReturn>(err)) {
            showNotification(
              `${err?.response?.status ?? "ERROR"}: ${
                err.response?.data.detail ?? "UNKNOWN ERROR"
              }`,
              "error",
              String(
                err?.response?.data ?? "Error Fetching Contacts List"
              ).substring(0, 300)
            );
          } else {
            showNotification(
              "Something Wrong!",
              "error",
              "Error Fetching Contacts List"
            );
          }
        }
      },
    }
  );
};

interface RetrieveCompaniesListInterface extends BaseAPIProps {
  filter: ContactCompanyFilters;
}
const useRetrieveContactCompanyList = ({
  company,
  showNotification,
  filter,
}: RetrieveCompaniesListInterface) => {
  let qKey = formContactCompanyListQKey(filter, company);
  let docketURL = `/contact/company/?page=1`;

  return useInfiniteQuery(
    qKey,
    ({ pageParam = docketURL }) =>
      DoxleAPI.get(pageParam, {
        headers: {
          "User-Company": company!.companyId,
        },
        params: filter,
      }),
    {
      enabled: Boolean(company),
      retry: 1,
      refetchInterval: 5 * 60 * 1000,
      refetchIntervalInBackground: true,
      staleTime: 4 * 60 * 1000,
      cacheTime: 5 * 60 * 1000,
      refetchOnWindowFocus: false,
      getNextPageParam: (prevData) => prevData.data?.next,
      onError: (err: any) => {
        if (showNotification) {
          if (isAxiosError<AxiosBackendErrorReturn>(err)) {
            showNotification(
              `${err?.response?.status ?? "ERROR"}: ${
                err.response?.data.detail ?? "UNKNOWN ERROR"
              }`,
              "error",
              String(
                err?.response?.data ?? "Error Fetching Contact Company List"
              ).substring(0, 300)
            );
          } else {
            showNotification(
              "Something Wrong!",
              "error",
              "Error Fetching Contact Company List"
            );
          }
        }
      },
    }
  );
};

export type SyncDirections = "D2X" | "X2D" | "BI" | "NONE";
export type DuplicateValueOptions =
  | "Merge"
  | "Override"
  | "Duplicate"
  | "Ignore";
export interface RequiredValues {
  email: boolean;
  phone: boolean;
  address: boolean;
  abn: boolean;
  bank: boolean;
}

interface SyncContactParams {
  required: RequiredValues;
  direction: SyncDirections;
  duplicates: DuplicateValueOptions;
}
interface SyncContactsQueryProps {
  onSuccessCb?: () => void;
}
const useSyncContacts = ({ onSuccessCb }: SyncContactsQueryProps) => {
  const queryClient = useQueryClient();
  const company = useDoxleCurrentContextStore(
    (state) => state.currentCompany,
    shallow
  );

  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );
  return useMutation(
    (syncParams: SyncContactParams) =>
      DoxleAPI.post(`/contact/sync/`, syncParams, {
        headers: {
          "User-Company": company?.companyId,
        },
      }),
    {
      mutationKey: ["xero-sync-contacts"],
      onSuccess: (data: AxiosResponse<any>, variables, context) => {
        queryClient.invalidateQueries(["contacts-list"]);
        queryClient.invalidateQueries(["contact-company-list"]);
        if (onSuccessCb) onSuccessCb();
      },
      onError: (err: any, variables, context) => {
        if (showNotification) {
          if (isAxiosError<AxiosBackendErrorReturn>(err)) {
            showNotification(
              `${err?.response?.status ?? "ERROR"}: ${
                err.response?.data.detail ?? "UNKNOWN ERROR"
              }`,
              "error",
              String(err?.response?.data ?? "Error Syncing Contacts").substring(
                0,
                300
              )
            );
          } else {
            showNotification(
              "Something Wrong!",
              "error",
              "Error Syncing Contacts"
            );
          }
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries(["docket-timelines"]);
      },
    }
  );
};

interface RemoveCompaniesListInterface extends BaseAPIProps {}

const useRemoveContactsCompanyQuery = ({
  company,
  showNotification,
}: RemoveCompaniesListInterface) => {
  const { handleDeleteCompanyContact } = useSetCompanyContactQueryData({
    filter: {},
  });
  const mutation = useMutation({
    mutationFn: async (deleteCompany: { contactCompanyId: string }) => {
      let companiesUrl = "/contact/company/";
      return DoxleAPI.delete(
        companiesUrl + deleteCompany.contactCompanyId + "/",
        {
          headers: {
            "User-Company": company?.companyId ?? "",
          },
        }
      );
    },
    onSuccess: (result, variables, context) => {
      handleDeleteCompanyContact(variables.contactCompanyId);
      // if (onSuccessCb) onSuccessCb();
    },
    onError: (err, variables, context) => {
      if (showNotification) {
        if (isAxiosError<AxiosBackendErrorReturn>(err)) {
          showNotification(
            `${err?.response?.status ?? "ERROR"}: ${
              err.response?.data.detail ?? "UNKNOWN ERROR"
            }`,
            "error",
            String(
              err?.response?.data ?? "Fail To Delete Contact's Company"
            ).substring(0, 300)
          );
        } else {
          showNotification(
            "Something Wrong!",
            "error",
            "Fail To Delete Contact's Company"
          );
        }
      }
    },
  });

  return mutation;
};

const OrdersQueryAPI = {
  useRetrieveContactsList,
  useRetrieveContactCompanyList,
  useSyncContacts,
  useRemoveContactsCompanyQuery,
};
export default OrdersQueryAPI;
