import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import styled from "styled-components";
import {
  DoxleFont,
  DoxleThemeColor,
} from "../../../DoxleGeneralStore/useDoxleThemeStore";

export const StyledOrderTogglePaymentCheckbox = styled(Checkbox)`
  && {
    margin-right: 8px;
    padding: 0px !important;
  }
`;
export const StyledOrderToggleSelectAllCheckbox = styled(Checkbox)`
  && {
    padding: 0px !important;
    width: 30px;
    display: flex;
    justify-content: flex-start;
  }
`;
export const StyledSelectAllContainer = styled.div`
  position: relative;
  left: 1px;
`;
export const StyledOrderToggleForm = styled(FormControlLabel)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  & .MuiTypography-root {
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-weight: 400;
    font-size: max(1.2rem, 12px);
    margin-top: 2px;
    color: ${(p) => p.$themeColor.primaryFontColor};
  }
`;

export const StyledOrderSelectToggleForm = styled(FormControlLabel)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  & .MuiTypography-root {
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-weight: 400;
    font-size: max(1.2rem, 12px);
    margin-top: 2px;
    color: ${(p) => p.$themeColor.primaryFontColor};
    width: 80px;
  }
`;
