import React, { useEffect, useState } from "react";
import { useEditOrderStore } from "../../../OrdersStore/useEditOrderStore";
import { shallow } from "zustand/shallow";
import { useDoxleCurrentContextStore } from "../../../../DoxleGeneralStore/useDoxleCurrentContext";
import useDoxleNotificationStore from "../../../../DoxleGeneralStore/useDoxleNotificationStore";
import { useOrdersStore } from "../../../OrdersStore/useOrdersStore";
import useSetOrderDetailQueryData from "../../../Hooks/useSetOrderDetailQueryData";
import Order from "../../../Models/order";
import OrdersQueryAPI from "../../../QueryHooks/OrdersQueryAPI";
import useGetUserInfo from "../../../../CoreContent/QueryDataHooks/GetQueryDataHooks/useGetUserInfo";

interface Props {
  updatedOrder: Omit<Partial<Order>, "orderId"> | undefined;
  isOrderLineUpdated: boolean;
}

interface ConfirmOverBudgetDialog {
  handleConfirmOverBudget: () => void;
  isUpdatingOrder: boolean;
}
const useConfirmOverBudgetDialog = ({
  updatedOrder,
  isOrderLineUpdated,
}: Props): ConfirmOverBudgetDialog => {
  const {
    updateEditedOrder,
    editedOrder,
    setShowOverBudgetWarning,
    setEditedOrder,
  } = useEditOrderStore(
    (state) => ({
      updateEditedOrder: state.updateEditedOrder,
      editedOrder: state.editedOrder,
      setShowOverBudgetWarning: state.setShowOverBudgetWarning,
      setEditedOrder: state.setEditedOrder,
    }),
    shallow
  );
  const company = useDoxleCurrentContextStore(
    (state) => state.currentCompany,
    shallow
  );

  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );
  const { queryFilter } = useOrdersStore(
    (state) => ({ queryFilter: state.queryFilter }),
    shallow
  );
  const { handleUpdatePartialOrder } = useSetOrderDetailQueryData({
    orderId: editedOrder?.orderId ?? "",
  });
  const onSuccessUpdateOrder = (order?: Order) => {
    if (order) {
      updateEditedOrder(order);
      handleUpdatePartialOrder(order);
    }
    setShowOverBudgetWarning(false);
    setEditedOrder(undefined);
  };

  const { patchOrder } = OrdersQueryAPI.useMutateOrderQuery({
    company,
    showNotification,
    filter: queryFilter,
    onUpdateSuccessCb: onSuccessUpdateOrder,
  });
  const handleConfirmOverBudget = () => {
    if (editedOrder?.orderId)
      patchOrder.mutate({
        orderId: editedOrder.orderId,
        order: {
          ...updatedOrder,
          lines: isOrderLineUpdated ? editedOrder?.lines : undefined,
          allowOverBudget: true,
        },
      });
  };

  return {
    handleConfirmOverBudget,
    isUpdatingOrder: patchOrder.isLoading,
  };
};

export default useConfirmOverBudgetDialog;
