import { useCallback, useEffect, useMemo, useState, MouseEvent } from "react";
import {
  DoxleFont,
  DoxleThemeColor,
  useDoxleThemeStore,
} from "../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import styled from "styled-components";
import { OurStoryIcon } from "./OurStoryIcons";

type Props = {};

const OurStory = (props: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }),
    shallow
  );

  return (
    <StyledOurStoryContainer>
      <StyledTopContainer $themeColor={doxleThemeColor}>
        <OurStoryIcon themeColor={doxleThemeColor} />
        <StyledTitle $themeColor={doxleThemeColor}>Our Story</StyledTitle>
      </StyledTopContainer>

      <StyledDescription $themeColor={doxleThemeColor} $doxleFont={doxleFont}>
        Doxle is created by builders for builders. With more than a decade spent
        in the building and engineering industry and understanding the lack of
        unanimous workflow and all consultants working in open and transparent
        fashion.
      </StyledDescription>
      <StyledDescription $themeColor={doxleThemeColor} $doxleFont={doxleFont}>
        With state of the art machine learning algorithms, we aim to provide you
        transparent measurements and costings to help you budget your builds.
        Please verify all measurements with our measure tool to ensure accuracy.
      </StyledDescription>
    </StyledOurStoryContainer>
  );
};

export default OurStory;

const StyledOurStoryContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 5rem;

  width: 100%;
`;

const StyledDescription = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  font-size: 1.8rem;
  max-width: 800px;
  color: ${(p) => p.$themeColor.primaryFontColor};
  font-family: ${(p) => p.$doxleFont.secondaryTitleFont};
  padding-left: 2rem;
  padding-right: 2rem;
  margin-top: 2rem;
`;

const StyledTitle = styled.div<{
  $themeColor: DoxleThemeColor;
}>`
  font-size: 4rem;
  font-weight: 600;
  color: ${(p) => p.$themeColor.primaryFontColor};
  margin-left: 20px;
`;

const StyledTopContainer = styled.div<{
  $themeColor: DoxleThemeColor;
}>`
  margin-bottom: 50px;
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
`;
