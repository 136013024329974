import {useGetParams} from "../../Utilities/FunctionUtilities";
import DoxleAPI from "../../Services/DoxleAPI";
import {useDoxleAuthStore} from "../../DoxleGeneralStore/useDoxleAuthStore";
import {shallow} from "zustand/shallow";
import {useDoxleCurrentContextStore} from "../../DoxleGeneralStore/useDoxleCurrentContext";
import useDoxleNotificationStore from "../../DoxleGeneralStore/useDoxleNotificationStore";
import {useEffect, useState} from "react";
import Loading from "../../Utilities/Lottie/Loading";


const XeroCallBack = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const company = useDoxleCurrentContextStore(state=> state.currentCompany, shallow)
  const showNotification = useDoxleNotificationStore(state => state.showNotification, shallow)
  const query = useGetParams();
  const code = query.get("code");
  const state = query.get("state");

  const finaliseXeroConnection = async () => {
    if (!(company)) return
    try {
      const response = await DoxleAPI.post(
        "/accounts/xero_call_back/",
        {
        "companyId": company?.companyId,
        "code": code,
        "state": state
        },
        {
          headers: {
            'User-Company': company?.companyId
          },
        }
      )
      if (response.status === 200) window.open(window.location.protocol + "//" + window.location.host, "_self")
    } catch (error: any) {
      showNotification(
        `${error?.response?.status ?? "ERROR"}: ${
          error?.response?.statusText ?? "UNKNOWN ERROR"
        }`,
        "error",
        error?.response?.data?.message ?? "Error Saving Xero Values"
      );
    }
  }

  useEffect(()=>{
    finaliseXeroConnection()
  }, [company])

  // accountingDetailAPI.finalizeXeroConnection(companyId, code, state)

  if (isLoading) return (<Loading />)
  else return  (
    <h1>Something wrong, please try again</h1>
  )
}

export default XeroCallBack
