import { AnimatePresence } from "framer-motion";
import React from "react";
import {
  StyledDoxleEmptyPlaceHolderContainer,
  StyledDoxleEmptyPlaceHolderHeadTitleText,
  StyledDoxleEmptyPlaceHolderSubTitleText,
  StyledPlaceholderAddBtn,
  StyledPlaceholderImage,
} from "./StyledComponentDoxleEmptyPlaceHolder";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import { ButtonProps } from "@mui/material";

interface PlaceholderAddBtn {
  btnText: string;
  btnColor?: string;
  btnTextColor?: string;
  btnTextSize?: number;
  btnFunction: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  btnWidthInPixel?: `${number}px`;
  btnHeightInPixel?: `${number}px`;
  btnStyle?: ButtonProps["sx"];
  btnIcon?: React.ReactNode;
}
interface PlaceholderCustomImage {
  imageSource: string;
  imgHeightInPixel?: `${number}px`;
  imgWidthInPixel?: `${number}px`;
  absPosInPixel?: {
    topInPixel?: `${number}px`;
    leftInPixel?: `${number}px`;
    rightInPixel?: `${number}px`;
    bottomInPixel?: `${number}px`;
  };
}
type Props = {
  containerWidthInPixel?: `${number}px`;
  containerWidthRatio?: `${number}%`;
  containerHeightInPixel?: `${number}px`;
  containerHeightRatio?: `${number}%`;
  containerBgColor?: string;
  headTitleText: string;
  subTitleText?: string;
  addBtn?: PlaceholderAddBtn;
  imageCustom?: PlaceholderCustomImage[];
  enterContainerAnimation?: boolean;
  exitContainerAnimation?: boolean;
  headTitleTextStyle?: React.CSSProperties;
  subTitleTextStyle?: React.CSSProperties;
  containerStyle?: React.CSSProperties;
  illustration?: React.ReactNode;
};

const DoxleEmptyPlaceHolder = ({
  containerWidthInPixel,
  containerWidthRatio,
  containerHeightInPixel,
  containerHeightRatio,
  headTitleText,
  subTitleText,
  addBtn,
  imageCustom,
  containerBgColor,

  enterContainerAnimation = false,
  exitContainerAnimation = false,
  headTitleTextStyle,
  subTitleTextStyle,
  containerStyle,
  illustration,
}: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }),
    shallow
  );

  //* Animation

  const containerAnimatedVariants = {
    entering: {
      // scaleX: [0, 1],
      opacity: [0, 1],
      transition: {
        duration: 0.4,
      },
    },
    exiting: {
      y: [0, 10],
      opacity: [0, 1],
      transition: {
        duration: 0.4,
      },
    },
  };
  const addBtnAnimatedVariants = {
    hovering: {
      scale: [1, 0.9],
      opacity: [1, 0.9],
      color: doxleThemeColor.primaryReverseFontColor,
      transition: {
        duration: 0.4,
      },
    },
  };
  return (
    <AnimatePresence>
      <StyledDoxleEmptyPlaceHolderContainer
        variants={containerAnimatedVariants}
        initial={false}
        animate={enterContainerAnimation ? "entering" : undefined}
        exit={exitContainerAnimation ? "exiting" : undefined}
        $widthInPixel={containerWidthInPixel}
        $heightInPixel={containerHeightInPixel}
        $widthRatio={containerWidthRatio}
        $heightRatio={containerHeightRatio}
        $containerBgColor={containerBgColor}
        $themeColor={doxleThemeColor}
        style={{
          width: containerWidthInPixel
            ? containerWidthInPixel
            : containerWidthRatio
            ? containerWidthRatio
            : containerStyle?.width
            ? containerStyle?.width
            : "100%",

          height: containerHeightInPixel
            ? containerHeightInPixel
            : containerHeightRatio
            ? containerHeightRatio
            : containerStyle?.height
            ? containerStyle?.height
            : "100%",
          ...containerStyle,
        }}
        layout="position"
      >
        {illustration}
        <StyledDoxleEmptyPlaceHolderHeadTitleText
          $themeColor={doxleThemeColor}
          $doxleFont={doxleFont}
          style={{
            ...headTitleTextStyle,
            fontSize: headTitleTextStyle?.fontSize ?? "4.2rem",
          }}
        >
          {headTitleText}
        </StyledDoxleEmptyPlaceHolderHeadTitleText>

        {subTitleText && (
          <StyledDoxleEmptyPlaceHolderSubTitleText
            $themeColor={doxleThemeColor}
            $doxleFont={doxleFont}
            style={subTitleTextStyle}
          >
            {subTitleText}
          </StyledDoxleEmptyPlaceHolderSubTitleText>
        )}

        {addBtn && (
          <StyledPlaceholderAddBtn
            variants={addBtnAnimatedVariants}
            initial={false}
            whileHover="hovering"
            $themeColor={doxleThemeColor}
            $doxleFont={doxleFont}
            $btnWidthInPixel={addBtn.btnWidthInPixel}
            $btnHeightInPixel={addBtn.btnHeightInPixel}
            $btnColor={addBtn.btnColor}
            onClick={addBtn.btnFunction}
            $btnTextColor={addBtn.btnTextColor}
            $btnTextSize={addBtn.btnTextSize}
            sx={addBtn.btnStyle}
          >
            {addBtn.btnIcon}
            {addBtn.btnText}
          </StyledPlaceholderAddBtn>
        )}

        {imageCustom &&
          imageCustom.map((image, index) => (
            <StyledPlaceholderImage
              src={image.imageSource}
              key={`img#${index}`}
              $imgHeightInPixel={image.imgHeightInPixel}
              $imgWidthInPixel={image.imgWidthInPixel}
              $absPosInPixel={image.absPosInPixel}
            />
          ))}
      </StyledDoxleEmptyPlaceHolderContainer>
    </AnimatePresence>
  );
};

export default DoxleEmptyPlaceHolder;
