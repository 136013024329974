import React from "react";
import useEmailSelectedQuotes from "./Hooks/useEmailSelectedQuotes";
import useGetQuoteRequestList from "../../CustomQueryHooks/useGetQuoteRequestList";
import { useDoxleQuoteStore } from "../../store/useDoxleQuoteStore";
import { shallow } from "zustand/shallow";
import {
  StyledEmailButtonSection,
  StyledEmailDialogButton,
  StyledEmailDialogContent,
  StyledEmailDialogQuoteItem,
  StyledEmailQuoteDialog,
  StyledEmailTitleDialog,
} from "../MainStyledComponents";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { editRgbaAlpha, formatter } from "../../../Utilities/FunctionUtilities";

type Props = {};

const EmailSelectedQuotes = (props: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }),
    shallow
  );
  const {
    showEmailQuoteDialog,
    handleCloseEmailDialog,

    selectedQuoteRequestItems,
    handleEmailQuotes,
  } = useEmailSelectedQuotes({});

  return (
    <StyledEmailQuoteDialog
      open={showEmailQuoteDialog}
      onClose={handleCloseEmailDialog}
      $themeColor={doxleThemeColor}
      PaperProps={{
        sx: {
          bgColor: doxleThemeColor.primaryContainerColor,
          borderRadius: "0.9rem",
          minWidth: "40rem ",
        },
        elevation: 4,
      }}
      slotProps={{
        backdrop: {
          sx: {
            bgcolor: editRgbaAlpha({
              rgbaColor: doxleThemeColor.primaryBackgroundColor,
              alpha: "0.4",
            }),
          },
        },
      }}
    >
      <StyledEmailTitleDialog
        $themeColor={doxleThemeColor}
        $doxleFont={doxleFont}
      >
        Confirm email quotes!
      </StyledEmailTitleDialog>

      <StyledEmailDialogContent $themeColor={doxleThemeColor}>
        {selectedQuoteRequestItems.map((quote, idx) => (
          <StyledEmailDialogQuoteItem
            $themeColor={doxleThemeColor}
            $doxleFont={doxleFont}
            key={quote.requestId}
          >
            <div className="supplier-text">
              <h4>{quote.supplierName}</h4>
              <p>
                {quote.quoteTotal
                  ? formatter.format(Number(quote.quoteTotal))
                  : "-"}
              </p>
            </div>

            <div className="email-text">{quote.toEmails}</div>
          </StyledEmailDialogQuoteItem>
        ))}
      </StyledEmailDialogContent>

      <StyledEmailButtonSection $themeColor={doxleThemeColor}>
        <StyledEmailDialogButton
          $themeColor={doxleThemeColor}
          $doxleFont={doxleFont}
          $bgColor={doxleThemeColor.doxleColor}
          $textColor="rgba(255,255,255,1)"
          onClick={handleEmailQuotes}
        >
          <span className="button-text"> Send</span>
        </StyledEmailDialogButton>

        <StyledEmailDialogButton
          $themeColor={doxleThemeColor}
          $doxleFont={doxleFont}
          $bgColor={"rgba(255,0,0,1)"}
          $textColor="rgba(255,255,255,1)"
          onClick={handleCloseEmailDialog}
        >
          <span className="button-text"> Cancel</span>
        </StyledEmailDialogButton>
      </StyledEmailButtonSection>
    </StyledEmailQuoteDialog>
  );
};

export default EmailSelectedQuotes;
