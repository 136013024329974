import AddCompanyScreen from "../AddCompanyScreen/AddCompanyScreen";
import { useDoxleCurrentContextStore } from "../../DoxleGeneralStore/useDoxleCurrentContext";
import { shallow } from "zustand/shallow";
import {
  StyledCompanySettingsContainer,
  StyledTitle,
  StyledCompanySettingsTabContainer,
} from "./styledComponents";
import CompanySettingsTabMenu, {
  CompanySettingsTabOptions,
} from "./CompanySettingsTabMenu/CompanySettingsTabMenu";
import { useState } from "react";
import CompanySettingsTab from "./CompanySettingsTab/CompanySettingsTab";
import PermissionsSettingsTab from "./PermissionsSettingsTab/PermissionsSettingsTab";
import XeroSettingsTab from "./XeroSettingsTab/XeroSettingsTab";
import ReportsSettingsTab from "./ReportsSettingsTab/ReportsSettingsTab";
import NotificationsSettingsTab from "./NotificationsSettingsTab/NotificationsSettingsTab";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import StatusSettingsTab from "./StatusSettingsTab/StatusSettingsTab";

const CompanySettingsScreen = () => {
  const [selectedTab, setSelectedTab] =
    useState<CompanySettingsTabOptions>("Company");
  const currentCompany = useDoxleCurrentContextStore(
    (state) => state.currentCompany,
    shallow
  );
  const styleProps = useDoxleThemeStore(
    (state) => ({
      $themeColor: state.doxleThemeColor,
      $doxleFont: state.doxleFont,
    }),
    shallow
  );
  if (!currentCompany) return <AddCompanyScreen />;
  return (
    <StyledCompanySettingsContainer {...styleProps}>
      <StyledTitle {...styleProps}>Settings</StyledTitle>
      <CompanySettingsTabMenu
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
      />
      <StyledCompanySettingsTabContainer {...styleProps}>
        {selectedTab === "Company" && <CompanySettingsTab />}
        {selectedTab === "Statuses" && <StatusSettingsTab />}
        {selectedTab === "Permissions" && <PermissionsSettingsTab />}
        {selectedTab === "Xero" && <XeroSettingsTab />}
        {selectedTab === "Reports" && <ReportsSettingsTab />}
        {selectedTab === "Notification" && <NotificationsSettingsTab />}
      </StyledCompanySettingsTabContainer>
    </StyledCompanySettingsContainer>
  );
};

export default CompanySettingsScreen;
