import React from "react";
import { StyledQRLogFilterFieldWrapper } from "./StyledQRLogFilter";
import useQRLogFilterUser from "./Hooks/useQRLogFilterUser";
import Popover from "@mui/material/Popover";
import QRUserList from "../CommonComponents/QRUserList";
import { FaAngleDown } from "react-icons/fa6";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import DoxleFilterTag from "../../../DoxleDesignPattern/DoxleFilterTag/DoxleFilterTag";
import useQRLogStore from "../../Store/useQRLogStore";
import { useShallow } from "zustand/react/shallow";
import { AnimatePresence } from "framer-motion";
type Props = {};

const QRLogFilterUser = (props: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore((state) => ({
    doxleThemeColor: state.doxleThemeColor,
    doxleFont: state.doxleFont,
  }));
  const { removeFilterQRUserLogProp } = useQRLogStore(
    useShallow((state) => ({
      removeFilterQRUserLogProp: state.removeFilterQRUserLogProp,
    }))
  );
  const {
    selectedUser,
    anchorUserList,
    setAnchorUserList,
    showUserList,
    closeUserPopoverList,
    handleSelectUser,
  } = useQRLogFilterUser();
  const canBeOpen = Boolean(anchorUserList);
  const id = canBeOpen ? "qr-user-menu-popper" : undefined;
  return (
    <StyledQRLogFilterFieldWrapper $isNull={!Boolean(selectedUser)}>
      <span className="label-text">Filter by user</span>

      <div
        className="toggle-display-field"
        onClick={(e) => setAnchorUserList(e.currentTarget)}
      >
        Show logs of a user
        <FaAngleDown color={doxleThemeColor.primaryFontColor} size={16} />
      </div>
      <AnimatePresence>
        {selectedUser && (
          <DoxleFilterTag
            itemData={selectedUser}
            itemDisplayText={`${selectedUser.firstName} ${selectedUser.lastName}`}
            removable={true}
            removeItemFunction={() => removeFilterQRUserLogProp("user")}
            tagStyle={{
              marginTop: 10,
            }}
          />
        )}
      </AnimatePresence>
      <Popover
        id={id}
        open={canBeOpen}
        anchorEl={anchorUserList}
        disableScrollLock
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={closeUserPopoverList}
        sx={{
          "& .MuiPopover-paper": {
            backgroundColor: "transparent",
            overflow: "visible",
            boxShadow: "none",
          },
        }}
        transitionDuration={{ appear: 100, exit: 200 }}
        elevation={4}
      >
        {showUserList && (
          <QRUserList
            selectedIds={selectedUser ? [selectedUser.userId!] : []}
            onSelectUser={handleSelectUser}
          />
        )}
      </Popover>
    </StyledQRLogFilterFieldWrapper>
  );
};

export default QRLogFilterUser;
