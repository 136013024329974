import { shallow } from "zustand/shallow";
import ScopeOfWorkAPI from "../QueryAPI/scopeOfWorkQueryAPI";
import { useDoxleCurrentContextStore } from "../../DoxleGeneralStore/useDoxleCurrentContext";
import useDoxleNotificationStore from "../../DoxleGeneralStore/useDoxleNotificationStore";
import { useScopeOfWorkStore } from "../Store/useScopeOfWorkStore";
import useSetScopeOfWorkGroupQueryData from "./useSetScopeOfWorkGroupQueryData";

interface EditSOWGroup {
  handleDeleteSOWGroup: () => void;
  isDeleteSOWGroup: boolean;

  handleCloseDeleteDialog: () => void;
}
export const useEditSOWGroup = (): EditSOWGroup => {
  const { company } = useDoxleCurrentContextStore((state) => ({
    company: state.currentCompany,
  }));
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification
  );
  const { deletedSOWGroup, setDeletedSOWGroup, filterScopeOfWorkGroup } =
    useScopeOfWorkStore(
      (state) => ({
        deletedSOWGroup: state.deletedSOWGroup,
        setDeletedSOWGroup: state.setDeletedSOWGroup,
        filterScopeOfWorkGroup: state.filterScopeOfWorkGroup,
      }),
      shallow
    );

  //# delete sow group query
  const { handleRemoveScopeGroup } = useSetScopeOfWorkGroupQueryData({
    filter: filterScopeOfWorkGroup,
  });

  const deleteSOWGroupQuery = ScopeOfWorkAPI.useDeleteScopeGroupQuery({
    company,
    filter: filterScopeOfWorkGroup,
  });

  const handleDeleteSOWGroup = () => {
    if (deletedSOWGroup) {
      handleRemoveScopeGroup(deletedSOWGroup.scopeGroupId);
      deleteSOWGroupQuery.mutate(deletedSOWGroup.scopeGroupId);
    }
    handleCloseDeleteDialog();
  };

  const handleCloseDeleteDialog = () => {
    setDeletedSOWGroup(undefined);
  };

  return {
    isDeleteSOWGroup: deleteSOWGroupQuery.isLoading,
    handleDeleteSOWGroup,

    handleCloseDeleteDialog,
  };
};
