import {
  EditOrderSideScreenBodyContainer,
  StyledOrderTableHeader,
  StyledOrderTableFooter,
  StyledOrderTableHeaderCell,
  StyledEditOrderTableBody,
  StyledLoadingLineText,
} from "./StyledComponents";
import OrderLineTableRow from "./OrderLineTableRow";
import { useEditOrderStore } from "../../OrdersStore/useEditOrderStore";
import { shallow } from "zustand/shallow";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { ORDER_DETAIL_TABLE_HEADER_LIST } from "../../Models/orderDetail";
import OrderLineFooter from "./OrderLineFooter";
import {
  StyledEditOrderLabelField,
  StyledOrderLineListScroller,
} from "../EditOrder/StyledComponentEditOrder";
import { useEditOrderContext } from "../EditOrder/EditOrder";
import CircularProgress from "@mui/material/CircularProgress";
import LinearProgress from "@mui/material/LinearProgress";
import { editRgbaAlpha } from "../../../Utilities/FunctionUtilities";
import { EmptyOrderLineBanner } from "../../OrdersIcons";
import DoxleEmptyPlaceHolder from "../../../DoxleDesignPattern/DoxleEmptyPlaceHolder/DoxleEmptyPlaceHolder";
import { User } from "../../../Models/user";
import OrderHistoryTable from "./OrderHistoryTable";
import { useEffect } from "react";

const EditOrderLineTable = () => {
  const themeColor = useDoxleThemeStore(
    (state) => state.doxleThemeColor,
    shallow
  );
  const { order, setOrder } = useEditOrderStore(
    (state) => ({
      order: state.editedOrder,
      setOrder: state.setEditedOrder,
    }),
    shallow
  );
  
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }),
    shallow
  );
  const editOrderContext = useEditOrderContext();
  const { isFetchingOrderDetail, isErrorFetchingOrderDetail } = editOrderContext
    ? editOrderContext
    : { isFetchingOrderDetail: false, isErrorFetchingOrderDetail: false };
  return (
    <EditOrderSideScreenBodyContainer>
      <StyledEditOrderLabelField
        $themeColor={doxleThemeColor}
        $doxleFont={doxleFont}
        style={{ marginBottom: 20 }}
      >
        Lines
      </StyledEditOrderLabelField>
      <StyledOrderLineListScroller>
        <StyledOrderTableHeader $themeColor={themeColor}>
          {ORDER_DETAIL_TABLE_HEADER_LIST.map((header, idx) => (
            <StyledOrderTableHeaderCell
              $themeColor={doxleThemeColor}
              $doxleFont={doxleFont}
              key={`header#${idx}`}
              $width={
                header.itemKeyProp !== "description"
                  ? header.itemKeyProp !== "itemCost"
                    ? header.itemKeyProp === "lineCost"
                      ? "calc(12rem - 2rem)"
                      : "8rem"
                    : "10rem"
                  : undefined
              }
              $paddingLeft={
                header.itemKeyProp === "lineCost" ? "2rem" : undefined
              }
              $horizontalAlign={
                header.itemKeyProp === "lineCost" ? "flex-start" : "center"
              }
            >
              {header.itemKeyProp !== "description" && header.headerName}
            </StyledOrderTableHeaderCell>
          ))}
          <StyledOrderTableHeaderCell
            $themeColor={doxleThemeColor}
            $doxleFont={doxleFont}
            $width={"2rem"}
          />
        </StyledOrderTableHeader>
        <StyledEditOrderTableBody>
          {isFetchingOrderDetail && (
            <>
              <LinearProgress
                className="loader-bar"
                sx={{
                  "&.MuiLinearProgress-root": {
                    backgroundColor: editRgbaAlpha({
                      rgbaColor: doxleThemeColor.doxleColor,
                      alpha: "0.3",
                    }),
                  },
                  "& .MuiLinearProgress-bar": {
                    backgroundColor: doxleThemeColor.doxleColor,
                  },
                  width: "50% !important",
                  marginTop: "30px",
                  alignSelf: "center",
                }}
              />
              <StyledLoadingLineText
                $themeColor={doxleThemeColor}
                $doxleFont={doxleFont}
              >
                Getting Order Lines...
              </StyledLoadingLineText>
            </>
          )}

          {!isFetchingOrderDetail && (
            <>
              {isErrorFetchingOrderDetail && (
                <DoxleEmptyPlaceHolder
                  illustration={
                    <EmptyOrderLineBanner
                      themeColor={doxleThemeColor}
                      containerStyle={{
                        width: "70%",
                        maxWidth: 400,
                        maxHeight: 400,
                        marginBottom: 14,
                      }}
                    />
                  }
                  headTitleText="Something wrong"
                  subTitleText="Unable to get order lines, please try to reload the page!"
                  headTitleTextStyle={{ fontSize: "max(2.5rem,25px)" }}
                  subTitleTextStyle={{ fontSize: "(max(1.4rem,14px)" }}
                  containerStyle={{
                    alignSelf: "center",
                    width: "70%",
                    height: "fit-content",
                  }}
                />
              )}
              {!isErrorFetchingOrderDetail &&
                (order?.lines ?? []).map((item, index) => (
                  <OrderLineTableRow
                    key={item.orderLineId}
                    orderLine={item}
                    lastLine={index === (order?.lines ?? []).length - 1}
                    index={index}
                  />
                ))}
              <OrderLineFooter />
            </>
          )}
        </StyledEditOrderTableBody>
        <StyledOrderTableFooter $themeColor={themeColor} layout />
        <OrderHistoryTable orderHistory={order?.history??[]}/>
      </StyledOrderLineListScroller>
    </EditOrderSideScreenBodyContainer>
  );
};
export default EditOrderLineTable;
