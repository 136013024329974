import React, { useCallback, useMemo } from "react";
import {
  StyledBookingDocketWrapper,
  StyledDocketPopover,
  StyledEditBookingContentWrapper,
  StyledSearchDocketTextField,
} from "./StyledEditBookingDialog";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { IoChevronDownOutline } from "react-icons/io5";
import { useEditBookingContext } from "./EditBookingDialog";
import useEditBookingDocketSection from "../Hooks/useEditBookingDocketSection";
import Popover from "@mui/material/Popover";
import { AnimatePresence, Variants } from "framer-motion";
import DoxleListSkeleton from "../../../DoxleDesignPattern/DoxleSkeleton/DoxleListSkeleton";
import { Components, Virtuoso } from "react-virtuoso";
import { Docket } from "../../../Models/dockets";
import ListLoadingMore from "../../../Utilities/Lottie/ListLoadingMore";
import BookingDocketRow from "../../BookingProject/Components/BookingDocketRow";
import { StyledVirtuosoListScroller } from "../../CommonComponents/StyledComponentBookings";
import DoxleEmptyPlaceHolder from "../../../DoxleDesignPattern/DoxleEmptyPlaceHolder/DoxleEmptyPlaceHolder";
import { Error404Banner } from "../../../DoxleDesignPattern/DoxleIcons/CommonIcons";

type Props = {};
interface DocketListContext {
  isErrorFetchingDocketList: boolean;
  selectedDocketId?: string;
}
const EditBookingDocketSection = (props: Props) => {
  const { doxleThemeColor, staticMenuColor } = useDoxleThemeStore();
  const { shouldShowError, editBookingData, setEditBookingData } =
    useEditBookingContext();

  const {
    docketList,
    handleFetchNextPageDocket,
    isFetchingDocketList,
    isSuccessFetchingDocketList,
    isErrorFetchingDocketList,
    isFetchingNextPageDocket,
    refetchdocketList,
    isRefetchingDocketList,
    setSearchDocketText,
    searchDocketText,
    showList,
    anchorPopover,
    setAnchorPopover,
    onClosePopover,
    paperAnimatedVariants,
    onSelectDocket,
  } = useEditBookingDocketSection();
  const canBeOpen = Boolean(anchorPopover);
  const id = canBeOpen ? "booking-docket-transition-popper" : undefined;

  const rowAnimatedVariants: Variants = {
    initial: {
      x: -5,
      opacity: 0,
    },
    entering: {
      x: 0,
      opacity: 1,
    },
  };
  const docketListStyle: React.CSSProperties = {
    width: "100%",
    flex: 1,
  };
  const components: Components<Docket, DocketListContext> = useMemo(
    () => ({
      Item: (props) => (
        <StyledBookingDocketWrapper
          key={props.item.docketPk}
          {...props}
          initial="initial"
          animate="entering"
          variants={rowAnimatedVariants}
          transition={{
            duration: 0.2,
          }}
          onClick={() => {
            if (onSelectDocket) onSelectDocket(props.item);
          }}
        />
      ),
      Scroller: React.forwardRef(({ ...props }, ref) => (
        <StyledVirtuosoListScroller ref={ref} {...props} />
      )),

      EmptyPlaceholder: (props) => (
        <DoxleEmptyPlaceHolder
          headTitleText={
            props.context?.isErrorFetchingDocketList
              ? "Something wrong!"
              : "No Dockets!"
          }
          subTitleText={
            props.context?.isErrorFetchingDocketList
              ? "Failed to get docket data..."
              : ""
          }
          containerBgColor="transparent"
          headTitleTextStyle={{
            fontSize: "16px",
          }}
          subTitleTextStyle={{ fontSize: "14px" }}
          illustration={
            props.context?.isErrorFetchingDocketList ? (
              <Error404Banner
                themeColor={doxleThemeColor}
                containerStyle={{
                  maxWidth: 200,
                }}
              />
            ) : undefined
          }
        />
      ),
    }),
    [onSelectDocket]
  );

  const itemContent = useCallback(
    (index: number, item: Docket, context?: DocketListContext) => (
      <BookingDocketRow
        docket={item}
        isSelected={Boolean(
          context?.selectedDocketId &&
            context.selectedDocketId === item.docketPk
        )}
      />
    ),
    []
  );
  return (
    <>
      <StyledEditBookingContentWrapper
        className="wrapper"
        onClick={(e) => setAnchorPopover(e.currentTarget)}
      >
        <span className="label-text">Docket </span>
        <StyledEditBookingContentWrapper
          className="nested-wrapper"
          onClick={(e) => setAnchorPopover(e.currentTarget)}
        >
          <span className="content-text">
            {editBookingData?.docketName || "Select Docket"}
          </span>

          <IoChevronDownOutline
            size={16}
            color={doxleThemeColor.primaryFontColor}
          />
        </StyledEditBookingContentWrapper>
      </StyledEditBookingContentWrapper>
      <Popover
        id={id}
        open={canBeOpen}
        anchorEl={anchorPopover}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        sx={{
          "& .MuiPopover-paper": {
            backgroundColor: doxleThemeColor.primaryContainerColor,
            overflow: "visible",
            boxShadow: "none",
            border: `1px solid ${doxleThemeColor.primaryDividerColor}`,
            marginTop: "12px",
          },
        }}
        elevation={8}
        onClose={onClosePopover}
      >
        <AnimatePresence>
          <StyledDocketPopover
            variants={paperAnimatedVariants}
            animate="entering"
            exit="exiting"
            $height={docketList.length > 20 ? 300 : 200}
          >
            <StyledSearchDocketTextField
              value={searchDocketText}
              onChange={(e) => setSearchDocketText(e.target.value)}
              placeholder="Search docket..."
            />
            {isFetchingDocketList && (
              <DoxleListSkeleton
                skeletonType="orderRow"
                containerStyle={{ flex: 1, width: "100%" }}
              />
            )}
            {!isFetchingDocketList && showList && (
              <Virtuoso
                context={{
                  isErrorFetchingDocketList,
                  selectedDocketId: editBookingData?.docket,
                }}
                data={docketList}
                components={components}
                style={docketListStyle}
                itemContent={itemContent}
                endReached={handleFetchNextPageDocket}
              />
            )}

            {isFetchingNextPageDocket && (
              <ListLoadingMore
                containerStyle={{
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  zIndex: 10,
                }}
                animationSize={50}
              />
            )}
          </StyledDocketPopover>
        </AnimatePresence>
      </Popover>
    </>
  );
};

export default EditBookingDocketSection;
