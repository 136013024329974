import { CircularProgress } from "@mui/material";
import React, { useCallback, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { VirtuosoGrid } from "react-virtuoso";
import styled from "styled-components";
import { shallow } from "zustand/shallow";
import { useDoxleCurrentContextStore } from "../../DoxleGeneralStore/useDoxleCurrentContext";
import useDoxleNotificationStore from "../../DoxleGeneralStore/useDoxleNotificationStore";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import { DoxleFile } from "../../Models/files";
import FilesQueryAPI, {
  IAddFileMutateProps,
} from "../QueryHooks/FileStorageQueryAPI";
import { useFileStore } from "../Store/useFileStore";
import GridFolderFiles from "./GridFolderFiles";
import FilePlaceholderImg from "../Assets/FilePlaceholder.png";

import {
  StyledChooseFileButton,
  StyledFilePlaceholder,
  StyledFilePlaceholderImage,
  StyledFilesInsideFolder,
  StyledGridItemContainer,
  StyledGridItemWrapper,
  StyledGridView,
  StyledPlaceholder,
} from "./StyledFilesBodyComponent";
import {
  StyledSubHeaderFirstRow,
  StyledHeaderTitle,
  StyledSubHeaderSecondRow,
  StyledHeaderSubtitle,
  StyledSubHeaderThirdRow,
  StyledSubHeaderThirdRowLeft,
  StyledSubHeaderThirdRowRight,
  StyledAddFileButton,
} from "./StyledFilesHeaderComponent";
import folderImage from "../Assets/folder.svg";
import { useIsMutating } from "@tanstack/react-query";
import { DropEvent, FileRejection, useDropzone } from "react-dropzone";
import useGetFileInsideFolder from "../Hooks/useGetFileInsideFolder";
import { useEditDocketSideScreenStore } from "../../CoreContent/EditDocketSideScreen/EditDocketSideScreenStore/useEditDocketSideScreenStore";

function GridFilesInsideFolderView() {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore((state) => ({
    doxleThemeColor: state.doxleThemeColor,
    doxleFont: state.doxleFont,
  }));
  const { company, currentProject } = useDoxleCurrentContextStore((state) => ({
    company: state.currentCompany,
    currentProject: state.currentProject,
  }));

  const { edittedDocket } = useEditDocketSideScreenStore(
    (state) => ({ edittedDocket: state.edittedDocket }),
    shallow
  );
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification
  );

  const { currentFolder, setCurrentFolder, filterRootFileQuery, currentLevel } =
    useFileStore(
      (state) => ({
        currentFolder: state.currentFolder,
        setCurrentFolder: state.setCurrentFolder,
        filterRootFileQuery: state.filterRootFileQuery,
        currentLevel: state.currentLevel,
      }),
      shallow
    );

  const {
    filesInsideFolderList,
    filterGetFileInsideFolder,
    isFetchingFileInsideFolder,
    isSuccessFetchingFileInsideFolder,
    isErrorFetchingFileInsideFolder,
    isRefetchingFileInsideFolder,
    handleFetchNextFileInsideFolder,
  } = useGetFileInsideFolder({});

  const addFileCallback = () => {
    // showNotification("File added successfully", "success");
  };
  const addFileQuery = FilesQueryAPI.useAddFileQuery({
    company,
    showNotification,
    filter: currentFolder
      ? {
          folderId: currentFolder.folderId,
        }
      : filterRootFileQuery,
  });

  const virtuoso = useRef(null);

  const onDrop = useCallback(
    <T extends File>(
      acceptedFiles: T[],
      fileRejections: FileRejection[],
      event: DropEvent
    ) => {
      const uploadedData: IAddFileMutateProps = { files: acceptedFiles };
      if (currentFolder) uploadedData.folderId = currentFolder.folderId;
      if (currentLevel === "Docket" && edittedDocket)
        uploadedData.docketId = edittedDocket.docketPk;
      if (currentLevel === "Project" && currentProject)
        uploadedData.projectId = currentProject.projectId;
      addFileQuery.mutate(uploadedData);
    },
    [currentFolder, currentLevel, edittedDocket, currentProject]
  );

  const { getRootProps } = useDropzone({
    onDrop,
    accept: {
      "image/*": [".png", ".jpg", ".jpeg", ".bmp", ".webp"],
      "application/pdf": [".pdf"],
      "application/vnd.ms-exce": [".xls"],
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
        ".xlsx",
      ],
      "application/msword": [".doc"],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        [".docx"],
      "application/vnd.ms-powerpoint": [".ppt"],
      "application/vnd.openxmlformats-officedocument.presentationml.presentation":
        [".pptx"],
      "text/plain": [".txt"],
      "text/csv": [".csv"],
      "video/mp4": [".mp4"],
      "video/mpeg": [".mpeg"],
    },
  });

  const chooseFileOnPress = () => {};

  const navigate = useNavigate();

  const url = window.location.href;
  console.log("url: " + url);
  const urlArray = url.split("/");
  //* GETTING THE LAST ITEM OF THE ARRAY
  const folderId = urlArray[urlArray.length - 1];

  //* IF THERE IS NO FOLDER ID PASSED, THEN NAVIGATE BACK TO FILES
  if (folderId === "*") {
    navigate("/Files");
    // setCurrentFolder("FolderFilesView");
    console.log("%c NAVIGATION 1");
  } else {
    console.log("%c NAVIGATION 2");
  }

  const folderSize =
    currentFolder && currentFolder.folderSize
      ? (currentFolder.folderSize / 1024 / 1024).toFixed(2)
      : 0.0;

  const addFileMutationKey = useIsMutating({
    mutationKey: ["ADD-FILES-MUTATION-KEY", company?.companyId],
  });

  const deleteFileMutationKey = useIsMutating({
    mutationKey: ["DELETE-FILE-MUTATION-KEY", company?.companyId],
  });

  return (
    <>
      {isSuccessFetchingFileInsideFolder && (
        <>
          {
            <StyledFilesInsideFolder $themeColor={doxleThemeColor}>
              <StyledSubHeaderFirstRow>
                <img style={{ width: "9rem" }} src={folderImage} />
                <StyledHeaderTitle $reduced={currentLevel !== "Company"}>
                  {currentFolder ? currentFolder?.folderName : "ROOT"}
                </StyledHeaderTitle>
              </StyledSubHeaderFirstRow>
              <StyledSubHeaderSecondRow>
                <StyledHeaderSubtitle>
                  Folder Storage : {folderSize} MB
                </StyledHeaderSubtitle>
              </StyledSubHeaderSecondRow>
              <StyledSubHeaderThirdRow>
                <StyledSubHeaderThirdRowLeft></StyledSubHeaderThirdRowLeft>
                <StyledSubHeaderThirdRowRight>
                  <StyledAddFileButton
                    {...getRootProps({
                      $themeColor: doxleThemeColor,
                    })}
                    $themeColor={doxleThemeColor}
                  >
                    Add File
                  </StyledAddFileButton>
                </StyledSubHeaderThirdRowRight>
              </StyledSubHeaderThirdRow>
            </StyledFilesInsideFolder>
          }

          {filesInsideFolderList.length > 0 ? (
            <StyledGridView $themeColor={doxleThemeColor}>
              <VirtuosoGrid
                data={filesInsideFolderList}
                ref={virtuoso}
                totalCount={filesInsideFolderList.length}
                overscan={200}
                style={{
                  height: "100%",
                  width: "100%",
                  // marginLeft: "14rem",
                  // marginTop: "8rem",

                  justifyContent: "center",
                  alignItems: "center",
                  // display: "flex",
                  // flex: 1,
                  // flexDirection: "inherit",
                  // position: "relative",
                }}
                components={{
                  Item: StyledGridItemContainer,
                  List: React.forwardRef((props, ref) => (
                    <ListContainer {...props} ref={ref} />
                  )),
                  ScrollSeekPlaceholder: ({ height, index }) => (
                    <StyledGridItemContainer>
                      <StyledGridItemWrapper $themeColor={doxleThemeColor}>
                        {"--"}
                      </StyledGridItemWrapper>
                    </StyledGridItemContainer>
                  ),
                }}
                itemContent={(index, item) => {
                  if ((item as DoxleFile).fileId) {
                    return <GridFolderFiles gridItem={item as DoxleFile} />;
                  }
                }}
                scrollSeekConfiguration={{
                  enter: (velocity) => Math.abs(velocity) > 200,
                  exit: (velocity) => Math.abs(velocity) < 30,
                  change: (_, range) => console.log({ range }),
                }}
              />
            </StyledGridView>
          ) : (
            <StyledPlaceholder $themeColor={doxleThemeColor}>
              <StyledFilePlaceholder $themeColor={doxleThemeColor}>
                <StyledFilePlaceholderImage src={FilePlaceholderImg} />
              </StyledFilePlaceholder>
              <StyledChooseFileButton
                {...getRootProps({
                  onClick: chooseFileOnPress,
                  $themeColor: doxleThemeColor,
                  $doxleFont: doxleFont,
                  // $isRootFolder: isRootFolder,
                })}
              >
                {!currentFolder ? "Add Company File" : "Add File"}
              </StyledChooseFileButton>
            </StyledPlaceholder>
          )}

          {(isFetchingFileInsideFolder ||
            addFileMutationKey ||
            deleteFileMutationKey) && (
            <CircularProgress
              size={50}
              sx={{
                color: doxleThemeColor.doxleColor,

                alignItems: "center",
                justifyContent: "center",
                position: "absolute",
                top: "55%",
                left: "50%",
                // background: "red",
                zIndex: "9",
              }}
            />
          )}
        </>
      )}
      ;
    </>
  );
}

export default GridFilesInsideFolderView;
const ListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  width: 100%;
`;
