import { shallow } from "zustand/shallow";
import { IFilterBookingQueryProps } from "../../QueryAPI/bookingQueryAPI";
import useBookingProjectStore from "../../store/useBookingProjectStore";
import { useEffect } from "react";

type Props = {
  dueType: IFilterBookingQueryProps["due"] | "completed";
};

interface BookingFilterOption {
  isFilterSelected: boolean;
  handleClickFilter: () => void;
}
const useBookingFilterOption = ({ dueType }: Props): BookingFilterOption => {
  const { setFilterBookingProjectQuery, filterBookingProjectQuery } =
    useBookingProjectStore(
      (state) => ({
        filterBookingProjectQuery: state.filterBookingProjectQuery,
        setFilterBookingProjectQuery: state.setFilterBookingProjectQuery,
      }),
      shallow
    );

  const isFilterSelected = Boolean(
    (dueType !== "completed" &&
      ((filterBookingProjectQuery.due &&
        filterBookingProjectQuery.due === dueType) ||
        (dueType === "week" && filterBookingProjectQuery.due === 7) ||
        (dueType === "month" && filterBookingProjectQuery.due === 30) ||
        (dueType === "fortnight" && filterBookingProjectQuery.due === 14) ||
        (dueType === "today" && filterBookingProjectQuery.due === 1))) ||
      (dueType === "completed" && filterBookingProjectQuery.completed)
  );

  const handleClickFilter = () => {
    if (!isFilterSelected) {
      if (dueType !== "completed")
        setFilterBookingProjectQuery({
          due:
            dueType === "week"
              ? 7
              : dueType === "today"
              ? 1
              : dueType === "fortnight"
              ? 14
              : 30,
          completed: false,
        });
      else setFilterBookingProjectQuery({ due: undefined, completed: true });
    } else setFilterBookingProjectQuery({ due: undefined, completed: false });
  };
  return { isFilterSelected, handleClickFilter };
};

export default useBookingFilterOption;
