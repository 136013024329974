import React, { useEffect, useRef, useState } from "react";
import { useEditOrderStore } from "../../OrdersStore/useEditOrderStore";
import { shallow } from "zustand/shallow";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import {
  StyledEditOrderErrorText,
  StyledEditOrderInputAdornment,
  StyledEditOrderLabelField,
  StyledEditOrderNoteText,
  StyledEditOrderTextfield,
  StyledEditOrderWrapperField,
} from "./StyledComponentEditOrder";
import { AnimatePresence } from "framer-motion";
import useEditOrderPaymentAndLateFee from "./Hooks/useEditOrderPaymentAndLateFee";

type Props = {};

const EditOrderPaymentAndLateFee = (props: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }),
    shallow
  );
  const readOnly = useEditOrderStore((state) => state.readOnly, shallow);
  const {
    handleBlurPaymentTextField,
    handleBlurLatefeeTextField,
    paymentText,
    setPaymentText,
    lateFeeText,
    setLateFeeText,
  } = useEditOrderPaymentAndLateFee({});
  const paymentInputRef = useRef<HTMLInputElement>(null);
  const lateFeeInputRef = useRef<HTMLInputElement>(null);
  return (
    <>
      <StyledEditOrderWrapperField layout="position">
        <StyledEditOrderLabelField
          $themeColor={doxleThemeColor}
          $doxleFont={doxleFont}
        >
          Payment
        </StyledEditOrderLabelField>
        <div className="row-wrapper">
          <StyledEditOrderTextfield
            $themeColor={doxleThemeColor}
            $doxleFont={doxleFont}
            value={paymentText}
            variant="standard"
            inputMode="numeric"
            disabled={readOnly}
            inputRef={paymentInputRef}
            onChange={(event) => setPaymentText(event.target.value)}
            sx={{
              width: "max(3rem,30px) !important",
              justifyContent: "center",
              minWidth: "18px !important",
              textAlign: "center",
              "& input": {
                textAlign: "center !important",
              },
            }}
            onFocus={(e) => e.currentTarget.select()}
            onBlur={handleBlurPaymentTextField}
            onKeyDown={(e) => {
              if (e.nativeEvent.key === "Enter") {
                e.preventDefault();
                paymentInputRef.current?.blur();
              }
            }}
          />
          <StyledEditOrderNoteText
            $themeColor={doxleThemeColor}
            $doxleFont={doxleFont}
          >
            (Days within our payment cycle which is 15th and 30th)
          </StyledEditOrderNoteText>
        </div>
        <AnimatePresence>
          {paymentText === "" && (
            <StyledEditOrderErrorText
              $themeColor={doxleThemeColor}
              $doxleFont={doxleFont}
              initial={{
                x: -10,
                opacity: 0,
              }}
              animate={{ x: 0, opacity: 1 }}
              exit={{
                x: -10,
                opacity: 0,
              }}
            >
              This field can't be empty!
            </StyledEditOrderErrorText>
          )}
          {paymentText && isNotNumeric(paymentText) && (
            <StyledEditOrderErrorText
              $themeColor={doxleThemeColor}
              $doxleFont={doxleFont}
              initial={{
                x: -10,
                opacity: 0,
              }}
              animate={{ x: 0, opacity: 1 }}
              exit={{
                x: -10,
                opacity: 0,
              }}
            >
              Invalid input, please enter a number!
            </StyledEditOrderErrorText>
          )}
        </AnimatePresence>
      </StyledEditOrderWrapperField>

      <StyledEditOrderWrapperField style={{ width: "35%" }}>
        <StyledEditOrderLabelField
          $themeColor={doxleThemeColor}
          $doxleFont={doxleFont}
        >
          Late Fees
        </StyledEditOrderLabelField>
        <div className="row-wrapper">
          <StyledEditOrderTextfield
            $themeColor={doxleThemeColor}
            $doxleFont={doxleFont}
            value={lateFeeText}
            disabled={readOnly}
            variant="standard"
            placeholder="Late fees"
            inputRef={lateFeeInputRef}
            inputMode="numeric"
            onBlur={handleBlurLatefeeTextField}
            onFocus={(e) => e.currentTarget.select()}
            onChange={(event) => setLateFeeText(event.target.value)}
            sx={{
              width: "5rem !important",

              minWidth: "100px",
            }}
            InputProps={{
              startAdornment: (
                <StyledEditOrderInputAdornment
                  $themeColor={doxleThemeColor}
                  $doxleFont={doxleFont}
                  position="start"
                >
                  $
                </StyledEditOrderInputAdornment>
              ),
            }}
            onKeyDown={(e) => {
              if (e.nativeEvent.key === "Enter") {
                e.preventDefault();
                lateFeeInputRef.current?.blur();
              }
            }}
          />
          <StyledEditOrderNoteText
            $themeColor={doxleThemeColor}
            $doxleFont={doxleFont}
          >
            (Late fees per day)
          </StyledEditOrderNoteText>
        </div>
        <AnimatePresence>
          {lateFeeText === "" && (
            <StyledEditOrderErrorText
              $themeColor={doxleThemeColor}
              $doxleFont={doxleFont}
              initial={{
                x: -10,
                opacity: 0,
              }}
              animate={{ x: 0, opacity: 1 }}
              exit={{
                x: -10,
                opacity: 0,
              }}
            >
              This field can't be empty
            </StyledEditOrderErrorText>
          )}

          {lateFeeText && isNotNumeric(lateFeeText) && (
            <StyledEditOrderErrorText
              $themeColor={doxleThemeColor}
              $doxleFont={doxleFont}
              initial={{
                x: -10,
                opacity: 0,
              }}
              animate={{ x: 0, opacity: 1 }}
              exit={{
                x: -10,
                opacity: 0,
              }}
            >
              Invalid input, please enter a number!
            </StyledEditOrderErrorText>
          )}
        </AnimatePresence>
      </StyledEditOrderWrapperField>
    </>
  );
};

export default EditOrderPaymentAndLateFee;
function isNotNumeric(str: string) {
  return isNaN(Number(str));
}
