import React, { useCallback, useState } from "react";
import DiscussionQueryAPI, {
  AddedDiscussionFile,
} from "../../../../../Services/QueryHooks/discussionsAPI";
import { useDoxleCurrentContextStore } from "../../../../../DoxleGeneralStore/useDoxleCurrentContext";
import useDoxleNotificationStore from "../../../../../DoxleGeneralStore/useDoxleNotificationStore";
import { useEditDocketSideScreenStore } from "../../../EditDocketSideScreenStore/useEditDocketSideScreenStore";
import { DropEvent, FileRejection } from "react-dropzone";
import {
  Discussion,
  getNewTemplateDiscussion,
} from "../../../../../Models/discussion";
import { VirtuosoHandle } from "react-virtuoso";
import useSetDocketListQueryData from "../../../../QueryDataHooks/SetQueryDataHooks/useSetDocketListQueryData";
import { useShallow } from "zustand/react/shallow";
import { produce } from "immer";

type Props = {
  commentListRef: React.RefObject<VirtuosoHandle>;
  discussionList: Discussion[];
};
interface EditDocketCommentInputSection {
  onDrop: <T extends File>(
    acceptedFiles: T[],
    fileRejections: FileRejection[],
    event: DropEvent
  ) => void;
  addedFiles: AddedDiscussionFile[];
  setAddedFiles: React.Dispatch<React.SetStateAction<AddedDiscussionFile[]>>;
  handleClickSendButton: () => void;
  commentText: string;
  setCommentText: React.Dispatch<React.SetStateAction<string>>;
  taggedUsers: string[];
  setTaggedUsers: React.Dispatch<React.SetStateAction<string[]>>;
  isAddingDiscussion: boolean;
  onKeyDown: (
    event:
      | React.KeyboardEvent<HTMLTextAreaElement>
      | React.KeyboardEvent<HTMLInputElement>
  ) => void;
  anchorAttachmentBtn: HTMLButtonElement | null;
  setAnchorAttachmentBtn: React.Dispatch<
    React.SetStateAction<HTMLButtonElement | null>
  >;
  onDropVideo: <T extends File>(
    acceptedFiles: T[],
    fileRejections: FileRejection[],
    event: DropEvent
  ) => void;
}
const useEditDocketCommentInputSection = ({
  commentListRef,
  discussionList,
}: Props): EditDocketCommentInputSection => {
  const [commentText, setCommentText] = useState<string>("");
  const [taggedUsers, setTaggedUsers] = useState<string[]>([]);
  const [addedFiles, setAddedFiles] = useState<AddedDiscussionFile[]>([]);
  const [anchorAttachmentBtn, setAnchorAttachmentBtn] =
    useState<HTMLButtonElement | null>(null);
  const { company, addCurrentErrorFiles } = useDoxleCurrentContextStore(
    useShallow((state) => ({
      company: state.currentCompany,
      addCurrentErrorFiles: state.addCurrentErrorFiles,
    }))
  );
  const showNotification = useDoxleNotificationStore(
    useShallow((state) => state.showNotification)
  );
  const { filterDiscussionList, edittedDocket, filterDocketListQuery } =
    useEditDocketSideScreenStore(
      useShallow((state) => ({
        edittedDocket: state.edittedDocket,
        filterDiscussionList: state.filterDiscussionList,
        filterDocketListQuery: state.filterDocketListQuery,
      }))
    );
  const { handleUpdateDocket } = useSetDocketListQueryData({
    filter: filterDocketListQuery,
  });
  const onAddSuccessCb = () => {
    setCommentText("");
    setTaggedUsers([]);
    setAddedFiles([]);
    if (edittedDocket) {
      handleUpdateDocket({
        ...edittedDocket,
        commentCount: edittedDocket.commentCount + 1,
      });
    }

    setTimeout(() => {
      if (commentListRef.current)
        commentListRef.current.scrollToIndex({
          index: discussionList.length - 1,
          behavior: "smooth",
          offset: -120,
        });
    }, 200);
  };
  const addDiscussionQuery = DiscussionQueryAPI.useAddDiscussionQuery({
    showNotification,

    company: company,
    filter: filterDiscussionList,
    onSuccessCb: onAddSuccessCb,
    overwritePos: "end",
  });

  //######### HANDLE DROPPING FILES ###########
  const onDrop = useCallback(
    <T extends File>(
      acceptedFiles: T[],
      fileRejections: FileRejection[],
      event: DropEvent
    ) => {
      const addedItems: AddedDiscussionFile[] = acceptedFiles.map(
        (file, idx) => {
          const filePath = URL.createObjectURL(file);
          return {
            name: `${file.name}`,
            type: file.type,

            uri: filePath,
          };
        }
      );
      setAddedFiles(
        produce((draft) => {
          draft.push(...addedItems);

          return draft;
        })
      );
      if (fileRejections.length > 0) addCurrentErrorFiles(fileRejections);

      setAnchorAttachmentBtn(null);
    },
    []
  );
  const onDropVideo = useCallback(
    <T extends File>(
      acceptedFiles: T[],
      fileRejections: FileRejection[],
      event: DropEvent
    ) => {
      const addedItems: AddedDiscussionFile[] = acceptedFiles.map(
        (file, idx) => {
          const filePath = URL.createObjectURL(file);
          return {
            name: `${file.name}`,
            type: file.type,

            uri: filePath,
          };
        }
      );

      if (fileRejections.length > 0) addCurrentErrorFiles(fileRejections);
      else if (addedItems.length > 0) {
        let newComment: Discussion = getNewTemplateDiscussion({
          commentText,
          company,
          docket: edittedDocket ? edittedDocket.docketPk : "",
        });
        if (filterDiscussionList.docketId)
          newComment.docket = filterDiscussionList.docketId;
        if (filterDiscussionList.projectId)
          newComment.project = filterDiscussionList.projectId;
        if (taggedUsers.length > 0) newComment.taggedUsers = taggedUsers;
        addDiscussionQuery.mutate({
          newDiscussion: newComment,
          files: addedItems,
        });
      }

      setAnchorAttachmentBtn(null);
    },
    [commentText, company, edittedDocket, filterDiscussionList, taggedUsers]
  );
  const handleClickSendButton = () => {
    if (
      (commentText || addedFiles.length > 0) &&
      !addDiscussionQuery.isLoading
    ) {
      let newComment: Discussion = getNewTemplateDiscussion({
        commentText,
        company,
        docket: edittedDocket ? edittedDocket.docketPk : "",
      });
      if (filterDiscussionList.docketId)
        newComment.docket = filterDiscussionList.docketId;
      if (filterDiscussionList.projectId)
        newComment.project = filterDiscussionList.projectId;
      if (taggedUsers.length > 0) newComment.taggedUsers = taggedUsers;

      addDiscussionQuery.mutate({
        newDiscussion: newComment,
        files: addedFiles,
      });
    }
  };

  const onKeyDown = (
    event:
      | React.KeyboardEvent<HTMLTextAreaElement>
      | React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (
      event.nativeEvent.key === "Enter" &&
      !(event.shiftKey || event.ctrlKey || event.metaKey || event.altKey)
    ) {
      event.preventDefault();
      handleClickSendButton();
    } else if (
      event.nativeEvent.key === "Enter" &&
      (event.ctrlKey || event.metaKey || event.altKey)
    ) {
      setCommentText((prev) => prev + "\n");
    } else if (event.nativeEvent.key === "Escape") {
      setCommentText("");
    }
  };
  return {
    onDrop,
    addedFiles,
    setAddedFiles,
    handleClickSendButton,
    commentText,
    setCommentText,
    taggedUsers,
    setTaggedUsers,
    isAddingDiscussion: addDiscussionQuery.isLoading,
    onKeyDown,
    anchorAttachmentBtn,
    setAnchorAttachmentBtn,
    onDropVideo,
  };
};

export default useEditDocketCommentInputSection;
