import React, { useMemo } from "react";
import { useDoxleCurrentContextStore } from "../../DoxleGeneralStore/useDoxleCurrentContext";
import { shallow } from "zustand/shallow";
import { useDoxleAuthStore } from "../../DoxleGeneralStore/useDoxleAuthStore";
import useDoxleNotificationStore from "../../DoxleGeneralStore/useDoxleNotificationStore";
import { useContactsStore } from "../Content/Store/useContactsStore";
import ContactsQueryAPI from "../QueryHooks/ContactsQueryAPI";
import { Contact } from "../../Models/addressBook";

type Props = {};

interface GetContactList {
  contactsList: Contact[];
  isFetchingContactList: boolean;
  isSuccessFetchingContactList: boolean;
  isErrorFetchingContactList: boolean;
  isFetchingNextPageContactList: boolean;
  handleFetchNextpageContact: () => void;
}
const useGetContactList = (props: Props): GetContactList => {
  const company = useDoxleCurrentContextStore(
    (state) => state.currentCompany,
    shallow
  );

  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );
  const contactsQueryFilter = useContactsStore(
    (state) => state.contactsQueryFilter,
    shallow
  );
  const contactsListQuery = ContactsQueryAPI.useRetrieveContactsList({

    company,
    showNotification,
    filter: contactsQueryFilter,
  });

  const contactsList: Contact[] = useMemo(
    () =>
      contactsListQuery.isSuccess
        ? contactsListQuery.data.pages.reduce((acc, data) => {
            return acc.concat(data.data.results);
          }, [] as Contact[])
        : [],
    [contactsListQuery.data, company]
  );

  const handleFetchNextpageContact = () => {
    if (contactsListQuery.hasNextPage) contactsListQuery.fetchNextPage();
  };
  return {
    contactsList,
    isFetchingContactList: contactsListQuery.isLoading,
    isSuccessFetchingContactList: contactsListQuery.isSuccess,
    isErrorFetchingContactList: contactsListQuery.isError,
    isFetchingNextPageContactList: contactsListQuery.isFetchingNextPage,
    handleFetchNextpageContact,
  };
};

export default useGetContactList;
