import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Contact } from "../../../../../Models/addressBook";
import { useProjectQAStore } from "../../../Store/useProjectQAStore";
import { shallow } from "zustand/shallow";
import { useDoxleCurrentContextStore } from "../../../../../DoxleGeneralStore/useDoxleCurrentContext";

import QAQueryAPI from "../../../../QueryAPI/qaQueryAPI";
import useDoxleNotificationStore from "../../../../../DoxleGeneralStore/useDoxleNotificationStore";
import {  QAWithFirstImg } from "../../../../Models/qa";
import {Floor} from "../../../../Models/locations";

type Props = {};

interface EditQaFloor {
  anchorFloorList: HTMLElement | null;
  setAnchorFloorList: React.Dispatch<
    React.SetStateAction<HTMLElement | null>
  >;
  handleUpdateFloor: (selectedFloor: Floor | undefined) => void;
  handleCloseFloorList: () => void;
  isUpdatingFloor: boolean;
  isHovering: boolean;
  setIsHovering: React.Dispatch<React.SetStateAction<boolean>>;
}
const useEditQaFloor = (props: Props): EditQaFloor => {
  const [isHovering, setIsHovering] = useState(false);
  const [anchorFloorList, setAnchorFloorList] =
    useState<HTMLElement | null>(null);
  const { selectedQAItem, updateSelectedQAItem } = useProjectQAStore(
    (state) => ({
      selectedQAItem: state.selectedQAItem,
      updateSelectedQAItem: state.updateSelectedQAItem,
    }),
    shallow
  );

  const company = useDoxleCurrentContextStore(
    (state) => state.currentCompany,
    shallow
  );
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );

  const onUpdateSuccess = (newQA?: QAWithFirstImg) => {
    if (newQA)
      updateSelectedQAItem({
        room: newQA.room,
        roomName: newQA.roomName,
        floor: newQA.floor,
        floorName: newQA.floorName,
      });
  };
  const updateQAQuery = QAQueryAPI.useUpdateQAQuery({
    company,
    qaItem: selectedQAItem!,
    showNotification,
    onSuccessCB: onUpdateSuccess,
  });

  const handleUpdateFloor = useCallback(
    (selectedFloor: Floor | undefined) => {
      if (selectedFloor)
        updateQAQuery.mutate({
          floor: selectedFloor.floorId,
        });
      else updateQAQuery.mutate({ floor: null });

      setAnchorFloorList(null);
    },
    []
  );
  const handleCloseFloorList = useCallback(() => {
    setAnchorFloorList(null);
  }, []);
  return {
    anchorFloorList,
    setAnchorFloorList,
    handleUpdateFloor,
    handleCloseFloorList,
    isUpdatingFloor: updateQAQuery.isLoading,
    isHovering,
    setIsHovering,
  };
};

export default useEditQaFloor;
