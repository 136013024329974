import React, { useEffect, useMemo } from "react";
import { groupBy } from "lodash";
import dayjs from "dayjs";
import { IQRUserLogDateGroup, IQRUserLogFilterQuery } from "../Models/qrLog";
import useDoxleNotificationStore from "../../DoxleGeneralStore/useDoxleNotificationStore";
import QRCodeAPI from "../QueryHooks/qrQueryAPI";

type Props = {
  filter: IQRUserLogFilterQuery;
};

const useGetQRUserLogList = ({ filter }: Props) => {
  const { showNotification } = useDoxleNotificationStore();

  const qrLogQuery = QRCodeAPI.useRetrieveQRUserLogListQuery({
    showNotification,
    filter,
    enableQuery: true,
    isNoPagination: filter.pagination,
  });

  const qrUserLogList = useMemo(
    () =>
      qrLogQuery.isSuccess && qrLogQuery.data
        ? "pages" in qrLogQuery.data
          ? qrLogQuery.data?.pages.flatMap((page) => page.data.results)
          : qrLogQuery.data.data
        : [],
    [qrLogQuery.data, qrLogQuery.isSuccess]
  );
  const logDateGroup: IQRUserLogDateGroup = useMemo(() => {
    const dateSet = groupBy(qrUserLogList, (log) =>
      dayjs(log.entryTime).format("YYYY-MM-DD")
    ); // group by pricebookItem
    return Object.keys(dateSet)
      .sort((a, b) => (dayjs(a).isBefore(b) ? 1 : -1))
      .reduce(
        (acc, date) => {
          return (
            acc.dateList.push(new Date(date)),
            acc.datalogList.push(...dateSet[date]),
            acc.itemCount.push(dateSet[date].length),
            acc
          );
        },
        {
          dateList: [],
          datalogList: [],
          itemCount: [],
        } as IQRUserLogDateGroup
      );
  }, [qrUserLogList]);
  const refetchQRUserLogList = () => {
    qrLogQuery.refetch();
  };
  const fetchNextPageQRUserLog = () => {
    if (
      qrLogQuery.data &&
      "pages" in qrLogQuery.data &&
      "hasNextPage" in qrLogQuery &&
      qrLogQuery.hasNextPage
    )
      qrLogQuery.fetchNextPage();
  };

  return {
    qrUserLogList: qrUserLogList,
    isFetchingQRUserLogList: qrLogQuery.isLoading,
    isSuccessFetchingQRUserLogList: qrLogQuery.isSuccess,
    isErrorFetchingQRUserLogList: qrLogQuery.isError,
    refetchQRUserLogList,
    isRefetchingQRUserLogList: qrLogQuery.isRefetching,
    fetchNextPageQRUserLog,
    isFetchingNextPageQRUserLog:
      "isFetchingNextPage" in qrLogQuery && qrLogQuery.isFetchingNextPage,
    logDateGroup,
  };
};

export default useGetQRUserLogList;
