import React from "react";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import { AnimatePresence } from "framer-motion";
import { StyledAutocompleteList } from "./StyledComponentDoxleAutocomplete";
import { PaperProps } from "@mui/material";

interface Props extends PaperProps {}

const AutocompleteList = React.forwardRef<HTMLDivElement, PaperProps>(
  (props, ref) => {
    const { doxleThemeColor, doxleFont } = useDoxleThemeStore((state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }));
    return (
      <AnimatePresence>
        <StyledAutocompleteList
          {...props}
          ref={ref}
          $themeColor={doxleThemeColor}
          elevation={8}
          {...props}
        />
      </AnimatePresence>
    );
  }
);

export default AutocompleteList;
