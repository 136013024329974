import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  ColumnGridItem,
  GridItem,
  GridPage,
  useDoxleGridLayoutStore,
} from "./Store/useDoxleGridLayoutStore";
import { shallow } from "zustand/shallow";
import {
  ElementRenderSize,
  useMeasureItemContent,
} from "./Hooks/useMeasureItemContent";

type Props<TItem> = {
  data: TItem[];
  renderItem: (item: TItem, itemIndex: number) => JSX.Element;
};

function VirtualListRenderer<TItem>({ data, renderItem }: Props<TItem>) {
  const [initialData, setInitialData] = useState<TItem[]>([...data]);
  const [currentItemBatch, setCurrentItemBatch] = useState<TItem[]>([]);

  const [currentIteratePage, setCurrentIteratePage] = useState<number>(0);
  const {
    rootContainerSize,

    addDataPage,
    editPageData,
    currentPage,
    dataPages,
  } = useDoxleGridLayoutStore(
    (state) => ({
      rootContainerSize: state.rootContainerSize,
      currentPageData: state.currentPageData,
      addDataPage: state.addDataPage,
      editPageData: state.editPageData,
      dataPages: state.dataPages,
      currentPage: state.currentPage,
    }),
    shallow
  );
  const numOfColPerPage: number = useMemo(
    () => rootContainerSize.numOfCol,
    [rootContainerSize.numOfCol]
  );
  const numOfItemPerPage: number = useMemo(
    () => rootContainerSize.maxItemPerPage,
    [rootContainerSize.maxItemPerPage]
  );
  const numOfItemPerCol: number = useMemo(
    () => rootContainerSize.maxItemPerCol,
    [rootContainerSize.maxItemPerCol]
  );
  useEffect(() => {
    console.log("numOfColPerPage", numOfColPerPage);
    console.log("numOfItemPerPage", numOfItemPerPage);
    console.log("numOfItemPerCol", numOfItemPerCol);
  }, [numOfColPerPage, numOfItemPerPage, numOfItemPerCol]);

  useEffect(() => {
    setInitialData([...data]);
  }, [
    numOfColPerPage,
    numOfItemPerPage,
    numOfItemPerCol,
    data,
    setInitialData,
  ]);
  const currentPageData: GridPage<any> | undefined = useMemo(
    () => dataPages[currentPage],
    [dataPages, currentPage]
  );
  const minHeightOfGridItem: number = useMemo(
    () =>
      numOfItemPerCol > 0 ? rootContainerSize.heightSize / numOfItemPerCol : 0,
    [numOfItemPerCol, rootContainerSize.heightSize]
  );
  const { itemSizeCollection, virtualElementRenderer } = useMeasureItemContent({
    data: currentItemBatch,
    renderItem,
  });
  const columnItemStyle: React.CSSProperties = useMemo(
    () => ({
      width: rootContainerSize.numOfCol
        ? `${100 / rootContainerSize.numOfCol}%`
        : 0,
      display: "flex",
      flexDirection: "column",
      height: "100%",
    }),
    [rootContainerSize]
  );

  useEffect(() => {
    //trigger popout number of item if current page doesn't have data and there are still data haven't been calculate size
    if (!currentPageData && initialData.length > 0) {
      const batchItems: TItem[] = [...initialData].slice(
        currentPage,
        currentPage + numOfItemPerPage
      );
      setCurrentItemBatch([...batchItems]);
    }
  }, [currentPageData, initialData, currentPage]);

  const handleDistributeItems = useCallback(
    (data: ElementRenderSize<TItem>[]) => {
      if (data.length > 0) {
        console.log("ITEM SIZE LAYOUT:", data);
        let initialGridItemSizeData: ElementRenderSize<TItem>[] = [...data];
        let pageData: ColumnGridItem<TItem>[] = [];
        console.log("numOfItemPerCol:", numOfItemPerCol);
        for (let i = 0; i < numOfColPerPage; i++) {
          console.log("initialData.length:", initialGridItemSizeData.length);
          let columnItemChildren: GridItem<TItem>[] = [];

          //distribute items to colummn
          for (let j = 0; j < numOfItemPerCol; j++) {
            let remainingColumnHeight = rootContainerSize.heightSize;
            const addedItem: ElementRenderSize<TItem> | undefined =
              initialGridItemSizeData[0]; //get first item out from initial list

            if (addedItem) {
              //item added fit the remaining column height
              if (addedItem.height <= remainingColumnHeight) {
                remainingColumnHeight -= addedItem.height;
                columnItemChildren.push({
                  itemData: addedItem.item,
                  itemStyle: {
                    width: "calc(100% - 4px)",
                    padding: 2,

                    display: "flex",
                    overflow: "scroll",
                    wordBreak: "break-word",
                    height:
                      addedItem.height <= minHeightOfGridItem
                        ? minHeightOfGridItem - 4
                        : addedItem.height - 4,
                  },
                });
                initialGridItemSizeData.shift(); //remove first item after added
              } else {
                const itemFillColumnIndex = initialGridItemSizeData.findIndex(
                  (item) => item.height <= remainingColumnHeight
                );
                if (itemFillColumnIndex !== -1) {
                  const itemMatched: ElementRenderSize<TItem> =
                    initialGridItemSizeData[itemFillColumnIndex];
                  columnItemChildren.push({
                    itemData: itemMatched.item,
                    itemStyle: {
                      width: "100%",
                      overflow: "scroll",
                      wordBreak: "break-word",
                      display: "flex",
                      height:
                        itemMatched.height <= minHeightOfGridItem
                          ? minHeightOfGridItem
                          : itemMatched.height,
                    },
                  });
                  initialGridItemSizeData = initialGridItemSizeData.filter(
                    (item, idx) => idx !== itemFillColumnIndex
                  ); //remove the added item out of initialData array
                }
              }
            }
          }

          //set the style for grid item
          if (columnItemChildren.length > 0) {
            const itemWithHighestHeight = columnItemChildren.reduce(
              (prev, current) =>
                prev.itemStyle.height &&
                current.itemStyle.height &&
                +prev.itemStyle.height > +current.itemStyle.height
                  ? prev
                  : current
            );
            columnItemChildren[
              columnItemChildren.indexOf(itemWithHighestHeight)
            ].itemStyle = {
              ...itemWithHighestHeight.itemStyle,
              height: undefined,
              flex: 1,
            };
          }

          pageData.push({
            columnStyle: { ...columnItemStyle },
            children: [...columnItemChildren],
          });
        }

        if (initialGridItemSizeData.length > 0) {
          const reduntdantData: TItem[] = initialGridItemSizeData.map(
            (item) => item.item
          );
          setInitialData((prev) => [
            ...reduntdantData,
            ...prev.slice(currentItemBatch.length),
          ]);
        }
        setCurrentItemBatch([]);
        if (!currentPageData) {
          console.log("addDataPage DATA:", pageData);
          addDataPage({ column: pageData });
        } else {
          console.log("EDIT DATA:", pageData);
          editPageData({ column: pageData }, currentPage);
        }
      }
    },
    [
      columnItemStyle,
      minHeightOfGridItem,
      numOfColPerPage,
      numOfItemPerCol,
      rootContainerSize.heightSize,
      addDataPage,
      currentPage,
      editPageData,
      currentPageData,
    ]
  );
  useEffect(() => {
    handleDistributeItems(itemSizeCollection);

    // if (pageData.column.length === numOfColPerPage) addDataPage(pageData);
  }, [itemSizeCollection]);
  return <>{virtualElementRenderer}</>;
}

export default React.memo(VirtualListRenderer<any>);
