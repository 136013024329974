import React from "react";
import { useQueryClient } from "@tanstack/react-query";

import { produce } from "immer";
import { Project } from "../../../Models/project";
import { useDoxleCurrentContextStore } from "../../../DoxleGeneralStore/useDoxleCurrentContext";
import {
  FilterGetProjectQuery,
  formFullDetailProjectListQKey,
} from "../../../Services/QueryHooks/projectQueryAPI";

type Props = {
  filter: FilterGetProjectQuery;
  addPos?: "start" | "end";
  overwrite?: boolean;
};
interface SetProjectQueryData {
  handleAddProjectQueryData: (newProject: Project) => void;
  handleDeleteProjectQueryData: (deletedProjectId: string) => void;
  handleUpdateProjectQueryData: (updatedProject: Project) => void;
}
const useSetProjectQueryData = ({
  filter,
  addPos = "end",
  overwrite,
}: Props): SetProjectQueryData => {
  const queryClient = useQueryClient();
  const { company } = useDoxleCurrentContextStore((state) => ({
    company: state.currentCompany,
  }));
  const view = window.location.href.toLowerCase().includes('noticeboard') ? 'noticeboard' : 'budget'
  const simpleProjectQKey = formFullDetailProjectListQKey(company, filter, view);
  const handleAddProjectQueryData = (newProject: Project) => {
    const queryData = queryClient.getQueryData(simpleProjectQKey);
    if (queryData && overwrite)
      queryClient.setQueryData(simpleProjectQKey, (old: any) => {
        if (old) {
          const totalNumOfPages = old.pages.length;
          return produce(old, (draftOld: any) => {
            if (addPos === "start") {
              (draftOld.pages[0].data.results as Project[]).unshift(newProject);
            } else {
              (
                draftOld.pages[totalNumOfPages - 1].data.results as Project[]
              ).push(newProject);
            }
            return draftOld;
          });
        } else queryClient.invalidateQueries(simpleProjectQKey);
      });
    else queryClient.invalidateQueries(simpleProjectQKey);
  };

  const handleDeleteProjectQueryData = (deletedProjectId: string) => {
    const queryData = queryClient.getQueryData(simpleProjectQKey);
    if (queryData && overwrite)
      queryClient.setQueryData(simpleProjectQKey, (old: any) =>
        produce(old, (draft: any) => {
          let pageIndexContainItem: number = old.pages.findIndex((page: any) =>
            Boolean(
              page.data.results.find(
                (project: Project) => project.projectId === deletedProjectId
              ) !== undefined
            )
          );
          if (pageIndexContainItem !== -1)
            draft.pages[pageIndexContainItem].data.results = draft.pages[
              pageIndexContainItem
            ].data.results.filter(
              (project: Project) => project.projectId !== deletedProjectId
            );
        })
      );
    else queryClient.invalidateQueries(simpleProjectQKey);
  };

  const handleUpdateProjectQueryData = (updatedProject: Project) => {
    const queryData = queryClient.getQueryData(simpleProjectQKey);
    if (queryData)
      queryClient.setQueryData(simpleProjectQKey, (old: any) => {
        if (old) {
          //find page contain deleted item
          let pageIndexContainItem: number = (
            old.pages as Array<any>
          ).findIndex((page) =>
            Boolean(
              (page.data.results as Project[]).find(
                (project) => project.projectId === updatedProject.projectId
              )
            )
          );
          if (pageIndexContainItem !== -1) {
            return produce(old, (draftOld: any) => {
              const updatedItem = (
                (draftOld.pages as Array<any>)[pageIndexContainItem].data
                  .results as Project[]
              ).find(
                (project) => project.projectId === updatedProject.projectId
              );
              if (updatedItem) Object.assign(updatedItem, updatedProject);
              return draftOld;
            });
          } else queryClient.invalidateQueries(simpleProjectQKey);
        } else queryClient.invalidateQueries(simpleProjectQKey);
      });
    else queryClient.invalidateQueries(simpleProjectQKey);
  };
  return {
    handleAddProjectQueryData,
    handleDeleteProjectQueryData,
    handleUpdateProjectQueryData,
  };
};

export default useSetProjectQueryData;
