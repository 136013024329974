import { create } from "zustand";
import { Company } from "../Models/company";
import { Project } from "../Models/project";
import { FileRejection } from "react-dropzone";
import { immer } from "zustand/middleware/immer";
export type TNavBarMenu =
  | "Our Story"
  | "Files"
  | "Font"
  | "Theme"
  | "Grid Mode"
  | "Inventory"
  | "Settings"
  | "Log out"
  | "Budgets"
  | "Pricebook"
  | "Contacts";
interface DoxleCurrentContextStore {
  currentProject: Project | undefined;
  setCurrentProject: (project: Project | undefined) => void;
  showProjectSideList: boolean;
  setShowProjectSideList: (value: boolean) => void;
  currentProjectStatusId: string | undefined;
  setCurrentProjectStatusId: (value: string | undefined) => void;

  currentSearchText: string;
  setCurrentSearchText: (value: string) => void;

  currentCompany: Company | undefined;
  setCurrentCompany: (company: Company | undefined) => void;

  isLoadingProjectDetail: boolean;
  setIsLoadingProjectDetail: (value: boolean) => void;

  currentEditCompany: Company | undefined;
  setCurrentEditCompany: (company: Company | undefined) => void;

  currentMenuPosition: "Top" | "Bottom";
  setCurrentMenuPosition: (position: "Top" | "Bottom") => void;

  displayedMenuItem: TNavBarMenu[];
  setDisplayedMenuItem: (menu: TNavBarMenu) => void;

  currentErrorFiles: FileRejection[];
  addCurrentErrorFiles: (files: FileRejection[]) => void;
  clearCurrentErrorFiles: () => void;
  initializeMenuPosition: () => void;
}

export const useDoxleCurrentContextStore = create(
  immer<DoxleCurrentContextStore>((set, get) => ({
    currentCompany: undefined,
    setCurrentCompany: (company: Company | undefined) => {
      set((state) => ({
        currentCompany: company,
        currentProjectStatusId: undefined,
      }));

      //!STORE TO LOCAL
      if (company) {
        localStorage.setItem("currentCompanyId", company.companyId);

        //!only set currentProject to undefined if the selected company is different from the current selected company
        if (
          get().currentCompany &&
          company?.companyId !== get().currentCompany?.companyId
        )
          set((state) => ({
            currentProject: undefined,
          }));
      }

      // else localStorage.removeItem("currentCompanyId");
    },

    currentProject: undefined,
    setCurrentProject: (project: Project | undefined) => {
      set((state) => ({ currentProject: project }));
      if (project) {
        localStorage.setItem("currentProjectId", project.projectId);
      } else localStorage.removeItem("currentProjectId");
    },
    showProjectSideList: true,
    setShowProjectSideList: (value: boolean) =>
      set({ showProjectSideList: value }),

    currentProjectStatusId: undefined,
    setCurrentProjectStatusId: (value: string | undefined) =>
      set((state) => ({ currentProjectStatusId: value })),

    currentSearchText: "",
    setCurrentSearchText: (value: string) =>
      set((state) => ({ currentSearchText: value })),

    isLoadingProjectDetail: false,
    setIsLoadingProjectDetail: (value: boolean) =>
      set({ isLoadingProjectDetail: value }),

    currentEditCompany: undefined,
    setCurrentEditCompany: (company: Company | undefined) => {
      set((state) => ({
        currentEditCompany: company,
      }));
    },

    currentMenuPosition: "Top",
    setCurrentMenuPosition(position: "Top" | "Bottom") {
      set((state) => ({
        currentMenuPosition: position,
      }));
      localStorage.setItem("lastMenuPosition", position);
    },

    displayedMenuItem: ["Files", "Budgets", "Pricebook"],
    setDisplayedMenuItem: (menu: TNavBarMenu) =>
      set((state) => {
        if (state.displayedMenuItem.includes(menu)) {
          state.displayedMenuItem = state.displayedMenuItem.filter(
            (item) => item !== menu
          );
        } else {
          state.displayedMenuItem.push(menu);
        }
      }),
    currentErrorFiles: [],
    addCurrentErrorFiles: (files: FileRejection[]) =>
      set((state) => {
        state.currentErrorFiles.push(...files);
      }),
    clearCurrentErrorFiles: () =>
      set((state) => {
        state.currentErrorFiles = [];
      }),
    initializeMenuPosition: () => {
      const lastMenuPosistionStorage = localStorage.getItem("lastMenuPosition");
      if (lastMenuPosistionStorage)
        get().setCurrentMenuPosition(
          lastMenuPosistionStorage as "Top" | "Bottom"
        );
    },
  }))
);
