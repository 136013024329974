import styled from "styled-components";
import {
  DoxleFont,
  DoxleTheme,
  DoxleThemeColor,
} from "../../../DoxleGeneralStore/useDoxleThemeStore";
import {
  Button,
  Checkbox,
  Menu,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { motion } from "framer-motion";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { editRgbaAlpha } from "../../../Utilities/FunctionUtilities";

export const StyledPageContainer = styled.div<{
  themeColor: DoxleThemeColor;
}>`
  width: 100%;
  height: calc(100% - 55px);
  padding-top: 55px;
  background-color: ${(p) => p.themeColor.primaryBackgroundColor};
  display: flex;
  flex-direction: column;
  align-items: center;
  .order-content-wrapper {
    width: calc(100% - 4rem);
    height: 100%;
    display: flex;
    flex-direction: column;
    margin: 0px 2rem;
    max-width: 1320px;
    min-width: 500px;
  }

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

export const StyledBodySectionContainer = styled.div<{
  themeColor: DoxleThemeColor;
}>`
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0px 0px 14px 0px;
  background-color: ${(p) => p.themeColor.primaryBackgroundColor};
  overflow: visible;
  position: relative;
  margin: 0px 144px;
  width: calc(100% - 288px);
  @media (max-width: 767px) {
    width: calc(100% - 160px);
    margin: 0px 80px;
  }
  @media (min-width: 768px) {
    width: calc(100% - 160px);
    margin: 0px 80px;
  }
  @media (min-width: 1024px) {
    width: calc(100% - 200px);
    margin: 0px 100px;
  }
  @media (min-width: 1280px) {
    margin: 0px 120px;
    width: calc(100% - 240px);
  }
`;

export const StyledHeaderSectionContainer = styled.div<{
  themeColor: DoxleThemeColor;
}>`
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0px 0px 14px 0px;
  background-color: ${(p) => p.themeColor.primaryBackgroundColor};
  overflow: visible;
  position: relative;
  margin: 0px 144px;
  width: calc(100% - 288px);
  @media (max-width: 767px) {
    width: calc(100% - 160px);
    margin: 0px 80px;
  }
  @media (min-width: 768px) {
    width: calc(100% - 160px);
    margin: 0px 80px;
  }
  @media (min-width: 1024px) {
    width: calc(100% - 200px);
    margin: 0px 100px;
  }
  @media (min-width: 1280px) {
    margin: 0px 120px;
    width: calc(100% - 240px);
  }
`;

export const StyledOrderTableHeaderRow = styled(TableRow)<{
  themeColor: DoxleThemeColor;
}>`
  && {
    width: 100% !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: ${(p) => p.themeColor.primaryBackgroundColor};
    height: 44px;
  }
`;

export const StyledOrderTableHeaderCell = styled(TableHead)<{
  doxleFont: DoxleFont;
  widthInPixel: `${number}px` | null;
  horizontalAlign?: "flex-start" | "center" | "flex-end";
  themeColor: DoxleThemeColor;
  paddingLeft?: number;
}>`
  && {
    ${(p) => (p.widthInPixel ? `width: ${p.widthInPixel};` : "flex:1;")}
    display: flex;
    justify-content: ${(p) =>
      p.horizontalAlign ? p.horizontalAlign : "center"};
    align-items: center;
    padding: 0px 30px;
    color: ${(p) => p.themeColor.primaryFontColor};
    text-transform: capitalize;
    font-family: ${(p) => p.doxleFont.primaryTitleFont};
  }
`;

export const StyledOrderTableHeaderText = styled(Typography)<{
  fontWeight?: number;
}>`
  && {
    padding-left: 5px;
    font-size: 14px;
    font-style: normal;
    font-weight: 200;
    line-height: normal;
    letter-spacing: -0.28px;
    font-family: "IBMPlexSans-Light";
    font-weight: ${(p) => p.fontWeight ?? 400};
    cursor: pointer;
  }
`;

export const StyledOrderTableRow = styled(motion.div)<{
  $themeColor: DoxleThemeColor;
  $currentTheme: DoxleTheme;
  $transparentMode: boolean;
}>`
  flex: 1;
  display: flex;
  flex-direction: row;
  background-color: ${(p) =>
    editRgbaAlpha({
      rgbaColor: p.$themeColor.primaryContainerColor,
      alpha: p.$transparentMode
        ? "0"
        : p.$currentTheme === "light"
        ? "0.3"
        : "0.5",
    })};
  height: 40px;
  align-items: center;
  cursor: pointer;
  transition: 0.4s;
  &:hover {
    background-color: ${(p) => p.$themeColor.rowHoverColor};
    transition: 0.4s;
  }
`;

export const StyledOrderRowWrapper = styled.div<{
  $themeColor?: DoxleThemeColor;
}>`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  position: relative;
  overflow: visible;
`;

export const StyledOrderNumberTableCell = styled(motion.div)<{
  $widthRatio: number;
  $textColor?: string;
  $paddingLeft?: number;
  $horizontalAlign?: "flex-start" | "center" | "space-between";
}>`
  height: 100%;
  width: calc(
    ${(p) => p.$widthRatio}% - 0.8rem - ${(p) => (p.$paddingLeft ?? 0) / 10}rem
  );

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${(p) => p.$horizontalAlign ?? "start"};
  padding-left: ${(p) => (p.$paddingLeft ?? 0) / 10}rem;
  margin: 0px 0.4rem;
  position: relative;

  .order-icon-wrapper {
    position: relative;
    margin-right: 4px;
  }
  span {
    color: ${(p) => p.theme.color.primaryFontColor};
    font-family: ${(p) => p.theme.font.subTitleFont};
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    &.payments {
      color: limegreen;
    }
  }
  .sign-order-text {
    color: ${(p) => p.theme.color.primaryFontColor};
    font-family: DearJoe4;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    overflow: visible;
    white-space: nowrap;
    background-color: ${(p) => p.theme.color.primaryBackgroundColor};
    bottom: 0px;
    left: 0px;
    border-radius: 50%;
    max-width: 20px;
    position: absolute;
    z-index: 1;
    transform: translate(-10%, 30%);
  }
`;

export const StyledOrderTableCell = styled(TableCell)<{
  widthInPixel: `${number}px` | null;
  hoverStatus: boolean;
  horizontalAlign?: "flex-start" | "center" | "flex-end";
  letterSpacing?: string;
  paddingLeft?: string;
  fontWeight?: string;
  fontColor?: string;
  themeColor: DoxleThemeColor;
  doxleFont: DoxleFont;
  currentTheme: string;
}>`
  && {
    ${(p) => (p.widthInPixel ? `width: ${p.widthInPixel};` : "flex:1;")}
    display: flex;
    height: 44px;
    border-bottom: 2px solid
      ${(p) =>
        p.currentTheme === "light"
          ? "rgba(239, 240, 244, 1)"
          : p.themeColor.primaryBackgroundColor};
    justify-content: ${(p) =>
      p.horizontalAlign ? p.horizontalAlign : "center"};
    align-items: center;
    font-family: ${(p) => p.doxleFont.primaryFont};
    font-size: 1.4rem;
    min-font-size: 12px;
    font-style: normal;
    font-weight: ${(p) => (p.fontWeight ? p.fontWeight : "500")};
    line-height: normal;
    letter-spacing: ${(p) => (p.letterSpacing ? p.letterSpacing : "0.7px")};
    color: ${(p) =>
      p.fontColor ? p.fontColor : p.themeColor.primaryFontColor};
    text-transform: capitalize;
    padding: 0px 30px;
    padding-left: ${(p) => (p.paddingLeft ? p.paddingLeft : "30px")};
    background-color: ${(p) =>
      p.hoverStatus
        ? p.currentTheme === "dark"
          ? ""
          : "#F5F6F7"
        : p.themeColor.primaryBackdropColor};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const StyledOrderStatusSpan = styled.span<{
  font: DoxleFont;
  bgColor: string;
  textColor: string;
}>`
  text-align: center;
  padding: 2px 8px;
  height: 18px;
  font-family: ${(p) => p.font.primaryFont};
  font-style: normal;
  font-weight: 600;
  font-size: 1.4rem;
  min-font-size: 12px;
  line-height: 17px;
  letter-spacing: 0.05em;
  margin: 0px 15px;
  color: ${(p) => p.textColor};
  background-color: ${(p) => p.bgColor};
  border-radius: 12px;
  min-width: 73px;
`;

export const StyledOrderHeaderContainer = styled.div<{
  $themeColor: DoxleThemeColor;
}>`
  width: calc(100%);
  margin-bottom: 2rem;

  display: flex;

  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  .search-wrapper {
    display: flex;
    width: calc(80% - 1.6rem);
    padding: 0px 0.8rem;
    max-width: 500px;
    flex-direction: row;
    align-items: center;

    height: 3rem;
    background: ${(p) => p.$themeColor.primaryTextFieldBackgroundColor};
    border-radius: 1.3rem;
    margin-top: 1.4rem;
  }
`;

export const StyledOrderHeaderTitle = styled(Typography)<{
  themeColor: DoxleThemeColor;
  font: DoxleFont;
}>`
  && {
    color: ${(p) => p.themeColor.primaryFontColor};
    font-family: ${(p) => p.font.primaryTitleFont};
    font-size: 30px;
    font-weight: 600;
  }
`;

export const StyledOrderHeaderSubTitle = styled.div<{
  themeColor: DoxleThemeColor;
  font: DoxleFont;
}>`
  text-align: left;
  color: ${(p) => p.themeColor.primaryFontColor};
  font-family: ${(p) => p.font.primaryTitleFont};
  font-size: 11px;
  width: 500px;
  align-self: center;
`;

export const StyledSearchField = styled(TextField)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  && {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    align-self: center;
    flex: 1;
  }
  input {
    padding: 0 !important;

    font-family: ${(p) => p.$doxleFont.secondaryTitleFont};
    font-style: normal;
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 1.6rem;
    letter-spacing: 0.05em;
    color: #000;
  }
  & .MuiOutlinedInput-notchedOutline {
    border: none;
  }
`;

export const RootOrderTabMenu = styled(motion.div)<{
  $editOrderPage?: boolean;
  $themeColor: DoxleThemeColor;
}>`
  display: flex;
  flex-direction: row;
  justify-content: ${(p) => (p.$editOrderPage ? "start" : "space-between")};
  width: ${(p) => (p.$editOrderPage ? "fit-content" : "100%")};
  border-bottom: 2px solid ${(p) => p.$themeColor.primaryDividerColor};
  padding-bottom: 4px;
  align-items: center;
`;

export const StyledOrderStatusMenuItemBtn = styled(motion(Button))<{
  $selected: boolean;
}>`
  && {
    min-width: 0 !important;
    padding: 4px 8px !important;
    margin: 0px 4px;
    position: relative;

    .order-name {
      color: ${(p) => (p.$selected ? "white" : p.theme.color.primaryFontColor)};
      text-transform: capitalize;
      font-family: ${(p) => p.theme.font.primaryFont};
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: normal;
      z-index: 1;
    }
    .selected-effect {
      position: absolute;
      border-radius: 4px;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
      background-color: ${(p) => p.theme.color.doxleColor3};
    }
  }
`;

export const StyledOrdersKebabMenu = styled(Menu)`
  & .MuiMenu-list {
    background-color: white;
    opacity: 1;
    transform-origin: 168.188px 0px;
    right: 10px;
  }
  & .MuiMenuItem-root {
    font-family: "IBM Plex Sans";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.05em;
  }
`;

export const StyledOrdersDatePickerView = styled(DesktopDatePicker)<{
  $themeColor: DoxleThemeColor;
}>`
  && {
    width: 40% !important;
  }
`;
export const StyledOrdersTableContainer = styled(motion.div)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;

  .order-status {
    color: ${(p) => p.$themeColor.primaryFontColor};
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-size: 1.8rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.09rem;
    margin-bottom: 1.4rem;
    margin-left: 0.8rem;
  }
  .order-list-wrapper {
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
  }
`;
export const StyledOrderListItemWrapper = styled(motion.div)`
  padding-bottom: 2px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const StyledGroupContentHeader = styled(motion.div)<{}>`
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 30px;
  color: ${(p) => p.theme.color.primaryFontColor};
  font-family: ${(p) => p.theme.font.primaryTitleFont};
  font-weight: 600;
  font-size: 16px;
`;

export const StyledGroupContentFooter = styled(motion.div)<{
  $balance?: boolean;
  $showTotal?: boolean;
}>`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  padding: 4px 0rem;
  width: calc(100% - 30px);
  padding-left: 30px;
  color: ${(p) => p.theme.color.primaryFontColor};
  font-family: ${(p) => p.theme.font.subTitleFont};
  font-weight: 600;
  font-size: 14px;

  .value {
    width: calc(10% - 2rem - 0.8rem);
    padding: 0px 0.4rem 0px 2.4rem;
  }
  .title {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    padding-right: 60px;
  }
  .spacer {
    flex: 1;
  }
  .balance {
    border-top: 1px solid ${(p) => p.theme.color.boldDividerColor};
    border-bottom: 1px solid ${(p) => p.theme.color.boldDividerColor};
  }
`;

export const StyledOrderDragIconContainer = styled.div`
  width: 5rem;
  cursor: grab;
  font-size: 2.6rem;
  justify-content: flex-end;
  display: flex;
  flex-direction: row;
`;

export const StyledAddOrderTextField = styled(TextField)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  && {
    height: 2.7rem;
    min-height: 25px;
    max-height: 30px;
    overflow: hidden;
    min-width: 100px;
    width: calc(100% - 1.6rem);
    padding: 0.8rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    //border-top: 1px solid ${(p) => p.$themeColor.primaryDividerColor};
    border-bottom: 1px solid
      ${(p) =>
        editRgbaAlpha({
          rgbaColor: p.$themeColor.primaryDividerColor,
          alpha: "0.5",
        })};
  }
  input {
    font-family: "IBM Plex Mono";
    font-weight: 300;
    font-size: 1.3rem;
    min-font-size: 13px;
    line-height: normal;
    width: 100% !important;
    color: ${(p) => p.$themeColor.primaryFontColor};
    padding: 0px !important;
    &::placeholder {
      font-family: "IBM Plex Mono";
      font-weight: 300;
      font-size: 1.3rem;
      min-font-size: 13px;
      line-height: normal;
      color: ${(p) => p.$themeColor.primaryInputPlaceholderColor};
      padding: 0px !important;
      opacity: 1;
    }
  }
  & .MuiInput-root:after {
    border-bottom: none !important;
  }
  & .MuiInput-root:before {
    border-bottom: none !important;
  }
  & .MuiInput-root {
    border-bottom: none !important;
  }
  & .MuiInput-root:hover:not(.Mui-disabled, .Mui-error):before {
    border-bottom: none !important;
  }
  & .MuiInputBase-root {
    width: 100%;
  }
`;

export const StyledOrderRowCheckbox = styled(Checkbox)`
  && {
    width: 30px;
    display: flex;
    justify-content: flex-start;
    padding: 0px !important;
  }
`;
