import React, { useEffect, useMemo, useState } from "react";
import { useProjectStore } from "../../DoxleGeneralStore/useprojectStore";
import { shallow } from "zustand/shallow";
import { useEditDocketSideScreenStore } from "../../CoreContent/EditDocketSideScreen/EditDocketSideScreenStore/useEditDocketSideScreenStore";
import { useContactsStore } from "../Content/Store/useContactsStore";
import { useDoxleCurrentContextStore } from "../../DoxleGeneralStore/useDoxleCurrentContext";
import {
  ContactCompanyFilters,
  ContactsFilters,
} from "../../Services/QueryHooks/contactsFilters";
import { useIsMutating } from "@tanstack/react-query";
import { useShallow } from "zustand/react/shallow";

type Props = { context?: "Project" | "DocketFullScreen" | "DocketHalfScreen" };
interface IContacts {
  displayDropDown: DOMRect | null;
  setDisplayDropDown: React.Dispatch<React.SetStateAction<DOMRect | null>>;
  isAddingContacts: boolean;

  handleShowDropDown: (
    e: React.MouseEvent<HTMLButtonElement | HTMLDivElement>
  ) => void;
}
const useContacts = ({ context }: Props): IContacts => {
  const [displayDropDown, setDisplayDropDown] = useState<DOMRect | null>(null);
  const { currentSearchText } = useDoxleCurrentContextStore(
    useShallow((state) => ({ currentSearchText: state.currentSearchText }))
  );

  const setDocketAddButton = useEditDocketSideScreenStore(
    useShallow((state) => state.setHandleAddButtonFn)
  );
  const {
    view,
    setContactsQueryFilter,
    setContactCompanyQueryFilter,
    setView,
    resetStore,
  } = useContactsStore(
    useShallow((state) => ({
      view: state.view,
      setContactsQueryFilter: state.setContactQueryFilter,
      setContactCompanyQueryFilter: state.setContactCompanyQueryFilter,
      setView: state.setView,
      resetStore: state.resetStore,
    }))
  );
  const handleShowDropDown = (
    e: React.MouseEvent<HTMLButtonElement | HTMLDivElement>
  ) => {
    e.stopPropagation();
    const bounding = e.currentTarget.getBoundingClientRect();
    setDisplayDropDown(bounding);
  };
  const urlView = window.location.href.toUpperCase().includes("CONTACT")
    ? "Contacts"
    : "Companies";

  const filterContactList: ContactsFilters = useMemo(
    () => ({
      search: currentSearchText,
    }),
    [currentSearchText]
  );

  const filterContactCompanyList: ContactCompanyFilters = useMemo(
    () => ({
      search: currentSearchText,
    }),
    [currentSearchText]
  );
  useEffect(() => {
    resetStore();
  }, []);
  useEffect(() => {
    setContactsQueryFilter(filterContactList);
  }, [filterContactList]);
  useEffect(() => {
    setContactCompanyQueryFilter(filterContactCompanyList);
  }, [filterContactCompanyList]);

  const isAddingContact = useIsMutating({ mutationKey: ["add-contact"] }) > 0;
  const isAddingContactCompany =
    useIsMutating({
      mutationKey: ["add-contact-company"],
    }) > 0;

  useEffect(() => {
    if (context === "DocketFullScreen" || context === "DocketHalfScreen")
      setDocketAddButton([
        {
          btnFunction: handleShowDropDown,
          btnText: "New",
          showBtnIcon: false,
          isLoading: isAddingContact || isAddingContactCompany,
        },
      ]);
    if (view !== urlView) setView(urlView);
  }, [isAddingContact, isAddingContactCompany]);
  return {
    displayDropDown,
    setDisplayDropDown,
    isAddingContacts: isAddingContact || isAddingContactCompany,
    handleShowDropDown,
  };
};

export default useContacts;
