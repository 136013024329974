import React, { useState } from "react";
import { useEditOrderStore } from "../../../OrdersStore/useEditOrderStore";
import { shallow } from "zustand/shallow";
import { useDoxleAuthStore } from "../../../../DoxleGeneralStore/useDoxleAuthStore";
import { useDoxleCurrentContextStore } from "../../../../DoxleGeneralStore/useDoxleCurrentContext";
import useDoxleNotificationStore from "../../../../DoxleGeneralStore/useDoxleNotificationStore";
import { useOrdersStore } from "../../../OrdersStore/useOrdersStore";
import Order from "../../../Models/order";
import OrdersQueryAPI from "../../../QueryHooks/OrdersQueryAPI";
import { useEditOrderContext } from "../EditOrder";
import { produce } from "immer";

type Props = {};
interface EditOrderPaymentAndLateFee {
  handleBlurPaymentTextField: () => void;
  handleBlurLatefeeTextField: () => void;
  paymentText: string;
  setPaymentText: React.Dispatch<React.SetStateAction<string>>;
  lateFeeText: string;
  setLateFeeText: React.Dispatch<React.SetStateAction<string>>;
}
const useEditOrderPaymentAndLateFee = (
  props: Props
): EditOrderPaymentAndLateFee => {
  const { editedOrder, updateEditedOrder } = useEditOrderStore(
    (state) => ({
      editedOrder: state.editedOrder,
      setEditedOrder: state.setEditedOrder,
      updateEditedOrder: state.updateEditedOrder,
    }),
    shallow
  );
  const [paymentText, setPaymentText] = useState(
    editedOrder?.paymentTerms?.toString() ?? "14"
  );
  const [lateFeeText, setLateFeeText] = useState(
    editedOrder?.damages?.toString() ?? "0.00"
  );
  const editOrderContext = useEditOrderContext();
  const { setUpdatedOrder } = editOrderContext
    ? editOrderContext : {setUpdatedOrder: (x: any)=>{}}
  const company = useDoxleCurrentContextStore(
    (state) => state.currentCompany,
    shallow
  );

  const { queryFilter } = useOrdersStore(
    (state) => ({ queryFilter: state.queryFilter }),
    shallow
  );

  const onSuccessUpdateOrder = (order?: Order) => {
    if (order) updateEditedOrder(order);
  };
  const { patchOrder } = OrdersQueryAPI.useMutateOrderQuery({
    company,
    // showNotification,
    filter: queryFilter,
    onUpdateSuccessCb: onSuccessUpdateOrder,
  });

  const handleBlurPaymentTextField = () => {
    if (editedOrder)
      if (paymentText) {
        if (paymentText !== editedOrder?.paymentTerms?.toString()) {
          // patchOrder.mutate({
          //   orderId: editedOrder.orderId,
          //   order: { paymentTerms: Number(paymentText) },
          // });
          setUpdatedOrder(
            produce((draft) => {
              if (draft) {
                draft.paymentTerms = Number(paymentText);
              } else
                draft = {
                  paymentTerms: Number(paymentText),
                };
              return draft;
            })
          );
        }
      } else setPaymentText(editedOrder.paymentTerms?.toString());
  };
  const handleBlurLatefeeTextField = () => {
    if (editedOrder)
      if (lateFeeText) {
        if (lateFeeText !== editedOrder?.damages?.toString()) {
          // patchOrder.mutate({
          //   orderId: editedOrder.orderId,
          //   order: { damages: Number(lateFeeText) },
          // });
          setUpdatedOrder(
            produce((draft) => {
              if (draft) {
                draft.damages = Number(lateFeeText);
              } else
                draft = {
                  damages: Number(lateFeeText),
                };
              return draft;
            })
          );
        }
      } else setLateFeeText(editedOrder.damages?.toString());
  };
  return {
    handleBlurPaymentTextField,
    handleBlurLatefeeTextField,
    paymentText,
    setPaymentText,
    lateFeeText,
    setLateFeeText,
  };
};

export default useEditOrderPaymentAndLateFee;
