import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import {
  AxiosBackendErrorReturn,
  IApiPaginatedData,
} from "../../Models/axiosReturn";
import { BaseAPIProps } from "../../Models/baseAPIProps";
import { Company } from "../../Models/company";
import DoxleAPI from "../../Services/DoxleAPI";
import { InventoryItem, NewInventoryItem } from "../Models/Inventory";
import { useDoxleCurrentContextStore } from "../../DoxleGeneralStore/useDoxleCurrentContext";
import { shallow } from "zustand/shallow";
import useSetInventoryQueryData from "./useSetInventoryQueryData";
import { isAxiosError } from "axios";

export interface IFilterInventoryQuery {
  search?: string;
}

export interface InventoryQueryParam {
  search?: string;
}

export interface InventoryGetQueryProps extends BaseAPIProps {
  search?: string;
}

export const getInventoryQKey = (
  company: Company | undefined,
  search: string = ""
) => {
  let baseQKey = ["inventory-get"];
  if (company) baseQKey.push(company.companyId);
  if (search) baseQKey.push(search);
  return baseQKey;
};

const useGetInventoryQuery = ({
  search,
  showNotification,
}: InventoryGetQueryProps) => {
  const company = useDoxleCurrentContextStore(
    (state) => state.currentCompany,
    shallow
  );
  const qKey = getInventoryQKey(company, search);
  let inventoryUrl = "/inventory/";
  let params: InventoryQueryParam = {};
  if (search) params.search = search;
  return useInfiniteQuery(
    qKey,
    async () => {
      return DoxleAPI.get<IApiPaginatedData<InventoryItem>>(inventoryUrl, {
        headers: {
          "User-Company": company?.companyId,
        },
        params,
      });
    },
    {
      onSuccess: (res) => {},

      onError: (error) => {
        if (showNotification) {
          if (isAxiosError<AxiosBackendErrorReturn>(error)) {
            showNotification(
              `${error?.response?.status ?? "ERROR"}: ${
                error.response?.data.detail ?? "UNKNOWN ERROR"
              }`,
              "error",
              String(
                error?.response?.data?.detail ?? "Failed to get inventories"
              ).substring(0, 300)
            );
          } else {
            showNotification(
              "Something Wrong!",
              "error",
              "Failed to get inventories"
            );
          }
        }
      },
      enabled: Boolean(company),
      staleTime: 5 * 60 * 1000,
      cacheTime: 6 * 60 * 1000,
      refetchInterval: 6 * 60 * 1000,
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: true,
    }
  );
};

const useGetSingleInventoryById = (inventoryId: string | undefined) => {
  const company = useDoxleCurrentContextStore(
    (state) => state.currentCompany,
    shallow
  );
  const qKey = ["singleInventory", inventoryId];
  let inventoryUrl = "/inventory/" + inventoryId + "/";
  return useQuery(
    qKey,
    async () => {
      return DoxleAPI.get<InventoryItem>(inventoryUrl, {
        headers: {
          "User-Company": company?.companyId,
        },
      });
    },

    {
      enabled: Boolean(inventoryId),
      staleTime: 0,
      cacheTime: 0,
      refetchOnMount: true,
    }
  );
};

export interface InventoryAddQueryProps extends BaseAPIProps {
  onSuccessAddCb?: (newInventory?: InventoryItem) => void;
}
interface AddInventoryParams {
  title: string;
  subtitle?: string;
  quantity?: string;
  cost?: string;
}
const useAddInventoryQuery = ({
  showNotification,
  company,
  onSuccessAddCb,
}: InventoryAddQueryProps) => {
  // const { handleAddDrawing } = useSetDrawingsQueryData({ projectId });
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationKey: ["Add-Inventory-Mutation"],
    mutationFn: async ({
      title,
      subtitle,
      quantity,
      cost,
    }: AddInventoryParams) => {
      const formData = new FormData();
      formData.append("title", title);
      if (subtitle) formData.append("subTitle", subtitle);
      if (quantity) formData.append("quantity", quantity.toString());
      if (cost) formData.append("cost", cost.toString());

      return DoxleAPI.post<InventoryItem>("/inventory/", formData, {
        headers: {
          "User-Company": company?.companyId || "",
        },
      });
    },
    onMutate: () => {
      const qKey = getInventoryQKey(company);
      queryClient.cancelQueries(qKey);
    },
    onSuccess: (result, variables, context) => {
      if (onSuccessAddCb) onSuccessAddCb(result.data);
    },
    onError: (error, variables, context) => {
      if (showNotification) {
        if (isAxiosError<AxiosBackendErrorReturn>(error)) {
          showNotification(
            `${error?.response?.status ?? "ERROR"}: ${
              error.response?.data.detail ?? "UNKNOWN ERROR"
            }`,
            "error",
            String(
              error?.response?.data?.detail ?? "Failed to add inventory"
            ).substring(0, 300)
          );
        } else {
          showNotification(
            "Something Wrong!",
            "error",
            "Failed to add inventory"
          );
        }
      }
    },
  });
  return mutation;
};

export interface InventoryDeleteQueryProps extends BaseAPIProps {
  onSuccessDeleteCb?: (deletedIds?: string) => void;
}

const useDeleteInventoryQuery = ({
  showNotification,
  onSuccessDeleteCb,
}: InventoryDeleteQueryProps) => {
  const queryClient = useQueryClient();
  const { handleDeleteInventory } = useSetInventoryQueryData({});
  const company = useDoxleCurrentContextStore(
    (state) => state.currentCompany,
    shallow
  );
  const mutation = useMutation({
    mutationFn: async (inventoryId: string) => {
      return DoxleAPI.delete("/inventory/" + inventoryId + "/", {
        headers: {
          "User-Company": company?.companyId || "",
        },
      });
    },
    onMutate: () => {
      const qKey = getInventoryQKey(company);
      queryClient.cancelQueries(qKey);
    },
    onSuccess: (result, variables, context) => {
      if (onSuccessDeleteCb) onSuccessDeleteCb(variables);
      handleDeleteInventory(variables);

      // if (showNotification)
      //   showNotification(
      //     "Inventory Deleted",
      //     "success",
      //     "SUCCESSFULLY Deleted Inventory"
      //   );
    },
    onError: (error, variables, context) => {
      if (showNotification) {
        if (isAxiosError<AxiosBackendErrorReturn>(error)) {
          showNotification(
            `${error?.response?.status ?? "ERROR"}: ${
              error.response?.data.detail ?? "UNKNOWN ERROR"
            }`,
            "error",
            String(
              error?.response?.data?.detail ?? "Failed to delete inventory"
            ).substring(0, 300)
          );
        } else {
          showNotification(
            "Something Wrong!",
            "error",
            "Failed to delete inventory"
          );
        }
      }
    },
  });

  return mutation;
};

export interface InventoryUpdateQueryProps extends BaseAPIProps {
  onSuccessUpdateCb?: (newInventory?: InventoryItem) => void;
}
interface UpdateInventorySetParams {
  inventoryId: string;
  updatedData: Partial<
    Pick<InventoryItem, "cost" | "title" | "subtitle" | "quantity">
  >;
}

const useUpdateInventoryQuery = ({
  showNotification,
  company,
  onSuccessUpdateCb,
}: InventoryUpdateQueryProps) => {
  const { handleUpdateInventoryList } = useSetInventoryQueryData({});
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async (updateBody: UpdateInventorySetParams) => {
      const { inventoryId, updatedData } = updateBody;
      return DoxleAPI.patch<InventoryItem>(
        `/inventory/${inventoryId}/`,
        updatedData,
        {
          headers: {
            "User-Company": company?.companyId,
          },
        }
      );
    },
    onMutate: () => {
      const qKey = getInventoryQKey(company);
      queryClient.cancelQueries(qKey);
    },
    onSuccess: (result, variables, context) => {
      if (onSuccessUpdateCb) onSuccessUpdateCb(result.data);
      handleUpdateInventoryList(result.data);
      // if (showNotification) showNotification("Inventory Updated", "success");
    },
    onError: (error, variables, context) => {
      if (showNotification) {
        if (isAxiosError<AxiosBackendErrorReturn>(error)) {
          showNotification(
            `${error?.response?.status ?? "ERROR"}: ${
              error.response?.data.detail ?? "UNKNOWN ERROR"
            }`,
            "error",
            String(
              error?.response?.data?.detail ?? "Failed to update inventory"
            ).substring(0, 300)
          );
        } else {
          showNotification(
            "Something Wrong!",
            "error",
            "Failed to update inventory"
          );
        }
      }
    },
    // onSettled: () => {
    //   queryClient.refetchQueries({
    //     queryKey: getInventoryQKey(company),
    //   });
    // },
  });

  return mutation;
};

export interface SetInventoryDefaultImgQueryProps extends BaseAPIProps {
  onSuccessUpdateCb?: (newInventory?: InventoryItem) => void;
}
interface SetInventoryDefaultImgParams {
  imageId: string;
  isDefault: boolean;
  index?: number;
}

const useSetInventoryDefaultImgQuery = ({
  showNotification,
  company,
  onSuccessUpdateCb,
}: SetInventoryDefaultImgQueryProps) => {
  const { handleUpdateInventoryList } = useSetInventoryQueryData({});
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async (updateBody: SetInventoryDefaultImgParams) => {
      const { imageId, ...updatedData } = updateBody;
      return DoxleAPI.patch<InventoryItem>(
        `/inventory/image/` + imageId + `/`,
        updatedData,
        {
          headers: {
            "User-Company": company?.companyId,
          },
        }
      );
    },
    onMutate: () => {
      const qKey = getInventoryQKey(company);
      queryClient.cancelQueries(qKey);
    },
    onSuccess: (result, variables, context) => {
      if (onSuccessUpdateCb) onSuccessUpdateCb(result.data);
      handleUpdateInventoryList(result.data);
      // if (showNotification)
      //   showNotification("Default Image Updated", "success");
    },
    onError: (error, variables, context) => {
      if (showNotification) {
        if (isAxiosError<AxiosBackendErrorReturn>(error)) {
          showNotification(
            `${error?.response?.status ?? "ERROR"}: ${
              error.response?.data.detail ?? "UNKNOWN ERROR"
            }`,
            "error",
            String(
              error?.response?.data?.detail ?? "Fail To Update Default Image"
            ).substring(0, 300)
          );
        } else {
          showNotification(
            "Something Wrong!",
            "error",
            "Fail To Update Default Image"
          );
        }
      }
    },
  });

  return mutation;
};

const InventoryQueryAPI = {
  useAddInventoryQuery,
  useGetInventoryQuery,
  useGetSingleInventoryById,
  useUpdateInventoryQuery,
  useDeleteInventoryQuery,
  useSetInventoryDefaultImgQuery,
};
export default InventoryQueryAPI;
