import { styled } from "styled-components";
import {
  DoxleFont,
  DoxleTheme,
  DoxleThemeColor,
} from "../../DoxleGeneralStore/useDoxleThemeStore";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import Popover from "@mui/material/Popover";
import { motion } from "framer-motion";
import { editRgbaAlpha } from "../../Utilities/FunctionUtilities";
import { ToastContainer } from "react-toastify";
import Paper, { PaperProps } from "@mui/material/Paper";
import InputAdornment from "@mui/material/InputAdornment";
export const StyledProjectRootContainer = styled(motion.div)<{}>`
  display: flex;
  width: calc(100%);
  height: calc(100%);
  flex-direction: column;
  justify-content: center;

  overflow: hidden;

  background-color: ${(p) => p.theme.color.primaryBackgroundColor};
  min-width: 600px;
  max-width: 1280px;
`;

export const StyledProjectMenuContainer = styled.div<{}>`
  display: flex;
  flex-direction: column;
  justify-content: center;

  /* align-items: center; */

  .greeting-text {
    color: ${(p) => p.theme.color.primaryFontColor};
    font-family: ${(p) => p.theme.font.primaryTitleFont};
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    max-width: 80%;
    text-align: left;
    margin-top: 10px;
  }
`;

export const StyledProjectMenuSpaceBetweenDiv = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #c3c3cf;
  width: 100%;
  min-width: 1200px;
  align-items: center;
  padding-bottom: 4px;
`;

export const StyledProjectMenuMenuTextField = styled(TextField)<{
  themeColor: DoxleThemeColor;
  doxleFont: DoxleFont;
}>`
  && {
    width: 250px;
    height: 30px;
    border-radius: 3px;
    background-color: ${(p) => p.themeColor.primaryTextFieldBackgroundColor};
    margin: 10px 10px 20px 10px;
  }
  input {
    padding: 10px !important;
    font-family: ${(p) => p.doxleFont.primaryFont};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.28px;
    color: ${(p) => p.themeColor.primaryFontColor};

    &::placeholder {
      font-family: ${(p) => p.doxleFont.primaryFont};
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.28px;
      color: ${(p) => p.themeColor.primaryInputPlaceholderColor};
    }
  }
  & .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }
  & .MuiInputBase-root {
    height: 100%;
  }
`;
export const StyledProjectMenuSpan = styled.span`
  display: flex;
  align-items: center;
`;
export const StyledProjectRowPadding = styled.span<{
  paddingLeft?: string;
  paddingRight?: string;
}>`
  padding-left: ${(p) => (p.paddingLeft ? p.paddingLeft : "0px")};
  padding-right: ${(p) => (p.paddingRight ? p.paddingRight : "0px")};
`;
export const StyledProjectListGroup = styled.li<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  color: ${(p) => p.$themeColor.doxleColor};
  font-family: ${(p) => p.$doxleFont.primaryTitleFont};
  font-size: 14px;
  font-weight: 600;
  padding: 5px 0px 2px 0px;
`;
export const StyledProjectListOption = styled.li<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  color: ${(p) => p.$themeColor.primaryFontColor};
  font-family: ${(p) => p.$doxleFont.primaryFont};
  font-size: 13px;
  cursor: pointer;
  font-weight: 400;
  padding: 2px 0px;
  &:hover {
    background-color: ${(p) => p.$themeColor.primaryHoverColor} !important;
  }
`;

export const StyledProjectTextField = styled(TextField)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  && {
    background-color: ${(p) => p.$themeColor.primaryTextFieldBackgroundColor};
    margin: 0px;
  }
  input {
    padding: 0px 12px !important;
    color: ${(p) => p.$themeColor.primaryFontColor};
    // font-family: ${(p) => p.$doxleFont.primaryFont};
    font-family: "IBM Plex Mono";
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 118.9%;
    letter-spacing: -0.54px;

    &::placeholder {
      color: ${(p) => p.$themeColor.primaryFontColor};
      // font-family: ${(p) => p.$doxleFont.primaryFont};
      font-family: "IBM Plex Mono";
      font-size: 13px;
      font-style: normal;
      font-weight: 700;
      line-height: 118.9%;
      letter-spacing: -0.54px;
    }
  }
  & .MuiInputBase-root {
    height: 100%;
  }
`;

export const StyledProjectMenuRoot = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  display: flex;
  flex-direction: row;
  height: 30px;
  width: 100%;

  min-width: 600px;
  justify-content: center;
  align-items: center;
  margin-top: 8px;
  margin-bottom: 2rem;
  border-bottom: 1px solid ${(p) => p.$themeColor.primaryDividerColor};
  .menu-tab-section {
    display: flex;
    flex-direction: row;
    align-items: center;

    overflow-x: scroll;
    height: 100%;
    overflow-y: visible;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    ::-webkit-scrollbar {
      display: none;
    }
  }
  .menu-tab-section::-webkit-scrollbar {
    display: none;
  }
  .sub-menu-section {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .filter-display-section {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0px 8px;
  }
`;

export const StyledProjectTabIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
`;

export const StyledProjectTabText = styled.div<{
  $selected: boolean;
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
  $disabled: boolean;
}>`
  position: relative;
  overflow: visible;
  font-family: ${(p) => p.$doxleFont.secondaryTitleFont};
  font-size: 14px;
  color: ${(p) => p.$themeColor.primaryFontColor};
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: ${(p) => (p.$selected ? 600 : 500)};

  padding: 2px 8px;
  min-width: 50px;
  cursor: pointer;
  margin: 0px 4px;
  transition: 0.4s;
  span {
    z-index: 3;
  }
  &:hover {
    opacity: 0.8;
    transform: translateY(-2px);
    transition: 0.4s ease-in-out all;
    border-bottom: none;
  }
  &:active {
    transform: scale(0.9);
    transition: 0.4s;
  }
  .selected-effect {
    position: absolute;
    z-index: 0;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-color: ${(p) => p.$themeColor.primaryFontColor};
    border-radius: 4px;
  }
  .underline-effect {
    position: absolute;
    z-index: 0;
    left: 0;
    right: 0;
    bottom: -4px;
    height: 1px;
    background-color: ${(p) => p.$themeColor.primaryFontColor};
  }
`;

export const StyledCollapseMenuButton = styled.div`
  border-radius: 10px;
  margin: 0px 4px;
  padding: 4px 12px;
  position: relative;
  align-items: center;
  display: flex;
  height: fit-content;
  cursor: pointer;
  font-family: ${(p) => p.theme.font.subTitleFont};
  color: ${(p) => p.theme.color.primaryReverseFontColor};
  background-color: ${(p) => p.theme.color.primaryFontColor};
  font-size: max(1.4rem, 14px);
  transition: 0.4s;
  &:hover {
    opacity: 0.8;
    transform: translateY(-2px);
    transition: 0.4s;
    border-bottom: none;
  }
  .underline-effect {
    position: absolute;
    z-index: 0;
    left: 0;
    right: 0;
    bottom: -5px;
    height: 1px;
    background-color: ${(p) => p.theme.color.primaryFontColor};
  }
`;

export const StyledProjectTabDisplayer = styled(motion.div)`
  flex: 1;
  min-height: 0px;

  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const StyledSelectedProjectDisplay = styled(motion.div)<{}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: flex-start;
  transition: 0.4s;
  cursor: pointer;
  &:hover span {
    color: ${(p) => p.theme.color.doxleColor};
    transition: 0.4s;
  }

  span {
    color: ${(p) => p.theme.color.primaryFontColor};
    font-family: ${(p) => p.theme.font.primaryFont};
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    margin-left: 8px;
  }
`;
export const StyledProjectDropdownContainer = styled(motion.div)<{}>`
  width: 50vw;
  min-width: 300px;
  max-width: 450px;
  min-height: 200px;

  height: 50vh;
  position: relative;
  max-height: 500px;
  background-color: ${(p) => p.theme.color.primaryContainerColor};
  padding: 14px;
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  border: 1px solid ${(p) => p.theme.color.primaryDividerColor};
  overflow: hidden;
  .dropdown-project-title {
    color: white;
    font-family: ${(p) => p.theme.font.primaryFont};
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
    padding: 7px 14px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    background-color: ${(p) => p.theme.color.doxleColor};
  }
  .divider {
    height: 1px;
    width: 100%;
    align-self: center;
    background-color: ${(p) => p.theme.color.primaryDividerColor};
    margin-bottom: 8px;
    margin-top: -2px;
  }
  .textfield-section-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 8px;
    height: 34px;
    overflow: hidden;
  }
`;
export const StyledProjectStatusText = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  color: ${(p) => p.$themeColor.doxleColor};
  font-family: ${(p) => p.$doxleFont.primaryFont};
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  padding: 8px;
  background-color: ${(p) => p.$themeColor.primaryContainerColor};
`;

export const StyledProjectSelectionItem = styled(motion.div)<{
  $selected: boolean;
}>`
  width: calc(100% - 16px);
  padding: 0px 8px;
  height: 35px;
  display: flex;
  align-items: center;
  color: ${(p) => p.theme.color.primaryFontColor};
  font-family: ${(p) => p.theme.font.primaryFont};
  font-size: 16px;
  font-style: normal;
  font-weight: ${(p) => (p.$selected ? 500 : 400)};
  line-height: normal;
  text-transform: capitalize;
  cursor: pointer;
  transition: 0.4s;
  border-radius: 0px 4px 4px 4px;
  &:hover {
    transition: 0.2s;

    background-color: ${(p) => p.theme.color.rowHoverColor};
  }
`;
export const StyledProjectListScroller = styled.div`
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;
export const StyledSearchProjectDropdownTextField = styled(
  motion(TextField)
)<{}>`
  && {
    flex: 1;
    height: 30px;

    padding: 0px 8px;
  }
  input {
    font-family: ${(p) => p.theme.font.primaryFont};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 0px !important;
    color: ${(p) => p.theme.color.primaryFontColor};

    &::placeholder {
      font-family: ${(p) => p.theme.font.primaryFont};
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      opacity: 1;
      color: ${(p) =>
        editRgbaAlpha({
          rgbaColor: p.theme.color.primaryFontColor,
          alpha: "0.4",
        })};
    }
  }
  & .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }
  & .MuiInputBase-root {
    height: 100%;
  }
`;
export const StyledErrorProjectDisplay = styled(motion.div)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  color: red;
  font-family: ${(p) => p.$doxleFont.primaryTitleFont};
  font-size: 2.7rem;
  font-style: normal;
  font-weight: 600;
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: 0.4s;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
    transition: 0.4s;
  }
`;

export const StyledProjectSearchBarContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
`;

export const StyledProjectSearchTextField = styled(TextField)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  && {
    width: 60%;
    height: 3rem;
    border-radius: 1.3rem;
    background-color: ${(p) => p.$themeColor.primaryTextFieldBackgroundColor};
    max-width: 450px;
  }
  input {
    padding: 10px !important;
    font-family: ${(p) => p.$doxleFont.secondaryTitleFont};
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.028rem;
    color: ${(p) => p.$themeColor.primaryFontColor};

    &::placeholder {
      font-family: ${(p) => p.$doxleFont.secondaryTitleFont};
      font-size: 1.4rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.028rem;
      color: ${(p) => p.$themeColor.primaryInputPlaceholderColor};
      opacity: 1;
    }
  }
  & .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }
  & .MuiInputBase-root {
    height: 100%;
  }
`;
export const StyledCheckboxFormControlLabel = styled(motion(FormControlLabel))<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  && {
    margin: 0px 2px !important;
  }
  & .MuiFormControlLabel-label {
    color: ${(p) => p.$themeColor.primaryFontColor};
    font-family: ${(p) => p.$doxleFont.subTitleFont};
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.4268rem;
    margin: 0px !important;
  }
`;

interface Coordinates {
  x: number;
  y: number;
}

export const StyledFilterDropdownContainer = styled(motion.div)<{
  $themeColor: DoxleThemeColor;
  $coordinates: null | Coordinates;
}>`
  //height: calc(30vh - 28px);
  background-color: ${(p) => p.$themeColor.primaryContainerColor};
  box-shadow: 0px 1px 1px 0px ${(p) => p.$themeColor.primaryBoxShadowColor};
  width: 9vw;
  position: absolute;
  right: ${(p) => window.innerWidth - (p.$coordinates?.x ?? 0)}px;
  top: ${(p) => (p.$coordinates?.y ?? 0) - 42}px;
  padding: 10px 0px;
  min-width: 200px;
  //min-height: 200px;
  overflow: hidden;
  z-index: 50;
  border: 1px solid ${(p) => p.$themeColor.primaryDividerColor};
`;

export const StyledFilterDropdownListItem = styled.li<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  color: ${(p) =>
    editRgbaAlpha({ rgbaColor: p.$themeColor.primaryFontColor, alpha: "0.7" })};
  font-family: ${(p) => p.$doxleFont.primaryFont};
  font-size: 13px;
  cursor: pointer;
  font-weight: 400;
  padding: 2px 8px;
  list-style-type: none;
`;

export const StyledDropdownListItemContainer = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
  $currentTheme: DoxleTheme;
}>`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 15px 20px;
  &:hover {
    background-color: ${(p) =>
      p.$currentTheme === "light"
        ? p.$themeColor.primaryHoverColor
        : p.$themeColor.doxleColor2};
  }
`;

export const StyledDividerLine = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  height: 10px;
  border-bottom: 1px solid ${(p) => p.$themeColor.primaryDividerColor};
  margin: 0 1rem;
`;

export const StyledSelectedProjectIcon = styled.img`
  width: 2.5378rem;
  height: 2.8363rem;
  flex-shrink: 0;
  fill: #000;
  margin-right: 0.6rem;
`;

export const StyledToastContainer = styled(ToastContainer)`
  &&&.Toastify__toast-container {
  }
  .Toastify__toast {
    box-shadow: none;
  }
  .Toastify__toast-theme--light {
    background-color: transparent;
    overflow: visible;
  }
  .Toastify__toast-body {
  }
  .Toastify__progress-bar {
  }
`;

export const StyledIconSpan = styled.span<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  margin-left: -7.5px;
  font-size: 10px;
  font-family: ${(p) => p.$doxleFont.subTitleFont};
  color: ${(p) => p.$themeColor.primaryFontColor};
`;

export const StyledIconContainer = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledProjectPopoverMenu = styled(Popover)<{
  $themeColor: DoxleThemeColor;
  $currentTheme: DoxleTheme;
  $height?: string;
  $marginTop?: string;
}>`
  && {
  }

  & .MuiPopover-paper {
    background-color: transparent;
    overflow: visible;
    box-shadow: none;
  }
`;

export const StyledMenuItem = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
  $selected: boolean;
}>`
  font-size: 15px;
  font-weight: 500;
  color: ${(p) =>
    p.$selected
      ? p.$themeColor.primaryReverseFontColor
      : p.$themeColor.primaryFontColor};
  background-color: ${(p) =>
    p.$selected ? p.$themeColor.primaryFontColor : ""};
  height: 30px;
  display: flex;
  align-items: center;
  font-family: ${(p) => p.$doxleFont.subTitleFont};
  cursor: pointer;
`;

interface IProjectPopoverProps
  extends Omit<
    PaperProps,
    "onDragStart" | "onAnimationStart" | "onDragEnd" | "onDrag"
  > {
  $themeColor: DoxleThemeColor;
}
const AnimatedPaper = motion(Paper);
const statusListAnimatedVariants = {
  entering: {
    y: [-10, 0],
    opacity: [0, 1],
  },
  exiting: {
    y: [0, -10],
    opacity: [1, 0],
  },
};
export const StyledProjectPopover = styled(
  ({ $themeColor, ...rest }: IProjectPopoverProps) => (
    <AnimatedPaper
      {...rest}
      variants={statusListAnimatedVariants}
      animate="entering"
      exit="exiting"
      initial="initial"
      transition={{
        duration: 0.1,
        damping: 16,
      }}
    />
  )
)`
  && {
    box-shadow: none;
    border: 1px solid ${(p) => p.$themeColor.primaryDividerColor};
    overflow: hidden;
    border-radius: 8px 0px 8px 8px;

    background-color: ${(p) => p.$themeColor.primaryContainerColor};
    width: 150px;
    display: flex;
    flex-direction: column;
    padding: 10px;
  }
`;

export const StyledProjectMenuTop = styled(motion.div)`
  width: 100%;
  display: flex;
  align-items: center;

  padding: 0px 8px;
  overflow: hidden;
  border-bottom: 1px solid ${(p) => p.theme.color.primaryDividerColor};

  .status-content {
    margin-left: 8px;
    display: flex;
    align-items: center;
  }
  .icon {
  }
`;

export const StyledProjectSideMenu = styled(motion.div)`
  width: 300px;
  height: calc(100% - 14px);
  background-color: ${(p) =>
    p.theme.currentTheme === "light"
      ? "rgba(255,255,255,1)"
      : p.theme.color.primaryContainerColor};
  box-shadow: 0px -2px 2px 2px ${(p) => p.theme.color.primaryBoxShadowColor};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 8px 14px 8px;
`;

export const StyledProjectContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(p) => p.theme.color.primaryBackgroundColor};
  .content-wrapper {
    flex: 1;
    height: calc(100% - 44px);
    padding: 30px 50px 14px 50px;
    justify-content: center;
    display: flex;
  }
`;

export const StyledProjectListItem = styled(motion.div)<{
  $selected: boolean;
}>`
  width: calc(100% - 24px);
  height: 25px;
  padding: 4px 12px;
  position: relative;
  display: flex;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  .address-text {
    font-family: ${(p) => p.theme.font.primaryTitleFont};
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    padding: 4px 2px;
    background-color: ${(p) =>
      p.$selected
        ? editRgbaAlpha({
            rgbaColor: p.theme.color.doxleColor3,
            alpha: "1",
          })
        : "transparent"};
    color: ${(p) => (p.$selected ? "white" : p.theme.color.primaryFontColor)};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: fit-content;

    max-width: calc(100% - 4px) !important;
    transition: all 0.2s ease;
    text-align: left;
  }
  .hover-item {
    width: 100%;
    top: 4px;
    bottom: 4px;
    background-color: ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.theme.color.doxleColor3,
        alpha: "0.25",
      })};
    position: absolute;
    left: -100%;
    transition: left 0.2s ease-in-out;
  }
  &:hover .hover-item {
    left: 0; /* Slide in when the container is hovered */
  }

  &:hover .address-text {
    background-color: transparent;
    color: ${(p) => p.theme.color.primaryFontColor};
  }
`;
interface ICustomTextFieldProps
  extends Omit<
    TextFieldProps<"standard">,
    "onDragStart" | "onAnimationStart" | "onDragEnd" | "onDrag"
  > {}
export const StyledProjectSideSearchTextField = styled(motion(TextField))`
  && {
    flex: 1;
    padding: 8px 0px 9px 0px !important;
    margin-left: 8px;
  }
  input {
    color: ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.theme.color.primaryFontColor,
        alpha: "0.6",
      })};
    font-size: 14px;
    font-family: ${(p) => p.theme.font.primaryFont};
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 0px !important;
    &::placeholder {
      color: ${(p) =>
        editRgbaAlpha({
          rgbaColor: p.theme.color.primaryFontColor,
          alpha: "0.4",
        })};
      font-size: 14px;
      opacity: 1;
      font-family: ${(p) => p.theme.font.primaryFont};
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
  & .MuiOutlinedInput-root {
    padding: 0px !important;
  }
  & .MuiOutlinedInput-input {
    padding: 0px !important;
  }
  & .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }

  & .MuiInput-root:before {
    border: none !important;
  }
  & .MuiInput-root:after {
    border: none !important;
  }
`;
export const StyledSearchInputEndornment = styled(InputAdornment)`
  && {
    margin-left: 4px;
    justify-content: center;
    align-items: center;
  }
`;
export const StyledProjectStatusList = styled(motion.div)`
  width: 100%;
  display: flex;
  align-items: center;

  overflow-x: auto;
  overflow-y: visible;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

export const StyledProjectStatusItem = styled.div<{
  $selected: boolean;
}>`
  padding: 5px 10px;
  margin: 0px 2px;
  position: relative;

  cursor: pointer;

  font-family: ${(p) => p.theme.font.primaryFont};
  font-size: 14px;
  font-style: normal;
  font-weight: ${(p) => (p.$selected ? 600 : 400)};
  line-height: normal;
  display: flex;

  text-transform: capitalize;
  overflow: visible;
  color: ${(p) => p.theme.color.primaryFontColor};
  .selected-effect {
    width: 100%;
    height: 1px;
    bottom: 0px;
    background-color: ${(p) => p.theme.color.primaryFontColor};
    position: absolute;
    left: 0;
    right: 0;
    transition: left 0.2s ease-in-out;
  }
`;

export const StyledFormatButtonsContainer = styled.div<{}>`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
`;

export const StyledProjectDropdownMenuBtn = styled.div`
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin-bottom: 2px;
  margin-left: 5px;
  align-items: center;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  cursor: pointer;
  border: 1px solid ${(p) => p.theme.color.primaryDividerColor};
  background-color: ${(p) => p.theme.color.primaryContainerColor};
  transition: all 0.4s ease;
  &:hover {
    background-color: ${(p) => p.theme.color.primaryFontColor};
  }

  &:hover .icon {
    color: ${(p) => p.theme.color.primaryContainerColor};
  }
  .icon {
    color: ${(p) => p.theme.color.primaryFontColor};
  }
`;
