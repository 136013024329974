import React from "react";

import { useQueryClient } from "@tanstack/react-query";
import {
  DefiniteAxiosQueryData,
  InfiniteAxiosQueryData,
} from "../../Models/axiosReturn";
import { produce } from "immer";
import { useDoxleCurrentContextStore } from "../../DoxleGeneralStore/useDoxleCurrentContext";
import { useShallow } from "zustand/react/shallow";
import {
  IQRAccessLog,
  IQRCode,
  IQRCodeFullDetail,
  IQRUserLog,
} from "../Models/qrLog";
import {
  baseQRCodeQKey,
  baseQRLogQKey,
  baseQRUserLogQKey,
  formQRCodeDetailQKey,
} from "./qrQueryAPI";

type Props = {
  appendPost?: "start" | "end";
};

const useSetQRCodeQueryData = ({ appendPost = "end" }: Props) => {
  const company = useDoxleCurrentContextStore(
    useShallow((state) => state.currentCompany)
  );
  const queryClient = useQueryClient();

  //* ----> QR CODE QueRY DATA <---- *//
  //get the base qr code list qkey
  const baseQRCodeQueryKey = [...baseQRCodeQKey, company?.companyId];
  const qrCodeListCacheActive = queryClient.getQueryCache().findAll({
    predicate: (query) =>
      baseQRCodeQueryKey.every((key) => query.queryKey.includes(key)) &&
      query.isActive(),
  });
  const qrCodeListCacheInactive = queryClient.getQueryCache().findAll({
    predicate: (query) =>
      baseQRCodeQueryKey.every((key) => query.queryKey.includes(key)) &&
      !query.isActive(),
  });
  const handleAddQRCode = (newQR: IQRCode) => {
    //optimistic update for active queries
    qrCodeListCacheActive.forEach((query) => {
      queryClient.setQueryData<InfiniteAxiosQueryData<IQRCode>>(
        query.queryKey,
        (data) => {
          if (data) {
            return produce(data, (draft) => {
              if (appendPost === "end")
                draft.pages[draft.pages.length - 1].data.results.push(newQR);
              else draft.pages[0].data.results.unshift(newQR);
              return draft;
            });
          } else queryClient.invalidateQueries(query.queryKey);
        }
      );
    });

    //remove all the queries which are not active
    qrCodeListCacheInactive.forEach((query) => {
      queryClient.removeQueries(query.queryKey);
    });
  };

  const handleRemoveQRCode = (qrId: string) => {
    //optimistic update for active queries
    qrCodeListCacheActive.forEach((query) => {
      queryClient.setQueryData<InfiniteAxiosQueryData<IQRCode>>(
        query.queryKey,
        (data) => {
          if (data) {
            return produce(data, (draft) => {
              draft.pages.forEach((page) => {
                const deletedIdx = page.data.results.findIndex(
                  (qr) => qr.codeId === qrId
                );
                if (deletedIdx !== -1) page.data.results.splice(deletedIdx, 1);
              });
              return draft;
            });
          } else queryClient.invalidateQueries(query.queryKey);
        }
      );
    });

    //remove all the queries which are not active
    qrCodeListCacheInactive.forEach((query) => {
      queryClient.removeQueries(query.queryKey);
    });
  };

  const handleUpdateQRCode = (qrCode: IQRCodeFullDetail) => {
    const qrDetailQKey = formQRCodeDetailQKey(qrCode.codeId);
    //optimistic update for qr detail
    queryClient
      .getQueryCache()
      .findAll({
        predicate: (query) =>
          qrDetailQKey.every((key) => query.queryKey.includes(key)),
      })
      .forEach((query) => {
        queryClient.setQueryData<DefiniteAxiosQueryData<IQRCodeFullDetail>>(
          query.queryKey,
          (old) =>
            produce(old, (draft) => {
              if (draft) Object.assign(draft.data, qrCode);

              return draft;
            })
        );
      });
    //optimistic update for active queries
    qrCodeListCacheActive.forEach((query) => {
      queryClient.setQueryData<InfiniteAxiosQueryData<IQRCode>>(
        query.queryKey,
        (data) => {
          if (data) {
            return produce(data, (draft) => {
              draft.pages.forEach((page) => {
                const updatedItem = page.data.results.find(
                  (qr) => qr.codeId === qrCode.codeId
                );
                if (updatedItem) Object.assign(updatedItem, qrCode);
              });
              return draft;
            });
          } else queryClient.invalidateQueries(query.queryKey);
        }
      );
    });

    //remove all the queries which are not active
    qrCodeListCacheInactive.forEach((query) => {
      queryClient.removeQueries(query.queryKey);
    });
  };

  //* ----> QR LOG Query DATA <---- *//
  const baseQRLogQueryKey = [...baseQRLogQKey, company?.companyId];
  const qrLogListCacheActive = queryClient.getQueryCache().findAll({
    predicate: (query) =>
      baseQRLogQueryKey.every((key) => query.queryKey.includes(key)) &&
      query.isActive(),
  });
  const qrLogListCacheInactive = queryClient.getQueryCache().findAll({
    predicate: (query) =>
      baseQRLogQueryKey.every((key) => query.queryKey.includes(key)) &&
      !query.isActive(),
  });

  const handleAddQRLog = (newQRLog: IQRAccessLog) => {
    //optimistic update for active queries
    qrLogListCacheActive.forEach((query) => {
      queryClient.setQueryData<InfiniteAxiosQueryData<IQRAccessLog>>(
        query.queryKey,
        (data) => {
          if (data) {
            return produce(data, (draft) => {
              if (appendPost === "end")
                draft.pages[draft.pages.length - 1].data.results.push(newQRLog);
              else draft.pages[0].data.results.unshift(newQRLog);
              return draft;
            });
          } else queryClient.invalidateQueries(query.queryKey);
        }
      );
    });

    //remove all the queries which are not active
    qrLogListCacheInactive.forEach((query) => {
      queryClient.removeQueries(query.queryKey);
    });
  };

  //* ----> QR User LOG Query DATA <---- *//
  const baseQRUserLogQueryKey = [...baseQRUserLogQKey, company?.companyId];
  const qrUserLogListCacheActive = queryClient.getQueryCache().findAll({
    predicate: (query) =>
      baseQRUserLogQueryKey.every((key) => query.queryKey.includes(key)) &&
      query.isActive(),
  });
  const qrUserLogListCacheInactive = queryClient.getQueryCache().findAll({
    predicate: (query) =>
      baseQRLogQueryKey.every((key) => query.queryKey.includes(key)) &&
      !query.isActive(),
  });

  const handleAddQRUserLog = (newQRUserLog: IQRUserLog) => {
    //optimistic update for active queries
    qrUserLogListCacheActive.forEach((query) => {
      queryClient.setQueryData<InfiniteAxiosQueryData<IQRUserLog>>(
        query.queryKey,
        (data) => {
          if (data) {
            return produce(data, (draft) => {
              // check if the item is already in the list
              const pageIdxContainItem = draft.pages.findIndex((page) =>
                page.data.results.find(
                  (qrUserLog) => qrUserLog.userLogId === newQRUserLog.userLogId
                )
              );

              // if the item is already in the list, update it
              if (pageIdxContainItem !== -1) {
                const itemIndex = draft.pages[
                  pageIdxContainItem
                ].data.results.findIndex(
                  (qrUserLog) => qrUserLog.userLogId === newQRUserLog.userLogId
                );
                Object.assign(
                  draft.pages[pageIdxContainItem].data.results[itemIndex],
                  newQRUserLog
                );
              }
              // if the item is not in the list, add it
              else {
                if (appendPost === "end")
                  draft.pages[draft.pages.length - 1].data.results.push(
                    newQRUserLog
                  );
                else draft.pages[0].data.results.unshift(newQRUserLog);
              }

              return draft;
            });
          } else queryClient.invalidateQueries(query.queryKey);
        }
      );
    });

    //remove all the queries which are not active
    qrUserLogListCacheInactive.forEach((query) => {
      queryClient.removeQueries(query.queryKey);
    });
  };
  return {
    handleAddQRCode,
    handleRemoveQRCode,
    handleUpdateQRCode,
    handleAddQRLog,
    handleAddQRUserLog,
  };
};

export default useSetQRCodeQueryData;
