import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import { StyledAddMailFieldWrapper } from "./styledComponents";
import useAddMailToSection from "./Hooks/useAddMailToSection";
import { useAddMailContext } from "./AddMail";
import { AnimatePresence } from "framer-motion";
import SelectedContactItem from "./SelectedContactItem";
import ContactPopoverList from "./ContactPopoverList";
import DoxleSelectContactDropdown from "../../../DoxleDesignPattern/DoxleSelectContactDropdown/DoxleSelectContactDropdown";

type Props = {};

const AddMailToSection = (props: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }),
    shallow
  );

  const {
    handleClickContact,
    anchorContactListPopover,
    handleCloseContactList,
  } = useAddMailToSection({});
  const { ballInCourt, setBallInCourt } = useAddMailContext();

  return (
    <StyledAddMailFieldWrapper
      $themeColor={doxleThemeColor}
      $doxleFont={doxleFont}
      layout
    >
      <span className="label-field"> To:</span>

      <div className="add-content-wrapper" onClick={handleClickContact}>
        <AnimatePresence>
          {ballInCourt && (
            <SelectedContactItem
              item={ballInCourt}
              onRemoveItem={(item) => setBallInCourt(undefined)}
            />
          )}
        </AnimatePresence>
      </div>

      {/* <ContactPopoverList
        anchorContactListPopover={anchorContactListPopover}
        handleCloseContactList={handleCloseContactList}
        selectedItems={ballInCourt ? [ballInCourt] : []}
        onSelectItem={setBallInCourt}
        closeOnSelect={true}
      /> */}
      <DoxleSelectContactDropdown
        popoverAnchor={anchorContactListPopover}
        onClosePopover={handleCloseContactList}
        variants="contact"
        selectedContactItemIds={ballInCourt ? [ballInCourt.contactId] : []}
        onSelectContact={setBallInCourt}
        containerStyle={{
          borderRadius: 9,
        }}
        closeOnSelect={false}
      />
    </StyledAddMailFieldWrapper>
  );
};

export default AddMailToSection;
