import { StyledPDFIconButton } from "./StyledComponentCommonQA";
import { GeneratingPDFIcon, GetPDFArrow } from "../../../QAIcons";
import { useDoxleThemeStore } from "../../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import { useProjectQAStore } from "../../Store/useProjectQAStore";
import QAQueryAPI from "../../../QueryAPI/qaQueryAPI";
import useDoxleNotificationStore from "../../../../DoxleGeneralStore/useDoxleNotificationStore";
import { useDoxleAuthStore } from "../../../../DoxleGeneralStore/useDoxleAuthStore";
import { useDoxleCurrentContextStore } from "../../../../DoxleGeneralStore/useDoxleCurrentContext";
import { useState } from "react";
import { useIsMutating } from "@tanstack/react-query";
import { DoxlePDFIcon } from "../../../../DoxleDesignPattern/DoxleGeneralIcons";
interface Props {}
const ExportQaPdf = ({}: Props) => {
  // const [enableFetchPdf, setEnableFetchPdf] = useState<boolean>(false)
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );
  const company = useDoxleCurrentContextStore(
    (state) => state.currentCompany,
    shallow
  );
  const styleProps = useDoxleThemeStore(
    (state) => ({
      $currentTheme: state.currentTheme,
      $themeColor: state.doxleThemeColor,
      $doxleFont: state.doxleFont,
    }),
    shallow
  );

  const { filter, selectedQAList, selectedQAListStatus } = useProjectQAStore(
    (state) => ({
      selectedQAList: state.selectedQAList,
      filter: state.filterQAItemQuery,
      selectedQAListStatus: state.selectedQAListStatus,
    }),
    shallow
  );

  const openPdf = (pdfPath?: string) => {
    if (pdfPath) {
      // const openURL = window.open();
      // if (openURL) {
      //   openURL.location = pdfPath;

      //   openURL.close();
      // }
      window.open(pdfPath, "_blank");
    }
  };

  const pdfWithAssigneeQuery = QAQueryAPI.useCreateQAPdfForAssigneeQuery({
    showNotification,

    company,
    onSuccessCb: openPdf,
  });

  const handleGetPDF = () => {
    if (selectedQAList)
      pdfWithAssigneeQuery.mutate({
        qaListId: selectedQAList.defectListId,
        assigneeId: filter?.assignee ?? null,
        status:
          selectedQAListStatus === "completed" ? "Completed" : "Unattended",
      });
  };

  const isGeneratingPDF = useIsMutating(["generate-qa-pdf"]);

  return (
    <StyledPDFIconButton
      onClick={handleGetPDF}
      disabled={Boolean(isGeneratingPDF)}
    >
      <DoxlePDFIcon
        themeColor={styleProps.$themeColor}
        containerStyle={{ width: 24 }}
      />
      {isGeneratingPDF ? (
        <GeneratingPDFIcon className={"rotate"} {...styleProps} />
      ) : (
        <GetPDFArrow
          height={20}
          viewBox={"0 0 30 24"}
          $themeColor={styleProps.$themeColor}
          $currentTheme={styleProps.$currentTheme}
        />
      )}
    </StyledPDFIconButton>
  );
};

export default ExportQaPdf;
