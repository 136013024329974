import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import FilePopover from "../Components/FilePopover";
import FileRenameDialog from "../Components/FileRenameDialog";
import { StyledTextButton } from "./StyledFilesHeaderComponent";
import useFileRightSubheader from "../Hooks/useFileRightSubheader";

function FileRightSubheader() {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore((state) => ({
    doxleThemeColor: state.doxleThemeColor,
    doxleFont: state.doxleFont,
  }));
  const {
    handleDialogClose,
    dialogOpen,
    editFile,
    deleteFile,
    handleDialogKeyDown,
    handleDialogTick,
    selectedFiles,
  } = useFileRightSubheader();

  return (
    <>
      {selectedFiles.length < 1 && <FilePopover />}
      {selectedFiles.length === 1 && (
        <StyledTextButton
          $isDelete={false}
          onClick={editFile}
          style={{
            backgroundColor: doxleThemeColor.successColor,
            color: "#fff",
          }}
        >
          Edit
        </StyledTextButton>
      )}

      {selectedFiles.length >= 1 && (
        <StyledTextButton
          $isDelete={true}
          onClick={deleteFile}
          style={{
            backgroundColor: doxleThemeColor.errorColor,
            color: "#fff",
          }}
        >
          Delete
        </StyledTextButton>
      )}
      {dialogOpen && (
        <FileRenameDialog
          dialogOpen={dialogOpen}
          handleTick={handleDialogTick}
          handleKeyDown={handleDialogKeyDown}
          handleClose={handleDialogClose}
        />
      )}
    </>
  );
}

export default FileRightSubheader;
