import React, { useMemo } from "react";
import {
  StyledContactsAutocompleteTextField,
  StyledEditOrderLabelField,
  StyledEditOrderWrapperField,
  StyledOrderContactCompanyOption,
  StyledOrderContractorDisplayer,
} from "./StyledComponentEditOrder";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import {
  Autocomplete,
  AutocompleteRenderOptionState,
  CircularProgress,
  InputAdornment,
} from "@mui/material";
import AutocompleteList from "../../../DoxleDesignPattern/DoxleAutoComplete/AutocompleteList";
import useEditContrractorOrder from "./Hooks/useEditContrractorOrder";
import { useEditOrderStore } from "../../OrdersStore/useEditOrderStore";
import { ContactCompany } from "../../../Models/addressBook";
import SelectContractorPopover from "../SelectContractorPopover/SelectContractorPopover";

type Props = {};

const EditContrractorOrder = (props: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }),
    shallow
  );
  const { editedOrder } = useEditOrderStore(
    (state) => ({
      editedOrder: state.editedOrder,
    }),
    shallow
  );
  const {
    handleClickContractorField,
    handleCloseContractorPopover,
    anchorContractorListEl,
  } = useEditContrractorOrder({});
  const wrapStyle: React.CSSProperties = useMemo(
    () => ({ flex: 1, paddingRight: 30 }),
    []
  );
  return (
    <StyledEditOrderWrapperField style={wrapStyle}>
      <StyledEditOrderLabelField
        $themeColor={doxleThemeColor}
        $doxleFont={doxleFont}
      >
        Contractor
      </StyledEditOrderLabelField>

      <StyledOrderContractorDisplayer
        $themeColor={doxleThemeColor}
        $doxleFont={doxleFont}
        onClick={handleClickContractorField}
      >
        {editedOrder?.contactCompany
          ? editedOrder.contactCompanyJson?.name
          : "Select contractor"}
      </StyledOrderContractorDisplayer>

      <SelectContractorPopover
        anchorContractorEl={anchorContractorListEl}
        handleClosePopoverContractor={handleCloseContractorPopover}
      />
    </StyledEditOrderWrapperField>
  );
};

export default EditContrractorOrder;
