import React, { useEffect, useRef, useState } from "react";
import { LineEditTool, NoteLine } from "../../../Models/note";
import useSetNoteLineQueryDataQuery from "../../../Hooks/useSetNoteLineQueryData";
import {
  NoteQueryAPI,
  UpdateNoteLineQueryParams,
} from "../../../QueryAPI/notesQueryAPI";
import { useDoxleCurrentContextStore } from "../../../../DoxleGeneralStore/useDoxleCurrentContext";
import { shallow } from "zustand/shallow";
import useDoxleNotificationStore from "../../../../DoxleGeneralStore/useDoxleNotificationStore";
import { produce } from "immer";
import { toggleQuote } from "../HelperFunctions";

type Props = { lineItem: NoteLine };

interface INoteLineItem {
  handleToggleLineItemEffect: (tool: LineEditTool) => void;
  lineItemEffect: LineEditTool[];
  isFocused: boolean;
  setIsFocused: React.Dispatch<React.SetStateAction<boolean>>;
  newLineDescription: string;
  handleBlurInput: () => void;
  lineDescriptionInputRef: React.RefObject<HTMLInputElement>;
  checkLineEditToolEnable: (tool: LineEditTool) => boolean;
  handleKeydownDescriptionTextfield: (
    event: React.KeyboardEvent<HTMLDivElement>
  ) => void;
  handleDeleteLine: () => void;
  handleNewLineDescriptionChange: (value: string) => void;
  onHoverRow: boolean;
  setOnHoverRow: React.Dispatch<React.SetStateAction<boolean>>;
}
const useNoteLineItem = ({ lineItem }: Props): INoteLineItem => {
  const [lineItemEffect, setLineItemEffect] = useState<LineEditTool[]>(
    Object.keys(lineItem).reduce((acc, key) => {
      const itemKey = key as keyof NoteLine;
      if (
        itemKey === "isBold" ||
        itemKey === "isBullet" ||
        itemKey === "isItalic"
      ) {
        if (lineItem[itemKey])
          return acc.concat(
            itemKey === "isBold"
              ? "bold"
              : itemKey === "isBullet"
              ? "bullet"
              : itemKey === "isItalic"
              ? "italic"
              : "quote"
          );
        else return acc;
      }
      return acc;
    }, [] as LineEditTool[])
  );
  const [isFocused, setIsFocused] = useState(false);
  const [newLineDescription, setNewLineDescription] = useState(lineItem.text);
  const [onHoverRow, setOnHoverRow] = useState(false);
  const { company } = useDoxleCurrentContextStore(
    (state) => ({
      company: state.currentCompany,
    }),
    shallow
  );
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification
  );

  const { update, destroy } = NoteQueryAPI.useMutateNoteLineQuery({
    company,
    noteId: lineItem.note,
    showNotification,
  });
  const lineDescriptionInputRef = useRef<HTMLInputElement>(null);
  const handleToggleLineItemEffect = (tool: LineEditTool) => {
    const keyPropUpdate: keyof Omit<NoteLine, "text" | "lineId" | "note"> =
      tool === "bold"
        ? "isBold"
        : tool === "bullet"
        ? "isBullet"
        : tool === "italic"
        ? "isItalic"
        : "isQuoted";
    if (tool !== "quote")
      setLineItemEffect(
        produce((prev) => {
          if (prev.includes(tool)) {
            prev = prev.filter((item) => item !== tool);
            update.mutate({ lineId: lineItem.lineId!, [keyPropUpdate]: false });
          } else {
            prev.push(tool);
            update.mutate({ lineId: lineItem.lineId!, [keyPropUpdate]: true });
          }

          return prev;
        })
      );
    if (tool === "quote") {
      if (lineDescriptionInputRef.current) {
        const { selectionStart, selectionEnd, value } =
          lineDescriptionInputRef.current;
        if (
          selectionStart !== null &&
          selectionEnd !== null &&
          selectionStart !== selectionEnd
        ) {
          const selectedText = value.substring(selectionStart, selectionEnd);
          const newText = toggleQuote(value, selectedText);
          setNewLineDescription(newText);
        }
      }
    }
  };

  const checkLineEditToolEnable = (tool: LineEditTool) =>
    lineItemEffect.includes(tool);
  const handleKeydownDescriptionTextfield = (
    event: React.KeyboardEvent<HTMLDivElement>
  ) => {
    if(event.nativeEvent.key === "Enter" && (event.ctrlKey || event.metaKey || event.altKey)){
      setNewLineDescription(prev => prev + '\n')
      return;
    }
    if (
      event.nativeEvent.key === "Enter" ||
      event.nativeEvent.key === "Escape"
    ) {
      event.preventDefault();
      setIsFocused(false);
      lineDescriptionInputRef.current?.blur();
    }
  };
  const handleDeleteLine = () => {
    destroy.mutate(lineItem.lineId!);
  };
  const handleBlurInput = () => {
    if (!newLineDescription) handleDeleteLine();
    else {
      if (newLineDescription !== lineItem.text)
        update.mutate({
          lineId: lineItem.lineId!,
          text: newLineDescription,
        });
    }
  };

  const handleNewLineDescriptionChange = (value: string) => {
    setNewLineDescription(
      checkLineEditToolEnable("bullet") ? value.substring(1) : value
    );
  };
  return {
    handleToggleLineItemEffect,
    lineItemEffect,
    isFocused,
    setIsFocused,
    newLineDescription,
    handleBlurInput,
    lineDescriptionInputRef,
    checkLineEditToolEnable,
    handleKeydownDescriptionTextfield,
    handleDeleteLine,
    handleNewLineDescriptionChange,
    onHoverRow,
    setOnHoverRow,
  };
};

export default useNoteLineItem;
