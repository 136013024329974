import { useState } from "react";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import { useEditDocketSideScreenStore } from "../EditDocketSideScreenStore/useEditDocketSideScreenStore";
import Tooltip from "@mui/material/Tooltip";
import { DoxleMUITooltipSx } from "../../../DoxleDesignPattern/MUIOverwriteSx";
import { DoxleInfoIcon } from "../../../DoxleDesignPattern/DoxleGeneralIcons";
import Popover from "@mui/material/Popover";
import {
  StyledDocketInfoPopover,
  StyledDocketInfoTitleText,
  StyledEditDocketInfoField,
  StyledIconContainer,
} from "../StyledComponentsEditDocketSideScreen";
import EditDocketLabelWithIcon from "./EditDocketLabelWithIcon";
import dayjs from "dayjs";

type Props = {};

const DocketInfoPopover = (props: Props) => {
  const [anchorDocketInfoPopover, setAnchorDocketInfoPopover] =
    useState<HTMLElement | null>(null);
  const { doxleThemeColor, doxleFont, staticMenuColor } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
      staticMenuColor: state.staticMenuColor,
    }),
    shallow
  );
  const { edittedDocket } = useEditDocketSideScreenStore(
    (state) => ({
      edittedDocket: state.edittedDocket,
    }),
    shallow
  );
  const canBeOpen = Boolean(anchorDocketInfoPopover);
  const id = canBeOpen ? "docket-info-popper" : undefined;
  return (
    <>
      <Tooltip
        PopperProps={{
          sx: DoxleMUITooltipSx(doxleThemeColor, doxleFont),
        }}
        title={anchorDocketInfoPopover ? "Hide info" : "Docket Info"}
      >
        <StyledIconContainer
          onClick={(e) => setAnchorDocketInfoPopover(e.currentTarget)}
        >
          <DoxleInfoIcon
            themeColor={doxleThemeColor}
            containerStyle={{ cursor: "pointer", width: 20 }}
            staticColor={staticMenuColor.staticFontColor}
          />
        </StyledIconContainer>
      </Tooltip>

      <Popover
        id={id}
        open={canBeOpen}
        anchorEl={anchorDocketInfoPopover}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClose={() => setAnchorDocketInfoPopover(null)}
        sx={{
          "& .MuiPopover-paper": {
            backgroundColor: "transparent",
            overflow: "visible",
            boxShadow: "none",
          },
        }}
        elevation={8}
      >
        <StyledDocketInfoPopover $themeColor={doxleThemeColor}>
          <StyledDocketInfoTitleText
            $doxleFont={doxleFont}
            $themeColor={doxleThemeColor}
          >
            Docket Info
          </StyledDocketInfoTitleText>
          <StyledEditDocketInfoField
            $doxleFont={doxleFont}
            key={"infoCol1#row1"}
            $themeColor={doxleThemeColor}
          >
            <EditDocketLabelWithIcon type="created By" />
            <span>
              {edittedDocket?.createdByJson
                ? `${edittedDocket?.createdByJson.firstName} ${edittedDocket?.createdByJson.lastName}`
                : "Unknown User"}
            </span>
          </StyledEditDocketInfoField>

          <StyledEditDocketInfoField
            $doxleFont={doxleFont}
            key={"infoCol1#row2"}
            $themeColor={doxleThemeColor}
          >
            <EditDocketLabelWithIcon type="created Time" />
            <span>
              {edittedDocket
                ? dayjs(edittedDocket?.createdOn).format(
                    "MMMM DD, YYYY HH:mm A"
                  )
                : "-"}
            </span>
          </StyledEditDocketInfoField>

          <StyledEditDocketInfoField
            $doxleFont={doxleFont}
            key={"infoCol2#row1"}
            $themeColor={doxleThemeColor}
          >
            <EditDocketLabelWithIcon type="last Edited By" />
            <span>
              {edittedDocket?.lastModifiedByJson
                ? `${edittedDocket?.lastModifiedByJson.firstName} ${edittedDocket?.lastModifiedByJson.lastName}`
                : "Unknown User"}
            </span>
          </StyledEditDocketInfoField>

          <StyledEditDocketInfoField
            $doxleFont={doxleFont}
            key={"infoCol2#row2"}
            $themeColor={doxleThemeColor}
          >
            <EditDocketLabelWithIcon type="last Edited Time" />
            <span>
              {edittedDocket
                ? dayjs(edittedDocket?.lastModifiedOn).format(
                    "MMMM DD, YYYY HH:mm A"
                  )
                : "-"}
            </span>
          </StyledEditDocketInfoField>
        </StyledDocketInfoPopover>
      </Popover>
    </>
  );
};

export default DocketInfoPopover;
