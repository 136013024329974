import React, { useState } from "react";
import useGetOrderStatus from "../../../Hooks/useGetOrderStatus";
import { useEditOrderStore } from "../../../OrdersStore/useEditOrderStore";
import { shallow } from "zustand/shallow";
import Order, { OrderStatus } from "../../../Models/order";
import { useDoxleAuthStore } from "../../../../DoxleGeneralStore/useDoxleAuthStore";
import { useDoxleCurrentContextStore } from "../../../../DoxleGeneralStore/useDoxleCurrentContext";
import { useOrdersStore } from "../../../OrdersStore/useOrdersStore";
import OrdersQueryAPI, {
  PatchOrderProps,
  getMutateOrderQKey,
} from "../../../QueryHooks/OrdersQueryAPI";
import useSetOrderDetailQueryData from "../../../Hooks/useSetOrderDetailQueryData";
import { useIsMutating } from "@tanstack/react-query";
import { useEditOrderContext } from "../EditOrder";
import { produce } from "immer";

type Props = {};
interface IEditOrderStatus {
  handleSelectOrderStatus: (item: OrderStatus) => void;
  statusListAnchor: HTMLDivElement | null;
  setStatusListAnchor: React.Dispatch<
    React.SetStateAction<HTMLDivElement | null>
  >;
  isUpdatingStatus: boolean;
}
const useEditOrderStatus = (props: Props): IEditOrderStatus => {
  const [statusListAnchor, setStatusListAnchor] =
    useState<HTMLDivElement | null>(null);

  const { editedOrder, updateEditedOrder } = useEditOrderStore(
    (state) => ({
      editedOrder: state.editedOrder,
      setEditedOrder: state.setEditedOrder,
      updateEditedOrder: state.updateEditedOrder,
    }),
    shallow
  );

  const editOrderContext = useEditOrderContext();
  const { setUpdatedOrder } = editOrderContext
    ? editOrderContext : {setUpdatedOrder: (x: any)=>{}}
  const company = useDoxleCurrentContextStore(
    (state) => state.currentCompany,
    shallow
  );
  const { queryFilter } = useOrdersStore(
    (state) => ({ queryFilter: state.queryFilter }),
    shallow
  );

  const { handleUpdatePartialOrder } = useSetOrderDetailQueryData({
    orderId: editedOrder?.orderId ?? "",
  });
  const onSuccessUpdateOrder = (order?: Order) => {
    if (order) {
      updateEditedOrder(order);
      handleUpdatePartialOrder(order);
    }
  };
  const { patchOrder } = OrdersQueryAPI.useMutateOrderQuery({
    company,
    // showNotification,
    filter: queryFilter,
    onUpdateSuccessCb: onSuccessUpdateOrder,
  });
  const handleSelectOrderStatus = (item: OrderStatus) => {
    if (editedOrder) {
      // patchOrder.mutate({
      //   orderId: editedOrder.orderId,
      //   order: {
      //     status: item.statusId,
      //     statusName: item.statusName,
      //     statusColor: item.color,
      //   },
      // });
      setUpdatedOrder(
        produce((draft) => {
          if (draft) {
            draft.status = item.statusId;
            draft.statusName = item.statusName;
            draft.statusColor = item.color;
          } else
            draft = {
              status: item.statusId,
              statusName: item.statusName,
              statusColor: item.color,
            };
          return draft;
        })
      );

      updateEditedOrder({
        status: item.statusId,
        statusName: item.statusName,
        statusColor: item.color,
      });
    }

    setStatusListAnchor(null);
  };
  const isUpdatingStatus =
    useIsMutating({
      mutationKey: getMutateOrderQKey("update"),
      predicate: (query) =>
        Boolean(
          (query.state.variables as PatchOrderProps).orderId ===
            editedOrder?.orderId &&
            (query.state.variables as PatchOrderProps).order.status
        ),
    }) > 0;
  return {
    handleSelectOrderStatus,
    statusListAnchor,
    setStatusListAnchor,
    isUpdatingStatus,
  };
};

export default useEditOrderStatus;
