import { shallow } from "zustand/shallow";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import useBookingDocketStore from "../../store/useBookingDocketStore";
import useBookingDocket from "../Hooks/useBookingDocket";
import { AnimatePresence } from "framer-motion";
import { StyledBookingDocketContainer } from "./StyledComponentBookingDocket";
import DoxleDialogHelper from "../../../DoxleDesignPattern/DoxleDialogHelper/DoxleDialogHelper";
import BookingDocketTable from "./BookingTable/BookingDocketTable";
import AddBookingDocket from "./AddBookingDocket/AddBookingDocket";
import EditBookingDocket from "./EditBookingDocket/EditBookingDocket";
import SendBooking from "../../EmailBookings/SendBooking";
import React from "react";

type Props = {};

const BookingDocket = (props: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
      currentTheme: state.currentTheme,
    }),
    shallow
  );
  const { handleDeleteBooking, handleCloseDeleteDialog } = useBookingDocket({});
  const { onAddBookingMode, edittedBooking, deletedProjectBooking } =
    useBookingDocketStore(
      (state) => ({
        onAddBookingMode: state.onAddBookingMode,
        edittedBooking: state.edittedBooking,
        deletedProjectBooking: state.deletedProjectBooking,
      }),
      shallow
    );

  if (onAddBookingMode) return <AddBookingDocket />;
  else if (edittedBooking) return <EditBookingDocket />;
  else
    return (
      <AnimatePresence>
        <StyledBookingDocketContainer
          initial={{
            x: -5,
            opacity: 0,
          }}
          animate={{
            x: 0,
            opacity: 1,
          }}
        >
          <BookingDocketTable />
          <SendBooking />
          <DoxleDialogHelper
            open={Boolean(deletedProjectBooking)}
            onClose={handleCloseDeleteDialog}
            title="Confirm delete booking!"
            description={`All data belong to booking item ***${deletedProjectBooking?.title}*** will be deleted permanently,are you sure to proceed?`}
            dialogButtons={[
              {
                buttonText: "Cancel",
                buttonFunction: handleCloseDeleteDialog,
                buttonColor: doxleThemeColor.primaryContainerColor,
                btnStyleSx: {
                  border: `1px solid ${doxleThemeColor.primaryDividerColor}`,
                },
                buttonTextStyle: {
                  color: doxleThemeColor.primaryFontColor,
                },
              },
              {
                buttonText: "Confirm",
                buttonFunction: handleDeleteBooking,
                btnStyleSx: {
                  border: `1px solid ${doxleThemeColor.primaryDividerColor}`,
                },
              },
            ]}
          />
        </StyledBookingDocketContainer>
      </AnimatePresence>
    );
};

export default BookingDocket;
