import React, { useMemo } from "react";
import { useDoxleAuthStore } from "../../DoxleGeneralStore/useDoxleAuthStore";
import { useDoxleCurrentContextStore } from "../../DoxleGeneralStore/useDoxleCurrentContext";
import useDoxleNotificationStore from "../../DoxleGeneralStore/useDoxleNotificationStore";
import { DoxleFolder } from "../../Models/files";
import FilesQueryAPI from "../QueryHooks/FileStorageQueryAPI";
import { useFileStore } from "../Store/useFileStore";
import { shallow } from "zustand/shallow";

type Props = {};
interface IGetRootFolderQuery {
  folderListSuccess: DoxleFolder[];
  isFetchingFolderList: boolean;
  isSuccessFetchingFolderList: boolean;
  isErrorFetchingFolderList: boolean;
}
const useGetRootFolderQuery = (props: Props): IGetRootFolderQuery => {
  const { user } = useDoxleAuthStore((state) => ({
    user: state.user,
  }));
  const { company } = useDoxleCurrentContextStore((state) => ({
    company: state.currentCompany,
  }));

  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification
  );
  const { filterRootFolderQuery } = useFileStore(
    (state) => ({
      filterRootFolderQuery: state.filterRootFolderQuery,
    }),
    shallow
  );
  const getFolderOnSuccessCallback = (folderList: DoxleFolder[]) => {};
  const getFolderListQuery = FilesQueryAPI.useGetFolderQuery({
    company,
    showNotification,
    getFolderOnSuccessCallback,
    filter: filterRootFolderQuery,
  });

  const folderListSuccess = useMemo(
    () =>
      getFolderListQuery.isSuccess
        ? (getFolderListQuery.data?.data as DoxleFolder[])
        : [],
    [getFolderListQuery.data]
  );
  return {
    folderListSuccess,
    isFetchingFolderList: getFolderListQuery.isLoading,
    isSuccessFetchingFolderList: getFolderListQuery.isSuccess,
    isErrorFetchingFolderList: getFolderListQuery.isError,
  };
};

export default useGetRootFolderQuery;
