import { Skeleton } from "@mui/material";
import {
  DrawingSkeleton,
  RootDrawingSkeleton,
  StyledSkeletonBox,
} from "./StyledComponentSkeleton";
import React, { useMemo } from "react";
import { Components, Virtuoso } from "react-virtuoso";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import { StyledDrawingListScroller } from "./DrawingsStyledComponent";

type Props = {};

function DrawingsSkeleton(props: Props) {
  const { doxleThemeColor, currentTheme } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      currentTheme: state.currentTheme,
    }),
    shallow
  );
  const emptyArray: string[] = Array(3).fill("");
  const disable = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    e.currentTarget.scrollTo({
      top: 0,
      left: 0,
    });
  };

  const components: Components<string, {}> = useMemo(
    () => ({
      Scroller: React.forwardRef((props, ref) => (
        <StyledDrawingListScroller {...props} ref={ref} />
      )),
    }),
    []
  );
  return (
    <RootDrawingSkeleton>
      <Virtuoso
        style={{
          width: "100%",
          height: "100%",
        }}
        onScroll={(e) => disable(e)}
        data={emptyArray}
        components={components}
        itemContent={() => (
          <StyledSkeletonBox $themeColor={doxleThemeColor}>
            <Skeleton
              animation="wave"
              variant="rounded"
              width={50}
              height={30}
              sx={{
                bgcolor: doxleThemeColor.skeletonColor,
              }}
            />

            <div className="right-skel-section">
              <Skeleton
                animation="wave"
                variant="rounded"
                sx={{
                  bgcolor: doxleThemeColor.skeletonColor,
                  height: "35rem",
                  aspectRatio: 2,
                }}
              />

              <Skeleton
                animation="wave"
                variant="text"
                sx={{
                  bgcolor: doxleThemeColor.skeletonColor,
                  marginTop: "28px",
                }}
                width="30%"
                height={40}
              />
              <Skeleton
                animation="wave"
                variant="text"
                sx={{
                  bgcolor: doxleThemeColor.skeletonColor,
                  marginTop: "8px",
                }}
                width="50%"
                height={14}
              />
            </div>
          </StyledSkeletonBox>
        )}
      />
    </RootDrawingSkeleton>
  );
}

export default DrawingsSkeleton;
