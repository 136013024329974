import { create } from "zustand";
import { Mail, MailQueryFilter } from "../Models/mail";
import {
  DoxleTheme,
  DoxleThemeColor,
} from "../../DoxleGeneralStore/useDoxleThemeStore";

interface MailThemeColor {
  semiBoldDividerColor: `rgba(${number},${number},${number},${number})`;
  badgeColor: `rgba(${number},${number},${number},${number})`;
  mailsToLabelColor: `rgba(${number},${number},${number},${number})`;
  mailsCommentColor: `rgba(${number},${number},${number},${number})`;
  mailsNameLabelColor: `rgba(${number},${number},${number},${number})`;
  bolderBorderColor: `rgba(${number},${number},${number},${number})`;
  replyTextColor: `rgba(${number},${number},${number},${number})`;
  mailConversationTabColor: `rgba(${number},${number},${number},${number})`;
  mailConversationInputLabel: `rgba(${number},${number},${number},${number})`;
}

export interface FullMailThemeColor extends MailThemeColor, DoxleThemeColor {}

interface DoxleCurrentContextStore {
  queryFilter: MailQueryFilter;
  setQueryFilter: (filter: Partial<MailQueryFilter>) => void;
  mailTheme: MailThemeColor;
  setTheme: (theme: DoxleTheme) => void;
  onAddNewMailMode: boolean;
  setOnAddNewMailMode: (value: boolean) => void;
  viewMail: Mail | undefined;
  setViewMail: (item: Mail | undefined) => void;
}

export const useMailStore = create<DoxleCurrentContextStore>((set, get) => ({
  queryFilter: { ordering: "-time_stamp", pollingRate: 60 },
  setQueryFilter: (filter: Partial<MailQueryFilter>) =>
    set({ queryFilter: { ...get().queryFilter, ...filter } }),
  onAddNewMailMode: false,
  setOnAddNewMailMode: (value: boolean) => set({ onAddNewMailMode: value }),
  viewMail: undefined,
  setViewMail: (item) => set({ viewMail: item }),
  mailTheme: {
    semiBoldDividerColor: "rgba(221, 221, 234,1)",
    badgeColor: "rgba(112,112,255,0.25)",
    mailsToLabelColor: "rgba(35, 31, 32, 0.3)",
    mailsCommentColor: "rgba(35, 31, 32, 1)",
    mailsNameLabelColor: "rgba(72, 72, 134, 0.4)",
    bolderBorderColor: "rgba(239, 240, 244, 1)",
    replyTextColor: "rgba(70, 70, 165, 1)",
    mailConversationTabColor: "rgba(0, 0, 0,0.7)",
    mailConversationInputLabel: "rgba(0,0,0,0.4)",
  },
  setTheme: (theme: DoxleTheme) => {
    if (theme === "contrast") {
      set({
        mailTheme: {
          semiBoldDividerColor: "rgba(221, 221, 234,1)",
          badgeColor: "rgba(112,112,255,0.25)",
          mailsToLabelColor: "rgba(35, 31, 32, 0.3)",
          mailsCommentColor: "rgba(35, 31, 32, 1)",
          mailsNameLabelColor: "rgba(72, 72, 134, 0.4)",
          bolderBorderColor: "rgba(239, 240, 244, 1)",
          replyTextColor: "rgba(70, 70, 165, 1)",
          mailConversationTabColor: "rgba(0, 0, 0,0.7)",
          mailConversationInputLabel: "rgba(0,0,0,0.4)",
        },
      });
    } else if (theme === "dark")
      set({
        mailTheme: {
          semiBoldDividerColor: "rgba(221, 221, 234,1)",
          badgeColor: "rgba(112,112,255,0.25)",
          mailsToLabelColor: "rgba(175, 175, 199, 0.55)",
          mailsNameLabelColor: "rgba(72, 72, 134, 1)",
          mailsCommentColor: "rgba(255, 255, 255,1)",
          bolderBorderColor: "rgba(239, 240, 244, 1)",
          replyTextColor: "rgba(70, 70, 165, 1)",
          mailConversationTabColor: "rgba(255, 255, 255,0.7)",
          mailConversationInputLabel: "rgba(255,255,255,0.4)",
        },
      });
    else
      set({
        mailTheme: {
          semiBoldDividerColor: "rgba(221, 221, 234,1)",
          badgeColor: "rgba(112,112,255,0.25)",
          mailsToLabelColor: "rgba(35, 31, 32, 0.3)",
          mailsCommentColor: "rgba(35, 31, 32, 1)",
          mailsNameLabelColor: "rgba(72, 72, 134, 0.4)",
          bolderBorderColor: "rgba(239, 240, 244, 1)",
          replyTextColor: "rgba(70, 70, 165, 1)",
          mailConversationTabColor: "rgba(0, 0, 0,0.7)",
          mailConversationInputLabel: "rgba(0,0,0,0.4)",
        },
      });
  },
}));
