import React, { useCallback, useMemo } from "react";
import { useDoxleCurrentContextStore } from "../../../DoxleGeneralStore/useDoxleCurrentContext";
import { shallow } from "zustand/shallow";
import ProjectQueryAPI from "../../../Services/QueryHooks/projectQueryAPI";
import { Project } from "../../../Models/project";
import { groupBy } from "lodash";

type Props = {
  searchText?: string;
};

interface IGroupedProjectStatus {
  statusId: string;
  statusName: string;
  itemCount: number;
}
interface IGetProjectList {
  isFetchingProject: boolean;
  isSuccessFetchingProject: boolean;
  isErrorFetchingProject: boolean;
  isRefetchingProject: boolean;
  projectList: Project[];
  hasNextPageProject: boolean;
  handleFetchNextPageProject: () => void;
  refetchProjectList: () => void;
  isFetchingNextPageProject: boolean;
  projectStatusList: IGroupedProjectStatus[];
}
const useGetProjectList = ({ searchText }: Props): IGetProjectList => {
  const { currentCompany, setCurrentProject } = useDoxleCurrentContextStore(
    (state) => ({
      currentProject: state.currentProject,
      currentCompany: state.currentCompany,
      setCurrentProject: state.setCurrentProject,
    }),
    shallow
  );
  const handleSetSelectedProject = useCallback(
    async (projectList: Project[]) => {
      const lastSelectedProjectId: string | null =
        localStorage.getItem("currentProjectId") ||
        localStorage.getItem("lastSelectedProjectId");

      if (!lastSelectedProjectId) {
        {
          setCurrentProject(projectList[0]);
        }
      } else {
        setCurrentProject(
          projectList.find(
            (project) => project.projectId === lastSelectedProjectId
          ) ?? projectList[0]
        );
      }
    },
    []
  );
  const onSuccessFetchProjectCb = (projects: Project[]) => {
    if (!searchText) {
      if (projects.length > 0) handleSetSelectedProject(projects);
      else setCurrentProject(undefined);
    }
  };
  const projectQuery = ProjectQueryAPI.useRetrieveFullProjectListQuery({
    enable: Boolean(currentCompany),
    company: currentCompany,

    filter: { searchText },
    onSuccessCb: onSuccessFetchProjectCb,
  });

  const projectList: Project[] = useMemo(
    () =>
      projectQuery.isSuccess
        ? projectQuery.data?.pages.reduce((acc, data) => {
            return acc.concat(data.data.results);
          }, [] as Project[])
        : [],
    [projectQuery.data]
  );
  const handleFetchNextPageProject = () => {
    if (projectQuery.hasNextPage) projectQuery.fetchNextPage();
  };
  const refetchProjectList = useCallback(() => {
    projectQuery.refetch({
      cancelRefetch: true,
    });
  }, [projectQuery.refetch]);

  const groupedProjects = useMemo(
    () => groupBy(projectList, (project) => project.projectStatus),
    [projectList]
  );
  const projectStatusList: IGroupedProjectStatus[] = useMemo(
    () =>
      Object.keys(groupedProjects).reduce((acc, id) => {
        const items = groupedProjects[id];

        return acc.concat({
          statusId: id,
          statusName: items[0].statusName,
          itemCount: items.length,
        });
      }, [] as IGroupedProjectStatus[]),
    [groupedProjects]
  );
  return {
    isFetchingProject: projectQuery.isLoading,
    isSuccessFetchingProject: projectQuery.isSuccess,
    isErrorFetchingProject: projectQuery.isError,
    isRefetchingProject: projectQuery.isRefetching,
    projectList,
    hasNextPageProject: Boolean(projectQuery.hasNextPage),
    handleFetchNextPageProject,
    refetchProjectList,
    isFetchingNextPageProject: projectQuery.isFetchingNextPage,
    projectStatusList,
  };
};

export default useGetProjectList;
