import React from "react";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import useAddLocationForm from "./Hooks/useAddLocationForm";
import {
  StyledAddContactFieldWrapper,
  StyledAddContactForm,
  StyledAddContactSubmitButton,
  StyledAddContactTextfield,
} from "./StyledDoxleSelectLocationDropdown";
import { produce } from "immer";
import { AnimatePresence, motion } from "framer-motion";
import {
  editRgbaAlpha,
} from "../../Utilities/FunctionUtilities";
import { FiPlus } from "react-icons/fi";
import ProgressScreen from "../../Utilities/Lottie/ProgressScreen";

type Props = {
  variant?: "room"|"floor";
  handleCloseForm: () => void;
  floorFilter?: string
};

const AddLocationForm = ({ variant, handleCloseForm, floorFilter }: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }),
    shallow
  );
  const {
    shouldShowError,
    handleAddFloor,
    newFloor,
    setNewFloor,
    isAddingLocation,
    newRoom,
    setNewRoom,
    handleAddRoom,
  } = useAddLocationForm({ handleCloseForm, floorFilter });

  return (
    <StyledAddContactForm
      animate={{
        y: [10, 0],
        opacity: [0, 1],
      }}
    >
      {variant === "floor" && (
          <StyledAddContactFieldWrapper
            $themeColor={doxleThemeColor}
            $doxleFont={doxleFont}
            layout="position"
          >
            <span className="field-label">Floor name</span>
            <StyledAddContactTextfield
              $themeColor={doxleThemeColor}
              $doxleFont={doxleFont}
              variant="standard"
              placeholder="Enter floor name..."
              value={newFloor.name}
              onChange={(e) =>
                setNewFloor(
                  produce((draft) => {
                    draft.name = e.target.value;
                    return draft;
                  })
                )
              }
            />
            <AnimatePresence>
              {shouldShowError && !newFloor.name && (
                <motion.span
                  className="error-text"
                  animate={{
                    x: [-5, 0],
                    opacity: [0, 1],
                  }}
                  exit={{ x: [0, -5], opacity: [1, 0] }}
                >
                  Please enter a name!
                </motion.span>
              )}
            </AnimatePresence>
          </StyledAddContactFieldWrapper>
      )}

      {variant === "room" && floorFilter && (
          <StyledAddContactFieldWrapper
            $themeColor={doxleThemeColor}
            $doxleFont={doxleFont}
            layout="position"
          >
            <span className="field-label">Room Name *</span>
            <StyledAddContactTextfield
              $themeColor={doxleThemeColor}
              $doxleFont={doxleFont}
              variant="standard"
              placeholder="Enter room name..."
              value={newRoom.name}
              onChange={(e) =>
                setNewRoom(
                  produce((draft) => {
                    draft.name = e.target.value;
                    return draft;
                  })
                )
              }
            />
            <AnimatePresence>
              {shouldShowError && !newRoom.name && (
                <motion.span
                  className="error-text"
                  animate={{
                    x: [-5, 0],
                    opacity: [0, 1],
                  }}
                  exit={{ x: [0, -5], opacity: [1, 0] }}
                >
                  Please enter a name!
                </motion.span>
              )}
            </AnimatePresence>
          </StyledAddContactFieldWrapper>

      )}

      {variant === "room" && !floorFilter && (
        <StyledAddContactFieldWrapper
          $themeColor={doxleThemeColor}
          $doxleFont={doxleFont}
          layout="position"
        >
          You must select a floor before you can add a room.
        </StyledAddContactFieldWrapper>

      )}

      <StyledAddContactSubmitButton
        $themeColor={doxleThemeColor}
        $doxleFont={doxleFont}
        layout="position"
        transition={{
          damping: 16,
        }}
        onClick={(e) => {
          if (variant === "floor") handleAddFloor();
          else handleAddRoom(e);
        }}
      >
        <FiPlus
          color="inherit"
          size="max(1.8rem,18px)"
          style={{ marginRight: 4 }}
        />
        Add
      </StyledAddContactSubmitButton>

      {isAddingLocation && (
        <ProgressScreen
          progressType="progress"
          progressText="Adding contact..."
          animationSize={70}
          containerStyle={{
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            position: "absolute",
            zIndex: 10,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: editRgbaAlpha({
              rgbaColor: doxleThemeColor.primaryContainerColor,
              alpha: "0.8",
            }),
          }}
          textStyle={{
            fontSize: "max(1.6rem,16px)",
            color: doxleThemeColor.primaryFontColor,
            marginTop: 8,
          }}
          hasBackDrop={true}
        />
      )}
    </StyledAddContactForm>
  );
};

export default AddLocationForm;
