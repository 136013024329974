import { Variants } from "framer-motion";
import React, { useState } from "react";

type Props = {};

const useQRLogFilter = () => {
  const [showFilterDialog, setShowFilterDialog] = useState(false);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const contentAnimateVariants: Variants = {
    initial: {
      height: "250px",
    },
    animate: {
      height: showDatePicker ? "70vh" : "200px",

      transition: {
        damping: 16,
        mass: 0.1,
        stiffness: 120,
      },
    },
  };
  return {
    showFilterDialog,
    setShowFilterDialog,
    contentAnimateVariants,
    showDatePicker,
    setShowDatePicker,
  };
};

export default useQRLogFilter;
