import React from "react";

import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import {
  StyledBookingPercentageCell,
  StyledBookingPercentageContainer,
} from "./StyledComponentBookings";

type Props = { percentage: number };
const Percentage_step_list: number[] = [25, 50, 75, 100];
const BookingPercentage = ({ percentage }: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }),
    shallow
  );

  const percentageFillColor =
    percentage === 25
      ? "#EA1515"
      : percentage === 50 || percentage === 75
      ? "#F90"
      : percentage === 100
      ? "#209D34"
      : undefined;
  return (
    <StyledBookingPercentageContainer
      $themeColor={doxleThemeColor}
      $doxleFont={doxleFont}
    >
      <span className="percentage-text">{percentage}%</span>
      {Percentage_step_list.map((percentItem, idx) => (
        <StyledBookingPercentageCell
          $themeColor={doxleThemeColor}
          $fillColor={
            percentage >= percentItem ? percentageFillColor : undefined
          }
          key={percentItem + idx}
        />
      ))}
    </StyledBookingPercentageContainer>
  );
};

export default BookingPercentage;
