import { TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import Table from "@mui/material/Table";
import styled from "styled-components";
import {
  DoxleFont,
  DoxleTheme,
  DoxleThemeColor,
} from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { motion } from "framer-motion";
import { editRgbaAlpha } from "../../../Utilities/FunctionUtilities";

export const StyledPriceListTable = styled(Table)`
  && {
    width: 100% !important;

    display: flex !important;
    flex-direction: column !important;
  }
`;
export const StyledPricebookWrapper = styled.div`
  width: calc(100% - 40px);
  height: calc(100% - 20px);
  padding: 0px 20px 20px 20px;
  display: flex;
  justify-content: center;
  overflow: hidden;
  position: relative;
  flex-direction: column;
`;

export const StyledPricebookTableHead = styled(TableHead)`
  && {
    display: flex;
    align-items: center;
    border-bottom: none !important;
  }
`;

export const StyledPricebookTableBody = styled(TableBody)`
  && {
    width: 100% !important;
    min-width: 20rem !important;
    flex: 1;
    display: flex !important;
    flex-direction: column;
  }
`;

export const StyledPricebookTableRowWrapper = styled(motion(TableRow))<{
  $themeColor: DoxleThemeColor;
}>`
  && {
    margin: 0px !important;
    padding: 0px !important;
    display: flex;
    flex-direction: row;
  }
`;

export const StyledPricebookTableDataContainer = styled(motion(TableCell))<{
  $themeColor: DoxleThemeColor;
  $currentTheme: DoxleTheme;
  $transparentMode?: boolean;
}>`
  && {
    width: calc(100% - 2px) !important;
    display: flex;
    flex-direction: row;
    padding: 0.4rem 0rem;
    height: calc(4rem - 0.8rem);
    min-height: 36px;
    border-bottom: 1px solid
      ${(p) =>
        p.$currentTheme === "dark"
          ? editRgbaAlpha({
              rgbaColor: p.$themeColor.primaryDividerColor,
              alpha: "1",
            })
          : p.$themeColor.primaryDividerColor};

    cursor: pointer;
    position: relative;
  }
`;

export const StyledPricebookRateTablePagination = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 8px 0px;
`;

export const StyledPageSizeSection = styled(motion.div)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  display: flex;
  align-items: center;
  padding: 4px 8px;
  border-radius: 4px;
  border: 1px solid ${(p) => p.$themeColor.primaryDividerColor};
  .page-size-title {
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    color: ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.$themeColor.primaryFontColor,
        alpha: "0.7",
      })};
  }
  .page-size-summary {
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    color: ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.$themeColor.primaryFontColor,
        alpha: "0.7",
      })};
  }

  .page-size-indicator {
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    color: ${(p) => p.$themeColor.doxleColor};
    margin: 0px 14px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: 0.2s all ease;
    padding: 2px;
    border-radius: 2px;
    &:hover {
      transition: 0.2s all ease;
      background-color: ${(p) =>
        editRgbaAlpha({
          rgbaColor: p.$themeColor.doxleColor,
          alpha: "0.2",
        })};
    }
  }
`;

export const StyledPageNumberSection = styled(motion.div)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  display: flex;
  align-items: center;
  padding: 4px 8px;
  border-radius: 4px;
  border: 1px solid ${(p) => p.$themeColor.primaryDividerColor};
  color: ${(p) => p.$themeColor.doxleColor};
  .page-num-indicator {
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    margin: 0px 14px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: 0.2s all ease;
    padding: 2px;
    border-radius: 2px;
    &:hover {
      transition: 0.2s all ease;
      background-color: ${(p) =>
        editRgbaAlpha({
          rgbaColor: p.$themeColor.doxleColor,
          alpha: "0.2",
        })};
    }
  }
  .icon {
    cursor: pointer;
    transition: 0.2s all ease;
    &:hover {
      transition: 0.2s all ease;
      transform: scale(1.1);
    }
  }
`;

export const StyledPopoverPaginationList = styled(motion.div)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  max-height: 300px;
  width: 60px;
  display: flex;
  flex-direction: column;
  overflow: auto;
  margin-bottom: 20px;
  border: 1px solid ${(p) => p.$themeColor.primaryDividerColor};
  background-color: ${(p) => p.$themeColor.primaryContainerColor};
  border-radius: 4px;

  .list-item {
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-size: 12px;
    width: calc(100%);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
    transition: 0.2s all ease;
    padding: 8px 0px;
    display: flex;
    justify-content: center;
    border-radius: 2px;
    &:hover {
      transition: 0.2s all ease;
      background-color: ${(p) =>
        editRgbaAlpha({
          rgbaColor: p.$themeColor.doxleColor,
          alpha: "0.2",
        })};
      color: white;
    }
  }
  .selected {
    color: ${(p) => p.$themeColor.doxleColor};
  }
`;

export const StyledPricebookGroupHeader = styled(motion.div)`
  width: calc(100% - 16px) !important;
  padding: 0px 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${(p) => p.theme.staticMenuColor.staticBg};
  height: 35px;
  border-bottom: 1px solid ${(p) => p.theme.color.primaryDividerColor};
  cursor: pointer;
  z-index: 100;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  font-family: ${(p) => p.theme.font.primaryFont};
  color: ${(p) => p.theme.staticMenuColor.staticFontColor};
`;
