import React, { useCallback, useMemo, useState } from "react";
import { useDoxleAuthStore } from "../../../../../DoxleGeneralStore/useDoxleAuthStore";
import { shallow } from "zustand/shallow";
import { useDoxleCurrentContextStore } from "../../../../../DoxleGeneralStore/useDoxleCurrentContext";
import { useProjectQAStore } from "../../../Store/useProjectQAStore";
import QAQueryAPI, { UploadedQAImage } from "../../../../QueryAPI/qaQueryAPI";
import { QAImage, QAFile } from "../../../../Models/qa";
import {
  DoxleImageGalleryItem,
  useDoxleImageGalleryStore,
} from "../../../../../DoxleDesignPattern/DoxleGallery/Store/useDoxleImageGalleryStore";
import useDoxleNotificationStore from "../../../../../DoxleGeneralStore/useDoxleNotificationStore";
import { DropEvent, FileRejection, useDropzone } from "react-dropzone";
import Resizer from "react-image-file-resizer";
import { useShallow } from "zustand/react/shallow";

type Props = {};

interface QAImageSection {
  qaImageList: QAImage[];
  isFetchingImgList: boolean;
  isErrorFetchingImgList: boolean;
  handleRefetchQAImageList: () => void;
  handleClickImage: (imgIndex: number) => void;
  setdeletedQAImg: React.Dispatch<React.SetStateAction<QAImage | undefined>>;
  deletedQAImg: QAImage | undefined;
  handleCloseDeleteDialog: () => void;
  handleDeleteImg: () => void;
  onDrop: <T extends File>(
    acceptedFiles: T[],
    fileRejections: FileRejection[],
    event: DropEvent
  ) => Promise<void>;
  isAddingImg: boolean;
  deletedQAFile: QAFile | undefined;
  setDeletedQAFile: React.Dispatch<React.SetStateAction<QAFile | undefined>>;
}
const useQAImageSection = (props: Props): QAImageSection => {
  const [deletedQAImg, setdeletedQAImg] = useState<QAImage | undefined>(
    undefined
  );

  const [deletedQAFile, setDeletedQAFile] = useState<QAFile | undefined>(
    undefined
  );
  const showNotification = useDoxleNotificationStore(
    useShallow((state) => state.showNotification)
  );
  const { company, addCurrentErrorFiles } = useDoxleCurrentContextStore(
    useShallow((state) => ({
      company: state.currentCompany,
      addCurrentErrorFiles: state.addCurrentErrorFiles,
    }))
  );
  const { selectedQAItem } = useProjectQAStore(
    useShallow((state) => ({
      selectedQAItem: state.selectedQAItem,
    }))
  );

  const getQAImageQuery = QAQueryAPI.useRetrieveQAImageList({
    company,
    qaItem: selectedQAItem!,
  });

  const qaImageList: QAImage[] = useMemo(
    () => (getQAImageQuery.isSuccess ? getQAImageQuery.data.data.results : []),
    [getQAImageQuery.data]
  );

  const handleRefetchQAImageList = () => {
    getQAImageQuery.refetch();
  };
  const { setImageList, setCurrentImageIndex } = useDoxleImageGalleryStore(
    useShallow((state) => ({
      setImageList: state.setImageList,
      setCurrentImageIndex: state.setCurrentImageIndex,
    }))
  );
  const galleryImageList: DoxleImageGalleryItem[] = useMemo(
    () =>
      qaImageList.map((image) => ({
        uri: image.urlWithMarkup ?? "",
        thumbnailUrl: image.thumbUrl,
        name: image.imageName,
        size: image.imageSize.toString(),
      })),
    [qaImageList]
  );
  const handleClickImage = useCallback(
    (imgIndex: number) => {
      setImageList(galleryImageList);
      setCurrentImageIndex(imgIndex);
    },
    [galleryImageList, setImageList]
  );

  const handleCloseDeleteDialog = () => {
    setdeletedQAImg(undefined);
    setDeletedQAFile(undefined);
  };

  const deleteImgQuery = QAQueryAPI.useDeleteQAImageQuery({
    showNotification,
    company,
    qaItem: selectedQAItem!,
  });

  const handleDeleteImg = () => {
    if (deletedQAImg) deleteImgQuery.mutate(deletedQAImg.imageId);
    if (deletedQAFile) mutateFileQuery.destroy.mutate(deletedQAFile);
    handleCloseDeleteDialog();
  };

  //* add image query
  const addImgQuery = QAQueryAPI.useAddMultiQAImageQuery({
    showNotification,
    company,
    qaItem: selectedQAItem!,
  });

  const mutateFileQuery = QAQueryAPI.useMutateQAFileQuery({
    showNotification,
    company,
  });
  //######### HANDLE DROPPING FILES ###########

  const resizingFile = (file: File) =>
    new Promise((resolve: (value: UploadedQAImage) => void) => {
      Resizer.imageFileResizer(
        file,
        300,
        300,
        "JPEG",
        80,
        0,
        async (uri) => {
          const imgBitmap: ImageBitmap = await createImageBitmap(uri as Blob);
          resolve({
            imagePath: file.name,
            imagePathWithMarkup: file.name,
            thumbPath: file.name,
            imageName: file.name,
            imageType: file.type,
            imageHeight: imgBitmap.height,
            imageWidth: imgBitmap.width,
            imageSize: file.size,
            project: selectedQAItem?.project,
            company: company?.companyId ?? "",
            defectList: selectedQAItem?.defectList ?? "",
            defect: selectedQAItem?.defectId ?? "",
            file: file as Blob,
          });
        },
        "blob",
        700,
        700
      );
    });

  const onDrop = useCallback(
    async <T extends File>(
      acceptedFiles: T[],
      fileRejections: FileRejection[],
      event: DropEvent
    ) => {
      const images = acceptedFiles.filter((file) =>
        file.type.toLowerCase().includes("image")
      );
      const files = acceptedFiles.filter((file) =>
        !file.type.toLowerCase().includes("image")
      );

      await Promise.all(
        images.map((img) => {
          const resizeFile = resizingFile(img);
          return resizeFile;
        })
      ).then((resizeFiles) => {
        addImgQuery.mutate(resizeFiles);
      });

      files.forEach((file) => {
        mutateFileQuery.add.mutate({
          qaId: selectedQAItem?.defectId!,
          file,
        });
      });
      if (fileRejections.length > 0) addCurrentErrorFiles(fileRejections);
    },
    []
  );

  return {
    qaImageList,
    isFetchingImgList: getQAImageQuery.isLoading,
    isErrorFetchingImgList: getQAImageQuery.isError,
    handleRefetchQAImageList,
    handleClickImage,
    setdeletedQAImg,
    deletedQAImg,
    handleCloseDeleteDialog,
    handleDeleteImg,
    onDrop,
    isAddingImg: addImgQuery.isLoading || mutateFileQuery.add.isLoading,
    deletedQAFile,
    setDeletedQAFile,
  };
};

export default useQAImageSection;
