import {useDoxleThemeStore} from "../../../DoxleGeneralStore/useDoxleThemeStore";
import {shallow} from "zustand/shallow";
import {TableVirtuoso} from "react-virtuoso";
import {Table} from "@mui/material";
import DoxleEmptyPlaceHolder from "../../../DoxleDesignPattern/DoxleEmptyPlaceHolder/DoxleEmptyPlaceHolder";
import React from "react";
import EstimatesTableRow from "./EstimatesTableRow";
import EstimatesTableHeader from "./EstimatesTableHeader";
import {
  StyledListScroller
} from "../../../DoxleDesignPattern/ProjectSelectWithDropdown/StyledComponentsProjectSelectWithDropdown";
import {Estimate} from "../../Models/estimatesFilters";

interface Props {
  estimateList: Estimate[]
  handleFetchNext: () => void;
}


const EstimateCompanyTable = ({
  estimateList,
  handleFetchNext
}: Props) => {
  const doxleThemeColor = useDoxleThemeStore(state => state.doxleThemeColor, shallow)
  return (
    <TableVirtuoso
      data={estimateList}
      style={{
        width: "100%",
        height: "100%",
      }}
      components={{
        Table: (props) => <Table {...props} />,
        EmptyPlaceholder: (props) => (
          <DoxleEmptyPlaceHolder
            {...props}
            headTitleText={"No Estimates"}
            subTitleText={"Add a estimate to get started"}
            containerHeightRatio="100%"
            containerWidthRatio="100%"
            containerHeightInPixel={`${window.innerHeight - 400}px`}
            addBtn={{
              btnText: "Add",
              btnColor: doxleThemeColor.doxleColor,
              btnHeightInPixel: "50px",
              btnWidthInPixel: "164px",
              btnFunction: () => {console.log("NOTHING HERE YET")}
            }}
          />
        ),
        Scroller: React.forwardRef((props, ref) => (
          <StyledListScroller
            style={{
              ...props.style,
            }}
            ref={ref}
            {...props}
          />
        )),
      }}
      endReached={handleFetchNext}
      fixedHeaderContent={() =>  <EstimatesTableHeader /> }
      itemContent={(index, item) => (<EstimatesTableRow estimate={item} />)}
    />
  );
}

export default React.memo(EstimateCompanyTable)