import React from "react";
import { NoteLine } from "../../Models/note";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import {
  StyledLineItemDescriptionTextField,
  StyledNoteLineItem,
} from "./StyledEditNote";
import { AnimatePresence, Variants, motion } from "framer-motion";
import useNoteLineItem from "./Hooks/useNoteLineItem";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import ToolModeDisplay from "./ToolModeDisplay";
import { DoxleDeleteIcon } from "../../../DoxleDesignPattern/DoxleGeneralIcons";

type Props = {
  lineItem: NoteLine;
};

const NoteLineItem = ({ lineItem }: Props) => {
  const { doxleThemeColor, doxleFont, transparentMode } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
      transparentMode: state.transparentMode,
    }),
    shallow
  );
  const {
    handleToggleLineItemEffect,
    lineItemEffect,
    isFocused,
    setIsFocused,
    newLineDescription,
    handleBlurInput,
    lineDescriptionInputRef,
    checkLineEditToolEnable,
    handleKeydownDescriptionTextfield,
    handleDeleteLine,
    handleNewLineDescriptionChange,
    onHoverRow,
    setOnHoverRow,
  } = useNoteLineItem({ lineItem });
  const containerAnimatedVariant: Variants = {
    initial: {
      height: "100%",
    },
    focused: {
      height: "150px",
    },
  };
  return (
    <ClickAwayListener onClickAway={() => setIsFocused(false)}>
      <StyledNoteLineItem
        $themeColor={doxleThemeColor}
        variants={containerAnimatedVariant}
        initial="initial"
        animate={isFocused ? "focused" : "initial"}
        layout
        transition={{
          damping: 16,
          duration: 0.3,
          ease: "easeInOut",
        }}
        onMouseEnter={() => setOnHoverRow(true)}
        onMouseLeave={() => setOnHoverRow(false)}
      >
        <div className="input-wrapper">
          <StyledLineItemDescriptionTextField
            $themeColor={doxleThemeColor}
            $doxleFont={doxleFont}
            $isBold={checkLineEditToolEnable("bold")}
            $isItalic={checkLineEditToolEnable("italic")}
            inputRef={lineDescriptionInputRef}
            multiline
            maxRows={5}
            value={
              checkLineEditToolEnable("bullet")
                ? `*${newLineDescription}`
                : newLineDescription
            }
            onChange={(event) => {
              handleNewLineDescriptionChange(event.target.value);
            }}
            onFocus={() => {
              setIsFocused(true);
            }}
            onBlur={handleBlurInput}
            onKeyDown={handleKeydownDescriptionTextfield}
          />

          {onHoverRow && (
            <motion.div className="delete-icon" onClick={handleDeleteLine}>
              <DoxleDeleteIcon themeColor={doxleThemeColor} />
            </motion.div>
          )}
        </div>

        <AnimatePresence>
          {isFocused && (
            <ToolModeDisplay
              toolModes={lineItemEffect}
              handleToggleTool={handleToggleLineItemEffect}
              containerStyle={{
                position: "absolute",
                bottom: 4,
                left: 0,
                zIndex: 1,
              }}
            />
          )}
        </AnimatePresence>
      </StyledNoteLineItem>
    </ClickAwayListener>
  );
};

export default NoteLineItem;
