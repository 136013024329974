import { StyledQAListEditFieldWrapper } from "./StyledComponentQAListEdit";
import { useDoxleThemeStore } from "../../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import { useProjectQAStore } from "../../Store/useProjectQAStore";
import QACustomDatePicker from "../CommonComponents/QACustomDatePicker";
import useEditQAListDueDate from "./Hooks/useEditQAListDueDate";
import { useShallow } from "zustand/react/shallow";

type Props = {};

const EditQAListDueDate = (props: Props) => {
  const { edittedQAListItem } = useProjectQAStore(
    useShallow((state) => ({
      edittedQAListItem: state.edittedQAListItem,
    }))
  );
  const { handleUpdateDueDate, isUpdatingDueDate } = useEditQAListDueDate({});
  return (
    <StyledQAListEditFieldWrapper className="date-field-wrapper">
      <span className="field-label-text">Due Date</span>

      <QACustomDatePicker
        value={edittedQAListItem?.dueDate ?? null}
        onChange={handleUpdateDueDate}
        isUpdating={isUpdatingDueDate}
      />
    </StyledQAListEditFieldWrapper>
  );
};

export default EditQAListDueDate;
