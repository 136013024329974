import { ISVGResponsiveCustom } from "../../Models/modelHelper";

export const GalleryPreviousIcon = ({
  themeColor,
  containerStyle,
  ...props
}: ISVGResponsiveCustom) => (
  <div
    style={{ width: 12, ...containerStyle, aspectRatio: 1, display: "flex" }}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 12 12"
      fill="none"
      {...props}
    >
      <g clipPath="url(#a)">
        <path
          fill={themeColor.primaryFontColor}
          fillOpacity={0.6}
          d="M-.25-3a.75.75 0 0 0-.743.648L-1-2.25v16.5a.75.75 0 0 0 1.493.102L.5 14.25v-16.5A.75.75 0 0 0-.25-3Zm13.03.22a.75.75 0 0 0-.976-.073l-.084.073-8.25 8.25a.75.75 0 0 0-.073.976l.073.084 8.25 8.25a.75.75 0 0 0 1.133-.976l-.073-.084L5.06 6l7.72-7.72a.75.75 0 0 0 0-1.06Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill={themeColor.primaryFontColor} d="M0 0h12v12H0z" />
        </clipPath>
      </defs>
    </svg>
  </div>
);

export const GalleryNextIcon = ({
  themeColor,
  containerStyle,
  ...props
}: ISVGResponsiveCustom) => (
  <div
    style={{ width: 12, ...containerStyle, aspectRatio: 1, display: "flex" }}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={12}
      height={12}
      fill="none"
      {...props}
    >
      <g clipPath="url(#a)">
        <path
          fill={themeColor.primaryFontColor}
          fillOpacity={0.6}
          d="M12.25-3a.75.75 0 0 1 .743.648L13-2.25v16.5a.75.75 0 0 1-1.493.102l-.007-.102v-16.5a.75.75 0 0 1 .75-.75Zm-13.03.22a.75.75 0 0 1 .976-.073l.084.073 8.25 8.25a.75.75 0 0 1 .073.976l-.073.084-8.25 8.25a.75.75 0 0 1-1.133-.976l.073-.084L6.94 6-.78-1.72a.75.75 0 0 1 0-1.06Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill={themeColor.primaryFontColor} d="M12 0H0v12h12z" />
        </clipPath>
      </defs>
    </svg>
  </div>
);
