import React from "react";
import CustomAccordion from "../components/Accordion/Components/CustomAccordion";
import { AboutBanner } from "./svg/aboutBanner";
import {useDoxleThemeStore} from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { AboutPageContainer, AboutPageLeft, AboutPageRight } from "./styles/StyledAboutPage";
import { StyledBannerContainer } from "./styles/StyledMainPage";

export default function About() {
  const { doxleThemeColor } = useDoxleThemeStore((state) => ({
    doxleThemeColor: state.doxleThemeColor,
  }));

  return (
    <AboutPageContainer>
      <AboutPageLeft>
      <StyledBannerContainer><AboutBanner themeColor={doxleThemeColor} /></StyledBannerContainer>
      </AboutPageLeft>
      <AboutPageRight>
        <CustomAccordion />
      </AboutPageRight>
    </AboutPageContainer>
  );
}


