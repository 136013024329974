import React, { useEffect, useMemo } from "react";
import { useDoxleAuthStore } from "../../DoxleGeneralStore/useDoxleAuthStore";
import { shallow } from "zustand/shallow";
import { useDoxleCurrentContextStore } from "../../DoxleGeneralStore/useDoxleCurrentContext";
import useDoxleNotificationStore from "../../DoxleGeneralStore/useDoxleNotificationStore";
import { useEditDocketSideScreenStore } from "../../CoreContent/EditDocketSideScreen/EditDocketSideScreenStore/useEditDocketSideScreenStore";
import QuotesQueryAPI from "../QueryAPI/quotesQuery";
import { useDoxleQuoteStore } from "../store/useDoxleQuoteStore";
import { QuoteResponse } from "../models/quote";

type Props = {};

interface GetQuoteResponseList {
  quoteResponseList: QuoteResponse[];
  isFetchingQuoteResponse: boolean;
  isSuccessFetchingQuoteResponse: boolean;
  isErrorFetchingQuoteResponse: boolean;

  fetchNextPageResponse: () => void;
  isFetchingNextPageResponse: boolean;
}
const useGetQuoteResponseList = ({}: Props): GetQuoteResponseList => {
  
  const company = useDoxleCurrentContextStore(
    (state) => state.currentCompany,
    shallow
  );
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );
  const { docket } = useEditDocketSideScreenStore(
    (state) => ({
      docket: state.edittedDocket,
      setOpen: state.setDisplayShareTab,
    }),
    shallow
  );
  const { filterGetQuoteResponse, setFilterGetQuoteResponse } =
    useDoxleQuoteStore(
      (state) => ({
        filterGetQuoteResponse: state.filterGetQuoteResponse,
        setFilterGetQuoteResponse: state.setFilterGetQuoteResponse,
      }),
      shallow
    );
  useEffect(() => {
    setFilterGetQuoteResponse({ docketId: docket?.docketPk, userAdded: true });
  }, [docket]);

  const quoteResponseQuery = QuotesQueryAPI.useRetrieveQuoteResponseList({

    company,
    filter: filterGetQuoteResponse,
    showNotification,
  });

  const quoteResponseList = useMemo(
    () =>
      quoteResponseQuery.isSuccess
        ? quoteResponseQuery.data.pages.reduce((acc, data) => {
            return acc.concat(data.data.results);
          }, [] as QuoteResponse[])
        : [],
    [quoteResponseQuery.data]
  );

  const fetchNextPageResponse = () => {
    if (quoteResponseQuery.hasNextPage) quoteResponseQuery.fetchNextPage();
  };
  return {
    quoteResponseList,
    isFetchingQuoteResponse: quoteResponseQuery.isLoading,
    isSuccessFetchingQuoteResponse: quoteResponseQuery.isSuccess,
    isErrorFetchingQuoteResponse: quoteResponseQuery.isError,

    fetchNextPageResponse,
    isFetchingNextPageResponse: quoteResponseQuery.isFetchingNextPage,
  };
};

export default useGetQuoteResponseList;
