import { Button, ButtonProps, CircularProgress, styled } from "@mui/material";
import { HTMLMotionProps, MotionProps, motion } from "framer-motion";
import React, { useRef } from "react";
import {
  DoxleFont,
  DoxleThemeColor,
  useDoxleThemeStore,
} from "../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import { editRgbaAlpha } from "../../Utilities/FunctionUtilities";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

interface Props extends ButtonProps {
  btnText?: string;
  btnIcon?: boolean;
  isLoading?: boolean;
}
const MotionButton = motion(Button);
interface IStyledAnimatedButton
  extends Omit<
    ButtonProps,
    "onDragStart" | "onAnimationStart" | "onDragEnd" | "onDrag"
  > {
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}
const AnimatedButton = styled(
  ({ $themeColor, $doxleFont, ...rest }: IStyledAnimatedButton) => (
    <MotionButton
      {...rest}
      initial={{
        scale: 0.9,
        opacity: 0,
      }}
      animate={{
        scale: 1,
        opacity: 1,
        transition: {
          duration: 0.2,
          delay: 0.2,
        },
      }}
      exit={{
        y: [0, 10],
        opacity: 0,
      }}
      layout
    />
  )
)`
  && {
    display: flex;
    flex-direction: row;
    background-color: rgba(234, 21, 21, 0.4);
    border-radius: 0 !important;
    //width: 6.7rem;
    height: 2.6rem;
    min-width: 6rem;
    .btn-text {
      color: ${(p) => editRgbaAlpha({
        rgbaColor: p.$themeColor.primaryFontColor,
        alpha: '0.7'
      })};
      font-family: ${(p) => p.$doxleFont.primaryFont};
      font-size: 1.4rem;
      font-style: normal;
      font-weight: 500;
      line-height: 118.9%;
      letter-spacing: -0.028rem;
      text-transform: capitalize;
      flex: 1;
      text-align: center;
    }

    padding: 0px 3px 0px 10px !important;
    &:hover {
      background-color: rgba(234, 21, 21, 0.7);
      transition: 0.4s;
    }

    .icon-container {
      padding: 0px 0.15rem;
      height: 100%;
      //border-left: 1px solid #6461fb;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .start-icon-container {
      padding-left: 0.15rem;
      padding-right: 0.05rem;
    }
    .end-icon-container {
      padding-left: 0.05rem;
      padding-right: 0.15rem;
    }
  }
`;
const DoxleDeleteManyButton = React.forwardRef<HTMLButtonElement, Props>(
  (
    {
      onDragStart,
      onAnimationStart,
      onDragEnd,
      onDrag,
      btnText,
      btnIcon,
      isLoading,
      ...rest
    }: Props,
    ref
  ) => {
    const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
      (state) => ({
        doxleThemeColor: state.doxleThemeColor,
        doxleFont: state.doxleFont,
      }),
      shallow
    );
    const iconColor = editRgbaAlpha({
      rgbaColor: doxleThemeColor.primaryFontColor,
      alpha: '0.7'
    })
    return (
      <AnimatedButton
        ref={ref}
        {...rest}
        style={{
          backgroundColor: rest.disabled ? "grey" : rest.style?.backgroundColor,
        }}
        $themeColor={doxleThemeColor}
        $doxleFont={doxleFont}
      >
        <div className="btn-text">{btnText ? btnText : "Delete"}</div>

        {btnIcon !== false && (
          <div className="icon-container end-icon-container">
            {!isLoading ? (
              <DeleteOutlineIcon
                style={{ color: iconColor, fontSize: "1.4rem" }}
              />
            ) : (
              <CircularProgress style={{ color: iconColor }} size="1.4rem" />
            )}
          </div>
        )}
      </AnimatedButton>
    );
  }
);

export default DoxleDeleteManyButton;
