import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import DoxleAPI from "../../Services/DoxleAPI";
import { BaseAPIProps } from "../../Models/baseAPIProps";
import { IFullDocketDetailQueryFilterProp } from "../../Models/dockets";
import { useDoxleCurrentContextStore } from "../../DoxleGeneralStore/useDoxleCurrentContext";
import { shallow } from "zustand/shallow";
import useDoxleNotificationStore from "../../DoxleGeneralStore/useDoxleNotificationStore";
import { isAxiosError } from "axios";
import { AxiosBackendErrorReturn } from "../../Models/axiosReturn";
import { useShallow } from "zustand/react/shallow";

interface RetrieveDocketsListInterface extends BaseAPIProps {
  filter: IFullDocketDetailQueryFilterProp;
}

const useRetrieveDocketList = ({
  filter,
  company,
  showNotification,
}: RetrieveDocketsListInterface) => {
  const qKey = ["fullDocket-list"];
  let docketURL = `/dockets/?page=1`;
  if (company) qKey.push(company.companyId);
  for (const [key, value] of Object.entries(filter)) {
    qKey.push(value);
  }
  return useInfiniteQuery(
    qKey,
    ({ pageParam = docketURL }) =>
      DoxleAPI.get(pageParam, {
        headers: {
          "User-Company": company!.companyId,
        },
        params: filter,
      }),
    {
      enabled: Boolean(company),
      // retry: true,
      // staleTime: 10 * 60 * 1000,
      refetchOnWindowFocus: false,
      refetchInterval: false,
      getNextPageParam: (prevData) => prevData.data?.next,
      onError: (error) => {
        if (showNotification) {
          if (isAxiosError<AxiosBackendErrorReturn>(error)) {
            showNotification(
              `${error?.response?.status ?? "ERROR"}: ${
                error.response?.data.detail ?? "UNKNOWN ERROR"
              }`,
              "error",
              String(
                error?.response?.data?.detail ?? "FAILED TO GET DOCKET LIST"
              ).substring(0, 300)
            );
          } else {
            showNotification(
              "Something Wrong!",
              "error",
              "FAILED TO GET DOCKET LIST"
            );
          }
        }
      },
    }
  );
};

const DocketsQueryAPI = {
  useRetrieveDocketList,
};

export default DocketsQueryAPI;
