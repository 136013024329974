import { IBooking } from "../../../Models/booking";
import { useDoxleThemeStore } from "../../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import { Variants, motion } from "framer-motion";

import { BOOKING_PROJECT_TABLE_FIELD } from "../../../store/useBookingProjectStore";

import { HiOutlineCheck } from "react-icons/hi";
import { editRgbaAlpha } from "../../../../Utilities/FunctionUtilities";
import CircularProgress from "@mui/material/CircularProgress";
import dayjs from "dayjs";

import deleteIcon from "../../../Asset/deleteIcon.svg";
import useBookingTableRow from "./Hooks/useBookingTableRow";
import {
  StyleBookingTableDataCell,
  StyledBookingTableRowContainer,
} from "./StyledComponentBookingDocketTable";
import BookingNumber from "../../../CommonComponents/BookingNumber";
import BookingPercentage from "../../../CommonComponents/BookingPercentage";
import {
  DoxleMailIcon,
  DoxleSMSIcon,
} from "../../../../DoxleDesignPattern/DoxleGeneralIcons";
import { useMemo } from "react";
type Props = {
  bookingItem: IBooking;
};

const BookingTableRow = ({ bookingItem }: Props) => {
  const { doxleThemeColor, doxleFont, transparentMode, currentTheme } =
    useDoxleThemeStore(
      (state) => ({
        doxleThemeColor: state.doxleThemeColor,
        doxleFont: state.doxleFont,
        transparentMode: state.transparentMode,
        currentTheme: state.currentTheme,
      }),
      shallow
    );
  const {
    isUpdatingBookingRow,
    isDeletingBookingRow,
    handleClickRow,
    isHoverRow,
    setisHoverRow,
    handleClickDeleteIcon,
    handleSendBookingAlert,
  } = useBookingTableRow({
    bookingItem,
  });
  const rowAnimatedVariants: Variants = {
    initial: {
      opacity: 0,
    },
    entering: {
      opacity: 1,
    },
    exiting: {
      opacity: 0,
    },
  };

  const dueDays = useMemo(
    () =>
      bookingItem.endDate
        ? dayjs(bookingItem.endDate).diff(
            new Date().toISOString().substring(0, 10),
            "day"
          )
        : undefined,
    [bookingItem]
  );

  return (
    <StyledBookingTableRowContainer
      $transparentMode={transparentMode}
      $currentTheme={currentTheme}
      $themeColor={doxleThemeColor}
      variants={rowAnimatedVariants}
      initial="initial"
      animate="entering"
      exit="exiting"
      onClick={handleClickRow}
      onMouseEnter={() => setisHoverRow(true)}
      onMouseLeave={() => setisHoverRow(false)}
    >
      {BOOKING_PROJECT_TABLE_FIELD.map((header, idx) => {
        if (header === "Days" && window.innerWidth < 1300) return null;
        if (header === "Due" && window.innerWidth < 1100) return null;
        if (header === "Title") {
          return (
            <StyleBookingTableDataCell
              key={`bookingCell#${bookingItem.bookingId}#${idx}`}
              $widthRatio={null}
              $horizontalAlign="flex-start"
              $doxleFont={doxleFont}
              $themeColor={doxleThemeColor}
            >
              {isHoverRow && (
                <motion.img
                  src={deleteIcon}
                  alt="delete icon"
                  className="delete-img-holder"
                  onClick={handleClickDeleteIcon}
                  initial={{
                    x: -10,
                    opacity: 0,
                  }}
                  animate={{
                    x: 0,
                    opacity: 1,
                  }}
                />
              )}

              <motion.div layout>
                {!isUpdatingBookingRow && !isDeletingBookingRow ? (
                  bookingItem.completed ? (
                    <HiOutlineCheck
                      fontSize="2rem"
                      color={editRgbaAlpha({
                        rgbaColor: doxleThemeColor.primaryFontColor,
                        alpha: "0.4",
                      })}
                    />
                  ) : (
                    <BookingNumber bookingItem={bookingItem} />
                  )
                ) : (
                  <CircularProgress
                    size="1.4rem"
                    style={{
                      color: isDeletingBookingRow
                        ? "red"
                        : doxleThemeColor.primaryFontColor,
                      marginRight: 8,
                    }}
                  />
                )}
              </motion.div>

              <motion.span
                className="title-text-content"
                style={{
                  color: bookingItem.completed
                    ? editRgbaAlpha({
                        rgbaColor: doxleThemeColor.primaryFontColor,
                        alpha: "0.4",
                      })
                    : doxleThemeColor.primaryFontColor,
                }}
                layout
              >
                {bookingItem.title}
              </motion.span>
            </StyleBookingTableDataCell>
          );
        } else if (header === "End Date" || header === "Start Date")
          return (
            <StyleBookingTableDataCell
              key={`bookingCell#${bookingItem.bookingId}#${idx}`}
              $widthRatio={"12%"}
              $horizontalAlign="center"
              $doxleFont={doxleFont}
              $themeColor={doxleThemeColor}
            >
              <span className="date-text-content">
                {header === "Start Date" &&
                  dayjs(bookingItem.startDate).format("DD MMM YY")}
                {header === "End Date" &&
                  dayjs(bookingItem.endDate).format("DD MMM YY")}
              </span>
            </StyleBookingTableDataCell>
          );
        else if (header === "Progress")
          return (
            <StyleBookingTableDataCell
              key={`bookingCell#${bookingItem.bookingId}#${idx}`}
              $widthRatio={"15%"}
              $horizontalAlign="center"
              $doxleFont={doxleFont}
              $themeColor={doxleThemeColor}
            >
              <BookingPercentage percentage={bookingItem.percentageCompleted} />
            </StyleBookingTableDataCell>
          );
        else if (header === "Due")
          return (
            <StyleBookingTableDataCell
              key={`bookingCell#${bookingItem.bookingId}#${idx}`}
              $widthRatio={"10%"}
              $horizontalAlign="center"
              $doxleFont={doxleFont}
              $themeColor={doxleThemeColor}
            >
              <span
                className={
                  (dueDays ?? 0) < 0
                    ? "date-period-content-overdue"
                    : "date-period-content"
                }
              >
                {" "}
                {dueDays ? `${dueDays} days` : "-"}
              </span>
            </StyleBookingTableDataCell>
          );
        else if (header === "Share")
          return (
            <StyleBookingTableDataCell
              key={`bookingCell#${bookingItem.bookingId}#${idx}`}
              $widthRatio={"5%"}
              $horizontalAlign="center"
              $doxleFont={doxleFont}
              $themeColor={doxleThemeColor}
            >
              <DoxleMailIcon
                className={"email"}
                themeColor={doxleThemeColor}
                onDivClick={handleSendBookingAlert}
              />
              <DoxleSMSIcon
                className={"sms"}
                themeColor={doxleThemeColor}
                onDivClick={handleSendBookingAlert}
              />
            </StyleBookingTableDataCell>
          );
        else
          return (
            <StyleBookingTableDataCell
              key={`bookingCell#${bookingItem.bookingId}#${idx}`}
              $widthRatio={"10%"}
              $horizontalAlign="center"
              $doxleFont={doxleFont}
              $themeColor={doxleThemeColor}
            >
              <span className="date-period-content">
                {" "}
                {bookingItem.startDate && bookingItem.endDate
                  ? `${dayjs(bookingItem.endDate).diff(
                      bookingItem.startDate,
                      "day"
                    )} days`
                  : "-"}
              </span>
            </StyleBookingTableDataCell>
          );
      })}
    </StyledBookingTableRowContainer>
  );
};

export default BookingTableRow;
