import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import AddQuoteRequest from "./AddQuoteRequest/AddQuoteRequest";
import { StyledRootContainer } from "./MainStyledComponents";
import QuoteRequestsTable from "./QuoteRequestTable/QuoteRequestsTable";
// import QuoteResponseTab from "./QuoteResponseTab";
import { shallow } from "zustand/shallow";
import { useDoxleQuoteStore } from "../store/useDoxleQuoteStore";
import { useEditDocketSideScreenStore } from "../../CoreContent/EditDocketSideScreen/EditDocketSideScreenStore/useEditDocketSideScreenStore";
import { useCallback, useEffect, useMemo } from "react";
import AddQuotesMenuPopper from "./AddQuotesMenuPopper/AddQuotesMenuPopper";
import AddQuoteResponse from "./AddQuoteResponse/AddQuoteResponse";
import ReviewQuoteRequest from "./ReviewQuoteRequest/ReviewQuoteRequest";
import { useIsMutating } from "@tanstack/react-query";
import {
  getQRequestMutationKey,
  getQResponseMutationKey,
} from "../QueryAPI/quotesQuery";
import QuoteFunctionalMenu from "./QuoteFunctionalMenu";

export type QuoteTableView =
  | "QuoteRequestsTable"
  | "QuoteRequestDetail"
  | "QuoteResponseDetail"
  | "AddQuoteRequest"
  | "AddQuoteResponse";

const QuotesPage = () => {
  const { THEME_COLOR, DOXLE_FONT } = useDoxleThemeStore((state) => ({
    THEME_COLOR: state.doxleThemeColor,
    DOXLE_FONT: state.doxleFont,
  }));

  const { view, setView, reviewedQuoteRequest } = useDoxleQuoteStore(
    (state) => ({
      view: state.view,
      setView: state.setView,
      reviewedQuoteRequest: state.reviewedQuoteRequest,
    }),
    shallow
  );
  const { setHandleAddButtonFn } = useEditDocketSideScreenStore(
    (state) => ({ setHandleAddButtonFn: state.setHandleAddButtonFn }),
    shallow
  );
  const { setAnchorAddQuotesMenuPopover, resetQuoteStore } = useDoxleQuoteStore(
    (state) => ({
      setAnchorAddQuotesMenuPopover: state.setAnchorAddQuotesMenuPopover,
      resetQuoteStore: state.resetQuoteStore,
    }),
    shallow
  );

  const isAddingRequest = useIsMutating({
    mutationKey: getQRequestMutationKey("add"),
  });
  const isAddingReponse = useIsMutating({
    mutationKey: getQResponseMutationKey("add"),
  });

  useEffect(() => {
    setHandleAddButtonFn([
      {
        btnFunction: setAnchorAddQuotesMenuPopover,
        isLoading: isAddingRequest > 0 || isAddingReponse > 0,
      },
    ]);
    return () => resetQuoteStore();
  }, []);

  //* Animation
  const rootQuoteAnimatedVariants = {
    entering: {
      opacity: [0, 1],
      y: [-10, 0],
      transition: {
        duration: 0.4,
      },
    },
  };
  return (
    <StyledRootContainer
      $themeColor={THEME_COLOR}
      $doxleFont={DOXLE_FONT}
      variants={rootQuoteAnimatedVariants}
      initial={false}
      animate="entering"
    >

      {view === "QuoteRequestsTable" ? (
        <>
        <QuoteFunctionalMenu />
        <QuoteRequestsTable />
        </>
      ) : view === "QuoteRequestDetail" && reviewedQuoteRequest ? (
        <ReviewQuoteRequest reviewedQuoteRequest={reviewedQuoteRequest} />
      ) : //view === "QuoteResponseDetail" ? (
      //   <QuoteResponseTab setView={setView} />
      // )
      view === "AddQuoteRequest" ? (
        <AddQuoteRequest />
      ) : (
        <AddQuoteResponse />
      )}

      <AddQuotesMenuPopper />
    </StyledRootContainer>
  );
};

export default QuotesPage;
