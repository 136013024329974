import React from "react";
import { useDoxleCurrentContextStore } from "../../DoxleGeneralStore/useDoxleCurrentContext";
import { useShallow } from "zustand/react/shallow";
import {
  StyledDoxleErrorActionButton,
  StyledDoxleErrorFileWarningContainer,
  StyledDoxleErrorFilesContainer,
  StyledDoxleErrorWarningActions,
  StyledDoxleErrorWarningContent,
  StyledDoxleErrorWarningTitle,
} from "./StyledDoxleErrorFileWarning";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import { editRgbaAlpha } from "../../Utilities/FunctionUtilities";
import { checkSupportedFileType } from "../../Utilities/MimeFileType";
import {
  DoxleCSVIcon,
  DoxleExcelIcon,
  DoxlePDFIcon,
  DoxleWordIcon,
} from "../DoxleGeneralIcons";
import { DoxleTextIcon } from "../../CoreContent/DoxleTopMenu/DoxleTopMenuIcon";
import { CiFileOff } from "react-icons/ci";

type Props = {};

const DoxleErrorFileWarning = (props: Props) => {
  const { doxleThemeColor } = useDoxleThemeStore((state) => ({
    doxleThemeColor: state.doxleThemeColor,
  }));
  const { clearCurrentErrorFiles, currentErrorFiles } =
    useDoxleCurrentContextStore(
      useShallow((state) => ({
        clearCurrentErrorFiles: state.clearCurrentErrorFiles,
        currentErrorFiles: state.currentErrorFiles,
      }))
    );
  return (
    <StyledDoxleErrorFileWarningContainer
      open={Boolean(currentErrorFiles.length > 0)}
      onClose={clearCurrentErrorFiles}
      PaperProps={{
        sx: {
          bgColor: doxleThemeColor.primaryContainerColor,
          borderRadius: "0.9rem",
          border: `1px solid ${doxleThemeColor.primaryDividerColor}`,
          minWidth: "500px !important",
          maxWidth: "800px !important",
          maxHeight: "500px !important",
        },
        elevation: 2,
      }}
      slotProps={{
        backdrop: {
          sx: {
            bgcolor: editRgbaAlpha({
              rgbaColor: doxleThemeColor.primaryBackgroundColor,
              alpha: "0.8",
            }),
          },
        },
      }}
    >
      <StyledDoxleErrorWarningTitle>
        {currentErrorFiles.length} Error File
        {currentErrorFiles.length > 1 ? "s" : ""}
      </StyledDoxleErrorWarningTitle>

      <StyledDoxleErrorWarningContent>
        {currentErrorFiles.map((file, idx) => {
          const supportedFileType = checkSupportedFileType({
            type: file.file.type,
          });

          return (
            <StyledDoxleErrorFilesContainer key={`key#${idx}`}>
              {supportedFileType === "pdf" ? (
                <DoxlePDFIcon
                  themeColor={doxleThemeColor}
                  containerStyle={{
                    width: 35,
                  }}
                />
              ) : supportedFileType === "doc" ? (
                <DoxleWordIcon
                  themeColor={doxleThemeColor}
                  containerStyle={{
                    width: 35,
                  }}
                />
              ) : supportedFileType === "xls" ? (
                <DoxleExcelIcon
                  themeColor={doxleThemeColor}
                  containerStyle={{
                    width: 35,
                  }}
                />
              ) : supportedFileType === "csv" ? (
                <DoxleCSVIcon
                  themeColor={doxleThemeColor}
                  containerStyle={{
                    width: 35,
                  }}
                />
              ) : supportedFileType === "text" ? (
                <DoxleTextIcon
                  themeColor={doxleThemeColor}
                  containerStyle={{
                    width: 35,
                  }}
                />
              ) : (
                <CiFileOff color={doxleThemeColor.primaryFontColor} size={35} />
              )}
              <div className="error-wrapper">
                <span className="file-name">{file.file.name}</span>
                {file.errors.map((error, idx) => (
                  <span key={`error#${idx}`} className="text">
                    * {error.message}
                  </span>
                ))}
              </div>
            </StyledDoxleErrorFilesContainer>
          );
        })}
        <StyledDoxleErrorWarningActions>
          <StyledDoxleErrorActionButton onClick={clearCurrentErrorFiles}>
            <span className="button-text">Ok</span>
          </StyledDoxleErrorActionButton>
        </StyledDoxleErrorWarningActions>
      </StyledDoxleErrorWarningContent>
    </StyledDoxleErrorFileWarningContainer>
  );
};

export default DoxleErrorFileWarning;
