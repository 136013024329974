import styled from "styled-components";
import {
  DoxleFont,
  DoxleThemeColor,
} from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { editRgbaAlpha } from "../../../Utilities/FunctionUtilities";

//==================================Upload Page=======================================
//====================================================================================
//====================================================================================

export const StyledTitle = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  font-size: 2.7rem;
  font-family: ${(p) => p.$doxleFont.secondaryTitleFont};
  margin-top: 20px;
  margin-bottom: 10px;
  color: ${(p) => p.$themeColor.primaryFontColor};
`;

export const StyledPrompt = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  font-size: 1.8rem;
  font-family: ${(p) => p.$doxleFont.secondaryTitleFont};
  color: rgba(111, 111, 125, 1);
`;

export const SizeDescription = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  font-size: 1.4rem;
  font-family: ${(p) => p.$doxleFont.secondaryTitleFont};
  color: ${(p) =>
    editRgbaAlpha({ rgbaColor: p.$themeColor.primaryFontColor, alpha: "0.9" })};
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const DrawingUploadPageContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const UploadContainer = styled.div`
  text-align: center;
`;

export const FileUploadSubmitBtn = styled.button<{
  $themeColor: DoxleThemeColor;
}>`
  background-color: ${(p) => p.$themeColor.primaryFontColor};
  color: ${(p) => p.$themeColor.primaryBackgroundColor};
  width: 8rem;
  height: 3.9rem;
  border-radius: 3px;
  border: none;
  cursor: pointer;
  margin-top: 1rem;
`;

//==================================Progress Page======================================
//====================================================================================
//====================================================================================

export const ProgressPageContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ProgressContainer = styled.div`
  text-align: center;
`;

export const StyledUploadingTitle = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  margin-top: 30px;
  font-size: 4.5rem;
  font-family: ${(p) => p.$doxleFont.secondaryTitleFont};
  margin-bottom: 20px;
  color: ${(p) => p.$themeColor.primaryFontColor};
`;

export const StyledProgressValue = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  font-family: ${(p) => p.$doxleFont.secondaryTitleFont};
  font-size: 2.7rem;
  font-weight: bold;
  margin-top: 20px;
  color: ${(p) =>
    editRgbaAlpha({ rgbaColor: p.$themeColor.primaryFontColor, alpha: "0.6" })};
`;

//==================================Floorplan price page======================================
//====================================================================================
//====================================================================================

export const StyledWarning = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  color: #ea1515;
  font-size: 1.2rem;
  font-family: ${(p) => p.$doxleFont.secondaryTitleFont};
  height: 0px;
  position: relative;
  left: 1.5rem;
`;

export const FormArea = styled.div``;

export const ButtonArea = styled.div`
  height: fit-content;
`;

export const StyledInput = styled.input<{
  $themeColor: DoxleThemeColor;
}>`
  width: 100%;
  padding: 12px 20px;
  margin: 10px 0;
  box-sizing: border-box;
  border: none;
  border-bottom: 2px solid #e6e6e6;
  background: transparent;
  height: 30px;
  color: ${(p) => p.$themeColor.primaryFontColor};

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: ${(p) => p.$themeColor.primaryFontColor};
    opacity: 1; /* Firefox */
    font-size: 1.4rem;
  }

  &::-ms-input-placeholder {
    /* Edge 12-18 */
    color: ${(p) => p.$themeColor.primaryFontColor};
    font-size: 1.4rem;
  }
`;

export const StyledDescription = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  font-size: 1.1rem;
  font-family: ${(p) => p.$doxleFont.secondaryTitleFont};
  color: ${(p) =>
    editRgbaAlpha({ rgbaColor: p.$themeColor.primaryFontColor, alpha: "0.7" })};
  padding: 20px;
`;

export const FloorplanPricePageContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
   @media (max-width: 600px){
    flex-direction: column;
    overflow-y: scroll;
    overflow-x: hidden;
  }
`;

export const FloorplanPricePageLeft = styled.div`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 0 2rem;

  img {
    width: 100%;
    aspect-ratio: ${239 / 162};
    min-width: 100px;
    max-width: 239px;
  }
`;

export const FloorplanPricePageRight = styled.div`
  padding: 2rem;
  @media (max-width: 600px){
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
`;

export const FloorplanPricePageRightInnerContainer = styled.div<{
  $themeColor: DoxleThemeColor;
}>`
  border: 2px solid ${(p) => p.$themeColor.borderColor};
  padding: 10px;
  border-radius: 5px;
  max-width: 328px;
`;

export const UserDetailSubmitBtn = styled.button<{
  $themeColor: DoxleThemeColor;
}>`
  background-color: ${(p) => p.$themeColor.primaryFontColor};
  color: ${(p) => p.$themeColor.primaryBackgroundColor};
  width: 8rem;
  height: 3rem;
  border-radius: 3px;
  border: none;
  cursor: pointer;
  margin-top: 3rem;
  margin-bottom: 1rem;
  margin-left: 2rem;
`;

export const BudgetResult = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  color: ${(p) => p.$themeColor.primaryFontColor};
  font-family: ${(p) => p.$doxleFont.secondaryTitleFont};
  font-size: 1.8rem;
  font-weight: bold;
  margin-top: 20px;
  max-width: 22rem;
`;

export const StyledBudgetPrice = styled.span<{
  $themeColor: DoxleThemeColor;
}>`
  color: ${(p) => p.$themeColor.doxleColor};
`;
