import {
  QueryClient,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import DoxleAPI from "../../Services/DoxleAPI";
import { BaseAPIProps } from "../../Models/baseAPIProps";
import { Company } from "../../Models/company";
// import {
//   ScopeOfWorkGroup,
//   ScopeOfWorkImage,
//   ScopeOfWorkItem,
// } from "../../Model/scopeOfWorks";
import { groupBy } from "lodash";

import {
  AxiosBackendErrorReturn,
  IApiPaginatedData,
} from "../../Models/axiosReturn";
import {
  QuoteScopeOfWorkItem,
  ScopeOfWorkGroup,
  ScopeOfWorkImage,
  ScopeOfWorkItem,
} from "../../ScopeOfWorks/Model/scopeOfWorks";
import { isAxiosError } from "axios";

// const baseUrlSOWItem = ""/scope/";

export interface FilterRetrieveScopeOfWorkQuery {
  docketId?: string;
  projectId?: string;
  search?: string;
}
interface RetrieveScopeOfWorkGroupProps extends BaseAPIProps {
  filter: FilterRetrieveScopeOfWorkQuery;
  onSuccessCb?: Function;
}

const useRetrieveScopeGroupList = ({
  company,
  filter,
  showNotification,
  onSuccessCb,
}: RetrieveScopeOfWorkGroupProps) => {
  const qKey = formScopeOfWorkGroupQkey(filter, company);
  const { docketId, projectId, search } = filter;
  let getParams: any = {};
  if (company) getParams.company = company.companyId;
  if (docketId) {
    getParams.docket = docketId;
  }
  if (projectId) {
    getParams.project = projectId;
  }
  if (search) {
    getParams.search = search;
  }

  const baseUrlSOWGroup = "/scope/group/?page=1";
  const getScopeOfWorkGroupQuery = useInfiniteQuery({
    queryKey: qKey,
    queryFn: async ({ pageParam = baseUrlSOWGroup }) => {
      return DoxleAPI.get<IApiPaginatedData<ScopeOfWorkGroup>>(pageParam, {
        headers: {
          "User-Company": company?.companyId || "",
        },
        params: getParams,
      });
    },
    getNextPageParam: (prevData) => prevData.data?.next,
    onSuccess: () => {
      if (onSuccessCb) onSuccessCb();
    },

    onError: (error) => {
      if (showNotification) {
        if (isAxiosError<AxiosBackendErrorReturn>(error)) {
          showNotification(
            `${error?.response?.status ?? "ERROR"}: ${
              error.response?.data.detail ?? "UNKNOWN ERROR"
            }`,
            "error",
            String(
              error?.response?.data?.detail ??
                "Failed to get scope of work list"
            ).substring(0, 300)
          );
        } else {
          showNotification(
            "Something Wrong!",
            "error",
            "Failed to get scope of work list"
          );
        }
      }
    },
    enabled: Boolean(company && (docketId || projectId)),
    retry: 1,

    // refetchInterval: 5 * 60 * 1000,
    // refetchIntervalInBackground: true,
    refetchOnWindowFocus: false,
    // refetchOnReconnect: true,
  });
  return getScopeOfWorkGroupQuery;
};

interface AddScopeGroupAPIProps extends BaseAPIProps {
  filter: FilterRetrieveScopeOfWorkQuery;
  onSuccessCb?: Function;
}
const useAddScopeGroupQuery = ({
  showNotification,

  company,
  filter,
  onSuccessCb,
}: AddScopeGroupAPIProps) => {
  const queryClient = useQueryClient();
  const baseUrlSOWGroup = "/scope/group/";
  const addScopeGroupQuery = useMutation({
    mutationFn: async (data: ScopeOfWorkGroup) => {
      return DoxleAPI.post(baseUrlSOWGroup, data, {
        headers: {
          "User-Company": company?.companyId ?? "",
        },
      });
    },
    onSuccess(result, variables, context) {
      handleOverWriteScopeGroupQueryData({
        queryClient,
        action: "add",
        company,
        filter,
        addedScopeGroup: result.data,
      });
      // const qKey = formScopeOfWorkGroupQkey(filter, company);
      // queryClient.invalidateQueries(qKey);

      // if (showNotification) showNotification("Added Scope Group", "success");

      if (onSuccessCb) onSuccessCb();
      //console.log(result);
    },
    onError(error, variables, context) {
      console.log("PostTaskGroupERROR");
      console.log(error);
    },
  });
  const mutate = (data: ScopeOfWorkGroup) => addScopeGroupQuery.mutate(data);
  return { ...addScopeGroupQuery, mutate };
};

interface UpdateScopeGroupAPIProps extends BaseAPIProps {
  filter: FilterRetrieveScopeOfWorkQuery;
  onSuccessCb?: Function;
}
export interface UpdateScopeGroupData
  extends Pick<ScopeOfWorkGroup, "scopeGroupId">,
    Partial<
      Pick<
        ScopeOfWorkGroup,
        | "scopeGroupTitle"
        | "assignedContactCompany"
        | "assignedContacts"
        | "docket"
        | "project"
      >
    > {}
const useUpdateScopeGroupQuery = ({
  showNotification,

  company,
  filter,
  onSuccessCb,
}: UpdateScopeGroupAPIProps) => {
  const queryClient = useQueryClient();
  const qKey = formScopeOfWorkGroupQkey(filter, company);
  const baseUrlSOWGroup = "/scope/group/";
  const updateSOWGroupQuery = useMutation({
    mutationFn: async (data: UpdateScopeGroupData) => {
      const { scopeGroupId, ...rest } = data;
      return DoxleAPI.patch(baseUrlSOWGroup + `${scopeGroupId}/`, rest, {
        headers: {
          "User-Company": company?.companyId ?? "",
        },
      });
    },
    onSuccess(result, variables, context) {
      queryClient.invalidateQueries({ queryKey: qKey });
      handleOverWriteScopeGroupQueryData({
        queryClient,
        action: "edit",
        company,
        filter,
        edittedScopeGroup: result.data,
      });

      // if (showNotification) showNotification("Item Updated", "success");

      if (onSuccessCb) onSuccessCb();
      //console.log(result);
    },
    onError(error, variables, context) {
      console.log("PostTaskGroupERROR");
      console.log(error);
    },
  });
  const mutate = (data: UpdateScopeGroupData) =>
    updateSOWGroupQuery.mutate(data);
  return { ...updateSOWGroupQuery, mutate };
};

interface DeleteScopeGroupAPIProps extends BaseAPIProps {
  filter: FilterRetrieveScopeOfWorkQuery;
  onSuccessCb?: Function;
}
const useDeleteScopeGroupQuery = ({
  showNotification,

  company,
  filter,
  onSuccessCb,
}: DeleteScopeGroupAPIProps) => {
  const queryClient = useQueryClient();
  const qKey = formScopeOfWorkGroupQkey(filter, company);
  const baseUrlSOWGroup = "/scope/group/";
  const updateSOWGroupQuery = useMutation({
    mutationFn: async (groupId: string) => {
      return DoxleAPI.delete(baseUrlSOWGroup + `${groupId}/`, {
        headers: {
          "User-Company": company?.companyId ?? "",
        },
      });
    },
    onSuccess(result, variables, context) {
      queryClient.invalidateQueries({ queryKey: qKey });
      handleOverWriteScopeGroupQueryData({
        queryClient,
        action: "delete",
        company,
        filter,
        deletedScopeGroupId: variables,
      });

      // if (showNotification) showNotification("Item Updated", "success");

      if (onSuccessCb) onSuccessCb();
      //console.log(result);
    },
    onError(error, variables, context) {
      if (showNotification) {
        if (isAxiosError<AxiosBackendErrorReturn>(error)) {
          showNotification(
            `${error?.response?.status ?? "ERROR"}: ${
              error.response?.data.detail ?? "UNKNOWN ERROR"
            }`,
            "error",
            String(
              error?.response?.data?.detail ?? "Post Task Group ERROR"
            ).substring(0, 300)
          );
        } else {
          showNotification(
            "Something Wrong!",
            "error",
            "Post Task Group ERROR"
          );
        }
      }
    },
  });
  const mutate = (groupId: string) => updateSOWGroupQuery.mutate(groupId);
  return { ...updateSOWGroupQuery, mutate };
};
//#SCOPE ITEMS SECTION
export interface FilterRetrieveScopeItemQuery {
  scopeGroupId: string;
  docket?: string;
  project?: string;
  quote_mode?: boolean;
}
interface RetrieveScopeItemQueryProps extends BaseAPIProps {
  filter: FilterRetrieveScopeItemQuery;
  onSuccessCb?: Function;
}

const useRetrieveScopeItemListQuery = ({
  filter,
  showNotification,

  company,
  onSuccessCb,
}: RetrieveScopeItemQueryProps) => {
  const qKey = formScopeOfWorkItemQKey(filter, company);
  const { scopeGroupId, docket, project, quote_mode } = filter;
  let getParams: any = {};

  // if (company) getParams.company = company.companyId;
  // if (docket) {
  //   getParams.docket = docket;
  // }
  // if (project) {
  //   getParams.project = project;
  // }
  getParams.scope_group = scopeGroupId;
  if (quote_mode) getParams.quote_mode = true;
  const baseUrlSOWGroup = "/scope/";
  const getScopeOfWorkItemQuery = useQuery({
    queryKey: qKey,
    queryFn: async () => {
      return DoxleAPI.get<QuoteScopeOfWorkItem[]>(baseUrlSOWGroup, {
        headers: {
          "User-Company": company?.companyId || "",
        },
        params: getParams,
      });
    },
    onSuccess: () => {
      if (onSuccessCb) onSuccessCb();
    },

    onError: (error) => {
      if (showNotification) {
        if (isAxiosError<AxiosBackendErrorReturn>(error)) {
          showNotification(
            `${error?.response?.status ?? "ERROR"}: ${
              error.response?.data.detail ?? "UNKNOWN ERROR"
            }`,
            "error",
            String(
              error?.response?.data?.detail ??
                "Failed to get scope of work list"
            ).substring(0, 300)
          );
        } else {
          showNotification(
            "Something Wrong!",
            "error",
            "Failed to get scope of work list"
          );
        }
      }
    },
    enabled: Boolean(company),
    retry: 1,

    // refetchInterval: 5 * 60 * 1000,
    // refetchIntervalInBackground: true,
    refetchOnWindowFocus: false,
    // refetchOnReconnect: true,
  });
  return getScopeOfWorkItemQuery;
};

interface AddScopeItemQueryProps extends BaseAPIProps {
  filter: FilterRetrieveScopeItemQuery;
  onSuccessCb?: Function;
}
const useAddScopeItemQuery = ({
  showNotification,

  company,
  filter,
  onSuccessCb,
}: AddScopeItemQueryProps) => {
  const queryClient = useQueryClient();
  const baseUrlSOWItem = "/scope/new/";
  const addScopeItemQuery = useMutation({
    mutationFn: async (data: ScopeOfWorkItem) => {
      return DoxleAPI.post(baseUrlSOWItem, data, {
        headers: {
          "User-Company": company?.companyId ?? "",
        },
      });
    },
    onSuccess(result, variables, context) {
      handleOverWriteScopeItemQueryData({
        queryClient,
        action: "add",
        addedScopeItem: result.data,
        filter,
        company,
      });

      // if (showNotification) showNotification("Added Scope Group", "success");

      if (onSuccessCb) onSuccessCb();
      //console.log(result);
    },
    onError(error, variables, context) {
      if (showNotification) {
        if (isAxiosError<AxiosBackendErrorReturn>(error)) {
          showNotification(
            `${error?.response?.status ?? "ERROR"}: ${
              error.response?.data.detail ?? "UNKNOWN ERROR"
            }`,
            "error",
            String(
              error?.response?.data?.detail ?? "Failed to add Scope Item"
            ).substring(0, 300)
          );
        } else {
          showNotification(
            "Something Wrong!",
            "error",
            "Failed to add Scope Item"
          );
        }
      }
    },
  });
  const mutate = (data: ScopeOfWorkItem) => addScopeItemQuery.mutate(data);
  return { ...addScopeItemQuery, mutate };
};

interface DeleteScopeItemQueryProps extends BaseAPIProps {
  filter: FilterRetrieveScopeItemQuery;
  onSuccessCb?: Function;
}

const useDeleteScopeItemQuery = ({
  showNotification,

  company,
  filter,
  onSuccessCb,
}: DeleteScopeItemQueryProps) => {
  const queryClient = useQueryClient();

  const addScopeItemQuery = useMutation({
    mutationFn: async (deletedScopeItemId: string) => {
      const baseUrlSOWItem = `/scope/${deletedScopeItemId}/`;
      return DoxleAPI.delete(baseUrlSOWItem, {
        headers: {
          "User-Company": company?.companyId ?? "",
        },
      });
    },
    onSuccess(result, variables, context) {
      handleOverWriteScopeItemQueryData({
        queryClient,
        action: "delete",
        deletedScopeItemId: variables,
        filter,
        company,
      });

      // if (showNotification) showNotification("Deleted Scope ITem", "success");

      if (onSuccessCb) onSuccessCb();
      //console.log(result);
    },
    onError(error, variables, context) {
      console.log("PostTaskGroupERROR");
      console.log(error);
      if (showNotification) {
        if (isAxiosError<AxiosBackendErrorReturn>(error)) {
          showNotification(
            `${error?.response?.status ?? "ERROR"}: ${
              error.response?.data.detail ?? "UNKNOWN ERROR"
            }`,
            "error",
            String(
              error?.response?.data?.detail ?? "Failed to delete Scope Item"
            ).substring(0, 300)
          );
        } else {
          showNotification(
            "Something Wrong!",
            "error",
            "Failed to delete Scope Item"
          );
        }
      }
    },
  });
  const mutate = (deletedScopeItemId: string) =>
    addScopeItemQuery.mutate(deletedScopeItemId);
  return { ...addScopeItemQuery, mutate };
};

interface MultiDeleteScopeItemQueryProps extends BaseAPIProps {
  onSuccessCb?: Function;
}

const useMultiDeleteScopeItemQuery = ({
  showNotification,

  company,

  onSuccessCb,
}: MultiDeleteScopeItemQueryProps) => {
  const queryClient = useQueryClient();

  const multiDeleteScopeItemQuery = useMutation({
    mutationFn: async (deletedScopeItemList: ScopeOfWorkItem[]) => {
      const deletedIds = deletedScopeItemList.map((item) => item.scopeItemId);
      const baseUrlSOWItem = `/scope/scope_item_multi_delete/`;
      return DoxleAPI.post(baseUrlSOWItem, deletedIds, {
        headers: {
          "User-Company": company?.companyId ?? "",
        },
      });
    },
    onSuccess(result, variables, context) {
      const extractList: ExtractFilterScopeItemResult[] =
        handleExtractFilterScopeItemList(variables);
      extractList.forEach((item) =>
        handleOverWriteScopeItemQueryData({
          queryClient,
          company,
          action: "delete",
          filter: item.filter,
          deletedScopeItems: item.items,
        })
      );
      // if (showNotification) showNotification("Deleted Scope ITem", "success");

      if (onSuccessCb) onSuccessCb();
      //console.log(result);
    },
    onError(error, variables, context) {
      console.log("PostTaskGroupERROR");
      console.log(error);
      if (showNotification)
        if (showNotification) {
          if (isAxiosError<AxiosBackendErrorReturn>(error)) {
            showNotification(
              `${error?.response?.status ?? "ERROR"}: ${
                error.response?.data.detail ?? "UNKNOWN ERROR"
              }`,
              "error",
              String(
                error?.response?.data?.detail ?? "Failed to delete Scope Item"
              ).substring(0, 300)
            );
          } else {
            showNotification(
              "Something Wrong!",
              "error",
              "Failed to delete Scope Item"
            );
          }
        }
    },
  });
  const mutate = (data: ScopeOfWorkItem[]) =>
    multiDeleteScopeItemQuery.mutate(data);
  return { ...multiDeleteScopeItemQuery, mutate };
};

export interface UpdateScopeItemData
  extends Partial<
    Pick<ScopeOfWorkItem, "completed" | "approved" | "scopeItemDescription">
  > {
  scopeItemId: string;
}
interface UpdateScopeItemQueryProps extends BaseAPIProps {
  filter: FilterRetrieveScopeItemQuery;
  onSuccessCb?: Function;
}
const useUpdateScopeItemQuery = ({
  showNotification,

  company,
  filter,
  onSuccessCb,
}: UpdateScopeItemQueryProps) => {
  const queryClient = useQueryClient();

  const updateScopeItemQuery = useMutation({
    mutationFn: async (data: UpdateScopeItemData) => {
      const { scopeItemId, ...rest } = data;
      const baseUrlSOWItem = `/scope/${scopeItemId}/`;
      return DoxleAPI.patch(baseUrlSOWItem, rest, {
        headers: {
          "User-Company": company?.companyId ?? "",
        },
      });
    },
    onSuccess(result, variables, context) {
      handleOverWriteScopeItemQueryData({
        queryClient,
        action: "edit",
        edittedScopeItem: result.data,
        filter,
        company,
      });

      // if (showNotification) showNotification("Updated Scope ITem", "success");

      if (onSuccessCb) onSuccessCb();
      //console.log(result);
    },
    onError(error, variables, context) {
      console.log("PostTaskGroupERROR");
      console.log(error);
      if (showNotification) {
        if (isAxiosError<AxiosBackendErrorReturn>(error)) {
          showNotification(
            `${error?.response?.status ?? "ERROR"}: ${
              error.response?.data.detail ?? "UNKNOWN ERROR"
            }`,
            "error",
            String(
              error?.response?.data?.detail ?? "Failed to update Scope Item"
            ).substring(0, 300)
          );
        } else {
          showNotification(
            "Something Wrong!",
            "error",
            "Failed to update Scope Item"
          );
        }
      }
    },
  });
  const mutate = (data: UpdateScopeItemData) =>
    updateScopeItemQuery.mutate(data);
  return { ...updateScopeItemQuery, mutate };
};
//# SCOPE IMAGE SECTION
interface RetrieveScopeImageQueryProps extends BaseAPIProps {
  scopeItemId: string;
  enabled: boolean;
  onSuccessCb?: Function;
}
const useRetrieveScopeItemImage = ({
  showNotification,
  company,
  onSuccessCb,
  scopeItemId,
  enabled,
}: RetrieveScopeImageQueryProps) => {
  const qKey = formScopeOfWorkImageQueryKey(scopeItemId, company);

  let getParams: any = {};
  getParams.scope_item = scopeItemId;

  const baseUrlSOWImage = "/scope/img_list/";
  const getScopeOfWorkItemQuery = useQuery({
    queryKey: qKey,
    queryFn: async () => {
      return DoxleAPI.get(baseUrlSOWImage, {
        headers: {
          "User-Company": company?.companyId || "",
        },
        params: getParams,
      });
    },
    onSuccess: () => {
      if (onSuccessCb) onSuccessCb();
    },

    onError: (error) => {
      if (showNotification) {
        if (isAxiosError<AxiosBackendErrorReturn>(error)) {
          showNotification(
            `${error?.response?.status ?? "ERROR"}: ${
              error.response?.data.detail ?? "UNKNOWN ERROR"
            }`,
            "error",
            String(
              error?.response?.data?.detail ?? "Failed to delete Scope image"
            ).substring(0, 300)
          );
        } else {
          showNotification(
            "Something Wrong!",
            "error",
            "Failed to delete Scope image"
          );
        }
      }
    },
    enabled: Boolean(company && enabled),
    retry: 1,

    refetchInterval: false,
    refetchIntervalInBackground: true,
    refetchOnWindowFocus: false,
    // refetchOnReconnect: true,
  });
  return getScopeOfWorkItemQuery;
};

export interface AddedScopeItemImage {
  uri: string;
  name: string;
  type: string;
}

interface AddMultiScopeImageQueryProps extends BaseAPIProps {
  scopeItemId: string;
  onSuccessCb?: Function;
}

export interface AddMultiScopeImageData extends ScopeOfWorkImage {
  imageFile: AddedScopeItemImage;
}
const useAddMultiScopeImageQuery = ({
  showNotification,

  company,
  scopeItemId,
  onSuccessCb,
}: AddMultiScopeImageQueryProps) => {
  const queryClient = useQueryClient();
  const baseUrlSOWImg = "/scope/img_multi_add/";
  const addScopeImgQuery = useMutation({
    mutationFn: async (data: Array<AddMultiScopeImageData>) => {
      let formData = new FormData();
      formData.append("scopeItem", scopeItemId);
      for (const scopeImage of data) {
        const { imageFile, ...rest } = scopeImage;
        formData.append("scope_images", JSON.stringify(rest));

        //generate blob
        const blobResult = await fetch(imageFile.uri)
          .then((res) => res.blob())
          .catch((err) => undefined);
        if (blobResult)
          formData.append(
            "files",
            blobResult,
            `${imageFile.name}.${imageFile.type.split("/")[1]}`
          );
      }
      return DoxleAPI.post(baseUrlSOWImg, formData, {
        headers: {
          "User-Company": company?.companyId ?? "",
        },
      });
    },
    onSuccess(result, variables, context) {
      console.log("IMG ADD RESULT:", result.data);
      handleOverWriteScopeImgQueryData({
        queryClient,
        action: "add",
        addedScopeImages: result.data.scopeImages,
        scopeItemId,
        company,
      });

      // if (showNotification) showNotification("Added Scope Images", "success");

      if (onSuccessCb) onSuccessCb();
      //console.log(result);
    },
    onError(error, variables, context) {
      console.log("PostTaskGroupERROR");
      console.log(error);
      if (showNotification) {
        if (isAxiosError<AxiosBackendErrorReturn>(error)) {
          showNotification(
            `${error?.response?.status ?? "ERROR"}: ${
              error.response?.data.detail ?? "UNKNOWN ERROR"
            }`,
            "error",
            String(
              error?.response?.data?.detail ?? "Failed To Upload Images"
            ).substring(0, 300)
          );
        } else {
          showNotification(
            "Something Wrong!",
            "error",
            "Failed To Upload Images"
          );
        }
      }
    },
  });
  const mutate = (data: Array<AddMultiScopeImageData>) =>
    addScopeImgQuery.mutate(data);
  return { ...addScopeImgQuery, mutate };
};

interface EditScopeImageQueryProps extends BaseAPIProps {
  scopeItemId: string;
  scopeImgId: string;
  onSuccessCb?: Function;
}

export interface EditScopeImgData
  extends Partial<
    Pick<ScopeOfWorkImage, "scopeImageTitle" | "scopeImageDescription">
  > {}
const useUpdateScopeImageQuery = ({
  showNotification,

  company,
  scopeItemId,
  scopeImgId,
  onSuccessCb,
}: EditScopeImageQueryProps) => {
  const queryClient = useQueryClient();
  const baseUrlSOWImg = `/scope/img/${scopeImgId}/`;
  const updateScopeImgQuery = useMutation({
    mutationFn: async (data: EditScopeImgData) => {
      return DoxleAPI.patch(baseUrlSOWImg, data, {
        headers: {
          "User-Company": company?.companyId ?? "",
        },
      });
    },
    onSuccess(result, variables, context) {
      console.log("IMG ADD RESULT:", result.data);
      handleOverWriteScopeImgQueryData({
        queryClient,
        action: "edit",
        edittedScopeImage: result.data,
        scopeItemId,
        company,
      });

      // if (showNotification) showNotification("Updated Scope Images", "success");

      if (onSuccessCb) onSuccessCb(result.data);
      //console.log(result);
    },
    onError(error, variables, context) {
      console.log("PostTaskGroupERROR");
      console.log(error);
      if (showNotification) {
        if (isAxiosError<AxiosBackendErrorReturn>(error)) {
          showNotification(
            `${error?.response?.status ?? "ERROR"}: ${
              error.response?.data.detail ?? "UNKNOWN ERROR"
            }`,
            "error",
            String(
              error?.response?.data?.detail ?? "Failed To update Image"
            ).substring(0, 300)
          );
        } else {
          showNotification(
            "Something Wrong!",
            "error",
            "Failed To update Image"
          );
        }
      }
    },
  });
  const mutate = (data: EditScopeImgData) => updateScopeImgQuery.mutate(data);
  return { ...updateScopeImgQuery, mutate };
};

interface DeleteMultiScopeImageQueryProps extends BaseAPIProps {
  scopeItemId: string;
  onSuccessCb?: Function;
}
const useMultiDeleteScopeImageQuery = ({
  showNotification,

  company,
  scopeItemId,
  onSuccessCb,
}: DeleteMultiScopeImageQueryProps) => {
  const queryClient = useQueryClient();
  const baseUrlSOWImg = `/scope/img_multi_delete/`;
  const updateScopeImgQuery = useMutation({
    mutationFn: async (data: string[]) => {
      return DoxleAPI.post(baseUrlSOWImg, data, {
        headers: {
          "User-Company": company?.companyId ?? "",
        },
      });
    },
    onSuccess(result, variables, context) {
      console.log("IMG ADD RESULT:", result.data);
      handleOverWriteScopeImgQueryData({
        queryClient,
        action: "delete",
        deletedScopeImgIds: variables,
        scopeItemId,
        company,
      });

      // if (showNotification) showNotification("Updated Scope Images", "success");

      if (onSuccessCb) onSuccessCb(variables);
      //console.log(result);
    },
    onError(error, variables, context) {
      console.log("PostTaskGroupERROR");
      console.log(error);
      if (showNotification) {
        if (isAxiosError<AxiosBackendErrorReturn>(error)) {
          showNotification(
            `${error?.response?.status ?? "ERROR"}: ${
              error.response?.data.detail ?? "UNKNOWN ERROR"
            }`,
            "error",
            String(
              error?.response?.data?.detail ?? "Failed To delete Images"
            ).substring(0, 300)
          );
        } else {
          showNotification(
            "Something Wrong!",
            "error",
            "Failed To delete Images"
          );
        }
      }
    },
  });
  const mutate = (data: string[]) => updateScopeImgQuery.mutate(data);
  return { ...updateScopeImgQuery, mutate };
};
//# HELPER FUNCTIONS

//*---> Scope Group <---
interface OverwriteScopeGroupQueryData {
  queryClient: QueryClient;
  action: "edit" | "add" | "delete";
  appendPos?: "start" | "end";
  filter: FilterRetrieveScopeOfWorkQuery;
  company: Company | undefined;
  addedScopeGroup?: ScopeOfWorkGroup;
  edittedScopeGroup?: ScopeOfWorkGroup;
  deletedScopeGroupId?: string;
}
const handleOverWriteScopeGroupQueryData = ({
  queryClient,
  action,
  appendPos = "start",
  filter,
  company,
  addedScopeGroup,
  edittedScopeGroup,
  deletedScopeGroupId,
}: OverwriteScopeGroupQueryData) => {
  const qKey = formScopeOfWorkGroupQkey(filter, company);

  if (action === "add" && addedScopeGroup) {
    return queryClient.setQueryData(qKey, (old: any) => {
      if (old) {
        const newData = old.pages.map((page: any, pageIndex: number) => {
          if (appendPos === "start") {
            if (pageIndex === 0) {
              const newPageData = {
                ...page,
                data: [addedScopeGroup, ...page.data],
              };
              return newPageData;
            } else return page;
          } else {
            if (pageIndex === old.pages.length - 1) {
              const newPageData = {
                ...page,
                data: [...page.data, addedScopeGroup],
              };
              return newPageData;
            } else return page;
          }
        });
        return { ...old, pages: newData };
      } else return old;
    });
  }

  if (action === "edit" && edittedScopeGroup) {
    queryClient.setQueryData(qKey, (old: any) => {
      if (old) {
        const newData = old.pages.map((page: any) => {
          //check if editted item is inside current page item
          if (
            (page.data.results as ScopeOfWorkGroup[]).find(
              (scopeGroupItem) =>
                scopeGroupItem.scopeGroupId === edittedScopeGroup.scopeGroupId
            ) !== undefined
          ) {
            const newPageData = {
              ...page,
              data: {
                ...page.data,
                results: (page.data.results as ScopeOfWorkGroup[]).map(
                  (scopeGroupItem) => {
                    if (
                      scopeGroupItem.scopeGroupId ===
                      edittedScopeGroup.scopeGroupId
                    )
                      return { ...edittedScopeGroup };
                    else return scopeGroupItem;
                  }
                ),
              },
            };

            return newPageData;
          } else return page;
        });

        return { ...old, pages: newData };
      } else return old;
    });
  }

  if (action === "delete" && deletedScopeGroupId) {
    queryClient.setQueryData(qKey, (old: any) => {
      if (old) {
        const newData = old.pages.map((page: any) => {
          if (
            (page.data.results as ScopeOfWorkGroup[]).find(
              (scopeGroupItem) =>
                scopeGroupItem.scopeGroupId === deletedScopeGroupId
            ) !== undefined
          ) {
            const newPageData = {
              ...page,
              data: {
                ...page.data,
                results: (page.data.results as ScopeOfWorkGroup[]).filter(
                  (scopeGroupItem) =>
                    scopeGroupItem.scopeGroupId !== deletedScopeGroupId
                ),
              },
            };

            return newPageData;
          } else return page;
        });

        return { ...old, pages: newData };
      } else return old;
    });
  }
};

export const formScopeOfWorkGroupQkey = (
  filter: FilterRetrieveScopeOfWorkQuery,
  company: Company | undefined
) => {
  let baseQKey = ["scope_of_works_group"];
  const { docketId, projectId, search } = filter;
  if (company) baseQKey.push(company.companyId);
  if (docketId) baseQKey.push(docketId);
  if (projectId) baseQKey.push(projectId);
  if (search) baseQKey.push(search);
  return baseQKey;
};

//*---> Scope Item <---
interface OverwriteScopeItemQueryData {
  queryClient: QueryClient;
  action: "edit" | "add" | "delete";
  appendPos?: "start" | "end";
  filter: FilterRetrieveScopeItemQuery;
  company: Company | undefined;
  addedScopeItem?: ScopeOfWorkItem;
  edittedScopeItem?: ScopeOfWorkItem;
  deletedScopeItemId?: string;
  deletedScopeItems?: ScopeOfWorkItem[];
}

const handleOverWriteScopeItemQueryData = ({
  queryClient,
  action,
  appendPos = "start",
  filter,
  company,
  addedScopeItem,
  edittedScopeItem,
  deletedScopeItemId,
  deletedScopeItems,
}: OverwriteScopeItemQueryData) => {
  const qKey = formScopeOfWorkItemQKey(filter, company);

  if (action === "add" && addedScopeItem) {
    return queryClient.setQueryData(qKey, (old: any) =>
      old
        ? {
            ...old,
            data:
              appendPos === "start"
                ? [addedScopeItem, ...old.data]
                : [...old.data, addedScopeItem],
          }
        : old
    );
  }
  if (action === "delete") {
    if (deletedScopeItemId)
      return queryClient.setQueryData(qKey, (old: any) =>
        old
          ? {
              ...old,
              data: (old.data as ScopeOfWorkItem[]).filter(
                (scopeItem) => scopeItem.scopeItemId !== deletedScopeItemId
              ),
            }
          : old
      );
    else if (deletedScopeItems)
      return queryClient.setQueryData(qKey, (old: any) =>
        old
          ? {
              ...old,
              data: (old.data as ScopeOfWorkItem[]).filter(
                (scopeItem) =>
                  !Boolean(
                    deletedScopeItems.find(
                      (deletedItem) =>
                        scopeItem.scopeItemId === deletedItem.scopeItemId
                    ) !== undefined
                  )
              ),
            }
          : old
      );
  }

  if (action === "edit" && edittedScopeItem) {
    return queryClient.setQueryData(qKey, (old: any) =>
      old
        ? {
            ...old,
            data: (old.data as ScopeOfWorkItem[]).map((scopeItem) =>
              scopeItem.scopeItemId === edittedScopeItem.scopeItemId
                ? edittedScopeItem
                : scopeItem
            ),
          }
        : old
    );
  }
};
export const formScopeOfWorkItemQKey = (
  filter: FilterRetrieveScopeItemQuery,
  company: Company | undefined
) => {
  let baseQKey = ["scope_items_list"];
  const { scopeGroupId, docket, project } = filter;
  if (company) baseQKey.push(company.companyId);
  if (project) baseQKey.push(project);
  if (docket) baseQKey.push(docket);
  baseQKey.push(scopeGroupId);
  return baseQKey;
};

//*---> Scope Image <---
interface OverwriteScopeImageQueryData {
  queryClient: QueryClient;
  action: "edit" | "add" | "delete";
  appendPos?: "start" | "end";
  scopeItemId: string;
  company: Company | undefined;
  addedScopeImages?: ScopeOfWorkImage[];
  edittedScopeImage?: ScopeOfWorkImage;
  deletedScopeImgIds?: string[];
}

const handleOverWriteScopeImgQueryData = ({
  queryClient,
  action,
  appendPos,
  scopeItemId,
  company,
  addedScopeImages,
  edittedScopeImage,
  deletedScopeImgIds,
}: OverwriteScopeImageQueryData) => {
  const qKey = formScopeOfWorkImageQueryKey(scopeItemId, company);

  if (action === "add" && addedScopeImages) {
    queryClient.setQueryData(qKey, (old: any) =>
      old
        ? {
            ...old,
            data:
              appendPos === "start"
                ? [...addedScopeImages, ...old.data]
                : [...old.data, ...addedScopeImages],
          }
        : old
    );
  }
  if (action === "edit" && edittedScopeImage) {
    console.log("OVERWRITE IMG, QKEY:", qKey);
    return queryClient.setQueryData(qKey, (old: any) =>
      old
        ? {
            ...old,
            data: (old.data as ScopeOfWorkImage[]).map((orImg) => {
              if (orImg.imageId === edittedScopeImage.imageId)
                return {
                  ...edittedScopeImage,
                  url: orImg.url,
                  thumbUrl: orImg.thumbUrl,
                };
              else return orImg;
            }),
          }
        : old
    );
  }

  if (action === "delete" && deletedScopeImgIds) {
    return queryClient.setQueryData(qKey, (old: any) =>
      old
        ? {
            ...old,
            data: [
              ...(old.data as ScopeOfWorkImage[]).filter(
                (img) =>
                  !Boolean(
                    deletedScopeImgIds.find(
                      (delImg) => delImg === img.imageId
                    ) !== undefined
                  )
              ),
            ],
          }
        : old
    );
  }
};
const formScopeOfWorkImageQueryKey = (
  scopeItemId: string,
  company: Company | undefined
) => {
  let baseQKey = ["scope_item_image_list"];
  if (company) baseQKey.push(company.companyId);
  baseQKey.push(scopeItemId);
  return baseQKey;
};

//# handle extract filter from list of different scope items belong to different scope group
interface ExtractFilterScopeItemResult {
  filter: FilterRetrieveScopeItemQuery;
  items: ScopeOfWorkItem[];
}
const handleExtractFilterScopeItemList = (
  list: ScopeOfWorkItem[]
): ExtractFilterScopeItemResult[] => {
  let filterList: ExtractFilterScopeItemResult[] = [];
  const groupedScopeItems = groupBy(list, (item) => item.scopeGroup);
  Object.keys(groupedScopeItems).forEach((groupId) => {
    const scopeItems = groupedScopeItems[groupId];
    const filter: FilterRetrieveScopeItemQuery = {
      scopeGroupId: groupId,
      docket: scopeItems[0].docket ?? undefined,
      project: scopeItems[0].project ?? undefined,
    };
    filterList.push({
      filter,
      items: scopeItems,
    });
  });

  return filterList;
};
const ScopeOfWorkAPI = {
  useRetrieveScopeGroupList,
  useAddScopeGroupQuery,
  useUpdateScopeGroupQuery,
  useRetrieveScopeItemListQuery,
  useAddScopeItemQuery,
  useRetrieveScopeItemImage,
  useDeleteScopeItemQuery,
  useUpdateScopeItemQuery,
  useAddMultiScopeImageQuery,
  useUpdateScopeImageQuery,
  useMultiDeleteScopeImageQuery,
  useMultiDeleteScopeItemQuery,
  useDeleteScopeGroupQuery,
};

export default ScopeOfWorkAPI;
