import React from "react";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import { StyledEditDocketLabelWithIconContainer } from "../StyledComponentsEditDocketSideScreen";
import {
  CreateByIcon,
  CreateTimeIcon,
  LastEditedByIcon,
  LastEditedTimeIcon,
} from "../EditDocketSideScreenIcons";

type Props = {
  type: "created By" | "created Time" | "last Edited By" | "last Edited Time";
};

const EditDocketLabelWithIcon = ({ type }: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }),
    shallow
  );
  return (
    <StyledEditDocketLabelWithIconContainer
      $themeColor={doxleThemeColor}
      $doxleFont={doxleFont}
    >
      {type === "created By" ? (
        <CreateByIcon {...doxleThemeColor} />
      ) : type === "created Time" ? (
        <CreateTimeIcon {...doxleThemeColor} />
      ) : type === "last Edited By" ? (
        <LastEditedByIcon {...doxleThemeColor} />
      ) : (
        <LastEditedTimeIcon {...doxleThemeColor} />
      )}
      <p> {type}</p>
    </StyledEditDocketLabelWithIconContainer>
  );
};

export default EditDocketLabelWithIcon;
