import React from "react";
import { DocketStatus } from "../../../Models/dockets";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import { StyledNbStatusItem } from "./StyledNbStatusPopover";
import DoxleStatus from "../../../DoxleDesignPattern/DoxleStatus/DoxleStatus";
import { TRgbaFormat } from "../../../Utilities/FunctionUtilities";

type Props = {
  handleUpdateNbStatus: (status: DocketStatus) => void;
  statusItem: DocketStatus;
  isSelected: boolean;
};

const NbStatusItem = ({
  handleUpdateNbStatus,
  statusItem,
  isSelected,
}: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }),
    shallow
  );
  return (
    <StyledNbStatusItem
      $doxleFont={doxleFont}
      $statusColor={statusItem.color}
      $selected={isSelected}
      onClick={() => handleUpdateNbStatus(statusItem)}
    >
      <DoxleStatus
        statusColor={statusItem.color as TRgbaFormat}
        style={{ marginRight: 4 }}
        size={14}
      />
      <div className="status-text">{statusItem.statusName}</div>
    </StyledNbStatusItem>
  );
};

export default NbStatusItem;
