import {useNavigate, useSearchParams} from "react-router-dom";



const useNavigateToLastApp = () => {
  const [URLSearchParams, SetURLSearchParams] = useSearchParams();
  const appParam = URLSearchParams.get('app') ?? ''
  const pathParam = URLSearchParams.get('pathParam') ?? '/'
  const apps= ['noticeboard', 'projects', 'bills', 'inventory', 'draw'];
  const navigate = useNavigate();
  const localLastUsedApp = localStorage.getItem('lastUsedApp');
  return () => {
    if (apps.includes(appParam)) {
      if (window.location.hostname.toLowerCase().includes("doxle")) {
        window.open(`https://${appParam}.doxle.com${pathParam}`, "_self");
      } else {
        navigate(appParam ? `/${appParam.charAt(0).toUpperCase() + appParam.slice(1)}/` : '/Projects/');
      }
    } else if (localLastUsedApp && apps.includes(localLastUsedApp)) {
      if (window.location.hostname.toLowerCase().includes("doxle")) {
        window.open(`https://${localLastUsedApp}.doxle.com${pathParam}`, "_self");
      } else {
        navigate(`/${localLastUsedApp.charAt(0).toUpperCase() + localLastUsedApp.slice(1)}/`);
      }
    } else {
      if (window.location.hostname.toLowerCase().includes("doxle")) {
        window.open(`https://projects.doxle.com${pathParam}`, "_self");
      } else {
        navigate("/Projects/");
      }
    }
  };
};
export default useNavigateToLastApp;
