import {useJoinContext} from "../pages/Join";
import {useEffect, useState} from "react";
import ContactUsAPI from "./contactUsAPI";


const NAME_TRIGGER_ID = 0;
const MOBILE_TRIGGER_ID = 1;
const EMAIL_TRIGGER_ID = 2;

const emailRegex = new RegExp(
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);

const mobileRegex = new RegExp(
  /^(?:\+?(61))? ?(?:\((?=.*\)))?(0?[2-57-8])\)? ?(\d\d(?:[- ](?=\d{3})|(?!\d\d[- ]?\d[- ]))\d\d[- ]?\d[- ]?\d{3})$/
);

const useJoinFormPage = () => {
  const { setStage, userFormData, setUserFormData } = useJoinContext();

  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [company, setCompany] = useState("");
  const [reference, setReference] = useState("");
  const [prompt, setPrompt] = useState<string[]>(["", "", ""]);
  const [sendState, setSendState] = useState<undefined|"sending"|"error">()

  const joinRequest = ContactUsAPI.useJoinRequest({
    onSuccessCb: () => {
      setStage(prev => prev + 1);
      setSendState(undefined);
    },
    onErrorCb: () => setSendState("error"),
  })
  const handleSubmit = async () => {
    let valid = true;
    let newPrompt = [...prompt];
    setUserFormData(prev => ({
      ...prev,
      name,
      mobile,
      email,
      company,
      reference,
    }));

    if (name === "") {
      newPrompt[NAME_TRIGGER_ID] = "Required";
      valid = false;
    } else {
      newPrompt[NAME_TRIGGER_ID] = "";
    }

    if (!mobileRegex.test(mobile)) {
      if (mobile === "") {
        newPrompt[MOBILE_TRIGGER_ID] = "Required";
      } else {
        newPrompt[MOBILE_TRIGGER_ID] = "Invalid Mobile";
      }
      valid = false;
    } else {
      newPrompt[MOBILE_TRIGGER_ID] = "";
    }

    if (!emailRegex.test(email)) {
      if (email === "") {
        newPrompt[EMAIL_TRIGGER_ID] = "Required";
      } else {
        newPrompt[EMAIL_TRIGGER_ID] = "Invalid Email";
      }
      valid = false;
    } else {
      newPrompt[EMAIL_TRIGGER_ID] = "";
    }

    setPrompt(newPrompt);

    if (valid) {
      setSendState("sending")
      await joinRequest.mutate({
        role: userFormData.role,
        name,
        mobile,
        email,
        company,
        reference,
      })
    }
  };

  useEffect(()=>{
    console.log('name', name)
  },[name])
  useEffect(()=>{
    console.log('mobile', mobile)
  },[mobile])
  useEffect(()=>{
    console.log('email', email)
  },[email])
  useEffect(()=>{
    console.log('company', company)
  },[company])
  useEffect(()=>{
    console.log('reference', reference)
  },[reference])
  useEffect(()=>{
    console.log('userFormData', userFormData)
  },[userFormData])

  return {
    NAME_TRIGGER_ID,
    MOBILE_TRIGGER_ID,
    EMAIL_TRIGGER_ID,
    prompt,
    sendState,
    name,
    setName,
    mobile,
    setMobile,
    email,
    setEmail,
    company,
    setCompany,
    reference,
    setReference,
    handleSubmit,
  }
}
export default useJoinFormPage
