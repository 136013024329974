import { motion } from "framer-motion";
import styled from "styled-components";
import {
  DoxleFont,
  DoxleTheme,
  DoxleThemeColor,
} from "../../../../DoxleGeneralStore/useDoxleThemeStore";
import { editRgbaAlpha } from "../../../../Utilities/FunctionUtilities";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Slider from "@mui/material/Slider";
import Paper, { PaperProps } from "@mui/material/Paper";

export const StyledWatcherAndAssigneeSection = styled(motion.div)`
  width: 100%;
  display: flex;
  margin-bottom: 3rem;

  .assignee-field-container {
    width: 50%;
    display: flex;
    flex-direction: column;
    max-width: 38rem;
    min-width: 32rem;
  }
  .watcher-field-container {
    flex: 1;
    display: flex;
    flex-direction: column;

    .watcher-tag-container {
      flex-basis: 50%;
      max-width: 70%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }
  }
`;
export const StyledEditGeneralFieldLabel = styled.span<{
  $doxleFont: DoxleFont;
  $themeColor: DoxleThemeColor;
}>`
  color: ${(p) => p.$themeColor.primaryFontColor};
  font-family: ${(p) => p.$doxleFont.secondaryFont};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 4px;
  text-transform: capitalize;
  margin-left: 4px;
`;
export const StyledAssigneeSelectContainer = styled.div``;

export const StyledContactOptionItem = styled.li<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
  $selected: boolean;
}>`
  height: 3rem;
  display: flex;
  align-items: center;
  font-family: ${(p) => p.$doxleFont.primaryFont};
  font-weight: 500;
  font-size: 1.2rem;
  line-height: normal;

  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  transition: 0.4s;
  color: ${(p) =>
    p.$selected ? p.$themeColor.doxleColor : p.$themeColor.primaryFontColor};
  &:hover {
    background-color: ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.$themeColor.doxleColor,
        alpha: "0.4",
      })} !important;
    color: white;
    transition: 0.4s;
  }
`;

interface ICustomTextFieldProps extends TextFieldProps<"standard"> {
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}
export const StyledContactSearchTextField = styled(
  ({ $themeColor, $doxleFont, ...rest }: ICustomTextFieldProps) => (
    <TextField {...rest} />
  )
)`
  && {
    width: calc(100%) !important;
    border-bottom: none !important;
    overflow-y: auto;
    background-color: ${(p) => p.$themeColor.primaryTextFieldBackgroundColor};
  }
  input {
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-weight: 500;
    font-size: max(1.4rem, 14px);
    line-height: normal;

    color: ${(p) => p.$themeColor.primaryFontColor};
    padding-left: 0.8rem !important;
    padding-right: 0.8rem !important;
    border-bottom: none !important;
    &::placeholder {
      font-family: ${(p) => p.$doxleFont.primaryTitleFont};
      font-size: max(1.4rem, 14px);
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      color: ${(p) => p.$themeColor.primaryInputPlaceholderColor} !important;
      opacity: 1 !important;
    }
  }
  & .MuiInput-root:after {
    border-bottom: none !important;
  }
  & .MuiInput-root:before {
    border-bottom: none !important;
  }
  & .MuiInput-root {
    border-bottom: none !important;
  }
  & .MuiInput-root:hover {
    border-bottom: none !important;
  }
  & .MuiInput-root:hover:not(.Mui-disabled, .Mui-error):before {
    border-bottom: none;
  }
  & .MuiInputLabel-root.Mui-focused {
    display: none;
  }
  & .MuiInputLabel-root {
    display: none;
  }
  & .MuiInputBase-root-MuiInput-root:after {
    border-bottom: none !important;
  }
`;

export const StyledDocketDatePickerSection = styled(motion.div)`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 3rem;
  .start-date-container {
    width: 50%;
    display: flex;
    flex-direction: column;
    max-width: 38rem;
    min-width: 32rem;
  }
  .end-date-container {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
`;
export const StyledCustomDatePicker = styled(motion.div)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
  $isNull: boolean;
}>`
  border-radius: 3px;

  cursor: pointer;
  min-width: 144px;
  justify-content: space-between;
  span {
    font-size: 14px;
    font-family: ${(p) => p.$doxleFont.primaryFont};
    color: ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.$themeColor.primaryFontColor,
        alpha: p.$isNull ? "0.4" : "1",
      })} !important;
    font-weight: 400;
  }

  align-items: center;
  flex-direction: row;
  align-self: flex-start;
  display: flex;
  height: 36px;
  max-width: calc(100% - 14px - 3px);
  width: fit-content;
  padding: 0px 7px;
  border: 1.5px solid ${(p) => p.theme.color.primaryDividerColor};

  .icon-dropdown {
    margin-left: 4px;
    transform: translateY(0) rotate(0);
    transition: transform 0.3s ease; /* Smooth transition for hover effect */
  }

  &:hover .icon-dropdown {
    animation: hoverAnimation 0.3s ease forwards;
  }

  @keyframes hoverAnimation {
    0%,
    100% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-3px);
    }
  }
`;
export const StyledEditDocketCommentInputSectionContainer = styled(
  motion.div
)<{}>`
  width: calc(100% - 30px);
  display: flex;

  align-items: center;

  border-radius: 8px;
  background-color: ${(p) =>
    editRgbaAlpha({ rgbaColor: p.theme.color.doxleColor, alpha: "0.05" })};
  border: 1px solid ${(p) => p.theme.color.primaryDividerColor};
  padding: 14px;

  .input-wrapper {
    flex: 1;
    display: flex;

    align-items: center;
    min-height: 20px;
    align-items: center;
    max-height: 120px;
    overflow: hidden;
  }
`;
export const StyledDiscussionListScroller = styled.div`
  width: 100%;
  height: 100%;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;
export const StyledDiscussionItemContainer = styled(motion.div)<{
  $horizontalAlign: "flex-start" | "flex-end";

  $hasAttachment?: boolean;
}>`
  max-width: calc(100% - 48px);
  display: flex;
  width: fit-content;
  min-width: 40%;
  flex-direction: column;
  align-items: ${(p) => p.$horizontalAlign};
  align-self: flex-start;
  background-color: ${(p) =>
    p.$hasAttachment
      ? editRgbaAlpha({
          rgbaColor: p.theme.color.doxleColor,
          alpha: p.theme.currentTheme !== "dark" ? "0.05" : "0.2",
        })
      : p.theme.color.primaryTextFieldBackgroundColor};
  border-radius: 11px;
  padding: 12px 24px;
  overflow-wrap: break-word;
  whitespace: pre-wrap;
`;
export const StyledCommentAuthorInfoContainer = styled.div<{
  $hasAttachment?: boolean;
}>`
  max-width: calc(100% - 30px);
  display: flex;
  width: fit-content;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.8rem;
  align-self: flex-start;
  border-bottom: 1px solid
    ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.$hasAttachment
          ? p.theme.color.doxleColor
          : p.theme.color.primaryFontColor,
        alpha: "0.1",
      })};
  padding: 1px 0px;
  padding-right: 30px;
`;
export const StyledCommentAuthorText = styled.span<{
  $isAuthor: boolean;
  $hasAttachment?: boolean;
}>`
  color: ${(p) =>
    editRgbaAlpha({
      rgbaColor: p.$hasAttachment
        ? p.theme.color.doxleColor
        : p.theme.color.primaryFontColor,
      alpha: p.theme.currentTheme !== "dark" ? "0.4" : "0.55",
    })};
  font-size: 10px;
  font-family: ${(p) => p.theme.font.primaryTitleFont};
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  text-transform: uppercase;
`;
export const StyledDiscussionTimestampText = styled.div<{
  $hasAttachment?: boolean;
}>`
  color: ${(p) =>
    editRgbaAlpha({
      rgbaColor: p.$hasAttachment
        ? p.theme.color.doxleColor
        : p.theme.color.primaryFontColor,
      alpha: p.theme.currentTheme !== "dark" ? "0.3" : "0.45",
    })};
  font-size: max(1rem, 10px);
  font-family: ${(p) => p.theme.font.robotoMonoFont};
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 4px;
`;
export const StyledCommentContentText = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  color: ${(p) => p.$themeColor.primaryFontColor};
  font-size: max(1.4rem, 14px);
  font-family: ${(p) => p.$doxleFont.primaryFont};
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.42px;
  white-space: pre-line;
  padding: 0px;
  max-width: 100%;
`;
export const StyledDiscussionAttachmentWrapper = styled.div<{}>`
  width: 8rem;
  aspect-ratio: 1;
  display: flex;
  border-radius: 4px;
  margin: 2px;
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease;
  border: 1px solid ${(p) => p.theme.color.primaryDividerColor};
  &:hover {
    cursor: pointer;
    border-color: ${(p) => p.theme.color.doxleColor};
  }
`;
export const StyledCommentAttachmentImage = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

export const StyledVideoThumbWrapper = styled.div`
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  display: flex;
  border: 1px solid ${(p) => p.theme.color.primaryDividerColor};
  align-items: center;
  justify-content: center;
  position: relative;

  .icon-play {
    position: absolute;
    z-index: 10;
    color: ${(p) => p.theme.color.doxleColor};
  }
`;
export const StyledDiscussionRowWrapper = styled(motion.div)`
  width: 100%;
  padding-bottom: 3rem;
`;
export const StyledFilePickerDisplayerContainer = styled(motion.div)`
  width: calc(100% - 0px);
  margin: 8px 0px;
  padding: 14px 0px;
  display: flex;

  flex-direction: row;
  flex-wrap: wrap;
  height: 240px;

  overflow: auto;
  align-items: center;
  // &::-webkit-scrollbar {
  //   display: none;
  // }
  // -ms-overflow-style: none;
  // scrollbar-width: none;
`;
export const StyledFileItemContainer = styled(motion.div)`
  height: 140px;
  aspect-ratio: 1;
  border-radius: 4px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 8px;
`;
export const StyledFileImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 4px;
`;
export const StyledDocketNameSection = styled(motion.div)`
  width: 100%;
  display: flex;
  margin-bottom: 3rem;
  flex-direction: column;
`;
export const StyledDocketNameTextField = styled(
  ({ $themeColor, $doxleFont, ...rest }: ICustomTextFieldProps) => (
    <TextField {...rest} />
  )
)`
  && {
    width: calc(100% - 14px - 3px);
    min-width: 40%;
    align-self: flex-start;
    padding: 7px;
    border-radius: 3px;
    border: 1.5px solid ${(p) => p.theme.color.primaryDividerColor};
  }
  input {
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-weight: 400;
    font-size: max(1.4rem, 14px);
    line-height: normal;
    color: ${(p) => p.$themeColor.primaryFontColor};
    padding: 0px !important;
    margin-left: 2px;
    &::placeholder {
      font-family: ${(p) => p.$doxleFont.primaryFont};
      font-weight: 400;
      font-size: max(1.4rem, 14px);
      line-height: normal;
      color: ${(p) => p.$themeColor.primaryInputPlaceholderColor};
      padding: 0px !important;
      opacity: 1;
    }
  }
  textarea {
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-weight: 400;
    font-size: max(1.4rem, 14px);
    line-height: normal;

    color: ${(p) => p.$themeColor.primaryFontColor};
    padding: 0px !important;
    &::placeholder {
      font-family: ${(p) => p.$doxleFont.primaryFont};
      font-weight: 400;
      font-size: max(1.4rem, 14px);
      line-height: normal;
      color: ${(p) => p.$themeColor.primaryInputPlaceholderColor};
      padding: 0px !important;
      opacity: 1;
    }
  }
  & .MuiInput-root:after {
    border-bottom: none !important;
  }
  & .MuiInput-root:before {
    border-bottom: none !important;
  }
  & .MuiInput-root {
    border-bottom: none !important;
  }
  & .MuiInput-root:hover:not(.Mui-disabled, .Mui-error):before {
    border-bottom: none !important;
  }
  & .MuiInputBase-root {
    width: 100%;
  }
`;

export const StyledDocketInfoHeader = styled.div`
  width: calc(100% - 100px);
  display: flex;
  flex-direction: column;
  padding: 30px 50px;
  background-color: ${(p) => p.theme.color.primaryContainerColor};
  .row-field-wrapper {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
`;

export const StyledDocketInfoEditFieldWrapper = styled(motion.div)`
  width: 40%;
  min-width: 180px;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  margin-right: 5rem;

  .watcher-tag-container {
    max-width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .date-picker-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;

export const StyledArchivePinSection = styled(motion.div)`
  display: flex;
  align-items: center;
  height: 100%;
`;

export const StyledCheckboxLabel = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  font-family: ${(p) => p.$doxleFont.primaryFont};
  font-weight: 400;
  font-size: max(1.4rem, 14px);
  line-height: normal;
  color: ${(p) => p.$themeColor.primaryFontColor};
  display: flex;
  align-items: center;
`;

export const StyledArchivePinToggleBtn = styled(motion(Button))<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  && {
    min-width: 0px !important;
    height: 100% !important;
    width: 41px !important;
    padding: 0 2px !important;
    border-radius: 3px !important;

    display: flex;
    align-items: center;

    text-transform: capitalize;
    background-color: ${(p) => "transparent"};
    transition: all 0.4s ease;
  }
  &:hover {
    background-color: ${(p) => p.$themeColor.primaryFontColor} !important;
    color: ${(p) => p.$themeColor.primaryReverseFontColor} !important;
    transition: all 0.4s ease;
  }
`;
export const StyledAssigneeAndWatcherPicker = styled(motion.div)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  border-radius: 3px;

  cursor: pointer;
  min-width: 144px;
  justify-content: space-between;
  font-size: 14px;
  font-family: ${(p) => p.$doxleFont.primaryFont};
  color: ${(p) =>
    editRgbaAlpha({
      rgbaColor: p.$themeColor.primaryFontColor,
      alpha: "0.4",
    })} !important;
  font-weight: 400;

  align-items: center;

  align-self: flex-start;
  display: flex;
  height: 36px;
  max-width: calc(100% - 14px - 2px);
  width: fit-content;
  padding: 0px 7px;
  border: 1.5px solid ${(p) => p.theme.color.primaryDividerColor};
  .icon-dropdown {
    margin-left: 4px;
    transform: translateY(0) rotate(0);
    transition: transform 0.3s ease; /* Smooth transition for hover effect */
  }

  &:hover .icon-dropdown {
    animation: hoverAnimation 0.3s ease forwards;
  }

  @keyframes hoverAnimation {
    0%,
    100% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-3px);
    }
  }
`;
export const StyledCommentAttachmentDropzone = styled(motion.div)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  height: 140px;
  aspect-ratio: 1;
  border-radius: 4px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 8px;
  border: 1px dashed ${(p) => p.$themeColor.primaryDividerColor};
  text-align: center;
  font-size: 14px;
  font-family: ${(p) => p.$doxleFont.primaryFont};
  color: ${(p) =>
    editRgbaAlpha({
      rgbaColor: p.$themeColor.primaryFontColor,
      alpha: "0.4",
    })} !important;
  font-weight: 400;
  cursor: pointer;
`;

export const StyledEditFunctionFieldLabel = styled.span`
  color: ${(p) => p.theme.color.primaryFontColor};
  font-family: ${(p) => p.theme.font.secondaryFont};
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 8px;
  text-transform: capitalize;
  margin-left: 4px;
`;
export const StyledDocketInfoFooter = styled.div`
  width: calc(100% - 100px);
  display: flex;
  flex-direction: column;
  padding: 50px;

  .row-field-wrapper {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
`;
export const StyledStatusAndPercentageSectionWrapper = styled.div`
  width: 50%;
  min-width: 220px;
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
  .slider-wrapper {
    padding: 14px 0px;
    border-radius: 4px;
    display: flex;
    align-self: flex-start;
    align-items: center;
    text-transform: capitalize;
    cursor: pointer;
    min-width: 300px;
    max-width: 600px;
    width: 100%;
    position: relative;
  }
`;

export const StyledDocketSlider = styled(Slider)`
  && {
  }
  &.MuiSlider-root {
    color: ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.theme.color.doxleColor,
        alpha: "0.7",
      })};
    height: 20px;
    padding: 0px;
    border-radius: 8px;
  }
  & .MuiSlider-rail {
    width: 100%;
  }
  & .MuiSlider-thumb {
    background-color: ${(p) => p.theme.color.primaryContainerColor};
    width: 30px;
    height: 30px;
    border-radius: 50%;
    // transform: translate(0px, -50%);
  }
  & .MuiSlider-thumb.Mui-focusVisible {
    box-shadow: 0px 0px 0px 0.8rem
      ${(p) =>
        editRgbaAlpha({ rgbaColor: p.theme.color.doxleColor, alpha: "0.15" })};
  }
  & .MuiSlider-track {
    height: 0px;
    background-color: transparent !important;
    display: none !important;
  }

  & .MuiSlider-mark {
    display: none !important;
  }
`;
export const StyledDocketPercentageText = styled.span<{
  $percentageValue: number;
}>`
  color: ${(p) => p.theme.color.doxleColor};
  font-family: ${(p) => p.theme.font.secondaryFont};
  font-size: 14px;
  font-style: normal;
  font-weight: 600;

  text-transform: capitalize;

  z-index: 10;
  position: absolute;
  opacity: ${(p) => (p.$percentageValue === 0 ? 0 : 1)};
  left: calc(
    ${(p) => p.$percentageValue}% - max(12.5%, 50px)
  ); /* Initial position - change as needed */
  transition: left 0.2s ease;
`;
export const StyledToggleFunctionalMenuSection = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
`;

export const StyledLinkDocketSection = styled(motion.div)`
  width: 100%;
  display: flex;
  align-items: center;

  .link-field-wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }

  @media (max-width: 700px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const StyledLinkedFieldDisplay = styled(motion.div)`
  display: flex;
  align-items: center;
  padding: 12px;
  border: 2px solid ${(p) => p.theme.color.primaryDividerColor};
  border-radius: 4px;
  justify-content: space-between;
  min-width: 200px;
  max-width: 500px;
  width: fit-content;
  color: ${(p) => p.theme.color.primaryFontColor};
  font-family: ${(p) => p.theme.font.primaryFont};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  .icon-dropdown {
    margin-left: 4px;
    transform: translateY(0) rotate(0);
    transition: transform 0.3s ease; /* Smooth transition for hover effect */
  }

  &:hover .icon-dropdown {
    animation: hoverAnimation 0.3s ease forwards;
  }

  @keyframes hoverAnimation {
    0%,
    100% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-3px);
    }
  }
`;
export const StyledLinkButton = styled(Button)<{
  $isLink: boolean;
}>`
  && {
    min-width: 80px;
    display: flex;
    font-family: ${(p) => p.theme.font.secondaryFont};
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    flex-direction: column;
    align-items: center;
    color: ${(p) => (p.$isLink ? p.theme.color.doxleColor : "rgba(255,0,0,1)")};
    margin: 8px 16px 0px 16px;
    padding: 4px !important;

    @media (max-width: 700px) {
      margin-bottom: 30px;
    }
  }

  &.MuiButtonBase-root {
  }
  &.MuiButton-root:hover {
    background: ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.$isLink ? p.theme.color.doxleColor : "rgba(255,0,0,1)",
        alpha: "0.2",
      })};
    transition: 0.4s;
  }
`;
export const StyledAccountDropDownWrapper = styled.div`
  border-radius: 8px;
  background-color: ${(p) => p.theme.color.primaryContainerColor};
  width: 280px;
  height: 300px;
  padding: 14px 0px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px -2px 8px 2px ${(p) => p.theme.color.primaryBoxShadowColor};
  overflow: hidden;
  border: 1px solid ${(p) => p.theme.color.primaryDividerColor};
`;
export const StyledAccountListScroller = styled.div`
  width: 100%;
  height: 100%;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;
export const StyledAccountItemWrapper = styled.div`
  padding: 7px 14px;
  font-size: 13px;
  width: calc(100% - 28px);
  font-family: ${(p) => p.theme.font.primaryFont};
  font-weight: 400;
  color: ${(p) => p.theme.color.primaryFontColor};
  border-bottom: 1px solid ${(p) => p.theme.color.primaryDividerColor};
  &:hover {
    background-color: ${(p) => p.theme.color.primaryHoverColor};
  }
  cursor: pointer;
`;
const AnimatedPaper = motion(Paper);
const containerVariants = {
  entering: {
    y: [-10, 0],
    opacity: [0, 1],
    transition: {
      duration: 0.4,
    },
  },
  exiting: {
    y: [0, -10],
    opacity: [1, 0],
    transition: {
      duration: 0.4,
    },
  },
};
export const StyledCommentAttachmentContainer = styled(
  ({
    ...rest
  }: Omit<
    PaperProps,
    "onDragStart" | "onAnimationStart" | "onDragEnd" | "onDrag"
  >) => (
    <AnimatedPaper
      {...rest}
      variants={containerVariants}
      animate="entering"
      exit="exiting"
      initial="initial"
      layout="position"
      elevation={1}
    />
  )
)`
  && {
    width: 200px;
    max-height: max(20rem, 200px);
    border-radius: 8px;
    position: relative;
    padding: 10px;

    display: flex;
    background-color: ${(p) => p.theme.color.primaryContainerColor} !important;
    border: 1px solid ${(p) => p.theme.color.primaryDividerColor};
    flex-direction: column;
  }
`;

export const StyledAttachmentPickerBtn = styled(Button)`
  && {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    text-transform: capitalize;
    padding: 7px 14px;
    font-size: 16px;
    width: calc(100%);
    font-family: ${(p) => p.theme.font.primaryFont};
    font-weight: 500;
    color: ${(p) => p.theme.color.primaryFontColor};
    border-bottom: 1px solid ${(p) => p.theme.color.primaryDividerColor};
    transition: all 0.3s ease;
    &:hover {
      background-color: ${(p) => p.theme.color.primaryHoverColor};
    }
    cursor: pointer;
    border-radius: 0px !important;

    .icon {
      margin-right: 10px;
    }
  }
`;
