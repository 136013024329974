import { useQuery } from "@tanstack/react-query";
import { useDoxleCurrentContextStore } from "../../DoxleGeneralStore/useDoxleCurrentContext";
import { shallow } from "zustand/shallow";
import useDoxleNotificationStore from "../../DoxleGeneralStore/useDoxleNotificationStore";
import DoxleAPI from "../../Services/DoxleAPI";
import { Account, ProjectTrackingOption } from "../Models/account";

const useRetrieveAccounts = () => {
  const company = useDoxleCurrentContextStore(
    (state) => state.currentCompany,
    shallow
  );
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );
  return useQuery(
    ["account-list", company?.companyId ?? ""],
    () =>
      DoxleAPI.get<Account[]>("/accounts/", {
        headers: { "User-Company": company?.companyId },
      }),
    {
      enabled: Boolean(company),
      cacheTime: 10 * 60 * 1000,
      staleTime: 3 * 60 * 1000,
      refetchInterval: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      retryOnMount: false,

      retry: 1,
      onError: (err: any) => {
        // if (showNotification)
        //   showNotification(
        //     `${err?.response?.status ?? "ERROR"}: ${
        //       err?.response?.statusText ?? "Unkown Error"
        //     }`,
        //     "error",
        //     err?.response?.data ?? "Error Fetching Accounts List"
        //   );
      },
    }
  );
};

const useRetrieveTrackingOptions = () => {
  const company = useDoxleCurrentContextStore(
    (state) => state.currentCompany,
    shallow
  );
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );
  return useQuery(
    ["trackingOptions", company?.companyId ?? ""],
    () =>
      DoxleAPI.get<ProjectTrackingOption[]>("/accounts/tracking/", {
        headers: { "User-Company": company?.companyId },
      }),
    {
      cacheTime: 10 * 60 * 1000,
      staleTime: 3 * 60 * 1000,
      refetchInterval: false,
      refetchOnWindowFocus: false,
      retry: 1,
      onError: (err: any) => {
        if (showNotification)
          showNotification(
            `${err?.response?.status ?? "ERROR"}: ${
              err?.response?.statusText ?? "Unkown Error"
            }`,
            "error",
            err?.response?.data ?? "Error Fetching Tracking Option List"
          );
      },
    }
  );
};

const AccountMappingQueryAPI = {
  useRetrieveAccounts,
  useRetrieveTrackingOptions,
};

export default AccountMappingQueryAPI;
