import { useShareProjectDialogContext } from "./ShareProjectDialog";
import Popover from "@mui/material/Popover";

import {
  StyledEditPermissionForm,
  StyledFormWrapper,
} from "./StyledShareProjectDialog";
import useEditPermissionPopoverForm, {
  Toggle_Permission_Fields,
} from "./Hooks/useEditPermissionPopoverForm";
import DoxleCheckbox from "../../../DoxleDesignPattern/DoxleCheckbox/DoxleCheckbox";

import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import { CheckboxProps } from "@mui/material/Checkbox";
import { editRgbaAlpha } from "../../../Utilities/FunctionUtilities";
import {
  DoxleCheckedIcon,
  DoxleUncheckedIcon,
} from "../../../DoxleDesignPattern/DoxleGeneralIcons";

type Props = {};

const EditPermissionPopoverForm = (props: Props) => {
  const { doxleThemeColor, staticMenuColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      staticMenuColor: state.staticMenuColor,
      doxleFont: state.doxleFont,
    }),
    shallow
  );
  const {
    editedPermissionItem,

    anchorPopoverForm,
  } = useShareProjectDialogContext();
  const { handleUpdateTogglePermission, isFieldChecked, handleClosePopover } =
    useEditPermissionPopoverForm({});
  const canBeOpen = Boolean(anchorPopoverForm);
  const id = canBeOpen ? "edit-permission-menu-popper" : undefined;
  const checkboxLabelStyle: CheckboxProps["sx"] = {
    width: "calc(50% - 16px) !important",
    marginInline: "8px !important",
    marginBlock: "4px",
    "& .MuiTypography-root": {
      display: "flex",

      flexDirection: "row",
      color: editRgbaAlpha({
        rgbaColor: staticMenuColor.staticFontColor,
        alpha: "0.7",
      }),
      fontWeight: 500,
      padding: "2px 0px",
      fontSize: 13,
      fontFamily: doxleFont.primaryFont,
      cursor: "pointer",
    },
  };
  if (!(editedPermissionItem && anchorPopoverForm)) return null;
  return (
    <Popover
      id={id}
      open={canBeOpen}
      anchorEl={anchorPopoverForm}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      slotProps={{
        paper: {
          sx: {
            bgcolor: "transparent !important",
          },
        },
      }}
      onClose={handleClosePopover}
    >
      <StyledEditPermissionForm>
        <StyledFormWrapper
          style={{ borderBottom: `1px solid ${staticMenuColor.staticDivider}` }}
        >
          <span className="form-title">can edit</span>
          {Toggle_Permission_Fields.map((field, idx) => (
            <DoxleCheckbox
              key={`edit${field}#${idx}`}
              checked={isFieldChecked(field, "edit")}
              onChangeCallback={(e) =>
                handleUpdateTogglePermission(field, e.target.checked, "edit")
              }
              icon={
                <DoxleUncheckedIcon
                  themeColor={doxleThemeColor}
                  containerStyle={{
                    width: 12,
                  }}
                />
              }
              checkedIcon={
                <DoxleCheckedIcon
                  themeColor={doxleThemeColor}
                  containerStyle={{
                    width: 12,
                  }}
                />
              }
              labelText={field}
              labelStyle={checkboxLabelStyle}
            />
          ))}
        </StyledFormWrapper>

        <StyledFormWrapper>
          <span className="form-title">can view</span>
          {Toggle_Permission_Fields.map((field, idx) => (
            <DoxleCheckbox
              alternateIcon={true}
              key={`view${field}#${idx}`}
              checked={isFieldChecked(field, "view")}
              onChangeCallback={(e) =>
                handleUpdateTogglePermission(field, e.target.checked, "view")
              }
              icon={
                <DoxleUncheckedIcon
                  themeColor={doxleThemeColor}
                  containerStyle={{
                    width: 12,
                  }}
                />
              }
              checkedIcon={
                <DoxleCheckedIcon
                  themeColor={doxleThemeColor}
                  containerStyle={{
                    width: 12,
                  }}
                />
              }
              labelText={field}
              labelStyle={checkboxLabelStyle}
            />
          ))}
        </StyledFormWrapper>
      </StyledEditPermissionForm>
    </Popover>
  );
};

export default EditPermissionPopoverForm;
