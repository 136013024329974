import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import { Variants, motion } from "framer-motion";
import styled from "styled-components";
import { editRgbaAlpha } from "../../../Utilities/FunctionUtilities";
import Paper, { PaperProps } from "@mui/material/Paper";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Slider from "@mui/material/Slider";

export const StyledEditBookingDialog = styled(Dialog)<{}>`
  && {
  }
  & .MuiDialog-paper {
    background-color: ${(p) => p.theme.color.primaryContainerColor};
    box-shadow: 0px 0px 14px 0px ${(p) => p.theme.color.primaryBoxShadowColor};
    min-width: 300px;
    max-width: 600px;

    border-radius: 12px;
    border: 1px solid ${(p) => p.theme.color.primaryDividerColor};
    elevation: 2;
    padding: 14px 20px;
    width: 80vw;
    position: relative;
  }
`;

export const StyledEditBookingDialogTitle = styled(DialogTitle)<{}>`
  && {
    background-color: ${(p) => p.theme.color.primaryContainerColor};
    padding: 0px !important;

    width: 100%;
    display: flex;
    align-items: center;
  }
  .title-wrapper {
    font-family: ${(p) => p.theme.font.primaryFont};
    font-style: normal;
    font-weight: 600;
    font-size: 20px;

    color: ${(p) => p.theme.color.primaryFontColor};
    display: inline-block;
    white-space: normal;
    word-wrap: break-word;
    flex: 1;
    display: flex;
    align-items: center;

    .icon {
      margin-right: 4px;
      margin-bottom: -2px;
    }
  }
  .menu-wrapper {
    display: flex;
    align-items: center;
  }
`;
export const StyledEditBookingDialogContent = styled(motion(DialogContent))<{}>`
  && {
    background-color: ${(p) => p.theme.color.primaryContainerColor};
    padding: 30px 0px 0px 0px !important;
    display: flex;
    flex-direction: column;
    overflow: auto;
    max-height: 800px;
  }
`;

export const StyledEditBookingTitleTextField = styled(TextField)`
  && {
    flex: 1;
    padding: 8px 0px !important;
    display: flex;
    align-items: center;
    flex-direction: row;
    border-bottom: 1px solid ${(p) => p.theme.color.primaryDividerColor};
  }

  & .MuiInputBase-multiline {
    color: ${(p) => p.theme.color.primaryFontColor};
    font-size: 16px;
    font-family: ${(p) => p.theme.font.primaryTitleFont};
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.65px;
    padding: 0px !important;
    width: 100%;

    textarea {
      &::placeholder {
        color: ${(p) =>
          editRgbaAlpha({
            rgbaColor: p.theme.color.primaryFontColor,
            alpha: "0.2",
          })};
        font-size: 16px;
        font-family: ${(p) => p.theme.font.primaryFont};
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.65px;
        padding: 0px !important;
        opacity: 1 !important;
      }
    }
  }
  & .MuiOutlinedInput-root {
    padding: 0px !important;
  }
  & .MuiOutlinedInput-input {
    padding: 0px !important;
  }
  & .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }
  &.MuiInputBase-root-MuiInput-root:before {
    border: none !important;
  }
`;

export const StyledEditBookingContentWrapper = styled(motion.div)`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;

  .error-text {
    color: ${(p) => p.theme.color.errorColor};
    font-size: 14px;
    font-family: ${(p) => p.theme.font.primaryFont};
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.65px;
    padding: 0px !important;
    margin-top: 4px;
  }

  .content-text {
    color: ${(p) => p.theme.color.primaryFontColor};
    font-size: 16px;
    font-family: ${(p) => p.theme.font.primaryTitleFont};
    font-style: normal;
    font-weight: 500;
    flex: 1;
    white-space: normal;
    word-wrap: break-word;
    display: inline-block;
  }

  .label-text {
    color: ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.theme.color.primaryFontColor,
        alpha: "0.4",
      })};
    font-size: 14px;
    font-family: ${(p) => p.theme.font.primaryFont};
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.65px;
    padding: 0px !important;
    margin-bottom: 4px;
  }
`;

export const StyledEditDocketAndDateWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  align-items: stretch;

  .wrapper {
    width: calc(48% - 22px);

    display: flex;
    flex-direction: column;
    .nested-wrapper {
      display: flex;
      align-items: center;
      margin-bottom: 0px;
      flex-direction: row;
      padding: 5px 10px;
      cursor: pointer;
      transition: border-color 0.3s ease;
      border-radius: 8px;
      border: 1px solid ${(p) => p.theme.color.primaryDividerColor};
      &:hover {
        border-color: ${(p) => p.theme.color.doxleColor};
      }
    }
  }

  .date-picker > div {
    width: 100%;

    display: flex;
  }
`;

export const StyledDocketPopover = styled(motion(Paper))<{ $height: number }>`
  && {
    background-color: ${(p) => p.theme.color.primaryContainerColor};
    width: 300px;
    min-height: 140px;
    ${(p) => p.$height && `height:${p.$height}px;`}

    position: relative;
    padding: 10px 4px;
    display: flex;
    flex-direction: column;
  }
`;

export const StyledSearchDocketTextField = styled(TextField)`
  && {
    width: calc(100%);
    padding-bottom: 0.8rem;
    border-bottom: 1px solid ${(p) => p.theme.color.primaryDividerColor};

    &:hover {
      border-bottom: 1px solid ${(p) => p.theme.color.primaryDividerColor} !important;
    }
  }
  input {
    color: ${(p) => p.theme.color.primaryFontColor};
    font-size: 14px;
    font-family: ${(p) => p.theme.font.primaryTitleFont};
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.65px;
    padding: 0px !important;
    &::placeholder {
      color: ${(p) => p.theme.color.primaryFontColor};
      font-size: 14px;
      font-family: ${(p) => p.theme.font.primaryFont};
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.65px;
      padding: 0px !important;
    }
  }
  & .MuiOutlinedInput-root {
    padding: 0px 0.8rem !important;
  }
  & .MuiOutlinedInput-input {
    padding: 0px !important;
  }
  & .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }

  & .MuiInput-root:before {
    border: none !important;
  }

  & .MuiInput-root:after {
    border: none !important;
  }
`;
export const StyledBookingDocketWrapper = styled(motion.div)<{}>`
  width: calc(100% - 1.6rem);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px;
  height: 20px;
  border-bottom: 1px solid ${(p) => p.theme.color.primaryDividerColor};
  cursor: pointer;
  transition: 0.4s;
  &:hover {
    transition: 0.4s;
    background-color: ${(p) => p.theme.color.rowHoverColor};
  }
`;
export const StyledDatePickerWrapper = styled.div`
  width: calc(48%);
  display: flex;
  flex-direction: column;

  .label-text {
    color: ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.theme.color.primaryFontColor,
        alpha: "0.4",
      })};
    font-size: 14px;
    font-family: ${(p) => p.theme.font.primaryFont};
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.65px;
    padding: 0px !important;
    margin-bottom: 4px;
  }
`;

export const StyledEditBookingDialogActions = styled(motion(DialogActions))`
  && {
    background-color: ${(p) => p.theme.color.primaryContainerColor};
    padding: 0px !important;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
  }
  .slider-wrapper {
    padding-right: 20px;
    border-radius: 4px;
    display: flex;

    align-items: center;
    text-transform: capitalize;
    cursor: pointer;
    max-width: 600px;
    flex: 1;
    position: relative;
  }
`;
export const StyledEditBookingDialogButton = styled(motion(Button))<{
  $type: "cancle" | "submit";
}>`
  && {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0px 4px;
    flex-shrink: 0 !important;
    border-radius: 4px !important;
    padding: 8px 16px !important;
    text-transform: capitalize;
    background-color: ${(p) =>
      p.$type === "cancle"
        ? p.theme.color.primaryContainerColor
        : p.theme.color.primaryFontColor};
    &:hover {
      background-color: ${(p) =>
        p.$type === "cancle"
          ? p.theme.color.primaryContainerColor
          : p.theme.color.primaryFontColor} !important;
      opacity: 0.9;
      transform: translateY(-1px);
      transition: all 0.4s ease;
    }
    border: 1px solid ${(p) => p.theme.color.primaryDividerColor};
    font-family: ${(p) => p.theme.font.primaryFont};
    font-style: normal;
    font-weight: 600;
    font-size: 16px;

    line-height: 16px;
    letter-spacing: 0.05em;
    color: ${(p) =>
      p.$type === "cancle"
        ? p.theme.color.primaryFontColor
        : p.theme.color.primaryContainerColor};
  }
`;
export const StyledBookingPercentSlider = styled(Slider)`
  && {
  }
  &.MuiSlider-root {
    color: ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.theme.color.primaryFontColor,
        alpha: "0.2",
      })};
    height: 4px;
    padding: 0px;
    border-radius: 4px;
    width: 100%;
    min-width: 0px !important;
  }
  & .MuiSlider-rail {
    width: 100%;
  }
  & .MuiSlider-thumb {
    background-color: ${(p) => p.theme.color.doxleColor};
    width: 20px;
    height: 20px;
    border-radius: 50%;
  }
  & .MuiSlider-thumb.Mui-focusVisible {
    box-shadow: 0px 0px 0px 8px
      ${(p) =>
        editRgbaAlpha({ rgbaColor: p.theme.color.doxleColor, alpha: "0.15" })};
  }
  & .MuiSlider-track {
    height: 0px;
    background-color: transparent !important;
    display: none !important;
  }

  & .MuiSlider-mark {
    display: none !important;
  }
`;

export const StyledBookingPercentageText = styled.span<{
  $percentageValue: number;
}>`
  color: ${(p) => p.theme.color.doxleColor};
  font-family: ${(p) => p.theme.font.secondaryFont};
  font-size: 16px;
  font-style: normal;
  font-weight: 600;

  text-transform: capitalize;

  z-index: 10;
  position: absolute;
  opacity: ${(p) => (p.$percentageValue === 0 ? 0 : 1)};
  left: calc(
    ${(p) => p.$percentageValue}% - max(12.5%, 70px)
  ); /* Initial position - change as needed */
  transition: left 0.2s ease;
`;

export const StyledBookingCommentBtn = styled(Button)`
  && {
    min-width: 0px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    padding: 0px;
    position: relative;
  }

  .comment-text {
    position: absolute;
    top: 0px;
    right: -2px;
    transform: translateY(-30%);
    font-family: ${(p) => p.theme.font.primaryFont};
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: normal;
    letter-spacing: 0.65px;
    color: ${(p) => p.theme.color.primaryFontColor};
  }
`;
