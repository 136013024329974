import React from "react";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import {
  StyledAcceptOrderContentWrapper,
  StyledAcceptOrderDialog,
  StyledAcceptOrderTitle,
  StyledSignatureButton,
} from "./StyledComponentEditOrder";
import { useEditOrderStore } from "../../OrdersStore/useEditOrderStore";
import { editRgbaAlpha } from "../../../Utilities/FunctionUtilities";
import { TransitionProps } from "@mui/material/transitions";
import Zoom from "@mui/material/Zoom";
import useGetUserInfo from "../../../CoreContent/QueryDataHooks/GetQueryDataHooks/useGetUserInfo";
import CircularProgress from "@mui/material/CircularProgress";
import ProgressScreen from "../../../Utilities/Lottie/ProgressScreen";
import useConfirmOverBudgetDialog from "./Hooks/useConfirmOverBudgetDialog";
import Order from "../../Models/order";

interface Props {
  updatedOrder: Omit<Partial<Order>, "orderId"> | undefined;
  isOrderLineUpdated: boolean;
}

const ConfirmOverBudgetDialog = ({
  updatedOrder,
  isOrderLineUpdated,
}: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }),
    shallow
  );
  const { editedOrder, showOverBudgetWarning, setShowOverBudgetWarning } =
    useEditOrderStore(
      (state) => ({
        editedOrder: state.editedOrder,
        showOverBudgetWarning: state.showOverBudgetWarning,
        setShowOverBudgetWarning: state.setShowOverBudgetWarning,
      }),
      shallow
    );
  const { handleConfirmOverBudget, isUpdatingOrder } =
    useConfirmOverBudgetDialog({ updatedOrder, isOrderLineUpdated });

  const { user, isFetchingUserInfo } = useGetUserInfo();
  return (
    <StyledAcceptOrderDialog
      $themeColor={doxleThemeColor}
      open={showOverBudgetWarning}
      onClose={() => {
        if (!isUpdatingOrder) setShowOverBudgetWarning(false);
      }}
      layout
      slotProps={{
        backdrop: {
          sx: {
            bgcolor: editRgbaAlpha({
              rgbaColor: doxleThemeColor.primaryBackdropColor,
              alpha: "0.4",
            }),
          },
        },
      }}
      PaperProps={{
        elevation: 8,
      }}
      TransitionComponent={Transition}
      aria-describedby="add-mail-dialog-slide"
    >
      <StyledAcceptOrderTitle
        $themeColor={doxleThemeColor}
        $doxleFont={doxleFont}
      >
        The Docket is over budget.
      </StyledAcceptOrderTitle>

      <StyledAcceptOrderContentWrapper
        $themeColor={doxleThemeColor}
        $doxleFont={doxleFont}
      >
        <div className="order-info-text">
          The Docket is over budget, are you sure you wish to save this change?
        </div>
        <div className="overbudget-button-container">
          <StyledSignatureButton
            $themeColor={doxleThemeColor}
            $doxleFont={doxleFont}
            disabled={isFetchingUserInfo}
            layout
            onClick={() => setShowOverBudgetWarning(false)}
          >
            <span className="btn-text">Cancel</span>
            {isFetchingUserInfo && (
              <CircularProgress
                size={14}
                sx={{
                  color: doxleThemeColor.primaryFontColor,
                  marginLeft: 2,
                }}
              />
            )}
          </StyledSignatureButton>
          <StyledSignatureButton
            $themeColor={doxleThemeColor}
            $doxleFont={doxleFont}
            disabled={isFetchingUserInfo}
            layout
            onClick={() => handleConfirmOverBudget()}
          >
            <span className="btn-text">Confirm</span>
            {isFetchingUserInfo && (
              <CircularProgress
                size={14}
                sx={{
                  color: doxleThemeColor.primaryFontColor,
                  marginLeft: 2,
                }}
              />
            )}
          </StyledSignatureButton>
        </div>
      </StyledAcceptOrderContentWrapper>
      {isUpdatingOrder && (
        <ProgressScreen
          progressType="progress"
          hasBackDrop={true}
          progressText="Processing request, please wait..."
          textStyle={{
            fontSize: "max(2rem,20px)",
            fontFamily: doxleFont.primaryFont,
            color: "white",
          }}
          containerStyle={{
            display: "flex",

            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
          animationSize={120}
        />
      )}
    </StyledAcceptOrderDialog>
  );
};

export default ConfirmOverBudgetDialog;
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Zoom ref={ref} {...props} />;
});
