import { useQueryClient } from "@tanstack/react-query";

import { produce } from "immer";
import {
  FilterGetQAListQuery,
  formQAListQueryKey,
} from "../QueryAPI/qaQueryAPI";
import { QAList } from "../Models/qa";
import { useDoxleCurrentContextStore } from "../../DoxleGeneralStore/useDoxleCurrentContext";
import { shallow } from "zustand/shallow";

type Props = {
  filter: FilterGetQAListQuery;
  addPos?: "start" | "end";
  overwrite?: boolean; //this prop is to choose the set data behaviour, if true will overwrite on the existing cache data, otherwise it will refetch
};
interface SetQAListQueryData {
  handleAddQAList: (addedQAList: QAList) => void;
  handleDeleteQAList: (deletedId: string) => void;
  handleEditQAList: (edittedQAList: QAList) => void;
  handleUpdateQAListSignature: (qaListId: string, newUrl: string) => void;
}
const useSetQAListQueryData = ({
  filter,
  addPos = "start",
  overwrite = true,
}: Props): SetQAListQueryData => {
  const queryClient = useQueryClient();
  const { company } = useDoxleCurrentContextStore(
    (state) => ({
      company: state.currentCompany,
    }),
    shallow
  );
  const qKey = formQAListQueryKey(filter, company);
  const handleAddQAList = (addedQAList: QAList) => {
    const oldServerData = queryClient.getQueryData(qKey);
    if (oldServerData && overwrite) {
      queryClient.setQueryData(qKey, (old: any) => {
        if (old) {
          const lengthOfPages = old.pages.length;
          return produce(old, (draftOld: any) => {
            draftOld.pages = produce(draftOld.pages, (draftPages: any) => {
              if (addPos === "start") {
                (draftPages[0].data.results as QAList[]).unshift({
                  ...addedQAList,
                  isNew: true,
                });
              } else {
                (draftPages[lengthOfPages - 1].data.results as QAList[]).push({
                  ...addedQAList,
                  isNew: true,
                });
              }

              return draftPages;
            });

            return draftOld;
          });
        } else return old;
      });
    } else queryClient.invalidateQueries(qKey);
  };

  const handleDeleteQAList = (deletedId: string) => {
    const oldServerData = queryClient.getQueryData(qKey);
    if (oldServerData && overwrite) {
      queryClient.setQueryData(qKey, (old: any) => {
        if (old) {
          //find page contain deleted item
          let pageContainGroup: number = (old.pages as Array<any>).findIndex(
            (page) =>
              (page.data.results as QAList[]).find(
                (qaList) => qaList.defectListId === deletedId
              )
          );
          if (pageContainGroup !== -1) {
            const targetOrderItemIndex = (
              (old.pages as Array<any>)[pageContainGroup].data
                .results as QAList[]
            ).findIndex((qaList) => qaList.defectListId === deletedId);
            return produce(old, (draftOld: any) => {
              draftOld.pages = produce(draftOld.pages, (draftPages: any) => {
                draftPages[pageContainGroup].data.results = produce(
                  draftPages[pageContainGroup].data.results,
                  (draftTargetPageData: QAList[]) => {
                    draftTargetPageData.splice(targetOrderItemIndex, 1);

                    return draftTargetPageData;
                  }
                );
                return draftPages;
              });

              return draftOld;
            });
          } else queryClient.invalidateQueries(qKey);
        } else queryClient.invalidateQueries(qKey);
      });
    } else queryClient.invalidateQueries(qKey);
  };

  const handleEditQAList = (edittedQAList: QAList) => {
    const oldServerData = queryClient.getQueryData(qKey);

    if (oldServerData && overwrite) {
      queryClient.setQueryData(qKey, (old: any) => {
        if (old) {
          //find page contain deleted item
          let pageContainGroup: number = (old.pages as Array<any>).findIndex(
            (page) =>
              (page.data.results as QAList[]).find(
                (qaList) => qaList.defectListId === edittedQAList.defectListId
              )
          );
          if (pageContainGroup !== -1) {
            const targetOrderItemIndex = (
              (old.pages as Array<any>)[pageContainGroup].data
                .results as QAList[]
            ).findIndex(
              (qaList) => qaList.defectListId === edittedQAList.defectListId
            );
            return produce(old, (draftOld: any) => {
              draftOld.pages = produce(draftOld.pages, (draftPages: any) => {
                draftPages[pageContainGroup].data.results = produce(
                  draftPages[pageContainGroup].data.results,
                  (draftTargetPageData: QAList[]) => {
                    if (targetOrderItemIndex !== -1) {
                      const updatedItem =
                        draftTargetPageData[targetOrderItemIndex];
                      if (updatedItem)
                        Object.assign(updatedItem, edittedQAList);
                    }

                    return draftTargetPageData;
                  }
                );
                return draftPages;
              });

              return draftOld;
            });
          } else queryClient.invalidateQueries(qKey);
        } else queryClient.invalidateQueries(qKey);
      });
    } else queryClient.invalidateQueries(qKey);
  };

  const handleUpdateQAListSignature = (qaListId: string, newUrl: string) => {
    const oldServerData = queryClient.getQueryData(qKey);

    if (oldServerData && overwrite) {
      queryClient.setQueryData(qKey, (old: any) => {
        if (old) {
          //find page contain deleted item
          let pageContainGroup: number = (old.pages as Array<any>).findIndex(
            (page) =>
              (page.data.results as QAList[]).find(
                (qaList) => qaList.defectListId === qaListId
              )
          );
          if (pageContainGroup !== -1) {
            const targetOrderItemIndex = (
              (old.pages as Array<any>)[pageContainGroup].data
                .results as QAList[]
            ).findIndex((qaList) => qaList.defectListId === qaListId);
            return produce(old, (draftOld: any) => {
              draftOld.pages = produce(draftOld.pages, (draftPages: any) => {
                draftPages[pageContainGroup].data.results = produce(
                  draftPages[pageContainGroup].data.results,
                  (draftTargetPageData: QAList[]) => {
                    if (targetOrderItemIndex !== -1) {
                      const updatedItem =
                        draftTargetPageData[targetOrderItemIndex];
                      if (updatedItem) updatedItem.signatureUrl = newUrl;
                    }

                    return draftTargetPageData;
                  }
                );
                return draftPages;
              });

              return draftOld;
            });
          } else queryClient.invalidateQueries(qKey);
        } else queryClient.invalidateQueries(qKey);
      });
    } else queryClient.invalidateQueries(qKey);
  };
  return {
    handleAddQAList,
    handleDeleteQAList,
    handleEditQAList,
    handleUpdateQAListSignature,
  };
};

export default useSetQAListQueryData;
