import React, { useCallback, useState } from "react";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import {
  StyledAddProjectStatusContainer,
  StyledAddProjectStatusDisplay,
  StyledProjectStatusItemContainer,
  StyledProjectStatusListContainer,
} from "./StyledComponentAddProjectScreen";
import { editRgbaAlpha } from "../../Utilities/FunctionUtilities";
import { motion } from "framer-motion";
import useAddProjectStatus from "./Hooks/useAddProjectStatus";
import { ClickAwayListener, Grow, Popper } from "@mui/material";
import Loading from "../../Utilities/Lottie/Loading";
import ListLoadingMore from "../../Utilities/Lottie/ListLoadingMore";
import DoxleEmptyPlaceHolder from "../../DoxleDesignPattern/DoxleEmptyPlaceHolder/DoxleEmptyPlaceHolder";
import { Virtuoso } from "react-virtuoso";
import { ProjectStatus } from "../../Models/project";
import { useDoxleCurrentContextStore } from "../../DoxleGeneralStore/useDoxleCurrentContext";

type Props = {
  projectStatus?: string;
  onSelectProjectStatus: (value: string) => void;
};

const AddProjectStatus = ({ projectStatus, onSelectProjectStatus }: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }),
    shallow
  );
  const { currentCompany } = useDoxleCurrentContextStore(
    (state) => ({
      currentCompany: state.currentCompany,
    }),
    shallow
  );
  const {
    projectStatusList,
    isFetchingStatusList,
    isSuccessFetchingStatusList,
    isErrorFetchingStatusList,
    showStatusList,
    anchorStatusListEl,
    setAnchorStatusListEl,
    setShowStatusList,
    handleCloseStatusList,
    handleOpenStatusList,
    displayList,
  } = useAddProjectStatus({});
  const canBeOpen = showStatusList && Boolean(anchorStatusListEl);
  const id = canBeOpen ? "status-list-popper" : undefined;
  const handleClickStatusItem = useCallback(
    (item: ProjectStatus) => {
      handleCloseStatusList();
      onSelectProjectStatus(item.statusId);
    },
    [handleCloseStatusList, onSelectProjectStatus]
  );

  //* render list
  const itemContent = useCallback(
    (index: number, item: ProjectStatus) => (
      <ProjectStatusItem
        key={item.statusId + index}
        statusItem={item}
        handleClickStatusItem={handleClickStatusItem}
        selected={Boolean(item.statusId === projectStatus)}
      />
    ),
    [handleClickStatusItem, projectStatus]
  );
  //* animation
  const labelAnimatedVariants = {
    initial: {
      color: editRgbaAlpha({
        rgbaColor: doxleThemeColor.primaryFontColor,
        alpha: "0.4",
      }),
    },
    focused: {
      color: doxleThemeColor.primaryFontColor,
      transition: {
        duration: 0.4,
      },
    },
  };

  return (
    <StyledAddProjectStatusContainer $doxleFont={doxleFont}>
      <motion.span
        className="textfield-label"
        variants={labelAnimatedVariants}
        initial="initial"
        animate={canBeOpen ? "focused" : "initial"}
      >
        Project Status
      </motion.span>

      <StyledAddProjectStatusDisplay
        $themeColor={doxleThemeColor}
        $doxleFont={doxleFont}
        $isNull={!Boolean(projectStatus && projectStatusList.length > 0)}
        onClick={handleOpenStatusList}
      >
        {Boolean(projectStatus && projectStatusList.length > 0)
          ? projectStatusList.find(
              (status) => status.statusId === projectStatus
            )?.statusName
          : "Project Status"}

        <Popper
          id={id}
          open={showStatusList}
          anchorEl={anchorStatusListEl}
          transition
          placement="bottom-start"
          modifiers={[
            {
              name: "arrow",
              enabled: true,
            },
            {
              name: "preventOverflow",
              enabled: true,
              options: {
                altAxis: true,
                altBoundary: true,
                tether: true,
                rootBoundary: "document",
                padding: 8,
              },
            },
          ]}
        >
          {({ TransitionProps }) => (
            <ClickAwayListener onClickAway={handleCloseStatusList}>
              <Grow {...TransitionProps} timeout={350}>
                <StyledProjectStatusListContainer
                  $doxleFont={doxleFont}
                  $themeColor={doxleThemeColor}
                  elevation={4}
                  layout
                >
                  <span className="status-list-header">
                    {currentCompany && `${currentCompany.name}'s`} Project
                    Status
                  </span>

                  <div className="list-content">
                    {isFetchingStatusList && (
                      <ListLoadingMore
                        animationSize={100}
                        containerStyle={{
                          width: "100%",
                          height: "100%",
                          justifyContent: "center",
                          alignItems: "center",
                          display: "flex",
                        }}
                      />
                    )}

                    {isErrorFetchingStatusList && (
                      <DoxleEmptyPlaceHolder
                        headTitleText="Something Wrong!"
                        subTitleText="Failed to get status list..."
                        headTitleTextStyle={{
                          fontSize: "1.5rem",
                        }}
                        subTitleTextStyle={{
                          fontSize: "1.1rem",
                        }}
                      />
                    )}

                    {isSuccessFetchingStatusList &&
                      (displayList ? (
                        <Virtuoso
                          style={{ height: "100%", width: "100%" }}
                          data={projectStatusList}
                          itemContent={itemContent}
                        />
                      ) : (
                        <ListLoadingMore
                          animationSize={100}
                          containerStyle={{
                            width: "100%",
                            height: "100%",
                            justifyContent: "center",
                            alignItems: "center",
                            display: "flex",
                          }}
                        />
                      ))}
                  </div>
                </StyledProjectStatusListContainer>
              </Grow>
            </ClickAwayListener>
          )}
        </Popper>
      </StyledAddProjectStatusDisplay>
    </StyledAddProjectStatusContainer>
  );
};

export default AddProjectStatus;

const ProjectStatusItem = React.memo(
  (props: {
    statusItem: ProjectStatus;
    handleClickStatusItem: (item: ProjectStatus) => void;
    selected: boolean;
  }) => {
    const { statusItem, handleClickStatusItem, selected } = props;
    const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
      (state) => ({
        doxleThemeColor: state.doxleThemeColor,
        doxleFont: state.doxleFont,
      }),
      shallow
    );
    return (
      <StyledProjectStatusItemContainer
        $themeColor={doxleThemeColor}
        $doxleFont={doxleFont}
        $selected={selected}
        onClick={(event) => {
          event.stopPropagation();
          handleClickStatusItem(statusItem);
        }}
        initial={{
          x: -2,
          opacity: 0,
        }}
        animate={{
          x: 0,
          opacity: 1,
        }}
        transition={{
          duration: 0.2,
        }}
      >
        {statusItem.statusName}
      </StyledProjectStatusItemContainer>
    );
  }
);
