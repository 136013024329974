import {
  StyledCompanySettingsTabLeftColumn,
  StyledCompanySettingsTabRightColumn,
  StyledTabContainer,
} from "../styledComponents";
import WomanWithCog from "../Assets/WomanWithCog";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import EditProjectStatusSection from "../EditStatusSections/EditProjectStatusSection";
import EditBillStatusesSection from "../EditStatusSections/EditOrderStatusesSection";
import StatusColorPicker from "../StatusComponents/StatusColorPicker";
import EditDocketStatusesSection from "../EditStatusSections/EditDocketStatusesSection";

const StatusSettingsTab = () => {
  const styleProps = useDoxleThemeStore(
    (state) => ({
      $themeColor: state.doxleThemeColor,
      $doxleFont: state.doxleFont,
    }),
    shallow
  );

  return (
    <StyledTabContainer>
      <StyledCompanySettingsTabLeftColumn>
        <EditProjectStatusSection />
        <EditDocketStatusesSection />
        <EditBillStatusesSection />
        <StatusColorPicker />
      </StyledCompanySettingsTabLeftColumn>
      <StyledCompanySettingsTabRightColumn>
        <div className={"left"} />
        <WomanWithCog themeColor={styleProps.$themeColor} />
        <div className={"right"} />
      </StyledCompanySettingsTabRightColumn>
    </StyledTabContainer>
  );
};

export default StatusSettingsTab;
