import React, { useState } from "react";
import { useDoxleAuthStore } from "../../../../DoxleGeneralStore/useDoxleAuthStore";
import { shallow } from "zustand/shallow";
import useDoxleNotificationStore from "../../../../DoxleGeneralStore/useDoxleNotificationStore";
import useSetOrderDetailQueryData from "../../../Hooks/useSetOrderDetailQueryData";
import { useEditOrderStore } from "../../../OrdersStore/useEditOrderStore";
import OrdersQueryAPI, {
  AxiosAddUpdateOrderLineReturn,
} from "../../../QueryHooks/OrdersQueryAPI";
import { useDoxleCurrentContextStore } from "../../../../DoxleGeneralStore/useDoxleCurrentContext";
import { useOrdersStore } from "../../../OrdersStore/useOrdersStore";
import Order, { OrderLine } from "../../../Models/order";
import { useEditOrderContext } from "../EditOrder";
import { produce } from "immer";

type Props = {};

interface IOrderLineFooter {
  newOrderDescription: string;
  setNewOrderDescription: React.Dispatch<React.SetStateAction<string>>;

  newSpecialCondition: string;
  setnewSpecialCondition: React.Dispatch<React.SetStateAction<string>>;

  handleKeydownSpecialCondition: (
    event: React.KeyboardEvent<HTMLDivElement>
  ) => void;
  handleKeydownNewLineDescription: (
    event: React.KeyboardEvent<HTMLDivElement>
  ) => void;

  handleOrderSpecialConditionChange: (value: string) => void;
}

const useOrderLineFooter = (props: Props): IOrderLineFooter => {
  const { editedOrder, addNewOrderLine } = useEditOrderStore(
    (state) => ({
      editedOrder: state.editedOrder,
      updateEditedOrder: state.updateEditedOrder,
      addNewOrderLine: state.addNewOrderLine,
    }),
    shallow
  );
  const [newOrderDescription, setNewOrderDescription] = useState("");
  const [newSpecialCondition, setnewSpecialCondition] = useState(
    editedOrder?.specialConditions ?? ""
  );

  const editOrderContext = useEditOrderContext();
  const { setUpdatedOrder } = editOrderContext
    ? editOrderContext : {setUpdatedOrder: ()=>{}}
  const handleKeydownNewLineDescription = (
    event: React.KeyboardEvent<HTMLDivElement>
  ) => {
    if (event.nativeEvent.key === "Enter") {
      if (newOrderDescription) event.preventDefault();
      addNewOrderLine(newOrderDescription);
      setNewOrderDescription("");
      event.currentTarget.blur();
    }
  };

  const handleKeydownSpecialCondition = (
    event: React.KeyboardEvent<HTMLDivElement>
  ) => {
    if (event.nativeEvent.key === "Escape") {
      event.preventDefault();

      event.currentTarget.blur();
    }
  };

  const handleOrderSpecialConditionChange = (value: string) => {
    setUpdatedOrder(
      produce((draft) => {
        if (draft) {
          draft.specialConditions = value;
        } else
          draft = {
            specialConditions: value,
          };
        return draft;
      })
    );
    setnewSpecialCondition(value);
  };
  return {
    newOrderDescription,
    setNewOrderDescription,

    newSpecialCondition,
    setnewSpecialCondition,

    handleKeydownSpecialCondition,

    handleOrderSpecialConditionChange,
    handleKeydownNewLineDescription,
  };
};

export default useOrderLineFooter;
