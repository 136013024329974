import styled from "styled-components";
import {
  DoxleFont,
  DoxleTheme,
  DoxleThemeColor,
} from "../../DoxleGeneralStore/useDoxleThemeStore";
import {
  Button,
  Menu,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { motion } from "framer-motion";
import { editRgbaAlpha } from "../../Utilities/FunctionUtilities";

export const StyledPageContainer = styled.div<{}>`
  width: 100%;
  height: 100%;
  background-color: ${(p) => p.theme.color.primaryBackgroundColor};
  display: flex;
  flex-direction: column;
  min-width: 500px;

  .list-wrapper {
    width: calc(100% - 40px);
    height: calc(100% - 40px);
    display: flex;
    position: relative;
    padding: 20px;
  }
  .header-section {
    font-family: ${(p) => p.theme.font.primaryTitleFont};
    font-size: 30px;
    line-height: 30px;
    font-weight: 600;
    color: ${(p) => p.theme.color.primaryFontColor};
    text-align: center;
    margin: 14px 0px;
    text-transform: capitalize;
    width: calc(100% - 40px);
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 20px;

    div {
      display: flex;
      align-items: center;
    }
  }
`;

export const StyledPricebookPageHeaderTitle = styled(Typography)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  && {
    color: ${(p) => p.$themeColor.primaryFontColor};
    font-family: ${(p) => p.$doxleFont.primaryTitleFont};
    font-size: 30px;
    font-weight: 600;
  }
`;

export const StyledPricebookPageHeaderSubTitle = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  text-align: left;
  color: ${(p) => p.$themeColor.primaryFontColor};
  font-family: ${(p) => p.$doxleFont.primaryTitleFont};
  font-size: 11px;
  width: 500px;
  align-self: center;
`;

export const StyledPricebookPageSearchField = styled(TextField)<{
  $context?: string;
}>`
  && {
    width: 361px;
    height: 30px;
    background: #e3e5eb;
    border-radius: 13px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    align-self: center;
    padding: 0 8px;
    margin: ${(p) => (p.$context ? "0.5rem" : "1.9rem")};
  }
  input {
    padding: 0 !important;
    height: 100%;
    font-family: "IBM Plex Mono";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.05em;
    color: #000000;
  }
  & .MuiOutlinedInput-notchedOutline {
    border: none;
  }
`;

export const StyledPricebookPageHeaderContainer = styled.div<{
  $themeColor: DoxleThemeColor;
  $context?: string;
}>`
  width: 100%;
  ${(p) => (!p.$context ? "height: 2.6rem;" : "")};
  background-color: ${(p) => p.$themeColor.primaryBackgroundColor};
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  min-width: 500px;
`;

export const StyledPricebookTableRowWrapper = styled(TableRow)<{
  themeColor: DoxleThemeColor;
}>`
  && {
    display: flex;
    flex-direction: row;
    width: 100%;
    border-bottom: 1px solid ${(p) => p.themeColor.primaryDividerColor};
    height: 44px;
  }
`;
export const StyledPricebookRow = styled.div<{}>`
  display: flex !important;
  flex-direction: row;
  width: 100% !important;
  padding: 0px !important;
  overflow: hidden;
  height: 44px;
  transition: all 0.2s ease;
  cursor: pointer;
  background-color: ${(p) =>
    editRgbaAlpha({
      rgbaColor: p.theme.color.primaryContainerColor,
      alpha: p.theme.transparentMode ? "0" : "1",
    })};
  border-bottom: 1px solid ${(p) => p.theme.color.primaryDividerColor};
  &:hover {
    background-color: ${(p) =>
      editRgbaAlpha({ rgbaColor: p.theme.color.doxleColor, alpha: "0.2" })};
  }
`;
export const StyledPricebookTableCell = styled.div<{
  $width: string;

  $horizontalAlign?: "flex-start" | "center" | "flex-end";
  $letterSpacing?: string;
  $paddingLeft?: string;
  $fontWeight?: string;
  $fontColor?: string;
}>`
  width: calc(${(p) => p.$width} - 16px);
  display: flex;
  height: 100%;

  justify-content: ${(p) =>
    p.$horizontalAlign ? p.$horizontalAlign : "center"};
  align-items: center;
  font-family: ${(p) => p.theme.font.primaryFont};
  font-size: 16px;
  font-style: normal;
  font-weight: ${(p) => (p.$fontWeight ? p.$fontWeight : "500")};
  line-height: normal;
  letter-spacing: ${(p) => (p.$letterSpacing ? p.$letterSpacing : "0.7px")};
  color: ${(p) =>
    p.$fontColor ? p.$fontColor : p.theme.color.primaryFontColor};
  text-transform: capitalize;
  padding: 0px 8px;

  overflow: hidden !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-transform: capitalize;
`;

export const StyledPricebookTableHeaderRow = styled.div`
  width: 100% !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 30px;
  background-color: ${(p) => p.theme.color.primaryBackgroundColor};
`;
export const StyledPricebookTableHead = styled(TableHead)`
  && {
    width: 100% !important;
  }
`;
export const StyledPricebookTableHeaderCell = styled.div<{
  $width: string;
  $horizontalAlign?: "flex-start" | "center" | "flex-end";

  $paddingLeft?: number;
}>`
  width: calc(${(p) => p.$width} - 16px);
  display: flex;
  justify-content: ${(p) =>
    p.$horizontalAlign ? p.$horizontalAlign : "center"};
  align-items: center;
  padding: 0px 8px !important;
  color: ${(p) => p.theme.color.primaryFontColor};
  text-transform: capitalize;
  font-family: ${(p) => p.theme.font.primaryTitleFont};
  border: none !important;
`;

export const StyledPricebookTableHeaderText = styled(Typography)<{
  $fontWeight?: number;
}>`
  && {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    font-family: ${(p) => p.theme.font.primaryTitleFont};
    font-weight: ${(p) => p.$fontWeight ?? 400};
    cursor: pointer;
    color: ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.theme.color.primaryFontColor,
        alpha: "0.4",
      })};
  }
`;

export const RootPricebookTabMenu = styled(motion.div)`
  display: flex;
  flex-direction: row;
  margin: 20px 0px 20px 0px;
  height: 30px;
  align-self: center;
`;

export const StyledPricebookMenuItemBtn = styled(motion(Button))<{
  themecolor: DoxleThemeColor;
  selected: boolean;
  doxleFont: DoxleFont;
}>`
  && {
    font-family: ${(p) => p.doxleFont.primaryTitleFont};
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.05em;
    min-width: 0 !important;
    padding: 5px 14px !important;
    margin: 0 4px;
    border-radius: 20px !important;
    color: ${(p) => (p.selected ? "white" : p.themecolor.primaryFontColor)};
    background-color: ${(p) =>
      p.selected
        ? p.themecolor.doxleColor
        : p.themecolor.primaryContainerColor};
    text-transform: capitalize;
  }
`;

export const StyledPricebookKebabMenu = styled(Menu)`
  & .MuiMenu-list {
    background-color: white;
    opacity: 1;
    transform-origin: 168.188px 0px;
    right: 10px;
  }
  & .MuiMenuItem-root {
    font-family: "IBM Plex Sans";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.05em;
  }
`;

export const StyledContextDropDownContainer = styled.div<{
  $top: number;
  $right: number;
  $context: string;
}>`
  display: flex;
  flex-direction: column;
  z-index: 100;
  position: fixed;
  top: ${(p) =>
    `calc(${p.$top}px${p.$context === "Project" ? " - 3.6rem" : ""})`};
  right: calc(100% - ${(p) => p.$right}px);
  background-color: ${(p) => p.theme.color.primaryContainerColor};
  border-radius: 4px;
  padding: 10px;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);
`;
export const StyledDropDownItem = styled.div<{}>`
  width: calc(100% - 20px);
  font-family: ${(p) => p.theme.font.primaryFont};
  font-size: 14px;
  font-weight: 500;
  color: ${(p) => p.theme.color.primaryFontColor};
  border-bottom: 1px solid ${(p) => p.theme.color.primaryDividerColor};
  &:hover {
    background-color: ${(p) => p.theme.color.primaryHoverColor};
  }
  padding: 5px 10px;
  cursor: pointer;
`;

export const StyledEditRateHeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 10px 0px;
`;

export const StyledEditRateHeaderCell = styled.div<{
  $width?: string;
}>`
  font-family: ${(p) => p.theme.font.primaryTitleFont};
  font-size: 1.3rem;
  font-weight: 500;
  color: ${(p) => p.theme.color.primaryFontColor};
  ${(p) => (p.$width ? `width: ${p.$width};` : "")};
  text-align: center;
`;
