import * as React from "react";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import { StyledAccordion } from "./StyledAccordion";
import styled from "styled-components";
import {DoxleFont, DoxleThemeColor, useDoxleThemeStore} from "../../../../../DoxleGeneralStore/useDoxleThemeStore";
import {shallow} from "zustand/shallow";
import {editRgbaAlpha} from "../../../../../Utilities/FunctionUtilities";


export default function CustomAccordion() {
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const styleProps = useDoxleThemeStore((state) => ({
    $themeColor: state.doxleThemeColor,
    $doxleFont: state.doxleFont
  }), shallow);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <div>
      <StyledAccordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
        $displayBorderTop={true}
        {...styleProps}
      >
        <AccordionSummary aria-controls="panel1bh-content" id="panel1bh-header">
          <Typography>
            <Title {...styleProps}>What is Doxle?</Title>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <StyledGrey {...styleProps}>
              Doxle is a simple app for builders and developers that gives
              complete control over budgeting, bookings, quality control etc. We
              integrate with Xero so any given time you know where you stand
              with your building budgets
            </StyledGrey>
          </Typography>
        </AccordionDetails>
      </StyledAccordion>
      <StyledAccordion
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
        $displayBorderTop={false}
        {...styleProps}
      >
        <AccordionSummary aria-controls="panel2bh-content" id="panel2bh-header">
          <Typography>
            <Title {...styleProps}>Who is it for?</Title>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <StyledGrey {...styleProps}>
            Doxle is for anyone that is involved in the AEC (Architecture, Engineering and Construction) industry.
            </StyledGrey>
          </Typography>
        </AccordionDetails>
      </StyledAccordion>
      <StyledAccordion
        expanded={expanded === "panel3"}
        onChange={handleChange("panel3")}
        $displayBorderTop={false}
        {...styleProps}
      >
        <AccordionSummary aria-controls="panel3bh-content" id="panel3bh-header">
          <Typography>
            <Title {...styleProps}>How much does it cost?</Title>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <StyledGrey {...styleProps}>
              Doxle is currently free as its in beta.
            </StyledGrey>
          </Typography>
        </AccordionDetails>
      </StyledAccordion>
      <StyledAccordion
        expanded={expanded === "panel4"}
        onChange={handleChange("panel4")}
        $displayBorderTop={false}
        {...styleProps}
      >
        <AccordionSummary aria-controls="panel4bh-content" id="panel4bh-header">
          <Typography>
            <Title {...styleProps}>Is it live yet?</Title>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <StyledGrey {...styleProps}>
              Yes, we are currently in Beta with a small number of builders. You
              can email us at home@doxle.com if you would like to get early
              access
            </StyledGrey>
          </Typography>
        </AccordionDetails>
      </StyledAccordion>
    </div>
  );
}

const StyledGrey = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont
}>`
  color: ${(p) => editRgbaAlpha({rgbaColor: p.$themeColor.primaryFontColor, alpha: '0.7'})};
  font-size: 1.8rem;
  padding-left: 15px;
  font-family: ${(p) => p.$doxleFont.subTitleFont};
`;

const Title = styled.span<{
  $themeColor: DoxleThemeColor;
}>`
  color: ${(p) => p.$themeColor.primaryFontColor};
  font-size: 1.8rem;
`;
