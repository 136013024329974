import { useEditSOWGroup } from "../Hooks/useEditSOWGroup";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import DoxleDialogHelper from "../../DoxleDesignPattern/DoxleDialogHelper/DoxleDialogHelper";
import { useScopeOfWorkStore } from "../Store/useScopeOfWorkStore";
import { shallow } from "zustand/shallow";
type Props = {};

const EditSOWGroup = (props: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore((state) => ({
    doxleThemeColor: state.doxleThemeColor,
    doxleFont: state.doxleFont,
  }));
  const { deletedSOWGroup } = useScopeOfWorkStore(
    (state) => ({
      deletedSOWGroup: state.deletedSOWGroup,
      setDeletedSOWGroup: state.setDeletedSOWGroup,
      filterScopeOfWorkGroup: state.filterScopeOfWorkGroup,
    }),
    shallow
  );
  const {
    isDeleteSOWGroup,
    handleDeleteSOWGroup,

    handleCloseDeleteDialog,
  } = useEditSOWGroup();
  return (
    <DoxleDialogHelper
      title="Confirm Delete Group!"
      description="All data belong to this group will be deleted, do you want to proceed?"
      open={Boolean(deletedSOWGroup)}
      onClose={handleCloseDeleteDialog}
      dialogButtons={[
        {
          buttonText: "Cancel",
          buttonColor: doxleThemeColor.primaryContainerColor,
          buttonFunction: handleCloseDeleteDialog,
          btnStyleSx: {
            border: `1px solid ${doxleThemeColor.primaryDividerColor}`,
          },
          buttonTextStyle: {
            color: doxleThemeColor.primaryFontColor,
          },
        },

        {
          buttonText: "Confirm",
          buttonFunction: handleDeleteSOWGroup,
          btnStyleSx: {
            border: `1px solid ${doxleThemeColor.primaryDividerColor}`,
          },
        },
      ]}
    />
  );
};

export default EditSOWGroup;
