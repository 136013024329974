import {
  StyledQAEditFieldWrapper,
  StyledQaAssigneeDisplayer,
} from "./StyledComponentQAItemDetail";
import { useDoxleThemeStore } from "../../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import useEditQaAssignee from "./Hooks/useEditQaAssignee";
import CircularProgress from "@mui/material/CircularProgress";
import { useProjectQAStore } from "../../Store/useProjectQAStore";
import SelectAssigneeDropdown from "../CommonComponents/SelectAssigneeDropdown";
import { RiCloseCircleFill } from "react-icons/ri";
import DoxleIconButton from "../../../../DoxleDesignPattern/DoxleButtons/DoxleIconButton";
import DoxleSelectContactDropdown from "../../../../DoxleDesignPattern/DoxleSelectContactDropdown/DoxleSelectContactDropdown";
import { useShallow } from "zustand/react/shallow";
type Props = {};

const EditQaAssignee = (props: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    useShallow((state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }))
  );
  const { selectedQAItem } = useProjectQAStore(
    useShallow((state) => ({
      selectedQAItem: state.selectedQAItem,
    }))
  );
  const {
    anchorAssigneeList,
    setAnchorAssigneeList,
    handleUpdateAssignee,
    handleCloseAssigneeList,
    isUpdatingAssignee,
    isHovering,
    setIsHovering,
  } = useEditQaAssignee({});
  return (
    <StyledQAEditFieldWrapper className="field-wrapper">
      <span className="field-label-text">Assignee</span>
      <StyledQaAssigneeDisplayer
        onClick={(e) => setAnchorAssigneeList(e.currentTarget)}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
      >
        {selectedQAItem?.assignee ? (
          selectedQAItem?.assigneeName
        ) : (
          <span className="null-text">Select Assignee</span>
        )}

        {isUpdatingAssignee ? (
          <CircularProgress
            style={{ color: doxleThemeColor.primaryFontColor }}
            size={14}
          />
        ) : (
          selectedQAItem?.assignee &&
          isHovering && (
            <DoxleIconButton
              iconSource={
                <RiCloseCircleFill
                  style={{ color: doxleThemeColor.primaryFontColor }}
                  size={20}
                />
              }
              onClick={(e) => {
                e.stopPropagation();
                handleUpdateAssignee(undefined);
              }}
              buttonSize={15}
              buttonWrapperStyle={{
                padding: "0px !important",
                margin: "0px !important",
              }}
            />
          )
        )}
      </StyledQaAssigneeDisplayer>

      <DoxleSelectContactDropdown
        popoverAnchor={anchorAssigneeList}
        onClosePopover={handleCloseAssigneeList}
        variants="contact"
        selectedContactItemIds={
          selectedQAItem?.assignee ? [selectedQAItem?.assignee] : undefined
        }
        onSelectContact={handleUpdateAssignee}
        containerStyle={{
          borderRadius: 9,
        }}
      />
    </StyledQAEditFieldWrapper>
  );
};

export default EditQaAssignee;
