import React from "react";
import { GoPlus } from "react-icons/go";
import {
  StyledAddNoteBtn,
  StyledAddNoteTitleTextField,
  StyledNoteListFooter,
} from "./StyledNoteList";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import useNoteListFooter from "./Hooks/useNoteListFooter";
import CircularProgress from "@mui/material/CircularProgress";
import { editRgbaAlpha } from "../../../Utilities/FunctionUtilities";

type Props = {};

const NoteListFooter = (props: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }),
    shallow
  );
  const {
    newNoteTitle,
    setNewNoteTitle,
    handleAddNote,
    handleKeydownNoteTitleTextfield,
    isAddingNote,
  } = useNoteListFooter({});
  return (
    <StyledNoteListFooter>
      <StyledAddNoteTitleTextField
        $themeColor={doxleThemeColor}
        $doxleFont={doxleFont}
        placeholder="New"
        variant="standard"
        value={newNoteTitle}
        onChange={(event) => setNewNoteTitle(event.target.value)}
        InputProps={{
          startAdornment: isAddingNote ? (
            <CircularProgress
              size="1.4rem"
              style={{
                color: doxleThemeColor.primaryInputPlaceholderColor,

                marginRight: "0.4rem",
              }}
            />
          ) : (
            <StyledAddNoteBtn
              initial={{
                backgroundColor: editRgbaAlpha({
                  rgbaColor: doxleThemeColor.primaryFontColor,
                  alpha: "0",
                }),
              }}
              animate={
                newNoteTitle
                  ? {
                      backgroundColor: doxleThemeColor.primaryFontColor,
                    }
                  : {
                      backgroundColor: editRgbaAlpha({
                        rgbaColor: doxleThemeColor.primaryFontColor,
                        alpha: "0",
                      }),
                    }
              }
            >
              <GoPlus
                style={{
                  color: !newNoteTitle
                    ? doxleThemeColor.primaryInputPlaceholderColor
                    : doxleThemeColor.primaryContainerColor,
                  fontSize: "1.8rem",
                }}
              />
            </StyledAddNoteBtn>
          ),
        }}
        onBlur={handleAddNote}
        onKeyDown={handleKeydownNoteTitleTextfield}
      />
    </StyledNoteListFooter>
  );
};

export default NoteListFooter;
