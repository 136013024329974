import { ScopeOfWorkGroup } from "../../../../ScopeOfWorks/Model/scopeOfWorks";
import useQuoteSOWGroupItem from "../Hooks/useQuoteSOWGroupItem";
import { StyledQuoteSOWGroupItem } from "../StyledComponentQuoteResponse";
import { useDoxleThemeStore } from "../../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import LinearProgress from "@mui/material/LinearProgress";
import { editRgbaAlpha } from "../../../../Utilities/FunctionUtilities";
import DoxleEmptyPlaceHolder from "../../../../DoxleDesignPattern/DoxleEmptyPlaceHolder/DoxleEmptyPlaceHolder";
import QuoteSOWItem from "./QuoteSOWItem";

type Props = {
  sowGroup: ScopeOfWorkGroup;
};

const QuoteSOWGroupItem = ({ sowGroup }: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,

      doxleFont: state.doxleFont,
    }),
    shallow
  );
  const {
    scopeItemList,
    isFetchingScopeItems,
    isSuccessFetchingScopeItems,
    isErrorFetchingScopeItems,
  } = useQuoteSOWGroupItem({ sowGroup });
  return (
    <StyledQuoteSOWGroupItem
      $themeColor={doxleThemeColor}
      $doxleFont={doxleFont}
    >
      <div className="sow-group-title">{sowGroup.scopeGroupTitle}</div>
      <div className="sow-item-container">
        {isFetchingScopeItems && (
          <LinearProgress
            variant="indeterminate"
            sx={{
              backgroundColor: editRgbaAlpha({
                rgbaColor: doxleThemeColor.doxleColor,
                alpha: "0.2",
              }),
              margin: "0.8rem 0px",
              width: "50%",
              alignSelf: "center",
              "& .MuiLinearProgress-bar": {
                backgroundColor: `${doxleThemeColor.doxleColor} !important`,
              },
            }}
          />
        )}

        {isErrorFetchingScopeItems && (
          <DoxleEmptyPlaceHolder
            containerBgColor="transparent"
            containerStyle={{
              width: "100%",
              height: "14rem",
            }}
            headTitleText="Something wrong!"
            subTitleText="Failed to get scope items..."
            headTitleTextStyle={{ fontSize: "2rem" }}
            subTitleTextStyle={{ fontSize: "1.4rem" }}
          />
        )}

        {isSuccessFetchingScopeItems && (
          <>
            {scopeItemList.length > 0 ? (
              scopeItemList.map((scopeItem) => (
                <QuoteSOWItem
                  scopeItem={scopeItem}
                  key={scopeItem.scopeItemId}
                />
              ))
            ) : (
              <DoxleEmptyPlaceHolder
                headTitleText="No Items in this scope group!"
                containerBgColor="transparent"
                containerStyle={{
                  height: "8rem",
                }}
                headTitleTextStyle={{ fontSize: "1.8rem" }}
              />
            )}
          </>
        )}
      </div>
    </StyledQuoteSOWGroupItem>
  );
};

export default QuoteSOWGroupItem;
