import React, { useState } from "react";
import woman from "../../images/woman.png";
import { useJoinContext } from "../../pages/Join";
import { useDoxleThemeStore} from "../../../../DoxleGeneralStore/useDoxleThemeStore";
import { JoinDoxle, NextBtn, PromptArea, SelectArea, SelectRoleContainer, SelectRoleLeft, SelectRoleRight, SelectRoleRightInnerContainer, StyledDescription } from "./StyledJoinPage";
import GirlWithDog from "../svg/GirlWithDog";

export default function SelectNewUserRolePage() {
  const { doxleThemeColor, doxleFont, currentTheme } = useDoxleThemeStore((state) => ({
    doxleThemeColor: state.doxleThemeColor,
    doxleFont: state.doxleFont,
    currentTheme: state.currentTheme,
  }));

  const {stage,setStage, userFormData, setUserFormData}=useJoinContext();
  const [role, setRole] = useState("Architect");


  const onOptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRole(e.target.value);
  };

  const handleClick = () => {
    const userData = {...userFormData};
    userData['role'] = role
    setUserFormData(userData);
    setStage(stage + 1);
  }

  return (
    <SelectRoleContainer>
      <SelectRoleLeft>
        <GirlWithDog currentTheme={currentTheme} style={{maxWidth: "40vw"}} />
      </SelectRoleLeft>
      <SelectRoleRight>
        <SelectRoleRightInnerContainer>
        <PromptArea>
          <JoinDoxle $themeColor={doxleThemeColor}>Join Doxle</JoinDoxle>
          <StyledDescription $themeColor={doxleThemeColor}>What best describes you?</StyledDescription>
        </PromptArea>

        <SelectArea $themeColor={doxleThemeColor} $doxleFont={doxleFont}>
          <label className="container">
            Architect
            <input
              type="radio"
              checked={role === "Architect"}
              name="customerType"
              value="Architect"
              id="architect"
              onChange={onOptionChange}
            />
            <span className="checkmark"></span>
          </label>

          <label className="container">
            Builder
            <input
              type="radio"
              name="customerType"
              value="Builder"
              id="builder"
              onChange={onOptionChange}
            />
            <span className="checkmark"></span>
          </label>

          <label className="container">
            Developer
            <input
              type="radio"
              name="customerType"
              value="Developer"
              id="developer"
              onChange={onOptionChange}
            />
            <span className="checkmark"></span>
          </label>

          <label className="container">
            Engineer
            <input
              type="radio"
              name="customerType"
              value="Enginner"
              id="enginner"
              onChange={onOptionChange}
            />
            <span className="checkmark"></span>
          </label>

          <label className="container">
            Inspector
            <input
              type="radio"
              name="customerType"
              value="Inspector"
              id="inspector"
              onChange={onOptionChange}
            />
            <span className="checkmark"></span>
          </label>

          <label className="container">
            House Owner
            <input
              type="radio"
              name="customerType"
              value="House Owner"
              id="houseOwner"
              onChange={onOptionChange}
            />
            <span className="checkmark"></span>
          </label>
          <NextBtn $themeColor={doxleThemeColor} onClick={handleClick}>Next</NextBtn>
        </SelectArea>
        </SelectRoleRightInnerContainer>
      </SelectRoleRight>
    </SelectRoleContainer>
  );
}
