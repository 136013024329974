import {useDoxleCurrentContextStore} from "../../DoxleGeneralStore/useDoxleCurrentContext";
import {useShallow} from "zustand/react/shallow";
import {useInfiniteQuery, useMutation} from "@tanstack/react-query";
import {Floor, FloorFilters, Room, RoomFilters} from "../../QA/Models/locations";
import DoxleAPI from "../DoxleAPI";
interface BaseRetrieveListProps {
  onSuccessCb?: () => void;
  onErrorCb?: () => void;
  enabled?: boolean
}
interface UseRetrieveFloorsQueryProps extends BaseRetrieveListProps, FloorFilters {}
interface UseRetrieveRoomsQueryProps extends BaseRetrieveListProps, RoomFilters {}

const getFloorQueryKey = (filter: FloorFilters) => {
  const qKey = ['retrieve-floors']
  for (const [key, value] of Object.entries(filter)) {
    qKey.push(`${key}:${value}`)
  }
  return qKey
}
const getRoomQueryKey = (filter: FloorFilters) => {
  const qKey = ['retrieve-rooms']
  for (const [key, value] of Object.entries(filter)) {
    qKey.push(`${key}:${value}`)
  }
  return qKey
}

const useRetrieveFloorsQuery = ({onSuccessCb, onErrorCb, enabled = true, ...params}: UseRetrieveFloorsQueryProps) => {
  const companyId = useDoxleCurrentContextStore(useShallow(state => state.currentCompany?.companyId)) ?? ""
  return useInfiniteQuery(
    getFloorQueryKey(params),
    ({pageParam = `/loc/floor/?page=1`}) => DoxleAPI.get(pageParam, {params, headers: {'User-Company': companyId}}),
    {
      enabled: Boolean(companyId && enabled),
      retry: 1,
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: false,
      staleTime: 5 * 60 * 1000,
      cacheTime: 30 * 60 * 1000,
      getNextPageParam: (prevData) => prevData.data?.next,
      getPreviousPageParam: (prev) => prev.data.previous,
      onSuccess: () => {
        if (onSuccessCb) onSuccessCb()
      },
      onError: () => {
        if (onErrorCb) onErrorCb()
      }
    }
  )
}
const useRetrieveRoomsQuery = ({onSuccessCb, onErrorCb, enabled = true, ...params}: UseRetrieveRoomsQueryProps) => {
  const companyId = useDoxleCurrentContextStore(useShallow(state => state.currentCompany?.companyId)) ?? ""
  return useInfiniteQuery(
    getRoomQueryKey(params),
    ({pageParam = `/loc/room/?page=1`}) => DoxleAPI.get(pageParam, {params, headers: {'User-Company': companyId}}),
    {
      enabled: Boolean(companyId && enabled),
      retry: 1,
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: false,
      staleTime: 5 * 60 * 1000,
      cacheTime: 30 * 60 * 1000,
      getNextPageParam: (prevData) => prevData.data?.next,
      getPreviousPageParam: (prev) => prev.data.previous,
      onSuccess: () => {
        if (onSuccessCb) onSuccessCb()
      },
      onError: () => {
        if (onErrorCb) onErrorCb()
      },
    }
  )
}

const useAddFloorQuery = ({onSuccessCb, onErrorCb, ...params}: UseRetrieveFloorsQueryProps) => {
  const companyId = useDoxleCurrentContextStore(useShallow(state => state.currentCompany?.companyId)) ?? ""
  return useMutation(
    ['add-floor'],
    (payload: Floor) => DoxleAPI.post('loc/room/', payload, {headers: {'User-Company': companyId}}),
    {
      onSuccess: () => {
        if (onSuccessCb) onSuccessCb()
      },
      onError: () => {
        if (onErrorCb) onErrorCb()
      },
    }
  )
}

const useAddRoomQuery = ({onSuccessCb, onErrorCb, ...params}: UseRetrieveRoomsQueryProps) => {
  const companyId = useDoxleCurrentContextStore(useShallow(state => state.currentCompany?.companyId)) ?? ""
  return useMutation(
    ['add-room'],
    (payload: Room) => DoxleAPI.post('loc/room/', payload, {headers: {'User-Company': companyId}}),
    {
      onSuccess: () => {
        if (onSuccessCb) onSuccessCb()
      },
      onError: () => {
        if (onErrorCb) onErrorCb()
      },
    }
  )
}

const LocationQueryAPI = {
  useRetrieveFloorsQuery,
  useRetrieveRoomsQuery,
  useAddFloorQuery,
  useAddRoomQuery
}
export default LocationQueryAPI
