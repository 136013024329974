import React from "react";
import animationData from "./lotties/linking.json";
import { AnimatePresence, motion } from "framer-motion";
import { useLottie } from "lottie-react";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
type Props = {
  text?: string;
  containerStyle?: React.CSSProperties;
  animationSize?: number;
  textStyle?: React.CSSProperties;
  enableAnimation?: boolean;
};

const LinkingLoader = ({
  text,
  containerStyle,
  animationSize = 50,
  textStyle,
  enableAnimation = false,
}: Props) => {
  const options = {
    animationData: animationData,
    loop: true,
    autoplay: true,
  };

  const { doxleThemeColor } = useDoxleThemeStore((state) => ({
    doxleThemeColor: state.doxleThemeColor,
    doxleFont: state.doxleFont,
  }));

  const { View } = useLottie(options, { width: `${animationSize}px` });

  return (
    <AnimatePresence>
      <motion.div
        initial={
          enableAnimation && {
            scale: 0,
            opacity: 0,
          }
        }
        animate={
          enableAnimation && {
            scale: 1,
            opacity: 1,
          }
        }
        transition={{
          duration: 0.4,
        }}
        exit={enableAnimation ? { scale: 0, opacity: 0 } : undefined}
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignContent: "center",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "transparent",
          fontSize: "2.7rem",
          color: doxleThemeColor.bolderDividerColor,
          zIndex: 10,
          ...containerStyle,
        }}
      >
        {View} {text && <span style={{ ...textStyle }}>{text}</span>}
      </motion.div>
    </AnimatePresence>
  );
};

export default LinkingLoader;
