import axios, {
  AxiosError,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from "axios";
import { ReactNode, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDoxleAuthStore } from "../DoxleGeneralStore/useDoxleAuthStore";
import { useDoxleCurrentContextStore } from "../DoxleGeneralStore/useDoxleCurrentContext";
import useNavigateToLastApp from "./useNavigateToLastApp";
import { useDoxleThemeStore } from "../DoxleGeneralStore/useDoxleThemeStore";
import { useShallow } from "zustand/react/shallow";
// import useDoxleNotificationStore from "../DoxleGeneralStore/useDoxleNotificationStore";

const local: boolean = false;

export const baseAddress: string =
  local && !window.location.host.includes("doxle")
    ? "http://127.0.0.1:8000/api2"
    : "https://app.doxle.com/api2";

const DoxleAPI = axios.create({
  baseURL: baseAddress,
  withCredentials: true,
});

export const DoxleAnonymousAPI = axios.create({
  baseURL: baseAddress,
});

export default DoxleAPI;

export const isAppDotDoxle = () =>
  Boolean(
    window.location.hostname.toLowerCase() === "inventory.doxle.com" ||
      window.location.hostname.toLowerCase() === "noticeboard.doxle.com" ||
      window.location.hostname.toLowerCase() === "projects.doxle.com" ||
      window.location.hostname.toLowerCase() === "draw.doxle.com" ||
      window.location.hostname.toLowerCase() === "bills.doxle.com" ||
      window.location.hostname.toLowerCase().includes("127.0.0.1") ||
      window.location.hostname.toLowerCase().includes("localhost")
  );

interface Props {
  children: ReactNode;
}
export const AxiosInterceptor = ({ children }: Props) => {
  const companyId = useDoxleCurrentContextStore(
    useShallow((state) => state.currentCompany?.companyId)
  );
  const setLoggedIn = useDoxleAuthStore(
    useShallow((state) => state.setLoggedIn)
  );
  const { fontMode, currentTheme } = useDoxleThemeStore(
    useShallow((state) => ({
      fontMode: state.fontMode,
      currentTheme: state.currentTheme,
    }))
  );
  // const showNotification = useDoxleNotificationStore(state => state.showNotification, shallow);
  const navigate = useNavigate();
  const pathname = window.location.pathname;

  const isGreetingPage = Boolean(
    pathname === "/Login/" ||
      pathname === "/About/" ||
      pathname === "/Join/" ||
      pathname === "/Login/" ||
      pathname === "/ContactUs/" ||
      pathname === "/UploadDrawing/" ||
      pathname === "/Welcome/"
  );

  const redirectToApp = useNavigateToLastApp();

  const redirectToLogin = () => {
    if (
      isGreetingPage ||
      pathname.includes("Shared") ||
      window.location.href.toLowerCase().includes("login") ||
      // window.location.hostname.toLowerCase().startsWith("doxle") ||
      // window.location.hostname.toLowerCase().startsWith("www") ||
      (!window.location.hostname.toLowerCase().includes("doxle") &&
        pathname === "/")
    )
      return;
    if (window.location.hostname.toLowerCase().includes(".doxle.com")) {
      const app = window.location.host.substring(
        0,
        window.location.host.indexOf(".doxle.com")
      );
      window.open(
        `https://login.doxle.com/?app=${app}&path=${window.location.pathname}`,
        "_self"
      );
    } else {
      navigate(`/Login/?path=${window.location.pathname}`);
    }
  };

  useEffect(() => {
    // INTERCEPT ALL AXIOS REQUESTS BEFORE SENDING - ADD COMPANY ID
    const reqInterceptor = (config: InternalAxiosRequestConfig<any>) => {
      // if (companyId) config.headers["User-Company"] = companyId;
      config.headers["X-User-Theme"] = currentTheme;
      config.headers["X-User-Font"] = fontMode;
      return config;
    };

    // INTERCEPT ALL AXIOS RESPONSES
    const successInterceptor = (response: AxiosResponse<any, any>) => {
      setLoggedIn(true);
      if (isGreetingPage || !isAppDotDoxle()) redirectToApp();
      // if (response.status === 201) showNotification('Entry saved successfully', 'success')
      // if (response.status === 204) showNotification('Entry deleted successfully', 'success')
      return response;
    };

    // INTERCEPT ALL AXIOS ERRORS
    const errInterceptor = (error: AxiosError<any>) => {
      if (
        error?.response?.status === 403 &&
        error?.response?.data?.detail?.includes("VerifyUserException")
      ) {
        setLoggedIn(false);
        redirectToLogin();
      }
      // showNotification(
      //   `${error?.response?.status ?? "ERROR"}: ${
      //     error?.response?.statusText ?? "Unkown Error"
      //   }`,
      //   "error",
      //   String(error?.response?.data).substring(0, 250) ?? "Error Processing Request"
      // );
      return Promise.reject(error);
    };
    DoxleAPI.interceptors.request.clear();
    DoxleAPI.interceptors.response.clear();
    DoxleAPI.interceptors.request.use(reqInterceptor);
    DoxleAPI.interceptors.response.use(successInterceptor, errInterceptor);

    // return () => {
    //   DoxleAPI.interceptors.request.eject(requestInterceptor)
    //   DoxleAPI.interceptors.response.eject(responseInterceptor)
    // };
  }, [navigate, companyId, fontMode, currentTheme]);

  return children;
};
