import Paper, { PaperProps } from "@mui/material/Paper";
import { motion } from "framer-motion";
import styled from "styled-components";
import {
  DoxleFont,
  DoxleThemeColor,
} from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { editRgbaAlpha } from "../../../Utilities/FunctionUtilities";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
interface IStatusListContainerProps
  extends Omit<
    PaperProps,
    "onDragStart" | "onAnimationStart" | "onDragEnd" | "onDrag"
  > {
  $themeColor: DoxleThemeColor;
}
const AnimatedPaper = motion(Paper);
const statusListAnimatedVariants = {
  entering: {
    y: [-10, 0],
    opacity: [0, 1],
    transition: {
      duration: 0.4,
    },
  },
  exiting: {
    y: [0, -10],
    opacity: [1, 0],
    transition: {
      duration: 0.4,
    },
  },
};
export const StyledSelectContractorPopover = styled(
  ({ $themeColor, ...rest }: IStatusListContainerProps) => (
    <AnimatedPaper
      {...rest}
      variants={statusListAnimatedVariants}
      animate="entering"
      exit="exiting"
      initial="initial"
      layout
      elevation={4}
    />
  )
)`
  && {
    background-color: ${(p) => p.$themeColor.primaryContainerColor};
    width: max(33rem, 330px);
    min-height: max(44rem, 440px);
    border-radius: 0px 9px 9px 9px;
    position: relative;
    padding: 1rem;
    box-shadow: 0px 0px 8px 0px ${(p) => p.$themeColor.primaryBoxShadowColor};
    border: 1px solid ${(p) => p.$themeColor.primaryDividerColor};
    display: flex;
    flex-direction: column;
  }
`;
export const StyledContractorItem = styled(motion.div)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  background-color: transparent;
  text-transform: capitalize !important;
  list-style-position: inside;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: calc(100% - 1.6rem);
  padding: 0px 0.8rem;
  font-family: ${(p) => p.$doxleFont.primaryFont};
  font-weight: 500;
  font-size: max(1.2rem, 12px);
  line-height: normal;
  height: max(3rem, 30px);
  display: flex;
  align-items: center;
  width: 100%;
  text-transform: capitalize;
  cursor: pointer;
  transition: 0.2s ease all;
  &:hover {
    background-color: ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.$themeColor.doxleColor,
        alpha: "0.4",
      })} !important;
    color: white !important;
    transition: 0.2s ease all;
  }
`;
export const StyledSearchContractorTextfield = styled(TextField)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  && {
    overflow: hidden;
    flex: 1;

    height: max(2.7rem, 27px);
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  & .MuiInputBase-input.Mui-disabled {
    -webkit-text-fill-color: ${(p) => p.$themeColor.primaryFontColor};
  }
  input {
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-weight: 500;
    font-size: max(1.3rem, 13px);
    line-height: normal;
    color: ${(p) => p.$themeColor.primaryFontColor};
    padding: 0px !important;

    &::placeholder {
      font-family: ${(p) => p.$doxleFont.primaryFont};
      font-weight: 500;
      font-size: max(1.3rem, 13px);
      line-height: normal;
      padding: 0px !important;
      color: ${(p) =>
        editRgbaAlpha({
          rgbaColor: p.$themeColor.primaryFontColor,
          alpha: "0.5",
        })};
    }
  }
  & .MuiInput-root:after {
    border-bottom: none !important;
  }
  & .MuiInput-root:before {
    border-bottom: none !important;
  }
  & .MuiInput-root {
    border-bottom: none !important;
  }
  & .MuiInput-root:hover:not(.Mui-disabled, .Mui-error):before {
    border-bottom: none !important;
  }
  & .MuiInputBase-root {
    width: 100%;
  }
`;
export const StyledContractorListScroller = styled(motion.div)`
  width: 100%;
  height: 100%;

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;
export const StyledSearchContractorWrapper = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  width: calc(100%);
  display: flex;
  align-items: center;
  padding: 4px 0px !important;
  margin-bottom: 4px;
  border-bottom: 1px solid ${(p) => p.$themeColor.primaryDividerColor};

  .form-title {
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-size: max(1.8rem, 18px);
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    color: ${(p) => p.$themeColor.primaryFontColor} !important;
    flex: 1;
  }
`;
export const StyledAddContractorButton = styled(motion(Button))<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  && {
    display: flex;
    flex-direction: row;
    min-width: 0px !important;
    cursor: pointer;
    padding: 4px 8px !important;
    border-radius: 6px !important;
    background-color: ${(p) => p.$themeColor.primaryContainerColor} !important;
    color: ${(p) => p.$themeColor.primaryFontColor} !important;
    text-transform: capitalize !important;
    border: 1px solid ${(p) => p.$themeColor.primaryDividerColor};
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-size: max(1.1rem, 11px);
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    text-transform: capitalize !important;
    transition: all 0.2s ease;
    &:hover {
      background-color: ${(p) => p.$themeColor.primaryFontColor} !important;
      color: ${(p) => p.$themeColor.primaryContainerColor} !important;

      opacity: 0.95;
      transition: all 0.2s ease;
    }
  }
`;

export const StyledAddContactForm = styled(motion.div)`
  width: calc(100%);
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 14px 0px;
  overflow-y: auto;
  position: relative;
`;

export const StyledAddContactFieldWrapper = styled(motion.div)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 14px;

  .field-label {
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-size: max(1.1rem, 11px);
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: ${(p) => p.$themeColor.primaryFontColor};
    margin-bottom: 8px;
  }

  .error-text {
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-size: max(1.1rem, 11px);
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: red;
    margin-top: 2px;
  }
`;
export const StyledAddContactTextfield = styled(TextField)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  && {
    width: 100%;
    padding-bottom: 2px;
    border-bottom: 1px solid ${(p) => p.$themeColor.primaryDividerColor};
  }
  & .MuiInputBase-input.Mui-disabled {
    -webkit-text-fill-color: ${(p) => p.$themeColor.primaryFontColor};
  }
  input {
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-weight: 500;
    font-size: max(1.2rem, 12px);
    line-height: normal;
    color: ${(p) => p.$themeColor.primaryFontColor};
    padding: 0px !important;

    &::placeholder {
      font-family: ${(p) => p.$doxleFont.primaryFont};
      font-weight: 500;
      font-size: max(1.1rem, 11px);
      line-height: normal;
      padding: 0px !important;
      color: ${(p) =>
        editRgbaAlpha({
          rgbaColor: p.$themeColor.primaryFontColor,
          alpha: "0.5",
        })};
    }
  }
  & .MuiInput-root:after {
    border-bottom: none !important;
  }
  & .MuiInput-root:before {
    border-bottom: none !important;
  }
  & .MuiInput-root {
    border-bottom: none !important;
  }
  & .MuiInput-root:hover:not(.Mui-disabled, .Mui-error):before {
    border-bottom: none !important;
  }
  & .MuiInputBase-root {
    width: 100%;
  }
`;

export const StyledSubmitAddButton = styled(motion(Button))<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  && {
    display: flex;
    flex-direction: row;
    min-width: 0px !important;
    cursor: pointer;
    align-self: flex-end;

    padding: 4px 12px !important;
    border-radius: 6px !important;
    background-color: ${(p) => p.$themeColor.primaryFontColor} !important;

    text-transform: capitalize !important;
    border: 1px solid ${(p) => p.$themeColor.primaryDividerColor};
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-size: max(1.4rem, 14px);
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: ${(p) => p.$themeColor.primaryContainerColor} !important;
    margin-top: 14px;
    transition: all 0.2s ease;
    &:hover {
      background-color: ${(p) =>
        p.$themeColor.primaryContainerColor} !important;
      color: ${(p) => p.$themeColor.primaryFontColor} !important;
      opacity: 0.95;
      transition: all 0.2s ease;
    }
  }
`;
