import React from "react";
import {
  CompanyPermission,
  DocketPermission,
  ProjectPermission,
} from "../../Models/permissions";
import Tooltip from "@mui/material/Tooltip";
import { DoxleMUITooltipSx } from "../MUIOverwriteSx";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import { generateInitials } from "../../Utilities/FunctionUtilities";
import { StyledDoxlePermissionAvatar } from "./StyledDoxlePermissionAvatar";

type Props = {
  permissionItem: ProjectPermission | DocketPermission | CompanyPermission;
  color: string;
  size?: number;
};

const DoxlePermissionAvatar = ({ permissionItem, color, size }: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore((state) => ({
    doxleThemeColor: state.doxleThemeColor,
    doxleFont: state.doxleFont,
  }));
  const initialString = generateInitials(
    `${permissionItem.contact.firstName} ${permissionItem.contact.lastName}`
  );
  return (
    <Tooltip
      PopperProps={{
        sx: DoxleMUITooltipSx(doxleThemeColor, doxleFont),
      }}
      title={`${permissionItem.contact.firstName} ${permissionItem.contact.lastName}`}
    >
      <StyledDoxlePermissionAvatar $bgColor={color} $size={size}>
        {initialString}
      </StyledDoxlePermissionAvatar>
    </Tooltip>
  );
};

export default DoxlePermissionAvatar;
