import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import { Contact } from "../../Models/addressBook";
import {
  Autocomplete,
  CircularProgress,
  FormControl,
  InputAdornment,
} from "@mui/material";
import { useDoxleAuthStore } from "../../DoxleGeneralStore/useDoxleAuthStore";
import { useDoxleCurrentContextStore } from "../../DoxleGeneralStore/useDoxleCurrentContext";
import ContactsAPI from "../../Services/QueryHooks/contactsAPI";
import AutocompleteList from "./AutocompleteList";
import {
  StyledContactOption,
  StyledContactsAutocompleteTextField,
} from "./StyledComponentDoxleAutocomplete";
import { AnimatePresence, LayoutGroup } from "framer-motion";
import AutocompleteContactValueTag from "./AutocompleteValueTag";
import {ContactsFilters} from "../../Services/QueryHooks/contactsFilters";

type Props = {
  valueList: Contact[];
  setNewContact: (newValue: Contact[]) => void;
  autocompleteWrapperStyle?: React.CSSProperties;
  labelStyle?: React.CSSProperties;
  labelText?: string;
  optionStyle?: React.CSSProperties;
  limitDisplayTags?: number;
};

const DoxleContactAutocompleteMultiple = ({
  valueList,
  setNewContact,
  autocompleteWrapperStyle,
  labelStyle,
  labelText,
  optionStyle,
  limitDisplayTags = 2,
}: Props) => {
  const [searchInput, setSearchInput] = useState<string>("");
  const [searchTextQuery, setSearchTextQuery] = useState<string>("");
  const [selectedValue, setSelectedValue] = useState<Contact[]>([...valueList]);
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore((state) => ({
    doxleThemeColor: state.doxleThemeColor,
    doxleFont: state.doxleFont,
  }));


  const company = useDoxleCurrentContextStore((state) => state.currentCompany);
  const filterRetrieveContactListQuery: ContactsFilters = useMemo(
    () => ({
      search: searchInput,
    }),
    [searchInput]
  );

  const getContactQuery = ContactsAPI.useRetrieveContactsQuery({
    company,
    filter: filterRetrieveContactListQuery,
    enable: true,
  });
  // useEffect(() => {
  //   if (retrievedCompanies.hasNextPage) retrievedCompanies.fetchNextPage();
  // }, [retrievedCompanies.hasNextPage]);
  const contactList: Contact[] = useMemo(
    () =>
      getContactQuery.isSuccess
        ? getContactQuery.data.pages.flatMap(
            (page) => page.data.results ?? []
          ) ?? []
        : [],
    [getContactQuery.data]
  );

  const handleAutoCompleteChange = useCallback((newValue: Contact[]) => {
    setSelectedValue(newValue);
  }, []);

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const timeout = setTimeout(() => setSearchInput(searchTextQuery), 400);

    return () => clearTimeout(timeout);
  }, [searchTextQuery]);

  useEffect(() => {
    if (selectedValue) setNewContact(selectedValue);
  }, [selectedValue]);
  return (
    <FormControl
      sx={{
        ...autocompleteWrapperStyle,
        width: autocompleteWrapperStyle?.width ?? "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Autocomplete
        PaperComponent={AutocompleteList}
        noOptionsText={"Not Found"}
        disablePortal
        multiple
        limitTags={limitDisplayTags}
        loadingText="Looking For Company..."
        options={contactList}
        getOptionLabel={(option) =>
          typeof option === "string"
            ? option
            : `${option.firstName} ${option.lastName}`
        }
        renderTags={(value, getTagProps) => {
          return (
            <AnimatePresence>
              <LayoutGroup id={`autoCompleteMultiple#${labelText}`}>
                {value.map((option, index) => (
                  <AutocompleteContactValueTag
                    key={`assigned#${(option as Contact).contactId}`}
                    value={option as Contact}
                    index={index}
                    getTagProps={getTagProps}
                  />
                ))}
              </LayoutGroup>{" "}
            </AnimatePresence>
          );
        }}
        value={selectedValue}
        onChange={(event, value) => {
          handleAutoCompleteChange(value as Contact[]);
        }}
        placeholder={"Click to select a contact"}
        renderOption={(props, option, context) => {
          return (
            <li
              {...props}
              key={`listItem-` + context.index + `-` + option.contactId}
            >
              <StyledContactOption
                $themeColor={doxleThemeColor}
                $doxleFont={doxleFont}
                style={
                  optionStyle
                    ? { ...optionStyle }
                    : {
                        fontFamily: doxleFont.primaryFont,
                        fontWeight: 500,
                        fontSize: "1.2rem",
                        lineHeight: "normal",
                        letterSpacing: "0.0075em",
                        height: 30,

                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                      }
                }
                animate={{
                  color: context.selected
                    ? doxleThemeColor.doxleColor
                    : doxleThemeColor.primaryFontColor,
                }}
                whileHover={{
                  backgroundColor: doxleThemeColor.hoverColor,
                }}
                transition={{ duration: 0.2 }}
              >
                {option.firstName} {option.lastName}
              </StyledContactOption>
            </li>
          );
        }}
        freeSolo
        isOptionEqualToValue={(option, value) =>
          option.contactId === value.contactId
        }
        loading={getContactQuery.isLoading}
        renderInput={(params) => (
          <StyledContactsAutocompleteTextField
            {...params}
            $themeColor={doxleThemeColor}
            $doxleFont={doxleFont}
            variant="standard"
            label={labelText}
            sx={{
              "& .MuiInputLabel-root": {
                ...labelStyle,
              },
            }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <InputAdornment position="end">
                  {getContactQuery.isLoading ? (
                    <CircularProgress
                      size={20}
                      sx={{ color: doxleThemeColor.doxleColor }}
                    />
                  ) : null}
                </InputAdornment>
              ),
            }}
            inputRef={inputRef}
            onChange={(
              event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            ) => setSearchTextQuery(event.target.value)}
          />
        )}
      />
    </FormControl>
  );
};

export default DoxleContactAutocompleteMultiple;
