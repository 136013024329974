import {
  Mail,
  MailAttachment,
  MailConversationParam,
  MailQueryFilter,
  NewMail,
} from "../Models/mail";
import { useDoxleCurrentContextStore } from "../../DoxleGeneralStore/useDoxleCurrentContext";
import { shallow } from "zustand/shallow";
import { useDoxleAuthStore } from "../../DoxleGeneralStore/useDoxleAuthStore";
import useDoxleNotificationStore from "../../DoxleGeneralStore/useDoxleNotificationStore";
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import DoxleAPI from "../../Services/DoxleAPI";
import { useNavigate } from "react-router-dom";
import { AxiosResponse, isAxiosError } from "axios";
import { Company } from "../../Models/company";
import {
  AxiosBackendErrorReturn,
  IApiPaginatedData,
} from "../../Models/axiosReturn";
import useSetMailQueryDataQuery from "./useSetMailQueryData";
import { BaseAPIProps } from "../../Models/baseAPIProps";

const useRetrieveMailList = (queryFilter: MailQueryFilter) => {
  const { pollingRate, ...filter } = queryFilter;
  const company = useDoxleCurrentContextStore(
    (state) => state.currentCompany,
    shallow
  );
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );
  let getUrl = "/mail/?page=1&page_size=25";
  const qKey = formMailListQKey(company, filter);
  for (const [key, value] of Object.entries(filter)) {
    if (value) {
      getUrl += `&${key}=${value}`;
    }
  }
  return useInfiniteQuery(
    qKey,
    ({ pageParam = getUrl }) =>
      DoxleAPI.get<IApiPaginatedData<Mail>>(pageParam, {
        headers: {
          "User-Company": company?.companyId,
        },
      }),
    {
      enabled: Boolean(company && (queryFilter.project || queryFilter.docket)),
      retry: 1,
      refetchInterval: pollingRate * 1000,
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: true,
      staleTime: 5 * 1000,
      cacheTime: 10 * 60 * 1000,
      getNextPageParam: (prevData) => prevData.data?.next,
      onError: (error: any) => {
        if (showNotification) {
          if (isAxiosError<AxiosBackendErrorReturn>(error)) {
            showNotification(
              `${error?.response?.status ?? "ERROR"}: ${
                error.response?.data.detail ?? "UNKNOWN ERROR"
              }`,
              "error",
              String(
                error?.response?.data?.detail ?? "Error Fetching Mail List"
              ).substring(0, 300)
            );
          } else {
            showNotification(
              "Something Wrong!",
              "error",
              "Error Fetching Mail List"
            );
          }
        }
      },
    }
  );
};

interface GetMailAttachmentProps extends BaseAPIProps {
  id: string;
  isReply: boolean;
  enable: boolean;
}
interface GetMailAttachmentParam {
  mail?: string;
  replyMail?: string;
}
const useGetMailAttachment = ({
  id,
  isReply,
  showNotification,
  enable,
}: GetMailAttachmentProps) => {
  const company = useDoxleCurrentContextStore(
    (state) => state.currentCompany,
    shallow
  );

  const qkey: string[] = [];
  if (!isReply) {
    qkey.push("mail-attachment");
  } else {
    qkey.push("mail-reply-attachment");
  }
  if (id) {
    qkey.push(id);
  }
  // console.log(enable, id);

  return useQuery(
    qkey,
    () => {
      return DoxleAPI.get<IApiPaginatedData<MailAttachment>>(
        "/mail/attachment/",
        {
          params: isReply ? { reply_mail: id } : { mail: id },
          headers: {
            "User-Company": company?.companyId,
          },
        }
      );
    },
    {
      enabled: Boolean(company && id && enable),
      retry: 1,
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: false,

      onError: (error: any) => {
        if (showNotification) {
          if (isAxiosError<AxiosBackendErrorReturn>(error)) {
            showNotification(
              `${error?.response?.status ?? "ERROR"}: ${
                error.response?.data.detail ?? "UNKNOWN ERROR"
              }`,
              "error",
              String(
                error?.response?.data?.detail ??
                  "Error Fetching Mail Attachment"
              ).substring(0, 300)
            );
          } else {
            showNotification(
              "Something Wrong!",
              "error",
              "Error Fetching Mail Attachment"
            );
          }
        }
      },
    }
  );
};

const useUpdateMail = (mailId: string) => {
  const company = useDoxleCurrentContextStore(
    (state) => state.currentCompany,
    shallow
  );
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );

  const queryClient = useQueryClient();
  let patchUrl = `/mail/${mailId}/`;
  const qKey = ["mail-conversation", mailId];
  const mutationKey = ["update-mail", mailId];

  return useMutation(
    mutationKey,
    (body: Partial<Mail>) => {
      return DoxleAPI.patch<Mail>(patchUrl, body, {
        headers: {
          "User-Company": company?.companyId,
        },
      });
    },
    {
      retry: 1,
      onSuccess: (data: AxiosResponse<any>, variables, context) => {
        console.log("data", data);
        if (data.data)
          queryClient.setQueryData(qKey, (old: any) =>
            old
              ? {
                  ...old,
                  data: {
                    ...data.data,
                  },
                }
              : old
          );
      },
      onError: (error: any) => {
        if (showNotification) {
          if (isAxiosError<AxiosBackendErrorReturn>(error)) {
            showNotification(
              `${error?.response?.status ?? "ERROR"}: ${
                error.response?.data.detail ?? "UNKNOWN ERROR"
              }`,
              "error",
              String(
                error?.response?.data?.detail ?? "Error Sending Reply Mail"
              ).substring(0, 300)
            );
          } else {
            showNotification(
              "Something Wrong!",
              "error",
              "Error Sending Reply Mail"
            );
          }
        }
      },
    }
  );
};

interface SendMailQueryProps {
  queryFilter: MailQueryFilter;
  onSuccessCb?: (newMail?: Mail) => void;
}

const useSendNewEmailQuery = ({
  queryFilter,
  onSuccessCb,
}: SendMailQueryProps) => {
  const company = useDoxleCurrentContextStore(
    (state) => state.currentCompany,
    shallow
  );
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );
  const { handleAddMailQueryData } = useSetMailQueryDataQuery({
    filter: queryFilter,
  });
  const queryClient = useQueryClient();
  let postUrl = `/mail/`;
  const qKey = formMailListQKey(company, queryFilter);
  for (const [key, value] of Object.entries(queryFilter)) {
    if (value) qKey.push(value);
  }
  const mutationKey = ["new-mail"];

  return useMutation(
    mutationKey,
    (body: NewMail) => {
      let formData = new FormData();
      for (const [key, value] of Object.entries(body)) {
        if (typeof value === "string") formData.append(key, value);
        else if (Array.isArray(value))
          value.forEach((item, i) => formData.append(key, item));
      }

      return DoxleAPI.post<Mail>(postUrl, formData, {
        headers: {
          "User-Company": company?.companyId,
        },
      });
    },
    {
      retry: 1,
      onSuccess: (res, variables, context) => {
        handleAddMailQueryData(res.data);
        if (onSuccessCb) onSuccessCb(res.data);
      },
      onError: (err: any) => {
        if (err?.response?.status)
          if (showNotification) {
            showNotification(
              `${err?.response?.status ?? "ERROR"}: ${
                err?.response?.statusText ?? "Unknown Error"
              }`,
              "error",
              err?.response?.data ?? "Error Sending Mail"
            );
          }
      },
      onSettled: () => {
        queryClient.invalidateQueries(baseQKeyMailList);
      },
    }
  );
};

//# helper functions
export const baseQKeyMailList = ["mail-list"];
export const formMailListQKey = (
  company: Company | undefined,
  filter: Omit<MailQueryFilter, "pollingRate">
) => {
  const qKey = ["mail-list", company?.companyId ?? ""];
  if (company) qKey.push(company.companyId);
  for (const [key, value] of Object.entries(filter)) {
    if (value) {
      qKey.push(`${key}:${value}`);
    }
  }
  return qKey;
};

export const getMailMutationKey = (action: "add" | "update") => [
  `${action}-mail`,
];
const MailQueryAPI = {
  useRetrieveMailList,
  useUpdateMail,
  useSendNewEmailQuery,
  useGetMailAttachment,
};

export default MailQueryAPI;
