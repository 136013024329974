import styled from "styled-components";
import { TextField, Typography } from "@mui/material";
import {
  DoxleFont,
  DoxleThemeColor,
} from "../../DoxleGeneralStore/useDoxleThemeStore";

export const StyledQuoteDetailLabelText = styled(Typography)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
  $fontSize?: `${number}px` | `${number}rem`;
  $paddingRight?: `${number}px`;
  $opacity?: number;
}>`
  &.MuiTypography-root {
    font-family: ${(p) => p.$doxleFont.primaryTitleFont};
    color: ${(p) => p.$themeColor.primaryFontColor};
    font-style: normal;
    font-weight: 500;
    font-size: ${(p) => p.$fontSize || "1rem"};
    line-height: normal;
    letter-spacing: 0.5px;
    white-space: nowrap;
    padding-right: ${(p) => p.$paddingRight || 0};
    opacity: ${(p) => p.$opacity || 1};
    text-transform: uppercase;
    margin-bottom: 2px;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 18px;
  }
`;
export const StyledQuoteWacherSectionContainer = styled.div<{
  $marginTop?: number;
  $marginBottom?: number;
  $gap?: number;
}>`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${(p) => (p.$gap || 0) / 10}rem;
  margin-top: ${(p) => (p.$marginTop || 14) / 10}rem;
  margin-bottom: ${(p) => (p.$marginBottom || 14) / 10}rem;
  z-index: 4;
`;
export const StyledSelectTaskContainer = styled.div`
  background-color: ${(p) => p.theme.color.primaryReverseFontColor};
  color: ${(p) => p.theme.color.primaryFontColor};
  width: 240px;
  padding: 8px 10px;
  font-size: 1.4rem;
  border-radius: 8px;
  cursor: pointer;
`;
export const RFIAutoCompleteContainer = styled.div`
  min-width: 800px;
  margin-top: 40px;
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const QuoteAutoCompleteContainer = styled.div`
  min-width: 800px;
  margin-top: 2.7rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 3.8rem;
  justify-content: space-between;
`;
export const RFIAutoCompleteDiv = styled(TextField)``;

export const QuoteAutoCompleteIndividualBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  max-width: 266px;
  width: 100%;
`;

export const QuoteBottomHalfBox = styled.div<{
  textColor: string;
  boxColor: string;
}>`
  display: flex;
  background: ${(p) => p.boxColor};
  color: ${(p) => p.textColor};
  border-radius: 0px;
  border: 0px !important;
  height: 100%;
  padding-top: 4px;
`;
export const QuoteBottomHalfContainer = styled.div<{
  marginTop: string;
}>`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: ${(p) => p.marginTop};
`;
export const StyledEmptyListPlaceHolder = styled.div<{
  themeColor: DoxleThemeColor;
}>`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 22px;
  background-color: ${(p) => p.themeColor.primaryBackgroundColor};
  letter-spacing: 0.05em;
  color: ${(p) => p.themeColor.primaryFontColor};
`;

export const SelectedSupplierContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
`;

export const DatePickerContainer = styled.div<{
  $themeColor: DoxleThemeColor;
}>`
  display: flex;
  gap: 4rem;
  border-radius: 0.4rem;

  & .MuiTextField-root {
    min-width: 17.6rem !important;
    border-radius: 0.4rem;
  }
  & .MuiOutlinedInput-input {
    border-radius: 0.4rem;
    padding: 0.2rem !important;
    font-size: 1.3rem;
    padding-left: 0.5rem !important;
    border: 1px solid ${(p) => p.$themeColor.rowBorderColor} !important;
    color: ${(p) => p.$themeColor.primaryFontColor};
  }
  & .MuiOutlinedInput-notchedOutline-RGRbs {
    border-color: #eff0f4 !important;
    border: 1px !important;
  }
`;

export const AttachmentStyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const StyledQuoteAttachments = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;

  .img-wrapper {
    width: 3rem;
    min-width: 20px;
  }
`;

export const AttachmentWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  flex: 1;
  width: 100%;
  margin-top: 0.8rem;
`;

export const AddQuotesButtonWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: 10px;
  flex-direction: row;
  justify-content: end;
  width: 100%;
`;

export const AddQuotesButtonStyled = styled.button<{
  $doxleFont: DoxleFont;
  $themeColor: DoxleThemeColor;
  $cursor: string;
}>`
  min-width: 7.2rem;
  height: 2.3rem;
  border: 1px solid #e4e4ec;
  background-color: ${(p) => p.$themeColor.primaryContainerColor};
  color: ${(p) => p.$themeColor.primaryFontColor};
  font-family: ${(p) => p.$doxleFont.primaryFont};
  letter-spacing: 0.06rem;
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  padding: 0 0.5rem;
  cursor: ${(p) => p.$cursor};
  border-radius: 0.4rem;
  font-size: 1.4rem;
  font-weight: 600;
`;

export const StyledQuoteDateInput = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
`;

export const StyledSpinner = styled.div<{
  color?: string;
}>`
  width: 10px;
  height: 10px;
  border: 2px solid ${(p) => p.color ?? "#FFF"};
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const SkeletonRow = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
  margin-bottom: 0.8rem;
`;

export const SkeletonLoaderContent = styled.div<{
  $width?: string;
  $bgColor?: string;
}>`
  background: ${(p) => p.$bgColor};
  border-radius: 4px;
  height: 25px;
  position: relative;
  overflow: hidden;
  width: ${(p) => p.$width};

  &::before {
    content: "";
    display: block;
    position: absolute;
    left: -150px;
    top: 0;
    height: 100%;
    width: 150px;
    background: linear-gradient(
      to right,
      transparent 0%,
      #e8e8e8 50%,
      transparent 100%
    );
    animation: load 1s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  }

  @keyframes load {
    from {
      left: -150px;
    }
    to {
      left: 100%;
    }
  }
`;
