import React, { useState } from "react";
import { DoxleFile } from "../../../../Models/files";

import ExcelIcon from "../../../../Assets/Images/excel.png";
import WordIcon from "../../../../Assets/Images/word.png";
import PDFIcon from "../../../../Assets/Images/pdf.png";
import { useDoxleThemeStore } from "../../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import { CircularProgress } from "@mui/material";
import { LuImageOff, LuVideo } from "react-icons/lu";
import {
  StyledCommentAttachmentImage,
  StyledDiscussionAttachmentWrapper,
  StyledVideoThumbWrapper,
} from "./StyledComponentBookingDocketDetail";
import { useShallow } from "zustand/react/shallow";
import { FaPlay } from "react-icons/fa6";
import {
  getFileIconUrlByType,
  isImageFileByType,
} from "../../../../Utilities/FunctionUtilities";

type Props = {
  item: DoxleFile;
  itemIndex: number;
  handleClickImage?: (imgIndex: number) => void;
  handleClickVideo?: (videoUrl: string) => void;
};

const DiscussionAttachmentItem = ({
  item,
  itemIndex,
  handleClickImage,
  handleClickVideo,
}: Props) => {
  const [isLoadingImg, setIsLoadingImg] = useState(false);
  const [isErrorImg, setIsErrorImg] = useState(false);
  const { doxleThemeColor } = useDoxleThemeStore(
    useShallow((state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }))
  );
  const isItemImage = Boolean(item.fileType.toLowerCase().includes("image"));
  const isItemVideo = Boolean(item.fileType.toLowerCase().includes("video"));
  return (
    <StyledDiscussionAttachmentWrapper
      style={
        !isItemImage
          ? {
              border: `1px solid ${doxleThemeColor.rowBorderColor}`,
              display: "flex",
              backgroundColor: doxleThemeColor.primaryContainerColor,
              justifyContent: "center",
              alignItems: "center",
            }
          : undefined
      }
      onClick={() => {
        if (!isItemImage && !isItemVideo) window.open(item.url);
        else if (handleClickVideo) {
          handleClickVideo(item.url);
        } else if (handleClickImage) handleClickImage(itemIndex);
      }}
    >
      {!isItemVideo && (
        <StyledCommentAttachmentImage
          src={
            isImageFileByType(item.fileType)
              ? item.url
              : getFileIconUrlByType(item.fileType)
          }
          onLoadStart={() => setIsLoadingImg(true)}
          onLoadedData={() => setIsLoadingImg(false)}
          onError={() => {
            setIsErrorImg(true);
            console.log("url" + item.url);
            setIsLoadingImg(false);
          }}
        />
      )}

      {isItemVideo && (
        <StyledVideoThumbWrapper>
          {item.thumbUrl ? (
            <>
              <StyledCommentAttachmentImage
                src={item.thumbUrl}
                onLoadStart={() => setIsLoadingImg(true)}
                onLoadedData={() => setIsLoadingImg(false)}
              />

              <FaPlay className="icon-play" size={30} />
            </>
          ) : (
            <LuVideo color={doxleThemeColor.primaryFontColor} size={30} />
          )}
        </StyledVideoThumbWrapper>
      )}
      {isLoadingImg && (
        <CircularProgress
          size="1.6rem"
          style={{
            position: "absolute",
            zIndex: 10,
            color: doxleThemeColor.primaryFontColor,
            top: "calc(50% - 0.8rem)",
            left: "calc(50% - 0.8rem)",
          }}
        />
      )}

      {isErrorImg && (
        <LuImageOff
          size={"4rem"}
          color={doxleThemeColor.primaryFontColor}
          style={{
            position: "absolute",
            zIndex: 11,
            color: doxleThemeColor.primaryFontColor,
            top: "calc(50% - 2rem)",
            left: "calc(50% - 2rem)",
          }}
        />
      )}
    </StyledDiscussionAttachmentWrapper>
  );
};

export default React.memo(DiscussionAttachmentItem);
