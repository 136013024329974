import React from "react";
import { Docket } from "../../../Models/dockets";
import BookingDocketNumber from "../../CommonComponents/BookingDocketNumber";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { StyledBookingDocketNameText } from "./StyledComponentBookingProject";

type Props = {
  docket: Docket;
  isSelected: boolean;
};

const BookingDocketRow = ({ docket, isSelected }: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore((state) => ({
    doxleThemeColor: state.doxleThemeColor,
    doxleFont: state.doxleFont,
  }));
  return (
    <>
      <BookingDocketNumber
        docketNumber={docket.docketIdNum}
        docketIconSize="2rem"
        docketNumberTextStyle={{
          fontSize: "1rem",
          bottom: "-0.3rem",
          backgroundColor: doxleThemeColor.primaryContainerColor,
        }}
      />
      <StyledBookingDocketNameText
        $themeColor={doxleThemeColor}
        $doxleFont={doxleFont}
        $selected={isSelected}
      >
        {docket.docketName}
      </StyledBookingDocketNameText>
    </>
  );
};

export default BookingDocketRow;
