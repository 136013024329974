import { useLottie } from "lottie-react";
// import animationData from "./lotties/doxle-loader.json"; // wagging tail
import animationData from "./lotties/loadMore.json"; // running doggie
import { AnimatePresence, motion } from "framer-motion";

type Props = {
  animationSize?: number | string;
  containerStyle?: React.CSSProperties;
};

const ListLoadingMore = ({ animationSize = 144, containerStyle }: Props) => {
  const options = {
    animationData: animationData,
    loop: true,
    autoplay: true,
  };

  const style = {
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
    backgroundColor: "transparent",
    zIndex: 10,
  };

  const { View } = useLottie(options, {
    width:
      typeof animationSize === "number" ? `${animationSize}px` : animationSize,
  });

  return (
    <AnimatePresence>
      <motion.div
        initial={{
          y: 10,
          opacity: 0,
        }}
        animate={{
          y: 0,
          opacity: 1,
          transition: {
            duration: 0.4,
          },
        }}
        exit={{
          y: 10,
          opacity: 0,
          transition: {
            duration: 0.4,
          },
        }}
        style={containerStyle ?? style}
      >
        {View}
      </motion.div>
    </AnimatePresence>
  );
};

export default ListLoadingMore;
