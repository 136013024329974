import React from "react";
import { useDoxleAuthStore } from "../../../../DoxleGeneralStore/useDoxleAuthStore";
import { shallow } from "zustand/shallow";
import { useDoxleCurrentContextStore } from "../../../../DoxleGeneralStore/useDoxleCurrentContext";
import useDoxleNotificationStore from "../../../../DoxleGeneralStore/useDoxleNotificationStore";
import OrdersQueryAPI, {
  PatchOrderProps,
  getMutateOrderQKey,
} from "../../../QueryHooks/OrdersQueryAPI";
import { useEditOrderStore } from "../../../OrdersStore/useEditOrderStore";
import { useOrdersStore } from "../../../OrdersStore/useOrdersStore";
import dayjs from "dayjs";
import { formatTISODate } from "../../../../Utilities/FunctionUtilities";
import Order from "../../../Models/order";
import { useIsMutating } from "@tanstack/react-query";
import useSetOrderDetailQueryData from "../../../Hooks/useSetOrderDetailQueryData";
import { useEditOrderContext } from "../EditOrder";
import { produce } from "immer";

type Props = {};

interface EditOrderGeneralInfo {
  handleUpdateStartDate: (value: dayjs.Dayjs | null) => void;
  handleUpdateEndDate: (value: dayjs.Dayjs | null) => void;
  isUpdatingStartDate: boolean;
  isUpdatingEndDate: boolean;
}
const useEditOrderGeneralInfo = (props: Props): EditOrderGeneralInfo => {
  const company = useDoxleCurrentContextStore(
    (state) => state.currentCompany,
    shallow
  );
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );
  const editOrderContext = useEditOrderContext();
  const { setUpdatedOrder } = editOrderContext
    ? editOrderContext : {setUpdatedOrder: (x: any)=>{}}
  const { editedOrder, setEditedOrder, updateEditedOrder } = useEditOrderStore(
    (state) => ({
      editedOrder: state.editedOrder,
      setEditedOrder: state.setEditedOrder,
      updateEditedOrder: state.updateEditedOrder,
    }),
    shallow
  );
  const { queryFilter } = useOrdersStore(
    (state) => ({ queryFilter: state.queryFilter }),
    shallow
  );

  const { handleUpdatePartialOrder } = useSetOrderDetailQueryData({
    orderId: editedOrder?.orderId ?? "",
  });
  const onSuccessUpdateOrder = (order?: Order) => {
    if (order) {
      updateEditedOrder(order);
      handleUpdatePartialOrder(order);
    }
  };
  const { patchOrder } = OrdersQueryAPI.useMutateOrderQuery({
    company,
    showNotification,
    filter: queryFilter,
    onUpdateSuccessCb: onSuccessUpdateOrder,
  });

  const handleUpdateStartDate = (value: dayjs.Dayjs | null) => {
    if (editedOrder) {
      setUpdatedOrder(
        produce((draft) => {
          if (draft) {
            draft.startDate = value?formatTISODate(value.toDate()):null;
          } else
            draft = {
              startDate: value?formatTISODate(value.toDate()):null,
            };
          return draft;
        })
      );

      updateEditedOrder({
        startDate: value?formatTISODate(value.toDate()):null,
      });
      // patchOrder.mutate({
      //   orderId: editedOrder.orderId,
      //   order: {
      //     startDate: formatTISODate(value.toDate()),
      //   },
      // });
    }
  };
  const handleUpdateEndDate = (value: dayjs.Dayjs | null) => {
    if (editedOrder) {
      setUpdatedOrder(
        produce((draft) => {
          if (draft) {
            draft.endDate = value?formatTISODate(value.toDate()):null;
          } else
            draft = {
              endDate: value?formatTISODate(value.toDate()):null,
            };
          return draft;
        })
      );

      updateEditedOrder({
        endDate: value?formatTISODate(value.toDate()):null,
      });
      // patchOrder.mutate({
      //   orderId: editedOrder.orderId,
      //   order: {
      //     startDate: formatTISODate(value.toDate()),
      //   },
      // });
    }
  };

  const isUpdatingStartDate =
    useIsMutating({
      mutationKey: getMutateOrderQKey("update"),
      predicate: (query) =>
        Boolean(
          (query.state.variables as PatchOrderProps).orderId ===
            editedOrder?.orderId &&
            (query.state.variables as PatchOrderProps).order.startDate
        ),
    }) > 0;
  const isUpdatingEndDate =
    useIsMutating({
      mutationKey: getMutateOrderQKey("update"),
      predicate: (query) =>
        Boolean(
          (query.state.variables as PatchOrderProps).orderId ===
            editedOrder?.orderId &&
            (query.state.variables as PatchOrderProps).order.endDate
        ),
    }) > 0;
  return {
    handleUpdateStartDate,
    handleUpdateEndDate,
    isUpdatingStartDate,
    isUpdatingEndDate,
  };
};

export default useEditOrderGeneralInfo;
