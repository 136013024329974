import React, { useEffect, useState } from "react";
import useBookingProjectStore from "../../../../store/useBookingProjectStore";
import { shallow } from "zustand/shallow";

import { IntRange } from "../../../../../Models/IntRangeType";
import Order from "../../../../../Orders/Models/order";
import { Docket } from "../../../../../Models/dockets";
import { useDoxleCurrentContextStore } from "../../../../../DoxleGeneralStore/useDoxleCurrentContext";
import {
  AddBookingQueryParams,
  BookingQueryAPI,
} from "../../../../QueryAPI/bookingQueryAPI";
import { useDoxleAuthStore } from "../../../../../DoxleGeneralStore/useDoxleAuthStore";
import useDoxleNotificationStore from "../../../../../DoxleGeneralStore/useDoxleNotificationStore";
import { formatTISODate } from "../../../../../Utilities/FunctionUtilities";
import useBookingDocketStore from "../../../../store/useBookingDocketStore";
import { useEditDocketSideScreenStore } from "../../../../../CoreContent/EditDocketSideScreen/EditDocketSideScreenStore/useEditDocketSideScreenStore";

type Props = {};

interface AddBookingProject {
  handleBackBtn: () => void;
  newBookingTitle: string;
  setnewBookingTitle: React.Dispatch<React.SetStateAction<string>>;

  startDate: Date | null;
  setStartDate: React.Dispatch<React.SetStateAction<Date | null>>;
  endDate: Date | null;
  setEndDate: React.Dispatch<React.SetStateAction<Date | null>>;
  percentageComplete: IntRange<0, 101>;
  setPercentageComplete: React.Dispatch<React.SetStateAction<IntRange<0, 101>>>;
  selectedOrder: Order | undefined;
  setSelectedOrder: React.Dispatch<React.SetStateAction<Order | undefined>>;

  handleAddBooking: () => void;
  isAddingBooking: boolean;
  isAddDisable: boolean;
}
const useAddBookingDocket = (props: Props): AddBookingProject => {
  const [newBookingTitle, setnewBookingTitle] = useState("");
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [percentageComplete, setPercentageComplete] =
    useState<IntRange<0, 101>>(0);
  const [selectedOrder, setSelectedOrder] = useState<Order | undefined>(
    undefined
  );

  const { setOnAddBookingMode, filterBookingDocketQuery } =
    useBookingDocketStore(
      (state) => ({
        setOnAddBookingMode: state.setOnAddBookingMode,
        filterBookingDocketQuery: state.filterBookingDocketQuery,
      }),
      shallow
    );
  const { edittedDocket } = useEditDocketSideScreenStore(
    (state) => ({ edittedDocket: state.edittedDocket }),
    shallow
  );
  const handleBackBtn = () => {
    setOnAddBookingMode(false);
  };
  const { company } = useDoxleCurrentContextStore((state) => ({
    company: state.currentCompany,
  }));
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification
  );

  const onSuccessAdd = () => {
    setOnAddBookingMode(false);
  };
  const { add } = BookingQueryAPI.useMutateBookingQuery({
    company,

    showNotification,
    filter: filterBookingDocketQuery,
    onSuccessAddCb: onSuccessAdd,
  });

  const handleAddBooking = () => {
    if (newBookingTitle && edittedDocket) {
      const dataBooking: AddBookingQueryParams = {
        docket: edittedDocket.docketPk,
        project: edittedDocket.project,
        company: edittedDocket.company,
        title: newBookingTitle,
        percentageCompleted: percentageComplete,
      };
      if (startDate) dataBooking.startDate = formatTISODate(startDate);
      if (endDate) dataBooking.endDate = formatTISODate(endDate);
      if (selectedOrder) dataBooking.order = selectedOrder.orderId;

      add.mutate(dataBooking);
    }
  };

  const isAddDisable = Boolean(!newBookingTitle || !edittedDocket);

  return {
    handleBackBtn,
    newBookingTitle,
    setnewBookingTitle,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    percentageComplete,
    setPercentageComplete,
    selectedOrder,
    setSelectedOrder,

    handleAddBooking,
    isAddingBooking: add.isLoading,
    isAddDisable,
  };
};

export default useAddBookingDocket;
