import React, { useEffect, useMemo } from "react";
import { useEditDocketSideScreenStore } from "../../CoreContent/EditDocketSideScreen/EditDocketSideScreenStore/useEditDocketSideScreenStore";
import { shallow } from "zustand/shallow";
import { useProjectStore } from "../../DoxleGeneralStore/useprojectStore";
import { useDoxleCurrentContextStore } from "../../DoxleGeneralStore/useDoxleCurrentContext";
import { useMailStore } from "../Store/mailStore";
import { useMailSideScreenStore } from "../MailSideScreen/MailSideScreenStore/UseMailSideScreenStore";
import { MailQueryFilter } from "../Models/mail";
import { useIsMutating } from "@tanstack/react-query";

type Props = { docket?: true | undefined; project?: boolean };

interface IMail {
  handleSearchChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}
const useMail = ({ docket, project }: Props): IMail => {
  const { docketPk, setHandleAddButtonFn } = useEditDocketSideScreenStore(
    (state) => ({
      docketPk: state.edittedDocket?.docketPk,
      setHandleAddButtonFn: state.setHandleAddButtonFn,
    }),
    shallow
  );
  const projectSetNewButton = useProjectStore(
    (state) => state.setFunctionNewButton,
    shallow
  );
  const { projectId, currentSearchText } = useDoxleCurrentContextStore(
    (state) => ({
      projectId: state.currentProject?.projectId,
      currentSearchText: state.currentSearchText,
    }),
    shallow
  );
  const { setOnAddNewMailMode, setQueryFilter } = useMailStore(
    (state) => ({
      setQueryFilter: state.setQueryFilter,

      setOnAddNewMailMode: state.setOnAddNewMailMode,
    }),
    shallow
  );

  const { setCurrentDisplay } = useMailSideScreenStore(
    (state) => ({
      setCurrentDisplay: state.setCurrentDisplay,
      resetStore: state.resetStore,
    }),
    shallow
  );
  const openAddMailScreen = () => {
    setOnAddNewMailMode(true);
  };
  let searchTimeout: null | NodeJS.Timeout = null;
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    //! THIS SEARCH ONLY APPLY FOR DOCKET SCOPE
    if (searchTimeout) clearTimeout(searchTimeout);
    searchTimeout = setTimeout(() => {
      if (docket && docketPk) setQueryFilter({ search: e.target.value });
    }, 400);
  };
  const mailFilterProject: MailQueryFilter = useMemo(
    () => ({
      project: projectId,
      search: currentSearchText,
      docket: undefined,
      ordering: "-time_stamp",
      pollingRate: 60
    }),
    [projectId, currentSearchText]
  );
  const mailFilterDocket: MailQueryFilter = useMemo(
    () => ({
      docket: docketPk,
      ordering: "-time_stamp",
      project: undefined,
      pollingRate: 7
    }),
    [docketPk]
  );

  const isAddingItem = useIsMutating({ mutationKey: ["new-mail"] });
  useEffect(() => {
    if (docket && docketPk) {
      setQueryFilter(mailFilterDocket);
      setHandleAddButtonFn([
        { btnFunction: openAddMailScreen, isLoading: isAddingItem > 0 },
      ]);
    } else if (project && projectId) {
      setQueryFilter(mailFilterProject);
      projectSetNewButton([
        { btnFunction: openAddMailScreen, isLoading: isAddingItem > 0 },
      ]);
    } else setQueryFilter({ docket: undefined, project: undefined });
  }, [
    docket,
    docketPk,
    project,
    projectId,
    setQueryFilter,
    mailFilterProject,
    mailFilterDocket,
    isAddingItem,
  ]);

  useEffect(() => {
    return () => {
      setQueryFilter({});
    };
  }, []);

  return { handleSearchChange };
};

export default useMail;
