import { useQueryClient } from "@tanstack/react-query";
import React from "react";
import { User } from "../../../Models/user";
import { baseQKeyUserInfo } from "../../../Services/QueryHooks/userQueryAPI";
import { produce } from "immer";

type Props = {};

interface SetUserInfoQueryData {
  handleUpdateProjectQueryData: (user: User) => void;
}
const useSetUserInfoQueryData = (props: Props): SetUserInfoQueryData => {
  const queryClient = useQueryClient();

  const handleUpdateProjectQueryData = (user: User) => {
    const queryData = queryClient.getQueryData(baseQKeyUserInfo);
    if (queryData)
      queryClient.setQueryData(baseQKeyUserInfo, (old: any) => {
        if (old) {
          return produce(old, (draftOld: any) => {
            draftOld.data = user;
            return draftOld;
          });
        } else queryClient.invalidateQueries(baseQKeyUserInfo);
      });
    else queryClient.invalidateQueries(baseQKeyUserInfo);
  };
  return {
    handleUpdateProjectQueryData,
  };
};

export default useSetUserInfoQueryData;
