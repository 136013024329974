import { useEffect, useMemo } from "react";
import { useDoxleCurrentContextStore } from "../../DoxleGeneralStore/useDoxleCurrentContext";
import useDoxleNotificationStore from "../../DoxleGeneralStore/useDoxleNotificationStore";
import { useFileStore } from "../Store/useFileStore";
import { shallow } from "zustand/shallow";
import FilesQueryAPI from "../QueryHooks/FileStorageQueryAPI";
import { DoxleFile } from "../../Models/files";
import { useShallow } from "zustand/react/shallow";

type Props = {};
interface IGetFileQueryRoot {
  fileListSuccess: DoxleFile[];
  isFetchingFileList: boolean;
  isSuccessFetchingFileList: boolean;
  isErrorFetchingFileList: boolean;
  handleFetchNextPage: () => void;
  isFetchingNextPage: boolean;
}
const useGetFileQueryRoot = (props: Props): IGetFileQueryRoot => {
  const { company } = useDoxleCurrentContextStore((state) => ({
    company: state.currentCompany,
  }));

  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification
  );
  const { filterRootFileQuery } = useFileStore(
    useShallow((state) => ({
      filterRootFileQuery: state.filterRootFileQuery,
    }))
  );

  const getFileListQuery = FilesQueryAPI.useGetFilesQuery({
    company,
    showNotification,

    filter: filterRootFileQuery,
  });

  const fileListSuccess = useMemo(
    () =>
      getFileListQuery.isSuccess
        ? getFileListQuery.data?.pages.reduce((acc, data) => {
            return acc.concat(data.data.results);
          }, [] as DoxleFile[])
        : [],
    [getFileListQuery.data]
  );

  return {
    fileListSuccess,
    isFetchingFileList: getFileListQuery.isLoading,
    isSuccessFetchingFileList: getFileListQuery.isSuccess,
    isErrorFetchingFileList: getFileListQuery.isError,
    handleFetchNextPage: () => {
      if (getFileListQuery.hasNextPage) getFileListQuery.fetchNextPage();
    },
    isFetchingNextPage: getFileListQuery.isFetchingNextPage,
  };
};

export default useGetFileQueryRoot;
