import { create } from "zustand";
import { produce } from "immer";

import { useEffect } from "react";
export type TAnnotationTools =
  | "Arrow"
  | "Pointer"
  | "Rectangle"
  | "Text"
  | "Circle"
  | "StraightLine";
export type TAnnotationColors =
  | "#216BFF"
  | "#FF7C04"
  | "#FF2222"
  | "#27BF36"
  | "#5A36BE"
  | "#FFBA35";

export interface Circle {
  x: number;
  y: number;

  r: number;
  color: TAnnotationColors & Omit<string, TAnnotationColors>;
  type: "Circle";
}

export interface StraightLine {
  x1: number;
  y1: number;
  x2: number;
  y2: number;
  color: TAnnotationColors & Omit<string, TAnnotationColors>;
  type: "StraightLine";
}

export interface Label {
  x: number;
  y: number;
  text: string;

  color: TAnnotationColors & Omit<string, TAnnotationColors>;
  type: "Text";
}
export interface IAxisPos {
  x: number;
  y: number;
}
export interface Rectangle {
  x: number;
  y: number;
  w: number;
  h: number;
  color: TAnnotationColors & Omit<string, TAnnotationColors>;
  type: "Rectangle";
}
export interface Arrow {
  x1: number;
  y1: number;
  x2: number;
  y2: number;
  color: TAnnotationColors & Omit<string, TAnnotationColors>;
  type: "Arrow";
}
interface StageState {
  stageScale: number;
  stageX: number;
  stageY: number;
}
export type TQAMarkupShape = Circle | StraightLine | Label | Rectangle | Arrow;
interface QAMarkupStore {
  oldMarkup: TQAMarkupShape[];
  setOldMarkup: (value: TQAMarkupShape[]) => void;
  addedMarkups: TQAMarkupShape[];
  addMarkup: (addedMarkup: TQAMarkupShape) => void;
  undoMarkup: () => void;
  clearAllMarkup: () => void;
  setaddedMarkups: (addedMarkups: TQAMarkupShape[]) => void;

  currentShape: TQAMarkupShape | null;
  setCurrentShape: (shape: TQAMarkupShape | null) => void;
  updateCurrentShape: (
    data:
      | Partial<Circle>
      | Partial<StraightLine>
      | Partial<Label>
      | Partial<Rectangle>
      | Partial<Arrow>
  ) => void;
  currentTool: TAnnotationTools;
  setCurrentTool: (tool: TAnnotationTools) => void;
  currentColor: TAnnotationColors;
  setCurrentColor: (color: TAnnotationColors) => void;

  stageState: StageState;
  setStageState: (stageState: StageState) => void;

  resetStore: () => void;
}

export const useQAMarkupStore = create<QAMarkupStore>((set, get) => ({
  oldMarkup: [],
  setOldMarkup: (value: TQAMarkupShape[]) =>
    set((state) =>
      produce(state, (draft) => {
        draft.oldMarkup = value;
        return draft;
      })
    ),

  addedMarkups: [],
  addMarkup: (addedMarkup) =>
    set((state) =>
      produce(state, (draft) => {
        draft.addedMarkups.push(addedMarkup);
        return draft;
      })
    ),
  undoMarkup: () =>
    set((state) =>
      produce(state, (draft) => {
        if (draft.addedMarkups.length > 0) {
          draft.addedMarkups.pop();
        } else {
          if (draft.oldMarkup) draft.oldMarkup.pop();
        }
        return draft;
      })
    ),
  clearAllMarkup: () =>
    set((state) =>
      produce(state, (draft) => {
        draft.addedMarkups = [];
        draft.oldMarkup = [];

        return draft;
      })
    ),
  setaddedMarkups: (addedMarkups) =>
    set((state) =>
      produce(state, (draft) => {
        draft.addedMarkups = addedMarkups;
        return draft;
      })
    ),
  currentShape: null,
  setCurrentShape: (
    shape: Circle | StraightLine | Label | Rectangle | Arrow | null
  ) =>
    set((state) =>
      produce(state, (draft) => {
        draft.currentShape = shape;
        return draft;
      })
    ),
  updateCurrentShape: (
    data:
      | Partial<Circle>
      | Partial<StraightLine>
      | Partial<Label>
      | Partial<Rectangle>
      | Partial<Arrow>
  ) =>
    set((state) =>
      produce(state, (draft) => {
        if (draft.currentShape) {
          Object.assign(draft.currentShape, data);
        }
        return draft;
      })
    ),
  currentTool: "Pointer",
  setCurrentTool: (tool) =>
    set((state) =>
      produce(state, (draft) => {
        draft.currentTool = tool;
        return draft;
      })
    ),

  currentColor: "#216BFF",
  setCurrentColor: (color) =>
    set((state) =>
      produce(state, (draft) => {
        draft.currentColor = color;
        return draft;
      })
    ),

  resetStore: () =>
    set((state) =>
      produce(state, (draft) => {
        draft.oldMarkup = [];
        draft.addedMarkups = [];
        draft.currentTool = "Pointer";
        return draft;
      })
    ),
  stageState: { stageScale: 1, stageX: 0, stageY: 0 },
  setStageState: (stageState: StageState) => set({ stageState: stageState }),
}));
