import React, { useEffect, useState } from "react";
import { useProjectStore } from "../../../DoxleGeneralStore/useprojectStore";
import { shallow } from "zustand/shallow";
import useBookingProjectStore from "../../store/useBookingProjectStore";
import { BookingQueryAPI, getBookingMutationKey } from "../../QueryAPI/bookingQueryAPI";
import { useDoxleAuthStore } from "../../../DoxleGeneralStore/useDoxleAuthStore";
import { useDoxleCurrentContextStore } from "../../../DoxleGeneralStore/useDoxleCurrentContext";
import useDoxleNotificationStore from "../../../DoxleGeneralStore/useDoxleNotificationStore";
import useBookingDocketStore from "../../store/useBookingDocketStore";
import { useEditDocketSideScreenStore } from "../../../CoreContent/EditDocketSideScreen/EditDocketSideScreenStore/useEditDocketSideScreenStore";
import { useIsMutating } from "@tanstack/react-query";

type Props = {};

interface BookingProject {
  handleDeleteBooking: () => void;
  handleCloseDeleteDialog: () => void;
}
const useBookingProject = (props: Props): BookingProject => {
  const { setHandleAddButtonFn } = useEditDocketSideScreenStore(
    (state) => ({
      setHandleAddButtonFn: state.setHandleAddButtonFn,
    }),
    shallow
  );
  const {
    setOnAddBookingMode,
    setEdittedBooking,
    deletedProjectBooking,
    setDeletedProjectBooking,
    filterBookingDocketQuery,
    resetBookingDocketStore,
  } = useBookingDocketStore(
    (state) => ({
      setOnAddBookingMode: state.setOnAddBookingMode,
      setEdittedBooking: state.setEdittedBooking,
      deletedProjectBooking: state.deletedProjectBooking,
      setDeletedProjectBooking: state.setDeletedProjectBooking,
      filterBookingDocketQuery: state.filterBookingDocketQuery,
      resetBookingDocketStore: state.resetBookingDocketStore,
    }),
    shallow
  );
  const { company } = useDoxleCurrentContextStore(
    (state) => ({
      company: state.currentCompany,
    }),
    shallow
  );
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );
  const { destroy } = BookingQueryAPI.useMutateBookingQuery({
    company,


    filter: filterBookingDocketQuery,
    showNotification,
  });
  const handleCloseDeleteDialog = () => {
    setDeletedProjectBooking(undefined);
  };
  const handleDeleteBooking = () => {
    if (deletedProjectBooking) destroy.mutate(deletedProjectBooking.bookingId);
    handleCloseDeleteDialog();
  };

  const isAddingItem = useIsMutating({mutationKey: getBookingMutationKey("add")});

  useEffect(() => {
    setHandleAddButtonFn([{
      btnFunction: () => {
        setOnAddBookingMode(true);
        setEdittedBooking(undefined);
      },
      isLoading: isAddingItem > 0
    }]);

    return () => {
      setHandleAddButtonFn([]);
      resetBookingDocketStore();
    };
  }, [isAddingItem]);

  return { handleDeleteBooking, handleCloseDeleteDialog };
};

export default useBookingProject;
