import React, { useEffect, useState } from "react";
import "./Measurement.css";
import { Paper } from "@mui/material";
import ToolBar from "./ToolBar";
import ScaleBar from "./ScaleBar";
// import DrawingsNavbar from "./DrawingsNavBar";
import KonvaComponent from "./Konva";
import DrawingPlaceholder from "./DrawingPlaceholder";
import DrawingSetDialog from "./DrawingSetDialog";
import { shallow } from "zustand/shallow";
import { useMeasurementsStore } from "../MeasurementsStore/useMeasurementsStore";
import DrawingsNavbar from "./DrawingsNavBar";
import { TakeOff } from "../Models/takeOffs";
import useMeasurements from "../Hooks/useMeasurements";
import Loading from "../../Utilities/Lottie/Loading";
import { useDoxleCurrentContextStore } from "../../DoxleGeneralStore/useDoxleCurrentContext";

interface Props {
  takeOffId?: string;
}
const Measurement = ({ takeOffId }: Props) => {
  // const [editDrawingDialogOpen, setEditDrawingDialogOpen] = useState<boolean>(false);
  const project = useDoxleCurrentContextStore(
    (state) => state.currentProject,
    shallow
  );
  const { takeOffQuery, drawingsQuery, drawingsList, takeOff } =
    useMeasurements({ takeOffId });
  const { setSheet, currentSheet, scalingMode, setCurrentTakeOff } =
    useMeasurementsStore(
      (state) => ({
        setSheet: state.setCurrentSheet,
        currentSheet: state.currentSheet,
        scalingMode: state.isScaling,
        setCurrentTakeOff: state.setCurrentTakeOff,
      }),
      shallow
    );

  useEffect(() => {
    setCurrentTakeOff(takeOff);
  }, [takeOff, setCurrentTakeOff]);

  useEffect(() => {
    const lastMeasurement = takeOff?.measurements
      ? takeOff.measurements[takeOff.measurements?.length - 1]
      : null;
    if (lastMeasurement) {
      let complete: boolean = false;
      for (let d = 0; d < drawingsList.length; d++) {
        for (let s = 0; s < drawingsList[d].sheets.length; s++) {
          if (drawingsList[d].sheets[s].sheetId === lastMeasurement.sheet) {
            setSheet(drawingsList[d].sheets[s]);
            complete = true;
            break;
          }
        }
        if (complete) break;
      }
      if (!complete) setSheet(drawingsList?.[0]?.sheets[0]);
    } else {
      setSheet(drawingsList?.[0]?.sheets[0]);
    }
  }, [takeOff, drawingsList]);

  if (takeOffQuery.isFetching || drawingsQuery.isFetching)
    return (
      <Loading
        text={
          takeOffQuery.isFetching
            ? "Fetching take off detail"
            : "Fetching drawings"
        }
      />
    );
  if (!takeOff) return <>Unable to identify your takeoff</>;
  if (!project) return <>Unable to identify your Current Project</>;
  if (drawingsList.length === 0) {
    const timeOut = setTimeout(() => {
      window.open(
        `${window.location.protocol}//${window.location.host}/Project/${project?.projectId}/Drawings/`,
        "_self"
      );
    }, 3000);
    return (
      <>
        Your project may not have any drawings, please upload some. You will be
        redirected in 3 seconds
      </>
    );
  }
  return (
    <div style={{ outline: "none", overflowY: "hidden" }}>
      <Paper style={{ overflow: "hidden" }}>
        <DrawingsNavbar drawingsList={drawingsList} />
        {scalingMode && currentSheet && <ScaleBar />}

        <ToolBar />
        {currentSheet ? <KonvaComponent /> : <DrawingPlaceholder />}
      </Paper>
      {/*{currentDrawings && (*/}
      {/*  <DrawingSetDialog*/}
      {/*    open={editDrawingDialogOpen}*/}
      {/*    closeAction={() => setEditDrawingDialogOpen(false)}*/}
      {/*    saveAction={updateDrawings}*/}
      {/*    drawingSet={currentDrawings}*/}
      {/*    deleteDrawing={deleteDrawing}*/}
      {/*    deleteSheet={deleteSheet}*/}
      {/*  />*/}
      {/*)}*/}
    </div>
  );
};

export default Measurement;
