import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import React, { useCallback, useMemo, useRef } from "react";
import {
  StyledDocketMailHeader,
  StyledDoxleMailScreenContainer,
  StyledMailHead,
  StyledMailList,
  StyledMailListScroller,
  StyledMailRootContainer,
  StyledMailSearchBar,
  StyledMailTitleSection,
} from "./styledCompnents";
import { useMailStore } from "../Store/mailStore";
import { Mail } from "../Models/mail";
import MailItem from "./MailItem";
import { Components, Virtuoso, VirtuosoHandle } from "react-virtuoso";

import DoxleAddNewButton from "../../DoxleDesignPattern/DoxleButtons/DoxleAddNewButton";
import DoxleListSkeleton from "../../DoxleDesignPattern/DoxleSkeleton/DoxleListSkeleton";
import MailConversation from "./MailConversation/mailConversation";
import ListLoadingMore from "../../Utilities/Lottie/ListLoadingMore";
import { AnimatePresence } from "framer-motion";
import DoxleEmptyPlaceHolder from "../../DoxleDesignPattern/DoxleEmptyPlaceHolder/DoxleEmptyPlaceHolder";
import MailSideScreen from "../MailSideScreen/MailSideScreen";
import useMail from "../Hooks/useMail";
import useGetMailList from "../Hooks/useGetMailList";
import AddMail from "./SendNewMail/AddMail";
import { Error404Banner } from "../../DoxleDesignPattern/DoxleIcons/CommonIcons";
import { EmptyMailBanner } from "./MailIcon";

interface Props {
  docket?: true | undefined;
  project?: boolean;
}

interface MailListContext {}
const MailPage = ({ docket, project }: Props) => {
  const mailListRef = useRef<VirtuosoHandle>(null);

  const themeProps = useDoxleThemeStore(
    (state) => ({
      $themeColor: state.doxleThemeColor,
      $doxleFont: state.doxleFont,
      $currentTheme: state.currentTheme,
    }),
    shallow
  );
  const {
    $mailTheme,

    addNew,
    toggleAddNew,

    viewMail,
  } = useMailStore(
    (state) => ({
      $mailTheme: state.mailTheme,

      addNew: state.onAddNewMailMode,
      toggleAddNew: state.setOnAddNewMailMode,
      viewMail: state.viewMail,
    }),
    shallow
  );

  const { handleSearchChange } = useMail({ docket, project });
  const {
    mailList,
    mailCount,
    isFetchingMailList,
    isSuccessFetchingMailList,
    isErrorFetchingMailList,
    isFetchingMoreMailList,
    fetchMoreMailList,
  } = useGetMailList({});
  const styleProps = {
    ...themeProps,
    $themeColor: {
      ...themeProps.$themeColor,
      ...$mailTheme,
    },
  };

  const itemContent = useCallback(
    (index: number, item: Mail) => (
      <MailItem mailItem={item} key={item.mailId} />
    ),
    []
  );

  const components: Components<Mail, MailListContext> = useMemo(
    () => ({
      Scroller: React.forwardRef(({ children, ...props }, ref) => (
        <StyledMailListScroller
          style={{
            ...props.style,
          }}
          ref={ref}
          {...props}
        >
          <AnimatePresence>{children}</AnimatePresence>
        </StyledMailListScroller>
      )),
      EmptyPlaceholder: () => (
        <DoxleEmptyPlaceHolder
          headTitleText={
            isErrorFetchingMailList ? "Something Wrong" : "No Mail Item "
          }
          subTitleText={
            isErrorFetchingMailList
              ? "Failed to get data, please try to reload the page"
              : "Create a new one or remove your filters"
          }
          containerBgColor="transparent"
          // headTitleTextStyle={{ fontSize: 18 }}
          illustration={
            isErrorFetchingMailList ? (
              <Error404Banner themeColor={styleProps.$themeColor} />
            ) : (
              <EmptyMailBanner
                themeColor={styleProps.$themeColor}
                containerStyle={{ marginBottom: 12 }}
              />
            )
          }
        />
      ),
    }),
    []
  );

  if (viewMail) return <MailConversation mail={viewMail} project={project} />;
  return (
    <StyledMailRootContainer {...styleProps}>
      <StyledDoxleMailScreenContainer
        $themeColor={styleProps.$themeColor}
        $project={project}
        $docket={docket}
      >
        {docket && (
          <StyledDocketMailHeader {...styleProps}>
            <StyledMailSearchBar
              {...styleProps}
              onChange={handleSearchChange}
              placeholder={"Search Mail..."}
            />
            {mailCount} mails found
          </StyledDocketMailHeader>
        )}
        {!(docket || project) && (
          <>
            <StyledMailTitleSection $themeColor={styleProps.$themeColor}>
              {addNew ? "Send New Mail" : `Mails(${mailCount})`}
            </StyledMailTitleSection>
            <StyledMailHead $themeColor={styleProps.$themeColor}>
              <StyledMailSearchBar
                {...styleProps}
                onChange={handleSearchChange}
                placeholder={"Search Mail..."}
              />
              <DoxleAddNewButton
                btnText={addNew ? "Back" : undefined}
                showBtnIcon={false}
                onClick={() => toggleAddNew(!addNew)}
              />
            </StyledMailHead>
          </>
        )}
        {/* <MailFilter/> */}
        <AddMail project={project} docket={docket} />
        <MailSideScreen />
        {/*<StyledMailList $themeColor={styleProps.$themeColor}>*/}
          {isFetchingMailList ? (
            <DoxleListSkeleton
              containerWidthInRatio={"100%"}
              containerHeightInRatio={"100%"}
              // containerBgColor={styleProps.$themeColor.primaryBackgroundColor}
              numOfRows={20}
              skeletonType={"mailRow"}
            />
          ) : (
            <Virtuoso
              style={{ height: "100%" }}
              overscan={50}
              endReached={fetchMoreMailList}
              // followOutput="smooth"
              ref={mailListRef}
              data={mailList}
              itemContent={itemContent}
              context={{}}
              components={components}
            />
          )}
        {/*</StyledMailList>*/}
        {isFetchingMoreMailList && (
          <ListLoadingMore
            containerStyle={{
              position: "absolute",
              bottom: 0,
              left: 0,
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 10,
            }}
            animationSize={120}
          />
        )}
      </StyledDoxleMailScreenContainer>
    </StyledMailRootContainer>
  );
};
export default MailPage;
