import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import { useMemo, useState } from "react";
import Order from "../../Models/order";
import {
  StyledOrderTableRow,
  StyledOrderNumberTableCell,
  StyledOrderRowWrapper,
  StyledOrderRowCheckbox,
} from "./StyledComponents";
import { formatter } from "../../../Utilities/FunctionUtilities";
import { useOrdersStore } from "../../OrdersStore/useOrdersStore";
import { useEditOrderStore } from "../../OrdersStore/useEditOrderStore";
import { IoDocumentOutline } from "react-icons/io5";
import { HiOutlineTrash } from "react-icons/hi2";
import {
  DoxleCheckedIcon,
  DoxleUncheckedIcon,
} from "../../../DoxleDesignPattern/DoxleGeneralIcons";
import { LuMailCheck, LuBadgeX, LuBadgeCheck } from "react-icons/lu";
import { useShallow } from "zustand/react/shallow";

interface Props {
  order: Order;
}

type OrderCellField =
  | "orderNumber"
  | "supplier"
  | "description"
  | "subtotal"
  | "payments"
  | "balance";
const ORDER_FIELD_DISPLAY: OrderCellField[] = [
  "orderNumber",
  "supplier",
  "description",
  "subtotal",
  "payments",
  "balance",
];

const OrderTableRow = ({ order }: Props) => {
  const [isHoverRow, setIsHoverRow] = useState(false);
  const { setDeletedOrder, showPayments, selectedOrders, toggleSelectedOrder } =
    useOrdersStore(
      useShallow((state) => ({
        showPayments: state.showPayments,
        setDeletedOrder: state.setDeletedOrder,
        selectedOrders: state.selectedOrders,
        toggleSelectedOrder: state.toggleSelectedOrder,
      }))
    );
  const { doxleThemeColor, doxleFont, transparentMode, currentTheme } =
    useDoxleThemeStore(
      useShallow((state) => ({
        doxleThemeColor: state.doxleThemeColor,
        doxleFont: state.doxleFont,
        transparentMode: state.transparentMode,
        currentTheme: state.currentTheme,
      }))
    );
  const { setEditedOrder } = useEditOrderStore(
    useShallow((state) => ({
      setEditedOrder: state.setEditedOrder,
    }))
  );

  const handleOrderRowClick = () => {
    setEditedOrder(order);
  };
  const handleClickDeleteIcon = (
    e: React.MouseEvent<SVGElement, MouseEvent>
  ) => {
    e.preventDefault();
    e.stopPropagation();
    setDeletedOrder(order);
  };
  const signOffText = order.signedOff
    ? order.signedOffName.split(" ")
    : undefined;

  const isOrderSelected = useMemo(
    () =>
      Boolean(
        selectedOrders.find((selected) => selected.orderId === order.orderId)
      ),
    [selectedOrders, order]
  );
  return (
    <StyledOrderRowWrapper
      $themeColor={doxleThemeColor}
      onMouseEnter={() => setIsHoverRow(true)}
      onMouseLeave={() => setIsHoverRow(false)}
    >
      {/* <StyledOrderDragIconContainer> */}

      <StyledOrderRowCheckbox
        disableRipple
        checked={isOrderSelected}
        onChange={(e) => toggleSelectedOrder(order)}
        icon={
          <DoxleUncheckedIcon
            themeColor={doxleThemeColor}
            containerStyle={{
              width: 16,
            }}
          />
        }
        checkedIcon={
          <DoxleCheckedIcon
            themeColor={doxleThemeColor}
            containerStyle={{
              width: 16,
            }}
          />
        }
      />
      <StyledOrderTableRow
        $themeColor={doxleThemeColor}
        $transparentMode={transparentMode}
        $currentTheme={currentTheme}
        onClick={handleOrderRowClick}
        initial={{
          y: -10,
          opacity: 0,
        }}
        animate={{
          y: 0,
          opacity: 1,
        }}
      >
        {ORDER_FIELD_DISPLAY.map((field, idx) =>
          (field === "payments" || field === "balance") &&
          !showPayments ? null : (
            <StyledOrderNumberTableCell
              layout="position"
              $widthRatio={
                showPayments
                  ? field === "subtotal" || field === "payments"
                    ? 12
                    : field === "balance"
                    ? 10
                    : field === "orderNumber"
                    ? 15
                    : field === "supplier"
                    ? 18
                    : 33
                  : field === "subtotal"
                  ? 10
                  : field === "orderNumber"
                  ? 18
                  : field === "supplier"
                  ? 22
                  : 50
              }
              key={`order#${order.orderId}#${idx}`}
              $textColor={
                field === "orderNumber" ? doxleThemeColor.doxleColor : undefined
              }
              $paddingLeft={
                field === "description" ||
                field === "subtotal" ||
                field === "balance"
                  ? 20
                  : undefined
              }
              $horizontalAlign={
                field !== "subtotal" && field !== "balance"
                  ? "flex-start"
                  : "space-between"
              }
            >
              {field === "orderNumber" && (
                <>
                  <div className="order-icon-wrapper">
                    {signOffText && (
                      <div className="sign-order-text">
                        {signOffText[0] ?? ""}
                      </div>
                    )}
                    <IoDocumentOutline
                      size={25}
                      color={doxleThemeColor.primaryFontColor}
                    />
                  </div>

                  <span> {order.orderNumber}</span>
                  {order.emailSent && order.accepted === null && (
                    <LuMailCheck
                      style={{
                        fontSize: "20px",
                        color: doxleThemeColor.primaryFontColor,
                        marginLeft: 10,
                        strokeWidth: "1.5px",
                        flexShrink: 0,
                      }}
                    />
                  )}
                  {order.accepted && (
                    <LuBadgeCheck
                      style={{
                        fontSize: "20px",
                        color: doxleThemeColor.primaryFontColor,
                        marginLeft: 10,
                        strokeWidth: "1.5px",
                        flexShrink: 0,
                      }}
                    />
                  )}
                  {order.accepted === false && (
                    <LuBadgeX
                      style={{
                        fontSize: "20px",
                        color: doxleThemeColor.primaryFontColor,
                        marginLeft: 10,
                        strokeWidth: "1.5px",
                        flexShrink: 0,
                      }}
                    />
                  )}
                </>
              )}

              {field === "supplier" && <span>{order.supplier}</span>}
              {field === "description" && <span>{order.description}</span>}
              {field === "subtotal" && (
                <>
                  <span>{formatter.format(Number(order.subTotal))}</span>

                  {isHoverRow && !showPayments && (
                    <>
                      {/* {signOffText && (
                        <div className="sign-order-text">
                          {signOffText[0][0] ?? ""} {signOffText[1][0] ?? ""}
                        </div>
                      )} */}
                      <HiOutlineTrash
                        style={{
                          marginLeft: "4px",
                          color: "red",
                          fontSize: "16px",
                          cursor: "pointer",
                          float: "right",
                          flexShrink: 0,
                        }}
                        onClick={handleClickDeleteIcon}
                      />
                    </>
                  )}
                </>
              )}
              {field === "payments" && (
                <span className={"payments"}>
                  (-)&nbsp; &nbsp;
                  {formatter.format(Number(order.totalPaymentsMade ?? 0))}
                </span>
              )}
              {field === "balance" && (
                <>
                  <span>
                    {formatter.format(
                      Number(order.subTotal) -
                        Number(order.totalPaymentsMade ?? 0)
                    )}
                  </span>
                  {isHoverRow && showPayments && (
                    <>
                      {/* {signOffText && (
                        <div className="sign-order-text">
                          {signOffText[0][0] ?? ""} {signOffText[1][0] ?? ""}
                        </div>
                      )} */}

                      <HiOutlineTrash
                        style={{
                          marginLeft: "0.4rem",
                          color: "red",
                          fontSize: "1.6rem",
                          cursor: "pointer",
                          float: "right",
                        }}
                        onClick={handleClickDeleteIcon}
                      />
                    </>
                  )}
                </>
              )}
            </StyledOrderNumberTableCell>
          )
        )}
      </StyledOrderTableRow>
    </StyledOrderRowWrapper>
  );
};

export default OrderTableRow;
