import React, { useMemo, useState } from "react";
import Order, { OrderHistory } from "../../Models/order";
import {
  StyledOrderHistoryHeaderCell,
  StyledOrderHistoryLineCell,
  StyledOrderHistoryLineRow,
  StyledOrderHistoryTableContainer,
  StyledOrderHistoryTitle,
} from "./StyledComponents";
import {
    Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { User } from "../../../Models/user";
import dayjs from "dayjs";
import { es } from "date-fns/locale";

type Props = {
  orderHistory: OrderHistory[];
};

const ORDER_HISTORY_TABLE_HEADER = ["Action", "Detail", "User", "Time"];

const OrderHistoryTable = ({ orderHistory }: Props) => {
  //   const [displayAll, setDisplayAll] = useState(false);
  const [showIndex, setShowIndex] = useState(4);
  const historyList = useMemo(() => {
    return [...orderHistory].reverse().slice(0, showIndex);
  }, [showIndex, orderHistory]);
  const rowVariants = useMemo(
    () => ({
      initial: {
        x: -10,
        opacity: 0,
        scaleY: 0,
      },
      entering: {
        x: 0,
        opacity: 1,
        scaleY: 1,
      },
    }),
    []
  );
  if (orderHistory.length === 0) {
    return;
  }
  return (
    <StyledOrderHistoryTableContainer>
      <StyledOrderHistoryTitle>Order History</StyledOrderHistoryTitle>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            {ORDER_HISTORY_TABLE_HEADER.map((item, index) => (
              <StyledOrderHistoryHeaderCell key={index}>
                {item}
              </StyledOrderHistoryHeaderCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {historyList.map((history, index) => (
            <StyledOrderHistoryLineRow
              key={index}
              variants={rowVariants}
              initial="initial"
              animate="entering"
              transition={{
                duration: 0.2,
              }}
            >
              <StyledOrderHistoryLineCell $width="20%">
                {history.shortText}
              </StyledOrderHistoryLineCell>
              <StyledOrderHistoryLineCell $width="40%">
                {history.longText}
              </StyledOrderHistoryLineCell>
              <StyledOrderHistoryLineCell $width="20%">
                {history.user?.firstName} {history.user?.lastName}
              </StyledOrderHistoryLineCell>
              <StyledOrderHistoryLineCell $width="20%">
                {dayjs(history.timeStamp).format("YYYY-MM-DD HH:mm")}
              </StyledOrderHistoryLineCell>
            </StyledOrderHistoryLineRow>
          ))}
        </TableBody>
      </Table>
      {showIndex < orderHistory.length && (
        <Button
          onClick={() => {
            setShowIndex(showIndex + 3);
          }}
          className="display-all-tag"
          variant="text"
          style={{fontSize: 14, marginTop: 5, textTransform:"capitalize"}}
        >
          Show More...
        </Button>
      )}
    </StyledOrderHistoryTableContainer>
  );
};

export default React.memo(OrderHistoryTable);
