import { StyledNoticeBoardTitleContainer } from "./StyledNoticeBoardContent";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { DoxleNBIcon } from "../../../DoxleDesignPattern/DoxleGeneralIcons";

export interface INoticeBoardNavProps {}

export default function NoticeBoardTitle(props: INoticeBoardNavProps) {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore((state) => ({
    doxleThemeColor: state.doxleThemeColor,
    doxleFont: state.doxleFont,
  }));
  return (
    <StyledNoticeBoardTitleContainer
      $themeColor={doxleThemeColor}
      $doxleFont={doxleFont}
    >
      <DoxleNBIcon
        themeColor={doxleThemeColor}
        containerStyle={{ width: "30px", marginRight: 8 }}
      />
      <span>Notice Board</span>
    </StyledNoticeBoardTitleContainer>
  );
}
