import React from "react";
import { IQRUserLog } from "../../Models/qrLog";
import { StyledQRSummaryItem } from "./StyledQRLogSummary";
import dayjs from "dayjs";

type Props = {
  logItem: IQRUserLog;
};

const QRSummaryItem = ({ logItem }: Props) => {
  return (
    <StyledQRSummaryItem $isInvalid={!Boolean(logItem.exitTime)}>
      <div className="date-text">
        {" "}
        {dayjs(logItem.entryTime).format("DD MMM YYYY")}
      </div>

      <div className="time-field">
        {dayjs(logItem.entryTime).format("hh:mm a")}
      </div>

      <div className="time-field">
        {logItem.exitTime ? dayjs(logItem.exitTime).format("hh:mm a") : "-"}
      </div>

      <div className="total-field">
        {logItem.exitTime
          ? (
              dayjs(logItem.exitTime).diff(logItem.entryTime) /
              (1000 * 60 * 60)
            ).toFixed(2)
          : "-"}
      </div>
    </StyledQRSummaryItem>
  );
};

export default QRSummaryItem;
