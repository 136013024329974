import React, { memo, useEffect } from "react";
import { IBooking } from "../../Models/booking";
import {
  StyledBookingContentTextField,
  StyledBookingRowContainer,
} from "./StyledBookingCalendar";
import { motion } from "framer-motion";
import DoxleCheckbox from "../../../DoxleDesignPattern/DoxleCheckbox/DoxleCheckbox";
import {
  BookingCheckedIcon,
  BookingUncheckedIcon,
} from "../../CommonComponents/BookingsIcon";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import useBookingItem from "../Hooks/useBookingItem";
import dayjs from "dayjs";
import CircularProgress from "@mui/material/CircularProgress";
import {
  DoxleMailIcon,
  DoxleSMSIcon,
} from "../../../DoxleDesignPattern/DoxleGeneralIcons";

type Props = {
  bookingItem: IBooking;
  dateCellValue: Date;
};

const BookingItem: React.FC<Props> = memo(
  ({ bookingItem, dateCellValue }: Props) => {
    const { doxleThemeColor } = useDoxleThemeStore();
    const {
      onClickCheckbox,
      isBookingMatchStartOrEnd,
      onClickBookingRow,
      isDeletingBooking,
      isUpdatingBooking,
      handleSendBookingAlert,
    } = useBookingItem({
      bookingItem,
      dateCellValue,
    });

    return (
      <StyledBookingRowContainer
        $isStartOrEnd={isBookingMatchStartOrEnd}
        // layout="position"
        // transition={{
        //   damping: 20,
        //   mass: 0.1,
        //   stiffness: 120,
        // }}
        $isCompleted={!!bookingItem.completed}
        onClick={onClickBookingRow}
      >
        {(isDeletingBooking || isUpdatingBooking) && (
          <CircularProgress
            size={16}
            style={{
              marginRight: 8,
              color: isDeletingBooking
                ? "red"
                : doxleThemeColor.primaryFontColor,
            }}
          />
        )}
        <motion.span className="title-text">
          {bookingItem.title}
          {!isBookingMatchStartOrEnd
            ? `${dayjs(bookingItem.startDate).format("(DD")} - ${dayjs(
                bookingItem.endDate
              ).format("DD MMM YY)")}`
            : ""}
        </motion.span>

        <motion.div
          className="menu-hover"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <DoxleMailIcon
            themeColor={doxleThemeColor}
            onDivClick={handleSendBookingAlert}
            containerStyle={{
              flexShrink: 0,
              width: 20,
              marginRight: 4,
            }}
          />
          <DoxleSMSIcon
            themeColor={doxleThemeColor}
            onDivClick={handleSendBookingAlert}
            containerStyle={{
              flexShrink: 0,
              width: 20,
            }}
          />
          <DoxleCheckbox
            labelStyle={{
              margin: 0,
            }}
            checkedIcon={
              <BookingCheckedIcon
                themeColor={doxleThemeColor}
                containerStyle={{
                  width: 20,
                }}
              />
            }
            icon={
              <BookingUncheckedIcon
                themeColor={doxleThemeColor}
                containerStyle={{
                  width: 20,
                }}
              />
            }
            checked={!!bookingItem.completed}
            alternateIcon={true}
            onChangeCallback={onClickCheckbox}
          />
        </motion.div>
      </StyledBookingRowContainer>
    );
  }
);

export default BookingItem;
