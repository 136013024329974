import React from "react";
import { QA, QAWithFirstImg } from "../../../Models/qa";
import {
  StyledQAItemContentWrapper,
  StyledQAItemImageWrapper,
  StyledQAItemToggleMenuWrapper,
} from "./StyledComponentQAListDetail";
import { useDoxleThemeStore } from "../../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import { QADeleteIcon, QAItemEmptyImageIcon } from "../../../QAIcons";
import dayjs from "dayjs";
import useQAItemRow from "./Hooks/useQAItemRow";
import CircularProgress from "@mui/material/CircularProgress";
import { LuImageOff } from "react-icons/lu";
import { FaCheck } from "react-icons/fa6";
import { CgClose } from "react-icons/cg";
import { LiaTrashSolid } from "react-icons/lia";
import { AnimatePresence, motion } from "framer-motion";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import { useShallow } from "zustand/react/shallow";

var advancedFormat = require("dayjs/plugin/advancedFormat");
dayjs.extend(advancedFormat);

type Props = {
  qaItem: QAWithFirstImg;
  setdeletedQAItem: React.Dispatch<React.SetStateAction<QA | undefined>>;
};

const IconButtonRef = React.forwardRef<HTMLButtonElement, IconButtonProps>(
  (props, ref) => <IconButton {...props} ref={ref} />
);
const AnimatedIconButton = motion(IconButtonRef);
const QAItemRow = ({ qaItem, setdeletedQAItem }: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    useShallow((state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }))
  );
  const {
    isLoadingImg,
    isImgError,
    onLoadImgStart,
    onLoadImgEnd,
    onLoadImgError,
    setIsHoverRow,
    isHoverRow,
    handleUpdateStatusQAItem,
    isUpdatingQAItem,
  } = useQAItemRow({ qaItem });
  return (
    <>
      {isUpdatingQAItem && (
        <CircularProgress
          size={20}
          style={{ color: doxleThemeColor.primaryFontColor, marginRight: 8 }}
        />
      )}
      <StyledQAItemImageWrapper
        $themeColor={doxleThemeColor}
        $doxleFont={doxleFont}
        onMouseEnter={() => setIsHoverRow(true)}
        onMouseLeave={() => setIsHoverRow(false)}
        layout="position"
        transition={{ duration: 0.2, damping: 16 }}
      >
        {qaItem.firstImage ? (
          <>
            {isLoadingImg && (
              <CircularProgress className="img-loader" size={16} />
            )}
            {!isImgError ? (
              <img
                src={qaItem.firstImage}
                className="qa-image-holder"
                onLoadStart={onLoadImgStart}
                onError={onLoadImgError}
                onLoadedData={onLoadImgEnd}
              />
            ) : (
              <LuImageOff color={doxleThemeColor.primaryFontColor} size={30} />
            )}
          </>
        ) : (
          <>
            <QAItemEmptyImageIcon />
            <span className="empty-placeholder-text">Add Image</span>
          </>
        )}
      </StyledQAItemImageWrapper>
      <StyledQAItemContentWrapper
        onMouseEnter={() => setIsHoverRow(true)}
        onMouseLeave={() => setIsHoverRow(false)}
        layout
      >
        <div className="qa-title">{`${qaItem.index}. ${qaItem.description}`}</div>
        <div className="assignee-date-section">
          <span
            className="text-content"
            style={{ textTransform: "capitalize" }}
          >
            {qaItem.assigneeName || "No Assignee"}
          </span>

          <span className="text-content">
            {qaItem.dueDate
              ? dayjs(qaItem.dueDate).format("MMM Do, YYYY")
              : "No Due Date"}
          </span>
        </div>

        <div className="comment-section">{qaItem.lastComment?.commentText}</div>
      </StyledQAItemContentWrapper>
      <StyledQAItemToggleMenuWrapper
        $themeColor={doxleThemeColor}
        onMouseEnter={() => setIsHoverRow(true)}
        onMouseLeave={() => setIsHoverRow(false)}
      >
        <AnimatePresence>
          {isHoverRow && (
            <>
              <AnimatedIconButton
                className="menu-button"
                animate={{
                  opacity: [0, 1],
                }}
                exit={{
                  opacity: [1, 0],
                }}
                transition={{
                  duration: 0.2,
                }}
                onClick={handleUpdateStatusQAItem}
              >
                {qaItem.status === "Completed" ? (
                  <CgClose size="25px" className="menu-icon" />
                ) : (
                  <FaCheck size="25px" className="menu-icon" />
                )}
              </AnimatedIconButton>
              <AnimatedIconButton
                className="menu-button"
                animate={{
                  opacity: [0, 1],
                }}
                exit={{
                  opacity: [1, 0],
                }}
                transition={{
                  duration: 0.2,
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  setdeletedQAItem(qaItem);
                }}
              >
                <QADeleteIcon />
              </AnimatedIconButton>
            </>
          )}
        </AnimatePresence>
      </StyledQAItemToggleMenuWrapper>
    </>
  );
};

export default React.memo(QAItemRow);
