import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Contact } from "../../../../../Models/addressBook";
import { useProjectQAStore } from "../../../Store/useProjectQAStore";
import { shallow } from "zustand/shallow";
import { useDoxleAuthStore } from "../../../../../DoxleGeneralStore/useDoxleAuthStore";
import { useDoxleCurrentContextStore } from "../../../../../DoxleGeneralStore/useDoxleCurrentContext";
import ContactsAPI from "../../../../../Services/QueryHooks/contactsAPI";
import { ContactsFilters } from "../../../../../Services/QueryHooks/contactsFilters";
import QAQueryAPI from "../../../../QueryAPI/qaQueryAPI";
import useDoxleNotificationStore from "../../../../../DoxleGeneralStore/useDoxleNotificationStore";
import { QA, QAWithFirstImg } from "../../../../Models/qa";

type Props = {};

interface EditQaAssignee {
  anchorAssigneeList: HTMLElement | null;
  setAnchorAssigneeList: React.Dispatch<
    React.SetStateAction<HTMLElement | null>
  >;
  handleUpdateAssignee: (selectedAssignee: Contact | undefined) => void;
  handleCloseAssigneeList: () => void;
  isUpdatingAssignee: boolean;
  isHovering: boolean;
  setIsHovering: React.Dispatch<React.SetStateAction<boolean>>;
}
const useEditQaAssignee = (props: Props): EditQaAssignee => {
  const [isHovering, setIsHovering] = useState(false);
  const [anchorAssigneeList, setAnchorAssigneeList] =
    useState<HTMLElement | null>(null);
  const { selectedQAItem, updateSelectedQAItem } = useProjectQAStore(
    (state) => ({
      selectedQAItem: state.selectedQAItem,
      updateSelectedQAItem: state.updateSelectedQAItem,
    }),
    shallow
  );

  const company = useDoxleCurrentContextStore(
    (state) => state.currentCompany,
    shallow
  );
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );

  const onUpdateSuccess = (newQA?: QAWithFirstImg) => {
    if (newQA)
      updateSelectedQAItem({
        assignee: newQA.assignee,
        assigneeName: newQA.assigneeName,
        assigneeJson: newQA.assigneeJson,
      });
  };
  const updateQAQuery = QAQueryAPI.useUpdateQAQuery({
    company,
    qaItem: selectedQAItem!,
    showNotification,
    onSuccessCB: onUpdateSuccess,
  });

  const handleUpdateAssignee = useCallback(
    (selectedAssignee: Contact | undefined) => {
      if (selectedAssignee)
        updateQAQuery.mutate({
          assignee: selectedAssignee.contactId,
          assigneeName: `${selectedAssignee.firstName} ${selectedAssignee.lastName}`,
        });
      else updateQAQuery.mutate({ assignee: null, assigneeName: "" });

      setAnchorAssigneeList(null);
    },
    []
  );
  const handleCloseAssigneeList = useCallback(() => {
    setAnchorAssigneeList(null);
  }, []);
  return {
    anchorAssigneeList,
    setAnchorAssigneeList,
    handleUpdateAssignee,
    handleCloseAssigneeList,
    isUpdatingAssignee: updateQAQuery.isLoading,
    isHovering,
    setIsHovering,
  };
};

export default useEditQaAssignee;
