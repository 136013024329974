import { motion } from "framer-motion";
import styled from "styled-components";
import {
  DoxleFont,
  DoxleTheme,
  DoxleThemeColor,
} from "../../../../DoxleGeneralStore/useDoxleThemeStore";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { editRgbaAlpha } from "../../../../Utilities/FunctionUtilities";
import TableFooter from "@mui/material/TableFooter";
import TextField, { TextFieldProps } from "@mui/material/TextField";

export const StyledQAProjectListContainer = styled(motion.div)`
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;

  .list-content-wrapper {
    flex: 1;
    width: 100%;
    display: flex;
    position: relative;
  }
`;

export const StyledQAProjectListSkeletonContainer = styled.div`
  width: 100%;
  height: calc(100% - 2rem);
  padding-top: 2rem;
  display: flex;
  flex-direction: column;

  .skel-header-wrapper {
    width: calc(100% - 2.8rem);
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.4rem;
    padding: 0px 1.4rem;
  }
`;

export const StyledQAListHeaderContainer = styled(motion(TableRow))`
  && {
    width: 100% !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: ${(p) => p.theme.staticMenuColor.staticBg};
    height: 30px;
  }
`;

export const StyledQAListTableHeaderCell = styled(TableCell)<{
  $widthRatio: `${number}%` | null;
  $horizontalAlign?: "flex-start" | "center" | "flex-end";
}>`
  && {
    ${(p) => (p.$widthRatio ? `width: ${p.$widthRatio};` : "flex:1;")}
    display: flex;
    height: 100%;
    padding: 0px !important;
    justify-content: ${(p) =>
      p.$horizontalAlign ? p.$horizontalAlign : "center"};
    align-items: center;
    cursor: pointer;
    color: ${(p) => p.theme.staticMenuColor.staticFontColor};
    text-transform: capitalize;

    border: none !important;
  }

  .cell-header-text {
    color: ${(p) => p.theme.staticMenuColor.staticFontColor};

    font-family: ${(p) => p.theme.font.primaryFont};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;

    letter-spacing: -0.024rem;
    text-transform: capitalize;
  }
`;

export const StyledQAListRowContainer = styled(motion(TableCell))`
  && {
    width: calc(100%) !important;
    display: flex;
    flex-direction: row;
    padding: 0px;

    height: calc(40px);
    cursor: pointer;
    position: relative;
    z-index: 1;
    border-bottom: 1px solid ${(p) => p.theme.color.primaryDividerColor};
    &:hover {
      background-color: ${(p) => p.theme.color.rowHoverColor};
      transition: 0.4s ease all;
    }
    transition: 0.4s ease all;
    background-color: ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.theme.color.primaryContainerColor,
        alpha: p.theme.transparentMode ? "0" : "1",
      })};
  }
`;
export const StyleQAListDataCell = styled.div<{
  $widthRatio: `${number}%` | null;

  $horizontalAlign?: "flex-start" | "center" | "flex-end";
}>`
  height: 100%;
  display: flex;
  justify-content: ${(p) =>
    p.$horizontalAlign ? p.$horizontalAlign : "center"};
  padding: 0px 4px !important;
  align-items: center;
  font-family: ${(p) => p.theme.font.primaryFont};
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.7px;
  color: ${(p) => p.theme.color.primaryFontColor};
  text-transform: capitalize;

  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  ${(p) => (p.$widthRatio ? `width: calc(${p.$widthRatio} - 8px);` : "flex:1;")}
  .complete-icon-wrapper {
    margin: 0px 2px;
  }
  .qa-list-icon-wrapper {
    cursor: pointer;
    position: relative;
    padding: 4px;
    padding-right: 0px;
    transition: 0.2s ease all;
    &:hover {
      transform: scale(1.1);
      transition: 0.2s ease all;
    }
    .expand-icon {
      position: absolute;
      z-index: 1;
      bottom: 4px;
      left: 4px;
      transform: translate(-20%, 20%);
      background-color: ${(p) => p.theme.color.primaryContainerColor};
    }
  }
  .title-text-content {
    color: ${(p) => p.theme.color.primaryFontColor};
    font-family: ${(p) => p.theme.font.secondaryTitleFont};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    text-align: left;
    margin-left: 4px;
    letter-spacing: -0.24px;
    flex-shrink: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .regular-content-text {
    color: ${(p) => p.theme.color.primaryFontColor};

    font-family: ${(p) => p.theme.font.primaryFont};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
  }
`;

export const StyledQAListSubmenuContainer = styled(motion(TableCell))<{}>`
  && {
    border: none !important;
    width: calc(100% - 3rem - 4px);
    display: flex;
    flex-direction: column;
    padding: 0.8rem 0px 0.8rem calc(3rem + 4px) !important;
    cursor: pointer;
    position: relative;
    z-index: 1;
  }
`;

export const StyledQAListSubmenuItem = styled(motion.div)<{}>`
  align-self: flex-start;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.8rem 0px;
  cursor: pointer;
  transition: 0.2s;

  .menu-text {
    color: inherit;

    font-family: ${(p) => p.theme.font.secondaryTitleFont};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.055rem;
    margin-left: 4px;
  }

  .adobe-img-holder {
    width: 14px;
  }
`;

export const StyledQAListFooterContainer = styled(TableRow)<{
  $themeColor: DoxleThemeColor;
}>`
  && {
    width: 100%;
    display: flex;
    height: 4rem;
    background-color: ${(p) => p.$themeColor.primaryBackgroundColor};
  }
`;
interface ICustomTextFieldProps
  extends Omit<
    TextFieldProps<"standard">,
    "onDragStart" | "onAnimationStart" | "onDragEnd" | "onDrag"
  > {
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}
export const StyledAddQAListTextField = styled(
  ({ $themeColor, $doxleFont, ...props }: ICustomTextFieldProps) => (
    <TextField {...props} />
  )
)`
  && {
    width: calc(100%);
    padding: 0.8rem 0px;
    border-bottom: 1px solid ${(p) => p.$themeColor.primaryDividerColor};
  }
  input {
    color: ${(p) => p.$themeColor.primaryFontColor};
    font-size: 1.4rem;
    font-family: ${(p) => p.$doxleFont.primaryTitleFont};
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.65px;
    padding: 0px !important;
    &::placeholder {
      color: ${(p) => p.$themeColor.primaryFontColor};
      font-size: 1.4rem;
      font-family: ${(p) => p.$doxleFont.primaryFont};
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.65px;
      padding: 0px !important;
    }
  }
  & .MuiOutlinedInput-root {
    padding: 0px 0.8rem !important;
  }
  & .MuiOutlinedInput-input {
    padding: 0px !important;
  }
  & .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }

  & .MuiInput-root:before {
    border: none !important;
  }
  & .MuiInput-root:after {
    border: none !important;
  }
`;
