import { QAComment } from "../../../Models/qa";
import React, { useEffect, useState } from "react";
import useDoxleNotificationStore from "../../../../DoxleGeneralStore/useDoxleNotificationStore";
import { shallow } from "zustand/shallow";
import { useDoxleAuthStore } from "../../../../DoxleGeneralStore/useDoxleAuthStore";
import { useDoxleCurrentContextStore } from "../../../../DoxleGeneralStore/useDoxleCurrentContext";
import { useProjectQAStore } from "../../Store/useProjectQAStore";
import QAQueryAPI from "../../../QueryAPI/qaQueryAPI";
import {
  StyledDoxleDialogHelperActions,
  StyledDoxleDialogHelperContainer,
  StyledDoxleDialogHelperContent,
  StyledDoxleDialogHelperEditButton,
  StyledDoxleDialogHelperText,
  StyledDoxleDialogHelperTitle,
} from "../../../../DoxleDesignPattern/DoxleDialogHelper/StyledComponentDoxleDialogHelper";
import { editRgbaAlpha } from "../../../../Utilities/FunctionUtilities";
import { useDoxleThemeStore } from "../../../../DoxleGeneralStore/useDoxleThemeStore";
import { StyledCommentTextField } from "./StyledComponentQAItemDetail";

interface Props {
  comment?: QAComment;
  setEditComment: React.Dispatch<React.SetStateAction<QAComment | undefined>>;
}

const EditCommentTextDialog = ({ comment, setEditComment }: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }),
    shallow
  );
  const [newText, setNewText] = useState<string>(comment?.commentText ?? "");
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );
  const company = useDoxleCurrentContextStore(
    (state) => state.currentCompany,
    shallow
  );
  const qaItem = useProjectQAStore((state) => state.selectedQAItem, shallow);
  const handleClose = () => {
    setEditComment(undefined);
    setNewText("");
  };
  const mutateQuery = QAQueryAPI.useMutateQACommentQuery({
    showNotification,
    company,
    qaItem,
    onSuccessCB: handleClose,
  });
  const handleUpdate = () => {
    if (comment)
      mutateQuery.patch.mutate({
        commentId: comment.commentId,
        commentText: newText,
      });
  };
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (
      e.key === "Enter" &&
      !(e.altKey || e.metaKey || e.shiftKey || e.ctrlKey)
    )
      handleUpdate();
    if (e.key === "Escape") handleClose();
  };
  useEffect(() => {
    setNewText(comment?.commentText ?? "");
  }, [comment]);
  return (
    <StyledDoxleDialogHelperContainer
      $themeColor={doxleThemeColor}
      open={Boolean(comment)}
      onClose={handleClose}
      PaperProps={{
        sx: {
          bgColor: doxleThemeColor.primaryContainerColor,
          borderRadius: "9px",
          border: `1px solid ${doxleThemeColor.primaryDividerColor}`,
          width: "50vw",
          minWidth: 300,
        },
        elevation: 2,
      }}
      slotProps={{
        backdrop: {
          sx: {
            bgcolor: editRgbaAlpha({
              rgbaColor: doxleThemeColor.primaryBackgroundColor,
              alpha: "0.8",
            }),
          },
        },
      }}
    >
      <StyledDoxleDialogHelperTitle
        $themeColor={doxleThemeColor}
        $doxleFont={doxleFont}
      >
        Edit Comment Text
      </StyledDoxleDialogHelperTitle>

      <StyledDoxleDialogHelperContent $themeColor={doxleThemeColor}>
        <StyledCommentTextField
          $themeColor={doxleThemeColor}
          $doxleFont={doxleFont}
          value={newText}
          onChange={(e) => setNewText(e.currentTarget.value)}
          onKeyDown={handleKeyDown}
          multiline
          onFocus={(e) => e.currentTarget.select()}
          minRows={3}
          maxRows={12}
        />
      </StyledDoxleDialogHelperContent>

      <StyledDoxleDialogHelperActions $themeColor={doxleThemeColor}>
        <StyledDoxleDialogHelperEditButton
          key={`helperBtn#editCommentCancel`}
          $themeColor={doxleThemeColor}
          $buttonIndex={0}
          $doxleFont={doxleFont}
          onClick={handleClose}
          layout="position"
          $bgColor={doxleThemeColor.primaryContainerColor}
          sx={{
            border: `1px solid ${doxleThemeColor.primaryDividerColor}`,
          }}
        >
          <span
            className="button-text"
            style={{ color: doxleThemeColor.primaryFontColor }}
          >
            Cancel
          </span>
        </StyledDoxleDialogHelperEditButton>
        <StyledDoxleDialogHelperEditButton
          key={`helperBtn#editCommentConfirm`}
          $themeColor={doxleThemeColor}
          $buttonIndex={1}
          $doxleFont={doxleFont}
          onClick={handleUpdate}
          layout="position"
          disabled={!Boolean(comment)}
          sx={{
            border: `1px solid ${doxleThemeColor.primaryDividerColor}`,
          }}
        >
          <span className="button-text">Confirm</span>
        </StyledDoxleDialogHelperEditButton>
      </StyledDoxleDialogHelperActions>
    </StyledDoxleDialogHelperContainer>
  );
};
export default EditCommentTextDialog;
