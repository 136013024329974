import { shallow } from "zustand/shallow";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import {
  StyledAddAttachmentButton,
  StyledAddedAttachmentWrapper,
  StyledDialogActionBtnWrapper,
  StyledEmailContentWrapper,
  StyledEmailFieldWrapper,
  StyledEmailBookingDialog,
  StyledEmailBookingTitle,
  StyledMailActionSubmitButton,
  StyledMailBookingSubjectTextField,
  StyledMailTextBodySection,
  StyledTextModeButton,
} from "./Components/StyledComponents";
import { editRgbaAlpha } from "../../Utilities/FunctionUtilities";
import React, { createContext, useContext, useMemo, useRef } from "react";
import { TransitionProps } from "@mui/material/transitions";
import Slide from "@mui/material/Slide";
import { Contact } from "../../Models/addressBook";
import { useDoxleAuthStore } from "../../DoxleGeneralStore/useDoxleAuthStore";
import useEmailBooking from "./Hooks/useEmailBooking";
import { NewMail } from "../../Mail/Models/mail";
import { TISODate } from "../../Models/dateFormat";
import { useDropzone } from "react-dropzone";
import AddMailToSection from "./Components/AddMailToSection";
import AddMailCCSection from "./Components/AddMailCCSection";
import { AnimatePresence } from "framer-motion";
import DialogActions from "@mui/material/DialogActions";
import ProgressScreen from "../../Utilities/Lottie/ProgressScreen";

import {
  DoxleAlignModeIcon,
  DoxleBoldModeIcon,
  DoxleItalicModeIcon,
  DoxleQuoteModeIcon,
} from "../../DoxleDesignPattern/DoxleGeneralIcons";
import AddedAttachmentItem from "./Components/AddedAttachmentItem";
import { ImAttachment } from "react-icons/im";
import { useShallow } from "zustand/react/shallow";

interface Props {}
interface IEmailBookingContextValue {
  attachments: File[];
  setAttachments: React.Dispatch<React.SetStateAction<File[]>>;
  watchers: Contact[];
  handleUpdateNewMail: (props: {
    key: keyof NewMail;
    value: TISODate | string;
  }) => void;

  handleAddWatcher: (value: Contact) => void;
  handleRemoveWatcher: (value: Contact) => void;
  handleClickAddBtn: () => void;
  newMail: NewMail;
  ballInCourt: Contact[];
  setBallInCourt: React.Dispatch<React.SetStateAction<Contact[]>>;
  handleAddBallInCourt: (value: Contact) => void;
  handleRemoveBallInCourt: (value: Contact) => void;
}

const EmailBookingContext = createContext<IEmailBookingContextValue | null>(
  null
);
const EmailBooking = ({}: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore((state) => ({
    doxleThemeColor: state.doxleThemeColor,
    doxleFont: state.doxleFont,
  }));
  const { user } = useDoxleAuthStore(
    useShallow((state) => ({ user: state.user }))
  );

  const {
    booking,
    setBooking,
    attachments,
    setAttachments,
    watchers,
    handleUpdateNewMail,
    handleAddWatcher,
    handleRemoveWatcher,
    handleClickAddBtn,
    newMail,
    ballInCourt,
    setBallInCourt,
    boldMode,
    setBoldMode,
    italicMode,
    setItalicMode,
    onDrop,
    isAddingEmail,
    handleAddBallInCourt,
    handleRemoveBallInCourt,
  } = useEmailBooking();
  const mailTextBodyInputRef = useRef<HTMLInputElement>(null);
  const dropzoneRef = useRef<HTMLDivElement>(null);
  const { getRootProps, open } = useDropzone({
    onDrop,
    accept: {
      "image/png": [".png"],
      "image/jpg": [".jpg"],
      "image/jpeg": [".jpeg"],
      "application/pdf": [".pdf"],
      "application/msword": [".doc"],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        [".docx"],
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
        ".xlsx",
      ],
    },

    multiple: true,
    noClick: true,
  });
  const handleAddAttachmentBtn = () => {
    if (dropzoneRef.current) {
      open();
    }
  };

  const handleAddQuote = () => {
    if (mailTextBodyInputRef.current) {
      const { selectionStart, selectionEnd } = mailTextBodyInputRef.current;

      if (selectionStart && selectionEnd && selectionStart !== selectionEnd) {
        const beforeSelection = newMail.textBody.slice(0, selectionStart);
        const selectedText = newMail.textBody.slice(
          selectionStart,
          selectionEnd
        );
        const afterSelection = newMail.textBody.slice(selectionEnd);
        const newText = beforeSelection + `"${selectedText}"` + afterSelection;
        handleUpdateNewMail({ key: "textBody", value: newText });
      }
    }
  };
  const contextValue: IEmailBookingContextValue = useMemo(
    () => ({
      attachments,
      setAttachments,
      watchers,
      handleUpdateNewMail,

      handleAddWatcher,
      handleRemoveWatcher,
      handleClickAddBtn,
      newMail,
      ballInCourt,
      setBallInCourt,
      handleAddBallInCourt,
      handleRemoveBallInCourt,
    }),
    [
      attachments,
      setAttachments,
      watchers,
      handleUpdateNewMail,

      handleAddWatcher,
      handleRemoveWatcher,
      handleClickAddBtn,
      newMail,
      ballInCourt,
      setBallInCourt,
    ]
  );

  return (
    <EmailBookingContext.Provider value={contextValue}>
      <StyledEmailBookingDialog
        $themeColor={doxleThemeColor}
        open={Boolean(booking)}
        onClose={() => {
          setBooking(undefined);
        }}
        slotProps={{
          backdrop: {
            sx: {
              bgcolor: editRgbaAlpha({
                rgbaColor: doxleThemeColor.primaryBackdropColor,
                alpha: "0.4",
              }),
            },
          },
        }}
        PaperProps={{
          elevation: 8,
        }}
        TransitionComponent={Transition}
        aria-describedby="add-mail-dialog-slide"
      >
        <StyledEmailBookingTitle
          $themeColor={doxleThemeColor}
          $doxleFont={doxleFont}
        >
          New Mail
        </StyledEmailBookingTitle>

        <StyledEmailContentWrapper
          $themeColor={doxleThemeColor}
          $doxleFont={doxleFont}
        >
          <StyledEmailFieldWrapper
            $themeColor={doxleThemeColor}
            $doxleFont={doxleFont}
          >
            <span className="label-field"> From:</span>

            <div className="add-content-wrapper">
              {user && (
                <>
                  {user?.firstName} {user?.lastName}
                  <span className="email-text">{`< ${user?.email} >`}</span>
                </>
              )}

              {!user && <span className="email-text">Unknown User</span>}
            </div>
          </StyledEmailFieldWrapper>

          <AddMailToSection />

          <AddMailCCSection />

          <StyledEmailFieldWrapper
            $themeColor={doxleThemeColor}
            $doxleFont={doxleFont}
            layout
          >
            <span className="label-field"> Subject:</span>

            <div className="add-content-wrapper">
              <StyledMailBookingSubjectTextField
                $themeColor={doxleThemeColor}
                $doxleFont={doxleFont}
                value={newMail.subject}
                onChange={(e) =>
                  handleUpdateNewMail({ key: "subject", value: e.target.value })
                }
                variant="standard"
              />
            </div>
          </StyledEmailFieldWrapper>

          <StyledMailTextBodySection
            {...getRootProps({})}
            ref={dropzoneRef}
            onClick={() => mailTextBodyInputRef.current?.focus()}
          >
            <StyledMailBookingSubjectTextField
              $themeColor={doxleThemeColor}
              $doxleFont={doxleFont}
              value={newMail.textBody}
              onChange={(e) =>
                handleUpdateNewMail({ key: "textBody", value: e.target.value })
              }
              variant="standard"
              inputRef={mailTextBodyInputRef}
              multiline={true}
              $boldMode={boldMode}
              $italicMode={italicMode}
            />
          </StyledMailTextBodySection>

          {attachments.length > 0 && (
            <StyledAddedAttachmentWrapper>
              <AnimatePresence>
                {attachments.map((file, idx) => (
                  <AddedAttachmentItem
                    item={file}
                    itemIndex={idx}
                    key={`addedFile#${idx}`}
                  />
                ))}
              </AnimatePresence>
            </StyledAddedAttachmentWrapper>
          )}
        </StyledEmailContentWrapper>

        <DialogActions
          sx={{
            width: "100%",
            flexDirection: "row",
            display: "flex",
            justifyContent: "space-between",
            padding: "0px !important",
          }}
        >
          <StyledDialogActionBtnWrapper>
            <StyledTextModeButton
              $themeColor={doxleThemeColor}
              $selected={boldMode}
              onClick={() => setBoldMode((prev) => !prev)}
              // onClick={handleBoldClick}
            >
              <DoxleBoldModeIcon themeColor={doxleThemeColor} />
            </StyledTextModeButton>
            <StyledTextModeButton
              $themeColor={doxleThemeColor}
              $selected={italicMode}
              onClick={() => setItalicMode((prev) => !prev)}
              // onClick={handleItalicClick}
            >
              <DoxleItalicModeIcon themeColor={doxleThemeColor} />
            </StyledTextModeButton>
            <StyledTextModeButton
              $themeColor={doxleThemeColor}
              onClick={handleAddQuote}
            >
              <DoxleQuoteModeIcon themeColor={doxleThemeColor} />
            </StyledTextModeButton>
            <StyledTextModeButton $themeColor={doxleThemeColor}>
              <DoxleAlignModeIcon themeColor={doxleThemeColor} />
            </StyledTextModeButton>
            <StyledTextModeButton
              onClick={handleAddAttachmentBtn}
              $themeColor={doxleThemeColor}
            >
              <ImAttachment
                size={18}
                color={doxleThemeColor.primaryFontColor}
              />
            </StyledTextModeButton>
          </StyledDialogActionBtnWrapper>

          <StyledDialogActionBtnWrapper>
            <StyledMailActionSubmitButton
              $themeColor={doxleThemeColor}
              $doxleFont={doxleFont}
              $reverseColor={true}
              onClick={() => setBooking(undefined)}
            >
              <span className="btn-text">Cancel</span>
            </StyledMailActionSubmitButton>

            <StyledMailActionSubmitButton
              $themeColor={doxleThemeColor}
              $doxleFont={doxleFont}
              onClick={handleClickAddBtn}
            >
              <span className="btn-text">Send</span>
            </StyledMailActionSubmitButton>
          </StyledDialogActionBtnWrapper>
        </DialogActions>

        {isAddingEmail && (
          <ProgressScreen
            progressType="send"
            hasBackDrop={true}
            progressText="Sending mail, please wait..."
            textStyle={{
              fontSize: "max(2rem,20px)",
              fontFamily: doxleFont.primaryFont,
              color: "white",
            }}
            containerStyle={{
              display: "flex",

              position: "absolute",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          />
        )}
      </StyledEmailBookingDialog>
    </EmailBookingContext.Provider>
  );
};

export const useEmailBookingContext = () =>
  useContext(EmailBookingContext) as IEmailBookingContextValue;
export default EmailBooking;

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
