import React from "react";
import DoxleDialogHelper from "../../../DoxleDesignPattern/DoxleDialogHelper/DoxleDialogHelper";
import useOrderActionDialog from "./Hooks/useOrderActionDialog";
import { useOrdersStore } from "../../OrdersStore/useOrdersStore";
import { shallow } from "zustand/shallow";
import OrdersQueryAPI from "../../QueryHooks/OrdersQueryAPI";
import useDoxleNotificationStore from "../../../DoxleGeneralStore/useDoxleNotificationStore";
import { useDoxleCurrentContextStore } from "../../../DoxleGeneralStore/useDoxleCurrentContext";
import useSetOrderQueryData from "../../Hooks/useSetOrderQueryData";
import useSetOrderDetailQueryData from "../../Hooks/useSetOrderDetailQueryData";
import Order from "../../Models/order";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";

type Props = {};

const OrderActionDialog = (props: Props) => {
  const { selectedOrders, clearSelectedOrders, orderFilter } = useOrdersStore(
    (state) => ({
      selectedOrders: state.selectedOrders,
      orderFilter: state.queryFilter,
      clearSelectedOrders: state.clearSelectedOrders,
    }),
    shallow
  );
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }),
    shallow
  );
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );
  const company = useDoxleCurrentContextStore(
    (state) => state.currentCompany,
    shallow
  );

  const { destroyOrder, destroyMutipleOrders } =
    OrdersQueryAPI.useMutateOrderQuery({
      company,
      showNotification,
      filter: orderFilter,
      onRemoveSuccessCb(deletedIds) {
        if (deletedIds) {
          handleRemoveMultiOrders(deletedIds);
          clearSelectedOrders();
          setOrderAction(undefined);
        }
      },
    });

  const { handleUpdateMutipleOrderDetails } = useSetOrderDetailQueryData({});

  const { updateOrderSignoff } = OrdersQueryAPI.useMutateOrderQuery({
    company,
    showNotification,
    filter: orderFilter,
    onUpdateManySuccessCb: (orders: Order[] | undefined) => {
      if (orders) {
        handleUpdateMutipleOrderDetails(orders);
      }
      clearSelectedOrders();
      setOrderAction(undefined);
    },
  });

  const { handleRemoveMultiOrders } = useSetOrderQueryData({
    filter: orderFilter,
  });

  const handleAction = () => {
    if (orderAction === "delete") {
      handleRemoveMutipleOrders();
    } else if(orderAction === "sign") {
      handleSignMutipleOrders();
    } else {
      handleUnsignMutipleOrders();
    }
  };

  const handleSignMutipleOrders = () => {
    if (selectedOrders.length > 0) {
      const filteredOrders = selectedOrders.filter(
        (item) => item.signedOff === null
      );
      if (filteredOrders.length < 1) {
        clearSelectedOrders();
        setOrderAction(undefined);
        return;
      }
      const orderIds: string[] = filteredOrders.map((item) => item.orderId);
      updateOrderSignoff.mutate({ orderIds: orderIds, signed: true });
    }
  };

  const handleUnsignMutipleOrders = () => {
    if (selectedOrders.length > 0) {
      const filteredOrders = selectedOrders.filter(
        (item) => item.signedOff !== null
      );
      if (filteredOrders.length < 1) {
        clearSelectedOrders();
        setOrderAction(undefined);
        return;
      }
      const orderIds: string[] = filteredOrders.map((item) => item.orderId);
      updateOrderSignoff.mutate({ orderIds: orderIds, signed: false });
    }
  };

  const handleRemoveMutipleOrders = () => {
    if (selectedOrders.length > 0) {
      const deleteIds: string[] = selectedOrders.map((item) => item.orderId);
      destroyMutipleOrders.mutate({
        orderIds: deleteIds,
        docketPk: selectedOrders[0].docket ?? undefined,
        projectId: selectedOrders[0].project ?? undefined,
      });
    }
  };
  const { orderAction, setOrderAction } = useOrderActionDialog({});
  const isOpenDialog = Boolean(orderAction !== undefined);
  return (
    <DoxleDialogHelper
      open={isOpenDialog}
      onClose={() => setOrderAction(undefined)}
      title={`${
        orderAction === "delete"
          ? "Confirm delete"
          : orderAction === "sign"
          ? "Confirm sign order"
          : "Confirm unsign order"
      }`}
      description={`${
        orderAction === "delete"
          ? `All data belong to ${selectedOrders.length} order${
              selectedOrders.length > 1 ? "s" : ""
            } will be deleted permanently, are you sure to proceed?`
          : orderAction === "sign"
          ? `Are you sure to sign ${selectedOrders.length} order${
              selectedOrders.length > 1 ? "s" : ""
            }?`
          : `Are you sure to unsign ${selectedOrders.length} order${
              selectedOrders.length > 1 ? "s" : ""
            }?`
      }`}
      dialogButtons={[
        {
          buttonText: "Cancel",
          buttonFunction: () => setOrderAction(undefined),
          buttonColor: doxleThemeColor.primaryContainerColor,
          btnStyleSx: {
            border: `1px solid ${doxleThemeColor.primaryDividerColor}`,
          },
          buttonTextStyle: {
            color: doxleThemeColor.primaryFontColor,
          },
        },
        {
          buttonText: "Confirm",
          buttonFunction: handleAction,
          btnStyleSx: {
            border: `1px solid ${doxleThemeColor.primaryDividerColor}`,
          },
        },
      ]}
    />
  );
};

export default OrderActionDialog;
