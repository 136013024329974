import React, { useCallback, useMemo } from "react";
import Modal from "@mui/base/Modal";
import { Backdrop, IconButtonProps, Slide } from "@mui/material";
import {
  StyledSideScreenRootContainer,
  StyledToggleButtonSection,
} from "./StyledComponentsDoxleSideScreen";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import { useAnimate } from "framer-motion";
import ScreenSizeControl from "./ScreenSizeControl";
import { TfiClose } from "react-icons/tfi";
import DoxleCloseBtn from "../DoxleButtons/DoxleCloseBtn";
type Props = {
  openSideScreen: boolean;
  handleCloseSideScreen: Function;
  hasBackDrop?: boolean;
  containerWidthInPixel?: `${number}px`;
  containerWidthInRatio?: `${number}%`;
  containerLeftPosInPixel?: `${number}px`;
  containerLeftPosInRatio?: `${number}%`;
  containerRightPosInPixel?: `${number}px`;
  containerRightPosInRatio?: `${number}%`;
  containerMinWidthInPixel?: `${number}px`;
  containerMinWidthInRatio?: `${number}%`;
  backdropStyle?: React.CSSProperties;
  transitionDirection?: "left" | "up" | "right" | "down";
  contentWrapperStyled?: React.CSSProperties;
  children: React.ReactNode;
  expandable?: boolean;

  onExpanded?: (expandValue: boolean) => void; //!FUNCTION TO GET EXPANDED STATE VALUE OF THE SIDE SCREEN

  fullScreenInitial?: boolean;

  //! custom icons for expand and close btn
  expandBtnStyle?: IconButtonProps["sx"];
  maximizeIcon?: React.ReactNode;
  minimizeIcon?: React.ReactNode;

  closeBtnStyled?: IconButtonProps["sx"];
  closeIcon?: React.ReactNode;
};

const DoxleSideScreen = ({
  openSideScreen,
  handleCloseSideScreen,
  hasBackDrop = false,
  containerWidthInPixel,
  containerWidthInRatio,
  containerLeftPosInPixel,
  containerLeftPosInRatio,
  containerMinWidthInPixel,
  containerMinWidthInRatio,
  containerRightPosInPixel,
  containerRightPosInRatio,
  backdropStyle,
  transitionDirection,
  children,
  expandable = true,
  onExpanded,
  contentWrapperStyled,
  fullScreenInitial = false,
  expandBtnStyle,
  closeBtnStyled,
  closeIcon,
  maximizeIcon,
  minimizeIcon,
}: Props) => {
  const { doxleThemeColor, staticMenuColor } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      staticMenuColor: state.staticMenuColor,
    }),
    shallow
  );
  const [scope, animate] = useAnimate();
  const initialRootContainerWidth = useMemo(
    () =>
      containerWidthInPixel
        ? containerWidthInPixel
        : containerWidthInRatio
        ? containerWidthInRatio
        : "50%",
    [containerWidthInPixel, containerWidthInRatio]
  );
  const fullScreenContainerWidth = useMemo(
    () =>
      containerWidthInPixel ? "100vw" : containerWidthInRatio ? "100%" : "100%",
    [containerWidthInPixel, containerWidthInRatio]
  );

  const onClickExpandBtn = useCallback(() => {
    animate(scope.current, { width: fullScreenContainerWidth });

    if (onExpanded) onExpanded(true);
  }, [fullScreenContainerWidth, scope.current]);

  const onClickMinimizeBtn = useCallback(() => {
    animate(scope.current, { width: initialRootContainerWidth });

    if (onExpanded) onExpanded(false);
  }, [initialRootContainerWidth, scope.current]);

  return (
    <Modal
      open={openSideScreen}
      style={{
        position: "fixed",
        zIndex: 1000,
        inset: 0,
        display: "flex",
        justifyContent: "flex-end",
        height: "100%",
        width: "100%",
      }}
      closeAfterTransition
      onClose={(ev, reason) => {
        handleCloseSideScreen();
      }}
      slots={{ backdrop: hasBackDrop ? Backdrop : undefined }}
      slotProps={{
        backdrop: {
          style: backdropStyle ? backdropStyle : undefined,
        },
      }}
    >
      <Slide
        direction={
          transitionDirection
            ? transitionDirection
            : containerLeftPosInPixel || containerLeftPosInRatio
            ? "right"
            : containerRightPosInPixel || containerRightPosInRatio
            ? "left"
            : "left"
        }
        in={openSideScreen}
        mountOnEnter
        unmountOnExit={true}
      >
        <StyledSideScreenRootContainer
          $themeColor={doxleThemeColor}
          //   widthInPixel={containerWidthInPixel}
          //   widthInRatio={containerWidthInRatio}
          $leftPosInPixel={containerLeftPosInPixel}
          $leftPosInRatio={containerLeftPosInRatio}
          $minWidthInPixel={containerMinWidthInPixel}
          $minWidthInRatio={containerMinWidthInRatio}
          $rightPosInPixel={containerRightPosInPixel}
          $rightPosInRatio={containerRightPosInRatio}
          layout="position"
          ref={scope}
          style={{
            width: fullScreenInitial
              ? fullScreenContainerWidth
              : initialRootContainerWidth,
          }}
        >
          <div
            style={{
              width: "100%",
              height: "100%",

              ...contentWrapperStyled,
              position: "relative",
            }}
          >
            {expandable && (
              <ScreenSizeControl
                expandToggleFunction={onClickExpandBtn}
                minimizeToggleFunction={onClickMinimizeBtn}
                fullScreenInitial={fullScreenInitial}
                expandBtnStyle={expandBtnStyle}
                maximizeIcon={maximizeIcon}
                minimizeIcon={minimizeIcon}
              />
            )}
            <DoxleCloseBtn
              sx={{
                width: 30,
                height: 30,
                padding: "4px !important",
                position: "absolute",
                right: 10,
                top: 5,
                zIndex: 1000,
                ...closeBtnStyled,
              }}
              onClick={() => handleCloseSideScreen()}
              closeIcon={
                closeIcon ?? (
                  <TfiClose
                    color={doxleThemeColor.primaryFontColor}
                    size={30}
                    strokeWidth={0.5}
                  />
                )
              }
            />
            {children}
          </div>
        </StyledSideScreenRootContainer>
      </Slide>
    </Modal>
  );
};

export default DoxleSideScreen;
