import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  ContactCompanyFilters,
  ContactsFilters,
} from "../../../Services/QueryHooks/contactsFilters";
import { useDoxleCurrentContextStore } from "../../../DoxleGeneralStore/useDoxleCurrentContext";
import { shallow } from "zustand/shallow";
import ContactsAPI from "../../../Services/QueryHooks/contactsAPI";
import { Contact, ContactCompany } from "../../../Models/addressBook";
import ScopeOfWorkAPI, {
  FilterRetrieveScopeOfWorkQuery,
} from "../../../Quotes/QueryAPI/scopeOfWorkQueryAPI";
import { useEditDocketSideScreenStore } from "../../../CoreContent/EditDocketSideScreen/EditDocketSideScreenStore/useEditDocketSideScreenStore";
import { ScopeOfWorkGroup } from "../../../ScopeOfWorks/Model/scopeOfWorks";

type Props = {
  onSelectTask?: (item: ScopeOfWorkGroup) => void;
  onClosePopover: () => void;
  closeOnSelect?: boolean;
};

const useDoxleSelectTaskDropdown = ({
  onSelectTask,
  onClosePopover,
  closeOnSelect,
}: Props) => {
  const company = useDoxleCurrentContextStore(
    (state) => state.currentCompany,
    shallow
  );
  const docket = useEditDocketSideScreenStore(
    (state) => state.edittedDocket,
    shallow
  );
  const [searchText, setSearchText] = useState("");
  const [filter, setFilter] = useState<FilterRetrieveScopeOfWorkQuery>({
    docketId: docket?.docketPk,
    projectId: docket?.project || "",
    search: "",
  });

  const retrieveTaskGroupsQuery = ScopeOfWorkAPI.useRetrieveScopeGroupList({
    company: company,
    filter: filter,
  });

  const taskList = useMemo(
    () =>
      retrieveTaskGroupsQuery.isSuccess
        ? retrieveTaskGroupsQuery.data.pages.reduce((acc, data) => {
            return acc.concat(data.data.results);
          }, [] as ScopeOfWorkGroup[])
        : [],
    [retrieveTaskGroupsQuery.data]
  );
  const handleFetchNextPageTask = () => {
    if (retrieveTaskGroupsQuery.hasNextPage)
      retrieveTaskGroupsQuery.fetchNextPage();
  };

  const handleSelectTask = useCallback(
    (task: ScopeOfWorkGroup) => {
      if (onSelectTask) onSelectTask(task);
      if (closeOnSelect) onClosePopover();
    },
    [onSelectTask, onClosePopover, closeOnSelect]
  );

  const handleFetchNextPage = () => {
    handleFetchNextPageTask();
  };
  useEffect(() => {
    const timeout = setTimeout(() => {
      setFilter((prev) => {
        console.log({ ...prev, search: searchText })
        return { ...prev, search: searchText };
      });
    }, 300);

    return () => clearTimeout(timeout);
  }, [searchText]);
  return {
    searchText,
    setSearchText,
    taskList,
    isFetchingTaskList: retrieveTaskGroupsQuery.isLoading,
    isErrorFetchingTaskList: retrieveTaskGroupsQuery.isError,
    handleFetchNextPage,
    isFetchingNextPageTaskList: retrieveTaskGroupsQuery.isFetchingNextPage,
    handleSelectTask,
  };
};

export default useDoxleSelectTaskDropdown;
