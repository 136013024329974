import React from "react";
import { Docket } from "../../Models/dockets";
import { Account } from "../Models/account";
import LinkUnlinkAccountButton from "./LinkUnlinkAccountButton";
import {
  StyledDocketMappingRow,
  StyledAccountFieldIconButton,
  StyledLinkedIndicator,
  StyledMapDocketAccountField,
  StyledMapDocketButton,
  StyledMapDocketTitle, StyledMapDocketXeroCost,
} from "./styledComponents";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import useDocketMappingRow from "../Hooks/useDocketMappingRow";
import { KeyboardArrowDownOutlined } from "@mui/icons-material";
import {toFloat} from "../../Measurements/konvaFunctions/generalFunctions";
import {formatter} from "../../Utilities/FunctionUtilities";

type Props = {
  docket: Docket;
};

const DocketMappingRow = ({ docket }: Props) => {
  const styleProps = useDoxleThemeStore(
    (state) => ({
      $themeColor: state.doxleThemeColor,
      $doxleFont: state.doxleFont,
      $currentTheme: state.currentTheme,
    }),
    shallow
  );

  const { handleAccountDropDown, isLinked, linkedItem, selectedAccount } =
    useDocketMappingRow({ docket });
  const xeroValue = docket.isExpense ? docket.costXero : docket.incomeXero;
  const formattedXeroValue = xeroValue !== null ? formatter.format(toFloat(xeroValue)) : null
  return (
    <StyledDocketMappingRow {...styleProps}>
      <StyledMapDocketTitle {...styleProps}>
        {docket.docketIdNum} {docket.docketName}
      </StyledMapDocketTitle>
      <StyledMapDocketXeroCost {...styleProps}>
        {Boolean(formattedXeroValue && docket.accountTrackingId) && formattedXeroValue}
      </StyledMapDocketXeroCost>
      <StyledMapDocketAccountField
        onClick={(e) => {
          if (!isLinked) handleAccountDropDown(e, docket);
        }}
        $linked={isLinked}
        {...styleProps}
      >
        {linkedItem
          ? linkedItem.accountName
          : selectedAccount
          ? selectedAccount
          : "Link an account"}
        {!isLinked && (
          <StyledAccountFieldIconButton {...styleProps}>
            <KeyboardArrowDownOutlined />
          </StyledAccountFieldIconButton>
        )}
      </StyledMapDocketAccountField>

      <StyledMapDocketButton {...styleProps}>
        <StyledLinkedIndicator {...styleProps} $linked={Boolean(linkedItem)}>
          {isLinked ? "Linked" : "Not Linked"}
        </StyledLinkedIndicator>
        <LinkUnlinkAccountButton isLinked={isLinked} docket={docket} />
      </StyledMapDocketButton>
    </StyledDocketMappingRow>
  );
};

export default React.memo(DocketMappingRow);
