import { MailAttachment } from "../../Models/mail";
import {
  StyledAttachmentContainer,
  StyledAttachmentItem,
  StyledAttachmentSection,
  StyledFileName,
  StyledImageContainer,
} from "./MailReply/StyledMailReply";
import {
  getFileIconUrl,
  isImageFile,
} from "../../../Utilities/FunctionUtilities";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";

type Props = {
  mailAttachment: MailAttachment[];
  isReply?: boolean;
};

const MailAttachmentSection = ({ isReply, mailAttachment }: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }),
    shallow
  );

  return (
    <StyledAttachmentSection>
      {mailAttachment.length > 0 && (
        <div style={{ marginTop: 10 }}>Attachment(s)</div>
      )}

      <StyledAttachmentContainer>
        {mailAttachment.map((item, index) => (
          <StyledAttachmentItem
            $themeColor={doxleThemeColor}
            $doxleFont={doxleFont}
            key={index}
            onClick={() => {
              window.open(item.url);
            }}
            style={{ border: isReply ? "none" : "" }}
          >
            <StyledImageContainer>
            <img
              src={
                isImageFile(item.fileName)
                  ? item.url
                  : getFileIconUrl(item.fileName)
              }
            />
            </StyledImageContainer>

            <StyledFileName
              $themeColor={doxleThemeColor}
              $doxleFont={doxleFont}
            >
              {item.fileName}
            </StyledFileName>
          </StyledAttachmentItem>
        ))}
      </StyledAttachmentContainer>
    </StyledAttachmentSection>
  );
};

export default MailAttachmentSection;
