import React, { useCallback, useEffect, useMemo } from "react";
import { Docket, DocketStatus, LightDocket } from "../../../Models/dockets";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import Popover from "@mui/material/Popover";
import { Components, ItemContent, Virtuoso } from "react-virtuoso";
import {
  StyledNBStatusItemWrapper,
  StyledNbStatusListContainer,
  StyledNbStatusListTitle,
} from "./StyledNbStatusPopover";
import DoxleEmptyPlaceHolder from "../../../DoxleDesignPattern/DoxleEmptyPlaceHolder/DoxleEmptyPlaceHolder";
import useNBStatusPopover from "./Hooks/useNBStatusPopover";
import NbStatusItem from "./NbStatusItem";
import useGetNBStatus from "./Hooks/useGetNBStatus";

type Props = {
  anchorNbStatusMenuEl: HTMLElement | null;
  currentFocusedNB: LightDocket | undefined;
  handleClosePopoverStatusMenu: () => void;
};
interface NbStatusListContext {}
const NBStatusPopover = ({
  anchorNbStatusMenuEl,
  currentFocusedNB,
  handleClosePopoverStatusMenu,
}: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }),
    shallow
  );
  const canBeOpen = Boolean(anchorNbStatusMenuEl && currentFocusedNB);
  const id = canBeOpen ? "nb-status-menu-popper" : undefined;
  const { handleUpdateNbStatus, showStatusList, setShowStatusList } =
    useNBStatusPopover({ currentFocusedNB, handleClosePopoverStatusMenu });
  const { nbStatusList } = useGetNBStatus({});
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (anchorNbStatusMenuEl) setShowStatusList(true);
      else setShowStatusList(false);
    }, 300);

    return () => clearTimeout(timeout);
  }, [anchorNbStatusMenuEl]);
  //*render list
  const components: Components<DocketStatus, NbStatusListContext> = useMemo(
    () => ({
      Item: (props) => (
        <StyledNBStatusItemWrapper
          {...props}
          initial={{
            y: -5,
            opacity: 0,
          }}
          animate={{
            y: 0,
            opacity: 1,
          }}
          transition={{
            duration: 0.3,
          }}
        />
      ),
      EmptyPlaceholder: () => (
        <DoxleEmptyPlaceHolder
          headTitleText="No Status Item!"
          subTitleText="Add more items to manage you work..."
          containerBgColor="transparent"
          headTitleTextStyle={{
            fontSize: "18px",
          }}
          subTitleTextStyle={{
            fontSize: "14px",
          }}
        />
      ),
    }),
    []
  );

  const itemContent: ItemContent<DocketStatus, NbStatusListContext> =
    useCallback(
      (_index, item, context) => {
        return (
          <NbStatusItem
            key={`nb_status_${item.statusId}`}
            statusItem={item}
            handleUpdateNbStatus={handleUpdateNbStatus}
            isSelected={currentFocusedNB?.status === item.statusId}
          />
        );
      },
      [handleUpdateNbStatus]
    );

  const listStyle = {
    flex: 1,
    width: "100%",
  };
  return (
    <Popover
      id={id}
      open={canBeOpen}
      anchorEl={anchorNbStatusMenuEl}
      disableScrollLock
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      onClose={handleClosePopoverStatusMenu}
      sx={{
        "& .MuiPopover-paper": {
          backgroundColor: "transparent",
          overflow: "visible",
          boxShadow: "none",
        },
      }}
      transitionDuration={{ appear: 100, exit: 200 }}
      elevation={8}
    >
      <StyledNbStatusListContainer $themeColor={doxleThemeColor}>
        <StyledNbStatusListTitle
          $themeColor={doxleThemeColor}
          $doxleFont={doxleFont}
        >
          # Notice Status
        </StyledNbStatusListTitle>

        {showStatusList && (
          <Virtuoso
            style={listStyle}
            itemContent={itemContent}
            data={nbStatusList}
            components={components}
          />
        )}
      </StyledNbStatusListContainer>
    </Popover>
  );
};

export default NBStatusPopover;
