import { Tooltip, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { shallow } from "zustand/shallow";
import { useDoxleCurrentContextStore } from "../../DoxleGeneralStore/useDoxleCurrentContext";
import useDoxleNotificationStore from "../../DoxleGeneralStore/useDoxleNotificationStore";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import { DoxleFile } from "../../Models/files";
import FileRenameDialog from "../Components/FileRenameDialog";
import FilesQueryAPI from "../QueryHooks/FileStorageQueryAPI";
import { useFileStore } from "../Store/useFileStore";
import FileHoverMenu from "./FileHoverMenu";
import {
  StyledGridFileItem,
  StyledGridFileName,
  StyledGridFileSize,
  StyledImageDiv,
} from "./StyledFilesBodyComponent";
import { StyledHeaderSubtitle } from "./StyledFilesHeaderComponent";
import { DOXLE_MIME_TYPE } from "../../Utilities/MimeFileType";
import {
  DoxleCSVIcon,
  DoxleExcelIcon,
  DoxlePDFIcon,
  DoxleWordIcon,
} from "../../DoxleDesignPattern/DoxleGeneralIcons";
import { IoVideocamOutline } from "react-icons/io5";
import useGridFileItem from "../Hooks/useGridFileItem";

const pdfIcon = require("../Assets/pdf.png");
const jpegIcon = require("../Assets/jpeg.png");
const pngIcon = require("../Assets/png.png");
const excelIcon = require("../Assets/excel.png");
const wordIcon = require("../Assets/word.png");

type Props = {
  gridFileItem: DoxleFile;
};

function GridFileItem({ gridFileItem }: Props) {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore((state) => ({
    doxleThemeColor: state.doxleThemeColor,
    doxleFont: state.doxleFont,
  }));
  const {
    openFile,
    onFileRowHover,
    setOnFileRowHover,
    formattedLastModified,
    dialogOpen,
    handleDialogKeyDown,
    handleDialogTick,
    handleDialogClose,
  } = useGridFileItem({ gridFileItem });
  return (
    <StyledGridFileItem
      $themeColor={doxleThemeColor}
      onMouseEnter={(event: React.MouseEvent<HTMLDivElement>) => {
        if (!onFileRowHover) setOnFileRowHover(true);
      }}
      onMouseLeave={(event: React.MouseEvent<HTMLDivElement>) => {
        setOnFileRowHover(false);
      }}
    >
      <StyledImageDiv
        onClick={openFile}
        style={{ flexGrow: 1, height: "unset", justifyContent: "center" }}
      >
        {gridFileItem.fileType
          .toLowerCase()
          .includes(DOXLE_MIME_TYPE.pdf.toLowerCase()) ? (
          <DoxlePDFIcon
            themeColor={doxleThemeColor}
            containerStyle={{
              width: "50%",
            }}
          />
        ) : gridFileItem.fileType
            .toLowerCase()
            .includes(DOXLE_MIME_TYPE.doc.toLowerCase()) ||
          gridFileItem.fileType
            .toLowerCase()
            .includes(DOXLE_MIME_TYPE.docx.toLowerCase()) ? (
          <DoxleWordIcon
            themeColor={doxleThemeColor}
            containerStyle={{
              width: "50%",
            }}
          />
        ) : gridFileItem.fileType
            .toLowerCase()
            .includes(DOXLE_MIME_TYPE.xlsx) ||
          gridFileItem.fileType
            .toLowerCase()
            .includes(DOXLE_MIME_TYPE.xls.toLowerCase()) ? (
          <DoxleExcelIcon
            themeColor={doxleThemeColor}
            containerStyle={{
              width: "50%",
            }}
          />
        ) : gridFileItem.fileType
            .toLowerCase()
            .includes(DOXLE_MIME_TYPE.csv.toLowerCase()) ? (
          <DoxleCSVIcon
            themeColor={doxleThemeColor}
            containerStyle={{
              width: "50%",
            }}
          />
        ) : gridFileItem.fileType
            .toLowerCase()
            .includes(DOXLE_MIME_TYPE.images) ? (
          <img className="image-grid-holder" src={gridFileItem.thumbUrl} />
        ) : (
          gridFileItem.fileType
            .toLowerCase()
            .includes(DOXLE_MIME_TYPE.video) && (
            <IoVideocamOutline
              color={doxleThemeColor.primaryFontColor}
              size={100}
            />
          )
        )}
      </StyledImageDiv>
      <Tooltip
        title={<Typography fontSize={16}>{gridFileItem.fileName}</Typography>}
      >
        <StyledGridFileName
          $themeColor={doxleThemeColor}
          $doxleFont={doxleFont}
        >
          {gridFileItem.fileName}
        </StyledGridFileName>
      </Tooltip>
      <StyledHeaderSubtitle>{formattedLastModified}</StyledHeaderSubtitle>

      <StyledGridFileSize $themeColor={doxleThemeColor} $doxleFont={doxleFont}>
        {(parseFloat(gridFileItem.fileSize) / 1024 / 1024).toFixed(2)}MB
      </StyledGridFileSize>

      {onFileRowHover && (
        <div style={{ marginTop: "10px" }}>
          <FileHoverMenu fileItem={gridFileItem} />
        </div>
      )}
      {dialogOpen && (
        <FileRenameDialog
          dialogOpen={dialogOpen}
          handleTick={handleDialogTick}
          handleKeyDown={handleDialogKeyDown}
          handleClose={handleDialogClose}
        />
      )}
    </StyledGridFileItem>
  );
}
export default GridFileItem;
