import React from "react";
import { IQRUserLog } from "../../Models/qrLog";
import { StyledQRLogItem } from "./StyledQRUserLog";
import dayjs from "dayjs";

type Props = {
  logItem: IQRUserLog;
};

const QRLogItem = ({ logItem }: Props) => {
  return (
    <StyledQRLogItem>
      <div className="user-name-text">{logItem.userName}</div>

      <div className="time-text">
        {dayjs(logItem.entryTime).format("hh:mm a")}
      </div>

      <div className="time-text">
        {logItem.exitTime ? dayjs(logItem.exitTime).format("hh:mm a") : "-"}
      </div>
    </StyledQRLogItem>
  );
};

export default QRLogItem;
