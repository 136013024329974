import React from "react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { StyledQAListEditContainer } from "./StyledComponentQAListEdit";
import EditQAListTitle from "./EditQAListTitle";
import EditQAListDueDate from "./EditQAListDueDate";
import EditQAListAssignee from "./EditQAListAssignee";
type Props = {};

const QAListEdit = (props: Props) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <StyledQAListEditContainer
        initial={{ y: 100, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
      >
        <EditQAListTitle />

        <EditQAListDueDate />

        <EditQAListAssignee />
      </StyledQAListEditContainer>
    </LocalizationProvider>
  );
};

export default QAListEdit;
