import { motion } from "framer-motion";
import styled from "styled-components";
import {
  DoxleFont,
  DoxleTheme,
  DoxleThemeColor,
} from "../../DoxleGeneralStore/useDoxleThemeStore";
import { editRgbaAlpha } from "../../Utilities/FunctionUtilities";
import Paper, { PaperProps } from "@mui/material/Paper";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import { Button, Popover } from "@mui/material";

export const StyledProjectSelectWithDropdownContainer = styled(motion.div)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  border-radius: 3px;
  min-height: 36px;

  cursor: pointer;
  align-items: center;

  align-self: flex-start;
  flex: 1;
  display: flex;
  padding: 0px 7px;

  overflow: visible;
  white-space: pre-wrap;
  position: relative;
  border: 1.5px solid ${(p) => p.theme.color.primaryDividerColor};
  span {
    color: ${(p) => p.$themeColor.primaryFontColor};
    font-size: 14px;
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    flex: 1;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .icon-dropdown {
    margin-left: 4px;
    transform: translateY(0) rotate(0);
    transition: transform 0.3s ease; /* Smooth transition for hover effect */
  }

  &:hover .icon-dropdown {
    animation: hoverAnimation 0.3s ease forwards;
  }

  @keyframes hoverAnimation {
    0%,
    100% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-3px);
    }
  }
`;
interface IProjectListContainer
  extends Omit<
    PaperProps,
    "onDragStart" | "onAnimationStart" | "onDragEnd" | "onDrag"
  > {
  $themeColor: DoxleThemeColor;
}
const AnimatedPaper = motion(Paper);
const projectListAnimatedVariants = {
  entering: {
    y: [-10, 0],
    opacity: [0, 1],
    transition: {
      duration: 0.4,
    },
  },
  exiting: {
    y: [0, -10],
    opacity: [1, 0],
    transition: {
      duration: 0.4,
    },
  },
};
export const StyledProjectListContainer = styled(
  ({ $themeColor, ...rest }: IProjectListContainer) => (
    <AnimatedPaper
      {...rest}
      variants={projectListAnimatedVariants}
      animate="entering"
      exit="exiting"
      initial="initial"
      layout
    />
  )
)`
  && {
    background-color: ${(p) => p.$themeColor.primaryContainerColor};

    position: relative;
    padding: 1rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: hidden;
  }
`;
export const StyledProjectItemSelectionContainer = styled(motion.div)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
  $selected: boolean;
}>`
  width: 100%;
  height: max(3rem, 30px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: flex;
  align-items: center;
  border-radius: 2px;
  max-height: 30px;
  cursor: pointer;

  cursor: pointer;
  &:hover {
    background-color: ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.$themeColor.doxleColor,
        alpha: "0.4",
      })};
    color: white;
    transition: 0.2s;
  }
  color: ${(p) =>
    p.$selected ? p.$themeColor.doxleColor : p.$themeColor.primaryFontColor};
`;
export const StyledProjectTitle = styled(motion.div)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  text-transform: capitalize;
  font-size: 1.2rem;
  font-family: ${(p) => p.$doxleFont.primaryFont};
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.06rem;
  width: 100%;
  padding-left: 10px;
`;

export const StyledListScroller = styled.div`
  width: 100%;
  height: 100%;
`;
interface ICustomTextFieldProps extends TextFieldProps<"standard"> {
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}

export const StyledProjectSearchTextField = styled(
  ({ $themeColor, $doxleFont, ...rest }: ICustomTextFieldProps) => (
    <TextField {...rest} />
  )
)`
  && {
    width: calc(100% - 1.6rem) !important;
    padding: 0 0.8rem;
  }
  input {
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-weight: 500;
    font-size: 1.4rem;
    line-height: normal;
    letter-spacing: 0.006rem;

    color: ${(p) => p.$themeColor.primaryFontColor};

    &::placeholder {
      font-family: ${(p) => p.$doxleFont.primaryTitleFont};
      font-size: 1.4rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.006rem;
      color: ${(p) => p.$themeColor.primaryInputPlaceholderColor} !important;
      opacity: 1 !important;
    }
  }
  & .MuiInput-root:after {
    border: none !important;
  }
  & .MuiInput-root:before {
    border: none !important;
  }
  & .MuiInput-root {
    border: none !important;
  }
  & .MuiInput-root:hover {
    border: none !important;
  }
  & .MuiInput-root:hover:not(.Mui-disabled, .Mui-error):before {
    border: none !important;
  }
  & .MuiInputLabel-root.Mui-focused {
    display: none;
  }
  & .MuiInputLabel-root {
    display: none;
  }
  & .MuiInputBase-root {
    width: 100%;
  }
  & .MuiInputBase-root-MuiInput-root:after {
    border-bottom: none !important;
  }
  & .MuiInputBase-input {
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const StyledProjectSearchFieldWrapper = styled.div`
  width: 70%;
`;

export const StyledAddAddProjectButton = styled(motion(Button))<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  && {
    display: flex;
    flex-direction: row;
    /* width: 120px !important; */
    cursor: pointer;
    padding: 4px 8px !important;
    border-radius: 6px !important;
    background-color: ${(p) => p.$themeColor.primaryContainerColor} !important;
    color: ${(p) => p.$themeColor.primaryFontColor} !important;
    text-transform: capitalize !important;
    border: 1px solid ${(p) => p.$themeColor.primaryDividerColor};
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-size: max(1.1rem, 11px);
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    text-transform: capitalize !important;
    transition: all 0.2s ease;
    &:hover {
      background-color: ${(p) => p.$themeColor.primaryFontColor} !important;
      color: ${(p) => p.$themeColor.primaryContainerColor} !important;

      opacity: 0.95;
      transition: all 0.2s ease;
    }
  }
`;

export const StyledSearchProjectWrapper = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 4px 0px !important;
  margin-bottom: 4px;
  border-bottom: 1px solid ${(p) => p.$themeColor.primaryDividerColor};
  .form-title {
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-size: max(1.8rem, 18px);
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    color: ${(p) => p.$themeColor.primaryFontColor} !important;
    flex: 1;
  }
`;

export const StyledProjectSelectPopover = styled(Popover)<{
  $themeColor: DoxleThemeColor;
}>`
  && {
  }

  & .MuiPopover-paper {
    box-shadow: 0px 0px 8px 0px ${(p) => p.$themeColor.primaryBoxShadowColor} !important;
    border: 1px solid ${(p) => p.$themeColor.primaryDividerColor};
    overflow: hidden;
    background-color: ${(p) => p.$themeColor.primaryContainerColor};
    width: max(31rem, 310px);
    height: 36rem;
  }

  & .MuiPaper-elevation8 {
    box-shadow: none;
  }
`;

export const StyledAddProjectFieldWrapper = styled(motion.div)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 14px;

  .field-label {
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-size: max(1.1rem, 11px);
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: ${(p) => p.$themeColor.primaryFontColor};
    margin-bottom: 8px;
  }

  .error-text {
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-size: max(1.1rem, 11px);
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: red;
    margin-top: 2px;
  }
`;
export const StyledAddProjectTextfield = styled(TextField)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  && {
    width: 100%;
    padding-bottom: 2px;
    border-bottom: 1px solid ${(p) => p.$themeColor.primaryDividerColor};
  }
  & .MuiInputBase-input.Mui-disabled {
    -webkit-text-fill-color: ${(p) => p.$themeColor.primaryFontColor};
  }
  input {
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-weight: 500;
    font-size: max(1.2rem, 12px);
    line-height: normal;
    color: ${(p) => p.$themeColor.primaryFontColor};
    padding: 0px !important;

    &::placeholder {
      font-family: ${(p) => p.$doxleFont.primaryFont};
      font-weight: 500;
      font-size: max(1.1rem, 11px);
      line-height: normal;
      padding: 0px !important;
      color: ${(p) =>
        editRgbaAlpha({
          rgbaColor: p.$themeColor.primaryFontColor,
          alpha: "0.5",
        })};
    }
  }
  & .MuiInput-root:after {
    border-bottom: none !important;
  }
  & .MuiInput-root:before {
    border-bottom: none !important;
  }
  & .MuiInput-root {
    border-bottom: none !important;
  }
  & .MuiInput-root:hover:not(.Mui-disabled, .Mui-error):before {
    border-bottom: none !important;
  }
  & .MuiInputBase-root {
    width: 100%;
  }
`;

export const StyledSubmitAddButton = styled(motion(Button))<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  && {
    display: flex;
    flex-direction: row;
    min-width: 0px !important;
    cursor: pointer;
    align-self: flex-end;

    padding: 4px 8px !important;
    border-radius: 6px !important;
    background-color: ${(p) => p.$themeColor.primaryFontColor} !important;

    text-transform: capitalize !important;
    border: 1px solid ${(p) => p.$themeColor.primaryDividerColor};
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-size: max(1.2rem, 12px);
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: ${(p) => p.$themeColor.primaryContainerColor} !important;
    margin-top: 14px;
    transition: all 0.2s ease;
    &:hover {
      background-color: ${(p) =>
        p.$themeColor.primaryContainerColor} !important;
      color: ${(p) => p.$themeColor.primaryFontColor} !important;
      opacity: 0.95;
      transition: all 0.2s ease;
    }
  }
`;

export const StyledAddProjectForm = styled(motion.div)`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 14px 0px;
  position: relative;
`;
