import { Docket } from "../../Models/dockets";

export interface IDocketStatusList {
  statusId: string;
  company: string;
  statusName: string;
  color: string;
  index: number;
  colorText: boolean;
  colorBackground: boolean;
  italics: boolean;
  bold: boolean;
  underline: boolean;
  strikethrough: boolean;
}

type IBudgetHeaders = "Name" | "Person" | "Budget" | "Order" | "Xero" | "Final";
export type IBudgetMainFilter =
  | "Due Today"
  | "Due This Week"
  | "Due This Month"
  | "Person"
  | "Over Budget"
  | "Status";

export type IBudgetSelectedDueDateFilter =
  | "Due Today"
  | "Due This Week"
  | "Due This Month";

export interface IBudgetTableHeaders {
  headerName: IBudgetHeaders;
  isDisplayed: boolean;
  cellWidth: string | number;
  keyProp: keyof Docket;
}

export interface IBudgetHeaderFilterMenu {
  filterName: IBudgetMainFilter;
  isDisplayed: boolean;
}

export const BUDGET_TABLE_HEADER_LIST: IBudgetTableHeaders[] = [
  {
    headerName: "Name",
    isDisplayed: true,
    cellWidth: "flex:1",
    keyProp: "docketName",
  },
  // {
  //   headerName: "Person",
  //   isDisplayed: true,
  //   cellWidth: 180,
  //   keyProp: "watching",
  // },
  {
    headerName: "Budget",
    isDisplayed: true,
    cellWidth: 180,
    keyProp: "costBudget",
  },
  {
    headerName: "Order",
    isDisplayed: true,
    cellWidth: 180,
    keyProp: "costActual",
  },
  {
    headerName: "Xero",
    isDisplayed: true,
    cellWidth: 180,
    keyProp: "costXero",
  },
  {
    headerName: "Final",
    isDisplayed: true,
    cellWidth: 180,
    keyProp: "costRunning",
  },
];

export const BUDGET_HEADER_FILTER_LIST: IBudgetHeaderFilterMenu[] = [
  {
    filterName: "Due Today",
    isDisplayed: true,
  },
  {
    filterName: "Due This Week",
    isDisplayed: true,
  },
  {
    filterName: "Due This Month",
    isDisplayed: true,
  },
  {
    filterName: "Person",
    isDisplayed: true,
  },
  {
    filterName: "Over Budget",
    isDisplayed: true,
  },
  {
    filterName: "Status",
    isDisplayed: true,
  },
];
