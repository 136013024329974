import {useXeroStore} from "../Store/useXeroStore";
import {useMemo} from "react";
import {Docket} from "../../Models/dockets";
import DocketQuery from "../../Services/QueryHooks/docketQueryAPI";
import useDoxleNotificationStore from "../../DoxleGeneralStore/useDoxleNotificationStore";
import {shallow} from "zustand/shallow";
import {useDoxleCurrentContextStore} from "../../DoxleGeneralStore/useDoxleCurrentContext";

interface Props {
  docket: Docket;
}
const useLinkUnlinkAccountButton = ({docket}:Props)=> {
  const unSavedAccountLinks = useXeroStore(state => state.unSavedAccountLinks)
  const unSavedAccount =useMemo(()=> unSavedAccountLinks.filter(
      link=> link.docketPk === docket.docketPk)?.[0],
    [unSavedAccountLinks])
  const showNotification = useDoxleNotificationStore(state => state.showNotification, shallow);
  const {company, project} = useDoxleCurrentContextStore(state => ({
    company: state.currentCompany,
    project: state.currentProject
  }), shallow);
  const updateDocketQuery = DocketQuery.useUpdateDocketQuery({
    showNotification,
    company,
    filter: {project: project?.projectId, view: "budget"},
  });
  const handleUnlinkDocket = (e: React.MouseEvent<HTMLDivElement>) => {
    updateDocketQuery.mutate({
      docketPk: docket.docketPk,
      accountTrackingId: null,
      accountCode: null,
      accountName: null
    })
  };
  const handleLinkDocket  = (e: React.MouseEvent<HTMLDivElement>) => {
    updateDocketQuery.mutate({
      docketPk: docket.docketPk,
      accountTrackingId: unSavedAccount?.accountId,
      accountCode: unSavedAccount?.accoutCode,
      accountName: unSavedAccount?.accountName
    })
  };
  return {
    unSavedAccount,
    handleUnlinkDocket,
    handleLinkDocket,
  }
}
export default useLinkUnlinkAccountButton
