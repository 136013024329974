import {
  StyledMenuToggledApp,
  StyledMenuControlBtn,
} from "./StyledMenuToggledApp";
import {
  DoxleBudgetFillIcon,
  DoxleBudgetIcons,
  DoxleContactIcon,
  DoxleFileFillIcon,
  DoxleFileIcon,
  DoxlePricebookFillIcon,
  DoxlePricebookIcon,
} from "../../../DoxleDesignPattern/DoxleGeneralIcons";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import { useDoxleCurrentContextStore } from "../../../DoxleGeneralStore/useDoxleCurrentContext";
import { useShallow } from "zustand/react/shallow";
import { StyledFileIcon } from "../NavBarEllipsis/Asset/SideMenuIcons";
import useMenuToggledApp from "./Hooks/useMenuToggledApp";
import Tooltip from "@mui/material/Tooltip";
import { DoxleMUITooltipSx } from "../../../DoxleDesignPattern/MUIOverwriteSx";
import { editRgbaAlpha } from "../../../Utilities/FunctionUtilities";
type Props = {};

const MenuToggledApp = () => {
  const { displayedMenuItem } = useDoxleCurrentContextStore(
    useShallow((state) => ({
      displayedMenuItem: state.displayedMenuItem,
    }))
  );
  const {
    doxleThemeColor,

    staticMenuColor,
    doxleFont,
  } = useDoxleThemeStore((state) => ({
    doxleThemeColor: state.doxleThemeColor,
    doxleFont: state.doxleFont,
    currentTheme: state.currentTheme,
    setTheme: state.setTheme,
    staticMenuColor: state.staticMenuColor,
    fontMode: state.fontMode,
    setFontMode: state.setFontMode,
    setIsGridMode: state.setIsGridMode,
    isGridMode: state.isGridMode,
  }));
  const { isSelected, handlePressMenuItem } = useMenuToggledApp();

  return (
    <StyledMenuToggledApp>
      {displayedMenuItem.map((menu, idx) => (
        <Tooltip
          PopperProps={{
            sx: {
              ...DoxleMUITooltipSx(doxleThemeColor, doxleFont),
            },
          }}
          arrow={true}
          slotProps={{
            arrow: {
              sx: {
                color: doxleThemeColor.primaryContainerColor,
              },
            },
          }}
          title={menu}
          key={`menu#${menu}-${idx}`}
        >
          <StyledMenuControlBtn
            onClick={() => handlePressMenuItem(menu)}
            layout="position"
            transition={{
              duration: 0.1,
            }}
            whileHover={{
              backgroundColor: staticMenuColor.staticHover,
              transition: {
                duration: 0.1,
              },
            }}
          >
            {menu === "Files" && (
              <DoxleFileIcon
                themeColor={doxleThemeColor}
                staticColor={
                  !isSelected(menu)
                    ? "#9EA6D7"
                    : staticMenuColor.staticFontColor
                }
                containerStyle={{
                  width: 24,
                }}
              />
            )}

            {menu === "Budgets" && (
              <DoxleBudgetIcons
                themeColor={doxleThemeColor}
                staticColor={
                  !isSelected(menu)
                    ? "#9EA6D7"
                    : staticMenuColor.staticFontColor
                }
                containerStyle={{
                  width: 18,
                }}
              />
            )}

            {menu === "Pricebook" && (
              <DoxlePricebookIcon
                themeColor={doxleThemeColor}
                staticColor={
                  !isSelected(menu)
                    ? "#9EA6D7"
                    : staticMenuColor.staticFontColor
                }
                containerStyle={{
                  width: 19,
                }}
              />
            )}

            {menu === "Contacts" && (
              <DoxleContactIcon
                themeColor={doxleThemeColor}
                staticColor={
                  !isSelected(menu)
                    ? "#9EA6D7"
                    : staticMenuColor.staticFontColor
                }
                containerStyle={{
                  width: 20,
                }}
              />
            )}
          </StyledMenuControlBtn>
        </Tooltip>
      ))}
    </StyledMenuToggledApp>
  );
};

export default MenuToggledApp;
