import React from "react";
import useAcceptOrderDialog from "./Hooks/useAcceptOrderDialog";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import {
  StyledAcceptDialogBtnWrapper,
  StyledAcceptOrderContentWrapper,
  StyledAcceptOrderDialog,
  StyledAcceptOrderTitle,
  StyledDialogActionButton,
  StyledSignatureButton,
} from "./StyledComponentEditOrder";
import { useEditOrderStore } from "../../OrdersStore/useEditOrderStore";
import { editRgbaAlpha } from "../../../Utilities/FunctionUtilities";
import { TransitionProps } from "@mui/material/transitions";
import Zoom from "@mui/material/Zoom";
import DialogActions from "@mui/material/DialogActions";
import useGetUserInfo from "../../../CoreContent/QueryDataHooks/GetQueryDataHooks/useGetUserInfo";
import { AnimatePresence, motion } from "framer-motion";
import CircularProgress from "@mui/material/CircularProgress";
import {
  DoxleAddIcon,
  DoxleEditIcon,
} from "../../../DoxleDesignPattern/DoxleGeneralIcons";
import DoxleEditSignaturePatch from "../../../DoxleDesignPattern/DoxleEditSignaturePatch/DoxleEditSignaturePatch";
import { IoChevronBack } from "react-icons/io5";
import ProgressScreen from "../../../Utilities/Lottie/ProgressScreen";

type Props = {};

const AcceptOrderDialog = (props: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }),
    shallow
  );
  const { editedOrder, setShowAcceptOrderDialog, showAcceptOrderDialog } =
    useEditOrderStore(
      (state) => ({
        editedOrder: state.editedOrder,
        setShowAcceptOrderDialog: state.setShowAcceptOrderDialog,
        showAcceptOrderDialog: state.showAcceptOrderDialog,
      }),
      shallow
    );
  const {
    onEditSignatureMode,
    setOnEditSignatureMode,
    handleAcceptOrder,
    isUpdatingOrder,
    shouldShowError,
  } = useAcceptOrderDialog({});

  const { user, isFetchingUserInfo } = useGetUserInfo();
  return (
    <StyledAcceptOrderDialog
      $themeColor={doxleThemeColor}
      open={Boolean(showAcceptOrderDialog)}
      onClose={() => {
        if (!isUpdatingOrder) setShowAcceptOrderDialog(undefined);
      }}
      layout
      slotProps={{
        backdrop: {
          sx: {
            bgcolor: editRgbaAlpha({
              rgbaColor: doxleThemeColor.primaryBackdropColor,
              alpha: "0.4",
            }),
          },
        },
      }}
      PaperProps={{
        elevation: 8,
      }}
      TransitionComponent={Transition}
      aria-describedby="add-mail-dialog-slide"
    >
      <StyledAcceptOrderTitle
        $themeColor={doxleThemeColor}
        $doxleFont={doxleFont}
      >
        {onEditSignatureMode && (
          <IoChevronBack
            color={doxleThemeColor.primaryFontColor}
            size={20}
            onClick={() => setOnEditSignatureMode(false)}
            style={{ cursor: "pointer", marginRight: 8 }}
          />
        )}
        {!onEditSignatureMode
          ? showAcceptOrderDialog === "accept"
            ? "Confirm Accept Order"
            : "Confirm Reject Order"
          : !user || !user.signature
          ? "Add Signature"
          : "Edit Signature"}
      </StyledAcceptOrderTitle>

      <StyledAcceptOrderContentWrapper
        $themeColor={doxleThemeColor}
        $doxleFont={doxleFont}
      >
        {!onEditSignatureMode && (
          <>
            <div className="order-info-text">
              Are you sure to{" "}
              {showAcceptOrderDialog === "accept" ? "accept" : "reject"} order{" "}
              <strong>***{editedOrder?.description}***</strong> ?
            </div>
            {showAcceptOrderDialog === "accept" && (
              <>
                <div className="signature-display-wrapper">
                  <StyledSignatureButton
                    $themeColor={doxleThemeColor}
                    $doxleFont={doxleFont}
                    disabled={isFetchingUserInfo}
                    layout
                    onClick={() => setOnEditSignatureMode(true)}
                  >
                    {!isFetchingUserInfo && (
                      <>
                        {!user || !user.signature ? (
                          <DoxleAddIcon
                            themeColor={doxleThemeColor}
                            containerStyle={{ marginRight: 4 }}
                          />
                        ) : (
                          <DoxleEditIcon
                            themeColor={doxleThemeColor}
                            containerStyle={{ marginRight: 4 }}
                          />
                        )}
                      </>
                    )}
                    <span className="btn-text">
                      {isFetchingUserInfo
                        ? "Getting your signature..."
                        : !user || !user.signature
                        ? "Add Signature"
                        : "Edit Signature"}
                    </span>
                    {isFetchingUserInfo && (
                      <CircularProgress
                        size={14}
                        sx={{
                          color: doxleThemeColor.primaryFontColor,
                          marginLeft: 2,
                        }}
                      />
                    )}
                  </StyledSignatureButton>

                  {user && user.signature && (
                    <motion.img
                      className="signature-img-wrapper"
                      src={user.signature}
                      animate={{
                        scale: [0, 1],
                        transition: {
                          duration: 0.3,
                          delay: 0.1,
                        },
                      }}
                    />
                  )}
                </div>

                <AnimatePresence>
                  {shouldShowError && !user?.signature && (
                    <motion.span
                      className="error-signature-text"
                      animate={{
                        y: [5, 0],
                        opacity: [0, 1],
                      }}
                      exit={{
                        y: [0, 5],
                        opacity: [1, 0],
                      }}
                    >
                      Please add a signature for your order!
                    </motion.span>
                  )}
                </AnimatePresence>
              </>
            )}
          </>
        )}

        {onEditSignatureMode && (
          <DoxleEditSignaturePatch
            containerStyle={{
              height: "50vh",
              width: "100%",
              maxHeight: 1000,
              minHeight: 400,
            }}
            onCompleteEdit={(newUser) => {
              setOnEditSignatureMode(false);
            }}
          />
        )}
      </StyledAcceptOrderContentWrapper>

      {!onEditSignatureMode && (
        <DialogActions
          sx={{
            width: "100%",
            flexDirection: "row",
            display: "flex",
            justifyContent: "space-between",
            padding: "0px !important",
          }}
        >
          <StyledAcceptDialogBtnWrapper>
            <StyledDialogActionButton
              $themeColor={doxleThemeColor}
              $doxleFont={doxleFont}
              onClick={handleAcceptOrder}
            >
              <span className="btn-text">Submit</span>
            </StyledDialogActionButton>
            <StyledDialogActionButton
              $themeColor={doxleThemeColor}
              $doxleFont={doxleFont}
              $reverseColor={true}
              onClick={() => setShowAcceptOrderDialog(undefined)}
            >
              <span className="btn-text" style={{ color: "red !important" }}>
                Cancel
              </span>
            </StyledDialogActionButton>
          </StyledAcceptDialogBtnWrapper>
        </DialogActions>
      )}
      {isUpdatingOrder && (
        <ProgressScreen
          progressType="progress"
          hasBackDrop={true}
          progressText="Processing request, please wait..."
          textStyle={{
            fontSize: "max(2rem,20px)",
            fontFamily: doxleFont.primaryFont,
            color: "white",
          }}
          containerStyle={{
            display: "flex",

            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
          animationSize={120}
        />
      )}
    </StyledAcceptOrderDialog>
  );
};

export default AcceptOrderDialog;
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Zoom ref={ref} {...props} />;
});
