import React from "react";
import { useDoxleThemeStore } from "../../../../DoxleGeneralStore/useDoxleThemeStore";
import { StyledEmptyQAImageBanner } from "./StyledComponentQAItemDetail";
import { EmptyQAImageBannerIcon } from "../../../QAIcons";
import { DropEvent, FileRejection, useDropzone } from "react-dropzone";
type Props = {
  onDrop: <T extends File>(
    acceptedFiles: T[],
    fileRejections: FileRejection[],
    event: DropEvent
  ) => Promise<void>;
};

const EmptyQAImageBanner = ({ onDrop }: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore((state) => ({
    doxleThemeColor: state.doxleThemeColor,
    doxleFont: state.doxleFont,
  }));
  const { getRootProps } = useDropzone({
    onDrop,
    accept: {
      "image/*": [".png", ".jpg", ".jpeg"],
    },
  });
  return (
    <StyledEmptyQAImageBanner
      {...getRootProps({})}
      $themeColor={doxleThemeColor}
      $doxleFont={doxleFont}
      layout="position"
      transition={{
        duration: 0.2,
        damping: 16,
      }}
    >
      <EmptyQAImageBannerIcon themeColor={doxleThemeColor} />
      <span className="banner-text">No image for this qa</span>
      <span className="description-text">
        Drop images or click on this zone to add more images
      </span>
    </StyledEmptyQAImageBanner>
  );
};

export default EmptyQAImageBanner;
