import { create } from "zustand";
import { Estimate, EstimatesFilters } from "../../Models/estimatesFilters";
import { produce } from "immer";

type TUpdatedEstimateFields = keyof Pick<
  Estimate,
  "description" | "quantity" | "unit" | "unitCost"
>;
interface EstimateStore {
  view: "QuickTender" | "Estimates";
  setView: (view: "QuickTender" | "Estimates") => void;
  estimatesList: Estimate[];
  setEstimateList: (list: Estimate[]) => void;
  pushEstimateList: (list: Estimate[]) => void;

  editedEstimateList: Estimate[];
  updateEstimateListItem: (
    estimate: Estimate,
    updatedField: TUpdatedEstimateFields,
    value: Estimate[TUpdatedEstimateFields]
  ) => void;

  estimatesQueryFilter: EstimatesFilters;
  setEstimatesQueryFilter: (filter: Partial<EstimatesFilters>) => void;
  isSearching: boolean;
  setIsSearching: (value: boolean) => void;
}

export const useEstimatesStore = create<EstimateStore>((set, get) => ({
  view: "Estimates",
  setView: (value: "QuickTender" | "Estimates") => set({ view: value }),
  estimatesList: [],
  setEstimateList: (list: Estimate[]) => set({ estimatesList: list }),
  pushEstimateList: (list: Estimate[]) =>
    set({ estimatesList: [...get().estimatesList, ...list] }),

  editedEstimateList: [],
  updateEstimateListItem: (
    estimate: Estimate,
    updatedField: TUpdatedEstimateFields,
    value: Estimate[TUpdatedEstimateFields]
  ) =>
    set((state) =>
      produce(state, (draftState) => {
        const index = draftState.editedEstimateList.findIndex(
          (item) => item.estimateId === estimate.estimateId
        );
        if (index !== -1) {
          draftState.editedEstimateList[index][updatedField] = value.toString();
        } else {
          draftState.editedEstimateList.push({
            ...estimate,
            [updatedField]: value,
          });
        }
      })
    ),
  estimatesQueryFilter: {},
  setEstimatesQueryFilter: (filter: Partial<EstimatesFilters>) =>
    set((state) =>
      produce(state, (draftState) => {
        draftState.estimatesQueryFilter = {
          ...state.estimatesQueryFilter,
          ...filter,
        };

        return draftState;
      })
    ),
  isSearching: false,
  setIsSearching: (value: boolean) => {
    if (!value && get().estimatesQueryFilter.search)
      set({
        estimatesQueryFilter: {
          ...get().estimatesQueryFilter,
          search: undefined,
        },
      });
    if (!value && get().estimatesQueryFilter.search)
      set({
        estimatesQueryFilter: {
          ...get().estimatesQueryFilter,
          search: undefined,
        },
      });
    set({ isSearching: value });
  },
}));
