import React from "react";
import { useDoxleCurrentContextStore } from "../../DoxleGeneralStore/useDoxleCurrentContext";
import { shallow } from "zustand/shallow";
import { getDrawingSetQKey } from "./drawingQueryAPI";
import { useQueryClient } from "@tanstack/react-query";
import { original, produce } from "immer";
import { DrawingSet, Sheet } from "../Models/drawings";
import { IApiPaginatedData } from "../../Models/axiosReturn";

type Props = {
  projectId: string;
  appendPos?: "start" | "end";
  overwrite?: boolean;
};

interface IUpdateDrawingIndex {
  item: DrawingSet;
  initIdx: number;
  destIdx: number;
}

interface SetDrawingsQueryData {
  handleRemoveDrawingSet: (deletedDrawingId: string) => void;
  handleUpdateDrawing: (updatedDrawing: DrawingSet) => void;
  handleAddDrawing: (newDrawing: DrawingSet) => void;
  handleRemoveMultiDrawings: (deletedDrawingIds: string[]) => void;
  handleUpdateSheet: (updatedSheet: Sheet) => void;
  handleDeleteMultipleSheet: (data: {
    deletedSheetIds: string[];
    drawingSetId: string;
  }) => void;
  handleUpdateDrawingIndex: (props: IUpdateDrawingIndex) => void;
}
const useSetDrawingsQueryData = ({
  projectId,
  appendPos,
  overwrite = true,
}: Props): SetDrawingsQueryData => {
  const { currentCompany } = useDoxleCurrentContextStore(
    (state) => ({
      currentCompany: state.currentCompany,
    }),
    shallow
  );
  const qKey = getDrawingSetQKey(projectId, currentCompany);
  const queryClient = useQueryClient();
  const handleRemoveDrawingSet = (deletedDrawingId: string) => {
    const queryData = queryClient.getQueryData(qKey);
    console.log("SET DRAWING QUERY DATA,DELETE");
    if (overwrite) {
      if (queryData)
        queryClient.setQueryData(qKey, (old: any) =>
          produce(old, (draft: any) => {
            let pageIndexContainItem: number = old.pages.findIndex(
              (page: any) =>
                Boolean(
                  page.data.results.find(
                    (drawing: DrawingSet) => drawing.setId === deletedDrawingId
                  ) !== undefined
                )
            );
            if (pageIndexContainItem !== -1)
              draft.pages[pageIndexContainItem].data.results = produce(
                draft.pages[pageIndexContainItem].data.results,
                (draftResult: DrawingSet[]) => {
                  draftResult.splice(
                    draftResult.findIndex(
                      (drawing) => drawing.setId === deletedDrawingId
                    ),
                    1
                  );

                  return draftResult;
                }
              );

            return draft;
          })
        );
      else queryClient.invalidateQueries(qKey);
    } else queryClient.invalidateQueries(qKey);
  };

  const handleUpdateDrawing = (updatedDrawing: DrawingSet) => {
    const queryData = queryClient.getQueryData(qKey);
    if (overwrite) {
      if (queryData)
        queryClient.setQueryData(qKey, (old: any) => {
          let pageIndexContainItem: number = old.pages.findIndex((page: any) =>
            Boolean(
              page.data.results.find(
                (drawing: DrawingSet) => drawing.setId === updatedDrawing.setId
              ) !== undefined
            )
          );
          if (pageIndexContainItem !== -1) {
            return produce(old, (draft: any) => {
              draft.pages[pageIndexContainItem].data.results = produce(
                draft.pages[pageIndexContainItem].data.results,
                (draftResult: DrawingSet[]) => {
                  const data = original(draftResult);
                  const item = data!.find(
                    (drawing) => drawing.setId === updatedDrawing.setId
                  );
                  if (item) Object.assign(item, updatedDrawing);

                  return draftResult;
                }
              );

              return draft;
            });
          } else queryClient.invalidateQueries(qKey);
        });
      else queryClient.invalidateQueries(qKey);
    } else queryClient.invalidateQueries(qKey);
  };

  const handleAddDrawing = (newDrawing: DrawingSet) => {
    const budgetData = queryClient.getQueryData(qKey);
    if (budgetData) {
      if (overwrite) {
        queryClient.setQueryData(qKey, (old: any) => {
          if (old) {
            const lengthOfPages = old.pages.length;
            return produce(old, (draftOld: any) => {
              draftOld.pages = produce(draftOld.pages, (draftPages: any) => {
                if (appendPos === "start") {
                  (
                    draftPages[0].data as IApiPaginatedData<DrawingSet>
                  ).results.unshift(newDrawing);
                } else {
                  (
                    draftPages[lengthOfPages - 1]
                      .data as IApiPaginatedData<DrawingSet>
                  ).results.push(newDrawing);
                }

                return draftPages;
              });

              return draftOld;
            });
          } else return queryClient.invalidateQueries(qKey);
        });
      } else queryClient.invalidateQueries(qKey);
    } else queryClient.invalidateQueries(qKey);
  };

  const handleRemoveMultiDrawings = (deletedDrawingIds: string[]) => {
    const queryData = queryClient.getQueryData(qKey);
    if (overwrite) {
      if (queryData)
        queryClient.setQueryData(qKey, (old: any) => {
          let deletedData: Array<{
            pageIndex: number;
            itemIndex: number;
          }> = [];
          deletedDrawingIds.forEach((id) => {
            (old.pages as any[]).forEach((page, pageIdx) => {
              const itemIndex = (page.data.results as DrawingSet[]).findIndex(
                (drawing) => drawing.setId === id
              );
              if (itemIndex !== -1)
                deletedData.push({
                  pageIndex: pageIdx,
                  itemIndex,
                });
            });
          });

          return produce(old, (draft: any) => {
            deletedData.forEach((data) => {
              draft.pages[data.pageIndex].data.results = produce(
                draft.pages[data.pageIndex].data.results,
                (draftResult: DrawingSet[]) => {
                  draftResult.splice(data.itemIndex, 1);
                  return draftResult;
                }
              );
            });

            return draft;
          });
        });
      else queryClient.invalidateQueries(qKey);
    } else queryClient.invalidateQueries(qKey);
  };

  const handleUpdateSheet = (updatedSheet: Sheet) => {
    const queryData = queryClient.getQueryData(qKey);
    if (overwrite) {
      if (queryData)
        queryClient.setQueryData(qKey, (old: any) => {
          let pageIndexContainItem: number = old.pages.findIndex((page: any) =>
            Boolean(
              page.data.results.find(
                (drawing: DrawingSet) =>
                  drawing.setId === updatedSheet.drawingSet
              ) !== undefined
            )
          );
          if (pageIndexContainItem !== -1) {
            return produce(old, (draft: any) => {
              draft.pages[pageIndexContainItem].data.results = produce(
                draft.pages[pageIndexContainItem].data.results,
                (draftResult: DrawingSet[]) => {
                  const data = original(draftResult);
                  const drawingSetItem = data!.find(
                    (drawing) => drawing.setId === updatedSheet.drawingSet
                  );

                  if (drawingSetItem) {
                    const sheetItem = drawingSetItem.sheets.find(
                      (sheet) => sheet.sheetId === updatedSheet.sheetId
                    );
                    if (sheetItem) Object.assign(sheetItem, updatedSheet);
                  }

                  return draftResult;
                }
              );

              return draft;
            });
          } else queryClient.invalidateQueries(qKey);
        });
      else queryClient.invalidateQueries(qKey);
    } else queryClient.invalidateQueries(qKey);
  };

  const handleDeleteMultipleSheet = (data: {
    deletedSheetIds: string[];
    drawingSetId: string;
  }) => {
    const { deletedSheetIds, drawingSetId } = data;
    const queryData = queryClient.getQueryData(qKey);
    if (overwrite) {
      if (queryData)
        queryClient.setQueryData(qKey, (old: any) => {
          let pageIndexContainItem: number = old.pages.findIndex((page: any) =>
            Boolean(
              page.data.results.find(
                (drawing: DrawingSet) => drawing.setId === drawingSetId
              ) !== undefined
            )
          );
          if (pageIndexContainItem !== -1) {
            return produce(old, (draft: any) => {
              draft.pages[pageIndexContainItem].data.results = produce(
                draft.pages[pageIndexContainItem].data.results,
                (draftResult: DrawingSet[]) => {
                  const data = original(draftResult);
                  const drawingSetItem = data!.find(
                    (drawing) => drawing.setId === drawingSetId
                  );

                  if (drawingSetItem) {
                    deletedSheetIds.forEach((deletedSheetId) =>
                      drawingSetItem.sheets.splice(
                        drawingSetItem.sheets.findIndex(
                          (sheet) => sheet.sheetId! === deletedSheetId
                        ),
                        1
                      )
                    );
                  }

                  return draftResult;
                }
              );

              return draft;
            });
          } else queryClient.invalidateQueries(qKey);
        });
      else queryClient.invalidateQueries(qKey);
    } else queryClient.invalidateQueries(qKey);
  };

  const handleUpdateDrawingIndex = ({
    item,
    initIdx,
    destIdx,
  }: IUpdateDrawingIndex) => {
    const queryData = queryClient.getQueryData(qKey);
    if (queryData && overwrite) {
      queryClient.setQueryData(qKey, (old: any) => {
        if (old) {
          const startPageIndex = (old.pages as any[]).findIndex((page) =>
            (page.data.results as DrawingSet[]).find(
              (item) => item.index === initIdx
            )
          );
          const endPageIndex = (old.pages as any[]).findIndex((page) =>
            (page.data.results as DrawingSet[]).find(
              (item) => item.index === destIdx
            )
          );

          if (startPageIndex !== -1 && endPageIndex !== -1) {
            const popPos = (
              old.pages[startPageIndex].data.results as DrawingSet[]
            ).findIndex((item) => item.index === initIdx);
            const insertPos = (
              old.pages[endPageIndex].data.results as DrawingSet[]
            ).findIndex((item) => item.index === destIdx);

            return produce(old, (draft: any) => {
              //!update increment case
              if (endPageIndex > startPageIndex) {
                for (let i = startPageIndex; i <= endPageIndex; i++) {
                  draft.pages[i].data.results = produce(
                    draft.pages[i].data.results,
                    (pageData: DrawingSet[]) => {
                      pageData.forEach((data, idx) => {
                        //! because all the items are sorted in increment so we have the range to update from destIdx => initIdx-1
                        if (data.index > initIdx && data.index <= destIdx)
                          data.index -= 1;
                        return data;
                      });
                      //perform insert to destination index

                      return pageData;
                    }
                  );
                }

                if (popPos !== -1) {
                  (
                    draft.pages[startPageIndex].data.results as DrawingSet[]
                  ).splice(popPos, 1);
                }
                if (insertPos !== -1)
                  (
                    draft.pages[endPageIndex].data.results as DrawingSet[]
                  ).splice(insertPos + 1, 0, item);
              }
              //!update decrement
              else if (endPageIndex < startPageIndex) {
                for (let i = endPageIndex; i <= startPageIndex; i++) {
                  draft.pages[i].data.results = produce(
                    draft.pages[i].data.results,
                    (pageData: DrawingSet[]) => {
                      pageData.forEach((data, idx) => {
                        //! because all the items are sorted in increment so we have the range to update from destIdx => initIdx-1
                        if (data.index >= destIdx && data.index < initIdx)
                          data.index += 1;
                        return data;
                      });

                      return pageData;
                    }
                  );
                }

                if (insertPos !== -1)
                  (
                    draft.pages[endPageIndex].data.results as DrawingSet[]
                  ).splice(insertPos, 0, item);

                if (popPos !== -1) {
                  (
                    draft.pages[startPageIndex].data.results as DrawingSet[]
                  ).splice(popPos, 1);
                }
              }
              //2 items same page
              else {
                draft.pages[endPageIndex].data.results = produce(
                  draft.pages[endPageIndex].data.results,
                  (pageData: DrawingSet[]) => {
                    if (initIdx > destIdx) {
                      for (let j = initIdx - 1; j >= destIdx; j--) {
                        pageData[j].index += 1;
                      }
                    }
                    if (initIdx < destIdx) {
                      for (let j = initIdx + 1; j <= destIdx; j++) {
                        pageData[j].index -= 1;
                      }
                    }
                    //perform insert to destination index
                    if (popPos !== -1) pageData.splice(initIdx, 1);
                    if (insertPos !== -1) pageData.splice(destIdx, 0, item);

                    return pageData;
                  }
                );
              }

              return draft;
            });
          }
        } else return queryClient.invalidateQueries(qKey);
      });
    } else queryClient.invalidateQueries(qKey);
  };

  return {
    handleRemoveDrawingSet,
    handleUpdateDrawing,
    handleAddDrawing,
    handleRemoveMultiDrawings,
    handleUpdateSheet,
    handleDeleteMultipleSheet,
    handleUpdateDrawingIndex,
  };
};

export default useSetDrawingsQueryData;
