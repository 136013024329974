import styled from "styled-components";

export const AboutPageContainer = styled.div`
  display: flex;
  width: 100%;
  height: 80%;
  @media (max-width: 600px){
    flex-direction: column;
    overflow-y: scroll;
    overflow-x: hidden;
  }
`;

export const AboutPageLeft = styled.div`
  width: calc(50% - 4rem);
  height: calc(100% - 4rem);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
   @media (max-width: 600px){
    width:  calc(100% - 4rem);
  }
`;

export const AboutPageRight = styled.div`
  width: calc(50% - 4rem);
  height: calc(100% - 4rem);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2rem;
  @media (max-width: 600px){
    width: calc(100% - 4rem);
  }
  
`;