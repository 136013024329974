import React, { useCallback, useMemo, useRef } from "react";
import { Order } from "../../../Models/orders";
import useBookingDocketComment from "../BookingDocketDetail/Hooks/useBookingDocketComment";
import {
  Components,
  ItemContent,
  ItemProps,
  Virtuoso,
  VirtuosoHandle,
} from "react-virtuoso";
import { IBooking } from "../../../Models/booking";
import { Discussion } from "../../../../Models/discussion";
import useBookingViewStore, {
  DocketInfoMenuValue,
} from "../../../store/useBookingViewStore";
import { shallow } from "zustand/shallow";
import DiscussionItem from "../BookingDocketDetail/DiscussionItem";
import BookingDocketOrder from "../BookingDocketDetail/BookingDocketOrder";
import { StyledVirtuosoListScroller } from "../../../CommonComponents/StyledComponentBookings";
import {
  StyledDiscussionListHeader,
  StyledDiscussionRowWrapper,
  StyledDocketOrderTitleSection,
  StyledOrderRowWrapper,
} from "../BookingDocketDetail/StyledComponentBookingDocketDetail";
import DoxleEmptyPlaceHolder from "../../../../DoxleDesignPattern/DoxleEmptyPlaceHolder/DoxleEmptyPlaceHolder";
import { AnimatePresence, Variants, motion } from "framer-motion";
import CircularProgress from "@mui/material/CircularProgress";
import { useDoxleThemeStore } from "../../../../DoxleGeneralStore/useDoxleThemeStore";
import BookingDocketMenu from "../BookingDocketMenu";
import AddBookingInputSection from "./AddBookingInputSection";
import useBookingDocketOrder from "../BookingDocketDetail/Hooks/useBookingDocketOrder";
import BookingOrderRow from "../BookingDocketDetail/BookingOrderRow";
import { AiFillCloseCircle } from "react-icons/ai";
import BookingOrderNumber from "../../../CommonComponents/BookingOrderNumber";
import SelectedOrderView from "../BookingDocketDetail/SelectedOrderView";
import EmptyOrderScreen from "../BookingDocketDetail/EmptyOrderScreen";
import { EmptyResponseBanner } from "../../../../Quotes/Content/QuotesIcon";
import { Error404Banner } from "../../../../DoxleDesignPattern/DoxleIcons/CommonIcons";
import { EmptyOrderBanner } from "../../../CommonComponents/BookingsIcon";
import CommentInputSection from "../../../BookingDocket/Components/BookingDocketDetail/CommentInputSection";

type Props = {
  docketId: string;
  selectedOrder?: Order;
  onSelectOrder?: (order: Order | undefined) => void;
};

interface ListContext {
  currentMenuTab: DocketInfoMenuValue;
  docketId: string;
  isErrorFetchingDiscussion: boolean;
  isErrorFetchingOrderList: boolean;
  isFetchingNextPage: boolean;
  selectedOrder: Order | undefined;
}
const AddBookingDocketPortrait = ({
  docketId,
  selectedOrder,
  onSelectOrder,
}: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
      currentTheme: state.currentTheme,
    }),
    shallow
  );
  const { currentMenuTab, setCurrentMenuTab } = useBookingViewStore(
    (state) => ({
      setCurrentMenuTab: state.setCurrentMenuTab,
      currentMenuTab: state.currentMenuTab,
    }),
    shallow
  );
  const {
    discussionList,
    isFetchingDiscussion,
    isSuccessFetchingDiscussion,
    isErrorFetchingDiscussion,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    currentVisibleRange,
    setCurrentVisibleRange,
    shouldShowIconScrollToBottom,
    filterDiscussionList,
    totalItemCount,
  } = useBookingDocketComment({ docketId: docketId });

  const {
    ordersList,
    isFetchingOrderList,
    isSuccessFetchingOrderList,
    isErrorFetchingOrderList,
    refetchOrderList,
    isRefetchingOrderList,
    fetchMoreOrder,
    isFetchingMoreOrder,
  } = useBookingDocketOrder({
    docketId,
    onSelectOrder,
    selectedOrder,
    mode: "add",
  });

  const rowAnimatedVariants: Variants = {
    initial: {
      x: -5,
      opacity: 0,
    },
    entering: {
      x: 0,
      opacity: 1,
    },
  };

  const commentListRef = useRef<VirtuosoHandle>(null);

  const handleScrollToBottom = () => {
    commentListRef.current?.scrollToIndex({
      index: discussionList.length - 1,
      behavior: "smooth",
    });
  };
  //* render list
  const itemContent: ItemContent<Discussion | Order | string, ListContext> =
    useCallback(
      (index, item, context) =>
        context.currentMenuTab === "Comment" ? (
          <DiscussionItem
            discussionItem={item as Discussion}
            key={`discussion#${(item as Discussion).commentId}`}
          />
        ) : context.selectedOrder ? (
          <BookingDocketOrder
            onSelectOrder={onSelectOrder}
            selectedOrder={selectedOrder}
            mode={"add"}
          />
        ) : (
          <BookingOrderRow order={item as Order} />
        ),
      [selectedOrder]
    );
  const components: Components<Discussion | Order | string, ListContext> =
    useMemo(
      () => ({
        Scroller: React.forwardRef((props, ref) => (
          <StyledVirtuosoListScroller ref={ref} {...props} />
        )),
        Item: React.forwardRef<
          HTMLDivElement,
          ItemProps<Discussion | Order | string>
        >((props, ref) => {
          console.log(typeof props.item);
          if ((props.item as Discussion).commentId !== undefined) {
            return (
              <StyledDiscussionRowWrapper
                {...props}
                ref={ref}
                key={(props.item as Discussion).commentId}
              />
            );
          } else if ((props.item as Order).orderId !== undefined) {
            return (
              <StyledOrderRowWrapper
                key={(props.item as Order).orderId}
                $themeColor={doxleThemeColor}
                {...props}
                initial="initial"
                animate="entering"
                variants={rowAnimatedVariants}
                transition={{
                  duration: 0.2,
                }}
                onClick={() => {
                  if (onSelectOrder) onSelectOrder(props.item as Order);
                }}
              />
            );
          }
          return <div {...props} ref={ref} key={"orderItem"}></div>;
        }),
        EmptyPlaceholder: (props) => {
          const context = props.context;
          if (context?.currentMenuTab === "Comment") {
            return (
              <DoxleEmptyPlaceHolder
                headTitleText={
                  context.isErrorFetchingDiscussion
                    ? "Something wrong!"
                    : "No Comments"
                }
                subTitleText={
                  context.isErrorFetchingDiscussion
                    ? "Failed to get comments..."
                    : "Add your comments to communicate with your colleagues..."
                }
                containerStyle={{
                  height: "fit-content",
                  marginTop: 50,
                }}
                illustration={
                  context.isErrorFetchingDiscussion ? (
                    <Error404Banner
                      themeColor={doxleThemeColor}
                      containerStyle={{ width: "30%" }}
                    />
                  ) : (
                    <EmptyResponseBanner themeColor={doxleThemeColor} />
                  )
                }
                headTitleTextStyle={{ fontSize: "3rem" }}
                subTitleTextStyle={{ fontSize: "1.8rem" }}
              />
            );
          } else {
            return (
              <DoxleEmptyPlaceHolder
                headTitleText={
                  context?.isErrorFetchingOrderList
                    ? "Something wrong!"
                    : "Oops, no purchase orders"
                }
                subTitleText={
                  context?.isErrorFetchingOrderList
                    ? "Failed to get orders..."
                    : "You need to have a Signed Purchase Order to make a valid booking."
                }
                containerStyle={{
                  height: "fit-content",
                  marginTop: 50,
                }}
                illustration={
                  context?.isErrorFetchingOrderList ? (
                    <Error404Banner
                      themeColor={doxleThemeColor}
                      containerStyle={{ width: "30%" }}
                    />
                  ) : (
                    <EmptyOrderBanner {...doxleThemeColor} />
                  )
                }
                headTitleTextStyle={{ fontSize: "3rem" }}
                subTitleTextStyle={{ fontSize: "1.8rem" }}
              />
            );
          }
        },
        Header: React.forwardRef((props, ref) => (
          <div
            style={{
              width: "100%",
              height: "auto",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <AddBookingInputSection />
            <BookingDocketMenu />
            <AnimatePresence>
              {props.context?.isFetchingNextPage && (
                <StyledDiscussionListHeader
                  initial={false}
                  animate={{
                    y: [-10, 0],
                    opacity: [0, 1],
                  }}
                  exit={{
                    y: [0, -10],
                    opacity: [1, 0],
                  }}
                  transition={{
                    duration: 0.2,
                  }}
                >
                  <CircularProgress
                    size="1.4rem"
                    style={{
                      color: doxleThemeColor.primaryFontColor,

                      alignSelf: "center",
                      justifySelf: "center",
                    }}
                  />
                </StyledDiscussionListHeader>
              )}
            </AnimatePresence>
          </div>
        )),
      }),
      [currentMenuTab]
    );
  const listStyle: React.CSSProperties = {
    flex: 1,
    width: "100%",
  };
  return (
    <div style={{height: "100%", display:"flex", flexDirection:"column"}}>
      <Virtuoso
        style={listStyle}
        data={
          currentMenuTab === "Comment"
            ? discussionList
            : selectedOrder
            ? ["single-order"]
            : ordersList
        }
        itemContent={itemContent}
        components={components}
        ref={commentListRef}
        atTopStateChange={fetchNextPage}
        context={{
          isFetchingNextPage,
          isErrorFetchingDiscussion,
          isErrorFetchingOrderList,
          docketId: docketId,
          currentMenuTab,
          selectedOrder,
        }}
        atTopThreshold={0.4}
        rangeChanged={setCurrentVisibleRange}
      />
      {currentMenuTab === "Comment" && (
        <CommentInputSection
          commentListRef={commentListRef}
          discussionList={discussionList}
          filterDiscussionList={filterDiscussionList}
        />
      )}
    </div>
  );
};

export default AddBookingDocketPortrait;
