import { useXeroStore } from "../Store/useXeroStore";
import { Popover } from "@mui/material";
import { shallow } from "zustand/shallow";
import { Account } from "../Models/account";
import { Components, Virtuoso } from "react-virtuoso";
import {
  StyledAccountDropDownContainer,
  StyledAccountDropDownRow,
} from "./styledComponents";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import useGetAccounts from "../Hooks/useGetAccounts";
import { useMemo } from "react";
import React from "react";
import { StyledListScroller } from "../../DoxleDesignPattern/ProjectSelectWithDropdown/StyledComponentsProjectSelectWithDropdown";
import DoxleEmptyPlaceHolder from "../../DoxleDesignPattern/DoxleEmptyPlaceHolder/DoxleEmptyPlaceHolder";
import { Error404Banner } from "../../DoxleDesignPattern/DoxleIcons/CommonIcons";
import { EmptyAccountBanner } from "./XeroIcons";
interface Props {}

interface IAccountListContext {
  isErrorFetchingAccountList: boolean;
}
const SelectAccountDropDown = ({}: Props) => {
  const styleProps = useDoxleThemeStore(
    (state) => ({
      $themeColor: state.doxleThemeColor,
      $doxleFont: state.doxleFont,
      $currentTheme: state.currentTheme,
    }),
    shallow
  );
  const {
    selectAccountDropDownProps,
    handleClose,
    setUnsavedAccountLinks,
    unsavedAccountLinks,
  } = useXeroStore(
    (state) => ({
      selectAccountDropDownProps: state.selectAccountDropDownProps,
      handleClose: state.closeAccountDropDown,
      setUnsavedAccountLinks: state.setUnsavedAccountLinks,
      unsavedAccountLinks: state.unSavedAccountLinks,
    }),
    shallow
  );
  const { accountsList, isFetchingAccountList, isErrorFetchingAccountList } =
    useGetAccounts({});
  const { docket, anchorEl } = selectAccountDropDownProps;
  const canBeOpen = Boolean(docket && anchorEl);
  const id = canBeOpen ? "nb-status-menu-popper" : undefined;
  const handleAccountClick = (account: Account | null) => {
    if (docket && account)
      setUnsavedAccountLinks([
        ...unsavedAccountLinks.filter(
          (acc) => acc.docketPk !== docket.docketPk
        ),
        {
          docketPk: docket?.docketPk,
          accountId: account.accountId,
          accoutCode: account.accountCode,
          accountName: account.accountName,
        },
      ]);
    else if (docket && account === null)
      setUnsavedAccountLinks(
        unsavedAccountLinks.filter((acc) => acc.docketPk !== docket.docketPk)
      );
    handleClose();
  };
  const components: Components<Account, IAccountListContext> = useMemo(
    () => ({
      Scroller: React.forwardRef((props, ref) => (
        <StyledListScroller style={{ ...props.style }} ref={ref} {...props} />
      )),

      EmptyPlaceholder: (props) => (
        <DoxleEmptyPlaceHolder
          headTitleText={
            props.context?.isErrorFetchingAccountList
              ? "Something wrong"
              : "No Items found"
          }
          subTitleText={
            props.context?.isErrorFetchingAccountList
              ? "Please try to refresh page, we are sorry for this!"
              : "Add more accounts in Xero to link your budgets..."
          }
          containerBgColor="transparent"
          headTitleTextStyle={{
            fontSize: "20px",
          }}
          subTitleTextStyle={{
            fontSize: "14px",
          }}
          containerStyle={{
            width: "calc(100% - 16px)",
            height: "calc(100% - 16px)",
            padding: 8,
          }}
          illustration={
            props.context?.isErrorFetchingAccountList ? (
              <Error404Banner
                themeColor={styleProps.$themeColor}
                containerStyle={{ width: "60%", marginBottom: 14 }}
              />
            ) : (
              <EmptyAccountBanner
                themeColor={styleProps.$themeColor}
                containerStyle={{ width: "60%", marginBottom: 14 }}
              />
            )
          }
        />
      ),
    }),
    []
  );
  return (
    <Popover
      id={id}
      open={Boolean(docket && anchorEl)}
      anchorEl={anchorEl}
      disableScrollLock
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      onClose={handleClose}
      sx={{
        "& .MuiPopover-paper": {
          backgroundColor: "transparent",
          overflow: "visible",
          boxShadow: "none",
        },
      }}
      transitionDuration={{ appear: 200, exit: 200 }}
      elevation={4}
    >
      <StyledAccountDropDownContainer {...styleProps}>
        <StyledAccountDropDownRow
          {...styleProps}
          key={"null-account"}
          onClick={() => handleAccountClick(null)}
        >
          Do Not Link
        </StyledAccountDropDownRow>
        <Virtuoso
          style={{ flex: 1, width: "100%" }}
          data={accountsList}
          components={components}
          itemContent={(index, account) => (
            <StyledAccountDropDownRow
              {...styleProps}
              key={account.accountId}
              onClick={() => handleAccountClick(account)}
            >
              {account.accountName}
            </StyledAccountDropDownRow>
          )}
          context={{ isErrorFetchingAccountList }}
        />
      </StyledAccountDropDownContainer>
    </Popover>
  );
};
export default SelectAccountDropDown;
