import { Button, ButtonProps } from "@mui/material";
import { ForwardRefComponent, HTMLMotionProps, motion } from "framer-motion";
import React from "react";

import ReplyIcon from "@mui/icons-material/Reply";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
interface Props
  extends Omit<
    ForwardRefComponent<HTMLDivElement, HTMLMotionProps<"div">>,
    "style" | "$$typeof"
  > {
  buttonText?: string;
  backIcon?: React.ReactNode;
  buttonWrapperStyle?: React.CSSProperties;
  buttonTextStyle?: React.CSSProperties;
  onClick?: (
    event?: React.MouseEvent<HTMLDivElement, MouseEvent> | undefined
  ) => void;
}

const DoxleNavigateBackButton = ({
  buttonText,

  buttonWrapperStyle,
  buttonTextStyle,
  onClick,
  backIcon,
  ...rest
}: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }),
    shallow
  );

  //*Animation
  const btnAnimatedVariants = {
    initial: {
      y: 0,
      opacity: 1,
    },
    entering: {
      scaleX: 1,
      y: 0,
      opacity: 1,

      transition: {
        duration: 0.4,
      },
    },
    hovering: {
      y: [0, -2],

      opacity: [1, 0.8],
      transition: {
        duration: 0.2,
      },
    },
    tapping: {
      scale: 0.8,
    },
  };
  return (
    <motion.div
      variants={btnAnimatedVariants}
      initial="initial"
      animate="entering"
      whileHover="hovering"
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        ...buttonWrapperStyle,
      }}
      whileTap="tapping"
      {...rest}
      onClick={onClick}
    >
      {backIcon ? (
        backIcon
      ) : (
        <ReplyIcon
          style={{
            fontSize: buttonWrapperStyle?.fontSize ?? "3rem",
            marginRight: 4,
            color: "inherit",
          }}
        />
      )}
      <span
        style={{
          ...buttonTextStyle,
          fontFamily: buttonTextStyle?.fontFamily ?? doxleFont.primaryFont,
          fontSize: buttonTextStyle?.fontSize ?? "1.4rem",
          fontWeight: buttonTextStyle?.fontWeight ?? "500",
        }}
      >
        {buttonText || "Back"}
      </span>
    </motion.div>
  );
};

export default DoxleNavigateBackButton;
