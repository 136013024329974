import usePricebookRateTablePagination from "./Hooks/usePricebookRateTablePagination";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import DoxlePaginationControl from "../../../DoxleDesignPattern/DoxlePaginationControl/DoxlePaginationControl";

type Props = {};

const PricebookRateTablePagination = (props: Props) => {
  const {
    currentPage,
    handleSetPage,
    handleSetPageSize,
    totalPage,
    currentPageSize,

    totalItem,
    hasPreviousPage,
    hasNextPage,
    fetchNextRateList,
    fetchPreviousRateList,
  } = usePricebookRateTablePagination({});

  return (
    <DoxlePaginationControl
      totalItemCount={totalItem}
      totalPage={totalPage}
      currentPage={currentPage}
      currentPageSize={currentPageSize}
      onSelectPage={handleSetPage}
      onSelectPageSize={handleSetPageSize}
      hasPreviousPage={hasPreviousPage}
      hasNextPage={hasNextPage}
      onFetchNext={fetchNextRateList}
      onFetchPrevious={fetchPreviousRateList}
    />
  );
};

export default PricebookRateTablePagination;
