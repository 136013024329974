import {
  Button,
  ButtonProps,
  CircularProgress,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";

import { motion } from "framer-motion";
import React, { useState } from "react";
import {
  DoxleFont,
  DoxleThemeColor,
  useDoxleThemeStore,
} from "../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import { editRgbaAlpha, TRgbaFormat } from "../../Utilities/FunctionUtilities";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import { StyledMailAddBtn } from "../../CoreContent/Projects/ProjectIcon";

interface Props extends ButtonProps {
  btnText?: string;
  showBtnIcon?: false | "start" | "end";
  btnIcon?: string | React.ReactNode;
  isLoading?: boolean;
  btnBaseColor?: TRgbaFormat;
  colorText?: true;
  iconOnly?: boolean;
  tooltipText?: string;
  textStyle?: React.CSSProperties;
}
const MotionButton = motion(Button);
interface IStyledAnimatedButton
  extends Omit<
    ButtonProps,
    "onDragStart" | "onAnimationStart" | "onDragEnd" | "onDrag"
  > {
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
  $btnBaseColor?: TRgbaFormat;
  $colorText?: true;
  $iconOnly?: boolean;
}

const AnimatedButton = styled(
  ({
    $themeColor,
    $doxleFont,
    $btnBaseColor,
    $colorText,
    $iconOnly,
    ...rest
  }: IStyledAnimatedButton) => (
    <MotionButton
      {...rest}
      initial={{
        scale: 0.9,
        opacity: 0,
      }}
      animate={{
        scale: 1,
        opacity: 1,
        transition: {
          duration: 0.2,
          delay: 0.2,
        },
      }}
      exit={{
        y: [0, 10],
        opacity: 0,
      }}
      layout
    />
  )
)`
  && {
    display: flex;
    flex-direction: row;
    background-color: ${(p) =>
      p.$iconOnly
        ? "transparent"
        : editRgbaAlpha({
            rgbaColor: p.$btnBaseColor ?? p.$themeColor.doxleColor,
            alpha: "0.1",
          })};
    border-radius: 0 !important;
    height: 26px;
    min-width: 10px;
    padding: 0 5px !important;
    margin-left: 5px;
    color: transparent !important;

    .btn-text {
      color: ${(p) =>
        p.$colorText
          ? p.$btnBaseColor
            ? p.$btnBaseColor
            : p.$themeColor.doxleColor
          : editRgbaAlpha({
              rgbaColor: p.$themeColor.primaryFontColor,
              alpha: "0.7",
            })};
      font-family: ${(p) => p.$doxleFont.primaryFont};
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      text-transform: capitalize;
      flex: 1;
      text-align: center;
    }

    &:hover {
      background-color: ${(p) =>
        p.$iconOnly
          ? "transparent"
          : editRgbaAlpha({
              rgbaColor: p.$btnBaseColor ?? p.$themeColor.doxleColor,
              alpha: "0.4",
            })} !important;
      transition: 0.4s;
    }

    .img {
      max-height: 1.4rem;
    }

    .icon-container {
      padding: 0px 1.5px;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .start-icon-container {
      padding-left: 1.5px;
      padding-right: 0.5px;
    }
    .end-icon-container {
      padding-left: 0.5px;
      padding-right: 1.5px;
    }
  }
`;
const DoxleAddNewButton = React.forwardRef<HTMLButtonElement, Props>(
  (
    {
      onDragStart,
      onAnimationStart,
      onDragEnd,
      onDrag,
      btnText,
      showBtnIcon,
      btnIcon,
      isLoading,
      btnBaseColor,
      colorText,
      iconOnly = false,
      tooltipText,
      textStyle,
      ...rest
    }: Props,
    ref
  ) => {
    const [showToolTip, setShowToolTip] = useState(false);
    const { doxleThemeColor, doxleFont, currentTheme } = useDoxleThemeStore(
      (state) => ({
        doxleThemeColor: state.doxleThemeColor,
        doxleFont: state.doxleFont,
        currentTheme: state.currentTheme,
      })
    );
    const iconColor = editRgbaAlpha({
      rgbaColor: doxleThemeColor.primaryFontColor,
      alpha: "0.7",
    });

    return (
      <Tooltip
        PopperProps={{
          disablePortal: true,
          sx: {
            "& .MuiTooltip-tooltip": {
              fontFamily: doxleFont.primaryFont,
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: "14px",

              color: `${doxleThemeColor.primaryFontColor} !important`,
              textTransform: " capitalize",
              backgroundColor: `${doxleThemeColor.primaryTextFieldBackgroundColor} !important`,
              border: `1px solid ${doxleThemeColor.primaryDividerColor} !important`,
              borderRadius: "4px",

              padding: "4px 8px",
            },
          },
        }}
        open={showToolTip}
        onClose={() => setShowToolTip(false)}
        onOpen={() => {
          if (tooltipText) setShowToolTip(true);
        }}
        title={tooltipText}
      >
        <div>
          <AnimatedButton
            ref={ref}
            style={{
              backgroundColor: rest.disabled
                ? "transparent"
                : rest.style?.backgroundColor,
            }}
            $themeColor={doxleThemeColor}
            $doxleFont={doxleFont}
            $btnBaseColor={btnBaseColor}
            $colorText={colorText}
            $iconOnly={iconOnly}
            {...rest}
          >
            {!iconOnly ? (
              <>
                {showBtnIcon === undefined ||
                  (showBtnIcon === "start" && (
                    <div className="icon-container start-icon-container">
                      {/*{!isLoading && <Icon />}*/}
                      {!isLoading ? (
                        btnIcon ? (
                          typeof btnIcon === "string" ? (
                            <img
                              src={btnIcon}
                              style={{
                                filter:
                                  currentTheme === "dark"
                                    ? "invert(1)"
                                    : "unset",
                              }}
                            />
                          ) : (
                            btnIcon
                          )
                        ) : (
                          <KeyboardArrowDownRoundedIcon
                            style={{ color: iconColor, fontSize: "14px" }}
                          />
                        )
                      ) : (
                        <CircularProgress
                          style={{ color: iconColor }}
                          size="14px"
                        />
                      )}
                    </div>
                  ))}

                <div className="btn-text" style={textStyle}>
                  {btnText ?? "New"}
                </div>

                {showBtnIcon === "end" && (
                  <div className="icon-container end-icon-container">
                    {/*{!isLoading && <Icon />}*/}
                    {!isLoading ? (
                      btnIcon ? (
                        typeof btnIcon === "string" ? (
                          <img
                            src={btnIcon}
                            style={{
                              filter:
                                currentTheme === "dark" ? "invert(1)" : "unset",
                            }}
                          />
                        ) : (
                          btnIcon
                        )
                      ) : (
                        <KeyboardArrowDownRoundedIcon
                          style={{ color: iconColor, fontSize: "14px" }}
                        />
                      )
                    ) : (
                      <CircularProgress
                        style={{ color: iconColor }}
                        size="14px"
                      />
                    )}
                  </div>
                )}
              </>
            ) : (
              <>
                {!isLoading ? (
                  <StyledMailAddBtn {...doxleThemeColor} />
                ) : (
                  <div className="icon-container start-icon-container">
                    <CircularProgress
                      style={{ color: iconColor }}
                      size="14px"
                    />
                  </div>
                )}
              </>
            )}
          </AnimatedButton>
        </div>
      </Tooltip>
    );
  }
);

export default DoxleAddNewButton;
