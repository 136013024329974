import { CircularProgress, Fade } from "@mui/material";

import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import { getScopeImgMutationKey } from "../QueryAPI/scopeOfWorkQueryAPI";
import { ScopeOfWorkItem } from "../Model/scopeOfWorks";
import {
  StyledScopeImageDisplayerTooltip,
  StyledScopeItemImageSectionContainer,
} from "./StyledComponentScopeOfWorkFullScreen";
import { useIsMutating } from "@tanstack/react-query";
import { RiImageAddLine } from "react-icons/ri";
import { editRgbaAlpha } from "../../Utilities/FunctionUtilities";
type Props = {
  scopeItem: ScopeOfWorkItem;
  handleClickAddSpecImageBtn: () => void;
};

const ScopeItemImageSection = ({
  scopeItem,
  handleClickAddSpecImageBtn,
}: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore((state) => ({
    doxleThemeColor: state.doxleThemeColor,
    doxleFont: state.doxleFont,
  }));

  const addMutationKey = getScopeImgMutationKey("add", scopeItem.scopeItemId);
  const isAddingImg = useIsMutating({ mutationKey: addMutationKey }) > 0;
  return (
    <StyledScopeImageDisplayerTooltip
      TransitionComponent={Fade}
      TransitionProps={{ timeout: 200 }}
      title="Click to view and add more images"
      arrow
      placement="top-start"
      PopperProps={{
        sx: {
          "& .MuiTooltip-tooltip": {
            fontFamily: doxleFont.primaryFont,
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: "10px",

            color: `${doxleThemeColor.primaryFontColor} !important`,

            backgroundColor: `${doxleThemeColor.primaryTextFieldBackgroundColor} !important`,
            border: `1px solid ${doxleThemeColor.primaryDividerColor} !important`,
            borderRadius: "4px",

            padding: "4px",
          },
          marginBottom: "-10px !important",
        },
      }}
      slotProps={{
        arrow: {
          sx: {
            bgcolor: `${doxleThemeColor.primaryTextFieldBackgroundColor} !important`,
          },
        },
      }}
    >
      <StyledScopeItemImageSectionContainer
        $themeColor={doxleThemeColor}
        $doxleFont={doxleFont}
        layout="position"
        transition={{
          duration: 0.2,
          damping: 12,
        }}
        onClick={handleClickAddSpecImageBtn}
      >
        <RiImageAddLine
          size={18}
          style={{ marginInline: 4 }}
          color={editRgbaAlpha({
            rgbaColor: doxleThemeColor.primaryFontColor,
            alpha: "0.7",
          })}
        />
        <span className="image-count-text">
          {scopeItem.imageCount} item{scopeItem.imageCount > 1 ? "s" : ""}
        </span>
        {isAddingImg && (
          <CircularProgress
            sx={{ color: doxleThemeColor.primaryFontColor }}
            size={"14px"}
            style={{ marginInline: 8 }}
          />
        )}
      </StyledScopeItemImageSectionContainer>
    </StyledScopeImageDisplayerTooltip>
  );
};

export default ScopeItemImageSection;
