import { create } from "zustand";
import Order, { NEW_ORDER_LINE_TEMPLATE, OrderLine } from "../Models/order";
import { immer } from "zustand/middleware/immer";

interface EditOrderStore {
  editedOrder: Order | undefined;
  setEditedOrder: (item: Order | undefined) => void;
  updateEditedOrder: (data: Partial<Order>) => void;
  updateOrderLine: (lineIndex: number, data: Partial<OrderLine>) => void;

  addNewOrderLine: (lineDes?: string) => void;
  removeOrderLine: (lineIndex: number) => void;
  readOnly: boolean;
  setReadOnly: (bool: boolean) => void;

  showEmailOrderDialog: boolean;
  setShowEmailOrderDialog: (value: boolean) => void;

  showAcceptOrderDialog: "accept" | "reject" | undefined;
  setShowAcceptOrderDialog: (value: "accept" | "reject" | undefined) => void;

  showOverBudgetWarning: boolean;
  setShowOverBudgetWarning: (bool: boolean) => void;

  showPaymentOptions: boolean;
  setShowPaymentOptions: (bool: boolean) => void;

  resetStore: () => void;
}

export const useEditOrderStore = create(
  immer<EditOrderStore>((set, get) => ({
    editedOrder: undefined,
    setEditedOrder: (item: Order | undefined) =>
      set((state) => {
        state.editedOrder = item;
      }),
    updateEditedOrder: (data: Partial<Order>) =>
      set((state) => {
        if (state.editedOrder) Object.assign(state.editedOrder, data);
      }),
    updateOrderLine: (lineIndex: number, data: Partial<OrderLine>) =>
      set((state) => {
        if (
          state.editedOrder &&
          state.editedOrder.lines &&
          lineIndex < state.editedOrder.lines.length
        ) {
          const updatedLine = state.editedOrder.lines[lineIndex];
          if (updatedLine) Object.assign(updatedLine, data);
        }
      }),

    addNewOrderLine: (lineDes?: string) =>
      set((state) => {
        if (state.editedOrder) {
          const newLine = NEW_ORDER_LINE_TEMPLATE(
            get().editedOrder!,
            get().editedOrder?.lines?.length,
            lineDes
          );

          if (state.editedOrder.lines) state.editedOrder.lines.push(newLine);
          else state.editedOrder.lines = [newLine];
        }
      }),
    removeOrderLine: (lineIndex: number) =>
      set((state) => {
        if (state.editedOrder && state.editedOrder.lines) {
          state.editedOrder.lines = state.editedOrder.lines.filter(
            (_, idx) => idx !== lineIndex
          );
        }
      }),
    readOnly: false,
    setReadOnly: (bool: boolean) => set({ readOnly: bool }),

    showEmailOrderDialog: false,
    setShowEmailOrderDialog: (value: boolean) =>
      set({ showEmailOrderDialog: value }),

    showAcceptOrderDialog: undefined,
    setShowAcceptOrderDialog: (value: "accept" | "reject" | undefined) =>
      set({ showAcceptOrderDialog: value }),
    showOverBudgetWarning: false,
    setShowOverBudgetWarning: (bool: boolean) => set({showOverBudgetWarning: bool}),
    showPaymentOptions: false,
    setShowPaymentOptions: (showPaymentOptions: boolean) => set({showPaymentOptions}),
    resetStore: () =>
      set((state) => ({
        ...state,
        readOnly: false,
        editedOrder: undefined,
      })),
  }))
);
