import {Contact, ContactCompany} from "../../Models/addressBook";
import {IPatchExistingCompanyContacts} from "../../Services/QueryHooks/contactsAPI";

export const checkChangesContactCompany = (props: {
  original: ContactCompany;
  editted: ContactCompany;
  updatedContacts: Contact[];
  newAddedContacts: Contact[];
}) => {
  const { original, editted, updatedContacts, newAddedContacts } = props;
  let changes: IPatchExistingCompanyContacts = {
    contactCompany: editted.contactCompanyId as string,
  };
  console.log("UPDATED CONTACTS:", updatedContacts);
  if (original.name !== editted.name) changes.name = editted.name;
  if (original.abn !== editted.abn) changes.abn = editted.abn;
  if (original.address !== editted.address) changes.address = editted.address;
  if (updatedContacts.length > 0) {
    changes.contacts = changes.contacts
      ? [...changes.contacts, ...updatedContacts]
      : [...updatedContacts];
  }
  if (newAddedContacts) {
    changes.contacts = changes.contacts
      ? [...changes.contacts, ...newAddedContacts]
      : [...newAddedContacts];
  }
  if (Object.keys(changes).length > 1) return changes;
  else return undefined;
};
