import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { shallow } from "zustand/shallow";
import styled from "styled-components";
import MoreHorizRoundedIcon from "@mui/icons-material/MoreHorizRounded";
import moment from "moment";
import { useMailSideScreenStore } from "../../MailSideScreenStore/UseMailSideScreenStore";
import { useDoxleThemeStore } from "../../../../DoxleGeneralStore/useDoxleThemeStore";
import {
  StyledEmailInfo,
  StyledInfoTitle,
  StyledMailBodySection,
  StyledMailCommentsSection,
  StyledMailGeneralSection,
  StyledMailInfoContainer,
  StyledMailResponseSection,
  StyledMainInfoContainer,
  StyledResponseInput,
  StyledVirtuosoScroller,
} from "../StyledMailSideScreen";
import MailConversationAPI from "../../../Hooks/mailConversationAPI";
import { Mail, MailComments, ReplyMail } from "../../../Models/mail";
import { useDoxleAuthStore } from "../../../../DoxleGeneralStore/useDoxleAuthStore";
import { editRgbaAlpha } from "../../../../Utilities/FunctionUtilities";
import useGetMailComments from "../../../Hooks/useGetMailComments";
import DoxleEmptyPlaceHolder from "../../../../DoxleDesignPattern/DoxleEmptyPlaceHolder/DoxleEmptyPlaceHolder";
import {
  EmptyRequestBanner,
  EmptyResponseBanner,
} from "../../../../Quotes/Content/QuotesIcon";
import Loading from "../../../../Utilities/Lottie/Loading";
import { Components, Virtuoso, VirtuosoHandle } from "react-virtuoso";
import MailCommentItem from "./MailCommentItem";
import { Error404Banner } from "../../../../DoxleDesignPattern/DoxleIcons/CommonIcons";
import ListLoadingMore from "../../../../Utilities/Lottie/ListLoadingMore";
import MailCommentInputSection from "./MailCommentInputSection";
import { useShallow } from "zustand/react/shallow";

type Props = {
  // mailComments: MailComments[];
  // submitComment: (comment:string, attachments: File[]) => void;
};

interface ListContext {
  isErrorFetchingComments: boolean;
}

export const MailCommentScreen = ({}: Props) => {
  const { currentMail } = useMailSideScreenStore(
    useShallow((state) => ({
      currentMail: state.currentMail,
      resetStore: state.resetStore,
    }))
  );
  const commentListRef = useRef<VirtuosoHandle>(null);
  const { doxleThemeColor, doxleFont, currentTheme } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
      currentTheme: state.currentTheme,
    })
  );
  const {
    mailComments,
    isFetchingMailComments,
    isErrorFetchingMailComments,
    isSuccessFetchingMailComments,
    isFetchingNextPage,
    fetchingNextMailComment,
  } = useGetMailComments({ mailId: currentMail?.mailId });

  const itemContent = useCallback(
    (index: number, item: MailComments) => <MailCommentItem comment={item} />,
    []
  );

  const components: Components<MailComments, ListContext> = useMemo(
    () => ({
      Scroller: React.forwardRef(({ ...props }, ref) => (
        <StyledVirtuosoScroller
          style={{
            ...props.style,
          }}
          ref={ref}
          {...props}
        />
      )),
      EmptyPlaceholder: (props) => (
        <DoxleEmptyPlaceHolder
          headTitleText={
            props.context?.isErrorFetchingComments
              ? "Something wrong!"
              : "No comments"
          }
          subTitleText={
            props.context?.isErrorFetchingComments
              ? "Failed to get comments..."
              : "Add your comments to communicate with your colleagues..."
          }
          containerBgColor="transparent"
          headTitleTextStyle={{ fontSize: "3rem" }}
          subTitleTextStyle={{ fontSize: "1.8rem" }}
          illustration={
            props.context?.isErrorFetchingComments ? (
              <Error404Banner
                themeColor={doxleThemeColor}
                containerStyle={{ width: "30%" }}
              />
            ) : (
              <EmptyResponseBanner themeColor={doxleThemeColor} />
            )
          }
        />
      ),
    }),
    []
  );

  return (
    <StyledMainInfoContainer>
      <StyledMailCommentsSection className="mailCommentsSection">
        {isFetchingNextPage && (
          <ListLoadingMore
            containerStyle={{
              position: "absolute",
              bottom: -30,
              left: 0,
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 20,
            }}
            animationSize={120}
          />
        )}
        {isFetchingMailComments ? (
          <Loading />
        ) : (
          <Virtuoso
            ref={commentListRef}
            style={{
              width: "100%",
              height: "100%",
            }}
            endReached={fetchingNextMailComment}
            data={mailComments}
            itemContent={itemContent}
            components={components}
            context={{ isErrorFetchingComments: isErrorFetchingMailComments }}
          />
        )}
      </StyledMailCommentsSection>
      <StyledMailResponseSection>
        <StyledMailInfoContainer $width="100%">
          <MailCommentInputSection
            commentListRef={commentListRef}
            mailComment={mailComments}
          />
        </StyledMailInfoContainer>
      </StyledMailResponseSection>
    </StyledMainInfoContainer>
  );
};
