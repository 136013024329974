import {
  Checkbox,
  CheckboxProps,
  FormControlLabel,
  FormControlLabelProps,
} from "@mui/material";
import React from "react";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import {
  AlternateCheckedIcon,
  AlternateUncheckedIcon,
  CheckedIcon,
  UncheckedIcon,
} from "./Icons";
import { styled } from "styled-components";
import { motion } from "framer-motion";

interface Props extends CheckboxProps {
  labelText?: string;
  checkColor?: string;
  labelStyle?: CheckboxProps["sx"];
  // onCheckedCallback?: Function;
  onChangeCallback?: React.ChangeEventHandler<HTMLInputElement>;
  alternateIcon?: boolean;
}
const StyledFormControl = styled(motion(FormControlLabel))``;
const DoxleCheckbox = ({
  labelText,
  labelStyle,
  onChangeCallback,
  alternateIcon,
  ...rest
}: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore((state) => ({
    doxleThemeColor: state.doxleThemeColor,
    doxleFont: state.doxleFont,
  }));

  return (
    <StyledFormControl
      layout="position"
      transition={{
        duration: 0.2,
        damping: 16,
      }}
      control={
        <Checkbox
          {...rest}
          sx={{
            "&.MuiCheckbox-root": {
              padding: 0,
              margin: "0px 4px",
            },
            ...rest.sx,
          }}
          icon={
            rest.icon ??
            (!alternateIcon ? (
              <UncheckedIcon {...doxleThemeColor} />
            ) : (
              <AlternateUncheckedIcon {...doxleThemeColor} />
            ))
          }
          checkedIcon={
            rest.checkedIcon ??
            (!alternateIcon ? (
              <CheckedIcon {...doxleThemeColor} />
            ) : (
              <AlternateCheckedIcon {...doxleThemeColor} />
            ))
          }
          // checked={onCheckedCallback}
          onChange={(e, value) => {
            e.stopPropagation();
            if (rest.onChange) rest.onChange(e, value);
            if (onChangeCallback) onChangeCallback(e);
          }}
        />
      }
      sx={{
        "& .MuiTypography-root": {
          color:
            (labelStyle as any)?.["& .MuiTypography-root"]?.color ??
            doxleThemeColor.primaryFontColor,

          fontSize:
            (labelStyle as any)?.["& .MuiTypography-root"]?.fontSize ?? "14px",
          fontFamily:
            (labelStyle as any)?.["& .MuiTypography-root"]?.fontFamily ??
            doxleFont.primaryFont,
          fontStyle:
            (labelStyle as any)?.["& .MuiTypography-root"]?.fontStyle ??
            "normal",
          fontWeight:
            (labelStyle as any)?.["& .MuiTypography-root"]?.fontWeight ?? 600,
          lineHeight:
            (labelStyle as any)?.["& .MuiTypography-root"]?.lineHeight ??
            "normal",
          letterSpacing:
            (labelStyle as any)?.["& .MuiTypography-root"]?.letterSpacing ??
            "0.7px",
          marginLeft:
            (labelStyle as any)?.["& .MuiTypography-root"]?.marginLeft ?? "4px",
        },
        "&.MuiFormControlLabel-root": {
          marginLeft:
            (labelStyle as any)?.["&.MuiFormControlLabel-root"]?.marginLeft ??
            0,
        },
        ...labelStyle,
      }}
      label={labelText ?? null}
    />
  );
};

export default DoxleCheckbox;
