import {
  Mail,
  MailComments,
  MailConversationParam,
  MailQueryFilter,
} from "../Models/mail";
import { useDoxleCurrentContextStore } from "../../DoxleGeneralStore/useDoxleCurrentContext";
import { shallow } from "zustand/shallow";
import { useDoxleAuthStore } from "../../DoxleGeneralStore/useDoxleAuthStore";
import useDoxleNotificationStore from "../../DoxleGeneralStore/useDoxleNotificationStore";
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import DoxleAPI from "../../Services/DoxleAPI";
import {
  AxiosBackendErrorReturn,
  IApiPaginatedData,
} from "../../Models/axiosReturn";
import { AxiosResponse, isAxiosError } from "axios";

const useRetrieveMailConversation = (param: MailConversationParam) => {
  const company = useDoxleCurrentContextStore(
    (state) => state.currentCompany,
    shallow
  );
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );
  let getUrl = `/mail/${param.mailId}/`;
  const qKey = ["mail-conversation", param.mailId];
  return useQuery(
    qKey,
    () =>
      DoxleAPI.get<Mail>(getUrl, {
        headers: {
          "User-Company": company?.companyId,
        },
      }),
    {
      enabled: Boolean(param.mailId),
      retry: 1,
      refetchInterval: false,
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: false,
      onError: (err: any) => {
        console.log("ERRoR:", err);
        if (err?.response?.status === 403)
          if (showNotification) {
            showNotification(`Unauthorised action`, "error");
          }
      },
    }
  );
};

interface AddReplyOrComment {
  replyText: string;
  attachments: File[];
  taggedUsers?: string[];
}

const useSendReplyMail = ({ mailId, onSuccessCb }: MailConversationParam) => {
  const company = useDoxleCurrentContextStore(
    (state) => state.currentCompany,
    shallow
  );
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );
  let postUrl = "/mail/reply/";
  const qKey = ["mail-replies", mailId];
  const mutationKey = ["new-reply", mailId];
  const queryClient = useQueryClient();

  return useMutation(
    mutationKey,
    ({ replyText, attachments }: AddReplyOrComment) => {
      let body = new FormData();
      if (mailId) body.append("mail", mailId);
      body.append("textBody", replyText);
      attachments.forEach((file) => body.append("attachments", file));
      return DoxleAPI.post(postUrl, body, {
        headers: {
          "User-Company": company?.companyId,
        },
      });
    },
    {
      retry: 1,
      onSuccess: (data: AxiosResponse<any>, variables, context) => {
        queryClient.setQueryData(qKey, (old: any) => {
          console.log("old", old);
          console.log("data", data);
          return old && data?.data
            ? {
                ...old,
                pages: (old?.pages ?? []).map((page: any, i: number) => {
                  console.log("page", page);

                  if (i + 1 !== old?.pages?.length && page?.data?.results)
                    return page;
                  return {
                    ...page,
                    data: {
                      ...page.data,
                      results: [...(page?.data?.results ?? []), data?.data],
                    },
                  };
                }),
              }
            : old;
        });
        if (onSuccessCb) onSuccessCb();
      },
      onError: (error: any) => {
        if (showNotification) {
          if (isAxiosError<AxiosBackendErrorReturn>(error)) {
            showNotification(
              `${error?.response?.status ?? "ERROR"}: ${
                error.response?.data.detail ?? "UNKNOWN ERROR"
              }`,
              "error",
              String(
                error?.response?.data?.detail ?? "Error Sending Reply Mail"
              ).substring(0, 300)
            );
          } else {
            showNotification(
              "Something Wrong!",
              "error",
              "Error Sending Reply Mail"
            );
          }
        }
      },
    }
  );
};

const useSendMailComment = ({ mailId, onSuccessCb }: MailConversationParam) => {
  const company = useDoxleCurrentContextStore(
    (state) => state.currentCompany,
    shallow
  );
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );
  let postUrl = "/mail/comment/";
  const qKey = ["mail-comments", mailId];
  const mutationKey = ["new-comment", mailId];
  const queryClient = useQueryClient();

  return useMutation(
    mutationKey,
    ({ replyText, attachments, taggedUsers }: AddReplyOrComment) => {
      let body = new FormData();
      if (mailId) body.append("mail", mailId);
      body.append("textBody", replyText);
      attachments.forEach((file) => body.append("attachments", file));
      if (taggedUsers)
        taggedUsers.forEach((id) => body.append("taggedUsers", id));
      return DoxleAPI.post(postUrl, body, {
        headers: {
          "User-Company": company?.companyId,
        },
      });
    },
    {
      retry: 1,
      onSuccess: (data: AxiosResponse<any>, variables, context) => {
        queryClient.setQueryData(qKey, (old: any) => {
          return old && data?.data
            ? {
                ...old,
                pages: (old?.pages ?? []).map((page: any, i: number) => {
                  if (i + 1 !== old?.pages?.length && page?.data?.results)
                    return page;
                  return {
                    ...page,
                    data: {
                      ...page.data,
                      results: [...(page?.data?.results ?? []), data?.data],
                    },
                  };
                }),
              }
            : old;
        });
        if (onSuccessCb) onSuccessCb();
      },
      onError: (error: any) => {
        if (showNotification) {
          if (isAxiosError<AxiosBackendErrorReturn>(error)) {
            showNotification(
              `${error?.response?.status ?? "ERROR"}: ${
                error.response?.data.detail ?? "UNKNOWN ERROR"
              }`,
              "error",
              String(
                error?.response?.data?.detail ?? "Error Sending Mail Comment"
              ).substring(0, 300)
            );
          } else {
            showNotification(
              "Something Wrong!",
              "error",
              "Error Sending Mail Comment"
            );
          }
        }
      },
    }
  );
};

interface MailConversationQueryProps {
  mailId: string | undefined;
  enabled: boolean;
  page_size?: number;
}

const useRetrieveReplyMail = ({
  mailId,
  enabled,
  page_size = 20,
}: MailConversationQueryProps) => {
  const company = useDoxleCurrentContextStore(
    (state) => state.currentCompany,
    shallow
  );
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );
  let getUrl = `/mail/reply/?mail=${mailId}&page=1&page_size=${page_size}`;
  const qKey = ["mail-replies", mailId];
  return useInfiniteQuery(
    qKey,
    ({ pageParam = getUrl }) =>
      DoxleAPI.get(pageParam, {
        headers: {
          "User-Company": company?.companyId,
        },
      }),
    {
      enabled: Boolean(company && mailId && enabled),
      retry: 1,
      refetchInterval: false,
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: false,
      getNextPageParam: (prevData) => prevData.data?.next,
      onError: (error: any) => {
        if (showNotification) {
          if (isAxiosError<AxiosBackendErrorReturn>(error)) {
            showNotification(
              `${error?.response?.status ?? "ERROR"}: ${
                error.response?.data.detail ?? "UNKNOWN ERROR"
              }`,
              "error",
              String(
                error?.response?.data?.detail ?? "Error Fetching Mail Replies"
              ).substring(0, 300)
            );
          } else {
            showNotification(
              "Something Wrong!",
              "error",
              "Error Fetching Mail Replies"
            );
          }
        }
      },
    }
  );
};

const useRetrieveMailComments = ({
  mailId,
  enabled,
  page_size = 20,
}: MailConversationQueryProps) => {
  const company = useDoxleCurrentContextStore(
    (state) => state.currentCompany,
    shallow
  );
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );
  let getUrl = `/mail/comment/?mail=${mailId}&page=1&page_size=${page_size}`;
  const qKey = ["mail-comments", mailId];
  return useInfiniteQuery(
    qKey,
    ({ pageParam = getUrl }) =>
      DoxleAPI.get<IApiPaginatedData<MailComments>>(pageParam, {
        headers: {
          "User-Company": company?.companyId,
        },
      }),
    {
      enabled: Boolean(company && mailId && enabled),
      retry: 1,
      refetchInterval: false,
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: false,
      // staleTime:0,
      // cacheTime:0,
      getNextPageParam: (prevData) => prevData.data?.next,
      getPreviousPageParam: (prev) => prev.data.previous,
      onError: (error: any) => {
        if (showNotification) {
          if (isAxiosError<AxiosBackendErrorReturn>(error)) {
            showNotification(
              `${error?.response?.status ?? "ERROR"}: ${
                error.response?.data.detail ?? "UNKNOWN ERROR"
              }`,
              "error",
              String(
                error?.response?.data?.detail ?? "Error Fetching Mail Comments"
              ).substring(0, 300)
            );
          } else {
            showNotification(
              "Something Wrong!",
              "error",
              "Error Fetching Mail Comments"
            );
          }
        }
      },
    }
  );
};
interface UpdateReply {
  replyId: string;
  isOfficialResponse: boolean;
}
const useUpdateReplyMail = ({ mailId, onSuccessCb }: MailConversationParam) => {
  const company = useDoxleCurrentContextStore(
    (state) => state.currentCompany,
    shallow
  );
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );
  const qKey = ["mail-replies", mailId];
  const mutationKey = ["update-reply", mailId];
  const queryClient = useQueryClient();

  return useMutation(
    mutationKey,
    ({ replyId, isOfficialResponse }: UpdateReply) => {
      return DoxleAPI.patch(
        `/mail/reply/${replyId}/`,
        { isOfficialResponse },
        {
          headers: {
            "User-Company": company?.companyId,
          },
        }
      );
    },
    {
      retry: 1,
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries(qKey);
        if (onSuccessCb) onSuccessCb();
      },
      onError: (err: any) => {
        if (err?.response?.status)
          if (showNotification) {
            showNotification(
              `${err?.response?.status ?? "ERROR"}: ${
                err?.response?.statusText ?? "Unknown Error"
              }`,
              "error",
              err?.response?.data ?? "Error Updating Reply Mail"
            );
          }
      },
    }
  );
};

const MailConversationAPI = {
  useRetrieveMailConversation,
  useSendReplyMail,
  useSendMailComment,
  useRetrieveReplyMail,
  useRetrieveMailComments,
  useUpdateReplyMail,
};

export default MailConversationAPI;
