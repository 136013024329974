import styled from "styled-components";
import { DoxleFont, DoxleThemeColor } from "../../../../DoxleGeneralStore/useDoxleThemeStore";
import { editRgbaAlpha } from "../../../../Utilities/FunctionUtilities";

export const StyledWarning = styled.div`
  color: #ea1515;
  font-size: 1.2rem;
  font-family: IBM Plex Mono;
  height: 0px;
  position: relative;
  left: 2rem;
`;

export const FormArea = styled.div``;

export const ButtonContainer = styled.div`
  width: 100%;
  text-align: center;
`

export const StyledInput = styled.input<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  width: 32.5rem;
  height: 4rem;
  padding: 12px 20px;
  box-sizing: border-box;
  border: none;
  border-bottom: 2px solid rgba(112, 112, 239, 0.1);
  background: transparent;
  border-radius: 2px;
  color: ${(p) => p.$themeColor.primaryFontColor};
  background-color: ${(p) => p.$themeColor.primaryContainerColor};
  font-family: ${(p) => p.$doxleFont.subTitleFont};

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.$themeColor.primaryFontColor,
        alpha: "0.25",
      })};
    opacity: 1; 
    font-size: 1.4rem;
    font-family: ${(p) => p.$doxleFont.subTitleFont};
  }

  &::-ms-input-placeholder {
    color: ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.$themeColor.primaryFontColor,
        alpha: "0.25",
      })};
    font-size: 1.4rem;
  }
`;

export const StyledTextArea = styled.textarea<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  width: 32.5rem;
  height: 9rem;
  box-sizing: border-box;
  border: none;
  border-bottom: 2px solid rgba(112, 112, 239, 0.1);
  padding: 12px 20px;
  resize: none;
  color: ${(p) => p.$themeColor.primaryFontColor};
  background-color: ${(p) => p.$themeColor.primaryContainerColor};

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.$themeColor.primaryFontColor,
        alpha: "0.25",
      })};
    font-size: 1.4rem;
    font-family: ${(p) => p.$doxleFont.subTitleFont};
  }
`;

export const StyledTitle = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  font-size: 2.7rem;
  font-family: ${(p) => p.$doxleFont.subTitleFont};
  margin-bottom: 10px;
  color: ${(p) => p.$themeColor.primaryFontColor};
  width: 100%;
  text-align: center;
`;

export const ContactPageContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
   @media (max-width: 600px){
    flex-direction: column;
  }
`;

export const ContactPageLeft = styled.div`
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;

  @media (max-width: 600px){
    height: auto;
  }

  img {
    width: 100%;
    aspect-ratio: ${239 / 162};
    min-width: 100px;
    max-width: 239px;
  }
`;

export const ContactPageRight = styled.div<{
  $themeColor: DoxleThemeColor;
}>`
  padding-right: 2rem;
  @media (max-width: 600px){
    height: auto;
  }

`;

export const SubmitBtn = styled.button<{
  $themeColor: DoxleThemeColor;
}>`
  background-color: ${(p) => p.$themeColor.primaryFontColor};
  color: ${(p) => p.$themeColor.primaryBackgroundColor};
  width: 8rem;
  height: 3.9rem;
  border-radius: 3px;
  border: none;
  cursor: pointer;
  margin-top: 1rem;

  &:hover{
    transform: scale(1.03) translateY(-0.3rem);
    opacity: 0.9;
    transition: 0.2s all;
  }
  &:active {
    transform: scale(0.98) translateY(0.2rem);
    opacity: 1;
    transition: 0.2s all;
  }
`;

export const StyledErrorText = styled.div<{
  $themeColor: DoxleThemeColor;
}>`
  width: 100%;
  text-align: center;
  color: ${(p) => p.$themeColor.errorColor};
  padding-top: 1rem;
`
