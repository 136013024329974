import React, { useEffect, useState } from "react";

import { shallow } from "zustand/shallow";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { useDoxleAuthStore } from "../../../DoxleGeneralStore/useDoxleAuthStore";
import { useDoxleCurrentContextStore } from "../../../DoxleGeneralStore/useDoxleCurrentContext";
import useDoxleNotificationStore from "../../../DoxleGeneralStore/useDoxleNotificationStore";
import { useScopeOfWorkStore } from "../../Store/useScopeOfWorkStore";
import ScopeOfWorkAPI from "../../QueryAPI/scopeOfWorkQueryAPI";
import DoxleDialogHelper from "../../../DoxleDesignPattern/DoxleDialogHelper/DoxleDialogHelper";

type Props = {};

const DeleteScopeImageGalleryItem = (props: Props) => {
  const [showDialogDelete, setShowDialogDelete] = useState<boolean>(false);
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore((state) => ({
    doxleThemeColor: state.doxleThemeColor,
    doxleFont: state.doxleFont,
  }));

  const { company } = useDoxleCurrentContextStore((state) => ({
    company: state.currentCompany,
  }));
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification
  );

  const {
    deletedScopeImage,
    setDeletedScopeImage,
    removeViewedScopeImageItem,
  } = useScopeOfWorkStore(
    (state) => ({
      deletedScopeImage: state.deletedScopeImage,
      setDeletedScopeImage: state.setDeletedScopeImage,
      removeViewedScopeImageItem: state.removeViewedScopeImageItem,
    }),
    shallow
  );
  useEffect(() => {
    if (deletedScopeImage) setShowDialogDelete(true);
  }, [deletedScopeImage]);

  const handleCloseDialogDelete = () => {
    setDeletedScopeImage(undefined);
    setShowDialogDelete(false);
  };
  //# delete scope img query
  const onSuccessDeleteCb = () => {
    if (deletedScopeImage) removeViewedScopeImageItem(deletedScopeImage);
    setDeletedScopeImage(undefined);
  };
  const deleteScopeImageQuery = ScopeOfWorkAPI.useMultiDeleteScopeImageQuery({
    showNotification,
  
    company,
    scopeItemId: deletedScopeImage ? deletedScopeImage.scopeItem : "",
    onSuccessCb: onSuccessDeleteCb,
  });

  const handleDeleteScopeImage = () => {
    setShowDialogDelete(false);
    if (deletedScopeImage)
      deleteScopeImageQuery.mutate([deletedScopeImage.imageId]);
  };

  return (
    <DoxleDialogHelper
      open={showDialogDelete}
      onClose={handleCloseDialogDelete}
      title="Confirm Delete Image!"
      description="All data belong to this image will be deleted permanently, Do you want to proceed?"
      dialogButtons={[
        {
          buttonText: "Cancel",
          buttonFunction: handleCloseDialogDelete,
          buttonColor: doxleThemeColor.primaryContainerColor,
          btnStyleSx: {
            border: `1px solid ${doxleThemeColor.primaryDividerColor}`,
          },
          buttonTextStyle: {
            color: doxleThemeColor.primaryFontColor,
          },
        },
        {
          buttonText: "Confirm",
          buttonFunction: handleDeleteScopeImage,
          btnStyleSx: {
            border: `1px solid ${doxleThemeColor.primaryDividerColor}`,
          },
        },
      ]}
    />
  );
};

export default DeleteScopeImageGalleryItem;
