import React, { useEffect, useState } from "react";
import { useDoxleCurrentContextStore } from "../../../../../DoxleGeneralStore/useDoxleCurrentContext";
import { useEditDocketSideScreenStore } from "../../../EditDocketSideScreenStore/useEditDocketSideScreenStore";
import { shallow } from "zustand/shallow";
import DocketQuery from "../../../../../Services/QueryHooks/docketQueryAPI";
import { Docket } from "../../../../../Models/dockets";
import useSetDocketDetailQueryData from "../../../../QueryDataHooks/SetQueryDataHooks/useSetDocketDetailQueryData";

type Props = {};

interface DocketNameSection {
  handleUpdateDocketName: () => void;
  newDocketName: string;
  setNewDocketName: React.Dispatch<React.SetStateAction<string>>;
}
const useDocketNameSection = (props: Props): DocketNameSection => {
  const {
    edittedDocket,
    updateEdittedDocket,
    filterDocketListQuery,
    handlePostUpdateDocket,
  } = useEditDocketSideScreenStore(
    (state) => ({
      edittedDocket: state.edittedDocket,
      updateEdittedDocket: state.updateEdittedDocket,
      filterDocketListQuery: state.filterDocketListQuery,
      handlePostUpdateDocket: state.handlePostUpdateDocket,
    }),
    shallow
  );
  const [newDocketName, setNewDocketName] = useState(
    edittedDocket?.docketName ?? ""
  );
  const { company } = useDoxleCurrentContextStore((state) => ({
    company: state.currentCompany,
  }));
  const { handleUpdateDocket } = useSetDocketDetailQueryData({
    docketPk: edittedDocket?.docketPk ?? "",
  });
  const onSuccessUpdate = (newDocket?: Docket) => {
    if (newDocket) {
      if (handlePostUpdateDocket) handlePostUpdateDocket(newDocket);

      handleUpdateDocket(newDocket);
    }
  };
  const updateDocketQuery = DocketQuery.useUpdateDocketQuery({
    filter: filterDocketListQuery,

    company,
    onSuccessCb: onSuccessUpdate,
  });

  const handleUpdateDocketName = () => {
    if (newDocketName && edittedDocket) {
      if (newDocketName !== edittedDocket?.docketName) {
        updateEdittedDocket({
          docketName: newDocketName,
        });
        updateDocketQuery.mutate({
          docketPk: edittedDocket.docketPk,
          docketName: newDocketName,
        });
      }
    } else setNewDocketName(edittedDocket?.docketName ?? "");
  };

  // useEffect(() => {
  //   if (edittedDocket?.docketName) setNewDocketName(edittedDocket.docketName);
  // }, [edittedDocket?.docketName]);

  return { handleUpdateDocketName, newDocketName, setNewDocketName };
};

export default useDocketNameSection;
