import React, { useCallback } from "react";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { useReviewQuoteRequestContext } from "./ReviewQuoteRequest";
import { DropEvent, FileRejection, useDropzone } from "react-dropzone";
import { motion } from "framer-motion";
import Button from "@mui/material/Button";
import { IoDocumentTextOutline } from "react-icons/io5";
type Props = {};

const AddQuoteAttachment = (props: Props) => {
  const { THEME_COLOR, currentTheme } = useDoxleThemeStore((state) => ({
    THEME_COLOR: state.doxleThemeColor,
    currentTheme: state.currentTheme,
  }));
  const { setAddedAttachments } = useReviewQuoteRequestContext();
  const onDrop = useCallback(
    <T extends File>(
      acceptedFiles: T[],
      fileRejections: FileRejection[],
      event: DropEvent
    ) => {
      setAddedAttachments((prev) => [...prev, ...acceptedFiles]);
    },
    []
  );

  const { getRootProps } = useDropzone({
    onDrop,
    accept: {
      "image/*": [".png", ".jpg", ".jpeg"],
      "application/pdf": [".pdf"],
      "application/msword": [".doc"],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        [".docx"],
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
        ".xlsx",
      ],
    },
    multiple: true,
    // noClick: addedScopeImages.length > 0 ? true : false,
  });
  return (
    <motion.div layout>
      <Button
        {...getRootProps({
          variant: "outlined",
          startIcon: (
            <IoDocumentTextOutline
              color={THEME_COLOR.primaryFontColor}
              size="1.6rem"
            />
          ),
          sx: {
            borderRadius: "0px 0.9rem 0.9rem 1.2rem",
            border: `1px solid ${
              currentTheme === "light"
                ? THEME_COLOR.primaryTableRowColor
                : THEME_COLOR.primaryDividerColor
            }`,
            color: THEME_COLOR.primaryFontColor,
            fontSize: "1.4rem",
            textTransform: "none",
            marginTop: "2rem",
          },
        })}
      >
        Choose File
      </Button>
    </motion.div>
  );
};

export default AddQuoteAttachment;
