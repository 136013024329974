import { BaseAPIProps } from "../../Models/baseAPIProps";
import {
  useInfiniteQuery,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import DoxleAPI from "../../Services/DoxleAPI";
import { Estimate, EstimatesFilters } from "../Models/estimatesFilters";

import {
  AxiosBackendErrorReturn,
  IApiPaginatedData,
} from "../../Models/axiosReturn";
import { Company } from "../../Models/company";
import useSetEstimateQueryData from "../Content/EditBudgetEstimate/hooks/useSetEstimateQueryData";
import DocketQuery from "../../Services/QueryHooks/docketQueryAPI";
import { Docket } from "../../Models/dockets";
import { isAxiosError } from "axios";
import { getProjectTotalQKey } from "../../Services/QueryHooks/projectQueryAPI";
import { getStageListQueryKey } from "../../Services/QueryHooks/stageQueryAPI";

interface RetrieveEstimatesListInterface extends BaseAPIProps {
  filter: EstimatesFilters;
}
export const useRetrieveEstimatesList = ({
  company,
  showNotification,
  filter,
}: RetrieveEstimatesListInterface) => {
  const qKey = formEstimateListQkey(filter, company);
  let docketURL = `/estimate/?page=1`;
  return useInfiniteQuery(
    qKey,
    ({ pageParam = docketURL }) => {
      return DoxleAPI.get<IApiPaginatedData<Estimate>>(pageParam, {
        headers: {
          "User-Company": company!.companyId,
        },
        params: filter,
      });
    },
    {
      enabled: Boolean(company),
      retry: 1,
      staleTime: 0,
      cacheTime: 5 * 60 * 1000,
      refetchInterval: 5 * 60 * 1000,
      // refetchInterval: 2 * 60 * 1000,
      // staleTime:0,
      // cacheTime: 0.5 * 60 *1000,
      // refetchIntervalInBackground: true,
      refetchOnWindowFocus: false,
      getNextPageParam: (prevData) => prevData.data?.next,
      onError: (error: any) => {
        if (showNotification) {
          if (isAxiosError<AxiosBackendErrorReturn>(error)) {
            showNotification(
              `${error?.response?.status ?? "ERROR"}: ${
                error.response?.data.detail ?? "UNKNOWN ERROR"
              }`,
              "error",
              String(
                error?.response?.data?.detail ?? "Error Fetching Contacts List"
              ).substring(0, 300)
            );
          } else {
            showNotification(
              "Something Wrong!",
              "error",
              "Error Fetching Contacts List"
            );
          }
        }
      },
    }
  );
};

interface EstimateMutationQueryProps extends RetrieveEstimatesListInterface {
  docketId: string | undefined;
  onAddSuccessCb?: (newEstimate?: Estimate) => void;
  onUpdateSuccessCb?: (newEstimate?: Estimate) => void;
  onDeleteSuccessCb?: (deletedIds?: string[]) => void;
}

export interface IEstimateUpdateQueryProps {
  estimateId: string;
  updateBody: Partial<Estimate>;
}
export interface DestroyEstimateProps {
  estimateIds: string[];
  docket: Docket | undefined;
}

export interface UpdateCoreDocketEstimatesProps {
  action: "set" | "update"; // set: add new lines, update existing, delete missing, update will only add / update will not delete
  estimates: Array<
    Partial<
      Omit<Estimate, "docket" | "coreDocket" | "project" | "company" | "index">
    >
  >;
  coreDocket: string;
  project?: string;
}
export interface UpdateDocketEstimatesProps {
  action: "set" | "update"; // set: add new lines, update existing, delete missing, update will only add / update will not delete
  estimates: Array<
    Partial<
      Omit<Estimate, "docket" | "coreDocket" | "project" | "company" | "index">
    >
  >;
  docket: string;
  project?: string;
}
export interface PartialDocketWithEstimates {
  docket_pk: string;
  docket_id: string;
  docketName: string;
  company: string;
  project: string;
  costBudget: string;
  costActual: string;
  costXero: string;
  costRunning: string;
  incomeBudget: string;
  incomeActual: string;
  incomeXero: string;
  income_running: string;
  estimates: Estimate[];
}
export interface CoreDocketWithEstimates {
  coreDocketId: string;
  coreDocketNumber: number;
  coreDocketName: string;
  accountCode: string;
  accountTrackingId: string;
  isExpense: boolean;
  isIncome: boolean;
  isVariation: boolean;
  isSticky: boolean;
  isArchived: boolean;
  coreStage: string;
  company: string;
  estimates: Estimate[];
}

const useMutateEstimate = ({
  company,
  showNotification,
  filter,
  onAddSuccessCb,
  onUpdateSuccessCb,
  onDeleteSuccessCb,
}: EstimateMutationQueryProps) => {
  const { handleAddEstimate, handleUpdateEstimate } = useSetEstimateQueryData({
    filter,
    appendPos: "end",
  });
  const queryClient = useQueryClient();
  const updateDocket = DocketQuery.useUpdateDocketValues();
  const update = useMutation({
    mutationKey: getMutateEstimateKey("update"),
    mutationFn: async ({
      estimateId,
      updateBody,
    }: IEstimateUpdateQueryProps) => {
      let body: any = {};
      if (updateBody.index) body.index = updateBody.index;
      if (updateBody.description) body.description = updateBody.description;
      if (updateBody.quantity !== undefined)
        body.quantity = updateBody.quantity;
      if (updateBody.quantityCalculation)
        body.quantityCalculation = updateBody.quantityCalculation;
      if (updateBody.unit !== undefined) body.unit = updateBody.unit;
      if (updateBody.unitCost !== undefined)
        body.unitCost = updateBody.unitCost;
      if (updateBody.isAllowance) body.isAllowance = updateBody.isAllowance;
      if (updateBody.takeOff !== undefined) body.takeOff = updateBody.takeOff;
      if (updateBody.pricebookItem !== undefined)
        body.pricebookItem = updateBody.pricebookItem;
      if (updateBody.docket) body.docket = updateBody.docket;
      if (updateBody.coreDocket) body.coreDocket = updateBody.coreDocket;
      if (updateBody.project) body.project = updateBody.project;

      return DoxleAPI.patch<Estimate>("/estimate/" + estimateId + "/", body, {
        headers: {
          "User-Company": company?.companyId ?? "",
        },
      });
    },
    onSuccess: (result, variables, context) => {
      // if (showNotification)
      //   showNotification(
      //     "Item Updated",
      //     "success",
      //     "SUCCESSFULLY Updated Estimate"
      //   );
      handleUpdateEstimate(result.data);

      if (onUpdateSuccessCb) onUpdateSuccessCb(result.data);
    },
    onError: (error, variables, context) => {
      if (showNotification) {
        if (isAxiosError<AxiosBackendErrorReturn>(error)) {
          showNotification(
            `${error?.response?.status ?? "ERROR"}: ${
              error.response?.data.detail ?? "UNKNOWN ERROR"
            }`,
            "error",
            String(
              error?.response?.data?.detail ?? "Fail To update takeoff"
            ).substring(0, 300)
          );
        } else {
          showNotification(
            "Something Wrong!",
            "error",
            "Fail To update takeoff"
          );
        }
      }
    },
    onSettled: (result, variables, context) => {
      if (result?.data.project) {
        const totalsKey = getProjectTotalQKey(result?.data.project);
        const stagesKey = getStageListQueryKey({
          projectId: result?.data.project,
        });
        queryClient.refetchQueries({
          type: "all",
          predicate: (query) =>
            totalsKey.every((item) => query.queryKey.includes(item)),
        });
        queryClient.refetchQueries({
          type: "all",
          predicate: (query) =>
            stagesKey.every((item) => query.queryKey.includes(item)),
        });
      }
      if (result?.data.docket) updateDocket.mutate(result?.data.docket);
    },
  });

  const add = useMutation({
    mutationKey: getMutateEstimateKey("add"),
    mutationFn: async (estimate: Partial<Estimate>) => {
      return DoxleAPI.post<Estimate>("/estimate/", estimate, {
        headers: {
          "User-Company": company?.companyId ?? "",
        },
      });
    },
    onSuccess: (result, variables, context) => {
      handleAddEstimate(result.data);
      if (onAddSuccessCb) onAddSuccessCb(result.data);
    },
    // if (handleScrollToBottomBudgetTable) handleScrollToBottomBudgetTable()
    onError: (error, variables, context) => {
      if (showNotification) {
        if (isAxiosError<AxiosBackendErrorReturn>(error)) {
          showNotification(
            `${error?.response?.status ?? "ERROR"}: ${
              error.response?.data.detail ?? "UNKNOWN ERROR"
            }`,
            "error",
            String(
              error?.response?.data?.detail ?? "Fail To add estimate"
            ).substring(0, 300)
          );
        } else {
          showNotification("Something Wrong!", "error", "Fail To add estimate");
        }
      }
    },
    onSettled: (result, variables, context) => {
      if (result?.data.project) {
        const totalsKey = getProjectTotalQKey(result?.data.project);
        const stagesKey = getStageListQueryKey({
          projectId: result?.data.project,
        });
        queryClient.refetchQueries({
          type: "all",
          predicate: (query) =>
            totalsKey.every((item) => query.queryKey.includes(item)),
        });
        queryClient.refetchQueries({
          type: "all",
          predicate: (query) =>
            stagesKey.every((item) => query.queryKey.includes(item)),
        });
      }
      if (result?.data.docket) updateDocket.mutate(result?.data.docket);
    },
  });

  const destroy = useMutation({
    mutationKey: getMutateEstimateKey("delete"),
    mutationFn: async ({ estimateIds, docket }: DestroyEstimateProps) => {
      return DoxleAPI.post(
        "/estimate/multi_del/",
        { estimateIds: estimateIds },
        {
          headers: {
            "User-Company": company?.companyId ?? "",
          },
        }
      );
    },
    onSuccess: (result, variables, context) => {
      if (onDeleteSuccessCb) onDeleteSuccessCb(variables.estimateIds);
    },
    onError: (error, variables, context) => {
      if (showNotification) {
        if (isAxiosError<AxiosBackendErrorReturn>(error)) {
          showNotification(
            `${error?.response?.status ?? "ERROR"}: ${
              error.response?.data.detail ?? "UNKNOWN ERROR"
            }`,
            "error",
            String(
              error?.response?.data?.detail ?? "Fail To delete estimate"
            ).substring(0, 300)
          );
        } else {
          showNotification(
            "Something Wrong!",
            "error",
            "Fail To delete estimate"
          );
        }
      }
    },
    onSettled: (result, error, variables, context) => {
      if (variables.docket?.project) {
        const totalsKey = getProjectTotalQKey(variables.docket?.project);
        const stagesKey = getStageListQueryKey({
          projectId: variables.docket?.project,
        });
        queryClient.refetchQueries({
          type: "all",
          predicate: (query) =>
            totalsKey.every((item) => query.queryKey.includes(item)),
        });
        queryClient.refetchQueries({
          type: "all",
          predicate: (query) =>
            stagesKey.every((item) => query.queryKey.includes(item)),
        });
      }
      if (variables?.docket?.docketPk)
        updateDocket.mutate(variables?.docket?.docketPk);
    },
  });

  const updateDocketEstimates = useMutation({
    mutationKey: getMutateEstimateKey("update-many"),
    mutationFn: async ({
      project,
      ...updateManyBody
    }: UpdateDocketEstimatesProps) => {
      return DoxleAPI.post<PartialDocketWithEstimates, AxiosBackendErrorReturn>(
        "/estimate/update_docket/",
        updateManyBody,
        {
          headers: {
            "User-Company": company?.companyId ?? "",
          },
        }
      );
    },
    onSuccess: (result, variables, context) => {
      updateDocket.mutate(variables.docket);
    },
    onError: (error, variables, context) => {
      if (showNotification) {
        if (isAxiosError<AxiosBackendErrorReturn>(error)) {
          showNotification(
            `${error?.response?.status ?? "ERROR"}: ${
              error.response?.data.detail ?? "UNKNOWN ERROR"
            }`,
            "error",
            String(
              error?.response?.data?.detail ?? "Fail To delete estimate"
            ).substring(0, 300)
          );
        } else {
          showNotification(
            "Something Wrong!",
            "error",
            "Fail To delete estimate"
          );
        }
      }
    },
    onSettled: (result, error, variables, context) => {
      if (variables?.project) {
        const totalsKey = getProjectTotalQKey(variables?.project);
        const stagesKey = getStageListQueryKey({
          projectId: variables?.project,
        });
        queryClient.refetchQueries({
          type: "all",
          predicate: (query) =>
            totalsKey.every((item) => query.queryKey.includes(item)),
        });
        queryClient.refetchQueries({
          type: "all",
          predicate: (query) =>
            stagesKey.every((item) => query.queryKey.includes(item)),
        });
      }
    },
  });

  const updateCoreDocketEstimates = useMutation({
    mutationKey: getMutateEstimateKey("update-many"),
    mutationFn: async ({
      project,
      ...updateManyBody
    }: UpdateCoreDocketEstimatesProps) => {
      return DoxleAPI.post<CoreDocketWithEstimates, AxiosBackendErrorReturn>(
        "/estimate/update_core_docket/",
        updateManyBody,
        {
          headers: {
            "User-Company": company?.companyId ?? "",
          },
        }
      );
    },
    onSuccess: (result, variables, context) => {},
    onError: (error, variables, context) => {
      if (showNotification) {
        if (isAxiosError<AxiosBackendErrorReturn>(error)) {
          showNotification(
            `${error?.response?.status ?? "ERROR"}: ${
              error.response?.data.detail ?? "UNKNOWN ERROR"
            }`,
            "error",
            String(
              error?.response?.data?.detail ?? "Fail To delete estimate"
            ).substring(0, 300)
          );
        } else {
          showNotification(
            "Something Wrong!",
            "error",
            "Fail To delete estimate"
          );
        }
      }
    },
  });

  return {
    update,
    add,
    destroy,
    updateDocketEstimates,
    updateCoreDocketEstimates,
  };
};

//# helper function
export const formEstimateListQkey = (
  filter: EstimatesFilters,
  company: Company | undefined
) => {
  let qKey = ["estimates-list"];
  if (company) qKey.push(company.companyId);
  for (const [key, value] of Object.entries(filter)) {
    qKey.push(value);
  }

  return qKey;
};

export const getMutateEstimateKey = (
  action: "add" | "update" | "delete" | "update-many"
) => {
  if (action === "update") return ["update-estimate"];
  else if (action === "update-many") return ["update-many-estimate"];
  else if (action === "delete") return ["delete-estimate"];
  else return ["add-estimate"];
};
const EstimatesQueryAPI = {
  useRetrieveEstimatesList,
  useMutateEstimate,
};
export default EstimatesQueryAPI;
