import { create } from "zustand";
import { TRgbaFormat } from "../../../Utilities/FunctionUtilities";

interface ColorPickerProps {
  anchorEl: HTMLDivElement;
  color: TRgbaFormat;
  handleSave: (color: TRgbaFormat) => void;
}

interface StatusColorPickerStore {
  anchorEl: HTMLDivElement | null;
  color: TRgbaFormat | null;
  openPicker: (props: ColorPickerProps) => void;
  handleClose: () => void;
  handleSave: ((color: TRgbaFormat) => void) | null;
}
const useStatusColorPickerStore = create<StatusColorPickerStore>((set) => ({
  anchorEl: null,
  color: null,
  handleSave: null,
  handleClose: () => set({ anchorEl: null, color: null, handleSave: null }),
  openPicker: (props: ColorPickerProps) => set({ ...props }),
}));

export default useStatusColorPickerStore;
