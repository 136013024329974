import { motion } from "framer-motion";
import styled from "styled-components";
import {
  DoxleFont,
  DoxleThemeColor,
} from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { editRgbaAlpha } from "../../../Utilities/FunctionUtilities";
import { InputAdornment, TextField, Typography } from "@mui/material";

export const StyledQuoteResponsePageContainer = styled(motion.div)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  width: calc(100vw - 2rem - 2.8rem);
  height: calc(100vh - 2rem - 1.4rem);
  padding: 1.4rem 1.4rem 0px 1.4rem;
  display: flex;
  flex-direction: column;
  background-color: ${(p) => p.$themeColor.primaryBackgroundColor};
  position: relative;
  overflow: hidden;
  border: 1rem solid ${(p) => p.$themeColor.primaryContainerColor};
  .quote-content {
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
    align-self: center;
    max-width: 1320px;
    min-width: 600px;
    .add-quote-btn {
      width: calc(100% - 6rem);
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 2rem 3rem;
    }
  }
  .banner-company-name {
    padding: 0.4rem 0.8rem;
    border-radius: 0.4rem;
    color: ${(p) => p.$themeColor.primaryContainerColor};
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-size: 1.8rem;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    letter-spacing: 0.09rem;
    background-color: ${(p) => p.$themeColor.primaryFontColor};
    margin-bottom: 1rem;
    align-self: flex-start;
  }

  .quote-title-text {
    color: ${(p) => p.$themeColor.primaryFontColor};
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-size: 2.7rem;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    letter-spacing: 0.135rem;
    margin-bottom: 1rem;
    text-transform: capitalize;
  }

  .quote-notice-text {
    color: ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.$themeColor.primaryFontColor,
        alpha: "0.7",
      })};
    font-family: ${(p) => p.$doxleFont.secondaryTitleFont};
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.07rem;
    margin-bottom: 3rem;
  }
`;
export const StyledQuoteResponseFieldWrapper = styled(motion.div)`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;

  .field-row-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .attachment-displayer-section {
    min-width: 30%;
    max-width: 70%;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 1.4rem;
  }
`;
export const StyledQuoteResponseLabelFieldText = styled(Typography)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  &.MuiTypography-root {
    font-family: ${(p) => p.$doxleFont.primaryTitleFont};
    color: ${(p) => p.$themeColor.primaryFontColor};
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: normal;
    letter-spacing: 0.05rem;
    white-space: nowrap;
    text-transform: uppercase;
    margin-left: 0.8rem;
  }
`;

export const StyledQuoteResponseTextField = styled(TextField)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
  $textAlign?: "left" | "center" | "right";
}>`
  && {
    padding: 0.2rem 0.8rem !important;

    min-width: 50px;
    width: 20rem;
    background-color: ${(p) => p.$themeColor.primaryContainerColor};
    border-radius: 0.4rem;
    height: 2.6rem;
    display: flex;
    flex-direction: row;
    align-items: center;

    border: 1px solid ${(p) => p.$themeColor.rowBorderColor};
  }
  input {
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-weight: 500;
    font-size: 1.3rem;
    line-height: normal;
    text-align: ${(p) => p.$textAlign ?? "left"};
    color: ${(p) => p.$themeColor.primaryFontColor};
    padding: 0px !important;
  }
  & .MuiInput-root:after {
    border-bottom: none !important;
  }
  & .MuiInput-root:before {
    border-bottom: none !important;
  }
  & .MuiInput-root {
    border-bottom: none !important;
  }
  & .MuiInput-root:hover:not(.Mui-disabled, .Mui-error):before {
    border-bottom: none !important;
  }
  & .MuiInputBase-root {
    width: 100%;
  }
`;

export const StyledQuoteResponseErrorText = styled(motion.span)<{
  $doxleFont: DoxleFont;
}>`
  font-family: ${(p) => p.$doxleFont.primaryFont};
  font-weight: 500;
  font-size: 1rem;
  line-height: normal;
  color: red;
  margin-top: 0.4rem;
`;

export const StyledRequestAttachmentContainer = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  min-width: 30%;
  max-width: 70%;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 1.4rem;
  padding-bottom: 1.4rem;
  border-bottom: 1px solid ${(p) => p.$themeColor.primaryDividerColor};
`;
export const StyledPaymentSuffixPopupContainer = styled.div<{
  $doxleFont: DoxleFont;
  $themeColor: DoxleThemeColor;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${(p) => p.$themeColor.primaryContainerColor};
  border-radius: 0px 0.4rem;
  padding: 0.8rem;
  .suffix-choice {
    padding: 0.4rem 0.8rem;
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-weight: 500;
    font-size: 1rem;
    line-height: normal;
    color: ${(p) => p.$themeColor.primaryFontColor};
    border-radius: 4px;
    cursor: pointer;
    text-transform: uppercase;
    &:hover {
      background-color: ${(p) => p.$themeColor.rowHoverColor};
      transition: 0.4s;
      font-weight: 600;
    }
  }
`;

export const StyledQuoteResponseInputAdornment = styled(InputAdornment)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  & .MuiTypography-root {
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-weight: 500;
    font-size: 1rem;
    line-height: normal;
    color: ${(p) => p.$themeColor.primaryFontColor};
    cursor: pointer;
  }
`;

export const StyledResponseNoticeText = styled.span<{
  $doxleFont: DoxleFont;
  $themeColor: DoxleThemeColor;
}>`
  font-family: ${(p) => p.$doxleFont.primaryFont};
  font-weight: 500;
  font-size: 1.2rem;
  line-height: normal;
  color: ${(p) => p.$themeColor.primaryFontColor};
  margin-left: 0.8rem;
`;
export const StyledQuoteResponseContractorContainer = styled(motion.div)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  border-radius: 0.2rem;
  background-color: ${(p) =>
    editRgbaAlpha({
      rgbaColor: p.$themeColor.primaryContainerColor,
      alpha: "0.1",
    })};
  color: ${(p) => p.$themeColor.primaryFontColor};
  cursor: default;
  min-width: 7rem;

  font-size: 1.3rem;
  font-weight: 500;
  align-self: flex-start;
  font-family: ${(p) => p.$doxleFont.primaryFont};
  align-items: center;
  flex-direction: row;
  display: flex;
  border: 1px solid ${(p) => p.$themeColor.rowBorderColor};
  padding: 0.2rem 1.2rem;
  height: 2.6rem;
`;

export const StyledQuoteResponseDateSection = styled(motion.div)`
  width: 100%;
  display: flex;
`;

export const StyledQuoteResponseTopMenu = styled.div`
  width: calc(100% - 4rem);
  margin: 3rem 2rem;
  justify-content: center;
  align-items: center;
  display: flex;
`;

export const StyledSuccessSubmitQuotePage = styled(motion.div)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  width: calc(100% - 6rem);
  flex: 1;
  padding: 3rem;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  display: flex;
  background-color: ${(p) => p.$themeColor.primaryContainerColor};
  .success-img {
    width: 40%;
    max-width: 400px;
    min-width: 200px;
    aspect-ratio: ${392.57 / 562.66};
  }
  .success-text {
    color: ${(p) => p.$themeColor.primaryFontColor};

    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-size: 3.6rem;
    font-style: normal;
    font-weight: 600;
    line-height: 120.8%;
    letter-spacing: -0.18rem;
    margin-top: 1.4rem;
  }
  .navigate-text {
    color: ${(p) => p.$themeColor.doxleColor};

    font-family: ${(p) => p.$doxleFont.secondaryTitleFont};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.28px;

    margin-top: 1.4rem;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
      transition: 0.4s;
      transform: scale(1.1);
    }
  }
`;

export const StyledQuoteResponseMenuItem = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
  $selected: boolean;
}>`
  color: ${(p) => p.$themeColor.primaryFontColor};
  font-size: 1.4rem;
  font-weight: ${(p) => (p.$selected ? 700 : 400)};

  font-family: ${(p) => p.$doxleFont.primaryFont};
  line-height: normal;
  position: relative;
  padding: 0px 0.8rem 0.4rem 0.8rem;
  cursor: pointer;
  text-transform: capitalize;
  transition: 0.2s;
  &:hover {
    transform: scaleX(1.05);
    transition: 0.4s;
  }
  .selected-effect {
    position: absolute;
    width: 100%;
    bottom: 0px;
    left: 0px;
    height: 1px;
    background-color: ${(p) => p.$themeColor.primaryFontColor};
  }
`;

export const StyledQuoteSOWView = styled(motion.div)`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: 1320px;
  min-width: 600px;
  align-self: center;
`;
export const StyledSOWListScroller = styled.div`
  width: 100%;
  height: 100% !important;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const StyledQuoteSOWGroupItem = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  width: 100%;
  min-height: 0px;
  display: flex;
  flex-direction: column;
  padding-bottom: 3rem;

  .sow-group-title {
    text-transform: capitalize;
    font-family: ${(p) => p.$doxleFont.primaryTitleFont};
    font-weight: 600;
    font-size: 1.4rem;
    line-height: normal;
    color: ${(p) => p.$themeColor.primaryFontColor};
    margin-bottom: 0.8rem;
  }
  .sow-item-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
`;
export const StyledQuoteSOWItem = styled(motion.div)<{
  $themeColor: DoxleThemeColor;
}>`
  width: calc(100% - 2rem);
  aspect-ratio: 1;
  padding: 1rem;
  display: flex;

  @media screen and (min-width: 1201px) {
    width: calc(${100 / 5}% - 2rem);
  }
  @media screen and (min-width: 1025px) and (max-width: 1200px) {
    width: calc(${100 / 4}% - 2rem);
  }
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    width: calc(${100 / 3}% - 2rem);
  }
  @media screen and (min-width: 481px) and (max-width: 767px) {
    width: calc(${100 / 2}% - 2rem);
  }

  .item-content-wrapper {
    width: calc(100% - 1.6rem);
    height: calc(100% - 1.6rem);
    padding: 0.8rem;
    border-radius: 0.4rem;
    box-shadow: 0px 0px 4px ${(p) => p.$themeColor.primaryBoxShadowColor};
    background-color: ${(p) => p.$themeColor.primaryContainerColor};
    cursor: pointer;
    transition: 0.4s;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    position: relative;
    overflow: hidden;
  }
`;
export const StyledSOWItemTitle = styled(motion.div)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  text-transform: capitalize;
  font-family: ${(p) => p.$doxleFont.primaryTitleFont};
  font-weight: 500;
  font-size: 14px;
  line-height: normal;
  color: ${(p) => p.$themeColor.primaryFontColor};
  margin-top: 4px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const StyledSOWImgWrapper = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  overflow: hidden;
  .img-holder {
    width: calc(${90 / 2}% - 8px);
    margin: 4px;
    aspect-ratio: 1;
    border-radius: 0.4rem;
  }
  .more-item-text {
    width: calc(${90 / 2}% - 8px);
    margin: 4px;
    aspect-ratio: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: capitalize;
    font-family: ${(p) => p.$doxleFont.primaryTitleFont};
    font-weight: 500;
    font-size: 1.2rem;
    line-height: normal;
    color: ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.$themeColor.primaryFontColor,
        alpha: "0.4",
      })};
  }
`;

export const StyledSOWItemHoverEffect = styled(motion.div)<{
  $themeColor: DoxleThemeColor;
}>`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 100;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(p) =>
    editRgbaAlpha({ rgbaColor: p.$themeColor.primaryFontColor, alpha: "0.8" })};
`;
export const StyledQuoteRejectPage = styled(motion.div)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  .reject-img {
    width: 40%;
    max-width: 400px;
    min-width: 200px;
    aspect-ratio: ${1674.46 / 1136.05};
  }
  .reject-text {
    color: ${(p) => p.$themeColor.primaryFontColor};

    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-size: 3.6rem;
    font-style: normal;
    font-weight: 600;
    line-height: 120.8%;
    letter-spacing: -0.18rem;
    margin-top: 1.4rem;
  }
  .navigate-text {
    color: ${(p) => p.$themeColor.doxleColor};

    font-family: ${(p) => p.$doxleFont.secondaryTitleFont};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.28px;

    margin-top: 1.4rem;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
      transition: 0.4s;
      transform: scale(1.1);
    }
  }
`;
