import { useEffect, useRef } from "react";
import { useFileStore } from "../Store/useFileStore";
import { useShallow } from "zustand/react/shallow";
interface Props {}
const useFileBody = ({}: Props) => {
  const { currentLevel, currentView, setCurrentView } = useFileStore(
    useShallow((state) => ({
      currentLevel: state.currentLevel,
      currentView: state.currentView,
      setCurrentView: state.setCurrentView,
    }))
  );

  const folderInputRef = useRef<HTMLInputElement>(null);
  const url = window.location.href;
  const urlArray = url.split("/");
  //* GETTING THE LAST ITEM OF THE ARRAY

  const nonEmptyUrlArray = urlArray.filter((item) => item);

  const urlPath = nonEmptyUrlArray[nonEmptyUrlArray.length - 1];
  useEffect(() => {
    if (currentLevel !== "Company" && currentView !== "ListView")
      setCurrentView("ListView");
  }, [currentLevel, currentView]);

  useEffect(() => {
    if (folderInputRef.current !== null) {
      folderInputRef.current.setAttribute("directory", "");
      folderInputRef.current.setAttribute("webkitdirectory", "");
    }
  }, [folderInputRef]);
  return {
    urlPath,
    currentView,
    currentLevel,

    folderInputRef,
  };
};

export default useFileBody;
