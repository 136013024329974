import React from "react";
import { Contact } from "../../Models/addressBook";
import { AutocompleteRenderGetTagProps } from "@mui/material";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import { StyledAutocompleteContactValueTagContainer } from "./StyledComponentDoxleAutocomplete";
import { TagRemoveIcon } from "./AutocompleteIcons";

type Props = {};

const AutocompleteContactValueTag = (props: {
  value: Contact;
  index: number;
  getTagProps: AutocompleteRenderGetTagProps;
}) => {
  const { value, index, getTagProps } = props;
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore((state) => ({
    doxleThemeColor: state.doxleThemeColor,
    doxleFont: state.doxleFont,
  }));
  //********* ANIMATION ******* */
  const tagContainerAnimatedVariants = {
    entering: {
      x: [-10, 0],
      opacity: [0, 1],
      transition: {
        duration: 0.2,
      },
    },
    exiting: {
      x: [0, -10],
      opacity: [1, 0],
      transition: {
        duration: 0.2,
      },
    },
  };
  //*************************** */
  return (
    <StyledAutocompleteContactValueTagContainer
      variants={tagContainerAnimatedVariants}
      initial={false}
      animate="entering"
      exit="exiting"
      $themeColor={doxleThemeColor}
      $doxleFont={doxleFont}
      variant="outlined"
      label={`${value.firstName} ${value.lastName}`}
      {...getTagProps({ index })}
      deleteIcon={<TagRemoveIcon $themeColor={doxleThemeColor} />}
    />
  );
};

export default AutocompleteContactValueTag;
