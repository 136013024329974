import React, { useState } from "react";
import { Contact } from "../../../../../Models/addressBook";

type Props = {};

interface SelectedSharedAssignee {
  anchorAssigneeList: HTMLElement | null;
  setAnchorAssigneeList: React.Dispatch<
    React.SetStateAction<HTMLElement | null>
  >;
  selectedAssignee: Contact | undefined;
  setselectedAssignee: React.Dispatch<
    React.SetStateAction<Contact | undefined>
  >;
  handleCloseAssigneeList: () => void;
}
const useSelectSharedAssignee = (props: Props): SelectedSharedAssignee => {
  const [selectedAssignee, setselectedAssignee] = useState<Contact | undefined>(
    undefined
  );
  const [anchorAssigneeList, setAnchorAssigneeList] =
    useState<HTMLElement | null>(null);

  const handleCloseAssigneeList = () => {
    setAnchorAssigneeList(null);
  };
  return {
    anchorAssigneeList,
    setAnchorAssigneeList,
    selectedAssignee,
    setselectedAssignee,
    handleCloseAssigneeList,
  };
};

export default useSelectSharedAssignee;
