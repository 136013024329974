import React, { useEffect, useState } from "react";
import { QAList } from "../../../../Models/qa";
import { useProjectQAStore } from "../../../Store/useProjectQAStore";
import { shallow } from "zustand/shallow";
import { useIsMutating } from "@tanstack/react-query";
import QAQueryAPI, {
  getQAListMutationKey,
} from "../../../../QueryAPI/qaQueryAPI";
import { useDoxleAuthStore } from "../../../../../DoxleGeneralStore/useDoxleAuthStore";
import { useDoxleCurrentContextStore } from "../../../../../DoxleGeneralStore/useDoxleCurrentContext";
import useDoxleNotificationStore from "../../../../../DoxleGeneralStore/useDoxleNotificationStore";
import useSetQAListQueryData from "../../../../CustomQueryHooks/useSetQAListQueryData";

type Props = {
  qaListItem: QAList;
};

interface QAListRow {
  showSubmenu: boolean;
  setShowSubmenu: React.Dispatch<React.SetStateAction<boolean>>;
  handleClickExpandMenu: (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => void;
  handleCloseSubmenu: () => void;
  handleClickRow: () => void;
  isHoverRow: boolean;
  setisHoverRow: React.Dispatch<React.SetStateAction<boolean>>;
  isDeletingQAList: boolean;
  handleUpdateCompleteQAList: () => void;
  isUpdatingQAList: boolean;
}
const useQAListRow = ({ qaListItem }: Props): QAListRow => {
  const [isHoverRow, setisHoverRow] = useState(false);
  const [showSubmenu, setShowSubmenu] = useState(false);
  const { setSelectedQAList, filterQAListQuery } = useProjectQAStore(
    (state) => ({
      setSelectedQAList: state.setSelectedQAList,
      filterQAListQuery: state.filterQAListQuery,
    }),
    shallow
  );
  const handleClickExpandMenu = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    e.preventDefault();
    e.stopPropagation();
    setShowSubmenu((prev) => !prev);
  };
  const handleCloseSubmenu = () => {
    setShowSubmenu(false);
  };

  const isDeletingQAList =
    useIsMutating({
      mutationKey: getQAListMutationKey("delete"),
      predicate: (query) =>
        Boolean(query.state.variables === qaListItem.defectListId),
    }) > 0;
  const { handleEditQAList } = useSetQAListQueryData({
    filter: filterQAListQuery,
  });
  const handleClickRow = () => {
    setSelectedQAList(qaListItem);

    if (qaListItem.isNew)
      handleEditQAList({ ...qaListItem, isNew: false, contributors: [] });
  };

  const company = useDoxleCurrentContextStore(
    (state) => state.currentCompany,
    shallow
  );
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );

  const onUpdateSuccessCb = (newQAList?: QAList) => {
    if (newQAList) {
      handleEditQAList(newQAList);
    }
  };
  const updateQAListQuery = QAQueryAPI.useUpdateQAListQuery({
    company,
    showNotification,
    onSuccessCB: onUpdateSuccessCb,
  });

  const handleUpdateCompleteQAList = () => {
    updateQAListQuery.mutate({
      qaList: qaListItem,
      updateParams: {
        completed: !qaListItem.completed,
      },
    });
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (qaListItem.isNew) handleClickRow();
    }, 400);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return {
    showSubmenu,
    setShowSubmenu,
    handleClickExpandMenu,
    handleCloseSubmenu,
    handleClickRow,
    isHoverRow,
    setisHoverRow,
    isDeletingQAList,
    handleUpdateCompleteQAList,
    isUpdatingQAList: updateQAListQuery.isLoading,
  };
};

export default useQAListRow;
