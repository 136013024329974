import { useMemo } from "react";
import { QA, QAFile } from "../../../../Models/qa";
import useDoxleNotificationStore from "../../../../../DoxleGeneralStore/useDoxleNotificationStore";
import { useDoxleCurrentContextStore } from "../../../../../DoxleGeneralStore/useDoxleCurrentContext";
import { useShallow } from "zustand/react/shallow";
import QAQueryAPI from "../../../../QueryAPI/qaQueryAPI";
import { useProjectQAStore } from "../../../Store/useProjectQAStore";

type Props = {};

interface IQAFileList {
  videoList: QAFile[];
  isFetchingVideo: boolean;
  isSuccessFetchingVideo: boolean;
  isErrorFetchingVideo: boolean;
  isRefetchingVideo: boolean;
  refetchVideoList: () => void;
}
const useQAFileList = ({}: Props): IQAFileList => {
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification
  );
  const { company } = useDoxleCurrentContextStore(
    useShallow((state) => ({
      company: state.currentCompany,
    }))
  );
  const { selectedQAItem } = useProjectQAStore(
    useShallow((state) => ({
      selectedQAItem: state.selectedQAItem,
    }))
  );

  const getQAImageQuery = QAQueryAPI.useRetrieveQAFileList({
    showNotification,
    company,
    qaId: selectedQAItem?.defectId ?? "",
    enable: !!selectedQAItem,
  });

  const videoList = useMemo(
    () => (getQAImageQuery.isSuccess ? getQAImageQuery.data.data.results : []),
    [getQAImageQuery.data]
  );

  const refetchVideoList = () => {
    getQAImageQuery.refetch();
  };
  return {
    videoList,
    isFetchingVideo: getQAImageQuery.isLoading,
    isSuccessFetchingVideo: getQAImageQuery.isSuccess,
    isErrorFetchingVideo: getQAImageQuery.isError,
    isRefetchingVideo: getQAImageQuery.isRefetching,
    refetchVideoList,
  };
};

export default useQAFileList;
