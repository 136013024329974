import { BUDGET_TABLE_HEADER_LIST } from "../Models/BudgetDocket";
import {
  StyleBudgetTableDataCell,
  StyledBudgetStatus,
  StyledBudgetTableDataContainer,
} from "./BudgetStyleComponent";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import { editRgbaAlpha, formatter } from "../../Utilities/FunctionUtilities";
import { AnimatePresence, Variants, motion } from "framer-motion";
import useBudgetTableRow from "./Hooks/useBudgetTableRow";
import { BudgetRowCommentIcon } from "./BudgetIcon";
import { CircularProgress } from "@mui/material";
import { FiArrowUpRight } from "react-icons/fi";
import { FiArrowDownLeft } from "react-icons/fi";
import React from "react";
import AssigneeDisplay from "./AssigneeDisplay";
import {
  DoxleHashMenuIcon,
  DoxleUnpinIcon,
} from "../../DoxleDesignPattern/DoxleGeneralIcons";
import { LightDocket } from "../../Models/dockets";
import { useShallow } from "zustand/react/shallow";

interface Props {
  docket: LightDocket;

  handleOpenBudgetMenu: (
    event: React.MouseEvent<SVGSVGElement, MouseEvent>,
    item: LightDocket
  ) => void;
}

const BudgetTableRow = ({
  docket,

  handleOpenBudgetMenu,
}: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    useShallow((state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }))
  );

  const {
    handleClickRow,

    isUpdatingBudget,
    isDeletingBudget,
    isFetchingXeroData,
    convertedStatusColor,
  } = useBudgetTableRow({ docket });
  //* animation
  const budgetRowAnimatedVariants = {
    initial: {
      opacity: 0,
      y: -3,
    },
    entering: {
      opacity: 1,
      y: 0,
    },
    exiting: {
      opacity: 0,
      x: 10,
    },
  };

  const statusAnimateVariants: Variants = {
    statusAnimate: {
      opacity: [1, 0.1, 1, 0.1, 1, 1], // Blink twice

      transition: {
        times: [0, 0.1, 0.2, 0.3, 0.4, 1], // Timing for each keyframe
        duration: 2, // Total duration for one sequence
        repeat: Infinity, // Repeat infinitely
        ease: "linear", // Linear easing for uniform animation speed
      },
    },
  };

  return (
    <StyledBudgetTableDataContainer
      variants={budgetRowAnimatedVariants}
      initial="initial"
      animate="entering"
      exit="exiting"
      layout="position"
      transition={{
        duration: 0.3,
        damping: 16,
      }}
      className={docket.statusStrikethrough ? "strikeout" : ""}
    >
      {/* status bg color */}
      {/* <motion.div
        className="status-background"
        initial={{
          backgroundColor: editRgbaAlpha({
            rgbaColor: docket.statusColor,
            alpha: "0.1",
          }),
        }}
        animate={
          docket.statusAnimateIcon
            ? {
                backgroundColor: [
                  docket.statusColor,
                  editRgbaAlpha({
                    rgbaColor: docket.statusColor,
                    alpha: "0.5",
                  }),
                ],

                transition: {
                  duration: 0.5,
                  repeat: Infinity,
                  repeatType: "reverse",
                  repeatDelay: 0.4,
                },
              }
            : { backgroundColor: docket.statusColor }
        }
      /> */}
      <AnimatePresence>
        {docket.completed && <div className="completed-line-through" />}
      </AnimatePresence>

      {BUDGET_TABLE_HEADER_LIST.map((header, idx) => {
        if (header.isDisplayed) {
          if (header.headerName === "Name") {
            return (
              <StyleBudgetTableDataCell
                $hasBorderRight={true}
                key={`docketCell#${docket.docketId}#${idx}`}
                $widthRatio={null}
                $horizontalAlign="flex-start"
                onClick={() => handleClickRow()}
                $completed={Boolean(docket.completed)}
              >
                {!isUpdatingBudget && !isDeletingBudget ? (
                  <StyledBudgetStatus
                    variants={statusAnimateVariants}
                    $statusColor={convertedStatusColor}
                    initial={{
                      rotateZ: 45,
                    }}
                    animate={
                      docket.statusAnimateIcon ? "statusAnimate" : undefined
                    }
                  />
                ) : (
                  <CircularProgress
                    size="14px"
                    style={{
                      color: isDeletingBudget
                        ? "red"
                        : doxleThemeColor.primaryFontColor,
                      marginInline: 8,
                      flexShrink: 0,
                    }}
                  />
                )}

                <motion.div
                  className="cell-text-content"
                  style={{
                    textDecoration: docket.statusUnderline
                      ? "underline"
                      : "none",
                    fontStyle: docket.statusItalics ? "italic" : undefined,
                    fontWeight: docket.statusBold ? 700 : undefined,
                  }}
                  layout="position"
                  transition={{
                    duration: 0.2,
                    damping: 16,
                  }}
                >
                  {docket.docketName}
                </motion.div>
                {docket.isSticky && (
                  <DoxleUnpinIcon
                    themeColor={doxleThemeColor}
                    containerStyle={{
                      width: 16,
                      cursor: "pointer",
                      marginLeft: 8,
                      flexShrink: 0,
                    }}
                  />
                )}
                {docket.ballInCourt && (
                  <AssigneeDisplay assigneeText={docket.ballInCourtName} />
                )}
                {docket.commentCount > 0 && (
                  <BudgetRowCommentIcon
                    themeColor={doxleThemeColor}
                    containerStyle={{ marginLeft: 8 }}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleClickRow("comment");
                    }}
                  />
                )}

                <DoxleHashMenuIcon
                  themeColor={doxleThemeColor}
                  containerStyle={{
                    cursor: "pointer",
                    marginLeft: 4,
                    padding: 4,
                    flexShrink: 0,
                  }}
                  onClick={(e) => {
                    handleOpenBudgetMenu(e, docket);
                  }}
                />
              </StyleBudgetTableDataCell>
            );
          } else
            return (
              <StyleBudgetTableDataCell
                $hasBorderRight={header.headerName !== "Final"}
                key={`docketCell#${docket.docketId}#${idx}`}
                $widthRatio={"16%"}
                $horizontalAlign="center"
                onClick={(e) => {
                  if (header.headerName === "Budget") handleClickRow("budget");
                  else if (header.headerName === "Order")
                    handleClickRow("order");
                  else handleClickRow("payment");
                }}
              >
                <span
                  className="cell-text-content"
                  style={{
                    color:
                      header.headerName === "Budget"
                        ? editRgbaAlpha({
                            rgbaColor: doxleThemeColor.primaryFontColor,
                            alpha: docket.completed ? "0.5" : "1",
                          })
                        : header.headerName === "Xero"
                        ? doxleThemeColor.doxleColor
                        : doxleThemeColor.primaryFontColor,
                    textAlign: "center",
                    fontWeight: header.headerName === "Final" ? "600" : "500",
                    marginRight: header.headerName === "Order" ? 4 : 0,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {header.headerName === "Budget" &&
                    (docket.isExpense
                      ? formatter.format(parseFloat(docket.costBudget ?? "0"))
                      : docket.isIncome
                      ? formatter.format(parseFloat(docket.costBudget ?? "0"))
                      : "")}

                  {header.headerName === "Order" &&
                    (docket.isExpense
                      ? formatter.format(parseFloat(docket.costActual ?? "0"))
                      : docket.isIncome
                      ? formatter.format(parseFloat(docket.incomeActual ?? "0"))
                      : "")}

                  {header.headerName === "Xero" &&
                    (docket.isExpense
                      ? formatter.format(parseFloat(docket.costXero ?? "0"))
                      : docket.isIncome
                      ? formatter.format(parseFloat(docket.incomeXero ?? "0"))
                      : "")}

                  {header.headerName === "Final" &&
                    (docket.isExpense
                      ? formatter.format(parseFloat(docket.costRunning ?? "0"))
                      : docket.isIncome
                      ? formatter.format(
                          parseFloat(docket.incomeRunning ?? "0")
                        )
                      : "")}

                  {header.headerName === "Order" && (
                    <div
                      style={{
                        marginBottom: "4px",
                      }}
                    >
                      {docket.costActual !== null &&
                      docket.costBudget !== null ? (
                        parseFloat(docket.costActual) >
                        parseFloat(docket.costBudget) ? (
                          <FiArrowUpRight fontSize="16px" color="#EA1515" />
                        ) : (
                          <FiArrowDownLeft fontSize="16px" color="#209D34" />
                        )
                      ) : (
                        <FiArrowUpRight fontSize="16px" color="#EA1515" />
                      )}
                    </div>
                  )}
                  {header.headerName === "Xero" && isFetchingXeroData && (
                    <CircularProgress
                      size="12px"
                      style={{
                        marginLeft: 8,
                        color: doxleThemeColor.doxleColor,
                      }}
                    />
                  )}
                </span>
              </StyleBudgetTableDataCell>
            );
        }
        return null;
      })}
    </StyledBudgetTableDataContainer>
  );
};

export default BudgetTableRow;
