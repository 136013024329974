import { CircularProgress } from "@mui/material";
import { useIsMutating } from "@tanstack/react-query";
import React, { useCallback, useMemo, useRef } from "react";
import { GridComponents, GridItemContent, VirtuosoGrid } from "react-virtuoso";
import styled from "styled-components";
import { shallow } from "zustand/shallow";
import { useDoxleCurrentContextStore } from "../../DoxleGeneralStore/useDoxleCurrentContext";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import { DoxleFile, DoxleFolder } from "../../Models/files";
import { useFileStore } from "../Store/useFileStore";
import GridFilesInsideFolderView from "./GridFilesInsideFolderView";
import GridFolderFiles from "./GridFolderFiles";
import {
  StyledGridItemContainer,
  StyledGridItemWrapper,
  StyledGridView,
} from "./StyledFilesBodyComponent";
import useGetRootFolderQuery from "../Hooks/useGetRootFolderQuery";
import useGetFileQueryRoot from "../Hooks/useGetFileQueryRoot";
import useGridFolderFilesView from "../Hooks/useGridFolderFilesView";
import ConfirmFolderUploadDialog from "../Components/ConfirmFolderUploadDialog";
import ListLoadingMore from "../../Utilities/Lottie/ListLoadingMore";

const ItemContainer = styled.div`
  padding: 0px;

  width: 25%;
  // height: 100%;
  display: flex;
  flex: none;
  align-content: stretch;
  background-color: green;

  // @media (max-width: 1024px) {
  //   width: 50%;
  // }

  // @media (max-width: 480px) {
  //   width: 100%;
  // }
`;

const ListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  width: 100%;
`;

const ItemWrapper = styled.div`
  flex: 1;
  text-align: center;
  font-size: 16px;
  // padding: 1rem 1rem;
  // border: 1px solid var(--ifm-hr-border-color);
  background-color: white;
  height: 30rem;
  width: 30rem;
  white-space: nowrap;
  border: 1px solid #ccd;
`;

type Props = {
  urlPath: string;
};

interface GridFileListcontext {}
function GridFolderFilesView({ urlPath }: Props) {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore((state) => ({
    doxleThemeColor: state.doxleThemeColor,
    doxleFont: state.doxleFont,
  }));

  const { company } = useDoxleCurrentContextStore((state) => ({
    company: state.currentCompany,
  }));

  const {
    folderListSuccess,
    isFetchingFolderList,
    isSuccessFetchingFolderList,
    isErrorFetchingFolderList,
  } = useGetRootFolderQuery({});

  const {
    fileListSuccess,
    isFetchingFileList,
    isSuccessFetchingFileList,
    isErrorFetchingFileList,
    handleFetchNextPage,
    isFetchingNextPage,
  } = useGetFileQueryRoot({});
  const combinedFileFolders: Array<DoxleFile | DoxleFolder> = useMemo(
    () => [
      ...folderListSuccess.sort((a, b) =>
        a.folderName < b.folderName ? -1 : 1
      ),
      ...fileListSuccess.sort((a, b) => (a.fileName < b.fileName ? -1 : 1)),
    ],
    [folderListSuccess, fileListSuccess]
  );

  const virtuoso = useRef(null);

  const addFileMutationKey = useIsMutating({
    mutationKey: ["ADD-FILES-MUTATION-KEY", company?.companyId],
  });
  const deleteFolderMutationKey = useIsMutating({
    mutationKey: ["DELETE-FOLDER-MUTATION-KEY", company?.companyId],
  });
  const {
    handleFileFolderDrop,
    pendingFolderUpload,
    setPendingFolderUpload,
    handleUploadFiles,
    isAddingFileRoot,
  } = useGridFolderFilesView();
  //! SETTING THE ROOT FOLDER TO TRUE, AS ITS NOT INSIDE THE FOLDER
  // setCurrentFolder("ROOT");

  //* render file list
  const components: GridComponents<GridFileListcontext> = useMemo(
    () => ({
      Item: StyledGridItemContainer,
      List: React.forwardRef((props, ref) => (
        <ListContainer {...props} ref={ref} />
      )),
      ScrollSeekPlaceholder: ({ height, index }) => (
        <StyledGridItemContainer>
          <StyledGridItemWrapper $themeColor={doxleThemeColor}>
            {"--"}
          </StyledGridItemWrapper>
        </StyledGridItemContainer>
      ),
    }),
    []
  );

  const itemContent: GridItemContent<
    DoxleFile | DoxleFolder,
    GridFileListcontext
  > = useCallback((index, item) => {
    if ((item as DoxleFile).fileId) {
      return <GridFolderFiles gridItem={item as DoxleFile} />;
    } else {
      return <GridFolderFiles gridItem={item as DoxleFolder} />;
    }
  }, []);

  //* ROOT FOLDER
  if (urlPath === "Files") {
    return (
      <>
        {(isSuccessFetchingFileList || isSuccessFetchingFolderList) && (
          <StyledGridView
            $themeColor={doxleThemeColor}
            onDrop={handleFileFolderDrop}
          >
            <ConfirmFolderUploadDialog
              folder={pendingFolderUpload}
              setFolder={setPendingFolderUpload}
              uploadFn={handleUploadFiles}
            />
            <VirtuosoGrid
              data={combinedFileFolders}
              ref={virtuoso}
              totalCount={combinedFileFolders.length}
              overscan={200}
              style={{
                height: "100%",
                width: "100%",
              }}
              components={components}
              itemContent={itemContent}
              scrollSeekConfiguration={{
                enter: (velocity) => Math.abs(velocity) > 200,
                exit: (velocity) => Math.abs(velocity) < 30,
                change: (_, range) => console.log({ range }),
              }}
            />
          </StyledGridView>
        )}
        {(isFetchingFileList ||
          isFetchingFolderList ||
          addFileMutationKey ||
          deleteFolderMutationKey ||
          isAddingFileRoot) && (
          <ListLoadingMore
            containerStyle={{
              position: "absolute",
              zIndex: 10,
              bottom: 0,
              left: 0,
              width: "100%",
              justifyContent: "center",
              display: "flex",
            }}
            animationSize={80}
          />
        )}
      </>
    );
  } else {
    return <GridFilesInsideFolderView />;
  }
}

export default GridFolderFilesView;
