import React from "react";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import { StyledRFISkeletonRowContainer } from "./StyledComponentDoxleSkeleton";
import { Skeleton } from "@mui/material";

type Props = {};

const RFISkeletonRow = (props: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore((state) => ({
    doxleThemeColor: state.doxleThemeColor,
    doxleFont: state.doxleFont,
  }));
  return (
    <StyledRFISkeletonRowContainer>
      <Skeleton
        width="100%"
        animation="wave"
        variant="rounded"
        height={30}
        sx={{
          mb: 1,
          borderRadius: 30,
          backgroundColor: doxleThemeColor.primaryBoxShadowColor,
        }}
      />

      <Skeleton
        width="60%"
        animation="wave"
        variant="rounded"
        height={18}
        sx={{
          mb: 1,
          borderRadius: 18,
          backgroundColor: doxleThemeColor.primaryBoxShadowColor,
        }}
      />
      <Skeleton
        width="40%"
        animation="wave"
        variant="rounded"
        height={18}
        sx={{
          borderRadius: 18,
          backgroundColor: doxleThemeColor.primaryBoxShadowColor,
        }}
      />
    </StyledRFISkeletonRowContainer>
  );
};

export default RFISkeletonRow;
