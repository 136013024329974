import React, { useCallback, useEffect, useMemo, useRef } from "react";
import {
  StyledBookingDocketCommentContainer,
  StyledDiscussionListHeader,
  StyledDiscussionRowWrapper,
} from "./StyledComponentBookingDocketDetail";
import useBookingDocketComment from "./Hooks/useBookingDocketComment";
import {
  Components,
  ItemContent,
  ItemProps,
  Virtuoso,
  VirtuosoHandle,
} from "react-virtuoso";
import { Discussion } from "../../../../Models/discussion";

import { AnimatePresence } from "framer-motion";
import DoxleEmptyPlaceHolder from "../../../../DoxleDesignPattern/DoxleEmptyPlaceHolder/DoxleEmptyPlaceHolder";
import CircularProgress from "@mui/material/CircularProgress";
import { useDoxleThemeStore } from "../../../../DoxleGeneralStore/useDoxleThemeStore";
import DiscussionItem from "./DiscussionItem";
import DoxleIconButton from "../../../../DoxleDesignPattern/DoxleButtons/DoxleIconButton";
import { HiChevronDoubleDown } from "react-icons/hi";
import DoxleListSkeleton from "../../../../DoxleDesignPattern/DoxleSkeleton/DoxleListSkeleton";
import DoxleGallery from "../../../../DoxleDesignPattern/DoxleGallery/DoxleGallery";
import { StyledVirtuosoListScroller } from "../../../CommonComponents/StyledComponentBookings";
import CommentInputSection from "../../../BookingDocket/Components/BookingDocketDetail/CommentInputSection";
import { EmptyResponseBanner } from "../../../../Quotes/Content/QuotesIcon";
import { Error404Banner } from "../../../../DoxleDesignPattern/DoxleIcons/CommonIcons";

type Props = {
  docketId: string;
};
interface CommentListContext {
  isFetchingNextPage: boolean;
  isErrorFetchingDiscussion: boolean;
}
const BookingDocketComment = ({ docketId }: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore((state) => ({
    doxleThemeColor: state.doxleThemeColor,
    doxleFont: state.doxleFont,
  }));
  const {
    discussionList,
    isFetchingDiscussion,
    isSuccessFetchingDiscussion,
    isErrorFetchingDiscussion,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    currentVisibleRange,
    setCurrentVisibleRange,
    shouldShowIconScrollToBottom,
    filterDiscussionList,
    totalItemCount,
  } = useBookingDocketComment({ docketId });
  const commentListRef = useRef<VirtuosoHandle>(null);

  const handleScrollToBottom = () => {
    commentListRef.current?.scrollToIndex({
      index: discussionList.length - 1,
      behavior: "smooth",
    });
  };
  //* render list
  const itemContent: ItemContent<Discussion, CommentListContext> = useCallback(
    (index, item) => (
      <DiscussionItem
        discussionItem={item}
        key={`discussion#${item.commentId}`}
      />
    ),
    []
  );
  const components: Components<Discussion, CommentListContext> = useMemo(
    () => ({
      Scroller: React.forwardRef((props, ref) => (
        <StyledVirtuosoListScroller ref={ref} {...props} />
      )),
      List: React.forwardRef((props, ref) => {
        const { children, ...rest } = props;
        return (
          <div {...rest} ref={ref}>
            <AnimatePresence>{children}</AnimatePresence>
          </div>
        );
      }),
      Item: React.forwardRef<HTMLDivElement, ItemProps<Discussion>>(
        (props, ref) => (
          <StyledDiscussionRowWrapper
            {...props}
            ref={ref}
            key={props.item.commentId}
          />
        )
      ),
      EmptyPlaceholder: (props) =>
        props.context?.isErrorFetchingDiscussion ? (
          <DoxleEmptyPlaceHolder
            headTitleText="Something wrong!"
            subTitleText="Failed to get comments..."
            containerStyle={{
              width: "100%",
              height: "100%",
            }}
            containerBgColor="transparent"
            headTitleTextStyle={{ fontSize: "3rem" }}
            subTitleTextStyle={{ fontSize: "1.8rem" }}
            illustration={
              <Error404Banner
                themeColor={doxleThemeColor}
                containerStyle={{ width: "30%" }}
              />
            }
          />
        ) : (
          <DoxleEmptyPlaceHolder
            headTitleText="No comments"
            subTitleText="Add your comments to communicate with your colleagues..."
            containerStyle={{
              width: "100%",
              height: "100%",
            }}
            containerBgColor="transparent"
            headTitleTextStyle={{ fontSize: "3rem" }}
            subTitleTextStyle={{ fontSize: "1.8rem" }}
            illustration={<EmptyResponseBanner themeColor={doxleThemeColor} />}
          />
        ),
      Header: React.forwardRef((props, ref) => (
        <AnimatePresence>
          {props.context?.isFetchingNextPage && (
            <StyledDiscussionListHeader
              initial={false}
              animate={{
                y: [-10, 0],
                opacity: [0, 1],
              }}
              exit={{
                y: [0, -10],
                opacity: [1, 0],
              }}
              transition={{
                duration: 0.2,
              }}
            >
              <CircularProgress
                size="1.4rem"
                style={{
                  color: doxleThemeColor.primaryFontColor,

                  alignSelf: "center",
                  justifySelf: "center",
                }}
              />
            </StyledDiscussionListHeader>
          )}
        </AnimatePresence>
      )),
    }),
    []
  );
  const listStyle: React.CSSProperties = { height: "100%", width: "100%" };

  return (
    <StyledBookingDocketCommentContainer>
      <div className="comment-list-wrapper">
        {!isFetchingDiscussion && (
          <Virtuoso
            style={listStyle}
            data={discussionList}
            itemContent={itemContent}
            components={components}
            totalCount={discussionList.length}
            ref={commentListRef}
            atTopStateChange={fetchNextPage}
            context={{ isFetchingNextPage, isErrorFetchingDiscussion }}
            followOutput="smooth"
            alignToBottom
            initialTopMostItemIndex={discussionList.length - 1}
            atTopThreshold={0.4}
            rangeChanged={setCurrentVisibleRange}
          />
        )}
        {isFetchingDiscussion && (
          <DoxleListSkeleton
            containerStyle={{
              width: "100%",
              height: "100%",
            }}
            skeletonType="comment"
            numOfRows={4}
          />
        )}

        <AnimatePresence>
          {shouldShowIconScrollToBottom && (
            <DoxleIconButton
              onClick={handleScrollToBottom}
              iconSource={
                <HiChevronDoubleDown
                  color={doxleThemeColor.primaryFontColor}
                  size="3rem"
                />
              }
              buttonSize={40}
              buttonWrapperStyle={{
                position: "absolute",
                bottom: 0,
                marginBottom: "10px",
                left: "calc(50% - 2rem)",

                zIndex: 99,
                boxShadow: `0px 0px 4px 0px ${doxleThemeColor.primaryBoxShadowColor}`,
              }}
              bgColor={doxleThemeColor.primaryContainerColor}
            />
          )}
        </AnimatePresence>
      </div>
      <CommentInputSection
        commentListRef={commentListRef}
        discussionList={discussionList}
        filterDiscussionList={filterDiscussionList}
      />
    </StyledBookingDocketCommentContainer>
  );
};

export default BookingDocketComment;
