import { forwardRef, useCallback } from "react";
import { useSnackbar, CustomContentProps } from "notistack";
import { RootTextOnlyNotificationContainer } from "./StyledComponentNotification";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import { ErrorNotiIcon, NotiSuccessIcon } from "./NotificationIcons";
interface ITextOnlyNotification extends CustomContentProps {
  extraDescription?: string;
  messageType: "error" | "success";
}

//################### CONSTANTS ####################
// const SUCCESS_BACKGROUND_COLOR: string = "#4caf50";
// const ERROR_BACKGROUND_COLOR: string = "#f44336";
//#################################################
const TextOnlyNotification = forwardRef<HTMLDivElement, ITextOnlyNotification>(
  ({ id, extraDescription, message, messageType, ...props }, ref) => {
    const { closeSnackbar } = useSnackbar();
    const { doxleThemeColor, doxleFont, currentTheme } = useDoxleThemeStore(
      (state) => ({
        doxleThemeColor: state.doxleThemeColor,
        doxleFont: state.doxleFont,
        currentTheme: state.currentTheme,
      }),
      shallow
    );
    //################### CONSTANTS ####################
    const SUCCESS_BACKGROUND_COLOR: string = "#4caf50";
    const ERROR_BACKGROUND_COLOR: string =
      currentTheme === "dark" ? "rgb(79, 79, 179)" : "rgb(112, 112, 255)";
    //#################################################
    const handleDismiss = useCallback(() => {
      closeSnackbar(id);
    }, [id, closeSnackbar]);
    return (
      <RootTextOnlyNotificationContainer
        $themeColor={doxleThemeColor}
        $doxleFont={doxleFont}
        ref={ref}
        $backgroundColor={
          messageType === "error"
            ? ERROR_BACKGROUND_COLOR
            : SUCCESS_BACKGROUND_COLOR
        }
        onClick={handleDismiss}
      >
        {messageType === "success" ? (
          <NotiSuccessIcon
            themeColor={doxleThemeColor}
            containerStyle={{ marginRight: 8, width: 24 }}
          />
        ) : (
          <ErrorNotiIcon
            themeColor={doxleThemeColor}
            containerStyle={{ marginRight: 8, width: 24 }}
          />
        )}

        <span> {message}</span>

        {/* {extraDescription && (
          <StyledExtraDesceiptionText
            $themeColor={doxleThemeColor}
            $doxleFont={doxleFont}
          >
            {extraDescription}
          </StyledExtraDesceiptionText>
        )} */}
      </RootTextOnlyNotificationContainer>
    );
  }
);

// @ts-ignore
TextOnlyNotification.displayName = "TextOnlyNotification";
export default TextOnlyNotification;
