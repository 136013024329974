import {
  DownloadNotificationContainer,
  StyledMessageContainer,
  StyledMessageContent,
  StyledMessageTitle,
  StyledProgressContainer,
} from "./StyledComponentNotification";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import CircularProgress from "@mui/material/CircularProgress";
import { shallow } from "zustand/shallow";

type TDownloadNotificationProps = {
  msgTitle: string;
  msgContent?: string;
  status: "success" | "error" | "loading";
  onClick?: () => void;
};

const SUCCESS_BACKGROUND_COLOR: string = "#4caf50";
const ERROR_BACKGROUND_COLOR: string = "#ff5252";
const LOADING_BACKGROUND_COLOR: string = "#5252FF";

const DownloadNotification = ({
  msgTitle,
  msgContent,
  status,
  onClick,
}: TDownloadNotificationProps) => {
  const { doxleThemeColor, doxleFont, currentTheme } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
      currentTheme: state.currentTheme,
    }),
    shallow
  );

  return (
    <DownloadNotificationContainer
      onClick={() => {
        if (onClick) onClick();
      }}
      $themeColor={doxleThemeColor}
      $doxleFont={doxleFont}
      $backgroundColor={
        status === "success"
          ? SUCCESS_BACKGROUND_COLOR
          : status === "loading"
          ? LOADING_BACKGROUND_COLOR
          : ERROR_BACKGROUND_COLOR
      }
    >
      {status === "loading" && (
        <StyledProgressContainer>
          <CircularProgress />
        </StyledProgressContainer>
      )}
      <StyledMessageContainer
        $themeColor={doxleThemeColor}
        $doxleFont={doxleFont}
      >
        <StyledMessageTitle
          $themeColor={doxleThemeColor}
          $doxleFont={doxleFont}
        >
          {msgTitle}
        </StyledMessageTitle>
        {msgContent && (
          <StyledMessageContent
            $themeColor={doxleThemeColor}
            $doxleFont={doxleFont}
          >
            {msgContent}
          </StyledMessageContent>
        )}
      </StyledMessageContainer>
    </DownloadNotificationContainer>
  );
};

export default DownloadNotification;
