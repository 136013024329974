import styled from "styled-components";
import {
  DoxleFont,
  DoxleThemeColor,
} from "../../../DoxleGeneralStore/useDoxleThemeStore";

export const StyledAddQuotesMenuPopper = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  height: 6.2rem;
  width: 18.2rem;
  display: flex;
  flex-direction: column;
  padding: 1.4rem;
  background-color: ${(p) => p.$themeColor.primaryContainerColor};
  box-shadow: 0px 2px 4px -1px ${(p) => p.$themeColor.primaryBoxShadowColor},
    0px 4px 5px 0px ${(p) => p.$themeColor.primaryBoxShadowColor},
    0px 1px 10px 0px ${(p) => p.$themeColor.primaryBoxShadowColor};
  border-radius: 0px 0.9rem;
  margin-top: 0.4rem;
  .quote-menu-item {
    color: ${(p) => p.$themeColor.primaryFontColor};
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.07rem;
    width: calc(100% - 1.6rem);
    border-radius: 0px 0.9rem;
    padding: 0.4rem 0.8rem;
    margin-bottom: 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: 0.2s;
    &:hover {
      background-color: ${(p) => p.$themeColor.rowHoverColor};
      transition: 0.4s;
      font-weight: 700;
    }
  }
`;
