import { shallow } from "zustand/shallow";
import { useDoxleGridLayoutStore } from "../Store/useDoxleGridLayoutStore";
import { useCallback, useEffect, useLayoutEffect, useMemo } from "react";
import { useResizeDetector } from "react-resize-detector";
import { OnRefChangeType } from "react-resize-detector/build/types/types";

interface ResizeLayout {}
export const useResizeLayout = (props: {
  rootContainerRef: React.RefObject<HTMLDivElement>;
}): ResizeLayout => {
  const { setRootContainerSize, rootContainerSize, clearPageData } =
    useDoxleGridLayoutStore((state) => ({
      setRootContainerSize: state.setRootContainerSize,
      rootContainerSize: state.rootContainerSize,
      clearPageData: state.clearPageData,
    }));
  const { width, height } = useResizeDetector({
    handleHeight: true,
    handleWidth: true,
    // refreshMode: "throttle",
    // refreshRate: 200,
    onResize: (width, height) => {
      if (width && height)
        setRootContainerSize({
          widthSize: width,
          heightSize: height,
        });
    },
    targetRef: props.rootContainerRef,
  });
  // useEffect(() => {

  // }, [width, height]);

  // useEffect(() => {
  //   console.log("rootContainerSize.widthSize:", rootContainerSize.widthSize);

  //   console.log("rootContainerSize.heightSize:", rootContainerSize.heightSize);
  // }, [rootContainerSize.widthSize, rootContainerSize.heightSize]);

  //   useEffect(() => {
  //     console.log("ROOT SIZE:", rootContainerSize);
  //   }, [rootContainerSize]);

  //* useEfect listen to width size of the root container to set max number of column
  const maxNumberOfColumn = useMemo(
    () =>
      rootContainerSize.widthSize
        ? rootContainerSize.widthSize >= 1280
          ? 5
          : rootContainerSize.widthSize >= 1024
          ? 4
          : rootContainerSize.widthSize >= 768
          ? 3
          : rootContainerSize.widthSize >= 300
          ? 2
          : 1
        : 0,
    [rootContainerSize.widthSize]
  );
  useEffect(() => {
    clearPageData();
    setRootContainerSize({
      numOfCol: maxNumberOfColumn,
      maxItemPerPage: maxNumberOfColumn * maxItemPerCol,
    });
  }, [maxNumberOfColumn]);

  //* useEffect listen to heightSize of the root container to set max item per column
  const maxItemPerCol = useMemo(
    () =>
      rootContainerSize.heightSize
        ? rootContainerSize.heightSize >= 1000
          ? 4
          : rootContainerSize.heightSize >= 800
          ? 3
          : rootContainerSize.heightSize >= 668
          ? 2
          : 1
        : 1,
    [rootContainerSize.heightSize]
  );
  useEffect(() => {
    clearPageData();
    setRootContainerSize({
      maxItemPerCol: maxItemPerCol,
      maxItemPerPage: maxNumberOfColumn * maxItemPerCol,
    });
  }, [maxItemPerCol]);

  return {};
};
