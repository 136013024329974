import { useEffect } from "react";

import { StyledInboxContainer } from "./StyledComponentsInbox";

import { shallow } from "zustand/shallow";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import EditDocketSideScreen from "../../CoreContent/EditDocketSideScreen/EditDocketSideScreen";
import { useInboxStore } from "../InboxStore/useInboxStore";
import { NoticeBoardContent } from "./NoticeBoardContent/NoticeBoardContent";
import {Helmet} from "react-helmet";

type Props = {};

const Inbox = (props: Props) => {
  const { doxleThemeColor } = useDoxleThemeStore((state) => ({
    doxleThemeColor: state.doxleThemeColor,
  }));
  const setFilterDocketListQuery = useInboxStore(
    (state) => state.setFilterDocketListQuery,
    shallow
  );
  useEffect(() => {
    localStorage.setItem("lastUsedApp", "Noticeboard");
    setFilterDocketListQuery({ project: undefined });
  }, []);

  return (
    <StyledInboxContainer $themeColor={doxleThemeColor}>
      <Helmet>
        <title>Doxle - Noticeboard</title>
      </Helmet>
      <NoticeBoardContent />

      <EditDocketSideScreen />
    </StyledInboxContainer>
  );
};

export default Inbox;
