import React, { useEffect, useMemo, useState } from "react";
import { NewProject, Project } from "../../../Models/project";
import { useDoxleCurrentContextStore } from "../../../DoxleGeneralStore/useDoxleCurrentContext";
import { shallow } from "zustand/shallow";
import { useNavigate } from "react-router-dom";
import { useDoxleAuthStore } from "../../../DoxleGeneralStore/useDoxleAuthStore";
import ProjectQueryAPI from "../../../Services/QueryHooks/projectQueryAPI";
import useDoxleNotificationStore from "../../../DoxleGeneralStore/useDoxleNotificationStore";
import { produce } from "immer";

type Props = {
  projectId?: string;
};

interface AddProjectScreen {
  setNewProject: React.Dispatch<React.SetStateAction<NewProject>>;
  newProject: NewProject;
  handleClickBackBtn: () => void;
  isDisabledCreate: boolean;
  handleAddProject: () => void;
  isAddingProject: boolean;
  isRetrievingProjectData: boolean;
  retrievedProjectData: Project | undefined;
  shouldShowError: boolean;
}
const useAddProjectScreen = ({ projectId }: Props): AddProjectScreen => {
  const [shouldShowError, setShouldShowError] = useState<boolean>(false);

  const [newProject, setNewProject] = useState<NewProject>({
    siteAddress: "",
    contractPrice: "",
    contractDate: "",
    startDate: "",
    endDate: "",

    company: "",

    projectStatus: "",

    ownerName: "",
    ownerAbn: "",
    ownerPhone: "",
    ownerEmail: "",

    contractName: "",
    projectType: "",

    isBudget: true,
    isNoticeboard: false,

    trackingId: null,
    trackingName: "",
  });
  const { currentCompany } = useDoxleCurrentContextStore(
    (state) => ({
      currentCompany: state.currentCompany,
    }),
    shallow
  );
  const { company, setCurrentProject } = useDoxleCurrentContextStore(
    (state) => ({
      company: state.currentCompany,
      setCurrentProject: state.setCurrentProject,
    }),
    shallow
  );
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );
  const navigate = useNavigate();

  const handleClickBackBtn = () => {
    navigate("/");
  };
  const onSuccessAddProject = (newProject?: Project) => {
    setTimeout(() => {
      if (newProject?.projectId) {
        navigate(`/Project/${newProject?.projectId}/`);
        setCurrentProject(newProject);
      } else navigate("/");
    }, 400);
  };

  const retrieveProjectQuery = ProjectQueryAPI.useRetrieveProjectDetailQuery({
    projectId,

    company,
    showNotification,
    enabled: Boolean(projectId),
  });
  const retrievedProjectData = useMemo(() => {
    if (retrieveProjectQuery.isSuccess && retrieveProjectQuery.data?.data)
      return retrieveProjectQuery.data?.data as Project;
    return undefined;
  }, [retrieveProjectQuery.isSuccess, retrieveProjectQuery.data?.data]);

  const updateProjectQuery = ProjectQueryAPI.useUpdateProjectQuery({
    company,
    showNotification,
    filter: {},
    onSuccessUpdateCb: onSuccessAddProject,
  });
  const addProjectQuery = ProjectQueryAPI.useAddProjectQuery({
    company,
    showNotification,
    filter: {},
    onSuccessCb: onSuccessAddProject,
  });
  const isDisabledCreate = Boolean(
    !newProject.ownerName ||
      !newProject.ownerPhone ||
      !newProject.ownerEmail ||
      !newProject.siteAddress ||
      !isValidPhoneNumber(newProject.ownerPhone) ||
      !validateEmail(newProject.ownerEmail)
  );
  const handleAddProject = () => {
    let finalProject: Omit<NewProject, "projectStatusJson"> = {
      siteAddress: newProject.siteAddress,
    };

    Object.keys(newProject).forEach((key) => {
      const itemKey = key as keyof NewProject;
      if (itemKey !== "siteAddress" && itemKey !== "projectStatusJson") {
        if (newProject[itemKey])
          finalProject[itemKey] = newProject[itemKey] as any;
      }
    });
    projectId
      ? updateProjectQuery.mutate({ projectId, updateData: finalProject })
      : isDisabledCreate
      ? setShouldShowError(true)
      : addProjectQuery.mutate(finalProject);
  };

  useEffect(() => {
    console.log(retrieveProjectQuery.data?.data);
    if (retrieveProjectQuery.isSuccess && retrieveProjectQuery.data.data)
      setNewProject({
        siteAddress: retrieveProjectQuery.data?.data?.siteAddress ?? "",
        contractPrice: retrieveProjectQuery.data?.data?.contractPrice ?? "",
        contractDate: retrieveProjectQuery.data?.data?.contractDate ?? "",
        projectBudget: retrieveProjectQuery.data?.data?.projectBudget ?? "",
        startDate: retrieveProjectQuery.data?.data?.startDate ?? "",
        endDate: retrieveProjectQuery.data?.data?.endDate ?? "",

        company: retrieveProjectQuery.data?.data?.companyId ?? "",

        projectStatus: retrieveProjectQuery.data?.data?.projectStatus ?? "",

        ownerName: retrieveProjectQuery.data?.data?.ownerName ?? "",
        ownerAbn: retrieveProjectQuery.data?.data?.ownerAbn ?? "",
        ownerPhone: retrieveProjectQuery.data?.data?.ownerPhone ?? "",
        ownerEmail: retrieveProjectQuery.data?.data?.ownerEmail ?? "",

        isBudget: retrieveProjectQuery.data?.data?.isBudget !== undefined
          ? retrieveProjectQuery.data?.data?.isBudget
          : true,
        isNoticeboard: retrieveProjectQuery.data?.data?.isNoticeboard !== undefined
          ? retrieveProjectQuery.data?.data?.isNoticeboard
          : false,

        contractName: retrieveProjectQuery.data?.data?.contractName ?? "",
        projectType: retrieveProjectQuery.data?.data?.projectType ?? "",

        trackingId: retrieveProjectQuery.data?.data?.trackingId ?? null,
        trackingName: retrieveProjectQuery.data?.data?.trackingName ?? "",
      });
  }, [retrieveProjectQuery.data, retrieveProjectQuery.isSuccess]);

  useEffect(() => {
    setNewProject((prev) =>
      produce(prev, (draft) => {
        draft.company = currentCompany?.companyId ?? "";
        return draft;
      })
    );
  }, [currentCompany]);

  return {
    setNewProject,
    newProject,
    handleClickBackBtn,
    isDisabledCreate,
    handleAddProject,
    isAddingProject: addProjectQuery.isLoading,
    isRetrievingProjectData: retrieveProjectQuery.isLoading,
    retrievedProjectData,
    shouldShowError,
  };
};

export default useAddProjectScreen;

export function isNumeric(value: string) {
  return !isNaN(Number(value)) && !isNaN(parseFloat(value));
}
export const isValidPhoneNumber = (phoneNumber: string): boolean => {
  // Regular expression for a broad range of phone number formats
  const phoneRegex = /^[+]?[0-9\-() ]{6,20}$/;

  // Test the provided phone number against the regular expression
  return phoneRegex.test(phoneNumber);
};
export const validateEmail = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};
