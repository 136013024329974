import Typography from "@mui/material/Typography";
import { motion } from "framer-motion";
import styled from "styled-components";
import {
  DoxleFont,
  DoxleThemeColor,
} from "../../../DoxleGeneralStore/useDoxleThemeStore";
import TextField from "@mui/material/TextField";
import { AutocompleteProps } from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import { editRgbaAlpha } from "../../../Utilities/FunctionUtilities";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";

export const StyledReviewQuote = styled(motion.div)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;

  .button-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .rv-quote-title-text {
    color: ${(p) => p.$themeColor.primaryFontColor};
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-size: 2rem;
    font-style: normal;
    font-weight: 600;
    margin-bottom: 4rem;
    display: flex;
    align-items: center;
  }
`;

export const StyledQuoteReviewFieldWrapper = styled(motion.div)`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 4rem;
`;
export const StyledQuoteSupplierContainer = styled.div<{
  $marginTop?: number;
  $marginBottom?: number;
  $gap?: number;
}>`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${(p) => (p.$gap || 0) / 10}rem;
  margin-top: ${(p) => (p.$marginTop ?? 14) / 10}rem;
  margin-bottom: ${(p) => (p.$marginBottom ?? 14) / 10}rem;
  z-index: 4;
`;

export const StyledEditQuoteRequestLabelText = styled(Typography)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
  $fontSize?: `${number}px`;
  $paddingRight?: `${number}px`;
  $opacity?: number;
}>`
  &.MuiTypography-root {
    font-family: ${(p) => p.$doxleFont.primaryTitleFont};
    color: ${(p) => p.$themeColor.primaryFontColor};
    font-style: normal;
    font-weight: 500;
    font-size: ${(p) => p.$fontSize || "1rem"};
    line-height: normal;
    letter-spacing: 0.5px;
    white-space: nowrap;
    padding-right: ${(p) => p.$paddingRight || 0};
    opacity: ${(p) => p.$opacity || 1};
  }
`;
export const StyledSelectedSupplierContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  align-items: center;
  flex: 1;
`;
export const StyleQuoteRequestAutocompleteSx = (
  themeColor: DoxleThemeColor,
  doxleFont: DoxleFont
) => {
  return {
    maxWidth: "25rem",
    backgroundColor: themeColor.primaryContainerColor,
    borderRadius: "0px 0.9rem 0.9rem 1.2rem",
    border: `1px solid ${themeColor.rowBorderColor}`,
    padding: "0px 0.8rem",
    "& .MuiAutocomplete-inputRoot": {
      paddingRight: "0px !important",
    },

    height: "2.7rem",
    alignSelf: "flex-start",
  };
};

export const StyledSupplierSelectSection = styled(motion.div)`
  max-width: 80%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
export const StyledDatePickerCustomContainer = styled(motion.div)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  border-radius: 0.2rem;
  background-color: ${(p) => p.$themeColor.primaryContainerColor};
  color: ${(p) => p.$themeColor.primaryFontColor};
  cursor: pointer;
  min-width: 14rem;
  justify-content: center;
  font-size: 1.3rem;
  font-weight: 500;
  font-family: ${(p) => p.$doxleFont.primaryFont};
  align-items: center;
  flex-direction: row;
  display: flex;
  border: 1px solid ${(p) => p.$themeColor.rowBorderColor};
  padding: 0.2rem 1.2rem;
  height: 2.6rem;
  align-self: flex-start;
`;
export const StyledReviewQuoteDatePickerSection = styled(motion.div)`
  max-width: 80%;
  display: flex;
  flex-direction: row;
`;

export const StyledReviewQuoteEmailBodySection = styled(motion.div)`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 2.7rem;
`;
export const StyledRVQuoteEmailBodyTextField = styled(TextField)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  && {
    display: flex;
    background: ${(p) => p.$themeColor.primaryContainerColor};
    max-width: 86rem;
    width: 100%;
    border-radius: 0px 0.9rem 0.9rem 1.8rem;
  }
  & .MuiOutlinedInput-root {
    border-radius: 0px 0.9rem 0.9rem 1.8rem;
    border: 1px solid ${(p) => p.$themeColor.rowBorderColor};
    min-height: ${(p) => (p.multiline ? "8rem" : "4rem")};
    height: ${(p) => (p.multiline ? "15vh" : "4rem")};
    padding: 0.8rem;
    background: ${(p) => p.$themeColor.primaryContainerColor};
  }
  & .MuiOutlinedInput-notchedOutline {
    border: 0px !important;
  }
  & .MuiOutlinedInput-input {
    color: white;
  }
  & .MuiOutlinedInput-input {
    color: ${(p) => p.$themeColor.primaryFontColor};
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.07rem;
    padding: 0px !important;
    margin-left: 0.5rem;
    height: 100% !important;
    width: 100% !important;
  }
`;
export const StyledRVQuoteAttachmentWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;

  width: 100%;
  margin-top: 0.8rem;
  padding-bottom: 0.8rem;
  max-width: 86rem;
`;
export const StyledQuoteAttachmentItem = styled(motion.div)<{
  $themeColor: DoxleThemeColor;
}>`
  position: relative;

  border-radius: 1.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.2rem 0.4rem;
  background: ${(p) => p.$themeColor.primaryContainerColor};
  box-shadow: 0px 0px 4px 0px ${(p) => p.$themeColor.primaryBoxShadowColor};
  margin: 0.7rem;
  cursor: pointer;
  position: relative;
`;
export const StyledAddedAttachmentItem = styled(motion.div)`
  aspect-ratio: 1;
  border-radius: 0.4rem;
  position: relative;
  display: inline-block;
  justify-content: center;
  align-items: center;
  margin: 0.4rem;
  height: auto;
  width: 100px;
`;
export const StyledAttachmentName = styled.div`
  color: ${(p) => p.theme.color.primaryFontColor};
  width: 100px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 12px;
`;
export const StyledImageContainer = styled.div`
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  border: 1px solid ${(p) => p.theme.color.primaryDividerColor};
  border-radius: 4px;
`;
export const StyledFileImage = styled.img`
   object-fit: contain;
   max-width: 100%;
   max-height: 100%;
   width: auto;
   height: auto;
`;
export const StyledQuoteAttachmentsIconWrapper = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  padding: 0.2rem 0.4rem;
  max-width: 14.4rem;
  .img-wrapper {
    width: 3rem;
    min-width: 20px;
    aspect-ratio: 1;
    max-height: 2rem;
  }
`;
export const StyledSelectedScopeGroupContainer = styled.div<{
  $marginTop?: number;
  $marginBottom?: number;
  $gap?: number;
}>`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${(p) => (p.$gap || 0) / 10}rem;
  margin-top: ${(p) => (p.$marginTop || 14) / 10}rem;
  margin-bottom: ${(p) => (p.$marginBottom || 14) / 10}rem;
  z-index: 4;

  .scope-group-item-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    flex: 1;
    margin-left: 20px;
  }
`;
export const StyledQuoteSOWgroupAutocompleteWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  max-width: 266px;
  width: 100%;
  margin-top: 20px;
`;
export const StyledSelectTaskContainer = styled.div`
  background-color: ${(p) => p.theme.color.primaryReverseFontColor};
  color: ${(p) => p.theme.color.primaryFontColor};
  width: 240px;
  padding: 8px 10px;
  font-size: 1.4rem;
  border-radius: 8px;
  cursor: pointer;
`;
export const StyledReviewQuoteButton = styled(Button)<{
  $doxleFont: DoxleFont;
  $themeColor: DoxleThemeColor;
  $bgColor: string;
  $textColor: string;
}>`
  && {
    min-width: 7.2rem !important;
    height: 2.3rem;
    border: 1px solid ${(p) => p.$themeColor.rowBorderColor};
    background-color: ${(p) => p.$bgColor};
    color: ${(p) => p.$textColor};
    font-family: ${(p) => p.$doxleFont.primaryFont};
    letter-spacing: 0.06rem;
    display: flex;
    margin: 0.5rem !important;
    border-radius: 0.4rem !important;
    align-items: center;
    justify-content: center;

    padding: 0 0.5rem;
    cursor: pointer;
    border-radius: 0.4rem;
    font-size: 1.4rem;
    font-weight: 600;
    text-transform: capitalize;
    &:hover {
      background-color: ${(p) => p.$bgColor} !important;
      transform: scale(0.95);
      transition: 0.4s;
    }
  }
  &.MuiButton-root.Mui-disabled {
    background-color: ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.$themeColor.primaryFontColor,
        alpha: "0.4",
      })};
  }
`;
export const StyledQuoteStatusContainer = styled.div<{}>`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 4rem;

  .quote-status-wrapper {
    margin-left: 20px;
    display: flex;
    align-items: center;
  }
`;

export const StyledViewQSButton = styled(motion(Button))<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  && {
    min-width: 7.2rem !important;
    height: 2.3rem;
    border: 1px solid ${(p) => p.$themeColor.rowBorderColor};
    background-color: ${(p) => p.$themeColor.primaryContainerColor};
    color: ${(p) => p.$themeColor.primaryFontColor};

    font-family: ${(p) => p.$doxleFont.primaryFont};
    letter-spacing: 0.06rem;
    display: flex;
    margin-left: 1rem !important;
    border-radius: 0.4rem !important;
    align-items: center;
    justify-content: center;

    padding: 0 0.5rem;
    cursor: pointer;
    border-radius: 0.4rem;
    font-size: 1.4rem;
    font-weight: 500;
    text-transform: capitalize;
    box-shadow: 0px 0px 4px 0px ${(p) => p.$themeColor.primaryBoxShadowColor};
    &:hover {
      background-color: ${(p) =>
        p.$themeColor.primaryContainerColor} !important;
      opacity: 0.8;
      font-weight: 600;
      transition: 0.4s;
    }
  }
`;

export const StyledViewQSDialog = styled(Dialog)<{
  $themeColor: DoxleThemeColor;
}>`
  && {
    box-shadow: 1px 1px 2px ${(p) => p.$themeColor.primaryBoxShadowColor};
  }
  & .MuiDialog-paper {
    background-color: ${(p) => p.$themeColor.primaryBackdropColor};
    box-shadow: 0px 0px 14px 0px ${(p) => p.$themeColor.primaryBoxShadowColor};

    max-width: 900px !important;
    min-width: 500px !important;
  }
`;
export const StyledViewQSDialogTitle = styled(DialogTitle)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  && {
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-style: normal;
    font-weight: 600;
    font-size: 2.4rem;
    line-height: 2.6rem;

    color: ${(p) => p.$themeColor.primaryFontColor};
    border-bottom: 1px solid ${(p) => p.$themeColor.primaryDividerColor};
    background-color: ${(p) => p.$themeColor.primaryContainerColor};
  }
`;
export const StyledViewQSDialogContent = styled(DialogContent)<{
  $themeColor: DoxleThemeColor;
}>`
  && {
    background-color: ${(p) => p.$themeColor.primaryContainerColor};
    box-shadow: 1px 1px 2px ${(p) => p.$themeColor.primaryBoxShadowColor};

    word-break: break-word;
    width: 60rem;

    min-width: 400px;
  }
`;
export const StyledQSDetailFieldWrapper = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  width: 100%;
  margin: 1.4rem 0px;
  display: flex;

  .field-title {
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-style: normal;
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 1.6rem;

    color: ${(p) => p.$themeColor.primaryFontColor};
    width: 35%;
    max-width: 200px;
    min-width: 100px;
    text-transform: capitalize;
  }
  .field-content {
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-style: normal;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 1.6rem;

    color: ${(p) => p.$themeColor.primaryFontColor};
    flex: 1;
    display: flex;
    flex-wrap: wrap;
  }
`;
export const StyledQSLineSection = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>``;

export const StyledQSAttachmentSection = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  width: 100%;
  display: flex;

  .field-title {
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-style: normal;
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 1.6rem;

    color: ${(p) => p.$themeColor.primaryFontColor};
    width: 35%;
    max-width: 200px;
    min-width: 100px;
    text-transform: capitalize;
  }

  .attachment-wrapper {
    flex: 1;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

export const StyledQSAttachmentItem = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  border-radius: 0.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.4rem 0.8rem;
  background: ${(p) => p.$themeColor.primaryContainerColor};
  box-shadow: 0px 0px 4px 0px ${(p) => p.$themeColor.primaryBoxShadowColor};
  margin: 0rem 0.7rem 0.7rem 0px;
  cursor: pointer;
  position: relative;

  .file-text {
    font-family: ${(p) => p.$doxleFont.primaryTitleFont};
    color: ${(p) => p.$themeColor.primaryFontColor};
    font-style: normal;
    font-weight: 500;
    font-size: 1.2rem;
    line-height: normal;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 14rem;
  }
  .img-wrapper {
    width: 3rem;
    min-width: 20px;
    aspect-ratio: 1;
    max-height: 2rem;
    margin-right: 0.8rem;
  }
`;
export const StyledQSLinesSection = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  width: 100%;
  display: flex;
  flex-direction: column;

  .field-title {
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-style: normal;
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 1.6rem;

    color: ${(p) => p.$themeColor.primaryFontColor};
    width: 35%;
    max-width: 200px;
    min-width: 100px;
    text-transform: capitalize;
  }

  .line-wrapper {
    margin-top: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: ${(p) => p.$doxleFont.primaryTitleFont};
    color: ${(p) => p.$themeColor.primaryFontColor};
    font-style: normal;
    font-weight: 500;
    font-size: 1.4rem;
    line-height: normal;
  }
`;

export const StyledQSLineItem = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 0.4rem 0px;
  border-bottom: 1px solid ${(p) => p.$themeColor.primaryDividerColor};
  .description {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    font-family: ${(p) => p.$doxleFont.primaryTitleFont};
    color: ${(p) => p.$themeColor.primaryFontColor};
    font-style: normal;
    font-weight: 500;
    font-size: 1.2rem;
    line-height: normal;
  }
  .currency-line {
    font-family: ${(p) => p.$doxleFont.primaryTitleFont};
    color: ${(p) => p.$themeColor.primaryFontColor};
    font-style: normal;
    font-weight: 500;
    font-size: 1.2rem;
    line-height: normal;
    width: 10rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: center;
  }
`;

export const StyledQuoteLineHeaderSection = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 2px;

  span {
    color: ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.$themeColor.primaryFontColor,
        alpha: "0.7",
      })};
    font-family: ${(p) => p.$doxleFont.primaryTitleFont};
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
    text-align: center;
  }
`;

export const StyledQSTotalSection = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  margin-top: 3rem;

  .total-field-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 8px;
    width: 40%;
    max-width: 300px;

    h4 {
      font-family: ${(p) => p.$doxleFont.primaryFont};
      font-size: 1.2rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-transform: capitalize;
      text-align: right;
      width: 14rem;
      margin: 0px !important;
    }
    p {
      font-family: ${(p) => p.$doxleFont.primaryFont};
      font-size: 1.2rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      text-align: left;
      flex: 1;
      margin: 0px 0px 0px 10px;
    }
  }
`;
