import React from "react";
import { useProjectQAStore } from "../../../Store/useProjectQAStore";
import { shallow } from "zustand/shallow";
import useDoxleNotificationStore from "../../../../../DoxleGeneralStore/useDoxleNotificationStore";
import { useDoxleAuthStore } from "../../../../../DoxleGeneralStore/useDoxleAuthStore";
import { useDoxleCurrentContextStore } from "../../../../../DoxleGeneralStore/useDoxleCurrentContext";
import QAQueryAPI from "../../../../QueryAPI/qaQueryAPI";
import { QAWithFirstImg } from "../../../../Models/qa";
import dayjs from "dayjs";
import { formatTISODate } from "../../../../../Utilities/FunctionUtilities";

type Props = {};

interface EditQaDueDate {
  handleUpdateDueDate: (value: dayjs.Dayjs | null) => void;
  isUpdatingDueDate: boolean;
}
const useEditQaDueDate = (props: Props): EditQaDueDate => {
  const { selectedQAItem, updateSelectedQAItem } = useProjectQAStore(
    (state) => ({
      selectedQAItem: state.selectedQAItem,
      updateSelectedQAItem: state.updateSelectedQAItem,
    }),
    shallow
  );
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );
  const { company } = useDoxleCurrentContextStore(
    (state) => ({
      company: state.currentCompany,
    }),
    shallow
  );
  const onUpdateSuccess = (newQA?: QAWithFirstImg) => {
    if (newQA)
      updateSelectedQAItem({
        dueDate: newQA.dueDate,
      });
  };
  const updateQAQuery = QAQueryAPI.useUpdateQAQuery({
    company,
    qaItem: selectedQAItem!,
    showNotification,
    onSuccessCB: onUpdateSuccess,
  });

  const handleUpdateDueDate = (value: dayjs.Dayjs | null) => {
    updateQAQuery.mutate({
      dueDate: value ? formatTISODate(value.toDate()) : null,
    });
  };
  return {
    handleUpdateDueDate,
    isUpdatingDueDate: updateQAQuery.isLoading,
  };
};

export default useEditQaDueDate;
