import styled from "styled-components";
import {
  InputAdornment,
  Paper,
  PaperProps,
  TableCell,
  TextField,
} from "@mui/material";
import {
  DoxleFont,
  DoxleThemeColor,
} from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { motion } from "framer-motion";
import { editRgbaAlpha } from "../../../Utilities/FunctionUtilities";

export const AddQuoteRequestContainer = styled(motion.div)<{}>`
  flex: 1;

  justify-content: flex-start;
  flex-direction: column;
  height: 100%;
  align-items: flex-start;
`;

export const StyledAddedAttachmentItem = styled(motion.div)`
  aspect-ratio: 1;
  border-radius: 0.4rem;
  position: relative;
  display: inline-block;
  justify-content: center;
  align-items: center;
  margin: 0.4rem;
  height: auto;
  width: 100px;
`;
export const StyledAttachmentName = styled.div`
  color: ${(p) => p.theme.color.primaryFontColor};
  width: 100px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 12px;
`;
export const StyledImageContainer = styled.div`
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  border: 1px solid ${(p) => p.theme.color.primaryDividerColor};
  border-radius: 4px;
`;
export const StyledFileImage = styled.img`
   object-fit: contain;
   max-width: 100%;
   max-height: 100%;
   width: auto;
   height: auto;
`;

export const QuoteRequestAutocompleteSx = (
  themeColor: DoxleThemeColor,
  doxleFont: DoxleFont
) => {
  return {
    display: "flex",
    background: themeColor.primaryContainerColor,
    "&.MuiOutlinedInput-root": {
      borderRadius: 0,
      height: "3.6rem",
      border: "none",
    },
    "&.MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "&.MuiOutlinedInput-input": {
      color: "white",
      border: "none",
    },
    "&.MuiInputBase-root": {
      border: 0,
      borderRadius: 0,
    },
    ".MuiAutocomplete-inputRoot": {
      borderRadius: "0px 0.9rem 0.9rem 1.2rem",
      height: 36,
      border: `1px solid ${themeColor.rowBorderColor}`,
      padding: "0px 0.3rem",
      color: themeColor.primaryFontColor,
      fontFamily: doxleFont.primaryFont,
      fontSize: "1.4rem",
      fontWeight: 500,
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "&.MuiAutocomplete-input": {
      padding: "0px !important",
      fontFamily: doxleFont.secondaryFont,
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "1.6rem",
      lineHeight: "1.9rem",

      marginLeft: "0.5rem",
      border: "0px !important",
    },
  };
};

export const QuoteRequestTextField = styled(TextField)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  && {
    display: flex;
    background: ${(p) => p.$themeColor.primaryContainerColor};
    max-width: 86rem;
    width: 100%;
    border-radius: 0px 0.9rem 0.9rem 1.8rem;
  }
  & .MuiOutlinedInput-root {
    border-radius: 0px 0.9rem 0.9rem 1.8rem;
    border: 1px solid ${(p) => p.$themeColor.rowBorderColor};
    min-height: ${(p) => (p.multiline ? "8rem" : "4rem")};
    height: ${(p) => (p.multiline ? "15vh" : "4rem")};
    padding: 0.8rem;
    background: ${(p) => p.$themeColor.primaryContainerColor};
  }
  & .MuiOutlinedInput-notchedOutline {
    border: 0px !important;
  }
  & .MuiOutlinedInput-input {
    color: white;
  }
  & .MuiOutlinedInput-input {
    color: ${(p) => p.$themeColor.primaryFontColor};
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.07rem;
    padding: 0px !important;
    margin-left: 0.5rem;
    height: 100% !important;
    width: 100% !important;
  }
`;

export const StyledErrorText = styled.div`
  display: flex;
  justify-content: center;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  margin-top: 200px;
`;
export const StyledOrderLineTextField = styled(TextField)<{
  themeColor: DoxleThemeColor;
  textAlign: "left" | "center" | "right";
  widthInPixel: `${number}px` | null;
}>`
  && {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    height: 100%;
    ${(p) => (p.widthInPixel ? `width:${p.widthInPixel};` : `flex:1;`)}
    display: flex;
    justify-content: center;
  }
  input {
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.05em;
    color: ${(p) => p.themeColor.primaryFontColor};
    padding: 0 !important;
    text-align: ${(p) => p.textAlign};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  & .MuiOutlinedInput-notchedOutline {
    border: none !important;
    padding: 0 !important;
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;
export const StyledOrderLineTextFieldAdornment = styled(InputAdornment)<{
  themeColor: DoxleThemeColor;
}>`
  && {
    cursor: pointer;
    text-transform: capitalize !important;
  }
  & .MuiTypography-root {
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.05em;
    color: ${(p) => p.themeColor.primaryFontColor};

    text-align: center;
    padding: 0 !important;
    text-transform: lowercase;
  }

  &.MuiInputAdornment-root {
    margin: 1px !important;
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.05em;
    color: ${(p) => p.themeColor.primaryFontColor};
    text-align: center;
    padding: 0 !important;
  }
`;

export const StyledPurchaseOrderTableCell = styled(TableCell)<{
  widthInPixel: `${number}px` | null;
  horizontalAlign: "flex-start" | "center" | "flex-end";
  leftpaddingNum: `${number}px`;
  hasBorderRight: boolean;
  themeColor: DoxleThemeColor;
}>`
  && {
    ${(p) => (p.widthInPixel ? `width:${p.widthInPixel};` : "flex:1;")}
    padding: 0px 0px 0px ${(p) => p.leftpaddingNum};
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.05em;
    color: ${(p) => p.themeColor.primaryFontColor};
    border: none;
    border-right: ${(p) => (p.hasBorderRight ? 1 : 0)}px solid
      ${(p) => p.themeColor.primaryDividerColor};
    height: 100%;
    display: flex;
    justify-content: ${(p) => p.horizontalAlign};
    align-items: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const CloseIconDiv = styled.div<{ themeColor: DoxleThemeColor }>`
  position: absolute;
  width: 11px;
  height: 11px;
  background: ${(p) => p.themeColor.primaryFontColor};
  top: -2px;
  right: -2px;
  border-radius: 100%;
`;
export const StyledDatePickerCustomContainer = styled(motion.div)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  border-radius: 0.2rem;
  background-color: ${(p) => p.$themeColor.primaryContainerColor};
  color: ${(p) => p.$themeColor.primaryFontColor};
  cursor: pointer;
  min-width: 14rem;
  justify-content: center;
  font-size: 1.3rem;
  font-weight: 500;
  font-family: ${(p) => p.$doxleFont.primaryFont};
  align-items: center;
  flex-direction: row;
  display: flex;
  border: 1px solid ${(p) => p.$themeColor.rowBorderColor};
  padding: 0.2rem 1.2rem;
  height: 2.6rem;
`;
