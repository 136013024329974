import { useCallback, useEffect, useMemo, useState } from "react";
import { shallow } from "zustand/shallow";
import { useEditDocketSideScreenStore } from "../../../CoreContent/EditDocketSideScreen/EditDocketSideScreenStore/useEditDocketSideScreenStore";

import { useIsMutating, useQueryClient } from "@tanstack/react-query";

import { useBudgetStore } from "../../Store/useBudgetStore";
import useSetDocketListQueryData from "../../../CoreContent/QueryDataHooks/SetQueryDataHooks/useSetDocketListQueryData";
import {
  UpdateDocketParams,
  getDocketMutationKey,
} from "../../../Services/QueryHooks/docketQueryAPI";
import { getProjectProfitAndLossQKey } from "../../QueryHooks/BudgetAPI";
import { LightDocket } from "../../../Models/dockets";
import { useShallow } from "zustand/react/shallow";
import {
  areColorsSimilar,
  extractRGBAValue,
} from "../../../Utilities/FunctionUtilities";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";

type Props = { docket: LightDocket };

const useBudgetTableRow = ({ docket }: Props) => {
  const { filterDocketListQuery } = useBudgetStore(
    useShallow((state) => ({
      filterDocketListQuery: state.filterDocketListQuery,
    }))
  );
  const { doxleThemeColor } = useDoxleThemeStore(useShallow((state) => state));
  const {
    setInitialEditedDocket,
    setFilterDocketListQuery,
    setHandlePostUpdateDocket,
    setSelectedFunctionMenu,
  } = useEditDocketSideScreenStore(
    useShallow((state) => ({
      setInitialEditedDocket: state.setInitialEditedDocket,
      setFilterDocketListQuery: state.setFilterDocketListQuery,
      setHandlePostUpdateDocket: state.setHandlePostUpdateDocket,

      setSelectedFunctionMenu: state.setSelectedFunctionMenu,
    }))
  );
  const { handleUpdateDocket } = useSetDocketListQueryData({
    filter: filterDocketListQuery,
  });
  const handleClickRow = useCallback(
    (navTab?: "order" | "comment" | "budget" | "payment") => {
      // console.log("CLICK ROW");
      setFilterDocketListQuery(filterDocketListQuery);
      setHandlePostUpdateDocket(handleUpdateDocket);
      setInitialEditedDocket(docket);

      if (navTab === "budget") setSelectedFunctionMenu("Budget");
      else if (navTab === "order") setSelectedFunctionMenu("Orders");
      else if (navTab === "comment") setSelectedFunctionMenu("Comments");
      else if (navTab === "payment") setSelectedFunctionMenu("Payments");
      if (docket.isNew) handleUpdateDocket({ ...docket, isNew: false });
    },
    [filterDocketListQuery, docket]
  );

  const queryClient = useQueryClient();
  const isFetchingXeroData = Boolean(
    queryClient.isFetching(
      getProjectProfitAndLossQKey(filterDocketListQuery.project, false)
    ) +
      queryClient.isFetching(
        getProjectProfitAndLossQKey(filterDocketListQuery.project, true)
      ) >
      0
  );
  const updateMutationKey = getDocketMutationKey("update");
  const isUpdatingBudget =
    useIsMutating({
      mutationKey: updateMutationKey,
      exact: true,
      predicate: (query) =>
        Boolean(
          (query.state.variables as UpdateDocketParams).docketPk ===
            docket.docketPk
        ),
    }) > 0;

  const deleteMutationKey = getDocketMutationKey("delete");
  const isDeletingBudget =
    useIsMutating({
      mutationKey: deleteMutationKey,
      exact: true,
      predicate: (query) => Boolean(query.state.variables === docket.docketPk),
    }) > 0;

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (docket.isNew) handleClickRow();
    }, 300);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  //compare status value matching with the background color
  const convertedStatusColor = useMemo(
    () =>
      areColorsSimilar(
        docket.statusColor,
        doxleThemeColor.primaryContainerColor
      )
        ? doxleThemeColor.primaryFontColor
        : docket.statusColor,
    [doxleThemeColor.primaryContainerColor, docket.statusColor]
  );
  return {
    handleClickRow,

    isUpdatingBudget,
    isDeletingBudget,
    isFetchingXeroData,
    convertedStatusColor,
  };
};

export default useBudgetTableRow;
