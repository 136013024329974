import { BaseAPIProps } from "../../Models/baseAPIProps";
import {
  QueryClient,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import DoxleAPI from "../../Services/DoxleAPI";
import Order from "../Models/order";
import PaymentClaim from "../Models/PaymentClaim";
import {
  AxiosBackendErrorReturn,
  IApiPaginatedData,
} from "../../Models/axiosReturn";
import DocketQuery from "../../Services/QueryHooks/docketQueryAPI";
import { isAxiosError } from "axios";
import { getProjectProfitAndLossQKey } from "../../Budgets/QueryHooks/BudgetAPI";

interface PaymentClaimQueryProps extends BaseAPIProps {
  filter: object;
}
const useRetrieveList = ({
  company,
  showNotification,
  filter,
}: PaymentClaimQueryProps) => {
  const qKey = ["payments-list"];
  let getUrl = `/payments/?page=1`;
  for (const [key, value] of Object.entries(filter)) {
    qKey.push(value);
    getUrl += `&${key}=${value}`;
  }
  return useInfiniteQuery(
    qKey,
    ({ pageParam = getUrl }) =>
      DoxleAPI.get<IApiPaginatedData<PaymentClaim>>(pageParam, {
        headers: {
          "User-Company": company!.companyId,
        },
      }),
    {
      enabled: Boolean(company),
      retry: 1,
      refetchInterval: 2 * 60 * 1000,
      refetchOnMount: false,
      staleTime: 1 * 60 * 1000,
      cacheTime: 2 * 60 * 1000,
      refetchIntervalInBackground: true,
      refetchOnWindowFocus: false,
      getNextPageParam: (prevData) => prevData.data?.next,
      onError: (error) => {
        if (showNotification) {
          if (isAxiosError<AxiosBackendErrorReturn>(error)) {
            showNotification(
              `${error?.response?.status ?? "ERROR"}: ${
                error.response?.data.detail ?? "UNKNOWN ERROR"
              }`,
              "error",
              String(
                error?.response?.data?.detail ?? "Fail To get payment list"
              ).substring(0, 300)
            );
          } else {
            showNotification(
              "Something Wrong!",
              "error",
              "Fail To get payment list"
            );
          }
        }
      },
    }
  );
};

interface MutatePaymentQueryProps extends PaymentClaimQueryProps {
  onSuccessCb?: Function;
}

interface NewPayment {
  order: string;
  amount: number;
  invoiceNumber: string;
  files: File[];
  status?: 'SUBMITTED'|'AUTHORISED';
}

const useMutateQuery = ({
  company,
  showNotification,
  filter,
  onSuccessCb,
}: MutatePaymentQueryProps) => {
  const qKey = ["payments-list"];
  const paymentsUrl = `/payments/`;
  const queryClient = useQueryClient();
  const updateDocket = DocketQuery.useUpdateDocketValues();
  for (const [key, value] of Object.entries(filter)) {
    qKey.push(value);
  }

  const addPaymentClaim = useMutation({
    mutationKey: ["add-payment"],
    mutationFn: async (paymentData: NewPayment) => {
      const formData = new FormData();
      formData.append("order", paymentData.order);
      formData.append("total", String(paymentData.amount));
      formData.append("invoiceNumber", paymentData.invoiceNumber);
      if (paymentData.status)formData.append("status", paymentData.status);
      paymentData.files.forEach((file) => formData.append("files", file));
      return DoxleAPI.post(paymentsUrl, formData, {
        headers: {
          "User-Company": company?.companyId!,
        },
      });
    },
    onSuccess: (result, variables, context) => {
      queryClient.setQueryData(qKey, (oldData: any) => {
        return oldData
          ? {
              ...oldData,
              pages: oldData.pages.map((page: any, index: number) => ({
                ...page,
                data: {
                  ...page.data,
                  results: [result.data.order, ...page.data.results],
                },
              })),
            }
          : oldData;
      });
      if (onSuccessCb) onSuccessCb(result?.data?.order);
    },
    onError: (error: any, variables, context) => {
      if (showNotification) {
        if (isAxiosError<AxiosBackendErrorReturn>(error)) {
          showNotification(
            `${error?.response?.status ?? "ERROR"}: ${
              error.response?.data.detail ?? "UNKNOWN ERROR"
            }`,
            "error",
            String(
              error?.response?.data?.detail ?? "Fail To add payment"
            ).substring(0, 300)
          );
        } else {
          showNotification("Something Wrong!", "error", "Fail To add payment");
        }
      }
    },

    onSettled: (result, variables, context) => {
      if (result) {
        const plKey = getProjectProfitAndLossQKey(result.data.project);
        queryClient
          .refetchQueries({
            type: "all",
            predicate: (query) =>
              plKey.every((item) => query.queryKey.includes(item)),
          })
          .then(() => {
            if (result.data?.docket) updateDocket.mutate(result?.data?.docket);
          });
      }
    },
  });

  const updatePaymentClaim = useMutation({
    mutationFn: async (paymentData: Partial<PaymentClaim>) => {
      return DoxleAPI.patch(
        paymentsUrl + paymentData?.paymentId + "/",
        paymentData,
        {
          headers: {
            "User-Company": company?.companyId!,
          },
        }
      );
    },
    onSuccess: (result, variables, context) => {
      queryClient.setQueryData(qKey, (oldData: any) => {
        return oldData
          ? {
              ...oldData,
              pages: oldData.pages.map((page: any, index: number) => ({
                ...page,
                data: {
                  ...page.data,
                  results: [result.data.order, ...page.data.results],
                },
              })),
            }
          : oldData;
      });
      queryClient.setQueryData(
        ["payment-detail", variables?.paymentId ?? ""],
        (oldData: any) => {
          return oldData
            ? {
                ...oldData,
                data: {
                  ...oldData.data,
                  paymentClaim: result.data,
                },
              }
            : oldData;
        }
      );

      if (onSuccessCb) onSuccessCb(result?.data?.order);
    },
    onError: (error: any, variables, context) => {
      if (showNotification) {
        if (isAxiosError<AxiosBackendErrorReturn>(error)) {
          showNotification(
            `${error?.response?.status ?? "ERROR"}: ${
              error.response?.data.detail ?? "UNKNOWN ERROR"
            }`,
            "error",
            String(
              error?.response?.data?.detail ?? "Fail To update payment"
            ).substring(0, 300)
          );
        } else {
          showNotification(
            "Something Wrong!",
            "error",
            "Fail To update payment"
          );
        }
      }
    },
    onSettled: (result, variables, context) => {
      if (result) {
        const plKey = getProjectProfitAndLossQKey(result.data.project);
        queryClient
          .refetchQueries({
            type: "all",
            predicate: (query) =>
              plKey.every((item) => query.queryKey.includes(item)),
          })
          .then(() => {
            if (result?.data?.docket) updateDocket.mutate(result?.data?.docket);
          });
      }
    },
  });

  return {
    addPaymentClaim,
    updatePaymentClaim,
  };
};
interface RetrievePaymentDetail extends BaseAPIProps {
  paymentId: string;
}

export interface PaymentDetailData {
  paymentClaim: PaymentClaim;
  order: Order | null;
  uniqueInvoiceNumber: boolean;
}
const useRetrieveDetail = ({
  company,

  paymentId,
  showNotification,
}: RetrievePaymentDetail) => {
  const qKey = ["payment-detail", paymentId ?? ""];
  let getURL = `/payments/${paymentId}/`;
  // const queryClient = useQueryClient();
  return useQuery(
    qKey,
    () =>
      DoxleAPI.get<PaymentDetailData>(getURL, {
        headers: {
          "User-Company": company!.companyId,
        },
      }),
    {
      enabled: Boolean(company && paymentId),
      retry: 1,
      refetchInterval: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      retryOnMount: false,
      onError: (error) => {
        if (showNotification) {
          if (isAxiosError<AxiosBackendErrorReturn>(error)) {
            showNotification(
              `${error?.response?.status ?? "ERROR"}: ${
                error.response?.data.detail ?? "UNKNOWN ERROR"
              }`,
              "error",
              String(
                error?.response?.data?.detail ?? "Fail To get payment details"
              ).substring(0, 300)
            );
          } else {
            showNotification(
              "Something Wrong!",
              "error",
              "Fail To get payment details"
            );
          }
        }
      },
      onSuccess: (response) => {},
    }
  );
};

//# helper function

const PaymentClaimAPI = {
  useRetrieveList,
  useRetrieveDetail,
  useMutateQuery,
};

export default PaymentClaimAPI;
