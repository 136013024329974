import React, { useState } from "react";
import {
  useDoxleThemeStore,
} from "../../../../DoxleGeneralStore/useDoxleThemeStore";
import {  ContinueBtn, DetailsFormContainer, MainContainer, StyledDetailTitle,  StyledFormArea, StyledInput, StyledWarning } from "./StyledFirstLogin";
import { TUserFormData, useWelcomeContext } from "../../pages/Welcome";
import { useNavigate } from "react-router-dom";

const NAME_TRIGGER_ID = 0;
const MOBILE_TRIGGER_ID = 1;
const EMAIL_TRIGGER_ID = 2;
const COMPANY_TRIGGER_ID = 3;

const emailRegex = new RegExp(
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);

const mobileRegex = new RegExp(
  /^(?:\+?(61))? ?(?:\((?=.*\)))?(0?[2-57-8])\)? ?(\d\d(?:[- ](?=\d{3})|(?!\d\d[- ]?\d[- ]))\d\d[- ]?\d[- ]?\d{3})$/
);

export default function DetailsFormPage() {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore((state) => ({
    doxleThemeColor: state.doxleThemeColor,
    doxleFont: state.doxleFont,
  }));

  const { stage, setStage, userFormData, setUserFormData } =
    useWelcomeContext();

  const [name, setName] = useState(userFormData.name);
  const [mobile, setMobile] = useState(userFormData.mobile);
  const [email, setEmail] = useState(userFormData.email);
  const [company, setCompany] = useState(userFormData.company);
  const [comment, setComment] = useState(userFormData.comment);
  const [prompt, setPrompt] = useState<string[]>(["", "", "", ""]);
  const navigate = useNavigate();

  const handleSubmit = () => {
    let valid = true;
    let newPrompt = [...prompt];

    setUserFormData({
      role: userFormData.role,
      name: name,
      mobile: mobile,
      email: email,
      company: company,
      comment: comment,
    });

    if (name === "") {
      newPrompt[NAME_TRIGGER_ID] = "Required";
      valid = false;
    } else {
      newPrompt[NAME_TRIGGER_ID] = "";
    }

    if (!mobileRegex.test(mobile)) {
      if (mobile === "") {
        newPrompt[MOBILE_TRIGGER_ID] = "Required";
      } else {
        newPrompt[MOBILE_TRIGGER_ID] = "Invalid Mobile";
      }
      valid = false;
    } else {
      newPrompt[MOBILE_TRIGGER_ID] = "";
    }

    if (!emailRegex.test(email)) {
      if (email === "") {
        newPrompt[EMAIL_TRIGGER_ID] = "Required";
      } else {
        newPrompt[EMAIL_TRIGGER_ID] = "Invalid Email";
      }
      valid = false;
    } else {
      newPrompt[EMAIL_TRIGGER_ID] = "";
    }

    if (company === "") {
      newPrompt[COMPANY_TRIGGER_ID] = "Required";
      valid = false;
    } else {
      newPrompt[COMPANY_TRIGGER_ID] = "";
    }

    setPrompt(newPrompt);


    if (valid) {
      saveUserData();
      //navigate("/Projects/");
    }
  };

  const goBackPage = () => {
    saveUserData();
    setStage(stage - 1);
  }

  const saveUserData = () => {
    const userData : TUserFormData = 
    { role: userFormData.role?userFormData.role:"",
      name: name,
      mobile: mobile,
      email: email,
      company: company,
      comment: comment
    };
    setUserFormData(userData);
  }

  return (
    <MainContainer>
        <DetailsFormContainer $themeColor={doxleThemeColor}>
        <StyledDetailTitle $doxleFont={doxleFont} $themeColor={doxleThemeColor}>Your Details</StyledDetailTitle>
          <StyledFormArea>
            <StyledWarning $doxleFont={doxleFont}>
              {prompt[NAME_TRIGGER_ID]}
            </StyledWarning>
            <StyledInput
              $themeColor={doxleThemeColor}
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Name *"
            />

            <StyledWarning $doxleFont={doxleFont}>
              {prompt[MOBILE_TRIGGER_ID]}
            </StyledWarning>
            <StyledInput
              $themeColor={doxleThemeColor}
              type="text"
              value={mobile}
              onChange={(e) => setMobile(e.target.value)}
              placeholder="Mobile *"
            />

            <StyledWarning $doxleFont={doxleFont}>
              {prompt[EMAIL_TRIGGER_ID]}
            </StyledWarning>
            <StyledInput
              $themeColor={doxleThemeColor}
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email *"
            />

            <StyledWarning $doxleFont={doxleFont}>
              {prompt[COMPANY_TRIGGER_ID]}
            </StyledWarning>
            <StyledInput
              $themeColor={doxleThemeColor}
              type="text"
              value={company}
              onChange={(e) => setCompany(e.target.value)}
              placeholder="Company *"
            />

            <StyledInput
              $themeColor={doxleThemeColor}
              type="text"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              placeholder="How did you find us?"
            />
            <ContinueBtn $themeColor={doxleThemeColor} onClick={goBackPage}>
              Back
            </ContinueBtn>
            <ContinueBtn $themeColor={doxleThemeColor} onClick={handleSubmit}>
              Continue
            </ContinueBtn>
          </StyledFormArea>
        </DetailsFormContainer>
    </MainContainer>
  );
}

