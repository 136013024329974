import { useCallback } from "react";
import ScopeOfWorkAPI, {
  AddMultiScopeImageData,
} from "../../../QueryAPI/scopeOfWorkQueryAPI";
import { useDoxleCurrentContextStore } from "../../../../DoxleGeneralStore/useDoxleCurrentContext";
import useDoxleNotificationStore from "../../../../DoxleGeneralStore/useDoxleNotificationStore";
import { DropEvent, FileRejection } from "react-dropzone";
import useSetSOWItemQueryData from "../../../Hooks/useSetSOWItemQueryData";
import { ScopeOfWorkItem } from "../../../Model/scopeOfWorks";
import { useScopeOfWorkStore } from "../../../Store/useScopeOfWorkStore";
import { shallow } from "zustand/shallow";
import { useShallow } from "zustand/react/shallow";

type Props = {
  scopeItem: ScopeOfWorkItem;
};

interface AddScopeItemImageSection {
  onDrop: <T extends File>(
    acceptedFiles: T[],
    fileRejections: FileRejection[],
    event: DropEvent
  ) => void;
}
const useAddScopeItemImageSection = ({
  scopeItem,
}: Props): AddScopeItemImageSection => {
  const { company } = useDoxleCurrentContextStore((state) => ({
    company: state.currentCompany,
  }));
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification
  );
  const { addErrorDroppedFiles } = useScopeOfWorkStore(
    useShallow((state) => ({
      addErrorDroppedFiles: state.addErrorDroppedFiles,
    }))
  );

  const addScopeImageQuery = ScopeOfWorkAPI.useAddMultiScopeImageQuery({
    showNotification,
    company,
    scopeItemId: scopeItem.scopeItemId,
    onSuccessCb: (data) => {
      if (data) {
        handleUpdateSOWItemImgCount(
          scopeItem.scopeItemId,
          data.scopeImages.length
        );
      }
    },
  });
  //# Dropzone
  const onDrop = useCallback(
    <T extends File>(
      acceptedFiles: T[],
      fileRejections: FileRejection[],
      event: DropEvent
    ) => {
      const addedItems: AddMultiScopeImageData[] = acceptedFiles.map(
        (file, idx) => {
          const filePath = URL.createObjectURL(file);
          return {
            imageId: "",
            scopeItem: scopeItem.scopeItemId,
            scopeImageTitle: `Untitled Image#${idx + 1}`,
            scopeImageDescription: `New Description#${idx + 1}`,
            path: filePath,
            thumbPath: filePath,
            scopeGroup: scopeItem.scopeGroup,
            index: idx,
            imageFile: {
              name: `${file.name}#${idx}.jpg`,
              type: file.type,
              uri: filePath,
            },
          };
        }
      );

      addScopeImageQuery.mutate(addedItems);

      if (fileRejections.length > 0) addErrorDroppedFiles(fileRejections);
    },
    []
  );

  const { handleUpdateSOWItemImgCount } = useSetSOWItemQueryData({
    filter: {
      scopeGroupId: scopeItem.scopeGroup,
    },
  });
  //#add scope image query

  return { onDrop };
};

export default useAddScopeItemImageSection;
