import React, { ChangeEvent, useEffect, useRef } from "react";
import { useAddPricebookItemContext } from "../AddPricebookItem";
import { produce } from "immer";
import { PricebookSupplierRate } from "../../../Models/Pricebook";

type Props = { rate: PricebookSupplierRate; index: number };

interface IAddSupplierRateLine {
  handleUpdateUnitCost: (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  handleUpdateUnit: (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  handleDeleteRate: () => void;

  rateSupplierRef: React.RefObject<HTMLDivElement>;
  unitCostRef: React.RefObject<HTMLInputElement>;
  handleClickSupplierName: (e: React.MouseEvent<HTMLDivElement>) => void;
  handleUpdateSupplierName: (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
}
const useAddSupplierRateLine = ({
  rate,
  index,
}: Props): IAddSupplierRateLine => {
  const { setShowSupplierList, setAddPricebookData } =
    useAddPricebookItemContext();

  const handleUpdateSupplierName = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setAddPricebookData(
      produce((draft) => {
        draft.supplierRates[index].supplierName = e.target.value;
        draft.supplierRates[index].supplier = null;
        return draft;
      })
    );
  };

  const handleUpdateUnitCost = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (!isNaN(Number(e.target.value))) {
      setAddPricebookData(
        produce((draft) => {
          draft.supplierRates[index].unitCost = e.target.value;
          return draft;
        })
      );
    }
  };

  const handleUpdateUnit = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setAddPricebookData(
      produce((draft) => {
        draft.supplierRates[index].uom = e.target.value;
        return draft;
      })
    );
  };

  const handleDeleteRate = () => {
    setAddPricebookData(
      produce((draft) => {
        draft.supplierRates.splice(index, 1);

        return draft;
      })
    );
  };
  const rateSupplierRef = useRef<HTMLDivElement>(null);
  const unitCostRef = useRef<HTMLInputElement>(null);
  const handleClickSupplierName = (e: React.MouseEvent<HTMLDivElement>) => {
    setShowSupplierList({
      anchorListEl: e.currentTarget,
      rateIndex: index,
      handlePostAdd: () => {
        setTimeout(() => {
          unitCostRef.current?.focus();
        }, 400);
      },
    });
  };

  useEffect(() => {
    if (rate.isNew) {
      const timeout = setTimeout(() => {
        rateSupplierRef.current?.click();
        setAddPricebookData(
          produce((draft) => {
            draft.supplierRates[index].isNew = false;
            return draft;
          })
        );
      }, 300);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [rateSupplierRef.current]);

  return {
    handleUpdateUnitCost,
    handleUpdateUnit,
    handleUpdateSupplierName,
    handleDeleteRate,
    rateSupplierRef,
    unitCostRef,
    handleClickSupplierName,
  };
};

export default useAddSupplierRateLine;
