import { Estimate } from "../../../Models/estimatesFilters";
import { useCallback, useMemo, useRef, useState } from "react";
import {
  ItemContent,
  TableComponents,
  TableVirtuoso,
  VirtuosoHandle,
} from "react-virtuoso";

import EditBudgetEstimateRow from "./EditBudgetEstimateRow";

import BudgetEstimateListFooter from "./BudgetEstimateListFooter";
import React from "react";
import DeleteEstimateConfirm from "./DeleteEstimateConfirm";
import {
  StyledBudgetEstimateTable,
  StyledBudgetEstimateTableBody,
  StyledBudgetEstimateTableHead,
  StyledBudgetListScroller,
  StyledEditDocketBudgetEstimateContainer,
  StyledBudgetEstimateTableRow,
  StyledBudgetEstimateFooter,
} from "./StyledComponentEditDocketBudgetEstimate";
import useEditDocketBudgetEstimate from "../hooks/useEditDocketBudgetEstimate";
import { useEditEstimatesStore } from "../../Store/useEditEstimateStore";
import useGetEstimateList from "../hooks/useGetEstimateList";
import DoxleListSkeleton from "../../../../DoxleDesignPattern/DoxleSkeleton/DoxleListSkeleton";
import DoxleEmptyPlaceHolder from "../../../../DoxleDesignPattern/DoxleEmptyPlaceHolder/DoxleEmptyPlaceHolder";
import { shallow } from "zustand/shallow";

//!!! EXTERNAL REPOS !!!//
import Measurement from "../../../../Measurements/Components/Measurements";
import { Error404Banner } from "../../../../DoxleDesignPattern/DoxleIcons/CommonIcons";
import { useDoxleThemeStore } from "../../../../DoxleGeneralStore/useDoxleThemeStore";
import { EmptyBudgetBanner } from "../../../../Budgets/Table/BudgetIcon";
import TableContainer from "@mui/material/TableContainer";
import BudgetEstimateHeader from "./BudgetEstimateHeader";
import { useEstimatesStore } from "../../Store/useEstimatesStore";

type Props = {};

interface EstimateListContext {
  isScrolling: boolean;
  isErrorFetchingEstimate: boolean;
}
const EditDocketBudgetEstimate = (props: Props) => {
  const [isScrolling, setIsScrolling] = useState(false);

  const estimateListRef = useRef<VirtuosoHandle>(null);
  const {} = useEditDocketBudgetEstimate({
    estimateListRef,
  });
  const {
    budgetEstimateItemList,
    isFetchingEstimate,
    isSuccessFetchingEstimate,
    isErrorFetchingEstimate,
    hasNextPageEstimate,
    fetchNextPageEstimate,
  } = useGetEstimateList({});

  const displayTakeOff = useEditEstimatesStore(
    (state) => state.displayTakeOff,
    shallow
  );
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }),
    shallow
  );

  //*render list
  const components: TableComponents<Estimate, EstimateListContext> = useMemo(
    () => ({
      Scroller: React.forwardRef((props, ref) => (
        <TableContainer
          ref={ref}
          {...props}
          component={StyledBudgetListScroller}
        />
      )),
      Table: (props) => (
        <StyledBudgetEstimateTable
          {...props}
          style={
            budgetEstimateItemList.length === 0
              ? {
                  height: "100%",
                }
              : undefined
          }
        />
      ),
      TableHead: StyledBudgetEstimateTableHead,
      TableBody: React.forwardRef((props, ref) => (
        <StyledBudgetEstimateTableBody {...props} ref={ref} />
      )),
      TableRow: (props) => (
        <StyledBudgetEstimateTableRow
          {...props}
          layout="position"
          transition={{
            duration: 0.2,
            damping: 16,
          }}
        />
      ),
      TableFoot: StyledBudgetEstimateFooter,
      EmptyPlaceholder: (props) => (
        <DoxleEmptyPlaceHolder
          containerStyle={{
            width: "100%",
            height: "100%",
          }}
          containerBgColor="transparent"
          headTitleText={
            props.context?.isErrorFetchingEstimate
              ? "Something Wrong"
              : "No Budgets"
          }
          subTitleText={
            props.context?.isErrorFetchingEstimate
              ? "Failed to get data, please try to reload the page"
              : "Add more budget to organise your work..."
          }
          illustration={
            props.context?.isErrorFetchingEstimate ? (
              <Error404Banner
                themeColor={doxleThemeColor}
                containerStyle={{ width: "30%" }}
              />
            ) : (
              <EmptyBudgetBanner themeColor={doxleThemeColor} />
            )
          }
        />
      ),
      // ScrollSeekPlaceholder,
    }),
    [budgetEstimateItemList.length]
  );

  const itemContent: ItemContent<Estimate, EstimateListContext> = useCallback(
    (index, item) => {
      return (
        <EditBudgetEstimateRow
          item={item}
          key={item.estimateId}
          itemIndex={index}
        />
      );
    },
    []
  );
  //!!! EXTERNAL REPOS !!!//
  if (displayTakeOff)
    return <Measurement takeOffId={displayTakeOff.takeOffId} />;
  else
    return (
      <StyledEditDocketBudgetEstimateContainer>
        {isFetchingEstimate && (
          <DoxleListSkeleton skeletonType="budgetRow" numOfRows={20} />
        )}
        {isSuccessFetchingEstimate && (
          <>
            <div
              style={{
                flex: 1,
                width: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <TableVirtuoso
                style={{
                  width: "100%",
                  flex: 1,
                }}
                context={{
                  isErrorFetchingEstimate,
                  isScrolling,
                }}
                // isScrolling={(scroll) => {
                //   if (!scroll) setIsScrolling(false);
                // }}
                totalCount={budgetEstimateItemList.length}
                ref={estimateListRef}
                data={budgetEstimateItemList}
                itemContent={itemContent}
                components={components}
                fixedHeaderContent={() => <BudgetEstimateHeader />}
                endReached={(index: number) => {
                  if (hasNextPageEstimate) fetchNextPageEstimate();
                }}
                fixedFooterContent={() => (
                  <BudgetEstimateListFooter isScrolling={isScrolling} />
                )}
              />
            </div>
          </>
        )}

        {isErrorFetchingEstimate && (
          <DoxleEmptyPlaceHolder
            containerStyle={{
              width: "100%",
              height: "100%",
            }}
            containerBgColor="transparen"
            headTitleText="Something wrong!"
            subTitleText="Your session may be expired, please try to reload the page..."
          />
        )}
        <DeleteEstimateConfirm />
      </StyledEditDocketBudgetEstimateContainer>
    );
};

export default EditDocketBudgetEstimate;
