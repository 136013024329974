import React from "react";
import { StyledBookingProjectFilterOption } from "./StyledComponentBookingProject";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import { IFilterBookingQueryProps } from "../../QueryAPI/bookingQueryAPI";
import useBookingFilterOption from "../Hooks/useBookingFilterOption";
import { AnimatePresence, motion } from "framer-motion";
import { AiFillCloseCircle } from "react-icons/ai";
type Props = {
  dueType: IFilterBookingQueryProps["due"] | "completed";
};

const BookingFilterOption = ({ dueType }: Props) => {
  const { doxleThemeColor } = useDoxleThemeStore((state) => ({
    doxleThemeColor: state.doxleThemeColor,

    currentTheme: state.currentTheme,
  }));
  const { isFilterSelected, handleClickFilter } = useBookingFilterOption({
    dueType,
  });
  return (
    <StyledBookingProjectFilterOption
      $selected={isFilterSelected}
      onClick={handleClickFilter}
    >
      {dueType === "week" && "Next 7 days"}
      {dueType === "fortnight" && "Next 14 days"}
      {dueType === "month" && "Next 30 days"}
      {dueType === "completed" && "Completed"}
      <AnimatePresence>
        {isFilterSelected && (
          <>
            <motion.div
              className="selected-filter-effect"
              layoutId="bookingFilterSelected"
              exit={{
                scaleX: [1, 0],
              }}
            />
          </>
        )}
      </AnimatePresence>
      {isFilterSelected && (
        <AiFillCloseCircle
          color={doxleThemeColor.primaryFontColor}
          size="16px"
          className="close-icon"
        />
      )}
    </StyledBookingProjectFilterOption>
  );
};

export default BookingFilterOption;
