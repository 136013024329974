import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import { StyledMailStatus, StyledMailListHeading } from "./styledCompnents"
import { shallow } from "zustand/shallow";
import {useMailStore} from "../Store/mailStore";
interface Props {
    statuscolor?: string
    fromString?: string
}
const MailStatusHeading = ({ fromString, statuscolor }: Props) => {
    const mailTheme = useMailStore(state => state.mailTheme, shallow)
    const {$themeColor} = useDoxleThemeStore(state => ({
        $themeColor: {...state.doxleThemeColor, ...mailTheme}
    }), shallow);
    return <>
        <StyledMailStatus statuscolor={statuscolor} />
        <StyledMailListHeading $themeColor={$themeColor}>{fromString?.replace(/ .*/, '')?.toUpperCase()}<br></br>{fromString?.replace(fromString?.replace(/ .*/, ''), "")?.toUpperCase()}</StyledMailListHeading>
    </>
}
export default MailStatusHeading
