import { create } from "zustand";
import { produce } from "immer";
import {DoxCircle, DoxLine, DoxPolygon, DoxRectangle, DrawingSet, Sheet, TakeOff} from "../Models/takeOffs";
import {sumMeasurements, toFloat} from "../konvaFunctions/generalFunctions";
import {useEffect} from "react";

type tTools = "Polygon"|"Rectangle"|"Circle"|"Line"|"Selector"
export const  takeOffUnits = ["LM","M2","M3","EA"]
export type tUnit = typeof takeOffUnits[number]
interface StageState {
  stageScale: number;
  stageX: number;
  stageY: number;
}
interface MeasurmentsStore {
  currentSheet: Sheet|undefined;
  setCurrentSheet: (sheet: Sheet) => void;
  displayTags: boolean;
  toggleDisplayTags: () => void;
  isDragging: boolean;
  setIsDragging: (bool: boolean) => void;
  currentShape: DoxCircle|DoxLine|DoxRectangle|DoxPolygon|null;
  setCurrentShape: (shape: DoxCircle|DoxLine|DoxRectangle|DoxPolygon|null) => void;
  measurements: (DoxCircle|DoxLine|DoxRectangle|DoxPolygon)[];
  addMeasurement: (measurement: DoxCircle|DoxLine|DoxRectangle|DoxPolygon) => void;
  undoMeasurement: () => void;
  setMeasurements: (measurements: (DoxCircle|DoxLine|DoxRectangle|DoxPolygon)[]) => void;
  currentTool: tTools;
  setCurrentTool: (tool: tTools) => void;
  currentUnit: tUnit;
  setCurrentUnit: (unit: tUnit) => void;
  isScaling: boolean;
  setIsScaling: (bool: boolean) => void;
  deduction: 1|-1;
  toggleDeduction: () => void;
  currentColor: string;
  setCurrentColor: (color: string) => void;
  zHeight: number;
  setZHeight: (z: number) => void;
  totalValue: number;
  currentTakeOff: TakeOff|undefined;
  setCurrentTakeOff: (takeOff: TakeOff|undefined) => void;
  resetStore: () => void;
  stageState: StageState;
  setStageState: (stageState: StageState) => void;
  userDefinedScaleLength: number;
  setUserDefinedScaleLength: (num: number) => void;
  scaleLine: DoxLine|null;
  setScaleLine: (line: DoxLine|null)=>void
}

const getAllowedTool = (currentTool: tTools, newUnit:tUnit) => {
  let returnTool: tTools = currentTool
  if (newUnit === 'EA') returnTool = 'Selector';
  else if (newUnit === 'M3' && currentTool === 'Line') returnTool = 'Polygon'
  else if (newUnit !== 'EA' && currentTool === 'Selector') returnTool = 'Line'
  return returnTool
}


export const useMeasurementsStore = create<MeasurmentsStore>((set, get) => ({
  currentSheet: undefined,
  setCurrentSheet: sheet => set({
    currentSheet: sheet,
    stageState: {stageScale: 1, stageX: 0, stageY: 0}
  }),
  displayTags: false,
  toggleDisplayTags: () => set({displayTags: !(get().displayTags)}),
  isDragging: false,
  setIsDragging: bool => set({isDragging: bool}),
  currentShape: null,
  setCurrentShape: shape => set({currentShape: shape}),
  measurements: [],
  addMeasurement: measurement => set({
    measurements: [...get().measurements, measurement],
    totalValue: get().totalValue + toFloat(measurement.actualValue)
  }),
  undoMeasurement: () => {
    const newMeasurements = [...get().measurements.slice(0,-1)];
    set({
      measurements: newMeasurements,
      totalValue: sumMeasurements(newMeasurements)
    });
  },
  setMeasurements: measurements => set({
    measurements: [...measurements],
    totalValue: sumMeasurements(measurements ?? [])
  }),
  currentTool: "Line",
  setCurrentTool: tool => set({currentTool: tool}),
  currentUnit: "LM",
  setCurrentUnit: unit => set({
    currentUnit: unit,
    measurements: [],
    totalValue: 0,
    currentTool: getAllowedTool(get().currentTool, unit)
  }),
  isScaling: false,
  setIsScaling: (bool: boolean) => set({
    isScaling: bool,
    currentShape: null,
  }),
  deduction: 1,
  toggleDeduction: () => set({deduction: (get().deduction * -1) as 1| -1}),
  currentColor: "#FF0000",
  setCurrentColor: color => set({currentColor: color}),
  zHeight: 1,
  setZHeight: z => set({zHeight: z}),
  totalValue: 0,
  currentTakeOff: undefined,
  setCurrentTakeOff: takeOff => {
    if (takeOff) set({
      currentTakeOff: takeOff,
      measurements: takeOff?.measurements ?? [],
      totalValue: sumMeasurements(takeOff?.measurements ?? []),
      currentUnit: takeOff.unit as tUnit,
      currentTool:
        takeOff.unit === "EA" ? "Selector"
        : takeOff.unit === "LM" ? "Line"
        : "Polygon",
    })
    else set({
      currentTakeOff: takeOff,
      measurements: [],
      totalValue: 0
    })
  },
  scaleLine: null,
  setScaleLine: (line: DoxLine|null)=>set({scaleLine: line}),
  resetStore: () => set({
    currentSheet: undefined,
    displayTags: false,
    currentShape: null,
    measurements: [],
    currentTool: "Line",
    currentUnit: "LM",
    deduction: 1,
    zHeight: 1,
    totalValue: 0,
    scaleLine: null,
    stageState: {stageScale: 1, stageX: 0, stageY: 0,},
    userDefinedScaleLength: 0
  }),
  stageState: {stageScale: 1, stageX: 0, stageY: 0,},
  setStageState: (stageState: StageState) => set({stageState: stageState}),
  userDefinedScaleLength: 0,
  setUserDefinedScaleLength: (num: number) => set({userDefinedScaleLength: num})
}));
