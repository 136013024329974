import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { shallow } from "zustand/shallow";
import { QuoteRequestLink, QuoteResponse } from "../../models/quote";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { useDoxleAuthStore } from "../../../DoxleGeneralStore/useDoxleAuthStore";
import useDoxleNotificationStore from "../../../DoxleGeneralStore/useDoxleNotificationStore";
import QuotesQueryAPI from "../../QueryAPI/quotesQuery";
import {
  StyledAddQuotesButton,
  StyledDoxleIconContainer,
  StyledDoxleIconTextSpan,
} from "../SharedDocketStyledComponents";
import Loading from "../../../Utilities/Lottie/Loading";
import { DoxleIcon } from "../icons";
import DoxleEmptyPlaceHolder from "../../../DoxleDesignPattern/DoxleEmptyPlaceHolder/DoxleEmptyPlaceHolder";
import { motion } from "framer-motion";
import {
  editRgbaAlpha,
  formatTISODate,
  formatTISODateTime,
} from "../../../Utilities/FunctionUtilities";
import { produce } from "immer";
import { useDoxleCurrentContextStore } from "../../../DoxleGeneralStore/useDoxleCurrentContext";
import AddQuoteResponseReference from "./AddQuoteResponseReference";
import QuoteResponseSupplier from "./QuoteResponseSupplier";
import QuoteResponseDateSection from "./QuoteResponseDateSection";
import QuoteResponsePaymentTerms from "./QuoteResponsePaymentTerms";
import QuoteResponseAttachment from "./QuoteResponseAttachment";
import QuoteResponseLineSection from "./QuoteResponseLineSection";
import QuoteResponseTotal from "./QuoteResponseTotal";
import SuccessSubmitQuotePage from "./SuccessSubmitQuotePage";
import { useNavigate } from "react-router-dom";
import { StyledQuoteResponsePageContainer } from "./StyledComponentQuoteResponse";
import QuoteResponseTopMenu from "./QuoteResponseTopMenu";
import QuoteSOWView from "./QuoteSOWView/QuoteSOWView";
import DoxleGallery from "../../../DoxleDesignPattern/DoxleGallery/DoxleGallery";
import QuoteRejectPage from "./QuoteRejectPage";

type Props = {};

export type QuoteResponseMenu = "Quote" | "Scope Of Works";

const getQuoteAccessId = () => {
  const url = window.location.href;
  const urlArray = url.split("/");
  const urlArrayUpper = url.toUpperCase().split("/");
  const docketIndex = urlArrayUpper.indexOf("QUOTE");
  if (docketIndex >= 0) {
    const docketPk = urlArray?.[docketIndex + 1];
    return docketPk ?? "";
  }
  return "";
};

export interface QuoteResponseContext {
  quoteResponse: QuoteResponse;
  setQuoteResponse: React.Dispatch<React.SetStateAction<QuoteResponse>>;
  quoteRequestData: QuoteRequestLink | null;
  addedAttachments: File[];
  setAddedAttachments: React.Dispatch<React.SetStateAction<File[]>>;
  selectedQuoteMenuTab: QuoteResponseMenu;
  setSelectedQuoteMenuTab: React.Dispatch<
    React.SetStateAction<QuoteResponseMenu>
  >;
}

const QuoteContext = createContext({});
const initialQuotesResponse: QuoteResponse = {
  responseId: "",
  request: null,
  company: "",
  project: "",
  docket: "",
  supplier: null,
  supplierName: "",
  contactName: "",
  contactEmail: "",
  contactId: "",
  quoteSubTotal: "0",
  status: null,
  quoteTotal: "0",
  timeStamp: formatTISODateTime(new Date()),
  lines: [],
  attachmentsCount: 0,
  attachments: [],
  paymentTerms: 14,
  paymentTermsSuffix: "Days",
  expiryDate: formatTISODate(new Date()),
  reference: "",
};
const QuoteResponsePage = (props: Props) => {
  const [quoteResponse, setQuoteResponse] = useState<QuoteResponse>(
    initialQuotesResponse
  );
  const [addedAttachments, setAddedAttachments] = useState<File[]>([]);
  const { THEME_COLOR, theme, doxleFont } = useDoxleThemeStore(
    (state) => ({
      THEME_COLOR: state.doxleThemeColor,
      theme: state.currentTheme,
      doxleFont: state.doxleFont,
    }),
    shallow
  );

  const [selectedQuoteMenuTab, setSelectedQuoteMenuTab] =
    useState<QuoteResponseMenu>("Quote");
  const [successfullAddedQuote, setSuccessfullAddedQuote] = useState<
    QuoteResponse | undefined
  >(undefined);

  const [showRejectPage, setshowRejectPage] = useState(false);
  const { setUser } = useDoxleAuthStore(
    (state) => ({
      setUser: state.setUser,
    }),
    shallow
  );
  const { company, setCurrentCompany } = useDoxleCurrentContextStore(
    (state) => ({
      company: state.currentCompany,
      setCurrentCompany: state.setCurrentCompany,
    }),
    shallow
  );
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );

  const quoteDetailRetrieveQuery = QuotesQueryAPI.useQuoteLink(
    getQuoteAccessId(),
    showNotification
  );

  const quoteRequestData: QuoteRequestLink | null = useMemo(
    () =>
      quoteDetailRetrieveQuery.isSuccess
        ? quoteDetailRetrieveQuery.data.data
        : null,
    [quoteDetailRetrieveQuery.data]
  );

  const onSuccessAddQuoteResponse = (newQResponse?: QuoteResponse) => {
    if (newQResponse) setSuccessfullAddedQuote(newQResponse);
  };
  const addResponseQuery = QuotesQueryAPI.useAddQuoteResponse({
    showNotification,
    onSuccessCb: onSuccessAddQuoteResponse,
  });

  const onSuccessUpdateCb = () => setshowRejectPage(true);
  const updateQuoteRequest = QuotesQueryAPI.useUpdateQuoteRequest({
    showNotification,
    onSuccessCb: onSuccessUpdateCb,
  });
  const isDisableAddBtn = Boolean(
    !quoteResponse.paymentTerms || !quoteResponse.reference
  );

  const handleAddQuoteResponse = () => {
    addResponseQuery.mutate({
      quoteResponse: quoteResponse,

      attachment: addedAttachments,
    });
  };
  const handleRejectQuoteRequest = () => {
    if (quoteRequestData) {
      updateQuoteRequest.mutate({
        company: quoteRequestData.company,
        quoteRequestId: quoteRequestData.requestId,
        updateBody: {
          status: "Rejected",
        },
      });
    }
  };
  useEffect(() => {
    if (quoteRequestData) {
      setQuoteResponse((prev) =>
        produce(prev, (draft) => {
          draft.request = quoteRequestData.requestId;
          draft.company = quoteRequestData.company.companyId;
          draft.project = quoteRequestData.project;
          draft.docket = quoteRequestData.docket.docketPk;
          draft.supplier = quoteRequestData.supplier?.contactCompanyId ?? null;
          draft.supplierName = quoteRequestData.supplierName;
          return draft;
        })
      );
      setUser(quoteRequestData.user);
      setCurrentCompany(quoteRequestData.company);
    }
  }, [quoteRequestData]);
  const navigate = useNavigate();
  const handleNavigateToDoxle = () => navigate("/");
  const rootDocketAnimatedVariants = {
    initial: {
      opacity: 0,
      scaleX: 0,
    },
    entering: {
      opacity: 1,
      scaleX: 1,
      transition: {
        duration: 0.4,
        delay: 0.1,
      },
    },
  };

  const contentAnimatedVariants = {
    initial: {
      opacity: 0,
      y: -5,
    },
    entering: {
      opacity: 1,
      y: 0,
    },
  };
  const quoteResponseContextValue: QuoteResponseContext = useMemo(
    () => ({
      quoteResponse,
      setQuoteResponse,
      quoteRequestData,
      addedAttachments,
      setAddedAttachments,
      selectedQuoteMenuTab,
      setSelectedQuoteMenuTab,
    }),
    [quoteResponse, quoteRequestData, addedAttachments, selectedQuoteMenuTab]
  );

  return (
    <QuoteContext.Provider value={quoteResponseContextValue}>
      <StyledQuoteResponsePageContainer
        $doxleFont={doxleFont}
        $themeColor={THEME_COLOR}
        variants={rootDocketAnimatedVariants}
        initial="initial"
        animate="entering"
        exit="initial"
        transition={{
          delay: 0.5,
        }}
      >
        {quoteDetailRetrieveQuery.isLoading && (
          <Loading
            containerStyle={{
              width: "100%",
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            text="Loading quote data, please wait..."
          />
        )}
        {(addResponseQuery.isLoading || updateQuoteRequest.isLoading) && (
          <Loading
            containerStyle={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "absolute",
              zIndex: 10,
              backgroundColor: editRgbaAlpha({
                rgbaColor: THEME_COLOR.primaryBackgroundColor,
                alpha: "0.95",
              }),
            }}
            text={
              addResponseQuery.isLoading
                ? "Submitting quote, please wait..."
                : "Sending quote response, please wait..."
            }
          />
        )}

        {quoteDetailRetrieveQuery.isSuccess &&
          quoteRequestData &&
          (successfullAddedQuote ? (
            <SuccessSubmitQuotePage quoteResponse={successfullAddedQuote} />
          ) : showRejectPage ? (
            <QuoteRejectPage />
          ) : (
            <>
              <motion.div
                className="banner-company-name"
                variants={contentAnimatedVariants}
                initial="initial"
                animate="entering"
              >
                {quoteRequestData?.company.name}
              </motion.div>
              <motion.div
                className="quote-title-text"
                variants={contentAnimatedVariants}
                initial="initial"
                animate="entering"
                transition={{ delay: 0.2 }}
              >
                {quoteRequestData?.docket.docketName} - Quote Response
              </motion.div>
              <motion.div
                className="quote-notice-text"
                variants={contentAnimatedVariants}
                initial="initial"
                animate="entering"
                transition={{ delay: 0.3 }}
              >
                Please check the scope of works and quotes to ensure you have
                included all required costs. Explicitly note any exclusions
                where you are not able to meet our requirements.
              </motion.div>
              <QuoteResponseTopMenu />
              {selectedQuoteMenuTab === "Quote" ? (
                <motion.div
                  className="quote-content"
                  variants={contentAnimatedVariants}
                  initial="initial"
                  animate="entering"
                  transition={{ delay: 0.4 }}
                >
                  <AddQuoteResponseReference />
                  <QuoteResponseSupplier />
                  <QuoteResponseDateSection />

                  <QuoteResponsePaymentTerms />

                  <QuoteResponseAttachment />

                  <QuoteResponseLineSection />

                  <QuoteResponseTotal />

                  <motion.div className="add-quote-btn" layout>
                    <StyledAddQuotesButton
                      $doxleFont={doxleFont}
                      $bgColor={THEME_COLOR.primaryFontColor}
                      $textColor={THEME_COLOR.primaryContainerColor}
                      disabled={isDisableAddBtn}
                      onClick={handleAddQuoteResponse}
                    >
                      Submit
                    </StyledAddQuotesButton>

                    <StyledAddQuotesButton
                      $doxleFont={doxleFont}
                      $bgColor={THEME_COLOR.primaryContainerColor}
                      $textColor={THEME_COLOR.primaryFontColor}
                      $borderColor={THEME_COLOR.rowBorderColor}
                      onClick={handleRejectQuoteRequest}
                    >
                      Reject
                    </StyledAddQuotesButton>
                  </motion.div>
                </motion.div>
              ) : (
                <QuoteSOWView scopeGroups={quoteRequestData.scopeGroups} />
              )}
            </>
          ))}

        {quoteDetailRetrieveQuery.isError && (
          <DoxleEmptyPlaceHolder
            headTitleText="Something wrong!"
            subTitleText="The link might be expired..."
            containerBgColor="transparent"
            containerStyle={{
              flex: 1,
              width: "100%",
            }}
            addBtn={{
              btnText: "Go to Doxle",
              btnFunction: handleNavigateToDoxle,

              btnTextSize: 18,
              btnWidthInPixel: "144px",
            }}
          />
        )}

        <StyledDoxleIconContainer
          $themeColor={THEME_COLOR}
          $doxleFont={doxleFont}
        >
          <DoxleIcon {...THEME_COLOR} />
          <StyledDoxleIconTextSpan
            $themeColor={THEME_COLOR}
            $doxleFont={doxleFont}
          >
            Powered by Doxle
          </StyledDoxleIconTextSpan>
        </StyledDoxleIconContainer>
      </StyledQuoteResponsePageContainer>
    </QuoteContext.Provider>
  );
};
const useQuoteContext = () => useContext(QuoteContext) as QuoteResponseContext;
export default QuoteResponsePage;
export { useQuoteContext };
