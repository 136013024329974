import React, { useCallback, useMemo } from "react";
import { useDoxleThemeStore } from "../../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import {
  StyledPaymentClaimListContainer,
  StyledPaymentClaimListTitle,
  StyledPaymentClaimTable,
  StyledPaymentClaimTableBody,
  StyledPaymentClaimTableContainer,
  StyledPaymentClaimTableHead,
  StyledPaymentClaimTableRow,
  StyledPaymentClaimTableScroller,
} from "./StyledComponentPaymentClaimList";
import PaymentClaim, {
  PAYMENT_CLAIM_TABLE_HEADER_LIST,
} from "../../../Models/PaymentClaim";
import { ItemContent, TableComponents, TableVirtuoso } from "react-virtuoso";
import TableContainer from "@mui/material/TableContainer";
import DoxleEmptyPlaceHolder from "../../../../DoxleDesignPattern/DoxleEmptyPlaceHolder/DoxleEmptyPlaceHolder";
import useGetPaymentClaims from "../../../Hooks/useGetPaymentClaims";
import PaymentClaimRow from "./PaymentClaimRow";
import PaymentClaimTableHeader from "./PaymentClaimTableHeader";
import DoxleListSkeleton from "../../../../DoxleDesignPattern/DoxleSkeleton/DoxleListSkeleton";
import ListLoadingMore from "../../../../Utilities/Lottie/ListLoadingMore";
import {
  useEditDocketSideScreenStore
} from "../../../../CoreContent/EditDocketSideScreen/EditDocketSideScreenStore/useEditDocketSideScreenStore";
import {formatter} from "../../../../Utilities/FunctionUtilities";
import { Error404Banner } from "../../../../DoxleDesignPattern/DoxleIcons/CommonIcons";
import { EmptyPaymentListBanner } from "./PaymentIcon";

type Props = {};

interface PaymentClaimTableContext {}
const PaymentClaimList = (props: Props) => {
  const { themeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      themeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }),
    shallow
  );
  const {
    docket,
    claimList,
    totalClaims,
    isFetchingClaimList,
    isSuccessFetchingClaimList,
    isErrorFetchingClaimList,
    handleFetchNextPageClaimList,
    isFetchingNExtPageClaimList,
  } = useGetPaymentClaims({});

  //*render table
  const tableComponents: TableComponents<
    PaymentClaim,
    PaymentClaimTableContext
  > = useMemo(
    () => ({
      Table: (props) => <StyledPaymentClaimTable {...props} />,
      TableBody: React.forwardRef((props, ref) => (
        <StyledPaymentClaimTableBody {...props} ref={ref} />
      )),
      Scroller: React.forwardRef((props, ref) => (
        <TableContainer
          ref={ref}
          {...props}
          component={StyledPaymentClaimTableScroller}
        />
      )),
      TableRow: StyledPaymentClaimTableRow,
      TableHead: StyledPaymentClaimTableHead,
      EmptyPlaceholder: ({ context }) => (
        <DoxleEmptyPlaceHolder
          headTitleText={isErrorFetchingClaimList?"Something Wrong":"No Payment Claim..."}
          containerStyle={{
            width: "100%",
            height: "100%",
          }}
          containerBgColor="transparent"
          illustration={
            isErrorFetchingClaimList ? (
              <Error404Banner themeColor={themeColor} containerStyle={{width: "30%"}}/>
            ) : (
              <EmptyPaymentListBanner themeColor={themeColor} />
            )
          }
        />
      ),
    }),
    []
  );

  const docketValue = Number((docket?.isIncome ? docket?.incomeXero : docket?.costXero) ?? 0)
  const difference = Math.round(100 * (docketValue - Number(totalClaims))) / 100
  const itemContent: ItemContent<PaymentClaim, PaymentClaimTableContext> =
    useCallback((_, item, context) => <PaymentClaimRow claim={item} />, []);
  return (
    <StyledPaymentClaimListContainer
      $themeColor={themeColor}
      initial={{
        y: 10,
        opacity: 0,
      }}
      animate={{
        y: 0,
        opacity: 1,
      }}
    >
      <StyledPaymentClaimListTitle
        $themeColor={themeColor}
        $doxleFont={doxleFont}
      >
        Invoices
      </StyledPaymentClaimListTitle>
      <div className="table-wrapper">
        {isSuccessFetchingClaimList && (
          <TableVirtuoso
            data={claimList}
            components={tableComponents}
            fixedHeaderContent={() => <PaymentClaimTableHeader />}
            itemContent={itemContent}
            endReached={handleFetchNextPageClaimList}
            atBottomThreshold={0.2}
          />
        )}

        {isErrorFetchingClaimList && (
          <DoxleEmptyPlaceHolder
            headTitleText="Something wrong!"
            subTitleText="Failed to get payment list, please try reload the page..."
            containerBgColor="transparent"
          />
        )}
        {isFetchingClaimList && (
          <DoxleListSkeleton skeletonType="orderRow" numOfRows={20} />
        )}

        {isFetchingNExtPageClaimList && (
          <ListLoadingMore
            animationSize={144}
            containerStyle={{
              position: "absolute",
              bottom: 0,
              left: 0,
              width: "100%",
              zIndex: 11,
              justifyContent: "center",
              display: "flex",
            }}
          />
        )}
      </div>
      {Boolean(isSuccessFetchingClaimList && Math.abs(difference) > 0.1) && (
        <div style={{
          backgroundColor: themeColor.primaryBackgroundColor,
          fontFamily: doxleFont.subTitleFont,
          fontWeight: 500,
          fontSize: "1.4rem",
          textAlign: "right"
        }}>
          Total {docket?.isIncome ? "Income" : "Cost"} from Profit and Loss = {formatter.format(docketValue)}
          <br/>Total {docket?.isIncome ? "Income" : "Cost"} Payments Found = {formatter.format(totalClaims)}
          <br/>Difference {formatter.format(Math.abs(difference))}
          <br/>This may be caused by Spent Money / Received Money Transactions in Xero
        </div>
      )}
    </StyledPaymentClaimListContainer>
  );
};

export default PaymentClaimList;
