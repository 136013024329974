import React, {useMemo, useState} from "react";
import {shallow} from "zustand/shallow";
import {useDoxleCurrentContextStore} from "../../DoxleGeneralStore/useDoxleCurrentContext";
import {Project} from "../../Models/project";
import useDoxleNotificationStore from "../../DoxleGeneralStore/useDoxleNotificationStore";
import ProjectQueryAPI from "../../Services/QueryHooks/projectQueryAPI";
import {ProjectTrackingOption} from "../Models/account";
import AccountMappingQueryAPI from "../QueryHooks/AccountsQueryAPI";

const useProjectTrackingDropdown = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement|null>(null)
  const {company, project, setProject} = useDoxleCurrentContextStore(state => ({
    project: state.currentProject,
    setProject: state.setCurrentProject,
    company: state.currentCompany
  }));
  const handleUpdateProjectSuccess = (project: Project|undefined) => {
    if (project) setProject(project)
  }
  const showNotification = useDoxleNotificationStore(state => state.showNotification, shallow)
  const updateProjectQuery = ProjectQueryAPI.useUpdateProjectQuery({
    company,
    showNotification,
    filter: {},
    onSuccessUpdateCb: handleUpdateProjectSuccess
  })
  const isLinked = useMemo(()=>Boolean(project?.trackingName), [project])
  const handleIconClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (isLinked && project) updateProjectQuery.mutate({
      projectId: project?.projectId,
      updateData: {
        trackingName: null,
        trackingId: null
      }
    })
  }
  const handleFieldClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!isLinked) setAnchorEl(e.currentTarget)
  }
  const handleSelectTrackingOption = (option: ProjectTrackingOption|null) => {
    if (project) updateProjectQuery.mutate({
      projectId: project?.projectId,
      updateData: {
        trackingName: option?.trackingOptionName ?? null,
        trackingId: option?.trackingOptionId ?? null
      }
    })
    setAnchorEl(null)
  }
  const trackingOptionQuery = AccountMappingQueryAPI.useRetrieveTrackingOptions()
  const trackingOptionList: ProjectTrackingOption[] = useMemo(()=> trackingOptionQuery.data?.data ?? [], [trackingOptionQuery])
  return {
    isLinked,
    handleFieldClick,
    project,
    handleIconClick,
    anchorEl,
    setAnchorEl,
    handleSelectTrackingOption,
    trackingOptionList,
  }
}
export default useProjectTrackingDropdown
