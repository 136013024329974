import { useCallback, useEffect } from "react";
import { useBudgetStore } from "../../Store/useBudgetStore";
import { useDoxleAuthStore } from "../../../DoxleGeneralStore/useDoxleAuthStore";
import { shallow } from "zustand/shallow";
import useDoxleNotificationStore from "../../../DoxleGeneralStore/useDoxleNotificationStore";
import { useDoxleCurrentContextStore } from "../../../DoxleGeneralStore/useDoxleCurrentContext";
import { useProjectStore } from "../../../DoxleGeneralStore/useprojectStore";
import DocketQuery from "../../../Services/QueryHooks/docketQueryAPI";
import { NEW_DOCKET_TEMPLATE } from "../../../Models/dockets";

type Props = {};

interface BudgetsProjectTab {
  isAddingNewBudget: boolean;
}
const useBudgetsProjectTab = (props: Props): BudgetsProjectTab => {
  const {
    filterDocketListQuery,
    isBudgetProjectLevel,
    setIsBudgetProjectLevel,
    resetBudgetStore,
    setFilterDocketListQuery,
  } = useBudgetStore((state) => ({
    filterDocketListQuery: state.filterDocketListQuery,
    isBudgetProjectLevel: state.isBudgetProjectLevel,
    setIsBudgetProjectLevel: state.setIsBudgetProjectLevel,
    resetBudgetStore: state.resetBudgetStore,
    setFilterDocketListQuery: state.setFilterDocketListQuery,
  }));
  const { setFunctionNewButton } = useProjectStore(
    (state) => ({
      setFunctionNewButton: state.setFunctionNewButton,
    }),
    shallow
  );
  const { user } = useDoxleAuthStore(
    (state) => ({
      user: state.user,
    }),
    shallow
  );
  const { company, currentProject, currentSearchText } =
    useDoxleCurrentContextStore(
      (state) => ({
        company: state.currentCompany,
        currentProject: state.currentProject,
        currentSearchText: state.currentSearchText,
      }),
      shallow
    );
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );

  const addBudgetQuery = DocketQuery.useAddDocketQuery({
    company,

    showNotification,
    filter: filterDocketListQuery,
  });

  const handleAddNewBudget = useCallback(() => {
    const newBudget = NEW_DOCKET_TEMPLATE({
      docketName: "New Budget",
      createdBy: user?.userId || "",
      project: isBudgetProjectLevel ? currentProject?.projectId ?? null : null,
      company: company?.companyId ?? "",
      isBudget: true,
      isExpense: true,
    });

    addBudgetQuery.mutate(newBudget);
  }, [company, currentProject, user, isBudgetProjectLevel]);

  useEffect(() => {
    return () => setFunctionNewButton([]);
  }, []);
  useEffect(() => {
    setFunctionNewButton([
      { btnFunction: handleAddNewBudget, isLoading: addBudgetQuery.isLoading },
    ]);
  }, [handleAddNewBudget, addBudgetQuery.isLoading]);

  useEffect(() => {
    setFilterDocketListQuery({
      view: "budget",
      project: isBudgetProjectLevel
        ? currentProject
          ? currentProject.projectId
          : undefined
        : undefined,
      search: currentSearchText || undefined,
    });
  }, [isBudgetProjectLevel, currentProject, currentSearchText]);

  return { isAddingNewBudget: addBudgetQuery.isLoading };
};

export default useBudgetsProjectTab;
