import React from "react";
import { QAList } from "../../../Models/qa";
import { useDoxleThemeStore } from "../../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import {
  StyleQAListDataCell,
  StyledQAListRowContainer,
} from "./StyledComponentQAProjectList";
import { QA_LIST_TABLE_FIELD } from "../../Store/useProjectQAStore";
import { IoDocumentOutline } from "react-icons/io5";
import { CgArrowsExpandRight } from "react-icons/cg";
import QAStatus from "../CommonComponents/QAStatus";
import dayjs from "dayjs";
import useQAListRow from "./Hooks/useQAListRow";
import QAListSubmenu from "./QAListSubmenu";
import { AnimatePresence, motion } from "framer-motion";
import DoxleIconButton from "../../../../DoxleDesignPattern/DoxleButtons/DoxleIconButton";
import CircularProgress from "@mui/material/CircularProgress";
import { QADeleteIcon } from "../../../QAIcons";
import { IoCheckmarkOutline } from "react-icons/io5";
import { PiSealCheckFill } from "react-icons/pi";
import { MdCancel } from "react-icons/md";

type Props = {
  qaListItem: QAList;
  setdeletedQAList: React.Dispatch<React.SetStateAction<QAList | undefined>>;
};

const QAListRow = ({ qaListItem, setdeletedQAList }: Props) => {
  const { doxleThemeColor, doxleFont, transparentMode, currentTheme } =
    useDoxleThemeStore(
      (state) => ({
        doxleThemeColor: state.doxleThemeColor,
        doxleFont: state.doxleFont,
        transparentMode: state.transparentMode,
        currentTheme: state.currentTheme,
      }),
      shallow
    );

  const {
    showSubmenu,
    setShowSubmenu,
    handleClickExpandMenu,
    handleCloseSubmenu,
    handleClickRow,
    isHoverRow,
    setisHoverRow,
    isDeletingQAList,
    handleUpdateCompleteQAList,
    isUpdatingQAList,
  } = useQAListRow({ qaListItem });
  return (
    <>
      <StyledQAListRowContainer
        onClick={handleClickRow}
        onMouseEnter={() => setisHoverRow(true)}
        onMouseLeave={() => setisHoverRow(false)}
      >
        {QA_LIST_TABLE_FIELD.map((header, idx) => (
          <StyleQAListDataCell
            key={`cell#${idx}#${header.headerName}`}
            $widthRatio={header.cellSize}
            $horizontalAlign={
              header.headerName === "Title" ? "flex-start" : "center"
            }
          >
            {header.headerName === "Title" && (
              <>
                {(isDeletingQAList || isUpdatingQAList) && (
                  <CircularProgress
                    style={{
                      color: isDeletingQAList
                        ? "red"
                        : doxleThemeColor.primaryFontColor,
                    }}
                    size="1.2rem"
                  />
                )}
                <motion.div
                  className="qa-list-icon-wrapper"
                  onClick={handleClickExpandMenu}
                >
                  <IoDocumentOutline
                    size="2rem"
                    color={doxleThemeColor.primaryFontColor}
                  />
                  <CgArrowsExpandRight
                    className="expand-icon"
                    size="1.2rem"
                    color={doxleThemeColor.primaryFontColor}
                  />
                </motion.div>
                {qaListItem.completed && (
                  <motion.div className="complete-icon-wrapper">
                    <PiSealCheckFill size="2rem" color="green" />
                  </motion.div>
                )}
                <motion.span className="title-text-content" layout>
                  {qaListItem.defectListTitle}
                </motion.span>

                {isHoverRow && !isDeletingQAList && !isUpdatingQAList && (
                  <>
                    <DoxleIconButton
                      iconSource={
                        qaListItem.completed ? (
                          <MdCancel
                            color={doxleThemeColor.primaryFontColor}
                            size="25px"
                            strokeWidth={0.1}
                          />
                        ) : (
                          <IoCheckmarkOutline
                            color={doxleThemeColor.primaryFontColor}
                            size="25px"
                            strokeWidth={0.4}
                          />
                        )
                      }
                      buttonSize={25}
                      buttonWrapperStyle={{ marginInline: "4px" }}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleUpdateCompleteQAList();
                      }}
                    />
                    <DoxleIconButton
                      iconSource={<QADeleteIcon />}
                      buttonSize={25}
                      onClick={(e) => {
                        e.stopPropagation();
                        if (!isDeletingQAList) setdeletedQAList(qaListItem);
                      }}
                    />
                  </>
                )}
              </>
            )}

            {header.headerName === "Working" && (
              <QAStatus
                statusCount={qaListItem.unattendedCount}
                statusType="unattended"
              />
            )}

            {header.headerName === "Completed" && (
              <QAStatus
                statusCount={qaListItem.completedCount}
                statusType="completed"
              />
            )}

            {header.headerName === "Assignee" && (
              <span className="regular-content-text">
                {qaListItem.assigneeName || "No Assignee"}
              </span>
            )}

            {header.headerName === "Due Date" && (
              <span className="regular-content-text">
                {qaListItem.dueDate
                  ? dayjs(qaListItem.dueDate).format("DD MMM YY")
                  : "-"}
              </span>
            )}
          </StyleQAListDataCell>
        ))}
      </StyledQAListRowContainer>
      <AnimatePresence>
        {showSubmenu && (
          <QAListSubmenu
            qaListItem={qaListItem}
            handleCloseSubmenu={handleCloseSubmenu}
          />
        )}
      </AnimatePresence>
    </>
  );
};

export default React.memo(QAListRow);
