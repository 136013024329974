import React, { useEffect, useMemo } from "react";
import {
  GridPage,
  useDoxleGridLayoutStore,
} from "./Store/useDoxleGridLayoutStore";
import { shallow } from "zustand/shallow";
import {
  StyledColumnGridContainer,
  StyledGridItemContainer,
} from "./StyledComponentDoxleGridLayout";

type Props<TItem> = {
  renderItem: (item: TItem, itemIndex: number) => JSX.Element;
};

function DisplayGrid<TItem>({ renderItem }: Props<TItem>) {
  const { currentPage, extractItemKeyProp } = useDoxleGridLayoutStore(
    (state) => ({
      currentPage: state.currentPage,
      extractItemKeyProp: state.extractItemKeyProp,
    }),
    shallow
  );

  const { dataPages } = useDoxleGridLayoutStore((state) => ({
    dataPages: state.dataPages,
  }));
  const gridData: GridPage<any> | undefined = dataPages[currentPage];

  useEffect(() => {
    console.log("gridData:", gridData);
  }, [gridData]);

  return (
    <>
      {gridData &&
        gridData.column.map(
          (columnItem, index) =>
            columnItem.children.length > 0 && (
              <StyledColumnGridContainer
                key={`columnGrid#${index}`}
                style={{ ...columnItem.columnStyle }}
              >
                {columnItem.children.map((gridItem, gridIndex) => (
                  <StyledGridItemContainer
                    key={`gridItemCol#${index}#${gridItem.itemData[extractItemKeyProp]}`}
                    style={{ ...gridItem.itemStyle }}
                  >
                    {renderItem(gridItem.itemData, gridIndex)}
                  </StyledGridItemContainer>
                ))}
              </StyledColumnGridContainer>
            )
        )}
    </>
  );
}

export default DisplayGrid;
