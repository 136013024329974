import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import PricebookQueryAPI, {
  AddPricebookBody,
} from "../../../QueryHooks/PricebookQueryAPI";
import { useDoxleCurrentContextStore } from "../../../../DoxleGeneralStore/useDoxleCurrentContext";
import { useShallow } from "zustand/react/shallow";
import { usePricebookStore } from "../../../Store/usePricebookStore";
import { ContactCompany } from "../../../../Models/addressBook";
import { produce } from "immer";
import {
  PricebookItem,
  PricebookSupplierRate,
} from "../../../Models/Pricebook";
import { DropEvent, FileRejection } from "react-dropzone";
import useGetCoreDockets from "../../../../CoreContent/QueryDataHooks/GetQueryDataHooks/useGetCoreDockets";
import { BasicCoreDocket } from "../../../../Models/dockets";
import useDoxleNotificationStore from "../../../../DoxleGeneralStore/useDoxleNotificationStore";

type Props = {};
interface IToggleSupplierListParams {
  anchorListEl: HTMLDivElement;
  rateIndex: number;
  handlePostAdd?: () => void;
}
export interface IAddPricebookItemContextValue {
  addPricebookData: Required<AddPricebookBody>;
  setAddPricebookData: React.Dispatch<
    React.SetStateAction<Required<AddPricebookBody>>
  >;
  setShowSupplierList: React.Dispatch<
    React.SetStateAction<IToggleSupplierListParams | undefined>
  >;
  shouldShowWarning: boolean;
}

interface IAddPricebookItem {
  addPricebookData: Required<AddPricebookBody>;
  setAddPricebookData: React.Dispatch<
    React.SetStateAction<Required<AddPricebookBody>>
  >;
  showSupplierList: IToggleSupplierListParams | undefined;
  setShowSupplierList: React.Dispatch<
    React.SetStateAction<IToggleSupplierListParams | undefined>
  >;
  addPricebookItemContextValue: IAddPricebookItemContextValue;
  handleAddPricebook: () => void;
  selectedCoreDockets: BasicCoreDocket[];
  handleSelectSupplier: (contact: ContactCompany) => void;
  handleSelectCoreDockets: (coreDocketId: string) => void;
  handleAddNewSupplierRate: () => void;
  handleUpdateDescription: (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  onDrop: <T extends File>(
    acceptedFiles: T[],
    fileRejections: FileRejection[],
    event: DropEvent
  ) => void;
  isAddingPricebook: boolean;
  shouldShowWarning: boolean;
  coreDocketList: BasicCoreDocket[];
  handleRemoveAddedSwatch: (index: number) => void;
  handleRemoveAddedCoreDocket: (index: number) => void;
}
const useAddPricebookItem = (): IAddPricebookItem => {
  const [addPricebookData, setAddPricebookData] = useState<
    Required<AddPricebookBody>
  >({
    company: "",
    coreDockets: [],
    description: "",
    supplierRates: [],
    swatches: [],
  });
  const [showSupplierList, setShowSupplierList] = useState<
    IToggleSupplierListParams | undefined
  >(undefined);
  const [shouldShowWarning, setShouldShowWarning] = useState(false);
  const { company, setCurrentSearchText } = useDoxleCurrentContextStore(
    useShallow((state) => ({
      company: state.currentCompany,
      setCurrentSearchText: state.setCurrentSearchText,
    }))
  );
  const {
    setShowAddPricebookDialog,
    pricebookItemQueryFilter,
    pricebookRateQueryFilter,
  } = usePricebookStore(
    useShallow((state) => ({
      pricebookItemQueryFilter: state.pricebookItemQueryFilter,
      pricebookRateQueryFilter: state.pricebookRateQueryFilter,
      setShowAddPricebookDialog: state.setShowAddPricebookDialog,
    }))
  );

  const handleSelectSupplier = useCallback(
    (contact: ContactCompany) => {
      if (showSupplierList) {
        setAddPricebookData(
          produce((draft) => {
            const editedItem = draft.supplierRates[showSupplierList.rateIndex];
            if (editedItem) {
              editedItem.supplier = contact.contactCompanyId ?? null;
              editedItem.supplierName = contact.name;
            }

            return draft;
          })
        );

        if (showSupplierList.handlePostAdd) showSupplierList.handlePostAdd();
      }
    },
    [showSupplierList]
  );
  const handleAddNewSupplierRate = () => {
    const newSupplierRate: PricebookSupplierRate = {
      rateId: "",
      supplier: null,
      supplierName: "",
      pricebookItemDescription: addPricebookData.description,
      uom: "",
      unitCost: "",
      pricebookItem: "",
      company: company?.companyId,
      timeStamp: new Date().toISOString(),
      isNew: true,
    };
    setAddPricebookData(
      produce((draft) => {
        draft.supplierRates.push(newSupplierRate);
        return draft;
      })
    );
  };
  const handleSelectCoreDockets = (coreDocketId: string) => {
    setAddPricebookData(
      produce((draft) => {
        const index = draft.coreDockets.findIndex((id) => id === coreDocketId);
        if (index === -1) draft.coreDockets.push(coreDocketId);

        return draft;
      })
    );
  };
  const handleUpdateDescription = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setAddPricebookData(
      produce((draft) => {
        draft.description = event.target.value;
        return draft;
      })
    );
  };
  const onDrop = useCallback(
    <T extends File>(
      acceptedFiles: T[],
      fileRejections: FileRejection[],
      event: DropEvent
    ) => {
      setAddPricebookData(
        produce((draft) => {
          draft.swatches.push(...acceptedFiles);
        })
      );
    },
    []
  );
  const { coreDocketList } = useGetCoreDockets();

  const selectedCoreDockets: BasicCoreDocket[] = useMemo(
    () =>
      coreDocketList.filter((item) =>
        addPricebookData.coreDockets.some(
          (selectedId) => selectedId === item.coreDocketId
        )
      ),
    [coreDocketList, addPricebookData.coreDockets]
  );

  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification
  );

  const priceBookItemMutation = PricebookQueryAPI.usePricebookItemMutation({
    company: company,
    filter: pricebookItemQueryFilter,
    showNotification,
    onSuccessAddCb: (added: PricebookItem) => {
      setCurrentSearchText(added.description);
      setShowAddPricebookDialog(false);
    },
  });
  const handleAddPricebook = () => {
    let valid = true;
    if (
      !addPricebookData.description ||
      addPricebookData.supplierRates.find(
        (item) => !item.unitCost || !item.uom || !item.supplier
      )
    )
      valid = false;
    if (!valid) {
      setShouldShowWarning(true);
    } else {
      priceBookItemMutation.addPricebookItem.mutate(addPricebookData);
    }
  };
  const handleRemoveAddedSwatch = useCallback((index: number) => {
    setAddPricebookData(
      produce((draft) => {
        draft.swatches.splice(index, 1);
        return draft;
      })
    );
  }, []);
  const handleRemoveAddedCoreDocket = useCallback((index: number) => {
    setAddPricebookData(
      produce((draft) => {
        draft.coreDockets.splice(index, 1);
        return draft;
      })
    );
  }, []);
  useEffect(() => {
    if (shouldShowWarning) {
      const timeout = setTimeout(() => {
        setShouldShowWarning(false);
      }, 5000);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [shouldShowWarning]);

  const addPricebookItemContextValue: IAddPricebookItemContextValue = useMemo(
    () => ({
      addPricebookData,
      setAddPricebookData,
      setShowSupplierList,
      shouldShowWarning,
    }),
    [addPricebookData, shouldShowWarning]
  );
  return {
    addPricebookData,
    setAddPricebookData,
    showSupplierList,
    setShowSupplierList,
    addPricebookItemContextValue,
    handleAddPricebook,
    selectedCoreDockets,
    handleSelectSupplier,
    handleSelectCoreDockets,
    handleAddNewSupplierRate,
    handleUpdateDescription,
    onDrop,
    isAddingPricebook: priceBookItemMutation.addPricebookItem.isLoading,
    shouldShowWarning,
    coreDocketList,
    handleRemoveAddedSwatch,
    handleRemoveAddedCoreDocket,
  };
};

export default useAddPricebookItem;
