import {nanoid} from "nanoid";
import {DoxCircle, XY} from "../Models/takeOffs";
import {toFloat} from "../konvaFunctions/generalFunctions";
import {useMeasurementsStore} from "../MeasurementsStore/useMeasurementsStore";
import {shallow} from "zustand/shallow";

interface RectangleProps {}
const useCircle = ({}: RectangleProps) => {
  const {
    currentShape, setCurrentShape, deduction, currentColor, currentUnit, zHeight, currentSheet, currentTakeOff, addMeasurement
  } = useMeasurementsStore(state => ({
    currentShape: state.currentShape,
    setCurrentShape: state.setCurrentShape,
    deduction: state.deduction,
    currentColor: state.currentColor,
    currentUnit: state.currentUnit,
    zHeight: state.zHeight,
    currentSheet: state.currentSheet,
    currentTakeOff: state.currentTakeOff,
    addMeasurement: state.addMeasurement
  }), shallow)
  const scale: number = toFloat(currentSheet?.scale)
  const mouseMove = (pos: XY) => {
    const currentCircle = currentShape as DoxCircle;
    let radius: number = Math.sqrt(
      Math.pow(toFloat(currentCircle.start.x) - pos.x, 2) +
      Math.pow(toFloat(currentCircle.start.y) - pos.y, 2)
    );
    let rawValue: number = 0;
    let scaledValue: number = 0;
    let actualValue: number = 0;
    if (currentUnit === "LM") {
      rawValue = Math.PI * radius * 2 * deduction;
      scaledValue = rawValue * scale;
      actualValue = scaledValue;
    } else if (currentUnit === "M2") {
      rawValue = Math.PI * Math.pow(radius, 2) * deduction;
      scaledValue = rawValue * Math.pow(scale, 2);
      actualValue = scaledValue;
    } else if (currentUnit === "M3") {
      rawValue = Math.PI * Math.pow(radius, 2);
      scaledValue = rawValue * Math.pow(scale, 2);
      actualValue = scaledValue * toFloat(currentShape?.zMultiplier);
    } else return;

    setCurrentShape({
      ...currentCircle,
      radius: radius,
      rawValue: rawValue,
      scaledValue: scaledValue,
      actualValue: actualValue,
    } as DoxCircle);
  }

  const mouseUp = (pos: XY) => {
    if (!currentSheet?.sheetId) return;
    if (!currentShape) {
      setCurrentShape({
        borderColor: deduction < 0 ? "#000" : currentColor,
        bgColor: deduction < 0 ? "#000" : currentColor,
        borderThickness: 2,
        shape: "circle",
        id: nanoid(),
        rawValue: 0.0,
        zMultiplier: zHeight && currentUnit === "M3" ? zHeight : 1.0,
        scaledValue: 0.0,
        actualValue: 0.0,
        sheet: currentSheet.sheetId,
        takeOff: currentTakeOff?.takeOffId,
        start: { x: pos.x, y: pos.y },
        radius: 0.0,
      });
    } else {
      const currentCircle = currentShape as DoxCircle;
      setCurrentShape(null);
      let radius: number = Math.sqrt(
        Math.pow(toFloat(currentCircle.start.x) - pos.x, 2) +
        Math.pow(toFloat(currentCircle.start.y) - pos.y, 2)
      );
      let rawValue: number = 0;
      let scaledValue: number = 0;
      let actualValue: number = 0;
      if (currentUnit === "LM") {
        rawValue = Math.PI * radius * 2 * deduction;
        scaledValue = rawValue * scale;
        actualValue = scaledValue;
      } else if (currentUnit === "M2") {
        rawValue = Math.PI * Math.pow(radius, 2) * deduction;
        scaledValue = rawValue * Math.pow(scale, 2);
        actualValue = scaledValue;
      } else if (currentUnit === "M3") {
        rawValue = Math.PI * Math.pow(radius, 2) * deduction;
        scaledValue = rawValue * Math.pow(scale, 2);
        actualValue = scaledValue * zHeight;
      } else return;
      const finishedCircle: DoxCircle = {
        ...currentCircle,
        radius: radius,
        value: rawValue,
        scaledValue: scaledValue,
        actualValue: actualValue
      } as DoxCircle;
      addMeasurement(finishedCircle);
    }
  }

  return {mouseMove, mouseUp}
}

export default useCircle