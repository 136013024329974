import React, { useCallback, useEffect, useState } from "react";
import { shallow } from "zustand/shallow";
import { DropEvent, FileRejection } from "react-dropzone";
import { VirtuosoHandle } from "react-virtuoso";
import { useDoxleCurrentContextStore } from "../../../DoxleGeneralStore/useDoxleCurrentContext";
import useDoxleNotificationStore from "../../../DoxleGeneralStore/useDoxleNotificationStore";
import { ReplyMail } from "../../Models/mail";
import MailConversationAPI from "../../Hooks/mailConversationAPI";
import { useMailSideScreenStore } from "../MailSideScreenStore/UseMailSideScreenStore";

export interface AddedMailAttachment {
  uri: string;
  name: string;
  type: string;
}
type Props = {
  replyListRef: React.RefObject<VirtuosoHandle>;
  mailReply: ReplyMail[];
};
interface EditMailResponseInputSection {
  onDrop: <T extends File>(
    acceptedFiles: T[],
    fileRejections: FileRejection[],
    event: DropEvent
  ) => void;
  addedAttachments: File[];
  setAddedAttachments: React.Dispatch<React.SetStateAction<File[]>>;
  handleClickSendButton: () => void;
  commentText: string;
  setCommentText: React.Dispatch<React.SetStateAction<string>>;
  isAddingResponse: boolean;
  onKeyDown: (
    event:
      | React.KeyboardEvent<HTMLTextAreaElement>
      | React.KeyboardEvent<HTMLInputElement>
  ) => void;
}
const useEditMailResponseInputSection = ({
  replyListRef,
  mailReply,
}: Props): EditMailResponseInputSection => {
  const [commentText, setCommentText] = useState<string>("");
  const [addedAttachments, setAddedAttachments] = useState<File[]>([]);
  const { currentMail } = useMailSideScreenStore(
    (state) => ({
      currentMail: state.currentMail,
      resetStore: state.resetStore,
    }),
    shallow
  );
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );

  const addReply = MailConversationAPI.useSendReplyMail({
    mailId: currentMail?.mailId,
    onSuccessCb: () => {
      setCommentText("");
      setAddedAttachments([]);
      if (replyListRef.current){
        replyListRef.current.scrollToIndex({
          index: mailReply.length - 1,
          behavior: "smooth"
        });
      }
    }
  })

  // useEffect(() => {
  //   console.log(mailReply.length)
  // }, [mailReply])
  

  const onDrop = useCallback(
    <T extends File>(
      acceptedFiles: T[],
      fileRejections: FileRejection[],
      event: DropEvent
    ) => {
      setAddedAttachments([...addedAttachments, ...acceptedFiles]);
    },
    [addedAttachments]
  );
  const handleClickSendButton = () => {
    if(commentText !== ""){
      addReply.mutate({replyText: commentText, attachments: addedAttachments});
    }
  };

  const onKeyDown = (
    event:
      | React.KeyboardEvent<HTMLTextAreaElement>
      | React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.nativeEvent.key === "Enter" && !event.shiftKey) {
      handleClickSendButton();
    } else if (event.nativeEvent.key === "Escape") {
      setCommentText("");
    }
  };
  return {
    onDrop,
    addedAttachments,
    setAddedAttachments,
    handleClickSendButton,
    commentText,
    setCommentText,
    isAddingResponse: addReply.isLoading,
    onKeyDown,
  };
};

export default useEditMailResponseInputSection;
