import React, { useEffect } from "react";
import useEffectAfterMount from "../../CoreContent/UtilityHooks/useEffectAfterMount";
import { useDoxleCurrentContextStore } from "../../DoxleGeneralStore/useDoxleCurrentContext";
import { useShallow } from "zustand/react/shallow";
import useQRLogStore from "../Store/useQRLogStore";
import PermissionsAPI from "../../Services/QueryHooks/permissionsAPI";
import { useDoxleAuthStore } from "../../DoxleGeneralStore/useDoxleAuthStore";

type Props = {};

const useQR = () => {
  const { currentSearchText, company, currentProject } =
    useDoxleCurrentContextStore(
      useShallow((state) => ({
        currentSearchText: state.currentSearchText,
        company: state.currentCompany,
        currentProject: state.currentProject,
      }))
    );
  const { user } = useDoxleAuthStore(
    useShallow((state) => ({ user: state.user }))
  );
  const contactPermissionQuery =
    PermissionsAPI.useRetrieveContactsPermissionsQuery({
      company,
      filter: { user: user?.userId },
      enable: Boolean(user?.userId),
    });

  const permissionItem = contactPermissionQuery.data?.pages.flatMap(
    (page) => page.data.results
  )[0];
  const isSuperUser = permissionItem ? permissionItem.manageUsers : false;
  const { setFilterQRCode, setFilterQRLog, setFilterQRUserLog } = useQRLogStore(
    useShallow((state) => ({
      setFilterQRCode: state.setFilterQRCode,
      setFilterQRLog: state.setFilterQRLog,
      setFilterQRUserLog: state.setFilterQRUserLog,
    }))
  );

  useEffectAfterMount(() => {
    setFilterQRCode({
      search: currentSearchText,
      project: currentProject?.projectId,
    });
    setFilterQRLog({
      search: currentSearchText,
      project: currentProject?.projectId,
    });
    setFilterQRUserLog({
      search: currentSearchText,
      project: currentProject?.projectId,
    });
  }, [currentSearchText, currentProject]);

  useEffect(() => {
    if (company) {
    }
  }, [company]);

  return { isSuperUser };
};

export default useQR;
