import Dialog from "@mui/material/Dialog";
import { styled } from "styled-components";
import {
  DoxleFont,
  DoxleThemeColor,
} from "../../../../DoxleGeneralStore/useDoxleThemeStore";
import DialogContent from "@mui/material/DialogContent";
import { motion } from "framer-motion";
import { editRgbaAlpha } from "../../../../Utilities/FunctionUtilities";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import Button from "@mui/material/Button";

export const StyledQAMarkupDialog = styled(Dialog)<{
  $themeColor: DoxleThemeColor;
}>`
  && {
    background-color: rgba(0, 0, 0, 0.8);
  }
  & .MuiDialog-paper {
    background-color: ${(p) => p.$themeColor.primaryContainerColor};
    box-shadow: 0px 0px 14px 0px ${(p) => p.$themeColor.primaryBoxShadowColor};
    max-width: 100rem !important;
    min-width: 400px !important;
    width: 70vw !important;
    max-height: 100vh !important;
  }
`;
export const StyledQAMarkupDialogContent = styled(DialogContent)<{
  $themeColor: DoxleThemeColor;
}>`
  && {
    background-color: ${(p) => p.$themeColor.primaryContainerColor};

    height: 80vh !important;
    min-height: 600px;
    width: calc(100% - 1.6rem) !important;
    padding: 0.8rem !important;
    display: flex;
    flex-direction: column;
    position: relative;
  }
`;
export const StyledQAMarkupContentSection = styled.div<{
  $themeColor: DoxleThemeColor;
}>`
  width: calc(100% - 0.8rem);
  height: calc(100% - 0.8rem);
  padding: 0.4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  .stage-markup {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
`;

export const StyledQAMarkupToolBar = styled(motion.div)<{
  $themeColor: DoxleThemeColor;
}>`
  width: 80%;
  max-width: 40rem;
  display: flex;
  flex-direction: column;

  position: absolute;
  bottom: 14px;
  align-self: center;
  padding: 1.4rem;
  border-radius: 0.8rem;
  background-color: ${(p) =>
    editRgbaAlpha({ rgbaColor: p.$themeColor.doxleColor, alpha: "0.8" })};
  .main-tool-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  .sub-tool-wrapper {
    padding-top: 0.8rem;
    border-top: 1px solid ${(p) => p.$themeColor.primaryDividerColor};
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 14px;
  }
`;
interface ICustomTextFieldProps
  extends Omit<
    TextFieldProps<"standard">,
    "onDragStart" | "onAnimationStart" | "onDragEnd" | "onDrag"
  > {
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
  $bgColor: string;
  $width: number;
  $posX: number;
  $posY: number;
}
export const StyledQAMarkupTextField = styled(
  ({ $themeColor, $doxleFont, ...props }: ICustomTextFieldProps) => (
    <TextField {...props} />
  )
)`
  && {
    width: ${(p) => p.$width / 10}rem;
    padding-bottom: 0.8rem;
    border-radius: 0.4rem;
    background-color: ${(p) => p.$bgColor};
    position: absolute;
    z-index: 100;
    left: ${(p) => p.$posX}px;
    top: ${(p) => p.$posY}px;
  }
  input {
    color: ${(p) => p.$themeColor.primaryFontColor};
    font-size: 1.4rem;
    font-family: ${(p) => p.$doxleFont.primaryTitleFont};
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.65px;
    padding: 0px !important;
    &::placeholder {
      color: ${(p) => p.$themeColor.primaryFontColor};
      font-size: 1.4rem;
      font-family: ${(p) => p.$doxleFont.primaryFont};
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.65px;
      padding: 0px !important;
    }
  }
  & .MuiOutlinedInput-root {
    padding: 0px 0.8rem !important;
  }
  & .MuiOutlinedInput-input {
    padding: 0px !important;
  }
  & .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }

  & .MuiInput-root:before {
    border: none !important;
  }
  & .MuiInput-root:after {
    border: none !important;
  }
`;

export const StyledSaveQAMarkupButton = styled(motion(Button))<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  && {
    min-width: 0px !important;
    position: absolute;
    z-index: 4;
    top: 2rem;
    left: 2rem;
    border-radius: 0.4rem;
    padding: 1rem 2rem;
    font-size: 1.4rem;
    color: white;
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.65px;

    background-color: ${(p) =>
      editRgbaAlpha({ rgbaColor: p.$themeColor.doxleColor, alpha: "0.8" })};
    &:hover {
      background-color: ${(p) => p.$themeColor.doxleColor} !important;
    }
  }
`;

export const StyledProcessingLoaderWrapper = styled(motion.div)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: ${(p) =>
    editRgbaAlpha({
      rgbaColor: p.$themeColor.primaryContainerColor,
      alpha: "0.8",
    })};
  align-items: center;
  .loader-bar {
    width: 100%;
    max-width: 50rem;
  }
  .loader-text {
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-style: normal;
    font-weight: 500;
    font-size: 2rem;
    color: ${(p) => p.$themeColor.primaryFontColor};
    display: flex;
    margin-top: 8px;
  }
`;
