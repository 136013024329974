import React from "react";
import {
  editRgbaAlpha,
  formatMessageDate,
} from "../../../../Utilities/FunctionUtilities";
import { MailComments } from "../../../Models/mail";
import { useDoxleThemeStore } from "../../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import { useDoxleAuthStore } from "../../../../DoxleGeneralStore/useDoxleAuthStore";
import { StyledEmailBody, StyledInfoTitle, StyledMailInfoContainer } from "../StyledMailSideScreen";
import MailAttachmentSection from "../MailAttachmentSection";

type Props = {
  comment: MailComments;
};

const MailCommentItem = ({ comment }: Props) => {
  const { doxleThemeColor, doxleFont, currentTheme } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
      currentTheme: state.currentTheme,
    }),
    shallow
  );
  
  const user = useDoxleAuthStore((state) => state.user, shallow);
  const userName = user?.firstName + " " + user?.lastName;

  return (
    <StyledMailInfoContainer $width="100%" style={{ marginTop: "1rem" }}>
      <StyledInfoTitle $themeColor={doxleThemeColor}>
        {comment.fromString} WROTE ON {formatMessageDate(comment.timeStamp)}
      </StyledInfoTitle>
      <StyledEmailBody
        $currentTheme={currentTheme}
        $themeColor={doxleThemeColor}
        style={{
          backgroundColor:
            userName === comment.fromString
              ? editRgbaAlpha({
                  rgbaColor: doxleThemeColor.doxleColor,
                  alpha: "0.1",
                })
              : "",
        }}
      >
        {comment.textBody}
        <MailAttachmentSection mailAttachment={comment.attachments} isReply />
      </StyledEmailBody>
    </StyledMailInfoContainer>
  );
};

export default MailCommentItem