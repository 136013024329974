import React from "react";
import { usePricebookStore } from "../../../Store/usePricebookStore";
import { PricebookSwatch } from "../../../Models/Pricebook";
import { useShallow } from "zustand/react/shallow";
import { useDoxleCurrentContextStore } from "../../../../DoxleGeneralStore/useDoxleCurrentContext";
import useDoxleNotificationStore from "../../../../DoxleGeneralStore/useDoxleNotificationStore";
import useSetPricebookQueryData from "../../../QueryHooks/useSetPricebookQueryData";
import PricebookQueryAPI from "../../../QueryHooks/PricebookQueryAPI";
import { useEditPricebookItemContext } from "../EditPricebookItem";

type Props = {
  item: PricebookSwatch;
};

interface IPricebookSwatchItem {
  handleDeleteSwatch: () => void;
  isDeletingSwatch: boolean;
}
const usePricebookSwatchItem = ({ item }: Props): IPricebookSwatchItem => {
  const { filter } = usePricebookStore(
    useShallow((state) => ({
      filter: state.pricebookItemQueryFilter,
    }))
  );
  const { pricebookId } = useEditPricebookItemContext();
  const company = useDoxleCurrentContextStore(
    useShallow((state) => state.currentCompany)
  );
  //Show Notification Function
  const showNotification = useDoxleNotificationStore(
    useShallow((state) => state.showNotification)
  );

  const { handleRemovePricebookItemImage } = useSetPricebookQueryData({
    itemId: pricebookId,
  });

  const swatchMutation = PricebookQueryAPI.useSwatchMutationQuery({
    company,
    showNotification,
    filter,
    onSuccessRemoveCb: (swatchId: string) => {
      handleRemovePricebookItemImage(swatchId);
    },
  });

  const handleDeleteSwatch = () => {
    swatchMutation.deleteSwatch.mutate(item.swatchId);
  };
  return {
    handleDeleteSwatch,
    isDeletingSwatch: swatchMutation.deleteSwatch.isLoading,
  };
};

export default usePricebookSwatchItem;
