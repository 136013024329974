import React, { useRef } from "react";
import { StyledDoxleGridLayoutContainer } from "./StyledComponentDoxleGridLayout";
import { useDoxleGridLayout } from "./Hooks/useDoxleGridLayout";
import { useResizeLayout } from "./Hooks/useResizeLayout";
import VirtualListRenderer from "./VirtualListRenderer";
import DisplayGrid from "./DisplayGrid";

type DoxleGridLayoutProps<TItem> = {
  containerStyle?: React.CSSProperties;
  data: TItem[];
  renderItem: (item: TItem, itemIndex: number) => JSX.Element; //!PASS THE ITEM COMPONENT STYLE IN HERE, IT'S A CB FUNCTION WILL PASS ITEM DATA IN AND
  extractItemKeyProp: keyof TItem; //! PASS THE ID OF ITEM HERE, IT SHOULD BE UNIQUE
};

function DoxleGridLayout<TItem>({
  containerStyle,
  data,
  renderItem,
  extractItemKeyProp,
}: DoxleGridLayoutProps<TItem>) {
  const rootContainerRef = useRef<HTMLDivElement>(null);
  const {} = useDoxleGridLayout({ data, extractItemKeyProp });
  const {} = useResizeLayout({ rootContainerRef });

  return (
    <StyledDoxleGridLayoutContainer
      // $width={containerStyle?.width || "100%"}
      // $height={containerStyle?.height || "100%"}
      ref={rootContainerRef}
      style={{
        ...containerStyle,
        width: containerStyle ? containerStyle.width ?? "100%" : "100%",
        height: containerStyle ? containerStyle.height ?? "100%" : "100%",
        display: "flex",
        flexDirection: "row",

        overflow: "hidden",
        justifyContent: "center",
        position: "relative",
      }}
    >
      {/* <RootContainerResizeListener onResize={handleResizeRootContainer} /> */}

      <DisplayGrid renderItem={renderItem} />
      <VirtualListRenderer data={data} renderItem={renderItem} />
      {/* 
      {data.map((item, index) => renderItem(item, index))} */}
    </StyledDoxleGridLayoutContainer>
  );
}

export default DoxleGridLayout;
