import DrawingsQueryAPI from "./DrawingsQueryAPI";
import { useMeasurementsStore } from "../MeasurementsStore/useMeasurementsStore";
import { shallow } from "zustand/shallow";
import { DrawingSet, TakeOff } from "../Models/takeOffs";
import TakeOffQueryAPI from "./TakeOffQueryAPI";
import { useEffect, useMemo } from "react";
import { useDoxleCurrentContextStore } from "../../DoxleGeneralStore/useDoxleCurrentContext";
import { useProjectStore } from "../../DoxleGeneralStore/useprojectStore";
import useGetCompanyList from "../../CoreContent/QueryDataHooks/GetQueryDataHooks/useGetCompanyList";
interface Props {
  takeOffId?: string;
}
const useMeasurements = ({ takeOffId }: Props) => {
  const { company, setCompany, project, setProject } =
    useDoxleCurrentContextStore(
      (state) => ({
        company: state.currentCompany,
        setCompany: state.setCurrentCompany,

        project: state.currentProject,
        setProject: state.setCurrentProject,
      }),
      shallow
    );
  const { companyList } = useGetCompanyList({});
  const projectList = useProjectStore((state) => state.projectList, shallow);
  const setSheet = useMeasurementsStore(
    (state) => state.setCurrentSheet,
    shallow
  );
  const urlArray = window.location.href.split("/");
  console.log("urlArray", urlArray);
  let urlId: string | undefined = urlArray?.[urlArray.indexOf("TakeOff") + 1];
  console.log("urlId", urlId);
  console.log("urlId", urlId?.length === 36 ? urlId : undefined);
  const takeOffQuery = TakeOffQueryAPI.useRetrieveTakeOffDetail(
    takeOffId ? takeOffId : urlId?.length === 36 ? urlId : undefined
  );
  const takeOff: TakeOff | undefined = useMemo(() => {
    if (!takeOffQuery.isSuccess) return undefined;
    return takeOffQuery.data?.data as TakeOff;
  }, [takeOffQuery]);
  if (takeOff?.company && takeOff.company !== company?.companyId)
    setCompany(
      companyList.filter(
        (company) => company.companyId === takeOff?.company
      )?.[0]
    );
  if (takeOff?.project && takeOff?.project !== project?.projectId)
    setProject(
      projectList.filter(
        (project) => project.projectId === takeOff?.project
      )?.[0]
    );

  const drawingsQuery = DrawingsQueryAPI.useRetrieveDrawingsList({
    enabled: Boolean(takeOff?.measurements || takeOffQuery.isSuccess),
  });

  const drawingsList: DrawingSet[] = useMemo(() => {
    if (!drawingsQuery.isSuccess) return [];
    return drawingsQuery.data?.data as DrawingSet[];
  }, [drawingsQuery]);

  return {
    takeOffQuery,
    drawingsQuery,
    drawingsList,
    takeOff,
  };
};
export default useMeasurements;
