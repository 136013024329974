import { motion } from "framer-motion";
import { styled } from "styled-components";
import { DoxleThemeColor } from "../../DoxleGeneralStore/useDoxleThemeStore";

export const StyledDoxleListSkeletonContainer = styled(motion.div)<{
  $themeColor: DoxleThemeColor;
  $widthInPixel?: `${number}px`;
  $widthInRatio?: `${number}%`;
  $heightInPixel?: `${number}px`;
  $heightInRatio?: `${number}%`;
  $bgColor?: string;
}>`
  justify-content: center;
  align-items: center;

  flex-direction: column;
`;
export const StyledCommentSkeletonRowContainer = styled.div<{
  $marginVertical: number;
  $rowHeight: number;
}>`
  width: calc(100% - 16px);
  margin: ${(p) => p.$marginVertical}px 8px;
  display: flex;
  flex-direction: column;
  height: ${(p) => p.$rowHeight}px;
  align-items: flex-start;
`;
export const StyledListScroller = styled.div`
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;
export const StyledDocketListSkeletonRowContainer = styled.div<{
  $marginVertical: number;
  $rowHeight: number;
  $themeColor: DoxleThemeColor;
}>`
  width: calc(100%);
  margin: ${(p) => p.$marginVertical / 10}rem 0rem;
  display: flex;
  flex-direction: row;
  height: ${(p) => p.$rowHeight / 10}rem;
  align-items: center;
  border-bottom: 1px solid ${(p) => p.$themeColor.primaryDividerColor};
`;
export const StyledScopeOfWorkSkeletonRowContainer = styled.div<{
  $marginVertical: number;
  $rowHeight: number;
}>`
  width: calc(100% - 16px);
  margin: ${(p) => p.$marginVertical}px 8px;
  display: flex;
  flex-direction: column;
  height: ${(p) => p.$rowHeight}px;
  align-items: flex-start;
`;
export const StyledRFISkeletonRowContainer = styled.div`
  width: 100%;
  margin-bottom: 60px;
  display: flex;
  flex-direction: column;

  align-items: flex-start;
`;
export const StyledMailListSkeletonRowContainer = styled.div<{
  $marginVertical: number;
  $rowHeight: number;
  $themeColor: DoxleThemeColor;
}>`
  width: calc(100% - 1.6rem);
  margin: ${(p) => p.$marginVertical / 10}rem 0.8rem;
  display: flex;
  flex-direction: column;
  height: 6rem;
  max-width: 100rem;
  //align-items: flex-start;
  //background-color: ${(p) => p.$themeColor.primaryContainerColor};
  border-bottom: 1px solid ${(p) => p.$themeColor.rowBorderColor};
  padding-top: 2rem;
`;
export const StyledInventoryListSkeletonRowContainer = styled.div<{
  $marginVertical: number;
  $rowHeight: number;
  $themeColor: DoxleThemeColor;
}>`
  width: calc(100% - 1.6rem);
  margin: ${(p) => p.$marginVertical / 10}rem 0.8rem;
  display: flex;
  flex-direction: column;
  height: 25.1rem;
  max-width: 100rem;
  border: 2px solid ${(p) => p.$themeColor.primaryDividerColor};
  border-radius: 3px;
  padding-top: 1rem;
  padding-bottom: 10px;
`;

export const StyledNoteSkeletonRow = styled.div<{
  $themeColor: DoxleThemeColor;
}>`
  width: calc(100% - 28px);
  height: 44px;
  padding: 0px 14px;
  border-bottom: 1px solid ${(p) => p.$themeColor.primaryDividerColor};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const StyledNoteLineSkeletonRow = styled.div<{
  $themeColor: DoxleThemeColor;
}>`
  height: 44px;
  width: auto;
  align-self: flex-start;
  border-bottom: 1px solid ${(p) => p.$themeColor.primaryDividerColor};
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;
export const StyledNoteListScroller = styled.div`
  width: 100%;
  height: 100%;

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;
export const StyledProjectSkeletonRow = styled.div<{
  $themeColor: DoxleThemeColor;
}>`
  height: 20px;
  width: 100%;
  align-self: flex-start;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 4px;
`;
