import React, { useCallback, useState } from "react";
import { Contact } from "../../../../../Models/addressBook";
import { useProjectQAStore } from "../../../Store/useProjectQAStore";
import { shallow } from "zustand/shallow";
import { useDoxleCurrentContextStore } from "../../../../../DoxleGeneralStore/useDoxleCurrentContext";
import QAQueryAPI from "../../../../QueryAPI/qaQueryAPI";
import useDoxleNotificationStore from "../../../../../DoxleGeneralStore/useDoxleNotificationStore";
import {  QAWithFirstImg } from "../../../../Models/qa";
import {Room} from "../../../../Models/locations";

type Props = {};

interface EditQaRoom {
  anchorRoomList: HTMLElement | null;
  setAnchorRoomList: React.Dispatch<
    React.SetStateAction<HTMLElement | null>
  >;
  handleUpdateRoom: (selectedRoom: Room | undefined) => void;
  handleCloseRoomList: () => void;
  isUpdatingRoom: boolean;
  isHovering: boolean;
  setIsHovering: React.Dispatch<React.SetStateAction<boolean>>;
}
const useEditQaRoom = (props: Props): EditQaRoom => {
  const [isHovering, setIsHovering] = useState(false);
  const [anchorRoomList, setAnchorRoomList] =
    useState<HTMLElement | null>(null);
  const { selectedQAItem, updateSelectedQAItem } = useProjectQAStore(
    (state) => ({
      selectedQAItem: state.selectedQAItem,
      updateSelectedQAItem: state.updateSelectedQAItem,
    }),
    shallow
  );

  const company = useDoxleCurrentContextStore(
    (state) => state.currentCompany,
    shallow
  );
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );

  const onUpdateSuccess = (newQA?: QAWithFirstImg) => {
    if (newQA)
      updateSelectedQAItem({
        room: newQA.room,
        roomName: newQA.roomName,
        floor: newQA.floor,
        floorName: newQA.floorName,
      });
  };
  const updateQAQuery = QAQueryAPI.useUpdateQAQuery({
    company,
    qaItem: selectedQAItem!,
    showNotification,
    onSuccessCB: onUpdateSuccess,
  });

  const handleUpdateRoom = useCallback(
    (selectedRoom: Room | undefined) => {
      if (selectedRoom)
        updateQAQuery.mutate({
          room: selectedRoom.roomId,
        });
      else updateQAQuery.mutate({ room: null });

      setAnchorRoomList(null);
    },
    []
  );
  const handleCloseRoomList = useCallback(() => {
    setAnchorRoomList(null);
  }, []);
  return {
    anchorRoomList,
    setAnchorRoomList,
    handleUpdateRoom,
    handleCloseRoomList,
    isUpdatingRoom: updateQAQuery.isLoading,
    isHovering,
    setIsHovering,
  };
};

export default useEditQaRoom;
