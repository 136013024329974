import React from "react";
import { useNavigate } from "react-router-dom";
import { MainAppBanner } from "./svg/mainBanner";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import {
  AboutBtn,
  ButtonArea,
  ImageText,
  JoinBtn,
  MainPageContainer,
  MainPageLeft,
  MainPageRight,
  StyledBannerContainer,
  StyledDescription,
  StyledPurple,
  StyledTitle,
} from "./styles/StyledMainPage";

export default function Main() {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }),
    shallow
  );

  const navigate = useNavigate();

  return (
    <MainPageContainer>
      <MainPageLeft>
        <StyledBannerContainer>
          <MainAppBanner themeColor={doxleThemeColor} />
          <ImageText $themeColor={doxleThemeColor}>
            be the person your dog thinks you are
          </ImageText>
        </StyledBannerContainer>
      </MainPageLeft>
      <MainPageRight>
        <StyledTitle $themeColor={doxleThemeColor}>
          Build better with{" "}
          <StyledPurple $themeColor={doxleThemeColor}>Doxle</StyledPurple>
        </StyledTitle>
        <StyledDescription $themeColor={doxleThemeColor} $doxleFont={doxleFont}>
          With the state of the art machine learning algorithms, we aim to
          provide transparent measurements and costings to help you budget your
          builds. We are currently at beta launch experimenting with builders.
          Click join to sign up to the waitlist to get early access.
          <ButtonArea>
            <JoinBtn
              $themeColor={doxleThemeColor}
              onClick={() => navigate("/Join/")}
            >
              Join
            </JoinBtn>
            <AboutBtn
              $themeColor={doxleThemeColor}
              onClick={() => {
                if (window.location.hostname.includes("doxle"))
                  window.open("https://login.doxle.com/", "_self");
                else navigate("/HarryLogin/");
              }}
            >
              Login
            </AboutBtn>
          </ButtonArea>
        </StyledDescription>
      </MainPageRight>
    </MainPageContainer>
  );
}
