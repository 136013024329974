import React, { useRef, useState } from "react";
import { useProjectQAStore } from "../../../Store/useProjectQAStore";
import { shallow } from "zustand/shallow";
import useDoxleNotificationStore from "../../../../../DoxleGeneralStore/useDoxleNotificationStore";
import { useDoxleAuthStore } from "../../../../../DoxleGeneralStore/useDoxleAuthStore";
import { useDoxleCurrentContextStore } from "../../../../../DoxleGeneralStore/useDoxleCurrentContext";
import QAQueryAPI from "../../../../QueryAPI/qaQueryAPI";
import useSetQAListQueryData from "../../../../CustomQueryHooks/useSetQAListQueryData";
import { QAList } from "../../../../Models/qa";

type Props = {};

interface EditQAListTitle {
  newQAListTitleText: string;
  setNewQAListTitleText: React.Dispatch<React.SetStateAction<string>>;
  handleKeydownInput: (e: React.KeyboardEvent<HTMLDivElement>) => void;
  inputRef: React.RefObject<HTMLInputElement>;
  handleUpdateQAListTitle: () => void;
  isUpdatingTitle: boolean;
}
const useEditQAListTitle = (props: Props): EditQAListTitle => {
  const { edittedQAListItem, filterQAListQuery } = useProjectQAStore(
    (state) => ({
      edittedQAListItem: state.edittedQAListItem,
      filterQAListQuery: state.filterQAListQuery,
    }),
    shallow
  );
  const [newQAListTitleText, setNewQAListTitleText] = useState(
    edittedQAListItem?.defectListTitle ?? ""
  );

  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );
  const { company } = useDoxleCurrentContextStore(
    (state) => ({
      company: state.currentCompany,
    }),
    shallow
  );
  const { handleEditQAList } = useSetQAListQueryData({
    filter: filterQAListQuery,
  });
  const onErrorUpdate = () => {
    setNewQAListTitleText(edittedQAListItem?.defectListTitle ?? "");
  };

  const onUpdateSuccessCb = (newQAList?: QAList) => {
    if (newQAList) handleEditQAList(newQAList);
  };
  const updateQAListQuery = QAQueryAPI.useUpdateQAListQuery({
    company,
    showNotification,
    onSuccessCB: onUpdateSuccessCb,
    onErrorCb: onErrorUpdate,
  });

  const inputRef = useRef<HTMLInputElement>(null);
  const handleKeydownInput = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.nativeEvent.key === "Enter") {
      e.preventDefault();
      inputRef.current?.blur();
    } else if (e.nativeEvent.key === "Escape") {
      setNewQAListTitleText("");
    }
  };

  const handleUpdateQAListTitle = () => {
    if (edittedQAListItem) {
      if (newQAListTitleText) {
        if (newQAListTitleText !== edittedQAListItem?.defectListTitle)
          updateQAListQuery.mutate({
            qaList: edittedQAListItem,
            updateParams: { defectListTitle: newQAListTitleText },
          });
      } else
        updateQAListQuery.mutate({
          qaList: edittedQAListItem,
          updateParams: { defectListTitle: "Untitled QA List" },
        });
    }
  };
  return {
    newQAListTitleText,
    setNewQAListTitleText,
    handleKeydownInput,
    inputRef,
    handleUpdateQAListTitle,
    isUpdatingTitle: updateQAListQuery.isLoading,
  };
};

export default useEditQAListTitle;
