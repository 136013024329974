import AddBookingDocketAndDate from "./AddBookingDocketAndDate";
import AddBookingPercentage from "./AddBookingPercentage";
import AddBookingTitle from "./AddBookingTitle";

type Props = {};

const AddBookingInputSection = ({}: Props) => {
  return (
    <>
      <AddBookingTitle />
      <AddBookingDocketAndDate />
      <AddBookingPercentage />
    </>
  );
};

export default AddBookingInputSection;
