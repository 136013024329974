import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  ContactCompanyFilters,
  ContactsFilters,
} from "../../../Services/QueryHooks/contactsFilters";
import { useDoxleCurrentContextStore } from "../../../DoxleGeneralStore/useDoxleCurrentContext";
import { shallow } from "zustand/shallow";
import ContactsAPI from "../../../Services/QueryHooks/contactsAPI";
import { Contact, ContactCompany } from "../../../Models/addressBook";

type Props = {
  variants?: TSelectContactMode;
  onSelectContact?: (item: Contact) => void;
  onSelectContactCompany?: (item: ContactCompany) => void;
  onClosePopover?: () => void;
  closeOnSelect?: boolean;
};

export type TSelectContactMode = "company" | "contact" | "both";
interface DoxleSelectContactDropdown {
  contactMode: Exclude<TSelectContactMode, "both">;
  setContactMode: React.Dispatch<
    React.SetStateAction<Exclude<TSelectContactMode, "both">>
  >;
  showAddContactForm: boolean;
  setShowAddContactForm: React.Dispatch<React.SetStateAction<boolean>>;
  showContactList: boolean;
  setShowContactList: React.Dispatch<React.SetStateAction<boolean>>;

  companiesList: ContactCompany[];

  isFetchingCompanyList: boolean;
  isSuccessFetchingCompanyList: boolean;
  isErrorFetchingCompanyList: boolean;
  searchText: string;
  setSearchText: React.Dispatch<React.SetStateAction<string>>;
  handleFetchNextPageCompany: () => void;
  isFetchingNextPageCompanyList: boolean;

  contactList: Contact[];

  isFetchingContactList: boolean;
  isSuccessFetchingContactList: boolean;
  isErrorFetchingContactList: boolean;

  handleFetchNextPageContact: () => void;
  isFetchingNextPageContactList: boolean;

  handleSelectCompany: (contractor: ContactCompany) => void;
  handleSelectContact: (contractor: Contact) => void;
  handleFetchNextPage: () => void;
}
const useDoxleSelectContactDropdown = ({
  variants,
  onSelectContact,
  onSelectContactCompany,
  onClosePopover,
  closeOnSelect,
}: Props): DoxleSelectContactDropdown => {
  const [contactMode, setContactMode] = useState<
    Exclude<TSelectContactMode, "both">
  >(variants ? (variants !== "both" ? variants : "contact") : "contact");
  const [showAddContactForm, setShowAddContactForm] = useState(false);
  const [showContactList, setShowContactList] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [filterContactCompany, setfilterContactCompany] =
    useState<ContactCompanyFilters>({});
  const [filterContact, setfilterContact] = useState<ContactsFilters>({});
  const company = useDoxleCurrentContextStore(
    (state) => state.currentCompany,
    shallow
  );

  const retrieveContactCompanyQuery =
    ContactsAPI.useRetrieveContactsCompanyQuery({
      filter: filterContactCompany,

      enable: Boolean(variants === "both" || variants === "company"),

      company,
    });
  const companiesList = useMemo(
    () =>
      retrieveContactCompanyQuery.isSuccess
        ? retrieveContactCompanyQuery.data.pages.flatMap(
            (page) => (page.data.results as ContactCompany[]) ?? []
          ) ?? []
        : [],
    [retrieveContactCompanyQuery.data]
  );
  const handleFetchNextPageCompany = () => {
    if (retrieveContactCompanyQuery.hasNextPage)
      retrieveContactCompanyQuery.fetchNextPage();
  };

  const retrieveContactQuery = ContactsAPI.useRetrieveContactsQuery({
    filter: filterContact,

    enable: Boolean(variants === "both" || variants === "contact"),

    company,
  });
  const contactList = useMemo(
    () =>
      retrieveContactQuery.isSuccess
        ? retrieveContactQuery.data.pages.flatMap(
            (page) => page.data.results ?? []
          ) ?? []
        : [],
    [retrieveContactQuery.data]
  );
  const handleFetchNextPageContact = () => {
    if (retrieveContactQuery.hasNextPage) retrieveContactQuery.fetchNextPage();
  };

  const handleSelectCompany = useCallback(
    (contractor: ContactCompany) => {
      if (onSelectContactCompany) onSelectContactCompany(contractor);
      if (closeOnSelect && onClosePopover) onClosePopover();
    },
    [onSelectContactCompany, onClosePopover, closeOnSelect]
  );

  const handleSelectContact = useCallback(
    (contractor: Contact) => {
      if (onSelectContact) onSelectContact(contractor);
      if (closeOnSelect && onClosePopover) onClosePopover();
    },
    [onSelectContact, onClosePopover, closeOnSelect]
  );

  const handleFetchNextPage = () => {
    if (contactMode === "company") handleFetchNextPageCompany();
    else handleFetchNextPageContact();
  };
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (searchText) {
        setfilterContact({ search: searchText });
        setfilterContactCompany({ search: searchText });
      } else {
        setfilterContact({});
        setfilterContactCompany({});
      }
    }, 300);

    return () => clearTimeout(timeout);
  }, [searchText]);
  return {
    contactMode,
    setContactMode,
    showAddContactForm,
    setShowAddContactForm,
    showContactList,
    setShowContactList,
    companiesList,
    isFetchingCompanyList: retrieveContactCompanyQuery.isLoading,
    isSuccessFetchingCompanyList: retrieveContactCompanyQuery.isSuccess,
    isErrorFetchingCompanyList: retrieveContactCompanyQuery.isError,
    searchText,
    setSearchText,
    handleFetchNextPageCompany,
    isFetchingNextPageCompanyList:
      retrieveContactCompanyQuery.isFetchingNextPage,

    contactList,
    isFetchingContactList: retrieveContactQuery.isLoading,
    isSuccessFetchingContactList: retrieveContactQuery.isSuccess,
    isErrorFetchingContactList: retrieveContactQuery.isError,
    handleFetchNextPageContact,
    isFetchingNextPageContactList: retrieveContactQuery.isFetchingNextPage,
    handleSelectCompany,
    handleSelectContact,
    handleFetchNextPage,
  };
};

export default useDoxleSelectContactDropdown;
