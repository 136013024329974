import React, { useEffect, useMemo, useState } from "react";
import { useDoxleCurrentContextStore } from "../../../DoxleGeneralStore/useDoxleCurrentContext";
import { useShallow } from "zustand/react/shallow";
import {
  Docket,
  IFullDocketDetailQueryFilterProp,
} from "../../../Models/dockets";
import DocketQuery from "../../../Services/QueryHooks/docketQueryAPI";
import { Variants } from "framer-motion";
import { useEditBookingContext } from "../Components/EditBookingDialog";
import { produce } from "immer";

type Props = {};

const useEditBookingDocketSection = () => {
  const [searchDocketText, setSearchDocketText] = useState<string>("");
  const [showList, setShowList] = useState(false);
  const [anchorPopover, setAnchorPopover] = useState<HTMLElement | null>(null);
  const { company, currentProject } = useDoxleCurrentContextStore(
    useShallow((state) => ({
      company: state.currentCompany,
      currentProject: state.currentProject,
    }))
  );
  const { shouldShowError, editBookingData, setEditBookingData } =
    useEditBookingContext();
  const filterDocketList: IFullDocketDetailQueryFilterProp = useMemo(
    () => ({
      project: currentProject?.projectId,
      search: searchDocketText,
      view: "budget",
    }),
    [currentProject, searchDocketText]
  );
  const docketListQuery = DocketQuery.useRetrieveFullDetailDocketList({
    filter: filterDocketList,
    company,
  });

  const docketList = useMemo(
    () =>
      docketListQuery.data?.pages.flatMap(
        (page) => (page?.data?.results as Docket[]) ?? []
      ) ?? [],
    [docketListQuery.data]
  );

  const handleFetchNextPageDocket = () => {
    if (docketListQuery.hasNextPage) docketListQuery.fetchNextPage();
  };

  const refetchdocketList = () => {
    if (currentProject) docketListQuery.refetch();
  };

  const onClosePopover = () => {
    setAnchorPopover(null);
  };
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (!docketListQuery.isLoading) {
        if (anchorPopover) setShowList(true);
        else {
          setShowList(false);
          setSearchDocketText("");
        }
      } else {
        setShowList(false);
      }
    }, 400);

    return () => {
      clearTimeout(timeout);
    };
  }, [anchorPopover, docketListQuery.isLoading]);
  const paperAnimatedVariants: Variants = {
    entering: {
      y: [-10, 0],
      scale: [0, 1],
      opacity: [0, 1],
      transition: {
        duration: 0.4,
      },
    },
    exiting: {
      y: [0, -10],
      scale: [1, 0],
      opacity: [1, 0],
      transition: {
        duration: 0.4,
      },
    },
  };

  const onSelectDocket = (docket: Docket) => {
    setEditBookingData(
      produce((draft) => {
        if (draft) {
          draft.docket = docket.docketPk;
          draft.docketName = docket.docketName;
        }
        return draft;
      })
    );
    setAnchorPopover(null);
  };

  return {
    docketList,

    handleFetchNextPageDocket,
    isFetchingDocketList: docketListQuery.isLoading,
    isSuccessFetchingDocketList: docketListQuery.isSuccess,
    isErrorFetchingDocketList: docketListQuery.isError,
    isFetchingNextPageDocket: docketListQuery.isFetchingNextPage,
    refetchdocketList,
    isRefetchingDocketList: docketListQuery.isRefetching,
    setSearchDocketText,
    searchDocketText,
    showList,
    anchorPopover,
    setAnchorPopover,
    onClosePopover,
    paperAnimatedVariants,
    onSelectDocket,
  };
};

export default useEditBookingDocketSection;
