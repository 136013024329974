import PaymentClaim from "../../../Models/PaymentClaim";
import DoxleNavigateBackButton from "../../../../DoxleDesignPattern/DoxleButtons/DoxleNavigateBackButton";
import {IoChevronBackSharp} from "react-icons/io5";
import React, { useEffect, useMemo} from "react";
import {useDoxleThemeStore} from "../../../../DoxleGeneralStore/useDoxleThemeStore";
import {shallow} from "zustand/shallow";
import {usePaymentClaimStore} from "../../../OrdersStore/usePaymentClaimStore";
import {AccountsQueryAPI} from "../../../../Services/QueryHooks/AccountsQueryAPI";
import EditOrderGeneralInfo from "../../EditOrderSideScreen/EditOrderGeneralInfo";
import EditOrderLineTable from "../../EditOrderSideScreen/EditOrderLineTable";
import {useEditOrderStore} from "../../../OrdersStore/useEditOrderStore";
import {StyledEditOrderContainer} from "../../EditOrder/StyledComponentEditOrder";
import {
  useEditDocketSideScreenStore
} from "../../../../CoreContent/EditDocketSideScreen/EditDocketSideScreenStore/useEditDocketSideScreenStore";
import XeroAttachment from "./XeroAttachments";
import {StyledXeroAttachmentContainer} from "./StyledComponentReviewPaymentClaim";
import {useDoxleCurrentContextStore} from "../../../../DoxleGeneralStore/useDoxleCurrentContext";

interface Props {claim: PaymentClaim}
const ViewXeroBill = ({claim}: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(state => ({
    doxleThemeColor: state.doxleThemeColor,
    doxleFont: state.doxleFont,
  }),  shallow);
  const projectId = useDoxleCurrentContextStore(state => state.currentProject?.projectId, shallow);
  const docketPk = useEditDocketSideScreenStore(state => state.edittedDocket?.docketPk, shallow);
  const setClaim  = usePaymentClaimStore(state => state.setReviewPaymentClaim, shallow);
  const {setEditedOrder, setReadOnly} = useEditOrderStore(state => ({
    setEditedOrder: state.setEditedOrder,
    setReadOnly: state.setReadOnly
  }), shallow)
  const xeroBillDetailQuery = AccountsQueryAPI.useRetrieveBillDetail({
    paymentClaimId: claim.paymentId,
    projectId,
    docketPk,
  })
  const orderNumber: string|undefined = xeroBillDetailQuery?.data?.data?.orderNumber
  const orderUrl: string|undefined = xeroBillDetailQuery?.data?.data?.orderUrl

  const handleUrlClick = () => {
    if (orderUrl) window.open(orderUrl, "_blank")
  }

  const xeroAttachments = useMemo(() => {
    if (xeroBillDetailQuery.isSuccess) return  xeroBillDetailQuery.data.data?.attachments ?? []
    else return []
  },[xeroBillDetailQuery.isSuccess, xeroBillDetailQuery.data])

  const { setHandleCloseButtonFn, setHandleAddButtonFn } =
    useEditDocketSideScreenStore(
      (state) => ({
        setHandleCloseButtonFn: state.setHandleCloseButtonFn,
        setHandleAddButtonFn: state.setHandleAddButtonFn,
      }),
      shallow
    );

  useEffect(()=> {
    if (xeroBillDetailQuery.isSuccess) {
      setEditedOrder(xeroBillDetailQuery.data.data);
      setReadOnly(true);
    }
  }, [xeroBillDetailQuery.isSuccess, xeroBillDetailQuery.data])

  useEffect(() => {
    setHandleAddButtonFn([])
    setHandleCloseButtonFn({
      btnFunction: () => {
        setEditedOrder(undefined);
        setReadOnly(false);
        setClaim(undefined)
      },
      btnText: "Close",
    });
    return () => setHandleCloseButtonFn(undefined);
  }, []);

  const rootVariants = {
    initial: {
      x: -10,
      opacity: 0,
    },
    entering: {
      x: 0,
      opacity: 1,
    },
  };

  return (
    <StyledEditOrderContainer
      variants={rootVariants}
      initial="initial"
      animate="entering"
      $themeColor={doxleThemeColor}
      $doxleFont={doxleFont}
    >
      <div onClick={handleUrlClick} className={orderUrl ? "title-edit-order active-link" : "title-edit-order"}>
        Xero Bill - {orderNumber}
      </div>
      <EditOrderGeneralInfo />
      <StyledXeroAttachmentContainer>
        {xeroAttachments.map((attachment: any, i:number) =>(
          <XeroAttachment attachment={attachment} i={i} />
        ))}
      </StyledXeroAttachmentContainer>
      <EditOrderLineTable />
    </StyledEditOrderContainer>
  );
}
export default ViewXeroBill
