import { SVGMotionProps, motion } from "framer-motion";
import { DoxleThemeColor } from "../../DoxleGeneralStore/useDoxleThemeStore";
import { TRgbaFormat, editRgbaAlpha } from "../../Utilities/FunctionUtilities";

export const DeleteScopeGroupIcon = (props: DoxleThemeColor) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={14} height={16} fill="none">
    <path
      fill={props.bolderDividerColor}
      d="M5.526 2.938v.187h2.948v-.188c0-.828-.66-1.5-1.474-1.5s-1.474.672-1.474 1.5Zm-.92.187v-.188C4.605 1.591 5.676.5 7 .5c1.323 0 2.395 1.091 2.395 2.438v.187h4.145c.254 0 .46.21.46.469a.465.465 0 0 1-.46.469h-1.075l-.737 8.945c-.116 1.409-1.273 2.492-2.662 2.492H4.934c-1.389 0-2.546-1.083-2.662-2.492l-.737-8.945H.461A.465.465 0 0 1 0 3.593c0-.258.206-.468.46-.468h4.145ZM3.19 12.93a1.763 1.763 0 0 0 1.744 1.633h4.132c.91 0 1.668-.71 1.744-1.633l.73-8.867H2.46l.73 8.867ZM6.08 6.594a.465.465 0 0 0-.46-.469c-.255 0-.461.21-.461.469v5.437c0 .26.206.469.46.469.255 0 .46-.21.46-.469V6.594Zm2.303-.469c.254 0 .46.21.46.469v5.437a.465.465 0 0 1-.46.469.465.465 0 0 1-.46-.469V6.594c0-.26.205-.469.46-.469Z"
    />
  </svg>
);
export const UncheckedScopeItemCheckbox = (props: DoxleThemeColor) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={14} height={14} fill="none">
    <rect
      width={11.5}
      height={11.5}
      x={1.25}
      y={1.25}
      stroke={props.doxleColor}
      strokeWidth={2.5}
      rx={4.75}
    />
  </svg>
);

export const CheckedScopeItemCheckbox = (props: DoxleThemeColor) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={14} height={14} fill="none">
    <rect
      width={11.5}
      height={11.5}
      x={1.25}
      y={1.25}
      fill={props.doxleColor}
      stroke={props.doxleColor}
      strokeWidth={2.5}
      rx={4.75}
    />
    <path
      fill={"#fff"}
      d="M12.85 3.143c.202.188.2.492-.005.678l-7.739 7.04a.555.555 0 0 1-.73.003l-3.218-2.88a.453.453 0 0 1-.01-.679c.2-.19.531-.194.738-.009l2.85 2.552 7.375-6.71a.554.554 0 0 1 .738.005Z"
    />
  </svg>
);
export const DeleteScopeItemIcon = (props: DoxleThemeColor) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={14} height={15} fill="none">
    <path
      fill={editRgbaAlpha({
        rgbaColor: props.bolderDividerColor as TRgbaFormat,
        alpha: "1",
      })}
      d="M5.526 2.438v.187h2.948v-.188c0-.828-.66-1.5-1.474-1.5s-1.474.672-1.474 1.5Zm-.92.187v-.188C4.605 1.091 5.676 0 7 0c1.323 0 2.395 1.091 2.395 2.438v.187h4.145c.254 0 .46.21.46.469a.465.465 0 0 1-.46.469h-1.075l-.737 8.945C11.612 13.917 10.455 15 9.066 15H4.934c-1.389 0-2.546-1.083-2.662-2.492l-.737-8.945H.461A.465.465 0 0 1 0 3.093c0-.258.206-.468.46-.468h4.145ZM3.19 12.43a1.763 1.763 0 0 0 1.744 1.633h4.132c.91 0 1.668-.71 1.744-1.633l.73-8.867H2.46l.73 8.867ZM6.08 6.094a.465.465 0 0 0-.46-.469c-.255 0-.461.21-.461.469v5.437c0 .26.206.469.46.469.255 0 .46-.21.46-.469V6.094Zm2.303-.469c.254 0 .46.21.46.469v5.437a.465.465 0 0 1-.46.469.465.465 0 0 1-.46-.469V6.094c0-.26.205-.469.46-.469Z"
    />
  </svg>
);

export const CloseGalleryIcon = (props: DoxleThemeColor) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={29} height={28} fill="none">
    <rect width={29} height={28} fill={props.primaryBackgroundColor} rx={3} />
    <path
      stroke={props.primaryFontColor}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m7 6 16 16M7 22 23 6"
    />
  </svg>
);
export const ScopeGroupTitleMenuExpandIcon = (
  props: {
    $themeColor: DoxleThemeColor;
    $isExpanded: boolean;
  } & Omit<SVGMotionProps<SVGSVGElement>, "onAnimationStart" | "onDragStart">
) => {
  const { $themeColor, $isExpanded, ...rest } = props;

  return (
    <motion.svg
      xmlns="http://www.w3.org/2000/svg"
      width={4}
      height={7}
      fill="none"
      style={{ marginLeft: "8px" }}
      initial={{
        rotateZ: "0deg",
        opacity: 0,
        x: -5,
      }}
      animate={{
        rotateZ: props.$isExpanded ? "90deg" : "0deg",
        x: 0,
        opacity: 1,
      }}
      {...rest}
    >
      <path
        fill={props.$themeColor.primaryFontColor}
        fillRule="evenodd"
        d="M.48 6.854a.5.5 0 0 1 0-.708l2.314-2.313L.48 1.52a.5.5 0 1 1 .708-.707L3.854 3.48a.5.5 0 0 1 0 .707L1.188 6.854a.5.5 0 0 1-.708 0Z"
        clipRule="evenodd"
      />
    </motion.svg>
  );
};
