import styled from "styled-components";
import { editRgbaAlpha } from "../../../Utilities/FunctionUtilities";
import { motion } from "framer-motion";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";

export const StyledQRLogFilterBtn = styled(motion(Button))`
  && {
    min-width: 0px !important;
    padding: 0px 8px !important;
  }
`;
export const StyledFilterQRLogDialog = styled(Dialog)<{}>`
  && {
  }
  & .MuiDialog-paper {
    background-color: ${(p) => p.theme.color.primaryContainerColor};
    box-shadow: 0px 0px 14px 0px ${(p) => p.theme.color.primaryBoxShadowColor};
    min-width: 300px;
    max-width: 600px;

    border-radius: 12px;
    border: 1px solid ${(p) => p.theme.color.primaryDividerColor};
    elevation: 2;
    padding: 14px 20px;
    width: 80vw;
    position: relative;
  }
`;
export const StyledFilterQRLogDialogTitle = styled(DialogTitle)<{}>`
  && {
    background-color: ${(p) => p.theme.color.primaryContainerColor};
    padding: 0px !important;

    width: 100%;
    display: flex;
    align-items: center;
  }
  .title-wrapper {
    font-family: ${(p) => p.theme.font.primaryFont};
    font-style: normal;
    font-weight: 600;
    font-size: 20px;

    color: ${(p) => p.theme.color.primaryFontColor};
    display: inline-block;
    white-space: normal;
    word-wrap: break-word;
    flex: 1;
    display: flex;
    align-items: center;
  }
`;
export const StyledFilterQRLogDialogContent = styled(motion(DialogContent))<{}>`
  && {
    background-color: ${(p) => p.theme.color.primaryContainerColor};
    padding: 30px 0px 0px 0px !important;
    display: flex;
    flex-direction: column;
    overflow: auto;
    max-height: 700px;
  }
`;
export const StyledQRLogFilterFieldWrapper = styled(motion.div)<{
  $isNull: boolean;
}>`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  .label-text {
    font-size: 12px;
    font-weight: 500;
    color: ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.theme.color.primaryFontColor,
        alpha: "0.4",
      })};
    line-height: 14px;
    margin-bottom: 4px;
    margin-left: 4px;
  }
  .toggle-display-field {
    width: calc(100% - 18px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
    border-radius: 8px;
    cursor: pointer;

    background-color: ${(p) => p.theme.color.primaryTextFieldBackgroundColor};
    border: 1px solid ${(p) => p.theme.color.primaryDividerColor};
    font-size: 16px;
    font-weight: 500;
    color: ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.theme.color.primaryFontColor,
        alpha: p.$isNull ? "0.4" : "1",
      })};
    line-height: 14px;
    transition: 0.4s ease all;
    &:hover {
      border-color: ${(p) => p.theme.color.doxleColor};
    }
    .icon-wrapper {
      display: flex;
      align-items: center;
    }
  }
`;
export const StyledQRFilterDatePickerWrapper = styled(motion.div)`
  align-self: center;
  background-color: ${(p) => p.theme.color.primaryTextFieldBackgroundColor};
  margin-top: 14px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  border: 1px solid ${(p) => p.theme.color.primaryDividerColor};
  align-items: center;
  padding: 14px;
`;
export const StyledDatePickerModeWrapper = styled.div<{
  $isRangeMode: boolean;
}>`
  display: flex;
  align-items: center;
  border-radius: 20px;
  position: relative;
  overflow: hidden;
  margin-bottom: 14px;
  border: 1px solid ${(p) => p.theme.color.primaryDividerColor};
  .selected-effect {
    position: absolute;
    top: 0;
    width: 50%;
    bottom: 0;
    background-color: ${(p) => p.theme.color.primaryFontColor};
    border-radius: 20px;
    ${(p) => (p.$isRangeMode ? "right: 0;" : "left: 0;")}
    z-index: 0;
  }
  .date-mode-btn {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 16px;
    font-size: 16px;
    z-index: 1;
    transition: all 0.4s ease;
  }
  .range {
    color: ${(p) =>
      p.$isRangeMode
        ? p.theme.color.primaryContainerColor
        : p.theme.color.primaryFontColor};
    font-weight: ${(p) => (p.$isRangeMode ? 600 : 400)};
  }
  .single {
    color: ${(p) =>
      !p.$isRangeMode
        ? p.theme.color.primaryContainerColor
        : p.theme.color.primaryFontColor};
    font-weight: ${(p) => (!p.$isRangeMode ? 600 : 400)};
  }
`;
