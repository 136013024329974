import { Route, Routes, useNavigate } from "react-router-dom";

import Header from "../components/header";
import Main from "./Main";
import About from "./About";
import Join from "./Join";
import Login from "./Login";
import ContactUs from "./ContactUs";
import UploadFloorPlan from "../../UploadDrawings/Pages/uploadFloorplan";
import Welcome from "./Welcome";
import { DoxleIcon } from "../../../Quotes/Content/icons";
import { NewDoxleIcon } from "../components/svg/DoxleIcon";
import {
  DoxleTheme,
  useDoxleThemeStore,
} from "../../../DoxleGeneralStore/useDoxleThemeStore";
import shallow from "zustand/shallow";
import styled from "styled-components";
import DoxleLoginScreenTest from "./DoxleLoginScreenTest";
const GreetingPageDisplayer = () => {
  const { currentTheme } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
      currentTheme: state.currentTheme,
    }),
    shallow
  );

  const navigate = useNavigate();
  const host = window.location.hostname.toLowerCase();
  // Home and login
  if (host.includes("doxle") && host.includes("login"))
    return (
      <StyledRootContainer $theme={currentTheme}>
        {/* <Header /> */}
        <DoxleLoginScreenTest />
        <div onClick={() => window.open("https://doxle.com/")}>
          <NewDoxleIcon />
        </div>
      </StyledRootContainer>
    );
  return (
    <StyledRootContainer $theme={currentTheme}>
      {/* <Header />  */}
      <StyledMainContainer>
        <Routes>
          <Route path="/About/" element={<About />} />
          <Route path="/Join/" element={<Join />} />
          <Route path="/Login/" element={<DoxleLoginScreenTest />} />
          <Route path="/ContactUs/" element={<ContactUs />} />
          <Route path="/UploadDrawing/" element={<UploadFloorPlan />} />
          <Route path="/Welcome/" element={<Welcome />} />
          <Route path="*" element={<Main />} />
        </Routes>
      </StyledMainContainer>

      <div onClick={() => navigate("/")}>
        <NewDoxleIcon />
      </div>
    </StyledRootContainer>
  );
};
export default GreetingPageDisplayer;

const StyledRootContainer = styled.div<{
  $theme: DoxleTheme;
}>`
  background-color: ${(p) =>
    p.$theme === "dark" ? "rgba(31, 33, 42,1)" : "rgba(255, 255, 255, 1)"};
  height: 100%;
  ::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;
`;

const StyledMainContainer = styled.div`
  height: calc(100% - 50px);
`;
