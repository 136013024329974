import { useDoxleCurrentContextStore } from "../../../../../DoxleGeneralStore/useDoxleCurrentContext";
import { useEditDocketSideScreenStore } from "../../../EditDocketSideScreenStore/useEditDocketSideScreenStore";
import { shallow } from "zustand/shallow";
import DocketQuery from "../../../../../Services/QueryHooks/docketQueryAPI";
import dayjs from "dayjs";
import { formatTISODate } from "../../../../../Utilities/FunctionUtilities";
import { Docket } from "../../../../../Models/dockets";
import useSetDocketDetailQueryData from "../../../../QueryDataHooks/SetQueryDataHooks/useSetDocketDetailQueryData";
import { Dispatch, SetStateAction, useState } from "react";

type Props = {};

interface DocketDatePickerSection {
  handleUpdateStartDate: (value: dayjs.Dayjs | null) => void;
  handleUpdateEndDate: (value: dayjs.Dayjs | null) => void;
  onHover: boolean;
  setOnHover: Dispatch<SetStateAction<boolean>>;
  handleRemoveStartDate: () => void;
  handleRemoveEndDate: () => void;
}
const useDocketDatePickerSection = (props: Props): DocketDatePickerSection => {
  const [onHover, setOnHover] = useState(false);
  const { company } = useDoxleCurrentContextStore((state) => ({
    company: state.currentCompany,
  }));
  const {
    edittedDocket,
    updateEdittedDocket,
    filterDocketListQuery,
    handlePostUpdateDocket,
  } = useEditDocketSideScreenStore(
    (state) => ({
      edittedDocket: state.edittedDocket,
      updateEdittedDocket: state.updateEdittedDocket,
      filterDocketListQuery: state.filterDocketListQuery,
      handlePostUpdateDocket: state.handlePostUpdateDocket,
    }),
    shallow
  );
  const { handleUpdateDocket } = useSetDocketDetailQueryData({
    docketPk: edittedDocket?.docketPk ?? "",
  });
  const onSuccessUpdate = (newDocket?: Docket) => {
    if (newDocket) {
      if (handlePostUpdateDocket) handlePostUpdateDocket(newDocket);

      handleUpdateDocket(newDocket);
    }
  };
  const updateDocketQuery = DocketQuery.useUpdateDocketQuery({
    filter: filterDocketListQuery,

    company,
    onSuccessCb: onSuccessUpdate,
  });
  const handleUpdateStartDate = (value: dayjs.Dayjs | null) => {
    if (edittedDocket) {
      if (value) {
        updateEdittedDocket({
          startDate: formatTISODate(value.toDate()),
        });
        updateDocketQuery.mutate({
          docketPk: edittedDocket.docketPk,
          startDate: formatTISODate(value.toDate()),
        });
      }
    }
  };

  const handleUpdateEndDate = (value: dayjs.Dayjs | null) => {
    if (edittedDocket) {
      if (value) {
        updateEdittedDocket({
          endDate: formatTISODate(value.toDate()),
        });
        updateDocketQuery.mutate({
          docketPk: edittedDocket.docketPk,
          endDate: formatTISODate(value.toDate()),
        });
      }
    }
  };

  const handleRemoveStartDate = () => {
    if (edittedDocket)
      updateDocketQuery.mutate({
        docketPk: edittedDocket.docketPk,
        startDate: null,
      });
  };
  const handleRemoveEndDate = () => {
    if (edittedDocket)
      updateDocketQuery.mutate({
        docketPk: edittedDocket.docketPk,
        endDate: null,
      });
  };

  return {
    handleUpdateStartDate,
    handleUpdateEndDate,
    onHover,
    setOnHover,
    handleRemoveStartDate,
    handleRemoveEndDate,
  };
};

export default useDocketDatePickerSection;
