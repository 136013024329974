import { motion } from "framer-motion";
import styled from "styled-components";

export const StyledDoxleGridLayoutContainer = styled(motion.div)<{}>`
  min-width: 200px;
  min-height: 200px;
`;

export const StyledColumnGridContainer = styled.div`
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;
export const StyledGridItemContainer = styled.div`
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;
