import { shallow } from "zustand/shallow";
import { useDoxleCurrentContextStore } from "../../../../DoxleGeneralStore/useDoxleCurrentContext";
import { useDoxleAuthStore } from "../../../../DoxleGeneralStore/useDoxleAuthStore";
import useDoxleNotificationStore from "../../../../DoxleGeneralStore/useDoxleNotificationStore";
import { useInboxStore } from "../../../InboxStore/useInboxStore";
import DocketQuery from "../../../../Services/QueryHooks/docketQueryAPI";
import useSetDocketListQueryData from "../../../../CoreContent/QueryDataHooks/SetQueryDataHooks/useSetDocketListQueryData";

type Props = {};

interface NoticeBoardContent {
  handleDeleteNoticeBoardDocket: (docketPk: string | undefined) => void;
}
const useNoticeBoardContent = (props: Props): NoticeBoardContent => {
  const { company } = useDoxleCurrentContextStore((state) => ({
    company: state.currentCompany,
  }));
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );
  const user = useDoxleAuthStore((state) => state.user, shallow);
  const { filterDocketListQuery } = useInboxStore(
    (state) => ({
      filterDocketListQuery: state.filterDocketListQuery,
    }),
    shallow
  );
  const {
    handleRemoveDocket: handleRemoveMyNoticeDockets,

    isDocketExist: isMyDocketExist,
  } = useSetDocketListQueryData({
    filter: {
      ...filterDocketListQuery,
      ball_in_court: user?.userId,
      archived: false,
    },
  });

  const {
    handleRemoveDocket: handleRemoveAllNoticeDockets,
    isDocketExist: isAllDocketExist,
  } = useSetDocketListQueryData({
    filter: {
      ...filterDocketListQuery,
      ball_in_court: undefined,
      archived: false,
    },
  });

  const {
    handleRemoveDocket: handleRemoveArchiveDockets,
    isDocketExist: isArchivedDocketExist,
  } = useSetDocketListQueryData({
    filter: {
      ...filterDocketListQuery,
      ball_in_court: undefined,
      archived: true,
    },
  });

  const onDeleteSuccess = (deletedId?: string) => {
    if (deletedId) {
      if (isMyDocketExist(deletedId)) handleRemoveMyNoticeDockets(deletedId);
      if (isAllDocketExist(deletedId)) handleRemoveAllNoticeDockets(deletedId);
      if (isArchivedDocketExist(deletedId))
        handleRemoveArchiveDockets(deletedId);
    }
  };
  const deleteDocketQuery = DocketQuery.useDeleteDocketQuery({
    company,
    filter: filterDocketListQuery,
    showNotification,
    onSuccessCb: onDeleteSuccess,
  });

  const handleDeleteNoticeBoardDocket = (docketPk: string | undefined) => {
    if (docketPk) {
      deleteDocketQuery.mutate(docketPk);
    }
  };

  return {
    handleDeleteNoticeBoardDocket,
  };
};

export default useNoticeBoardContent;
