import { Contact, ContactCompany } from "../../../Models/addressBook";
import React, { useCallback, useMemo } from "react";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import { ItemContent, TableComponents, TableVirtuoso } from "react-virtuoso";
import { Table } from "@mui/material";
import DoxleEmptyPlaceHolder from "../../../DoxleDesignPattern/DoxleEmptyPlaceHolder/DoxleEmptyPlaceHolder";
import ContactCompanyTableRow from "./ContactCompanyTableRow";
import ContactCompanyTableHeader from "./ContactCompanyTableHeader";
import { StyledListScroller } from "../../../DoxleDesignPattern/ProjectSelectWithDropdown/StyledComponentsProjectSelectWithDropdown";
import { StyledContactListItemWrapper } from "../StyledComponents";
import ContactsTableRow from "../ContactsTable/ContactsTableRow";
import ContactsTableHeader from "../ContactsTable/ContactsTableHeader";
import useGetContactCompanyList from "../../Hooks/useGetContactCompanyList";
import ListLoadingMore from "../../../Utilities/Lottie/ListLoadingMore";
import { EmptyContactBanner } from "../ContactsIcons";
import { Error404Banner } from "../../../DoxleDesignPattern/DoxleIcons/CommonIcons";
import { FaPlus } from "react-icons/fa6";

interface Props {}
interface ContactTableListContext {}

const ContactCompanyTable = ({}: Props) => {
  const doxleThemeColor = useDoxleThemeStore(
    (state) => state.doxleThemeColor,
    shallow
  );
  const {
    contactCompanyList,
    isFetchingContactCompanyList,
    isSuccessFetchingContactCompanyList,
    isErrorFetchingContactCompanyList,
    isFetchingNextPageContactCompanyList,
    handleFetchNextpageContactCompany,
  } = useGetContactCompanyList({});
  const TableComponents: TableComponents<
    ContactCompany,
    ContactTableListContext
  > = useMemo(
    () => ({
      Table: (props) => <Table {...props} />,
      EmptyPlaceholder: (props) => (
        <DoxleEmptyPlaceHolder
          {...props}
          headTitleText={
            isErrorFetchingContactCompanyList
              ? "Something Wrong"
              : "No Contact Companies"
          }
          subTitleText={
            isErrorFetchingContactCompanyList
              ? "Failed to get data, please try to reload the page"
              : "Add a company to get started"
          }
          illustration={
            isErrorFetchingContactCompanyList ? (
              <Error404Banner
                themeColor={doxleThemeColor}
                containerStyle={{ width: "30%" }}
              />
            ) : (
              <EmptyContactBanner themeColor={doxleThemeColor} />
            )
          }
          addBtn={{
            btnText: "Add",
            btnColor: doxleThemeColor.primaryFontColor,
            btnTextColor: doxleThemeColor.primaryReverseFontColor,
            btnHeightInPixel: "40px",
            btnWidthInPixel: "164px",
            btnFunction: () => {
              console.log("NOTHING HERE YET");
            },
            btnIcon: (
              <FaPlus
                size={20}
                color={doxleThemeColor.primaryContainerColor}
                style={{ marginRight: "7px" }}
              />
            ),
          }}
        />
      ),
      Scroller: React.forwardRef((props, ref) => (
        <StyledListScroller
          style={{
            ...props.style,
          }}
          ref={ref}
          {...props}
        />
      )),
      TableRow: ({ item: _, ...props }) => (
        <StyledContactListItemWrapper
          {...props}
          $themeColor={doxleThemeColor}
        />
      ),
    }),
    [doxleThemeColor]
  );

  const itemContent: ItemContent<ContactCompany, ContactTableListContext> =
    useCallback(
      (index, item) => <ContactCompanyTableRow contactCompany={item} />,
      []
    );
  const tableStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);

  return (
    <>
      <TableVirtuoso
        data={contactCompanyList}
        style={tableStyle}
        components={TableComponents}
        endReached={handleFetchNextpageContactCompany}
        fixedHeaderContent={() => <ContactCompanyTableHeader />}
        itemContent={itemContent}
        atBottomThreshold={200}
      />

      {isFetchingNextPageContactCompanyList && (
        <ListLoadingMore
          containerStyle={{
            position: "absolute",
            bottom: 0,
            left: 0,
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 20,
          }}
          animationSize={120}
        />
      )}
    </>
  );
};

export default React.memo(ContactCompanyTable);
