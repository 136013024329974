import { useMemo } from "react";
import {
  StyleBudgetFooterRow,
  StyledBudgetFooterContainer,
} from "./BudgetStyleComponent";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import { BUDGET_TABLE_HEADER_LIST } from "../Models/BudgetDocket";

import { formatter } from "../../Utilities/FunctionUtilities";
import { useDoxleCurrentContextStore } from "../../DoxleGeneralStore/useDoxleCurrentContext";
import { CircularProgress, Skeleton } from "@mui/material";
import { FiArrowDownLeft, FiArrowUpRight } from "react-icons/fi";
import ProjectQueryAPI from "../../Services/QueryHooks/projectQueryAPI";
import { Variants } from "framer-motion";
import { useShallow } from "zustand/react/shallow";

type Props = {
  isScrolling?: boolean;
};

const BudgetFooter = ({ isScrolling }: Props) => {
  const { doxleThemeColor, doxleFont, currentTheme } = useDoxleThemeStore(
    useShallow((state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
      currentTheme: state.currentTheme,
    }))
  );
  const { project } = useDoxleCurrentContextStore((state) => ({
    project: state.currentProject,
  }));
  const totalsQuery = ProjectQueryAPI.useFetchProjectTotals(project?.projectId);
  const {
    projectBudget,
    totalBudget,
    totalOrders,
    totalXero,
    totalInvoices,
    totalRunning,
  } = useMemo(() => {
    // console.log(totalsQuery.data);
    if (totalsQuery.isSuccess)
      return {
        projectBudget: totalsQuery.data?.data?.projectBudget,
        totalBudget: totalsQuery.data?.data?.totalBudget,
        totalOrders: totalsQuery.data?.data?.totalOrders,
        totalXero: totalsQuery.data?.data?.totalActuals,
        totalInvoices: totalsQuery.data?.data?.totalInvoices,
        totalRunning: totalsQuery.data?.data?.totalRunning,
      };
    else
      return {
        projectBudget: "0.00",
        totalBudget: "0.00",
        totalOrders: "0.00",
        totalActuals: "0.00",
        totalInvoices: "0.00",
        totalRunning: "0.00",
      };
  }, [totalsQuery.isSuccess, totalsQuery.data]);

  const footerVariants: Variants = {
    initial: {
      opacity: 1,
    },
    isScrolling: {
      opacity: 0,
    },
  };
  return (
    <StyledBudgetFooterContainer
      variants={footerVariants}
      initial="initial"
      animate={isScrolling ? "isScrolling" : "initial"}
      transition={{
        duration: 0.1,
        damping: 16,
      }}
    >
      {BUDGET_TABLE_HEADER_LIST.map((budget, idx) => {
        if (budget.isDisplayed) {
          if (budget.headerName === "Name") {
            return (
              <StyleBudgetFooterRow
                $widthInRem={null}
                $horizontalAlign="flex-start"
                $doxleFont={doxleFont}
                $themeColor={doxleThemeColor}
                key={`bgFooter#${idx}`}
              >
                {project ? "# Project Total" : "# Total"}

                {totalsQuery.isFetching && (
                  <CircularProgress
                    size="12px"
                    style={{
                      marginLeft: 8,
                      color: doxleThemeColor.doxleColor,
                    }}
                  />
                )}
              </StyleBudgetFooterRow>
            );
          }
          if (budget.headerName === "Person") {
            return (
              <StyleBudgetFooterRow
                $widthInRem={
                  typeof budget.cellWidth === "number"
                    ? `${budget.cellWidth / 10}rem`
                    : null
                }
                $horizontalAlign="center"
                $doxleFont={doxleFont}
                $themeColor={doxleThemeColor}
                key={`bgFooter#${idx}`}
              />
            );
          }
          if (budget.headerName === "Budget") {
            return (
              <StyleBudgetFooterRow
                $widthInRem={"16%"}
                // $widthInRem={
                //   typeof budget.cellWidth === "number"
                //     ? `${budget.cellWidth / 10}rem`
                //     : null
                // }
                $horizontalAlign="center"
                $doxleFont={doxleFont}
                $themeColor={doxleThemeColor}
                key={`bgFooter#${idx}`}
              >
                {project &&
                (totalsQuery.isFetching || totalsQuery.isLoading) ? (
                  <Skeleton width={200} />
                ) : (
                  formatter.format(Number(totalBudget))
                )}
              </StyleBudgetFooterRow>
            );
          }

          if (budget.headerName === "Order") {
            return (
              <StyleBudgetFooterRow
                $widthInRem={"16%"}
                $horizontalAlign="center"
                $doxleFont={doxleFont}
                $themeColor={doxleThemeColor}
                key={`bgFooter#${idx}`}
              >
                <span
                  className="cell-text-content"
                  style={{
                    textAlign: "center",
                    marginRight: budget.headerName === "Order" ? 4 : 0,
                  }}
                >
                  {project &&
                  (totalsQuery.isFetching || totalsQuery.isLoading) ? (
                    <Skeleton width={120} />
                  ) : currentTheme !== "contrast" ? (
                    formatter.format(Number(totalOrders))
                  ) : (
                    formatter.format(Number(totalOrders))
                  )}
                  <>
                    <span style={{ position: "absolute" }}>
                      {Number(totalOrders) > Number(totalBudget) ? (
                        <FiArrowUpRight fontSize="16px" color="#EA1515" />
                      ) : (
                        <FiArrowDownLeft fontSize="16px" color="#209D34" />
                      )}
                    </span>
                  </>
                </span>

                {/*  : (*/}
                {/*  <StyledBudgetTableSpan*/}
                {/*    $font={doxleFont}*/}
                {/*    $textColor={doxleThemeColor.primaryFontColor}*/}
                {/*    $bgColor={orderSpanColour}*/}
                {/*  >*/}
                {/*    {orderTotal}*/}
                {/*  </StyledBudgetTableSpan>*/}
                {/*)}*/}
              </StyleBudgetFooterRow>
            );
          }

          if (budget.headerName === "Xero") {
            return (
              <StyleBudgetFooterRow
                $widthInRem={"16%"}
                // $widthInRem={
                //   typeof budget.cellWidth === "number"
                //     ? `${budget.cellWidth / 10}rem`
                //     : null
                // }
                $horizontalAlign="center"
                $doxleFont={doxleFont}
                $themeColor={doxleThemeColor}
                $textColor={doxleThemeColor.doxleColor}
                key={`bgFooter#${idx}`}
              >
                {
                  project &&
                  (totalsQuery.isFetching || totalsQuery.isLoading) ? (
                    <Skeleton width={120} />
                  ) : (
                    formatter.format(Number(totalXero))
                  )
                  //   : currentTheme !== "contrast" ? (
                  //   xeroTotal
                  // ) : (
                  //   <StyledBudgetTableSpan
                  //     $font={doxleFont}
                  //     $textColor={doxleThemeColor.primaryFontColor}
                  //     $bgColor="#A4C8FF"
                  //   >
                  //     {xeroTotal}
                  //   </StyledBudgetTableSpan>)
                }
              </StyleBudgetFooterRow>
            );
          }

          if (budget.headerName === "Final") {
            return (
              <StyleBudgetFooterRow
                $widthInRem={"16%"}
                $horizontalAlign="center"
                $doxleFont={doxleFont}
                $themeColor={doxleThemeColor}
                key={`bgFooter#${idx}`}
              >
                {project &&
                (totalsQuery.isFetching || totalsQuery.isLoading) ? (
                  <Skeleton width={120} />
                ) : currentTheme !== "contrast" ? (
                  formatter.format(Number(totalRunning))
                ) : (
                  formatter.format(Number(totalRunning))
                )}
              </StyleBudgetFooterRow>
            );
          }
        }

        return null;
      })}
    </StyledBudgetFooterContainer>
  );
};

export default BudgetFooter;
