import React, { useMemo, useState } from "react";
import MailConversationAPI from "./mailConversationAPI";
import { Mail, MailAttachment, ReplyMail } from "../Models/mail";
import moment from "moment/moment";
import MailQueryAPI from "./mailAPI";

const useMailReplyScreen = (currentMail: Mail) => {
  // const [enableAttachmentQuery, setenableAttachmentQuery] = useState(false);
  const replyQuery = MailConversationAPI.useRetrieveReplyMail({
    mailId: currentMail.mailId,
    enabled: true,
    page_size: 15,
  });

  const mailReplies: ReplyMail[] = useMemo(
    () =>
      replyQuery.data?.pages.flatMap(
        (page: any) => (page?.data?.results as ReplyMail[]) ?? []
      ) ?? [],
    [replyQuery.data]
  );

  const mailAttachmentQuery = MailQueryAPI.useGetMailAttachment({
    id: currentMail.mailId,
    isReply: false,
    enable: currentMail.attachmentsCount > 0,
  });

  const mailAttachment = useMemo(() => {
    return mailAttachmentQuery.data?.data.results ?? [];
  }, [mailAttachmentQuery.data]);



  const handleFetchReplyNextPage = () => {

    if(replyQuery.hasNextPage){
      replyQuery.fetchNextPage();
    }
  }


  return {
    mailReplies,
    mailAttachment,
    isLoadingReply: replyQuery.isLoading,
    isErrorLoadingReply: replyQuery.isError,
    handleFetchReplyNextPage,
    isFetchingNextPage: replyQuery.isFetchingNextPage,
  };
};

export default useMailReplyScreen;
