import React from "react";
import {
  DoxleCBCheckedIcon,
  DoxleCBUncheckIcon,
  DoxleCheckedIcon,
  DoxleUncheckedIcon,
} from "../../../DoxleDesignPattern/DoxleGeneralIcons";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import {
  StyledOrderToggleSelectAllCheckbox,
  StyledSelectAllContainer,
} from "./StyledOrderDocketTab";
import { useOrdersStore } from "../../OrdersStore/useOrdersStore";

type Props = {};

const OrderSelectAll = (props: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }),
    shallow
  );

  const { toggleSelectAllOrder, selectAllOrder } = useOrdersStore(
    (state) => ({
      toggleSelectAllOrder: state.toggleSelectAllOrder,
      selectAllOrder: state.selectAllOrder,
    }),
    shallow
  );

  return (
    <StyledOrderToggleSelectAllCheckbox
      onChange={(e) => toggleSelectAllOrder()}
      checked={selectAllOrder}
      icon={
        <DoxleUncheckedIcon
          themeColor={doxleThemeColor}
          containerStyle={{
            width: 16,
          }}
        />
      }
      checkedIcon={
        <DoxleCheckedIcon
          themeColor={doxleThemeColor}
          containerStyle={{
            width: 16,
          }}
        />
      }
    />
  );
};

export default OrderSelectAll;
