import React from "react";
import DoxleDialogHelper from "../../DoxleDesignPattern/DoxleDialogHelper/DoxleDialogHelper";
import useDrawingDeleteConfirmDialog from "../Hooks/useDrawingDeleteConfirmDialog";
import { useDrawingStore } from "../Store/useDrawingStore";
import { shallow } from "zustand/shallow";
import { CircularProgress } from "@mui/material";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";

type Props = {};

const DrawingDeleteConfirmDialog = (props: Props) => {
  const { handleDeleteDrawing, handleCloseDeleteDialog, isDeletingDrawing } =
    useDrawingDeleteConfirmDialog({});
  const { selectedDrawingForDelete } = useDrawingStore(
    (state) => ({
      selectedDrawingForDelete: state.selectedDrawingForDelete,
    }),
    shallow
  );
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore((state) => ({
    doxleThemeColor: state.doxleThemeColor,
    doxleFont: state.doxleFont,
  }));

  const isDialogOpen = Boolean(selectedDrawingForDelete);
  return (
    <DoxleDialogHelper
      open={isDialogOpen}
      onClose={handleCloseDeleteDialog}
      title="Confirm Delete Drawing!"
      description={`All data belong to drawing item ***${selectedDrawingForDelete?.name}*** will be deleted permanently, are you sure to proceed?`}
      dialogButtons={[
        {
          buttonFunction: handleCloseDeleteDialog,
          buttonText: "Cancel",
          buttonColor: doxleThemeColor.primaryContainerColor,
          btnStyleSx: {
            border: `1px solid ${doxleThemeColor.primaryDividerColor}`,
          },
          buttonTextStyle: {
            color: doxleThemeColor.primaryFontColor,
          },
        },
        {
          buttonFunction: handleDeleteDrawing,
          buttonText: "Confirm",
        },       
      ]}
    />
  );
};

export default DrawingDeleteConfirmDialog;
