//!-------- > QUERY KEYS < -----------
//* ["simple-projects", companyId] => require "simple-projects" and pass in companyId to retrieve projects for timeline
//!-----------------------------------

import {
  QueryClient,
  useInfiniteQuery,
  useMutation,
  useQueryClient,
  useQuery,
  UseQueryResult,
} from "@tanstack/react-query";
import DoxleAPI from "../../Services/DoxleAPI";
import { BaseAPIProps } from "../../Models/baseAPIProps";
import { Company } from "../../Models/company";
import { NewProject, Project, ProjectStatus } from "../../Models/project";
import { Docket } from "../../Models/dockets";
import {
  AxiosBackendErrorReturn,
  IApiPaginatedData,
} from "../../Models/axiosReturn";
import { NewContact } from "../../DoxleDesignPattern/DoxleAutoComplete/DoxleContactInviteSection";
import { ProjectPermission } from "../../Models/permissions";
import useDoxleNotificationStore from "../../DoxleGeneralStore/useDoxleNotificationStore";
import { shallow } from "zustand/shallow";
import useSetProjectQueryData from "../../CoreContent/QueryDataHooks/SetQueryDataHooks/useSetProjectQueryData";
// import {formBudgetListQueryKey} from "../../Budgets/QueryHooks/BudgetAPI";
import { useDoxleCurrentContextStore } from "../../DoxleGeneralStore/useDoxleCurrentContext";
import axios, { AxiosError, AxiosResponse, isAxiosError } from "axios";
import { useSnackbar } from "notistack";
import useSetStatusQueryData from "../../CoreContent/CompanySettingsScreen/Hooks/useSetStatusQueryData";
import { User } from "../../Models/user";
import { useShallow } from "zustand/react/shallow";

export interface FilterGetProjectQuery {
  searchText?: string | null;
}
interface RetrieveFullProjectListQuery extends BaseAPIProps {
  onSuccessCb?: (data: Project[]) => void;
  enable?: boolean;
  filter: FilterGetProjectQuery;
}

const useRetrieveFullProjectListQuery = ({
  onSuccessCb,
  enable,
  showNotification,
  company,

  filter,
}: RetrieveFullProjectListQuery) => {
  const { searchText } = filter;
  const href = window.location.href;
  const view = href.toLowerCase().includes("noticeboard")
    ? "noticeboard"
    : "budget";
  const qKey = formFullDetailProjectListQKey(company, filter, view);
  let projectURL = `/project/?page=1`;
  const getParams: any = { view };
  // if (company) getParams.company = company?.companyId;
  if (searchText) getParams.search = searchText;
  const projectQuery = useInfiniteQuery(
    qKey,
    ({ pageParam = projectURL }) =>
      DoxleAPI.get<IApiPaginatedData<Project>>(pageParam, {
        headers: {
          "User-Company": company?.companyId ?? "",
        },
        params: getParams,
      }),
    {
      enabled: Boolean(company && (enable || true)),
      // retry: 1,
      // staleTime: Infinity,
      staleTime: 15 * 60 * 1000,
      cacheTime: 20 * 60 * 1000,
      refetchOnWindowFocus: false,
      getNextPageParam: (prevData) => prevData.data?.next,
      onSuccess: (res) => {
        if (onSuccessCb)
          onSuccessCb(
            res.pages.reduce((acc, data) => {
              return acc.concat(data.data.results);
            }, [] as Project[])
          );
      },
      onError: (error) => {
        if (showNotification) {
          if (isAxiosError<AxiosBackendErrorReturn>(error)) {
            showNotification(
              `${error?.response?.status ?? "ERROR"}: ${
                error.response?.data.detail ?? "UNKNOWN ERROR"
              }`,
              "error",
              String(
                error?.response?.data?.detail ?? "Failed to get project list"
              ).substring(0, 300)
            );
          } else {
            showNotification(
              "Something Wrong!",
              "error",
              "Failed to get project list"
            );
          }
        }
      },
    }
  );
  return projectQuery;
};

export interface FilterRetrieveProjectListWithDockets {
  view: "noticeboard";
  due?: "today" | "week" | "month";
  archived?: boolean;
  searchText?: string;
}

interface RetrieveProjectsListWithDockets extends BaseAPIProps {
  onSuccessCb?: Function;
  filter: FilterRetrieveProjectListWithDockets;
}
export interface ResponseSuccessRetrieveProjectsListWithDockets {
  projectId: string | null;
  siteAddress: string;
  data: Docket[];
  docketCount: number;
}

const useRetrieveProjectListWithDockets = ({
  company,

  showNotification,
  onSuccessCb,
  filter,
}: RetrieveProjectsListWithDockets) => {
  const { view, searchText, due, archived } = filter;
  const qKey = formRetrieveProjectListWithDocketsQKey(filter, company);
  const projectQuery = useQuery(
    qKey,
    () => {
      let getParams: any = {};
      if (view) getParams.view = view;
      if (searchText) getParams.search = searchText;
      if (due) getParams.due = due;
      if (archived !== undefined) getParams.archived = archived;
      return DoxleAPI.get<ResponseSuccessRetrieveProjectsListWithDockets[]>(
        "/project/project_with_dockets/",
        {
          headers: {
            "User-Company": company?.companyId ?? "",
          },
          params: getParams,
        }
      );
    },
    {
      enabled: company !== undefined,
      retry: 1,
      refetchInterval: 1 * 60 * 1000,
      onSuccess: (res) => {
        if (onSuccessCb) onSuccessCb();
      },
      onError: (error) => {
        if (showNotification) {
          if (isAxiosError<AxiosBackendErrorReturn>(error)) {
            showNotification(
              `${error?.response?.status ?? "ERROR"}: ${
                error.response?.data.detail ?? "UNKNOWN ERROR"
              }`,
              "error",
              String(
                error?.response?.data?.detail ?? "Failed to get project list"
              ).substring(0, 300)
            );
          } else {
            showNotification(
              "Something Wrong!",
              "error",
              "Failed to get project list"
            );
          }
        }
      },
    }
  );
  return projectQuery;
};

interface IProjectDetailProps extends BaseAPIProps {
  projectId?: string;
  view?: "accounts" | "dockets";
  enabled: boolean;
}

const useRetrieveProjectDetailQuery = ({
  projectId,

  company,
  showNotification,
  view,
  enabled,
}: IProjectDetailProps) => {
  const queryClient = useQueryClient();
  let params: any = {};
  if (view) params[view] = "true";
  return useQuery(
    [company?.companyId, projectId],
    () =>
      DoxleAPI.get("/project/" + projectId + "/", {
        headers: {
          "User-Company": company!.companyId,
        },
        params,
      }),
    {
      enabled: Boolean(company?.companyId && projectId && enabled),
      staleTime: Infinity,
      refetchOnWindowFocus: false,

      onSuccess: (response) => {
        const href = window.location.href;
        const view = href.toLowerCase().includes("noticeboard")
          ? "noticeboard"
          : "budget";
        const responseDockets = response.data.dockets;
        delete response.data.dockets;
        const responseProject = response.data as Project;
        if (responseProject)
          queryClient.setQueryData(
            formFullDetailProjectListQKey(company, {}, view),
            (old: any) =>
              old
                ? {
                    ...old,
                    pages: old.pages.map((page: any, index: number) => {
                      return {
                        ...page,
                        data: {
                          ...page.data,
                          results: page.data.results.map(
                            (oldProject: Project) => {
                              if (
                                oldProject.projectId !==
                                responseProject.projectId
                              )
                                return oldProject;
                              return responseProject;
                            }
                          ),
                        },
                      };
                    }),
                  }
                : old
          );

        if (responseDockets)
          queryClient.setQueryData(
            ["fullDocket-list", projectId, "budget"],
            (old: any) =>
              old
                ? {
                    ...old,
                    pages: [
                      {
                        ...old.pages?.[0],
                        data: {
                          count: 1,
                          previous: null,
                          next: null,
                          results: responseDockets,
                        },
                      },
                    ],
                  }
                : old
          );
      },
      onError: (error) => {
        if (showNotification) {
          if (isAxiosError<AxiosBackendErrorReturn>(error)) {
            showNotification(
              `${error?.response?.status ?? "ERROR"}: ${
                error.response?.data.detail ?? "UNKNOWN ERROR"
              }`,
              "error",
              String(
                error?.response?.data?.detail ?? "Failed to get project detail"
              ).substring(0, 300)
            );
          } else {
            showNotification(
              "Something Wrong!",
              "error",
              "Failed to get project detail"
            );
          }
        }
      },
    }
  );
};

interface IUpdateProjectQueryProps extends BaseAPIProps {
  filter: FilterGetProjectQuery;
  onSuccessUpdateCb?: (newProject?: Project) => void;
}
interface IUpdateProjectParams {
  projectId: string;
  updateData: Partial<Project>;
}

const useUpdateProjectQuery = ({
  company,
  showNotification,
  filter,
  onSuccessUpdateCb,
}: IUpdateProjectQueryProps) => {
  const { handleUpdateProjectQueryData } = useSetProjectQueryData({ filter });
  const mutation = useMutation({
    mutationFn: async ({ projectId, updateData }: IUpdateProjectParams) => {
      return DoxleAPI.patch<Project>(
        "/project/" + projectId + "/",
        updateData,
        {
          headers: {
            "User-Company": company?.companyId || "",
          },
        }
      );
    },
    onSuccess: (result, variables, context) => {
      handleUpdateProjectQueryData(result.data);
      // if (showNotification) showNotification("Project Updated", "success");
      if (onSuccessUpdateCb) onSuccessUpdateCb(result.data);
    },
    onError: (error, variables, context) => {
      if (showNotification) {
        if (isAxiosError<AxiosBackendErrorReturn>(error)) {
          showNotification(
            `${error?.response?.status ?? "ERROR"}: ${
              error.response?.data.detail ?? "UNKNOWN ERROR"
            }`,
            "error",
            String(
              error?.response?.data?.detail ?? "Failed to update project detail"
            ).substring(0, 300)
          );
        } else {
          showNotification(
            "Something Wrong!",
            "error",
            "Failed to update project detail"
          );
        }
      }
    },
  });
  const mutate = (data: IUpdateProjectParams) => mutation.mutate(data);
  return { ...mutation, mutate };
};

interface AddProjectQueryProps extends BaseAPIProps {
  filter: FilterGetProjectQuery;
  onSuccessCb?: (newProject?: Project) => void;
}

const useAddProjectQuery = ({
  showNotification,

  company,
  filter,
  onSuccessCb,
}: AddProjectQueryProps) => {
  const { handleAddProjectQueryData } = useSetProjectQueryData({ filter });
  const mutation = useMutation({
    mutationFn: async (newProject: NewProject) => {
      return DoxleAPI.post<Project>("/project/", newProject, {
        headers: {
          "User-Company": company?.companyId || "",
        },
      });
    },
    onSuccess: (result, variables, context) => {
      handleAddProjectQueryData(result.data);
      // if (showNotification) showNotification("Project Updated", "success");
      console.log("EDIT PROJECT RESULT:", result);

      if (onSuccessCb) onSuccessCb(result.data);
    },
    onError: (error, variables, context) => {
      if (showNotification) {
        if (isAxiosError<AxiosBackendErrorReturn>(error)) {
          showNotification(
            `${error?.response?.status ?? "ERROR"}: ${
              error.response?.data.detail ?? "UNKNOWN ERROR"
            }`,
            "error",
            String(
              error?.response?.data?.detail ?? "Failed to ad project"
            ).substring(0, 300)
          );
        } else {
          showNotification(
            "Something Wrong!",
            "error",
            "Failed to add project"
          );
        }
      }
    },
  });
  const mutate = (newProject: NewProject) => mutation.mutate(newProject);
  return { ...mutation, mutate };
};

interface DeleteProjectQueryProps extends BaseAPIProps {
  filter: FilterGetProjectQuery;
  onSuccessCb?: (deletedId?: string) => void;
}

const useDeleteProjectQuery = ({
  showNotification,

  company,
  filter,
  onSuccessCb,
}: DeleteProjectQueryProps) => {
  const { handleDeleteProjectQueryData } = useSetProjectQueryData({ filter });
  const mutation = useMutation({
    mutationKey: getProjectMutationKey("delete"),
    mutationFn: async (deletedProjectId: string) => {
      return DoxleAPI.delete(`/project/${deletedProjectId}/`, {
        headers: {
          "User-Company": company?.companyId || "",
        },
      });
    },
    onSuccess: (result, variables, context) => {
      // if (showNotification) showNotification("Project Updated", "success");
      handleDeleteProjectQueryData(variables);
      if (onSuccessCb) onSuccessCb(variables);
    },
    onError: (error, variables, context) => {
      if (showNotification) {
        if (isAxiosError<AxiosBackendErrorReturn>(error)) {
          showNotification(
            `${error?.response?.status ?? "ERROR"}: ${
              error.response?.data.detail ?? "UNKNOWN ERROR"
            }`,
            "error",
            String(
              error?.response?.data?.detail ?? "Failed to delete project"
            ).substring(0, 300)
          );
        } else {
          showNotification(
            "Something Wrong!",
            "error",
            "Failed to delete project"
          );
        }
      }
    },
  });
  const mutate = (deletedProjectId: string) =>
    mutation.mutate(deletedProjectId);
  return { ...mutation, mutate };
};

interface IClearProjectNBQuery extends BaseAPIProps {
  onSuccessCb?: (clearedProjectId?: string) => void;
}
interface ISuccessClearNbReturn {
  detail: string;
  message: string;
}
const useClearProjectNbQuery = ({
  showNotification,

  company,

  onSuccessCb,
}: IClearProjectNBQuery) => {
  const mutation = useMutation({
    mutationKey: getProjectMutationKey("delete"),
    mutationFn: async (deletedProjectId: string) => {
      return DoxleAPI.delete<ISuccessClearNbReturn, AxiosError>(
        `/project/${deletedProjectId}/clear_noticeboard/`,
        {
          headers: {
            "User-Company": company?.companyId || "",
          },
        }
      );
    },
    onSuccess: (result, variables, context) => {
      // if (showNotification) showNotification("Items Cleared", "success");

      if (onSuccessCb) onSuccessCb(variables);
    },
    onError: (error, variables, context) => {
      if (showNotification) {
        if (isAxiosError<AxiosBackendErrorReturn>(error)) {
          showNotification(
            `${error?.response?.status ?? "ERROR"}: ${
              error.response?.data.detail ?? "UNKNOWN ERROR"
            }`,
            "error",
            String(
              error?.response?.data?.detail ?? "Failed to update spec"
            ).substring(0, 300)
          );
        } else {
          showNotification(
            "Something Wrong!",
            "error",
            "Failed to update spec"
          );
        }
      }
    },
  });
  const mutate = (deletedProjectId: string) =>
    mutation.mutate(deletedProjectId);
  return { ...mutation, mutate };
};
//* Project status
export interface GetProjectStatusQueryFilter {
  is_noticeboard_projectStatus?: true;
  is_budget_project_status?: true;
}
interface RetrieveProjectStatusList extends BaseAPIProps {
  filter: GetProjectStatusQueryFilter;
}

const useRetrieveProjectStatusList = ({
  showNotification,
  company,
  filter,
}: RetrieveProjectStatusList) => {
  const qKey = formRetrieveProjectStatusListQkey(company, filter);
  let projectStatusURL = `/project/status/`;

  const projectQuery = useQuery(
    qKey,

    ({ pageParam = projectStatusURL }) =>
      DoxleAPI.get<ProjectStatus[]>(pageParam, {
        headers: {
          "User-Company": company!.companyId,
        },
        params: filter,
      }),
    {
      enabled: Boolean(company),
      // retry: 1,
      // staleTime: Infinity,
      staleTime: Infinity,
      cacheTime: 60 * 60 * 1000,

      onSuccess: (res) => {},
      onError: (error) => {
        if (showNotification) {
          if (isAxiosError<AxiosBackendErrorReturn>(error)) {
            showNotification(
              `${error?.response?.status ?? "ERROR"}: ${
                error.response?.data.detail ?? "UNKNOWN ERROR"
              }`,
              "error",
              String(
                error?.response?.data?.detail ??
                  "Fail to get project status list"
              ).substring(0, 300)
            );
          } else {
            showNotification(
              "Something Wrong!",
              "error",
              "Fail to get project status list"
            );
          }
        }
      },
    }
  );
  return projectQuery;
};
//# HELPER FUNCTIONS

export const formFullDetailProjectListQKey = (
  company: Company | undefined,
  filter: FilterGetProjectQuery,
  view: "noticeboard" | "budget"
) => {
  const baseQKey = ["full-detail-project-list"];
  const { searchText } = filter;
  if (company) baseQKey.push(company?.companyId);
  baseQKey.push(view);
  if (searchText) baseQKey.push(searchText);
  return baseQKey;
};

export const formRetrieveProjectListWithDocketsQKey = (
  filter: FilterRetrieveProjectListWithDockets,
  company: Company | undefined
) => {
  const baseQKey = ["project-list-with-dockets"];
  if (company) baseQKey.push(company.companyId);
  if (filter.view) baseQKey.push(filter.view);
  if (filter.searchText) baseQKey.push(filter.searchText);
  if (filter.due) baseQKey.push(filter.due);
  if (filter.archived) baseQKey.push(`archived:${filter.archived}`);
  return baseQKey;
};

export const getProjectMutationKey = (action: "add" | "update" | "delete") => [
  `${action}-project`,
];

export const formRetrieveProjectStatusListQkey = (
  company: Company | undefined,
  filter: GetProjectStatusQueryFilter
) => {
  let baseQKey = ["project-status-list"];
  if (company) baseQKey.push(company.companyId);
  if (filter.is_budget_project_status)
    baseQKey.push(`is_budget_project_status`);
  if (filter.is_noticeboard_projectStatus)
    baseQKey.push(`is_noticeboard_projectStatus`);
  return baseQKey;
};
//# END HELPER FUNCTION

interface ShareDocketMutationProps {
  projectId: string;
  contactIds?: string[];
  newContacts?: NewContact[];
  // permissions: SharedDocketPermissions
}

interface GetProjectLinkProps {
  projectId: string;
  contactId?: string;
  userId?: NewContact;
}
interface ShareDocketQueryProp extends BaseAPIProps {
  onSuccessCb?: () => void;
  showDownloadResourceNotification?: (
    message: string,
    status: "success" | "error" | "loading",
    extraContent?: string,
    duration?: number
  ) => void;
}
type ExportTypes = "excel" | "pdf" | "csv";
interface ExportOptions {
  budget: boolean;
  orders: boolean;
  xero: boolean;
  running: boolean;
  formats: ExportTypes[];
}
interface ExportMutationProps {
  projectId: string;
  options: ExportOptions;
}

const useShareProjectLink = ({
  company,
  showNotification,
  onSuccessCb,
  showDownloadResourceNotification,
}: ShareDocketQueryProp) => {
  const queryClient = useQueryClient();
  const emailLink = useMutation({
    mutationFn: ({
      projectId,
      contactIds,
      newContacts,
    }: ShareDocketMutationProps) => {
      const updateDocketURL = `/project/share/${projectId}/`;
      return DoxleAPI.post(
        updateDocketURL,
        {
          contacts: contactIds,
          newContacts,
        },
        {
          headers: {
            "User-Company": company?.companyId || "",
          },
        }
      );
    },

    onSuccess: (result: AxiosResponse<any>, variables, context) => {
      console.log("variables", variables);
      console.log("result", result);
      const qkey = ["permissions-list", variables.projectId];
      queryClient.setQueryData(qkey, (oldData: any) => {
        if (!oldData) return oldData;
        return {
          ...oldData,
          pages: [
            ...oldData.pages.map((page: any, ind: number) => ({
              ...page,
              data: {
                ...page?.data,
                results: [
                  ...(ind === 0 ? result.data : []),
                  ...page?.data?.results.filter(
                    (permission: ProjectPermission) => {
                      return (
                        result.data.findIndex(
                          (newPermission: ProjectPermission) =>
                            newPermission.contact.contactId !==
                            permission.contact.contactId
                        ) !== -1
                      );
                    }
                  ),
                ],
              },
            })),
          ],
        };
      });
      if (showNotification)
        showNotification("Shared", "success", "An Email Has been Sent");
      if (onSuccessCb) onSuccessCb();
    },
    onError: (error: any, variables, context) => {
      if (showNotification) {
        if (isAxiosError<AxiosBackendErrorReturn>(error)) {
          showNotification(
            `${error?.response?.status ?? "ERROR"}: ${
              error.response?.data.detail ?? "UNKNOWN ERROR"
            }`,
            "error",
            String(
              error?.response?.data?.detail ?? "Unable to share docket"
            ).substring(0, 300)
          );
        } else {
          showNotification(
            "Something Wrong!",
            "error",
            "Unable to share docket"
          );
        }
      }
    },
  });
  const { closeSnackbar } = useSnackbar();
  const getLink = useMutation({
    mutationFn: ({ projectId, contactId, userId }: GetProjectLinkProps) => {
      const getURL = `/project/share/${projectId}/`;
      let params: any = {};
      if (contactId) params.contact = contactId;
      if (userId) params.contact = userId;
      return DoxleAPI.get(getURL, {
        headers: {
          "User-Company": company?.companyId || "",
        },
        params,
      });
    },
    onSuccess: (result: AxiosResponse<any>, variables, context) => {
      if (result?.data?.url) {
        // if (document.hasFocus())
        //   navigator.clipboard.writeText(result.data.url).then(() => {
        //     if (showNotification)
        //       showNotification(
        //         "The docket link has been copied to your clipboard",
        //         "success",
        //         ""
        //       );
        //   });
      }
    },
    onError: (error: any, variables, context) => {
      if (showNotification) {
        if (isAxiosError<AxiosBackendErrorReturn>(error)) {
          showNotification(
            `${error?.response?.status ?? "ERROR"}: ${
              error.response?.data.detail ?? "UNKNOWN ERROR"
            }`,
            "error",
            String(
              error?.response?.data?.detail ?? "Unable to get docket link"
            ).substring(0, 300)
          );
        } else {
          showNotification(
            "Something Wrong!",
            "error",
            "Unable to get docket link"
          );
        }
      }
    },
  });

  const exportProject = useMutation(["exporting-project"], {
    mutationFn: ({ projectId, options }: ExportMutationProps) => {
      let getURL = `/project/export/${projectId}/`;
      const { formats, ...params } = options;
      formats.forEach(
        (format, i) => (getURL += `${i === 0 ? "?" : "&"}formats=${format}`)
      );
      return DoxleAPI.get(getURL, {
        headers: {
          "User-Company": company?.companyId || "",
        },
        params,
      });
    },
    onMutate: () => {
      if (showNotification)
        showNotification("Generating Excel", "success", "Please wait...");
    },
    onSuccess: (result: AxiosResponse<any>, variables, context) => {
      if (showNotification)
        showNotification(
          "Processing: You will be emailed when complete",
          "success",
          "",
          3000
        );
    },
    onError: (error: any, variables, context) => {
      if (showNotification)
        showNotification(
          "Unable to get process your request",
          "error",
          String(error?.request?.status) ??
            "Unknown Error:" + String(error?.request?.data) ??
            "No Data"
        );
    },
  });

  return { emailLink, getLink, exportProject };
};
export interface SharedProjectResponse {
  project: Project;
  permissions: ProjectPermission;
  company: Company;
  token: string;
  user: User;
}
const useRetrieveSharedProjectQuery = (
  projectLinkId: string | undefined,
  onSuccessGetDetails?: (sharedDetails?: SharedProjectResponse) => void
) => {
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );
  return useQuery(
    getSharedProjectQKey(projectLinkId),
    async () => {
      const response = await DoxleAPI.get<SharedProjectResponse>(
        "/project/link/" + projectLinkId + "/"
      );
      if (onSuccessGetDetails) onSuccessGetDetails(response.data);
      return response;
    },
    {
      enabled: Boolean(projectLinkId),
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      onSuccess: (response) => {},
      onError: (error: any) => {
        if (showNotification) {
          if (isAxiosError<AxiosBackendErrorReturn>(error)) {
            showNotification(
              `${error?.response?.status ?? "ERROR"}: ${
                error.response?.data.detail ?? "UNKNOWN ERROR"
              }`,
              "error",
              String(
                error?.response?.data?.detail ?? "Error Fetching Project"
              ).substring(0, 300)
            );
          } else {
            showNotification(
              "Something Wrong!",
              "error",
              "Error Fetching Project"
            );
          }
        }
      },
    }
  );
};

const useFetchProjectTotals = (projectId: string | undefined) => {
  const companyId = useDoxleCurrentContextStore(
    useShallow((state) => state.currentCompany?.companyId)
  );
  const showNotification = useDoxleNotificationStore(
    useShallow((state) => state.showNotification)
  );
  return useQuery(
    getProjectTotalQKey(projectId),
    () => {
      return DoxleAPI.get<Project>(`/project/${projectId}/`, {
        headers: {
          "User-Company": companyId ?? "",
        },
      });
    },
    {
      enabled: Boolean(companyId && projectId),
      retry: 1,
      refetchInterval: false,
      onError: (error) => {
        if (showNotification) {
          if (isAxiosError<AxiosBackendErrorReturn>(error)) {
            showNotification(
              `${error?.response?.status ?? "ERROR"}: ${
                error.response?.data.detail ?? "UNKNOWN ERROR"
              }`,
              "error",
              String(
                error?.response?.data?.detail ?? "Error Fetching Project Totals"
              ).substring(0, 300)
            );
          } else {
            showNotification(
              "Something Wrong!",
              "error",
              "Error Fetching Project Totals"
            );
          }
        }
      },
    }
  );
};
interface UpdateStatusProps {
  statusId: string;
  updateStatus: Omit<Partial<ProjectStatus>, "statusId">;
}
const useMutateProjectStatus = () => {
  const companyId = useDoxleCurrentContextStore(
    (state) => state.currentCompany?.companyId,
    shallow
  );
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );
  const queryClient = useQueryClient();
  const updateStatus = useMutation(
    ["update-project-status", companyId],
    ({ statusId, updateStatus }: UpdateStatusProps) => {
      return DoxleAPI.patch<ProjectStatus>(
        `/project/status/${statusId}/`,
        updateStatus,
        { headers: { "User-Company": companyId ?? "" } }
      );
    },
    {
      retry: 1,
      onSuccess: (data, variables, context) => {
        queryClient.setQueryData<AxiosResponse<ProjectStatus[]> | undefined>(
          ["project-status-list", companyId],
          (old) => {
            if (!old || !old?.data) return old;
            return {
              ...old,
              data: old.data.map((status) =>
                status.statusId === data.data.statusId ? data.data : status
              ),
            };
          }
        );
      },
      onError: (error: any) => {
        if (showNotification) {
          if (isAxiosError<AxiosBackendErrorReturn>(error)) {
            showNotification(
              `${error?.response?.status ?? "ERROR"}: ${
                error.response?.data.detail ?? "UNKNOWN ERROR"
              }`,
              "error",
              String(
                error?.response?.data?.detail ?? "Error Update Project Status"
              ).substring(0, 300)
            );
          } else {
            showNotification(
              "Something Wrong!",
              "error",
              "Error Update Project Status"
            );
          }
        }
      },
    }
  );
  interface UpdateStatusIndexProps {
    statusId: string;
    source: number;
    dest: number;
  }
  const { handleUpdateStatusOrder } = useSetStatusQueryData();
  const updateStatusIndex = useMutation(
    ["update-project-status", companyId],
    ({ statusId, source, dest }: UpdateStatusIndexProps) => {
      return DoxleAPI.patch<ProjectStatus>(
        `/project/status/${statusId}/`,
        {
          index: dest,
        },
        { headers: { "User-Company": companyId ?? "" } }
      );
    },
    {
      retry: 1,
      onSuccess: (data, variables, context) => {},
      onError: (error: any, variables) => {
        handleUpdateStatusOrder(variables.dest, variables.source, [
          "project-status-list",
          companyId ?? "",
        ]);
        if (showNotification) {
          if (isAxiosError<AxiosBackendErrorReturn>(error)) {
            showNotification(
              `${error?.response?.status ?? "ERROR"}: ${
                error.response?.data.detail ?? "UNKNOWN ERROR"
              }`,
              "error",
              String(
                error?.response?.data?.detail ?? "Error Update Project Status"
              ).substring(0, 300)
            );
          } else {
            showNotification(
              "Something Wrong!",
              "error",
              "Error Update Project Status"
            );
          }
        }
      },
    }
  );
  const addStatus = useMutation(
    ["add-project-status", companyId],
    (newStatus: Omit<ProjectStatus, "statusId" | "totalCount">) => {
      return DoxleAPI.post<ProjectStatus>(`/project/status/`, newStatus, {
        headers: { "User-Company": companyId ?? "" },
      });
    },
    {
      retry: 1,
      onSuccess: (data, variables, context) => {
        queryClient.setQueryData<AxiosResponse<ProjectStatus[]> | undefined>(
          ["project-status-list", companyId],
          (old) => {
            if (!old || !old?.data) return old;
            return {
              ...old,
              data: [...old.data, data.data],
            };
          }
        );
      },
      onError: (error: any) => {
        if (showNotification) {
          if (isAxiosError<AxiosBackendErrorReturn>(error)) {
            showNotification(
              `${error?.response?.status ?? "ERROR"}: ${
                error.response?.data.detail ?? "UNKNOWN ERROR"
              }`,
              "error",
              String(
                error?.response?.data?.detail ?? "Error Add Project Status"
              ).substring(0, 300)
            );
          } else {
            showNotification(
              "Something Wrong!",
              "error",
              "Error Add Project Status"
            );
          }
        }
      },
    }
  );
  interface DeleteProps {
    statusId: string;
    newId?: string | null;
  }
  const deleteStatus = useMutation(
    ["delete-project-status", companyId],
    ({ statusId, newId }: DeleteProps) => {
      let url = `/project/status/${statusId}/`;
      if (newId) url += `?new_status_id=${newId}`;
      else if (newId === null) url += "?cascade=true";
      return DoxleAPI.delete(url, {
        headers: { "User-Company": companyId ?? "" },
      });
    },
    {
      retry: 1,
      onSuccess: (data, { statusId }, context) => {
        queryClient.setQueryData<AxiosResponse<ProjectStatus[]> | undefined>(
          ["project-status-list", companyId],
          (old) => {
            if (!old || !old?.data) return old;
            return {
              ...old,
              data: old.data.filter((status) => status.statusId !== statusId),
            };
          }
        );
      },
      onError: (error: any) => {
        if (showNotification) {
          if (isAxiosError<AxiosBackendErrorReturn>(error)) {
            showNotification(
              `${error?.response?.status ?? "ERROR"}: ${
                error.response?.data.detail ?? "UNKNOWN ERROR"
              }`,
              "error",
              String(
                error?.response?.data?.detail ?? "Error Delete Project Status"
              ).substring(0, 300)
            );
          } else {
            showNotification(
              "Something Wrong!",
              "error",
              "Error Delete Project Status"
            );
          }
        }
      },
    }
  );
  return {
    updateStatus: updateStatus.mutate,
    deleteStatus: deleteStatus.mutate,
    addStatus: addStatus.mutate,
    updateStatusIndex: updateStatusIndex.mutate,
  };
};
interface ImportProjectProps {
  onSuccessCb: (result: Project) => void;
}
const useImportProject = ({ onSuccessCb }: ImportProjectProps) => {
  const company = useDoxleCurrentContextStore(
    (state) => state.currentCompany,
    shallow
  );
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );
  const { handleUpdateProjectQueryData } = useSetProjectQueryData({
    filter: {},
  });
  return useMutation(
    ["import-project"],
    (file: File) => {
      const formData = new FormData();
      formData.append("file", file);
      return DoxleAPI.post<Project>("/project/import/", formData, {
        headers: { "User-Company": company?.companyId },
      });
    },
    {
      onSuccess: (result, file, context) => {
        handleUpdateProjectQueryData(result.data);
        if (onSuccessCb) onSuccessCb(result.data);
      },
      onError: (error, file, context) => {
        if (showNotification) {
          if (isAxiosError<AxiosBackendErrorReturn>(error)) {
            showNotification(
              `${error?.response?.status ?? "ERROR"}: ${
                error.response?.data.detail ?? "UNKNOWN ERROR"
              }`,
              "error",
              String(
                error?.response?.data?.detail ?? "Error Delete Project Status"
              ).substring(0, 300)
            );
          } else {
            showNotification(
              "Something Wrong!",
              "error",
              "Error Delete Project Status"
            );
          }
        }
      },
    }
  );
};

//# HELPER FUNCTIONS
const getSharedProjectQKey = (projectLinkId: string | undefined) => [
  "shared-project",
  projectLinkId,
];

export const baseProjectTotalQKey = ["project-totals"];
export const getProjectTotalQKey = (projectId: string | undefined) => [
  ...baseProjectTotalQKey,
  projectId,
];
const ProjectQueryAPI = {
  useUpdateProjectQuery,
  // useRetrieveSimpleProjectQuery,
  useRetrieveFullProjectListQuery,
  useRetrieveProjectDetailQuery,
  useAddProjectQuery,
  useRetrieveProjectListWithDockets,
  useRetrieveProjectStatusList,
  useShareProjectLink,
  useRetrieveSharedProjectQuery,
  useDeleteProjectQuery,
  useFetchProjectTotals,
  useMutateProjectStatus,
  useClearProjectNbQuery,
  useImportProject,
};

export default ProjectQueryAPI;
