import React, { useCallback, useMemo, useRef, useState } from "react";
import { shallow } from "zustand/shallow";
import { useDoxleThemeStore } from "../../../../DoxleGeneralStore/useDoxleThemeStore";
import {
  StyledEmailInfo,
  StyledInfoTitle,
  StyledMailBodySection,
  StyledMailGeneralSection,
  StyledMailInfoContainer,
  StyledMailReplySection,
  StyledMailResponseSection,
  StyledMainInfoContainer,
  StyledTopEmailInfo,
  StyledVirtuosoScroller,
} from "../StyledMailSideScreen";
import MailConversationAPI from "../../../Hooks/mailConversationAPI";
import useMailReplyScreen from "../../../Hooks/useMailReplyScreen";
import { formatMessageDate } from "../../../../Utilities/FunctionUtilities";
import { Mail, ReplyMail } from "../../../Models/mail";
import { Components, Virtuoso, VirtuosoHandle } from "react-virtuoso";
import DoxleEmptyPlaceHolder from "../../../../DoxleDesignPattern/DoxleEmptyPlaceHolder/DoxleEmptyPlaceHolder";
import { EmptyResponseBanner } from "../../../../Quotes/Content/QuotesIcon";
import MailResponseInputSection from "./MailResponseInputSection";
import MailReplyItem from "./MailReplyItem";
import MailAttachmentSection from "../MailAttachmentSection";
import Loading from "../../../../Utilities/Lottie/Loading";
import { Error404Banner } from "../../../../DoxleDesignPattern/DoxleIcons/CommonIcons";
import ListLoadingMore from "../../../../Utilities/Lottie/ListLoadingMore";
import { BsSendCheck, BsSendExclamation } from "react-icons/bs";
import { RiMailSendLine } from "react-icons/ri";

type Props = {
  currentMail: Mail;
};

interface ListContext {
  isErrorLoadingReply: boolean;
  isFetchingNextPage: boolean;
}

export const MailReplyScreen = ({ currentMail }: Props) => {
  const { doxleThemeColor, currentTheme } = useDoxleThemeStore((state) => ({
    doxleThemeColor: state.doxleThemeColor,
    doxleFont: state.doxleFont,
    currentTheme: state.currentTheme,
  }));

  const {
    mailReplies,
    mailAttachment,
    isLoadingReply,
    isErrorLoadingReply,
    handleFetchReplyNextPage,
    isFetchingNextPage,
  } = useMailReplyScreen(currentMail);
  const replyListRef = useRef<VirtuosoHandle>(null);

  const itemContent = useCallback(
    (index: number, item: ReplyMail) => <MailReplyItem item={item} />,
    []
  );

  const components: Components<ReplyMail, ListContext> = useMemo(
    () => ({
      Scroller: React.forwardRef(({ ...props }, ref) => (
        <StyledVirtuosoScroller
          style={{
            ...props.style,
          }}
          ref={ref}
          {...props}
        />
      )),
      Header: React.forwardRef((props, ref) => {
        return (
          <>
            <StyledMailGeneralSection>
              <StyledMailInfoContainer className="fromContainer" $width="20%">
                <StyledInfoTitle $themeColor={doxleThemeColor}>
                  FROM
                </StyledInfoTitle>
                <StyledEmailInfo
                  className="fromName"
                  $themeColor={doxleThemeColor}
                  $currentTheme={currentTheme}
                >
                  {currentMail?.fromString}
                </StyledEmailInfo>
              </StyledMailInfoContainer>

              <StyledMailInfoContainer $width="70%">
                <StyledInfoTitle $themeColor={doxleThemeColor}>
                  SUBJECT
                </StyledInfoTitle>
                <StyledEmailInfo
                  $themeColor={doxleThemeColor}
                  $currentTheme={currentTheme}
                >
                  {currentMail?.subject}
                </StyledEmailInfo>
              </StyledMailInfoContainer>

              <StyledMailInfoContainer $width="10%">
                {currentMail?.sendStatus === "Sent" ? (
                  <BsSendCheck
                    style={{
                      width: 20,
                      height: 20,
                      margin: "auto",
                      padding: "20px 0",
                    }}
                    color={"rgb(17, 183, 24)"}
                  />
                ) : currentMail?.sendStatus === "Sending" ? (
                  <RiMailSendLine
                    style={{
                      width: 20,
                      height: 20,
                      margin: "auto",
                      padding: "20px 0",
                    }}
                    color={"rgb(253, 183, 4)"}
                  />
                ) : (
                  <BsSendExclamation
                    style={{
                      width: 20,
                      height: 20,
                      margin: "auto",
                      padding: "20px 0",
                    }}
                    color={"rgb(255, 0, 0)"}
                  />
                )}
              </StyledMailInfoContainer>
            </StyledMailGeneralSection>
            <StyledMailBodySection className="mailBodySection">
              <StyledMailInfoContainer $width="100%">
                <StyledInfoTitle $themeColor={doxleThemeColor}>
                  MESSAGE ON{" "}
                  {formatMessageDate(currentMail.timeStamp).toUpperCase()}
                </StyledInfoTitle>

                <StyledTopEmailInfo
                  $themeColor={doxleThemeColor}
                  $currentTheme={currentTheme}
                >
                  {currentMail.textBody}
                  <MailAttachmentSection mailAttachment={mailAttachment} />
                </StyledTopEmailInfo>
              </StyledMailInfoContainer>
            </StyledMailBodySection>
          </>
        );
      }),
      EmptyPlaceholder: (props) => (
        <DoxleEmptyPlaceHolder
          headTitleText={
            props.context?.isErrorLoadingReply
              ? "Something wrong!"
              : "No replies"
          }
          subTitleText={
            props.context?.isErrorLoadingReply
              ? "Failed to get replies..."
              : "Add your replies to communicate with your colleagues..."
          }
          containerBgColor="transparent"
          headTitleTextStyle={{ fontSize: "3rem" }}
          subTitleTextStyle={{ fontSize: "1.8rem" }}
          illustration={
            props.context?.isErrorLoadingReply ? (
              <Error404Banner
                themeColor={doxleThemeColor}
                containerStyle={{ width: "30%" }}
              />
            ) : (
              <EmptyResponseBanner themeColor={doxleThemeColor} />
            )
          }
        />
      ),
    }),
    [mailAttachment]
  );

  return (
    <StyledMainInfoContainer>
      {/* <StyledMailGeneralSection>
        <StyledMailInfoContainer className="fromContainer" $width="20%">
          <StyledInfoTitle $themeColor={doxleThemeColor}>FROM</StyledInfoTitle>
          <StyledEmailInfo
            className="fromName"
            $themeColor={doxleThemeColor}
            $currentTheme={currentTheme}
          >
            {currentMail?.fromString}
          </StyledEmailInfo>
        </StyledMailInfoContainer>

        <StyledMailInfoContainer $width="80%">
          <StyledInfoTitle $themeColor={doxleThemeColor}>
            SUBJECT
          </StyledInfoTitle>
          <StyledEmailInfo
            $themeColor={doxleThemeColor}
            $currentTheme={currentTheme}
          >
            {currentMail?.subject}
          </StyledEmailInfo>
        </StyledMailInfoContainer>
      </StyledMailGeneralSection>

      <StyledMailBodySection className="mailBodySection">
        <StyledMailInfoContainer $width="100%">
          <StyledInfoTitle $themeColor={doxleThemeColor}>
            MESSAGE ON {formatMessageDate(currentMail.timeStamp).toUpperCase()}
          </StyledInfoTitle>

          <StyledTopEmailInfo
            $themeColor={doxleThemeColor}
            $currentTheme={currentTheme}
          >
            {currentMail.textBody}
            <MailAttachmentSection mailAttachment={mailAttachment} />
          </StyledTopEmailInfo>
        </StyledMailInfoContainer>
      </StyledMailBodySection> */}

      <StyledMailReplySection>
        {isFetchingNextPage && (
          <ListLoadingMore
            containerStyle={{
              position: "absolute",
              bottom: -30,
              left: 0,
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 20,
            }}
            animationSize={120}
          />
        )}

        {isLoadingReply ? (
          <Loading />
        ) : (
          <Virtuoso
            style={{
              width: "100%",
              height: "100%",
            }}
            endReached={handleFetchReplyNextPage}
            data={mailReplies}
            itemContent={itemContent}
            components={components}
            context={{ isErrorLoadingReply, isFetchingNextPage }}
            ref={replyListRef}
          />
        )}
      </StyledMailReplySection>

      <StyledMailResponseSection>
        <StyledMailInfoContainer $width="100%">
          <MailResponseInputSection
            replyListRef={replyListRef}
            mailReply={mailReplies}
          />
        </StyledMailInfoContainer>
      </StyledMailResponseSection>
    </StyledMainInfoContainer>
  );
};
