import {
  StyledDocketPercentageText,
  StyledDocketSlider,
  StyledEditFunctionFieldLabel,
  StyledStatusAndPercentageSectionWrapper,
} from "./StyledComponentDocketInfo";
import { Variants, motion } from "framer-motion";
import { StyledDocketStatusDisplayer } from "../../StyledComponentsEditDocketSideScreen";
import { useEditDocketSideScreenStore } from "../../EditDocketSideScreenStore/useEditDocketSideScreenStore";
import { shallow } from "zustand/shallow";
import useStatusAndPercentSection from "./Hooks/useStatusAndPercentSection";
import { editRgbaAlpha } from "../../../../Utilities/FunctionUtilities";
import EditDocketStatusPopover from "../EditDocketStatusPopover/EditDocketStatusPopover";

import { IoChevronDownOutline } from "react-icons/io5";
import { useDoxleThemeStore } from "../../../../DoxleGeneralStore/useDoxleThemeStore";
import { useShallow } from "zustand/react/shallow";
type Props = {};

const StatusAndPercentSection = (props: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore((state) => ({
    doxleThemeColor: state.doxleThemeColor,
    doxleFont: state.doxleFont,
  }));
  const { edittedDocket } = useEditDocketSideScreenStore(
    useShallow((state) => ({
      edittedDocket: state.edittedDocket,
    }))
  );
  const {
    anchorStatusListPopover,
    handleClickStatus,
    handleCloseStatusList,
    handleUpdatePercentage,
  } = useStatusAndPercentSection({});
  const statusAnimateVariants: Variants = {
    statusAnimate: {
      opacity: [1, 0.1, 1, 0.1, 1, 1], // Blink twice

      transition: {
        times: [0, 0.1, 0.2, 0.3, 0.4, 1], // Timing for each keyframe
        duration: 2, // Total duration for one sequence
        repeat: Infinity, // Repeat infinitely
        ease: "linear", // Linear easing for uniform animation speed
      },
    },
  };
  return (
    <motion.div
      layout="position"
      transition={{
        duration: 0.2,
        damping: 16,
      }}
      className="row-field-wrapper"
    >
      <StyledStatusAndPercentageSectionWrapper>
        <StyledEditFunctionFieldLabel>
          Docket Status
        </StyledEditFunctionFieldLabel>
        {edittedDocket && (
          <StyledDocketStatusDisplayer
            $statusColor={edittedDocket.statusColor}
            onClick={handleClickStatus}
            animate={{
              backgroundColor: editRgbaAlpha({
                rgbaColor: edittedDocket.statusColor,
                alpha: "0.1",
              }),
            }}
          >
            <div className="status-name-wrapper">
              <motion.div
                className="status-dot"
                variants={statusAnimateVariants}
                initial={{
                  rotateZ: 45,
                }}
                animate={
                  edittedDocket.statusAnimateIcon ? "statusAnimate" : undefined
                }
              />
              {edittedDocket.statusName}
            </div>

            <IoChevronDownOutline
              className="icon-dropdown"
              color={doxleThemeColor.primaryFontColor}
              size={14}
            />
          </StyledDocketStatusDisplayer>
        )}
      </StyledStatusAndPercentageSectionWrapper>

      <StyledStatusAndPercentageSectionWrapper>
        <StyledEditFunctionFieldLabel>
          Office Percent Complete
        </StyledEditFunctionFieldLabel>

        {edittedDocket && (
          <div className="slider-wrapper">
            <StyledDocketSlider
              step={25}
              min={0}
              max={100}
              getAriaValueText={(value) => `${value}%`}
              value={edittedDocket.percentageCompleted}
              onChange={(e, value) => handleUpdatePercentage(value as number)}
            />

            <StyledDocketPercentageText
              $percentageValue={edittedDocket.percentageCompleted}
            >
              {edittedDocket.percentageCompleted}%
            </StyledDocketPercentageText>
          </div>
        )}
      </StyledStatusAndPercentageSectionWrapper>
      <EditDocketStatusPopover
        anchorStatusListPopover={anchorStatusListPopover}
        handleCloseStatusList={handleCloseStatusList}
      />
    </motion.div>
  );
};

export default StatusAndPercentSection;
