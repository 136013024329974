import { useQueryClient } from "@tanstack/react-query";
import React from "react";
import { QAFile } from "../Models/qa";
import { baseQAFileQKey } from "../QueryAPI/qaQueryAPI";
import {
  IApiPaginatedData,
  DefiniteAxiosQueryData,
} from "../../Models/axiosReturn";
import { produce } from "immer";

type Props = {
  appendPos?: "start" | "end";
};

const useSetQAFileQueryData = ({ appendPos = "start" }: Props) => {
  const queryClient = useQueryClient();
  const handleAddQAFileData = (addedVideo: QAFile) => {
    const baseQKey = [...baseQAFileQKey, addedVideo.defect];
    const dataCacheActive = queryClient.getQueryCache().findAll({
      predicate: (query) =>
        baseQKey.every((key) => query.queryKey.includes(key)) &&
        query.isActive(),
    });
    dataCacheActive.forEach((query) => {
      queryClient.setQueryData<
        DefiniteAxiosQueryData<IApiPaginatedData<QAFile>>
      >(query.queryKey, (data) => {
        if (data) {
          return produce(data, (draft) => {
            if (appendPos === "end") draft.data.results.push(addedVideo);
            else draft.data.results.unshift(addedVideo);
            return draft;
          });
        } else queryClient.invalidateQueries(query.queryKey);
      });
    });
  };

  const handleDeleteQAFileData = (qaId: string, deletedId: string) => {
    const baseQKey = [...baseQAFileQKey, qaId];
    const dataCacheActive = queryClient.getQueryCache().findAll({
      predicate: (query) =>
        baseQKey.every((key) => query.queryKey.includes(key)) &&
        query.isActive(),
    });
    dataCacheActive.forEach((query) => {
      queryClient.setQueryData<
        DefiniteAxiosQueryData<IApiPaginatedData<QAFile>>
      >(query.queryKey, (data) => {
        if (data) {
          return produce(data, (draft) => {
            draft.data.results = draft.data.results.filter(
              (vd) => vd.fileId !== deletedId
            );
            return draft;
          });
        } else queryClient.invalidateQueries(query.queryKey);
      });
    });
  };
  return {
    handleAddQAFileData,
    handleDeleteQAFileData,
  };
};

export default useSetQAFileQueryData;
