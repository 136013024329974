import React, { useMemo } from "react";
import {
  StyledDoxleStatus,
  StyledDoxleStatusIconTypeWrapper,
  StyledDoxleStatusPercentage,
  StyledDoxleStatusSpan,
} from "./StyledComponentsDoxleStatus";
import { AnimatePresence, MotionProps } from "framer-motion";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import { editRgbaAlpha } from "../../Utilities/FunctionUtilities";
interface DoxleStatusWithPercentage {
  type?: "percentage";
  percentageText: number | string;
  percentageTextColor: string;
}

type DoxleStatusType = "icon" | "bordered" | "oval" | "full";

interface BaseDoxleStatus {
  statusColor: `rgba(${number},${number},${number},${number})`;

  size?: number;
  disableAnimation?: boolean;
  overrideBorderRadiusInPixel?: number;
  overrideBorderWidthInPixel?: number;
}

type Props = Omit<React.HTMLAttributes<HTMLDivElement> & MotionProps, ""> &
  BaseDoxleStatus &
  (
    | {
        type?: DoxleStatusType;
        percentageText?: never;
        percentageTextColor?: never;
      }
    | DoxleStatusWithPercentage
  ) & {
    showFullStatus?: boolean;
    statusName?: string;
  };

const DoxleStatus = React.forwardRef(
  (
    {
      statusColor,
      statusName,
      type,
      size = 12,
      percentageText,
      percentageTextColor,
      showFullStatus,
      disableAnimation = false,
      overrideBorderRadiusInPixel,
      overrideBorderWidthInPixel,
      ...rest
    }: Props,
    ref: any
  ) => {
    const { font, currentTheme } = useDoxleThemeStore(
      (state) => ({
        font: state.doxleFont,
        currentTheme: state.currentTheme,
      }),
      shallow
    );

    const calculatedBorderRadius = size * (6 / 14);

    const calculatedBorderWidth = size * (2.5 / 14);

    const statusAnimatedVariants = {
      hovering: {
        rotateZ: "180deg",
        scale: [1, 0.8, 1],
        transition: {
          duration: 0.2,
        },
      },
    };

    if (type === "oval" || type === "bordered")
      return (
        <StyledDoxleStatusSpan
          $font={font}
          $currentTheme={currentTheme}
          $statusColor={statusColor}
          $type={type}
          {...rest}
        >
          {statusName}
        </StyledDoxleStatusSpan>
      );
    else if (type === "percentage") {
      return (
        <StyledDoxleStatusPercentage
          $statusColor={statusColor}
          $textColor={percentageTextColor}
          $doxleFont={font}
          layout
          {...rest}
        >
          {showFullStatus && <span className="status-text">{statusName}</span>}
          <span className="percentage-text">{percentageText}%</span>
        </StyledDoxleStatusPercentage>
      );
    }
    return (
      <StyledDoxleStatusIconTypeWrapper
        $doxleFont={font}
        $statusColor={statusColor}
        initial={{
          backgroundColor: editRgbaAlpha({
            rgbaColor: statusColor,
            alpha: "0",
          }),
          marginRight: 0,
        }}
        animate={
          showFullStatus
            ? {
                backgroundColor: editRgbaAlpha({
                  rgbaColor: statusColor,
                  alpha: "0.1",
                }),
                marginRight: 4,
              }
            : {
                backgroundColor: editRgbaAlpha({
                  rgbaColor: statusColor,
                  alpha: "0",
                }),
                marginRight: 0,
              }
        }
        {...rest}
      >
        <StyledDoxleStatus
          layout
          $statusColor={statusColor}
          $borderRadiusInRem={`${
            (overrideBorderRadiusInPixel
              ? overrideBorderRadiusInPixel / 10
              : undefined) ?? calculatedBorderRadius / 10
          }rem`}
          $borderWidthInRem={`${
            (overrideBorderWidthInPixel
              ? overrideBorderWidthInPixel / 10
              : undefined) ?? calculatedBorderWidth / 10
          }rem`}
          $sizeInRem={`${size / 10}rem`}
          variants={statusAnimatedVariants}
          initial={false}
          whileHover={!disableAnimation ? "hovering" : undefined}
        />

        {showFullStatus && (
          <span className="status-icon-text">{statusName}</span>
        )}
      </StyledDoxleStatusIconTypeWrapper>
    );
  }
);

export default DoxleStatus;
