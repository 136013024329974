import { useState } from "react";
import { shallow } from "zustand/shallow";
import { useDoxleAuthStore } from "../../../../../DoxleGeneralStore/useDoxleAuthStore";
import { useDoxleCurrentContextStore } from "../../../../../DoxleGeneralStore/useDoxleCurrentContext";
import useDoxleNotificationStore from "../../../../../DoxleGeneralStore/useDoxleNotificationStore";
import { BookingQueryAPI } from "../../../../QueryAPI/bookingQueryAPI";
import { formatTISODate } from "../../../../../Utilities/FunctionUtilities";
import useBookingDocketStore from "../../../../store/useBookingDocketStore";
import { useEditDocketBookingContext } from "../EditBookingDocket";

type Props = {};

interface EditBookingDocketAndDate {
  handleSelectDateRange: (value: [Date | null, Date | null]) => void;
  isUpdatingDate: boolean;
  localStartDate: Date | null;
  localEndDate: Date | null;
}
const useEditBookingDocketAndDate = (
  props: Props
): EditBookingDocketAndDate => {
  const { filterBookingDocketQuery, edittedBooking } = useBookingDocketStore(
    (state) => ({
      filterBookingDocketQuery: state.filterBookingDocketQuery,
      edittedBooking: state.edittedBooking,
    }),
    shallow
  );
  const [localStartDate, setlocalStartDate] = useState(
    edittedBooking?.startDate ? new Date(edittedBooking?.startDate) : null
  );
  const [localEndDate, setlocalEndDate] = useState(
    edittedBooking?.endDate ? new Date(edittedBooking?.endDate) : null
  );
  const { company } = useDoxleCurrentContextStore(
    (state) => ({
      company: state.currentCompany,
    }),
    shallow
  );
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );
  const { setStartDate, setEndDate } =
  useEditDocketBookingContext();

  const { update } = BookingQueryAPI.useMutateBookingQuery({
    company,

    showNotification,
    filter: filterBookingDocketQuery,
  });

  const handleSelectDateRange = (value: [Date | null, Date | null]) => {
    const [start, end] = value;
    setStartDate(start);
    setEndDate(end);

    setlocalStartDate(start);
    setlocalEndDate(end);
    // if (edittedBooking) {
    //   update.mutate({
    //     bookingId: edittedBooking.bookingId,
    //     updateData: {
    //       startDate: start ? formatTISODate(start) : null,
    //       endDate: end ? formatTISODate(end) : null,
    //     },
    //   });
    // }
  };

  return {
    handleSelectDateRange,
    isUpdatingDate: update.isLoading,
    localStartDate,
    localEndDate,
  };
};

export default useEditBookingDocketAndDate;
