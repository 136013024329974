import React, { useMemo } from "react";
import CompanyQueryAPI from "../../../Services/QueryHooks/companyQueryAPI";
import { Company } from "../../../Models/company";
import { useDoxleCurrentContextStore } from "../../../DoxleGeneralStore/useDoxleCurrentContext";
import { shallow } from "zustand/shallow";
import {useDoxleAuthStore} from "../../../DoxleGeneralStore/useDoxleAuthStore";

type Props = {};

interface IGetCompanyList {
  companyList: Company[];
  isFetchingCompanyList: boolean;
  isErrorFetchingCompanyList: boolean;
  isSuccessFetchingCompanyList: boolean;
}
const useGetCompanyList = (props: Props): IGetCompanyList => {
  const user = useDoxleAuthStore(state => state.user, shallow)
  const { setCurrentCompany } = useDoxleCurrentContextStore(
    (state) => ({
      setCurrentCompany: state.setCurrentCompany,
    }),
    shallow
  );
  const onSuccessFetchingCompanyList = (companyList?: Company[]) => {
    if (companyList) {
      const storedCompanyId: string | null = user?.lastCompany ?? localStorage.getItem("currentCompanyId");
      const matchedCompany: Company | undefined =
        companyList.length > 0
          ? companyList.filter(
              (resCompany: Company) => resCompany.companyId === storedCompanyId
            )[0] ?? companyList[0]
          : undefined;

      setCurrentCompany(matchedCompany);
    }
  };
  const getCompanyQuery = CompanyQueryAPI.useGetCompanyQuery({
    // showNotification,
    company: undefined,
    onSuccessCb: onSuccessFetchingCompanyList,
  });

  const companyList: Company[] = useMemo(
    () =>
      getCompanyQuery.isSuccess
        ? getCompanyQuery.data?.data?.results ?? []
        : [],
    [getCompanyQuery.data]
  );
  return {
    companyList,
    isFetchingCompanyList: getCompanyQuery.isLoading,
    isErrorFetchingCompanyList: getCompanyQuery.isError,
    isSuccessFetchingCompanyList: getCompanyQuery.isSuccess,
  };
};

export default useGetCompanyList;
