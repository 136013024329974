import DoxleDialogHelper from "../../DoxleDesignPattern/DoxleDialogHelper/DoxleDialogHelper";
import { useTheme } from "styled-components";
import usePricebookDeleteConfirmDialog from "../Hooks/usePricebookDeleteConfirmDialog";

type Props = {};

const PricebookDeleteConfirmDialog = (props: Props) => {
  const doxleTheme = useTheme();

  const {
    isDialogOpen,
    dialogDescription,
    handleDeleteSupplierRate,
    handleCloseDeleteDialog,
  } = usePricebookDeleteConfirmDialog();
  return (
    <DoxleDialogHelper
      open={isDialogOpen}
      onClose={handleCloseDeleteDialog}
      title="Confirm Delete Pricebook"
      description={dialogDescription}
      dialogButtons={[
        {
          buttonText: "Cancel",
          buttonFunction: handleCloseDeleteDialog,
          buttonColor: doxleTheme.color.primaryContainerColor,
          btnStyleSx: {
            border: `1px solid ${doxleTheme.color.primaryDividerColor}`,
          },
          buttonTextStyle: {
            color: doxleTheme.color.primaryFontColor,
          },
        },
        {
          buttonText: "Confirm",
          buttonFunction: handleDeleteSupplierRate,
          btnStyleSx: {
            border: `1px solid ${doxleTheme.color.primaryDividerColor}`,
          },
        },
      ]}
    />
  );
};

export default PricebookDeleteConfirmDialog;
