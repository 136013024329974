import {
  StyledCompanySettingsTextField,
  StyledEditCompanyDetailsSection,
  StyledCompanySettingsTextFieldWrapper,
  StyledCompanySettingsTextFieldLabel,
  StyledCompanySettingsTextArea,
  StyledCompanyLogoRow,
  StyledLogoDisplayer,
  StyledLogoText,
  StyledSaveButton,
  StyledSaveBtnContainer,
} from "../styledComponents";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import { Company } from "../../../Models/company";
import { useDoxleCurrentContextStore } from "../../../DoxleGeneralStore/useDoxleCurrentContext";
import CompanyQueryAPI from "../../../Services/QueryHooks/companyQueryAPI";
import { useCallback, useEffect, useState } from "react";
import { FileRejection, useDropzone } from "react-dropzone";
import { CircularProgress } from "@mui/material";

type EditCompany = Omit<
  Company,
  "companyId" | "projects" | "owner" | "totalStorageSize"
>;
const EDIT_COMPANY_FIELDS = [
  "Name",
  "ABN",
  "Office Phone",
  "Mobile Phone",
  "Icon",
  "Email",
  "Website",
  // "Address",
  "Company Type",
];

const EditCompanyDetailsSection = () => {
  const { currentCompany, setCurrentCompany, currentEditCompany } =
    useDoxleCurrentContextStore(
      (state) => ({
        setCurrentCompany: state.setCurrentCompany,
        currentCompany: state.currentCompany,
        currentEditCompany: state.currentEditCompany,
      }),
      shallow
    );

  const [name, setName] = useState<string>(currentCompany?.name ?? "");
  const [abn, setAbn] = useState<string>(currentCompany?.abn ?? "");
  const [officePhone, setOfficePhone] = useState<string>(
    currentCompany?.phone ?? ""
  );
  const [mobilePhone, setMobilePhone] = useState<string>(
    currentCompany?.mobilePhone ?? ""
  );
  const [icon, setIcon] = useState<File | null>(null);
  const [email, setEmail] = useState<string>(currentCompany?.email ?? "");
  const [website, setWebsite] = useState<string>(currentCompany?.website ?? "");
  const [disableBtn, setDisableBtn] = useState(true);
  const [companyType, setCompanyType] = useState<string>(
    currentCompany?.companyType ?? ""
  );

  const onDrop = useCallback(
    <T extends File>(acceptedFiles: T[], fileRejections: FileRejection[]) => {
      const file = acceptedFiles[0];
      if (file) {
        setIcon(file);
        setDisableBtn(false);
      }
    },
    []
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "image/jpeg": [".jpeg", ".png"],
    },
  });

  const styleProps = useDoxleThemeStore(
    (state) => ({
      $themeColor: state.doxleThemeColor,
      $doxleFont: state.doxleFont,
    }),
    shallow
  );

  const editCompanyQuery = CompanyQueryAPI.useUpdateCompanySettingDetail(
    (company: Company) => {
      setCurrentCompany(company);
      setDisableBtn(true);
    }
  );

  const getFieldValue = (fieldName: string) => {
    switch (fieldName) {
      case "Name": {
        return name;
      }
      case "ABN": {
        return abn;
      }
      case "Office Phone": {
        return officePhone;
      }
      case "Mobile Phone": {
        return mobilePhone;
      }
      case "Email": {
        return email;
      }
      case "Website": {
        return website;
      }
      case "Company Type": {
        return companyType;
      }
    }
    return "";
  };

  const handleInputChange = (val: string, fieldName: string) => {
    switch (fieldName) {
      case "Name": {
        setName(val);
        break;
      }
      case "ABN": {
        setAbn(val);
        break;
      }
      case "Office Phone": {
        setOfficePhone(val);
        break;
      }
      case "Mobile Phone": {
        setMobilePhone(val);
        break;
      }
      case "Email": {
        setEmail(val);
        break;
      }
      case "Website": {
        setWebsite(val);
        break;
      }
      case "Company Type": {
        setCompanyType(val);
      }
    }
    setDisableBtn(false);
  };

  const handleUpdateCompany = () => {
    editCompanyQuery.mutate({
      name: name,
      email: email,
      phone: officePhone,
      abn: abn,
      companyType: companyType,
      mobilePhone: mobilePhone,
      website: website,
    });
    if (icon !== null) {
      editCompanyQuery.mutate({
        logo: icon,
      });
    }
  };

  useEffect(() => {
    setName(currentCompany?.name ?? "");
    setAbn(currentCompany?.abn ?? "");
    setOfficePhone(currentCompany?.phone ?? "");
    setMobilePhone(currentCompany?.mobilePhone ?? "");
    setEmail(currentCompany?.email ?? "");
    setWebsite(currentCompany?.website ?? "");
    setCompanyType(currentCompany?.companyType ?? "");
  }, [currentCompany]);

  return (
    <>
      <StyledEditCompanyDetailsSection>
        {EDIT_COMPANY_FIELDS.map((fieldName, idx) => {
          if (fieldName === "Icon")
            return (
              <StyledCompanySettingsTextFieldWrapper
                key={`tabName#${fieldName}#${idx}`}
              >
                <StyledCompanySettingsTextFieldLabel {...styleProps}>
                  {fieldName}
                </StyledCompanySettingsTextFieldLabel>
                <StyledCompanyLogoRow {...styleProps}>
                  <StyledLogoDisplayer
                    {...styleProps}
                    {...getRootProps()}
                    style={{ cursor: "pointer" }}
                  >
                    <input {...getInputProps()} />
                    <img
                      src={currentCompany?.logo}
                      width={"100%"}
                      height={"100%"}
                    />
                  </StyledLogoDisplayer>
                  <StyledLogoText {...styleProps}>
                    Upload an image, for your company logo
                  </StyledLogoText>
                </StyledCompanyLogoRow>
              </StyledCompanySettingsTextFieldWrapper>
            );
          if (fieldName === "Address")
            return (
              <StyledCompanySettingsTextFieldWrapper
                key={`tabName#${fieldName}#${idx}`}
              >
                <StyledCompanySettingsTextFieldLabel {...styleProps}>
                  {fieldName}
                </StyledCompanySettingsTextFieldLabel>
                <StyledCompanySettingsTextArea
                  {...styleProps}
                  placeholder={fieldName}
                />
              </StyledCompanySettingsTextFieldWrapper>
            );
          return (
            <StyledCompanySettingsTextFieldWrapper
              key={`tabName#${fieldName}#${idx}`}
            >
              <StyledCompanySettingsTextFieldLabel {...styleProps}>
                {fieldName}
              </StyledCompanySettingsTextFieldLabel>
              <StyledCompanySettingsTextField
                {...styleProps}
                placeholder={fieldName}
                value={getFieldValue(fieldName)}
                onChange={(e) => handleInputChange(e.target.value, fieldName)}
              />
            </StyledCompanySettingsTextFieldWrapper>
          );
        })}
      </StyledEditCompanyDetailsSection>

      <StyledSaveBtnContainer>
        <StyledSaveButton
          $themeColor={styleProps.$themeColor}
          $doxleFont={styleProps.$doxleFont}
          onClick={handleUpdateCompany}
          disabled={disableBtn}
        >
          save
        </StyledSaveButton>
      </StyledSaveBtnContainer>
    </>
  );
};

export default EditCompanyDetailsSection;
