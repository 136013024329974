import {useNavigate, useParams} from "react-router-dom";
import XeroFirstAuth from "./FirstAuth";
import XeroCallBack from "./CallBack";

const Xero = () => {
  const navigation = useNavigate()
  const params = useParams()
  const paramsList: undefined|string[] = params?.['*']?.split('/')
  if (paramsList?.includes('First')) return (<XeroFirstAuth />)
  if (paramsList?.includes('CallBack')) return (<XeroCallBack />)
  return (<></>)
}

export default Xero