import React from "react";
import { QAFile } from "../../../Models/qa";
import { StyledQAImageItemContainer } from "./StyledComponentQAItemDetail";
import useQAFileItem from "./Hooks/useQAVideoItem";
import { Variants } from "framer-motion";
import CircularProgress from "@mui/material/CircularProgress";
import { FaPlay } from "react-icons/fa6";
import { useDoxleThemeStore } from "../../../../DoxleGeneralStore/useDoxleThemeStore";
import { useShallow } from "zustand/react/shallow";
import DoxleIconButton from "../../../../DoxleDesignPattern/DoxleButtons/DoxleIconButton";
import { CgTrashEmpty } from "react-icons/cg";
import { editRgbaAlpha } from "../../../../Utilities/FunctionUtilities";
import { useDoxleImageGalleryStore } from "../../../../DoxleDesignPattern/DoxleGallery/Store/useDoxleImageGalleryStore";

type Props = {
  fileItem: QAFile;
  setDeletedQAFile: React.Dispatch<React.SetStateAction<QAFile | undefined>>;
};

const QAFileItem = ({ fileItem, setDeletedQAFile }: Props) => {
  const { doxleThemeColor } = useDoxleThemeStore(
    useShallow((state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }))
  );
  const {
    isLoadingImg,
    isImgError,
    onLoadImgStart,
    onLoadImgEnd,
    onLoadImgError,
    isDeletingFile,
    thumbUrl
  } = useQAFileItem({ fileItem });
  const rootAnimatedVariants: Variants = {
    initial: {
      opacity: 0,
    },
    entering: {
      opacity: 1,
    },
    exit: {
      opacity: 0,
    },
    hovering: {
      scale: [1, 0.95],
      opacity: [1, 0.95],
    },
  };
  return (
    <StyledQAImageItemContainer
      variants={rootAnimatedVariants}
      initial="initial"
      animate="entering"
      transition={{
        duration: 0.4,
      }}
      onClick={() => window.open(fileItem.url)}
    >
      {isLoadingImg && (
        <CircularProgress className="img-loader" size="1.6rem" />
      )}

      <img
        className="img-holder"
        src={thumbUrl}
        onLoadStart={onLoadImgStart}
        onError={onLoadImgError}
        onLoadedData={onLoadImgEnd}
      />
      <div className="menu-wrapper">
        <DoxleIconButton
          iconSource={
            !isDeletingFile ? (
              <CgTrashEmpty style={{ color: "red" }} size="20px" />
            ) : (
              <CircularProgress style={{ color: "red" }} size="20px" />
            )
          }
          buttonWrapperStyle={{
            margin: "0px 4px",
          }}
          bgColor={editRgbaAlpha({
            rgbaColor: doxleThemeColor.primaryContainerColor,
            alpha: "0.8",
          })}
          buttonSize={30}
          onClick={(e) => {
            e.stopPropagation();
            setDeletedQAFile(fileItem);
          }}
        />
      </div>
    </StyledQAImageItemContainer>
  );
};

export default QAFileItem;
