import React, { useRef, useState } from "react";
import { useEditDocketSideScreenStore } from "../../../EditDocketSideScreenStore/useEditDocketSideScreenStore";
import { shallow } from "zustand/shallow";
import useSetDocketDetailQueryData from "../../../../QueryDataHooks/SetQueryDataHooks/useSetDocketDetailQueryData";
import { Docket } from "../../../../../Models/dockets";
import DocketQuery from "../../../../../Services/QueryHooks/docketQueryAPI";
import { useDoxleCurrentContextStore } from "../../../../../DoxleGeneralStore/useDoxleCurrentContext";
import useEffectAfterMount from "../../../../UtilityHooks/useEffectAfterMount";

type Props = {};
interface IStatusAndPercentSection {
  anchorStatusListPopover: HTMLDivElement | null;
  handleClickStatus: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  handleCloseStatusList: () => void;
  handleUpdatePercentage: (value: number) => void;
}
const useStatusAndPercentSection = (props: Props): IStatusAndPercentSection => {
  const [anchorStatusListPopover, setAnchorStatusListPopover] =
    useState<HTMLDivElement | null>(null);
  const { company } = useDoxleCurrentContextStore((state) => ({
    company: state.currentCompany,
  }));
  const {
    edittedDocket,
    updateEdittedDocket,
    filterDocketListQuery,
    handlePostUpdateDocket,
  } = useEditDocketSideScreenStore(
    (state) => ({
      edittedDocket: state.edittedDocket,
      updateEdittedDocket: state.updateEdittedDocket,
      filterDocketListQuery: state.filterDocketListQuery,
      handlePostUpdateDocket: state.handlePostUpdateDocket,
    }),
    shallow
  );
  const { handleUpdateDocket } = useSetDocketDetailQueryData({
    docketPk: edittedDocket?.docketPk ?? "",
  });
  const onSuccessUpdate = (newDocket?: Docket) => {
    if (newDocket) {
      if (handlePostUpdateDocket) handlePostUpdateDocket(newDocket);
      handleUpdateDocket(newDocket);
    }
  };
  const updateDocketQuery = DocketQuery.useUpdateDocketQuery({
    filter: filterDocketListQuery,

    company,
    onSuccessCb: onSuccessUpdate,
  });
  const previousPercentageCompleted = useRef<number | undefined>(
    edittedDocket?.percentageCompleted
  );
  const handleUpdatePercentage = (value: number) => {
    if (edittedDocket) {
      updateEdittedDocket({
        percentageCompleted: value,
      });
    }
  };
  const handleClickStatus = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    e.stopPropagation();
    setAnchorStatusListPopover(e.currentTarget);
  };
  const handleCloseStatusList = () => setAnchorStatusListPopover(null);

  useEffectAfterMount(() => {
    if (
      previousPercentageCompleted.current !== edittedDocket?.percentageCompleted
    ) {
      const timeout = setTimeout(() => {
        if (edittedDocket?.percentageCompleted !== undefined) {
          previousPercentageCompleted.current =
            edittedDocket?.percentageCompleted;
          updateDocketQuery.mutate({
            docketPk: edittedDocket.docketPk,
            percentageCompleted: edittedDocket.percentageCompleted,
          });
        }
      }, 400);

      return () => clearTimeout(timeout);
    }
  }, [edittedDocket?.percentageCompleted, previousPercentageCompleted.current]);

  return {
    anchorStatusListPopover,
    handleClickStatus,
    handleCloseStatusList,
    handleUpdatePercentage,
  };
};

export default useStatusAndPercentSection;
