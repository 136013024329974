import React from "react";
import { IPricebookItemGroupInfo } from "../../Models/PricebookTable";
import { StyledPricebookGroupHeader } from "./StyledComponents";
import { usePricebookStore } from "../../Store/usePricebookStore";
import { useShallow } from "zustand/react/shallow";
import { FaChevronUp } from "react-icons/fa";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
type Props = {
  headerItem: IPricebookItemGroupInfo;
};

const PricebookGroupHeader = ({ headerItem }: Props) => {
  const { staticMenuColor } = useDoxleThemeStore(
    useShallow((state) => ({
      staticMenuColor: state.staticMenuColor,
    }))
  );
  const { setShrinkedPricebookSections, shrinkedPricebookSections } =
    usePricebookStore(
      useShallow((state) => ({
        setShrinkedPricebookSections: state.setShrinkedPricebookSections,
        shrinkedPricebookSections: state.shrinkedPricebookSections,
      }))
    );
  const isExpand = !shrinkedPricebookSections.includes(headerItem.pricebookId);
  return (
    <StyledPricebookGroupHeader
      onClick={() => setShrinkedPricebookSections(headerItem.pricebookId)}
    >
      {headerItem.pricebookDescription}

      <FaChevronUp
        color={staticMenuColor.staticFontColor}
        size={12}
        style={{
          transform: `rotate(${isExpand ? 180 : 0}deg)`,
          transition: "transform 0.2s ease-in-out",
          marginLeft: 4,
        }}
      />
    </StyledPricebookGroupHeader>
  );
};

export default PricebookGroupHeader;
