import React, { createContext, useContext, useMemo, useState } from "react";
import {
  StyledAddQuoteResponseContainer,
  StyledAddQuotesButton,
} from "./StyledComponentsAddQuoteResponse";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { useDoxleCurrentContextStore } from "../../../DoxleGeneralStore/useDoxleCurrentContext";
import { shallow } from "zustand/shallow";
import { QuoteResponse } from "../../models/quote";
import {
  formatTISODateTime,
  formatTISODate,
} from "../../../Utilities/FunctionUtilities";
import SelectQuoteRequest from "./SelectQuoteRequest";
import SelectQuoteResponseDate from "./SelectQuoteResponseDate";
import AddQuoteResponseReference from "./AddQuoteResponseReference";
import AddPaymentTermResponse from "./AddPaymentTermResponse";
import AddResponseAttachments from "./AddResponseAttachments";
import AddResponseLineSection from "./AddResponseLineSection";
import QuoteTotalSection from "./QuoteTotalSection";
import { motion } from "framer-motion";
import { useDoxleQuoteStore } from "../../store/useDoxleQuoteStore";
import { useDoxleAuthStore } from "../../../DoxleGeneralStore/useDoxleAuthStore";
import useDoxleNotificationStore from "../../../DoxleGeneralStore/useDoxleNotificationStore";
import QuotesQueryAPI from "../../QueryAPI/quotesQuery";
import { useEditDocketSideScreenStore } from "../../../CoreContent/EditDocketSideScreen/EditDocketSideScreenStore/useEditDocketSideScreenStore";
import { CircularProgress } from "@mui/material";
import useSetQuoteResponseData from "../../CustomQueryHooks/useSetQuoteResponseData";
import AddQuoteContractor from "./AddQuoteContractor";

type Props = {};

const initialQuotesResponse: QuoteResponse = {
  responseId: "",
  request: null,
  company: "",
  project: "",
  docket: "",
  supplier: null,
  supplierName: "",
  contactName: "",
  contactEmail: "",
  contactId: "",
  quoteSubTotal: "0",
  status: null,
  quoteTotal: "0",
  timeStamp: formatTISODateTime(new Date()),
  lines: [],
  attachmentsCount: 0,
  attachments: [],
  paymentTerms: 14,
  paymentTermsSuffix: "Days",
  expiryDate: formatTISODate(new Date()),
  reference: "",
};

interface AddQuoteResponseContextValue {
  newQuoteResponse: QuoteResponse;
  setNewQuoteResponse: React.Dispatch<React.SetStateAction<QuoteResponse>>;
  setAddedAttachments: React.Dispatch<React.SetStateAction<File[]>>;
  addedAttachments: File[];
  referenceWarning: boolean;
  setReferenceWarning: React.Dispatch<React.SetStateAction<boolean>>;
  contractorWarning: boolean;
  setContractorWarning: React.Dispatch<React.SetStateAction<boolean>>;
}
const AddQuoteResponseContext =
  createContext<AddQuoteResponseContextValue | null>(null);
const AddQuoteResponse = (props: Props) => {
  const [newQuoteResponse, setNewQuoteResponse] = useState<QuoteResponse>(
    initialQuotesResponse
  );
  const [addedAttachments, setAddedAttachments] = useState<File[]>([]);
  const [referenceWarning, setReferenceWarning] = useState(false);
  const [contractorWarning, setContractorWarning] = useState(false);
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore((state) => ({
    doxleThemeColor: state.doxleThemeColor,
    doxleFont: state.doxleFont,
  }));
  const company = useDoxleCurrentContextStore(
    (state) => state.currentCompany,
    shallow
  );
  const { edittedDocket } = useEditDocketSideScreenStore(
    (state) => ({ edittedDocket: state.edittedDocket }),
    shallow
  );

  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );
  const { filterGetQuoteResponse } = useDoxleQuoteStore(
    (state) => ({ filterGetQuoteResponse: state.filterGetQuoteResponse }),
    shallow
  );
  const { handleAddQuoteResponse: addResponseQueryData } =
    useSetQuoteResponseData({ filter: filterGetQuoteResponse });
  const onSuccessAddQuoteResponse = (newQResponse?: QuoteResponse) => {
    if (newQResponse) addResponseQueryData(newQResponse);
    setView("QuoteRequestsTable");
  };
  const addResponseQuery = QuotesQueryAPI.useAddQuoteResponse({
    showNotification,
    onSuccessCb: onSuccessAddQuoteResponse,
  });
  const { setView } = useDoxleQuoteStore(
    (state) => ({ setView: state.setView }),
    shallow
  );

  const handleClickCancelBtn = () => setView("QuoteRequestsTable");

  const isDisableAddBtn = Boolean(
    !newQuoteResponse.paymentTerms ||
      !newQuoteResponse.reference ||
      !newQuoteResponse.supplier
  );

  const handleAddQuoteResponse = () => {
    let valid = true;
    if (newQuoteResponse.reference.length === 0) {
      setReferenceWarning(true);
      valid = false;
    } else {
      setReferenceWarning(false);
    }
    if (!newQuoteResponse.supplier) {
      setContractorWarning(true);
      valid = false;
    } else {
      setContractorWarning(false);
    }
    if (!valid) {
      return;
    }
    const newResponse = {
      ...newQuoteResponse,
      company: company?.companyId ?? "",
      docket: edittedDocket?.docketPk ?? "",
      project: edittedDocket?.project ?? null,
    };
    console.log(newResponse);
    addResponseQuery.mutate({
      quoteResponse: newResponse,

      attachment: addedAttachments,
    });
  };
  const contextValue: AddQuoteResponseContextValue = useMemo(
    () => ({
      newQuoteResponse,
      setNewQuoteResponse,
      setAddedAttachments,
      addedAttachments,
      referenceWarning,
      setReferenceWarning,
      contractorWarning,
      setContractorWarning,
    }),
    [
      newQuoteResponse,
      setNewQuoteResponse,
      setAddedAttachments,
      addedAttachments,
      referenceWarning,
      setReferenceWarning,
      contractorWarning,
      setContractorWarning,
    ]
  );
  return (
    <AddQuoteResponseContext.Provider value={contextValue}>
      <StyledAddQuoteResponseContainer
        $themeColor={doxleThemeColor}
        $doxleFont={doxleFont}
        initial={{
          y: 10,
          opacity: 0,
        }}
        animate={{
          y: 0,
          opacity: 1,
        }}
      >
        <div className="banner-company-name">{company?.name}</div>
        <div className="quote-title-text">Add Quote Response</div>
        <div className="quote-notice-text">
          Please check the scope of works and quotes to ensure you have included
          all required costs. Explicitly note any exclusions where you are not
          able to meet our requirements.
        </div>
        <div className="add-quote-content">
          {/* <SelectQuoteRequest /> */}
          <AddQuoteResponseReference />
          <AddQuoteContractor />
          <SelectQuoteResponseDate />
          <AddPaymentTermResponse />

          <AddResponseAttachments />
          <AddResponseLineSection />
          <QuoteTotalSection />

          <motion.div className="add-quote-btn" layout>
            <StyledAddQuotesButton
              $doxleFont={doxleFont}
              $bgColor={doxleThemeColor.primaryContainerColor}
              $textColor={doxleThemeColor.primaryFontColor}
              $borderColor={doxleThemeColor.rowBorderColor}
              onClick={handleClickCancelBtn}
            >
              Cancel
            </StyledAddQuotesButton>

            <StyledAddQuotesButton
              $doxleFont={doxleFont}
              $bgColor={doxleThemeColor.primaryFontColor}
              $textColor={doxleThemeColor.primaryContainerColor}
              onClick={handleAddQuoteResponse}
            >
              {addResponseQuery.isLoading && (
                <CircularProgress
                  style={{
                    color: doxleThemeColor.primaryContainerColor,
                    marginRight: "0.4rem",
                  }}
                  size="1.6rem"
                />
              )}
              {addResponseQuery.isLoading ? "Adding..." : "Add"}
            </StyledAddQuotesButton>
          </motion.div>
        </div>
      </StyledAddQuoteResponseContainer>
    </AddQuoteResponseContext.Provider>
  );
};
export const useAddQuoteResponseContext = () =>
  useContext(AddQuoteResponseContext) as AddQuoteResponseContextValue;
export default AddQuoteResponse;
