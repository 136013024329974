import React from "react";
import {
  StyledBookingPercentageText,
  StyledEditBookingLabelText,
  StyledEditBookingPercentageContainer,
} from "./StyledComponentEditBookingDocket";
import { useDoxleThemeStore } from "../../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import useEditBookingPercentage from "./Hooks/useEditBookingPercentage";
import BookingPercentageSlider from "../../../CommonComponents/BookingPercentageSlider";
import { useEditDocketBookingContext } from "./EditBookingDocket";

type Props = {};

const EditBookingPercentage = (props: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
      currentTheme: state.currentTheme,
    }),
    shallow
  );
  const { newPercentageComplete, setNewPercentageComplete } =
    useEditBookingPercentage({});
  const { percentageComplete, setPercentageComplete } =
    useEditDocketBookingContext();
  return (
    <StyledEditBookingPercentageContainer>
      <StyledEditBookingLabelText
        $themeColor={doxleThemeColor}
        $doxleFont={doxleFont}
      >
        Percent Complete
      </StyledEditBookingLabelText>

      <BookingPercentageSlider
        value={percentageComplete}
        onChangeValue={(value) => setPercentageComplete(value)}
      />

      <StyledBookingPercentageText
        $themeColor={doxleThemeColor}
        $doxleFont={doxleFont}
      >
        {percentageComplete}%
      </StyledBookingPercentageText>
    </StyledEditBookingPercentageContainer>
  );
};

export default EditBookingPercentage;
