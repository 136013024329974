import { shallow } from "zustand/shallow";
import { Docket, NEW_DOCKET_TEMPLATE } from "../../../../Models/dockets";
import { useDoxleCurrentContextStore } from "../../../../DoxleGeneralStore/useDoxleCurrentContext";
import { useDoxleAuthStore } from "../../../../DoxleGeneralStore/useDoxleAuthStore";
import useDoxleNotificationStore from "../../../../DoxleGeneralStore/useDoxleNotificationStore";
import { useInboxStore } from "../../../InboxStore/useInboxStore";
import DocketQuery from "../../../../Services/QueryHooks/docketQueryAPI";
import { RefObject, useRef, useState } from "react";
import useSetDocketListQueryData from "../../../../CoreContent/QueryDataHooks/SetQueryDataHooks/useSetDocketListQueryData";

type Props = {
  scrollToBottom: () => void;
};

interface NoticeBoardContentBodyFooter {
  handleAddNewNoticeBoardDocket: (docketName: string) => void;
  isAddingdocket: boolean;
  handleInputKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  newNbTitleText: string;
  handleAddNewDocket: () => void;
  newTitleInputRef: RefObject<HTMLInputElement>;
}
const useNoticeBoardContentBodyFooter = ({
  scrollToBottom,
}: Props): NoticeBoardContentBodyFooter => {
  const [newNbTitleText, setNewNbTitleText] = useState("");
  const { user } = useDoxleAuthStore((state) => ({
    user: state.user,
  }));
  const { company } = useDoxleCurrentContextStore((state) => ({
    company: state.currentCompany,
  }));
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );
  const { filterDocketListQuery } = useInboxStore(
    (state) => ({
      filterDocketListQuery: state.filterDocketListQuery,
    }),
    shallow
  );
  const {
    handleAddDocket: handleAddAllNoticeDockets,
    isDocketExist: isAllDocketExist,
  } = useSetDocketListQueryData({
    filter: {
      ...filterDocketListQuery,
      ball_in_court: undefined,
      archived: false,
    },
  });
  const onSuccessCb = (newDocket?: Docket) => {
    setNewNbTitleText("");
    scrollToBottom();
    if (newDocket && !isAllDocketExist(newDocket.docketPk))
      handleAddAllNoticeDockets(newDocket);
  };
  const addDocketQuery = DocketQuery.useAddDocketQuery({
    filter: filterDocketListQuery,
    company,
    showNotification,

    onSuccessCb: onSuccessCb,
  });

  const handleAddNewNoticeBoardDocket = (docketName: string) => {
    const newDocket = NEW_DOCKET_TEMPLATE({
      docketName: docketName,
      isNoticeboard: true,
      createdBy: user ? (user.userId as string) : "",
      company: company?.companyId ?? "",
      ballInCourt: user?.userId,
    });
    addDocketQuery.mutate(newDocket);
  };
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setNewNbTitleText(value);
  };
  const handleAddNewDocket = () => {
    if (newNbTitleText) {
      handleAddNewNoticeBoardDocket(newNbTitleText);
    }
  };

  const newTitleInputRef = useRef<HTMLInputElement>(null);
  const handleInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (
      event.nativeEvent.key === "Enter" &&
      !event.shiftKey &&
      !event.ctrlKey
    ) {
      event.preventDefault();
      handleAddNewDocket();
    } else if (event.nativeEvent.key === "Escape" && !newNbTitleText) {
      newTitleInputRef.current?.blur();
    } else if (event.nativeEvent.key === "Escape" && newNbTitleText)
      setNewNbTitleText("");
  };
  return {
    handleAddNewNoticeBoardDocket,
    isAddingdocket: addDocketQuery.isLoading,
    handleInputKeyDown,
    handleInputChange,
    newNbTitleText,
    handleAddNewDocket,
    newTitleInputRef,
  };
};

export default useNoticeBoardContentBodyFooter;
