import React, { useMemo } from "react";
import MailConversationAPI from "./mailConversationAPI";
import { Mail, MailComments } from "../Models/mail";

type Props = { mailId?: string };

interface GetMailComments {
  mailComments: MailComments[];
  isFetchingMailComments: boolean;
  isErrorFetchingMailComments: boolean;
  isSuccessFetchingMailComments: boolean;
  fetchingNextMailComment: () => void;
  isFetchingNextPage: boolean;
}
const useGetMailComments = ({ mailId }: Props): GetMailComments => {
  const replyMailQuery = MailConversationAPI.useRetrieveReplyMail({
    mailId,
    enabled: Boolean(mailId),
  });
  const mailCommentsQuery = MailConversationAPI.useRetrieveMailComments({
    mailId,
    enabled: Boolean(replyMailQuery.isSuccess && mailId),
  });
  const mailComments: MailComments[] = useMemo(
    () =>
      mailCommentsQuery.data?.pages.flatMap(
        (page) => (page?.data?.results as MailComments[]) ?? []
      ) ?? [],
    [mailCommentsQuery.data]
  );

  const fetchingNextMailComment = () => {
    if (mailCommentsQuery.hasNextPage) {
      mailCommentsQuery.fetchNextPage();
    }
  };
  return {
    mailComments,
    isFetchingMailComments: mailCommentsQuery.isLoading,
    isErrorFetchingMailComments: mailCommentsQuery.isError,
    isSuccessFetchingMailComments: mailCommentsQuery.isSuccess,
    isFetchingNextPage: mailCommentsQuery.isFetchingNextPage,
    fetchingNextMailComment,
  };
};

export default useGetMailComments;
