import React, { memo } from "react";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { useEditPricebookItemContext } from "./EditPricebookItem";
import { StyledAddedSwatchContainer } from "./StyledAddEditPricebookItem";
import { IoCloseCircleSharp } from "react-icons/io5";
import { produce } from "immer";
import { useDoxleImageGalleryStore } from "../../../DoxleDesignPattern/DoxleGallery/Store/useDoxleImageGalleryStore";
import { useShallow } from "zustand/react/shallow";
type Props = {
  item: File;
  itemIndex: number;
  onRemoveAddedSwatch: (index: number) => void;
};

const AddedSwatchItem = memo(
  ({ item, itemIndex, onRemoveAddedSwatch }: Props) => {
    const { doxleThemeColor } = useDoxleThemeStore((state) => ({
      doxleThemeColor: state.doxleThemeColor,
    }));
    const itemUrl = URL.createObjectURL(item);

    const { setImageList } = useDoxleImageGalleryStore(
      useShallow((state) => ({
        setImageList: state.setImageList,
      }))
    );
    return (
      <StyledAddedSwatchContainer layout="position">
        <img
          src={itemUrl}
          onClick={(e) => {
            setImageList([
              {
                uri: itemUrl,
                name: "Image#1",
              },
            ]);
          }}
        />
        <IoCloseCircleSharp
          color={doxleThemeColor.primaryFontColor}
          size={20}
          className="close-icon"
          onClick={() => onRemoveAddedSwatch(itemIndex)}
        />
      </StyledAddedSwatchContainer>
    );
  }
);

export default AddedSwatchItem;
