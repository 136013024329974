import { motion } from "framer-motion";
import styled from "styled-components";

import { Button } from "@mui/material";
import {
  DoxleFont,
  DoxleThemeColor,
} from "../../DoxleGeneralStore/useDoxleThemeStore";

export const StyledDoxleEmptyPlaceHolderContainer = styled(motion.div)<{
  $widthInPixel?: `${number}px`;
  $widthRatio?: `${number}%`;
  $heightInPixel?: `${number}px`;
  $heightRatio?: `${number}%`;
  $containerBgColor?: string;
  $themeColor: DoxleThemeColor;
}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${(p) =>
    p.$containerBgColor || p.$themeColor.primaryContainerColor};
  position: relative;
`;

export const StyledDoxleEmptyPlaceHolderHeadTitleText = styled.span<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
  $headTitleFontSizeInPixel?: `${number}px`;
}>`
  color: ${(p) => p.$themeColor.primaryFontColor};
  // font-size: 4.2rem;
  font-family: ${(p) => p.$doxleFont.primaryFont};
  font-weight: 700;
  text-transform: capitalize;
  margin-bottom: 14px;
  text-align: center;
  // @media (min-width: 768px) {
  //   font-size: 2.25vw;
  // }
  // @media (max-width: 767px) {
  //   font-size: 2.5vw;
  // }
  // @media (min-width: 1024px) {
  //   font-size: 3vw;
  // }
  // @media (min-width: 1280px) {
  //   font-size: 3.5vw;
  // }
`;
export const StyledDoxleEmptyPlaceHolderSubTitleText = styled.span<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
  $subTitleFontSizeInPixel?: `${number}px`;
}>`
  color: ${(p) => p.$themeColor.primaryFontColor};
  font-size: 1.5rem;
  font-family: ${(p) => p.$doxleFont.secondaryFont};
  font-weight: 500;
  text-transform: capitalize;
  text-align: center;
`;
export const StyledPlaceholderAddBtn = styled(motion(Button))<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
  $btnWidthInPixel?: `${number}px`;
  $btnHeightInPixel?: `${number}px`;
  $btnColor?: string;
  $btnTextColor?: string;
  $btnTextSize?: number;
}>`
  && {
    min-width: 40px !important;

    color: ${(p) => p.$btnTextColor ?? p.$themeColor.primaryFontColor};
    font-size: 2.4rem;
    font-family: ${(p) => p.$doxleFont.secondaryFont};
    font-weight: 700;
    text-transform: capitalize;
    font-style: normal;
    border-radius: 4px;
    background-color: ${(p) =>
      p.$btnColor ? p.$btnColor : p.$themeColor.doxleColor} !important;

    width: ${(p) => p.$btnWidthInPixel ?? "120px"} !important;
    height: ${(p) => p.$btnHeightInPixel ?? "40px"} !important;

    display: flex;
    flex-direction: row;
    margin-top: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    @media (min-width: 768px) {
      font-size: ${(p) => (p.$btnTextSize ? p.$btnTextSize - 8 : 16)}px;
      width: ${(p) =>
        p.$btnWidthInPixel
          ? `calc(${p.$btnWidthInPixel} - 40px)`
          : "80px"} !important;
      height: ${(p) =>
        p.$btnHeightInPixel
          ? `calc(${p.$btnHeightInPixel} - 10px)`
          : "30px"} !important;
    }
    @media (max-width: 767px) {
      font-size: ${(p) => (p.$btnTextSize ? p.$btnTextSize - 8 : 16)}px;
      width: ${(p) =>
        p.$btnWidthInPixel
          ? `calc(${p.$btnWidthInPixel} - 40px)`
          : "80px"} !important;
      height: ${(p) =>
        p.$btnHeightInPixel
          ? `calc(${p.$btnHeightInPixel} - 10px)`
          : "30px"} !important;
    }
    @media (min-width: 1024px) {
      font-size: ${(p) => (p.$btnTextSize ? p.$btnTextSize - 4 : 20)}px;
      width: ${(p) =>
        p.$btnWidthInPixel
          ? `calc(${p.$btnWidthInPixel} - 20px)`
          : "100px"} !important;
      height: ${(p) =>
        p.$btnHeightInPixel
          ? `calc(${p.$btnHeightInPixel} - 5px)`
          : "35px"} !important;
    }
    @media (min-width: 1280px) {
      font-size: ${(p) => p.$btnTextSize ?? 24}px;
      width: ${(p) => p.$btnWidthInPixel ?? "120px"} !important;
      height: ${(p) => p.$btnHeightInPixel ?? "40px"} !important;
    }
  }
`;
export const StyledPlaceholderImage = styled(motion.img)<{
  $imgHeightInPixel?: `${number}px`;
  $imgWidthInPixel?: `${number}px`;
  $absPosInPixel?: {
    topInPixel?: `${number}px`;
    leftInPixel?: `${number}px`;
    rightInPixel?: `${number}px`;
    bottomInPixel?: `${number}px`;
  };
}>`
  width: ${(p) => p.$imgWidthInPixel || "200px"};
  height: ${(p) => p.$imgHeightInPixel || "300px"};
  position: absolute;

  z-index: 10;
  ${(p) =>
    p.$absPosInPixel &&
    p.$absPosInPixel.topInPixel &&
    `top: ${p.$absPosInPixel.topInPixel};`}
  ${(p) =>
    p.$absPosInPixel &&
    p.$absPosInPixel.rightInPixel &&
    `right: ${p.$absPosInPixel.rightInPixel};`}
  ${(p) =>
    p.$absPosInPixel &&
    p.$absPosInPixel.leftInPixel &&
    `left: ${p.$absPosInPixel.leftInPixel};`}
  ${(p) =>
    p.$absPosInPixel &&
    p.$absPosInPixel.bottomInPixel &&
    `bottom: ${p.$absPosInPixel.bottomInPixel};`}
`;
