import styled from "styled-components";
import {Button, Checkbox, Chip, Dialog, Menu, TextField, Typography} from "@mui/material";
import {DoxleFont, DoxleTheme, DoxleThemeColor} from "../../DoxleGeneralStore/useDoxleThemeStore";
import {motion} from "framer-motion";
import React from "react";
import {editRgbaAlpha} from "../../Utilities/FunctionUtilities";
import DoxleStatus from "../../DoxleDesignPattern/DoxleStatus/DoxleStatus";

export const StyledShareDocketFullScreenContainer = styled.div<{
  $doxleFont?: DoxleFont,
  $themeColor?: DoxleThemeColor,
  $currentTheme?: DoxleTheme
}>`
  height: Calc(100vh - 300px) !important;
  display: flex;
  flex-direction: row;
  background-color: ${(p) => p?.$themeColor?.primaryBackgroundColor ?? 'rgb(245, 245, 244)'} !important;
  padding: 26px;
  justify-content: space-between;
  width: 100%;
`;


export const StyledShareDocketTabContainer = styled.div<{
  $doxleFont?: DoxleFont,
  $themeColor?: DoxleThemeColor,
  $currentTheme?: DoxleTheme
  $percentageWidth?: number;
}>`
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${(p) => p?.$themeColor?.primaryBackgroundColor ?? 'rgb(245, 245, 244)'} !important;
  padding: 50px 26px 26px;
  /* width: Calc(${(p) => p?.$percentageWidth ?? 100}% - 52px) !important;
  max-width: ${(p) => p?.$percentageWidth ?? 100}% !important; */
  width: 50%;
  min-width: 300px;
`;

export const StyledSelectedContactsContainer = styled.div<{
  $doxleFont?: DoxleFont,
  $themeColor?: DoxleThemeColor,
  $currentTheme?: DoxleTheme
}>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  min-height: 35px;
  padding: 20px;
`

export const StyledContactChip = styled(Chip)<{
  $doxleFont?: DoxleFont,
  $themeColor?: DoxleThemeColor,
  $currentTheme?: DoxleTheme
}>`
  background-color: ${(p) => p?.$themeColor?.doxleColor ?? '#7070ff'} !important;
  color: white !important;
  font-size: 14px !important;
  margin-right: 5px !important;
  margin-top: 5px !important;
`

export const StyledShareLabel = styled(Typography)<{
  $doxleFont?: DoxleFont,
  $themeColor?: DoxleThemeColor,
  $currentTheme?: DoxleTheme
}>`
  font-family: ${(p) => p?.$doxleFont?.primaryFont ?? "Inter"};
  font-size: 14px !important;
  color: ${(p) => p?.$themeColor?.primaryFontColor ?? 'rgba(0,0,0,1)'};
  font-weight: 600 !important;
`

export const StyledSelectContactRow = styled.div<{
  $doxleFont?: DoxleFont,
  $themeColor?: DoxleThemeColor,
  $currentTheme?: DoxleTheme
  $expanded: boolean
}>`
  display: flex;
  flex-direction: column;
  margin-bottom: 60px;
  background-color: ${(p) => editRgbaAlpha({
    rgbaColor: p?.$themeColor?.primaryReverseFontColor ?? "rgba(255,255,255,1)",
    alpha: "0.7"
  })} !important;
  min-height: ${(p) => p?.$expanded ? "425px": "unset"};
  width: 100%;
  border-radius: 0px 4px 4px 4px;
  padding: 18px;
  transition: 0.4s;
`

export const StyledInputRow = styled.div<{
  $doxleFont?: DoxleFont,
  $themeColor?: DoxleThemeColor,
  $currentTheme?: DoxleTheme
}>`
  display: flex;
  flex-direction: row;
  width: 90%;
`

export const StyledSelectContactContainer = styled.div<{
  $doxleFont?: DoxleFont,
  $themeColor?: DoxleThemeColor,
  $currentTheme?: DoxleTheme
}>`
  width: 60%;
  max-width: 408px;
`

export const StyledAddContactButtonContainer = styled.div<{
  $doxleFont?: DoxleFont,
  $themeColor?: DoxleThemeColor,
  $currentTheme?: DoxleTheme
}>`
  width: 40%;
  min-width: 60px;
  padding-left: 25px;
`

export const StyledShareButton = styled(Button)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
  $bgColor?: string;
}>`
  && {
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.05em;
    color: ${(p) => p.$themeColor.primaryReverseFontColor};
    background-color: ${(p) => p.$bgColor ? p.$bgColor : p.$themeColor.doxleColor};
    margin: 0px 4px;
    text-transform: capitalize;
    min-width: 60px;
    };
  }
  &.Mui-disabled {
    background-color: ${(p) => p.$themeColor.primaryBoxShadowColor} !important;
  }
`;

export const StyledSharedContactRow = styled.div<{
  $doxleFont?: DoxleFont,
  $themeColor?: DoxleThemeColor,
  $currentTheme?: DoxleTheme
}>`
  display: flex;
  flex-direction: row;
  width: 100%;
  min-height:40px;
  max-height: 45px;
  border-bottom: 1px solid ${(p) => p?.$themeColor?.primaryDividerColor ?? "rgba(230,232,232,1)"};
  overflow-y: hidden;
  overflow-x: visible;
  padding-bottom: 5px;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
`

export const StyledSharedContactInfo = styled.div<{
  $doxleFont?: DoxleFont;
  $themeColor?: DoxleThemeColor;
  $currentTheme?: DoxleTheme;
  $fullWidth?: boolean;
}>`
  display: flex;
  flex-direction: column;
  width: ${(p) => p?.$fullWidth ? "100%" : "35%"};
  min-width: 150px;
  padding: ${(p) => p?.$fullWidth ? "3px 0px" : "8px 0px"};
`

export const StyledPermissionContainer = styled.div<{
$doxleFont?: DoxleFont,
$themeColor?: DoxleThemeColor,
$currentTheme?: DoxleTheme
}>`
  display: flex;
  flex-direction: row;
  width: 65%;
  min-width: 150px;
  padding: 10px 0px;
  justify-content: center;
`

export const StyledContactName= styled.p<{
  $doxleFont?: DoxleFont,
  $themeColor?: DoxleThemeColor,
  $currentTheme?: DoxleTheme
}>`
  font-family: ${(p) => p?.$doxleFont?.primaryFont ?? "Inter"};
  font-size: min(1.4rem, 14px) !important;
  color: ${(p) => p?.$themeColor?.primaryFontColor ?? 'rgba(0,0,0,1)'};
  font-weight: 600 !important;
  padding: 0px !important;
  margin: 0px !important;
`

export const StyledContactEmail =  styled.p<{
  $doxleFont?: DoxleFont,
  $themeColor?: DoxleThemeColor,
  $currentTheme?: DoxleTheme
}>`
  font-family: ${(p) => p?.$doxleFont?.primaryFont ?? "Inter"};
  font-size: min(1.2rem, 12px) !important;
  color: ${(p) => p?.$themeColor?.primaryFontColor ?? 'rgba(0,0,0,1)'};
  font-weight: 600 !important;
  padding: 0px !important;
  margin: 0px !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

export const StyledPermissionSelect = styled.div<{
  $doxleFont?: DoxleFont,
  $themeColor?: DoxleThemeColor,
  $currentTheme?: DoxleTheme
}>`
  height: 20px;
  max-height: 27px;
  background-color: ${(p) => editRgbaAlpha({
    rgbaColor: p?.$themeColor?.primaryReverseFontColor ?? "rgba(255,255,255,1)",
    alpha: "0.7"
  })} !important;
  color: ${(p) =>  p?.$themeColor?.primaryFontColor ?? "rgba(0,0,0,1)"} !important;
  border: 1px solid ${(p) => p?.$themeColor?.primaryDividerColor ?? "rgba(230,232,232,1)"};
  border-radius: 0px 4px 4px 4px;
  min-width: 140px;
  //width: 140px;
  max-width: 400px;
  font-family: ${(p) => p.$doxleFont?.subTitleFont ?? "IBM Plex Mono" } !important;
  font-size: min(1.4rem, 14px) !important;
  font-weight: 500;
  line-height: 17px;
  padding: 5px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const StyledEditPermissionsDropDownMenu = styled(Menu)<{
  $doxleFont?: DoxleFont,
  $themeColor?: DoxleThemeColor,
  $currentTheme?: DoxleTheme
}>`
  
  .MuiPaper-root {
    display: flex;
    width: 300px;
    max-height: 600px;
    justify-content: center;
    background-color: ${(p) => editRgbaAlpha({
      rgbaColor: p?.$themeColor?.primaryReverseFontColor ?? "rgba(255,255,255,1)",
      alpha: "1"
    })} !important;
    border-radius: 0px 4px 4px 4px !important;
  }
  .MuiList-root {
    width: Calc(100% - 20px);
  }
  .MuiMenuItem-root {
    border-bottom:  1px solid ${(p) => p?.$themeColor?.primaryDividerColor ?? "#EFF0F4"} !important;
    font-family: ${(p) => p?.$doxleFont?.primaryFont ?? "Inter"};
    font-size: 12px;
    color: ${(p) => editRgbaAlpha({
      rgbaColor: p?.$themeColor?.primaryFontColor ?? "rgba(0,0,0,1)",
      alpha: "0.7"
    })} !important;
  }
  .MuiMenuItem-root:hover {
    //background-color: ${(p) => p?.$themeColor?.primaryDividerColor ?? "red"} !important;
  }
`

export const StyledMenuItem = styled.div<{
  $doxleFont?: DoxleFont,
  $themeColor?: DoxleThemeColor,
  $currentTheme?: DoxleTheme
  $color?: string
  $clickable?: boolean
}>`
  display: flex;
  width: Calc(100% - 20px);
  min-height: 46px;
  flex-direction: column;
  font-family: ${(p) => p?.$doxleFont?.subTitleFont ?? 'IBM Plex Mono'};
  font-size: 13px;
  color: ${(p) => p?.$color ?? p?.$themeColor?.primaryFontColor ?? "rgba(0,0,0,1)"};
  padding: 5px 10px;
  font-weight: 600;
  border-bottom:  1px solid ${(p) => p?.$themeColor?.primaryDividerColor ?? "#EFF0F4"};
  justify-content: center;
  cursor: ${(p) => p?.$clickable ? "pointer" : "unset"};
  &&:hover {
    background-color: ${(p) => p?.$clickable ? p?.$themeColor?.hoverColor ?? "rgba(126, 150, 187, 0.12)" : "unset"} !important;
  }
`
export const StyledCheckBoxColumnContainer = styled.div<{
  $doxleFont?: DoxleFont,
  $themeColor?: DoxleThemeColor,
  $currentTheme?: DoxleTheme
}>`
  display: flex;
  width: 100%;
  flex-direction: row;
`
export const StyledCheckBoxColumn = styled.div<{
  $doxleFont?: DoxleFont,
  $themeColor?: DoxleThemeColor,
  $currentTheme?: DoxleTheme
}>`
  display: flex;
  flex-direction: column;
  width: 50%;
`
export const StyledPermissionCheckboxRow  = styled.div<{
  $doxleFont?: DoxleFont,
  $themeColor?: DoxleThemeColor,
  $currentTheme?: DoxleTheme
}>`
  display: flex;
  width: 100%;
  flex-direction: row;
  color: ${(p) => editRgbaAlpha({
    rgbaColor: p?.$themeColor?.primaryFontColor ?? 'rgba(0,0,0,1)',
    alpha: "0.7"
  })};
  font-weight: 400;
  padding: 2px 0px;
  font-size: 13px;
  font-family: ${(p) => p?.$doxleFont?.primaryFont ?? "Inter"};
  cursor: pointer;
  border-radius: 0px 4px 4px 4px;
  &&:hover {
    background-color: ${(p) => p?.$themeColor?.hoverColor ?? "rgba(126, 150, 187, 0.12)"};
  }
`
export const StyledPermissionCheckbox = styled(Checkbox)<{
  $doxleFont?: DoxleFont,
  $themeColor?: DoxleThemeColor,
  $currentTheme?: DoxleTheme
}>`
  padding: 0px 5px !important;
`

export const StyledShareInput = styled(TextField)<{
  $doxleFont?: DoxleFont,
  $themeColor?: DoxleThemeColor,
  $currentTheme?: DoxleTheme
}>`
  "& .MuiInputBase-root" : {
    background-color: ${(p) => p.$themeColor?.primaryBackgroundColor ?? "white"};
    border: 1px solid #D5D7D9;
    border-radius: 0px 4px 4px 4px;
  },
  "& .MuiInputBase-root:before" : {
    border-bottom: none;
  },
`

export const StyledCopyLinkButton = styled(Button)<{
  $doxleFont?: DoxleFont,
  $themeColor?: DoxleThemeColor,
  $currentTheme?: DoxleTheme
  $marginRight?: number
}>`
  && {
    margin: 5px ${(p) => p.$marginRight + 'px' ?? '5px'} 5px 5px;
    padding: 10px 5px;
    background-color: ${(p) => p.$themeColor?.primaryBackgroundColor ?? "#7070ff"};
    color: ${(p) => p.$themeColor?.doxleColor ?? "#7070ff"} !important;
  }
  &:hover{
    background-color: ${(p) => p.$themeColor?.doxleColor ?? "#7070ff"} !important;
    color: ${(p) => p.$themeColor?.primaryReverseFontColor ?? "white"} !important;
  }
`

export const StyledShareDocketTitleRow= styled.div<{
  $doxleFont?: DoxleFont,
  $themeColor?: DoxleThemeColor,
  $currentTheme?: DoxleTheme
}>`
  display: flex;
  flex-direction: row;
  width: 100%;
  color:  ${(p) => p.$themeColor?.primaryFontColor ?? "rgba(0,0,0,1)"};
  font-family: ${(p) => p.$doxleFont?.primaryTitleFont ?? "Inter"};;
`

export const StyledBoldHeader= styled.div<{
  $doxleFont?: DoxleFont,
  $themeColor?: DoxleThemeColor,
  $currentTheme?: DoxleTheme
}>`
  font-weight: 900;
  font-size: min(27px,2.7rem);
  margin-right: 20px;
`

export const StyledSemiBoldHeader= styled.div<{
  $doxleFont?: DoxleFont,
  $themeColor?: DoxleThemeColor,
  $currentTheme?: DoxleTheme
}>`
  font-family: ${(p) => p.$doxleFont?.subTitleFont ?? "IBM Plex Mono"};;
  font-weight: 700;
  font-size: min(27px,2.7rem);
  margin-left: 10px;
`

export const StyledSubtitleContainer= styled.div<{
  $doxleFont?: DoxleFont,
  $themeColor?: DoxleThemeColor,
  $currentTheme?: DoxleTheme
}>`
  display: flex;
  flex-direction: row;
  width: 100%;
  font-family: ${(p) => p.$doxleFont?.subTitleFont ?? "IBM Plex Mono"};;
  font-weight: 500;
  font-size: min(16px,1.6rem);
  color:  ${(p) => editRgbaAlpha({ 
    rgbaColor: p.$themeColor?.primaryFontColor ?? "rgba(0,0,0,1)",
    alpha: '0.6'
  })};
`
export const StyledContactListScroller = styled.div`
  width: 100%;
  height: 100% !important;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`