import React, {CSSProperties, useState} from "react";
import {RootEstimateTabMenu, StyledEstimateKebabMenu, StyledEstimateMenuItemBtn} from "./StyledComponents";
import {useDoxleThemeStore} from "../../DoxleGeneralStore/useDoxleThemeStore";
import {shallow} from "zustand/shallow";
import {MenuItem, MenuList} from "@mui/material";
import {EstimatesKebabIcon, EstimatesSearchIcon} from "./EstimatesIcons";
import {useEstimatesStore} from "./Store/useEstimatesStore";

interface IProjectKebabMenu {
  anchorEl: (EventTarget & SVGSVGElement) | null;
  open: boolean;
}
const EstimatesTabMenu = () => {
  const [kebabMenu, setKebabMenu] = useState<IProjectKebabMenu>({
    anchorEl: null,
    open: false,
  });

  const {theme, font, themeColor} = useDoxleThemeStore(state=>({
    theme: state.currentTheme,
    font: state.doxleFont,
    themeColor: state.doxleThemeColor
  }), shallow)
  const {view, setView, isSearching, setIsSearching } = useEstimatesStore(state=> ({
    view: state.view,
    setView: state.setView,
    isSearching: state.isSearching,
    setIsSearching: state.setIsSearching
  }),shallow)

  const handleClickSearchIcon = () => {
    setIsSearching(true)
  };

  const handleKebabMenuClose = () => {
    setKebabMenu({ open: false, anchorEl: null });
  };

  const handleMenuClick = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    setKebabMenu({ anchorEl: e.currentTarget, open: true });
  };
  const EstimateTabMenuItems:("QuickTender"|"Estimates")[]= ["Estimates", "QuickTender"]

  const rootMenuAnimatedVariants = {
    entering: {
      x: [-10, 0],
      opacity: [0, 1],
      transition: {
        duration: 0.4,
      },
    },
  };

  return (
    <RootEstimateTabMenu
      variants={rootMenuAnimatedVariants}
      initial={false}
      animate="entering"
    >
      {EstimateTabMenuItems.map((menuItem, idx) => (
        <StyledEstimateMenuItemBtn
          doxleFont={font}
          onClick={() => setView(menuItem)}
          selected={view === menuItem}
          key={`docketMenu#${idx}`}
          themecolor={themeColor}
          // variants={docketMenuItemAnimatedVariants}
          initial="initial"
          whileHover="hover"
          animate={[
            view === menuItem ? "selected" : "initial",
          ]}
          transition={{
            selected: { type: "spring", stiffness: 500, duration: 0.4 },
            hover: { type: "spring", stiffness: 500, duration: 0.4 },
          }}
        >
          {menuItem}
        </StyledEstimateMenuItemBtn>
      ))}
      <EstimatesSearchIcon {...themeColor} onClick={handleClickSearchIcon}  />
      <EstimatesKebabIcon {...themeColor} onClick={handleMenuClick} />
      <MenuList>
        <StyledEstimateKebabMenu
          id="ProjectKebabMenu"
          anchorEl={kebabMenu.anchorEl}
          open={kebabMenu.open}
          onClose={handleKebabMenuClose}
          PaperProps={{
            elevation: 1,
          }}
        >
          <MenuItem
            onClick={() => {
              handleKebabMenuClose();
            }}
          >
            Filters
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleKebabMenuClose();
            }}
          >
            Add New Estimate
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleKebabMenuClose();
            }}
          >
            Add New Company
          </MenuItem>
        </StyledEstimateKebabMenu>
      </MenuList>
    </RootEstimateTabMenu>
  );
}

export default EstimatesTabMenu