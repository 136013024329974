import React from "react";
import {
  StyledBudgetEstimateHeader,
  StyledBudgetEstimateLowerHeader,
  StyledBudgetEstimateUpperHeader,
} from "./StyledComponentEditDocketBudgetEstimate";
import { Estimate_Header_List } from "../../Store/useEditEstimateStore";

type Props = {};

const BudgetEstimateHeader = (props: Props) => {
  return (
    <StyledBudgetEstimateHeader>
      <StyledBudgetEstimateUpperHeader>
        {Estimate_Header_List.map((header, idx) => (
          <div
            className="header-text"
            key={`upHeader#${idx}#${header}`}
            style={{
              height: "100%",
              width:
                header !== "Description"
                  ? header === "Order"
                    ? 40
                    : header === "Amount" || header === "Unit Total"
                    ? "20%"
                    : 88
                  : undefined,
              flex: header === "Description" ? 1 : undefined,
            }}
          >
            {header === "Description"
              ? "A"
              : header === "Qty"
              ? "B"
              : header === "Unit"
              ? "C"
              : header === "Amount"
              ? "D"
              : header === "Unit Total"
              ? "E"
              : ""}
          </div>
        ))}
      </StyledBudgetEstimateUpperHeader>

      <StyledBudgetEstimateLowerHeader>
        {Estimate_Header_List.map((header, idx) => (
          <div
            className="header-text"
            key={`upHeader#${idx}#${header}`}
            style={{
              height: "100%",
              width:
                header !== "Description"
                  ? header === "Order"
                    ? 40
                    : header === "Amount" || header === "Unit Total"
                    ? "20%"
                    : 88
                  : undefined,
              flex: header === "Description" ? 1 : undefined,
              backgroundColor:
                header !== "Order" ? "rgba(79, 85, 124, 0.4)" : undefined,
            }}
          >
            {header !== "Order" ? header : ""}
          </div>
        ))}
      </StyledBudgetEstimateLowerHeader>
    </StyledBudgetEstimateHeader>
  );
};

export default BudgetEstimateHeader;
