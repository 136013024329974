import React, { useEffect, useRef, useState } from "react";
import { LineEditTool } from "../../../Models/note";
import { produce } from "immer";
import { useDoxleCurrentContextStore } from "../../../../DoxleGeneralStore/useDoxleCurrentContext";
import { shallow } from "zustand/shallow";
import useDoxleNotificationStore from "../../../../DoxleGeneralStore/useDoxleNotificationStore";
import { useNoteStore } from "../../../Store/useNoteStore";
import { NoteQueryAPI } from "../../../QueryAPI/notesQueryAPI";
import { toggleQuote } from "../HelperFunctions";

type Props = { noteId: string };

interface INoteLineListFooter {
  lineEditTools: LineEditTool[];
  handleToggleLineEditTool: (tool: LineEditTool) => void;
  newLineDescription: string;
  setNewLineDescription: React.Dispatch<React.SetStateAction<string>>;
  handleKeydownDescriptionTextfield: (
    event: React.KeyboardEvent<HTMLDivElement>
  ) => void;
  handleAddNoteLine: () => void;
  isAddingLine: boolean;
  setIsFocused: React.Dispatch<React.SetStateAction<boolean>>;
  isFocused: boolean;
  checkLineEditToolEnable: (tool: LineEditTool) => boolean;
  newLineInputRef: React.RefObject<HTMLInputElement>;
  handleNewLineDescriptionChange: (value: string) => void;
}
const useNoteLineListFooter = ({ noteId }: Props): INoteLineListFooter => {
  const [lineEditTools, setLineEditTools] = useState<LineEditTool[]>([]);
  const [newLineDescription, setNewLineDescription] = useState("");
  const [isFocused, setIsFocused] = useState(false);
  const { company } = useDoxleCurrentContextStore(
    (state) => ({
      company: state.currentCompany,
    }),
    shallow
  );
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification
  );
  const resetInput = () => {
    setNewLineDescription("");
    setLineEditTools([]);
  };
  const { add } = NoteQueryAPI.useMutateNoteLineQuery({
    company,
    noteId: noteId,
    showNotification,
    onSuccessAddCb: () => setNewLineDescription(""),
  });
  const checkLineEditToolEnable = (tool: LineEditTool) =>
    lineEditTools.includes(tool);
  const newLineInputRef = useRef<HTMLInputElement>(null);
  const handleAddNoteLine = () => {
    if (newLineDescription && !add.isLoading)
      add.mutate({
        text: newLineDescription,
        isBold: checkLineEditToolEnable("bold"),
        isBullet: checkLineEditToolEnable("bullet"),
        isItalic: checkLineEditToolEnable("italic"),
        isQuoted: checkLineEditToolEnable("quote"),
      });
    else newLineInputRef.current?.blur();
  };

  const handleToggleLineEditTool = (tool: LineEditTool) => {
    if (tool !== "quote")
      setLineEditTools(
        produce((prev) => {
          if (prev.includes(tool)) prev = prev.filter((item) => item !== tool);
          else prev.push(tool);

          return prev;
        })
      );
    if (tool === "quote") {
      if (newLineInputRef.current) {
        const { selectionStart, selectionEnd, value } = newLineInputRef.current;
        if (
          selectionStart !== null &&
          selectionEnd !== null &&
          selectionStart !== selectionEnd
        ) {
          const selectedText = value.substring(selectionStart, selectionEnd);
          const newText = toggleQuote(
            checkLineEditToolEnable("bullet") ? value.substring(1) : value,
            selectedText
          );
          setNewLineDescription(newText);
        }
      }
    }
  };

  const handleKeydownDescriptionTextfield = (
    event: React.KeyboardEvent<HTMLDivElement>
  ) => {
    if (event.nativeEvent.key === "Enter") {
      event.preventDefault();
      handleAddNoteLine();
    }

    if (event.nativeEvent.key === "Escape") {
      event.preventDefault();
      setNewLineDescription("");
      setLineEditTools([]);
      setIsFocused(false);
      newLineInputRef.current?.blur();
    }
  };
  const handleNewLineDescriptionChange = (value: string) => {
    setNewLineDescription(
      checkLineEditToolEnable("bullet") ? value.slice(1) : value
    );
  };
  useEffect(() => {
    if (!isFocused) resetInput();
  }, [isFocused]);

  return {
    lineEditTools,
    handleToggleLineEditTool,
    newLineDescription,
    setNewLineDescription,
    handleKeydownDescriptionTextfield,
    handleAddNoteLine,
    isAddingLine: add.isLoading,
    setIsFocused,
    isFocused,
    checkLineEditToolEnable,
    newLineInputRef,
    handleNewLineDescriptionChange,
  };
};

export default useNoteLineListFooter;
