import {OrderStatus} from "../../../Orders/Models/order";
import {ProjectStatus} from "../../../Models/project";
import {DocketStatus} from "../../../Models/dockets";
import {useDoxleThemeStore} from "../../../DoxleGeneralStore/useDoxleThemeStore";
import {shallow} from "zustand/shallow";
import {
  StyledDoxleDialogHelperActions,
  StyledDoxleDialogHelperContainer,
  StyledDoxleDialogHelperContent,
  StyledDoxleDialogHelperEditButton,
  StyledDoxleDialogHelperText,
  StyledDoxleDialogHelperTitle
} from "../../../DoxleDesignPattern/DoxleDialogHelper/StyledComponentDoxleDialogHelper";
import {editRgbaAlpha} from "../../../Utilities/FunctionUtilities";
import React, {useEffect, useState} from "react";
import {Autocomplete, TextField} from "@mui/material";
import {StyledAutocompleteTextField, StyledCompanySettingsTextField} from "../styledComponents";

interface Props {
  title: string;
  description: string;
  handleClose: () => void;
  deleteFn: (newStatusId: string|null) => void;
  statusList: OrderStatus[]|ProjectStatus[]|DocketStatus[];
}
const DeleteStatusDialog = ({title, description, handleClose, deleteFn, statusList}: Props) =>{
  const selectStatusList = [...statusList, null]
  const [selectedNewStatus, setSelectedNewStatus] =
    useState<OrderStatus|ProjectStatus|DocketStatus|null>(selectStatusList[0])
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }),
    shallow
  );

  return (
    <StyledDoxleDialogHelperContainer
      $themeColor={doxleThemeColor}
      PaperProps={{
        sx: {
          bgColor: doxleThemeColor.primaryContainerColor,
          borderRadius: "0.9rem",
        },
        elevation: 4,
      }}
      slotProps={{
        backdrop: {
          sx: {
            bgcolor: editRgbaAlpha({
              rgbaColor: doxleThemeColor.primaryBackgroundColor,
              alpha: "0.4",
            }),
          },
        },
      }}
      open={true}
      onClose={handleClose}
    >
      <StyledDoxleDialogHelperTitle
        $themeColor={doxleThemeColor}
        $doxleFont={doxleFont}
      >
        {title}
      </StyledDoxleDialogHelperTitle>

      <StyledDoxleDialogHelperContent $themeColor={doxleThemeColor}>
        <StyledDoxleDialogHelperText
          $themeColor={doxleThemeColor}
          $doxleFont={doxleFont}
        >
          {description.split('\n').map((text, i) => {
            if (i === 0) return (text)
            else return (<><br/>{text}</>)
          })}
        </StyledDoxleDialogHelperText>
      </StyledDoxleDialogHelperContent>
      <Autocomplete
        value={selectedNewStatus}
        options={selectStatusList}
        getOptionLabel={(option) => option?.statusName ?? "Delete Associated Orders"}
        onChange={(e, value) => setSelectedNewStatus(value)}
        sx={{
          backgroundColor: doxleThemeColor.primaryContainerColor,
        }}
        renderInput={(params) => (
          <StyledAutocompleteTextField
            $themeColor={doxleThemeColor}
            $doxleFont={doxleFont}
            {...params}
          />
        )}
      />
      <StyledDoxleDialogHelperActions $themeColor={doxleThemeColor}>
          <StyledDoxleDialogHelperEditButton
            key={`deleteStatusDialogCancel}`}
            // $bgColor={button.buttonColor}
            $themeColor={doxleThemeColor}
            $buttonIndex={0}
            $doxleFont={doxleFont}
            onClick={handleClose}
          >
            <span className="button-text">
              Cancel
            </span>
          </StyledDoxleDialogHelperEditButton>
        <StyledDoxleDialogHelperEditButton
          key={`deleteStatusDialogConfirm`}
          // $bgColor={button.buttonColor}
          $themeColor={doxleThemeColor}
          $buttonIndex={1}
          $doxleFont={doxleFont}
          onClick={() => deleteFn(selectedNewStatus?.statusId ?? null)}
          layout
        >
          <span className="button-text">
            Delete Status
          </span>
        </StyledDoxleDialogHelperEditButton>
      </StyledDoxleDialogHelperActions>
    </StyledDoxleDialogHelperContainer>
  )
}
export default DeleteStatusDialog
