import React, { useCallback, useMemo, useRef } from "react";
import {
  StyledQAItemRowWrapper,
  StyledQAListDetailContainer,
} from "./StyledComponentQAListDetail";
import useGetQAItemList from "./Hooks/useGetQAItemList";
import QAItemListSkeleton from "./QAItemListSkeleton";
import ListLoadingMore from "../../../../Utilities/Lottie/ListLoadingMore";
import {
  Components,
  ItemContent,
  Virtuoso,
  VirtuosoHandle,
} from "react-virtuoso";
import { QAWithFirstImg } from "../../../Models/qa";
import { StyledQAListScroller } from "../CommonComponents/StyledComponentCommonQA";
import { AnimatePresence, Variants } from "framer-motion";
import { useDoxleThemeStore } from "../../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import DoxleEmptyPlaceHolder from "../../../../DoxleDesignPattern/DoxleEmptyPlaceHolder/DoxleEmptyPlaceHolder";
import QAItemRow from "./QAItemRow";
import { useProjectQAStore } from "../../Store/useProjectQAStore";
import QAItemDetail from "../QAItemDetail/QAItemDetail";
import useQAListDetail from "./Hooks/useQAListDetail";
import DoxleDialogHelper from "../../../../DoxleDesignPattern/DoxleDialogHelper/DoxleDialogHelper";

type Props = {};
interface ListContext {
  isErrorFetchingQAItemList: boolean;
}
const QAListDetail = (props: Props) => {
  const { doxleThemeColor, doxleFont, currentTheme } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
      currentTheme: state.currentTheme,
    }),
    shallow
  );
  const { selectedQAItem, setSelectedQAItem } = useProjectQAStore(
    (state) => ({
      selectedQAItem: state.selectedQAItem,
      setSelectedQAItem: state.setSelectedQAItem,
    }),
    shallow
  );
  const {
    qaItemList,
    isFetchingQAItemList,
    isSuccessFetchingQAItemList,
    isErrorFetchingQAItemList,
    hasNextPage,
    handleFetchNextPage,
    isFetchingNextPage,
    handleRefetchQAList,
    isRefetchingQAList,
  } = useGetQAItemList({});

  const {
    isAddingQAItem,
    deletedQAItem,
    setdeletedQAItem,
    handleDeleteQAItem,
  } = useQAListDetail({});
  const tableRef = useRef<VirtuosoHandle>(null);
  const scrollToBottom = () => {
    setTimeout(() => {
      tableRef.current?.scrollToIndex({
        index: qaItemList.length - 1,
        behavior: "smooth",
      });
    }, 500);
  };
  const animatedVariants: Variants = {
    initial: {
      x: -5,
      opacity: 0,
    },
    entering: {
      x: 0,
      opacity: 1,
    },
    exiting: {
      x: 10,
      opacity: 0,
    },
  };
  const components: Components<QAWithFirstImg, ListContext> = useMemo(
    () => ({
      Scroller: React.forwardRef(({ context, ...props }, ref) => (
        <StyledQAListScroller ref={ref} {...props} />
      )),

      Item: ({ context, ...rest }) => (
        <AnimatePresence key={rest.item.defectId}>
          <StyledQAItemRowWrapper
            onClick={() => setSelectedQAItem(rest.item)}
            $themeColor={doxleThemeColor}
            {...rest}
            variants={animatedVariants}
            initial="initial"
            animate="entering"
            exit="exiting"
            transition={{
              duration: 0.2,
            }}
          />
        </AnimatePresence>
      ),
      EmptyPlaceholder: ({ context, ...rest }) => (
        <DoxleEmptyPlaceHolder
          headTitleText={
            context?.isErrorFetchingQAItemList
              ? "Something wrong!"
              : "No QA Issue"
          }
          subTitleText={
            context?.isErrorFetchingQAItemList
              ? "Failed to get notice board data, we are sorry! Please try to reload page..."
              : "Add more qa item to manage your work quality..."
          }
          containerBgColor="transparent"
        />
      ),
    }),
    []
  );

  const itemContent: ItemContent<QAWithFirstImg, ListContext> = useCallback(
    (index, item, context) => {
      return <QAItemRow qaItem={item} setdeletedQAItem={setdeletedQAItem} />;
    },
    []
  );

  const listStyle: React.CSSProperties = {
    width: "100%",
    flex: 1,
    marginTop: 8,
  };
  if (selectedQAItem) return <QAItemDetail />;
  return (
    <StyledQAListDetailContainer
      initial={{
        x: 500,
      }}
      animate={{ x: 0 }}
      transition={{ duration: 0.1 }}
    >
      {/* <DoxleRefetchButton
        onClick={handleRefetchQAList}
        btnText="Reload List"
        isRefetching={isRefetchingQAList}
      /> */}
      {isFetchingQAItemList && <QAItemListSkeleton />}

      {!isFetchingQAItemList && (
        <Virtuoso
          style={listStyle}
          context={{
            isErrorFetchingQAItemList,
          }}
          ref={tableRef}
          data={qaItemList}
          components={components}
          itemContent={itemContent}
          endReached={handleFetchNextPage}
        />
      )}

      {(isFetchingNextPage || isAddingQAItem) && (
        <ListLoadingMore
          containerStyle={{
            position: "absolute",
            bottom: 0,
            left: 0,
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 10,
          }}
          animationSize={120}
        />
      )}

      <DoxleDialogHelper
        open={Boolean(deletedQAItem)}
        onClose={() => setdeletedQAItem(undefined)}
        title="Confirm delete qa!"
        description={`All data belong to qa item *** ${deletedQAItem?.description} *** will be deleted permanently, are you sure to proceed?`}
        dialogButtons={[
          {
            buttonText: "Cancel",
            buttonFunction: () => setdeletedQAItem(undefined),
            buttonColor: doxleThemeColor.primaryContainerColor,
            btnStyleSx: {
              border: `1px solid ${doxleThemeColor.primaryDividerColor}`,
            },
            buttonTextStyle: {
              color: doxleThemeColor.primaryFontColor,
            },
          },
          {
            buttonText: "Confirm",
            buttonFunction: handleDeleteQAItem,
            btnStyleSx: {
              border: `1px solid ${doxleThemeColor.primaryDividerColor}`,
            },
          },
        ]}
      />
    </StyledQAListDetailContainer>
  );
};

export default QAListDetail;
