import { motion } from "framer-motion";
import styled from "styled-components";
import {
  DoxleFont,
  DoxleThemeColor,
} from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { TextField } from "@mui/material";
import {
  TRgbaFormat,
  editRgbaAlpha,
} from "../../../Utilities/FunctionUtilities";

export const StyledAddScopeItemImageSectionContainer = styled(motion.div)<{
  $themeColor: DoxleThemeColor;
}>`
  width: calc(95% - 18px);
  margin: 8px 0px;
  border-radius: 8px;

  display: flex;
  cursor: pointer;
  height: 400px;
  padding: 8px;
  border: 1px solid ${(p) => p.$themeColor.primaryDividerColor};
  z-index: 0;
  overflow: hidden;
  position: relative;
`;
export const StyledAddedScopeImageDisplayerContainer = styled(motion.div)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
export const StyledAddedScopeImageSection = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const StyledAddedScopeImageItemContainer = styled(motion.div)`
  width: 35rem;
  height: calc(100% - 2.8rem);

  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 1.4rem 0px;

  .img-holder {
    width: 100%;
    flex: 1;
    display: flex;
    overflow: hidden;
    margin-bottom: 4px;
    justify-content: center;
  }
`;
export const StyledAddedScopeImage = styled.img`
  //max-width: 80%;
`;

export const StyledAddedImageInfoTextfield = styled(TextField)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
  $isTitle: boolean;
}>`
  && {
    width: 100%;
    margin-top: ${(p) => (p.$isTitle ? "1.4rem" : "0.8rem")};
  }
  input {
    color: ${(p) => p.$themeColor.primaryFontColor};
    font-size: ${(p) => (p.$isTitle ? "1.5rem" : "1rem")};
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-style: normal;
    font-weight: ${(p) => (p.$isTitle ? "500" : "400")};
    line-height: normal;
    letter-spacing: 0.055rem;
    padding: 0px !important;
  }

  & .MuiOutlinedInput-input {
    padding: 0;
  }
  & .MuiInput-root:after {
    border-bottom: 1px solid ${(p) => p.$themeColor.primaryFontColor};
  }
  & .MuiInput-root:before {
    border-bottom: 1px solid
      ${(p) =>
        editRgbaAlpha({
          rgbaColor: p.$themeColor.primaryFontColor as TRgbaFormat,
          alpha: "0.4",
        })};
  }
`;
export const StyledScopeImgWrapper = styled(motion.div)`
  width: calc(20% - 20px);
  aspect-ratio: 1;
  padding: 10px;
  display: flex;
`;
export const StyledScopeImgList = styled(motion.div)`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;
export const StyledScopeImgListScroller = styled.div`
  width: 100%;

  display: flex;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;
export const StyledScopeImageItem = styled(motion.div)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  width: calc(100% - 18px);
  height: calc(100% - 18px);
  display: flex;
  padding: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  border: 1px solid ${(p) => p.$themeColor.primaryDividerColor};
  overflow: hidden;
  transition: 0.4s all ease;
  .dropzone-title {
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: normal;
    letter-spacing: 0.05em;
    color: ${(p) => p.$themeColor.primaryFontColor};
    margin-top: 8px;
    text-align: center;
  }
  .img-holder {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 4px;
  }

  &:hover {
    border-color: ${(p) => p.$themeColor.doxleColor};
    transition: 0.4s all ease;

    .menu-hover {
      display: flex;
      transition: 0.4s ease all;
    }
  }
`;

export const StyledScopeImgHoverMenu = styled(motion.div)<{
  $themeColor: DoxleThemeColor;
}>`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: none;
  justify-content: center;
  align-items: center;
  background-color: ${(p) =>
    editRgbaAlpha({
      rgbaColor: p.$themeColor.primaryContainerColor,
      alpha: "0.8",
    })};
`;
