import { motion } from "framer-motion";
import styled from "styled-components";
import {
  DoxleFont,
  DoxleThemeColor,
} from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { editRgbaAlpha } from "../../../Utilities/FunctionUtilities";
import TextField from "@mui/material/TextField";

export const StyledNoteListContainer = styled(motion.div)`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const StyledNoteTitle = styled.span<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
  $transparentMode: boolean;
}>`
  color: ${(p) => p.$themeColor.primaryFontColor};

  font-family: ${(p) => p.$doxleFont.primaryTitleFont};
  font-size: max(1.4rem, 14px);
  font-style: normal;
  font-weight: 500;

  letter-spacing: -0.26px;
  flex-shrink: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-left: 8px;
`;
export const StyledNoteRowContainer = styled(motion.div)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
  $transparentMode: boolean;
}>`
  width: calc(100%);
  align-items: center;
  padding: 5px 0;

  position: relative;
  display: flex;
  border-bottom: 1px solid ${(p) => p.$themeColor.primaryDividerColor};
  background-color: ${(p) =>
    editRgbaAlpha({
      rgbaColor: p.$themeColor.primaryContainerColor,
      alpha: p.$transparentMode ? "0" : "1",
    })};
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.$themeColor.doxleColor,
        alpha: "0.15",
      })};
    transition: all 0.2s ease;
  }
  .note-title {
    color: ${(p) => p.$themeColor.primaryFontColor};

    font-family: ${(p) => p.$doxleFont.primaryTitleFont};
    font-size: max(1.4rem, 14px);
    font-style: normal;
    font-weight: 500;

    letter-spacing: -0.26px;
    flex-shrink: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-left: 8px;
  }

  .icon-wrapper {
    display: flex;
    align-items: center;
    margin: 0px 12px;
  }
`;

export const StyledNoteListFooter = styled(motion.div)`
  width: 100%;
  height: 40px;
  margin-top: 8px;
  display: flex;
`;

export const StyledAddNoteTitleTextField = styled(TextField)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  && {
    overflow: hidden;
    min-width: 100px;
    width: 100%;
    padding: 0.8rem 0px;
    display: flex;
    flex-direction: row;
    align-items: center;

    border-bottom: 1px solid ${(p) => p.$themeColor.primaryDividerColor};
  }
  input {
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-weight: 400;
    font-size: max(1.3rem, 13px);
    line-height: normal;
    width: 100% !important;
    color: ${(p) => p.$themeColor.primaryFontColor};
    padding: 0px !important;
    &::placeholder {
      font-family: ${(p) => p.$doxleFont.primaryFont};
      font-weight: 400;
      font-size: max(1.3rem, 13px);
      line-height: normal;
      color: ${(p) => p.$themeColor.primaryInputPlaceholderColor};
      padding: 0px !important;
      opacity: 1;
    }
  }
  & .MuiInput-root:after {
    border-bottom: none !important;
  }
  & .MuiInput-root:before {
    border-bottom: none !important;
  }
  & .MuiInput-root {
    border-bottom: none !important;
  }
  & .MuiInput-root:hover:not(.Mui-disabled, .Mui-error):before {
    border-bottom: none !important;
  }
  & .MuiInputBase-root {
    width: 100%;
  }
`;

export const StyledAddNoteBtn = styled(motion.div)`
  padding: 1px;
  border-radius: 50%;
  margin-right: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
