import React from "react";
import { StyledQuotesStatus } from "../MainStyledComponents";
import Accepted from "../../Assets/accepted.svg";
import Rejected from "../../Assets/rejected.png";
import { QuoteRequestStatus } from "../../models/quote";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { CircularProgress } from "@mui/material";

const quoteStatus: Record<
  QuoteRequestStatus,
  { color: string; image: string }
> = {
  Accepted: {
    color: "rgba(18, 183, 24, 0.70)",
    image: Accepted,
  },
  Rejected: {
    color: "rgba(255, 116, 116, 0.70)",
    image: Rejected,
  },
  Draft: {
    color: "#7070EF",
    image: "",
  },
  Expired: { color: "", image: "" },
  Received: { color: "rgba(18, 183, 24, 0.44)", image: "" },
  Refused: { color: "", image: "" },
  Requested: {
    color: "#FFE274",
    image: "",
  },
};

interface QuoteStatusProps {
  status: keyof typeof quoteStatus;
  isSendingMail?: boolean;
}

const QuoteStatusDisplayer: React.FC<QuoteStatusProps> = ({
  status,
  isSendingMail,
}) => {
  const { doxleFont, doxleThemeColor } = useDoxleThemeStore((state) => ({
    doxleThemeColor: state.doxleThemeColor,
    doxleFont: state.doxleFont,
  }));
  return (
    <>
      <StyledQuotesStatus
        $bgColor={quoteStatus[status]?.color}
        $doxleFont={doxleFont}
      >
        {status === "Requested" ? "Waiting" : status}
      </StyledQuotesStatus>
      {isSendingMail && (
        <CircularProgress
          size={15}
          style={{
            color: doxleThemeColor.primaryFontColor,
            marginLeft: 5,
          }}
        />
      )}
    </>
  );
};

export default QuoteStatusDisplayer;
