import { motion } from "framer-motion";
import styled from "styled-components";
import { editRgbaAlpha } from "../../Utilities/FunctionUtilities";
import {
  DoxleFont,
  DoxleThemeColor,
} from "../../DoxleGeneralStore/useDoxleThemeStore";
import Button from "@mui/material/Button";

export const StyledDoxleEditSignaturePatch = styled(motion.div)<{
  $themeColor: DoxleThemeColor;
}>`
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: ${(p) => p.$themeColor.primaryContainerColor};
`;

export const StyledSaveSignatureLoaderWrapper = styled(motion.div)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: ${(p) => p.$themeColor.primaryBackdropColor};
  align-items: center;
  .loader-bar {
    width: 100%;
    max-width: 50rem;
  }
  .loader-text {
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-style: normal;
    font-weight: 500;
    font-size: max(2rem, 20px);
    color: ${(p) => p.$themeColor.primaryReverseFontColor};
    display: flex;
    margin-bottom: 14px;
  }
`;
export const StyledAddSignatureTitle = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  font-family: ${(p) => p.$doxleFont.primaryFont};
  font-style: normal;
  font-weight: 500;
  font-size: max(2.4rem, 24px);

  color: ${(p) => p.$themeColor.primaryFontColor};
  border-bottom: 1px solid ${(p) => p.$themeColor.primaryDividerColor};
  background-color: ${(p) => p.$themeColor.primaryContainerColor};
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
export const StyledAddSignatureContentWrapper = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  background-color: ${(p) => p.$themeColor.primaryContainerColor};

  word-break: break-word;
  width: calc(100% - 2.8rem) !important;
  padding: 0.8rem 1.4rem !important;
  display: flex;
  flex-direction: column;

  .preview-wrapper {
    width: calc(100% - 2px);
    margin: 8px 0px;
    height: 20vh;
    background-color: white;
    border-radius: 1.2rem;
    border: 1px solid ${(p) => p.$themeColor.rowBorderColor};
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    .preview-text {
      font-family: ${(p) => p.$doxleFont.primaryFont};
      font-style: normal;
      font-weight: 400;
      font-size: 1.2rem;

      color: ${(p) => p.$themeColor.doxleColor};
      position: absolute;
      z-index: 10;
      top: 8px;
      right: 8px;
    }

    .image-signature {
      height: fit-content;
    }
  }
  .canvas-wrapper {
    background-color: white;
    width: calc(100% - 2px);
    margin: 8px 0px;
    height: 20vh;
    border-radius: 1.2rem;
    border: 1px dashed
      ${(p) =>
        editRgbaAlpha({
          rgbaColor: p.$themeColor.primaryFontColor,
          alpha: "0.4",
        })};
    justify-content: center;
    align-items: center;
    display: flex;
    position: relative;
    .sign-text {
      font-family: ${(p) => p.$doxleFont.primaryFont};
      font-style: normal;
      font-weight: 500;
      font-size: 1.2rem;

      color: ${(p) => p.$themeColor.primaryFontColor};
      position: absolute;
      z-index: 10;
      top: 8px;
      right: 8px;
    }
    .signature-pad {
      width: 90%;
      height: 90%;
    }
  }
`;
export const StyledEditSignatureBtnWrapper = styled.div`
  width: calc(100% - 2.8rem) !important;
  padding: 1.4rem !important;
  display: flex;
  justify-content: flex-end;
`;
export const StyledEditSignatureButton = styled(motion(Button))<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
  $bgColor: string;
  $textColor: string;
  $hasBorder?: boolean;
}>`
  && {
    min-width: 0px !important;
    margin: 0px 4px;
    border-radius: 40%;
    padding: 0.4rem 1.4rem;
    font-size: 1.4rem;
    color: ${(p) => p.$textColor};
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.65px;
    border: ${(p) => (p.$hasBorder ? 1 : 0)}px solid
      ${(p) => p.$themeColor.rowBorderColor};
    background-color: ${(p) => p.$bgColor};
    text-transform: capitalize;
    &:hover {
      background-color: ${(p) => p.$bgColor} !important;
      opacity: 0.8;
    }
  }
  &.MuiButton-root.Mui-disabled {
    color: ${(p) => p.$textColor} !important;
    background-color: ${(p) => p.$bgColor} !important;
    opacity: 0.4;
  }
`;
