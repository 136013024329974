import { useDoxleThemeStore } from "../../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import {
  StyledEditBookingContentWrapper,
  StyledEditBookingDocketAndDateContainer,
  StyledEditBookingLabelText,
} from "./StyledComponentEditBookingDocket";
import BookingDateRangePicker from "../../../CommonComponents/BookingDateRangePicker";
import useEditBookingDocketAndDate from "./Hooks/useEditBookingDocketAndDate";
import useBookingDocketStore from "../../../store/useBookingDocketStore";
import { useEditDocketBookingContext } from "./EditBookingDocket";
import CustomDatePicker from "../../../../DoxleDesignPattern/CustomDatePicker/CustomDatePicker";
import { useState } from "react";
import DoxleIconButton from "../../../../DoxleDesignPattern/DoxleButtons/DoxleIconButton";
import HighlightOffSharpIcon from "@mui/icons-material/HighlightOffSharp";
import dayjs from "dayjs";

type Props = {};

const EditBookingDocketAndDate = (props: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
      currentTheme: state.currentTheme,
    }),
    shallow
  );
  const { startDate, endDate, setStartDate, setEndDate } = useEditDocketBookingContext();
  const { edittedBooking } = useBookingDocketStore(
    (state) => ({
      edittedBooking: state.edittedBooking,
    }),
    shallow
  );
  const [onHoverStartDate, setOnHoverStartDate] = useState(false);
  const [onHoverEndDate, setOnHoverEndDate] = useState(false);

  const {
    isUpdatingDate,
    handleSelectDateRange,
    localEndDate,
    localStartDate,
  } = useEditBookingDocketAndDate({});
  return (
    <StyledEditBookingDocketAndDateContainer>
      <div className="field-wrapper">
        <StyledEditBookingLabelText
          $themeColor={doxleThemeColor}
          $doxleFont={doxleFont}
        >
          Docket
        </StyledEditBookingLabelText>

        <StyledEditBookingContentWrapper
          $themeColor={doxleThemeColor}
          $doxleFont={doxleFont}
          $isNull={false}
          layout
        >
          {edittedBooking?.docketName ?? "No Selected Docket"}
        </StyledEditBookingContentWrapper>
      </div>

      <div className="field-wrapper">
        <StyledEditBookingLabelText
          $themeColor={doxleThemeColor}
          $doxleFont={doxleFont}
        >
          Start Date
        </StyledEditBookingLabelText>
        <div
          className="date-picker-wrapper"
          onMouseEnter={(e) => {
            e.stopPropagation();
            setOnHoverStartDate(true);
          }}
          onMouseLeave={(e) => {
            setOnHoverStartDate(false);
          }}
        >
          <CustomDatePicker
            value={startDate ? dayjs(startDate).format("YYYY/MM/DD") : null}
            onChange={(value: dayjs.Dayjs | null) => {
              setStartDate(value ? value.toDate() : null);
            }}
          />
          {startDate && onHoverStartDate && (
            <DoxleIconButton
              iconSource={
                <HighlightOffSharpIcon
                  htmlColor={doxleThemeColor.primaryFontColor}
                  sx={{
                    fontSize: "2rem",
                  }}
                />
              }
              buttonSize={30}
              onClick={() => {
                setStartDate(null);
              }}
            />
          )}
        </div>
      </div>

      <div className="field-wrapper">
        <StyledEditBookingLabelText
          $themeColor={doxleThemeColor}
          $doxleFont={doxleFont}
        >
          End Date
        </StyledEditBookingLabelText>
        <div
          className="date-picker-wrapper"
          onMouseEnter={(e) => {
            e.stopPropagation();
            setOnHoverEndDate(true);
          }}
          onMouseLeave={(e) => {
            setOnHoverEndDate(false);
          }}
        >
          <CustomDatePicker
            value={endDate ? dayjs(endDate).format("YYYY/MM/DD") : null}
            onChange={(value: dayjs.Dayjs | null) => {
              setEndDate(value ? value.toDate() : null);
            }}
          />
          {endDate && onHoverEndDate && (
            <DoxleIconButton
              iconSource={
                <HighlightOffSharpIcon
                  htmlColor={doxleThemeColor.primaryFontColor}
                  sx={{
                    fontSize: "2rem",
                  }}
                />
              }
              buttonSize={30}
              onClick={() => {
                setEndDate(null);
              }}
            />
          )}
        </div>
      </div>
    </StyledEditBookingDocketAndDateContainer>
  );
};

export default EditBookingDocketAndDate;
