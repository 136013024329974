import { OrderLine } from "../../Models/order";
import { memo, useEffect, useMemo, useRef } from "react";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import { useEditOrderStore } from "../../OrdersStore/useEditOrderStore";
import {
  StyledOrderLineCell,
  StyledOrderLineTableRow,
  StyledOrderLineTableRowWrapper,
} from "./StyledComponents";
import { ORDER_DETAIL_TABLE_HEADER_LIST } from "../../Models/orderDetail";
import { formatter } from "../../../Utilities/FunctionUtilities";
import {
  StyledOrderLineInputAdornment,
  StyledOrderLineItemTextField,
} from "../EditOrder/StyledComponentEditOrder";
import useOrderLineTableRow from "../EditOrder/Hooks/useOrderLineTableRow";
import { AnimatePresence, motion } from "framer-motion";
import { HiOutlineTrash } from "react-icons/hi2";
import { CircularProgress } from "@mui/material";
import { useEditOrderContext } from "../EditOrder/EditOrder";
type Props = {
  orderLine: OrderLine;
  lastLine: boolean;
  index: number;
};

const OrderLineTableRow = ({ orderLine, lastLine, index }: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }),
    shallow
  );

  const { editedOrder, readOnly, removeOrderLine, updateOrderLine } =
    useEditOrderStore(
      (state) => ({
        editedOrder: state.editedOrder,
        readOnly: state.readOnly,
        removeOrderLine: state.removeOrderLine,
        updateOrderLine: state.updateOrderLine,
      }),
      shallow
    );
  const {
    handleLineDescriptionChange,
    handleLineUnitChange,
    handleLineQtyChange,
    handleItemCostChange,
    handleUpdateOrderLine,
    showDeleteIcon,
    onMouseEnterRow,
    onMouseLeaveRow,
  } = useOrderLineTableRow({ orderLine, index });
  const editOrderContext = useEditOrderContext();
  const { setIsOrderLineUpdated } = editOrderContext
    ? editOrderContext : {setIsOrderLineUpdated: (bool: boolean)=>{}}
  const handleSelect = (itemKeyProp: keyof OrderLine) => {
    const ref =
      itemKeyProp === "description"
        ? desRef
        : itemKeyProp === "quantity"
        ? qTyRef
        : itemKeyProp === "unit"
        ? unitRef
        : itemKeyProp === "itemCost"
        ? itemCostRef
        : null;
    if (ref && ref?.current) ref.current.select();
  };
  const desRef = useRef<HTMLInputElement>(null);
  const qTyRef = useRef<HTMLInputElement>(null);
  const unitRef = useRef<HTMLInputElement>(null);
  const itemCostRef = useRef<HTMLInputElement>(null);
  const rowVariants = useMemo(
    () => ({
      initial: {
        x: -10,
        opacity: 0,
        scaleY: 0,
      },
      entering: {
        x: 0,
        opacity: 1,
        scaleY: 1,
      },
    }),
    []
  );

  useEffect(() => {
    if (orderLine.isNew) {
      desRef.current?.focus();

      const timeout = setTimeout(() => {
        updateOrderLine(index, { isNew: false });
      }, 400);

      return () => clearTimeout(timeout);
    }
  }, []);

  return (
    <StyledOrderLineTableRowWrapper>
      <StyledOrderLineTableRow
        $themeColor={doxleThemeColor}
        onMouseEnter={onMouseEnterRow}
        onMouseLeave={onMouseLeaveRow}
        variants={rowVariants}
        initial="initial"
        animate="entering"
        transition={{
          duration: 0.2,
        }}
      >
        {ORDER_DETAIL_TABLE_HEADER_LIST.map((header, idx) => (
          <StyledOrderLineCell
            className={"description-cell"}
            $themeColor={doxleThemeColor}
            $doxleFont={doxleFont}
            $width={
              header.itemKeyProp !== "description"
                ? header.itemKeyProp !== "itemCost"
                  ? header.itemKeyProp === "lineCost"
                    ? "12rem"
                    : "8rem"
                  : "10rem"
                : undefined
            }
            $hasBorderRight={idx !== ORDER_DETAIL_TABLE_HEADER_LIST.length - 1}
            key={`line${orderLine.orderLineId}#${idx}`}
          >
            {header.itemKeyProp !== "lineCost" && (
              <StyledOrderLineItemTextField
                inputRef={
                  header.itemKeyProp === "description"
                    ? desRef
                    : header.itemKeyProp === "quantity"
                    ? qTyRef
                    : header.itemKeyProp === "unit"
                    ? unitRef
                    : itemCostRef
                }
                disabled={readOnly}
                onBlur={() => {
                  if (
                    header.itemKeyProp === "quantity" ||
                    header.itemKeyProp === "itemCost"
                  )
                    handleUpdateOrderLine(header.itemKeyProp);
                }}
                onFocusCapture={() => handleSelect(header.itemKeyProp)}
                onKeyDown={(e) => {
                  if (e.nativeEvent.key === "Enter") {
                    e.preventDefault();
                    if (header.itemKeyProp === "description")
                      desRef.current?.blur();
                    if (header.itemKeyProp === "quantity")
                      qTyRef.current?.blur();
                    if (header.itemKeyProp === "unit") unitRef.current?.blur();
                    if (header.itemKeyProp === "itemCost")
                      itemCostRef.current?.blur();
                  }
                }}
                $themeColor={doxleThemeColor}
                $doxleFont={doxleFont}
                variant="standard"
                onFocus={(e) => e.currentTarget.select()}
                value={
                  header.itemKeyProp === "description"
                    ? orderLine.description
                    : header.itemKeyProp === "quantity"
                    ? orderLine.quantity
                    : header.itemKeyProp === "unit"
                    ? orderLine.unit
                    : orderLine.itemCost
                }
                onChange={(event) =>
                  header.itemKeyProp === "description"
                    ? handleLineDescriptionChange(event)
                    : header.itemKeyProp === "quantity"
                    ? handleLineQtyChange(event)
                    : header.itemKeyProp === "unit"
                    ? handleLineUnitChange(event)
                    : handleItemCostChange(event)
                }
                InputProps={{
                  startAdornment: (header.itemKeyProp === "description" ||
                    header.itemKeyProp === "itemCost") && (
                    <StyledOrderLineInputAdornment
                      $themeColor={doxleThemeColor}
                      $doxleFont={doxleFont}
                      position="start"
                    >
                      {header.itemKeyProp === "description" &&
                        `${editedOrder?.orderNumber}.${
                          index < 10 ? `0${index + 1}` : index + 1
                        }`}
                      {header.itemKeyProp === "itemCost" && "$ "}
                    </StyledOrderLineInputAdornment>
                  ),
                }}
                $textAlign={
                  header.itemKeyProp === "description" ? "left" : "center"
                }
              />
            )}

            {header.itemKeyProp === "lineCost" && (
              <div className="line-cost-cell">
                {formatter.format(Number(orderLine.lineCost))}
              </div>
            )}
          </StyledOrderLineCell>
        ))}
        <StyledOrderLineCell
          $doxleFont={doxleFont}
          $themeColor={doxleThemeColor}
          $width={"2rem"}
          $hasBorderRight={false}
        >
          <AnimatePresence>
            {showDeleteIcon && !readOnly && (
              <motion.div
                initial={{
                  y: 10,
                  opacity: 0,
                }}
                animate={{
                  y: 0,
                  opacity: 1,
                }}
                exit={{
                  y: 10,
                  opacity: 0,
                }}
                className="delete-icon-wrapper"
                onClick={() => {
                  removeOrderLine(index);
                  setIsOrderLineUpdated(true);
                }}
              >
                <HiOutlineTrash style={{ color: "red", fontSize: "1.6rem" }} />
              </motion.div>
            )}
          </AnimatePresence>
        </StyledOrderLineCell>
      </StyledOrderLineTableRow>
    </StyledOrderLineTableRowWrapper>
  );
};

export default memo(OrderLineTableRow);
