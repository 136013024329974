import React, { createContext, useContext, useEffect, useState } from "react";
import DoxleAPI from "../../Services/DoxleAPI";
import CookieService from "../../Services/cookieService";
import { User } from "../../Models/user";
import { useDoxleAuthStore } from "../../DoxleGeneralStore/useDoxleAuthStore";
import { shallow } from "zustand/shallow";
import { AuthQueryAPI } from "../../Services/QueryHooks/authQueryAPI";
import {useDoxleWindowStore} from "../../DoxleGeneralStore/useDoxleWindowSizeStore";
import {useDoxleThemeStore} from "../../DoxleGeneralStore/useDoxleThemeStore";
// import {useQueryClient} from "@tanstack/react-query";
// import useDoxleNotificationStore from "../../DoxleGeneralStore/useDoxleNotificationStore";

interface authContextInterface {}
const getInitialUser = () => {
  try {
    const userStr = localStorage.getItem("currentUser") || "";
    const user: User | undefined = userStr ? JSON.parse(userStr) : undefined;
    console.log("USER GET:", user);
    return user;
  } catch (e) {
    console.log(e);
    return undefined;
  }
};

const AuthContext = createContext({});
const AuthProvider = (children: any) => {
  let timer: NodeJS.Timeout | null = null;
  const {setTheme, setFontMode} = useDoxleThemeStore(state => ({
    setTheme: state.setTheme,
    setFontMode: state.setFontMode,
  }), shallow)
  const [isFetchingUser, setIsFetchingUser] = useState<boolean>(false);
  // const showNotification = useDoxleNotificationStore(state => state.showNotification, shallow)
  // const queryClient = useQueryClient();
  const {
    user,
    loggedIn,
    getUser,
    setFirstAuth,
    setLoggedIn,
  } = useDoxleAuthStore(
    (state) => ({
      user: state.user,
      getUser: state.getUser,
      setFirstAuth: state.setFirstAuth,
      setLoggedIn: state.setLoggedIn,
      loggedIn: state.loggedIn,
    }),
    shallow
  );

  const handleFetchUser = async () => {
    if (!isFetchingUser && user === undefined && loggedIn !== false) {
      setIsFetchingUser(true)
      const user = await getUser()
      setIsFetchingUser(false)
      if (
        user?.lastFont === "Inter"
        || user?.lastFont === "Serif"
        || user?.lastFont === "Mono"
        || user?.lastFont === "Default"
      ) setFontMode(user?.lastFont)
      if (
        user?.lastTheme === "light" ||
        user?.lastTheme === "contrast" ||
        user?.lastTheme ==="dark"
      ) setTheme(user?.lastTheme)
    }
  }

  useEffect(() => {
    if (!user) {
      setFirstAuth(true);
    }
  }, []);

  useEffect(() => {
    handleFetchUser()
  }, [user, loggedIn]);

  // const useExchangeRefreshTokenQuery = AuthQueryAPI.useExchangeRefreshToken({
  //   getStorageExchangeRFToken: getStorageRefreshToken,
  //   onErrorCb: clearAuthAsyncStorage,
  //   showNotification,
  //   onSuccessCb: onSuccessExchangeRefreshToken,
  //   enable: enableExchangeTokenQuery,
  // });
  const authContextValue: authContextInterface = {};
  return <AuthContext.Provider value={authContextValue} {...children} />;
};
const useAuth = () => useContext(AuthContext);
export { AuthProvider, useAuth };
