import {
  UseInfiniteQueryResult,
  UseQueryResult,
  useInfiniteQuery,
  useMutation,
  useQuery,
} from "@tanstack/react-query";

import axios, { AxiosResponse, isAxiosError } from "axios";
import { isEmpty } from "lodash";
import { BaseAPIProps } from "../../Models/baseAPIProps";
import {
  IAddQRCodeParams,
  IPatchQRCodeParams,
  IQRAccessLog,
  IQRAccessScanParams,
  IQRCode,
  IQRCodeFilterQuery,
  IQRCodeFullDetail,
  IQRLogFilterQuery,
  IQRUserLog,
  IQRUserLogFilterQuery,
  IScanQRCodeReturnedData,
} from "../Models/qrLog";
import { useDoxleCurrentContextStore } from "../../DoxleGeneralStore/useDoxleCurrentContext";
import { useShallow } from "zustand/react/shallow";
import { IApiPaginatedData } from "../../Models/axiosReturn";
import DoxleAPI from "../../Services/DoxleAPI";
import { removeFalsyProps } from "../../Utilities/FunctionUtilities";
import useSetQRCodeQueryData from "./useSetQRCodeQueryData";
import { Company } from "../../Models/company";

interface IRetrieveQRCodeQueryProps extends BaseAPIProps {
  filter: IQRCodeFilterQuery;
  enableQuery?: boolean;
}

const useRetrieveQRCodeListQuery = ({
  showNotification,
  filter,
  enableQuery,
}: IRetrieveQRCodeQueryProps) => {
  const company = useDoxleCurrentContextStore(
    useShallow((state) => state.currentCompany)
  );
  const qKey = formQRCodeListQKey(filter, company);

  let noteURL = `/qr_code/?page=1`;
  const defectQuery = useInfiniteQuery(
    qKey,
    ({ pageParam = noteURL }) =>
      DoxleAPI.get<IApiPaginatedData<IQRCode>>(pageParam, {
        headers: {
          "User-Company": company!.companyId,
        },
        params: removeFalsyProps(filter),
      }),

    {
      getNextPageParam: (prev) => prev.data.next,
      enabled: company !== undefined && (enableQuery || false),
      retry: 2,
      retryDelay: 4 * 1000,
      refetchInterval: false,

      onSuccess: (res) => {},
      onError: () => {
        // if (showNotification)
        //   showNotification('SOMETHING WRONG', 'error', 'fail to get note list');
      },
    }
  );
  return defectQuery;
};

interface IRetrieveQRDetailQueryProps extends BaseAPIProps {
  qrId: string;
  enableQuery?: boolean;
}
const useRetrieveQRDetailQuery = ({
  showNotification,
  qrId,
  enableQuery,
}: IRetrieveQRDetailQueryProps) => {
  const company = useDoxleCurrentContextStore(
    useShallow((state) => state.currentCompany)
  );
  const qKey = formQRCodeDetailQKey(qrId);

  let noteURL = "/qr_code/" + `${qrId}/`;
  const defectQuery = useQuery(
    qKey,
    ({ pageParam = noteURL }) =>
      DoxleAPI.get<IQRCodeFullDetail>(pageParam, {
        headers: {
          "User-Company": company!.companyId,
        },
      }),

    {
      enabled: company !== undefined && (enableQuery || false),
      retry: 1,
      retryDelay: 3 * 1000,
      refetchInterval: false,

      onSuccess: (res) => {},
      onError: (err) => {
        // if (showNotification)
        //   showNotification('SOMETHING WRONG', 'error', 'fail to get note list');
      },
    }
  );
  return defectQuery;
};

interface IMutateQRCodeProps extends BaseAPIProps {
  onSuccessAddQRCode?: (newQRCode: IQRCode) => void;
  onSuccessUpdateQRCode?: (updatedQRCode: IQRCode) => void;
  onSuccessDeleteQRCode?: (deletedQRID: string) => void;
}
const useMutateQRCode = ({
  company,
  showNotification,
  onSuccessAddQRCode,
  onSuccessUpdateQRCode,
  onSuccessDeleteQRCode,
}: IMutateQRCodeProps) => {
  const { handleAddQRCode, handleRemoveQRCode, handleUpdateQRCode } =
    useSetQRCodeQueryData({
      appendPost: "end",
    });
  const add = useMutation({
    mutationKey: getQRCodeMutationKey("add"),
    mutationFn: async ({ grantedUserList, ...rest }: IAddQRCodeParams) => {
      const formData = new FormData();
      Object.entries(rest).forEach(([key, value]) => {
        if (value) formData.append(key, value.toString());
      });
      grantedUserList.forEach((user) => {
        formData.append("white_list", user.user);
      });
      return DoxleAPI.post<IQRCode>("/qr_code/", formData, {
        headers: {
          "User-Company": company?.companyId ?? "",
        },
      });
    },
    onSuccess: (result, variables, context) => {
      handleAddQRCode(result.data);
      if (showNotification)
        showNotification("Item Added", "success", "SUCCESSFULLY added QR Code");
      if (onSuccessAddQRCode) onSuccessAddQRCode(result.data);
    },
    // if (handleScrollToBottomBudgetTable) handleScrollToBottomBudgetTable()
    onError: (error, variables, context) => {
      if (showNotification)
        showNotification("Failed To Add Item!", "error", "Fail To add QR Code");
    },
  });
  const destroy = useMutation({
    mutationKey: getQRCodeMutationKey("delete"),
    mutationFn: async (deletedId: string) => {
      return DoxleAPI.delete("/qr_code/" + `${deletedId}/`, {
        headers: {
          "User-Company": company?.companyId ?? "",
        },
      });
    },
    onSuccess: (result, variables, context) => {
      handleRemoveQRCode(variables);
      if (showNotification)
        showNotification(
          "Item Deleted",
          "success",
          "SUCCESSFULLY added QR Code"
        );
      if (onSuccessDeleteQRCode) onSuccessDeleteQRCode(variables);
    },
    // if (handleScrollToBottomBudgetTable) handleScrollToBottomBudgetTable()
    onError: (error, variables, context) => {
      if (showNotification)
        showNotification("Failed To Add Item!", "error", "Fail To add QR Code");
    },
  });

  const patch = useMutation({
    mutationKey: getQRCodeMutationKey("update"),
    mutationFn: async ({
      qrCodeId,
      whiteList,
      ...rest
    }: IPatchQRCodeParams) => {
      const data = whiteList
        ? { ...rest, white_list: whiteList?.map((user) => user.user) }
        : rest;
      return DoxleAPI.patch<IQRCodeFullDetail>(
        "/qr_code/" + `${qrCodeId}/`,
        data,
        {
          headers: {
            "User-Company": company?.companyId ?? "",
          },
        }
      );
    },
    onSuccess: (result, variables, context) => {
      handleUpdateQRCode(result.data);
      if (showNotification)
        showNotification(
          "Item Updated",
          "success",
          "SUCCESSFULLY added QR Code"
        );
      if (onSuccessUpdateQRCode) onSuccessUpdateQRCode(result.data);
    },

    onError: (error, variables, context) => {
      if (showNotification)
        showNotification(
          "Failed To Update Item!",
          "error",
          "Fail To add QR Code"
        );
    },
  });

  return {
    add: { ...add, mutate: (qr: IAddQRCodeParams) => add.mutate(qr) },
    destroy: { ...destroy, mutate: (id: string) => destroy.mutate(id) },
    patch: { ...patch, mutate: (qr: IPatchQRCodeParams) => patch.mutate(qr) },
  };
};

//* QR LOG
interface IRetrieveQRLogQueryProps extends BaseAPIProps {
  filter: IQRLogFilterQuery;
  enableQuery?: boolean;
}
const useRetrieveQRLogListQuery = ({
  showNotification,
  company,
  filter,
  enableQuery,
}: IRetrieveQRLogQueryProps) => {
  const qKey = formQRLogListQKey(filter, company);

  let url = `/qr_code/qr_log/?page=1`;
  const query = useInfiniteQuery(
    qKey,
    ({ pageParam = url }) =>
      DoxleAPI.get<IApiPaginatedData<IQRAccessLog>>(pageParam, {
        headers: {
          "User-Company": company!.companyId,
        },
        params: removeFalsyProps(filter),
      }),

    {
      getNextPageParam: (prev) => prev.data.next,
      enabled: company !== undefined && (enableQuery || false),
      retry: 2,
      retryDelay: 4 * 1000,
      refetchInterval: false,

      onSuccess: (res) => {},
      onError: () => {
        // if (showNotification)
        //   showNotification('SOMETHING WRONG', 'error', 'fail to get note list');
      },
    }
  );
  return query;
};

// * QR User Log
type IRetrieveQRUserLogQueryProps<
  T extends IQRUserLogFilterQuery["pagination"]
> = BaseAPIProps & {
  filter: IQRUserLogFilterQuery;
  enableQuery?: boolean;
  isNoPagination?: T;
};
const useRetrieveQRUserLogListQuery = <
  T extends IQRUserLogFilterQuery["pagination"]
>({
  showNotification,

  filter,
  enableQuery,
  isNoPagination,
}: IRetrieveQRUserLogQueryProps<T>) => {
  const company = useDoxleCurrentContextStore(
    useShallow((state) => state.currentCompany)
  );
  const qKey = formQRUserLogListQKey(filter, company);

  let url = `/qr_code/qr_user_log/?page=1`;
  Object.keys(filter).map((key) => {
    const prop = key as keyof IQRUserLogFilterQuery;
    if (filter[prop] && !isEmpty(filter[prop])) {
      if ((key as keyof IQRUserLogFilterQuery) !== "search_date_range")
        url += `&${key}=${filter[prop]}`;
      else {
        const range = filter.search_date_range!;
        url += `&${key}=${range[0]}`;
        url += `&${key}=${range[1]}`;
      }
    }
  });
  const query = useInfiniteQuery(
    qKey,
    ({ pageParam = url }) =>
      DoxleAPI.get<IApiPaginatedData<IQRUserLog>>(pageParam, {
        headers: {
          "User-Company": company!.companyId,
        },
      }),

    {
      getNextPageParam: (prev) => prev.data.next,
      enabled:
        company !== undefined &&
        (enableQuery || false) &&
        isNoPagination !== "none",
      retry: 2,
      retryDelay: 4 * 1000,
      refetchInterval: false,

      onSuccess: (res) => {},
      onError: () => {
        // if (showNotification)
        //   showNotification('SOMETHING WRONG', 'error', 'fail to get note list');
      },
    }
  );

  const queryWithoutPagination = useQuery(
    qKey,
    ({ pageParam = url }) =>
      DoxleAPI.get<IQRUserLog[]>(pageParam, {
        headers: {
          "User-Company": company!.companyId,
        },
      }),

    {
      enabled:
        company !== undefined &&
        (enableQuery || false) &&
        isNoPagination === "none",
      retry: 2,
      retryDelay: 4 * 1000,
      staleTime: 1 * 60 * 1000,
      refetchOnMount: true,
      refetchInterval: false,

      onSuccess: (res) => {},
      onError: () => {
        // if (showNotification)
        //   showNotification('SOMETHING WRONG', 'error', 'fail to get note list');
      },
    }
  );

  return isNoPagination === "none"
    ? (queryWithoutPagination as UseQueryResult<
        AxiosResponse<IQRUserLog[], any>,
        unknown
      >)
    : (query as UseInfiniteQueryResult<
        AxiosResponse<IApiPaginatedData<IQRUserLog>, any>,
        unknown
      >);
};
interface IMutateQRLogProps extends BaseAPIProps {
  onSuccessAddQRLog?: (newQRCode: IScanQRCodeReturnedData) => void;
  onErrorAddQRLog?: (errorCode: number) => void;
}

const useMutateQRLog = ({
  company,
  showNotification,
  onSuccessAddQRLog,
  onErrorAddQRLog,
}: IMutateQRLogProps) => {
  const { handleAddQRUserLog } = useSetQRCodeQueryData({
    appendPost: "start",
  });
  const scanner = useMutation({
    mutationKey: getQRCodeMutationKey("scan"),
    mutationFn: async (qr: IQRAccessScanParams) => {
      return DoxleAPI.post<IScanQRCodeReturnedData>("/qr_code/qr_log/", qr, {
        headers: {
          "User-Company": company?.companyId ?? "",
        },
      });
    },
    onSuccess: (result, variables, context) => {
      handleAddQRUserLog(result.data.userLog);
      if (showNotification)
        showNotification("Item Added", "success", "SUCCESSFULLY added QR Code");
      if (onSuccessAddQRLog) onSuccessAddQRLog(result.data);
    },
    // if (handleScrollToBottomBudgetTable) handleScrollToBottomBudgetTable()
    onError: (error, variables, context) => {
      if (showNotification)
        showNotification("Failed To Add Item!", "error", "Fail To add QR Code");

      if (onErrorAddQRLog && isAxiosError(error))
        onErrorAddQRLog(error.response?.status ?? 400);
    },
  });

  return {
    scanner: {
      ...scanner,
      mutate: (qr: IQRAccessScanParams) => scanner.mutate(qr),
    },
  };
};
//# HELPER FUNCTIONS
export const baseQRCodeQKey = ["qr-code-list"];
export const formQRCodeListQKey = (
  filter: IQRCodeFilterQuery,
  company: Company | undefined
) => {
  const baseQKey = [...baseQRCodeQKey];
  if (company) baseQKey.push(company.companyId);
  Object.entries(filter).forEach(([key, value]) => {
    if (value) baseQKey.push(`${key}: ${value}`);
  });

  return baseQKey;
};

export const baseQRCodeDetailQKey = ["qr-code-detail"];
export const formQRCodeDetailQKey = (qrId: string) => {
  const baseQKey = [...baseQRCodeDetailQKey];

  baseQKey.push(qrId);
  return baseQKey;
};

export const getQRCodeMutationKey = (
  action: "add" | "update" | "delete" | "scan"
) => [`${action}-qr-code`];

//* QR LOG
export const baseQRLogQKey = ["qr-log-list"];
export const formQRLogListQKey = (
  filter: IQRLogFilterQuery,
  company: Company | undefined
) => {
  const baseQKey = [...baseQRLogQKey];
  if (company) baseQKey.push(company.companyId);
  Object.entries(filter).forEach(([key, value]) => {
    if (value) baseQKey.push(`${key}: ${value}`);
  });

  return baseQKey;
};

//* QR User Log
export const baseQRUserLogQKey = ["qr-user-log-list"];
export const formQRUserLogListQKey = (
  filter: IQRUserLogFilterQuery,
  company: Company | undefined
) => {
  const baseQKey = [...baseQRUserLogQKey];
  if (company) baseQKey.push(company.companyId);
  Object.entries(filter).forEach(([key, value]) => {
    if (value) baseQKey.push(`${key}: ${value}`);
  });

  return baseQKey;
};
const QRCodeAPI = {
  useRetrieveQRCodeListQuery,
  useMutateQRCode,
  useRetrieveQRLogListQuery,
  useMutateQRLog,
  useRetrieveQRDetailQuery,
  useRetrieveQRUserLogListQuery,
};

export default QRCodeAPI;
