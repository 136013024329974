import {Contact} from "../../Models/addressBook";
import {
  StyledContactEmail, StyledContactName,
  StyledPermissionContainer, StyledPermissionSelect,
  StyledSharedContactInfo,
  StyledSharedContactRow,
  StyledCopyLinkButton
} from "./SyledComponents";
import {DocketPermission} from "../../Models/permissions";
import {useMemo} from "react";
import {useDoxleThemeStore} from "../../DoxleGeneralStore/useDoxleThemeStore";
import {shallow} from "zustand/shallow";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import DropDownPermissions from "./DropDownPermissions";
import useDoxleShareDialogStore from "../../DoxleGeneralStore/useDoxleShareDialogStore";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DocketQuery from "../../Services/QueryHooks/docketQueryAPI";
import useDoxleNotificationStore from "../../DoxleGeneralStore/useDoxleNotificationStore";
import {useDoxleAuthStore} from "../../DoxleGeneralStore/useDoxleAuthStore";
import {useDoxleCurrentContextStore} from "../../DoxleGeneralStore/useDoxleCurrentContext";
import {
  useEditDocketSideScreenStore
} from "../EditDocketSideScreen/EditDocketSideScreenStore/useEditDocketSideScreenStore";
import MailOutlineIcon from '@mui/icons-material/MailOutline';
interface Props {
  docketPermission: DocketPermission
}


const SharedContactRow = ({docketPermission}: Props) => {
  const docket = useEditDocketSideScreenStore(state => state.edittedDocket, shallow)
  const showNotification = useDoxleNotificationStore(state => state.showNotification, shallow);
  const company = useDoxleCurrentContextStore(state => state.currentCompany, shallow);
  const {handleSetMenu} = useDoxleShareDialogStore((state) => ({
    handleSetMenu: state.handleSetDropDownMenu
  }), shallow)

  const styleProps = useDoxleThemeStore((state) => ({
    $themeColor: state.doxleThemeColor,
    $doxleFont: state.doxleFont,
    $currentTheme: state.currentTheme,
  }), shallow);

  const getPermissionsText = useMemo(()=>{
    const maxTextLength = 40
    const editable: string[] = []
    const viewable: string[] = []
    if (docketPermission.addSpecifications) editable.push("Scope Of Works")
    else if (docketPermission.viewSpecifications) viewable.push("Scope Of Works")
    if (docketPermission.addEstimate) editable.push("Budgets")
    else if (docketPermission.viewEstimate) viewable.push("Budgets")
    if (docketPermission.addOrders) editable.push("Orders")
    else if (docketPermission.viewOrders) viewable.push("Orders")
    if (docketPermission.addDefects) editable.push("QA")
    else if (docketPermission.viewDefects) viewable.push("QA")
    if (docketPermission.addFiles) editable.push("Files")
    else if (docketPermission.viewFiles) viewable.push("Files")
    if (docketPermission.addBookings) editable.push("Bookings")
    else if (docketPermission.viewBookings) viewable.push("Bookings")
    if (docketPermission.addRfi) editable.push("Mail")
    else if (docketPermission.viewRfi) viewable.push("Mail")
    if (docketPermission.addQuotes) editable.push("Quotes")
    else if (docketPermission.viewQuotes) viewable.push("Quotes")
    let returnString = ""
    if (editable.length) returnString = "Can edit " + editable.join(', ')
    if (editable.length && viewable.length) returnString += ", "
    if (viewable.length) returnString += "Can view " + viewable.join(', ')
    if (!returnString) returnString = "Limited Access"
    if (editable.length === 8) returnString = "Full Access"

    if (returnString.length > maxTextLength) return returnString.substring(0, maxTextLength - 3) + "..."
    return returnString
  }, [docketPermission])

  const shareDocketQuery = DocketQuery.useShareDocketLink({

    company,
    showNotification,
  })

  const handleGetLink = () => {
    if (docket?.docketPk) shareDocketQuery.getLink.mutate({
      docketPk: docket.docketPk,
      contactId: docketPermission.contact.contactId
    })
  }

  const handleShareDocket = () => {
    if (docket?.docketPk) shareDocketQuery.emailLink.mutate({
      docketPk: docket?.docketPk,
      contactIds: [docketPermission.contact.contactId],
    })
  }

  return (
    <StyledSharedContactRow {...styleProps}>
      <StyledSharedContactInfo {...styleProps}>
        <StyledContactName {...styleProps} >{docketPermission.contact.firstName+ " " + docketPermission.contact.lastName}</StyledContactName>
        <StyledContactEmail {...styleProps} >{docketPermission.contact.email}</StyledContactEmail>
      </StyledSharedContactInfo >
      <StyledPermissionContainer {...styleProps}>
        <StyledPermissionSelect
          {...styleProps}
          onClick={(e)=> handleSetMenu(docketPermission, e.target)}
        >
          {getPermissionsText} &nbsp; <KeyboardArrowDownIcon style={{transform: "translate(0px,3px)"}}/>
        </StyledPermissionSelect>
      </StyledPermissionContainer>
      <StyledCopyLinkButton
        {...styleProps}
        $marginRight={2}
        onClick={handleGetLink}
      >
        <ContentCopyIcon/>
      </StyledCopyLinkButton>
      <StyledCopyLinkButton
        {...styleProps}
        $marginRight={10}
        onClick={handleShareDocket}
      >
        <MailOutlineIcon/>
      </StyledCopyLinkButton>
    </StyledSharedContactRow>
  )
}

export default SharedContactRow
