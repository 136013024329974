import { Button, Dialog } from "@mui/material";
import { motion } from "framer-motion";
import styled from "styled-components";
import { DoxleFont, DoxleThemeColor } from "../DoxleGeneralStore/useDoxleThemeStore";
import { editRgbaAlpha } from "../Utilities/FunctionUtilities";


export const StyledImagePreviewPageRootContainer = styled(motion.div)`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;

  flex-direction: column;

  .image-content {
    flex: 1;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const StyledImagePreviewPageTitle = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  font-family: ${(p) => p.$doxleFont.secondaryFont};
  color: ${(p) => p.$themeColor.primaryFontColor};
  font-style: normal;
  font-weight: 500;
  font-size: 2.7rem;
  line-height: 3.2rem;
`;

export const StyledImagePreviewPagePageNumber = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  width: 100%;
  max-width: 25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .page-number-text {
    color: ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.$themeColor.primaryFontColor,
        alpha: "0.6",
      })};
    font-family: ${(p) => p.$doxleFont.secondaryFont};
    font-style: normal;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 2.3rem;
    align-self: center;
  }
`;

export const StyledPreviewDialogPageNumber = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  .page-number-text {
    color: ${(p) => p.$themeColor.primaryFontColor};
    font-family: ${(p) => p.$doxleFont.secondaryFont};
    font-style: normal;
    font-weight: 600;
    font-size: 2rem;
    line-height: 2.3rem;
    align-self: center;
  }
`;

export const StyledImagePreviewPageImage = styled.img<{
  $imageVertical?: boolean;
  $width?: number;
  $height?: number;
  $aspectRatio?: number;
}>`
  padding: 0px;
  border-radius: 0px;
  z-index: 101;

  transition: all 0.4s ease;
  /* width: 100% */
  max-width: ${(p) => p.$width}px;
  max-height: ${(p) => p.$height}px;
  /* max-height: 100%; */
  aspect-ratio: ${(p) => p.$aspectRatio};
`;

export const StyledImagePreviewContainer = styled.div<{
  imageVertical?: boolean;
}>`
  padding: 0px;
  margin-top: 10px;
  border-radius: 0px;
  z-index: 101;
  display: flex;
  height: 100%;
  width: 100%;
  transition: all 0.6s ease;
  // &:hover {
  //   transform: scale(1.2);
  //   position: absolute;
  // }
  .react-pdf__Page__canvas {
    margin: 0 auto;
    width: 100% !important;
    height: 100% !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .react-pdf__Document {
    margin: 0 auto;
    width: 100% !important;
    height: 100% !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const StyledImagePreviewPageNavigateLeftButton = styled(Button)`
  && {
    display: flex;

    min-width: 40px;
    max-width: 40px;
    min-height: 40px;
    max-height: 40px;
    transition: 0.2s;
    &:hover {
      transform: scale(1.1);
      transition: 0.2s;
    }
  }
`;

export const StyledImagePreviewPageNavigateRightButton = styled(Button)`
  && {
    display: flex;

    min-width: 40px;
    max-width: 40px;
    min-height: 40px;
    max-height: 40px;
    transition: 0.2s;
    &:hover {
      transform: scale(1.1);
      transition: 0.2s;
    }
  }
`;

export const StyledImagePreviewPageSpaceBetweenDiv = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledImagePreviewPageReturnToHomeButton = styled(Button)`
  && {
    height: 28px;
    position: absolute;
    top: 40px;
    left: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: black;
  }
`;

export const StyledImagePreviewTopContainer = styled.div`
  display: flex;
  width: 60%;
  align-self: flex-start;
  justify-content: space-between;
  margin-top: 2rem;
`;

export const StyledImagePreviewDialogTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: relative;
`;

export const StyledImagePreviewDialog = styled(Dialog)`
  & .MuiDialog-paper {
    background-color: transparent;
    box-shadow: none;
    width: 100%;
    max-width: none;
    height: 100%;
    overflow-y: hidden;
  }

  & .MuiDialog-paper::-webkit-scrollbar {
    display: none;
  }
`;
export const StyledImagePreviewDialogContainer = styled.div`
  text-align: center;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const StyledPreviewImageContainer = styled.div`
  flex: 1;
  padding: 14px 0px;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

export const StyledPreviewImageTitle = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  font-family: ${(p) => p.$doxleFont.secondaryFont};
  font-style: normal;
  font-weight: 500;
  font-size: 2.7rem;
  color: ${(p) => p.$themeColor.primaryFontColor};

  align-self: flex-start;

  position: absolute;
  left: 0px;
`;
