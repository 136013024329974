import styled from "styled-components";
import {
  DoxleFont,
  DoxleThemeColor,
} from "../../DoxleGeneralStore/useDoxleThemeStore";
import { TRgbaFormat, editRgbaAlpha } from "../../Utilities/FunctionUtilities";
import { motion } from "framer-motion";

export const StyledCommentUserInitialContainer = styled(motion.div)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
  $focus: boolean;
  $size: number;
}>`
  width: ${(p) => p.$size}px;
  height: ${(p) => p.$size}px;
  border-radius: 16px;

  font-size: ${(p) => p.$size * (7 / 16)}px;
  font-family: ${(p) => p.$doxleFont.secondaryTitleFont};
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
`;
export const StyledProjectDisplaySectionContainer = styled(motion.div)`
  min-width: max(20%, 180px);

  display: flex;
  flex-direction: column;
  margin-right: 6rem;
  overflow: hidden;
  margin-bottom: 20px;
  justify-content: center;

  .project-displayer-section {
    display: flex;
    flex-direction: column;
    width: 100%;

    .row-wrapper {
      display: flex;
      align-items: center;
      position: relative;
      width: 100%;
    }
  }
`;
