import {
  StyledContactEmail,
  StyledContactName,
  StyledPermissionContainer,
  StyledPermissionSelect,
  StyledSharedContactInfo,
  StyledSharedContactRow,
  StyledCopyLinkButton,
} from "./SyledComponents";
import {
  CompanyPermission,
  DocketPermission,
  ProjectPermission,
} from "../../Models/permissions";
import { useMemo } from "react";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import useDoxleShareDialogStore from "../../DoxleGeneralStore/useDoxleShareDialogStore";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import useDoxleNotificationStore from "../../DoxleGeneralStore/useDoxleNotificationStore";
import { useDoxleCurrentContextStore } from "../../DoxleGeneralStore/useDoxleCurrentContext";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { Project } from "../../Models/project";
import ProjectQueryAPI from "../../Services/QueryHooks/projectQueryAPI";

interface Props {
  project: Project | undefined;
  projectPermission: ProjectPermission | DocketPermission;
}

const SharedContactRow = ({ project, projectPermission }: Props) => {
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );
  const company = useDoxleCurrentContextStore(
    (state) => state.currentCompany,
    shallow
  );
  const { handleSetMenu } = useDoxleShareDialogStore(
    (state) => ({
      handleSetMenu: state.handleSetDropDownMenu,
    }),
    shallow
  );

  const styleProps = useDoxleThemeStore(
    (state) => ({
      $themeColor: state.doxleThemeColor,
      $doxleFont: state.doxleFont,
      $currentTheme: state.currentTheme,
    }),
    shallow
  );

  const getPermissionsText = useMemo(() => {
    const maxTextLength = 40;
    const editable: string[] = [];
    const viewable: string[] = [];
    if (projectPermission.addSpecifications) editable.push("Scope Of Works");
    else if (projectPermission.viewSpecifications)
      viewable.push("Scope Of Works");
    if (projectPermission.addEstimate) editable.push("Budgets");
    else if (projectPermission.viewEstimate) viewable.push("Budgets");
    if (projectPermission.addOrders) editable.push("Orders");
    else if (projectPermission.viewOrders) viewable.push("Orders");
    if (projectPermission.addDefects) editable.push("QA");
    else if (projectPermission.viewDefects) viewable.push("QA");
    if (projectPermission.addFiles) editable.push("Files");
    else if (projectPermission.viewFiles) viewable.push("Files");
    if (projectPermission.addBookings) editable.push("Bookings");
    else if (projectPermission.viewBookings) viewable.push("Bookings");
    if (projectPermission.addRfi) editable.push("Mail");
    else if (projectPermission.viewRfi) viewable.push("Mail");
    if (projectPermission.addQuotes) editable.push("Quotes");
    else if (projectPermission.viewQuotes) viewable.push("Quotes");
    let returnString = "";
    if (editable.length) returnString = "Can edit " + editable.join(", ");
    if (editable.length && viewable.length) returnString += ", ";
    if (viewable.length) returnString += "Can view " + viewable.join(", ");
    if (!returnString) returnString = "Limited Access";
    if (editable.length === 8) returnString = "Full Access";

    if (returnString.length > maxTextLength)
      return returnString.substring(0, maxTextLength - 3) + "...";
    return returnString;
  }, [projectPermission]);

  const shareDocketQuery = ProjectQueryAPI.useShareProjectLink({
    company,
    showNotification,
  });

  const handleGetLink = () => {
    if (project?.projectId)
      shareDocketQuery.getLink.mutate({
        projectId: project?.projectId,
        contactId: projectPermission.contact.contactId,
      });
  };

  const handleShareDocket = () => {
    if (project?.projectId)
      shareDocketQuery.emailLink.mutate({
        projectId: project?.projectId,
        contactIds: [projectPermission.contact.contactId],
      });
  };
  console.log("shared contact row project", project);
  console.log("shared contact row", projectPermission);
  return (
    <StyledSharedContactRow {...styleProps}>
      <StyledSharedContactInfo {...styleProps}>
        <StyledContactName {...styleProps}>
          {projectPermission.contact.firstName +
            " " +
            projectPermission.contact.lastName}
        </StyledContactName>
        <StyledContactEmail {...styleProps}>
          {projectPermission.contact.email}
        </StyledContactEmail>
      </StyledSharedContactInfo>
      <StyledPermissionContainer {...styleProps}>
        <StyledPermissionSelect
          {...styleProps}
          onClick={(e) => handleSetMenu(projectPermission, e.currentTarget)}
        >
          {getPermissionsText} &nbsp;{" "}
          <KeyboardArrowDownIcon style={{ transform: "translate(0px,3px)" }} />
        </StyledPermissionSelect>
      </StyledPermissionContainer>
      <StyledCopyLinkButton
        {...styleProps}
        $marginRight={2}
        onClick={handleGetLink}
      >
        <ContentCopyIcon />
      </StyledCopyLinkButton>
      <StyledCopyLinkButton
        {...styleProps}
        $marginRight={10}
        onClick={handleShareDocket}
      >
        <MailOutlineIcon />
      </StyledCopyLinkButton>
    </StyledSharedContactRow>
  );
};

export default SharedContactRow;
