import React, { createContext, useContext } from "react";
import {
  StyledBookingCommentBtn,
  StyledBookingPercentSlider,
  StyledBookingPercentageText,
  StyledEditBookingDialog,
  StyledEditBookingDialogActions,
  StyledEditBookingDialogButton,
  StyledEditBookingDialogContent,
  StyledEditBookingDialogTitle,
  StyledEditDocketAndDateWrapper,
} from "./StyledEditBookingDialog";
import useEditBookingDialog, {
  IEditBookingContextValue,
} from "../Hooks/useEditBookingDialog";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { BsCalendar2 } from "react-icons/bs";
import EditBookingTitleSection from "./EditBookingTitleSection";
import EditBookingDocketSection from "./EditBookingDocketSection";
import BookingDateRangePicker from "../../CommonComponents/BookingDateRangePicker";
import DoxleIconButton from "../../../DoxleDesignPattern/DoxleButtons/DoxleIconButton";
import {
  DoxleCheckedIcon,
  DoxleDeleteIcon,
} from "../../../DoxleDesignPattern/DoxleGeneralIcons";
import { FaAngleLeft, FaRegComment } from "react-icons/fa6";
import useGetDocketFullDetails from "../../../CoreContent/QueryDataHooks/GetQueryDataHooks/useGetDocketFullDetails";
import { AnimatePresence, motion } from "framer-motion";
import CircularProgress from "@mui/material/CircularProgress";
import BookingDocketComment from "../../BookingProject/Components/BookingDocketDetail/BookingDocketComment";
import { editRgbaAlpha } from "../../../Utilities/FunctionUtilities";
import ProgressScreen from "../../../Utilities/Lottie/ProgressScreen";

type Props = {};

const EditBookingContext = createContext<IEditBookingContextValue | null>(null);
const EditBookingDialog = (props: Props) => {
  const { doxleThemeColor, staticMenuColor } = useDoxleThemeStore();

  const {
    isOpen,
    closeDialog,
    editBookingData,
    titleDialog,
    contentAnimateVariants,
    contextValue,
    onPickDate,
    onPercentageChange,
    onToggleComplete,
    onClickDelete,
    setShowCommentSection,
    showCommentSection,
    isUpdating,
    onClickSubmit,
  } = useEditBookingDialog();

  const { docketDetail, isFetchingDocketDetail } = useGetDocketFullDetails({
    docketPk: editBookingData?.docket ?? "",
  });
  return (
    <EditBookingContext.Provider value={contextValue}>
      <StyledEditBookingDialog
        open={isOpen}
        onClose={closeDialog}
        slotProps={{
          backdrop: {
            sx: {
              bgcolor: staticMenuColor.staticBackdrop,
            },
          },
        }}
      >
        <StyledEditBookingDialogTitle>
          <div
            className="title-wrapper"
            style={{
              cursor: showCommentSection ? "pointer" : "default",
            }}
            onClick={() => setShowCommentSection(false)}
          >
            {showCommentSection ? (
              <FaAngleLeft
                color={doxleThemeColor.primaryFontColor}
                className="icon"
                size={20}
              />
            ) : (
              <BsCalendar2
                color={doxleThemeColor.primaryFontColor}
                className="icon"
                size={20}
              />
            )}

            {showCommentSection ? "Comments" : titleDialog}
          </div>

          <div className="menu-wrapper">
            <StyledBookingCommentBtn
              disabled={isFetchingDocketDetail || !editBookingData?.docket}
              onClick={() => setShowCommentSection((prev) => !prev)}
            >
              <FaRegComment
                size={20}
                color={editRgbaAlpha({
                  rgbaColor: doxleThemeColor.primaryFontColor,
                  alpha:
                    isFetchingDocketDetail || !editBookingData?.docket
                      ? "0.4"
                      : "1",
                })}
              />

              <AnimatePresence>
                {(docketDetail ||
                  (isFetchingDocketDetail && editBookingData?.docket)) && (
                  <motion.span
                    className="comment-text"
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                  >
                    {isFetchingDocketDetail ? (
                      <CircularProgress
                        size={10}
                        sx={{ color: doxleThemeColor.primaryFontColor }}
                      />
                    ) : (
                      docketDetail && docketDetail.commentCount
                    )}
                  </motion.span>
                )}
              </AnimatePresence>
            </StyledBookingCommentBtn>
            <DoxleIconButton
              onClick={onClickDelete}
              iconSource={
                <DoxleDeleteIcon
                  themeColor={doxleThemeColor}
                  containerStyle={{
                    width: 20,
                  }}
                />
              }
              buttonSize={30}
              buttonWrapperStyle={{ marginLeft: "8px" }}
            />

            <DoxleIconButton
              iconSource={
                <DoxleCheckedIcon
                  themeColor={doxleThemeColor}
                  containerStyle={{
                    width: 20,
                  }}
                />
              }
              onClick={onToggleComplete}
              buttonSize={30}
              buttonWrapperStyle={{
                marginLeft: "8px",
                opacity: editBookingData?.completed ? 1 : 0.4 + "!important",
              }}
            />
          </div>
        </StyledEditBookingDialogTitle>

        <StyledEditBookingDialogContent
          variants={contentAnimateVariants}
          initial="initial"
          animate="animate"
        >
          {showCommentSection && editBookingData?.docket ? (
            <BookingDocketComment docketId={editBookingData?.docket} />
          ) : (
            <>
              <EditBookingTitleSection />

              <StyledEditDocketAndDateWrapper>
                <EditBookingDocketSection />

                <BookingDateRangePicker
                  startDate={
                    editBookingData?.startDate
                      ? new Date(editBookingData?.startDate)
                      : null
                  }
                  endDate={
                    editBookingData?.endDate
                      ? new Date(editBookingData?.endDate)
                      : null
                  }
                  onChange={onPickDate}
                />
              </StyledEditDocketAndDateWrapper>
            </>
          )}
        </StyledEditBookingDialogContent>
        <AnimatePresence>
          {!showCommentSection && (
            <StyledEditBookingDialogActions
              animate={{
                opacity: 1,
                y: [-10, 0],
                transition: {
                  delay: 0.5,
                },
              }}
              exit={{
                opacity: 0,
                y: -10,
              }}
            >
              <div className="slider-wrapper">
                <StyledBookingPercentSlider
                  step={25}
                  min={0}
                  max={100}
                  getAriaValueText={(value) => `${value}%`}
                  value={editBookingData?.percentageCompleted ?? 0}
                  onChange={(e, value) => onPercentageChange(value as number)}
                />

                <StyledBookingPercentageText
                  $percentageValue={editBookingData?.percentageCompleted ?? 0}
                >
                  {editBookingData?.percentageCompleted ?? 0}%
                </StyledBookingPercentageText>
              </div>
              <StyledEditBookingDialogButton
                $type="cancle"
                onClick={closeDialog}
              >
                Cancel
              </StyledEditBookingDialogButton>

              <StyledEditBookingDialogButton
                $type="submit"
                onClick={onClickSubmit}
              >
                Submit
              </StyledEditBookingDialogButton>
            </StyledEditBookingDialogActions>
          )}
        </AnimatePresence>
      </StyledEditBookingDialog>
    </EditBookingContext.Provider>
  );
};

export default EditBookingDialog;

export const useEditBookingContext = () =>
  useContext(EditBookingContext) as IEditBookingContextValue;
