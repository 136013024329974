import React, { useCallback, useEffect, useState } from "react";
import { Mesh } from "../svg/mesh";
import { UploadFloorPlanIcon } from "../svg/uploadFloorplanIcon";
import { StyledLinearProgress } from "./styledProgressBar";
import { useUploadFloorplanContext } from "../Pages/uploadFloorplan";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { ProgressContainer, ProgressPageContainer, StyledProgressValue, StyledUploadingTitle } from "./StyledFloorplanUploadPage";

export default function ProgressSection() {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore((state) => ({
    doxleThemeColor: state.doxleThemeColor,
    doxleFont: state.doxleFont
  }));

  const [progress, setProgress] = useState<number>(0);
  const {stage, setStage, floorplanFormData, setFloorplanFormData} = useUploadFloorplanContext();

  useEffect(() => {
    if(progress === 100){
      setTimeout(()=>{setStage(stage + 1)}, 2000);
    }
    const intervalId = setInterval(() => {
      if(progress < 100){
        setProgress(progress + 1);
      }
    }, 20);
    return () => clearInterval(intervalId);
  }, [progress]);


  return (
    <ProgressPageContainer>
      <Mesh themeColor={doxleThemeColor} />
      <ProgressContainer>
        <UploadFloorPlanIcon themeColor={doxleThemeColor} />
        <StyledUploadingTitle $themeColor={doxleThemeColor} $doxleFont={doxleFont}>Uploading Plan ....</StyledUploadingTitle>
        <StyledLinearProgress variant="determinate" value={progress} />
        <StyledProgressValue $themeColor={doxleThemeColor} $doxleFont={doxleFont}>{progress}%</StyledProgressValue>
      </ProgressContainer>
    </ProgressPageContainer>
  );
}
