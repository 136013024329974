import React, { useCallback, useLayoutEffect, useMemo, useRef } from "react";
import { useDoxleCurrentContextStore } from "../../../../DoxleGeneralStore/useDoxleCurrentContext";
import { shallow } from "zustand/shallow";
import useDoxleNotificationStore from "../../../../DoxleGeneralStore/useDoxleNotificationStore";
import { useDoxleAuthStore } from "../../../../DoxleGeneralStore/useDoxleAuthStore";
import { useDoxleThemeStore } from "../../../../DoxleGeneralStore/useDoxleThemeStore";
import DoxleEmptyPlaceHolder from "../../../../DoxleDesignPattern/DoxleEmptyPlaceHolder/DoxleEmptyPlaceHolder";
import { CircularProgress } from "@mui/material";
import {
  DoxleImageGalleryItem,
  useDoxleImageGalleryStore,
} from "../../../../DoxleDesignPattern/DoxleGallery/Store/useDoxleImageGalleryStore";

import { Discussion } from "../../../../Models/discussion";
import FilesAPI from "../../../../Services/QueryHooks/fileAndFolderQueryAPI";
import { DoxleFile } from "../../../../Models/files";
import { isEmpty } from "lodash";
import { StyledDiscussionAttachmentDisplayerContainer } from "./StyledComponentBookingDocketDetail";
import DiscussionAttachmentItem from "./DiscussionAttachmentItem";
import { useShallow } from "zustand/react/shallow";

type Props = {
  discussionItem: Discussion;
};
const DiscussionAttachmentDisplayer = ({ discussionItem }: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore((state) => ({
    doxleThemeColor: state.doxleThemeColor,
    doxleFont: state.doxleFont,
  }));
  const { setImageList, setCurrentImageIndex, setVideoUrl } =
    useDoxleImageGalleryStore(
      useShallow((state) => ({
        setImageList: state.setImageList,
        setCurrentImageIndex: state.setCurrentImageIndex,
        setVideoUrl: state.setVideoUrl,
      }))
    );

  const imageList: DoxleFile[] = useMemo(
    () =>
      discussionItem.attachments && discussionItem.attachments.length > 0
        ? discussionItem.attachments.filter((attachment) =>
            attachment.fileType.toLowerCase().includes("image")
          )
        : [],
    [discussionItem.attachments]
  );

  const fileList: DoxleFile[] = useMemo(
    () =>
      discussionItem.attachments && discussionItem.attachments.length > 0
        ? discussionItem.attachments.filter(
            (attachment) =>
              !attachment.fileType.toLowerCase().includes("image") &&
              !attachment.fileType.toLowerCase().includes("video")
          )
        : [],
    [discussionItem.attachments]
  );
  const videoList: DoxleFile[] = useMemo(
    () =>
      discussionItem.attachments && discussionItem.attachments.length > 0
        ? discussionItem.attachments.filter((attachment) =>
            attachment.fileType.toLowerCase().includes("video")
          )
        : [],
    [discussionItem.attachments]
  );
  const galleryImageList: DoxleImageGalleryItem[] = useMemo(
    () =>
      imageList.map((image) => ({
        uri: image.url,
        thumbnailUrl: image.thumbUrl,
        name: image.fileName,
        size: image.fileSize,
      })),
    [imageList]
  );
  const handleClickImage = useCallback(
    (imgIndex: number) => {
      setImageList(galleryImageList);
      setCurrentImageIndex(imgIndex);
    },
    [galleryImageList, setImageList]
  );

  const handleClickVideo = useCallback((videoUrl: string) => {
    setVideoUrl(videoUrl);
  }, []);

  return Boolean(
    !isEmpty(imageList) || !isEmpty(fileList) || !isEmpty(videoList)
  ) ? (
    <StyledDiscussionAttachmentDisplayerContainer>
      {imageList.length > 0 && (
        <div className="attachment-list-wrapper">
          {imageList.map((item, index) => (
            <DiscussionAttachmentItem
              key={item.fileId}
              item={item}
              itemIndex={index}
              handleClickImage={handleClickImage}
            />
          ))}
        </div>
      )}

      {videoList.length > 0 && (
        <div className="attachment-list-wrapper">
          {videoList.map((item, index) => (
            <DiscussionAttachmentItem
              key={item.fileId}
              item={item}
              itemIndex={index}
              handleClickVideo={handleClickVideo}
            />
          ))}
        </div>
      )}
      {fileList.length > 0 && (
        <div className="attachment-list-wrapper" style={{ marginBottom: 0 }}>
          {fileList.map((item, index) => (
            <DiscussionAttachmentItem
              key={item.fileId}
              item={item}
              itemIndex={index}
            />
          ))}
        </div>
      )}
    </StyledDiscussionAttachmentDisplayerContainer>
  ) : (
    <></>
  );
};

export default React.memo(DiscussionAttachmentDisplayer);
