/************************ Code Flow ***********************
 * SELECT THE FIRST FOLDER BY DEFAULT AND DISPLAY FILES INSIDE THAT FOLDER
 * IF THERE IS NOT FILES INSIDE THE SELECTED FOLDER, THEN SHOW FILE PLACE HOLDER
 * RIGHT SECTION IS JUST TO ADD FILES INSIDE THE FOLDER
 *
 *
 */

import Table from "@mui/material/Table";
import { TableVirtuoso, TableComponents } from "react-virtuoso";

import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import { DoxleFile } from "../../Models/files";
import {
  StyledBodyRightSection,
  StyledChooseFileButton,
  StyledFileTableRowSection,
  StyledLinearProgress,
  StyledRightFileTableHeader,
  StyledRightFileTableHeaderRow,
} from "./StyledFilesBodyComponent";

import RightSectionFileTableHeader from "./RightSectionFileTableHeader";
import Loading from "../../Utilities/Lottie/Loading";
import FilesRowRight from "./FilesRowsRight";
import React, { useMemo } from "react";
import { StyledListScroller } from "../../DoxleDesignPattern/ProjectSelectWithDropdown/StyledComponentsProjectSelectWithDropdown";
import ConfirmFolderUploadDialog from "../Components/ConfirmFolderUploadDialog";
import { editRgbaAlpha } from "../../Utilities/FunctionUtilities";
import { EmptyFileBannerIcon } from "./FileIcons";
import { motion } from "framer-motion";
import ListLoadingMore from "../../Utilities/Lottie/ListLoadingMore";
import useListViewRight from "../Hooks/useListViewRight";

interface IFileInsideFolderTableContext {}
const ListViewRight = () => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore((state) => ({
    doxleThemeColor: state.doxleThemeColor,
    doxleFont: state.doxleFont,
  }));
  const {
    filesInsideFolderList,
    filterGetFileInsideFolder,
    isFetchingFileInsideFolder,
    isSuccessFetchingFileInsideFolder,
    isFetchingNextFile,
    fetchNextFile,
    sidePeekWidth,
    isAddingFileInsideFolder,
    isDeletingFileInsideFolder,
    handleFileFolderDrop,
    pendingFolderUpload,
    handleUploadFiles,
    setPendingFolderUpload,
    currentFolder,
    currentLevel,
    sidePeek,
    getRootProps,
  } = useListViewRight();
  const TableComponents: TableComponents<
    DoxleFile,
    IFileInsideFolderTableContext
  > = useMemo(
    () => ({
      Table: (props) => <Table {...props} />,

      TableHead: React.forwardRef((props, ref) => (
        <StyledRightFileTableHeader {...props} $themeColor={doxleThemeColor} />
      )),
      TableRow: React.forwardRef((props, ref) => (
        <StyledFileTableRowSection {...props} $themeColor={doxleThemeColor} />
      )),
      Scroller: React.forwardRef((props, ref) => (
        <StyledListScroller
          style={{
            ...props.style,
          }}
          ref={ref}
          {...props}
        />
      )),
    }),
    [doxleThemeColor]
  );
  return (
    <motion.div
      style={{
        height: "100%",
        position: "relative",
        flex: 1,
        padding: "0px 8px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      onDrop={handleFileFolderDrop}
      initial={{ x: 100, opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      transition={{ duration: 0.4 }}
    >
      <ConfirmFolderUploadDialog
        folder={pendingFolderUpload}
        setFolder={setPendingFolderUpload}
        uploadFn={handleUploadFiles}
      />
      {currentFolder && (
        <>
          {Boolean(
            isFetchingFileInsideFolder ||
              isAddingFileInsideFolder ||
              isDeletingFileInsideFolder
          ) && (
            <Loading
              text={"Loading ..."}
              animationSize={200}
              textStyle={{
                color: editRgbaAlpha({
                  rgbaColor: doxleThemeColor.primaryFontColor,
                  alpha: "0.4",
                }),
                fontSize: 16,
              }}
              containerStyle={{
                width: "100%",
                position: "absolute",
                paddingBottom: 50,
                height: "calc(100% - 50px)",
                top: 0,
                left: 0,
              }}
            />
          )}

          {isSuccessFetchingFileInsideFolder && (
            <>
              {Boolean(
                !isAddingFileInsideFolder && !isDeletingFileInsideFolder
              ) && (
                <>
                  {filesInsideFolderList.length > 0 ? (
                    <StyledBodyRightSection
                      $topPadding={currentLevel !== "Company"}
                      $themeColor={doxleThemeColor}
                      $sidePeek={false}
                      $sidePeekWidth={sidePeekWidth}
                    >
                      <TableVirtuoso
                        data={filesInsideFolderList}
                        style={{
                          width: "100%",
                          height: "100%",
                        }}
                        components={TableComponents}
                        endReached={fetchNextFile}
                        fixedHeaderContent={() => {
                          return (
                            <StyledRightFileTableHeaderRow
                              $themeColor={doxleThemeColor}
                            >
                              <RightSectionFileTableHeader />
                            </StyledRightFileTableHeaderRow>
                          );
                        }}
                        itemContent={(index, item: DoxleFile) => (
                          <FilesRowRight
                            key={item.fileId}
                            file={item as DoxleFile}
                          />
                        )}
                      />

                      {isFetchingNextFile && (
                        <ListLoadingMore
                          containerStyle={{
                            position: "absolute",
                            bottom: 0,
                            left: 0,
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            zIndex: 20,
                          }}
                          animationSize={120}
                        />
                      )}
                    </StyledBodyRightSection>
                  ) : (
                    <StyledBodyRightSection
                      $topPadding={currentLevel !== "Company"}
                      $themeColor={doxleThemeColor}
                      $sidePeek={Boolean(currentLevel === "Docket" && sidePeek)}
                      $sidePeekWidth={sidePeekWidth}
                    >
                      <EmptyFileBannerIcon
                        themeColor={doxleThemeColor}
                        containerStyle={{
                          width: "40%",
                          maxWidth: 250,
                          minWidth: 100,
                        }}
                      />
                      <StyledChooseFileButton
                        {...getRootProps({
                          onClick: () => {},
                          $themeColor: doxleThemeColor,
                          $doxleFont: doxleFont,
                        })}
                      >
                        Add File
                      </StyledChooseFileButton>
                    </StyledBodyRightSection>
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
      {!currentFolder &&
        (Boolean(isAddingFileInsideFolder || isDeletingFileInsideFolder) ? (
          <Loading
            text={"Loading ..."}
            animationSize={200}
            textStyle={{
              color: editRgbaAlpha({
                rgbaColor: doxleThemeColor.primaryFontColor,
                alpha: "0.4",
              }),
              fontSize: 16,
            }}
            containerStyle={{
              width: "100%",
              position: "relative",
              paddingBottom: 50,
              height: "calc(100% - 50px)",
            }}
          />
        ) : (
          <StyledBodyRightSection
            $topPadding={currentLevel !== "Company"}
            $themeColor={doxleThemeColor}
            $sidePeek={Boolean(currentLevel === "Docket" && sidePeek)}
            $sidePeekWidth={sidePeekWidth}
          >
            <EmptyFileBannerIcon
              themeColor={doxleThemeColor}
              containerStyle={{
                width: "40%",
                maxWidth: 250,
              }}
            />
            <StyledChooseFileButton
              {...getRootProps({
                onClick: () => {},
                $themeColor: doxleThemeColor,
                $doxleFont: doxleFont,
                $isRootFolder: !currentFolder,
              })}
            >
              Add File
            </StyledChooseFileButton>
          </StyledBodyRightSection>
        ))}
    </motion.div>
  );
};

export default ListViewRight;
