import React from "react";
import { useDrawingStore } from "../Store/useDrawingStore";
import { shallow } from "zustand/shallow";

type Props = {};

interface SheetsHeader {
  handleToggleCheckboxSelectAll: (value: boolean) => void;
  isCheckboxTotalChecked: boolean;
}

const useSheetsHeader = (props: Props): SheetsHeader => {
  const {
    handleSelectAllSheetIds,
    handleClearAllSelectedSheetIds,
    selectedSheetIds,
    viewedDrawingSet,
  } = useDrawingStore(
    (state) => ({
      handleSelectAllSheetIds: state.handleSelectAllSheetIds,
      handleClearAllSelectedSheetIds: state.handleClearAllSelectedSheetIds,
      selectedSheetIds: state.selectedSheetIds,
      viewedDrawingSet: state.viewedDrawingSet,
    }),
    shallow
  );

  const handleToggleCheckboxSelectAll = (value: boolean) => {
    if (value) {
      if (viewedDrawingSet)
        handleSelectAllSheetIds(
          viewedDrawingSet.sheets.map((sheet) => sheet.sheetId!)
        );
    } else handleClearAllSelectedSheetIds();
  };

  const isCheckboxTotalChecked = Boolean(
    viewedDrawingSet &&
      viewedDrawingSet.sheets.length === selectedSheetIds.length
  );
  return {
    handleToggleCheckboxSelectAll,
    isCheckboxTotalChecked,
  };
};

export default useSheetsHeader;
