import { motion } from "framer-motion";
import {
  StyledAccountDropDownWrapper,
  StyledAccountItemWrapper,
  StyledAccountListScroller,
  StyledEditFunctionFieldLabel,
  StyledLinkButton,
  StyledLinkDocketSection,
  StyledLinkedFieldDisplay,
} from "./StyledComponentDocketInfo";
import { useEditDocketSideScreenStore } from "../../EditDocketSideScreenStore/useEditDocketSideScreenStore";
import { shallow } from "zustand/shallow";
import useLinkDocketSection from "./Hooks/useLinkDocketSection";

import {
  EmptyAccountListBanner,
  XeroLinkedIcon,
  XeroUnlinkIcon,
} from "../../EditDocketSideScreenIcons";
import { useDoxleThemeStore } from "../../../../DoxleGeneralStore/useDoxleThemeStore";
import { BiErrorCircle } from "react-icons/bi";
import { IoChevronDownOutline } from "react-icons/io5";
import useGetAccounts from "../../../../Xero/Hooks/useGetAccounts";
import LinkingLoader from "../../../../Utilities/Lottie/LinkingLoader";
import Tooltip from "@mui/material/Tooltip";
import { DoxleMUITooltipSx } from "../../../../DoxleDesignPattern/MUIOverwriteSx";
import CircularProgress from "@mui/material/CircularProgress";
import Popover from "@mui/material/Popover";
import React, { useMemo } from "react";
import { TransitionProps } from "@mui/material/transitions";
import Grow from "@mui/material/Grow";
import { Components, Virtuoso } from "react-virtuoso";
import { Account } from "../../../../Xero/Models/account";
import DoxleEmptyPlaceHolder from "../../../../DoxleDesignPattern/DoxleEmptyPlaceHolder/DoxleEmptyPlaceHolder";
import { Error404Banner } from "../../../../DoxleDesignPattern/DoxleIcons/CommonIcons";
type Props = {};
interface IAccountListContext {
  isErrorFetchingAccountList: boolean;
}
const LinkDocketSection = (props: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }),
    shallow
  );
  const { edittedDocket } = useEditDocketSideScreenStore(
    (state) => ({
      edittedDocket: state.edittedDocket,
    }),
    shallow
  );
  const { isFetchingAccountList, isErrorFetchingAccountList, accountsList } =
    useGetAccounts({});
  const {
    isLinked,
    linkedItem,
    handleUnlinkDocket,
    handleLinkDocket,
    isProcessLinking,
    anchorAccountList,
    setAnchorAccountList,
    setSelectedAccount,
    selectedAccount,
  } = useLinkDocketSection({});
  const canBeOpen = Boolean(anchorAccountList);
  const id = canBeOpen ? "account-link-menu-popper" : undefined;

  const components: Components<Account, IAccountListContext> = useMemo(
    () => ({
      Scroller: React.forwardRef((props, ref) => (
        <StyledAccountListScroller
          style={{ ...props.style }}
          ref={ref}
          {...props}
        />
      )),

      EmptyPlaceholder: (props) => (
        <DoxleEmptyPlaceHolder
          headTitleText={
            props.context?.isErrorFetchingAccountList
              ? "Something wrong"
              : "No Items found"
          }
          subTitleText={
            props.context?.isErrorFetchingAccountList
              ? "Please try to refresh page, we are sorry for this!"
              : "Add more accounts in Xero to link your budgets..."
          }
          containerBgColor="transparent"
          headTitleTextStyle={{
            fontSize: "20px",
          }}
          subTitleTextStyle={{
            fontSize: "14px",
          }}
          containerStyle={{
            width: "calc(100% - 16px)",
            height: "calc(100% - 16px)",
            padding: 8,
          }}
          illustration={
            props.context?.isErrorFetchingAccountList ? (
              <Error404Banner
                themeColor={doxleThemeColor}
                containerStyle={{
                  width: "60%",
                  marginBottom: 14,
                  maxWidth: 200,
                }}
              />
            ) : (
              <EmptyAccountListBanner
                themeColor={doxleThemeColor}
                containerStyle={{
                  width: "60%",
                  marginBottom: 14,
                  maxWidth: 200,
                }}
              />
            )
          }
        />
      ),
    }),
    [doxleThemeColor]
  );
  return (
    <StyledLinkDocketSection>
      <motion.div className="link-field-wrapper">
        <StyledEditFunctionFieldLabel>
          Doxle Docket
        </StyledEditFunctionFieldLabel>

        <StyledLinkedFieldDisplay style={{ opacity: 0.5 }}>
          {edittedDocket?.docketName}
        </StyledLinkedFieldDisplay>
      </motion.div>

      {isFetchingAccountList && (
        <LinkingLoader
          containerStyle={{
            width: "fit-content",
            height: "fit-content",
          }}
        />
      )}

      {!isFetchingAccountList && (
        <>
          {isErrorFetchingAccountList && (
            <Tooltip
              PopperProps={{
                disablePortal: true,
                sx: DoxleMUITooltipSx(doxleThemeColor, doxleFont),
              }}
              title={
                "Unable to get linked accounts, you may need to link your project first!"
              }
            >
              <div style={{ cursor: "pointer", marginLeft: 20 }}>
                <BiErrorCircle color="red" size={30} />
              </div>
            </Tooltip>
          )}

          {!isErrorFetchingAccountList && (
            <>
              <StyledLinkButton
                $isLink={isLinked}
                onClick={() => {
                  if (isLinked) handleUnlinkDocket();
                  else handleLinkDocket();
                }}
              >
                {isProcessLinking ? (
                  <CircularProgress size={20} color="inherit" />
                ) : isLinked ? (
                  <XeroLinkedIcon themeColor={doxleThemeColor} />
                ) : (
                  <XeroUnlinkIcon themeColor={doxleThemeColor} />
                )}

                {isProcessLinking
                  ? "Processing..."
                  : isLinked
                  ? "Linked"
                  : "No Link"}
              </StyledLinkButton>

              <motion.div className="link-field-wrapper">
                <StyledEditFunctionFieldLabel>
                  Xero Account
                </StyledEditFunctionFieldLabel>

                <StyledLinkedFieldDisplay
                  onClick={(e) => setAnchorAccountList(e.currentTarget)}
                >
                  {selectedAccount
                    ? selectedAccount.accountName
                    : linkedItem
                    ? linkedItem.accountName
                    : "Select an account"}
                  <IoChevronDownOutline
                    className="icon-dropdown"
                    color={doxleThemeColor.primaryFontColor}
                    size={14}
                  />
                </StyledLinkedFieldDisplay>
              </motion.div>
            </>
          )}
        </>
      )}

      <Popover
        id={id}
        open={canBeOpen}
        anchorEl={anchorAccountList}
        TransitionComponent={Transition}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        onClose={() => setAnchorAccountList(null)}
        sx={{
          "& .MuiPopover-paper": {
            backgroundColor: "transparent",
            overflow: "visible",
            boxShadow: "none",
          },
        }}
        transitionDuration={{ appear: 200, exit: 200 }}
        elevation={4}
      >
        <StyledAccountDropDownWrapper>
          <Virtuoso
            style={{ height: "100%", width: "100%" }}
            data={accountsList}
            components={components}
            itemContent={(index, account) => (
              <StyledAccountItemWrapper
                key={account.accountId}
                onClick={() => setSelectedAccount(account)}
              >
                {account.accountName}
              </StyledAccountItemWrapper>
            )}
            context={{ isErrorFetchingAccountList }}
          />
        </StyledAccountDropDownWrapper>
      </Popover>
    </StyledLinkDocketSection>
  );
};

export default LinkDocketSection;
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Grow ref={ref} {...props} />;
});
