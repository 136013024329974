import React, { useEffect, useState } from "react";
import { shallow } from "zustand/shallow";
import { useDoxleAuthStore } from "../../../../../DoxleGeneralStore/useDoxleAuthStore";
import { useDoxleCurrentContextStore } from "../../../../../DoxleGeneralStore/useDoxleCurrentContext";
import useDoxleNotificationStore from "../../../../../DoxleGeneralStore/useDoxleNotificationStore";
import { BookingQueryAPI } from "../../../../QueryAPI/bookingQueryAPI";
import { IntRange } from "../../../../../Models/IntRangeType";
import useBookingDocketStore from "../../../../store/useBookingDocketStore";

type Props = {};

interface EditBookingPercentage {
  newPercentageComplete: IntRange<0, 101>;
  setNewPercentageComplete: React.Dispatch<
    React.SetStateAction<IntRange<0, 101>>
  >;
}
const useEditBookingPercentage = (props: Props): EditBookingPercentage => {
  const { filterBookingDocketQuery, edittedBooking } = useBookingDocketStore(
    (state) => ({
      filterBookingDocketQuery: state.filterBookingDocketQuery,
      edittedBooking: state.edittedBooking,
    }),
    shallow
  );
  const [newPercentageComplete, setNewPercentageComplete] = useState<
    IntRange<0, 101>
  >(edittedBooking?.percentageCompleted ?? 0);
  const { company } = useDoxleCurrentContextStore(
    (state) => ({
      company: state.currentCompany,
    }),
    shallow
  );
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );
  const { update } = BookingQueryAPI.useMutateBookingQuery({
    company,

    showNotification,
    filter: filterBookingDocketQuery,
  });

  // useEffect(() => {
  //   const timeout = setTimeout(() => {
  //     if (edittedBooking) {
  //       update.mutate({
  //         bookingId: edittedBooking.bookingId,
  //         updateData: {
  //           percentageCompleted: newPercentageComplete,
  //         },
  //       });
  //     }
  //   }, 300);

  //   return () => {
  //     clearTimeout(timeout);
  //   };
  // }, [newPercentageComplete, edittedBooking]);

  return { newPercentageComplete, setNewPercentageComplete };
};

export default useEditBookingPercentage;
