import FileBody from "./FileBody";
import FileHeader from "./FileHeader";
import { StyledFilesContainer } from "./StyledFilesHeaderComponent";
import { FileLevel } from "../Store/useFileStore";
import AddFilePopover from "./AddFilePopover";
import { Helmet } from "react-helmet";
import useFiles from "../Hooks/useFiles";

type Props = {
  level?: FileLevel;
};

const Files = ({ level = "Company" }: Props) => {
  const {} = useFiles(level);

  return (
    <StyledFilesContainer>
      <Helmet>
        <title>Doxle - Files</title>
      </Helmet>
      <div className="file-content-wrapper">
        {level === "Company" && <FileHeader />}
        <FileBody />
      </div>

      {level !== "Company" && <AddFilePopover />}
    </StyledFilesContainer>
  );
};

export default Files;
