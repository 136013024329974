import React from "react";
import { useQueryClient } from "@tanstack/react-query";

import { produce } from "immer";
import { ContactsFilters } from "../../../Services/QueryHooks/contactsFilters";
import { Contact } from "../../../Models/addressBook";
import { useDoxleCurrentContextStore } from "../../../DoxleGeneralStore/useDoxleCurrentContext";
import { formContactListQKey } from "../../../Services/QueryHooks/contactsAPI";

type Props = {
  filter: ContactsFilters;
  addPos?: "start" | "end";
  overwrite?: boolean;
};

interface SetContactsQueryData {
  handleAddNewContact: (addedContact: Contact) => void;
  handleDeleteContactQueryData: (deletedContactId: string) => void;
  handleEditContactQueryData: (edittedContact: Contact) => void;
}
const useSetContactsQueryData = ({
  filter,
  addPos = "start",
  overwrite = true,
}: Props): SetContactsQueryData => {
  const queryClient = useQueryClient();
  const { company } = useDoxleCurrentContextStore((state) => ({
    company: state.currentCompany,
  }));
  const qKey = formContactListQKey(filter, company);
  const handleAddNewContact = (addedContact: Contact) => {
    const queryData = queryClient.getQueryData(qKey);
    if (queryData)
      queryClient.setQueryData(qKey, (old: any) => {
        const pageLength = old.pages.length;

        return produce(old, (draftOld: any) => {
          if (addPos === "start")
            (draftOld.pages[0].data.results as any[]).unshift(addedContact);
          else draftOld.pages[pageLength - 1].data.results.push(addedContact);
        });
      });
    else queryClient.invalidateQueries(qKey);
  };

  const handleDeleteContactQueryData = (deletedContactId: string) => {
    const oldServerData = queryClient.getQueryData(qKey);
    if (oldServerData && overwrite) {
      queryClient.setQueryData(qKey, (old: any) => {
        //find page contain deleted item
        let pageContainGroup: number = (old.pages as Array<any>).findIndex(
          (page) =>
            (page.data.results as Contact[]).find(
              (contact) => contact.contactId === deletedContactId
            )
        );
        if (pageContainGroup !== -1) {
          const targetOrderItemIndex = (
            (old.pages as Array<any>)[pageContainGroup].data
              .results as Contact[]
          ).findIndex((contact) => contact.contactId === deletedContactId);
          return produce(old, (draftOld: any) => {
            draftOld.pages = produce(draftOld.pages, (draftPages: any) => {
              draftPages[pageContainGroup].data.results = produce(
                draftPages[pageContainGroup].data.results,
                (draftTargetPageData: Contact[]) => {
                  draftTargetPageData.splice(targetOrderItemIndex, 1);

                  return draftTargetPageData;
                }
              );
              return draftPages;
            });

            return draftOld;
          });
        } else queryClient.invalidateQueries(qKey);
      });
    } else queryClient.invalidateQueries(qKey);
  };

  const handleEditContactQueryData = (edittedContact: Contact) => {
    const oldServerData = queryClient.getQueryData(qKey);

    if (oldServerData && overwrite) {
      queryClient.setQueryData(qKey, (old: any) => {
        //find page contain deleted item
        let pageContainGroup: number = (old.pages as Array<any>).findIndex(
          (page) =>
            (page.data.results as Contact[]).find(
              (contact) => contact.contactId === edittedContact.contactId
            )
        );
        if (pageContainGroup !== -1) {
          const targetOrderItemIndex = (
            (old.pages as Array<any>)[pageContainGroup].data
              .results as Contact[]
          ).findIndex(
            (contact) => contact.contactId === edittedContact.contactId
          );
          return produce(old, (draftOld: any) => {
            draftOld.pages = produce(draftOld.pages, (draftPages: any) => {
              draftPages[pageContainGroup].data.results = produce(
                draftPages[pageContainGroup].data.results,
                (draftTargetPageData: Contact[]) => {
                  const item = draftTargetPageData[targetOrderItemIndex];
                  if (item) {
                    Object.assign(item, edittedContact);
                  }

                  return draftTargetPageData;
                }
              );
              return draftPages;
            });

            return draftOld;
          });
        } else queryClient.invalidateQueries(qKey);
      });
    } else queryClient.invalidateQueries(qKey);
  };
  return {
    handleAddNewContact,
    handleDeleteContactQueryData,
    handleEditContactQueryData,
  };
};

export default useSetContactsQueryData;
