import React from "react";
import {
  StyledQuoteReviewFieldWrapper,
  StyledQuoteSupplierContainer,
  StyledSelectedSupplierContainer,
  StyledSupplierSelectSection,
} from "./StyledComponentReviewQuote";
import { StyledQuoteDetailLabelText } from "../QuoteRequestStyledComponents";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { useReviewQuoteRequestContext } from "./ReviewQuoteRequest";
import DoxleFilterTag from "../../../DoxleDesignPattern/DoxleFilterTag/DoxleFilterTag";
import { Contact } from "../../../Models/addressBook";
import { produce } from "immer";
import SelectQuoteRequestContractor from "./SelectQuoteRequestContractor";
import SelectPrivateContractor from "./SelectPrivateContractor";
import { editRgbaAlpha } from "../../../Utilities/FunctionUtilities";

type Props = {};

const EditSupplierQuoteRequest = (props: Props) => {
  const { doxleThemeColor, doxleFont, currentTheme } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
      currentTheme: state.currentTheme,
    })
  );
  const { edittedQuoteRequest, setEdittedQuoteRequest } =
    useReviewQuoteRequestContext();
  const handleRemoveSupplierContact = (item: Contact) =>
    setEdittedQuoteRequest((prev) =>
      produce(prev, (draft) => {
        draft.supplierContacts = draft.supplierContacts.filter(
          (supplier) => supplier !== item.contactId
        );
        if (draft.supplierContactsJson)
          draft.supplierContactsJson = draft.supplierContactsJson.filter(
            (supplier) => supplier.contactId !== item.contactId
          );

        return draft;
      })
    );
  const handleRemoveSupplierCompany = () =>
    setEdittedQuoteRequest((prev) =>
      produce(prev, (draft) => {
        draft.supplier = null;
        draft.supplierName = "";
        return draft;
      })
    );
  return (
    <StyledQuoteReviewFieldWrapper>
      <StyledQuoteSupplierContainer $marginBottom={0} $gap={68} $marginTop={0}>
        <StyledQuoteDetailLabelText
          $themeColor={doxleThemeColor}
          $doxleFont={doxleFont}
          $opacity={0.7}
        >
          INCLUDED SUPPLIERS
        </StyledQuoteDetailLabelText>

        <StyledSelectedSupplierContainer>
          {/* {edittedQuoteRequest.supplierContactsJson &&
            edittedQuoteRequest.supplierContactsJson?.map((contact) => (
              <DoxleFilterTag
                itemData={contact}
                key={contact.contactId}
                itemDisplayText={`${contact.firstName} ${contact.lastName}`}
                removeItemFunction={handleRemoveSupplierContact}
              />
            ))} */}

          {edittedQuoteRequest.supplier && (
            <DoxleFilterTag
              itemData={edittedQuoteRequest.supplier}
              tagStyle={{
                alignItems: "flex-start",
                flexDirection: "column",
                height: "fit-content",
              }}
              itemDisplayText={edittedQuoteRequest.supplierName}
              extraContent={
                <div
                  style={{
                    color: editRgbaAlpha({
                      rgbaColor: doxleThemeColor.primaryFontColor,
                      alpha: "0.5",
                    }),
                  }}
                >
                  {edittedQuoteRequest.supplierContactsJson?.[0].email}
                </div>
              }
              // removeItemFunction={handleRemoveSupplierCompany}

              key={edittedQuoteRequest.supplier}
            />
          )}
        </StyledSelectedSupplierContainer>
      </StyledQuoteSupplierContainer>

      {/* <StyledSupplierSelectSection layout>
        <StyledQuoteReviewFieldWrapper
          style={{
            marginRight: "10%",
            width: "30%",
            marginBottom: 0,
            maxWidth: "40rem",
          }}
        >
          <StyledQuoteDetailLabelText
            $themeColor={doxleThemeColor}
            $doxleFont={doxleFont}
            $opacity={0.7}
          >
            Company Contractor
          </StyledQuoteDetailLabelText>

          <SelectQuoteRequestContractor />
        </StyledQuoteReviewFieldWrapper>

        <StyledQuoteReviewFieldWrapper
          style={{ width: "40%", marginBottom: 0 }}
        >
          <StyledQuoteDetailLabelText
            $themeColor={doxleThemeColor}
            $doxleFont={doxleFont}
            $opacity={0.7}
          >
            Private Contractor
          </StyledQuoteDetailLabelText>
          <SelectPrivateContractor />
        </StyledQuoteReviewFieldWrapper>
      </StyledSupplierSelectSection> */}
    </StyledQuoteReviewFieldWrapper>
  );
};

export default EditSupplierQuoteRequest;
