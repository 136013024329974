import { shallow } from "zustand/shallow";
import useGetAccounts from "../../../../../Xero/Hooks/useGetAccounts";
import { Account } from "../../../../../Xero/Models/account";
import { useEditDocketSideScreenStore } from "../../../EditDocketSideScreenStore/useEditDocketSideScreenStore";
import { Dispatch, SetStateAction, useMemo, useState } from "react";
import useDoxleNotificationStore from "../../../../../DoxleGeneralStore/useDoxleNotificationStore";
import { useDoxleCurrentContextStore } from "../../../../../DoxleGeneralStore/useDoxleCurrentContext";
import DocketQuery from "../../../../../Services/QueryHooks/docketQueryAPI";
import useSetDocketDetailQueryData from "../../../../QueryDataHooks/SetQueryDataHooks/useSetDocketDetailQueryData";
import { Docket } from "../../../../../Models/dockets";
import useEffectAfterMount from "../../../../UtilityHooks/useEffectAfterMount";

type Props = {};
interface AccountWithScore extends Account {
  score: number;
}
interface ILinkDocketSection {
  linkedItem: Account | undefined;
  isLinked: boolean;
  handleUnlinkDocket: () => void;
  handleLinkDocket: () => void;
  isProcessLinking: boolean;
  anchorAccountList: HTMLDivElement | null;
  setAnchorAccountList: Dispatch<SetStateAction<HTMLDivElement | null>>;
  setSelectedAccount: Dispatch<SetStateAction<Account | undefined>>;
  selectedAccount: Account | undefined;
}
const useLinkDocketSection = (props: Props): ILinkDocketSection => {
  const [anchorAccountList, setAnchorAccountList] =
    useState<HTMLDivElement | null>(null);
  const [selectedAccount, setSelectedAccount] = useState<Account | undefined>(
    undefined
  );
  const { accountsList } = useGetAccounts({});
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );
  const { company, project } = useDoxleCurrentContextStore(
    (state) => ({
      company: state.currentCompany,
      project: state.currentProject,
    }),
    shallow
  );

  const {
    edittedDocket,

    filterDocketListQuery,
    handlePostUpdateDocket,
  } = useEditDocketSideScreenStore(
    (state) => ({
      edittedDocket: state.edittedDocket,

      filterDocketListQuery: state.filterDocketListQuery,
      handlePostUpdateDocket: state.handlePostUpdateDocket,
    }),
    shallow
  );
  const { handleUpdateDocket } = useSetDocketDetailQueryData({
    docketPk: edittedDocket?.docketPk ?? "",
  });
  const onSuccessUpdate = (newDocket?: Docket) => {
    if (newDocket) {
      if (handlePostUpdateDocket) handlePostUpdateDocket(newDocket);

      handleUpdateDocket(newDocket);
    }
  };
  const updateDocketQuery = DocketQuery.useUpdateDocketQuery({
    showNotification,
    company,
    filter: filterDocketListQuery,
    onSuccessCb: onSuccessUpdate,
  });
  const handleUnlinkDocket = () => {
    if (edittedDocket)
      updateDocketQuery.mutate({
        docketPk: edittedDocket.docketPk,
        accountTrackingId: null,
        accountCode: null,
      });
  };
  const handleLinkDocket = () => {
    if (!selectedAccount)
      showNotification("Please select an account", "error", "", 2000);
    else if (edittedDocket && selectedAccount)
      updateDocketQuery.mutate({
        docketPk: edittedDocket.docketPk,
        accountTrackingId: selectedAccount.accountId,
        accountCode: selectedAccount.accountCode,
      });
  };
  const linkedItem: Account | undefined = accountsList.find(
    (data) => data.accountId === edittedDocket?.accountTrackingId
  );
  const isLinked = Boolean(linkedItem);

  const suggestedAccount = useMemo(() => {
    const accountsListWithScore: AccountWithScore[] = accountsList
      .map((account) => {
        const accountNameWords = account.accountName.toLowerCase().split(" ");
        const score = accountNameWords.filter(
          (word) =>
            edittedDocket?.docketName.toLowerCase().includes(word) ||
            String(edittedDocket?.docketIdNum).toLowerCase().includes(word)
        ).length;
        return { ...account, score };
      })
      .sort((a, b) => (a.score < b.score ? -1 : a.score > b.score ? 1 : 0));
    const topAccount: AccountWithScore = accountsListWithScore[0];
    if (topAccount && topAccount.score > 0) {
      return topAccount;
    } else return undefined;
  }, [accountsList, edittedDocket]);

  useEffectAfterMount(() => {
    if (!isLinked) {
      if (suggestedAccount) setSelectedAccount(suggestedAccount);
    }
  }, [isLinked, suggestedAccount]);
  return {
    linkedItem,
    isLinked,
    handleUnlinkDocket,
    handleLinkDocket,
    isProcessLinking: updateDocketQuery.isLoading,
    anchorAccountList,
    setAnchorAccountList,
    setSelectedAccount,
    selectedAccount,
  };
};

export default useLinkDocketSection;
