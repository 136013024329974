import React, { useEffect, useState } from "react";
import { DrawingSet, Sheet } from "../Models/drawings";
import { useProjectStore } from "../../DoxleGeneralStore/useprojectStore";
import { shallow } from "zustand/shallow";

type Props = { drawingSet: DrawingSet };

interface SheetsHook {
  showAddSheetDialog: boolean;
  setShowAddSheetDialog: React.Dispatch<React.SetStateAction<boolean>>;
}
const useSheets = ({ drawingSet }: Props): SheetsHook => {
  const [showAddSheetDialog, setShowAddSheetDialog] = useState(false);
  const { setFunctionNewButton } = useProjectStore(
    (state) => ({ setFunctionNewButton: state.setFunctionNewButton }),
    shallow
  );

  useEffect(() => {
    setFunctionNewButton([]);
  }, []);

  return { setShowAddSheetDialog, showAddSheetDialog };
};

export default useSheets;
