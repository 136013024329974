import { useDoxleThemeStore } from "../../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import React, {
  MutableRefObject,
  RefObject,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  RootInvoiceTabPanelDisplayer,
  StyledAddedFileDisplaySection,
  StyledDroppedFileItem,
  StyledDroppedFileItemFileInfo,
  StyledDroppedFileItemTextSpan,
  StyledInvoiceTabHeadTitle,
  StyledInvoiceTabSubTitle,
  StyledPaymentInputContainer,
  StyledPurchaseOrderInput,
  StyledAttachedHeaderText,
  StyledPaymentClaimTextArea,
  StyledPaymentStatusSelect,
} from "../StyledPaymentClaimComponents";

import ClickAwayListener from "@mui/material/ClickAwayListener";
import Popper from "@mui/material/Popper";
import Fade from "@mui/material/Fade";
import Stack from "@mui/material/Stack";

import FormControl from "@mui/material/FormControl";
import { InvoicePDFIcon } from "../Icons";
import { useDoxleAuthStore } from "../../../../DoxleGeneralStore/useDoxleAuthStore";
import { useDoxleCurrentContextStore } from "../../../../DoxleGeneralStore/useDoxleCurrentContext";
import useDoxleNotificationStore from "../../../../DoxleGeneralStore/useDoxleNotificationStore";
import {
  dotDateRegex,
  formatDate,
  formatter,
  ISODateRegex,
  slashDateRegex,
  toNum,
} from "../../../../Utilities/FunctionUtilities";
import DoxleCloseBtn from "../../../../DoxleDesignPattern/DoxleButtons/DoxleCloseBtn";
import PaymentClaim, {
  PaymentClaimBackCharge,
} from "../../../Models/PaymentClaim";
import { useEditDocketSideScreenStore } from "../../../../CoreContent/EditDocketSideScreen/EditDocketSideScreenStore/useEditDocketSideScreenStore";
import PaymentClaimAPI from "../../../QueryHooks/PaymentClaimAPI";
import Order from "../../../Models/order";
import Loading from "../../../../Utilities/Lottie/Loading";
import dayjs, { Dayjs } from "dayjs";
import { StyledDoxleDialogHelperEditButton } from "../../../../DoxleDesignPattern/DoxleDialogHelper/StyledComponentDoxleDialogHelper";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  StyledDateCalendar,
  StyledOrderTobButtonContainer,
  StyledPOGeneralInfoText,
} from "../../EditOrderSideScreen/StyledComponents";
import { TISODate } from "../../../../Models/dateFormat";
import { usePaymentClaimStore } from "../../../OrdersStore/usePaymentClaimStore";
import {
  StyledPaymentClaimFieldWrapper,
  StyledPaymentClaimNoteTextField,
  StyledReivewPaymentStatusSelect,
  StyledReviewPaymentClaimContainer,
  StyledReviewPaymentDateCalendar,
  StyledReviewPaymentSubtitle,
  StyledReviewPaymentTextField,
  StyledReviewPaymentTitle,
  StyledStatusFieldLabel,
  StyledStatusMenuItem,
} from "./StyledComponentReviewPaymentClaim";
import DoxleNavigateBackButton from "../../../../DoxleDesignPattern/DoxleButtons/DoxleNavigateBackButton";
import { IoChevronBackSharp } from "react-icons/io5";
import { SelectChangeEvent } from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";

const allowedKeys = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "0",
  ".",
  "ArrowLeft",
  "ArrowRight",
  "Tab",
  "Delete",
  "Backspace",
];

interface props {
  claim: PaymentClaim;
}

type PaymentClaimStatusType = "Approved" | "Rejected" | "Pending";
interface PaymentClaimStatusOption {
  value: PaymentClaimStatusType;
  color: `rgba(${number},${number},${number},${number})`;
}

const PAYMENT_CLAIM_STATUS_OPTIONS: PaymentClaimStatusOption[] = [
  {
    value: "Pending",
    color: "rgba(255,255,0,1)",
  },
  {
    value: "Rejected",
    color: "rgba(255,0,0,1)",
  },
  {
    value: "Approved",
    color: "rgba(0,255,0,1)",
  },
];

const ReviewPaymentClaim = ({ claim }: props) => {
  const [statusValue, setStatusValue] = useState<PaymentClaimStatusOption>(
    PAYMENT_CLAIM_STATUS_OPTIONS.filter((opt) => {
      const initialValue =
        claim.status === "AUTHORISED"
          ? "Approved"
          : claim.status === "SUBMITTED"
          ? "Pending"
          : "Rejected";
      return opt.value === initialValue;
    })[0]
  );
  const [displayDatePicker, setDisplayDatePicker] = useState<boolean>(false);
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }),
    shallow
  );
  const { setAction, backCharges, setClaim } = usePaymentClaimStore(
    (state) => ({
      setAction: state.setAction,
      backCharges: state.backCharges,
      setClaim: state.setReviewPaymentClaim,
    }),
    shallow
  );

  const company = useDoxleCurrentContextStore(
    (state) => state.currentCompany,
    shallow
  );
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );
  const docket = useEditDocketSideScreenStore(
    (state) => state.edittedDocket,
    shallow
  );
  const amountParentRef: MutableRefObject<any> = useRef();
  const amountRef: MutableRefObject<undefined | HTMLInputElement> = useRef();
  const invNumParentRef: MutableRefObject<any> = useRef();
  const invNumRef: MutableRefObject<undefined | HTMLInputElement> = useRef();
  const statusParentRef: MutableRefObject<any> = useRef();
  const statusRef: MutableRefObject<undefined | HTMLInputElement> = useRef();
  // const paymentDateParentRef: MutableRefObject<any> = useRef();
  let paymentDateRef: RefObject<HTMLInputElement | undefined> = useRef();
  const notesParentRef: MutableRefObject<any> = useRef();
  const notesRef: MutableRefObject<undefined | HTMLInputElement> = useRef();

  const paymentClaimQuery = PaymentClaimAPI.useRetrieveDetail({
    company,
    showNotification,
    paymentId: claim.paymentId,
  });

  const paymentClaimMutateQuery = PaymentClaimAPI.useMutateQuery({
    company,
    showNotification,
    filter: {},
  });

  interface PaymentDetailDatail {
    paymentClaim: PaymentClaim | undefined;
    order: Order | undefined;
    uniqueInvoiceNumber: boolean;
  }
  const { paymentClaim, order, uniqueInvoiceNumber } = (paymentClaimQuery?.data
    ?.data ?? {
    paymentClaim: undefined,
    order: undefined,
    uniqueInvoiceNumber: true,
  }) as PaymentDetailDatail;

  const invoiceAmountHelperText = useMemo(() => {
    if (toNum(paymentClaim?.total ?? claim.total) > toNum(order?.total)) {
      console.log("paymentClaim?.total", toNum(paymentClaim?.total));
      console.log("claim.total", toNum(claim.total));
      console.log("order?.total", toNum(order?.total));
      return "Invoice exceeds order";
    }
    if (
      toNum(paymentClaim?.total ?? claim.total) +
        toNum(order?.totalApprovedPayments) >
      toNum(order?.total)
    ) {
      console.log("paymentClaim?.total", toNum(paymentClaim?.total));
      console.log("claim.total", toNum(claim.total));
      console.log("order?.total", toNum(order?.total));
      return "Payment Claim will exceed docket budget";
    }
    if (toNum(docket?.costXero) > toNum(docket?.costBudget))
      return "Docket is already over budget";
    if (
      toNum(docket?.costXero) + toNum(paymentClaim?.total ?? claim.total) >
      toNum(docket?.costBudget)
    )
      return "Invoice will place docket over budget";
    else return "";
  }, [docket, claim]);

  const handleStatusChange = (e: SelectChangeEvent<unknown>, v: any) => {
    const option = PAYMENT_CLAIM_STATUS_OPTIONS.filter(
      (opt) => opt.value === v.props.value
    )?.[0];
    if (option) setStatusValue(option);
  };

  const handleSumbmit = () => {
    const paymentDateRefValue = paymentDateRef?.current?.value;
    let plannedPaymentDate: null | TISODate =
      paymentDateRefValue && ISODateRegex.test(paymentDateRefValue)
        ? (paymentDateRefValue as TISODate)
        : paymentDateRefValue &&
          (dotDateRegex.test(paymentDateRefValue) ||
            slashDateRegex.test(paymentDateRefValue))
        ? (`${paymentDateRefValue.substring(
            6,
            10
          )}-${paymentDateRefValue.substring(
            3,
            5
          )}-${paymentDateRefValue.substring(0, 2)}` as TISODate)
        : null;
    let updatedClaim: Partial<PaymentClaim> = {
      paymentId: paymentClaim?.paymentId ?? claim.paymentId,
    };
    const approved: boolean | null =
      statusValue.value == "Approved"
        ? true
        : statusValue.value == "Rejected"
        ? false
        : null;
    if (
      invNumRef?.current?.value &&
      invNumRef.current.value !== paymentClaim?.invoiceNumber
    )
      updatedClaim.invoiceNumber = invNumRef.current.value;
    if (approved !== (paymentClaim?.approved ?? claim.approved))
      updatedClaim.approved = approved;
    if (notesRef?.current?.value) updatedClaim.note = notesRef.current.value;
    if (plannedPaymentDate !== paymentClaim?.plannedPaymentDate)
      updatedClaim.plannedPaymentDate = plannedPaymentDate;
    if (Object.keys(updatedClaim).length > 1)
      paymentClaimMutateQuery.updatePaymentClaim.mutate(updatedClaim);
    // else showNotification("Already up to date", "success");
  };

  const initialNote = useMemo(() => {
    let note = "";
    const claimTotal: number = toNum(paymentClaim?.total ?? claim.total);
    if (
      order?.total &&
      order?.totalApprovedPayments &&
      (paymentClaim?.total || claim.total)
    ) {
      const orderTotal: number = toNum(order.total);
      const approvedPayments: number = toNum(order.totalApprovedPayments);
      const percentage =
        Math.round(((approvedPayments + claimTotal) / orderTotal) * 100) / 100;
      note += `Order Value = $${orderTotal}inc\nPrevious Approved Payment Claims = $${approvedPayments}inc\nThis Claim = $${claimTotal}\n`;
      if (approvedPayments + claimTotal < orderTotal)
        note += `$${
          orderTotal - approvedPayments - claimTotal
        } (${percentage}%) Remaining - OK if equivalent works have been completed\n`;
      else if (approvedPayments + claimTotal > orderTotal)
        note += `INVOICE OVER PO VALUE by $${
          approvedPayments + claimTotal - orderTotal
        } (${percentage}% of PO)\n`;
      else note += "OK if works are complete\n";
    }
    if (docket?.costBudget && docket?.costActual && docket?.costXero) {
      const budget: number = toNum(docket.costBudget);
      const orders: number = toNum(docket.costActual);
      const xero: number = toNum(docket.costXero);
      if (orders > budget)
        note += `WARNING: approved orders ($${orders}) are greater than docket budget ($${budget})`;
      if (xero + claimTotal > budget) {
        xero > budget
          ? (note += `WARNING: Actual expenses ($${xero}) are already greater than docket budget ($${budget})`)
          : (note += `WARNING: Actual expenses ($${xero}) will be pushed over docket budget ($${budget}) with the addition of this claim $${claimTotal}`);
      }
    }
    return note;
  }, [docket, paymentClaim, claim, order]);

  const handleDatePicker = () => {
    setDisplayDatePicker(true);
  };

  const handleBackcharge = () => {
    setAction("backcharge");
  };

  const rootInvoiceTabAnimatedVariants = {
    initial: {
      opacity: 0,
      y: -300,
      transition: {
        duration: 0.1,
      },
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.1,

        ease: [0.2, 0.4, 0.6, 0.8],
      },
    },
  };
  if (paymentClaimQuery.isError) return <>Error</>;
  if (paymentClaimQuery.isLoading) return <Loading />;
  return (
    <StyledReviewPaymentClaimContainer
      variants={rootInvoiceTabAnimatedVariants}
      initial="initial"
      animate="visible"
      exit="initial"
    >
      <div style={{ width: "100%", display: "flex" }}>
        <DoxleNavigateBackButton
          onClick={() => setClaim(undefined)}
          buttonText="Payment List"
          buttonWrapperStyle={{ color: doxleThemeColor.primaryFontColor }}
          buttonTextStyle={{ color: doxleThemeColor.primaryFontColor }}
          backIcon={
            <IoChevronBackSharp
              color="inherit"
              fontSize="1.6rem"
              style={{ marginRight: 4 }}
            />
          }
        />
      </div>
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        // height={80}
        marginBottom={"3rem"}
      >
        <StyledReviewPaymentTitle
          $themeColor={doxleThemeColor}
          $doxleFont={doxleFont}
        >
          Payment Claim Review
        </StyledReviewPaymentTitle>
        <StyledReviewPaymentSubtitle
          $themeColor={doxleThemeColor}
          $doxleFont={doxleFont}
        >
          Keep on top of your budget, and ensure the works have been completed.
        </StyledReviewPaymentSubtitle>
      </Stack>
      <StyledPaymentClaimFieldWrapper>
        <StyledReviewPaymentTextField
          $themeColor={doxleThemeColor}
          $doxleFont={doxleFont}
          fullWidth
          disabled
          label={"Invoice Amount"}
          helperText={invoiceAmountHelperText}
          ref={amountParentRef}
          placeholder={"Invoice Dollar Value inc GST"}
          defaultValue={formatter.format(
            toNum(paymentClaim ? paymentClaim.total : claim.total)
          )}
          sx={{ maxWidth: 144 }}
          inputRef={amountRef}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            id: "claim-amount-input",
            // onKeyDown: handleKeyDown,
            // onBlur: handleBlur,
          }}
        />
        <StyledReviewPaymentTextField
          $themeColor={doxleThemeColor}
          $doxleFont={doxleFont}
          fullWidth
          ref={invNumParentRef}
          placeholder={"Invoice Number"}
          label={"Invoice Number"}
          helperText={
            uniqueInvoiceNumber ? "" : "This Invoice Number is not Unique"
          }
          inputRef={invNumRef}
          defaultValue={claim.invoiceNumber}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            id: "invoice-number-input",
            // onKeyDown: handleKeyDown,
            // onBlur: handleBlur,
          }}
        />
      </StyledPaymentClaimFieldWrapper>
      <StyledPaymentClaimFieldWrapper>
        <div
          style={{
            width: "50%",
            maxWidth: 400,
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            marginRight: 16,
          }}
        >
          <StyledStatusFieldLabel
            id="status-select-label"
            $themeColor={doxleThemeColor}
            $doxleFont={doxleFont}
          >
            Status
          </StyledStatusFieldLabel>
          <StyledReivewPaymentStatusSelect
            $themeColor={doxleThemeColor}
            $doxleFont={doxleFont}
            fullWidth
            ref={statusParentRef}
            inputRef={statusRef}
            label={"Status"}
            placeholder={"Status"}
            value={statusValue.value}
            onChange={handleStatusChange}
            inputProps={{
              id: "status-input",
              sx: { color: statusValue.color },
              // ref: statusRef
            }}
          >
            {PAYMENT_CLAIM_STATUS_OPTIONS.map((option) => (
              <StyledStatusMenuItem
                $doxleFont={doxleFont}
                key={"status-option-" + option.value}
                value={option.value}
                $color={option.color}
                $themeColor={doxleThemeColor}
              >
                {option.value}
              </StyledStatusMenuItem>
            ))}
          </StyledReivewPaymentStatusSelect>
        </div>
        <div
          style={{
            width: "50%",
            maxWidth: 400,
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            marginRight: 16,
          }}
        >
          <StyledStatusFieldLabel
            id="status-select-label"
            $themeColor={doxleThemeColor}
            $doxleFont={doxleFont}
          >
            Date
          </StyledStatusFieldLabel>
          <StyledReviewPaymentTextField
            sx={{ maxWidth: 144, marginTop: "0px !important" }}
            $themeColor={doxleThemeColor}
            $doxleFont={doxleFont}
            inputRef={paymentDateRef}
            onClick={handleDatePicker}
            defaultValue={
              paymentClaim?.plannedPaymentDate
                ? dayjs(paymentClaim.plannedPaymentDate).format("DD.MM.YYYY")
                : "Select A Date..."
            }
          />
        </div>

        <Popper
          open={displayDatePicker}
          anchorEl={paymentDateRef?.current ?? null}
          transition
          placement="bottom-start"
          sx={{ zIndex: 1000 }}
        >
          {({ TransitionProps }) => (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <ClickAwayListener
                onClickAway={(event) => {
                  setDisplayDatePicker(false);
                }}
              >
                <Fade {...TransitionProps} timeout={350}>
                  <StyledReviewPaymentDateCalendar
                    defaultValue={
                      paymentClaim?.plannedPaymentDate
                        ? dayjs(paymentClaim?.plannedPaymentDate)
                        : claim?.plannedPaymentDate
                        ? dayjs(claim?.plannedPaymentDate)
                        : null
                    }
                    $themeColor={doxleThemeColor}
                    $doxleFont={doxleFont}
                    onChange={(value: any) => {
                      if (paymentDateRef?.current)
                        paymentDateRef.current.value =
                          dayjs(value).format("DD.MM.YYYY");
                    }}
                  />
                </Fade>
              </ClickAwayListener>
            </LocalizationProvider>
          )}
        </Popper>
      </StyledPaymentClaimFieldWrapper>

      <StyledPaymentClaimNoteTextField
        $themeColor={doxleThemeColor}
        $doxleFont={doxleFont}
        fullWidth
        multiline
        minRows={10}
        label={"Note"}
        helperText={
          statusValue.value === "Rejected"
            ? "This message will be sent to the payment claim creator."
            : statusValue.value === "Approved"
            ? "This message will be added to your accounting system, it will not be sent to the  payment claim creator"
            : ""
        }
        defaultValue={initialNote}
        inputRef={notesRef}
        ref={notesParentRef}
        inputProps={{
          id: "notes-input",
          // onKeyDown: handleKeyDown,
          // onBlur: handleBlur,
        }}
      />
      {backCharges.map((backCharge: PaymentClaimBackCharge) => {
        return (
          <div>
            {backCharge.reference} - ${backCharge.total}
          </div>
        );
      })}

      <StyledOrderTobButtonContainer></StyledOrderTobButtonContainer>
      <StyledAddedFileDisplaySection>
        {claim.attachments && (
          <StyledAttachedHeaderText
            $themecolor={doxleThemeColor}
            $doxleFont={doxleFont}
          >
            Attached Files:{" "}
          </StyledAttachedHeaderText>
        )}
        {(claim?.attachments ?? []).map((file, index) => (
          <StyledDroppedFileItem key={`droppedFile#${index}`}>
            <StyledDroppedFileItemFileInfo>
              <InvoicePDFIcon />
              <StyledDroppedFileItemTextSpan widthInPixel={null}>
                {file.name}
              </StyledDroppedFileItemTextSpan>
              <StyledDroppedFileItemTextSpan widthInPixel={100}>
                {(file.size * 0.000001).toFixed(2)}Mb
              </StyledDroppedFileItemTextSpan>
            </StyledDroppedFileItemFileInfo>
          </StyledDroppedFileItem>
        ))}
      </StyledAddedFileDisplaySection>
    </StyledReviewPaymentClaimContainer>
  );
};
export default ReviewPaymentClaim;
