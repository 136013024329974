import React from "react";
import {
  StyledFilterQRSummaryBtn,
  StyledQRSummaryFilterBtn,
  StyledQRSummaryFilterContainer,
} from "./StyledQRLogSummary";
import { LuListFilter } from "react-icons/lu";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import useQRLogSummaryFilter from "./Hooks/useQRLogSummaryFilter";
import Popover from "@mui/material/Popover";
import QRUserList from "../CommonComponents/QRUserList";
import { AnimatePresence, motion } from "framer-motion";
import "react-datepicker/dist/react-datepicker.css";
import "../CommonComponents/datePicker.css";
import ReactDatePicker from "react-datepicker";
import { produce } from "immer";
import { ClickAwayListener } from "@mui/material";
type Props = {};

const QRLogSummaryFilter = (props: Props) => {
  const { doxleThemeColor, staticMenuColor } = useDoxleThemeStore();
  const {
    anchorFilterMenu,
    setAnchorFilterMenu,
    closeFilterMenu,
    contentAnimateVariants,
    handleSelectFilterUser,
    filteredUser,
    showUserList,
    setShowUserList,
    showDatePicker,
    setShowDatePicker,
    rangePickedDate,
    onDateChange,
  } = useQRLogSummaryFilter();
  const canBeOpen = Boolean(anchorFilterMenu);
  const id = canBeOpen ? "qr-report-filter-popper" : undefined;
  return (
    <>
      <StyledQRSummaryFilterBtn
        onClick={(e) => setAnchorFilterMenu(e.currentTarget)}
      >
        <LuListFilter size={25} color={doxleThemeColor.primaryFontColor} />
      </StyledQRSummaryFilterBtn>

      <Popover
        id={id}
        open={canBeOpen}
        anchorEl={anchorFilterMenu}
        disableScrollLock
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClose={closeFilterMenu}
        sx={{
          "& .MuiPopover-paper": {
            backgroundColor: "transparent",
            overflow: "visible",
            boxShadow: "none",
          },
        }}
        transitionDuration={{ appear: 100, exit: 200 }}
        elevation={4}
      >
        <StyledQRSummaryFilterContainer
          variants={contentAnimateVariants}
          initial="initial"
          animate="animate"
        >
          <div className="title-text">Log Report Filter</div>

          <StyledFilterQRSummaryBtn
            $hasBorderBtm={true}
            onClick={() => setShowUserList((prev) => !prev)}
          >
            Filter User
          </StyledFilterQRSummaryBtn>

          {showUserList && (
            <ClickAwayListener onClickAway={() => setShowUserList(false)}>
              <motion.div
                className="list-wrapper"
                animate={{
                  opacity: [0, 1],
                  y: [-10, 0],
                }}
              >
                <QRUserList
                  selectedIds={filteredUser ? [filteredUser.userId!] : []}
                  onSelectUser={handleSelectFilterUser}
                />
              </motion.div>
            </ClickAwayListener>
          )}

          <StyledFilterQRSummaryBtn
            layout="position"
            transition={{
              damping: 16,
              mass: 0.4,
            }}
            onClick={() => setShowDatePicker((prev) => !prev)}
          >
            Filter By Date Range
          </StyledFilterQRSummaryBtn>

          {showDatePicker && (
            <ClickAwayListener onClickAway={() => setShowDatePicker(false)}>
              <motion.div
                className="list-wrapper"
                animate={{
                  opacity: [0, 1],
                  y: [-10, 0],
                }}
              >
                <ReactDatePicker
                  selected={rangePickedDate.start}
                  onChange={onDateChange}
                  startDate={rangePickedDate.start}
                  endDate={rangePickedDate.end}
                  selectsRange={true}
                  showIcon={false}
                  popperPlacement="top-end"
                  inline={true}
                />
              </motion.div>
            </ClickAwayListener>
          )}
        </StyledQRSummaryFilterContainer>
      </Popover>
    </>
  );
};

export default QRLogSummaryFilter;
