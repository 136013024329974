import Paper, { PaperProps } from "@mui/material/Paper";
import {
  DoxleFont,
  DoxleThemeColor,
} from "../../../../DoxleGeneralStore/useDoxleThemeStore";
import { motion } from "framer-motion";
import styled from "styled-components";
import {
  TRgbaFormat,
  editRgbaAlpha,
} from "../../../../Utilities/FunctionUtilities";

interface IStatusListContainerProps
  extends Omit<
    PaperProps,
    "onDragStart" | "onAnimationStart" | "onDragEnd" | "onDrag"
  > {
  $themeColor: DoxleThemeColor;
}
const AnimatedPaper = motion(Paper);
const projectListAnimatedVariants = {
  entering: {
    y: [-10, 0],
    opacity: [0, 1],
    transition: {
      duration: 0.4,
    },
  },
  exiting: {
    y: [0, -10],
    opacity: [1, 0],
    transition: {
      duration: 0.4,
    },
  },
};
export const StyledProjectListContainer = styled(
  ({ $themeColor, ...rest }: IStatusListContainerProps) => (
    <AnimatedPaper
      {...rest}
      variants={projectListAnimatedVariants}
      animate="entering"
      exit="exiting"
      initial="initial"
      layout="position"
      elevation={1}
    />
  )
)`
  && {
    width: 150px;
    height: max(20rem, 200px);
    border-radius: 0px 8px 8px 8px;
    position: relative;
    padding: 10px;
    margin-top: 8px;
    display: flex;
    background-color: ${(p) => p.theme.color.primaryContainerColor} !important;
    border: 1px solid ${(p) => p.theme.color.primaryDividerColor};
    flex-direction: column;
  }
`;
export const StyledStatusListTitle = styled.span<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  color: ${(p) => p.$themeColor.primaryFontColor};
  font-family: ${(p) => p.$doxleFont.primaryFont};
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 1rem;
  text-transform: capitalize;
  padding-left: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const StyledStatusListItemWrapper = styled(motion.div)`
  padding-bottom: 4px;
  min-height: 20px;
`;

export const StyledDocketStatusItem = styled.div<{
  $doxleFont: DoxleFont;
  $statusColor: string;
  $selected: boolean;
  $statusSize?: number;
}>`
  max-width: calc(100% - 16px);
  padding: 8px;
  width: fit-content;
  border-radius: 4px;
  display: flex;
  margin: 2px 0px;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  background-color: ${(p) =>
    p.$selected
      ? editRgbaAlpha({
          rgbaColor: p.$statusColor as TRgbaFormat,
          alpha: "0.2",
        })
      : "transparent"};
  .status-text {
    color: ${(p) => p.theme.color.primaryFontColor};
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-size: 14px;
    font-style: normal;
    font-weight: ${(p) => (p.$selected ? 600 : 400)};
    line-height: normal;
    margin-left: 8px;
  }
  .status-dot {
    width: ${(p) => p.$statusSize ?? 10}px;
    height: ${(p) => p.$statusSize ?? 10}px;
    border-radius: ${(p) => (p.$statusSize ? p.$statusSize / 3 : 10 / 3)}px;
    border: 2px solid ${(p) => p.$statusColor};
    flex-shrink: 0;

    margin-right: 8px;
  }
  transition: 0.4s;

  &:hover {
    background-color: ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.$statusColor as TRgbaFormat,
        alpha: "0.2",
      })};
    transition: 0.4s;
  }
`;
