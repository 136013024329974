import React from "react";
import {
  StyledOrderBudgetDisplayer,
  StyledOrderBudgetFieldWrapper,
} from "./StyledComponentBookingDocketDetail";
import { Order } from "../../../Models/orders";
import { useDoxleThemeStore } from "../../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import { formatter } from "../../../../Utilities/FunctionUtilities";

type Props = { orderDetail: Order };

const OrderBudgetDisplayer = ({ orderDetail }: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }),
    shallow
  );
  return (
    <StyledOrderBudgetDisplayer>
      <StyledOrderBudgetFieldWrapper
        $themeColor={doxleThemeColor}
        $doxleFont={doxleFont}
      >
        <div className="row-content-wrapper">
          <span className="budget-label-text">SUBTOTAL</span>
          <span className="budget-content-text">
            {formatter.format(parseFloat(orderDetail.subTotal.toString()))}
          </span>
        </div>

        <div className="row-content-wrapper">
          <span className="budget-label-text">GST</span>
          <span className="budget-content-text">
            {formatter.format(parseFloat(orderDetail.tax.toString()))}
          </span>
        </div>
      </StyledOrderBudgetFieldWrapper>

      <StyledOrderBudgetFieldWrapper
        $themeColor={doxleThemeColor}
        $doxleFont={doxleFont}
      >
        <div className="row-content-wrapper">
          <span className="budget-label-text">Total</span>
          <span className="budget-content-text">
            {formatter.format(parseFloat(orderDetail.total.toString()))}
          </span>
        </div>
      </StyledOrderBudgetFieldWrapper>
    </StyledOrderBudgetDisplayer>
  );
};

export default OrderBudgetDisplayer;
