import React from "react";
import { QAComment } from "../../../Models/qa";
import { useDoxleThemeStore } from "../../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import {
  StyledQACommentInitialWrapper,
  StyledQACommentItemContainer,
} from "./StyledComponentQAItemDetail";
import dayjs from "dayjs";
import { PencilIcon, TrashIcon } from "@heroicons/react/24/outline";
import { useDoxleAuthStore } from "../../../../DoxleGeneralStore/useDoxleAuthStore";
import QAQueryAPI from "../../../QueryAPI/qaQueryAPI";
import useDoxleNotificationStore from "../../../../DoxleGeneralStore/useDoxleNotificationStore";
import { useDoxleCurrentContextStore } from "../../../../DoxleGeneralStore/useDoxleCurrentContext";
import { useProjectQAStore } from "../../Store/useProjectQAStore";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { useShallow } from "zustand/react/shallow";
var advancedFormat = require("dayjs/plugin/advancedFormat");
dayjs.extend(advancedFormat);

type Props = {
  commentItem: QAComment;
  setEditCommentDialog: React.Dispatch<
    React.SetStateAction<QAComment | undefined>
  >;
};

const QACommentItem = ({ commentItem, setEditCommentDialog }: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    useShallow((state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }))
  );
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );
  const user = useDoxleAuthStore(useShallow((state) => state.user));
  const company = useDoxleCurrentContextStore(
    (state) => state.currentCompany,
    shallow
  );
  const qaItem = useProjectQAStore(useShallow((state) => state.selectedQAItem));
  const mutateQuery = QAQueryAPI.useMutateQACommentQuery({
    showNotification,
    company,
    qaItem,
  });
  const handleEditComment = () => {
    setEditCommentDialog(commentItem);
  };
  const handleToggleOfficial = () => {
    mutateQuery.patch.mutate({
      commentId: commentItem.commentId,
      isOfficial: !Boolean(commentItem?.isOfficial),
    });
  };
  return (
    <StyledQACommentItemContainer
      $official={commentItem.isOfficial}
      initial={{ y: -5, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
    >
      <span className="comment-info">
        {commentItem.authorJson
          ? `${commentItem.authorJson.firstName} ${commentItem.authorJson.lastName}`
          : "Unknown User"}{" "}
        -{" "}
        {commentItem.timeStamp
          ? dayjs(commentItem.timeStamp).format("MMM Do, YYYY dddd h:mm a")
          : ""}
      </span>
      <div className="comment-checkbox-wrapper">
        <div className="comment-text-wrapper">
          {commentItem.commentText}

          <div className={"comment-edit-icons"}>
            {commentItem.authorJson?.userId === user?.userId ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginInline: 5,
                }}
              >
                <PencilIcon
                  width={15}
                  style={{ cursor: "pointer" }}
                  onClick={handleEditComment}
                  color={doxleThemeColor.doxleColor}
                />
                <TrashIcon
                  width={15}
                  color={"red"}
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    mutateQuery.destroy.mutate(commentItem.commentId)
                  }
                />
              </div>
            ) : (
              <div />
            )}
            {commentItem.authorJson && (
              <StyledQACommentInitialWrapper
                $themeColor={doxleThemeColor}
                $doxleFont={doxleFont}
              >
                {commentItem.authorJson.firstName[0] ?? ""}
                {commentItem.authorJson.lastName[0] ?? ""}
              </StyledQACommentInitialWrapper>
            )}
          </div>
        </div>
        {commentItem.isOfficial ? (
          <CheckBoxIcon onClick={handleToggleOfficial} />
        ) : (
          <CheckBoxOutlineBlankIcon onClick={handleToggleOfficial} />
        )}
        <span className={"official-text"} onClick={handleToggleOfficial}>
          {commentItem.isOfficial ? "Mark as unofficial" : "Mark as official"}
        </span>
      </div>
    </StyledQACommentItemContainer>
  );
};

export default React.memo(QACommentItem);
