import React from "react";
import { shallow } from "zustand/shallow";
import styled from "styled-components";
import MoreHorizRoundedIcon from "@mui/icons-material/MoreHorizRounded";
import { useMailSideScreenStore } from "../MailSideScreenStore/UseMailSideScreenStore";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { StyleShareBtn, StyledCommentsBtn, StyledMailBtn, StyledTopIconContainer, StyledTopLeftSection, StyledTopRightSection } from "./StyledMailSideScreen";
import DoxleIconButton from "../../../DoxleDesignPattern/DoxleButtons/DoxleIconButton";
import { editRgbaAlpha } from "../../../Utilities/FunctionUtilities";

export const TopMenu = () => {
    const { currentMail, setCurrentDisplay, currentDisplay } = useMailSideScreenStore(
      (state) => ({
        currentMail: state.currentMail,
        resetStore: state.resetStore,
        setCurrentDisplay: state.setCurrentDisplay,
        currentDisplay: state.currentDisplay
      }),
      shallow
    );
    const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
      (state) => ({
        doxleThemeColor: state.doxleThemeColor,
        doxleFont: state.doxleFont,
      }),
      shallow
    );

    const handleClickBtn = (val: "Mail" | "Comments") => {
      if(currentDisplay !== "New"){
        setCurrentDisplay(val);
      }
    }
  
    return (
        <StyledTopIconContainer>
          <StyledTopLeftSection>
            <StyledMailBtn $themeColor={doxleThemeColor} onClick={() => handleClickBtn("Mail")}
            $isActivated={currentDisplay === "Mail" || currentDisplay === "New"}>Mail</StyledMailBtn>
            <StyledCommentsBtn $themeColor={doxleThemeColor} onClick={() => handleClickBtn("Comments")}
            $isActivated={currentDisplay === "Comments"}>
              Comments
            </StyledCommentsBtn>
          </StyledTopLeftSection>
  
          <StyledTopRightSection>
            <StyleShareBtn $themeColor={doxleThemeColor}>Share</StyleShareBtn>
            <DoxleIconButton
              iconSource={
                <MoreHorizRoundedIcon
                  style={{
                    fontSize: "3rem",
                    color: editRgbaAlpha({
                      rgbaColor: doxleThemeColor.primaryFontColor,
                      alpha: "0.8",
                    }),
                  }}
                />
              }
              buttonSize={30}
            />
          </StyledTopRightSection>
        </StyledTopIconContainer>
    );
  };