import React from "react";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import useQRLogStore from "../../Store/useQRLogStore";
import { useShallow } from "zustand/react/shallow";
import {
  StyledDatePickerModeWrapper,
  StyledQRFilterDatePickerWrapper,
  StyledQRLogFilterFieldWrapper,
} from "./StyledQRLogFilter";
import { FaAngleDown } from "react-icons/fa6";
import ReactDatePicker from "react-datepicker";
import useQRLogFilterDate from "./Hooks/useQRLogFilterDate";
import "react-datepicker/dist/react-datepicker.css";
import "../CommonComponents/datePicker.css";
import { AnimatePresence, motion } from "framer-motion";
import DoxleCloseBtn from "../../../DoxleDesignPattern/DoxleButtons/DoxleCloseBtn";
import { IoCloseCircle } from "react-icons/io5";
import { ClickAwayListener } from "@mui/material";
type Props = {
  showDatePicker: boolean;
  setShowDatePicker: React.Dispatch<React.SetStateAction<boolean>>;
};

const QRLogFilterDate = ({ showDatePicker, setShowDatePicker }: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore((state) => ({
    doxleThemeColor: state.doxleThemeColor,
    doxleFont: state.doxleFont,
  }));
  const { removeFilterQRUserLogProp, filterQRUserLog } = useQRLogStore(
    useShallow((state) => ({
      removeFilterQRUserLogProp: state.removeFilterQRUserLogProp,
      filterQRUserLog: state.filterQRUserLog,
    }))
  );
  const {
    dateSelectDisplayText,

    onDateChange,
    isPickDateRange,
    setisPickDateRange,
    selectedStartDate,
    selectedEndDate,
  } = useQRLogFilterDate({ setShowDatePicker });
  return (
    <StyledQRLogFilterFieldWrapper
      $isNull={!Boolean(dateSelectDisplayText)}
      layout
      transition={{
        damping: 16,
        mass: 0.7,
      }}
    >
      <span className="label-text">Filter by date</span>

      <div
        className="toggle-display-field"
        onClick={() => setShowDatePicker((prev) => !prev)}
      >
        {dateSelectDisplayText || "Filter with date or a date range"}

        <div className="icon-wrapper">
          {dateSelectDisplayText && (
            <DoxleCloseBtn
              size="small"
              closeIcon={
                <IoCloseCircle
                  size={20}
                  color={doxleThemeColor.primaryFontColor}
                />
              }
              sx={{ marginInline: "4px" }}
              onClick={(e) => {
                e.stopPropagation();
                removeFilterQRUserLogProp("search_date");
                removeFilterQRUserLogProp("search_date_range");
              }}
            />
          )}
          <FaAngleDown color={doxleThemeColor.primaryFontColor} size={16} />
        </div>
      </div>

      <AnimatePresence>
        {showDatePicker && (
          <ClickAwayListener onClickAway={() => setShowDatePicker(false)}>
            <StyledQRFilterDatePickerWrapper
              animate={{
                opacity: [0, 1],
                y: [10, 0],
              }}
              exit={{
                opacity: 0,
                y: 10,
              }}
            >
              <StyledDatePickerModeWrapper $isRangeMode={isPickDateRange}>
                <div
                  className="date-mode-btn single"
                  onClick={() => setisPickDateRange(false)}
                >
                  Single
                </div>
                <div
                  className="date-mode-btn range"
                  onClick={() => setisPickDateRange(true)}
                >
                  Range
                </div>

                <motion.div
                  className="selected-effect"
                  layout
                  transition={{
                    damping: 16,
                    mass: 0.4,
                  }}
                />
              </StyledDatePickerModeWrapper>
              <ReactDatePicker
                selected={selectedStartDate}
                onChange={(dateRange, e) => {
                  onDateChange(dateRange, e);
                }}
                startDate={selectedStartDate}
                endDate={selectedEndDate}
                selectsRange={isPickDateRange}
                showIcon={false}
                popperPlacement="top-end"
                inline={true}
              />
            </StyledQRFilterDatePickerWrapper>
          </ClickAwayListener>
        )}
      </AnimatePresence>
    </StyledQRLogFilterFieldWrapper>
  );
};

export default QRLogFilterDate;
