import { useDoxleCurrentContextStore } from "../../DoxleGeneralStore/useDoxleCurrentContext";
import { shallow } from "zustand/shallow";
import { useDoxleAuthStore } from "../../DoxleGeneralStore/useDoxleAuthStore";
import useDoxleNotificationStore from "../../DoxleGeneralStore/useDoxleNotificationStore";
import { useMeasurementsStore } from "../MeasurementsStore/useMeasurementsStore";
import {
  useMutation,
  useQueryClient,
  UseMutationResult,
  UseQueryResult,
  DefinedUseQueryResult,
} from "@tanstack/react-query";
import DoxleAPI from "../../Services/DoxleAPI";
import {
  AxiosBackendErrorReturn,
  IApiPaginatedData,
  InfiniteAxiosQueryData,
} from "../../Models/axiosReturn";
import { AxiosError, AxiosResponse, isAxiosError } from "axios";
import { formEstimateListQkey } from "../../Estimates/QueryHooks/EstimatesQueryAPI";
import { Estimate } from "../../Estimates/Models/estimatesFilters";
import { TakeOff } from "../Models/takeOffs";

const useSaveMeasurements = () => {
  const queryClient = useQueryClient();
  const { company, project } = useDoxleCurrentContextStore(
    (state) => ({
      company: state.currentCompany,
      project: state.currentProject,
      // docket: state.currentDocket,
    }),
    shallow
  );

  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );
  const { currentTakeOff, measurements, unit } = useMeasurementsStore(
    (state) => ({
      currentTakeOff: state.currentTakeOff,
      measurements: state.measurements,
      unit: state.currentUnit,
    }),
    shallow
  );
  interface SaveFunctionVariables {
    onSuccessCb?: () => void;
  }
  const saveNewTakeOff = useMutation<
    AxiosResponse<UpdateTakeOffResponse, any>,
    AxiosError<AxiosBackendErrorReturn> | unknown,
    SaveFunctionVariables,
    unknown
  >(
    (variables: SaveFunctionVariables) =>
      DoxleAPI.post(
        `/takeoff/`,
        {
          ...currentTakeOff,
          unit,
          measurements,
        },
        {
          headers: {
            "User-Company": company?.companyId,
          },
        }
      ),
    {
      mutationKey: ["save-new-take-off"],
      onSuccess: async (response, variables, context) => {
        // showNotification(`Successfully added take off`,"success")
        // console.log(data);
        if (variables.onSuccessCb) variables.onSuccessCb();
        if (response.data.takeOff.estimate)
          await queryClient.invalidateQueries({
            queryKey: ["estimates-list"],
            exact: false,
          });
        if (response.data.takeOff)
          queryClient.setQueryData(
            ["takeOffId-detail", response.data.takeOff.takeOffId],
            (old) => {
              if (!old) return old;
              return {
                ...old,
                data: response.data.takeOff,
              };
            }
          );
      },
      onError: (error: any, variables, context) => {
        if (showNotification) {
          if (isAxiosError<AxiosBackendErrorReturn>(error)) {
            showNotification(
              `${error?.response?.status ?? "ERROR"}: ${
                error.response?.data.detail ?? "UNKNOWN ERROR"
              }`,
              "error",
              String(
                error?.response?.data?.detail ?? "Error Saving Takeoff"
              ).substring(0, 300)
            );
          } else {
            showNotification(
              "Something Wrong!",
              "error",
              "Error Saving Takeoff"
            );
          }
        }
      },
    }
  );
  interface UpdateTakeOffResponse {
    takeOff: TakeOff;
    errors: any[];
  }
  const updateTakeOff = useMutation<
    AxiosResponse<UpdateTakeOffResponse, any>,
    AxiosError<AxiosBackendErrorReturn> | unknown,
    SaveFunctionVariables,
    unknown
  >(
    (variables: SaveFunctionVariables) =>
      DoxleAPI.patch<UpdateTakeOffResponse>(
        `/takeoff/${currentTakeOff?.takeOffId ?? ""}/`,
        {
          ...currentTakeOff,
          unit,
          measurements,
        },
        {
          headers: {
            "User-Company": company?.companyId,
          },
        }
      ),
    {
      mutationKey: ["update-take-off"],
      onSuccess: async (response, variables, context) => {
        // showNotification(`Successfully added take off`,"success")
        // console.log(data);
        if (variables.onSuccessCb) variables.onSuccessCb();
        if (response.data.takeOff.estimate)
          await queryClient.invalidateQueries({
            queryKey: ["estimates-list"],
            exact: false,
          });
        if (response.data.takeOff)
          queryClient.setQueryData<AxiosResponse<TakeOff>>(
            ["takeOffId-detail", response.data.takeOff.takeOffId],
            (old) => {
              console.log("old", old);
              console.log("response.data", response.data);
              console.log("response.data.takeOff", response.data.takeOff);
              if (!old) return old;
              return {
                ...old,
                data: response.data.takeOff,
              };
            }
          );
      },
      onError: (error, variables, context) => {
        if (showNotification) {
          if (isAxiosError<AxiosBackendErrorReturn>(error)) {
            showNotification(
              `${error?.response?.status ?? "ERROR"}: ${
                error.response?.data.detail ?? "UNKNOWN ERROR"
              }`,
              "error",
              String(
                error?.response?.data?.detail ?? "Error Update Takeoff"
              ).substring(0, 300)
            );
          } else {
            showNotification(
              "Something Wrong!",
              "error",
              "Error Update Takeoff"
            );
          }
        }
      },
    }
  );

  return (variables: SaveFunctionVariables) => {
    if (currentTakeOff?.takeOffId) updateTakeOff.mutate(variables);
    else saveNewTakeOff.mutate(variables);
  };
};

export default useSaveMeasurements;
