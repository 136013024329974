import React, { useRef } from "react";
import { useDoxleCurrentContextStore } from "../../DoxleGeneralStore/useDoxleCurrentContext";
import { useShallow } from "zustand/react/shallow";
import { useEditDocketSideScreenStore } from "../../CoreContent/EditDocketSideScreen/EditDocketSideScreenStore/useEditDocketSideScreenStore";
import useDoxleNotificationStore from "../../DoxleGeneralStore/useDoxleNotificationStore";
import { useFileStore } from "../Store/useFileStore";
import { DoxleFolder } from "../../Models/files";
import FileStorageQueryAPI from "../QueryHooks/FileStorageQueryAPI";

const useListNewFolderPlaceholder = () => {
  const { company, currentProject } = useDoxleCurrentContextStore(
    useShallow((state) => ({
      company: state.currentCompany,
      currentProject: state.currentProject,
    }))
  );
  const edittedDocket = useEditDocketSideScreenStore(
    useShallow((state) => state.edittedDocket)
  );
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification
  );
  const { filter, currentLevel, setCurrentFolder } = useFileStore(
    useShallow((state) => ({
      filter: state.filterRootFolderQuery,
      currentLevel: state.currentLevel,
      setCurrentFolder: state.setCurrentFolder,
    }))
  );
  const inputRef = useRef<HTMLInputElement>(null);

  const onAddFolderSuccessCallback = (folder?: DoxleFolder) => {
    if (folder) {
      setCurrentFolder(folder);
    }
    if (inputRef.current) inputRef.current.value = "";
  };

  const addFolderQuery = FileStorageQueryAPI.useAddFolderQuery({
    company,
    showNotification,
    onAddFolderSuccessCallback,
    filter,
  });
  const handleRowClick = () => {
    if (inputRef.current) inputRef.current.focus();
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      addFolderQuery.mutate({
        folderId: "",
        folderName: e.currentTarget.value,
        lastModified: "",
        docket:
          currentLevel === "Docket" && edittedDocket
            ? edittedDocket.docketPk
            : null,
        project:
          currentLevel === "Project" && currentProject
            ? currentProject.projectId
            : currentLevel === "Docket" && edittedDocket
            ? edittedDocket.project
            : null,
        company: company?.companyId,
      });
    }
  };
  return { handleRowClick, handleKeyDown, inputRef };
};

export default useListNewFolderPlaceholder;
