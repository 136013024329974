import React from "react";
import {
  StyledEditLineModeButton,
  StyledToolModeDisplay,
} from "./StyledEditNote";
import { LineEditTool } from "../../Models/note";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import {
  DoxleAlignModeIcon,
  DoxleBoldModeIcon,
  DoxleItalicModeIcon,
  DoxleQuoteModeIcon,
} from "../../../DoxleDesignPattern/DoxleGeneralIcons";

type Props = {
  toolModes: LineEditTool[];
  handleToggleTool: (tool: LineEditTool) => void;
  containerStyle?: React.CSSProperties;
};

const ToolModeDisplay = ({
  toolModes,
  handleToggleTool,
  containerStyle,
}: Props) => {
  const { doxleThemeColor, doxleFont, transparentMode } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
      transparentMode: state.transparentMode,
    }),
    shallow
  );
  const checkLineEditToolEnable = (tool: LineEditTool) =>
    toolModes.includes(tool);
  return (
    <StyledToolModeDisplay
      $themeColor={doxleThemeColor}
      style={containerStyle}
      animate={{
        y: [10, 0],
        opacity: [0, 1],
        transition: {
          delay: 0.4,
        },
      }}
      exit={{
        scaleX: [1, 0],
        opacity: [1, 0],
      }}
    >
      <StyledEditLineModeButton
        $themeColor={doxleThemeColor}
        $selected={checkLineEditToolEnable("bold")}
        onClick={() => handleToggleTool("bold")}
        // onClick={handleBoldClick}
      >
        <DoxleBoldModeIcon themeColor={doxleThemeColor} />
      </StyledEditLineModeButton>
      <StyledEditLineModeButton
        $themeColor={doxleThemeColor}
        $selected={checkLineEditToolEnable("italic")}
        onClick={() => handleToggleTool("italic")}
        // onClick={handleBoldClick}
      >
        <DoxleItalicModeIcon themeColor={doxleThemeColor} />
      </StyledEditLineModeButton>
      <StyledEditLineModeButton
        $themeColor={doxleThemeColor}
        $selected={checkLineEditToolEnable("quote")}
        onClick={() => handleToggleTool("quote")}
        // onClick={handleBoldClick}
      >
        <DoxleQuoteModeIcon themeColor={doxleThemeColor} />
      </StyledEditLineModeButton>

      <StyledEditLineModeButton
        $themeColor={doxleThemeColor}
        $selected={checkLineEditToolEnable("bullet")}
        onClick={() => handleToggleTool("bullet")}
        // onClick={handleBoldClick}
      >
        <DoxleAlignModeIcon themeColor={doxleThemeColor} />
      </StyledEditLineModeButton>
    </StyledToolModeDisplay>
  );
};

export default ToolModeDisplay;
