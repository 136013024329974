import { Button, Dialog } from "@mui/material";
import { motion } from "framer-motion";
import styled from "styled-components";
import {
  DoxleFont,
  DoxleThemeColor,
} from "../../DoxleGeneralStore/useDoxleThemeStore";
import { editRgbaAlpha } from "../../Utilities/FunctionUtilities";
import { Height } from "@mui/icons-material";

export const StyledDrawingsPreviewPageRootContainer = styled(motion.div)`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;

  flex-direction: column;

  .image-content {
    flex: 1;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const StyledDrawingsPreviewPageTitle = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  font-family: ${(p) => p.$doxleFont.secondaryFont};
  color: ${(p) => p.$themeColor.primaryFontColor};
  font-style: normal;
  font-weight: 500;
  font-size: 2.7rem;
  line-height: 3.2rem;
`;

export const StyledDrawingsPreviewPagePageNumber = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  width: 100%;
  max-width: 25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .page-number-text {
    color: ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.$themeColor.primaryFontColor,
        alpha: "0.6",
      })};
    font-family: ${(p) => p.$doxleFont.secondaryFont};
    font-style: normal;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 2.3rem;
    align-self: center;
  }
`;

export const StyledPreviewDialogPageNumber = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  .page-number-text {
    color: ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.$themeColor.primaryFontColor,
        alpha: "0.6",
      })};
    font-family: ${(p) => p.$doxleFont.secondaryFont};
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: normal;
    align-self: center;
  }
`;

export const StyledDrawingsPreviewPageImage = styled.img<{
  $imageVertical?: boolean;
  $width?: number;
  $aspectRatio?: number;
  $maxWidth: number;
}>`
  padding: 0px;
  border-radius: 0px;
  z-index: 101;

  transition: all 0.4s ease;
  width: ${(p) => p.$width}px;
  max-width: ${(p) => p.$maxWidth}px;
  max-height: 100%;
  aspect-ratio: ${(p) => p.$aspectRatio};
`;

export const StyledDrawingsPreviewContainer = styled.div<{
  imageVertical?: boolean;
}>`
  padding: 0px;
  margin-top: 10px;
  border-radius: 0px;
  z-index: 101;
  display: flex;
  height: 100%;
  width: 100%;
  transition: all 0.6s ease;

  .react-pdf__Page__canvas {
    margin: 0 auto;
    width: 100% !important;
    height: 100% !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .react-pdf__Document {
    margin: 0 auto;
    width: 100% !important;
    height: 100% !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const StyledDrawingsPreviewPageNavigateLeftButton = styled(Button)`
  && {
    display: flex;

    min-width: 40px;
    max-width: 40px;
    min-height: 40px;
    max-height: 40px;
    transition: 0.2s;
    &:hover {
      transform: scale(1.1);
      transition: 0.2s;
    }
  }
`;

export const StyledDrawingsPreviewPageNavigateRightButton = styled(Button)`
  && {
    display: flex;

    min-width: 40px;
    max-width: 40px;
    min-height: 40px;
    max-height: 40px;
    transition: 0.2s;
    &:hover {
      transform: scale(1.1);
      transition: 0.2s;
    }
  }
`;

export const StyledDrawingsPreviewPageSpaceBetweenDiv = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledDrawingsPreviewPageReturnToHomeButton = styled(Button)`
  && {
    height: 28px;
    position: absolute;
    top: 40px;
    left: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: black;
  }
`;

export const StyledDrawingPreviewTopContainer = styled.div`
  display: flex;
  width: 60%;
  align-self: flex-start;
  justify-content: space-between;
  margin-top: 2rem;
`;

export const StyledDrawingPreviewDialogTop = styled.div<{}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 28px);
  position: absolute;
  padding: 14px;
  z-index: 10;
  top: 0;
  left: 0;

  .wrapper {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

export const StyledDrawingPreviewDialog = styled(Dialog)`
  & .MuiDialog-paper {
    background-color: transparent;
    box-shadow: none;
    width: 100%;
    max-width: none;
    height: 100%;
    overflow-y: hidden;
    margin: 0px !important;
    max-height: 100vh !important;
  }

  & .MuiDialog-paper::-webkit-scrollbar {
    display: none;
  }
`;
export const StyledDrawingPreviewDialogContainer = styled.div`
  text-align: center;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const StyledPreviewImageContainer = styled.div`
  flex: 1;
  padding: 14px 0px;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

export const StyledPreviewDrawingTitle = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  font-family: ${(p) => p.$doxleFont.secondaryFont};
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  color: white;

  align-self: flex-start;

  position: absolute;
  left: 14px;
  background-color: ${(p) =>
    editRgbaAlpha({
      rgbaColor: p.$themeColor.doxleColor,
      alpha: "0.8",
    })};
  max-width: 20vw;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: 4px;
  padding: 4px 8px;
`;
