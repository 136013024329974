import { enqueueSnackbar } from "notistack";
import { create } from "zustand";
import { Contact } from "../Models/addressBook";
import { DocketPermission, ProjectPermission } from "../Models/permissions";

interface ShareDialogStore {
  permissionDropDownAncorEl: null | HTMLDivElement;
  setPermissionDropDownAncorEl: (anchorEl: null | HTMLDivElement) => void;
  currentPermission: DocketPermission | ProjectPermission | null;
  updateCurrentPermission: (
    permission: Partial<
      Omit<
        DocketPermission | ProjectPermission,
        "company" | "project" | "docket" | "contact" | "user"
      >
    >
  ) => void;
  setPermission: (
    permission: DocketPermission | ProjectPermission | null
  ) => void;
  handleSetDropDownMenu: (
    permission: DocketPermission | ProjectPermission | null,
    anchorEl: null | any
  ) => void;
  permissionsList: (DocketPermission | ProjectPermission)[];
  pushPermission: (permission: DocketPermission | ProjectPermission) => void;
  deletePermission: (permission: DocketPermission | ProjectPermission) => void;
  setPermissionsList: (
    contact: (DocketPermission | ProjectPermission)[]
  ) => void;
}

const useDoxleShareDialogStore = create<ShareDialogStore>((set, get) => ({
  permissionDropDownAncorEl: null,
  setPermissionDropDownAncorEl: (anchorEl) =>
    set({ permissionDropDownAncorEl: anchorEl }),
  currentPermission: null,
  updateCurrentPermission: (permission) => {
    const oldPermission: DocketPermission | ProjectPermission | null =
      get().currentPermission;
    if (oldPermission !== null)
      set({
        currentPermission: {
          ...oldPermission,
          ...permission,
        },
      });
  },
  setPermission: (permission: DocketPermission | ProjectPermission | null) =>
    set({ currentPermission: permission }),
  handleSetDropDownMenu: (
    permission: DocketPermission | ProjectPermission | null,
    anchorEl: null | any
  ) =>
    set({
      currentPermission: permission,
      permissionDropDownAncorEl: anchorEl,
    }),
  permissionsList: [],
  pushPermission: (permission: DocketPermission | ProjectPermission) =>
    set({ permissionsList: [...get().permissionsList, permission] }),
  deletePermission: (permission: DocketPermission | ProjectPermission) =>
    set({
      permissionsList: get().permissionsList.filter(
        (prev: DocketPermission | ProjectPermission) =>
          prev.user !== permission.user
      ),
    }),
  setPermissionsList: (permission: (DocketPermission | ProjectPermission)[]) =>
    set({ permissionsList: permission }),
}));

export default useDoxleShareDialogStore;
