import {
  StyledNoticeBoardButton,
  StyledNoticeBoardContentContainer,
  StyledNoticeBoardImgContainer,
  StyledNoticeBoardEmptyScreenContainer,
} from "./StyledNoticeBoardContent";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import { FiChevronDown } from "react-icons/fi";
import useNoticeBoardEmptyScreen from "./Hooks/useNoticeBoardEmptyScreen";
import { CircularProgress } from "@mui/material";
import { NBEmptyBanner } from "../../../DoxleDesignPattern/DoxleGeneralIcons";
import { HiPlus } from "react-icons/hi";

export interface INoticeBoardEmptyScreenProps {}

export default function NoticeBoardEmptyScreen(
  props: INoticeBoardEmptyScreenProps
) {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }),
    shallow
  );
  const { handleAddNewNoticeBoardDocket, isAddingdocket } =
    useNoticeBoardEmptyScreen({});
  return (
    <StyledNoticeBoardEmptyScreenContainer>
      <StyledNoticeBoardImgContainer>
        {/* <img src={noticeBoardMainImg} alt="notice board main img " /> */}
        <NBEmptyBanner
          themeColor={doxleThemeColor}
          containerStyle={{ width: "50%" }}
        />
      </StyledNoticeBoardImgContainer>
      <StyledNoticeBoardContentContainer
        $themeColor={doxleThemeColor}
        $doxleFont={doxleFont}
      >
        <h2>One communal board for all</h2>
        <p>
          With the power of one notice board, there is complete transparency
          between all Doxlers so there is no data lost through miscommunication.
          Attach files, pin your notices, track archived notices over time etc.
        </p>
      </StyledNoticeBoardContentContainer>
      <StyledNoticeBoardButton
        $themeColor={doxleThemeColor}
        $doxleFont={doxleFont}
        initial={{ scale: 1 }}
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 1 }}
        onClick={handleAddNewNoticeBoardDocket}
      >
        {!isAddingdocket ? (
          <HiPlus
            fontSize="16px"
            color={doxleThemeColor.primaryContainerColor}
            style={{ marginRight: "5px" }}
          />
        ) : (
          <CircularProgress
            size="16px"
            style={{
              color: doxleThemeColor.primaryContainerColor,
              marginRight: "5px",
            }}
          />
        )}
        <span>New Notice </span>
        <div></div>
      </StyledNoticeBoardButton>
    </StyledNoticeBoardEmptyScreenContainer>
  );
}
