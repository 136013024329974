import React, { useEffect, useRef, useState } from "react";
import {
  Arrow,
  Circle,
  IAxisPos,
  Label,
  Rectangle,
  StraightLine,
  TQAMarkupShape,
  useQAMarkupStore,
} from "../Store/useQAMarkupStore";
import { shallow } from "zustand/shallow";
import { useProjectQAStore } from "../../../Store/useProjectQAStore";
import Konva from "konva";
import QAQueryAPI, {
  UpdateQAImageWithMarkupProps,
} from "../../../../QueryAPI/qaQueryAPI";
import { useDoxleAuthStore } from "../../../../../DoxleGeneralStore/useDoxleAuthStore";
import { useDoxleCurrentContextStore } from "../../../../../DoxleGeneralStore/useDoxleCurrentContext";
import useDoxleNotificationStore from "../../../../../DoxleGeneralStore/useDoxleNotificationStore";
import {
  QAImage,
  QAMarkupArrow,
  QAMarkupCircle,
  QAMarkupLabel,
  QAMarkupRectangle,
  QAMarkupStraightLine,
} from "../../../../Models/qa";
import useSetQaImageQueryData from "../../../../CustomQueryHooks/useSetQaImageQueryData";
type Props = {};

interface QAMarkup {
  isDragging: boolean;
  setIsDragging: React.Dispatch<React.SetStateAction<boolean>>;
  wrapperRef: React.RefObject<HTMLDivElement>;
  layoutStage: {
    width: number;
    height: number;
  };
  handleMouseUp: (e: Konva.KonvaEventObject<MouseEvent>) => void;
  handleMouseMove: (e: Konva.KonvaEventObject<MouseEvent>) => void;
  handleMouseWheel: (e: Konva.KonvaEventObject<WheelEvent>) => void;

  newTextLabelValue: string;
  setnewTextLabelValue: React.Dispatch<React.SetStateAction<string>>;

  stageRef: React.RefObject<Konva.Stage>;
  isSavingMarkup: boolean;
  onSaveAnnotationImage: () => void;
  shouldShowSaveBtn: boolean;
}

export const TEXT_FIELD_WIDTH: number = 200;
const useQAMarkup = (props: Props): QAMarkup => {
  const [newTextLabelValue, setnewTextLabelValue] = useState("");
  const [startPoint, setstartPoint] = useState<IAxisPos | undefined>(undefined);
  const [endPoint, setEndPoint] = useState<IAxisPos | undefined>(undefined);
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [layoutStage, setlayoutStage] = useState<{
    width: number;
    height: number;
  }>({ width: 0, height: 0 });
  const wrapperRef = useRef<HTMLDivElement>(null);

  const { markupQAImage, setMarkupQAImage } = useProjectQAStore(
    (state) => ({
      markupQAImage: state.markupQAImage,
      setMarkupQAImage: state.setMarkupQAImage,
    }),
    shallow
  );
  const {
    stageState,
    currentTool,
    setStageState,
    currentShape,
    currentColor,
    setCurrentShape,
    addMarkup,
    updateCurrentShape,
    addedMarkups,
    resetStore,
    setOldMarkup,
    oldMarkup,
  } = useQAMarkupStore(
    (state) => ({
      stageState: state.stageState,
      setStageState: state.setStageState,
      currentTool: state.currentTool,
      currentShape: state.currentShape,
      setCurrentShape: state.setCurrentShape,
      currentColor: state.currentColor,
      addMarkup: state.addMarkup,
      updateCurrentShape: state.updateCurrentShape,
      addedMarkups: state.addedMarkups,
      resetStore: state.resetStore,
      setOldMarkup: state.setOldMarkup,
      oldMarkup: state.oldMarkup,
    }),
    shallow
  );
  const handleMouseUp = (e: Konva.KonvaEventObject<MouseEvent>) => {
    if (currentTool === "Pointer") return;
    if (e?.evt?.button && e?.evt?.button !== 0) return;
    if (!markupQAImage) return;

    const pos = e.target?.getStage()?.getRelativePointerPosition() as IAxisPos;
    if (
      pos.x < 0 ||
      pos.x > markupQAImage.qaImage.imageWidth ||
      pos.y < 0 ||
      pos.y > markupQAImage.qaImage.imageHeight
    )
      return;

    if (!startPoint) {
      setstartPoint({ x: pos.x, y: pos.y });
      if (currentTool === "StraightLine" || currentTool === "Arrow") {
        const shape: StraightLine | Arrow = {
          type: currentTool,
          color: currentColor,
          x1: pos.x,
          y1: pos.y,
          x2: pos.x,
          y2: pos.y,
        };
        setCurrentShape(shape);
      } else if (currentTool === "Rectangle") {
        const shape: Rectangle = {
          type: "Rectangle",
          color: currentColor,
          x: pos.x,
          y: pos.y,
          w: pos.x - pos.x,
          h: pos.y - pos.y,
        };
        setCurrentShape(shape);
      } else if (currentTool === "Circle") {
        const shape: Circle = {
          type: "Circle",
          color: currentColor,
          x: pos.x,
          y: pos.y,
          r: Math.sqrt(Math.pow(pos.x - pos.x, 2) + Math.pow(pos.y - pos.y, 2)),
        };
        setCurrentShape(shape);
      } else if (currentTool === "Text") {
        const shape: Label = {
          type: "Text",
          color: currentColor,
          x:
            pos.x + TEXT_FIELD_WIDTH + 40 <= markupQAImage.qaImage.imageWidth
              ? pos.x
              : markupQAImage.qaImage.imageWidth - TEXT_FIELD_WIDTH - 40,
          y:
            pos.y + 50 + 40 <= markupQAImage.qaImage.imageHeight
              ? pos.y
              : markupQAImage.qaImage.imageHeight - 50 - 40,
          text: "",
        };
        setCurrentShape(shape);
      }
    } else {
      if (currentShape) {
        if (
          currentShape.type === "StraightLine" ||
          currentShape.type === "Arrow"
        ) {
          const shape: StraightLine | Arrow = {
            type: currentShape.type,
            color: currentColor,
            x1: startPoint.x,
            y1: startPoint.y,
            x2: pos.x,
            y2: pos.y,
          };
          addMarkup(shape);
        } else if (currentShape.type === "Rectangle") {
          const shape: Rectangle = {
            type: "Rectangle",
            color: currentColor,
            x: startPoint.x,
            y: startPoint.y,
            w: pos.x - startPoint.x,
            h: pos.y - startPoint.y,
          };
          addMarkup(shape);
        } else if (currentShape.type === "Circle") {
          const shape: Circle = {
            type: "Circle",
            color: currentColor,
            x: startPoint.x,
            y: startPoint.y,
            r: Math.sqrt(
              Math.pow(startPoint.x - pos.x, 2) +
                Math.pow(startPoint.y - pos.y, 2)
            ),
          };
          addMarkup(shape);
        } else if (currentShape.type === "Text") {
          const shape: Label = {
            type: "Text",
            color: currentColor,
            x: currentShape.x,
            y: currentShape.y,

            text: newTextLabelValue,
          };
          addMarkup(shape);
          setnewTextLabelValue("");
        }

        setCurrentShape(null);
        setstartPoint(undefined);
      }
    }
  };

  const handleMouseMove = (e: Konva.KonvaEventObject<MouseEvent>) => {
    if (
      !startPoint ||
      !markupQAImage ||
      currentTool === "Pointer" ||
      currentTool === "Text"
    )
      return;

    const stage = e.target.getStage();
    if (stage) {
      const pos = stage.getRelativePointerPosition() as IAxisPos;
      if (
        pos.x < 0 ||
        pos.x > markupQAImage.qaImage.imageWidth ||
        pos.y < 0 ||
        pos.y > markupQAImage.qaImage.imageHeight
      )
        return;
      if (currentTool === "StraightLine" || currentTool === "Arrow") {
        const shape: StraightLine | Arrow = {
          type: currentTool,
          color: currentColor,
          x1: startPoint.x,
          y1: startPoint.y,
          x2: pos.x,
          y2: pos.y,
        };
        updateCurrentShape(shape);
      } else if (currentTool === "Rectangle") {
        const shape: Rectangle = {
          type: "Rectangle",
          color: currentColor,
          x: startPoint.x,
          y: startPoint.y,
          w: pos.x - startPoint.x,
          h: pos.y - startPoint.y,
        };
        updateCurrentShape(shape);
      } else if (currentTool === "Circle") {
        let radius = Math.sqrt(
          Math.pow(startPoint.x - pos.x, 2) + Math.pow(startPoint.y - pos.y, 2)
        );

        const shape: Circle = {
          type: "Circle",
          color: currentColor,
          x: startPoint.x,
          y: startPoint.y,
          r: radius,
        };
        updateCurrentShape(shape);
      }
    }
  };
  const handleMouseWheel = (e: Konva.KonvaEventObject<WheelEvent>) => {
    e.evt.preventDefault();
    const scaleBy = 1.05;
    const stage = e.target.getStage();
    if (stage) {
      const oldScale = stage.scaleX();
      const mousePointTo = {
        x: stage.getPointerPosition()!.x / oldScale - stage.x() / oldScale,
        y: stage.getPointerPosition()!.y / oldScale - stage.y() / oldScale,
      };
      const newScale =
        e.evt.deltaY < 0 ? oldScale * scaleBy : oldScale / scaleBy;
      setStageState({
        stageScale: newScale,
        stageX:
          -(mousePointTo.x - stage.getPointerPosition()!.x / newScale) *
          newScale,
        stageY:
          -(mousePointTo.y - stage.getPointerPosition()!.y / newScale) *
          newScale,
      });
    }
  };
  useEffect(() => {
    setlayoutStage({
      width: wrapperRef.current?.offsetWidth ?? 0,
      height: wrapperRef.current?.offsetHeight ?? 0,
    });
  }, [wrapperRef.current, markupQAImage]);

  useEffect(() => {
    if (markupQAImage) {
      setStageState({
        stageScale: layoutStage.height / markupQAImage.qaImage.imageHeight,
        stageX: (layoutStage.width - markupQAImage.qaImage.imageWidth) / 2,
        stageY: (layoutStage.height - markupQAImage.qaImage.imageHeight) / 2,
      });
    }
  }, [markupQAImage, layoutStage]);

  useEffect(() => {
    if (markupQAImage) {
      const initialMarkup:
        | Array<Circle | StraightLine | Label | Rectangle | Arrow>
        | undefined = markupQAImage.markupList.map((markup) => {
        if (markup.shape === "circle")
          return {
            type: "Circle",
            x: Number(markup.startX),
            y: Number(markup.startY),
            r: Number(markup.radius),
            color: markup.borderColor,
          } as Circle;
        else if (markup.shape === "arrow")
          return {
            type: "Arrow",
            x1:
              typeof markup.startX === "string"
                ? Number(markup.startX)
                : markup.startX,
            y1:
              typeof markup.startY === "string"
                ? Number(markup.startY)
                : markup.startY,
            x2:
              typeof markup.endX === "string"
                ? Number(markup.endX)
                : markup.endX,
            y2:
              typeof markup.endY === "string"
                ? Number(markup.endY)
                : markup.endY,
            color: markup.bgColor,
          } as Arrow;
        else if (markup.shape === "rectangle")
          return {
            type: "Rectangle",
            x: Number(markup.startX),
            y: Number(markup.startY),
            w: Number(markup.endX) - Number(markup.startX),
            h: Number(markup.endY) - Number(markup.startY),
            color: markup.bgColor,
          } as Rectangle;
        else if (markup.shape === "line")
          return {
            type: "StraightLine",
            x1: Number(markup.startX),
            y1: Number(markup.startY),
            x2: Number(markup.endX),
            y2: Number(markup.endY),
            color: markup.bgColor,
          } as StraightLine;
        else
          return {
            type: "Text",
            x: Number(markup.startX),
            y: Number(markup.startY),
            text: markup.markupText,
            color: markup.bgColor,
          } as Label;
      });

      setOldMarkup(initialMarkup);
    }
    return () => {
      setstartPoint(undefined);
      resetStore();
    };
  }, [markupQAImage]);

  //*update api
  const company = useDoxleCurrentContextStore(
    (state) => state.currentCompany,
    shallow
  );
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );
  const { handleUpdateQAImgQueryData } = useSetQaImageQueryData({
    qaId: markupQAImage?.qaImage.defect ?? "",
  });

  const onSuccessUpdateImgWithMarkup = (props?: {
    newUrlImg: string;
    prevData: UpdateQAImageWithMarkupProps;
  }) => {
    if (props)
      handleUpdateQAImgQueryData(props.prevData.qaImage.imageId, {
        thumbUrl: props.newUrlImg,
      });
    setMarkupQAImage(undefined);
  };
  const updateQAImageWithMarkupQuery =
    QAQueryAPI.useUpdateQAImageWithMarkupQuery({
      showNotification,
      company,
      onSuccessCB: onSuccessUpdateImgWithMarkup,
    });

  const onSuccessUpdateMarkup = (props: {
    successList: Array<
      | QAMarkupRectangle
      | QAMarkupCircle
      | QAMarkupStraightLine
      | QAMarkupLabel
      | QAMarkupArrow
    >;
    qaImage: QAImage;
  }) => {
    handleUpdateQAImgQueryData(props.qaImage.imageId, {
      markup: props.successList,
    });
  };
  const updateMarkupQuery = QAQueryAPI.useUpdateQAImageMarkupQuery({
    showNotification,
    company,
    onSuccessCB: onSuccessUpdateMarkup,
  });

  const onSaveAnnotationImage = () => {
    if (markupQAImage) {
      const { qaImage } = markupQAImage;

      let finalMarkupData: Array<
        | QAMarkupRectangle
        | QAMarkupCircle
        | QAMarkupStraightLine
        | QAMarkupLabel
        | QAMarkupArrow
      > = [];
      [...oldMarkup, ...addedMarkups].forEach((markup, idx) => {
        if (markup.type === "Rectangle")
          finalMarkupData.push({
            id: "",
            bgColor: markup.color,
            borderColor: markup.color,
            borderThickness: 1,
            defectImage: qaImage.imageId,
            markupIndex: idx,
            shape: "rectangle",
            startX: parseFloat(markup.x.toFixed(1)),
            startY: parseFloat(markup.y.toFixed(1)),
            endX: parseFloat((markup.x + markup.w).toFixed(1)),
            endY: parseFloat((markup.y + markup.h).toFixed(1)),
          } as QAMarkupRectangle);
        else if (markup.type === "Circle")
          finalMarkupData.push({
            id: "",
            bgColor: markup.color,
            borderColor: markup.color,
            borderThickness: 1,
            defectImage: qaImage.imageId,
            markupIndex: idx,
            shape: "circle",
            startX: parseFloat(markup.x.toFixed(1)),
            startY: parseFloat(markup.y.toFixed(1)),
            radius: parseFloat(markup.r.toFixed(1)),
          } as QAMarkupCircle);
        else if (markup.type === "StraightLine")
          finalMarkupData.push({
            id: "",
            bgColor: markup.color,
            borderColor: markup.color,
            borderThickness: 1,
            defectImage: qaImage.imageId,
            markupIndex: idx,
            shape: "line",
            startX: parseFloat(markup.x1.toFixed(1)),
            startY: parseFloat(markup.y1.toFixed(1)),
            endX: parseFloat(markup.x2.toFixed(1)),
            endY: parseFloat(markup.y2.toFixed(1)),
          } as QAMarkupStraightLine);
        else if (markup.type === "Arrow")
          finalMarkupData.push({
            id: "",
            bgColor: markup.color,
            borderColor: markup.color,
            borderThickness: 1,
            defectImage: qaImage.imageId,
            markupIndex: idx,
            shape: "arrow",
            startX: parseFloat(markup.x1.toFixed(1)),
            startY: parseFloat(markup.y1.toFixed(1)),
            endX: parseFloat(markup.x2.toFixed(1)),
            endY: parseFloat(markup.y2.toFixed(1)),
          } as QAMarkupArrow);
        else
          finalMarkupData.push({
            id: "",
            bgColor: markup.color,
            borderColor: markup.color,
            borderThickness: 1,
            defectImage: qaImage.imageId,
            markupIndex: idx,
            shape: "label",
            startX: parseFloat(markup.x.toFixed(1)),
            startY: parseFloat(markup.y.toFixed(1)),
            markupText: markup.text,
          } as QAMarkupLabel);
      });

      updateMarkupQuery.mutate({
        qaImage: qaImage,
        markupList: finalMarkupData,
      });

      //# post image with markup
      stageRef.current?.toBlob({
        mimeType: "image/jpeg",
        quality: 1,
        callback: (blob) => {
          if (blob) {
            updateQAImageWithMarkupQuery.mutate({
              qaImage: qaImage,
              newImgBlob: blob,
            });
          }
        },
      });
    }
  };

  const stageRef = useRef<Konva.Stage>(null);

  const isSavingMarkup =
    updateMarkupQuery.isLoading || updateQAImageWithMarkupQuery.isLoading;

  const shouldShowSaveBtn = Boolean(
    (markupQAImage && markupQAImage.markupList.length > oldMarkup.length) ||
      addedMarkups.length > 0
  );
  return {
    isDragging,
    setIsDragging,
    wrapperRef,
    layoutStage,
    handleMouseUp,
    handleMouseMove,
    handleMouseWheel,

    newTextLabelValue,
    setnewTextLabelValue,
    stageRef,
    isSavingMarkup,
    onSaveAnnotationImage,
    shouldShowSaveBtn,
  };
};

export default useQAMarkup;
