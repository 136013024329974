import React from "react";
import { useDoxleCurrentContextStore } from "../../DoxleGeneralStore/useDoxleCurrentContext";
import useDoxleNotificationStore from "../../DoxleGeneralStore/useDoxleNotificationStore";
import { shallow } from "zustand/shallow";
import { useDoxleAuthStore } from "../../DoxleGeneralStore/useDoxleAuthStore";
import DrawingQueryAPI from "../QueryHooks/drawingQueryAPI";
import { useDrawingStore } from "../Store/useDrawingStore";
import useSetDrawingsQueryData from "../QueryHooks/useSetDrawingsQueryData";

type Props = {};

interface DrawingDeleteConfirmDialog {
  handleDeleteDrawing: () => void;
  handleCloseDeleteDialog: () => void;
  isDeletingDrawing: boolean;
}

const useDrawingDeleteConfirmDialog = (
  props: Props
): DrawingDeleteConfirmDialog => {
  const { company, selectedProject } = useDoxleCurrentContextStore(
    (state) => ({
      company: state.currentCompany,
      selectedProject: state.currentProject,
    }),
    shallow
  );

  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );

  const { selectedDrawingForDelete, setSelectedDrawingForDelete } =
    useDrawingStore(
      (state) => ({
        selectedDrawingForDelete: state.selectedDrawingForDelete,
        setSelectedDrawingForDelete: state.setSelectedDrawingForDelete,
      }),
      shallow
    );
  const { handleRemoveMultiDrawings } = useSetDrawingsQueryData({
    projectId: selectedProject?.projectId || "",
  });
  const onSuccessDelete = (deletedIds?: string[]) => {
    setSelectedDrawingForDelete(undefined);
    if (deletedIds) handleRemoveMultiDrawings(deletedIds);
  };

  const deleteDrawing = DrawingQueryAPI.useDeleteDrawingQuery({
    showNotification,
    company,
    projectId: selectedProject?.projectId || "",

    onSuccessDeleteCb: onSuccessDelete,
  });

  const handleDeleteDrawing = () => {
    if (selectedDrawingForDelete) {
      deleteDrawing.mutate([selectedDrawingForDelete.setId!]);
      handleRemoveMultiDrawings([selectedDrawingForDelete.setId!]);
    }
  };

  const handleCloseDeleteDialog = () => setSelectedDrawingForDelete(undefined);

  return {
    handleDeleteDrawing,
    handleCloseDeleteDialog,
    isDeletingDrawing: deleteDrawing.isLoading,
  };
};

export default useDrawingDeleteConfirmDialog;
