import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDoxleQuoteStore } from "../../../store/useDoxleQuoteStore";
import { shallow } from "zustand/shallow";
import { useDoxleAuthStore } from "../../../../DoxleGeneralStore/useDoxleAuthStore";
import { useDoxleCurrentContextStore } from "../../../../DoxleGeneralStore/useDoxleCurrentContext";
import useDoxleNotificationStore from "../../../../DoxleGeneralStore/useDoxleNotificationStore";
import QuotesQueryAPI from "../../../QueryAPI/quotesQuery";
import { useEditDocketSideScreenStore } from "../../../../CoreContent/EditDocketSideScreen/EditDocketSideScreenStore/useEditDocketSideScreenStore";

type Props = {};
interface DeleteSelectedQuotes {
  showDialogDelete: boolean;

  handleDeleteSelectedQuotes: () => void;
  handleCloseDeleteDialog: () => void;
}
const useDeleteSelectedQuotes = (props: Props): DeleteSelectedQuotes => {
  const [showDialogDelete, setShowDialogDelete] = useState(false);

  
  const company = useDoxleCurrentContextStore(
    (state) => state.currentCompany,
    shallow
  );
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );
  const { setQuoteDeleteBtnFn } = useEditDocketSideScreenStore(
    (state) => ({
      setQuoteDeleteBtnFn: state.setQuoteDeleteBtnFn,
      setEmailQuoteBtnFn: state.setEmailQuoteBtnFn,
    }),
    shallow
  );
  const {
    filterGetQuoteRequest,
    filterGetQuoteResponse,
    quoteRequestIds,
    quoteResponseIds,
    setQuoteRequestIds,
    setQuoteResponseIds,
  } = useDoxleQuoteStore(
    (state) => ({
      filterGetQuoteRequest: state.filterGetQuoteRequest,
      filterGetQuoteResponse: state.filterGetQuoteResponse,
      quoteRequestIds: state.quoteRequestIds,
      quoteResponseIds: state.quoteResponseIds,
      setQuoteRequestIds: state.setQuoteRequestIds,
      setQuoteResponseIds: state.setQuoteResponseIds,
    }),
    shallow
  );

  const deleteRqQuery = QuotesQueryAPI.useDeleteMultipleQuoteRequests({

    company,
    filter: filterGetQuoteRequest,
    showNotification,
  });

  const deleteResponseQuery = QuotesQueryAPI.useDeleteMultipleQuoteResponse({

    company,
    filter: filterGetQuoteResponse,
    showNotification,
  });

  const handleDeleteSelectedQuotes = () => {
    setShowDialogDelete(false);
    if (quoteRequestIds.length > 0) deleteRqQuery.mutate(quoteRequestIds);
    if (quoteResponseIds.length > 0)
      deleteResponseQuery.mutate(quoteResponseIds);
    setQuoteRequestIds([]);
    setQuoteResponseIds([]);
  };

  const handleToggleDeleteDialog = useCallback(() => {
    if (quoteRequestIds.length > 0 || quoteResponseIds.length > 0)
      setShowDialogDelete(true);
  }, [quoteRequestIds, quoteResponseIds]);
  const totalDeleteItems = useMemo(
    () => quoteRequestIds.length + quoteResponseIds.length,
    [quoteRequestIds, quoteResponseIds]
  );
  const handleCloseDeleteDialog = () => setShowDialogDelete(false);

  //* USE EFFECT TO CONTROL SETTING FUNCTION FOR DELETE AND EMAIL BTN
  useEffect(() => {
    setQuoteDeleteBtnFn({
      btnFunction: handleToggleDeleteDialog,
      btnText: `Delete${totalDeleteItems > 0 ? ` (${totalDeleteItems})` : ""}`,
      isProcessing: deleteRqQuery.isLoading || deleteResponseQuery.isLoading,
    });
  }, [
    handleToggleDeleteDialog,
    totalDeleteItems,
    deleteRqQuery.isLoading,
    deleteResponseQuery.isLoading,
  ]);
  useEffect(() => {
    return () => setQuoteDeleteBtnFn(undefined);
  }, []);

  return {
    showDialogDelete,
    handleDeleteSelectedQuotes,
    handleCloseDeleteDialog,
  };
};

export default useDeleteSelectedQuotes;
