import { useEffect, useMemo } from "react";

import { shallow } from "zustand/shallow";

import { useProjectQAStore } from "../../../Store/useProjectQAStore";
import { QAList } from "../../../../Models/qa";
import useDoxleNotificationStore from "../../../../../DoxleGeneralStore/useDoxleNotificationStore";
import { useDoxleAuthStore } from "../../../../../DoxleGeneralStore/useDoxleAuthStore";
import { useDoxleCurrentContextStore } from "../../../../../DoxleGeneralStore/useDoxleCurrentContext";
import QAQueryAPI, {
  FilterGetQAListQuery,
} from "../../../../QueryAPI/qaQueryAPI";
import dayjs from "dayjs";

type Props = {};

interface ProjectQAList {
  qaList: QAList[];
  isSuccessFetchingQAList: boolean;
  isErrorFetchingQAList: boolean;
  isFetchingQAList: boolean;

  handleRefetchQAList: () => void;
  isRefetchingQAList: boolean;
  fetchMoreQAList: () => void;
  isFetchingMoreQAList: boolean;
}
const useGetProjectQAList = (props: Props): ProjectQAList => {
  const { setFilterQAListQuery } = useProjectQAStore(
    (state) => ({
      setFilterQAListQuery: state.setFilterQAListQuery,
    }),
    shallow
  );
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );
  const { company, currentProject, currentSearchText } =
    useDoxleCurrentContextStore(
      (state) => ({
        currentProject: state.currentProject,
        company: state.currentCompany,
        currentSearchText: state.currentSearchText,
      }),
      shallow
    );
  const filterQAListQuery: FilterGetQAListQuery = useMemo(
    () => ({
      projectId: currentProject?.projectId || "",
      searchText: currentSearchText,
      orderBy: ['completed', '-created_on']
    }),
    [currentProject, currentSearchText]
  );
  //* useefect to update filter getting qa list to store
  useEffect(() => {
    setFilterQAListQuery(filterQAListQuery);
  }, [filterQAListQuery]);

  const getServerQAListQuery = QAQueryAPI.useRetrieveQAListQuery({
    company,
    showNotification,
    filter: filterQAListQuery,
    enableQuery: Boolean(currentProject),
  });

  const qaList: QAList[] = useMemo(
    () =>
      getServerQAListQuery.isSuccess
        ? getServerQAListQuery.data.pages
            .reduce((acc, data) => {
              return acc.concat(data.data.results);
            }, [] as QAList[])
            // .sort((a, b) => {
            //   // 1. Prioritize completed status:
            //   if (a.completed && !b.completed) {
            //     return 1; // a goes after b (completed goes down)
            //   } else if (!a.completed && b.completed) {
            //     return -1; // a goes before b (not completed goes up)
            //   }
            //
            //   // 2. If both have same completed status, sort by createdOn descending:
            //   const dateA = dayjs(a.createdOn);
            //   const dateB = dayjs(b.createdOn);
            //   return dateB.isBefore(dateA) ? -1 : 1; // Descending based on createdOn
            // })
        : [],
    [getServerQAListQuery.data]
  );

  const handleRefetchQAList = () => {
    getServerQAListQuery.refetch();
  };
  const fetchMoreQAList = () => {
    if (getServerQAListQuery.hasNextPage && !getServerQAListQuery.isLoading)
      getServerQAListQuery.fetchNextPage();
  };

  return {
    qaList,
    isSuccessFetchingQAList: getServerQAListQuery.isSuccess,
    isErrorFetchingQAList: getServerQAListQuery.isError,
    isFetchingQAList: getServerQAListQuery.isLoading,

    handleRefetchQAList,
    isRefetchingQAList: getServerQAListQuery.isRefetching,
    fetchMoreQAList,
    isFetchingMoreQAList: getServerQAListQuery.isFetchingNextPage,
  };
};

export default useGetProjectQAList;
