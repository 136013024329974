import { produce } from "immer";
import { ContactCompanyFilters } from "../../Services/QueryHooks/contactsFilters";
import { ContactCompany } from "../../Models/addressBook";
import { useQueryClient } from "@tanstack/react-query";
import { useDoxleCurrentContextStore } from "../../DoxleGeneralStore/useDoxleCurrentContext";
import { formContactCompanyListQKey } from "../../Services/QueryHooks/contactsAPI";

type Props = {
  filter: ContactCompanyFilters;
  addPos?: "start" | "end";
  overwrite?: boolean;
};

interface SetCompanyContactQueryData {
  handleAddNewCompanyContact: (addedContact: ContactCompany) => void;
  handleDeleteCompanyContact: (deletedContactId: string) => void;
  handleEditCompanyContact: (edittedContact: ContactCompany) => void;
}
const useSetCompanyContactQueryData = ({
  filter,
  addPos = "start",
  overwrite = true,
}: Props): SetCompanyContactQueryData => {
  const queryClient = useQueryClient();

  const { company } = useDoxleCurrentContextStore((state) => ({
    company: state.currentCompany,
  }));
  const qKey = formContactCompanyListQKey(filter, company);
  const handleAddNewCompanyContact = (addedContact: ContactCompany) => {
    const queryData = queryClient.getQueryData(qKey);
    if (queryData)
      queryClient.setQueryData(qKey, (old: any) => {
        const pageLength = old.pages.length;

        return produce(old, (draftOld: any) => {
          if (addPos === "start")
            (draftOld.pages[0].data.results as any[]).unshift(addedContact);
          else draftOld.pages[pageLength - 1].data.results.push(addedContact);
        });
      });
    else queryClient.invalidateQueries(qKey);
  };

  const handleDeleteCompanyContact = (deletedContactId: string) => {
    const oldServerData = queryClient.getQueryData(qKey);
    if (oldServerData && overwrite) {
      queryClient.setQueryData(qKey, (old: any) => {
        //find page contain deleted item
        let pageContainGroup: number = (old.pages as Array<any>).findIndex(
          (page) =>
            (page.data.results as ContactCompany[]).find(
              (contact) => contact.contactCompanyId === deletedContactId
            )
        );
        if (pageContainGroup !== -1) {
          const targetOrderItemIndex = (
            (old.pages as Array<any>)[pageContainGroup].data
              .results as ContactCompany[]
          ).findIndex(
            (contact) => contact.contactCompanyId === deletedContactId
          );
          return produce(old, (draftOld: any) => {
            draftOld.pages = produce(draftOld.pages, (draftPages: any) => {
              draftPages[pageContainGroup].data.results = produce(
                draftPages[pageContainGroup].data.results,
                (draftTargetPageData: ContactCompany[]) => {
                  draftTargetPageData.splice(targetOrderItemIndex, 1);

                  return draftTargetPageData;
                }
              );
              return draftPages;
            });

            return draftOld;
          });
        } else queryClient.invalidateQueries(qKey);
      });
    } else queryClient.invalidateQueries(qKey);
  };

  const handleEditCompanyContact = (edittedContact: ContactCompany) => {
    const oldServerData = queryClient.getQueryData(qKey);

    if (oldServerData && overwrite) {
      queryClient.setQueryData(qKey, (old: any) => {
        //find page contain deleted item
        let pageContainGroup: number = (old.pages as Array<any>).findIndex(
          (page) =>
            (page.data.results as ContactCompany[]).find(
              (contact) =>
                contact.contactCompanyId === edittedContact.contactCompanyId
            )
        );
        if (pageContainGroup !== -1) {
          const targetOrderItemIndex = (
            (old.pages as Array<any>)[pageContainGroup].data
              .results as ContactCompany[]
          ).findIndex(
            (contact) =>
              contact.contactCompanyId === edittedContact.contactCompanyId
          );
          return produce(old, (draftOld: any) => {
            draftOld.pages = produce(draftOld.pages, (draftPages: any) => {
              draftPages[pageContainGroup].data.results = produce(
                draftPages[pageContainGroup].data.results,
                (draftTargetPageData: ContactCompany[]) => {
                  const item = draftTargetPageData[targetOrderItemIndex];
                  if (item) {
                    Object.assign(item, edittedContact);
                  }

                  return draftTargetPageData;
                }
              );
              return draftPages;
            });

            return draftOld;
          });
        } else queryClient.invalidateQueries(qKey);
      });
    } else queryClient.invalidateQueries(qKey);
  };
  return {
    handleAddNewCompanyContact,
    handleDeleteCompanyContact,
    handleEditCompanyContact,
  };
};

export default useSetCompanyContactQueryData;
