import React, { useEffect, useMemo, useState } from "react";
import { Contact } from "../../../Models/addressBook";
import { useDoxleCurrentContextStore } from "../../../DoxleGeneralStore/useDoxleCurrentContext";
import { ContactsFilters } from "../../../Services/QueryHooks/contactsFilters";
import { shallow } from "zustand/shallow";
import ContactsAPI from "../../../Services/QueryHooks/contactsAPI";

type Props = {};

interface ContactPopoverList {
  searchTextQuery: string;
  setSearchTextQuery: React.Dispatch<React.SetStateAction<string>>;
  contactList: Contact[];
  isFetchingContactList: boolean;
  onAddContactMode: boolean;
  setOnAddContactMode: React.Dispatch<React.SetStateAction<boolean>>;
  fetchNextContactList: () => void;
  isFetchingNextContactList: boolean;
  shouldShowContactList: boolean;
  setShouldShowContactList: React.Dispatch<React.SetStateAction<boolean>>;
}
const useContactPopoverList = (props: Props): ContactPopoverList => {
  const [onAddContactMode, setOnAddContactMode] = useState(false);
  const [searchInput, setSearchInput] = useState<string>("");
  const [searchTextQuery, setSearchTextQuery] = useState<string>("");
  const [shouldShowContactList, setShouldShowContactList] = useState(false);
  const company = useDoxleCurrentContextStore(
    (state) => state.currentCompany,
    shallow
  );
  const filterRetrieveContactListQuery: ContactsFilters = useMemo(
    () => ({
      search: searchInput,
    }),
    [searchInput]
  );

  const getContactQuery = ContactsAPI.useRetrieveContactsQuery({
    company,
    filter: filterRetrieveContactListQuery,
    enable: true,
  });
  const contactList: Contact[] = useMemo(
    () =>
      getContactQuery.isSuccess
        ? getContactQuery.data.pages.flatMap((page) => page.data.results)
        : [],
    [getContactQuery.data]
  );

  useEffect(() => {
    const timeout = setTimeout(() => setSearchInput(searchTextQuery), 400);

    return () => clearTimeout(timeout);
  }, [searchTextQuery]);

  const fetchNextContactList = () => {
    if (getContactQuery.hasNextPage) getContactQuery.fetchNextPage();
  };

  return {
    searchTextQuery,
    setSearchTextQuery,
    contactList,
    isFetchingContactList: getContactQuery.isLoading,
    onAddContactMode,
    setOnAddContactMode,
    fetchNextContactList,
    isFetchingNextContactList: getContactQuery.isFetchingNextPage,
    shouldShowContactList,
    setShouldShowContactList,
  };
};

export default useContactPopoverList;
