import { Box, Typography } from "@mui/material";

import { useDoxleThemeStore } from "../../../../DoxleGeneralStore/useDoxleThemeStore";
import { formatter } from "../../../../Utilities/FunctionUtilities";
import useGetEstimateList from "../hooks/useGetEstimateList";
import { useEffect, useMemo } from "react";
import { StyledBudgetEstimateListFooter } from "./StyledComponentEditDocketBudgetEstimate";
import { useAnimate } from "framer-motion";
type Props = {
  isScrolling: boolean;
};

const BudgetEstimateListFooter = ({ isScrolling }: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore((state) => ({
    doxleThemeColor: state.doxleThemeColor,
    doxleFont: state.doxleFont,
  }));

  const { budgetEstimateItemList } = useGetEstimateList({});

  const totalCost = useMemo(
    () =>
      budgetEstimateItemList.reduce(
        (total, estimateItem) =>
          total + parseFloat(estimateItem.lineCost.toString()),
        0
      ),
    [budgetEstimateItemList]
  );
  const [scope, animate] = useAnimate();
  useEffect(() => {
    if (isScrolling)
      animate(
        scope.current,
        { opacity: 0, y: 10 },
        { delay: 0.2, duration: 0.4 }
      );
    else
      animate(
        scope.current,
        { opacity: 1, y: 0 },
        { delay: 0.4, duration: 0.4 }
      );
  }, [isScrolling]);

  return (
    <StyledBudgetEstimateListFooter ref={scope}>
      <Typography
        fontSize={14}
        fontStyle={doxleFont.primaryTitleFont}
        width={"80%"}
        display={"flex"}
        justifyContent={"flex-end"}
      >
        # TOTAL
      </Typography>
      <Typography
        display={"flex"}
        justifyContent={"center"}
        width="20%"
        fontSize={14}
        fontFamily={doxleFont.primaryTitleFont}
        fontWeight={"600"}
      >
        {formatter.format(totalCost)}
      </Typography>
    </StyledBudgetEstimateListFooter>
  );
};

export default BudgetEstimateListFooter;
