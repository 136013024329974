import React from "react";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import {
  AddButtonIcon,
  AddFileIcon,
  AddFolderIcon,
} from "../Content/FileIcons";
import {
  StyledPopover,
  StyledPopoverItem,
  StyledPopoverSection,
} from "../Content/StyledFilesBodyComponent";
import { StyledAddButton } from "../Content/StyledFilesHeaderComponent";
import useFilePopover from "../Hooks/useFilePopover";
import DoxleAddNewButton from "../../DoxleDesignPattern/DoxleButtons/DoxleAddNewButton";

type Props = {
  overrideAnchorEl?: HTMLDivElement | HTMLButtonElement | null;
  overrideHandleClose?: React.Dispatch<
    React.SetStateAction<HTMLDivElement | HTMLButtonElement | null>
  >;
};

export default function FilePopover({
  overrideAnchorEl,
  overrideHandleClose,
}: Props) {
  const { doxleThemeColor } = useDoxleThemeStore((state) => ({
    doxleThemeColor: state.doxleThemeColor,
  }));
  const {
    getRootProps,
    addFileClicked,
    handleClick,
    handleClose,
    anchorEl,
    addFolderClicked,
    isAddingFolderOrFiles,
  } = useFilePopover({ overrideAnchorEl, overrideHandleClose });

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <DoxleAddNewButton
        onClick={handleClick}
        disabled={isAddingFolderOrFiles}
        iconOnly={true}
        isLoading={isAddingFolderOrFiles}
      />
      <StyledPopover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <StyledPopoverSection>
          <StyledPopoverItem onClick={addFolderClicked}>
            <AddFolderIcon
              themeColor={doxleThemeColor}
              containerStyle={{
                marginRight: "6px",
              }}
            />
            Add Folder
          </StyledPopoverItem>
          <StyledPopoverItem
            {...getRootProps({
              onClick: addFileClicked,
              style: {
                borderBottom: "none",
              },
            })}
          >
            <AddFileIcon
              themeColor={doxleThemeColor}
              containerStyle={{
                marginRight: "6px",
              }}
            />
            Add Files
          </StyledPopoverItem>
        </StyledPopoverSection>
      </StyledPopover>
    </>
  );
}
