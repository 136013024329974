import React from "react";
import useSheetDeleteConfirmDialog from "../Hooks/useSheetDeleteConfirmDialog";
import { useDrawingStore } from "../Store/useDrawingStore";
import { shallow } from "zustand/shallow";
import DoxleDialogHelper from "../../DoxleDesignPattern/DoxleDialogHelper/DoxleDialogHelper";
import { CircularProgress } from "@mui/material";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";

type Props = {};

const SheetDeleteConfirmDialog = (props: Props) => {
  const { handleDeleteSheet, handleCloseDeleteDialog, isDeletingSheet } =
    useSheetDeleteConfirmDialog({});
  const { selectedSheetForDelete } = useDrawingStore(
    (state) => ({
      selectedSheetForDelete: state.selectedSheetForDelete,
    }),
    shallow
  );
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }),
    shallow
  );

  const isDialogOpen = Boolean(selectedSheetForDelete);
  return (
    <DoxleDialogHelper
      open={isDialogOpen}
      onClose={handleCloseDeleteDialog}
      title="Confirm Delete Sheet!"
      description={`All data belong to sheet item ***${selectedSheetForDelete?.title}*** will be deleted permanently, are you sure to proceed?`}
      dialogButtons={[
        {
          buttonText: "Cancel",
          buttonFunction: () => handleCloseDeleteDialog,
          buttonColor: doxleThemeColor.primaryContainerColor,
          btnStyleSx: {
            border: `1px solid ${doxleThemeColor.primaryDividerColor}`,
          },
          buttonTextStyle: {
            color: doxleThemeColor.primaryFontColor,
          },
        },
        {
          buttonText: "Confirm",
          buttonFunction: handleDeleteSheet,
          btnStyleSx: {
            border: `1px solid ${doxleThemeColor.primaryDividerColor}`,
          },
        },
      ]}
    />
  );
};

export default SheetDeleteConfirmDialog;
