import React, { useState } from "react";
import { StyledQAMarkupToolBar } from "./StyledComponentQAMarkup";
import { useDoxleThemeStore } from "../../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import {
  TAnnotationColors,
  TAnnotationTools,
  useQAMarkupStore,
} from "./Store/useQAMarkupStore";
import DoxleIconButton from "../../../../DoxleDesignPattern/DoxleButtons/DoxleIconButton";
import { BiRectangle } from "react-icons/bi";
import { GoArrowUpRight } from "react-icons/go";
import { FaRegHandPointer } from "react-icons/fa6";
import { IoText } from "react-icons/io5";
import { LuCircle } from "react-icons/lu";
import { PiLineSegmentThin } from "react-icons/pi";
import { LuUndo2 } from "react-icons/lu";
import { PiEraserLight } from "react-icons/pi";
import { editRgbaAlpha } from "../../../../Utilities/FunctionUtilities";
type Props = {};

const TOOL_MENU: TAnnotationTools[] = [
  "Pointer",
  "Arrow",

  "Rectangle",
  //   "Text",
  "Circle",
  "StraightLine",
];
const COLOR_MENU: TAnnotationColors[] = [
  "#216BFF",
  "#FF7C04",
  "#FF2222",
  "#27BF36",
  "#5A36BE",
  "#FFBA35",
];
const QAMarkupToolBar = (props: Props) => {
  const [currentSubToolMenu, setcurrentSubToolMenu] = useState<
    "tool" | "color" | undefined
  >(undefined);
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }),
    shallow
  );
  const {
    currentTool,
    setCurrentTool,
    currentColor,
    setCurrentColor,
    clearAllMarkup,
    undoMarkup,
  } = useQAMarkupStore(
    (state) => ({
      currentTool: state.currentTool,
      setCurrentTool: state.setCurrentTool,
      currentColor: state.currentColor,
      setCurrentColor: state.setCurrentColor,
      clearAllMarkup: state.clearAllMarkup,
      undoMarkup: state.undoMarkup,
    }),
    shallow
  );
  return (
    <StyledQAMarkupToolBar $themeColor={doxleThemeColor} layout>
      <div className="main-tool-wrapper">
        <DoxleIconButton
          iconSource={
            currentTool === "Arrow" ? (
              <GoArrowUpRight
                size="3rem"
                color={doxleThemeColor.primaryFontColor}
              />
            ) : currentTool === "Circle" ? (
              <LuCircle
                size="2.5rem"
                color={doxleThemeColor.primaryFontColor}
              />
            ) : currentTool === "Pointer" ? (
              <FaRegHandPointer
                size="2.5rem"
                color={doxleThemeColor.primaryFontColor}
              />
            ) : currentTool === "Rectangle" ? (
              <BiRectangle
                size="2.5rem"
                color={doxleThemeColor.primaryFontColor}
              />
            ) : currentTool === "StraightLine" ? (
              <PiLineSegmentThin
                size="2.5rem"
                color={doxleThemeColor.primaryFontColor}
              />
            ) : (
              <IoText size="2.5rem" color={doxleThemeColor.primaryFontColor} />
            )
          }
          buttonWrapperStyle={{
            backgroundColor: doxleThemeColor.primaryContainerColor,
          }}
          sx={{
            "&:hover": {
              backgroundColor: `${doxleThemeColor.primaryContainerColor} !important`,
              opacity: `${0.8} !important`,
            },
          }}
          buttonSize={40}
          onClick={() => {
            if (currentSubToolMenu === "tool") setcurrentSubToolMenu(undefined);
            else setcurrentSubToolMenu("tool");
          }}
        />
        <DoxleIconButton
          iconSource={<></>}
          buttonWrapperStyle={{
            backgroundColor: currentColor,
          }}
          buttonSize={40}
          sx={{
            "&:hover": {
              backgroundColor: `${currentColor} !important`,
              opacity: `${0.8} !important`,
            },
          }}
          onClick={() => {
            if (currentSubToolMenu === "color")
              setcurrentSubToolMenu(undefined);
            else setcurrentSubToolMenu("color");
          }}
        />

        <DoxleIconButton
          iconSource={
            <LuUndo2 size="2rem" color={doxleThemeColor.primaryFontColor} />
          }
          buttonWrapperStyle={{
            backgroundColor: doxleThemeColor.primaryContainerColor,
          }}
          buttonSize={40}
          sx={{
            "&:hover": {
              backgroundColor: `${doxleThemeColor.primaryContainerColor} !important`,
              opacity: `${0.8} !important`,
            },
          }}
          onClick={() => {
            undoMarkup();
          }}
        />

        <DoxleIconButton
          sx={{
            "&:hover": {
              backgroundColor: `${doxleThemeColor.primaryContainerColor} !important`,
              opacity: `${0.8} !important`,
            },
          }}
          iconSource={
            <PiEraserLight
              size="2rem"
              color={doxleThemeColor.primaryFontColor}
            />
          }
          buttonWrapperStyle={{
            backgroundColor: doxleThemeColor.primaryContainerColor,
          }}
          buttonSize={40}
          onClick={() => {
            clearAllMarkup();
          }}
        />
      </div>
      {currentSubToolMenu === "tool" && (
        <div className="sub-tool-wrapper">
          {TOOL_MENU.map((tool, idx) => (
            <DoxleIconButton
              key={`${tool}#${idx}`}
              iconSource={
                tool === "Arrow" ? (
                  <GoArrowUpRight
                    size="3rem"
                    color={doxleThemeColor.primaryFontColor}
                  />
                ) : tool === "Circle" ? (
                  <LuCircle
                    size="2.5rem"
                    color={doxleThemeColor.primaryFontColor}
                  />
                ) : tool === "Pointer" ? (
                  <FaRegHandPointer
                    size="2.5rem"
                    color={doxleThemeColor.primaryFontColor}
                  />
                ) : tool === "Rectangle" ? (
                  <BiRectangle
                    size="2.5rem"
                    color={doxleThemeColor.primaryFontColor}
                  />
                ) : tool === "StraightLine" ? (
                  <PiLineSegmentThin
                    size="2.5rem"
                    color={doxleThemeColor.primaryFontColor}
                  />
                ) : (
                  <IoText
                    size="2.5rem"
                    color={doxleThemeColor.primaryFontColor}
                  />
                )
              }
              buttonWrapperStyle={{
                backgroundColor:
                  tool === currentTool
                    ? doxleThemeColor.primaryContainerColor
                    : "transparent",
              }}
              sx={{
                "&:hover": {
                  backgroundColor: `${editRgbaAlpha({
                    rgbaColor: doxleThemeColor.primaryContainerColor,
                    alpha: "0.8",
                  })} !important`,
                  opacity: `${0.8} !important`,
                },
              }}
              buttonSize={40}
              onClick={() => {
                setCurrentTool(tool);
                setcurrentSubToolMenu(undefined);
              }}
            />
          ))}
        </div>
      )}

      {currentSubToolMenu === "color" && (
        <div className="sub-tool-wrapper">
          {COLOR_MENU.map((color, idx) => (
            <DoxleIconButton
              key={`${color}#${idx}`}
              iconSource={<></>}
              buttonWrapperStyle={{
                backgroundColor: color,
              }}
              buttonSize={40}
              onClick={() => {
                setCurrentColor(color);
                setcurrentSubToolMenu(undefined);
              }}
            />
          ))}
        </div>
      )}
    </StyledQAMarkupToolBar>
  );
};

export default QAMarkupToolBar;
