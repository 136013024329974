import React from "react";

import { shallow } from "zustand/shallow";

import AddIcon from "@mui/icons-material/Add";
import { CircularProgress } from "@mui/material";
import { FilterRetrieveScopeItemQuery } from "../QueryAPI/scopeOfWorkQueryAPI";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import { ScopeOfWorkGroup } from "../Model/scopeOfWorks";
import { StyledAddScopeItemTextField } from "./StyledComponentScopeOfWorkFullScreen";
import { TRgbaFormat, editRgbaAlpha } from "../../Utilities/FunctionUtilities";
import useAddScopeItem from "../Hooks/useAddScopeItem";

type Props = {
  filterScopeItemQuery: FilterRetrieveScopeItemQuery;
  scopeGroup: ScopeOfWorkGroup;
};

const AddScopeItem = ({ filterScopeItemQuery, scopeGroup }: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }),
    shallow
  );
  const {
    handleAddScopeItem,
    newScopeItemTitle,
    setNewScopeItemTitle,
    onKeyDownDescriptionField,
    newTitleInputRef,
    isAddingItem,
  } = useAddScopeItem({ filterScopeItemQuery, scopeGroup });
  return (
    <StyledAddScopeItemTextField
      layout="position"
      placeholder="New"
      transition={{
        duration: 0.2,
        damping: 12,
      }}
      inputRef={newTitleInputRef}
      value={newScopeItemTitle}
      onChange={(
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      ) => setNewScopeItemTitle(event.target.value)}
      InputProps={{
        startAdornment: isAddingItem ? (
          <CircularProgress
            sx={{ color: doxleThemeColor.primaryFontColor, marginRight: "4px" }}
            size={"1.6rem"}
          />
        ) : (
          <AddIcon
            htmlColor={editRgbaAlpha({
              rgbaColor: doxleThemeColor.bolderDividerColor as TRgbaFormat,
              alpha: "0.2",
            })}
            style={{ marginRight: "4px", fontSize: "2rem" }}
          />
        ),
      }}
      $doxleFont={doxleFont}
      $themeColor={doxleThemeColor}
      onKeyDown={onKeyDownDescriptionField}
      multiline
    />
  );
};

export default React.memo(AddScopeItem);
