import {
  StyledDocketInfoContainer,
  StyledEditBookingConatiner,
  StyledEditBookingProject,
  StyledEditInfoWrapper,
} from "./StyledComponentEditBookingDocket";
import DoxleNavigateBackButton from "../../../../DoxleDesignPattern/DoxleButtons/DoxleNavigateBackButton";
import { IoChevronBackSharp } from "react-icons/io5";
import { useDoxleThemeStore } from "../../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import EditBookingTitle from "./EditBookingTitle";
import EditBookingDocketAndDate from "./EditBookingDocketAndDate";
import EditBookingPercentage from "./EditBookingPercentage";
import BookingDocketDetails from "../BookingDocketDetail/BookingDocketDetails";
import useEditBookingDocket from "./Hooks/useEditBookingDocket";
import { createContext, useContext, useEffect, useMemo, useRef } from "react";
import useRefComponentDimension from "../../../../CoreContent/UtilityHooks/useRefComponentDimension";
import useBookingViewStore from "../../../store/useBookingViewStore";
import EditBookingInputSection from "./EditBookingInputSection";
import EditBookingDocketPortrait from "./EditBookingDocketPortrait";
import { useEditDocketSideScreenStore } from "../../../../CoreContent/EditDocketSideScreen/EditDocketSideScreenStore/useEditDocketSideScreenStore";
import { IntRange } from "../../../../Models/IntRangeType";
import { StyledAddBookingButton } from "../AddBookingDocket/StyledComponentAddBookingDocket";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
type Props = {};
interface EditDocketBookingContextValue {
  bookingTitle: string;
  setBookingTitle: React.Dispatch<React.SetStateAction<string>>;
  startDate: Date | null;
  setStartDate: React.Dispatch<React.SetStateAction<Date | null>>;
  endDate: Date | null;
  setEndDate: React.Dispatch<React.SetStateAction<Date | null>>;
  percentageComplete: IntRange<0, 101>;
  setPercentageComplete: React.Dispatch<React.SetStateAction<IntRange<0, 101>>>;
}
const EditDocketBookingContext =
  createContext<EditDocketBookingContextValue | null>(null);
const EditBookingDocket = ({}: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
      currentTheme: state.currentTheme,
    }),
    shallow
  );
  const {
    handleClickBackBtn,
    bookingTitle,
    setBookingTitle,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    percentageComplete,
    setPercentageComplete,
    handleEditBooking,
  } = useEditBookingDocket({});

  const contextValue: EditDocketBookingContextValue = useMemo(
    () => ({
      bookingTitle,
      setBookingTitle,
      startDate,
      setStartDate,
      endDate,
      setEndDate,
      percentageComplete,
      setPercentageComplete,
    }),
    [bookingTitle, startDate, endDate, percentageComplete]
  );

  const BookingEditScreenRef = useRef<HTMLDivElement>(null);

  const { width, height } = useRefComponentDimension({
    componentRef: BookingEditScreenRef,
  });

  const { screenMode, setScreenMode } = useBookingViewStore(
    (state) => ({
      setScreenMode: state.setCurrentScreenDisplay,
      screenMode: state.currentScreenDisplay,
    }),
    shallow
  );

  useEffect(() => {
    if (width < 850) {
      setScreenMode("Portrait");
    } else {
      setScreenMode("Landscape");
    }
  }, [width]);

  return (
    <StyledEditBookingProject
      initial={{ y: 200 }}
      animate={{ y: 0 }}
      exit={{ y: 200 }}
      transition={{
        damping: 4,
      }}
    >
      <DoxleNavigateBackButton
        onClick={handleClickBackBtn}
        buttonText="Booking List"
        backIcon={
          <IoChevronBackSharp
            fontSize="1.6rem"
            color={doxleThemeColor.primaryFontColor}
          />
        }
        buttonWrapperStyle={{ alignSelf: "flex-start" }}
        buttonTextStyle={{color: doxleThemeColor.primaryFontColor}}
      />

      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <EditDocketBookingContext.Provider value={contextValue}>
          <StyledEditBookingConatiner
            $displayMode={screenMode}
            ref={BookingEditScreenRef}
          >
            {/* {screenMode === "Landscape" && (
          <>
            <StyledEditInfoWrapper $displayMode={screenMode}>
              <EditBookingInputSection />
            </StyledEditInfoWrapper>

            <StyledDocketInfoContainer $displayMode={screenMode}>
              <BookingDocketDetails mode="edit" />
            </StyledDocketInfoContainer>
          </>
        )}
        {screenMode === "Portrait" && <EditBookingDocketPortrait/>} */}
            <EditBookingDocketPortrait />
          </StyledEditBookingConatiner>
          <StyledAddBookingButton
            $themeColor={doxleThemeColor}
            $doxleFont={doxleFont}
            onClick={handleEditBooking}
          >
            Save
          </StyledAddBookingButton>
        </EditDocketBookingContext.Provider>
      </LocalizationProvider>
    </StyledEditBookingProject>
  );
};
export const useEditDocketBookingContext = () =>
  useContext(EditDocketBookingContext) as EditDocketBookingContextValue;
export default EditBookingDocket;
