import React, { useCallback, useEffect, useMemo } from "react";
import { ContactCompany } from "../../../Models/addressBook";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import { Popover } from "@mui/material";
import {
  StyledAddContractorButton,
  StyledContractorListScroller,
  StyledSearchContractorTextfield,
  StyledSearchContractorWrapper,
  StyledSelectContractorPopover,
} from "./StyledSelectContractorPopover";
import useSelectContractorPopover from "./Hooks/useSelectContractorPopover";
import { Components, ItemContent, Virtuoso } from "react-virtuoso";
import DoxleEmptyPlaceHolder from "../../../DoxleDesignPattern/DoxleEmptyPlaceHolder/DoxleEmptyPlaceHolder";
import ContractorItem from "./ContractorItem";
import { useEditOrderStore } from "../../OrdersStore/useEditOrderStore";
import ListLoadingMore from "../../../Utilities/Lottie/ListLoadingMore";
import AddContactForm from "./AddContactForm";

type Props = {
  anchorContractorEl: HTMLElement | null;

  handleClosePopoverContractor: () => void;
};

interface ContractorListContext {
  isFetchingCompanyList: boolean;
  isErrorFetchingCompanyList: boolean;
}
const SelectContractorPopover = ({
  anchorContractorEl,
  handleClosePopoverContractor,
}: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }),
    shallow
  );
  const { editedOrder } = useEditOrderStore(
    (state) => ({
      editedOrder: state.editedOrder,
    }),
    shallow
  );
  const {
    showAddContactForm,
    setShowAddContactForm,
    showContactList,
    setShowContactList,
    companiesList,
    isFetchingCompanyList,
    isSuccessFetchingCompanyList,
    isErrorFetchingCompanyList,
    searchText,
    setSearchText,
    handleFetchNextPage,
    isFetchingNExtPageCompanyList,
    handleSelectContractor,
  } = useSelectContractorPopover({ handleClosePopoverContractor });
  const canBeOpen = Boolean(anchorContractorEl);
  const id = canBeOpen ? "nb-status-menu-popper" : undefined;

  //* render list
  const components: Components<ContactCompany, ContractorListContext> = useMemo(
    () => ({
      Scroller: React.forwardRef((props, ref) => (
        <StyledContractorListScroller
          ref={ref}
          {...props}
          animate={{ y: [10, 0], opacity: [0, 1] }}
        />
      )),
      EmptyPlaceholder: (props) => (
        <DoxleEmptyPlaceHolder
          headTitleText={
            props.context?.isErrorFetchingCompanyList
              ? "Something wrong!"
              : "No contact found!"
          }
          subTitleText={
            isErrorFetchingCompanyList
              ? "Failed to get contacts, we are sorry for this!"
              : "Add more contacts to your list..."
          }
          containerBgColor="transparent"
          headTitleTextStyle={{
            fontSize: "18px",
          }}
          subTitleTextStyle={{
            fontSize: "14px",
          }}
        />
      ),
    }),
    []
  );

  const itemContent: ItemContent<ContactCompany, ContractorListContext> =
    useCallback(
      (_index, item, context) => {
        return (
          <ContractorItem
            key={`contractor_${item.contactCompanyId}`}
            contractor={item}
            selected={editedOrder?.contactCompany === item.contactCompanyId}
            handleSelectContractor={handleSelectContractor}
          />
        );
      },
      [editedOrder?.contactCompany, handleSelectContractor]
    );
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (anchorContractorEl) setShowContactList(true);
      else setShowContactList(false);
    }, 300);

    return () => clearTimeout(timeout);
  }, [anchorContractorEl]);
  const listStyle = {
    flex: 1,
    width: "100%",
  };
  return (
    <Popover
      id={id}
      open={canBeOpen}
      anchorEl={anchorContractorEl}
      disableScrollLock
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      onClose={handleClosePopoverContractor}
      sx={{
        "& .MuiPopover-paper": {
          backgroundColor: "transparent",
          overflow: "visible",
          boxShadow: "none",
        },
      }}
      transitionDuration={{ appear: 100, exit: 200 }}
      elevation={8}
    >
      <StyledSelectContractorPopover $themeColor={doxleThemeColor}>
        <StyledSearchContractorWrapper
          $themeColor={doxleThemeColor}
          $doxleFont={doxleFont}
        >
          {!showAddContactForm ? (
            <StyledSearchContractorTextfield
              $themeColor={doxleThemeColor}
              $doxleFont={doxleFont}
              variant="standard"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              placeholder="Search contractor..."
            />
          ) : (
            <span className="form-title">Add Contact</span>
          )}

          <StyledAddContractorButton
            $themeColor={doxleThemeColor}
            $doxleFont={doxleFont}
            onClick={() => {
              setShowAddContactForm((prev) => !prev);
              setSearchText("");
            }}
          >
            {!showAddContactForm ? "Add contact" : "Close"}
          </StyledAddContractorButton>
        </StyledSearchContractorWrapper>

        {!showAddContactForm && (
          <>
            {showContactList && (
              <Virtuoso
                style={listStyle}
                itemContent={itemContent}
                data={companiesList}
                components={components}
                context={{ isErrorFetchingCompanyList, isFetchingCompanyList }}
                endReached={handleFetchNextPage}
                atBottomThreshold={40}
              />
            )}
          </>
        )}

        {showAddContactForm && (
          <AddContactForm
            handleCloseForm={() => setShowAddContactForm(false)}
          />
        )}

        {isFetchingNExtPageCompanyList && (
          <ListLoadingMore
            animationSize={50}
            containerStyle={{
              bottom: 0,
              left: "50%",
              translate: "-50%",
              display: "flex",
              justifyContent: "center",
              position: "absolute",
              zIndex: 10,
            }}
          />
        )}
      </StyledSelectContractorPopover>
    </Popover>
  );
};

export default SelectContractorPopover;
