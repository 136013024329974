import { create } from "zustand";
import { QuoteTableView } from "../Content/QuotesPage";
import {
  IFilterQuoteRequestQuery,
  IFilterQuoteResponseQuery,
} from "../QueryAPI/quotesQuery";
import { immer } from "zustand/middleware/immer";
import { QuoteRequest, QuoteStorage } from "../models/quote";

interface DoxleQuoteStore {
  //---> control quote request query
  filterGetQuoteRequest: IFilterQuoteRequestQuery;
  setFilterGetQuoteRequest: (item: Partial<IFilterQuoteRequestQuery>) => void;

  //---> control quote response query
  filterGetQuoteResponse: IFilterQuoteResponseQuery;
  setFilterGetQuoteResponse: (item: Partial<IFilterQuoteResponseQuery>) => void;

  quoteResponseIds: string[];
  setQuoteResponseIds: (quotesIds: string[]) => void;

  quoteRequestIds: string[];
  setQuoteRequestIds: (quotesIds: string[]) => void;
  view: QuoteTableView;
  setView: React.Dispatch<React.SetStateAction<QuoteTableView>>;

  //*HANDLE POPOVER ATTACHMENT LIST
  attachmentPopperList: QuoteStorage[] | undefined;

  anchorAttachmentList: HTMLDivElement | null;
  handleOpenAttachmentPopper: (
    event: React.MouseEvent<HTMLDivElement>,
    attachmentList: QuoteStorage[]
  ) => void;
  handleCloseAttachmentPopper: () => void;
  //*<------

  //*handle review quote request
  reviewedQuoteRequest: QuoteRequest | undefined;
  setReviewedQuoteRequest: (item: QuoteRequest | undefined) => void;
  //*<---------

  anchorAddQuotesMenuPopover: HTMLElement | null;
  setAnchorAddQuotesMenuPopover: (
    event: React.MouseEvent<HTMLButtonElement|HTMLDivElement> | undefined
  ) => void;
  clearAnchorAddQuotesMenuPopover: () => void;

  onDeleteQuoteMode: boolean;
  setOnDeleteQuoteMode: (value: boolean) => void;
  resetQuoteStore: () => void;
}

export const useDoxleQuoteStore = create(
  immer<DoxleQuoteStore>((set, get) => ({
    //---> control quote request query
    filterGetQuoteRequest: {},
    setFilterGetQuoteRequest: (item: Partial<IFilterQuoteRequestQuery>) =>
      set((state) => {
        Object.assign(state.filterGetQuoteRequest, item);
      }),

    //---> control quote response query
    filterGetQuoteResponse: { userAdded: true },
    setFilterGetQuoteResponse: (item: Partial<IFilterQuoteResponseQuery>) =>
      set((state) => {
        const filterItem = state.filterGetQuoteResponse;
        Object.assign(filterItem, item);
      }),

    quoteResponseIds: [],
    setQuoteResponseIds: (quotesIds: string[]) => {
      set((state) => {
        return {
          quoteResponseIds: quotesIds,
        };
      });
    },

    quoteRequestIds: [],
    setQuoteRequestIds: (quotesIds: string[]) => {
      set((state) => {
        return {
          quoteRequestIds: quotesIds,
        };
      });
    },
    view: "QuoteRequestsTable",
    setView: (view: React.SetStateAction<QuoteTableView>) => {
      set((state) => {
        return {
          view: typeof view === "function" ? view(state.view) : view,
        };
      });
    },

    //*handle review quote request
    reviewedQuoteRequest: undefined,
    setReviewedQuoteRequest: (item: QuoteRequest | undefined) =>
      set({ reviewedQuoteRequest: item }),
    //*<---------

    attachmentPopperList: undefined,

    anchorAttachmentList: null,
    handleOpenAttachmentPopper: (
      event: React.MouseEvent<HTMLDivElement, MouseEvent>,
      attachmentList: QuoteStorage[]
    ) => {
      event.stopPropagation();
      event.preventDefault();
      return set({
        anchorAttachmentList: event.currentTarget,
        attachmentPopperList: attachmentList,
      });
    },
    handleCloseAttachmentPopper: () =>
      set({
        attachmentPopperList: undefined,
        anchorAttachmentList: null,
      }),

    anchorAddQuotesMenuPopover: null,
    setAnchorAddQuotesMenuPopover: (
      event: React.MouseEvent<HTMLButtonElement|HTMLDivElement> | undefined
    ) =>
      set({ anchorAddQuotesMenuPopover: event ? event.currentTarget : null }),
    clearAnchorAddQuotesMenuPopover: () =>
      set((state) => {
        state.anchorAddQuotesMenuPopover = null;
      }),

    onDeleteQuoteMode: false,
    setOnDeleteQuoteMode: (value: boolean) => set({ onDeleteQuoteMode: value }),

    resetQuoteStore: () =>
      set((state) => {
        state.filterGetQuoteRequest = {};
        state.filterGetQuoteResponse = {};
        state.quoteResponseIds = [];
        state.quoteRequestIds = [];
        state.attachmentPopperList = undefined;
        state.anchorAttachmentList = null;
        state.anchorAddQuotesMenuPopover = null;
        state.view = "QuoteRequestsTable";
      }),
  }))
);
