import React, { useCallback, useEffect } from "react";
import { useEditDocketSideScreenStore } from "../../../../CoreContent/EditDocketSideScreen/EditDocketSideScreenStore/useEditDocketSideScreenStore";
import { shallow } from "zustand/shallow";
import { useProjectStore } from "../../../../DoxleGeneralStore/useprojectStore";
import { useNoteStore } from "../../../Store/useNoteStore";
import { NoteQueryAPI, getNoteMutateKey } from "../../../QueryAPI/notesQueryAPI";
import { useDoxleCurrentContextStore } from "../../../../DoxleGeneralStore/useDoxleCurrentContext";
import useDoxleNotificationStore from "../../../../DoxleGeneralStore/useDoxleNotificationStore";
import { useIsMutating } from "@tanstack/react-query";

type Props = {};
interface INoteList {
  handleAddNote: () => void;
  isAddingNote: boolean;
}
const useNoteList = (props: Props): INoteList => {
  const { company, currentProject } = useDoxleCurrentContextStore(
    (state) => ({
      company: state.currentCompany,
      currentProject: state.currentProject,
    }),
    shallow
  );
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification
  );
  const { edittedDocket, setHandleAddButtonFn } = useEditDocketSideScreenStore(
    (state) => ({
      edittedDocket: state.edittedDocket,
      setHandleAddButtonFn: state.setHandleAddButtonFn,
    }),
    shallow
  );
  const setProjectAddButtonFn = useProjectStore(
    (state) => state.setFunctionNewButton,
    shallow
  );
  const { filterNoteQuery, resetNoteStore, noteScope } = useNoteStore(
    (state) => ({
      filterNoteQuery: state.filterNoteQuery,
      resetNoteStore: state.resetNoteStore,
      noteScope: state.noteScope,
    }),
    shallow
  );

  const { add } = NoteQueryAPI.useMutateNoteQuery({
    company,
    filter: filterNoteQuery,
    showNotification,
  });

  const handleAddNote = useCallback(() => {
    if (!add.isLoading)
      add.mutate({
        title: "Untitled Note",
        docket: noteScope === "Docket" ? edittedDocket?.docketPk : undefined,
        project:
          noteScope === "Project" ? currentProject?.projectId : undefined,
      });
  }, [noteScope, edittedDocket, currentProject, add.isLoading]);

  // const isAddingItem = useIsMutating({mutationKey: getNoteMutateKey("add")});

  useEffect(() => {
    if (noteScope === "Project") {
      setProjectAddButtonFn([
        {
          btnFunction: handleAddNote,
          btnText: "",
          isLoading: add.isLoading,
        },
      ]);
    } else {
      setHandleAddButtonFn([
        { btnFunction: handleAddNote, tooltipText: "Add quick note", isLoading: add.isLoading },
      ]);
    }
  }, [setHandleAddButtonFn, noteScope, handleAddNote, setProjectAddButtonFn, add.isLoading]);

  useEffect(() => {
    return () => {
      setHandleAddButtonFn([]);
      setProjectAddButtonFn([]);
    };
  }, []);

  return { handleAddNote, isAddingNote: add.isLoading };
};

export default useNoteList;
