import {Grid, IconButton, InputAdornment, TextField} from "@mui/material";
import {useState} from "react";
import ReplayIcon from '@mui/icons-material/Replay';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import React from "react";
import {useMeasurementsStore} from "../MeasurementsStore/useMeasurementsStore";
import {shallow} from "zustand/shallow";
import {toFloat} from "../konvaFunctions/generalFunctions";
import DoxleCheckbox from "../../DoxleDesignPattern/DoxleCheckbox/DoxleCheckbox";
import DrawingsQueryAPI from "../Hooks/DrawingsQueryAPI";
import {ICONS} from "../Assets/icons"

interface props{
}

const ScaleBar: React.FC<props> = ({}) => {
  const [scaleAll, setScaleAll] = useState<boolean>(false);
  const {
    currentSheet,
    setScalingMode,
    setCurrentShape,
    scaleLength,
    setScaleLength,
    scaleLine,
    setScaleLine
  } = useMeasurementsStore(state => ({
    currentSheet: state.currentSheet,
    setScalingMode: state.setIsScaling,
    currentShape: state.currentShape,
    setCurrentShape: state.setCurrentShape,
    scaleLength: state.userDefinedScaleLength,
    setScaleLength: state.setUserDefinedScaleLength,
    scaleLine: state.scaleLine,
    setScaleLine: state.setScaleLine
  }), shallow)

  const handleUndo = (e:any) => {
    setCurrentShape(null);
    setScaleLine(null);
  }

  const handleCancel = (e:any) => {
    setCurrentShape(null);
    setScaleLine(null);
    setScalingMode(false);
  }

  const updateSheets = DrawingsQueryAPI.useUpdateSheet()
  const updateDrawingsScale = DrawingsQueryAPI.useUpdateDrawingsSet()

  const handleSave = async (e:any) => {
    if (!scaleLine) return;
    setScalingMode(false);
    const scale = scaleLength / toFloat(scaleLine.rawValue) / 1000;
    if (!scaleAll) await updateSheets.mutate({id: currentSheet?.sheetId ?? "", scale});
    else await updateDrawingsScale.mutate({id: currentSheet?.drawingSet ?? "", scale});
    setCurrentShape(null);
    setScaleLine(null);
    setScalingMode(false);
  }

  return(
    <div className="measurement-tools">
      <Grid container>
        {
          !scaleLine
            ?
            <Grid item xs={12}
                  style={{
                    textAlign: "center",
                    color: "red",
                    fontFamily: "Roboto"
                  }}
            >
              <img src={ICONS.RulerAlert} style={{ position: "relative", top: "2px"}} /> &nbsp;
              Draw a line of known length (Longer lines help the accuracy of the scale) &nbsp;
              <img src={ICONS.Ruler} style={{ position: "relative", top: "2px"}} />
            </Grid>
            :
            <>
              <Grid
                item xs={3}
                style={{
                  marginTop: "5px",
                  textAlign: "right",
                  color: "red",
                  paddingRight: "50px",
                  fontFamily: "Roboto"
                }}
              >
                Enter the line length
              </Grid>
              <Grid item xs={3} style={{alignItems: "center"}}>
                <TextField
                  type={"number"}
                  value={scaleLength}
                  variant={"standard"}
                  onChange={(e:any) => {setScaleLength(parseFloat(e.target.value))}}
                  InputProps={{
                    disableUnderline: true,
                    endAdornment: <InputAdornment position="end">mm</InputAdornment>,
                  }}
                />
              </Grid>
              <Grid item xs={3}
                    style={{
                      marginTop: "5px",
                      textAlign: "center",
                      color: "red",
                      paddingRight: "50px",
                      fontFamily: "Roboto"
                    }}
              >
                Scale All Sheets &nbsp;
                <DoxleCheckbox
                  style={{marginTop: "-5px"}}
                  checked={scaleAll}
                  onClick={(e:any) => {setScaleAll(e.target.checked)}}
                />
              </Grid>
              <Grid item xs={3}>
                <IconButton
                  style={{
                    color: "#a584fd"
                  }}
                  onClick={handleUndo}
                >
                  <ReplayIcon/>
                </IconButton>
                <IconButton
                  style={{
                    color: "#a584fd"
                  }}
                  onClick={handleCancel}
                >
                  <CloseIcon/>
                </IconButton>
                <IconButton
                  style={{
                    color: "#a584fd"
                  }}
                  onClick={handleSave}
                >
                  <DoneIcon/>
                </IconButton>
              </Grid>
            </>

        }
      </Grid>
    </div>
  )
}

export default ScaleBar
