import React from "react";
import { StyledBookingDateHeader } from "./StyledBookingCalendar";
import dayjs from "dayjs";
import isToday from "dayjs/plugin/isToday";
dayjs.extend(isToday);
type Props = {
  date: Date;
};

const BookingDateHeader = ({ date }: Props) => {
  return (
    <StyledBookingDateHeader $isToday={dayjs(date).isToday()}>
      <span className="date-month-text">{dayjs(date).format("MMM DD")}</span>

      <span className="week-day-text">{dayjs(date).format("ddd")}</span>
    </StyledBookingDateHeader>
  );
};

export default BookingDateHeader;
