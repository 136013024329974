import React, { useCallback } from "react";
import { DropEvent, FileRejection } from "react-dropzone";
import { useAddQuoteResponseContext } from "../AddQuoteResponse";
import { produce } from "immer";

type Props = {};
interface AddResponseAttachments {
  onDrop: <T extends File>(
    acceptedFiles: T[],
    fileRejections: FileRejection[],
    event: DropEvent
  ) => void;
}
const useAddResponseAttachments = (props: Props): AddResponseAttachments => {
  const { setAddedAttachments } = useAddQuoteResponseContext();
  const onDrop = useCallback(
    <T extends File>(
      acceptedFiles: T[],
      fileRejections: FileRejection[],
      event: DropEvent
    ) => {
      setAddedAttachments((prev) =>
        produce(prev, (draft) => {
          draft.push(...acceptedFiles);

          return draft;
        })
      );
    },
    []
  );

  return { onDrop };
};

export default useAddResponseAttachments;
