import React, { useRef } from "react";
import {
  StyledDocketNameSection,
  StyledDocketNameTextField,
  StyledEditGeneralFieldLabel,
} from "./StyledComponentDocketInfo";
import useDocketNameSection from "./Hooks/useDocketNameSection";
import { useDoxleThemeStore } from "../../../../DoxleGeneralStore/useDoxleThemeStore";

type Props = {};

const DocketNameSection = (props: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore((state) => ({
    doxleThemeColor: state.doxleThemeColor,
    doxleFont: state.doxleFont,
  }));
  const { handleUpdateDocketName, newDocketName, setNewDocketName } =
    useDocketNameSection({});
  const inputRef = useRef<HTMLInputElement>(null);
  return (
    <StyledDocketNameSection
      layout="position"
      transition={{
        duration: 0.1,
        damping: 16,
      }}
    >
      <StyledEditGeneralFieldLabel
        $doxleFont={doxleFont}
        $themeColor={doxleThemeColor}
      >
        Docket Name
      </StyledEditGeneralFieldLabel>
      <StyledDocketNameTextField
        variant="standard"
        $themeColor={doxleThemeColor}
        $doxleFont={doxleFont}
        value={newDocketName}
        onChange={(e) => setNewDocketName(e.target.value)}
        onBlur={handleUpdateDocketName}
        inputRef={inputRef}
        multiline
        onKeyDown={(e) => {
          if (
            e.nativeEvent.key === "Enter" &&
            !(e.shiftKey || e.ctrlKey || e.metaKey || e.altKey)
          ) {
            e.preventDefault();
            inputRef.current?.blur();
          } else if (
            e.nativeEvent.key === "Enter" &&
            (e.ctrlKey || e.metaKey || e.altKey)
          ) {
            setNewDocketName((prev) => prev + "\n");
          } else if (e.nativeEvent.key === "Escape") {
            setNewDocketName("");
          }
        }}
        onFocus={(e) => e.currentTarget.select()}
      />
    </StyledDocketNameSection>
  );
};

export default DocketNameSection;
