import React, { useCallback, useContext, useMemo, useState } from "react";
import FileUpload from "../Components/FileUpload";
import ProgressSection from "../Components/ProgressSection";
import FloorplanPriceResult from "../Components/FloorplanPriceResult";


type TFloorplanFormData = {
  file: File | null;
  filePath: string;
  name: string;
  mobile: string;
  email: string;
  budget: number;
};

const emptyFloorplanFormData : TFloorplanFormData = 
{file: null, filePath: "", name: "", mobile: "", email:"", budget: -1};

type TUploadFloorplanContextValue = {
  stage: number;
  setStage: React.Dispatch<React.SetStateAction<number>>;
  floorplanFormData : TFloorplanFormData;
  setFloorplanFormData:  React.Dispatch<React.SetStateAction<TFloorplanFormData>>
}

const UPLOAD_STAGE = 1;
const PROCESS_STAGE = 2;
const RESULT_STAGE = 3;

const UploadFloorplanContext = React.createContext<TUploadFloorplanContextValue  | null>(null);

export default function UploadFloorPlan() {


  const [stage, setStage] = useState(UPLOAD_STAGE);
  const [floorplanFormData, setFloorplanFormData] = useState<TFloorplanFormData>(emptyFloorplanFormData);

  const contextValue: TUploadFloorplanContextValue = useMemo(
    () => ({
      stage,
      setStage,
      floorplanFormData,
      setFloorplanFormData,
    }),
    [stage]
  );

  if(stage === PROCESS_STAGE){
    return (
      <UploadFloorplanContext.Provider value={contextValue}>
      <ProgressSection/>
      </UploadFloorplanContext.Provider>
    );
    
  }else if(stage === RESULT_STAGE){
    return (
      <UploadFloorplanContext.Provider value={contextValue}>
      <FloorplanPriceResult/>
      </UploadFloorplanContext.Provider>
    );
  }

  return (
    <UploadFloorplanContext.Provider value={contextValue}>
    <FileUpload/>
    </UploadFloorplanContext.Provider>
  );
 
}

export const useUploadFloorplanContext = () =>
  useContext(UploadFloorplanContext) as TUploadFloorplanContextValue;

