import React, { useRef, useState } from "react";
import useDoxleNotificationStore from "../../../../../DoxleGeneralStore/useDoxleNotificationStore";
import { useDoxleAuthStore } from "../../../../../DoxleGeneralStore/useDoxleAuthStore";
import { shallow } from "zustand/shallow";
import { useDoxleCurrentContextStore } from "../../../../../DoxleGeneralStore/useDoxleCurrentContext";
import { useProjectQAStore } from "../../../Store/useProjectQAStore";
import QAQueryAPI from "../../../../QueryAPI/qaQueryAPI";
import { NEW_QA_COMMENT_TEMPLATE } from "../../../../Models/qa";

type Props = {};

interface QACommentInputsection {
  commentInputRef: React.RefObject<HTMLInputElement>;
  newCommentText: string;
  setNewCommentText: React.Dispatch<React.SetStateAction<string>>;
  handleKeydownInput: (e: React.KeyboardEvent<HTMLDivElement>) => void;
  handleAddCmt: () => void;
  isAddingCmt: boolean;
}
const useQACommentInputsection = (props: Props): QACommentInputsection => {
  const [newCommentText, setNewCommentText] = useState<string>("");
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );

  const { company } = useDoxleCurrentContextStore(
    (state) => ({
      company: state.currentCompany,
    }),
    shallow
  );
  const { selectedQAItem } = useProjectQAStore(
    (state) => ({
      selectedQAItem: state.selectedQAItem,
    }),
    shallow
  );
  const onSuccessAddCb = () => {
    setNewCommentText("");
  };

  const user = useDoxleAuthStore((state) => state.user, shallow);

  const addCmtQuery = QAQueryAPI.useAddQACommentQuery({
    showNotification,
    company,
    qaItem: selectedQAItem!,
    onSuccessCB: onSuccessAddCb,
  });

  const commentInputRef = useRef<HTMLInputElement>(null);

  const handleAddCmt = () => {
    if (newCommentText) {
      const newCmtData = NEW_QA_COMMENT_TEMPLATE({
        commentText: newCommentText,
        company: company?.companyId || "",
        defect: selectedQAItem?.defectId ?? "",
        author: user?.userId || "",
      });
      addCmtQuery.mutate(newCmtData);
    } else commentInputRef.current?.blur();
  };

  const handleKeydownInput = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (
      e.nativeEvent.key === "Enter" &&
      !(e.shiftKey || e.ctrlKey || e.metaKey || e.altKey)
    ) {
      e.preventDefault();
      handleAddCmt();
    } else if (
      e.nativeEvent.key === "Enter" &&
      (e.ctrlKey || e.metaKey || e.altKey)
    ) {
      setNewCommentText((prev) => prev + "\n");
    } else if (e.nativeEvent.key === "Escape") {
      setNewCommentText("");
      commentInputRef.current?.blur();
    }
  };
  return {
    commentInputRef,
    newCommentText,
    setNewCommentText,
    handleKeydownInput,
    handleAddCmt,
    isAddingCmt: addCmtQuery.isLoading,
  };
};

export default useQACommentInputsection;
