import { motion } from "framer-motion";
import styled from "styled-components";

import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  TextField,
} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import {
  DoxleFont,
  DoxleTheme,
  DoxleThemeColor,
} from "../../DoxleGeneralStore/useDoxleThemeStore";
import { TRgbaFormat, editRgbaAlpha } from "../../Utilities/FunctionUtilities";

export const StyledScopeOfWorksContainer = styled(motion.div)<{
  $themeColor: DoxleThemeColor;
}>`
  width: calc(100% - 1.6rem);
  height: calc(100% - 4rem);
  background-color: ${(p) => p.$themeColor.primaryBackgroundColor};
  padding: 0px 0.8rem;
  position: relative;
  margin: 2rem 0px;
  display: flex;
`;
export const StyledScopeGroupRowContainer = styled(motion.div)<{
  $themeColor: DoxleThemeColor;
}>`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 4rem;
`;
export const StyledScopeGroupTitleContainer = styled(motion.div)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-bottom: 8px;
  padding-top: 8px;
  border-radius: 4px;
  background-color: ${(p) =>
    editRgbaAlpha({
      rgbaColor: p.theme.color.doxleColor,
      alpha: "0.1",
    })};
  max-width: calc(100% - 8px - 2px);
  border-width: 1px;
  border-style: solid;
  padding-left: 8px;
  overflow: hidden;
  align-self: flex-start;
  position: relative;
  overflow: hidden;

  .title-text {
    visibility: hidden;
    position: absolute;
    color: blue;
    font-size: 14px;
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.6px;
    z-index: 0;
    border-radius: 6px;

    cursor: pointer;
    white-space: pre-wrap;
    overflow: hidden;
    flex-shrink: 1;
  }
  .title-text-wrapper {
    flex-shrink: 1;
    display: flex;
    align-items: center !important;
    overflow: hidden;
  }
  .toggle-menu-container {
    margin-left: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;

export const StyledAddScopeItemTextField = styled(motion(TextField))<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  && {
    border-bottom: 1px solid ${(p) => p.$themeColor.primaryDividerColor};
    width: calc(100%);
    padding: 7px 0px;
  }
  input {
    color: ${(p) => p.$themeColor.primaryFontColor};
    font-size: 13px;
    font-family: ${(p) => p.$doxleFont.primaryTitleFont};
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.65px;
    &::placeholder {
      color: ${(p) => p.$themeColor.bolderDividerColor};
      font-size: 13px;
      font-family: ${(p) => p.$doxleFont.primaryFont};
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.65px;
      padding: 0px !important;
    }
  }
  & .MuiInputBase-multiline {
    color: ${(p) => p.$themeColor.primaryFontColor};
    font-size: 13px;
    font-family: ${(p) => p.$doxleFont.primaryTitleFont};
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.65px;
  }
  & .MuiOutlinedInput-root {
    padding: 0px !important;
  }
  & .MuiOutlinedInput-input {
    padding: 0px !important;
  }
  & .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }
  &.MuiInputBase-root-MuiInput-root:before {
    border: none !important;
  }
`;
export const StyledScopeItemRowContainer = styled(motion.div)`
  position: relative;
  overflow: visible;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const StyledScopeItemRowDetailSection = styled(motion.div)<{
  $themeColor: DoxleThemeColor;
  $currentTheme: DoxleTheme;
  $transparentMode: boolean;
}>`
  width: calc(100% - 16px);
  min-height: 26px;
  padding: 4px 8px;
  display: flex;
  flex-direction: row;
  align-items: center;

  cursor: pointer;
  overflow: hidden;

  z-index: 1;

  .main-content-wrapper {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow: hidden;
    height: 100%;

    border-bottom: 1px solid ${(p) => p.$themeColor.primaryDividerColor};
  }
`;
export const StyledScopeItemRowCheckbox = styled(Checkbox)`
  && {
    margin-right: 8px;
    padding: 0px !important;
  }
`;

export const StyledScopeItemDescriptionText = styled(motion.div)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  color: ${(p) => p.$themeColor.primaryFontColor};
  font-size: 14px;
  font-family: ${(p) => p.$doxleFont.primaryFont};
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.7px;
  flex-shrink: 1;
  white-space: pre-wrap;

  cursor: text;
  word-break: break-word;

  display: flex;
  align-items: center;
`;
export const StyledScopeItemDescriptionTextField = styled(motion(TextField))<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  && {
    flex: 1;
  }
  input {
    color: ${(p) => p.$themeColor.primaryFontColor};
    font-size: 13px;
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.7px;

    &::placeholder {
      color: ${(p) => p.$themeColor.bolderDividerColor};
      font-size: max(1.4rem, 14px);
      font-family: ${(p) => p.$doxleFont.primaryFont};
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.7px;
    }
  }
  & .MuiInputBase-multiline {
    color: ${(p) => p.$themeColor.primaryFontColor};
    font-size: 13px;
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.7px;
  }
  & .MuiOutlinedInput-root {
    padding: 0px !important;
  }
  & .MuiOutlinedInput-input {
    padding: 0px !important;
  }
  & .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }
`;
export const StyledScopeItemImageSectionContainer = styled(motion.div)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  max-width: 200px;
  height: 33px;
  margin: 0px 4px;
  display: flex;
  align-items: center;
  position: relative;

  .image-count-text {
    color: ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.$themeColor.primaryFontColor as TRgbaFormat,
        alpha: "0.6",
      })};
    font-size: 12px;
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.7px;
    white-space: nowrap;
  }
`;

export const StyledEditSOWGroupTextFieldForm = styled(motion(FormControl))`
  && {
    display: flex;
    flex-direction: column;
  }
`;
export const StyledEditSOWGroupTextFieldLabel = styled.span<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  color: ${(p) =>
    editRgbaAlpha({
      rgbaColor: p.$themeColor.primaryFontColor as TRgbaFormat,
      alpha: "0.6",
    })};
  font-size: 12px;
  font-family: ${(p) => p.$doxleFont.primaryFont};
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.7px;
`;
export const StyledEditSOWGroupTextField = styled(TextField)``;

export const StyledScopeItemRowHoverMenu = styled(motion.div)`
  display: flex;
  flex-direction: row;

  align-items: center;
  height: 100%;
  margin-right: 0.4rem;
`;
export const StyledSOWGroupListScroller = styled.div`
  width: 100%;
  height: 100% !important;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

export const StyledScopeImageListItem = styled.img<{
  $themeColor: DoxleThemeColor;
}>`
  height: 80%;
  aspect-ratio: 1;
  border-radius: 2px;
  margin: 0px 2px;
  border-width: 1.5px;
  border-style: solid;
  border-color: transparent;
  transition: 0.2s ease all;
  &:hover {
    border-color: ${(p) => p.$themeColor.doxleColor};
    transition: 0.2s ease all;
  }
`;
export const StyledScopeImageDisplayerTooltip = styled(Tooltip)`
  && {
  }
`;
export const StyledEditSOWGroupTitle = styled(DialogTitle)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  && {
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-weight: 600;
    font-size: 1.4rem;
    border-bottom: 1px solid ${(p) => p.$themeColor.primaryDividerColor};
    color: ${(p) => p.$themeColor.primaryFontColor};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    position: relative;
    height: 2.75rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &.MuiDialogTitle-root {
    width: calc(100% - 2.8rem);
    padding: 0.7rem 1.4rem;
  }
`;

export const StyledEditSOWGroupDialog = styled(Dialog)<{
  $themeColor: DoxleThemeColor;
}>`
  && {
    display: flex;
    flex-direction: column;
  }
  & .MuiDialog-paper {
    background-color: ${(p) => p.$themeColor.primaryContainerColor};
    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 1.4rem 0px ${(p) => p.$themeColor.primaryBoxShadowColor};
    border-radius: 0px 0.9rem !important;
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
    width: 30vw;
    height: 30vh;
    min-width: 300px;
    min-height: 300px;
    max-height: 400px;
  }
`;
export const StyledEditSOWGroupDialogContent = styled(DialogContent)`
  && {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: calc(100% - 28px);
    height: calc(100% - 28px);
    padding: 14px !important;
    overflow: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
`;
export const StyledSOWGroupTitleTextfield = styled(TextField)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  && {
    min-width: 120px;
    flex-shrink: 1;
    z-index: 10;
  }
  input {
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-weight: 500;
    font-size: 13px;
    line-height: normal;
    letter-spacing: 0.075px;

    color: ${(p) => p.$themeColor.primaryFontColor};

    &::placeholder {
      font-family: ${(p) => p.$doxleFont.primaryFont};
      font-weight: 500;
      font-size: 13px;
      line-height: normal;
      letter-spacing: 0.075px;

      color: ${(p) =>
        editRgbaAlpha({
          rgbaColor: p.$themeColor.primaryFontColor,
          alpha: "0.4",
        })};
      opacity: 1 !important;
    }
  }

  textarea {
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-weight: 500;
    font-size: 13px;
    line-height: normal;
    letter-spacing: 0.075px;

    color: ${(p) => p.$themeColor.primaryFontColor};

    &::placeholder {
      font-family: ${(p) => p.$doxleFont.primaryFont};
      font-weight: 500;
      font-size: 13px;
      line-height: normal;
      letter-spacing: 0.075px;

      color: ${(p) =>
        editRgbaAlpha({
          rgbaColor: p.$themeColor.primaryFontColor,
          alpha: "0.4",
        })};
      opacity: 1 !important;
    }
  }
  & .MuiInput-root:after {
    border-bottom: none !important;
  }
  & .MuiInput-root:before {
    border-bottom: none !important;
  }
  & .MuiInput-root {
    border-bottom: none !important;
  }
  & .MuiInput-root:hover:not(.Mui-disabled, .Mui-error):before {
    border-bottom: none !important;
  }
  & .MuiInputLabel-root.Mui-focused {
    border-bottom: none !important;
  }
  & .MuiInputLabel-root {
    border-bottom: none !important;
  }
`;

export const StyledScopeGroupListWrapper = styled(motion.div)`
  width: 100%;
  height: 100%;
`;

export const StyledScopeItemDescriptionTooltip = styled(Tooltip)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>``;

export const StyledSOWGroupMenuBtn = styled(Button)`
  && {
    display: flex;
    margin: 0 4px;
    min-width: 20px !important;
  }
  &.MuiButton-root {
    background-color: transparent;
    padding: 0px !important;
  }
  &.MuiButtonBase-root {
    padding: 0px !important;
  }
  &.MuiButton-root:hover {
    background: transparent;
  }
`;
export const StyledScopeGroupAssigneeDisplay = styled(motion.div)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
  $currentTheme: DoxleTheme;
}>`
  display: flex;
  align-items: center;

  margin: 0px 8px;
  background-color: ${(p) =>
    editRgbaAlpha({
      rgbaColor: p.$themeColor.doxleColor,
      alpha: p.$currentTheme !== "dark" ? "0.15" : "0.7",
    })};
  border-radius: 4px;
  padding: 4px 8px;
  cursor: pointer;
  .title {
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-weight: 500;
    font-size: 12px;
    line-height: normal;
    letter-spacing: 0.075px;
    color: ${(p) => p.$themeColor.primaryFontColor};
    cursor: pointer !important;
    white-space: nowrap !important;
    margin-left: 4px;
  }
`;
export const StyledAssigneeListPopover = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  background-color: ${(p) => p.$themeColor.primaryContainerColor};
  width: max(20rem, 200px);
  min-height: 100px;
  border-radius: 8px;
  position: relative;
  padding: 10px;
  border: 1px solid ${(p) => p.$themeColor.primaryDividerColor};
  display: flex;
  flex-direction: column;

  .title {
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-weight: 600;
    font-size: 13px;
    line-height: normal;
    letter-spacing: 0.075px;
    color: ${(p) => p.$themeColor.primaryFontColor};
    margin-bottom: 8px;
  }

  .item {
    width: 100%;
    display: flex;
    align-items: center;

    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-weight: 400;
    font-size: 14px;
    line-height: normal;
    letter-spacing: 0.075px;
    color: ${(p) => p.$themeColor.primaryFontColor};
    padding: 8px 0px;

    .icon {
      margin-right: 8px;
    }
  }
`;
export const StyledScopeItemAssigneeDisplay = styled(motion.div)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
  $currentTheme: DoxleTheme;
}>`
  display: flex;
  align-items: center;

  margin: 0px 8px;

  border-radius: 4px;
  padding: 3px;
  cursor: pointer;

  .title {
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-weight: 500;
    font-size: 12px;
    line-height: normal;
    letter-spacing: 0.075px;
    color: ${(p) => p.$themeColor.primaryFontColor};
    cursor: pointer !important;
    white-space: nowrap !important;
    margin-left: 2px;
    text-transform: uppercase;
    border-radius: 50%;
  }

  .remaining-assignee-num {
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-weight: 500;
    font-size: 12px;
    line-height: normal;
    letter-spacing: 0.075px;
    color: ${(p) => p.$themeColor.primaryFontColor};
    cursor: pointer !important;
    white-space: nowrap !important;
    margin-left: 2px;
  }
`;
export const StyledErrorDroppedFileWarningContainer = styled(Dialog)<{
  $themeColor: DoxleThemeColor;
}>`
  && {
    background-color: ${(p) => p.$themeColor.primaryBackdropColor};
    box-shadow: 1px 1px 2px ${(p) => p.$themeColor.primaryBoxShadowColor};
  }
  & .MuiDialog-paper {
    background-color: ${(p) => p.$themeColor.primaryBackdropColor};
    box-shadow: 0px 0px 14px 0px ${(p) => p.$themeColor.primaryBoxShadowColor};
  }
`;
export const StyledErrorWarningTitle = styled(DialogTitle)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  && {
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-style: normal;
    font-weight: 600;
    font-size: 20px;

    color: ${(p) => p.$themeColor.primaryFontColor};
    border-bottom: 1px solid ${(p) => p.$themeColor.primaryDividerColor};
    background-color: ${(p) => p.$themeColor.primaryContainerColor};
    padding: 7px 14px !important;
  }
`;
export const StyledErrorWarningContent = styled(DialogContent)<{
  $themeColor: DoxleThemeColor;
}>`
  && {
    background-color: ${(p) => p.$themeColor.primaryContainerColor};
    box-shadow: 1px 1px 2px ${(p) => p.$themeColor.primaryBoxShadowColor};

    word-break: break-word;
    padding: 8px 14px !important;
  }
`;
export const StyledErrorWarningActions = styled(DialogActions)<{
  $themeColor: DoxleThemeColor;
}>`
  && {
    background-color: ${(p) => p.$themeColor.primaryContainerColor};
    box-shadow: 1px 1px 2px ${(p) => p.$themeColor.primaryBoxShadowColor};
  }
`;
export const StyledErrorActionButton = styled(motion(Button))<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  && {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0px 4px;
    border-radius: 4px !important;
    text-transform: capitalize;
    background-color: ${(p) => p.$themeColor.primaryFontColor};

    &:hover {
      background-color: ${(p) => p.$themeColor.primaryFontColor} !important;
      opacity: 0.9;
      transform: translateY(-1px) scale(1.05);
      transition: 0.4s;
    }
    .button-text {
      font-family: ${(p) => p.$doxleFont.primaryFont};
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: normal;
      letter-spacing: 0.05em;
      color: ${(p) => p.$themeColor.primaryContainerColor};
    }
  }
`;
export const StyledErrorFilesContainer = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  width: calc(100% - 16px);
  display: flex;
  align-items: center;
  padding: 8px;
  border-bottom: 1px solid ${(p) => p.$themeColor.primaryDividerColor};

  .error-wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-left: 14px;
  }
  .image-holder {
    width: 35px;
    aspect-ratio: 1;
  }
  .text {
    width: 100%;
    overflow: hidden;
    white-space: pre-wrap;

    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: normal;
    letter-spacing: 0.05em;
    color: ${(p) => p.$themeColor.primaryFontColor};
    margin-bottom: 2px;
  }
  .file-name {
    width: 100%;
    overflow: hidden;
    white-space: pre-wrap;

    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: normal;
    letter-spacing: 0.05em;
    color: ${(p) => p.$themeColor.primaryFontColor};
    margin-bottom: 5px;
  }
`;
