import {
  StyledAddStatusTextField,
  StyledInputAdornment,
} from "../styledComponents";
import React, { useState } from "react";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";

interface Props {
  addStatusFunction: (statusName: string) => void;
}
const AddStatusTextField = ({ addStatusFunction }: Props) => {
  const [statusName, setStatusName] = useState<string>("");
  const styleProps = useDoxleThemeStore((state) => ({
    $themeColor: state.doxleThemeColor,
    $doxleFont: state.doxleFont,
    $currentTheme: state.currentTheme,
  }));
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      addStatusFunction(statusName);
      setStatusName("");
    }
    if (e.key === "Excape") {
      setStatusName("");
    }
  };
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStatusName(e.currentTarget.value);
  };

  return (
    <StyledAddStatusTextField
      {...styleProps}
      onKeyDown={handleKeyDown}
      onChange={handleChange}
      value={statusName}
      placeholder={"Add Status"}
      InputProps={{
        startAdornment: (
          <StyledInputAdornment {...styleProps} position="start">
            +
          </StyledInputAdornment>
        ),
      }}
    />
  );
};
export default AddStatusTextField;
