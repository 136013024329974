import { shallow } from "zustand/shallow";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import {
  StyledAddAttachmentButton,
  StyledAddedAttachmentWrapper,
  StyledDialogActionBtnWrapper,
  StyledSMSContentWrapper,
  StyledSMSFieldWrapper,
  StyledSMSBookingDialog,
  StyledSMSBookingTitle,
  StyledSMSActionSubmitButton,
  StyledSMSBookingSubjectTextField,
  StyledSMSTextBodySection,
  StyledTextModeButton,
} from "./Components/StyledSMSComponents";
import { editRgbaAlpha } from "../../Utilities/FunctionUtilities";
import React, { createContext, useContext, useMemo, useRef } from "react";
import { TransitionProps } from "@mui/material/transitions";
import Slide from "@mui/material/Slide";
import { Contact } from "../../Models/addressBook";
import { useDoxleAuthStore } from "../../DoxleGeneralStore/useDoxleAuthStore";
import useSMSBooking from "./Hooks/useSMSBooking";
import { TISODate } from "../../Models/dateFormat";
import AddSMSToSection from "./Components/AddSMSToSection";
import DialogActions from "@mui/material/DialogActions";
import ProgressScreen from "../../Utilities/Lottie/ProgressScreen";

import {
  DoxleAlignModeIcon,
  DoxleBoldModeIcon,
  DoxleItalicModeIcon,
  DoxleQuoteModeIcon,
} from "../../DoxleDesignPattern/DoxleGeneralIcons";
import { IBooking } from "../Models/booking";

interface ISMSBookingContextValue {
  handleClickAddBtn: () => void;
  message: string;
  setMessage: React.Dispatch<React.SetStateAction<string>>;
  ballInCourt: Contact | undefined;
  setBallInCourt: React.Dispatch<React.SetStateAction<Contact | undefined>>;
}

const SMSBookingContext = createContext<ISMSBookingContextValue | null>(null);
const SMSBooking = () => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }),
    shallow
  );
  const messageRef = useRef<HTMLInputElement | null>(null);
  const user = useDoxleAuthStore((state) => state.user, shallow);

  const {
    handleClickAddBtn,
    ballInCourt,
    setBallInCourt,
    message,
    setMessage,
    isSendingSMS,
    booking,
    setBooking,
  } = useSMSBooking();

  const contextValue: ISMSBookingContextValue = {
    handleClickAddBtn,
    ballInCourt,
    setBallInCourt,
    message,
    setMessage,
  };

  return (
    <SMSBookingContext.Provider value={contextValue}>
      <StyledSMSBookingDialog
        $themeColor={doxleThemeColor}
        open={Boolean(booking)}
        onClose={() => {
          setBooking(undefined);
        }}
        slotProps={{
          backdrop: {
            sx: {
              bgcolor: editRgbaAlpha({
                rgbaColor: doxleThemeColor.primaryBackdropColor,
                alpha: "0.4",
              }),
            },
          },
        }}
        PaperProps={{
          elevation: 8,
        }}
        TransitionComponent={Transition}
        aria-describedby="add-mail-dialog-slide"
      >
        <StyledSMSBookingTitle
          $themeColor={doxleThemeColor}
          $doxleFont={doxleFont}
        >
          New SMS
        </StyledSMSBookingTitle>

        <StyledSMSContentWrapper
          $themeColor={doxleThemeColor}
          $doxleFont={doxleFont}
        >
          <StyledSMSFieldWrapper
            $themeColor={doxleThemeColor}
            $doxleFont={doxleFont}
          >
            <span className="label-field"> From:</span>

            {/*<div className="add-content-wrapper">*/}
            {/*    {user && (*/}
            {/*        <>*/}
            {/*            {user?.firstName} {user?.lastName}*/}
            {/*            <span className="email-text">{`< ${user?.email} >`}</span>*/}
            {/*        </>*/}
            {/*    )}*/}

            {/*    {!user && <span className="email-text">Unknown User</span>}*/}
            {/*</div>*/}
          </StyledSMSFieldWrapper>

          <AddSMSToSection />

          <StyledSMSTextBodySection onClick={() => messageRef.current?.focus()}>
            <StyledSMSBookingSubjectTextField
              $themeColor={doxleThemeColor}
              $doxleFont={doxleFont}
              value={message}
              onChange={(e) =>
                setMessage(
                  e.target.value.substring(
                    0,
                    Math.min(125, e.target.value.length)
                  )
                )
              }
              variant="standard"
              inputRef={messageRef}
              multiline={true}
            />
          </StyledSMSTextBodySection>
        </StyledSMSContentWrapper>

        <DialogActions
          sx={{
            width: "100%",
            flexDirection: "row",
            display: "flex",
            justifyContent: "space-between",
            padding: "0px !important",
          }}
        >
          <StyledDialogActionBtnWrapper>
            <StyledSMSActionSubmitButton
              $themeColor={doxleThemeColor}
              $doxleFont={doxleFont}
              $reverseColor={true}
              onClick={() => setBooking(undefined)}
            >
              <span className="btn-text">Cancel</span>
            </StyledSMSActionSubmitButton>

            <StyledSMSActionSubmitButton
              $themeColor={doxleThemeColor}
              $doxleFont={doxleFont}
              onClick={handleClickAddBtn}
            >
              <span className="btn-text">Send</span>
            </StyledSMSActionSubmitButton>
          </StyledDialogActionBtnWrapper>
        </DialogActions>

        {isSendingSMS && (
          <ProgressScreen
            progressType="send"
            hasBackDrop={true}
            progressText="Sending mail, please wait..."
            textStyle={{
              fontSize: "max(2rem,20px)",
              fontFamily: doxleFont.primaryFont,
              color: "white",
            }}
            containerStyle={{
              display: "flex",

              position: "absolute",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          />
        )}
      </StyledSMSBookingDialog>
    </SMSBookingContext.Provider>
  );
};

export const useSMSBookingContext = () =>
  useContext(SMSBookingContext) as ISMSBookingContextValue;
export default SMSBooking;

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
