import { PricebookSupplierRate } from "../../Models/Pricebook";
import {
  StyledDeleteIconButton,
  StyledEditPricebookInputAdornment,
  StyledSupplierName, StyledSupplierNameInput,
  StyledSupplierRateLineTextField,
  StyledWarningField,
  StyledWarningRow,
} from "../PricebookList/StyledComponents";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import DeleteIcon from "@mui/icons-material/Delete";
import { useShallow } from "zustand/react/shallow";
import { useEditPricebookItemContext } from "./EditPricebookItem";
import useEditSupplierRateLine from "./Hooks/useEditSupplierRateLine";
import Tooltip from "@mui/material/Tooltip";
import { DoxleMUITooltipSx } from "../../../DoxleDesignPattern/MUIOverwriteSx";
import { StyledDeleteRateLineBtn } from "./StyledAddEditPricebookItem";
import { DoxleDeleteIcon } from "../../../DoxleDesignPattern/DoxleGeneralIcons";
import { AnimatePresence, motion } from "framer-motion";

interface Props {
  rate: PricebookSupplierRate;
  index: number;
}
const EditSupplierRateLine = ({ rate, index }: Props) => {
  const styleProps = useDoxleThemeStore(
    useShallow((state) => ({
      $themeColor: state.doxleThemeColor,
      $doxleFont: state.doxleFont,
      $currentTheme: state.currentTheme,
    }))
  );
  const { shouldShowWarning } = useEditPricebookItemContext();
  const {
    handleUpdateUnitCost,
    handleUpdateUnit,
    handleDeleteRate,
    showWarningTooltip,
    setShowWarningTooltip,
    rateSupplierRef,
    unitCostRef,
    handleClickSupplierName,
    handleUpdateSupplierName,
    handleBlurSupplierName
  } = useEditSupplierRateLine({ rate, index });
  return (
    <>
      <motion.div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          marginBottom: 5,
          justifyContent: "space-between",
        }}
        layout="position"
      >
        <StyledSupplierNameInput
          ref={rateSupplierRef}
          onClick={handleClickSupplierName}
          onBlur={handleBlurSupplierName}
          onChange={handleUpdateSupplierName}
          $widthInPercent={50}
          value={rate.supplierName}
        />
        <StyledSupplierRateLineTextField
          {...styleProps}
          $widthInPercent={28}
          value={rate.unitCost}
          inputRef={unitCostRef}
          onChange={handleUpdateUnitCost}
          InputProps={{
            startAdornment: (
              <StyledEditPricebookInputAdornment
                {...styleProps}
                position="start"
              >
                $
              </StyledEditPricebookInputAdornment>
            ),
          }}
        />
        <StyledSupplierRateLineTextField
          {...styleProps}
          $widthInPercent={18}
          value={rate.uom}
          onChange={handleUpdateUnit}
        />

        <Tooltip
          PopperProps={{
            sx: DoxleMUITooltipSx(
              styleProps.$themeColor,
              styleProps.$doxleFont
            ),
          }}
          title={`Need to have at least one supplier rate.`}
          open={showWarningTooltip}
          onClose={() => setShowWarningTooltip(false)}
        >
          <StyledDeleteRateLineBtn onClick={handleDeleteRate}>
            <DoxleDeleteIcon
              themeColor={styleProps.$themeColor}
              containerStyle={{
                flexShrink: 0,
                width: 16,
                cursor: "pointer",
              }}
            />
          </StyledDeleteRateLineBtn>
        </Tooltip>
      </motion.div>

      <AnimatePresence>
        {shouldShowWarning &&
          (!rate.unitCost ||
            !rate.uom ||
            (!rate.supplier && !rate.supplierName)) && (
            <StyledWarningRow
              animate={{ opacity: [0, 1], x: [-10, 0] }}
              exit={{ opacity: 0, x: -10 }}
            >
              <StyledWarningField $width="50%">
                {!rate.supplier && !rate.supplierName
                  ? "Please enter a supplier..."
                  : ""}
              </StyledWarningField>
              <StyledWarningField $width="28%">
                {!rate.unitCost ? "Please Enter Price" : ""}
              </StyledWarningField>
              <StyledWarningField $width="18%">
                {!rate.uom ? "Please Enter Uom" : ""}
              </StyledWarningField>
            </StyledWarningRow>
          )}
      </AnimatePresence>
    </>
  );
};
export default EditSupplierRateLine;
