import DoxleListSkeleton from "../../DoxleDesignPattern/DoxleSkeleton/DoxleListSkeleton";
import EditDocketSideScreen from "../../CoreContent/EditDocketSideScreen/EditDocketSideScreen";
import BudgetTable from "./BudgetTable";
import useGetBudgetList from "./Hooks/useGetBudgetList";
import useBudgetsProjectTab from "./Hooks/useBudgetsProjectTab";
import { StyledBudgetsProjectTabContainer } from "./BudgetStyleComponent";
import ListLoadingMore from "../../Utilities/Lottie/ListLoadingMore";
import { useBudgetStore } from "../Store/useBudgetStore";
import { shallow } from "zustand/shallow";
import { useEffect } from "react";

const BudgetsProjectTab = () => {
  const { isFetchingBudgetList } = useGetBudgetList({});
  const { isAddingNewBudget } = useBudgetsProjectTab({});
  const setIsProjectLevel = useBudgetStore(
    (state) => state.setIsBudgetProjectLevel,
    shallow
  );
  useEffect(() => setIsProjectLevel(true), []);
  return (
    <StyledBudgetsProjectTabContainer
      initial={{
        opacity: 0,
        x: -10,
      }}
      animate={{
        opacity: 1,
        x: 0,
      }}
    >
      <BudgetTable />

      <EditDocketSideScreen />

      {isAddingNewBudget && (
        <ListLoadingMore
          containerStyle={{
            position: "absolute",
            zIndex: 10,
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            bottom: 0,
          }}
        />
      )}
    </StyledBudgetsProjectTabContainer>
  );
};
export default BudgetsProjectTab;
