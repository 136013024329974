import React, { useState } from "react";
import {
  StyledAddBookingContentWrapper,
  StyledAddBookingDocketAndDateContainer,
  StyledAddBookingLabelText,
} from "./StyledComponentAddBookingProject";
import { useDoxleThemeStore } from "../../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import { useAddProjectBookingContext } from "./AddBookingProject";
import dayjs from "dayjs";
import BookingDateRangePicker from "../../../CommonComponents/BookingDateRangePicker";
import useAddBookingDocketAndDate from "./Hooks/useAddBookingDocketAndDate";
import BookingDocketListPopper from "../BookingDocketListPopper";
import DoxleIconButton from "../../../../DoxleDesignPattern/DoxleButtons/DoxleIconButton";
import CustomDatePicker from "../../../../DoxleDesignPattern/CustomDatePicker/CustomDatePicker";
import HighlightOffSharpIcon from "@mui/icons-material/HighlightOffSharp";

type Props = {};

const AddBookingDocketAndDate = (props: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
      currentTheme: state.currentTheme,
    }),
    shallow
  );
  const {
    handleSelectDateRange,
    handleOpenDocketList,
    handleCloseDocketList,
    anchorDocketList,

    handleSelectDocket,
  } = useAddBookingDocketAndDate({});
  const { selectedDocket, startDate, endDate, setStartDate, setEndDate } =
    useAddProjectBookingContext();
  const [onHoverStartDate, setOnHoverStartDate] = useState(false);
  const [onHoverEndDate, setOnHoverEndDate] = useState(false);

  return (
    <StyledAddBookingDocketAndDateContainer>
      <div className="field-wrapper">
        <StyledAddBookingLabelText
          $themeColor={doxleThemeColor}
          $doxleFont={doxleFont}
        >
          Docket
          <span className="require-symbol">*</span>
        </StyledAddBookingLabelText>

        <StyledAddBookingContentWrapper
          $themeColor={doxleThemeColor}
          $doxleFont={doxleFont}
          $isNull={Boolean(!selectedDocket)}
          layout
          onClick={handleOpenDocketList}
          style={{ overflow: "visible" }}
        >
          {selectedDocket?.docketName ?? "Select Docket"}

          
        </StyledAddBookingContentWrapper>
      </div>

      <div className="field-wrapper">
        <StyledAddBookingLabelText
          $themeColor={doxleThemeColor}
          $doxleFont={doxleFont}
        >
          Start Date
        </StyledAddBookingLabelText>
        <div
          className="date-picker-wrapper"
          onMouseEnter={(e) => {
            e.stopPropagation();
            setOnHoverStartDate(true);
          }}
          onMouseLeave={(e) => {
            setOnHoverStartDate(false);
          }}
        >
          <CustomDatePicker
            value={startDate ? dayjs(startDate).format("YYYY/MM/DD") : null}
            onChange={(value: dayjs.Dayjs | null) => {
              setStartDate(value ? value.toDate() : null);
            }}
          />
          {startDate && onHoverStartDate && (
            <DoxleIconButton
              iconSource={
                <HighlightOffSharpIcon
                  htmlColor={doxleThemeColor.primaryFontColor}
                  sx={{
                    fontSize: "2rem",
                  }}
                />
              }
              buttonSize={30}
              onClick={() => {
                setStartDate(null);
              }}
            />
          )}
        </div>
      </div>

      <div className="field-wrapper">
        <StyledAddBookingLabelText
          $themeColor={doxleThemeColor}
          $doxleFont={doxleFont}
        >
          End Date
        </StyledAddBookingLabelText>
        <div
          className="date-picker-wrapper"
          onMouseEnter={(e) => {
            e.stopPropagation();
            setOnHoverEndDate(true);
          }}
          onMouseLeave={(e) => {
            setOnHoverEndDate(false);
          }}
        >
          <CustomDatePicker
            value={endDate ? dayjs(endDate).format("YYYY/MM/DD") : null}
            onChange={(value: dayjs.Dayjs | null) => {
              setEndDate(value ? value.toDate() : null);
            }}
          />
          {endDate && onHoverEndDate && (
            <DoxleIconButton
              iconSource={
                <HighlightOffSharpIcon
                  htmlColor={doxleThemeColor.primaryFontColor}
                  sx={{
                    fontSize: "2rem",
                  }}
                />
              }
              buttonSize={30}
              onClick={() => {
                setEndDate(null);
              }}
            />
          )}
        </div>
      </div>

      <BookingDocketListPopper
        anchorPopper={anchorDocketList}
        onClosePopper={handleCloseDocketList}
        onSelectDocket={handleSelectDocket}
      />
    </StyledAddBookingDocketAndDateContainer>
  );
};

export default AddBookingDocketAndDate;
