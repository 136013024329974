type TContactCompanyTableHeaders = "Company Name"| "ABN"| "Address" | "Contacts" | "Phone" | "Email"
type TContactsTableHeaders =
  |"First Name"
  |"Last Name"
  |"Email"
  |"Phone"
  |"Primary Contact"
  |"Send Quotes"
  |"Company Name"
  |"Company ABN"
  |"Company Address"
export type ContactsOrderByFields =
  |'first_name'
  |'last_name'
  |'email'
  |'phone'
  |'is_primary'
  |'send_quotes'
  |'company_name'
  |'company_abn'
  |'company_address'
export type RevContactsOrderByFields = `-${ContactsOrderByFields}`
type ContactsHeadersBackwardMapInterface = {
  [Property in TContactsTableHeaders]: ContactsOrderByFields;
}
export const CONTACTS_HEADERS_MAP:ContactsHeadersBackwardMapInterface = {
  "First Name": "first_name",
  "Last Name": "last_name",
  Email: "email",
  Phone: "phone",
  "Primary Contact": "is_primary",
  "Send Quotes": "send_quotes",
  "Company Name": "company_name",
  "Company ABN": "company_abn",
  "Company Address": "company_address"
}

export type ContactCompanyOrderByFields =
  |'name'
  |'abn'
  |'address'
  |'contacts_count'
  |'phone'
  |'email'
export type RevContactCompanyOrderByFields = `-${ContactCompanyOrderByFields}`
type ContactsCompanyHeadersBackwardMapInterface = {
  [Property in TContactCompanyTableHeaders]: ContactCompanyOrderByFields;
}
export const CONTACT_COMPANIES_HEADERS_MAP:ContactsCompanyHeadersBackwardMapInterface = {
  "Company Name": "name",
  ABN: "abn",
  Address: "address",
  "Contacts": "contacts_count",
  Phone: "phone",
  Email: "email"
}


export  interface ContactsFilters {
  search?: string;
  is_primary?: string;
  send_quotes?: string;
  contact_company?: string;
  order_by?: ContactsOrderByFields|RevContactsOrderByFields;
}

export  interface ContactCompanyFilters {
  search?: string;
  has_contact?: 'true';
  order_by?: ContactCompanyOrderByFields|RevContactCompanyOrderByFields;
}

