import React from "react";

interface BookingGroupByDateListContext {
  isFullStatusMode: boolean;
  isScrolling: boolean;
}
const Bookings: React.FC = () => {
  // const { showFullStatus, isSeperateByProject } = useBookingProjectStore(
  //   (state) => ({
  //     isSeperateByProject: state.isSeperateByProject,
  //     showFullStatus: state.showFullStatus,
  //   }),
  //   shallow
  // );
  // const { doxleThemeColor } = useDoxleThemeStore(
  //   (state) => ({
  //     doxleThemeColor: state.doxleThemeColor,
  //     doxleFont: state.doxleFont,
  //     currentTheme: state.currentTheme,
  //   }),
  //   shallow
  // );

  // const {
  //   handleOnBeforeDragStart,
  //   handleOnDragEnd,
  //   isProjectScope,
  //   isScrolling,
  //   setIsScrolling,
  // } = useBookings({});

  // const { showFullStatusProjectScope } = useProjectStore(
  //   (state) => ({
  //     showFullStatusProjectScope: state.showFullStatus,
  //   }),
  //   shallow
  // );

  // const {
  //   isFetchingBookingList,
  //   isSuccessFetchingBookingList,
  //   isErrorFetchingBookingList,
  //   hasNextPageBooking,
  //   fetchNextPageBooking,
  //   bookingList,
  //   isFetchingNextPage,
  //   bookingListGroupByDate,
  //   bookingListGroupByProject,
  // } = useGetBookingDocketInfinite({});

  // const isFullStatusMode = useMemo(
  //   () => (isProjectScope ? showFullStatusProjectScope : showFullStatus),
  //   [isProjectScope, showFullStatusProjectScope, showFullStatus]
  // );
  // //*render list
  // const components: Components<
  //   BookingGroupByDateData,
  //   BookingGroupByDateListContext
  // > = useMemo(
  //   () => ({
  //     Scroller: React.forwardRef(({ children, ...props }, ref) => (
  //       <StyledBookingListScroller
  //         style={{
  //           ...props.style,
  //         }}
  //         ref={ref}
  //         {...props}
  //       >
  //         <AnimatePresence>{children}</AnimatePresence>
  //       </StyledBookingListScroller>
  //     )),
  //     Item: React.forwardRef((props) => (
  //       <motion.div
  //         {...props}
  //         style={{ paddingBottom: "1.2rem" }}
  //         initial={{
  //           scale: 0.8,
  //           opacity: 0,
  //         }}
  //         animate={{
  //           scale: 1,
  //           opacity: 1,
  //         }}
  //         exit={{
  //           x: [0, 500],
  //           opacity: [1, 0],
  //         }}
  //         transition={{
  //           duration: 0.3,
  //           delay: 0.1,
  //         }}
  //       />
  //     )),
  //     EmptyPlaceholder: () => (
  //       <DoxleEmptyPlaceHolder
  //         headTitleText="No Booking!"
  //         subTitleText="Add more items to manage you work..."
  //         containerBgColor="transparent"
  //       />
  //     ),
  //   }),
  //   []
  // );

  // const itemContentGroupByDate: ItemContent<
  //   BookingGroupByDateData,
  //   BookingGroupByDateListContext
  // > = useCallback((_index, item, context) => {
  //   return (
  //     <BookingProjectScope
  //       key={`booking_${item.date}`}
  //       data={item}
  //       isShowFullStatus={context.isFullStatusMode}
  //     />
  //   );
  // }, []);
  // return (
  //   <LocalizationProvider dateAdapter={AdapterDayjs}>
  //     <DragDropContext
  //       onDragEnd={handleOnDragEnd}
  //       onBeforeDragStart={handleOnBeforeDragStart}
  //     >
  //       <StyledBookingContainer
  //         style={{ position: "relative" }}
  //         $themeColor={doxleThemeColor}
  //       >
  //         <BookingHeaderMenu isProjectScope={isProjectScope} />

  //         <StyledBookingListContainer>
  //           {isFetchingBookingList && (
  //             <DoxleListSkeleton
  //               skeletonType="budgetRow"
  //               containerStyle={{
  //                 width: "100%",
  //                 height: "100%",
  //               }}
  //             />
  //           )}

  //           {isSuccessFetchingBookingList && (
  //             <>
  //               {!isSeperateByProject && (
  //                 <>
  //                   <AddBookingForm isSrolling={isScrolling} />

  //                   <StyledBookingListWrapper layout>
  //                     <Virtuoso
  //                       // useWindowScroll
  //                       isScrolling={setIsScrolling}
  //                       style={{
  //                         height: "100%",
  //                         width: "100%",
  //                       }}
  //                       overscan={200}
  //                       totalCount={bookingListGroupByDate.length}
  //                       data={bookingListGroupByDate}
  //                       context={{ isFullStatusMode, isScrolling }}
  //                       itemContent={itemContentGroupByDate}
  //                       endReached={() => {
  //                         if (hasNextPageBooking) fetchNextPageBooking();
  //                       }}
  //                       atBottomThreshold={0.2}
  //                       components={components}
  //                     />
  //                   </StyledBookingListWrapper>
  //                 </>
  //               )}
  //               {/* {isSeperateByProject && (
  //                 <Virtuoso
  //                   // useWindowScroll
  //                   data={list.groupsTime}
  //                   context={{}}
  //                   itemContent={(_index, item) => {
  //                     return (
  //                       <motion.div
  //                         initial={{ opacity: 0, x: -10 }}
  //                         animate={{ opacity: 1, x: 0 }}
  //                         transition={{
  //                           duration: 0.4,
  //                           delay: 0.3,
  //                         }}
  //                       >
  //                         <div>
  //                           <Address>{item.projectAddress}</Address>
  //                           {item.time.map((timeItem: any) => {
  //                             return (
  //                               <StrictModeDroppable
  //                                 droppableId={`${timeItem.date}_${item.project}`}
  //                                 key={`${timeItem.date}_${item.project}`}
  //                               >
  //                                 {(provided) => (
  //                                   <div
  //                                     {...provided.droppableProps}
  //                                     ref={provided.innerRef}
  //                                   >
  //                                     <BookingItem
  //                                       key={`booking_${timeItem.date}`}
  //                                       date={timeItem.date}
  //                                       projectAddress={item.projectAddress}
  //                                       listTask={timeItem.tasks}
  //                                       project={item.project}
  //                                       isShowFullStatus={isShowFullStatus}
  //                                     />
  //                                   </div>
  //                                 )}
  //                               </StrictModeDroppable>
  //                             );
  //                           })}
  //                           <AddBookingForm
  //                             projectAddress={item.projectAddress}
  //                           />
  //                         </div>
  //                       </motion.div>
  //                     );
  //                   }}
  //                   // components={{
  //                   //   Scroller: React.forwardRef((props, ref) => (
  //                   //     <StyledListScroller
  //                   //       style={{
  //                   //         ...props.style,
  //                   //       }}
  //                   //       ref={ref}
  //                   //       {...props}
  //                   //     />
  //                   //   )),
  //                   // }}
  //                 />
  //               )} */}

  //               {/* <div
  //                 style={{
  //                   padding: "2rem",
  //                   display: "flex",
  //                   justifyContent: "center",
  //                   visibility: "hidden",
  //                 }}
  //               >
  //                 <button disabled={isFetching} ref={ref}>
  //                   {isFetching ? "Loading..." : "Load More"}
  //                 </button>
  //               </div> */}

  //               <BookingsStatusPopperMenu />
  //             </>
  //           )}

  //           {/* {(isEmpty(bookingList) ||
  //             isEmpty(bookingListGroupByDate) ||
  //             isEmpty(bookingListGroupByProject)) &&
  //             !isFetchingBookingList && (
  //               <DoxleEmptyPlaceHolder
  //                 headTitleText="No Booking!"
  //                 subTitleText="Add more items to manage you work..."
  //                 containerBgColor="transparent"
  //               />
  //             )} */}
  //           {isErrorFetchingBookingList && (
  //             <DoxleEmptyPlaceHolder
  //               headTitleText="Something Wrong!"
  //               subTitleText="Failed to get booking list, please try to reload the page..."
  //               containerBgColor="transparent"
  //             />
  //           )}

  //           {isFetchingNextPage && (
  //             <ListLoadingMore
  //               containerStyle={{
  //                 width: "100%",
  //                 left: 0,
  //                 bottom: 0,
  //                 zIndex: 10,
  //                 display: "flex",
  //                 justifyContent: "center",
  //                 alignItems: "center",
  //                 position: "absolute",
  //               }}
  //             />
  //           )}
  //         </StyledBookingListContainer>

  //         <DeleteBookingConfirm />
  //       </StyledBookingContainer>
  //     </DragDropContext>
  //   </LocalizationProvider>
  // );

  return <></>;
};

export default Bookings;
