import { useShallow } from "zustand/react/shallow";
import { useFileStore } from "../Store/useFileStore";

const useFileLeftSubheader = () => {
  const {
    setCurrentFolder,
    currentView,
    setCurrentView,
    clearSelectedFiles,
    currentLevel,
  } = useFileStore(
    useShallow((state) => ({
      setCurrentFolder: state.setCurrentFolder,
      currentView: state.currentView,
      setCurrentView: state.setCurrentView,
      clearSelectedFiles: state.clearSelectedFiles,
      currentLevel: state.currentLevel,
    }))
  );

  function listButtonClicked() {
    setCurrentView("ListView");
    setCurrentFolder(undefined);
    sessionStorage.setItem("currentFolderId", "ROOT");
  }

  function gridButtonClicked() {
    clearSelectedFiles();
    setCurrentView("GridView");
    setCurrentFolder(undefined);
    sessionStorage.setItem("currentFolderId", "ROOT");
  }
  return {
    currentView,
    currentLevel,
    listButtonClicked,
    gridButtonClicked,
  };
};

export default useFileLeftSubheader;
