import NoticeBoardTitle from "./NoticeBoardTitle";
import NoticeBoardContentBody from "./NoticeBoardContentBody";
import { StyledNoticeBoardLayout } from "./StyledNoticeBoardContent";
import DoxleDialogHelper from "../../../DoxleDesignPattern/DoxleDialogHelper/DoxleDialogHelper";
import { shallow } from "zustand/shallow";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import useNoticeBoardContent from "./Hooks/useNoticeBoardContent";
import { useInboxStore } from "../../InboxStore/useInboxStore";
import NoticeBoardMenu from "./NoticeBoardMenu";

export interface INoticeBoardContentProps {}

export function NoticeBoardContent(props: INoticeBoardContentProps) {
  const { deletedDocket, setDeletedDocket } = useInboxStore(
    (state) => ({
      deletedDocket: state.deletedDocket,
      setDeletedDocket: state.setDeletedDocket,
    }),
    shallow
  );
  const { doxleThemeColor } = useDoxleThemeStore(
    (state) => ({ doxleThemeColor: state.doxleThemeColor }),
    shallow
  );
  const { handleDeleteNoticeBoardDocket } = useNoticeBoardContent({});
  const handleDeleteDocket = () => {
    handleDeleteNoticeBoardDocket(deletedDocket?.docketPk);
    setDeletedDocket(undefined);
  };
  return (
    <StyledNoticeBoardLayout>
      <NoticeBoardTitle />
      <NoticeBoardMenu />
      <NoticeBoardContentBody />

      <DoxleDialogHelper
        open={Boolean(deletedDocket !== undefined)}
        onClose={() => setDeletedDocket(undefined)}
        title="Confirm delete docket"
        description={`All Data belong to ${deletedDocket?.docketName} docket will be deleted permanently, Do you want to proceed?`}
        dialogButtons={[
          {
            buttonText: "Cancel",
            buttonFunction: () => setDeletedDocket(undefined),
            buttonColor: doxleThemeColor.primaryContainerColor,
            btnStyleSx: {
              border: `1px solid ${doxleThemeColor.primaryDividerColor}`,
            },
            buttonTextStyle: {
              color: doxleThemeColor.primaryFontColor,
            },
          },
          {
            buttonText: "Confirm",
            buttonFunction: handleDeleteDocket,
            btnStyleSx: {
              border: `1px solid ${doxleThemeColor.primaryDividerColor}`,
            },
          },
        ]}
      />
    </StyledNoticeBoardLayout>
  );
}
