import { Contact } from "../../../Models/addressBook";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import { ItemContent, TableComponents, TableVirtuoso } from "react-virtuoso";
import { Table } from "@mui/material";
import DoxleEmptyPlaceHolder from "../../../DoxleDesignPattern/DoxleEmptyPlaceHolder/DoxleEmptyPlaceHolder";
import React, { useCallback, useMemo } from "react";
import ContactsTableRow from "./ContactsTableRow";
import ContactsTableHeader from "./ContactsTableHeader";
import { StyledListScroller } from "../../../DoxleDesignPattern/ProjectSelectWithDropdown/StyledComponentsProjectSelectWithDropdown";
import useGetContactList from "../../Hooks/useGetContactList";
import { StyledContactListItemWrapper } from "../StyledComponents";
import { EmptyContactBanner } from "../ContactsIcons";

interface Props {}

interface ContactTableListContext {}
const ContactTable = ({}: Props) => {
  const doxleThemeColor = useDoxleThemeStore(
    (state) => state.doxleThemeColor,
    shallow
  );
  const { contactsList, handleFetchNextpageContact } = useGetContactList({});

  //*render list
  const TableComponents: TableComponents<Contact, ContactTableListContext> =
    useMemo(
      () => ({
        Table: (props) => <Table {...props} />,
        EmptyPlaceholder: (props) => (
          <DoxleEmptyPlaceHolder
            {...props}
            headTitleText={"No Contacts"}
            subTitleText={"Add a contact to get started"}
            illustration={
                <EmptyContactBanner themeColor={doxleThemeColor} />
            }
            addBtn={{
              btnText: "Add",
              btnColor: doxleThemeColor.doxleColor,
              btnHeightInPixel: "50px",
              btnWidthInPixel: "164px",
              btnFunction: () => {
                console.log("NOTHING HERE YET");
              },
            }}
          />
        ),
        Scroller: React.forwardRef((props, ref) => (
          <StyledListScroller
            style={{
              ...props.style,
            }}
            ref={ref}
            {...props}
          />
        )),
        TableRow: ({ item: _, ...props }) => (
          <StyledContactListItemWrapper
            {...props}
            $themeColor={doxleThemeColor}
          />
        ),
      }),
      [doxleThemeColor]
    );

  const itemContent: ItemContent<Contact, ContactTableListContext> =
    useCallback((index, item) => <ContactsTableRow contact={item} />, []);

  const tableStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);
  return (
    <TableVirtuoso
      data={contactsList}
      style={tableStyle}
      components={TableComponents}
      endReached={handleFetchNextpageContact}
      fixedHeaderContent={() => <ContactsTableHeader />}
      itemContent={itemContent}
      atBottomThreshold={0.2}
    />
  );
};

export default React.memo(ContactTable);
