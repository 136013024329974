import React, { useEffect, useRef, useState } from "react";
import { useProjectQAStore } from "../../../Store/useProjectQAStore";
import { shallow } from "zustand/shallow";
import ReactSignatureCanvas from "react-signature-canvas";
import QAQueryAPI from "../../../../QueryAPI/qaQueryAPI";
import { useDoxleAuthStore } from "../../../../../DoxleGeneralStore/useDoxleAuthStore";
import { useDoxleCurrentContextStore } from "../../../../../DoxleGeneralStore/useDoxleCurrentContext";
import useDoxleNotificationStore from "../../../../../DoxleGeneralStore/useDoxleNotificationStore";
import useSetQAListQueryData from "../../../../CustomQueryHooks/useSetQAListQueryData";

type Props = {};

interface QAListSignature {
  finalSignaturePath: string | undefined;
  setFinalSignaturePath: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
  signPadRef: React.RefObject<ReactSignatureCanvas>;
  onFinishDraw: () => void;
  handleClearSignature: () => void;
  handleSaveSignature: () => void;
  isSavingSignature: boolean;
}
const useQAListSignature = (props: Props): QAListSignature => {
  const [finalSignaturePath, setFinalSignaturePath] = useState<
    string | undefined
  >(undefined);
  const {
    edittedSignatureQAList,
    setEdittedSignatureQAList,
    filterQAListQuery,
  } = useProjectQAStore(
    (state) => ({
      edittedSignatureQAList: state.edittedSignatureQAList,
      setEdittedSignatureQAList: state.setEdittedSignatureQAList,
      filterQAListQuery: state.filterQAListQuery,
    }),
    shallow
  );

  useEffect(() => {
    if (edittedSignatureQAList)
      setFinalSignaturePath(edittedSignatureQAList.signatureUrl);

    return () => {
      setFinalSignaturePath(undefined);
    };
  }, [edittedSignatureQAList]);

  const signPadRef = useRef<ReactSignatureCanvas>(null);

  const onFinishDraw = () => {
    const uri = signPadRef.current
      ?.getTrimmedCanvas()
      .toDataURL("image/png", 1);
    if (uri) setFinalSignaturePath(uri);
  };

  const handleClearSignature = () => {
    setFinalSignaturePath(undefined);
    signPadRef.current?.clear();
  };

  //*update api
  const company = useDoxleCurrentContextStore(
    (state) => state.currentCompany,
    shallow
  );
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );

  const { handleUpdateQAListSignature } = useSetQAListQueryData({
    filter: filterQAListQuery,
  });

  const onUpdateSuccessCb = (newUrl?: string) => {
    if (newUrl && edittedSignatureQAList)
      handleUpdateQAListSignature(edittedSignatureQAList.defectListId, newUrl);
    setEdittedSignatureQAList(undefined);
  };
  const updateQAListsignature = QAQueryAPI.useUpdateQAListSignatureQuery({
    showNotification,
    company,
    onSuccessCB: onUpdateSuccessCb,
  });

  const handleSaveSignature = () => {
    if (!signPadRef.current?.isEmpty()) {
      const blob = signPadRef.current?.getTrimmedCanvas().toBlob(
        (blob) => {
          if (blob && edittedSignatureQAList)
            updateQAListsignature.mutate({
              qaList: edittedSignatureQAList,
              signatureFile: blob,
            });
        },
        "image/png",
        1
      );
    }
  };

  return {
    finalSignaturePath,
    setFinalSignaturePath,
    signPadRef,
    onFinishDraw,
    handleClearSignature,
    handleSaveSignature,
    isSavingSignature: updateQAListsignature.isLoading,
  };
};

export default useQAListSignature;
