import React, { useCallback, useEffect, useMemo } from "react";
import {
  Components,
  GroupedVirtuoso,
  GroupItemContent,
  GroupContent,
} from "react-virtuoso";
import DoxleEmptyPlaceHolder from "../../../DoxleDesignPattern/DoxleEmptyPlaceHolder/DoxleEmptyPlaceHolder";
import OrderTableRow from "./OrderTableRow";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import { useOrdersStore } from "../../OrdersStore/useOrdersStore";
import Order from "../../Models/order";
import { StyledListScroller } from "../../../DoxleDesignPattern/ProjectSelectWithDropdown/StyledComponentsProjectSelectWithDropdown";
import {
  StyledOrderListItemWrapper,
  StyledOrdersTableContainer,
  StyledGroupContentHeader,
  StyledGroupContentFooter,
} from "./StyledComponents";
import DoxleListSkeleton from "../../../DoxleDesignPattern/DoxleSkeleton/DoxleListSkeleton";
import ListLoadingMore from "../../../Utilities/Lottie/ListLoadingMore";
import DeleteOrderConfirm from "./DeleteOrderConfirm";
import useOrdersTable from "../../Hooks/useOrdersTable";
import { formatter } from "../../../Utilities/FunctionUtilities";
import { Error404Banner } from "../../../DoxleDesignPattern/DoxleIcons/CommonIcons";
import { EmptyOrderBanner } from "./OrderIcon";
// import AddNewOrderTableRow from "../EditDocketTab/AddNewOrderTableRow";

interface props {}
interface OrderListTableContext {
  view: "Orders" | "Invoices";
  showPayments: boolean;
}
const OrdersTable = ({}: props) => {
  const {
    sortedOrderList,
    groups,
    groupCounts,
    isFetchingOrder,
    isErrorFetchingOrder,
    handleFetchNextPageOrder,
    isFetchingNextPageOrder,
  } = useOrdersTable();

  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }),
    shallow
  );
  const { showPayments, view } = useOrdersStore(
    (state) => ({
      showPayments: state.showPayments,
      view: state.view,
    }),
    shallow
  );

  const { selectAllOrder, setSelectedOrder } = useOrdersStore(
    (state) => ({
      selectAllOrder: state.selectAllOrder,
      setSelectedOrder: state.setSelectedOrder,
    }),
    shallow
  );

  useEffect(() => {
    if (selectAllOrder) {
      setSelectedOrder(sortedOrderList);
      return;
    }
    // if(sortedOrderList.length === selectedOrders.length){
    //   setSelectAllOrder(true);
    // }
  }, [selectAllOrder, sortedOrderList]);

  //* render list
  const tableComponents: Components<Order, OrderListTableContext> = useMemo(
    () => ({
      EmptyPlaceholder: (props) => (
        <DoxleEmptyPlaceHolder
          {...props}
          headTitleText={
            props.context?.view === "Orders"
              ? "No Orders Found"
              : "No Invoices Found"
          }
          subTitleText={
            props.context?.view === "Orders"
              ? "Add an order to get started"
              : "Add an invoice to get started"
          }
          containerStyle={{
            width: "100%",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
          containerBgColor="transparent"
        />
      ),
      Scroller: React.forwardRef((props, ref) => (
        <StyledListScroller
          style={{
            ...props.style,
          }}
          ref={ref}
          {...props}
        />
      )),
      Item: (props) => (
        <StyledOrderListItemWrapper
          {...props}
          layout="position"
          transition={{
            duration: 0.2,
            damping: 16,
          }}
        />
      ),
    }),
    []
  );

  const itemContent: GroupItemContent<Order, OrderListTableContext> =
    useCallback(
      (index: number, groupIndex: number, data, context) => {
        // console.log("sortedOrderList[index]", sortedOrderList[index])
        if (index !== sortedOrderList.length - 1)
          return <OrderTableRow order={sortedOrderList[index]} />;

        return (
          <>
            <OrderTableRow order={sortedOrderList[index]} />
            <StyledGroupContentFooter $showTotal={context.showPayments}>
              <div className="title"># Orders</div>
              <div className={"value"}>
                {formatter.format(groups?.[index]?.orderTotal ?? 0)}
              </div>
            </StyledGroupContentFooter>
            {context.showPayments && (
              <StyledGroupContentFooter $showTotal={context.showPayments}>
                <div className={"title"}># Payments</div>
                <div className={"value"}>
                  {formatter.format(groups?.[index]?.totalPayments ?? 0)}
                </div>
              </StyledGroupContentFooter>
            )}
            {context.showPayments && (
              <StyledGroupContentFooter
                $balance
                $showTotal={context.showPayments}
              >
                <div className={"title balance"}># Balance</div>
                <div className={"value balance"}>
                  {formatter.format(groups?.[index]?.totalBalance ?? 0)}
                </div>
              </StyledGroupContentFooter>
            )}
          </>
        );
      },
      [sortedOrderList, showPayments]
    );

  const groupContent: GroupContent<OrderListTableContext> = useCallback(
    (index, context) => {
      if (index === 0)
        return (
          <StyledGroupContentHeader>
            {groups?.[index]?.supplier}
          </StyledGroupContentHeader>
        );
      else
        return (
          <>
            <StyledGroupContentFooter $showTotal={context.showPayments}>
              <div className={"title"}># Orders</div>
              <div className={"value"}>
                {formatter.format(groups?.[index - 1]?.orderTotal ?? 0)}
              </div>
            </StyledGroupContentFooter>
            {context.showPayments && (
              <StyledGroupContentFooter $showTotal={context.showPayments}>
                <div className={"title"}># Payments</div>
                <div className={"value"}>
                  {formatter.format(groups?.[index - 1]?.totalPayments ?? 0)}
                </div>
              </StyledGroupContentFooter>
            )}
            {context.showPayments && (
              <StyledGroupContentFooter
                $balance
                $showTotal={context.showPayments}
              >
                <div className={"title balance"}># Balance</div>
                <div className={"value balance"}>
                  {formatter.format(groups?.[index - 1]?.totalBalance ?? 0)}
                </div>
              </StyledGroupContentFooter>
            )}
            <StyledGroupContentHeader>
              {groups?.[index]?.supplier}
            </StyledGroupContentHeader>
          </>
        );
    },
    [groups, showPayments]
  );

  const listStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);
  return (
    <StyledOrdersTableContainer
      $themeColor={doxleThemeColor}
      $doxleFont={doxleFont}
      initial={{
        y: 10,
        opacity: 0,
      }}
      animate={{
        y: 0,
        opacity: 1,
      }}
    >
      <div className="order-list-wrapper">
        {!isFetchingOrder && (
          <>
            {sortedOrderList.length === 0 && (
              <DoxleEmptyPlaceHolder
                headTitleText={
                  isErrorFetchingOrder ? "Something Wrong" : "No Orders Found"
                }
                containerStyle={{
                  width: "100%",
                  height: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                containerBgColor="transparent"
                illustration={
                  isErrorFetchingOrder ? (
                    <Error404Banner
                      themeColor={doxleThemeColor}
                      containerStyle={{ width: "30%" }}
                    />
                  ) : (
                    <EmptyOrderBanner
                      themeColor={doxleThemeColor}
                      containerStyle={{
                        marginBottom: 20,
                      }}
                    />
                  )
                }
              />
            )}
            {sortedOrderList.length > 0 && (
              <GroupedVirtuoso
                context={{ view, showPayments }}
                groupCounts={groupCounts}
                groupContent={groupContent}
                style={listStyle}
                components={tableComponents}
                endReached={handleFetchNextPageOrder}
                atBottomThreshold={0.3}
                itemContent={itemContent}
              />
            )}
            {isErrorFetchingOrder && (
              <DoxleEmptyPlaceHolder
                headTitleText="Something wrong!"
                subTitleText="Your session maybe expired, please try to reload page!"
                containerBgColor="transparent"
              />
            )}
            {isFetchingNextPageOrder && (
              <ListLoadingMore
                animationSize={80}
                containerStyle={{
                  position: "absolute",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  zIndex: 10,
                  left: 0,
                  bottom: 0,
                }}
              />
            )}
          </>
        )}

        {isFetchingOrder && (
          <DoxleListSkeleton
            skeletonType="orderRow"
            numOfRows={14}
            containerStyle={{
              width: "100%",
              height: "100%",
            }}
          />
        )}
      </div>
      <DeleteOrderConfirm />
    </StyledOrdersTableContainer>
  );
};
export default React.memo(OrdersTable);
