import { StyledDoxleGalleryRootContainer } from "./StyledComponentDoxleGallery";
import ReactPlayer from "react-player/lazy";
import useDoxleVideoPlayer from "./Store/useDoxleVideoPlayer";
import DoxleIconButton from "../DoxleButtons/DoxleIconButton";
import { IoClose } from "react-icons/io5";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import { useDoxleImageGalleryStore } from "./Store/useDoxleImageGalleryStore";
import { shallow } from "zustand/shallow";
import { DoxleIconPlain } from "../DoxleGeneralIcons";

type Props = {
  videoUrl: string;
};

const DoxleVideoPlayer = ({ videoUrl }: Props) => {
  const { videoControlProps, togglePlaying, handlePlay, handlePause } =
    useDoxleVideoPlayer();
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore((state) => ({
    doxleThemeColor: state.doxleThemeColor,
    doxleFont: state.doxleFont,
  }));
  const { resetGallery } = useDoxleImageGalleryStore(
    (state) => ({
      resetGallery: state.resetGallery,
    }),
    shallow
  );
  return (
    <StyledDoxleGalleryRootContainer
      style={{ position: "relative", overflow: "hidden" }}
    >
      <DoxleIconPlain
        themeColor={doxleThemeColor}
        containerStyle={{
          position: "absolute",
          left: 10,
          top: 10,
          width: 50,
        }}
      />

      <DoxleIconButton
        iconSource={
          <IoClose size="50px" color={doxleThemeColor.primaryFontColor} />
        }
        buttonSize={50}
        buttonWrapperStyle={{
          position: "absolute",
          right: 0,
          zIndex: 100,
          top: 0,
          cursor: "pointer",
        }}
        onClick={resetGallery}
      />
      <ReactPlayer
        url={videoUrl}
        playing={videoControlProps.playing}
        width="100%"
        height="100%"
        style={{
          maxWidth: 1500,
          maxHeight: 1200,
        }}
        onPlay={handlePlay}
        onPause={handlePause}
        controls
      />
    </StyledDoxleGalleryRootContainer>
  );
};

export default DoxleVideoPlayer;
