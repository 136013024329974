import React from "react";
import {
  StyledDocketListSkeletonRowContainer,
  StyledInventoryListSkeletonRowContainer,
  StyledMailListSkeletonRowContainer,
  StyledNoteLineSkeletonRow,
  StyledNoteSkeletonRow,
  StyledProjectSkeletonRow,
} from "./StyledComponentDoxleSkeleton";
import { Skeleton } from "@mui/material";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import { editRgbaAlpha } from "../../Utilities/FunctionUtilities";

type Props = {};
const ROW_HEIGHT = 40;
const MARGIN_ROW_VERTICAL = 20;
const DocketListSkeletonRow = (props: Props) => {
  const { doxleThemeColor } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
    }),
    shallow
  );
  return (
    <StyledDocketListSkeletonRowContainer
      $marginVertical={MARGIN_ROW_VERTICAL}
      $rowHeight={ROW_HEIGHT}
      $themeColor={doxleThemeColor}
    >
      {/* <div style={{ width: 30, height: 30, display: "flex" }}>
        <Skeleton variant="circular" width={"100%"} height={"100%"} />
      </div> */}

      <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
        <Skeleton
          variant="rounded"
          width="100%"
          height={14}
          style={{
            borderRadius: "10px",

            marginBottom: "14px",
          }}
          sx={{
            bgcolor: doxleThemeColor.skeletonColor,
          }}
        />
        <Skeleton
          variant="rounded"
          width="calc(90%)"
          height={8}
          style={{
            borderRadius: "10px",

            marginBottom: "4px",
          }}
          sx={{
            bgcolor: doxleThemeColor.skeletonColor,
          }}
        />
      </div>

      {/* <Skeleton
        variant="rounded"
        width={80}
        height={20}
        style={{
          borderRadius: "10px",
        }}
      /> */}
    </StyledDocketListSkeletonRowContainer>
  );
};

export default DocketListSkeletonRow;

export const BudgetDocketListSkeletonRow = (props: Props) => {
  const { doxleThemeColor, currentTheme } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      currentTheme: state.currentTheme,
    }),
    shallow
  );
  return (
    <StyledDocketListSkeletonRowContainer
      $marginVertical={MARGIN_ROW_VERTICAL}
      $rowHeight={ROW_HEIGHT}
      $themeColor={doxleThemeColor}
    >
      <div style={{ flex: 1, display: "flex", flexDirection: "row" }}>
        <Skeleton
          variant="rounded"
          width={"40%"}
          height={"1.4rem"}
          style={{
            borderRadius: "10px",
            marginInline: "10px",
          }}
          sx={{
            bgcolor: doxleThemeColor.skeletonColor,
          }}
        />
        <Skeleton
          variant="rounded"
          width={"30%"}
          height={"1.4rem"}
          style={{
            borderRadius: "10px",
            marginInline: "10px",
          }}
          sx={{
            bgcolor: doxleThemeColor.skeletonColor,
          }}
        />
        {[...Array(4)].map((e, i) => (
          <Skeleton
            key={"skeleton" + i}
            variant="rounded"
            width={"20rem"}
            height={"1.4rem"}
            style={{
              borderRadius: "10px",
              marginInline: "10px",
            }}
            sx={{
              bgcolor: doxleThemeColor.skeletonColor,
            }}
          />
        ))}
      </div>
    </StyledDocketListSkeletonRowContainer>
  );
};

export const OrderListSkeletonRow = (props: Props) => {
  const { doxleThemeColor } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
    }),
    shallow
  );
  return (
    <StyledDocketListSkeletonRowContainer
      $marginVertical={MARGIN_ROW_VERTICAL}
      $rowHeight={ROW_HEIGHT}
      $themeColor={doxleThemeColor}
      style={{
        justifyContent: "space-between",
      }}
    >
      <Skeleton
        key={"skeleton"}
        variant="rounded"
        width={"10%"}
        height="1.4rem"
        style={{
          margin: "0px 1.2rem",
        }}
        sx={{
          bgcolor: doxleThemeColor.skeletonColor,
        }}
      />
      <Skeleton
        variant="rounded"
        width={"30%"}
        height="1.4rem"
        style={{
          margin: "0px 1.2rem",
        }}
        sx={{
          bgcolor: doxleThemeColor.skeletonColor,
        }}
      />
      <Skeleton
        variant="rounded"
        width={"30%"}
        height="1.4rem"
        style={{
          margin: "0px 1.2rem",
        }}
        sx={{
          bgcolor: doxleThemeColor.skeletonColor,
        }}
      />
    </StyledDocketListSkeletonRowContainer>
  );
};

export const MailSkeletonRow = (props: Props) => {
  const { doxleThemeColor } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
    }),
    shallow
  );
  return (
    <StyledMailListSkeletonRowContainer
      $marginVertical={MARGIN_ROW_VERTICAL}
      $rowHeight={100}
      $themeColor={doxleThemeColor}
    >
      <div style={{ flex: 1, display: "flex", flexDirection: "row" }}>
        <Skeleton
          variant="rounded"
          width={"20%"}
          height={"1.4rem"}
          style={{
            borderRadius: "10px",
            marginInline: "10px",
          }}
          sx={{
            bgcolor: doxleThemeColor.skeletonColor,
          }}
        />
        <Skeleton
          variant="rounded"
          width={"35%"}
          height={"1.4rem"}
          style={{
            borderRadius: "10px",
            marginInline: "10px",
          }}
          sx={{
            bgcolor: doxleThemeColor.skeletonColor,
          }}
        />
      </div>
      <div style={{ flex: 1, display: "flex", flexDirection: "row" }}>
        <Skeleton
          variant="rounded"
          width={"20%"}
          height={"1.4rem"}
          style={{
            borderRadius: "10px",
            marginInline: "10px",
          }}
          sx={{
            bgcolor: doxleThemeColor.skeletonColor,
          }}
        />
        <Skeleton
          variant="rounded"
          width={"70%"}
          height={"1.4rem"}
          style={{
            borderRadius: "10px",
            marginInline: "10px",
          }}
          sx={{
            bgcolor: doxleThemeColor.skeletonColor,
          }}
        />
      </div>
      <div style={{ flex: 1, display: "flex", flexDirection: "row" }}>
        <div
          style={{
            width: "20%",
            borderRadius: "10px",
            marginInline: "10px",
          }}
        />
        <Skeleton
          variant="rounded"
          width={"65%"}
          height={"1.4rem"}
          style={{
            borderRadius: "10px",
            marginInline: "10px",
          }}
          sx={{
            bgcolor: doxleThemeColor.skeletonColor,
          }}
        />
      </div>
    </StyledMailListSkeletonRowContainer>
  );
};

export const PricebookSkeletonRow = (props: Props) => {
  const { doxleThemeColor, currentTheme } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      currentTheme: state.currentTheme,
    }),
    shallow
  );
  return (
    <StyledDocketListSkeletonRowContainer
      $marginVertical={0}
      $rowHeight={45}
      $themeColor={doxleThemeColor}
    >
      <div style={{ flex: 1, display: "flex", flexDirection: "row" }}>
        <Skeleton
          variant="rounded"
          width={"35%"}
          height={"1.4rem"}
          style={{
            borderRadius: "10px",
            marginInline: "10px",
          }}
          sx={{
            bgcolor: doxleThemeColor.skeletonColor,
          }}
        />
        <Skeleton
          variant="rounded"
          width={"25%"}
          height={"1.4rem"}
          style={{
            borderRadius: "10px",
            marginInline: "10px",
          }}
          sx={{
            bgcolor: doxleThemeColor.skeletonColor,
          }}
        />
        <Skeleton
          variant="rounded"
          width={"10%"}
          height={"1.4rem"}
          style={{
            borderRadius: "10px",
            marginInline: "10px",
          }}
          sx={{
            bgcolor: doxleThemeColor.skeletonColor,
          }}
        />
        <Skeleton
          variant="rounded"
          width={"10%"}
          height={"1.4rem"}
          style={{
            borderRadius: "10px",
            marginInline: "10px",
          }}
          sx={{
            bgcolor: doxleThemeColor.skeletonColor,
          }}
        />
        <Skeleton
          variant="rounded"
          width={"20%"}
          height={"1.4rem"}
          style={{
            borderRadius: "10px",
            marginInline: "10px",
          }}
          sx={{
            bgcolor: doxleThemeColor.skeletonColor,
          }}
        />
      </div>
    </StyledDocketListSkeletonRowContainer>
  );
};

export const InventorySkeletonRow = (props: Props) => {
  const { doxleThemeColor } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
    }),
    shallow
  );
  return (
    <StyledInventoryListSkeletonRowContainer
      $marginVertical={MARGIN_ROW_VERTICAL}
      $rowHeight={100}
      $themeColor={doxleThemeColor}
    >
      <div style={{ flex: 1, display: "flex", flexDirection: "row" }}>
        <Skeleton
          variant="rounded"
          width={"100%"}
          height={"3rem"}
          style={{
            borderRadius: "10px",
            marginInline: "10px",
          }}
          sx={{
            bgcolor: doxleThemeColor.skeletonColor,
          }}
        />
      </div>
      <div style={{ flex: 1, display: "flex", flexDirection: "row" }}>
        <Skeleton
          variant="rounded"
          width={"30%"}
          height={"19rem"}
          style={{
            borderRadius: "10px",
            marginInline: "10px",
          }}
          sx={{
            bgcolor: doxleThemeColor.skeletonColor,
          }}
        />
        <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
          <Skeleton
            variant="rounded"
            width={"90%"}
            height={"3.3rem"}
            style={{
              borderRadius: "10px",
              marginInline: "10px",
            }}
            sx={{
              bgcolor: doxleThemeColor.skeletonColor,
            }}
          />
          <Skeleton
            variant="rounded"
            width={"90%"}
            height={"8rem"}
            style={{
              borderRadius: "10px",
              marginInline: "10px",
              marginTop: "30px",
            }}
            sx={{
              bgcolor: doxleThemeColor.skeletonColor,
            }}
          />
        </div>
      </div>
    </StyledInventoryListSkeletonRowContainer>
  );
};

export const NoteSkeletonRow = (props: Props) => {
  const { doxleThemeColor } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
    }),
    shallow
  );
  return (
    <StyledNoteSkeletonRow $themeColor={doxleThemeColor}>
      <Skeleton
        variant="rounded"
        width={30}
        height={30}
        sx={{
          bgcolor: doxleThemeColor.skeletonColor,
        }}
      />
      <Skeleton
        variant="rounded"
        width={"90%"}
        height={12}
        sx={{
          bgcolor: doxleThemeColor.skeletonColor,
        }}
      />
    </StyledNoteSkeletonRow>
  );
};

export const NoteLineSkeletonRow = (props: Props) => {
  const { doxleThemeColor } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
    }),
    shallow
  );
  return (
    <StyledNoteLineSkeletonRow $themeColor={doxleThemeColor}>
      <Skeleton
        variant="circular"
        width={14}
        height={14}
        sx={{
          bgcolor: doxleThemeColor.skeletonColor,
        }}
      />
      <Skeleton
        variant="rounded"
        width={"60%"}
        height={8}
        sx={{
          bgcolor: doxleThemeColor.skeletonColor,
          marginLeft: "40px",
        }}
      />
    </StyledNoteLineSkeletonRow>
  );
};
export const ProjectSkeletonRow = (props: Props) => {
  const { doxleThemeColor } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
    }),
    shallow
  );
  return (
    <StyledProjectSkeletonRow $themeColor={doxleThemeColor}>
      <Skeleton
        variant="rounded"
        height={10}
        sx={{
          width: "100%",
          bgcolor: doxleThemeColor.skeletonColor,
        }}
      />
    </StyledProjectSkeletonRow>
  );
};
