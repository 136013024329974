import { shallow } from "zustand/shallow";
import { useEditDocketSideScreenStore } from "../../CoreContent/EditDocketSideScreen/EditDocketSideScreenStore/useEditDocketSideScreenStore";
import { useDoxleCurrentContextStore } from "../../DoxleGeneralStore/useDoxleCurrentContext";
import useDoxleNotificationStore from "../../DoxleGeneralStore/useDoxleNotificationStore";
import { useScopeOfWorkStore } from "../Store/useScopeOfWorkStore";
import { useCallback, useEffect, useMemo } from "react";
import ScopeOfWorkAPI from "../QueryAPI/scopeOfWorkQueryAPI";
import {
  NEW_SCOPE_GROUP_TEMPLATE,
  ScopeOfWorkGroup,
} from "../Model/scopeOfWorks";
import { VirtuosoHandle } from "react-virtuoso";

interface ScopeOfWork {
  isSuccessFetchingScopeGroupList: boolean;
  isFetchingScopeGroupList: boolean;
  isErrorFetchingScopeGroupList: boolean;
  scopeGroupList: ScopeOfWorkGroup[];
  handleAddScopeGroup: () => void;
  hasNextPage: boolean;
  fetchNextPage: () => void;
}
interface props {
  taskListRef: React.RefObject<VirtuosoHandle>;
}
export const useScopeOfWork = ({ taskListRef }: props): ScopeOfWork => {
  const { company } = useDoxleCurrentContextStore((state) => ({
    company: state.currentCompany,
  }));
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification
  );
  const { edittedDocket, setHandleAddButtonFn } = useEditDocketSideScreenStore(
    (state) => ({
      edittedDocket: state.edittedDocket,
      setHandleAddButtonFn: state.setHandleAddButtonFn,
    }),
    shallow
  );

  const { filterScopeOfWorkGroup, setFilterScopeOfWorkGroup, resetStore } =
    useScopeOfWorkStore(
      (state) => ({
        filterScopeOfWorkGroup: state.filterScopeOfWorkGroup,
        setFilterScopeOfWorkGroup: state.setFilterScopeOfWorkGroup,
        resetStore: state.resetStore,
      }),
      shallow
    );

  useEffect(() => {
    return () => resetStore();
  }, []);

  useEffect(() => {
    setFilterScopeOfWorkGroup({
      docketId: edittedDocket?.docketPk,
    });
  }, [edittedDocket?.docketPk, setFilterScopeOfWorkGroup]);

  //#fetch scope group list
  const getScopeGroupQuery = ScopeOfWorkAPI.useRetrieveScopeGroupList({
    company,
    showNotification,
    filter: filterScopeOfWorkGroup,
  });

  const scopeGroupList: ScopeOfWorkGroup[] = useMemo(
    () =>
      getScopeGroupQuery.isSuccess
        ? getScopeGroupQuery.data.pages.reduce((acc, data) => {
            return acc.concat(data.data.results);
          }, [] as ScopeOfWorkGroup[])
        : [],
    [getScopeGroupQuery.data]
  );

  //# add scope group query
  const addScopeGroupQuery = ScopeOfWorkAPI.useAddScopeGroupQuery({
    showNotification,
    company,
    filter: filterScopeOfWorkGroup,
    onSuccessCb: () => {
      taskListRef.current?.scrollToIndex({
        index: scopeGroupList.length - 1,
        behavior: "smooth",
      });
    },
  });

  // //# add scope group query
  // const deleteManyScopeGroupQuery = ScopeOfWorkAPI.useMultiDeleteScopeItemQuery(
  //   {
  //     showNotification,

  //     company,
  //     filter: filterScopeOfWorkGroup,

  //   }
  // );

  const handleAddScopeGroup = useCallback(() => {
    const newScopeGroup: ScopeOfWorkGroup = NEW_SCOPE_GROUP_TEMPLATE({
      company: company?.companyId ?? "",
      scopeGroupTitle: "New Task Group",
      scopeGroupIndex: scopeGroupList.length,
      docket: edittedDocket?.docketPk ?? null,
    });
    if (!addScopeGroupQuery.isLoading) addScopeGroupQuery.mutate(newScopeGroup);
  }, [
    scopeGroupList.length,
    edittedDocket?.docketPk,
    addScopeGroupQuery.isLoading,
  ]);

  useEffect(() => {
    setHandleAddButtonFn([
      {
        btnFunction: handleAddScopeGroup,
        isLoading: addScopeGroupQuery.isLoading,
      },
    ]);
  }, [setHandleAddButtonFn, handleAddScopeGroup, addScopeGroupQuery.isLoading]);

  return {
    isSuccessFetchingScopeGroupList: getScopeGroupQuery.isSuccess,
    scopeGroupList,
    handleAddScopeGroup,
    isFetchingScopeGroupList: getScopeGroupQuery.isLoading,
    isErrorFetchingScopeGroupList: getScopeGroupQuery.isError,
    hasNextPage: Boolean(getScopeGroupQuery.hasNextPage),
    fetchNextPage: () => getScopeGroupQuery.fetchNextPage(),
  };
};
