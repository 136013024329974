import React, { memo, useState } from "react";
import { shallow } from "zustand/shallow";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { usePricebookStore } from "../../Store/usePricebookStore";
import { PricebookSupplierRate } from "../../Models/Pricebook";
import { PRICEBOOK_TABLE_HEADER_LIST } from "../../Models/PricebookTable";
import {
  StyledPricebookRow,
  StyledPricebookTableCell,
} from "../StyledComponents";
import { formatter, toNum } from "../../../Utilities/FunctionUtilities";
import { StyledDeleteIconButton } from "../PricebookList/StyledComponents";
import PricebookQueryAPI from "../../QueryHooks/PricebookQueryAPI";
import { useDoxleCurrentContextStore } from "../../../DoxleGeneralStore/useDoxleCurrentContext";
import useDoxleNotificationStore from "../../../DoxleGeneralStore/useDoxleNotificationStore";
import { DeletePricebookIcon } from "../PricebookIcons";
import dayjs from "dayjs";
import { CircularProgress } from "@mui/material";
import usePricebookTableRow from "./Hook/usePricebookTableRow";

interface Props {
  rate: PricebookSupplierRate;
}

const PricebookTableRow = ({ rate }: Props) => {
  const { doxleThemeColor } = useDoxleThemeStore((state) => ({
    doxleThemeColor: state.doxleThemeColor,
  }));
  const {
    handleDeleteSupplierRate,
    isDeletingItem,

    handleClickRateRow,
  } = usePricebookTableRow({ rate });
  return (
    <StyledPricebookRow onClick={handleClickRateRow}>
      {PRICEBOOK_TABLE_HEADER_LIST.map((header, idx) => {
        if (header.isDisplayed) {
          if (header.headerName === "Supplier") {
            return (
              <StyledPricebookTableCell
                key={`docketCell#${rate.rateId}#${idx}`}
                $width={"50%"}
                $horizontalAlign="flex-start"
                $fontWeight="400"
                $letterSpacing="-0.28px"
              >
                {rate.supplierName}
              </StyledPricebookTableCell>
            );
          }
          if (header.headerName === "Unit") {
            return (
              <StyledPricebookTableCell
                key={`docketCell#${rate.rateId}#${idx}`}
                $width={"14%"}
                $horizontalAlign="center"
                $fontWeight="400"
                $letterSpacing="-0.28px"
                $paddingLeft={"30px"}
              >
                {rate.uom}
              </StyledPricebookTableCell>
            );
          }
          if (header.headerName === "Unit Price") {
            return (
              <StyledPricebookTableCell
                key={`docketCell#${rate.rateId}#${idx}`}
                $width={"14%"}
                $horizontalAlign="center"
                $fontWeight="400"
                $letterSpacing="-0.28px"
              >
                {formatter.format(toNum(rate.unitCost))}
              </StyledPricebookTableCell>
            );
          }
          if (header.headerName === "Modified") {
            return (
              <StyledPricebookTableCell
                key={`docketCell#${rate.rateId}#${idx}`}
                $width={"17%"}
                $horizontalAlign="center"
                $fontWeight="400"
                $letterSpacing="-0.28px"
              >
                {dayjs(rate.timeStamp).format("MMM DD, YYYY")}
              </StyledPricebookTableCell>
            );
          }
        }
      })}
      <StyledPricebookTableCell
        key={`docketCell#${rate.rateId}#delete`}
        $width={"5%"}
        $horizontalAlign="center"
        $fontWeight="400"
        $letterSpacing="-0.28px"
      >
        {!isDeletingItem ? (
          <StyledDeleteIconButton onClick={handleDeleteSupplierRate}>
            <DeletePricebookIcon themeColor={doxleThemeColor} />
          </StyledDeleteIconButton>
        ) : (
          <CircularProgress color="error" size={20} />
        )}
      </StyledPricebookTableCell>
    </StyledPricebookRow>
  );
};

export default memo(PricebookTableRow);
