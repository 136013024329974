import React, { useEffect, useMemo, useState } from "react";
import { useDoxleAuthStore } from "../../../DoxleGeneralStore/useDoxleAuthStore";
import { shallow } from "zustand/shallow";
import { useDoxleCurrentContextStore } from "../../../DoxleGeneralStore/useDoxleCurrentContext";
import {
  Docket,
  IFullDocketDetailQueryFilterProp,
} from "../../../Models/dockets";
import DocketQuery from "../../../Services/QueryHooks/docketQueryAPI";
import { useShallow } from "zustand/react/shallow";

type Props = { anchorPopper: HTMLElement | null };

interface BookingDocketListPopper {
  docketList: Docket[];

  handleFetchNextPageDocket: () => void;
  isFetchingNextPageDocket: boolean;
  isFetchingDocketList: boolean;
  isSuccessFetchingDocketList: boolean;
  isErrorFetchingDocketList: boolean;
  refetchdocketList: () => void;
  isRefetchingDocketList: boolean;
  setSearchDocketText: React.Dispatch<React.SetStateAction<string>>;
  searchDocketText: string;
  showList: boolean;
}
const useBookingDocketListPopper = ({
  anchorPopper,
}: Props): BookingDocketListPopper => {
  const [searchDocketText, setSearchDocketText] = useState<string>("");
  const [showList, setshowList] = useState(false);

  const { company, currentProject } = useDoxleCurrentContextStore(
    useShallow((state) => ({
      company: state.currentCompany,
      currentProject: state.currentProject,
    }))
  );
  const filterDocketList: IFullDocketDetailQueryFilterProp = useMemo(
    () => ({
      project: currentProject?.projectId,
      search: searchDocketText,
      view: "budget",
    }),
    [currentProject, searchDocketText]
  );
  const docketListQuery = DocketQuery.useRetrieveFullDetailDocketList({
    filter: filterDocketList,
    company,
  });

  const docketList = useMemo(
    () =>
      docketListQuery.data?.pages.flatMap(
        (page) => (page?.data?.results as Docket[]) ?? []
      ) ?? [],
    [docketListQuery.data]
  );

  const handleFetchNextPageDocket = () => {
    if (docketListQuery.hasNextPage) docketListQuery.fetchNextPage();
  };

  const refetchdocketList = () => {
    if (currentProject) docketListQuery.refetch();
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (!docketListQuery.isLoading) {
        if (anchorPopper) setshowList(true);
        else {
          setshowList(false);
          setSearchDocketText("");
        }
      } else {
        setshowList(false);
      }
    }, 400);

    return () => {
      clearTimeout(timeout);
    };
  }, [anchorPopper, docketListQuery.isLoading]);

  return {
    docketList,

    handleFetchNextPageDocket,
    isFetchingDocketList: docketListQuery.isLoading,
    isSuccessFetchingDocketList: docketListQuery.isSuccess,
    isErrorFetchingDocketList: docketListQuery.isError,
    isFetchingNextPageDocket: docketListQuery.isFetchingNextPage,
    refetchdocketList,
    isRefetchingDocketList: docketListQuery.isRefetching,
    setSearchDocketText,
    searchDocketText,
    showList,
  };
};

export default useBookingDocketListPopper;
