import { useQueryClient } from "@tanstack/react-query";

import { produce } from "immer";
import {
  IFilterBookingQueryProps,
  getBookingListQKey,
  getBookingListWithoutPaginationQKey,
} from "../QueryAPI/bookingQueryAPI";
import { IBooking } from "../Models/booking";
import { useDoxleCurrentContextStore } from "../../DoxleGeneralStore/useDoxleCurrentContext";
import { shallow } from "zustand/shallow";
import { useShallow } from "zustand/react/shallow";
import { DefiniteAxiosQueryData } from "../../Models/axiosReturn";

type Props = {
  filter: IFilterBookingQueryProps;
  appendPos?: "start" | "end";
  overwrite?: boolean;
};

const useSetBookingQueryData = ({
  filter,
  appendPos = "end",
  overwrite = true,
}: Props) => {
  const queryClient = useQueryClient();
  const { company } = useDoxleCurrentContextStore(
    useShallow((state) => ({
      company: state.currentCompany,
    }))
  );
  const qKey = getBookingListQKey(filter, company);
  const qKeyWithoutPagination = getBookingListWithoutPaginationQKey(
    filter,
    company
  );
  const handleAddBookingQueryData = (addedBooking: IBooking) => {
    const oldServerData = queryClient.getQueryData(qKey);
    if (oldServerData && overwrite) {
      queryClient.setQueryData(qKey, (old: any) => {
        if (old) {
          const lengthOfPages = old.pages.length;
          return produce(old, (draftOld: any) => {
            draftOld.pages = produce(draftOld.pages, (draftPages: any) => {
              if (appendPos === "start") {
                (draftPages[0].data.results as IBooking[]).unshift(
                  addedBooking
                );
              } else {
                (draftPages[lengthOfPages - 1].data.results as IBooking[]).push(
                  addedBooking
                );
              }

              return draftPages;
            });

            return draftOld;
          });
        } else return old;
      });
    } else queryClient.invalidateQueries(qKey);
  };
  const handleDeleteBookingQueryData = (deletedBookingId: string) => {
    const oldServerData = queryClient.getQueryData(qKey);
    if (oldServerData && overwrite) {
      queryClient.setQueryData(qKey, (old: any) => {
        if (old) {
          //find page contain deleted item
          let pageContainGroup: number = (old.pages as Array<any>).findIndex(
            (page) =>
              (page.data.results as IBooking[]).find(
                (booking) => booking.bookingId === deletedBookingId
              )
          );
          if (pageContainGroup !== -1) {
            const targetOrderItemIndex = (
              (old.pages as Array<any>)[pageContainGroup].data
                .results as IBooking[]
            ).findIndex((booking) => booking.bookingId === deletedBookingId);
            return produce(old, (draftOld: any) => {
              draftOld.pages = produce(draftOld.pages, (draftPages: any) => {
                draftPages[pageContainGroup].data.results = produce(
                  draftPages[pageContainGroup].data.results,
                  (draftTargetPageData: IBooking[]) => {
                    draftTargetPageData.splice(targetOrderItemIndex, 1);

                    return draftTargetPageData;
                  }
                );
                return draftPages;
              });

              return draftOld;
            });
          } else queryClient.invalidateQueries(qKey);
        } else queryClient.invalidateQueries(qKey);
      });
    } else queryClient.invalidateQueries(qKey);
  };

  const handleEditBookingQueryData = (edittedBooking: IBooking) => {
    const oldServerData = queryClient.getQueryData(qKey);

    if (oldServerData && overwrite) {
      queryClient.setQueryData(qKey, (old: any) => {
        if (old) {
          //find page contain deleted item
          let pageContainGroup: number = (old.pages as Array<any>).findIndex(
            (page) =>
              (page.data.results as IBooking[]).find(
                (booking) => booking.bookingId === edittedBooking.bookingId
              )
          );
          if (pageContainGroup !== -1) {
            const targetOrderItemIndex = (
              (old.pages as Array<any>)[pageContainGroup].data
                .results as IBooking[]
            ).findIndex(
              (booking) => booking.bookingId === edittedBooking.bookingId
            );
            return produce(old, (draftOld: any) => {
              draftOld.pages = produce(draftOld.pages, (draftPages: any) => {
                draftPages[pageContainGroup].data.results = produce(
                  draftPages[pageContainGroup].data.results,
                  (draftTargetPageData: IBooking[]) => {
                    if (targetOrderItemIndex !== -1) {
                      const item = draftTargetPageData[targetOrderItemIndex];
                      Object.assign(item, edittedBooking);
                    }

                    return draftTargetPageData;
                  }
                );
                return draftPages;
              });

              return draftOld;
            });
          } else queryClient.invalidateQueries(qKey);
        } else queryClient.invalidateQueries(qKey);
      });
    } else queryClient.invalidateQueries(qKey);
  };

  // no pagination list
  const handleAddBookingQueryDataWithoutPagination = (
    addedBooking: IBooking
  ) => {
    const oldServerData = queryClient.getQueryData(qKeyWithoutPagination);
    if (oldServerData && overwrite) {
      queryClient.setQueryData<DefiniteAxiosQueryData<IBooking[]>>(
        qKeyWithoutPagination,
        (old) => {
          if (old) {
            return produce(old, (draftOld) => {
              if (appendPos === "start") draftOld.data.unshift(addedBooking);
              else draftOld.data.push(addedBooking);
              return draftOld;
            });
          } else return old;
        }
      );
    } else queryClient.invalidateQueries(qKey);
  };

  const handleEditBookingQueryDataWithoutPagination = (
    edittedBooking: IBooking
  ) => {
    const oldServerData = queryClient.getQueryData(qKeyWithoutPagination);
    if (oldServerData && overwrite) {
      queryClient.setQueryData<DefiniteAxiosQueryData<IBooking[]>>(
        qKeyWithoutPagination,
        (old) => {
          if (old) {
            return produce(old, (draftOld) => {
              const item = draftOld.data.find(
                (booking) => booking.bookingId === edittedBooking.bookingId
              );
              if (item) Object.assign(item, edittedBooking);
              return draftOld;
            });
          } else return old;
        }
      );
    } else queryClient.invalidateQueries(qKey);
  };

  const handleDeleteBookingQueryDataWithoutPagination = (
    deletedBookingId: string
  ) => {
    const oldServerData = queryClient.getQueryData(qKeyWithoutPagination);
    if (oldServerData && overwrite) {
      queryClient.setQueryData<DefiniteAxiosQueryData<IBooking[]>>(
        qKeyWithoutPagination,
        (old) => {
          if (old) {
            return produce(old, (draftOld) => {
              const itemIndex = draftOld.data.findIndex(
                (booking) => booking.bookingId === deletedBookingId
              );
              if (itemIndex !== -1) draftOld.data.splice(itemIndex, 1);
              return draftOld;
            });
          } else return old;
        }
      );
    } else queryClient.invalidateQueries(qKey);
  };
  return {
    handleAddBookingQueryData,
    handleDeleteBookingQueryData,
    handleEditBookingQueryData,
    handleAddBookingQueryDataWithoutPagination,
    handleEditBookingQueryDataWithoutPagination,
    handleDeleteBookingQueryDataWithoutPagination,
  };
};

export default useSetBookingQueryData;
