import React, { useEffect, useRef, useState } from "react";
import PDFIcon from "../../Assets/adobePdfIcon.svg";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { QuoteAttachmentItem, QuoteStorage } from "../../models/quote";
import {
  StyledCloseIcon,
  StyledQuotesAttachmentItemsLabel,
  StyledQuotesAttachmentTitle,
  StyledQuotesAttachmentsItem,
  StyledQuotesAttachmentsPopup,
  StyledQuotesAttachmentsPopupContainer,
  StyledQuotesAttachmentsPopupTitle,
} from "../MainStyledComponents";
import ExcelIcon from "../../Assets/excelIcon.svg";
import DocIcon from "../../Assets/microsoftIcon.svg";
import { DoxleFile } from "../../../Models/files";
import { ClickAwayListener, Fade, Popper } from "@mui/material";
import { useDoxleQuoteStore } from "../../store/useDoxleQuoteStore";
import { shallow } from "zustand/shallow";

interface QuoteAttachmentProps {
  attachments: QuoteStorage[];
  requestId: string;
  selectedAttachment?: string | null;
  setSelectedAttachment: React.Dispatch<React.SetStateAction<string | null>>;
}
const QuoteAttachmentsDisplayer: React.FC<QuoteAttachmentProps> = ({
  requestId,
  attachments,
  selectedAttachment,
  setSelectedAttachment,
}) => {
  const { doxleFont, THEME_COLOR } = useDoxleThemeStore((state) => ({
    THEME_COLOR: state.doxleThemeColor,
    doxleFont: state.doxleFont,
  }));
  const firstAttachmentIconSource = attachments[0].fileType
    .toLowerCase()
    .includes("pdf")
    ? PDFIcon
    : attachments[0].fileType.toLowerCase().includes("doc") ||
      attachments[0].fileType.toLowerCase().includes("docx")
    ? DocIcon
    : attachments[0].fileType.toLowerCase().includes("xlsx")
    ? ExcelIcon
    : attachments[0].url;

  const { handleOpenAttachmentPopper } = useDoxleQuoteStore(
    (state) => ({
      handleOpenAttachmentPopper: state.handleOpenAttachmentPopper,
    }),
    shallow
  );

  return (
    <StyledQuotesAttachmentsItem
      onClick={(event) => {
        event.preventDefault();
        event.stopPropagation();
        if (attachments?.length > 1)
          handleOpenAttachmentPopper(event, attachments);
        else window.open(attachments[0].url, "_blank");
      }}
      // onClick={() => window.open(attachments[0].url, "_blank")}
      style={{ justifyContent: "flex-start" }}
    >
      <img
        src={firstAttachmentIconSource}
        alt="icon-type"
        style={{ height: "60%", aspectRatio: 1 }}
      />
      <StyledQuotesAttachmentTitle
        $themeColor={THEME_COLOR}
        $doxleFont={doxleFont}
      >
        {attachments[0]?.fileName}
      </StyledQuotesAttachmentTitle>
      {attachments.length > 1 && (
        <StyledQuotesAttachmentItemsLabel
          $themeColor={THEME_COLOR}
          $doxleFont={doxleFont}
        >
          +{attachments.length - 1} items
        </StyledQuotesAttachmentItemsLabel>
      )}
    </StyledQuotesAttachmentsItem>
  );
};

export default QuoteAttachmentsDisplayer;
