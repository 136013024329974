import { PricebookSupplierRate } from "./Pricebook";

export type TPricebookTableHeaders =
  | "Item"
  | "Supplier"
  | "Unit"
  | "Unit Price"
  | "Modified";
// |"Swatches"

export interface IPricebookTableHeaders {
  headerName: TPricebookTableHeaders;
  isDisplayed: boolean;
}

export const PRICEBOOK_TABLE_HEADER_LIST: IPricebookTableHeaders[] = [
  {
    headerName: "Supplier",
    isDisplayed: true,
  },
  {
    headerName: "Unit Price",
    isDisplayed: true,
  },
  {
    headerName: "Unit",
    isDisplayed: true,
  },
  {
    headerName: "Modified",
    isDisplayed: true,
  },
  // {
  //   headerName: "Swatches",
  //   isDisplayed: true,
  // },
];
export interface IPricebookItemGroupInfo {
  pricebookId: string;
  pricebookDescription: string;
}
export interface IPricebookRateGroup {
  pricebookItem: IPricebookItemGroupInfo[];
  rateCounts: number[];
  rateItems: PricebookSupplierRate[];
}
export interface IPricebookTableContext {
  isErrorFetchingRateList: boolean;
  pricebookLength: number;
}
