import React, { memo } from "react";
import { BasicCoreDocket } from "../../../Models/dockets";
import { StyledCoreDocketList } from "../PricebookList/StyledComponents";
import { IoIosCloseCircle } from "react-icons/io";
import { useEditPricebookItemContext } from "./EditPricebookItem";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { produce } from "immer";
type Props = {
  item: BasicCoreDocket;
  itemIndex: number;
  onRemoveDocket: (index: number) => void;
};

const SelectedCoreDocketItem = memo(
  ({ item, itemIndex, onRemoveDocket }: Props) => {
    const { doxleThemeColor } = useDoxleThemeStore((state) => ({
      doxleThemeColor: state.doxleThemeColor,
    }));

    return (
      <StyledCoreDocketList>
        {item.coreDocketNumber + " " + item.coreDocketName}
        <IoIosCloseCircle
          color={doxleThemeColor.primaryFontColor}
          size={20}
          className="remove-icon"
          onClick={() => onRemoveDocket(itemIndex)}
        />
      </StyledCoreDocketList>
    );
  }
);

export default SelectedCoreDocketItem;
