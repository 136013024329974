import React, { useEffect, useState } from "react";
import {
  InboxFilterOption,
  NBGroupMode,
  NBMenu,
  useInboxStore,
} from "../../../InboxStore/useInboxStore";
import { useDoxleCurrentContextStore } from "../../../../DoxleGeneralStore/useDoxleCurrentContext";
import useDoxleNotificationStore from "../../../../DoxleGeneralStore/useDoxleNotificationStore";
import { shallow } from "zustand/shallow";
import useSetProjectWithDocketListQueryData from "../../../../CoreContent/QueryDataHooks/SetQueryDataHooks/useSetProjectWithDocketListQueryData";
import { Docket, NEW_DOCKET_TEMPLATE } from "../../../../Models/dockets";
import DocketQuery from "../../../../Services/QueryHooks/docketQueryAPI";
import { useDoxleAuthStore } from "../../../../DoxleGeneralStore/useDoxleAuthStore";
import useSetDocketListQueryData from "../../../../CoreContent/QueryDataHooks/SetQueryDataHooks/useSetDocketListQueryData";

type Props = {};

interface NoticeBoardMenu {
  handleClickViewMode: (item: NBMenu) => void;
  handleAddNewNoticeBoardDocket: () => void;
  isAddingNotice: boolean;
  handleClickHashIcon: (e: React.MouseEvent<SVGElement, MouseEvent>) => void;
  anchorFilterPopperList: SVGElement | null;
  handleCloseFilterMenu: () => void;
  handleClickFilter: (type: InboxFilterOption) => void;
  handleRemoveFilter: (filter: InboxFilterOption) => void;
  handleSetGroupMode: (mode: NBGroupMode) => void;
  handleRemoveStatusFilter: (statusName: string) => void;
}
const useNoticeBoardMenu = (props: Props): NoticeBoardMenu => {
  const [anchorFilterPopperList, setanchorFilterPopperList] =
    useState<SVGElement | null>(null);
  const {
    setInboxViewMode,

    setFilterDocketListQuery,
    setFilterProjectListWithDockets,
    filterDocketListQuery,
    filterProjectListWithDockets,
    inboxFilterOptions,
    setInboxFilterOptions,
    addInboxFilter,
    removeInboxFilter,
    inboxGroupMode,
    setInboxGroupMode,
    setStatusFilterValue,
    inboxViewMode,
  } = useInboxStore((state) => ({
    setInboxViewMode: state.setInboxViewMode,
    filterDocketListQuery: state.filterDocketListQuery,
    filterProjectListWithDockets: state.filterProjectListWithDockets,
    setFilterDocketListQuery: state.setFilterDocketListQuery,
    setFilterProjectListWithDockets: state.setFilterProjectListWithDockets,
    inboxFilterOptions: state.inboxFilterOptions,
    setInboxFilterOptions: state.setInboxFilterOptions,
    addInboxFilter: state.addInboxFilter,
    removeInboxFilter: state.removeInboxFilter,
    inboxGroupMode: state.inboxGroupMode,
    setInboxGroupMode: state.setInboxGroupMode,
    setStatusFilterValue: state.setStatusFilterValue,
    inboxViewMode: state.inboxViewMode,
  }));
  const { user } = useDoxleAuthStore((state) => ({
    user: state.user,
  }));
  const { company } = useDoxleCurrentContextStore((state) => ({
    company: state.currentCompany,
  }));

  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );

  const { handleAddDocket: handleAddMyNoticeDockets } =
    useSetDocketListQueryData({
      filter: {
        ...filterDocketListQuery,
        ball_in_court: user?.userId,
        archived: false,
      },
    });

  const { handleAddDocket: handleAddAllNoticeDockets } =
    useSetDocketListQueryData({
      filter: {
        ...filterDocketListQuery,
        ball_in_court: undefined,
        archived: false,
      },
    });

  const { handleAddDocket: handleAddArchiveDockets } =
    useSetDocketListQueryData({
      filter: {
        ...filterDocketListQuery,
        ball_in_court: undefined,
        archived: true,
      },
    });
  const onSuccessCb = (newDocket?: Docket) => {
    if (newDocket) {
      if (inboxViewMode === "Archived") handleAddArchiveDockets(newDocket);
      else {
        if (newDocket.ballInCourt === user?.userId)
          handleAddMyNoticeDockets(newDocket);

        handleAddAllNoticeDockets(newDocket);
      }
    }
  };
  const addDocketQuery = DocketQuery.useAddDocketQuery({
    filter: filterDocketListQuery,
    company,
    showNotification,

    onSuccessCb: onSuccessCb,
  });

  const handleAddNewNoticeBoardDocket = () => {
    const newDocket = NEW_DOCKET_TEMPLATE({
      docketName: "New Notice",
      isNoticeboard: true,
      createdBy: user ? (user.userId as string) : "",
      company: company?.companyId ?? "",
      ballInCourt: inboxViewMode === "My Notices" ? user?.userId : undefined,
      isArchived: inboxViewMode === "Archived" ? true : false,
    });
    addDocketQuery.mutate(newDocket);
  };

  const handleClickViewMode = (item: NBMenu) => {
    setInboxViewMode(item);
    localStorage.setItem("lastNbViewMode", item);
    if (item === "Archived") {
      setFilterDocketListQuery({
        archived: true,
      });
      setFilterProjectListWithDockets({ archived: true });
    } else {
      setFilterDocketListQuery({
        archived: false,
      });
      setFilterProjectListWithDockets({ archived: false });
    }
  };

  const handleGetInitialViewModeStorage = () => {
    try {
      const lastMode = localStorage.getItem("lastNbViewMode");
      if (lastMode) {
        setInboxViewMode(lastMode as NBMenu);
        if ((lastMode as NBMenu) === "Archived") {
          setFilterDocketListQuery({ archived: true });
          setFilterProjectListWithDockets({ archived: true });
        }
      }
    } catch (error) {
      console.log("ERROR handleGetInitialViewModeStorage:", error);
    }
  };

  useEffect(() => {
    handleGetInitialViewModeStorage();
  }, []);

  const handleClickHashIcon = (e: React.MouseEvent<SVGElement, MouseEvent>) => {
    setanchorFilterPopperList(e.currentTarget);
  };

  const handleCloseFilterMenu = () => {
    setanchorFilterPopperList(null);
  };
  const handleSetGroupMode = (mode: NBGroupMode) => {
    setInboxGroupMode(mode);
    handleCloseFilterMenu();
  };

  const handleClickFilter = (type: InboxFilterOption) => {
    const isFilteredItemOn: boolean =
      inboxFilterOptions.find((filter) => filter === type) !== undefined;
    if (!isFilteredItemOn) {
      setFilterDocketListQuery({
        due:
          type === "Due Today"
            ? "today"
            : type === "Due This Month"
            ? "month"
            : "week",
      });
      setFilterProjectListWithDockets({
        due:
          type === "Due Today"
            ? "today"
            : type === "Due This Month"
            ? "month"
            : "week",
      });

      let newFilter = [type];

      sessionStorage.setItem("dueFilter", JSON.stringify(newFilter));
      addInboxFilter(type);
      //save to async storage
    } else {
      sessionStorage.setItem("dueFilter", JSON.stringify([]));
      removeInboxFilter(type);

      setFilterDocketListQuery({
        due: undefined,
      });
      setFilterProjectListWithDockets({
        due: undefined,
      });
    }
    handleCloseFilterMenu();
  };

  const handleRemoveFilter = (filter: InboxFilterOption) => {
    sessionStorage.setItem("dueFilter", JSON.stringify([]));
    removeInboxFilter(filter);

    setFilterDocketListQuery({
      due: undefined,
    });
    setFilterProjectListWithDockets({
      due: undefined,
    });
  };

  const handleRemoveStatusFilter = (statusName: string) => {
    setStatusFilterValue(statusName);
  };

  return {
    handleClickViewMode,
    handleAddNewNoticeBoardDocket,
    isAddingNotice: addDocketQuery.isLoading,
    handleClickHashIcon,
    anchorFilterPopperList,
    handleCloseFilterMenu,
    handleClickFilter,
    handleRemoveFilter,
    handleSetGroupMode,
    handleRemoveStatusFilter,
  };
};

export default useNoticeBoardMenu;
