import React from "react";
import { StyledProjectStatusList } from "./StyledProjectComponents";
import useGetProjectStatus from "../QueryDataHooks/GetQueryDataHooks/useGetProjectStatus";
import Skeleton from "@mui/material/Skeleton";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import ProjectStatusItem from "./ProjectStatusItem";
import { useShallow } from "zustand/react/shallow";

type Props = {};

const ProjectStatusList = (props: Props) => {
  const { doxleThemeColor } = useDoxleThemeStore(
    useShallow((state) => ({
      doxleThemeColor: state.doxleThemeColor,
    }))
  );
  const {
    projectStatusList,
    isFetchingProjectStatus,
    isErrorFetchingProjectStatus,
  } = useGetProjectStatus({});
  return (
    <StyledProjectStatusList
      animate={{
        y: [-50, 0],
        opacity: [0, 1],
      }}
      transition={{
        duration: 0.2,
        damping: 16,
      }}
      exit={{
        y: [0, -50],
        opacity: [1, 0],
      }}
    >
      {isFetchingProjectStatus && (
        <Skeleton
          variant="text"
          height={10}
          sx={{
            flex: 1,
            bgcolor: doxleThemeColor.skeletonColor,
            ml: "8px",
          }}
        />
      )}
      {!isFetchingProjectStatus &&
        projectStatusList.map((status) => (
          <ProjectStatusItem statusItem={status} key={status.statusId} />
        ))}
    </StyledProjectStatusList>
  );
};

export default ProjectStatusList;
