import React from "react";
import { TISODate } from "../../../../Models/dateFormat";
import dayjs, { Dayjs } from "dayjs";
import { DatePicker, DatePickerProps } from "@mui/x-date-pickers/DatePicker";
import {
  BaseSingleInputFieldProps,
  DateValidationError,
  FieldSection,
} from "@mui/x-date-pickers/models";
import { useDoxleThemeStore } from "../../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import { StyledQADatePickerCustomContainer } from "./StyledComponentCommonQA";
import CircularProgress from "@mui/material/CircularProgress";
import { customDatePickerStyle } from "../../../../Models/modelHelper";
var advancedFormat = require("dayjs/plugin/advancedFormat");
dayjs.extend(advancedFormat);
type Props = {};

interface ButtonFieldProps
  extends BaseSingleInputFieldProps<
    Dayjs | null,
    Dayjs,
    FieldSection,
    DateValidationError
  > {
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  isUpdating?: boolean;
}

function ButtonField(props: ButtonFieldProps) {
  const {
    setOpen,
    label,
    isUpdating,
    InputProps: { ref } = {},
    inputProps: { "aria-label": ariaLabel } = {},
  } = props;
  const { doxleThemeColor, doxleFont, currentTheme } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
      currentTheme: state.currentTheme,
    }),
    shallow
  );
  return (
    <StyledQADatePickerCustomContainer
      onClick={(e) => {
        // e.preventDefault();
        // e.stopPropagation();
        setOpen?.((prev) => !prev);
      }}
      ref={ref}
      aria-label={ariaLabel}
      layout="position"
      transition={{
        duration: 0.2,
        damping: 16,
      }}
    >
      {!label ? (
        <span className="null-text">Select due date</span>
      ) : (
        <span className="date-text-content">{label}</span>
      )}

      {isUpdating && (
        <CircularProgress
          style={{ color: doxleThemeColor.primaryFontColor }}
          size={14}
        />
      )}
    </StyledQADatePickerCustomContainer>
  );
}

function ButtonDatePicker({
  isUpdating,
  ...props
}: Omit<DatePickerProps<Dayjs>, "open" | "onOpen" | "onClose"> & {
  isUpdating?: boolean;
}) {
  const [open, setOpen] = React.useState(false);
  const { doxleThemeColor } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
    }),
    shallow
  );

  const { field, ...rest } = customDatePickerStyle<any>(doxleThemeColor);

  return (
    <DatePicker
      slots={{ field: ButtonField as any, ...props.slots }}
      slotProps={{ field: { setOpen, isUpdating } as any, ...rest }}
      {...props}
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
    />
  );
}

export default function QACustomDatePicker({
  value,
  onChange,
  isUpdating,
}: {
  value: TISODate | null;
  onChange?: ((value: dayjs.Dayjs | null) => void) | undefined;
  isUpdating?: boolean;
}) {
  return (
    <ButtonDatePicker
      label={value ? `${dayjs(value).format("MMM Do, YYYY")}` : null}
      value={value ? dayjs(value) : null}
      onChange={(newValue) => {
        onChange && onChange(newValue);
      }}
      isUpdating={isUpdating}
    />
  );
}
