import React, { useEffect, useState } from "react";
import useBookingProjectStore from "../../../../store/useBookingProjectStore";
import { shallow } from "zustand/shallow";
import { useDoxleAuthStore } from "../../../../../DoxleGeneralStore/useDoxleAuthStore";
import { useDoxleCurrentContextStore } from "../../../../../DoxleGeneralStore/useDoxleCurrentContext";
import useDoxleNotificationStore from "../../../../../DoxleGeneralStore/useDoxleNotificationStore";
import { IBooking } from "../../../../Models/booking";
import { BookingQueryAPI } from "../../../../QueryAPI/bookingQueryAPI";
import { formatTISODate } from "../../../../../Utilities/FunctionUtilities";
import { useEditProjectBookingContext } from "../EditBookingProject";

type Props = {};

interface EditBookingDocketAndDate {
  handleSelectDateRange: (value: [Date | null, Date | null]) => void;
  isUpdatingDate: boolean;
  localStartDate: Date | null;
  localEndDate: Date | null;
}
const useEditBookingDocketAndDate = (
  props: Props
): EditBookingDocketAndDate => {
  const { filterBookingProjectQuery, edittedBooking, updateEdittedBooking } =
    useBookingProjectStore(
      (state) => ({
        filterBookingProjectQuery: state.filterBookingProjectQuery,
        edittedBooking: state.edittedBooking,
        updateEdittedBooking: state.updateEdittedBooking,
      }),
      shallow
    );
  const { setStartDate, setEndDate } =
    useEditProjectBookingContext();
  const [localStartDate, setlocalStartDate] = useState(
    edittedBooking?.startDate ? new Date(edittedBooking?.startDate) : null
  );
  const [localEndDate, setlocalEndDate] = useState(
    edittedBooking?.endDate ? new Date(edittedBooking?.endDate) : null
  );

  const { company } = useDoxleCurrentContextStore(
    (state) => ({
      company: state.currentCompany,
    }),
    shallow
  );
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );

  const { update } = BookingQueryAPI.useMutateBookingQuery({
    company,

    showNotification,
    filter: filterBookingProjectQuery,
  });

  const handleSelectDateRange = (value: [Date | null, Date | null]) => {
    const [start, end] = value;
    setStartDate(start);
    setEndDate(end);

    // if (edittedBooking) {
    //   console.log("UPDATE DATE");
    //   if (start) {
    //     update.mutate({
    //       bookingId: edittedBooking.bookingId,
    //       updateData: {
    //         startDate: formatTISODate(start),
    //       },
    //     });
    //   }
    //   if (end)
    //     update.mutate({
    //       bookingId: edittedBooking.bookingId,
    //       updateData: {
    //         endDate: formatTISODate(end),
    //       },
    //     });
    // }
    setlocalStartDate(start);
    setlocalEndDate(end);
    // if (edittedBooking) {
    //   update.mutate({
    //     bookingId: edittedBooking.bookingId,
    //     updateData: {
    //       startDate: start ? formatTISODate(start) : null,
    //       endDate: end ? formatTISODate(end) : null,
    //     },
    //   });
    // }
  };

  return {
    handleSelectDateRange,
    isUpdatingDate: update.isLoading,
    localStartDate,
    localEndDate,
  };
};

export default useEditBookingDocketAndDate;
