import { motion } from "framer-motion";
import styled from "styled-components";

export const StyledMenuToggledApp = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  margin-left: 8px;
`;

export const StyledMenuControlBtn = styled(motion.div)<{}>`
  height: calc(100%);
  width: 49px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.4s all ease;
  margin-right: 4px;
  .icon-dropdown {
    margin-left: 4px;
    transform: translateY(0) rotate(0);
    transition: transform 0.3s ease; /* Smooth transition for hover effect */
  }

  &:hover .icon-dropdown {
    animation: hoverAnimation 0.3s ease forwards;
  }

  @keyframes hoverAnimation {
    0%,
    100% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-3px);
    }
  }
`;

export const StyledPopoverArrow = styled.div`
  &::before {
    background-color: ${(p) => p.theme.staticMenuColor.staticBg};
    content: "";
    display: block;
    position: absolute;
    width: 12px;
    height: 12px;
    top: -6px;
    transform: rotate(45deg);
    left: calc(50% - 6px);
  }
`;
export const StyledFontMenuPopover = styled(motion.div)`
  display: flex;
  flex-direction: column;

  width: 200px;
  padding: 10px 0px;
  background-color: ${(p) => p.theme.staticMenuColor.staticBg};
`;

export const StyledFontMenuItem = styled.div`
  position: relative;
  width: calc(100% - 60px);
  padding: 8px 10px 8px 50px;
  display: flex;
  align-items: center;

  cursor: pointer;
  transition: 0.4s all ease;
  &:hover {
    background-color: ${(p) => p.theme.staticMenuColor.staticHover};
    transition: 0.4s all ease;
  }
  .menu-text {
    color: ${(p) => p.theme.staticMenuColor.staticFontColor};
    font-size: 14px;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    margin-left: 8px;
  }
`;
