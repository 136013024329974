import React, { useCallback } from "react";

import { shallow } from "zustand/shallow";
import { AnimatePresence } from "framer-motion";
import { produce } from "immer";
import { AiFillCloseCircle } from "react-icons/ai";
import ExcelIcon from "../../../Assets/Images/excel.png";
import WordIcon from "../../../Assets/Images/word.png";
import PDFIcon from "../../../Assets/Images/pdf.png";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import {
  StyledFileImage,
  StyledFileItemContainer,
  StyledFilePickerDisplayerContainer,
} from "./MailReply/StyledMailReply";
import DoxleIconButton from "../../../DoxleDesignPattern/DoxleButtons/DoxleIconButton";
import {
  getFileIconUrl,
  isImageFile,
} from "../../../Utilities/FunctionUtilities";

type Props = {
  addedAttachments: File[];
  setAddedAttachments: React.Dispatch<React.SetStateAction<File[]>>;
};

const AttachmentPickerDisplayer = ({
  addedAttachments,
  setAddedAttachments,
}: Props) => {
  const handleRemoveFile = useCallback(
    (index: number) => {
      setAddedAttachments((prev) =>
        produce(prev, (draft) => {
          draft = draft.filter((_, idx) => idx !== index);
          return draft;
        })
      );
    },
    [setAddedAttachments]
  );

  return (
    <StyledFilePickerDisplayerContainer>
      <AnimatePresence>
        {addedAttachments.map((file, index) => (
          <FileItem
            file={file}
            fileIndex={index}
            handleRemoveFile={handleRemoveFile}
            key={`addedFile#${file.name}`}
          />
        ))}
      </AnimatePresence>
    </StyledFilePickerDisplayerContainer>
  );
};

export default AttachmentPickerDisplayer;

const FileItem = React.memo(
  (props: {
    file: File;
    handleRemoveFile: (index: number) => void;
    fileIndex: number;
  }) => {
    const { file, handleRemoveFile, fileIndex } = props;
    const { doxleThemeColor } = useDoxleThemeStore(
      (state) => ({
        doxleThemeColor: state.doxleThemeColor,
        doxleFont: state.doxleFont,
      }),
      shallow
    );

    return (
      <StyledFileItemContainer
        layout
        initial={false}
        animate={{ y: [10, 0], opacity: [0, 1] }}
        exit={{
          y: [0, 10],
          opacity: [1, 0],
        }}
        transition={{
          duration: 0.2,
        }}
        style={
          !isImageFile(file.name)
            ? {
                border: `1px solid ${doxleThemeColor.rowBorderColor}`,
                display: "flex",
                backgroundColor: doxleThemeColor.primaryContainerColor,
              }
            : undefined
        }
      >
        <DoxleIconButton
          iconSource={
            <AiFillCloseCircle
              size={"2rem"}
              color={doxleThemeColor.primaryFontColor}
            />
          }
          buttonSize={30}
          disableAnimation
          onClick={() => handleRemoveFile(fileIndex)}
          buttonWrapperStyle={{
            position: "absolute",
            zIndex: 10,
            top: "-1.5rem",
            right: "-0.5rem",
          }}
        />

        <StyledFileImage
          src={
            isImageFile(file.name)
              ? URL.createObjectURL(file)
              : getFileIconUrl(file.name)
          }
          style={{ height: isImageFile(file.name) ? "100%" : "auto" }}
        />
      </StyledFileItemContainer>
    );
  }
);
