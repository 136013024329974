import { Skeleton } from "@mui/material";

import React from "react";
import { Virtuoso } from "react-virtuoso";

import { shallow } from "zustand/shallow";
import { useDoxleThemeStore } from "../../../../DoxleGeneralStore/useDoxleThemeStore";
import {
  StyledQAItemListSkeleton,
  StyledSkeletonBox,
} from "./StyledComponentQAListDetail";

type Props = {};

function QAItemListSkeleton(props: Props) {
  const { doxleThemeColor, doxleFont, currentTheme } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
      currentTheme: state.currentTheme,
    }),
    shallow
  );
  const emptyArray: string[] = Array(3).fill("");
  const disable = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    e.currentTarget.scrollTo({
      top: 0,
      left: 0,
    });
  };
  return (
    <StyledQAItemListSkeleton>
      <Virtuoso
        style={{
          width: "100%",
          height: "100%",
        }}
        onScroll={(e) => disable(e)}
        data={emptyArray}
        itemContent={() => (
          <StyledSkeletonBox $themeColor={doxleThemeColor}>
            <Skeleton
              animation="wave"
              sx={{
                bgcolor: doxleThemeColor.skeletonColor,
                opacity: 0.4,
                height: "100%",
                aspectRatio: 1.4,
              }}
              variant="rounded"
            />

            <div className="history-skel-wrapper">
              <Skeleton
                animation="wave"
                style={{ height: "1.4rem", width: "90%" }}
                variant="rounded"
                sx={{
                  bgcolor: doxleThemeColor.skeletonColor,
                  mb: "1.4rem",
                }}
              />
              <Skeleton
                animation="wave"
                style={{ height: "1.4rem", width: "70%" }}
                variant="rounded"
                sx={{
                  bgcolor: doxleThemeColor.skeletonColor,
                  mb: "1.4rem",
                  opacity: 0.4,
                }}
              />
              <Skeleton
                animation="wave"
                style={{ height: "1.4rem", width: "70%" }}
                variant="rounded"
                sx={{
                  bgcolor: doxleThemeColor.skeletonColor,
                  mb: "1.4rem",
                  opacity: 0.4,
                }}
              />
            </div>
          </StyledSkeletonBox>
        )}
      />
    </StyledQAItemListSkeleton>
  );
}

export default QAItemListSkeleton;
