import React from "react";
import { StyledCommentUserInitialContainer } from "./StyledComponentCommentUserInitial";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import { TRgbaFormat, editRgbaAlpha } from "../../Utilities/FunctionUtilities";

type Props = {
  userFirstName: string;
  userLastName: string;
  focus?: boolean;
  size?: number;
  outlinedColor?: string;
  wrapperStyle?: React.CSSProperties;
};

const CommentUserInitial = ({
  userFirstName,
  userLastName,
  focus = true,
  size = 16,
  outlinedColor,
  wrapperStyle,
}: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }),
    shallow
  );

  const containerAnimatedVariants = {
    initial: {
      border: `1px solid ${editRgbaAlpha({
        rgbaColor: doxleThemeColor.primaryFontColor as TRgbaFormat,
        alpha: "0.4",
      })}`,
      color: editRgbaAlpha({
        rgbaColor: doxleThemeColor.primaryFontColor as TRgbaFormat,
        alpha: "0.4",
      }),
    },
    focus: {
      border: `1px solid ${
        outlinedColor ? outlinedColor : doxleThemeColor.primaryFontColor
      }`,
      color: outlinedColor ? outlinedColor : doxleThemeColor.primaryFontColor,
      transition: {
        duration: 0.4,
      },
    },
  };
  return (
    <StyledCommentUserInitialContainer
      $themeColor={doxleThemeColor}
      $doxleFont={doxleFont}
      $focus={focus}
      $size={size}
      variants={containerAnimatedVariants}
      initial="initial"
      animate={focus ? "focus" : "initial"}
      style={wrapperStyle}
    >
      {userFirstName[0] ? userFirstName[0] : ""}
      {userLastName[0] ? userLastName[0] : ""}
    </StyledCommentUserInitialContainer>
  );
};

export default CommentUserInitial;
