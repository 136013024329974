import { shallow } from "zustand/shallow";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { StyledDocketDetailMenu, StyledDocketDetailMenuItem } from "./BookingDocketDetail/StyledComponentBookingDocketDetail";
import useBookingViewStore from "../../store/useBookingViewStore";
import { motion } from "framer-motion";

const BookingDocketMenu = () => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }),
    shallow
  );

  const { currentMenuTab, setCurrentMenuTab } = useBookingViewStore(
    (state) => ({
      setCurrentMenuTab: state.setCurrentMenuTab,
      currentMenuTab: state.currentMenuTab,
    }),
    shallow
  );
  
  return (
    <StyledDocketDetailMenu $themeColor={doxleThemeColor}>
      <StyledDocketDetailMenuItem
        $themeColor={doxleThemeColor}
        $doxleFont={doxleFont}
        $selected={currentMenuTab === "Order"}
        onClick={() => setCurrentMenuTab("Order")}
      >
        Order
        {currentMenuTab === "Order" && (
          <motion.div
            className="selected-effect"
            layoutId="selected-docket-order-menu"
          />
        )}
      </StyledDocketDetailMenuItem>

      <StyledDocketDetailMenuItem
        $themeColor={doxleThemeColor}
        $doxleFont={doxleFont}
        $selected={currentMenuTab === "Comment"}
        onClick={() => setCurrentMenuTab("Comment")}
      >
        Comment
        {currentMenuTab === "Comment" && (
          <motion.div
            className="selected-effect"
            layoutId="selected-docket-order-menu"
          />
        )}
      </StyledDocketDetailMenuItem>
    </StyledDocketDetailMenu>
  );
};

export default BookingDocketMenu;
