import React, { useEffect, useMemo, useRef, useState } from "react";
import { Autocomplete, Paper, TextField } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  AddQuoteRequestContainer,
  QuoteRequestAutocompleteSx,
  QuoteRequestTextField,
} from "./AddStyledComponents";
import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { shallow } from "zustand/shallow";
import {
  AddQuoteRequests as IAddQuoteRequests,
  QuoteRequest,
} from "../../models/quote";
import { useDoxleAuthStore } from "../../../DoxleGeneralStore/useDoxleAuthStore";
import { useDoxleCurrentContextStore } from "../../../DoxleGeneralStore/useDoxleCurrentContext";
import useDoxleNotificationStore from "../../../DoxleGeneralStore/useDoxleNotificationStore";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { ContactCompany } from "../../../Models/addressBook";
import QuotesQueryAPI from "../../QueryAPI/quotesQuery";
// import { useEditDocketSideScreenStore } from "../../CoreContent/EditDocketSideScreen/EditDocketSideScreenStore/useEditDocketSideScreenStore";
import {
  editRgbaAlpha,
  formatDate,
} from "../../../Utilities/FunctionUtilities";

import {
  AddQuotesButtonStyled,
  AddQuotesButtonWrapper,
  AttachmentWrapper,
  DatePickerContainer,
  QuoteAutoCompleteContainer,
  QuoteAutoCompleteIndividualBox,
  QuoteBottomHalfBox,
  QuoteBottomHalfContainer,
  SelectedSupplierContainer,
  StyledQuoteDateInput,
  StyledQuoteDetailLabelText,
  StyledQuoteWacherSectionContainer,
  StyledSelectTaskContainer,
  StyledSpinner,
} from "../QuoteRequestStyledComponents";

import PDFIcon from "../../Assets/adobePdfIcon.svg";
import MicrosoftIcon from "../../Assets/microsoftIcon.svg";
import ExcelIcon from "../../Assets/excelIcon.svg";
import PagesIcon from "../../Assets/pageIcon.svg";
import ScopeOfWorkAPI from "../../QueryAPI/scopeOfWorkQueryAPI";
import { ExpandMore } from "@mui/icons-material";
import { useDoxleQuoteStore } from "../../store/useDoxleQuoteStore";

import { useEditDocketSideScreenStore } from "../../../CoreContent/EditDocketSideScreen/EditDocketSideScreenStore/useEditDocketSideScreenStore";
import QuoteAttachments from "./QuoteAttachments";
import QuoteDatePicker from "./QuoteDatePicker";
import DoxleFilterTag from "../../../DoxleDesignPattern/DoxleFilterTag/DoxleFilterTag";
import QuoteSelectContractor from "./QuoteSelectContractor";
import AddedAttachmentItem from "./AddedAttachmentItem";
import { ScopeOfWorkGroup } from "../../../ScopeOfWorks/Model/scopeOfWorks";
import DoxleSelectTaskDropdown from "../../../DoxleDesignPattern/DoxleSelectTaskDropdown/DoxleSelectTaskDropdown";

dayjs.extend(utc);
dayjs.extend(timezone);

interface AddQuoteTableProps {
  quoteRequest?: QuoteRequest | undefined;
}

export const FILETYPE = {
  pdf: PDFIcon,
  docx: MicrosoftIcon,
  xlsx: ExcelIcon,
  pages: PagesIcon,
};

const AddQuoteRequests: React.FC<AddQuoteTableProps> = ({ quoteRequest }) => {
  const [suppliers, setSuppliers] = useState<ContactCompany[]>([]);
  const [attachments, setAttachments] = useState<File[]>([]);
  const [taskGroups, setTaskGroups] = useState<ScopeOfWorkGroup[]>([]);
  const [dueDate, setDueDate] = useState<Dayjs | null>(
    quoteRequest?.dueDate ? dayjs(quoteRequest.dueDate) : dayjs(new Date())
  );
  const [anchorTaskListPopover, setAnchorTaskListPopover] =
    useState<HTMLDivElement | null>(null);
  const docket = useEditDocketSideScreenStore(
    (state) => state.edittedDocket,
    shallow
  );

  const company = useDoxleCurrentContextStore(
    (state) => state.currentCompany,
    shallow
  );
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );
  const { THEME_COLOR, DOXLE_FONT, currentTheme } = useDoxleThemeStore(
    (state) => ({
      THEME_COLOR: state.doxleThemeColor,
      DOXLE_FONT: state.doxleFont,
      currentTheme: state.currentTheme,
    })
  );

  const { setView, filterGetQuoteRequest, setFilterGetQuoteRequest } =
    useDoxleQuoteStore(
      (state) => ({
        setView: state.setView,
        filterGetQuoteRequest: state.filterGetQuoteRequest,
        setFilterGetQuoteRequest: state.setFilterGetQuoteRequest,
      }),
      shallow
    );
  useEffect(() => {
    setFilterGetQuoteRequest({ withAttachment: true });
  }, []);
  const requestTextRef: React.MutableRefObject<HTMLInputElement | undefined> =
    useRef<HTMLInputElement>();

  const handleClose = () => setView("QuoteRequestsTable");

  const addRequestQuery = QuotesQueryAPI.useAddManyQuoteRequests({
    company,
    showNotification,

    filter: filterGetQuoteRequest,
    onSuccessCb: handleClose,
  });
  // const patchRequestQuery = QuotesQueryAPI.useUpdateQuoteRequest(
  //   company?.companyId,
  //   docket?.docketPk ?? "",
  //   showNotification,
  //   handleClose
  // );

  const saveNewQuote = () => {
    // formData.append("emailNow", "false");
    console.log(dueDate);
    const newRequest: IAddQuoteRequests = {
      coreDocket: null,
      docket: docket?.docketPk || "",
      suppliers: suppliers.map((supplier) => supplier.contactCompanyId!),
      emailBody: requestTextRef.current?.value ?? "",
      attachments: attachments,
      dueDate: dueDate ? dueDate.toISOString() : null,

      taskGroups: taskGroups.map((group) => group.scopeGroupId),
      emailNow: false,
    };
    addRequestQuery.mutate(newRequest);
  };

  const patchExistingQuote = () => {
    if (!quoteRequest?.requestId) return;
    // patchRequestQuery.mutate({
    //
    //   quoteRequestId: quoteRequest.requestId,
    //   updateBody: {
    //     requestId: quoteRequest.requestId,
    //     emailBody:
    //       requestTextRef?.current?.value !== quoteRequest.emailBody
    //         ? requestTextRef?.current?.value
    //         : undefined,
    //     attachments: attachments,
    //     dueDate:
    //       dueDate === quoteRequest.dueDate
    //         ? undefined
    //         : dueDate === null
    //         ? null
    //         : formatDate(dueDate.toISOString(), "yyyy-MM-dd"),
    //   },
    // });
  };

  const handleSaveRequest = () => {
    if (quoteRequest) patchExistingQuote();
    else saveNewQuote();
  };

  const onRemoveSupplier = (contactCompanyId: string) => {
    setSuppliers((prevState) =>
      prevState.filter(
        (prevSupplier) => prevSupplier.contactCompanyId !== contactCompanyId
      )
    );
  };

  const onRemoveScopeOfWorks = (scopeGroupId: string) => {
    setTaskGroups((prevState) =>
      prevState.filter((prevScope) => prevScope.scopeGroupId !== scopeGroupId)
    );
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <AddQuoteRequestContainer
        initial={{
          y: 10,
          opacity: 0,
        }}
        animate={{ y: 0, opacity: 1 }}
      >
        <StyledQuoteWacherSectionContainer
          $marginTop={34}
          $marginBottom={0}
          $gap={68}
        >
          <StyledQuoteDetailLabelText
            $themeColor={THEME_COLOR}
            $doxleFont={DOXLE_FONT}
            $opacity={0.7}
          >
            INCLUDED SUPPLIERS
          </StyledQuoteDetailLabelText>
          {suppliers.length > 0 && (
            <SelectedSupplierContainer>
              {suppliers.map((supplier: ContactCompany) => (
                <DoxleFilterTag
                  key={supplier.contactCompanyId}
                  itemData={supplier}
                  itemDisplayText={supplier.name}
                  removeItemFunction={() =>
                    onRemoveSupplier(supplier.contactCompanyId || "")
                  }
                  tagStyle={{
                    backgroundColor: editRgbaAlpha({
                      rgbaColor: THEME_COLOR.rowHoverColor,
                      alpha: "0.1",
                    }),
                  }}
                />
              ))}
            </SelectedSupplierContainer>
          )}
        </StyledQuoteWacherSectionContainer>
        <QuoteAutoCompleteContainer>
          <QuoteAutoCompleteIndividualBox>
            <StyledQuoteDetailLabelText
              $themeColor={THEME_COLOR}
              $doxleFont={DOXLE_FONT}
              $opacity={0.7}
            >
              ADD CONTRACTOR
            </StyledQuoteDetailLabelText>

            <QuoteSelectContractor
              suppliers={suppliers}
              setSuppliers={setSuppliers}
            />
          </QuoteAutoCompleteIndividualBox>
          <DatePickerContainer $themeColor={THEME_COLOR}>
            <StyledQuoteDateInput>
              <StyledQuoteDetailLabelText
                $themeColor={THEME_COLOR}
                $doxleFont={DOXLE_FONT}
                $opacity={0.7}
              >
                INITIATED DATE
              </StyledQuoteDetailLabelText>

              <QuoteDatePicker
                disabled
                value={dayjs(new Date())}
                onChange={(value: unknown) => {
                  setDueDate(value as Dayjs | null);
                }}
              />
            </StyledQuoteDateInput>

            <StyledQuoteDateInput>
              <StyledQuoteDetailLabelText
                $themeColor={THEME_COLOR}
                $doxleFont={DOXLE_FONT}
                $opacity={0.7}
              >
                DUE DATE
              </StyledQuoteDetailLabelText>

              <QuoteDatePicker
                value={dueDate}
                onChange={(value: unknown) => {
                  setDueDate(value as Dayjs | null);
                  console.log("DATE PICKED:", value);
                }}
              />
            </StyledQuoteDateInput>
          </DatePickerContainer>
        </QuoteAutoCompleteContainer>
        <QuoteBottomHalfContainer marginTop="50px">
          <StyledQuoteDetailLabelText
            $themeColor={THEME_COLOR}
            $doxleFont={DOXLE_FONT}
            $opacity={0.7}
          >
            EMAIL BODY
          </StyledQuoteDetailLabelText>
          <QuoteBottomHalfBox textColor="black" boxColor="">
            <QuoteRequestTextField
              multiline
              fullWidth
              inputRef={requestTextRef}
              onFocus={(e) => e.currentTarget.select()}
              defaultValue={
                quoteRequest?.emailBody ??
                `Could you please provide a quote at your earliest convenience. Please find the attached link\n` +
                  `below and let me when I can expect the quote to be submitted? `
              }
              $themeColor={THEME_COLOR}
              $doxleFont={DOXLE_FONT}
            />
          </QuoteBottomHalfBox>
        </QuoteBottomHalfContainer>
        <QuoteBottomHalfContainer marginTop="20px">
          <StyledQuoteDetailLabelText
            $paddingRight={"0px"}
            $themeColor={THEME_COLOR}
            $doxleFont={DOXLE_FONT}
          >
            Attachments
          </StyledQuoteDetailLabelText>
          <AttachmentWrapper>
            {attachments?.map((attachment, index) => (
              <AddedAttachmentItem
                attachment={attachment}
                index={index}
                setAttachments={setAttachments}
                key={`${attachment.name}`}
              />
            ))}
          </AttachmentWrapper>

          <QuoteAttachments
            attachments={attachments}
            setAttachments={setAttachments}
          />
        </QuoteBottomHalfContainer>
        <StyledQuoteWacherSectionContainer
          $marginTop={34}
          $marginBottom={0}
          $gap={68}
        >
          <StyledQuoteDetailLabelText
            $themeColor={THEME_COLOR}
            $doxleFont={DOXLE_FONT}
            $opacity={0.7}
          >
            Task
          </StyledQuoteDetailLabelText>
          {taskGroups.length > 0 && (
            <SelectedSupplierContainer>
              {taskGroups.map((taskGroup) => (
                <DoxleFilterTag
                  itemData={taskGroup}
                  itemDisplayText={taskGroup.scopeGroupTitle}
                  removeItemFunction={(item) =>
                    onRemoveScopeOfWorks(item.scopeGroupId)
                  }
                  tagStyle={{
                    backgroundColor: THEME_COLOR.primaryHoverColor,
                  }}
                  key={taskGroup.scopeGroupId}
                />
              ))}
            </SelectedSupplierContainer>
          )}
        </StyledQuoteWacherSectionContainer>
        <StyledSelectTaskContainer
          onClick={(e) => setAnchorTaskListPopover(e.currentTarget)}
          style={{ color: taskGroups.length === 0 ? THEME_COLOR.primaryInputPlaceholderColor : "" }}
        >
          {taskGroups.length > 0
            ? taskGroups[taskGroups.length - 1].scopeGroupTitle
            : "Select Task Group..."}
        </StyledSelectTaskContainer>
        <DoxleSelectTaskDropdown
          popoverAnchor={anchorTaskListPopover}
          onClosePopover={() => {
            setAnchorTaskListPopover(null);
          }}
          selectedTaskItemIds={taskGroups.map((item) => item.scopeGroupId)}
          onSelectTask={(task) => {
            setTaskGroups((prevState) => [...prevState, task]);
          }}
          containerStyle={{
            borderRadius: 9,
          }}
        />
        <AddQuotesButtonWrapper>
          <AddQuotesButtonStyled
            $doxleFont={DOXLE_FONT}
            $themeColor={THEME_COLOR}
            onClick={handleClose}
            $cursor="pointer"
          >
            <span>Cancel </span>
          </AddQuotesButtonStyled>
          <AddQuotesButtonStyled
            $doxleFont={DOXLE_FONT}
            $themeColor={THEME_COLOR}
            onClick={handleSaveRequest}
            $cursor={addRequestQuery.isLoading ? "not-allowed" : "pointer"}
            style={{
              pointerEvents: addRequestQuery.isLoading ? "none" : "auto",
              backgroundColor: THEME_COLOR.primaryFontColor,
              color: THEME_COLOR.primaryContainerColor,
            }}
          >
            {addRequestQuery.isLoading && <StyledSpinner />}
            <span>Add</span>
          </AddQuotesButtonStyled>
        </AddQuotesButtonWrapper>
      </AddQuoteRequestContainer>
    </LocalizationProvider>
  );
};

export default AddQuoteRequests;
