import {
  StyledBookingDocketPopperContainer,
  StyledBookingDocketWrapper,
  StyledSearchDocketTextField,
} from "./StyledComponentBookingProject";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import useBookingDocketListPopper from "../Hooks/useBookingDocketListPopper";
import { Docket } from "../../../Models/dockets";
import { AnimatePresence, Variants } from "framer-motion";
import Popover from "@mui/material/Popover";
import { Components, Virtuoso } from "react-virtuoso";
import { useCallback, useMemo } from "react";
import DoxleEmptyPlaceHolder from "../../../DoxleDesignPattern/DoxleEmptyPlaceHolder/DoxleEmptyPlaceHolder";
import React from "react";
import BookingDocketRow from "./BookingDocketRow";
import ListLoadingMore from "../../../Utilities/Lottie/ListLoadingMore";
import { StyledVirtuosoListScroller } from "../../CommonComponents/StyledComponentBookings";
import DoxleListSkeleton from "../../../DoxleDesignPattern/DoxleSkeleton/DoxleListSkeleton";

type Props = {
  anchorPopper: HTMLElement | null;
  onClosePopper: () => void;
  onSelectDocket?: (docket: Docket) => void;
  currentSelectedDocket?: Docket;
};
interface DocketListContext {
  isErrorFetchingDocketList: boolean;
  currentSelectedDocket?: Docket;
}
const BookingDocketListPopper = ({
  anchorPopper,
  onClosePopper,
  onSelectDocket,
  currentSelectedDocket,
}: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore((state) => ({
    doxleThemeColor: state.doxleThemeColor,
    doxleFont: state.doxleFont,
  }));
  const {
    docketList,
    handleFetchNextPageDocket,
    isFetchingDocketList,
    isSuccessFetchingDocketList,
    isErrorFetchingDocketList,
    isFetchingNextPageDocket,
    refetchdocketList,
    isRefetchingDocketList,
    setSearchDocketText,
    searchDocketText,
    showList,
  } = useBookingDocketListPopper({ anchorPopper });
  const canBeOpen = Boolean(anchorPopper);
  const id = canBeOpen ? "booking-docket-transition-popper" : undefined;

  //render list
  const rowAnimatedVariants: Variants = {
    initial: {
      x: -5,
      opacity: 0,
    },
    entering: {
      x: 0,
      opacity: 1,
    },
  };
  const docketListStyle: React.CSSProperties = {
    width: "100%",
    flex: 1,
  };
  const components: Components<Docket, DocketListContext> = useMemo(
    () => ({
      Item: (props) => (
        <StyledBookingDocketWrapper
          key={props.item.docketPk}
          $themeColor={doxleThemeColor}
          {...props}
          initial="initial"
          animate="entering"
          variants={rowAnimatedVariants}
          transition={{
            duration: 0.2,
          }}
          onClick={() => {
            if (onSelectDocket) onSelectDocket(props.item);
          }}
        />
      ),
      Scroller: React.forwardRef(({ ...props }, ref) => (
        <StyledVirtuosoListScroller ref={ref} {...props} />
      )),

      EmptyPlaceholder: (props) =>
        props.context?.isErrorFetchingDocketList ? (
          <DoxleEmptyPlaceHolder
            headTitleText="Something wrong!"
            subTitleText="Failed to get docket data..."
            containerBgColor="transparent"
            headTitleTextStyle={{
              fontSize: "1.4rem",
            }}
            subTitleTextStyle={{ fontSize: "1.2rem" }}
          />
        ) : (
          <DoxleEmptyPlaceHolder
            headTitleText="No Dockets!"
            containerBgColor="transparent"
            headTitleTextStyle={{
              fontSize: "1.4rem",
            }}
            subTitleTextStyle={{ fontSize: "1.2rem" }}
          />
        ),
    }),
    [onSelectDocket]
  );

  const itemContent = useCallback(
    (index: number, item: Docket, context?: DocketListContext) => (
      <BookingDocketRow
        docket={item}
        isSelected={Boolean(
          context?.currentSelectedDocket &&
            context.currentSelectedDocket.docketPk === item.docketPk
        )}
      />
    ),
    []
  );

  return (
    <Popover
      id={id}
      open={canBeOpen}
      anchorEl={anchorPopper}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      sx={{
        "& .MuiPopover-paper": {
          backgroundColor: "transparent",
          overflow: "visible",
          boxShadow: "none",
          border: `1px solid ${doxleThemeColor.primaryDividerColor}`,
        },
      }}
      elevation={8}
      onClose={onClosePopper}
    >
      <AnimatePresence>
        <StyledBookingDocketPopperContainer
          $themeColor={doxleThemeColor}
          $height={docketList.length > 20 ? 300 : 200}
        >
          <StyledSearchDocketTextField
            $themeColor={doxleThemeColor}
            $doxleFont={doxleFont}
            value={searchDocketText}
            onChange={(e) => setSearchDocketText(e.target.value)}
            placeholder="Search docket..."
          />
          {isFetchingDocketList && (
            <DoxleListSkeleton
              skeletonType="orderRow"
              containerStyle={{ flex: 1, width: "100%" }}
            />
          )}
          {!isFetchingDocketList && showList && (
            <Virtuoso
              context={{ isErrorFetchingDocketList, currentSelectedDocket }}
              data={docketList}
              components={components}
              style={docketListStyle}
              itemContent={itemContent}
              endReached={handleFetchNextPageDocket}
            />
          )}

          {isFetchingNextPageDocket && (
            <ListLoadingMore
              containerStyle={{
                position: "absolute",
                bottom: 0,
                left: 0,
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 10,
              }}
              animationSize={50}
            />
          )}
        </StyledBookingDocketPopperContainer>
      </AnimatePresence>
    </Popover>
  );
};

export default BookingDocketListPopper;
