import React, { forwardRef } from "react";
import { IStageBudget } from "../Models/stage";
import {
  StyledStageHeaderCell,
  StyledStageHeaderTitleText,
  StyledStageHeaderWrapper,
} from "./BudgetStyleComponent";
import { BUDGET_TABLE_HEADER_LIST } from "../Models/BudgetDocket";
import { formatter } from "../../Utilities/FunctionUtilities";
import { useBudgetStore } from "../Store/useBudgetStore";
import { useShallow } from "zustand/react/shallow";
import { FaChevronUp } from "react-icons/fa";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";

type Props = {
  stageItem?: IStageBudget;
};

const BudgetStageHeader = ({ stageItem }: Props) => {
  const { staticMenuColor } = useDoxleThemeStore(
    useShallow((state) => ({
      staticMenuColor: state.staticMenuColor,
    }))
  );
  const { setShrinkedStageSections, shrinkedStageSections } = useBudgetStore(
    useShallow((state) => ({
      setShrinkedStageSections: state.setShrinkedStageSections,
      shrinkedStageSections: state.shrinkedStageSections,
    }))
  );

  const isExpand = stageItem
    ? !shrinkedStageSections.includes(stageItem.stageId)
    : !shrinkedStageSections.includes("unstaged");
  if (stageItem)
    return (
      <StyledStageHeaderWrapper
        onClick={() => setShrinkedStageSections(stageItem.stageId)}
      >
        {BUDGET_TABLE_HEADER_LIST.map(
          (header, idx) =>
            header.isDisplayed && (
              <StyledStageHeaderCell
                $widthRatio={header.headerName === "Name" ? null : `16%`}
                $horizontalAlign={
                  header.headerName === "Name" ? "flex-start" : "center"
                }
                key={`header#${idx}`}
              >
                <StyledStageHeaderTitleText
                  sx={{
                    paddingLeft: header.headerName === "Name" ? "32px" : 0,
                  }}
                >
                  {header.headerName === "Name" && stageItem.title}

                  {header.headerName === "Budget" &&
                    stageItem.stageBudget &&
                    formatter.format(
                      parseFloat(stageItem.stageBudget.toString())
                    )}

                  {header.headerName === "Order" &&
                    stageItem.totalOrders &&
                    formatter.format(
                      parseFloat(stageItem.totalOrders.toString())
                    )}

                  {header.headerName === "Xero" &&
                    stageItem.totalActuals &&
                    formatter.format(
                      parseFloat(stageItem.totalActuals.toString())
                    )}

                  {header.headerName === "Final" &&
                    stageItem.totalRunning &&
                    formatter.format(
                      parseFloat(stageItem.totalRunning.toString())
                    )}
                </StyledStageHeaderTitleText>

                {header.headerName === "Name" && (
                  <FaChevronUp
                    color={staticMenuColor.staticFontColor}
                    size={12}
                    style={{
                      transform: `rotate(${isExpand ? 180 : 0}deg)`,
                      transition: "transform 0.2s ease-in-out",
                      marginLeft: 4,
                    }}
                  />
                )}
              </StyledStageHeaderCell>
            )
        )}
      </StyledStageHeaderWrapper>
    );

  return (
    <StyledStageHeaderWrapper
      layout="position"
      onClick={() => setShrinkedStageSections("unstaged")}
    >
      <StyledStageHeaderTitleText
        sx={{
          paddingLeft: "32px",
        }}
      >
        Unstaged budgets
      </StyledStageHeaderTitleText>

      <FaChevronUp
        color={staticMenuColor.staticFontColor}
        size={12}
        style={{
          transform: `rotate(${isExpand ? 180 : 0}deg)`,
          transition: "transform 0.2s ease-in-out",
          marginLeft: 4,
        }}
      />
    </StyledStageHeaderWrapper>
  );
};

export default BudgetStageHeader;
