import {
  IFilterGetFileQueryFilter,
  getFileInsideFolderQKey,
  getFileQKey,
} from "../QueryHooks/FileStorageQueryAPI";
import { useDoxleCurrentContextStore } from "../../DoxleGeneralStore/useDoxleCurrentContext";
import { shallow } from "zustand/shallow";
import { useQueryClient } from "@tanstack/react-query";
import { produce } from "immer";
import { DoxleFile } from "../../Models/files";
import {
  IApiPaginatedData,
  InfiniteAxiosQueryData,
} from "../../Models/axiosReturn";
import { useShallow } from "zustand/react/shallow";

type Props = {
  filter: IFilterGetFileQueryFilter;
  appendPos?: "start" | "end";
  overwrite?: boolean;
  isFolderFile: boolean;
};

const useSetFileQueryData = ({
  filter,
  appendPos = "end",
  overwrite = true,
  isFolderFile,
}: Props) => {
  const { currentCompany } = useDoxleCurrentContextStore(
    useShallow((state) => ({
      currentCompany: state.currentCompany,
    }))
  );
  const queryClient = useQueryClient();
  const handleRemoveFile = (deletedFileId: string) => {
    const qKey = getFileQKey({}, currentCompany);
    const fileListCacheActive = queryClient.getQueryCache().findAll({
      predicate: (query) =>
        qKey.every((key) => query.queryKey.includes(key)) && query.isActive(),
    });
    const fileListCacheInactive = queryClient.getQueryCache().findAll({
      predicate: (query) =>
        qKey.every((key) => query.queryKey.includes(key)) && !query.isActive(),
    });
    fileListCacheActive.forEach((query) => {
      queryClient.setQueryData<InfiniteAxiosQueryData<DoxleFile>>(
        query.queryKey,
        (old) => {
          if (old) {
            return produce(old, (draft) => {
              let pageIndexContainItem: number = old.pages.findIndex((page) =>
                Boolean(
                  page.data.results.find(
                    (file) => file.fileId === deletedFileId
                  ) !== undefined
                )
              );
              if (pageIndexContainItem !== -1)
                draft.pages[pageIndexContainItem].data.results = draft.pages[
                  pageIndexContainItem
                ].data.results.filter(
                  (file: DoxleFile) => file.fileId !== deletedFileId
                );
            });
          } else return old;
        }
      );
    });
    fileListCacheInactive.forEach((query) => {
      queryClient.invalidateQueries(query.queryKey);
    });
  };

  const handleUpdateFile = (updatedDoxleFile: DoxleFile) => {
    const qKey = updatedDoxleFile.folder
      ? getFileQKey(
          {
            folderId: updatedDoxleFile.folder,
          },
          currentCompany
        ) // need to seperate the query key for folder level and docket/project/company level to avoid duplicate
      : updatedDoxleFile.docket
      ? getFileQKey(
          {
            docketId: updatedDoxleFile.docket,
          },
          currentCompany
        )
      : updatedDoxleFile.project
      ? getFileQKey(
          {
            projectId: updatedDoxleFile.project,
          },
          currentCompany
        )
      : getFileQKey({}, currentCompany);

    const fileListCacheActive = queryClient.getQueryCache().findAll({
      predicate: (query) =>
        qKey.every((key) => query.queryKey.includes(key)) && query.isActive(),
    });
    const fileListCacheInactive = queryClient.getQueryCache().findAll({
      predicate: (query) =>
        qKey.every((key) => query.queryKey.includes(key)) && !query.isActive(),
    });
    fileListCacheActive.forEach((query) => {
      queryClient.setQueryData<InfiniteAxiosQueryData<DoxleFile>>(
        query.queryKey,
        (old) => {
          if (old) {
            return produce(old, (draft) => {
              let pageIndexContainItem: number = old.pages.findIndex((page) =>
                Boolean(
                  page.data.results.find(
                    (file) => file.fileId === updatedDoxleFile.fileId
                  ) !== undefined
                )
              );

              if (pageIndexContainItem !== -1) {
                const updatedItem = draft.pages[
                  pageIndexContainItem
                ].data.results.find(
                  (file: DoxleFile) => file.fileId === updatedDoxleFile.fileId
                );
                if (updatedItem) Object.assign(updatedItem, updatedDoxleFile);
              }
              return draft;
            });
          } else return old;
        }
      );
    });
    fileListCacheInactive.forEach((query) => {
      queryClient.invalidateQueries(query.queryKey);
    });
  };

  const handleAddFile = (newDoxleFile: DoxleFile) => {
    const qKey = newDoxleFile.folder
      ? getFileQKey(
          {
            folderId: newDoxleFile.folder,
          },
          currentCompany
        ) // need to seperate the query key for folder level and docket/project/company level to avoid duplicate
      : newDoxleFile.docket
      ? getFileQKey(
          {
            docketId: newDoxleFile.docket,
          },
          currentCompany
        )
      : newDoxleFile.project
      ? getFileQKey(
          {
            projectId: newDoxleFile.project,
          },
          currentCompany
        )
      : getFileQKey({}, currentCompany);
    const fileListCacheActive = queryClient.getQueryCache().findAll({
      predicate: (query) =>
        qKey.every((key) => query.queryKey.includes(key)) && query.isActive(),
    });
    const fileListCacheInactive = queryClient.getQueryCache().findAll({
      predicate: (query) =>
        qKey.every((key) => query.queryKey.includes(key)) && !query.isActive(),
    });
    fileListCacheActive.forEach((query) => {
      queryClient.setQueryData<InfiniteAxiosQueryData<DoxleFile>>(
        query.queryKey,
        (old) => {
          if (old) {
            return produce(old, (draft) => {
              const lengthOfPages = old.pages.length;
              draft.pages = produce(draft.pages, (draftPages) => {
                if (appendPos === "start") {
                  (
                    draftPages[0].data as IApiPaginatedData<DoxleFile>
                  ).results.unshift(newDoxleFile);
                } else {
                  (
                    draftPages[lengthOfPages - 1]
                      .data as IApiPaginatedData<DoxleFile>
                  ).results.push(newDoxleFile);
                }

                return draftPages;
              });

              return draft;
            });
          } else return old;
        }
      );
    });
    fileListCacheInactive.forEach((query) => {
      queryClient.removeQueries(query.queryKey);
    });
  };

  const handleAddMultipleFile = (newFiles: DoxleFile[]) => {
    const qKey = newFiles[0].folder
      ? getFileQKey(
          {
            folderId: newFiles[0].folder,
          },
          currentCompany
        ) // need to seperate the query key for folder level and docket/project/company level to avoid duplicate
      : newFiles[0].docket
      ? getFileQKey(
          {
            docketId: newFiles[0].docket,
          },
          currentCompany
        )
      : newFiles[0].project
      ? getFileQKey(
          {
            projectId: newFiles[0].project,
          },
          currentCompany
        )
      : getFileQKey({}, currentCompany);
    const fileListCacheActive = queryClient.getQueryCache().findAll({
      predicate: (query) =>
        qKey.every((key) => query.queryKey.includes(key)) && query.isActive(),
    });
    const fileListCacheInactive = queryClient.getQueryCache().findAll({
      predicate: (query) =>
        qKey.every((key) => query.queryKey.includes(key)) && !query.isActive(),
    });
    fileListCacheActive.forEach((query) => {
      queryClient.setQueryData<InfiniteAxiosQueryData<DoxleFile>>(
        query.queryKey,
        (old) => {
          if (old) {
            return produce(old, (draft) => {
              const lengthOfPages = old.pages.length;
              draft.pages = produce(draft.pages, (draftPages) => {
                if (appendPos === "start") {
                  (
                    draftPages[0].data as IApiPaginatedData<DoxleFile>
                  ).results.unshift(...newFiles);
                } else {
                  (
                    draftPages[lengthOfPages - 1]
                      .data as IApiPaginatedData<DoxleFile>
                  ).results.push(...newFiles);
                }

                return draftPages;
              });

              return draft;
            });
          } else return old;
        }
      );
    });
    fileListCacheInactive.forEach((query) => {
      queryClient.removeQueries(query.queryKey);
    });
  };

  const handleRemoveMultipleFile = (deletedFiles: DoxleFile[]) => {
    const qKey = deletedFiles[0].folder
      ? getFileQKey(
          {
            folderId: deletedFiles[0].folder,
          },
          currentCompany
        ) // need to seperate the query key for folder level and docket/project/company level to avoid duplicate
      : deletedFiles[0].docket
      ? getFileQKey(
          {
            docketId: deletedFiles[0].docket,
          },
          currentCompany
        )
      : deletedFiles[0].project
      ? getFileQKey(
          {
            projectId: deletedFiles[0].project,
          },
          currentCompany
        )
      : getFileQKey({}, currentCompany);
    const fileListCacheActive = queryClient.getQueryCache().findAll({
      predicate: (query) =>
        qKey.every((key) => query.queryKey.includes(key)) && query.isActive(),
    });
    const fileListCacheInactive = queryClient.getQueryCache().findAll({
      predicate: (query) =>
        qKey.every((key) => query.queryKey.includes(key)) && !query.isActive(),
    });
    fileListCacheActive.forEach((query) => {
      queryClient.setQueryData<InfiniteAxiosQueryData<DoxleFile>>(
        query.queryKey,
        (old) => {
          if (old) {
            return produce(old, (draft) => {
              draft.pages.forEach((page) => {
                page.data.results = page.data.results.filter(
                  (file: DoxleFile) =>
                    !deletedFiles.find(
                      (deletedFile) => file.fileId === deletedFile.fileId
                    )
                );
              });

              return draft;
            });
          } else return old;
        }
      );
    });
    fileListCacheInactive.forEach((query) => {
      queryClient.invalidateQueries(query.queryKey);
    });
  };
  return {
    handleRemoveFile,
    handleUpdateFile,
    handleAddFile,
    handleAddMultipleFile,
    handleRemoveMultipleFile,
  };
};

export default useSetFileQueryData;
