import React from "react";
import {
  StyledAddResponseFieldWrapper,
  StyledAddResponseLabelFieldText,
  StyledSelectQuoteResponseDate,
} from "./StyledComponentsAddQuoteResponse";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import AddQuoteResponseDatePicker from "./AddQuoteResponseDatePicker";
import dayjs from "dayjs";
import { useAddQuoteResponseContext } from "./AddQuoteResponse";
import { produce } from "immer";
import {
  formatTISODateTime,
  formatTISODate,
} from "../../../Utilities/FunctionUtilities";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

type Props = {};

const SelectQuoteResponseDate = (props: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore((state) => ({
    doxleThemeColor: state.doxleThemeColor,
    doxleFont: state.doxleFont,
  }));

  const { setNewQuoteResponse, newQuoteResponse } =
    useAddQuoteResponseContext();

  const handlePickExpiryDate = (value: dayjs.Dayjs | null) => {
    if (value)
      setNewQuoteResponse((prev) =>
        produce(prev, (draft) => {
          draft.expiryDate = formatTISODate(value.toDate());
        })
      );
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <StyledSelectQuoteResponseDate layout>
        <StyledAddResponseFieldWrapper
          style={{
            width: "50%",
            maxWidth: 400,
          }}
        >
          <StyledAddResponseLabelFieldText
            $themeColor={doxleThemeColor}
            $doxleFont={doxleFont}
          >
            INITIAL DATE
          </StyledAddResponseLabelFieldText>
          <AddQuoteResponseDatePicker
            value={dayjs(new Date())}
            disabled={true}
          />
        </StyledAddResponseFieldWrapper>

        <StyledAddResponseFieldWrapper
          style={{
            flex: 1,
          }}
        >
          <StyledAddResponseLabelFieldText
            $themeColor={doxleThemeColor}
            $doxleFont={doxleFont}
          >
            Expiry DATE
          </StyledAddResponseLabelFieldText>
          <AddQuoteResponseDatePicker
            value={
              newQuoteResponse.expiryDate
                ? dayjs(newQuoteResponse.expiryDate)
                : null
            }
            onChange={handlePickExpiryDate}
          />
        </StyledAddResponseFieldWrapper>
      </StyledSelectQuoteResponseDate>
    </LocalizationProvider>
  );
};

export default SelectQuoteResponseDate;
