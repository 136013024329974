import React, { useEffect, useState } from "react";
import {Project} from "../../../Models/project";

type Props = {
  retrievedProjectData: Project|undefined
  onTextChangeCb: (value: string) => void
  projectKey: keyof Project
};

interface AddProjectTextField {
  isTextFieldFocused: boolean;
  setisTextFieldFocused: React.Dispatch<React.SetStateAction<boolean>>;
  setTextInput: React.Dispatch<React.SetStateAction<string>>;
  textInput: string;
}
const useAddProjectTextField = ({
  onTextChangeCb,
  retrievedProjectData,
  projectKey
}: Props): AddProjectTextField => {
  const [isTextFieldFocused, setisTextFieldFocused] = useState(false);
  const [textInput, setTextInput] = useState("");

  useEffect(() => {
    const timeout = setTimeout(() => {
      onTextChangeCb(textInput);
    }, 300);

    return () => {
      clearTimeout(timeout);
    };
  }, [textInput]);
  useEffect(()=>{
    const initialValue = String(retrievedProjectData?.[projectKey] ?? "")
    if (retrievedProjectData) setTextInput(initialValue)
  },[retrievedProjectData])
  return {
    isTextFieldFocused,
    setisTextFieldFocused,
    setTextInput,
    textInput,
  };
};

export default useAddProjectTextField;
