import React from "react";
import { ProjectStatus } from "../../Models/project";
import { useDoxleCurrentContextStore } from "../../DoxleGeneralStore/useDoxleCurrentContext";
import { shallow } from "zustand/shallow";
import { StyledProjectStatusItem } from "./StyledProjectComponents";
import { AnimatePresence, motion } from "framer-motion";
import { useShallow } from "zustand/react/shallow";

type Props = {
  statusItem: ProjectStatus;
};

const ProjectStatusItem = ({ statusItem }: Props) => {
  const { currentProjectStatusId, setCurrentProjectStatusId } =
    useDoxleCurrentContextStore(
      useShallow((state) => ({
        currentProjectStatusId: state.currentProjectStatusId,
        setCurrentProjectStatusId: state.setCurrentProjectStatusId,
      }))
    );
  const handleClickStatus = () => {
    if (currentProjectStatusId === statusItem.statusId)
      setCurrentProjectStatusId(undefined);
    else setCurrentProjectStatusId(statusItem.statusId);
  };
  return (
    <StyledProjectStatusItem
      onClick={handleClickStatus}
      $selected={currentProjectStatusId === statusItem.statusId}
    >
      {statusItem.statusName}
      <AnimatePresence>
        {currentProjectStatusId === statusItem.statusId && (
          <motion.div
            exit={{
              scale: [1, 0],
            }}
            transition={{
              duration: 0.2,
            }}
            className="selected-effect"
            layoutId="selectedProjectstatusEffect"
          />
        )}
      </AnimatePresence>
    </StyledProjectStatusItem>
  );
};

export default ProjectStatusItem;
