import React from "react";
import DoxleDialogHelper from "../../../DoxleDesignPattern/DoxleDialogHelper/DoxleDialogHelper";
import useDeleteQuoteAttachment from "./Hooks/useDeleteQuoteAttachment";
import { useReviewQuoteRequestContext } from "./ReviewQuoteRequest";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";

type Props = {};

const DeleteQuoteAttachment = (props: Props) => {
  const { handleCloseDeleteDialog, handleDeleteAttachment } =
    useDeleteQuoteAttachment({});
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }),
    shallow
  );
  const { deletedQuoteAttachment } = useReviewQuoteRequestContext();
  return (
    <DoxleDialogHelper
      open={Boolean(deletedQuoteAttachment)}
      onClose={handleCloseDeleteDialog}
      title="Confirm delete attachment!"
      description={`This attachment will be deleted permanently, are you sure to proceed?`}
      dialogButtons={[
        {
          buttonText: "Cancel",
          buttonFunction: handleCloseDeleteDialog,
          buttonColor: doxleThemeColor.primaryContainerColor,
          btnStyleSx: {
            border: `1px solid ${doxleThemeColor.primaryDividerColor}`,
          },
          buttonTextStyle: {
            color: doxleThemeColor.primaryFontColor,
          },
        },
        {
          buttonText: "Confirm",
          buttonFunction: handleDeleteAttachment,
          btnStyleSx: {
            border: `1px solid ${doxleThemeColor.primaryDividerColor}`,
          },
        },
      ]}
    />
  );
};

export default DeleteQuoteAttachment;
