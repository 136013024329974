import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import {
  AxiosBackendErrorReturn,
  IApiPaginatedData,
} from "../../Models/axiosReturn";
import DoxleAPI from "../../Services/DoxleAPI";
import { InventoryImage } from "../Models/Inventory";
import { useDoxleCurrentContextStore } from "../../DoxleGeneralStore/useDoxleCurrentContext";
import { shallow } from "zustand/shallow";
import { BaseAPIProps } from "../../Models/baseAPIProps";
import { getInventoryQKey } from "./InventoryQueryAPI";
import useSetInventoryQueryData from "./useSetInventoryQueryData";
import { AxiosError, isAxiosError } from "axios";

interface ImageGetServerReturn {
  results: InventoryImage[];
  error: any[];
}
const useGetInventoryImageById = (inventoryId: string | undefined) => {
  const qKey = ["inventoryId", "images", inventoryId];
  let inventoryUrl = "/inventory/" + inventoryId + "/images/";
  const company = useDoxleCurrentContextStore(
    (state) => state.currentCompany,
    shallow
  );

  return useQuery(
    qKey,
    async () => {
      return DoxleAPI.get<IApiPaginatedData<InventoryImage>>(inventoryUrl, {
        headers: {
          "User-Company": company?.companyId,
        },
      });
    },
    {
      onSuccess: (res) => {
        console.log(res.data);
      },

      onError: () => {},
      enabled: Boolean(company && inventoryId),
      staleTime: 0,
      cacheTime: 0,
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: false,
    }
  );
};

export interface IUploadInventoryImageQueryProps extends BaseAPIProps {
  onSuccessAddCb?: (newImage?: InventoryImage[]) => void;
}
export interface AddInventoryUploadImageParams {
  imageFiles: File[];
  inventoryId: string;
}

interface ImagePostServerReturn {
  created: InventoryImage[];
  error: any[];
}
const useUploadInventoryImageQuery = ({
  showNotification,
  company,
  onSuccessAddCb,
}: IUploadInventoryImageQueryProps) => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationKey: ["Add-Inventory-Image-Mutation"],
    mutationFn: async ({
      imageFiles,
      inventoryId,
    }: AddInventoryUploadImageParams) => {
      const formData = new FormData();
      for (let i = 0; i < imageFiles.length; i++) {
        formData.append("images", imageFiles[i]);
      }

      return DoxleAPI.post<ImagePostServerReturn>(
        "/inventory/" + inventoryId + "/images/",
        formData,
        {
          headers: {
            "User-Company": company?.companyId || "",
          },
        }
      );
    },
    onMutate: () => {
      const qKey = getInventoryQKey(company);
      queryClient.cancelQueries(qKey);
    },
    onSuccess: (result, variables, context) => {
      if (onSuccessAddCb) onSuccessAddCb(result.data.created);
    },
    onError: (error, variables, context) => {
      if (showNotification) {
        if (isAxiosError<AxiosBackendErrorReturn>(error)) {
          showNotification(
            `${error?.response?.status ?? "ERROR"}: ${
              error.response?.data.detail ?? "UNKNOWN ERROR"
            }`,
            "error",
            String(
              error?.response?.data?.detail ?? "Fail To Upload Inventory Image"
            ).substring(0, 300)
          );
        } else {
          showNotification(
            "Something Wrong!",
            "error",
            "Fail To Upload Inventory Image"
          );
        }
      }
    },
  });

  return mutation;
};

export interface AddInventoryDeleteImageParams {
  imageIds: string[];
  inventoryId: string;
}
export interface InventoryImageDeleteQueryProps extends BaseAPIProps {
  onSuccessDeleteCb?: (deletedIds?: string) => void;
}

const useDeleteInventoryImageQuery = ({
  showNotification,
  onSuccessDeleteCb,
}: InventoryImageDeleteQueryProps) => {
  const queryClient = useQueryClient();
  const { handleDeleteInventoryImage } = useSetInventoryQueryData({});
  const company = useDoxleCurrentContextStore(
    (state) => state.currentCompany,
    shallow
  );
  const mutation = useMutation({
    mutationKey: getInventoryMutateKey("delete"),
    mutationFn: async ({
      inventoryId,
      imageIds,
    }: AddInventoryDeleteImageParams) => {
      const formData = new FormData();
      for (let i = 0; i < imageIds.length; i++) {
        formData.append("imageIds", imageIds[i]);
      }
      const data = {
        imageIds: imageIds,
      };
      return DoxleAPI.post("/inventory/" + inventoryId + "/del_images/", data, {
        headers: {
          "User-Company": company?.companyId || "",
        },
      });
    },
    onMutate: () => {
      const qKey = getInventoryQKey(company);
      queryClient.cancelQueries(qKey);
    },
    onSuccess: (result, variables, context) => {
      if (onSuccessDeleteCb) {
        handleDeleteInventoryImage(
          variables.imageIds[0],
          variables.inventoryId
        );
      }

      // if (showNotification)
      //   showNotification(
      //     "Inventory Image Deleted",
      //     "success",
      //     "SUCCESSFULLY Deleted Inventory Image"
      //   );
    },
    onError: (error, variables, context) => {
      if (showNotification) {
        if (isAxiosError<AxiosBackendErrorReturn>(error)) {
          showNotification(
            `${error?.response?.status ?? "ERROR"}: ${
              error.response?.data.detail ?? "UNKNOWN ERROR"
            }`,
            "error",
            String(
              error?.response?.data?.detail ?? "Fail To Delete Inventory Image"
            ).substring(0, 300)
          );
        } else {
          showNotification(
            "Something Wrong!",
            "error",
            "Fail To Delete Inventory Image"
          );
        }
      }
    },
  });

  return mutation;
};
export const getInventoryMutateKey = (action: "add" | "delete" | "update") => [
  `${action}-inventory`,
];
const InventoryImageQueryAPI = {
  useGetInventoryImageById,
  useUploadInventoryImageQuery,
  useDeleteInventoryImageQuery,
};
export default InventoryImageQueryAPI;
