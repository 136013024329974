import styled from "styled-components";
import {
  DoxleFont,
  DoxleTheme,
  DoxleThemeColor,
} from "../../DoxleGeneralStore/useDoxleThemeStore";
import {editRgbaAlpha, editRgbaColor, TRgbaFormat} from "../../Utilities/FunctionUtilities";
import { Button } from "@mui/material";

export const StyledBackdrop = styled.div<{
  $currentTheme: DoxleTheme;
  $doxleFont: DoxleFont;
  $themeColor: DoxleThemeColor;
}>`
  width: 100vw;
  height: 100vh;
  background-color: ${(p) =>
    editRgbaAlpha({
      rgbaColor: p.$themeColor.primaryContainerColor,
      alpha: "0.6",
    })};
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1500;
  display: flex;
  justify-content: center;
`;
export const StyledRootContainer = styled.div<{
  $currentTheme: DoxleTheme;
  $doxleFont: DoxleFont;
  $themeColor: DoxleThemeColor;
}>`
  width: 100%;
  height: calc(100vh - 106px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin-bottom: 16px;
  position: relative;
`;
export const StyledProjectMapHeader = styled.div<{
  $currentTheme: DoxleTheme;
  $doxleFont: DoxleFont;
  $themeColor: DoxleThemeColor;
}>`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: calc(80px - 4rem);
  padding: 2rem 0;
`;
export const StyledProjectMapHeaderLeft = styled.div<{
  $currentTheme: DoxleTheme;
  $doxleFont: DoxleFont;
  $themeColor: DoxleThemeColor;
}>`
  height: 100%;
  width: calc(50% - 2rem);
  font-family: ${(p) => p.$doxleFont.subTitleFont};
  font-size: 16px;
  color: ${(p) => p.$themeColor.primaryFontColor};
  padding-left: 2rem;
  margin: auto 0;
  line-height: 24px;
  display: flex;
  flex-direction: column;
  .title{
    font-weight: 500;
  }
`;
export const StyledUnmappedAccountAlertContainer = styled.div<{
  $alertColor: TRgbaFormat;
}>`
  color: ${p=> p.$alertColor};
  font-size: 24px;
  margin-left: 2rem;
  background-color: ${p=> editRgbaAlpha({
    rgbaColor: p.$alertColor,
    alpha: '0.2'
  })};
  padding: 0 0.8rem;
  border-radius: 0.8rem;
  cursor: pointer;

  &:hover{
    .unmapped-accounts{
      display: flex !important;
    }
  }
  .unmapped-accounts{
    display: none;
  }
`
export const StyledUnmappedAccountDropDown = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 2rem 0 2rem 2rem;
  box-shadow: 0 5px 5x 0 ${p=> p.theme.color.primaryBoxShadowColor};
  min-width: 120px;
  width: fit-content;
  position: absolute;
  right: 2rem;
  top: 63px;
  z-index: 5;
  background-color: ${p=> p.theme.color.primaryContainerColor};
  max-height: 400px;
  overflow-x: hidden;
  overflow-y: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`
export const StyledUnmappedAccountRow = styled.div<{
  $hasCost: boolean|null;
}>`
  width: calc(100% - 3rem);
  padding: 0.8rem 1.5rem;
  border-bottom: 1px solid ${p=> p.theme.color.primaryDividerColor};
  font-size: 13px;
  font-family: ${p=> 
    p.$hasCost === null 
    ? p.theme.font.subTitleFont 
    : p.theme.font.primaryFont};
  font-weight: ${p => p.$hasCost !== false ? 600 : 400};
  color: ${p=> editRgbaColor({
    rgbaColor: p.theme.color.primaryFontColor,
    r: p.$hasCost ? 200 : 0,
    g: p.$hasCost ? -100 : 0,
    b: p.$hasCost ? -100 : 0
  })};
  cursor: pointer;
  &:hover {
    background-color: ${p=> p.theme.color.primaryHoverColor};
  }
  line-height: 20px;
`
export const StyledProjectMapHeaderRight = styled.div<{
  $currentTheme: DoxleTheme;
  $doxleFont: DoxleFont;
  $themeColor: DoxleThemeColor;
}>`
  display: flex;
  flex-direction: row;
  height: 100%;
  width: calc(50% - 2rem);
  color: ${(p) => p.$themeColor.primaryFontColor};
  padding-right: 2rem;
  margin: 0.5rem 0;
  line-height: 40px;
  justify-content: end;
`;
export const StyledBodyContainer = styled.div<{
  $currentTheme: DoxleTheme;
  $doxleFont: DoxleFont;
  $themeColor: DoxleThemeColor;
}>`
  width: calc(100% - 90px);
  height: 100%;
  display: flex;
  flex-direction: column;
`;
export const StyledBottomContainer = styled.div<{
  $currentTheme: DoxleTheme;
  $doxleFont: DoxleFont;
  $themeColor: DoxleThemeColor;
}>`
  height: 80px;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-top: 10px;
  flex: 1;
`;
export const StyledDocketMappingRow = styled.div<{
  $currentTheme: DoxleTheme;
  $doxleFont: DoxleFont;
  $themeColor: DoxleThemeColor;
}>`
  width: 100%;
  height: 50px;
  border-bottom: 1px solid #cbdae2;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: -50;
`;

export const StyledMapDocketTitle = styled.div<{
  $currentTheme: DoxleTheme;
  $doxleFont: DoxleFont;
  $themeColor: DoxleThemeColor;
}>`
  height: 100%;
  width: 25%;
  display: flex;
  flex: 3;
  justify-content: flex-start;
  padding-left: 30px;
  align-items: center;
  font-family: ${(p) => p.$doxleFont.subTitleFont};
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: ${(p) => p.$themeColor.primaryFontColor};
`;
export const StyledMapDocketXeroCost = styled.div`
  height: 100%;
  align-items: center;
  flex: 1;
  font-family: ${(p) => p.theme.font.subTitleFont};
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: ${(p) => p.theme.color.primaryFontColor};
  display: flex;
  justify-content: left;
`
export const StyledMapDocketAccountField = styled.div<{
  $currentTheme: DoxleTheme;
  $doxleFont: DoxleFont;
  $themeColor: DoxleThemeColor;
  $linked: boolean;
}>`
  display: flex;
  min-width: calc(240px - 3rem);
  height: calc(100% - 20px);
  min-height: 30px;
  margin: 10px 10%;
  justify-content: space-between;
  padding: 0 1rem 0 2rem;
  align-items: center;
  font-family: ${(p) => p.$doxleFont.primaryFont};
  font-size: 13px;
  color: ${(p) => p.$themeColor.primaryFontColor};
  border: 1px solid
    ${(p) =>
      p.$linked ? p.$themeColor.primaryDividerColor : p.$themeColor.doxleColor};
  border-radius: 0 5px 5px 5px;
  background-color: ${(p) =>
    p.$linked
      ? p.$themeColor.primaryBackgroundColor
      : p.$themeColor.primaryReverseFontColor};
  ${(p) => (p.$linked ? "" : "cursor: pointer;")};
`;
export const StyledMapDocketButton = styled.div<{
  $currentTheme: DoxleTheme;
  $doxleFont: DoxleFont;
  $themeColor: DoxleThemeColor;
}>`
  display: flex;
  flex-direction: row;
`;
export const StyledUnlinkAccountButton = styled.div<{
  $currentTheme: DoxleTheme;
  $doxleFont: DoxleFont;
  $themeColor: DoxleThemeColor;
}>`
  display: flex;
  flex-direction: row;
  padding-left: 12px;
  height: 100%;
  justify-content: center;
  align-items: center;
  width: 112px;
  cursor: pointer;
  color: ${(p) => p.$themeColor.doxleColor};
  border-radius: 3px;
  margin: 0 20px;
  .MuiSvgIcon-root {
    height: 24px;
  }
  &:hover {
    background-color: ${(p) => p.$themeColor.primaryHoverColor};
  }
`;
export const StyledUnlinkAccountText = styled.div<{
  $currentTheme: DoxleTheme;
  $doxleFont: DoxleFont;
  $themeColor: DoxleThemeColor;
}>`
  font-family: "IBM Plex Mono";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 17px;
  color: ${(p) => p.$themeColor.doxleColor};
  height: 100%;
  padding-left: 5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
`;
export const StyledUnlinkSpacer = styled.div<{
  $currentTheme: DoxleTheme;
  $doxleFont: DoxleFont;
  $themeColor: DoxleThemeColor;
}>`
  width: 150px;
  padding-left: 12px;
`;

export const LinkAllButton = styled.button`
  width: 160px;
  height: 50px;
  font-size: 20px;
  border: 1px solid #7949ff;
  line-height: 20px;
  background-color: #7949ff;
  border-radius: 4px;
  cursor: pointer;
  font-family: "IBM Plex Mono";
  color: #ffffff;
  font-style: normal;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background-color: #8159f1;
    border-color: #8159f1;
    box-shadow: none;
  }
  &:active {
    box-shadow: none;
    background-color: rgb(169, 138, 253);
    border-color: #c4afff;
  }
  &:focus {
    box-shadow: 0 0 0 0.2rem #7922f5;
  }
`;

export const StyledLinkedIndicator = styled.div<{
  $currentTheme: DoxleTheme;
  $doxleFont: DoxleFont;
  $themeColor: DoxleThemeColor;
  $linked: boolean;
}>`
  width: 98px;
  height: 23px;
  background-color: ${(p) =>
    p.$linked ? p.$themeColor.doxleColor : p.$themeColor.errorColor};
  border-radius: 9px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "IBM Plex Mono";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 17px;
  color: #ffffff;
`;
export const StyledAccountDropDownContainer = styled.div<{
  $currentTheme: DoxleTheme;
  $doxleFont: DoxleFont;
  $themeColor: DoxleThemeColor;
}>`
  border-radius: 0 8px 8px 8px;
  background-color: ${(p) => p.$themeColor.primaryContainerColor};
  width: 280px;
  height: 300px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px -2px 8px 2px ${(p) => p.$themeColor.primaryBoxShadowColor};
  overflow: hidden;
`;
export const StyledAccountDropDownRow = styled.div<{
  $currentTheme: DoxleTheme;
  $doxleFont: DoxleFont;
  $themeColor: DoxleThemeColor;
}>`
  padding: 0.7rem 2rem;
  font-size: 13px;
  font-family: ${(p) => p.$doxleFont.primaryFont};
  font-weight: 400;
  color: ${(p) => p.$themeColor.primaryFontColor};
  border-bottom: 1px solid ${(p) => p.$themeColor.primaryDividerColor};
  &:hover {
    background-color: ${(p) => p.$themeColor.primaryHoverColor};
  }
  cursor: pointer;
`;
export const StyledProjectTrackingField = styled.div<{
  $currentTheme: DoxleTheme;
  $doxleFont: DoxleFont;
  $themeColor: DoxleThemeColor;
  $linked: boolean;
}>`
  display: flex;
  min-width: 300px;
  height: calc(100% - 20px);
  min-height: 30px;
  flex-direction: column;
  align-items: center;
  font-family: ${(p) => p.$doxleFont.primaryFont};
  font-size: 13px;
  border: 1px solid
    ${(p) =>
      p.$linked ? p.$themeColor.primaryDividerColor : p.$themeColor.doxleColor};
  line-height: 30px;
  border-radius: 0 5px 5px 5px;
  background-color: ${(p) =>
    p.$linked
      ? p.$themeColor.primaryBackgroundColor
      : p.$themeColor.primaryReverseFontColor};
  ${(p) => (p.$linked ? "" : "cursor: pointer;")};
`;
export const StyledIconButton = styled.div<{
  $currentTheme: DoxleTheme;
  $doxleFont: DoxleFont;
  $themeColor: DoxleThemeColor;
}>`
  position: relative;
  right: calc(15px - 50%);
  top: calc(0.5rem - 100%);
  color: ${(p) => p.$themeColor.doxleColor};
  cursor: pointer;
  border-radius: 8px;
  height: 15px;
  padding: 0.3rem;
  line-height: 10px;
  &:hover {
    background-color: ${(p) => p.$themeColor.hoverColor};
  }
`;
export const StyledAccountFieldIconButton = styled.div<{
  $currentTheme: DoxleTheme;
  $doxleFont: DoxleFont;
  $themeColor: DoxleThemeColor;
}>`
  position: relative;
  color: ${(p) => p.$themeColor.doxleColor};
  cursor: pointer;
  border-radius: 8px;
  height: 15px;
  padding: 0.3rem;
  line-height: 10px;
  &:hover {
    background-color: ${(p) => p.$themeColor.hoverColor};
  }
`;
