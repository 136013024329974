import React, { useMemo } from "react";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import useDoxlePaginationControl from "./Hooks/useDoxlePaginationControl";
import { TransitionProps } from "@mui/material/transitions";
import { FaChevronLeft } from "react-icons/fa6";
import { FaChevronRight } from "react-icons/fa6";
import { IoIosArrowDown } from "react-icons/io";
import {
  StyledDoxlePageNumberSection,
  StyledDoxlePageSizeSection,
  StyledDoxlePaginationControl,
  StyledDoxlePopoverPaginationList,
} from "./StyledDoxlePaginationControl";
import Popover from "@mui/material/Popover";
import Fade from "@mui/material/Fade";

type Props = {
  //! require props
  totalItemCount: number; //!needed to display total items
  totalPage: number; //! needed to render list of page numbers
  currentPage: number; //! needed to indicate current page
  currentPageSize: number; //! needed to indicate current page size
  onSelectPage: (page: number) => void; //! needed to trigger whenever select page number

  onSelectPageSize: (pageSize: number) => void; //! needed to trigger whenever select page size
  hasPreviousPage: boolean; //! restrict click previous page
  hasNextPage: boolean; //!needed to restrict click next page
  onFetchNext: () => void;
  onFetchPrevious: () => void;

  //! optional props
  pageSizeStep?: number; //*page size step to create a list of page size options (default=25), eg: step = 25, maxPageSize=200=> array option will be [25,50,75...,175,200]
  maxPageSize?: number; //* max range support for page size(default = 200)

  paginationContainerStyle?: React.CSSProperties;
  pageSizeSectionStyle?: React.CSSProperties;
  pageNumberSectionStyle?: React.CSSProperties;
  popoverListStyle?: React.CSSProperties;
  popoverOptionItemStyle?: React.CSSProperties;
};

const DoxlePaginationControl = ({
  totalPage,
  currentPage,
  currentPageSize,
  onSelectPage,
  onSelectPageSize,
  pageSizeStep = 25,
  maxPageSize = 200,
  hasPreviousPage,
  hasNextPage,
  onFetchNext,
  onFetchPrevious,
  totalItemCount,
  paginationContainerStyle,
  pageSizeSectionStyle,
  pageNumberSectionStyle,
  popoverListStyle,
  popoverOptionItemStyle,
}: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }),
    shallow
  );
  const {
    anchorPageControlList,
    handleClosePopoverListControl,
    handleClickPageSizeControl,
    handleClickPageNumControl,
    listControlMode,
    handleSetPage,
    handleSetPageSize,
    currenPageRange,
  } = useDoxlePaginationControl({
    onSelectPage,
    onSelectPageSize,
    totalPage,
    currentPage,
    currentPageSize,
    totalItemCount,
  });
  const Page_Size_List = useMemo(() => {
    let numbers: number[] = [];

    for (let i = pageSizeStep; i <= maxPageSize; i += pageSizeStep) {
      numbers.push(i);
    }

    return numbers;
  }, [pageSizeStep, maxPageSize]);
  const Page_Number_List = useMemo(() => {
    const pages: number[] = [];
    for (let i = 1; i <= totalPage; i += 1) {
      pages.push(i);
    }
    return pages;
  }, [totalPage]);
  const canBeOpen = Boolean(anchorPageControlList);
  const id = canBeOpen ? "page-control-menu-popper" : undefined;
  return (
    <StyledDoxlePaginationControl style={paginationContainerStyle}>
      <StyledDoxlePageSizeSection style={pageSizeSectionStyle}>
        <span className="page-size-title">Items per page</span>

        <div
          className="page-size-indicator"
          onClick={handleClickPageSizeControl}
        >
          {currentPageSize}
          <IoIosArrowDown
            color={doxleThemeColor.doxleColor}
            size={12}
            style={{ marginLeft: 4 }}
          />
        </div>
        <span className="page-size-summary">
          Items {currenPageRange.start}-{currenPageRange.end} of{" "}
          {totalItemCount}
        </span>
      </StyledDoxlePageSizeSection>

      <StyledDoxlePageNumberSection style={pageNumberSectionStyle}>
        <FaChevronLeft
          color={!hasPreviousPage ? "grey" : "inherit"}
          size={12}
          className="icon"
          onClick={onFetchPrevious}
        />

        <div className="page-num-indicator" onClick={handleClickPageNumControl}>
          Page {currentPage} of {totalPage}
          <IoIosArrowDown
            color={doxleThemeColor.doxleColor}
            size={12}
            style={{ marginLeft: 4 }}
          />
        </div>
        <FaChevronRight
          color={!hasNextPage ? "grey" : "inherit"}
          size={12}
          className="icon"
          onClick={onFetchNext}
        />
      </StyledDoxlePageNumberSection>

      <Popover
        id={id}
        open={canBeOpen}
        anchorEl={anchorPageControlList}
        disableScrollLock
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        onClose={handleClosePopoverListControl}
        sx={{
          "& .MuiPopover-paper": {
            backgroundColor: "transparent",
            overflow: "visible",
            boxShadow: "none",
          },
        }}
        TransitionComponent={Transition}
        transitionDuration={{ appear: 200, exit: 200 }}
        elevation={4}
      >
        <StyledDoxlePopoverPaginationList
          style={popoverListStyle}
          $themeColor={doxleThemeColor}
          $doxleFont={doxleFont}
        >
          {listControlMode === "pageSize" &&
            Page_Size_List.map((item, idx) => (
              <div
                className={`list-item ${
                  item === currentPageSize ? "selected" : ""
                }`}
                key={`page_size#${idx}`}
                onClick={() => handleSetPageSize(item)}
                style={popoverOptionItemStyle}
              >
                {item}
              </div>
            ))}

          {listControlMode === "pageNum" &&
            Page_Number_List.map((item, idx) => (
              <div
                className={`list-item ${
                  item === currentPage ? "selected" : ""
                }`}
                key={`page_num#${idx}`}
                onClick={() => handleSetPage(item)}
                style={popoverOptionItemStyle}
              >
                {item}
              </div>
            ))}
        </StyledDoxlePopoverPaginationList>
      </Popover>
    </StyledDoxlePaginationControl>
  );
};

export default DoxlePaginationControl;
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Fade appear={true} ref={ref} {...props} />;
});
