import React, { useState } from "react";

type Props = {};

interface IShareProjectSearchSection {
  searchText: string;
  setSearchText: React.Dispatch<React.SetStateAction<string>>;
  anchorCustomPermissionEl: HTMLDivElement | null;
  openPopover: (event: React.MouseEvent<HTMLDivElement>) => void;
  closePopover: () => void;
}
const useShareProjectSearchSection = (
  props: Props
): IShareProjectSearchSection => {
  const [searchText, setSearchText] = useState("");
  const [anchorCustomPermissionEl, setAnchorCustomPermissionEl] =
    useState<HTMLDivElement | null>(null);

  const openPopover = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorCustomPermissionEl(event.currentTarget);
  };

  const closePopover = () => {
    setAnchorCustomPermissionEl(null);
  };

  return {
    searchText,
    setSearchText,
    anchorCustomPermissionEl,
    openPopover,
    closePopover,
  };
};

export default useShareProjectSearchSection;
