export type TContactsTableHeaders =
  | "First Name"
  | "Last Name"
  | "Email"
  | "Phone"
  | "Primary Contact"
  | "Send Quotes"
  | "Company Name"
  | "Company ABN"
  | "Company Address";

export interface IContactsTableHeaders {
  headerName: TContactsTableHeaders;
  isDisplayed: boolean;
}

export const CONTACTS_TABLE_HEADER_LIST: IContactsTableHeaders[] = [
  {
    headerName: "Company Name",
    isDisplayed: false,
  },
  {
    headerName: "Company ABN",
    isDisplayed: false,
  },
  {
    headerName: "First Name",
    isDisplayed: true,
  },
  {
    headerName: "Last Name",
    isDisplayed: true,
  },
  {
    headerName: "Phone",
    isDisplayed: true,
  },
  {
    headerName: "Email",
    isDisplayed: true,
  },
  {
    headerName: "Company Address",
    isDisplayed: false,
  },
  {
    headerName: "Primary Contact",
    isDisplayed: false,
  },
  {
    headerName: "Send Quotes",
    isDisplayed: false,
  },
];

export type TContactCompanyTableHeaders =
  | "Company Name"
  | "ABN"
  | "Address"
  | "Contacts"
  | "Phone"
  | "Email";

export interface IContactsCompanyTableHeaders {
  headerName: TContactCompanyTableHeaders;
  isDisplayed: boolean;
}

export const CONTACT_COMPANY_TABLE_HEADER_LIST: IContactsCompanyTableHeaders[] =
  [
    {
      headerName: "Company Name",
      isDisplayed: true,
    },
    {
      headerName: "Phone",
      isDisplayed: true,
    },
    {
      headerName: "Email",
      isDisplayed: true,
    },
    {
      headerName: "ABN",
      isDisplayed: false,
    },
    {
      headerName: "Address",
      isDisplayed: false,
    },
   
    // {
    //   headerName: "Contacts",
    //   isDisplayed: true,
    // },
  ];
