import React, { useCallback, useEffect, useState } from "react";
import useQRLogStore from "../../../Store/useQRLogStore";
import { useShallow } from "zustand/react/shallow";
import useGetUserList from "../../../../CoreContent/QueryDataHooks/GetQueryDataHooks/useGetUserList";
import { User } from "../../../../Models/user";
type Props = {};

const useQRLogFilterUser = () => {
  const [anchorUserList, setAnchorUserList] = useState<HTMLElement | null>(
    null
  );
  const [showUserList, setShowUserList] = useState(false);
  const { filterQRUserLog, removeFilterQRUserLogProp, setFilterQRUserLog } =
    useQRLogStore(
      useShallow((state) => ({
        filterQRUserLog: state.filterQRUserLog,
        removeFilterQRUserLogProp: state.removeFilterQRUserLogProp,
        setFilterQRUserLog: state.setFilterQRUserLog,
      }))
    );
  const { userList } = useGetUserList({});

  const selectedUser = userList.find(
    (user) => user.userId === filterQRUserLog.user
  );
  const handleSelectUser = useCallback((user: User) => {
    removeFilterQRUserLogProp("search");
    setFilterQRUserLog({
      user: user.userId,
    });
    closeUserPopoverList();
  }, []);
  const closeUserPopoverList = () => {
    setAnchorUserList(null);
  };
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (anchorUserList) setShowUserList(true);
      else setShowUserList(false);
    }, 300);

    return () => clearTimeout(timeout);
  }, [anchorUserList]);
  return {
    selectedUser,
    anchorUserList,
    setAnchorUserList,
    showUserList,
    closeUserPopoverList,
    handleSelectUser,
  };
};

export default useQRLogFilterUser;
