import { create } from "zustand";
import { DrawingSet, Sheet } from "../Models/drawings";
import { produce } from "immer";

interface DrawingStore {
  selectedDrawingForDelete: DrawingSet | undefined;
  setSelectedDrawingForDelete: (item: DrawingSet | undefined) => void;

  selectedSheetForDelete: Sheet | undefined;
  setSelectedSheetForDelete: (item: Sheet | undefined) => void;

  viewedDrawingSet: DrawingSet | undefined;
  setViewedDrawingSet: (item: DrawingSet | undefined) => void;

  previewedDrawingSet: PreviewedDrawingSet | undefined;
  setPreviewedDrawingSet: (
    item:
      | {
          drawingSet: DrawingSet;
          currentIndex?: number;
        }
      | undefined
  ) => void;

  //* checkbox drawings
  selectedDrawingIds: string[];
  handleAddDrawingId: (id: string) => void;
  handleRemoveDrawingId: (id: string) => void;
  handleSelectAllDrawingIds: (ids: string[]) => void;
  handleClearAllSelectedDrawingIds: () => void;

  //* checkbox sheet
  selectedSheetIds: string[];
  handleAddSheetId: (id: string) => void;
  handleRemoveSheetId: (id: string) => void;
  handleSelectAllSheetIds: (ids: string[]) => void;
  handleClearAllSelectedSheetIds: () => void;

  currentDraggedDrawing: DrawingSet | undefined;
  setCurrentDraggedDrawing: (item: DrawingSet | undefined) => void;

  resetStore: () => void;
}

export interface PreviewedDrawingSet {
  drawingSet: DrawingSet;
  currentIndex?: number;
}
export const useDrawingStore = create<DrawingStore>((set, get) => ({
  selectedDrawingForDelete: undefined,
  setSelectedDrawingForDelete: (item: DrawingSet | undefined) =>
    set({ selectedDrawingForDelete: item }),

  selectedSheetForDelete: undefined,
  setSelectedSheetForDelete: (item: Sheet | undefined) =>
    set({ selectedSheetForDelete: item }),

  viewedDrawingSet: undefined,
  setViewedDrawingSet: (item: DrawingSet | undefined) =>
    set({ viewedDrawingSet: item }),

  previewedDrawingSet: undefined,
  setPreviewedDrawingSet: (item: PreviewedDrawingSet | undefined) =>
    set((state) => ({
      previewedDrawingSet: item,
    })),

  selectedDrawingIds: [],
  handleAddDrawingId: (id: string) =>
    set((state) =>
      produce(state, (draft) => {
        draft.selectedDrawingIds.push(id);
        return draft;
      })
    ),
  handleRemoveDrawingId: (id: string) =>
    set((state) =>
      produce(state, (draft) => {
        draft.selectedDrawingIds.splice(
          state.selectedDrawingIds.findIndex((selectedId) => selectedId === id),
          1
        );
        return draft;
      })
    ),
  handleSelectAllDrawingIds: (ids: string[]) =>
    set((state) =>
      produce(state, (draft) => {
        draft.selectedDrawingIds = ids;

        return draft;
      })
    ),
  handleClearAllSelectedDrawingIds: () => set({ selectedDrawingIds: [] }),

  selectedSheetIds: [],
  handleAddSheetId: (id: string) =>
    set((state) =>
      produce(state, (draft) => {
        draft.selectedSheetIds.push(id);
        return draft;
      })
    ),
  handleRemoveSheetId: (id: string) =>
    set((state) =>
      produce(state, (draft) => {
        draft.selectedSheetIds.splice(
          state.selectedSheetIds.findIndex((selectedId) => selectedId === id),
          1
        );
        return draft;
      })
    ),
  handleSelectAllSheetIds: (ids: string[]) =>
    set((state) =>
      produce(state, (draft) => {
        draft.selectedSheetIds = ids;

        return draft;
      })
    ),
  handleClearAllSelectedSheetIds: () => set({ selectedSheetIds: [] }),
  currentDraggedDrawing: undefined,
  setCurrentDraggedDrawing: (item: DrawingSet | undefined) =>
    set((state) =>
      produce(state, (draft) => {
        draft.currentDraggedDrawing = item;

        return draft;
      })
    ),

  resetStore: () =>
    set({
      selectedSheetIds: [],
      viewedDrawingSet: undefined,
      selectedDrawingForDelete: undefined,
      previewedDrawingSet: undefined,

      selectedDrawingIds: [],
    }),
}));
