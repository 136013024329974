import React, { useEffect, useState } from "react";
import { QAWithFirstImg } from "../../../../Models/qa";
import QAQueryAPI, {
  formDefectListDetailQKey,
} from "../../../../QueryAPI/qaQueryAPI";
import useDoxleNotificationStore from "../../../../../DoxleGeneralStore/useDoxleNotificationStore";
import { shallow } from "zustand/shallow";
import { useDoxleAuthStore } from "../../../../../DoxleGeneralStore/useDoxleAuthStore";
import { useDoxleCurrentContextStore } from "../../../../../DoxleGeneralStore/useDoxleCurrentContext";
import { useQueryClient } from "@tanstack/react-query";
import useSetQAQueryData from "../../../../CustomQueryHooks/useSetQAQueryData";
import { useProjectQAStore } from "../../../Store/useProjectQAStore";

type Props = { qaItem: QAWithFirstImg };

interface QAItemRow {
  isLoadingImg: boolean;
  isImgError: boolean;
  onLoadImgStart: () => void;
  onLoadImgEnd: () => void;
  onLoadImgError: () => void;
  setIsHoverRow: React.Dispatch<React.SetStateAction<boolean>>;
  isHoverRow: boolean;
  handleUpdateStatusQAItem: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
  isUpdatingQAItem: boolean;
}
const useQAItemRow = ({ qaItem }: Props): QAItemRow => {
  const [isHoverRow, setIsHoverRow] = useState(false);
  const [isLoadingImg, setIsLoadingImg] = useState(false);
  const [isImgError, setIsImgError] = useState(false);

  const onLoadImgStart = () => {
    setIsLoadingImg(true);
  };
  const onLoadImgEnd = () => {
    setIsLoadingImg(false);
  };
  const onLoadImgError = () => {
    setIsLoadingImg(false);
    setIsImgError(true);
  };
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );
  const { company } = useDoxleCurrentContextStore(
    (state) => ({
      company: state.currentCompany,
    }),
    shallow
  );
  const { setSelectedQAItem } = useProjectQAStore(
    (state) => ({
      setSelectedQAItem: state.setSelectedQAItem,
    }),
    shallow
  );
  const { handleEditQAQueryData } = useSetQAQueryData({
    defectListId: qaItem.defectList,
  });
  const queryClient = useQueryClient();
  const onUpdateSuccessCb = () => {
    const qaListDetailQKey = formDefectListDetailQKey(
      qaItem.defectList,
      company
    );
    queryClient.invalidateQueries(qaListDetailQKey);
  };
  const updateQAQuery = QAQueryAPI.useUpdateQAQuery({
    company,
    qaItem: qaItem,
    showNotification,
    onSuccessCB: onUpdateSuccessCb,
  });

  const handleUpdateStatusQAItem = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.stopPropagation();
    if (!updateQAQuery.isLoading) {
      if (qaItem.status === "Completed")
        updateQAQuery.mutate({ status: "Unattended" });
      else updateQAQuery.mutate({ status: "Completed" });
    }
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (qaItem.isNew) {
        setSelectedQAItem(qaItem);

        handleEditQAQueryData({ ...qaItem, isNew: false });
      }
    }, 300);
    return () => clearTimeout(timeout);
  }, []);

  return {
    isLoadingImg,
    isImgError,
    onLoadImgStart,
    onLoadImgEnd,
    onLoadImgError,
    setIsHoverRow,
    isHoverRow,
    handleUpdateStatusQAItem,
    isUpdatingQAItem: updateQAQuery.isLoading,
  };
};

export default useQAItemRow;
