import React, { useEffect, useMemo, useState } from "react";
import { ContactsFilters } from "../../../../Services/QueryHooks/contactsFilters";
import ContactsAPI from "../../../../Services/QueryHooks/contactsAPI";
import { useDoxleCurrentContextStore } from "../../../../DoxleGeneralStore/useDoxleCurrentContext";
import { shallow } from "zustand/shallow";
import { Contact } from "../../../../Models/addressBook";

type Props = {};

interface AddMailToSection {
  handleClickContact: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  anchorContactListPopover: HTMLDivElement | null;
  handleCloseContactList: () => void;
}
const useAddMailToSection = (props: Props): AddMailToSection => {
  const [anchorContactListPopover, setAnchorContactListPopover] =
    useState<HTMLDivElement | null>(null);

  const handleClickContact = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    setAnchorContactListPopover(e.currentTarget);
  };
  const handleCloseContactList = () => {
    setAnchorContactListPopover(null);
  };

  return {
    handleClickContact,
    anchorContactListPopover,
    handleCloseContactList,
  };
};

export default useAddMailToSection;
