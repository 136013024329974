import React, { useEffect } from "react";

import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";

import { PiSignOutThin } from "react-icons/pi";
import {
  InventoryIcon,
  StyledFileIcon,
  StyledSettingsIcon,
  StyledStoryIcon,
} from "./Asset/SideMenuIcons";
import { StyledNavMenuItem } from "./StyledNavBarEllipsis";
import { useRootNavBarPopoverMenuContext } from "../../../DoxleDesignPattern/DoxleNestedPopover";
import {
  DoxleBudgetIcons,
  DoxleContactIcon,
  DoxleFileIcon,
  DoxleGridToggleIcon,
  DoxlePricebookIcon,
  DoxleThemeSwitchIcon,
} from "../../../DoxleDesignPattern/DoxleGeneralIcons";
import {
  TNavBarMenu,
  useDoxleCurrentContextStore,
} from "../../../DoxleGeneralStore/useDoxleCurrentContext";
import { useShallow } from "zustand/react/shallow";
import { ImStarEmpty, ImStarFull } from "react-icons/im";
import { editRgbaAlpha } from "../../../Utilities/FunctionUtilities";

type Props = {
  menu: TNavBarMenu;
  handlePressMenuItem: (nav: TNavBarMenu) => void;
};

const NavMenuItem = ({ menu, handlePressMenuItem }: Props) => {
  const { displayedMenuItem, setDisplayedMenuItem } =
    useDoxleCurrentContextStore(
      useShallow((state) => ({
        displayedMenuItem: state.displayedMenuItem,
        setDisplayedMenuItem: state.setDisplayedMenuItem,
      }))
    );

  const { rootPopupState } = useRootNavBarPopoverMenuContext();
  if (!rootPopupState) throw new Error("must be used inside a CascadingMenu");
  const handleClick = React.useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      rootPopupState.close();
      handlePressMenuItem(menu);
    },
    [rootPopupState]
  );
  const handleClickPin = (
    e: React.MouseEvent<SVGElement, MouseEvent>,
    menu: TNavBarMenu
  ) => {
    e.stopPropagation();
    rootPopupState.close();
    setDisplayedMenuItem(menu);
  };
  const { doxleThemeColor, staticMenuColor, isGridMode } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      staticMenuColor: state.staticMenuColor,
      isGridMode: state.isGridMode,
    })
  );

  return (
    <StyledNavMenuItem
      layout="position"
      transition={{
        duration: 0.2,
        damping: 16,
      }}
      onClick={handleClick}
    >
      <MenuIcon menu={menu} />
      <span
        className="menu-text"
        style={
          menu === "Grid Mode" && isGridMode
            ? {
                color: doxleThemeColor.doxleColor,
              }
            : undefined
        }
      >
        {menu}
      </span>

      {(menu === "Budgets" ||
        menu === "Pricebook" ||
        menu === "Files" ||
        menu === "Contacts") && (
        <>
          {displayedMenuItem.includes(menu) && (
            <ImStarFull
              color={doxleThemeColor.doxleColor}
              size={16}
              onClick={(e) => handleClickPin(e, menu)}
            />
          )}
          {!displayedMenuItem.includes(menu) && (
            <ImStarEmpty
              color={editRgbaAlpha({
                rgbaColor: staticMenuColor.staticFontColor,
                alpha: "0.4",
              })}
              size={16}
              onClick={(e) => handleClickPin(e, menu)}
            />
          )}
        </>
      )}
    </StyledNavMenuItem>
  );
};

export default NavMenuItem;

const MenuIcon = ({ menu }: { menu: TNavBarMenu }) => {
  const { doxleThemeColor, staticMenuColor, isGridMode } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      staticMenuColor: state.staticMenuColor,
      isGridMode: state.isGridMode,
    })
  );
  if (menu === "Our Story")
    return (
      <StyledStoryIcon
        themeColor={doxleThemeColor}
        staticColor={staticMenuColor.staticFontColor}
        containerStyle={{ marginRight: 2 }}
      />
    );
  else if (menu === "Inventory")
    return (
      <InventoryIcon
        themeColor={doxleThemeColor}
        staticColor={staticMenuColor.staticFontColor}
        containerStyle={{
          marginRight: 2,
        }}
      />
    );
  else if (menu === "Settings")
    return (
      <StyledSettingsIcon
        themeColor={doxleThemeColor}
        staticColor={staticMenuColor.staticFontColor}
        style={{
          marginRight: 2,
        }}
      />
    );
  else if (menu === "Files")
    return (
      <DoxleFileIcon
        themeColor={doxleThemeColor}
        staticColor={staticMenuColor.staticFontColor}
      />
    );
  else if (menu === "Log out")
    return <PiSignOutThin size={20} color={staticMenuColor.staticFontColor} />;
  else if (menu === "Theme")
    return (
      <DoxleThemeSwitchIcon
        themeColor={doxleThemeColor}
        containerStyle={{
          width: 18,
          marginRight: 2,
        }}
      />
    );
  else if (menu === "Grid Mode")
    return (
      <DoxleGridToggleIcon
        themeColor={doxleThemeColor}
        staticColor={isGridMode ? doxleThemeColor.doxleColor : undefined}
        containerStyle={{
          width: 16,
          marginRight: 4,
        }}
      />
    );
  else if (menu === "Pricebook")
    return (
      <DoxlePricebookIcon
        themeColor={doxleThemeColor}
        staticColor={staticMenuColor.staticFontColor}
        containerStyle={{
          width: 18,
        }}
      />
    );
  else if (menu === "Budgets")
    return (
      <DoxleBudgetIcons
        themeColor={doxleThemeColor}
        staticColor={staticMenuColor.staticFontColor}
        containerStyle={{
          width: 18,
        }}
      />
    );
  else if (menu === "Contacts")
    return (
      <DoxleContactIcon
        themeColor={doxleThemeColor}
        staticColor={staticMenuColor.staticFontColor}
        containerStyle={{
          width: 18,
        }}
      />
    );
  else return <></>;
};
