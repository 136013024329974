import React from "react";
import { Order } from "../../../Models/orders";
import BookingOrderNumber from "../../../CommonComponents/BookingOrderNumber";
import { useDoxleThemeStore } from "../../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import { StyledOrderRowContentText } from "./StyledComponentBookingDocketDetail";
import { formatter } from "../../../../Utilities/FunctionUtilities";

type Props = { order: Order };

const BookingOrderRow = ({ order }: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }),
    shallow
  );
  return (
    <>
      <div className="order-num-wrapper">
        <BookingOrderNumber order={order} />
      </div>

      <StyledOrderRowContentText
        $themeColor={doxleThemeColor}
        $doxleFont={doxleFont}
        $variants="contractor"
      >
        {order.supplier ? order.supplier : "Unknown Contractor"}
      </StyledOrderRowContentText>

      <StyledOrderRowContentText
        $themeColor={doxleThemeColor}
        $doxleFont={doxleFont}
        $variants="lineCost"
      >
        {formatter.format(Number(order.total))}
      </StyledOrderRowContentText>
    </>
  );
};

export default BookingOrderRow;
