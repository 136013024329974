import React from "react";
import animationData from "../Lottie/lotties/completeCircle.json";
import { useLottie } from "lottie-react";
import { motion } from "framer-motion";

type Props = {
  animationSize?: number;
};

const CompleteCircle = ({ animationSize = 40 }: Props) => {
  const options = {
    animationData: animationData,
    loop: true,
    autoplay: true,
  };

  const style = {
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
    backgroundColor: "transparent",
    zIndex: 10,
  };

  const { View } = useLottie(options, {
    width: `${animationSize}px`,
    height: `${animationSize}px`,
  });

  const containerAnimatedVariants = {
    entering: {
      opacity: [0, 1],
      transition: {
        duration: 0.4,
      },
    },
    exiting: {
      opacity: [1, 0],
      transition: {
        duration: 0.4,
      },
    },
  };
  return (
    <motion.div
      style={style}
      variants={containerAnimatedVariants}
      initial={false}
      animate="entering"
      exit="exiting"
    >
      {View}
    </motion.div>
  );
};

export default CompleteCircle;
