import React from "react";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import {
  StyledDoxleGalleryPageControlSection,
  StyledDoxleGalleryTopMenu,
  StyledGalleryItemTitle,
  StyledGalleryPageControlButton,
} from "./StyledComponentDoxleGallery";
import { GalleryNextIcon, GalleryPreviousIcon } from "./DoxleGalleryIcons";
import { useControls } from "react-zoom-pan-pinch";
import { useDoxleImageGalleryStore } from "./Store/useDoxleImageGalleryStore";
import { shallow } from "zustand/shallow";
import { IoClose } from "react-icons/io5";
import DoxleIconButton from "../DoxleButtons/DoxleIconButton";

type Props = {};

const DoxleGalleryControlTab = ({}: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore((state) => ({
    doxleThemeColor: state.doxleThemeColor,
    doxleFont: state.doxleFont,
  }));
  const { zoomIn, zoomOut, resetTransform } = useControls();
  const {
    handleSwitchNextImg,
    handleSwitchPreviousImg,
    currentImageIndex,
    imageList,
    resetGallery,
  } = useDoxleImageGalleryStore(
    (state) => ({
      handleSwitchNextImg: state.handleSwitchNextImg,
      handleSwitchPreviousImg: state.handleSwitchPreviousImg,
      currentImageIndex: state.currentImageIndex,
      imageList: state.imageList,
      resetGallery: state.resetGallery,
    }),
    shallow
  );
  return (
    <StyledDoxleGalleryTopMenu>
      <StyledGalleryItemTitle
        $themeColor={doxleThemeColor}
        $doxleFont={doxleFont}
      >
        {imageList[currentImageIndex] ? imageList[currentImageIndex].name : ""}
      </StyledGalleryItemTitle>

      <DoxleIconButton
        iconSource={
          <IoClose size="50px" color={doxleThemeColor.primaryFontColor} />
        }
        buttonSize={50}
        buttonWrapperStyle={{
          position: "absolute",
          right: 0,
        }}
        onClick={resetGallery}
      />
      <StyledDoxleGalleryPageControlSection
        $themeColor={doxleThemeColor}
        $doxleFont={doxleFont}
      >
        <StyledGalleryPageControlButton
          disableRipple
          style={{ backgroundColor: "transparent" }}
          onClick={() => {
            if (currentImageIndex > 0) resetTransform();
            handleSwitchPreviousImg();
          }}
        >
          <GalleryPreviousIcon themeColor={doxleThemeColor} />
        </StyledGalleryPageControlButton>

        <span className="page-number-text">
          {currentImageIndex + 1} of {imageList.length}
        </span>

        <StyledGalleryPageControlButton
          disableRipple
          onClick={() => {
            if (currentImageIndex < imageList.length - 1) resetTransform();
            handleSwitchNextImg();
          }}
        >
          <GalleryNextIcon themeColor={doxleThemeColor} />
        </StyledGalleryPageControlButton>
      </StyledDoxleGalleryPageControlSection>
    </StyledDoxleGalleryTopMenu>
  );
};

export default DoxleGalleryControlTab;
