import { useEffect, useState } from "react";
import { useDoxleAuthStore } from "../../../../DoxleGeneralStore/useDoxleAuthStore";
import { useDoxleCurrentContextStore } from "../../../../DoxleGeneralStore/useDoxleCurrentContext";
import useDoxleNotificationStore from "../../../../DoxleGeneralStore/useDoxleNotificationStore";
import { useEditDocketSideScreenStore } from "../../../../CoreContent/EditDocketSideScreen/EditDocketSideScreenStore/useEditDocketSideScreenStore";
import { shallow } from "zustand/shallow";
import { useEstimatesStore } from "../../Store/useEstimatesStore";
import EstimatesQueryAPI, { getMutateEstimateKey } from "../../../QueryHooks/EstimatesQueryAPI";
import { VirtuosoHandle } from "react-virtuoso";
import useGetEstimateList from "./useGetEstimateList";
import { useIsMutating } from "@tanstack/react-query";

type Props = { estimateListRef: React.RefObject<VirtuosoHandle> };
interface EditDocketBudgetEstimate {}
const useEditDocketBudgetEstimate = ({
  estimateListRef,
}: Props): EditDocketBudgetEstimate => {
  const { company } = useDoxleCurrentContextStore((state) => ({
    company: state.currentCompany,
  }));
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification
  );
  const { edittedDocket, setHandleAddButtonFn } = useEditDocketSideScreenStore(
    (state) => ({
      edittedDocket: state.edittedDocket,
      setHandleAddButtonFn: state.setHandleAddButtonFn,
    }),
    shallow
  );

  const docketId = edittedDocket?.docketId;
  const { estimatesQueryFilter } = useEstimatesStore(
    (state) => ({
      estimatesQueryFilter: state.estimatesQueryFilter,
    }),
    shallow
  );
  const { budgetEstimateItemList } = useGetEstimateList({});

  const onAddSuccessCb = () => {
    if (estimateListRef.current)
      estimateListRef.current.scrollToIndex({
        index: budgetEstimateItemList.length - 1,
        behavior: "smooth",
        offset: 40,
      });
  };
  const { add } = EstimatesQueryAPI.useMutateEstimate({
    company,
    showNotification,
    filter: estimatesQueryFilter,
    docketId,
    onAddSuccessCb: onAddSuccessCb,
  });
  const handleAddNewEstimate = () => {
    const newItem = {
      description: "Untitled budget",
      docket: edittedDocket?.docketPk,
      company: company?.companyId ?? "",
      project: edittedDocket?.project,
    };

    add.mutate(newItem);
  };

  const isAddingItem = useIsMutating({
    mutationKey: getMutateEstimateKey("add"),
  });

  useEffect(() => {
    setHandleAddButtonFn([{ btnFunction: handleAddNewEstimate, isLoading: isAddingItem > 0}]);
  }, [isAddingItem]);

  return {};
};

export default useEditDocketBudgetEstimate;
