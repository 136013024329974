import { useState } from "react";

import {
  StyledScopeImageGalleryItem,
  StyledScopeImageHolder,
  StyledScopeImageToggleMenu,
  StyledSelectedScopeImageEffect,
} from "./StyledComponentScopeImageGallery";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import CheckIcon from "@mui/icons-material/Check";
import EditOffIcon from "@mui/icons-material/EditOff";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

import ScopeImageInfoTextField from "./ScopeImageInfoTextField";
import { AnimatePresence } from "framer-motion";

import useScopeImgGalleryItem from "../../Hooks/useScopeImgGalleryItem";
import CheckBoxSharpIcon from "@mui/icons-material/CheckBoxSharp";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import DoxleIconButton from "../../../DoxleDesignPattern/DoxleButtons/DoxleIconButton";
import ProgressScreen from "../../../Utilities/Lottie/ProgressScreen";
import DeleteItemLoadingScreen from "../../../Utilities/Lottie/DeleteItemLoadingScreen";
import { ScopeOfWorkImage } from "../../Model/scopeOfWorks";

type Props = {
  scopeImgItem: ScopeOfWorkImage;
};

const ScopeImageGalleryItem = ({ scopeImgItem }: Props) => {
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const { handleClickScopeImgItem, isDeletingScopeImg, handleDeleteScopeImg } =
    useScopeImgGalleryItem({ scopeImgItem });
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore((state) => ({
    doxleThemeColor: state.doxleThemeColor,
    doxleFont: state.doxleFont,
  }));

  const onMouseEnterScopeImg = () => {
    setShowMenu(true);
  };
  const onMouseLeaveScopeImg = () => {
    setShowMenu(false);
  };

  //# Animation

  const menuAnimatedVariants = {
    initial: {
      opacity: 0,
      y: -10,
    },
    entering: {
      y: 0,
      opacity: 1,
    },
    exiting: {
      opacity: 0,
      y: -10,
    },
  };

  return (
    <StyledScopeImageGalleryItem
      onMouseEnter={onMouseEnterScopeImg}
      onMouseLeave={onMouseLeaveScopeImg}
      onClick={handleClickScopeImgItem}
    >
      {scopeImgItem.url && (
        <div
          style={{
            flex: 1,
            width: "100%",
            display: "flex",
            overflow: "hidden",
          }}
        >
          <StyledScopeImageHolder src={scopeImgItem.url} />
        </div>
      )}
      <ScopeImageInfoTextField
        keyValue="scopeImageTitle"
        scopeImgItem={scopeImgItem}
      />

      <ScopeImageInfoTextField
        keyValue="scopeImageDescription"
        scopeImgItem={scopeImgItem}
      />

      <AnimatePresence>
        {showMenu && (
          <StyledScopeImageToggleMenu
            variants={menuAnimatedVariants}
            initial={false}
            animate="entering"
            exit="exiting"
            transition={{
              duration: 0.2,
            }}
          >
            <DoxleIconButton
              iconSource={
                <DeleteOutlineIcon
                  htmlColor={doxleThemeColor.primaryFontColor}
                  style={{ fontSize: "1.8rem" }}
                />
              }
              buttonWrapperStyle={{ marginInline: "0.2rem !important" }}
              buttonSize={30}
              bgColor={doxleThemeColor.primaryContainerColor}
              onClick={handleDeleteScopeImg}
            />
          </StyledScopeImageToggleMenu>
        )}
      </AnimatePresence>

      {isDeletingScopeImg && (
        <DeleteItemLoadingScreen
          animationSize={144}
          hasBackdrop
          containerStyle={{
            paddingBottom: "3rem",
          }}
        />
      )}

      <AnimatePresence>
        {/* {isItemSelected && (
          <StyledSelectedScopeImageEffect
            $themeColor={doxleThemeColor}
            initial={{
              opacity: 0,
            }}
            animate={{
              opacity: 1,
              transition: { duration: 0.2 },
            }}
            exit={{
              opacity: 0,
              transition: { duration: 0.2 },
            }}
          >
            <CheckBoxSharpIcon
              htmlColor={doxleThemeColor.primaryFontColor}
              style={{ fontSize: "4rem" }}
            />
          </StyledSelectedScopeImageEffect>
        )} */}
      </AnimatePresence>
    </StyledScopeImageGalleryItem>
  );
};

export default ScopeImageGalleryItem;
