import React, { useCallback, useMemo } from "react";

import {
  StyledQuoteSOWView,
  StyledSOWListScroller,
} from "../StyledComponentQuoteResponse";
import { Components, ItemContent, Virtuoso } from "react-virtuoso";
import { AnimatePresence, motion } from "framer-motion";
import DoxleEmptyPlaceHolder from "../../../../DoxleDesignPattern/DoxleEmptyPlaceHolder/DoxleEmptyPlaceHolder";
import QuoteSOWGroupItem from "./QuoteSOWGroupItem";
import { ScopeOfWorkGroup } from "../../../../ScopeOfWorks/Model/scopeOfWorks";

type Props = {
  scopeGroups: ScopeOfWorkGroup[];
};

interface SOWGroupListContext {}
const QuoteSOWView = ({ scopeGroups }: Props) => {
  const sowRootAnimatedVariants = useMemo(
    () => ({
      initial: {
        y: -10,
        opacity: 0,
      },
      entering: {
        y: 0,
        opacity: 1,
      },
    }),
    []
  );

  const components: Components<ScopeOfWorkGroup, SOWGroupListContext> = useMemo(
    () => ({
      Scroller: React.forwardRef(({ children, ...props }, ref) => (
        <StyledSOWListScroller
          style={{
            ...props.style,
          }}
          ref={ref}
          {...props}
        >
          <AnimatePresence>{children}</AnimatePresence>
        </StyledSOWListScroller>
      )),
      Item: React.forwardRef((props) => <motion.div {...props} layout />),
      EmptyPlaceholder: () => (
        <DoxleEmptyPlaceHolder
          headTitleText="There is no Scope Of Work for this quote!"
          containerBgColor="transparent"
        />
      ),
    }),
    []
  );

  const itemContent: ItemContent<ScopeOfWorkGroup, SOWGroupListContext> =
    useCallback((_index, item, context) => {
      return (
        <QuoteSOWGroupItem
          key={`sowGroup${item.scopeGroupId}`}
          sowGroup={item}
        />
      );
    }, []);

  return (
    <StyledQuoteSOWView
      variants={sowRootAnimatedVariants}
      initial="initial"
      animate="entering"
    >
      <Virtuoso
        data={scopeGroups}
        components={components}
        itemContent={itemContent}
      />
    </StyledQuoteSOWView>
  );
};

export default QuoteSOWView;
