import { useInfiniteQuery, useMutation } from "@tanstack/react-query";
import axios, { isAxiosError } from "axios";
import { BaseAPIProps } from "../../Models/baseAPIProps";
import DoxleAPI from "../../Services/DoxleAPI";
import {
  AxiosBackendErrorReturn,
  IApiPaginatedData,
} from "../../Models/axiosReturn";
import { Note, NoteLine } from "../Models/note";
import useSetNoteQueryDataQuery from "../Hooks/useSetNoteQueryDataQuery";
import useSetNoteLineQueryDataQuery from "../Hooks/useSetNoteLineQueryData";
import { Company } from "../../Models/company";

export interface FilterGetNoteQuery {
  project?: string;
  docket?: string;
  searchText?: string;
}
interface RetrieveNoteListQueryProps extends BaseAPIProps {
  filter: FilterGetNoteQuery;
  enableQuery?: boolean;
}

const useRetrieveNoteListQuery = ({
  showNotification,

  company,
  filter,
  enableQuery,
}: RetrieveNoteListQueryProps) => {
  const { project, docket, searchText } = filter;
  const qKey = getNoteListQKey(filter, company);

  let noteURL = `/notes/?page=1`;
  const getParams: any = {};
  if (company) getParams.company = company.companyId;
  if (project) getParams.project = project;
  if (docket) getParams.docket = docket;
  if (searchText) getParams.search = searchText;
  const defectQuery = useInfiniteQuery(
    qKey,
    ({ pageParam = noteURL }) =>
      DoxleAPI.get<IApiPaginatedData<Note>>(pageParam, {
        headers: {
          "User-Company": company!.companyId,
        },
        params: getParams,
      }),

    {
      getNextPageParam: (prev) => prev.data.next,
      enabled: company !== undefined && (enableQuery || true),
      retry: 1,
      refetchInterval: 5 * 60 * 1000,
      onError: () => {
        if (showNotification)
          showNotification(
            "SOMETHING WRONG",
            "error",
            "Failed to get note list"
          );
      },
    }
  );
  return defectQuery;
};

interface MutateNoteQueryProps extends BaseAPIProps {
  filter: FilterGetNoteQuery;
  onSuccessAddCb?: (newNote?: Note) => void;
  onSuccessDeleteCb?: (deletedId?: string) => void;
  onSuccessUpdateCb?: (newNote?: Note) => void;
}
interface AddNoteQueryParams
  extends Partial<Pick<Note, "project" | "docket" | "title">> {}
interface UpdateNoteQueryParams extends AddNoteQueryParams {
  noteId: string;
}
const useMutateNoteQuery = ({
  company,
  filter,
  showNotification,
  onErrorCb,
  onSuccessAddCb,
  onSuccessDeleteCb,
  onSuccessUpdateCb,
}: MutateNoteQueryProps) => {
  const {
    handleDeleteNoteQueryData,
    handleAddNoteQueryData,
    handleEditNoteQueryData,
  } = useSetNoteQueryDataQuery({ filter });
  const update = useMutation({
    mutationKey: getNoteMutateKey("update"),
    mutationFn: async ({ noteId, ...data }: UpdateNoteQueryParams) => {
      const url = `/notes/${noteId}/`;
      return DoxleAPI.patch<Note>(url, data, {
        headers: {
          "User-Company": company?.companyId ?? "",
        },
      });
    },
    onSuccess: (result, variables, context) => {
      handleEditNoteQueryData(result.data);
      //   if (showNotification)
      //     showNotification(
      //       'Item Updated',
      //       'success',
      //       'SUCCESSFULLY Updated Estimate',
      //     );

      if (onSuccessUpdateCb) onSuccessUpdateCb(result.data);
    },
    onError: (error, variables, context) => {
      if (showNotification) {
        if (isAxiosError<AxiosBackendErrorReturn>(error)) {
          showNotification(
            `${error?.response?.status ?? "ERROR"}: ${
              error.response?.data.detail ?? "UNKNOWN ERROR"
            }`,
            "error",
            String(
              error?.response?.data?.detail ?? "Fail To update note"
            ).substring(0, 300)
          );
        } else {
          showNotification("Something Wrong!", "error", "Fail To update note");
        }
      }
    },
  });

  const add = useMutation({
    mutationKey: getNoteMutateKey("add"),
    mutationFn: async (note: AddNoteQueryParams) => {
      const url = `/notes/`;
      return DoxleAPI.post<Note>(url, note, {
        headers: {
          "User-Company": company?.companyId ?? "",
        },
      });
    },
    onSuccess: (result, variables, context) => {
      handleAddNoteQueryData({ ...result.data, isNew: true });
      if (showNotification)
        if (onSuccessAddCb)
          // showNotification("Item Added", "success", "SUCCESSFULLY added note");
          onSuccessAddCb(result.data);
    },
    // if (handleScrollToBottomBudgetTable) handleScrollToBottomBudgetTable()
    onError: (error, variables, context) => {
      if (showNotification) {
        if (isAxiosError<AxiosBackendErrorReturn>(error)) {
          showNotification(
            `${error?.response?.status ?? "ERROR"}: ${
              error.response?.data.detail ?? "UNKNOWN ERROR"
            }`,
            "error",
            String(
              error?.response?.data?.detail ?? "Fail To add note"
            ).substring(0, 300)
          );
        } else {
          showNotification("Something Wrong!", "error", "Fail To add note");
        }
      }
    },
  });

  const destroy = useMutation({
    mutationKey: getNoteMutateKey("delete"),
    mutationFn: async (deletedId: string) => {
      const url = `/notes/${deletedId}/`;
      return DoxleAPI.delete(
        url,

        {
          headers: {
            "User-Company": company?.companyId ?? "",
          },
        }
      );
    },
    onSuccess: (result, variables, context) => {
      handleDeleteNoteQueryData(variables);
      // if (showNotification)
      //   showNotification(
      //     "Note Deleted",
      //     "success",
      //     "SUCCESSFULLY Updated TakeOff"
      //   );

      if (onSuccessDeleteCb) onSuccessDeleteCb(variables);
    },
    onError: (error, variables, context) => {
      if (showNotification) {
        if (isAxiosError<AxiosBackendErrorReturn>(error)) {
          showNotification(
            `${error?.response?.status ?? "ERROR"}: ${
              error.response?.data.detail ?? "UNKNOWN ERROR"
            }`,
            "error",
            String(
              error?.response?.data?.detail ?? "Fail To delete note"
            ).substring(0, 300)
          );
        } else {
          showNotification("Something Wrong!", "error", "Fail To delete note");
        }
      }
    },
  });

  return {
    add: { ...add, mutate: (note: AddNoteQueryParams) => add.mutate(note) },
    destroy: {
      ...destroy,
      mutate: (deletedId: string) => destroy.mutate(deletedId),
    },
    update: {
      ...update,
      mutate: (data: UpdateNoteQueryParams) => update.mutate(data),
    },
  };
};

//* NOTE LINES
interface RetrieveNoteLineQueryProps extends BaseAPIProps {
  noteId: string;
}
const useRetrieveNoteLineListQuery = ({
  showNotification,

  company,
  noteId,
}: RetrieveNoteLineQueryProps) => {
  const qKey = getNoteLineListQKey(noteId, company);

  let noteURL = `/notes/line/?page=1`;
  const getParams: any = {};
  if (company) getParams.company = company.companyId;
  getParams.note = noteId;
  const defectQuery = useInfiniteQuery(
    qKey,
    ({ pageParam = noteURL }) =>
      DoxleAPI.get<IApiPaginatedData<NoteLine>>(pageParam, {
        headers: {
          "User-Company": company!.companyId,
        },
        params: getParams,
      }),

    {
      getNextPageParam: (prev) => prev.data.next,
      enabled: company !== undefined,
      retry: 1,
      refetchInterval: 5 * 60 * 1000,

      onSuccess: (res) => {},
      onError: (error) => {
        if (showNotification) {
          if (isAxiosError<AxiosBackendErrorReturn>(error)) {
            showNotification(
              `${error?.response?.status ?? "ERROR"}: ${
                error.response?.data.detail ?? "UNKNOWN ERROR"
              }`,
              "error",
              String(
                error?.response?.data?.detail ?? "Fail To get note list"
              ).substring(0, 300)
            );
          } else {
            showNotification(
              "Something Wrong!",
              "error",
              "Fail To get note list"
            );
          }
        }
      },
    }
  );
  return defectQuery;
};

interface MutateNoteLineQueryProps extends BaseAPIProps {
  noteId: string;
  onSuccessAddCb?: (newNote?: NoteLine) => void;
  onSuccessDeleteCb?: (deletedId?: string) => void;
  onSuccessUpdateCb?: (newNote?: NoteLine) => void;
}
interface MutateNoteLineQueryParams
  extends Partial<
    Pick<NoteLine, "text" | "isBold" | "isItalic" | "isQuoted" | "isBullet">
  > {}
export interface UpdateNoteLineQueryParams extends MutateNoteLineQueryParams {
  lineId: string;
}
const useMutateNoteLineQuery = ({
  company,
  noteId,
  showNotification,
  onErrorCb,
  onSuccessAddCb,
  onSuccessDeleteCb,
  onSuccessUpdateCb,
}: MutateNoteLineQueryProps) => {
  const {
    handleAddNoteLineQueryData,
    handleDeleteNoteLineQueryData,
    handleEditNoteLineQueryData,
  } = useSetNoteLineQueryDataQuery({ noteId });
  const update = useMutation({
    mutationFn: async ({ lineId, ...data }: UpdateNoteLineQueryParams) => {
      const url = "/notes/line/" + lineId + "/";
      return DoxleAPI.patch<NoteLine>(url, data, {
        headers: {
          "User-Company": company?.companyId ?? "",
        },
      });
    },
    onSuccess: (result, variables, context) => {
      handleEditNoteLineQueryData(result.data);
      //   if (showNotification)
      //     showNotification(
      //       'Item Updated',
      //       'success',
      //       'SUCCESSFULLY Updated Estimate',
      //     );

      if (onSuccessUpdateCb) onSuccessUpdateCb(result.data);
    },
    onError: (error, variables, context) => {
      if (showNotification) {
        if (isAxiosError<AxiosBackendErrorReturn>(error)) {
          showNotification(
            `${error?.response?.status ?? "ERROR"}: ${
              error.response?.data.detail ?? "UNKNOWN ERROR"
            }`,
            "error",
            String(
              error?.response?.data?.detail ?? "Fail To update line"
            ).substring(0, 300)
          );
        } else {
          showNotification("Something Wrong!", "error", "Fail To update line");
        }
      }
    },
  });

  const add = useMutation({
    mutationKey: ["add-note-line"],
    mutationFn: async (note: MutateNoteLineQueryParams) => {
      const url = "/notes/line/";
      return DoxleAPI.post<NoteLine>(
        url,
        { ...note, note: noteId },
        {
          headers: {
            "User-Company": company?.companyId ?? "",
          },
        }
      );
    },
    onSuccess: (result, variables, context) => {
      handleAddNoteLineQueryData(result.data);
      //   if (showNotification)
      //     showNotification('Item Added', 'success', 'SUCCESSFULLY added note');
      if (onSuccessAddCb) onSuccessAddCb(result.data);
    },
    // if (handleScrollToBottomBudgetTable) handleScrollToBottomBudgetTable()
    onError: (error, variables, context) => {
      if (showNotification) {
        if (isAxiosError<AxiosBackendErrorReturn>(error)) {
          showNotification(
            `${error?.response?.status ?? "ERROR"}: ${
              error.response?.data.detail ?? "UNKNOWN ERROR"
            }`,
            "error",
            String(
              error?.response?.data?.detail ?? "Fail To add line"
            ).substring(0, 300)
          );
        } else {
          showNotification("Something Wrong!", "error", "Fail To add line");
        }
      }
    },
  });

  const destroy = useMutation({
    mutationFn: async (deletedId: string) => {
      const url = "/notes/line/" + deletedId + "/";
      return DoxleAPI.delete(
        url,

        {
          headers: {
            "User-Company": company?.companyId ?? "",
          },
        }
      );
    },
    onSuccess: (result, variables, context) => {
      handleDeleteNoteLineQueryData(variables);
      // if (showNotification)
      //   showNotification(
      //     "Item Updated",
      //     "success",
      //     "SUCCESSFULLY Updated TakeOff"
      //   );

      if (onSuccessDeleteCb) onSuccessDeleteCb(variables);
    },
    onError: (error, variables, context) => {
      if (showNotification) {
        if (isAxiosError<AxiosBackendErrorReturn>(error)) {
          showNotification(
            `${error?.response?.status ?? "ERROR"}: ${
              error.response?.data.detail ?? "UNKNOWN ERROR"
            }`,
            "error",
            String(
              error?.response?.data?.detail ?? "Fail To delete line"
            ).substring(0, 300)
          );
        } else {
          showNotification("Something Wrong!", "error", "Fail To delete line");
        }
      }
    },
  });

  return {
    add: {
      ...add,
      mutate: (data: MutateNoteLineQueryParams) => add.mutate(data),
    },
    destroy: {
      ...destroy,
      mutate: (deletedId: string) => destroy.mutate(deletedId),
    },
    update: {
      ...update,
      mutate: (data: UpdateNoteLineQueryParams) => update.mutate(data),
    },
  };
};
//# HELPER FUNCTIONS
export const getNoteListQKey = (
  filter: FilterGetNoteQuery,
  company: Company | undefined
) => {
  let baseQKey = ["note-list"];
  if (company) baseQKey.push(company.companyId);
  const { project, docket, searchText } = filter;
  if (project) baseQKey.push(project);
  if (docket) baseQKey.push(docket);
  if (searchText) baseQKey.push(searchText);
  return baseQKey;
};

export const getNoteLineListQKey = (
  noteId: string,
  company: Company | undefined
) => {
  let baseQKey = ["note-line-list"];
  if (company) baseQKey.push(company.companyId);
  baseQKey.push(noteId);

  return baseQKey;
};

export const getNoteMutateKey = (action: "add" | "update" | "delete") => [
  `${action}-note`,
];

export const NoteQueryAPI = {
  useRetrieveNoteListQuery,
  useMutateNoteQuery,
  useRetrieveNoteLineListQuery,
  useMutateNoteLineQuery,
};
