import {
  Button,
  Dialog,
  IconButton,
  InputAdornment,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { motion } from "framer-motion";
import styled from "styled-components";
import {
  DoxleFont,
  DoxleThemeColor,
} from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { editRgbaAlpha } from "../../../Utilities/FunctionUtilities";
export const StyledPricebookCardListContainer = styled.div`
  height: calc(100% - 20px);
  width: calc(100% - 40px);
  padding: 0px 20px 20px 20px;
  display: flex;
  justify-content: center;
  overflow: hidden;
  position: relative;
  flex-direction: column;
`;

export const StyledPricebookCardWrapper = styled(motion.div)<{
  $numOfCol: number;
}>`
  width: calc(${(p) => 100 / p.$numOfCol}% - 20px);
  aspect-ratio: 0.6;
  padding: 20px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 2px solid ${(p) => p.theme.color.primaryDividerColor};
`;
export const StyledPricebookCardList = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;
export const StyledPricebookCard = styled.div`
  width: calc(100% - 22px);
  height: calc(100% - 22px);
  display: flex;
  padding: 10px;
  flex-direction: column;
  position: relative;
  align-items: center;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  cursor: pointer;
  border-radius: 4px;
  transition: all 0.5s ease-in-out;

  .bottom-menu {
    opacity: 0;
    transition: all 0.2s ease-in-out;
  }
  &:hover {
    border-color: ${(p) => p.theme.color.primaryFontColor};
    transition: all 0.5s ease-in-out;

    .bottom-menu {
      opacity: 1;
      transition: all 0.5s ease-in-out;
    }
  }
`;

export const StyledPricebookNameText = styled.div`
  font-family: ${(p) => p.theme.font.primaryFont};
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: ${(p) => p.theme.color.primaryFontColor};
  width: 100%;
  text-align: center;
  max-height: 40px;
  margin: 8px 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  white-space: nowrap;
`;
export const StyledPriceRateSection = styled.div`
  flex: 1;
  width: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  padding: 5px 0px;
`;
export const StyledPricebookCardImgSection = styled.div`
  width: 100%;
  aspect-ratio: 1;

  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  .image-wrapper {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
  .icon-prev {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 2;
    opacity: 0;
    transition: all 0.3s ease;
  }
  .icon-next {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 2;
    opacity: 0;
    transition: all 0.3s ease;
  }
  &:hover {
    .icon-prev {
      opacity: 1;
    }
    .icon-next {
      opacity: 1;
    }
  }
`;
export const StyledPricebookCardHeader = styled.div`
  width: 100%;
  height: 130px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${(p) => p.theme.color.primaryReverseFontColor};
`;

export const PricebookCardTitle = styled.div`
  display: flex;
  color: ${(p) => p.theme.color.primaryFontColor};
  font-size: 50px;
  font-family: Work Sans;
  font-weight: 300;
  padding: 40px;
`;

export const PricebookTableHeaderInput = styled(TextField)<{
  textHighlightColor?: string;
}>`
  && {
    display: flex;
  }
  & .MuiInput-input {
    cursor: default;
    border: none !important;
  }
  input {
    color: ${(p) => p.theme.color.primaryFontColor};
    font-size: 50px;
    font-family: Work Sans;
    font-weight: 300;
    padding: 40px;
  }
  input::first-line {
    background-color: ${(p) => p.textHighlightColor};
  }
  input[type="“number”"]::-webkit-inner-spin-button,
  input[type="“number”"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }
`;
export const StyledPricebookTitleContainer = styled.div`
  width: 60%;
  display: flex;
  position: relative;
`;

export const StyledPricebookCardSwatchContainer = styled.div`
  display: flex;
  /* flex-direction: row; */
  width: 40%;
  overflow-x: scroll;
  height: 125px;
  margin-right: 15px;
  /* position: relative;
  align-items: center;
  z-index: 0; */
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

export const StyledPricebookCardHeaderRemoveButtonDiv = styled.div`
  position: absolute;
  left: 0;
  top: -10px;
  cursor: pointer;
`;

export const PricebookTableHeaderImageDisplayMainDiv = styled.div<{
  opacity?: string;
}>`
  display: flex;
  position: relative;
  z-index: 1;
  ${(p) => (p.opacity ? `opacity: ${p.opacity}` : "")};
`;

export const PricebookTableHeaderSwatches = styled.img<{
  marginRight: string;
}>`
  && {
    min-width: 125px;
    max-width: 125px;
    height: 125px;
    margin-right: ${(p) => p.marginRight};
    border-radius: 5px;
  }
  &:hover {
  }
`;

export const PricebookTableHeaderImageContainer = styled(motion.div)`
  display: flex;
  position: relative;
  min-width: 75px;
  max-width: 75px;
  height: 75px;
  margin-right: 10px;
  z-index: 2;
  cursor: pointer;
`;

export const StyledPricebookCardBottomMenu = styled.div`
  display: flex;
  width: calc(100% - 10px);

  align-items: center;
  justify-content: flex-end;
  padding: 5px;
`;

export const StylerdPricebookCardEmpySwatchText = styled.div`
  display: flex;
  width: calc(90% - 40px);
  height: calc(90% - 40px);
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px;
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #bdbdbd;
`;

export const StyledPricebookCardDropText = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #bdbdbd;
`;

export const StyledPricebookCardDescriptionInput = styled(TextField)<{
  textHighlightColor?: string;
}>`
  && {
    display: flex;
  }
  & .MuiInput-input {
    cursor: default;
    border: none !important;
  }
  input {
    color: #000;
    font-size: 50px;
    font-family: Work Sans;
    font-weight: 300;
    padding: 40px;
  }
  input::first-line {
    background-color: ${(p) => p.textHighlightColor};
  }
  input[type="“number”"]::-webkit-inner-spin-button,
  input[type="“number”"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }
`;

export const StyledPricebookSupplierRateContainer = styled.div<{}>`
  width: 100%;
  display: flex;
  flex-direction: row;
  min-height: 33px;
  align-items: center;
  justify-content: center;
  position: relative;
`;

export const StyledSupplierRateCell = styled.div`
  display: flex;
  height: 100%;

  align-items: center;
  flex-direction: row;
  font-family: ${(p) => p.theme.font.primaryFont};
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: ${(p) => p.theme.color.doxleColor};
  padding: 0 !important;
  word-break: break-all;
  overflow-wrap: break-word;
  width: 50%;
`;
export const StyledRateInputEndornment = styled(InputAdornment)`
  && {
    margin-right: 2px;
  }
  & .MuiTypography-root {
    padding: 0px !important;
    justify-content: center;
    align-items: center;
    font-family: ${(p) => p.theme.font.primaryFont};
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    color: ${(p) => p.theme.color.primaryFontColor};
  }
`;
export const StyledSupplierRateInput = styled(TextField)<{
  $textHighlightColor?: string;
  $width?: string;
  $textAlign?: string;
}>`
  && {
    display: flex;
    justify-content: center;
    ${(p) => (p.$width ? `width: ${p.$width}` : "")};
  }
  & .MuiInput-input {
    cursor: default;
    border: none !important;
    ${(p) => (p.$textAlign ? `text-align: ${p.$textAlign}` : "")};
  }
  input {
    font-family: ${(p) => p.theme.font.primaryFont};
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    color: ${(p) => p.theme.color.primaryFontColor};
    padding: 0;
  }
`;
export const StyledRatePriceText = styled.div`
  font-family: ${(p) => p.theme.font.primaryFont};
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  color: ${(p) => p.theme.color.primaryFontColor};
  padding: 0;
  margin-right: 4px;
  width: calc(50% - 4px);
  text-align: right;
`;
export const StyledPricebookNameStrip = styled.div`
  background-color: #7070ff;
  width: 10px;
  height: 4px;
`;

export const StyledCardMenuHoverButton = styled(motion.div)`
  display: flex;
  align-items: center;
  padding: 5px;
  overflow: hidden;
  transition: all 0.3s ease;
  border-radius: 14px;
  .btn-text {
    font-family: ${(p) => p.theme.font.primaryFont};
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: ${(p) => p.theme.color.primaryFontColor};

    max-width: 0px;

    transition: all 0.4s ease;
    opacity: 0;
  }

  &:hover {
    background-color: ${(p) => p.theme.color.primaryContainerColor};
    box-shadow: 0px 4px 4px ${(p) => p.theme.color.primaryBoxShadowColor};
    .btn-text {
      margin: 0px 10px;
      opacity: 1;
      max-width: 100px;
      transform: translateX(0);
    }
  }
`;
export const StyledSupplierRateRemoveIconDiv = styled.div`
  display: flex;
  z-index: 2;
  position: absolute;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  right: 0;
  top: 50%;
  cursor: pointer;
`;

export const StyledPricebookSwatchesContainer = styled.div<{
  opacity?: string;
}>`
  display: flex;
  position: relative;
  z-index: 1;
  ${(p) => (p.opacity ? `opacity: ${p.opacity}` : "")};
`;

export const StyledPricebookSwatchesHeader = styled.img<{
  marginRight: string;
}>`
  && {
    min-width: 125px;
    max-width: 125px;
    height: 125px;
    margin-right: ${(p) => p.marginRight};
    border-radius: 5px;
  }
  &:hover {
  }
`;

export const StyledPricebookSwatchesDeleteButton = styled(Button)`
  && {
    position: absolute;
    top: 5px;
    left: 100px;
  }
`;

export const StyledPricebookAddNewDialog = styled(Dialog)`
  & .MuiDialog-paper {
    width: 955px;
    min-width: 700px;
    max-width: none;
    height: 90vh;
    box-shadow: none !important;
    overflow: scroll;
    justify-content: center;
    overflow: hidden;
  }
`;

export const StyledPricebookAddNewContainer = styled.div<{}>`
  && {
    width: calc(100% - 160px);
    height: calc(100% - 160px);
    display: flex;
    padding: 80px;
    flex-direction: column;
    position: relative;
    background-color: ${(p) => p.theme.color.primaryBackgroundColor};
  }
`;

export const StyledPricebookAddEditHeadTitle = styled.div`
  && {
    width: 153px;
    height: 28px;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #7070ff;
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    color: white;
    letter-spacing: 0.05em;
  }
`;

export const StyledFormInputSection = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  flex: 1;
  width: 100%;
  padding-bottom: 40px;
  // &::-webkit-scrollbar {
  //   display: none;
  // }
  // -ms-overflow-style: none;
  // scrollbar-width: none;
`;

export const StyledPricebookAddNewSpaceBetweenDiv = styled.div<{
  marginTop?: string;
}>`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: ${(p) => (p.marginTop ? p.marginTop : "0px")};
`;

export const StyledPricebookAddNewDeleteButton = styled(Button)`
  && {
    height: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
  }
  &.MuiButton-root {
    color: grey;
  }
  &.MuiButtonBase-root {
  }
  &.MuiButton-root:hover {
    background-color: transparent;
  }
`;

export const StyledPricebookAddNewInputDiv = styled(motion.div)<{
  $width: string;
  $marginTop?: string;
  $marginBottom?: string;
}>`
  && {
    width: ${(p) => p.$width};
    display: flex;
    flex-direction: column;
    margin-top: ${(p) => (p.$marginTop ? p.$marginTop : "35px")};
    ${(p) => (p.$marginBottom ? `margin-bottom: ${p.$marginBottom}` : "")};
  }
`;

export const StyledWarningRow = styled(motion.div)`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 4px 0px;
`;
export const StyledWarningField = styled.div<{
  $width: string;
}>`
  color: red;
  width: ${(p) => p.$width};
  text-transform: capitalize;
  position: relative;
  bottom: 0.4rem;
`;

export const StyledPricebookAddNewInputTitleText = styled(Typography)<{
  $marginBottom?: string;
  $paddingRight?: string;
}>`
  && {
    color: ${(p) => p.theme.color.primaryFontColor};
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.05em;
    margin-bottom: ${(p) => (p.$marginBottom ? p.$marginBottom : "3px")};
    padding-right: ${(p) => (p.$paddingRight ? p.$paddingRight : "0px")};
  }
`;

export const StyledSwatchContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1rem;
`;

export const StyledSwatchImageContainer = styled.div`
  border-radius: 4px;
  margin: 4px;
  position: relative;
  width: 100px;
  min-width: 100px;
  height: 100px;
  display: flex;
  img {
    width: 100%;
  }
  .close-icon {
    position: absolute;
    z-index: 5;
    top: 4px;
    right: 4px;
    cursor: pointer;
  }
`;

export const StyledCloseButton = styled.div`
  position: relative;
  left: 0.5rem;
  top: 0.2rem;
  cursor: pointer;
`;

export const StyledSwatchDropzone = styled.div`
  height: calc(80% - 2px);
  width: calc(80% - 2px);

  border-radius: 4px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px dashed ${(p) => p.theme.color.primaryFontColor};
  text-align: center;
  font-size: 14px;
  font-family: ${(p) => p.theme.font.primaryFont};
  color: ${(p) =>
    editRgbaAlpha({
      rgbaColor: p.theme.color.primaryFontColor,
      alpha: "0.4",
    })} !important;
  font-weight: 400;
  cursor: pointer;
`;

export const StyledPricebookAddNewInputText = styled(TextField)<{
  $width: string;
  $height?: string;
  $autocomplete?: true;
}>`
  && {
    width: ${(p) => p.$width};
    display: flex;
    background-color: ${(p) => p.theme.color.primaryTextFieldBackgroundColor};
    color: ${(p) => p.theme.color.primaryFontColor};
  }
  & .MuiInputBase-root {
    height: 50px;
  }
  & .MuiInputBase-input {
    padding: 0 !important;
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
  }
  & .MuiOutlinedInput-notchedOutline {
    border: ${(p) => (p.error ? "" : "none !important")};
  }
  input {
    background-color: ${(p) => p.theme.color.primaryTextFieldBackgroundColor};
    color: ${(p) => p.theme.color.primaryFontColor};
    position: ${(p) => (p.$autocomplete ? "relative" : "unset")};
    top: ${(p) => (p.$autocomplete ? "-9px" : "unset")};
    width: ${(p) => p.$width};
    height: 50px;
    outline: none !important;
    margin-left: 20px;
    margin-right: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const StyledPricebookAddNewSelectMenu = styled(Select)<{
  themeColor: DoxleThemeColor;
}>`
  & .MuiSelect-select {
    padding: 0px 20px 0px 20px;
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: ${(p) => p.themeColor.primaryFontColor};
    height: 50px !important;
    background-color: #f7f7f7;
    display: flex;
    align-items: center;
    border: none;
  }
  & .MuiSelect-root.Mui-focused {
    color: #f7f7f7;
    background-color: #f7f7f7;
  }
`;

export const StyledWarningText = styled.div`
  color: red;
  font-size: 12px;
  font-family: ${(p) => p.theme.font.primaryFont};
  font-style: normal;
  font-weight: 400;

  line-height: 110%;
`;

export const StyledPricebookAddNewDropzoneDiv = styled.div`
  border-width: 2px;
  display: flex;
  max-width: 100%;
  height: 130px;
  padding: 5px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #f7f7f7;
  position: relative;
`;

export const StyledPricebookAddNewImageDisplayDiv = styled(motion.div)`
  display: flex;
  flex-direction: row;
  max-width: 100%;
  overflow-x: scroll;
  height: 80px;
  padding: 5px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #f7f7f7;
  position: relative;
  z-index: 1;
`;

export const StyledPricebookAddNewDropzoneText = styled(Typography)`
  && {
    display: flex;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #bdbdbd;
  }
`;

export const StyledPricebookAddNewDropzoneImageContainer = styled(motion.div)`
  display: flex;
  position: relative;
  min-width: 75px;
  max-width: 75px;
  height: 75px;
  margin-right: 10px;
  z-index: 2;
  cursor: pointer;
`;

export const StyledPricebookAddNewSwatches = styled.img<{}>`
  min-width: 75px;
  max-width: 75px;
  height: 75px;
  border-radius: 5px;
`;

export const StyledPricebookAddNewSwatchesDeleteButton = styled(Button)`
  && {
    position: absolute;
    top: 5px;
    left: 100px;
  }

  &.MuiButton-root {
    background-color: white;
    min-width: 20px;
    max-width: 20px;
    height: 20px;
  }
  &.MuiButtonBase-root {
    border-radius: 50% !important;
    color: white;
  }
  &.MuiButton-root:hover {
    background-color: white;
  }
`;

export const StyledPricebookAddNewAnotherSupplierButton = styled(Button)`
  && {
    margin-top: 20px;
    display: flex;
    width: 165px;
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    color: #7070ef;
    letter-spacing: 0.05em;
    text-decoration-line: underline;
  }
  &.MuiButton-root {
    background-color: transparent;
    padding: 0px !important;
  }
  &.MuiButtonBase-root {
    padding: 0px !important;
  }
  &.MuiButton-root:hover {
    background: transparent;
  }
`;

export const StyledSupplierRateLineTextField = styled(TextField)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
  $widthInPercent: number;
  $linked?: boolean;
}>`
  && {
    min-width: 100px;
    width: Calc(${(p) => p.$widthInPercent}% - 3px);
    max-width: 450px;
    height: 3rem;
    border-radius: 0.3rem;
    background-color: ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.$themeColor.primaryReverseContainerColor,
        alpha: "0.1",
      })};
  }
  input {
    padding: 1rem !important;
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.028rem;
    color: ${(p) =>
      p.$linked ? p.$themeColor.doxleColor : p.$themeColor.primaryFontColor};
    border: none;
    &::placeholder {
      font-family: ${(p) => p.$doxleFont.primaryFont};
      font-size: 1.4rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.028rem;
      color: ${(p) =>
        editRgbaAlpha({
          rgbaColor: p.$themeColor.primaryFontColor,
          alpha: "0.8",
        })};
    }
  }
  & .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }
  & .MuiInputBase-root {
    height: 100%;
  }
`;

export const StyledSupplierName = styled.div<{
  $widthInPercent: number;
}>`
  min-width: 100px;
  width: Calc(${(p) => p.$widthInPercent}% - 3px - 1rem);
  max-width: 450px;
  height: 3rem;
  border-radius: 0.3rem;
  font-size: 1.4rem;
  display: flex;
  align-items: center;
  padding-left: 1rem;
  background-color: ${(p) =>
    editRgbaAlpha({
      rgbaColor: p.theme.color.primaryReverseContainerColor,
      alpha: "0.1",
    })};
  color: ${(p) => p.theme.color.doxleColor};
  cursor: pointer;
`;

export const StyledSupplierNameInput = styled.input<{
  $widthInPercent: number;
}>`
  min-width: 100px;
  width: Calc(${(p) => p.$widthInPercent}% - 3px - 1rem);
  max-width: 450px;
  height: 3rem;
  border-radius: 0.3rem;
  border: none;
  outline: none;
  font-size: 1.4rem;
  display: flex;
  align-items: center;
  padding-left: 1rem;
  background-color: ${(p) =>
    editRgbaAlpha({
      rgbaColor: p.theme.color.primaryReverseContainerColor,
      alpha: "0.1",
    })};
  color: ${(p) => p.theme.color.doxleColor};
  cursor: pointer;
`;

export const StyledEditPricebookInputAdornment = styled(InputAdornment)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  && {
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-weight: 500;
    font-size: 1.3rem;
    line-height: normal;
    color: ${(p) => p.$themeColor.primaryFontColor};
    padding: 0px !important;
  }
  & .MuiTypography-root {
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-weight: 500;
    font-size: 1.3rem;
    line-height: normal;
    color: ${(p) => p.$themeColor.primaryFontColor};
  }
`;
interface Coordinates {
  x: number;
  y: number;
}
export const StyledCompanyDropdownListContainer = styled(motion.div)<{
  $themeColor: DoxleThemeColor;
  $coordinates: null | Coordinates;
}>`
  height: 250px;
  background-color: ${(p) => p.$themeColor.primaryContainerColor};
  box-shadow: 1px 4px 4px ${(p) => p.$themeColor.primaryBoxShadowColor};
  width: 33em;
  border-radius: 8px;
  position: fixed;
  left: ${(p) => p.$coordinates?.x ?? 0}px;
  top: ${(p) => p.$coordinates?.y ?? 0}px;
  padding: 14px 0px;
  min-width: 180px;
  min-height: 200px;
  overflow: hidden;
  z-index: 50;
`;
export const StyledCompanyPopperList = styled.ul<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  min-height: 30px;
  cursor: pointer;
  border-radius: 0px 12px 12px 12px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  z-index: 1000;
  background-color: ${(p) => p?.$themeColor?.primaryReverseFontColor};
  padding: 7px 25px 10px;
  &:hover {
    background-color: ${(p) => p?.$themeColor?.primaryTextFieldBackgroundColor};
  }
  @media (max-width: 500px) {
    display: block;
  }
`;
export const StyledCompanyListItem = styled.li<{
  $themeColor?: DoxleThemeColor;
}>`
  min-height: 30px;
  cursor: pointer;
  border-radius: 0px 12px 12px 12px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  background-color: ${(p) => p?.$themeColor?.primaryReverseFontColor};
  padding: 7px 25px 10px;
  &:hover {
    background-color: ${(p) => p?.$themeColor?.primaryTextFieldBackgroundColor};
  }
  @media (max-width: 500px) {
    display: block;
  }
`;
export const StyledBadgeListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 10px 0px;
`;

export const StyledCoreDocketList = styled.div<{}>`
  background-color: ${(p) => p.theme.color.primaryHoverColor};
  color: ${(p) => p.theme.color.primaryFontColor};
  padding: 3px 10px;
  position: relative;
  font-size: 12px;
  font-weight: 500;
  margin-right: 10px;
  margin-top: 0px;
  border-radius: 9px;
  .remove-icon {
    position: absolute;
    top: 0px;
    right: 0px;
    transform: translate(25%, -50%);
  }
  cursor: pointer;
`;
export const StyledDeleteIconButton = styled(IconButton)<{}>`
  && {
    padding: 0 8px;
  }
`;
