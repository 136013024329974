import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { BaseAPIProps } from "../../Models/baseAPIProps";
import { IStageAPIQueryFilter, IStageBudget } from "../../Budgets/Models/stage";
import DoxleAPI from "../DoxleAPI";
import { IApiPaginatedData } from "../../Models/axiosReturn";
import { isAxiosError } from "axios";

interface IRetrieveStageModelProps extends BaseAPIProps {
  filter: IStageAPIQueryFilter;
  enable?: boolean;
}
const useRetrieveStageListQuery = ({
  showNotification,

  company,
  filter,
  enable,
}: IRetrieveStageModelProps) => {
  let stageModelQKey = getStageListQueryKey(filter);

  let stageUrl = `/stage/?page=1`;

  let filterStageParam = getAPIStageParams(filter);

  const stageQuery = useInfiniteQuery({
    queryKey: stageModelQKey,
    queryFn: async ({ pageParam = stageUrl }) =>
      DoxleAPI.get<IApiPaginatedData<IStageBudget>>(pageParam, {
        headers: {
          "User-Company": company!.companyId,
        },
        params: filterStageParam,
      }),
    enabled: company !== undefined && (enable || false),
    getNextPageParam: (prev, pages) => prev.data.next,
    retry: 1,
    staleTime: 15 * 60 * 1000,
    cacheTime: 20 * 60 * 1000,
    refetchOnMount: false,
    refetchInterval: 19 * 60 * 1000,
    refetchOnWindowFocus: false,
    onError: (error) => {
      if (showNotification)
        showNotification(
          isAxiosError(error) && error.response?.status === 403
            ? "Unauthorised actions"
            : "SOMETHING WRONG!",
          "error",
          "Fail to fetch stage model"
        );
    },
  });

  return stageQuery;
};

//# HELPER FUNCTIONS
export const baseStageQKey = ["stage-list"];
export const getStageListQueryKey = (filter: IStageAPIQueryFilter) => {
  const baseKey = [...baseStageQKey];

  for (const [key, value] of Object.entries(filter)) {
    if (value) baseKey.push(`${key}:${value}`);
  }
  return baseKey;
};

const getAPIStageParams = (filter: IStageAPIQueryFilter) => {
  let filterStageParam: any = {
    project: filter.projectId,
  };
  if (filter.pagination) filterStageParam.pagination = filter.pagination;
  if (filter.order_by) filterStageParam.order_by = filter.order_by;
  return filterStageParam;
};
const StageQueryAPI = {
  useRetrieveStageListQuery,
};

export default StageQueryAPI;
