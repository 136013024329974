import React, { useCallback, useEffect, useMemo } from "react";
import useContactPopoverList from "./Hooks/useContactPopoverList";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import { Components, ItemContent, Virtuoso } from "react-virtuoso";

import DoxleEmptyPlaceHolder from "../../../DoxleDesignPattern/DoxleEmptyPlaceHolder/DoxleEmptyPlaceHolder";
import ContactListItem from "./ContactListItem";
import { Contact } from "../../../Models/addressBook";
import Popover from "@mui/material/Popover";

import ListLoadingMore from "../../../Utilities/Lottie/ListLoadingMore";
import {
  StyledContactListContainer,
  StyledSearchContactTextField,
} from "./StyledEmailOrder";

type Props = {
  anchorContactListPopover: HTMLDivElement | null;

  handleCloseContactList: () => void;
  selectedItems?: Contact[];
  onSelectItem: (item: Contact) => void;
  closeOnSelect?: boolean;
};
interface ContactListContext {
  selectedItems?: Contact[];
}
const ContactPopoverList = ({
  anchorContactListPopover,
  handleCloseContactList,
  selectedItems,
  onSelectItem,
  closeOnSelect,
}: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }),
    shallow
  );
  const {
    searchTextQuery,
    setSearchTextQuery,
    contactList,
    isFetchingContactList,
    onAddContactMode,
    setOnAddContactMode,
    fetchNextContactList,
    isFetchingNextContactList,
    shouldShowContactList,
    setShouldShowContactList,
  } = useContactPopoverList({});
  const canBeOpen = Boolean(anchorContactListPopover);
  const id = canBeOpen ? "status-menu-popper" : undefined;
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (anchorContactListPopover) setShouldShowContactList(true);
      else setShouldShowContactList(false);
    }, 300);

    return () => clearTimeout(timeout);
  }, [anchorContactListPopover]);

  //*render list
  const components: Components<Contact, ContactListContext> = useMemo(
    () => ({
      EmptyPlaceholder: () => (
        <DoxleEmptyPlaceHolder
          headTitleText="No contact found!"
          subTitleText=""
          containerBgColor="transparent"
          headTitleTextStyle={{ fontSize: "16px" }}
        />
      ),
    }),
    []
  );

  const itemContent: ItemContent<Contact, ContactListContext> = useCallback(
    (_index, item, context) => {
      return (
        <ContactListItem
          key={`booking_${item}`}
          item={item}
          isSelected={Boolean(
            context.selectedItems &&
              context.selectedItems.find((c) => c.contactId === item.contactId)
          )}
          onSelectItem={(item) => {
            onSelectItem(item);
            if (closeOnSelect) handleCloseContactList();
            setSearchTextQuery("");
          }}
        />
      );
    },
    [onSelectItem, handleCloseContactList, closeOnSelect]
  );
  const contactListAnimatedVariants = {
    entering: {
      y: [-10, 0],
      opacity: [0, 1],
      transition: {
        duration: 0.4,
      },
    },
    exiting: {
      y: [0, -10],
      opacity: [1, 0],
      transition: {
        duration: 0.4,
      },
    },
  };
  const listStyle = {
    flex: 1,
    width: "100%",
  };

  return (
    <Popover
      id={id}
      open={canBeOpen}
      anchorEl={anchorContactListPopover}
      disableScrollLock
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      onClose={handleCloseContactList}
      sx={{
        "& .MuiPopover-paper": {
          backgroundColor: "transparent",
          overflow: "visible",
          boxShadow: "none",
        },
      }}
      elevation={8}
    >
      <StyledContactListContainer
        $themeColor={doxleThemeColor}
        variants={contactListAnimatedVariants}
        animate="entering"
        exit="exiting"
        initial="initial"
        layout
        elevation={4}
      >
        <StyledSearchContactTextField
          $themeColor={doxleThemeColor}
          $doxleFont={doxleFont}
          variant="standard"
          autoFocus
          value={searchTextQuery}
          onChange={(e) => setSearchTextQuery(e.target.value)}
          placeholder="Search contact..."
        />
        {shouldShowContactList && (
          <Virtuoso
            style={listStyle}
            context={{ selectedItems }}
            itemContent={itemContent}
            data={contactList}
            components={components}
            endReached={fetchNextContactList}
          />
        )}

        {isFetchingNextContactList && (
          <ListLoadingMore
            containerStyle={{
              position: "absolute",
              bottom: 0,
              left: 0,
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 10,
            }}
            animationSize={80}
          />
        )}
      </StyledContactListContainer>
    </Popover>
  );
};

export default ContactPopoverList;
