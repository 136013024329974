import React, { useMemo, useState } from "react";
import { useDoxleAuthStore } from "../../../../../DoxleGeneralStore/useDoxleAuthStore";
import { useDoxleCurrentContextStore } from "../../../../../DoxleGeneralStore/useDoxleCurrentContext";
import useDoxleNotificationStore from "../../../../../DoxleGeneralStore/useDoxleNotificationStore";
import { useEditDocketSideScreenStore } from "../../../EditDocketSideScreenStore/useEditDocketSideScreenStore";
import { shallow } from "zustand/shallow";
import DiscussionQueryAPI from "../../../../../Services/QueryHooks/discussionsAPI";
import { Discussion } from "../../../../../Models/discussion";
import { ListRange, VirtuosoHandle } from "react-virtuoso";

type Props = {};

interface CommentDisplaySection {
  discussionList: Discussion[];
  isFetchingDiscussion: boolean;
  isSuccessFetchingDiscussion: boolean;
  isErrorFetchingDiscussion: boolean;
  hasNextPage: boolean;
  fetchNextPage: (isReachedTop: boolean) => void;
  isFetchingNextPage: boolean;
  currentVisibleRange: ListRange;
  setCurrentVisibleRange: React.Dispatch<React.SetStateAction<ListRange>>;
  shouldShowIconScrollToBottom: boolean;
}
const useCommentDisplaySection = ({}: Props): CommentDisplaySection => {
  const [currentVisibleRange, setCurrentVisibleRange] = useState<ListRange>({
    startIndex: 0,
    endIndex: 0,
  });

  const { company } = useDoxleCurrentContextStore((state) => ({
    company: state.currentCompany,
  }));
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification
  );
  const { filterDiscussionList } = useEditDocketSideScreenStore(
    (state) => ({
      filterDiscussionList: state.filterDiscussionList,
    }),
    shallow
  );

  const retrieveDiscussion = DiscussionQueryAPI.useGetDiscussionListQuery({
    showNotification,

    company,
    filter: filterDiscussionList,
    paginated: true,
    pageSize: 25,
  });

  const discussionList: Discussion[] = useMemo(
    () =>
      retrieveDiscussion.isSuccess
        ? retrieveDiscussion.data.pages.reduce((acc, data) => {
            return data.data.results.concat(acc);
          }, [] as Discussion[])
        : [],
    [retrieveDiscussion.data, retrieveDiscussion.isSuccess]
  );

  const shouldShowIconScrollToBottom = useMemo(
    () => currentVisibleRange.endIndex <= discussionList.length - 2,
    [discussionList, currentVisibleRange]
  );

  const fetchNextPage = (isReachedTop: boolean) => {
    if (
      retrieveDiscussion.hasNextPage &&
      isReachedTop &&
      !retrieveDiscussion.isLoading &&
      !retrieveDiscussion.isFetchingNextPage
    ) {
      console.log("TRIGGER FETCH NEXT");
      retrieveDiscussion.fetchNextPage();
    }
  };
  return {
    discussionList,
    isFetchingDiscussion: retrieveDiscussion.isLoading,
    isSuccessFetchingDiscussion: retrieveDiscussion.isSuccess,
    isErrorFetchingDiscussion: retrieveDiscussion.isError,
    hasNextPage: Boolean(retrieveDiscussion.hasNextPage),
    fetchNextPage,
    isFetchingNextPage: retrieveDiscussion.isFetchingNextPage,
    currentVisibleRange,
    setCurrentVisibleRange,
    shouldShowIconScrollToBottom,
  };
};

export default useCommentDisplaySection;
