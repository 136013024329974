import React, { useCallback, useEffect, useMemo } from "react";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import Popover, { PopoverProps } from "@mui/material/Popover";
import ListLoadingMore from "../../Utilities/Lottie/ListLoadingMore";
import { motion } from "framer-motion";
import { Components, ItemContent, Virtuoso } from "react-virtuoso";
import DoxleEmptyPlaceHolder from "../DoxleEmptyPlaceHolder/DoxleEmptyPlaceHolder";
import { Error404Banner } from "../DoxleIcons/CommonIcons";
import { ScopeOfWorkGroup } from "../../ScopeOfWorks/Model/scopeOfWorks";
import {
  StyledSearchTaskTextfield,
  StyledSearchTaskWrapper,
  StyledTaskListScroller,
  StyledTaskPopover,
} from "./StyledDoxleSelectTaskDropdown";
import TaskItem from "./TaskItem";
import useDoxleSelectTaskDropdown from "./Hooks/useDoxleSelectTaskDropdown";

type Props = {
  popoverAnchor: HTMLElement | null;
  onClosePopover: () => void;
  onSelectTask?: (item: ScopeOfWorkGroup) => void;
  onRemoveTask?: (item: ScopeOfWorkGroup) => void;
  selectedTaskItemIds?: string[];
  popoverProps?: PopoverProps;
  closeOnSelect?: boolean;
  containerStyle?: React.CSSProperties;
};

interface TaskListContext {
  isFetchingTaskList: boolean;
  isErrorFetchingTaskList: boolean;
}

const DoxleSelectTaskDropdown = ({
  popoverAnchor,
  onClosePopover,
  onSelectTask,
  popoverProps,
  selectedTaskItemIds,
  closeOnSelect = true,
  containerStyle,
  onRemoveTask,
}: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }),
    shallow
  );

  const {
    searchText,
    setSearchText,
    taskList,
    isFetchingTaskList,
    isErrorFetchingTaskList,
    handleFetchNextPage,
    isFetchingNextPageTaskList,
    handleSelectTask,
  } = useDoxleSelectTaskDropdown({
    onClosePopover,
    onSelectTask,
    closeOnSelect,
  });
  const canBeOpen = Boolean(popoverAnchor);

  //* render list
  const components: Components<ScopeOfWorkGroup, TaskListContext> = useMemo(
    () => ({
      Scroller: React.forwardRef((props, ref) => (
        <StyledTaskListScroller
          ref={ref}
          {...props}
          animate={{ y: [10, 0], opacity: [0, 1] }}
        />
      )),
      EmptyPlaceholder: (props) => (
        <DoxleEmptyPlaceHolder
          headTitleText={
            props.context?.isErrorFetchingTaskList
              ? "Something wrong!"
              : `No Task found!`
          }
          subTitleText={
            props.context?.isErrorFetchingTaskList
              ? "Failed to get Tasks, we are sorry for this!"
              : "Add more Tasks to your list..."
          }
          containerBgColor="transparent"
          headTitleTextStyle={{
            fontSize: "20px",
          }}
          subTitleTextStyle={{
            fontSize: "14px",
          }}
          illustration={
            props.context?.isErrorFetchingTaskList ? (
              <Error404Banner
                themeColor={doxleThemeColor}
                containerStyle={{
                  width: "50%",
                  marginBottom: 14,
                }}
              />
            ) : (
              <></>
            )
          }
        />
      ),
    }),
    [doxleThemeColor]
  );

  const itemContent: ItemContent<ScopeOfWorkGroup, TaskListContext> =
    useCallback(
      (_index, item, context) => {
        return (
          <TaskItem
            key={`task_${item.scopeGroupId}`}
            task={item}
            selected={Boolean(
              selectedTaskItemIds &&
                selectedTaskItemIds.includes(item.scopeGroupId!)
            )}
            onSelect={handleSelectTask}
            onRemoveTask={onRemoveTask}
          />
        );
      },
      [selectedTaskItemIds, handleSelectTask, onRemoveTask]
    );

  const listStyle = {
    flex: 1,
    width: "100%",
  };
  return (
    <Popover
      open={canBeOpen}
      anchorEl={popoverAnchor}
      disableScrollLock
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      onClose={() => {
        setSearchText("");
        onClosePopover();
      }}
      sx={{
        "& .MuiPopover-paper": {
          backgroundColor: "transparent",
          overflow: "visible",
          boxShadow: "none",
        },
      }}
      transitionDuration={{ appear: 100, exit: 200 }}
      elevation={4}
      {...popoverProps}
    >
      <StyledTaskPopover style={containerStyle}>
        <StyledSearchTaskWrapper>
          <StyledSearchTaskTextfield
            variant="standard"
            value={searchText}
            autoFocus
            onChange={(e) => setSearchText(e.target.value)}
            placeholder="Search Task..."
          />
        </StyledSearchTaskWrapper>

        <Virtuoso
          style={listStyle}
          itemContent={itemContent}
          data={taskList}
          components={components}
          context={{
            isErrorFetchingTaskList,
            isFetchingTaskList,
          }}
          endReached={handleFetchNextPage}
          atBottomThreshold={40}
        />

        {isFetchingNextPageTaskList && (
          <ListLoadingMore
            animationSize={50}
            containerStyle={{
              bottom: 0,
              left: "50%",
              translate: "-50%",
              display: "flex",
              justifyContent: "center",
              position: "absolute",
              zIndex: 10,
            }}
          />
        )}
      </StyledTaskPopover>
    </Popover>
  );
};

export default DoxleSelectTaskDropdown;
