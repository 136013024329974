import React, { useCallback, useMemo } from "react";
import {
  StyledListScroller,
  StyledScopeImageGallery,
  StyledScopeImgContainer,
  StyledScopeImgListContainer,
} from "./StyledComponentScopeImageGallery";

import { shallow } from "zustand/shallow";
import { AnimatePresence } from "framer-motion";

import { GridComponents, GridItemContent, VirtuosoGrid } from "react-virtuoso";
import ScopeImageGalleryItem from "./ScopeImageGalleryItem";
import DeleteScopeImageGalleryItem from "./DeleteScopeImageGalleryItem";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { useScopeOfWorkStore } from "../../Store/useScopeOfWorkStore";
import DoxleIconButton from "../../../DoxleDesignPattern/DoxleButtons/DoxleIconButton";
import { CloseGalleryIcon } from "../ScopeOfWorkFullScreenIcons";
import { ScopeOfWorkImage } from "../../Model/scopeOfWorks";

type Props = {};

interface ScopeImgListContext {}
const ScopeImageGallery = (props: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore((state) => ({
    doxleThemeColor: state.doxleThemeColor,
    doxleFont: state.doxleFont,
  }));

  const { viewedScopeImagedList, setViewedScopeImagedList } =
    useScopeOfWorkStore(
      (state) => ({
        viewedScopeImagedList: state.viewedScopeImagedList,
        setViewedScopeImagedList: state.setViewedScopeImagedList,
      }),
      shallow
    );

  const handleCloseScopeImgGallery = () => {
    setViewedScopeImagedList(undefined);
  };

  //* animation props
  const galleryContainerAnimatedVariants = {
    entering: {
      y: [200, 0],
      opacity: [0, 1],
      transition: {
        duration: 0.1,
      },
    },
    exiting: {
      y: 200,
      opacity: 0,
      transition: {
        duration: 0.2,
      },
    },
  };
  //*render list
  const components: GridComponents<ScopeImgListContext> = useMemo(
    () => ({
      Item: (props) => <StyledScopeImgContainer layout {...props} />,
      List: React.forwardRef((props, ref) => (
        <StyledScopeImgListContainer {...props} ref={ref} layout />
      )),
      Scroller: React.forwardRef((props, ref) => (
        <StyledListScroller ref={ref} {...props} />
      )),
    }),
    []
  );

  const itemContent: GridItemContent<ScopeOfWorkImage, ScopeImgListContext> =
    useCallback(
      (index, item) => (
        <ScopeImageGalleryItem
          scopeImgItem={item}
          key={`scopeItem#${item.imageId}`}
        />
      ),
      []
    );

  const gridListStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
  return (
    <>
      <AnimatePresence>
        {viewedScopeImagedList && (
          <StyledScopeImageGallery
            $themeColor={doxleThemeColor}
            variants={galleryContainerAnimatedVariants}
            initial={false}
            animate="entering"
            exit="exiting"
          >
            <div className="gallery-content-wrapper">
              <DoxleIconButton
                iconSource={<CloseGalleryIcon {...doxleThemeColor} />}
                style={{ position: "absolute", top: 4, right: 4, zIndex: 200 }}
                buttonSize={40}
                disableAnimation
                onClick={handleCloseScopeImgGallery}
              />

              <VirtuosoGrid
                style={gridListStyle}
                data={viewedScopeImagedList}
                components={components}
                itemContent={itemContent}
              />
            </div>
          </StyledScopeImageGallery>
        )}
      </AnimatePresence>

      <DeleteScopeImageGalleryItem />
    </>
  );
};

export default ScopeImageGallery;
