import { useMemo, useState } from "react";
import { DoxleFile } from "../../Models/files";
import { checkSupportedFileType } from "../../Utilities/MimeFileType";
import { useIsMutating } from "@tanstack/react-query";
import {
  IDeleteFileQueryParams,
  getFileMutateKey,
} from "../QueryHooks/FileStorageQueryAPI";
import { useDoxleImageGalleryStore } from "../../DoxleDesignPattern/DoxleGallery/Store/useDoxleImageGalleryStore";
import { useShallow } from "zustand/react/shallow";

type Props = { fileItem: DoxleFile };

const useFilesRowLeft = ({ fileItem }: Props) => {
  const [onFileRowHover, setOnFileRowHover] = useState<boolean>(false);

  const { setImageList, setVideoUrl } = useDoxleImageGalleryStore(
    useShallow((state) => ({
      setImageList: state.setImageList,

      setVideoUrl: state.setVideoUrl,
    }))
  );
  const openFile = () => {
    if (fileItem.fileType.toLowerCase().includes("image") && fileItem.testUrl)
      setImageList([{ uri: fileItem.testUrl, name: fileItem.fileName }]);
    else if (fileItem.fileType.toLowerCase().includes("video") && fileItem.url)
      setVideoUrl(fileItem.url);
    else window.open(fileItem.testUrl);
  };

  const supportedFileType = useMemo(() => {
    return checkSupportedFileType({ type: fileItem.fileType });
  }, [fileItem.fileType]);

  const isDeletingFile =
    useIsMutating({
      mutationKey: getFileMutateKey("delete"),
      predicate: (query) =>
        Boolean(
          (query.state.variables as IDeleteFileQueryParams).files.find(
            (file) => fileItem.fileId === file.fileId
          )
        ),
    }) > 0;
  return {
    onFileRowHover,
    setOnFileRowHover,
    openFile,
    supportedFileType,
    isDeletingFile,
  };
};

export default useFilesRowLeft;
