import React from "react";
import {
  StyledLoginModeButton,
  StyledMagicLinkLandingPageContainer,
  StyledStyledMagicLinkLandingPageImg,
} from "./StyledComponentDoxleLoginScreen";

import Img from "../../Assets/Images/LightTheme/loginLinkResponse.jpg";
import InvImg from "../../Assets/Images/InvloginLinkResponse.jpg";
import { useNavigate } from "react-router";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
type Props = {};

const MagicLinkLandingPage = (props: Props) => {
  const { doxleThemeColor, doxleFont, currentTheme } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
      currentTheme: state.currentTheme
    }),
    shallow
  );
  const navigate = useNavigate();
  const handleClickNavigateBack = () => {
    const href: string = window.location.href
    const app: string = href.substring(window.location.protocol.length + 2, href.indexOf('.'))
    const path: string = window.location.pathname ?? '/'
    if (window.location.hostname.toLowerCase().includes('doxle')) {
      window.open(`https://login.doxle.com/?app=${app}&path=${path}`, "_self")
    } else {
      navigate("/Login/?path=${path}");
    }
  };
  return (
    <StyledMagicLinkLandingPageContainer
      $themeColor={doxleThemeColor}
      $doxleFont={doxleFont}
    >
      <StyledStyledMagicLinkLandingPageImg src={currentTheme !== "dark" ? Img : InvImg} />

      <span>A verification link has been sent to your email ...</span>
      <StyledLoginModeButton
        $themeColor={doxleThemeColor}
        $doxleFont={doxleFont}
        sx={{ marginTop: 10 }}
        onClick={handleClickNavigateBack}
      >
        (Back to Login)
      </StyledLoginModeButton>
    </StyledMagicLinkLandingPageContainer>
  );
};

export default MagicLinkLandingPage;
