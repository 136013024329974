import { create } from "zustand";
import {Docket} from "../../Models/dockets";
import {DocketAccountLink} from "../Models/account";
export interface SelectAccountDropDownProps {
  docket: Docket|undefined;
  anchorEl: HTMLDivElement|undefined;
}
interface XeroStore {
  unSavedAccountLinks: DocketAccountLink[];
  setUnsavedAccountLinks: (unSavedAccountLinks: DocketAccountLink[]) => void;
  handleAccountDropDown: (e: React.MouseEvent<HTMLDivElement>, docket: Docket) => void;
  closeAccountDropDown: () => void;
  selectAccountDropDownProps: SelectAccountDropDownProps;
}

export const useXeroStore = create<XeroStore>((set, get) => ({
  unSavedAccountLinks: [],
  setUnsavedAccountLinks: (unSavedAccountLinks: DocketAccountLink[]) => set({unSavedAccountLinks}),
  handleAccountDropDown: (e: React.MouseEvent<HTMLDivElement>, docket: Docket) => set({
    selectAccountDropDownProps: {
      docket,
      anchorEl: e.currentTarget
    }
  }),
  closeAccountDropDown: () => set({
    selectAccountDropDownProps: {
      docket: undefined,
      anchorEl: undefined
    }
  }),
  selectAccountDropDownProps: {
    docket: undefined,
    anchorEl: undefined
  }
}))
