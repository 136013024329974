import { useMemo } from "react";
import { shallow } from "zustand/shallow";
import useDoxleNotificationStore from "../../../DoxleGeneralStore/useDoxleNotificationStore";
import { useDoxleCurrentContextStore } from "../../../DoxleGeneralStore/useDoxleCurrentContext";
import { useBudgetStore } from "../../Store/useBudgetStore";

import DocketQuery from "../../../Services/QueryHooks/docketQueryAPI";
import BudgetAPI from "../../QueryHooks/BudgetAPI";
import { useProjectStore } from "../../../DoxleGeneralStore/useprojectStore";
import { LightDocket } from "../../../Models/dockets";

type Props = {};

interface GetBudgetList {
  budgetList: LightDocket[];
  isFetchingBudgetList: boolean;
  isSuccessFetchingBudgetList: boolean;
  isErrorFetchingBudgetList: boolean;
  hasNextPageBudgetList: boolean;
  handleFetchNextPageBudgetList: () => void;
  isFetchingNextPageBudgetList: boolean;
}
const useGetBudgetList = (props: Props): GetBudgetList => {
  const { filterDocketListQuery } = useBudgetStore((state) => ({
    filterDocketListQuery: state.filterDocketListQuery,
  }));
  const { currentProject, currentCompany, currentSearchText } =
    useDoxleCurrentContextStore(
      (state) => ({
        currentProject: state.currentProject,
        currentCompany: state.currentCompany,
        currentSearchText: state.currentSearchText,
      }),
      shallow
    );

  const { isBudgetProjectLevel } = useBudgetStore(
    (state) => ({
      isBudgetProjectLevel: state.isBudgetProjectLevel,
      setIsBudgetProjectLevel: state.setIsBudgetProjectLevel,
    }),
    shallow
  );

  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );

  const getInfiniteBudgetListQuery =
    DocketQuery.useRetrieveFullDetailDocketList({
      filter: filterDocketListQuery,
      company: currentCompany,
      showNotification,
      enabled: Boolean(
        (isBudgetProjectLevel && currentProject) || !isBudgetProjectLevel
      ),
    });

  const budgetList: LightDocket[] = useMemo(
    () =>
      getInfiniteBudgetListQuery.isSuccess
        ? getInfiniteBudgetListQuery.data.pages
            .flatMap((page) => page.data.results)
            // .sort((a, b) => (a.isSticky ? -1 : 0))
        : [],
    [getInfiniteBudgetListQuery.data]
  );
  const paymentsOnly = useProjectStore(
    (state) => state.accountingBasis === "CASH",
    shallow
  );
  BudgetAPI.useRetrieveProjectProfitAndLoss({
    filter: filterDocketListQuery,
    company: currentCompany,
    showNotification,
    enabled: Boolean(
      filterDocketListQuery.project && getInfiniteBudgetListQuery.isSuccess
    ),
    paymentsOnly,
  });
  const handleFetchNextPageBudgetList = () => {
    if (getInfiniteBudgetListQuery.hasNextPage)
      getInfiniteBudgetListQuery.fetchNextPage();
  };

  return {
    budgetList,
    isFetchingBudgetList: getInfiniteBudgetListQuery.isLoading,
    isSuccessFetchingBudgetList: getInfiniteBudgetListQuery.isSuccess,
    isErrorFetchingBudgetList: getInfiniteBudgetListQuery.isError,
    hasNextPageBudgetList: Boolean(getInfiniteBudgetListQuery.hasNextPage),
    handleFetchNextPageBudgetList,
    isFetchingNextPageBudgetList: getInfiniteBudgetListQuery.isFetchingNextPage,
  };
};

export default useGetBudgetList;
