import EditBookingTitle from "./EditBookingTitle";
import EditBookingDocketAndDate from "./EditBookingDocketAndDate";
import EditBookingPercentage from "./EditBookingPercentage";
type Props = {};

const EditBookingInputSection = ({}: Props) => {
  return (
    <>
      <EditBookingTitle />
      <EditBookingDocketAndDate />
      <EditBookingPercentage />
    </>
  );
};

export default EditBookingInputSection;
