import React, { memo, useEffect, useState } from "react";
import { useOrdersStore } from "../../OrdersStore/useOrdersStore";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import {
  StyledOrderHeaderContainer,
  StyledSearchField,
} from "../OrderPage/StyledComponents";
import OrdersTabMenu from "./OrdersTabMenu";
import { motion } from "framer-motion";
import { AiFillCloseCircle } from "react-icons/ai";
const OrderTabHeader = () => {
  const [searchInputQuery, setSearchInputQuery] = useState("");
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const { setQueryFilter } = useOrdersStore(
    (state) => ({
      setQueryFilter: state.setQueryFilter,
    }),
    shallow
  );
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }),
    shallow
  );

  useEffect(() => {
    const timeout = setTimeout(() => {
      setQueryFilter({ search: searchInputQuery });
    }, 300);
    return () => clearTimeout(timeout);
  }, [searchInputQuery]);

  return (
    <StyledOrderHeaderContainer $themeColor={doxleThemeColor}>
      {/* <StyledOrderHeaderTitle themeColor={themeColor} font={font}>
        {view === "Orders" ? "Purchase Orders" : "Invoices"}
      </StyledOrderHeaderTitle> */}
      {isSearching ? (
        <motion.div
          className="search-wrapper"
          initial={{ x: -5, opacity: 0 }}
          animate={{
            x: 0,
            opacity: 1,
          }}
          transition={{
            duration: 0.2,
          }}
        >
          <StyledSearchField
            $themeColor={doxleThemeColor}
            $doxleFont={doxleFont}
            value={searchInputQuery}
            fullWidth
            placeholder={"Search..."}
            onChange={(event) => setSearchInputQuery(event.target.value)}
          />
          <AiFillCloseCircle
            size="1.8rem"
            style={{ color: "black", cursor: "pointer", marginLeft: "0.4rem" }}
            onClick={() => {
              setIsSearching(false);
              setSearchInputQuery("");
            }}
          />
        </motion.div>
      ) : (
        <OrdersTabMenu setIsSearching={setIsSearching} />
      )}
    </StyledOrderHeaderContainer>
  );
};

export default memo(OrderTabHeader);
