import { QueryKey, UseQueryOptions, useQuery } from "@tanstack/react-query";
import React, { useMemo } from "react";
import DoxleAPI from "../../../Services/DoxleAPI";
import { useDoxleCurrentContextStore } from "../../../DoxleGeneralStore/useDoxleCurrentContext";
import { shallow } from "zustand/shallow";
import { useDoxleAuthStore } from "../../../DoxleGeneralStore/useDoxleAuthStore";
import UserQueryAPI from "../../../Services/QueryHooks/userQueryAPI";
import useDoxleNotificationStore from "../../../DoxleGeneralStore/useDoxleNotificationStore";
import { User } from "../../../Models/user";

type Props = {};
interface GetUserInfo {
  user: User | undefined;
  isFetchingUserInfo: boolean;
  isErrorFetchingUserInfo: boolean;
}
const useGetUserInfo = (): GetUserInfo => {
  const { company } = useDoxleCurrentContextStore(
    (state) => ({
      company: state.currentCompany,
    }),
    shallow
  );
  const { setUser } = useDoxleAuthStore(
    (state) => ({ setUser: state.setUser }),
    shallow
  );
  const sharedPaths = ['/Quote/', '/Shared/', '/SharedProject/', '/SharedOrder/']
  const path = window.location.pathname

  const userInfoQuery = UserQueryAPI.useRetrieveUserInfo({
    company,
    onSuccessCB: (user) => {
      if (user) setUser(user);
    },
    enabled: !sharedPaths.some(str => path.startsWith(str))
  });
  const user = useMemo(
    () => (userInfoQuery.isSuccess ? userInfoQuery.data.data : undefined),
    [userInfoQuery.data]
  );

  return {
    user,
    isFetchingUserInfo: userInfoQuery.isLoading,
    isErrorFetchingUserInfo: userInfoQuery.isError,
  };
};

export default useGetUserInfo;
