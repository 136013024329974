import useBookingViewStore from "../store/useBookingViewStore";
import SMSBooking from "./SMSBooking";
import EmailBooking from "./EmailBookings";

const SendBooking = () => {
  const { sendBooking } = useBookingViewStore();
  if (sendBooking?.type === "sms") return <SMSBooking />;
  else if (sendBooking?.type === "email") return <EmailBooking />;
  return null;
};
export default SendBooking;
