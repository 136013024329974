import React, { ChangeEvent, useState } from "react";
import { IBooking } from "../../Models/booking";
import useBookingCalendarStore from "../../store/useBookingCalendarStore";
import { useShallow } from "zustand/react/shallow";
import useSetBookingQueryData from "../../Hooks/useSetBookingQueryData";
import { useDoxleCurrentContextStore } from "../../../DoxleGeneralStore/useDoxleCurrentContext";
import useDoxleNotificationStore from "../../../DoxleGeneralStore/useDoxleNotificationStore";
import {
  BookingQueryAPI,
  UpdateBookingQueryParams,
  getBookingMutationKey,
} from "../../QueryAPI/bookingQueryAPI";
import { formatTISODateTime } from "../../../Utilities/FunctionUtilities";
import dayjs from "dayjs";
import { useIsMutating } from "@tanstack/react-query";
import useBookingViewStore from "../../store/useBookingViewStore";

type Props = {
  bookingItem: IBooking;
  dateCellValue: Date;
};

const useBookingItem = ({ bookingItem, dateCellValue }: Props) => {
  const setSendBooking = useBookingViewStore(
    useShallow((state) => state.setSendBooking)
  );
  const { filterBookingProjectQuery, setEditedBooking } =
    useBookingCalendarStore(
      useShallow((state) => ({
        filterBookingProjectQuery: state.filterBookingProjectQuery,
        setEditedBooking: state.setEditedBooking,
      }))
    );
  const { handleEditBookingQueryDataWithoutPagination } =
    useSetBookingQueryData({
      filter: filterBookingProjectQuery,
    });
  const { company, currentProject } = useDoxleCurrentContextStore(
    useShallow((state) => ({
      company: state.currentCompany,
      currentProject: state.currentProject,
    }))
  );

  const { showNotification } = useDoxleNotificationStore();
  const { destroy, update } = BookingQueryAPI.useMutateBookingQuery({
    company,
    filter: filterBookingProjectQuery,
    showNotification,
    onSuccessUpdateCb: (data) => {},
  });

  const onClickCheckbox = (e: ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    handleEditBookingQueryDataWithoutPagination({
      ...bookingItem,
      completed: bookingItem.completed ? null : formatTISODateTime(new Date()),
    });
    update.mutate({
      bookingId: bookingItem.bookingId,
      updateData: {
        completed: bookingItem.completed
          ? null
          : formatTISODateTime(new Date()),
      },
    });
  };

  const onClickBookingRow = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    e.stopPropagation();
    setEditedBooking(bookingItem);
  };
  const isBookingMatchStartOrEnd =
    dayjs(dateCellValue).diff(bookingItem.startDate, "day") === 0 ||
    dayjs(dateCellValue).diff(bookingItem.endDate, "day") === 0;

  const isDeletingBooking =
    useIsMutating({
      mutationKey: getBookingMutationKey("delete"),
      predicate: (query) => query.state.variables === bookingItem.bookingId,
    }) > 0;

  const isUpdatingBooking =
    useIsMutating({
      mutationKey: getBookingMutationKey("delete"),
      predicate: (query) =>
        (query.state.variables as UpdateBookingQueryParams).bookingId ===
        bookingItem.bookingId,
    }) > 0;

  const handleSendBookingAlert = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    event.preventDefault();
    if (event.currentTarget.classList.contains("sms"))
      setSendBooking({
        booking: bookingItem,
        type: "sms",
      });
    else if (event.currentTarget.classList.contains("email"))
      setSendBooking({
        booking: bookingItem,
        type: "email",
      });
  };
  return {
    onClickCheckbox,

    isBookingMatchStartOrEnd,
    onClickBookingRow,
    isDeletingBooking,
    isUpdatingBooking,
    handleSendBookingAlert,
  };
};

export default useBookingItem;
