import {
  StyledAccountDropDownContainer,
  StyledAccountDropDownRow,
  StyledIconButton,
  StyledProjectTrackingField,
} from "./styledComponents";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import LinkOffIcon from "@mui/icons-material/LinkOff";
import React, { useMemo } from "react";
import { KeyboardArrowDownOutlined } from "@mui/icons-material";
import { Popover } from "@mui/material";
import { Components, Virtuoso } from "react-virtuoso";
import useProjectTrackingDropdown from "../Hooks/useProjectTrackingDropdown";
import { Account } from "../Models/account";
import { StyledListScroller } from "../../DoxleDesignPattern/ProjectSelectWithDropdown/StyledComponentsProjectSelectWithDropdown";
import DoxleEmptyPlaceHolder from "../../DoxleDesignPattern/DoxleEmptyPlaceHolder/DoxleEmptyPlaceHolder";

const ProjectTrackingDropdown = () => {
  const styleProps = useDoxleThemeStore(
    (state) => ({
      $themeColor: state.doxleThemeColor,
      $doxleFont: state.doxleFont,
      $currentTheme: state.currentTheme,
    }),
    shallow
  );
  const {
    isLinked,
    handleFieldClick,
    project,
    handleIconClick,
    anchorEl,
    setAnchorEl,
    handleSelectTrackingOption,
    trackingOptionList,
  } = useProjectTrackingDropdown();

  return (
    <>
      <StyledProjectTrackingField
        {...styleProps}
        $linked={isLinked}
        onClick={handleFieldClick}
      >
        {project?.trackingName ?? "Select a Tracking Option"}
        <StyledIconButton {...styleProps} onClick={handleIconClick}>
          {isLinked ? <LinkOffIcon /> : <KeyboardArrowDownOutlined />}
        </StyledIconButton>
      </StyledProjectTrackingField>
      <Popover
        id={"tracking-option-dropdown"}
        open={Boolean(project && anchorEl)}
        anchorEl={anchorEl}
        disableScrollLock
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={() => setAnchorEl(null)}
        sx={{
          "& .MuiPopover-paper": {
            backgroundColor: "transparent",
            overflow: "visible",
            boxShadow: "none",
          },
        }}
        transitionDuration={{ appear: 100, exit: 200 }}
        elevation={8}
      >
        <StyledAccountDropDownContainer {...styleProps}>
          <StyledAccountDropDownRow
            {...styleProps}
            key={"null-account"}
            onClick={() => handleSelectTrackingOption(null)}
          >
            Do Not Link
          </StyledAccountDropDownRow>
          <Virtuoso
            style={{ height: "100%", width: "100%" }}
            data={trackingOptionList}
            itemContent={(index, option) => {
              return (
                <StyledAccountDropDownRow
                  {...styleProps}
                  key={option.trackingOptionId}
                  onClick={() => handleSelectTrackingOption(option)}
                >
                  {option.trackingOptionName}
                </StyledAccountDropDownRow>
              );
            }}
            context={{ anchorEl, trackingOptionList }}
          />
        </StyledAccountDropDownContainer>
      </Popover>
    </>
  );
};
export default ProjectTrackingDropdown;
