import React, { useCallback, useState } from "react";
import { shallow } from "zustand/shallow";
import { useEditDocketSideScreenStore } from "../../EditDocketSideScreenStore/useEditDocketSideScreenStore";
import { FilterGetProjectQuery } from "../../../../Services/QueryHooks/projectQueryAPI";
import { useDoxleThemeStore } from "../../../../DoxleGeneralStore/useDoxleThemeStore";
import { AnimatePresence } from "framer-motion";
import AddProjectTextField from "./AddProjectTextField";
import { StyledAddProjectDocketContainer } from "../../StyledComponentsEditDocketSideScreen";
import { AddProjectIcon } from "../../EditDocketSideScreenIcons";

type Props = { filterProjectListQuery: FilterGetProjectQuery };

const AddProjectDocket = ({ filterProjectListQuery }: Props) => {
  const [showAddProjectTextField, setShowAddProjectTextField] =
    useState<boolean>(false);
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }),
    shallow
  );

  const { edittedDocket } = useEditDocketSideScreenStore(
    (state) => ({ edittedDocket: state.edittedDocket }),
    shallow
  );

  const handleToggleAddProjectTextField = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      setShowAddProjectTextField(true);
    },
    []
  );
  const handleCloseProjectTextField = () => {
    setShowAddProjectTextField(false);
  };

  const rootContainerAnimatedVariants = {
    initial: {
      scaleX: 1,
      opacity: 1,
    },
    hovering: {
      opacity: 0.9,
      transition: { duration: 0.4 },
    },
    tapping: {
      scaleX: 1.1,
      transition: { duration: 0.4 },
    },
  };

  return (
    <>
      <StyledAddProjectDocketContainer
        $themeColor={doxleThemeColor}
        $doxleFont={doxleFont}
        variants={rootContainerAnimatedVariants}
        initial="initial"
        whileHover="hovering"
        whileTap="tapping"
        onClick={handleToggleAddProjectTextField}
      >
        <AddProjectIcon {...doxleThemeColor} />
        <span>Add Project</span>
      </StyledAddProjectDocketContainer>

      <AnimatePresence>
        {showAddProjectTextField && (
          <AddProjectTextField
            hideProjectTextFieldFunction={handleCloseProjectTextField}
            filterProjectListQuery={filterProjectListQuery}
          />
        )}
      </AnimatePresence>
    </>
  );
};

export default AddProjectDocket;
