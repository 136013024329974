import React from "react";
import {
  StyledAddQAListTextField,
  StyledQAListFooterContainer,
} from "./StyledComponentQAProjectList";
import { useDoxleThemeStore } from "../../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import TableCell from "@mui/material/TableCell";
import useQAListFooter from "./Hooks/useQAListFooter";
import InputAdornment from "@mui/material/InputAdornment";
import { FiPlus } from "react-icons/fi";
import CircularProgress from "@mui/material/CircularProgress";
type Props = {
  newQAListInputRef: React.RefObject<HTMLInputElement>;
  scrollToBottom: () => void;
};

const QAListFooter = ({ newQAListInputRef, scrollToBottom }: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }),
    shallow
  );

  const { newQAListTitle, setnewQAListTitle, handleAddQAList, isAddingQAList } =
    useQAListFooter({ scrollToBottom });
  return (
    <StyledQAListFooterContainer $themeColor={doxleThemeColor}>
      <TableCell
        sx={{
          padding: "0px !important",
          border: "none !important",
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
        }}
      >
        <StyledAddQAListTextField
          inputRef={newQAListInputRef}
          $themeColor={doxleThemeColor}
          $doxleFont={doxleFont}
          placeholder="New QA List..."
          value={newQAListTitle}
          onChange={(e) => setnewQAListTitle(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" style={{ marginRight: 8 }}>
                {isAddingQAList ? (
                  <CircularProgress
                    style={{ color: doxleThemeColor.primaryFontColor }}
                    size="1.2rem"
                  />
                ) : (
                  <FiPlus
                    color={doxleThemeColor.primaryInputPlaceholderColor}
                    size="1.6rem"
                    onClick={() => handleAddQAList()}
                  />
                )}
              </InputAdornment>
            ),
          }}
          onFocus={(e) => e.target.select()}
          onKeyDown={(e) => {
            if (e.nativeEvent.key === "Enter") {
              e.preventDefault();
              handleAddQAList();
              newQAListInputRef.current?.blur();
            } else if (e.nativeEvent.key === "Escape") {
              setnewQAListTitle("");
            }
          }}
        />
      </TableCell>
    </StyledQAListFooterContainer>
  );
};

export default QAListFooter;
