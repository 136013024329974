import {XY} from "../Models/takeOffs";
import {useMeasurementsStore} from "../MeasurementsStore/useMeasurementsStore";
import {shallow} from "zustand/shallow";
import {nanoid} from "nanoid";

interface RectangleProps {}
const useEachPicker = ({}: RectangleProps) => {
  const {
    currentShape, setCurrentShape, deduction, currentColor, currentUnit, zHeight, currentSheet, currentTakeOff, addMeasurement
  } = useMeasurementsStore(state => ({
    currentShape: state.currentShape,
    setCurrentShape: state.setCurrentShape,
    deduction: state.deduction,
    currentColor: state.currentColor,
    currentUnit: state.currentUnit,
    zHeight: state.zHeight,
    currentSheet: state.currentSheet,
    currentTakeOff: state.currentTakeOff,
    addMeasurement: state.addMeasurement
  }), shallow)
  const mouseUp = (pos: XY) => {
    if (!currentSheet?.sheetId) return;
    addMeasurement({
      borderColor: deduction < 0 ? "#000" : currentColor,
      bgColor: deduction < 0 ? "#000" : currentColor,
      borderThickness: 2,
      shape: "circle",
      id: nanoid(),
      rawValue: deduction,
      scaledValue: deduction,
      zMultiplier: zHeight ? zHeight : 1.0,
      actualValue: deduction,
      sheet: currentSheet?.sheetId,
      takeOff: currentTakeOff?.takeOffId,
      start: { x: pos.x, y: pos.y },
      radius: 5.0,
    });
  }

  return {mouseUp}
}

export default useEachPicker
