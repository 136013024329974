import React, { forwardRef, useCallback } from "react";
import { useSnackbar, SnackbarContent, CustomContentProps } from "notistack";
import { makeStyles } from "@mui/styles";
import {
  DownloadResourceNotificationContainer,
  RootTextOnlyNotificationContainer,
  StyledExtraDesceiptionText,
  StyledMessageContainer,
  StyledMessageContent,
  StyledMessageTitle,
  StyledProgressContainer,
} from "./StyledComponentNotification";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import CircularProgress from "@mui/material/CircularProgress";
import { shallow } from "zustand/shallow";
interface IDownloadResourceNotification extends CustomContentProps {
  extraContent?: string;
  status: "success" | "error" | "loading";
}

const DownloadResourceNotification = forwardRef<
  HTMLDivElement,
  IDownloadResourceNotification
>(({ id, extraContent, message, status, ...props }, ref) => {
  const classes = useStyles();
  const SUCCESS_BACKGROUND_COLOR: string = "#4caf50";
  const ERROR_BACKGROUND_COLOR: string = "#ff5252";
  const LOADING_BACKGROUND_COLOR: string = "#5252FF";

  const { closeSnackbar } = useSnackbar();
  const { doxleThemeColor, doxleFont, currentTheme } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
      currentTheme: state.currentTheme,
    }),
    shallow
  );

  const handleDismiss = useCallback(() => {
    closeSnackbar(id);
  }, [id, closeSnackbar]);
  return (
    <DownloadResourceNotificationContainer
      {...props}
      $themeColor={doxleThemeColor}
      $doxleFont={doxleFont}
      id={"ProjectDownloadNotification"}
      ref={ref}
      className={classes.root}
      $backgroundColor={
        status === "success"
          ? SUCCESS_BACKGROUND_COLOR
          : status === "loading"
          ? LOADING_BACKGROUND_COLOR
          : ERROR_BACKGROUND_COLOR
      }
      onClick={handleDismiss}
    >
      {status === "loading" && (
        <StyledProgressContainer>
          <CircularProgress />
        </StyledProgressContainer>
      )}
      <StyledMessageContainer
        $themeColor={doxleThemeColor}
        $doxleFont={doxleFont}
      >
        <StyledMessageTitle
          $themeColor={doxleThemeColor}
          $doxleFont={doxleFont}
        >
          {message}
        </StyledMessageTitle>
        {extraContent && (
          <StyledMessageContent
            $themeColor={doxleThemeColor}
            $doxleFont={doxleFont}
          >
            {extraContent}
          </StyledMessageContent>
        )}
      </StyledMessageContainer>
    </DownloadResourceNotificationContainer>
  );
});

// @ts-ignore
DownloadResourceNotification.displayName = "DownloadResourceNotification";
export default DownloadResourceNotification;
const useStyles = makeStyles(() => ({
  root: {
    "@media (min-width:600px)": {
      minWidth: "344px !important",
    },
  },
}));
