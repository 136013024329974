import {StyledAttachmentContainer} from "./styledCompnents";
interface Props {
  file: File
  handleRemoveFile: (index: number)=> void;
  index: number
}
const NewMailAttachment = ({file, handleRemoveFile, index}:Props) => {
  return <StyledAttachmentContainer onClick={()=>handleRemoveFile(index)}>
    <img height={20}
      src={
        file.type.toLowerCase() === "application/pdf"
          ? "/assets/icons/adobe-pdf-icon.svg"
          : file.type.toLowerCase() === "image/jpeg"
          ? "/assets/icons/jpeg.png"
          : file.type.toLowerCase() === "image/png"
            ? "/assets/icons/png.png"
            : file.type.includes('word')
              ? "/assets/icons/word.png"
              : file.type.includes('excel')
                ? "/assets/icons/excel.png"
                : "/assets/icons/file.png"
      }
      style={{
        marginTop: "-4px",
        marginLeft: "4px",
        marginRight: "4px",
        marginBottom: "6px",
      }}
    />
    {file.name.length <= 50 ? file.name : file.name.substring(0,45)+"..."}
  </StyledAttachmentContainer>
}
export default NewMailAttachment
