import React, { useCallback, useEffect, useMemo, useState } from "react";
import InventoryQueryAPI from "./QueryAPIHooks/InventoryQueryAPI";
import { InventoryItem } from "./Models/Inventory";
import { useDoxleThemeStore } from "../DoxleGeneralStore/useDoxleThemeStore";
import {
  StyledInventoryList,
  StyledInventoryListScroller,
  StyledInventoryRootContainer,
  StyledInventoryScreenContainer,
} from "./StyledInventoryScreen";
import { Components, Virtuoso } from "react-virtuoso";
import { AnimatePresence } from "framer-motion";
import DoxleEmptyPlaceHolder from "../DoxleDesignPattern/DoxleEmptyPlaceHolder/DoxleEmptyPlaceHolder";
import SingleInventory from "./SingleInventory";
import DoxleListSkeleton from "../DoxleDesignPattern/DoxleSkeleton/DoxleListSkeleton";
import EditInventoryModal from "./EditInventoryScreen/EditInventoryScreen";
import AddInventoryScreen from "./AddInventoryScreen/AddInventoryScreen";
import InventoryDeleteConfirmDialog from "./EditInventoryScreen/DrawingDeleteConfirmDialog";
import { EmptyBoxIcon, Error404Icon } from "./InventoryPageIcons";
import { Error404Banner } from "../DoxleDesignPattern/DoxleIcons/CommonIcons";
import useDoxleNotificationStore from "../DoxleGeneralStore/useDoxleNotificationStore";
import { shallow } from "zustand/shallow";
import EditInventoryScreen from "./EditInventoryScreen/EditInventoryScreen";
import { useInventoryStore } from "./Store/useInventoryStore";
import InventoryImagePreview from "./InventoryImagePreview";
import { useDoxleCurrentContextStore } from "../DoxleGeneralStore/useDoxleCurrentContext";
import {Helmet} from "react-helmet";

type Props = {};

const InventoryScreen = (props: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);

  const { doxleThemeColor, doxleFont } = useDoxleThemeStore((state) => ({
    doxleThemeColor: state.doxleThemeColor,
    doxleFont: state.doxleFont,
  }));

  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );

  const { filterInventoryQuery, setFilterInventoryQuery } = useInventoryStore(
    (state) => ({
      filterInventoryQuery: state.filterInventoryQuery,
      setFilterInventoryQuery: state.setFilterInventoryQuery,
    })
  );

  const { currentSearchText } = useDoxleCurrentContextStore(
    (state) => ({
      currentSearchText: state.currentSearchText,
    }),
    shallow
  );

  const inventoryQuery = InventoryQueryAPI.useGetInventoryQuery({
    search: currentSearchText,
    showNotification,
  });

  const { previewImageInventory } = useInventoryStore(
    (state) => ({
      previewImageInventory: state.previewImageInventory,
    }),
    shallow
  );

  const inventoryList: InventoryItem[] = useMemo(() => {
    let inventoryList: InventoryItem[] = [];
    if (inventoryQuery.isSuccess && inventoryQuery.data?.pages)
      inventoryList = inventoryQuery.data.pages.reduce((acc, dataPage) => {
        return acc.concat(dataPage.data.results);
      }, [] as InventoryItem[]);
    return inventoryList;
  }, [inventoryQuery.data]);

  const handleEndReached = () => {
    if (inventoryQuery?.hasNextPage) inventoryQuery.fetchNextPage();
  };

  const itemContent = useCallback(
    (index: number, item: InventoryItem) => (
      <SingleInventory
        inventoryItem={item}
        key={item.inventoryId}
        openEditScreen={setAnchorEl}
      />
    ),
    []
  );

  interface InventoryListContext {
    isFetchingNextPage: boolean;
    isErrorFetchingInventory: boolean;
  }

  const components: Components<InventoryItem, InventoryListContext> = useMemo(
    () => ({
      Scroller: React.forwardRef(({ ...props }, ref) => (
        <StyledInventoryListScroller
          style={{
            ...props.style,
          }}
          ref={ref}
          {...props}
        />
      )),
      EmptyPlaceholder: (props) => (
        <DoxleEmptyPlaceHolder
          headTitleText={
            props.context?.isErrorFetchingInventory
              ? "Something wrong"
              : "No inventories"
          }
          subTitleText={
            props.context?.isErrorFetchingInventory
              ? "Failed to get inventories..."
              : "Create a new one or remove your filters"
          }
          containerBgColor="transparent"
          headTitleTextStyle={{ fontSize: "3rem" }}
          illustration={
            props.context?.isErrorFetchingInventory ? (
              <Error404Banner
                themeColor={doxleThemeColor}
                containerStyle={{
                  width: "40%",
                  maxWidth: 600,
                  marginBottom: 14,
                }}
              />
            ) : (
              <EmptyBoxIcon
                themeColor={doxleThemeColor}
                containerStyle={{
                  marginBottom: 14,
                  maxWidth: 600,
                  width: "50%",
                }}
              />
            )
          }
        />
      ),
    }),
    []
  );

  useEffect(() => {
    localStorage.setItem("lastUsedApp", "Inventory");
  }, []);

  return (
    <StyledInventoryRootContainer $doxleFont={doxleFont}>
      <Helmet>
        <title>Doxle - Inventory</title>
      </Helmet>
      <StyledInventoryScreenContainer>
        <EditInventoryScreen anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
        <AddInventoryScreen />
        <InventoryDeleteConfirmDialog />
        {previewImageInventory && <InventoryImagePreview />}
        {inventoryQuery.isLoading ? (
          <DoxleListSkeleton
            containerWidthInRatio={"100%"}
            containerHeightInRatio={"100%"}
            numOfRows={3}
            skeletonType={"inventoryRow"}
          />
        ) : (
          <Virtuoso
            style={{
              width: "100%",
              height: "100%",
            }}
            endReached={handleEndReached}
            data={inventoryList}
            itemContent={itemContent}
            context={{
              isFetchingNextPage: inventoryQuery.isFetchingNextPage,
              isErrorFetchingInventory: inventoryQuery.isError,
            }}
            components={components}
          />
        )}
      </StyledInventoryScreenContainer>
    </StyledInventoryRootContainer>
  );
};

export default InventoryScreen;
