import styled from "styled-components";
import { motion } from "framer-motion";
import {
  DoxleFont,
  DoxleThemeColor,
} from "../../DoxleGeneralStore/useDoxleThemeStore";
import { editRgbaAlpha, TRgbaFormat } from "../../Utilities/FunctionUtilities";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TextField from "@mui/material/TextField";
import TableHead from "@mui/material/TableHead";
import Button from "@mui/material/Button";
import LinearProgress from "@mui/material/LinearProgress";
import TableBody from "@mui/material/TableBody";
import Table from "@mui/material/Table";
import { Popover, TableFooter } from "@mui/material";

export const StyledBodyContainer = styled.div<{
  $currentLevel?: "Company" | "Project" | "Docket";
}>`
  display: flex;
  flex: 1;
  width: 100%;
  ${(p) => (p.$currentLevel === "Project" ? `margin-top: -2rem;` : ``)};
  flex-direction: row;

  ${(p) =>
    p.$currentLevel === "Company"
      ? `
    border-top-width: 1.5px;
    border-top-style: solid;
    border-top-color: ${p.theme.color.primaryDividerColor};
  `
      : ``};
`;

export const StyledBodyLeftSection = styled(motion.div)<{
  $topPadding?: boolean;
}>`
  padding-top: ${(p) => (p.$topPadding ? "max(3rem, 27px)" : 0)};
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
  width: calc(25% - 16px);
  height: calc(100% - ${(p) => (p.$topPadding ? "max(3rem, 27px)" : 0)});
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  z-index: 100;
  min-width: 180px;
  background-color: ${(p) => p.theme.color.primaryContainerColor};
`;

export const StyledBodyRightSection = styled.div<{
  $themeColor: DoxleThemeColor;
  $sidePeek?: boolean;
  $sidePeekWidth?: `${number}vw`;
  $topPadding?: boolean;
}>`
  position: relative;
  padding-top: ${(p) => (p.$topPadding ? "max(3rem, 27px)" : "0px")};
  background-color: ${(p) => p.$themeColor.primaryBackgroundColor};
  display: flex;
  height: calc(100% - ${(p) => (p.$topPadding ? "max(3rem, 27px)" : "0px")});
  width: ${(p) => (p.$sidePeek ? `calc(${p.$sidePeekWidth} * 0.75)` : "100%")};
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .empty-text {
    color: ${(p) => p.$themeColor.primaryFontColor};

    font-family: IBM Plex Mono;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.48px;
  }
`;

export const StyledFileFolderTableRow = styled(TableRow)<{
  $themeColor: DoxleThemeColor;
}>`
  &.MuiTableRow-root {
    min-width: 0px !important;
    display: flex;
    flex-direction: column;
  }
`;

export const StyledFileTableRowSection = styled(TableRow)<{
  $themeColor: DoxleThemeColor;
}>`
  && {
    margin: 0px !important;
    padding: 0px !important;
    display: flex;
    flex-direction: row;
    min-height: 1px !important;
  }
`;

export const StyledFileFolderTableRowSectionCell = styled(motion(TableCell))<{
  $selected?: boolean;
  $halfBorder?: boolean;
}>`
  && {
    position: relative;
    align-items: center;
    min-width: 0px !important;

    height: 40px;
    width: calc(100% - 13px) !important;
    overflow: hidden;
    padding: 0px 0px 0px 13px !important;

    display: flex;
    flex-direction: row;

    border-bottom: none !important;
  }
  .row-content-wrapper {
    display: flex;
    flex-direction: row;
    width: calc(100% - 10px);
    position: relative;

    height: 100%;
    align-items: center;
    border-radius: 0.5rem;
    padding: 0 5px;
    background-color: ${(p) =>
      p.$selected
        ? editRgbaAlpha({ rgbaColor: p.theme.color.doxleColor, alpha: "0.1" })
        : "transparent"};
    transition: all 0.4s ease;
  }

  &.MuiTableCell-root:before {
    content: "";
    position: absolute;
    left: 0px;
    ${(p) =>
      p.$halfBorder ? `height: 50%; bottom: 50%;` : `height: 100%; bottom: 0;`}
    border-left: 1px solid ${(p) => p.theme.color.boldDividerColor};
    z-index: 1000;
  }
`;
export const StyledFileRowContentWrapper = styled.div<{
  $themeColor: DoxleThemeColor;
}>`
  display: flex;
  flex-direction: row;
  width: 100%;
  position: relative;
  overflow: hidden;

  height: 100%;
  align-items: center;

  &:hover {
    background-color: ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.$themeColor.hoverColor,
        alpha: "0.2",
      })};
    transition: 0.4s ease;
    font-weight: 500;
  }
`;
export const StyledFolderTreeLine = styled.div<{}>`
  position: absolute;
  top: calc(50% - 0.5px);
  left: 0px;
  height: 1px;
  width: 13px;
  background-color: ${(p) => p.theme.color.boldDividerColor};
  &::after {
    margin-left: 20px;
    margin-top: -100px;
    height: 6px;
    width: 6px;
    border-radius: 3px;
    background-color: ${(p) => p.theme.color.boldDividerColor};
  }
`;

export const StyledFileFolderTableLeftDiv = styled.div<{
  $themeColor: DoxleThemeColor;
}>`
  height: 100%;
  // max-width: 80%;
  width: 100%;
  margin: 0px;
  display: flex;
  flex-direction: row;
  // background-color: transparent;
  align-items: center;
  justify-content: flex-start;
  // cursor: pointer;

  // border-bottom: 1px solid red;
  transition: 0.4s;
  z-index: 0;

  &:hover {
    // background-color: green;
    // opacity: 0.6;
    // transition: 0.4s;
    font-weight: 500;
    cursor: pointer;
    // display: block;
    // flex: 0;
  }
`;

export const StyledImageDiv = styled.div<{}>`
  display: flex;

  align-items: center;

  cursor: pointer;
  height: 100%;
  margin-right: 4px;
  .image-holder {
    margin-right: 4px;
    height: 25px;
    aspect-ratio: 1;
    object-fit: contain;
  }
  .image-grid-holder {
    width: 50%;
    aspect-ratio: 1;
    object-fit: contain;
  }
`;

export const StyledFolderNameField = styled(TextField)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  && {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    border-bottom: none !important;
    pointer-events: none;
    background-color: red;
    width: 100%;
  }
  input {
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-weight: 600;
    font-size: 15px;
    line-height: normal;
    letter-spacing: 0.0075em;
    color: ${(p) => p.$themeColor.primaryFontColor};
    border-bottom: none !important;
  }
  & .MuiInput-input.Mui-disabled {
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-weight: 500;
    font-size: 1.4rem;
    line-height: normal;
    letter-spacing: 0.0075em;
    -webkit-text-fill-color: ${(p) => p.$themeColor.primaryFontColor};
    cursor: pointer;
  }

  & .MuiInputBase-multiline {
    font-weight: 500;
    font-size: 1.4rem;
    line-height: normal;
    letter-spacing: 0.0075em;
    border-bottom: none !important;
    color: ${(p) => p.$themeColor.primaryFontColor};
  }
  & .MuiInput-root:before {
    border-bottom: none !important;
  }
  & .MuiInput-root:after {
    border-bottom: none !important;
  }
  & .MuiInput-root {
    border-bottom: none !important;
  }
  & .MuiInput-root:hover {
    border-bottom: none !important;
  }
  & .MuiInput-root:hover:not(.Mui-disabled, .Mui-error):before {
    border-bottom: none !important;
  }
  & .MuiInputLabel-root.Mui-focused {
    color: ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.$themeColor.primaryFontColor as TRgbaFormat,
        alpha: "0.4",
      })};
    font-size: 1.2rem !important;
  }
  & .MuiInputLabel-root {
    font-weight: 500;
    font-size: 14px;
    line-height: 110%;
    letter-spacing: 0.0075em;
    transition: 0.4s !important;
    disabled: true;
    color: ${(p) => p.$themeColor.primaryInputPlaceholderColor};
  }
`;

export const StyledFileFolderTableRightDiv = styled(motion.div)<{}>`
  display: flex;

  align-items: center;

  z-index: 18;
  margin-right: 4px;
`;

export const StyledLeftFileTableHeader = styled(TableHead)<{
  $themeColor: DoxleThemeColor;
}>`
  && {
    display: flex;
    width: 100%;
    min-width: 0px !important;
    align-items: center;
    font-weight: 500;
    font-size: 18px;

    border-bottom: none !important;
    background-color: ${(p) => p.$themeColor.primaryContainerColor};
  }
`;

export const StyledRightFileTableHeader = styled(TableHead)<{
  $themeColor: DoxleThemeColor;
}>`
  && {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 18px;
    border-bottom: none !important;
  }
`;

export const StyledRightFileTableHeaderRow = styled(TableRow)<{
  $themeColor: DoxleThemeColor;
}>`
  && {
    height: 2.8rem;
    min-height: 29px;
    display: flex;
    width: 100%;
    align-items: center;
  }
`;
export const StyledLeftRootTableRow = styled(TableRow)`
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
`;
export const StyledFileFolderTableHeaderCell = styled(TableCell)<{
  $selected?: boolean;
}>`
  && {
    position: relative;
    align-items: center;
    min-width: 0px !important;
    height: 40px;
    min-height: 36px;
    max-height: 40px;
    width: calc(100% - 13px) !important;
    overflow: hidden;
    padding: 0px 0px 0px 13px !important;
    
    display: flex;
    flex-direction: row;
    border: none !important;
    border-left: 1px solid transparent;
    :before{
      content: '';
      position: absolute;
      bottom: 0px;
      left: calc(-13px - 0px);
      height: 50%;
      border-left: 1px solid ${(p) => p.theme.color.boldDividerColor};
      z-index: 1000;
    }
  }
  .row-content-wrapper {
    display: flex;
    flex-direction: row;
    width: calc(100% - 10px);
    position: relative;
    height: 100%;
    align-items: center;
    border-radius: 5px;
    padding: 0 5px 0 5px;
    background-color: ${(p) =>
      p.$selected
        ? editRgbaAlpha({ rgbaColor: p.theme.color.doxleColor, alpha: "0.2" })
        : "transparent"};
    
  }
  &:hover {
    background-color: ${(p) => p.theme.color.hoverColor};
    transition: 0.4s;
    font-weight: 500;
  }

  &.MuiTableCell-root {
    border-bottom: none !important;
`;

export const StyledRightFileTableHeaderCell = styled(TableCell)<{
  $themeColor: DoxleThemeColor;
}>`
  && {
    padding: 0px 5px !important;
    height: 30px;
    display: flex;
    width: calc(100% - 10px);
    align-items: center;
    font-weight: 500;
    font-size: 18px;
    border: none !important;
  }
`;

export const StyledFileNameTableHeaderDiv = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  min-width: calc(30% - 35px);
  flex: 1;
  color: ${(p) =>
    editRgbaAlpha({
      rgbaColor: p.$themeColor.primaryFontColor,
      alpha: "0.6",
    })};
  text-transform: capitalize;
  font-weight: 500;
  font-size: 12px;
  text-align: start;
  font-family: ${(p) => p.$doxleFont.subTitleFont};
  padding-left: 35px;

  display: flex;
  align-items: center;
  padding-top: 4px;
`;

export const StyledRootFileFolderName = styled(motion.div)<{}>`
  flex: 1;

  font-size: 14px;
  align-items: center;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: ${(p) => p.theme.font.subTitleFont};
  font-weight: 700;
  color: ${(p) => p.theme.color.primaryFontColor};
`;

export const StyledFileFolderName = styled(motion.div)<{}>`
  flex: 1;

  font-size: 13px;
  align-items: center;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: ${(p) => p.theme.font.subTitleFont};
  font-weight: 500;
  color: ${(p) => p.theme.color.primaryFontColor};
`;

export const StyledFileSizeTableHeaderDiv = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  width: 12%;
  color: ${(p) => p.$themeColor.primaryFontColor};
  text-transform: capitalize;
  padding-top: 4px;
  color: ${(p) =>
    editRgbaAlpha({
      rgbaColor: p.$themeColor.primaryFontColor,
      alpha: "0.6",
    })};
  font-weight: 500;
  font-size: 12px;
  font-family: ${(p) => p.$doxleFont.subTitleFont};
  text-align: center;
  z-index: 4;

  display: flex;
  align-items: center;
  justify-content: center;
`;
export const StyledCreatedByTableHeaderDiv = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  margin-left: -10px;

  width: 10%;
  color: ${(p) => p.$themeColor.primaryFontColor};
  text-transform: capitalize;
  color: ${(p) =>
    editRgbaAlpha({
      rgbaColor: p.$themeColor.primaryFontColor,
      alpha: "0.6",
    })};
  font-weight: 400;
  font-size: 1rem;
  font-family: ${(p) => p.$doxleFont.subTitleFont};
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: calc(100%);
`;

export const StyledCreatedTimeTableHeaderDiv = styled.div<{
  $themeColor: DoxleThemeColor;
}>`
  width: 20%;
  color: ${(p) => p.$themeColor.primaryFontColor};

  font-weight: 600;
  font-size: 14px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: calc(100% - 1rem);
  padding-top: 1rem;
`;
export const StyledLastEditedByTableHeaderDiv = styled.div<{
  $themeColor: DoxleThemeColor;
}>`
  width: 10%;
  color: ${(p) => p.$themeColor.primaryFontColor};
  //background-color: $ {(p) => p.$themeColor.primaryContainerColor};
  // background-color: red;
  text-transform: capitalize;
  font-weight: 600;
  font-size: 14px;
  // text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
export const StyledLastEditedTimeTableHeaderDiv = styled.div<{
  $themeColor: DoxleThemeColor;
}>`
  margin-left: 0px;
  width: 20%;
  text-align: center;
  color: ${(p) => p.$themeColor.primaryFontColor};
  // background-color: $ {(p) => p.$themeColor.primaryContainerColor};
  // background-color: pink;
  text-transform: capitalize;
  font-weight: 600;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
export const StyledFileTableRowCell = styled(TableCell)<{
  $themeColor: DoxleThemeColor;
}>`
  && {
    margin: 0px !important;
    padding: 0px 5px !important;
    width: calc(100% - 10px);
    display: flex;
    position: relative;
    height: 40px;

    flex-direction: row;
    align-items: center;

    background-color: ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.$themeColor.doxleColor,
        alpha: "0",
      })};
    overflow: hidden;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: ${(p) => p.$themeColor.primaryDividerColor};
    transition: 0.4s all ease;
    &:hover {
      transition: 0.4s all ease;
      background-color: ${(p) =>
        editRgbaAlpha({
          rgbaColor: p.$themeColor.doxleColor,
          alpha: "0.2",
        })};
      font-weight: 400;
      cursor: pointer;
    }
  }
  .image-name-wrapper {
    flex: 1;
    height: 100%;
    display: flex;
    align-items: center;
    min-width: 30px;
    position: relative;
  }
`;

export const StyledFileNameTableDiv = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  flex-shrink: 1;
  font-weight: 400;
  font-size: 13px;
  font-family: ${(p) => p.$doxleFont.subTitleFont};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  align-self: center;
  color: ${(p) => p.$themeColor.primaryFontColor};
  transition: 0.2s;
  &:hover {
    color: ${(p) => p.$themeColor.doxleColor};

    transition: 0.2s;
    font-weight: 500;
    cursor: pointer;
    text-decoration: underline;
  }
`;

export const StyledFileSizeTableDiv = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  width: calc(12% - 4px);
  text-align: center;
  font-weight: 400;
  font-size: 13px;
  font-family: ${(p) => p.$doxleFont.subTitleFont};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  justify-content: center;
  color: ${(p) => p.$themeColor.primaryFontColor};
  margin: 0px 2px;
`;

export const StyledCreatedByTableDiv = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  width: calc(15% - 4px);
  text-align: center;
  justify-content: center;
  font-weight: 400;
  font-size: 13px;
  font-family: ${(p) => p.$doxleFont.subTitleFont};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: ${(p) => p.$themeColor.primaryFontColor};
  margin: 0px 2px;
`;

export const StyledCreatedTimeTableDiv = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  width: calc(20% - 4px);
  text-align: center;
  justify-content: center;
  font-weight: 400;
  font-size: 13px;
  font-family: ${(p) => p.$doxleFont.subTitleFont};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: ${(p) => p.$themeColor.primaryFontColor};
  min-width: 120px;
  margin: 0px 2px;
`;

export const StyledFileHeaderLabel = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  font-weight: 500;
  font-family: ${(p) => p.$doxleFont.subTitleFont};
  font-size: 12px;
  text-align: start;
  color: ${(p) =>
    editRgbaAlpha({
      rgbaColor: p.$themeColor.primaryFontColor,
      alpha: "0.6",
    })};
  padding-top: 4px;
`;

export const StyledLastEditedByTableDiv = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  width: 10%;
  text-align: center;
  font-weight: 600;
  font-size: max(1.3rem, 13px);
  font-family: ${(p) => p.$doxleFont.subTitleFont};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  justify-content: center;
  color: ${(p) => p.$themeColor.primaryFontColor};
  // background-color: pink;
`;

export const StyledLastEditedTimeTableDiv = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  width: 20%;
  font-weight: 600;
  font-size: max(1.3rem, 13px);
  font-family: ${(p) => p.$doxleFont.subTitleFont};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
  justify-content: start;
  color: ${(p) => p.$themeColor.primaryFontColor};
  // background-color: blue;
`;
export const StyledHoverMenuContainer = styled.div<{
  $isHovering: boolean;
}>`
  position: absolute;
  ${(p) => (p.$isHovering ? "right: 0;" : "right: -100px;")}
  transition: all 0.2s ease;
  bottom: 0px;
  top: 0px;
  display: flex;
  align-items: center;
  overflow: hidden;
`;
export const StyledFileHeaderIcons = styled(Button)<{
  // $themeColor: DoxleThemeColor;
}>`
  && {
    margin-top: 0px;
    margin-right: -20px;
  }
`;

export const StyledFileHoverSection = styled.div`
  margin: 0px;
  padding: 0px;
  z-index: 30;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: none !important;
`;

export const StyledPencilIconButton = styled(Button)`
  && {
    margin: 0px;
    min-width: 0px !important;
    transition: 0.2s;
    z-index: 30;
    padding: 4px 10px !important;
    border-radius: 4px !important;

    &:hover {
      transform: scale(1.05) translateY(1px);
      transition: 0.2s ease all;
      font-weight: 500;
      cursor: pointer;
    }
    &:active {
      transform: scale(0.97) translateY(-1px);
      transition: 0.2s;
    }
  }
`;

export const StyledTrashIconButton = styled(Button)<{}>`
  && {
    align-self: center;
    min-width: 0px !important;
    transition: 0.2s;
    padding: 0.4rem 0.15rem !important;
    border-radius: 0.4rem !important;
    &:hover {
      transform: scale(1.05) translateY(1px);

      opacity: 1;
      transition: 0.2s;
      font-weight: 500;
      cursor: pointer;
    }
    &:active {
      transform: scale(0.97) translateY(-1px);
      transition: 0.2s;
    }
  }
`;
export const StyledPopover = styled(Popover)<{}>`
  & .MuiPaper-root {
    background-color: transparent;
    box-shadow: none;
  }
`;
export const StyledPopoverSection = styled.div<{}>`
  display: flex;
  flex-direction: column;
  padding: 4px;
  justify-content: center;

  background-color: ${(p) => p.theme.color.primaryContainerColor};
  border-radius: 8px 0px 8px 8px;
  border: 1px solid ${(p) => p.theme.color.primaryDividerColor};
  overflow: hidden;
  min-width: 144px;
`;

export const StyledPopoverItem = styled(Button)<{}>`
  && {
    height: 40px;
    border-radius: 0px !important;
    transition: all 0.2s ease;
    font-weight: 400;
    font-size: 14px;
    color: ${(p) => p.theme.color.primaryFontColor};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    text-transform: none;
    border-bottom-width: 1.2px;
    border-bottom-style: solid;
    border-bottom-color: ${(p) => p.theme.color.primaryDividerColor};
    transition: all 0.2s ease;
    &:hover {
      background-color: ${(p) => p.theme.color.rowHoverColor};

      font-weight: 500;
      cursor: pointer;
    }
  }
`;

export const StyledFilePlaceholder = styled.div<{
  $themeColor: DoxleThemeColor;
}>`
  // background-color: pink;
  // background-color: ${(p) => p.$themeColor.primaryContainerColor};
  // background-color: blue;
  display: flex;

  // width: 90rem;
  height: 60%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const StyledChooseFileButton = styled.div<{
  $themeColor: DoxleThemeColor;
  $isRootFolder?: boolean;
}>`
  margin-top: 14px;
  background-color: ${(p) => p.$themeColor.primaryFontColor};
  color: ${(p) => p.$themeColor.primaryBackgroundColor};
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 14px;
  border-radius: 4px;
  padding: 10px 18px;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
    transition: 0.2s ease;
    font-weight: 600;
    cursor: pointer;
  }
`;

export const StyledFileUploadText = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  margin-top: 6px;
  // // background-color: cyan;
  // background-color: ${(p) => p.$themeColor.primaryFontColor};
  color: ${(p) => p.$themeColor.primaryFontColor};
  display: flex;
  height: 40px;
  width: 250px;
  // flex-direction: column;
  align-items: center;
  border-radius: 9px;
  justify-content: center;
  font-weight: 500;
  font-size: 18px;
  font-family: ${(p) => p.$doxleFont.subTitleFont};
`;

export const StyledPendingFileUploadSection = styled.div<{
  $themeColor: DoxleThemeColor;
}>`
  margin-top: 36px;
  // background-color: cyan;
  // background-color: ${(p) => p.$themeColor.primaryFontColor};
  color: ${(p) => p.$themeColor.primaryBackgroundColor};
  display: flex;
  // height: 40px;
  // width: 250px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // font-weight: 600;
  // font-size: 20px;
  // border-radius: 9px;
  // cursor: pointer;
  // &:hover {
  //   // background-color: ${(p) => p.$themeColor.hoverColor};
  //   opacity: 0.8;
  //   transition: 0.2s;
  //   font-weight: 600;
  //   cursor: pointer;
  // }
`;

export const StyledPendingFileUploadText = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  margin-top: 6px;
  // // background-color: cyan;
  // background-color: ${(p) => p.$themeColor.primaryFontColor};
  color: ${(p) => p.$themeColor.primaryFontColor};
  display: flex;
  // height: 40px;
  // width: 250px;
  // flex-direction: column;
  align-items: center;
  border-radius: 9px;
  justify-content: center;
  font-weight: 500;
  font-size: 36px;
  // font-family: ${(p) => p.$doxleFont.subTitleFont};
`;

export const StyledFilePlaceholderImage = styled.img`
  // max-width: 466.722px;
  // width: 80%;
  // height: 90%;
  // display: flex;
  // margin: 0px;
  max-height: calc(100vh - 200px);
  align-self: center;
  @media (max-width: 4000px) {
    width: 90%;
  }

  @media (max-width: 3200px) {
    width: 80%;
  }

  @media (max-width: 2900px) {
    width: 70%;
  }

  @media (max-width: 2450px) {
    width: 66%;
  }

  @media (max-width: 2300px) {
    width: 63%;
  }

  @media (max-width: 210px) {
    width: 60%;
  }

  @media (max-width: 1850px) {
    width: 45%;
  }

  @media (max-width: 1600px) {
    width: 40%;
  }

  @media (max-width: 1200px) {
    width: 36%;
  }

  @media (max-width: 1024px) {
    width: 33%;
  }

  @media (max-width: 480px) {
    width: 30%;
  }

  // min-width: 350px;
  // max-height: 583px;
  // height: 70rem;
  // min-height: 437.2px;
`;

export const StyledGridView = styled.div<{
  $themeColor: DoxleThemeColor;
}>`
  margin-top: 6px;
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: ${(p) => p.$themeColor.boldDividerColor};

  background-color: ${(p) => p.$themeColor.primaryContainerColor};

  color: ${(p) => p.$themeColor.primaryFontColor};
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  align-items: center;

  justify-content: center;
`;

export const StyledPlaceholder = styled.div<{
  $themeColor: DoxleThemeColor;
}>`
  margin-top: 0%;
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: ${(p) => p.$themeColor.boldDividerColor};
  display: flex;
  height: 70%;
  // width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  // background-color: yellow;
  // z-index: 0;
  position: relative;
`;

export const StyledGridItemContainer = styled.div<{}>`
  width: calc(${100 / 2}% - 16px);
  aspect-ratio: 1;
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (min-width: 1280px) {
    width: calc(${100 / 5}% - 16px);
  }

  @media screen and (min-width: 1000px) and (max-width: 1279px) {
    width: calc(${100 / 4}% - 16px);
  }

  @media screen and (min-width: 800px) and (max-width: 999px) {
    width: calc(${100 / 3}% - 16px);
  }
  @media screen and (min-width: 500px) and (max-width: 799px) {
    width: calc(${100 / 2}% - 16px);
  }
  @media screen and (max-width: 499px) {
    width: calc(${100 / 1}% - 16px);
  }
`;

export const StyledGridItemWrapper = styled.div<{
  $themeColor: DoxleThemeColor;
}>`
  width: 100%;
  height: 100%;
  text-align: center;
  font-size: 16px;
  background-color: ${(p) => p.$themeColor.primaryContainerColor};
  white-space: nowrap;
  display: flex;

  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const StyledGridFolderItem = styled.div<{
  $themeColor: DoxleThemeColor;
}>`
  width: calc(100% - 8px);
  height: calc(100% - 8px);
  padding: 4px;
  text-align: center;
  font-size: 16px;
  background-color: ${(p) => p.$themeColor.primaryContainerColor};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid ${(p) => p.$themeColor.primaryDividerColor};
  border-radius: 18px;
  cursor: pointer;

  span {
    font-weight: 500;
    font-size: 18px;
    width: 100%;
  }
  transition: all 0.3s ease-in-out;
  &:hover {
    border-color: ${(p) => p.$themeColor.doxleColor};

    font-weight: 600;
    cursor: pointer;
  }
`;

export const StyledGridFolderName = styled.span<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  // background-color: pink;
  margin-top: 3px;
  // font-family: ${(p) => p.$doxleFont.secondaryTitleFont};
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  align-self: center;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${(p) => p.$themeColor.primaryFontColor};
`;

export const StyledGridFileItem = styled.div<{
  $themeColor: DoxleThemeColor;
}>`
  width: calc(100% - 8px);
  height: calc(100% - 8px);
  padding: 4px;
  text-align: center;
  font-size: 16px;
  background-color: ${(p) => p.$themeColor.primaryContainerColor};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid ${(p) => p.$themeColor.primaryDividerColor};
  font-weight: 500;
  border-radius: 18px;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: all 0.3s ease-in-out;
  &:hover {
    border-color: ${(p) => p.$themeColor.doxleColor};
    font-weight: 600;
    cursor: pointer;
  }
`;

export const StyledGridFileName = styled.span<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  margin-top: 6px;
  // font-family: ${(p) => p.$doxleFont.secondaryTitleFont};
  font-weight: 500;
  font-size: 16px;

  text-align: center;
  align-self: center;
  color: ${(p) => p.$themeColor.primaryFontColor};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: wrap;
`;

export const StyledGridFileSize = styled.span<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  margin-top: 6px;
  font-family: ${(p) => p.$doxleFont.secondaryTitleFont};
  font-weight: 500;
  font-size: 14px;
  line-height: normal;
  text-align: center;
  align-self: center;
  color: ${(p) =>
    editRgbaAlpha({
      rgbaColor: p.$themeColor.primaryFontColor,
      alpha: "0.4",
    })};
  }) ;
`;

export const StyledFilesInsideFolder = styled.div<{
  $themeColor: DoxleThemeColor;
}>`
  flex: 1;
  background-color: ${(p) => p.$themeColor.primaryContainerColor};
  // background-color: yellow;
  height: 12%;
  display: flex;

  flex-direction: column;
  justify-content: center;
  align-items: center;
  // border: 1px solid ${(p) => p.$themeColor.boldDividerColor};
`;

export const StyledDialog = styled.div<{
  $themeColor: DoxleThemeColor;
}>`
  // flex: 1;
  // background-color: ${(p) => p.$themeColor.primaryContainerColor};
  // background-color: yellow;
  height: 20%;
  width: 100%;
  display: flex;

  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid ${(p) => p.$themeColor.boldDividerColor};
`;

export const StyledCheckButton = styled.div<{
  $themeColor: DoxleThemeColor;
}>`
  // flex: 1;
  background-color: ${(p) => p.$themeColor.primaryContainerColor};
  // background-color: yellow;
  height: 20%;
  width: 100%;
  display: flex;

  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid ${(p) => p.$themeColor.boldDividerColor};
`;

export const StyledCancelButton = styled.div<{
  $themeColor: DoxleThemeColor;
}>`
  // flex: 1;
  // background-color: ${(p) => p.$themeColor.primaryContainerColor};
  // background-color: yellow;
  height: 20%;
  width: 100%;
  display: flex;

  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid ${(p) => p.$themeColor.boldDividerColor};
`;

export const StyledFileRenameField = styled(TextField)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  && {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    border-bottom: none !important;
    // pointer-events: none;
    // background-color: red;
    width: 100%;
  }
  input {
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-weight: 500;
    font-size: 18px;
    line-height: normal;
    letter-spacing: 0.0075em;
    color: ${(p) => p.$themeColor.primaryFontColor};
    border-bottom: none !important;
    border: 3px soild ${(p) => p.$themeColor.boldDividerColor};

    &::placeholder {
      font-family: ${(p) => p.$doxleFont.primaryFont};
      font-size: 18px;
      font-style: normal;
      font-weight: 500;

      color: ${(p) => p.$themeColor.primaryFontColor};
    }
  }

  & .MuiInput-root:before {
    border-bottom: none !important;
  }
  & .MuiInput-root:after {
    border-bottom: none !important;
  }
  & .MuiInput-root {
    border-bottom: none !important;
    padding: 8px;
    // border: 1px solid ${(p) => p.$themeColor.boldDividerColor} !important;
  }
  & .MuiInput-root:hover {
    border-bottom: none !important;
  }

  & .MuiTextField-root {
    // border-bottom: none !important;
    border: 1px solid ${(p) => p.$themeColor.boldDividerColor} !important;
  }

  &. MuiInputLabel-root.Mui-focused {
    color: ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.$themeColor.primaryFontColor as TRgbaFormat,
        alpha: "0.4",
      })};
    font-size: 14px !important;
    border: 3px soild ${(p) => p.$themeColor.boldDividerColor};
  }
  &. MuiInputLabel-root {
    font-weight: 500;
    font-size: 18px;
    line-height: 110%;
    letter-spacing: 0.0075em;
    transition: 0.4s !important;
    // background-color:'red',
    color: ${(p) => p.$themeColor.primaryDividerColor};
    border: 3px solid ${(p) => p.$themeColor.boldDividerColor};
  }
`;

export const StyledIconButton = styled(Button)<{
  $themeColor: DoxleThemeColor;
}>`
  && {
    align-self: center;
    margin-left: 6px;
    // margin-right: 18px;
    margin: 0px;
    border-radius: 9px;
    width: 4rem;
    // height: 22px;
    min-width: 0px !important;
    // max-width: 10px;
    // background-color: yellow;
    transition: 0.2s;
    background-color: ${(p) => p.$themeColor.doxleColor};
    // border: 1px solid ${(p) => p.$themeColor.boldDividerColor};

    &:hover {
      background-color: ${(p) => p.$themeColor.hoverColor};

      transition: 0.2s;
      font-weight: 500;
      cursor: pointer;
    }
  }
`;

export const StyledLinearProgress = styled(LinearProgress)<{
  $themeColor: DoxleThemeColor;
}>`
  && {
    height: 1px;
    border-radius: 5px;
    width: 75%;
    align-items: center;
    max-width: 500px;
    justify-content: center;
    background-color: ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.$themeColor.doxleColor,
        alpha: "0.2",
      })};
  }
  & .MuiLinearProgress-bar {
    border-radius: 5px;
    background-color: ${(p) => p.$themeColor.doxleColor};
  }
`;

// background-color: ${(p) =>
//   editRgbaAlpha({
//     rgbaColor: p.$themeColor.doxleColor as TRgbaFormat,
//     alpha: "0.1",
//   })};

// @media (max-width: 1024px) {
//   width: 50%;
// }

// @media (max-width: 480px) {
//   width: 100%;
// }
export const StyledTableFileListScroller = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;
export const StyledFileTableBody = styled(TableBody)`
  && {
    width: 100% !important;
    min-width: 14.4rem !important;
    display: flex;
    flex-direction: column;
  }
`;

export const StyledLeftFileTable = styled(Table)<{
  $themeColor: DoxleThemeColor;
}>`
  && {
    width: 100% !important;
    height: 100% !important;
    display: flex;
    flex-direction: column;
    background-color: ${(p) => p.$themeColor.primaryContainerColor};
  }
`;
export const StyledLeftFileListScroller = styled.div`
  width: 100%;
  height: 100%;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;
export const StyledFileLeftTableHead = styled(TableHead)`
  && {
    width: 100% !important;
    z-index: 1000;
  }
`;
export const StyledFileLeftTableBody = styled(TableBody)`
  && {
    width: 100% !important;

    display: flex;
    flex-direction: column;
  }
`;
export const StyledListLeftOuterWrapper = styled.div<{ $paddingTop?: number }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: ${(p) => p.$paddingTop ?? 0}px;
`;
export const StyledListNewFolderPlaceholderRow = styled(TableCell)`
  && {
    position: relative;
    align-items: center;
    min-width: 0px !important;

    height: 40px;
    width: calc(100% - 13px) !important;
    overflow: hidden;
    padding: 0px 0px 0px 13px !important;

    display: flex;
    flex-direction: row;

    border-bottom: none !important;
  }
  .row-content-wrapper {
    display: flex;
    flex-direction: row;
    width: calc(100% - 10px);
    position: relative;

    height: 100%;
    align-items: center;
    border-radius: 0.5rem;
    padding: 0 5px;
    background-color: ${(p) => (p) => p.theme.color.primaryContainerColor};
    transition: all 0.4s ease;
  }

  &.MuiTableCell-root:before {
    content: "";
    position: absolute;
    left: 0px;
    height: 50%;
    bottom: 50%;
    border-left: 1px solid ${(p) => p.theme.color.boldDividerColor};
    z-index: 1000;
  }
`;

export const StyledNewFolderTextField = styled.input<{}>`
  border: none;
  font-family: ${(p) => p.theme.font.primaryFont};
  height: calc(100% - 2px);
  background-color: transparent;
  width: calc(100% - 10px - 20px);
  padding-left: 10px;
  &:focus {
    border: none;
    outline: none;
  }
`;
export const StyledFolderInput = styled.input<{
  webkitdirectory: true | undefined;
  directory: true | undefined;
}>``;
export const StyledFilesRowRightWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const StyleNewFileRowRight = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  width: 100%;
  height: 2.7rem;
  min-height: 25px;
  max-height: 30px;
  border-bottom: 1px solid ${(p) => p.$themeColor.boldDividerColor};
  //border-top: 1px solid $ {(p) => p.$themeColor.boldDividerColor};
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  vertical-align: center;
  cursor: pointer;
  img {
    margin-left: 1rem;
  }
  span {
    padding-left: 1rem;
    line-height: 2.4rem;
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-weight: 400;
    font-size: 1.3rem;
    color: ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.$themeColor.primaryFontColor,
        alpha: "0.6",
      })};
  }
`;

export const StyledFileListLeftFooter = styled(TableFooter)`
  && {
    width: 100%;
    height: 40px;
  }
`;

export const StyledAddedFileItem = styled(motion.div)`
  display: flex;
  align-items: center;
  align-self: flex-start;
  border: 1px solid ${(p) => p.theme.color.primaryDividerColor};
  border-radius: 100px;
  padding: 4px;
  margin-right: 8px;
  margin-bottom: 8px;
  .image-holder {
    margin-right: 4px;
    width: 18px;
    aspect-ratio: 1;
    object-fit: contain;
  }
  .file-name {
    font-size: 12px;
    font-weight: 400;
    margin: 0px 5px;
    font-family: ${(p) => p.theme.font.primaryFont};
  }
  .icon-remove {
    cursor: pointer;
  }
`;

export const StyledConfirmAddFileLabel = styled.div`
  font-size: 12px;
  font-weight: 500;
  font-family: ${(p) => p.theme.font.primaryFont};
  color: ${(p) => p.theme.color.primaryFontColor};
  margin-bottom: 4px;
`;
