import { useXeroStore } from "../Store/useXeroStore";
import { shallow } from "zustand/shallow";
import { Account } from "../Models/account";
import { useEffect, useMemo } from "react";
import { Docket } from "../../Models/dockets";
import useGetAccounts from "./useGetAccounts";
interface Props {
  docket: Docket;
}
interface AccountWithScore extends Account {
  score: number;
}
const useDocketMappingRow = ({ docket }: Props) => {
  const { handleAccountDropDown, setUnsavedAccountLinks, unsavedAccountLinks } =
    useXeroStore(
      (state) => ({
        handleAccountDropDown: state.handleAccountDropDown,
        unsavedAccountLinks: state.unSavedAccountLinks,
        setUnsavedAccountLinks: state.setUnsavedAccountLinks,
      }),
      shallow
    );
  const { accountsList } = useGetAccounts({});
  const linkedItem: Account | undefined = accountsList.filter(
    (data) => data.accountId === docket.accountTrackingId
  )?.[0];
  const isLinked = Boolean(linkedItem);

  const selectedAccount = useMemo(
    () =>
      unsavedAccountLinks.filter((acc) => acc.docketPk === docket.docketPk)?.[0]
        ?.accountName,
    [unsavedAccountLinks, docket]
  );

  useEffect(() => {
    if (isLinked) return;
    const accountsListWithScore: AccountWithScore[] = accountsList.map(
      (account) => {
        const accountNameWords = account.accountName.toLowerCase().split(" ");
        const score = accountNameWords.filter(
          (word) =>
            docket.docketName.toLowerCase().includes(word) ||
            String(docket.docketIdNum).includes(word)
        ).length;
        return { ...account, score };
      }
    );
    const topAccount: AccountWithScore = accountsListWithScore.sort((a, b) =>
      a.score < b.score ? -1 : a.score > b.score ? 1 : 0
    )?.[0];
    if (topAccount && topAccount.score > 0) {
      setUnsavedAccountLinks([
        ...unsavedAccountLinks.filter(
          (account) => account.docketPk !== docket.docketPk
        ),
        {
          docketPk: docket.docketPk,
          accountId: topAccount.accountCode,
          accoutCode: topAccount.accountName,
          accountName: topAccount.accountName,
        },
      ]);
    }
  }, [isLinked, docket]);

  return {
    handleAccountDropDown,
    isLinked,
    linkedItem,
    selectedAccount,
  };
};
export default useDocketMappingRow;
