import {useQueryClient} from "@tanstack/react-query";
import {Docket} from "../../Models/dockets";
import {useDoxleCurrentContextStore} from "../../DoxleGeneralStore/useDoxleCurrentContext";
import {shallow} from "zustand/shallow";
import {useProjectStore} from "../../DoxleGeneralStore/useprojectStore";
import {AxiosResponse} from "axios";
import {ProjectWithDockets} from "../../Budgets/QueryHooks/BudgetAPI";

const useUpdateProfitAndLoss = () => {
  const projectId = useDoxleCurrentContextStore(state => state.currentProject?.projectId, shallow);
  const paymentsOnly = useProjectStore(state=> state.accountingBasis==='CASH', shallow);
  const queryClient = useQueryClient()
  const addDocketToProfitAndLoss = (docket?: Docket) => {
    queryClient.setQueryData<AxiosResponse<ProjectWithDockets>>(
      ["project-p&l", projectId, `payments=${paymentsOnly}`],
      (old) => {
        console.log(old);
        if (!old || !docket) return old;
        const docketList = [...old.data.dockets]
        docketList.push(docket)
        const sortedDockets = docketList.sort((a, b) => (
          a.docketIdNum < b.docketIdNum ? -1 : 0
        ))
        console.log(sortedDockets)
        return ({
          ...old,
          data: {
            ...old.data,
            dockets: sortedDockets
          }
        })
      }
    )
  }
  return {
    addDocketToProfitAndLoss
  }
}

export default useUpdateProfitAndLoss
