import {StyledUnmappedAccountRow} from "./styledComponents";
// import {PiMoneyLight} from "react-icons/pi";
import React from "react";
import {useDoxleCurrentContextStore} from "../../DoxleGeneralStore/useDoxleCurrentContext";
import {shallow} from "zustand/shallow";
import DocketQueryAPI from "../../Services/QueryHooks/docketQueryAPI";
import useDoxleNotificationStore from "../../DoxleGeneralStore/useDoxleNotificationStore";
import {AccountWithCost} from "../Hooks/useAccountMapping";
import useUpdateProfitAndLoss from "../Hooks/useUpdateProfitAndLoss";

interface Props {
  account: AccountWithCost;
}
const UnmappedAccountRow = ({
  account
}: Props) => {
  const hasCost = Boolean(account.cost);
  const {project, company} = useDoxleCurrentContextStore(state => ({
    project: state.currentProject,
    company: state.currentCompany,
  }), shallow);
  const showNotification = useDoxleNotificationStore(state => state.showNotification, shallow);
  const {addDocketToProfitAndLoss} = useUpdateProfitAndLoss()
  const addBudgetDocketQuery = DocketQueryAPI.useAddDocketQuery({
    company,
    showNotification,
    filter: { project: project?.projectId, view: "budget" },
    onSuccessCb: addDocketToProfitAndLoss
  });
  const handleAddDocket = () => {
    addBudgetDocketQuery.mutate({
      project: project?.projectId ?? null,
      projectSiteAddress: project?.siteAddress ?? '',
      docketName: account.accountName,
      accountCode: account.accountCode.replace(/\D/g, ""),
      accountTrackingId: account.accountId,
      isExpense: account.type !== 'REVENUE',
      isIncome: account.type === 'REVENUE',
    })
  }
  return (
    <StyledUnmappedAccountRow $hasCost={hasCost} onClick={handleAddDocket}>
      {/*{hasCost && <PiMoneyLight style={{width: 20, height: 24}}/>}*/}
      &nbsp;&nbsp;
      {account.accountName}{hasCost && ` - ${account.cost}`}
    </StyledUnmappedAccountRow>
  )
}

export default UnmappedAccountRow
