import React, { useCallback, useState } from "react";
import { Contact } from "../../../../../Models/addressBook";
import { useDoxleCurrentContextStore } from "../../../../../DoxleGeneralStore/useDoxleCurrentContext";
import { useEditDocketSideScreenStore } from "../../../EditDocketSideScreenStore/useEditDocketSideScreenStore";
import { shallow } from "zustand/shallow";
import { Docket } from "../../../../../Models/dockets";
import DocketQuery from "../../../../../Services/QueryHooks/docketQueryAPI";
import useSetDocketDetailQueryData from "../../../../QueryDataHooks/SetQueryDataHooks/useSetDocketDetailQueryData";

type Props = {};

interface IAssigneeSection {
  handleUpdateAssignee: (item: Contact | null) => void;
  handleOpenAssigneeList: (
    e: React.MouseEvent<HTMLElement, MouseEvent>
  ) => void;
  handleCloseAssigneeList: () => void;
  anchorAssigneeList: HTMLElement | null;
}
const useAssigneeSection = (props: Props): IAssigneeSection => {
  const [anchorAssigneeList, setAnchorAssigneeList] =
    useState<HTMLElement | null>(null);
  const { company } = useDoxleCurrentContextStore((state) => ({
    company: state.currentCompany,
  }));
  const {
    edittedDocket,
    updateEdittedDocket,
    filterDocketListQuery,
    handlePostUpdateDocket,
  } = useEditDocketSideScreenStore(
    (state) => ({
      edittedDocket: state.edittedDocket,
      updateEdittedDocket: state.updateEdittedDocket,
      filterDocketListQuery: state.filterDocketListQuery,
      handlePostUpdateDocket: state.handlePostUpdateDocket,
    }),
    shallow
  );
  const { handleUpdateDocket } = useSetDocketDetailQueryData({
    docketPk: edittedDocket?.docketPk ?? "",
  });
  const onSuccessUpdate = (newDocket?: Docket) => {
    if (newDocket) {
      if (handlePostUpdateDocket) handlePostUpdateDocket(newDocket);

      handleUpdateDocket(newDocket);
    }
  };
  const updateDocketQuery = DocketQuery.useUpdateDocketQuery({
    filter: filterDocketListQuery,

    company,
    onSuccessCb: onSuccessUpdate,
  });

  const handleUpdateAssignee = (item: Contact | null) => {
    if (edittedDocket) {
      if (item) {
        updateEdittedDocket({
          ballInCourt: item.contactId,
          ballInCourtName: `${item.firstName} ${item.lastName}`,
          ballInCourtJson: item,
        });
        updateDocketQuery.mutate({
          docketPk: edittedDocket.docketPk,
          ballInCourt: item.contactId,
        });
      } else {
        updateEdittedDocket({
          ballInCourt: null,
          ballInCourtName: undefined,
          ballInCourtJson: undefined,
        });
        updateDocketQuery.mutate({
          docketPk: edittedDocket.docketPk,
          ballInCourt: null,
        });
      }
    }
  };
  const handleOpenAssigneeList = useCallback(
    (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      setAnchorAssigneeList(e.currentTarget);
    },
    []
  );
  const handleCloseAssigneeList = () => {
    setAnchorAssigneeList(null);
  };

  return {
    handleUpdateAssignee,
    handleOpenAssigneeList,
    handleCloseAssigneeList,
    anchorAssigneeList,
  };
};

export default useAssigneeSection;
