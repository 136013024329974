import React, { useMemo } from "react";
import {
  StyledEmailBody,
  StyledInfoTitle,
  StyledMailInfoContainer,
} from "../StyledMailSideScreen";
import { ReplyMail } from "../../../Models/mail";
import { useDoxleThemeStore } from "../../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import {
  editRgbaAlpha,
  formatMessageDate,
} from "../../../../Utilities/FunctionUtilities";
import { useDoxleAuthStore } from "../../../../DoxleGeneralStore/useDoxleAuthStore";
import MailQueryAPI from "../../../Hooks/mailAPI";
import MailAttachmentSection from "../MailAttachmentSection";

type Props = {
  item: ReplyMail;
};

const MailReplyItem = ({ item }: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }),
    shallow
  );

  const user = useDoxleAuthStore((state) => state.user, shallow);

  const userName = useMemo(() => {
    let name = user?.firstName ?? "";
    name += " ";
    name += user?.lastName ?? "";
    return name;
  }, [user]);

  // const replyAttachmentQuery = MailQueryAPI.useGetMailAttachment({
  //   id: item.replyId,
  //   isReply: true,
  //   enable: true,
  // });

  return (
    <StyledMailInfoContainer $width="100%" style={{ marginTop: "1rem" }}>
      <StyledInfoTitle $themeColor={doxleThemeColor}>
        {item.fromString} WROTE ON {formatMessageDate(item.timeStamp)}
      </StyledInfoTitle>
      <StyledEmailBody
        $themeColor={doxleThemeColor}
        style={{
          backgroundColor:
            userName === item.fromString
              ? editRgbaAlpha({
                  rgbaColor: doxleThemeColor.doxleColor,
                  alpha: "0.1",
                })
              : "",
        }}
      >
        {item.textBody}
        <MailAttachmentSection mailAttachment={item.attachments} isReply />
      </StyledEmailBody>
    </StyledMailInfoContainer>
  );
};

export default MailReplyItem;
