import React, { useState } from "react";
import useBookingCalendarStore from "../../store/useBookingCalendarStore";
import { useShallow } from "zustand/react/shallow";
import { useDoxleCurrentContextStore } from "../../../DoxleGeneralStore/useDoxleCurrentContext";
import {
  AddBookingQueryParams,
  BookingQueryAPI,
} from "../../QueryAPI/bookingQueryAPI";
import { formatTISODate } from "../../../Utilities/FunctionUtilities";
type Props = {
  date: Date;
};

const useBookingSectionFooter = ({ date }: Props) => {
  const [newTitle, setNewTitle] = useState("");
  const { filterBookingProjectQuery } = useBookingCalendarStore(
    useShallow((state) => ({
      filterBookingProjectQuery: state.filterBookingProjectQuery,
    }))
  );
  const { company, currentProject } = useDoxleCurrentContextStore(
    useShallow((state) => ({
      company: state.currentCompany,
      currentProject: state.currentProject,
    }))
  );
  const { add } = BookingQueryAPI.useMutateBookingQuery({
    company,
    filter: filterBookingProjectQuery,
  });
  const handleAddBooking = () => {
    if (newTitle) {
      const dataBooking: AddBookingQueryParams = {
        project: currentProject?.projectId,
        company: company?.companyId,
        title: newTitle,
        endDate: formatTISODate(date),
        startDate: formatTISODate(date),
      };

      add.mutate(dataBooking);
    }
  };
  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.nativeEvent.key === "Enter") {
      e.preventDefault();
      handleAddBooking();

      // Clear the input
      setNewTitle("");
      e.currentTarget.blur();
    } else if (e.nativeEvent.key === "Escape") {
      setNewTitle("");
      e.currentTarget.blur();
    }
  };
  return {
    newTitle,
    setNewTitle,
    handleKeyDown,
    isAdding: add.isLoading,
  };
};

export default useBookingSectionFooter;
