import React, { useCallback, useState } from "react";
import {
  StyledContactsAddNewPrimaryContactTitleDiv,
  StyledContactsAddNewInputTitleText,
  StyledContactsAddNewPrimaryContactDiv,
  StyledContactsAddNewInputDiv,
  StyledContactsDeleteButton,
  StyledContactsAddMoreCheckBox,
  StyledContactsAddNewTopButtonsDiv,
} from "./styledComponents";
import { Remove } from "@mui/icons-material";
import { Contact } from "../../../Models/addressBook";
import {useDoxleThemeStore} from "../../../DoxleGeneralStore/useDoxleThemeStore";
import {shallow} from "zustand/shallow";
import DoxleDialogHelper from "../../../DoxleDesignPattern/DoxleDialogHelper/DoxleDialogHelper";
import ContactsTextField from "../GeneralComponents/ContactsTextField";


type Props = {
  contactItem: Contact;
  itemIndex: number;
  setExistedContacts?: React.Dispatch<React.SetStateAction<Contact[]>>;
  setNewAddedContacts?: React.Dispatch<React.SetStateAction<Contact[]>>;
  setUpdatedContacts?: React.Dispatch<React.SetStateAction<Contact[]>>;
  action: "edit" | "add";
};

function ContactsCompanyEditMenuContactComponent({
  contactItem,
  itemIndex,
  setExistedContacts,
  action,
  setNewAddedContacts,
  setUpdatedContacts,
}: // index,
Props) {
  // State for Confirmation Delete Dialog Menu
  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);
  const styleProps = useDoxleThemeStore(state => ({
    $doxleFont: state.doxleFont,
    $themeColor: state.doxleThemeColor,
    $currentTheme: state.currentTheme,
  }), shallow);

  const handleDeleteNewContactClick = useCallback(() => {
    if (action === "add" && setNewAddedContacts) {
      setNewAddedContacts((prev) => [
        ...prev.filter((prevContact, index) => index !== itemIndex),
      ]);
    }
  }, []);

  const handleFirstNameChange = useCallback(
    (value: string) => {
      if (action === "edit" && setExistedContacts) {
        setExistedContacts((prev) => [
          ...prev.map((prevContact, index) => {
            if (prevContact.contactId === contactItem.contactId)
              return { ...prevContact, firstName: value };
            else return prevContact;
          }),
        ]);
        if (setUpdatedContacts)
          setUpdatedContacts((prev) =>
            prev.filter(
              (prevContact) => prevContact.contactId === contactItem.contactId
            )[0] !== undefined
              ? [
                  ...prev.map((prevContact) => {
                    if (prevContact.contactId === contactItem.contactId)
                      return { ...contactItem, firstName: value };
                    else return prevContact;
                  }),
                ]
              : [...prev, { ...contactItem, firstName: value }]
          );
      }

      if (action === "add" && setNewAddedContacts) {
        setNewAddedContacts((prev) => [
          ...prev.map((prevContact, index) => {
            if (index === itemIndex)
              return { ...prevContact, firstName: value };
            else return prevContact;
          }),
        ]);
      }
    },
    [action, contactItem]
  );

  const handleLastNameChange = useCallback(
    (value: string) => {
      if (action === "edit" && setExistedContacts) {
        setExistedContacts((prev) => [
          ...prev.map((prevContact, index) => {
            if (prevContact.contactId === contactItem.contactId)
              return { ...prevContact, lastName: value };
            else return prevContact;
          }),
        ]);
        if (setUpdatedContacts)
          setUpdatedContacts((prev) =>
            prev.filter(
              (prevContact) => prevContact.contactId === contactItem.contactId
            )[0] !== undefined
              ? [
                  ...prev.map((prevContact) => {
                    if (prevContact.contactId === contactItem.contactId)
                      return { ...contactItem, lastName: value };
                    else return prevContact;
                  }),
                ]
              : [...prev, { ...contactItem, lastName: value }]
          );
      }

      if (action === "add" && setNewAddedContacts) {
        setNewAddedContacts((prev) => [
          ...prev.map((prevContact, index) => {
            if (index === itemIndex) return { ...prevContact, lastName: value };
            else return prevContact;
          }),
        ]);
      }
    },
    [action, contactItem]
  );

  const handleEmailChange = useCallback(
    (value: string) => {
      if (action === "edit" && setExistedContacts) {
        setExistedContacts((prev) => [
          ...prev.map((prevContact, index) => {
            if (prevContact.contactId === contactItem.contactId)
              return { ...prevContact, email: value };
            else return prevContact;
          }),
        ]);

        if (setUpdatedContacts)
          setUpdatedContacts((prev) =>
            prev.filter(
              (prevContact) => prevContact.contactId === contactItem.contactId
            )[0] !== undefined
              ? [
                  ...prev.map((prevContact) => {
                    if (prevContact.contactId === contactItem.contactId)
                      return { ...contactItem, email: value };
                    else return prevContact;
                  }),
                ]
              : [...prev, { ...contactItem, email: value }]
          );
      }

      if (action === "add" && setNewAddedContacts) {
        setNewAddedContacts((prev) => [
          ...prev.map((prevContact, index) => {
            if (index === itemIndex) return { ...prevContact, email: value };
            else return prevContact;
          }),
        ]);
      }
    },
    [action, contactItem]
  );

  const handlePhoneChange = useCallback(
    (value: string) => {
      if (action === "edit" && setExistedContacts) {
        setExistedContacts((prev) => [
          ...prev.map((prevContact, index) => {
            if (index === itemIndex) return { ...prevContact, phone: value };
            else return prevContact;
          }),
        ]);
        if (setUpdatedContacts)
          setUpdatedContacts((prev) =>
            prev.filter(
              (prevContact) => prevContact.contactId === contactItem.contactId
            )[0] !== undefined
              ? [
                  ...prev.map((prevContact) => {
                    if (prevContact.contactId === contactItem.contactId)
                      return { ...contactItem, phone: value };
                    else return prevContact;
                  }),
                ]
              : [...prev, { ...contactItem, phone: value }]
          );
      }
      if (action === "add" && setNewAddedContacts)
        setNewAddedContacts((prev) => [
          ...prev.map((prevContact, index) => {
            if (index === itemIndex) return { ...prevContact, phone: value };
            else return prevContact;
          }),
        ]);
    },
    [action, contactItem]
  );

  const handleToggleIsPrimary = useCallback(
    (value: boolean) => {
      if (action === "edit" && setExistedContacts) {
        setExistedContacts((prev) => [
          ...prev.map((prevContact, index) => {
            if (prevContact.contactId === contactItem.contactId)
              return { ...prevContact, isPrimary: value };
            else return prevContact;
          }),
        ]);
        if (setUpdatedContacts)
          setUpdatedContacts((prev) =>
            prev.filter(
              (prevContact) => prevContact.contactId === contactItem.contactId
            )[0] !== undefined
              ? [
                  ...prev.map((prevContact) => {
                    if (prevContact.contactId === contactItem.contactId)
                      return { ...contactItem, isPrimary: value };
                    else return prevContact;
                  }),
                ]
              : [...prev, { ...contactItem, isPrimary: value }]
          );
      }
      if (action === "add" && setNewAddedContacts)
        setNewAddedContacts((prev) => [
          ...prev.map((prevContact, index) => {
            if (index === itemIndex)
              return { ...prevContact, isPrimary: value };
            else return prevContact;
          }),
        ]);
    },
    [action, contactItem]
  );

  const handleToggleSendQuotes = useCallback(
    (value: boolean) => {
      if (action === "edit" && setExistedContacts) {
        setExistedContacts((prev) => [
          ...prev.map((prevContact, index) => {
            if (prevContact.contactId === contactItem.contactId)
              return { ...prevContact, sendQuotes: value };
            else return prevContact;
          }),
        ]);
        if (setUpdatedContacts)
          setUpdatedContacts((prev) =>
            prev.filter(
              (prevContact) => prevContact.contactId === contactItem.contactId
            )[0] !== undefined
              ? [
                  ...prev.map((prevContact) => {
                    if (prevContact.contactId === contactItem.contactId)
                      return { ...contactItem, sendQuotes: value };
                    else return prevContact;
                  }),
                ]
              : [...prev, { ...contactItem, sendQuotes: value }]
          );
      }
      if (action === "add" && setNewAddedContacts)
        setNewAddedContacts((prev) => [
          ...prev.map((prevContact, index) => {
            if (index === itemIndex)
              return { ...prevContact, sendQuotes: value };
            else return prevContact;
          }),
        ]);
    },
    [action, contactItem]
  );
  const checkValidEmail = useCallback(() => {
    const emailRegex = /.+\@.+\..+/;
    return emailRegex.test(contactItem.email);
  }, [contactItem.email]);

  const checkValidPhone = useCallback(() => {
    const numberRegex =
      /^(\+?\(61\)|\(\+?61\)|\+?61|\(0[1-9]\)|0[1-9])?( ?-?[0-9]){7,9}$/m;
    return numberRegex.test(contactItem.phone);
  }, [contactItem.phone]);
  // //$$$$$$$$ DELETE CONTACTS FOR COMPANY QUERY $$$$$$$$$$$
  // const deleteSelectedContact =
  //   ContactsAPI.useRemoveContactsQuery(showNotification);

  // const runDeleteContact = () => {
  //   const deleteContact = {
  //     companyId: company?.companyId!,
  //     searchInput: searchInput,
  //     contactId: item.contactId!,
  //   };
  //   console.log("delete is running", deleteContact);
  //   deleteSelectedContact.mutate(deleteContact);
  // };

  // //$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
  return (
    <>
      <DoxleDialogHelper
        title="Confirm Delete Contact?"
        description="This contact will be deleted permanently, do you want to proceed?"
        open={confirmDelete}
        onClose={() => setConfirmDelete(false)}
        dialogButtons={[
          {
            buttonText: "Cancel",
            buttonFunction: () => {},
            buttonColor: styleProps.$themeColor.primaryContainerColor,
            btnStyleSx: {
              border: `1px solid ${styleProps.$themeColor.primaryDividerColor}`,
            },
            buttonTextStyle: {
              color: styleProps.$themeColor.primaryFontColor,
            },
          },
          {
            buttonText: "Confirm",
            buttonFunction: () => {},
            btnStyleSx: {
              border: `1px solid ${styleProps.$themeColor.primaryDividerColor}`,
            },
          },
        ]}
      />

      <StyledContactsAddNewPrimaryContactTitleDiv
        style={{ justifyContent: "space-between", alignItems: "center" }}
      >
        <StyledContactsAddNewInputTitleText
          marginBottom={"0px"}
          {...styleProps}
        >
          {itemIndex == 0 ? "PRIMARY CONTACT" : "SECONDARY CONTACT"}
        </StyledContactsAddNewInputTitleText>
        <StyledContactsAddNewTopButtonsDiv>
          <StyledContactsAddMoreCheckBox
            checked={contactItem.isPrimary}
            onChange={(event) => {
              handleToggleIsPrimary(event.target.checked);
            }}
          />
          <StyledContactsAddNewInputTitleText
            marginBottom={"0px"}
            paddingRight={"5px"}
            {...styleProps}
          >
            PRIMARY
          </StyledContactsAddNewInputTitleText>
          <StyledContactsAddMoreCheckBox
            checked={contactItem.sendQuotes}
            onChange={(event) => {
              handleToggleSendQuotes(event.target.checked);
            }}
          />
          <StyledContactsAddNewInputTitleText
            {...styleProps}
            marginBottom={"0px"}
            paddingRight={"5px"}
          >
            SEND QUOTE?
          </StyledContactsAddNewInputTitleText>

          <StyledContactsDeleteButton
            disableRipple
            sx={{ minWidth: "14px", maxWidth: "14px" }}
            onClick={
              action === "add"
                ? handleDeleteNewContactClick
                : () => setConfirmDelete(true)
            }
          >
            <Remove
              sx={{
                width: "14px",
                height: "14px",
              }}
            />
          </StyledContactsDeleteButton>
        </StyledContactsAddNewTopButtonsDiv>
      </StyledContactsAddNewPrimaryContactTitleDiv>
      <StyledContactsAddNewPrimaryContactDiv>
        <StyledContactsAddNewInputDiv marginTop="0px" width="45%">
          <ContactsTextField
            fieldLabelText="first name"
            required={true}
            fieldValue={contactItem.firstName}
            setFieldValue={handleFirstNameChange}
            errorToggle={!contactItem.firstName}
            errorText={
              !contactItem.firstName ? "Please Enter First Name" : undefined
            }
          />
        </StyledContactsAddNewInputDiv>
        <StyledContactsAddNewInputDiv marginTop="0px" width="45%">
          <ContactsTextField
            fieldLabelText="last name"
            fieldValue={contactItem.lastName}
            setFieldValue={handleLastNameChange}
            errorToggle={!contactItem.lastName}
            errorText={
              !contactItem.lastName ? "Please Enter Last Name" : undefined
            }
          />
        </StyledContactsAddNewInputDiv>
      </StyledContactsAddNewPrimaryContactDiv>
      <StyledContactsAddNewPrimaryContactDiv>
        <StyledContactsAddNewInputDiv width="45%">
          <ContactsTextField
            fieldLabelText="email"
            fieldValue={contactItem.email}
            setFieldValue={handleEmailChange}
            errorToggle={!contactItem.email || !checkValidEmail()}
            errorText={
              !contactItem.lastName
                ? "Please Enter Email"
                : !checkValidEmail()
                ? "Please Enter Valid Email"
                : undefined
            }
          />
        </StyledContactsAddNewInputDiv>
        <StyledContactsAddNewInputDiv width="45%">
          <ContactsTextField
            fieldLabelText="phone number"
            required={true}
            fieldValue={contactItem.phone}
            setFieldValue={handlePhoneChange}
            errorToggle={!contactItem.phone || !checkValidPhone()}
            errorText={
              !contactItem.phone
                ? "Please Enter Phone Number"
                : !checkValidPhone()
                ? "Please Enter Valid Phone Number"
                : undefined
            }
          />
        </StyledContactsAddNewInputDiv>
      </StyledContactsAddNewPrimaryContactDiv>
    </>
  );
}

export default ContactsCompanyEditMenuContactComponent;
