import Button from "@mui/material/Button";
import { motion } from "framer-motion";
import styled from "styled-components";
import {
  DoxleFont,
  DoxleThemeColor,
} from "../../../DoxleGeneralStore/useDoxleThemeStore";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import { editRgbaAlpha } from "../../../Utilities/FunctionUtilities";

export const StyledEditNoteContainer = styled(motion.div)`
  width: calc(100% - 20rem);
  height: calc(100% - 5rem);
  display: flex;
  flex-direction: column;
  padding: 0 10rem 5rem 10rem;

  .content-wrapper {
    flex: 1;
    width: 100%;
    display: flex;
    margin-top: 20px;
    position: relative;
  }
`;
export const StyledCloseBtnContainer = styled.div`
  display: flex;
  justify-content: end;
`
export const StyledBackBtn = styled(motion(Button))<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  && {
    justify-content: center;

    min-width: 0px !important;
    align-items: center;
    display: flex;
    padding: 4px 8px 4px 0px !important;
    flex-direction: row;
    align-self: flex-start;
  }
  .btn-text {
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-style: normal;
    font-weight: 500;
    font-size: max(1.4rem, 14px);
    line-height: max(1.4rem, 14px);
    color: ${(p) => p.$themeColor.primaryFontColor};
    text-transform: capitalize;
    margin-left: 4px;
  }
  &:active {
    background-color: transparent !important;
  }
`;
interface ICustomTextFieldProps
  extends Omit<
    TextFieldProps<"standard">,
    "onDragStart" | "onAnimationStart" | "onDragEnd" | "onDrag"
  > {
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}
export const StyledNoteTitleTextField = styled(
  ({ $themeColor, $doxleFont, variant, ...props }: ICustomTextFieldProps) => (
    <TextField {...props} variant="standard" />
  )
)`
  && {
    overflow: hidden;
    min-width: 100px;
    width: calc(100%);
    padding: 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 14px;
  }
  input {
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-weight: 500;
    font-size: max(1.6rem, 16px);
    line-height: normal;

    color: ${(p) => p.$themeColor.primaryFontColor};
    padding: 0px !important;
    margin-left: 8px;
    &::placeholder {
      font-family: ${(p) => p.$doxleFont.primaryFont};
      font-weight: 500;
      font-size: max(1.6rem, 16px);
      line-height: normal;
      color: ${(p) => p.$themeColor.primaryInputPlaceholderColor};
      padding: 0px !important;
      opacity: 1;
    }
  }
  textarea {
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-weight: 500;
    font-size: max(1.6rem, 16px);
    line-height: normal;

    color: ${(p) => p.$themeColor.primaryFontColor};
    padding: 0px !important;
    margin-left: 8px;
    &::placeholder {
      font-family: ${(p) => p.$doxleFont.primaryFont};
      font-weight: 500;
      font-size: max(1.6rem, 16px);
      line-height: normal;
      color: ${(p) => p.$themeColor.primaryInputPlaceholderColor};
      padding: 0px !important;
      opacity: 1;
    }
  }
  & .MuiInput-root:after {
    border-bottom: none !important;
  }
  & .MuiInput-root:before {
    border-bottom: none !important;
  }
  & .MuiInput-root {
    border-bottom: none !important;
  }
  & .MuiInput-root:hover:not(.Mui-disabled, .Mui-error):before {
    border-bottom: none !important;
  }
  & .MuiInputBase-root {
    width: 100%;
  }
`;

export const StyledNoteLineSection = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`;
export const StyledNoteLineListScroller = styled.div`
  width: 100%;
  height: 100%;

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;
export const StyledNoteLineListFooter = styled(motion.div)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 0px !important;
  padding: 2px 0px;
  padding-top: 14px;
  border-bottom: 1px solid ${(p) => p.$themeColor.primaryDividerColor};
  position: relative;
`;

export const StyledNewLineDescriptionTextField = styled(
  ({
    $themeColor,
    $doxleFont,
    variant,
    $isBold,
    $isItalic,
    ...props
  }: ICustomTextFieldProps & {
    $isBold?: boolean;
    $isItalic?: boolean;
  }) => <TextField {...props} variant="standard" />
)`
  && {
    overflow: hidden;
    min-width: 100px;
    width: 100%;
    padding: 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  input {
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-weight: ${(p) => (p.$isBold ? "bold" : 400)};
    font-size: max(1.4rem, 14px);
    line-height: normal;
    font-style: ${(p) => (p.$isItalic ? "italic" : "normal")};
    color: ${(p) => p.$themeColor.primaryFontColor};
    padding: 0px !important;
    margin-left: 2px;
    &::placeholder {
      font-family: ${(p) => p.$doxleFont.primaryFont};
      font-weight: 400;
      font-size: max(1.4rem, 14px);
      line-height: normal;
      color: ${(p) => p.$themeColor.primaryInputPlaceholderColor};
      padding: 0px !important;
      opacity: 1;
    }
  }
  textarea {
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-weight: ${(p) => (p.$isBold ? "bold" : 400)};
    font-size: max(1.4rem, 14px);
    line-height: normal;
    font-style: ${(p) => (p.$isItalic ? "italic" : "normal")};
    color: ${(p) => p.$themeColor.primaryFontColor};
    padding: 0px !important;
    max-height: 70px;
    margin-left: 2px;
    &::placeholder {
      font-family: ${(p) => p.$doxleFont.primaryFont};
      font-weight: 400;
      font-size: max(1.4rem, 14px);
      line-height: normal;
      color: ${(p) => p.$themeColor.primaryInputPlaceholderColor};
      padding: 0px !important;
      opacity: 1;
    }
  }
  & .MuiInput-root:after {
    border-bottom: none !important;
  }
  & .MuiInput-root:before {
    border-bottom: none !important;
  }
  & .MuiInput-root {
    border-bottom: none !important;
  }
  & .MuiInput-root:hover:not(.Mui-disabled, .Mui-error):before {
    border-bottom: none !important;
  }
  & .MuiInputBase-root {
    width: 100%;
  }
`;
export const StyledAddNoteLineBtn = styled(motion.div)`
  padding: 1px;
  border-radius: 50%;
  margin-right: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const StyledEditLineModeButton = styled(motion(Button))<{
  $themeColor: DoxleThemeColor;
  $selected?: boolean;
}>`
  && {
    display: flex;
    flex-direction: row;
    min-width: 0px !important;
    cursor: pointer;
    margin: 0px 4px;
    height: 25px;
    width: 25px;
    padding: 5px !important;
    border-radius: 4px !important;
    background-color: ${(p) => p.$themeColor.primaryTextFieldBackgroundColor};
    text-transform: capitalize !important;
    border: 1px solid
      ${(p) => (p.$selected ? p.$themeColor.doxleColor : "rgba(0,0,0,0)")};
    &:hover {
      background-color: ${(p) =>
        p.$themeColor.primaryTextFieldBackgroundColor} !important;
      opacity: 0.95;
      transition: all 0.2s ease;
    }
  }
`;

export const StyledToolModeDisplay = styled(motion.div)<{
  $themeColor: DoxleThemeColor;
}>`
  display: flex;
  align-items: center;
  padding: 4px 8px;
  border-radius: 3px;
  border: 1px solid ${(p) => p.$themeColor.primaryDividerColor};
`;

export const StyledNoteLineItem = styled(motion.div)<{
  $themeColor: DoxleThemeColor;
}>`
  width: 100%;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${(p) => p.$themeColor.primaryDividerColor};
  padding: 2px 0px;
  position: relative;
  overflow: hidden;
  .input-wrapper {
    width: 100%;
    display: flex;
    align-items: center;

    .delete-icon {
      margin: 0px 8px;
      cursor: pointer;
    }
  }
`;

export const StyledLineItemDescriptionTextField = styled(
  ({
    $themeColor,
    $doxleFont,
    variant,
    $isBold,
    $isItalic,
    ...props
  }: ICustomTextFieldProps & {
    $isBold?: boolean;
    $isItalic?: boolean;
  }) => <TextField {...props} variant="standard" />
)`
  && {
    overflow: hidden;
    min-width: 100px;
    flex: 1;
    padding: 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  input {
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-weight: ${(p) => (p.$isBold ? "bold" : 400)};
    font-size: max(1.4rem, 14px);
    line-height: normal;
    font-style: ${(p) => (p.$isItalic ? "italic" : "normal")};
    color: ${(p) => p.$themeColor.primaryFontColor};
    padding: 0px !important;
    margin-left: 2px;
    &::placeholder {
      font-family: ${(p) => p.$doxleFont.primaryFont};
      font-weight: 400;
      font-size: max(1.4rem, 14px);
      line-height: normal;
      color: ${(p) => p.$themeColor.primaryInputPlaceholderColor};
      padding: 0px !important;
      opacity: 1;
    }
  }
  textarea {
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-weight: ${(p) => (p.$isBold ? "bold" : 400)};
    font-size: max(1.4rem, 14px);
    line-height: normal;
    font-style: ${(p) => (p.$isItalic ? "italic" : "normal")};
    color: ${(p) => p.$themeColor.primaryFontColor};
    padding: 0px !important;
    // max-height: 20px;
    margin-left: 2px;
    &::placeholder {
      font-family: ${(p) => p.$doxleFont.primaryFont};
      font-weight: 400;
      font-size: max(1.4rem, 14px);
      line-height: normal;
      color: ${(p) => p.$themeColor.primaryInputPlaceholderColor};
      padding: 0px !important;
      opacity: 1;
    }
  }
  & .MuiInput-root:after {
    border-bottom: none !important;
  }
  & .MuiInput-root:before {
    border-bottom: none !important;
  }
  & .MuiInput-root {
    border-bottom: none !important;
  }
  & .MuiInput-root:hover:not(.Mui-disabled, .Mui-error):before {
    border-bottom: none !important;
  }
  & .MuiInputBase-root {
    width: 100%;
  }
  & .MuiInputBase-input {
    overflow: hidden;
    tex-overflow: ellipsis;
  }
`;
