import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { BaseAPIProps } from "../../Models/baseAPIProps";
import DoxleAPI from "../../Services/DoxleAPI";
import { useDoxleCurrentContextStore } from "../../DoxleGeneralStore/useDoxleCurrentContext";
import { shallow } from "zustand/shallow";
import { useDoxleAuthStore } from "../../DoxleGeneralStore/useDoxleAuthStore";
import useDoxleNotificationStore from "../../DoxleGeneralStore/useDoxleNotificationStore";
import { Company, CompanySettings } from "../../Models/company";
import { AddCompanyInputValue } from "../../CoreContent/AddCompanyScreen/AddCompanyScreen";
import { AxiosResponse } from "axios";
import { IApiPaginatedData } from "../../Models/axiosReturn";

interface GetCompanyQueryProps extends BaseAPIProps {
  onSuccessCb?: (companyList?: Company[]) => void;
}

const useGetCompanyQuery = ({
  company,
  showNotification,
  onSuccessCb,
}: GetCompanyQueryProps) => {
  const qKey = ["company"];
  let companyURL = `/company/`;
  const { loggedIn, user } = useDoxleAuthStore(
    (state) => ({
      loggedIn: state.loggedIn,
      user: state.user,
    }),
    shallow
  );

  return useQuery(
    qKey,
    () =>
      DoxleAPI.get<IApiPaginatedData<Company>>(companyURL, {
        params: { ordering: "name" },
      }),
    {
      enabled: Boolean(loggedIn && user),
      retry: 1,
      staleTime: Infinity,
      cacheTime: Infinity,
      refetchOnWindowFocus: false,
      onSuccess: (res) => {
        // console.log("SUCCESS COMPANY:", res.data);
        if (onSuccessCb) onSuccessCb(res.data.results);
      },
    }
  );
};

const useRetrieveCompanySettings = () => {
  const company = useDoxleCurrentContextStore(
    (state) => state.currentCompany,
    shallow
  );
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );

  const qKey = ["company-settings", company?.companyId ?? ""];
  let companyURL = `/company/settings/${company?.companyId}/`;

  return useQuery(
    qKey,
    () =>
      DoxleAPI.get(companyURL, {
        headers: {
          "User-Company": company?.companyId ?? "",
        },
      }),
    {
      enabled: Boolean(company),
      retry: 1,
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      onError: (err: any) => {
        if (showNotification)
          showNotification(
            `[${
              err?.response?.status ?? "ERR"
            }]Failed To Retrieve Company Settings`,
            "error",
            String(err?.response?.data ?? "Unknown Error").substring(0, 300)
          );
      },
    }
  );
};

const useAddCompany = (onSuccessCb?: (company: Company) => void) => {
  const queryClient = useQueryClient();
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );

  let companyURL = `/company/`;
  return useMutation(
    getCompanyMutationKey("add"),
    (companyDetails: AddCompanyInputValue) => {
      const formData = new FormData();
      for (const [key, value] of Object.entries(companyDetails)) {
        if (value) formData.append(key, value);
      }
      return DoxleAPI.post<Company>(companyURL, formData, {
        headers: {},
      });
    },
    {
      retry: 1,
      onSuccess: (result: AxiosResponse<any>, variables, context) => {
        console.log("Success", result);
        queryClient.setQueryData(["company"], (oldData: any) => ({
          ...oldData,
          data: {
            ...oldData.data,
            results: [result.data, ...oldData.data.results],
          },
        }));
        // if (showNotification) showNotification(`New Company Saved`, "success");
        if (onSuccessCb) onSuccessCb(result.data);
      },
      onError: (err: any, variables, context) => {
        console.log("Error", err);
        if (showNotification)
          showNotification(
            `[${err?.response?.status ?? "ERR"}]Failed To Create Company`,
            "error",
            String(err?.response?.data ?? "Unknown Error").substring(0, 300)
          );
      },
    }
  );
};

const usePatchCompany = (onSuccessCb?: (company: Company) => void) => {
  const queryClient = useQueryClient();
  const currentEditCompany = useDoxleCurrentContextStore(
    (state) => state.currentEditCompany,
    shallow
  );
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );

  let companyURL = `/company/${currentEditCompany?.companyId}/`;
  return useMutation(
    getCompanyMutationKey("update"),
    (companyDetails: AddCompanyInputValue) => {
      const formData = new FormData();
      for (const [key, value] of Object.entries(companyDetails)) {
        if (value) formData.append(key, value);
      }
      return DoxleAPI.patch<Company>(companyURL, formData, {
        headers: {
          "User-Company": currentEditCompany?.companyId ?? "",
        },
      });
    },
    {
      retry: 1,
      onSuccess: (result, variables, context) => {
        console.log("Success", result);
        queryClient.setQueryData(["company"], (oldData: any) => ({
          ...oldData,
          data: {
            ...oldData.data,
            results: [
              ...oldData.data.results.map((company: Company) =>
                company.companyId === result.data.companyId
                  ? result.data
                  : company
              ),
            ],
          },
        }));
        // if (showNotification) showNotification(`Company Updated`, "success");
        if (onSuccessCb) onSuccessCb(result.data);
      },
      onError: (err: any, variables, context) => {
        console.log("Error", err);
        if (showNotification)
          showNotification(
            `[${err?.response?.status ?? "ERR"}]Failed To Update Company`,
            "error",
            String(err?.response?.data ?? "Unknown Error").substring(0, 300)
          );
      },
    }
  );
};

export interface updateCompanySettingProps {
  name: string;
  email: string;
  phone: string;
  abn: string;
  logo: File | null;
  companyType: string;
  mobilePhone: string;
  website: string;
}

const useUpdateCompanySettingDetail = (
  onSuccessCb?: (company: Company) => void
) => {
  const queryClient = useQueryClient();
  const currentCompany = useDoxleCurrentContextStore(
    (state) => state.currentCompany,
    shallow
  );
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );

  let companyURL = `/company/${currentCompany?.companyId}/`;
  return useMutation(
    getCompanyMutationKey("update"),
    (companyDetails: Partial<updateCompanySettingProps>) => {
      const formData = new FormData();
      for (const [key, value] of Object.entries(companyDetails)) {
        if (value) formData.append(key, value);
      }
      return DoxleAPI.patch<Company>(companyURL, formData, {
        headers: {
          "User-Company": currentCompany?.companyId ?? "",
        },
      });
    },
    {
      retry: 1,
      onSuccess: (result, variables, context) => {
        queryClient.setQueryData(["company"], (oldData: any) => ({
          ...oldData,
          data: {
            ...oldData.data,
            results: [
              ...oldData.data.results.map((company: Company) =>
                company.companyId === result.data.companyId
                  ? result.data
                  : company
              ),
            ],
          },
        }));
        if (onSuccessCb) onSuccessCb(result.data);
      },
      onError: (err: any, variables, context) => {
        console.log("Error", err);
        if (showNotification)
          showNotification(
            `[${
              err?.response?.status ?? "ERR"
            }]Failed To Update Company Details`,
            "error",
            String(err?.response?.data ?? "Unknown Error").substring(0, 300)
          );
      },
    }
  );
};

const useDeleteCompany = (onSuccessCb?: () => void) => {
  const queryClient = useQueryClient();
  const company = useDoxleCurrentContextStore(
    (state) => state.currentCompany,
    shallow
  );
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );

  return useMutation(
    getCompanyMutationKey("delete"),
    (companyId: string) => {
      return DoxleAPI.delete(`/company/${companyId}/`, {
        headers: {
          "User-Company": company?.companyId,
        },
      });
    },
    {
      retry: 1,
      onSuccess: (result, variables, context) => {
        console.log("Success", result);
        queryClient.setQueryData(["company"], (oldData: any) => ({
          ...oldData,
          data: {
            ...oldData.data,
            results: [
              ...oldData.data.results.filter(
                (company: Company) => company.companyId !== variables
              ),
            ],
          },
        }));
        // if (showNotification) showNotification(`Company Deleted`, "success");

        if (onSuccessCb) {
          onSuccessCb();
        }
      },
      onError: (err: any, variables, context) => {
        console.log("Error", err);
        if (showNotification)
          showNotification(
            `[${err?.response?.status ?? "ERR"}]Failed To Delete Company`,
            "error",
            String(err?.response?.data ?? "Unknown Error").substring(0, 300)
          );
      },
    }
  );
};

const useUpdateCompanySettings = () => {
  const queryClient = useQueryClient();
  const company = useDoxleCurrentContextStore(
    (state) => state.currentCompany,
    shallow
  );
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );

  const qKey = ["company"];
  let companyURL = `/company/settings/${company?.companyId}/`;

  return useMutation(
    qKey,
    (settings: Partial<CompanySettings>) =>
      DoxleAPI.get(companyURL, {
        headers: {
          "User-Company": company?.companyId ?? "",
        },
      }),
    {
      retry: 1,
      onSuccess: (result: AxiosResponse<any>, variables, context) => {
        queryClient.setQueryData(
          ["company-settings", company?.companyId ?? ""],
          (oldData: any) => ({
            ...oldData,
            data: result.data,
          })
        );
        // if (showNotification)
        //   showNotification(`Company Setting Updated`, "success");
      },
      onError: (err: any, variables, context) => {
        if (showNotification)
          showNotification(
            `[${
              err?.response?.status ?? "ERR"
            }]Failed To Retrieve Company Settings`,
            "error",
            String(err?.response?.data ?? "Unknown Error").substring(0, 300)
          );
      },
    }
  );
};

export const getCompanyMutationKey = (action: "add" | "delete" | "update") => [
  `${action}-company`,
];
const CompanyQueryAPI = {
  useGetCompanyQuery,
  useRetrieveCompanySettings,
  useUpdateCompanySettings,
  useAddCompany,
  useDeleteCompany,
  usePatchCompany,
  useUpdateCompanySettingDetail,
};

export default CompanyQueryAPI;
