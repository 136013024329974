import React, { useState } from "react";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import { StyledBallInCourtTag } from "./StyledNoticeBoardContent";
import Tooltip from "@mui/material/Tooltip";
import { editRgbaAlpha } from "../../../Utilities/FunctionUtilities";

type Props = {
  bicName: string;
};

const BallInCourtTag = ({ bicName }: Props) => {
  const [showToolTip, setshowToolTip] = useState(false);
  const { doxleThemeColor, doxleFont, currentTheme } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
      currentTheme: state.currentTheme,
    }),
    shallow
  );
  const splitName = bicName.split(" ");
  return (
    <Tooltip
      open={showToolTip}
      PopperProps={{
        sx: {
          zIndex: 10,
          "& .MuiTooltip-tooltip": {
            fontFamily: doxleFont.primaryFont,
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "max(1.2rem,12px)",

            color: `${editRgbaAlpha({
              rgbaColor: doxleThemeColor.primaryFontColor,
              alpha: "0.8",
            })} !important`,
            textTransform: " capitalize",
            backgroundColor: `${doxleThemeColor.primaryTextFieldBackgroundColor} !important`,
            border: `1px solid ${doxleThemeColor.primaryDividerColor} !important`,
            borderRadius: "4px",

            padding: "4px 8px",
          },
        },
      }}
      placement="bottom"
      title={bicName}
    >
      <StyledBallInCourtTag
        $themeColor={doxleThemeColor}
        $doxleFont={doxleFont}
        $currentTheme={currentTheme}
        onMouseEnter={(e) => {
          e.stopPropagation();

          setshowToolTip(true);
        }}
        onMouseLeave={() => setshowToolTip(false)}
      >
        @{splitName[0][0] ?? ""}
        {splitName[1][0] ?? ""}
      </StyledBallInCourtTag>
    </Tooltip>
  );
};

export default BallInCourtTag;
