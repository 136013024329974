import { shallow } from "zustand/shallow";
import { useDoxleThemeStore } from "../DoxleGeneralStore/useDoxleThemeStore";
import { pdfjs } from "react-pdf";
import {
  TransformComponent,
  TransformWrapper,
  useControls,
} from "react-zoom-pan-pinch";
import {
  ArrowNextReverseColor,
  ArrowPreviousReverseColor,
} from "../Drawings/Asset/DrawingIcons";
import useRefComponentDimension from "../CoreContent/UtilityHooks/useRefComponentDimension";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { editRgbaAlpha } from "../Utilities/FunctionUtilities";
import DoxleIconButton from "../DoxleDesignPattern/DoxleButtons/DoxleIconButton";
import { IoClose } from "react-icons/io5";
import DoxleEmptyPlaceHolder from "../DoxleDesignPattern/DoxleEmptyPlaceHolder/DoxleEmptyPlaceHolder";
import { Error404Banner } from "../DoxleDesignPattern/DoxleIcons/CommonIcons";
import {
  StyledImagePreviewDialog,
  StyledImagePreviewDialogContainer,
  StyledImagePreviewDialogTop,
  StyledImagePreviewPageImage,
  StyledImagePreviewPageNavigateLeftButton,
  StyledImagePreviewPageNavigateRightButton,
  StyledPreviewDialogPageNumber,
  StyledPreviewImageContainer,
  StyledPreviewImageTitle,
} from "./StyledInventoryImagePreview";
import { StyledDrawingsPreviewPageImage } from "../Drawings/Content/DrawingsPreviewPageStyledComponent";
import { useInventoryStore } from "./Store/useInventoryStore";
import { InventoryImage } from "./Models/Inventory";
import InventoryImageQueryAPI from "./QueryAPIHooks/InventoryImageAPI";
import Loading from "../Utilities/Lottie/Loading";
import { useShallow } from "zustand/react/shallow";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const InventoryImagePreview = () => {
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [isCurrentImgErr, setIsCurrentImgErr] = useState<boolean>(false);
  const [imgWidth, setImgWidth] = useState(0);
  const [imgHeight, setImgHeight] = useState(0);
  const imgEl = useRef<HTMLImageElement>(null);

  const { doxleThemeColor, doxleFont } = useDoxleThemeStore((state) => ({
    doxleThemeColor: state.doxleThemeColor,
    doxleFont: state.doxleFont,
  }));
  useEffect(() => {
    console.log(imgWidth, imgHeight);
  }, [imgWidth, imgHeight]);

  const { previewImageInventory, setPreviewImageInventory } = useInventoryStore(
    useShallow((state) => ({
      setPreviewImageInventory: state.setPreviewImageInventory,
      previewImageInventory: state.previewImageInventory,
    }))
  );
  const inventoryImageQuery = InventoryImageQueryAPI.useGetInventoryImageById(
    previewImageInventory?.inventoryId
  );

  const inventoryImg: InventoryImage[] = useMemo(() => {
    return inventoryImageQuery.data?.data.results ?? [];
  }, [inventoryImageQuery.data]);

  const handleClickPrevBtn = () => {
    if (currentIndex !== 0) {
      setCurrentIndex((prev) => prev - 1);
      setIsCurrentImgErr(false);
    }
  };
  const handleClickNextBtn = () => {
    if (currentIndex !== inventoryImg.length - 1) {
      setCurrentIndex((prev) => prev + 1);
      setIsCurrentImgErr(false);
    }
  };

  const dialogContainerRef = useRef<HTMLDivElement>(null);

  const DrawingPreviewControls = () => {
    const { zoomIn, zoomOut, resetTransform } = useControls();
    return (
      <StyledPreviewDialogPageNumber
        $themeColor={doxleThemeColor}
        $doxleFont={doxleFont}
      >
        <StyledImagePreviewPageNavigateLeftButton
          disableRipple
          style={{ backgroundColor: "transparent" }}
          onClick={() => {
            if (currentIndex > 0) resetTransform();
            handleClickPrevBtn();
          }}
        >
          <ArrowPreviousReverseColor themeColor={doxleThemeColor} />
        </StyledImagePreviewPageNavigateLeftButton>

        <span className="page-number-text">
          {currentIndex + 1} of {inventoryImg.length}
        </span>

        <StyledImagePreviewPageNavigateRightButton
          disableRipple
          onClick={() => {
            if (currentIndex < inventoryImg.length - 1) resetTransform();
            handleClickNextBtn();
          }}
        >
          <ArrowNextReverseColor themeColor={doxleThemeColor} />
        </StyledImagePreviewPageNavigateRightButton>
      </StyledPreviewDialogPageNumber>
    );
  };

  const { width, height } = useRefComponentDimension({
    componentRef: dialogContainerRef,
  });

  return (
    <StyledImagePreviewDialog
      slotProps={{
        backdrop: {
          style: {
            backgroundColor: editRgbaAlpha({
              rgbaColor: doxleThemeColor.primaryBackgroundColor,
              alpha: "0.8",
            }),
          },
        },
      }}
      open={true}
      onClose={() => setPreviewImageInventory(undefined)}
    >
      {inventoryImageQuery.isLoading && <Loading />}
      {inventoryImageQuery.isError && (
        <DoxleEmptyPlaceHolder
          headTitleText="Failed to load Inventory Images"
          containerBgColor="transparent"
          headTitleTextStyle={{
            fontSize: 20,
            color: "red",
          }}
          illustration={
            <Error404Banner
              themeColor={doxleThemeColor}
              containerStyle={{
                width: "30%",
              }}
            />
          }
        />
      )}
      {inventoryImg.length > 0 ? (
        <TransformWrapper>
          <StyledImagePreviewDialogContainer>
            <StyledImagePreviewDialogTop>
              <DrawingPreviewControls />
              <DoxleIconButton
                iconSource={
                  <IoClose
                    size="5rem"
                    color={doxleThemeColor.primaryFontColor}
                  />
                }
                buttonSize={50}
                buttonWrapperStyle={{
                  position: "absolute",
                  right: 0,
                }}
                onClick={() => setPreviewImageInventory(undefined)}
              />
            </StyledImagePreviewDialogTop>

            {!isCurrentImgErr ? (
              <StyledPreviewImageContainer ref={dialogContainerRef}>
                <TransformComponent wrapperStyle={{ overflow: "visible" }}>
                  <StyledImagePreviewPageImage
                    ref={imgEl}
                    src={inventoryImg[currentIndex].url}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    onError={() => setIsCurrentImgErr(true)}
                    $height={height}
                    $width={width}
                    $aspectRatio={imgWidth / imgHeight}
                    onLoad={() => {
                      setImgHeight(imgEl.current?.naturalHeight ?? 0);
                      setImgWidth(imgEl.current?.naturalWidth ?? 0);
                    }}
                  />
                </TransformComponent>
              </StyledPreviewImageContainer>
            ) : (
              <DoxleEmptyPlaceHolder
                headTitleText="Failed to load image, please try again later"
                containerBgColor="transparent"
                headTitleTextStyle={{
                  fontSize: 20,
                  color: "red",
                }}
                illustration={
                  <Error404Banner
                    themeColor={doxleThemeColor}
                    containerStyle={{
                      width: "30%",
                    }}
                  />
                }
              />
            )}
          </StyledImagePreviewDialogContainer>
        </TransformWrapper>
      ) : (
        "empty"
      )}
    </StyledImagePreviewDialog>
  );
};

export default InventoryImagePreview;
