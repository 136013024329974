import {
  StyledAddContactTopLeftDiv,
  StyledContactAddContactSection,
  StyledContactsAddAnotherContactButton,
  StyledContactsAddNewRootContainer,
  StyledContactsSubmitButton,
  StyledLoadingBottomContainer,
} from "./styledComponents";
import { shallow } from "zustand/shallow";
import useDoxleNotificationStore from "../../../DoxleGeneralStore/useDoxleNotificationStore";
import { useDoxleCurrentContextStore } from "../../../DoxleGeneralStore/useDoxleCurrentContext";
import { useCallback, useEffect, useState } from "react";
import {
  Contact,
  ContactCompany,
  CREATE_NEW_CONTACT_COMPANY_TEMPLATE,
  CREATE_NEW_CONTACT_TEMPLATE,
} from "../../../Models/addressBook";
import { useContactsStore } from "../Store/useContactsStore";
import ContactsAPI from "../../../Services/QueryHooks/contactsAPI";
import DoxleEmptyPlaceHolder from "../../../DoxleDesignPattern/DoxleEmptyPlaceHolder/DoxleEmptyPlaceHolder";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import ContactCompanyForm from "./ContactCompanyForm";
import ContactsCompanyEditMenuContactComponent from "./ContactsCompanyEditMenuContactComponent";
import ListLoadingMore from "../../../Utilities/Lottie/ListLoadingMore";
import { checkChangesContactCompany } from "../contactsHelperFunctions";
import useGetContactCompanyDetail from "../../../CoreContent/QueryDataHooks/GetQueryDataHooks/useGetContactCompanyDetail";

const AddContactCompanyDialog = ({}) => {
  const [newContactCompany, setNewContactCompany] = useState<ContactCompany>({
    ...CREATE_NEW_CONTACT_COMPANY_TEMPLATE({ name: "New Company Name..." }),
  });
  const [existedContacts, setExistedContacts] = useState<Contact[]>([]);
  const [newAddedContacts, setNewAddedContacts] = useState<Contact[]>([]);
  const [updatedContacts, setUpdatedContacts] = useState<Contact[]>([]); //THIS STATE WILL ADD THOSE UPDATED CONTACTS TO CALL API
  const company = useDoxleCurrentContextStore(
    (state) => state.currentCompany,
    shallow
  );
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );
  const {
    edittedCompany,
    setAddDialogType,
    setEdittedCompany,
    filterRetrieveContactCompanyListQuery,
  } = useContactsStore(
    (state) => ({
      edittedCompany: state.edittedCompany,
      setAddDialogType: state.setAddDialogType,
      setEdittedCompany: state.setEdittedCompany,
      filterRetrieveContactCompanyListQuery: state.contactCompanyQueryFilter,
    }),
    shallow
  );
  const hanleInitializeContactsState = useCallback(() => {
    if (edittedCompany) {
      setNewContactCompany({ ...edittedCompany });
      //! THIS CONTACT JSON ONLY BE RETRIEVED WHEN MAKING API CALL TO GET DETAILS OF CONTACT COMPANY, in this case, edittedCompany is the result of api call for contact company detail
      if (edittedCompany.contactsJson)
        setExistedContacts([...edittedCompany.contactsJson]);
    }
  }, [edittedCompany]);
  const styleProps = useDoxleThemeStore(
    (state) => ({
      $doxleFont: state.doxleFont,
      $themeColor: state.doxleThemeColor,
      $currentTheme: state.currentTheme,
    }),
    shallow
  );

  const {
    contactCompanyDetails,
    isErrorFetchingContactDetails,
    isSuccessFetchingContactDetails,
    isFetchingContactDetails,
  } = useGetContactCompanyDetail({
    contactCompanyId: edittedCompany?.contactCompanyId,
  });

  useEffect(() => {
    if (contactCompanyDetails && contactCompanyDetails.contactsJson)
      setExistedContacts([...contactCompanyDetails.contactsJson]);
  }, [contactCompanyDetails]);

  useEffect(() => {
    console.log("EDITTED COMPANY:", edittedCompany);
    hanleInitializeContactsState();
  }, [edittedCompany]);

  const handleSetExistedContacts = useCallback(
    (contactCompany: ContactCompany) => {
      if (
        contactCompany.contactsJson &&
        contactCompany.contactsJson.length > 0
      ) {
        setExistedContacts([...contactCompany.contactsJson]);
      } else if (
        contactCompany.contactsJson &&
        contactCompany.contactsJson.length > 0
      ) {
        setExistedContacts([...contactCompany.contactsJson]);
      }
    },
    []
  );

  //!THIS USEEFFECT LISTEN TO THE CHANGE WHEN AUTOCOMPLETE SELECT NEW CONTACT COMPANY
  useEffect(() => {
    handleSetExistedContacts(newContactCompany);
    setNewAddedContacts([]);
  }, [newContactCompany.contactCompanyId]);

  // Check the value of new Contacts using regex
  const contactsRegexCheck = useCallback(() => {
    const emailRegex = /.+\@.+\..+/;
    const numberRegex =
      /^(\+?\(61\)|\(\+?61\)|\+?61|\(0[1-9]\)|0[1-9])?( ?-?[0-9]){7,9}$/m;
    let errorCounter = 0;
    //# CHECK COMPANY
    Object.keys(newContactCompany).map((key) => {
      let keyItem = key as keyof ContactCompany;
      if (keyItem === "name" || keyItem === "address") {
        if (!newContactCompany[keyItem]) errorCounter += 1;
      }
    });

    existedContacts.forEach((contact) => {
      if (!emailRegex.test(contact.email)) errorCounter += 1;
      if (!numberRegex.test(contact.phone)) errorCounter += 1;
      if (!contact.firstName && !contact.lastName) errorCounter += 1;
    });

    console.log("ERROR:", errorCounter);
    if (errorCounter === 0) {
      return true;
    } else {
      showNotification(
        "Some Fields are invalid",
        "error",
        "Please check your form"
      );
      return false;
    }
  }, [newContactCompany, existedContacts]);
  const handleResetFields = () => {
    setEdittedCompany(undefined);
    setNewContactCompany({
      ...CREATE_NEW_CONTACT_COMPANY_TEMPLATE({ name: "New Company Name..." }),
    });
    setExistedContacts([]);
    setUpdatedContacts([]);
    setNewAddedContacts([]);
  };
  const handleClose = async () => {
    setAddDialogType(null);
    handleResetFields();
  };
  // Handle adding new prompts for contacts
  const handleAddAnother = () => {
    if (edittedCompany) {
      setExistedContacts((prev) => [
        ...prev,
        CREATE_NEW_CONTACT_TEMPLATE({
          companyAbn: edittedCompany.abn,
          companyName: edittedCompany.name,
          contactCompany: edittedCompany.contactCompanyId,
          companyAddress: edittedCompany.address,
        }),
      ]);
    } else
      setNewAddedContacts((prev) => [...prev, CREATE_NEW_CONTACT_TEMPLATE({})]);
  };
  //$$$$$$$$ POST NEW COMPANY AND CONTACTS IN COMPANY MENU QUERY  $$$$$$$$$$$
  const postContacts = ContactsAPI.useAddContactCompanyQuery({
    showNotification,

    company,
    filter: filterRetrieveContactCompanyListQuery,
  });
  const runPostContacts = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const contactsRegex = contactsRegexCheck();
    if (contactsRegex) {
      const postNewContacts = {
        name: newContactCompany.name,
        abn: newContactCompany.abn,
        address: newContactCompany.address,
        contacts: newAddedContacts,
      };
      postContacts.mutate(postNewContacts);
    }
    handleClose();
  };

  //$$$$$$$$ POST CONTACTS FOR EXISTING COMPANY IN COMPANY MENU QUERY $$$$$$$$$$$
  const postExistingCompanyContacts = ContactsAPI.usePatchExistingCompany({
    showNotification,

    company,
    filter: filterRetrieveContactCompanyListQuery,
  });
  const runPostExistingCompanyContacts = (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();
    // const contactsLength = contactsLengthCheck(true);
    const contactsRegex = contactsRegexCheck();
    // if (checker(contactsLength)) {
    if (contactsRegex && edittedCompany) {
      let changesContactCompany = checkChangesContactCompany({
        original: edittedCompany,
        editted: newContactCompany,
        updatedContacts: updatedContacts,
        newAddedContacts: newAddedContacts,
      });
      if (changesContactCompany) {
        postExistingCompanyContacts.mutate(changesContactCompany);
        handleClose();
      }
    }
  };

  //$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$

  useEffect(() => {
    return () => handleResetFields();
  }, []);
  return (
    <StyledContactsAddNewRootContainer {...styleProps}>
      <StyledAddContactTopLeftDiv>
        {!edittedCompany ? "ADD CONTACT" : "EDIT CONTACT"}
      </StyledAddContactTopLeftDiv>

      {
        <form
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "100%",
          }}
          onSubmit={(event) => {
            if (!edittedCompany) {
              runPostContacts(event);
            } else {
              runPostExistingCompanyContacts(event);
            }
          }}
        >
          <ContactCompanyForm
            newContactCompany={newContactCompany}
            setNewContactCompany={setNewContactCompany}
            action={edittedCompany ? "edit" : "add"}
          />

          <StyledContactAddContactSection>
            {existedContacts.length > 0 &&
              existedContacts.map((item, index) => {
                return (
                  <ContactsCompanyEditMenuContactComponent
                    contactItem={item}
                    itemIndex={index}
                    setExistedContacts={setExistedContacts}
                    setUpdatedContacts={setUpdatedContacts}
                    action={"edit"}
                  />
                );
              })}
            {newAddedContacts.length > 0 &&
              newAddedContacts.map((item, index) => {
                return (
                  <ContactsCompanyEditMenuContactComponent
                    contactItem={item}
                    itemIndex={index}
                    setNewAddedContacts={setNewAddedContacts}
                    action={"add"}
                  />
                );
              })}
            {existedContacts.length === 0 && newAddedContacts.length === 0 && (
              <DoxleEmptyPlaceHolder
                headTitleText="Add Contact To Company For Better Comunication"
                headTitleTextStyle={{ fontSize: 20 }}
                containerBgColor="transparent"
              />
            )}
          </StyledContactAddContactSection>

          <StyledContactsAddAnotherContactButton
            {...styleProps}
            disableRipple
            onClick={handleAddAnother}
          >
            Add Another Contact
          </StyledContactsAddAnotherContactButton>

          <StyledContactsSubmitButton
            {...styleProps}
            fullWidth={true}
            type="submit"
          >
            {!edittedCompany ? "SUBMIT" : "SAVE"}
          </StyledContactsSubmitButton>
        </form>
      }

      {/* {isFetchingContactDetails && (
        <ListLoadingMore
          containerStyle={{
            width: "100%",
            position: "absolute",
            bottom: 0,
            left: 0,
            display: "flex",
            justifyContent: "center",
          }}
        />
      )} */}
      {isErrorFetchingContactDetails && (
        <DoxleEmptyPlaceHolder
          headTitleText="Something Wrong!"
          subTitleText="Failed To get Contact Company Details! Please Reload Page..."
          headTitleTextStyle={{ fontSize: 24 }}
          subTitleTextStyle={{ fontSize: 14 }}
          containerBgColor="transparent"
        />
      )}
    </StyledContactsAddNewRootContainer>
  );
};

export default AddContactCompanyDialog;
