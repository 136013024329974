import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from "react";
import {
  StyledCloseBtnContainer,
  StyledDocketInfoContainer,
  StyledEditBookingConatiner,
  StyledEditBookingProject,
  StyledEditInfoWrapper,
} from "./StyledComponentEditBookingProject";
import { IBooking } from "../../../Models/booking";
import DoxleNavigateBackButton from "../../../../DoxleDesignPattern/DoxleButtons/DoxleNavigateBackButton";
import { IoChevronBackSharp } from "react-icons/io5";
import { useDoxleThemeStore } from "../../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import useEditBookingProject from "./Hooks/useEditBookingProject";
import EditBookingTitle from "./EditBookingTitle";
import EditBookingDocketAndDate from "./EditBookingDocketAndDate";
import EditBookingPercentage from "./EditBookingPercentage";
import BookingDocketDetails from "../BookingDocketDetail/BookingDocketDetails";
import useBookingProjectStore from "../../../store/useBookingProjectStore";
import useRefComponentDimension from "../../../../CoreContent/UtilityHooks/useRefComponentDimension";
import useBookingViewStore from "../../../store/useBookingViewStore";
import EditBookingInputSection from "./EditBookingInputSection";
import EditBookingDocketPortrait from "./EditBookingDocketPortrait";
import { IntRange } from "../../../../Models/IntRangeType";
import { StyledAddBookingButton } from "../AddBookingProject/StyledComponentAddBookingProject";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import DoxleCloseBtn from "../../../../DoxleDesignPattern/DoxleButtons/DoxleCloseBtn";
type Props = {};
interface EditProjectBookingContextValue {
  bookingTitle: string;
  setBookingTitle: React.Dispatch<React.SetStateAction<string>>;
  startDate: Date | null;
  setStartDate: React.Dispatch<React.SetStateAction<Date | null>>;
  endDate: Date | null;
  setEndDate: React.Dispatch<React.SetStateAction<Date | null>>;
  percentageComplete: IntRange<0, 101>;
  setPercentageComplete: React.Dispatch<React.SetStateAction<IntRange<0, 101>>>;
}
const EditProjectBookingContext =
  createContext<EditProjectBookingContextValue | null>(null);

const Transition = React.forwardRef(
  (
    props: TransitionProps & {
      children: React.ReactElement;
    },
    ref: React.Ref<unknown>
  ) => {
    return <Slide direction="left" ref={ref} {...props} />;
  }
);

const EditBookingProject = ({}: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
      currentTheme: state.currentTheme,
    }),
    shallow
  );
  const {
    handleClickBackBtn,
    bookingTitle,
    setBookingTitle,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    percentageComplete,
    setPercentageComplete,
    handleEditBooking,
  } = useEditBookingProject({});
  const contextValue: EditProjectBookingContextValue = useMemo(
    () => ({
      bookingTitle,
      setBookingTitle,
      startDate,
      setStartDate,
      endDate,
      setEndDate,
      percentageComplete,
      setPercentageComplete,
    }),
    [bookingTitle, startDate, endDate, percentageComplete]
  );

  const { edittedBooking } = useBookingProjectStore(
    (state) => ({
      edittedBooking: state.edittedBooking,
    }),
    shallow
  );

  const BookingEditScreenRef = useRef<HTMLDivElement>(null);

  const { width, height } = useRefComponentDimension({
    componentRef: BookingEditScreenRef,
  });

  const { screenMode, setScreenMode } = useBookingViewStore(
    (state) => ({
      setScreenMode: state.setCurrentScreenDisplay,
      screenMode: state.currentScreenDisplay,
    }),
    shallow
  );

  useEffect(() => {
    if (width < 700) {
      setScreenMode("Portrait");
    } else {
      setScreenMode("Landscape");
    }
  }, [width]);

  return (
    <Dialog
      fullScreen
      open={edittedBooking !== undefined}
      TransitionComponent={Transition}
      PaperProps={{
        style: { backgroundColor: doxleThemeColor.primaryContainerColor },
      }}
      sx={{ "& .MuiPaper-root": { overflow: "hidden !important" } }}
    >
      <StyledCloseBtnContainer onClick={handleClickBackBtn}>
        <DoxleCloseBtn sx={{ width: 60, height: 60 }} />
      </StyledCloseBtnContainer>

      <StyledEditBookingProject>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <EditProjectBookingContext.Provider value={contextValue}>
            {edittedBooking && (
              <StyledEditBookingConatiner
                $displayMode={screenMode}
                ref={BookingEditScreenRef}
              >
                {/* {screenMode === "Landscape" && (
        <>
          <StyledEditInfoWrapper $displayMode={screenMode}>
            <EditBookingInputSection />
          </StyledEditInfoWrapper>

          <StyledDocketInfoContainer $displayMode={screenMode}>
            <BookingDocketDetails
              docketId={edittedBooking?.docket}
              mode="edit"
            />
          </StyledDocketInfoContainer>
        </>
      )} 
      {screenMode === "Portrait" && (
      <EditBookingDocketPortrait edittedBooking={edittedBooking} />)
      } */}
                <EditBookingDocketPortrait edittedBooking={edittedBooking} />
                <StyledAddBookingButton
                  $themeColor={doxleThemeColor}
                  $doxleFont={doxleFont}
                  onClick={handleEditBooking}
                >
                  Save
                </StyledAddBookingButton>
              </StyledEditBookingConatiner>
            )}
          </EditProjectBookingContext.Provider>
        </LocalizationProvider>
      </StyledEditBookingProject>
    </Dialog>
  );
};
export const useEditProjectBookingContext = () =>
  useContext(EditProjectBookingContext) as EditProjectBookingContextValue;
export default EditBookingProject;
