import React, { useRef } from "react";
import {
  StyledBackBtn,
  StyledCloseBtnContainer,
  StyledEditNoteContainer,
  StyledNoteTitleTextField,
} from "./StyledEditNote";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import useEditNote from "./Hooks/useEditNote";
import { FaChevronLeft } from "react-icons/fa";
import { IoDocumentOutline } from "react-icons/io5";
import { useNoteStore } from "../../Store/useNoteStore";
import NoteLineSection from "./NoteLineSection";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import DoxleCloseBtn from "../../../DoxleDesignPattern/DoxleButtons/DoxleCloseBtn";
import DoxleMultilineTextField from "../../../DoxleDesignPattern/DoxleTextField/DoxleMultilineTextField";
type Props = {};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const EditNote = (props: Props) => {
  const { doxleThemeColor, doxleFont, transparentMode } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
      transparentMode: state.transparentMode,
    }),
    shallow
  );
  const { edittedNote } = useNoteStore(
    (state) => ({
      edittedNote: state.edittedNote,
    }),
    shallow
  );
  const {
    handleBackBtnClick,
    newNoteTitle,
    setNewNoteTitle,
    handleKeydownTitleTextField,
    handleUpdateNote,
    titleInputRef,
  } = useEditNote({});

  return (
    <Dialog
      fullScreen
      open={edittedNote !== undefined}
      TransitionComponent={Transition}
      PaperProps={{
        style: { backgroundColor: doxleThemeColor.primaryBackgroundColor },
      }}
      sx={{ "& .MuiPaper-root": { overflow: "hidden !important" } }}
    >
      <DoxleCloseBtn
        onClick={handleBackBtnClick}
        sx={{ width: 60, height: 60, alignSelf: "flex-end" }}
      />

      <StyledEditNoteContainer
        animate={{
          y: [20, 0],
          opacity: [0, 1],
          transition: {
            damping: 16,
          },
        }}
      >
        <DoxleMultilineTextField
          placeholder="Note title..."
          inputRef={titleInputRef}
          value={newNoteTitle}
          onChange={(event) => setNewNoteTitle(event.target.value)}
          onFocus={() => titleInputRef.current?.select()}
          onBlur={handleUpdateNote}
          onKeyDown={handleKeydownTitleTextField}
          style={{backgroundColor:"transparent", height:"auto"}}
          sx={[
            {
              textarea: {
                fontSize: "1.6rem",
                "&::placeholder": { fontSize: "1.6rem" },
              },
            },
          ]}
          onClick={(e)=>{e.stopPropagation()}}
          maxRows={3}
          InputProps={{
            startAdornment: (
              <IoDocumentOutline
                color={doxleThemeColor.primaryFontColor}
                size={30}
              />
            ),
          }}
        />
        <div className="content-wrapper">
          {edittedNote && <NoteLineSection noteId={edittedNote.noteId} />}
        </div>
      </StyledEditNoteContainer>
    </Dialog>
  );
};

export default EditNote;
