import React, { useCallback, useEffect, useMemo, useState } from "react";
import { NewMail } from "../../../Models/mail";
import { Contact } from "../../../../Models/addressBook";
import { useEditDocketSideScreenStore } from "../../../../CoreContent/EditDocketSideScreen/EditDocketSideScreenStore/useEditDocketSideScreenStore";
import { shallow } from "zustand/shallow";
import { useDoxleCurrentContextStore } from "../../../../DoxleGeneralStore/useDoxleCurrentContext";
import useDoxleNotificationStore from "../../../../DoxleGeneralStore/useDoxleNotificationStore";
import { useMailStore } from "../../../Store/mailStore";
import { TISODate } from "../../../../Models/dateFormat";
import MailQueryAPI from "../../../Hooks/mailAPI";
import { produce } from "immer";
import { DropEvent, FileRejection } from "react-dropzone";

type Props = { docket?: true; project?: boolean };
interface ISelectedInputText {
  start: number;
  end: number;
}
interface IAddMail {
  attachments: File[];
  setAttachments: React.Dispatch<React.SetStateAction<File[]>>;
  watchers: Contact[];
  handleUpdateNewMail: (props: {
    key: keyof NewMail;
    value: TISODate | string;
  }) => void;
  handleToContactAutocompleteChange: (value: Contact | undefined) => void;
  handleAddWatcher: (value: Contact) => void;
  handleRemoveWatcher: (value: Contact) => void;
  handleClickAddBtn: () => void;
  newMail: NewMail;
  ballInCourt: Contact | undefined;
  setBallInCourt: React.Dispatch<React.SetStateAction<Contact | undefined>>;
  boldMode: boolean;
  setBoldMode: React.Dispatch<React.SetStateAction<boolean>>;
  italicMode: boolean;
  setItalicMode: React.Dispatch<React.SetStateAction<boolean>>;
  onDrop: <T extends File>(
    acceptedFiles: T[],
    fileRejections: FileRejection[],
    event: DropEvent
  ) => void;
  isAddingEmail: boolean;

  resetInputEmail: () => void;
}

const useAddMail = ({ docket, project }: Props): IAddMail => {
  const [attachments, setAttachments] = useState<File[]>([]);
  const [watchers, setWatchers] = useState<Contact[]>([]);
  const [ballInCourt, setBallInCourt] = useState<Contact | undefined>(
    undefined
  );
  const [boldMode, setBoldMode] = useState(false);
  const [italicMode, setItalicMode] = useState(false);

  const edittedDocket = useEditDocketSideScreenStore(
    (state) => state.edittedDocket,
    shallow
  );
  const currentProject = useDoxleCurrentContextStore(
    (state) => state.currentProject,
    shallow
  );
  const initialMail: NewMail = useMemo(
    () => ({
      dueDate: null,
      textBody: "",
      subject: "",
      project: docket
        ? edittedDocket?.project ?? null
        : project
        ? currentProject?.projectId
        : null,
      docket: docket ? edittedDocket?.docketPk ?? null : null,
      ballInCourt: "",
      watchers: [],
      attachments: [],
    }),
    [docket, project, currentProject, edittedDocket]
  );

  const [newMail, setnewMail] = useState<NewMail>({ ...initialMail });
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );

  const setOnAddNewMailMode = useMailStore(
    (state) => state.setOnAddNewMailMode,
    shallow
  );
  const addNewProps = useMailStore(
    (state) => ({
      queryFilter: state.queryFilter,
      onSuccessCb: () => {
        resetInputEmail();
        setOnAddNewMailMode(false);
      },
    }),
    shallow
  );
  const resetInputEmail = () => {
    setnewMail(initialMail);
    setAttachments([]);
    setWatchers([]);
    setBallInCourt(undefined);
    setBoldMode(false);
    setItalicMode(false);
  };

  const handleUpdateNewMail = (props: {
    key: keyof NewMail;
    value: TISODate | string;
  }) => {
    const { key, value } = props;
    setnewMail((prev) =>
      produce(prev, (draft) => {
        if (key === "subject") draft.subject = value;
        else if (key === "textBody") draft.textBody = value;
        else if (key === "ballInCourt") draft.ballInCourt = value;
        return draft;
      })
    );
  };
  const handleToContactAutocompleteChange = (value: Contact | undefined) => {
    setnewMail((prev) =>
      produce(prev, (draft) => {
        draft.ballInCourt = value?.contactId ?? "";
        return draft;
      })
    );
  };

  const handleAddWatcher = (value: Contact) => {
    setWatchers((prevState) =>
      produce(prevState, (draft) => {
        if (
          !prevState.find((watcher) => watcher.contactId === value.contactId)
        ) {
          draft.push(value);
        }
        return draft;
      })
    );
  };

  const handleRemoveWatcher = (value: Contact) => {
    setWatchers((prevState) =>
      produce(prevState, (draft) => {
        draft = draft.filter((c) => c.contactId !== value.contactId);
        return draft;
      })
    );
  };

  //*** SEND RFI EMAIL Query *** */
  const sendEmailQuery = MailQueryAPI.useSendNewEmailQuery(addNewProps);
  //*** END SEND RFI EMAIL *** */

  const handleClickAddBtn = () => {
    if (ballInCourt && newMail.subject && newMail.textBody)
      sendEmailQuery.mutate({
        ...newMail,
        ballInCourt: ballInCourt.contactId,
        attachments: attachments,
        watchers: watchers.map((contact) => contact.contactId),
      });
    else showNotification("Please Fill In All Value", "error");
  };

  const onDrop = useCallback(
    <T extends File>(
      acceptedFiles: T[],
      fileRejections: FileRejection[],
      event: DropEvent
    ) => {
      setAttachments(
        produce((prev) => {
          prev.push(...acceptedFiles);
          return prev;
        })
      );
    },
    []
  );
  useEffect(() => {
    console.log("edittedDocket:", edittedDocket);
  }, [edittedDocket, docket, project]);

  return {
    attachments,
    setAttachments,
    watchers,
    handleUpdateNewMail,
    handleToContactAutocompleteChange,
    handleAddWatcher,
    handleRemoveWatcher,
    handleClickAddBtn,
    newMail,
    ballInCourt,
    setBallInCourt,
    boldMode,
    setBoldMode,
    italicMode,
    setItalicMode,
    onDrop,
    isAddingEmail: sendEmailQuery.isLoading,

    resetInputEmail,
  };
};

export default useAddMail;
