import {
  StyledCheckBoxColumn,
  StyledCheckBoxColumnContainer,
  StyledEditPermissionsDropDownMenu,
  StyledMenuItem, StyledPermissionCheckbox,
  StyledPermissionCheckboxRow
} from "./SyledComponents";
import {CheckedIcon, UncheckedIcon} from "../../DoxleDesignPattern/DoxleCheckbox/Icons";
import React, {useRef} from "react";
import {useDoxleThemeStore} from "../../DoxleGeneralStore/useDoxleThemeStore";
import {shallow} from "zustand/shallow";
import useDoxleShareDialogStore from "../../DoxleGeneralStore/useDoxleShareDialogStore";
import DoxleCheckbox from "../../DoxleDesignPattern/DoxleCheckbox/DoxleCheckbox";
import {editRgbaAlpha} from "../../Utilities/FunctionUtilities";
import PermissionsAPI from "../../Services/QueryHooks/permissionsAPI";
import {useDoxleCurrentContextStore} from "../../DoxleGeneralStore/useDoxleCurrentContext";
import useDoxleNotificationStore from "../../DoxleGeneralStore/useDoxleNotificationStore";
import {CompanyPermission, CompanyPermissionKey, DocketPermission, ProjectPermission} from "../../Models/permissions";

const DropDownPermissions = () => {
  const company = useDoxleCurrentContextStore((state)=>state.currentCompany, shallow)
  const showNotification = useDoxleNotificationStore((state) => state.showNotification, shallow);

  const {anchorEl, handleSetDropDownMenu, currentPermission, updateCurrentPermission} = useDoxleShareDialogStore((state) => ({
    anchorEl: state.permissionDropDownAncorEl,
    handleSetDropDownMenu: state.handleSetDropDownMenu,
    currentPermission: state.currentPermission,
    updateCurrentPermission: state.updateCurrentPermission
  }), shallow)

  const styleProps = useDoxleThemeStore(
    (state) => ({
      $themeColor: state.doxleThemeColor,
      $doxleFont: state.doxleFont,
      $currentTheme: state.currentTheme,
  }), shallow);

  // const editScopeRef = useRef<HTMLInputElement>(null)
  // const editBudgetRef = useRef<HTMLInputElement>(null)
  // const editOrdersRef = useRef<HTMLInputElement>(null)
  // const editQaRef = useRef<HTMLInputElement>(null)
  // const editFilesRef = useRef<HTMLInputElement>(null)
  // const editBookingsRef = useRef<HTMLInputElement>(null)
  // const editMailsRef = useRef<HTMLInputElement>(null)
  // const editQuotesRef = useRef<HTMLInputElement>(null)
  // const viewScopeRef = useRef<HTMLInputElement>(null)
  // const viewBudgetRef = useRef<HTMLInputElement>(null)
  // const viewOrdersRef = useRef<HTMLInputElement>(null)
  // const viewQaRef = useRef<HTMLInputElement>(null)
  // const viewFilesRef = useRef<HTMLInputElement>(null)
  // const viewBookingsRef = useRef<HTMLInputElement>(null)
  // const viewMailsRef = useRef<HTMLInputElement>(null)
  // const viewQuotesRef = useRef<HTMLInputElement>(null)

  const checkboxLabelStyle = {
    "& .MuiTypography-root": {
      display: 'flex',
      width: '100%',
      flexDirection: 'row',
      color: editRgbaAlpha({
        rgbaColor: styleProps.$themeColor?.primaryFontColor,
        alpha: "0.7"
      }),
      fontWeight: 400,
      padding: '2px 0px',
      fontSize: 13,
      fontFamily: styleProps.$doxleFont?.primaryFont,
      cursor: 'pointer',
    }
  }

  const useMutatePermission = PermissionsAPI.useMutatePermission({
    company,

    showNotification,
    onSuccessCb: () =>  handleSetDropDownMenu(null, null)
  })

  const handleLimited = () => {
    if (currentPermission === null) { handleSetDropDownMenu(null, null); return }
    let updatedPermission:any = {}
    for (const [key , value] of Object.entries(currentPermission)) {
      if (key === "company" || key === "project" || key === "docket" || key === "user" && value !== undefined)
        updatedPermission[key] = value
      else if (key !== "contact")
        updatedPermission[key] = false
    }
    useMutatePermission.updatePermission.mutate(updatedPermission as Partial<CompanyPermission|ProjectPermission|DocketPermission>);
  }

  const handleFullAccess = () => {
    if (currentPermission === null) { handleSetDropDownMenu(null, null); return }
    let updatedPermission:any = {}
    for (const [key , value] of Object.entries(currentPermission)) {
      if (key === "company" || key === "project" || key === "docket" || key === "user" && value !== undefined)
        updatedPermission[key] = value
      else if (key !== "contact")
        updatedPermission[key] = true
    }
    useMutatePermission.updatePermission.mutate(updatedPermission as Partial<CompanyPermission|ProjectPermission|DocketPermission>)
  }

  const handleRemove = () => {
    if (currentPermission === null) { handleSetDropDownMenu(null, null); return }
    useMutatePermission.deletePermission.mutate(currentPermission)
  }

  const handleClose = () => {
    if (currentPermission === null) { handleSetDropDownMenu(null, null); return }
    useMutatePermission.updatePermission.mutate(currentPermission)
  }

  if (!(anchorEl && currentPermission)) return null;
  return (
    <StyledEditPermissionsDropDownMenu
      {...styleProps}
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={true}
      onClose={handleClose}
    >
      <StyledMenuItem {...styleProps} $clickable={true} onClick={handleFullAccess}>
        FullAccess
        <StyledPermissionCheckboxRow {...styleProps} >
          Can edit and share dockets
        </StyledPermissionCheckboxRow>
      </StyledMenuItem>
      <StyledMenuItem {...styleProps} >
        Can Edit
        <StyledCheckBoxColumnContainer {...styleProps} >
          <StyledCheckBoxColumn {...styleProps} >
            <StyledPermissionCheckboxRow {...styleProps} >
              <DoxleCheckbox
                // inputRef={editScopeRef}
                // defaultChecked={currentPermission.addSpecifications}
                checked={currentPermission.addSpecifications}
                onChangeCallback={(e: React.ChangeEvent<HTMLInputElement>) => {
                  updateCurrentPermission({
                    addSpecifications: e.target.checked,
                    deleteSpecifications: e.target.checked,
                    viewSpecifications: e.target.checked || currentPermission?.viewSpecifications
                  })
                }}
                icon={<UncheckedIcon {...styleProps.$themeColor} />}
                checkedIcon={<CheckedIcon {...styleProps.$themeColor} />}
                labelText="Scope of works"
                labelStyle={checkboxLabelStyle}
              />
            </StyledPermissionCheckboxRow>
            <StyledPermissionCheckboxRow>
              <DoxleCheckbox
                // inputRef={editBudgetRef}
                // defaultChecked={currentPermission.addEstimate}
                checked={currentPermission.addEstimate}
                onChangeCallback={(e: React.ChangeEvent<HTMLInputElement>) => {
                  updateCurrentPermission({
                    addEstimate: e.target.checked,
                    deleteEstimate: e.target.checked,
                    viewEstimate: e.target.checked || currentPermission?.viewEstimate
                  })
                }}
                icon={<UncheckedIcon {...styleProps.$themeColor} />}
                checkedIcon={<CheckedIcon {...styleProps.$themeColor} />}
                labelText="Budgets"
                labelStyle={checkboxLabelStyle}
              />
            </StyledPermissionCheckboxRow>
            <StyledPermissionCheckboxRow {...styleProps} >
              <DoxleCheckbox
                // inputRef={editOrdersRef}
                // defaultChecked={currentPermission.addOrders}
                checked={currentPermission.addOrders}
                onChangeCallback={(e: React.ChangeEvent<HTMLInputElement>) => {
                  updateCurrentPermission({
                    addOrders: e.target.checked,
                    deleteOrders: e.target.checked,
                    viewOrders: e.target.checked || currentPermission?.viewOrders
                  })
                }}
                icon={<UncheckedIcon {...styleProps.$themeColor} />}
                checkedIcon={<CheckedIcon {...styleProps.$themeColor} />}
                labelText="Orders"
                labelStyle={checkboxLabelStyle}
              />
            </StyledPermissionCheckboxRow>
            <StyledPermissionCheckboxRow {...styleProps} >
              <DoxleCheckbox
                // inputRef={editQaRef}
                // defaultChecked={currentPermission.addDefects}
                checked={currentPermission.addDefects}
                onChangeCallback={(e: React.ChangeEvent<HTMLInputElement>) => {
                  updateCurrentPermission({
                    addDefects: e.target.checked,
                    deleteDefects: e.target.checked,
                    viewDefects: e.target.checked || currentPermission?.viewDefects
                  })
                }}
                icon={<UncheckedIcon {...styleProps.$themeColor} />}
                checkedIcon={<CheckedIcon {...styleProps.$themeColor} />}
                labelText="QA"
                labelStyle={checkboxLabelStyle}
              />
            </StyledPermissionCheckboxRow>
          </StyledCheckBoxColumn>
          <StyledCheckBoxColumn {...styleProps} >
            <StyledPermissionCheckboxRow {...styleProps} >
              <DoxleCheckbox
                // inputRef={editFilesRef}
                // defaultChecked={currentPermission.addFiles}
                checked={currentPermission.addFiles}
                onChangeCallback={(e: React.ChangeEvent<HTMLInputElement>) => {
                  updateCurrentPermission({
                    addFiles: e.target.checked,
                    deleteFiles: e.target.checked,
                    viewFiles: e.target.checked || currentPermission?.viewFiles
                  })
                }}
                icon={<UncheckedIcon {...styleProps.$themeColor} />}
                checkedIcon={<CheckedIcon {...styleProps.$themeColor} />}
                labelText="Files"
                labelStyle={checkboxLabelStyle}
              />
            </StyledPermissionCheckboxRow>
            <StyledPermissionCheckboxRow {...styleProps} >
              <DoxleCheckbox
                // inputRef={editBookingsRef}
                // defaultChecked={currentPermission?.addBookings ?? false}
                checked={currentPermission.addBookings ?? false}
                onChangeCallback={(e: React.ChangeEvent<HTMLInputElement>) => {
                  updateCurrentPermission({
                    addBookings: e.target.checked,
                    deleteBookings: e.target.checked,
                    viewBookings: e.target.checked || currentPermission?.viewBookings
                  })
                }}
                icon={<UncheckedIcon {...styleProps.$themeColor} />}
                checkedIcon={<CheckedIcon {...styleProps.$themeColor} />}
                labelText="Bookings"
                labelStyle={checkboxLabelStyle}
              />
            </StyledPermissionCheckboxRow>
            <StyledPermissionCheckboxRow {...styleProps} >
              <DoxleCheckbox
                // inputRef={editMailsRef}
                // defaultChecked={currentPermission.addRfi}
                checked={currentPermission.addRfi ?? false}
                onChangeCallback={(e: React.ChangeEvent<HTMLInputElement>) => {
                  updateCurrentPermission({
                    addRfi: e.target.checked,
                    deleteRfi: e.target.checked,
                    viewRfi: e.target.checked || currentPermission?.viewRfi
                  })
                }}
                icon={<UncheckedIcon {...styleProps.$themeColor} />}
                checkedIcon={<CheckedIcon {...styleProps.$themeColor} />}
                labelText="Mails"
                labelStyle={checkboxLabelStyle}
              />
            </StyledPermissionCheckboxRow>
            <StyledPermissionCheckboxRow {...styleProps} >
              <DoxleCheckbox
                // inputRef={editQuotesRef}
                // defaultChecked={currentPermission.addQuotes}
                checked={currentPermission.addQuotes ?? false}
                onChangeCallback={(e: React.ChangeEvent<HTMLInputElement>) => {
                  updateCurrentPermission({
                    addQuotes: e.target.checked,
                    deleteQuotes: e.target.checked,
                    viewQuotes: e.target.checked || currentPermission?.viewQuotes
                  })
                }}
                icon={<UncheckedIcon {...styleProps.$themeColor} />}
                checkedIcon={<CheckedIcon {...styleProps.$themeColor} />}
                labelText="Quotes"
                labelStyle={checkboxLabelStyle}
              />
            </StyledPermissionCheckboxRow>
          </StyledCheckBoxColumn>
        </StyledCheckBoxColumnContainer>
      </StyledMenuItem>
      <StyledMenuItem {...styleProps} >
        Can View
        <StyledCheckBoxColumnContainer {...styleProps} >
          <StyledCheckBoxColumn {...styleProps} >
            <StyledPermissionCheckboxRow {...styleProps} >
              <DoxleCheckbox
                // inputRef={viewScopeRef}
                // defaultChecked={currentPermission.viewSpecifications}
                checked={currentPermission.viewSpecifications ?? false}
                onChangeCallback={(e: React.ChangeEvent<HTMLInputElement>) => {
                  updateCurrentPermission({
                    viewSpecifications: e.target.checked
                  })
                }}
                icon={<UncheckedIcon {...styleProps.$themeColor} />}
                checkedIcon={<CheckedIcon {...styleProps.$themeColor} />}
                labelText="Scope of works"
                labelStyle={checkboxLabelStyle}
              />
            </StyledPermissionCheckboxRow >
            <StyledPermissionCheckboxRow {...styleProps} >
              <DoxleCheckbox
                // inputRef={viewBudgetRef}
                // defaultChecked={currentPermission.viewEstimate}
                checked={currentPermission.viewEstimate ?? false}
                onChangeCallback={(e: React.ChangeEvent<HTMLInputElement>) => {
                  updateCurrentPermission({
                    viewEstimate: e.target.checked
                  })
                }}
                icon={<UncheckedIcon {...styleProps.$themeColor} />}
                checkedIcon={<CheckedIcon {...styleProps.$themeColor} />}
                labelText="Budgets"
                labelStyle={checkboxLabelStyle}
              />
            </StyledPermissionCheckboxRow >
            <StyledPermissionCheckboxRow {...styleProps} >
              <DoxleCheckbox
                // inputRef={viewOrdersRef}
                // defaultChecked={currentPermission.viewOrders}
                checked={currentPermission.viewOrders ?? false}
                onChangeCallback={(e: React.ChangeEvent<HTMLInputElement>) => {
                  updateCurrentPermission({
                    viewOrders: e.target.checked
                  })
                }}
                icon={<UncheckedIcon {...styleProps.$themeColor} />}
                checkedIcon={<CheckedIcon {...styleProps.$themeColor} />}
                labelText="Orders"
                labelStyle={checkboxLabelStyle}
              />
            </StyledPermissionCheckboxRow>
            <StyledPermissionCheckboxRow  {...styleProps}>
              <DoxleCheckbox
                // inputRef={viewQaRef}
                // defaultChecked={currentPermission.viewDefects}
                checked={currentPermission.viewDefects ?? false}
                onChangeCallback={(e: React.ChangeEvent<HTMLInputElement>) => {
                  updateCurrentPermission({
                    viewDefects: e.target.checked
                  })
                }}
                icon={<UncheckedIcon {...styleProps.$themeColor} />}
                checkedIcon={<CheckedIcon {...styleProps.$themeColor} />}
                labelText="QA"
                labelStyle={checkboxLabelStyle}
              />
            </StyledPermissionCheckboxRow>
          </StyledCheckBoxColumn>
          <StyledCheckBoxColumn  {...styleProps}>
            <StyledPermissionCheckboxRow  {...styleProps}>
              <DoxleCheckbox
                // inputRef={viewFilesRef}
                // defaultChecked={currentPermission.viewFiles}
                checked={currentPermission.viewFiles ?? false}
                onChangeCallback={(e: React.ChangeEvent<HTMLInputElement>) => {
                  updateCurrentPermission({
                    viewFiles: e.target.checked
                  })
                }}
                icon={<UncheckedIcon {...styleProps.$themeColor} />}
                checkedIcon={<CheckedIcon {...styleProps.$themeColor} />}
                labelText="Files"
                labelStyle={checkboxLabelStyle}
              />
            </StyledPermissionCheckboxRow>
            <StyledPermissionCheckboxRow  {...styleProps}>
              <DoxleCheckbox
                // inputRef={viewBookingsRef}
                // defaultChecked={currentPermission?.viewBookings ?? false}
                checked={currentPermission.viewBookings ?? false}
                onChangeCallback={(e: React.ChangeEvent<HTMLInputElement>) => {
                  updateCurrentPermission({
                    viewBookings: e.target.checked
                  })
                }}
                icon={<UncheckedIcon {...styleProps.$themeColor} />}
                checkedIcon={<CheckedIcon {...styleProps.$themeColor} />}
                labelText="Bookings"
                labelStyle={checkboxLabelStyle}
              />
            </StyledPermissionCheckboxRow>
            <StyledPermissionCheckboxRow  {...styleProps}>
              <DoxleCheckbox
                // inputRef={viewMailsRef}
                // defaultChecked={currentPermission.viewRfi}
                checked={currentPermission.viewRfi ?? false}
                onChangeCallback={(e: React.ChangeEvent<HTMLInputElement>) => {
                  updateCurrentPermission({
                    viewRfi: e.target.checked
                  })
                }}
                icon={<UncheckedIcon {...styleProps.$themeColor} />}
                checkedIcon={<CheckedIcon {...styleProps.$themeColor} />}
                labelText="Mails"
                labelStyle={checkboxLabelStyle}
              />
            </StyledPermissionCheckboxRow>
            <StyledPermissionCheckboxRow  {...styleProps}>
              <DoxleCheckbox
                // inputRef={viewQuotesRef}
                // defaultChecked={currentPermission.viewQuotes}
                checked={currentPermission.viewQuotes ?? false}
                onChangeCallback={(e: React.ChangeEvent<HTMLInputElement>) => {
                  updateCurrentPermission({
                    viewQuotes: e.target.checked
                  })
                }}
                icon={<UncheckedIcon {...styleProps.$themeColor} />}
                checkedIcon={<CheckedIcon {...styleProps.$themeColor} />}
                labelText="Quotes"
                labelStyle={checkboxLabelStyle}
              />
            </StyledPermissionCheckboxRow>
          </StyledCheckBoxColumn>
        </StyledCheckBoxColumnContainer>
      </StyledMenuItem>
      <StyledMenuItem $clickable={true} onClick={handleLimited}  {...styleProps}>
        Limited
        <StyledPermissionCheckboxRow  {...styleProps}>
          Can only view whats been assigned and
          add comments
        </StyledPermissionCheckboxRow>
      </StyledMenuItem>
      <StyledMenuItem $color={"red"} $clickable={true} onClick={handleRemove}  {...styleProps}>
        Remove
      </StyledMenuItem>
    </StyledEditPermissionsDropDownMenu>
  )
}

export default DropDownPermissions
