import React, { useState } from "react";
import DoxleDialogHelper from "../../DoxleDesignPattern/DoxleDialogHelper/DoxleDialogHelper";
import { shallow } from "zustand/shallow";
import { CircularProgress } from "@mui/material";
import { useInventoryStore } from "../Store/useInventoryStore";
import InventoryQueryAPI from "../QueryAPIHooks/InventoryQueryAPI";
import useDoxleNotificationStore from "../../DoxleGeneralStore/useDoxleNotificationStore";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";

type Props = {};

const InventoryDeleteConfirmDialog = (props: Props) => {

  const { inventoryForDelete, setInventoryForDelete, setCurrentEditInventory, setIsDeletingItem,  isDeletingItem} = useInventoryStore(
    (state) => ({
      inventoryForDelete: state.inventoryForDelete,
      setInventoryForDelete: state.setInventoryForDelete,
      setCurrentEditInventory: state.setCurrentEditInventory,
      isDeletingItem: state.isDeletingItem,
      setIsDeletingItem: state.setIsDeletingItem,
    }),
    shallow
  );

  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );

  const { doxleThemeColor } = useDoxleThemeStore(
    (state) => ({ doxleThemeColor: state.doxleThemeColor }),
    shallow
  );

  const [isDeletingInventory, setIsDeletingInventory] = useState<boolean>(false);

  const deleteInventory = InventoryQueryAPI.useDeleteInventoryQuery({showNotification: showNotification, onSuccessDeleteCb: (deletedIds) => {
    setInventoryForDelete(undefined);
  }});

  const handleCloseDeleteDialog = () => {
    setInventoryForDelete(undefined);
  }

  const handleDeleteInventory = () => {
    if(inventoryForDelete){
      deleteInventory.mutate(inventoryForDelete.inventoryId);
      setIsDeletingItem(true);
    }
  }

  return (
    <DoxleDialogHelper
      open={inventoryForDelete !== undefined && !isDeletingItem}
      onClose={handleCloseDeleteDialog}
      title="Confirm Delete Inventory"
      description={`All data belong to Inventory "${inventoryForDelete?.title}" will be deleted permanently, are you sure to proceed?`}
      dialogButtons={[
        {
          buttonText: "Cancel",
          buttonFunction: handleCloseDeleteDialog,
          buttonColor: doxleThemeColor.primaryContainerColor,
          btnStyleSx: {
            border: `1px solid ${doxleThemeColor.primaryDividerColor}`,
          },
          buttonTextStyle: {
            color: doxleThemeColor.primaryFontColor,
          },
        },
        {
          buttonText: "Confirm",
          buttonFunction: handleDeleteInventory,
          btnStyleSx: {
            border: `1px solid ${doxleThemeColor.primaryDividerColor}`,
          },
        },
      ]}
    />
  );
};

export default InventoryDeleteConfirmDialog;
