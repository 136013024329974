import {
  StyledAddProjectButtonSection,
  StyledAddProjectContent,
  StyledAddProjectHeadTitleText,
  StyledAddProjectScreenContainer,
} from "./StyledComponentAddProjectScreen";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import { LayoutGroup, motion } from "framer-motion";
import useAddProjectScreen from "./Hooks/useAddProjectScreen";
import AddProjectTextField from "./AddProjectTextField";
import { produce } from "immer";
import AddProjectDatePicker from "./AddProjectDatePicker";
import dayjs from "dayjs";
import AddProjectStatus from "./AddProjectStatus";
import AddProjectXeroTracking from "./AddProjectXeroTracking";
import ListLoadingMore from "../../Utilities/Lottie/ListLoadingMore";
import { useParams } from "react-router-dom";
import Loading from "../../Utilities/Lottie/Loading";
import { useDoxleCurrentContextStore } from "../../DoxleGeneralStore/useDoxleCurrentContext";
import { AddProjectBanner } from "./AddProjectIcon";
import AddProjectUser from "./AddProjectUser";
import { Helmet } from "react-helmet";
import { Checkbox } from "@mui/material";
import DoxleCheckbox from "../../DoxleDesignPattern/DoxleCheckbox/DoxleCheckbox";
import { useShallow } from "zustand/react/shallow";

type Props = {};

const AddProjectScreen = ({}: Props) => {
  const { projectId } = useParams();
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore((state) => ({
    doxleThemeColor: state.doxleThemeColor,
    doxleFont: state.doxleFont,
  }));
  const company = useDoxleCurrentContextStore(
    useShallow((state) => state.currentCompany)
  );
  const {
    setNewProject,
    newProject,
    handleClickBackBtn,
    isDisabledCreate,
    handleAddProject,
    isAddingProject,
    isRetrievingProjectData,
    retrievedProjectData,
    shouldShowError,
  } = useAddProjectScreen({ projectId });
  if (projectId && isRetrievingProjectData) return <Loading />;
  return (
    <StyledAddProjectScreenContainer
      $themeColor={doxleThemeColor}
      $doxleFont={doxleFont}
    >
      <Helmet>
        <title>Doxle - Projects</title>
      </Helmet>
      <div className="content-wrapper">
        <AddProjectBanner themeColor={doxleThemeColor} containerStyle={{}} />
        <StyledAddProjectHeadTitleText
          $themeColor={doxleThemeColor}
          $doxleFont={doxleFont}
          initial={{ opacity: 0, y: -5 }}
          animate={{
            opacity: 1,
            y: 0,
            transition: {
              delay: 0.2,
              duration: 0.2,
            },
          }}
        >
          {projectId ? " Edit Project" : "New Project"}
        </StyledAddProjectHeadTitleText>

        <StyledAddProjectContent
          $themeColor={doxleThemeColor}
          $doxleFont={doxleFont}
          initial={{ opacity: 0, y: -5 }}
          animate={{
            opacity: 1,
            y: 0,
            transition: {
              delay: 0.3,
              duration: 0.2,
            },
          }}
        >
          <LayoutGroup id="addProjectContent">
            {/* //*owner - phone - email */}
            <motion.div
              className="row-wrapper"
              layout
              style={{ justifyContent: "space-between" }}
            >
              <AddProjectTextField
                projectKey={"ownerName"}
                retrievedProjectData={retrievedProjectData}
                wrapperWidthRatio="30%"
                labelText="Project Owner"
                placeholderText="Owner’s Full Name"
                numericField={false}
                onTextChangeCb={(value) => {
                  setNewProject(
                    produce((draft) => {
                      draft.ownerName = value;
                      return draft;
                    })
                  );
                }}
                isRequired={true}
                shouldShowError={shouldShowError}
              />

              <AddProjectTextField
                projectKey={"ownerPhone"}
                retrievedProjectData={retrievedProjectData}
                wrapperWidthRatio="25%"
                labelText="Owner Phone"
                placeholderText="Owner’s Phone Number"
                onTextChangeCb={(value) => {
                  setNewProject(
                    produce((draft) => {
                      draft.ownerPhone = value;
                      return draft;
                    })
                  );
                }}
                isRequired={true}
                numericField={true}
                shouldShowError={shouldShowError}
              />

              <AddProjectTextField
                projectKey={"ownerEmail"}
                retrievedProjectData={retrievedProjectData}
                wrapperWidthRatio="25%"
                labelText="Owner Email"
                placeholderText="Owner’s Email"
                onTextChangeCb={(value) => {
                  setNewProject(
                    produce((draft) => {
                      draft.ownerEmail = value;
                      return draft;
                    })
                  );
                }}
                isEmailField={true}
                isRequired={true}
                numericField={false}
                shouldShowError={shouldShowError}
              />
            </motion.div>
            {/* //*site address - start -end date */}
            <motion.div
              className="row-wrapper"
              layout
              style={{ justifyContent: "space-between" }}
            >
              <AddProjectTextField
                projectKey={"siteAddress"}
                retrievedProjectData={retrievedProjectData}
                wrapperWidthRatio="30%"
                labelText="Site Address"
                placeholderText="Project Address"
                onTextChangeCb={(value) => {
                  setNewProject(
                    produce((draft) => {
                      draft.siteAddress = value;
                      return draft;
                    })
                  );
                }}
                isRequired={true}
                numericField={false}
                shouldShowError={shouldShowError}
              />

              <AddProjectDatePicker
                type="start"
                dateValue={
                  newProject.startDate ? dayjs(newProject.startDate) : null
                }
                onPickDate={(value) => {
                  if (value)
                    setNewProject(
                      produce((draft) => {
                        draft.startDate = value.format("YYYY-MM-DD").toString();
                      })
                    );
                  else
                    setNewProject(
                      produce((draft) => {
                        draft.startDate = "";
                      })
                    );
                }}
              />
              <AddProjectDatePicker
                type="end"
                dateValue={
                  newProject.endDate ? dayjs(newProject.endDate) : null
                }
                onPickDate={(value) => {
                  if (value)
                    setNewProject(
                      produce((draft) => {
                        draft.endDate = value.format("YYYY-MM-DD").toString();
                      })
                    );
                  else
                    setNewProject(
                      produce((draft) => {
                        draft.endDate = "";
                      })
                    );
                }}
              />
            </motion.div>
            {/* //*contract price - name - budget */}
            <motion.div
              className="row-wrapper"
              layout
              style={{ justifyContent: "space-between" }}
            >
              <AddProjectTextField
                projectKey={"contractPrice"}
                retrievedProjectData={retrievedProjectData}
                wrapperWidthRatio="30%"
                labelText="Contract Price"
                placeholderText="Signed Contract Price"
                onTextChangeCb={(value) => {
                  setNewProject(
                    produce((draft) => {
                      draft.contractPrice = value;
                      return draft;
                    })
                  );
                }}
                numericField={true}
              />

              <AddProjectTextField
                projectKey={"contractName"}
                retrievedProjectData={retrievedProjectData}
                wrapperWidthRatio="25%"
                labelText="Contract Name"
                placeholderText="Signed Contract Name"
                onTextChangeCb={(value) => {
                  setNewProject(
                    produce((draft) => {
                      draft.contractName = value;
                      return draft;
                    })
                  );
                }}
                numericField={false}
              />

              <AddProjectTextField
                projectKey={"projectBudget"}
                retrievedProjectData={retrievedProjectData}
                wrapperWidthRatio="25%"
                labelText="Project Budget"
                placeholderText="Estimated Project Budget"
                onTextChangeCb={(value) => {
                  setNewProject(
                    produce((draft) => {
                      draft.projectBudget = value;
                      return draft;
                    })
                  );
                }}
                numericField={true}
              />
            </motion.div>
            {/* //* type */}
            <motion.div className="row-wrapper" layout>
              <AddProjectTextField
                projectKey={"projectType"}
                retrievedProjectData={retrievedProjectData}
                wrapperWidthRatio="30%"
                labelText="Project Type"
                placeholderText="Single or Double Storey House "
                onTextChangeCb={(value) => {
                  setNewProject(
                    produce((draft) => {
                      draft.projectType = value;
                      return draft;
                    })
                  );
                }}
                isRequired={false}
                numericField={false}
              />

              <AddProjectStatus
                projectStatus={newProject.projectStatus}
                onSelectProjectStatus={(value) =>
                  setNewProject(
                    produce((draft) => {
                      draft.projectStatus = value;

                      return draft;
                    })
                  )
                }
              />
            </motion.div>

            {/* //* project user */}
            <motion.div className="row-wrapper" layout>
              <AddProjectUser />
              <DoxleCheckbox
                labelText={"Allow Noticeboard Dockets"}
                style={{ marginLeft: 50 }}
                labelStyle={{ marginTop: 3 }}
                checked={newProject.isNoticeboard}
                onClick={() =>
                  setNewProject(
                    produce((draft) => {
                      draft.isNoticeboard = !draft.isNoticeboard;
                      return draft;
                    })
                  )
                }
              />
            </motion.div>

            {/* //* xero tracking */}
            <motion.div className="row-wrapper" layout>
              <AddProjectXeroTracking
                projectTrackingId={newProject.trackingId}
                setNewProject={setNewProject}
              />
              <DoxleCheckbox
                labelText={"Allow Budget Dockets"}
                style={{ marginLeft: 50 }}
                labelStyle={{ marginTop: 3 }}
                checked={newProject.isBudget}
                onClick={() =>
                  setNewProject(
                    produce((draft) => {
                      draft.isBudget = !draft.isBudget;
                      return draft;
                    })
                  )
                }
              />
            </motion.div>
          </LayoutGroup>
          {isAddingProject && (
            <ListLoadingMore
              containerStyle={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
              animationSize="6rem"
            />
          )}

          <StyledAddProjectButtonSection layout $doxleFont={doxleFont}>
            <div
              className="menu-button"
              style={{
                backgroundColor: doxleThemeColor.doxleColor,
                color: "white",
                // pointerEvents: isDisabledCreate ? "none" : "all",
              }}
              onClick={handleAddProject}
            >
              {projectId ? "Save Project" : "Create Project"}
            </div>

            <div
              className="menu-button"
              style={{
                backgroundColor: doxleThemeColor.primaryContainerColor,
                color: doxleThemeColor.primaryFontColor,
                border: `1px solid ${doxleThemeColor.primaryDividerColor}`,
              }}
              onClick={handleClickBackBtn}
            >
              Cancel
            </div>
          </StyledAddProjectButtonSection>
          <span className="note-text">
            (*) = Denotes all mandatory fields. Contract Price = can be empty if
            there is no signed contract. Contract Name = If the contract is
            under a company or a Trust can be empty. Project Budget = The budget
            you want to build the project in
          </span>
        </StyledAddProjectContent>
      </div>

      <div className="bottom-navigate">
        {company?.name} / {projectId ? "Edit" : "Add"} Project /
      </div>
    </StyledAddProjectScreenContainer>
  );
};

export default AddProjectScreen;
