import React from "react";
import { SuccessBanner } from "../svg/successBanner";
import {useDoxleThemeStore} from "../../../../DoxleGeneralStore/useDoxleThemeStore";
import { JoinSuccessPageContainer } from "./StyledJoinPage";



export default function JoinSuccessPage() {
  const { doxleThemeColor } = useDoxleThemeStore((state) => ({
    doxleThemeColor: state.doxleThemeColor,
  }));

  return (
    <JoinSuccessPageContainer>
      <SuccessBanner themeColor={doxleThemeColor} height={"35vh"}/>
    </JoinSuccessPageContainer>
  );
}

