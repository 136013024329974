import { StyledArchivePinSection } from "./StyledComponentDocketInfo";
import { useDoxleThemeStore } from "../../../../DoxleGeneralStore/useDoxleThemeStore";
import {
  DoxleArchiveIcon,
  DoxlePinIcon,
  DoxleUnarchiveIcon,
  DoxleUnpinIcon,
} from "../../../../DoxleDesignPattern/DoxleGeneralIcons";
import { useEditDocketSideScreenStore } from "../../EditDocketSideScreenStore/useEditDocketSideScreenStore";
import { shallow } from "zustand/shallow";
import useArchivePinSection from "./Hooks/useArchivePinSection";
import { CircularProgress, Tooltip } from "@mui/material";
import { StyledIconContainer } from "../../StyledComponentsEditDocketSideScreen";
import { DoxleMUITooltipSx } from "../../../../DoxleDesignPattern/MUIOverwriteSx";

type Props = {};

const ArchivePinSection = (props: Props) => {
  const { doxleThemeColor, doxleFont, staticMenuColor } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
      staticMenuColor: state.staticMenuColor,
    })
  );
  const { edittedDocket } = useEditDocketSideScreenStore(
    (state) => ({
      edittedDocket: state.edittedDocket,
    }),
    shallow
  );
  const {
    handleUpdateArchive,
    handleUpdatePin,
    isUpdatingArchive,
    isUpdatingPin,

    setOnHoverPin,

    setOnHoverArchive,
  } = useArchivePinSection({});

  return (
    <StyledArchivePinSection
      layout="position"
      transition={{
        duration: 0.1,
        damping: 12,
      }}
    >
      <Tooltip
        PopperProps={{
          sx: DoxleMUITooltipSx(doxleThemeColor, doxleFont),
        }}
        title={edittedDocket?.isArchived ? "Unarchive" : "Archive"}
      >
        <StyledIconContainer
          onClick={handleUpdateArchive}
          onMouseEnter={() => setOnHoverArchive(true)}
          onMouseLeave={() => setOnHoverArchive(false)}
        >
          {isUpdatingArchive ? (
            <CircularProgress
              size={16}
              style={{
                color: staticMenuColor.staticFontColor,
              }}
            />
          ) : (
            <>
              {edittedDocket?.isArchived ? (
                <DoxleUnarchiveIcon
                  themeColor={doxleThemeColor}
                  containerStyle={{ width: 20 }}
                  staticColor={staticMenuColor.staticFontColor}
                />
              ) : (
                <DoxleArchiveIcon
                  themeColor={doxleThemeColor}
                  containerStyle={{ width: 20 }}
                  staticColor={staticMenuColor.staticFontColor}
                />
              )}
            </>
          )}
        </StyledIconContainer>
      </Tooltip>

      <Tooltip
        PopperProps={{
          sx: DoxleMUITooltipSx(doxleThemeColor, doxleFont),
        }}
        title={edittedDocket?.isSticky ? "Unpin" : "Pin"}
      >
        <StyledIconContainer
          onClick={handleUpdatePin}
          onMouseEnter={() => setOnHoverPin(true)}
          onMouseLeave={() => setOnHoverPin(false)}
        >
          {isUpdatingPin ? (
            <CircularProgress
              size={16}
              style={{
                color: staticMenuColor.staticFontColor,
              }}
            />
          ) : (
            <>
              {edittedDocket?.isSticky ? (
                <DoxleUnpinIcon
                  themeColor={doxleThemeColor}
                  containerStyle={{ width: 20 }}
                  staticColor={staticMenuColor.staticFontColor}
                />
              ) : (
                <DoxlePinIcon
                  themeColor={doxleThemeColor}
                  containerStyle={{ width: 20 }}
                  staticColor={staticMenuColor.staticFontColor}
                />
              )}
            </>
          )}
        </StyledIconContainer>
      </Tooltip>
    </StyledArchivePinSection>
  );
};

export default ArchivePinSection;
