import React from "react";

import { useQueryClient } from "@tanstack/react-query";

import { produce } from "immer";
import { useDoxleCurrentContextStore } from "../../DoxleGeneralStore/useDoxleCurrentContext";
import { shallow } from "zustand/shallow";
import { getOrderDetailQKey } from "../QueryHooks/OrdersQueryAPI";
import Order from "../Models/order";

type Props = {
  orderId?: string;
  overwrite?: boolean;
};

interface SetOrderDetailQueryData {
  handleUpdateWholeOrder: (updatedOrder: Order) => void;
  handleUpdateMutipleOrderDetails: (updatedOrder: Order[]) => void;
  handleUpdatePartialOrder: (payload: Partial<Order>) => void;
  
}
const useSetOrderDetailQueryData = ({
  orderId,
  overwrite = true,
}: Props): SetOrderDetailQueryData => {
  const { company } = useDoxleCurrentContextStore(
    (state) => ({
      company: state.currentCompany,
    }),
    shallow
  );
  const qKey = getOrderDetailQKey(orderId, company);
  const queryClient = useQueryClient();

  const handleUpdateWholeOrder = (updatedOrder: Order) => {
    const orderListQueryData = queryClient.getQueryData(qKey);
    if (orderListQueryData) {
      if (overwrite) {
        queryClient.setQueryData(qKey, (old: any) => {
          if (old) {
            return produce(old, (draftOld: any) => {
              draftOld.data = { ...updatedOrder };

              return draftOld;
            });
          } else queryClient.invalidateQueries(qKey);
        });
      } else queryClient.invalidateQueries(qKey);
    } else queryClient.invalidateQueries(qKey);
  };

  const handleUpdatePartialOrder = (payload: Partial<Order>) => {
    const orderListQueryData = queryClient.getQueryData(qKey);
    if (orderListQueryData) {
      if (overwrite) {
        queryClient.setQueryData(qKey, (old: any) => {
          if (old) {
            return produce(old, (draftOld: any) => {
              draftOld.data = produce(draftOld.data, (draftOrder: Order) => {
                draftOrder = { ...old.data, ...payload };
                return draftOrder;
              });

              return draftOld;
            });
          } else queryClient.invalidateQueries(qKey);
        });
      } else queryClient.invalidateQueries(qKey);
    } else queryClient.invalidateQueries(qKey);
  };

  const handleUpdateMutipleOrderDetails = (orders: Order[]) => {
    console.log(orders)
    orders.forEach((order) => {
      const qKey = getOrderDetailQKey(order.orderId, company)
      const orderListQueryData = queryClient.getQueryData(qKey);
      if (orderListQueryData) {
        if (overwrite) {
          queryClient.setQueryData(qKey, (old: any) => {
            if (old) {
              return produce(old, (draftOld: any) => {
                draftOld.data = { ...order }
                return draftOld;
              });
            } else queryClient.invalidateQueries(qKey);
          });
        } else queryClient.invalidateQueries(qKey);
      } else queryClient.invalidateQueries(qKey);
    })
  };


  return {
    handleUpdateWholeOrder,
    handleUpdatePartialOrder,
    handleUpdateMutipleOrderDetails,
  };
};

export default useSetOrderDetailQueryData;
