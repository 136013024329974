import React, { useEffect, useMemo } from "react";
import OrdersQueryAPI from "../QueryHooks/OrdersQueryAPI";
import { useEditDocketSideScreenStore } from "../../CoreContent/EditDocketSideScreen/EditDocketSideScreenStore/useEditDocketSideScreenStore";
import { useDoxleCurrentContextStore } from "../../DoxleGeneralStore/useDoxleCurrentContext";
import { shallow } from "zustand/shallow";
import { useDoxleAuthStore } from "../../DoxleGeneralStore/useDoxleAuthStore";
import useDoxleNotificationStore from "../../DoxleGeneralStore/useDoxleNotificationStore";
import { useOrdersStore } from "../OrdersStore/useOrdersStore";
import { useEditOrderStore } from "../OrdersStore/useEditOrderStore";
import Order from "../Models/order";

type Props = {};

interface GetOrderList {
  ordersList: Order[];
  isFetchingOrder: boolean;
  isSuccessFetchingOrder: boolean;
  isErrorFetchingOrder: boolean;

  handleFetchNextPageOrder: () => void;
  isFetchingNextPageOrder: boolean;
}
const useGetOrderList = (props: Props): GetOrderList => {
  const company = useDoxleCurrentContextStore(
    (state) => state.currentCompany,
    shallow
  );

  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );
  const { docket, tab } = useEditDocketSideScreenStore(
    (state) => ({
      docket: state.edittedDocket,
      tab: state.selectedFunctionMenu,
    }),
    shallow
  );
  const { view, queryFilter, setQueryFilter } = useOrdersStore(
    (state) => ({
      queryFilter: state.queryFilter,
      setQueryFilter: state.setQueryFilter,
      view: state.view,
    }),
    shallow
  );

  useEffect(() => {
    if (docket) setQueryFilter({ docket: docket.docketPk });
    if (view === "Invoices") setQueryFilter({ is_invoice: true });
    else setQueryFilter({ is_invoice: false });
  }, [setQueryFilter, docket, view]);
  const orderListQuery = OrdersQueryAPI.useRetrieveOrderList({
    company,
    showNotification,
    enabled: Boolean(queryFilter.docket),
    filter: queryFilter,
  });

  const ordersList: Order[] = useMemo(
    () =>
      orderListQuery.isSuccess
        ? orderListQuery.data?.pages.reduce((acc, data) => {
            return acc.concat(data.data.results);
          }, [] as Order[])
        : [],
    [orderListQuery.data]
  );
  useEffect(() => {
  }, [orderListQuery.data])

  //   useEffect(() => {
  //     if (orderListQuery.hasNextPage) orderListQuery.fetchNextPage();
  //   }, [orderListQuery.hasNextPage]);
  const handleFetchNextPageOrder = () => {
    if (orderListQuery.hasNextPage) orderListQuery.fetchNextPage();
  };
  return {
    ordersList,
    isFetchingOrder: orderListQuery.isLoading,
    isSuccessFetchingOrder: orderListQuery.isSuccess,
    isErrorFetchingOrder: orderListQuery.isError,

    handleFetchNextPageOrder,
    isFetchingNextPageOrder: orderListQuery.isFetchingNextPage,
  };
};

export default useGetOrderList;
