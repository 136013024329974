import React, { useContext, useMemo, useState } from "react";
import JoinFormPage from "../components/JoinPage/JoinFormPage";
import JoinSuccessPage from "../components/JoinPage/JoinSuccessPage";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import SelectNewUserRolePage from "../components/JoinPage/SelectNewUserRolePage";

type TNewUserFormData = {
  role: string;
  name: string;
  mobile: string;
  email: string;
  company: string;
  reference: string;
};

const emptyCustomerData: TNewUserFormData = {
  role: "",
  name: "",
  mobile: "",
  email: "",
  company: "",
  reference: "",
};

type TJoinContextValue = {
  stage: number;
  setStage: React.Dispatch<React.SetStateAction<number>>;
  userFormData: TNewUserFormData;
  setUserFormData: React.Dispatch<React.SetStateAction<TNewUserFormData>>;
};

const SELECT_TYPE_STAGE = 1;
const FILL_FORM_STAGE = 2;
const SUCCESS_STAGE = 3;

const JoinFormContext = React.createContext<TJoinContextValue | null>(null);

export default function Join() {
  const [stage, setStage] = useState(SELECT_TYPE_STAGE);
  const [userFormData, setUserFormData] =
    useState<TNewUserFormData>(emptyCustomerData);

  const contextValue: TJoinContextValue = useMemo(
    () => ({
      stage,
      setStage,
      userFormData,
      setUserFormData,
    }),
    [stage]
  );
  if (stage === FILL_FORM_STAGE) {
    return (
      <JoinFormContext.Provider value={contextValue}>
        <JoinFormPage />
      </JoinFormContext.Provider>
    );
  } else if (stage === SUCCESS_STAGE) {
    console.log(userFormData);
    return <JoinSuccessPage />;
  }

  return (
    <JoinFormContext.Provider value={contextValue}>
      <SelectNewUserRolePage />
    </JoinFormContext.Provider>
  );
}

export const useJoinContext = () =>
  useContext(JoinFormContext) as TJoinContextValue;
