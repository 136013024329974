import React, { useMemo } from "react";
import { Docket } from "../Models/dockets";
import { shallow } from "zustand/shallow";
import Loading from "../Utilities/Lottie/Loading";
import CircularProgress from "@mui/material/CircularProgress";
import { useDoxleThemeStore } from "../DoxleGeneralStore/useDoxleThemeStore";
import {
  LinkAllButton,
  StyledBackdrop,
  StyledBodyContainer,
  StyledBottomContainer,
  StyledProjectMapHeader,
  StyledProjectMapHeaderLeft,
  StyledProjectMapHeaderRight,
  StyledRootContainer,
} from "./Content/styledComponents";
import DocketMappingRow from "./Content/DocketMappingRow";
import { Components, Virtuoso } from "react-virtuoso";
import { StyledListScroller } from "../DoxleDesignPattern/ProjectSelectWithDropdown/StyledComponentsProjectSelectWithDropdown";
import SelectAccountDropDown from "./Content/SelectAccountDropDown";
import useAccountMapping from "./Hooks/useAccountMapping";
import { useDoxleCurrentContextStore } from "../DoxleGeneralStore/useDoxleCurrentContext";
import DoxleEmptyPlaceHolder from "../DoxleDesignPattern/DoxleEmptyPlaceHolder/DoxleEmptyPlaceHolder";
import { Error404Banner } from "../DoxleDesignPattern/DoxleIcons/CommonIcons";
import { EmptyDocketXeroBanner } from "./Content/XeroIcons";
import ProjectTrackingDropdown from "./Content/ProjectTrackingDropdown";
import UnmappedAccountAlert from "./Content/UnmappedAccountAlert";
import {useProjectStore} from "../DoxleGeneralStore/useprojectStore";
export interface IUnlinkedItem {
  docket: Docket;
  trackingId: string;
}

interface IDocketListContext {
  isErrorFetchingDocketList: boolean;
}
const AccountMapping = () => {
  const styleProps = useDoxleThemeStore(
    (state) => ({
      $themeColor: state.doxleThemeColor,
      $doxleFont: state.doxleFont,
      $currentTheme: state.currentTheme,
    }),
    shallow
  );
  const accounting = useProjectStore(state=> state.accountingBasis, shallow);
  const {
    unSavedAccountLinks,
    docketList,
    handleLinkAll,
    isMutating,
    unmappedAccountsWithCost,
    sortedUnmatchedAccounts,
    isFetchingDocketList,
    isSuccessFetchingDocketList,
    isErrorFetchingDocketList,
  } = useAccountMapping();

  const components: Components<Docket, IDocketListContext> = useMemo(
    () => ({
      Scroller: React.forwardRef((props, ref) => (
        <StyledListScroller style={{ ...props.style }} ref={ref} {...props} />
      )),

      EmptyPlaceholder: (props) => (
        <DoxleEmptyPlaceHolder
          headTitleText={
            props.context?.isErrorFetchingDocketList
              ? "Something wrong"
              : "No Items found"
          }
          subTitleText={
            props.context?.isErrorFetchingDocketList
              ? "Please try to refresh page, we are sorry for this!"
              : "Add more docket items to manage your budgets..."
          }
          containerBgColor="transparent"
          illustration={
            props.context?.isErrorFetchingDocketList ? (
              <Error404Banner
                themeColor={styleProps.$themeColor}
                containerStyle={{ width: "30%" }}
              />
            ) : (
              <EmptyDocketXeroBanner
                themeColor={styleProps.$themeColor}
                containerStyle={{ width: "30%" }}
              />
            )
          }
        />
      ),
    }),
    []
  );
  return (
    <StyledRootContainer
      className="rootAccountMappingContainer"
      {...styleProps}
    >
      <SelectAccountDropDown />

      <StyledProjectMapHeader {...styleProps}>
        <StyledProjectMapHeaderLeft {...styleProps}>
          <div className={'title'}>Project Tracking</div>
          <div className={'accounting'}>Accounting Basis: {accounting === 'CASH' ? 'Cash' : 'Accrual'}</div>
        </StyledProjectMapHeaderLeft>

        <StyledProjectMapHeaderRight {...styleProps}>
          <ProjectTrackingDropdown />
          <UnmappedAccountAlert
            sortedUnmatchedAccounts={sortedUnmatchedAccounts}
            isSuccessFetchingDocketList={isSuccessFetchingDocketList}
            unmappedAccountsWithCost={unmappedAccountsWithCost}
          />
        </StyledProjectMapHeaderRight>
      </StyledProjectMapHeader>
      <StyledBodyContainer {...styleProps}>
        {isFetchingDocketList ? (
          <Loading />
        ) : (
          <>
            {isMutating && (
              <StyledBackdrop {...styleProps}>
                <CircularProgress style={{ marginTop: "33%" }} />
              </StyledBackdrop>
            )}
            <Virtuoso
              style={{ height: "100%", width: "100%" }}
              data={docketList}
              itemContent={(index, docket) => (
                <DocketMappingRow key={docket.docketPk} docket={docket} />
              )}
              components={components}
              // endReached={handleEndReached}
              context={{ isErrorFetchingDocketList }}
            />
            {/*{isFetchingNextPage && (*/}
            {/*  <ListLoadingMore*/}
            {/*    containerStyle={{*/}
            {/*      position: "absolute",*/}
            {/*      bottom: 0,*/}
            {/*      left: 0,*/}
            {/*      width: "100%",*/}
            {/*      display: "flex",*/}
            {/*      justifyContent: "center",*/}
            {/*      alignItems: "center",*/}
            {/*      zIndex: 20,*/}
            {/*    }}*/}
            {/*    animationSize={120}*/}
            {/*  />*/}
            {/*)}*/}
            <StyledBottomContainer {...styleProps}>
              <LinkAllButton
                className="buttonLinkAll"
                onClick={() => handleLinkAll()}
                disabled={unSavedAccountLinks.length === 0}
              >
                Link All
              </LinkAllButton>
            </StyledBottomContainer>
          </>
        )}
      </StyledBodyContainer>
    </StyledRootContainer>
  );
};

export default AccountMapping;
