import { DragIcon, HashIcon, StatusKebabIcon } from "../Assets/icons";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import { ProjectStatus } from "../../../Models/project";
import {
  StyledDragIconContainer,
  StyledEditStatusTile,
  StyledEditStatusTileWrapper,
  StyledStatusArea,
  StyledStatusColorDot,
  StyledStatusDropDownOption,
  StyledStatusListPlaceholderContainer,
  StyledStatusOptionDropdown,
  StyledStatusTileSection,
  StyledStatusTypeHeader,
} from "../styledComponents";
import useProjectStatuses from "../Hooks/useProjectStatuses";
import { ClickAwayListener, Skeleton } from "@mui/material";
import EditStatusTextField from "../StatusComponents/EditStatusTextField";
import AddStatusTextField from "../StatusComponents/AddStatusTextField";
import DeleteStatusDialog from "./DeleteStatusDialog";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
} from "@hello-pangea/dnd";
import StatusCloneObject from "./StatusCloneObject";
import { FiMoreVertical } from "react-icons/fi";

const EditProjectStatusesSection = () => {
  const styleProps = useDoxleThemeStore(
    (state) => ({
      $themeColor: state.doxleThemeColor,
      $doxleFont: state.doxleFont,
      $currentTheme: state.currentTheme,
    }),
    shallow
  );

  const {
    projectStatusList,
    handleStatusColorClick,
    showDropDown,
    dropDownPosition,
    projectStatusDropdownOptions,
    handleKebabIconClick,
    handleCloseDropDown,
    handleUpdateStatusName,
    handleAddStatus,
    deleteStatusDialog,
    setDeleteStatusDialog,
    handleDeleteConfirmed,
    handleUpdateStatusIndex,
    retrieveProjectStatusQuery,
  } = useProjectStatuses();

  const onDragEnd = (result: DropResult) => {
    const { source, destination } = result;
    if (!destination) {
      return;
    }
    console.log(source.index + "," + destination.index);

    if (source.index === destination.index) {
      return;
    }
    handleUpdateStatusIndex(
      projectStatusList[source.index],
      source.index,
      destination.index
    );
  };

  return (
    <StyledStatusArea>
      <StyledStatusTypeHeader {...styleProps}>
        <HashIcon themeColor={styleProps.$themeColor} /> Project Status
      </StyledStatusTypeHeader>
      {retrieveProjectStatusQuery.isLoading && (
        <StyledStatusListPlaceholderContainer
          $themeColor={styleProps.$themeColor}
        >
          <Skeleton
            variant="rounded"
            height={30}
            width={"60%"}
            sx={{ bgcolor: styleProps.$themeColor.skeletonColor }}
          />
        </StyledStatusListPlaceholderContainer>
      )}
      {retrieveProjectStatusQuery.isError && (
        <StyledStatusListPlaceholderContainer
          $themeColor={styleProps.$themeColor}
          style={{ color: "red" }}
        >
          Fail to load status list
        </StyledStatusListPlaceholderContainer>
      )}
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable
          droppableId="project-droppable"
          direction="horizontal"
          renderClone={(provided, snapshot, rubric) => {
            const status = projectStatusList[rubric.source.index];
            return (
              <StatusCloneObject
                provided={provided}
                statusColor={status.statusColor ?? "rgba(0,0,0,1)"}
                statusName={status.statusName}
              />
            );
          }}
        >
          {(provided) => {
            return (
              <StyledStatusTileSection
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {projectStatusList.map((status: ProjectStatus, index) => (
                  <Draggable
                    key={status.statusId}
                    draggableId={status.statusId}
                    index={index}
                  >
                    {(provided) => {
                      const color = status?.statusColor
                        ? status.statusColor === "rgba(0,0,0,1)"
                          ? styleProps.$themeColor.primaryFontColor
                          : status.statusColor
                        : "rgba(0,0,0,1)";
                      return (
                        <StyledEditStatusTileWrapper
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <StyledDragIconContainer>
                            <DragIcon
                              themeColor={styleProps.$themeColor}
                              className={"drag-icon"}
                            />
                          </StyledDragIconContainer>

                          <StyledEditStatusTile
                            $statusColor={color}
                            {...styleProps}
                            key={status.statusId}
                          >
                            <StyledStatusColorDot
                              $statusColor={color}
                              $size={"12px"}
                              onClick={(e) => handleStatusColorClick(e, status)}
                            />
                            <EditStatusTextField
                              status={status}
                              handleUpdateStatusName={handleUpdateStatusName}
                            />
                            <FiMoreVertical
                              color={styleProps.$themeColor.primaryFontColor}
                              onClick={(e) => handleKebabIconClick(e, status)}
                            />
                          </StyledEditStatusTile>
                        </StyledEditStatusTileWrapper>
                      );
                    }}
                  </Draggable>
                ))}
              </StyledStatusTileSection>
            );
          }}
        </Droppable>
      </DragDropContext>
      <AddStatusTextField addStatusFunction={handleAddStatus} />
      {showDropDown && (
        <ClickAwayListener onClickAway={handleCloseDropDown}>
          <StyledStatusOptionDropdown {...styleProps} {...dropDownPosition}>
            {projectStatusDropdownOptions.map((option) => (
              <StyledStatusDropDownOption
                {...styleProps}
                onClick={option.handleClick}
                $disabled={option.disabled}
                $selected={option.selected}
              >
                {option.selected === true && (
                  <>
                    <CheckBoxIcon style={{ transform: "translateY(2px)" }} />
                    &nbsp;
                  </>
                )}
                {option.selected === false && (
                  <>
                    <CheckBoxOutlineBlankIcon
                      style={{ transform: "translateY(2px)" }}
                    />
                    &nbsp;
                  </>
                )}
                {option.text}
              </StyledStatusDropDownOption>
            ))}
          </StyledStatusOptionDropdown>
        </ClickAwayListener>
      )}
      {deleteStatusDialog && (
        <DeleteStatusDialog
          title={"Delete Status " + deleteStatusDialog.statusName}
          description={`This status has ${deleteStatusDialog.totalCount} orders associated with it. Please choose what you would like to do with these orders`}
          deleteFn={handleDeleteConfirmed}
          statusList={projectStatusList.filter(
            (status) => status.statusId !== deleteStatusDialog.statusId
          )}
          handleClose={() => setDeleteStatusDialog(null)}
        />
      )}
    </StyledStatusArea>
  );
};

export default EditProjectStatusesSection;
