import {
  useInfiniteQuery,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import {
  AxiosBackendErrorReturn,
  IApiPaginatedData,
} from "../../Models/axiosReturn";
import DoxleAPI from "../../Services/DoxleAPI";
import {
  InventoryComment,
  InventoryImage,
  InventoryItem,
  NewComment,
} from "../Models/Inventory";
import { useDoxleCurrentContextStore } from "../../DoxleGeneralStore/useDoxleCurrentContext";
import { shallow } from "zustand/shallow";
import { BaseAPIProps } from "../../Models/baseAPIProps";
import { getInventoryQKey } from "./InventoryQueryAPI";
import useSetInventoryQueryData from "./useSetInventoryQueryData";
import { isAxiosError } from "axios";

const useGetInventoryCommentsById = (inventoryId: string) => {
  const qKey = ["inventoryId", "comments", inventoryId];
  let inventoryUrl = "/inventory/" + inventoryId + "/comments/";
  const company = useDoxleCurrentContextStore(
    (state) => state.currentCompany,
    shallow
  );

  return useInfiniteQuery(
    qKey,
    async () => {
      return DoxleAPI.get<IApiPaginatedData<InventoryComment>>(inventoryUrl, {
        headers: {
          "User-Company": company?.companyId,
        },
      });
    },
    {
      onSuccess: (res) => {},

      onError: () => {},
      enabled: Boolean(company),
      staleTime: 0,
      cacheTime: 0,
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: false,
    }
  );
};

interface AddCommentParams {
  commentText: string;
}

export interface InventoryAddQueryProps extends BaseAPIProps {
  inventoryId: string;
}

const useAddInventoryCommentQuery = ({
  showNotification,
  company,
  inventoryId,
}: InventoryAddQueryProps) => {
  const { handleUpdateInventoryList } = useSetInventoryQueryData({});
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationKey: ["Inventory-Comment-Mutation", inventoryId],
    mutationFn: async ({ commentText }: AddCommentParams) => {
      const formData = new FormData();
      formData.append("commentText", commentText);

      return DoxleAPI.post<InventoryItem>(
        "/inventory/" + inventoryId + "/comments/",
        formData,
        {
          headers: {
            "User-Company": company?.companyId || "",
          },
        }
      );
    },
    onMutate: () => {
      const qKey = getInventoryQKey(company);
      queryClient.cancelQueries(qKey);
    },
    onSettled: () => {
      queryClient.refetchQueries({
        type: "all",
        predicate: (query) =>
          getInventoryQKey(company).every((item) =>
            query.queryKey.includes(item)
          ),
      });
    },
    onSuccess: (result, variables, context) => {
      // if (onSuccessAddCb) onSuccessAddCb(result.data);
      handleUpdateInventoryList(result.data);

      if (showNotification)
        showNotification(
          "Comment added",
          "success",
          "SUCCESSFULLY Added Comment"
        );
    },
    onError: (error, variables, context) => {
      if (showNotification) {
        if (isAxiosError<AxiosBackendErrorReturn>(error)) {
          showNotification(
            `${error?.response?.status ?? "ERROR"}: ${
              error.response?.data.detail ?? "UNKNOWN ERROR"
            }`,
            "error",
            String(
              error?.response?.data?.detail ?? "Failed To Add Comment"
            ).substring(0, 300)
          );
        } else {
          showNotification(
            "Something Wrong!",
            "error",
            "Failed To Add Comment"
          );
        }
      }
    },
  });

  return mutation;
};

const InventoryCommentsQueryAPI = {
  useGetInventoryCommentsById,
  useAddInventoryCommentQuery,
};
export default InventoryCommentsQueryAPI;
