import { editRgbaAlpha } from "../../../Utilities/FunctionUtilities";
import { CustomSVGProps } from "../../UpdatedGreetingScreen/pages/svg/mainBanner";


export const Mesh = ({ themeColor, ...props }: CustomSVGProps) => (
  <div style={{ zIndex: -1, position: "absolute", top: "20%" }}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={"45.9rem"}
      height={600}
      fill="none"
      {...props}
    >
      <g stroke={editRgbaAlpha({rgbaColor:themeColor.doxleColor, alpha: "0.1"})} >
        <path d="M2.551 65.357H543.3M4.251 436.307H545M4.251 250.832H545M1.701 158.094H542.45M3.401 529.043H544.15M3.401 343.568H544.15M2.551 111.053H543.3M4.251 482.004H545M4.251 296.529H545M1.701 203.791H542.45M3.401 574.74H544.15M39.611 43.008V598.76M70.219 42.336v555.752M131.436 42.336v555.752M438.371 41.664v555.752M284.479 42.336v555.752M192.652 43.008V598.76M499.587 42.336v555.752M345.695 43.008V598.76M100.828 43.008V598.76M407.762 42.336v555.752M253.87 43.008V598.76M162.044 43.008V598.76M468.979 42.336v555.752M315.086 43.008V598.76M223.261 43.68v555.752M530.196 43.008V598.76M376.304 43.68v555.752M3.401 389.266H544.15M2.551 88.205H543.3M4.251 459.154H545M4.251 273.68H545M1.701 180.943H542.45M3.401 551.893H544.15M3.401 366.418H544.15M.85 134.574h540.749M2.551 505.523H543.3M2.551 320.049H543.3M0 227.311h540.749M18.144 598.762l512.408-.336M1.701 412.785H542.45" />
      </g>
    </svg>
  </div>
);
