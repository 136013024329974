import React from "react";
import {
  StyledAssigneeAndWatcherPicker,
  StyledDocketInfoEditFieldWrapper,
  StyledEditGeneralFieldLabel,
} from "./StyledComponentDocketInfo";
import { useDoxleThemeStore } from "../../../../DoxleGeneralStore/useDoxleThemeStore";
import useWatcherSection from "./Hooks/useWatcherSection";
import { useEditDocketSideScreenStore } from "../../EditDocketSideScreenStore/useEditDocketSideScreenStore";
import { shallow } from "zustand/shallow";
import ContactSelect from "./ContactSelect";
import { AnimatePresence } from "framer-motion";
import DoxleFilterTag from "../../../../DoxleDesignPattern/DoxleFilterTag/DoxleFilterTag";
import DoxleSelectContactDropdown from "../../../../DoxleDesignPattern/DoxleSelectContactDropdown/DoxleSelectContactDropdown";

import { IoChevronDownOutline } from "react-icons/io5";

type Props = {};

const WatcherSection = (props: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore((state) => ({
    doxleThemeColor: state.doxleThemeColor,
    doxleFont: state.doxleFont,
  }));
  const {
    handleAddWatchers,
    handleRemoveWatcher,
    handleOpenWatcherList,
    handleCloseWatcherList,
    anchorWatcherList,
  } = useWatcherSection({});
  const { edittedDocket } = useEditDocketSideScreenStore(
    (state) => ({
      edittedDocket: state.edittedDocket,
    }),
    shallow
  );
  return (
    <StyledDocketInfoEditFieldWrapper
      layout="position"
      transition={{
        duration: 0.1,
        damping: 12,
      }}
    >
      <StyledEditGeneralFieldLabel
        $doxleFont={doxleFont}
        $themeColor={doxleThemeColor}
      >
        Watchers
      </StyledEditGeneralFieldLabel>
      {/* <ContactSelect
        onSelectContact={handleAddWatchers}
        labelText="Select Watchers"
        selectedValue={edittedDocket?.watching ?? []}
      /> */}
      <StyledAssigneeAndWatcherPicker
        $doxleFont={doxleFont}
        $themeColor={doxleThemeColor}
        onClick={handleOpenWatcherList}
      >
        Select Watchers
        <IoChevronDownOutline
          className="icon-dropdown"
          color={doxleThemeColor.primaryFontColor}
          size={14}
        />
      </StyledAssigneeAndWatcherPicker>
      <DoxleSelectContactDropdown
        popoverAnchor={anchorWatcherList}
        onClosePopover={handleCloseWatcherList}
        variants="contact"
        selectedContactItemIds={
          edittedDocket && edittedDocket.watching
            ? edittedDocket.watching
            : undefined
        }
        onSelectContact={handleAddWatchers}
        containerStyle={{
          borderRadius: 9,
        }}
        closeOnSelect={false}
      />
      <div className="watcher-tag-container">
        <AnimatePresence>
          {edittedDocket &&
            edittedDocket.watcherJson &&
            edittedDocket.watching.length > 0 &&
            edittedDocket.watcherJson.map((watcher) => (
              <DoxleFilterTag
                key={watcher.contactId}
                itemData={watcher}
                itemDisplayText={`${watcher.firstName} ${watcher.lastName}`}
                removeItemFunction={handleRemoveWatcher}
                removable={watcher.contactId !== edittedDocket.ballInCourt}
                tagStyle={{
                  marginInline: "0.4rem",
                  marginTop: "14px",
                }}
              />
            ))}
        </AnimatePresence>
      </div>
    </StyledDocketInfoEditFieldWrapper>
  );
};

export default WatcherSection;
