import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import { HiOutlineUserRemove } from "react-icons/hi";
import { ScopeOfWorkGroup } from "../../ScopeOfWorks/Model/scopeOfWorks";
import { StyledTaskItem } from "./StyledDoxleSelectTaskDropdown";
interface BaseProps {
  selected: boolean;
}


interface TaskProps extends BaseProps {
  task: ScopeOfWorkGroup;
  onSelect: (item: ScopeOfWorkGroup) => void;
  onRemoveTask?: (item: ScopeOfWorkGroup) => void;
}

function TaskItem({
  selected,
  onSelect,
  ...props
}: TaskProps): JSX.Element {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }),
    shallow
  );
  const handleClick = () => {
      onSelect(props.task);
  };
  return (
    <StyledTaskItem
      onClick={handleClick}
      animate={{
        color: selected
          ? doxleThemeColor.doxleColor
          : doxleThemeColor.primaryFontColor,
      }}
      transition={{ duration: 0.2 }}
    >
      <div className="info-wrapper">
        <span className="task-name">
          {props.task.scopeGroupTitle}
        </span>
      </div>
    </StyledTaskItem>
  );
}

export default TaskItem;
