import React, { useCallback } from "react";
import { DropEvent, FileRejection } from "react-dropzone";
import { DrawingSet } from "../Models/drawings";

type Props = { drawingSet: DrawingSet };

interface AddSheetDropZoneHook {
  onDrop: <T extends File>(
    acceptedFiles: T[],
    fileRejections: FileRejection[],
    event: DropEvent
  ) => void;
}
const useAddSheetDropZone = ({ drawingSet }: Props): AddSheetDropZoneHook => {
  const onDrop = useCallback(
    <T extends File>(
      acceptedFiles: T[],
      fileRejections: FileRejection[],
      event: DropEvent
    ) => {},
    []
  );
  return {
    onDrop,
  };
};

export default useAddSheetDropZone;
