import { useCallback, useEffect, useMemo } from "react";

import { shallow } from "zustand/shallow";
import { IBooking } from "../../Models/booking";
import useBookingProjectStore from "../../store/useBookingProjectStore";
import { useDoxleAuthStore } from "../../../DoxleGeneralStore/useDoxleAuthStore";
import { useDoxleCurrentContextStore } from "../../../DoxleGeneralStore/useDoxleCurrentContext";
import { BookingQueryAPI } from "../../QueryAPI/bookingQueryAPI";
import useDoxleNotificationStore from "../../../DoxleGeneralStore/useDoxleNotificationStore";
import { useEditDocketSideScreenStore } from "../../../CoreContent/EditDocketSideScreen/EditDocketSideScreenStore/useEditDocketSideScreenStore";
import useBookingDocketStore from "../../store/useBookingDocketStore";

type Props = {};

interface GetBookingList {
  bookingDocketList: IBooking[];
  isFetchingBookingDocketList: boolean;
  isSuccessFetchingBookingDocketList: boolean;
  isErrorFetchingBookingDocketList: boolean;
  isRefetchingBookingDocketList: boolean;
  isFetchingMoreBookingDocketList: boolean;
  refetchBookingDocketList: () => void;
  fetchMoreBookingDocketList: () => void;
}
const useGetDocketBookingList = (props: Props): GetBookingList => {
  const { filterBookingDocketQuery, setFilterBookingDocketQuery } =
    useBookingDocketStore(
      (state) => ({
        filterBookingDocketQuery: state.filterBookingDocketQuery,
        setFilterBookingDocketQuery: state.setFilterBookingDocketQuery,
      }),
      shallow
    );
  const { company } = useDoxleCurrentContextStore(
    (state) => ({
      company: state.currentCompany,
    }),
    shallow
  );
  const { edittedDocket } = useEditDocketSideScreenStore(
    (state) => ({ edittedDocket: state.edittedDocket }),
    shallow
  );
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );
  useEffect(() => {
    if (edittedDocket)
      setFilterBookingDocketQuery({
        docket: edittedDocket.docketPk,
      });
  }, [edittedDocket]);
  const getBookingQuery = BookingQueryAPI.useRetrieveBookingList({

    company,
    showNotification,
    filter: filterBookingDocketQuery,
    enableQuery: Boolean(edittedDocket),
  });

  const bookingDocketList = useMemo(
    () =>
      getBookingQuery.isSuccess
        ? getBookingQuery.data.pages.reduce((acc, data) => {
            return acc.concat(data.data.results);
          }, [] as IBooking[])
        : [],
    [getBookingQuery.data]
  );

  const refetchBookingDocketList = () => {
    getBookingQuery.refetch();
  };
  const fetchMoreBookingDocketList = () => {
    if (getBookingQuery.hasNextPage) getBookingQuery.fetchNextPage();
  };

  return {
    bookingDocketList,
    isFetchingBookingDocketList: getBookingQuery.isLoading,
    isSuccessFetchingBookingDocketList: getBookingQuery.isSuccess,
    isErrorFetchingBookingDocketList: getBookingQuery.isError,
    isRefetchingBookingDocketList: getBookingQuery.isRefetching,
    isFetchingMoreBookingDocketList: getBookingQuery.isFetchingNextPage,
    refetchBookingDocketList,
    fetchMoreBookingDocketList,
  };
};

export default useGetDocketBookingList;
