import { useDoxleAuthStore } from "../../DoxleGeneralStore/useDoxleAuthStore";
import { useDoxleCurrentContextStore } from "../../DoxleGeneralStore/useDoxleCurrentContext";
import useDoxleNotificationStore from "../../DoxleGeneralStore/useDoxleNotificationStore";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import { DoxleFile, DoxleFolder } from "../../Models/files";
import {
  FileEditIcon,
  FileTrashIcon,
  FolderIcon,
  PencilIcon,
} from "./FileIcons";
import FilesQueryAPI, {
  updateFolderObject,
} from "../QueryHooks/FileStorageQueryAPI";
import FileBody from "./FileBody";
import FileHeader from "./FileHeader";
import {
  StyledFileFolderTableRightDiv,
  StyledFileHoverSection,
  StyledPencilIconButton,
  StyledTrashIconButton,
} from "./StyledFilesBodyComponent";
import { useEffect, useState } from "react";
import { useFileStore } from "../Store/useFileStore";
import { shallow } from "zustand/shallow";
import FileRenameDialog from "../Components/FileRenameDialog";
import { DoxleDeleteIcon } from "../../DoxleDesignPattern/DoxleGeneralIcons";
import useFileHoverMenu from "../Hooks/useFileHoverMenu";

type Props = {
  fileItem?: DoxleFile;
  folderItem?: DoxleFolder;
  // inputProps?: any;
};

const FileHoverMenu = ({ fileItem, folderItem }: Props) => {
  const { doxleThemeColor } = useDoxleThemeStore((state) => ({
    doxleThemeColor: state.doxleThemeColor,
    doxleFont: state.doxleFont,
    currentTheme: state.currentTheme,
  }));
  const {
    dialogOpen,
    setDialogOpen,
    pencilIconClicked,
    trashIconClicked,
    handleDialogKeyDown,
    handleDialogTick,
    handleDialogClose,
  } = useFileHoverMenu({ fileItem, folderItem });

  return (
    <StyledFileHoverSection>
      <StyledPencilIconButton onClick={pencilIconClicked}>
        <FileEditIcon themeColor={doxleThemeColor} />
      </StyledPencilIconButton>
      <StyledTrashIconButton onClick={trashIconClicked}>
        <DoxleDeleteIcon
          themeColor={doxleThemeColor}
          containerStyle={{
            width: 16,
          }}
        />
      </StyledTrashIconButton>

      {dialogOpen && (
        <FileRenameDialog
          dialogOpen={dialogOpen}
          handleTick={handleDialogTick}
          handleKeyDown={handleDialogKeyDown}
          handleClose={handleDialogClose}
          currentHoverItem={
            folderItem ? folderItem.folderName : fileItem?.fileName
          }
        />
      )}
    </StyledFileHoverSection>
  );
};

export default FileHoverMenu;
