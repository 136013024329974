import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import DoxleAPI from "../../Services/DoxleAPI";
import { Company } from "../../Models/company";
import { BaseAPIProps } from "../../Models/baseAPIProps";
import { DoxleFile, DoxleFolder } from "../../Models/files";
import { produce } from "immer";
import {
  AxiosBackendErrorReturn,
  IApiPaginatedData,
} from "../../Models/axiosReturn";
import useSetRootFolderQueryData from "../Hooks/useSetRootFolderQueryData";
import useSetFileQueryData from "../Hooks/useSetFileQueryData";
import { AxiosProgressEvent, isAxiosError } from "axios";

export interface IFilterGetFolderQueryFilter {
  projectId?: string;
  docketId?: string;
  search?: string;
  orderBy?: string[];
}
export interface IFilterGetFileQueryFilter {
  projectId?: string;
  docketId?: string;
  folderId?: string;
  search?: string;
  orderBy?: string[];
}

//* USE QUERY TO RETRIEVE ALL THE FOLDERS
interface GetFolderQueryProps extends BaseAPIProps {
  filter: IFilterGetFolderQueryFilter;
  getFolderOnSuccessCallback?: (folderList: DoxleFolder[]) => void;
}
const useGetFolderQuery = ({
  company,
  showNotification,
  getFolderOnSuccessCallback,
  filter,
}: GetFolderQueryProps) => {
  let url = "/storage/folder/";
  const queryKey = getFolderQKey(filter, company);
  const { projectId, docketId, search, orderBy } = filter;
  const getParam: any = {};
  if (docketId) getParam.docket = docketId;
  else if (projectId) getParam.project = projectId;
  else if (!projectId && !docketId) getParam.company = company?.companyId;
  if (search) getParam.search = search;
  if (orderBy) orderBy.forEach(order => url += url.includes('?') ? `&order_by=${order}` : `?order_by=${order}`);
  const folderQuery = useQuery(
    queryKey,
    () => {
      return DoxleAPI.get(url, {
        headers: {
          "User-Company": company?.companyId,
        },
        params: getParam,
      });
    },
    {
      enabled: company !== undefined,
      retry: 1,
      cacheTime: 6 * 10 * 1000,
      staleTime: 5 * 60 * 1000,

      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchInterval: 6 * 10 * 1000,
      refetchIntervalInBackground: true,
      onSuccess: (response) => {
        if (getFolderOnSuccessCallback) {
          getFolderOnSuccessCallback(response.data);
        }
      },

      onError: (error) => {
        if (showNotification) {
          if (isAxiosError<AxiosBackendErrorReturn>(error)) {
            showNotification(
              `${error?.response?.status ?? "ERROR"}: ${
                error.response?.data.detail ?? "UNKNOWN ERROR"
              }`,
              "error",
              String(
                error?.response?.data?.detail ?? "Failed To Fetch Folder"
              ).substring(0, 300)
            );
          } else {
            showNotification(
              "Something Wrong!",
              "error",
              "Failed To Fetch Folder"
            );
          }
        }
      },
    }
  );
  return folderQuery;
};

//* USE QUERY TO RETRIEVE ALL THE FILES
interface GetFileQueryProps extends BaseAPIProps {
  filter: IFilterGetFileQueryFilter;
}
const useGetFilesQuery = ({
  company,

  showNotification,
  filter,
}: GetFileQueryProps) => {
  const queryKey = getFileQKey(filter, company);
  const { projectId, docketId, search, orderBy } = filter;
  const getParam: any = {};
  let initialUrl ="/storage/file/?page=1"
  if (projectId) getParam.project = projectId;
  else if (docketId) getParam.docket = docketId;
  else if (!projectId && !docketId) getParam.company = company?.companyId;
  if (search) getParam.search = search;
  if (orderBy) orderBy.forEach(order => initialUrl += initialUrl.includes('?') ? `&order_by=${order}` : `?order_by=${order}`);

  const filesQuery = useInfiniteQuery({
    queryKey,
    queryFn: ({ pageParam = initialUrl }) => {
      return DoxleAPI.get<IApiPaginatedData<DoxleFile>>(pageParam, {
        headers: {
          "User-Company": company?.companyId,
        },

        params: getParam,
      });
    },
    getNextPageParam: (prev) => {
      return prev.data.next;
    },
    //* FETCHING ON MOUNT WILL BE SET TO FALSE, IF ENABLED = FALSE
    enabled: company !== undefined,

    retry: 1,
    //* AFTER 5 MINUTES THE QUERY IS GARBAGE COLLECTED AND isLoading IS SET TO TRUE TO MAKE AND API CALL
    //* BACKGROUND REFETCH WILL BE TRIGGERED EVERY TIME BECAUSE STALE TIME = 0, WHILE isLoading=False, isFetching=True
    cacheTime: 6 * 60 * 1000,

    //* WHEN AN API CALL IS MADE AND THE DATA IS fetchBundle, IT GOES STALE STRAIGHTAWAY, BY SETTING THE STALE TIME TO 30 SECS, IT REMAINS FRESH FOR 30 SECS AND
    //* THEN GOES STALE, WHICH MEANS NO BACKGROUND REQUEST IS MADE WHEN THE QUERY IS FRESH. STRENGTH = BETTER OPTIMISATION. WEAKNESS = USER MIGHT SEE ODL DATA FOR 30 SECS
    //* isFetching=False FOR 30SECS
    staleTime: 5 * 60 * 1000,

    //* IF THIS IS SET TO false, WHEN THE COMPONENT IS MOUNTED, THERE WILL BE NO API CALL, DEFAULT = TRUE
    refetchOnMount: false,

    //* DEFAULT = TRUE, WHEN THE WINDOW LOOSES AND REGAINS FOCUS, THE DATA IS REFETCHED IN THE BACKGROUND AND UPDATED
    refetchOnWindowFocus: false,

    //* POLLING DATA FROM THE BACKEND AT REGULAR INTERVALS, THINK ABOUT THE STOCK MARKET IF YOUR BACKEND CHANGES CONSTANTLY THEN POLLING IS THE BETS OPTION
    refetchInterval: 6 * 60 * 1000,
    // refetchInterval:2000,

    //* THE ABOVE WILL NOT POLL WHEN THE BROWSER LOOSES FOCUS, THE BELOW COMMAND WILL POLL IRRELEVANT OF WINDOW / BROWSER FOCUS
    refetchIntervalInBackground: true,
    onSuccess: (response) => {
      // console.log('%c GET_FILES_ON_SUCCESS', 'color:green;', response.data)
    },

    onError: (error) => {
      if (showNotification) {
        if (isAxiosError<AxiosBackendErrorReturn>(error)) {
          showNotification(
            `${error?.response?.status ?? "ERROR"}: ${
              error.response?.data.detail ?? "UNKNOWN ERROR"
            }`,
            "error",
            String(
              error?.response?.data?.detail ?? "Failed To Fetch Files"
            ).substring(0, 300)
          );
        } else {
          showNotification(
            "Something Wrong!",
            "error",
            "Failed To Fetch Files"
          );
        }
      }
    },
  });
  return filesQuery;
};

//* USE QUERY TO RETRIEVE ALL THE FILES
const useGetFilesInsideFolderQuery = ({
  company,

  showNotification,
  filter,
}: GetFileQueryProps) => {
  let queryKey = getFileQKey({ folderId: filter.folderId }, company);
  const getParams: any = {
    company: company?.companyId,
  };
  if (filter.folderId) getParams.folderId = filter.folderId;

  const url = "/storage/file/";
  const filesQuery = useInfiniteQuery({
    queryKey,

    queryFn: ({ pageParam = url }) => {
      return DoxleAPI.get<IApiPaginatedData<DoxleFile>>(pageParam, {
        headers: {
          "User-Company": company?.companyId,
        },

        params: getParams,
      });
    },
    getNextPageParam: (prev) => {
      return prev.data.next;
    },
    //* FETCHING ON MOUNT WILL BE SET TO FALSE, IF ENABLED = FALSE
    enabled: company !== undefined && filter.folderId !== undefined,

    retry: 1,
    //* AFTER 5 MINUTES THE QUERY IS GARBAGE COLLECTED AND isLoading IS SET TO TRUE TO MAKE AND API CALL
    //* BACKGROUND REFETCH WILL BE TRIGGERED EVERY TIME BECAUSE STALE TIME = 0, WHILE isLoading=False, isFetching=True
    cacheTime: 10 * 60 * 1000,

    //* WHEN AN API CALL IS MADE AND THE DATA IS fetchBundle, IT GOES STALE STRAIGHTAWAY, BY SETTING THE STALE TIME TO 30 SECS, IT REMAINS FRESH FOR 30 SECS AND
    //* THEN GOES STALE, WHICH MEANS NO BACKGROUND REQUEST IS MADE WHEN THE QUERY IS FRESH. STRENGTH = BETTER OPTIMISATION. WEAKNESS = USER MIGHT SEE ODL DATA FOR 30 SECS
    //* isFetching=False FOR 30SECS
    staleTime: 5 * 60 * 1000,

    //* IF THIS IS SET TO false, WHEN THE COMPONENT IS MOUNTED, THERE WILL BE NO API CALL, DEFAULT = TRUE
    refetchOnMount: false,

    //* DEFAULT = TRUE, WHEN THE WINDOW LOOSES AND REGAINS FOCUS, THE DATA IS REFETCHED IN THE BACKGROUND AND UPDATED
    refetchOnWindowFocus: false,

    //* POLLING DATA FROM THE BACKEND AT REGULAR INTERVALS, THINK ABOUT THE STOCK MARKET IF YOUR BACKEND CHANGES CONSTANTLY THEN POLLING IS THE BETS OPTION
    refetchInterval: 4 * 60 * 1000,
    // refetchInterval:2000,

    //* THE ABOVE WILL NOT POLL WHEN THE BROWSER LOOSES FOCUS, THE BELOW COMMAND WILL POLL IRRELEVANT OF WINDOW / BROWSER FOCUS
    refetchIntervalInBackground: true,

    onSuccess: (response) => {
      console.log(
        "%c GET_FILES_ON_SUCCESS",
        "background:green; color:white;",
        response.pages[0].data.results
      );
    },

    onError: (error) => {
      if (showNotification) {
        if (isAxiosError<AxiosBackendErrorReturn>(error)) {
          showNotification(
            `${error?.response?.status ?? "ERROR"}: ${
              error.response?.data.detail ?? "UNKNOWN ERROR"
            }`,
            "error",
            String(
              error?.response?.data?.detail ?? "Failed To Fetch Files"
            ).substring(0, 300)
          );
        } else {
          showNotification(
            "Something Wrong!",
            "error",
            "Failed To Fetch Files"
          );
        }
      }
    },
  });

  return filesQuery;
};

interface AddFolderQueryProps extends BaseAPIProps {
  onAddFolderSuccessCallback?: (newFolder?: DoxleFolder) => void;
  filter: IFilterGetFolderQueryFilter;
}

const useAddFolderQuery = ({
  company,
  showNotification,
  onAddFolderSuccessCallback,
  filter,
}: AddFolderQueryProps) => {
  const { handleAddFolder } = useSetRootFolderQueryData({ filter });
  const addFolderMutation = useMutation({
    mutationKey: getFolderMutateQKey("add"),
    mutationFn: async (data: DoxleFolder) => {
      return addFolder(company, data);
    },
    onSuccess(response, request, context) {
      if (response?.data) handleAddFolder(response?.data);
      if (onAddFolderSuccessCallback) {
        onAddFolderSuccessCallback(response?.data);
        console.log("Success Added Folder");
      }
      // if (showNotification){
      //   showNotification('Its a success', 'success')
      // }
    },
    onError(error, variables, context) {
      if (showNotification) {
        if (isAxiosError<AxiosBackendErrorReturn>(error)) {
          showNotification(
            `${error?.response?.status ?? "ERROR"}: ${
              error.response?.data.detail ?? "UNKNOWN ERROR"
            }`,
            "error",
            String(
              error?.response?.data?.detail ?? "Failed To Add Folder"
            ).substring(0, 300)
          );
        } else {
          showNotification("Something Wrong!", "error", "Failed To Add Folder");
        }
      }
    },
  });

  const mutate = (data: DoxleFolder) => addFolderMutation.mutate(data);

  return { ...addFolderMutation, mutate: mutate };
};

//* ADD NEW FOLDER
const addFolder = async (
  company: Company | undefined,
  newFolder: DoxleFolder
) => {
  try {
    return DoxleAPI.post<DoxleFolder>("/storage/folder/", newFolder, {
      headers: {
        "User-Company": company?.companyId,
      },
    });
  } catch (error: any) {
    console.error("Print Error");
    console.error(error.message);
  }
};

interface AddFileQueryProps extends BaseAPIProps {
  filter: IFilterGetFileQueryFilter;
  addFileCallback?: Function;
  uploadProgressCallback?: (percentComplete: number) => void;
}
export interface IAddFileMutateProps {
  docketId?: string;
  projectId?: string;
  folderId?: string;
  files: File[];
  createFolderName?: string;
}

const useAddFileQuery = ({
  company,
  showNotification,
  addFileCallback,
  uploadProgressCallback,
  filter,
}: AddFileQueryProps) => {
  const { handleAddMultipleFile } = useSetFileQueryData({
    filter,
    isFolderFile: Boolean(filter.folderId),
  });
  //* CURRENT FOLDER ID IS PASSED AS A PARAM BUT ALSO IN THE DATA OBJECT
  const queryClient = useQueryClient();
  const addFileMutation = useMutation({
    mutationKey: getFileMutateKey("add"),
    mutationFn: async ({
      files,
      docketId,
      projectId,
      folderId,
      createFolderName,
    }: IAddFileMutateProps) => {
      const formData = new FormData();
      if (projectId) formData.append("projectId", projectId);
      if (docketId) formData.append("docketId", docketId);
      if (folderId) formData.append("folderId", folderId);
      if (createFolderName)
        formData.append("createFolderName", createFolderName);
      //* LOOP THROUGH EACH FILE AND PASS IT TO THE BACK END VIA FORM DATA

      files.forEach((file) => {
        formData.append("files", file);
      });

      return DoxleAPI.post<{
        files: DoxleFile[];
        errors: any;
      }>("/storage/file/", formData, {
        headers: {
          "User-Company": company?.companyId,
        },

        onUploadProgress: (progressEvent: AxiosProgressEvent) => {
          console.log(
            "progressEvent" + progressEvent.loaded + ":" + progressEvent.total
          );
          if (progressEvent.total) {
            let percentCompleted = Math.round(
              (progressEvent.loaded * 100) / (progressEvent.total * 1.1)
            );
            console.log("progressCompleted:Query" + percentCompleted + "%");
            if (uploadProgressCallback)
              uploadProgressCallback(percentCompleted);
          }
        },
      });
    },

    onSuccess(response, variables, context) {
      if (addFileCallback) {
        addFileCallback();
      }
      const queryKey = getFolderQKey(filter, company);
      if (variables.createFolderName) queryClient.invalidateQueries(queryKey);
      handleAddMultipleFile(response.data.files);
    },

    onError(error, variables, context) {
      if (showNotification) {
        if (isAxiosError<AxiosBackendErrorReturn>(error)) {
          showNotification(
            `${error?.response?.status ?? "ERROR"}: ${
              error.response?.data.detail ?? "UNKNOWN ERROR"
            }`,
            "error",
            String(
              error?.response?.data?.detail ?? "Failed To Add Files"
            ).substring(0, 300)
          );
        } else {
          showNotification("Something Wrong!", "error", "Failed To Add Files");
        }
      }
    },
  });
  // * OVERRIDE MUTATE Function
  const mutate = (props: IAddFileMutateProps) => addFileMutation.mutate(props);

  // * CUSTOM HOOK ALWAYS NEEDS A RETURN THE HOOK
  return { ...addFileMutation, mutate: mutate };
};

interface UpdateFolderQueryProps extends BaseAPIProps {
  onUpdateFolderSuccessCallback?: (folder: DoxleFolder) => void;
  filter: IFilterGetFolderQueryFilter;
}

export type updateFolderObject = {
  folderId: string;
  folderName: string;
};

const updateFolder = (
  company: Company | undefined,
  folderId: string,
  folderName: string
) => {
  //  console.log(`Updating Fodler name with: ${folderId} and ${folderName}`)
  return DoxleAPI.patch(
    "/storage/folder/" + folderId + "/",
    { folderName: folderName },
    {
      headers: {
        "User-Company": company?.companyId,
      },
      // params:{
      //   folderName: folderName
      // }
    }
  );
};

const useUpdateFolderQuery = ({
  company,
  showNotification,
  onUpdateFolderSuccessCallback,
  filter,
}: UpdateFolderQueryProps) => {
  const { handleEditFolder } = useSetRootFolderQueryData({ filter });
  const updateFolderMutation = useMutation({
    mutationKey: getFolderMutateQKey("update"),
    mutationFn: async (props: { folderId: string; folderName: string }) => {
      const { folderId, folderName } = props;
      if (folderId && folderName)
        return updateFolder(company, folderId, folderName);
      else {
        if (showNotification) {
          showNotification("Invalid Folder Name", "error");
        }
        console.error(`Invalid Folder Name `);
      }
    },
    //* VARIABLE IS WHAT WEW PASS IN MUTATE FUNCTION
    //* DATA IS SERVER RESPONSE

    onSuccess(response, request, context) {
      // const currentFolderId = request.folderId;
      // const queryKey = ["FOLDERS-QUERY-KEY", company?.companyId];
      if (onUpdateFolderSuccessCallback) {
        onUpdateFolderSuccessCallback(response?.data as DoxleFolder);
      }
      handleEditFolder(response?.data);
      // queryClient.setQueryData(queryKey, (staleData: any) => {
      //   let newArrayData: DoxleFolder[] = [];
      //   return staleData
      //     ? {
      //         ...staleData,
      //         data: [
      //           ...(staleData.data as DoxleFolder[]).map((folderItem) => {
      //             if (
      //               folderItem.folderId ===
      //               (response?.data as DoxleFolder).folderId
      //             ) {
      //               return response?.data;
      //             } else {
      //               return folderItem;
      //             }
      //           }),
      //         ],
      //       }
      //     : staleData;
      // });
    },
    onError(error, variables, context) {
      if (showNotification) {
        if (isAxiosError<AxiosBackendErrorReturn>(error)) {
          showNotification(
            `${error?.response?.status ?? "ERROR"}: ${
              error.response?.data.detail ?? "UNKNOWN ERROR"
            }`,
            "error",
            String(
              error?.response?.data?.detail ?? "Failed To Update Folder"
            ).substring(0, 300)
          );
        } else {
          showNotification(
            "Something Wrong!",
            "error",
            "Failed To Update Folder"
          );
        }
      }
    },
  });

  // * OVERRIDE MUTATE Function
  const mutate = (props: { folderId: string; folderName: string }) =>
    updateFolderMutation.mutate(props);

  // * CUSTOM HOOK ALWAYS NEEDS A RETURN THE HOOK
  return { ...updateFolderMutation, mutate: mutate };
};

interface UpdateFileQueryProps extends BaseAPIProps {
  filter: IFilterGetFileQueryFilter;
  onUpdateFileCallback?: Function;
}

export type blobUpdateFile = {
  fileId: string | undefined;
  fileName: string | undefined;
  folderId?: string;
};

const useUpdateFileQuery = ({
  company,
  showNotification,
  onUpdateFileCallback,
  filter,
}: UpdateFileQueryProps) => {
  const { handleUpdateFile } = useSetFileQueryData({
    filter,
    isFolderFile: Boolean(filter.folderId),
  });

  const updateFileMutation = useMutation({
    mutationKey: getFileMutateKey("update"),
    mutationFn: async (props: {
      fileId: string;
      fileName: string;
      currentFolderId?: string;
      currentFolderName?: string;
    }) => {
      const { fileId, fileName, currentFolderId, currentFolderName } = props;
      return DoxleAPI.patch<DoxleFile>(
        "/storage/file/" + fileId + "/",
        {
          fileName: fileName,
          currentFolderId: currentFolderId,
          currentFolderName: currentFolderName,
        },
        {
          headers: {
            "User-Company": company?.companyId,
          },
        }
      );
    },
    onSuccess(response, request, context) {
      handleUpdateFile(response.data);
      if (onUpdateFileCallback) {
        onUpdateFileCallback();
      }
    },
    onError(error, variables, context) {
      if (showNotification) {
        if (isAxiosError<AxiosBackendErrorReturn>(error)) {
          showNotification(
            `${error?.response?.status ?? "ERROR"}: ${
              error.response?.data.detail ?? "UNKNOWN ERROR"
            }`,
            "error",
            String(
              error?.response?.data?.detail ?? "Failed To Update Files"
            ).substring(0, 300)
          );
        } else {
          showNotification(
            "Something Wrong!",
            "error",
            "Failed To Update Files"
          );
        }
      }
    },
  });

  const mutate = (props: {
    fileId: string;
    fileName: string;
    currentFolderId?: string;
    currentFolderName?: string;
  }) => updateFileMutation.mutate(props);

  // * CUSTOM HOOK ALWAYS NEEDS A RETURN THE HOOK
  return { ...updateFileMutation, mutate: mutate };
};

interface DeleteFolderQueryProps extends BaseAPIProps {
  onDeleteFolderCallback?: Function;
  filter: IFilterGetFolderQueryFilter;
  // currentFolderId?:string;
}
const deleteFolder = (company: Company | undefined, folderIds: string[]) => {
  const delete_folders_url = "/storage/folder/delete/";
  const params = {
    docketId: null,
    projectId: null,
    companyId: company?.companyId,
    folders: folderIds,
  };

  return DoxleAPI.post(delete_folders_url, params, {
    headers: {
      "User-Company": company?.companyId,
    },
  });
};

const useDeleteFolderQuery = ({
  company,
  showNotification,
  onDeleteFolderCallback,
  filter,
}: DeleteFolderQueryProps) => {
  const { handleDeleteMultipleFolders } = useSetRootFolderQueryData({ filter });
  const deleteFolderMutation = useMutation({
    mutationKey: getFolderMutateQKey("delete"),
    mutationFn: async (data: string[]) => {
      return deleteFolder(company, data);
    },
    onSuccess(response, request, context) {
      if (onDeleteFolderCallback) {
        onDeleteFolderCallback();
      }
      handleDeleteMultipleFolders(request);
      // for (let i = 0; i < response.data.length; i++) {
      //   console.log(response.data[i]);
      // }
      // queryClient.setQueryData(queryKey, (staleData: any) => {
      //   // console.log("Stale Data - Printing Stale Data ...")
      //   // console.log(staleData)
      //   // for(let i=0; i<staleData.data.length;i++){
      //   //   console.log(staleData[i])
      //   // }

      //   return staleData
      //     ? { ...staleData, data: [...response.data] }
      //     : staleData;
      // });
    },
    onError(error, variables, context) {
      if (showNotification) {
        if (isAxiosError<AxiosBackendErrorReturn>(error)) {
          showNotification(
            `${error?.response?.status ?? "ERROR"}: ${
              error.response?.data.detail ?? "UNKNOWN ERROR"
            }`,
            "error",
            String(
              error?.response?.data?.detail ?? "Failed To Delete Folder"
            ).substring(0, 300)
          );
        } else {
          showNotification(
            "Something Wrong!",
            "error",
            "Failed To Delete Folder"
          );
        }
      }
    },
  });

  // * OVERRIDE MUTATE Function
  const mutate = (data: string[]) => deleteFolderMutation.mutate(data);

  // * CUSTOM HOOK ALWAYS NEEDS A RETURN THE HOOK
  return { ...deleteFolderMutation, mutate: mutate };
};

interface DeleteFileQueryProps extends BaseAPIProps {
  onDeleteFileCallback?: (deletedIds?: string[]) => void;
  filter: IFilterGetFileQueryFilter;
}

export interface IDeleteFileQueryParams {
  files: DoxleFile[];
  currentFolderId?: string;
  currentFolderName?: string;
}
const useDeleteFileQuery = ({
  company,

  showNotification,
  onDeleteFileCallback,
  filter,
}: DeleteFileQueryProps) => {
  const { handleRemoveMultipleFile } = useSetFileQueryData({
    filter,
    isFolderFile: Boolean(filter.folderId),
  });
  const deleteFileMutation = useMutation({
    mutationKey: getFileMutateKey("delete"),
    mutationFn: async (props: IDeleteFileQueryParams) => {
      const { files, currentFolderId, currentFolderName } = props;
      return DoxleAPI.post(
        "/storage/file/delete_multiple/",
        {
          files: files,
          currentFolderId: currentFolderId,
        },
        {
          headers: {
            "User-Company": company?.companyId,
          },
        }
      );
    },
    onSuccess(response, request, context) {
      if (onDeleteFileCallback)
        onDeleteFileCallback(request.files.map((file) => file.fileId));
      handleRemoveMultipleFile(request.files);
    },
    onError(error, variables, context) {
      if (showNotification) {
        if (isAxiosError<AxiosBackendErrorReturn>(error)) {
          showNotification(
            `${error?.response?.status ?? "ERROR"}: ${
              error.response?.data.detail ?? "UNKNOWN ERROR"
            }`,
            "error",
            String(
              error?.response?.data?.detail ?? "Failed To Delete Files"
            ).substring(0, 300)
          );
        } else {
          showNotification(
            "Something Wrong!",
            "error",
            "Failed To Delete Files"
          );
        }
      }
    },
  });

  // * OVERRIDE MUTATE Function
  const mutate = (props: IDeleteFileQueryParams) =>
    deleteFileMutation.mutate(props);

  // * CUSTOM HOOK ALWAYS NEEDS A RETURN THE HOOK
  return { ...deleteFileMutation, mutate: mutate };
};

//# HELPER FUNCTIONS
export const getFolderQKey = (
  filter: IFilterGetFolderQueryFilter,
  company: Company | undefined
) => {
  let queryKey = ["FOLDERS-QUERY-KEY", company?.companyId];
  const { projectId, docketId, search } = filter;
  if (docketId) queryKey.push(`docketId:${docketId}`);
  else if (projectId) queryKey.push(`projectId:${projectId}`);

  if (search) queryKey.push(`search:${search}`);
  return queryKey;
};

export const getFileQKey = (
  filter: IFilterGetFileQueryFilter,
  company: Company | undefined
) => {
  let queryKey = ["FILES-QUERY-KEY", company?.companyId];
  const { search, projectId, docketId, folderId } = filter;
  if (folderId) queryKey.push(`folder:${folderId}`);
  else if (projectId) queryKey.push(`project:${projectId}`);
  else if (docketId) queryKey.push(`docket:${docketId}`);
  if (search) queryKey.push(`search:${search}`);

  return queryKey;
};

export const getFileInsideFolderQKey = (
  company: Company | undefined,
  folderId?: string
) => {
  let queryKey = ["FILES-INSIDE-FOLDER-QUERY-KEY"];
  if (company) queryKey.push(company?.companyId);
  if (folderId) queryKey.push(folderId);

  return queryKey;
};

export const getFileMutateKey = (action: "add" | "delete" | "update") => [
  `${action}-file`,
];

export const getFolderMutateQKey = (action: "add" | "delete" | "update") => [
  `${action}-folder`,
];
const FilesQueryAPI = {
  useGetFolderQuery,
  useGetFilesQuery,
  useGetFilesInsideFolderQuery,

  useAddFolderQuery,
  useAddFileQuery,

  useUpdateFolderQuery,
  useUpdateFileQuery,

  useDeleteFolderQuery,
  useDeleteFileQuery,
};

export default FilesQueryAPI;
