import { editRgbaAlpha } from "../../Utilities/FunctionUtilities";
import {
  StyledDoxleDialogHelperActions,
  StyledDoxleDialogHelperContainer,
  StyledDoxleDialogHelperContent,
  StyledDoxleDialogHelperEditButton,
  StyledDoxleDialogHelperText,
  StyledDoxleDialogHelperTitle,
} from "../../DoxleDesignPattern/DoxleDialogHelper/StyledComponentDoxleDialogHelper";
import React, { MouseEvent, useRef, useState } from "react";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import DoxleCheckbox from "../../DoxleDesignPattern/DoxleCheckbox/DoxleCheckbox";
import ProjectQueryAPI from "../../Services/QueryHooks/projectQueryAPI";
import { useDoxleCurrentContextStore } from "../../DoxleGeneralStore/useDoxleCurrentContext";
import useDoxleNotificationStore from "../../DoxleGeneralStore/useDoxleNotificationStore";
import { useParams } from "react-router-dom";
import ExportExcelButton from "./ExportExcelButton";
import ExportPDFButton from "./ExportPDFButton";
import { StyledFormatButtonsContainer } from "../Projects/StyledProjectComponents";
import { StyledFileButton, StyledFileIcon } from "./SyledComponents";
import { DoxleCSVIcon } from "../../DoxleDesignPattern/DoxleGeneralIcons";

interface Props {
  type?: "pdf" | "excel" | "csv";
  closeDialog: () => void;
}
const ExportOptionDialog = ({ type, closeDialog }: Props) => {
  const [exportFormats, setExportFormats] = useState<
    ("pdf" | "csv" | "excel")[]
  >(type ? [type] : []);
  const { projectId } = useParams();
  const styleProps = useDoxleThemeStore(
    (state) => ({
      $themeColor: state.doxleThemeColor,
      $doxleFont: state.doxleFont,
    }),
    shallow
  );
  const budgetCheckboxRef = useRef<HTMLInputElement>(null);
  const ordersCheckboxRef = useRef<HTMLInputElement>(null);
  const xeroCheckboxRef = useRef<HTMLInputElement>(null);
  const runningCheckboxRef = useRef<HTMLInputElement>(null);
  const { company, project } = useDoxleCurrentContextStore(
    (state) => ({
      company: state.currentCompany,
      project: state.currentProject,
    }),
    shallow
  );
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );
  const shareProjectQuery = ProjectQueryAPI.useShareProjectLink({
    company,
    showNotification,
  });
  const handleConfirm = (e: MouseEvent<HTMLButtonElement>) => {
    const budget: boolean = budgetCheckboxRef?.current?.checked ?? false;
    const orders: boolean = ordersCheckboxRef?.current?.checked ?? false;
    const xero: boolean = xeroCheckboxRef?.current?.checked ?? false;
    const running: boolean = runningCheckboxRef?.current?.checked ?? false;
    console.log("projectId", projectId);
    console.log("options", { budget, orders, xero, running });
    if ((projectId || project) && (budget || orders || xero || running))
      shareProjectQuery.exportProject.mutate({
        projectId: projectId ?? project?.projectId ?? "",
        options: { budget, orders, xero, running, formats: exportFormats },
      });
    closeDialog();
  };
  const handleExcelClick = () => {
    setExportFormats((prev) => {
      if (prev.includes("excel"))
        return [...prev.filter((opt) => opt !== "excel")];
      else return [...prev, "excel"];
    });
  };
  const handlePdfClick = () => {
    setExportFormats((prev) => {
      if (prev.includes("pdf")) return [...prev.filter((opt) => opt !== "pdf")];
      else return [...prev, "pdf"];
    });
  };
  const handleCsvClick = () => {
    setExportFormats((prev) => {
      if (prev.includes("csv")) return [...prev.filter((opt) => opt !== "csv")];
      else return [...prev, "csv"];
    });
  };
  return (
    <StyledDoxleDialogHelperContainer
      {...styleProps}
      open
      onClose={closeDialog}
      PaperProps={{
        sx: {
          bgColor: styleProps.$themeColor.primaryContainerColor,
          borderRadius: "0.9rem",
        },
        elevation: 4,
      }}
      slotProps={{
        backdrop: {
          sx: {
            bgcolor: editRgbaAlpha({
              rgbaColor: styleProps.$themeColor.primaryBackgroundColor,
              alpha: "0.4",
            }),
          },
        },
      }}
    >
      <StyledDoxleDialogHelperTitle {...styleProps}>
        Export Options
      </StyledDoxleDialogHelperTitle>

      <StyledDoxleDialogHelperContent {...styleProps}>
        <StyledDoxleDialogHelperText {...styleProps}>
          Select the formats you would like to export
        </StyledDoxleDialogHelperText>
        <StyledFormatButtonsContainer>
          <StyledFileButton
            {...styleProps}
            onClick={handlePdfClick}
            $selected={exportFormats.includes("pdf")}
          >
            <StyledFileIcon
              height="5.5rem"
              src={"/assets/icons/adobe-pdf-icon.svg"}
            />
          </StyledFileButton>
          <StyledFileButton
            {...styleProps}
            onClick={handleExcelClick}
            $selected={exportFormats.includes("excel")}
          >
            <StyledFileIcon
              height="5.5rem"
              src={"/assets/icons/excel-icon.png"}
            />
          </StyledFileButton>
          {/*<StyledFileButton*/}
          {/*  {...styleProps} onClick={handleCsvClick} $selected={exportFormats.includes('csv')} $hPadding={'2rem'}>*/}
          {/*  <DoxleCSVIcon width={'5.5rem'}  themeColor={styleProps.$themeColor}/>*/}
          {/*</StyledFileButton>*/}
        </StyledFormatButtonsContainer>
        <StyledDoxleDialogHelperText {...styleProps}>
          Check the fields you would like exported on your document
        </StyledDoxleDialogHelperText>
        <DoxleCheckbox labelText={"Budget"} inputRef={budgetCheckboxRef} />
        <DoxleCheckbox
          labelText={"Orders"}
          inputRef={ordersCheckboxRef}
          defaultChecked={true}
        />
        <DoxleCheckbox labelText={"Xero Payments"} inputRef={xeroCheckboxRef} />
        <DoxleCheckbox
          labelText={"Running Totals"}
          inputRef={runningCheckboxRef}
        />
      </StyledDoxleDialogHelperContent>
      <StyledDoxleDialogHelperActions {...styleProps}>
        <div
          style={{
            flexDirection: "row",
            display: "flex",
            width: "100%",
            justifyContent: "center",
          }}
        >
          <StyledDoxleDialogHelperEditButton
            key={`export-dialog#0`}
            className={`0-export-action-cancel`}
            $bgColor={styleProps.$themeColor.primaryFontColor}
            {...styleProps}
            $dialogDescription={"export-project"}
            $buttonIndex={0}
            onClick={() => closeDialog()}
            layout
          >
            <span className="button-text">Cancel</span>
          </StyledDoxleDialogHelperEditButton>
          &nbsp;&nbsp;
          <StyledDoxleDialogHelperEditButton
            key={`export-dialog#1`}
            className={`1-export-action-confirm`}
            $bgColor={styleProps.$themeColor.primaryContainerColor}
            sx={{
              border: `1px solid ${styleProps.$themeColor.primaryDividerColor}`,
            }}
            {...styleProps}
            $dialogDescription={"export-project"}
            $buttonIndex={1}
            onClick={handleConfirm}
            layout
          >
            <span
              className="button-text"
              style={{
                color: styleProps.$themeColor.primaryFontColor,
              }}
            >
              Export Project
            </span>
          </StyledDoxleDialogHelperEditButton>
        </div>
      </StyledDoxleDialogHelperActions>
    </StyledDoxleDialogHelperContainer>
  );
};
export default ExportOptionDialog;
