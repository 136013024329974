import React from "react";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { useDropzone } from "react-dropzone";
import useQuoteResponseAttachment from "./Hooks/useQuoteResponseAttachment";
import {
  StyledQuoteResponseFieldWrapper,
  StyledQuoteResponseLabelFieldText,
  StyledRequestAttachmentContainer,
} from "./StyledComponentQuoteResponse";
import { IoDocumentTextOutline } from "react-icons/io5";
import { useQuoteContext } from "./QuoteResponse";
import AddedResponseAttachment from "./AddedResponseAttachment";
import { motion } from "framer-motion";
import { Button } from "@mui/material";
import QuoteRequestAttachmentItem from "./QuoteRequestAttachmentItem";

type Props = {};
const AnimatedButton = motion(Button);
const QuoteResponseAttachment = (props: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore((state) => ({
    doxleThemeColor: state.doxleThemeColor,
    doxleFont: state.doxleFont,
  }));
  const { onDrop } = useQuoteResponseAttachment({});

  const { getRootProps } = useDropzone({
    onDrop,
    accept: {
      "image/*": [".png", ".jpg", ".jpeg"],
      "application/pdf": [".pdf"],
      "application/msword": [".doc"],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        [".docx"],
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
        ".xlsx",
      ],
    },
    multiple: true,
    // noClick: addedScopeImages.length > 0 ? true : false,
  });

  const { addedAttachments, quoteRequestData } = useQuoteContext();
  return (
    <StyledQuoteResponseFieldWrapper>
      <StyledQuoteResponseLabelFieldText
        $themeColor={doxleThemeColor}
        $doxleFont={doxleFont}
      >
        Attachments
      </StyledQuoteResponseLabelFieldText>
      {quoteRequestData && quoteRequestData?.attachments && (
        <StyledRequestAttachmentContainer
          $themeColor={doxleThemeColor}
          $doxleFont={doxleFont}
        >
          {quoteRequestData.attachments.map((rqAttachment) => (
            <QuoteRequestAttachmentItem
              key={rqAttachment.attachmentId}
              attachment={rqAttachment}
            />
          ))}
        </StyledRequestAttachmentContainer>
      )}
      <div className="attachment-displayer-section">
        {addedAttachments.map((attachment, idx) => (
          <AddedResponseAttachment
            attachment={attachment}
            index={idx}
            key={`added${idx}`}
          />
        ))}
      </div>
      <AnimatedButton
        layout
        {...getRootProps({
          variant: "outlined",
          startIcon: (
            <IoDocumentTextOutline
              style={{ color: doxleThemeColor.primaryFontColor }}
              size="1.6rem"
            />
          ),
          sx: {
            borderRadius: "0px 0.9rem 0.9rem 1.2rem !important",
            border: `1px solid ${doxleThemeColor.rowBorderColor}`,
            color: doxleThemeColor.primaryFontColor,
            fontSize: "1.4rem",
            textTransform: "none",
            marginTop: "2rem",
            width: "15.1rem",
          },
        })}
      >
        Choose File
      </AnimatedButton>
    </StyledQuoteResponseFieldWrapper>
  );
};

export default QuoteResponseAttachment;
