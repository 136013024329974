import React, { useEffect, useMemo } from "react";
import useDoxleNotificationStore from "../../DoxleGeneralStore/useDoxleNotificationStore";
import { useDoxleAuthStore } from "../../DoxleGeneralStore/useDoxleAuthStore";
import { shallow } from "zustand/shallow";
import { useDoxleCurrentContextStore } from "../../DoxleGeneralStore/useDoxleCurrentContext";
import DrawingQueryAPI, {
  IDrawingRetrieveQueryProps,
} from "../QueryHooks/drawingQueryAPI";
import { DrawingSet } from "../Models/drawings";
import { useProjectStore } from "../../DoxleGeneralStore/useprojectStore";

type Props = {};

interface DrawingsQueryFilter {}
interface GetDrawingList {
  drawingsList: DrawingSet[];
  isFetchingDrawingList: boolean;
  isSuccessFetchingDrawingList: boolean;
  isErrorFetchingDrawingList: boolean;
  isFetchingNextPageDrawingList: boolean;
  handleFetchNextPageDrawing: () => void;
  hasNextPage: boolean;
}
const useGetDrawingList = (props: Props): GetDrawingList => {
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );
  const { currentProject, company, currentSearchText } =
    useDoxleCurrentContextStore(
      (state) => ({
        currentProject: state.currentProject,
        company: state.currentCompany,
        currentSearchText: state.currentSearchText,
      }),
      shallow
    );

  const retrievedDrawings = DrawingQueryAPI.useRetrieveDrawingQuery({
    company,
    showNotification,
    projectId: currentProject?.projectId || "",
    search: currentSearchText,
  });

  const drawingsList = useMemo(
    () =>
      retrievedDrawings.isSuccess
        ? retrievedDrawings.data.pages.reduce((acc, data) => {
            return acc.concat(data.data.results);
          }, [] as DrawingSet[])
        : [],
    [retrievedDrawings.data]
  );

  const handleFetchNextPage = () => {
    if (retrievedDrawings.hasNextPage) retrievedDrawings.fetchNextPage();
  };

  return {
    drawingsList,
    isFetchingDrawingList: retrievedDrawings.isLoading,
    isSuccessFetchingDrawingList: retrievedDrawings.isSuccess,
    isErrorFetchingDrawingList: retrievedDrawings.isError,
    isFetchingNextPageDrawingList: retrievedDrawings.isFetchingNextPage,
    handleFetchNextPageDrawing: handleFetchNextPage,
    hasNextPage: Boolean(retrievedDrawings.hasNextPage),
  };
};

export default useGetDrawingList;
