import FileSubheader from "./FileSubheader";

import { StyledHeaderContainer } from "./StyledFilesHeaderComponent";
import { useFileStore } from "../Store/useFileStore";
import { useShallow } from "zustand/react/shallow";

type Props = {};

const FileHeader = (props: Props) => {
  const currentLevel = useFileStore(useShallow((state) => state.currentLevel));
  return (
    <StyledHeaderContainer $reduce={currentLevel !== "Company"}>
      <FileSubheader />
    </StyledHeaderContainer>
  );
};

export default FileHeader;
