import { motion } from "framer-motion";
import React, { useState } from "react";
import styled from "styled-components";
import { IoIosCloseCircle } from "react-icons/io";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import { IconBaseProps } from "react-icons";
import CircularProgress from "@mui/material/CircularProgress";
type Props = {
  deleteIcon?: React.ReactNode; //overwrite default delete icon
  buttonWrapperStyle?: React.CSSProperties; //overwrite style of the button wrapper
  defaultIconProps?: IconBaseProps; //manipulate default icon props

  deleteTextStyle?: React.CSSProperties; //overwrite style of the delete text
  onConfirmDelete?: (e?: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  isDeleting?: boolean;
};

const DoxleExpandableDeleteBtn = ({
  deleteIcon,
  buttonWrapperStyle,
  defaultIconProps,
  deleteTextStyle,
  isDeleting,
  onConfirmDelete,
}: Props) => {
  const [onConfirm, setOnConfirm] = useState(false);
  const { doxleThemeColor } = useDoxleThemeStore((state) => ({
    doxleThemeColor: state.doxleThemeColor,
  }));

  const handleClickBtn = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    if (onConfirm) {
      if (onConfirmDelete) {
        onConfirmDelete();
      }
      setOnConfirm(false);
    } else {
      setOnConfirm(true);
    }
  };
  return (
    <StyledCardMenuHoverButton
      $onConfirm={onConfirm}
      style={buttonWrapperStyle}
      className={onConfirm ? "confirm-style" : ""}
      onClick={handleClickBtn}
      onMouseLeave={() => setOnConfirm(false)}
    >
      {isDeleting ? (
        <CircularProgress size={14} color="warning" />
      ) : deleteIcon ? (
        deleteIcon
      ) : (
        <IoIosCloseCircle
          color={onConfirm ? "white" : doxleThemeColor.primaryFontColor}
          size={20}
          {...defaultIconProps}
        />
      )}
      {!isDeleting && (
        <span className="btn-text" style={deleteTextStyle}>
          {onConfirm ? "Confirm" : "Delete"}
        </span>
      )}
    </StyledCardMenuHoverButton>
  );
};

export default DoxleExpandableDeleteBtn;

const StyledCardMenuHoverButton = styled(motion.div)<{
  $onConfirm: boolean;
}>`
  display: flex;
  align-items: center;
  padding: 5px;
  overflow: hidden;
  transition: all 0.3s ease;
  border-radius: 14px;
  cursor: pointer;
  .btn-text {
    font-family: ${(p) => p.theme.font.primaryFont};
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: ${(p) => (p.$onConfirm ? "white" : p.theme.color.primaryFontColor)};

    max-width: 0px;

    transition: all 0.4s ease;
    opacity: 0;
  }

  &:hover {
    background-color: ${(p) =>
      p.$onConfirm
        ? p.theme.color.doxleColor
        : p.theme.color.primaryContainerColor};
    box-shadow: 0px 4px 4px ${(p) => p.theme.color.primaryBoxShadowColor};
    .btn-text {
      margin: 0px 10px;
      opacity: 1;
      max-width: 100px;
      transform: translateX(0);
    }
  }
`;
