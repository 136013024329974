import Button from "@mui/material/Button";
import { motion } from "framer-motion";
import styled from "styled-components";
import { editRgbaAlpha } from "../../../Utilities/FunctionUtilities";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";

export const StyledShareProjectBtn = styled(motion(Button))`
  && {
    height: 30px;
    padding: 8px !important;
    background: none;
    border-radius: 6px !important;
    font-family: ${(p) => p.theme.font.primaryFont};
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: 0.05em;
    color: #fff;
    text-transform: none;
    background-color: ${(p) => p.theme.color.doxleColor3};
    transition: all 0.4s ease;
  }
  &:hover {
    background: ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.theme.color.doxleColor3,
        alpha: "0.7",
      })} !important;
    color: white;
  }
`;
export const StyledShareProjectDialog = styled(Dialog)`
  && {
  }
  & .MuiDialog-paper {
    background-color: ${(p) => p.theme.staticMenuColor.staticBg};
    box-shadow: 0px 2px 8px 2px ${(p) => p.theme.color.primaryBoxShadowColor};
    margin: 14px !important;
    min-width: 450px;
    max-width: 600px;
    max-height: 800px;
    min-height: 500px;
    height: 100vh;
    width: calc(100vw - 28px);
    display: flex;
    flex-direction: column;
  }
`;

export const StyledShareProjectDialogTitle = styled(DialogTitle)`
  && {
    border-bottom: 1px solid ${(p) => p.theme.staticMenuColor.staticDivider};
    background-color: ${(p) => p.theme.staticMenuColor.staticBg};
    padding: 10px 14px !important;
    width: calc(100% - 28px);
    display: flex;
    align-items: center;
    .project-address {
      font-family: ${(p) => p.theme.font.primaryFont};
      font-style: normal;
      font-weight: 500;
      font-size: 14px;

      color: ${(p) => p.theme.staticMenuColor.staticFontColor};
      flex: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      height: fit-content;
    }

    .copy-link-section {
      display: flex;
      margin: 0px 14px 0px 8px;
      align-items: center;
      cursor: pointer;
      font-family: ${(p) => p.theme.staticMenuColor.staticFontColor};
      font-style: normal;
      font-weight: 500;
      font-size: 14px;

      color: ${(p) =>
        editRgbaAlpha({
          rgbaColor: p.theme.color.doxleColor,
          alpha: "0.7",
        })};
    }
  }
`;
export const StyledShareProjectDialogContent = styled(DialogContent)`
  && {
    background-color: ${(p) => p.theme.staticMenuColor.staticBg};
    width: calc(100% - 28px);
    padding: 14px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .loader-text {
      font-family: ${(p) => p.theme.font.primaryFont};
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      color: ${(p) => p.theme.staticMenuColor.staticFontColor};
      display: flex;
      margin-bottom: 14px;
    }
  }
`;
export const StyledShareListScroller = styled.div`
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

export const StyledSharedUserItem = styled(motion.div)`
  width: 100%;
  display: flex;
  align-items: center;
  min-height: 30px;
  padding: 14px 0px;

  .user-name {
    font-family: ${(p) => p.theme.font.primaryFont};
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: ${(p) => p.theme.staticMenuColor.staticFontColor};

    margin: 0px 10px;
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-transform: capitalize;
  }

  .permission-toggle {
    font-family: ${(p) => p.theme.font.primaryFont};
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: ${(p) => p.theme.staticMenuColor.staticFontColor};
    display: flex;
    align-items: center;
    cursor: pointer;

    .icon-dropdown {
      margin-left: 4px;
      transform: translateY(0) rotate(0);
      transition: transform 0.3s ease; /* Smooth transition for hover effect */
    }

    &:hover .icon-dropdown {
      animation: hoverAnimation 0.3s ease forwards;
    }

    @keyframes hoverAnimation {
      0%,
      100% {
        transform: translateY(0);
      }

      50% {
        transform: translateY(-3px);
      }
    }
  }
`;

export const StyledEditPermissionForm = styled(motion.div)`
  background-color: ${(p) => p.theme.staticMenuColor.staticHover};
  width: 200px;
  display: flex;
  flex-direction: column;
  padding: 14px;
  min-height: 100px;
`;

export const StyledFormWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 14px 0px;

  .form-title {
    font-family: ${(p) => p.theme.font.primaryFont};
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: ${(p) => p.theme.staticMenuColor.staticFontColor};
    margin-bottom: 8px;
    width: 100%;
  }
`;

export const StyledShareProjectSearchSection = styled(motion.div)`
  width: 100%;
  display: flex;
  align-items: center;
  margin: 28px 0px 40px 0px;
`;
export const StyledSearchShareContactField = styled(TextField)`
  && {
    flex: 1;
  }
  input {
    font-family: ${(p) => p.theme.font.primaryFont};
    font-weight: 500;
    font-size: 14px;
    line-height: normal;
    padding: 10px !important;
    color: ${(p) => p.theme.staticMenuColor.staticFontColor};

    &::placeholder {
      font-family: ${(p) => p.theme.font.primaryFont};
      font-weight: 500;
      font-size: 14px;
      line-height: normal;

      color: ${(p) =>
        editRgbaAlpha({
          rgbaColor: p.theme.staticMenuColor.staticFontColor,
          alpha: "0.4",
        })};
      opacity: 1;
    }
  }

  & .MuiOutlinedInput-root {
    &.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: ${(p) =>
        p.theme.color.doxleColor3}; // Color when the field is focused
      border-width: 1.5px;
      transition: border-color 0.4s ease;
    }

    & .MuiOutlinedInput-notchedOutline {
      border-color: ${(p) =>
        p.theme.staticMenuColor.staticDivider}; // Normal border color
    }

    &:hover .MuiOutlinedInput-notchedOutline {
      border-color: ${(p) =>
        editRgbaAlpha({
          rgbaColor: p.theme.color.doxleColor3,
          alpha: "0.2",
        })}; // Color when hovered (optional)
    }
  }
`;
export const StyledSearchInputAdornment = styled(InputAdornment)`
  && {
    font-family: ${(p) => p.theme.font.primaryFont};
    font-weight: 500;
    font-size: 12px;
    line-height: normal;

    color: ${(p) => p.theme.staticMenuColor.staticFontColor};
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    .icon-dropdown {
      margin-left: 4px;
      transform: translateY(0) rotate(0);
      transition: transform 0.3s ease; /* Smooth transition for hover effect */
    }

    &:hover .icon-dropdown {
      animation: hoverAnimation 0.3s ease forwards;
    }

    @keyframes hoverAnimation {
      0%,
      100% {
        transform: translateY(0);
      }

      50% {
        transform: translateY(-3px);
      }
    }
  }
`;

export const StyledInviteBtn = styled(Button)`
  && {
    margin-left: 8px;
    min-width: 0px !important;
    padding: 0px !important;
    background-color: rgba(108, 127, 255, 1); // Default background color
    color: rgba(255, 255, 255, 1); // Default text color
    font-size: 14px;
    width: 60px;
    height: 38px;
    font-family: ${(p) => p.theme.font.primaryTitleFont};
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: flex;
    align-items: center;
    text-transform: capitalize;
    &:hover {
      background-color: rgba(108, 127, 255, 0.6) !important;
      transition: all 0.2s ease;
    }

    &:disabled {
      background-color: rgba(128, 128, 148, 1); // Disabled background color
      color: rgba(0, 0, 0, 0.6); // Disabled text color
      cursor: not-allowed; // Optional: Change cursor to indicate disabled state
    }
  }
`;
