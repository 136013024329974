import {memo, useState} from "react";
import {useDoxleThemeStore} from "../../../DoxleGeneralStore/useDoxleThemeStore";
import {shallow} from "zustand/shallow";
import {StyledEstimateTableCell, StyledEstimateTableRow} from "../StyledComponents";
import {QUICK_ESTIMATE_TABLE_HEADER_LIST} from "../../Models/estimatesTable";
import {Docket} from "../../../Models/dockets";

interface Props {
  docket: Docket
}

const QuickEstimateDocketRow = ({
                                 docket
                               }: Props) => {
  const [isHover, setIsHover] = useState<boolean>(false);



  const { doxleThemeColor, doxleFont, currentTheme } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
      currentTheme: state.currentTheme,
    }),
    shallow
  );


  return (
    <StyledEstimateTableRow
      themeColor={doxleThemeColor}
      onMouseOver={() => setIsHover(true)}
      onMouseOut={() => setIsHover(false)}
    >
      {QUICK_ESTIMATE_TABLE_HEADER_LIST.map((header, idx) => {
        if (header.isDisplayed) {
          if (header.headerName === "#") {
            return (
              <StyledEstimateTableCell
                hoverStatus={isHover}
                key={`docketCell#${docket.docketPk}#${idx}`}
                widthInPixel={"80px"}
                horizontalAlign="flex-start"
                doxleFont={doxleFont}
                themeColor={doxleThemeColor}
                currentTheme={currentTheme}
                fontWeight="400"
                letterSpacing="-0.28px"
              >
                {docket.docketId}
              </StyledEstimateTableCell>
            );
          }
          if (header.headerName === "Description") {
            return (
              <StyledEstimateTableCell
                hoverStatus={isHover}
                key={`docketCell#${docket.docketPk}#${idx}`}
                widthInPixel={null}
                horizontalAlign="flex-start"
                doxleFont={doxleFont}
                themeColor={doxleThemeColor}
                currentTheme={currentTheme}
                fontWeight="400"
                letterSpacing="-0.28px"
              >
                {docket.docketName}
              </StyledEstimateTableCell>
            );
          }
          if (header.headerName === "Qty") {
            return (
              <StyledEstimateTableCell
                hoverStatus={isHover}
                key={`docketCell#${docket.docketPk}#${idx}`}
                widthInPixel={null}
                horizontalAlign="flex-start"
                doxleFont={doxleFont}
                themeColor={doxleThemeColor}
                currentTheme={currentTheme}
                fontWeight="400"
                letterSpacing="-0.28px"
                paddingLeft={"40px"}
              >
                {}
              </StyledEstimateTableCell>
            );
          }
          if (header.headerName === "Unit") {
            return (
              <StyledEstimateTableCell
                hoverStatus={isHover}
                key={`docketCell#${docket.docketPk}#${idx}`}
                widthInPixel={"100px"}
                horizontalAlign="center"
                doxleFont={doxleFont}
                themeColor={doxleThemeColor}
                currentTheme={currentTheme}
                fontWeight="400"
                letterSpacing="-0.28px"
              >
                {}
              </StyledEstimateTableCell>
            );
          }
          if (header.headerName === "Item Cost") {
            return (
              <StyledEstimateTableCell
                hoverStatus={isHover}
                key={`docketCell#${docket.docketPk}#${idx}`}
                widthInPixel={"100px"}
                horizontalAlign="center"
                doxleFont={doxleFont}
                themeColor={doxleThemeColor}
                currentTheme={currentTheme}
                fontWeight="400"
                letterSpacing="-0.28px"
              >
                {}
              </StyledEstimateTableCell>
            );
          }
          if (header.headerName === "Line Cost") {
            return (
              <StyledEstimateTableCell
                hoverStatus={isHover}
                key={`docketCell#${docket.docketPk}#${idx}`}
                widthInPixel={"100px"}
                horizontalAlign="center"
                doxleFont={doxleFont}
                themeColor={doxleThemeColor}
                currentTheme={currentTheme}
                fontWeight="400"
                letterSpacing="-0.28px"
              >
                {docket.costBudget}
              </StyledEstimateTableCell>
            );
          }
        }
      })}
    </StyledEstimateTableRow>
  );
};

export default memo(QuickEstimateDocketRow)