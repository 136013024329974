import React, { useRef, useState } from "react";
import { useDoxleAuthStore } from "../../../../DoxleGeneralStore/useDoxleAuthStore";
import { useDoxleCurrentContextStore } from "../../../../DoxleGeneralStore/useDoxleCurrentContext";
import useDoxleNotificationStore from "../../../../DoxleGeneralStore/useDoxleNotificationStore";
import { shallow } from "zustand/shallow";
import { useInboxStore } from "../../../InboxStore/useInboxStore";
import DocketQuery from "../../../../Services/QueryHooks/docketQueryAPI";
import useSetProjectWithDocketListQueryData from "../../../../CoreContent/QueryDataHooks/SetQueryDataHooks/useSetProjectWithDocketListQueryData";
import { Docket, NEW_DOCKET_TEMPLATE } from "../../../../Models/dockets";
import useSetDocketListQueryData from "../../../../CoreContent/QueryDataHooks/SetQueryDataHooks/useSetDocketListQueryData";

type Props = { projectId?: string | null; assigneeId?: string | null };

interface NBGroupContentFooter {
  handleAddNewNoticeBoardDocket: (docketName: string) => void;
  isAddingdocket: boolean;
  handleInputKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  newNbTitleText: string;
  handleAddNewDocket: () => void;
  newTitleInputRef: React.RefObject<HTMLInputElement>;
}
const useNBGroupContentFooter = ({
  projectId,
  assigneeId,
}: Props): NBGroupContentFooter => {
  const [newNbTitleText, setNewNbTitleText] = useState("");
  const { user } = useDoxleAuthStore((state) => ({
    user: state.user,
  }));
  const { company } = useDoxleCurrentContextStore((state) => ({
    company: state.currentCompany,
  }));
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );
  const { filterDocketListQuery, inboxViewMode } = useInboxStore(
    (state) => ({
      filterDocketListQuery: state.filterDocketListQuery,

      inboxViewMode: state.inboxViewMode,
    }),
    shallow
  );
  const { handleAddDocket: handleAddMyNoticeDockets } =
    useSetDocketListQueryData({
      filter: {
        ...filterDocketListQuery,
        ball_in_court: user?.userId,
        archived: false,
      },
    });

  const { handleAddDocket: handleAddAllNoticeDockets } =
    useSetDocketListQueryData({
      filter: {
        ...filterDocketListQuery,
        ball_in_court: undefined,
        archived: false,
      },
    });

  const { handleAddDocket: handleAddArchiveDockets } =
    useSetDocketListQueryData({
      filter: {
        ...filterDocketListQuery,
        ball_in_court: undefined,
        archived: true,
      },
    });
  const onSuccessCb = (newDocket?: Docket) => {
    if (newDocket) {
      if (inboxViewMode === "Archived") handleAddArchiveDockets(newDocket);
      else {
        handleAddAllNoticeDockets(newDocket);
        if (newDocket.ballInCourt === user?.userId)
          handleAddMyNoticeDockets(newDocket);
      }
    }
  };
  const addDocketQuery = DocketQuery.useAddDocketQuery({
    filter: filterDocketListQuery,
    company,
    showNotification,

    onSuccessCb: onSuccessCb,
  });

  const handleAddNewNoticeBoardDocket = (docketName: string) => {
    const newDocket = NEW_DOCKET_TEMPLATE({
      docketName: docketName,
      isNoticeboard: true,
      createdBy: user ? (user.userId as string) : "",
      company: company?.companyId ?? "",
      project: projectId,
      ballInCourt: assigneeId,
      isArchived: inboxViewMode === "Archived" ? true : false,
    });
    addDocketQuery.mutate(newDocket);
  };
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setNewNbTitleText(value);
  };
  const handleAddNewDocket = () => {
    if (newNbTitleText) {
      handleAddNewNoticeBoardDocket(newNbTitleText);
    }
  };
  const newTitleInputRef = useRef<HTMLInputElement>(null);

  const handleInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (
      event.nativeEvent.key === "Enter" &&
      !event.shiftKey &&
      !event.ctrlKey
    ) {
      event.preventDefault();
      handleAddNewDocket();
    } else if (event.nativeEvent.key === "Escape" && !newNbTitleText) {
      newTitleInputRef.current?.blur();
    } else if (event.nativeEvent.key === "Escape" && newNbTitleText)
      setNewNbTitleText("");
  };
  return {
    handleAddNewNoticeBoardDocket,
    isAddingdocket: addDocketQuery.isLoading,
    handleInputKeyDown,
    handleInputChange,
    newNbTitleText,
    handleAddNewDocket,
    newTitleInputRef,
  };
};

export default useNBGroupContentFooter;
