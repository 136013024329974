import styled from "styled-components";
import {DoxleFont, DoxleThemeColor} from "../../DoxleGeneralStore/useDoxleThemeStore";
import {editRgbaAlpha} from "../../Utilities/FunctionUtilities";

export const StyledDocketNumberContainer = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  padding: 0px 4px;
  position: relative;

  .docket-number-text {
    color: ${(p) =>
  editRgbaAlpha({
    rgbaColor: p.$themeColor.primaryFontColor,
    alpha: "0.7",
  })};

    font-family: ${(p) => p.$doxleFont.plexSanCondensed};
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;

    letter-spacing: -0.064rem;
    position: absolute;
    bottom: -0.04rem;
    left: calc(8px - 0.8rem);
    z-index: 6;
    background-color: ${(p) => p.$themeColor.primaryBackgroundColor};
  }
`;
