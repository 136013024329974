import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from "react";
import {
  StyledAddBookingButton,
  StyledAddBookingConatiner,
  StyledAddBookingDocketContainer,
} from "./StyledComponentAddBookingDocket";
import { useDoxleThemeStore } from "../../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import DoxleNavigateBackButton from "../../../../DoxleDesignPattern/DoxleButtons/DoxleNavigateBackButton";
import { IoChevronBackSharp } from "react-icons/io5";
import { IntRange } from "../../../../Models/IntRangeType";
import Order from "../../../../Orders/Models/order";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import AddBookingLoader from "../../../CommonComponents/AddBookingLoader";
import useAddBookingDocket from "./Hooks/useAddBookingDocket";
import useRefComponentDimension from "../../../../CoreContent/UtilityHooks/useRefComponentDimension";
import useBookingViewStore from "../../../store/useBookingViewStore";
import { useEditDocketSideScreenStore } from "../../../../CoreContent/EditDocketSideScreen/EditDocketSideScreenStore/useEditDocketSideScreenStore";
import AddBookingDocketPortrait from "./AddBookingDocketPortrait";

type Props = {};
interface AddDocketBookingContextValue {
  newBookingTitle: string;
  setnewBookingTitle: React.Dispatch<React.SetStateAction<string>>;

  startDate: Date | null;
  setStartDate: React.Dispatch<React.SetStateAction<Date | null>>;
  endDate: Date | null;
  setEndDate: React.Dispatch<React.SetStateAction<Date | null>>;
  percentageComplete: IntRange<0, 101>;
  setPercentageComplete: React.Dispatch<React.SetStateAction<IntRange<0, 101>>>;
  selectedOrder: Order | undefined;
  setSelectedOrder: React.Dispatch<React.SetStateAction<Order | undefined>>;
}

const AddDocketBookingContext =
  createContext<AddDocketBookingContextValue | null>(null);
const AddBookingDocket = (props: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
      currentTheme: state.currentTheme,
    }),
    shallow
  );

  const {
    handleBackBtn,
    newBookingTitle,
    setnewBookingTitle,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    percentageComplete,
    setPercentageComplete,
    selectedOrder,
    setSelectedOrder,

    handleAddBooking,
    isAddDisable,
    isAddingBooking,
  } = useAddBookingDocket({});

  const contextValue: AddDocketBookingContextValue = useMemo(
    () => ({
      newBookingTitle,
      setnewBookingTitle,
      startDate,
      setStartDate,
      endDate,
      setEndDate,
      percentageComplete,
      setPercentageComplete,
      selectedOrder,
      setSelectedOrder,
    }),
    [newBookingTitle, startDate, endDate, percentageComplete, selectedOrder]
  );
  const BookingAddScreenRef = useRef<HTMLDivElement>(null);

  const { width, height } = useRefComponentDimension({
    componentRef: BookingAddScreenRef,
  });

  const { screenMode, setScreenMode } = useBookingViewStore(
    (state) => ({
      setScreenMode: state.setCurrentScreenDisplay,
      screenMode: state.currentScreenDisplay,
    }),
    shallow
  );

  const { isFullScreenMode } = useEditDocketSideScreenStore(
    (state) => ({
      isFullScreenMode: state.isFullScreenMode,
    }),
    shallow
  );

  useEffect(() => {
    if (width < 850) {
      setScreenMode("Portrait");
    } else {
      setScreenMode("Landscape");
    }
  }, [width]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <AddDocketBookingContext.Provider value={contextValue}>
        <StyledAddBookingDocketContainer
          $themeColor={doxleThemeColor}
          initial={{
            x: 10,
            opacity: 0,
          }}
          animate={{
            x: 0,
            opacity: 1,
          }}
        >
          <DoxleNavigateBackButton
            onClick={handleBackBtn}
            buttonText="Booking List"
            backIcon={
              <IoChevronBackSharp
                fontSize="1.6rem"
                color={doxleThemeColor.primaryFontColor}
              />
            }
            buttonWrapperStyle={{ alignSelf: "flex-start" }}
            buttonTextStyle={{color: doxleThemeColor.primaryFontColor}}
          />

          <StyledAddBookingConatiner
            $displayMode={screenMode}
            ref={BookingAddScreenRef}
          >
            {/* {screenMode === "Landscape" && (
              <>
                <StyledAddInfoWrapper $displayMode={screenMode}>
                  <AddBookingInputSection />
                </StyledAddInfoWrapper>

                <StyledDocketInfoContainer $displayMode={screenMode}>
                  <BookingDocketDetails
                    mode="add"
                    selectedOrder={selectedOrder}
                    onSelectOrder={setSelectedOrder}
                  />
                </StyledDocketInfoContainer>
              </>
            )}

            {screenMode === "Portrait" && (
              <AddBookingDocketPortrait
                selectedOrder={selectedOrder}
                onSelectOrder={setSelectedOrder}
              />
            )}*/}

            <AddBookingDocketPortrait
              selectedOrder={selectedOrder}
              onSelectOrder={setSelectedOrder}
            />
          </StyledAddBookingConatiner>

          <StyledAddBookingButton
            $themeColor={doxleThemeColor}
            $doxleFont={doxleFont}
            disabled={isAddDisable}
            onClick={handleAddBooking}
          >
            Submit
          </StyledAddBookingButton>

          {isAddingBooking && <AddBookingLoader />}
        </StyledAddBookingDocketContainer>
      </AddDocketBookingContext.Provider>
    </LocalizationProvider>
  );
};
export const useAddDocketBookingContext = () =>
  useContext(AddDocketBookingContext) as AddDocketBookingContextValue;
export default AddBookingDocket;
