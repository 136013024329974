import { useInfiniteQuery, useMutation, useQuery } from "@tanstack/react-query";
import DoxleAPI from "../../Services/DoxleAPI";
import { BaseAPIProps } from "../../Models/baseAPIProps";
import {
  Order,
  OrderFilters,
  OrderLine,
  OrderStatus,
  OrderStatusFilters,
} from "../Models/orders";
import {
  AxiosBackendErrorReturn,
  IApiPaginatedData,
} from "../../Models/axiosReturn";
import { Company } from "../../Models/company";
import { isAxiosError } from "axios";

interface InfiniteOrderQueryProps extends BaseAPIProps {
  filter: OrderFilters;
  enabled: boolean;
  onSuccessCb?: (orderList?: Order[]) => void;
}

const useRetrieveOrderList = ({
  filter,
  company,
  showNotification,
  enabled,
  onSuccessCb,
}: InfiniteOrderQueryProps) => {
  const qKey = formOrderListQKey(filter, company);
  let docketURL = `/orders/?page=1`;

  return useInfiniteQuery(
    qKey,
    ({ pageParam = docketURL }) =>
      DoxleAPI.get<IApiPaginatedData<Order>>(pageParam, {
        headers: {
          "User-Company": company!.companyId,
        },
        params: filter,
      }),
    {
      enabled: Boolean(company && enabled),
      retry: 1,
      refetchInterval: 2 * 60 * 1000,
      refetchIntervalInBackground: true,
      refetchOnWindowFocus: false,
      getNextPageParam: (prevData) => prevData.data?.next,
      onSuccess(data) {
        if (onSuccessCb)
          onSuccessCb(
            data.pages.reduce((acc, data) => {
              return acc.concat(data.data.results);
            }, [] as Order[])
          );
      },
      onError: (error) => {
        if (showNotification)
          if (isAxiosError<AxiosBackendErrorReturn>(error)) {
            showNotification(
              `${error?.response?.status ?? "ERROR"}: ${
                error.response?.data.detail ?? "UNKNOWN ERROR"
              }`,
              "error",
              String(
                error?.response?.data?.detail ?? "FAILED TO GET ORDER LIST"
              ).substring(0, 300)
            );
          } else
            showNotification(
              "Something Wrong!",
              "error",
              "FAILED TO GET ORDER LIST"
            );
      },
    }
  );
};

interface RetrieveOrderDetailProps extends InfiniteOrderQueryProps {
  orderId: string;
}
const useRetrieveOrderDetail = ({
  orderId,

  filter,
  company,
  showNotification,
  enabled,
}: RetrieveOrderDetailProps) => {
  const qKey = formOrderDetailQKey(orderId, company);
  let docketURL = `/orders/${orderId}/`;

  return useQuery(
    qKey,
    () =>
      DoxleAPI.get<Order>(docketURL, {
        headers: {
          "User-Company": company!.companyId,
        },
      }),
    {
      enabled: Boolean(company && orderId && enabled),
      retry: 1,
      // cacheTime: 10 * 60 * 1000,
      // refetchInterval: 0.14 * 60 * 1000,
      onError: (error) => {
        if (showNotification)
          if (isAxiosError<AxiosBackendErrorReturn>(error)) {
            showNotification(
              `${error?.response?.status ?? "ERROR"}: ${
                error.response?.data.detail ?? "UNKNOWN ERROR"
              }`,
              "error",
              String(
                error?.response?.data?.detail ?? "FAILED TO GET ORDER LIST"
              ).substring(0, 300)
            );
          } else
            showNotification(
              "Something Wrong",
              "error",
              "FAILED TO GET ORDER LIST"
            );
      },
      onSuccess: (response) => {
        const orderDetail = response.data;
        let qKey = ["order-list"];
        for (const [key, value] of Object.entries(filter)) {
          qKey.push(value);
        }
      },
    }
  );
};
// interface MutateOrderListQueryProps
//   extends Omit<InfiniteOrderQueryProps, 'enabled'> {
//   onUpdateSuccess?: (edittedOrder?: Order) => void;
//   onDeleteSuccess?: (deletedId?: string) => void;
//   onAddSuccess?: (newOrder?: Order) => void;
// }

// interface UpdateOrderQueryProps {
//   orderId: string;
//   updateBody: Partial<Order>;
// }

// interface AddOrderQueryProps {
//   order: Order;
//   lines?: OrderLine[];
// }

// interface AxiosAddOrderReturn {
//   order: Order;
// }
// const useMutateOrderList = ({
//   showNotification,
//
//   company,
//   filter,
//   onUpdateSuccess,
//   onDeleteSuccess,
//   onAddSuccess,
// }: MutateOrderListQueryProps) => {
//   const {handleAddOrder, handleEditOrder, handleDeleteOrder} =
//     useSetOrderListQueryData({filter});
//   const update = useMutation({
//     mutationFn: async ({orderId, updateBody}: UpdateOrderQueryProps) => {
//       return DoxleAPI.patch('/orders/' + orderId + '/', updateBody, {
//         headers: {
//           'User-Company': company?.companyId ?? '',
//         },
//       });
//     },
//     onSuccess: (result, variables, context) => {
//       handleEditOrder(result.data);
//       if (onUpdateSuccess) onUpdateSuccess(result.data);

//     },
//     onError: (error, variables, context) => {
//       if (showNotification)
//     },
//   });
//   const add = useMutation({
//     mutationFn: async (data: AddOrderQueryProps) => {
//       const {order, lines} = data;
//       const dataPost = lines ? {...order, lines} : {...order, lines: []};
//       return DoxleAPI.post<AxiosAddOrderReturn>(
//         `/orders/`,
//         dataPost,
//         {
//           headers: {
//             'User-Company': company?.companyId ?? '',
//           },
//         },
//       );
//     },
//     onSuccess: (result, variables, context) => {
//       // queryClient.invalidateQueries(qKey);
//       handleAddOrder(result.data.order);

//       if (onAddSuccess) onAddSuccess(result.data.order);
//     },
//     // if (handleScrollToBottomBudgetTable) handleScrollToBottomBudgetTable()
//     onError: (error, variables, context) => {

//     },
//   });

//   const destroy = useMutation({
//     mutationFn: async (deletedOrderId: string) => {
//       return DoxleAPI.delete('/orders/' + deletedOrderId + '/', {
//         headers: {
//           'User-Company': company?.companyId ?? '',
//         },
//       });
//     },
//     onSuccess: (result, variables, context) => {
//       handleDeleteOrder(variables);
//       if (onDeleteSuccess) onDeleteSuccess(variables);

//     },
//     onError: (error, variables, context) => {
//     },
//   });
//   const mutateUpdate = (data: UpdateOrderQueryProps) => update.mutate(data);
//   const mutateAdd = (data: AddOrderQueryProps) => add.mutate(data);

//   const mutateDestroy = (id: string) => destroy.mutate(id);
//   return {
//     update: {...update, mutate: mutateUpdate},
//     add: {...add, mutate: mutateAdd},
//     destroy: {...destroy, mutate: mutateDestroy},
//   };
// };

interface RetrieveOrderStatusQueryProps extends BaseAPIProps {
  filter: OrderStatusFilters;
  onSuccessCb?: (statusList?: OrderStatus[]) => void;
}
const useRetrieveOrderStatusQuery = ({
  filter,
  company,
  showNotification,
  onSuccessCb,
}: RetrieveOrderStatusQueryProps) => {
  const qKey = formOrderStatusListQKey(filter, company);
  let docketURL = `/orders/status/`;
  let getParams: any = {};
  if (filter.is_invoice_status) getParams.is_invoice_status = true;
  if (filter.is_order_status) getParams.is_order_status = true;
  return useQuery(
    qKey,
    () =>
      DoxleAPI.get<OrderStatus[]>(docketURL, {
        headers: {
          "User-Company": company!.companyId,
        },
        params: getParams,
      }),
    {
      enabled: Boolean(company),
      retry: 1,

      onError: (error) => {
        if (showNotification)
          if (isAxiosError<AxiosBackendErrorReturn>(error)) {
            showNotification(
              `${error?.response?.status ?? "ERROR"}: ${
                error.response?.data.detail ?? "UNKNOWN ERROR"
              }`,
              "error",
              String(
                error?.response?.data?.detail ?? "FAILED TO GET ORDER LIST"
              ).substring(0, 300)
            );
          } else
            showNotification(
              "Something Wrong",
              "error",
              "FAILED TO GET ORDER LIST"
            );
      },
      onSuccess: (response) => {
        if (onSuccessCb) onSuccessCb(response.data);
      },
    }
  );
};

// interface UpdateOrdeLineQueryProps {
//   lineId: string;
//   updateBody: Partial<OrderLine>;
// }

// export interface AxiosAddUpdateOrderLineReturn {
//   line: OrderLine;
//   order: Order;
// }
// export interface AxiosDeleteOrderLineReturn {
//   updatedOrder: Order;
// }
// interface MutateOrderLineQueryProps
//   extends Omit<InfiniteOrderQueryProps, 'enabled'> {
//   onUpdateSuccess?: (data?: AxiosAddUpdateOrderLineReturn) => void;
//   onDeleteSuccess?: (data?: AxiosDeleteOrderLineReturn) => void;
//   onAddSuccess?: (data?: AxiosAddUpdateOrderLineReturn) => void;
// }
// const useMutateOrderLineList = ({
//   showNotification,
//
//   company,
//   filter,
//   onUpdateSuccess,
//   onDeleteSuccess,
//   onAddSuccess,
// }: MutateOrderLineQueryProps) => {
//   const {handleEditOrder} = useSetOrderListQueryData({filter});
//   const update = useMutation({
//     mutationFn: async ({lineId, updateBody}: UpdateOrdeLineQueryProps) => {
//       return DoxleAPI.patch<AxiosAddUpdateOrderLineReturn>(
//         '/orders/line/' + lineId + '/',
//         updateBody,
//         {
//           headers: {
//             'User-Company': company?.companyId ?? '',
//           },
//         },
//       );
//     },
//     onSuccess: (result, variables, context) => {
//       handleEditOrder(result.data.order);
//       if (onUpdateSuccess) onUpdateSuccess(result.data);
//     },
//     onError: (error, variables, context) => {

//     },
//   });

//   const add = useMutation({
//     mutationFn: async (newLine: OrderLine) => {
//       return DoxleAPI.post<AxiosAddUpdateOrderLineReturn>(
//         '/orders/add_line/',
//         newLine,
//         {
//           headers: {
//             'User-Company': company?.companyId ?? '',
//           },
//         },
//       );
//     },
//     onSuccess: (result, variables, context) => {
//       handleEditOrder(result.data.order);
//       if (onAddSuccess) onAddSuccess(result.data);

//     },
//     onError: (error, variables, context) => {
//     },
//   });

//   const destroy = useMutation({
//     mutationFn: async (deletedId: string) => {
//       return DoxleAPI.delete<AxiosDeleteOrderLineReturn>(
//         '/orders/line/' + deletedId + '/',

//         {
//           headers: {
//             'User-Company': company?.companyId ?? '',
//           },
//         },
//       );
//     },
//     onSuccess: (result, variables, context) => {
//       handleEditOrder(result.data.updatedOrder);
//       if (onDeleteSuccess) onDeleteSuccess(result.data);
//     },
//     onError: (error, variables, context) => {
//     },
//   });

//   const mutateUpdate = (data: UpdateOrdeLineQueryProps) => update.mutate(data);
//   const mutateAdd = (newLine: OrderLine) => add.mutate(newLine);
//   const mutateDelete = (deletedId: string) => destroy.mutate(deletedId);
//   return {
//     update: {...update, mutate: mutateUpdate},
//     add: {...add, mutate: mutateAdd},
//     destroy: {...destroy, mutate: mutateDelete},
//   };
// };
//* HELPER FUNCTIONS
export const formOrderListQKey = (
  filter: OrderFilters,
  company: Company | undefined
) => {
  let qKey = ["order-list"];
  if (company) qKey.push(company.companyId);
  for (const [key, value] of Object.entries(filter)) {
    qKey.push(value);
  }

  return qKey;
};

export const formOrderStatusListQKey = (
  filter: OrderStatusFilters,
  company: Company | undefined
) => {
  let qKey = ["order-status-list"];
  if (company) qKey.push(company.companyId);
  for (const [key, value] of Object.entries(filter)) {
    qKey.push(key);
  }

  return qKey;
};

export const formOrderDetailQKey = (
  orderId: string,
  company: Company | undefined
) => {
  let qKey = ["order-detail"];
  if (company) qKey.push(company.companyId);
  qKey.push(orderId);
  return qKey;
};
const OrdersQueryAPI = {
  useRetrieveOrderList,
  useRetrieveOrderDetail,
  useRetrieveOrderStatusQuery,
  // useMutateOrderList,
  // useMutateOrderLineList,
};
export default OrdersQueryAPI;
