import { motion } from "framer-motion";
import styled from "styled-components";
import {
  DoxleFont,
  DoxleThemeColor,
} from "../../../DoxleGeneralStore/useDoxleThemeStore";
import {
  TRgbaFormat,
  editRgbaAlpha,
} from "../../../Utilities/FunctionUtilities";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  InputAdornment,
  TableRow,
  TextField,
} from "@mui/material";

export const StyledEditOrderContainer = styled(motion.div)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  width: calc(100% - 1.6rem);
  height: calc(100% - 4rem);

  display: flex;
  flex-direction: column;

  padding: 2rem 0.8rem;
  position: relative;
  .pdf-loader {
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    position: absolute;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: ${(p) => p.$themeColor.primaryBackdropColor};
    span {
      color: ${(p) => p.$themeColor.primaryReverseBackdropColor};
      font-family: ${(p) => p.$doxleFont.primaryFont};
      font-size: max(2.5rem, 25px);
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin-top: 30px;
    }
  }
  .title-edit-order {
    color: ${(p) => p.$themeColor.primaryFontColor};
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-size: max(1.8rem, 18px);
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.09rem;
    text-transform: capitalize;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-bottom: 0.5rem;
  }
  .disclaimer {
    color: ${(p) => p.$themeColor.errorColor};
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-size: max(1.3rem, 13px);
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.09rem;
    text-transform: capitalize;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-bottom: 0.5rem;
  }
  .order-tab-menu {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 2rem;
  }

  .active-link {
    color: rgb(26, 180, 215) !important;
    cursor: pointer;
    :hover {
      text-decoration: underline;
    }
  }
  .active-link:hover {
    text-decoration: underline;
  }
  .action-btn-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .order-edit-content-wrapper {
    flex: 1;
    min-height: 0px;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    // scrollbar-width: none;
    // -ms-overflow-style: none;
    // &::-webkit-scrollbar {
    //   display: none;
    // }

    display: flex;
    flex-direction: column;
  }
  .order-accepted {
    width: 100%;
    text-align: center;
    font-weight: 600;
    font-size: 12.5px;
    font-family: ${(p) => p.$doxleFont.primaryFont};
    padding: 1rem 0;
    border-radius: 0.6rem;
    color: rgba(60, 179, 113, 1);
    background-color: rgba(60, 179, 113, 0.25);
    margin-bottom: 1rem;
  }
  .order-rejected {
    width: 100%;
    text-align: center;
    font-size: 12.5px;
    font-weight: 600;
    font-family: ${(p) => p.$doxleFont.primaryFont};
    border-radius: 0.6rem;
    padding: 1rem 0;
    color: rgba(178, 34, 34, 1);
    background-color: rgba(178, 34, 34, 0.25);
    margin-bottom: 1rem;
  }
`;
export const StyledEditOrderLabelField = styled.span<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  color: ${(p) =>
    editRgbaAlpha({ rgbaColor: p.$themeColor.primaryFontColor, alpha: "0.7" })};
  font-family: ${(p) => p.$doxleFont.primaryFont};
  font-size: max(1rem, 10px);
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  text-transform: uppercase;
  margin-bottom: 0.2rem;
  margin-left: 0.4rem;
`;
export const StyledEditOrderWrapperField = styled(motion.div)<{
  $width?: string;
}>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  ${(p) => p.$width && `width:${p.$width};`}
  .row-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
  }
`;
export const StyledOrderContactCompanyOption = styled(motion.li)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  background-color: transparent;
  text-transform: capitalize !important;
  list-style-position: inside;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: calc(100% - 1.6rem);
  padding: 0px 0.8rem;
  font-family: ${(p) => p.$doxleFont.primaryFont};
  font-weight: 500;
  font-size: 1.2rem;
  line-height: normal;
  height: 3rem;
  display: flex;
  align-items: center;
  width: 100%;
  text-transform: capitalize;
  &:hover {
    background-color: ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.$themeColor.doxleColor,
        alpha: "0.4",
      })} !important;
    color: white !important;
  }
`;
export const StyledContactsAutocompleteTextField = styled(TextField)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  && {
    padding-top: 0px !important;
    padding-bottom: 0px !important;

    overflow: hidden;
    background-color: ${(p) => p.$themeColor.primaryTextFieldBackgroundColor};
  }
  & .MuiInputBase-input.Mui-disabled {
    -webkit-text-fill-color: ${(p) => p.$themeColor.primaryFontColor};
  }
  input {
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-weight: 500;
    font-size: max(1.3rem, 13px);
    line-height: normal;
    color: ${(p) => p.$themeColor.primaryFontColor};
  }
  & .MuiInput-root:after {
    border-bottom: none !important;
  }
  & .MuiInput-root:before {
    border-bottom: none !important;
  }
  & .MuiInput-root {
    border-bottom: none !important;
  }
  & .MuiInput-root:hover:not(.Mui-disabled, .Mui-error):before {
    border-bottom: none !important;
  }
`;
export const StyledDateSelectCustomContainer = styled(motion.div)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  border-radius: 4px;
  background-color: ${(p) => p.$themeColor.primaryTextFieldBackgroundColor};
  color: ${(p) => p.$themeColor.primaryFontColor};
  cursor: pointer;
  min-width: 140px;
  justify-content: center;
  font-size: max(1.3rem, 13px);
  font-weight: 500;
  font-family: ${(p) => p.$doxleFont.primaryFont};
  align-items: center;
  flex-direction: row;
  align-self: flex-start;
  display: flex;
  padding: 0px 1.2rem;
  height: max(2.7rem, 27px);
`;
export const StyledEditOrderTextfield = styled(TextField)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  && {
    padding: 0px 0.8rem !important;

    overflow: hidden;
    min-width: 100px;
    width: 80%;
    background-color: ${(p) => p.$themeColor.primaryTextFieldBackgroundColor};
    border-radius: 4px;
    height: max(2.7rem, 27px);
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  & .MuiInputBase-input.Mui-disabled {
    -webkit-text-fill-color: ${(p) => p.$themeColor.primaryFontColor};
  }
  input {
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-weight: 500;
    font-size: max(1.3rem, 13px);
    line-height: normal;
    color: ${(p) => p.$themeColor.primaryFontColor};
    padding: 0px !important;
  }
  & .MuiInput-root:after {
    border-bottom: none !important;
  }
  & .MuiInput-root:before {
    border-bottom: none !important;
  }
  & .MuiInput-root {
    border-bottom: none !important;
  }
  & .MuiInput-root:hover:not(.Mui-disabled, .Mui-error):before {
    border-bottom: none !important;
  }
  & .MuiInputBase-root {
    width: 100%;
  }
`;
export const StyledEditOrderErrorText = styled(motion.span)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  font-family: ${(p) => p.$doxleFont.primaryFont};
  font-weight: 500;
  font-size: max(1rem, 10px);
  line-height: normal;
  color: red;
  margin-top: 4px;
`;
export const StyledEditOrderNoteText = styled.span<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  font-family: ${(p) => p.$doxleFont.primaryFont};
  font-weight: 500;
  font-size: max(1.2rem, 12px);
  line-height: normal;
  color: ${(p) => p.$themeColor.primaryFontColor};
  margin-left: 2px;
`;
export const StyledEditOrderInputAdornment = styled(InputAdornment)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  && {
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-weight: 500;
    font-size: 1.3rem;
    line-height: normal;
    color: ${(p) => p.$themeColor.primaryFontColor};
    padding: 0px !important;
  }
  & .MuiTypography-root {
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-weight: 500;
    font-size: 1.3rem;
    line-height: normal;
    color: ${(p) => p.$themeColor.primaryFontColor};
  }
`;
export const StyledOrderStatusContainer = styled(motion.div)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
  $statusColor?: string;
}>`
  border-radius: 4px;
  background-color: ${(p) => p.$themeColor.primaryTextFieldBackgroundColor};
  color: ${(p) => p.$statusColor ?? p.$themeColor.primaryFontColor};
  cursor: pointer;

  justify-content: center;
  font-size: max(1.3rem, 13px);
  font-weight: 500;
  font-family: ${(p) => p.$doxleFont.primaryFont};
  align-items: center;
  flex-direction: row;
  align-self: flex-start;
  display: flex;
  padding: 0px 1.2rem;
  height: max(2.7rem, 27px);
`;
export const StyledOrderStatusPopupContainer = styled.div<{
  $doxleFont: DoxleFont;
  $themeColor: DoxleThemeColor; // to be removed
}>`
  position: relative;

  font-family: ${(p) => p.$doxleFont.primaryFont};
  font-weight: 500;
  display: flex;
  flex-direction: column;
  border-radius: 0px 0px 0px 0.9rem;
  height: 20rem;
  width: 20rem;
  box-shadow: 0px 0px 8px 0px ${(p) => p.$themeColor.primaryBoxShadowColor};
  background-color: ${(p) => p.$themeColor.primaryContainerColor};

  .status-title-wrapper {
    font-size: 1.4rem;
    font-weight: 700;
    font-family: ${(p) => p.$doxleFont.primaryFont};
    text-align: left;
    letter-spacing: 0.7px;
    padding: 1.1rem;
    color: ${(p) => p.$themeColor.primaryFontColor};
    border-bottom: 1px solid ${(p) => p.$themeColor.boldDividerColor};
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .status-item-wrapper {
    width: calc(100% - 1.6rem);
    padding: 0.8rem;
    flex: 1;
    display: flex;
    flex-direction: column;

    overflow: auto;
  }
`;

export const StyledOrderStatusItem = styled.div<{
  $statusColor: string;
  $doxleFont: DoxleFont;
}>`
  width: calc(100% - 1.6rem);
  padding: 0.4rem 0.8rem;
  border: 0px 0.9rem;
  font-size: 1.4rem;
  font-weight: 500;
  font-family: ${(p) => p.$doxleFont.primaryFont};
  text-align: left;
  letter-spacing: 0.7px;
  display: flex;
  align-items: center;
  color: ${(p) => p.$statusColor};

  text-transform: capitalize;
  cursor: pointer;
  margin: 0.4rem 0px;
  &:hover {
    background-color: ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.$statusColor as TRgbaFormat,
        alpha: "0.2",
      })};
    transition: 0.4s;
    font-weight: 700;
  }
`;

export const StyledEditOrderLineTableHeader = styled(TableRow)`
  && {
    width: 100%;
    display: flex;
    flex-direction: row;
    padding-bottom: 0.4rem;
  }
`;
export const StyledOrderLineItemTextField = styled(TextField)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
  $textAlign?: "center" | "left";
}>`
  && {
    overflow: hidden;

    width: calc(100% - 0.8rem);
    padding: 0px 0.4rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: text;
  }
  & .MuiInputBase-input.Mui-disabled {
    -webkit-text-fill-color: ${(p) => p.$themeColor.primaryFontColor};
  }
  input {
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-weight: 500;
    font-size: max(1.3rem, 13px);
    line-height: normal;
    width: 100% !important;
    color: ${(p) => p.$themeColor.primaryFontColor};
    padding: 0px !important;
    text-align: ${(p) => p.$textAlign ?? "center"};

    &::placeholder {
      font-family: ${(p) => p.$doxleFont.primaryFont};
      font-weight: 400;
      font-size: max(1.3rem, 13px);
      line-height: normal;
      color: ${(p) => p.$themeColor.primaryInputPlaceholderColor};
      padding: 0px !important;
      opacity: 1;
    }
  }
  & .MuiInput-root:after {
    border-bottom: none !important;
  }
  & .MuiInput-root:before {
    border-bottom: none !important;
  }
  & .MuiInput-root {
    border-bottom: none !important;
  }
  & .MuiInput-root:hover:not(.Mui-disabled, .Mui-error):before {
    border-bottom: none !important;
  }
  & .MuiInputBase-root {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  & .MuiInputBase-input {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
export const StyledOrderLineInputAdornment = styled(InputAdornment)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  && {
    font-family: ${(p) => p.$doxleFont.secondaryTitleFont};
    font-weight: 500;
    font-size: max(1.4rem, 13px);
    line-height: normal;

    color: ${(p) => p.$themeColor.primaryFontColor};
    text-transform: uppercase;
    //letter-spacing: 0.07rem;
  }
`;
export const StyledOrderLineListScroller = styled.div`
  width: 100%;
  //height: 100%;
  &::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
`;

export const StyledAcceptOrderDialog = styled(motion(Dialog))<{
  $themeColor: DoxleThemeColor;
}>`
  && {
    box-shadow: 1px 1px 2px ${(p) => p.$themeColor.primaryBoxShadowColor};
  }
  & .MuiDialog-paper {
    background-color: ${(p) => p.$themeColor.primaryContainerColor};
    box-shadow: 0px 0px 12px 0px ${(p) => p.$themeColor.primaryBoxShadowColor};
    display: flex !important;
    flex-direction: column;
    width: calc(80vw - 68px) !important;
    position: relative;
    max-width: 700px;
    min-width: 350px;
    min-height: 300px !important;
    max-height: 1000px !important;
    border-radius: 9px !important;
    padding: 14px 34px;
  }
`;

export const StyledAcceptOrderTitle = styled(DialogTitle)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  && {
    color: ${(p) => p.$themeColor.primaryFontColor};
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-size: max(1.8rem, 18px);
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 14px 0px;
    border-bottom: 1px solid ${(p) => p.$themeColor.primaryDividerColor};
    display: flex;
    align-items: center;
  }
`;

export const StyledAcceptOrderContentWrapper = styled(DialogContent)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  && {
    width: calc(100%);
    margin-top: 20px;

    display: flex;
    flex-direction: column;

    overflow: auto;
    position: relative;
    padding: 0px 0px 14px 0px !important;
  }

  & .order-info-text {
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-size: max(1.4rem, 14px);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: ${(p) => p.$themeColor.primaryFontColor} !important;
  }

  & .signature-display-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 20px;
    padding-top: 14px;
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-size: max(1.4rem, 14px);
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: ${(p) => p.$themeColor.primaryFontColor} !important;
    .signature-img-wrapper {
      width: 200px;
      margin-top: 20px;
      border: 1px solid ${(p) => p.$themeColor.primaryDividerColor};
      border-radius: 4px;
      background-color: #fff;
      padding: 14px;
    }
  }

  & .error-signature-text {
    margin-top: 8px;

    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-size: max(1.4rem, 14px);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: red;
  }
  
  & .overbudget-button-container {
    display: flex;
    flex-direction: row;
    padding-left: 50%;
    justify-content: space-evenly;
    margin: auto 0;
  }
`;
export const StyledAcceptDialogBtnWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-top: 14px;
  justify-content: flex-end;
`;
export const StyledDialogActionButton = styled(motion(Button))<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
  $reverseColor?: boolean;
}>`
  && {
    display: flex;
    flex-direction: row;
    min-width: 90px !important;
    cursor: pointer;
    margin: 0px 4px;
    padding: 7px 14px !important;
    border-radius: 6px !important;
    background-color: ${(p) =>
      p.$reverseColor
        ? p.$themeColor.primaryContainerColor
        : p.$themeColor.primaryFontColor};
    text-transform: capitalize !important;
    ${(p) =>
      p.$reverseColor
        ? `border:1px solid ${p.$themeColor.primaryDividerColor};`
        : ""}
    .btn-text {
      font-family: ${(p) => p.$doxleFont.primaryFont};
      font-size: max(1.4rem, 14px);
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      color: ${(p) =>
        p.$reverseColor
          ? p.$themeColor.primaryFontColor
          : p.$themeColor.primaryContainerColor} !important;
    }

    &:hover {
      background-color: ${(p) =>
        p.$reverseColor
          ? p.$themeColor.primaryContainerColor
          : p.$themeColor.primaryFontColor} !important;
      opacity: 0.95;
      transition: all 0.2s ease;
    }
  }
`;

export const StyledConfirmOrderButton = styled(motion(Button))<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
  $reverseColor?: boolean;
}>`
  && {
    display: flex;
    flex-direction: row;
    min-width: 120px !important;
    cursor: pointer;
    margin: 0px 4px;
    padding: 5px 12px !important;
    border-radius: 6px !important;
    background-color: ${(p) =>
      p.$reverseColor
        ? p.$themeColor.primaryContainerColor
        : p.$themeColor.primaryFontColor};
    text-transform: capitalize !important;
    ${(p) =>
      p.$reverseColor
        ? `border:1px solid ${p.$themeColor.primaryDividerColor};`
        : ""}
    .btn-text {
      font-family: ${(p) => p.$doxleFont.primaryFont};
      font-size: max(1.8rem, 18px);
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      color: ${(p) =>
        p.$reverseColor ? "red" : p.$themeColor.primaryContainerColor};
      text-transform: capitalize !important;
    }

    &:hover {
      background-color: ${(p) =>
        p.$reverseColor
          ? p.$themeColor.primaryContainerColor
          : p.$themeColor.primaryFontColor} !important;
      opacity: 0.95;
      transition: all 0.2s ease;
      transform: translateY(-2px);
    }
  }
`;

export const StyledSignatureButton = styled(motion(Button))<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  && {
    display: flex;
    flex-direction: row;
    min-width: 0px !important;
    cursor: pointer;
    align-self: flex-start;
    padding: 8px 12px !important;
    border-radius: 4px !important;
    background-color: ${(p) => p.$themeColor.primaryContainerColor};
    text-transform: capitalize !important;
    border: 1px solid ${(p) => p.$themeColor.primaryDividerColor};
    .btn-text {
      font-family: ${(p) => p.$doxleFont.primaryFont};
      font-size: max(1.4rem, 14px);
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      color: ${(p) => p.$themeColor.primaryFontColor} !important;
    }

    &:hover {
      background-color: ${(p) =>
        p.$themeColor.primaryContainerColor} !important;
      opacity: 0.95;
      transition: all 0.2s ease;
    }
  }
`;

export const StyledOrderSignoffContainer = styled(motion.div)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  display: flex;
`;

export const StyledEditIconContainer = styled(motion.div)`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledOrderSignoffPlaceholder = styled(motion.div)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
  $signed: boolean;
}>`
  overflow: hidden;
  min-width: 100px;
  width: max(3rem, 30px);
  background-color: ${(p) => p.$themeColor.primaryTextFieldBackgroundColor};
  color: ${(p) =>
    p.$signed
      ? p.$themeColor.primaryFontColor
      : editRgbaAlpha({
          rgbaColor: p.$themeColor.primaryFontColor,
          alpha: "0.4",
        })};
  border-radius: 4px;
  height: max(2.7rem, 27px);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 1.2rem;
  font-family: ${(p) => p.$doxleFont.primaryFont};
  font-weight: 500;
  font-size: max(1.3rem, 13px);
  cursor: pointer;
`;

export const StyledOrderContractorDisplayer = styled(motion.div)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  border-radius: 4px;
  background-color: ${(p) => p.$themeColor.primaryTextFieldBackgroundColor};
  color: ${(p) => p.$themeColor.primaryFontColor};
  cursor: pointer;
  min-width: 140px;

  font-size: max(1.3rem, 13px);
  font-weight: 500;
  font-family: ${(p) => p.$doxleFont.primaryFont};
  align-items: center;
  flex-direction: row;
  align-self: flex-start;
  display: flex;
  padding: 0px 1.2rem;
  height: max(2.7rem, 27px);
`;
export const StyledEditOrderActionButton = styled(motion(Button))<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
  $reverseColor?: boolean;
}>`
  && {
    display: flex;
    flex-direction: row;
    min-width: 100px !important;
    cursor: pointer;
    margin: 0px 4px;
    padding: 5px 12px !important;
    border-radius: 6px !important;
    background-color: ${(p) =>
      p.$reverseColor
        ? p.$themeColor.primaryContainerColor
        : p.$themeColor.primaryFontColor};
    text-transform: capitalize !important;
    ${(p) =>
      p.$reverseColor
        ? `border:1px solid ${p.$themeColor.primaryDividerColor};`
        : ""}
    .btn-text {
      font-family: ${(p) => p.$doxleFont.primaryFont};
      font-size: max(1.8rem, 18px);
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      color: ${(p) =>
        p.$reverseColor
          ? p.$themeColor.primaryFontColor
          : p.$themeColor.primaryContainerColor};
      text-transform: capitalize !important;
    }

    &:hover {
      background-color: ${(p) =>
        p.$reverseColor
          ? p.$themeColor.primaryContainerColor
          : p.$themeColor.primaryFontColor} !important;
      opacity: 0.95;
      transition: all 0.2s ease;
      transform: translateY(-2px);
    }
  }
`;
export const StyledDialogInputContent = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 32px);
  padding: 16px;
  background-color: ${p => p.theme.color.primaryContainerColor};
  font-family: ${p => p.theme.font.primaryFont};
  color: ${p => p.theme.color.primaryFontColor};
`
export const StyledDialogInput = styled.input`
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  border: 1px solid ${(p) => p.theme.color.primaryDividerColor};
  border-radius: 0px;
  background: transparent;
  height: 30px;
  color: ${(p) => p.theme.color.primaryFontColor};

  &:focus {
    outline: none;
    border: 1px solid ${(p) => editRgbaAlpha({rgbaColor: p.theme.color.doxleColor, alpha: '0.4'})};
  }

  &::placeholder {
    color: ${(p) => editRgbaAlpha({ rgbaColor: p.theme.color.primaryFontColor, alpha: "0.7" })};
    opacity: 1;
    font-size: 1.4rem;
  }

  &::-ms-input-placeholder {
    color: ${(p) => editRgbaAlpha({ rgbaColor: p.theme.color.primaryFontColor, alpha: "0.7" })};
    font-size: 1.4rem;
  }
`
export const StyledAdornment = styled.div<{
  $selected?: boolean;
  $first?: boolean;
}>`
  width: calc(24px - 10px);
  background-color: ${(p) => editRgbaAlpha({ 
    rgbaColor: p.$selected ? p.theme.color.doxleColor : p.theme.color.primaryDividerColor,
    alpha: "0.3" 
  })};
  color: ${(p) => editRgbaAlpha({ 
    rgbaColor: p.$selected ? p.theme.color.doxleColor : p.theme.color.primaryFontColor, 
    alpha: "0.8" 
  })};
  font: ${p => p.theme.font.primaryFont};
  padding: 8px 5px;
  font-weight: 700;
  height: calc(30px - 16px);
  margin: 8px 0;
  cursor: ${p => p.$selected ? 'unset' : 'pointer'};
  border-radius: ${p => p.$first ? '4px 0 0 4px' : '0 4px 4px 0'};
`
