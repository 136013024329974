import React, { useMemo } from "react";
import { useDoxleCurrentContextStore } from "../../../DoxleGeneralStore/useDoxleCurrentContext";
import { shallow } from "zustand/shallow";
import useDoxleNotificationStore from "../../../DoxleGeneralStore/useDoxleNotificationStore";
import ContactsAPI from "../../../Services/QueryHooks/contactsAPI";
import { ContactCompany } from "../../../Models/addressBook";

type Props = {
  contactCompanyId?: string;
};

interface IGetContactCompanyDetail {
  contactCompanyDetails: ContactCompany | undefined;
  isFetchingContactDetails: boolean;
  isSuccessFetchingContactDetails: boolean;
  isErrorFetchingContactDetails: boolean;
}
const useGetContactCompanyDetail = ({
  contactCompanyId,
}: Props): IGetContactCompanyDetail => {
  const company = useDoxleCurrentContextStore(
    (state) => state.currentCompany,
    shallow
  );
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );
  const getCompanyDetails = ContactsAPI.useGetContactCompanyDetailQuery({
    // showNotification,

    company,
    contactCompanyId: contactCompanyId ?? "",
    enable: Boolean(contactCompanyId),
  });

  const contactCompanyDetails = useMemo(
    () =>
      getCompanyDetails.isSuccess ? getCompanyDetails.data.data : undefined,
    [getCompanyDetails.data]
  );
  return {
    contactCompanyDetails,
    isFetchingContactDetails: getCompanyDetails.isLoading,
    isSuccessFetchingContactDetails: getCompanyDetails.isSuccess,
    isErrorFetchingContactDetails: getCompanyDetails.isError,
  };
};

export default useGetContactCompanyDetail;
