import React, { useCallback, useEffect, useState } from "react";

type Props = {
  onSelectPage: (page: number) => void;

  onSelectPageSize: (pageSize: number) => void;

  totalPage: number;

  currentPage: number;

  currentPageSize: number;
  totalItemCount: number;
};

interface IDoxlePaginationControl {
  anchorPageControlList: HTMLElement | null;
  handleClosePopoverListControl: () => void;
  handleClickPageSizeControl: (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => void;
  handleClickPageNumControl: (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => void;
  listControlMode: "pageSize" | "pageNum" | undefined;
  handleSetPage: (pageNum: number) => void;
  handleSetPageSize: (pageSize: number) => void;
  currenPageRange: {
    start: number;
    end: number;
  };
}
const useDoxlePaginationControl = ({
  onSelectPage,
  onSelectPageSize,
  totalPage,
  currentPage,
  currentPageSize,
  totalItemCount,
}: Props): IDoxlePaginationControl => {
  const [anchorPageControlList, setAnchorPageControlList] =
    useState<HTMLElement | null>(null);
  const [listControlMode, setListControlMode] = useState<
    "pageSize" | "pageNum" | undefined
  >(undefined);

  const handleClosePopoverListControl = useCallback(() => {
    setAnchorPageControlList(null);
    setListControlMode(undefined);
  }, []);

  const handleSetPage = useCallback(
    (pageNum: number) => {
      onSelectPage(pageNum);

      handleClosePopoverListControl();
    },
    [totalPage, onSelectPage, handleClosePopoverListControl]
  );
  const currenPageRange: {
    start: number;
    end: number;
  } = {
    start: currentPage * currentPageSize - (currentPageSize - 1),
    end:
      currentPage * currentPageSize >= totalItemCount
        ? totalItemCount
        : currentPage * currentPageSize,
  };
  const handleSetPageSize = useCallback(
    (pageSize: number) => {
      onSelectPageSize(pageSize);
      handleClosePopoverListControl();
    },
    [onSelectPageSize, handleClosePopoverListControl]
  );
  const handleClickPageSizeControl = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    setAnchorPageControlList(e.currentTarget);
    setListControlMode("pageSize");
  };

  const handleClickPageNumControl = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    setAnchorPageControlList(e.currentTarget);
    setListControlMode("pageNum");
  };

  return {
    anchorPageControlList,
    handleClosePopoverListControl,
    handleClickPageSizeControl,
    handleClickPageNumControl,
    listControlMode,
    handleSetPage,
    handleSetPageSize,
    currenPageRange,
  };
};

export default useDoxlePaginationControl;
