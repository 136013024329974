import { shallow } from "zustand/shallow";
import {
  EditDocketFunctionMenuType,
  useEditDocketSideScreenStore,
} from "../EditDocketSideScreenStore/useEditDocketSideScreenStore";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import {
  StyledMenuItem,
  StyledProjectPopover,
  StyledProjectPopoverMenu,
  StyledProjectTabIcon,
} from "../../Projects/StyledProjectComponents";
import {
  StyledMenuBookingsIcon,
  StyledMenuBudgetsIcon,
  StyledMenuContactsIcon,
  StyledMenuFilesIcon,
  StyledMenuPricebookIcon,
} from "../../Projects/ProjectIcon";
import { FaRegListAlt, FaTasks } from "react-icons/fa";
import { BsChatLeftQuote } from "react-icons/bs";
import { MdOutlinePayment } from "react-icons/md";
import { IoMdSettings } from "react-icons/io";
import { FaRegNoteSticky } from "react-icons/fa6";
import { CiMail } from "react-icons/ci";
import { FaRegCommentDots } from "react-icons/fa";

interface Props {
  anchorEl: HTMLDivElement | null;
  setAnchorEl: React.Dispatch<React.SetStateAction<HTMLDivElement | null>>;
}

export default function EditDocketShrinkMenu({ anchorEl, setAnchorEl }: Props) {
  const { doxleThemeColor, currentTheme, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
      currentTheme: state.currentTheme,
    })
  );

  const { docketFunctionMenu, setSelectedFunctionMenu, selectedFunctionMenu } =
    useEditDocketSideScreenStore(
      (state) => ({
        selectedFunctionMenu: state.selectedFunctionMenu,
        setSelectedFunctionMenu: state.setSelectedFunctionMenu,
        docketFunctionMenu: state.docketFunctionMenu,
      }),
      shallow
    );

  const getIcon = (tab: EditDocketFunctionMenuType) => {
    const iconColor =
      selectedFunctionMenu === tab
        ? doxleThemeColor.primaryReverseFontColor
        : doxleThemeColor.primaryFontColor;
    switch (tab) {
      // case "Drawings": {
      //   return (
      //     <StyledMenuDrawingIcon
      //       themeColor={doxleThemeColor}
      //       selected={tab === selectedFunctionMenu}
      //     />
      //   );
      // }
      case "Files": {
        return (
          <StyledMenuFilesIcon
            themeColor={doxleThemeColor}
            selected={tab === selectedFunctionMenu}
          />
        );
      }
      case "Quotes": {
        return <BsChatLeftQuote style={{ color: iconColor }} />;
      }
      case "Budget": {
        return (
          <StyledMenuBudgetsIcon
            themeColor={doxleThemeColor}
            selected={tab === selectedFunctionMenu}
          />
        );
      }
      case "Bookings": {
        return (
          <StyledMenuBookingsIcon
            themeColor={doxleThemeColor}
            selected={tab === selectedFunctionMenu}
          />
        );
      }
      case "Scope Of Works": {
        return <FaTasks style={{ color: iconColor }} />;
      }
      case "Mail": {
        return <CiMail style={{ color: iconColor }} />;
      }
      case "Pricebook": {
        return (
          <StyledMenuPricebookIcon
            themeColor={doxleThemeColor}
            selected={tab === selectedFunctionMenu}
          />
        );
      }
      case "Contacts": {
        return (
          <StyledMenuContactsIcon
            themeColor={doxleThemeColor}
            selected={tab === selectedFunctionMenu}
          />
        );
      }
      case "Comments": {
        return <FaRegCommentDots style={{ color: iconColor }} />;
      }
      case "Notes": {
        return <FaRegNoteSticky style={{ color: iconColor }} />;
      }
      case "Payments": {
        return <MdOutlinePayment style={{ color: iconColor }} />;
      }
      case "Detail": {
        return <IoMdSettings style={{ color: iconColor }} />;
      }
      case "Orders": {
        return <FaRegListAlt style={{ color: iconColor }} />;
      }
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <StyledProjectPopoverMenu
      open={open}
      onClose={handleClose}
      $themeColor={doxleThemeColor}
      $currentTheme={currentTheme}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
    >
      <StyledProjectPopover $themeColor={doxleThemeColor}>
        {docketFunctionMenu.map(
          (tab, idx) =>
            tab.display && (
              <StyledMenuItem
                $themeColor={doxleThemeColor}
                $doxleFont={doxleFont}
                $selected={tab.tabName === selectedFunctionMenu}
                onClick={() => {
                  setSelectedFunctionMenu(tab.tabName);
                  handleClose();
                }}
                key={`tab_shrink_${idx}`}
              >
                <StyledProjectTabIcon>
                  {getIcon(tab.tabName)}
                </StyledProjectTabIcon>
                {tab.tabName === "Detail"
                  ? "General"
                  : tab.tabName === "Scope Of Works"
                  ? "Tasks"
                  : tab.tabName}
              </StyledMenuItem>
            )
        )}
      </StyledProjectPopover>
    </StyledProjectPopoverMenu>
  );
}
