import { motion } from "framer-motion";
import styled from "styled-components";
import {
  DoxleFont,
  DoxleTheme,
} from "../../DoxleGeneralStore/useDoxleThemeStore";
import { editRgbaAlpha } from "../../Utilities/FunctionUtilities";

export const StyledDoxleStatus = styled(motion.div)<{
  $statusColor: `rgba(${number},${number},${number},${number})`;
  $borderWidthInRem: `${number}rem`;
  $borderRadiusInRem: `${number}rem`;
  $sizeInRem: `${number}rem`;
}>`
  width: ${(p) =>
    `calc(${p.$sizeInRem} - ${p.$borderWidthInRem} - ${p.$borderWidthInRem})`};
  height: ${(p) =>
    `calc(${p.$sizeInRem} - ${p.$borderWidthInRem} - ${p.$borderWidthInRem})`};
  border-radius: ${(p) => p.$borderRadiusInRem};
  border: ${(p) => p.$borderWidthInRem} solid ${(p) => p.$statusColor};
  cursor: pointer;
`;

export const StyledDoxleStatusSpan = styled.span<{
  $font: DoxleFont;
  $statusColor: `rgba(${number},${number},${number},${number})`;
  $currentTheme: DoxleTheme;
  $type: "oval" | "bordered";
}>`
  text-align: center;
  padding: ${(p) => (p.$type === "bordered" ? "8px 8px 0px 8px" : "2px 8px")};
  height: ${(p) => (p.$type === "bordered" ? "14px" : "18px")};
  font-family: ${(p) => p.$font.primaryFont};
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: ${(p) => (p.$type === "bordered" ? "6px" : "17px")};
  letter-spacing: 0.05em;
  margin: 0px 15px;
  color: ${(p) => p.$statusColor};
  background-color: ${(p) =>
    p.$type === "bordered"
      ? "none"
      : editRgbaAlpha({
          rgbaColor: p.$statusColor,
          alpha: p.$currentTheme === "dark" ? "0.4" : "0.1",
        })};
  border: ${(p) =>
    p.$type === "oval" ? "none" : "2px solid " + p.$statusColor};
  border-radius: ${(p) => (p.$type === "bordered" ? "11px" : "12px")};
  min-width: 73px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StyledDoxleStatusWrapper = styled(motion.div)<{
  $paddingHorizontal?: number;
  $bgColor: string;
}>`
  display: flex;
  flex-direction: row;
  padding: 2px ${(p) => p.$paddingHorizontal}px;
  justify-content: center;
  align-items: center;
`;

export const StyledDoxleStatusPercentage = styled(motion.div)<{
  $statusColor: `rgba(${number},${number},${number},${number})`;
  $textColor: string;
  $doxleFont: DoxleFont;
}>`
  border-radius: 0px 0.52rem 0.52rem 0.52rem;
  padding: 2px 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: ${(p) => p.$statusColor};

  .percentage-text {
    color: ${(p) => p.$textColor};
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.024rem;
  }
  .status-text {
    margin-right: 0.8rem;
    color: ${(p) => p.$textColor};
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.024rem;
  }
`;
export const StyledDoxleStatusIconTypeWrapper = styled(motion.div)<{
  $statusColor: string;
  $doxleFont: DoxleFont;
}>`
  display: flex;
  flex-direction: row;

  align-items: center;
  border-radius: 1.2rem;
  padding: 2px 8px;
  .status-icon-text {
    color: ${(p) => p.$statusColor};
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.028rem;
    margin-left: 2px;
    text-transform: capitalize;
  }
`;
