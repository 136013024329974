import DoxleDialogHelper, {
  DoxleDialogButton,
} from "../../DoxleDesignPattern/DoxleDialogHelper/DoxleDialogHelper";
import { editRgbaAlpha } from "../../Utilities/FunctionUtilities";
import {
  StyledDoxleDialogHelperActions,
  StyledDoxleDialogHelperContainer,
  StyledDoxleDialogHelperContent,
  StyledDoxleDialogHelperEditButton,
  StyledDoxleDialogHelperText,
  StyledDoxleDialogHelperTitle,
} from "../../DoxleDesignPattern/DoxleDialogHelper/StyledComponentDoxleDialogHelper";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import React, { useRef, MouseEvent } from "react";
import { useDoxleCurrentContextStore } from "../../DoxleGeneralStore/useDoxleCurrentContext";
import { StyledAddCompanyLoginTextField } from "./StyledComponentAddCompanyScreen";
import { useNavigate } from "react-router-dom";
import CompanyQueryAPI from "../../Services/QueryHooks/companyQueryAPI";
import { ToastContainer } from "react-toastify";
interface Props {
  deleteType: "Project" | "Company";
  deleteItemName: string;
  deleteItemId: string;
  deleteFunction: (deleteItemId: string) => void;
  closeDialog: () => void;
}
const ConfirmDeleteDialog = ({
  deleteType,
  deleteItemName,
  deleteItemId,
  deleteFunction,
  closeDialog,
}: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }),
    shallow
  );

  const description = `Are you sure you wish to delete the ${deleteType.toLowerCase()}: ${deleteItemName}?\n\nYour data will be deleted and will not be recoverable. If you're sure type "delete" in the text filed below.`;
  const textFieldRef = useRef<HTMLInputElement>(null);
  const textFieldOuterRef = useRef<HTMLDivElement>(null);

  const handleConfirm = (e: MouseEvent) => {
    if (textFieldRef?.current?.value === "delete" && deleteItemId)
      deleteFunction(deleteItemId);
    else if (textFieldOuterRef?.current)
      textFieldOuterRef.current.style.borderBottomColor = "red";
  };

  const dialogButtons: DoxleDialogButton[] = [
    {
      buttonText: "Cancel",
      buttonFunction: closeDialog,
    },
    {
      buttonText: "Delete " + deleteItemName,
      buttonFunction: handleConfirm,
      buttonColor: "rgba(255,0,0,1)",
    },
  ];

  return (
    <StyledDoxleDialogHelperContainer
      $themeColor={doxleThemeColor}
      open
      PaperProps={{
        sx: {
          bgColor: doxleThemeColor.primaryContainerColor,
          borderRadius: "0.9rem",
        },
        elevation: 4,
      }}
      slotProps={{
        backdrop: {
          sx: {
            bgcolor: editRgbaAlpha({
              rgbaColor: doxleThemeColor.primaryBackgroundColor,
              alpha: "0.4",
            }),
          },
        },
      }}
    >
      <StyledDoxleDialogHelperTitle
        $themeColor={doxleThemeColor}
        $doxleFont={doxleFont}
      >
        Delete {deleteType}: {deleteItemName}?
      </StyledDoxleDialogHelperTitle>
      <StyledDoxleDialogHelperContent $themeColor={doxleThemeColor}>
        <StyledDoxleDialogHelperText
          $themeColor={doxleThemeColor}
          $doxleFont={doxleFont}
        >
          {description.split("\n").map((text, i) => {
            if (i === 0) return text;
            else
              return (
                <>
                  <br />
                  {text}
                </>
              );
          })}
        </StyledDoxleDialogHelperText>
        <br />
        <StyledAddCompanyLoginTextField
          $themeColor={doxleThemeColor}
          $doxleFont={doxleFont}
          $hasBorderBottom
          placeholder={"Type delete"}
          inputRef={textFieldRef}
          ref={textFieldOuterRef}
        />
        <br />
      </StyledDoxleDialogHelperContent>
      <StyledDoxleDialogHelperActions $themeColor={doxleThemeColor}>
        <div
          style={{
            flexDirection: "row",
            display: "flex",
            width: "100%",
            justifyContent: "center",
          }}
        >
          <StyledDoxleDialogHelperEditButton
            key={`delete-dialog-${deleteItemId}#${0}`}
            className={`${0}-${dialogButtons[0].buttonText}`}
            $bgColor={dialogButtons[0].buttonColor}
            $themeColor={doxleThemeColor}
            $dialogDescription={description}
            $buttonIndex={0}
            $doxleFont={doxleFont}
            onClick={() => dialogButtons[0].buttonFunction()}
            layout
          >
            {dialogButtons[0].iconButton && dialogButtons[0].iconButton}
            <span
              className="button-text"
              style={{ ...dialogButtons[0].buttonTextStyle }}
            >
              {dialogButtons[0].buttonText}
            </span>
          </StyledDoxleDialogHelperEditButton>
          &nbsp;&nbsp;
          <StyledDoxleDialogHelperEditButton
            key={`delete-dialog-${deleteItemId}#${1}`}
            className={`${1}-${dialogButtons[1].buttonText}`}
            $bgColor={"rgba(255,0,0,1)"}
            $themeColor={doxleThemeColor}
            $dialogDescription={description}
            $buttonIndex={1}
            $doxleFont={doxleFont}
            onClick={() => dialogButtons[1].buttonFunction()}
            layout
          >
            {dialogButtons[1].iconButton && dialogButtons[1].iconButton}
            <span
              className="button-text"
              style={{ ...dialogButtons[1].buttonTextStyle }}
            >
              {dialogButtons[1].buttonText}
            </span>
          </StyledDoxleDialogHelperEditButton>
        </div>
      </StyledDoxleDialogHelperActions>
    </StyledDoxleDialogHelperContainer>
  );
};

export default ConfirmDeleteDialog;
