import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { shallow } from "zustand/shallow";
import DoxleSideScreen from "../../DoxleDesignPattern/DoxlleSideScreen/DoxleSideScreen";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import { TRgbaFormat, editRgbaAlpha } from "../../Utilities/FunctionUtilities";
import { useMailSideScreenStore } from "./MailSideScreenStore/UseMailSideScreenStore";
import useWindowDimensions from "../../CoreContent/UtilityHooks/useWindowDimensions";
import MailHalfScreenMode from "./MailHalfScreenMode";
import MailFullScreenMode from "./MailFullScreenMode";
import MailConversationAPI from "../Hooks/mailConversationAPI";
import { Mail, MailComments } from "../Models/mail";

type Props = {};

const MailSideScreen = (props: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }),
    shallow
  );
  const {
    currentMail,
    setCurrentMail,
    resetStore,
    isFullScreenMode,
    setIsFullScreenMode,
    currentDisplay,
  } = useMailSideScreenStore(
    (state) => ({
      currentMail: state.currentMail,
      setCurrentMail: state.setCurrentMail,
      resetStore: state.resetStore,
      isFullScreenMode: state.isFullScreenMode,
      setIsFullScreenMode: state.setIsFullScreenMode,
      currentDisplay: state.currentDisplay,
    }),
    shallow
  );

  const isModalOpen = useMemo(
    () => Boolean(currentDisplay !== null),
    [currentMail, currentDisplay]
  );
  const handleCloseModal = useCallback(() => {
    resetStore();
  }, []);

  const onModalExpanded = (value: boolean) => {
    setIsFullScreenMode(value);
  };
  const { width: viewPortWidth } = useWindowDimensions();

  return (
    <DoxleSideScreen
      openSideScreen={isModalOpen}
      handleCloseSideScreen={handleCloseModal}
      containerMinWidthInPixel="400px"
      containerWidthInRatio={
        viewPortWidth <= 900
          ? "85%"
          : viewPortWidth <= 1280
          ? "80%"
          : viewPortWidth <= 1480
          ? "75%"
          : "50%"
      }
      contentWrapperStyled={{
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        backgroundColor: doxleThemeColor.primaryBackgroundColor,
      }}
      hasBackDrop={true}
      backdropStyle={{
        backgroundColor: editRgbaAlpha({
          rgbaColor: doxleThemeColor.primaryBackdropColor as TRgbaFormat,
          alpha: "0.4",
        }),
      }}
      onExpanded={onModalExpanded}
      children={
        !isFullScreenMode ? <MailHalfScreenMode /> : <MailFullScreenMode />
      }
    />
  );
};

export default MailSideScreen;
