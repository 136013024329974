import React from "react";
import { IBooking } from "../Models/booking";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import { IoDocumentOutline } from "react-icons/io5";

import { editRgbaAlpha } from "../../Utilities/FunctionUtilities";
import { StyledBookingNumberContainer } from "./StyledComponentBookings";

type Props = {
  bookingItem: IBooking;
};

const BookingNumber = ({ bookingItem }: Props) => {
  const { doxleThemeColor, doxleFont, currentTheme } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
      currentTheme: state.currentTheme,
    }),
    shallow
  );
  return (
    <StyledBookingNumberContainer
      $themeColor={doxleThemeColor}
      $doxleFont={doxleFont}
    >
      <IoDocumentOutline
        fontSize="2.5rem"
        color={editRgbaAlpha({
          rgbaColor: doxleThemeColor.primaryFontColor,
          alpha: "0.7",
        })}
      />
      <div className="docket-number-text">{bookingItem.docketIdNum}</div>
    </StyledBookingNumberContainer>
  );
};

export default BookingNumber;
