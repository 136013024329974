import React from "react";
import { StyledPricebookAddNewDialog } from "../PricebookList/StyledComponents";
import useAddEditPricebookDialog from "./Hooks/useAddEditPricebookDialog";
import { usePricebookStore } from "../../Store/usePricebookStore";
import { useShallow } from "zustand/react/shallow";
import EditPricebookItem from "./EditPricebookItem";
import AddPricebookItem from "./AddPricebookItem";

type Props = {};

const AddEditPricebookDialog = (props: Props) => {
  const { isOpenDialog, handleCloseEditDialog } = useAddEditPricebookDialog();
  const {
    editedPricebookItemId,

    showAddPricebookDialog,
  } = usePricebookStore(
    useShallow((state) => ({
      editedPricebookItemId: state.editedPricebookItemId,

      showAddPricebookDialog: state.showAddPricebookDialog,
    }))
  );
  return (
    <StyledPricebookAddNewDialog
      open={isOpenDialog}
      onClose={handleCloseEditDialog}
    >
      {editedPricebookItemId && (
        <EditPricebookItem pricebookId={editedPricebookItemId} />
      )}

      {showAddPricebookDialog && <AddPricebookItem />}
    </StyledPricebookAddNewDialog>
  );
};

export default AddEditPricebookDialog;
