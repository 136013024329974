import React from "react";
import {
  IFilterQuoteResponseQuery,
  getQuoteResponseQKey,
} from "../QueryAPI/quotesQuery";
import { useDoxleCurrentContextStore } from "../../DoxleGeneralStore/useDoxleCurrentContext";
import { shallow } from "zustand/shallow";
import { useQueryClient } from "@tanstack/react-query";
import { QuoteResponse } from "../models/quote";
import { produce } from "immer";
import { IApiPaginatedData } from "../../Models/axiosReturn";

type Props = {
  filter: IFilterQuoteResponseQuery;
  appendPos?: "start" | "end";
  overwrite?: boolean;
};
interface SetQuoteResponseData {
  handleAddQuoteResponse: (newQuoteResponse: QuoteResponse) => void;
  handleRemoveMultiQuoteResponse: (deletedQuoteResponseIds: string[]) => void;
}
const useSetQuoteResponseData = ({
  filter,
  appendPos = "start",
  overwrite = true,
}: Props): SetQuoteResponseData => {
  const { currentCompany } = useDoxleCurrentContextStore(
    (state) => ({
      currentCompany: state.currentCompany,
    }),
    shallow
  );
  const qKey = getQuoteResponseQKey(filter, currentCompany);
  const queryClient = useQueryClient();
  const handleAddQuoteResponse = (newQuoteResponse: QuoteResponse) => {
    const quoteResponseData = queryClient.getQueryData(qKey);
    if (quoteResponseData && overwrite) {
      queryClient.setQueryData(qKey, (old: any) => {
        if (old) {
          const lengthOfPages = old.pages.length;
          return produce(old, (draftOld: any) => {
            draftOld.pages = produce(draftOld.pages, (draftPages: any) => {
              if (appendPos === "start") {
                (
                  draftPages[0].data as IApiPaginatedData<QuoteResponse>
                ).results.unshift(newQuoteResponse);
              } else {
                (
                  draftPages[lengthOfPages - 1]
                    .data as IApiPaginatedData<QuoteResponse>
                ).results.push(newQuoteResponse);
              }

              return draftPages;
            });

            return draftOld;
          });
        } else return queryClient.invalidateQueries(qKey);
      });
    } else queryClient.invalidateQueries(qKey);
  };

  const handleRemoveMultiQuoteResponse = (
    deletedQuoteResponseIds: string[]
  ) => {
    const queryData = queryClient.getQueryData(qKey);
    if (queryData && overwrite) {
      queryClient.setQueryData(qKey, (old: any) => {
        let pageIndexContainItems: Array<{
          pageIndex: number;
          lisOfIds: string[];
        }> = [];
        old.pages.forEach((page: any, index: number) => {
          const requestData = page.data.results as QuoteResponse[];

          let listOfMatchedIds: string[] = [];
          deletedQuoteResponseIds.forEach((deletedId) => {
            if (requestData.find((request) => request.responseId === deletedId))
              listOfMatchedIds.push(deletedId);
          });

          if (listOfMatchedIds.length > 0)
            pageIndexContainItems.push({
              pageIndex: index,
              lisOfIds: listOfMatchedIds,
            });
        });
        // (old.pages as any[]).forEach((page) => {
        //   if (page.data.results.find((requestItem:QuoteRequest)=>requestItem.requestId===))
        // });

        return produce(old, (draftOld: any) => {
          if (pageIndexContainItems.length > 0) {
            draftOld.pages = produce(draftOld.pages, (draftPages: any) => {
              pageIndexContainItems.forEach((pageContainItem) => {
                const { pageIndex, lisOfIds } = pageContainItem;

                draftPages[pageIndex].data.results = produce(
                  draftPages[pageIndex].data.results,
                  (draftResults: QuoteResponse[]) => {
                    draftResults = old.pages[pageIndex].data.results.filter(
                      (requestItem: QuoteResponse) =>
                        !lisOfIds.includes(requestItem.responseId)
                    );

                    return draftResults;
                  }
                );
              });

              return draftPages;
            });
          } else queryClient.invalidateQueries(qKey);
          return draftOld;
        });
      });
    } else queryClient.invalidateQueries(qKey);
  };

  return { handleAddQuoteResponse, handleRemoveMultiQuoteResponse };
};

export default useSetQuoteResponseData;
