import React from "react";
import { useDoxleThemeStore } from "../../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import useEditBookingTitle from "./Hooks/useEditBookingTitle";
import { StyledEditBookingTitleContainer } from "./StyledComponentEditBookingProject";
import BookingDocketNumber from "../../../CommonComponents/BookingDocketNumber";
import useBookingProjectStore from "../../../store/useBookingProjectStore";

import CircularProgress from "@mui/material/CircularProgress";
import { StyledBookingTitleTextField } from "../../../CommonComponents/StyledComponentBookings";
import { useEditProjectBookingContext } from "./EditBookingProject";
import DoxleMultilineTextField from "../../../../DoxleDesignPattern/DoxleTextField/DoxleMultilineTextField";

type Props = {};

const EditBookingTitle = (props: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
      currentTheme: state.currentTheme,
    }),
    shallow
  );
  const { setBookingTitle, bookingTitle } = useEditProjectBookingContext();
  const {
    setNewTitleInput,
    newTitleInput,
    handleEditUpdateBookingTitle,
    onKeydownTitleInput,
    isUpdatingTitle,
    inputRef,
  } = useEditBookingTitle({});
  const { edittedBooking } = useBookingProjectStore(
    (state) => ({
      edittedBooking: state.edittedBooking,
    }),
    shallow
  );
  return (
    <StyledEditBookingTitleContainer>
      <BookingDocketNumber
        docketNumber={edittedBooking?.docketIdNum}
        docketIconSize="5rem"
        required={true}
      />

      <DoxleMultilineTextField
        value={bookingTitle}
        onKeyDown={onKeydownTitleInput}
        onChange={(e) => setBookingTitle(e.target.value)}
        onBlur={() => handleEditUpdateBookingTitle}
        onFocus={(e) => e.currentTarget.select()}
        maxRows={2}
        style={{
          backgroundColor: "transparent",
          maxWidth: "50rem",
          fontSize: "1.8rem",
        }}
        sx={[
          {
            textarea: {
              fontSize: "1.8rem",
              "&::placeholder": { fontSize: "1.8rem" },
            },
          },
        ]}
        placeholder="New booking title..."
      />

      {/* <StyledBookingTitleTextField
        $themeColor={doxleThemeColor}
        $doxleFont={doxleFont}
        placeholder="New booking title..."
        variant="standard"
        value={bookingTitle}
        onChange={(e) => setBookingTitle(e.target.value)}
        autoFocus
        inputRef={inputRef}
        InputProps={{
          endAdornment: isUpdatingTitle ? (
            <CircularProgress
              size="1.2rem"
              style={{ color: "inherit", marginLeft: 8 }}
            />
          ) : null,
        }}
        onKeyDown={onKeydownTitleInput}
        onFocus={(e) => e.target.select()}
        onBlur={handleEditUpdateBookingTitle}
      /> */}
    </StyledEditBookingTitleContainer>
  );
};

export default EditBookingTitle;
