import React from "react";
import {
  StyledDescriptionInputEndornment,
  StyledEditQATextField,
  StyledQAEditFieldWrapper,
} from "./StyledComponentQAItemDetail";
import { useDoxleThemeStore } from "../../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import useEditQADescription from "./Hooks/useEditQADescription";
import CircularProgress from "@mui/material/CircularProgress";
import DoxleMultilineTextField from "../../../../DoxleDesignPattern/DoxleTextField/DoxleMultilineTextField";
import { editRgbaAlpha } from "../../../../Utilities/FunctionUtilities";
import { useShallow } from "zustand/react/shallow";

type Props = {};

const EditQADescription = (props: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    useShallow((state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }))
  );

  const {
    newQADesciptionText,
    setNewQADesciptionText,
    handleKeydownInput,
    inputRef,
    handleUpdateQADescription,
    isUpdatingDescription,
  } = useEditQADescription({});
  return (
    <StyledQAEditFieldWrapper>
      <span className="field-label-text">Title</span>

      <DoxleMultilineTextField
        value={newQADesciptionText}
        onKeyDown={handleKeydownInput}
        onChange={(e) => setNewQADesciptionText(e.target.value)}
        onFocus={(e) => e.currentTarget.select()}
        onBlur={handleUpdateQADescription}
        maxRows={3}
        style={{
          backgroundColor: doxleThemeColor.primaryContainerColor,
          border:
            "1px solid " +
            editRgbaAlpha({
              rgbaColor: doxleThemeColor.primaryDividerColor,
              alpha: "0.5",
            }),
        }}
        sx={[
          {
            textarea: {
              fontSize: "16px",
              "&::placeholder": { fontSize: "16px" },
              fontFamily: doxleFont.primaryFont,
            },
          },
        ]}
        placeholder="New description..."
        InputProps={{
          endAdornment: isUpdatingDescription && (
            <StyledDescriptionInputEndornment position="end">
              <CircularProgress
                style={{ color: doxleThemeColor.primaryFontColor }}
                size="16px"
              />
            </StyledDescriptionInputEndornment>
          ),
        }}
      />
    </StyledQAEditFieldWrapper>
  );
};

export default EditQADescription;
