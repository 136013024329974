import React from "react";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import {
  StyledCloseButton,
  StyledFileInfoContainer,
  StyledFileName,
} from "./StyledAddInventory";

type Props = {
  imgFile: File;
  index: number;
  removeFile: (index: number) => void;
};

const AddedImageItems = ({ imgFile, index, removeFile }: Props) => {
  const { doxleThemeColor, currentTheme, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
      currentTheme: state.currentTheme,
    })
  );
  return (
    <StyledFileInfoContainer
      $themeColor={doxleThemeColor}
      $doxleFont={doxleFont}
    >
      <img
        src={URL.createObjectURL(imgFile)}
        style={{ width: "100%", aspectRatio: 1 }}
      />
      <StyledFileName $themeColor={doxleThemeColor} $doxleFont={doxleFont}>
        {imgFile.name}
      </StyledFileName>
      <StyledCloseButton
        onClick={() => {
          removeFile(index);
        }}
      >
        <img
          key={"watcher-img-" + index}
          src="/assets/icons/close_button_fill.svg"
          style={{ width: 20, height: 20 }}
        />
      </StyledCloseButton>
    </StyledFileInfoContainer>
  );
};

export default React.memo(AddedImageItems);
