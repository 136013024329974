import {DoxCircle, DoxLine, DoxPolygon, DoxRectangle} from "../Models/takeOffs";
import {useMemo} from "react";

export const toFloat = (value: string | number | undefined) =>  {
  if (typeof value === "undefined") return 0;
  if (typeof value === "string")
    return !isNaN(parseFloat(value)) ? parseFloat(value) : 0;
  return value;
}

export const sumMeasurements = (measurements: (DoxCircle|DoxLine|DoxRectangle|DoxPolygon)[]) => {
  let sum:number = 0;
  measurements.forEach(m => sum += toFloat(m.actualValue))
  return sum
}
