import { CircularProgress, InputAdornment } from "@mui/material";
import React, { useCallback, useMemo, useState } from "react";
import DoxleSendButton from "../../../../DoxleDesignPattern/DoxleButtons/DoxleSendButton";
import { useDoxleThemeStore } from "../../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import { useDoxleAuthStore } from "../../../../DoxleGeneralStore/useDoxleAuthStore";
import { useDoxleCurrentContextStore } from "../../../../DoxleGeneralStore/useDoxleCurrentContext";
import useDoxleNotificationStore from "../../../../DoxleGeneralStore/useDoxleNotificationStore";
import ProjectQueryAPI, {
  FilterGetProjectQuery,
} from "../../../../Services/QueryHooks/projectQueryAPI";
import { StyledAddProjectTextField } from "../../StyledComponentsEditDocketSideScreen";

type Props = {
  hideProjectTextFieldFunction?: Function;
  filterProjectListQuery: FilterGetProjectQuery;
};

const AddProjectTextField = ({
  hideProjectTextFieldFunction,
  filterProjectListQuery,
}: Props) => {
  const [newProjectAddressText, setNewProjectAddressText] =
    useState<string>("");
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }),
    shallow
  );
  const { company } = useDoxleCurrentContextStore(
    (state) => ({
      company: state.currentCompany,
    }),
    shallow
  );
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );
  //# ADD PROJECTS QUERY
  const addProjectQuery = ProjectQueryAPI.useAddProjectQuery({
    showNotification,

    company,
    filter: filterProjectListQuery,
  });

  const handleAddProject = useCallback(() => {
    const href = window.location.href.toLowerCase()
    if (newProjectAddressText) {
      addProjectQuery.mutate({
        siteAddress: newProjectAddressText,
        isBudget: (href.includes('project') || (!href.includes('doxle') && !href.includes('noticeboard'))),
        isNoticeboard: href.includes('noticeboard'),
      });
    }
  }, [newProjectAddressText]);
  const projectTextFieldAnimatedVariants = useMemo(
    () => ({
      initial: {
        opacity: 0,
        x: -10,
      },
      enter: {
        opacity: 1,
        x: 0,
        transition: {
          duration: 0.4,
        },
      },
      exit: {
        opacity: 0,
        y: -10,
        transition: {
          duration: 0.4,
        },
      },
    }),
    []
  );
  return (
    <StyledAddProjectTextField
      animate="enter"
      exit="exit"
      initial="initial"
      variants={projectTextFieldAnimatedVariants}
      $themeColor={doxleThemeColor}
      $doxleFont={doxleFont}
      onChange={(
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      ) => {
        setNewProjectAddressText(event.target.value);
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment
            position="end"
            style={{ display: "flex", flexDirection: "row" }}
          >
            {addProjectQuery.isLoading && (
              <CircularProgress
                size={10}
                sx={{ color: doxleThemeColor.doxleColor, marginRight: "5px" }}
              />
            )}
            <DoxleSendButton
              disabled={Boolean(!newProjectAddressText)}
              onClick={handleAddProject}
            />
          </InputAdornment>
        ),
      }}
      placeholder="Enter Project Address..."
      onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => {
        event.stopPropagation();
        if (event.nativeEvent.key === "Enter") {
          handleAddProject();
        }
        if (event.nativeEvent.key === "Escape") {
          if (hideProjectTextFieldFunction) hideProjectTextFieldFunction();
        }
      }}
    />
  );
};

export default AddProjectTextField;
