import {useDoxleCurrentContextStore} from "../../DoxleGeneralStore/useDoxleCurrentContext";
import {useDoxleAuthStore} from "../../DoxleGeneralStore/useDoxleAuthStore";
import useDoxleNotificationStore from "../../DoxleGeneralStore/useDoxleNotificationStore";
import {shallow} from "zustand/shallow";
import {useMutation, useQuery} from "@tanstack/react-query";
import DoxleAPI from "../../Services/DoxleAPI";
import {AxiosResponse, isAxiosError} from "axios";
import { AxiosBackendErrorReturn } from "../../Models/axiosReturn";

interface RetrieveBillProps {
  paymentClaimId: string;
  projectId?: string;
  docketPk?: string;
}
const useRetrieveBillDetail = ({paymentClaimId, projectId, docketPk}: RetrieveBillProps) => {
  const company= useDoxleCurrentContextStore(state => state.currentCompany, shallow)
  const showNotification = useDoxleNotificationStore(state => state.showNotification, shallow)
  let params: any = {}
  if (projectId) params.project = projectId;
  if (docketPk) params.docket = docketPk;
  return useQuery(
    ["accounds-bill-detail", paymentClaimId],
    () =>
      DoxleAPI.get<any>(`/accounts/${paymentClaimId}/`, {
        headers: {

          "User-Company": company!.companyId,
        },
        params
      }),
    {
      enabled: Boolean(company && paymentClaimId),
      retry: 1,
      // staleTime: 10 * 60 * 1000,
      refetchInterval: false,
      refetchIntervalInBackground: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      onError: (error) => {
          if (showNotification) {
            if (isAxiosError<AxiosBackendErrorReturn>(error)) {
              showNotification(
                `${error?.response?.status ?? "ERROR"}: ${
                  error.response?.data.detail ?? "UNKNOWN ERROR"
                }`,
                "error",
                String(
                  error?.response?.data?.detail ?? "FAILED TO GET BILL DETAILS FROM ACCOUNTING SERVICE"
                ).substring(0, 300)
              );
            } else {
              showNotification(
                "Something Wrong!",
                "error",
                "FAILED TO GET BILL DETAILS FROM ACCOUNTING SERVICE"
              );
            }
          }
      },
    }
  );
}

const useRetrieveAttachment = () => {
  const company= useDoxleCurrentContextStore(state => state.currentCompany, shallow)
  const showNotification = useDoxleNotificationStore(state => state.showNotification, shallow)

  return useMutation(
    ["accounts-get-attachment"],
    (url: string) => DoxleAPI.get<any>(`/accounts/attachments/`, {
        headers: {

          "User-Company": company!.companyId,
        },
        responseType: 'arraybuffer',
        params: {url}
      }),
    {
      retry: 1,
      onSuccess: (data: AxiosResponse<any>, variables, context) => {
        const file = new Blob([data.data], {type: String(data.headers["content-type"])});
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      },
      onError: (error) => {
          if (showNotification) {
            if (isAxiosError<AxiosBackendErrorReturn>(error)) {
              showNotification(
                `${error?.response?.status ?? "ERROR"}: ${
                  error.response?.data.detail ?? "UNKNOWN ERROR"
                }`,
                "error",
                String(
                  error?.response?.data?.detail ?? "FAILED TO GET BILL DETAILS FROM ACCOUNTING SERVICE"
                ).substring(0, 300)
              );
            } else {
              showNotification(
                "Something Wrong!",
                "error",
                "FAILED TO GET BILL DETAILS FROM ACCOUNTING SERVICE"
              );
            }
          }
      },
    }
  );
}

export const AccountsQueryAPI = {
  useRetrieveBillDetail,
  useRetrieveAttachment
}
