import React, { useEffect, useState } from "react";
import { Docket, LightDocket } from "../../../../Models/dockets";
import { useEditDocketSideScreenStore } from "../../../../CoreContent/EditDocketSideScreen/EditDocketSideScreenStore/useEditDocketSideScreenStore";
import { shallow } from "zustand/shallow";
import { useInboxStore } from "../../../InboxStore/useInboxStore";
import useSetDocketListQueryData from "../../../../CoreContent/QueryDataHooks/SetQueryDataHooks/useSetDocketListQueryData";
import { useDoxleCurrentContextStore } from "../../../../DoxleGeneralStore/useDoxleCurrentContext";
import useDoxleNotificationStore from "../../../../DoxleGeneralStore/useDoxleNotificationStore";
import DocketQuery from "../../../../Services/QueryHooks/docketQueryAPI";
import { useDoxleAuthStore } from "../../../../DoxleGeneralStore/useDoxleAuthStore";

type Props = { item: LightDocket };

interface INoticeBoardRow {
  handlePinNb: () => void;
  isHover: boolean;
  setIsHover: React.Dispatch<React.SetStateAction<boolean>>;
  handleClickRow: (initialTab?: "comment" | "general") => void;
  handleToggleArchiveNb: () => void;
}
const useNoticeBoardRow = ({ item }: Props): INoticeBoardRow => {
  const [isHover, setIsHover] = useState(false);
  const {
    setInitialEditedDocket,
    setFilterDocketListQuery,
    setHandlePostUpdateDocket,
    setSelectedFunctionMenu,
  } = useEditDocketSideScreenStore(
    (state) => ({
      setInitialEditedDocket: state.setInitialEditedDocket,
      setFilterDocketListQuery: state.setFilterDocketListQuery,
      setHandlePostUpdateDocket: state.setHandlePostUpdateDocket,
      setSelectedFunctionMenu: state.setSelectedFunctionMenu,
    }),
    shallow
  );
  const { filterDocketListQuery, inboxViewMode } = useInboxStore(
    (state) => ({
      filterDocketListQuery: state.filterDocketListQuery,

      inboxViewMode: state.inboxViewMode,
    }),
    shallow
  );

  const user = useDoxleAuthStore((state) => state.user, shallow);
  const {
    handleUpdateDocket: handleUpdateMyNoticeDockets,
    handleRemoveDocket: handleRemoveMyNoticeDockets,
    handleAddDocket: handleAddMyNoticeDockets,
    isDocketExist: isMyDocketExist,
  } = useSetDocketListQueryData({
    filter: {
      ...filterDocketListQuery,
      ball_in_court: user?.userId,
      archived: false,
    },
  });

  const {
    handleUpdateDocket: handleUpdateAllNoticeDockets,
    handleRemoveDocket: handleRemoveAllNoticeDockets,
    handleAddDocket: handleAddAllNoticeDockets,
    isDocketExist: isAllDocketExist,
  } = useSetDocketListQueryData({
    filter: {
      ...filterDocketListQuery,
      ball_in_court: undefined,
      archived: false,
    },
  });

  const {
    handleUpdateDocket: handleUpdateArchiveDockets,
    handleRemoveDocket: handleRemoveArchiveDockets,
    handleAddDocket: handleAddArchiveDockets,
    isDocketExist: isArchivedDocketExist,
  } = useSetDocketListQueryData({
    filter: {
      ...filterDocketListQuery,
      ball_in_court: undefined,
      archived: true,
    },
  });

  const { currentCompany } = useDoxleCurrentContextStore(
    (state) => ({
      currentCompany: state.currentCompany,
    }),
    shallow
  );
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );
  const onSuccessUpdate = (newDocket?: Docket) => {
    if (newDocket) {
      if (inboxViewMode !== "Archived") {
        if (newDocket.isArchived) {
          if (isMyDocketExist(newDocket.docketPk))
            handleRemoveMyNoticeDockets(newDocket.docketPk);

          if (isAllDocketExist(newDocket.docketPk))
            handleRemoveAllNoticeDockets(newDocket.docketPk);

          if (!isArchivedDocketExist(newDocket.docketPk))
            handleAddArchiveDockets(newDocket);
          else handleUpdateArchiveDockets(newDocket);
        } else {
          if (!isMyDocketExist(newDocket.docketPk))
            handleAddMyNoticeDockets(newDocket);
          else handleUpdateMyNoticeDockets(newDocket);

          if (!isAllDocketExist(newDocket.docketPk))
            handleAddAllNoticeDockets(newDocket);
          else handleUpdateAllNoticeDockets(newDocket);

          if (isArchivedDocketExist(newDocket.docketPk))
            handleRemoveArchiveDockets(newDocket.docketPk);
        }
      } else {
        if (!newDocket.isArchived) {
          if (!isMyDocketExist(newDocket.docketPk))
            handleAddMyNoticeDockets(newDocket);
          else handleUpdateMyNoticeDockets(newDocket);

          if (!isAllDocketExist(newDocket.docketPk))
            handleAddAllNoticeDockets(newDocket);
          else handleUpdateAllNoticeDockets(newDocket);

          if (isArchivedDocketExist(newDocket.docketPk))
            handleRemoveArchiveDockets(newDocket.docketPk);
        }
      }
    }
  };
  const updateNbQuery = DocketQuery.useUpdateDocketQuery({
    filter: filterDocketListQuery,

    company: currentCompany,
    onSuccessCb: onSuccessUpdate,
    showNotification,
  });
  const handleClickRow = (initialTab?: "comment" | "general") => {
    setFilterDocketListQuery(filterDocketListQuery);
    setInitialEditedDocket(item);
    setHandlePostUpdateDocket((docket) => {
      onSuccessUpdate(docket);
    });

    if (initialTab === "comment") setSelectedFunctionMenu("Comments");
    if (item.isNew) {
      handleUpdateMyNoticeDockets({ ...item, isNew: false });
      handleUpdateAllNoticeDockets({ ...item, isNew: false });
    }
  };
  const handlePinNb = () => {
    updateNbQuery.mutate({
      docketPk: item.docketPk,
      isSticky: !item.isSticky,
    });
  };

  const handleToggleArchiveNb = () => {
    updateNbQuery.mutate({
      docketPk: item.docketPk,
      isArchived: !item.isArchived,
    });
  };
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (item.isNew) handleClickRow();
    }, 300);

    return () => {
      clearTimeout(timeout);
    };
  }, []);
  return {
    handlePinNb,
    isHover,
    setIsHover,
    handleClickRow,
    handleToggleArchiveNb,
  };
};

export default useNoticeBoardRow;
