import React from "react";
import {
  StyledBreadCrumbQANavItem,
  StyledQAProjectNavContainer,
} from "./StyledComponentQAProject";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import useQAProjectNav from "./Hooks/useQAProjectNav";
import { useProjectQAStore } from "./Store/useProjectQAStore";
import { AnimatePresence, Variants } from "framer-motion";
import QAListToggleMenu from "./QAListToggleMenu";
import { editRgbaAlpha } from "../../Utilities/FunctionUtilities";
import { useShallow } from "zustand/react/shallow";

type Props = {};

const QAProjectNav = (props: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    useShallow((state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }))
  );
  const { selectedQAList, selectedQAItem, edittedQAListItem } =
    useProjectQAStore(
      useShallow((state) => ({
        selectedQAList: state.selectedQAList,
        selectedQAItem: state.selectedQAItem,
        edittedQAListItem: state.edittedQAListItem,
      }))
    );
  const { handleClickRootNav, handleClickQAListNav } = useQAProjectNav({});
  const rootCrumbVariants: Variants = {
    initial: {
      backgroundColor: doxleThemeColor.primaryContainerColor,
      color: doxleThemeColor.primaryFontColor,
    },
    selected: {
      // backgroundColor: doxleThemeColor.doxleColor,
      color: doxleThemeColor.doxleColor,
    },
    hover: {
      opacity: 0.9,
      translateY: -2,
      transition: {
        duration: 0.2,
      },
    },
  };

  const subCrumbVariants: Variants = {
    initial: {
      opacity: 0,
      x: 14,
      backgroundColor: doxleThemeColor.primaryContainerColor,
      color: doxleThemeColor.primaryFontColor,
    },
    enter: {
      opacity: 1,
      x: 0,
      backgroundColor: doxleThemeColor.primaryContainerColor,
      color: doxleThemeColor.primaryFontColor,
    },
    selected: {
      opacity: 1,
      x: 0,
      // backgroundColor: doxleThemeColor.primaryFontColor,
      color: doxleThemeColor.doxleColor,
    },
    exit: {
      opacity: 0,
      x: 100,
    },
    hover: {
      opacity: 0.9,
      translateY: -2,
      transition: {
        duration: 0.2,
      },
    },
  };

  return (
    <StyledQAProjectNavContainer>
      <div className="nav-wrapper">
        <StyledBreadCrumbQANavItem
          variants={rootCrumbVariants}
          initial="initial"
          animate={
            selectedQAList || selectedQAItem || edittedQAListItem
              ? "initial"
              : "selected"
          }
          whileHover="hover"
          onClick={handleClickRootNav}
          transition={{
            duration: 0.2,
          }}
        >
          <span className="nav-text"> Checklist</span>
        </StyledBreadCrumbQANavItem>
        <AnimatePresence>
          {selectedQAList && (
            <>
              <StyledBreadCrumbQANavItem
                variants={subCrumbVariants}
                initial="initial"
                animate={selectedQAItem ? "enter" : "selected"}
                onClick={handleClickQAListNav}
                transition={{
                  duration: 0.2,
                }}
                whileHover="hover"
                $isSubMenu={true}
              >
                <span className="nav-text">
                  {selectedQAList.defectListTitle}
                </span>
              </StyledBreadCrumbQANavItem>

              {selectedQAItem && (
                <StyledBreadCrumbQANavItem
                  variants={subCrumbVariants}
                  initial="initial"
                  animate="selected"
                  transition={{
                    duration: 0.2,
                  }}
                  whileHover="hover"
                  $isSubMenu={true}
                >
                  <span className="nav-text"> Edit QA</span>
                </StyledBreadCrumbQANavItem>
              )}
            </>
          )}

          {edittedQAListItem && (
            <StyledBreadCrumbQANavItem
              variants={rootCrumbVariants}
              initial="initial"
              animate={"selected"}
              whileHover="hover"
              transition={{
                duration: 0.2,
              }}
              $isSubMenu={true}
            >
              <span className="nav-text">Edit QA List</span>
            </StyledBreadCrumbQANavItem>
          )}
        </AnimatePresence>
      </div>

      <AnimatePresence>
        <QAListToggleMenu />
      </AnimatePresence>
    </StyledQAProjectNavContainer>
  );
};

export default QAProjectNav;
