import {
  usePopupState,
  bindFocus,
  bindTrigger,
} from "material-ui-popup-state/hooks";
import {
  StyledShareListScroller,
  StyledShareProjectBtn,
  StyledShareProjectDialog,
  StyledShareProjectDialogContent,
  StyledShareProjectDialogTitle,
} from "./StyledShareProjectDialog";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import useShareProjectDialog, {
  IShareProjectDialogContext,
} from "./Hooks/useShareProjectDialog";
import { useDoxleCurrentContextStore } from "../../../DoxleGeneralStore/useDoxleCurrentContext";
import DoxleCloseBtn from "../../../DoxleDesignPattern/DoxleButtons/DoxleCloseBtn";
import { TfiClose } from "react-icons/tfi";
import useGetSharePermission from "../../QueryDataHooks/GetQueryDataHooks/useGetSharePermission";
import LinearProgress from "@mui/material/LinearProgress";
import {
  editRgbaAlpha,
  generateRGBAColors,
} from "../../../Utilities/FunctionUtilities";
import { Components, ItemContent, Virtuoso } from "react-virtuoso";
import {
  CompanyPermission,
  DocketPermission,
  ProjectPermission,
} from "../../../Models/permissions";
import { createContext, useCallback, useContext, useMemo } from "react";
import DoxleEmptyPlaceHolder from "../../../DoxleDesignPattern/DoxleEmptyPlaceHolder/DoxleEmptyPlaceHolder";
import React from "react";
import { Error404Banner } from "../../../DoxleDesignPattern/DoxleIcons/CommonIcons";
import { EmptyShareListBanner } from "./ShareDialogIcons";
import SharedProjectUserItem from "./SharedProjectUserItem";
import EditPermissionPopoverForm from "./EditPermissionPopoverForm";
import ShareProjectSearchSection from "./ShareProjectSearchSection";

type Props = {};

interface IProjectPermissionContext {
  isErrorFetchingPermissions: boolean;
}

export const ShareProjectDialogContext =
  createContext<IShareProjectDialogContext | null>(null);
const ShareProjectDialog = (props: Props) => {
  const { doxleThemeColor, staticMenuColor } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      staticMenuColor: state.staticMenuColor,
    }),
    shallow
  );
  const { currentProject } = useDoxleCurrentContextStore(
    (state) => ({
      currentProject: state.currentProject,
    }),
    shallow
  );
  const { setShowShareDialog, showShareDialog, contextValue } =
    useShareProjectDialog({});

  const { permissions, isFetchingPermissions, isErrorFetchingPermissions } =
    useGetSharePermission({ project: currentProject?.projectId });
  const popupState = usePopupState({
    variant: "popover",
    popupId: "root-share-project-doxle",
  });
  const randomColors = useMemo(
    () => generateRGBAColors(permissions.length),
    [permissions.length]
  );
  //*render list
  const components: Components<
    ProjectPermission | CompanyPermission | DocketPermission,
    IProjectPermissionContext
  > = useMemo(
    () => ({
      Scroller: React.forwardRef((props, ref) => (
        <StyledShareListScroller {...props} ref={ref} />
      )),

      EmptyPlaceholder: (props) => (
        <DoxleEmptyPlaceHolder
          headTitleText={
            props.context?.isErrorFetchingPermissions
              ? "Something wrong"
              : "No Shared Users"
          }
          subTitleText={
            props.context?.isErrorFetchingPermissions
              ? "Please try to refresh page, we are sorry for this!"
              : "Share access to people to collab..."
          }
          containerBgColor="transparent"
          headTitleTextStyle={{
            fontSize: "20px",
            color: staticMenuColor.staticFontColor,
          }}
          subTitleTextStyle={{
            fontSize: 14,
            color: staticMenuColor.staticFontColor,
          }}
          illustration={
            props.context?.isErrorFetchingPermissions ? (
              <Error404Banner
                themeColor={doxleThemeColor}
                containerStyle={{ width: "50%", marginBottom: 14 }}
              />
            ) : (
              <EmptyShareListBanner
                themeColor={doxleThemeColor}
                containerStyle={{ width: "50%", marginBottom: 14 }}
                staticColor={staticMenuColor.staticFontColor}
              />
            )
          }
        />
      ),
    }),
    []
  );

  const itemContent: ItemContent<
    ProjectPermission | CompanyPermission | DocketPermission,
    IProjectPermissionContext
  > = useCallback(
    (index, item) => (
      <SharedProjectUserItem
        sharedUserDetail={item}
        randomColor={randomColors[index]}
      />
    ),
    [randomColors]
  );
  return (
    <ShareProjectDialogContext.Provider value={contextValue}>
      <StyledShareProjectBtn
        {...bindTrigger(popupState)}
        {...bindFocus(popupState)}
        layout="position"
        transition={{ duration: 0.2, damping: 16 }}
        onClick={() => setShowShareDialog(true)}
      >
        Share
      </StyledShareProjectBtn>

      <StyledShareProjectDialog
        onClose={() => setShowShareDialog(false)}
        open={showShareDialog}
        PaperProps={{
          elevation: 2,
        }}
      >
        <StyledShareProjectDialogTitle>
          <span className="project-address">
            Share {currentProject?.siteAddress}
          </span>

          <DoxleCloseBtn
            sx={{ width: 30, height: 30, padding: "4px !important" }}
            onClick={() => setShowShareDialog(false)}
            closeIcon={
              <TfiClose
                color={staticMenuColor.staticFontColor}
                size={16}
                strokeWidth={0.5}
              />
            }
          />
        </StyledShareProjectDialogTitle>

        <StyledShareProjectDialogContent>
          {isFetchingPermissions && (
            <>
              <span className="loader-text">Getting Share Detail...</span>
              <LinearProgress
                className="loader-bar"
                sx={{
                  "&.MuiLinearProgress-root": {
                    backgroundColor: editRgbaAlpha({
                      rgbaColor: doxleThemeColor.doxleColor,
                      alpha: "0.3",
                    }),
                  },
                  "& .MuiLinearProgress-bar": {
                    backgroundColor: doxleThemeColor.doxleColor,
                  },
                  width: "50% !important",
                }}
              />
            </>
          )}

          {!isFetchingPermissions && (
            <>
              <ShareProjectSearchSection />

              <Virtuoso
                style={{ width: "100%", flex: 1 }}
                data={permissions}
                components={components}
                itemContent={itemContent}
              />
            </>
          )}
        </StyledShareProjectDialogContent>
        <EditPermissionPopoverForm />
      </StyledShareProjectDialog>
    </ShareProjectDialogContext.Provider>
  );
};

export const useShareProjectDialogContext = () =>
  useContext(ShareProjectDialogContext) as IShareProjectDialogContext;
export default ShareProjectDialog;
