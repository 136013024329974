import { StyledBudgetRootContainer } from "./BudgetStyleComponent";
import BudgetTable from "./BudgetTable";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";

import DoxleListSkeleton from "../../DoxleDesignPattern/DoxleSkeleton/DoxleListSkeleton";
import EditDocketSideScreen from "../../CoreContent/EditDocketSideScreen/EditDocketSideScreen";
import useGetBudgetList from "./Hooks/useGetBudgetList";

import DoxleEmptyPlaceHolder from "../../DoxleDesignPattern/DoxleEmptyPlaceHolder/DoxleEmptyPlaceHolder";
import BudgetHeader from "./BudgetHeader";
import { useEffect } from "react";
import { useBudgetStore } from "../Store/useBudgetStore";
import { Helmet } from "react-helmet";

type Props = {};

const Budget = (props: Props) => {
  const doxleThemeColor = useDoxleThemeStore(
    (state) => state.doxleThemeColor,
    shallow
  );
  const setIsProjectLevel = useBudgetStore(
    (state) => state.setIsBudgetProjectLevel,
    shallow
  );
  const {
    isFetchingBudgetList,
    isSuccessFetchingBudgetList,
    isErrorFetchingBudgetList,
  } = useGetBudgetList({});

  useEffect(() => {
    setIsProjectLevel(false);
  }, []);

  return (
    <StyledBudgetRootContainer $themeColor={doxleThemeColor}>
      <Helmet>
        <title>Doxle - Projects</title>
      </Helmet>
      <div className="content-wrapper">
        <BudgetHeader />
        {isErrorFetchingBudgetList && (
          <DoxleEmptyPlaceHolder
            headTitleText="Something Wrong!"
            subTitleText="Failed to get budget list..."
          />
        )}
        {isFetchingBudgetList && (
          <DoxleListSkeleton skeletonType={"budgetRow"} />
        )}
        {isSuccessFetchingBudgetList && (
          <>
            <BudgetTable />

            {/*<BudgetFooter/>*/}
          </>
        )}
        <EditDocketSideScreen />
      </div>
    </StyledBudgetRootContainer>
  );
};

export default Budget;
