import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import { AnimatePresence } from "framer-motion";
import SelectedContactItem from "./SelectedContactItem";
import ContactPopoverList from "./ContactPopoverList";
import { StyledEmailFieldWrapper } from "./StyledComponents";
import { useEmailBookingContext } from "../EmailBookings";
import useAddMailToSection from "../Hooks/useAddMailToSection";
import DoxleSelectContactDropdown from "../../../DoxleDesignPattern/DoxleSelectContactDropdown/DoxleSelectContactDropdown";

type Props = {};

const AddMailToSection = (props: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }),
    shallow
  );

  const {
    handleClickContact,
    anchorContactListPopover,
    handleCloseContactList,
  } = useAddMailToSection({});
  const {
    ballInCourt,
    setBallInCourt,
    handleRemoveBallInCourt,
    handleAddBallInCourt,
  } = useEmailBookingContext();

  return (
    <StyledEmailFieldWrapper
      $themeColor={doxleThemeColor}
      $doxleFont={doxleFont}
      layout
    >
      <span className="label-field"> To:</span>

      <div className="add-content-wrapper" onClick={handleClickContact}>
        <AnimatePresence>
          {ballInCourt.map((bic, idx) => (
            <SelectedContactItem
              item={bic}
              onRemoveItem={(item) => handleRemoveBallInCourt(item)}
              key={bic.contactId}
            />
          ))}
        </AnimatePresence>
      </div>


      <DoxleSelectContactDropdown
        popoverAnchor={anchorContactListPopover}
        onClosePopover={handleCloseContactList}
        variants="contact"
        selectedContactItemIds={
          ballInCourt ? ballInCourt.map((item) => item.contactId) : []
        }
        onSelectContact={handleAddBallInCourt}
        containerStyle={{
          borderRadius: 9,
        }}
        closeOnSelect={false}
      />
    </StyledEmailFieldWrapper>
  );
};

export default AddMailToSection;
