import { ChangeEvent, useState } from "react";
import { useDoxleCurrentContextStore } from "../../../../DoxleGeneralStore/useDoxleCurrentContext";
import { shallow } from "zustand/shallow";
import useDoxleNotificationStore from "../../../../DoxleGeneralStore/useDoxleNotificationStore";
import { useOrdersStore } from "../../../OrdersStore/useOrdersStore";
import { useEditOrderStore } from "../../../OrdersStore/useEditOrderStore";
import { OrderLine } from "../../../Models/order";
import useSetOrderDetailQueryData from "../../../Hooks/useSetOrderDetailQueryData";
import { roundUpInputValue } from "../../../../Utilities/FunctionUtilities";
import { useEditOrderContext } from "../EditOrder";

type Props = { orderLine: OrderLine; index: number };

interface IOrderLineTableRow {
  newLineDescription: string;
  newLineUnit: string;
  newLineQty: string | number;
  newItemCost: string | number;
  handleLineDescriptionChange: (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  handleLineUnitChange: (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  handleLineQtyChange: (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  handleItemCostChange: (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  handleUpdateOrderLine: (
    key: keyof Pick<OrderLine, "quantity" | "itemCost">
  ) => void;
  showDeleteIcon: boolean;
  onMouseEnterRow: () => void;
  onMouseLeaveRow: () => void;
}
const useOrderLineTableRow = ({
  orderLine,
  index,
}: Props): IOrderLineTableRow => {
  const [showDeleteIcon, setshowDeleteIcon] = useState(false);
  const { editedOrder, setEditedOrder, updateEditedOrder, updateOrderLine } =
    useEditOrderStore(
      (state) => ({
        editedOrder: state.editedOrder,
        setEditedOrder: state.setEditedOrder,
        updateEditedOrder: state.updateEditedOrder,
        updateOrderLine: state.updateOrderLine,
      }),
      shallow
    );

  const [newLineDescription, setNewLineDescription] = useState<string>(
    orderLine.description
  );
  const [newLineUnit, setNewLineUnit] = useState<string>(orderLine.unit);
  const [newLineQty, setNewLineQty] = useState<string | number>(
    roundUpInputValue(orderLine.quantity.toString(), 4)
  );
  const [newItemCost, setNewItemCost] = useState<string | number>(
    roundUpInputValue(orderLine.itemCost.toString(), 4)
  );
  const company = useDoxleCurrentContextStore(
    (state) => state.currentCompany,
    shallow
  );

  // const showNotification = useDoxleNotificationStore(
  //   (state) => state.showNotification,
  //   shallow
  // );

  const editOrderContext = useEditOrderContext();
  const { setIsOrderLineUpdated, isOrderLineUpdated } = editOrderContext
    ? editOrderContext : {setIsOrderLineUpdated: (bool: boolean)=>{}, isOrderLineUpdated: false }
  // const { queryFilter } = useOrdersStore(
  //   (state) => ({ queryFilter: state.queryFilter }),
  //   shallow
  // );
  // const { handleUpdatePartialOrder } = useSetOrderDetailQueryData({
  //   orderId: editedOrder?.orderId ?? "",
  // });

  const handleLineDescriptionChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    updateOrderLine(index, {
      description: event.target.value,
    });
    setIsOrderLineUpdated(true);
  };
  const handleLineUnitChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    updateOrderLine(index, {
      unit: event.target.value,
    });
    setIsOrderLineUpdated(true);
  };
  const handleLineQtyChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const re = /(^-$)|(^-?\d+$)|(^-?\d+\.$)|(^-?\d+\.\d+$)/;
    // if value is not blank, then test the regex

    if (event.target.value === "" || re.test(event.target.value)) {
      updateOrderLine(index, {
        quantity: event.target.value,
      });
    }
    setIsOrderLineUpdated(true);
  };

  const handleItemCostChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const re = /(^-$)|(^-?\d+$)|(^-?\d+\.$)|(^-?\d+\.\d+$)/;
    // if value is not blank, then test the regex

    if (event.target.value === "" || re.test(event.target.value)) {
      // setNewItemCost(event.target.value);
      updateOrderLine(index, {
        itemCost: event.target.value,
      });
      setIsOrderLineUpdated(true);
    }
  };

  const handleUpdateOrderLine = (
    key: keyof Pick<OrderLine, "quantity" | "itemCost">
  ) => {
    if (key === "quantity") {
      const roundUpNumber = roundUpInputValue(orderLine.quantity.toString(), 4);

      updateOrderLine(index, {
        quantity: roundUpNumber,
        lineCost: roundUpInputValue(
          (Number(orderLine.quantity) * Number(orderLine.itemCost)).toString(),
          4
        ),
      });
    }

    if (key === "itemCost") {
      const roundUpNumber = roundUpInputValue(orderLine.itemCost.toString(), 4);

      updateOrderLine(index, {
        itemCost: roundUpNumber,
        lineCost: roundUpInputValue(
          (Number(orderLine.itemCost) * Number(orderLine.quantity)).toString(),
          4
        ),
      });
    }
  };

  const onMouseEnterRow = () => {
    setshowDeleteIcon(true);
  };
  const onMouseLeaveRow = () => setshowDeleteIcon(false);

  return {
    newLineDescription,
    newLineUnit,
    newLineQty,
    newItemCost,
    handleLineDescriptionChange,
    handleLineUnitChange,
    handleLineQtyChange,
    handleItemCostChange,
    handleUpdateOrderLine,
    showDeleteIcon,
    onMouseEnterRow,
    onMouseLeaveRow,
  };
};

export default useOrderLineTableRow;
