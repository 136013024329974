import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from "react";
import {
  StyledAddBookingButton,
  StyledAddBookingConatiner,
  StyledAddBookingProjectContainer,
  StyledAddInfoWrapper,
  StyledCloseBtnContainer,
  StyledDocketInfoContainer,
} from "./StyledComponentAddBookingProject";
import { useDoxleThemeStore } from "../../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import DoxleNavigateBackButton from "../../../../DoxleDesignPattern/DoxleButtons/DoxleNavigateBackButton";
import useAddBookingProject from "./Hooks/useAddBookingProject";
import { IoChevronBackSharp } from "react-icons/io5";

import { IntRange } from "../../../../Models/IntRangeType";
import Order from "../../../../Orders/Models/order";
import { Docket } from "../../../../Models/dockets";
import AddBookingTitle from "./AddBookingTitle";
import AddBookingDocketAndDate from "./AddBookingDocketAndDate";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import AddBookingPercentage from "./AddBookingPercentage";
import BookingDocketDetails from "../BookingDocketDetail/BookingDocketDetails";
import AddBookingLoader from "../../../CommonComponents/AddBookingLoader";
import useRefComponentDimension from "../../../../CoreContent/UtilityHooks/useRefComponentDimension";
import useBookingViewStore from "../../../store/useBookingViewStore";
import EditBookingInputSection from "./AddBookingInputSection";
import AddBookingDocketPortrait from "./AddBookingPortrait";
import { EmptyDocketBanner } from "../../../CommonComponents/BookingsIcon";
import DoxleEmptyPlaceHolder from "../../../../DoxleDesignPattern/DoxleEmptyPlaceHolder/DoxleEmptyPlaceHolder";
import Dialog from "@mui/material/Dialog";
import { TransitionProps } from "@mui/material/transitions";
import Slide from "@mui/material/Slide";
import useBookingProjectStore from "../../../store/useBookingProjectStore";
import DoxleCloseBtn from "../../../../DoxleDesignPattern/DoxleButtons/DoxleCloseBtn";

type Props = {};
interface AddProjectBookingContextValue {
  newBookingTitle: string;
  setnewBookingTitle: React.Dispatch<React.SetStateAction<string>>;

  startDate: Date | null;
  setStartDate: React.Dispatch<React.SetStateAction<Date | null>>;
  endDate: Date | null;
  setEndDate: React.Dispatch<React.SetStateAction<Date | null>>;
  percentageComplete: IntRange<0, 101>;
  setPercentageComplete: React.Dispatch<React.SetStateAction<IntRange<0, 101>>>;
  selectedOrder: Order | undefined;
  setSelectedOrder: React.Dispatch<React.SetStateAction<Order | undefined>>;
  selectedDocket: Docket | undefined;
  setSelectedDocket: React.Dispatch<React.SetStateAction<Docket | undefined>>;
}

const Transition = React.forwardRef(
  (
    props: TransitionProps & {
      children: React.ReactElement;
    },
    ref: React.Ref<unknown>
  ) => {
    return <Slide direction="left" ref={ref} {...props} />;
  }
);

const AddProjectBookingContext =
  createContext<AddProjectBookingContextValue | null>(null);
const AddBookingProject = (props: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
      currentTheme: state.currentTheme,
    }),
    shallow
  );

  const {
    handleBackBtn,
    newBookingTitle,
    setnewBookingTitle,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    percentageComplete,
    setPercentageComplete,
    selectedOrder,
    setSelectedOrder,
    selectedDocket,
    setSelectedDocket,
    handleAddBooking,
    isAddDisable,
    isAddingBooking,
  } = useAddBookingProject({});

  const contextValue: AddProjectBookingContextValue = useMemo(
    () => ({
      newBookingTitle,
      setnewBookingTitle,
      startDate,
      setStartDate,
      endDate,
      setEndDate,
      percentageComplete,
      setPercentageComplete,
      selectedOrder,
      setSelectedOrder,
      selectedDocket,
      setSelectedDocket,
    }),
    [
      newBookingTitle,
      startDate,
      endDate,
      percentageComplete,
      selectedOrder,
      selectedDocket,
    ]
  );
  const BookingAddScreenRef = useRef<HTMLDivElement>(null);

  const { width, height } = useRefComponentDimension({
    componentRef: BookingAddScreenRef,
  });

  const { onAddBookingMode } = useBookingProjectStore(
    (state) => ({
      onAddBookingMode: state.onAddBookingMode,
    }),
    shallow
  );

  const { screenMode, setScreenMode } = useBookingViewStore(
    (state) => ({
      setScreenMode: state.setCurrentScreenDisplay,
      screenMode: state.currentScreenDisplay,
    }),
    shallow
  );

  useEffect(() => {
    if (width < 700) {
      setScreenMode("Portrait");
    } else {
      setScreenMode("Landscape");
    }
  }, [width]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <AddProjectBookingContext.Provider value={contextValue}>
        <Dialog
          fullScreen
          open={onAddBookingMode}
          TransitionComponent={Transition}
          PaperProps={{
            style: { backgroundColor: doxleThemeColor.primaryContainerColor },
          }}
          sx={{ "& .MuiPaper-root": { overflow: "hidden !important" } }}
        >
          <StyledCloseBtnContainer
            onClick={() => {
              handleBackBtn();
              setSelectedDocket(undefined);
            }}
          >
            <DoxleCloseBtn sx={{ width: 60, height: 60 }} />
          </StyledCloseBtnContainer>

          <StyledAddBookingProjectContainer
            ref={BookingAddScreenRef}
            $themeColor={doxleThemeColor}
            initial={{
              x: 10,
              opacity: 0,
            }}
            animate={{
              x: 0,
              opacity: 1,
            }}
          >
            <StyledAddBookingConatiner
              $displayMode={screenMode}
              ref={BookingAddScreenRef}
            >
              {/* {screenMode === "Landscape" && (
              <>
                <StyledAddInfoWrapper $displayMode={screenMode}>
                  <EditBookingInputSection />
                </StyledAddInfoWrapper>

                <StyledDocketInfoContainer $displayMode={screenMode}>
                  <BookingDocketDetails
                    docketId={selectedDocket?.docketPk}
                    mode="add"
                    selectedOrder={selectedOrder}
                    onSelectOrder={setSelectedOrder}
                  />
                </StyledDocketInfoContainer>
              </>
            )}

            {screenMode === "Portrait" && !selectedDocket && (
              <div>
                <StyledAddInfoWrapper $displayMode={screenMode}>
                  <EditBookingInputSection />
                </StyledAddInfoWrapper>
                <DoxleEmptyPlaceHolder
                  headTitleText="Opps, no docket selected"
                  subTitleText="Please select a docket to make a valid booking."
                  containerStyle={{
                    height: "fit-content",
                  }}
                  illustration={<EmptyDocketBanner {...doxleThemeColor} />}
                  containerBgColor="transparent"
                  headTitleTextStyle={{ fontSize: "3rem" }}
                  subTitleTextStyle={{ fontSize: "1.8rem" }}
                />
              </div>
            )}

            {screenMode === "Portrait" && selectedDocket && (
              <AddBookingDocketPortrait
                docketId={selectedDocket.docketPk}
                selectedOrder={selectedOrder}
                onSelectOrder={setSelectedOrder}
              />
            )} */}
              {!selectedDocket && (
                <>
                  <StyledAddInfoWrapper $displayMode={screenMode}>
                    <EditBookingInputSection />
                    <DoxleEmptyPlaceHolder
                      headTitleText="Opps, no docket selected"
                      subTitleText="Please select a docket to make a valid booking."
                      containerStyle={{
                        height: "fit-content",
                      }}
                      illustration={<EmptyDocketBanner {...doxleThemeColor} />}
                      containerBgColor="transparent"
                      headTitleTextStyle={{ fontSize: "3rem" }}
                      subTitleTextStyle={{ fontSize: "1.8rem" }}
                    />
                  </StyledAddInfoWrapper>
                </>
              )}
              {selectedDocket && (
                <AddBookingDocketPortrait
                  docketId={selectedDocket.docketPk}
                  selectedOrder={selectedOrder}
                  onSelectOrder={setSelectedOrder}
                />
              )}
            </StyledAddBookingConatiner>

            <StyledAddBookingButton
              $themeColor={doxleThemeColor}
              $doxleFont={doxleFont}
              disabled={isAddDisable}
              onClick={handleAddBooking}
            >
              Submit
            </StyledAddBookingButton>

            {isAddingBooking && <AddBookingLoader />}
          </StyledAddBookingProjectContainer>
        </Dialog>
      </AddProjectBookingContext.Provider>
    </LocalizationProvider>
  );
};
export const useAddProjectBookingContext = () =>
  useContext(AddProjectBookingContext) as AddProjectBookingContextValue;
export default AddBookingProject;
