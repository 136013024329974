import React from "react";
import {
  StylePaymentClaimTableHeaderRow,
  StyledPaymentClaimTableHeaderItems,
} from "./StyledComponentPaymentClaimList";
import { useDoxleThemeStore } from "../../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import { PAYMENT_CLAIM_TABLE_HEADER_LIST } from "../../../Models/PaymentClaim";

type Props = {};

const PaymentClaimTableHeader = (props: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }),
    shallow
  );
  return (
    <StylePaymentClaimTableHeaderRow
      $themeColor={doxleThemeColor}
      $doxleFont={doxleFont}
    >
      {PAYMENT_CLAIM_TABLE_HEADER_LIST.map((header, idx) => {
        if (header.display) return(
        <StyledPaymentClaimTableHeaderItems
          $themeColor={doxleThemeColor}
          $doxleFont={doxleFont}
          key={`header#${idx}`}
          $width={
            header.headerName !== "DESCRIPTION"
              ? header.headerName === "ICON"
                ? "5rem"
                : "12%"
              : "48%"
          }
          $horizontalAlign={
            header.headerName === "INVOICE NUMBER" ? "flex-start" : "center"
          }
        >
          <span>{header.headerName !== "ICON" ? header.headerName : ""}</span>
        </StyledPaymentClaimTableHeaderItems>
        )
      })}
    </StylePaymentClaimTableHeaderRow>
  );
};

export default PaymentClaimTableHeader;
