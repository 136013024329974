import React, { useRef, useState } from "react";
import { Note } from "../../../Models/note";
import { useNoteStore } from "../../../Store/useNoteStore";
import { shallow } from "zustand/shallow";
import { useIsMutating } from "@tanstack/react-query";
import {
  NoteQueryAPI,
  getNoteMutateKey,
} from "../../../QueryAPI/notesQueryAPI";
import { useDoxleCurrentContextStore } from "../../../../DoxleGeneralStore/useDoxleCurrentContext";

type Props = { noteItem: Note };

interface INoteRow {
  onHoverNoteRow: boolean;
  setOnHoverNoteRow: React.Dispatch<React.SetStateAction<boolean>>;
  handleClickDeleteIcon: (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => void;
  isDeleteNoteRow: boolean;
  handleClickNoteRow: () => void;
  newNoteTitle: string;
  setNewNoteTitle: React.Dispatch<React.SetStateAction<string>>;
  titleInputRef: React.RefObject<HTMLInputElement>;
  handleKeydownTitleTextField: (
    event: React.KeyboardEvent<HTMLDivElement>
  ) => void;
  handleUpdateNote: () => void;
}
const useNoteRow = ({ noteItem }: Props): INoteRow => {
  const [onHoverNoteRow, setOnHoverNoteRow] = useState(false);
  const [newNoteTitle, setNewNoteTitle] = useState(noteItem.title);
  const titleInputRef = useRef<HTMLInputElement>(null);

  const { setDeletedNote, setEdittedNote } = useNoteStore(
    (state) => ({
      setDeletedNote: state.setDeletedNote,
      setEdittedNote: state.setEdittedNote,
    }),
    shallow
  );
  const { company } = useDoxleCurrentContextStore(
    (state) => ({
      company: state.currentCompany,
    }),
    shallow
  );
  const { filterNoteQuery } = useNoteStore(
    (state) => ({
      filterNoteQuery: state.filterNoteQuery,
    }),
    shallow
  );
  const { update } = NoteQueryAPI.useMutateNoteQuery({
    company,
    filter: filterNoteQuery,
  });

  const handleClickDeleteIcon = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    e.stopPropagation();

    setDeletedNote(noteItem);
  };

  const handleClickNoteRow = () => {
    setEdittedNote(noteItem);
  };
  const isDeleteNoteRow =
    useIsMutating({
      mutationKey: getNoteMutateKey("delete"),
      predicate: (query) => Boolean(query.state.variables === noteItem.noteId),
      exact: true,
    }) > 0;

  const handleUpdateNote = () => {
    if (newNoteTitle !== noteItem.title) {
      update.mutate({
        noteId: noteItem.noteId,
        title: newNoteTitle.length > 0 ? newNoteTitle : "Untitled Note",
      });
    }
  };
  const handleKeydownTitleTextField = (
    event: React.KeyboardEvent<HTMLDivElement>
  ) => {
    if (
      event.nativeEvent.key === "Enter" &&
      (event.ctrlKey || event.metaKey || event.altKey)
    ) {
      setNewNoteTitle((prev) => prev + "\n");
      return;
    }
    if (event.nativeEvent.key === "Enter") {
      event.preventDefault();
      handleUpdateNote();
      titleInputRef.current?.blur();
    }

    if (event.nativeEvent.key === "Escape") {
      event.preventDefault();

      titleInputRef.current?.blur();
    }
  };
  return {
    onHoverNoteRow,
    setOnHoverNoteRow,
    handleClickDeleteIcon,
    isDeleteNoteRow,
    handleClickNoteRow,
    handleKeydownTitleTextField,
    handleUpdateNote,
    titleInputRef,
    newNoteTitle,
    setNewNoteTitle,
  };
};

export default useNoteRow;
