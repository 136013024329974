import React, { useEffect, useState } from "react";
import { IBooking } from "../../../../Models/booking";
import useBookingProjectStore from "../../../../store/useBookingProjectStore";
import { shallow } from "zustand/shallow";
import { IntRange } from "../../../../../Models/IntRangeType";
import { useDoxleCurrentContextStore } from "../../../../../DoxleGeneralStore/useDoxleCurrentContext";
import useDoxleNotificationStore from "../../../../../DoxleGeneralStore/useDoxleNotificationStore";
import { BookingQueryAPI } from "../../../../QueryAPI/bookingQueryAPI";
import { formatTISODate } from "../../../../../Utilities/FunctionUtilities";

type Props = {};

const useEditBookingProject = (props: Props) => {
  const { setEdittedBooking, edittedBooking, filterBookingProjectQuery } = useBookingProjectStore(
    (state) => ({
      setEdittedBooking: state.setEdittedBooking,
      edittedBooking: state.edittedBooking,
      filterBookingProjectQuery: state.filterBookingProjectQuery,
    }),
    shallow
  );
  const { company } = useDoxleCurrentContextStore(
    (state) => ({
      company: state.currentCompany,
    }),
    shallow
  );
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );

  const { update } = BookingQueryAPI.useMutateBookingQuery({
    company,
    showNotification,
    filter: filterBookingProjectQuery,
    onSuccessUpdateCb: () => {
      handleClickBackBtn();
    }
  });
 
  

  const [bookingTitle, setBookingTitle] = useState(edittedBooking?.title ?? "");
  const [startDate, setStartDate] = useState<Date | null>(
    edittedBooking?.startDate ? new Date(edittedBooking?.startDate) : null
  );
  const [endDate, setEndDate] = useState<Date | null>(
    edittedBooking?.endDate ? new Date(edittedBooking?.endDate) : null
  );
  const [percentageComplete, setPercentageComplete] = useState<
    IntRange<0, 101>
  >(edittedBooking?.percentageCompleted ?? 0);

  useEffect(() => {
      setBookingTitle(edittedBooking?.title ?? "");
      setStartDate(edittedBooking?.startDate ? new Date(edittedBooking?.startDate) : null);
      setEndDate(edittedBooking?.endDate ? new Date(edittedBooking?.endDate) : null);
      setPercentageComplete(edittedBooking?.percentageCompleted ?? 0);
  }, [edittedBooking]);

  const handleClickBackBtn = () => {
    setEdittedBooking(undefined);
  };
  const handleEditBooking = () => {
    console.log(bookingTitle, startDate, endDate, percentageComplete);
    if (edittedBooking) {
      update.mutate({
        bookingId: edittedBooking.bookingId,
        updateData: {
          startDate: startDate ? formatTISODate(startDate) : null,
          endDate: endDate ? formatTISODate(endDate) : null,
          title: bookingTitle,
          percentageCompleted: percentageComplete,
        },
      });
    }
  };
  return {
    handleClickBackBtn,
    bookingTitle,
    setBookingTitle,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    percentageComplete,
    setPercentageComplete,
    handleEditBooking,
  };
};

export default useEditBookingProject;
