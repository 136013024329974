import { motion } from "framer-motion";
import styled from "styled-components";
import { DoxleFont, DoxleThemeColor } from "../../../../DoxleGeneralStore/useDoxleThemeStore";
import { editRgbaAlpha } from "../../../../Utilities/FunctionUtilities";

export const StyledFilePickerDisplayerContainer = styled(motion.div)`
  width: calc(100% - 4rem);
  margin: 0px 2rem 0.8rem 2rem;

  align-items: center;

  display: flex;

  flex-direction: row;
  flex-wrap: wrap;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;
export const StyledFileItemContainer = styled(motion.div)`
  height: 10rem;
  aspect-ratio: 1;
  border-radius: 0.4rem;
  position: relative;
  display: inline-block;
  justify-content: center;
  align-items: center;
  margin: 0.4rem;
`;
export const StyledFileImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 4px;
`;
export const StyledEditMailResponseInputContainer = styled(motion.div)<{
  $themeColor: DoxleThemeColor;
}>`
  width: (100% - 14px);
  display: flex;

  align-items: center;
  height: 3.7rem;

  background-color: ${(p) =>
    editRgbaAlpha({ rgbaColor: p.$themeColor.doxleColor, alpha: "0.1" })};
  padding-right: 14px;
  padding-left: 2px;

  .input-wrapper {
    flex: 1;
    display: flex;

    align-items: center;
    min-height: 20px;
    align-items: center;
    max-height: 120px;
    overflow: hidden;
  }
`;

export const StyledAttachmentSection = styled.div<{
}>`
`;


export const StyledAttachmentContainer = styled.div<{
}>`
width: 100%;
display: flex;
flex-wrap: wrap;
`;

export const StyledAttachmentItem = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 5px;
  padding: 5px;
  border: 1px solid ${(p) => p.$themeColor.primaryDividerColor};
  max-width: 100px;
  border-radius: 5px;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
`;

export const StyledImageContainer = styled.div`
 width: 5rem;
 height: 5rem;
 display: flex;
 align-items: center;
 justify-content: center;
 img {
  height: 100%;
 }

`;

export const StyledFileName = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  font-weight: 500;
  font-size: 1.2rem;
  color: ${(p) => p.$themeColor.primaryFontColor};
  font-family: ${(p) => p.$doxleFont.primaryFont};
  overflow-x: hidden;
  white-space: nowrap;
  margin-left: 0.5rem;
  width: 100%;
  text-overflow: ellipsis;
  margin-top: 5px;
`;
