import {
  StyledCollapseMenuButton,
  StyledProjectMenuRoot,
  StyledProjectTabText,
} from "./StyledProjectComponents";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import { useProjectStore } from "../../DoxleGeneralStore/useprojectStore";
import { StyledProjectMenuExpandIcon } from "./ProjectIcon";
import ProjectFilter from "./ProjectFilter";
import DoxleAddNewButton from "../../DoxleDesignPattern/DoxleButtons/DoxleAddNewButton";
import { motion } from "framer-motion";
import { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import useRefComponentDimension from "../UtilityHooks/useRefComponentDimension";
import ProjectPopoverMenu from "./ProjectPopoverMenu";
import { Height } from "@mui/icons-material";
import { useShallow } from "zustand/react/shallow";

const ProjectMenu = () => {
  const {
    projectTabs,
    selectedProjectTab,
    setSelectedProjectTab,
    showFullStatus,
    setShowFullStatus,
    buttonNewFunction,
    permissions,
  } = useProjectStore(
    useShallow((state) => ({
      projectTabs: state.projectTabs,
      selectedProjectTab: state.selectedProjectTab,
      setSelectedProjectTab: state.setSelectedProjectTab,
      showFullStatus: state.showFullStatus,
      setShowFullStatus: state.setShowFullStatus,
      buttonNewFunction: state.buttonNewFunction,
      permissions: state.projectPermissions,
    }))
  );

  const styleProps = useDoxleThemeStore(
    useShallow((state) => ({
      $themeColor: state.doxleThemeColor,
      $doxleFont: state.doxleFont,
      $currentTheme: state.currentTheme,
    }))
  );
  const tabDisabled = useCallback(
    (tab: string) => {
      if (tab === "Drawings" && permissions?.viewDrawings === false)
        return true;
      if (
        tab === "Budgets" &&
        permissions?.viewEstimate === false &&
        permissions?.viewOrders === false
      )
        return true;
      if (tab === "Bookings" && permissions?.viewBookings === false)
        return true;
      if (tab === "Files" && permissions?.viewFiles === false) return true;
      return false;
    },
    [permissions]
  );

  const [menuCollapse, setMenuCollapse] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const menuContainerRef = useRef<HTMLDivElement>(null);

  const { width, height, isOverflow } = useRefComponentDimension({
    componentRef: menuContainerRef,
  });

  useEffect(() => {
    const handleResize = () => {
      if (width < 820) {
        setMenuCollapse(true);
      } else {
        setMenuCollapse(false);
      }
    };
    handleResize();

    window.addEventListener("resize", handleResize);

    // const timeout = setTimeout(() => {
    //   if (width < 730) {
    //     setMenuCollapse(true);
    //     setAnchorEl(null);
    //   } else {
    //     setMenuCollapse(false);
    //   }
    // }, 200);
    return () => {
      // clearTimeout(timeout);
      window.removeEventListener("resize", handleResize);
    };
  }, [width, height]);

  return (
    <StyledProjectMenuRoot {...styleProps} ref={menuContainerRef}>
      {menuCollapse ? (
        <>
          <StyledCollapseMenuButton
            {...styleProps}
            onClick={(e) => setAnchorEl(e.currentTarget)}
          >
            {selectedProjectTab}
            <StyledProjectMenuExpandIcon themeColor={styleProps.$themeColor} />
            <motion.div
              className="underline-effect"
              layoutId="selectedUnderlineEffect"
            />
          </StyledCollapseMenuButton>
          <ProjectPopoverMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
        </>
      ) : (
        <div className="menu-tab-section">
          {projectTabs.map((tab, idx) => (
            <StyledProjectTabText
              key={`tab#${tab}#${idx}`}
              {...styleProps}
              $selected={tab === selectedProjectTab}
              $disabled={tabDisabled(tab)}
              onClick={() => {
                if (!tabDisabled(tab)) setSelectedProjectTab(tab);
              }}
            >
              <span>{tab === "QA" ? "Checklist" : tab}</span>

              {tab === selectedProjectTab && (
                <>
                  <motion.div
                    className="underline-effect"
                    layoutId="selectedUnderlineEffect"
                  />
                </>
              )}
            </StyledProjectTabText>
          ))}
        </div>
      )}

      <div className="filter-display-section"></div>

      <div className="sub-menu-section">
        {buttonNewFunction.map((button, idx) => (
          <DoxleAddNewButton
            onClick={(e) => button.btnFunction(e)}
            disabled={permissions?.editDocket === false}
            btnIcon={button.btnIcon}
            btnText={button.btnText}
            showBtnIcon={button.showBtnIcon}
            btnBaseColor={button.btnBaseColor}
            iconOnly={true}
            key={`btn#${idx}`}
            isLoading={button.isLoading}
          />
        ))}

        {!Boolean(permissions) && <ProjectFilter />}
      </div>
    </StyledProjectMenuRoot>
  );
};
export default ProjectMenu;

const ArrowContainer = styled.div<{
  $display: boolean;
}>`
  cursor: pointer;
  position: relative;
  display: ${(p) => (p.$display ? "block" : "none")};
  top: 0.8rem;
  margin-left: 10px;
  margin-right: 10px;
`;
