import React, { useMemo, useRef } from "react";
import {
  StyledCommentTextField,
  StyledDiscussionMention,
} from "../../StyledComponentsEditDocketSideScreen";
import { shallow } from "zustand/shallow";
import {
  DoxleFont,
  DoxleThemeColor,
  useDoxleThemeStore,
} from "../../../../DoxleGeneralStore/useDoxleThemeStore";
import DoxleSendButton from "../../../../DoxleDesignPattern/DoxleButtons/DoxleSendButton";
import DoxleIconButton from "../../../../DoxleDesignPattern/DoxleButtons/DoxleIconButton";
import "./DocketInfoCSS.css";
import { Mention, SuggestionDataItem } from "react-mentions";
import { AnimatePresence } from "framer-motion";
import FilePickerDisplayer from "./FilePickerDisplayer";
import { CircularProgress } from "@mui/material";
import { useDropzone } from "react-dropzone";
import { Discussion } from "../../../../Models/discussion";
import useGetContactList from "./Hooks/useGetContactList";
import useEditDocketCommentInputSection from "./Hooks/useEditDocketCommentInputSection";
import { StyledEditDocketCommentInputSectionContainer } from "./StyledComponentDocketInfo";
import { VirtuosoHandle } from "react-virtuoso";
import useAutosizeInput from "./Hooks/useAutosizeInput";
import { DOXLE_ACCEPTED_MIME } from "../../../../Utilities/MimeFileType";
import { DoxleAttachmentIcon } from "../../../../DoxleDesignPattern/DoxleGeneralIcons";
import CommentAttachmentPopover from "./CommentAttachmentPopover";
type Props = {
  commentListRef: React.RefObject<VirtuosoHandle>;
  discussionList: Discussion[];
};

const EditDocketCommentInputSection = ({
  commentListRef,
  discussionList,
}: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore((state) => ({
    doxleThemeColor: state.doxleThemeColor,
    doxleFont: state.doxleFont,
  }));
  const { contactList, isFetchingContact } = useGetContactList({});
  const {
    onDrop,
    addedFiles,
    setAddedFiles,
    handleClickSendButton,
    commentText,
    setCommentText,
    taggedUsers,
    setTaggedUsers,
    isAddingDiscussion,
    onKeyDown,
    anchorAttachmentBtn,
    setAnchorAttachmentBtn,
    onDropVideo,
  } = useEditDocketCommentInputSection({ commentListRef, discussionList });

  const { getRootProps } = useDropzone({
    onDrop,
    accept: { ...DOXLE_ACCEPTED_MIME, "video/*": [".mp4", ".mpeg", ".avi"] },
    maxSize: 15000000,
  });

  const inputRef = useRef<HTMLInputElement>(null);
  useAutosizeInput(inputRef.current, commentText);
  const suggestionRef = useRef<HTMLDivElement>(null);
  //###########################################
  const nameOptions: SuggestionDataItem[] = useMemo(
    () =>
      contactList.map(
        (contact) =>
          ({
            id: contact.user,
            display: `${contact.firstName} ${contact.lastName}`,
          } as SuggestionDataItem)
      ),
    [contactList]
  );

  //* Animation
  const mentionAnimatedVariants = {
    entering: {
      y: [10, 0],
      opacity: [0, 1],
      transition: {
        duration: 0.2,
      },
    },
    exiting: {
      y: [0, 5],
      opacity: [1, 0],
      transition: {
        duration: 0.2,
      },
    },
  };
  return (
    <div style={{ display: "flex", width: "100%", flexDirection: "column" }}>
      <AnimatePresence>
        {addedFiles.length > 0 && (
          <FilePickerDisplayer
            addedFiles={addedFiles}
            setAddedFiles={setAddedFiles}
            onDrop={onDrop}
          />
        )}
      </AnimatePresence>

      <StyledEditDocketCommentInputSectionContainer
        layout="position"
        transition={{
          duration: 0.2,
          damping: 16,
        }}
        ref={suggestionRef}
      >
        <div className="input-wrapper">
          <StyledCommentTextField
            style={{
              ...mentionInputStyle(
                doxleThemeColor,
                doxleFont,
                Boolean(!commentText)
              ),
            }}
            value={commentText}
            placeholder="Add Comment"
            onChange={(event: any) => {
              setCommentText(event.target.value);
            }}
            onKeyDown={onKeyDown}
            customSuggestionsContainer={(children: React.ReactNode) => (
              <StyledDiscussionMention
                $themeColor={doxleThemeColor}
                variants={mentionAnimatedVariants}
                initial={false}
                animate="entering"
                exit="exiting"
              >
                {children}
              </StyledDiscussionMention>
            )}
            singleLine={false}
            forceSuggestionsAboveCursor={true}
            inputRef={inputRef}
            suggestionsPortalHost={suggestionRef.current ?? undefined}
            children={
              <Mention
                onAdd={(id, display) => {
                  setTaggedUsers([...taggedUsers, id as string]);
                }}
                markup={`#TaggedUser@[__display__]`}
                displayTransform={(id, display) => `@${display}`}
                data={nameOptions}
                trigger="@"
                renderSuggestion={(data) => (
                  <div key={data.id}>{data.display}</div>
                )}
              />
            }
          />
        </div>
        {isAddingDiscussion && (
          <CircularProgress
            sx={{ color: doxleThemeColor.doxleColor, marginInline: 2 }}
            size={14}
          />
        )}
        <DoxleIconButton
          onClick={(e) => {
            setAnchorAttachmentBtn(e.currentTarget);
          }}
          iconSource={
            <DoxleAttachmentIcon
              themeColor={doxleThemeColor}
              isFocused={true}
            />
          }
          buttonSize={25}
        />

        <DoxleSendButton
          disabled={Boolean(
            (!commentText && addedFiles.length === 0) || isAddingDiscussion
          )}
          sx={{ marginTop: "2px", marginLeft: "4px" }}
          onClick={handleClickSendButton}
        />
      </StyledEditDocketCommentInputSectionContainer>

      <CommentAttachmentPopover
        anchorPopover={anchorAttachmentBtn}
        handleClosePopover={() => {
          setAnchorAttachmentBtn(null);
        }}
        onDropFileImages={onDrop}
        onDropVideo={onDropVideo}
      />
    </div>
  );
};

export default EditDocketCommentInputSection;

const mentionInputStyle = (
  themeColor: DoxleThemeColor,
  doxleFont: DoxleFont,
  isNull: boolean
) => ({
  width: "100%",
  display: "flex",
  alignItems: "center",
  zIndex: 0,

  control: {
    display: "flex",
    maxWidth: "100%",
  },
  "&singleLine": {
    display: "flex",
    width: "100%",
    alignItems: "center",
    minHeight: 30,
    highlighter: {
      border: "0px !important",
      display: "none",
    },

    input: {
      fontFamily: doxleFont.primaryFont,
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "14px",

      color: isNull ? themeColor.doxleColor : themeColor.primaryFontColor,
      border: "0px",
      outline: "none",

      caretColor: themeColor.primaryFontColor,
      maxWidth: "90% !important",
      bottom: 0,
    },
  },
  "&multiLine": {
    display: "flex",
    width: "calc(100%)",

    alignItems: "center",
    minHeight: 20,
    height: "auto",
    backgroundColor: "transparent",
    border: "none !important",
    borderColor: "transparent !important",
    highlighter: {
      maxWidth: "100%",
      display: "block",
      backgroundColor: "transparent",
      border: "1px solid transparent",
      borderColor: "transparent !important",
      minHeight: 20,
      maxHeight: 144,
      height: "fit-content",
    },
    input: {
      fontFamily: doxleFont.primaryFont,
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "14px",

      color: themeColor.primaryFontColor,

      caretColor: themeColor.primaryFontColor,

      maxWidth: "100% !important",
      "*:focus": {
        outline: "none",
      },
    },
  },
  suggestions: {
    backgroundColor: themeColor.primaryContainerColor,
    border: `1px solid ${themeColor.primaryDividerColor}`,
    list: {
      fontFamily: doxleFont.secondaryFont,
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "14px",

      color: themeColor.primaryFontColor,
    },
    item: {
      height: 20,
      padding: "5px 14px",
      display: "flex",
      alignItems: "center",
      borderBottom: `1px solid ${themeColor.primaryDividerColor}`,
      color: themeColor.primaryFontColor,
      "&focused": {
        backgroundColor: themeColor.doxleColor,
        color: themeColor.primaryReverseFontColor,
        transition: "0.4s",
      },
    },
  },
});
