import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { motion } from "framer-motion";
import styled from "styled-components";
import {
  DoxleFont,
  DoxleTheme,
  DoxleThemeColor,
} from "../../../../DoxleGeneralStore/useDoxleThemeStore";
import TableCell from "@mui/material/TableCell";
import Typography from "@mui/material/Typography";
import { editRgbaAlpha } from "../../../../Utilities/FunctionUtilities";

export const StyledBookingTableContainer = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  position: relative;
`;
export const StyledBookingTable = styled(Table)`
  && {
    width: 100% !important;

    display: flex;
    flex-direction: column;
  }
`;
export const StyledBookingListScroller = styled.div`
  width: 100%;
  height: 100%;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;
export const StyledBookingTableHead = styled(TableHead)`
  && {
    width: 100% !important;
    z-index: 1000;
  }
`;
export const StyledBookingTableBody = styled(TableBody)`
  && {
    width: 100% !important;
    min-width: 20rem !important;
    flex: 1;
    display: flex !important;
    flex-direction: column;
  }
`;
export const StyledBookingTableRow = styled(motion(TableRow))`
  && {
    width: 100% !important;
    height: 40px;
    display: flex;
    flex-direction: row !important;
    align-items: center;
    overflow: hidden;
  }
`;
export const StyledBookingTableHeaderContainer = styled(TableRow)<{
  $themeColor: DoxleThemeColor;
}>`
  && {
    width: 100% !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: ${(p) => p.$themeColor.primaryBackgroundColor};
    height: 4.4rem;
  }
`;
export const StyledBookingTableHeaderCell = styled(TableCell)<{
  $widthRatio: `${number}%` | null;
  $horizontalAlign?: "flex-start" | "center" | "flex-end";
  $padding?: string;
  $themeColor: DoxleThemeColor;
}>`
  && {
    ${(p) => (p.$widthRatio ? `width: ${p.$widthRatio};` : "flex:1;")}
    display: flex;
    height: 100%;
    padding: 0px !important;
    justify-content: ${(p) =>
      p.$horizontalAlign ? p.$horizontalAlign : "center"};
    align-items: center;
    cursor: pointer;
    color: ${(p) => p.$themeColor.primaryFontColor};
    text-transform: capitalize;
    ${(p) => (p.$padding ? `padding: ${p.$padding}` : "")};

    border: none !important;
  }
`;
export const StyledBookingHeaderText = styled(Typography)<{}>`
  && {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.028rem;
    font-family: ${(p) => p.theme.font.primaryFont};
    color: ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.theme.color.primaryFontColor,
        alpha: "0.6",
      })};
  }
`;
export const StyledBookingTableRowContainer = styled(motion(TableCell))<{}>`
  && {
    width: calc(100% - 2px) !important;
    display: flex;
    flex-direction: row;
    padding: 4px 0rem;

    height: calc(100% - 8px);
    border-bottom: 1px solid ${(p) => p.theme.color.primaryDividerColor};

    cursor: pointer;
    position: relative;
    &:hover {
      background-color: ${(p) => p.theme.color.rowHoverColor};
      transition: 0.4s;
    }
    background-color: ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.theme.color.primaryContainerColor,
        alpha: p.theme.transparentMode
          ? "0"
          : p.theme.currentTheme === "light"
          ? "0.3"
          : "0.5",
      })};
  }
`;
export const StyledBookingNumberContainer = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  padding: 0px 4px;
  position: relative;

  .docket-number-text {
    color: ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.$themeColor.primaryFontColor,
        alpha: "0.7",
      })};

    font-family: ${(p) => p.$doxleFont.plexSanCondensed};
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;

    letter-spacing: -0.064rem;
    position: absolute;
    bottom: -0.04rem;
    left: calc(8px - 0.8rem);
    z-index: 10;
    background-color: ${(p) => p.$themeColor.primaryBackgroundColor};
  }
`;
export const StyleBookingTableDataCell = styled.div<{
  $widthRatio: `${number}%` | null;

  $horizontalAlign?: "flex-start" | "center" | "flex-end";
}>`
  display: flex;
  height: 100%;
  justify-content: ${(p) =>
    p.$horizontalAlign ? p.$horizontalAlign : "center"};
  padding: 0px max(1rem, 10px);
  align-items: center;
  font-family: ${(p) => p.theme.font.primaryFont};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  color: ${(p) => p.theme.color.primaryFontColor};
  text-transform: capitalize;

  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  ${(p) => p.$widthRatio && `width: calc(${p.$widthRatio} - max(2rem, 20px));`}

  ${(p) => !p.$widthRatio && "flex:1;"}
  .title-text-content {
    color: ${(p) => p.theme.color.primaryFontColor};
    font-family: ${(p) => p.theme.font.secondaryTitleFont};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    flex-shrink: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .date-text-content {
    color: ${(p) => p.theme.color.primaryFontColor};
    font-family: ${(p) => p.theme.font.primaryFont};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    flex-shrink: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .date-period-content {
    color: ${(p) => p.theme.color.doxleColor};
    font-family: ${(p) => p.theme.font.secondaryTitleFont};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;

    flex-shrink: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .date-period-content-overdue {
    color: ${(p) => p.theme.color.errorColor};
    font-family: ${(p) => p.theme.font.secondaryTitleFont};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    flex-shrink: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .delete-img-holder {
    width: 1.6rem !important;
    padding-right: 8px;
  }
`;
export const StyledBookingPercentageContainer = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  .percentage-text {
    font-family: ${(p) => p.$doxleFont.secondaryTitleFont};
    font-style: normal;
    font-weight: 500;
    font-size: 1.2rem;

    color: ${(p) => p.$themeColor.primaryFontColor};
    text-align: right;
    margin-right: 4px;
    width: 4rem;
  }
`;

export const StyledBookingPercentageCell = styled.div<{
  $fillColor?: string;
  $themeColor: DoxleThemeColor;
}>`
  width: 1.4rem;
  height: 1.4rem;
  border-radius: 0.14rem;
  margin: 0px 1px;
  background-color: ${(p) =>
    p.$fillColor ??
    editRgbaAlpha({
      rgbaColor: p.$themeColor.doxleColor,
      alpha: "0.3",
    })};
`;
