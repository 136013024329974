import React, { useCallback } from "react";

import DoxleIconButton from "../../../../DoxleDesignPattern/DoxleButtons/DoxleIconButton";
import { useDoxleThemeStore } from "../../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import { AnimatePresence } from "framer-motion";
import { AddedDiscussionFile } from "../../../../Services/QueryHooks/discussionsAPI";
import { produce } from "immer";
import { AiFillCloseCircle } from "react-icons/ai";
import {
  StyledFilePickerDisplayerContainer,
  StyledFileImage,
  StyledFileItemContainer,
  StyledCommentAttachmentDropzone,
} from "./StyledComponentDocketInfo";
import {
  getFileIconUrl,
  isImageFile,
} from "../../../../Utilities/FunctionUtilities";
import "./ScrollbarCSS.css";
import { DropEvent, FileRejection, useDropzone } from "react-dropzone";
import { DOXLE_ACCEPTED_MIME } from "../../../../Utilities/MimeFileType";
import { AttachmentUploadIcon } from "../../EditDocketSideScreenIcons";
type Props = {
  addedFiles: AddedDiscussionFile[];
  setAddedFiles: React.Dispatch<React.SetStateAction<AddedDiscussionFile[]>>;
  onDrop: <T extends File>(
    acceptedFiles: T[],
    fileRejections: FileRejection[],
    event: DropEvent
  ) => void;
};

const FilePickerDisplayer = ({ addedFiles, setAddedFiles, onDrop }: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }),
    shallow
  );
  const handleRemoveFile = useCallback(
    (index: number) => {
      setAddedFiles((prev) =>
        produce(prev, (draft) => {
          draft = draft.filter((_, idx) => idx !== index);
          return draft;
        })
      );
    },
    [setAddedFiles]
  );

  const { getRootProps } = useDropzone({
    onDrop,
    accept: DOXLE_ACCEPTED_MIME,
  });
  return (
    <StyledFilePickerDisplayerContainer>
      <AnimatePresence>
        {addedFiles.map((file, index) => (
          <FileItem
            file={file}
            fileIndex={index}
            handleRemoveFile={handleRemoveFile}
            key={`addedFile#${file.uri}#${file.name}`}
          />
        ))}
      </AnimatePresence>

      <StyledCommentAttachmentDropzone
        {...getRootProps({
          $themeColor: doxleThemeColor,
          $doxleFont: doxleFont,
        })}
        layout="position"
        transition={{
          duration: 0.1,
          damping: 16,
        }}
      >
        <AttachmentUploadIcon
          themeColor={doxleThemeColor}
          containerStyle={{
            width: "40%",
            marginBottom: 14,
          }}
        />
        Click to add more attachments...
      </StyledCommentAttachmentDropzone>
    </StyledFilePickerDisplayerContainer>
  );
};

export default FilePickerDisplayer;

const FileItem = React.memo(
  (props: {
    file: AddedDiscussionFile;
    handleRemoveFile: (index: number) => void;
    fileIndex: number;
  }) => {
    const { file, handleRemoveFile, fileIndex } = props;
    const { doxleThemeColor } = useDoxleThemeStore(
      (state) => ({
        doxleThemeColor: state.doxleThemeColor,
        doxleFont: state.doxleFont,
      }),
      shallow
    );

    return (
      <StyledFileItemContainer
        layout="position"
        initial={false}
        animate={{ y: [10, 0], opacity: [0, 1] }}
        exit={{
          y: [0, 10],
          opacity: [1, 0],
        }}
        transition={{
          duration: 0.1,
          damping: 16,
        }}
        style={
          !isImageFile(file.name)
            ? {
                border: `1px solid ${doxleThemeColor.rowBorderColor}`,
                display: "flex",
                backgroundColor: doxleThemeColor.primaryContainerColor,
              }
            : undefined
        }
      >
        <DoxleIconButton
          iconSource={
            <AiFillCloseCircle
              size={"2rem"}
              color={doxleThemeColor.primaryFontColor}
            />
          }
          buttonSize={30}
          disableAnimation
          onClick={() => handleRemoveFile(fileIndex)}
          buttonWrapperStyle={{
            position: "absolute",
            zIndex: 10,
            top: "-15px",
            right: "-5px",
          }}
        />
        <StyledFileImage
          src={isImageFile(file.name) ? file.uri : getFileIconUrl(file.name)}
          style={{ height: isImageFile(file.name) ? "100%" : "auto" }}
        />
      </StyledFileItemContainer>
    );
  }
);
