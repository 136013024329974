import FileHoverMenu from "./FileHoverMenu";
import {
  StyledFileFolderName,
  StyledFileFolderTableRightDiv,
  StyledFileFolderTableRowSectionCell,
  StyledFolderTreeLine,
  StyledImageDiv,
} from "./StyledFilesBodyComponent";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import React, { useEffect, useMemo, useState } from "react";
import { DoxleFile } from "../../Models/files";
import { AnimatePresence } from "framer-motion";
import {
  DOXLE_MIME_TYPE,
  checkSupportedFileType,
} from "../../Utilities/MimeFileType";
import {
  DoxleCSVIcon,
  DoxleExcelIcon,
  DoxlePDFIcon,
  DoxleWordIcon,
} from "../../DoxleDesignPattern/DoxleGeneralIcons";
import { DoxleTextIcon } from "../../CoreContent/DoxleTopMenu/DoxleTopMenuIcon";
import ListNewFolderPlaceholder from "../Components/ListNewFolderPlaceholder";
import useFilesRowLeft from "../Hooks/useFilesRowLeft";
import CircularProgress from "@mui/material/CircularProgress";
import { IoVideocamOutline } from "react-icons/io5";

type Props = {
  fileItem: DoxleFile;
  lastRow?: boolean;
};
const FilesRowLeft = ({ fileItem, lastRow }: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore((state) => ({
    doxleThemeColor: state.doxleThemeColor,
    doxleFont: state.doxleFont,
  }));
  const {
    onFileRowHover,
    setOnFileRowHover,
    openFile,
    supportedFileType,
    isDeletingFile,
  } = useFilesRowLeft({ fileItem });

  return (
    <>
      <StyledFileFolderTableRowSectionCell
        $halfBorder={false}
        $selected={onFileRowHover}
        onMouseEnter={() => {
          setOnFileRowHover(true);
        }}
        onMouseLeave={() => {
          setOnFileRowHover(false);
        }}
        exit={{
          scaleX: 0,
          transition: {
            duration: 0.2,
          },
        }}
      >
        <StyledFolderTreeLine />
        <div className="row-content-wrapper">
          <StyledImageDiv>
            {supportedFileType === "pdf" ? (
              <DoxlePDFIcon
                themeColor={doxleThemeColor}
                containerStyle={{
                  width: 25,
                }}
              />
            ) : supportedFileType === "doc" ? (
              <DoxleWordIcon
                themeColor={doxleThemeColor}
                containerStyle={{
                  width: 25,
                }}
              />
            ) : supportedFileType === "xls" ? (
              <DoxleExcelIcon
                themeColor={doxleThemeColor}
                containerStyle={{
                  width: 25,
                }}
              />
            ) : supportedFileType === "csv" ? (
              <DoxleCSVIcon
                themeColor={doxleThemeColor}
                containerStyle={{
                  width: 25,
                }}
              />
            ) : supportedFileType === "text" ? (
              <DoxleTextIcon
                themeColor={doxleThemeColor}
                containerStyle={{
                  width: 25,
                }}
              />
            ) : supportedFileType === "video" ? (
              <IoVideocamOutline
                color={doxleThemeColor.primaryFontColor}
                size={25}
              />
            ) : (
              supportedFileType === "image" && (
                <img className="image-holder" src={fileItem.thumbUrl} />
              )
            )}
          </StyledImageDiv>
          <StyledFileFolderName onClick={openFile}>
            {fileItem.fileName}
          </StyledFileFolderName>
          <AnimatePresence>
            {isDeletingFile ? (
              <CircularProgress
                color="error"
                size={14}
                style={{ marginLeft: 8 }}
              />
            ) : (
              onFileRowHover && (
                <StyledFileFolderTableRightDiv
                  initial={{ x: 10, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  exit={{ x: 10, opacity: 0 }}
                >
                  <FileHoverMenu fileItem={fileItem} />
                </StyledFileFolderTableRightDiv>
              )
            )}
          </AnimatePresence>
        </div>
      </StyledFileFolderTableRowSectionCell>
      {lastRow && <ListNewFolderPlaceholder />}
    </>
  );
};

export default FilesRowLeft;
