import {
  StyledContextDropDownContainer,
  StyledDropDownItem,
} from "./StyledComponents";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import { MouseEvent } from "react";
import { ClickAwayListener } from "@mui/material";
import { useContactsStore } from "./Store/useContactsStore";
import { useShallow } from "zustand/react/shallow";
interface Props {
  buttonBounding: DOMRect | null;
  handleClose: () => void;
  context?: "Project" | "DocketFullScreen" | "DocketHalfScreen";
}
const ContextPageNewDropDown = ({
  buttonBounding,
  handleClose,
  context,
}: Props) => {
  const styleProps = useDoxleThemeStore(
    useShallow((state) => ({
      $themeColor: state.doxleThemeColor,
      $doxleFont: state.doxleFont,
      $currentTheme: state.currentTheme,
    }))
  );
  const { view, setView, setAddDialogType } = useContactsStore(
    useShallow((state) => ({
      view: state.view,
      setView: state.setView,
      setAddDialogType: state.setAddDialogType,
    }))
  );
  const top: number = buttonBounding?.bottom ?? 0;
  const right: number = buttonBounding?.right ?? 0;
  const dropDownItems: {
    text: string;
    function: (e: MouseEvent<HTMLDivElement>) => void;
  }[] = [
    {
      text: view === "Contacts" ? "Switch to Companies" : "Switch to Contacts",
      function: (e: MouseEvent<HTMLDivElement>) =>
        setView(view === "Contacts" ? "Companies" : "Contacts"),
    },
    {
      text: "Add Contact",
      function: (e: MouseEvent<HTMLDivElement>) => {
        setAddDialogType("Contact");
        handleClose();
      },
    },
    {
      text: "Add Company",
      function: (e: MouseEvent<HTMLDivElement>) => {
        setAddDialogType("ContactCompany");
        handleClose();
      },
    },
    {
      text: "Sync From Xero",
      function: (e: MouseEvent<HTMLDivElement>) => {
        setAddDialogType("Xero");
        handleClose();
      },
    },
  ];
  return (
    <ClickAwayListener onClickAway={handleClose}>
      <StyledContextDropDownContainer
        $top={top}
        $right={right}
        $context={context}
      >
        {dropDownItems.map((dropDownItem) => (
          <StyledDropDownItem onClick={dropDownItem.function} {...styleProps}>
            {dropDownItem.text}
          </StyledDropDownItem>
        ))}
      </StyledContextDropDownContainer>
    </ClickAwayListener>
  );
};
export default ContextPageNewDropDown;
