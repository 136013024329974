import React, { useEffect, useMemo, useRef } from "react";
import { useDoxleGridLayoutStore } from "./Store/useDoxleGridLayoutStore";
import { shallow } from "zustand/shallow";
import { ElementRenderSize } from "./Hooks/useMeasureItemContent";
import { produce } from "immer";

type Props<TItem> = {
  data: TItem;
  itemIndex: number;
  renderItem: (item: TItem, index: number) => JSX.Element;
  setItemSize: React.Dispatch<React.SetStateAction<ElementRenderSize<TItem>[]>>;
};

function VirtualMeasureElement<TItem>({
  data,
  renderItem,
  setItemSize,
  itemIndex,
}: Props<TItem>) {
  const { rootContainerSize, extractItemKeyProp } = useDoxleGridLayoutStore(
    (state) => ({
      rootContainerSize: state.rootContainerSize,
      extractItemKeyProp: state.extractItemKeyProp,
    }),
    shallow
  );
  const columnItemStyle: React.CSSProperties = useMemo(
    () => ({
      width: rootContainerSize.numOfCol
        ? rootContainerSize.widthSize / rootContainerSize.numOfCol
        : 0,
      wordBreak: "break-word",
      overflow: "hidden",
      display: "flex",
      position: "absolute",
      visibility: "hidden",
      zIndex: -1,
    }),
    [rootContainerSize.widthSize, rootContainerSize.numOfCol]
  );
  const columnRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (columnRef.current) {
      setItemSize((prev) =>
        produce(prev, (draft: ElementRenderSize<TItem>[]) => {
          const matchedItemIndex: number = draft.findIndex(
            (item) =>
              item.item[extractItemKeyProp as keyof TItem] &&
              data[extractItemKeyProp as keyof TItem] &&
              item.item[extractItemKeyProp as keyof TItem] ===
                data[extractItemKeyProp as keyof TItem]
          );
          if (matchedItemIndex === -1)
            draft.push({
              width: columnRef.current!.offsetWidth,
              height: columnRef.current!.offsetHeight,
              item: data,
            });
          else {
            draft[matchedItemIndex] = {
              width: columnRef.current!.offsetWidth,
              height: columnRef.current!.offsetHeight,
              item: data,
            };
          }
        })
      );
    }
  }, [data, rootContainerSize.widthSize, rootContainerSize.numOfCol]);

  return (
    <div style={columnItemStyle} ref={columnRef}>
      {renderItem(data, itemIndex)}
    </div>
  );
}

export default VirtualMeasureElement;
