import React from "react";
import {
  StyledAddBookingLabelText,
  StyledAddBookingPercentageContainer,
  StyledBookingPercentageText,
} from "./StyledComponentAddBookingProject";
import { useDoxleThemeStore } from "../../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import BookingPercentageSlider from "../../../CommonComponents/BookingPercentageSlider";
import { useAddProjectBookingContext } from "./AddBookingProject";

type Props = {};

const AddBookingPercentage = (props: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
      currentTheme: state.currentTheme,
    }),
    shallow
  );
  const { percentageComplete, setPercentageComplete } =
    useAddProjectBookingContext();
  return (
    <StyledAddBookingPercentageContainer>
      <StyledAddBookingLabelText
        $themeColor={doxleThemeColor}
        $doxleFont={doxleFont}
      >
        Percent Complete
      </StyledAddBookingLabelText>

      <BookingPercentageSlider
        value={percentageComplete}
        onChangeValue={(value) => setPercentageComplete(value)}
      />

      <StyledBookingPercentageText
        $themeColor={doxleThemeColor}
        $doxleFont={doxleFont}
      >
        {percentageComplete}%
      </StyledBookingPercentageText>
    </StyledAddBookingPercentageContainer>
  );
};

export default AddBookingPercentage;
