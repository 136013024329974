import React, { useState } from "react";
import {
  DoxleThemeColor,
  useDoxleThemeStore,
} from "../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import {
  StyledAddProjectDatePicker,
  StyledAddProjectDatePickerContainer,
  StyledAddProjectDateTextField,
} from "./StyledComponentAddProjectScreen";
import { motion } from "framer-motion";
import dayjs, { Dayjs } from "dayjs";
import { editRgbaAlpha } from "../../Utilities/FunctionUtilities";
import {
  DateTimePicker,
  DesktopDatePicker,
  DesktopDatePickerSlotsComponentsProps,
  LocalizationProvider,
  MobileDatePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TextFieldProps } from "@mui/material";
import { DatePickerIcon } from "./AddProjectIcon";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import updateLocale from "dayjs/plugin/updateLocale";
import { customDatePickerStyle } from "../../Models/modelHelper";

type Props = {
  type: "end" | "start";
  onPickDate: (date: Dayjs | null) => void;
  dateValue: Dayjs | null;
};

const AddProjectDatePicker = ({ type, onPickDate, dateValue }: Props) => {
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }),
    shallow
  );
  const labelAnimatedVariants = {
    initial: {
      color: editRgbaAlpha({
        rgbaColor: doxleThemeColor.primaryFontColor,
        alpha: "0.4",
      }),
    },
    focused: {
      color: doxleThemeColor.primaryFontColor,
    },
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <StyledAddProjectDatePickerContainer
        $doxleFont={doxleFont}
        $themeColor={doxleThemeColor}
      >
        <motion.span
          className="textfield-label"
          variants={labelAnimatedVariants}
          initial="initial"
          animate={isInputFocused ? "focused" : "initial"}
          transition={{
            duration: 0.4,
          }}
        >
          {type === "end" ? "Finish Date" : "Start Date"}
        </motion.span>

        <DesktopDatePicker
          open={openDatePicker}
          onOpen={() => setOpenDatePicker(true)}
          onClose={() => setOpenDatePicker(false)}
          format="DD/MM/YYYY"
          value={dateValue}
          slotProps={customDatePickerStyle<Dayjs>(doxleThemeColor)}
          sx={{
            backgroundColor: doxleThemeColor.primaryContainerColor,
            width: "100%",
          }}
          slots={{
            textField: (params: TextFieldProps) => (
              <StyledAddProjectDateTextField
                {...params}
                $themeColor={doxleThemeColor}
                $doxleFont={doxleFont}
                onFocus={() => setIsInputFocused(true)}
                onBlur={() => setIsInputFocused(false)}
              />
            ),

            openPickerIcon: (params: any) => (
              <CalendarMonthOutlinedIcon
                {...params}
                style={{
                  fontSize: "2rem",
                  color: editRgbaAlpha({
                    rgbaColor: doxleThemeColor.primaryFontColor,
                    alpha: "0.2",
                  }),
                }}
              />
            ),
          }}
          onChange={onPickDate}
        />
      </StyledAddProjectDatePickerContainer>
    </LocalizationProvider>
  );
};

export default AddProjectDatePicker;
