import { useDoxleAuthStore } from "../../../DoxleGeneralStore/useDoxleAuthStore";
import { shallow } from "zustand/shallow";
import useDoxleNotificationStore from "../../../DoxleGeneralStore/useDoxleNotificationStore";
import CookieService from "../../../Services/cookieService";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
  KeyboardEvent,
} from "react";
import DoxleAPI from "../../../Services/DoxleAPI";
import {
  SetURLSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { LoginDetails } from "../../../Services/QueryHooks/authQueryAPI";

interface ILoginScreen {
  isLoading: boolean;
  email: string;
  handleEmailLinkBtn: (email: string) => Promise<void>;
  handleResetPassword: (email: string) => Promise<void>;
  prompt: string[];
  password: string;
  loginMode: "email" | "link" | "reset";
  setEmail: Dispatch<SetStateAction<string>>;
  setPassword: Dispatch<SetStateAction<string>>;
  setLoginMode: Dispatch<SetStateAction<"link" | "email" | "reset">>;
  handleSubmit: () => void;
  handleKeyDown: (e: KeyboardEvent<HTMLInputElement>) => void;
  handleLoginWithPassword: ({ user, password }: LoginDetails) => Promise<void>;
}

const emailRegex = new RegExp(
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);

const useLoginScreen = (): ILoginScreen => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [prompt, setPrompt] = useState<string[]>(["", ""]);

  const [loginMode, setLoginMode] = useState<"link" | "email" | "reset">(
    "email"
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { loginWithDetails, sendEmailLink, loginWithLink } = useDoxleAuthStore(
    (state) => ({
      loginWithDetails: state.loginWithDetails,
      sendEmailLink: state.sendEmailLink,
      loginWithLink: state.loginWithLink,
    }),
    shallow
  );
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );
  const [URLSearchParams, SetURLSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const handleCheckAuthAndRedirect = async () => {
    // Check if returning from magic link
    let oobCode: string = "";
    let apiKey: string = "";
    window.location.href
      .substring(window.location.href.indexOf("?") + 1)
      .split("&")
      .forEach((str: string) => {
        if (str.startsWith("apiKey=")) apiKey = str.substring(7);
        else if (str.startsWith("oobCode=")) oobCode = str.substring(8);
      });
    if (oobCode && apiKey) {
      const response = await loginWithLink(apiKey, oobCode);
      if (response.type === "success") {
        const href: string = window.location.href;
        if (href.includes("doxle")) {
          const appParam = URLSearchParams.get("app") ?? "";
          const pathParam = URLSearchParams.get("pathParam") ?? "/";
          if (window.location.host.includes("doxle")) {
            if (
              [
                "noticeboard",
                "projects",
                "bills",
                "inventory",
                "draw",
              ].includes(appParam)
            )
              window.open(`https://${appParam}.doxle.com${pathParam}`, "_self");
            else window.open(`https://projects.doxle.com${pathParam}`, "_self");
          }
        }
      }
      //   window.open(window.location.protocol + "//" + window.location.host + "/Inbox/", "_self");
    }
  };
  interface LoginDetails {
    user: string;
    password: string;
  }
  const handleLoginWithPassword = async ({ user, password }: LoginDetails) => {
    setIsLoading(true);
    const result = await loginWithDetails({ user, password });
    // debugger;
    console.log("RESULT LOGIN:", result);
    setIsLoading(false);
    if (result.type === "success") {
      const href: string = window.location.href;
      const appParam = URLSearchParams.get("app") ?? "";
      const pathParam = URLSearchParams.get("pathParam") ?? "/";
      if (href.includes("doxle")) {
        if (window.location.host.includes("doxle")) {
          if (
            ["noticeboard", "projects", "bills", "inventory", "draw"].includes(
              appParam
            )
          )
            window.open(`https://${appParam}.doxle.com${pathParam}`, "_self");
          else window.open(`https://projects.doxle.com${pathParam}`, "_self");
        }
      }
      navigate("/");
    } else {
      showNotification(
        "Login failed, please check your email or password!",
        "error",
        "Incorrect email or password, please try again!",
        3000
      );
    }
  };

  const handleEmailLinkBtn = async (email: string) => {
    setIsLoading(true);
    try {
      const response = await sendEmailLink(email);
      if (response.type === "success") navigate("/responseLinkLogin/");
      else
        showNotification(
          "Failed To Send Magic Link",
          "error",
          "Please Try Again",
          1500
        );
    } catch (error) {
      showNotification(
        "SOMETHING WRONG!!!",
        "error",
        "Unable To Login, Please Try Again or Reload Page!!!",
        1500
      );
    }
    setIsLoading(false);
  };

  const handleResetPassword = async (email: string) => {
    setIsLoading(true);
    try {
      const response = await DoxleAPI.post(`/rpw/`, { email });
      if (response.status === 200) {
        showNotification("Success", "success", "An email has been sent", 1500);
        navigate("/responseLinkLogin/");
      } else
        showNotification(
          `${response.status}: ${response.statusText}`,
          "error",
          String(response.data),
          1500
        );
    } catch (error: any) {
      showNotification(
        `${error?.response?.status ?? "ERROR"}: ${
          error?.response?.statusText ?? "Unknown Error"
        }`,
        "error",
        error?.response?.data
          ? String(error?.response?.data)
          : "Unable To Send Password Reset Email",
        1500
      );
    }
    setIsLoading(false);
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") handleSubmit();
  };

  const handleSubmit = () => {
    let valid = true;
    let newPromt = [...prompt];

    if (emailRegex.test(email) === false) {
      if (email === "") {
        newPromt[0] = "Requirerd email";
      } else {
        newPromt[0] = "Invalid email";
      }
      valid = false;
    } else {
      newPromt[0] = "";
    }

    if (loginMode === "email" && password === "") {
      newPromt[1] = "Password is required";
      valid = false;
    } else {
      newPromt[1] = "";
    }

    setPrompt(newPromt);

    if (valid) {
      if (loginMode === "email")
        handleLoginWithPassword({ user: email, password });
      if (loginMode === "link") handleEmailLinkBtn(email);
      if (loginMode === "reset") handleResetPassword(email);
    }
  };
  useEffect(() => {
    handleCheckAuthAndRedirect();
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setPrompt([]);
    }, 4000);

    return () => {
      clearTimeout(timeout);
    };
  }, [prompt]);

  return {
    isLoading,
    handleLoginWithPassword,
    handleEmailLinkBtn,
    handleResetPassword,
    email,
    prompt,
    password,
    loginMode,
    setEmail,
    setLoginMode,
    setPassword,
    handleSubmit,
    handleKeyDown,
  };
};
export default useLoginScreen;
