import React, { useEffect } from "react";
import { useNoteStore } from "../Store/useNoteStore";
import { shallow } from "zustand/shallow";
import { useDoxleCurrentContextStore } from "../../DoxleGeneralStore/useDoxleCurrentContext";
import { useEditDocketSideScreenStore } from "../../CoreContent/EditDocketSideScreen/EditDocketSideScreenStore/useEditDocketSideScreenStore";
import { useShallow } from "zustand/react/shallow";

type Props = { scope: "Project" | "Docket" };

interface INotes {}
const useNotes = ({ scope }: Props): INotes => {
  const { setFilterNoteQuery, filterNoteQuery, setNoteScope, resetNoteStore } =
    useNoteStore(
      useShallow((state) => ({
        filterNoteQuery: state.filterNoteQuery,
        setFilterNoteQuery: state.setFilterNoteQuery,
        setNoteScope: state.setNoteScope,
        resetNoteStore: state.resetNoteStore,
      }))
    );
  const { currentProject, currentSearchText } = useDoxleCurrentContextStore(
    useShallow((state) => ({
      currentProject: state.currentProject,
      currentSearchText: state.currentSearchText,
    }))
  );
  const edittedDocket = useEditDocketSideScreenStore(
    useShallow((state) => state.edittedDocket)
  );

  useEffect(() => {
    if (scope === "Project") {
      setFilterNoteQuery({
        ...filterNoteQuery,
        project: currentProject?.projectId,
        searchText: currentSearchText,
        docket: undefined,
      });
      setNoteScope("Project");
    } else {
      setFilterNoteQuery({
        ...filterNoteQuery,
        docket: edittedDocket?.docketPk,
        searchText: currentSearchText,
        project: undefined,
      });

      setNoteScope("Docket");
    }
  }, [edittedDocket, scope, currentProject, currentSearchText]);

  useEffect(() => {
    return () => {
      resetNoteStore();
    };
  }, []);

  return {};
};

export default useNotes;
