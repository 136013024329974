import { motion } from "framer-motion";
import React from "react";
import styled from "styled-components";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import { IconButton, IconButtonProps } from "@mui/material";
import { IoCloseOutline } from "react-icons/io5";
const StyledCloseButton = styled((props: IconButtonProps) => (
  <IconButton {...props} />
))`
  && {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

interface Props extends IconButtonProps {
  closeIcon?: React.ReactNode;
}

const DoxleCloseBtn = ({ closeIcon, ...rest }: Props) => {
  const { doxleThemeColor } = useDoxleThemeStore((state) => ({
    doxleThemeColor: state.doxleThemeColor,
  }));

  const btnAnimatedVariants = {
    initial: {
      rotateZ: "0deg",
    },
    hovering: {
      rotateZ: "90deg",
      transition: {
        duration: 0.2,
      },
    },
  };
  return (
    <StyledCloseButton {...rest}>
      <motion.div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        // src={
        //   currentTheme === "light" || currentTheme === "contrast"
        //     ? CloseIconDark
        //     : CloseIconLight
        // }
        variants={btnAnimatedVariants}
        initial="intial"
        whileHover="hovering"
      >
        {!closeIcon ? (
          <IoCloseOutline color={doxleThemeColor.primaryFontColor} size={50} />
        ) : (
          closeIcon
        )}
      </motion.div>

      {/* <CloseIconButton
        themeColor={doxleThemeColor}
        defaultProps={{
          variants: btnAnimatedVariants,
          initial: "intial",
          whileHover: "hovering",
        }}
      /> */}
    </StyledCloseButton>
  );
};

export default DoxleCloseBtn;
