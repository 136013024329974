import React from "react";

import { useQueryClient } from "@tanstack/react-query";

import { produce } from "immer";
import { NoteLine } from "../Models/note";
import { getNoteLineListQKey } from "../QueryAPI/notesQueryAPI";
import { useDoxleCurrentContextStore } from "../../DoxleGeneralStore/useDoxleCurrentContext";
import { shallow } from "zustand/shallow";

type Props = {
  noteId: string;
  appendPos?: "start" | "end";
  overwrite?: boolean;
};

interface SetNoteLineLineQueryDataQuery {
  handleAddNoteLineQueryData: (addedNoteLine: NoteLine) => void;
  handleDeleteNoteLineQueryData: (deleteLineId: string) => void;
  handleEditNoteLineQueryData: (edittedNoteLine: NoteLine) => void;
}
const useSetNoteLineQueryDataQuery = ({
  noteId,
  appendPos = "end",
  overwrite = true,
}: Props): SetNoteLineLineQueryDataQuery => {
  const queryClient = useQueryClient();
  const { company } = useDoxleCurrentContextStore(
    (state) => ({ company: state.currentCompany }),
    shallow
  );
  const qKey = getNoteLineListQKey(noteId, company);

  const handleAddNoteLineQueryData = (addedNoteLine: NoteLine) => {
    const oldServerData = queryClient.getQueryData(qKey);
    if (oldServerData) {
      if (overwrite) {
        queryClient.setQueryData(qKey, (old: any) => {
          if (old) {
            const lengthOfPages = old.pages.length;
            return produce(old, (draftOld: any) => {
              draftOld.pages = produce(draftOld.pages, (draftPages: any) => {
                if (appendPos === "start") {
                  (draftPages[0].data.results as NoteLine[]).unshift(
                    addedNoteLine
                  );
                } else {
                  (
                    draftPages[lengthOfPages - 1].data.results as NoteLine[]
                  ).push(addedNoteLine);
                }

                return draftPages;
              });

              return draftOld;
            });
          } else return old;
        });
      } else queryClient.invalidateQueries(qKey);
    } else queryClient.invalidateQueries(qKey);
  };
  const handleDeleteNoteLineQueryData = (deleteLineId: string) => {
    const oldServerData = queryClient.getQueryData(qKey);
    if (oldServerData) {
      if (overwrite) {
        queryClient.setQueryData(qKey, (old: any) => {
          if (old) {
            //find page contain deleted item
            let pageContainGroup: number = (old.pages as Array<any>).findIndex(
              (page) =>
                (page.data.results as NoteLine[]).find(
                  (line) => line.lineId === deleteLineId
                )
            );
            if (pageContainGroup !== -1) {
              const targetOrderItemIndex = (
                (old.pages as Array<any>)[pageContainGroup].data
                  .results as NoteLine[]
              ).findIndex((line) => line.lineId === deleteLineId);
              return produce(old, (draftOld: any) => {
                draftOld.pages = produce(draftOld.pages, (draftPages: any) => {
                  draftPages[pageContainGroup].data.results = produce(
                    draftPages[pageContainGroup].data.results,
                    (draftTargetPageData: NoteLine[]) => {
                      draftTargetPageData.splice(targetOrderItemIndex, 1);

                      return draftTargetPageData;
                    }
                  );
                  return draftPages;
                });

                return draftOld;
              });
            } else queryClient.invalidateQueries(qKey);
          } else queryClient.invalidateQueries(qKey);
        });
      } else queryClient.invalidateQueries(qKey);
    } else queryClient.invalidateQueries(qKey);
  };

  const handleEditNoteLineQueryData = (edittedNoteLine: NoteLine) => {
    const oldServerData = queryClient.getQueryData(qKey);

    if (oldServerData) {
      if (overwrite) {
        queryClient.setQueryData(qKey, (old: any) => {
          if (old) {
            //find page contain deleted item
            let pageContainGroup: number = (old.pages as Array<any>).findIndex(
              (page) =>
                (page.data.results as NoteLine[]).find(
                  (line) => line.lineId === edittedNoteLine.lineId
                )
            );
            if (pageContainGroup !== -1) {
              const targetOrderItemIndex = (
                (old.pages as Array<any>)[pageContainGroup].data
                  .results as NoteLine[]
              ).findIndex((line) => line.lineId === edittedNoteLine.lineId);
              return produce(old, (draftOld: any) => {
                draftOld.pages = produce(draftOld.pages, (draftPages: any) => {
                  draftPages[pageContainGroup].data.results = produce(
                    draftPages[pageContainGroup].data.results,
                    (draftTargetPageData: NoteLine[]) => {
                      if (targetOrderItemIndex !== -1) {
                        const item = draftTargetPageData[targetOrderItemIndex];
                        Object.assign(item, edittedNoteLine);
                      }

                      return draftTargetPageData;
                    }
                  );
                  return draftPages;
                });

                return draftOld;
              });
            } else queryClient.invalidateQueries(qKey);
          } else queryClient.invalidateQueries(qKey);
        });
      } else queryClient.invalidateQueries(qKey);
    } else queryClient.invalidateQueries(qKey);
  };
  return {
    handleAddNoteLineQueryData,
    handleDeleteNoteLineQueryData,
    handleEditNoteLineQueryData,
  };
};

export default useSetNoteLineQueryDataQuery;
