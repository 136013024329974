import { useLottie } from "lottie-react";
// import animationData from "./lotties/doxle-loader.json"; // wagging tail
import animationData from "./lotties/sendMail.json"; // running doggie
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";

interface props {
  animationSize?: number;
  hasBackDrop?: boolean;
  progressType?: "progress" | "send";
  containerStyle?: React.CSSProperties;
  progressText?: string;
  textStyle?: React.CSSProperties;
}

const ProgressScreen: React.FC<props> = ({
  animationSize = 144,
  hasBackDrop = false,
  progressType = "progress",
  containerStyle,
  progressText,
  textStyle,
}: props) => {
  const animationData =
    progressType === "progress"
      ? require("./lotties/progress.json")
      : require("./lotties/sendMail.json");
  const options = {
    animationData: animationData,
    loop: true,
    autoplay: true,
  };
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore((state) => ({
    doxleThemeColor: state.doxleThemeColor,
    doxleFont: state.doxleFont,
  }));
  const style = {
    position: "absolute" as "absolute",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
    zIndex: 1000,
    backgroundColor: hasBackDrop
      ? doxleThemeColor.primaryBackdropColor
      : undefined,
  };

  const { View } = useLottie(options, { width: `${animationSize}px` });

  return (
    <div style={{ ...style, ...containerStyle }}>
      {View}
      {progressText && <span style={textStyle}>{progressText}</span>}
    </div>
  );
};

export default ProgressScreen;
