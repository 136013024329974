import React, { useCallback, useMemo } from "react";
import { Contact } from "../../../../Models/addressBook";
import Popover from "@mui/material/Popover";
import { Components, Virtuoso } from "react-virtuoso";
import {
  StyledQAListScroller,
  StyledQaAssigneePopperContainer,
  StyledQaAssigneeRowWrapper,
  StyledSearchAssigneeTextField,
} from "./StyledComponentCommonQA";
import { useDoxleThemeStore } from "../../../../DoxleGeneralStore/useDoxleThemeStore";
import { AnimatePresence, Variants } from "framer-motion";
import useSelectAssigneeDropdown from "./Hooks/useSelectAssigneeDropdown";
import DoxleEmptyPlaceHolder from "../../../../DoxleDesignPattern/DoxleEmptyPlaceHolder/DoxleEmptyPlaceHolder";
import AssigneeListItem from "./AssigneeListItem";
import DoxleListSkeleton from "../../../../DoxleDesignPattern/DoxleSkeleton/DoxleListSkeleton";
import ListLoadingMore from "../../../../Utilities/Lottie/ListLoadingMore";

type Props = {
  anchorDropdown: HTMLElement | null;
  onCloseDropdown: () => void;
  selectedAssigneeId?: string | null;
  onSelectAssignee?: (assignee: Contact) => void;
};
interface AssigneeListContext {
  isFetchingContactList: boolean;
  selectedAssigneeId?: string | null;
}
const SelectAssigneeDropdown = ({
  selectedAssigneeId,
  onSelectAssignee,
  anchorDropdown,
  onCloseDropdown,
}: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore((state) => ({
    doxleThemeColor: state.doxleThemeColor,
    doxleFont: state.doxleFont,
  }));

  const {
    searchInputQuery,
    setSearchInputQuery,
    contactList,
    isFetchingContactList,
    showContactList,
    setShowContactList,
    handleFetchNextPageContact,
    isFetchingNextPageContact,
  } = useSelectAssigneeDropdown({ anchorDropdown });
  const canBeOpen = Boolean(anchorDropdown);
  const id = canBeOpen ? "select-qa-assignee-popper" : undefined;

  const rowAnimatedVariants: Variants = {
    initial: {
      x: -5,
      opacity: 0,
    },
    entering: {
      x: 0,
      opacity: 1,
    },
  };
  const assigneeListStyle: React.CSSProperties = {
    width: "100%",
    flex: 1,
  };
  const components: Components<Contact, AssigneeListContext> = useMemo(
    () => ({
      Item: (props) => (
        <StyledQaAssigneeRowWrapper
          key={props.item.contactId}
          $themeColor={doxleThemeColor}
          {...props}
          initial="initial"
          animate="entering"
          variants={rowAnimatedVariants}
          transition={{
            duration: 0.2,
          }}
          onClick={() => {
            if (onSelectAssignee) onSelectAssignee(props.item);
          }}
        />
      ),
      Scroller: React.forwardRef(({ ...props }, ref) => (
        <StyledQAListScroller ref={ref} {...props} />
      )),

      EmptyPlaceholder: (props) =>
        props.context?.isFetchingContactList ? (
          <DoxleEmptyPlaceHolder
            headTitleText="Something wrong!"
            subTitleText="Failed to get assignee data..."
            containerBgColor="transparent"
            headTitleTextStyle={{
              fontSize: "1.4rem",
            }}
            subTitleTextStyle={{ fontSize: "1.2rem" }}
          />
        ) : (
          <DoxleEmptyPlaceHolder
            headTitleText="No Assignee Found!"
            containerBgColor="transparent"
            headTitleTextStyle={{
              fontSize: "1.4rem",
            }}
            subTitleTextStyle={{ fontSize: "1.2rem" }}
          />
        ),
    }),
    [onSelectAssignee]
  );

  const itemContent = useCallback(
    (index: number, item: Contact, context?: AssigneeListContext) => (
      <AssigneeListItem
        assignee={item}
        isSelected={Boolean(
          context?.selectedAssigneeId &&
            context.selectedAssigneeId === item.contactId
        )}
      />
    ),
    []
  );
  return (
    <Popover
      id={id}
      open={canBeOpen}
      anchorEl={anchorDropdown}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      sx={{
        "& .MuiPopover-paper": {
          backgroundColor: "transparent",
          overflow: "visible",
          boxShadow: "none",
        },
      }}
      elevation={8}
      onClose={onCloseDropdown}
    >
      <AnimatePresence>
        <StyledQaAssigneePopperContainer
          $themeColor={doxleThemeColor}
          $height={contactList.length > 20 ? 300 : 200}
        >
          <StyledSearchAssigneeTextField
            $themeColor={doxleThemeColor}
            $doxleFont={doxleFont}
            value={searchInputQuery}
            onChange={(e) => setSearchInputQuery(e.target.value)}
            placeholder="Search assignee..."
            autoFocus
            onFocus={(e) => e.target.select()}
          />
          {isFetchingContactList && (
            <DoxleListSkeleton
              skeletonType="comment"
              containerStyle={{ flex: 1, width: "100%" }}
            />
          )}
          {!isFetchingContactList && showContactList && (
            <Virtuoso
              context={{ isFetchingContactList, selectedAssigneeId }}
              data={contactList}
              components={components}
              style={assigneeListStyle}
              itemContent={itemContent}
              endReached={handleFetchNextPageContact}
            />
          )}

          {isFetchingNextPageContact && (
            <ListLoadingMore
              containerStyle={{
                position: "absolute",
                bottom: 0,
                left: 0,
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 10,
              }}
              animationSize={50}
            />
          )}
        </StyledQaAssigneePopperContainer>
      </AnimatePresence>
    </Popover>
  );
};

export default SelectAssigneeDropdown;
