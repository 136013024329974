import {
  StyledQAEditFieldWrapper,
  StyledQaAssigneeDisplayer,
} from "./StyledComponentQAItemDetail";
import { useDoxleThemeStore } from "../../../../DoxleGeneralStore/useDoxleThemeStore";
import CircularProgress from "@mui/material/CircularProgress";
import { useProjectQAStore } from "../../Store/useProjectQAStore";
import { RiCloseCircleFill } from "react-icons/ri";
import DoxleIconButton from "../../../../DoxleDesignPattern/DoxleButtons/DoxleIconButton";
import DoxleSelectContactDropdown from "../../../../DoxleDesignPattern/DoxleSelectContactDropdown/DoxleSelectContactDropdown";
import { useShallow } from "zustand/react/shallow";
import useEditQaFloor from "./Hooks/useEditQaFloor";
import DoxleSelectLocationDropdown from "../../../../DoxleDesignPattern/DoxleSelectLocationDropdown/DoxleSelectLocationDropdown";
type Props = {};

const EditQaFloor = (props: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    useShallow((state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }))
  );
  const { selectedQAItem } = useProjectQAStore(
    useShallow((state) => ({
      selectedQAItem: state.selectedQAItem,
    }))
  );
  const {
    anchorFloorList,
    setAnchorFloorList,
    handleUpdateFloor,
    handleCloseFloorList,
    isUpdatingFloor,
    isHovering,
    setIsHovering,
  } = useEditQaFloor({});
  return (
    <StyledQAEditFieldWrapper className="field-wrapper">
      <span className="field-label-text">Floor</span>
      <StyledQaAssigneeDisplayer
        onClick={(e) => setAnchorFloorList(e.currentTarget)}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
      >
        {selectedQAItem?.floor ? (
          selectedQAItem?.floorName
        ) : (
          <span className="null-text">Select Floor</span>
        )}

        {isUpdatingFloor ? (
          <CircularProgress
            style={{ color: doxleThemeColor.primaryFontColor }}
            size={14}
          />
        ) : (
          selectedQAItem?.assignee &&
          isHovering && (
            <DoxleIconButton
              iconSource={
                <RiCloseCircleFill
                  style={{ color: doxleThemeColor.primaryFontColor }}
                  size={20}
                />
              }
              onClick={(e) => {
                e.stopPropagation();
                handleUpdateFloor(undefined);
              }}
              buttonSize={18}
              buttonWrapperStyle={{
                padding: "0px !important",
                margin: "0px !important",
              }}
            />
          )
        )}
      </StyledQaAssigneeDisplayer>

      <DoxleSelectLocationDropdown
        popoverAnchor={anchorFloorList}
        onClosePopover={handleCloseFloorList}
        variant="floor"
        selectedFloorId={selectedQAItem?.floor}
        onSelectFloor={handleUpdateFloor}
        containerStyle={{
          borderRadius: 9,
        }}
      />
    </StyledQAEditFieldWrapper>
  );
};

export default EditQaFloor;
