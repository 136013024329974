import React from "react";
import { useEditEstimatesStore } from "../../Store/useEditEstimateStore";
import { shallow } from "zustand/shallow";
import DoxleDialogHelper from "../../../../DoxleDesignPattern/DoxleDialogHelper/DoxleDialogHelper";
import { useDoxleAuthStore } from "../../../../DoxleGeneralStore/useDoxleAuthStore";
import { useDoxleCurrentContextStore } from "../../../../DoxleGeneralStore/useDoxleCurrentContext";
import useDoxleNotificationStore from "../../../../DoxleGeneralStore/useDoxleNotificationStore";
import { useEstimatesStore } from "../../Store/useEstimatesStore";
import { useEditDocketSideScreenStore } from "../../../../CoreContent/EditDocketSideScreen/EditDocketSideScreenStore/useEditDocketSideScreenStore";
import EstimatesQueryAPI from "../../../QueryHooks/EstimatesQueryAPI";
import { useDoxleThemeStore } from "../../../../DoxleGeneralStore/useDoxleThemeStore";
import useSetEstimateQueryData from "../hooks/useSetEstimateQueryData";

type Props = {};

const DeleteEstimateConfirm = (props: Props) => {
  const { doxleThemeColor } = useDoxleThemeStore();
  const { company } = useDoxleCurrentContextStore((state) => ({
    company: state.currentCompany,
  }));
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification
  );
  const { estimatesQueryFilter } = useEstimatesStore(
    (state) => ({
      estimatesQueryFilter: state.estimatesQueryFilter,
    }),
    shallow
  );
  const edittedDocket = useEditDocketSideScreenStore(
    (state) => state.edittedDocket,
    shallow
  );
  const { handleRemoveEstimate } = useSetEstimateQueryData({
    filter: estimatesQueryFilter,
  });
  const { destroy } = EstimatesQueryAPI.useMutateEstimate({
    company,
    showNotification,
    filter: estimatesQueryFilter,
    docketId: undefined,
  });
  const {
    deletedEstimateList,

    clearDeletedEstimateList,
  } = useEditEstimatesStore(
    (state) => ({
      deletedEstimateList: state.deletedEstimateList,

      clearDeletedEstimateList: state.clearDeletedEstimateList,
    }),
    shallow
  );
  const isDialogOpen = deletedEstimateList.length > 0;

  const handleDeleteBudgetRow = () => {
    const idList: string[] = deletedEstimateList.map(
      (estimate) => estimate.estimateId
    );
    clearDeletedEstimateList();
    setTimeout(() => {
      handleRemoveEstimate(idList);
      destroy.mutate({ estimateIds: idList, docket: edittedDocket });
    }, 300);
  };

  return (
    <DoxleDialogHelper
      open={isDialogOpen}
      title="Confirm Delete Budget Row"
      description="All Data belong to these budget row will be deleted PERMANENTLY, do you still want to proceed?"
      dialogButtons={[
        {
          buttonText: "Cancel",
          buttonFunction: () => clearDeletedEstimateList(),
          buttonColor: doxleThemeColor.primaryContainerColor,
          btnStyleSx: {
            border: `1px solid ${doxleThemeColor.primaryDividerColor}`,
          },
          buttonTextStyle: {
            color: doxleThemeColor.primaryFontColor,
          },
        },
        {
          buttonText: "Confirm",
          buttonFunction: handleDeleteBudgetRow,
          btnStyleSx: {
            border: `1px solid ${doxleThemeColor.primaryDividerColor}`,
          },
        },
      ]}
      onClose={clearDeletedEstimateList}
    />
  );
};

export default DeleteEstimateConfirm;
