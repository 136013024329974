import { motion } from "framer-motion";
import styled from "styled-components";

export const StyledQRUserLog = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
`;
export const StyledQRLogGroupHeader = styled(motion.div)`
  width: calc(100% - 32px);
  display: flex;
  flex: 1;
  align-items: center;
  background-color: ${(p) => p.theme.staticMenuColor.staticBg};
  padding: 8px 16px;
  cursor: pointer;
  .date-title {
    font-size: 18px;
    font-weight: 600;
    color: ${(p) => p.theme.staticMenuColor.staticFontColor};
    line-height: 18px;
    flex: 1;
  }

  .field-header {
    font-size: 16px;
    font-weight: 400;
    color: ${(p) => p.theme.staticMenuColor.staticFontColor};
    line-height: 14px;
    width: 15%;
    min-width: 100px;
    max-width: 140px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: center;
  }
`;
export const StyledQRLogItem = styled.div`
  width: calc(100% - 32px);
  display: flex;
  min-height: 20px;
  align-items: center;
  border-bottom: 1px solid ${(p) => p.theme.color.primaryDividerColor};
  padding: 8px 16px;
  transition: all 0.3s ease;
  cursor: pointer;
  &:hover {
    background-color: ${(p) => p.theme.color.primaryHoverColor};
  }
  .user-name-text {
    font-size: 16px;
    font-weight: 600;
    color: ${(p) => p.theme.color.primaryFontColor};
    line-height: 16px;
    flex: 1;
  }

  .time-text {
    font-size: 16px;
    font-weight: 400;
    color: ${(p) => p.theme.color.primaryFontColor};
    line-height: 16px;
    width: 15%;
    min-width: 100px;
    max-width: 140px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: center;
  }
`;
