import { useCallback, useEffect, useMemo } from "react";
import { useInboxStore } from "../InboxStore/useInboxStore";
import { shallow } from "zustand/shallow";
import { useDoxleCurrentContextStore } from "../../DoxleGeneralStore/useDoxleCurrentContext";
import useDoxleNotificationStore from "../../DoxleGeneralStore/useDoxleNotificationStore";
import DocketQuery from "../../Services/QueryHooks/docketQueryAPI";
import { Docket, LightDocket } from "../../Models/dockets";
import { useDoxleAuthStore } from "../../DoxleGeneralStore/useDoxleAuthStore";
import { groupBy } from "lodash";

type Props = {};

export interface GroupDocketByAssignee {
  assigneeId: string | null;
  bicName: string;
  dockets: LightDocket[];
}
interface INbAssigneeGroup {
  nbItemsByAssignee: LightDocket[];
  groupAssigneeNbCount: number[];
  assigneeGroupIds: string[];
}
interface INbProjectGroup {
  nbItemsByProject: LightDocket[];
  groupProjectNbCount: number[];
  projectGroupIds: string[];
}
export interface GroupDocketByProject {
  projectId: string | null;
  projectSiteAddress: string;
  dockets: LightDocket[];
}
interface GetInboxDockets {
  docketList: LightDocket[];
  docketSortedByStatus: LightDocket[];

  isFetchingDocketList: boolean;
  isSuccessFetchingDocketList: boolean;
  isErrorFetchingDocketList: boolean;
  isFetchingNextPageDocketList: boolean;
  handleFetchNextPageDocket: () => void;
  hasNextPageDocket: boolean;

  generateGroupNbByAssignee: INbAssigneeGroup;
  generateGroupNbByProject: INbProjectGroup;
}
const useGetInboxDockets = (props: Props): GetInboxDockets => {
  const {
    filterDocketListQuery,
    inboxViewMode,
    setFilterDocketListQuery,
    statusFilterValue,
  } = useInboxStore(
    (state) => ({
      filterDocketListQuery: state.filterDocketListQuery,
      inboxViewMode: state.inboxViewMode,
      setFilterDocketListQuery: state.setFilterDocketListQuery,
      statusFilterValue: state.statusFilterValue,
    }),
    shallow
  );
  const user = useDoxleAuthStore((state) => state.user, shallow);
  const { company, currentSearchText } = useDoxleCurrentContextStore(
    (state) => ({
      company: state.currentCompany,
      currentSearchText: state.currentSearchText,
    })
  );
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification
  );

  const getDocketListQuery = DocketQuery.useRetrieveFullDetailDocketList({
    filter: filterDocketListQuery,
    showNotification,
    company,
    enabled: true,
  });
  const docketList = useMemo(
    () =>
      getDocketListQuery.isSuccess
        ? getDocketListQuery.data?.pages
          ? getDocketListQuery.data?.pages.reduce((acc, data) => {
              return acc.concat(data.data.results);
            }, [] as LightDocket[]) ?? []
          : []
        : [],
    [getDocketListQuery.data]
  );
  const docketSortedByStatus = useMemo(() => {
    const dockets = [...docketList].sort((a, b) => {
      if (a.isSticky && !b.isSticky) {
        return -1; // a is sticky, so it should come first
      } else if (!a.isSticky && b.isSticky) {
        return 1; // b is sticky, so it should come first
      } else if (a.isSticky && b.isSticky) {
        if (a.statusIndex < b.statusIndex) {
          return 1;
        } else if (a.statusIndex > b.statusIndex) {
          return -1;
        } else {
          return 0;
        }
      } else {
        // If both dockets are sticky or not sticky, sort by statusIndex
        if (a.statusIndex < b.statusIndex) {
          return 1; // a should come before b
        } else if (a.statusIndex > b.statusIndex) {
          return -1; // a should come after b
        } else {
          return 0; // a and b have the same statusIndex
        }
      }
    });
    //filter list by status
    if (statusFilterValue.length > 0) {
      const filtered = dockets.filter((item) =>
        statusFilterValue.includes(item.statusName)
      );
      return filtered;
    }
    return dockets;
  }, [docketList, statusFilterValue]);
  const handleFetchNextPageDocket = () => {
    if (getDocketListQuery.hasNextPage) getDocketListQuery.fetchNextPage();
  };

  const generateGroupNbByAssignee: INbAssigneeGroup = useMemo(() => {
    let filteredDocketList = [...docketList];
    if (statusFilterValue.length > 0) {
      filteredDocketList = docketList.filter((item) =>
        statusFilterValue.includes(item.statusName)
      );
    }

    const sortedWithAssigneeId = filteredDocketList.sort((a, b) => {
      if (a.ballInCourt === null && b.ballInCourt !== null) return -1;
      else if (a.ballInCourt !== null && b.ballInCourt === null) return 1;
      else {
        if (a.ballInCourtName > b.ballInCourtName) return 1;
        else return -1;
      }
    });

    const groupAssigneeNb = groupBy(
      sortedWithAssigneeId,
      (nb) => nb.ballInCourt
    );
    const groupAssigneeNbCount = Object.values(groupAssigneeNb).map(
      (group) => group.length
    );
    const assigneeGroupIds = Object.keys(groupAssigneeNb);
    const nestedSortList = assigneeGroupIds.reduce((acc, assigneeId) => {
      return acc.concat(
        groupAssigneeNb[assigneeId].sort((a, b) => {
          if (a.isSticky && !b.isSticky) {
            return -1; // a is sticky, so it should come first
          } else if (!a.isSticky && b.isSticky) {
            return 1; // b is sticky, so it should come first
          } else {
            // If both dockets are sticky or not sticky, sort by statusIndex
            if (a.statusIndex < b.statusIndex) {
              return 1; // a should come before b
            } else if (a.statusIndex > b.statusIndex) {
              return -1; // a should come after b
            } else {
              return 0; // a and b have the same statusIndex
            }
          }
        })
      );
    }, [] as LightDocket[]);
    return {
      nbItemsByAssignee: nestedSortList,
      groupAssigneeNbCount,
      assigneeGroupIds,
    };
  }, [docketList, statusFilterValue]);

  const generateGroupNbByProject: INbProjectGroup = useMemo(() => {
    let filteredDocketList = [...docketList];
    if (statusFilterValue.length > 0) {
      filteredDocketList = docketList.filter((item) =>
        statusFilterValue.includes(item.statusName)
      );
    }

    const sortedWithProjectName = filteredDocketList.sort((a, b) => {
      if (a.project === null && b.project !== null) return -1;
      else if (a.project !== null && b.project === null) return 1;
      else {
        if (a.projectSiteAddress > b.projectSiteAddress) return 1;
        else return -1;
      }
    });

    const groupProjectNb = groupBy(sortedWithProjectName, (nb) => nb.project);
    const groupProjectNbCount = Object.values(groupProjectNb).map(
      (group) => group.length
    );
    const projectGroupIds = Object.keys(groupProjectNb);

    const nestedSortList = projectGroupIds.reduce((acc, projectId) => {
      return acc.concat(
        groupProjectNb[projectId].sort((a, b) => {
          if (a.isSticky && !b.isSticky) {
            return -1; // a is sticky, so it should come first
          } else if (!a.isSticky && b.isSticky) {
            return 1; // b is sticky, so it should come first
          } else {
            // If both dockets are sticky or not sticky, sort by statusIndex
            if (a.statusIndex < b.statusIndex) {
              return 1; // a should come before b
            } else if (a.statusIndex > b.statusIndex) {
              return -1; // a should come after b
            } else {
              return 0; // a and b have the same statusIndex
            }
          }
        })
      );
    }, [] as LightDocket[]);
    return {
      nbItemsByProject: nestedSortList,
      groupProjectNbCount,
      projectGroupIds,
    };
  }, [docketList, statusFilterValue]);

  useEffect(() => {
    if (inboxViewMode === "My Notices" && user)
      setFilterDocketListQuery({
        ball_in_court: user.userId,
        search: currentSearchText,
      });
    else
      setFilterDocketListQuery({
        ball_in_court: undefined,
        search: currentSearchText,
      });
  }, [inboxViewMode, currentSearchText]);

  return {
    docketList,
    isFetchingDocketList: getDocketListQuery.isLoading,
    isSuccessFetchingDocketList: getDocketListQuery.isSuccess,
    isErrorFetchingDocketList: getDocketListQuery.isError,
    isFetchingNextPageDocketList: getDocketListQuery.isFetchingNextPage,
    handleFetchNextPageDocket,
    hasNextPageDocket: Boolean(getDocketListQuery.hasNextPage),

    docketSortedByStatus,
    generateGroupNbByAssignee,
    generateGroupNbByProject,
  };
};

export default useGetInboxDockets;
