import {MouseEvent} from "react";
import {StyledFileButton, StyledFileButtonText, StyledFileIcon} from "./SyledComponents";
import {useDoxleThemeStore} from "../../DoxleGeneralStore/useDoxleThemeStore";
import {shallow} from "zustand/shallow";

interface Props {
  onClick: (e: MouseEvent<HTMLDivElement>) => void;
  selected: boolean;
}

const ExportExcelButton = ({onClick, selected}: Props) =>{
  const styleProps = useDoxleThemeStore(state => ({
    $themeColor: state.doxleThemeColor,
    $doxleFont: state.doxleFont,
    $currentTheme: state.currentTheme,
  }), shallow);
  return (
    <StyledFileButton {...styleProps} onClick={onClick} $selected={selected}>
      <StyledFileIcon height='5.5rem' src={'/assets/icons/excel-icon.png'} />
      {/*<br/>*/}
      {/*<StyledFileButtonText {...styleProps}>*/}
      {/*  Export Excel*/}
      {/*</StyledFileButtonText>*/}
    </StyledFileButton>
  )
}
export default ExportExcelButton
