import styled from "styled-components";
import { editRgbaAlpha } from "../../../Utilities/FunctionUtilities";
import Button from "@mui/material/Button";
import { motion } from "framer-motion";

export const StyledAddedSwatchContainer = styled(motion.div)`
  border-radius: 4px;
  margin: 4px;
  position: relative;
  width: calc(20% - 8px);
  aspect-ratio: 1;

  display: flex;
  img {
    width: 100%;
    height: 100%;
  }
  .close-icon {
    position: absolute;
    z-index: 5;
    top: 4px;
    right: 4px;
    cursor: pointer;
  }
`;

export const StyledPricebookSwatchContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
`;

export const StyledAddSwatchDropzone = styled.div`
  height: calc(100% - 42px);
  width: calc(100% - 42px);
  padding: 20px;
  border-radius: 4px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px dashed ${(p) => p.theme.color.primaryFontColor};
  text-align: center;
  font-size: 20px;
  font-family: ${(p) => p.theme.font.primaryFont};
  color: ${(p) =>
    editRgbaAlpha({
      rgbaColor: p.theme.color.primaryFontColor,
      alpha: "0.4",
    })} !important;
  font-weight: 400;
  cursor: pointer;
`;
export const StyledDeleteRateLineBtn = styled.div`
  padding: 4px;
`;
export const StyledPricebookDialogSubmitButton = styled(Button)<{
  $width?: string;
}>`
  && {
    margin-top: 14px;
    display: flex;
    width: ${(p) => p.$width};
    height: 40px;
    font-family: ${(p) => p.theme.font.primaryFont};
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0.05em;
    color: ${(p) => p.theme.color.primaryReverseFontColor};
    transition: all 0.3s ease;
    &:hover {
      opacity: 0.7;
    }
  }
  &.MuiButton-root {
    background-color: ${(p) => p.theme.color.primaryFontColor};
  }
  &.MuiButtonBase-root {
  }
  &.MuiButton-root:hover {
    background-color: ${(p) => p.theme.color.primaryFontColor};
  }
`;
export const StyledPricebookAddNewSupplierBtn = styled(motion(Button))`
  && {
    margin-top: 20px;
    display: flex;
    min-width: 0px !important;
    font-family: ${(p) => p.theme.font.primaryFont};
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    align-self: flex-start;
    text-transform: capitalize !important;
    color: ${(p) => p.theme.color.doxleColor};
    letter-spacing: 0.05em;
    text-decoration-line: underline;
    cursor: pointer;
  }

  &.MuiButton-root {
    background-color: transparent;
    padding: 0px !important;
  }
  &.MuiButtonBase-root {
    padding: 0px !important;
  }
  &.MuiButton-root:hover {
    background: transparent;
  }
`;
export const StyledPricebookSwatchItemContainer = styled(motion.div)`
  border-radius: 4px;
  margin: 4px;
  position: relative;
  width: calc(20% - 8px);
  aspect-ratio: 1;
  display: flex;
  img {
    width: 100%;
    cursor: pointer;
  }
  .close-icon {
    position: absolute;
    z-index: 5;
    top: 4px;
    right: 4px;
    cursor: pointer;
  }
`;

export const StyledCoreDocketOption = styled.li<{ $selected: boolean }>`
  width: calc(100% - 20px);
  display: flex;
  align-items: center;
  padding: 10px !important;
  font-family: ${(p) => p.theme.font.primaryFont};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.05em;
  color: ${(p) =>
    p.$selected ? p.theme.color.doxleColor : p.theme.color.primaryFontColor};
  cursor: pointer;
  transition: all 0.3s ease;
  &:hover {
    background-color: ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.theme.color.doxleColor,
        alpha: "0.2",
      })} !important;
    color: white;
  }
`;
