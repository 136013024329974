import React, { useEffect } from "react";
import { StyledQAProjectContainer } from "./StyledComponentQAProject";
import { motion } from "framer-motion";
import QAProjectList from "./Content/QAProjectList/QAProjectList";
import QAProjectNav from "./QAProjectNav";
import { useProjectQAStore } from "./Store/useProjectQAStore";
import { shallow } from "zustand/shallow";
import QAListDetail from "./Content/QAListDetail/QAListDetail";
import { useDoxleCurrentContextStore } from "../../DoxleGeneralStore/useDoxleCurrentContext";
import QAMarkup from "./Content/QAMarkup/QAMarkup";
import QAListEdit from "./Content/QAListEdit/QAListEdit";
import QAListSignature from "./Content/QAListSignature/QAListSignature";

type Props = {};

const QAProject = (props: Props) => {
  const {
    selectedQAList,
    edittedQAListItem,
    setSelectedQAList,
    setSelectedQAItem,
  } = useProjectQAStore(
    (state) => ({
      selectedQAList: state.selectedQAList,
      edittedQAListItem: state.edittedQAListItem,
      setSelectedQAList: state.setSelectedQAList,
      setSelectedQAItem: state.setSelectedQAItem,
    }),
    shallow
  );
  const { company, currentProject, currentSearchText } =
    useDoxleCurrentContextStore(
      (state) => ({
        currentProject: state.currentProject,
        company: state.currentCompany,
        currentSearchText: state.currentSearchText,
      }),
      shallow
    );

  useEffect(() => {
    if (currentProject) {
      setSelectedQAList(undefined);
      setSelectedQAItem(undefined);
    }
  }, [currentProject, company]);

  return (
    <StyledQAProjectContainer>
      <QAProjectNav />

      <motion.div
        className="qa-content-wrapper"
        layout="position"
        transition={{
          duration: 0.2,
          damping: 16,
        }}
      >
        {!selectedQAList && !edittedQAListItem && <QAProjectList />}

        {selectedQAList && <QAListDetail />}

        {edittedQAListItem && <QAListEdit />}
      </motion.div>

      <QAMarkup />

      <QAListSignature />
    </StyledQAProjectContainer>
  );
};

export default QAProject;
