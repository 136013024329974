import React, { useCallback, useState } from "react";
import { useDoxleCurrentContextStore } from "../../../../DoxleGeneralStore/useDoxleCurrentContext";
import { shallow } from "zustand/shallow";
import useDoxleNotificationStore from "../../../../DoxleGeneralStore/useDoxleNotificationStore";
import ContactsAPI from "../../../../Services/QueryHooks/contactsAPI";
import {
  CREATE_NEW_CONTACT_COMPANY_TEMPLATE,
  ContactCompany,
} from "../../../../Models/addressBook";
import {
  checkEmailValid,
  checkPhoneValid,
  checkValidABN,
} from "../../../../Utilities/FunctionUtilities";

type Props = { handleCloseForm: () => void };

interface IAddContactForm {
  shouldShowError: boolean;
  handleAddContact: () => void;
  newContact: ContactCompany;
  setNewContact: React.Dispatch<React.SetStateAction<ContactCompany>>;
  isAddingContact: boolean;
}
const useAddContactForm = ({ handleCloseForm }: Props): IAddContactForm => {
  const [shouldShowError, setShouldShowError] = useState(false);
  const [newContact, setNewContact] = useState<ContactCompany>({
    ...CREATE_NEW_CONTACT_COMPANY_TEMPLATE({}),
  });
  const company = useDoxleCurrentContextStore(
    (state) => state.currentCompany,
    shallow
  );
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );
  const contactsRegexCheck = useCallback(() => {
    let errorCounter = 0;
    //# CHECK COMPANY
    Object.keys(newContact).map((key) => {
      let keyItem = key as keyof ContactCompany;
      if (keyItem === "name" || keyItem === "address") {
        if (!newContact[keyItem]) errorCounter += 1;
      } else if (keyItem === "email") {
        if (newContact[keyItem] && !checkEmailValid(newContact[keyItem]))
          errorCounter += 1;
      } else if (keyItem === "phone") {
        if (!newContact[keyItem]) errorCounter += 1;
        else if (newContact[keyItem] && !checkPhoneValid(newContact[keyItem]))
          errorCounter += 1;
      } else if (keyItem === "abn") {
        if (newContact[keyItem] && !checkValidABN(newContact[keyItem]))
          errorCounter += 1;
      }
    });

    if (errorCounter === 0) {
      return true;
    } else {
      setShouldShowError(true);
      return false;
    }
  }, [newContact]);

  const postContacts = ContactsAPI.useAddContactCompanyQuery({
    showNotification,

    company,
    filter: {},
    onSuccessCb: () => {
      handleCloseForm();
    },
  });

  const handleAddContact = () => {
    const checkValid = contactsRegexCheck();

    if (checkValid)
      postContacts.mutate({
        name: newContact.name,
        abn: newContact.abn,
        address: newContact.address,
        contacts: [],
      });
  };
  return {
    shouldShowError,
    handleAddContact,
    newContact,
    setNewContact,
    isAddingContact: postContacts.isLoading,
  };
};

export default useAddContactForm;
