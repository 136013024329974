import Box from "@mui/material/Box";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import InventoryQueryAPI from "../QueryAPIHooks/InventoryQueryAPI";
import { useCallback, useState } from "react";
import {
  StyledAddInventoryScreenModal,
  StyledDetailTitle,
  StyledEditInventoryInput,
  StyledInputContainer,
  StyledSubititleTextArea,
} from "../EditInventoryScreen/StyledEditInventory";
import { FileRejection, useDropzone } from "react-dropzone";
import {
  StyledAddButton,
  StyledButtonContainer,
  StyledImageUploadZone,
  StyledInventoryFileContainer,
  StyledNewInventoryTitle,
  StyledUploadPrompt,
  StyledWarning,
} from "./StyledAddInventory";
import { AttachmentUploadIcon } from "../../Mail/Svg/MailAttachmentUploadIcon";
import { useDoxleCurrentContextStore } from "../../DoxleGeneralStore/useDoxleCurrentContext";
import useDoxleNotificationStore from "../../DoxleGeneralStore/useDoxleNotificationStore";
import { shallow } from "zustand/shallow";
import InventoryImageAPI from "../QueryAPIHooks/InventoryImageAPI";
import useSetInventoryQueryData from "../QueryAPIHooks/useSetInventoryQueryData";
import { useInventoryStore } from "../Store/useInventoryStore";
import { InventoryItem } from "../Models/Inventory";
import AddedImageItems from "./AddedImageItems";
import { produce } from "immer";

const style = {
  position: "absolute" as "absolute",
  top: 0,
  right: 0,
  bgcolor: "background.paper",
  p: 4,
};

export default function EditInventoryModal() {
  const [title, setTitle] = useState<string>("");
  const [subtitle, setSubtitle] = useState<string>("");
  const [quantity, setQuantity] = useState<string>("");
  const [cost, setCost] = useState<string>("");
  const [images, setImages] = useState<File[]>([]);
  const [newInventory, setNewInventory] = useState<InventoryItem | undefined>(
    undefined
  );

  const [titleWarning, setTitleWarning] = useState<string>("");
  const [quantityWarning, setQuantityWarning] = useState<string>("");
  const [costWarning, setCostWarning] = useState<string>("");

  const { doxleThemeColor, currentTheme, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
      currentTheme: state.currentTheme,
    })
  );

  const company = useDoxleCurrentContextStore(
    (state) => state.currentCompany,
    shallow
  );
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );

  const { display, setDisplay } = useInventoryStore((state) => ({
    display: state.displayAddInventoryScreen,
    setDisplay: state.setDisplayAddInventoryScreen,
  }));

  const resetInputParams = () => {
    setTitle("");
    setSubtitle("");
    setQuantity("");
    setCost("");
    setImages([]);
    setTitleWarning("");
    setQuantityWarning("");
    setCostWarning("");
  };

  const handleSuccessAddInventory = () => {
    resetInputParams();
    setDisplay(false);
  };

  const { handleAddInventory, handleUpdateInventoryList } =
    useSetInventoryQueryData({});

  const addImage = InventoryImageAPI.useUploadInventoryImageQuery({
    company,
    showNotification,
    onSuccessAddCb(newImages) {
      if (newImages && newInventory) {
        if (newImages.length > 0) {
          let newInventoryWithImg = { ...newInventory };
          newInventoryWithImg.defaultImage = newImages[0].url;
          handleUpdateInventoryList(newInventoryWithImg);
        }
      }
    },
    onErrorCb() {
      if (newInventory) {
        let newInventoryWithError = { ...newInventory };
        newInventoryWithError.defaultImage = "Error";
        handleAddInventory(newInventoryWithError);
      }
    },
  });
  // const errorResult=useIsMutating({
  //   mutationKey:["hh"],
  //   predicate:query=>Boolean(query.state.status===""&& (query.state.variables as AddInventoryUploadImageParams).inventoryId),

  // })

  const addInventory = InventoryQueryAPI.useAddInventoryQuery({
    company: company,
    showNotification,
    onSuccessAddCb(newInventory) {
      setNewInventory(newInventory);
      if (newInventory) {
        if (images.length > 0) {
          // newInventory.firstThumbnail = "loading";
          handleAddInventory({ ...newInventory, defaultImage: "loading" });
          addImage.mutate({
            imageFiles: images,
            inventoryId: newInventory.inventoryId,
          });
        } else {
          handleAddInventory(newInventory);
        }
      }
      handleSuccessAddInventory();
    },
  });

  const handleClose = () => {
    resetInputParams();
    setDisplay(false);
  };

  const checkQuantityCostValid = (val: string) => {
    if (val === "") {
      return false;
    }
    if (isNaN(Number(val))) {
      return false;
    } else {
      // if (countDecimals(val) > 4) {
      //   return false;
      // }
      return true;
    }
  };

  const handleClickAddBtn = () => {
    let valid = true;

    if (title.length === 0) {
      setTitleWarning("please enter title");
      valid = false;
    }

    if (quantity.length === 0) {
      setQuantityWarning("please enter quantity");
      valid = false;
    }

    if (cost.length === 0) {
      setCostWarning("please enter price");
      valid = false;
    }

    if (valid) {
      addInventory.mutate({
        title: title,
        subtitle: subtitle,
        quantity: Number(quantity).toFixed(2).toString(),
        cost: Number(cost).toFixed(2).toString(),
      });
    }
  };

  const onDrop = useCallback(
    <T extends File>(acceptedFiles: T[], fileRejections: FileRejection[]) => {
      const file = acceptedFiles[0];
      if (file) {
        setImages((prevState) => [...prevState, ...acceptedFiles]);
      }
    },
    []
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "image/jpeg": [".jpeg", ".png"],
    },
  });

  const removeFile = useCallback((index: number) => {
    // let newFileList = [...images];
    // newFileList.splice(index, 1);
    setImages((prev) =>
      produce(prev, (draft) => {
        draft = draft.filter((_, idx) => idx !== index);
        return draft;
      })
    );
  }, []);

  return (
    <div>
      <StyledAddInventoryScreenModal
        open={display}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        $themeColor={doxleThemeColor}
        $currentTheme={currentTheme}
        $height={"100%"}
        BackdropProps={{
          style:
            currentTheme === "dark"
              ? { backgroundColor: "black", opacity: "0.4" }
              : { backgroundColor: "white", opacity: "0.4" },
        }}
        closeAfterTransition
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "calc(100vh - 28px) !important",
            padding: "14px !important",
            width: "20vw",
            position: "absolute",
            top: 0,
            right: 0,
            minWidth: 400,
          }}
        >
          <StyledNewInventoryTitle
            $themeColor={doxleThemeColor}
            $doxleFont={doxleFont}
          >
            New Inventory
          </StyledNewInventoryTitle>

          <StyledInputContainer>
            <StyledDetailTitle $themeColor={doxleThemeColor}>
              Title *
            </StyledDetailTitle>
            <StyledEditInventoryInput
              $currentTheme={currentTheme}
              $themeColor={doxleThemeColor}
              value={title}
              onChange={(e) => {
                if (e.target.value.length === 0) {
                  setTitleWarning("please enter title");
                } else {
                  setTitleWarning("");
                }
                setTitle(e.target.value);
              }}
            />
            <StyledWarning $doxleFont={doxleFont}>{titleWarning}</StyledWarning>
          </StyledInputContainer>

          <StyledInputContainer>
            <StyledDetailTitle $themeColor={doxleThemeColor}>
              Subtitle
            </StyledDetailTitle>
            <StyledSubititleTextArea
              $currentTheme={currentTheme}
              $themeColor={doxleThemeColor}
              $doxleFont={doxleFont}
              value={subtitle}
              onChange={(e) => {
                setSubtitle(e.target.value);
              }}
            />
          </StyledInputContainer>

          <StyledInputContainer>
            <StyledDetailTitle $themeColor={doxleThemeColor}>
              Quantity *
            </StyledDetailTitle>
            <StyledEditInventoryInput
              width="9.9"
              $currentTheme={currentTheme}
              $themeColor={doxleThemeColor}
              value={quantity}
              onChange={(e) => {
                if (e.target.value.length == 0) {
                  setQuantityWarning("please enter quantity");
                } else if (!checkQuantityCostValid(e.target.value)) {
                  setQuantityWarning("please enter correct quantity");
                } else {
                  setQuantityWarning("");
                }
                setQuantity(e.target.value);
              }}
            />
            <StyledWarning $doxleFont={doxleFont}>
              {quantityWarning}
            </StyledWarning>
          </StyledInputContainer>

          <StyledInputContainer>
            <StyledDetailTitle $themeColor={doxleThemeColor}>
              Price(ex gst) *
            </StyledDetailTitle>
            <StyledEditInventoryInput
              width="9.9"
              $currentTheme={currentTheme}
              $themeColor={doxleThemeColor}
              value={cost}
              onChange={(e) => {
                if (e.target.value.length == 0) {
                  setCostWarning("please enter cost");
                } else if (!checkQuantityCostValid(e.target.value)) {
                  setCostWarning("please enter correct cost");
                } else {
                  setCostWarning("");
                }
                setCost(e.target.value);
              }}
            />
            <StyledWarning $doxleFont={doxleFont}>{costWarning}</StyledWarning>
          </StyledInputContainer>

          <StyledImageUploadZone
            {...getRootProps()}
            style={{ cursor: "pointer" }}
          >
            <input {...getInputProps()} />
            <AttachmentUploadIcon themeColor={doxleThemeColor} />
            <StyledUploadPrompt
              $themeColor={doxleThemeColor}
              $doxleFont={doxleFont}
            >
              Upload inventory image here
            </StyledUploadPrompt>
          </StyledImageUploadZone>

          <StyledInventoryFileContainer>
            {images.map((file, index) => (
              <AddedImageItems
                imgFile={file}
                index={index}
                removeFile={removeFile}
                key={`file${index}`}
              />
            ))}
          </StyledInventoryFileContainer>
          <StyledButtonContainer>
            <StyledAddButton
              $themeColor={doxleThemeColor}
              onClick={handleClickAddBtn}
            >
              Add
            </StyledAddButton>
          </StyledButtonContainer>
        </Box>
      </StyledAddInventoryScreenModal>
    </div>
  );
}
