import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import { DoxleFile, DoxleFolder } from "../../Models/files";
import {
  IFilterGetFileQueryFilter,
  IFilterGetFolderQueryFilter,
} from "../QueryHooks/FileStorageQueryAPI";

export type FileView = "GridView" | "ListView";
export type FileLevel = "Company" | "Project" | "Docket";

interface FileStore {
  currentFolder: DoxleFolder | undefined;
  currentFile: DoxleFile | undefined;
  currentView: FileView;
  selectedFiles: DoxleFile[];
  isProcessing: boolean;
  setIsProcessing: (root: boolean) => void;
  setCurrentFolder: (folder: DoxleFolder | undefined) => void;
  setCurrentFile: (file: DoxleFile | undefined) => void;
  setCurrentView: (view: FileView) => void;
  setSelectedFile: (file: DoxleFile) => void;
  setUnselectedFile: (file: DoxleFile) => void;
  clearSelectedFiles: () => void;
  setSelectedMultipleFiles: (files: DoxleFile[]) => void;
  currentLevel: FileLevel;
  setCurrentLevel: (level: FileLevel) => void;

  //*control folder query filter
  filterRootFolderQuery: IFilterGetFolderQueryFilter;
  setPartialFilterRootFolderQuery: (
    filter: Partial<IFilterGetFolderQueryFilter>
  ) => void;
  setWholeFilterRootFolderQuery: (
    filter: Partial<IFilterGetFolderQueryFilter>
  ) => void;

  //*control file root query filter
  filterRootFileQuery: IFilterGetFileQueryFilter;
  setPartialFilterRootFileQuery: (
    filter: Partial<IFilterGetFileQueryFilter>
  ) => void;
  setWholeFilterRootFileQuery: (
    filter: Partial<IFilterGetFileQueryFilter>
  ) => void;

  clearFileStore: () => void;
}

const sessionView = sessionStorage.getItem("currentFileSessionView");

export const useFileStore = create(
  immer<FileStore>((set, get) => ({
    currentFolder: undefined,
    currentFile: undefined,
    isProcessing: false,

    currentView: (sessionView as FileView) || "ListView",
    selectedFiles: [],

    setIsProcessing: (process: boolean) =>
      set((state) => {
        state.isProcessing = process;
      }),

    setCurrentFolder: (folder: DoxleFolder | undefined) =>
      set((state) => {
        state.currentFolder = folder;
      }),
    setCurrentFile: (file: DoxleFile | undefined) =>
      set((state) => {
        state.currentFile = file;
      }),
    setCurrentView: (view: FileView) =>
      set((state) => {
        state.currentView = view;
        sessionStorage.setItem("currentFileSessionView", view);
      }),

    setSelectedFile: (file: DoxleFile) =>
      set((state) => {
        //* PUSH THE FILE INTO SELECTED FILE LIST
        state.selectedFiles.push(file);

        // console.log("Selected File: ")
        // console.log(get().selectedFiles)
      }),

    setUnselectedFile: (file: DoxleFile) =>
      set((state) => {
        const selectedFiles = state.selectedFiles;

        const foundIndex = selectedFiles.findIndex(
          (item) => item.fileId === file.fileId
        );

        //* REMOVES THE FILE IN A SPECIFIC INDEX
        if (foundIndex > -1) {
          console.log(
            "%cFound Index ",
            "background:white; color:black; ",
            foundIndex
          );
          state.selectedFiles.splice(foundIndex, 1);
        }
      }),

    clearSelectedFiles: () =>
      set((state) => {
        console.log(
          "%cClear Selected Files ",
          "background:white; color:black;"
        );
        state.selectedFiles = [];
      }),

    setSelectedMultipleFiles: (files: DoxleFile[]) =>
      set((state) => {
        console.log("Selected File: ");
        state.selectedFiles = files;
      }),

    currentLevel: "Company",

    setCurrentLevel: (level: FileLevel) => set({ currentLevel: level }),

    filterRootFolderQuery: {orderBy: ['folder_name']},
    setPartialFilterRootFolderQuery: (
      filter: Partial<IFilterGetFolderQueryFilter>
    ) =>
      set((state) => {
        state.filterRootFolderQuery = {
          ...state.filterRootFolderQuery,
          ...filter,
        };
      }),
    setWholeFilterRootFolderQuery: (
      filter: Partial<IFilterGetFolderQueryFilter>
    ) =>
      set((state) => {
        state.filterRootFolderQuery = filter;
      }),

    filterRootFileQuery: {orderBy: ['file_name']},
    setPartialFilterRootFileQuery: (
      filter: Partial<IFilterGetFileQueryFilter>
    ) =>
      set((state) => {
        state.filterRootFileQuery = {
          ...state.filterRootFileQuery,
          ...filter,
        };
      }),
    setWholeFilterRootFileQuery: (filter: Partial<IFilterGetFileQueryFilter>) =>
      set((state) => {
        state.filterRootFileQuery = filter;
      }),

    clearFileStore: () =>
      set((state) => {
        state.currentFolder = undefined;
        state.currentFile = undefined;

        state.selectedFiles = [];
        state.filterRootFolderQuery = {orderBy: ['folder_name']};
        state.filterRootFileQuery = {orderBy: ['file_name']};
      }),
  }))
);
