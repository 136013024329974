import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useDoxleAuthStore } from "../../../../DoxleGeneralStore/useDoxleAuthStore";
import { useDoxleThemeStore } from "../../../../DoxleGeneralStore/useDoxleThemeStore";
import { TNavBarMenu } from "../../../../DoxleGeneralStore/useDoxleCurrentContext";

type Props = {};

interface INavBarEllipsis {
  handlePressMenuItem: (nav: TNavBarMenu) => void;
  MAIN_MENU: TNavBarMenu[];
}
const useNavBarEllipsis = (props: Props): INavBarEllipsis => {
  const navigate = useNavigate();

  const logout = useDoxleAuthStore((state) => state.handleLogOut);
  const {
    currentTheme,
    setTheme,

    setIsGridMode,
    isGridMode,
  } = useDoxleThemeStore((state) => ({
    doxleThemeColor: state.doxleThemeColor,

    currentTheme: state.currentTheme,
    setTheme: state.setTheme,

    setIsGridMode: state.setIsGridMode,
    isGridMode: state.isGridMode,
  }));
  const handlePressMenuItem = (nav: TNavBarMenu) => {
    // if (item.text === "Xero Settings") {
    //   navigate("/Xero/");
    // }
    if (nav === "Our Story") {
      navigate("/OurStory/");
    } else if (nav === "Files") {
      navigate("/Files/");
    } else if (nav === "Budgets") {
      navigate("/Projects/");
    } else if (nav === "Pricebook") {
      navigate("/Pricebook/");
    } else if (nav === "Contacts") {
      navigate("/Contacts/");
    } else if (nav === "Inventory") {
      if (window.location.host.includes("doxle.com"))
        window.open("https://inventory.doxle.com/");
      else navigate("/Inventory/");
    } else if (nav === "Settings") {
      navigate("/Settings/");
    } else if (nav === "Log out") {
      logout();
    } else if (nav === "Theme")
      setTheme(currentTheme === "light" ? "dark" : "light");
    else if (nav === "Grid Mode") setIsGridMode(!isGridMode);
  };

  const MAIN_MENU: TNavBarMenu[] = useMemo(
    () => [
      "Budgets",
      "Pricebook",
      "Contacts",
      "Files",
      "Inventory",
      "Our Story",
      "Font",
      "Theme",
      "Grid Mode",
      "Settings",
      "Log out",
    ],
    []
  );
  return {
    handlePressMenuItem,
    MAIN_MENU,
  };
};

export default useNavBarEllipsis;
