import styled from "styled-components";
import {
  DoxleFont,
  DoxleTheme,
  DoxleThemeColor,
} from "../../../../DoxleGeneralStore/useDoxleThemeStore";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer, {
  TableContainerProps,
} from "@mui/material/TableContainer";
import TableRow, { TableRowProps } from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import { editRgbaAlpha } from "../../../../Utilities/FunctionUtilities";
import { motion } from "framer-motion";

export const StyledPaymentClaimListContainer = styled(motion.div)<{
  $themeColor: DoxleThemeColor;
}>`
  width: calc(100%);
  flex: 1;

  display: flex;
  flex-direction: column;

  background-color: ${(p) => p.$themeColor.primaryBackgroundColor};

  .table-wrapper {
    flex: 1;
    width: 100%;
    display: flex;
    position: relative;
  }
`;
export const StyledPaymentClaimListTitle = styled(Typography)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  && {
    color: ${(p) => p.$themeColor.primaryFontColor};
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-size: 1.8rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.09rem;
    margin-bottom: 1.4rem;
    margin-left: 2.8rem;
    align-self: flex-start;
  }
`;

export const StyledPaymentClaimTable = styled(Table)`
  && {
    width: 100%;
    height: 100%;
  }
`;
export const StyledPaymentClaimTableBody = styled(TableBody)`
  && {
    width: 100% !important;
    min-width: 40rem !important;
  }
`;
export const StyledPaymentClaimTableScroller = styled.div`
  width: 100%;
  height: 100%;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

export const StyledPaymentClaimTableContainer = styled(
  (props: TableContainerProps) => <TableContainer {...props} />
)`
  &.MuiTableContainer-root {
    width: 100% !important;
    height: 100% !important;
    min-width: 40rem !important;
  }
`;
export const StyledPaymentClaimTableRow = styled((props: TableRowProps) => (
  <TableRow {...props} />
))`
  && {
    padding-bottom: 0.4rem;
    width: 100%;
    display: flex;
    align-items: center;
  }
`;
export const StyledPaymentClaimTableHead = styled(TableHead)`
  && {
    width: 100% !important;
  }
`;
export const StylePaymentClaimTableHeaderRow = styled(TableRow)<{
  $doxleFont: DoxleFont;
  $themeColor: DoxleThemeColor;
}>`
  && {
    font-family: ${(p) => p.$doxleFont.primaryTitleFont};
    color: ${(p) => p.$themeColor.primaryFontColor};
    background-color: ${(p) => p.$themeColor.primaryBackgroundColor};
    letter-spacing: 0.5px;
    font-size: 1rem;
    width: calc(100% - 8px) !important;
    padding: 0px 4px 0.4rem 4px !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    min-width: 300px !important;
  }
`;
export const StyledPaymentClaimTableHeaderItems = styled(TableCell)<{
  $doxleFont: DoxleFont;
  $themeColor: DoxleThemeColor;
  $width: `${number}%` | `${number}rem` | null;
  $horizontalAlign: "flex-start" | "center" | "flex-end";
}>`
  && {
    ${(p) => (p.$width ? `width: calc(${p.$width} - 4px);` : "flex:1;")}
    margin: 0px 2px;
    padding: 0px !important;

    letter-spacing: 0.05rem;
    border: none !important;
    display: flex;
    overflow: hidden;
    align-items: center;
    justify-content: ${(p) => p.$horizontalAlign};
    text-overflow: ellipsis;
    white-space: nowrap;
    text-transform: uppercase;

    span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 100%;
      font-family: ${(p) => p.$doxleFont.primaryTitleFont};
      font-size: 1rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      color: ${(p) =>
        editRgbaAlpha({
          rgbaColor: p.$themeColor.primaryFontColor,
          alpha: "0.7",
        })};
    }
  }
`;

export const StyledPaymentClaimItemRow = styled(TableCell)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
  $currentTheme: DoxleTheme;
  $transparentMode: boolean;
}>`
 && { width: calc(100% - 8px);
  padding: 0px 4px !important;
  display: flex;

  align-items: center;
  height: 4rem;
  border-radius: 0px 0px 0px 0.9rem;
  border: none !important;
 
  cursor: pointer;
  
  &:hover {
    background-color: ${(p) => p.$themeColor.rowHoverColor};
    transition: 0.4s;
  }

  background-color: ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.$themeColor.primaryContainerColor,
        alpha: p.$transparentMode
          ? "0"
          : p.$currentTheme === "light"
          ? "0.3"
          : "0.5",
      })};
 

`;
export const StyledPaymentClaimCell = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
  $width: `${number}%` | `${number}rem` | null;
  $horizontalAlign: "flex-start" | "center" | "flex-end";
  $fullScreen?: boolean
}>`
  ${(p) => (p.$width ? `width:calc(${p.$width} - 4px);` : "flex:1;")}
  display: flex;
  margin: 0px 2px;
  justify-content: ${(p) => p.$horizontalAlign};
  align-items: center;
  color: ${(p) => p.$themeColor.primaryFontColor};
  height: 100%;
  font-family: ${(p) => p.$doxleFont.primaryFont};
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 500;
  line-height: 118.9%;
  letter-spacing: -0.028rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  .order-number {
    color: ${(p) => p.$themeColor.doxleColor};

    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 500;
    line-height: 118.9%;
    letter-spacing: -0.028rem;
    text-transform: uppercase;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .invoice-number {
    color: ${(p) => p.$themeColor.primaryFontColor};

    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 500;
    line-height: 118.9%;
    letter-spacing: -0.028rem;
    text-transform: uppercase;
    max-width: 100%%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .general-text {
    color: ${(p) => p.$themeColor.primaryFontColor};

    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 500;
    line-height: 118.9%;
    letter-spacing: -0.028rem;
    text-transform: uppercase;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;
