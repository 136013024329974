import React, { useCallback, useMemo, useRef } from "react";
import {
  VirtuosoHandle,
  Virtuoso,
  Components,
  ItemProps,
  ItemContent,
} from "react-virtuoso";
import DrawingsRow from "./DrawingsRow";
import { DrawingSet } from "../Models/drawings";
import {
  StyledDrawingListScroller,
  StyledDrawingsContainer,
} from "./DrawingsStyledComponent";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import useGetDrawingList from "../Hooks/useGetDrawingList";
import ListLoadingMore from "../../Utilities/Lottie/ListLoadingMore";
import DrawingDeleteConfirmDialog from "./DrawingDeleteConfirmDialog";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "@hello-pangea/dnd";
import { useDoxleCurrentContextStore } from "../../DoxleGeneralStore/useDoxleCurrentContext";
import useDoxleNotificationStore from "../../DoxleGeneralStore/useDoxleNotificationStore";
import DrawingQueryAPI from "../QueryHooks/drawingQueryAPI";
import useSetDrawingsQueryData from "../QueryHooks/useSetDrawingsQueryData";
import { motion } from "framer-motion";
import DrawingsSkeleton from "./DrawingsSkeleton";
import DoxleEmptyPlaceHolder from "../../DoxleDesignPattern/DoxleEmptyPlaceHolder/DoxleEmptyPlaceHolder";
import { Error404Banner } from "../../DoxleDesignPattern/DoxleIcons/CommonIcons";
import { EmptyDrawingBanner } from "./DrawingsIcon";
window.addEventListener("error", (e) => {
  if (
    e.message ===
      "ResizeObserver loop completed with undelivered notifications." ||
    e.message === "ResizeObserver loop limit exceeded"
  ) {
    e.stopImmediatePropagation();
  }
});

type Props = {};
interface DrawingListContext {
  isErrorFetchingDrawingList: boolean;
}
function Drawings({}: Props) {
  // const [drawings, setDrawings] = useState<DrawingSet[]>([...drawingsList]);

  const drawingsRef = useRef<VirtuosoHandle>(null);
  const { doxleThemeColor } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
      currentTheme: state.currentTheme,
    }),
    shallow
  );
  const { company, selectedProject } = useDoxleCurrentContextStore(
    (state) => ({
      company: state.currentCompany,
      selectedProject: state.currentProject,
    }),
    shallow
  );

  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );
  const {
    drawingsList,
    isFetchingDrawingList,
    isErrorFetchingDrawingList,
    isFetchingNextPageDrawingList,
    hasNextPage,
    handleFetchNextPageDrawing,
  } = useGetDrawingList({});
  const updateIdxDrawings = DrawingQueryAPI.useUpdateIndexDrawingQuery({
    showNotification,
    company,
    projectId: selectedProject?.projectId || "",
  });
  const HeightPreservingItem = useCallback(
    ({ children, ...props }: ItemProps<DrawingSet>) => {
      const knownSize = props["data-known-size"];

      const style: any = {
        "--child-height": `${knownSize}px`,
      };
      return (
        <motion.div
          {...props}
          layout="position"
          className="height-preserving-container"
          style={style}
        >
          {children}
        </motion.div>
      );
    },
    []
  );
  const components: Components<DrawingSet, DrawingListContext> = useMemo(
    () => ({
      Scroller: React.forwardRef((props, ref) => (
        <StyledDrawingListScroller {...props} ref={ref} />
      )),

      Item: HeightPreservingItem,
      EmptyPlaceholder: (props) => (
        <DoxleEmptyPlaceHolder
          headTitleText={
            props.context?.isErrorFetchingDrawingList
              ? "Something wrong"
              : "No Drawings"
          }
          subTitleText={
            props.context?.isErrorFetchingDrawingList
              ? "Please try to refresh page, we are sorry for this!"
              : "Add more drawings to work..."
          }
          containerBgColor="transparent"
          illustration={
            props.context?.isErrorFetchingDrawingList ? (
              <Error404Banner
                themeColor={doxleThemeColor}
                containerStyle={{ width: "30%" }}
              />
            ) : (
              <EmptyDrawingBanner
                themeColor={doxleThemeColor}
                containerStyle={{ width: "30%" }}
              />
            )
          }
        />
      ),
    }),
    []
  );

  const itemContent: ItemContent<DrawingSet, DrawingListContext> = useCallback(
    (index: number, item: DrawingSet) => (
      <Draggable key={item.setId} draggableId={item.setId!} index={index}>
        {(providedDrag) => (
          <DrawingsRow drawing={item} provided={providedDrag} />
        )}
      </Draggable>
    ),
    []
  );

  const listStyle: React.CSSProperties = useMemo(
    () => ({
      width: "100%",
      height: "100%",
    }),
    []
  );
  const { handleUpdateDrawingIndex } = useSetDrawingsQueryData({
    projectId: selectedProject?.projectId || "",
  });
  const handleOnDragEnd = useCallback(
    (result: DropResult) => {
      if (!result.destination) {
        return;
      }

      if (result.source.index === result.destination.index) {
        return;
      }

      let start = result.source.index;
      let end = result.destination?.index;

      if (end !== undefined) {
        const itemId = result.draggableId;
        const item = drawingsList.find((item) => item.setId === itemId);
        // if (item) {
        //   handleUpdateDrawingIndex({
        //     item: { ...item, index: end },
        //     initIdx: start,
        //     destIdx: end,
        //   });
        // }
        if (item) {
          handleUpdateDrawingIndex({
            item: { ...item, index: end },
            initIdx: start,
            destIdx: end,
          });
          updateIdxDrawings.mutate({
            drawingItem: item,
            endIndex: end,
            startIndex: start,
          });
        }
      }
    },
    [drawingsList]
  );

  return (
    <StyledDrawingsContainer
      initial={{
        x: -20,
        opacity: 0,
      }}
      animate={{
        x: 0,
        opacity: 1,
      }}
    >
      {isFetchingDrawingList && <DrawingsSkeleton />}
      {!isFetchingDrawingList && (
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable
            droppableId="droppable"
            mode="virtual"
            renderClone={(provided, snapshot, rubric) => {
              const drawing = drawingsList[rubric.source.index];

              return (
                // <DrawingsRow
                //   drawing={drawing}
                //   key={drawing.setId}
                //   index={rubric.source.index}
                // />
                <DrawingsRow
                  drawing={drawing}
                  provided={provided}
                  isClone={snapshot.isClone}
                />
              );
            }}
          >
            {(providedDrop) => {
              return (
                <Virtuoso
                  style={listStyle}
                  data={drawingsList}
                  ref={drawingsRef}
                  context={{ isErrorFetchingDrawingList }}
                  components={components}
                  scrollerRef={providedDrop.innerRef as any}
                  endReached={() => {
                    if (hasNextPage) handleFetchNextPageDrawing();
                  }}
                  itemContent={itemContent}
                />
              );
            }}
          </Droppable>
        </DragDropContext>
      )}
      {isFetchingNextPageDrawingList && (
        <ListLoadingMore
          containerStyle={{
            width: "100%",
            bottom: 0,
            left: 0,
            position: "absolute",
            zIndex: 10,
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        />
      )}

      <DrawingDeleteConfirmDialog />
    </StyledDrawingsContainer>
  );
}

export default React.memo(Drawings);
