import { StateCreator, create } from "zustand";
import { FilterGetDiscussionQuery } from "../../../Services/QueryHooks/discussionsAPI";
import {
  Docket,
  IFullDocketDetailQueryFilterProp,
  LightDocket,
} from "../../../Models/dockets";
import { produce } from "immer";
import { MouseEvent } from "react";
import { TRgbaFormat } from "../../../Utilities/FunctionUtilities";

interface IEditDocketBtn {
  btnFunction: (e: MouseEvent<HTMLDivElement | HTMLButtonElement>) => void;
  btnText?: string;
  showBtnIcon?: false | "start" | "end";
  btnIcon?: string | React.ReactNode;
  btnBaseColor?: TRgbaFormat;
  tooltipText?: string;
  isLoading?: boolean;
}

interface IEditDocketQuoteBtn {
  btnFunction: Function;
  btnText: string;
  disable?: boolean;
  isProcessing?: boolean;
}
interface IEditDocketOrderBtn {
  btnFunction: (e: MouseEvent<HTMLDivElement | HTMLButtonElement>) => void;
  btnText: string;

  isLoading?: boolean;
  btnIcon?: React.ReactNode;
  btnStyle?: React.CSSProperties;
}
export type EditDocketFunctionMenuType =
  // (typeof EDIT_DOCKET_FUNCTION_MENU_TYPES)[number];
  | "Detail"
  | "Schedule"
  | "Budget"
  | "Quotes"
  | "Orders"
  | "Payments"
  | "Invoices"
  | "Notes"
  | "Scope Of Works"
  | "Files"
  | "Mail"
  | "Bookings"
  | "Pricebook"
  | "Contacts"
  | "Comments";
export const EDIT_DOCKET_FUNCTION_MENU_TYPES: EditDocketFunctionMenuType[] = [
  "Detail",
  "Schedule",
  "Budget",
  "Quotes",
  "Orders",
  "Payments",
  "Invoices",
  "Notes",
  "Scope Of Works",
  "Files",
  "Mail",
  "Bookings",
  "Pricebook",
  "Contacts",
];
export interface IEditDocketMenuTab {
  tabName: EditDocketFunctionMenuType;
  display: boolean;
}

const InboxDocketFunctions: IEditDocketMenuTab[] = [
  {
    tabName: "Detail",
    display: true,
  },

  {
    tabName: "Files",
    display: true,
  },
  {
    tabName: "Scope Of Works",
    display: true,
  },
  {
    tabName: "Mail",
    display: true,
  },
  {
    tabName: "Notes",
    display: true,
  },
  {
    tabName: "Comments",
    display: true,
  },
  // "Contacts",
];
const BudgetDocketFunctions: IEditDocketMenuTab[] = [
  {
    tabName: "Detail",
    display: true,
  },

  {
    tabName: "Files",
    display: true,
  },
  {
    tabName: "Scope Of Works",
    display: true,
  },
  {
    tabName: "Quotes",
    display: true,
  },
  {
    tabName: "Budget",
    display: true,
  },
  {
    tabName: "Orders",
    display: true,
  },
  {
    tabName: "Payments",
    display: true,
  },
  {
    tabName: "Bookings",
    display: true,
  },
  {
    tabName: "Mail",
    display: true,
  },
  {
    tabName: "Pricebook",
    display: true,
  },
  {
    tabName: "Contacts",
    display: true,
  },
  {
    tabName: "Notes",
    display: true,
  },
  {
    tabName: "Comments",
    display: true,
  },
];

interface EditDocketSideScreenStore {
  //!COMPULSORY TO SYNC DATA BETWEEN THE LIST AND THE SIDE SCREEN WHEN DATA CHANGE
  initialEditedDocket: LightDocket | undefined;
  setInitialEditedDocket: (item: LightDocket | undefined) => void;

  edittedDocket: Docket | undefined;
  setEdittedDocket: (item: Docket | undefined) => void;
  filterDocketListQuery: IFullDocketDetailQueryFilterProp;
  setFilterDocketListQuery: (item: IFullDocketDetailQueryFilterProp) => void;

  handlePostUpdateDocket: ((newDocket: Docket) => void) | undefined;
  setHandlePostUpdateDocket: (
    func: ((newDocket: Docket) => void) | undefined
  ) => void;
  updateEdittedDocket: (updateData: Partial<Docket>) => void;
  //!<-----

  //--> fullscreen toggle
  isFullScreenMode: boolean;
  setIsFullScreenMode: (value: boolean) => void;
  //<----

  selectedFunctionMenu: EditDocketFunctionMenuType;
  setSelectedFunctionMenu: (item: EditDocketFunctionMenuType) => void;

  docketFunctionMenu: IEditDocketMenuTab[];
  setDocketFunctionMenu: (list: IEditDocketMenuTab[]) => void;
  toggleDisplayFunctionMenu: (item: IEditDocketMenuTab, value: boolean) => void;

  //---> discussion section
  filterDiscussionList: FilterGetDiscussionQuery;
  setFilterDiscussionList: (item: FilterGetDiscussionQuery) => void;
  //<----g

  displayShareTab: boolean;
  setDisplayShareTab: (bool: boolean) => void;

  resetStore: () => void;
}

interface EditDocketExternalFunctionStore {
  //*----> for add btn function <-----
  handleAddButtonFn: IEditDocketBtn[];
  setHandleAddButtonFn: (func: IEditDocketBtn[]) => void;
  //*<------------------------>

  //*----> for close btn function <-----
  handleCloseButtonFn: IEditDocketBtn | undefined;
  setHandleCloseButtonFn: (func: IEditDocketBtn | undefined) => void;
  //*<------------------------>

  handleDeleteManyButtonFn: IEditDocketBtn | undefined;
  setHandleDeleteManyButtonFn: (func: IEditDocketBtn | undefined) => void;

  //*----> for quote tab <----
  quoteDeleteBtnFn: IEditDocketQuoteBtn | undefined;
  setQuoteDeleteBtnFn: (fn: IEditDocketQuoteBtn | undefined) => void;

  emailQuoteBtnFn: IEditDocketQuoteBtn | undefined;
  setEmailQuoteBtnFn: (fn: IEditDocketQuoteBtn | undefined) => void;
  //*<------------------------>

  //*----> for order tab <----
  orderActionBtns: IEditDocketOrderBtn[];
  setOrderActionBtns: (fn: IEditDocketOrderBtn[]) => void;
  //*<------------------------>
}
const createEditDocketSideScreenStore: StateCreator<
  EditDocketSideScreenStore & EditDocketExternalFunctionStore,
  [["zustand/immer", never], never],
  [],
  EditDocketSideScreenStore
> = (set, get) => ({
  //* ----> DOCKET VARiATIONS <----
  initialEditedDocket: undefined,
  setInitialEditedDocket: (item: LightDocket | undefined) =>
    set({
      initialEditedDocket: item,
      docketFunctionMenu:
        item?.isIncome || item?.isExpense || item?.isBudget || item?.isVariation
          ? BudgetDocketFunctions
          : InboxDocketFunctions,
    }),

  edittedDocket: undefined,
  setEdittedDocket: (item: Docket | undefined) =>
    set({
      edittedDocket: item,
    }),

  handlePostUpdateDocket: undefined as
    | ((newDocket?: Docket) => void)
    | undefined,
  setHandlePostUpdateDocket: (
    func: ((newDocket: Docket) => void) | undefined
  ) =>
    set({
      handlePostUpdateDocket: func,
    }),
  updateEdittedDocket: (updateData: Partial<Docket>) =>
    set((state) =>
      produce(state, (draft) => {
        const item = draft.edittedDocket;
        if (item) Object.assign(item, updateData);

        return draft;
      })
    ),
  filterDocketListQuery: {
    view: window.location.href.toLowerCase().includes("noticeboard")
      ? "noticeboard"
      : "budget",
  },
  setFilterDocketListQuery: (item: Partial<IFullDocketDetailQueryFilterProp>) =>
    set((state) =>
      produce(state, (draft) => {
        const updateItem = draft.filterDocketListQuery;
        Object.assign(updateItem, item);
        return draft;
      })
    ),

  //*<---------------------------
  selectedFunctionMenu: "Detail",
  setSelectedFunctionMenu: (item: EditDocketFunctionMenuType) => {
    set({
      selectedFunctionMenu: item,
      displayShareTab: false,
    });
  },
  docketFunctionMenu: InboxDocketFunctions,
  setDocketFunctionMenu: (list: IEditDocketMenuTab[]) =>
    set({ docketFunctionMenu: list }),
  toggleDisplayFunctionMenu: (item: IEditDocketMenuTab, value: boolean) =>
    set((state) =>
      produce(state, (draft) => {
        const matchedItem = draft.docketFunctionMenu.find(
          (menu) => menu.tabName === item.tabName
        );
        if (matchedItem) matchedItem.display = value;

        return draft;
      })
    ),

  filterDiscussionList: {},
  setFilterDiscussionList: (item: FilterGetDiscussionQuery) =>
    set({ filterDiscussionList: item }),

  displayShareTab: false,
  setDisplayShareTab: (bool: boolean) => set({ displayShareTab: bool }),
  isFullScreenMode: true,
  setIsFullScreenMode: (value: boolean) => set({ isFullScreenMode: value }),

  resetStore: () =>
    set((state) => ({
      ...state,
      edittedDocket: undefined,
      initialEditedDocket: undefined,
      selectedFunctionMenu: "Detail",
      filterDiscussionList: {},
      filterDocketListQuery: {},
      onSelectMultipleMode: false,
      selectedScopeImages: [],
      isFullScreenMode: false,
      displayShareTab: false,

      handleAddButtonFn: undefined,
      handlePostUpdateDocket: undefined,
    })),
});

const createEditDocketExternalFunctionStore: StateCreator<
  EditDocketExternalFunctionStore & EditDocketSideScreenStore,
  [["zustand/immer", never], never],
  [],
  EditDocketExternalFunctionStore
> = (set, get) => ({
  //*----> for add btn function <-----
  handleAddButtonFn: [],
  setHandleAddButtonFn: (func: IEditDocketBtn[]) =>
    set((state) =>
      produce(state, (draft) => {
        draft.handleAddButtonFn = func;

        return draft;
      })
    ),
  //*<------------------------>

  //*----> for close btn function <-----
  handleCloseButtonFn: undefined,
  setHandleCloseButtonFn: (func: IEditDocketBtn | undefined) =>
    set((state) =>
      produce(state, (draft) => {
        draft.handleCloseButtonFn = func;

        return draft;
      })
    ),

  //*<------------------------>

  handleDeleteManyButtonFn: undefined,
  setHandleDeleteManyButtonFn: (func: IEditDocketBtn | undefined) =>
    set((state) =>
      produce(state, (draft) => {
        draft.handleDeleteManyButtonFn = func;

        return draft;
      })
    ),

  //*----> for quote tab <----
  quoteDeleteBtnFn: undefined,
  setQuoteDeleteBtnFn: (fn: IEditDocketQuoteBtn | undefined) =>
    set((state) =>
      produce(state, (draft) => {
        draft.quoteDeleteBtnFn = fn;

        return draft;
      })
    ),

  emailQuoteBtnFn: undefined,
  setEmailQuoteBtnFn: (fn: IEditDocketQuoteBtn | undefined) =>
    set((state) =>
      produce(state, (draft) => {
        draft.emailQuoteBtnFn = fn;

        return draft;
      })
    ),
  //*<------------------------>

  //*----> for order tab <----
  orderActionBtns: [] as IEditDocketOrderBtn[],
  setOrderActionBtns: (fn: IEditDocketOrderBtn[]) =>
    set((state) =>
      produce(state, (draft) => {
        draft.orderActionBtns = fn;

        return draft;
      })
    ),
  //*<------------------------>
});

export const useEditDocketSideScreenStore = create<
  EditDocketSideScreenStore & EditDocketExternalFunctionStore
>()((...a) => ({
  ...createEditDocketSideScreenStore(...a),
  ...createEditDocketExternalFunctionStore(...a),
}));
