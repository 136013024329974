import React, { useCallback, useEffect, useMemo } from "react";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import useDoxleSelectLocationDropdown from "./Hooks/useDoxleSelectLocationDropdown";
import Popover, { PopoverProps } from "@mui/material/Popover";
import {
  StyledAddContactButton,
  StyledContactListScroller,
  StyledContactPopover,
  StyledSearchContactTextfield,
  StyledSearchContactWrapper,
} from "./StyledDoxleSelectLocationDropdown";
import AddLocationForm from "./AddLocationForm";
import ListLoadingMore from "../../Utilities/Lottie/ListLoadingMore";
import { Components, ItemContent, Virtuoso } from "react-virtuoso";
import DoxleEmptyPlaceHolder from "../DoxleEmptyPlaceHolder/DoxleEmptyPlaceHolder";
import LocationItem from "./LocationItem";
import { Error404Banner } from "../DoxleIcons/CommonIcons";
import { EmptyContactListBanner } from "./Icons";
import { PopupState as IPopupState } from "material-ui-popup-state/hooks";
import { useShallow } from "zustand/react/shallow";
import {Floor, Room, RoomFilters} from "../../QA/Models/locations";
//! PLEASE DONT EDIT THIS COMPONENTS BY ANY CHANCE!!!!! ASK PETER FOR THE UPDATE TO ENSURE STABILITY

export type TContactPopoverWithAnchor = {
  //! COMPOSULRY PROPS TO SET ANCHOR FOR THE POPOVER
  popoverAnchor: HTMLElement | null;

  // --- overwrite popover props
  popoverProps?: Omit<PopoverProps, "id" | "open" | "anchorEl" | "onClose">;
};
export type TContactPopoverWithPopoverState = Omit<
  PopoverProps,
  keyof IPopupState
> & {
  popupState: IPopupState; //!COMPULSORY TO USE THE popupState
};

export type TContactPopoverGeneralCustom = {
  // //! COMPOSULRY PROPS TO SET ANCHOR FOR THE POPOVER
  // popoverAnchor: HTMLElement | null;

  onClosePopover?: () => void;
  variant?: "room"|"floor";
  onSelectRoom?: (item: Room) => void;

  onSelectFloor?: (item: Floor) => void;
  onClearRoom?: () => void;
  onClearFloor?: () => void;

  selectedRoomId?: string|null;
  selectedFloorId?: string|null;

  closeOnSelect?: boolean;
  containerStyle?: React.CSSProperties;

  floorFilter?: string;
};
type Props = TContactPopoverWithAnchor & TContactPopoverGeneralCustom;

interface LocationListContext {
  isFetchingRoomList: boolean;
  isErrorFetchingRoomList: boolean;
  mode: "room"|"floor";
  isErrorFetchingFloorList: boolean;
  isFetchingFloorList: boolean;
}

const DoxleSelectLocationDropdown = ({
  popoverAnchor,
  onClosePopover,
  onSelectRoom,
  onSelectFloor,
  variant = "room",
  popoverProps,
  selectedRoomId,
  selectedFloorId,
  closeOnSelect = true,
  containerStyle,
  onClearFloor,
  onClearRoom,
  floorFilter
}: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    useShallow((state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }))
  );

  const {
    mode,
    showAddLocationForm,
    setShowAddLocationForm,
    showLocationList,
    setShowLocationList,
    floorList,
    isFetchingFloorList,
    isErrorFetchingFloorList,
    searchText,
    setSearchText,
    roomList,
    isFetchingRoomList,
    isErrorFetchingRoomList,
    handleSelectFloor,
    handleSelectRoom,
    handleFetchNextPage,
  } = useDoxleSelectLocationDropdown({
    variant,
    onClosePopover,
    onSelectFloor,
    onSelectRoom,
    closeOnSelect,
    floorFilter
  });
  const canBeOpen = Boolean(popoverAnchor);
  const id = canBeOpen ? "contact-menu-popper" : undefined;

  //* render list
  const components: Components<Floor | Room, LocationListContext> =
    useMemo(
      () => ({
        Scroller: React.forwardRef((props, ref) => (
          <StyledContactListScroller
            ref={ref}
            {...props}
            animate={{ y: [10, 0], opacity: [0, 1] }}
          />
        )),
        EmptyPlaceholder: (props) => (
          <DoxleEmptyPlaceHolder
            headTitleText={
              props.context?.isErrorFetchingFloorList ||
              props.context?.isErrorFetchingRoomList
                ? "Something wrong!"
                : `No locations found!`
            }
            subTitleText={
              props.context?.isErrorFetchingFloorList ||
              props.context?.isErrorFetchingRoomList
                ? "Failed to get locations, we are sorry for this!"
                : "Add more locations to your list..."
            }
            containerBgColor="transparent"
            headTitleTextStyle={{
              fontSize: "20px",
            }}
            subTitleTextStyle={{
              fontSize: "14px",
            }}
            illustration={
              props.context?.isErrorFetchingFloorList ||
              props.context?.isErrorFetchingRoomList ? (
                <Error404Banner
                  themeColor={doxleThemeColor}
                  containerStyle={{
                    width: "50%",
                    marginBottom: 14,
                  }}
                />
              ) : (
                <EmptyContactListBanner
                  themeColor={doxleThemeColor}
                  containerStyle={{
                    width: "50%",
                    marginBottom: 14,
                  }}
                />
              )
            }
          />
        ),
      }),
      [doxleThemeColor]
    );

  const itemContent: ItemContent<Floor | Room, LocationListContext> =
    useCallback(
      (_index, item, context) => {
        if (context.mode === "floor" && "floorId" in item)
          return (
            <LocationItem
              key={`contractor_${item.floorId}`}
              floor={item}
              selected={selectedFloorId === item.floorId}
              onSelect={handleSelectFloor}
              variant="floor"
              onClearSelected={onClearFloor}
            />
          );
        else if (context.mode === "room" && "roomId" in item)
          return (
            <LocationItem
              key={`contractor_${item.roomId}`}
              room={item}
              selected={selectedRoomId === item.roomId}
              onSelect={handleSelectRoom}
              variant="room"
              onClearSelected={onClearRoom}
            />
          );
      },
      [
        selectedFloorId,
        selectedRoomId,
        handleSelectFloor,
        handleSelectRoom,
        onClearFloor,
        onClearRoom
      ]
    );

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (popoverAnchor) setShowLocationList(true);
      else setShowLocationList(false);
    }, 300);

    return () => clearTimeout(timeout);
  }, [popoverAnchor]);
  const listStyle = {
    flex: 1,
    width: "100%",
  };
  return (
    <Popover
      id={id}
      open={canBeOpen}
      anchorEl={popoverAnchor}
      disableScrollLock
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      onClose={() => {
        setSearchText("");
        if (onClosePopover) onClosePopover();
      }}
      sx={{
        "& .MuiPopover-paper": {
          backgroundColor: "transparent",
          overflow: "visible",
          boxShadow: "none",
        },
      }}
      transitionDuration={{ appear: 100, exit: 200 }}
      elevation={4}
      {...popoverProps}
    >
      <StyledContactPopover
        $themeColor={doxleThemeColor}
        style={containerStyle}
      >
        <StyledSearchContactWrapper
          $themeColor={doxleThemeColor}
          $doxleFont={doxleFont}
        >
          {!showAddLocationForm ? (
            <StyledSearchContactTextfield
              variant="standard"
              value={searchText}
              // autoFocus
              onChange={(e) => setSearchText(e.target.value)}
              placeholder={`Search ${mode}...`}
            />
          ) : (
            <span className="form-title">Add {mode}</span>
          )}

          <StyledAddContactButton
            onClick={() => {
              setShowAddLocationForm((prev) => !prev);
              setSearchText("");
            }}
          >
            {!showAddLocationForm ? `Add ${mode}` : "Close"}
          </StyledAddContactButton>
        </StyledSearchContactWrapper>

        {!showAddLocationForm && (
          <>
            {showLocationList && (
              <Virtuoso
                style={listStyle}
                itemContent={itemContent}
                data={mode === "floor" ? floorList : roomList}
                components={components}
                context={{
                  isErrorFetchingFloorList,
                  isFetchingFloorList,
                  mode,
                  isErrorFetchingRoomList,
                  isFetchingRoomList,
                }}
                endReached={handleFetchNextPage}
                atBottomThreshold={40}
              />
            )}
          </>
        )}

        {showAddLocationForm && (
          <AddLocationForm
            handleCloseForm={() => setShowAddLocationForm(false)}
            variant={mode}
            floorFilter={floorFilter}
          />
        )}

        {isErrorFetchingFloorList ||
          (isErrorFetchingRoomList && (
            <ListLoadingMore
              animationSize={50}
              containerStyle={{
                bottom: 0,
                left: "50%",
                translate: "-50%",
                display: "flex",
                justifyContent: "center",
                position: "absolute",
                zIndex: 10,
              }}
            />
          ))}
      </StyledContactPopover>
    </Popover>
  );
};

export default DoxleSelectLocationDropdown;
