import React, { useEffect, useMemo, useState } from "react";
import { shallow } from "zustand/shallow";
import ProjectQueryAPI from "../../../Services/QueryHooks/projectQueryAPI";
import { useDoxleCurrentContextStore } from "../../../DoxleGeneralStore/useDoxleCurrentContext";
import useDoxleNotificationStore from "../../../DoxleGeneralStore/useDoxleNotificationStore";
import { Project } from "../../../Models/project";
import { useNavigate } from "react-router-dom";
import { useShallow } from "zustand/react/shallow";
import useThrottlingSearch from "../../UtilityHooks/useThrottlingSearch";

type Props = {};

interface ProjectDropdownList {
  isFetchingProject: boolean;
  isSuccessFetchingProject: boolean;
  isErrorFetchingProject: boolean;
  projectList: Project[];
  hasNextPageProject: boolean;
  handleFetchNextPageProject: () => void;
  searchProjectInputText: string;
  setSearchProjectInputText: React.Dispatch<React.SetStateAction<string>>;
  isFetchingNextPage: boolean;
  handleClickAddProject: () => void;

  showSearchInput: boolean;
  setshowSearchInput: React.Dispatch<React.SetStateAction<boolean>>;
}
const useProjectDropdownList = (props: Props): ProjectDropdownList => {
  const [searchProjectInputText, setSearchProjectInputText] =
    useState<string>("");
  const [showSearchInput, setshowSearchInput] = useState(false);
  const showNotification = useDoxleNotificationStore(
    useShallow((state) => state.showNotification)
  );

  const { currentCompany } = useDoxleCurrentContextStore(
    useShallow((state) => ({
      currentProject: state.currentProject,
      currentCompany: state.currentCompany,
    }))
  );
  const { searchThrottleValue } = useThrottlingSearch({
    controlledValue: searchProjectInputText,
    delayTime: 300,
  });
  const projectQuery = ProjectQueryAPI.useRetrieveFullProjectListQuery({
    enable: true,
    company: currentCompany,
    showNotification: showNotification,
    filter: { searchText: searchThrottleValue },
  });

  const projectList: Project[] = useMemo(() => {
    if (projectQuery.isSuccess) {
      const projects = projectQuery.data?.pages.reduce((acc, data) => {
        return acc.concat(data.data.results);
      }, [] as Project[]);
      //active stays on top
      projects.sort((a, b) => {
        if (a.statusName === "Active" && b.statusName !== "Active") {
          return -1;
        }
        return 1;
      });
      return projects;
    }
    return [];
  }, [projectQuery.data?.pages]);

  const handleFetchNextPageProject = () => {
    if (projectQuery.hasNextPage) projectQuery.fetchNextPage();
  };
  const navigate = useNavigate();
  const handleClickAddProject = () => {
    navigate("/AddProject/");
  };

  return {
    isFetchingProject: projectQuery.isLoading,
    isSuccessFetchingProject: projectQuery.isSuccess,
    isErrorFetchingProject: projectQuery.isError,

    projectList,
    hasNextPageProject: Boolean(projectQuery.hasNextPage),
    handleFetchNextPageProject,
    searchProjectInputText,
    setSearchProjectInputText,
    isFetchingNextPage: projectQuery.isFetchingNextPage,
    handleClickAddProject,

    showSearchInput,
    setshowSearchInput,
  };
};

export default useProjectDropdownList;
