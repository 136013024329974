import React from "react";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";

import animationData from "../Asset/booking.json";
import { editRgbaAlpha } from "../../Utilities/FunctionUtilities";
import { useLottie } from "lottie-react";
import { StyledAddBookingLoaderContainer } from "./StyledComponentBookings";
type Props = {
  loadingText?: string;
  animationSize?: number;
};

const AddBookingLoader = ({ loadingText, animationSize = 300 }: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore((state) => ({
    doxleThemeColor: state.doxleThemeColor,
    doxleFont: state.doxleFont,
  }));
  const options = {
    animationData: animationData,
    loop: true,
    autoplay: true,
  };

  const { View } = useLottie(options, {
    width: `${animationSize / 10}rem`,

    justifyContent: "center",
    alignItems: "center",
  });
  return (
    <StyledAddBookingLoaderContainer
      $themeColor={doxleThemeColor}
      $doxleFont={doxleFont}
    >
      {View}
      <div className="loading-text">
        {loadingText ?? "Processing your booking, please wait ..."}
      </div>
    </StyledAddBookingLoaderContainer>
  );
};

export default AddBookingLoader;
