import React, { useCallback, useState } from "react";
import { useDoxleAuthStore } from "../../../../../DoxleGeneralStore/useDoxleAuthStore";
import { useDoxleCurrentContextStore } from "../../../../../DoxleGeneralStore/useDoxleCurrentContext";
import { useEditDocketSideScreenStore } from "../../../EditDocketSideScreenStore/useEditDocketSideScreenStore";
import { shallow } from "zustand/shallow";
import DocketQuery from "../../../../../Services/QueryHooks/docketQueryAPI";
import { Docket, DocketStatus } from "../../../../../Models/dockets";
import { TRgbaFormat } from "../../../../../Utilities/FunctionUtilities";
import useSetDocketDetailQueryData from "../../../../QueryDataHooks/SetQueryDataHooks/useSetDocketDetailQueryData";

type Props = { handleCloseStatusList: () => void };

interface EditDocketStatusPopover {
  docketStatusList: DocketStatus[];
  isFetchingStatusList: boolean;
  isSuccessFetchingStatusList: boolean;
  isErrorFetchingStatusList: boolean;
  showStatusList: boolean;
  setshowStatusList: React.Dispatch<React.SetStateAction<boolean>>;
  handleClickStatus: (statusItem: DocketStatus) => void;
}
const useEditDocketStatusPopover = ({
  handleCloseStatusList,
}: Props): EditDocketStatusPopover => {
  const [showStatusList, setshowStatusList] = useState(false);
  const { company } = useDoxleCurrentContextStore((state) => ({
    company: state.currentCompany,
  }));

  const {
    edittedDocket,
    updateEdittedDocket,
    filterDocketListQuery,
    handlePostUpdateDocket,
  } = useEditDocketSideScreenStore(
    (state) => ({
      edittedDocket: state.edittedDocket,
      updateEdittedDocket: state.updateEdittedDocket,
      filterDocketListQuery: state.filterDocketListQuery,
      handlePostUpdateDocket: state.handlePostUpdateDocket,
    }),
    shallow
  );
  const docketStatusListQuery = DocketQuery.useRetrieveDocketStatusList({
    company,

    filter: {
      isBudgetStatus:
        filterDocketListQuery?.view === "budget" ||
        filterDocketListQuery?.budget !== undefined,
      isNoticeBoardStatus: filterDocketListQuery?.view === "noticeboard",
    },
  });

  const docketStatusList = docketStatusListQuery.isSuccess
    ? (docketStatusListQuery.data.data as DocketStatus[])
    : [];
  const { handleUpdateDocket } = useSetDocketDetailQueryData({
    docketPk: edittedDocket?.docketPk ?? "",
  });
  const onSuccessUpdate = (newDocket?: Docket) => {
    if (newDocket) {
      if (handlePostUpdateDocket) handlePostUpdateDocket(newDocket);
      handleUpdateDocket(newDocket);
    }
  };
  const updateDocketQuery = DocketQuery.useUpdateDocketQuery({
    filter: filterDocketListQuery,

    company,
    onSuccessCb: onSuccessUpdate,
  });

  const handleClickStatus = useCallback(
    (statusItem: DocketStatus) => {
      if (edittedDocket) {
        updateDocketQuery.mutate({
          docketPk: edittedDocket.docketPk,
          status: statusItem.statusId,
          statusName: statusItem.statusName,
          statusColor: statusItem.color as TRgbaFormat,
        });
        updateEdittedDocket({
          status: statusItem.statusId,
          statusName: statusItem.statusName,
          statusColor: statusItem.color as TRgbaFormat,
        });
      }
      handleCloseStatusList();
    },
    [edittedDocket]
  );
  return {
    docketStatusList,
    isFetchingStatusList: docketStatusListQuery.isLoading,
    isSuccessFetchingStatusList: docketStatusListQuery.isSuccess,
    isErrorFetchingStatusList: docketStatusListQuery.isError,
    showStatusList,
    setshowStatusList,
    handleClickStatus,
  };
};

export default useEditDocketStatusPopover;
