import React from "react";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";

import { HiOutlineDocument } from "react-icons/hi2";
import { editRgbaAlpha } from "../../Utilities/FunctionUtilities";
import { motion } from "framer-motion";
import { StyledBookingDocketNumberContainer } from "../BookingProject/Components/StyledComponentBookingProject";
type Props = {
  docketNumber?: string|null;
  required?: boolean;
  docketIconSize?: string | number;
  docketNumberTextStyle?: React.CSSProperties;
};

const BookingDocketNumber = ({
  docketNumber,
  required = false,
  docketIconSize,
  docketNumberTextStyle,
}: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
      currentTheme: state.currentTheme,
    }),
    shallow
  );

  return (
    <StyledBookingDocketNumberContainer
      $themeColor={doxleThemeColor}
      $doxleFont={doxleFont}
    >
      <HiOutlineDocument
        fontSize={docketIconSize ?? "3rem"}
        color={
          !docketNumber
            ? editRgbaAlpha({
                rgbaColor: doxleThemeColor.primaryFontColor,
                alpha: "0.5",
              })
            : doxleThemeColor.primaryFontColor
        }
      />

      {docketNumber && (
        <motion.span
          className="docket-number-text"
          initial={{ scale: 0, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          style={docketNumberTextStyle}
        >
          {docketNumber}
        </motion.span>
      )}

      {required && <span className="require-symbol">*</span>}
    </StyledBookingDocketNumberContainer>
  );
};

export default BookingDocketNumber;
