import React, { useEffect, useRef, useState } from "react";
import { AnimatePresence } from "framer-motion";
import {useDoxleThemeStore} from "../../../DoxleGeneralStore/useDoxleThemeStore";
import {shallow} from "zustand/shallow";
import {
  StyledContactsAddNewInputDiv,
  StyledContactsAddNewInputText,
  StyledContactsAddNewInputTitleText, StyledTextfieldErrorText
} from "../AddContactDialog/styledComponents";

type Props<T> = {
  fieldValue: T;
  setFieldValue: (fieldValue: T) => void;
  required?: boolean;
  fieldLabelText: string;
  multiline?: boolean;
  textFieldHeightInPixel?: `${number}px`;
  errorToggle?: boolean;
  errorText?: string;
  autoFocus?: boolean;
};

const ContactsTextField = ({
  fieldValue,
  setFieldValue,
  required,
  fieldLabelText,
  multiline,
  textFieldHeightInPixel,
  errorToggle,
  errorText,
  autoFocus,
}: Props<string>) => {
  const styleProps = useDoxleThemeStore(
    (state) => ({
      $doxleFont: state.doxleFont,
      $themeColor: state.doxleThemeColor,
      $currentTheme: state.currentTheme,
    }),
    shallow
  );
  const [textQuery, setTextQuery] = useState<string>(fieldValue);
  // const inputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (textQuery !== fieldValue) setTextQuery(fieldValue);
  }, [fieldValue]);
  useEffect(() => {
    const delayTimeout = setTimeout(() => setFieldValue(textQuery), 400);
    return () => clearTimeout(delayTimeout);
  }, [textQuery]);

  //* Animation
  const errorTextanimatedVariants = {
    entering: {
      x: [-10, 0],
      opacity: [0, 1],
      transition: {
        duration: 0.2,
      },
    },
    exiting: {
      x: [0, -10],
      opacity: [1, 0],
      transition: {
        duration: 0.2,
      },
    },
  };
  return (
    <StyledContactsAddNewInputDiv marginTop="0px" width="100%">
      <StyledContactsAddNewInputTitleText {...styleProps}>
        {fieldLabelText.toUpperCase()}
        {required ? "*" : null}
      </StyledContactsAddNewInputTitleText>

      <StyledContactsAddNewInputText
        {...styleProps}
        autoFocus={autoFocus}
        height={textFieldHeightInPixel}
        inputHeight={textFieldHeightInPixel}
        required={required || false}
        width="100%"
        placeholder={`Enter your ${fieldLabelText}`}
        value={textQuery}
        autoComplete="off"
        onChange={(event) => {
          setTextQuery(event.target.value);

        }}
        multiline={multiline || false}
      />

      <div style={{ height: 20, width: "100%", overflow: "visible" }}>
        <AnimatePresence>
          {errorToggle && errorText && (
            <StyledTextfieldErrorText
              {...styleProps}
              variants={errorTextanimatedVariants}
              initial={false}
              animate="entering"
              exit="exiting"
            >
              {errorText}
            </StyledTextfieldErrorText>
          )}
        </AnimatePresence>
      </div>
    </StyledContactsAddNewInputDiv>
  );
};

export default ContactsTextField;
