import { Table, TableContainer, TableHead } from "@mui/material";
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  TableVirtuoso,
  VirtuosoHandle,
  TableComponents,
  ItemContent,
} from "react-virtuoso";
import SheetsHeader from "./SheetsHeader";
import SheetRow from "./SheetRow";
import {
  StyledDrawingsSheetsBreadcrumbDiv,
  StyledSheetTableRow,
} from "./SheetsStyledComponents";
import { IDrawingDisplayMenu } from "./DrawingsTabDisplayer";
import { DrawingSet, Sheet } from "../Models/drawings";
import { AnimatePresence, motion } from "framer-motion";
import {
  StyledDrawingListScroller,
  StyledDrawingTableBody,
} from "./DrawingsStyledComponent";
import SheetDeleteConfirmDialog from "./SheetDeleteConfirmDialog";
import useSheets from "../Hooks/useSheets";
import AddSheetDropZone from "./AddSheetDropZone";

type Props = {
  drawingSet: DrawingSet;
};
interface SheetListContext {
  drawingSet: DrawingSet;
}
function Sheets({ drawingSet }: Props) {
  const { setShowAddSheetDialog, showAddSheetDialog } = useSheets({
    drawingSet,
  });

  //* render table props
  const TableComponents: TableComponents<Sheet, SheetListContext> = useMemo(
    () => ({
      Table: (props) => (
        <Table
          {...props}
          style={{
            width: "100% !important",
            height: "100% !important",
            minWidth: "90rem !important",
          }}
          sx={{
            "&.MuiTableCell-head:first-of-type": {
              width: " 1px",
              whiteSpace: "nowrap",
            },
          }}
        />
      ),

      Scroller: React.forwardRef((props, ref) => (
        <TableContainer
          sx={{
            "&.MuiTableContainer-root": {
              width: "100% !important",
              height: "100% !important",
              minWidth: "40rem !important",
            },
          }}
          ref={ref}
          {...props}
          component={StyledDrawingListScroller}
        />
      )),
      TableHead: TableHead,
      TableBody: React.forwardRef((props, ref) => (
        <StyledDrawingTableBody {...props} ref={ref} />
      )),
      TableRow: (props) => (
        <StyledSheetTableRow {...props} key={props.item.sheetId} layout />
      ),
      // TableFoot: React.forwardRef((props, ref) => (
      //   <AnimatePresence>
      //     {!isScrollingTable && (
      //       <StyledBudgetFooterContainer ref={ref} {...props} />
      //     )}
      //   </AnimatePresence>
      // )),
    }),
    []
  );

  const itemContent: ItemContent<Sheet, SheetListContext> = useCallback(
    (index: number, item: Sheet, context: SheetListContext) => (
      <SheetRow
        drawing={context.drawingSet}
        sheet={item}
        key={item.sheetId}
        sheetIndex={index}
      />
    ),
    []
  );

  return (
    <motion.div
      style={{ width: "100%", height: "100%", display: "flex" }}
      initial={{
        x: -20,
        opacity: 0,
      }}
      animate={{
        x: 0,
        opacity: 1,
      }}
    >
      <TableVirtuoso
        style={{ width: "100%", height: "100%" }}
        data={drawingSet.sheets}
        context={{ drawingSet }}
        components={TableComponents}
        fixedHeaderContent={() => <SheetsHeader />}
        itemContent={itemContent}
      />

      <SheetDeleteConfirmDialog />

      <AddSheetDropZone
        openDialog={showAddSheetDialog}
        onClose={() => setShowAddSheetDialog(false)}
        drawingSet={drawingSet}
      />
    </motion.div>
  );
}

export default Sheets;
