import styled from "styled-components";
import {
  DoxleFont,
  DoxleThemeColor,
} from "../../../../DoxleGeneralStore/useDoxleThemeStore";
import { motion } from "framer-motion";

export const StyledProjectDrawerContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(p) => p.theme.color.primaryBackgroundColor};
  .content-wrapper {
    height: calc(100% - 44px);
    padding: 30px 50px 14px 50px;
    justify-content: center;
    display: flex;
  }
`;

export const StyledProjectDrawerRootContainer = styled(motion.div)`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  background-color: ${(p) => p.theme.color.primaryBackgroundColor};
`;

export const StyledProjectDrawerLeft = styled.div<{
  $themeColor: DoxleThemeColor;
}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: ${(p) => p.theme.color.primaryTextFieldBackgroundColor};
  flex: 1;
  height: 100%;
`;

export const StyledProjectTabDisplayer = styled(motion.div)`
  flex: 1;
  min-height: 0px;
  width: calc(100% - 80px);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 40px;
`;

export const StyledProjectMenuRoot = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
  $isOverflow: boolean;
}>`
  display: flex;
  flex-direction: row;
  height: 30px;
  width: 100%;

  min-width: 600px;
  justify-content: flex-start;
  align-items: center;
  margin-top: 8px;
  margin-bottom: 2rem;
  border-bottom: 1px solid ${(p) => p.$themeColor.primaryDividerColor};
  .menu-tab-section {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-x: scroll;
    height: 100%;
    overflow-y: visible;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    ::-webkit-scrollbar {
      display: none;
    }
  }

  .shrink-btn {
    opacity: ${(p) => (p.$isOverflow ? 1 : 0)};
    z-index: ${(p) => (p.$isOverflow ? 1 : 0)};
    display: ${(p) => (p.$isOverflow ? "flex" : "none")};
  }

  .full-menu {
    opacity: ${(p) => (p.$isOverflow ? 0 : 1)};
    z-index: ${(p) => (p.$isOverflow ? 0 : 1)};
    display: ${(p) => (p.$isOverflow ? "none" : "flex")};
  }
`;

export const StyledProjectDrawerTabText = styled.div<{
  $selected: boolean;
  $disabled: boolean;
}>`
  position: relative;
  overflow: visible;
  font-family: ${(p) => p.theme.font.secondaryTitleFont};
  font-size: 10px;
  color: ${(p) => p.theme.color.primaryFontColor};
  text-transform: uppercase;
  width: fit-content;
  background-color: ${(p) => p.theme.color.primaryBackgroundColor};
  font-weight: ${(p) => (p.$selected ? 600 : 500)};
  flex-shrink: 0;
  padding: 1px 8px;
  min-width: 50px;
  cursor: pointer;
  margin: 0px 4px;
  transition: 0.4s;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    opacity: 0.8;
    transition: 0.4s ease-in-out all;
    border-bottom: none;
  }
  &:active {
    transition: 0.4s;
  }
  .selected-effect {
    position: absolute;
    z-index: 0;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-color: ${(p) => p.theme.color.primaryFontColor};
    border-radius: 4px;
  }
  .underline-effect {
    position: absolute;
    z-index: 0;
    left: 0;
    right: 0;
    bottom: -7px;
    height: 1px;
    background-color: ${(p) => p.theme.color.primaryFontColor};
  }
`;
