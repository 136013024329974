import EditDocketFullScreenMode from "../EditDocketSideScreen/EditDocketFullScreenMode/EditDocketFullScreenMode";
import DocketQuery from "../../Services/QueryHooks/docketQueryAPI";
import useDoxleNotificationStore from "../../DoxleGeneralStore/useDoxleNotificationStore";
import { shallow } from "zustand/shallow";
import { useEditDocketSideScreenStore } from "../EditDocketSideScreen/EditDocketSideScreenStore/useEditDocketSideScreenStore";
import { useEffect, useMemo } from "react";
import { Docket } from "../../Models/dockets";
import { User } from "../../Models/user";
import { Company } from "../../Models/company";
import { useDoxleCurrentContextStore } from "../../DoxleGeneralStore/useDoxleCurrentContext";
import { useDoxleAuthStore } from "../../DoxleGeneralStore/useDoxleAuthStore";
import Error404 from "../ErrorPages/Error404";
import Loading from "../../Utilities/Lottie/Loading";
import { useParams } from "react-router-dom";
import { useShallow } from "zustand/react/shallow";

interface Props {}

const getLinkId = () => {
  const url = window.location.href;
  const urlArray = url.split("/");
  const urlArrayUpper = url.toUpperCase().split("/");
  const docketIndex = urlArrayUpper.indexOf("SHARED");
  if (docketIndex >= 0) {
    const docketPk = urlArray?.[docketIndex + 1];
    return docketPk ?? "";
  }
  return "";
};
type SharedDocketParams = {
  linkId: string;
  tab: "Payments" | "Orders" | undefined;
};

const SharedDocketLandingPage = ({}: Props) => {
  const { linkId, tab } = useParams<SharedDocketParams>();
  const { setDocket, setFilterDiscussionList, setSelectedFunctionMenu } =
    useEditDocketSideScreenStore(
      useShallow((state) => ({
        setDocket: state.setEdittedDocket,
        setFilterDiscussionList: state.setFilterDiscussionList,
        setSelectedFunctionMenu: state.setSelectedFunctionMenu,
      }))
    );
  const setCompany = useDoxleCurrentContextStore(
    useShallow((state) => state.setCurrentCompany)
  );
  const setUser = useDoxleAuthStore(useShallow((state) => state.setUser));

  const docketLinkQuery = DocketQuery.useExchangeSharedLink({
    linkId: linkId,
  });

  const { docket, company, user } = useMemo(() => {
    return docketLinkQuery?.isSuccess
      ? docketLinkQuery.data?.data
      : {
          docket: undefined,
          company: undefined,
          user: undefined,
          token: undefined,
        };
  }, [docketLinkQuery.isSuccess, docketLinkQuery.data]);

  useEffect(() => {
    if (docket) {
      setDocket(docket);
      setFilterDiscussionList({ docketId: docket.docketPk });
    }
    if (company) setCompany(company);
    if (user) setUser(user);
  }, [docket, company, user]);

  useEffect(() => {
    if (tab) {
      if (tab.toLowerCase().includes("order"))
        setSelectedFunctionMenu("Orders");
      else if (tab.toLowerCase().includes("payment"))
        setSelectedFunctionMenu("Payments");
    }
  }, []);

  return docketLinkQuery.isSuccess ? <EditDocketFullScreenMode /> : <Loading />;
};

export default SharedDocketLandingPage;
