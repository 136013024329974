import React from "react";
import { QuoteResponse } from "../../models/quote";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import {
  StyledQSAttachmentItem,
  StyledQSAttachmentSection,
  StyledQSDetailFieldWrapper,
  StyledQSLineItem,
  StyledQSLinesSection,
  StyledQSTotalSection,
  StyledQuoteLineHeaderSection,
  StyledViewQSDialog,
  StyledViewQSDialogContent,
  StyledViewQSDialogTitle,
} from "./StyledComponentReviewQuote";
import dayjs from "dayjs";
import PDFIcon from "../../Assets/adobePdfIcon.svg";
import MicrosoftIcon from "../../Assets/microsoftIcon.svg";
import ExcelIcon from "../../Assets/excelIcon.svg";
import { formatter } from "../../../Utilities/FunctionUtilities";

type Props = {
  quoteResponse: QuoteResponse;
  open: boolean;
  onClose: () => void;
};
export const FILETYPE = {
  pdf: PDFIcon,
  docx: MicrosoftIcon,
  xlsx: ExcelIcon,
};

type LineHeader = "Description" | "Quantity" | "Item Cost" | "Line cost";
const LINE_HEADER_LIST: LineHeader[] = [
  "Description",
  "Quantity",
  "Item Cost",
  "Line cost",
];
const ViewQuoteResponse = ({ quoteResponse, open, onClose }: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore((state) => ({
    doxleThemeColor: state.doxleThemeColor,
    doxleFont: state.doxleFont,
  }));
  return (
    <StyledViewQSDialog
      $themeColor={doxleThemeColor}
      open={open}
      onClose={onClose}
    >
      <StyledViewQSDialogTitle
        $themeColor={doxleThemeColor}
        $doxleFont={doxleFont}
      >
        Quote Response
      </StyledViewQSDialogTitle>

      <StyledViewQSDialogContent $themeColor={doxleThemeColor}>
        <StyledQSDetailFieldWrapper
          $themeColor={doxleThemeColor}
          $doxleFont={doxleFont}
        >
          <div className="field-title">Reference</div>
          <div className="field-content">{quoteResponse.reference}</div>
        </StyledQSDetailFieldWrapper>
        <StyledQSDetailFieldWrapper
          $themeColor={doxleThemeColor}
          $doxleFont={doxleFont}
        >
          <div className="field-title">Supplier</div>
          <div className="field-content">{quoteResponse.supplierName}</div>
        </StyledQSDetailFieldWrapper>

        <StyledQSDetailFieldWrapper
          $themeColor={doxleThemeColor}
          $doxleFont={doxleFont}
        >
          <div className="field-title">Payment Terms</div>
          <div className="field-content">
            {quoteResponse.paymentTerms} {quoteResponse.paymentTermsSuffix}
          </div>
        </StyledQSDetailFieldWrapper>
        <StyledQSDetailFieldWrapper
          $themeColor={doxleThemeColor}
          $doxleFont={doxleFont}
        >
          <div className="field-title">Respond On</div>
          <div className="field-content">
            {dayjs(quoteResponse.timeStamp as string).format("DD-MM-YYYY")}
          </div>
        </StyledQSDetailFieldWrapper>

        <StyledQSDetailFieldWrapper
          $themeColor={doxleThemeColor}
          $doxleFont={doxleFont}
        >
          <div className="field-title">Expired On</div>
          <div className="field-content">
            {dayjs(quoteResponse.expiryDate as string).format("DD-MM-YYYY")}
          </div>
        </StyledQSDetailFieldWrapper>

        {quoteResponse.attachments && quoteResponse.attachments.length > 0 && (
          <StyledQSAttachmentSection
            $themeColor={doxleThemeColor}
            $doxleFont={doxleFont}
          >
            <div className="field-title">Attachments</div>
            <div className="attachment-wrapper">
              {quoteResponse.attachments.map((attachment) => {
                const fileExtension = attachment.fileType
                  .toLowerCase()
                  .includes("pdf")
                  ? FILETYPE.pdf
                  : attachment.fileType.toLowerCase().includes("docx") ||
                    attachment.fileType.toLowerCase().includes("doc")
                  ? FILETYPE.docx
                  : attachment.fileType.toLowerCase().includes("xlsx")
                  ? FILETYPE.xlsx
                  : attachment.url;

                return (
                  <StyledQSAttachmentItem
                    key={attachment.attachmentId}
                    $themeColor={doxleThemeColor}
                    $doxleFont={doxleFont}
                    onClick={() => window.open(attachment.url)}
                  >
                    <img className="img-wrapper" src={fileExtension} />
                    <span className="file-text"> {attachment.fileName}</span>
                  </StyledQSAttachmentItem>
                );
              })}
            </div>
          </StyledQSAttachmentSection>
        )}

        <StyledQSLinesSection
          $themeColor={doxleThemeColor}
          $doxleFont={doxleFont}
        >
          <div className="field-title">Lines</div>
          <div className="line-wrapper">
            {quoteResponse.lines.length === 0 ? (
              "No Lines"
            ) : (
              <>
                <StyledQuoteLineHeaderSection
                  $themeColor={doxleThemeColor}
                  $doxleFont={doxleFont}
                >
                  {LINE_HEADER_LIST.map((header, idx) => (
                    <span
                      key={`header#${idx}`}
                      style={{
                        width: header !== "Description" ? "10rem" : undefined,
                        flex: header === "Description" ? 1 : undefined,
                        textAlign: header === "Description" ? "left" : "center",
                      }}
                    >
                      {header}
                    </span>
                  ))}
                </StyledQuoteLineHeaderSection>
                {quoteResponse.lines.map((line) => (
                  <StyledQSLineItem
                    $themeColor={doxleThemeColor}
                    $doxleFont={doxleFont}
                    key={line.lineId}
                  >
                    {LINE_HEADER_LIST.map((header, idx) => (
                      <div
                        key={`line${line.lineId}#${header}`}
                        className={
                          header === "Description"
                            ? "description"
                            : "currency-line"
                        }
                      >
                        {header === "Description" && line.description}
                        {header === "Item Cost" &&
                          formatter.format(Number(line.itemCost))}
                        {header === "Quantity" &&
                          `${line.quantity} ${line.unit}`}
                        {header === "Line cost" &&
                          formatter.format(Number(line.lineCost))}
                      </div>
                    ))}
                  </StyledQSLineItem>
                ))}
              </>
            )}
          </div>
        </StyledQSLinesSection>

        <StyledQSTotalSection
          $themeColor={doxleThemeColor}
          $doxleFont={doxleFont}
        >
          <div className="total-field-wrapper">
            <h4>Subtotal</h4>
            <p>{formatter.format(Number(quoteResponse.quoteSubTotal))}</p>
          </div>

          <div className="total-field-wrapper">
            <h4>Total</h4>
            <p>{formatter.format(Number(quoteResponse.quoteTotal))}</p>
          </div>
        </StyledQSTotalSection>
      </StyledViewQSDialogContent>
    </StyledViewQSDialog>
  );
};

export default ViewQuoteResponse;
