import Dialog from "@mui/material/Dialog";
import styled from "styled-components";
import {
  DoxleFont,
  DoxleThemeColor,
} from "../../../../DoxleGeneralStore/useDoxleThemeStore";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { motion } from "framer-motion";
import Button from "@mui/material/Button";
import { editRgbaAlpha } from "../../../../Utilities/FunctionUtilities";

export const StyledQASignatureDialog = styled(Dialog)<{
  $themeColor: DoxleThemeColor;
}>`
  && {
    background-color: rgba(0, 0, 0, 0.8);
  }
  & .MuiDialog-paper {
    background-color: ${(p) => p.$themeColor.primaryContainerColor};
    box-shadow: 0px 0px 14px 0px ${(p) => p.$themeColor.primaryBoxShadowColor};
    max-width: 70rem !important;
    min-width: 400px !important;
    width: 60vw !important;
    margin: 0px !important;
    position: relative;
  }
`;
export const StyledQASignatureDialogTitle = styled(DialogTitle)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  && {
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-style: normal;
    font-weight: 400;
    font-size: 2.4rem;
    line-height: 3rem;

    color: ${(p) => p.$themeColor.primaryFontColor};
    border-bottom: 1px solid ${(p) => p.$themeColor.primaryDividerColor};
    background-color: ${(p) => p.$themeColor.primaryContainerColor};
    width: calc(100% - 2.8rem);
    padding: 1.4rem !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;
export const StyledQASignatureDialogContent = styled(DialogContent)<{}>`
  && {
    background-color: ${(p) => p.theme.color.primaryContainerColor};

    word-break: break-word;
    width: calc(100% - 28px) !important;
    padding: 8px 14px !important;
    display: flex;
    flex-direction: column;

    .preview-wrapper {
      width: calc(100% - 2px);
      margin: 8px 0px;
      height: 20vh;
      border-radius: 12px;
      border: 1px solid ${(p) => p.theme.color.rowBorderColor};
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      .preview-text {
        font-family: ${(p) => p.theme.font.primaryFont};
        font-style: normal;
        font-weight: 400;
        font-size: 12px;

        color: ${(p) => p.theme.color.doxleColor};
        position: absolute;
        z-index: 10;
        top: 8px;
        right: 8px;
      }

      .image-signature {
        width: 80%;
        height: 100%;
        object-fit: contain;
      }
    }
    .canvas-wrapper {
      width: calc(100% - 2px);
      margin: 8px 0px;
      height: 20vh;
      border-radius: 1.2rem;
      border: 1px dashed
        ${(p) =>
          editRgbaAlpha({
            rgbaColor: p.theme.color.primaryFontColor,
            alpha: "0.4",
          })};
      justify-content: center;
      align-items: center;
      display: flex;
      position: relative;
      .sign-text {
        font-family: ${(p) => p.theme.font.primaryFont};
        font-style: normal;
        font-weight: 500;
        font-size: 12px;

        color: ${(p) => p.theme.color.primaryFontColor};
        position: absolute;
        z-index: 10;
        top: 8px;
        right: 8px;
      }
      .signature-pad {
        width: 90%;
        height: 90%;
      }
    }
  }
`;
export const StyledQASignatureDialogActions = styled(DialogActions)<{
  $themeColor: DoxleThemeColor;
}>`
  && {
    background-color: ${(p) => p.$themeColor.primaryContainerColor};
    width: calc(100% - 2.8rem) !important;
    padding: 1.4rem !important;
    display: flex;
    justify-content: flex-end;
  }
`;

export const StyledQASignatureButton = styled(motion(Button))<{
  $bgColor: string;
  $textColor: string;
  $hasBorder?: boolean;
}>`
  && {
    min-width: 0px !important;
    margin: 0px 4px;
    border-radius: 5px !important;
    padding: 8px 24px;
    font-size: 16px;
    color: ${(p) => p.$textColor};
    font-family: ${(p) => p.theme.font.primaryFont};
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.65px;
    border: ${(p) => (p.$hasBorder ? 1 : 0)}px solid
      ${(p) => p.theme.color.rowBorderColor};
    background-color: ${(p) => p.$bgColor};
    text-transform: capitalize;
    &:hover {
      background-color: ${(p) => p.$bgColor} !important;
      opacity: 0.8;
    }
  }
  &.MuiButton-root.Mui-disabled {
    color: ${(p) => p.$textColor} !important;
    background-color: ${(p) => p.$bgColor} !important;
    opacity: 0.4;
  }
`;

export const StyledSaveSignatureLoaderWrapper = styled(motion.div)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: ${(p) =>
    editRgbaAlpha({
      rgbaColor: p.$themeColor.primaryContainerColor,
      alpha: "0.8",
    })};
  align-items: center;
  .loader-bar {
    width: 100%;
    max-width: 50rem;
  }
  .loader-text {
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-style: normal;
    font-weight: 500;
    font-size: 2rem;
    color: ${(p) => p.$themeColor.primaryFontColor};
    display: flex;
    margin-top: 8px;
  }
`;
