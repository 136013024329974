import {
  StyledDoxleDialogHelperActions,
  StyledDoxleDialogHelperContainer,
  StyledDoxleDialogHelperContent,
  StyledDoxleDialogHelperEditButton,
  StyledDoxleDialogHelperTitle,
} from "../../DoxleDesignPattern/DoxleDialogHelper/StyledComponentDoxleDialogHelper";
import { editRgbaAlpha } from "../../Utilities/FunctionUtilities";
import { ChangeEvent, MouseEvent, KeyboardEvent, useEffect } from "react";
import {
  DoxleFont,
  DoxleThemeColor,
  useDoxleThemeStore,
} from "../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import { styled } from "styled-components";
import { TextField } from "@mui/material";
import { TrashIcon } from "@heroicons/react/24/outline";
import { FolderUploadDetails } from "../Hooks/useListViewRight";
import { produce } from "immer";
import AddedFileItem from "./AddedFileItem";
import { StyledConfirmAddFileLabel } from "../Content/StyledFilesBodyComponent";

interface Props {
  folder: FolderUploadDetails | null;
  setFolder: React.Dispatch<React.SetStateAction<FolderUploadDetails | null>>;
  uploadFn: () => void;
}

const ConfirmFolderUploadDialog = ({ folder, setFolder, uploadFn }: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore((state) => ({
    doxleThemeColor: state.doxleThemeColor,
    doxleFont: state.doxleFont,
  }));
  const dialogButtons = ["Cancel", "Upload"];
  const handleUploadButton = () => {
    uploadFn();
    setFolder(null);
  };
  const handleUpdateFolderName = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFolder((prevState) =>
      !prevState
        ? prevState
        : {
            folderName: e.target.value,
            files: prevState?.files ?? [],
          }
    );
  };
  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") uploadFn();
    if (e.key === "Escape") setFolder(null);
  };
  const handleRemoveFile = (index: number) => {
    setFolder(
      produce((draft) => {
        if (draft) draft.files.splice(index, 1);
      })
    );
  };
  return (
    <StyledDoxleDialogHelperContainer
      $themeColor={doxleThemeColor}
      PaperProps={{
        sx: {
          bgColor: doxleThemeColor.primaryContainerColor,
          borderRadius: "9px",
        },
        elevation: 4,
      }}
      slotProps={{
        backdrop: {
          sx: {
            bgcolor: editRgbaAlpha({
              rgbaColor: doxleThemeColor.primaryBackgroundColor,
              alpha: "0.4",
            }),
          },
        },
      }}
      open={Boolean(folder)}
    >
      <StyledDoxleDialogHelperTitle
        $themeColor={doxleThemeColor}
        $doxleFont={doxleFont}
      >
        Upload Folder?
      </StyledDoxleDialogHelperTitle>

      <StyledDoxleDialogHelperContent $themeColor={doxleThemeColor}>
        <StyledConfirmAddFileLabel>Folder Name:</StyledConfirmAddFileLabel>

        <StyledUploadFolderTextField
          value={folder?.folderName ?? ""}
          onChange={handleUpdateFolderName}
          onKeyDown={handleKeyDown}
          label={"Folder Name"}
          fullWidth
          autoFocus
        />
        <StyledUploadFolderFilesContainer>
          {folder?.files.length && (
            <StyledConfirmAddFileLabel>
              {folder?.files.length} Files Found:
            </StyledConfirmAddFileLabel>
          )}

          <div className="file-container">
            {folder?.files.map((file: File, index) => (
              <AddedFileItem
                key={`${file.name}-${index}`}
                file={file}
                handleRemoveFile={handleRemoveFile}
                index={index}
              />
            ))}
          </div>
        </StyledUploadFolderFilesContainer>
      </StyledDoxleDialogHelperContent>

      <StyledDoxleDialogHelperActions $themeColor={doxleThemeColor}>
        {dialogButtons.map((button, idx) => (
          <StyledDoxleDialogHelperEditButton
            key={`helperBtn#${idx}`}
            className={`doxleDialogBtn-${idx}-UploadFolder`}
            $bgColor={
              button === "Cancel"
                ? doxleThemeColor.primaryReverseContainerColor
                : undefined
            }
            $themeColor={doxleThemeColor}
            $dialogDescription={"UploadFolder"}
            $buttonIndex={idx}
            $doxleFont={doxleFont}
            onClick={
              button === "Cancel" ? () => setFolder(null) : handleUploadButton
            }
            layout
          >
            <span className="button-text">
              {button === "Cancel" ? "Cancel upload" : "Upload Folder"}
            </span>
          </StyledDoxleDialogHelperEditButton>
        ))}
      </StyledDoxleDialogHelperActions>
    </StyledDoxleDialogHelperContainer>
  );
};

export default ConfirmFolderUploadDialog;

export const StyledUploadFolderTextField = styled(TextField)<{}>`
  && {
    width: 100%;
    height: 30px;
    border-radius: 8px;
    background-color: ${(p) => p.theme.color.primaryTextFieldBackgroundColor};
    max-width: 450px;
  }
  label {
    display: none;
  }
  input {
    padding: 10px !important;
    font-family: ${(p) => p.theme.font.secondaryTitleFont};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.028rem;
    color: ${(p) => p.theme.color.primaryFontColor};

    &::placeholder {
      font-family: ${(p) => p.theme.font.secondaryTitleFont};
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.028rem;
      color: ${(p) => p.theme.color.primaryInputPlaceholderColor};
      opacity: 1;
    }
  }
  & .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }
  & .MuiInputBase-root {
    height: 100%;
  }
`;
export const StyledUploadFolderFilesContainer = styled.div<{}>`
  width: 100%;

  margin-top: 8px;

  .file-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
  }
`;
export const StyledPendingUploadFile = styled.span<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  background-color: ${(p) =>
    editRgbaAlpha({
      rgbaColor: p.$themeColor.doxleColor,
      alpha: "0.25",
    })};
  padding: 0.25rem;
  margin: 0.3rem;
  line-height: 2.5rem;
  border-radius: 8px;
  font-size: max(1.4rem, 13px);
  font-weight: 500;
  font-family: ${(p) => p.$doxleFont.subTitleFont};
  cursor: pointer;
  text-wrap: none;
  white-space: nowrap;
  .trashIcon {
    color: transparent;
  }
  &:hover {
    background-color: ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.$themeColor.doxleColor,
        alpha: "0.45",
      })} !important;
    .trashIcon {
      transform: translateY(3px);
      color: ${(p) => p.$themeColor.primaryFontColor};
    }
  }
`;
