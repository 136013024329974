import {
  StyledCompanySettingsTabLeftColumn,
  StyledCompanySettingsTabRightColumn,
  StyledTabContainer,
} from "../styledComponents";
import WomanWithCog from "../Assets/WomanWithCog";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import EditCompanyDetails from "../EditCompanyDetails/EditCompanyDetails";
// import EditProjectStatusSection from "../EditProjectStatusSection/EditProjectStatusSection";
// import EditDocketStatusesSection from "../EditDocketStatusesSection/EditDocketStatusesSection";
// import EditBillStatusesSection from "../EditOrderStatusesSection/EditBillStatusesSection";

const CompanySettingsTab = () => {
  const styleProps = useDoxleThemeStore(
    (state) => ({
      $themeColor: state.doxleThemeColor,
      $doxleFont: state.doxleFont,
    }),
    shallow
  );

  return (
    <StyledTabContainer>
      <StyledCompanySettingsTabLeftColumn>
        <EditCompanyDetails />
        {/*<EditProjectStatusSection />*/}
        {/*<EditDocketStatusesSection />*/}
        {/*<EditBillStatusesSection />*/}
      </StyledCompanySettingsTabLeftColumn>
      <StyledCompanySettingsTabRightColumn>
        <div className={"left"} />
        <WomanWithCog themeColor={styleProps.$themeColor} />
        <div className={"right"} />
      </StyledCompanySettingsTabRightColumn>
    </StyledTabContainer>
  );
};

export default CompanySettingsTab;
