export const RulerIcon = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    style={{
      marginTop: "3px",
      // "&:hover": {
      //   cursor: "pointer",
      // },
    }}
    fill="none"
    {...props}>
    <path
      fill={props.fill ?? "#6461FB"}
      fillRule="evenodd"
      d="M4.689 1.575a.6.6 0 0 1 .6-.6h7.424a.6.6 0 0 1 .6.6v14.85a.6.6 0 0 1-.6.6H5.29a.6.6 0 0 1-.6-.6V1.575Zm1.2 14.25h6.224v-1.452h-1.521a.6.6 0 0 1 0-1.2h1.521v-1.982H9.002a.6.6 0 0 1 0-1.2h3.113V8.01h-1.522a.6.6 0 1 1 0-1.2h1.521V4.827H9.002a.6.6 0 0 1 0-1.2h3.112V2.175H5.89v13.65Z"
      clipRule="evenodd"
    />
  </svg>
);

export const PlusIcon = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={12}
    height={12}
    fill="none"
    style={{
      marginLeft: "20px",
      marginRight: "20px",
      "&:hover": { cursor: "pointer" },
    }}
    {...props}>
    <path
      fill="#AFAFC7"
      d="M6.418 5.58V.419a.419.419 0 0 0-.838 0v5.16H.42a.419.419 0 0 0 0 .838H5.58v5.164a.419.419 0 0 0 .838 0V6.417l5.163.002a.419.419 0 0 0 0-.838L6.418 5.58Z"
    />
  </svg>
);
