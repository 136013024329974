import { produce } from "immer";

import { create } from "zustand";
import {
  IQRCodeFilterQuery,
  IQRLogFilterQuery,
  IQRUserLogFilterQuery,
} from "../Models/qrLog";

type QRLogStoreState = {
  qrView: "QRCode" | "QRLog";
  setQRView: (view: "QRCode" | "QRLog") => void;
  filterQRCode: IQRCodeFilterQuery;
  setFilterQRCode: (filter: Partial<IQRCodeFilterQuery>) => void;

  filterQRLog: IQRLogFilterQuery;
  setFilterQRLog: (filter: Partial<IQRLogFilterQuery>) => void;

  handleRemoveFilterQRLogProp: (filterProp: keyof IQRLogFilterQuery) => void;

  filterQRUserLog: IQRUserLogFilterQuery;
  setFilterQRUserLog: (filter: Partial<IQRUserLogFilterQuery>) => void;
  removeFilterQRUserLogProp: (filterProp: keyof IQRLogFilterQuery) => void;
};

const useQRLogStore = create<QRLogStoreState>((set, get) => ({
  qrView: "QRLog",
  setQRView: (view) =>
    set((state) =>
      produce(state, (draftState) => {
        draftState.qrView = view;

        return draftState;
      })
    ),
  filterQRCode: {},
  setFilterQRCode: (filter: Partial<IQRCodeFilterQuery>) =>
    set((state) =>
      produce(state, (draftState) => {
        Object.assign(draftState.filterQRCode, filter);

        return draftState;
      })
    ),

  filterQRLog: {},
  setFilterQRLog: (filter: Partial<IQRLogFilterQuery>) =>
    set((state) =>
      produce(state, (draftState) => {
        Object.assign(draftState.filterQRLog, filter);

        return draftState;
      })
    ),

  handleRemoveFilterQRLogProp: (filterProp: keyof IQRLogFilterQuery) => {
    set((state) =>
      produce(state, (draftState) => {
        delete draftState.filterQRLog[filterProp];

        return draftState;
      })
    );
  },
  filterQRUserLog: {},
  setFilterQRUserLog: (filter: Partial<IQRUserLogFilterQuery>) =>
    set((state) =>
      produce(state, (draftState) => {
        Object.assign(draftState.filterQRUserLog, filter);

        return draftState;
      })
    ),
  removeFilterQRUserLogProp: (filterProp: keyof IQRLogFilterQuery) => {
    set((state) =>
      produce(state, (draftState) => {
        delete draftState.filterQRUserLog[filterProp];

        return draftState;
      })
    );
  },
}));

export default useQRLogStore;
