import React, { useState } from "react";
import { Docket, DocketStatus, LightDocket } from "../../../../Models/dockets";
import { useDoxleCurrentContextStore } from "../../../../DoxleGeneralStore/useDoxleCurrentContext";
import { shallow } from "zustand/shallow";
import { useInboxStore } from "../../../InboxStore/useInboxStore";
import useDoxleNotificationStore from "../../../../DoxleGeneralStore/useDoxleNotificationStore";
import DocketQuery from "../../../../Services/QueryHooks/docketQueryAPI";
import { TRgbaFormat } from "../../../../Utilities/FunctionUtilities";
import useSetDocketListQueryData from "../../../../CoreContent/QueryDataHooks/SetQueryDataHooks/useSetDocketListQueryData";
import { useDoxleAuthStore } from "../../../../DoxleGeneralStore/useDoxleAuthStore";

type Props = {
  currentFocusedNB: LightDocket | undefined;
  handleClosePopoverStatusMenu: () => void;
};

interface INBStatusPopover {
  handleUpdateNbStatus: (status: DocketStatus) => void;
  showStatusList: boolean;
  setShowStatusList: React.Dispatch<React.SetStateAction<boolean>>;
}
const useNBStatusPopover = ({
  currentFocusedNB,
  handleClosePopoverStatusMenu,
}: Props): INBStatusPopover => {
  const [showStatusList, setShowStatusList] = useState(false);
  const { currentCompany } = useDoxleCurrentContextStore(
    (state) => ({
      currentCompany: state.currentCompany,
    }),
    shallow
  );
  const { filterDocketListQuery } = useInboxStore((state) => ({
    filterDocketListQuery: state.filterDocketListQuery,
  }));
  const user = useDoxleAuthStore((state) => state.user, shallow);
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );
  const { handleUpdateDocket: handleUpdateMyNoticeDockets } =
    useSetDocketListQueryData({
      filter: { ...filterDocketListQuery, ball_in_court: user?.userId },
    });

  const { handleUpdateDocket: handleUpdateAllNoticeDockets } =
    useSetDocketListQueryData({
      filter: { ...filterDocketListQuery, ball_in_court: undefined },
    });
  const onSuccessUpdate = (newDocket?: Docket) => {
    if (newDocket) {
      handleUpdateMyNoticeDockets(newDocket);
      handleUpdateAllNoticeDockets(newDocket);
    }
  };
  const updateNbQuery = DocketQuery.useUpdateDocketQuery({
    filter: filterDocketListQuery,

    company: currentCompany,
    onSuccessCb: onSuccessUpdate,
    showNotification,
  });
  const handleUpdateNbStatus = (status: DocketStatus) => {
    if (currentFocusedNB)
      updateNbQuery.mutate({
        docketPk: currentFocusedNB.docketPk,
        status: status.statusId,
        statusName: status.statusName,
        statusColor: status.color as TRgbaFormat,
      });
    handleClosePopoverStatusMenu();
  };
  return { handleUpdateNbStatus, showStatusList, setShowStatusList };
};

export default useNBStatusPopover;
