import React from "react";
import { useDoxleThemeStore } from "../../../../DoxleGeneralStore/useDoxleThemeStore";
import { useProjectQAStore } from "../../Store/useProjectQAStore";
import { shallow } from "zustand/shallow";
import {
  StyledQAListEditFieldWrapper,
  StyledQaListAssigneeDisplayer,
} from "./StyledComponentQAListEdit";
import useEditQAListAssignee from "./Hooks/useEditQAListAssignee";
import CircularProgress from "@mui/material/CircularProgress";
import DoxleIconButton from "../../../../DoxleDesignPattern/DoxleButtons/DoxleIconButton";
import { RiCloseCircleFill } from "react-icons/ri";
import SelectAssigneeDropdown from "../CommonComponents/SelectAssigneeDropdown";
import DoxleSelectContactDropdown from "../../../../DoxleDesignPattern/DoxleSelectContactDropdown/DoxleSelectContactDropdown";

type Props = {};

const EditQAListAssignee = (props: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }),
    shallow
  );
  const { edittedQAListItem } = useProjectQAStore(
    (state) => ({
      edittedQAListItem: state.edittedQAListItem,
    }),
    shallow
  );
  const {
    anchorAssigneeList,
    setAnchorAssigneeList,
    handleUpdateAssignee,
    handleCloseAssigneeList,
    isUpdatingAssignee,
    isHovering,
    setIsHovering,
  } = useEditQAListAssignee({});
  return (
    <StyledQAListEditFieldWrapper className="assignee-field-wrapper">
      <span className="field-label-text">Assignee</span>
      <StyledQaListAssigneeDisplayer
        onClick={(e) => setAnchorAssigneeList(e.currentTarget)}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
      >
        {edittedQAListItem?.assignee ? (
          edittedQAListItem?.assigneeName
        ) : (
          <span className="null-text">Select Assignee</span>
        )}

        {isUpdatingAssignee ? (
          <CircularProgress
            style={{ color: doxleThemeColor.primaryFontColor }}
            size="1.2rem"
          />
        ) : (
          edittedQAListItem?.assignee &&
          isHovering && (
            <DoxleIconButton
              iconSource={
                <RiCloseCircleFill
                  style={{ color: doxleThemeColor.primaryFontColor }}
                  size="2rem"
                />
              }
              onClick={(e) => {
                e.stopPropagation();
                handleUpdateAssignee(undefined);
              }}
              buttonSize={15}
              buttonWrapperStyle={{
                padding: "0px !important",
                margin: "0px !important",
              }}
            />
          )
        )}
      </StyledQaListAssigneeDisplayer>

      {/* <SelectAssigneeDropdown
        anchorDropdown={anchorAssigneeList}
        onCloseDropdown={handleCloseAssigneeList}
        selectedAssigneeId={edittedQAListItem?.assignee}
        onSelectAssignee={handleUpdateAssignee}
      /> */}

      <DoxleSelectContactDropdown
        popoverAnchor={anchorAssigneeList}
        onClosePopover={handleCloseAssigneeList}
        variants="contact"
        selectedContactItemIds={
          edittedQAListItem?.assignee
            ? [edittedQAListItem?.assignee]
            : undefined
        }
        onSelectContact={handleUpdateAssignee}
        containerStyle={{
          borderRadius: 9,
        }}
      />
    </StyledQAListEditFieldWrapper>
  );
};

export default EditQAListAssignee;
