import DocketQuery from "../../../../Services/QueryHooks/docketQueryAPI";
import { useDoxleCurrentContextStore } from "../../../../DoxleGeneralStore/useDoxleCurrentContext";
import { DocketStatus } from "../../../../Models/dockets";

type Props = {};

interface IGetNBStatus {
  nbStatusList: DocketStatus[];
  isFetchingStatusList: boolean;
  isErrorFetchingStatusList: boolean;
  isSuccessFetchingStatusList: boolean;
}
const useGetNBStatus = (props: Props): IGetNBStatus => {
  const { company } = useDoxleCurrentContextStore((state) => ({
    company: state.currentCompany,
  }));
  const nbStatusListQuery = DocketQuery.useRetrieveDocketStatusList({
    company,

    filter: {
      isNoticeBoardStatus: true,
    },
  });

  const nbStatusList = nbStatusListQuery.isSuccess
    ? (nbStatusListQuery.data.data as DocketStatus[])
    : [];

  return {
    nbStatusList,
    isFetchingStatusList: nbStatusListQuery.isLoading,
    isErrorFetchingStatusList: nbStatusListQuery.isError,
    isSuccessFetchingStatusList: nbStatusListQuery.isSuccess,
  };
};

export default useGetNBStatus;
