import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDoxleAuthStore } from "../../../DoxleGeneralStore/useDoxleAuthStore";
import { shallow } from "zustand/shallow";
import { useDoxleCurrentContextStore } from "../../../DoxleGeneralStore/useDoxleCurrentContext";
import ProjectQueryAPI from "../../../Services/QueryHooks/projectQueryAPI";
import { ProjectStatus } from "../../../Models/project";

type Props = {};

interface AddProjectStatus {
  projectStatusList: ProjectStatus[];
  isFetchingStatusList: boolean;
  isSuccessFetchingStatusList: boolean;
  isErrorFetchingStatusList: boolean;
  showStatusList: boolean;
  anchorStatusListEl: HTMLElement | null;
  setAnchorStatusListEl: React.Dispatch<
    React.SetStateAction<HTMLElement | null>
  >;
  setShowStatusList: React.Dispatch<React.SetStateAction<boolean>>;
  handleCloseStatusList: () => void;
  handleOpenStatusList: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => void;
  displayList: boolean;
}
const useAddProjectStatus = (props: Props): AddProjectStatus => {
  const [showStatusList, setShowStatusList] = useState(false);
  const [anchorStatusListEl, setAnchorStatusListEl] =
    React.useState<null | HTMLElement>(null);
  const [displayList, setDisplayList] = useState<boolean>(false);
  const company = useDoxleCurrentContextStore(
    (state) => state.currentCompany,
    shallow
  );
  const getProjectStatusQuery = ProjectQueryAPI.useRetrieveProjectStatusList({
    company,
    filter: {}
  });
  const projectStatusList = useMemo(
    () =>
      getProjectStatusQuery.isSuccess ? getProjectStatusQuery.data.data : [],
    [getProjectStatusQuery.data]
  );
  const handleOpenStatusList = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    setAnchorStatusListEl(event.currentTarget);
    setShowStatusList(true);
  };
  const handleCloseStatusList = useCallback(() => {
    setAnchorStatusListEl(null);
    setShowStatusList(false);
  }, [setAnchorStatusListEl, setShowStatusList]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDisplayList(showStatusList);
    }, 300);

    return () => {
      clearTimeout(timeout);
    };
  }, [showStatusList]);

  return {
    projectStatusList,
    isFetchingStatusList: getProjectStatusQuery.isLoading,
    isSuccessFetchingStatusList: getProjectStatusQuery.isSuccess,
    isErrorFetchingStatusList: getProjectStatusQuery.isError,
    showStatusList,
    anchorStatusListEl,
    setAnchorStatusListEl,
    setShowStatusList,
    handleCloseStatusList,
    handleOpenStatusList,
    displayList,
  };
};

export default useAddProjectStatus;
