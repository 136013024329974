import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import { StyledContactItem } from "./StyledDoxleSelectLocationDropdown";
import { HiOutlineUserRemove } from "react-icons/hi";
import { useShallow } from "zustand/react/shallow";
import {Floor, Room} from "../../QA/Models/locations";
interface BaseProps {
  selected: boolean;
  onClearSelected?: () => void;
}

interface FloorProps extends BaseProps {
  floor: Floor;
  onSelect: (item: Floor) => void;

  variant: "floor";
}

interface RoomProps extends BaseProps {
  room: Room;
  onSelect: (item: Room) => void;
  variant: "room";
}
type LocationItemProps = FloorProps | RoomProps;

function LocationItem({
  variant,
  selected,
  onSelect,
  ...props
}: LocationItemProps): JSX.Element {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    useShallow((state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }))
  );
  const handleClick = () => {
    if (variant === "floor" && "floor" in props) {
      onSelect(props.floor);
    } else if (variant === "room" && "room" in props) {
      onSelect(props.room);
    }
  };
  return (
    <StyledContactItem
      onClick={handleClick}
      animate={{
        color: selected
          ? doxleThemeColor.doxleColor
          : doxleThemeColor.primaryFontColor,
      }}
      transition={{ duration: 0.2 }}
    >
      <div className="info-wrapper">
        <span className="contact-name">
          {variant === "floor" && "floor" in props
            ? props.floor.name
            : variant === "room" && "room" in props
            ? props.room.name
            : ""}
        </span>
        <span className="email-text">
          {variant === "room" && "room" in props
            ? props.room.floorName
            : ""}
        </span>
      </div>

      {selected &&
        variant === "floor" &&
        "floor" in props &&
        props.onClearSelected && (
          <HiOutlineUserRemove
            className="remove-icon"
            size={20}
            onClick={(e) => {
              e.stopPropagation();
              if (props.onClearSelected)
                props.onClearSelected();
            }}
          />
        )}

      {selected &&
        variant === "room" &&
        "room" in props &&
        props.onClearSelected && (
          <HiOutlineUserRemove
            className="remove-icon"
            size={20}
            onClick={(e) => {
              e.stopPropagation();
              if (props.onClearSelected) props.onClearSelected();
            }}
          />
        )}
    </StyledContactItem>
  );
}

export default LocationItem;
