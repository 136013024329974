import { useEffect, useMemo, useState } from "react";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import { useDoxleMainMenuStore } from "../../DoxleGeneralStore/useDoxleMainMenuStore";
import {
  StyledMenuThemeAndEllipsis,
  StyledDoxleTopMenuContainer,
  StyledMenuRightSection,
  StyledMenuLeftSection,
  StyledAddBtn,
  StyledDoxleGlobalSearchTextField,
  StyledInputEndornment,
} from "./StyledComponentDoxleTopMenu";

import { useLocation, useNavigate } from "react-router-dom";
import NavBarEllipsis from "./NavBarEllipsis/NavBarEllipsis";
import { useDoxleCurrentContextStore } from "../../DoxleGeneralStore/useDoxleCurrentContext";
import { useDoxleAuthStore } from "../../DoxleGeneralStore/useDoxleAuthStore";
import moment from "moment";
import { useInventoryStore } from "../../Inventory/Store/useInventoryStore";
import { useProjectStore } from "../../DoxleGeneralStore/useprojectStore";
import MenuShareUser from "./MenuShareUser/MenuShareUser";
import MenuToggledApp from "./MenuToggledApp/MenuToggledApp";
import ShareProjectDialog from "./ShareProjectDialog/ShareProjectDialog";
import { TDoxleApp } from "../../Models/DoxleModels";
import { AnimatePresence, Variants } from "framer-motion";
import DoxleCloseBtn from "../../DoxleDesignPattern/DoxleButtons/DoxleCloseBtn";
import { TfiClose } from "react-icons/tfi";
import { TopMenuSearchIcon } from "./DoxleTopMenuIcon";
import { IoMdCloseCircle } from "react-icons/io";
import { useShallow } from "zustand/react/shallow";
import useEffectAfterMount from "../UtilityHooks/useEffectAfterMount";
import { editRgbaAlpha } from "../../Utilities/FunctionUtilities";
type Props = {};

const DoxleTopMenu = () => {
  // const [displaySearchInput, setDisplaySearchInput] = useState<boolean>(false);
  const [searchInput, setSearchInput] = useState<string>("");
  const [isFocusSearch, setIsFocusSearch] = useState(false);
  const { doxleThemeColor, staticMenuColor, currentTheme } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,

      staticMenuColor: state.staticMenuColor,
      currentTheme: state.currentTheme,
    })
  );

  const { currentCompany } = useDoxleCurrentContextStore(
    useShallow((state) => ({
      currentCompany: state.currentCompany,
    }))
  );

  const { user } = useDoxleAuthStore(
    useShallow((state) => ({
      user: state.user,
    }))
  );

  const { setCurrentSearchText, currentMenuPostion, currentSearchText } =
    useDoxleCurrentContextStore(
      useShallow((state) => ({
        setCurrentSearchText: state.setCurrentSearchText,

        currentMenuPostion: state.currentMenuPosition,
        currentSearchText: state.currentSearchText,
      }))
    );

  const { setDisplay } = useInventoryStore((state) => ({
    display: state.displayAddInventoryScreen,
    setDisplay: state.setDisplayAddInventoryScreen,
  }));

  const { selectedProjectTab } = useProjectStore(
    useShallow((state) => ({
      selectedProjectTab: state.selectedProjectTab,
    }))
  );

  useEffect(() => {
    setSearchInput("");
    setCurrentSearchText("");
  }, [currentCompany, selectedProjectTab, window.location.href]);
  useEffectAfterMount(() => {
    if (currentSearchText) {
      setSearchInput((prev) =>
        prev !== currentSearchText ? currentSearchText : prev
      );
    }
  }, [currentSearchText]);
  const { mainMenuList, currentSelectedMainMenu, customiseMenuList } =
    useDoxleMainMenuStore(
      useShallow((state) => ({
        mainMenuList: state.mainMenuList,
        setShowSideMenu: state.setShowSideMenu,
        currentSelectedMainMenu: state.currentSelectedMainMenu,
        setCurrentSelectedMainMenu: state.setCurrentSelectedMainMenu,
        customiseMenuList: state.customiseMenuList,
        setCustomiseMenuList: state.customiseMenuList,
      }))
    );

  useEffect(() => {
    localStorage.setItem("lastMainMenuList", JSON.stringify(mainMenuList));
  }, [mainMenuList]);
  useEffect(() => {
    localStorage.setItem(
      "lastCustomiseMenuList",
      JSON.stringify(customiseMenuList)
    );
  }, [customiseMenuList]);

  //!Useefect to throttle search value in global
  useEffect(() => {
    const timeout = setTimeout(() => {
      setCurrentSearchText(searchInput);
    }, 300);

    return () => {
      clearTimeout(timeout);
    };
  }, [searchInput]);

  //! useefect to reset search when selected tab menu changes
  useEffect(() => {
    setSearchInput("");
  }, [currentSelectedMainMenu]);

  const location = useLocation();
  const urlString = window.location.host.toLowerCase();
  const currentRouteName = location.pathname.replace(/\//g, "");

  const currentDoxleApp: TDoxleApp = urlString.includes("project")
    ? currentRouteName.toLowerCase().includes("file")
      ? "Files"
      : currentRouteName.toLowerCase().includes("pricebook")
      ? "Pricebook"
      : currentRouteName.toLowerCase().includes("contacts")
      ? "Contacts"
      : currentRouteName.toLowerCase().includes("ourstory")
      ? "Our Story"
      : currentRouteName.toLowerCase().includes("settings")
      ? "Settings"
      : currentRouteName.toLowerCase().includes("inventory")
      ? "Inventory"
      : "Projects"
    : urlString.includes("noticeboard")
    ? "Notice Boards"
    : urlString.includes("inventory")
    ? "Inventory"
    : urlString.includes("files")
    ? "Files"
    : urlString.includes("draw")
    ? "Draw"
    : urlString.includes("bill")
    ? "Bills"
    : currentRouteName.toLowerCase().includes("projects")
    ? "Projects"
    : currentRouteName.toLowerCase().includes("noticeboard")
    ? "Notice Boards"
    : currentRouteName.toLowerCase().includes("inventory")
    ? "Inventory"
    : currentRouteName.toLowerCase().includes("file")
    ? "Files"
    : currentRouteName.toLowerCase().includes("draw")
    ? "Draw"
    : currentRouteName.toLowerCase().includes("bill")
    ? "Bills"
    : currentRouteName.toLowerCase().includes("ourstory")
    ? "Our Story"
    : currentRouteName.toLowerCase().includes("setting")
    ? "Settings"
    : currentRouteName.toLowerCase().includes("pricebook")
    ? "Pricebook"
    : currentRouteName.toLowerCase().includes("contacts")
    ? "Contacts"
    : "Projects";
  const navigate = useNavigate();
  const rootMenuContaineranimatedVariants = useMemo(
    () => ({
      initial: {
        y: -100,
        opacity: 0,
      },
      entering: {
        y: 0,
        opacity: 1,
        transition: {
          duration: 0.4,
          delay: 0.3,
        },
      },
    }),
    []
  );

  let lastLoginDate = moment(user?.lastLogin).format("MMM Do YYYY");
  const index = lastLoginDate.indexOf(" ", lastLoginDate.indexOf(" ") + 1);
  lastLoginDate =
    lastLoginDate.slice(0, index) + "," + lastLoginDate.slice(index);

  const textFieldAnimatedVariants: Variants = {
    initial: {
      borderColor: editRgbaAlpha({
        rgbaColor: staticMenuColor.staticDivider,
        alpha: "0",
      }),
      backgroundColor: staticMenuColor.staticTextFieldBg,
    },

    focused: {
      borderColor: editRgbaAlpha({
        rgbaColor: staticMenuColor.staticDivider,
        alpha: "1",
      }),
      backgroundColor: editRgbaAlpha({
        rgbaColor: staticMenuColor.staticHover,
        alpha: "1",
      }),
    },

    hasValue: {
      borderColor: "rgba(255,0,0,0.5)",
    },
  };

  if (currentRouteName !== "AddProject")
    return (
      <StyledDoxleTopMenuContainer
        $position={currentMenuPostion}
        variants={rootMenuContaineranimatedVariants}
        initial="initial"
        animate="entering"
      >
        <StyledMenuLeftSection>
          {/* <DoxleAppDropDown />  */}
          <NavBarEllipsis />

          <MenuToggledApp />
        </StyledMenuLeftSection>

        <StyledDoxleGlobalSearchTextField
          variants={textFieldAnimatedVariants}
          initial="initial"
          animate={
            isFocusSearch ? "focused" : searchInput ? "hasValue" : "initial"
          }
          variant="standard"
          value={searchInput}
          onChange={(event) => setSearchInput(event.target.value)}
          placeholder={"Search"}
          onFocus={() => setIsFocusSearch(true)}
          onBlur={() => setIsFocusSearch(false)}
          InputProps={{
            startAdornment: (
              <StyledInputEndornment position="start">
                <TopMenuSearchIcon theme={currentTheme} />
              </StyledInputEndornment>
            ),
            endAdornment: (
              <StyledInputEndornment
                position="end"
                onClick={() => setSearchInput("")}
                style={{ cursor: "pointer" }}
              >
                {searchInput.length > 0 && (
                  <IoMdCloseCircle
                    size={18}
                    color={editRgbaAlpha({
                      rgbaColor: staticMenuColor.staticFontColor,
                      alpha: "0.7",
                    })}
                  />
                )}
              </StyledInputEndornment>
            ),
          }}
        />

        <StyledMenuRightSection>
          <AnimatePresence>
            {currentDoxleApp === "Projects" && (
              <StyledMenuThemeAndEllipsis
                animate={{
                  y: [-10, 0],
                  opacity: [0, 1],
                }}
                exit={{
                  y: [0, -10],
                  opacity: [1, 0],
                }}
              >
                <MenuShareUser />

                <ShareProjectDialog />

                {/* <StyledNavBarShareButton
                $themeColor={doxleThemeColor}
                $doxleFont={doxleFont}
                onClick={(e) => {
                  if (displayShareBtn) {
                    handleShareButton(e);
                  }
                }}
                layout="position"
                transition={{ duration: 0.2, damping: 16 }}
                style={{
                  opacity: displayShareBtn ? "1" : "0",
                  cursor: displayShareBtn ? "pointer" : "default",
                }}
              >
                Share
              </StyledNavBarShareButton> */}
              </StyledMenuThemeAndEllipsis>
            )}
          </AnimatePresence>
          {currentDoxleApp === "Inventory" && (
            <StyledAddBtn
              $themeColor={doxleThemeColor}
              onClick={() => setDisplay(true)}
            >
              Add
            </StyledAddBtn>
          )}
          {(currentDoxleApp === "Our Story" ||
            currentDoxleApp === "Settings" ||
            currentDoxleApp === "Inventory" ||
            currentDoxleApp === "Files" ||
            currentDoxleApp === "Pricebook" ||
            currentDoxleApp === "Contacts") && (
            <DoxleCloseBtn
              sx={{
                width: 30,
                height: 30,
                padding: "4px !important",
                marginLeft: "14px",
              }}
              onClick={() => {
                navigate("/Projects");
              }}
              closeIcon={
                <TfiClose
                  color={staticMenuColor.staticFontColor}
                  size={16}
                  strokeWidth={0.5}
                />
              }
            />
          )}
        </StyledMenuRightSection>
      </StyledDoxleTopMenuContainer>
    );
  else return <></>;
};

export default DoxleTopMenu;
