import React, { useState } from "react";
import { IBooking } from "../../../../Models/booking";
import { useIsMutating } from "@tanstack/react-query";
import {
  UpdateBookingQueryParams,
  getBookingMutationKey, BookingQueryAPI,
} from "../../../../QueryAPI/bookingQueryAPI";
import { shallow } from "zustand/shallow";
import useBookingDocketStore from "../../../../store/useBookingDocketStore";
import {useDoxleCurrentContextStore} from "../../../../../DoxleGeneralStore/useDoxleCurrentContext";
import useBookingViewStore from "../../../../store/useBookingViewStore";

type Props = { bookingItem: IBooking };

interface BookingTableRow {
  isUpdatingBookingRow: boolean;
  isDeletingBookingRow: boolean;
  handleClickRow: () => void;
  isHoverRow: boolean;
  setisHoverRow: React.Dispatch<React.SetStateAction<boolean>>;
  handleClickDeleteIcon: (
    e: React.MouseEvent<HTMLImageElement, MouseEvent>
  ) => void;
  handleSendBookingAlert: (event: React.MouseEvent<HTMLDivElement>) => void
}
const useBookingTableRow = ({ bookingItem }: Props): BookingTableRow => {
  const [isHoverRow, setisHoverRow] = useState(false);
  const { setEdittedBooking, setDeletedProjectBooking } = useBookingDocketStore(
    (state) => ({
      setEdittedBooking: state.setEdittedBooking,
      setDeletedProjectBooking: state.setDeletedProjectBooking,
    }),
    shallow
  );

  const handleClickRow = () => {
    setEdittedBooking(bookingItem);
  };
  const handleClickDeleteIcon = (
    e: React.MouseEvent<HTMLImageElement, MouseEvent>
  ) => {
    e.stopPropagation();
    e.preventDefault();
    setDeletedProjectBooking(bookingItem);
  };
  const setSendBooking = useBookingViewStore(state => state.setSendBooking, shallow)

  const isUpdatingBookingRow =
    useIsMutating({
      mutationKey: getBookingMutationKey("update"),
      predicate: (query) =>
        (query.state.variables as UpdateBookingQueryParams).bookingId ===
        bookingItem.bookingId,
    }) > 0;
  const isDeletingBookingRow =
    useIsMutating({
      mutationKey: getBookingMutationKey("delete"),
      predicate: (query) => query.state.variables === bookingItem.bookingId,
    }) > 0;
  const handleSendBookingAlert = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    event.preventDefault();
    if (event.currentTarget.classList.contains('sms')) setSendBooking({
      booking: bookingItem,
      type: "sms"
    })
    else if (event.currentTarget.classList.contains('email')) setSendBooking({
      booking: bookingItem,
      type: "email"
    })
  };

  return {
    isUpdatingBookingRow,
    isDeletingBookingRow,
    handleClickRow,
    isHoverRow,
    setisHoverRow,
    handleClickDeleteIcon,
    handleSendBookingAlert
  };
};

export default useBookingTableRow;
