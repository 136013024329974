import { useDoxleCurrentContextStore } from "../../../DoxleGeneralStore/useDoxleCurrentContext";
import { shallow } from "zustand/shallow";
import {
  Docket,
  IFullDocketDetailQueryFilterProp,
  LightDocket,
} from "../../../Models/dockets";
import {
  formDocketListQueryKey,
  getDocketDetailQKey,
  getRelatedBudgetListQueryKey,
} from "../../../Services/QueryHooks/docketQueryAPI";
import { useQueryClient } from "@tanstack/react-query";
import { produce } from "immer";
import {
  DefiniteAxiosQueryData,
  InfiniteAxiosQueryData,
} from "../../../Models/axiosReturn";
import { getProjectTotalQKey } from "../../../Services/QueryHooks/projectQueryAPI";
import {getStageListQueryKey} from "../../../Services/QueryHooks/stageQueryAPI";

type Props = {
  filter: IFullDocketDetailQueryFilterProp;
  appendPos?: "start" | "end";
  overwrite?: boolean;
};
interface SetDocketQueryData {
  handleRemoveDocket: (deletedDocketId: string) => void;
  handleAddDocket: (addedItem: Docket | LightDocket) => void;
  handleUpdateDocket: (edittedItem: Docket | LightDocket) => void;
  handleRemoveDocketWithProjectId: (projectId: string) => void;
  handleUpdateProjectAddress: (projectId: string, newAddress: string) => void;
  isDocketExist: (checkedItemId: string) => boolean;
  handleUpdateMultipleDocket: (
    editedDocketList: Array<Docket | LightDocket>
  ) => void;
  handleRefetchDocketListQuery: () => void;
  handleUpdateRelatedDocketBudgetQuery: (
    projectId: string | null,
    updatedDocket: Docket
  ) => void;
}
const useSetDocketListQueryData = ({
  filter,
  appendPos,
  overwrite = true,
}: Props): SetDocketQueryData => {
  const { currentCompany } = useDoxleCurrentContextStore(
    (state) => ({
      currentCompany: state.currentCompany,
    }),
    shallow
  );
  const qKey = formDocketListQueryKey({ filter, company: currentCompany });
  const queryClient = useQueryClient();
  const handleRemoveDocket = (deletedDocketId: string) => {
    const queryData = queryClient.getQueryData(qKey);
    if (overwrite && queryData) {
      queryClient.setQueryData<InfiniteAxiosQueryData<Docket | LightDocket>>(
        qKey,
        (old) => {
          if (old)
            return produce(old, (draft) => {
              let pageIndexContainItem: number = old.pages.findIndex(
                (page: any) =>
                  Boolean(
                    page.data.results.find(
                      (docket: Docket | LightDocket) =>
                        docket.docketPk === deletedDocketId
                    ) !== undefined
                  )
              );
              if (pageIndexContainItem !== -1)
                draft.pages[pageIndexContainItem].data.results = draft.pages[
                  pageIndexContainItem
                ].data.results.filter(
                  (docket: Docket | LightDocket) =>
                    docket.docketPk !== deletedDocketId
                );
            });
          else queryClient.invalidateQueries(qKey);
        }
      );
    } else queryClient.invalidateQueries(qKey);
    if (filter?.view === "budget" || filter.budget) {
      if (filter.project) {
        const totalsKey = getProjectTotalQKey(filter.project);
        const stagesKey = getStageListQueryKey({projectId: filter.project});
        queryClient.refetchQueries({
          type: "all",
          predicate: (query) =>
            totalsKey.every((item) => query.queryKey.includes(item)),
        });
        queryClient.refetchQueries({
          type: "all",
          predicate: (query) =>
            stagesKey.every((item) => query.queryKey.includes(item)),
        });
      }
    }
  };
  const handleUpdateDocket = (edittedItem: Docket | LightDocket) => {
    const docketQueryData = queryClient.getQueryData(qKey);

    if (docketQueryData && overwrite) {
      queryClient.setQueryData<InfiniteAxiosQueryData<Docket | LightDocket>>(
        qKey,
        (old) => {
          if (old) {
            //find page contain deleted item
            let pageIndexContainItem: number = old.pages.findIndex((page) =>
              Boolean(
                page.data.results.find(
                  (docket) => docket.docketPk === edittedItem.docketPk
                )
              )
            );
            if (pageIndexContainItem !== -1) {
              return produce(old, (draftOld) => {
                const targetItem = draftOld.pages[
                  pageIndexContainItem
                ].data.results.find(
                  (docket) => docket.docketPk === edittedItem.docketPk
                );
                if (targetItem) Object.assign(targetItem, edittedItem);

                return draftOld;
              });
            } else queryClient.invalidateQueries(qKey);
          } else queryClient.invalidateQueries(qKey);
        }
      );
    } else queryClient.invalidateQueries(qKey);
    if (filter?.view === "budget" || filter.budget) {
      if (filter.project) {
        const totalsKey = getProjectTotalQKey(filter.project);
        const stagesKey = getStageListQueryKey({projectId: filter.project});
        queryClient.refetchQueries({
          type: "all",
          predicate: (query) =>
            totalsKey.every((item) => query.queryKey.includes(item)),
        });
        queryClient.refetchQueries({
          type: "all",
          predicate: (query) =>
            stagesKey.every((item) => query.queryKey.includes(item)),
        });
      }
    }
  };
  const handleAddDocket = (addedItem: Docket | LightDocket) => {
    const docketQueryData = queryClient.getQueryData(qKey);
    if (docketQueryData && overwrite) {
      queryClient.setQueryData<InfiniteAxiosQueryData<Docket | LightDocket>>(
        qKey,
        (old) => {
          if (old) {
            const totalNumOfPages = old.pages.length;
            return produce(old, (draftOld: any) => {
              if (!isDocketExist(addedItem.docketPk)) {
                if (appendPos === "start") {
                  (
                    draftOld.pages[0].data.results as Array<
                      Docket | LightDocket
                    >
                  ).unshift(addedItem);
                } else {
                  (
                    draftOld.pages[totalNumOfPages - 1].data.results as Array<
                      Docket | LightDocket
                    >
                  ).push(addedItem);
                }
              }
              return draftOld;
            });
          } else queryClient.invalidateQueries(qKey);
        }
      );
    } else queryClient.invalidateQueries(qKey);
    if (filter?.view === "budget" || filter.budget) {
      if (filter.project) {
        const totalsKey = getProjectTotalQKey(filter.project);
        const stagesKey = getStageListQueryKey({projectId: filter.project});
        queryClient.refetchQueries({
          type: "all",
          predicate: (query) =>
            totalsKey.every((item) => query.queryKey.includes(item)),
        });
        queryClient.refetchQueries({
          type: "all",
          predicate: (query) =>
            stagesKey.every((item) => query.queryKey.includes(item)),
        });
      }
    }
  };

  const handleRemoveDocketWithProjectId = (projectId: string) => {
    const queryData = queryClient.getQueryData(qKey);
    if (overwrite && queryData) {
      queryClient.setQueryData<InfiniteAxiosQueryData<Docket | LightDocket>>(
        qKey,
        (old) => {
          if (old) {
            let deletedData: Array<{
              pageIndex: number;
              deletedDocketId: Array<string>;
            }> = [];

            old.pages.forEach((page, pageIndex) => {
              const deletedIds = page.data.results.reduce((acc, docket) => {
                if (docket.project === projectId)
                  return acc.concat(docket.docketPk);
                else return acc;
              }, [] as string[]);

              if (deletedIds.length > 0)
                deletedData.push({
                  pageIndex,
                  deletedDocketId: deletedIds,
                });
            });

            return produce(old, (draft) => {
              deletedData.forEach((deletedItem) => {
                draft.pages[deletedItem.pageIndex].data.results = draft.pages[
                  deletedItem.pageIndex
                ].data.results.filter(
                  (docket) =>
                    !deletedItem.deletedDocketId.some(
                      (deletedId) => deletedId === docket.docketPk
                    )
                );
              });

              return draft;
            });
          } else queryClient.invalidateQueries(qKey);
        }
      );
    } else queryClient.invalidateQueries(qKey);
    if (filter?.view === "budget" || filter.budget) {
      if (filter.project) {
        const totalsKey = getProjectTotalQKey(filter.project);
        const stagesKey = getStageListQueryKey({projectId: filter.project});
        queryClient.refetchQueries({
          type: "all",
          predicate: (query) =>
            totalsKey.every((item) => query.queryKey.includes(item)),
        });
        queryClient.refetchQueries({
          type: "all",
          predicate: (query) =>
            stagesKey.every((item) => query.queryKey.includes(item)),
        });
      }
    }
  };

  const handleUpdateProjectAddress = (
    projectId: string,
    newAddress: string
  ) => {
    const queryData = queryClient.getQueryData(qKey);
    if (overwrite && queryData) {
      queryClient.setQueryData<InfiniteAxiosQueryData<Docket | LightDocket>>(
        qKey,
        (old) => {
          if (old) {
            let deletedData: Array<{
              pageIndex: number;
              updatedDocketIds: Array<string>;
            }> = [];

            old.pages.forEach((page, pageIndex) => {
              const matchedIds = page.data.results.reduce((acc, docket) => {
                if (docket.project === projectId)
                  return acc.concat(docket.docketPk);
                else return acc;
              }, [] as string[]);

              if (matchedIds.length > 0)
                deletedData.push({
                  pageIndex,
                  updatedDocketIds: matchedIds,
                });
            });

            return produce(old, (draft) => {
              deletedData.forEach((deletedItem) => {
                draft.pages[deletedItem.pageIndex].data.results = produce(
                  draft.pages[deletedItem.pageIndex].data.results,
                  (draftDocketList) => {
                    deletedItem.updatedDocketIds.forEach((updatedId) => {
                      const updatedDocket = draftDocketList.find(
                        (docket) => updatedId === docket.docketPk
                      );
                      if (updatedDocket)
                        updatedDocket.projectSiteAddress = newAddress;
                    });

                    return draftDocketList;
                  }
                );
              });

              return draft;
            });
          } else queryClient.invalidateQueries(qKey);
        }
      );
    } else queryClient.invalidateQueries(qKey);
  };

  const handleUpdateMultipleDocket = (
    editedDocketList: Array<Docket | LightDocket>
  ) => {
    const docketQueryData = queryClient.getQueryData(qKey);

    if (docketQueryData && overwrite) {
      queryClient.setQueryData<InfiniteAxiosQueryData<Docket | LightDocket>>(
        qKey,
        (old) => {
          if (old) {
            let updatedPagesData: {
              pageIndex: number;
              updatedDocketList: Array<Docket | LightDocket>;
            }[] = [];
            old.pages.forEach((page, pageIndex) => {
              const updatedDocketList = page.data.results.filter((docket) => {
                return editedDocketList.some(
                  (editedDocket) => editedDocket.docketPk === docket.docketPk
                );
              });
              if (updatedDocketList.length > 0) {
                updatedPagesData.push({ pageIndex, updatedDocketList });
              }
            });
            return produce(old, (draftOld) => {
              updatedPagesData.forEach((updatedPageData) => {
                updatedPageData.updatedDocketList.forEach((editedDocket) => {
                  const targetItem = draftOld.pages[
                    updatedPageData.pageIndex
                  ].data.results.find(
                    (docket) => docket.docketPk === editedDocket.docketPk
                  );
                  if (targetItem) Object.assign(targetItem, editedDocket);
                });
              });
              return draftOld;
            });
          } else queryClient.invalidateQueries(qKey);
        }
      );
    } else queryClient.invalidateQueries(qKey);
  };
  const handleRefetchDocketListQuery = () => {
    queryClient.refetchQueries(qKey);
  };

  //! With this function, the hook does not need to pass the correct filter, so when declare the hook just set the filter to {} and it will work
  const handleUpdateRelatedDocketBudgetQuery = (
    projectId: string | null,
    updatedDocket: Docket
  ) => {
    const relatedBudgetKey = getRelatedBudgetListQueryKey(
      currentCompany?.companyId,
      projectId
    );
    // update docket list query
    queryClient
      .getQueryCache()
      .findAll({
        type: "all",
        predicate: (query) =>
          relatedBudgetKey.every((item) => query.queryKey.includes(item)),
      })
      .forEach(({ queryKey }) => {
        return queryClient.setQueryData<
          InfiniteAxiosQueryData<Docket | LightDocket>
        >(queryKey, (old) => {
          if (old) {
            //find page contain deleted item
            let pageIndexContainItem: number = old.pages.findIndex((page) =>
              Boolean(
                page.data.results.find(
                  (docket) => docket.docketPk === updatedDocket.docketPk
                )
              )
            );
            if (pageIndexContainItem !== -1) {
              return produce(old, (draftOld) => {
                const targetItem = draftOld.pages[
                  pageIndexContainItem
                ].data.results.find(
                  (docket) => docket.docketPk === updatedDocket.docketPk
                );
                if (targetItem) Object.assign(targetItem, updatedDocket);

                return draftOld;
              });
            } else queryClient.invalidateQueries(qKey);
          } else queryClient.invalidateQueries(qKey);
        });
      });

    //update docket details query
    const docketDetailKey = getDocketDetailQKey(updatedDocket.docketPk);
    queryClient
      .getQueryCache()
      .findAll({
        type: "all",
        predicate: (query) =>
          docketDetailKey.every((item) => query.queryKey.includes(item)),
      })
      .forEach(({ queryKey }) => {
        return queryClient.setQueryData<DefiniteAxiosQueryData<Docket>>(
          queryKey,
          (old) => {
            if (old)
              return produce(old, (draftOld) => {
                if (draftOld.data) Object.assign(draftOld.data, updatedDocket);
                return draftOld;
              });
            else return old;
          }
        );
      });
  };

  const isDocketExist = (checkedItemId: string) => {
    const docketQueryData = queryClient.getQueryData(qKey);
    if (docketQueryData) {
      let matchedItem: number = (
        (docketQueryData as any).pages as Array<any>
      ).find((page) =>
        Boolean(
          (page.data.results as Docket[]).find(
            (docket) => docket.docketPk === checkedItemId
          )
        )
      );

      return Boolean(matchedItem);
    } else return false;
  };

  return {
    handleRemoveDocket,
    handleAddDocket,
    handleUpdateDocket,
    handleRemoveDocketWithProjectId,
    handleUpdateProjectAddress,
    isDocketExist,
    handleUpdateMultipleDocket,
    handleRefetchDocketListQuery,
    handleUpdateRelatedDocketBudgetQuery,
  };
};

export default useSetDocketListQueryData;
