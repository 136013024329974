import React, { useEffect, useState } from "react";
import {
  StyledAddQuoteResponseTextField,
  StyledAddResponseFieldWrapper,
  StyledAddResponseLabelFieldText,
  StyledErrorText,
} from "./StyledComponentsAddQuoteResponse";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { useAddQuoteResponseContext } from "./AddQuoteResponse";
import { produce } from "immer";
import { AnimatePresence } from "framer-motion";

type Props = {};

const AddQuoteResponseReference = (props: Props) => {
  const [referenceInput, setReferenceInput] = useState("");
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore((state) => ({
    doxleThemeColor: state.doxleThemeColor,
    doxleFont: state.doxleFont,
  }));
  const { setNewQuoteResponse, newQuoteResponse, referenceWarning } =
    useAddQuoteResponseContext();

  useEffect(() => {
    const timeout = setTimeout(
      () =>
        setNewQuoteResponse((prev) =>
          produce(prev, (draft) => {
            draft.reference = referenceInput;
            return draft;
          })
        ),
      300
    );

    return () => clearTimeout(timeout);
  }, [referenceInput]);

  return (
    <StyledAddResponseFieldWrapper>
      <StyledAddResponseLabelFieldText
        $themeColor={doxleThemeColor}
        $doxleFont={doxleFont}
      >
        REFERENCE NO
      </StyledAddResponseLabelFieldText>

      <StyledAddQuoteResponseTextField
        $themeColor={doxleThemeColor}
        $doxleFont={doxleFont}
        value={referenceInput}
        onChange={(e) => setReferenceInput(e.target.value)}
        variant="standard"
        placeholder="Quote Reference"
        onFocus={(e) => e.currentTarget.select()}
      />

      <AnimatePresence>
        {referenceWarning && (
          <StyledErrorText
            $doxleFont={doxleFont}
            initial={{ x: -5, opacity: 0 }}
            animate={{
              x: 0,
              opacity: 1,
            }}
            exit={{ x: -5, opacity: 0 }}
          >
            Please enter reference...
          </StyledErrorText>
        )}
      </AnimatePresence>
    </StyledAddResponseFieldWrapper>
  );
};

export default AddQuoteResponseReference;
