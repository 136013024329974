import React from "react";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import useQRLogStore from "../Store/useQRLogStore";
import { useShallow } from "zustand/react/shallow";
import Switch from "@mui/material/Switch";
import { motion } from "framer-motion";
type Props = {};
const AnimatedSwitch = motion(Switch);
const QRViewSwitch = (props: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore((state) => ({
    doxleThemeColor: state.doxleThemeColor,
    doxleFont: state.doxleFont,
  }));
  const { qrView, setQRView } = useQRLogStore(
    useShallow((state) => ({
      qrView: state.qrView,
      setQRView: state.setQRView,
    }))
  );

  const isChecked = qrView === "QRLog";
  return (
    <AnimatedSwitch
      layout="position"
      transition={{
        damping: 16,
        mass: 0.5,
      }}
      checked={isChecked}
      onChange={(e, checked) => {
        if (checked) setQRView("QRLog");
        else setQRView("QRCode");
      }}
      sx={{
        padding: "8px",
        paddingRight: "0px",
        width: "82px",
        height: "38px",
        marginRight: "8px",
        "& .MuiSwitch-track": {
          width: "100%",
          backgroundColor: doxleThemeColor.doxleColor,
          borderRadius: 22 / 2,
          opacity: 1,
          "&::before, &::after": {
            content: '""',
            position: "absolute",
            top: "50%",
            transform: "translateY(-50%)",
            width: "70px",
            height: "30px",
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
            fontSize: "14px",
            lineHeight: "14px",
            fontWeight: 500,
            color: "white",
            fontFamily: doxleFont.primaryFont,
            pointerEvents: "none",
            textAlign: "center",
          },
          "&::before": {
            content: isChecked ? `"Log"` : `""`,

            left: 6,
          },
          "&::after": {
            content: !isChecked ? `"Code"` : `""`,

            right: -4,
          },
          "&.Mui-checked": {
            top: "50%",
            transform: "translate(64px, -50%)",
            color: "white",
          },
        },
        "& .MuiSwitch-thumb": {
          boxShadow: "none",
          width: "17px",
          height: "17px",
          margin: "2px",
        },
        "& .MuiSwitch-switchBase": {
          padding: "0px",
          "&.Mui-checked": {
            top: "50%",
            transform: "translate(60px, -50%)",
            color: "white",
          },
          transform: "translate(9px, -50%)",
          top: "50%",
        },
        "& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track": {
          backgroundColor: doxleThemeColor.doxleColor,
          opacity: 1,
        },
      }}
    />
  );
};

export default QRViewSwitch;
