import { shallow } from "zustand/shallow";
import React, { memo, useCallback, useEffect, useMemo } from "react";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { PricebookSupplierRate } from "../../Models/Pricebook";
import TableContainer from "@mui/material/TableContainer";
import DoxleEmptyPlaceHolder from "../../../DoxleDesignPattern/DoxleEmptyPlaceHolder/DoxleEmptyPlaceHolder";
import {
  TableVirtuoso,
  TableComponents,
  Components,
  GroupItemContent,
  GroupContent,
  GroupedVirtuoso,
} from "react-virtuoso";
import PricebookTableHeader from "./PricebookTableHeader";
import PricebookTableRow from "./PricebookTableRow";
import { StyledListScroller } from "../../../DoxleDesignPattern/ProjectSelectWithDropdown/StyledComponentsProjectSelectWithDropdown";
import useGetRateList from "../../Hooks/useGetRateList";
import {
  StyledPriceListTable,
  StyledPricebookTableBody,
  StyledPricebookTableHead,
  StyledPricebookTableRowWrapper,
  StyledPricebookWrapper,
} from "./StyledComponents";
import { Error404Banner } from "../../../DoxleDesignPattern/DoxleIcons/CommonIcons";
import { EmptyPriceListBanner } from "../PricebookIcons";
import DoxleListSkeleton from "../../../DoxleDesignPattern/DoxleSkeleton/DoxleListSkeleton";
import { FaPlus } from "react-icons/fa6";
import PricebookRateTablePagination from "../PricebookRatePagination/PricebookRateTablePagination";
import { usePricebookStore } from "../../Store/usePricebookStore";
import { useShallow } from "zustand/react/shallow";
import PricebookGroupHeader from "./PricebookGroupHeader";
import { IPricebookTableContext } from "../../Models/PricebookTable";
import usePricebookTable from "./Hook/usePricebookTable";

interface Props {}

const PricebookTable = ({}: Props) => {
  const doxleThemeColor = useDoxleThemeStore((state) => state.doxleThemeColor);

  const { rateList, isFetchingRateList, isErrorFetchingRateList } =
    useGetRateList({});
  const { rateGroupData } = usePricebookTable();

  const { setShowAddPricebookDialog } = usePricebookStore(
    useShallow((state) => ({
      setShowAddPricebookDialog: state.setShowAddPricebookDialog,
    }))
  );

  //* render list

  const groupListComponents: Components<
    PricebookSupplierRate,
    IPricebookTableContext
  > = useMemo(
    () => ({
      Scroller: React.forwardRef((props, ref) => (
        <StyledListScroller ref={ref} {...props} />
      )),

      EmptyPlaceholder: ({ context, ...rest }) => (
        <DoxleEmptyPlaceHolder
          illustration={
            context?.isErrorFetchingRateList ? (
              <Error404Banner
                themeColor={doxleThemeColor}
                containerStyle={{
                  maxWidth: 400,
                  marginBottom: 14,
                }}
              />
            ) : (
              <EmptyPriceListBanner
                themeColor={doxleThemeColor}
                containerStyle={{
                  maxWidth: 400,
                  marginBottom: 14,
                }}
              />
            )
          }
          headTitleText={
            context?.isErrorFetchingRateList
              ? "Something wrong"
              : "No Pricebook Items"
          }
          subTitleText={
            context?.isErrorFetchingRateList
              ? "Failed to get price list"
              : "Add some prices started"
          }
          containerStyle={{
            width: "100%",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            backgroundColor: "transparent",
          }}
          addBtn={{
            btnText: "Add",
            btnColor: doxleThemeColor.primaryFontColor,
            btnTextColor: doxleThemeColor.primaryContainerColor,
            btnHeightInPixel: "40px",
            btnWidthInPixel: "164px",
            btnIcon: (
              <FaPlus
                size={20}
                color={doxleThemeColor.primaryContainerColor}
                style={{ marginRight: "7px" }}
              />
            ),
            btnFunction: () => {
              setShowAddPricebookDialog(true);
            },
          }}
        />
      ),

      Header: ({ context }) =>
        context!.pricebookLength > 0 ? <PricebookTableHeader /> : <></>,
    }),
    []
  );
  const itemContent: GroupItemContent<
    PricebookSupplierRate,
    IPricebookTableContext
  > = useCallback(
    (index, groupIdx, data, context) => (
      <PricebookTableRow
        key={rateGroupData.rateItems[index].rateId}
        rate={rateGroupData.rateItems[index]}
      />
    ),
    [rateGroupData.rateItems]
  );

  const groupPricebookGroupContent: GroupContent<IPricebookTableContext> =
    useCallback(
      (groupIdx, context) => {
        return (
          <PricebookGroupHeader
            headerItem={rateGroupData.pricebookItem[groupIdx]}
            key={rateGroupData.pricebookItem[groupIdx].pricebookId}
          />
        );
      },
      [rateGroupData.pricebookItem]
    );
  const tableStyle: React.CSSProperties = {
    width: "calc(100%)",
    height: "100%",
  };
  return (
    <StyledPricebookWrapper>
      {isFetchingRateList && (
        <DoxleListSkeleton
          containerWidthInRatio={"100%"}
          containerHeightInRatio={"100%"}
          numOfRows={15}
          skeletonType={"pricebookRow"}
          containerStyle={{ marginTop: 45, padding: 10 }}
        />
      )}
      {!isFetchingRateList && (
        <>
          <GroupedVirtuoso
            style={tableStyle}
            context={{
              isErrorFetchingRateList,
              pricebookLength: rateList.length,
            }}
            groupCounts={rateGroupData.rateCounts}
            components={groupListComponents}
            itemContent={itemContent}
            groupContent={groupPricebookGroupContent}
            increaseViewportBy={300}
          />
        </>
      )}
      {rateList.length > 0 && <PricebookRateTablePagination />}
    </StyledPricebookWrapper>
  );
};
export default memo(PricebookTable);
