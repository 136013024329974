import React from "react";
import QuotesQueryAPI from "../../../QueryAPI/quotesQuery";
import { useDoxleAuthStore } from "../../../../DoxleGeneralStore/useDoxleAuthStore";
import { shallow } from "zustand/shallow";
import { useDoxleCurrentContextStore } from "../../../../DoxleGeneralStore/useDoxleCurrentContext";
import { useDoxleQuoteStore } from "../../../store/useDoxleQuoteStore";
import useSetQuoteRequestQueryData from "../../../CustomQueryHooks/useSetQuoteRequestQueryData";
import { useReviewQuoteRequestContext } from "../ReviewQuoteRequest";
import { produce } from "immer";

type Props = {};

interface DeleteQuoteAttachment {
  handleCloseDeleteDialog: () => void;
  handleDeleteAttachment: () => void;
}
const useDeleteQuoteAttachment = (props: Props): DeleteQuoteAttachment => {
  
  const company = useDoxleCurrentContextStore(
    (state) => state.currentCompany,
    shallow
  );
  const { filterGetQuoteRequest } = useDoxleQuoteStore(
    (state) => ({
      filterGetQuoteRequest: state.filterGetQuoteRequest,
    }),
    shallow
  );
  const {
    edittedQuoteRequest,
    setEdittedQuoteRequest,
    deletedQuoteAttachment,
    setDeletedQuoteAttachment,
  } = useReviewQuoteRequestContext();
  const { handleUpdatePartialQuoteRequest } = useSetQuoteRequestQueryData({
    filter: filterGetQuoteRequest,
  });

  const onSuccessDelete = (deletedId?: string) => {
    if (deletedId) {
      handleUpdatePartialQuoteRequest(edittedQuoteRequest.requestId, {
        attachments: edittedQuoteRequest.attachments.filter(
          (attachment) => attachment.attachmentId !== deletedId
        ),
      });
      setEdittedQuoteRequest((prev) =>
        produce(prev, (draft) => {
          draft.attachments = draft.attachments.filter(
            (attachment) => attachment.attachmentId !== deletedId
          );
          return draft;
        })
      );
    }
  };
  const deleteAttachmentQuery = QuotesQueryAPI.useDeleteQuoteRequestAttachment({
    company,

  

    onSuccessDeleteCb: onSuccessDelete,
  });
  const handleCloseDeleteDialog = () => setDeletedQuoteAttachment(undefined);
  const handleDeleteAttachment = () => {
    if (deletedQuoteAttachment)
      deleteAttachmentQuery.mutate(deletedQuoteAttachment.attachmentId);
    handleCloseDeleteDialog();
  };

  return { handleCloseDeleteDialog, handleDeleteAttachment };
};

export default useDeleteQuoteAttachment;
