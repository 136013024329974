import { useCallback, useEffect, useMemo, useState } from "react";
import { Contact } from "../../../Models/addressBook";
import { DropEvent, FileRejection } from "react-dropzone";
import { produce } from "immer";
import { useEditDocketSideScreenStore } from "../../../CoreContent/EditDocketSideScreen/EditDocketSideScreenStore/useEditDocketSideScreenStore";
import { shallow } from "zustand/shallow";
import { useDoxleCurrentContextStore } from "../../../DoxleGeneralStore/useDoxleCurrentContext";
import { NewMail } from "../../../Mail/Models/mail";
import useDoxleNotificationStore from "../../../DoxleGeneralStore/useDoxleNotificationStore";
import { TISODate } from "../../../Models/dateFormat";
import useGetContactCompanyDetail from "../../../CoreContent/QueryDataHooks/GetQueryDataHooks/useGetContactCompanyDetail";
import { IBooking } from "../../Models/booking";
import { BookingQueryAPI } from "../../QueryAPI/bookingQueryAPI";
import useBookingViewStore, {
  ISendBookingDialog,
} from "../../store/useBookingViewStore";
import { useShallow } from "zustand/react/shallow";

interface IEmailBooking {
  booking: IBooking | undefined;
  setBooking: (val: ISendBookingDialog | undefined) => void;
  attachments: File[];
  setAttachments: React.Dispatch<React.SetStateAction<File[]>>;
  watchers: Contact[];
  handleUpdateNewMail: (props: {
    key: keyof NewMail;
    value: TISODate | string;
  }) => void;
  handleAddWatcher: (value: Contact) => void;
  handleRemoveWatcher: (value: Contact) => void;
  handleClickAddBtn: () => void;
  newMail: NewMail;
  ballInCourt: Contact[];
  setBallInCourt: React.Dispatch<React.SetStateAction<Contact[]>>;
  boldMode: boolean;
  setBoldMode: React.Dispatch<React.SetStateAction<boolean>>;
  italicMode: boolean;
  setItalicMode: React.Dispatch<React.SetStateAction<boolean>>;
  onDrop: <T extends File>(
    acceptedFiles: T[],
    fileRejections: FileRejection[],
    event: DropEvent
  ) => void;
  isAddingEmail: boolean;
  handleAddBallInCourt: (value: Contact) => void;
  handleRemoveBallInCourt: (value: Contact) => void;
}
const useEmailBooking = (): IEmailBooking => {
  const [attachments, setAttachments] = useState<File[]>([]);
  const [watchers, setWatchers] = useState<Contact[]>([]);
  const [ballInCourt, setBallInCourt] = useState<Contact[]>([]);
  const [boldMode, setBoldMode] = useState(false);
  const [italicMode, setItalicMode] = useState(false);

  const { sendBooking, setSendBooking } = useBookingViewStore(
    useShallow((state) => ({
      sendBooking: state.sendBooking,
      setSendBooking: state.setSendBooking,
    }))
  );
  const booking: IBooking | undefined = sendBooking?.booking;
  const edittedDocket = useEditDocketSideScreenStore(
    useShallow((state) => state.edittedDocket)
  );
  const currentProject = useDoxleCurrentContextStore(
    useShallow((state) => state.currentProject)
  );
  const initialMail: NewMail = useMemo(
    () => ({
      dueDate: null,
      textBody: "",
      subject: "",
      project: currentProject
        ? currentProject.projectId
        : edittedDocket
        ? edittedDocket.project
        : null,
      docket: edittedDocket ? edittedDocket?.docketPk : null,
      ballInCourt: "",
      watchers: [],
      attachments: [],
      booking: booking?.bookingId ?? null,
    }),
    [currentProject, edittedDocket, booking]
  );
  const [newMail, setnewMail] = useState<NewMail>({ ...initialMail });
  const showNotification = useDoxleNotificationStore(
    useShallow((state) => state.showNotification)
  );

  const { contactCompanyDetails } = useGetContactCompanyDetail({
    contactCompanyId: booking?.assignedContractor ?? undefined,
  });

  useEffect(() => {
    if (contactCompanyDetails && contactCompanyDetails.contactsJson) {
      const primaryContact = contactCompanyDetails.contactsJson.find(
        (contact) => contact.isPrimary
      );

      if (primaryContact) setBallInCourt([primaryContact]);
      else {
        if (contactCompanyDetails.contactsJson.length > 0)
          setBallInCourt([contactCompanyDetails.contactsJson[0]]);
        else setBallInCourt([]);
      }
    } else setBallInCourt([]);
  }, [contactCompanyDetails]);

  const resetInputEmail = () => {
    setnewMail(initialMail);
    setAttachments([]);
    setWatchers([]);
    setBallInCourt([]);
    setBoldMode(false);
    setItalicMode(false);
  };

  const handleUpdateNewMail = (props: {
    key: keyof NewMail;
    value: TISODate | string;
  }) => {
    const { key, value } = props;
    setnewMail((prev) =>
      produce(prev, (draft) => {
        if (key === "subject") draft.subject = value;
        else if (key === "textBody") draft.textBody = value;
        else if (key === "ballInCourt") draft.ballInCourt = value;
        return draft;
      })
    );
  };

  const handleAddWatcher = (value: Contact) => {
    setWatchers((prevState) =>
      produce(prevState, (draft) => {
        if (!prevState.map((c) => c.contactId).includes(value.contactId)) {
          draft.push(value);
        }
        return draft;
      })
    );
  };

  const handleRemoveWatcher = (value: Contact) => {
    setWatchers((prevState) =>
      produce(prevState, (draft) => {
        draft = draft.filter((c) => c.contactId !== value.contactId);
        return draft;
      })
    );
  };

  const handleAddBallInCourt = (value: Contact) => {
    setBallInCourt((prevState) =>
      produce(prevState, (draft) => {
        if (!prevState.find((c) => c.contactId === value.contactId)) {
          draft.push(value);
        }
        return draft;
      })
    );
  };

  const handleRemoveBallInCourt = (value: Contact) => {
    setBallInCourt((prevState) =>
      produce(prevState, (draft) => {
        draft = draft.filter((c) => c.contactId !== value.contactId);
        return draft;
      })
    );
  };
  const company = useDoxleCurrentContextStore(
    useShallow((state) => state.currentCompany)
  );
  const { share } = BookingQueryAPI.useMutateBookingQuery({
    filter: {},
    company,
    showNotification,
    onSuccessShareCb: () => setSendBooking(undefined),
  });

  const handleClickAddBtn = () => {
    if (!booking) {
      showNotification("Unable to send, unrecognized booking!", "error");
      return;
    }
    if (ballInCourt.length > 0 && newMail.subject && newMail.textBody)
      share.mutate({
        bookingId: booking.bookingId,
        toRecipients: ballInCourt.map((contact) => contact.contactId),
        attachments: attachments,
        ccRecipients: watchers.map((contact) => contact.contactId),
        subject: newMail.subject,
        textBody: newMail.textBody,
      });
    else showNotification("Please Fill In All Value", "error");
  };

  const onDrop = useCallback(
    <T extends File>(
      acceptedFiles: T[],
      fileRejections: FileRejection[],
      event: DropEvent
    ) => {
      setAttachments(
        produce((prev) => {
          prev.push(...acceptedFiles);
          return prev;
        })
      );
    },
    []
  );

  //! this useefect is to prefill to contact when api call to get full detail of the edited order
  useEffect(() => {
    if (booking)
      setBallInCourt(
        produce((prev) => {
          const isItemAdded = prev.find(
            (item) => item.contactId === booking?.assignedContact
          );
          if (booking?.assignedContact && !isItemAdded)
            prev.push({
              contactId: booking.assignedContact,
              userCompany: company!.companyId,
              user: "",
              firstName: booking.assignedContactName?.split(" ")?.[0] ?? "",
              lastName: booking.assignedContactName?.split(" ")?.[1] ?? "",
              email: booking?.assignedContactEmail ?? "",
              phone: booking?.assignedContactPhone ?? "",
              contactCompany: booking?.assignedContractor ?? null,
              isPrimary: true,
              sendQuotes: true,
            });
          return prev;
        })
      );
  }, [booking]);

  //! reset all fields when close dialog
  useEffect(() => {
    if (!booking) resetInputEmail();
  }, [booking]);

  return {
    attachments,
    setAttachments,
    watchers,
    handleUpdateNewMail,
    handleAddWatcher,
    handleRemoveWatcher,
    handleClickAddBtn,
    newMail,
    ballInCourt,
    setBallInCourt,
    boldMode,
    setBoldMode,
    italicMode,
    setItalicMode,
    onDrop,
    isAddingEmail: share.isLoading,
    booking,
    setBooking: setSendBooking,
    handleAddBallInCourt,
    handleRemoveBallInCourt,
  };
};

export default useEmailBooking;
