import React, { useEffect, useState } from "react";
import useBookingCalendarStore from "../../store/useBookingCalendarStore";
import { useShallow } from "zustand/react/shallow";
import useEffectAfterMount from "../../../CoreContent/UtilityHooks/useEffectAfterMount";
type Props = {};

const useBookingCalendarFilter = () => {
  const [filterStartDate, setFilterStartDate] = useState<Date | null>(null);
  const [filterEndDate, setFilterEndDate] = useState<Date | null>(null);
  const [filterDropdownAnchor, setFilterDropdownAnchor] =
    React.useState<null | HTMLElement>(null);

  const onClosePopover = () => {
    setFilterDropdownAnchor(null);
  };
  const { setFilterBookingPeriod, filterBookingPeriod } =
    useBookingCalendarStore(
      useShallow((state) => ({
        setFilterBookingPeriod: state.setFilterBookingPeriod,
        filterBookingPeriod: state.filterBookingPeriod,
      }))
    );
  const handleSelectFilter = (filter: "2weeks" | "4weeks") => {
    onClosePopover();
    setTimeout(() => {
      if (filter === "2weeks") {
        setFilterBookingPeriod("2weeks");
      } else if (filter === "4weeks") {
        setFilterBookingPeriod("4weeks");
      }
    }, 400);
  };
  const onSelectFilterDateRange = (
    date: [Date | null, Date | null],
    event: React.SyntheticEvent<any, Event> | undefined
  ) => {
    setFilterStartDate(date[0]);
    setFilterEndDate(date[1]);
  };

  useEffect(() => {
    if (filterEndDate && filterStartDate) {
      setFilterBookingPeriod({
        start: filterStartDate,
        end: filterEndDate,
      });
      onClosePopover();
    }
  }, [filterEndDate, filterStartDate]);

  useEffectAfterMount(() => {
    if (!filterBookingPeriod || typeof filterBookingPeriod === "string") {
      setFilterEndDate(null);
      setFilterStartDate(null);
    }
  }, [filterBookingPeriod]);

  const currentFilter = filterBookingPeriod
    ? filterBookingPeriod === "2weeks"
      ? "2weeks"
      : filterBookingPeriod === "4weeks"
      ? "4weeks"
      : "custom"
    : undefined;
  return {
    filterDropdownAnchor,
    setFilterDropdownAnchor,
    onClosePopover,
    handleSelectFilter,
    filterStartDate,

    filterEndDate,

    onSelectFilterDateRange,
    currentFilter,
  };
};

export default useBookingCalendarFilter;
