import { memo, useRef, useState } from "react";
import { useOrdersStore } from "../../OrdersStore/useOrdersStore";
import {
  StyledOrderHeaderContainer,
  StyledOrderHeaderSubTitle,
  StyledOrderHeaderTitle,
  StyledSearchField,
} from "./StyledComponents";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";

interface props {}
const OrderPageHeader = ({}: props) => {
  const [timer, setTimer] = useState<null | NodeJS.Timeout>(null);
  const { view, setQueryFilter } = useOrdersStore(
    (state) => ({
      view: state.view,
      queryFilter: state.queryFilter,
      setQueryFilter: state.setQueryFilter,
    }),
    shallow
  );
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }),
    shallow
  );

  const searchInputRef: React.MutableRefObject<undefined | HTMLInputElement> =
    useRef();

  const handleSearchChange = () => {
    if (searchInputRef.current)
      setTimer(
        setTimeout(() => {
          setQueryFilter({ search: searchInputRef?.current?.value });
        }, 400)
      );
    else setTimer(null);
  };

  return (
    <StyledOrderHeaderContainer $themeColor={doxleThemeColor}>
      <StyledOrderHeaderTitle themeColor={doxleThemeColor} font={doxleFont}>
        {view === "Orders" ? "Purchase Orders" : "Invoices"}
      </StyledOrderHeaderTitle>
      <StyledOrderHeaderSubTitle themeColor={doxleThemeColor} font={doxleFont}>
        {view === "Orders"
          ? "A purchase order is money going  out / expense to a company. Use the search bar with order \n" +
            "number to get quick access to your purchase orders"
          : "An invoice  is money coming into a company and getting paid on time determines good cash flow. Use the \n" +
            "search bar with invoice number to get quick access to your invoices"}
      </StyledOrderHeaderSubTitle>

      <div className="search-wrapper">
        <StyledSearchField
          $themeColor={doxleThemeColor}
          $doxleFont={doxleFont}
          inputRef={searchInputRef}
          fullWidth
          placeholder={"Search..."}
          onChange={handleSearchChange}
        />
      </div>
    </StyledOrderHeaderContainer>
  );
};

export default memo(OrderPageHeader);
