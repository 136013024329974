import React, { useEffect, useRef, useState } from "react";
import {
  StyledScopeImageInfoTextField,
  StyledScopeImageInfoForm,
} from "./StyledComponentScopeImageGallery";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { useIsMutating } from "@tanstack/react-query";
import ScopeOfWorkAPI, {
  EditScopeImgData,
  getScopeImgMutationKey,
} from "../../QueryAPI/scopeOfWorkQueryAPI";
import { ScopeOfWorkImage } from "../../Model/scopeOfWorks";
import { useDoxleAuthStore } from "../../../DoxleGeneralStore/useDoxleAuthStore";
import { useDoxleCurrentContextStore } from "../../../DoxleGeneralStore/useDoxleCurrentContext";
import useDoxleNotificationStore from "../../../DoxleGeneralStore/useDoxleNotificationStore";
import { CircularProgress } from "@mui/material";

type Props = {
  keyValue: "scopeImageTitle" | "scopeImageDescription";
  scopeImgItem: ScopeOfWorkImage;
};

const ScopeImageInfoTextField = ({ keyValue, scopeImgItem }: Props) => {
  const [textQuery, setTextQuery] = useState(
    keyValue === "scopeImageTitle"
      ? scopeImgItem.scopeImageTitle
      : scopeImgItem.scopeImageDescription
  );
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore((state) => ({
    doxleThemeColor: state.doxleThemeColor,
    doxleFont: state.doxleFont,
  }));


  const { company } = useDoxleCurrentContextStore((state) => ({
    company: state.currentCompany,
  }));
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification
  );
  const updateScopeImgId = ScopeOfWorkAPI.useUpdateScopeImageQuery({
  
    showNotification,
    company,
    scopeItemId: scopeImgItem.scopeItem,
  });

  const handleUpdateScopeImg = () => {
    if (keyValue === "scopeImageTitle") {
      if (scopeImgItem.scopeImageTitle !== textQuery)
        updateScopeImgId.mutate({
          scopeImgId: scopeImgItem.imageId,
          scopeImageTitle: textQuery,
        });
    } else {
      if (scopeImgItem.scopeImageDescription !== textQuery)
        updateScopeImgId.mutate({
          scopeImgId: scopeImgItem.imageId,
          scopeImageDescription: textQuery,
        });
    }
  };
  const isUpdatingItem =
    useIsMutating({
      mutationKey: getScopeImgMutationKey("update", scopeImgItem.scopeItem),
      predicate: (query) =>
        Boolean(
          (query.state.variables as EditScopeImgData).scopeImgId ===
            scopeImgItem.scopeItem &&
            (keyValue === "scopeImageTitle"
              ? (query.state.variables as EditScopeImgData).scopeImageTitle !==
                undefined
              : keyValue === "scopeImageDescription"
              ? (query.state.variables as EditScopeImgData)
                  .scopeImageDescription !== undefined
              : false)
        ),
    }) > 0;
  const inputRef = useRef<HTMLInputElement>(null);
  const handleKeyDownTextField = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.nativeEvent.key === "Enter" || e.nativeEvent.key === "Escape") {
      e.preventDefault();
      inputRef.current?.blur();
    }
  };
  return (
    <StyledScopeImageInfoForm>
      <StyledScopeImageInfoTextField
        variant="standard"
        $isTitle={Boolean(keyValue === "scopeImageTitle")}
        $doxleFont={doxleFont}
        $themeColor={doxleThemeColor}
        inputRef={inputRef}
        onFocus={(e) => e.currentTarget.select()}
        value={textQuery}
        onChange={(
          event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
        ) => setTextQuery(event.target.value)}
        InputProps={{
          endAdornment: isUpdatingItem ? (
            <CircularProgress
              size="1.2rem"
              style={{ color: doxleThemeColor.primaryFontColor }}
            />
          ) : null,
        }}
        onKeyDown={handleKeyDownTextField}
        onBlur={handleUpdateScopeImg}
      />
    </StyledScopeImageInfoForm>
  );
};

export default ScopeImageInfoTextField;
