import { create } from "zustand";
import { FilterRetrieveScopeOfWorkQuery } from "../QueryAPI/scopeOfWorkQueryAPI";
import { ScopeOfWorkGroup, ScopeOfWorkImage } from "../Model/scopeOfWorks";
import { immer } from "zustand/middleware/immer";
import { FileRejection } from "react-dropzone";
import { PopupState } from "material-ui-popup-state/hooks";

export interface IFocusedSOWGroup {
  sowItem: ScopeOfWorkGroup;
  anchorItem: HTMLElement;
}

export interface ScopeOfWorkStore {
  filterScopeOfWorkGroup: FilterRetrieveScopeOfWorkQuery;
  setFilterScopeOfWorkGroup: (filter: FilterRetrieveScopeOfWorkQuery) => void;

  //---> SOW Gallery
  viewedScopeImagedList: ScopeOfWorkImage[] | undefined;
  setViewedScopeImagedList: (item: ScopeOfWorkImage[] | undefined) => void;
  removeViewedScopeImageItem: (item: ScopeOfWorkImage) => void;

  deletedScopeImage: ScopeOfWorkImage | undefined;
  setDeletedScopeImage: (item: ScopeOfWorkImage | undefined) => void;
  //<----

  deletedSOWGroup: ScopeOfWorkGroup | undefined;
  setDeletedSOWGroup: (item: ScopeOfWorkGroup | undefined) => void;
  focusedSOWGroup: IFocusedSOWGroup | undefined;
  setFocusedSOWGroup: (data: IFocusedSOWGroup | undefined) => void;
  updateFocusedSOWGroup: (data: Partial<ScopeOfWorkGroup>) => void;

  expandedSOWGroupIds: string[];
  toggleExpandedSOWGroup: (itemId: string) => void;

  errorDroppedFiles: FileRejection[];
  addErrorDroppedFiles: (files: FileRejection[]) => void;
  clearErrorDroppedFiles: () => void;
  resetStore: () => void;
}

export const useScopeOfWorkStore = create(
  immer<ScopeOfWorkStore>((set, get) => ({
    filterScopeOfWorkGroup: {},
    setFilterScopeOfWorkGroup: (filter: FilterRetrieveScopeOfWorkQuery) =>
      set((state) => {
        state.filterScopeOfWorkGroup = { ...filter };
      }),

    //---> scope image gallery
    viewedScopeImagedList: undefined,
    setViewedScopeImagedList: (item: ScopeOfWorkImage[] | undefined) =>
      set((state) => {
        state.viewedScopeImagedList = item;
      }),
    removeViewedScopeImageItem: (item: ScopeOfWorkImage) =>
      set((state) => {
        state.viewedScopeImagedList = state.viewedScopeImagedList?.filter(
          (img) => img.imageId !== item.imageId
        );
      }),

    deletedScopeImage: undefined,
    setDeletedScopeImage: (item: ScopeOfWorkImage | undefined) =>
      set((state) => {
        state.deletedScopeImage = item;
      }),
    //<-----

    //---> scope group
    deletedSOWGroup: undefined,
    setDeletedSOWGroup: (item: ScopeOfWorkGroup | undefined) =>
      set((state) => {
        state.deletedSOWGroup = item;
      }),
    focusedSOWGroup: undefined,
    setFocusedSOWGroup: (data: IFocusedSOWGroup | undefined) =>
      set({
        focusedSOWGroup: data,
      }),
    updateFocusedSOWGroup: (data: Partial<ScopeOfWorkGroup>) =>
      set((state) => {
        if (state.focusedSOWGroup)
          Object.assign(state.focusedSOWGroup.sowItem, data);
      }),

    expandedSOWGroupIds: [],
    toggleExpandedSOWGroup: (itemId: string) =>
      set((state) => {
        const matchItem = state.expandedSOWGroupIds.findIndex(
          (id) => id === itemId
        );
        if (matchItem !== -1) state.expandedSOWGroupIds.splice(matchItem, 1);
        else state.expandedSOWGroupIds.push(itemId);
      }),

    errorDroppedFiles: [],
    addErrorDroppedFiles: (files: FileRejection[]) =>
      set((state) => {
        state.errorDroppedFiles.push(...files);
      }),
    clearErrorDroppedFiles: () =>
      set((state) => {
        state.errorDroppedFiles = [];
      }),
    resetStore: () =>
      set((state) => {
        state.viewedScopeImagedList = undefined;
        state.errorDroppedFiles = [];
        state.expandedSOWGroupIds = [];
      }),
  }))
);
