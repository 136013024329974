import React, { useState } from "react";
import { DoxleFile } from "../../Models/files";
import { useDoxleCurrentContextStore } from "../../DoxleGeneralStore/useDoxleCurrentContext";
import { useShallow } from "zustand/react/shallow";
import useDoxleNotificationStore from "../../DoxleGeneralStore/useDoxleNotificationStore";
import { useFileStore } from "../Store/useFileStore";
import FilesQueryAPI from "../QueryHooks/FileStorageQueryAPI";
import { useDoxleImageGalleryStore } from "../../DoxleDesignPattern/DoxleGallery/Store/useDoxleImageGalleryStore";

type Props = {
  gridFileItem: DoxleFile;
};

const useGridFileItem = ({ gridFileItem }: Props) => {
  const [onFileRowHover, setOnFileRowHover] = useState<boolean>(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const { company } = useDoxleCurrentContextStore(
    useShallow((state) => ({
      company: state.currentCompany,
    }))
  );
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification
  );
  const { setImageList, setVideoUrl } = useDoxleImageGalleryStore(
    useShallow((state) => ({
      setImageList: state.setImageList,

      setVideoUrl: state.setVideoUrl,
    }))
  );
  const {
    currentFolder,
    selectedFiles,
    clearSelectedFiles,
    setIsProcessing,
    filterRootFileQuery,
  } = useFileStore(
    useShallow((state) => ({
      setIsProcessing: state.setIsProcessing,
      currentFolder: state.currentFolder,
      selectedFiles: state.selectedFiles,
      clearSelectedFiles: state.clearSelectedFiles,
      filterRootFileQuery: state.filterRootFileQuery,
    }))
  );
  const updateFileQuery = FilesQueryAPI.useUpdateFileQuery({
    company,
    showNotification,
    filter: currentFolder
      ? {
          folderId: currentFolder.folderId,
        }
      : filterRootFileQuery,
  });

  const handleDialogKeyDown = (newFileName: string) => {
    updateFileQuery.mutate({
      fileId: gridFileItem.fileId,
      fileName: newFileName,
      currentFolderId: currentFolder ? currentFolder?.folderId : undefined,
      currentFolderName: currentFolder ? currentFolder?.folderName : undefined,
    });

    setDialogOpen(false);
  };

  const handleDialogTick = (newFileName: string) => {
    updateFileQuery.mutate({
      fileId: gridFileItem.fileId,
      fileName: newFileName,
      currentFolderId: currentFolder ? currentFolder?.folderId : undefined,
      currentFolderName: currentFolder ? currentFolder?.folderName : undefined,
    });

    setDialogOpen(false);
  };
  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const lastModifiedFormat = new Date(gridFileItem.modified);
  const formattedLastModified = lastModifiedFormat.toLocaleString("en-EN", {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });

  const openFile = () => {
    if (
      gridFileItem.fileType.toLowerCase().includes("image") &&
      gridFileItem.testUrl
    )
      setImageList([
        { uri: gridFileItem.testUrl, name: gridFileItem.fileName },
      ]);
    else if (
      gridFileItem.fileType.toLowerCase().includes("video") &&
      gridFileItem.url
    )
      setVideoUrl(gridFileItem.url);
    else window.open(gridFileItem.testUrl);
  };

  return {
    openFile,
    onFileRowHover,
    setOnFileRowHover,
    formattedLastModified,
    dialogOpen,
    handleDialogKeyDown,
    handleDialogTick,
    handleDialogClose,
  };
};

export default useGridFileItem;
