import React, { useMemo } from "react";
import { useDoxleCurrentContextStore } from "../../DoxleGeneralStore/useDoxleCurrentContext";
import useDoxleNotificationStore from "../../DoxleGeneralStore/useDoxleNotificationStore";
import ScopeOfWorkAPI, {
  FilterRetrieveScopeItemQuery,
} from "../QueryAPI/scopeOfWorkQueryAPI";
import { ScopeOfWorkGroup, ScopeOfWorkItem } from "../Model/scopeOfWorks";

type Props = {
  scopeGroup: ScopeOfWorkGroup;
};

interface GetScopeItemList {
  scopeItemList: ScopeOfWorkItem[];
  isFetchingScopeItemList: boolean;
  isSuccessFetchingScopeItemList: boolean;
  isErrorFetchingScopeItemList: boolean;
  isAllCompleted: boolean;
}
const useGetScopeItemList = ({ scopeGroup }: Props): GetScopeItemList => {
  const { company } = useDoxleCurrentContextStore((state) => ({
    company: state.currentCompany,
  }));
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification
  );

  const getScopeItemQuery = ScopeOfWorkAPI.useRetrieveScopeItemListQuery({
    showNotification,
    company,
    filter: {
      scopeGroupId: scopeGroup.scopeGroupId,
    },
    enabled: true,
  });
  const scopeItemList: ScopeOfWorkItem[] = useMemo(
    () =>
      getScopeItemQuery.isSuccess
        ? (getScopeItemQuery.data.data as ScopeOfWorkItem[])
        : [],
    [getScopeItemQuery.data]
  );

  const isAllCompleted = useMemo(
    () =>
      Boolean(
        !scopeItemList.find((item) => !item.completed) &&
          scopeItemList.length > 0
      ),
    [scopeItemList]
  );
  return {
    scopeItemList,
    isFetchingScopeItemList: getScopeItemQuery.isLoading,
    isSuccessFetchingScopeItemList: getScopeItemQuery.isSuccess,
    isErrorFetchingScopeItemList: getScopeItemQuery.isError,
    isAllCompleted,
  };
};

export default useGetScopeItemList;
