import React from "react";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import {
  StyledAddQuoteResponseTextField,
  StyledAddResponseFieldWrapper,
  StyledAddResponseInputAdornment,
  StyledAddResponseLabelFieldText,
  StyledErrorText,
  StyledPaymentSuffixPopupContainer,
  StyledResponseNoticeText,
} from "./StyledComponentsAddQuoteResponse";
import { useAddQuoteResponseContext } from "./AddQuoteResponse";
import useAddPaymentTermResponse from "./Hooks/useAddPaymentTermResponse";
import { Popover } from "@mui/material";
import { TPOPaymentTermsSuffix } from "../../models/quote";
import { AnimatePresence } from "framer-motion";

type Props = {};
const SUFFIX_LIST: TPOPaymentTermsSuffix[] = ["Days", "NET", "EOM"];
const AddPaymentTermResponse = (props: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore((state) => ({
    doxleThemeColor: state.doxleThemeColor,
    doxleFont: state.doxleFont,
  }));

  const { newQuoteResponse } = useAddQuoteResponseContext();
  const {
    newPaymentTerm,
    setNewPaymentTerm,
    anchorPopoverTermSuffix,
    handleClickInputAdornment,
    handleClosePopoverSuffix,
    handleSelectSuffix,
  } = useAddPaymentTermResponse({});
  const isOpenPaymentSuffixPopper = Boolean(anchorPopoverTermSuffix);
  const id = isOpenPaymentSuffixPopper ? "suffix-list-popper" : undefined;
  return (
    <StyledAddResponseFieldWrapper>
      <StyledAddResponseLabelFieldText
        $themeColor={doxleThemeColor}
        $doxleFont={doxleFont}
      >
        PREFERRED PAYMENT TERMS
      </StyledAddResponseLabelFieldText>
      <div className="field-row-wrapper">
        <StyledAddQuoteResponseTextField
          $themeColor={doxleThemeColor}
          $doxleFont={doxleFont}
          variant="standard"
          style={{ width: 80 }}
          onFocus={(e) => e.currentTarget.select()}
          inputMode="numeric"
          $textAlign="center"
          onChange={(e) => setNewPaymentTerm(e.target.value)}
          value={newPaymentTerm}
          InputProps={{
            endAdornment: (
              <StyledAddResponseInputAdornment
                $themeColor={doxleThemeColor}
                $doxleFont={doxleFont}
                position="end"
                onClick={handleClickInputAdornment}
              >
                {newQuoteResponse.paymentTermsSuffix}
              </StyledAddResponseInputAdornment>
            ),
          }}
        />

        <StyledResponseNoticeText
          $themeColor={doxleThemeColor}
          $doxleFont={doxleFont}
        >
          (Days within our payment cycle which is 15th and 30th)
        </StyledResponseNoticeText>
      </div>

      <AnimatePresence>
        {!newQuoteResponse.paymentTerms && (
          <StyledErrorText
            $doxleFont={doxleFont}
            initial={{ x: -5, opacity: 0 }}
            animate={{
              x: 0,
              opacity: 1,
            }}
            exit={{ x: -5, opacity: 0 }}
          >
            Please enter payment term...
          </StyledErrorText>
        )}
      </AnimatePresence>
      <Popover
        id={id}
        open={Boolean(isOpenPaymentSuffixPopper)}
        anchorEl={anchorPopoverTermSuffix}
        disableScrollLock
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handleClosePopoverSuffix}
        sx={{
          "& .MuiPopover-paper": {
            backgroundColor: "transparent",
            overflow: "visible",
          },
        }}
        elevation={4}
      >
        <StyledPaymentSuffixPopupContainer
          $themeColor={doxleThemeColor}
          $doxleFont={doxleFont}
        >
          {SUFFIX_LIST.map((suffix, idx) => (
            <div
              className="suffix-choice"
              key={suffix + idx}
              style={{
                color:
                  suffix === newQuoteResponse.paymentTermsSuffix
                    ? doxleThemeColor.doxleColor
                    : doxleThemeColor.primaryFontColor,
              }}
              onClick={() => handleSelectSuffix(suffix)}
            >
              {suffix}
            </div>
          ))}
        </StyledPaymentSuffixPopupContainer>
      </Popover>
    </StyledAddResponseFieldWrapper>
  );
};

export default AddPaymentTermResponse;
