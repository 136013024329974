import React from "react";
import { StyledQAProjectListSkeletonContainer } from "./StyledComponentQAProjectList";
import { useDoxleThemeStore } from "../../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import Skeleton from "@mui/material/Skeleton";
import { editRgbaAlpha } from "../../../../Utilities/FunctionUtilities";

type Props = {};

const QAProjectListSkeleton = (props: Props) => {
  const { doxleThemeColor, doxleFont, currentTheme } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
      currentTheme: state.currentTheme,
    }),
    shallow
  );
  return (
    <StyledQAProjectListSkeletonContainer>
      <div className="skel-header-wrapper">
        <Skeleton
          animation="wave"
          height={"2rem"}
          width="10%"
          sx={{
            bgcolor: doxleThemeColor.skeletonColor,
            marginLeft: "3rem",
          }}
        />
        <div
          style={{
            display: "flex",
            width: "60%",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {Array(4)
            .fill("skel-header")
            .map((skelHeader, idx) => (
              <Skeleton
                key={`${skelHeader}#${idx}`}
                animation="wave"
                height={"2rem"}
                width="10%"
                sx={{
                  bgcolor: doxleThemeColor.skeletonColor,
                }}
              />
            ))}
        </div>
      </div>

      {Array(10)
        .fill("skel-body")
        .map((skelHeader, idx) => (
          <div
            key={`${skelHeader}#${idx}`}
            className="skel-header-wrapper"
            style={{ marginBottom: "0.8rem" }}
          >
            <Skeleton
              animation="wave"
              height={"1.6rem"}
              width="30%"
              sx={{
                bgcolor: doxleThemeColor.skeletonColor,
              }}
            />
            <div
              style={{
                display: "flex",
                width: "60%",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              {Array(4)
                .fill("skel-header")
                .map((skelHeader, idx) => (
                  <Skeleton
                    key={`${skelHeader}#${idx}`}
                    animation="wave"
                    height={"1.6rem"}
                    width="10%"
                    sx={{
                      bgcolor: doxleThemeColor.skeletonColor,
                    }}
                  />
                ))}
            </div>
          </div>
        ))}
    </StyledQAProjectListSkeletonContainer>
  );
};

export default QAProjectListSkeleton;
