import React, { useCallback, useState } from "react";
import { ScopeOfWorkGroup, ScopeOfWorkItem } from "../Model/scopeOfWorks";
import { useDoxleCurrentContextStore } from "../../DoxleGeneralStore/useDoxleCurrentContext";
import useDoxleNotificationStore from "../../DoxleGeneralStore/useDoxleNotificationStore";
import useSetSOWItemQueryData from "./useSetSOWItemQueryData";
import ScopeOfWorkAPI, {
  UpdateScopeItemData,
} from "../QueryAPI/scopeOfWorkQueryAPI";
import { Contact } from "../../Models/addressBook";
import { produce } from "immer";

type Props = { scopeGroup: ScopeOfWorkGroup };

interface ScopeGroupRow {
  handleCloseDialogConfirmDelete: () => void;
  deletedScopeItem: ScopeOfWorkItem | undefined;
  handleDeleteScopeItem: () => void;
  setDeletedScopeItem: React.Dispatch<
    React.SetStateAction<ScopeOfWorkItem | undefined>
  >;
  handleUpdateScopeItem: (data: UpdateScopeItemData) => void;
  handleToggleSOWItemAssigneePopover: (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    item: ScopeOfWorkItem
  ) => void;
  anchorAssigneeSOWItemPopover: HTMLElement | null;
  editedSOWItem: ScopeOfWorkItem | undefined;
  handleCloseSOWItemAssigneePopover: () => void;
  handleUpdateAssigneeSOWItem: (item: Contact) => void;
}
const useScopeGroupRow = ({ scopeGroup }: Props): ScopeGroupRow => {
  const [deletedScopeItem, setDeletedScopeItem] = useState<
    ScopeOfWorkItem | undefined
  >(undefined);

  const [anchorAssigneeSOWItemPopover, setAnchorAssigneeSOWItemPopover] =
    useState<HTMLElement | null>(null);

  const [editedSOWItem, setEditedSOWItem] = useState<
    ScopeOfWorkItem | undefined
  >(undefined);

  const { company } = useDoxleCurrentContextStore((state) => ({
    company: state.currentCompany,
  }));
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification
  );
  const { handleDeleteMultipleSOWItems } = useSetSOWItemQueryData({
    filter: {
      scopeGroupId: scopeGroup.scopeGroupId,
    },
  });
  //# delete scope item query
  const deleteScopeItemQuery = ScopeOfWorkAPI.useDeleteScopeItemQuery({
    company,
    filter: {
      scopeGroupId: scopeGroup.scopeGroupId,
    },
  });

  const handleDeleteScopeItem = useCallback(() => {
    if (deletedScopeItem) {
      deleteScopeItemQuery.mutate(deletedScopeItem.scopeItemId);
      handleDeleteMultipleSOWItems([deletedScopeItem.scopeItemId]);
    }
    setDeletedScopeItem(undefined);
  }, [deletedScopeItem]);

  //# update scope item query

  const onSuccessUpdateSOWItem = (updatedItem?: ScopeOfWorkItem) => {
    if (
      editedSOWItem &&
      updatedItem &&
      editedSOWItem.scopeItemId === updatedItem.scopeItemId
    ) {
      setEditedSOWItem(
        produce((draft) => {
          if (draft) Object.assign(draft, updatedItem);

          return draft;
        })
      );
    }
  };
  const updateScopeItemQuery = ScopeOfWorkAPI.useUpdateScopeItemQuery({
    showNotification,

    company,
    filter: {
      scopeGroupId: scopeGroup.scopeGroupId,
    },
    onSuccessCb: onSuccessUpdateSOWItem,
  });

  const handleUpdateScopeItem = useCallback((data: UpdateScopeItemData) => {
    updateScopeItemQuery.mutate(data);
  }, []);

  const handleUpdateAssigneeSOWItem = useCallback(
    (item: Contact) => {
      if (editedSOWItem) {
        const isItemExist = editedSOWItem.assignedContacts.includes(
          item.contactId
        );
        if (!isItemExist)
          updateScopeItemQuery.mutate({
            scopeItemId: editedSOWItem.scopeItemId,
            assignedContacts: [
              ...editedSOWItem.assignedContacts,
              item.contactId,
            ],
          });
        else
          updateScopeItemQuery.mutate({
            scopeItemId: editedSOWItem.scopeItemId,
            assignedContacts: editedSOWItem.assignedContacts.filter(
              (id) => id !== item.contactId
            ),
          });
      }
    },
    [editedSOWItem]
  );
  const handleToggleSOWItemAssigneePopover = useCallback(
    (e: React.MouseEvent<HTMLElement, MouseEvent>, item: ScopeOfWorkItem) => {
      setAnchorAssigneeSOWItemPopover(e.currentTarget);
      setEditedSOWItem(item);
    },
    []
  );
  const handleCloseSOWItemAssigneePopover = () => {
    setAnchorAssigneeSOWItemPopover(null);
    setEditedSOWItem(undefined);
  };

  const handleCloseDialogConfirmDelete = useCallback(() => {
    setDeletedScopeItem(undefined);
  }, []);
  return {
    handleCloseDialogConfirmDelete,
    deletedScopeItem,
    handleDeleteScopeItem,
    setDeletedScopeItem,
    handleUpdateScopeItem,
    anchorAssigneeSOWItemPopover,
    handleToggleSOWItemAssigneePopover,

    editedSOWItem,
    handleCloseSOWItemAssigneePopover,
    handleUpdateAssigneeSOWItem,
  };
};

export default useScopeGroupRow;
