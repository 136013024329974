import React, { createContext, useContext, useMemo, useRef } from "react";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import {
  StyledAddMailContentWrapper,
  StyledAddMailDialog,
  StyledAddMailFieldWrapper,
  StyledAddMailTitle,
  StyledAddedAttachmentWrapper,
  StyledDialogActionBtnWrapper,
  StyledMailActionSubmitButton,
  StyledMailBodyTextField,
  StyledMailSubjectTextField,
  StyledMailTextBodySection,
  StyledTextModeButton,
} from "./styledComponents";
import { editRgbaAlpha } from "../../../Utilities/FunctionUtilities";
import { useMailStore } from "../../Store/mailStore";
import { TransitionProps } from "@mui/material/transitions";
import Slide from "@mui/material/Slide";
import useAddMail from "./Hooks/useAddMail";
import { useDoxleAuthStore } from "../../../DoxleGeneralStore/useDoxleAuthStore";
import AddMailToSection from "./AddMailToSection";
import { Contact } from "../../../Models/addressBook";
import { NewMail } from "../../Models/mail";
import { TISODate } from "../../../Models/dateFormat";
import AddMailCCSection from "./AddMailCCSection";
import DialogActions from "@mui/material/DialogActions";

import { useDropzone } from "react-dropzone";
import AddedAttachmentItem from "./AddedAttachmentItem";
import ProgressScreen from "../../../Utilities/Lottie/ProgressScreen";
import { ImAttachment } from "react-icons/im";

// Import the types from 'react-quill'
import { AnimatePresence } from "framer-motion";
import {
  DoxleAlignModeIcon,
  DoxleBoldModeIcon,
  DoxleItalicModeIcon,
  DoxleQuoteModeIcon,
} from "../../../DoxleDesignPattern/DoxleGeneralIcons";

type Props = { docket?: true; project?: boolean };

interface IAddMailContextValue {
  attachments: File[];
  setAttachments: React.Dispatch<React.SetStateAction<File[]>>;
  watchers: Contact[];
  handleUpdateNewMail: (props: {
    key: keyof NewMail;
    value: TISODate | string;
  }) => void;
  handleToContactAutocompleteChange: (value: Contact | undefined) => void;
  handleAddWatcher: (value: Contact) => void;
  handleRemoveWatcher: (value: Contact) => void;
  handleClickAddBtn: () => void;
  newMail: NewMail;
  ballInCourt: Contact | undefined;
  setBallInCourt: React.Dispatch<React.SetStateAction<Contact | undefined>>;
}

const AddMailContext = createContext<IAddMailContextValue | null>(null);
const AddMail = ({ docket, project }: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }),
    shallow
  );
  const { onAddNewMailMode, setOnAddNewMailMode } = useMailStore(
    (state) => ({
      onAddNewMailMode: state.onAddNewMailMode,
      setOnAddNewMailMode: state.setOnAddNewMailMode,
    }),
    shallow
  );
  const { user } = useDoxleAuthStore(
    (state) => ({ user: state.user }),
    shallow
  );
  const {
    attachments,
    setAttachments,
    watchers,
    handleUpdateNewMail,
    handleToContactAutocompleteChange,
    handleAddWatcher,
    handleRemoveWatcher,
    handleClickAddBtn,
    newMail,
    ballInCourt,
    setBallInCourt,
    boldMode,
    setBoldMode,
    italicMode,
    setItalicMode,
    onDrop,
    isAddingEmail,
    resetInputEmail,
  } = useAddMail({ docket, project });
  const mailTextBodyInputRef = useRef<HTMLInputElement>(null);
  const dropzoneRef = useRef<HTMLDivElement>(null);
  const { getRootProps, open } = useDropzone({
    onDrop,
    accept: {
      "image/*": [".png", ".jpg", ".jpeg"],
      "application/pdf": [".pdf"],
      "application/msword": [".doc"],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        [".docx"],
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
        ".xlsx",
      ],
    },

    multiple: true,
    noClick: true,
  });
  const handleAddAttachmentBtn = () => {
    if (dropzoneRef.current) {
      open();
    }
  };

  const handleAddQuote = () => {
    if (mailTextBodyInputRef.current) {
      const { selectionStart, selectionEnd } = mailTextBodyInputRef.current;

      if (selectionStart && selectionEnd && selectionStart !== selectionEnd) {
        const beforeSelection = newMail.textBody.slice(0, selectionStart);
        const selectedText = newMail.textBody.slice(
          selectionStart,
          selectionEnd
        );
        const afterSelection = newMail.textBody.slice(selectionEnd);
        const newText = beforeSelection + `"${selectedText}"` + afterSelection;
        handleUpdateNewMail({ key: "textBody", value: newText });
      }
    }
  };
  const contextValue: IAddMailContextValue = useMemo(
    () => ({
      attachments,
      setAttachments,
      watchers,
      handleUpdateNewMail,
      handleToContactAutocompleteChange,
      handleAddWatcher,
      handleRemoveWatcher,
      handleClickAddBtn,
      newMail,
      ballInCourt,
      setBallInCourt,
    }),
    [
      attachments,
      setAttachments,
      watchers,
      handleUpdateNewMail,
      handleToContactAutocompleteChange,
      handleAddWatcher,
      handleRemoveWatcher,
      handleClickAddBtn,
      newMail,
      ballInCourt,
      setBallInCourt,
    ]
  );
  return (
    <AddMailContext.Provider value={contextValue}>
      <StyledAddMailDialog
        $themeColor={doxleThemeColor}
        open={onAddNewMailMode}
        onClose={() => {
          if (!isAddingEmail) setOnAddNewMailMode(false);
        }}
        slotProps={{
          backdrop: {
            sx: {
              bgcolor: editRgbaAlpha({
                rgbaColor: doxleThemeColor.primaryBackdropColor,
                alpha: "0.4",
              }),
            },
          },
        }}
        PaperProps={{
          elevation: 8,
        }}
        TransitionComponent={Transition}
        aria-describedby="add-mail-dialog-slide"
      >
        <StyledAddMailTitle
          $themeColor={doxleThemeColor}
          $doxleFont={doxleFont}
        >
          New Mail
        </StyledAddMailTitle>

        <StyledAddMailContentWrapper
          $themeColor={doxleThemeColor}
          $doxleFont={doxleFont}
        >
          <StyledAddMailFieldWrapper
            $themeColor={doxleThemeColor}
            $doxleFont={doxleFont}
          >
            <span className="label-field"> From:</span>

            <div className="add-content-wrapper">
              {user?.firstName} {user?.lastName}
              <span className="email-text">{`< ${user?.email} >`}</span>
            </div>
          </StyledAddMailFieldWrapper>

          <AddMailToSection />

          <AddMailCCSection />

          <StyledAddMailFieldWrapper
            $themeColor={doxleThemeColor}
            $doxleFont={doxleFont}
            layout
          >
            <span className="label-field"> Subject:</span>

            <div className="add-content-wrapper">
              <StyledMailSubjectTextField
                $themeColor={doxleThemeColor}
                $doxleFont={doxleFont}
                value={newMail.subject}
                onChange={(e) =>
                  handleUpdateNewMail({ key: "subject", value: e.target.value })
                }
                variant="standard"
              />
            </div>
          </StyledAddMailFieldWrapper>

          <StyledMailTextBodySection
            {...getRootProps({})}
            ref={dropzoneRef}
            onClick={() => mailTextBodyInputRef.current?.focus()}
          >
            <StyledMailBodyTextField
              $themeColor={doxleThemeColor}
              $doxleFont={doxleFont}
              value={newMail.textBody}
              onChange={(e) =>
                handleUpdateNewMail({ key: "textBody", value: e.target.value })
              }
              variant="standard"
              inputRef={mailTextBodyInputRef}
              multiline={true}
              $boldMode={boldMode}
              $italicMode={italicMode}
            />

            {attachments.length > 0 && (
              <StyledAddedAttachmentWrapper>
                <AnimatePresence>
                  {attachments.map((file, idx) => (
                    <AddedAttachmentItem
                      item={file}
                      itemIndex={idx}
                      key={`addedFile#${idx}`}
                    />
                  ))}
                </AnimatePresence>
              </StyledAddedAttachmentWrapper>
            )}
          </StyledMailTextBodySection>
        </StyledAddMailContentWrapper>

        <DialogActions
          sx={{
            width: "100%",
            flexDirection: "row",
            display: "flex",
            justifyContent: "space-between",
            padding: "0px !important",
          }}
        >
          <StyledDialogActionBtnWrapper>
            <StyledTextModeButton
              $themeColor={doxleThemeColor}
              $selected={boldMode}
              onClick={() => setBoldMode((prev) => !prev)}
              // onClick={handleBoldClick}
            >
              <DoxleBoldModeIcon themeColor={doxleThemeColor} />
            </StyledTextModeButton>
            <StyledTextModeButton
              $themeColor={doxleThemeColor}
              $selected={italicMode}
              onClick={() => setItalicMode((prev) => !prev)}
              // onClick={handleItalicClick}
            >
              <DoxleItalicModeIcon themeColor={doxleThemeColor} />
            </StyledTextModeButton>
            <StyledTextModeButton
              $themeColor={doxleThemeColor}
              onClick={handleAddQuote}
            >
              <DoxleQuoteModeIcon themeColor={doxleThemeColor} />
            </StyledTextModeButton>
            <StyledTextModeButton $themeColor={doxleThemeColor}>
              <DoxleAlignModeIcon themeColor={doxleThemeColor} />
            </StyledTextModeButton>
            <StyledTextModeButton
              onClick={handleAddAttachmentBtn}
              $themeColor={doxleThemeColor}
            >
              <ImAttachment
                size={18}
                color={doxleThemeColor.primaryFontColor}
              />
            </StyledTextModeButton>
          </StyledDialogActionBtnWrapper>

          <StyledDialogActionBtnWrapper>
            <StyledMailActionSubmitButton
              $themeColor={doxleThemeColor}
              $doxleFont={doxleFont}
              $reverseColor={true}
              onClick={() => {
                resetInputEmail();
                setOnAddNewMailMode(false);
              }}
            >
              <span className="btn-text">Cancel</span>
            </StyledMailActionSubmitButton>

            <StyledMailActionSubmitButton
              $themeColor={doxleThemeColor}
              $doxleFont={doxleFont}
              onClick={handleClickAddBtn}
            >
              <span className="btn-text">Send</span>
            </StyledMailActionSubmitButton>
          </StyledDialogActionBtnWrapper>
        </DialogActions>

        {isAddingEmail && (
          <ProgressScreen
            progressType="send"
            hasBackDrop={true}
            progressText="Sending mail, please wait..."
            textStyle={{
              fontSize: "max(2rem,20px)",
              fontFamily: doxleFont.primaryFont,
            }}
            containerStyle={{
              display: "flex",

              position: "absolute",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          />
        )}
      </StyledAddMailDialog>
    </AddMailContext.Provider>
  );
};

export const useAddMailContext = () =>
  useContext(AddMailContext) as IAddMailContextValue;
export default AddMail;
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
