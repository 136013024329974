import FileHoverMenu from "./FileHoverMenu";
import {
  StyledFileFolderName,
  StyledFileFolderTableRightDiv,
  StyledFileFolderTableRowSectionCell,
  StyledFolderTreeLine,
  StyledImageDiv,
} from "./StyledFilesBodyComponent";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import { useEffect, useState } from "react";
import { DoxleFolder } from "../../Models/files";
import useDoxleNotificationStore from "../../DoxleGeneralStore/useDoxleNotificationStore";
import { useFileStore } from "../Store/useFileStore";
import { shallow } from "zustand/shallow";
import { AnimatePresence } from "framer-motion";
import { FolderItemIcon } from "./FileIcons";
import ListNewFolderPlaceholder from "../Components/ListNewFolderPlaceholder";
import useFoldersRowLeft from "../Hooks/useFoldersRowLeft";
import { CircularProgress } from "@mui/material";

type Props = {
  folderItem: DoxleFolder;
  lastRow?: boolean;
};
const FoldersRowLeft = ({ folderItem, lastRow }: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore((state) => ({
    doxleThemeColor: state.doxleThemeColor,
    doxleFont: state.doxleFont,
  }));
  const {
    folderClicked,
    onFolderRowHover,
    currentFolder,
    setOnFolderRowHover,
    isDeletingFolder,
  } = useFoldersRowLeft({ folderItem });

  return (
    <>
      <StyledFileFolderTableRowSectionCell
        $selected={
          onFolderRowHover ||
          (currentFolder &&
            currentFolder?.folderId === folderItem.folderId &&
            !onFolderRowHover)
        }
        $halfBorder={false}
        onMouseEnter={(event: React.MouseEvent<HTMLDivElement>) => {
          if (!onFolderRowHover) setOnFolderRowHover(true);
        }}
        onMouseLeave={(event: React.MouseEvent<HTMLDivElement>) => {
          //* AS LONGS ITS NOT THE CURRENT SELECTED FOLDER
          if (currentFolder) {
            if (currentFolder?.folderId !== folderItem.folderId)
              setOnFolderRowHover(false);
          } else setOnFolderRowHover(false);
        }}
        exit={{
          scaleX: 0,
          transition: {
            duration: 0.2,
          },
        }}
      >
        <StyledFolderTreeLine />
        <div className="row-content-wrapper">
          <StyledImageDiv>
            <FolderItemIcon themeColor={doxleThemeColor} />
          </StyledImageDiv>
          <StyledFileFolderName
            onClick={folderClicked}
            transition={{
              duration: 0.2,
              damping: 16,
            }}
          >
            {folderItem?.folderName}
          </StyledFileFolderName>

          <AnimatePresence>
            {isDeletingFolder ? (
              <CircularProgress
                color="error"
                size={14}
                style={{ marginLeft: 8 }}
              />
            ) : (
              (onFolderRowHover ||
                (currentFolder &&
                  currentFolder?.folderId === folderItem.folderId &&
                  !onFolderRowHover)) && (
                <StyledFileFolderTableRightDiv
                  initial={{ x: 10, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  exit={{ x: 10, opacity: 0 }}
                >
                  <FileHoverMenu folderItem={folderItem} />
                </StyledFileFolderTableRightDiv>
              )
            )}
          </AnimatePresence>
        </div>
      </StyledFileFolderTableRowSectionCell>
      {lastRow && <ListNewFolderPlaceholder />}
    </>
  );
};

export default FoldersRowLeft;
