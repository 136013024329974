import useSharedOrder from "./Hooks/useSharedOrder";
import { useParams } from "react-router-dom";
import { EditOrderContextValue } from "../EditOrder/EditOrder";
import AcceptOrderDialog from "../EditOrder/AcceptOrderDialog";
import EditOrderGeneralInfo from "../EditOrderSideScreen/EditOrderGeneralInfo";
import EditOrderLineTable from "../EditOrderSideScreen/EditOrderLineTable";
import {
  StyledConfirmOrderButton,
  StyledEditOrderContainer,
} from "../EditOrder/StyledComponentEditOrder";

import { Helmet } from "react-helmet";
import Loading from "../../../Utilities/Lottie/Loading";
import { createContext } from "react";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import DoxleEmptyPlaceHolder from "../../../DoxleDesignPattern/DoxleEmptyPlaceHolder/DoxleEmptyPlaceHolder";
import { AnimatePresence } from "framer-motion";

interface Props {}

const EditOrderContext = createContext<EditOrderContextValue | null>(null);
const SharedOrder = ({}: Props) => {
  const { linkId } = useParams();

  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }),
    shallow
  );

  const {
    order,
    company,
    isUpdatingOrder,
    setShowAcceptOrderDialog,
    contextValue,
  } = useSharedOrder(linkId);

  const rootVariants = {
    initial: {
      x: -10,
      opacity: 0,
    },
    entering: {
      x: 0,
      opacity: 1,
    },
  };

  return (
    <EditOrderContext.Provider value={contextValue}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Doxle Projects</title>
      </Helmet>
      <StyledEditOrderContainer
        variants={rootVariants}
        initial="initial"
        animate="entering"
        $themeColor={doxleThemeColor}
        $doxleFont={doxleFont}
      >
        {!contextValue.isFetchingOrderDetail && (
          <>
            {order && (
              <>
                <div className="title-edit-order">
                  {company?.name} Order - {order?.orderNumber}
                </div>
                <div className="disclaimer">
                  Disclaimer: By accepting PO you acknowledge you have read and
                  agreed to the terms & conditions outlined on the order and
                  accept all items shown for the given price. Please contact the
                  issuer of the order for an ETO for any additional items, as
                  extras will not be paid without an ETO.
                </div>
                <AcceptOrderDialog />

                <div className="order-edit-content-wrapper">
                  <EditOrderGeneralInfo />
                  <EditOrderLineTable />
                </div>

                <div className="action-btn-wrapper">
                  {!order.accepted && (
                    <StyledConfirmOrderButton
                      $themeColor={doxleThemeColor}
                      $doxleFont={doxleFont}
                      onClick={() => setShowAcceptOrderDialog("accept")}
                    >
                      <span className="btn-text"> Accept</span>
                    </StyledConfirmOrderButton>
                  )}
                  {order.accepted !== null && (
                    <StyledConfirmOrderButton
                      $themeColor={doxleThemeColor}
                      $doxleFont={doxleFont}
                      $reverseColor={true}
                      onClick={() => setShowAcceptOrderDialog("reject")}
                    >
                      <span
                        className="btn-text"
                        style={{ color: "red !important" }}
                      >
                        Reject
                      </span>
                    </StyledConfirmOrderButton>
                  )}
                </div>
              </>
            )}

            {!order && <DoxleEmptyPlaceHolder headTitleText="" />}
          </>
        )}

        <AnimatePresence>
          {(isUpdatingOrder || contextValue.isFetchingOrderDetail) && (
            <Loading
              containerStyle={{
                position: "absolute",
                left: 0,
                top: 0,
                bottom: 0,
                right: 0,
                justifyContent: "center",
                alignItems: "center",
              }}
            />
          )}
        </AnimatePresence>
      </StyledEditOrderContainer>
    </EditOrderContext.Provider>
  );
};

export default SharedOrder;
