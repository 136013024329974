import React, { useState } from "react";
import { useDoxleCurrentContextStore } from "../../../../DoxleGeneralStore/useDoxleCurrentContext";
import { ScopeOfWorkImage } from "../../../Model/scopeOfWorks";
import useDoxleNotificationStore from "../../../../DoxleGeneralStore/useDoxleNotificationStore";
import ScopeOfWorkAPI from "../../../QueryAPI/scopeOfWorkQueryAPI";
import useSetSOWItemQueryData from "../../../Hooks/useSetSOWItemQueryData";

type Props = { imgItem: ScopeOfWorkImage };

interface ScopeImageItem {
  handleDeleteScopeImage: (
    e: React.MouseEvent<SVGSVGElement, MouseEvent>
  ) => void;

  isDeletingImg: boolean;
}
const useScopeImageItem = ({ imgItem }: Props): ScopeImageItem => {
  const { company } = useDoxleCurrentContextStore((state) => ({
    company: state.currentCompany,
  }));
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification
  );
  const { handleUpdateSOWItemImgCount } = useSetSOWItemQueryData({
    filter: {
      scopeGroupId: imgItem.scopeGroup,
    },
  });

  const deleteScopeImageQuery = ScopeOfWorkAPI.useMultiDeleteScopeImageQuery({
    showNotification,

    company,
    scopeItemId: imgItem.scopeItem,
    onSuccessCb: (deletedIds) => {
      if (deletedIds)
        handleUpdateSOWItemImgCount(imgItem.scopeItem, -deletedIds.length);
    },
  });

  const handleDeleteScopeImage = (
    e: React.MouseEvent<SVGSVGElement, MouseEvent>
  ) => {
    e.stopPropagation();
    if (!deleteScopeImageQuery.isLoading)
      deleteScopeImageQuery.mutate([imgItem.imageId]);
  };
  return {
    handleDeleteScopeImage,

    isDeletingImg: deleteScopeImageQuery.isLoading,
  };
};

export default useScopeImageItem;
