import { TISODate, TISODateTime } from "../../Models/dateFormat";
import { Contact, ContactCompany } from "../../Models/addressBook";
import { Docket } from "../../Models/dockets";
import { v4 as uuidv4 } from "uuid";
import {Company} from "../../Models/company";
import {User} from "../../Models/user";

export type PaymentTermsSuffix = "Days" | "NET" | "EOM";
export type DamagesPeriod = "Day" | "Month" | "Week";
export interface OrderStatus {
  statusId: string;
  company: string;
  statusName: string;
  color: `rgba(${number},${number},${number},${number})`;
  index: number;
  isDefault: boolean;
  isInvoiceStatus: boolean;
  isOrderStatus: boolean;
  isSummed: boolean;
  setSigned: boolean;
  readonly totalCount: number;
}

export interface OrderLine {
  orderLineId: string; // UUID
  order?: string; // UUID of order to which it belongs
  index: number;
  description: string;
  itemCost: string | number;
  itemFormula?: string;
  quantity: string | number;
  quantityFormula?: string;
  lineCost: string | number;
  ticked?: boolean;
  unit: string;
  estimate?: string | null;
  pricebook?: string | null;
  company?: string;

  isNew?: boolean; //*front end handle effect add new only, not related to backend
}

export interface OrderHistory {
  orderHistoryId: string; // UUID
  order?: string; // UUID of order to which it belongs
  index: number;
  shortText: string;
  longText: string;
  timeStamp: TISODateTime;
  user: null | User;
  project?: null | string;
  company?: string;
}

export default interface Order {
  isInvoice: boolean;

  orderId: string;
  orderNumber: string;
  description: string;
  issueDate: TISODate;
  startDate: TISODate | null;
  endDate: TISODate | null;
  paymentTerms: number;
  paymentTermsSuffix: PaymentTermsSuffix;
  damages: string | number;
  damagesPeriod: DamagesPeriod;
  supplier: string; // Contact Company Name || entered name
  contactCompany: string | null; // UUID
  contactCompanyJson?: ContactCompany;
  primaryContact?: Contact;
  accepted: boolean|null;
  acceptedBy: string|null;
  subTotal: string | number;
  tax: string | number;
  total: string | number;
  specialConditions: string;
  signedOff: string | null; // UUID of User or null
  signedOffName: string;
  emailSent: boolean;
  approved: boolean;
  approvedBy: string | null; //UUID of User or Null
  emailAddresses: string;
  versionId: string;
  status: string;
  statusName: string;
  statusColor: `rgba(${number},${number},${number},${number})`;
  lines?: OrderLine[];
  history?: OrderHistory[];
  totalPaymentsMade?: string | number;
  totalOutstanding?: string | number;
  docket: string | null;
  coreDocket: string | null;
  project: string | null;
  company: string | null;
  totalApprovedPayments?: string;
}

interface NewOrderTempleteProps {
  docket: Docket;
  invoice?: boolean;
  defaultStatus?: OrderStatus;
}

type PartialOrder = Partial<Omit<Order, 'docket'>>
type MinimumNewOrder = Pick<Order, 'docket'>
export interface  NewOrder extends PartialOrder, MinimumNewOrder {}
export const NEW_ORDER_TEMPLATE = ({
  docket,
  invoice,
  defaultStatus,
}: NewOrderTempleteProps): NewOrder => {
  return {
    isInvoice: Boolean(invoice),
    orderNumber: docket.docketId + ".New",
    status: defaultStatus?.statusId,
    docket: docket.docketPk
  };
};
export const NEW_ORDER_LINE_TEMPLATE = (
  order: Order,
  index?: number,
  description?: string
): OrderLine => ({
  orderLineId: uuidv4(),
  order: order.orderId,
  index: index ?? 0,
  description: description ?? "Untitled line",
  itemCost: "0.00",
  itemFormula: "",
  quantity: "0.0",
  quantityFormula: "",
  lineCost: "0.00",
  ticked: false,
  unit: "each",
  estimate: null,
  pricebook: null,
  company: order.company ?? undefined,
  isNew: true,
});

export interface OrderLinkReturn {
  order: Order;
  company: Company;
  user: User;
  token?: string;
}
