import styled from "styled-components";
import {
  DoxleFont,
  DoxleTheme,
  DoxleThemeColor,
} from "../../DoxleGeneralStore/useDoxleThemeStore";
import { Button, InputAdornment, TextField } from "@mui/material";
import { editRgbaAlpha, TRgbaFormat } from "../../Utilities/FunctionUtilities";

export const StyledCompanySettingsContainer = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  display: flex;
  flex-direction: column;
  padding: 5.7rem 6.7rem 0 6.7rem;
  width: calc(100% - 13.7rem);
  height: calc(100% - 5.7rem);
  background-color: ${(p) => p.$themeColor.primaryReverseFontColor};
`;
export const StyledTitle = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  color: ${(p) => p.$themeColor.primaryFontColor};
  font-family: ${(p) => p.$doxleFont.primaryTitleFont};
  font-size: max(40px, 4rem);
  font-weight: 600;
`;
export const StyledCompanySettingsTabContainer = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  flex: 1;
  display: flex;
  width: 100%;
  min-height: 0px;
`;
export const StyledCompanyTabMenuContainer = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  padding-top: 1.6rem;
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 1150px;
  border-bottom: 1px solid ${(p) => p.$themeColor.primaryDividerColor};
`;
export const StyledCompanyTab = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
  $selected?: boolean;
}>`
  transform: translateY(1px);
  font-size: max(14px, 1.4rem);
  color: ${(p) => p.$themeColor.primaryFontColor};
  font-family: ${(p) => p.$doxleFont.primaryTitleFont};
  margin-right: 0.2rem;
  padding: 1.6rem 1.4rem 1rem 0.4rem;
  ${(p) =>
    p.$selected
      ? `
    border-bottom: 1px solid ${p.$themeColor.primaryFontColor};
    font-weight: 500;
    `
      : `cursor: pointer;`}
`;
export const StyledTabContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-left: 0.5rem;
  width: calc(100% - 0.5rem);
  max-width: 1400px;
  flex: 1;
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
  margin-bottom: 10rem;
  @media (max-width: 1000px) {
    flex-direction: column;
  }
`;
export const StyledCompanySettingsTabLeftColumn = styled.div`
  padding-top: 6.5rem;
  width: 65%;
  display: flex;
  flex-direction: column;
  @media (max-width: 1000px) {
    width: 100%;
  }
`;
export const StyledCompanySettingsTabRightColumn = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  .left {
    /* flex: 4; */
  }
  svg {
    max-width: 80%;
  }
  .right {
    /* flex: 1; */
  }
`;
export const StyledEditCompanyDetailsSection = styled.div`
  display: flex;
  flex-direction: column;
  height: fit-content;
`;
export const StyledCompanySettingsTextFieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
export const StyledCompanySettingsTextFieldLabel = styled.label<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  font-size: max(12px, 1.2rem);
  font-family: ${(p) => p.$doxleFont.primaryTitleFont};
  color: ${(p) => p.$themeColor.primaryFontColor};
`;
export const StyledCompanySettingsTextField = styled.input<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  font-family: ${(p) => p.$doxleFont.primaryTitleFont};
  font-size: max(13px, 1.3rem);
  width: 402px;
  max-width: calc(100% - 2rem - 2px);
  border-radius: 0.3rem;
  background-color: ${(p) => p.$themeColor.secondaryTextFieldBackgroundColor};
  border: 1px solid ${(p) => p.$themeColor.secondaryTextFieldBackgroundColor};
  color: ${(p) => p.$themeColor.primaryFontColor};
  &:hover {
    border: 1px solid
      ${(p) =>
        editRgbaAlpha({ rgbaColor: p.$themeColor.doxleColor, alpha: "0.2" })};
  }
  &:focus {
    outline: none;
    border: 1px solid
      ${(p) =>
        editRgbaAlpha({ rgbaColor: p.$themeColor.doxleColor, alpha: "0.2" })};
  }
  padding: 1rem;
  margin-top: 0.3rem;
  margin-bottom: 2.5rem;
`;
export const StyledCompanySettingsTextArea = styled.textarea<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  font-family: ${(p) => p.$doxleFont.primaryTitleFont};
  font-size: max(13px, 1.3rem);
  width: 402px;
  max-width: calc(100% - 2rem - 2px);
  border-radius: 0.3rem;
  background-color: ${(p) => p.$themeColor.secondaryTextFieldBackgroundColor};
  border: 1px solid ${(p) => p.$themeColor.secondaryTextFieldBackgroundColor};
  color: ${(p) => p.$themeColor.primaryFontColor};
  &:hover {
    border: 1px solid
      ${(p) =>
        editRgbaAlpha({ rgbaColor: p.$themeColor.doxleColor, alpha: "0.2" })};
  }
  &:focus {
    outline: none;
    border: 1px solid
      ${(p) =>
        editRgbaAlpha({ rgbaColor: p.$themeColor.doxleColor, alpha: "0.2" })};
  }
  height: calc(5 * 13px + 2rem);
  padding: 1rem;
  margin-top: 0.3rem;
  margin-bottom: 2.5rem;
  resize: none;
`;
export const StyledCompanyLogoRow = styled.div<{}>`
  display: flex;
  flex-direction: row;
`;
export const StyledLogoDisplayer = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  border-radius: 0.3rem;
  border: 1px solid ${(p) => p.$themeColor.secondaryTextFieldBackgroundColor};
  padding: 0.5rem;
  height: calc(46px);
  width: calc(50px);
  margin-top: 0.3rem;
  margin-bottom: 2.5rem;
`;
export const StyledLogoText = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  padding-left: 2rem;
  margin-top: 25px;
  font-family: ${(p) => p.$doxleFont.primaryTitleFont};
  font-size: max(12px, 1.2rem);
  color: ${(p) =>
    editRgbaAlpha({ rgbaColor: p.$themeColor.primaryFontColor, alpha: "0.4" })};
`;
export const StyledStatusTileSection = styled.div<{}>`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;
export const StyledStatusTypeHeader = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  font-size: 14px;
  font-family: ${(p) => p.$doxleFont.primaryTitleFont};
  font-weight: 500;
  color: ${(p) => p.$themeColor.primaryFontColor};
`;
export const StyledEditStatusTileWrapper = styled.div<{}>`
  display: flex;
  flex-direction: row;
  .drag-icon {
    cursor: grab;
    display: none;
  }
  &:hover {
    .drag-icon {
      display: block;
    }
  }
`;
export const StyledDragIconContainer = styled.div<{}>`
  width: 15px;
  align-items: center;
  justify-content: center;
  display: flex;
`;

export const StyledEditStatusTile = styled.div<{
  $statusColor: TRgbaFormat;
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
  $currentTheme: DoxleTheme;
}>`
  display: flex;
  flex-direction: row;
  background-color: ${(p) => p.$statusColor};
  min-height: 20px;
  align-items: center;
  border-radius: 18px;
  background-color: ${(p) =>
    editRgbaAlpha({
      rgbaColor: p.$statusColor,
      alpha: p.$currentTheme === "dark" ? "0.4" : "0.15",
    })};
  width: fit-content;
  padding: 6px;
  font-size: 14px;
  line-height: 14px;
  margin: 11px 11px 11px 0;

  svg {
    cursor: pointer;
    margin-left: 4px;
    padding: 2px;
  }
`;

export const StyledStatusColorDot = styled.div<{
  $statusColor: TRgbaFormat;
  $size: `${number}px` | `${number}rem` | `${number}em` | `${number}%`;
}>`
  width: ${(p) => p.$size};
  height: ${(p) => p.$size};
  background-color: ${(p) => p.$statusColor};
  border-radius: 50%;
  margin: auto 4px;
  cursor: pointer;
  border: 0.5px solid
    ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.theme.color.primaryFontColor,
        alpha: "0.2",
      })};
`;
export const StyledStatusPicker = styled.div<{}>`
  display: flex;
  flex-direction: column;

  background-color: ${(p) => p.theme.color.primaryContainerColor};
  box-shadow: 0px 0px 8px 0px ${(p) => p.theme.color.primaryBoxShadowColor};
  border-radius: 0 10px 10px 10px;
  padding: 10px;

  .color-picker {
    width: 200px;
    aspect-ratio: 0.55;
  }
`;
export const StyledColorPickerButton = styled.button<{
  $doxleFont: DoxleFont;
  $themeColor: DoxleThemeColor;
}>`
  color: ${(p) => p.$themeColor.primaryFontColor};
  width: 100%;
  height: 36px;
  margin-top: 1.2rem;
  background-color: ${(p) =>
    editRgbaAlpha({ rgbaColor: p.$themeColor.doxleColor, alpha: "0.35" })};
  border-radius: 0.5rem;
  // box-shadow: 1px 1px 2px $ {(p) => p.$themeColor.primaryBoxShadowColor};
  border: none;
  padding: 0;
  overflow: visible;
  cursor: pointer;
  align-self: end;
  font-size: 14px;
  font-family: ${(p) => p.$doxleFont.primaryTitleFont};
  font-weight: 500;
  color: ${(p) => p.$themeColor.primaryFontColor};
  &:hover {
    background-color: ${(p) =>
      editRgbaAlpha({ rgbaColor: p.$themeColor.doxleColor, alpha: "0.5" })};
    transform: scale(1.04);
  }
  &:active {
    transform: scale(0.97) translateY(-1px);
  }
  transition: 0.4s;
`;
export const StyledStatusOptionDropdown = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
  $top: number;
  $left: number;
}>`
  min-width: 200px;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: ${(p) => `calc(${p.$top}px - 56px)`};
  left: ${(p) => p.$left}px;
  background-color: ${(p) => p.$themeColor.primaryContainerColor};
  box-shadow: 0px 0px 8px 0px ${(p) => p.$themeColor.primaryBoxShadowColor};
  border-radius: 0 1rem 1rem 1rem;
`;
export const StyledStatusDropDownOption = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
  $disabled?: boolean;
  $selected?: boolean;
}>`
  width: calc(100% - 3rem);
  font-size: 14px;
  font-family: ${(p) => p.$doxleFont.primaryTitleFont};
  font-weight: ${(p) => (p?.$selected ? 700 : 500)};
  color: ${(p) =>
    editRgbaAlpha({
      rgbaColor: p?.$selected
        ? p.$themeColor.doxleColor
        : p.$themeColor.primaryFontColor,
      alpha: p?.$disabled ? "0.5" : "1",
    })};
  ${(p) => (p?.$disabled ? "" : `cursor: pointer;`)}
  padding: 0.6rem 1.5rem;
  border-bottom: 1px solid ${(p) => p.$themeColor.primaryDividerColor};
  &:hover {
    background-color: ${(p) => p.$themeColor.primaryHoverColor};
  }
`;
export const StyledEditStatusTextField = styled.input<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
  value: string;
}>`
  font-family: ${(p) => p.$doxleFont.primaryTitleFont};
  font-size: max(13px, 1.3rem);
  border-radius: 0.1rem;
  background-color: transparent;
  border: 1px solid transparent;
  color: ${(p) => p.$themeColor.primaryFontColor};
  width: 120px;
  height: 20px;
  &:hover {
    border: 1px solid
      ${(p) =>
        editRgbaAlpha({ rgbaColor: p.$themeColor.doxleColor, alpha: "0.2" })};
  }
  &:focus {
    outline: none;
    border: 1px solid
      ${(p) =>
        editRgbaAlpha({ rgbaColor: p.$themeColor.doxleColor, alpha: "0.2" })};
  }
  padding: 0.1rem;
  margin-top: -5px;
`;
export const StyledAddStatusTextField = styled(TextField)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  && {
    width: 250px;
    height: 30px;
    border-radius: 3px;
    background-color: transparent;
  }
  input {
    padding: 12px 10px 8px 0 !important;
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.28px;
    color: ${(p) => p.$themeColor.primaryFontColor};

    &::placeholder {
      font-family: ${(p) => p.$doxleFont.primaryFont};
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.28px;
      color: ${(p) =>
        editRgbaAlpha({
          rgbaColor: p.$themeColor.primaryFontColor,
          alpha: "0.7",
        })};
    }
  }
  & .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }
  & .MuiInputBase-root {
    height: 100%;
    padding-left: 0;
  }
`;
export const StyledInputAdornment = styled(InputAdornment)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  && {
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-weight: 400;
    font-size: 18px;
    line-height: normal;
    color: ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.$themeColor.primaryFontColor,
        alpha: "0.6",
      })};
    padding: 0px !important;
  }
  & .MuiTypography-root {
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-weight: 400;
    font-size: 18px;
    line-height: normal;
    color: ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.$themeColor.primaryFontColor,
        alpha: "0.6",
      })};
  }
`;
export const StyledStatusArea = styled.div`
  margin-bottom: 3.5rem;
`;
export const StyledAutocompleteTextField = styled(TextField)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  && {
    background-color: ${(p) => p.$themeColor.secondaryTextFieldBackgroundColor};
    width: calc(100% - 4rem);
    margin: 0 2rem 0 2rem;
    border: 1px solid
      ${(p) =>
        editRgbaAlpha({
          rgbaColor: p.$themeColor.doxleColor,
          alpha: "0.4",
        })};
    border-radius: 0.8rem;
  }
  & input {
    font-size: 14px;
    background-color: ${(p) => p.$themeColor.secondaryTextFieldBackgroundColor};
  }
  & .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }
  & .MuiAutocomplete-popper {
    font-size: 14px;
  }
`;
export const StyledStatusListPlaceholderContainer = styled.div<{
  $themeColor: DoxleThemeColor;
}>`
  font-size: 14px;
  color: ${(p) => p.$themeColor.primaryFontColor};
  height: 48px;
  display: flex;
  align-items: center;
`;

export const StyledSaveBtnContainer = styled.div`
  width: 420px;
  display: flex;
  justify-content: end;
  position: absolute;
  bottom: 50px;
`;

export const StyledSaveButton = styled(Button)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  && {
    margin-top: 1.4rem;
    background-color: ${(p) => p.$themeColor.primaryFontColor};
    font-family: ${(p) => p.$doxleFont.secondaryTitleFont};
    font-style: normal;
    font-weight: 500;
    font-size: 1.4rem;
    width: fit-content;
    text-transform: capitalize !important;

    color: ${(p) => p.$themeColor.primaryContainerColor};
    border-radius: 0.4rem !important;
    padding: 4px 1.4rem !important;

    &:hover {
      background-color: ${(p) =>
        editRgbaAlpha({
          rgbaColor: p.$themeColor.primaryFontColor,
          alpha: "0.5",
        })} !important;
    }
  }

  &.MuiButton-root.Mui-disabled {
    background-color: ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.$themeColor.primaryFontColor,
        alpha: "0.5",
      })} !important;
    color: ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.$themeColor.primaryContainerColor,
        alpha: "0.5",
      })} !important;
  }
`;
