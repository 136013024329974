import { motion } from "framer-motion";
import styled from "styled-components";
import {
  DoxleFont,
  DoxleThemeColor,
} from "../../../../DoxleGeneralStore/useDoxleThemeStore";
import TextField from "@mui/material/TextField";
import { editRgbaAlpha } from "../../../../Utilities/FunctionUtilities";
import Select from "@mui/material/Select";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";

export const StyledReviewPaymentClaimContainer = styled(motion.div)`
  min-width: 300px;
  width: 100%;

  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;
export const StyledReviewPaymentTitle = styled.h1<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  font-family: ${(p) => p.$doxleFont.primaryTitleFont};
  font-style: normal;
  font-weight: 600;
  font-size: 3rem;
  line-height: 3.9rem;
  letter-spacing: 0.005rem;
  margin: 4px 0px !important;
  color: ${(p) => p.$themeColor.primaryFontColor};
`;
export const StyledReviewPaymentSubtitle = styled.span<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  font-family: ${(p) => p.$doxleFont.primaryTitleFont};
  font-style: normal;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.6rem;
  letter-spacing: 0.005rem;
  text-transform: none;
  color: ${(p) => p.$themeColor.primaryFontColor};
`;
export const StyledReviewPaymentTextField = styled(TextField)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  && {
    margin: 1.5rem 8px;
    width: calc(100% - 16px);

    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 400px;
  }

  input {
    font-family: ${(p) => p.$doxleFont.primaryTitleFont};
    font-style: normal;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 1.5rem;
    letter-spacing: 0.05em;
    padding: 0px 0.8rem !important;
    width: calc(100% - 1.6rem);
    background-color: ${(p) => p.$themeColor.primaryContainerColor};
    border-radius: 0px 0px 0px 4px;
    height: 3rem !important;
    color: ${(p) => p.$themeColor.primaryFontColor} !important;
    &::placeholder {
      font-family: ${(p) => p.$doxleFont.primaryTitleFont};
      font-style: normal;
      font-weight: 400;
      font-size: 1.2rem;
      line-height: 1.5rem;
      text-align: left;
      letter-spacing: 0.05em;
      color: ${(p) =>
        editRgbaAlpha({
          rgbaColor: p.$themeColor.primaryFontColor,
          alpha: "0.7",
        })};
    }
  }
  &.MuiOutlinedInput-root {
    height: 100%;
  }
  & .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }
  & label {
    font-family: ${(p) => p.$doxleFont.primaryTitleFont};
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    margin-top: -0.8rem !important;
    margin-left: -0.4rem !important;
    color: ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.$themeColor.primaryFontColor,
        alpha: "0.7",
      })};
  }
  & .MuiInputLabel-root.Mui-disabled {
    color: ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.$themeColor.primaryFontColor,
        alpha: "0.7",
      })};
  }
  & .MuiFormHelperText-root {
    color: red !important;
    font-size: 1.1rem !important;
  }
`;
export const StyledReivewPaymentStatusSelect = styled(Select)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  && {
    height: 3rem !important;
    margin: 0px 8px 15px 8px;
    width: calc(100% - 16px);
    max-width: 144px;
    background-color: ${(p) => p.$themeColor.primaryContainerColor};
    border-radius: 0px 0px 0px 4px;
    display: flex;

    align-items: flex-start;
    padding: 0px !important;
    border: none !important;
  }

  & .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }
  & label {
    color: ${(p) => p.$themeColor.doxleColor} !important;
    font-size: 1.6rem !important;
  }
  & .MuiFormHelperText-root {
    color: red !important;
    font-size: 1.1rem !important;
  }
  & .MuiSelect-root {
    height: 100% !important;
  }
  & .MuiInput-root-MuiSelect-root:after {
    border: none !important;
  }
  & .MuiSelect-select {
    font-family: ${(p) => p.$doxleFont.primaryTitleFont};
    font-style: normal;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 1.5rem;
    letter-spacing: 0.05em;
    padding: 0px;
    height: 100% !important;
    display: flex;
    align-items: center;
    padding-left: 0.8rem;
  }
`;
export const StyledReviewPaymentDateCalendar = styled(DateCalendar)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
    &.MuiDateCalendar-root {
      border-radius: 4px;
      background-color: ${(p) => p.$themeColor.primaryTableRowColor};
      box-shadow: 0px 0px 8px 0px ${(p) => p.$themeColor.primaryBoxShadowColor};
      margin-top: 8px;
    }
    & .MuiPickersCalendarHeader-labelContainer {
      font-family: ${(p) => p.$doxleFont.secondaryFont};
      font-style: normal;
      font-weight: 500;
      font-size: 1.8rem;
      line-height: 2rem;
      letter-spacing: 0.005rem;
  
      color: ${(p) => p.$themeColor.primaryFontColor};
    }
    & .MuiDayCalendar-weekDayLabel {
      font-family: ${(p) => p.$doxleFont.secondaryFont};
      font-style: normal;
      font-weight: 500;
      font-size: 1.4rem
      line-height: 1.6rem;
      letter-spacing: 0.005rem;
      color: ${(p) =>
        editRgbaAlpha({
          rgbaColor: p.$themeColor.primaryFontColor,
          alpha: "0.6",
        })}
    }
  `;
export const StyledPaymentClaimDateSelect = styled(motion.div)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  border-radius: 0px 0.4rem;
  background-color: ${(p) => p.$themeColor.primaryContainerColor};
  color: ${(p) => p.$themeColor.primaryFontColor};
  cursor: pointer;
  min-width: 14rem;
  justify-content: center;
  font-size: 1.3rem;
  font-weight: 500;
  font-family: ${(p) => p.$doxleFont.primaryFont};
  align-items: center;
  flex-direction: row;
  align-self: flex-start;
  display: flex;
  padding: 0px 1.2rem;
  height: 2.7rem;
`;
export const StyledPaymentClaimFieldWrapper = styled.div<{}>`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 2rem;
`;
export const StyledPaymentClaimNoteTextField = styled(TextField)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  && {
    margin: 1.5rem 8px;
    width: calc(100% - 16px);

    max-width: 600px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    align-self: flex-start;
  }
  input {
    font-family: ${(p) => p.$doxleFont.primaryTitleFont};
    font-style: normal;
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 1.5rem;
    letter-spacing: 0.005rem;
    padding: 0px !important;
    color: ${(p) => p.$themeColor.primaryFontColor};
    &::placeholder {
      font-family: ${(p) => p.$doxleFont.primaryTitleFont};
      font-style: normal;
      font-weight: 400;
      font-size: 1.2rem;
      line-height: 1.5rem;
      letter-spacing: 0.005rem;
      color: ${(p) =>
        editRgbaAlpha({
          rgbaColor: p.$themeColor.primaryFontColor,
          alpha: "0.7",
        })};
      opacity: 1;
    }
  }
  & .MuiOutlinedInput-input {
    height: 4rem;
    background-color: ${(p) => p.$themeColor.primaryContainerColor};

    border-radius: 0px 0px 0px 4px;
    padding: 0.4rem 0.8rem !important;
    width: calc(100% - 1.6rem);
  }
  & .MuiOutlinedInput-root {
    padding: 0px !important;
  }
  & .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }
  & label {
    font-family: ${(p) => p.$doxleFont.primaryTitleFont};
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: -0.8rem !important;
    color: ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.$themeColor.primaryFontColor,
        alpha: "0.7",
      })};
  }
  & .MuiFormHelperText-root {
    color: red !important;
    font-size: 1.1rem !important;
  }
`;
export const StyledStatusMenuItem = styled(MenuItem)<{
  $color: string;
  $doxleFont: DoxleFont;
  $themeColor: DoxleThemeColor;
}>`
  && {
    font-family: ${(p) => p.$doxleFont.primaryTitleFont};
    font-style: normal;
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 1.5rem;
    letter-spacing: 0.005rem;

    color: ${(p) => p.$color};
    padding: 0.4rem 0.8rem !important;
    margin-bottom: 4px;
    border-radius: 0.4rem !important;
    &:hover {
      background-color: ${(p) => p.$themeColor.hoverColor};
    }
  }
`;
export const StyledStatusFieldLabel = styled.span<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  && {
    font-family: ${(p) => p.$doxleFont.primaryTitleFont};
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 0.2rem;
    color: ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.$themeColor.primaryFontColor,
        alpha: "0.7",
      })};
    margin-left: 15px;
  }
`;
export const StyledXeroAttachmentContainer = styled.div<{}>`
  display: flex;
  flexDirection: row;
  width: 100%;
  /* height: 50px; */
`
export const StyledAttachmentSpan  = styled.span<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  padding: 10px;

  margin: auto 2px;
  cursor: pointer;
  border-radius: 4px;
  color: ${(p) => p.$themeColor.primaryFontColor};
  border: 1px solid ${(p) => p.$themeColor.primaryDividerColor};
  /* &:hover {
    background-color: ${(p) => p.$themeColor.primaryHoverColor};
  } */
`

export const StyledFileName  = styled.div`
  text-overflow: ellipsis;
  overflow-x: hidden;
  white-space: nowrap;
  max-width: 100px;

`
