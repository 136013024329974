import React, { useState } from "react";

import { shallow } from "zustand/shallow";
import { FiPlus } from "react-icons/fi";
import { produce } from "immer";
import { AnimatePresence, motion } from "framer-motion";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import ProgressScreen from "../../Utilities/Lottie/ProgressScreen";
import { editRgbaAlpha } from "../../Utilities/FunctionUtilities";
import {
  StyledAddProjectFieldWrapper,
  StyledAddProjectForm,
  StyledAddProjectTextfield,
  StyledSubmitAddButton,
} from "./StyledComponentsProjectSelectWithDropdown";
import ProjectQueryAPI from "../../Services/QueryHooks/projectQueryAPI";
import { useDoxleCurrentContextStore } from "../../DoxleGeneralStore/useDoxleCurrentContext";
import useDoxleNotificationStore from "../../DoxleGeneralStore/useDoxleNotificationStore";

type Props = {
  handleCloseForm: () => void;
};

const AddProjectForm = ({ handleCloseForm }: Props) => {
  const [shouldShowError, setShouldShowError] = useState(false);
  const [address, setAddress] = useState("");

  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }),
    shallow
  );

  const company = useDoxleCurrentContextStore(
    (state) => state.currentCompany,
    shallow
  );

  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );

  const addProjectQuery = ProjectQueryAPI.useAddProjectQuery({
    company,
    showNotification,
    filter: {},
    onSuccessCb: () => {
      handleCloseForm();
    },
  });

  const handleAddProject = () => {
    const href = window.location.href;
    if (address !== "") {
      setShouldShowError(false);
      addProjectQuery.mutate({
        siteAddress: address,
        isBudget: (href.includes('project') || (!href.includes('doxle') && !href.includes('Noticeboard'))),
        isNoticeboard: href.toLowerCase().includes('noticeboard'),
      });
    } else {
      setShouldShowError(true);
    }
  };

  const isAddingProject = addProjectQuery.isLoading;

  return (
    <StyledAddProjectForm
      animate={{
        y: [10, 0],
        opacity: [0, 1],
      }}
    >
      <StyledAddProjectFieldWrapper
        $themeColor={doxleThemeColor}
        $doxleFont={doxleFont}
        layout="position"
      >
        <span className="field-label">Site Address</span>
        <StyledAddProjectTextfield
          $themeColor={doxleThemeColor}
          $doxleFont={doxleFont}
          variant="standard"
          placeholder="Enter site address..."
          value={address}
          onChange={(e) => setAddress(e.target.value)}
          autoFocus
        />
        <AnimatePresence>
          {shouldShowError && (
            <motion.span
              className="error-text"
              animate={{
                x: [-5, 0],
                opacity: [0, 1],
              }}
              exit={{ x: [0, -5], opacity: [1, 0] }}
            >
              Please enter site address!
            </motion.span>
          )}
        </AnimatePresence>
      </StyledAddProjectFieldWrapper>

      <StyledSubmitAddButton
        $themeColor={doxleThemeColor}
        $doxleFont={doxleFont}
        layout="position"
        onClick={handleAddProject}
      >
        <FiPlus
          color="inherit"
          size="max(1.6rem,16px)"
          style={{ marginRight: 4 }}
        />
        Add
      </StyledSubmitAddButton>

      {isAddingProject && (
        <ProgressScreen
          progressType="progress"
          progressText="Adding Project..."
          animationSize={70}
          containerStyle={{
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            position: "absolute",
            zIndex: 10,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: editRgbaAlpha({
              rgbaColor: doxleThemeColor.primaryContainerColor,
              alpha: "0.8",
            }),
          }}
          textStyle={{
            fontSize: "max(1.6rem,16px)",
            color: doxleThemeColor.primaryFontColor,
            marginTop: 8,
          }}
          hasBackDrop={true}
        />
      )}
    </StyledAddProjectForm>
  );
};

export default AddProjectForm;
