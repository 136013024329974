import React from "react";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import { Dialog, Slide } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import {
  StyledAddSheetDropzone,
  StyledAddSheetTitle,
} from "./DrawingsStyledComponent";
import { DrawingSet } from "../Models/drawings";
import { motion } from "framer-motion";
import { useDropzone } from "react-dropzone";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import useAddSheetDropZone from "../Hooks/useAddSheetDropZone";
type Props = {
  openDialog: boolean;
  onClose: () => void;
  drawingSet: DrawingSet;
};

const AddSheetDropZone = ({ openDialog, onClose, drawingSet }: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }),
    shallow
  );
  const { onDrop } = useAddSheetDropZone({ drawingSet });

  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    onDrop,
    accept: {
      "application/pdf": [".pdf"],
    },
    maxFiles: 1,
    multiple: false,
  });
  return (
    <Dialog
      open={openDialog}
      TransitionComponent={Transition}
      onClose={onClose}
      aria-describedby="add-sheet-dialog-slide"
      slotProps={{
        backdrop: {
          sx: {
            bgcolor: doxleThemeColor.primaryBackdropColor,
          },
        },
      }}
      PaperProps={{
        sx: {
          bgcolor: doxleThemeColor.primaryContainerColor,
          display: "flex !important",
          width: "100vw",
          height: "70rem",
          borderRadius: "0.9rem",
        },
        elevation: 8,
      }}
    >
      <StyledAddSheetTitle $themeColor={doxleThemeColor} $doxleFont={doxleFont}>
        Add Sheets
      </StyledAddSheetTitle>
      <StyledAddSheetDropzone
        $themeColor={doxleThemeColor}
        $doxleFont={doxleFont}
      >
        <motion.div layout className="dropzone" {...getRootProps({})}>
          <CloudUploadOutlinedIcon
            style={{
              fontSize: "14rem",
              color: doxleThemeColor.primaryFontColor,
            }}
          />
          Drop or click on this zone to add file!
        </motion.div>

        {/* {addedFile && (
          <StyledAddedFileSection
            $themeColor={doxleThemeColor}
            $doxleFont={doxleFont}
            layout
            initial={false}
            animate={{ y: [500, 0] }}
          >
            <motion.div className="attachment-section">
              <AddedDrawingFile
                file={addedFile}
                handleRemoveAddedFile={handleRemoveAddedFile}
              />
            </motion.div>

            <StyledNewDrawingNameTextField
              $themeColor={doxleThemeColor}
              $doxleFont={doxleFont}
              value={newDrawingTitle}
              onChange={(event) => setNewDrawingTitle(event.target.value)}
              placeholder="Drawing name..."
              autoFocus
              onKeyDown={(event) => {
                if (event.nativeEvent.key === "Enter") {
                  event.stopPropagation();
                  handleAddDrawing();
                }
                if (event.nativeEvent.key === "Escape") {
                  event.stopPropagation();
                  setNewDrawingTitle("");
                }
              }}
            />
          </StyledAddedFileSection>
        )}
        <AnimatePresence>
          {showErrorText && (
            <motion.span
              className="error-text"
              initial={false}
              animate={{ x: [-10, 0], opacity: [0, 1] }}
              exit={{
                x: [0, -10],
                opacity: [1, 0],
              }}
            >
              Please enter drawing name...
            </motion.span>
          )}
        </AnimatePresence> */}
      </StyledAddSheetDropzone>
    </Dialog>
  );
};

export default AddSheetDropZone;

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
