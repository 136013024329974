import { create } from "zustand";
import {IBooking} from "../Models/booking";


export type ScreenDisplayMode = "Landscape" | "Portrait"
export type DocketInfoMenuValue = "Order" | "Comment" | undefined
export type BookingOpearionMode = "Add" | "Edit" | undefined



export interface ISendBookingDialog {
  booking: IBooking;
  type: "sms"|"email";
}

export interface BookingViewStore {
  currentScreenDisplay: ScreenDisplayMode;
  setCurrentScreenDisplay: (mode: ScreenDisplayMode) => void;

  currentMenuTab: DocketInfoMenuValue;
  setCurrentMenuTab: (val: DocketInfoMenuValue) => void;

  currentOperation : BookingOpearionMode;
  setCurrentOperation: (val: BookingOpearionMode) => void;

  sendBooking: ISendBookingDialog|undefined;
  setSendBooking: (val: ISendBookingDialog|undefined) => void;
}

const useBookingViewStore = create<BookingViewStore>((set, get) => ({
  currentScreenDisplay: "Landscape",
  currentMenuTab: "Order",
  currentOperation: undefined,
  sendBooking: undefined,

  setCurrentScreenDisplay: (mode: ScreenDisplayMode) => {
    set({currentScreenDisplay: mode});
  },

  setCurrentMenuTab: (val: DocketInfoMenuValue) => {
    set({currentMenuTab: val});
  },

  setCurrentOperation: (val: BookingOpearionMode) => {
    set({currentOperation: val});
  },

  setSendBooking: (val: ISendBookingDialog|undefined) => {
    set({sendBooking: val});
  },

}));

export default useBookingViewStore;
