import React, { useEffect, useMemo } from "react";
import { useDoxleAuthStore } from "../../../../DoxleGeneralStore/useDoxleAuthStore";
import { useDoxleCurrentContextStore } from "../../../../DoxleGeneralStore/useDoxleCurrentContext";
import useDoxleNotificationStore from "../../../../DoxleGeneralStore/useDoxleNotificationStore";
import { useEditDocketSideScreenStore } from "../../../../CoreContent/EditDocketSideScreen/EditDocketSideScreenStore/useEditDocketSideScreenStore";
import { shallow } from "zustand/shallow";
import { useEstimatesStore } from "../../Store/useEstimatesStore";
import { useRetrieveEstimatesList } from "../../../QueryHooks/EstimatesQueryAPI";
import { Estimate } from "../../../Models/estimatesFilters";

const TakeOffUnits = ["EA", "LM", "M2", "M3"];

type Props = {};
interface GetEstimateList {
  budgetEstimateItemList: Estimate[];
  isFetchingEstimate: boolean;
  isSuccessFetchingEstimate: boolean;
  isErrorFetchingEstimate: boolean;
  hasNextPageEstimate: boolean;
  fetchNextPageEstimate: () => void;
}
const useGetEstimateList = (props: Props): GetEstimateList => {
  const { company } = useDoxleCurrentContextStore((state) => ({
    company: state.currentCompany,
  }));
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification
  );

  const edittedDocket = useEditDocketSideScreenStore(
    (state) => state.edittedDocket,
    shallow
  );

  const { estimatesQueryFilter, setEstimatesQueryFilter, setEstimateList } =
    useEstimatesStore(
      (state) => ({
        estimatesQueryFilter: state.estimatesQueryFilter,
        setEstimatesQueryFilter: state.setEstimatesQueryFilter,
        setEstimateList: state.setEstimateList,
      }),
      shallow
    );

  const getBudgetEstimateQuery = useRetrieveEstimatesList({
    company,
    showNotification,
    filter: estimatesQueryFilter,
  });
  useEffect(() => {
    if (edittedDocket)
      setEstimatesQueryFilter({ docket: edittedDocket.docketPk });
  }, [edittedDocket]);

  const budgetEstimateItemList: Estimate[] = useMemo(
    () =>
      getBudgetEstimateQuery.isSuccess
        ? getBudgetEstimateQuery.data.pages.flatMap(
            (page) => page.data.results ?? []
          ) ?? []
        : [],
    [getBudgetEstimateQuery.data]
  );

  return {
    budgetEstimateItemList,
    isFetchingEstimate: getBudgetEstimateQuery.isLoading,
    isSuccessFetchingEstimate: getBudgetEstimateQuery.isSuccess,
    isErrorFetchingEstimate: getBudgetEstimateQuery.isError,
    hasNextPageEstimate: Boolean(getBudgetEstimateQuery.hasNextPage),
    fetchNextPageEstimate: () => getBudgetEstimateQuery.fetchNextPage(),
  };
};

export default useGetEstimateList;
