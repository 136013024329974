import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDoxleAuthStore } from "../../../../DoxleGeneralStore/useDoxleAuthStore";
import { shallow } from "zustand/shallow";
import { useDoxleCurrentContextStore } from "../../../../DoxleGeneralStore/useDoxleCurrentContext";
import useDoxleNotificationStore from "../../../../DoxleGeneralStore/useDoxleNotificationStore";
import { useEditDocketSideScreenStore } from "../../../../CoreContent/EditDocketSideScreen/EditDocketSideScreenStore/useEditDocketSideScreenStore";
import { useDoxleQuoteStore } from "../../../store/useDoxleQuoteStore";
import QuotesQueryAPI from "../../../QueryAPI/quotesQuery";
import useGetQuoteRequestList from "../../../CustomQueryHooks/useGetQuoteRequestList";
import { QuoteRequest } from "../../../models/quote";

type Props = {};

interface EmailSelectedQuotes {
  showEmailQuoteDialog: boolean;
  handleCloseEmailDialog: () => void;

  selectedQuoteRequestItems: QuoteRequest[];
  handleEmailQuotes: () => void;
}
const useEmailSelectedQuotes = (props: Props): EmailSelectedQuotes => {
  const [showEmailQuoteDialog, setShowEmailQuoteDialog] = useState(false);
  
  const company = useDoxleCurrentContextStore(
    (state) => state.currentCompany,
    shallow
  );
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );
  const { setEmailQuoteBtnFn } = useEditDocketSideScreenStore(
    (state) => ({
      setEmailQuoteBtnFn: state.setEmailQuoteBtnFn,
    }),
    shallow
  );
  const { quoteRequestIds, quoteResponseIds, setQuoteRequestIds } =
    useDoxleQuoteStore(
      (state) => ({
        quoteRequestIds: state.quoteRequestIds,
        quoteResponseIds: state.quoteResponseIds,
        setQuoteRequestIds: state.setQuoteRequestIds,
      }),
      shallow
    );

  const emailQuoteQuery = QuotesQueryAPI.useEmailQuoteRequests({
    showNotification,
    company,
    onSuccessCb() {
      setQuoteRequestIds([]);
    },
  });

  const handleCloseEmailDialog = () => setShowEmailQuoteDialog(false);

  const handleToggleEmailDialog = useCallback(() => {
    if (quoteRequestIds.length > 0 && quoteResponseIds.length === 0)
      setShowEmailQuoteDialog(true);
  }, [quoteRequestIds, quoteResponseIds]);
  const isEmailedBtnDisable = useMemo(
    () => quoteResponseIds.length > 0 && quoteRequestIds.length > 0,
    [quoteRequestIds, quoteResponseIds]
  );

  const handleEmailQuotes = () => {
    setShowEmailQuoteDialog(false);
    emailQuoteQuery.mutate({
      quoteRequestIds,
    });
  };
  //*USE EFECT TO SET FUNCTION FOR EMAIL BTN
  useEffect(() => {
    setEmailQuoteBtnFn({
      btnFunction: handleToggleEmailDialog,
      btnText: `Email${
        quoteRequestIds.length > 0 ? ` (${quoteRequestIds.length})` : ""
      }`,
      isProcessing: emailQuoteQuery.isLoading,
      disable: isEmailedBtnDisable,
    });
  }, [
    handleToggleEmailDialog,
    isEmailedBtnDisable,
    quoteRequestIds,
    emailQuoteQuery.isLoading,
  ]);
  useEffect(() => {
    return () => setEmailQuoteBtnFn(undefined);
  }, []);
  const { quoteRequestList } = useGetQuoteRequestList({});

  const selectedQuoteRequestItems: QuoteRequest[] = useMemo(
    () =>
      quoteRequestList.filter((rq) =>
        quoteRequestIds.some((id) => id === rq.requestId)
      ),
    [quoteRequestList, quoteRequestIds]
  );
  return {
    showEmailQuoteDialog,
    handleCloseEmailDialog,

    selectedQuoteRequestItems,
    handleEmailQuotes,
  };
};

export default useEmailSelectedQuotes;
