import React, { useState } from "react";
import useDoxleNotificationStore from "../../../../../DoxleGeneralStore/useDoxleNotificationStore";
import { useDoxleAuthStore } from "../../../../../DoxleGeneralStore/useDoxleAuthStore";
import { shallow } from "zustand/shallow";
import { useDoxleCurrentContextStore } from "../../../../../DoxleGeneralStore/useDoxleCurrentContext";
import { useProjectQAStore } from "../../../Store/useProjectQAStore";
import QAQueryAPI from "../../../../QueryAPI/qaQueryAPI";
import { NEW_QA_LIST_TEMPLATE, QAList } from "../../../../Models/qa";

type Props = { scrollToBottom: () => void };

interface QAListFooter {
  newQAListTitle: string;
  setnewQAListTitle: React.Dispatch<React.SetStateAction<string>>;
  handleAddQAList: () => void;
  isAddingQAList: boolean;
}
const useQAListFooter = ({ scrollToBottom }: Props): QAListFooter => {
  const [newQAListTitle, setnewQAListTitle] = useState("");

  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );
  const {  user } = useDoxleAuthStore(
    (state) => ({  user: state.user }),
    shallow
  );
  const { company, currentProject } = useDoxleCurrentContextStore(
    (state) => ({
      currentProject: state.currentProject,
      company: state.currentCompany,
    }),
    shallow
  );
  const { filterQAListQuery } = useProjectQAStore(
    (state) => ({
      filterQAListQuery: state.filterQAListQuery,
    }),
    shallow
  );

  const onSuccessCb = () => {
    setnewQAListTitle("");
    scrollToBottom();
  };
  const addDefectQuery = QAQueryAPI.useAddQAListQuery({
    showNotification,
    company,
    filter: filterQAListQuery,
    onSuccessCB: onSuccessCb,
  });

  const handleAddQAList = () => {
    if (newQAListTitle) {
      const newDefectTemplate: QAList = NEW_QA_LIST_TEMPLATE({
        company,
        createdBy: user?.userId,
        defectListTitle: newQAListTitle,
        project: currentProject ? currentProject?.projectId : undefined,
      });
      addDefectQuery.mutate(newDefectTemplate);
    }
  };
  return {
    newQAListTitle,
    setnewQAListTitle,
    handleAddQAList,
    isAddingQAList: addDefectQuery.isLoading,
  };
};

export default useQAListFooter;
