import React from "react";
import { PricebookSupplierRate } from "../../Models/Pricebook";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { useShallow } from "zustand/react/shallow";
import { useAddPricebookItemContext } from "./AddPricebookItem";
import { AnimatePresence, motion } from "framer-motion";
import {
  StyledEditPricebookInputAdornment,
  StyledSupplierName,
  StyledSupplierRateLineTextField,
  StyledWarningField,
  StyledWarningRow,
} from "../PricebookList/StyledComponents";
import useAddSupplierRateLine from "./Hooks/useAddSupplierRateLine";
import { DoxleDeleteIcon } from "../../../DoxleDesignPattern/DoxleGeneralIcons";
import { StyledDeleteRateLineBtn } from "./StyledAddEditPricebookItem";

type Props = {
  rate: PricebookSupplierRate;
  index: number;
};

const AddSupplierRateLine = ({ rate, index }: Props) => {
  const styleProps = useDoxleThemeStore(
    useShallow((state) => ({
      $themeColor: state.doxleThemeColor,
      $doxleFont: state.doxleFont,
      $currentTheme: state.currentTheme,
    }))
  );
  const {
    handleUpdateUnitCost,
    handleUpdateUnit,
    handleDeleteRate,

    rateSupplierRef,
    unitCostRef,
    handleClickSupplierName,
  } = useAddSupplierRateLine({ rate, index });
  const { shouldShowWarning } = useAddPricebookItemContext();
  return (
    <>
      <motion.div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          marginBottom: 5,
          justifyContent: "space-between",
        }}
        layout="position"
      >
        <StyledSupplierName
          ref={rateSupplierRef}
          onClick={handleClickSupplierName}
          $widthInPercent={50}
        >
          {rate.supplierName}
        </StyledSupplierName>
        <StyledSupplierRateLineTextField
          {...styleProps}
          $widthInPercent={28}
          value={rate.unitCost}
          inputRef={unitCostRef}
          onChange={handleUpdateUnitCost}
          InputProps={{
            startAdornment: (
              <StyledEditPricebookInputAdornment
                {...styleProps}
                position="start"
              >
                $
              </StyledEditPricebookInputAdornment>
            ),
          }}
        />
        <StyledSupplierRateLineTextField
          {...styleProps}
          $widthInPercent={18}
          value={rate.uom}
          onChange={handleUpdateUnit}
        />

        <StyledDeleteRateLineBtn onClick={handleDeleteRate}>
          <DoxleDeleteIcon
            themeColor={styleProps.$themeColor}
            containerStyle={{
              flexShrink: 0,
              width: 16,
              cursor: "pointer",
            }}
          />
        </StyledDeleteRateLineBtn>
      </motion.div>

      <AnimatePresence>
        {shouldShowWarning && (!rate.unitCost || !rate.uom) && (
          <StyledWarningRow
            animate={{ opacity: [0, 1], x: [-10, 0] }}
            exit={{ opacity: 0, x: -10 }}
          >
            <StyledWarningField $width="50%" />
            <StyledWarningField $width="28%">
              {!rate.unitCost ? "Please Enter Price" : ""}
            </StyledWarningField>
            <StyledWarningField $width="18%">
              {!rate.uom ? "Please Enter Uom" : ""}
            </StyledWarningField>
          </StyledWarningRow>
        )}
      </AnimatePresence>
    </>
  );
};

export default AddSupplierRateLine;
