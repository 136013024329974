import {
  StyledAddContactBackdrop,
  StyledAddContactsDialog,
} from "./styledComponents";
import React from "react";
import { useContactsStore } from "../Store/useContactsStore";
import { shallow } from "zustand/shallow";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import SyncXeroContactsDialog from "./SyncXeroContactsDialog";
import AddContactDialog from "./AddContactDialog";
import AddContactCompanyDialog from "./AddContactCompanyDialog";

const AddContactDialogDisplayer = ({}) => {
  const styleProps = useDoxleThemeStore(
    (state) => ({
      $themeColor: state.doxleThemeColor,
      $doxleFont: state.doxleFont,
      $currentTheme: state.currentTheme,
    }),
    shallow
  );
  const { addDialogType, setAddDialog } = useContactsStore(
    (state) => ({
      addDialogType: state.addDialogType,
      setAddDialog: state.setAddDialogType,
    }),
    shallow
  );
  if (addDialogType === null) return null;
  return (
    <StyledAddContactBackdrop
      {...styleProps}
      onClick={() => setAddDialog(null)}
    >
      <StyledAddContactsDialog
        {...styleProps}
        onClick={(e) => e.stopPropagation()}
        view={addDialogType}
      >
        {addDialogType === "ContactCompany" && <AddContactCompanyDialog />}
        {addDialogType === "Contact" && <AddContactDialog />}
        {addDialogType === "Xero" && <SyncXeroContactsDialog />}
      </StyledAddContactsDialog>
    </StyledAddContactBackdrop>
  );
};
export default AddContactDialogDisplayer;
