import React from "react";
import { Contact } from "../../../../Models/addressBook";
import { useDoxleThemeStore } from "../../../../DoxleGeneralStore/useDoxleThemeStore";
import CommentUserInitial from "../../../../DoxleDesignPattern/CommentUserInitial/CommentUserInitial";
import { StyledAssigneeNameText } from "./StyledComponentCommonQA";

type Props = {
  assignee: Contact;
  isSelected: boolean;
};

const AssigneeListItem = ({ assignee, isSelected }: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore((state) => ({
    doxleThemeColor: state.doxleThemeColor,
    doxleFont: state.doxleFont,
  }));
  return (
    <>
      <CommentUserInitial
        userFirstName={assignee.firstName}
        userLastName={assignee.lastName}
        size={20}
      />

      <StyledAssigneeNameText
        $themeColor={doxleThemeColor}
        $doxleFont={doxleFont}
        $selected={isSelected}
      >
        {assignee.firstName} {assignee.lastName}
      </StyledAssigneeNameText>
    </>
  );
};

export default React.memo(AssigneeListItem);
