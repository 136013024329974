import React from "react";
import { ScrollSeekPlaceholderProps } from "react-virtuoso";
import {
  StyledBookingTableRow,
  StyledBookingTableRowContainer,
} from "./StyledComponentBookingTable";
import { useDoxleThemeStore } from "../../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import Skeleton from "@mui/material/Skeleton";
import { useShallow } from "zustand/react/shallow";

const ScrollSeekerRow = ({ height, index }: ScrollSeekPlaceholderProps) => {
  const { doxleThemeColor, doxleFont, currentTheme, transparentMode } =
    useDoxleThemeStore(
      useShallow((state) => ({
        doxleThemeColor: state.doxleThemeColor,
        doxleFont: state.doxleFont,
        currentTheme: state.currentTheme,
        transparentMode: state.transparentMode,
      }))
    );
  return (
    <StyledBookingTableRow>
      <StyledBookingTableRowContainer
        style={{ justifyContent: "space-between", alignItems: "center" }}
      >
        <Skeleton
          variant="rounded"
          width={"40%"}
          height={10}
          style={{
            borderRadius: "10px",
            marginInline: "10px",
          }}
          sx={{
            bgcolor: doxleThemeColor.skeletonColor,
          }}
        />

        <Skeleton
          variant="rounded"
          width={"30%"}
          height={10}
          style={{
            borderRadius: "10px",
            marginInline: "10px",
          }}
          sx={{
            bgcolor: doxleThemeColor.skeletonColor,
          }}
        />

        <Skeleton
          variant="rounded"
          width={"10%"}
          height={10}
          style={{
            borderRadius: "10px",
            marginInline: "10px",
          }}
          sx={{
            bgcolor: doxleThemeColor.skeletonColor,
          }}
        />
      </StyledBookingTableRowContainer>
    </StyledBookingTableRow>
  );
};

export default ScrollSeekerRow;
