import { useMemo } from "react";
import { useDoxleCurrentContextStore } from "../../../DoxleGeneralStore/useDoxleCurrentContext";
import { shallow } from "zustand/shallow";
import useDoxleNotificationStore from "../../../DoxleGeneralStore/useDoxleNotificationStore";
import budgetsAPI from "../../QueryHooks/BudgetAPI";
import { IDocketStatusList } from "../../Models/BudgetDocket";

type Props = {};

interface GetBudgetStatusList {
  docketStatusList: IDocketStatusList[];
  isFetchingStatusList: boolean;
  isSuccessFetchingStatusList: boolean;
  isErrorFetchingStatusList: boolean;
}
const useGetBudgetStatusList = (props: Props): GetBudgetStatusList => {
  const currentCompany = useDoxleCurrentContextStore(
    (state) => state.currentCompany,
    shallow
  );

  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );
  const docketStatusQuery = budgetsAPI.useRetrieveBudgetStatusList({
    company: currentCompany,
    showNotification: showNotification,
  });

  const docketStatusList: IDocketStatusList[] = useMemo(
    () => (docketStatusQuery.isSuccess ? docketStatusQuery.data.data : []),
    [docketStatusQuery.data, docketStatusQuery.isSuccess]
  );
  return {
    docketStatusList,
    isFetchingStatusList: docketStatusQuery.isLoading,
    isSuccessFetchingStatusList: docketStatusQuery.isSuccess,
    isErrorFetchingStatusList: docketStatusQuery.isError,
  };
};

export default useGetBudgetStatusList;
