import React, { useEffect, useRef, useState } from "react";
import {
  StyledDocketFunctionMenuSection,
  StyledDocketDetailSection,
  StyledTopGeneralInfoContainer,
  StyledEditDocketMenuWrapper,
  StyledFunctionMenuCollapseButton,
  StyledIconContainer,
} from "../StyledComponentsEditDocketSideScreen";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import { useEditDocketSideScreenStore } from "../EditDocketSideScreenStore/useEditDocketSideScreenStore";
import FullScreenFunctionMenuButton from "./FullScreenFunctionMenuButton";
import CustomiseFunctionalMenu from "./CustomiseFunctionalMenu";
import useRefComponentDimension from "../../UtilityHooks/useRefComponentDimension";
import DoxleAddNewButton from "../../../DoxleDesignPattern/DoxleButtons/DoxleAddNewButton";
import DoxleDocketNumber from "../../../DoxleDesignPattern/DocketNumber/DocketNumber";
import {
  DoxleDeleteIcon,
  DoxleShareIcon,
} from "../../../DoxleDesignPattern/DoxleGeneralIcons";
import EditDocketShrinkMenu from "./EditDocketShrinkMenu";
import { StyledProjectMenuExpandIcon } from "../../Projects/ProjectIcon";
import DocketInfoPopover from "./DocketInfoPopover";
import { AnimatePresence } from "framer-motion";
import { Tooltip } from "@mui/material";
import { DoxleMUITooltipSx } from "../../../DoxleDesignPattern/MUIOverwriteSx";
import ArchivePinSection from "./DocketInfo/ArchivePinSection";
import DocketShareUserDisplay from "./DocketShareUserDisplay";
import DoxleDialogHelper from "../../../DoxleDesignPattern/DoxleDialogHelper/DoxleDialogHelper";
import { useDoxleCurrentContextStore } from "../../../DoxleGeneralStore/useDoxleCurrentContext";
import DocketQuery from "../../../Services/QueryHooks/docketQueryAPI";
import { useShallow } from "zustand/react/shallow";
type Props = {};

const TopGeneralInfo = (props: Props) => {
  const [showDeleteDocketDialog, setShowDeleteDocketDialog] = useState(false);
  const { doxleThemeColor, doxleFont, staticMenuColor } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
      staticMenuColor: state.staticMenuColor,
    })
  );
  const { currentCompany } = useDoxleCurrentContextStore(
    useShallow((state) => ({
      currentCompany: state.currentCompany,
    }))
  );
  const {
    edittedDocket,
    docketFunctionMenu,

    displayShareTab,
    setDisplayShareTab,

    selectedFunctionMenu,
    filterDocketListQuery,
    resetStore,
  } = useEditDocketSideScreenStore(
    useShallow((state) => ({
      edittedDocket: state.edittedDocket,
      docketFunctionMenu: state.docketFunctionMenu,

      displayShareTab: state.displayShareTab,
      setDisplayShareTab: state.setDisplayShareTab,

      handleCloseButtonFn: state.handleCloseButtonFn,

      selectedFunctionMenu: state.selectedFunctionMenu,
      filterDocketListQuery: state.filterDocketListQuery,
      resetStore: state.resetStore,
    }))
  );
  const deleteBudgetQuery = DocketQuery.useDeleteDocketQuery({
    company: currentCompany,
    filter: filterDocketListQuery,
    onSuccessCb: () => {
      resetStore();
    },
  });
  const [menuCollapse, setMenuCollapse] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const menuItemRefs = useRef(new Array(docketFunctionMenu.length));
  const menuRef = useRef<HTMLDivElement>(null);
  const [menuWidth, setMenuWidth] = useState<number>(800);

  useEffect(() => {
    let width = 0;
    for (let i = 0; i < menuItemRefs.current.length; i++) {
      if (menuItemRefs.current[i] && menuItemRefs.current[i].offsetWidth)
        width += menuItemRefs.current[i].offsetWidth;
    }
    setMenuWidth(width);
  }, []);

  const { width } = useRefComponentDimension({ componentRef: menuRef });
  useEffect(() => {
    if (width < menuWidth + 10) {
      setMenuCollapse(true);
      setAnchorEl(null);
    } else {
      setMenuCollapse(false);
    }
  }, [width]);

  const getShortenedProjectName = (siteAddress: string): string => {
    const words = siteAddress.split(/[\s,]+/);
    let returnAddress = "";
    for (let i = 0; i < words.length; i++) {
      returnAddress += i !== 0 ? " " + words[i] : words[i];
      if (returnAddress.length > 8) break;
    }
    return returnAddress;
  };

  return (
    <StyledTopGeneralInfoContainer>
      {edittedDocket && (
        <>
          <DoxleDialogHelper
            open={showDeleteDocketDialog}
            onClose={() => setShowDeleteDocketDialog(false)}
            title="Confirm delete booking!"
            description={`All data belong to item ***${edittedDocket?.docketName}*** will be deleted permanently,are you sure to proceed?`}
            dialogButtons={[
              {
                buttonText: "Cancel",
                buttonFunction: () => setShowDeleteDocketDialog(false),
                buttonColor: doxleThemeColor.primaryContainerColor,
                btnStyleSx: {
                  border: `1px solid ${doxleThemeColor.primaryDividerColor}`,
                },
                buttonTextStyle: {
                  color: doxleThemeColor.primaryFontColor,
                },
              },
              {
                buttonText: "Confirm",
                buttonFunction: () => {
                  deleteBudgetQuery.mutate(edittedDocket.docketPk);
                  setShowDeleteDocketDialog(false);
                },
                btnStyleSx: {
                  border: `1px solid ${doxleThemeColor.primaryDividerColor}`,
                },
              },
            ]}
          />
          <StyledDocketDetailSection
            $themeColor={doxleThemeColor}
            $doxleFont={doxleFont}
          >
            <div className="docket-name-status-section">
              {/*{company?.name}/{" "}*/}

              <DoxleDocketNumber
                docket={edittedDocket}
                // handleOpenBudgetMenu={handleClickStatus}
                handleOpenBudgetMenu={() => {}}
                iconProps={{
                  fontSize: 25,
                  color: staticMenuColor.staticFontColor,
                }}
                docketNumberStyle={{
                  backgroundColor: staticMenuColor.staticBg,
                  borderRadius: "50%",
                  color: staticMenuColor.staticFontColor,
                }}
              />
              <div className="docket-title">
                <span className="faded-text">
                  {edittedDocket.project
                    ? `${getShortenedProjectName(
                        edittedDocket.projectSiteAddress
                      )} / `
                    : ""}
                </span>
                {edittedDocket.docketName.slice(0, 40)}
                {edittedDocket.docketName.length > 40 && "..."} /{" "}
                {selectedFunctionMenu === "Detail"
                  ? "General"
                  : selectedFunctionMenu === "Scope Of Works"
                  ? "Tasks"
                  : selectedFunctionMenu}
              </div>
            </div>

            <div className="right-side-menu">
              <DocketShareUserDisplay />
              <Tooltip
                PopperProps={{
                  sx: DoxleMUITooltipSx(doxleThemeColor, doxleFont),
                }}
                title={"Delete"}
              >
                <StyledIconContainer
                  onClick={(e) => setShowDeleteDocketDialog(true)}
                >
                  <DoxleDeleteIcon
                    themeColor={doxleThemeColor}
                    containerStyle={{ cursor: "pointer", width: 20 }}
                    iconColor={"rgba(255,0,0,1)"}
                  />
                </StyledIconContainer>
              </Tooltip>
              <ArchivePinSection />
              <DocketInfoPopover />

              {displayShareTab ? (
                <DoxleAddNewButton
                  btnText={"Back"}
                  showBtnIcon={false}
                  onClick={() => setDisplayShareTab(false)}
                />
              ) : (
                <Tooltip
                  PopperProps={{
                    sx: DoxleMUITooltipSx(doxleThemeColor, doxleFont),
                  }}
                  title={"Share docket"}
                >
                  <StyledIconContainer onClick={() => setDisplayShareTab(true)}>
                    <DoxleShareIcon
                      themeColor={doxleThemeColor}
                      containerStyle={{ cursor: "pointer", width: 20 }}
                      staticColor={staticMenuColor.staticFontColor}
                    />
                  </StyledIconContainer>
                </Tooltip>
              )}
            </div>
          </StyledDocketDetailSection>

          <StyledEditDocketMenuWrapper
            $themeColor={doxleThemeColor}
            ref={menuRef}
          >
            <AnimatePresence>
              {menuCollapse && (
                <>
                  <StyledFunctionMenuCollapseButton
                    $doxleFont={doxleFont}
                    $themeColor={doxleThemeColor}
                    onClick={(e) => setAnchorEl(e.currentTarget)}
                    animate={{
                      y: [-10, 0],
                      opacity: [0, 1],
                    }}
                  >
                    {selectedFunctionMenu === "Detail"
                      ? "General"
                      : selectedFunctionMenu === "Scope Of Works"
                      ? "Tasks"
                      : selectedFunctionMenu}
                    <StyledProjectMenuExpandIcon themeColor={doxleThemeColor} />
                  </StyledFunctionMenuCollapseButton>
                  <EditDocketShrinkMenu
                    anchorEl={anchorEl}
                    setAnchorEl={setAnchorEl}
                  />
                </>
              )}

              {!menuCollapse && (
                <StyledDocketFunctionMenuSection
                  $themeColor={doxleThemeColor}
                  $doxleFont={doxleFont}
                  animate={{
                    x: [-50, 0],
                    opacity: [0, 1],
                  }}
                  exit={{
                    x: [0, -100],
                    opacity: [1, 0],
                  }}
                  transition={{
                    damping: 18,
                  }}
                  viewport={{
                    once: true,
                  }}
                >
                  {docketFunctionMenu.map(
                    (menuItem, itemIndex) =>
                      menuItem.display && (
                        <FullScreenFunctionMenuButton
                          menuItemRefs={menuItemRefs}
                          itemIndex={itemIndex}
                          item={menuItem}
                          key={`fullscreenFunctionMenuBtn${menuItem}#${itemIndex}`}
                        />
                      )
                  )}
                </StyledDocketFunctionMenuSection>
              )}
            </AnimatePresence>
            <CustomiseFunctionalMenu />
          </StyledEditDocketMenuWrapper>

          {/* <EditDocketStatusPopover
            anchorStatusListPopover={anchorStatusListPopover}
            handleCloseStatusList={handleCloseStatusList}
          /> */}
        </>
      )}
    </StyledTopGeneralInfoContainer>
  );
};

export default TopGeneralInfo;
