import React from "react";
import { useQueryClient } from "@tanstack/react-query";

import { original, produce } from "immer";
import { QA, QAWithFirstImg } from "../Models/qa";
import { useDoxleCurrentContextStore } from "../../DoxleGeneralStore/useDoxleCurrentContext";
import { shallow } from "zustand/shallow";
import { formQAItemListQKey } from "../QueryAPI/qaQueryAPI";

type Props = {
  defectListId: string;
  appendPos?: "start" | "end";
  overwrite?: boolean;
};
interface SetQAQueryData {
  handleAddQAQueryData: (addedQA: QAWithFirstImg) => void;
  handleEditQAQueryData: (edittedQA: QA) => void;
  handleDeleteQAQueryData: (deletedQAId: string) => void;
  handleUpdateFirstImageQA: (qaID: string, firstImg: string | null) => void;
}
const useSetQAQueryData = ({
  defectListId,
  appendPos = "start",
  overwrite = true,
}: Props): SetQAQueryData => {
  const queryClient = useQueryClient();
  const { company } = useDoxleCurrentContextStore(
    (state) => ({
      company: state.currentCompany,
    }),
    shallow
  );
  const qKey = formQAItemListQKey(defectListId, company);

  const handleAddQAQueryData = (addedQA: QAWithFirstImg) => {
    const oldServerData = queryClient.getQueryData(qKey);
    if (oldServerData) {
      if (overwrite) {
        queryClient.setQueryData(qKey, (old: any) => {
          if (old) {
            const lengthOfPages = old.pages.length;
            return produce(old, (draftOld: any) => {
              draftOld.pages = produce(draftOld.pages, (draftPages: any) => {
                if (appendPos === "start") {
                  (draftPages[0].data.results as QAWithFirstImg[]).unshift({
                    ...addedQA,
                    isNew: true,
                  });
                } else {
                  (
                    draftPages[lengthOfPages - 1].data
                      .results as QAWithFirstImg[]
                  ).push({ ...addedQA, isNew: true });
                }

                return draftPages;
              });

              return draftOld;
            });
          } else return old;
        });
      } else queryClient.invalidateQueries(qKey);
    } else queryClient.invalidateQueries(qKey);
  };

  const handleEditQAQueryData = (edittedQA: QA) => {
    const oldServerData = queryClient.getQueryData(qKey);

    if (oldServerData) {
      if (overwrite) {
        queryClient.setQueryData(qKey, (old: any) => {
          if (old) {
            //find page contain deleted item
            let pageContainGroup: number = (old.pages as Array<any>).findIndex(
              (page) =>
                (page.data.results as QAWithFirstImg[]).find(
                  (qa) => qa.defectId === edittedQA.defectId
                )
            );
            if (pageContainGroup !== -1) {
              const targetOrderItemIndex = (
                (old.pages as Array<any>)[pageContainGroup].data
                  .results as QAWithFirstImg[]
              ).findIndex((qa) => qa.defectId === edittedQA.defectId);
              return produce(old, (draftOld: any) => {
                draftOld.pages = produce(draftOld.pages, (draftPages: any) => {
                  draftPages[pageContainGroup].data.results = produce(
                    draftPages[pageContainGroup].data.results,
                    (draftTargetPageData: QAWithFirstImg[]) => {
                      if (targetOrderItemIndex !== -1) {
                        const updatedItem = original(
                          draftTargetPageData[targetOrderItemIndex]
                        );
                        if (updatedItem) Object.assign(updatedItem, edittedQA);
                      }

                      return draftTargetPageData;
                    }
                  );
                  return draftPages;
                });

                return draftOld;
              });
            } else queryClient.invalidateQueries(qKey);
          } else queryClient.invalidateQueries(qKey);
        });
      } else queryClient.invalidateQueries(qKey);
    } else queryClient.invalidateQueries(qKey);
  };

  const handleDeleteQAQueryData = (deletedQAId: string) => {
    const oldServerData = queryClient.getQueryData(qKey);
    if (oldServerData) {
      if (overwrite) {
        queryClient.setQueryData(qKey, (old: any) => {
          if (old) {
            //find page contain deleted item
            let pageContainGroup: number = (old.pages as Array<any>).findIndex(
              (page) =>
                (page.data.results as QAWithFirstImg[]).find(
                  (qa) => qa.defectId === deletedQAId
                )
            );
            if (pageContainGroup !== -1) {
              const targetOrderItemIndex = (
                (old.pages as Array<any>)[pageContainGroup].data
                  .results as QAWithFirstImg[]
              ).findIndex((qa) => qa.defectId === deletedQAId);
              return produce(old, (draftOld: any) => {
                draftOld.pages = produce(draftOld.pages, (draftPages: any) => {
                  draftPages[pageContainGroup].data.results = produce(
                    draftPages[pageContainGroup].data.results,
                    (draftTargetPageData: QAWithFirstImg[]) => {
                      draftTargetPageData.splice(targetOrderItemIndex, 1);

                      return draftTargetPageData;
                    }
                  );
                  return draftPages;
                });

                return draftOld;
              });
            } else queryClient.invalidateQueries(qKey);
          } else queryClient.invalidateQueries(qKey);
        });
      } else queryClient.invalidateQueries(qKey);
    } else queryClient.invalidateQueries(qKey);
  };

  const handleUpdateFirstImageQA = (qaID: string, firstImg: string | null) => {
    const oldServerData = queryClient.getQueryData(qKey);

    if (oldServerData) {
      if (overwrite) {
        queryClient.setQueryData(qKey, (old: any) => {
          if (old) {
            //find page contain deleted item
            let pageContainGroup: number = (old.pages as Array<any>).findIndex(
              (page) =>
                (page.data.results as QAWithFirstImg[]).find(
                  (qa) => qa.defectId === qaID
                )
            );
            if (pageContainGroup !== -1) {
              const targetOrderItemIndex = (
                (old.pages as Array<any>)[pageContainGroup].data
                  .results as QAWithFirstImg[]
              ).findIndex((qa) => qa.defectId === qaID);
              return produce(old, (draftOld: any) => {
                draftOld.pages = produce(draftOld.pages, (draftPages: any) => {
                  draftPages[pageContainGroup].data.results = produce(
                    draftPages[pageContainGroup].data.results,
                    (draftTargetPageData: QAWithFirstImg[]) => {
                      if (targetOrderItemIndex !== -1) {
                        draftTargetPageData[targetOrderItemIndex].firstImage =
                          firstImg;
                      }

                      return draftTargetPageData;
                    }
                  );
                  return draftPages;
                });

                return draftOld;
              });
            } else queryClient.invalidateQueries(qKey);
          } else queryClient.invalidateQueries(qKey);
        });
      } else queryClient.invalidateQueries(qKey);
    } else queryClient.invalidateQueries(qKey);
  };
  return {
    handleAddQAQueryData,
    handleEditQAQueryData,
    handleDeleteQAQueryData,
    handleUpdateFirstImageQA,
  };
};

export default useSetQAQueryData;
