import { shallow } from "zustand/shallow";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { useEditOrderStore } from "../../OrdersStore/useEditOrderStore";
import dayjs from "dayjs";

import { RootPurchaseOrderDetailGeneralInfo } from "./StyledComponents";
import EditContrractorOrder from "../EditOrder/EditContrractorOrder";
import {
  StyledEditOrderLabelField,
  StyledEditOrderWrapperField,
} from "../EditOrder/StyledComponentEditOrder";
import EditOrderDateSelect from "../EditOrder/EditOrderDateSelect";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { motion } from "framer-motion";
import EditDescriptionOrder from "../EditOrder/EditDescriptionOrder";
import EditOrderPaymentAndLateFee from "../EditOrder/EditOrderPaymentAndLateFee";
import EditOrderStatus from "../EditOrder/EditOrderStatus";
import useEditOrderGeneralInfo from "../EditOrder/Hooks/useEditOrderGeneralInfo";
import ReadOnlyOrderContractor from "../EditOrder/ReadOnlyOrderContractor";
import EditOrderSignoff from "../EditOrder/EditOrderSignoff";
import CustomDatePicker from "../../../DoxleDesignPattern/CustomDatePicker/CustomDatePicker";
import { useState } from "react";
import DoxleIconButton from "../../../DoxleDesignPattern/DoxleButtons/DoxleIconButton";
import HighlightOffSharpIcon from "@mui/icons-material/HighlightOffSharp";
type Props = {};

const EditOrderGeneralInfo = (props: Props) => {
  const [onHoverStartDate, setOnHoverStartDate] = useState(false);
  const [onHoverEndDate, setOnHoverEndDate] = useState(false);

  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }),
    shallow
  );

  const { editedOrder, readOnly } = useEditOrderStore(
    (state) => ({
      editedOrder: state.editedOrder,
      readOnly: state.readOnly,
    }),
    shallow
  );

  const {
    isUpdatingStartDate,
    isUpdatingEndDate,
    handleUpdateStartDate,
    handleUpdateEndDate,
  } = useEditOrderGeneralInfo({});

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <RootPurchaseOrderDetailGeneralInfo>
        <div className="space-between-field">
          {!readOnly ? <EditContrractorOrder /> : <ReadOnlyOrderContractor />}

          <StyledEditOrderWrapperField $width="35%">
            <StyledEditOrderLabelField
              $themeColor={doxleThemeColor}
              $doxleFont={doxleFont}
            >
              {!readOnly && "Start"} Date
            </StyledEditOrderLabelField>
            {/* <EditOrderDateSelect
              value={
                editedOrder?.startDate ? dayjs(editedOrder.startDate) : null
              }
              disabled={readOnly}
              onChange={handleUpdateStartDate}
              isUpdating={isUpdatingStartDate}
            /> */}
            <div
              className="date-picker-wrapper"
              onMouseEnter={(e) => {
                e.stopPropagation();
                setOnHoverStartDate(true);
              }}
              onMouseLeave={(e) => {
                setOnHoverStartDate(false);
              }}
            >
              <CustomDatePicker
                value={
                  editedOrder?.startDate
                    ? dayjs(editedOrder.startDate).format("YYYY/MM/DD")
                    : null
                }
                onChange={handleUpdateStartDate}
                containerStyle={{
                  padding: "5px 8px",
                  borderRadius: 4,
                  height: 20,
                  fontSize:"max(13px, 1.3rem)"
                }}
              />
              {editedOrder?.startDate && onHoverStartDate && (
                <DoxleIconButton
                  iconSource={
                    <HighlightOffSharpIcon
                      htmlColor={doxleThemeColor.primaryFontColor}
                      sx={{
                        fontSize: "2rem",
                      }}
                    />
                  }
                  buttonSize={30}
                  onClick={() => {
                    handleUpdateStartDate(null);
                  }}
                />
              )}
              </div>
          </StyledEditOrderWrapperField>
        </div>

        {!readOnly && (
          <motion.div className="space-between-field" layout="position">
            <EditDescriptionOrder />
            <StyledEditOrderWrapperField style={{ width: "35%" }}>
              <StyledEditOrderLabelField
                $themeColor={doxleThemeColor}
                $doxleFont={doxleFont}
              >
                End Date
              </StyledEditOrderLabelField>
              {/* <EditOrderDateSelect
                value={editedOrder?.endDate ? dayjs(editedOrder.endDate) : null}
                onChange={handleUpdateEndDate}
                isUpdating={isUpdatingEndDate}
                disabled={readOnly}
              /> */}
              <div
                className="date-picker-wrapper"
                onMouseEnter={(e) => {
                  e.stopPropagation();
                  setOnHoverEndDate(true);
                }}
                onMouseLeave={(e) => {
                  setOnHoverEndDate(false);
                }}
              >
                <CustomDatePicker
                  value={
                    editedOrder?.endDate
                      ? dayjs(editedOrder.endDate).format("YYYY/MM/DD")
                      : null
                  }
                  onChange={handleUpdateEndDate}
                  containerStyle={{
                    padding: "5px 8px",
                    borderRadius: 4,
                    height: 20,
                    fontSize:"max(13px, 1.3rem)"
                  }}
                />
                {editedOrder?.endDate && onHoverEndDate && (
                  <DoxleIconButton
                    iconSource={
                      <HighlightOffSharpIcon
                        htmlColor={doxleThemeColor.primaryFontColor}
                        sx={{
                          fontSize: "2rem",
                        }}
                      />
                    }
                    buttonSize={30}
                    onClick={() => {
                      handleUpdateEndDate(null);
                    }}
                  />
                )}
              </div>
            </StyledEditOrderWrapperField>
          </motion.div>
        )}

        <motion.div className="space-between-field" layout="position">
          <EditOrderPaymentAndLateFee />
        </motion.div>
        <motion.div className="space-between-field" layout="position">
          <EditOrderStatus />
          <EditOrderSignoff />
        </motion.div>
      </RootPurchaseOrderDetailGeneralInfo>
    </LocalizationProvider>
  );
};

export default EditOrderGeneralInfo;
