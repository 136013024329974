import { IPaginationFilter } from "../../Models/filterTypes";
import { TPricebookTableHeaders } from "./PricebookTable";

export type PricebookRateOrderByFields =
  | "pricebook_item_description"
  | "supplier_name"
  | "uom"
  | "unit_cost"
  | "time_stamp";
// |'swatch_count'

export type RevPricebookRateOrderByFields = `-${PricebookRateOrderByFields}`;
type PricebookHeadersBackwardMapInterface = {
  [Property in TPricebookTableHeaders]: PricebookRateOrderByFields;
};
export const PRICEBOOK_SUPPLIER_RATEHEADERS_MAP: PricebookHeadersBackwardMapInterface =
  {
    Item: "pricebook_item_description",
    Supplier: "supplier_name",
    Unit: "uom",
    "Unit Price": "unit_cost",
    Modified: "time_stamp",
    // Swatches: "swatch_count",
  };

export interface PricebookSupplierRateFilters extends IPaginationFilter {
  search?: string;
  supplier?: string;
  uom?: string;
  pricebook_item__core_dockets?: string;
  company?: string;
  order_by?: PricebookRateOrderByFields | RevPricebookRateOrderByFields;
  core_docket?: string;
}

export type PricebookItemOrderByFields =
  | "description"
  | "core_dockets"
  | "swatch_count"
  | "item_count";

export type RevPricebookItemOrderByFields = `-${PricebookItemOrderByFields}`;

export interface PricebookItemFilters extends IPaginationFilter {
  search?: string;
  core_dockets?: string;
  company?: string;
  order_by?: PricebookItemOrderByFields | RevPricebookItemOrderByFields;
}
