import React from "react";
import { useDoxleThemeStore } from "../../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import { StyledQAStatusContainer } from "./StyledComponentCommonQA";
import { useShallow } from "zustand/react/shallow";

type Props = {
  statusCount: number;
  statusType: "completed" | "unattended";

  displayText?: boolean;
  marginRight?: number;
} & Omit<
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
  "onDragStart" | "onAnimationStart" | "onDragEnd" | "onDrag" | "ref"
>;

const COMPLETE_STATUS_COLOR = "#209D34";
// 'rgba(18, 183, 24, 0.8)';
const UNATTENDED_STATUS_COLOR = "#FF9900";
const QAStatus = ({
  statusType,

  statusCount,
  displayText,
  marginRight = 0,
  ...props
}: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    useShallow((state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }))
  );
  return (
    <StyledQAStatusContainer
      {...props}
      $marginRight={marginRight}
      $bgColor={
        statusType === "unattended"
          ? UNATTENDED_STATUS_COLOR
          : COMPLETE_STATUS_COLOR
      }
      $textColor={
        statusType === "unattended"
          ? doxleThemeColor.primaryFontColor
          : doxleThemeColor.primaryReverseFontColor
      }
    >
      <span className="status-text">
        {displayText && statusType + " "}
        {displayText ? `(${statusCount})` : statusCount}
      </span>
    </StyledQAStatusContainer>
  );
};

export default QAStatus;
