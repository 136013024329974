import React from "react";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import {
  StyledAddQuoteLineTextField,
  StyledQuoteResponseLineHeader,
  StyledQuoteResponseLineSection,
} from "../SharedDocketStyledComponents";
import { QuoteLine } from "../../models/quote";
import { useQuoteContext } from "./QuoteResponse";
import { GoPlus } from "react-icons/go";
import AddQuoteResponseLineItem from "./AddQuoteResponseLineItem";
import useQuoteResponseLineSection from "./Hooks/useQuoteResponseLineSection";

type Props = {};
interface QuoteResponseLineFields {
  fieldName: "Qty" | "Unit" | "Unit Price" | "Description" | "Subtotal";
  lineProp: keyof QuoteLine;
}
export const QUOTE_LINE_FIELDS: QuoteResponseLineFields[] = [
  {
    fieldName: "Description",
    lineProp: "description",
  },
  {
    fieldName: "Qty",
    lineProp: "quantity",
  },
  {
    fieldName: "Unit",
    lineProp: "unit",
  },
  {
    fieldName: "Unit Price",
    lineProp: "itemCost",
  },
  {
    fieldName: "Subtotal",
    lineProp: "lineCost",
  },
];
const QuoteResponseLineSection = (props: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore((state) => ({
    doxleThemeColor: state.doxleThemeColor,
    doxleFont: state.doxleFont,
  }));
  const {
    newQuoteLineDescription,
    setNewQuoteLineDescription,
    handleKeydownDescription,
  } = useQuoteResponseLineSection({});
  const { quoteResponse } = useQuoteContext();
  return (
    <StyledQuoteResponseLineSection $themeColor={doxleThemeColor}>
      <StyledQuoteResponseLineHeader
        $themeColor={doxleThemeColor}
        $doxleFont={doxleFont}
      >
        {QUOTE_LINE_FIELDS.map((header, idx) => (
          <div
            key={`header#${header.lineProp + idx}`}
            className="header-field-wrapper"
            style={{
              width:
                header.fieldName !== "Description"
                  ? header.fieldName !== "Subtotal"
                    ? "8rem"
                    : "14rem"
                  : undefined,
              flex: header.fieldName === "Description" ? 1 : undefined,
            }}
          >
            {header.fieldName !== "Description" && header.fieldName}
          </div>
        ))}
        <div className="icon-space-header" />
      </StyledQuoteResponseLineHeader>

      {quoteResponse.lines.map((line, idx) => (
        <AddQuoteResponseLineItem
          lineItem={line}
          itemIndex={idx}
          key={`line#${idx}`}
        />
      ))}

      <StyledAddQuoteLineTextField
        $themeColor={doxleThemeColor}
        $doxleFont={doxleFont}
        placeholder="New"
        variant="standard"
        value={newQuoteLineDescription}
        onChange={(event) => setNewQuoteLineDescription(event.target.value)}
        InputProps={{
          startAdornment: (
            <GoPlus
              style={{
                color: doxleThemeColor.primaryInputPlaceholderColor,
                fontSize: "1.8rem",
                marginRight: "0.4rem",
              }}
            />
          ),
        }}
        onKeyDown={handleKeydownDescription}
      />
    </StyledQuoteResponseLineSection>
  );
};

export default QuoteResponseLineSection;
