import {useState} from "react";
import ContactUsAPI from "./contactUsAPI";

const NAME_TRIGGER_ID = 0;
const EMAIL_TRIGGER_ID = 1;
const MESSAGE_TRIGGER_ID = 2;

const emailRegex = new RegExp(
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);

const useContactUs = () =>{
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [prompt, setPrompt] = useState<string[]>(["","",""]);
  const [sendState, setSendState] = useState<undefined|"sending"|"success"|"error">()

  const sendMail = ContactUsAPI.useSendEnquiry({
    onSuccessCb: () => setSendState("success"),
    onErrorCb: () => setSendState("error"),
  })
  const handleSubmit = async () => {
    let valid = true;
    let newPrompt = [...prompt];

    if (!emailRegex.test(email)) {
      if(email === ""){
        newPrompt[EMAIL_TRIGGER_ID] = "Required";
      }else{
        newPrompt[EMAIL_TRIGGER_ID] = "Invalid Email";
      }
      valid= false;
    } else {
      newPrompt[EMAIL_TRIGGER_ID] = "";
    }

    if (name === "") {
      newPrompt[NAME_TRIGGER_ID] = "Required"
      valid = false;
    } else {
      newPrompt[NAME_TRIGGER_ID] = "";
    }

    if (message === "") {
      newPrompt[MESSAGE_TRIGGER_ID] = "Required";
      valid = false;
    } else {
      newPrompt[MESSAGE_TRIGGER_ID] = "";
    }

    setPrompt(newPrompt);

    if(valid){
      setSendState("sending")
      await sendMail.mutate({name, email, message})
    }

  };

  return {
    NAME_TRIGGER_ID,
    EMAIL_TRIGGER_ID,
    MESSAGE_TRIGGER_ID,
    prompt,
    name,
    setName,
    email,
    setEmail,
    message,
    setMessage,
    handleSubmit,
    sendState
  }
}

export default useContactUs
