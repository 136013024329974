import styled from "styled-components";
import {
  DoxleFont,
  DoxleThemeColor,
} from "../../DoxleGeneralStore/useDoxleThemeStore";
import { editRgbaAlpha } from "../../Utilities/FunctionUtilities";
import { motion } from "framer-motion";

export const StyledInventoryFileContainer = styled.div`
  width: 100%;
  margin-top: 2rem;
  overflow: auto;

  display: flex;
  flex-wrap: wrap;
  flex-shrink: 1;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const StyledFileInfoContainer = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: calc(${100 / 3}% - 2px - 0.8rem);
  border: 1px solid #a9b0c2;
  margin: 0.4rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  position: relative;
  height: fit-content;
`;

export const StyledCloseButton = styled(motion.div)`
  position: absolute;
  right: 8px;
  top: 8px;
  cursor: pointer;
  z-index: 5;
`;

export const StyledStarButton = styled(motion.div)`
  position: absolute;
  right: 32px;
  top: 5px;
  cursor: pointer;
  z-index: 5;
`;

export const StyledNewInventoryTitle = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  font-size: max(2rem, 20px);
  margin-bottom: 2rem;
  font-weight: 500;
  color: ${(p) => p.$themeColor.primaryFontColor};
  font-family: ${(p) => p.$doxleFont.primaryFont};
`;

export const StyledFileName = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  font-weight: 500;
  font-size: 1.2rem;
  color: ${(p) => p.$themeColor.primaryFontColor};
  font-family: ${(p) => p.$doxleFont.primaryFont};
  overflow-x: hidden;
  white-space: nowrap;
  margin-left: 0.5rem;
  max-width: 100%;
  text-overflow: ellipsis;
`;

export const StyledImageUploadZone = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 26rem;
  min-height: 13.1rem;
  border-radius: 0.8rem;
  border: 1px dashed #a9b0c2;
  margin-top: 3rem;
`;

export const StyledUploadPrompt = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  margin-top: 10px;
  font-size: 1.2rem;
  font-family: ${(p) => p.$doxleFont.primaryFont};
  color: ${(p) =>
    editRgbaAlpha({ rgbaColor: p.$themeColor.primaryFontColor, alpha: "0.7" })};
  font-weight: 500;
  display: flex;
  flex-direction: column;
`;

export const StyledAddButton = styled.button<{
  $themeColor: DoxleThemeColor;
}>`
  width: 8rem;
  height: 3.9rem;
  border-radius: 0.3rem;
  margin: 10px;
  background-color: ${(p) => p.$themeColor.primaryFontColor};
  color: ${(p) => p.$themeColor.primaryReverseFontColor};
  border: none;
  cursor: pointer;
  font-size: 1.4rem;
`;

export const StyledButtonContainer = styled.div`
  text-align: end;
`;

export const StyledBottomContainer = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const StyledWarning = styled.div<{
  $doxleFont: DoxleFont;
}>`
  color: #ea1515;
  font-size: 1rem;
  font-family: ${(p) => p.$doxleFont.subTitleFont};
  position: relative;
  bottom: 20px;
  height: 0;
  margin-top: 4px;
`;
