import { motion } from "framer-motion";
import styled from "styled-components";
import {
  DoxleFont,
  DoxleThemeColor,
} from "../../../../DoxleGeneralStore/useDoxleThemeStore";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import { editRgbaAlpha } from "../../../../Utilities/FunctionUtilities";
import Button from "@mui/material/Button";
import { ScreenDisplayMode } from "../../../store/useBookingViewStore";

export const StyledAddBookingDocketContainer = styled(motion.div)<{
  $themeColor: DoxleThemeColor;
}>`
  padding: 2rem 0px;
  padding-bottom: 4rem;
  width: 100%;
  height: calc(100% - 6rem);
  display: flex;
  flex-direction: column;
  background-color: ${(p) => p.$themeColor.primaryBackgroundColor};
  position: relative;
  .add-content {
    margin-top: 14px;
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;

    .docket-content-wrapper {
      flex: 1;
      width: 100%;
      display: flex;
    }
  }
`;

export const StyledAddBookingTitleContainer = styled(motion.div)`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 3rem;
`;

export const StyledAddBookingDocketAndDateContainer = styled(motion.div)`
  width: 100%;
  max-width: 700px;
  display: flex;
  margin-bottom: 3rem;
  cursor: pointer;
  .field-wrapper {
    width: 50%;
    min-width: 12rem;
    display: flex;
    flex-direction: column;
  }
    .date-picker-wrapper{
    display: flex;
    flex-direction: row;
    width: 100px;
  }
`;

export const StyledAddBookingLabelText = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  font-family: ${(p) => p.$doxleFont.secondaryTitleFont};
  font-style: normal;
  font-weight: 500;
  font-size: 1.2rem;

  color: ${(p) =>
    editRgbaAlpha({ rgbaColor: p.$themeColor.primaryFontColor, alpha: "0.5" })};
  margin-bottom: 4px;
`;

export const StyledAddBookingContentWrapper = styled(motion.div)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
  $isNull: boolean;
}>`
    max-width: 180px;
  background-color: ${(p) => p.$themeColor.primaryTextFieldBackgroundColor};
  padding: 1rem 4px;
  font-family: ${(p) => p.$doxleFont.primaryFont};
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;
  border-radius: 8px;
  color: ${(p) =>
    p.$isNull
      ? editRgbaAlpha({
          rgbaColor: p.$themeColor.primaryFontColor,
          alpha: "0.5",
        })
      : p.$themeColor.primaryFontColor};
  /* overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  position: relative; */
  .require-symbol {
    position: absolute;
    z-index: 4;
    color: ${(p) => p.$themeColor.doxleColor};

    font-family: ${(p) => p.$doxleFont.plexSanCondensed};
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;

    letter-spacing: -0.12rem;
    top: 0px;
    right: -10px;
  }
`;

export const StyledAddBookingPercentageContainer = styled(motion.div)`
  width: 100%;
  max-width: 60rem;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
`;

export const StyledBookingPercentageText = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  align-self: center;
  font-family: ${(p) => p.$doxleFont.secondaryTitleFont};
  font-style: normal;
  font-weight: 500;
  font-size: 1.8rem;

  color: ${(p) => p.$themeColor.primaryFontColor};
  margin-top: 1.4rem;
`;

export const StyledAddBookingButton = styled(Button)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  && {
    margin-top: 1.4rem;
    align-self: flex-end;
    background-color: ${(p) => p.$themeColor.primaryFontColor};
    font-family: ${(p) => p.$doxleFont.secondaryTitleFont};
    font-style: normal;
    font-weight: 500;
    font-size: 1.4rem;

    color: ${(p) => p.$themeColor.primaryContainerColor};
    border-radius: 0.4rem !important;
    padding: 4px 1.4rem !important;

    &:hover {
      background-color: ${(p) =>
        editRgbaAlpha({
          rgbaColor: p.$themeColor.primaryFontColor,
          alpha: "0.5",
        })} !important;
    }
  }

  &.MuiButton-root.Mui-disabled {
    background-color: ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.$themeColor.primaryFontColor,
        alpha: "0.5",
      })} !important;
    color: ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.$themeColor.primaryContainerColor,
        alpha: "0.5",
      })} !important;
  }
`;

export const StyledAddBookingConatiner = styled.div<{
  $displayMode: ScreenDisplayMode;
}>`
  margin-top: 14px;
  flex: 1;
  width: 100%;
  /* display: flex; */
  /* flex-direction: ${(p) => (p.$displayMode === "Portrait" ? "column" : "row")};
  overflow-y: ${(p) => (p.$displayMode === "Portrait" ? "scroll" : "hidden")}; */
  overflow-y: scroll;
  overflow-x: hidden;

  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const StyledAddInfoWrapper = styled.div<{
  $displayMode: ScreenDisplayMode;
}>`
  width: ${(p) =>
    p.$displayMode === "Portrait" ? "100%" : "calc(50% - 50px)"};
  display: flex;
  flex-direction: column;
  padding-right: 50px;
`;

export const StyledDocketInfoContainer = styled.div<{
  $displayMode: ScreenDisplayMode;
}>`
  width: ${(p) => (p.$displayMode === "Portrait" ? "100%" : "50%")};
  display: flex;
  height: 100%;
`;
