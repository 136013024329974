import { useRef } from "react";
import { pdfjs } from "react-pdf";
import {
  StyledDrawingPreviewDialog,
  StyledDrawingPreviewDialogContainer,
  StyledDrawingPreviewDialogTop,
  StyledDrawingsPreviewPageImage,
  StyledDrawingsPreviewPageNavigateLeftButton,
  StyledDrawingsPreviewPageNavigateRightButton,
  StyledPreviewDialogPageNumber,
  StyledPreviewDrawingTitle,
  StyledPreviewImageContainer,
} from "./DrawingsPreviewPageStyledComponent";
import { PreviewedDrawingSet, useDrawingStore } from "../Store/useDrawingStore";
import useDrawingsPreviewPage from "../Hooks/useDrawingsPreviewPage";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import {
  ArrowNextReverseColor,
  ArrowPreviousReverseColor,
} from "../Asset/DrawingIcons";
import DoxleIconButton from "../../DoxleDesignPattern/DoxleButtons/DoxleIconButton";
import { IoClose } from "react-icons/io5";
import { editRgbaAlpha } from "../../Utilities/FunctionUtilities";
import useRefComponentDimension from "../../CoreContent/UtilityHooks/useRefComponentDimension";
import {
  TransformWrapper,
  TransformComponent,
  useControls,
} from "react-zoom-pan-pinch";
import DoxleEmptyPlaceHolder from "../../DoxleDesignPattern/DoxleEmptyPlaceHolder/DoxleEmptyPlaceHolder";
import { Error404Banner } from "../../DoxleDesignPattern/DoxleIcons/CommonIcons";

type Props = {
  previewedDrawingSet: PreviewedDrawingSet;
};

const DrawingsPreviewPage = ({ previewedDrawingSet }: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }),
    shallow
  );
  const {
    backdropDisplay,
    currentIndex,
    setCurrentIndex,
    setBackdropDisplay,
    handleClosePreviewScreen,
    handleClickPrevBtn,
    isDisablePrevBtn,
    isDisableNextBtn,
    handleClickNextBtn,
    isCurrentImgErr,
    setIsCurrentImgErr,
  } = useDrawingsPreviewPage({ previewedDrawingSet });
  const { setPreviewedDrawingSet } = useDrawingStore(
    (state) => ({
      setPreviewedDrawingSet: state.setPreviewedDrawingSet,
    }),
    shallow
  );
  const drawing = previewedDrawingSet.drawingSet;
  const verticalImage =
    drawing.sheets[currentIndex].height > drawing.sheets[currentIndex].width;
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  const dialogContainerRef = useRef<HTMLDivElement>(null);

  const DrawingPreviewControls = () => {
    const { zoomIn, zoomOut, resetTransform } = useControls();
    return (
      <StyledPreviewDialogPageNumber
        $themeColor={doxleThemeColor}
        $doxleFont={doxleFont}
      >
        <StyledDrawingsPreviewPageNavigateLeftButton
          disableRipple
          style={{ backgroundColor: "transparent" }}
          onClick={() => {
            if (currentIndex > 0) resetTransform();
            handleClickPrevBtn();
          }}
        >
          <ArrowPreviousReverseColor themeColor={doxleThemeColor} />
        </StyledDrawingsPreviewPageNavigateLeftButton>

        <span className="page-number-text">
          Page {currentIndex + 1} of {drawing.sheets.length}
        </span>

        <StyledDrawingsPreviewPageNavigateRightButton
          disableRipple
          onClick={() => {
            if (currentIndex < previewedDrawingSet.drawingSet.sheets.length - 1)
              resetTransform();
            handleClickNextBtn();
          }}
        >
          <ArrowNextReverseColor themeColor={doxleThemeColor} />
        </StyledDrawingsPreviewPageNavigateRightButton>
      </StyledPreviewDialogPageNumber>
    );
  };

  const { width, height: containerRefHeight } = useRefComponentDimension({
    componentRef: dialogContainerRef,
  });
  const maxWidth =
    containerRefHeight *
    0.95 *
    (Number(drawing.sheets[currentIndex].width) /
      Number(drawing.sheets[currentIndex].height));

  return (
    <StyledDrawingPreviewDialog
      slotProps={{
        backdrop: {
          style: {
            backgroundColor: editRgbaAlpha({
              rgbaColor: doxleThemeColor.primaryBackgroundColor,
              alpha: "0.8",
            }),
          },
        },
      }}
      open={true}
      onClose={() => setPreviewedDrawingSet(undefined)}
    >
      <TransformWrapper>
        <StyledDrawingPreviewDialogContainer>
          <StyledDrawingPreviewDialogTop>
            <StyledPreviewDrawingTitle
              $themeColor={doxleThemeColor}
              $doxleFont={doxleFont}
            >
              {previewedDrawingSet.drawingSet.name}
            </StyledPreviewDrawingTitle>
            <DrawingPreviewControls />
            <DoxleIconButton
              iconSource={
                <IoClose size="5rem" color={doxleThemeColor.primaryFontColor} />
              }
              buttonSize={50}
              buttonWrapperStyle={{
                position: "absolute",
                right: 0,
              }}
              onClick={() => setPreviewedDrawingSet(undefined)}
            />
          </StyledDrawingPreviewDialogTop>

          {!isCurrentImgErr ? (
            <StyledPreviewImageContainer ref={dialogContainerRef}>
              <TransformComponent wrapperStyle={{ overflow: "visible" }}>
                <StyledDrawingsPreviewPageImage
                  $maxWidth={maxWidth}
                  $width={width}
                  $aspectRatio={
                    Number(drawing.sheets[currentIndex].width) /
                    Number(drawing.sheets[currentIndex].height)
                  }
                  src={drawing.sheets[currentIndex].imageUrl}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  onError={() => setIsCurrentImgErr(true)}
                />
                {/* )} */}
              </TransformComponent>
            </StyledPreviewImageContainer>
          ) : (
            <DoxleEmptyPlaceHolder
              headTitleText="Failed to load image, please try again later"
              containerBgColor="transparent"
              headTitleTextStyle={{
                fontSize: 20,
                color: "red",
              }}
              illustration={
                <Error404Banner
                  themeColor={doxleThemeColor}
                  containerStyle={{
                    width: "100%",
                    maxWidth: 350,
                    marginBottom: 14,
                  }}
                />
              }
            />
          )}
        </StyledDrawingPreviewDialogContainer>
      </TransformWrapper>
    </StyledDrawingPreviewDialog>
  );
};

export default DrawingsPreviewPage;
