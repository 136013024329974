import { motion } from "framer-motion";
import React from "react";
import {
  StyledEditFunctionFieldLabel,
  StyledToggleFunctionalMenuSection,
} from "./StyledComponentDocketInfo";
import { useEditDocketSideScreenStore } from "../../EditDocketSideScreenStore/useEditDocketSideScreenStore";
import { shallow } from "zustand/shallow";
import DoxleCheckbox from "../../../../DoxleDesignPattern/DoxleCheckbox/DoxleCheckbox";
import {
  DoxleCheckedIcon,
  DoxleUncheckedIcon,
} from "../../../../DoxleDesignPattern/DoxleGeneralIcons";
import { useDoxleThemeStore } from "../../../../DoxleGeneralStore/useDoxleThemeStore";
import { CheckboxProps } from "@mui/material/Checkbox";
import { useShallow } from "zustand/react/shallow";

type Props = {};

const ToggleFunctionalMenuSection = (props: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore((state) => ({
    doxleThemeColor: state.doxleThemeColor,
    doxleFont: state.doxleFont,
  }));
  const { docketFunctionMenu, toggleDisplayFunctionMenu } =
    useEditDocketSideScreenStore(
      useShallow((state) => ({
        docketFunctionMenu: state.docketFunctionMenu,
        toggleDisplayFunctionMenu: state.toggleDisplayFunctionMenu,
      }))
    );
  const checkboxLabelStyle: CheckboxProps["sx"] = {
    marginInline: "8px !important",
    marginBlock: "8px",
    minWidth: "144px !important",
    width: "15% !important",
    "& .MuiTypography-root": {
      display: "flex",

      flexDirection: "row",
      color: doxleThemeColor.primaryFontColor,
      fontWeight: 400,
      padding: "0px",
      fontSize: 14,
      fontFamily: doxleFont.primaryFont,
      cursor: "pointer",
    },
  };
  return (
    <StyledToggleFunctionalMenuSection>
      <StyledEditFunctionFieldLabel>
        Docket Options
      </StyledEditFunctionFieldLabel>
      <motion.div
        layout="position"
        transition={{
          duration: 0.2,
          damping: 16,
        }}
        className="row-field-wrapper"
      >
        {docketFunctionMenu.map(
          (menu, idx) =>
            menu.tabName !== "Detail" && (
              <DoxleCheckbox
                key={`menuItem${menu.tabName}#${idx}`}
                checked={menu.display}
                onChangeCallback={(e) =>
                  toggleDisplayFunctionMenu(menu, e.target.checked)
                }
                icon={
                  <DoxleUncheckedIcon
                    themeColor={doxleThemeColor}
                    containerStyle={{
                      width: 14,
                    }}
                  />
                }
                checkedIcon={
                  <DoxleCheckedIcon
                    themeColor={doxleThemeColor}
                    containerStyle={{
                      width: 14,
                    }}
                  />
                }
                labelText={
                  menu.tabName === "Scope Of Works" ? "Tasks" : menu.tabName
                }
                labelStyle={checkboxLabelStyle}
              />
            )
        )}
      </motion.div>
    </StyledToggleFunctionalMenuSection>
  );
};

export default ToggleFunctionalMenuSection;
