import React, { useMemo } from "react";
import { StyledQRSummaryGroupFooter } from "./StyledQRLogSummary";
import { useQRLogSummaryContext } from "./QRLogSummary";

type Props = {
  userId: string;
};

const QRSummaryGroupFooter = ({ userId }: Props) => {
  const { userLogList } = useQRLogSummaryContext();
  const totalHours = useMemo(
    () =>
      userLogList
        .reduce((acc, log) => {
          if (log.user === userId && log.exitTime) {
            return (
              acc +
              (new Date(log.exitTime).getTime() -
                new Date(log.entryTime).getTime()) /
                (1000 * 60 * 60)
            );
          }
          return acc;
        }, 0)
        .toFixed(2),
    [userLogList]
  );
  return (
    <StyledQRSummaryGroupFooter>
      <span>Total</span>

      <span>{totalHours}</span>
    </StyledQRSummaryGroupFooter>
  );
};

export default QRSummaryGroupFooter;
