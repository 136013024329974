import React, { useState } from "react";
import { useJoinContext } from "../../pages/Join";
import { ManWithBook } from "../svg/manWithBook";
import { shallow } from "zustand/shallow";
import { useDoxleThemeStore } from "../../../../DoxleGeneralStore/useDoxleThemeStore";
import {
  ApplyBtn,
  FormArea,
  JoinFormContainer,
  JoinFormLeft,
  JoinFormRight,
  JoinFormRightInnerContainer,
  RequiredWarning,
  StyledInput,
} from "./StyledJoinPage";
import useJoinFormPage from "../../Hooks/useJoinFormPage";
import Loading from "../../../../Utilities/Lottie/Loading";
import { StyledErrorText } from "../../pages/styles/StyledConactUsPage";
import { StyledBannerContainer } from "../../pages/styles/StyledMainPage";

export default function JoinFormPage() {
  const { doxleThemeColor, doxleFont, currentTheme } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
      currentTheme: state.currentTheme,
    })
  );
  const {
    NAME_TRIGGER_ID,
    MOBILE_TRIGGER_ID,
    EMAIL_TRIGGER_ID,
    sendState,
    prompt,
    name,
    setName,
    mobile,
    setMobile,
    email,
    setEmail,
    company,
    setCompany,
    reference,
    setReference,
    handleSubmit,
  } = useJoinFormPage();
  return (
    <JoinFormContainer>
      <JoinFormLeft>
        <StyledBannerContainer>
          <ManWithBook themeColor={doxleThemeColor} />
        </StyledBannerContainer>
      </JoinFormLeft>
      <JoinFormRight>
        <JoinFormRightInnerContainer
          $themeColor={doxleThemeColor}
          $currentTheme={currentTheme}
        >
          {sendState === "sending" ? (
            <Loading />
          ) : (
            <FormArea>
              <RequiredWarning $doxleFont={doxleFont}>
                {prompt[NAME_TRIGGER_ID]}
              </RequiredWarning>
              <StyledInput
                $themeColor={doxleThemeColor}
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Name *"
                $currentTheme={currentTheme}
              />

              <RequiredWarning $doxleFont={doxleFont}>
                {prompt[MOBILE_TRIGGER_ID]}
              </RequiredWarning>
              <StyledInput
                $themeColor={doxleThemeColor}
                type="text"
                value={mobile}
                onChange={(e) => setMobile(e.target.value)}
                placeholder="Mobile *"
                $currentTheme={currentTheme}
              />

              <RequiredWarning $doxleFont={doxleFont}>
                {prompt[EMAIL_TRIGGER_ID]}
              </RequiredWarning>
              <StyledInput
                $currentTheme={currentTheme}
                $themeColor={doxleThemeColor}
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email *"
              />

              <StyledInput
                $currentTheme={currentTheme}
                $themeColor={doxleThemeColor}
                type="text"
                value={company}
                onChange={(e) => setCompany(e.target.value)}
                placeholder="Company"
              />

              <StyledInput
                $currentTheme={currentTheme}
                $themeColor={doxleThemeColor}
                type="text"
                value={reference}
                onChange={(e) => setReference(e.target.value)}
                placeholder="How did you find us?"
              />

              <ApplyBtn $themeColor={doxleThemeColor} onClick={handleSubmit}>
                Apply
              </ApplyBtn>
              {sendState === "error" && (
                <StyledErrorText $themeColor={doxleThemeColor}>
                  An Error Occurred Please Try Again
                </StyledErrorText>
              )}
            </FormArea>
          )}
        </JoinFormRightInnerContainer>
      </JoinFormRight>
    </JoinFormContainer>
  );
}
