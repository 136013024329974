import React, { useMemo, useState } from "react";
import { ScopeOfWorkItem } from "../Model/scopeOfWorks";
import { TransitionProps } from "@mui/material/transitions";
import Grow from "@mui/material/Grow";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import { AnimatePresence, motion } from "framer-motion";
import {
  StyledAssigneeListPopover,
  StyledScopeGroupAssigneeDisplay,
  StyledScopeItemAssigneeDisplay,
} from "./StyledComponentScopeOfWorkFullScreen";
import { SOWAssigneeeIcon } from "./ScopeOfWorkIcon";
import Popover from "@mui/material/Popover";
import { RiUserLine } from "react-icons/ri";

type Props = {
  scopeItem: ScopeOfWorkItem;
};

const ScopeItemAssigneeDisplay = ({ scopeItem }: Props) => {
  const [anchorAssigneeList, setAnchorAssigneeList] =
    useState<HTMLElement | null>(null);
  const { doxleThemeColor, doxleFont, currentTheme } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
      currentTheme: state.currentTheme,
    }),
    shallow
  );
  const canBeOpen = Boolean(anchorAssigneeList);
  const id = canBeOpen ? "assignee-list-popper" : undefined;

  const assigneeNameList = useMemo(() => {
    const initialName: string[] = [];

    if (scopeItem.assignedContacts && scopeItem.assignedContacts.length > 0)
      initialName.push(...scopeItem.assignedContactNames.split(", "));

    return initialName;
  }, [scopeItem]);

  if (assigneeNameList.length > 0)
    return (
      <>
        <AnimatePresence>
          <StyledScopeItemAssigneeDisplay
            $themeColor={doxleThemeColor}
            $doxleFont={doxleFont}
            $currentTheme={currentTheme}
            layout="position"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              if (assigneeNameList.length > 1)
                setAnchorAssigneeList(e.currentTarget);
            }}
            animate={{
              x: [-5, 0],
              opacity: [0, 1],
            }}
            exit={{
              x: [0, -5],
              opacity: [1, 0],
            }}
            transition={{
              duration: 0.1,
              damping: 12,
            }}
          >
            <SOWAssigneeeIcon
              themeColor={doxleThemeColor}
              containerStyle={{
                width: 16,
              }}
            />

            <span className="title">
              {assigneeNameList[0]
                .split(" ")
                .map((item, idx) => (idx <= 1 ? item[0] : ""))}
            </span>

            {assigneeNameList.length - 1 > 0 && (
              <span className="remaining-assignee-num">
                (+{assigneeNameList.length - 1})
              </span>
            )}
          </StyledScopeItemAssigneeDisplay>
        </AnimatePresence>
        <Popover
          id={id}
          open={canBeOpen}
          anchorEl={anchorAssigneeList}
          disableScrollLock
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          TransitionComponent={Transition}
          onClose={() => setAnchorAssigneeList(null)}
          sx={{
            "& .MuiPopover-paper": {
              backgroundColor: "transparent",
              overflow: "visible",
              boxShadow: "none",
            },
          }}
          transitionDuration={{ appear: 50, exit: 50 }}
          elevation={2}
        >
          <StyledAssigneeListPopover
            $themeColor={doxleThemeColor}
            $doxleFont={doxleFont}
          >
            <span className="title">Assignees</span>

            {assigneeNameList.map((name, idx) => (
              <motion.div
                animate={{ y: [3, 0], opacity: [0, 1] }}
                transition={{
                  damping: 16,
                  delay: idx * 0.05,
                }}
                className="item"
                key={`${name}#${idx}`}
              >
                <RiUserLine
                  color={doxleThemeColor.primaryFontColor}
                  size={16}
                  className="icon"
                />
                {name}
              </motion.div>
            ))}
          </StyledAssigneeListPopover>
        </Popover>
      </>
    );
  else return <></>;
};

export default ScopeItemAssigneeDisplay;

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Grow ref={ref} {...props} />;
});
