import React, { useState } from "react";

import dayjs, { Dayjs } from "dayjs";
import { DatePicker, DatePickerProps } from "@mui/x-date-pickers/DatePicker";
import {
  BaseSingleInputFieldProps,
  DateValidationError,
  FieldSection,
} from "@mui/x-date-pickers/models";

import { shallow } from "zustand/shallow";
import { StyledCustomDatePicker } from "./StyledComponentDocketInfo";
import { useDoxleThemeStore } from "../../../../DoxleGeneralStore/useDoxleThemeStore";
import { customDatePickerStyle } from "../../../../Models/modelHelper";
import { IoChevronDownOutline } from "react-icons/io5";
interface ButtonFieldProps
  extends BaseSingleInputFieldProps<
    Dayjs | null,
    Dayjs,
    FieldSection,
    DateValidationError
  > {
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

function ButtonField(props: ButtonFieldProps) {
  const {
    setOpen,
    label,
    InputProps: { ref } = {},
    inputProps: { "aria-label": ariaLabel } = {},

    value,
  } = props;
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }),
    shallow
  );

  return (
    <StyledCustomDatePicker
      $themeColor={doxleThemeColor}
      $doxleFont={doxleFont}
      onClick={() => setOpen?.((prev) => !prev)}
      ref={ref}
      aria-label={ariaLabel}
      layout="position"
      transition={{
        duration: 0.2,
        damping: 12,
      }}
      $isNull={Boolean(!value)}
    >
      <span>{label || "Select Date"}</span>
      <IoChevronDownOutline
        className="icon-dropdown"
        color={doxleThemeColor.primaryFontColor}
        size={14}
      />
    </StyledCustomDatePicker>
  );
}

function ButtonDatePicker(
  props: Omit<DatePickerProps<Dayjs>, "open" | "onOpen" | "onClose">
) {
  const [open, setOpen] = React.useState(false);
  const { doxleThemeColor } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
    }),
    shallow
  );

  const { field, ...rest } = customDatePickerStyle<any>(doxleThemeColor);

  return (
    <DatePicker
      slots={{ field: ButtonField, ...props.slots }}
      slotProps={{
        field: { setOpen } as any,

        ...rest,
      }}
      {...props}
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
    />
  );
}

export default function CustomDatePicker({
  value,
  onChange,
}: {
  value: string | null;
  onChange?: (value: dayjs.Dayjs | null) => void;
}) {
  return (
    <ButtonDatePicker
      label={value === null ? null : `${dayjs(value).format("DD MMMM YYYY")}`}
      value={value ? dayjs(value) : null}
      onChange={(newValue) => {
        onChange && onChange(newValue);
      }}
    />
  );
}
