import React, { useCallback, useEffect, useState } from "react";
import { shallow } from "zustand/shallow";
import { useOrdersStore } from "../../OrdersStore/useOrdersStore";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { OrderStatus } from "../../Models/order";
import {
  RootOrderTabMenu,
  StyledOrderStatusMenuItemBtn,
} from "../OrderPage/StyledComponents";
import { motion } from "framer-motion";
import OrderStatusItem from "./OrderStatusItem";
import useGetOrderStatus from "../../Hooks/useGetOrderStatus";
import { useEditOrderStore } from "../../OrdersStore/useEditOrderStore";
import {
  StyledOrderSelectToggleForm,
  StyledOrderToggleForm,
  StyledOrderTogglePaymentCheckbox,
  StyledOrderToggleSelectAllCheckbox,
} from "./StyledOrderDocketTab";
import FormControlLabel from "@mui/material/FormControlLabel";
import {
  DoxleCBCheckedIcon,
  DoxleCBUncheckIcon,
  DoxleCheckedIcon,
  DoxleUncheckedIcon,
} from "../../../DoxleDesignPattern/DoxleGeneralIcons";
import OrderActionDialog from "./OrderActionDialog";
import { useEditDocketSideScreenStore } from "../../../CoreContent/EditDocketSideScreen/EditDocketSideScreenStore/useEditDocketSideScreenStore";
import { StyledCustomiseOrderFunctionBtn } from "../../../CoreContent/EditDocketSideScreen/StyledComponentsEditDocketSideScreen";
import { CircularProgress } from "@mui/material";
import OrderSelectAll from "./OrderSelectAll";
import { useShallow } from "zustand/react/shallow";
interface IProjectKebabMenu {
  anchorEl: (EventTarget & SVGSVGElement) | null;
  open: boolean;
}
interface IOrdersTabMenu {
  setIsSearching?: React.Dispatch<React.SetStateAction<boolean>>;
}
const OrdersTabMenu = ({ setIsSearching }: IOrdersTabMenu) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }),
    shallow
  );

  const {
    selectedStatus,
    setSelectedStatus,
    toggleShowPayments,
    showPayments,
    toggleSelectAllOrder,
    selectAllOrder,
    selectedOrders,
  } = useOrdersStore(
    useShallow((state) => ({
      selectedStatus: state.selectedStatus,
      setSelectedStatus: state.setSelectedStatus,
      showPayments: state.showPayments,
      toggleShowPayments: state.toggleShowPayments,
      toggleSelectAllOrder: state.toggleSelectAllOrder,
      selectAllOrder: state.selectAllOrder,
      selectedOrders: state.selectedOrders,
    }))
  );
  const { orderActionBtns } = useEditDocketSideScreenStore(
    useShallow((state) => ({
      handleAddBtn: state.handleAddButtonFn,
      orderActionBtns: state.orderActionBtns,
    }))
  );
  const { editedOrder } = useEditOrderStore(
    (state) => ({
      editedOrder: state.editedOrder,
      // setEditedOrder: state.setEditedOrder,
    }),
    shallow
  );

  const onGetStatusSuccessCb = useCallback(
    (list: OrderStatus[]) => {},
    [selectedStatus, setSelectedStatus]
  );

  const { orderStatuses } = useGetOrderStatus({ onGetStatusSuccessCb });

  useEffect(() => {
    if (orderStatuses && orderStatuses.length && selectedStatus === undefined) {
      const lastStatusId = sessionStorage.getItem("lastOrderStatusId");
      const storageStatus: OrderStatus | undefined = orderStatuses.filter(
        (status) => status.statusId === lastStatusId
      )?.[0];
      const issuedStatus: OrderStatus | undefined = orderStatuses.filter(
        (status) => status.statusName.toLowerCase().includes("issue")
      )?.[0];
      setSelectedStatus(storageStatus ?? issuedStatus ?? orderStatuses?.[0]);
    }
  }, [orderStatuses, selectedStatus, setSelectedStatus]);

  const rootMenuAnimatedVariants = {
    entering: {
      x: [-10, 0],
      opacity: [0, 1],
      transition: {
        duration: 0.4,
      },
    },
  };

  return (
    <RootOrderTabMenu
      variants={rootMenuAnimatedVariants}
      initial={false}
      animate="entering"
      $editOrderPage={Boolean(editedOrder)}
      $themeColor={doxleThemeColor}
      layout="position"
      transition={{
        duration: 0.1,
        damping: 16,
      }}
    >
      {!editedOrder && <OrderSelectAll />}
      {orderStatuses.map((status, idx) => (
        <OrderStatusItem orderStatus={status} key={status.statusId} />
      ))}

      <div
        style={{
          flex: 1,
          flexDirection: "row",
          display: "flex",
          justifyContent: "end",
          cursor: "pointer",
          alignItems: "center",
        }}
      >
        {!editedOrder && selectedOrders.length === 0 && (
          <StyledOrderToggleForm
            $themeColor={doxleThemeColor}
            $doxleFont={doxleFont}
            control={
              <StyledOrderTogglePaymentCheckbox
                onChange={(e) => toggleShowPayments(e.target.checked)}
                icon={
                  <DoxleUncheckedIcon
                    themeColor={doxleThemeColor}
                    containerStyle={{
                      width: 16,
                    }}
                  />
                }
                checkedIcon={
                  <DoxleCheckedIcon
                    themeColor={doxleThemeColor}
                    containerStyle={{
                      width: 16,
                    }}
                  />
                }
              />
            }
            label={showPayments ? "Hide Payments" : "Show Payments"}
          />
        )}

        {!editedOrder && selectedOrders.length > 0 && (
          <>
            {orderActionBtns.map((btn, idx) => (
              <StyledCustomiseOrderFunctionBtn
                layout="position"
                key={`orderBTn#${idx}`}
                initial={{
                  y: 5,
                  opacity: 0,
                }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: 5, opacity: 0 }}
                onClick={(e) => btn.btnFunction(e)}
                style={btn.btnStyle}
              >
                {btn.isLoading ? (
                  <CircularProgress
                    size="1.4rem"
                    style={{
                      color: doxleThemeColor.primaryFontColor,
                      marginRight: "0.4rem",
                    }}
                  />
                ) : (
                  btn.btnIcon ?? <></>
                )}

                {btn.btnText}
              </StyledCustomiseOrderFunctionBtn>
            ))}
          </>
        )}
      </div>
    </RootOrderTabMenu>
  );
};

export default OrdersTabMenu;
