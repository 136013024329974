import React, { useCallback, useLayoutEffect, useMemo, useRef } from "react";
import { useDoxleCurrentContextStore } from "../../../../DoxleGeneralStore/useDoxleCurrentContext";
import { shallow } from "zustand/shallow";
import useDoxleNotificationStore from "../../../../DoxleGeneralStore/useDoxleNotificationStore";
import { useDoxleAuthStore } from "../../../../DoxleGeneralStore/useDoxleAuthStore";
import { useDoxleThemeStore } from "../../../../DoxleGeneralStore/useDoxleThemeStore";
import DoxleEmptyPlaceHolder from "../../../../DoxleDesignPattern/DoxleEmptyPlaceHolder/DoxleEmptyPlaceHolder";
import { CircularProgress } from "@mui/material";
import {
  DoxleImageGalleryItem,
  useDoxleImageGalleryStore,
} from "../../../../DoxleDesignPattern/DoxleGallery/Store/useDoxleImageGalleryStore";

import { Discussion } from "../../../../Models/discussion";
import FilesAPI from "../../../../Services/QueryHooks/fileAndFolderQueryAPI";
import { DoxleFile } from "../../../../Models/files";
import { isEmpty } from "lodash";
import { StyledDiscussionAttachmentDisplayerContainer } from "./StyledComponentBookingDocketDetail";
import DiscussionAttachmentItem from "./DiscussionAttachmentItem";

type Props = {
  discussionItem: Discussion;
};
const DiscussionAttachmentDisplayer = ({ discussionItem }: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }),
    shallow
  );
  const { setImageList, setCurrentImageIndex } = useDoxleImageGalleryStore(
    (state) => ({
      setImageList: state.setImageList,
      setCurrentImageIndex: state.setCurrentImageIndex,
    }),
    shallow
  );

  const imageList: DoxleFile[] = useMemo(
    () =>
      discussionItem.attachments && discussionItem.attachments.length > 0
        ? discussionItem.attachments.filter((attachment) =>
            attachment.fileType.toLowerCase().includes("image")
          )
        : [],
    [discussionItem.attachments]
  );

  const fileList: DoxleFile[] = useMemo(
    () =>
      discussionItem.attachments && discussionItem.attachments.length > 0
        ? discussionItem.attachments.filter(
            (attachment) => !attachment.fileType.toLowerCase().includes("image")
          )
        : [],
    [discussionItem.attachments]
  );
  const galleryImageList: DoxleImageGalleryItem[] = useMemo(
    () =>
      imageList.map((image) => ({
        uri: image.url,
        thumbnailUrl: image.thumbUrl,
        name: image.fileName,
        size: image.fileSize,
      })),
    [imageList]
  );
  const handleClickImage = useCallback(
    (imgIndex: number) => {
      setImageList(galleryImageList);
      setCurrentImageIndex(imgIndex);
    },
    [galleryImageList, setImageList]
  );

  return Boolean(!isEmpty(imageList) || !isEmpty(fileList)) ? (
    <StyledDiscussionAttachmentDisplayerContainer $themeColor={doxleThemeColor}>
      {imageList.length > 0 && (
        <div className="attachment-list-wrapper">
          {imageList.map((item, index) => (
            <DiscussionAttachmentItem
              key={item.fileId}
              item={item}
              itemIndex={index}
              handleClickImage={handleClickImage}
            />
          ))}
        </div>
      )}
      {fileList.length > 0 && (
        <div className="attachment-list-wrapper" style={{ marginBottom: 0 }}>
          {fileList.map((item, index) => (
            <DiscussionAttachmentItem
              key={item.fileId}
              item={item}
              itemIndex={index}
            />
          ))}
        </div>
      )}
    </StyledDiscussionAttachmentDisplayerContainer>
  ) : (
    <></>
  );
};

export default React.memo(DiscussionAttachmentDisplayer);
