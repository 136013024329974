import {
  QueryClient,
  useInfiniteQuery,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import DoxleAPI from "../../Services/DoxleAPI";
import { Company } from "../../Models/company";
import { BaseAPIProps } from "../../Models/baseAPIProps";
import { Discussion } from "../../Models/discussion";
import {
  AxiosBackendErrorReturn,
  IApiPaginatedData,
} from "../../Models/axiosReturn";
import useSetDiscussionQueryData from "../../CoreContent/QueryDataHooks/SetQueryDataHooks/useSetDiscussionQueryData";
import { isAxiosError } from "axios";

//! PASS THIS FILTER FOR ALL OF THE QUERY RELATED TO DISCUSSION LIST QUERY TO USE OVERWRITE QUERY FUNCTION, which will help stablize query data and storing with unique query key
export interface FilterGetDiscussionQuery {
  projectId?: string;
  docketId?: string;
  rfiPk?: string;
}

interface GetDiscussionListQueryProps extends BaseAPIProps {
  filter: FilterGetDiscussionQuery;
  paginated?: boolean;
  pageSize?: number;
}
const useGetDiscussionListQuery = ({
  showNotification,

  company,
  filter,
  paginated,
  pageSize = 20,
}: GetDiscussionListQueryProps) => {
  const { projectId, docketId, rfiPk } = filter;

  const qKey = formDiscussionQueryKey({ filter, company });
  let discussionsURL = "/discussions/?page=1";

  let getParams: any = {};
  if (company) getParams.company = company?.companyId;
  if (projectId) getParams.projectId = projectId;
  if (docketId) getParams.docket = docketId;
  if (rfiPk) getParams.rfiPk = rfiPk;
  if (paginated) {
    getParams.pagination = true;
  }

  getParams.order_by = "-time_stamp";
  getParams.page_size = pageSize;
  const discussionQuery = useInfiniteQuery({
    queryKey: qKey,
    queryFn: async ({ pageParam = discussionsURL }) => {
      return DoxleAPI.get<IApiPaginatedData<Discussion>>(pageParam, {
        headers: {
          "User-Company": company?.companyId ?? "",
        },
        params: getParams,
      });
    },
    getNextPageParam: (prevData) => prevData.data.next,
    getPreviousPageParam: (prevData) => prevData.data.previous,
    onSuccess: () => {},
    onError: (error) => {
      console.log("comments API ERROR!");
      if (showNotification) {
        if (isAxiosError<AxiosBackendErrorReturn>(error)) {
          showNotification(
            `${error?.response?.status ?? "ERROR"}: ${
              error.response?.data.detail ?? "UNKNOWN ERROR"
            }`,
            "error",
            String(
              error?.response?.data?.detail ?? "Failed To Load Comments"
            ).substring(0, 300)
          );
        } else {
          showNotification(
            "Something Wrong!",
            "error",
            "Failed To Load Comments"
          );
        }
      }
    },
    enabled: Boolean(company !== undefined),
    retry: 1,
    refetchInterval: 4 * 60 * 1000,
    refetchIntervalInBackground: true,
  });
  return discussionQuery;
};

interface AddDiscussionQueryProps extends BaseAPIProps {
  filter: FilterGetDiscussionQuery;
  onSuccessCb?: Function;
  overwritePos?: "start" | "end";
}
export interface AddedDiscussionFile {
  uri: string;
  name: string;
  type: string;
}
const useAddDiscussionQuery = ({
  filter,
  onSuccessCb,

  company,
  showNotification,
  overwritePos = "end",
}: AddDiscussionQueryProps) => {
  const { handleAddDiscussion } = useSetDiscussionQueryData({
    filter,
    appendPos: overwritePos,
  });
  const mutation = useMutation({
    mutationFn: async (data: {
      newDiscussion: Discussion;
      files: AddedDiscussionFile[];
    }) => {
      const { newDiscussion, files } = data;

      const formData = new FormData();

      Object.keys(newDiscussion).forEach((key) => {
        const keyItem = key as keyof Discussion;
        if (
          keyItem !== "project" &&
          keyItem !== "docket" &&
          keyItem !== "rfi" &&
          keyItem !== "taggedUsers"
        )
          formData.append(keyItem, newDiscussion[keyItem] as string);

        if (keyItem === "project" && newDiscussion.project)
          formData.append(keyItem, newDiscussion.project);
        if (keyItem === "docket" && newDiscussion.docket)
          formData.append(keyItem, newDiscussion.docket);
        if (keyItem === "rfi" && newDiscussion.rfi)
          formData.append(keyItem, newDiscussion.rfi);
        if (keyItem === "taggedUsers" && newDiscussion.taggedUsers.length > 0) {
          newDiscussion.taggedUsers.forEach((user) =>
            formData.append(keyItem, user)
          );
        }
      });
      if (files.length > 0)
        // files.forEach(file => {
        //   const blobResult = await fetch
        //   formData.append('files', file);
        // });
        for (const file of files) {
          const blobResult = await fetch(file.uri)
            .then((res) => res.blob())
            .catch((err) => undefined);
          if (blobResult) formData.append("files", blobResult, file.name);
        }
      return DoxleAPI.post("/discussions/", formData, {
        headers: {
          "User-Company": company?.companyId || "",
          "Content-Type": "multipart/form-data",
        },
      });
    },
    onSuccess: (result, variables, context) => {
      handleAddDiscussion(result.data);
      if (onSuccessCb) onSuccessCb();
      // if (result.data.errors.length > 0 && showNotification) {
      //   showNotification("Some Files Are Failed To Upload", "error", "", 1200);
      // }
    },
    onError: (error, variables, context) => {
      if (showNotification) {
        if (isAxiosError<AxiosBackendErrorReturn>(error)) {
          showNotification(
            `${error?.response?.status ?? "ERROR"}: ${
              error.response?.data.detail ?? "UNKNOWN ERROR"
            }`,
            "error",
            String(
              error?.response?.data?.detail ?? "Failed To Add Discussion"
            ).substring(0, 300)
          );
        } else {
          showNotification(
            "Something Wrong!",
            "error",
            "Failed To Add Discussion"
          );
        }
      }

      console.log("ERROR:", error);
    },
    // onSettled(data, error, variables, context) {
    //   const queryKey = ['fullDocket-list'];

    //   if (variables.docket.project !== null)
    //     queryKey.push(variables.docket.project);
    //   queryClient.invalidateQueries(queryKey);
    // },
  });
  const mutate = (data: {
    newDiscussion: Discussion;
    files: AddedDiscussionFile[];
  }) => mutation.mutate(data);
  return { ...mutation, mutate: mutate };
};

//################### HELPER FUNCTIONS ##################

export const formDiscussionQueryKey = (props: {
  filter: FilterGetDiscussionQuery;
  company: Company | undefined;
}) => {
  const { filter, company } = props;
  const { projectId, docketId, rfiPk } = filter;
  let baseQKey = ["discussion-list"];
  if (company) baseQKey.push(company.companyId);
  if (projectId) baseQKey.push(projectId);
  if (docketId) baseQKey.push(docketId);
  if (rfiPk) baseQKey.push(rfiPk);

  return baseQKey;
};

//############## END OF HELPER FUNCTIONS ###############
const DiscussionQueryAPI = {
  useGetDiscussionListQuery,

  useAddDiscussionQuery,
};

export default DiscussionQueryAPI;
