import React from "react";
import { OrderLine } from "../../../Models/orders";
import {
  StyledOrderLineListHeaderContainer,
  StyledViewOrderLineSection,
} from "./StyledComponentBookingDocketDetail";
import { useDoxleThemeStore } from "../../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import { ORDER_LINE_FIELDS_LIST } from "../../../store/useBookingProjectStore";

import DoxleEmptyPlaceHolder from "../../../../DoxleDesignPattern/DoxleEmptyPlaceHolder/DoxleEmptyPlaceHolder";
import OrderLineItem from "./OrderLineItem";
import CircularProgress from "@mui/material/CircularProgress";

type Props = {
  isFetchingOrderDetail: boolean;
  isSuccessFetchingOrderDetail: boolean;
  isErrorFetchingOrderDetail: boolean;
  orderLines: OrderLine[];
};

const ViewOrderLineSection = ({
  isFetchingOrderDetail,
  isSuccessFetchingOrderDetail,
  isErrorFetchingOrderDetail,
  orderLines,
}: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }),
    shallow
  );
  return (
    <StyledViewOrderLineSection $themeColor={doxleThemeColor}>
      <StyledOrderLineListHeaderContainer
        $themeColor={doxleThemeColor}
        $doxleFont={doxleFont}
      >
        {ORDER_LINE_FIELDS_LIST.map((header, index) => (
          <div
            key={`${header.fieldName}#${index}`}
            className="header-cell-text"
            style={{
              width:
                header.fieldName === "Qty"
                  ? "10%"
                  : header.fieldName === "Price" ||
                    header.fieldName === "Subtotal"
                  ? "20%"
                  : undefined,
              flex: header.fieldName === "Description" ? 1 : undefined,
              textAlign: header.fieldName === "Description" ? "left" : "center",
              paddingLeft: header.fieldName === "Description" ? 10 : 0,
            }}
          >
            {header.fieldName}
          </div>
        ))}
      </StyledOrderLineListHeaderContainer>

      {isFetchingOrderDetail && (
        <CircularProgress
          style={{ color: doxleThemeColor.primaryFontColor }}
          size="2rem"
        />
      )}
      {isErrorFetchingOrderDetail && (
        <DoxleEmptyPlaceHolder
          headTitleText="Something wrong!"
          headTitleTextStyle={{ fontSize: "1.6rem" }}
          containerStyle={{ height: "10rem" }}
          subTitleText="Pull down to refresh"
          subTitleTextStyle={{ fontSize: "1.2rem" }}
        />
      )}

      {isSuccessFetchingOrderDetail &&
        orderLines.map((line, idx) => (
          <OrderLineItem lineItem={line} key={line.orderLineId} />
        ))}
    </StyledViewOrderLineSection>
  );
};

export default ViewOrderLineSection;
