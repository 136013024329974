import React, { memo } from "react";
import { shallow } from "zustand/shallow";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { useContactsStore } from "../Store/useContactsStore";
import {
  CONTACT_COMPANY_TABLE_HEADER_LIST,
  TContactCompanyTableHeaders,
} from "../../Models/contactsTable";
import {
  CONTACT_COMPANIES_HEADERS_MAP,
  ContactCompanyOrderByFields,
  RevContactCompanyOrderByFields,
} from "../../../Services/QueryHooks/contactsFilters";
import {
  StyledContactTableHeaderCell,
  StyledContactTableHeaderRow,
  StyledContactTableHeaderText,
} from "../StyledComponents";

const ContactCompanyTableHeader = () => {
  const { orderBy, setQueryFilter } = useContactsStore(
    (state) => ({
      orderBy: state.contactCompanyQueryFilter.order_by,
      setQueryFilter: state.setContactCompanyQueryFilter,
    }),
    shallow
  );
  const { doxleThemeColor, doxleFont, staticMenuColor } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
      staticMenuColor: state.staticMenuColor,
    }),
    shallow
  );
  const handleHeaderClick = (header: TContactCompanyTableHeaders) => {
    let newOrderByParam:
      | ContactCompanyOrderByFields
      | RevContactCompanyOrderByFields = CONTACT_COMPANIES_HEADERS_MAP[header];
    if (orderBy && newOrderByParam.includes(orderBy))
      newOrderByParam = `-${newOrderByParam}`;
    setQueryFilter({ order_by: newOrderByParam });
  };

  const checkHeaderMatch = (header: TContactCompanyTableHeaders) => {
    return !!orderBy?.includes(CONTACT_COMPANIES_HEADERS_MAP[header]);
  };

  return (
    <StyledContactTableHeaderRow>
      {CONTACT_COMPANY_TABLE_HEADER_LIST.map(
        (header, idx) =>
          header.isDisplayed && (
            <StyledContactTableHeaderCell
              $themeColor={doxleThemeColor}
              widthInPixel={header.headerName === "Contacts" ? "100px" : null}
              $doxleFont={doxleFont}
              horizontalAlign={
                header.headerName === "Contacts" ? "center" : "flex-start"
              }
              key={`header#${idx}`}
              onClick={() => handleHeaderClick(header.headerName)}
            >
              <StyledContactTableHeaderText
                $fontWeight={checkHeaderMatch(header.headerName) ? 800 : 400}
                $doxleFont={doxleFont}
              >
                {header.headerName}
              </StyledContactTableHeaderText>
              {checkHeaderMatch(header.headerName) &&
                orderBy?.startsWith("-") && (
                  <ArrowDropDownIcon
                    style={{ color: staticMenuColor.staticFontColor }}
                  />
                )}
              {checkHeaderMatch(header.headerName) &&
                !orderBy?.startsWith("-") && (
                  <ArrowDropUpIcon
                    style={{ color: staticMenuColor.staticFontColor }}
                  />
                )}
            </StyledContactTableHeaderCell>
          )
      )}
    </StyledContactTableHeaderRow>
  );
  return <></>;
};

export default memo(ContactCompanyTableHeader);
