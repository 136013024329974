import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import React, {
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { DropEvent, FileRejection, useDropzone } from "react-dropzone";
import {
  RootInvoiceTabPanelDisplayer,
  StyledAddedFileDisplaySection,
  StyledDroppedFileItem,
  StyledDroppedFileItemButton,
  StyledDroppedFileItemFileInfo,
  StyledDroppedFileItemTextSpan,
  StyledDropzone,
  StyledDropzoneButton,
  StyledDropzoneSection,
  StyledHeadTitleDropzone,
  StyledInvoiceTabHeadTitle,
  StyledInvoiceTabSubTitle,
  StyledPaymentInputContainer,
  StyledPurchaseOrderInput,
  StyledSubTitleDropzone,
  StyledAttachedHeaderText,
} from "./StyledPaymentClaimComponents";
import { InputAdornment, Stack, Typography } from "@mui/material";
import { InvoiceFileRemoveIcon, InvoicePDFIcon } from "./Icons";
import OrderAutocomplete from "./OrderAutocomplete";
import paymentClaimAPI from "../../QueryHooks/PaymentClaimAPI";
import { useDoxleAuthStore } from "../../../DoxleGeneralStore/useDoxleAuthStore";
import { useDoxleCurrentContextStore } from "../../../DoxleGeneralStore/useDoxleCurrentContext";
import useDoxleNotificationStore from "../../../DoxleGeneralStore/useDoxleNotificationStore";
import { toNum } from "../../../Utilities/FunctionUtilities";
import DoxleCloseBtn from "../../../DoxleDesignPattern/DoxleButtons/DoxleCloseBtn";
import { replace } from "lodash";
import { usePaymentClaimStore } from "../../OrdersStore/usePaymentClaimStore";

const allowedKeys = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "0",
  ".",
  "ArrowLeft",
  "ArrowRight",
  "Tab",
  "Delete",
  "Backspace",
];

const SubmitNewPaymentClaim = () => {
  const [addedFiles, setAddedFiles] = useState<File[]>([]);
  const [percentageInput, setPercentageInput] = useState<boolean>(false);
  const props = useDoxleThemeStore(
    (state) => ({
      $themecolor: state.doxleThemeColor,
      $doxleFont: state.doxleFont,
    }),
    shallow
  );
  const { mode, setMode, reviewPaymentClaim } = usePaymentClaimStore(
    (state) => ({
      mode: state.action,
      setMode: state.setAction,
      reviewPaymentClaim: state.reviewPaymentClaim,
    }),
    shallow
  );
  const handleClose = () => {
    reviewPaymentClaim ? setMode("review") : setMode(null);
  };
  
  const company = useDoxleCurrentContextStore(
    (state) => state.currentCompany,
    shallow
  );
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );

  const orderRef: MutableRefObject<undefined | HTMLInputElement> = useRef();
  const amountParentRef: MutableRefObject<any> = useRef();
  const amountRef: MutableRefObject<undefined | HTMLInputElement> = useRef();
  const invNumParentRef: MutableRefObject<any> = useRef();
  const invNumRef: MutableRefObject<undefined | HTMLInputElement> = useRef();

  const onDrop = useCallback(
    <T extends File>(
      acceptedFiles: T[],
      fileRejections: FileRejection[],
      event: DropEvent
    ) => {
      setAddedFiles([...addedFiles, ...acceptedFiles]);
    },
    [addedFiles]
  );

  const { getRootProps } = useDropzone({
    onDrop,
    accept: {
      "application/pdf": [".pdf"],
    },
  });

  const mutateQuery = paymentClaimAPI.useMutateQuery({
  
    company,
    showNotification,
    filter: {},
    onSuccessCb: () => handleClose,
  });

  const validateInvoiceNumber = (el: HTMLInputElement, forceFocus?: true) => {
    el.style.border = "rgba(0,0,0,0) 0px solid";
    if (invNumParentRef?.current?.style)
      invNumParentRef.current.style.border = "rgba(0,0,0,0) 0px solid";
    const invoiceNumber = invNumRef?.current?.value ?? "";
    if (!el.value) {
      if (forceFocus) el.focus();
      if (invNumParentRef?.current?.style)
        invNumParentRef.current.style.border = "red 1px solid";
      else el.style.border = "red 1px solid";
      showNotification("Please Enter an Invoice Number", "error", "", 3000);
      return false;
    }
    if (el.value.length > 100) {
      if (forceFocus) el.focus();
      if (invNumParentRef?.current?.style)
        invNumParentRef.current.style.border = "red 1px solid";
      else el.style.border = "red 1px solid";
      showNotification(
        "Invalid Invoice Number",
        "error",
        "Length should be 100 characters or less",
        3000
      );
      return false;
    }
    return invoiceNumber;
  };

  const validateOrder = (el: HTMLInputElement, forceFocus?: true) => {
    el.style.border = "rgba(0,0,0,0) 0px solid";
    const orderId: string = el.getAttribute("data-order-id") ?? "";
    const orderTotal: number = toNum(
      el.getAttribute("data-order-total") ?? "0"
    );
    let orderValid: boolean = true;
    if (!orderId) {
      if (forceFocus) el.focus();
      el.style.border = "red 1px solid";
      showNotification("Please Enter an Invoice Number", "error", "", 3000);
      orderValid = false;
    }
    if (el.value.length > 100) {
      if (forceFocus) el.focus();
      el.style.border = "red 1px solid";
      showNotification(
        "Invalid Invoice Number",
        "error",
        "Length should be 100 characters or less",
        3000
      );
      orderValid = false;
    }
    return { orderId, orderTotal, orderValid };
  };

  const validateAmount = (
    el: HTMLInputElement,
    orderTotal: number | null,
    forceFocus?: true
  ) => {
    el.style.border = "rgba(0,0,0,0) 0px solid";
    if (amountParentRef?.current?.style)
      amountParentRef.current.style.border = "rgba(0,0,0,0) 0px solid";
    const inputAmount = Math.round(toNum(el.value ?? "0") * 100) / 100;
    el.value = inputAmount.toFixed(2);
    if (!inputAmount) {
      if (forceFocus) el.focus();
      if (amountParentRef?.current?.style)
        amountParentRef.current.style.border = "red 1px solid";
      else el.style.border = "red 1px solid";
      showNotification("Please Enter a Valid Invoice Total", "error", "", 3000);
      return false;
    }
    orderTotal = orderTotal
      ? orderTotal
      : toNum(orderRef?.current?.getAttribute("data-order-total") ?? null);
    if (
      (percentageInput && inputAmount > 100) ||
      (orderTotal && !percentageInput && inputAmount > orderTotal)
    ) {
      if (forceFocus) el.focus();
      if (amountParentRef?.current?.style)
        amountParentRef.current.style.border = "red 1px solid";
      else el.style.border = "red 1px solid";
      showNotification(
        "Invoice Total Error",
        "error",
        percentageInput
          ? "You cannot claim more than 100% of the order"
          : "Invoice Total cannot be larger than Order Total, please request an ETO",
        3000
      );
      return false;
    }
    return inputAmount;
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    console.log(event.key);
    if ((event.target as any).id === "invoice-value-input") {
      if (event.key === "%") setPercentageInput(true);
      if (event.key === "$") setPercentageInput(false);
      if (!allowedKeys.includes(event.key)) event.preventDefault();
    }
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    // if (event.target.id === "invoice-value-input")
    //   validateAmount(event.target, null);
    // if (event.target.id === "invoice-number-input")
    //   validateInvoiceNumber(event.target);
  };

  const handleProcessInvoiceBtn = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    console.log("here", 0);
    if (!addedFiles.length) return;
    event.stopPropagation();
    console.log("here", 1);
    if (!(orderRef?.current && amountRef?.current && invNumRef?.current))
      return;
    console.log("here", 2);
    const invoiceNumber = validateInvoiceNumber(invNumRef.current, true);
    const { orderId, orderTotal, orderValid } = validateOrder(
      orderRef.current,
      true
    );
    const inputAmount = validateAmount(amountRef.current, orderTotal, true);
    console.log("orderValid", orderValid);
    console.log("inputAmount", inputAmount);
    console.log("invoiceNumber", invoiceNumber);
    if (orderValid && inputAmount && invoiceNumber) {
      console.log("here", 3);
      mutateQuery.addPaymentClaim.mutate({
        order: orderId,
        amount: inputAmount,
        invoiceNumber: invoiceNumber,
        files: addedFiles,
      });
    }
  };

  const rootInvoiceTabAnimatedVariants = {
    initial: {
      opacity: 0,
      y: -300,
      transition: {
        duration: 0.1,
      },
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.1,
        delay: 0.4,
        ease: [0.2, 0.4, 0.6, 0.8],
      },
    },
  };

  return (
    <RootInvoiceTabPanelDisplayer
      variants={rootInvoiceTabAnimatedVariants}
      initial="initial"
      animate="visible"
      exit="initial"
    >
      <div style={{ width: "100%", display: "flex", justifyContent: "right" }}>
        <DoxleCloseBtn
          onClick={handleClose}
          sx={{ width: "3.6rem", height: "3.6rem" }}
        />
      </div>
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        // height={80}
      >
        <StyledInvoiceTabHeadTitle {...props}>
          {mode === "backcharge" ? "Add a backcharge" : "Upload your invoice"}
        </StyledInvoiceTabHeadTitle>
        <StyledInvoiceTabSubTitle>
          {mode === "backcharge"
            ? "Enter a clear reason for the backcharge as the contractor will be alerted to the change"
            : "Invoices should have purchase order number to be actioned for payment"}
        </StyledInvoiceTabSubTitle>
      </Stack>
      {mode === "backcharge" ? (
        <StyledPurchaseOrderInput
          {...props}
          fullWidth
          ref={amountParentRef}
          value={`Payment Claim: ${reviewPaymentClaim?.invoiceNumber} - Total Due: $${reviewPaymentClaim?.totalDue}`}
          inputRef={orderRef}
        />
      ) : (
        <OrderAutocomplete inputRef={orderRef} />
      )}
      <StyledPaymentInputContainer>
        <StyledPurchaseOrderInput
          {...props}
          fullWidth
          ref={amountParentRef}
          placeholder={
            percentageInput
              ? "Claim a Percentage of the Total Order"
              : "Invoice Dollar Value inc GST"
          }
          inputRef={amountRef}
          id={"here"}
          InputProps={{
            id: "invoice-value-input",
            onKeyDown: handleKeyDown,
            onBlur: handleBlur,
            startAdornment: !percentageInput && (
              <InputAdornment
                style={{ cursor: "pointer", transform: "scale(1.3)" }}
                position="start"
                onClick={() => setPercentageInput((prevState) => !prevState)}
              >
                $
              </InputAdornment>
            ),
            endAdornment: percentageInput && (
              <InputAdornment
                style={{ cursor: "pointer" }}
                position="end"
                onClick={() => setPercentageInput((prevState) => !prevState)}
              >
                %
              </InputAdornment>
            ),
          }}
        />

        <StyledPurchaseOrderInput
          {...props}
          fullWidth
          ref={invNumParentRef}
          placeholder={mode === "backcharge" ? "Reference" : "InvoicesNumber"}
          inputRef={invNumRef}
          InputProps={{
            id: "invoice-number-input",
            onKeyDown: handleKeyDown,
            onBlur: handleBlur,
          }}
        />
      </StyledPaymentInputContainer>
      <StyledDropzoneSection {...props}>
        <StyledDropzone {...getRootProps({ $themecolor: props.$themecolor })}>
          <StyledHeadTitleDropzone>
            {mode === "backcharge"
              ? "Drag & drop your attachment(s)"
              : "Drag & drop your invoice attachment(s)"}
          </StyledHeadTitleDropzone>
          <StyledSubTitleDropzone>
            {mode !== "backcharge" &&
              "Please include only one invoice, you can attach supplementary files if required."}
          </StyledSubTitleDropzone>
          <StyledDropzoneButton onClick={handleProcessInvoiceBtn}>
            {addedFiles.length ? "Process Invoice" : "Attach an Invoice"}
          </StyledDropzoneButton>
        </StyledDropzone>
      </StyledDropzoneSection>

      <StyledAddedFileDisplaySection>
        {addedFiles && (
          <StyledAttachedHeaderText {...props}>
            Attached Files:{" "}
          </StyledAttachedHeaderText>
        )}
        {addedFiles.map((file, index) => (
          <StyledDroppedFileItem key={`droppedFile#${index}`}>
            <StyledDroppedFileItemFileInfo>
              <InvoicePDFIcon />
              <StyledDroppedFileItemTextSpan widthInPixel={null}>
                {file.name}
              </StyledDroppedFileItemTextSpan>
              <StyledDroppedFileItemTextSpan widthInPixel={100}>
                {(file.size * 0.000001).toFixed(2)}Mb
              </StyledDroppedFileItemTextSpan>
              <StyledDroppedFileItemButton
                onClick={() =>
                  setAddedFiles((prevState) => {
                    prevState.splice(index, 1);
                    return [...prevState];
                  })
                }
              >
                <InvoiceFileRemoveIcon />
              </StyledDroppedFileItemButton>
            </StyledDroppedFileItemFileInfo>
            {/*{index % 2 === 0 && (*/}
            {/*  <StyledDroppedFileErrorTextSpan>*/}
            {/*    Your invoice has been approved and will be process in our next*/}
            {/*    payment run of April 15 2023*/}
            {/*  </StyledDroppedFileErrorTextSpan>*/}
            {/*)}*/}
          </StyledDroppedFileItem>
        ))}
      </StyledAddedFileDisplaySection>
    </RootInvoiceTabPanelDisplayer>
  );
};
export default SubmitNewPaymentClaim;
