import React from "react";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";

import { IntRange } from "../../Models/IntRangeType";
import { StyledBookingSlider } from "./StyledComponentBookings";

type Props = {
  value: number;
  onChangeValue: (value: IntRange<0, 101>) => void;
};

const BookingPercentageSlider = ({ value, onChangeValue }: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }),
    shallow
  );
  function valueText(value: number) {
    return `${value}%`;
  }

  return (
    <StyledBookingSlider
      $themeColor={doxleThemeColor}
      step={25}
      marks
      min={0}
      max={100}
      getAriaValueText={valueText}
      value={value}
      onChange={(e, value) => onChangeValue(value as IntRange<0, 101>)}
    />
  );
};

export default BookingPercentageSlider;
