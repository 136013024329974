import { create } from "zustand";

import { produce } from "immer";
import { IFilterBookingQueryProps } from "../QueryAPI/bookingQueryAPI";
import { OrderLine } from "../Models/orders";
import { IBooking } from "../Models/booking";

export type IBookingTableDisplayField =
  | "Title"
  | "Share"
  | "Progress"
  | "Start Date"
  | "End Date"
  | "Due"
  | "Days";
export const BOOKING_PROJECT_TABLE_FIELD: IBookingTableDisplayField[] = [
  "Title",
  "Share",
  "Progress",
  "Start Date",
  "End Date",
  "Due",
  "Days",
];
export interface ViewOrderLineHeaders {
  keyLine: keyof OrderLine;
  fieldName: "Description" | "Qty" | "Price" | "Subtotal";
}

export const ORDER_LINE_FIELDS_LIST: ViewOrderLineHeaders[] = [
  {
    keyLine: "description",
    fieldName: "Description",
  },
  {
    keyLine: "quantity",
    fieldName: "Qty",
  },
  {
    keyLine: "itemCost",
    fieldName: "Price",
  },
  {
    keyLine: "lineCost",
    fieldName: "Subtotal",
  },
];
export interface DocketState {
  //*booking project scope
  onAddBookingMode: boolean;
  setOnAddBookingMode: (show: boolean) => void;
  filterBookingProjectQuery: IFilterBookingQueryProps;
  setFilterBookingProjectQuery: (filter: IFilterBookingQueryProps) => void;

  edittedBooking: IBooking | undefined;
  setEdittedBooking: (item: IBooking | undefined) => void;
  updateEdittedBooking: (data: Partial<IBooking>) => void;

  deletedProjectBooking: IBooking | undefined;
  setDeletedProjectBooking: (item: IBooking | undefined) => void;
}

const useBookingProjectStore = create<DocketState>((set, get) => ({
  onAddBookingMode: false,
  setOnAddBookingMode: (show: boolean) =>
    set((state) =>
      produce(state, (draftState) => {
        draftState.onAddBookingMode = show;

        return draftState;
      })
    ),
  filterBookingProjectQuery: {},
  setFilterBookingProjectQuery: (filter: IFilterBookingQueryProps) =>
    set((state) =>
      produce(state, (draftState) => {
        draftState.filterBookingProjectQuery = {
          ...state.filterBookingProjectQuery,
          ...filter,
        };

        return draftState;
      })
    ),
  edittedBooking: undefined,
  setEdittedBooking: (item: IBooking | undefined) =>
    set((state) =>
      produce(state, (draftState) => {
        draftState.edittedBooking = item;

        return draftState;
      })
    ),
  updateEdittedBooking: (data: Partial<IBooking>) =>
    set((state) =>
      produce(state, (draftState) => {
        if (draftState.edittedBooking) {
          Object.assign(draftState.edittedBooking, data);
        }

        return draftState;
      })
    ),

  deletedProjectBooking: undefined,
  setDeletedProjectBooking: (item: IBooking | undefined) =>
    set((state) =>
      produce(state, (draftState) => {
        draftState.deletedProjectBooking = item;

        return draftState;
      })
    ),
}));

export default useBookingProjectStore;
