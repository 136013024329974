import { create } from "zustand";
import { Estimate, EstimatesFilters } from "../../Models/estimatesFilters";

export interface DisplayTakeOff {
  takeOffId: string;
  unit: string;
}
export type TEstimateDisplayField =
  | "Order"
  | "Description"
  | "Qty"
  | "Amount"
  | "Unit Total"
  | "Unit";

export const Estimate_Header_List: TEstimateDisplayField[] = [
  "Order",
  "Description",
  "Qty",
  "Unit",
  "Amount",
  "Unit Total",
];
interface EditEstimatesStore {
  //!COMPULSORY
  sidePeekEstimate: Estimate | undefined;
  setSidePeekEstimate: (item: Estimate | undefined) => void;
  sidePeekEstimates: Estimate | undefined;
  setSidePeekEstimates: (item: Estimate | undefined) => void;
  estimatesQueryFilter: EstimatesFilters;
  setEstimatesQueryFilter: (item: EstimatesFilters) => void;
  //!<-----

  deletedEstimateList: Estimate[];
  setDeletedEstimateList: (item: Estimate[]) => void;
  clearDeletedEstimateList: () => void;
  displayTakeOff: null | DisplayTakeOff;
  setDisplayTakeOff: (displayTakeOff: DisplayTakeOff | null) => void;
  resetStore: () => void;
}

export const useEditEstimatesStore = create<EditEstimatesStore>((set, get) => ({
  sidePeekEstimate: undefined,
  setSidePeekEstimate: (item: Estimate | undefined) =>
    set({ sidePeekEstimate: item }),
  sidePeekEstimates: undefined,
  setSidePeekEstimates: (item: Estimate | undefined) =>
    set({ sidePeekEstimates: item }),
  estimatesQueryFilter: {},
  setEstimatesQueryFilter: (item: Partial<EstimatesFilters>) =>
    set({ estimatesQueryFilter: { ...get().estimatesQueryFilter, ...item } }),

  deletedEstimateList: [],
  setDeletedEstimateList: (item: Estimate[]) =>
    set({ deletedEstimateList: item }),
  clearDeletedEstimateList: () => set({ deletedEstimateList: [] }),
  displayTakeOff: null,
  setDisplayTakeOff: (displayTakeOff: DisplayTakeOff | null) =>
    set({ displayTakeOff: displayTakeOff }),
  resetStore: () =>
    set((state) => ({
      ...state,
      displayTakeOffId: null,
      sidePeekEstimate: undefined,
      sidePeekEstimates: undefined,
      estimatesQueryFilter: {},
    })),
}));
