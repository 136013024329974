import {useNavigate, useParams} from "react-router-dom";
import Loading from "../../Utilities/Lottie/Loading";

const XeroFirstAuth = () => {
  window.open("/accounts/xero_fa", "_self")
  return(
    <Loading />
  );
}

export default XeroFirstAuth
