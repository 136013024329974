import {useMeasurementsStore} from "../MeasurementsStore/useMeasurementsStore";
import {shallow} from "zustand/shallow";
import {toFloat} from "../konvaFunctions/generalFunctions";
import {DoxRectangle, XY} from "../Models/takeOffs";
import {nanoid} from "nanoid";

interface RectangleProps {}
const useRectangle = ({}: RectangleProps) => {
  const {
    currentShape, setCurrentShape, deduction, currentColor, currentUnit, zHeight, currentSheet, currentTakeOff, addMeasurement
  } = useMeasurementsStore(state => ({
    currentShape: state.currentShape,
    setCurrentShape: state.setCurrentShape,
    deduction: state.deduction,
    currentColor: state.currentColor,
    currentUnit: state.currentUnit,
    zHeight: state.zHeight,
    currentSheet: state.currentSheet,
    currentTakeOff: state.currentTakeOff,
    addMeasurement: state.addMeasurement
  }), shallow)
  const scale: number = toFloat(currentSheet?.scale)
  const mouseMove = (pos: XY) => {
    if (!currentShape) return;
    const currentRectangle = currentShape as DoxRectangle
    let rawValue:number = 0;
    let scaledValue : number = 0;
    let actualValue : number = 0;
    if (currentUnit === "M2"){
      rawValue = (Math.abs(toFloat(currentRectangle.start.x) - pos.x) * Math.abs(toFloat(currentRectangle.start.y) - pos.y)) * deduction
      scaledValue = rawValue * Math.pow(scale, 2)
      actualValue = scaledValue;
    } else if (currentUnit === "M3"){
      rawValue = (Math.abs(toFloat(currentRectangle.start.x) - pos.x) * Math.abs(toFloat(currentRectangle.start.y) - pos.y)) * deduction
      scaledValue = rawValue * Math.pow(scale, 2)
      actualValue = scaledValue * toFloat(currentShape.zMultiplier);
    } else if (currentUnit === "LM") {
      rawValue = (Math.abs(toFloat(currentRectangle.start.x) - pos.x) + Math.abs(toFloat(currentRectangle.start.y) - pos.y)) * 2 * deduction
      scaledValue = rawValue * scale
      actualValue = scaledValue
    } else return

    setCurrentShape({
      ...currentRectangle,
      end: { x: pos.x, y: pos.y },
      rawValue: rawValue,
      scaledValue: scaledValue,
      actualValue: actualValue
    } as DoxRectangle)
  }

  const mouseUp = (pos: XY) => {
    if (!currentShape) {
      setCurrentShape({
        id: nanoid(),
        sheet: currentSheet?.sheetId,
        borderColor: deduction < 0 ? "#000" : currentColor,
        bgColor: deduction < 0 ? "#000" : currentColor,
        borderThickness: 2,
        shape: "rectangle",
        zMultiplier: currentUnit === "M3" && zHeight ? zHeight : 1.0,
        takeOff: currentTakeOff?.takeOffId,
        start: { x: pos.x, y: pos.y },
        end: { x: pos.x, y: pos.y },
        rawValue: 0,
        scaledValue: 0,
        actualValue: 0
      } as DoxRectangle)
    }
    else {
      const currentRectangle = currentShape as DoxRectangle
      setCurrentShape(null)
      let rawValue: number = 0;
      let scaledValue: number = 0;
      let actualValue: number = 0;
      if (currentUnit === "M2") {
        rawValue = (Math.abs(toFloat(currentRectangle.start.x) - pos.x) * Math.abs(toFloat(currentRectangle.start.y) - pos.y)) * deduction
        scaledValue = rawValue * Math.pow(scale, 2)
        actualValue = scaledValue;
      } else if (currentUnit === "M3") {
        rawValue = (Math.abs(toFloat(currentRectangle.start.x) - pos.x) * Math.abs(toFloat(currentRectangle.start.y) - pos.y)) * deduction
        scaledValue = rawValue * Math.pow(scale, 2)
        actualValue = scaledValue * zHeight;
      } else if (currentUnit === "LM") {
        rawValue = (Math.abs(toFloat(currentRectangle.start.x) - pos.x) + Math.abs(toFloat(currentRectangle.start.y) - pos.y)) * 2 * deduction
        scaledValue = rawValue * scale
        actualValue = scaledValue;
      } else return

      const finishedRectangle: DoxRectangle = {
        ...currentRectangle,
        end: {x: pos.x, y: pos.y},
        rawValue: rawValue,
        scaledValue: scaledValue,
        actualValue: actualValue,
      } as DoxRectangle
      addMeasurement(finishedRectangle)
    }
  }

  return {mouseMove, mouseUp}
}

export default useRectangle