import { SVGProps } from "react";
import { DoxleThemeColor } from "../../DoxleGeneralStore/useDoxleThemeStore";


export interface CustomSVGProps extends SVGProps<SVGSVGElement> {
  themeColor: DoxleThemeColor;
}

export const AttachmentUploadIcon = ({ themeColor, ...props }: CustomSVGProps) => (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width={42}
    height={42}
    fill="none"
    {...props}
  >
    <circle
      cx={20.975}
      cy={20.975}
      r={20.475}
      fill="#CFCFF4"
      fillOpacity={0.5}
      stroke="#D5DBE8"
    />
    <path
      fill={themeColor.primaryFontColor}
      d="M15.485 14.438h10.277a.574.574 0 0 0 .572-.577.575.575 0 0 0-.494-.572l-.078-.005H15.486a.574.574 0 0 0-.57.577c0 .292.214.533.493.571l.077.005Zm5.05 14.222.09.006c.39 0 .712-.297.756-.68l.005-.09v-9.68l2.508 2.532a.756.756 0 0 0 1.005.064l.072-.064a.775.775 0 0 0 .063-1.015l-.063-.073-3.805-3.841a.756.756 0 0 0-1.005-.065l-.072.064-3.81 3.842a.774.774 0 0 0 0 1.088.756.756 0 0 0 1.005.064l.072-.064 2.507-2.528v9.677c0 .394.294.719.673.763Z"
    />
  </svg>
);
