import React, { useCallback, useMemo, useRef } from "react";
import { StyledQAProjectListContainer } from "./StyledComponentQAProjectList";
import QAProjectListSkeleton from "./QAProjectListSkeleton";
import useGetProjectQAList from "./Hooks/useGetProjectQAList";
import { TableComponents, TableVirtuoso, VirtuosoHandle } from "react-virtuoso";
import { QAList } from "../../../Models/qa";
import {
  StyledQAListScroller,
  StyledQATable,
  StyledQATableBody,
  StyledQATableFooter,
  StyledQATableHead,
  StyledQATableRow,
} from "../CommonComponents/StyledComponentCommonQA";
import TableContainer from "@mui/material/TableContainer";
import { useDoxleThemeStore } from "../../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import DoxleEmptyPlaceHolder from "../../../../DoxleDesignPattern/DoxleEmptyPlaceHolder/DoxleEmptyPlaceHolder";
import QAListRow from "./QAListRow";
import QAListHeader from "./QAListHeader";
import ListLoadingMore from "../../../../Utilities/Lottie/ListLoadingMore";
import useQAProjectList from "./Hooks/useQAProjectList";
import QAListFooter from "./QAListFooter";
import DoxleDialogHelper from "../../../../DoxleDesignPattern/DoxleDialogHelper/DoxleDialogHelper";
import QASharePdf from "../QASharePdf/QASharePdf";
import { Error404Banner } from "../../../../DoxleDesignPattern/DoxleIcons/CommonIcons";
import { EmptyQAListBanner } from "./QAListIcon";

type Props = {};

interface QAListTableContext {
  isErrorFetchingQAList: boolean;
  shouldShowRefetch: boolean;
  isRefetchingQAList: boolean;
}
const QAProjectList = (props: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }),
    shallow
  );
  const {
    qaList,
    isSuccessFetchingQAList,
    isErrorFetchingQAList,
    isFetchingQAList,

    handleRefetchQAList,
    isRefetchingQAList,
    fetchMoreQAList,
    isFetchingMoreQAList,
  } = useGetProjectQAList({});
  const {
    shouldShowRefetch,
    setCurrentVisibleRange,
    newQAListInputRef,
    handleDeleteQAList,
    handleCloseQAListDeleteDialog,
    deletedQAList,
    setdeletedQAList,
    isAddingQAList,
  } = useQAProjectList({});
  const tableRef = useRef<VirtuosoHandle>(null);
  const scrollToBottom = () => {
    setTimeout(() => {
      tableRef.current?.scrollToIndex({
        index: qaList.length - 1,
        behavior: "smooth",
      });
    }, 500);
  };
  //*render table
  const tableComponents: TableComponents<QAList, QAListTableContext> = useMemo(
    () => ({
      Table: (props) => <StyledQATable {...props} />,

      Scroller: React.forwardRef(({ context, ...props }, ref) => (
        <TableContainer
          ref={ref}
          {...props}
          component={StyledQAListScroller}
          sx={{ height: "100%", width: "100%" }}
        />
      )),
      TableHead: StyledQATableHead,
      TableBody: React.forwardRef((props, ref) => (
        <StyledQATableBody {...props} ref={ref} />
      )),
      TableRow: (props) => (
        <StyledQATableRow
          {...props}
          animate={{ y: [-2, 0] }}
          key={props.item.defectListId}
        />
      ),
      TableFoot: StyledQATableFooter,
      EmptyPlaceholder: (props) => (
        <DoxleEmptyPlaceHolder
          headTitleText={
            props.context?.isErrorFetchingQAList
              ? "Something Wrong"
              : "No QA List Item"
          }
          subTitleText={
            props.context?.isErrorFetchingQAList
              ? "Failed to get data, please try to reload the page"
              : "Add more items to manage your project quality!"
          }
          containerStyle={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
          containerBgColor="transparent"
          illustration={
            props.context?.isErrorFetchingQAList ? (
              <Error404Banner themeColor={doxleThemeColor} />
            ) : (
              <EmptyQAListBanner
                themeColor={doxleThemeColor}
                containerStyle={{ marginBottom: 12 }}
              />
            )
          }
        />
      ),
    }),
    [doxleThemeColor]
  );
  const itemContent = useCallback(
    (index: number, item: QAList, context: QAListTableContext) => (
      <QAListRow qaListItem={item} setdeletedQAList={setdeletedQAList} />
    ),
    []
  );
  const tableStyle: React.CSSProperties = {
    width: "100%",
    height: "100%",
    display: "flex",
  };
  return (
    <StyledQAProjectListContainer
      initial={{
        x: -10,
        opacity: 0,
      }}
      animate={{ x: 0, opacity: 1 }}
    >
      {isFetchingQAList && <QAProjectListSkeleton />}

      {!isFetchingQAList && (
        <TableVirtuoso
          ref={tableRef}
          context={{
            isErrorFetchingQAList,
            shouldShowRefetch,
            isRefetchingQAList,
          }}
          data={qaList}
          overscan={100}
          style={tableStyle}
          components={tableComponents}
          endReached={fetchMoreQAList}
          fixedHeaderContent={() => {
            return <QAListHeader />;
          }}
          fixedFooterContent={() =>
            qaList.length > 0 ? (
              <QAListFooter
                newQAListInputRef={newQAListInputRef}
                scrollToBottom={scrollToBottom}
              />
            ) : null
          }
          itemContent={itemContent}
          rangeChanged={setCurrentVisibleRange}
        />
      )}
      {(isFetchingMoreQAList || isAddingQAList) && (
        <ListLoadingMore
          containerStyle={{
            position: "absolute",
            bottom: 0,
            left: 0,
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 10,
          }}
          animationSize={120}
        />
      )}

      <DoxleDialogHelper
        open={Boolean(deletedQAList)}
        onClose={handleCloseQAListDeleteDialog}
        title="Confirm delete qa list!"
        description={`All data belong to qa list item ***${deletedQAList?.defectListTitle}*** will be deleted permanently, are you sure to proceed?`}
        dialogButtons={[
          {
            buttonText: "Cancel",
            buttonFunction: handleCloseQAListDeleteDialog,
            buttonColor: doxleThemeColor.primaryContainerColor,
            btnStyleSx: {
              border: `1px solid ${doxleThemeColor.primaryDividerColor}`,
            },
            buttonTextStyle: {
              color: doxleThemeColor.primaryFontColor,
            },
          },
          {
            buttonText: "Confirm",
            buttonFunction: handleDeleteQAList,
            btnStyleSx: {
              border: `1px solid ${doxleThemeColor.primaryDividerColor}`,
            },
          },
        ]}
      />

      <QASharePdf />
    </StyledQAProjectListContainer>
  );
};

export default QAProjectList;
