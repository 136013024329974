import { IconButton, IconButtonProps } from "@mui/material";
import { MotionProps, motion } from "framer-motion";
import React from "react";
import { styled } from "styled-components";
import {
  DoxleThemeColor,
  useDoxleThemeStore,
} from "../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import { SendIcon } from "./DoxleButtonIcons";

interface Props extends IconButtonProps {
  btnSize?: number;
}

const DoxleSendButton = React.forwardRef<HTMLButtonElement, Props>(
  ({ btnSize = 22, ...rest }: Props, ref) => {
    const { doxleThemeColor } = useDoxleThemeStore(
      (state) => ({
        doxleThemeColor: state.doxleThemeColor,
      }),
      shallow
    );
    const btnAnimatedVariants = {
      initial: {
        backgroundColor: doxleThemeColor.doxleColor,
      },
      disable: {
        backgroundColor: doxleThemeColor.primaryInputPlaceholderColor,
        transition: {
          duration: 0.4,
        },
      },
      hovering: {
        scale: 1.1,
        transition: {
          duration: 0.2,
        },
      },
    };
    return (
      <StyledIconButton
        ref={ref}
        {...rest}
        $doxleThemeColor={doxleThemeColor}
        $width={btnSize}
        $height={btnSize}
        variants={btnAnimatedVariants}
        initial="initial"
        animate={rest.disabled ? "disable" : "initial"}
        whileHover={!rest.disabled ? "hovering" : undefined}
      >
        <SendIcon themeColor={doxleThemeColor} />
      </StyledIconButton>
    );
  }
);

export default DoxleSendButton;

const AnimatedIconButton = motion(IconButton);
const StyledIconButton = styled(
  React.forwardRef<
    HTMLButtonElement,
    Omit<
      IconButtonProps & MotionProps,
      "onDragStart" | "onAnimationStart" | "onDragEnd" | "onDrag"
    > & {
      $doxleThemeColor: DoxleThemeColor;
      $width: number;
      $height: number;
    }
  >(
    (
      {
        $doxleThemeColor,
        $width,
        $height,
        ...props
      }: Omit<
        IconButtonProps & MotionProps,
        "onDragStart" | "onAnimationStart" | "onDragEnd" | "onDrag"
      > & {
        $doxleThemeColor: DoxleThemeColor;
        $width: number;
        $height: number;
      },
      innerRef
    ) => <AnimatedIconButton {...props} ref={innerRef} />
  )
)`
  && {
    width: ${(p) => p.$width}px;
    height: ${(p) => p.$height}px;
    padding: 0px;
    border-radius: 50% !important;
  }
`;
