import React, { useEffect, useMemo } from "react";
import { useDoxleAuthStore } from "../../DoxleGeneralStore/useDoxleAuthStore";
import { shallow } from "zustand/shallow";
import { useDoxleCurrentContextStore } from "../../DoxleGeneralStore/useDoxleCurrentContext";
import useDoxleNotificationStore from "../../DoxleGeneralStore/useDoxleNotificationStore";
import { useEditDocketSideScreenStore } from "../../CoreContent/EditDocketSideScreen/EditDocketSideScreenStore/useEditDocketSideScreenStore";
import QuotesQueryAPI, {
  IFilterQuoteRequestQuery,
} from "../QueryAPI/quotesQuery";
import { QuoteRequest } from "../models/quote";
import { useDoxleQuoteStore } from "../store/useDoxleQuoteStore";

type Props = {};

interface GetQuoteRequestList {
  quoteRequestList: QuoteRequest[];
  isFetchingQuoteRequest: boolean;
  isSuccessFetchingQuoteRequest: boolean;
  isErrorFetchingQuoteRequest: boolean;

  fetchNextPageRequest: () => void;
  isFetchingNextPageRequest: boolean;
}
const useGetQuoteRequestList = (props: Props): GetQuoteRequestList => {
  
  const company = useDoxleCurrentContextStore(
    (state) => state.currentCompany,
    shallow
  );
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );
  const { docket } = useEditDocketSideScreenStore(
    (state) => ({
      docket: state.edittedDocket,
      setOpen: state.setDisplayShareTab,
    }),
    shallow
  );
  const { filterGetQuoteRequest, setFilterGetQuoteRequest } =
    useDoxleQuoteStore(
      (state) => ({
        filterGetQuoteRequest: state.filterGetQuoteRequest,
        setFilterGetQuoteRequest: state.setFilterGetQuoteRequest,
      }),
      shallow
    );
  useEffect(() => {
    setFilterGetQuoteRequest({ docketId: docket?.docketPk });
  }, [docket]);

  const quoteRequestQuery = QuotesQueryAPI.useRetrieveQuoteRequestList({
  
    company,
    filter: filterGetQuoteRequest,
    showNotification,
  });

  const quoteRequestList = useMemo(
    () =>
      quoteRequestQuery.isSuccess
        ? quoteRequestQuery.data.pages.reduce((acc, data) => {
            return acc.concat(data.data.results);
          }, [] as QuoteRequest[])
        : [],
    [quoteRequestQuery.data]
  );

  const fetchNextPageRequest = () => {
    if (quoteRequestQuery.hasNextPage) quoteRequestQuery.fetchNextPage();
  };
  return {
    quoteRequestList,
    isFetchingQuoteRequest: quoteRequestQuery.isLoading,
    isSuccessFetchingQuoteRequest: quoteRequestQuery.isSuccess,
    isErrorFetchingQuoteRequest: quoteRequestQuery.isError,

    fetchNextPageRequest,
    isFetchingNextPageRequest: quoteRequestQuery.isFetchingNextPage,
  };
};

export default useGetQuoteRequestList;
