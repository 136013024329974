import { useQueryClient } from "@tanstack/react-query";

import { produce } from "immer";
import { Mail, MailQueryFilter } from "../Models/mail";
import { useDoxleCurrentContextStore } from "../../DoxleGeneralStore/useDoxleCurrentContext";
import { shallow } from "zustand/shallow";
import { formMailListQKey } from "./mailAPI";

type Props = {
  filter: MailQueryFilter;
  appendPos?: "start" | "end";
  overwrite?: boolean;
};

interface SetMailQueryDataQuery {
  handleAddMailQueryData: (addedMail: Mail) => void;
  handleDeleteMailQueryData: (deletedMailId: string) => void;
  handleEditMailQueryData: (edittedMail: Mail) => void;
}
const useSetMailQueryDataQuery = ({
  filter,
  appendPos = "start",
  overwrite = true,
}: Props): SetMailQueryDataQuery => {
  const queryClient = useQueryClient();
  const company = useDoxleCurrentContextStore(
    (state) => state.currentCompany,
    shallow
  );
  const qKey = formMailListQKey(company, filter);

  const handleAddMailQueryData = (addedMail: Mail) => {
    const oldServerData = queryClient.getQueryData(qKey);
    if (oldServerData && overwrite) {
      queryClient.setQueryData(qKey, (old: any) => {
        const lengthOfPages = old.pages.length;
        return produce(old, (draftOld: any) => {
          draftOld.pages = produce(draftOld.pages, (draftPages: any) => {
            if (appendPos === "start") {
              (draftPages[0].data.results as Mail[]).unshift(addedMail);
            } else {
              (draftPages[lengthOfPages - 1].data.results as Mail[]).push(
                addedMail
              );
            }

            return draftPages;
          });

          return draftOld;
        });
      });
    } else
      queryClient.refetchQueries({
        type: "all",
        predicate: (query) =>
          qKey.every((item) => query.queryKey.includes(item)),
      });
  };
  const handleDeleteMailQueryData = (deletednoteId: string) => {
    const oldServerData = queryClient.getQueryData(qKey);
    if (oldServerData && overwrite) {
      queryClient.setQueryData(qKey, (old: any) => {
        //find page contain deleted item
        let pageContainGroup: number = (old.pages as Array<any>).findIndex(
          (page) =>
            (page.data.results as Mail[]).find(
              (mail) => mail.mailId === deletednoteId
            )
        );
        if (pageContainGroup !== -1) {
          const targetOrderItemIndex = (
            (old.pages as Array<any>)[pageContainGroup].data.results as Mail[]
          ).findIndex((mail) => mail.mailId === deletednoteId);
          return produce(old, (draftOld: any) => {
            draftOld.pages = produce(draftOld.pages, (draftPages: any) => {
              draftPages[pageContainGroup].data.results = produce(
                draftPages[pageContainGroup].data.results,
                (draftTargetPageData: Mail[]) => {
                  draftTargetPageData.splice(targetOrderItemIndex, 1);

                  return draftTargetPageData;
                }
              );
              return draftPages;
            });

            return draftOld;
          });
        } else queryClient.invalidateQueries(qKey);
      });
    } else queryClient.invalidateQueries(qKey);
  };

  const handleEditMailQueryData = (edittedMail: Mail) => {
    const oldServerData = queryClient.getQueryData(qKey);

    if (oldServerData && overwrite) {
      queryClient.setQueryData(qKey, (old: any) => {
        if (old) {
          //find page contain deleted item
          let pageContainGroup: number = (old.pages as Array<any>).findIndex(
            (page) =>
              (page.data.results as Mail[]).find(
                (mail) => mail.mailId === edittedMail.mailId
              )
          );
          if (pageContainGroup !== -1) {
            return produce(old, (draftOld: any) => {
              draftOld.pages[pageContainGroup].data.results = produce(
                draftOld.pages[pageContainGroup].data.results,
                (draftTargetPageData: Mail[]) => {
                  const item = draftTargetPageData.find(
                    (mail) => mail.mailId === edittedMail.mailId
                  );
                  if (item) Object.assign(item, edittedMail);
                  return draftTargetPageData;
                }
              );

              return draftOld;
            });
          } else queryClient.invalidateQueries(qKey);
        } else queryClient.invalidateQueries(qKey);
      });
    } else queryClient.invalidateQueries(qKey);
  };
  return {
    handleAddMailQueryData,
    handleDeleteMailQueryData,
    handleEditMailQueryData,
  };
};

export default useSetMailQueryDataQuery;
