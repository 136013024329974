import React, { useEffect, useState } from "react";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import {
  StyledAddCompanyErrorToolTip,
  StyledAddCompanyLoginTextField,
} from "./StyledComponentAddCompanyScreen";
import { Zoom } from "@mui/material";
import {styled} from "styled-components";
import {initial} from "lodash";

type Props = {
  setCurrentValue: Array<(value: string) => void>;
  placeholderText?: string[];
  hasBorderBottom?: boolean;
  heightRatio: `${number}%`;
  errorToggle: boolean;
  errorText: string;
  handleCloseToolTip: () => void;
  passwordMode?: boolean;
  keyDownFunction?: Function;
  maxLengths?: number[];
  initialValues?: (string|undefined)[];
};

const StyledSplitTextFieldContainer = styled.div<{
  $heightRatio: `${number}%`;
}>`
  flex-direction: row;
  display: flex;
  height: ${(p) => p.$heightRatio};
`

const AddCompanySplitTextField = ({
  setCurrentValue,
  placeholderText,
  hasBorderBottom,
  heightRatio,
  errorToggle,
  errorText,
  handleCloseToolTip,
  passwordMode,
  keyDownFunction,
  maxLengths,
  initialValues
}: Props) => {
  // const [textInput0, setTextInput0] = useState<string>(initialValues?.[0] ?? "");
  // const [textInput1, setTextInput1] = useState<string>(initialValues?.[1] ?? "");
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }),
    shallow
  );

  // useEffect(() => {
  //   const timeout = setTimeout(() => {
  //     setCurrentValue?.[0](textInput0);
  //   }, 400);

  //   return () => clearTimeout(timeout);
  // }, [textInput0]);
  // useEffect(() => {
  //   const timeout = setTimeout(() => {
  //     setCurrentValue?.[1](textInput1);
  //   }, 400);

  //   return () => clearTimeout(timeout);
  // }, [textInput1]);
  return (
    <StyledSplitTextFieldContainer $heightRatio={heightRatio}>
      <StyledAddCompanyErrorToolTip
        $themeColor={doxleThemeColor}
        $doxleFont={doxleFont}
        TransitionComponent={Zoom}
        TransitionProps={{ timeout: 600 }}
        PopperProps={{
          disablePortal: true,
          sx: {
            "& .MuiTooltip-tooltip": {
              fontFamily: doxleFont.primaryTitleFont,
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: " 14px",
              lineHeight: " 16px",
              color: `${doxleThemeColor.errorColor} !important`,
              textTransform: " none",
              backgroundColor: `${doxleThemeColor.primaryContainerColor} !important`,
              boxShadow: `0px 0px 4px 2px ${doxleThemeColor.primaryBoxShadowColor} !important`,
              borderRadius: "4px",

              padding: "4px 14px",
            },
          },
        }}
        onClose={handleCloseToolTip}
        open={errorToggle}
        title={errorText}
        placement="right"
      >
        <StyledAddCompanyLoginTextField
          $heightRatio={"100%"}
          $themeColor={doxleThemeColor}
          $doxleFont={doxleFont}
          placeholder={placeholderText?.[0]}
          $hasBorderBottom={hasBorderBottom || false}
          required
          variant="outlined"
          value={initialValues?.[0]}
          onChange={(
            event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
          ) => {
            const newValue = event.target.value;
            if (!maxLengths?.[0] || newValue.length <= maxLengths[0]) setCurrentValue[0](newValue);
          }}
          type={passwordMode ? "password" : undefined}
          onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => {
            if (event.nativeEvent.key === "Enter") {
              if (keyDownFunction) keyDownFunction();
            }
            if (event.nativeEvent.key === "Escape") event.currentTarget.blur();
          }}
        />
      </StyledAddCompanyErrorToolTip>
      <StyledAddCompanyErrorToolTip
        $themeColor={doxleThemeColor}
        $doxleFont={doxleFont}
        TransitionComponent={Zoom}
        TransitionProps={{ timeout: 600 }}
        PopperProps={{
          disablePortal: true,
          sx: {
            "& .MuiTooltip-tooltip": {
              fontFamily: doxleFont.primaryTitleFont,
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: " 14px",
              lineHeight: " 16px",
              color: `${doxleThemeColor.errorColor} !important`,
              textTransform: " none",
              backgroundColor: `${doxleThemeColor.primaryContainerColor} !important`,
              boxShadow: `0px 0px 4px 2px ${doxleThemeColor.primaryBoxShadowColor} !important`,
              borderRadius: "4px",

              padding: "4px 14px",
            },
          },
        }}
        onClose={handleCloseToolTip}
        open={errorToggle}
        title={errorText}
        placement="right"
      >
        <StyledAddCompanyLoginTextField
          $heightRatio={"100%"}
          $themeColor={doxleThemeColor}
          $doxleFont={doxleFont}
          placeholder={placeholderText?.[1]}
          $hasBorderBottom={hasBorderBottom || false}
          required
          variant="outlined"
          value={initialValues?.[1]}
          onChange={(
            event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
          ) => {
            const newValue = event.target.value;
            if (!maxLengths?.[1] || newValue.length <= maxLengths[1]) setCurrentValue[1](newValue);
          }}
          type={passwordMode ? "password" : undefined}
          onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => {
            if (event.nativeEvent.key === "Enter") {
              if (keyDownFunction) keyDownFunction();
            }

            if (event.nativeEvent.key === "Escape") event.currentTarget.blur();
          }}
        />
      </StyledAddCompanyErrorToolTip>
    </StyledSplitTextFieldContainer>
  );
};

export default AddCompanySplitTextField;
