import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { usePricebookStore } from "../../../Store/usePricebookStore";
import { useShallow } from "zustand/react/shallow";
import {
  PricebookItem,
  PricebookSupplierRate,
} from "../../../Models/Pricebook";
import { ContactCompany } from "../../../../Models/addressBook";
import { produce } from "immer";
import { useDoxleCurrentContextStore } from "../../../../DoxleGeneralStore/useDoxleCurrentContext";
import PricebookQueryAPI, {
  PatchPricebookBody,
} from "../../../QueryHooks/PricebookQueryAPI";
import useGetCoreDockets from "../../../../CoreContent/QueryDataHooks/GetQueryDataHooks/useGetCoreDockets";
import { BasicCoreDocket } from "../../../../Models/dockets";
import { DropEvent, FileRejection } from "react-dropzone";
import useDoxleNotificationStore from "../../../../DoxleGeneralStore/useDoxleNotificationStore";
import useSetPricebookQueryData from "../../../QueryHooks/useSetPricebookQueryData";

type Props = {
  pricebookId: string;
};

interface IToggleSupplierListParams {
  anchorListEl: HTMLDivElement;
  rateIndex: number;
  handlePostAdd?: () => void;
}
export interface IEditPricebookItemContextValue {
  updatedPricebookData: Required<PatchPricebookBody>;
  setUpdatedPricebookData: React.Dispatch<
    React.SetStateAction<Required<PatchPricebookBody>>
  >;
  pricebookId: string;
  setShowSupplierList: React.Dispatch<
    React.SetStateAction<IToggleSupplierListParams | undefined>
  >;
  shouldShowWarning: boolean;
}
interface IEditPricebookItem {
  showSupplierList: IToggleSupplierListParams | undefined;
  setShowSupplierList: React.Dispatch<
    React.SetStateAction<IToggleSupplierListParams | undefined>
  >;

  handleSelectSupplier: (contact: ContactCompany) => void;
  handleUpdateDescription: (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  updatedPricebookData: Required<PatchPricebookBody>;
  setUpdatedPricebookData: React.Dispatch<
    React.SetStateAction<Required<PatchPricebookBody>>
  >;
  shouldShowWarning: boolean;
  editPricebookItemContextValue: IEditPricebookItemContextValue;
  selectedCoreDockets: BasicCoreDocket[];
  coreDocketList: BasicCoreDocket[];
  handleSelectCoreDockets: (coreDocketId: string) => void;
  onDrop: <T extends File>(
    acceptedFiles: T[],
    fileRejections: FileRejection[],
    event: DropEvent
  ) => void;
  handleAddNewSupplierRate: () => void;
  handlePatchPricebook: () => void;
  isUpdatingPricebook: boolean;
  handleRemoveAddedSwatch: (index: number) => void;
  handleRemoveAddedCoreDocket: (index: number) => void;
  handleShowSupplierList: (e: React.FocusEvent<HTMLDivElement>, index: number) => void;
}

const useEditPricebookItem = ({ pricebookId }: Props): IEditPricebookItem => {
  const [showSupplierList, setShowSupplierList] = useState<
    IToggleSupplierListParams | undefined
  >(undefined);

  const [updatedPricebookData, setUpdatedPricebookData] = useState<
    Required<PatchPricebookBody>
  >({
    pricebookId,
    coreDockets: [],
    description: "",
    supplierRates: [],
    swatches: [],
  });

  const [shouldShowWarning, setShouldShowWarning] = useState(false);
  const company = useDoxleCurrentContextStore(
    useShallow((state) => state.currentCompany)
  );
  const {
    editedPricebookItemId,
    setEditedPricebookItemId,
    pricebookItemQueryFilter,
    pricebookRateQueryFilter,
  } = usePricebookStore(
    useShallow((state) => ({
      pricebookItemQueryFilter: state.pricebookItemQueryFilter,
      pricebookRateQueryFilter: state.pricebookRateQueryFilter,
      editedPricebookItemId: state.editedPricebookItemId,
      setEditedPricebookItemId: state.setEditedPricebookItemId,
    }))
  );

  const handleSelectSupplier = useCallback(
    (contact: ContactCompany) => {
      if (showSupplierList) {
        setUpdatedPricebookData(
          produce((draft) => {
            const editedItem = draft.supplierRates[showSupplierList.rateIndex];
            if (editedItem) {
              editedItem.supplier = contact.contactCompanyId ?? null;
              editedItem.supplierName = contact.name;
            }

            return draft;
          })
        );

        if (showSupplierList.handlePostAdd) showSupplierList.handlePostAdd();
      }
    },
    [showSupplierList]
  );

  const handleShowSupplierList = (e: React.FocusEvent<HTMLDivElement>, index: number) => {
    setShowSupplierList({
      anchorListEl: e.currentTarget,
      rateIndex: index,
    });
  }

  const handleAddNewSupplierRate = () => {
    const newSupplierRate: PricebookSupplierRate = {
      rateId: "",
      supplier: null,
      supplierName: "",
      pricebookItemDescription: updatedPricebookData.description,
      uom: "",
      unitCost: "",
      pricebookItem: pricebookId,
      company: company?.companyId,
      timeStamp: new Date().toISOString(),
      isNew: true,
    };
    setUpdatedPricebookData(
      produce((draft) => {
        draft.supplierRates.push(newSupplierRate);
        return draft;
      })
    );
  };
  const handleSelectCoreDockets = (coreDocketId: string) => {
    setUpdatedPricebookData(
      produce((draft) => {
        const index = draft.coreDockets.findIndex((id) => id === coreDocketId);
        if (index === -1) draft.coreDockets.push(coreDocketId);

        return draft;
      })
    );
  };
  const handleUpdateDescription = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setUpdatedPricebookData(
      produce((draft) => {
        draft.description = event.target.value;
        return draft;
      })
    );
  };
  const onDrop = useCallback(
    <T extends File>(
      acceptedFiles: T[],
      fileRejections: FileRejection[],
      event: DropEvent
    ) => {
      setUpdatedPricebookData(
        produce((draft) => {
          draft.swatches.push(...acceptedFiles);
        })
      );
    },
    []
  );
  const { coreDocketList } = useGetCoreDockets();

  const selectedCoreDockets: BasicCoreDocket[] = useMemo(
    () =>
      coreDocketList.filter((item) =>
        updatedPricebookData.coreDockets.some(
          (selectedId) => selectedId === item.coreDocketId
        )
      ),
    [coreDocketList, updatedPricebookData.coreDockets]
  );

  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification
  );

  const { handleUpdatePricebookItem } = useSetPricebookQueryData({
    itemId: pricebookId,
  });
  const priceBookItemMutation = PricebookQueryAPI.usePricebookItemMutation({
    company: company,
    filter: pricebookItemQueryFilter,
    showNotification,
    onSuccessUpdateCb: (updated: PricebookItem) => {
      handleUpdatePricebookItem(updated);
      setEditedPricebookItemId(null);
    },
  });
  const handlePatchPricebook = () => {
    let valid = true;
    console.log("updatedPricebookData", updatedPricebookData);
    if (
      !updatedPricebookData.description ||
      updatedPricebookData.supplierRates.find(
        (item) =>
          !item.unitCost || !item.uom || (!item.supplier && !item.supplierName)
      )
    )
      valid = false;
    if (!valid) {
      setShouldShowWarning(true);
    } else {
      priceBookItemMutation.updatePricebookItem.mutate(updatedPricebookData);
    }
  };

  const handleRemoveAddedSwatch = useCallback((index: number) => {
    setUpdatedPricebookData(
      produce((draft) => {
        draft.swatches.splice(index, 1);
        return draft;
      })
    );
  }, []);
  const handleRemoveAddedCoreDocket = useCallback((index: number) => {
    setUpdatedPricebookData(
      produce((draft) => {
        draft.coreDockets.splice(index, 1);
        return draft;
      })
    );
  }, []);
  useEffect(() => {
    if (shouldShowWarning) {
      const timeout = setTimeout(() => {
        setShouldShowWarning(false);
      }, 10000);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [shouldShowWarning]);
  const editPricebookItemContextValue: IEditPricebookItemContextValue = useMemo(
    () => ({
      updatedPricebookData,
      setUpdatedPricebookData,
      pricebookId,
      setShowSupplierList,
      shouldShowWarning,
    }),
    [updatedPricebookData, pricebookId, shouldShowWarning]
  );
  return {
    showSupplierList,
    setShowSupplierList,

    handleSelectSupplier,
    updatedPricebookData,
    setUpdatedPricebookData,
    handleUpdateDescription,
    editPricebookItemContextValue,
    shouldShowWarning,
    selectedCoreDockets,
    coreDocketList,
    handleSelectCoreDockets,
    onDrop,
    handleAddNewSupplierRate,
    handlePatchPricebook,
    isUpdatingPricebook: priceBookItemMutation.updatePricebookItem.isLoading,
    handleRemoveAddedSwatch,
    handleRemoveAddedCoreDocket,
    handleShowSupplierList
  };
};

export default useEditPricebookItem;
