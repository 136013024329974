import React, {useEffect, useState} from "react";
import { useDoxleCurrentContextStore } from "../../../DoxleGeneralStore/useDoxleCurrentContext";
import {Floor, Room} from "../../../QA/Models/locations";
import {useShallow} from "zustand/react/shallow";
import LocationQueryAPI from "../../../Services/QueryHooks/LocationQueryAPI";

type Props = {
  handleCloseForm: () => void;
  floorFilter?: string;
};

interface IAddRoomForm {
  shouldShowError: boolean;
  handleAddFloor: () => void;
  newFloor: Floor;
  setNewFloor: React.Dispatch<React.SetStateAction<Floor>>;
  isAddingLocation: boolean;

  newRoom: Room;
  setNewRoom: React.Dispatch<React.SetStateAction<Room>>;
  handleAddRoom: (event: React.FormEvent<HTMLElement>) => void;
}
const useAddLocationForm = ({ handleCloseForm, floorFilter }: Props): IAddRoomForm => {
  const projectId = useDoxleCurrentContextStore(useShallow(state => state.currentProject?.projectId)) ?? "";
  const [shouldShowError, setShouldShowError] = useState(false);
  const [newFloor, setNewFloor] = useState<Floor>({
    floorId: "",
    name: "",
    project: projectId
  });
  const [newRoom, setNewRoom] = useState<Room>({
    roomId: "",
    name: "",
    floorName: "",
    floor: floorFilter ?? "",
    project: projectId
  });

  const postFloors = LocationQueryAPI.useAddFloorQuery({
    project: projectId,
    onSuccessCb: () => handleCloseForm()
  });

  const handleAddFloor = () => {
    postFloors.mutate(newFloor);
  };

  const postRoom = LocationQueryAPI.useAddRoomQuery({
    project: projectId,
    onSuccessCb: () => handleCloseForm()
  });

  const handleAddRoom = (event: React.FormEvent<HTMLElement>) => {
    postRoom.mutate(newRoom)
  };

  useEffect(()=>{
    setNewRoom(prev => ({...prev, floor: floorFilter ?? ""}))
  },[floorFilter])

  return {
    shouldShowError,
    handleAddFloor,
    newFloor,
    setNewFloor,
    isAddingLocation: postFloors.isLoading || postFloors.isLoading,
    newRoom,
    setNewRoom,
    handleAddRoom,
  };
};

export default useAddLocationForm;
