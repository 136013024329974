import React, { useState } from "react";
import { useDoxleCurrentContextStore } from "../../../../DoxleGeneralStore/useDoxleCurrentContext";
import { shallow } from "zustand/shallow";
import useDoxleNotificationStore from "../../../../DoxleGeneralStore/useDoxleNotificationStore";
import { useEditDocketSideScreenStore } from "../../../../CoreContent/EditDocketSideScreen/EditDocketSideScreenStore/useEditDocketSideScreenStore";
import { useProjectStore } from "../../../../DoxleGeneralStore/useprojectStore";
import { useNoteStore } from "../../../Store/useNoteStore";
import { NoteQueryAPI } from "../../../QueryAPI/notesQueryAPI";

type Props = {};

interface INoteListFooter {
  newNoteTitle: string;
  setNewNoteTitle: React.Dispatch<React.SetStateAction<string>>;
  handleAddNote: () => void;
  handleKeydownNoteTitleTextfield: (
    event: React.KeyboardEvent<HTMLDivElement>
  ) => void;
  isAddingNote: boolean;
}
const useNoteListFooter = (props: Props): INoteListFooter => {
  const [newNoteTitle, setNewNoteTitle] = useState("");
  const { company, currentProject } = useDoxleCurrentContextStore(
    (state) => ({
      company: state.currentCompany,
      currentProject: state.currentProject,
    }),
    shallow
  );
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification
  );
  const { edittedDocket } = useEditDocketSideScreenStore(
    (state) => ({
      edittedDocket: state.edittedDocket,
    }),
    shallow
  );
  const { filterNoteQuery, noteScope } = useNoteStore(
    (state) => ({
      filterNoteQuery: state.filterNoteQuery,

      noteScope: state.noteScope,
    }),
    shallow
  );

  const { add } = NoteQueryAPI.useMutateNoteQuery({
    company,
    filter: filterNoteQuery,
    showNotification,
    onSuccessAddCb: () => setNewNoteTitle(""),
  });

  const handleAddNote = () => {
    if (newNoteTitle)
      add.mutate({
        title: newNoteTitle,
        docket: noteScope === "Docket" ? edittedDocket?.docketPk : undefined,
        project:
          noteScope === "Project" ? currentProject?.projectId : undefined,
      });
  };
  const handleKeydownNoteTitleTextfield = (
    event: React.KeyboardEvent<HTMLDivElement>
  ) => {
    if (event.nativeEvent.key === "Enter") {
      event.preventDefault();
      handleAddNote();
      event.currentTarget.blur();
    }

    if (event.nativeEvent.key === "Escape") {
      event.preventDefault();
      setNewNoteTitle("");
      event.currentTarget.blur();
    }
  };
  return {
    newNoteTitle,
    setNewNoteTitle,
    handleAddNote,
    handleKeydownNoteTitleTextfield,
    isAddingNote: add.isLoading,
  };
};

export default useNoteListFooter;
