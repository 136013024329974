import { useIsMutating } from "@tanstack/react-query";
import React, {useMemo, useState} from "react";
import { getQaFileMutationKey } from "../../../../QueryAPI/qaQueryAPI";
import { QAFile } from "../../../../Models/qa";

type Props = { fileItem: QAFile };

interface IQAFileItem {
  isLoadingImg: boolean;
  isImgError: boolean;
  onLoadImgStart: () => void;
  onLoadImgEnd: () => void;
  onLoadImgError: () => void;
  isDeletingFile: boolean;
  thumbUrl: string
}
const useQAFileItem = ({ fileItem }: Props): IQAFileItem => {
  const [isLoadingImg, setIsLoadingImg] = useState(false);
  const [isImgError, setIsImgError] = useState(false);

  const thumbUrl = useMemo(() => {
    if (fileItem.thumbUrl) return fileItem.thumbUrl;
    return fileItem.fileType.toLowerCase() === "application/pdf"
      ? "https://s3.ap-southeast-2.amazonaws.com/doxle.com/assets/icons/pdf.png"
        : fileItem.fileType.includes('word') || fileItem.fileType.includes('word')
          ? "https://s3.ap-southeast-2.amazonaws.com/doxle.com/assets/icons/word.png"
          : fileItem.fileType.includes('exce') || fileItem.fileType.includes('spreadsheet')
            ? "https://s3.ap-southeast-2.amazonaws.com/doxle.com/assets/icons/excel.png"
            : fileItem.fileType.includes('csv')
              ? "https://s3.ap-southeast-2.amazonaws.com/doxle.com/assets/icons/csv.png"
              : "https://s3.ap-southeast-2.amazonaws.com/doxle.com/assets/icons/file.png"
  } ,[fileItem.thumbUrl, fileItem.fileType])

  const onLoadImgStart = () => {
    setIsLoadingImg(true);
  };
  const onLoadImgEnd = () => {
    setIsLoadingImg(false);
  };
  const onLoadImgError = () => {
    setIsLoadingImg(false);
    setIsImgError(true);
  };

  const isDeletingFile =
    useIsMutating({
      mutationKey: getQaFileMutationKey("delete"),
      predicate: (query) =>
        (query.state.variables as QAFile).fileId === fileItem.fileId,
    }) > 0;
  return {
    isLoadingImg,
    isImgError,
    onLoadImgStart,
    onLoadImgEnd,
    onLoadImgError,
    isDeletingFile,
    thumbUrl
  };
};

export default useQAFileItem;
