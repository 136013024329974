import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import styled from "styled-components";

export const StyledLinearProgress = styled(LinearProgress)`
  && {
    
  }

  & .MuiLinearProgress-bar {
    border-radius: 14px;
    background-color: #7070ef !important;
  }

  &.MuiLinearProgress-root {
    background: transparent;
    height: 14px;
    border-radius: 14px;
  }
`;
