import { shallow } from "zustand/shallow";
import { useDoxleThemeStore } from "../DoxleGeneralStore/useDoxleThemeStore";
import {
  InventoryComment,
  InventoryImage,
  InventoryItem,
} from "./Models/Inventory";
import {
  StyledCalculateSymbol,
  StyledCommentDetails,
  StyledCommentText,
  StyledDeletingPrompt,
  StyledEditIconContainer,
  StyledInventoryBtmContainer,
  StyledInventoryCommentContainer,
  StyledInventoryDetailsContainer,
  StyledInventoryImage,
  StyledInventoryItemContainer,
  StyledInventoryStats,
  StyledInventoryStatsContainer,
  StyledInventorySubtitle,
  StyledInventoryTitle,
} from "./StyledSingleInventory";
import { useCallback, useMemo, useState } from "react";
import InventoryImageQueryAPI from "./QueryAPIHooks/InventoryImageAPI";
import { AddMinusIcon } from "./InventoryPageIcons";
import InventoryCommentsQueryAPI from "./QueryAPIHooks/InventoryCommentAPI";
import { Components, Virtuoso } from "react-virtuoso";
import React from "react";
import { StyledInventoryListScroller } from "./StyledInventoryScreen";
import moment from "moment";
import { useDoxleCurrentContextStore } from "../DoxleGeneralStore/useDoxleCurrentContext";
import useDoxleNotificationStore from "../DoxleGeneralStore/useDoxleNotificationStore";
import { useInventoryStore } from "./Store/useInventoryStore";
import { Box, CircularProgress } from "@mui/material";
import BrokenImageOutlinedIcon from '@mui/icons-material/BrokenImageOutlined';

interface Props {
  inventoryItem: InventoryItem;
  openEditScreen: React.Dispatch<React.SetStateAction<HTMLDivElement | null>>;
}

const SingleInventory = ({ inventoryItem, openEditScreen }: Props) => {
  const [imgError, setImgError] = React.useState(false);

  const { $themeColor, $themeFont, $currentTheme } = useDoxleThemeStore(
    (state) => ({
      $themeColor: state.doxleThemeColor,
      $themeFont: state.doxleFont,
      $currentTheme: state.currentTheme,
    }),
    shallow
  );

  const { setCurrentEditInventory, deletingItem, isDeletingItem, setPreviewImageInventory } =
    useInventoryStore(
      (state) => ({
        setCurrentEditInventory: state.setCurrentEditInventory,
        deletingItem: state.inventoryForDelete,
        isDeletingItem: state.isDeletingItem,
        setPreviewImageInventory: state.setPreviewImageInventory,
      }),
      shallow
    );

    const handlePreviewImage = () => {
      setPreviewImageInventory(inventoryItem);
    }


  // const inventoryImageQuery = InventoryImageQueryAPI.useGetInventoryImageById(
  //   inventoryItem.inventoryId
  // );

  // const inventoryImg: InventoryImage[] = useMemo(
  //   () =>
  //     inventoryImageQuery.data?.pages.flatMap(
  //       (page) => (page?.data?.results as InventoryImage[]) ?? []
  //     ) ?? [],
  //   [inventoryImageQuery.data]
  // );

  // const inventoryCommentsQuery =
  //   InventoryCommentsQueryAPI.useGetInventoryCommentsById(
  //     inventoryItem.inventoryId
  //   );

  // const inventoryComments: InventoryComment[] = useMemo(
  //   () =>
  //     inventoryCommentsQuery.data?.pages.flatMap(
  //       (page) => (page?.data?.results as InventoryComment[]) ?? []
  //     ) ?? [],
  //   [inventoryCommentsQuery.data]
  // );

  interface InventoryListContext {
    isFetchingNextPage: boolean;
  }

  const components: Components<InventoryComment, InventoryListContext> =
    useMemo(
      () => ({
        Scroller: React.forwardRef(({ ...props }, ref) => (
          <StyledInventoryListScroller
            style={{
              ...props.style,
            }}
            ref={ref}
            {...props}
          />
        )),
      }),
      []
    );

  const handleOpenEditModal = (event: React.MouseEvent<HTMLDivElement>) => {
    if (!isDeletingItem) {
      setCurrentEditInventory(inventoryItem);
      openEditScreen(event.currentTarget);
    }
  };

  const formatDate = (date: string) => {
    let dateFormated = moment(date).format("MMM Do YYYY");
    const index = dateFormated.indexOf(" ", dateFormated.indexOf(" ") + 1);
    dateFormated =
      dateFormated.slice(0, index) + "," + dateFormated.slice(index);

    return dateFormated;
  };

  const isDeleting = Boolean(
    isDeletingItem && inventoryItem.inventoryId === deletingItem?.inventoryId
  );

  const CommentItemContent = (index: number, item: InventoryComment) => (
    <>
      <StyledCommentText $themeColor={$themeColor}>
        {item.commentText}
      </StyledCommentText>
      <StyledCommentDetails $themeColor={$themeColor}>
        {item.userName} wrote on {formatDate(item.timeStamp)}
      </StyledCommentDetails>
    </>
  );

  return (
    <StyledInventoryItemContainer
      $themeColor={$themeColor}
      $themeFont={$themeFont}
      isDeleting={isDeleting}
      $currentTheme={$currentTheme}
    >
      <StyledEditIconContainer onClick={handleOpenEditModal}>
        {isDeleting ? (
          <div style={{ display: "flex", alignItems: "center" }}>
            <CircularProgress color="error" size={20} />
            <StyledDeletingPrompt>Deleting ...</StyledDeletingPrompt>
          </div>
        ) : (
          <AddMinusIcon themeColor={$themeColor} />
        )}
      </StyledEditIconContainer>

      <StyledInventoryTitle $themeColor={$themeColor} $themeFont={$themeFont}>
        {inventoryItem.title}
      </StyledInventoryTitle>
      <StyledInventorySubtitle
        $themeColor={$themeColor}
        $themeFont={$themeFont}
      >
        {inventoryItem.subtitle ? inventoryItem.subtitle : <br />}
      </StyledInventorySubtitle>

      <StyledInventoryBtmContainer>
        <StyledInventoryImage>
          {!imgError ? (
            inventoryItem.defaultImage ? (
              inventoryItem.defaultImage === "loading" ? (
                <Box sx={{ display: "flex" }}>
                  <CircularProgress />
                </Box>
              ) : inventoryItem.defaultImage === "error" ? (
                <div style={{ color: "red" }}>
                  Upload Fail, please try again later
                </div>
              ) : (
                <img
                  src={inventoryItem.defaultImage}
                  onError={({ currentTarget }) => {
                    setImgError(true);
                  }}
                  onClick={handlePreviewImage}
                />
              )
            ) : (
              "No image"
            )
          ) : (
            <BrokenImageOutlinedIcon color="error" sx={{fontSize:40}}/>
          )}
        </StyledInventoryImage>
        <StyledInventoryDetailsContainer
          $themeColor={$themeColor}
          $currentTheme={$currentTheme}
        >
          <StyledInventoryStatsContainer
            $themeColor={$themeColor}
            $currentTheme={$currentTheme}
          >
            <StyledInventoryStats $themeColor={$themeColor}>
              {Number(inventoryItem.quantity).toFixed(2)}
            </StyledInventoryStats>
            <StyledCalculateSymbol $themeColor={$themeColor}>
              {" "}
              X{" "}
            </StyledCalculateSymbol>
            <StyledInventoryStats $themeColor={$themeColor}>
              ${Number(inventoryItem.cost).toFixed(2)}
            </StyledInventoryStats>
            <StyledCalculateSymbol $themeColor={$themeColor}>
              {" "}
              ={" "}
            </StyledCalculateSymbol>
            <StyledInventoryStats $themeColor={$themeColor} isTotalPrice={true}>
              $
              {(
                Number(inventoryItem.cost) * Number(inventoryItem.quantity)
              ).toFixed(2)}
            </StyledInventoryStats>
          </StyledInventoryStatsContainer>

          <StyledInventoryCommentContainer
            $themeColor={$themeColor}
            $currentTheme={$currentTheme}
          >
            {inventoryItem.latestComment ? (
              <>
                <StyledCommentText $themeColor={$themeColor}>
                  {inventoryItem.latestComment.commentText}
                </StyledCommentText>
                <StyledCommentDetails $themeColor={$themeColor}>
                  {inventoryItem.latestComment.userName} wrote on{" "}
                  {formatDate(inventoryItem.latestComment.timeStamp)}
                </StyledCommentDetails>
              </>
            ) : (
              <>
                <StyledCommentText $themeColor={$themeColor}>
                  No Comments Available
                </StyledCommentText>
                <StyledCommentDetails $themeColor={$themeColor}>
                  Please leave a comment here
                </StyledCommentDetails>
              </>
            )}
          </StyledInventoryCommentContainer>
        </StyledInventoryDetailsContainer>
      </StyledInventoryBtmContainer>
    </StyledInventoryItemContainer>
  );
};
export default SingleInventory;
