import React, { useRef, useState } from "react";
import { useProjectQAStore } from "../../../Store/useProjectQAStore";
import { shallow } from "zustand/shallow";
import useDoxleNotificationStore from "../../../../../DoxleGeneralStore/useDoxleNotificationStore";
import { useDoxleAuthStore } from "../../../../../DoxleGeneralStore/useDoxleAuthStore";
import { useDoxleCurrentContextStore } from "../../../../../DoxleGeneralStore/useDoxleCurrentContext";
import QAQueryAPI from "../../../../QueryAPI/qaQueryAPI";

type Props = {};

interface EditQADescription {
  newQADesciptionText: string;
  setNewQADesciptionText: React.Dispatch<React.SetStateAction<string>>;
  handleKeydownInput: (e: React.KeyboardEvent<HTMLDivElement>) => void;
  inputRef: React.RefObject<HTMLInputElement>;
  handleUpdateQADescription: () => void;
  isUpdatingDescription: boolean;
}
const useEditQADescription = (props: Props): EditQADescription => {
  const { selectedQAItem } = useProjectQAStore(
    (state) => ({
      selectedQAItem: state.selectedQAItem,
    }),
    shallow
  );
  const [newQADesciptionText, setNewQADesciptionText] = useState(
    selectedQAItem?.description ?? ""
  );

  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );
  const { company, currentSearchText } = useDoxleCurrentContextStore(
    (state) => ({
      company: state.currentCompany,
      currentSearchText: state.currentSearchText,
    }),
    shallow
  );

  const onErrorUpdate = () => {
    setNewQADesciptionText(selectedQAItem?.description ?? "");
  };
  const updateQAQuery = QAQueryAPI.useUpdateQAQuery({
    company,
    qaItem: selectedQAItem!,
    showNotification,
    onErrorCb: onErrorUpdate,
  });

  const inputRef = useRef<HTMLInputElement>(null);
  const handleKeydownInput = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.nativeEvent.key === "Enter") {
      e.preventDefault();
      inputRef.current?.blur();
    } else if (e.nativeEvent.key === "Escape") {
      setNewQADesciptionText("");
    }
  };

  const handleUpdateQADescription = () => {
    if (newQADesciptionText) {
      if (newQADesciptionText !== selectedQAItem?.description)
        updateQAQuery.mutate({ description: newQADesciptionText });
    } else updateQAQuery.mutate({ description: "Untitled QA" });
  };
  return {
    newQADesciptionText,
    setNewQADesciptionText,
    handleKeydownInput,
    inputRef,
    handleUpdateQADescription,
    isUpdatingDescription: updateQAQuery.isLoading,
  };
};

export default useEditQADescription;
