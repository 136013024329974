import { useDoxleCurrentContextStore } from "../../DoxleGeneralStore/useDoxleCurrentContext";
import { useQueryClient } from "@tanstack/react-query";
import { formScopeOfWorkImageQueryKey } from "../QueryAPI/scopeOfWorkQueryAPI";
import { ScopeOfWorkImage } from "../Model/scopeOfWorks";
import { produce } from "immer";
import {
  IApiPaginatedData,
  InfiniteAxiosQueryData,
} from "../../Models/axiosReturn";

type Props = {
  scopeItemId: string;
  appendPos?: "start" | "end";
  overwrite?: boolean;
};

interface SetSOWImgQueryData {
  handleAddMultiSOWImg: (newItem: ScopeOfWorkImage[]) => void;
  handleEditSOWImg: (newItem: ScopeOfWorkImage) => void;
  handleDeleteMultipleSOWImgs: (deletedIds: string[]) => void;
}
const useSetSOWImgQueryData = ({
  scopeItemId,
  appendPos = "end",
  overwrite = true,
}: Props): SetSOWImgQueryData => {
  const { company } = useDoxleCurrentContextStore((state) => ({
    company: state.currentCompany,
  }));
  const queryClient = useQueryClient();
  const qKey = formScopeOfWorkImageQueryKey(scopeItemId, company);

  const handleAddMultiSOWImg = (newItem: ScopeOfWorkImage[]) => {
    const queryData = queryClient.getQueryData(qKey);
    if (queryData && overwrite) {
      queryClient.setQueryData(qKey, (old: any) => {
        if (old) {
          const lengthOfPages = old.pages.length;
          return produce(old, (draftOld: any) => {
            draftOld.pages = produce(draftOld.pages, (draftPages: any) => {
              if (appendPos === "start") {
                (
                  draftPages[0].data as IApiPaginatedData<ScopeOfWorkImage>
                ).results.unshift(...newItem);
              } else {
                (
                  draftPages[lengthOfPages - 1]
                    .data as IApiPaginatedData<ScopeOfWorkImage>
                ).results.push(...newItem);
              }

              return draftPages;
            });

            return draftOld;
          });
        } else queryClient.invalidateQueries(qKey);
      });
    } else queryClient.invalidateQueries(qKey);
  };

  const handleEditSOWImg = (newItem: ScopeOfWorkImage) => {
    const queryData = queryClient.getQueryData(qKey);

    if (queryData && overwrite) {
      queryClient.setQueryData(qKey, (old: any) => {
        return produce(old, (draft: any) => {
          const item = (draft.data as ScopeOfWorkImage[]).find(
            (item) => item.imageId === newItem.imageId
          );
          if (item) Object.assign(item, newItem);
          return draft;
        });
      });
    } else queryClient.invalidateQueries(qKey);
  };
  const handleDeleteMultipleSOWImgs = (deletedIds: string[]) => {
    const queryData = queryClient.getQueryData(qKey);
    if (queryData && overwrite) {
      queryClient.setQueryData<InfiniteAxiosQueryData<ScopeOfWorkImage>>(
        qKey,
        (old) => {
          return produce(old, (draft) => {
            if (draft)
              draft.pages.forEach((page) => {
                page.data.results = page.data.results.filter(
                  (oriItem) => !deletedIds.includes(oriItem.imageId)
                );
              });
            return draft;
          });
        }
      );
    } else queryClient.invalidateQueries(qKey);
  };

  return {
    handleAddMultiSOWImg,
    handleEditSOWImg,
    handleDeleteMultipleSOWImgs,
  };
};

export default useSetSOWImgQueryData;
