import React from "react";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import { StyledSuccessSubmitQuotePage } from "./StyledComponentQuoteResponse";
import { QuoteResponse } from "../../models/quote";
import successImg from "../../../Assets/QuoteSuccessSubmit.png";
import { useNavigate } from "react-router-dom";
type Props = {
  quoteResponse: QuoteResponse;
};

const SuccessSubmitQuotePage = ({ quoteResponse }: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,

      doxleFont: state.doxleFont,
    }),
    shallow
  );
  const navigate = useNavigate();
  const handleNavigateToDoxle = () => navigate("/");
  return (
    <StyledSuccessSubmitQuotePage
      $themeColor={doxleThemeColor}
      $doxleFont={doxleFont}
      initial={{
        y: 100,
        opacity: 0.4,
      }}
      animate={{ y: 0, opacity: 1 }}
    >
      <img className="success-img" src={successImg} />
      <div className="success-text">Your quote successfully submitted...</div>
      <div className="navigate-text" onClick={handleNavigateToDoxle}>
        Go to Doxle
      </div>
    </StyledSuccessSubmitQuotePage>
  );
};

export default SuccessSubmitQuotePage;
