import React, { useEffect, useMemo, useState } from "react";
import ContactsAPI from "../../../../Services/QueryHooks/contactsAPI";
import { shallow } from "zustand/shallow";
import { useDoxleCurrentContextStore } from "../../../../DoxleGeneralStore/useDoxleCurrentContext";
import useDoxleNotificationStore from "../../../../DoxleGeneralStore/useDoxleNotificationStore";
import { ContactCompany } from "../../../../Models/addressBook";
import { useAddQuoteResponseContext } from "../AddQuoteResponse";
import { produce } from "immer";
import { ContactCompanyFilters } from "../../../../Services/QueryHooks/contactsFilters";

type Props = {};
interface AddQuoteContractor {
  searchInput: string;
  setSearchInput: React.Dispatch<React.SetStateAction<string>>;
  handleSelectContractor: (item: ContactCompany) => void;
  contactList: ContactCompany[];
  isFetchingContractor: boolean;
  isErrorFetchingContractor: boolean;
}
const useAddQuoteContractor = (props: Props): AddQuoteContractor => {
  const [searchInput, setSearchInput] = useState("");
  const [filterContractorList, setFilterContractorList] =
    useState<ContactCompanyFilters>({});

  const company = useDoxleCurrentContextStore(
    (state) => state.currentCompany,
    shallow
  );
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );
  const retrieveContactCompanyQuery =
    ContactsAPI.useRetrieveContactsCompanyQuery({
      filter: filterContractorList,

      enable: true,
      showNotification,
      company,
    });
  const contactList = useMemo(
    () =>
      retrieveContactCompanyQuery.isSuccess
        ? retrieveContactCompanyQuery.data.pages.flatMap(
            (page) => (page.data.results as ContactCompany[]) ?? []
          ) ?? []
        : [],
    [retrieveContactCompanyQuery.data]
  );

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (searchInput) setFilterContractorList({ search: searchInput });
      else setFilterContractorList({});
    }, 200);
    return () => clearTimeout(timeout);
  }, [searchInput]);

  const { setNewQuoteResponse } = useAddQuoteResponseContext();
  const handleSelectContractor = (item: ContactCompany) => {
    setNewQuoteResponse((prev) =>
      produce(prev, (draft) => {
        draft.supplier = item.contactCompanyId!;
        draft.supplierName = item.name;
        return draft;
      })
    );
  };
  return {
    searchInput,
    setSearchInput,
    handleSelectContractor,
    contactList,
    isFetchingContractor: retrieveContactCompanyQuery.isLoading,
    isErrorFetchingContractor: retrieveContactCompanyQuery.isError,
  };
};

export default useAddQuoteContractor;
