import React from "react";
import {
  StyledDoxleListSkeletonContainer,
  StyledListScroller,
} from "./StyledComponentDoxleSkeleton";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import { Virtuoso } from "react-virtuoso";
import CommentSkeletonRow from "./CommentSkeletonRow";
import DocketListSkeletonRow, {
  BudgetDocketListSkeletonRow,
  InventorySkeletonRow,
  MailSkeletonRow,
  NoteLineSkeletonRow,
  NoteSkeletonRow,
  OrderListSkeletonRow,
  PricebookSkeletonRow,
  ProjectSkeletonRow,
} from "./DocketListSkeletonRow";
import ScopeOfWorkSkeletonRow from "./ScopeOfWorkSkeletonRow";
import RFISkeletonRow from "./RFISkeletonRow";
import Skeleton from "@mui/material/Skeleton";

type Props = {
  containerWidthInPixel?: `${number}px`;
  containerWidthInRatio?: `${number}%`;
  containerHeightInPixel?: `${number}px`;
  containerHeightInRatio?: `${number}%`;
  containerBgColor?: string;
  containerStyle?: React.CSSProperties;
  numOfRows?: number;
  skeletonType?:
    | "comment"
    | "docketRow"
    | "budgetRow"
    | "orderRow"
    | "scopeOfWork"
    | "rfiRow"
    | "mailRow"
    | "pricebookRow"
    | "inventoryRow"
    | "noteRow"
    | "noteLineRow"
    | "projectRow";
};
const ROW_HEIGHT = 100;
const MARGIN_ROW_VERTICAL = 14;
const DoxleListSkeleton = ({
  containerWidthInPixel,
  containerWidthInRatio,
  containerHeightInPixel,
  containerHeightInRatio,
  containerBgColor,
  numOfRows,
  containerStyle,
  skeletonType = "comment",
}: Props) => {
  const { doxleThemeColor } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
    }),
    shallow
  );

  // const SKELETON_LIST_HEIGHT =
  //   containerHeightInPixel || containerHeightInRatio
  //     ? "100%"
  //     : numOfRows
  //     ? `${numOfRows * (ROW_HEIGHT + MARGIN_ROW_VERTICAL * 2)}px`
  //     : `${20 * (ROW_HEIGHT + MARGIN_ROW_VERTICAL * 2)}px`;

  return (
    <StyledDoxleListSkeletonContainer
      $themeColor={doxleThemeColor}
      $widthInPixel={containerWidthInPixel}
      $widthInRatio={containerWidthInRatio}
      $heightInPixel={containerHeightInPixel}
      $heightInRatio={containerHeightInRatio}
      $bgColor={containerBgColor}
      style={{
        width: containerStyle?.width
          ? containerStyle?.width
          : containerWidthInPixel
          ? containerWidthInPixel
          : containerWidthInRatio
          ? containerWidthInRatio
          : "100%",
        height: containerStyle?.height
          ? containerStyle?.height
          : containerHeightInPixel
          ? containerHeightInPixel
          : containerHeightInRatio
          ? containerHeightInRatio
          : "100%",
        backgroundColor: containerBgColor ?? containerStyle?.backgroundColor,
        ...containerStyle,
      }}
    >
      <Virtuoso
        data={Array(numOfRows ?? 20).fill("SKELETON")}
        style={{
          width: "100%",
          height: "100%",
          pointerEvents: "none",
        }}
        itemContent={(index, item) =>
          skeletonType === "comment" ? (
            <CommentSkeletonRow key={`skelRow${index}`} />
          ) : skeletonType === "budgetRow" ? (
            <BudgetDocketListSkeletonRow key={`docketListRow${index}`} />
          ) : skeletonType === "orderRow" ? (
            <OrderListSkeletonRow key={`docketListRow${index}`} />
          ) : skeletonType === "scopeOfWork" ? (
            <ScopeOfWorkSkeletonRow key={`docketListRow${index}`} />
          ) : skeletonType === "rfiRow" ? (
            <RFISkeletonRow key={`docketListRow${index}`} />
          ) : skeletonType === "mailRow" ? (
            <MailSkeletonRow key={`docketListRow${index}`} />
          ) : skeletonType === "pricebookRow" ? (
            <PricebookSkeletonRow key={`docketListRow${index}`} />
          ) : skeletonType === "inventoryRow" ? (
            <InventorySkeletonRow key={`docketListRow${index}`} />
          ) : skeletonType === "noteRow" ? (
            <NoteSkeletonRow key={`docketListRow${index}`} />
          ) : skeletonType === "noteLineRow" ? (
            <NoteLineSkeletonRow key={`docketListRow${index}`} />
          ) : skeletonType === "projectRow" ? (
            <ProjectSkeletonRow />
          ) : (
            <DocketListSkeletonRow key={`docketListRow${index}`} />
          )
        }
        components={{
          Scroller: React.forwardRef((props, ref) => (
            <StyledListScroller
              style={{
                ...props.style,
              }}
              ref={ref}
              {...props}
            />
          )),
        }}
      />
    </StyledDoxleListSkeletonContainer>
  );
};

export default DoxleListSkeleton;
