import { TextField } from "@mui/material";
import Button from "@mui/material/Button";
import { motion } from "framer-motion";
import styled from "styled-components";
import { editRgbaAlpha } from "../../../../Utilities/FunctionUtilities";

export const StyledLoginHeader = styled.div`
  background-color: ${(p) => p.theme.staticMenuColor.staticBg};
  width: 100%;
  top: 0;
  height: 20px;
  padding: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
`;

export const StyledHeaderRight = styled.div`
  cursor: pointer;
  color: ${(p) =>
    editRgbaAlpha({
      rgbaColor: p.theme.staticMenuColor.staticFontColor,
      alpha: "0.5",
    })};
  padding: 10px;
  font-size: 13px;
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const StyledHeaderLeft = styled.div`
  color: ${(p) =>
    editRgbaAlpha({
      rgbaColor: p.theme.staticMenuColor.staticFontColor,
      alpha: "0.5",
    })};
  float: right;
  display: flex;
  padding: 10px;
  font-size: 13px;
  margin-right: 7px;
`;

export const StyledDoxleLoginScreenTest = styled.div`
  background-color: ${(p) => p.theme.color.primaryContainerColor};
  color: ${(p) => p.theme.color.primaryFontColor};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  flex-direction: column;
  font-size: 14px;
`;

export const StyledBannerText = styled.div`
  margin-top: 5px;
  margin-bottom: 5px;
  color: #5252ff;
  font-weight: 700;
  font-size: 24px;
`;

export const StyledLinkText = styled.span`
  color: ${(p) => p.theme.color.primaryFontColor};
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

export const StyledLoginField = styled(TextField)`
  && {
    width: 273px;
    height: 45px !important;
    border-radius: 2px !important;
    margin-top: 4px;
    margin-bottom: 2px;
    font-size: 14px;
  }
  & .MuiOutlinedInput-notchedOutline {
    border-color: ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.theme.color.primaryFontColor,
        alpha: "0.4",
      })} !important;
    transition: 0.4s ease all;
  }
  & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${(p) => p.theme.color.doxleColor} !important;
    transition: 0.2s ease all;
    border-width: 1.5px;
  }
  & .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    transition: 0.2s ease all;
  }
  input {
    font-size: 14px;
    padding-top: 12px;
    padding-bottom: 12px;
    color: ${(p) => p.theme.color.primaryFontColor};
    &::placeholder {
      font-size: 14px;
      color: ${(p) =>
        editRgbaAlpha({
          rgbaColor: p.theme.color.primaryFontColor,
          alpha: "0.4",
        })};
      opacity: 1;
    }
  }
`;

export const StyledLoginButton = styled(motion(Button))`
  && {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    flex-direction: row;
    width: 273px;
    height: 45px;
    color: white;
    text-transform: none !important;
    border-radius: 4px !important;
    transition: background-color 0.3s ease;

    font-size: 14px;
    background-color: black;
    margin-top: 20px;
    margin-bottom: 10px;
    &:hover {
      background-color: #5252ff;
    }
  }
`;

export const StyledPromptError = styled(motion.div)`
  color: #ea1515;
  font-size: 12px;
  position: relative;
  width: 250px;
  margin-left: 5px;
  height: 0px;
  margin-bottom: -5px;
`;
