import React, { ForwardedRef, forwardRef, useMemo } from "react";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import {
  StyledDoxleFilterTagContainer,
  StyledEmailTag,
} from "./StyledComponentDoxleFilterTag";
import DoxleIconButton from "../DoxleButtons/DoxleIconButton";
import { FilterRemoveIcon } from "./Icons";
import { IconButtonProps } from "@mui/material/IconButton";

type Props<T> = {
  itemData: T;
  itemDisplayText: string;
  removeItemFunction?: (item: T) => void;
  tagStyle?: React.CSSProperties;
  textStyle?: React.CSSProperties;
  ref?: React.Ref<HTMLDivElement> | null;
  removable?: boolean;
  extraContent?: React.ReactNode;
  removeIconStyle?: React.CSSProperties;
  removeBtnProps?: IconButtonProps;
};

function DoxleFilterTag<T>({
  itemData,
  itemDisplayText,
  removeItemFunction,
  tagStyle,
  ref,
  removable = true,
  extraContent,
  textStyle,
  removeIconStyle,
  removeBtnProps,
}: Props<T>) {
  const { doxleThemeColor, doxleFont, currentTheme } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
      currentTheme: state.currentTheme,
    })
  );

  const containerAnimatedVariants = useMemo(
    () => ({
      initial: {
        x: -10,
        opacity: 0,
      },
      entering: {
        x: 0,
        opacity: 1,
      },

      hovering: {
        scale: 1.1,
      },
      exiting: {
        opacity: 0,
        y: -2,
      },
    }),
    []
  );
  return (
    <StyledDoxleFilterTagContainer
      variants={containerAnimatedVariants}
      initial="initial"
      animate="entering"
      exit="exiting"
      layout="position"
      transition={{
        duration: 0.1,
        damping: 12,
      }}
      ref={ref}
      style={{
        ...tagStyle,
      }}
    >
      <span style={textStyle}> {itemDisplayText}</span>
      {extraContent ?? extraContent}

      {removeItemFunction && (
        <div
          style={{
            position: "absolute",
            top: -7,
            right: -7,
            zIndex: 100,
          }}
        >
          {removable && (
            <DoxleIconButton
              iconSource={
                <FilterRemoveIcon
                  themeColor={doxleThemeColor}
                  containerStyle={{ width: 17, ...removeIconStyle }}
                />
              }
              buttonSize={15}
              disableAnimation
              onClick={(event) => {
                event.stopPropagation();
                if (removeItemFunction) removeItemFunction(itemData);
              }}
              {...removeBtnProps}
            />
          )}
        </div>
      )}
    </StyledDoxleFilterTagContainer>
  );
}
export default DoxleFilterTag;
