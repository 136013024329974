import { StyledPageContainer } from "./StyledComponents";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import { useContactsStore } from "./Store/useContactsStore";
import ContactsPage from "./ContactsTable/ContactsPage";
import ContactCompanyPage from "./ContactCompaniesTable/ContactCompanyPage";
import AddContactDialogDisplayer from "./AddContactDialog/AddContactDialogDisplayer";
import ContextPageNewDropDown from "./ContextPageNewDropDown";
import useContacts from "../Hooks/useContacts";
import { Helmet } from "react-helmet";
import { useShallow } from "zustand/react/shallow";
import { DoxleContactIcon } from "../../DoxleDesignPattern/DoxleGeneralIcons";
import { useDoxleCurrentContextStore } from "../../DoxleGeneralStore/useDoxleCurrentContext";
import DoxleAddNewButton from "../../DoxleDesignPattern/DoxleButtons/DoxleAddNewButton";
interface Props {
  context?: "Project" | "DocketFullScreen" | "DocketHalfScreen";
}

const Contacts = ({ context }: Props) => {
  const doxleThemeColor = useDoxleThemeStore(
    useShallow((state) => state.doxleThemeColor)
  );
  const { currentCompany } = useDoxleCurrentContextStore(
    useShallow((state) => ({
      currentCompany: state.currentCompany,
    }))
  );
  const { view } = useContactsStore(
    useShallow((state) => ({
      view: state.view,
    }))
  );
  const {
    displayDropDown,
    setDisplayDropDown,
    isAddingContacts,
    handleShowDropDown,
  } = useContacts({ context });

  return (
    <StyledPageContainer>
      <Helmet>
        <title>Doxle - Contacts</title>
      </Helmet>
      {context !== "DocketFullScreen" && context !== "DocketHalfScreen" && (
        <div className="header-section">
          <div>
            <DoxleContactIcon
              themeColor={doxleThemeColor}
              containerStyle={{
                width: 30,
                marginRight: 8,
              }}
            />
            {`${currentCompany?.name}'s`} Contacts
          </div>

          <div>
            <DoxleAddNewButton
              onClick={handleShowDropDown}
              iconOnly={true}
              isLoading={isAddingContacts}
            />
          </div>
        </div>
      )}
      {view === "Contacts" ? <ContactsPage /> : <ContactCompanyPage />}
      <AddContactDialogDisplayer />
      {displayDropDown && (
        <ContextPageNewDropDown
          context={context}
          buttonBounding={displayDropDown}
          handleClose={() => setDisplayDropDown(null)}
        />
      )}
    </StyledPageContainer>
  );
};
export default Contacts;
