import React from "react";
import {
  CompanyPermission,
  DocketPermission,
  ProjectPermission,
} from "../../../Models/permissions";
import { StyledSharedUserItem } from "./StyledShareProjectDialog";
import ShareUserItem from "../MenuShareUser/ShareUserItem";
import { IoChevronDownOutline } from "react-icons/io5";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import { editRgbaAlpha } from "../../../Utilities/FunctionUtilities";
import { useShareProjectDialogContext } from "./ShareProjectDialog";

type Props = {
  sharedUserDetail: ProjectPermission | CompanyPermission | DocketPermission;
  randomColor: string;
};

const SharedProjectUserItem = ({ sharedUserDetail, randomColor }: Props) => {
  const { doxleThemeColor, staticMenuColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      staticMenuColor: state.staticMenuColor,
      doxleFont: state.doxleFont,
    }),
    shallow
  );
  const {
    setEditedPermissionItem,

    setAnchorPopoverForm,
  } = useShareProjectDialogContext();
  return (
    <StyledSharedUserItem>
      <ShareUserItem
        color={randomColor}
        permissionItem={sharedUserDetail}
        size={30}
      />

      <div className="user-name">
        {sharedUserDetail.contact.firstName} {sharedUserDetail.contact.lastName}
      </div>

      <div
        className="permission-toggle"
        onClick={(e) => {
          setEditedPermissionItem(sharedUserDetail);
          setAnchorPopoverForm(e.currentTarget);
        }}
      >
        edit
        <IoChevronDownOutline
          className="icon-dropdown"
          color={editRgbaAlpha({
            rgbaColor: staticMenuColor.staticFontColor,
            alpha: "0.7",
          })}
          size={14}
          style={{ marginLeft: 8 }}
        />
      </div>
    </StyledSharedUserItem>
  );
};

export default SharedProjectUserItem;
