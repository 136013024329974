import { useLottie } from "lottie-react";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
// import animationData from "./lotties/doxle-loader.json"; // wagging tail
import animationData from "./lotties/doxleDog.json"; // running doggie
import { AnimatePresence, Variants, motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { useInterval } from "../../CoreContent/UtilityHooks/useInterval";
import { ISVGResponsiveCustom } from "../../Models/modelHelper";
import { set } from "lodash";
import { produce } from "immer";

interface props {
  text?: string;
  containerStyle?: React.CSSProperties;
  animationSize?: number;
  textStyle?: React.CSSProperties;
  enableAnimation?: boolean;
}

const Loading: React.FC<props> = ({
  text,
  containerStyle,
  animationSize = 200,
  textStyle,
  enableAnimation = false,
}: props) => {
  const [shouldStart, setshouldStart] = useState(false);
  const [orderAnimated, setOrderAnimated] = useState<number[]>([]);
  const [currentAnimatedIdx, setCurrentAnimatedIdx] = useState(0);
  const options = {
    animationData: animationData,
    loop: true,
    autoplay: true,
  };

  const { doxleThemeColor } = useDoxleThemeStore((state) => ({
    doxleThemeColor: state.doxleThemeColor,
    doxleFont: state.doxleFont,
  }));

  const { View } = useLottie(options, {
    width: `${animationSize}px`,
    aspectRatio: 1,
    marginTop: `-${animationSize / 7 ?? 200 / 7}px`,
  });

  useInterval(() => {
    if (shouldStart) setCurrentAnimatedIdx((prev) => prev + 1);
  }, 2000);
  useEffect(() => {
    if (shouldStart)
      setOrderAnimated(
        produce((draft) => {
          if (currentAnimatedIdx < 4) draft.push(currentAnimatedIdx);
          else {
            draft = [];
            setCurrentAnimatedIdx(0);
          }
          return draft;
        })
      );
  }, [currentAnimatedIdx, shouldStart]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setshouldStart(true);
    }, 800);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <AnimatePresence>
      <motion.div
        initial={
          enableAnimation && {
            scale: 0,
            opacity: 0,
          }
        }
        animate={
          enableAnimation && {
            scale: 1,
            opacity: 1,
          }
        }
        transition={{
          duration: 0.4,
        }}
        exit={enableAnimation ? { scale: 0, opacity: 0 } : undefined}
        style={{
          width: "100%",
          height: `calc(100% - ${textStyle?.fontSize ?? 27}px)`,
          display: "flex",
          flexDirection: "column",
          alignContent: "center",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "transparent",
          fontSize: 18,
          color: doxleThemeColor.primaryFontColor,
          zIndex: 10,
          ...containerStyle,
          paddingBottom: `${textStyle?.fontSize ?? 18}px`,
        }}
      >
        {View}{" "}
        {/* <span
          style={{
            ...textStyle,
            fontFamily: "IBM Plex Mono",
            marginTop: `-${animationSize / 7 ?? 250 / 7}px`,
            maxWidth: `min(500px, 60%)`,
            minHeight: 50,
          }}
        >
          {text }
        </span> */}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: `-${animationSize / 7 ?? 200 / 7}px`,
          }}
        >
          {[1, 2, 3, 4].map((item, idx) => (
            <AnimatedLoader
              key={idx}
              themeColor={doxleThemeColor}
              containerStyle={{
                width: 25,
                margin: "0px 4px",
              }}
              isAnimated={orderAnimated.includes(idx)}
            />
          ))}
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

export default Loading;

export const AnimatedLoader = ({
  themeColor,
  containerStyle,
  isAnimated,
  ...props
}: ISVGResponsiveCustom & {
  isAnimated: boolean;
}) => {
  const svgPathVariants: Variants = {
    visible: {
      pathLength: [0, 1],

      transition: {
        pathLength: {
          duration: 2,
        },
      },
    },
  };

  return (
    <div
      style={{
        width: 12,
        ...containerStyle,
        aspectRatio: 12 / 13,
        display: "flex",
      }}
    >
      <motion.svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        viewBox="0 0 12 13"
        fill="none"
      >
        {isAnimated && (
          <motion.path
            stroke={themeColor.primaryFontColor}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            strokeOpacity={1}
            d="m5.165 11.641-3.84-4.224c-.434-.478-.434-1.359 0-1.834l3.84-4.224c.097-.111.223-.202.368-.264a1.186 1.186 0 0 1 .934 0c.145.062.271.153.368.264l3.84 4.224c.434.478.434 1.359 0 1.834l-3.84 4.224a1.018 1.018 0 0 1-.368.264 1.187 1.187 0 0 1-.934 0 1.018 1.018 0 0 1-.368-.264Z"
            variants={svgPathVariants}
            animate={"visible"}
          />
        )}

        <motion.path
          stroke={themeColor.primaryFontColor}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
          strokeOpacity={0.25}
          d="m5.165 11.641-3.84-4.224c-.434-.478-.434-1.359 0-1.834l3.84-4.224c.097-.111.223-.202.368-.264a1.186 1.186 0 0 1 .934 0c.145.062.271.153.368.264l3.84 4.224c.434.478.434 1.359 0 1.834l-3.84 4.224a1.018 1.018 0 0 1-.368.264 1.187 1.187 0 0 1-.934 0 1.018 1.018 0 0 1-.368-.264Z"
        />
      </motion.svg>
    </div>
  );
};
