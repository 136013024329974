import { createContext, useContext } from "react";
import useDoxleNotificationStore from "../../DoxleGeneralStore/useDoxleNotificationStore";
import useGetCompanyList from "../QueryDataHooks/GetQueryDataHooks/useGetCompanyList";

type Props = {};
export interface ICompanyContextValue {}

const CompanyContext = createContext({});
const CompanyProvider = (children: any) => {
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification
  );

  // const { setCurrentCompany } = useDoxleCurrentContextStore(
  //   (state) => ({
  //     setCurrentCompany: state.setCurrentCompany,
  //   }),
  //   shallow
  // );
  //**** FETCH COMPANIES **** */
  const {} = useGetCompanyList({});

  //************************* */
  const companyContextValue: ICompanyContextValue = {};
  return <CompanyContext.Provider {...children} value={companyContextValue} />;
};
const useCompany = () => useContext(CompanyContext);
export { CompanyProvider, useCompany };
