import React from "react";

import { useScopeGroupTitle } from "../Hooks/useScopeGroupTitle";
import { ScopeOfWorkGroup } from "../Model/scopeOfWorks";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import {
  StyledSOWGroupMenuBtn,
  StyledSOWGroupTitleTextfield,
  StyledScopeGroupTitleContainer,
  StyledScopeItemRowCheckbox,
} from "./StyledComponentScopeOfWorkFullScreen";
import { Variants, motion } from "framer-motion";
import { DoxleDeleteIcon } from "../../DoxleDesignPattern/DoxleGeneralIcons";
import DoxleSelectContactDropdown from "../../DoxleDesignPattern/DoxleSelectContactDropdown/DoxleSelectContactDropdown";
import { AiOutlineUsergroupAdd } from "react-icons/ai";
import ScopeGroupAssigneeDisplay from "./ScopeGroupAssigneeDisplay";
import useGetScopeItemList from "../Hooks/useGetScopeItemList";
import {
  CheckedScopeItemCheckbox,
  UncheckedScopeItemCheckbox,
} from "./ScopeOfWorkFullScreenIcons";
import CircularProgress from "@mui/material/CircularProgress";
import { CgArrowsExpandRight } from "react-icons/cg";
import { CgMinimizeAlt } from "react-icons/cg";
import { useScopeOfWorkStore } from "../Store/useScopeOfWorkStore";
import { shallow } from "zustand/shallow";
import { editRgbaAlpha } from "../../Utilities/FunctionUtilities";
import { initial } from "lodash";
import {
  bindFocus,
  bindToggle,
  bindTrigger,
  usePopupState,
} from "material-ui-popup-state/hooks";
import DoxleNestedPopover from "../../DoxleDesignPattern/DoxleNestedPopover";
import ScopeGroupMenuPopover from "./ScopeGroupMenuPopover/ScopeGroupMenuPopover";
type Props = {
  scopeGroup: ScopeOfWorkGroup;
};

const ScopeGroupTitle = ({ scopeGroup }: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore((state) => ({
    doxleThemeColor: state.doxleThemeColor,
    doxleFont: state.doxleFont,
  }));

  const {
    handleClickGroupTitle,
    onEditMode,
    setOnEditMode,
    newTitle,
    setNewTitle,
    titleFieldRef,
    onKeyDownTitleField,
    handleClickAssigneeBtn,
    handleClosePopover,
    anchorAssigneePopover,
    handleUpdateContactAssignee,
    handleUpdateCompanyAssignee,
    handleClickDeleteBtn,
    handleBlurTitleTextField,
    handleCheckComplete,
    isUpdatingCheck,
    handleToggleExpandSOWGroup,
    isGroupExpanded,
    handleRemoveContactAssignee,
    handleRemoveCompanyAssignee,
    handleDoubleClickTextField,
    hiddenTextRef,
    textFieldWidth,
  } = useScopeGroupTitle({
    scopeGroup,
  });

  const { setFocusedSOWGroup, focusedSOWGroup } = useScopeOfWorkStore(
    (state) => ({
      setFocusedSOWGroup: state.setFocusedSOWGroup,
      focusedSOWGroup: state.focusedSOWGroup,
    }),
    shallow
  );
  const titleWrapperVariants: Variants = {
    initial: {
      borderColor: editRgbaAlpha({
        rgbaColor: doxleThemeColor.doxleColor,
        alpha: "0",
      }),
    },
    focus: {
      borderColor: editRgbaAlpha({
        rgbaColor: doxleThemeColor.doxleColor,
        alpha: "1",
      }),
    },
  };

  return (
    <>
      <StyledScopeGroupTitleContainer
        initial="initial"
        variants={titleWrapperVariants}
        animate={
          focusedSOWGroup?.sowItem.scopeGroupId === scopeGroup.scopeGroupId
            ? "focus"
            : "initial"
        }
        transition={{
          duration: 0.2,
        }}
        $doxleFont={doxleFont}
        $themeColor={doxleThemeColor}
        layout="position"
        onDoubleClick={(e) => {
          handleDoubleClickTextField();
        }}
      >
        <span
          onClick={handleClickGroupTitle}
          ref={hiddenTextRef}
          className="title-text"
        >
          {newTitle}
        </span>
        <StyledSOWGroupTitleTextfield
          $doxleFont={doxleFont}
          $themeColor={doxleThemeColor}
          value={newTitle}
          onChange={(e) => setNewTitle(e.target.value)}
          multiline
          style={{ width: textFieldWidth }}
          // InputProps={{
          //   readOnly: !onEditMode,
          // }}
          onFocus={(e) => e.currentTarget.select()}
          inputRef={titleFieldRef}
          variant="standard"
          onKeyDown={onKeyDownTitleField}
          placeholder="New Task Title..."
          onBlur={handleBlurTitleTextField}
        />

        {/* <ScopeGroupAssigneeDisplay scopeGroup={scopeGroup} /> */}
        <motion.div
          layout="position"
          transition={{
            duration: 0.1,
            damping: 16,
          }}
          className="toggle-menu-container"
        >
          <StyledSOWGroupMenuBtn onClick={handleToggleExpandSOWGroup}>
            {!isGroupExpanded ? (
              <CgArrowsExpandRight
                className="expand-icon"
                size={16}
                color={doxleThemeColor.primaryFontColor}
              />
            ) : (
              <CgMinimizeAlt
                className="expand-icon"
                size={16}
                color={doxleThemeColor.doxleColor}
              />
            )}
          </StyledSOWGroupMenuBtn>
          <StyledSOWGroupMenuBtn
            onClick={(e) => {
              e.stopPropagation();
              setFocusedSOWGroup({
                anchorItem: e.currentTarget,
                sowItem: scopeGroup,
              });
            }}
          >
            <AiOutlineUsergroupAdd
              color={doxleThemeColor.primaryFontColor}
              size={20}
            />
          </StyledSOWGroupMenuBtn>
          <StyledSOWGroupMenuBtn
            onClick={(e) => {
              e.stopPropagation();
              handleClickDeleteBtn();
            }}
          >
            <DoxleDeleteIcon
              themeColor={doxleThemeColor}
              containerStyle={{
                width: 16,
              }}
              iconColor="rgba(255,0,0,1)"
            />
          </StyledSOWGroupMenuBtn>
        </motion.div>
      </StyledScopeGroupTitleContainer>
    </>
  );
};

export default React.memo(ScopeGroupTitle);
