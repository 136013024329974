import React from "react";
import { Company } from "../../../Models/company";
import { useDoxleCurrentContextStore } from "../../../DoxleGeneralStore/useDoxleCurrentContext";

import {
  StyledCompanyDisplayBtn,
  StyledMainMenuPopover,
} from "./StyledNavBarEllipsis";
import { useRootNavBarPopoverMenuContext } from "../../../DoxleDesignPattern/DoxleNestedPopover";
import { usePopupState } from "material-ui-popup-state/hooks";
import { bindHover, bindFocus } from "material-ui-popup-state";
import useGetCompanyList from "../../QueryDataHooks/GetQueryDataHooks/useGetCompanyList";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { FaAngleRight } from "react-icons/fa6";
import { FaCheck } from "react-icons/fa6";
import DoxleNestedPopover from "../../../DoxleDesignPattern/DoxleNestedPopover";
import { useShallow } from "zustand/react/shallow";
type Props = {
  popupId: string;
};

const CompanySubMenu = ({ popupId }: Props) => {
  const { staticMenuColor } = useDoxleThemeStore(
    useShallow((state) => ({
      staticMenuColor: state.staticMenuColor,
    }))
  );
  const { currentCompany, setCurrentCompany } = useDoxleCurrentContextStore(
    useShallow((state) => ({
      currentCompany: state.currentCompany,
      setCurrentCompany: state.setCurrentCompany,
    }))
  );
  const { companyList } = useGetCompanyList({});
  const { parentPopupState, rootPopupState } =
    useRootNavBarPopoverMenuContext();
  const popupState = usePopupState({
    popupId,
    variant: "popover",
    parentPopupState,
  });
  if (!rootPopupState) throw new Error("must be used inside a CascadingMenu");
  const handleClickCompanyItem = React.useCallback(
    (
      event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
      company: Company
    ) => {
      rootPopupState.close();
      setCurrentCompany(company);
    },
    [rootPopupState]
  );
  return (
    <>
      <StyledCompanyDisplayBtn
        $selected={true}
        {...bindHover(popupState)}
        {...bindFocus(popupState)}
      >
        {currentCompany ? currentCompany.name : "Select A company"}

        <FaAngleRight
          className="icon-expand"
          color={staticMenuColor.staticFontColor}
          size={14}
        />
        <DoxleNestedPopover
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          popupState={popupState}
          sx={{
            "& .MuiPopover-paper": {
              backgroundColor: `${staticMenuColor.staticBg} !important`,
              overflow: "visible",
              padding: "0px !important",
            },
          }}
        >
          <StyledMainMenuPopover style={{ padding: "0px" }}>
            {companyList.map((company) => (
              <StyledCompanyDisplayBtn
                $selected={company.companyId === currentCompany?.companyId}
                $isItem={true}
                key={company.companyId}
                onClick={(e) => {
                  handleClickCompanyItem(e, company);
                }}
              >
                {company.companyId === currentCompany?.companyId && (
                  <FaCheck
                    color={staticMenuColor.staticFontColor}
                    size={20}
                    style={{
                      position: "absolute",
                      alignSelf: "center",
                      left: 8,
                      top: "50%",
                      transform: "translateY(-50%)",
                    }}
                  />
                )}
                {company.name}
              </StyledCompanyDisplayBtn>
            ))}
          </StyledMainMenuPopover>
        </DoxleNestedPopover>
      </StyledCompanyDisplayBtn>
    </>
  );
};

export default CompanySubMenu;
