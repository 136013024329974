import React from "react";
import { EmptyOrderBanner } from "../../../CommonComponents/BookingsIcon";
import { useDoxleThemeStore } from "../../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import { StyledEmptyOrderScreen } from "./StyledComponentBookingDocketDetail";

type Props = {};

const EmptyOrderScreen = (props: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }),
    shallow
  );
  return (
    <StyledEmptyOrderScreen
      $themeColor={doxleThemeColor}
      $doxleFont={doxleFont}
    >
      <EmptyOrderBanner {...doxleThemeColor} />

      <span className="empty-title-text">Oops, no purchase orders</span>

      <span className="empty-subtitle-text">
        You need to have a Signed Purchase Order to make a valid booking.
      </span>
    </StyledEmptyOrderScreen>
  );
};

export default EmptyOrderScreen;
