import React, { useMemo } from "react";
import {
  SkeletonLoaderContent,
  SkeletonRow,
} from "./QuoteRequestStyledComponents";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";

export const SkeletonLoader = () => {
  const { currentTheme, THEME_COLOR } = useDoxleThemeStore((state) => ({
    currentTheme: state.currentTheme,
    THEME_COLOR: state.doxleThemeColor,
  }));

  return (
    <>
      {Array.from({ length: 10 }, (_, idx) => (
        <SkeletonRow key={idx + "skel"}>
          <SkeletonLoaderContent
            $width="21.5rem"
            $bgColor={THEME_COLOR.skeletonColor}
          />
          <SkeletonLoaderContent
            $width="15rem"
            $bgColor={THEME_COLOR.skeletonColor}
          />
          <SkeletonLoaderContent
            $width="14.2rem"
            $bgColor={THEME_COLOR.skeletonColor}
          />
          <SkeletonLoaderContent
            $width="13rem"
            $bgColor={THEME_COLOR.skeletonColor}
          />
          <SkeletonLoaderContent
            $width="12rem"
            $bgColor={THEME_COLOR.skeletonColor}
          />
          <SkeletonLoaderContent
            $width="12rem"
            $bgColor={THEME_COLOR.skeletonColor}
          />
          <SkeletonLoaderContent
            $width="29rem"
            $bgColor={THEME_COLOR.skeletonColor}
          />
        </SkeletonRow>
      ))}
    </>
  );
};
