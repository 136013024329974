import React, { useCallback, useMemo, useRef } from "react";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { PricebookItem } from "../../Models/Pricebook";
import { GridComponents, GridItemContent, VirtuosoGrid } from "react-virtuoso";
import DoxleListSkeleton from "../../../DoxleDesignPattern/DoxleSkeleton/DoxleListSkeleton";
import PricebookCard from "./PricebookCard";
import DoxleEmptyPlaceHolder from "../../../DoxleDesignPattern/DoxleEmptyPlaceHolder/DoxleEmptyPlaceHolder";
import {
  StyledPricebookCardList,
  StyledPricebookCardListContainer,
  StyledPricebookCardWrapper,
} from "./StyledComponents";
import useGetPricebookList from "../../Hooks/useGetPricebookList";
import { Error404Banner } from "../../../DoxleDesignPattern/DoxleIcons/CommonIcons";
import { EmptyPriceListBanner } from "../PricebookIcons";
import DoxlePaginationControl from "../../../DoxleDesignPattern/DoxlePaginationControl/DoxlePaginationControl";
import { IPricebookCardListContext } from "../../Models/PricebookListType";
import useRefComponentDimension from "../../../CoreContent/UtilityHooks/useRefComponentDimension";
import { LayoutGroup } from "framer-motion";
import { useShallow } from "zustand/react/shallow";

interface Props {}

const PricebookCardList = ({}: Props) => {
  const doxleThemeColor = useDoxleThemeStore(
    useShallow((state) => state.doxleThemeColor)
  );
  const {
    pricebookList,
    isFetchingPricebookList,
    isSuccessFetchingPricebookList,
    isErrorFetchingPricebookList,
    totalPage,
    totalItem,
    hasPreviousPage,
    hasNextPage,
    removeQueryPricebookList,
    currentPage,
    handleSetPage,
    handleSetPageSize,
    currentPageSize,
    fetchNextRateList,
    fetchPreviousRateList,
  } = useGetPricebookList({});
  const wrapperRef = useRef<HTMLDivElement>(null);
  const { width: wrapperWidth, height } = useRefComponentDimension({
    componentRef: wrapperRef,
  });
  const numOfCol = useMemo(
    () =>
      wrapperWidth < 300
        ? 1
        : wrapperWidth < 700
        ? 2
        : wrapperWidth < 1000
        ? 3
        : 4,
    [wrapperWidth]
  );
  const components: GridComponents<IPricebookCardListContext> = useMemo(
    () => ({
      Item: ({ context, ...props }) => (
        <StyledPricebookCardWrapper
          {...props}
          $numOfCol={context?.numOfCol ?? 3}
        />
      ),
      List: React.forwardRef(({ context, children, ...props }, ref) => (
        <StyledPricebookCardList {...props} ref={ref}>
          <LayoutGroup>{children}</LayoutGroup>
        </StyledPricebookCardList>
      )),

      // Scroller: React.forwardRef((props, ref) => (
      //   <StyledListScroller ref={ref} {...props} />
      // )),
    }),
    []
  );
  const itemContent: GridItemContent<PricebookItem, IPricebookCardListContext> =
    useCallback((index, item) => <PricebookCard pricebook={item} />, []);
  return (
    <StyledPricebookCardListContainer ref={wrapperRef}>
      {isFetchingPricebookList && <DoxleListSkeleton />}
      {!isFetchingPricebookList && (
        <>
          {pricebookList.length > 0 && (
            <VirtuosoGrid
              context={{ isErrorFetchingPricebookList, numOfCol }}
              data={pricebookList}
              style={{
                width: "100%",
                flex: 1,
              }}
              // fixedHeaderContent={() =>  <PricebookTableHeader /> }
              itemContent={itemContent}
              components={components}
            />
          )}

          {pricebookList.length === 0 && (
            <DoxleEmptyPlaceHolder
              headTitleText={
                isErrorFetchingPricebookList
                  ? "Something wrong!"
                  : "No Pricebook Items"
              }
              subTitleText={
                isErrorFetchingPricebookList
                  ? "Please try to reload the page, we are sorry for this!"
                  : "Add an item to get started"
              }
              containerBgColor={doxleThemeColor.primaryBackgroundColor}
              addBtn={{
                btnText: "Add",
                btnColor: doxleThemeColor.primaryFontColor,
                btnTextColor: doxleThemeColor.primaryContainerColor,
                btnHeightInPixel: "40px",
                btnWidthInPixel: "164px",
                btnFunction: () => {
                  console.log("NOTHING HERE YET");
                },
              }}
              illustration={
                isErrorFetchingPricebookList ? (
                  <Error404Banner
                    themeColor={doxleThemeColor}
                    containerStyle={{
                      maxWidth: 400,
                      marginBottom: 14,
                    }}
                  />
                ) : (
                  <EmptyPriceListBanner
                    themeColor={doxleThemeColor}
                    containerStyle={{
                      maxWidth: 400,
                      marginBottom: 14,
                    }}
                  />
                )
              }
            />
          )}
        </>
      )}
      {pricebookList.length > 0 && (
        <DoxlePaginationControl
          totalItemCount={totalItem}
          totalPage={totalPage}
          currentPage={currentPage}
          currentPageSize={currentPageSize}
          onSelectPage={handleSetPage}
          onSelectPageSize={handleSetPageSize}
          hasPreviousPage={hasPreviousPage}
          hasNextPage={hasNextPage}
          onFetchNext={fetchNextRateList}
          onFetchPrevious={fetchPreviousRateList}
        />
      )}
    </StyledPricebookCardListContainer>
  );
};
export default PricebookCardList;
