import React, { useCallback, useEffect, useMemo, useState } from "react";
import useGetQRUserLogList from "../../../Hooks/useGetQRUserLogList";
import { IQRUserLog } from "../../../Models/qrLog";
import { useDoxleCurrentContextStore } from "../../../../DoxleGeneralStore/useDoxleCurrentContext";
import { useShallow } from "zustand/react/shallow";
import dayjs from "dayjs";
import { formatTISODate } from "../../../../Utilities/FunctionUtilities";
import { User } from "../../../../Models/user";
import { produce } from "immer";

type Props = {};

const useQRLogSummary = () => {
  const [dateFilterRange, setDateFilterRange] =
    useState<IQRLogSummaryDateRange>({
      start: dayjs().startOf("month").toDate(),
      end: dayjs().endOf("month").toDate(),
    });
  const [showQRSummaryDialog, setShowQRSummaryDialog] = useState(false);
  const [userLogList, setUserLogList] = useState<IQRUserLog[]>([]);
  const [hiddenUser, setHiddenUser] = useState<string[]>([]);
  const [filteredUser, setFilteredUser] = useState<User | undefined>(undefined);
  const { currentProject } = useDoxleCurrentContextStore(
    useShallow((state) => ({ currentProject: state.currentProject }))
  );
  const closeSummaryDialog = () => {
    setShowQRSummaryDialog(false);
    setDateFilterRange({
      start: dayjs().startOf("month").toDate(),
      end: dayjs().endOf("month").toDate(),
    });

    setHiddenUser([]);
    setFilteredUser(undefined);
  };
  const { ...rest } = useGetQRUserLogList({
    filter: {
      project: currentProject?.projectId,
      search_date_range: [
        formatTISODate(dateFilterRange.start),
        formatTISODate(dateFilterRange.end),
      ],
      page_size: 50,
      order_by: ["user__first_name"],
      pagination: "none",
      user: filteredUser?.userId,
    },
  });
  const handleHideUserGroup = useCallback((user: string) => {
    setHiddenUser(
      produce((draft) => {
        if (draft.includes(user)) {
          draft.splice(draft.indexOf(user), 1);
        } else {
          draft.push(user);
        }
        return draft;
      })
    );
  }, []);
  const contextValue: IQRLogSummaryContextValue = useMemo(
    () => ({
      userLogList,
      setUserLogList,
      ...rest,
      handleHideUserGroup,
      hiddenUser,
      dateFilterRange,
      setDateFilterRange,
      filteredUser,
      setFilteredUser,
    }),
    [
      userLogList,
      rest,
      handleHideUserGroup,
      hiddenUser,
      dateFilterRange,
      filteredUser,
    ]
  );
  useEffect(() => {
    setUserLogList(rest.qrUserLogList);
  }, [rest.qrUserLogList]);

  return {
    showQRSummaryDialog,
    setShowQRSummaryDialog,
    dateFilterRange,
    setDateFilterRange,
    contextValue,

    userLogList,
    setUserLogList,
    ...rest,
    closeSummaryDialog,
    filteredUser,
    setFilteredUser,
  };
};

export default useQRLogSummary;
export interface IQRLogSummaryDateRange {
  start: Date;
  end: Date;
}
export interface IQRLogSummaryContextValue
  extends ReturnType<typeof useGetQRUserLogList> {
  userLogList: IQRUserLog[];
  setUserLogList: React.Dispatch<React.SetStateAction<IQRUserLog[]>>;
  handleHideUserGroup: (user: string) => void;
  hiddenUser: string[];
  dateFilterRange: IQRLogSummaryDateRange;
  setDateFilterRange: React.Dispatch<
    React.SetStateAction<IQRLogSummaryDateRange>
  >;
  filteredUser: User | undefined;
  setFilteredUser: React.Dispatch<React.SetStateAction<User | undefined>>;
}
