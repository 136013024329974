import { PricebookSwatch } from "../../Models/Pricebook";
import { memo } from "react";
import usePricebookSwatchItem from "./Hooks/usePricebookSwatchItem";
import DoxleExpandableDeleteBtn from "../../../DoxleDesignPattern/DoxleButtons/DoxleExpandableDeleteBtn";
import { StyledPricebookSwatchItemContainer } from "./StyledAddEditPricebookItem";
import { useDoxleImageGalleryStore } from "../../../DoxleDesignPattern/DoxleGallery/Store/useDoxleImageGalleryStore";
import { useShallow } from "zustand/react/shallow";

type Props = {
  index: number;
  swatch: PricebookSwatch;
};

const PricebookSwatchItem = memo(({ index, swatch }: Props) => {
  const { handleDeleteSwatch, isDeletingSwatch } = usePricebookSwatchItem({
    item: swatch,
  });
  const { setImageList } = useDoxleImageGalleryStore(
    useShallow((state) => ({
      setImageList: state.setImageList,
    }))
  );
  return (
    <StyledPricebookSwatchItemContainer layout="position">
      <img
        src={swatch.url}
        onClick={(e) => {
          setImageList([
            {
              uri: swatch.url,
              name: "Image#1",
            },
          ]);
        }}
      />
      <DoxleExpandableDeleteBtn
        buttonWrapperStyle={{
          position: "absolute",
          top: 4,
          right: 4,
          zIndex: 5,
        }}
        isDeleting={isDeletingSwatch}
        onConfirmDelete={handleDeleteSwatch}
      />
    </StyledPricebookSwatchItemContainer>
  );
});

export default PricebookSwatchItem;
