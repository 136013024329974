import React, { useEffect, useMemo, useRef, useState } from "react";
import { Docket } from "../../../../../Models/dockets";
import { useDoxleAuthStore } from "../../../../../DoxleGeneralStore/useDoxleAuthStore";
import { shallow } from "zustand/shallow";
import { useDoxleCurrentContextStore } from "../../../../../DoxleGeneralStore/useDoxleCurrentContext";
import useDoxleNotificationStore from "../../../../../DoxleGeneralStore/useDoxleNotificationStore";
import { Order } from "../../../../Models/orders";
import OrdersQueryAPI from "../../../../QueryAPI/OrdersQueryAPI";
import useBookingProjectStore from "../../../../store/useBookingProjectStore";
import { useEditDocketSideScreenStore } from "../../../../../CoreContent/EditDocketSideScreen/EditDocketSideScreenStore/useEditDocketSideScreenStore";
import useBookingDocketStore from "../../../../store/useBookingDocketStore";

type Props = {
  onSelectOrder?: (order: Order) => void;

  mode: "add" | "edit";
};

interface BookingDocketOrder {
  ordersList: Order[];
  isFetchingOrderList: boolean;
  isSuccessFetchingOrderList: boolean;
  isErrorFetchingOrderList: boolean;
  refetchOrderList: () => void;
  isRefetchingOrderList: boolean;
  fetchMoreOrder: () => void;
  isFetchingMoreOrder: boolean;
  contentWrapperRef: React.RefObject<HTMLDivElement>;
  contentWrapperHeight: number;

  //edit booking content
  orderDetail: Order | undefined;
  isFetchingOrderDetail: boolean;
}
const useBookingDocketOrder = ({
  onSelectOrder,

  mode,
}: Props): BookingDocketOrder => {
  const [contentWrapperHeight, setcontentWrapperHeight] = useState<number>(0);
  const contentWrapperRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (contentWrapperRef.current)
      setcontentWrapperHeight(
        contentWrapperRef.current.getBoundingClientRect().height
      );
  }, [contentWrapperRef.current]);

  
  const { company } = useDoxleCurrentContextStore(
    (state) => ({
      company: state.currentCompany,
    }),
    shallow
  );
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );
  const { edittedDocket } = useEditDocketSideScreenStore(
    (state) => ({ edittedDocket: state.edittedDocket }),
    shallow
  );
  //*Add booking mode content
  const onSuccessGetOrder = (orderList?: Order[]) => {
    if (mode === "add" && orderList && orderList.length === 1 && onSelectOrder)
      onSelectOrder(orderList[0]);
  };
  const retrieveOrdersQuery = OrdersQueryAPI.useRetrieveOrderList({

    showNotification,
    company,
    filter: {
      docket: edittedDocket?.docketPk,
      is_invoice: false,
      signed_off: "true",
    },
    enabled: Boolean(edittedDocket && mode === "add"),
    onSuccessCb: onSuccessGetOrder,
  });

  const ordersList: Order[] = useMemo(
    () =>
      retrieveOrdersQuery.data?.pages
        ? retrieveOrdersQuery.data?.pages.reduce((acc, pageData) => {
            return acc.concat(pageData.data.results);
          }, [] as Order[])
        : [],
    [retrieveOrdersQuery.data]
  );

  const fetchMoreOrder = () => {
    if (retrieveOrdersQuery.hasNextPage) retrieveOrdersQuery.fetchNextPage();
  };

  const refetchOrderList = () => {
    if (edittedDocket) retrieveOrdersQuery.refetch();
  };

  //*edit booking mode content
  const { edittedBooking } = useBookingDocketStore(
    (state) => ({
      edittedBooking: state.edittedBooking,
    }),
    shallow
  );

  const getOrderDetailsQuery = OrdersQueryAPI.useRetrieveOrderDetail({

    company,
    filter: { is_invoice: false },
    orderId: edittedBooking?.order ?? "",
    enabled: Boolean(mode === "edit" && edittedBooking?.order),
  });
  const orderDetail: Order | undefined = useMemo(
    () =>
      getOrderDetailsQuery.isSuccess
        ? getOrderDetailsQuery.data.data
        : undefined,
    [getOrderDetailsQuery.data]
  );
  return {
    ordersList,
    isFetchingOrderList: retrieveOrdersQuery.isLoading,
    isSuccessFetchingOrderList: retrieveOrdersQuery.isSuccess,
    isErrorFetchingOrderList: retrieveOrdersQuery.isError,
    refetchOrderList,
    isRefetchingOrderList: retrieveOrdersQuery.isRefetching,
    fetchMoreOrder,
    isFetchingMoreOrder: retrieveOrdersQuery.isFetchingNextPage,
    contentWrapperRef,
    contentWrapperHeight,
    orderDetail,
    isFetchingOrderDetail: getOrderDetailsQuery.isLoading,
  };
};

export default useBookingDocketOrder;
