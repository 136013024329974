import { create } from "zustand";
import {
  Docket,
  IFullDocketDetailQueryFilterProp,
  LightDocket,
} from "../../Models/dockets";
import { FilterRetrieveProjectListWithDockets } from "../../Services/QueryHooks/projectQueryAPI";
import { produce } from "immer";

export type InboxFilterOption =
  | "Due Today"
  | "Due This Week"
  | "Due This Month";
export type NBMenu = "My Notices" | "All Notices" | "Archived";
export type NBGroupMode = "Projects" | "Assignee";

export const NB_ROW_SIZE = 35;
interface InboxStore {
  inboxFilterOptions: InboxFilterOption[];
  setInboxFilterOptions: (filters: InboxFilterOption[]) => void;
  addInboxFilter: (item: InboxFilterOption) => void;
  removeInboxFilter: (item: InboxFilterOption) => void;

  statusFilterValue: string[];
  setStatusFilterValue: (val: string) => void;

  clearInboxStore: () => void;
  isCompressView: boolean;
  setIsCompressView: (value: boolean) => void;

  showFullStatus: boolean;
  setShowFullStatus: (value: boolean) => void;

  inboxViewMode: NBMenu;
  setInboxViewMode: (mode: NBMenu) => void;

  inboxGroupMode: NBGroupMode;
  setInboxGroupMode: (mode: NBGroupMode) => void;
  filterDocketListQuery: IFullDocketDetailQueryFilterProp;
  setFilterDocketListQuery: (
    item: Partial<IFullDocketDetailQueryFilterProp>
  ) => void;
  filterProjectListWithDockets: FilterRetrieveProjectListWithDockets;
  setFilterProjectListWithDockets: (
    data: Partial<FilterRetrieveProjectListWithDockets>
  ) => void;
  currentEdittedDocket: LightDocket | undefined;
  setCurrentEdittedDocket: (item: LightDocket | undefined) => void;

  deletedDocket: LightDocket | undefined;
  setDeletedDocket: (item: LightDocket | undefined) => void;
}

const sessionStorageFilters = sessionStorage.getItem("dueFilter");
const initInboxFilters = (
  sessionStorageFilters ? JSON.parse(sessionStorageFilters) : []
) as InboxFilterOption[];

export const useInboxStore = create<InboxStore>((set, get) => ({
  inboxFilterOptions: initInboxFilters,
  setInboxFilterOptions: (filters: InboxFilterOption[]) => {
    sessionStorage.setItem("dueFilter", JSON.stringify(filters));
    set({ inboxFilterOptions: filters });
  },

  statusFilterValue: [],
  setStatusFilterValue: (val: string) => {
    const index = get().statusFilterValue.indexOf(val);
    if (index > -1) {
      set((state) => {
        return produce(state, (draft) => {
          draft.statusFilterValue.splice(index, 1);
          return draft;
        });
      });
    } else {
      set((state) => {
        return produce(state, (draft) => {
          draft.statusFilterValue.push(val);
          return draft;
        });
      });
    }
  },

  addInboxFilter: (item: InboxFilterOption) =>
    set((state) => {
      return produce(state, (draft) => {
        draft.inboxFilterOptions = [item];
        return draft;
      });
    }),
  removeInboxFilter: (item: InboxFilterOption) =>
    set((state) =>
      produce(state, (draft) => {
        draft.inboxFilterOptions = draft.inboxFilterOptions.filter(
          (old) => old !== item
        );
      })
    ),

  clearInboxStore: () => set({ inboxFilterOptions: [] }),
  isCompressView: false,
  setIsCompressView: (value: boolean) => set({ isCompressView: value }),

  inboxViewMode: "My Notices",
  setInboxViewMode: (mode: NBMenu) =>
    set((state) =>
      produce(state, (draft) => {
        draft.inboxViewMode = mode;
        return draft;
      })
    ),

  inboxGroupMode: "Projects",
  setInboxGroupMode: (mode: NBGroupMode) =>
    set((state) =>
      produce(state, (draft) => {
        draft.inboxGroupMode = mode;
        return draft;
      })
    ),
  filterDocketListQuery: {
    view: "noticeboard",
    due:
      initInboxFilters.length > 0
        ? initInboxFilters.includes("Due Today")
          ? "today"
          : initInboxFilters.includes("Due This Week")
          ? "week"
          : initInboxFilters.includes("Due This Month")
          ? "month"
          : undefined
        : undefined,
    order_by: ["ball_in_court_name", "-is_sticky", "-status__index"],
  },
  setFilterDocketListQuery: (
    filter: Partial<IFullDocketDetailQueryFilterProp>
  ) => {
    const newFilter: IFullDocketDetailQueryFilterProp = {
      ...get().filterDocketListQuery,
      ...filter,
    };
    set({ filterDocketListQuery: newFilter });
    sessionStorage.setItem("inboxFilters", JSON.stringify(newFilter));
  },
  filterProjectListWithDockets: {
    view: "noticeboard",
    due:
      initInboxFilters.length > 0
        ? initInboxFilters.includes("Due Today")
          ? "today"
          : initInboxFilters.includes("Due This Week")
          ? "week"
          : initInboxFilters.includes("Due This Month")
          ? "month"
          : undefined
        : undefined,
  },
  setFilterProjectListWithDockets: (
    data: Partial<FilterRetrieveProjectListWithDockets>
  ) =>
    set((state) =>
      produce(state, (draft: InboxStore) => {
        draft.filterProjectListWithDockets = {
          ...draft.filterProjectListWithDockets,
          ...data,
        };
        return draft;
      })
    ),
  currentEdittedDocket: undefined,
  setCurrentEdittedDocket: (item: LightDocket | undefined) =>
    set((state) => ({
      currentEdittedDocket: item,
    })),
  showFullStatus: false,
  setShowFullStatus: (value: boolean) => {
    set({ showFullStatus: value });
  },

  deletedDocket: undefined,
  setDeletedDocket: (item: LightDocket | undefined) =>
    set({
      deletedDocket: item,
    }),
}));
