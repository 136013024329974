import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import {
  StyledBudgetPoperMenuItem,
  StyledBudgetRenameTextField,
  StyledBudgetRowPopperMenu,
} from "./BudgetStyleComponent";
import { shallow } from "zustand/shallow";

import {
  CircularProgress,
  ClickAwayListener,
  Grow,
  Popper,
} from "@mui/material";
import { MarkBudgetCompleteIcon, RenameBudgetIcon } from "./BudgetIcon";
import useBudgetRowPopperMenu from "./Hooks/useBudgetRowPopperMenu";
import { AnimatePresence } from "framer-motion";
import { useRef } from "react";
import { editRgbaAlpha } from "../../Utilities/FunctionUtilities";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import PushPinRoundedIcon from "@mui/icons-material/PushPinRounded";
import { LightDocket } from "../../Models/dockets";
type Props = {
  currentFocusedDocket: LightDocket | undefined;
  anchorBudgetMenuEl: SVGSVGElement | null;
  handleCloseBudgetMenu: () => void;
};

const BudgetRowPopperMenu = ({
  currentFocusedDocket,
  anchorBudgetMenuEl,
  handleCloseBudgetMenu,
}: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }),
    shallow
  );
  const canBeOpen = Boolean(currentFocusedDocket && anchorBudgetMenuEl);
  const id = canBeOpen ? "budget-menu-popper" : undefined;
  const renameInputRef = useRef<HTMLInputElement>(null);
  const {
    showRenameInput,
    setShowRenameInput,
    newBudgetNameText,
    setNewBudgetNameText,
    handleRenameBudget,
    isUpdatingBudget,
    handleMarkCompleteBudget,
    handlePinBudget,
    handleDeleteBudget,
  } = useBudgetRowPopperMenu({
    handleCloseBudgetMenu,
    currentFocusedDocket,
    renameInputRef,
  });
  return (
    <Popper
      id={id}
      open={Boolean(currentFocusedDocket)}
      anchorEl={anchorBudgetMenuEl}
      transition
      //   disablePortal
      placement="auto-end"
    >
      {({ TransitionProps }) => (
        <ClickAwayListener onClickAway={handleCloseBudgetMenu}>
          <Grow {...TransitionProps} timeout={350}>
            <StyledBudgetRowPopperMenu
              elevation={4}
              layout="position"
              transition={{
                duration: 0.2,
                damping: 16,
              }}
            >
              <span className="menu-title-text">
                # Docket Menu
                {!showRenameInput && isUpdatingBudget && (
                  <CircularProgress
                    size="1.6rem"
                    style={{
                      color: doxleThemeColor.primaryFontColor,
                      marginLeft: 4,
                    }}
                  />
                )}
              </span>

              <StyledBudgetPoperMenuItem
                onClick={() => setShowRenameInput(true)}
              >
                <RenameBudgetIcon {...doxleThemeColor} />

                <span className="menu-item-text">Rename Docket</span>
              </StyledBudgetPoperMenuItem>
              <AnimatePresence>
                {showRenameInput && (
                  <StyledBudgetRenameTextField
                    placeholder="New budget name..."
                    initial={{
                      opacity: 0,
                      y: -5,
                    }}
                    animate={{
                      opacity: 1,
                      y: 0,
                    }}
                    transition={{
                      duration: 0.2,
                    }}
                    exit={{
                      opacity: 0,
                      y: -5,
                    }}
                    $themeColor={doxleThemeColor}
                    $doxleFont={doxleFont}
                    autoFocus
                    onFocus={(event) => event.target.select()}
                    value={newBudgetNameText}
                    onChange={(event) =>
                      setNewBudgetNameText(event.target.value)
                    }
                    InputProps={{
                      endAdornment: isUpdatingBudget ? (
                        <CircularProgress
                          size="1.6rem"
                          style={{ color: doxleThemeColor.primaryFontColor }}
                        />
                      ) : null,
                    }}
                    onKeyDown={(event) => {
                      if (event.nativeEvent.key === "Enter") {
                        event.preventDefault();
                        handleRenameBudget();
                      } else if (event.nativeEvent.key === "Escape") {
                        event.preventDefault();
                        setNewBudgetNameText("");
                      }
                    }}
                  />
                )}
              </AnimatePresence>

              <StyledBudgetPoperMenuItem onClick={handleMarkCompleteBudget}>
                <MarkBudgetCompleteIcon {...doxleThemeColor} />

                <span className="menu-item-text">
                  {currentFocusedDocket
                    ? currentFocusedDocket.completed
                      ? "Mark as incomplete"
                      : "Mark as complete"
                    : "Mark as complete"}
                </span>
              </StyledBudgetPoperMenuItem>

              <StyledBudgetPoperMenuItem onClick={handlePinBudget}>
                {/* <PinBudgetIcon
                  themeColor={doxleThemeColor}
                  isSticky={
                    currentFocusedDocket ? currentFocusedDocket.isSticky : false
                  }
                /> */}

                <PushPinRoundedIcon
                  sx={{
                    color:
                      currentFocusedDocket && currentFocusedDocket.isSticky
                        ? "red"
                        : doxleThemeColor.primaryFontColor,

                    fontSize: "1.6rem",
                  }}
                />

                <span
                  className="menu-item-text"
                  style={{
                    color:
                      currentFocusedDocket && currentFocusedDocket.isSticky
                        ? "red"
                        : editRgbaAlpha({
                            rgbaColor: doxleThemeColor.primaryFontColor,
                            alpha: "0.7",
                          }),
                  }}
                >
                  {currentFocusedDocket && currentFocusedDocket.isSticky
                    ? "Unpin Docket"
                    : "Pin Docket"}
                </span>
              </StyledBudgetPoperMenuItem>

              <StyledBudgetPoperMenuItem
                onClick={handleDeleteBudget}
                $hoverColor="rgba(255,0,0,0.1)"
              >
                <DeleteOutlineRoundedIcon
                  sx={{
                    color: "red",

                    fontSize: "1.6rem",
                  }}
                />

                <span
                  className="menu-item-text"
                  style={{
                    color: "red",
                  }}
                >
                  Delete Budget
                </span>
              </StyledBudgetPoperMenuItem>
            </StyledBudgetRowPopperMenu>
          </Grow>
        </ClickAwayListener>
      )}
    </Popper>
  );
};

export default BudgetRowPopperMenu;
