import React, { useCallback, useRef, useState } from "react";
import ScopeOfWorkAPI, {
  FilterRetrieveScopeItemQuery,
} from "../QueryAPI/scopeOfWorkQueryAPI";
import {
  NEW_SCOPE_ITEM_TEMPLATE,
  ScopeOfWorkGroup,
  ScopeOfWorkItem,
} from "../Model/scopeOfWorks";
import { useDoxleCurrentContextStore } from "../../DoxleGeneralStore/useDoxleCurrentContext";
import { shallow } from "zustand/shallow";
import useDoxleNotificationStore from "../../DoxleGeneralStore/useDoxleNotificationStore";

type Props = {
  filterScopeItemQuery: FilterRetrieveScopeItemQuery;
  scopeGroup: ScopeOfWorkGroup;
};

interface AddScopeItem {
  handleAddScopeItem: () => void;
  newScopeItemTitle: string;
  setNewScopeItemTitle: React.Dispatch<React.SetStateAction<string>>;
  onKeyDownDescriptionField: (
    event: React.KeyboardEvent<HTMLDivElement>
  ) => void;
  newTitleInputRef: React.RefObject<HTMLInputElement>;
  isAddingItem: boolean;
}
const useAddScopeItem = ({
  filterScopeItemQuery,
  scopeGroup,
}: Props): AddScopeItem => {
  const [newScopeItemTitle, setNewScopeItemTitle] = useState<string>("");
  const { company } = useDoxleCurrentContextStore(
    (state) => ({ company: state.currentCompany }),
    shallow
  );
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );

  //# add scope item query
  const addScopeItemQuery = ScopeOfWorkAPI.useAddScopeItemQuery({
    showNotification,
    company,
    filter: filterScopeItemQuery,
  });

  const handleAddScopeItem = useCallback(() => {
    const newScopeItem: ScopeOfWorkItem = NEW_SCOPE_ITEM_TEMPLATE({
      scopeGroup: filterScopeItemQuery.scopeGroupId,
      company: company?.companyId ?? "",
      scopeItemDescription: newScopeItemTitle,
      docket: scopeGroup.docket,
      project: scopeGroup.project,
    });
    addScopeItemQuery.mutate(newScopeItem);
    setNewScopeItemTitle("");
  }, [newScopeItemTitle, scopeGroup]);

  const newTitleInputRef = useRef<HTMLInputElement>(null);
  const onKeyDownDescriptionField = (
    event: React.KeyboardEvent<HTMLDivElement>
  ) => {
    if (
      event.nativeEvent.key === "Enter" &&
      !event.shiftKey &&
      !event.ctrlKey
    ) {
      event.preventDefault();
      handleAddScopeItem();
    } else if (event.nativeEvent.key === "Escape" && !newScopeItemTitle) {
      newTitleInputRef.current?.blur();
    } else if (event.nativeEvent.key === "Escape" && newScopeItemTitle)
      setNewScopeItemTitle("");
  };

  return {
    handleAddScopeItem,
    newScopeItemTitle,
    setNewScopeItemTitle,
    onKeyDownDescriptionField,
    newTitleInputRef,
    isAddingItem: addScopeItemQuery.isLoading,
  };
};

export default useAddScopeItem;
