import React from "react";
import {
  StyledDoxle404Screen,
  StyledGoHomepageButton,
} from "./StyledDoxle404Screen";
import { Doxle404Banner, DoxleVectorIcon } from "./Doxle404ScreenIcons";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";

type Props = {};

const Doxle404Screen = ({}: Props) => {
  const doxleThemeColor = useDoxleThemeStore(
    (state) => state.doxleThemeColor,
    shallow
  );

  return (
    <StyledDoxle404Screen>
      <Doxle404Banner themeColor={doxleThemeColor} />
      <p>Apologies, something has gone wrong, please email us - help@doxle.com </p>
      <StyledGoHomepageButton><DoxleVectorIcon themeColor={doxleThemeColor}></DoxleVectorIcon>Go to homepage</StyledGoHomepageButton>
    </StyledDoxle404Screen>
  );
};

export default Doxle404Screen;
