import React from "react";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import { DoxleFile, DoxleFolder } from "../../Models/files";
import GridFileItem from "./GridFileItem";

import GridFolderItem from "./GridFolderItem";
import { StyledGridItemWrapper } from "./StyledFilesBodyComponent";

type props = {
  gridItem: DoxleFile | DoxleFolder;
};

function GridFolderFiles({ gridItem }: props) {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore((state) => ({
    doxleThemeColor: state.doxleThemeColor,
    doxleFont: state.doxleFont,
  }));

  if ("folderId" in gridItem) {
    return (
      <StyledGridItemWrapper $themeColor={doxleThemeColor}>
        <GridFolderItem gridFolderItem={gridItem} />
      </StyledGridItemWrapper>
    );
  } else {
    return (
      <StyledGridItemWrapper $themeColor={doxleThemeColor}>
        <GridFileItem gridFileItem={gridItem} />
      </StyledGridItemWrapper>
    );
  }
}

export default GridFolderFiles;
