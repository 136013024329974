import React, { useEffect, useMemo, useState } from "react";
import { useDoxleAuthStore } from "../../../DoxleGeneralStore/useDoxleAuthStore";
import { shallow } from "zustand/shallow";
import { useDoxleCurrentContextStore } from "../../../DoxleGeneralStore/useDoxleCurrentContext";
import { XeroTrackingAPI } from "../../../Services/QueryHooks/trackingXeroAPI";
import { XeroTrackingOption } from "../../../Models/xeroTracking";
import { NewProject } from "../../../Models/project";
import { produce } from "immer";
import AccountMappingQueryAPI from "../../../Xero/QueryHooks/AccountsQueryAPI";
import {ProjectTrackingOption} from "../../../Xero/Models/account";

type Props = {
  setNewProject: React.Dispatch<React.SetStateAction<NewProject>>;
};

interface AddProjectXeroTracking {
  trackingOptions: ProjectTrackingOption[];
  isFetchingTrackingOptions: boolean;
  isSuccessFetchingTrackingOptions: boolean;
  isErrorFetchingTrackingOptions: boolean;
  setSearchTrackingText: React.Dispatch<React.SetStateAction<string>>;
}
const useAddProjectXeroTracking = ({
  setNewProject,
}: Props): AddProjectXeroTracking => {
  const [searchTrackingText, setSearchTrackingText] = useState<string>("");

  const retrieveTrackingOptions = AccountMappingQueryAPI.useRetrieveTrackingOptions();

  const trackingOptions = useMemo(
    () =>
      retrieveTrackingOptions.isSuccess
        ? retrieveTrackingOptions.data.data.filter((option) =>
            option.trackingOptionName.toLowerCase().includes(searchTrackingText.toLowerCase())
          )
        : [],
    [searchTrackingText, retrieveTrackingOptions.data]
  );

  useEffect(() => {
    const timeout = setTimeout(() => {
      setNewProject(
        produce((draft) => {
          draft.trackingName = searchTrackingText;
          draft.trackingId = null;
          return draft;
        })
      );
    }, 300);

    return () => {
      clearTimeout(timeout);
    };
  }, [searchTrackingText]);

  return {
    trackingOptions,
    isFetchingTrackingOptions: retrieveTrackingOptions.isLoading,
    isSuccessFetchingTrackingOptions: retrieveTrackingOptions.isSuccess,
    isErrorFetchingTrackingOptions: retrieveTrackingOptions.isError,
    setSearchTrackingText,
  };
};

export default useAddProjectXeroTracking;
