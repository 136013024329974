import React from "react";
import { Estimate, EstimatesFilters } from "../../../Models/estimatesFilters";
import { useDoxleCurrentContextStore } from "../../../../DoxleGeneralStore/useDoxleCurrentContext";
import { shallow } from "zustand/shallow";
import { formEstimateListQkey } from "../../../QueryHooks/EstimatesQueryAPI";
import { useQueryClient } from "@tanstack/react-query";
import { produce } from "immer";
import { IApiPaginatedData } from "../../../../Models/axiosReturn";

type Props = {
  filter: EstimatesFilters;
  appendPos?: "start" | "end";
  overwrite?: boolean;
};

interface SetEstimateQueryData {
  handleRemoveEstimate: (deletedEstimateIds: string[]) => void;
  handleUpdateEstimate: (updatedEstimate: Estimate) => void;
  handleAddEstimate: (newBudget: Estimate) => void;
}
const useSetEstimateQueryData = ({
  filter,
  appendPos = "end",
  overwrite = true,
}: Props): SetEstimateQueryData => {
  const { currentCompany } = useDoxleCurrentContextStore(
    (state) => ({
      currentCompany: state.currentCompany,
    }),
    shallow
  );
  const qKey = formEstimateListQkey(filter, currentCompany);
  const queryClient = useQueryClient();
  const handleRemoveEstimate = (deletedEstimateIds: string[]) => {
    const queryData = queryClient.getQueryData(qKey);
    if (queryData && overwrite) {
      queryClient.setQueryData(qKey, (old: any) => {
        let pageIndexContainItems: Array<{
          pageIndex: number;
          lisOfIds: string[];
        }> = [];
        old.pages.forEach((page: any, index: number) => {
          const estimateData = page.data.results as Estimate[];

          let listOfMatchedIds: string[] = [];
          deletedEstimateIds.forEach((deletedId) => {
            if (
              estimateData.find((estimate) => estimate.estimateId === deletedId)
            )
              listOfMatchedIds.push(deletedId);
          });

          if (listOfMatchedIds.length > 0)
            pageIndexContainItems.push({
              pageIndex: index,
              lisOfIds: listOfMatchedIds,
            });
        });
        // (old.pages as any[]).forEach((page) => {
        //   if (page.data.results.find((estimateItem:Estimate)=>estimateItem.estimateId===))
        // });

        return produce(old, (draftOld: any) => {
          if (pageIndexContainItems.length > 0) {
            draftOld.pages = produce(draftOld.pages, (draftPages: any) => {
              pageIndexContainItems.forEach((pageContainItem) => {
                const { pageIndex, lisOfIds } = pageContainItem;

                draftPages[pageIndex].data.results = produce(
                  draftPages[pageIndex].data.results,
                  (draftResults: Estimate[]) => {
                    draftResults = old.pages[pageIndex].data.results.filter(
                      (estimateItem: Estimate) =>
                        !lisOfIds.includes(estimateItem.estimateId)
                    );

                    return draftResults;
                  }
                );
              });

              return draftPages;
            });
          } else queryClient.invalidateQueries(qKey);
          return draftOld;
        });
      });
    } else queryClient.invalidateQueries(qKey);
  };

  const handleUpdateEstimate = (updatedEstimate: Estimate) => {
    const queryData = queryClient.getQueryData(qKey);
    if (overwrite && queryData) {
      queryClient.setQueryData(qKey, (old: any) => {
        let pageIndexContainItem: number = old.pages.findIndex((page: any) =>
          Boolean(
            page.data.results.find(
              (estimate: Estimate) =>
                estimate.estimateId === updatedEstimate.estimateId
            ) !== undefined
          )
        );
        if (pageIndexContainItem !== -1) {
          const itemIndex = (
            old.pages[pageIndexContainItem].data as IApiPaginatedData<Estimate>
          ).results.findIndex(
            (estimate) => estimate.estimateId === updatedEstimate.estimateId
          );
          return produce(old, (draft: any) => {
            if (itemIndex !== -1) {
              (
                draft.pages[pageIndexContainItem]
                  .data as IApiPaginatedData<Estimate>
              ).results[itemIndex] = updatedEstimate;
              return draft;
            } else queryClient.invalidateQueries(qKey);
          });
        } else queryClient.invalidateQueries(qKey);
      });
    } else queryClient.invalidateQueries(qKey);
  };

  const handleAddEstimate = (newBudget: Estimate) => {
    const budgetData = queryClient.getQueryData(qKey);
    if (budgetData && overwrite) {
      queryClient.setQueryData(qKey, (old: any) => {
        if (old) {
          const lengthOfPages = old.pages.length;
          return produce(old, (draftOld: any) => {
            draftOld.pages = produce(draftOld.pages, (draftPages: any) => {
              if (appendPos === "start") {
                (
                  draftPages[0].data as IApiPaginatedData<Estimate>
                ).results.unshift(newBudget);
              } else {
                (
                  draftPages[lengthOfPages - 1]
                    .data as IApiPaginatedData<Estimate>
                ).results.push(newBudget);
              }

              return draftPages;
            });

            return draftOld;
          });
        } else return queryClient.invalidateQueries(qKey);
      });
    } else queryClient.invalidateQueries(qKey);
  };
  return {
    handleRemoveEstimate,
    handleUpdateEstimate,
    handleAddEstimate,
  };
};

export default useSetEstimateQueryData;
