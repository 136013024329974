import React, { useCallback, useState } from "react";
import { FolderUploadDetails } from "./useListViewRight";
import { useDoxleCurrentContextStore } from "../../DoxleGeneralStore/useDoxleCurrentContext";
import { useShallow } from "zustand/react/shallow";
import useDoxleNotificationStore from "../../DoxleGeneralStore/useDoxleNotificationStore";
import { useEditDocketSideScreenStore } from "../../CoreContent/EditDocketSideScreen/EditDocketSideScreenStore/useEditDocketSideScreenStore";
import { useFileStore } from "../Store/useFileStore";
import useGetFileInsideFolder from "./useGetFileInsideFolder";
import FilesQueryAPI, {
  IAddFileMutateProps,
  getFileMutateKey,
} from "../QueryHooks/FileStorageQueryAPI";
import { DropEvent, FileRejection, useDropzone } from "react-dropzone";
import { DOXLE_ACCEPTED_MIME } from "../../Utilities/MimeFileType";
import { useIsMutating } from "@tanstack/react-query";

const useGridFolderFilesView = () => {
  const [pendingFolderUpload, setPendingFolderUpload] =
    useState<FolderUploadDetails | null>(null);
  const { company, currentProject, addCurrentErrorFiles } =
    useDoxleCurrentContextStore(
      useShallow((state) => ({
        company: state.currentCompany,
        currentProject: state.currentProject,
        addCurrentErrorFiles: state.addCurrentErrorFiles,
      }))
    );
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification
  );
  const { currentFolder, setCurrentFolder, currentLevel, filterRootFileQuery } =
    useFileStore(
      useShallow((state) => ({
        currentFolder: state.currentFolder,
        setCurrentFolder: state.setCurrentFolder,
        currentLevel: state.currentLevel,
        filterRootFileQuery: state.filterRootFileQuery,
      }))
    );
  const { edittedDocket, sidePeek } = useEditDocketSideScreenStore(
    useShallow((state) => ({
      edittedDocket: state.edittedDocket,
      sidePeek: !state.isFullScreenMode,
    }))
  );

  const addFileQuery = FilesQueryAPI.useAddFileQuery({
    company,
    showNotification,
    filter: filterRootFileQuery, //* checking if add file to selected folder or in level scope
  });

  const onDrop = useCallback(
    <T extends File>(
      acceptedFiles: T[],
      fileRejections: FileRejection[],
      event: DropEvent
    ) => {
      //* collect uploaded data based on level
      const uploadedData: IAddFileMutateProps = { files: acceptedFiles };
      if (currentFolder) uploadedData.folderId = currentFolder.folderId;
      else if (currentLevel === "Docket" && edittedDocket) {
        uploadedData.docketId = edittedDocket.docketPk;
        uploadedData.projectId = edittedDocket.project ?? undefined;
      } else if (currentLevel === "Project" && currentProject)
        uploadedData.projectId = currentProject.projectId;
      addFileQuery.mutate(uploadedData);
      console.log("fileRejections", fileRejections);
      if (fileRejections.length > 0) addCurrentErrorFiles(fileRejections);
    },
    [currentFolder, currentLevel, edittedDocket, currentProject]
  );

  const { getRootProps } = useDropzone({
    onDrop,
    accept: DOXLE_ACCEPTED_MIME,
    maxSize: 50000000,
  });
  interface allowedType {
    mimePrefix: string;
    extensions: string[];
  }
  const allowedFileTypes: allowedType[] = [
    {
      mimePrefix: "image/",
      extensions: [".png", ".jpg", ".jpeg", ".bmp", ".webp"],
    },
    {
      mimePrefix: "application/",
      extensions: [".pdf", ".xls", ".xlsx", ".doc", ".docx", ".ppt", ".pptx"],
    },
    { mimePrefix: "text/", extensions: [".txt", ".csv", ".rtf"] },
    { mimePrefix: "video/", extensions: [".mp4", ".mpeg"] },
  ];
  const getFile = async (
    fileEntry: FileSystemFileEntry
  ): Promise<File | void> => {
    try {
      return new Promise((resolve, reject) => fileEntry.file(resolve, reject));
    } catch (err) {
      console.log(err);
    }
  };
  const allowedFileType = (file: File): boolean => {
    const mimeIndex: number = allowedFileTypes.findIndex((type) =>
      file.type.startsWith(type.mimePrefix)
    );
    if (mimeIndex === -1) return false;
    const extIndex: number = allowedFileTypes[mimeIndex].extensions.findIndex(
      (ext) => file.name.toLowerCase().endsWith(ext.toLowerCase())
    );
    return extIndex >= 0;
  };

  const handleUploadFiles = useCallback(
    (files?: File[]) => {
      let folderName = "";
      if (!files && pendingFolderUpload) {
        files = pendingFolderUpload.files;
        folderName = pendingFolderUpload.folderName;
      }
      if (!files || files.length === 0) return;
      const uploadedData: IAddFileMutateProps = { files };
      if (folderName) uploadedData.createFolderName = folderName;
      if (currentFolder) uploadedData.folderId = currentFolder.folderId;
      else if (currentLevel === "Docket" && edittedDocket) {
        uploadedData.docketId = edittedDocket.docketPk;
        uploadedData.projectId = edittedDocket.project ?? undefined;
      } else if (currentLevel === "Project" && currentProject)
        uploadedData.projectId = currentProject.projectId;
      addFileQuery.mutate(uploadedData);
    },
    [
      currentFolder,
      currentLevel,
      edittedDocket,
      currentProject,
      addFileQuery,
      pendingFolderUpload,
    ]
  );

  const extractFolderFiles = async (folderEntry: FileSystemDirectoryEntry) => {
    const folderName = folderEntry.name;
    const reader = await folderEntry?.createReader();
    reader?.readEntries((entries) => {
      entries.forEach((entry, index) => {
        if (entry.isFile)
          getFile(entry as FileSystemFileEntry).then((file) => {
            if (file && allowedFileType(file))
              setPendingFolderUpload((prevState) => ({
                folderName,
                files: [...(prevState?.files ?? []), file],
              }));
          });
      });
    });
  };

  const handleFileFolderDrop = async (e: React.DragEvent<HTMLDivElement>) => {
    let fileSystemFolderEntry: undefined | FileSystemDirectoryEntry = undefined;
    let fileSystemEntries: FileSystemFileEntry[] = [];

    // Parse first level objects
    for (let i = 0; i < e.dataTransfer.items.length; i++) {
      const item = e.dataTransfer.items[i].webkitGetAsEntry();
      if (item?.isFile) fileSystemEntries.push(item as FileSystemFileEntry);
      if (item?.isDirectory) {
        if (fileSystemFolderEntry) continue; // Avoid uploading multiple folders
        fileSystemFolderEntry = item as FileSystemDirectoryEntry;
      }
    }
    // If folder was found, extract contents and confirm with user
    if (fileSystemFolderEntry) extractFolderFiles(fileSystemFolderEntry);
    // Else read loose files and upload immediately
    else {
      const files: File[] = [];
      const errorFiles: FileRejection[] = [];
      for (let i = 0; i < fileSystemEntries.length; i++) {
        const file = await getFile(fileSystemEntries[i]);
        if (file) {
          if (allowedFileType(file)) {
            if (file.size > 50000000)
              errorFiles.push({
                file,
                errors: [
                  {
                    message: "File size is greater than 50Mb",
                    code: "file-too-large",
                  },
                ],
              });
            else files.push(file);
          } else
            errorFiles.push({
              file,
              errors: [
                {
                  message: "File type not allowed",
                  code: "file-invalid-type",
                },
              ],
            });
        }
      }
      handleUploadFiles(files);
      if (errorFiles.length > 0) addCurrentErrorFiles(errorFiles);
    }
  };

  const isAddingFileRoot =
    useIsMutating({
      mutationKey: getFileMutateKey("add"),
      predicate: (query) =>
        Boolean(
          !currentFolder &&
            !(query.state.variables as IAddFileMutateProps).folderId
        ),
    }) > 0;
  return {
    handleFileFolderDrop,
    pendingFolderUpload,
    setPendingFolderUpload,
    handleUploadFiles,

    isAddingFileRoot,
  };
};

export default useGridFolderFilesView;
