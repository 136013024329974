import { SVGProps } from "react";
import { DoxleThemeColor } from "../../DoxleGeneralStore/useDoxleThemeStore";


export interface CustomSVGProps extends SVGProps<SVGSVGElement> {
  themeColor: DoxleThemeColor;
}

export const OurStoryIcon = ({ themeColor, ...props }: CustomSVGProps) => (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width={34}
    height={39}
    fill="none"
    {...props}
  >
    <path
      fill={themeColor.primaryFontColor}
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.7}
      d="M10.5 18.575c-.828 0-1.5.621-1.5 1.387 0 .767.672 1.388 1.5 1.388h13c.828 0 1.5-.621 1.5-1.388 0-.766-.672-1.387-1.5-1.387h-13Zm0 5.088c-.828 0-1.5.62-1.5 1.387 0 .766.672 1.387 1.5 1.387h13c.828 0 1.5-.62 1.5-1.387 0-.766-.672-1.387-1.5-1.387h-13Zm0 5.087c-.828 0-1.5.621-1.5 1.387 0 .767.672 1.388 1.5 1.388h13c.828 0 1.5-.621 1.5-1.387 0-.767-.672-1.388-1.5-1.388h-13Zm9.67-26.666 11.658 10.782C32.578 13.56 33 14.5 33 15.482V34.3c0 2.042-1.792 3.7-4 3.7H5c-2.208 0-4-1.658-4-3.7V4.7C1 2.658 2.792 1 5 1h12.344c.056 0 .11.007.164.014.04.005.079.01.118.012.43.028.854.103 1.248.255.115.045.224.104.332.162l.1.053c.03.015.063.03.095.044.055.025.11.05.161.082.156.098.296.214.438.333l.073.054c.033.023.067.047.097.075ZM29 35.225c.552 0 1-.416 1-.925V15.8h-9c-2.208 0-4-1.658-4-3.7V3.775H5c-.552 0-1 .416-1 .925v29.6c0 .509.448.925 1 .925h24Zm-1.244-22.2L20 5.849V12.1c0 .509.448.925 1 .925h6.756Z"
    />
  </svg>
);
