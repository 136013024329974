import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDoxleAuthStore } from "../../../DoxleGeneralStore/useDoxleAuthStore";
import { shallow } from "zustand/shallow";
import { useDoxleCurrentContextStore } from "../../../DoxleGeneralStore/useDoxleCurrentContext";
import UserQueryAPI from "../../../Services/QueryHooks/userQueryAPI";
import { User } from "../../../Models/user";

type Props = {};

interface AddProjectUser {
  userList: User[];
  showUserList: boolean;
  anchorUserListEl: HTMLElement | null;
  handleOpenUserList: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => void;
  handleCloseUserList: () => void;
  isFetchingUserList: boolean;
  isSuccessFetchingUserList: boolean;
  isErrorFetchingUserList: boolean;
  displayList: boolean;
}
const useAddProjectUser = (props: Props): AddProjectUser => {
  const [showUserList, setShowUserList] = useState(false);
  const [anchorUserListEl, setAnchorUserListEl] =
    React.useState<null | HTMLElement>(null);
  const [displayList, setDisplayList] = useState<boolean>(false);
  const company = useDoxleCurrentContextStore(
    (state) => state.currentCompany,
    shallow
  );

  const getUserListQuery = UserQueryAPI.useGetUserList({
    company,
  });

  const userList = useMemo(
    () =>
      getUserListQuery.isSuccess
        ? getUserListQuery.data.pages.reduce((acc, data) => {
            return acc.concat(data.data.results);
          }, [] as User[])
        : [],
    [getUserListQuery.data?.pages]
  );

  const handleOpenUserList = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    setAnchorUserListEl(event.currentTarget);
    setShowUserList(true);
  };
  const handleCloseUserList = useCallback(() => {
    setAnchorUserListEl(null);
    setShowUserList(false);
  }, [setAnchorUserListEl, setShowUserList]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDisplayList(showUserList);
    }, 300);

    return () => {
      clearTimeout(timeout);
    };
  }, [showUserList]);

  return {
    userList,
    showUserList,
    anchorUserListEl,
    handleOpenUserList,
    handleCloseUserList,
    isFetchingUserList: getUserListQuery.isLoading,
    isSuccessFetchingUserList: getUserListQuery.isSuccess,
    isErrorFetchingUserList: getUserListQuery.isError,
    displayList,
  };
};

export default useAddProjectUser;
