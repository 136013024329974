import * as React from "react";

import ReactDatePicker from "react-datepicker";

import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import dayjs from "dayjs";
import "react-datepicker/dist/react-datepicker.css";
import "./datePicker.css";
import { StyledBookingDateRangePickerWrapper } from "./StyledComponentBookings";
import { IoChevronDownOutline } from "react-icons/io5";
import { forwardRef } from "react";
import { StyledDatePickerWrapper } from "../EditBookingDialog/Components/StyledEditBookingDialog";

export default function BookingDateRangePicker({
  onChange,
  startDate,
  endDate,
  isLoading,
  displayContainerStyle,
}: {
  onChange: (
    date: [Date | null, Date | null],
    event: React.SyntheticEvent<any, Event> | undefined
  ) => void;
  startDate: null | Date;
  endDate: null | Date;
  isLoading?: boolean;
  displayContainerStyle?: React.CSSProperties;
}) {
  const ref = React.useRef<HTMLDivElement>(null);
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore((state) => ({
    doxleThemeColor: state.doxleThemeColor,
    doxleFont: state.doxleFont,
  }));
  const CustomInput = forwardRef(
    (
      { onClick }: React.HTMLProps<HTMLDivElement>,
      ref: React.Ref<HTMLDivElement>
    ) => (
      <StyledBookingDateRangePickerWrapper
        onClick={onClick as any}
        ref={ref as any}
        $isNull={Boolean(!startDate && !endDate)}
        style={displayContainerStyle}
      >
        <span className="content-text">
          {startDate ? dayjs(startDate).format("DD MMM YYYY") : "*"} -{" "}
          {endDate ? dayjs(endDate).format("DD MMM YYYY") : "*"}
        </span>

        <IoChevronDownOutline
          size={16}
          color={doxleThemeColor.primaryFontColor}
        />
      </StyledBookingDateRangePickerWrapper>
    )
  );

  return (
    <StyledDatePickerWrapper className="date-picker">
      <span className="label-text">Start - End Date </span>
      <ReactDatePicker
        selected={startDate}
        onChange={(dateRange, e) => {
          onChange(dateRange, e);
        }}
        startDate={startDate}
        endDate={endDate}
        selectsRange={true}
        showIcon={false}
        popperPlacement="top-end"
        customInput={<CustomInput />}
        withPortal={true}
        calendarStartDay={1}
      />
    </StyledDatePickerWrapper>
  );
}
