import React from "react";
import {
  StyledAddContactFieldWrapper,
  StyledAddContactForm,
  StyledAddContactTextfield,
  StyledSubmitAddButton,
} from "./StyledSelectContractorPopover";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import { FiPlus } from "react-icons/fi";
import useAddContactForm from "./Hooks/useAddContactForm";
import { produce } from "immer";
import { AnimatePresence, motion } from "framer-motion";
import {
  checkEmailValid,
  checkPhoneValid,
  checkValidABN,
  editRgbaAlpha,
} from "../../../Utilities/FunctionUtilities";
import ProgressScreen from "../../../Utilities/Lottie/ProgressScreen";

type Props = {
  handleCloseForm: () => void;
};

const AddContactForm = ({ handleCloseForm }: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }),
    shallow
  );
  const {
    shouldShowError,
    handleAddContact,
    newContact,
    setNewContact,
    isAddingContact,
  } = useAddContactForm({ handleCloseForm });
  return (
    <StyledAddContactForm
      animate={{
        y: [10, 0],
        opacity: [0, 1],
      }}
    >
      <StyledAddContactFieldWrapper
        $themeColor={doxleThemeColor}
        $doxleFont={doxleFont}
        layout="position"
      >
        <span className="field-label">Contact name</span>
        <StyledAddContactTextfield
          $themeColor={doxleThemeColor}
          $doxleFont={doxleFont}
          variant="standard"
          placeholder="Enter contact name..."
          value={newContact.name}
          onChange={(e) =>
            setNewContact(
              produce((draft) => {
                draft.name = e.target.value;
                return draft;
              })
            )
          }
        />
        <AnimatePresence>
          {shouldShowError && !newContact.name && (
            <motion.span
              className="error-text"
              animate={{
                x: [-5, 0],
                opacity: [0, 1],
              }}
              exit={{ x: [0, -5], opacity: [1, 0] }}
            >
              Please enter contact name!
            </motion.span>
          )}
        </AnimatePresence>
      </StyledAddContactFieldWrapper>

      <StyledAddContactFieldWrapper
        $themeColor={doxleThemeColor}
        $doxleFont={doxleFont}
        layout="position"
      >
        <span className="field-label">Email</span>
        <StyledAddContactTextfield
          $themeColor={doxleThemeColor}
          $doxleFont={doxleFont}
          variant="standard"
          placeholder="Enter email..."
          value={newContact.email}
          onChange={(e) =>
            setNewContact(
              produce((draft) => {
                draft.email = e.target.value;
                return draft;
              })
            )
          }
        />
        <AnimatePresence>
          {shouldShowError &&
            newContact.email &&
            !checkEmailValid(newContact.email) && (
              <motion.span
                className="error-text"
                animate={{
                  x: [-5, 0],
                  opacity: [0, 1],
                }}
                exit={{ x: [0, -5], opacity: [1, 0] }}
              >
                Invalid email
              </motion.span>
            )}
        </AnimatePresence>
      </StyledAddContactFieldWrapper>

      <StyledAddContactFieldWrapper
        $themeColor={doxleThemeColor}
        $doxleFont={doxleFont}
        layout="position"
      >
        <span className="field-label">Phone</span>
        <StyledAddContactTextfield
          $themeColor={doxleThemeColor}
          $doxleFont={doxleFont}
          variant="standard"
          placeholder="Enter phone..."
          value={newContact.phone}
          onChange={(e) =>
            setNewContact(
              produce((draft) => {
                draft.phone = e.target.value;
                return draft;
              })
            )
          }
        />
        {shouldShowError &&
          (!newContact.phone ||
            (newContact.phone && !checkPhoneValid(newContact.phone))) && (
            <motion.span
              className="error-text"
              animate={{
                x: [-5, 0],
                opacity: [0, 1],
              }}
              exit={{ x: [0, -5], opacity: [1, 0] }}
            >
              {!newContact.phone
                ? "Please enter phone number"
                : "Invalid phone"}
            </motion.span>
          )}
      </StyledAddContactFieldWrapper>

      <StyledAddContactFieldWrapper
        $themeColor={doxleThemeColor}
        $doxleFont={doxleFont}
        layout="position"
      >
        <span className="field-label">Address</span>
        <StyledAddContactTextfield
          $themeColor={doxleThemeColor}
          $doxleFont={doxleFont}
          variant="standard"
          placeholder="Enter address..."
          value={newContact.address}
          onChange={(e) =>
            setNewContact(
              produce((draft) => {
                draft.address = e.target.value;
                return draft;
              })
            )
          }
        />
        {shouldShowError && !newContact.address && (
          <motion.span
            className="error-text"
            animate={{
              x: [-5, 0],
              opacity: [0, 1],
            }}
            exit={{ x: [0, -5], opacity: [1, 0] }}
          >
            Please enter address
          </motion.span>
        )}
      </StyledAddContactFieldWrapper>

      <StyledAddContactFieldWrapper
        $themeColor={doxleThemeColor}
        $doxleFont={doxleFont}
        layout="position"
      >
        <span className="field-label">Abn</span>
        <StyledAddContactTextfield
          $themeColor={doxleThemeColor}
          $doxleFont={doxleFont}
          variant="standard"
          placeholder="Enter ABN..."
          value={newContact.abn}
          onChange={(e) =>
            setNewContact(
              produce((draft) => {
                draft.abn = e.target.value;
                return draft;
              })
            )
          }
        />
        {shouldShowError &&
          newContact.abn &&
          !checkValidABN(newContact.abn) && (
            <motion.span
              className="error-text"
              animate={{
                x: [-5, 0],
                opacity: [0, 1],
              }}
              exit={{ x: [0, -5], opacity: [1, 0] }}
            >
              Invalid ABN
            </motion.span>
          )}
      </StyledAddContactFieldWrapper>
      <StyledSubmitAddButton
        $themeColor={doxleThemeColor}
        $doxleFont={doxleFont}
        layout="position"
        onClick={handleAddContact}
      >
        <FiPlus
          color="inherit"
          size="max(1.8rem,18px)"
          style={{ marginRight: 4 }}
        />
        Add
      </StyledSubmitAddButton>

      {isAddingContact && (
        <ProgressScreen
          progressType="progress"
          progressText="Adding contact..."
          animationSize={70}
          containerStyle={{
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            position: "absolute",
            zIndex: 10,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: editRgbaAlpha({
              rgbaColor: doxleThemeColor.primaryContainerColor,
              alpha: "0.8",
            }),
          }}
          textStyle={{
            fontSize: "max(1.6rem,16px)",
            color: doxleThemeColor.primaryFontColor,
            marginTop: 8,
          }}
          hasBackDrop={true}
        />
      )}
    </StyledAddContactForm>
  );
};

export default AddContactForm;
