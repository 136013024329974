import React from "react";
import useDeleteSelectedQuotes from "./Hooks/useDeleteSelectedQuotes";
import DoxleDialogHelper from "../../../DoxleDesignPattern/DoxleDialogHelper/DoxleDialogHelper";
import { useDoxleQuoteStore } from "../../store/useDoxleQuoteStore";
import { shallow } from "zustand/shallow";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";

type Props = {};

const DeleteSelectedQuotes = (props: Props) => {
  const {
    showDialogDelete,
    handleDeleteSelectedQuotes,
    handleCloseDeleteDialog,
  } = useDeleteSelectedQuotes({});
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }),
    shallow
  );
  const { quoteRequestIds, quoteResponseIds } = useDoxleQuoteStore(
    (state) => ({
      quoteRequestIds: state.quoteRequestIds,
      quoteResponseIds: state.quoteResponseIds,
    }),
    shallow
  );
  return (
    <DoxleDialogHelper
      open={showDialogDelete}
      onClose={handleCloseDeleteDialog}
      title="Confirm delete quote items!"
      description={`${
        quoteRequestIds.length > 0
          ? `${quoteRequestIds.length} quote request${
              quoteRequestIds.length > 1 ? "s" : ""
            }`
          : ""
      } ${
        quoteRequestIds.length > 0 && quoteResponseIds.length > 0 ? "and " : ""
      } ${
        quoteResponseIds.length > 0
          ? `${quoteResponseIds.length} quote response${
              quoteResponseIds.length > 1 ? "s" : ""
            }`
          : ""
      } and all data belong to those items will also be deleted permanently, are you sure to proceed?`}
      dialogButtons={[
        {
          buttonText: "Cancel",
          buttonFunction: handleCloseDeleteDialog,
          buttonColor: doxleThemeColor.primaryContainerColor,
          btnStyleSx: {
            border: `1px solid ${doxleThemeColor.primaryDividerColor}`,
          },
          buttonTextStyle: {
            color: doxleThemeColor.primaryFontColor,
          },
        },
        {
          buttonText: "Confirm",
          buttonFunction: handleDeleteSelectedQuotes,
          btnStyleSx: {
            border: `1px solid ${doxleThemeColor.primaryDividerColor}`,
          },
        },
      ]}
    />
  );
};

export default DeleteSelectedQuotes;
