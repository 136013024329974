import Popover from "@mui/material/Popover";
import React from "react";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import { useShallow } from "zustand/react/shallow";

import { DropEvent, FileRejection, useDropzone } from "react-dropzone";
import { DOXLE_ACCEPTED_MIME } from "../../Utilities/MimeFileType";
import { ImImages } from "react-icons/im";
import { PiFilesLight, PiVideoLight } from "react-icons/pi";
import { StyledAttachmentPickerBtn } from "./StyledComponentBookings";

type Props = {
  anchorPopover: HTMLButtonElement | null;

  handleClosePopover: () => void;
  onDropFileImages: <T extends File>(
    acceptedFiles: T[],
    fileRejections: FileRejection[],
    event: DropEvent
  ) => void;
  onDropVideo: <T extends File>(
    acceptedFiles: T[],
    fileRejections: FileRejection[],
    event: DropEvent
  ) => void;
};

const CommentAttachmentPopover = ({
  anchorPopover,
  handleClosePopover,
  onDropFileImages,
  onDropVideo,
}: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    useShallow((state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }))
  );
  const canBeOpen = Boolean(anchorPopover);
  const id = canBeOpen ? "comment-attachment-popover" : undefined;
  const { getRootProps } = useDropzone({
    onDrop: onDropFileImages,
    accept: DOXLE_ACCEPTED_MIME,
    maxSize: 15000000,
    noDrag: true,
  });
  const { getRootProps: getVideoProps } = useDropzone({
    onDrop: onDropVideo,
    accept: { "video/*": [".mp4", ".mpeg", ".avi"] },
    maxSize: 15000000,
    noDrag: true,
    maxFiles: 1,
    multiple: false,
  });
  return (
    <Popover
      id={id}
      open={canBeOpen}
      anchorEl={anchorPopover}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      onClose={handleClosePopover}
      sx={{
        "& .MuiPopover-paper": {
          backgroundColor: doxleThemeColor.primaryContainerColor,
          border: `1px solid ${doxleThemeColor.primaryDividerColor}`,
          overflow: "hidden",
          boxShadow: "none",
          borderRadius: "8px",
          width: "180px",
          maxHeight: "max(20rem, 200px)",
          display: "flex",
          flexDirection: "column",
          padding: "10px 0px !important",
          alignItems: "center",
          justifyContent: "center",
        },
      }}
    >
      <StyledAttachmentPickerBtn {...getRootProps({})}>
        <PiFilesLight
          size="25px"
          color={doxleThemeColor.primaryFontColor}
          className="icon"
        />
        Files & Images
      </StyledAttachmentPickerBtn>

      <StyledAttachmentPickerBtn
        {...getVideoProps({
          sx: {
            borderBottomWidth: "0px !important",
          },
        })}
      >
        <PiVideoLight
          size="25px"
          color={doxleThemeColor.primaryFontColor}
          className="icon"
        />
        Video
      </StyledAttachmentPickerBtn>
    </Popover>
  );
};

export default CommentAttachmentPopover;
