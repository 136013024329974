import React from "react";
import { useDoxleThemeStore } from "../../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import { useProjectQAStore } from "../../Store/useProjectQAStore";
import {
  StyledQASignatureButton,
  StyledQASignatureDialog,
  StyledQASignatureDialogActions,
  StyledQASignatureDialogContent,
  StyledQASignatureDialogTitle,
  StyledSaveSignatureLoaderWrapper,
} from "./StyledComponentQAListSignature";
import { TransitionProps } from "@mui/material/transitions";
import Slide from "@mui/material/Slide";
import { editRgbaAlpha } from "../../../../Utilities/FunctionUtilities";
import DoxleCloseBtn from "../../../../DoxleDesignPattern/DoxleButtons/DoxleCloseBtn";
import useQAListSignature from "./Hooks/useQAListSignature";
import SignaturePad from "react-signature-canvas";
import LinearProgress from "@mui/material/LinearProgress";
import { useShallow } from "zustand/react/shallow";
type Props = {};

const QAListSignature = (props: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    useShallow((state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }))
  );
  const { edittedSignatureQAList, setEdittedSignatureQAList } =
    useProjectQAStore(
      useShallow((state) => ({
        edittedSignatureQAList: state.edittedSignatureQAList,
        setEdittedSignatureQAList: state.setEdittedSignatureQAList,
      }))
    );

  const {
    finalSignaturePath,
    signPadRef,
    onFinishDraw,
    handleClearSignature,
    handleSaveSignature,
    isSavingSignature,
  } = useQAListSignature({});
  const openDialog = Boolean(edittedSignatureQAList);
  return (
    <StyledQASignatureDialog
      TransitionComponent={Transition}
      $themeColor={doxleThemeColor}
      open={openDialog}
      onClose={() => {
        setEdittedSignatureQAList(undefined);
      }}
      PaperProps={{
        sx: {
          bgColor: doxleThemeColor.primaryContainerColor,
          borderRadius: "0.9rem",
        },
        elevation: 4,
      }}
      slotProps={{
        backdrop: {
          sx: {
            bgcolor: editRgbaAlpha({
              rgbaColor: doxleThemeColor.primaryBackgroundColor,
              alpha: "0.4",
            }),
          },
        },
      }}
      aria-describedby="qa-signature-dialog"
    >
      {isSavingSignature && (
        <StyledSaveSignatureLoaderWrapper
          $themeColor={doxleThemeColor}
          $doxleFont={doxleFont}
        >
          <LinearProgress
            className="loader-bar"
            sx={{
              "&.MuiLinearProgress-root": {
                backgroundColor: editRgbaAlpha({
                  rgbaColor: doxleThemeColor.doxleColor,
                  alpha: "0.3",
                }),
              },
              "& .MuiLinearProgress-bar": {
                backgroundColor: doxleThemeColor.doxleColor,
              },
              width: "50% !important",
            }}
          />
          <span className="loader-text">Saving signature...</span>
        </StyledSaveSignatureLoaderWrapper>
      )}
      <StyledQASignatureDialogTitle
        $themeColor={doxleThemeColor}
        $doxleFont={doxleFont}
      >
        Add Signature
        <DoxleCloseBtn
          onClick={() => {
            setEdittedSignatureQAList(undefined);
          }}
          sx={{
            width: "3rem",
            height: "3rem",
            borderRadius: "50%",
            padding: "0.2rem !important",
          }}
        />
      </StyledQASignatureDialogTitle>

      <StyledQASignatureDialogContent>
        <div className="preview-wrapper">
          <span className="preview-text">Preview</span>

          {finalSignaturePath && (
            <img className="image-signature" src={finalSignaturePath} />
          )}
        </div>
        <div className="canvas-wrapper">
          <span className="sign-text">Sign zone</span>

          <SignaturePad
            ref={signPadRef}
            canvasProps={{
              className: "signature-pad",
            }}
            onEnd={onFinishDraw}
            clearOnResize
          />
        </div>
      </StyledQASignatureDialogContent>

      <StyledQASignatureDialogActions $themeColor={doxleThemeColor}>
        <StyledQASignatureButton
          $bgColor={doxleThemeColor.primaryContainerColor}
          $textColor={doxleThemeColor.primaryFontColor}
          $hasBorder
          whileHover={{
            scale: 1.1,
          }}
          whileTap={{ scale: 1 }}
          onClick={handleClearSignature}
        >
          Clear
        </StyledQASignatureButton>

        <StyledQASignatureButton
          $bgColor={doxleThemeColor.doxleColor}
          $textColor={"white"}
          whileHover={{
            scale: 1.1,
          }}
          whileTap={{ scale: 1 }}
          onClick={handleSaveSignature}
          disabled={signPadRef.current?.isEmpty()}
        >
          Save
        </StyledQASignatureButton>
      </StyledQASignatureDialogActions>
    </StyledQASignatureDialog>
  );
};

export default QAListSignature;

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
