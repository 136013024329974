import React, { useCallback, useMemo } from "react";
import { ItemContent, TableVirtuoso, TableComponents } from "react-virtuoso";
import { shallow } from "zustand/shallow";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import {
  StyleQuotesTableHeaderRow,
  StyledQuotesCheckBox,
  StyledQuotesResponseHeader,
  StyledQuotesTableContainer,
  StyledQuotesTableHeaderItems,
  StyledQuoteTableScroller,
  StyledQuoteTableBody,
  StyledQuoteTableHead,
  StyledQuoteTableRow,
  StyledQuoteRequestsTableContainer,
} from "../MainStyledComponents";
import QuoteResponseRow from "./QuoteResponseTableRow";
import QuoteRequestRow from "./QuoteRequestRow";
import { QuotesCheckBoxIcon, QuotesCheckBoxUncheckedIconNo1 } from "../icons";
import { useDoxleQuoteStore } from "../../store/useDoxleQuoteStore";
import ListLoadingMore from "../../../Utilities/Lottie/ListLoadingMore";
import { SkeletonLoader } from "../SkeletonLoader";
import useQuoteRequestsTable from "./Hooks/useQuoteRequestsTable";
import DoxleEmptyPlaceHolder from "../../../DoxleDesignPattern/DoxleEmptyPlaceHolder/DoxleEmptyPlaceHolder";
import { QuoteRequest, QuoteResponse } from "../../models/quote";
import { Table, TableContainer } from "@mui/material";
import QuoteAttachmentPopper from "./QuoteAttachmentPopper";
import useGetQuoteRequestList from "../../CustomQueryHooks/useGetQuoteRequestList";
import useGetQuoteResponseList from "../../CustomQueryHooks/useGetQuoteResponseList";
import DeleteSelectedQuotes from "./DeleteSelectedQuotes";
import EmailSelectedQuotes from "./EmailSelectedQuotes";
import { Error404Banner } from "../../../DoxleDesignPattern/DoxleIcons/CommonIcons";
import { EmptyRequestBanner, EmptyResponseBanner} from "../QuotesIcon";

type QuoteRequestHeaderName =
  | "CONTRACTOR"
  | "QUOTE REQUESTED"
  | "QUOTE DUE DATE"
  | "QUOTE RESPONDED"
  | "QUOTE AMOUNT"
  | "QUOTE STATUS"
  | "ATTACHMENTS";
type QuoteResponseHeaderName =
  | "CONTRACTOR"
  | "DATE CREATED"
  | "CREATED BY"
  | "ACCEPTED BY"
  | "QUOTE AMOUNT"
  | "QUOTE STATUS"
  | "ATTACHMENTS";
interface IQuoteRequestHeader {
  name: QuoteRequestHeaderName;
  width?: number;
}
interface IQuoteResponseHeader {
  name: QuoteResponseHeaderName;
  width?: number;
}
export const quoteRequestHeaders: IQuoteRequestHeader[] = [
  {
    name: "CONTRACTOR",
    width: 215,
  },
  {
    name: "QUOTE REQUESTED",
  },
  {
    name: "QUOTE DUE DATE",
  },
  {
    name: "QUOTE RESPONDED",
  },
  {
    name: "QUOTE AMOUNT",
  },
  {
    name: "QUOTE STATUS",
  },
  {
    name: "ATTACHMENTS",
    width: 300,
  },
];

export const quoteResponseHeaders: IQuoteResponseHeader[] = [
  {
    name: "CONTRACTOR",
    width: 215,
  },
  {
    name: "DATE CREATED",
  },
  {
    name: "CREATED BY",
  },
  {
    name: "ACCEPTED BY",
  },
  {
    name: "QUOTE AMOUNT",
  },
  {
    name: "QUOTE STATUS",
  },
  {
    name: "ATTACHMENTS",
    width: 300,
  },
];

interface IQuoteRequestTableContext {
  selectedAttachment: string | null;
  setSelectedAttachment: React.Dispatch<React.SetStateAction<string | null>>;
}
interface IQuoteResponseTableContext {}
const QuoteRequestsTable = () => {
  const { THEME_COLOR, DOXLE_FONT } = useDoxleThemeStore((state) => ({
    THEME_COLOR: state.doxleThemeColor,
    DOXLE_FONT: state.doxleFont,
  }));

  const {
    quoteRequestIds,

    quoteResponseIds,
  } = useDoxleQuoteStore(
    (state) => ({
      quoteRequestIds: state.quoteRequestIds,

      quoteResponseIds: state.quoteResponseIds,
    }),
    shallow
  );
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }),
    shallow
  );
  const {
    handleSelectAllQuoteRequests,
    handleSelectAllQuoteResponse,

    selectedAttachment,
    setSelectedAttachment,
  } = useQuoteRequestsTable({});
  const {
    quoteRequestList,
    isFetchingQuoteRequest,
    isSuccessFetchingQuoteRequest,
    isErrorFetchingQuoteRequest,

    fetchNextPageRequest,
    isFetchingNextPageRequest,
  } = useGetQuoteRequestList({});

  const {
    quoteResponseList,
    isFetchingQuoteResponse,
    isSuccessFetchingQuoteResponse,
    isErrorFetchingQuoteResponse,

    fetchNextPageResponse,
    isFetchingNextPageResponse,
  } = useGetQuoteResponseList({});
  //* table components
  const quoteRequestTableComponents: TableComponents<
    QuoteRequest,
    IQuoteRequestTableContext
  > = useMemo(
    () => ({
      Table: (props) => (
        <Table
          {...props}
          style={{
            width: "100% !important",
            height: "100% !important",
          }}
        />
      ),
      TableBody: React.forwardRef((props, ref) => (
        <StyledQuoteTableBody {...props} ref={ref} />
      )),
      Scroller: React.forwardRef((props, ref) => (
        <TableContainer
          sx={{
            "&.MuiTableContainer-root": {
              width: "100% !important",
              height: "100% !important",
              minWidth: "40rem !important",
            },
          }}
          ref={ref}
          {...props}
          component={StyledQuoteTableScroller}
        />
      )),
      TableRow: StyledQuoteTableRow,
      TableHead: StyledQuoteTableHead,
      EmptyPlaceholder: ({ context }) => (
        <DoxleEmptyPlaceHolder
          headTitleText={isErrorFetchingQuoteRequest?"Something Wrong":"No Quote Request"}
          containerStyle={{
            width: "100%",
            height: "14rem",
          }}
          headTitleTextStyle= {{fontSize: 30}}
          containerBgColor="transparent"
          illustration={
            isErrorFetchingQuoteRequest ? (
              <Error404Banner themeColor={doxleThemeColor} containerStyle={{width: "30%"}} />
            ) : (
              <EmptyRequestBanner themeColor={doxleThemeColor} containerStyle={{marginTop: 120}} />
            )
          }
        />
      ),
    }),
    []
  );

  const quoteResponseTableComponents: TableComponents<
    QuoteResponse,
    IQuoteResponseTableContext
  > = useMemo(
    () => ({
      Table: (props) => (
        <Table
          {...props}
          style={{
            width: "100% !important",
            height: "100% !important",
            minWidth: "40rem !important",
          }}
        />
      ),
      TableBody: React.forwardRef((props, ref) => (
        <StyledQuoteTableBody {...props} ref={ref} />
      )),
      Scroller: React.forwardRef((props, ref) => (
        <TableContainer
          sx={{
            "&.MuiTableContainer-root": {
              width: "100% !important",
              height: "100% !important",
              minWidth: "40rem !important",
            },
          }}
          ref={ref}
          {...props}
          component={StyledQuoteTableScroller}
        />
      )),
      TableRow: StyledQuoteTableRow,
      TableHead: StyledQuoteTableHead,
      EmptyPlaceholder: ({ context }) => (
        <DoxleEmptyPlaceHolder
          headTitleText="No Quote Response"
          containerStyle={{
            width: "100%",
            height: "14rem",
          }}
          headTitleTextStyle={{fontSize: 30}}
          containerBgColor="transparent"
           illustration={
            isErrorFetchingQuoteRequest ? (
              <Error404Banner themeColor={doxleThemeColor} containerStyle={{width: "30%"}} />
            ) : (
              <EmptyResponseBanner themeColor={doxleThemeColor} containerStyle={{marginTop: 120}} />
            )
          }
        />
      ),
    }),
    []
  );
  //* render request list
  const itemRequestContent: ItemContent<
    QuoteRequest,
    IQuoteRequestTableContext
  > = useCallback(
    (_, item, context) => (
      <QuoteRequestRow
        key={item.requestId}
        quoteRequest={item}
        selectedAttachment={context.selectedAttachment}
        setSelectedAttachment={context.setSelectedAttachment}
      />
    ),
    []
  );

  const itemResponseContent: ItemContent<
    QuoteResponse,
    IQuoteResponseTableContext
  > = useCallback(
    (_, item, context) => (
      <QuoteResponseRow
        key={item.responseId}
        quoteResponse={item}
        selectedAttachment={selectedAttachment}
        setSelectedAttachment={setSelectedAttachment}
      />
    ),
    []
  );

  const tableStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
  return (
    <StyledQuoteRequestsTableContainer
      initial={{ x: -10, opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
    >
      <StyledQuotesTableContainer
        $themeColor={THEME_COLOR}
        $doxleFont={DOXLE_FONT}
      >
        {isFetchingQuoteRequest && <SkeletonLoader />}
        {isSuccessFetchingQuoteRequest && (
          <TableVirtuoso
            context={{
              selectedAttachment,
              setSelectedAttachment,
            }}
            data={quoteRequestList}
            totalCount={quoteRequestList?.length}
            style={tableStyle}
            components={quoteRequestTableComponents}
            initialTopMostItemIndex={0}
            endReached={fetchNextPageRequest}
            fixedHeaderContent={() => (
              <StyleQuotesTableHeaderRow
                $themeColor={THEME_COLOR}
                $doxleFont={DOXLE_FONT}
              >
                <StyledQuotesTableHeaderItems
                  $themeColor={THEME_COLOR}
                  $doxleFont={DOXLE_FONT}
                  sx={{
                    marginRight: "1.4rem !important",
                  }}
                >
                  <StyledQuotesCheckBox
                    $themeColor={THEME_COLOR}
                    disableTouchRipple
                    checked={quoteRequestIds.length === quoteRequestList.length}
                    onClick={handleSelectAllQuoteRequests}
                    icon={
                      <QuotesCheckBoxUncheckedIconNo1
                        themeColor={THEME_COLOR}
                      />
                    }
                    checkedIcon={<QuotesCheckBoxIcon />}
                  />
                </StyledQuotesTableHeaderItems>
                {quoteRequestHeaders.map((item, idx) => (
                  <StyledQuotesTableHeaderItems
                    key={item.name + idx}
                    $themeColor={THEME_COLOR}
                    $doxleFont={DOXLE_FONT}
                    sx={{
                      width:
                        item.name !== "CONTRACTOR" &&
                        item.name !== "ATTACHMENTS"
                          ? "calc(12% - 4px)"
                          : item.name === "ATTACHMENTS"
                          ? "calc(15% - 4px)"
                          : undefined,
                      flex: item.name === "CONTRACTOR" ? 1 : undefined,
                      justifyContent:
                        item.name === "CONTRACTOR" ? "flex-start" : "center",
                    }}
                  >
                    {item.name}
                  </StyledQuotesTableHeaderItems>
                ))}
              </StyleQuotesTableHeaderRow>
            )}
            itemContent={itemRequestContent}
          />
        )}

        {isFetchingNextPageRequest && (
          <ListLoadingMore
            animationSize={80}
            containerStyle={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              position: "absolute",
              bottom: 0,
              zIndex: 9,
            }}
          />
        )}
        {isErrorFetchingQuoteRequest && (
          <DoxleEmptyPlaceHolder
            headTitleText="Something wrong!"
            subTitleText="Failed to get quote request..."
            containerBgColor="transparent"
          />
        )}
        <QuoteAttachmentPopper />
      </StyledQuotesTableContainer>

      <StyledQuotesTableContainer
        $themeColor={THEME_COLOR}
        $doxleFont={DOXLE_FONT}
      >
        <StyledQuotesResponseHeader
          $doxleFont={DOXLE_FONT}
          $themeColor={THEME_COLOR}
        >
          User Created Quotes
        </StyledQuotesResponseHeader>
        <div
          style={{
            flex: 1,
            width: "100%",
            display: "flex",
            flexDirection: "column",
            position: "relative",
          }}
        >
          {isFetchingQuoteResponse && <SkeletonLoader />}

          {isSuccessFetchingQuoteResponse && (
            <TableVirtuoso
              data={quoteResponseList}
              style={{ height: "100%" }}
              endReached={fetchNextPageResponse}
              initialTopMostItemIndex={0}
              components={quoteResponseTableComponents}
              totalCount={quoteResponseList.length}
              fixedHeaderContent={() => (
                <StyleQuotesTableHeaderRow
                  $themeColor={THEME_COLOR}
                  $doxleFont={DOXLE_FONT}
                >
                  <StyledQuotesTableHeaderItems
                    $themeColor={THEME_COLOR}
                    $doxleFont={DOXLE_FONT}
                    sx={{
                      marginRight: "1.4rem !important",
                    }}
                  >
                    <StyledQuotesCheckBox
                      $themeColor={THEME_COLOR}
                      disableTouchRipple
                      checked={
                        quoteResponseIds.length === quoteResponseList.length
                      }
                      onClick={handleSelectAllQuoteResponse}
                      icon={
                        <QuotesCheckBoxUncheckedIconNo1
                          themeColor={THEME_COLOR}
                        />
                      }
                      checkedIcon={<QuotesCheckBoxIcon />}
                    />
                  </StyledQuotesTableHeaderItems>
                  {quoteResponseHeaders.map((item, idx) => (
                    <StyledQuotesTableHeaderItems
                      key={item.name + idx}
                      $themeColor={THEME_COLOR}
                      $doxleFont={DOXLE_FONT}
                      sx={{
                        width:
                          item.name !== "CONTRACTOR" &&
                          item.name !== "ATTACHMENTS"
                            ? "calc(12% - 4px)"
                            : item.name === "ATTACHMENTS"
                            ? "calc(15% - 4px)"
                            : undefined,
                        flex: item.name === "CONTRACTOR" ? 1 : undefined,
                        justifyContent:
                          item.name === "CONTRACTOR" ? "flex-start" : "center",
                      }}
                    >
                      {item.name}
                    </StyledQuotesTableHeaderItems>
                  ))}
                </StyleQuotesTableHeaderRow>
              )}
              itemContent={itemResponseContent}
            />
          )}

          {isFetchingNextPageResponse && (
            <ListLoadingMore
              animationSize={80}
              containerStyle={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                position: "absolute",
                bottom: 0,
                zIndex: 9,
              }}
            />
          )}
          {isErrorFetchingQuoteResponse && (
            <DoxleEmptyPlaceHolder
              headTitleText="Something wrong!"
              subTitleText="Failed to get quote response..."
              containerBgColor="transparent"
            />
          )}
        </div>
      </StyledQuotesTableContainer>

      <DeleteSelectedQuotes />
      <EmailSelectedQuotes />
    </StyledQuoteRequestsTableContainer>
  );
};

export default QuoteRequestsTable;
