import React, { memo } from "react";
import { shallow } from "zustand/shallow";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import {
  StyledPricebookTableHeaderCell,
  StyledPricebookTableHeaderRow,
  StyledPricebookTableHeaderText,
} from "../StyledComponents";
import { usePricebookStore } from "../../Store/usePricebookStore";
import {
  PRICEBOOK_TABLE_HEADER_LIST,
  TPricebookTableHeaders,
} from "../../Models/PricebookTable";
import {
  PRICEBOOK_SUPPLIER_RATEHEADERS_MAP,
  PricebookRateOrderByFields,
  RevPricebookRateOrderByFields,
} from "../../Models/PricebookFilters";
import { useShallow } from "zustand/react/shallow";

const PricebookTableHeader = () => {
  const { orderBy, setQueryFilter } = usePricebookStore(
    useShallow((state) => ({
      orderBy: state.pricebookRateQueryFilter.order_by,
      setQueryFilter: state.setPricebookRateQueryFilter,
    }))
  );
  const { staticMenuColor } = useDoxleThemeStore(
    useShallow((state) => ({
      staticMenuColor: state.staticMenuColor,
    }))
  );
  const handleHeaderClick = (header: TPricebookTableHeaders) => {
    let newOrderByParam:
      | PricebookRateOrderByFields
      | RevPricebookRateOrderByFields =
      PRICEBOOK_SUPPLIER_RATEHEADERS_MAP[header];
    if (orderBy && newOrderByParam.includes(orderBy))
      newOrderByParam = `-${newOrderByParam}`;
    setQueryFilter({ order_by: newOrderByParam });
  };

  const checkHeaderMatch = (header: TPricebookTableHeaders) => {
    return !!orderBy?.includes(PRICEBOOK_SUPPLIER_RATEHEADERS_MAP[header]);
  };

  return (
    <StyledPricebookTableHeaderRow>
      {PRICEBOOK_TABLE_HEADER_LIST.map(
        (header, idx) =>
          header.isDisplayed && (
            <StyledPricebookTableHeaderCell
              $width={
                header.headerName === "Supplier"
                  ? "50%"
                  : header.headerName === "Unit Price"
                  ? "14%"
                  : header.headerName === "Unit"
                  ? "14%"
                  : header.headerName === "Modified"
                  ? "17%"
                  : "5%"
              }
              $horizontalAlign={
                header.headerName === "Supplier" ? "flex-start" : "center"
              }
              key={`header#${idx}`}
              onClick={() => handleHeaderClick(header.headerName)}
            >
              <StyledPricebookTableHeaderText
                $fontWeight={checkHeaderMatch(header.headerName) ? 800 : 400}
              >
                {header.headerName}
              </StyledPricebookTableHeaderText>
              {checkHeaderMatch(header.headerName) &&
                orderBy?.startsWith("-") && (
                  <ArrowDropDownIcon
                    style={{ color: staticMenuColor.staticFontColor }}
                  />
                )}
              {checkHeaderMatch(header.headerName) &&
                !orderBy?.startsWith("-") && (
                  <ArrowDropUpIcon
                    style={{ color: staticMenuColor.staticFontColor }}
                  />
                )}
            </StyledPricebookTableHeaderCell>
          )
      )}
      <StyledPricebookTableHeaderCell $width="5%" key={`header#delete`} />
    </StyledPricebookTableHeaderRow>
  );
};

export default memo(PricebookTableHeader);
