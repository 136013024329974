import { useEditDocketSideScreenStore } from "../EditDocketSideScreen/EditDocketSideScreenStore/useEditDocketSideScreenStore";
import React, { useMemo } from "react";
import { shallow } from "zustand/shallow";
import {
  StyledBoldHeader,
  StyledContactEmail,
  StyledContactListScroller,
  StyledContactName,
  StyledSemiBoldHeader,
  StyledSharedContactInfo,
  StyledSharedContactRow,
  StyledShareDocketFullScreenContainer,
  StyledShareDocketTabContainer,
  StyledShareDocketTitleRow,
  StyledShareLabel,
  StyledSubtitleContainer,
} from "./SyledComponents";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import { Contact } from "../../Models/addressBook";
import DocketQuery from "../../Services/QueryHooks/docketQueryAPI";
import { useDoxleAuthStore } from "../../DoxleGeneralStore/useDoxleAuthStore";
import { useDoxleCurrentContextStore } from "../../DoxleGeneralStore/useDoxleCurrentContext";
import useDoxleNotificationStore from "../../DoxleGeneralStore/useDoxleNotificationStore";
import PermissionsAPI from "../../Services/QueryHooks/permissionsAPI";
import { DocketPermission } from "../../Models/permissions";
import DoxleContactInviteSection, {
  NewContact,
} from "../../DoxleDesignPattern/DoxleAutoComplete/DoxleContactInviteSection";
import { Virtuoso } from "react-virtuoso";
import SharedContactRow from "./SharedContactRow";
import DropDownPermissions from "./DropDownPermissions";
import FullScreenImage from "./fullSecreenImage";
import DoxleStatus from "../../DoxleDesignPattern/DoxleStatus/DoxleStatus";
import useGetSharePermission from "../QueryDataHooks/GetQueryDataHooks/useGetSharePermission";

interface RelatedPermissions {
  companyPermissionsTotal: number;
  companyPermissions: Contact[];
  projectPermissionsTotal: number;
  projectPermissions: Contact[];
}

type TrinaryNum = 0 | 1 | 2;
// 0 = View access to items assigned to the contact or contact's contactCompany
// 1 = View access to all items of that type
// 2 = Read & Write access
export interface SharedDocketPermissions {
  scopeOfWorks: TrinaryNum;
  bookings: TrinaryNum;
  estimates: TrinaryNum;
  orders: TrinaryNum;
  files: TrinaryNum;
  drawings: TrinaryNum;
  takeOffs: TrinaryNum;
  rfis: TrinaryNum;
  // notes: TrinaryNum;
}
interface Props {}
const ShareDocketFullScreen = ({}: Props) => {
  const company = useDoxleCurrentContextStore(
    (state) => state.currentCompany,
    shallow
  );
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );
  const styleProps = useDoxleThemeStore(
    (state) => ({
      $themeColor: state.doxleThemeColor,
      $doxleFont: state.doxleFont,
      $currentTheme: state.currentTheme,
    }),
    shallow
  );
  const { docket, setOpen } = useEditDocketSideScreenStore(
    (state) => ({
      docket: state.edittedDocket,
      setOpen: state.setDisplayShareTab,
    }),
    shallow
  );

  const {
    permissions,
    isFetchingPermissions,
    isErrorFetchingPermissions,
    permittedContactIds,
  } = useGetSharePermission({ docket: docket?.docketPk });

  // const relatedPermissionQuery =
  //   PermissionsAPI.useRetrieveRelatedPermissionsContactsQuery({
  //
  //     company,
  //     showNotification,
  //     filter: { docket: docket?.docketPk },
  //   });

  // const relatedPermissions: RelatedPermissions = relatedPermissionQuery?.data
  //   ?.data ?? {
  //   companyPermissionsTotal: 0,
  //   companyPermissions: [],
  //   projectPermissionsTotal: 0,
  //   projectPermissions: [],
  // };

  // const displayNames = 3;
  // const totalWithAccess =
  //   relatedPermissions.companyPermissionsTotal +
  //   relatedPermissions.projectPermissionsTotal +
  //   (contactPermissionQuery.data?.pages?.[0]?.data?.count ?? 0);
  // const companyPermissionsContacts = useMemo(() => {
  //   const names = relatedPermissions.companyPermissions
  //     .slice(0, displayNames)
  //     .map((contact: Contact) => {
  //       let nameStr = contact.firstName;
  //       if (nameStr && contact.lastName) nameStr += " " + contact.lastName;
  //       return nameStr;
  //     });
  //   const more = relatedPermissions.companyPermissionsTotal - displayNames;
  //   return `${names.join(", ")}${more > 0 ? " and " + more + " more." : ""}`;
  // }, [
  //   relatedPermissions.companyPermissions,
  //   relatedPermissions.companyPermissionsTotal,
  // ]);

  // const projectPermissionsContacts = useMemo(() => {
  //   const names = relatedPermissions.projectPermissions
  //     .slice(0, displayNames)
  //     .map((contact: Contact) => {
  //       console.log("projContact", contact);
  //       let nameStr = contact.firstName;
  //       if (nameStr && contact.lastName) nameStr += " " + contact.lastName;
  //       return nameStr;
  //     });
  //   const more = relatedPermissions.projectPermissionsTotal - displayNames;
  //   return `${names.join(", ")}${more > 0 ? " and " + more + " more." : ""}`;
  // }, [
  //   relatedPermissions.projectPermissions,
  //   relatedPermissions.projectPermissionsTotal,
  // ]);

  const handleClose = () => {
    setOpen(false);
  };

  const shareDocketQuery = DocketQuery.useShareDocketLink({
    company,
    showNotification,
  });

  const handleAddAndShareNewContact = (contact: NewContact) => {
    shareDocketQuery.emailLink.mutate({
      docketPk: docket?.docketPk ?? "",
      newContacts: [contact],
    });
  };

  const handleShareDocket = (contact: Contact) => {
    shareDocketQuery.emailLink.mutate({
      docketPk: docket?.docketPk ?? "",
      contactIds: [contact.contactId],
    });
  };

  return (
    <StyledShareDocketFullScreenContainer {...styleProps}>
      <StyledShareDocketTabContainer {...styleProps} $percentageWidth={40}>
        <FullScreenImage currentTheme={styleProps.$currentTheme} />
        <StyledShareDocketTitleRow {...styleProps}>
          <StyledBoldHeader {...styleProps}>Sharing Docket</StyledBoldHeader>
          <div style={{ marginTop: 5 }}>
            <DoxleStatus
              statusColor={docket?.statusColor ?? "rgba(121,121,255,1)"}
              type={"icon"}
              size={16}
              overrideBorderRadiusInPixel={9}
              overrideBorderWidthInPixel={4}
            />
          </div>
          <StyledSemiBoldHeader {...styleProps}>
            {docket?.docketName}
          </StyledSemiBoldHeader>
        </StyledShareDocketTitleRow>
        <StyledSubtitleContainer {...styleProps}>
          Please note: The link will expire in 14 days, your guest can access
          via login.doxle.com
        </StyledSubtitleContainer>
      </StyledShareDocketTabContainer>
      <StyledShareDocketTabContainer {...styleProps} $percentageWidth={42}>
        {/*<AddContactOrEmailSection/>*/}
        <DoxleContactInviteSection
          addNewContactFn={handleAddAndShareNewContact}
          addExistingContactFn={handleShareDocket}
          permittedContactIds={permittedContactIds}
        />
        <StyledShareLabel {...styleProps}>
          Who has access ({permissions.length})
        </StyledShareLabel>
        {/*<StyledSharedContactRow>*/}
        {/*  <StyledSharedContactInfo {...styleProps} $fullWidth={true}>*/}
        {/*    <StyledContactName {...styleProps}>*/}
        {/*      {relatedPermissions.companyPermissionsTotal} Users can see view*/}
        {/*      the docket by company permissions*/}
        {/*    </StyledContactName>*/}
        {/*    <StyledContactEmail {...styleProps}>*/}
        {/*      {companyPermissionsContacts}*/}
        {/*    </StyledContactEmail>*/}
        {/*  </StyledSharedContactInfo>*/}
        {/*</StyledSharedContactRow>*/}
        {/*<StyledSharedContactRow>*/}
        {/*  <StyledSharedContactInfo {...styleProps} $fullWidth={true}>*/}
        {/*    <StyledContactName {...styleProps}>*/}
        {/*      {relatedPermissions.projectPermissionsTotal} Users can see view*/}
        {/*      the docket by project permissions*/}
        {/*    </StyledContactName>*/}
        {/*    <StyledContactEmail {...styleProps}>*/}
        {/*      {projectPermissionsContacts}*/}
        {/*    </StyledContactEmail>*/}
        {/*  </StyledSharedContactInfo>*/}
        {/*</StyledSharedContactRow>*/}
        <Virtuoso
          data={permissions.filter((item) => item.contact)}
          itemContent={(index: number, item) => (
            <SharedContactRow docketPermission={item as DocketPermission} />
          )}
          components={{
            Scroller: React.forwardRef((props, ref) => (
              <StyledContactListScroller ref={ref} {...props} />
            )),
          }}
        />
        <DropDownPermissions />
      </StyledShareDocketTabContainer>
    </StyledShareDocketFullScreenContainer>
  );
};

export default ShareDocketFullScreen;
