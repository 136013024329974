import { Autocomplete, CircularProgress, InputAdornment } from "@mui/material";
import { shallow } from "zustand/shallow";
import OrdersQueryAPI from "../../QueryHooks/OrdersQueryAPI";
import OrderFilters from "../../Models/orderFilters";
import { useEditDocketSideScreenStore } from "../../../CoreContent/EditDocketSideScreen/EditDocketSideScreenStore/useEditDocketSideScreenStore";
import useDoxleNotificationStore from "../../../DoxleGeneralStore/useDoxleNotificationStore";
import { useDoxleCurrentContextStore } from "../../../DoxleGeneralStore/useDoxleCurrentContext";
import { useDoxleAuthStore } from "../../../DoxleGeneralStore/useDoxleAuthStore";
import Order from "../../Models/order";
import React, { MutableRefObject, useMemo, useState } from "react";
import { StyledPurchaseOrderInput } from "./StyledPaymentClaimComponents";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { formatter, toNum } from "../../../Utilities/FunctionUtilities";
import AutocompleteList from "../../../DoxleDesignPattern/DoxleAutoComplete/AutocompleteList";
import { StyledContactOption } from "../../../DoxleDesignPattern/DoxleAutoComplete/StyledComponentDoxleAutocomplete";

interface Props {
  inputRef: MutableRefObject<undefined | HTMLInputElement>;
  overrideOrdersList?: Order[];
}
const OrderAutocomplete = ({inputRef, overrideOrdersList}: Props) => {
  const [searchTextQuery , setSearchTextQuery] = useState<string>("")
  const {themeColor, doxleFont} = useDoxleThemeStore(state => ({
    themeColor: state.doxleThemeColor,
    doxleFont: state.doxleFont
  }), shallow)
  const docket = useEditDocketSideScreenStore(state => state.edittedDocket, shallow)
  const showNotification = useDoxleNotificationStore(state => state.showNotification, shallow)
  const company = useDoxleCurrentContextStore(state=> state.currentCompany, shallow)

  const queryFilter: OrderFilters = {
    is_invoice: false,
    search: searchTextQuery ? searchTextQuery : "",
    docket: docket?.docketPk,
    payable: "true",
  };

  const orderListQuery = OrdersQueryAPI.useRetrieveOrderList({

    company,
    showNotification,
    enabled: !Boolean(overrideOrdersList),
    filter: queryFilter,
  });

  const ordersList: Order[] = useMemo(
    () =>
      overrideOrdersList
        ? overrideOrdersList
        : orderListQuery.data?.pages
        ? orderListQuery.data?.pages.flatMap(
            (page: any) => page?.data?.results ?? []
          ) ?? []
        : [],
    [overrideOrdersList, orderListQuery.data]
  );

  return (
    <div style={{ width: "Calc(100% - 16px)", marginRight: 16 }}>
      <Autocomplete
        fullWidth
        PaperComponent={AutocompleteList}
        noOptionsText={"No Payable Orders Found"}
        disablePortal
        loading={orderListQuery.isLoading}
        loadingText="Looking For Orders..."
        options={ordersList}
        getOptionLabel={(option) =>
          `${option.orderNumber}: ${option.supplier} - ${
            option.description
          } (${formatter.format(toNum(option.total))})`
        }
        isOptionEqualToValue={(option, value) =>
          option.orderId === value.orderId
        }
        onChange={(e, v) => {
          if (inputRef.current && v) {
            inputRef.current?.setAttribute("data-order-id", v.orderId);
            inputRef.current?.setAttribute("data-order-total", String(v.total));
          }
          if (inputRef.current && !v) {
            inputRef.current?.removeAttribute("data-order-id");
            inputRef.current?.removeAttribute("data-order-total");
          }
        }}
        renderOption={(props, option, context) => {
          return (
            <li
              {...props}
              key={`listItem-` + context.index + `-` + option.orderId}
            >
              <StyledContactOption
                $themeColor={themeColor}
                $doxleFont={doxleFont}
                style={{
                  fontFamily: doxleFont.primaryFont,
                  fontWeight: 500,
                  fontSize: "1.2rem",
                  lineHeight: "normal",
                  letterSpacing: "0.0075em",
                  height: 30,

                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                }}
                animate={{
                  color: context.selected
                    ? themeColor.doxleColor
                    : themeColor.primaryFontColor,
                }}
                whileHover={{
                  backgroundColor: themeColor.hoverColor,
                }}
                transition={{ duration: 0.2 }}
              >
                {`${option.orderNumber}: ${option.supplier} - ${
                  option.description
                } (${formatter.format(toNum(option.total))})`}
              </StyledContactOption>
            </li>
          );
        }}
        renderInput={(params) => (
          <StyledPurchaseOrderInput
            {...params}
            placeholder="Select a purchase order to claim payment against"
            $themecolor={themeColor}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <InputAdornment position="end">
                  {orderListQuery.isLoading ? (
                    <CircularProgress
                      size={20}
                      sx={{ color: themeColor.doxleColor }}
                    />
                  ) : null}
                </InputAdornment>
              ),
            }}
            inputRef={inputRef}
            onChange={(
              event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            ) => setSearchTextQuery(event.target.value)}
          />
        )}
      />
    </div>
  );
};

export default OrderAutocomplete;
