import React, { useCallback, useEffect, useRef, useState } from "react";
import { ListRange } from "react-virtuoso";
import useGetProjectQAList from "./useGetProjectQAList";
import { useProjectStore } from "../../../../../DoxleGeneralStore/useprojectStore";
import { shallow } from "zustand/shallow";
import { useProjectQAStore } from "../../../Store/useProjectQAStore";
import useDoxleNotificationStore from "../../../../../DoxleGeneralStore/useDoxleNotificationStore";
import { useDoxleAuthStore } from "../../../../../DoxleGeneralStore/useDoxleAuthStore";
import { useDoxleCurrentContextStore } from "../../../../../DoxleGeneralStore/useDoxleCurrentContext";
import QAQueryAPI from "../../../../QueryAPI/qaQueryAPI";
import { NEW_QA_LIST_TEMPLATE, QAList } from "../../../../Models/qa";

type Props = {};

interface QAProjectList {
  shouldShowRefetch: any;
  setCurrentVisibleRange: React.Dispatch<React.SetStateAction<ListRange>>;
  newQAListInputRef: React.RefObject<HTMLInputElement>;
  handleDeleteQAList: () => void;
  handleCloseQAListDeleteDialog: () => void;
  deletedQAList: QAList | undefined;
  setdeletedQAList: React.Dispatch<React.SetStateAction<QAList | undefined>>;
  isAddingQAList: boolean;
}
const useQAProjectList = (props: Props): QAProjectList => {
  const [deletedQAList, setdeletedQAList] = useState<QAList | undefined>(
    undefined
  );
  const [currentVisibleRange, setCurrentVisibleRange] = useState<ListRange>({
    startIndex: 0,
    endIndex: 0,
  });
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );
  const { user } = useDoxleAuthStore(
    (state) => ({ user: state.user }),
    shallow
  );
  const { company, currentProject } = useDoxleCurrentContextStore(
    (state) => ({
      currentProject: state.currentProject,
      company: state.currentCompany,
    }),
    shallow
  );
  const { filterQAListQuery } = useProjectQAStore(
    (state) => ({
      filterQAListQuery: state.filterQAListQuery,
    }),
    shallow
  );
  const {
    qaList,

    isRefetchingQAList,
  } = useGetProjectQAList({});
  const { setFunctionNewButton } = useProjectStore(
    (state) => ({
      setFunctionNewButton: state.setFunctionNewButton,
    }),
    shallow
  );
  const newQAListInputRef = useRef<HTMLInputElement>(null);

  const shouldShowRefetch = Boolean(
    currentVisibleRange.startIndex < 2 || isRefetchingQAList
  );
  const addDefectQuery = QAQueryAPI.useAddQAListQuery({
    showNotification,
    company,
    filter: filterQAListQuery,
  });

  const handleAddFunction = useCallback(() => {
    // if (qaList.length === 0) {
      const newDefectTemplate: QAList = NEW_QA_LIST_TEMPLATE({
        company,
        createdBy: user?.userId,
        defectListTitle: "New QA List",
        project: currentProject ? currentProject?.projectId : undefined,
      });
      addDefectQuery.mutate(newDefectTemplate);
    // } else {
    //   newQAListInputRef.current?.focus();
    // }
  }, [company, currentProject, user, qaList]);

  useEffect(() => {
    setFunctionNewButton([{btnFunction: handleAddFunction, isLoading: addDefectQuery.isLoading}]);

    return () => {
      setFunctionNewButton([]);
    };
  }, [handleAddFunction, addDefectQuery.isLoading]);

  const deleteQaListQuery = QAQueryAPI.useDeleteQAListQuery({
    company,
    // showNotification,
    filter: filterQAListQuery,
  });

  const handleCloseQAListDeleteDialog = () => {
    setdeletedQAList(undefined);
  };
  const handleDeleteQAList = () => {
    if (deletedQAList) deleteQaListQuery.mutate(deletedQAList.defectListId);
    handleCloseQAListDeleteDialog();
  };
  return {
    shouldShowRefetch,
    setCurrentVisibleRange,
    newQAListInputRef,
    handleDeleteQAList,
    handleCloseQAListDeleteDialog,
    deletedQAList,
    setdeletedQAList,
    isAddingQAList: addDefectQuery.isLoading,
  };
};

export default useQAProjectList;
