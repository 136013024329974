import React from "react";
import {
  StyledQuoteResponseMenuItem,
  StyledQuoteResponseTopMenu,
} from "./StyledComponentQuoteResponse";
import { QuoteResponseMenu, useQuoteContext } from "./QuoteResponse";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import { motion } from "framer-motion";

type Props = {};
const QUOTE_MENU: QuoteResponseMenu[] = ["Quote", "Scope Of Works"];
const QuoteResponseTopMenu = (props: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }),
    shallow
  );

  const { selectedQuoteMenuTab, setSelectedQuoteMenuTab } = useQuoteContext();
  return (
    <StyledQuoteResponseTopMenu>
      {QUOTE_MENU.map((menu, idx) => (
        <StyledQuoteResponseMenuItem
          $themeColor={doxleThemeColor}
          $doxleFont={doxleFont}
          $selected={menu === selectedQuoteMenuTab}
          key={`menu#${idx}${menu}`}
          onClick={() => setSelectedQuoteMenuTab(menu)}
        >
          {menu}

          {selectedQuoteMenuTab === menu && (
            <motion.div
              className="selected-effect"
              layoutId="selectedQuoteFunctionMenu"
            />
          )}
        </StyledQuoteResponseMenuItem>
      ))}
    </StyledQuoteResponseTopMenu>
  );
};

export default QuoteResponseTopMenu;
