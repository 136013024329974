import React from "react";
import { useDoxleThemeStore } from "../../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import {
  StyledDescriptionInputEndornment,
  StyledEditQATextField,
} from "./StyledComponentQAItemDetail";
import useQACommentInputsection from "./Hooks/useQACommentInputsection";
import CircularProgress from "@mui/material/CircularProgress";
import DoxleMultilineTextField from "../../../../DoxleDesignPattern/DoxleTextField/DoxleMultilineTextField";
import { editRgbaAlpha } from "../../../../Utilities/FunctionUtilities";
import { useShallow } from "zustand/react/shallow";

type Props = {};

const QACommentInputsection = (props: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    useShallow((state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }))
  );
  const {
    commentInputRef,
    newCommentText,
    setNewCommentText,
    handleKeydownInput,
    handleAddCmt,
    isAddingCmt,
  } = useQACommentInputsection({});
  return (
    <>
      <DoxleMultilineTextField
        value={newCommentText}
        onKeyDown={handleKeydownInput}
        onChange={(e) => setNewCommentText(e.target.value)}
        onFocus={(e) => e.currentTarget.select()}
        maxRows={5}
        sx={[
          {
            textarea: {
              fontSize: 16,
              "&::placeholder": { fontSize: 16 },
              fontFamily: doxleFont.primaryFont,
              color: doxleThemeColor.primaryFontColor,
            },
            width: "calc(100% - 18px) !important",
            backgroundColor: `${doxleThemeColor.primaryContainerColor} !important`,
            border:
              "1px solid " +
              editRgbaAlpha({
                rgbaColor: doxleThemeColor.primaryDividerColor,
                alpha: "0.8",
              }) +
              " !important",
            padding: "2px 8px",
          },
        ]}
        placeholder="Type in a new comment ..."
        InputProps={{
          endAdornment: isAddingCmt && (
            <StyledDescriptionInputEndornment position="end">
              <CircularProgress
                style={{ color: doxleThemeColor.primaryFontColor }}
                size="1.4rem"
              />
            </StyledDescriptionInputEndornment>
          ),
        }}
      />
    </>
  );
};

export default React.memo(QACommentInputsection);
