import { StyledEditDocketFullScreenContentSectionContainer } from "../StyledComponentsEditDocketSideScreen";
import { useEditDocketSideScreenStore } from "../EditDocketSideScreenStore/useEditDocketSideScreenStore";
import { shallow } from "zustand/shallow";
import DocketInfo from "../EditDocketSideScreenCommonContent/DocketInfo/DocketInfo";
import ShareDocketFullScreen from "../../ShareDocketDialog/ShareDocketFullScreen";

// !!! EXTERNAL REPOS !!!//
import DocketOrdersTab from "../../../Orders/Content/OrderDocketTab/DocketOrdersTab";
import PaymentClaimTab from "../../../Orders/Content/SubmitPaymentClaim/PaymentClaimTab";
import QuotesPage from "../../../Quotes/Content/QuotesPage";
import ScopeOfWork from "../../../ScopeOfWorks/Content/ScopeOfWork";
import Files from "../../../Files/Content/Files";
import MailPage from "../../../Mail/Content/Mail";
import EditDocketBudgetEstimate from "../../../Estimates/Content/EditBudgetEstimate/components/EditDocketBudgetEstimate";
import BookingDocket from "../../../Bookings/BookingDocket/Components/BookingDocket";
import Pricebook from "../../../Pricebook/Content/Pricebook";
import Contacts from "../../../Contacts/Content/Contacts";
import Notes from "../../../Notes/Components/Notes";
import CommentDisplaySection from "../EditDocketSideScreenCommonContent/DocketInfo/CommentDisplaySection";

type Props = {};

const EditDocketFullScreenContentSection = (props: Props) => {
  const { selectedFunctionMenu, shareTab } = useEditDocketSideScreenStore(
    (state) => ({
      selectedFunctionMenu: state.selectedFunctionMenu,
      shareTab: state.displayShareTab,
    }),
    shallow
  );

  return (
    <StyledEditDocketFullScreenContentSectionContainer>
      {shareTab && <ShareDocketFullScreen />}
      {!shareTab && selectedFunctionMenu === "Detail" && <DocketInfo />}

      {/*!!! EXTERNAL REPOS !!!*/}
      {!shareTab && selectedFunctionMenu === "Scope Of Works" && (
        <ScopeOfWork />
      )}
      {!shareTab && selectedFunctionMenu === "Budget" && (
        <EditDocketBudgetEstimate />
      )}
      {!shareTab && selectedFunctionMenu === "Quotes" && <QuotesPage />}
      {!shareTab && selectedFunctionMenu === "Files" && (
        <Files level="Docket" />
      )}
      {!shareTab &&
        (selectedFunctionMenu === "Orders" ||
          selectedFunctionMenu === "Invoices") && <DocketOrdersTab />}
      {!shareTab && selectedFunctionMenu === "Payments" && <PaymentClaimTab />}

      {!shareTab && selectedFunctionMenu === "Pricebook" && (
        <Pricebook context={"DocketFullScreen"} />
      )}
      {!shareTab && selectedFunctionMenu === "Contacts" && (
        <Contacts context={"DocketFullScreen"} />
      )}

      {!shareTab && selectedFunctionMenu === "Bookings" && <BookingDocket />}
      {!shareTab && selectedFunctionMenu === "Mail" && <MailPage docket />}
      {!shareTab && selectedFunctionMenu === "Notes" && (
        <Notes scope="Docket" />
      )}
      {!shareTab && selectedFunctionMenu === "Comments" && (
        <CommentDisplaySection />
      )}
    </StyledEditDocketFullScreenContentSectionContainer>
  );
};

export default EditDocketFullScreenContentSection;
