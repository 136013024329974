import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import DoxleAPI from "../../Services/DoxleAPI";
import { AxiosProgressEvent, AxiosResponse, isAxiosError } from "axios";
import { Company } from "../../Models/company";
import { BaseAPIProps } from "../../Models/baseAPIProps";
import {
  Docket,
  DocketStatus,
  IFullDocketDetailQueryFilterProp,
  INBQueryFilterProps,
  LightDocket,
} from "../../Models/dockets";
import { NewContact } from "../../DoxleDesignPattern/DoxleAutoComplete/DoxleContactInviteSection";
import { ProjectPermission } from "../../Models/permissions";
import {
  AxiosBackendErrorReturn,
  IApiPaginatedData,
} from "../../Models/axiosReturn";
import useSetDocketListQueryData from "../../CoreContent/QueryDataHooks/SetQueryDataHooks/useSetDocketListQueryData";
import { useDoxleCurrentContextStore } from "../../DoxleGeneralStore/useDoxleCurrentContext";
import { shallow } from "zustand/shallow";
import useDoxleNotificationStore from "../../DoxleGeneralStore/useDoxleNotificationStore";
import useSetStatusQueryData from "../../CoreContent/CompanySettingsScreen/Hooks/useSetStatusQueryData";
import { User } from "../../Models/user";
import { DocketAccountLink } from "../../Xero/Models/account";
import { useShallow } from "zustand/react/shallow";
import { baseProjectTotalQKey } from "./projectQueryAPI";

interface IRetrieveDocketDetail extends BaseAPIProps {
  docketPk: string;
  enable?: boolean;
}
const useRetrieveDocketDetail = ({
  docketPk,
  enable,
  company,
  showNotification,
}: IRetrieveDocketDetail) => {
  const qKey = getDocketDetailQKey(docketPk);
  let docketURL = `/dockets/` + docketPk + "/";

  const docketQuery = useQuery(
    qKey,
    () =>
      DoxleAPI.get<Docket>(docketURL, {
        headers: {
          "User-Company": company!.companyId,
        },
      }),
    {
      enabled: Boolean(company && (enable || false)),
      retry: 1,
      staleTime: 60 * 1000,

      refetchInterval: 3 * 60 * 1000,
      refetchIntervalInBackground: true,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      onError: (error) => {
        if (showNotification) {
          if (isAxiosError<AxiosBackendErrorReturn>(error)) {
            showNotification(
              `${error?.response?.status ?? "ERROR"}: ${
                error.response?.data.detail ?? "UNKNOWN ERROR"
              }`,
              "error",
              String(
                error?.response?.data?.detail ?? "Failed to get docket detail"
              ).substring(0, 300)
            );
          } else {
            showNotification(
              "Something Wrong!",
              "error",
              "Failed to get docket detail"
            );
          }
        }
      },
    }
  );
  return docketQuery;
};

interface InfiniteQueryProps extends BaseAPIProps {
  filter: IFullDocketDetailQueryFilterProp | INBQueryFilterProps;

  enabled?: boolean;
  // orderBy?: {
  //   project?: boolean;
  // };
}

const useRetrieveFullDetailDocketList = ({
  filter,
  company,
  showNotification,
  enabled,
}: InfiniteQueryProps) => {
  const qKey = formDocketListQueryKey({ filter, company });

  let docketURL = `/dockets/?page=1`;
  const { order_by, ...rest } = filter;

  for (const [key, value] of Object.entries(rest)) {
    if (value) {
      docketURL += `&${key}=${value}`;
    }
  }
  if (order_by) {
    if (Array.isArray(order_by)) {
      if (order_by.length > 0)
        for (let i = 0; i < order_by.length; i++) {
          docketURL += `&order_by=${order_by[i]}`;
        }
    } else docketURL += `&order_by=${order_by}`;
  }

  return useInfiniteQuery(
    qKey,
    ({ pageParam = docketURL, signal }) => {
      // queryClient.cancelQueries({ queryKey: baseDocketListQKey });
      return DoxleAPI.get<IApiPaginatedData<LightDocket>>(pageParam, {
        headers: {
          "User-Company": company!.companyId,
        },
        signal,
        // params: filter,
      });
    },
    {
      enabled: Boolean(company && (enabled ?? true)),
      retry: 1,
      // staleTime: 10 * 60 * 1000,

      refetchInterval: 1 * 60 * 1000,
      refetchIntervalInBackground: true,
      refetchOnReconnect: true,
      refetchOnWindowFocus: false,
      getNextPageParam: (prevData) => prevData.data.next,
      getPreviousPageParam: (prevData) => prevData.data.previous,
      onError: (error) => {
        if (showNotification) {
          if (isAxiosError<AxiosBackendErrorReturn>(error)) {
            showNotification(
              `${error?.response?.status ?? "ERROR"}: ${
                error.response?.data.detail ?? "UNKNOWN ERROR"
              }`,
              "error",
              String(
                error?.response?.data?.detail ?? "Failed to get docket list"
              ).substring(0, 300)
            );
          } else {
            showNotification(
              "Something Wrong!",
              "error",
              "Failed to get docket list"
            );
          }
        }
      },
    }
  );
};

const useRetrieveBasicCoreDocketList = () => {
  const company = useDoxleCurrentContextStore(
    useShallow((state) => state.currentCompany)
  );
  const showNotification = useDoxleNotificationStore(
    useShallow((state) => state.showNotification)
  );

  return useQuery(
    ["core-dockets", company?.companyId],
    () =>
      DoxleAPI.get(`/dockets/core/`, {
        headers: {
          "User-Company": company!.companyId,
        },
      }),
    {
      enabled: Boolean(company),

      staleTime: 30 * 60 * 1000,
      cacheTime: 35 * 60 * 1000,
      refetchInterval: 31 * 60 * 1000,
      onError: (error) => {
        if (showNotification) {
          if (isAxiosError<AxiosBackendErrorReturn>(error)) {
            showNotification(
              `${error?.response?.status ?? "ERROR"}: ${
                error.response?.data.detail ?? "UNKNOWN ERROR"
              }`,
              "error",
              String(
                error?.response?.data?.detail ?? "FAILED TO GET DOCKET LIST"
              ).substring(0, 300)
            );
          } else {
            showNotification(
              "Something Wrong!",
              "error",
              "FAILED TO GET DOCKET LIST"
            );
          }
        }
      },
    }
  );
};

export interface IBudgetsAddProps {
  project?: string;
  projectSiteAddress?: string;
  docketName: string;
  startDate: string;
  endDate: string;
  percentageCompleted: number;
  isExpense: boolean;
  isIncome: boolean;
}
interface AddDocketQueryProps extends BaseAPIProps {
  filter: IFullDocketDetailQueryFilterProp;

  onSuccessCb?: (newDocket?: Docket) => void;
}
interface NewDocket
  extends Partial<Omit<Docket, "docketName">>,
    Pick<Docket, "docketName"> {}
const useAddDocketQuery = ({
  showNotification,
  company,
  filter,
  onSuccessCb,
}: AddDocketQueryProps) => {
  const { handleAddDocket } = useSetDocketListQueryData({ filter });
  const mutation = useMutation({
    mutationKey: getDocketMutationKey("add"),
    mutationFn: (newDocket: NewDocket) => {
      const addDocketURL = `/dockets/`;
      return DoxleAPI.post<Docket>(addDocketURL, newDocket, {
        headers: {
          "User-Company": company?.companyId || "",
        },
      });
    },

    onSuccess: (result: AxiosResponse<Docket>, variables, context) => {
      handleAddDocket({ ...result.data, isNew: true });

      // if (showNotification)
      //   showNotification(
      //     "Docket Added",
      //     "success",
      //     "SUCCESSFULLY UPDATED DATA"
      //   );
      if (onSuccessCb) onSuccessCb({ ...result.data, isNew: true });
    },
    onError: (error, variables, context) => {
      if (showNotification) {
        if (isAxiosError<AxiosBackendErrorReturn>(error)) {
          showNotification(
            `${error?.response?.status ?? "ERROR"}: ${
              error.response?.data.detail ?? "UNKNOWN ERROR"
            }`,
            "error",
            String(
              error?.response?.data?.detail ?? "Failed to add docket"
            ).substring(0, 300)
          );
        } else {
          showNotification("Something Wrong!", "error", "Failed to add docket");
        }
      }
    },
  });
  const mutate = (newDocket: NewDocket) => mutation.mutate(newDocket);
  return { ...mutation, mutate: mutate };
};

interface DeleteDocketQueryProps extends BaseAPIProps {
  filter: IFullDocketDetailQueryFilterProp;

  onSuccessCb?: (deletedId?: string) => void;
}

const useDeleteDocketQuery = ({
  showNotification,

  company,
  filter,
  onSuccessCb,
}: DeleteDocketQueryProps) => {
  const { handleRemoveDocket } = useSetDocketListQueryData({ filter });
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationKey: getDocketMutationKey("delete"),
    mutationFn: (deletedDocketId: string) => {
      let deleteDocketURL = `/dockets/${deletedDocketId}/`;
      return DoxleAPI.delete(deleteDocketURL, {
        headers: {
          "User-Company": company?.companyId || "",
        },
      });
    },

    onSuccess: (result, variables, context) => {
      handleRemoveDocket(variables);
      queryClient.invalidateQueries({ queryKey: [...baseProjectTotalQKey] });
      // if (showNotification)
      //   showNotification(
      //     "Docket Delete",
      //     "success",
      //     "SUCCESSFULLY Deleted DATA"
      //   );
      if (onSuccessCb) onSuccessCb(variables);
    },
    onError: (error, variables, context) => {
      if (showNotification) {
        if (isAxiosError<AxiosBackendErrorReturn>(error)) {
          showNotification(
            `${error?.response?.status ?? "ERROR"}: ${
              error.response?.data.detail ?? "UNKNOWN ERROR"
            }`,
            "error",
            String(
              error?.response?.data?.detail ?? "Failed to delete docket"
            ).substring(0, 300)
          );
        } else {
          showNotification(
            "Something Wrong!",
            "error",
            "Failed to delete docket"
          );
        }
      }
    },
  });
  const mutate = (deletedDocketId: string) => mutation.mutate(deletedDocketId);
  return { ...mutation, mutate: mutate };
};

interface UpdateDocketQueryProps extends BaseAPIProps {
  filter: IFullDocketDetailQueryFilterProp;

  onSuccessCb?: (newDocket?: Docket) => void;
}
export interface UpdateDocketParams
  extends Partial<
    Omit<
      Docket,
      | "docketPk"
      | "docketId"
      | "createdOn"
      | "lastModifiedOn"
      | "latestComment"
      | "isNew"
    >
  > {
  docketPk: string;
  setUpdateProgressValue?: (progress: number) => void;
}
const useUpdateDocketQuery = ({
  showNotification,

  company,
  filter,
  onSuccessCb,
}: UpdateDocketQueryProps) => {
  const { handleUpdateDocket } = useSetDocketListQueryData({ filter });

  const mutation = useMutation({
    mutationKey: getDocketMutationKey("update"),
    mutationFn: (updateData: UpdateDocketParams) => {
      const { docketPk, setUpdateProgressValue, ...rest } = updateData;
      let updateDocketURL = `/dockets/${updateData.docketPk}/`;
      return DoxleAPI.patch<Docket>(updateDocketURL, rest, {
        headers: {
          "User-Company": company?.companyId || "",
        },
        onUploadProgress: (progressEvent: AxiosProgressEvent) => {
          if (progressEvent.total) {
            let percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            if (setUpdateProgressValue)
              setUpdateProgressValue(percentCompleted);
          }
        },
      });
    },

    onSuccess: (result, variables, context) => {
      handleUpdateDocket(result.data);
      // if (showNotification)
      //   showNotification(
      //     "Docket Updated",
      //     "success",
      //     "SUCCESSFULLY Updated DATA"
      //   );
      if (onSuccessCb) onSuccessCb(result.data);
    },
    onError: (error, variables, context) => {
      if (showNotification) {
        if (isAxiosError<AxiosBackendErrorReturn>(error)) {
          showNotification(
            `${error?.response?.status ?? "ERROR"}: ${
              error.response?.data.detail ?? "UNKNOWN ERROR"
            }`,
            "error",
            String(
              error?.response?.data?.detail ?? "Failed to update docket"
            ).substring(0, 300)
          );
        } else {
          showNotification(
            "Something Wrong!",
            "error",
            "Failed to update docket"
          );
        }
      }
    },
    onSettled: () => {},
  });
  const mutate = (updateData: UpdateDocketParams) =>
    mutation.mutate(updateData);
  return { ...mutation, mutate: mutate };
};

//* DOCKET STATUS
export interface FilterRetrieveDocketStatusList {
  isNoticeBoardStatus?: boolean;
  isBudgetStatus?: boolean;
}
interface RetrieveDocketStatusList extends BaseAPIProps {
  filter: FilterRetrieveDocketStatusList;
}
const useRetrieveDocketStatusList = ({
  showNotification,
  company,
  filter,
  onErrorCb,
}: RetrieveDocketStatusList) => {
  const qKey = formDocketStatusQKey(filter, company);
  let docketURL = `/dockets/status/`;
  const { isNoticeBoardStatus, isBudgetStatus } = filter;
  let getParams: any = {};
  if (company) getParams.company = company.companyId;
  if (isNoticeBoardStatus) getParams.is_noticeboard = "true";
  if (isBudgetStatus) getParams.is_budget = "true";

  const docketQuery = useQuery(
    qKey,
    () =>
      DoxleAPI.get<DocketStatus[]>(docketURL, {
        headers: {
          "User-Company": company!.companyId,
        },
        params: getParams,
      }),
    {
      enabled: company !== undefined,
      retry: 1,
      staleTime: 5 * 60 * 100,
      cacheTime: 10 * 60 * 1000,
      refetchOnMount: false,
      refetchInterval: 9 * 60 * 1000,
      refetchOnWindowFocus: false,
      onError: (error) => {
        if (showNotification) {
          if (isAxiosError<AxiosBackendErrorReturn>(error)) {
            showNotification(
              `${error?.response?.status ?? "ERROR"}: ${
                error.response?.data.detail ?? "UNKNOWN ERROR"
              }`,
              "error",
              String(
                error?.response?.data?.detail ?? "Failed to get docket status"
              ).substring(0, 300)
            );
          } else {
            showNotification(
              "Something Wrong!",
              "error",
              "Failed to get docket status"
            );
          }
        }

        if (onErrorCb) onErrorCb();
      },
    }
  );
  return docketQuery;
};

const useUpdateManyDockets = ({
  company,
  showNotification,
  filter,
}: UpdateDocketQueryProps) => {
  const qKey = formDocketListQueryKey({ filter, company });
  const queryClient = useQueryClient();
  return useMutation(
    ["update-many-dockets"],
    (unsavedLinks: DocketAccountLink[]) =>
      DoxleAPI.post<null>(
        "/dockets/update_many/",
        { dockets: unsavedLinks },
        { headers: { "User-Company": company?.companyId ?? "" } }
      ),
    {
      onSuccess: (data, variables, context) => {
        queryClient.setQueryData(qKey, (old: any) => {
          if (!old) return old;
          else
            return {
              ...old,
              pages: (old?.pages ?? []).map((page: any) => ({
                ...page,
                data: {
                  ...page.data,
                  results: (page?.data?.results ?? []).map((docket: Docket) => {
                    const newData = variables.filter(
                      (link) => link.docketPk === docket.docketPk
                    )?.[0];
                    if (newData)
                      return {
                        ...docket,
                        accountTrackingId: newData.accountId,
                        accountCode: newData.accoutCode,
                        accountName: newData.accountName,
                      };
                    else return docket;
                  }),
                },
              })),
            };
        });
      },
      onError: (error, variables, context) => {
        if (showNotification) {
          if (isAxiosError<AxiosBackendErrorReturn>(error)) {
            showNotification(
              `${error?.response?.status ?? "ERROR"}: ${
                error.response?.data.detail ?? "UNKNOWN ERROR"
              }`,
              "error",
              String(
                error?.response?.data?.detail ?? "Failed to update dockets"
              ).substring(0, 300)
            );
          } else {
            showNotification(
              "Something Wrong!",
              "error",
              "Failed to get docket status"
            );
          }
        }
      },
    }
  );
};

//############################## HELPER FUNCTIONS ########################

interface ShareDocketQueryProp extends BaseAPIProps {
  onSuccessCb?: Function;
}
interface ShareDocketMutationProps {
  docketPk: string;
  contactIds?: string[];
  newContacts?: NewContact[];
  // permissions: SharedDocketPermissions
}

interface GetDocketLinkProps {
  docketPk: string;
  contactId?: string;
  userId?: NewContact;
}

const useShareDocketLink = ({
  company,
  showNotification,
  onSuccessCb,
}: ShareDocketQueryProp) => {
  const queryClient = useQueryClient();
  const emailLink = useMutation({
    mutationFn: ({
      docketPk,
      contactIds,
      newContacts,
    }: ShareDocketMutationProps) => {
      const updateDocketURL = `/dockets/share/${docketPk}/`;
      return DoxleAPI.post(
        updateDocketURL,
        {
          contacts: contactIds,
          newContacts,
        },
        {
          headers: {
            "User-Company": company?.companyId || "",
          },
        }
      );
    },
    onSuccess: (result: AxiosResponse<any>, variables, context) => {
      const qkey = ["permissions-list", variables.docketPk];
      queryClient.setQueryData(qkey, (oldData: any) => {
        if (!oldData) return oldData;
        return {
          ...oldData,
          pages: [
            ...oldData.pages.map((page: any, ind: number) => ({
              ...page,
              data: {
                ...page?.data,
                results: [
                  ...(ind === 0 ? result.data : []),
                  ...page?.data?.results.filter(
                    (permission: ProjectPermission) => {
                      return (
                        result.data.findIndex(
                          (newPermission: ProjectPermission) =>
                            newPermission.contact.contactId !==
                            permission.contact.contactId
                        ) !== -1
                      );
                    }
                  ),
                ],
              },
            })),
          ],
        };
      });
      if (showNotification)
        showNotification("Shared", "success", "An Email Has been Sent");
      if (onSuccessCb) onSuccessCb();
    },
    onError: (error: any, variables, context) => {
      if (showNotification)
        showNotification(
          "Unable to share docket",
          "error",
          String(error?.request?.status) ??
            "Unknown Error:" + String(error?.request?.data) ??
            "No Data"
        );
    },
  });

  const getLink = useMutation({
    mutationFn: ({ docketPk, contactId, userId }: GetDocketLinkProps) => {
      const getURL = `/dockets/share/${docketPk}/`;
      let params: any = {};
      if (contactId) params.contact = contactId;
      if (userId) params.contact = userId;
      return DoxleAPI.get(getURL, {
        headers: {
          "User-Company": company?.companyId || "",
        },
        params,
      });
    },
    onSuccess: (result: AxiosResponse<any>, variables, context) => {
      if (result?.data?.url)
        navigator.clipboard.writeText(result.data.url).then(() => {
          if (showNotification)
            showNotification(
              "The docket link has been copied to your clipboard",
              "success",
              ""
            );
        });
    },
    onError: (error: any, variables, context) => {
      if (showNotification)
        showNotification(
          "Unable to get docket link",
          "error",
          String(error?.request?.status) ??
            "Unknown Error:" + String(error?.request?.data) ??
            "No Data"
        );
    },
  });
  return { emailLink, getLink };
};

interface ExchangeSharedLinkQueryProps {
  linkId: string | undefined;
  showNotification?: (
    message: string,
    messageType: "success" | "error",
    extraMessage?: string,
    duration?: number
  ) => void;
}
interface ExchangeLinkData {
  docket?: Docket;
  company?: Company;
  user?: User;
}
const useExchangeSharedLink = ({
  linkId,
  showNotification,
}: ExchangeSharedLinkQueryProps) => {
  const docketQuery = useQuery(
    ["exchange-link", linkId],
    () => DoxleAPI.get<ExchangeLinkData>(`/dockets/link/${linkId}/`),
    {
      enabled: Boolean(linkId),
      retry: 1,
      staleTime: 4 * 60 * 1000,
      onError: (error) => {
        if (showNotification) {
          if (isAxiosError<AxiosBackendErrorReturn>(error)) {
            showNotification(
              `${error?.response?.status ?? "ERROR"}: ${
                error.response?.data.detail ?? "UNKNOWN ERROR"
              }`,
              "error",
              String(
                error?.response?.data?.detail ?? "Failed to get exchange link"
              ).substring(0, 300)
            );
          } else {
            showNotification(
              "Something Wrong!",
              "error",
              "Failed to get exchange link"
            );
          }
        }
      },
    }
  );
  return docketQuery;
};
const useUpdateDocketValues = () => {
  const companyId = useDoxleCurrentContextStore(
    (state) => state.currentCompany?.companyId,
    shallow
  );
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );
  const { handleUpdateRelatedDocketBudgetQuery } = useSetDocketListQueryData({
    filter: {},
  });
  const updateDocketMutation = useMutation({
    mutationFn: (docketPk: string | undefined) => {
      const getURL = `/dockets/${docketPk}/`;
      return DoxleAPI.get<Docket>(getURL, {
        headers: {
          "User-Company": companyId || "",
        },
      });
    },
    onSuccess: (result, docketPk, context) => {
      handleUpdateRelatedDocketBudgetQuery(result.data.project, result.data);
    },
    onError: (error: any, variables, context) => {
      if (showNotification) {
        if (isAxiosError<AxiosBackendErrorReturn>(error)) {
          showNotification(
            `${error?.response?.status ?? "ERROR"}: ${
              error.response?.data.detail ?? "UNKNOWN ERROR"
            }`,
            "error",
            String(
              error?.response?.data?.detail ?? "Failed to update docket values"
            ).substring(0, 300)
          );
        } else {
          showNotification(
            "Something Wrong!",
            "error",
            "Failed to get update docket values"
          );
        }
      }
    },
  });

  const mutate = (docketPk: string | undefined) =>
    updateDocketMutation.mutate(docketPk);

  return {
    ...updateDocketMutation,
    mutate,
  };
};
interface UpdateStatusProps {
  statusId: string;
  updateStatus: Omit<Partial<DocketStatus>, "statusId">;
}
const useMutateDocketStatus = () => {
  const company = useDoxleCurrentContextStore(
    (state) => state.currentCompany,
    shallow
  );
  const companyId = company?.companyId ?? "";
  const qKey = formDocketStatusQKey({}, company);

  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );
  const queryClient = useQueryClient();
  const updateStatus = useMutation(
    ["update-docket-status", companyId],
    ({ statusId, updateStatus }: UpdateStatusProps) => {
      return DoxleAPI.patch<DocketStatus>(
        `/dockets/status/${statusId}/`,
        updateStatus,
        { headers: { "User-Company": companyId ?? "" } }
      );
    },
    {
      retry: 1,
      onSuccess: (data, variables, context) => {
        queryClient.setQueryData<AxiosResponse<DocketStatus[]> | undefined>(
          qKey,
          (old) => {
            if (!old || !old?.data) return old;
            return {
              ...old,
              data: old.data.map((status) =>
                status.statusId === data.data.statusId ? data.data : status
              ),
            };
          }
        );
      },
      onError: (error: any) => {
        if (showNotification) {
          if (isAxiosError<AxiosBackendErrorReturn>(error)) {
            showNotification(
              `${error?.response?.status ?? "ERROR"}: ${
                error.response?.data.detail ?? "UNKNOWN ERROR"
              }`,
              "error",
              String(
                error?.response?.data?.detail ??
                  "Failed to update docket status"
              ).substring(0, 300)
            );
          } else {
            showNotification(
              "Something Wrong!",
              "error",
              "Failed to update docket status"
            );
          }
        }
      },
      onSettled: (data, err, variables, context) => {
        if (data) {
          queryClient.removeQueries({
            queryKey: [...baseDocketListQKey, companyId],
          });
        }
      },
    }
  );
  interface UpdateStatusIndexProps {
    statusId: string;
    source: number;
    dest: number;
  }
  const { handleUpdateStatusOrder } = useSetStatusQueryData();
  const updateStatusIndex = useMutation(
    ["update-docket-status", companyId],
    ({ statusId, source, dest }: UpdateStatusIndexProps) => {
      return DoxleAPI.patch<DocketStatus>(
        `/dockets/status/${statusId}/`,
        {
          index: dest,
        },
        { headers: { "User-Company": companyId ?? "" } }
      );
    },
    {
      retry: 1,
      onSuccess: (data, variables, context) => {},
      onError: (error: any, variables) => {
        handleUpdateStatusOrder(variables.dest, variables.source, qKey);
        if (showNotification) {
          if (isAxiosError<AxiosBackendErrorReturn>(error)) {
            showNotification(
              `${error?.response?.status ?? "ERROR"}: ${
                error.response?.data.detail ?? "UNKNOWN ERROR"
              }`,
              "error",
              String(
                error?.response?.data?.detail ??
                  "Failed to update docket status"
              ).substring(0, 300)
            );
          } else {
            showNotification(
              "Something Wrong!",
              "error",
              "Failed to update docket status"
            );
          }
        }
      },
    }
  );
  const addStatus = useMutation(
    ["add-docket-status", companyId],
    (newStatus: Omit<DocketStatus, "statusId" | "totalCount">) => {
      return DoxleAPI.post<DocketStatus>(`/dockets/status/`, newStatus, {
        headers: { "User-Company": companyId ?? "" },
      });
    },
    {
      retry: 1,
      onSuccess: (data, variables, context) => {
        queryClient.setQueryData<AxiosResponse<DocketStatus[]> | undefined>(
          qKey,
          (old) => {
            if (!old || !old?.data) return old;
            return {
              ...old,
              data: [...old.data, data.data],
            };
          }
        );
      },
      onError: (error: any) => {
        if (showNotification) {
          if (isAxiosError<AxiosBackendErrorReturn>(error)) {
            showNotification(
              `${error?.response?.status ?? "ERROR"}: ${
                error.response?.data.detail ?? "UNKNOWN ERROR"
              }`,
              "error",
              String(
                error?.response?.data?.detail ?? "Failed to add docket status"
              ).substring(0, 300)
            );
          } else {
            showNotification(
              "Something Wrong!",
              "error",
              "Failed to add docket status"
            );
          }
        }
      },
    }
  );
  interface DeleteProps {
    statusId: string;
    newId?: string | null;
  }
  const deleteStatus = useMutation(
    ["delete-docket-status", companyId],
    ({ statusId, newId }: DeleteProps) => {
      let url = `/dockets/status/${statusId}/`;
      if (newId) url += `?new_status_id=${newId}`;
      else if (newId === null) url += "?cascade=true";
      return DoxleAPI.delete(url, {
        headers: { "User-Company": companyId ?? "" },
      });
    },
    {
      retry: 1,
      onSuccess: (data, { statusId }, context) => {
        queryClient.setQueryData<AxiosResponse<DocketStatus[]> | undefined>(
          qKey,
          (old) => {
            if (!old || !old?.data) return old;
            return {
              ...old,
              data: old.data.filter((status) => status.statusId !== statusId),
            };
          }
        );
      },
      onError: (error: any) => {
        if (showNotification) {
          if (isAxiosError<AxiosBackendErrorReturn>(error)) {
            showNotification(
              `${error?.response?.status ?? "ERROR"}: ${
                error.response?.data.detail ?? "UNKNOWN ERROR"
              }`,
              "error",
              String(
                error?.response?.data?.detail ??
                  "Failed to delete docket status"
              ).substring(0, 300)
            );
          } else {
            showNotification(
              "Something Wrong!",
              "error",
              "Failed to delete docket status"
            );
          }
        }
      },
    }
  );
  return {
    updateStatus: updateStatus.mutate,
    deleteStatus: deleteStatus.mutate,
    addStatus: addStatus.mutate,
    updateStatusIndex: updateStatusIndex.mutate,
  };
};

//# HELPER FUNCITONS
export const baseDocketListQKey = ["fullDocket-list"];
export const formDocketListQueryKey = (data: {
  filter: IFullDocketDetailQueryFilterProp | INBQueryFilterProps;
  company: Company | undefined;
}) => {
  const { filter, company } = data;
  const baseQKey = ["fullDocket-list"];
  if (company) baseQKey.push(company.companyId);
  for (const [key, value] of Object.entries(filter)) {
    if (value) baseQKey.push(`${key}:${value}`);
  }
  return baseQKey;
};
export const getRelatedBudgetListQueryKey = (
  companyId: string | undefined,
  projectId: string | null
) => [
  "fullDocket-list",
  companyId || "",
  "budget:true",
  `project:${projectId || ""}`,
];

export const formDocketStatusQKey = (
  filter: FilterRetrieveDocketStatusList,
  company: Company | undefined
) => {
  const qKey = ["docket-status"];
  if (company) qKey.push(company.companyId);
  const { isNoticeBoardStatus, isBudgetStatus } = filter;
  if (isNoticeBoardStatus) qKey.push("noticeboard");
  if (isBudgetStatus) qKey.push("budget");
  return qKey;
};

export const getDocketMutationKey = (
  action: "add" | "delete" | "update"
): string[] => [`${action}-full-detail-docket`];

export const getDocketDetailQKey = (docketPk: string): string[] => [
  "docket-detail",
  docketPk,
];

const DocketQuery = {
  useRetrieveDocketDetail,
  useRetrieveFullDetailDocketList,
  useUpdateDocketQuery,
  useRetrieveDocketStatusList,
  useAddDocketQuery,
  useDeleteDocketQuery,
  useUpdateManyDockets,
  useShareDocketLink,
  useExchangeSharedLink,
  useUpdateDocketValues,
  useMutateDocketStatus,
  useRetrieveBasicCoreDocketList,
};

export default DocketQuery;
