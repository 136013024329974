import React, { useEffect, useState } from "react";
import {
  StyledQuoteResponseErrorText,
  StyledQuoteResponseFieldWrapper,
  StyledQuoteResponseLabelFieldText,
  StyledQuoteResponseTextField,
} from "./StyledComponentQuoteResponse";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { useQuoteContext } from "./QuoteResponse";
import { produce } from "immer";
import { AnimatePresence } from "framer-motion";

type Props = {};

const AddQuoteResponseReference = (props: Props) => {
  const [referenceInput, setReferenceInput] = useState("");
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore((state) => ({
    doxleThemeColor: state.doxleThemeColor,
    doxleFont: state.doxleFont,
  }));
  const { quoteResponse, setQuoteResponse } = useQuoteContext();

  useEffect(() => {
    const timeout = setTimeout(
      () =>
        setQuoteResponse((prev) =>
          produce(prev, (draft) => {
            draft.reference = referenceInput;
            return draft;
          })
        ),
      300
    );

    return () => clearTimeout(timeout);
  }, [referenceInput]);
  return (
    <StyledQuoteResponseFieldWrapper>
      <StyledQuoteResponseLabelFieldText
        $themeColor={doxleThemeColor}
        $doxleFont={doxleFont}
      >
        REFERENCE NO
      </StyledQuoteResponseLabelFieldText>

      <StyledQuoteResponseTextField
        $themeColor={doxleThemeColor}
        $doxleFont={doxleFont}
        value={referenceInput}
        onChange={(e) => setReferenceInput(e.target.value)}
        variant="standard"
        placeholder="Quote Reference"
        onFocus={(e) => e.currentTarget.select()}
      />

      <AnimatePresence>
        {!quoteResponse.reference && (
          <StyledQuoteResponseErrorText
            $doxleFont={doxleFont}
            initial={{ x: -5, opacity: 0 }}
            animate={{
              x: 0,
              opacity: 1,
            }}
            exit={{ x: -5, opacity: 0 }}
          >
            Please enter reference...
          </StyledQuoteResponseErrorText>
        )}
      </AnimatePresence>
    </StyledQuoteResponseFieldWrapper>
  );
};

export default AddQuoteResponseReference;
