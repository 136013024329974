import { create } from "zustand";
import {
  PricebookItemFilters,
  PricebookSupplierRateFilters,
} from "../Models/PricebookFilters";
import { PricebookSupplierRate } from "../Models/Pricebook";
import { produce } from "immer";

interface PricebookStore {
  view: "Table" | "List";
  setView: (view: "Table" | "List") => void;
  pricebookItemQueryFilter: PricebookItemFilters;
  setPricebookItemQueryFilter: (filter: Partial<PricebookItemFilters>) => void;
  pricebookRateQueryFilter: PricebookSupplierRateFilters;
  clearPricebookRateQueryFilter: () => void;
  setPricebookRateQueryFilter: (
    filter: Partial<PricebookSupplierRateFilters>
  ) => void;
  isSearching: boolean;
  setIsSearching: (value: boolean) => void;
  editedPricebookItemId: null | string; //Pricebook Item Id | "new"
  setEditedPricebookItemId: (item: null | string) => void;

  showAddPricebookDialog: boolean;
  setShowAddPricebookDialog: (value: boolean) => void;

  deletedPricebookRateItem: PricebookSupplierRate | undefined;
  setDeletePricebookRateItem: (item: PricebookSupplierRate | undefined) => void;

  shrinkedPricebookSections: Array<string>; //ids of all the stage that is shrinked, to handle ui
  setShrinkedPricebookSections: (stageId: string) => void;
  clearShrinkedPricebookSections: () => void;
}

export const usePricebookStore = create<PricebookStore>((set, get) => ({
  view: "Table",
  setView: (value: "Table" | "List") => set({ view: value }),
  pricebookItemQueryFilter: {
    page: 1,
    page_size: 25,
  },
  setPricebookItemQueryFilter: (filter: Partial<PricebookItemFilters>) =>
    set({
      pricebookItemQueryFilter: {
        ...get().pricebookItemQueryFilter,
        ...filter,
      },
    }),
  pricebookRateQueryFilter: {
    page: 1,
    page_size: 25,
  },
  setPricebookRateQueryFilter: (
    filter: Partial<PricebookSupplierRateFilters>
  ) =>
    set({
      pricebookRateQueryFilter: {
        ...get().pricebookRateQueryFilter,
        ...filter,
      },
    }),
  clearPricebookRateQueryFilter: () =>
    set({
      pricebookRateQueryFilter: {
        page: 1,
        page_size: 25,
      },
    }),
  isSearching: false,
  setIsSearching: (value: boolean) => {
    if (
      !value &&
      get().view === "Table" &&
      get().pricebookRateQueryFilter.search
    )
      set({
        pricebookRateQueryFilter: {
          ...get().pricebookRateQueryFilter,
          search: undefined,
        },
      });
    if (
      !value &&
      get().view === "List" &&
      get().pricebookItemQueryFilter.search
    )
      set({
        pricebookItemQueryFilter: {
          ...get().pricebookItemQueryFilter,
          search: undefined,
        },
      });
    set({ isSearching: value });
  },
  editedPricebookItemId: null,
  setEditedPricebookItemId: (item: string | null) =>
    set({ editedPricebookItemId: item }),

  showAddPricebookDialog: false,
  setShowAddPricebookDialog: (value: boolean) =>
    set({ showAddPricebookDialog: value }),
  deletedPricebookRateItem: undefined,
  setDeletePricebookRateItem(item: PricebookSupplierRate | undefined) {
    set({ deletedPricebookRateItem: item });
  },

  shrinkedPricebookSections: [],
  setShrinkedPricebookSections: (stageId: string) =>
    set((state) =>
      produce(state, (draftState) => {
        if (state.shrinkedPricebookSections.includes(stageId)) {
          draftState.shrinkedPricebookSections =
            state.shrinkedPricebookSections.filter((id) => id !== stageId);
        } else {
          draftState.shrinkedPricebookSections.push(stageId);
        }

        return draftState;
      })
    ),
  clearShrinkedPricebookSections: () => set({ shrinkedPricebookSections: [] }),
}));
