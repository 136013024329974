import { useCallback, useEffect, useMemo, useState } from "react";
import {
  Contact,
  ContactCompany,
  CREATE_NEW_CONTACT_TEMPLATE,
} from "../../../Models/addressBook";
import { useContactsStore } from "../Store/useContactsStore";
import { shallow } from "zustand/shallow";
import { useDoxleCurrentContextStore } from "../../../DoxleGeneralStore/useDoxleCurrentContext";
import useDoxleNotificationStore from "../../../DoxleGeneralStore/useDoxleNotificationStore";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import ContactsAPI from "../../../Services/QueryHooks/contactsAPI";
import {
  StyledContactsAddMoreCheckBox,
  StyledContactsAddNewRootContainer,
  StyledAddContactTopLeftDiv,
  StyledContactsAddNewInputDiv,
  StyledContactsAddNewInputTitleText,
  StyledContactsAddNewPrimaryContactDiv,
  StyledContactsAddNewPrimaryContactTitleDiv,
  StyledContactsAddNewTopButtonsDiv,
  StyledContactsAddToCompanyButton,
  StyledContactsSubmitButton,
  StyledContactForm,
} from "./styledComponents";
import ContactsTextField from "../GeneralComponents/ContactsTextField";
import CompanyAutoCompleteSelection from "../GeneralComponents/CompanyAutoCompleteSelection";

export interface ICompanyErrorDisplay {
  company: boolean;
  companyAbn?: boolean;
  companyAddress?: boolean;
}
export interface IContactErrorDisplay {
  email?: boolean;
  phoneNumber?: boolean;
}

interface Props {
  onSuccessCb?: (addedContact: Contact) => void;
}

const AddContactDialog = ({ onSuccessCb }: Props) => {
  const initialContact: Contact = useMemo(
    () => ({
      ...CREATE_NEW_CONTACT_TEMPLATE({}),
    }),
    []
  );
  const [addToExistingCompanyBool, setAddToExistingCompanyBool] =
    useState<boolean>(false);
  const [errorDisplay, setErrorDisplay] = useState<ErrorDisplay>({
    email: false,
    phoneNumber: false,
    company: false,
  });
  const [newContact, setNewContact] = useState<Contact>({ ...initialContact });
  const [selectedCompany, setSelectedCompany] = useState<
    ContactCompany | undefined
  >(undefined);
  const company = useDoxleCurrentContextStore(
    (state) => state.currentCompany,
    shallow
  );
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );

  const {
    edittedContact,
    setAddDialogType,
    setEdittedContact,
    filterRetrieveContactQuery,
  } = useContactsStore(
    (state) => ({
      edittedContact: state.edittedContact,
      setAddDialogType: state.setAddDialogType,
      setEdittedContact: state.setEdittedContact,
      filterRetrieveContactQuery: state.contactsQueryFilter,
    }),
    shallow
  );
  const styleProps = useDoxleThemeStore(
    (state) => ({
      $doxleFont: state.doxleFont,
      $themeColor: state.doxleThemeColor,
      $currentTheme: state.currentTheme,
    }),
    shallow
  );
  //!initialize state to editted contact to control changes in this component
  useEffect(() => {
    if (edittedContact) setNewContact({ ...edittedContact });
  }, [edittedContact]);

  useEffect(() => {
    if (selectedCompany) {
      setNewContact((prev) => ({
        ...prev,
        companyName: selectedCompany.name,
        companyAbn: selectedCompany.abn,
        companyAddress: selectedCompany.address,
        companyAddressL1: selectedCompany.addressL1,
        companyAddressL2: selectedCompany.addressL2,
        companyAddressCity: selectedCompany.addressCity,
        companyAddressState: selectedCompany.addressState,
        companyAddressPostCode: selectedCompany.addressPostCode,
      }));
    } else
      setNewContact((prev) => ({
        ...prev,
        contactCompany: "",
        companyName: "",
        companyAbn: "",
        companyAddress: "",
        companyAddressL1: "",
        companyAddressL2: "",
        companyAddressCity: "",
        companyAddressState: "",
        companyAddressPostCode: "",
      }));
  }, [selectedCompany]);

  type ErrorDisplay = ICompanyErrorDisplay & IContactErrorDisplay;
  const emailRegex = /.+\@.+\..+/;
  const numberRegex =
    /^(\+?\(61\)|\(\+?61\)|\+?61|\(0[1-9]\)|0[1-9])?( ?-?[0-9]){7,9}$/m;

  // Check the value of new Contacts using regex
  const contactsRegexCheck = () => {
    let error: ErrorDisplay = {
      email: false,
      phoneNumber: false,
      company: false,
    };
    let errorCounter = 0;
    if (emailRegex.test(newContact.email) === false) {
      console.log("Email Error", newContact.email);
      error.email = true;
      errorCounter = errorCounter + 1;
    }
    if (newContact.phone && numberRegex.test(newContact.phone) === false) {
      console.log("Phone Error", newContact.phone);
      error.phoneNumber = true;
      errorCounter = errorCounter + 1;
    }
    if (errorCounter === 0) {
      return true;
    } else return false;
  };
  const checkValidEmail = useCallback(
    (email: string) => emailRegex.test(email),
    [emailRegex]
  );
  const checkValidPhoneNumber = useCallback(
    (phone: string) => Boolean(phone === "" || numberRegex.test(phone)),
    [numberRegex]
  );

  // Handle when the whole overlay menu closes
  const handleClose = async () => {
    setAddDialogType(null);
    if (edittedContact) setEdittedContact(undefined);
    //#reset fields
    setNewContact({ ...initialContact });
    setSelectedCompany(undefined);
    setAddToExistingCompanyBool(false);
  };

  const handleFirstNameChange = useCallback((value: string) => {
    setNewContact((prev) => ({ ...prev, firstName: value }));
  }, []);

  const handleLastNameChange = useCallback((value: string) => {
    setNewContact((prev) => ({ ...prev, lastName: value }));
  }, []);

  const handleEmailChange = useCallback((value: string) => {
    setNewContact((prev) => ({ ...prev, email: value }));
  }, []);

  const handlePhoneChange = useCallback((value: string) => {
    setNewContact((prev) => ({ ...prev, phone: value }));
  }, []);
  //$$$$$$$$ POST CONTACT FOR NEW COMPANY QUERY $$$$$$$$$$$
  const postContact = ContactsAPI.useAddContactQuery({
    showNotification,
    company,
    filter: filterRetrieveContactQuery,
    onSuccessCb,
  });

  const runPostContact = (event: React.FormEvent<HTMLElement>) => {
    console.log("runPostContact");
    event.preventDefault();
    const contactsRegex = contactsRegexCheck();
    console.log("contactsRegex", contactsRegex);
    if (contactsRegex) {
      postContact.mutate({
        contactId: "",
        firstName: newContact.firstName,
        lastName: newContact.lastName,
        email: newContact.email,
        phone: newContact.phone,
        contactCompany: selectedCompany
          ? selectedCompany.contactCompanyId
          : undefined,
        companyName: selectedCompany ? selectedCompany.name : undefined,
        companyAbn: selectedCompany ? selectedCompany.abn : undefined,
        companyAddress: selectedCompany ? selectedCompany.address : undefined,
        sendQuotes: newContact.sendQuotes,
        userCompany: company?.companyId || "",
      });
      handleClose();
    }
  };

  //$$$$$$$$ EDIT CONTACT WITH NO COMPANY QUERY $$$$$$$$$$$
  const patchContact = ContactsAPI.usePatchContactQuery({
    showNotification,
    company,

    filter: filterRetrieveContactQuery,
  });

  const runPatchContact = (event: React.FormEvent<HTMLElement>) => {
    event.preventDefault();

    console.log("runninnggg");
    const contactsRegex = contactsRegexCheck();
    if (contactsRegex && edittedContact) {
      patchContact.mutate({
        data: {
          firstName: newContact.firstName,
          lastName: newContact.lastName,
          email: newContact.email,
          phone: newContact.phone,
          contactCompany: selectedCompany
            ? selectedCompany.contactCompanyId
            : undefined,
          companyName: selectedCompany ? selectedCompany.name : undefined,
          companyAbn: selectedCompany ? selectedCompany.abn : undefined,
          companyAddress: selectedCompany ? selectedCompany.address : undefined,
          sendQuotes: newContact.sendQuotes,
          userCompany: company?.companyId || "",
        },
        contactId: edittedContact.contactId,
      });
      handleClose();
    }
  };

  return (
    // <StyledContactAddNewWithoutCompanyDialog
    //   open={true}
    //   onClose={handleClose}
    // >
    <StyledContactForm
      onSubmit={(event) => {
        if (!edittedContact) {
          runPostContact(event);
        } else {
          runPatchContact(event);
        }
      }}
    >
      <StyledContactsAddNewRootContainer {...styleProps}>
        <StyledAddContactTopLeftDiv>
          {!edittedContact ? "ADD CONTACT" : "EDIT CONTACT"}
        </StyledAddContactTopLeftDiv>
        <StyledContactsAddNewInputDiv width="100%" marginTop="10px">
          <StyledContactsAddNewInputTitleText
            {...styleProps}
            marginBottom={"0px"}
            paddingRight={"5px"}
          >
            ADD CONTACT TO EXISTING COMPANY?
          </StyledContactsAddNewInputTitleText>
          <StyledContactsAddNewPrimaryContactDiv
            style={{
              paddingTop: "5px",
              paddingBottom: "10px",
              justifyContent: "flex-start",
            }}
          >
            <StyledContactsAddToCompanyButton
              {...styleProps}
              bgColor={addToExistingCompanyBool ? "#7070ff" : "#f7f7f7"}
              onClick={() => setAddToExistingCompanyBool(true)}
            >
              YES
            </StyledContactsAddToCompanyButton>
            <StyledContactsAddToCompanyButton
              {...styleProps}
              bgColor={
                addToExistingCompanyBool === false ? "#7070ff" : "#f7f7f7"
              }
              onClick={() => setAddToExistingCompanyBool(false)}
            >
              NO
            </StyledContactsAddToCompanyButton>
          </StyledContactsAddNewPrimaryContactDiv>
        </StyledContactsAddNewInputDiv>

        <StyledContactsAddNewPrimaryContactTitleDiv
          style={{
            justifyContent: "flex-end",
            alignItems: "center",
            paddingTop: "15px",
          }}
        >
          <StyledContactsAddNewTopButtonsDiv>
            <StyledContactsAddNewInputTitleText
              {...styleProps}
              marginBottom={"0px"}
              paddingRight={"5px"}
            >
              INCLUDE ON QUOTE REQUESTS?
            </StyledContactsAddNewInputTitleText>
            <StyledContactsAddMoreCheckBox
              checked={newContact.sendQuotes}
              onChange={(event) => {
                setNewContact((prev) => ({
                  ...prev,
                  sendQuotes: event.target.checked,
                }));
              }}
            />
          </StyledContactsAddNewTopButtonsDiv>
        </StyledContactsAddNewPrimaryContactTitleDiv>
        <StyledContactsAddNewPrimaryContactDiv>
          <StyledContactsAddNewInputDiv marginTop="0px" width="45%">
            <ContactsTextField
              fieldLabelText="first name"
              required
              fieldValue={newContact.firstName}
              setFieldValue={handleFirstNameChange}
              errorToggle={!newContact.firstName}
              errorText={
                !newContact.firstName ? "Please Enter First Name!" : undefined
              }
              autoFocus
            />
          </StyledContactsAddNewInputDiv>
          <StyledContactsAddNewInputDiv marginTop="0px" width="45%">
            <ContactsTextField
              fieldLabelText="last name"
              required
              fieldValue={newContact.lastName}
              setFieldValue={handleLastNameChange}
              errorToggle={!newContact.lastName}
              errorText={
                !newContact.lastName ? "Please Enter Last Name" : undefined
              }
            />
          </StyledContactsAddNewInputDiv>
        </StyledContactsAddNewPrimaryContactDiv>
        <StyledContactsAddNewPrimaryContactDiv>
          <StyledContactsAddNewInputDiv width="45%">
            <ContactsTextField
              fieldLabelText="email"
              required
              fieldValue={newContact.email}
              setFieldValue={handleEmailChange}
              errorToggle={
                !newContact.email || !checkValidEmail(newContact.email)
              }
              errorText={
                !newContact.lastName
                  ? "Please Enter Last Name"
                  : !checkValidEmail(newContact.email)
                  ? "Please Enter Valid Email"
                  : undefined
              }
            />
          </StyledContactsAddNewInputDiv>
          <StyledContactsAddNewInputDiv width="45%">
            <ContactsTextField
              fieldLabelText="phone number"
              fieldValue={newContact.phone}
              setFieldValue={handlePhoneChange}
              errorToggle={
                !newContact.phone || !checkValidPhoneNumber(newContact.phone)
              }
              errorText={
                !checkValidPhoneNumber(newContact.phone)
                  ? "Please Enter Valid Phone Number"
                  : undefined
              }
            />
          </StyledContactsAddNewInputDiv>
        </StyledContactsAddNewPrimaryContactDiv>
        {addToExistingCompanyBool && (
          <StyledContactsAddNewInputDiv width="100%">
            <CompanyAutoCompleteSelection
              currentValue={selectedCompany}
              setNewContactCompany={setSelectedCompany}
            />
          </StyledContactsAddNewInputDiv>
        )}
        <StyledContactsAddNewPrimaryContactDiv
          style={{
            paddingTop: errorDisplay.company ? "27px" : "50px",
          }}
        >
          <StyledContactsSubmitButton
            {...styleProps}
            fullWidth={true}
            type="submit"
            onClick={() => {}}
          >
            SUBMIT
          </StyledContactsSubmitButton>
        </StyledContactsAddNewPrimaryContactDiv>
      </StyledContactsAddNewRootContainer>
    </StyledContactForm>
    // </StyledContactAddNewWithoutCompanyDialog>
  );
};
export default AddContactDialog;
