import { shallow } from "zustand/shallow";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { DragIcon, StatusKebabIcon } from "../Assets/icons";
import EditStatusTextField from "../StatusComponents/EditStatusTextField";
import {
  StyledDragIconContainer,
  StyledEditStatusTile,
  StyledEditStatusTileWrapper,
  StyledStatusColorDot,
} from "../styledComponents";
import { ProjectStatus } from "../../../Models/project";
import { DraggableProvided } from "@hello-pangea/dnd";
import { DocketStatus } from "../../../Models/dockets";
import { TRgbaFormat } from "../../../Utilities/FunctionUtilities";

interface props {
  provided: DraggableProvided;
  statusColor: TRgbaFormat;
  statusName: string;
}

const StatusCloneObject = ({ statusColor, statusName, provided }: props) => {
  const styleProps = useDoxleThemeStore(
    (state) => ({
      $themeColor: state.doxleThemeColor,
      $doxleFont: state.doxleFont,
      $currentTheme: state.currentTheme,
    }),
    shallow
  );

  const color = statusColor==="rgba(0,0,0,1)"?styleProps.$themeColor.primaryFontColor:statusColor;


  return (
    <StyledEditStatusTileWrapper
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
    >
      <StyledDragIconContainer>
      <DragIcon themeColor={styleProps.$themeColor} />
      </StyledDragIconContainer>
      <StyledEditStatusTile
        $statusColor={color}
        {...styleProps}
      >
        <StyledStatusColorDot
          $statusColor={color}
          $size={"8px"}
        />
        <div style={{color: styleProps.$themeColor.primaryFontColor}}>{statusName}</div>
        <StatusKebabIcon statusColor={color} />
      </StyledEditStatusTile>
    </StyledEditStatusTileWrapper>
  );
};

export default StatusCloneObject;