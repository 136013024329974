import {
  StyledUnmappedAccountAlertContainer,
  StyledUnmappedAccountDropDown,
  StyledUnmappedAccountRow
} from "./styledComponents";
import {GoAlert} from "react-icons/go";
import { GoCheckCircle } from "react-icons/go";
import React, {memo} from "react";
import UnmappedAccountRow from "./UnmappedAccountRow";
import {CircularProgress} from "@mui/material";
import {AccountWithCost} from "../Hooks/useAccountMapping";
interface Props {
  sortedUnmatchedAccounts: AccountWithCost[];
  isSuccessFetchingDocketList: boolean;
  unmappedAccountsWithCost: string[];
}
const UnmappedAccountAlert = memo(({
  sortedUnmatchedAccounts,
  isSuccessFetchingDocketList,
  unmappedAccountsWithCost,
}: Props) => {
  if (!isSuccessFetchingDocketList) return <CircularProgress style={{marginLeft: 5, marginTop: 4, width: 25, height: 25}}/>
  return (
    <StyledUnmappedAccountAlertContainer $alertColor={
      sortedUnmatchedAccounts.length === 0
        ? 'rgba(17, 183, 24, 1)'
        : unmappedAccountsWithCost.length
          ? 'rgba(255, 0, 0, 1)'
          : 'rgba(253, 183, 4, 1)'
    }>
      {sortedUnmatchedAccounts.length === 0
        ? <GoCheckCircle />
        : <GoAlert />
      }
      <StyledUnmappedAccountDropDown className={'unmapped-accounts'}>
        <StyledUnmappedAccountRow $hasCost={null}>
          {sortedUnmatchedAccounts.length === 0 ? 'All accounts have a docket' : 'Accounts With No Docket'}
        </StyledUnmappedAccountRow>
        {sortedUnmatchedAccounts.map(acc => (
          <UnmappedAccountRow
            account={acc}
          />
        ))}
      </StyledUnmappedAccountDropDown>
    </StyledUnmappedAccountAlertContainer>
  )
})

export default UnmappedAccountAlert
