import React from "react";
import {
  StyledBudgetFilterMenuLine,
  StyledBudgetHeaderContainer,
  StyledBudgetHeaderMenuMenuItem,
  StyledBudgetHeaderMenuTextField,
  StyledBudgetHeaderSpaceBetweenDiv,
  StyledBudgetHeaderSpan,
  StyledBudgetRowPadding,
  StyledBudgetSearchField,
  StyledBudgetTitleOptionsText,
  StyledCheckboxFormControl,
} from "./BudgetStyleComponent";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import { ArrowRight } from "@mui/icons-material";

import { Checkbox, FormGroup, Menu } from "@mui/material";
import {
  CheckedIcon,
  StyledBudgetDueThisMonthIcon,
  StyledBudgetDueThisWeekIcon,
  StyledBudgetDueTodayIcon,
  StyledBudgetFilterOverBudgetIcon,
  StyledBudgetFilterPersonIcon,
  StyledBudgetFilterStatusIcon,
  UncheckboxBudgetFilter,
} from "./BudgetIcon";
import {
  BUDGET_HEADER_FILTER_LIST,
  IBudgetMainFilter,
} from "../Models/BudgetDocket";
import { useBudgetStore } from "../Store/useBudgetStore";
import ViewFilter from "./ViewFilter";

import useGetBudgetStatusList from "./Hooks/useGetBudgetStatusList";
import useBudgetHeader from "./Hooks/useBudgetHeader";
import DoxleEmptyPlaceHolder from "../../DoxleDesignPattern/DoxleEmptyPlaceHolder/DoxleEmptyPlaceHolder";
import DoxleStatus from "../../DoxleDesignPattern/DoxleStatus/DoxleStatus";
import { AnimatePresence } from "framer-motion";
import FilterListRoundedIcon from "@mui/icons-material/FilterListRounded";
import DoxleFilterTag from "../../DoxleDesignPattern/DoxleFilterTag/DoxleFilterTag";

const BudgetHeader = () => {
  // Stores
  const {
    filterDocketListQuery,
    setFilterDocketListQuery,
    isCompact,
    setIsCompact,
    isFullStatus,
    setIsFullStatus,
  } = useBudgetStore((state) => ({
    filterDocketListQuery: state.filterDocketListQuery,
    setFilterDocketListQuery: state.setFilterDocketListQuery,
    isCompact: state.isCompact,
    setIsCompact: state.setIsCompact,
    isFullStatus: state.isFullStatus,
    setIsFullStatus: state.setIsFullStatus,
  }));
  const {
    searchInput,

    handleRemoveFilter,
    handleSearchInputTextChange,
    mainFilterAnchor,
    statusFilterAnchor,
    setMainFilterAnchor,
    setStatusFilterAnchor,
  } = useBudgetHeader({});
  const { doxleThemeColor, doxleFont, currentTheme } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
      currentTheme: state.currentTheme,
    }),
    shallow
  );
  const { docketStatusList, isErrorFetchingStatusList } =
    useGetBudgetStatusList({});

  //render icons
  const handleFilterMenuIcon = (name: IBudgetMainFilter) => {
    switch (name) {
      case "Due Today":
        return <StyledBudgetDueTodayIcon {...doxleThemeColor} />;
      case "Due This Week":
        return <StyledBudgetDueThisWeekIcon {...doxleThemeColor} />;
      case "Due This Month":
        return <StyledBudgetDueThisMonthIcon {...doxleThemeColor} />;
      case "Person":
        return <StyledBudgetFilterPersonIcon {...doxleThemeColor} />;
      case "Over Budget":
        return <StyledBudgetFilterOverBudgetIcon {...doxleThemeColor} />;
      case "Status":
        return (
          <StyledBudgetFilterStatusIcon
            color={doxleThemeColor.primaryFontColor}
          />
        );
    }
  };

  const { isBudgetProjectLevel } = useBudgetStore((state) => ({
    isBudgetProjectLevel: state.isBudgetProjectLevel,
  }));
  if (!isBudgetProjectLevel)
    return (
      <StyledBudgetHeaderContainer>
        <StyledBudgetHeaderSpaceBetweenDiv>
          {!isBudgetProjectLevel && (
            <StyledBudgetSearchField
              $themeColor={doxleThemeColor}
              $doxleFont={doxleFont}
              value={searchInput}
              onChange={(event) =>
                handleSearchInputTextChange(event.target.value)
              }
              placeholder={"Search Budget Dockets"}
            />
          )}
          <Menu
            anchorEl={mainFilterAnchor}
            open={Boolean(mainFilterAnchor)}
            onClose={() => setMainFilterAnchor(null)}
            elevation={4}
            slotProps={{
              paper: {
                style: {
                  display: "flex",
                  width: "29rem",
                  height: "41.5rem",
                  justifyContent: "center",
                  backgroundColor: doxleThemeColor.primaryContainerColor,
                },
                sx: {
                  "& .MuiMenuItem-root.Mui-selected": {
                    backgroundColor: doxleThemeColor.primaryDividerColor,
                  },
                  "& .MuiMenuItem-root:hover": {
                    backgroundColor: doxleThemeColor.hoverColor,
                  },
                  // "& .MuiMenuItem-root.Mui-selected:hover": {
                  //   backgroundColor: doxleThemeColor.bolderDividerColor,
                  // },
                },
              },
            }}
          >
            <StyledBudgetHeaderMenuTextField
              $themeColor={doxleThemeColor}
              $doxleFont={doxleFont}
              placeholder="Filter by..."
            />
            {BUDGET_HEADER_FILTER_LIST.map((filter, idx) => {
              if (filter.isDisplayed) {
                if (filter.filterName.includes("Due")) {
                  return filter.filterName !== "Due This Month" ? (
                    <StyledBudgetHeaderMenuMenuItem
                      onClick={() => {
                        if (
                          (filterDocketListQuery.due === "today" &&
                            filter.filterName === "Due Today") ||
                          (filterDocketListQuery.due === "week" &&
                            filter.filterName === "Due This Week") ||
                          (filterDocketListQuery.due === "month" &&
                            filter.filterName === "Due This Month")
                        ) {
                          setFilterDocketListQuery({
                            ...filterDocketListQuery,
                            due: undefined,
                          });
                        } else {
                          setFilterDocketListQuery({
                            ...filterDocketListQuery,
                            due:
                              filter.filterName === "Due Today"
                                ? "today"
                                : filter.filterName === "Due This Week"
                                ? "week"
                                : filter.filterName === "Due This Month"
                                ? "month"
                                : undefined,
                          });
                        }
                      }}
                      $themeColor={doxleThemeColor}
                      $doxleFont={doxleFont}
                      key={`filter#${filter.filterName}#${idx}`}
                    >
                      {((filterDocketListQuery.due === "today" &&
                        filter.filterName === "Due Today") ||
                        (filterDocketListQuery.due === "week" &&
                          filter.filterName === "Due This Week")) && (
                        <ArrowRight
                          color="inherit"
                          style={{
                            color:
                              currentTheme === "dark"
                                ? doxleThemeColor.primaryFontColor
                                : "rgba(112, 112, 112, 1)",
                            padding: "0px",
                            margin: "-6px",
                            cursor: "pointer",
                          }}
                        />
                      )}
                      {handleFilterMenuIcon(filter.filterName)}
                      {filter.filterName}
                    </StyledBudgetHeaderMenuMenuItem>
                  ) : (
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                      }}
                      key={`filter#${filter.filterName}#${idx}`}
                    >
                      <StyledBudgetHeaderMenuMenuItem
                        onClick={() => {
                          if (
                            (filterDocketListQuery.due === "today" &&
                              filter.filterName === "Due Today") ||
                            (filterDocketListQuery.due === "week" &&
                              filter.filterName === "Due This Week") ||
                            (filterDocketListQuery.due === "month" &&
                              filter.filterName === "Due This Month")
                          ) {
                            setFilterDocketListQuery({
                              ...filterDocketListQuery,
                              due: undefined,
                            });
                          } else {
                            setFilterDocketListQuery({
                              ...filterDocketListQuery,
                              due:
                                filter.filterName === "Due Today"
                                  ? "today"
                                  : filter.filterName === "Due This Week"
                                  ? "week"
                                  : filter.filterName === "Due This Month"
                                  ? "month"
                                  : undefined,
                            });
                          }
                        }}
                        $themeColor={doxleThemeColor}
                        $doxleFont={doxleFont}
                        style={{ marginTop: 0 }}
                      >
                        {filterDocketListQuery.due === "month" &&
                          filter.filterName === "Due This Month" && (
                            <ArrowRight
                              color="inherit"
                              style={{
                                color:
                                  currentTheme === "dark"
                                    ? doxleThemeColor.primaryFontColor
                                    : "rgba(112, 112, 112, 1)",
                                padding: "0px",
                                margin: "-6px",
                                cursor: "pointer",
                              }}
                            />
                          )}
                        {handleFilterMenuIcon(filter.filterName)}
                        {filter.filterName}
                      </StyledBudgetHeaderMenuMenuItem>
                      <StyledBudgetFilterMenuLine />
                    </div>
                  );
                } else
                  return (
                    <StyledBudgetHeaderMenuMenuItem
                      key={`filter#${filter.filterName}#${idx}`}
                      onClick={(event: {
                        currentTarget: React.SetStateAction<HTMLElement | null>;
                      }) => {
                        if (filter.filterName === "Person") {
                        }
                        if (filter.filterName === "Status") {
                          setStatusFilterAnchor(event.currentTarget);
                        }

                        if (filter.filterName === "Over Budget") {
                          if (filterDocketListQuery.budget === "over") {
                            setFilterDocketListQuery({
                              ...filterDocketListQuery,
                              budget: undefined,
                            });
                          } else {
                            setFilterDocketListQuery({
                              ...filterDocketListQuery,
                              budget: "over",
                            });
                          }
                        }
                      }}
                      $themeColor={doxleThemeColor}
                      $doxleFont={doxleFont}
                    >
                      {filter.filterName === "Over Budget" &&
                        filterDocketListQuery.budget === "over" && (
                          <ArrowRight
                            color="inherit"
                            style={{
                              color:
                                currentTheme === "dark"
                                  ? doxleThemeColor.primaryFontColor
                                  : "rgba(112, 112, 112, 1)",
                              padding: "0px",
                              margin: "-6px",
                              cursor: "pointer",
                            }}
                          />
                        )}
                      {handleFilterMenuIcon(filter.filterName)}
                      {filter.filterName}
                    </StyledBudgetHeaderMenuMenuItem>
                  );
              }
              return null;
            })}
          </Menu>
          <Menu
            anchorEl={statusFilterAnchor}
            open={Boolean(statusFilterAnchor)}
            onClose={() => setStatusFilterAnchor(null)}
            elevation={4}
            slotProps={{
              paper: {
                style: {
                  display: "flex",
                  width: "14rem",
                  justifyContent: "center",
                  padding: "0.5rem",
                  backgroundColor: doxleThemeColor.primaryContainerColor,
                  // boxShadow: "0.1",
                },
                sx: {
                  "& .MuiMenuItem-root.Mui-selected": {
                    backgroundColor: doxleThemeColor.primaryDividerColor,
                  },
                  "& .MuiMenuItem-root:hover": {
                    backgroundColor: doxleThemeColor.hoverColor,
                  },
                  "& .MuiMenu-list": {
                    width: "100%",
                  },
                },
              },
            }}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
          >
            {isErrorFetchingStatusList && (
              <DoxleEmptyPlaceHolder
                headTitleText="Something Wrong!"
                subTitleText="Failed to get status list..."
                containerStyle={{
                  height: "10rem",
                  width: "100%",
                }}
                headTitleTextStyle={{
                  fontSize: "1.1rem",
                }}
                subTitleTextStyle={{
                  fontSize: "0.8rem",
                }}
              />
            )}
            {!isErrorFetchingStatusList &&
              docketStatusList.map((status) => {
                return (
                  <StyledBudgetHeaderMenuMenuItem
                    key={`${status.statusId}`}
                    $themeColor={doxleThemeColor}
                    $doxleFont={doxleFont}
                    onClick={() => {
                      if (filterDocketListQuery.status === status.statusId) {
                        setFilterDocketListQuery({
                          ...filterDocketListQuery,
                          status: undefined,
                        });
                      } else {
                        setFilterDocketListQuery({
                          ...filterDocketListQuery,
                          status: status.statusId,
                        });
                      }
                    }}
                    style={{
                      padding: "0.3rem 0.35rem 0.3rem 1rem",
                    }}
                  >
                    {filterDocketListQuery.status === status.statusId && (
                      <ArrowRight
                        color="inherit"
                        style={{
                          color: doxleThemeColor.primaryFontColor,
                        }}
                      />
                    )}
                    <DoxleStatus
                      statusColor={status.color as any}
                      type="icon"
                      style={{
                        marginRight: "0.44rem",
                      }}
                    />
                    <span
                      className="status-text"
                      style={{ color: status.color }}
                    >
                      {status.statusName}
                    </span>
                  </StyledBudgetHeaderMenuMenuItem>
                );
              })}
          </Menu>
          <StyledBudgetHeaderSpan
            style={{
              flex: 1,
              justifyContent: isBudgetProjectLevel ? "flex-start" : "center",
            }}
          >
            <AnimatePresence>
              {filterDocketListQuery.due && (
                <StyledBudgetRowPadding $paddingRight="20px">
                  <DoxleFilterTag
                    itemData={<></>}
                    itemDisplayText={filterDocketListQuery.due}
                    removeItemFunction={() => handleRemoveFilter("Due Date")}
                  />
                </StyledBudgetRowPadding>
              )}
              {filterDocketListQuery.budget === "over" && (
                <StyledBudgetRowPadding $paddingRight="20px">
                  <DoxleFilterTag
                    itemData={<></>}
                    itemDisplayText={"Over Budget"}
                    removeItemFunction={() => handleRemoveFilter("Over Budget")}
                  />
                </StyledBudgetRowPadding>
              )}
              {filterDocketListQuery.status && (
                <StyledBudgetRowPadding $paddingRight="20px">
                  <DoxleFilterTag
                    itemData={<></>}
                    itemDisplayText={
                      docketStatusList.filter(
                        (status) =>
                          status.statusId === filterDocketListQuery.status
                      )?.[0]?.statusName ?? "Status"
                    }
                    removeItemFunction={() => handleRemoveFilter("Status")}
                  />
                </StyledBudgetRowPadding>
              )}
            </AnimatePresence>
          </StyledBudgetHeaderSpan>
          <StyledBudgetHeaderSpan>
            <StyledBudgetTitleOptionsText
              $themeColor={doxleThemeColor}
              $doxleFont={doxleFont}
              onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
                setMainFilterAnchor(event.currentTarget)
              }
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <FilterListRoundedIcon
                style={{
                  fontSize: "1.8rem",
                  marginRight: 4,
                  color: "inherit",
                }}
              />
              Filter
            </StyledBudgetTitleOptionsText>

            <FormGroup sx={{ display: "flex", flexDirection: "row" }}>
              <StyledCheckboxFormControl
                $themeColor={doxleThemeColor}
                $doxleFont={doxleFont}
                control={
                  <Checkbox
                    checked={isCompact}
                    onChange={(event) => setIsCompact(event.target.checked)}
                    icon={<UncheckboxBudgetFilter />}
                    checkedIcon={<CheckedIcon />}
                  />
                }
                label="Compact View"
              />
              <StyledCheckboxFormControl
                $themeColor={doxleThemeColor}
                $doxleFont={doxleFont}
                control={
                  <Checkbox
                    checked={isFullStatus}
                    onChange={(event) => setIsFullStatus(event.target.checked)}
                    icon={<UncheckboxBudgetFilter />}
                    checkedIcon={<CheckedIcon />}
                  />
                }
                label="Show Full Status"
              />
            </FormGroup>

            <ViewFilter />
          </StyledBudgetHeaderSpan>
        </StyledBudgetHeaderSpaceBetweenDiv>
      </StyledBudgetHeaderContainer>
    );
  return null;
};

export default BudgetHeader;
