import "./App.css";
import {
  StyledAppContainer,
  StyledAppContentSection,
} from "./StyledComponentApp";
import { useDoxleThemeStore } from "./DoxleGeneralStore/useDoxleThemeStore";
import { usePageTransitionContextStore } from "./DoxleGeneralStore/usePageTransitionContextStore";
import { shallow } from "zustand/shallow";
import { useDoxleAuthStore } from "./DoxleGeneralStore/useDoxleAuthStore";
import { useLocation, Routes as RouterRoutes, Route } from "react-router-dom";
import { PageTransition } from "@steveeeie/react-page-transition";
import MagicLinkLandingPage from "./CoreContent/DoxleLoginScreen/MagicLinkLandingPage";
import { useDoxleCurrentContextStore } from "./DoxleGeneralStore/useDoxleCurrentContext";
import AddCompanyScreen from "./CoreContent/AddCompanyScreen/AddCompanyScreen";
import Error404 from "./CoreContent/ErrorPages/Error404";
import DoxleTopMenu from "./CoreContent/DoxleTopMenu/DoxleTopMenu";
import Xero from "./Xero/Content/Xero";
import Project from "./CoreContent/Projects/Project";
import SharedDocketLandingPage from "./CoreContent/ShareDocketDialog/SharedDocketLandingPage";
import GreetingPageDisplayer from "./CoreContent/UpdatedGreetingScreen/pages/GreetingPageDisplayer";

//!!! EXTERNAL REPOS !!!//
import Budget from "./Budgets/Table/Budget";
// import Timeline from "./Timeline/Components/Timeline";
import OrdersPage from "./Orders/Content/OrderPage/OrdersPage";
import Contacts from "./Contacts/Content/Contacts";
import Pricebook from "./Pricebook/Content/Pricebook";
import Estimates from "./Estimates/Content/Estimates";
import RFI from "./RFI/Content/RFI";
import AddProjectScreen from "./CoreContent/AddProjectScreen/AddProjectScreen";
import Measurement from "./Measurements/Components/Measurements";
import MailConversation from "./Mail/Content/MailConversation/mailConversation";
import ShareProjectScreen from "./CoreContent/ShareProjectScreen/ShareProjectScreen";
import SharedProject from "./CoreContent/Projects/SharedProject";
import Files from "./Files/Content/Files";
import GridFilesInsideFolderView from "./Files/Content/GridFilesInsideFolderView";
import Inbox from "./Inbox/Components/Inbox";
import RFIRequestPage from "./RFI/Content/RFIDetail/RFIRequestPage/RFIRequestPage";
import QuoteResponsePage from "./Quotes/Content/QuoteResponsePage/QuoteResponse";
import Bookings from "./Bookings/CommonComponents/Bookings";
import OurStory from "./CoreContent/OurStory/OurStory";
import ContactUs from "./CoreContent/UpdatedGreetingScreen/pages/ContactUs";
import CompanySettingsScreen from "./CoreContent/CompanySettingsScreen/CompanySettingsScreen";
import InventoryScreen from "./Inventory/InventoryScreen";
import dayjs from "dayjs";
import updateLocale from "dayjs/plugin/updateLocale";
import { isAppDotDoxle } from "./Services/DoxleAPI";
import SharedOrder from "./Orders/Content/SharedOrder/SharedOrder";
import Loading from "./Utilities/Lottie/Loading";
import DoxleGallery from "./DoxleDesignPattern/DoxleGallery/DoxleGallery";
import Doxle404Screen from "./DoxleDesignPattern/Doxle404Screen/Doxle404Screen";
import DoxleLoginScreenTest from "./CoreContent/UpdatedGreetingScreen/pages/DoxleLoginScreenTest";
import ProjectDrawer from "./CoreContent/Projects/DoxleProjectDrawer/ProjectDrawer";
import { Suspense } from "react";
import DoxleErrorFileWarning from "./DoxleDesignPattern/DoxleErrorFileWarning/DoxleErrorFileWarning";
import { useShallow } from "zustand/react/shallow";

dayjs.extend(updateLocale);

dayjs.updateLocale("en", {
  weekStart: 1,
});

function App() {
  const { preset, enterAnimation, exitAnimation } =
    usePageTransitionContextStore(
      useShallow((state) => ({
        preset: state.preset,
        enterAnimation: state.enterAnimation,
        exitAnimation: state.exitAnimation,
      }))
    );

  const { loggedIn } = useDoxleAuthStore(
    useShallow((state) => ({ loggedIn: state.loggedIn }))
  );
  const doxleThemeColor = useDoxleThemeStore(
    useShallow((state) => state.doxleThemeColor)
  );

  const { currentCompany, currentMenuPos } = useDoxleCurrentContextStore(
    useShallow((state) => ({
      currentCompany: state.currentCompany,
      currentMenuPos: state.currentMenuPosition,
    }))
  );

  const location = useLocation();

  const topMenuEnabled = Boolean(
    !window.location.href.includes("/ShareProject/")
  );
  const host = window.location.hostname.toLowerCase();

  // Display loader, not home screen until user check is made
  if (loggedIn === undefined)
    return (
      <StyledAppContainer $themeColor={doxleThemeColor}>
        <StyledAppContentSection>
          <Loading
            containerStyle={{
              width: "100%",
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
            textStyle={{
              fontSize: 18,
            }}
          />
        </StyledAppContentSection>
      </StyledAppContainer>
    );
  // Home and login
  if (!isAppDotDoxle())
    return (
      <StyledAppContainer $themeColor={doxleThemeColor}>
        <StyledAppContentSection>
          <PageTransition
            preset={preset}
            transitionKey={location.pathname}
            enterAnimation={enterAnimation}
            exitAnimation={""}
          >
            <GreetingPageDisplayer />
          </PageTransition>
        </StyledAppContentSection>
      </StyledAppContainer>
    );
  // Projects and local testing
  return (
    <StyledAppContainer $themeColor={doxleThemeColor}>
      {loggedIn && topMenuEnabled && currentMenuPos === "Top" && (
        <DoxleTopMenu />
      )}

      <StyledAppContentSection>
        <PageTransition
          preset={preset}
          transitionKey={location.pathname}
          enterAnimation={enterAnimation}
          exitAnimation={""}
        >
          {window.location.href.toLowerCase().includes("inventory") ? (
            <RouterRoutes location={location}>
              {!loggedIn && (
                <Route path={"*"} element={<GreetingPageDisplayer />} />
              )}
              {!currentCompany && (
                <Route path={"*"} element={<AddCompanyScreen />} />
              )}
              <Route path={"CompanySettings/"} element={<AddCompanyScreen />} />
              <Route path={"Settings/"} element={<CompanySettingsScreen />} />
              <Route path="*" element={<InventoryScreen />} />
            </RouterRoutes>
          ) : window.location.href.toLowerCase().includes("noticeboard") ? (
            <RouterRoutes location={location}>
              {!loggedIn && (
                <Route path={"*"} element={<GreetingPageDisplayer />} />
              )}
              {!currentCompany && (
                <Route path={"*"} element={<AddCompanyScreen />} />
              )}
              <Route path={"CompanySettings/"} element={<AddCompanyScreen />} />
              <Route path={"Settings/"} element={<CompanySettingsScreen />} />
              <Route path={"*"} element={<Inbox />} />
            </RouterRoutes>
          ) : (
            <Suspense
              fallback={
                <Loading
                  containerStyle={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    width: "100%",
                  }}
                  textStyle={{
                    fontSize: 18,
                  }}
                />
              }
            >
              <RouterRoutes location={location}>
                {/* Submodule Paths for Logged-In Users */}
                {/* Comment Out Route and import line if you do not have access */}
                {/* Other Core Paths for unauthorised viewers */}
                {/*<Route path={"/Login/*" || "login/*"} element={<DoxleLoginScreen />} />*/}
                <Route
                  path="/ResponseLinkLogin/"
                  element={<MagicLinkLandingPage />}
                />
                <Route
                  path="/Shared/:linkId/:tab/*"
                  element={<SharedDocketLandingPage />}
                />
                {/* Submodule branches for unauthorised viewers */}
                {/* //!!! EXTERNAL REPOS !!!// */}
                <Route path="/RFI/*" element={<RFIRequestPage />} />
                <Route path="/Quote/*" element={<QuoteResponsePage />} />
                <Route
                  path={"SharedProject/:projectLinkId/"}
                  element={<SharedProject />}
                />
                <Route
                  path={"SharedProject/:projectLinkId/:tab/"}
                  element={<SharedProject />}
                />
                <Route
                  path={"SharedOrder/:linkId/"}
                  element={<SharedOrder />}
                />
                {/* Catch all URLs apart from those above where the user is not logged in */}
                {/* Magic Links should go above this line, all other components below */}
                {/*Roobert's content*/}$
                {!loggedIn && (
                  <Route path={"*"} element={<GreetingPageDisplayer />} />
                )}
                {/*<Route path={"/Login/*" || "login/*"} element={<DoxleLoginScreen />} />*/}
                {/*${!loggedIn && <Route path={"*"} element={<DoxleInitialGreetingScreen />} />}*/}
                {/* Catch all URLs if user has no company permissions */}
                {!currentCompany && (
                  <Route path={"*"} element={<AddCompanyScreen />} />
                )}
                {/*/!* Core Content *!/*/}
                <Route
                  path={"CompanySettings/"}
                  element={<AddCompanyScreen />}
                />
                <Route path={"Settings/"} element={<CompanySettingsScreen />} />
                <Route path={"Projects"} element={<Project />} />
                <Route path={"Project"} element={<Project />} />
                <Route path={"Project/:projectId/"} element={<Project />} />
                <Route path={"AddProject"} element={<AddProjectScreen />} />
                <Route
                  path={"EditProject/:projectId/"}
                  element={<AddProjectScreen />}
                />
                <Route
                  path={"/ShareProject/:projectId/"}
                  element={<ShareProjectScreen />}
                />
                <Route path={"Noticeboard"} element={<Inbox />} />
                <Route path={"Draw"} element={<Error404 />} />
                <Route path={"Drop"} element={<Error404 />} />
                {/*/!* Submodule Paths for Logged-In Users *!/*/}
                {/* //!!! EXTERNAL REPOS !!!// */}
                <Route path={"Estimates"} element={<Estimates />} />
                <Route path={"QuickTender"} element={<Estimates />} />
                <Route path={"Orders"} element={<OrdersPage />} />
                <Route path={"Invoices"} element={<OrdersPage />} />
                <Route path={"Budget"} element={<Budget />} />
                <Route path={"Budgets"} element={<Budget />} />
                <Route path={"Bookings"} element={<Bookings />} />
                {/*<Route path={"Timeline"} element={<Timeline />} /> */}
                <Route path={"Files"} element={<Files />} />
                <Route
                  path={"Files/*"}
                  element={<GridFilesInsideFolderView />}
                />
                <Route path={"Pricebook"} element={<Pricebook />} />
                <Route path={"Contacts"} element={<Contacts />} />
                <Route path={"RFI"} element={<RFI />} />
                <Route path={"Xero/*"} element={<Xero />} />
                <Route path={"TakeOff/*"} element={<Measurement />} />
                {/*<Route path={"Mail"} element={<MailPage project={false} />} />*/}
                <Route path={"Mail/:mailId/"} element={<MailConversation />} />
                <Route path={"OurStory/"} element={<OurStory />} />
                <Route path="GetInTouch/" element={<ContactUs />} />
                <Route path="Inventory/" element={<InventoryScreen />} />
                <Route path="404/" element={<Doxle404Screen />} />
                <Route path="HarryLogin/" element={<DoxleLoginScreenTest />} />
                <Route path="/ProjectDrawer/" element={<ProjectDrawer />} />
                {/*<Route path={"IFC/"} element={<IFCViewer />} />*/}
                <Route path={"*"} element={<Project />} />
                {/* Comment Out Route and import line if you do not have access */}
                {/*/!* Other Core Paths for Logged-In Users *!/*/}
                <Route path={"*"} element={<Error404 />} />
              </RouterRoutes>
            </Suspense>
          )}
        </PageTransition>
      </StyledAppContentSection>
      {loggedIn && topMenuEnabled && currentMenuPos === "Bottom" && (
        <DoxleTopMenu />
      )}
      {/*</div>*/}
      <DoxleGallery />
      <DoxleErrorFileWarning />
    </StyledAppContainer>
  );
}

export default App;
