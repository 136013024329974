import React, { useEffect, useState, KeyboardEvent, ChangeEvent } from "react";
import { useDoxleAuthStore } from "../../../../DoxleGeneralStore/useDoxleAuthStore";
import { Estimate } from "../../../Models/estimatesFilters";
import { useDoxleCurrentContextStore } from "../../../../DoxleGeneralStore/useDoxleCurrentContext";
import useDoxleNotificationStore from "../../../../DoxleGeneralStore/useDoxleNotificationStore";
import { useEstimatesStore } from "../../Store/useEstimatesStore";
import { useEditDocketSideScreenStore } from "../../../../CoreContent/EditDocketSideScreen/EditDocketSideScreenStore/useEditDocketSideScreenStore";
import { shallow } from "zustand/shallow";
import EstimatesQueryAPI, {
  DestroyEstimateProps,
  getMutateEstimateKey,
} from "../../../QueryHooks/EstimatesQueryAPI";
import { useEditEstimatesStore } from "../../Store/useEditEstimateStore";
import { useIsMutating } from "@tanstack/react-query";

//!!! EXTERNAL REPOS !!!//
import TakeOffQueryAPI from "../../../../Measurements/Hooks/TakeOffQueryAPI";
import { TakeOff } from "../../../../Measurements/Models/takeOffs";
const takeOffUnits = ["EA", "LM", "M2", "M3"];

type Props = { item: Estimate };
function isNumeric(value: string) {
  return /(^-?\d+$)|(^-?\d+\.$)|(^-?\d+\.\d+$)/.test(value);
}

interface EditBudgetEstimateRow {
  handleClickDeleteEstimate: () => void;
  handlekeyPress: (e: KeyboardEvent<HTMLInputElement>) => void;
  handleUnitCostChange: (e: ChangeEvent<HTMLInputElement>) => void;
  handleQuantityChange: (e: ChangeEvent<HTMLInputElement>) => void;
  description: string;
  setDescription: React.Dispatch<React.SetStateAction<string>>;
  quantity: string;
  unitCost: string;

  isUpdatingEstimate: boolean;
  isDeletingEstimate: boolean;
  unit: string;
  setUnit: React.Dispatch<React.SetStateAction<string>>;
  handleUpdateItem: () => void;
  handleRulerIconClicked: () => void;
}
const useEditBudgetEstimateRow = ({ item }: Props): EditBudgetEstimateRow => {
  const [description, setDescription] = useState<string>(item.description);
  const [quantity, setQuantity] = useState<string>(String(item.quantity));
  const [unitCost, setUnitCost] = useState<string>(
    new Intl.NumberFormat().format(Number(item.unitCost))
  );
  const [unit, setUnit] = useState<string>(item.unit);
  const { company, projectId } = useDoxleCurrentContextStore((state) => ({
    company: state.currentCompany,
    projectId: state.currentProject?.projectId ?? "",
  }));
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification
  );
  const { estimatesQueryFilter } = useEstimatesStore(
    (state) => ({
      estimatesQueryFilter: state.estimatesQueryFilter,
    }),
    shallow
  );

  const { setDisplayTakeOff, setDeletedEstimateList } = useEditEstimatesStore(
    (state) => ({
      setDisplayTakeOff: state.setDisplayTakeOff,
      setDeletedEstimateList: state.setDeletedEstimateList,
    }),
    shallow
  );
  const edittedDocket = useEditDocketSideScreenStore(
    (state) => state.edittedDocket,
    shallow
  );
  const docketId = edittedDocket?.docketId;

  const { update } = EstimatesQueryAPI.useMutateEstimate({
    company,
    showNotification,
    filter: estimatesQueryFilter,
    docketId,
  });

  const handleUpdateItem = (
    takeOff: undefined | "update" | null = undefined
  ) => {
    if (!isNumeric(unitCost.replaceAll(",", ""))) {
      setUnitCost(new Intl.NumberFormat().format(Number(item.unitCost)));
      return;
    }
    const updatedItem: Partial<Estimate> = {
      description: description,
      quantity: quantity,
      unitCost: unitCost.replaceAll(",", ""),
      unit: unit,
    };
    if (takeOff) updatedItem.takeOff = takeOff;

    update.mutate({ estimateId: item.estimateId, updateBody: updatedItem });
  };

  const handlekeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.nativeEvent.key === "Enter") {
      e.preventDefault();
      e.currentTarget.blur();
    }
  };
  const handleUnitCostChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value === "" || value === "-") {
      setUnitCost(value);
    }
    const removedText = value.replaceAll(",", "");
    if (isNumeric(removedText)) {
      setUnitCost(value);
    }
  };
  const handleQuantityChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (isNumeric(value)) setQuantity(value);
  };
  const handleClickDeleteEstimate = () => setDeletedEstimateList([item]);

  const isDeletingEstimate =
    useIsMutating({
      mutationKey: getMutateEstimateKey("delete"),
      predicate: (query) =>
        (query.state.variables as DestroyEstimateProps).estimateIds.find(
          (deletedId) => deletedId === item.estimateId
        ) !== undefined,
    }) > 0;

  //!!! EXTERNAL REPOS !!!//
  const addTakeOff = TakeOffQueryAPI.useCreateNewTakeOff({
    onSuccessCb: setDisplayTakeOff,
  });
  const handleRulerIconClicked = () => {
    if (item.takeOff && setDisplayTakeOff)
      setDisplayTakeOff({
        takeOffId: item.takeOff,
        unit: unit,
      });
    else {
      addTakeOff.mutate({
        takeOffId: "",
        index: 0,
        description: description,
        totalValue: 0,
        unit: takeOffUnits.includes(item.unit.toUpperCase())
          ? item.unit.toUpperCase()
          : "EA",
        measurements: [],
        measurementCount: 0,
        estimate: item.estimateId,
        project: projectId,
        docket: edittedDocket?.docketPk ?? null,
        company: company?.companyId,
      } as TakeOff);
    }
  };

  return {
    handleClickDeleteEstimate,
    handlekeyPress,
    description,
    setDescription,
    handleUnitCostChange,
    handleQuantityChange,
    quantity,
    unitCost,
    isUpdatingEstimate: update.isLoading,
    isDeletingEstimate,
    unit,
    setUnit,
    handleUpdateItem,
    handleRulerIconClicked,
  };
};

export default useEditBudgetEstimateRow;
function isNotNumeric(str: string) {
  return isNaN(Number(str));
}
