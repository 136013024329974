import { useMemo } from "react";
import useGetRateList from "../../../Hooks/useGetRateList";
import { IPricebookRateGroup } from "../../../Models/PricebookTable";
import { groupBy } from "lodash";
import { usePricebookStore } from "../../../Store/usePricebookStore";
import { useShallow } from "zustand/react/shallow";
import { produce } from "immer";

type Props = {};

interface IPricebookTable {
  rateGroupData: IPricebookRateGroup;
}
const usePricebookTable = (): IPricebookTable => {
  const { shrinkedPricebookSections } = usePricebookStore(
    useShallow((state) => ({
      shrinkedPricebookSections: state.shrinkedPricebookSections,
    }))
  );
  const { rateList } = useGetRateList({});
  const rateGroupData: IPricebookRateGroup = useMemo(() => {
    const rateIdSet = groupBy(rateList, (rate) => rate.pricebookItem); // group by pricebookItem

    return Object.entries(rateIdSet).reduce(
      (acc, [key, rateItems]) => {
        const pricebookId = key;
        const pricebookDescription = rateItems[0].pricebookItemDescription;
        const isExpand = !shrinkedPricebookSections.includes(pricebookId);

        return produce(acc, (draft) => {
          draft.pricebookItem.push({
            pricebookId,
            pricebookDescription: pricebookDescription ?? "",
          });
          draft.rateCounts.push(isExpand ? rateItems.length : 0);
          if (isExpand) draft.rateItems.push(...rateItems);

          return draft;
        });
      },
      {
        pricebookItem: [],
        rateCounts: [],
        rateItems: [],
      } as IPricebookRateGroup
    );
  }, [rateList, shrinkedPricebookSections]);
  return { rateGroupData };
};

export default usePricebookTable;
