import {
  ClickAwayListener,
  Divider,
  Grid,
  IconButton, MenuList,
  NativeSelect,
  Paper,
  Popper,
  Tooltip,
  Typography
} from "@mui/material";
import UndoIcon from "@mui/icons-material/Undo";
import React, {useState, useEffect, MouseEventHandler, useRef} from "react";
import {DrawingSet, Sheet} from "../Models/takeOffs";
import {takeOffUnits, tUnit, useMeasurementsStore} from "../MeasurementsStore/useMeasurementsStore";
import DrawingsQueryAPI from "../Hooks/DrawingsQueryAPI";
import {ICONS} from "../Assets/icons";
import {toFloat} from "../konvaFunctions/generalFunctions";
import useSaveMeasurements from "../Hooks/useSaveMeasurements";
import ClearIcon from '@mui/icons-material/Clear';
import ClearAll from '@mui/icons-material/Autorenew';
import DoxleDialogHelper from "../../DoxleDesignPattern/DoxleDialogHelper/DoxleDialogHelper";
import {shallow} from "zustand/shallow";
import {useEditEstimatesStore} from "../../Estimates/Content/Store/useEditEstimateStore";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";


interface props{
  drawingsList: DrawingSet[]
    // handleEditSheetClicked: MouseEventHandler;
    // handleUndoIconClicked: MouseEventHandler;
    // setOpenDropzoneDialog: Function;
}

const DrawingsNavbar: React.FC<props> = ({
                                           drawingsList
                                         }) => {
  const [displayDialog, setDisplayDialog] = useState<false|"save"|tUnit>(false);
  const [popper, setPopper] = useState<null|"drawing"|"sheet"|"unit">(null)
  const [currentDrawing, setCurrentDrawing] = useState<DrawingSet|undefined>(undefined);
  const unitRef = useRef<HTMLButtonElement|null>(null)
  const drawingRef = useRef<HTMLButtonElement|null>(null)
  const sheetRef = useRef<HTMLButtonElement|null>(null)
  const {
    currentSheet,
    setCurrentSheet,
    setScalingMode,
    scalingMode,
    handleUndoIconClicked,
    setMeasurements,
    sum,
    measurements,
    currentTakeOff,
    currentUnit,
    setCurrentUnit,

  } = useMeasurementsStore(state => ({
    currentSheet: state.currentSheet,
    setCurrentSheet: state.setCurrentSheet,
    setScalingMode: state.setIsScaling,
    scalingMode: state.isScaling,
    handleUndoIconClicked: state.undoMeasurement,
    setMeasurements: state.setMeasurements,
    sum: state.totalValue,
    measurements: state.measurements,
    currentTakeOff: state.currentTakeOff,
    currentUnit: state.currentUnit,
    setCurrentUnit: state.setCurrentUnit,
  }));
  const saveMeasurements = useSaveMeasurements()

  const handleSetDrawing = (set: DrawingSet) => {
    setCurrentDrawing(set)
    setCurrentSheet(set?.sheets?.[0])
    setPopper(null)
  }

  const handleSetSheet = (sheet: Sheet) => {
    setCurrentSheet(sheet)
    setPopper(null)
  }
  const handleRulerIcon = () => setScalingMode(true);
  const resetStore = useMeasurementsStore(state => state.resetStore, shallow)
  const {displayTakeOff, setDisplayTakeOff} = useEditEstimatesStore(state => ({
    displayTakeOff: state.displayTakeOff,
    setDisplayTakeOff: state.setDisplayTakeOff
  }), shallow)
  const handleCloseMeasurement = () => {
    setDisplayDialog(false);
    setDisplayTakeOff(null);
    resetStore();
  }

  const handleSetUnit = () => {
    setCurrentUnit(displayDialog as tUnit)
    setDisplayDialog(false)
  }

  const handleCloseDialog = () => {
    setDisplayDialog(false)
  }
  const handleChangeUnit = (unit: tUnit) => {
    if (measurements.length && unit !== currentUnit) setDisplayDialog(unit)
    else setCurrentUnit(unit)
    setPopper(null);
  }

  const closeMeasurements = () =>{
    console.log('********************************')
    console.log('sum', toFloat(sum))
    console.log('currentTakeOff?.totalValue', toFloat(currentTakeOff?.totalValue))
    console.log('********************************')
    console.log('measurements', JSON.stringify(measurements))
    console.log('currentTakeOff?.measurements', JSON.stringify(currentTakeOff?.measurements))
    console.log('********************************')
    console.log('currentTakeOff?.unit', currentTakeOff?.unit?.toLowerCase())
    console.log('currentUnit', currentUnit?.toLowerCase())
    console.log('********************************')
    if (
      (toFloat(sum) !== toFloat(currentTakeOff?.totalValue)) ||
      (JSON.stringify(measurements) !== JSON.stringify(currentTakeOff?.measurements)) ||
      (currentUnit?.toLowerCase() !== currentTakeOff?.unit?.toLowerCase())
    ) setDisplayDialog("save");
    else handleCloseMeasurement();
  }

  const handleSaveAndClose = () => {
    setDisplayDialog(false);
    saveMeasurements({onSuccessCb: handleCloseMeasurement});
  }

  useEffect(()=>{
    setCurrentDrawing(drawingsList?.[0])
  }, [drawingsList])

  useEffect(()=>{
    if (
      takeOffUnits.includes((displayTakeOff?.unit ?? "").toUpperCase()) &&
      displayTakeOff?.unit !== currentTakeOff?.unit
    )
      handleChangeUnit((displayTakeOff?.unit ?? "").toUpperCase() as tUnit)
  }, [displayTakeOff, currentTakeOff])

  if (!drawingsList) return null
  else return(
    <Grid container className="drawing-nav-bar">
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={3} style={{alignSelf: "center"}}>
            <IconButton id="drawing-button" ref={drawingRef} onClick={() => setPopper("drawing")}>
              <Typography style={{fontFamily: "Inter", fontSize:13, color:"#9974fe", margin: "auto 0px"}}>Set:</Typography>
              &nbsp;
              <Typography style={{fontFamily: "Inter", fontSize:13, color:"#000", margin: "auto 0px"}}>{currentDrawing?.name ?? ""}</Typography>
            </IconButton>
        </Grid>
        <Grid item xs={2} style={{alignSelf: "center"}}>
          <small>
            <IconButton id="sheet-button" ref={sheetRef} onClick={() => setPopper("sheet")}>
              <Typography style={{fontFamily: "Inter", fontSize:13, color:"#9974fe", margin: "auto 0px"}}>Sheet:</Typography>
              &nbsp;
              <Typography style={{fontFamily: "Inter", fontSize:13, color:"#000", margin: "auto 0px"}}>{currentSheet?.title ?? ""}</Typography>
            </IconButton>
          </small>
        </Grid>
        <Grid item xs={2} style={{alignSelf: "center", flexDirection: "row", display: "flex", justifyContent: "center"}}>
          <Typography style={{fontFamily: "Inter", fontSize:13, color:"#9974fe", margin: "auto 0px"}}>
            {`${sum.toFixed(3)}`}
          </Typography>
          <IconButton id="unit-button" ref={unitRef} onClick={() => setPopper("unit")}>
            <Typography style={{fontFamily: "Inter", fontSize:13, color:"#9974fe"}}>
              {currentUnit?.toLowerCase() ?? ""}
            </Typography>
            <KeyboardArrowDownRoundedIcon style={{color: "#9974fe"}}/>
          </IconButton>
        </Grid>
        <Grid item xs={5} style={{ float: "right", alignSelf: "center"}}>
          <div style={{ position: "relative", float: "right", display: scalingMode ? "none" : "unset"}}>
            <ul className="measurement-header-icon-box">
              <li className="icon-line-above clickable-icon">
                <IconButton onClick={handleUndoIconClicked}>
                  <Tooltip title="Undo">
                    <><UndoIcon style={{color:"#9974fe"}}/>Undo</>
                  </Tooltip>
                </IconButton>
              </li>
              <li className="icon-line-above">
                <img src={ICONS.Linehorizontal} alt="" style={{transform: "scaleY(1.9) translateY(4px)"}}/>
              </li>
              <li className="icon-line-above clickable-icon">
                <IconButton onClick={()=>setMeasurements([])}>
                  <Tooltip title="Clear All Measurements">
                    <><ClearAll style={{color:"#9974fe"}}/>Clear</>
                  </Tooltip>
                </IconButton>
              </li>
              <li className="icon-line-above">
                  <img src={ICONS.Linehorizontal} alt="" style={{transform: "scaleY(1.9) translateY(4px)"}} />
              </li>
              <li className="icon-line-above clickable-icon">
                <IconButton onClick={handleRulerIcon}>
                  <Tooltip title="Scale Current Sheet">
                    <>{!currentSheet?.scale || toFloat(currentSheet?.scale) === 0
                      ? <img className="pulse-alert" src={ICONS.RulerAlert} alt="" />
                      : <img src={ICONS.Ruler} alt="" />
                    } Scale</>
                  </Tooltip>
                </IconButton>
              </li>
              <li className="icon-line-above">
                <img src={ICONS.Linehorizontal} alt="" style={{transform: "scaleY(1.9) translateY(4px)"}} />
              </li>
              <li className="icon-line-above clickable-icon">
                <IconButton onClick={() => saveMeasurements({})}>
                  <Tooltip title="Save Measurements">
                    <><ICONS.checkIcon style={{color: "#9974fe"}} />Save</>
                  </Tooltip>
                </IconButton>
              </li>
              <li className="icon-line-above">
                <img src={ICONS.Linehorizontal} alt="" style={{transform: "scaleY(1.9) translateY(4px)"}} />
              </li>
              <li className="icon-line-above clickable-icon">
                <IconButton onClick={closeMeasurements}>
                  <Tooltip title="Close">
                    <><ClearIcon  style={{color:"#9974fe"}} />Close</>
                  </Tooltip>
                </IconButton>
              </li>
            </ul>
          </div>
        </Grid>
      </Grid>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <DoxleDialogHelper
        open={Boolean(displayDialog)}
        title={displayDialog === "save" ? "Save Measurements?" : `Change Unit to ${displayDialog}?`}
        description={displayDialog === "save"
          ? "It looks like there are unsaved measurements. Would you like to save them?\n\nNote: Budgets lines with linked to this take off will also be updated"
          : "Changing the unit will clear your existing measurements.\n\nAre you sure?"
        }
        dialogButtons={[
          {
            buttonText: displayDialog === "save"
              ? "Close without Saving"
              : "Cancel",
            buttonFunction: displayDialog === "save" ? handleCloseMeasurement : handleCloseDialog
          },
          {
            buttonText: displayDialog === "save"
              ? "Save and Close"
              : "Confirm",
            buttonFunction: displayDialog === "save" ? handleSaveAndClose : handleSetUnit
          }
        ]}
      />
      <Menu
        open={Boolean(popper)}
        anchorEl={
          popper === "unit"
            ? unitRef.current
            : popper === "drawing"
              ? drawingRef.current
              : popper === "sheet"
                ? sheetRef.current
                : null
        }
        role={undefined}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Paper>
          {popper === "drawing" &&<ClickAwayListener onClickAway={() => setPopper(null)}>
            <MenuList id="popper-menu" aria-labelledby="drawing-button">
              {drawingsList.map(set => <MenuItem onClick={()=>handleSetDrawing(set)}>{set?.name}</MenuItem>)}
            </MenuList>
          </ClickAwayListener>}
          {popper === "sheet" &&<ClickAwayListener onClickAway={() => setPopper(null)}>
            <MenuList id="popper-menu" aria-labelledby="sheet-button">
              {(currentDrawing?.sheets ?? []).map(sheet => <MenuItem onClick={()=>handleSetSheet(sheet)}>{sheet.title}</MenuItem>)}
            </MenuList>
          </ClickAwayListener>}
          {popper === "unit" &&<ClickAwayListener onClickAway={() => setPopper(null)}>
             <MenuList id="popper-menu" aria-labelledby="unit-button">
               {takeOffUnits.map(unit => <MenuItem key={`unit-menu-${unit}`} onClick={()=>handleChangeUnit(unit)}>{unit}</MenuItem>)}
            </MenuList>
          </ClickAwayListener>}
        </Paper>
      </Menu>
    </Grid>
  )
}

export default DrawingsNavbar
