import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import OrdersQueryAPI from "../../../QueryHooks/OrdersQueryAPI";
import { useDoxleCurrentContextStore } from "../../../../DoxleGeneralStore/useDoxleCurrentContext";
import { shallow } from "zustand/shallow";
import useDoxleNotificationStore from "../../../../DoxleGeneralStore/useDoxleNotificationStore";
import { useEditOrderStore } from "../../../OrdersStore/useEditOrderStore";
import { useOrdersStore } from "../../../OrdersStore/useOrdersStore";
import { useEditDocketSideScreenStore } from "../../../../CoreContent/EditDocketSideScreen/EditDocketSideScreenStore/useEditDocketSideScreenStore";
import {
  DoxleMailIcon,
  DoxlePDFIcon, DoxleXeroBillIcon,
} from "../../../../DoxleDesignPattern/DoxleGeneralIcons";
import { useIsMutating } from "@tanstack/react-query";
import { useDoxleThemeStore } from "../../../../DoxleGeneralStore/useDoxleThemeStore";
import Order from "../../../Models/order";
import { useShallow } from "zustand/react/shallow";

type Props = {};
interface IEditOrder {
  isSendingOrder: number;
  isAddingOrder: boolean;
  isGeneratingPdf: boolean;
  isUpdatingOrder: boolean;
  setUpdatedOrder: Dispatch<
    SetStateAction<Omit<Partial<Order>, "orderId"> | undefined>
  >;
  isFetchingOrderDetail: boolean;
  isErrorFetchingOrderDetail: boolean;
  updatedOrder: Omit<Partial<Order>, "orderId"> | undefined;
  handleClickSaveBtn: () => void;
  isOrderLineUpdated: boolean;
  setIsOrderLineUpdated: Dispatch<SetStateAction<boolean>>;
  handleClickCancelBtn: () => void;
  showWarningChangesDialog: boolean;
  setShowWarningChangesDialog: Dispatch<SetStateAction<boolean>>;
}
const useEditOrder = (props: Props): IEditOrder => {
  const [updatedOrder, setUpdatedOrder] = useState<
    Omit<Partial<Order>, "orderId"> | undefined
  >(undefined);
  const [isOrderLineUpdated, setIsOrderLineUpdated] = useState(false);
  const [showWarningChangesDialog, setShowWarningChangesDialog] =
    useState(false);
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
      currentTheme: state.currentTheme,
    }),
    shallow
  );
  const company = useDoxleCurrentContextStore(
    (state) => state.currentCompany,
    shallow
  );

  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );
  const { setHandleCloseButtonFn, edittedDocket, setHandleAddButtonFn } =
    useEditDocketSideScreenStore(
      useShallow((state) => ({
        setHandleCloseButtonFn: state.setHandleCloseButtonFn,
        edittedDocket: state.edittedDocket,
        setHandleAddButtonFn: state.setHandleAddButtonFn,
      }))
    );
  const {
    editedOrder,
    setEditedOrder,
    setShowEmailOrderDialog,
    setShowOverBudgetDialog,
    setShowPaymentOptions,
  } = useEditOrderStore(
    useShallow((state) => ({
      editedOrder: state.editedOrder,
      setEditedOrder: state.setEditedOrder,
      setShowEmailOrderDialog: state.setShowEmailOrderDialog,
      setShowOverBudgetDialog: state.setShowOverBudgetWarning,
      setShowPaymentOptions: state.setShowPaymentOptions
    }))
  );
  const orderFilter = useOrdersStore(useShallow((state) => state.queryFilter));

  const orderDetailQuery = OrdersQueryAPI.useRetrieveOrderDetail({
    orderId: editedOrder?.orderId,

    company,
    showNotification,
    enabled: Boolean(editedOrder),
    onSuccessCb: (order) => {
      if (order) setEditedOrder(order);
    },
  });
  const orderDetail = useMemo(
    () =>
      orderDetailQuery.isSuccess ? orderDetailQuery.data.data : editedOrder,
    [orderDetailQuery.data]
  );

  const { patchOrder } = OrdersQueryAPI.useMutateOrderQuery({
    filter: orderFilter,
    company,
    showNotification,
    onUpdateSuccessCb: (order) => {
      setEditedOrder(undefined);
    },
    onOverBudget: () => setShowOverBudgetDialog(true),
    // onAddSuccessCb: handleAddNewOrderSuccess
  });

  const handleClickSaveBtn = () => {
    setShowWarningChangesDialog(false);
    if (editedOrder) {
      if (!updatedOrder) {
        if (isOrderLineUpdated)
          patchOrder.mutate({
            orderId: editedOrder.orderId,
            order: {
              lines: editedOrder.lines,
            },
          });
        else setEditedOrder(undefined);
      } else {
        patchOrder.mutate({
          orderId: editedOrder.orderId,
          order: {
            ...updatedOrder,
            lines: isOrderLineUpdated ? editedOrder.lines : undefined,
          },
        });
      }
    }
  };

  const handleClickCancelBtn = () => {
    if (isOrderLineUpdated || updatedOrder) {
      setShowWarningChangesDialog(true);
    } else {
      setEditedOrder(undefined);
    }
  };
  const retrievePdfQuery = OrdersQueryAPI.useRetrieveOrderPDF({
    orderId: editedOrder?.orderId,

    company,
    showNotification,
    onSuccessCb: (url: string | undefined) => {
      if (url) window.open(url, "_blank");
    },
  });

  const isAddingOrder = Boolean(
    useIsMutating({
      mutationKey: ["add-order"],
    })
  );
  const isSendingOrder = useIsMutating({
    mutationKey: ["share-order"],
  });

  useEffect(() => {
    setEditedOrder(orderDetail);
  }, [orderDetail]);

  useEffect(() => {
    if (orderDetail)
      setHandleAddButtonFn([
        {
          btnFunction: () => setShowPaymentOptions(true),
          btnText: "",
          showBtnIcon: "start",
          isLoading: isAddingOrder,
          btnIcon: (
            <DoxleXeroBillIcon
              themeColor={doxleThemeColor}
              containerStyle={{
                width: "22px",
              }}
            />
          ),
          btnBaseColor: doxleThemeColor.primaryContainerColor,
          tooltipText: "Submit for Payment",
        },
        {
          btnFunction: () => setShowEmailOrderDialog(true),
          btnText: "",
          showBtnIcon: "start",
          isLoading: isAddingOrder,
          btnIcon: (
            <DoxleMailIcon
              themeColor={doxleThemeColor}
              containerStyle={{
                width: "22px",
              }}
            />
          ),
          btnBaseColor: doxleThemeColor.primaryContainerColor,
          tooltipText: "Email Order",
        },
        {
          btnFunction: () => retrievePdfQuery.mutate(),
          showBtnIcon: "start",
          btnText: "",
          btnIcon: (
            <DoxlePDFIcon
              themeColor={doxleThemeColor}
              containerStyle={{
                width: "24px",
              }}
            />
          ),
          btnBaseColor: doxleThemeColor.primaryContainerColor,
          tooltipText: "Export PDF",
        },
      ]);
    else setHandleAddButtonFn([]);
    // setHandleCloseButtonFn({
    //   btnFunction: () => setEditedOrder(undefined),
    //   btnText: "Close",
    // });

    return () => setHandleCloseButtonFn(undefined);
  }, [isAddingOrder]);

  return {
    isSendingOrder,
    isAddingOrder,
    isGeneratingPdf: retrievePdfQuery.isLoading,
    updatedOrder,
    setUpdatedOrder,
    isFetchingOrderDetail: orderDetailQuery.isLoading,
    isErrorFetchingOrderDetail: orderDetailQuery.isError,
    handleClickSaveBtn,
    isUpdatingOrder: patchOrder.isLoading,
    isOrderLineUpdated,
    setIsOrderLineUpdated,
    handleClickCancelBtn,
    showWarningChangesDialog,
    setShowWarningChangesDialog,
  };
};

export default useEditOrder;
