import React, { useEffect } from "react";
import { StyledBookingProjectContainer } from "./StyledComponentBookingProject";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import BookingProjectFilterMenu from "./BookingProjectFilterMenu";
import BookingProjectTable from "./BookingTable/BookingProjectTable";
import useBookingProject from "../Hooks/useBookingProject";
import AddBookingProject from "./AddBookingProject/AddBookingProject";
import { AnimatePresence } from "framer-motion";
import useBookingProjectStore from "../../store/useBookingProjectStore";
import EditBookingProject from "./EditBookingProject/EditBookingProject";
import DoxleDialogHelper from "../../../DoxleDesignPattern/DoxleDialogHelper/DoxleDialogHelper";
import { useDoxleCurrentContextStore } from "../../../DoxleGeneralStore/useDoxleCurrentContext";
import SendBooking from "../../EmailBookings/SendBooking";
import { useShallow } from "zustand/react/shallow";

type Props = {};

const BookingProject = (props: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    useShallow((state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
      currentTheme: state.currentTheme,
    }))
  );
  const { handleDeleteBooking, handleCloseDeleteDialog } = useBookingProject(
    {}
  );
  const {
    onAddBookingMode,
    edittedBooking,
    deletedProjectBooking,
    setEdittedBooking,
    setOnAddBookingMode,
  } = useBookingProjectStore(
    useShallow((state) => ({
      onAddBookingMode: state.onAddBookingMode,
      edittedBooking: state.edittedBooking,
      deletedProjectBooking: state.deletedProjectBooking,
      setEdittedBooking: state.setEdittedBooking,
      setOnAddBookingMode: state.setOnAddBookingMode,
    }))
  );

  const { currentCompany, currentProject } = useDoxleCurrentContextStore(
    useShallow((state) => ({
      currentCompany: state.currentCompany,
      currentProject: state.currentProject,
    }))
  );

  useEffect(() => {
    setEdittedBooking(undefined);
    setOnAddBookingMode(false);
  }, [currentCompany, currentProject]);

  return (
    <>
      <AddBookingProject />
      <EditBookingProject />
      <AnimatePresence>
        <StyledBookingProjectContainer
          $themeColor={doxleThemeColor}
          initial={{
            x: -5,
            opacity: 0,
          }}
          animate={{
            x: 0,
            opacity: 1,
          }}
        >
          <BookingProjectFilterMenu />

          <BookingProjectTable />
          <SendBooking />
          <DoxleDialogHelper
            open={Boolean(deletedProjectBooking)}
            onClose={handleCloseDeleteDialog}
            title="Confirm delete booking!"
            description={`All data belong to booking item ***${deletedProjectBooking?.title}*** will be deleted permanently,are you sure to proceed?`}
            dialogButtons={[
              {
                buttonText: "Cancel",
                buttonFunction: handleCloseDeleteDialog,
                buttonColor: doxleThemeColor.primaryContainerColor,
                btnStyleSx: {
                  border: `1px solid ${doxleThemeColor.primaryDividerColor}`,
                },
                buttonTextStyle: {
                  color: doxleThemeColor.primaryFontColor,
                },
              },
              {
                buttonText: "Confirm",
                buttonFunction: handleDeleteBooking,
                btnStyleSx: {
                  border: `1px solid ${doxleThemeColor.primaryDividerColor}`,
                },
              },
            ]}
          />
        </StyledBookingProjectContainer>
      </AnimatePresence>
    </>
  );
};

export default BookingProject;
