import { StyledPageContainer } from "./StyledComponents";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import PricebookTable from "./PricebookTable/PricebookTable";
import PricebookCardList from "./PricebookList/PricebookCardList";
import { usePricebookStore } from "../Store/usePricebookStore";
import ContextPageNewDropDown from "./ContextPageNewDropDown";
import usePricebook from "../Hooks/usePricebook";
import { Helmet } from "react-helmet";
import PricebookDeleteConfirmDialog from "./PricebookDeleteConfirmDialog";
import { useShallow } from "zustand/react/shallow";
import AddEditPricebookDialog from "./AddEditPricebookItem/AddEditPricebookDialog";
import { useDoxleCurrentContextStore } from "../../DoxleGeneralStore/useDoxleCurrentContext";
import PricebookViewSwitch from "./PricebookViewSwitch";
import DoxleAddNewButton from "../../DoxleDesignPattern/DoxleButtons/DoxleAddNewButton";
import { DoxlePricebookIcon } from "../../DoxleDesignPattern/DoxleGeneralIcons";

interface Props {
  context?: "Project" | "DocketFullScreen" | "DocketHalfScreen";
}

const Pricebook = ({ context }: Props) => {
  const { view, setShowAddPricebookDialog } = usePricebookStore(
    useShallow((state) => ({
      view: state.view,
      setShowAddPricebookDialog: state.setShowAddPricebookDialog,
    }))
  );
  const { displayDropDown, setDisplayDropDown, isAddingItem } = usePricebook({
    context,
  });
  const { currentCompany } = useDoxleCurrentContextStore(
    useShallow((state) => ({
      currentCompany: state.currentCompany,
    }))
  );
  const { doxleThemeColor } = useDoxleThemeStore(
    useShallow((state) => ({
      doxleThemeColor: state.doxleThemeColor,
    }))
  );
  return (
    <StyledPageContainer>
      <Helmet>
        <title>Doxle - Pricebook</title>
      </Helmet>

      <PricebookDeleteConfirmDialog />
      {context !== "DocketFullScreen" && context !== "DocketHalfScreen" && (
        <div className="header-section">
          <div>
            <DoxlePricebookIcon
              themeColor={doxleThemeColor}
              containerStyle={{
                width: 22,
                marginRight: 8,
              }}
            />
            {`${currentCompany?.name}'s`} Pricebook
          </div>

          <div>
            <PricebookViewSwitch />
            <DoxleAddNewButton
              onClick={(e) => setShowAddPricebookDialog(true)}
              iconOnly={true}
              isLoading={isAddingItem}
            />
          </div>
        </div>
      )}
      {view === "Table" ? <PricebookTable /> : <PricebookCardList />}
      <AddEditPricebookDialog />
      {displayDropDown && (
        <ContextPageNewDropDown
          context={context ?? ""}
          buttonBounding={displayDropDown}
          handleClose={() => setDisplayDropDown(null)}
        />
      )}
    </StyledPageContainer>
  );
};
export default Pricebook;
