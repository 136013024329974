import { useState } from "react";
import { ScopeOfWorkImage } from "../../Model/scopeOfWorks";
import {
  StyledScopeImageItem,
  StyledScopeImgHoverMenu,
} from "./StyledComponentAddScopeImage";
import { motion } from "framer-motion";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import useScopeImageItem from "./Hooks/useScopeImageItem";
import { DoxleDeleteIcon } from "../../../DoxleDesignPattern/DoxleGeneralIcons";
import CircularProgress from "@mui/material/CircularProgress";
import { TfiUpload } from "react-icons/tfi";
import { SOWImageUploadIcon } from "../ScopeOfWorkIcon";

type Props = {
  imgItem: ScopeOfWorkImage;
  imgIndex: number;
  handleClickImage: (imgIndex: number) => void;
  openPickFile?: () => void;
};

const ScopeImageItem = ({
  imgItem,
  imgIndex,
  handleClickImage,
  openPickFile,
}: Props) => {
  const [isImgError, setIsImgError] = useState(false);
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore((state) => ({
    doxleThemeColor: state.doxleThemeColor,
    doxleFont: state.doxleFont,
  }));

  const { handleDeleteScopeImage, isDeletingImg } = useScopeImageItem({
    imgItem,
  });
  if (!imgItem.isDummy)
    return (
      <StyledScopeImageItem
        $doxleFont={doxleFont}
        $themeColor={doxleThemeColor}
        onClick={() => handleClickImage(imgIndex)}
      >
        {(imgItem.thumbUrl || imgItem.url) && (
          <motion.img
            src={isImgError ? imgItem.url : imgItem.thumbUrl}
            className="img-holder"
            onError={() => setIsImgError(true)}
          />
        )}

        <StyledScopeImgHoverMenu
          $themeColor={doxleThemeColor}
          className={"menu-hover"}
          style={isDeletingImg ? { display: "flex" } : undefined}
        >
          {isDeletingImg ? (
            <CircularProgress color="error" size={20} />
          ) : (
            <DoxleDeleteIcon
              themeColor={doxleThemeColor}
              containerStyle={{
                width: 25,
                marginInline: 8,
              }}
              onClick={handleDeleteScopeImage}
            />
          )}
        </StyledScopeImgHoverMenu>
      </StyledScopeImageItem>
    );
  else
    return (
      <StyledScopeImageItem
        $doxleFont={doxleFont}
        $themeColor={doxleThemeColor}
        onClick={() => {
          if (openPickFile) openPickFile();
        }}
      >
        <SOWImageUploadIcon
          themeColor={doxleThemeColor}
          containerStyle={{
            width: "70%",
          }}
        />
        <span className="dropzone-title">Click in or drop files to add!</span>
      </StyledScopeImageItem>
    );
};

export default ScopeImageItem;
