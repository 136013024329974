import React from "react";
import { QAList } from "../../../Models/qa";
import { useDoxleThemeStore } from "../../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import {
  StyledQAListSubmenuContainer,
  StyledQAListSubmenuItem,
} from "./StyledComponentQAProjectList";
import { QAListEditIcon, QAListSignatureIcon } from "../../../QAIcons";
import adobeIcon from "../../../Asset/adobe.png";
import { ClickAwayListener } from "@mui/material";
import { Variants } from "framer-motion";
import { editRgbaAlpha } from "../../../../Utilities/FunctionUtilities";
import { useProjectQAStore } from "../../Store/useProjectQAStore";
import { useShallow } from "zustand/react/shallow";
type Props = {
  qaListItem: QAList;
  handleCloseSubmenu: () => void;
};

const QAListSubmenu = ({ qaListItem, handleCloseSubmenu }: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    useShallow((state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }))
  );
  const { setSharedQAList, setEdittedQAListItem, setEdittedSignatureQAList } =
    useProjectQAStore(
      useShallow((state) => ({
        setSharedQAList: state.setSharedQAList,
        setEdittedQAListItem: state.setEdittedQAListItem,
        setEdittedSignatureQAList: state.setEdittedSignatureQAList,
      }))
    );
  const menuAnimatedVariants: Variants = {
    initial: {
      x: 0,
      color: editRgbaAlpha({
        rgbaColor: doxleThemeColor.primaryFontColor,
        alpha: "0.6",
      }),
    },
    hovering: {
      x: 5,
      color: doxleThemeColor.doxleColor,
    },
  };
  return (
    <ClickAwayListener onClickAway={handleCloseSubmenu}>
      <StyledQAListSubmenuContainer
        initial={{
          y: -10,
          opacity: 0,
        }}
        animate={{
          y: 0,
          opacity: 1,
        }}
        exit={{
          y: -10,
          opacity: 0,
        }}
      >
        <StyledQAListSubmenuItem
          variants={menuAnimatedVariants}
          initial="initial"
          whileHover="hovering"
          transition={{ duration: 0.1 }}
          onClick={(e) => {
            e.stopPropagation();
            setEdittedQAListItem(qaListItem);
          }}
        >
          <QAListEditIcon {...doxleThemeColor} />

          <span className="menu-text">Edit QA List</span>
        </StyledQAListSubmenuItem>

        <StyledQAListSubmenuItem
          variants={menuAnimatedVariants}
          initial="initial"
          whileHover="hovering"
          transition={{ duration: 0.1 }}
          onClick={(e) => {
            e.stopPropagation();
            setEdittedSignatureQAList(qaListItem);
          }}
        >
          <QAListSignatureIcon {...doxleThemeColor} />
          <span className="menu-text">Add Signature</span>
        </StyledQAListSubmenuItem>

        <StyledQAListSubmenuItem
          variants={menuAnimatedVariants}
          initial="initial"
          whileHover="hovering"
          transition={{ duration: 0.1 }}
          onClick={(e) => {
            e.stopPropagation();
            setSharedQAList(qaListItem);
          }}
        >
          <img className="adobe-img-holder" src={adobeIcon} />

          <span className="menu-text">Export to Pdf</span>
        </StyledQAListSubmenuItem>
      </StyledQAListSubmenuContainer>
    </ClickAwayListener>
  );
};

export default QAListSubmenu;
