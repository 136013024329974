import styled from "styled-components";
import {
  DoxleFont,
  DoxleTheme,
  DoxleThemeColor,
} from "../../../DoxleGeneralStore/useDoxleThemeStore";
import {
  TRgbaFormat,
  editRgbaAlpha,
} from "../../../Utilities/FunctionUtilities";
import { motion } from "framer-motion";
import Paper, { PaperProps } from "@mui/material/Paper";
import Button from "@mui/material/Button";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import { RgbaColor } from "react-colorful";

export const StyledNoticeBoardContentBodySection = styled.section`
  width: calc(100% - 2rem);
  margin-left: 2rem;
  flex: 1;
  display: flex;
  margin-top: 4rem;
`;

export const StyledNoticeBoardContentBodySectionContainer = styled.div`
  height: calc(100% - 10rem);
  width: 100%;

  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;
// export const StyledNoticeBoardContentBodySectionContainerTitle = styled.div<{
//     $themeColor: DoxleThemeColor;
//     $doxleFont: DoxleFont;
// }>`
//     display: flex;
//     height:100%;
//     img{
//         width: 1.7298rem;
//         height: 1.9211rem;
//         flex-shrink: 0;
//         fill: ${(p) => editRgbaAlpha({
//     rgbaColor: p.$themeColor.primaryFontColor,
//     alpha: "0.7",
// })};
//         margin:0.5rem 0.47rem;
//     }
//     h3{
//         color:${(p) => p.$themeColor.primaryFontColor};
//         font-family:${(p) => p.$doxleFont.primaryFont};
//         font-size: 1.8rem;
//         font-style: normal;
//         font-weight: 600;
//         line-height: 118.9%; /* 21.402px */
//         letter-spacing: -0.036rem;
//         margin: 0.5rem 0;
//     }
// `
export const StyledNoticeBoardRowContainer = styled(motion.div)<{
  $rowHeight: number;
}>`
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
  min-height: ${(p) => p.$rowHeight}px;
  z-index: 0;
`;
export const StyledNoticeBoardRowContainerContainer = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
  $currentTheme: DoxleTheme;
}>`
  display: flex;
  align-items: center;
  max-width: 100%;
  padding-bottom: 2px;
  border-bottom: 1px solid
    ${(p) =>
      p.$currentTheme === "light"
        ? p.$themeColor.boldDividerColor
        : p.$themeColor.primaryDividerColor};

  cursor: pointer;
  position: relative;

  .main-info-wrapper {
    flex-shink: 1;
    display: flex;
    padding: 2px;
    border-radius: 1px;
    margin-left: 8px;
    align-items: center;
    position: relative;
    overflow: hidden;

    .pin-effect {
      position: absolute;
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      z-index: 0;
      background-color: ${(p) =>
        p.$currentTheme !== "dark" ? "#ffe81a95" : "#FFD53B"};
    }
    .comment-icon-wrapper {
      position: relative;
      overflow: visible;
      padding: 4px 0px;
      margin-right: 8px;
      .comment-count {
        color: ${(p) =>
          editRgbaAlpha({
            rgbaColor: p.$themeColor.primaryFontColor,
            alpha: "0.9",
          })};
        font-family: ${(p) => p.$doxleFont.subTitleFont};
        font-size: max(1.3rem, 13px);
        font-style: normal;
        font-weight: 500;
        position: absolute;
        top: min(-0.5rem, -5px);
        right: -25%;
        z-index: 2;
      }
    }
  }
  span {
    color: ${(p) => p.$themeColor.primaryFontColor};
    font-family: ${(p) => p.$doxleFont.subTitleFont};
    font-size: max(1.4rem, 14px);
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    overflow: hidden;
    white-space: pre-line;

    position: relative;
    flex-shrink: 1;
  }
  img {
    width: 1.1rem;
    height: 1.2rem;
    margin-left: 8px;
  }

  .delete-edit-icon-wrapper {
    margin-left: 8px;
    display: flex;
    align-items: center;

    div {
      cursor: pointer;
    }
  }
`;

export const StyledCircle = styled(motion.div)`
  width: 8px;
  height: 8px;
  flex-shrink: 0;
  border-radius: 50%;
  transition: 0.2s ease;
  margin: 4px;
`;

export const StyledIconImage = styled.img<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  width: 1.4464rem;
  height: 1.35rem;
  flex-shrink: 0;
  fill: ${(p) =>
    editRgbaAlpha({
      rgbaColor: p.$themeColor.primaryFontColor,
      alpha: "0.7",
    })};
  stroke-width: 0.03rem;
  stroke: ${(p) =>
    editRgbaAlpha({
      rgbaColor: p.$themeColor.primaryFontColor,
      alpha: "0.7",
    })};
  padding-right: 0.5rem;
`;

export const StyledNBFooterContainer = styled.label<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
  $footerHeight: number;
}>`
  display: flex;
  align-items: center;
  height: ${(p) => p.$footerHeight}px;
  padding-top: 4px;
  width: 100%;
  cursor: pointer;
  border-bottom: 0.1rem solid
    ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.$themeColor.primaryDividerColor,
        alpha: "0.7",
      })};
  img {
    width: 1.2rem;
    height: 1.2rem;
    flex-shrink: 0;
    fill: ${(p) => p.$themeColor.primaryInputPlaceholderColor};
    padding: 0 1rem;
  }
  input {
    color: ${(p) => p.$themeColor.primaryFontColor};
    font-family: ${(p) => p.$doxleFont.subTitleFont};
    font-size: max(1.3rem, 13px);
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border: none;
    background: none;
    outline: none;
    appearance: none;
    flex: 1;
    max-width: 100%;

    &::placeholder {
      color: ${(p) => p.$themeColor.primaryInputPlaceholderColor};
      font-family: ${(p) => p.$doxleFont.primaryTitleFont};
      font-size: max(1.3rem, 13px);
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
`;
export const StyledNBFooterTextField = styled(motion(TextField))<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
  $height: number;
}>`
  && {
    border-bottom: 1px solid ${(p) => p.$themeColor.primaryDividerColor};
    width: calc(100%);
    padding: 8px 0px;
    display: flex;
    align-items: center;
    flex-direction: row;
  }
  input {
    color: ${(p) => p.$themeColor.primaryFontColor};
    font-size: 14px;
    font-family: ${(p) => p.$doxleFont.primaryTitleFont};
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.65px;
    &::placeholder {
      color: ${(p) => p.$themeColor.bolderDividerColor};
      font-size: 14px;
      font-family: ${(p) => p.$doxleFont.primaryFont};
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.65px;
      padding: 0px !important;
    }
  }
  & .MuiInputBase-multiline {
    color: ${(p) => p.$themeColor.primaryFontColor};
    font-size: 14px;
    font-family: ${(p) => p.$doxleFont.primaryTitleFont};
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.65px;
    width: 100%;
  }
  & .MuiOutlinedInput-root {
    padding: 0px !important;
  }
  & .MuiOutlinedInput-input {
    padding: 0px !important;
  }
  & .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }
  &.MuiInputBase-root-MuiInput-root:before {
    border: none !important;
  }
`;
export const StyledScroller = styled.div`
  width: 100%;
  height: 100%;

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;
export const StyledNBItemWrapper = styled(motion.div)`
  display: flex;
  width: 100%;
  min-height: 0px;
`;
export const StyledNoticeBoardLayout = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 4rem 0px;
  max-width: 1320px;
  min-width: 600px;
  position: relative;
`;
export const StyledNoticeBoardTitleContainer = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  display: flex;
  align-items: center;

  img {
    fill: var(--base-900, #22282f);
    padding-right: 8.5px;
  }
  span {
    color: ${(p) => p.$themeColor.primaryFontColor};
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-size: max(2.7rem, 27px);
    font-style: normal;
    font-weight: 600;

    letter-spacing: -0.54px;
  }
`;

export const StyledNoticeBoardEmptyScreenContainer = styled.section`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;
export const StyledNoticeBoardImgContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 3rem;

  width: 100%;
  img {
    height: 100%;
    aspect-ratio: 296/238;
  }
`;
export const StyledNoticeBoardContentContainer = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 3rem;
  max-width: 100.9rem;
  h2 {
    color: ${(p) => p.$themeColor.primaryFontColor};
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-size: 5rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.25rem;
    margin: 0;
    text-align: center;
  }
  p {
    color: ${(p) => p.$themeColor.primaryFontColor};
    font-family: ${(p) => p.$doxleFont.secondaryTitleFont};
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.07rem;
    margin: 0;
    padding-left: 3rem;
  }
`;
export const StyledNoticeBoardButton = styled(motion.button)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  display: flex;
  align-items: center;
  min-width: 10rem;
  padding: 0px 8px;
  height: 35px;
  border-radius: 3px;
  background: ${(p) => p.$themeColor.primaryFontColor};
  border: none;
  box-sizing: border-box;
  position: relative;
  cursor: pointer;
  span {
    color: ${(p) => p.$themeColor.primaryReverseFontColor};
    font-size: max(1.4rem, 14px);
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.28px;
    margin-right: 4px;
  }
  .divider {
    width: 1px;
    height: 100%;
    background: ${(p) => p.$themeColor.primaryReverseFontColor};
    margin: 0px 8px;
  }
`;

export const StyledNoticeBoardMenuContainer = styled.div<{
  $themeColor: DoxleThemeColor;
}>`
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 14px;
  border-bottom: 1px solid ${(p) => p.$themeColor.primaryDividerColor};

  .menu-btn-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: -1px;
  }

  .filter-new-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;

    .hash-icon {
      transition: 0.2s;
      margin-right: 8px;
      cursor: pointer;
      &:hover {
        transform: scale(1.2);
        transition: 0.2s;
      }
    }
  }

  .filter-tag-wrapper {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow: visible;
    justify-content: center;
  }
  .filter-title {
  }
`;

export const StyledNBMenuButton = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
  $selected: boolean;
}>`
  padding: 8px;
  display: flex;
  position: relative;
  cursor: pointer;
  color: ${(p) => p.$themeColor.primaryFontColor};

  font-family: ${(p) => p.$doxleFont.primaryFont};
  font-size: max(1.3rem, 13px);
  font-style: normal;
  font-weight: ${(p) => (p.$selected ? 600 : 400)};
  .menu-selected {
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    z-index: 2;
    height: 1px;
    background-color: ${(p) => p.$themeColor.doxleColor};
  }
`;

export const StyledProjectGroupTitleContainer = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  width: 100%;
  display: flex;
  min-height: 0px;
  height: 40px;

  flex-direction: row;
  padding-bottom: 14px;
  z-index: 100;
  align-items: center;
  background-color: ${(p) => p.$themeColor.primaryBackgroundColor};
  .project-address-title {
    color: ${(p) => p.$themeColor.primaryFontColor};
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-size: 1.8rem;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0.55px;
    text-transform: capitalize;
    margin-left: 8px;

    flex-shrink: 1;
    margin-right: 8px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    z-index: 100;
    background-color: ${(p) => p.$themeColor.primaryBackgroundColor};
  }

  .menu-icon {
    padding: 0px 4px;
    cursor: pointer;
    font-size: 1.6rem;
    color: ${(p) => p.$themeColor.doxleColor};
    font-weight: 400;
    transition: 0.4s;
    &:hover {
      transform: scale(1.2);
      font-weight: 500;
      transition: 0.4s;
    }
  }
`;

export const StyledNBGroupItemContainer = styled(motion.div)`
  padding-bottom: 20px;
  width: 100%;
  min-height: 3rem;
  display: flex;
  flex-direction: column;
`;

interface IDoxlePaperProps
  extends Omit<
    PaperProps,
    "onDragStart" | "onAnimationStart" | "onDragEnd" | "onDrag"
  > {
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}
const AnimatedPaper = motion(Paper);
const projectListAnimatedVariants = {
  entering: {
    y: [-10, 0],
    opacity: [0, 1],
    transition: {
      duration: 0.4,
    },
  },
  exiting: {
    y: [0, -10],
    opacity: [1, 0],
    transition: {
      duration: 0.4,
    },
  },
};
export const StyledNBFilterListContainer = styled(
  ({ $themeColor, $doxleFont, ...rest }: IDoxlePaperProps) => (
    <AnimatedPaper
      {...rest}
      variants={projectListAnimatedVariants}
      animate="entering"
      exit="exiting"
      initial="initial"
      layout
      elevation={4}
    />
  )
)`
  && {
    background-color: ${(p) => p.$themeColor.primaryContainerColor};
    width: 18rem;
    margin-top: 8px;
    min-height: 14rem;
    border-radius: 0.8rem;
    position: relative;
    padding: 2rem 1rem;
    box-shadow: 0px 0px 8px 0px ${(p) => p.$themeColor.primaryBoxShadowColor};
    border: 1px solid ${(p) => p.$themeColor.primaryDividerColor};
    display: flex;
    flex-direction: column;
  }

  & .divider {
    width: 100%;
    margin: 8px 0px;
    height: 1px;
    background-color: ${(p) => p.$themeColor.primaryDividerColor};
  }
  & .group-title {
    color: ${(p) => p.$themeColor.primaryFontColor};
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-size: max(1.3rem, 13px);
    font-style: normal;
    font-weight: 600;

    text-transform: capitalize;
    margin-bottom: 8px;
  }
`;

export const StyledNBFilterMenuBtn = styled(Button)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
  $selected?: boolean;
}>`
  && {
    width: 100%;
    padding: 4px 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    color: ${(p) =>
      p.$selected
        ? p.$themeColor.doxleColor
        : editRgbaAlpha({
            rgbaColor: p.$themeColor.primaryFontColor,
            alpha: "0.7",
          })};
    span {
      color: inherit;
      font-family: ${(p) => p.$doxleFont.primaryFont};
      font-size: max(1.3rem, 13px);
      font-style: normal;
      font-weight: 500;
      letter-spacing: -0.26px;
      text-transform: capitalize;
      margin-left: 8px;
    }
    transition: 0.4s;
    &:hover {
      background-color: ${(p) =>
        editRgbaAlpha({
          rgbaColor: p.$themeColor.doxleColor,
          alpha: "0.4",
        })} !important;
      transition: 0.4s;
      color: ${(p) =>
        editRgbaAlpha({
          rgbaColor: p.$themeColor.primaryFontColor,
          alpha: "0.7",
        })} !important;
    }
  }
`;

export const StyledStatusFilterMenuBtn = styled(Button)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
  $selected?: boolean;
  $statusColor: TRgbaFormat;
}>`
  && {
    width: 100%;
    padding: 4px 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    background-color: ${(p) =>
      p.$selected
        ? editRgbaAlpha({
            rgbaColor: p.$statusColor,
            alpha: "0.3",
          })
        : "transparent"};
    color: ${(p) =>
      p.$selected
        ? p.$themeColor.doxleColor
        : editRgbaAlpha({
            rgbaColor: p.$themeColor.primaryFontColor,
            alpha: "0.7",
          })};
    span {
      color: ${(p) => (p.$selected ? "white" : p.$statusColor)};
      font-family: ${(p) => p.$doxleFont.primaryFont};
      font-size: max(1.3rem, 13px);
      font-style: normal;
      font-weight: 500;
      letter-spacing: -0.26px;
      text-transform: capitalize;
      margin-left: 8px;
      font-weight: ${(p) => (p.$selected ? 600 : "normal")};
    }
    transition: 0.4s;
    &:hover {
      background-color: ${(p) =>
        editRgbaAlpha({
          rgbaColor: p.$statusColor,
          alpha: "0.2",
        })} !important;
      transition: 0.4s;
      span {
        color: ${(p) => p.$statusColor};
        /* transition: 0.4s; */
      }
    }
  }
`;

interface ICustomTextFieldProps extends TextFieldProps<"standard"> {
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}
export const StyledEditProjectAddressTextField = styled(
  ({ $themeColor, $doxleFont, ...rest }: ICustomTextFieldProps) => (
    <TextField {...rest} />
  )
)`
  && {
    flex: 1;
    border-bottom: none !important;
    max-width: 50rem;
  }
  input {
    color: ${(p) => p.$themeColor.primaryFontColor};
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-size: 1.8rem;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0.55px;
    padding-left: 0.8rem !important;
    padding-right: 0.8rem !important;
    border-bottom: none !important;
    &::placeholder {
      font-family: ${(p) => p.$doxleFont.primaryFont};
      font-size: 1.8rem;
      font-style: normal;
      font-weight: 600;
      letter-spacing: 0.55px;
      color: ${(p) => p.$themeColor.primaryInputPlaceholderColor} !important;
      opacity: 1 !important;
    }
  }
  & .MuiInput-root:after {
    border-bottom: none !important;
  }
  & .MuiInput-root:before {
    border-bottom: none !important;
  }
  & .MuiInput-root {
    border-bottom: none !important;
  }
  & .MuiInput-root:hover {
    border-bottom: none !important;
  }
  & .MuiInput-root:hover:not(.Mui-disabled, .Mui-error):before {
    border-bottom: none;
  }
  & .MuiInputLabel-root.Mui-focused {
    display: none;
  }
  & .MuiInputLabel-root {
    display: none;
  }
  & .MuiInputBase-root-MuiInput-root:after {
    border-bottom: none !important;
  }
`;

export const StyledBallInCourtTag = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
  $currentTheme: DoxleTheme;
}>`
  background-color: ${(p) =>
    editRgbaAlpha({
      rgbaColor: p.$themeColor.doxleColor,
      alpha: p.$currentTheme === "dark" ? "0.65" : "0.15",
    })};
  color: ${(p) => p.$themeColor.primaryFontColor};
  padding: 2px 4px;
  font-family: ${(p) => p.$doxleFont.subTitleFont};
  font-size: max(1.3rem, 13px);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-left: 8px;
  border-radius: 2px;
  z-index: 10;
`;

export const StyledNbGroupModeSwitcher = styled(motion.div)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>``;

export const StyledRowWrapperWithFooter = styled.div`
  min-height: 70px;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 30px;
`;
