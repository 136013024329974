import React from "react";
import { Note } from "../../Models/note";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import { NoteIcon } from "../NoteIcons";
import Tooltip from "@mui/material/Tooltip";
import { DoxleMUITooltipSx } from "../../../DoxleDesignPattern/MUIOverwriteSx";
import { HiOutlineDocument } from "react-icons/hi2";
import { StyledNoteRowContainer, StyledNoteTitle } from "./StyledNoteList";
import { AnimatePresence, Variants, motion } from "framer-motion";
import useNoteRow from "./Hooks/useNoteRow";
import { DoxleDeleteIcon } from "../../../DoxleDesignPattern/DoxleGeneralIcons";
import CircularProgress from "@mui/material/CircularProgress";
import useEditNote from "../EditNote/Hooks/useEditNote";
import { StyledNoteTitleTextField } from "../EditNote/StyledEditNote";
import { IoDocumentOutline } from "react-icons/io5";
import DoxleMultilineTextField from "../../../DoxleDesignPattern/DoxleTextField/DoxleMultilineTextField";
type Props = {
  noteItem: Note;
};

const NoteRow = ({ noteItem }: Props) => {
  const { doxleThemeColor, doxleFont, transparentMode } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
      transparentMode: state.transparentMode,
    }),
    shallow
  );
  const {
    newNoteTitle,
    setNewNoteTitle,
    handleKeydownTitleTextField,
    handleUpdateNote,
    titleInputRef,
    onHoverNoteRow,
    setOnHoverNoteRow,
    handleClickDeleteIcon,
    isDeleteNoteRow,
    handleClickNoteRow,
  } = useNoteRow({ noteItem });
  const animatedVariants: Variants = {
    initial: {
      x: -5,
      opacity: 0,
    },
    entering: {
      x: 0,
      opacity: 1,
    },
    exiting: {
      x: 10,
      opacity: 0,
    },
  };
  return (
    <AnimatePresence>
      <StyledNoteRowContainer
        layout
        variants={animatedVariants}
        initial="initial"
        animate="entering"
        exit="exiting"
        transition={{
          duration: 0.2,
        }}
        $themeColor={doxleThemeColor}
        $doxleFont={doxleFont}
        $transparentMode={transparentMode}
        onMouseEnter={() => setOnHoverNoteRow(true)}
        onMouseLeave={() => setOnHoverNoteRow(false)}
        onClick={handleClickNoteRow}
      >
        {isDeleteNoteRow ? (
          <CircularProgress size={20} color="error" />
        ) : (
          <HiOutlineDocument
            size={20}
            style={{ minWidth: 20 }}
            color={doxleThemeColor.primaryFontColor}
          />
        )}
         <DoxleMultilineTextField
          placeholder="Note title..."
          inputRef={titleInputRef}
          value={newNoteTitle}
          onChange={(event) => setNewNoteTitle(event.target.value)}
          onFocus={() => titleInputRef.current?.select()}
          onBlur={handleUpdateNote}
          onKeyDown={handleKeydownTitleTextField}
          style={{fontSize: "1.1rem", backgroundColor:"transparent", height:"auto"}}
          onClick={(e)=>{e.stopPropagation()}}
          maxRows={3}
        />

        {onHoverNoteRow && (
          <motion.div
            className="icon-wrapper"
            onClick={handleClickDeleteIcon}
            animate={{
              x: [10, 0],
              opacity: [0, 1],
            }}
          >
            <DoxleDeleteIcon themeColor={doxleThemeColor} />
          </motion.div>
        )}
      </StyledNoteRowContainer>
    </AnimatePresence>
  );
};

export default NoteRow;
