import { DocketStatus } from "../../../../Models/dockets";
import { useDoxleThemeStore } from "../../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import { useEditDocketSideScreenStore } from "../../EditDocketSideScreenStore/useEditDocketSideScreenStore";
import { StyledDocketStatusItem } from "./StyledComponentEditDocketStatusPopover";
import { useShallow } from "zustand/react/shallow";
import { motion } from "framer-motion";

type Props = {
  statusItem: DocketStatus;
  handleClickStatus: (statusItem: DocketStatus) => void;
};

const DocketStatusItem = ({ statusItem, handleClickStatus }: Props) => {
  const { doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }),
    shallow
  );

  const { edittedDocket } = useEditDocketSideScreenStore(
    useShallow((state) => ({
      edittedDocket: state.edittedDocket,
      updateEdittedDocket: state.updateEdittedDocket,
      filterDocketListQuery: state.filterDocketListQuery,
    }))
  );

  return (
    <StyledDocketStatusItem
      $doxleFont={doxleFont}
      $statusColor={statusItem.color}
      $selected={Boolean(
        edittedDocket && edittedDocket.status === statusItem.statusId
      )}
      onClick={() => handleClickStatus(statusItem)}
    >
      <motion.div
        className="status-dot"
        initial={{
          rotateZ: 45,
        }}
      />
      <div className="status-text">{statusItem.statusName}</div>
    </StyledDocketStatusItem>
  );
};

export default DocketStatusItem;
