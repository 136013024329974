import { useCallback, useEffect, useState } from "react";
import { shallow } from "zustand/shallow";
import { useIsMutating } from "@tanstack/react-query";
import { useMailSideScreenStore } from "../MailSideScreen/MailSideScreenStore/UseMailSideScreenStore";
import { Mail } from "../Models/mail";

type Props = { mail: Mail };

interface MailTableRow {
  handleClickRow: () => void;
}

const useMailTableRow = ({ mail }: Props): MailTableRow => {


  const { setCurrentMail, setCurrentDisplay } =
    useMailSideScreenStore(
      (state) => ({
        setCurrentDisplay: state.setCurrentDisplay,
        setCurrentMail: state.setCurrentMail,
      }),
      shallow
    );

  const handleClickRow = useCallback(() => {
    setCurrentMail(mail);
    setCurrentDisplay("Mail");
  }, [mail]);



  return {
    handleClickRow,
  };
};

export default useMailTableRow;
