import React, { useMemo, useRef } from "react";

import { useDoxleAuthStore } from "../../../../DoxleGeneralStore/useDoxleAuthStore";
import { shallow } from "zustand/shallow";
import { useDoxleThemeStore } from "../../../../DoxleGeneralStore/useDoxleThemeStore";
import { Discussion } from "../../../../Models/discussion";

import dayjs from "dayjs";
import {
  StyledCommentAuthorInfoContainer,
  StyledCommentAuthorText,
  StyledCommentContentText,
  StyledDiscussionItemContainer,
  StyledDiscussionTimestampText,
} from "./StyledComponentBookingDocketDetail";
import DiscussionAttachmentDisplayer from "./DiscussionAttachmentDisplayer";

type Props = {
  discussionItem: Discussion;
};

const highLightRegExp = /(#TaggedUser@\[[^\]]+\])/g;

const DiscussionItem = ({ discussionItem }: Props) => {
  const { user } = useDoxleAuthStore(
    (state) => ({ user: state.user }),
    shallow
  );
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }),
    shallow
  );
  const isUserAuthor: boolean = useMemo(
    () => (user ? Boolean(user.userId === discussionItem.author) : false),
    [user, discussionItem.author]
  );
  const commentRef = useRef<HTMLDivElement>(null);
    
  return (
    <StyledDiscussionItemContainer
      $themeColor={doxleThemeColor}
      $horizontalAlign={isUserAuthor ? "flex-end" : "flex-start"}
      ref={commentRef}
    >
      <StyledCommentAuthorInfoContainer $themeColor={doxleThemeColor}>
        <StyledCommentAuthorText
          $themeColor={doxleThemeColor}
          $doxleFont={doxleFont}
          $isAuthor={isUserAuthor}
        >
          {discussionItem.authorJson
            ? `${discussionItem.authorJson.firstName} ${discussionItem.authorJson.lastName}`
            : "Unknown User"}
        </StyledCommentAuthorText>

        <StyledDiscussionTimestampText
          $themeColor={doxleThemeColor}
          $doxleFont={doxleFont}
        >
          {dayjs(discussionItem.timeStamp).format("ddd, DD MMM YYYY hh:mm")}
        </StyledDiscussionTimestampText>
      </StyledCommentAuthorInfoContainer>

      <DiscussionAttachmentDisplayer discussionItem={discussionItem} />

      <StyledCommentContentText
        $themeColor={doxleThemeColor}
        $doxleFont={doxleFont}
      >
        {discussionItem.commentText
          .split(highLightRegExp)
          .map((item, index) => {
            if (highLightRegExp.test(item)) {
              return (
                <span key={index} style={{ color: doxleThemeColor.doxleColor }}>
                  {item.replace("#TaggedUser", "")}
                </span>
              );
            } else {
              return <span key={index}>{item}</span>;
            }
          })}
      </StyledCommentContentText>
    </StyledDiscussionItemContainer>
  );
};

export default React.memo(DiscussionItem);
