import React from "react";
import { useFileStore } from "../Store/useFileStore";
import { useShallow } from "zustand/react/shallow";
import { useDoxleCurrentContextStore } from "../../DoxleGeneralStore/useDoxleCurrentContext";
import { useEditDocketSideScreenStore } from "../../CoreContent/EditDocketSideScreen/EditDocketSideScreenStore/useEditDocketSideScreenStore";

const useFileSubheader = () => {
  const { company, currentProject } = useDoxleCurrentContextStore(
    useShallow((state) => ({
      company: state.currentCompany,
      currentProject: state.currentProject,
    }))
  );

  const { edittedDocket } = useEditDocketSideScreenStore(
    useShallow((state) => ({ edittedDocket: state.edittedDocket }))
  );
  const { currentFolder, currentLevel } = useFileStore(
    useShallow((state) => ({
      currentFolder: state.currentFolder,
      currentLevel: state.currentLevel,
    }))
  );

  const headTitleText =
    currentLevel === "Company"
      ? !Boolean(currentFolder)
        ? company?.name + " Files"
        : currentFolder?.folderName
      : currentLevel === "Docket"
      ? !Boolean(currentFolder)
        ? `${edittedDocket?.docketName}` + " Files"
        : currentFolder?.folderName
      : currentLevel === "Project"
      ? !Boolean(currentFolder)
        ? `${currentProject?.siteAddress}` + " Files"
        : currentFolder?.folderName
      : "";

  const subTitleText =
    currentLevel === "Company"
      ? !Boolean(currentFolder)
        ? company?.totalStorageSize
          ? "Company Storage :" +
            (company.totalStorageSize / 1024 / 1024).toFixed(2) +
            "MB"
          : "0 MB"
        : currentFolder?.folderSize
        ? "Folder Storage :" +
          (currentFolder?.folderSize / 1024 / 1024).toFixed(2) +
          "MB"
        : "0 MB"
      : currentLevel === "Docket"
      ? !Boolean(currentFolder)
        ? edittedDocket?.totalStorageSize !== undefined
          ? "Docket Storage :" +
            (edittedDocket.totalStorageSize / 1024 / 1024).toFixed(2) +
            "MB"
          : "0 MB"
        : currentFolder?.folderSize
        ? "Folder Storage :" +
          (currentFolder?.folderSize / 1024 / 1024).toFixed(2) +
          "MB"
        : "0 MB"
      : currentLevel === "Project"
      ? !Boolean(currentFolder)
        ? currentProject?.totalStorageSize !== undefined
          ? "Project Storage :" +
            (currentProject.totalStorageSize / 1024 / 1024).toFixed(2) +
            "MB"
          : "0 MB"
        : currentFolder?.folderSize
        ? "Folder Storage :" +
          (currentFolder?.folderSize / 1024 / 1024).toFixed(2) +
          "MB"
        : "0 MB"
      : "";
  return { headTitleText, subTitleText, currentLevel };
};

export default useFileSubheader;
