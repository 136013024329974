import React from "react";
import { useDropzone } from "react-dropzone";
import useAddResponseAttachments from "./Hooks/useAddResponseAttachments";
import { Button } from "@mui/material";
import { IoDocumentTextOutline } from "react-icons/io5";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import {
  StyledAddResponseFieldWrapper,
  StyledAddResponseLabelFieldText,
} from "./StyledComponentsAddQuoteResponse";
import { motion } from "framer-motion";
import { useAddQuoteResponseContext } from "./AddQuoteResponse";
import AddedAttachmentResponse from "./AddedAttachmentResponse";
type Props = {};
const AnimatedButton = motion(Button);
const AddResponseAttachments = (props: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore((state) => ({
    doxleThemeColor: state.doxleThemeColor,
    doxleFont: state.doxleFont,
  }));
  const { onDrop } = useAddResponseAttachments({});

  const { getRootProps } = useDropzone({
    onDrop,
    accept: {
      "image/*": [".png", ".jpg", ".jpeg"],
      "application/pdf": [".pdf"],
      "application/msword": [".doc"],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        [".docx"],
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
        ".xlsx",
      ],
    },
    multiple: true,
    // noClick: addedScopeImages.length > 0 ? true : false,
  });

  const { addedAttachments } = useAddQuoteResponseContext();
  return (
    <StyledAddResponseFieldWrapper>
      <StyledAddResponseLabelFieldText
        $themeColor={doxleThemeColor}
        $doxleFont={doxleFont}
      >
        Attachments
      </StyledAddResponseLabelFieldText>
      <div className="attachment-displayer-section">
        {addedAttachments.map((attachment, idx) => (
          <AddedAttachmentResponse
            attachment={attachment}
            index={idx}
            key={`added${idx}`}
          />
        ))}
      </div>
      <AnimatedButton
        layout
        {...getRootProps({
          variant: "outlined",
          startIcon: (
            <IoDocumentTextOutline
              style={{ color: doxleThemeColor.primaryFontColor }}
              size="1.6rem"
            />
          ),
          sx: {
            borderRadius: "0px 0.9rem 0.9rem 1.2rem !important",
            border: `1px solid ${doxleThemeColor.rowBorderColor}`,
            color: doxleThemeColor.primaryFontColor,
            fontSize: "1.4rem",
            textTransform: "none",
            marginTop: "2rem",
            width: "15.1rem",
          },
        })}
      >
        Choose File
      </AnimatedButton>
    </StyledAddResponseFieldWrapper>
  );
};

export default AddResponseAttachments;
