import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import useFileLeftSubheader from "../Hooks/useFileLeftSubheader";

import { StyledGridListViewButton } from "./StyledFilesHeaderComponent";
import { BsListUl, BsGrid } from "react-icons/bs";
function FileLeftSubheader() {
  const { currentView, currentLevel, listButtonClicked, gridButtonClicked } =
    useFileLeftSubheader();
  return (
    <>
      <StyledGridListViewButton
        $selected={Boolean(currentView === "ListView")}
        onClick={listButtonClicked}
      >
        <BsListUl className="menu-icon" />
        List View
      </StyledGridListViewButton>
      <span style={{ marginRight: "8px" }}></span>
      {currentLevel === "Company" && (
        <StyledGridListViewButton
          $selected={Boolean(currentView === "GridView")}
          onClick={gridButtonClicked}
        >
          <BsGrid className="menu-icon" />
          Grid View
        </StyledGridListViewButton>
      )}
    </>
  );
}

export default FileLeftSubheader;
