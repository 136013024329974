import React from "react";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";

import { AnimatePresence } from "framer-motion";
import SelectedContactItem from "./SelectedContactItem";
import ContactPopoverList from "./ContactPopoverList";
import useAddMailCCSection from "./Hooks/useAddMailCCSection";
import { useEmailOrderContext } from "./EmailOrder";
import { StyledEmailFieldWrapper } from "./StyledEmailOrder";

type Props = {};

const AddMailCCSection = (props: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }),
    shallow
  );

  const {
    handleClickContact,
    anchorContactListPopover,
    handleCloseContactList,
  } = useAddMailCCSection({});
  const { watchers, handleAddWatcher, handleRemoveWatcher } =
    useEmailOrderContext();

  return (
    <StyledEmailFieldWrapper
      $themeColor={doxleThemeColor}
      $doxleFont={doxleFont}
      layout
    >
      <span className="label-field"> Cc:</span>

      <div
        className="add-content-wrapper"
        style={{ flexWrap: "wrap" }}
        onClick={handleClickContact}
      >
        <AnimatePresence>
          {watchers.map((item) => (
            <SelectedContactItem
              item={item}
              onRemoveItem={handleRemoveWatcher}
            />
          ))}
        </AnimatePresence>
      </div>

      <ContactPopoverList
        anchorContactListPopover={anchorContactListPopover}
        handleCloseContactList={handleCloseContactList}
        selectedItems={watchers}
        onSelectItem={handleAddWatcher}
      />
    </StyledEmailFieldWrapper>
  );
};

export default AddMailCCSection;
