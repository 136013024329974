import styled from "styled-components";
import {
  DoxleFont,
  DoxleThemeColor,
} from "../../DoxleGeneralStore/useDoxleThemeStore";
import { motion } from "framer-motion";
import { TRgbaFormat, editRgbaAlpha } from "../../Utilities/FunctionUtilities";

import TextField, { TextFieldProps } from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Slider, { SliderProps } from "@mui/material/Slider";

export const StyledBookingHeaderContainer = styled.div<{
  $themeColor: DoxleThemeColor;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5rem;
  margin-top: 1rem;
  position: sticky;
  top: 0px;
  z-index: 10;
  width: 100%;
  background-color: ${(p) => p.$themeColor.primaryBackgroundColor};
`;
export const StyledBookingHeaderMenuSection = styled(motion.div)`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  flex: 1;
  justify-content: space-between;
  .date-picker-wrapper {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;
  }
`;
export const StyledDateTitle = styled.span<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  color: ${(p) => p.$themeColor.primaryFontColor};
  font-family: ${(p) => p.$doxleFont.primaryFont};
  font-size: 2.7rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  margin-right: 4px;
`;
export const StyledDatePickerCustomContainer = styled(motion.div)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  border-radius: 9px;

  cursor: pointer;

  justify-content: center;
  font-size: 14px;
  font-family: ${(p) => p.$doxleFont.secondaryTitleFont};
  align-items: center;
  flex-direction: row;
  display: flex;

  padding: 2px 12px;

  .date-text-content {
    color: ${(p) => p.$themeColor.primaryFontColor};
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    flex-shrink: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;
export const StyledBookingContainer = styled(motion.div)<{
  $themeColor: DoxleThemeColor;
}>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${(p) => p.$themeColor.primaryBackgroundColor};
  align-items: center;
`;
export const StyledBookingListContainer = styled.div`
  flex: 1;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
`;
export const StyledBookingListScroller = styled.div`
  width: 100%;
  height: 100% !important;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledBookingProjectScopeContainer = styled(motion.div)<{
  $themeColor: DoxleThemeColor;
}>`
  width: calc(100% - 4rem - 2px);
  display: flex;
  padding: 2rem;
  border-radius: 1.2rem;
  border: 1px solid ${(p) => p.$themeColor.rowBorderColor};

  background-color: ${(p) => p.$themeColor.primaryContainerColor};
  .divider {
    width: 1px;
    background-color: ${(p) => p.$themeColor.primaryDividerColor};
    margin-right: 2rem;
  }
`;

export const StyledBookingProjectGroupItem = styled(motion.div)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  width: 100%;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;

  .project-address-text {
    color: #7878b0;
    margin-bottom: 2px;
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-left: 1.5rem;
    text-transform: capitalize;
  }
`;

export const StyledDateWrapper = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  margin-right: 2rem;
  text-align: center;

  .week-date-text {
    color: ${(p) => p.$themeColor.primaryFontColor};
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.032rem;
  }

  .day-text {
    color: ${(p) => p.$themeColor.primaryFontColor};
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-size: 3rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.06rem;
  }

  .year-month-text {
    color: ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.$themeColor.primaryFontColor,
        alpha: "0.5",
      })};
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.02rem;
    text-transform: uppercase;
  }
`;

export const StyledTaskContentWrapper = styled.div`
  flex: 1;

  display: flex;
  flex-direction: column;
`;

export const StyledTagContainer = styled(motion.div)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  display: flex;
  flex-direction: row;
  max-width: calc(100% - 2.4rem);
  word-wrap: break-word;
  flex-shrink: 1;
  background-color: #f5f6f7;
  padding: 0.4rem 1.2rem;
  border-radius: 1.4rem;
  margin-bottom: 1.2rem;
  align-items: center;
  flex-direction: row;

  cursor: pointer;

  .booking-item-name {
    font-family: ${(p) => p.$doxleFont.primaryTitleFont};
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.028rem;
    color: ${(p) => p.$themeColor.primaryFontColor};

    margin-left: 8px;
    overflow-wrap: break-word;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
  }
`;
export const StyledAddNewBookingCellScopeContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;

  .icon-container {
    width: 2rem;
  }
`;
export const StyledAddNewBookingTextField = styled(TextField)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  && {
    flex-basis: 60%;
    flex-shrink: 1;
  }
  input {
    padding: 0px 0px 0.2rem 0px !important;
    font-family: ${(p) => p.$doxleFont.primaryTitleFont};
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.028rem;
    color: ${(p) => p.$themeColor.primaryFontColor};

    &::placeholder {
      font-family: ${(p) => p.$doxleFont.primaryTitleFont};
      font-size: 1.4rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.028rem;
      color: ${(p) => p.$themeColor.primaryInputPlaceholderColor} !important;
      opacity: 1 !important;
    }
  }
  & .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }
  & .MuiInputBase-root {
    height: 100%;
  }
`;

export const StyledBookingItemNameTextField = styled(TextField)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  && {
    flex-basis: 80%;
    flex-shrink: 1;
    min-width: 0px !important;
  }
  input {
    padding: 0px 0px 0px 8px !important;
    font-family: ${(p) => p.$doxleFont.primaryTitleFont};
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.028rem;
    color: ${(p) => p.$themeColor.primaryFontColor};

    &::placeholder {
      font-family: ${(p) => p.$doxleFont.primaryTitleFont};
      font-size: 1.4rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.028rem;
      color: ${(p) => p.$themeColor.primaryInputPlaceholderColor} !important;
      opacity: 1 !important;
    }
  }
  & .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }
  & .MuiInputBase-root {
    height: 100%;
    width: 100%;
  }
`;
export const StyledBookingStatusPopperMenu = styled(motion(Paper))<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  && {
    width: 18.3rem;
    min-height: 14.7rem;
    border-radius: 0px 1.8rem 1.2rem 1.2rem;
    padding: 12px;
    display: flex;
    flex-direction: column;
    background-color: ${(p) => p.$themeColor.primaryContainerColor};
    margin-top: 2.4rem;
    .menu-title-text {
      color: ${(p) => p.$themeColor.primaryFontColor};
      font-family: ${(p) => p.$doxleFont.primaryFont};
      font-size: 1.6rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.028rem;
      align-self: flex-start;
      border-radius: 0px 0px 0px 0.4rem;
      padding: 2px 8px;
      background-color: ${(p) =>
        editRgbaAlpha({ rgbaColor: p.$themeColor.doxleColor, alpha: "0.1" })};
      margin-bottom: 2rem;
    }
  }
`;

export const StyledBookingStatusListScroller = styled.div`
  width: 100%;
  height: 15rem !important;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

export const StyledBookingStatusItem = styled.div<{
  $statusColor: string;
  $doxleFont: DoxleFont;
}>`
  width: calc(100% - 16px);
  padding: 4px 8px;

  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 8px;
  transition: 0.4s;
  border-radius: 0px 0.4rem 0.4rem 0.4rem;
  cursor: pointer;

  .status-text {
    font-family: ${(p) => p.$doxleFont.primaryTitleFont};
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: ${(p) => p.$statusColor};
    text-transform: capitalize;
  }
  &:hover {
    background-color: ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.$statusColor as TRgbaFormat,
        alpha: "0.1",
      })};
    transition: 0.4s;
  }
`;
export const StyledAddBookingFormContainer = styled(motion.div)<{
  $themeColor: DoxleThemeColor;
}>`
  border-radius: 1.2rem;
  background-color: ${(p) => p.$themeColor.primaryContainerColor};
  width: calc(100% - 2rem);
  padding: 1rem;

  // position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 5;
  // top: 8px;
  // left: 8px;

  box-shadow: 0px 0px 8px 0px ${(p) => p.$themeColor.primaryBoxShadowColor};
  margin-bottom: 14px;
`;
export const StyledBookingListWrapper = styled(motion.div)`
  flex: 1;
  width: 100%;
  display: flex;
`;

export const StyledDateRangePickerButton = styled(motion.div)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  border-radius: 0.9rem;
  background-color: ${(p) =>
    editRgbaAlpha({
      rgbaColor: p.$themeColor.doxleColor,
      alpha: "0.3",
    })};
  color: black;
  cursor: pointer;
  min-width: 14rem;
  justify-content: center;
  font-size: 1.4rem;
  font-family: ${(p) => p.$doxleFont.secondaryTitleFont};
  align-items: center;
  flex-direction: row;
  display: flex;
  border-color: transparent;
  padding: 2px 12px;
`;
export const StyledAddBookingInputContainer = styled(motion.div)`
  flex: 1;
  align-self: stretch;
  display: flex;
  align-items: center;
`;

export const StyledDatePickerRangeButton = styled(motion(Button))`
  && {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: none;
    backgroundcolor: transparent;
    min-width: 60px !important;
    margin-right: 1rem;
    &:hover {
      background-color: transparent !important;
    }
  }
`;
export const StyledPickedDateText = styled(motion.span)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  font-family: ${(p) => p.$doxleFont.primaryTitleFont};
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: ${(p) => p.$themeColor.primaryFontColor};
  text-transform: capitalize;
`;

export const StyledBookingNumberContainer = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  padding: 0px 4px;
  position: relative;

  .docket-number-text {
    color: ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.$themeColor.primaryFontColor,
        alpha: "0.7",
      })};

    font-family: ${(p) => p.$doxleFont.plexSanCondensed};
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;

    letter-spacing: -0.064rem;
    position: absolute;
    bottom: -0.04rem;
    left: calc(8px - 0.8rem);
    z-index: 1;
    background-color: ${(p) => p.$themeColor.primaryBackgroundColor};
  }
`;

export const StyledBookingPercentageContainer = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  .percentage-text {
    font-family: ${(p) => p.$doxleFont.secondaryTitleFont};
    font-style: normal;
    font-weight: 500;
    font-size: 1.2rem;

    color: ${(p) => p.$themeColor.primaryFontColor};
    text-align: right;
    margin-right: 4px;
    width: 4rem;
  }
`;
export const StyledBookingPercentageCell = styled.div<{
  $fillColor?: string;
  $themeColor: DoxleThemeColor;
}>`
  width: 1.4rem;
  height: 1.4rem;
  border-radius: 0.14rem;
  margin: 0px 1px;
  background-color: ${(p) =>
    p.$fillColor ??
    editRgbaAlpha({
      rgbaColor: p.$themeColor.doxleColor,
      alpha: "0.3",
    })};
`;
export const StyledAddBookingLoaderContainer = styled(motion.div)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${(p) =>
    editRgbaAlpha({
      rgbaColor: p.$themeColor.primaryBackgroundColor,
      alpha: "0.9",
    })};
  .loading-text {
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-style: normal;
    font-weight: 500;
    font-size: 2rem;
    color: ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.$themeColor.doxleColor,
        alpha: "0.7",
      })};
    margin-top: 1.4rem;
  }
`;
export const StyledBookingOrderNumberContainer = styled(motion.div)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  position: relative;
  padding: 0px 4px;
`;

export const StyledOrderInfoWrapper = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 1;
  background-color: ${(p) => p.$themeColor.primaryBackgroundColor};
  bottom: 0px;
  left: 4px;
  transform: translate(-33%, 33%);
  .order-number-text {
    font-family: ${(p) => p.$doxleFont.plexSanCondensed};
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    color: ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.$themeColor.primaryFontColor,
        alpha: "0.7",
      })};
    margin-bottom: 2px;
  }

  .order-signed-text {
    color: #f90;

    font-family: ${(p) => p.$doxleFont.sarina};
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    letter-spacing: -0.064rem;
  }
`;
export const StyledVirtuosoListScroller = styled.div`
  width: 100%;
  height: 100%;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;
export const StyledBookingDocketNumberContainer = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  position: relative;
  padding: 0px 4px;

  .docket-number-text {
    position: absolute;
    z-index: 4;
    color: ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.$themeColor.primaryFontColor,
        alpha: "0.7",
      })};

    font-family: ${(p) => p.$doxleFont.plexSanCondensed};
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;

    letter-spacing: -0.12rem;
    bottom: -0.1rem;
    left: 2px;
    background-color: ${(p) => p.$themeColor.primaryBackgroundColor};
  }
  .require-symbol {
    position: absolute;
    z-index: 4;
    color: ${(p) => p.$themeColor.doxleColor};

    font-family: ${(p) => p.$doxleFont.plexSanCondensed};
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;

    letter-spacing: -0.12rem;
    top: 0px;
    right: 7px;
  }
`;
export const StyledBookingDateRangePickerWrapper = styled(motion.div)<{
  $isNull: boolean;
}>`
  width: 100% !important;
  border-radius: 8px;
  border: 1px solid ${(p) => p.theme.color.primaryDividerColor};
  display: flex;
  align-items: center;
  margin-bottom: 0px;
  flex-direction: row;
  padding: 5px 10px;
  cursor: pointer;
  transition: border-color 0.3s ease;
  &:hover {
    border-color: ${(p) => p.theme.color.doxleColor};
  }

  .content-text {
    color: ${(p) => p.theme.color.primaryFontColor};
    font-size: 16px;
    font-family: ${(p) => p.theme.font.primaryTitleFont};
    font-style: normal;
    font-weight: 500;
    flex: 1;
    white-space: normal;
    word-wrap: break-word;
    display: inline-block;
  }
`;
interface IBookingSliderCustomProps extends SliderProps {
  $themeColor: DoxleThemeColor;
}
export const StyledBookingSlider = styled(
  ({ $themeColor, ...props }: IBookingSliderCustomProps) => (
    <Slider {...props} />
  )
)`
  && {
  }
  &.MuiSlider-root {
    color: ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.$themeColor.doxleColor,
        alpha: "0.3",
      })};
    height: 2rem;
    padding: 0px;
    border-radius: 0px 0.8rem 0.8rem 0px;
  }
  & .MuiSlider-rail {
    padding-right: 1.4rem;
    width: 100%;
  }
  & .MuiSlider-thumb {
    background-color: #f90;
    width: 1.4rem;
    height: 1.4rem;
    border-radius: 50%;
    transform: translate(0px, -50%);
  }
  & .MuiSlider-thumb.Mui-focusVisible {
    box-shadow: 0px 0px 0px 0.8rem
      ${(p) =>
        editRgbaAlpha({ rgbaColor: p.$themeColor.doxleColor, alpha: "0.15" })};
  }
  & .MuiSlider-track {
    height: 4px;

    background-color: ${(p) => p.$themeColor.doxleColor};
  }
`;
interface ICustomTextFieldProps
  extends Omit<
    TextFieldProps<"standard">,
    "onDragStart" | "onAnimationStart" | "onDragEnd" | "onDrag"
  > {
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}
export const StyledBookingTitleTextField = styled(
  ({ $themeColor, $doxleFont, ...props }: ICustomTextFieldProps) => (
    <TextField {...props} />
  )
)`
  && {
    width: 100%;
    max-width: 50rem;
  }
  input {
    color: ${(p) => p.$themeColor.primaryFontColor};
    font-size: 1.8rem;
    font-family: ${(p) => p.$doxleFont.primaryTitleFont};
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.65px;
    &::placeholder {
      color: ${(p) => p.$themeColor.primaryFontColor};
      font-size: 1.8rem;
      font-family: ${(p) => p.$doxleFont.primaryFont};
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.65px;
      padding: 0px !important;
    }
  }
  & .MuiOutlinedInput-root {
    padding: 0px 10px 0px 38px !important;
  }
  & .MuiOutlinedInput-input {
    padding: 0px !important;
  }
  & .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }

  & .MuiInput-root:before {
    border: none !important;
  }
  & .MuiInput-root:after {
    border: none !important;
  }
`;
export const StyledAttachmentPickerBtn = styled(Button)`
  && {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    text-transform: capitalize;
    padding: 7px 14px;
    font-size: 16px;
    width: calc(100%);
    font-family: ${(p) => p.theme.font.primaryFont};
    font-weight: 500;
    color: ${(p) => p.theme.color.primaryFontColor};
    border-bottom: 1px solid ${(p) => p.theme.color.primaryDividerColor};
    transition: all 0.3s ease;
    &:hover {
      background-color: ${(p) => p.theme.color.primaryHoverColor};
    }
    cursor: pointer;
    border-radius: 0px !important;

    .icon {
      margin-right: 10px;
    }
  }
`;
