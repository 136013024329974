import { useEffect } from "react";
import Sheets from "./Sheets";
import Drawings from "./Drawings";
import DrawingsSkeleton from "./DrawingsSkeleton";
import { shallow } from "zustand/shallow";
import { useDoxleCurrentContextStore } from "../../DoxleGeneralStore/useDoxleCurrentContext";
import useGetDrawingList from "../Hooks/useGetDrawingList";
import { StyledDrawingsRootContainer } from "./DrawingsStyledComponent";
import { useDrawingStore } from "../Store/useDrawingStore";
import DrawingsPreviewPage from "./DrawingsPreviewPage";
import AddDrawingDropZone from "./AddDrawingDropZone";
import useDrawingsTabDisplayer from "../Hooks/useDrawingsTabDisplayer";

interface props {
  overrideProjectId?: string;
}

export interface IDrawingDisplayMenu {
  display: "Drawings" | "Sheets" | "Preview";
  selectedDrawingId: string;
  selectedIndex?: number;
}

const DrawingsTabDisplayer = ({ overrideProjectId }: props) => {
  const {
    drawingsList,
    isFetchingDrawingList,
    isSuccessFetchingDrawingList,
    isErrorFetchingDrawingList,
  } = useGetDrawingList({});
  const { currentProject, currentCompany } = useDoxleCurrentContextStore(
    (state) => ({
      currentProject: state.currentProject,
      currentCompany: state.currentCompany,
    }),
    shallow
  );

  const { viewedDrawingSet, resetStore, previewedDrawingSet } = useDrawingStore(
    (state) => ({
      viewedDrawingSet: state.viewedDrawingSet,

      resetStore: state.resetStore,
      previewedDrawingSet: state.previewedDrawingSet,
    }),
    shallow
  );

  const { showAddDrawingZone, setshowAddDrawingZone } = useDrawingsTabDisplayer(
    {}
  );
  useEffect(() => {
    resetStore();
  }, [currentProject, currentCompany]);
  return (
    <StyledDrawingsRootContainer>
      {isFetchingDrawingList && <DrawingsSkeleton />}

      {!viewedDrawingSet && !previewedDrawingSet && <Drawings />}

      {viewedDrawingSet && !previewedDrawingSet && (
        <Sheets drawingSet={viewedDrawingSet} />
      )}

      {previewedDrawingSet && (
        <DrawingsPreviewPage previewedDrawingSet={previewedDrawingSet} />
      )}

      <AddDrawingDropZone
        open={showAddDrawingZone}
        onClose={() => setshowAddDrawingZone(false)}
      />
    </StyledDrawingsRootContainer>
  );
};

export default DrawingsTabDisplayer;
