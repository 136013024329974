import styled from "styled-components";
import { motion } from "framer-motion";
import {
  DoxleFont,
  DoxleThemeColor,
} from "../../DoxleGeneralStore/useDoxleThemeStore";
import { TRgbaFormat, editRgbaAlpha } from "../../Utilities/FunctionUtilities";
import {
  Button,
  FormControl,
  TextField,
  TextFieldProps,
  Tooltip,
} from "@mui/material";

export const StyledLoginBackdrop = styled.div<{
  $themeColor: DoxleThemeColor;
}>`
  background-color: ${(p) => p.$themeColor.primaryContainerColor};
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
`
export const StyledDoxleLoginScreenContainer = styled.div<{
  $themeColor: DoxleThemeColor;
  $mobile: boolean;
}>`
  height: ${(p) => p.$mobile ? "60vh" : "100%"};
  background-color: ${(p) => p.$themeColor.primaryContainerColor};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  min-width: 350px;
  min-height: 400px;
`;
export const StyledDoxleLoginImg = styled.img<{
  $mobile?: boolean;
}>`
  width: ${(p) => p.$mobile ? "80vw" : "35vw"};
  aspect-ratio: auto;
  position: fixed;
  z-index: 0;
  ${(p) => p.$mobile ? "top: max(400px, 50vh);" : "bottom: 14px;"};
  right: ${(p) => p.$mobile ? "Calc(50vw - min(230px, 40vw) + min(35px, 1vw))" : "14px"};
  max-width: 460px;
  //max-height: 520px;
  //min-width: 230px;
  //min-height: 260px;
`;
export const StyledLoginTitleText = styled.span<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
  $reduceFont?: boolean;
}>`
  color: ${(p) => p.$themeColor.primaryFontColor};
  font-size: ${(p) => p.$reduceFont ? "7rem" : "9rem"} !important;
  font-family: ${(p) => p.$doxleFont.primaryTitleFont};
  font-style: normal;
  font-weight: 700;
  line-height: 118.9%;
  letter-spacing: -1.8px;
  text-transform: none;
`;

export const StyledLoginFormContainer = styled(motion(FormControl))<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
  $height: number;
}>`
  && {
    margin-top: 14px;
    z-index: 1;
    width: 409px;
    height: ${(p) => p.$height}px;
    border-radius: 9px;
    border: 1px solid ${(p) => p.$themeColor.bolderDividerColor};
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
    overflow: visible;
    @media (max-width: 767px) {
      width: 300px;
      height: ${(p) => p.$height - 20}px;
    }
    @media (min-width: 768px) {
      width: 300px;
      height: ${(p) => p.$height - 20}px;
    }
    @media (min-width: 1024px) {
      width: 350px;
      height: ${(p) => p.$height - 10}px;
    }
    @media (min-width: 1280px) {
      width: 409px;
      height: ${(p) => p.$height}px;
    }
  }
`;

interface LoginTextFieldProps extends TextFieldProps<"standard"> {
  themeColor: DoxleThemeColor;
  doxleFont: DoxleFont;
  hasBorderBottom?: boolean;
  heightRatio: `${number}%`;
}
export const StyledLoginTextField = styled(TextField)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
  $hasBorderBottom?: boolean;
  $heightRatio: `${number}%`;
}>`
  && .MuiInputBase-input {
    font-size: 14px;
    color: ${(p) => p.$themeColor.primaryFontColor} !important;
  }
  && {
    width: 100%;
    height: ${(p) => p.$heightRatio};
    display: flex;
    justify-content: center;
    border-bottom: ${(p) => (p.$hasBorderBottom ? 1 : 0)}px solid ${(p) => p.$themeColor.bolderDividerColor};
    color: ${(p) => p.$themeColor.primaryFontColor} !important;
    font-size: 30px;
    font-family: ${(p) => p.$doxleFont.primaryTitleFont};
    font-style: normal;
    font-weight: 400;
    text-transform: none;
    @media (max-width: 767px) {
      font-size: 14px;
    }
    @media (min-width: 768px) {
      font-size: 14px;
    }
    @media (min-width: 1024px) {
      font-size: 16px;
    }
    @media (min-width: 1280px) {
      font-size: 18px;
    }
    &::placeholder {
      color: ${(p) => p.$themeColor.primaryInputPlaceholderColor} !important;
      font-size: 18px;
      font-family: ${(p) => p.$doxleFont.primaryTitleFont};
      font-style: normal;
      font-weight: 400;
      text-transform: none;
      @media (min-width: 768px) {
        font-size: 14px;
      }
      @media (max-width: 767px) {
        font-size: 14px;
      }
      @media (min-width: 1024px) {
        font-size: 16px;
      }
      @media (min-width: 1280px) {
        font-size: 18px;
      }
    }
  }

  & .MuiOutlinedInput-input {
    padding: 0px 14px !important;
  }
  & .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }
`;
export const StyledLoginButton = styled(Button)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  && {
    min-width: 0px !important;
    padding: 0px 30px !important;
    background-color: ${(p) => p.$themeColor.primaryReverseContainerColor};
    color: ${ (p) => p.$themeColor.primaryReverseFontColor};
    font-size: 18px;
    font-family: ${(p) => p.$doxleFont.primaryTitleFont};
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: none;

    z-index: 1;
    height: 30px;
    &:hover {
      background-color: ${(p) => editRgbaAlpha({
        rgbaColor: p.$themeColor.primaryReverseContainerColor as TRgbaFormat,
        alpha: "0.8",
      })} !important;
      transition: 0.2s;
      transform: scale(1.1);
    }
    @media (min-width: 768px) {
      font-size: 14px;
    }
    @media (max-width: 767px) {
      font-size: 14px;
    }
    @media (min-width: 1024px) {
      font-size: 16px;
    }
    @media (min-width: 1280px) {
      font-size: 18px;
    }
  }
`;

export const StyledLoginModeButton = styled(Button)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  && {
    min-width: 0px !important;
    padding: 0px !important;
    margin: 0px 4px !important;
    background-color: transparent;
    color: ${(p) => editRgbaAlpha({
      alpha: "0.8",
      rgbaColor: p.$themeColor.primaryFontColor as TRgbaFormat,
    })};
    font-size: 14px;
    font-family: ${(p) => p.$doxleFont.secondaryTitleFont};
    font-style: normal;
    font-weight: 400;
    text-transform: none;
    height: 28px;
    &:hover {
      background-color: transparent !important;
      transition: 0.2s;
      text-decoration: underline;
    }
    @media (min-width: 768px) {
      font-size: 14px;
    }
    @media (max-width: 767px) {
      font-size: 14px;
    }
    @media (min-width: 1024px) {
      font-size: 16px;
    }
    @media (min-width: 1280px) {
      font-size: 18px;
    }
  }
`;
export const StyledLoginModeBtnContainer = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 1;
  color: ${(p) => editRgbaAlpha({
    alpha: "0.8",
    rgbaColor: p.$themeColor.primaryFontColor as TRgbaFormat,
  })};
  font-size: 14px;
  font-family: ${(p) => p.$doxleFont.secondaryTitleFont};
  font-style: normal;
  font-weight: 400;
  text-transform: none;
`;
export const StyledMagicLinkLandingPageContainer = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  span {
    color: ${(p) => p.$themeColor.primaryFontColor};
    font-size: 36px;
    font-family: ${(p) => p.$doxleFont.primaryTitleFont};
    font-style: normal;
    font-weight: 600;
    text-transform: none;
    margin-top: 14px;
    margin-bottom: 20px;
  }

  @media (min-width: 768px) {
    font-size: 24px;
  }
  @media (max-width: 767px) {
    font-size: 24px;
  }
  @media (min-width: 1024px) {
    font-size: 28px;
  }
  @media (min-width: 1280px) {
    font-size: 32px;
  }
`;
export const StyledStyledMagicLinkLandingPageImg = styled.img`
  width: 393px;
  height: 561px;
  @media (min-width: 768px) {
    width: 300px;
    height: 440px;
  }
  @media (max-width: 767px) {
    width: 300px;
    height: 440px;
  }
  @media (min-width: 1024px) {
    width: 340px;
    height: 500px;
  }
  @media (min-width: 1280px) {
    width: 393px;
    height: 561px;
  }
`;
export const StyledErrorToolTip = styled(Tooltip)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  &.MuiTooltip-tooltip {
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: red !important;
    text-transform: none;
    background-color: ${(p)=> p.$themeColor.primaryContainerColor};
    box-shadow: 1px 2px 4px grey !important;
    border-radius: 4px;
  }
`;

export const StyledLoginPreloaderContainer = styled(motion.div)``;
