import { useState } from "react";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import {
  StyledCloseButton,
  StyledStarButton,
} from "../AddInventoryScreen/StyledAddInventory";
import { InventoryImage } from "../Models/Inventory";
import { StyledEditInventoryFileContainer } from "./StyledEditInventory";
import { useInventoryStore } from "../Store/useInventoryStore";
import { CircularProgress } from "@mui/material";
import { useIsMutating } from "@tanstack/react-query";
import {
  AddInventoryDeleteImageParams,
  getInventoryMutateKey,
} from "../QueryAPIHooks/InventoryImageAPI";
import { DoxleDeleteIcon } from "../../DoxleDesignPattern/DoxleGeneralIcons";
import { AnimatePresence } from "framer-motion";
import BrokenImageOutlinedIcon from "@mui/icons-material/BrokenImageOutlined";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import StarOutlinedIcon from "@mui/icons-material/StarOutlined";
import InventoryQueryAPI from "../QueryAPIHooks/InventoryQueryAPI";
import useDoxleNotificationStore from "../../DoxleGeneralStore/useDoxleNotificationStore";
import { shallow } from "zustand/shallow";
import { useDoxleCurrentContextStore } from "../../DoxleGeneralStore/useDoxleCurrentContext";

type Props = {
  img: InventoryImage;
  index: number;
  handleDeleteFile: (index: number) => void;
};

const InventoryImageItems = ({ img, index, handleDeleteFile }: Props) => {
  const [showIcon, setShowIcon] = useState(false);
  const [imgError, setImgError] = useState(false);
  const { doxleThemeColor, currentTheme, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
      currentTheme: state.currentTheme,
    })
  );

  const { company } = useDoxleCurrentContextStore(
    (state) => ({
      company: state.currentCompany,
    }),
    shallow
  );

  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );

  const { currentEditInventory, setCurrentEditInventory } = useInventoryStore(
    (state) => ({
      currentEditInventory: state.currentEditInventory,
      setCurrentEditInventory: state.setCurrentEditInventory,
    }),
    shallow
  );

  const setInventoryDefaultImgQuery =
    InventoryQueryAPI.useSetInventoryDefaultImgQuery({
      showNotification,
      company,
      onSuccessUpdateCb: (newItem) => {
        if (newItem) setCurrentEditInventory(newItem);
      },
    });

  const handleSetDefaultImg = () => {
    if (currentEditInventory?.defaultImage !== img.url && !isDeletingItem) {
      setInventoryDefaultImgQuery.mutate({
        imageId: img.imageId,
        isDefault: true,
      });
    }
  };
  const isDeletingItem =
    useIsMutating({
      mutationKey: getInventoryMutateKey("delete"),
      predicate: (query) =>
        Boolean(
          (
            query.state.variables as AddInventoryDeleteImageParams
          ).imageIds.includes(img.imageId)
        ),
    }) > 0;

  return (
    <StyledEditInventoryFileContainer
      onMouseEnter={() => setShowIcon(true)}
      onMouseLeave={() => setShowIcon(false)}
      $themeColor={doxleThemeColor}
      $doxleFont={doxleFont}
      $currentTheme={currentTheme}
    >
      {!imgError ? (
        <div>
          <img
            src={img.url}
            style={{ width: "80%", aspectRatio: 1 }}
            onError={() => {
              setImgError(true);
            }}
          />
          <AnimatePresence>
            {(showIcon || isDeletingItem) && (
              <>
                <StyledStarButton
                  animate={{
                    scale: [0, 1],
                    opacity: [0, 1],
                  }}
                  exit={{
                    scale: [1, 0],
                    opacity: [1, 0],
                  }}
                  transition={{ duration: 0.2 }}
                  onClick={handleSetDefaultImg}
                >
                  {currentEditInventory &&
                  currentEditInventory?.defaultImage === img.url ? (
                    <StarOutlinedIcon
                      sx={{ color: doxleThemeColor.doxleColor, fontSize: 30 }}
                    />
                  ) : (
                    <StarBorderIcon
                      sx={{ color: doxleThemeColor.doxleColor, fontSize: 30 }}
                    />
                  )}
                </StyledStarButton>
                <StyledCloseButton
                  animate={{
                    scale: [0, 1],
                    opacity: [0, 1],
                  }}
                  exit={{
                    scale: [1, 0],
                    opacity: [1, 0],
                  }}
                  transition={{ duration: 0.2 }}
                  onClick={() => {
                    handleDeleteFile(index);
                    // setIsDeleting(true);
                  }}
                >
                  {isDeletingItem ? (
                    <CircularProgress color="error" size={20} />
                  ) : (
                    <DoxleDeleteIcon
                      themeColor={doxleThemeColor}
                      iconColor={doxleThemeColor.errorColor}
                      containerStyle={{ width: "max(2rem, 20px)" }}
                    />
                  )}
                </StyledCloseButton>
              </>
            )}
          </AnimatePresence>
        </div>
      ) : (
        <BrokenImageOutlinedIcon color="error" sx={{ fontSize: 30 }} />
      )}
    </StyledEditInventoryFileContainer>
  );
};

export default InventoryImageItems;
