import { SVGProps } from "react";
import { DoxleThemeColor } from "../../../../DoxleGeneralStore/useDoxleThemeStore";
import { IoSettingsOutline } from "react-icons/io5";
import { ISVGResponsiveCustom } from "../../../../Models/modelHelper";
import { PiSignOutThin } from "react-icons/pi";

export interface CustomSVGProps extends SVGProps<SVGSVGElement> {
  themeColor: DoxleThemeColor;
}

export const StyledInviteIcon = ({ themeColor, ...props }: CustomSVGProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={17}
    fill="none"
    {...props}
  >
    <path
      fill={themeColor.primaryFontColor}
      fillOpacity={0.7}
      stroke="#fff"
      strokeLinejoin="round"
      strokeWidth={0.4}
      d="M4.253 4.636c0-1.38 1.068-2.5 2.386-2.5 1.317 0 2.385 1.12 2.385 2.5 0 1.381-1.068 2.5-2.385 2.5-1.318 0-2.386-1.119-2.386-2.5ZM6.64 1c-1.917 0-3.47 1.628-3.47 3.636 0 2.009 1.553 3.637 3.47 3.637 1.916 0 3.47-1.628 3.47-3.637C10.109 2.628 8.555 1 6.639 1Zm6.289 4.545c0-.878.68-1.59 1.518-1.59s1.518.712 1.518 1.59c0 .88-.68 1.591-1.518 1.591s-1.518-.712-1.518-1.59Zm1.518-2.727c-1.437 0-2.602 1.221-2.602 2.727 0 1.507 1.165 2.728 2.602 2.728 1.437 0 2.603-1.221 2.603-2.728 0-1.506-1.166-2.727-2.603-2.727Zm0 12.273c-.944 0-1.7-.16-2.306-.42a4.863 4.863 0 0 0 .585-.982c.443.162 1.005.265 1.72.265 1.855 0 2.678-.696 3.064-1.287a2.59 2.59 0 0 0 .406-1.204v-.122a.556.556 0 0 0-.541-.568h-4.289a2.655 2.655 0 0 0-.544-1.137h4.832c.899 0 1.627.764 1.627 1.705v.135a2.233 2.233 0 0 1-.01.166 3.755 3.755 0 0 1-.586 1.668c-.617.944-1.8 1.78-3.958 1.78ZM2.626 9.636C1.729 9.636 1 10.4 1 11.341v.592a1.75 1.75 0 0 0 .013.189 4.034 4.034 0 0 0 .734 1.892C2.508 15.066 3.96 16 6.64 16c2.678 0 4.13-.934 4.891-1.986a4.034 4.034 0 0 0 .734-1.892 2.585 2.585 0 0 0 .013-.189v-.592c0-.941-.728-1.705-1.626-1.705H2.627Zm-.541 2.284v-.579c0-.314.242-.568.542-.568h8.024c.3 0 .542.254.542.568v.579a2.475 2.475 0 0 1-.063.4c-.066.273-.198.64-.464 1.007-.513.71-1.61 1.537-4.027 1.537-2.418 0-3.515-.828-4.028-1.537A2.873 2.873 0 0 1 2.091 12a1.486 1.486 0 0 1-.006-.079Z"
    />
  </svg>
);

export const StyledReportIcon = ({ themeColor, ...props }: CustomSVGProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={16}
    fill="none"
    {...props}
  >
    <path
      fill={themeColor.primaryFontColor}
      d="M16.087 1.111c.435 0 .788.348.788.778v11.333c0 .92-.756 1.667-1.688 1.667h-7.2a1.7 1.7 0 0 0 .113-.613V1.89c0-.43.353-.778.787-.778h7.2ZM6.47 15.996V16h8.718C16.741 16 18 14.756 18 13.222V1.89A1.9 1.9 0 0 0 16.087 0h-7.2a1.9 1.9 0 0 0-1.912 1.889v1.667H1.8c-1 .037-1.8.85-1.8 1.846v5.154a1.9 1.9 0 0 0 1.913 1.888h2.256l.48 2.196a1.74 1.74 0 0 0 1.82 1.356Zm.506-1.72a.616.616 0 0 1-.62.613.619.619 0 0 1-.606-.483L3.697 5.012a1.833 1.833 0 0 0-.112-.346h3.39v9.61ZM2.598 5.247l1.329 6.086H1.913a.783.783 0 0 1-.788-.777V5.402a.74.74 0 0 1 .745-.735c.35 0 .654.241.728.58Zm12.027-.358a.67.67 0 0 1-.675-.667.67.67 0 0 1 .675-.666.67.67 0 0 1 .675.666.67.67 0 0 1-.675.667Zm.675 2.778a.559.559 0 0 0-.563-.556h-4.725a.56.56 0 0 0-.562.556.56.56 0 0 0 .563.555h4.725c.31 0 .562-.249.562-.555Zm-.563 3c.311 0 .563.248.563.555a.56.56 0 0 1-.563.556h-4.725a.56.56 0 0 1-.562-.556c0-.307.252-.555.563-.555h4.725Z"
    />
  </svg>
);

export const StyledFileIcon = ({
  themeColor,
  staticColor,
  containerStyle,
  ...props
}: ISVGResponsiveCustom & { staticColor?: string }) => (
  <div
    style={{
      width: 20,
      ...containerStyle,
      aspectRatio: 20 / 17,
      display: "flex",
    }}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 20 17"
      fill="none"
      {...props}
    >
      <path
        stroke={staticColor ?? themeColor.primaryFontColor}
        strokeWidth={1}
        d="m1.29 14.976 1.733-7.22c.226-.939 1.038-1.598 1.97-1.598h12.252v-.705c0-1.165-.91-2.11-2.03-2.11h-5.166L7.9 1.488A1.984 1.984 0 0 0 6.603 1h-3.57c-1.12 0-2.029.944-2.03 2.109L1 13.889c0 .398.105.77.29 1.087Zm0 0c-.002.004.038-.161 0 0Zm1.288.442c-.071.296.144.582.438.582h12.848c.724 0 1.356-.512 1.531-1.243l1.591-6.61c.072-.296-.144-.583-.437-.583H4.993c-.31 0-.581.22-.657.533l-1.758 7.32Z"
      />
    </svg>
  </div>
);

export const StyledXeroIcon = ({ themeColor, ...props }: CustomSVGProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    {...props}
  >
    <circle
      cx={8}
      cy={8}
      r={7.65}
      stroke={themeColor.primaryFontColor}
      strokeOpacity={0.7}
      strokeWidth={0.7}
    />
    <path
      fill={themeColor.primaryFontColor}
      fillOpacity={0.7}
      d="m2.966 7 .846 1.284.12.018L5.15 10h-.762l-.882-1.32-.138-.048L2.192 7h.774Zm2.166 0L3.956 8.608l-.132-.006L2.864 10h-.732L3.41 8.29l.126.012L4.394 7h.738Zm1.736 3.06c-.3 0-.564-.062-.792-.186a1.32 1.32 0 0 1-.534-.534 1.783 1.783 0 0 1-.186-.84c0-.324.062-.602.186-.834.128-.236.304-.416.528-.54.224-.124.478-.186.762-.186.292 0 .54.06.744.18.204.12.36.284.468.492.108.208.162.444.162.708 0 .072-.002.14-.006.204-.004.064-.01.12-.018.168H5.77V8.2H7.9l-.318.096c0-.272-.068-.48-.204-.624-.136-.148-.322-.222-.558-.222a.831.831 0 0 0-.45.12.759.759 0 0 0-.294.36 1.441 1.441 0 0 0-.102.576c0 .224.036.414.108.57a.785.785 0 0 0 .306.354c.132.08.288.12.468.12a.92.92 0 0 0 .486-.114.807.807 0 0 0 .294-.318l.51.24a1.222 1.222 0 0 1-.726.618 1.737 1.737 0 0 1-.552.084ZM8.897 10V7h.546l.06.54a.922.922 0 0 1 .354-.444c.16-.104.356-.156.588-.156.06 0 .12.006.18.018.06.008.11.022.15.042l-.09.564a.75.75 0 0 0-.15-.036 1.01 1.01 0 0 0-.216-.018.735.735 0 0 0-.366.102.782.782 0 0 0-.3.294 1 1 0 0 0-.114.504V10h-.642Zm3.59-3.06c.292 0 .55.062.774.186.224.124.398.304.522.54.128.232.192.51.192.834 0 .324-.064.604-.192.84-.124.232-.298.41-.522.534a1.572 1.572 0 0 1-.774.186c-.292 0-.55-.062-.774-.186a1.33 1.33 0 0 1-.528-.534 1.737 1.737 0 0 1-.192-.84c0-.324.064-.602.192-.834.128-.236.304-.416.528-.54.224-.124.482-.186.774-.186Zm0 .504a.823.823 0 0 0-.462.126.776.776 0 0 0-.288.354 1.441 1.441 0 0 0-.102.576c0 .224.034.416.102.576a.814.814 0 0 0 .288.36c.128.08.282.12.462.12.18 0 .332-.04.456-.12a.814.814 0 0 0 .288-.36c.068-.16.102-.352.102-.576 0-.228-.034-.42-.102-.576a.776.776 0 0 0-.288-.354.794.794 0 0 0-.456-.126Z"
    />
  </svg>
);
export const InventoryIcon = ({
  staticColor,
  themeColor,
  containerStyle,
  ...props
}: ISVGResponsiveCustom & {
  staticColor?: string;
}) => (
  <div
    style={{ width: 18, ...containerStyle, aspectRatio: 1, display: "flex" }}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={18}
      height={18}
      viewBox="0 0 18 18"
      fill="none"
      {...props}
    >
      <path
        opacity={1}
        fill={staticColor ?? themeColor.primaryFontColor}
        d="M2.615 18c-.447 0-.828-.157-1.143-.472A1.557 1.557 0 0 1 1 16.385V5.263a1.637 1.637 0 0 1-.715-.577A1.54 1.54 0 0 1 0 3.769V1.615C0 1.168.157.787.472.472A1.557 1.557 0 0 1 1.615 0h14.77c.447 0 .828.157 1.143.472.315.315.472.696.472 1.143V3.77c0 .345-.095.651-.285.917a1.63 1.63 0 0 1-.715.577v11.122c0 .447-.157.828-.472 1.143a1.557 1.557 0 0 1-1.143.472L2.615 18ZM2 5.385v10.903c0 .205.074.375.221.51.147.135.33.202.549.202h12.616c.18 0 .327-.058.442-.173a.599.599 0 0 0 .173-.442v-11H2Zm-.385-1h14.77c.18 0 .327-.058.442-.173A.602.602 0 0 0 17 3.769V1.615a.599.599 0 0 0-.173-.442.599.599 0 0 0-.442-.173H1.615a.599.599 0 0 0-.442.173.599.599 0 0 0-.173.442V3.77a.6.6 0 0 0 .173.443.598.598 0 0 0 .442.172Zm5.212 5.481h4.365a.4.4 0 0 0 .309-.124.452.452 0 0 0 .114-.318.42.42 0 0 0-.114-.309.415.415 0 0 0-.309-.115H6.808a.418.418 0 0 0-.309.114.421.421 0 0 0-.114.31.43.43 0 0 0 .124.317.43.43 0 0 0 .318.124"
      />
    </svg>
  </div>
);
export const StyledStoryIcon = ({
  staticColor,
  themeColor,
  containerStyle,
  ...props
}: ISVGResponsiveCustom & {
  staticColor?: string;
}) => (
  <div
    style={{
      width: 18,
      ...containerStyle,
      aspectRatio: 18 / 22,
      display: "flex",
    }}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 18 22"
      fill="none"
      {...props}
    >
      <path
        fill={staticColor ?? themeColor.primaryFontColor}
        strokeLinecap="round"
        stroke={staticColor ?? themeColor.primaryFontColor}
        strokeLinejoin="round"
        strokeWidth={0.01}
        opacity={1}
        d="M5.75 10.5a.75.75 0 0 0 0 1.5h6.5a.75.75 0 0 0 0-1.5h-6.5Zm0 2.75a.75.75 0 0 0 0 1.5h6.5a.75.75 0 0 0 0-1.5h-6.5Zm0 2.75a.75.75 0 0 0 0 1.5h6.5a.75.75 0 0 0 0-1.5h-6.5Zm4.835-14.414 5.829 5.828A2 2 0 0 1 17 8.828V19a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h6.172c.028 0 .055.004.082.007.02.003.04.006.059.007.215.015.427.056.624.138.057.024.112.056.166.087l.05.029.047.024a.652.652 0 0 1 .081.044c.078.053.148.116.219.18a.63.63 0 0 0 .036.03.491.491 0 0 1 .049.04ZM15 19.5a.5.5 0 0 0 .5-.5V9H11a2 2 0 0 1-2-2V2.5H3a.5.5 0 0 0-.5.5v16a.5.5 0 0 0 .5.5h12Zm-.622-12L10.5 3.621V7a.5.5 0 0 0 .5.5h3.378Z"
      />
    </svg>
  </div>
);

export const StyledThemeIcon = ({ themeColor, ...props }: CustomSVGProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={17}
    height={17}
    fill="none"
    {...props}
  >
    <path
      fill={themeColor.primaryFontColor}
      d="M8.5 17a8.5 8.5 0 1 0 0-17 8.5 8.5 0 0 0 0 17Zm0-1.7V1.7a6.8 6.8 0 0 1 0 13.6Z"
    />
  </svg>
);

export const StyledFontIcon = ({ themeColor, ...props }: CustomSVGProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      fill={themeColor.primaryFontColor}
      d="M6 2a.5.5 0 0 1 .467.319l2.43 6.25a.482.482 0 0 1 .009.022l.17.437-.527 1.404-.456-1.17H3.907l-.94 2.42a.5.5 0 1 1-.933-.363L3.095 8.59a.504.504 0 0 1 .008-.021l2.432-6.251A.5.5 0 0 1 6 2ZM4.297 8.262h3.408L6.001 3.88 4.296 8.262Zm8.171-2.94a.5.5 0 0 0-.935.002L7.154 17H6.5a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1h-.278l1.125-3h5.373l1.149 3H15.5a.5.5 0 1 0 0 1h2a.5.5 0 1 0 0-1h-.56L12.466 5.32ZM14.337 13H9.722l2.283-6.09L14.337 13Z"
    />
  </svg>
);

export const StyledButtonBarIcon = ({
  themeColor,
  ...props
}: CustomSVGProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={18}
    fill="none"
    {...props}
  >
    <path
      fill={themeColor.primaryFontColor}
      stroke="#fff"
      strokeWidth={0.4}
      d="M1 3.778A2.778 2.778 0 0 1 3.778 1h10.444A2.778 2.778 0 0 1 17 3.778v10.444A2.778 2.778 0 0 1 14.222 17H3.778A2.778 2.778 0 0 1 1 14.222V3.778ZM3.778 2.11c-.92 0-1.667.746-1.667 1.667v8.555H15.89V3.778c0-.92-.746-1.667-1.667-1.667H3.778ZM2.11 14.222c0 .92.746 1.667 1.667 1.667h10.444c.92 0 1.667-.746 1.667-1.667v-.778H2.11v.778Z"
    />
  </svg>
);

export const StyledSettingsIcon = ({
  themeColor,
  staticColor,
  ...props
}: CustomSVGProps & { staticColor?: string }) => (
  <svg
    stroke={staticColor ?? themeColor.primaryFontColor}
    fill={staticColor ?? themeColor.primaryFontColor}
    strokeWidth="0"
    viewBox="0 0 512 512"
    width={18}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    opacity={0.7}
  >
    <path
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="32"
      d="M262.29 192.31a64 64 0 1057.4 57.4 64.13 64.13 0 00-57.4-57.4zM416.39 256a154.34 154.34 0 01-1.53 20.79l45.21 35.46a10.81 10.81 0 012.45 13.75l-42.77 74a10.81 10.81 0 01-13.14 4.59l-44.9-18.08a16.11 16.11 0 00-15.17 1.75A164.48 164.48 0 01325 400.8a15.94 15.94 0 00-8.82 12.14l-6.73 47.89a11.08 11.08 0 01-10.68 9.17h-85.54a11.11 11.11 0 01-10.69-8.87l-6.72-47.82a16.07 16.07 0 00-9-12.22 155.3 155.3 0 01-21.46-12.57 16 16 0 00-15.11-1.71l-44.89 18.07a10.81 10.81 0 01-13.14-4.58l-42.77-74a10.8 10.8 0 012.45-13.75l38.21-30a16.05 16.05 0 006-14.08c-.36-4.17-.58-8.33-.58-12.5s.21-8.27.58-12.35a16 16 0 00-6.07-13.94l-38.19-30A10.81 10.81 0 0149.48 186l42.77-74a10.81 10.81 0 0113.14-4.59l44.9 18.08a16.11 16.11 0 0015.17-1.75A164.48 164.48 0 01187 111.2a15.94 15.94 0 008.82-12.14l6.73-47.89A11.08 11.08 0 01213.23 42h85.54a11.11 11.11 0 0110.69 8.87l6.72 47.82a16.07 16.07 0 009 12.22 155.3 155.3 0 0121.46 12.57 16 16 0 0015.11 1.71l44.89-18.07a10.81 10.81 0 0113.14 4.58l42.77 74a10.8 10.8 0 01-2.45 13.75l-38.21 30a16.05 16.05 0 00-6.05 14.08c.33 4.14.55 8.3.55 12.47z"
    />
  </svg>
);

export const StyledChangeFontIcon = ({
  themeColor,
  ...props
}: CustomSVGProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={12}
    height={12}
    fill="none"
    {...props}
  >
    <path
      fill={themeColor.primaryFontColor}
      d="M3 0c.155 0 .294.095.35.24l1.823 4.687.006.016.127.328-.395 1.053-.341-.878H1.43L.725 7.261a.375.375 0 1 1-.7-.272l.796-2.046a.373.373 0 0 1 .007-.016L2.65.24A.375.375 0 0 1 3 0ZM1.722 4.696h2.556L3 1.41 1.722 4.696ZM7.85 2.491a.375.375 0 0 0-.701.002L3.865 11.25h-.49a.375.375 0 0 0 0 .75h1.5a.375.375 0 0 0 0-.75h-.209L5.51 9h4.03l.861 2.25h-.276a.375.375 0 0 0 0 .75h1.5a.375.375 0 0 0 0-.75h-.42L7.85 2.49ZM9.253 8.25H5.79l1.713-4.567L9.253 8.25Z"
    />
  </svg>
);

export const StyledLogOutIcon = ({
  staticColor,
  themeColor,
  containerStyle,
  ...props
}: ISVGResponsiveCustom & {
  staticColor?: string;
}) => (
  <div
    style={{ width: 18, ...containerStyle, aspectRatio: 1, display: "flex" }}
  >
    <svg
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      viewBox="0 0 20 20"
      {...props}
    >
      <path
        height="100%"
        width="100%"
        stroke={staticColor ?? themeColor.primaryFontColor}
        strokeWidth={1}
        d="M9 21H5a2 2 0 01-2-2V5a2 2 0 012-2h4M16 17l5-5-5-5M21 12H9"
      />
    </svg>
  </div>
);
