import { useState } from "react";
import { useDoxleCurrentContextStore } from "../../DoxleGeneralStore/useDoxleCurrentContext";
import { useShallow } from "zustand/react/shallow";
import useDoxleNotificationStore from "../../DoxleGeneralStore/useDoxleNotificationStore";
import { useFileStore } from "../Store/useFileStore";
import FilesQueryAPI from "../QueryHooks/FileStorageQueryAPI";

const useFileRightSubheader = () => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const { company } = useDoxleCurrentContextStore(
    useShallow((state) => ({
      company: state.currentCompany,
    }))
  );
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification
  );
  const {
    currentFolder,
    selectedFiles,
    clearSelectedFiles,
    setIsProcessing,
    filterRootFileQuery,
  } = useFileStore(
    useShallow((state) => ({
      setIsProcessing: state.setIsProcessing,
      currentFolder: state.currentFolder,
      selectedFiles: state.selectedFiles,
      clearSelectedFiles: state.clearSelectedFiles,
      filterRootFileQuery: state.filterRootFileQuery,
    }))
  );
  const onDeleteFileSuccessCallback = () => {
    clearSelectedFiles();
  };

  const onUpdateFileCallback = () => {
    //* ONCE UPDATE IS SUCCESSFULL WE NEED TO CLEAR THE SELECTION
    clearSelectedFiles();
  };

  const deleteFileQuery = FilesQueryAPI.useDeleteFileQuery({
    company,
    showNotification,
    onDeleteFileCallback: onDeleteFileSuccessCallback,
    filter: currentFolder
      ? {
          folderId: currentFolder.folderId,
        }
      : filterRootFileQuery,
  });

  const updateFileQuery = FilesQueryAPI.useUpdateFileQuery({
    company,
    showNotification,
    onUpdateFileCallback: onUpdateFileCallback,
    filter: currentFolder
      ? {
          folderId: currentFolder.folderId,
        }
      : filterRootFileQuery,
  });

  const editFile = () => {
    if (selectedFiles.length === 0) {
      showNotification(
        "No Files to edit",
        "error",
        "Please only select one file to edit at a time",
        2000
      );
    } else if (selectedFiles.length > 1) {
      showNotification(
        "Cannot edit multiple files",
        "error",
        "Please only select one file to edit at a time",
        2000
      );
    }
    //* ONLY 1 FILE TO EDIT MAKE AN API CALL
    else {
      setDialogOpen(true);
    }
  };

  const deleteFile = () => {
    console.log("Deleting Files");
    console.log(selectedFiles);

    if (selectedFiles.length > 0) {
      setIsProcessing(true);
      deleteFileQuery.mutate({
        files: selectedFiles,
        currentFolderId: currentFolder ? currentFolder?.folderId : undefined,
        currentFolderName: currentFolder
          ? currentFolder?.folderName
          : undefined,
      });
    } else {
      showNotification("No Files to delete", "error");
    }
  };

  const handleDialogKeyDown = (newFileName: string) => {
    updateFileQuery.mutate({
      fileId: selectedFiles[0].fileId,
      fileName: newFileName,
      currentFolderId: currentFolder ? currentFolder?.folderId : undefined,
      currentFolderName: currentFolder ? currentFolder?.folderName : undefined,
    });

    setDialogOpen(false);
  };

  const handleDialogTick = (newFileName: string) => {
    updateFileQuery.mutate({
      fileId: selectedFiles[0].fileId,
      fileName: newFileName,
      currentFolderId: currentFolder ? currentFolder?.folderId : undefined,
      currentFolderName: currentFolder ? currentFolder?.folderName : undefined,
    });

    setDialogOpen(false);
  };
  const handleDialogClose = () => {
    setDialogOpen(false);
  };
  return {
    handleDialogClose,
    dialogOpen,
    editFile,
    deleteFile,
    handleDialogKeyDown,
    handleDialogTick,
    selectedFiles,
  };
};

export default useFileRightSubheader;
