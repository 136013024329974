import React from "react";
import { QuoteRequestStatus } from "../../models/quote";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { StyledQuotesStatus } from "../MainStyledComponents";

type Props = { status: QuoteRequestStatus };

const quoteStatus: Record<QuoteRequestStatus, { color: string }> = {
  Accepted: {
    color: "rgba(18, 183, 24, 0.70)",
  },
  Rejected: {
    color: "rgba(255, 116, 116, 0.70)",
  },
  Draft: {
    color: "#7070EF",
  },
  Expired: { color: "rgba(255,0,0,1  }" },
  Received: { color: "rgba(18, 183, 24, 0.44)" },
  Refused: { color: "" },
  Requested: {
    color: "#FFE274",
  },
};

const QuoteStatus = ({ status }: Props) => {
  const { doxleFont } = useDoxleThemeStore((state) => ({
    THEME_COLOR: state.doxleThemeColor,
    doxleFont: state.doxleFont,
  }));
  return (
    <StyledQuotesStatus
      $bgColor={quoteStatus[status]?.color}
      $doxleFont={doxleFont}
    >
      {status === "Requested" ? "Waiting" : status}
    </StyledQuotesStatus>
  );
};

export default QuoteStatus;
