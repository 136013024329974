import React, { useState } from "react";
import { PricebookSupplierRate } from "../../../Models/Pricebook";
import { usePricebookStore } from "../../../Store/usePricebookStore";
import { useShallow } from "zustand/react/shallow";
import { useIsMutating } from "@tanstack/react-query";
import { getSupplierRateMutationKey } from "../../../QueryHooks/PricebookQueryAPI";

type Props = { rate: PricebookSupplierRate };

interface IPricebookTableRow {
  handleDeleteSupplierRate: (
    e: React.MouseEvent<HTMLButtonElement | HTMLElement>
  ) => void;
  isDeletingItem: boolean;

  handleClickRateRow: () => void;
}
const usePricebookTableRow = ({ rate }: Props): IPricebookTableRow => {
  const { setDeletePricebookRateItem, setEditedPricebookItemId } =
    usePricebookStore(
      useShallow((state) => ({
        setDeletePricebookRateItem: state.setDeletePricebookRateItem,
        setEditedPricebookItemId: state.setEditedPricebookItemId,
      }))
    );
  const handleClickRateRow = () => {
    setEditedPricebookItemId(rate.pricebookItem);
  };
  const handleDeleteSupplierRate = (
    e: React.MouseEvent<HTMLButtonElement | HTMLElement>
  ) => {
    e.stopPropagation();
    setDeletePricebookRateItem(rate);
  };

  const isDeletingItem =
    useIsMutating({
      mutationKey: getSupplierRateMutationKey("delete"),
      predicate: (query) => query.state.variables === rate.rateId,
    }) > 0;

  return {
    handleDeleteSupplierRate,
    isDeletingItem,

    handleClickRateRow,
  };
};

export default usePricebookTableRow;
