import {
  ForwardRefComponent,
  MotionProps,
  SVGMotionProps,
  motion,
} from "framer-motion";
import { DoxleThemeColor } from "../../DoxleGeneralStore/useDoxleThemeStore";
import { ISVGResponsiveCustom } from "../../Models/modelHelper";

interface ButtonSVGProps
  extends Omit<
    ForwardRefComponent<SVGSVGElement, SVGMotionProps<SVGSVGElement>>,
    "$$typeof"
  > {
  themeColor: DoxleThemeColor;
}
export const LogoutIcon = ({ themeColor, ...rest }: ButtonSVGProps) => (
  <motion.svg
    xmlns="http://www.w3.org/2000/svg"
    width={13}
    height={13}
    fill="none"
    style={{ marginRight: 8 }}
    {...rest}
  >
    <path
      fill={themeColor.primaryFontColor}
      d="M9.595 6.189a3.405 3.405 0 1 1 0 6.81 3.405 3.405 0 0 1 0-6.81ZM6.203 7.427a4.007 4.007 0 0 0-.439.929h-4.37a.464.464 0 0 0-.465.464v.562c0 .406.177.792.485 1.057.779.67 1.952 1.014 3.537 1.014.37 0 .72-.02 1.045-.057.154.31.349.597.575.855a9.39 9.39 0 0 1-1.62.13c-1.789 0-3.175-.406-4.143-1.238A2.322 2.322 0 0 1 0 9.383V8.82c0-.769.624-1.393 1.393-1.393h4.81Zm5.44.776-3.44 3.44a2.477 2.477 0 0 0 3.44-3.44ZM9.596 7.118a2.477 2.477 0 0 0-2.05 3.868l3.441-3.44a2.465 2.465 0 0 0-1.391-.428ZM4.95 0a3.096 3.096 0 1 1 0 6.192A3.096 3.096 0 0 1 4.95 0Zm0 .929a2.167 2.167 0 1 0 0 4.334 2.167 2.167 0 0 0 0-4.334Z"
    />
  </motion.svg>
);

export const ReportIcon = ({ themeColor, ...rest }: ButtonSVGProps) => (
  <motion.svg
    xmlns="http://www.w3.org/2000/svg"
    width={11}
    height={11}
    fill="none"
    style={{ marginRight: 10 }}
    {...rest}
  >
    <path
      fill={themeColor.primaryFontColor}
      d="M3.99 3.8a.458.458 0 0 1 0 .65l-.592.592h4.204l-.593-.593a.458.458 0 1 1 .648-.648l1.375 1.375a.458.458 0 0 1 0 .648L7.657 7.2a.458.458 0 1 1-.648-.648l.593-.593H3.398l.593.593a.458.458 0 1 1-.648.648L1.968 5.824a.458.458 0 0 1 0-.648L3.343 3.8a.458.458 0 0 1 .648 0ZM2.293 0A2.292 2.292 0 0 0 0 2.292v6.416A2.292 2.292 0 0 0 2.292 11h6.416A2.292 2.292 0 0 0 11 8.708V2.292A2.292 2.292 0 0 0 8.708 0H2.292Zm7.713 1.833H.995A1.376 1.376 0 0 1 2.292.917h6.416c.599 0 1.108.382 1.297.916ZM.917 2.75h9.166v5.5H.917v-5.5Zm.078 6.417h9.01a1.376 1.376 0 0 1-1.297.916H2.292a1.376 1.376 0 0 1-1.297-.916Z"
    />
  </motion.svg>
);
export const SettingIcon = ({ themeColor, ...rest }: ButtonSVGProps) => (
  <motion.svg
    xmlns="http://www.w3.org/2000/svg"
    width={13}
    height={13}
    fill="none"
    style={{ marginRight: 8 }}
    {...rest}
  >
    <path
      stroke={themeColor.primaryFontColor}
      d="M5.889 1H7.11c.338 0 .611.274.611.611v.348c0 .261.176.488.417.589.242.1.523.062.708-.123l.246-.246a.611.611 0 0 1 .864 0l.864.864a.611.611 0 0 1 0 .864l-.246.246a.646.646 0 0 0-.122.708c.1.241.327.417.588.417h.348c.337 0 .611.273.611.61v1.223a.611.611 0 0 1-.611.611h-.348a.652.652 0 0 0-.588.417.646.646 0 0 0 .122.708l.246.246a.611.611 0 0 1 0 .864l-.864.864a.611.611 0 0 1-.864 0l-.246-.246a.646.646 0 0 0-.708-.122.652.652 0 0 0-.417.588v.348a.611.611 0 0 1-.61.611H5.888a.611.611 0 0 1-.611-.611v-.348a.652.652 0 0 0-.417-.588.646.646 0 0 0-.708.122l-.246.246a.611.611 0 0 1-.864 0l-.864-.864a.611.611 0 0 1 0-.864l.246-.246a.646.646 0 0 0 .123-.708.652.652 0 0 0-.59-.417h-.347A.611.611 0 0 1 1 7.112V5.888c0-.338.274-.611.611-.611h.348a.652.652 0 0 0 .589-.417.646.646 0 0 0-.123-.708l-.246-.246a.611.611 0 0 1 0-.864l.864-.864a.611.611 0 0 1 .864 0l.246.246a.646.646 0 0 0 .708.123.652.652 0 0 0 .417-.59v-.347c0-.337.273-.611.61-.611Z"
    />
    <path
      stroke={themeColor.primaryFontColor}
      d="M7.722 6.5a1.222 1.222 0 1 1-2.444 0 1.222 1.222 0 0 1 2.444 0Z"
    />
  </motion.svg>
);

export const XeroLinkIcon = ({ themeColor, ...rest }: ButtonSVGProps) => (
  <motion.svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    style={{ marginRight: 5 }}
    {...rest}
  >
    <path
      fill={themeColor.primaryFontColor}
      d="M4 2a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h1V9H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1v1a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H4Zm7 4v1h1a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1H8a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1V6a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4a2 2 0 0 0 2-2V8a2 2 0 0 0-2-2h-1Z"
    />
  </motion.svg>
);
export const SendIcon = ({
  themeColor,
  containerStyle,
  ...props
}: ISVGResponsiveCustom) => (
  <div
    style={{ width: 18, ...containerStyle, aspectRatio: 1, display: "flex" }}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 13 13"
      fill="none"
      {...props}
    >
      <g clipPath="url(#a)">
        <path
          fill={themeColor.primaryReverseFontColor}
          d="M6.883 1.97a.542.542 0 0 0-.766 0L3.053 5.034a.541.541 0 1 0 .766.766l2.14-2.14v7.174a.542.542 0 0 0 1.083 0V3.66l2.14 2.14a.542.542 0 0 0 .765-.766L6.883 1.97Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill={"transparent"} d="M0 0h13v13H0z" />
        </clipPath>
      </defs>
    </svg>
  </div>
);

export const CloseIconButton = ({ themeColor, ...rest }: ButtonSVGProps) => (
  <motion.svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    fill="none"
    {...rest}
  >
    <path
      stroke={themeColor.primaryFontColor}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={4}
      d="m8 8 32 32M8 40 40 8"
    />
  </motion.svg>
);
