//!------> QUERY KEYS <-------
//* ["drawing-takeoff", *projectId*] => retrieve drawing with the fixed key "drawing-takeoff", projectId custom
//!---------------------------

import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import DoxleAPI from "../../Services/DoxleAPI";
import { DrawingSet, Sheet } from "../Models/drawings";
import { Company } from "../../Models/company";
import {
  AxiosBackendErrorReturn,
  IApiPaginatedData,
} from "../../Models/axiosReturn";
import { BaseAPIProps } from "../../Models/baseAPIProps";
import useSetDrawingsQueryData from "./useSetDrawingsQueryData";
import { isAxiosError } from "axios";

interface DrawingsQueryParam {
  project: string | undefined;
  search?: string;
}
export interface IDrawingRetrieveQueryProps extends BaseAPIProps {
  projectId: string | undefined;
  search?: string;
}

const useRetrieveDrawingQuery = ({
  projectId,
  search,
  showNotification,
  company,
}: IDrawingRetrieveQueryProps) => {
  const qKey = getDrawingSetQKey(projectId || "", company, search);
  let drawingUrl = "/drawings/?page_size=3";
  let params: DrawingsQueryParam = { project: projectId };
  if (search) params.search = search;
  return useInfiniteQuery(
    qKey,
    async ({ pageParam = drawingUrl }) => {
      return DoxleAPI.get<IApiPaginatedData<DrawingSet>>(pageParam, {
        headers: {
          "User-Company": company?.companyId,
        },
        params,
      });
    },
    {
      getNextPageParam: (prev) => prev.data.next,
      onSuccess: (res) => {},

      onError: (error) => {
        if (showNotification) {
          if (isAxiosError<AxiosBackendErrorReturn>(error)) {
            showNotification(
              `${error?.response?.status ?? "ERROR"}: ${
                error.response?.data.detail ?? "UNKNOWN ERROR"
              }`,
              "error",
              String(
                error?.response?.data?.detail ?? "Failed to get drawing list"
              ).substring(0, 300)
            );
          } else {
            showNotification(
              "Something Wrong!",
              "error",
              "Failed to get drawing list"
            );
          }
        }
      },
      enabled: Boolean(company && projectId),
      staleTime: 5 * 60 * 1000,
      cacheTime: 6 * 60 * 1000,
      refetchInterval: 6 * 60 * 1000,
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: true,
    }
  );
};

export interface IDrawingAddQueryProps extends BaseAPIProps {
  projectId: string;
  onSuccessAddCb?: () => void;
}
interface AddDrawingParams {
  drawingName: string;
  file: File;
  lowRes?: "TRUE" | "FALSE";
}
interface UploadDrawingResponse {
  detail: string;
}
const useAddDrawingQuery = ({
  showNotification,
  company,
  projectId,
  onSuccessAddCb,
}: IDrawingAddQueryProps) => {
  const { handleAddDrawing } = useSetDrawingsQueryData({
    projectId,
    appendPos: "start",
  });
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationKey: ["Add-Drawings-Mutation"],
    mutationFn: async ({ drawingName, lowRes, file }: AddDrawingParams) => {
      const formData = new FormData();

      formData.append("project", projectId);
      formData.append("name", drawingName);
      if (lowRes) formData.append("lowRes", lowRes);
      formData.append("file", file);

      return DoxleAPI.post<UploadDrawingResponse>(
        "/drawings/upload/",
        formData,
        {
          headers: {
            "User-Company": company?.companyId || "",
          },
        }
      );
    },
    onMutate: () => {
      const qKey = getDrawingSetQKey(projectId, company);
      queryClient.cancelQueries(qKey);
    },
    onSuccess: (result, variables, context) => {
      if (onSuccessAddCb) onSuccessAddCb();
      // handleAddDrawing(result.data);

      // if (showNotification)
      //   showNotification(
      //     "Your Drawings Are Being Uploaded",
      //     "success",
      //     "You will receive an email when complete",
      //     3000

      //   );
    },
    onError: (error, variables, context) => {
      if (showNotification) {
        if (isAxiosError<AxiosBackendErrorReturn>(error)) {
          showNotification(
            `${error?.response?.status ?? "ERROR"}: ${
              error.response?.data.detail ?? "UNKNOWN ERROR"
            }`,
            "error",
            String(
              error?.response?.data?.detail ?? "Fail To Upload Drawing"
            ).substring(0, 300)
          );
        } else {
          showNotification(
            "Something Wrong!",
            "error",
            "Fail To Upload Drawing"
          );
        }
      }
    },
  });

  return mutation;
};

export interface IDrawingDeleteQueryProps extends BaseAPIProps {
  projectId: string;
  onSuccessDeleteCb?: (deletedIds?: string[]) => void;
}

const useDeleteDrawingQuery = ({
  company,
  showNotification,
  projectId,
  onSuccessDeleteCb,
}: IDrawingDeleteQueryProps) => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: async (drawingIds: string[]) => {
      return DoxleAPI.post(
        "/drawings/delete/",
        { drawings: drawingIds },
        {
          headers: {
            "User-Company": company?.companyId || "",
          },
        }
      );
    },
    onMutate: () => {
      const qKey = getDrawingSetQKey(projectId, company);
      queryClient.cancelQueries(qKey);
    },
    onSuccess: (result, variables, context) => {
      if (onSuccessDeleteCb) onSuccessDeleteCb(variables);

      // if (showNotification)
      //   showNotification(
      //     "Drawing Deleted",
      //     "success",
      //     "SUCCESSFULLY Deleted Drawing"
      //   );
    },
    onError: (error, variables, context) => {
      if (showNotification) {
        if (isAxiosError<AxiosBackendErrorReturn>(error)) {
          showNotification(
            `${error?.response?.status ?? "ERROR"}: ${
              error.response?.data.detail ?? "UNKNOWN ERROR"
            }`,
            "error",
            String(
              error?.response?.data?.detail ?? "Fail To delete Drawing"
            ).substring(0, 300)
          );
        } else {
          showNotification(
            "Something Wrong!",
            "error",
            "Fail To delete Drawing"
          );
        }
      }
    },
  });

  return mutation;
};

export interface IDrawingUpdateQueryProps extends BaseAPIProps {
  projectId: string;

  onSuccessUpdateCb?: (newDrawing?: DrawingSet) => void;
}
interface UpdateDrawingSetParams {
  drawingId: string;
  name?: string;
  index?: number;
}

const useUpdateDrawingQuery = ({
  showNotification,
  company,
  projectId,
  onSuccessUpdateCb,
}: IDrawingUpdateQueryProps) => {
  const { handleUpdateDrawing } = useSetDrawingsQueryData({ projectId });
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async (updateBody: UpdateDrawingSetParams) => {
      const { drawingId, ...rest } = updateBody;
      return DoxleAPI.patch<DrawingSet>("/drawings/" + drawingId + "/", rest, {
        headers: {
          "User-Company": company?.companyId ?? "",
        },
      });
    },
    onMutate: () => {
      const qKey = getDrawingSetQKey(projectId, company);
      queryClient.cancelQueries(qKey);
    },
    onSuccess: (result, variables, context) => {
      if (onSuccessUpdateCb) onSuccessUpdateCb(result.data);
      handleUpdateDrawing(result.data);
      // if (showNotification) showNotification("Drawing Updated", "success");
    },
    onError: (error, variables, context) => {
      if (showNotification) {
        if (isAxiosError<AxiosBackendErrorReturn>(error)) {
          showNotification(
            `${error?.response?.status ?? "ERROR"}: ${
              error.response?.data.detail ?? "UNKNOWN ERROR"
            }`,
            "error",
            String(
              error?.response?.data?.detail ?? "Fail To Update Drawing"
            ).substring(0, 300)
          );
        } else {
          showNotification(
            "Something Wrong!",
            "error",
            "Fail To Update Drawing"
          );
        }
      }
    },
  });

  return mutation;
};
interface UpdateIndexDrawingSetParams {
  drawingItem: DrawingSet;
  startIndex: number;
  endIndex: number;
}
const useUpdateIndexDrawingQuery = ({
  showNotification,
  company,
  projectId,
  onSuccessUpdateCb,
}: IDrawingUpdateQueryProps) => {
  const { handleUpdateDrawingIndex } = useSetDrawingsQueryData({ projectId });
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async (updateBody: UpdateIndexDrawingSetParams) => {
      const { drawingItem, ...rest } = updateBody;
      return DoxleAPI.patch<DrawingSet>(
        `/drawings/${drawingItem.setId}/index/`,
        {
          index: rest.endIndex,
        },
        { headers: { "User-Company": company?.companyId } }
      );
    },
    onMutate: (variables) => {
      const qKey = getDrawingSetQKey(projectId, company);
      queryClient.cancelQueries(qKey);
      const prevQueryDate = queryClient.getQueryData(qKey);
      return prevQueryDate;
    },
    onSuccess: (result, variables, context) => {
      if (onSuccessUpdateCb) onSuccessUpdateCb(result.data);
      console.log(variables);
      // handleUpdateDrawingIndex({
      //   item: { ...variables.drawingItem, index: variables.endIndex },
      //   initIdx: variables.startIndex,
      //   destIdx: variables.endIndex,
      // });
      // if (showNotification) showNotification("Drawing Updated", "success");
    },
    onError: (error, variables, context) => {
      if (showNotification) {
        if (isAxiosError<AxiosBackendErrorReturn>(error)) {
          showNotification(
            `${error?.response?.status ?? "ERROR"}: ${
              error.response?.data.detail ?? "UNKNOWN ERROR"
            }`,
            "error",
            String(
              error?.response?.data?.detail ?? "Fail To Update Drawing"
            ).substring(0, 300)
          );
        } else {
          showNotification(
            "Something Wrong!",
            "error",
            "Fail To Update Drawing"
          );
        }
      }
    },
    // onSettled: () => {
    //   queryClient.refetchQueries({
    //     queryKey: getDrawingSetQKey(projectId, company),
    //   });
    // },
  });

  return mutation;
};
interface SheetsProps {
  nameNPages: Array<{ name: string; page: number }>;
  file: File;
  lowRes?: "TRUE" | "FALSE";
}
export interface ISheetAddQueryProps extends BaseAPIProps {
  projectId: string;
  drawing: string;
  onSuccessCb?: Function;
}

const useAddSheetQuery = ({
  company,
  showNotification,
  projectId,
  drawing,
  onSuccessCb,
}: ISheetAddQueryProps) => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationKey: ["Add-Drawings-Mutation"],
    mutationFn: async ({ nameNPages, lowRes, file }: SheetsProps) => {
      const formData = new FormData();

      formData.append("project", projectId);
      formData.append("file", file);
      formData.append("drawing", drawing);

      const stringifiedNameNPages = JSON.stringify(nameNPages);
      formData.append("sheets", stringifiedNameNPages);

      if (lowRes) formData.append("lowRes", lowRes);

      return DoxleAPI.post("/sheet/", formData);
    },
    onSuccess: (result, variables, context) => {
      // console.log("RESULT:", result);
      // console.log("variables", variables);
      // console.log("CONTEXT", context);

      // queryClient.setQueryData(
      //   ["drawing-takeoff", variables.projectId],
      //   (oldData: any) =>
      //     oldData
      //       ? {
      //           ...oldData,
      //           data: {
      //             ...oldData.data,
      //             results: [
      //               ...oldData.data.results.map((drawing: DrawingSet) => {
      //                 if (drawing.setId === variables.drawing) {
      //                   return {
      //                     ...drawing,
      //                     sheets: [...drawing.sheets, ...result.data.sheets],
      //                   };
      //                 } else return drawing;
      //               }),
      //             ],
      //           },
      //         }
      //       : oldData
      // );

      // if (showNotification)
      //   showNotification(
      //     "Sheet Uploaded",
      //     "success",
      //     "SUCCESSFULLY Uploaded Sheet"
      //   );

      if (onSuccessCb) onSuccessCb();
    },
    onError: (error, variables, context) => {
      if (showNotification) {
        if (isAxiosError<AxiosBackendErrorReturn>(error)) {
          showNotification(
            `${error?.response?.status ?? "ERROR"}: ${
              error.response?.data.detail ?? "UNKNOWN ERROR"
            }`,
            "error",
            String(
              error?.response?.data?.detail ?? "Fail To Upload Sheet"
            ).substring(0, 300)
          );
        } else {
          showNotification("Something Wrong!", "error", "Fail To Upload Sheet");
        }
      }
    },
  });

  return mutation;
};

export interface ISheetUpdateQueryProps extends BaseAPIProps {
  projectId: string;
  onSuccessUpdateCb?: (newSheet?: Sheet) => void;
}
interface UpdateSheetParams {
  scaleAll?: boolean;
  scale?: number;
  title?: string;
  drawingSetId?: string;
  sheetId: string;
}
const useUpdateSheetQuery = ({
  showNotification,
  company,
  projectId,
  onSuccessUpdateCb,
}: ISheetUpdateQueryProps) => {
  const { handleUpdateSheet } = useSetDrawingsQueryData({ projectId });
  const mutation = useMutation({
    mutationFn: async ({
      sheetId,

      scaleAll,
      scale,
      title,
      drawingSetId,
    }: UpdateSheetParams) => {
      let body: any = {};
      if (title) {
        body.title = title;
      }
      if (scale) {
        const tScale = scale.toPrecision(13);
        body.scale = tScale;
      }
      // if (scaleAll && drawingSetId) {
      //   return DoxleAPI.post(
      //     "/drawings/scale/" + drawingSetId + "/",
      //     { scale: body.scale },
      //     {
      //       headers: { "User-Company": company?.companyId ?? ""},
      //     }
      //   );
      // } else
      return DoxleAPI.patch<Sheet>("/sheet/" + sheetId + "/", body, {
        headers: {
          "User-Company": company?.companyId ?? "",
        },
      });
    },
    onSuccess: (result, variables, context) => {
      if (onSuccessUpdateCb) onSuccessUpdateCb(result.data);

      handleUpdateSheet(result.data);
      // if (showNotification) showNotification("Sheet Updated", "success");
    },
    onError: (error, variables, context) => {
      if (showNotification) {
        if (isAxiosError<AxiosBackendErrorReturn>(error)) {
          showNotification(
            `${error?.response?.status ?? "ERROR"}: ${
              error.response?.data.detail ?? "UNKNOWN ERROR"
            }`,
            "error",
            String(
              error?.response?.data?.detail ?? "Fail To Update Sheet"
            ).substring(0, 300)
          );
        } else {
          showNotification("Something Wrong!", "error", "Fail To Update Sheet");
        }
      }
    },
  });

  return mutation;
};

export interface ISheetDeleteQueryProps extends BaseAPIProps {
  projectId: string;
  onSuccessDeleteCb?: (data?: {
    deletedSheetIds: string[];
    drawingSetId: string;
  }) => void;
}

const useDeleteMultiSheetQuery = ({
  showNotification,
  company,
  projectId,
  onSuccessDeleteCb,
}: ISheetDeleteQueryProps) => {
  // const { handleDeleteMultipleSheet } = useSetDrawingsQueryData({ projectId });
  const mutation = useMutation({
    mutationFn: async (data: {
      deletedSheetIds: string[];
      drawingSetId: string;
    }) => {
      const { deletedSheetIds } = data;
      return DoxleAPI.post("/drawings/delete/", { sheets: deletedSheetIds });
    },
    onSuccess: (result, variables, context) => {
      if (onSuccessDeleteCb) onSuccessDeleteCb(variables);
      // handleDeleteMultipleSheet(variables);
      // queryClient.setQueryData(
      //   ["drawing-takeoff", variables.projectId],
      //   (oldData: any) =>
      //     oldData
      //       ? {
      //           ...oldData,
      //           data: {
      //             ...oldData.data,
      //             results: [
      //               ...oldData.data.results.filter(
      //                 (drawing: DrawingSet) =>
      //                   drawing.setId !== variables.drawingSetId
      //               ),
      //             ],
      //           },
      //         }
      //       : oldData
      // );
      // console.log("RESULT:", result);
      // console.log("variables", variables);
      // console.log("CONTEXT", context);

      // if (showNotification)
      //   showNotification(
      //     "Sheet Deleted",
      //     "success",
      //     "SUCCESSFULLY Deleted Drawing"
      //   );
    },
    onError: (error, variables, context) => {
      if (showNotification) {
        if (isAxiosError<AxiosBackendErrorReturn>(error)) {
          showNotification(
            `${error?.response?.status ?? "ERROR"}: ${
              error.response?.data.detail ?? "UNKNOWN ERROR"
            }`,
            "error",
            String(
              error?.response?.data?.detail ?? "Fail To delete sheet"
            ).substring(0, 300)
          );
        } else {
          showNotification("Something Wrong!", "error", "Fail To delete sheet");
        }
      }
    },
  });

  return mutation;
};

//* helper function
export const getDrawingSetQKey = (
  projectId: string,
  company: Company | undefined,
  search: string = ""
) => {
  let baseQKey = ["drawing-takeoff"];
  if (company) baseQKey.push(company.companyId);
  baseQKey.push(projectId);
  if (search) baseQKey.push(search);
  return baseQKey;
};

const DrawingQueryAPI = {
  useRetrieveDrawingQuery,
  useAddDrawingQuery,
  useUpdateDrawingQuery,
  useDeleteDrawingQuery,
  useAddSheetQuery,
  useUpdateSheetQuery,
  useDeleteMultiSheetQuery,
  useUpdateIndexDrawingQuery,
};
export default DrawingQueryAPI;
