import React, { useState } from "react";
import { DoxleFile } from "../../../../Models/files";

import ExcelIcon from "../../../../Assets/Images/excel.png";
import WordIcon from "../../../../Assets/Images/word.png";
import PDFIcon from "../../../../Assets/Images/pdf.png";
import { useDoxleThemeStore } from "../../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import { CircularProgress } from "@mui/material";
import { LuImageOff } from "react-icons/lu";
import {
  StyledCommentAttachmentImage,
  StyledDiscussionAttachmentWrapper,
} from "./StyledComponentBookingDocketDetail";

type Props = {
  item: DoxleFile;
  itemIndex: number;
  handleClickImage?: (imgIndex: number) => void;
};

const DiscussionAttachmentItem = ({
  item,
  itemIndex,
  handleClickImage,
}: Props) => {
  const [isLoadingImg, setIsLoadingImg] = useState(false);
  const [isErrorImg, setIsErrorImg] = useState(false);
  const { doxleThemeColor } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }),
    shallow
  );
  const isItemImage = Boolean(item.fileType.toLowerCase().includes("image"));

  const iconSource = item.fileType.toLowerCase().includes("pdf")
    ? PDFIcon
    : item.fileType.toLowerCase().includes("doc") ||
      item.fileType.toLowerCase().includes("docx")
    ? WordIcon
    : ExcelIcon;
  const imgSource = item.thumbUrl || item.url;
  return (
    <StyledDiscussionAttachmentWrapper
      style={
        !isItemImage
          ? {
              border: `1px solid ${doxleThemeColor.rowBorderColor}`,
              display: "flex",
              backgroundColor: doxleThemeColor.primaryContainerColor,
              justifyContent: "center",
              alignItems: "center",
            }
          : undefined
      }
    >
      {isItemImage && (
        <>
          <StyledCommentAttachmentImage
            src={imgSource}
            onLoadStart={() => setIsLoadingImg(true)}
            onLoadedData={() => setIsLoadingImg(false)}
            onError={() => {
              setIsErrorImg(true);
              setIsLoadingImg(false);
            }}
            onClick={() => {
              if (handleClickImage) handleClickImage(itemIndex);
            }}
          />

          {isLoadingImg && (
            <CircularProgress
              size="1.6rem"
              style={{
                position: "absolute",
                zIndex: 10,
                color: doxleThemeColor.primaryFontColor,
                top: "calc(50% - 0.8rem)",
                left: "calc(50% - 0.8rem)",
              }}
            />
          )}

          {isErrorImg && (
            <LuImageOff
              size={"4rem"}
              color={doxleThemeColor.primaryFontColor}
              style={{
                position: "absolute",
                zIndex: 11,
                color: doxleThemeColor.primaryFontColor,
                top: "calc(50% - 2rem)",
                left: "calc(50% - 2rem)",
              }}
            />
          )}
        </>
      )}

      {!isItemImage && (
        <StyledCommentAttachmentImage
          src={iconSource}
          style={{
            width: "60%",
            height: "fit-content",
          }}
          onClick={() => {
            window.open(item.url);
          }}
        />
      )}
    </StyledDiscussionAttachmentWrapper>
  );
};

export default React.memo(DiscussionAttachmentItem);
