import React, { useMemo } from "react";
import { NoteLine } from "../Models/note";
import { useDoxleCurrentContextStore } from "../../DoxleGeneralStore/useDoxleCurrentContext";
import { shallow } from "zustand/shallow";
import useDoxleNotificationStore from "../../DoxleGeneralStore/useDoxleNotificationStore";
import { NoteQueryAPI } from "../QueryAPI/notesQueryAPI";

type Props = {
  noteId: string;
};

interface GetNoteLineList {
  noteLineList: NoteLine[];
  isFetchingNoteLineList: boolean;
  isSuccessFetchingNoteLineList: boolean;
  isErrorFetchingNoteLineList: boolean;
  isRefetchingNoteLineList: boolean;
  isFetchingMoreNoteLineList: boolean;
  refetchNoteLine: () => void;
  fetchMoreNoteLine: () => void;
}
const useGetNoteLineList = ({ noteId }: Props): GetNoteLineList => {
  const { company } = useDoxleCurrentContextStore(
    (state) => ({ company: state.currentCompany }),
    shallow
  );
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification
  );
  const getNoteLineQuery = NoteQueryAPI.useRetrieveNoteLineListQuery({
    company,
    noteId,
  });

  const noteLineList = useMemo(
    () =>
      getNoteLineQuery.isSuccess
        ? getNoteLineQuery.data.pages.reduce((acc, data) => {
            return acc.concat(data.data.results);
          }, [] as NoteLine[])
        : [],
    [getNoteLineQuery.data]
  );

  const refetchNoteLine = () => {
    getNoteLineQuery.refetch();
  };
  const fetchMoreNoteLine = () => {
    if (getNoteLineQuery.hasNextPage) getNoteLineQuery.fetchNextPage();
  };
  return {
    noteLineList,
    isFetchingNoteLineList: getNoteLineQuery.isLoading,
    isSuccessFetchingNoteLineList: getNoteLineQuery.isSuccess,
    isErrorFetchingNoteLineList: getNoteLineQuery.isError,
    isRefetchingNoteLineList: getNoteLineQuery.isRefetching,
    isFetchingMoreNoteLineList: getNoteLineQuery.isFetchingNextPage,
    refetchNoteLine,
    fetchMoreNoteLine,
  };
};

export default useGetNoteLineList;
