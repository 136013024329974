import styled from "styled-components";

export const StyledSideMenuContainer = styled.div`
  width: 250px;
  height: calc(100%);
  background-color: ${(p) => p.theme.color.primaryBackgroundColor};
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 10px;
`;
