import React, { useCallback, useMemo } from "react";
import { StyledQRUserLog } from "./StyledQRUserLog";
import useQRLogStore from "../../Store/useQRLogStore";
import { useShallow } from "zustand/react/shallow";
import useGetQRUserLogList from "../../Hooks/useGetQRUserLogList";
import Loading from "../../../Utilities/Lottie/Loading";
import {
  Components,
  GroupContent,
  GroupItemContent,
  GroupedVirtuoso,
} from "react-virtuoso";
import { IQRUserLog } from "../../Models/qrLog";
import { IQRUserLogListContext } from "../../Models/QRListTypes";
import DoxleEmptyPlaceHolder from "../../../DoxleDesignPattern/DoxleEmptyPlaceHolder/DoxleEmptyPlaceHolder";
import { Error404Banner } from "../../../DoxleDesignPattern/DoxleIcons/CommonIcons";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { EmptyQRLogBanner } from "../QRIcons";
import QRLogItem from "./QRLogItem";
import QRLogGroupHeader from "./QRLogGroupHeader";
type Props = {};

const QRUserLog = (props: Props) => {
  const { doxleThemeColor } = useDoxleThemeStore();
  const { filterQRUserLog } = useQRLogStore(
    useShallow((state) => ({
      filterQRUserLog: state.filterQRUserLog,
    }))
  );
  const {
    isFetchingQRUserLogList,
    isSuccessFetchingQRUserLogList,
    isErrorFetchingQRUserLogList,
    refetchQRUserLogList,
    isRefetchingQRUserLogList,
    fetchNextPageQRUserLog,
    isFetchingNextPageQRUserLog,
    logDateGroup,
  } = useGetQRUserLogList({ filter: filterQRUserLog });
  //*render group list
  const groupListComponents: Components<IQRUserLog, IQRUserLogListContext> =
    useMemo(
      () => ({
        EmptyPlaceholder: ({ context, ...rest }) => (
          <DoxleEmptyPlaceHolder
            headTitleText={
              context?.isErrorFetchingQRUserLogList
                ? "Something Wrong"
                : "No Logs Found"
            }
            subTitleText={
              context?.isErrorFetchingQRUserLogList
                ? "Failed to get data, please try to reload the page"
                : ""
            }
            containerStyle={{
              width: "100%",
              flexGrow: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
            containerBgColor="transparent"
            illustration={
              context?.isErrorFetchingQRUserLogList ? (
                <Error404Banner themeColor={doxleThemeColor} />
              ) : (
                <EmptyQRLogBanner themeColor={doxleThemeColor} />
              )
            }
          />
        ),
      }),
      []
    );
  const itemContent: GroupItemContent<IQRUserLog, IQRUserLogListContext> =
    useCallback(
      (index, groupIdx, data, context) => {
        const item = logDateGroup.datalogList[index];
        if (item) return <QRLogItem key={item.userLogId} logItem={item} />;
      },
      [logDateGroup.datalogList]
    );

  const groupBudgetItemContent: GroupContent<IQRUserLogListContext> =
    useCallback(
      (groupIdx, context) => {
        const item = logDateGroup.dateList[groupIdx];
        return <QRLogGroupHeader date={item} key={item.toLocaleTimeString()} />;
      },
      [logDateGroup.dateList]
    );
  const tableStyle: React.CSSProperties = {
    width: "100%",
    height: "100%",
  };
  return (
    <StyledQRUserLog>
      {isFetchingQRUserLogList && (
        <Loading
          containerStyle={{
            width: "100%",
            height: "calc(100% - 200px)",
            justifyContent: "center",
            alignItems: "center",
            paddingBottom: "200px",
          }}
        />
      )}

      {!isFetchingQRUserLogList && (
        <GroupedVirtuoso
          style={tableStyle}
          context={{
            isErrorFetchingQRUserLogList,
          }}
          groupCounts={logDateGroup.itemCount}
          components={groupListComponents}
          itemContent={itemContent}
          groupContent={groupBudgetItemContent}
          endReached={() => {
            fetchNextPageQRUserLog();
          }}
          increaseViewportBy={300}
        />
      )}
    </StyledQRUserLog>
  );
};

export default QRUserLog;
