import React, { useEffect, useState } from "react";
import { useAddQuoteResponseContext } from "../AddQuoteResponse";
import { produce } from "immer";
import { TPOPaymentTermsSuffix } from "../../../models/quote";

type Props = {};
interface AddPaymentTermResponse {
  newPaymentTerm: string;
  setNewPaymentTerm: React.Dispatch<React.SetStateAction<string>>;
  anchorPopoverTermSuffix: HTMLDivElement | null;
  handleClickInputAdornment: (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => void;
  handleClosePopoverSuffix: () => void;
  handleSelectSuffix: (item: TPOPaymentTermsSuffix) => void;
}
const useAddPaymentTermResponse = (props: Props): AddPaymentTermResponse => {
  const [newPaymentTerm, setNewPaymentTerm] = useState("14");
  const [anchorPopoverTermSuffix, setAnchorPopoverTermSuffix] =
    useState<HTMLDivElement | null>(null);
  const { setNewQuoteResponse } = useAddQuoteResponseContext();
  useEffect(() => {
    const timeout = setTimeout(() =>
      setNewQuoteResponse((prev) =>
        produce(prev, (draft) => {
          draft.paymentTerms = newPaymentTerm;
          return draft;
        })
      )
    );

    return () => clearTimeout(timeout);
  }, [newPaymentTerm]);

  const handleClickInputAdornment = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    setAnchorPopoverTermSuffix(e.currentTarget);
  };
  const handleClosePopoverSuffix = () => setAnchorPopoverTermSuffix(null);

  const handleSelectSuffix = (item: TPOPaymentTermsSuffix) => {
    handleClosePopoverSuffix();
    setNewQuoteResponse((prev) =>
      produce(prev, (draft) => {
        draft.paymentTermsSuffix = item;
        return draft;
      })
    );
  };
  return {
    newPaymentTerm,
    setNewPaymentTerm,
    anchorPopoverTermSuffix,
    handleClickInputAdornment,
    handleClosePopoverSuffix,
    handleSelectSuffix,
  };
};

export default useAddPaymentTermResponse;
