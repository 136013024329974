import React, { useEffect, useMemo, useState } from "react";
import { ContactsFilters } from "../../../../../Services/QueryHooks/contactsFilters";
import { useDoxleAuthStore } from "../../../../../DoxleGeneralStore/useDoxleAuthStore";
import { shallow } from "zustand/shallow";
import { useDoxleCurrentContextStore } from "../../../../../DoxleGeneralStore/useDoxleCurrentContext";
import ContactsAPI from "../../../../../Services/QueryHooks/contactsAPI";
import { Contact } from "../../../../../Models/addressBook";

type Props = { anchorDropdown: HTMLElement | null };

interface SelectAssigneeDropdown {
  searchInputQuery: string;
  setSearchInputQuery: React.Dispatch<React.SetStateAction<string>>;
  contactList: Contact[];
  isFetchingContactList: boolean;
  handleFetchNextPageContact: () => void;
  isFetchingNextPageContact: boolean;
  showContactList: boolean;
  setShowContactList: React.Dispatch<React.SetStateAction<boolean>>;
}
const useSelectAssigneeDropdown = ({
  anchorDropdown,
}: Props): SelectAssigneeDropdown => {
  const [showContactList, setShowContactList] = useState(false);
  const [filterContact, setFilterContact] = useState<ContactsFilters>({});
  const [searchInputQuery, setSearchInputQuery] = useState<string>("");

  const company = useDoxleCurrentContextStore(
    (state) => state.currentCompany,
    shallow
  );

  const getContactQuery = ContactsAPI.useRetrieveContactsQuery({
    company,
    filter: filterContact,
    enable: true,
  });
  const contactList: Contact[] = useMemo(
    () =>
      getContactQuery.isSuccess
        ? getContactQuery.data.pages.reduce((acc, data) => {
            return acc.concat(data.data.results);
          }, [] as Contact[])
        : [],
    [getContactQuery.data]
  );

  useEffect(() => {
    const timeout = setTimeout(() => {
      setFilterContact((prev) => ({ ...prev, search: searchInputQuery }));
    }, 300);

    return () => {
      clearTimeout(timeout);
    };
  }, [searchInputQuery]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (!getContactQuery.isLoading) {
        if (anchorDropdown) setShowContactList(true);
        else {
          setShowContactList(false);
          setSearchInputQuery("");
        }
      } else {
        setShowContactList(false);
      }
    }, 400);

    return () => {
      clearTimeout(timeout);
    };
  }, [anchorDropdown, getContactQuery.isLoading]);
  const handleFetchNextPageContact = () => {
    if (getContactQuery.hasNextPage) getContactQuery.fetchNextPage();
  };
  return {
    searchInputQuery,
    setSearchInputQuery,
    contactList,
    isFetchingContactList: getContactQuery.isLoading,
    showContactList,
    setShowContactList,
    handleFetchNextPageContact,
    isFetchingNextPageContact: getContactQuery.isFetchingNextPage,
  };
};

export default useSelectAssigneeDropdown;
