import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import {
  StyledMailRootContainer,
  StyledMailListInfoLabel,
} from "../styledCompnents";
import {
  StyledDoxleMailScreenContainer,
  StyledMailConversationFrom,
  StyledMailConversationInputControl,
  StyledBadgeList,
  StyledBadgeListContainer,
  StyledTabList,
  StyledTabListItem,
  StyledMailConversationInputLabel,
  StyledMailConversationInput,
  StyledMailConversationHead,
  StyleWriteMail,
  StyledAttachmentsArea,
} from "./styledCompnents";
import { shallow } from "zustand/shallow";
import React, { useCallback, useMemo, useRef, useState } from "react";
import MailCommentList from "./commentsList";
import MailConversationAPI from "../../Hooks/mailConversationAPI";
import { useNavigate, useParams } from "react-router-dom";
import { Mail, MailComments, ReplyMail } from "../../Models/mail";
import MailStatusHeading from "../mailStatusHeading";
import MailReplyList from "./replyList";
import { Virtuoso } from "react-virtuoso";
import DoxleContactAutocomplete from "../../../DoxleDesignPattern/DoxleAutoComplete/DoxleContactAutocomplete";
import { StyledListScroller } from "../../../DoxleDesignPattern/ProjectSelectWithDropdown/StyledComponentsProjectSelectWithDropdown";
import { useMailStore } from "../../Store/mailStore";
import DoxleIconButton from "../../../DoxleDesignPattern/DoxleButtons/DoxleIconButton";
import { useDropzone } from "react-dropzone";
import NewMailAttachment from "./NewMailAttachment";
import MailAPI from "../../Hooks/mailAPI";
import DoxleListSkeleton from "../../../DoxleDesignPattern/DoxleSkeleton/DoxleListSkeleton";
import { useIsMutating } from "@tanstack/react-query";
import Loading from "../../../Utilities/Lottie/Loading";
import AddMailWatchers from "./addWatchers";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import { DoxleAttachmentIcon } from "../../../DoxleDesignPattern/DoxleGeneralIcons";
import { useShallow } from "zustand/react/shallow";

interface Props {
  mail?: Mail | undefined;
  project?: boolean;
}
const MailConversation = ({ mail, project }: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { mailId } = useParams();
  // const [mailId, setMailId] = useState<string|undefined>(undefined)
  const [tab, setTab] = useState<number>(1);
  const [attachments, setAttachments] = useState<File[]>([]);
  const { mailTheme, setViewMail } = useMailStore(
    useShallow((state) => ({
      mailTheme: state.mailTheme,
      setViewMail: state.setViewMail,
    }))
  );
  const styleProps = useDoxleThemeStore((state) => ({
    $doxleFont: state.doxleFont,
    $themeColor: { ...state.doxleThemeColor, ...mailTheme },
    $currentTheme: state.currentTheme,
  }));
  const mailQuery = MailConversationAPI.useRetrieveMailConversation({
    mailId: mailId ?? mail?.mailId,
  });
  const mailConversation = useMemo(
    () => (mailQuery.isSuccess ? (mailQuery.data?.data as Mail) : undefined),
    [mailQuery.isSuccess, mailQuery.data]
  );
  const bodyInputRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();
  const replyMailQuery = MailConversationAPI.useRetrieveReplyMail({
    mailId: mailConversation?.mailId,
    enabled: mailQuery.isSuccess,
  });
  const mailReplies: ReplyMail[] = useMemo(
    () =>
      replyMailQuery.data?.pages.flatMap(
        (page: any) => (page?.data?.results as ReplyMail[]) ?? []
      ) ?? [],
    [replyMailQuery.data]
  );
  const mailCommentsQuery = MailConversationAPI.useRetrieveMailComments({
    mailId: mailConversation?.mailId,
    enabled: Boolean(replyMailQuery.isSuccess || tab === 2),
  });
  const mailComments: MailComments[] = useMemo(
    () =>
      mailCommentsQuery.data?.pages.flatMap(
        (page: any) => (page?.data?.results as MailComments[]) ?? []
      ) ?? [],
    [mailCommentsQuery.data]
  );

  const onDrop = useCallback(
    <T extends File>(acceptedFiles: T[]) => {
      setAttachments((prevState) => [...prevState, ...acceptedFiles]);
    },
    [setAttachments]
  );
  const { getRootProps } = useDropzone({
    onDrop,
    accept: {
      "image/*": [".png", ".jpg", ".jpeg"],
      "application/pdf": [".pdf"],
      "application/msword": [".doc"],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        [".docx"],
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
        ".xlsx",
      ],
    },
  });
  const handelSuccess = () => {
    if (bodyInputRef?.current) bodyInputRef.current.value = "";
    setAttachments([]);
  };
  const updateMail = MailAPI.useUpdateMail(mailConversation?.mailId ?? "");
  const addReply = MailConversationAPI.useSendReplyMail({
    mailId: mailConversation?.mailId,
    onSuccessCb: handelSuccess,
  });
  const addComment = MailConversationAPI.useSendMailComment({
    mailId: mailConversation?.mailId,
    onSuccessCb: handelSuccess,
  });

  const handleSetBallInCourt = (contactId: string) => {
    if (mailConversation?.mailId && mailConversation.ballInCourt !== contactId)
      updateMail.mutate({ ballInCourt: contactId } as Partial<Mail>);
  };

  const handleRemoveWatcher = (i: number) => {
    let body: Partial<Mail> = {};
    let watchers: string[] = mailConversation?.watchers ?? mail?.watchers ?? [];
    watchers?.splice(i, 1);
    body.watchers = watchers;
    if (mailConversation?.mailId) updateMail.mutate(body);
  };

  const isAddingReply = useIsMutating({
    mutationKey: ["new-reply", mailConversation?.mailId ?? mail?.mailId],
  });
  const isAddingComment = useIsMutating({
    mutationKey: ["new-comment", mailConversation?.mailId ?? mail?.mailId],
  });
  const handleClose = () => {
    if (mailId) navigate("/Mail/");
    else setViewMail(undefined);
  };
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    // Don't handle if key is enter or modifier key pressed
    if (e.key !== "Enter") return;
    if (e.ctrlKey || e.altKey || e.shiftKey || e.metaKey) return;
    // If standard Enter prevent adding a new line
    e.preventDefault();
    e.stopPropagation();
    const textValue: string | undefined = (e.target as any)?.value;
    if (!textValue) {
      // Prevents sending empty string or errors if value not found
      console.log("CANNOT FIND TEXT AREA VALUE:", textValue);
      return;
    }
    if (tab === 1) addReply.mutate({ replyText: textValue, attachments });
    else if (tab === 2)
      addComment.mutate({ replyText: textValue, attachments });
  };
  const handleRemoveFile = (index: number) => {
    setAttachments((prevState) => {
      prevState.splice(index, 1);
      return [...prevState];
    });
  };
  const handleTab = (e: React.MouseEvent<HTMLButtonElement>) => {
    setTab(Number(e.currentTarget.value));
  };
  const handleFetchNextComments = (index: number) => {
    if (mailCommentsQuery.hasNextPage) mailCommentsQuery.fetchNextPage();
  };

  const handleFetchNextReplies = (index: number) => {
    if (replyMailQuery.hasNextPage) replyMailQuery.fetchNextPage();
  };

  const itemContent = useCallback(
    (index: number, item: ReplyMail) => (
      <MailReplyList conversation={item} key={item.replyId} />
    ),
    []
  );
  const itemContentComments = useCallback(
    (index: number, item: MailComments) => (
      <MailCommentList conversation={item} key={item.commentId} />
    ),
    []
  );
  window.ondrop = function (e) {
    e.preventDefault();
    return false;
  };
  return (
    <StyledMailRootContainer {...styleProps}>
      {/*{displayAddWatchersDialog && }*/}
      <StyledDoxleMailScreenContainer
        $themeColor={styleProps.$themeColor}
        $project={project}
      >
        <StyledMailConversationHead>
          <MailStatusHeading
            fromString={mailConversation?.fromString}
            statuscolor={mailConversation?.statusColor}
          />
          {!mailId && (
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          )}
        </StyledMailConversationHead>
        <StyledMailListInfoLabel $themeColor={styleProps.$themeColor}>
          <StyledMailListInfoLabel $themeColor={styleProps.$themeColor}>
            to <span>{mailConversation?.toStringName}</span>
          </StyledMailListInfoLabel>
        </StyledMailListInfoLabel>
        <StyledMailConversationFrom>
          <StyledMailConversationInputControl width={35}>
            <StyledMailConversationInputLabel
              $themeColor={styleProps.$themeColor}
            >
              Ball in court
            </StyledMailConversationInputLabel>
            <StyledMailConversationInput $themeColor={styleProps.$themeColor}>
              {mailConversation?.ballInCourtJson ?? mail?.ballInCourtJson ? (
                <DoxleContactAutocomplete
                  key={"mail-ball-in-court-autocomplete-with-value"}
                  currentValue={
                    mailConversation?.ballInCourtJson ?? mail?.ballInCourtJson
                  }
                  setNewContact={(contact) =>
                    handleSetBallInCourt(contact.contactId)
                  }
                  autocompleteWrapperStyle={{
                    "& .MuiInputBase-root": {
                      backgroundColor:
                        styleProps?.$themeColor?.bolderBorderColor,
                      borderRadius: "4px",
                    },
                    "& .MuiInputBase-root:before": {
                      borderBottom: "none",
                    },
                  }}
                  textFieldInputRef={inputRef}
                />
              ) : (
                <DoxleContactAutocomplete
                  key={"mail-ball-in-court-autocomplete-without-value"}
                  currentValue={
                    mailConversation?.ballInCourtJson ?? mail?.ballInCourtJson
                  }
                  setNewContact={(contact) =>
                    handleSetBallInCourt(contact.contactId)
                  }
                  autocompleteWrapperStyle={{
                    "& .MuiInputBase-root": {
                      backgroundColor:
                        styleProps?.$themeColor?.bolderBorderColor,
                      borderRadius: "4px",
                    },
                    "& .MuiInputBase-root:before": {
                      borderBottom: "none",
                    },
                  }}
                  textFieldInputRef={inputRef}
                />
              )}
            </StyledMailConversationInput>
            {/* <StyledMailConversationInput type='text' $themeColor={styleProps.$themeColor} /> */}
          </StyledMailConversationInputControl>
          <StyledMailConversationInputControl width={65}>
            <StyledMailConversationInputLabel
              $themeColor={styleProps.$themeColor}
            >
              Watchers
            </StyledMailConversationInputLabel>
            <StyledBadgeListContainer>
              {(mailConversation?.watchersJson ?? mail?.watchersJson ?? []).map(
                (watcher, i) => (
                  <StyledBadgeList
                    key={"watcher-badge-" + watcher.contactId}
                    $themeColor={styleProps.$themeColor}
                    onClick={() => handleRemoveWatcher(i)}
                  >
                    {watcher?.firstName + " " + watcher?.lastName}
                    <img
                      key={"watcher-img-" + watcher.contactId}
                      src="/assets/icons/close_button_fill.svg"
                    />
                  </StyledBadgeList>
                )
              )}
              {mailConversation && <AddMailWatchers mail={mailConversation} />}
            </StyledBadgeListContainer>
          </StyledMailConversationInputControl>
        </StyledMailConversationFrom>
        <StyledTabList $themeColor={styleProps.$themeColor}>
          <StyledTabListItem $themeColor={styleProps.$themeColor}>
            <button
              onClick={handleTab}
              value="1"
              className={tab === 1 ? "active" : ""}
            >
              Mails
            </button>
          </StyledTabListItem>
          <StyledTabListItem $themeColor={styleProps.$themeColor}>
            <button
              onClick={handleTab}
              value="2"
              className={tab === 2 ? "active" : ""}
            >
              Comments
            </button>
          </StyledTabListItem>
        </StyledTabList>
        <MailCommentList conversation={mailConversation ?? mail} />
        {tab === 1 ? (
          replyMailQuery.isLoading ? (
            <DoxleListSkeleton containerHeightInPixel={"350px"} />
          ) : (
            <>
              <Virtuoso
                style={{ height: "calc(100vh - 650px)" }}
                data={mailReplies}
                itemContent={itemContent}
                components={{
                  Scroller: React.forwardRef((props, ref) => (
                    <StyledListScroller
                      style={{ ...props.style }}
                      ref={ref}
                      {...props}
                    />
                  )),
                }}
                endReached={handleFetchNextReplies}
              />
              {isAddingReply !== 0 && (
                <Loading
                  containerStyle={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100vw",
                    height: "100vh",
                  }}
                />
              )}
            </>
          )
        ) : mailCommentsQuery.isLoading ? (
          <DoxleListSkeleton containerHeightInPixel={"350px"} />
        ) : (
          <>
            <Virtuoso
              style={{ height: "calc(100vh - 650px)" }}
              data={mailComments}
              itemContent={itemContentComments}
              components={{
                Scroller: React.forwardRef((props, ref) => (
                  <StyledListScroller
                    style={{ ...props.style }}
                    ref={ref}
                    {...props}
                  />
                )),
              }}
              endReached={handleFetchNextComments}
            />
            {isAddingComment !== 0 && (
              <Loading
                containerStyle={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100vw",
                  height: "100vh",
                }}
              />
            )}
          </>
        )}
        <StyledAttachmentsArea {...styleProps}>
          {attachments.map((file, i) => (
            <NewMailAttachment
              key={"new-attachment-" + i}
              file={file}
              handleRemoveFile={handleRemoveFile}
              index={i}
            />
          ))}
          <DoxleIconButton
            {...getRootProps({
              iconSource: (
                <DoxleAttachmentIcon
                  themeColor={styleProps.$themeColor}
                  isFocused={true}
                />
              ),
              buttonSize: 20,
              margin: 0,
            })}
          />
        </StyledAttachmentsArea>
        <StyleWriteMail
          {...styleProps}
          multiline
          minRows={1}
          maxRows={12}
          placeholder={`Write a ${tab === 1 ? "mail" : "comments"} ...`}
          InputProps={{ onKeyDown: handleKeyDown }}
          inputRef={bodyInputRef}
        />
      </StyledDoxleMailScreenContainer>
    </StyledMailRootContainer>
  );
};
export default MailConversation;
