import { Mail } from "../Models/mail";
import {
  StyledMailListItem,
  StyledMailMainContainer,
  StyledMailContent,
  StyledMailInfoContainer,
  StyledSubject,
  StyledName,
  StyledMailContainer,
  StyledDateContainer,
  StyledMonth,
  StyledDay,
  StyledDateInnerWrapper, StyledAttachmentContainer,
} from "./styledCompnents";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import { useNavigate } from "react-router-dom";
import { useMailStore } from "../Store/mailStore";
import { shallow } from "zustand/shallow";
import moment from "moment";
import useMailTableRow from "../Hooks/useMailTableRow";
import { BsFillCloudArrowUpFill } from "react-icons/bs";
import { BsSendCheck } from "react-icons/bs";
import { BsSendExclamation } from "react-icons/bs";
import { RiMailSendLine } from "react-icons/ri";


interface Props {
  mailItem: Mail;
}

const MailItem = ({ mailItem }: Props) => {
  const navigate = useNavigate();
  const { mailTheme, setViewMail } = useMailStore(
    (state) => ({
      mailTheme: state.mailTheme,
      setViewMail: state.setViewMail,
    }),
    shallow
  );
  const { $themeColor, $themeFont, transparentMode, currentTheme } = useDoxleThemeStore(
    (state) => ({
      $themeColor: { ...state.doxleThemeColor, ...mailTheme },
      $themeFont: state.doxleFont,
      transparentMode: state.transparentMode,
      currentTheme: state.currentTheme,

    }),
    shallow
  );

  // const handlePdfIconClick = (e: React.MouseEvent<HTMLElement>) => {
  //   if (mailItem.attachments?.[0]?.url) {
  //     console.log(e);
  //     e.preventDefault();
  //     e.stopPropagation();
  //     window.open(mailItem.attachments?.[0]?.url, "_blank");
  //   }
  // };

  const MAIL_BODY_MAX_LENGTH = 270;
  let textBody = mailItem.textBody;
  if (textBody.length >= MAIL_BODY_MAX_LENGTH) {
    textBody = textBody.substring(0, MAIL_BODY_MAX_LENGTH);
    textBody += "...";
  }

  const { handleClickRow } = useMailTableRow({ mail: mailItem });

  const mailDate = mailItem.timeStamp;
  const month = moment(mailDate).format("MMM").toUpperCase();
  const date = moment(mailDate).format("DD");
  const day = moment(mailDate).format("dddd").toUpperCase();

  // return <StyledMailListItem $themeColor={$themeColor} onClick={()=>navigate(`/Mail/${mailItem.mailId}`)}>
  return (
    <StyledMailListItem
      $themeColor={$themeColor}
      $themeFont={$themeFont}
      $transparentMode={transparentMode}
      $currentTheme={currentTheme}
    >
      <StyledMailContainer onClick={handleClickRow}>
        {/* <StyledAttachmentContainer onClick={handlePdfIconClick}><MailAttachmentIcon themeColor={$themeColor}/></StyledAttachmentContainer> */}
        <StyledDateContainer>
          <StyledDateInnerWrapper>
            <StyledMonth $themeColor={$themeColor}>{month}</StyledMonth>
            <StyledDay $themeColor={$themeColor}>
              <span style={{ fontSize: "1.4rem", fontWeight: 500 }}>
                {date}
              </span>{" "}
              {day}
            </StyledDay>
          </StyledDateInnerWrapper>
        </StyledDateContainer>

        <StyledMailMainContainer>
          <StyledMailInfoContainer>
            <StyledName $themeColor={$themeColor}>
              {mailItem.fromString}
            </StyledName>
            <StyledSubject $themeColor={$themeColor}>
              {mailItem.subject}
            </StyledSubject>
          </StyledMailInfoContainer>

          <StyledMailContent $themeColor={$themeColor}>
            {textBody}
          </StyledMailContent>
        </StyledMailMainContainer>
        <StyledAttachmentContainer>
          {
            mailItem.sendStatus === "Sent"
            ? <BsSendCheck style={{width: 20, height: 20, margin: "auto"}} color={'rgb(17, 183, 24)'} />
            : mailItem.sendStatus === "Sending"
            ? <RiMailSendLine style={{width: 20, height: 20, margin: "auto"}} color={'rgb(253, 183, 4)'} />
            : <BsSendExclamation style={{width: 20, height: 20, margin: "auto"}} color={'rgb(255, 0, 0)'}/>
          }
        </StyledAttachmentContainer>
      </StyledMailContainer>
    </StyledMailListItem>
  );
};
export default MailItem;
