import React from "react";
import { Contact } from "../../../Models/addressBook";

import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import { editRgbaAlpha } from "../../../Utilities/FunctionUtilities";
import { easeInOut } from "framer-motion";
import { StyledMailContactOption } from "./StyledEmailOrder";

type Props = {
  item: Contact;
  onSelectItem: (item: Contact) => void;
  isSelected?: boolean;
};

const ContactListItem = ({ item, onSelectItem, isSelected }: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }),
    shallow
  );
  return (
    <StyledMailContactOption
      onClick={() => onSelectItem(item)}
      $themeColor={doxleThemeColor}
      $doxleFont={doxleFont}
      animate={{
        color: isSelected
          ? doxleThemeColor.doxleColor
          : doxleThemeColor.primaryFontColor,
      }}
      whileHover={{
        backgroundColor: editRgbaAlpha({
          rgbaColor: doxleThemeColor.doxleColor,
          alpha: "0.1",
        }),
        color: "rgba(0,0,0,1)",
      }}
      transition={{ duration: 0.2, ease: easeInOut }}
    >
      {item.firstName} {item.lastName}
      <span className="email-text">{item.email}</span>
    </StyledMailContactOption>
  );
};

export default ContactListItem;
