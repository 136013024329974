import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { useDoxleCurrentContextStore } from "../../../DoxleGeneralStore/useDoxleCurrentContext";
import { shallow } from "zustand/shallow";
import useDoxleNotificationStore from "../../../DoxleGeneralStore/useDoxleNotificationStore";
import { useBudgetStore } from "../../Store/useBudgetStore";
import DocketQuery from "../../../Services/QueryHooks/docketQueryAPI";
import useGetStageList from "./useGetStageList";
import useGetBudgetList from "./useGetBudgetList";
import { IBudgetGroupedStage } from "../../Models/TableTypes";
import { LightDocket } from "../../../Models/dockets";
import { useShallow } from "zustand/react/shallow";
import useEffectAfterMount from "../../../CoreContent/UtilityHooks/useEffectAfterMount";

type Props = {};

interface BudgetTableHook {
  anchorBudgetMenuEl: SVGSVGElement | null;
  handleOpenBudgetMenu: (
    event: React.MouseEvent<SVGSVGElement, MouseEvent>,
    item: LightDocket
  ) => void;
  handleCloseBudgetMenu: () => void;

  currentFocusedDocket: LightDocket | undefined;
  isScrollingTable: boolean;
  setIsScrollingTable: React.Dispatch<React.SetStateAction<boolean>>;

  handleDeleteBudget: () => void;
  handleCloseConfirmDeleteDialog: () => void;

  stageDocketGroup: IBudgetGroupedStage;
  isFetchingBudgetData: boolean;
  isSuccessFetchingBudgetData: boolean;
  isErrorFetchingBudgetData: boolean;
  handleFetchNextPageBudgetData: () => void;
  isFetchingNextPageBudgetData: boolean;
}
const useBudgetTable = ({}: Props): BudgetTableHook => {
  const [anchorBudgetMenuEl, setAnchorBudgetMenuEl] =
    React.useState<null | SVGSVGElement>(null);
  const [currentFocusedDocket, setCurrentFocusedDocket] = useState<
    LightDocket | undefined
  >(undefined);
  const [isScrollingTable, setIsScrollingTable] = useState(false);

  const handleOpenBudgetMenu = useCallback(
    (event: React.MouseEvent<SVGSVGElement, MouseEvent>, item: LightDocket) => {
      event.stopPropagation();
      event.preventDefault();
      setCurrentFocusedDocket(item);
      setAnchorBudgetMenuEl(event.currentTarget);
    },
    [setCurrentFocusedDocket, setAnchorBudgetMenuEl]
  );
  const handleCloseBudgetMenu = useCallback(() => {
    setAnchorBudgetMenuEl(null);
    setCurrentFocusedDocket(undefined);
  }, [setAnchorBudgetMenuEl, setCurrentFocusedDocket]);

  const { currentCompany } = useDoxleCurrentContextStore(
    useShallow((state) => ({
      currentCompany: state.currentCompany,
    }))
  );
  const {
    filterDocketListQuery,
    setConfirmDeleteBudget,
    confirmDeleteBudget,
    shrinkedStageSections,
  } = useBudgetStore((state) => ({
    filterDocketListQuery: state.filterDocketListQuery,
    confirmDeleteBudget: state.confirmDeleteBudget,
    setConfirmDeleteBudget: state.setConfirmDeleteBudget,
    shrinkedStageSections: state.shrinkedStageSections,
  }));

  const showNotification = useDoxleNotificationStore(
    useShallow((state) => state.showNotification)
  );

  const {
    budgetList,
    isFetchingBudgetList,
    isFetchingNextPageBudgetList,
    hasNextPageBudgetList,
    handleFetchNextPageBudgetList,
    isErrorFetchingBudgetList,
  } = useGetBudgetList({});

  const {
    stageList,
    isFetchingStageList,
    isSuccessFetchingStageList,
    isErrorFetchingStageList,
    handleFetchNextPageStage,
    isFetchingNextPageStage,
  } = useGetStageList();

  const stageDocketGroup: IBudgetGroupedStage = useMemo(() => {
    //track the count of dockets in each stage and push the count in each loop of stage list
    let docketCounts: number[] = [];

    //get all docket with stage based on the order of stage list
    const docketsWithStage: LightDocket[] = stageList.flatMap((stage) => {
      const budgetFilteredList = !shrinkedStageSections.includes(stage.stageId)
        ? budgetList.filter((budget) => budget.stageModel === stage.stageId)
        : [];
      docketCounts.push(budgetFilteredList.length);
      return budgetFilteredList;
    });

    //get all dockets without stage
    const docketsWithoutStage: LightDocket[] = !shrinkedStageSections.includes(
      "unstaged"
    )
      ? budgetList.filter((budget) => !budget.stageModel)
      : [];
    docketCounts.push(docketsWithoutStage.length);

    return {
      stageItems: [...stageList, "unstaged"],
      budgetItems: [...docketsWithStage, ...docketsWithoutStage],
      budgetItemsCount: docketCounts,
    };
  }, [stageList, budgetList, shrinkedStageSections]);

  const handleFetchNextPageBudgetData = () => {
    handleFetchNextPageBudgetList();
    handleFetchNextPageStage();
  };

  //*delete
  const deleteBudgetQuery = DocketQuery.useDeleteDocketQuery({
    showNotification,

    company: currentCompany,
    filter: filterDocketListQuery,
  });

  const handleDeleteBudget = () => {
    if (confirmDeleteBudget) {
      deleteBudgetQuery.mutate(confirmDeleteBudget.docketPk);
      setConfirmDeleteBudget(undefined);
    }
  };

  const handleCloseConfirmDeleteDialog = () =>
    setConfirmDeleteBudget(undefined);

  return {
    anchorBudgetMenuEl,
    handleOpenBudgetMenu,
    handleCloseBudgetMenu,

    currentFocusedDocket,
    isScrollingTable,
    setIsScrollingTable,
    handleDeleteBudget,
    handleCloseConfirmDeleteDialog,

    stageDocketGroup,
    isFetchingBudgetData: isFetchingBudgetList || isFetchingStageList,
    isSuccessFetchingBudgetData:
      isSuccessFetchingStageList && isSuccessFetchingStageList,
    isErrorFetchingBudgetData:
      isErrorFetchingBudgetList || isErrorFetchingStageList,
    handleFetchNextPageBudgetData,
    isFetchingNextPageBudgetData:
      isFetchingNextPageBudgetList || isFetchingNextPageStage,
  };
};

export default useBudgetTable;
