import React, { useEffect, useMemo, useRef } from "react";
import useBookingCalendarStore, {
  generatePeriodArray,
} from "../../store/useBookingCalendarStore";
import { useShallow } from "zustand/react/shallow";
import useGetBookingCalendarList from "./useGetBookingCalendarList";
import {
  formatTISODate,
  get1MonthBeforeAndAfter,
  normalizeDate,
} from "../../../Utilities/FunctionUtilities";
import { IBooking } from "../../Models/booking";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import { useDoxleCurrentContextStore } from "../../../DoxleGeneralStore/useDoxleCurrentContext";
import { BookingQueryAPI } from "../../QueryAPI/bookingQueryAPI";
import useRefComponentDimension from "../../../CoreContent/UtilityHooks/useRefComponentDimension";
import useDoxleNotificationStore from "../../../DoxleGeneralStore/useDoxleNotificationStore";
import useEffectAfterMount from "../../../CoreContent/UtilityHooks/useEffectAfterMount";
dayjs.extend(isBetween);

type Props = {};
export interface IBookingCalendarGroup {
  bookingList: IBooking[];

  dateValue: Date;
}
const useBookingCalendar = (props?: Props) => {
  const { company, currentProject, currentSearchText } =
    useDoxleCurrentContextStore(
      useShallow((state) => ({
        company: state.currentCompany,
        currentProject: state.currentProject,
        currentSearchText: state.currentSearchText,
      }))
    );
  const {
    currentWeek,
    setFilterBookingProjectQuery,
    filterBookingProjectQuery,
    filterBookingPeriod,
    deletedBooking,
    setDeletedBooking,
    setEditedBooking,
  } = useBookingCalendarStore(
    useShallow((state) => ({
      currentWeek: state.currentWeek,
      setFilterBookingProjectQuery: state.setFilterBookingProjectQuery,
      filterBookingProjectQuery: state.filterBookingProjectQuery,
      filterBookingPeriod: state.filterBookingPeriod,
      deletedBooking: state.deletedBooking,
      setDeletedBooking: state.setDeletedBooking,
      setEditedBooking: state.setEditedBooking,
    }))
  );
  const dateRangeFilter = useMemo(
    () =>
      filterBookingPeriod
        ? generatePeriodArray(filterBookingPeriod)
        : undefined,
    [filterBookingPeriod]
  );
  const {
    bookingCalendarList,
    refetchBookingCalendarList,
    isFetchingBookingCalendarList,
    isErrorFetchingBookingCalendarList,
  } = useGetBookingCalendarList({});
  const wrapperRef = useRef<HTMLDivElement>(null);
  const { width: wrapperWidth } = useRefComponentDimension({
    componentRef: wrapperRef,
  });
  const numOfCol = useMemo(
    () => (wrapperWidth < 650 ? 1 : wrapperWidth < 1000 ? 2 : 3),
    [wrapperWidth]
  );
  const bookingDataDateGroup: IBookingCalendarGroup[] = useMemo(() => {
    if (dateRangeFilter) {
      return dateRangeFilter.map((date) => {
        const list = bookingCalendarList
          .filter(
            (booking) =>
              (booking.startDate &&
                booking.endDate &&
                dayjs(date).isBetween(
                  booking.startDate,
                  booking.endDate,
                  "day",
                  "[]"
                ) &&
                !booking.completed) ||
              dayjs(date).isSame(booking.startDate, "day") ||
              dayjs(date).isSame(booking.endDate, "day")
          )
          // .sort((a, b) => (a.completed ? 1 : -1));
        return {
          dateValue: date,
          bookingList: list,
        } as IBookingCalendarGroup;
      });
    } else {
      return currentWeek.map((date) => {
        const list = bookingCalendarList
          .filter(
            (booking) =>
              (booking.startDate &&
                booking.endDate &&
                dayjs(date).isBetween(
                  booking.startDate,
                  booking.endDate,
                  "day",
                  "[]"
                ) &&
                !booking.completed) ||
              dayjs(date).isSame(booking.startDate, "day") ||
              dayjs(date).isSame(booking.endDate, "day")
          )
          // .sort((a, b) => (a.completed ? 1 : -1));
        return {
          dateValue: date,
          bookingList: list,
        } as IBookingCalendarGroup;
      });
    }
  }, [dateRangeFilter, currentWeek, bookingCalendarList]);

  //prefetch booking list when the calendar is changed
  const monthRange = useMemo(
    () => get1MonthBeforeAndAfter(currentWeek),
    [currentWeek]
  );
  const prefetchBookingList = BookingQueryAPI.usePrefetchBookingList();

  const showNotification = useDoxleNotificationStore(
    useShallow((state) => state.showNotification)
  );
  const { destroy } = BookingQueryAPI.useMutateBookingQuery({
    company,

    filter: filterBookingProjectQuery,
    showNotification,
  });
  const handleCloseDeleteDialog = () => {
    setDeletedBooking(undefined);
  };
  const handleDeleteBooking = () => {
    if (deletedBooking) destroy.mutate(deletedBooking.bookingId);
    setEditedBooking(undefined);
    handleCloseDeleteDialog();
  };
  useEffect(() => {
    setFilterBookingProjectQuery({
      project: currentProject?.projectId,
      search: currentSearchText,
    });
  }, [currentProject, currentSearchText]);
  useEffect(() => {
    if (company) {
      const timeout = setTimeout(() => {
        if (!filterBookingPeriod) {
          setFilterBookingProjectQuery({
            currentMonthDate: monthRange.currentMonth,
            due: undefined,
            startDate: undefined,
            endDate: undefined,
          });

          if (filterBookingProjectQuery.project) {
            prefetchBookingList({
              company,
              filter: {
                ...filterBookingProjectQuery,
                currentMonthDate: monthRange.previousMonth,
              },
            });
            prefetchBookingList({
              company,
              filter: {
                ...filterBookingProjectQuery,
                currentMonthDate: monthRange.nextMonth,
              },
            });
            prefetchBookingList({
              company,
              filter: {
                ...filterBookingProjectQuery,
                currentMonthDate: undefined,
                due: "fortnight",
              },
            });
            prefetchBookingList({
              company,
              filter: {
                ...filterBookingProjectQuery,
                currentMonthDate: undefined,
                due: "month",
              },
            });
          }
        } else {
          if (filterBookingPeriod === "2weeks") {
            setFilterBookingProjectQuery({
              due: "fortnight",
              currentMonthDate: undefined,
            });
          } else if (filterBookingPeriod === "4weeks") {
            setFilterBookingProjectQuery({
              due: "month",
              currentMonthDate: undefined,
            });
          } else if (filterBookingPeriod.start && filterBookingPeriod.end) {
            setFilterBookingProjectQuery({
              startDate: formatTISODate(filterBookingPeriod.start),
              endDate: formatTISODate(filterBookingPeriod.end),
              currentMonthDate: undefined,
            });
          }
        }
      }, 400);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [monthRange, filterBookingProjectQuery, filterBookingPeriod, company]);
  return {
    dateRangeFilter,
    bookingDataDateGroup,
    refetchBookingCalendarList,
    isFetchingBookingCalendarList,
    isErrorFetchingBookingCalendarList,
    wrapperRef,
    numOfCol,
    handleCloseDeleteDialog,
    handleDeleteBooking,
    deletedBooking,
  };
};

export default useBookingCalendar;
