import React, { useCallback, useMemo } from "react";
import { useDoxleThemeStore } from "../../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import useGetDocketBookingList from "../../Hooks/useGetDocketBookingList";
import { TableComponents, TableVirtuoso } from "react-virtuoso";
import { IBooking } from "../../../Models/booking";
import {
  StyledBookingTable,
  StyledBookingTableBody,
  StyledBookingTableContainer,
  StyledBookingTableHead,
  StyledBookingTableRow,
} from "./StyledComponentBookingDocketTable";
import { TableContainer } from "@mui/material";
import { StyledBookingListScroller } from "../../../CommonComponents/StyledComponentBookings";
import DoxleEmptyPlaceHolder from "../../../../DoxleDesignPattern/DoxleEmptyPlaceHolder/DoxleEmptyPlaceHolder";
import BookingTableRow from "./BookingTableRow";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import BookingDocketTableHeader from "./BookingDocketTableHeader";
import ListLoadingMore from "../../../../Utilities/Lottie/ListLoadingMore";
import DoxleListSkeleton from "../../../../DoxleDesignPattern/DoxleSkeleton/DoxleListSkeleton";
import { Error404Banner } from "../../../../DoxleDesignPattern/DoxleIcons/CommonIcons";
import { EmptyBookingBanner } from "../../../BookingProject/Components/BookingTable/BookingIcon";

type Props = {};
interface BookingTableContext {
  isErrorFetchingBookingDocketList: boolean;
}
const BookingDocketTable = (props: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }),
    shallow
  );
  const {
    bookingDocketList,
    isFetchingBookingDocketList,
    isSuccessFetchingBookingDocketList,
    isErrorFetchingBookingDocketList,
    isRefetchingBookingDocketList,
    isFetchingMoreBookingDocketList,
    refetchBookingDocketList,
    fetchMoreBookingDocketList,
  } = useGetDocketBookingList({});
  const tableVirtuosoContext: BookingTableContext = {
    isErrorFetchingBookingDocketList,
  };
  const tableComponents: TableComponents<IBooking, BookingTableContext> =
    useMemo(
      () => ({
        Table: (props) => <StyledBookingTable {...props} />,

        Scroller: React.forwardRef((props, ref) => (
          <TableContainer
            ref={ref}
            {...props}
            component={StyledBookingListScroller}
          />
        )),
        TableHead: StyledBookingTableHead,
        TableBody: React.forwardRef((props, ref) => (
          <StyledBookingTableBody {...props} ref={ref} />
        )),
        TableRow: (props) => (
          <StyledBookingTableRow
            {...props}
            $themeColor={doxleThemeColor}
            layout
            key={props.item.bookingId}
          />
        ),
        EmptyPlaceholder: (props) => (
          <DoxleEmptyPlaceHolder
            headTitleText={
              props.context?.isErrorFetchingBookingDocketList
                ? "Something Wrong"
                : "No Bookings"
            }
            subTitleText={
              props.context?.isErrorFetchingBookingDocketList
                ? "Failed to get data, please try to reload the page"
                : "Add a booking to schedule your work!"
            }
            containerStyle={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
            containerBgColor="transparent"
            illustration={
              props.context?.isErrorFetchingBookingDocketList ? (
                <Error404Banner themeColor={doxleThemeColor} containerStyle={{width:"30%"}}/>
              ) : (
                <EmptyBookingBanner themeColor={doxleThemeColor} containerStyle={{marginBottom: 12}}/>
              )
            }
          />
        ),
      }),
      []
    );

  const itemContent = useCallback(
    (index: number, item: IBooking, context: BookingTableContext) => (
      <BookingTableRow bookingItem={item} />
    ),
    []
  );
  const tableStyle: React.CSSProperties = {
    width: "100%",
    height: "100%",
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <StyledBookingTableContainer>
        {isFetchingBookingDocketList && (
          <DoxleListSkeleton skeletonType="budgetRow" />
        )}

        {!isFetchingBookingDocketList && (
          <TableVirtuoso
            context={tableVirtuosoContext}
            data={bookingDocketList}
            overscan={100}
            style={tableStyle}
            components={tableComponents}
            endReached={fetchMoreBookingDocketList}
            fixedHeaderContent={() => {
              return <BookingDocketTableHeader />;
            }}
            itemContent={itemContent}
          />
        )}
        {isFetchingMoreBookingDocketList && (
          <ListLoadingMore
            containerStyle={{
              position: "absolute",
              bottom: 0,
              left: 0,
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 10,
            }}
            animationSize={120}
          />
        )}
      </StyledBookingTableContainer>
    </LocalizationProvider>
  );
};

export default BookingDocketTable;
