import React, { useEffect, useState } from "react";
import { StyledAddBookingTitleContainer } from "./StyledComponentAddBookingDocket";

import { useDoxleThemeStore } from "../../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import { useAddDocketBookingContext } from "./AddBookingDocket";
import { useEditDocketSideScreenStore } from "../../../../CoreContent/EditDocketSideScreen/EditDocketSideScreenStore/useEditDocketSideScreenStore";
import BookingDocketNumber from "../../../CommonComponents/BookingDocketNumber";
import { StyledBookingTitleTextField } from "../../../CommonComponents/StyledComponentBookings";

type Props = {};

const AddBookingTitle = (props: Props) => {
  // const [newTitleInput, setNewTitleInput] = useState("");
  const { newBookingTitle, setnewBookingTitle } = useAddDocketBookingContext();
  // useEffect(() => {
  //   const timeout = setTimeout(() => {
  //     setnewBookingTitle(newTitleInput);
  //   }, 300);

  //   return () => {
  //     clearTimeout(timeout);
  //   };
  // }, [newTitleInput]);

  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
      currentTheme: state.currentTheme,
    }),
    shallow
  );
  const { edittedDocket } = useEditDocketSideScreenStore(
    (state) => ({
      edittedDocket: state.edittedDocket,
    }),
    shallow
  );
  return (
    <StyledAddBookingTitleContainer>
      <BookingDocketNumber
        docketNumber={edittedDocket?.docketIdNum}
        docketIconSize="5rem"
        required={true}
      />

      <StyledBookingTitleTextField
        $themeColor={doxleThemeColor}
        $doxleFont={doxleFont}
        placeholder="New booking title..."
        variant="standard"
        value={newBookingTitle}
        onChange={(e) => setnewBookingTitle(e.target.value)}
        autoFocus
      />
    </StyledAddBookingTitleContainer>
  );
};

export default AddBookingTitle;
