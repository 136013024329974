import React from "react";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { QuoteRequest } from "../../models/quote";
import {
  StyledQuoteTableDataCell,
  StyledQuoteTableRowContainer,
  StyledQuotesAttachmentsContainer,
  StyledQuotesCheckBox,
} from "../MainStyledComponents";
import { QuotesCheckBoxIcon, QuotesCheckBoxUncheckedIconNo1 } from "../icons";
import { shallow } from "zustand/shallow";
import QuoteAttachmentsDisplayer from "./QuoteAttachmentsDisplayer";
import QuoteStatusDisplayer from "./QuoteStatusDisplayer";
import moment from "moment";
import { useDoxleQuoteStore } from "../../store/useDoxleQuoteStore";
import { quoteRequestHeaders } from "./QuoteRequestsTable";
import { formatter } from "../../../Utilities/FunctionUtilities";
import { useIsMutating } from "@tanstack/react-query";
import {
  AddQuoteRequestStorageParams,
  QuoteRequestEmailQueryParams,
  UpdateQuoteRequestParams,
  getQRequestMutationKey,
  getQStorageMutationKey,
} from "../../QueryAPI/quotesQuery";
import CircularProgress from "@mui/material/CircularProgress";

interface QuoteRequestsRowProps {
  quoteRequest: QuoteRequest;
  selectedAttachment: string | null;
  setSelectedAttachment: React.Dispatch<React.SetStateAction<string | null>>;
}

const QuoteRequestRow: React.FC<QuoteRequestsRowProps> = ({
  quoteRequest,
  selectedAttachment,
  setSelectedAttachment,
}) => {
  const { THEME_COLOR, doxleFont } = useDoxleThemeStore((state) => ({
    THEME_COLOR: state.doxleThemeColor,
    doxleFont: state.doxleFont,
  }));

  const {
    setQuoteRequestIds,
    quoteRequestIds,
    setView,
    setReviewedQuoteRequest,
  } = useDoxleQuoteStore(
    (state) => ({
      quoteResponseIds: state.quoteResponseIds,
      quoteRequestIds: state.quoteRequestIds,
      setQuoteRequestIds: state.setQuoteRequestIds,
      setQuoteResponseIds: state.setQuoteResponseIds,
      setView: state.setView,
      setReviewedQuoteRequest: state.setReviewedQuoteRequest,
    }),
    shallow
  );
  const handleClickCheckbox = (requestId: string) => {
    setQuoteRequestIds(
      quoteRequestIds.includes(requestId)
        ? quoteRequestIds.filter((id) => !(id === requestId))
        : [...quoteRequestIds, requestId]
    );
  };
  const handleClickRow = () => {
    setReviewedQuoteRequest(quoteRequest);
    setView("QuoteRequestDetail");
  };
  const formatDate = (date: string) => {
    return moment(date).format("MMM DD, YYYY");
  };

  const isUpdatingQuoteRequest =
    useIsMutating({
      mutationKey: getQRequestMutationKey("update"),
      predicate: (query) =>
        (query.state.variables as UpdateQuoteRequestParams).quoteRequestId ===
        quoteRequest.requestId,
    }) > 0;
  const isAddingQRStorage =
    useIsMutating({
      mutationKey: getQStorageMutationKey("add"),
      predicate: (query) =>
        (query.state.variables as AddQuoteRequestStorageParams).requestId ===
        quoteRequest.requestId,
    }) > 0;
  const isSendingMail =
    useIsMutating({
      mutationKey: ["email-quote-request"],
      predicate: (query) => {
        const quoteRequestIds = (query.state.variables as QuoteRequestEmailQueryParams).quoteRequestIds??[]
        return quoteRequestIds.includes(quoteRequest.requestId);
      },
    }) > 0;

  const isUpdatingQRInProgress = isUpdatingQuoteRequest || isAddingQRStorage;
  return (
    <StyledQuoteTableRowContainer
      $doxleFont={doxleFont}
      $themeColor={THEME_COLOR}
      onClick={handleClickRow}
    >
      <StyledQuoteTableDataCell
        $doxleFont={doxleFont}
        $themeColor={THEME_COLOR}
        style={{
          marginRight: "1.4rem",
        }}
      >
        {!isUpdatingQRInProgress ? (
          <StyledQuotesCheckBox
            $themeColor={THEME_COLOR}
            disableTouchRipple
            sx={{
              "& span": {
                border: `2px solid ${THEME_COLOR.doxleColor} !important`,
              },
            }}
            checked={quoteRequestIds.includes(quoteRequest.requestId)}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleClickCheckbox(quoteRequest.requestId);
            }}
            icon={<QuotesCheckBoxUncheckedIconNo1 themeColor={THEME_COLOR} />}
            checkedIcon={<QuotesCheckBoxIcon />}
          />
        ) : (
          <CircularProgress
            style={{
              color: THEME_COLOR.primaryFontColor,
              marginLeft: "0.8rem",
            }}
            size="1.2rem"
          />
        )}
      </StyledQuoteTableDataCell>
      {quoteRequestHeaders.map((header, idx) => (
        <StyledQuoteTableDataCell
          key={`cel#${header.name + idx}`}
          $doxleFont={doxleFont}
          $themeColor={THEME_COLOR}
          style={{
            width:
              header.name !== "CONTRACTOR" && header.name !== "ATTACHMENTS"
                ? "calc(12% - 4px)"
                : header.name === "ATTACHMENTS"
                ? "calc(15% - 4px)"
                : undefined,
            flex: header.name === "CONTRACTOR" ? 1 : undefined,
            justifyContent:
              header.name === "CONTRACTOR" ? "flex-start" : "center",
            alignItems: "center",
          }}
        >
          {header.name === "CONTRACTOR" && quoteRequest.supplierName}
          {header.name === "QUOTE REQUESTED" &&
            (quoteRequest.sentOn ? formatDate(quoteRequest.sentOn) : "-")}
          {header.name === "QUOTE DUE DATE" &&
            (quoteRequest.dueDate
              ? formatDate(`${quoteRequest.dueDate}`)
              : "-")}
          {header.name === "QUOTE RESPONDED" &&
            (quoteRequest.respondedTimestamp
              ? formatDate(quoteRequest.respondedTimestamp)
              : "-")}
          {header.name === "QUOTE AMOUNT" &&
            (quoteRequest.quoteSubTotal
              ? formatter.format(
                  parseFloat(quoteRequest.quoteSubTotal?.toString())
                )
              : "-")}
          {header.name === "QUOTE STATUS" && (
            <QuoteStatusDisplayer status={quoteRequest.status} isSendingMail={isSendingMail} />
          )}
          {header.name === "ATTACHMENTS" &&
            (quoteRequest.attachments.length > 0 ? (
              <QuoteAttachmentsDisplayer
                requestId={quoteRequest.requestId}
                attachments={quoteRequest.attachments}
                setSelectedAttachment={setSelectedAttachment}
                selectedAttachment={selectedAttachment}
              />
            ) : (
              "-"
            ))}
        </StyledQuoteTableDataCell>
      ))}
    </StyledQuoteTableRowContainer>
  );
};

export default QuoteRequestRow;
