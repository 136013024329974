import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import {
  StyledFileFolderTableHeaderCell,
  StyledFolderTreeLine,
  StyledImageDiv,
  StyledLeftRootTableRow,
  StyledRootFileFolderName,
} from "./StyledFilesBodyComponent";
import { useFileStore } from "../Store/useFileStore";
import { shallow } from "zustand/shallow";
import { FolderCloseIcon, FolderOpenIcon, FolderRootIcon } from "./FileIcons";
import ListNewFolderPlaceholder from "../Components/ListNewFolderPlaceholder";
import useListViewLeftHeader from "../Hooks/useListViewLeftHeader";

function ListViewLeftHeader({ showAddFolder }: { showAddFolder?: boolean }) {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore((state) => ({
    doxleThemeColor: state.doxleThemeColor,
    doxleFont: state.doxleFont,
  }));
  const { currentFolder, fileHeaderClicked } = useListViewLeftHeader();

  return (
    <StyledLeftRootTableRow>
      <StyledFileFolderTableHeaderCell
        onClick={fileHeaderClicked}
        $selected={!Boolean(currentFolder)}
      >
        <StyledFolderTreeLine />
        <div className="row-content-wrapper">
          <StyledImageDiv>
            <FolderRootIcon themeColor={doxleThemeColor} />
          </StyledImageDiv>
          <StyledRootFileFolderName layout>Root</StyledRootFileFolderName>
        </div>
      </StyledFileFolderTableHeaderCell>

      {showAddFolder && <ListNewFolderPlaceholder />}
    </StyledLeftRootTableRow>
  );
}

export default ListViewLeftHeader;
