import { useQueryClient } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { produce } from "immer";

interface SetStatusQueryData {
  handleUpdateStatusOrder: (source: number, dest: number, qKey: string[]) => void;
}
const useSetStatusQueryData = (): SetStatusQueryData => {

  const queryClient = useQueryClient();

  const handleUpdateStatusOrder = (source: number, dest: number, qKey: string[]) => {
    queryClient.setQueryData<AxiosResponse<any[]> | undefined>(
      qKey,
      (old) => {
        if (!old || !old?.data) return old;
        return {
          ...old,
          data: produce(old.data, (draft) => {
            const tempSource = {...draft[source]};
            const tempDest = {...draft[dest]};
            if(dest > source){
              for(let i = source; i < dest - 1; i++){
                draft[i] = draft[i + 1];
              }
              draft[dest - 1] = tempDest; 
            }else{
              for(let i = source; i > dest + 1; i--){
                draft[i] = draft[i - 1];
              }
              draft[dest + 1] = tempDest;
            }
            draft[dest] = tempSource;
            return draft;
          })
        };
      }
    );
  };

  return {
    handleUpdateStatusOrder
  };
};

export default useSetStatusQueryData;
