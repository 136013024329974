import React, { useMemo, useRef } from "react";

import { useDoxleAuthStore } from "../../../../DoxleGeneralStore/useDoxleAuthStore";
import { shallow } from "zustand/shallow";
import { useDoxleThemeStore } from "../../../../DoxleGeneralStore/useDoxleThemeStore";
import { Discussion } from "../../../../Models/discussion";
import DiscussionAttachmentDisplayer from "./DiscussionAttachmentDisplayer";
import {
  StyledCommentAuthorInfoContainer,
  StyledCommentAuthorText,
  StyledCommentContentText,
  StyledDiscussionItemContainer,
  StyledDiscussionTimestampText,
} from "./StyledComponentDocketInfo";
import dayjs from "dayjs";
import { useShallow } from "zustand/react/shallow";

type Props = {
  discussionItem: Discussion;
};

const highLightRegExp = /(#TaggedUser@\[[^\]]+\])/g;

const DiscussionItem = ({ discussionItem }: Props) => {
  const { user } = useDoxleAuthStore(
    useShallow((state) => ({ user: state.user }))
  );
  const { doxleThemeColor, doxleFont, currentTheme } = useDoxleThemeStore(
    useShallow((state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
      currentTheme: state.currentTheme,
    }))
  );
  const isUserAuthor: boolean = useMemo(
    () => (user ? Boolean(user.userId === discussionItem.author) : false),
    [user, discussionItem.author]
  );
  const commentRef = useRef<HTMLDivElement>(null);

  return (
    <StyledDiscussionItemContainer
      $horizontalAlign={"flex-start"}
      ref={commentRef}
      $hasAttachment={false}
    >
      <StyledCommentAuthorInfoContainer>
        <StyledCommentAuthorText
          $isAuthor={isUserAuthor}
          $hasAttachment={false}
        >
          {discussionItem.authorJson
            ? `${discussionItem.authorJson.firstName} ${discussionItem.authorJson.lastName}`
            : "Unknown User"}
        </StyledCommentAuthorText>

        <StyledDiscussionTimestampText $hasAttachment={false}>
          wrote on{" "}
          {dayjs(discussionItem.timeStamp).format("ddd, DD MMM YYYY hh:mma")}
        </StyledDiscussionTimestampText>
      </StyledCommentAuthorInfoContainer>

      <StyledCommentContentText
        $themeColor={doxleThemeColor}
        $doxleFont={doxleFont}
      >
        {discussionItem.commentText
          .split(highLightRegExp)
          .map((item, index) => {
            if (highLightRegExp.test(item)) {
              return (
                <span key={index} style={{ color: doxleThemeColor.doxleColor }}>
                  {item.replace("#TaggedUser", "")}
                </span>
              );
            } else {
              return <span key={index}>{item}</span>;
            }
          })}
      </StyledCommentContentText>

      <DiscussionAttachmentDisplayer discussionItem={discussionItem} />
    </StyledDiscussionItemContainer>
  );
};

export default React.memo(DiscussionItem);
