import { ProjectStatus } from "../../../Models/project";
import { DocketStatus } from "../../../Models/dockets";
import { OrderStatus } from "../../../Orders/Models/order";
import { useState } from "react";
import { StyledEditStatusTextField } from "../styledComponents";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";

interface Props {
  status: ProjectStatus | DocketStatus | OrderStatus;
  handleUpdateStatusName: (
    status: ProjectStatus | DocketStatus | OrderStatus,
    statusName: string
  ) => void;
}
const EditStatusTextField = ({ status, handleUpdateStatusName }: Props) => {
  const [editStatus, setEditStatus] = useState<boolean>(false);
  const [statusName, setStatusName] = useState<string>(status.statusName);
  const styleProps = useDoxleThemeStore(
    (state) => ({
      $themeColor: state.doxleThemeColor,
      $doxleFont: state.doxleFont,
    }),
    shallow
  );
  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    handleUpdateStatusName(status, e.currentTarget.value);
    setEditStatus(false);
  };
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStatusName(e.currentTarget.value);
  };
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleUpdateStatusName(status, e.currentTarget.value);
      setEditStatus(false);
    }
    if (e.key === "Escape") {
      setEditStatus(false);
    }
  };
  if (!editStatus)
    return (
      <div
        style={{ color: styleProps.$themeColor.primaryFontColor }}
        onClick={() => setEditStatus(true)}
      >
        {statusName}
      </div>
    );
  return (
    <StyledEditStatusTextField
      {...styleProps}
      value={statusName}
      onBlur={handleBlur}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
    />
  );
};
export default EditStatusTextField;
