import React from "react";
import { shallow } from "zustand/shallow";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { QuoteResponse } from "../../models/quote";
import {
  StyledQuoteTableDataCell,
  StyledQuoteTableRowContainer,
  StyledQuotesAttachmentsContainer,
  StyledQuotesCheckBox,
} from "../MainStyledComponents";
import QuoteAttachmentsDisplayer from "./QuoteAttachmentsDisplayer";
import { QuotesCheckBoxIcon, QuotesCheckBoxUncheckedIconNo1 } from "../icons";
import moment from "moment";
import { useDoxleQuoteStore } from "../../store/useDoxleQuoteStore";
import { quoteResponseHeaders } from "./QuoteRequestsTable";
import { formatter } from "../../../Utilities/FunctionUtilities";
import QuoteStatusDisplayer from "./QuoteStatusDisplayer";

interface QuoteRequestsRowProps {
  quoteResponse: QuoteResponse;
  selectedAttachment: string | null;
  setSelectedAttachment: React.Dispatch<React.SetStateAction<string | null>>;
}

const QuoteResponseRow: React.FC<QuoteRequestsRowProps> = ({
  quoteResponse,
  selectedAttachment,
  setSelectedAttachment,
}) => {
  const { THEME_COLOR, doxleFont } = useDoxleThemeStore((state) => ({
    THEME_COLOR: state.doxleThemeColor,
    doxleFont: state.doxleFont,
  }));

  const { setQuoteResponseIds, quoteResponseIds } = useDoxleQuoteStore(
    (state) => ({
      quoteResponseIds: state.quoteResponseIds,
      setQuoteResponseIds: state.setQuoteResponseIds,
    }),
    shallow
  );
  const handleClickCheckbox = (responseId: string) => {
    setQuoteResponseIds(
      quoteResponseIds.includes(responseId)
        ? quoteResponseIds.filter((id) => !(id === responseId))
        : [...quoteResponseIds, responseId]
    );
  };

  const formatDate = (date: string) => {
    return moment(date).format("MMM DD, YYYY");
  };

  return (
    <StyledQuoteTableRowContainer
      $doxleFont={doxleFont}
      $themeColor={THEME_COLOR}
    >
      <StyledQuoteTableDataCell
        $doxleFont={doxleFont}
        $themeColor={THEME_COLOR}
        style={{
          marginRight: "1.4rem",
        }}
      >
        <StyledQuotesCheckBox
          $themeColor={THEME_COLOR}
          disableTouchRipple
          sx={{
            "& span": {
              border: `2px solid ${THEME_COLOR.doxleColor} !important`,
            },
          }}
          checked={quoteResponseIds.includes(quoteResponse.responseId)}
          onClick={() => handleClickCheckbox(quoteResponse.responseId)}
          icon={<QuotesCheckBoxUncheckedIconNo1 themeColor={THEME_COLOR} />}
          checkedIcon={<QuotesCheckBoxIcon />}
        />
      </StyledQuoteTableDataCell>
      {quoteResponseHeaders.map((header, idx) => (
        <StyledQuoteTableDataCell
          key={`cel#${header.name + idx}`}
          $doxleFont={doxleFont}
          $themeColor={THEME_COLOR}
          style={{
            width:
              header.name !== "CONTRACTOR" && header.name !== "ATTACHMENTS"
                ? "calc(12% - 4px)"
                : header.name === "ATTACHMENTS"
                ? "calc(15% - 4px)"
                : undefined,
            flex: header.name === "CONTRACTOR" ? 1 : undefined,
            justifyContent:
              header.name === "CONTRACTOR" ? "flex-start" : "center",
            alignItems: "center",
          }}
        >
          {header.name === "CONTRACTOR" && quoteResponse.supplierName}
          {header.name === "DATE CREATED" &&
            (quoteResponse.timeStamp
              ? formatDate(quoteResponse.timeStamp)
              : "-")}
          {header.name === "CREATED BY" && "-"}
          {header.name === "ACCEPTED BY" && "-"}
          {header.name === "QUOTE AMOUNT" &&
            formatter.format(
              parseFloat(quoteResponse.quoteSubTotal.toString())
            )}
          {header.name === "QUOTE STATUS" &&
            (quoteResponse.status ? (
              <QuoteStatusDisplayer status={quoteResponse.status} />
            ) : (
              "-"
            ))}
          {header.name === "ATTACHMENTS" &&
            (quoteResponse.attachments.length > 0 ? (
              <QuoteAttachmentsDisplayer
                requestId={quoteResponse.responseId}
                attachments={quoteResponse.attachments}
                setSelectedAttachment={setSelectedAttachment}
                selectedAttachment={selectedAttachment}
              />
            ) : (
              "-"
            ))}
        </StyledQuoteTableDataCell>
      ))}
    </StyledQuoteTableRowContainer>
  );
};

export default QuoteResponseRow;
