import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import { FilterGetNoteQuery } from "../QueryAPI/notesQueryAPI";
import { Note, NoteLine } from "../Models/note";
type TNoteScope = "Project" | "Docket";
interface DocketNoteStore {
  noteScope: TNoteScope;
  setNoteScope: (scope: TNoteScope) => void;

  filterNoteQuery: FilterGetNoteQuery;
  setFilterNoteQuery: (filter: FilterGetNoteQuery) => void;

  deletedNote: Note | undefined;
  setDeletedNote: (item: Note | undefined) => void;

  edittedNote: Note | undefined;
  setEdittedNote: (item: Note | undefined) => void;

  focusedNoteLine: NoteLine | undefined;
  setFocusedNoteLine: (item: NoteLine | undefined) => void;
  resetNoteStore: () => void;
}

export const useNoteStore = create<DocketNoteStore, [["zustand/immer", never]]>(
  immer((set, get) => ({
    noteScope: "Project",
    setNoteScope: (scope: TNoteScope) =>
      set((state) => ({
        noteScope: scope,
      })),
    filterNoteQuery: {},
    setFilterNoteQuery: (filter: FilterGetNoteQuery) =>
      set((state) => ({
        filterNoteQuery: { ...state.filterNoteQuery, ...filter },
      })),

    deletedNote: undefined,
    setDeletedNote: (item: Note | undefined) =>
      set((state) => ({
        deletedNote: item,
      })),

    edittedNote: undefined,
    setEdittedNote: (item: Note | undefined) =>
      set((state) => ({
        edittedNote: item,
      })),

    focusedNoteLine: undefined,
    setFocusedNoteLine: (item: NoteLine | undefined) =>
      set((state) => ({
        focusedNoteLine: item,
      })),

    resetNoteStore: () =>
      set({
        filterNoteQuery: {},
        noteScope: "Project",
        edittedNote: undefined,
      }),
  }))
);
