import React, { useEffect, useState } from "react";

import { useDoxleCurrentContextStore } from "../../../DoxleGeneralStore/useDoxleCurrentContext";
import { shallow } from "zustand/shallow";
import useDoxleNotificationStore from "../../../DoxleGeneralStore/useDoxleNotificationStore";
import { useBudgetStore } from "../../Store/useBudgetStore";
import { formatTISODateTime } from "../../../Utilities/FunctionUtilities";
import DocketQuery from "../../../Services/QueryHooks/docketQueryAPI";
import { LightDocket } from "../../../Models/dockets";

type Props = {
  handleCloseBudgetMenu: () => void;
  currentFocusedDocket: LightDocket | undefined;
  renameInputRef: React.RefObject<HTMLInputElement>;
};

interface BudgetRowPopperMenu {
  showRenameInput: boolean;
  setShowRenameInput: React.Dispatch<React.SetStateAction<boolean>>;
  newBudgetNameText: string;
  setNewBudgetNameText: React.Dispatch<React.SetStateAction<string>>;
  handleRenameBudget: () => void;
  isUpdatingBudget: boolean;
  handleMarkCompleteBudget: () => void;
  handlePinBudget: () => void;
  handleDeleteBudget: () => void;
}
const useBudgetRowPopperMenu = ({
  handleCloseBudgetMenu,
  currentFocusedDocket,
  renameInputRef,
}: Props): BudgetRowPopperMenu => {
  const [showRenameInput, setShowRenameInput] = useState(false);
  const [newBudgetNameText, setNewBudgetNameText] = useState(
    currentFocusedDocket?.docketName || ""
  );
  const { currentCompany } = useDoxleCurrentContextStore(
    (state) => ({
      currentCompany: state.currentCompany,
    }),
    shallow
  );
  const { filterDocketListQuery, setConfirmDeleteBudget } = useBudgetStore(
    (state) => ({
      filterDocketListQuery: state.filterDocketListQuery,
      setConfirmDeleteBudget: state.setConfirmDeleteBudget,
    })
  );

  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );

  const onSuccessUpdateBudget = () => {
    setShowRenameInput(false);
    // handleCloseBudgetMenu();
  };
  const updateBudgetQuery = DocketQuery.useUpdateDocketQuery({
    showNotification,

    company: currentCompany,
    filter: filterDocketListQuery,
    onSuccessCb: onSuccessUpdateBudget,
  });

  const handleRenameBudget = () => {
    if (newBudgetNameText && currentFocusedDocket) {
      if (newBudgetNameText !== currentFocusedDocket.docketName) {
        handleCloseBudgetMenu();
        updateBudgetQuery.mutate({
          docketPk: currentFocusedDocket.docketPk,
          docketName: newBudgetNameText,
        });
      } else setShowRenameInput(false);
    } else setShowRenameInput(false);
  };

  const handleMarkCompleteBudget = () => {
    handleCloseBudgetMenu();
    if (currentFocusedDocket)
      updateBudgetQuery.mutate({
        docketPk: currentFocusedDocket.docketPk,
        completed: currentFocusedDocket.completed
          ? null
          : formatTISODateTime(new Date()),
      });
  };
  const handlePinBudget = () => {
    handleCloseBudgetMenu();
    if (currentFocusedDocket)
      updateBudgetQuery.mutate({
        docketPk: currentFocusedDocket.docketPk,
        isSticky: !currentFocusedDocket.isSticky,
      });
  };

  const handleDeleteBudget = () => {
    setConfirmDeleteBudget(currentFocusedDocket);
    handleCloseBudgetMenu();
  };
  useEffect(() => {
    setShowRenameInput(false);
    if (currentFocusedDocket)
      setNewBudgetNameText(currentFocusedDocket.docketName);
  }, [setShowRenameInput, setNewBudgetNameText, currentFocusedDocket]);

  return {
    showRenameInput,
    setShowRenameInput,
    newBudgetNameText,
    setNewBudgetNameText,
    handleRenameBudget,
    isUpdatingBudget: updateBudgetQuery.isLoading,
    handleMarkCompleteBudget,
    handlePinBudget,
    handleDeleteBudget,
  };
};

export default useBudgetRowPopperMenu;
