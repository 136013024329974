import { createContext, useContext, useEffect } from "react";
import {
  StyledBadgeListContainer,
  StyledFormInputSection,
  StyledPricebookAddNewContainer,
  StyledPricebookAddNewInputDiv,
  StyledPricebookAddNewInputText,
  StyledPricebookAddNewInputTitleText,
  StyledPricebookAddEditHeadTitle,
  StyledWarningText,
} from "../PricebookList/StyledComponents";
import useEditPricebookItem, {
  IEditPricebookItemContextValue,
} from "./Hooks/useEditPricebookItem";
import useGetPricebookDetail from "../../Hooks/useGetPricebookDetail";
import { useShallow } from "zustand/react/shallow";
import Loading from "../../../Utilities/Lottie/Loading";
import { editRgbaAlpha } from "../../../Utilities/FunctionUtilities";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import DoxleSelectContactDropdown from "../../../DoxleDesignPattern/DoxleSelectContactDropdown/DoxleSelectContactDropdown";
import { useMemo } from "react";
import { produce } from "immer";
import SelectedCoreDocketItem from "./SelectedCoreDocketItem";
import Autocomplete from "@mui/material/Autocomplete";
import { useDropzone } from "react-dropzone";
import AddedSwatchItem from "./AddedSwatchItem";
import PricebookSwatchItem from "./PricebookSwatchItem";
import { AttachmentUploadIcon } from "../../../CoreContent/EditDocketSideScreen/EditDocketSideScreenIcons";
import SupplierRateHeader from "../PricebookList/SupplierRateHeader";
import EditSupplierRateLine from "./EditSupplierRateLine";
import {
  StyledAddSwatchDropzone,
  StyledCoreDocketOption,
  StyledPricebookAddNewSupplierBtn,
  StyledPricebookDialogSubmitButton,
  StyledPricebookSwatchContainer,
} from "./StyledAddEditPricebookItem";
import DoxleEmptyPlaceHolder from "../../../DoxleDesignPattern/DoxleEmptyPlaceHolder/DoxleEmptyPlaceHolder";
import { Doxle404Banner } from "../../../DoxleDesignPattern/Doxle404Screen/Doxle404ScreenIcons";

type Props = {
  pricebookId: string;
};

const EditPricebookItemContext =
  createContext<IEditPricebookItemContextValue | null>(null);
const EditPricebookItem = ({ pricebookId }: Props) => {
  const styleProps = useDoxleThemeStore(
    useShallow((state) => ({
      $themeColor: state.doxleThemeColor,
      $doxleFont: state.doxleFont,
      $currentTheme: state.currentTheme,
    }))
  );
  const {
    showSupplierList,
    setShowSupplierList,
    updatedPricebookData,
    setUpdatedPricebookData,
    editPricebookItemContextValue,
    handleSelectSupplier,
    handleUpdateDescription,
    shouldShowWarning,
    selectedCoreDockets,
    coreDocketList,
    handleSelectCoreDockets,
    onDrop,
    handleAddNewSupplierRate,
    handlePatchPricebook,
    isUpdatingPricebook,
    handleRemoveAddedSwatch,
    handleRemoveAddedCoreDocket,
  } = useEditPricebookItem({ pricebookId });

  const {
    pricebookItem,

    isFetchingPricebookItem,
    isErrorFetchingPricebookItem,
  } = useGetPricebookDetail({ pricebookId: pricebookId });

  //useEffect to set updatedPricebookData when pricebookItem is fetched
  useEffect(() => {
    if (pricebookItem) {
      setUpdatedPricebookData(
        produce((draft) => {
          draft.supplierRates = draft.supplierRates.concat(
            pricebookItem.supplierRates.filter(
              (item) =>
                !draft.supplierRates.some(
                  (newItem) => newItem.rateId === item.rateId
                )
            )
          );
          draft.description = pricebookItem.description;
          draft.coreDockets = pricebookItem.coreDockets;
          return draft;
        })
      );
    }
  }, [pricebookItem]);

  const selectedSupplierRate = useMemo(
    () =>
      updatedPricebookData.supplierRates
        .map((item) => item.supplier ?? "")
        .concat(
          pricebookItem?.supplierRates.map((item) => item.supplier ?? "") ?? []
        ),
    [updatedPricebookData.supplierRates, pricebookItem?.supplierRates]
  );
  const { getRootProps } = useDropzone({
    accept: {
      "image/png": [".png"],
      "image/jpg": [".jpg"],
      "image/jpeg": [".jpeg"],
    },
    onDrop,
  });
  const contactDropdownStyle: React.CSSProperties = {
    borderRadius: 9,
  };
  return (
    <EditPricebookItemContext.Provider value={editPricebookItemContextValue}>
      {(isFetchingPricebookItem || isUpdatingPricebook) && (
        <Loading
          containerStyle={{
            backgroundColor: editRgbaAlpha({
              rgbaColor: styleProps.$themeColor.primaryBackgroundColor,
              alpha: "0.8",
            }),
            position: "absolute",
          }}
          text={
            isFetchingPricebookItem
              ? "Getting item details"
              : "Updating item details"
          }
        />
      )}

      <DoxleSelectContactDropdown
        popoverAnchor={showSupplierList ? showSupplierList?.anchorListEl : null}
        onClosePopover={() => {
          setShowSupplierList(undefined);
        }}
        variants="company"
        onSelectContactCompany={handleSelectSupplier}
        selectedCompanyItemIds={selectedSupplierRate}
        containerStyle={contactDropdownStyle}
      />

      <StyledPricebookAddNewContainer>
        {!isErrorFetchingPricebookItem && (
          <>
            <StyledPricebookAddEditHeadTitle>
              EDIT PRICEBOOK ITEM
            </StyledPricebookAddEditHeadTitle>

            <StyledFormInputSection>
              <StyledPricebookAddNewInputDiv $width={"100%"}>
                <StyledPricebookAddNewInputTitleText>
                  Item Description
                </StyledPricebookAddNewInputTitleText>
                <StyledPricebookAddNewInputText
                  required
                  $width="100%"
                  placeholder="Description"
                  value={updatedPricebookData.description}
                  onChange={handleUpdateDescription}
                />
                <StyledWarningText
                  style={{
                    opacity:
                      !updatedPricebookData.description && shouldShowWarning
                        ? 1
                        : 0,
                  }}
                >
                  Please Enter Description
                </StyledWarningText>
              </StyledPricebookAddNewInputDiv>
              <StyledPricebookAddNewInputDiv $width={"100%"} layout="position">
                <StyledPricebookAddNewInputTitleText>
                  Template Dockets
                </StyledPricebookAddNewInputTitleText>
                <StyledBadgeListContainer>
                  {selectedCoreDockets.map((coreDocket, i) => (
                    <SelectedCoreDocketItem
                      item={coreDocket}
                      itemIndex={i}
                      key={coreDocket.coreDocketId}
                      onRemoveDocket={handleRemoveAddedCoreDocket}
                    />
                  ))}
                </StyledBadgeListContainer>
                <Autocomplete
                  sx={{
                    width: "100% !important",
                    display: "flex",
                    alignItems: "center",

                    backgroundColor:
                      styleProps.$themeColor.primaryContainerColor,
                    "&.MuiAutocomplete-root .MuiOutlinedInput-root": {
                      padding: "0px !important",
                    },
                  }}
                  placeholder="Find a core docket"
                  options={coreDocketList}
                  getOptionLabel={(option) =>
                    option.coreDocketNumber + " " + option.coreDocketName
                  }
                  slotProps={{
                    paper: {
                      sx: {
                        backgroundColor:
                          styleProps.$themeColor.primaryContainerColor,
                      },
                    },
                  }}
                  renderOption={(props, option, state) => (
                    <StyledCoreDocketOption
                      $selected={updatedPricebookData.coreDockets.includes(
                        option.coreDocketId
                      )}
                      onClick={() =>
                        handleSelectCoreDockets(option.coreDocketId)
                      }
                      {...props}
                    >
                      {option.coreDocketNumber + " " + option.coreDocketName}
                    </StyledCoreDocketOption>
                  )}
                  onChange={(event, value) => {
                    if (value) handleSelectCoreDockets(value.coreDocketId);
                  }}
                  renderInput={(params) => (
                    <StyledPricebookAddNewInputText {...params} $width="100%" />
                  )}
                />
              </StyledPricebookAddNewInputDiv>

              <StyledPricebookAddNewInputDiv $width={"100%"} layout="position">
                <StyledPricebookAddNewInputTitleText>
                  Swatches
                </StyledPricebookAddNewInputTitleText>

                <StyledPricebookSwatchContainer>
                  {updatedPricebookData.swatches.map((swatch, index) => (
                    <AddedSwatchItem
                      item={swatch}
                      itemIndex={index}
                      key={`addedSwatch#${index}`}
                      onRemoveAddedSwatch={handleRemoveAddedSwatch}
                    />
                  ))}
                  {pricebookItem?.swatches &&
                    pricebookItem.swatches.map((swatch, index) => (
                      <PricebookSwatchItem
                        key={`existing-swatch-${swatch.swatchId}`}
                        index={index}
                        swatch={swatch}
                      />
                    ))}
                </StyledPricebookSwatchContainer>

                <StyledAddSwatchDropzone {...getRootProps()}>
                  <AttachmentUploadIcon
                    themeColor={styleProps.$themeColor}
                    containerStyle={{
                      width: "40%",
                      marginBottom: 14,
                      maxWidth: 120,
                    }}
                  />
                  Click or drop image to upload
                </StyledAddSwatchDropzone>
              </StyledPricebookAddNewInputDiv>
              <SupplierRateHeader />
              {updatedPricebookData.supplierRates.map((item, index) => {
                return (
                  <EditSupplierRateLine
                    rate={item}
                    index={index}
                    key={`rate#${index}`}
                  />
                );
              })}

              <StyledPricebookAddNewSupplierBtn
                layout="position"
                onClick={handleAddNewSupplierRate}
              >
                Add Another Supplier
              </StyledPricebookAddNewSupplierBtn>
            </StyledFormInputSection>
            <StyledPricebookDialogSubmitButton
              type="submit"
              onClick={handlePatchPricebook}
            >
              SUBMIT
            </StyledPricebookDialogSubmitButton>
          </>
        )}

        {isErrorFetchingPricebookItem && (
          <DoxleEmptyPlaceHolder
            headTitleText="Something wrong!"
            subTitleText="Unable to find item, please try to reload the page..."
            illustration={
              <Doxle404Banner
                themeColor={styleProps.$themeColor}
                containerStyle={{
                  width: "100%",
                  maxWidth: 400,
                }}
              />
            }
          />
        )}
      </StyledPricebookAddNewContainer>
    </EditPricebookItemContext.Provider>
  );
};

export const useEditPricebookItemContext = () =>
  useContext(EditPricebookItemContext) as IEditPricebookItemContextValue;
export default EditPricebookItem;
