import Button from "@mui/material/Button";
import Checkbox, { CheckboxProps } from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow, { TableRowProps } from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { motion } from "framer-motion";
import styled from "styled-components";
import {
  DoxleFont,
  DoxleThemeColor,
} from "../../DoxleGeneralStore/useDoxleThemeStore";
import { editRgbaAlpha } from "../../Utilities/FunctionUtilities";
import TextField from "@mui/material/TextField";

export const StyledRootContainer = styled(motion.div)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  width: calc(100% - 1.6rem);
  height: calc(100% - 4rem);
  background-color: ${(p) => p.$themeColor.primaryBackgroundColor};
  padding: 0px 0.8rem;
  position: relative;
  margin: 2rem 0px;
  display: flex;
  flex-direction: column;
`;

export const StyledTitleTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 400px;
  padding-left: 62px;
  padding-top: 60px;
`;

export const StyledTitleText = styled(Typography)<{
  themeColor: DoxleThemeColor;
  doxleFont: DoxleFont;
}>`
  &.MuiTypography-root {
    font-family: ${(p) => p.doxleFont.secondaryFont};
    font-style: normal;
    font-weight: 300;
    font-size: 55px;
    line-height: 65px;
    letter-spacing: 0.05em;
    color: ${(p) => p.themeColor.primaryFontColor};
  }
`;

export const StyledQuotesTableDivSpaceBetween = styled.div`
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
`;

export const StyledQuotesTableDivRight = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
  align-items: center;
  padding: 0px 90px;
`;

export const StyledQuoteRequestRow = styled.tr<{
  backgroundColor: string;
  cursor?: string;
}>`
  display: flex;
  background-color: ${(p) => p.backgroundColor};
  min-width: 1100px;
  width: 100%;
  height: 33px;
  border: 1px solid #f5f6f7;
  align-items: center;
  cursor: ${(p) => p.cursor ?? "default"};
`;

export const StyledQuotesButton = styled(Button)<{
  backgroundColor?: string;
}>`
  && {
    height: 25px;
    width: 110px;
    background: ${(p) => p.backgroundColor ?? "#7b7bfe"};
    border-radius: 3px;
    margin-right: 10px;
    //margin-left: 20px;
  }
`;

export const StyledQuotesButtonText = styled(Typography)<{
  color?: string;
}>`
  &.MuiTypography-root {
    font-family: "IBM Plex Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 14px;
    letter-spacing: 0.05em;
    color: ${(p) => p.color ?? "#ffffff"};
  }
`;

export const StyledQuotesDescriptionDiv = styled.div`
  width: 40%;
  min-width: 350px;
  align-items: center;
  display: flex;
`;

export const StyledQuotesHeaderDiv = styled.div`
  width: 20%;
  min-width: 100px;
  align-items: center;
  display: flex;
`;

export const StyledQuotesHeaderButtonDiv = styled.div`
  position: fixed;
  right: 0;
  align-items: center;
  display: flex;
`;

export const StyledQuotesCheckBox = styled(
  ({
    $themeColor,

    ...props
  }: CheckboxProps & {
    $themeColor: DoxleThemeColor;
  }) => <Checkbox {...props} />
)`
  && {
    padding: 0;
    margin-left: 0.8rem;

    & span {
      border-radius: 0.6rem;
      border: 2px solid
        ${(p) =>
          editRgbaAlpha({
            rgbaColor: p.$themeColor.primaryFontColor,
            alpha: "0.4",
          })};
    }
  }
`;

export const StyledQuoteRequestRowText = styled(Typography)<{
  fontWeight: string;
  fontSize: string;
  lineHeight: string;
  themeColor: DoxleThemeColor;
  doxleFont: DoxleFont;
}>`
  &.MuiTypography-root {
    font-family: ${(p) => p.doxleFont.secondaryFont};
    font-style: normal;
    font-weight: ${(p) => p.fontWeight};
    font-size: ${(p) => p.fontSize};
    line-height: ${(p) => p.lineHeight};
    letter-spacing: 0.05em;
    color: ${(p) => p.themeColor.primaryFontColor};
  }
`;

export const StyledQuotesRequestBox = styled.div<{
  backgroundColor: string;
  width?: string;
  minWidth?: string;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: ${(p) => p.width ?? "270px"};
  width: ${(p) => p.minWidth ?? "270px"};
  //margin-left: 100px;
  height: 20px;
  border-radius: 12px;
  background: ${(p) => p.backgroundColor};
`;

export const StyledQuotesStatusText = styled(Typography)`
  &.MuiTypography-root {
    font-family: "IBM Plex Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 17px;
    letter-spacing: 0.05em;
  }
`;

export const StyledLogoTitleContainer = styled.div`
  display: flex;
  padding-top: 35px;
  align-items: start;
  padding-left: 5px;
`;

export const StyledLogoContainer = styled.div``;

export const StyledQuotesTableContainer = styled.div<{
  $doxleFont: DoxleFont;
  $themeColor: DoxleThemeColor;
}>`
  width: 100%;
  box-sizing: border-box;
  color: ${(p) => p.$themeColor.primaryFontColor};
  height: calc(50% - 6rem);
  margin: 3rem 0px;
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const StyleQuotesTableHeaderRow = styled(TableRow)<{
  $doxleFont: DoxleFont;
  $themeColor: DoxleThemeColor;
}>`
  && {
    font-family: ${(p) => p.$doxleFont.primaryTitleFont};
    color: ${(p) => p.$themeColor.primaryFontColor};
    background-color: ${(p) => p.$themeColor.primaryBackgroundColor};
    letter-spacing: 0.5px;
    font-size: 1rem;
    width: calc(100% - 1.6rem) !important;
    padding: 0px 0.8rem 0.4rem 0.8rem !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    min-width: 300px !important;
  }
`;

export const StyledQuotesTableHeaderItems = styled(TableCell)<{
  $doxleFont: DoxleFont;
  $themeColor: DoxleThemeColor;
}>`
  && {
    margin: 0px 2px;
    padding: 0px !important;
    color: ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.$themeColor.primaryFontColor,
        alpha: "0.7",
      })};
    font-family: ${(p) => p.$doxleFont.primaryTitleFont};
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.05rem;
    border: none !important;
    display: flex;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const StyledQuotesStatus = styled.span<{
  $bgColor: string;
  $doxleFont: DoxleFont;
}>`
  background-color: ${(p) => `${p.$bgColor}`};
  color: #fff;
  font-family: ${(p) => p.$doxleFont.primaryFont};
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.06rem;
  padding: 0.2rem 0.4rem;
  border-radius: 0px 0.52rem 0.52rem 0.52rem;
`;

export const StyledQuotesAttachmentsPopupContainer = styled.div<{
  $doxleFont: DoxleFont;
  $themeColor: DoxleThemeColor; // to be removed
}>`
  position: relative;

  font-family: ${(p) => p.$doxleFont.primaryFont};
  font-weight: 500;
  display: flex;
  flex-direction: column;
  border-radius: 0px 0px 0px 0.9rem;
  max-height: 30rem;
  min-height: 20rem;
  width: 24rem;
  box-shadow: 0px 0px 8px 0px ${(p) => p.$themeColor.primaryBoxShadowColor};
  background-color: ${(p) => p.$themeColor.primaryContainerColor};
  .attachment-wrapper {
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;

    scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      display: none;
    }
    overflow: auto;
  }
`;

export const StyledQuotesAttachmentsPopup = styled.div`
  position: absolute;
  top: 0px;
  left: 0;
  width: 100%;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  z-index: 10;
  background-color: #fff;
`;

export const StyledQuotesAttachmentsPopupTitle = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  font-size: 1.4rem;
  font-weight: 700;
  font-family: ${(p) => p.$doxleFont.primaryFont};
  text-align: left;
  letter-spacing: 0.7px;
  padding: 1.1rem;
  color: ${(p) => p.$themeColor.primaryFontColor};
  border-bottom: 1px solid ${(p) => p.$themeColor.boldDividerColor};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const StyledCloseIcon = styled.span`
  cursor: pointer;
  color: rgba(0, 0, 0, 0.5);
`;

export const StyledQuotesAttachmentsItem = styled.div<{
  $alignment?: string;
}>`
  font-size: 1rem;
  display: flex;
  gap: 0.9rem;
  align-items: center;
  justify-content: ${(p) => p.$alignment ?? "left"};
  padding: 0.6rem 1.1rem;
  cursor: pointer;
  height: 100%;
  width: 100%;
`;

export const StyledQuotesAttachmentsContainer = styled.td`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledQuotesAttachmentItemsLabel = styled.span<{
  $doxleFont: DoxleFont;
  $themeColor: DoxleThemeColor;
}>`
  color: ${(p) =>
    editRgbaAlpha({ rgbaColor: p.$themeColor.primaryFontColor, alpha: "0.4" })};
  font-family: ${(p) => p.$doxleFont.primaryFont};
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.07rem;
`;

export const StyledQuotesAttachmentTitle = styled.span<{
  $doxleFont: DoxleFont;
  $themeColor: DoxleThemeColor;
}>`
  color: ${(p) => p.$themeColor.primaryFontColor};
  font-family: ${(p) => p.$doxleFont.primaryFont};
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.07rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
`;
export const StyledHeaderButton = styled.span<{
  backgroundColor?: string;
  doxleFont: DoxleFont;
  color?: string;
}>`
  min-width: 72px;
  height: 23px;
  border-radius: 3px;
  border: 1px solid #e4e4ec;
  background-color: ${(p) => p.backgroundColor ?? "#FFF"};
  color: ${(p) => p.color ?? "#000"};
  font-family: ${(p) => p.doxleFont.primaryFont};
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.6px;
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: center;
  padding: 0 3px 0 7px;
  margin-left: 6px;
  cursor: pointer;
`;

export const StyledQuotesResponseHeader = styled.div<{
  $doxleFont: DoxleFont;
  $themeColor: DoxleThemeColor;
}>`
  font-family: ${(p) => p.$doxleFont.primaryFont};
  height: 1.9rem;
  font-size: 1.6rem;
  font-weight: 600;
  letter-spacing: 0.08rem;
  border-bottom: 1px solid ${(p) => p.$themeColor.primaryDividerColor};
  margin-bottom: 3rem;
  padding-bottom: 2px;
`;
export const StyledQuoteTableScroller = styled.div`
  width: 100%;
  height: 100%;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;
export const StyledQuoteTableBody = styled(TableBody)`
  && {
    width: 100% !important;
    min-width: 40rem !important;
  }
`;

export const StyledQuoteTableHead = styled(TableHead)`
  && {
    width: 100% !important;
  }
`;

export const StyledQuoteTableRow = styled((props: TableRowProps) => (
  <TableRow {...props} />
))`
  && {
    padding-bottom: 1px;
    width: 100%;
    display: flex;
    align-items: center;
  }
`;
export const StyledQuoteTableRowContainer = styled(TableCell)<{
  $doxleFont: DoxleFont;
  $themeColor: DoxleThemeColor;
}>`
  && {
    width: calc(100% - 1.6rem);
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: ${(p) => p.$themeColor.primaryContainerColor};
    border-radius: 0px 0px 0px 0.9rem;
    padding: 0px 0.8rem !important;
    height: 4rem;
    cursor: pointer;
    transition: 0.4s;
  }
  &:hover {
    background-color: ${(p) => p.$themeColor.rowHoverColor} !important;
    transition: 0.4s;
  }
`;

export const StyledQuoteTableDataCell = styled.div<{
  $doxleFont: DoxleFont;
  $themeColor: DoxleThemeColor;
}>`
  margin: 0px 2px;
  color: ${(p) => p.$themeColor.primaryFontColor};
  font-family: ${(p) => p.$doxleFont.primaryFont};
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.07rem;
  display: flex;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 100%;
  align-items: center;
`;
export const StyledAttachmentPopperItem = styled.div<{
  $themeColor: DoxleThemeColor;
}>`
  width: calc(100% - 2.2rem);
  padding: 0px 1.1rem;
  border-bottom: 1px solid ${(p) => p.$themeColor.primaryDividerColor};
  display: flex;
  flex-direction: row;
  cursor: pointer;
  height: 3rem;
  align-items: center;
  .img-holder {
    height: 70%;
    aspect-ratio: 1;
    margin-right: 4px;
  }
  &:hover {
    background-color: ${(p) => p.$themeColor.rowHoverColor};
    transition: 0.4s;
  }
`;
export const StyledQuoteRequestsTableContainer = styled(motion.div)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;
export const StyledEmailQuoteDialog = styled(Dialog)<{
  $themeColor: DoxleThemeColor;
}>`
  && {
    background-color: ${(p) => p.$themeColor.primaryBackdropColor};
    box-shadow: 1px 1px 2px ${(p) => p.$themeColor.primaryBoxShadowColor};
  }
  & .MuiDialog-paper {
    background-color: ${(p) => p.$themeColor.primaryBackdropColor};
    box-shadow: 0px 0px 14px 0px ${(p) => p.$themeColor.primaryBoxShadowColor};
  }
`;

export const StyledEmailTitleDialog = styled(DialogTitle)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  && {
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-style: normal;
    font-weight: 600;
    font-size: 1.8rem;
    line-height: 2rem;

    color: ${(p) => p.$themeColor.primaryFontColor};
    border-bottom: 1px solid ${(p) => p.$themeColor.primaryDividerColor};
    background-color: ${(p) => p.$themeColor.primaryContainerColor};
    padding: 1.2rem 1.4rem !important;
  }
`;
export const StyledEmailDialogContent = styled(DialogContent)<{
  $themeColor: DoxleThemeColor;
}>`
  && {
    background-color: ${(p) => p.$themeColor.primaryContainerColor};
    box-shadow: 0px 0px 4px ${(p) => p.$themeColor.primaryBoxShadowColor};

    word-break: break-word;
    display: flex;
    flex-direction: column;
    overflow: auto;
  }
`;
export const StyledEmailDialogQuoteItem = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  && {
    margin-top: 1rem;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .supplier-text {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    h4 {
      font-family: ${(p) => p.$doxleFont.primaryFont};
      font-style: normal;
      font-weight: 600;
      font-size: 1.4rem;
      line-height: 1.6rem;
      letter-spacing: 0.05em;
      margin: 0px !important;
      color: ${(p) => p.$themeColor.primaryFontColor};
    }
    p {
      font-family: ${(p) => p.$doxleFont.primaryFont};
      font-style: normal;
      font-weight: 400;
      font-size: 1.4rem;
      line-height: 1.6rem;
      letter-spacing: 0.05em;
      color: ${(p) => p.$themeColor.primaryFontColor};
    }
  }

  .email-text {
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-style: normal;
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 1.4rem;
    letter-spacing: 0.05em;
    color: ${(p) => p.$themeColor.primaryFontColor};
    margin-top: 4px;
  }
`;
export const StyledEmailButtonSection = styled(DialogActions)<{
  $themeColor: DoxleThemeColor;
}>`
  && {
    background-color: ${(p) => p.$themeColor.primaryContainerColor};
    box-shadow: 1px 1px 2px ${(p) => p.$themeColor.primaryBoxShadowColor};
  }
`;

export const StyledEmailDialogButton = styled(motion(Button))<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
  $bgColor: string;
  $textColor: string;
}>`
  && {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 0.4rem !important;
    .button-text {
      font-family: ${(p) => p.$doxleFont.primaryFont};
      font-style: normal;
      font-weight: 400;
      font-size: 1.2rem;
      line-height: 1.6rem;
      letter-spacing: 0.05em;
      color: ${(p) => p.$textColor};
    }

    background-color: ${(p) => p.$bgColor};
    margin: 0px 0.4rem;
    padding: 0.4rem 0.8rem !important;
    text-transform: capitalize;
    &:hover {
      background-color: ${(p) => p.$bgColor} !important;
      opacity: 0.9;
      transform: translateY(-1px) scale(1.05);
      transition: 0.4s;
    }
  }
`;

export const StyledQuoteAutocompleteTextField = styled(TextField)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  && {
    padding-top: 0px !important;
    padding-bottom: 0px !important;

    overflow: hidden;
  }
  input {
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-weight: 500;
    font-size: 1.4rem;
    line-height: normal;
    color: ${(p) => p.$themeColor.primaryFontColor};

    &::placeholder {
      font-family: ${(p) => p.$doxleFont.primaryFont};
      font-weight: 500;
      font-size: 1.4rem;
      line-height: normal;
      color: ${(p) => p.$themeColor.primaryInputPlaceholderColor};
      opacity: 1;
    }
  }
  & .MuiInput-root:after {
    border-bottom: none !important;
  }
  & .MuiInput-root:before {
    border-bottom: none !important;
  }
  & .MuiInput-root {
    border-bottom: none !important;
  }
  & .MuiInput-root:hover:not(.Mui-disabled, .Mui-error):before {
    border-bottom: none !important;
  }
  & .MuiInputBase-input {
    overflow: hidden;
    text-overflow: ellipsis;
  }
  & .MuiInputBase-root {
    height: 100%;
    width: 100%;
  }
`;

export const StyledAutocompleteListItem = styled.li<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
  $selected: boolean;
}>`
  margin-bottom: 0.4rem;
  font-size: 1.4rem;
  font-weight: 400;
  color: ${(p) =>
    p.$selected ? p.$themeColor.doxleColor : p.$themeColor.primaryFontColor};
  background: transparent;

  &:hover {
    background: ${(p) => p.$themeColor.hoverColor};
    font-width: 500;
    transition: 0.2s;
  }
`;
