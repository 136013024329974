import React, { useMemo } from "react";
import { useDoxleAuthStore } from "../../DoxleGeneralStore/useDoxleAuthStore";
import { shallow } from "zustand/shallow";
import { useDoxleCurrentContextStore } from "../../DoxleGeneralStore/useDoxleCurrentContext";
import useDoxleNotificationStore from "../../DoxleGeneralStore/useDoxleNotificationStore";
import PaymentClaimAPI from "../QueryHooks/PaymentClaimAPI";
import { useEditDocketSideScreenStore } from "../../CoreContent/EditDocketSideScreen/EditDocketSideScreenStore/useEditDocketSideScreenStore";
import PaymentClaim from "../Models/PaymentClaim";
import {Docket} from "../../Models/dockets";

type Props = {};

interface GetPaymentClaims {
  docket: Docket|undefined;
  claimList: PaymentClaim[];
  totalClaims: number;
  isFetchingClaimList: boolean;
  isSuccessFetchingClaimList: boolean;
  isErrorFetchingClaimList: boolean;
  handleFetchNextPageClaimList: () => void;
  isFetchingNExtPageClaimList: boolean;
}
const useGetPaymentClaims = (props: Props): GetPaymentClaims => {
  
  const company = useDoxleCurrentContextStore(
    (state) => state.currentCompany,
    shallow
  );
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );
  const { docket } = useEditDocketSideScreenStore(
    (state) => ({
      docket: state.edittedDocket,
    }),
    shallow
  );
  const paymentClaimQuery = PaymentClaimAPI.useRetrieveList({

    company,
    showNotification,
    filter: docket?.docketPk ? { dockets: docket.docketPk, projects: docket?.project } : {},
  });
  const claimList: PaymentClaim[] = useMemo(
    () =>
      paymentClaimQuery.isSuccess
        ? paymentClaimQuery.data.pages.reduce((acc, data) => {
            return acc.concat(data.data.results);
          }, [] as PaymentClaim[])
        : [],
    [paymentClaimQuery.data]
  );
  let totalClaims = 0
  claimList.forEach(claim => totalClaims += Number(claim?.filteredSubTotal ?? 0))
  const handleFetchNextPageClaimList = () => {
    if (paymentClaimQuery.hasNextPage) paymentClaimQuery.fetchNextPage();
  };
  return {
    docket,
    claimList,
    totalClaims,
    isFetchingClaimList: paymentClaimQuery.isLoading,
    isSuccessFetchingClaimList: paymentClaimQuery.isSuccess,
    isErrorFetchingClaimList: paymentClaimQuery.isError,
    handleFetchNextPageClaimList,
    isFetchingNExtPageClaimList: paymentClaimQuery.isFetchingNextPage,
  };
};

export default useGetPaymentClaims;
