import { motion } from "framer-motion";
import styled from "styled-components";

import { Button, Paper, PaperProps, TextField } from "@mui/material";
import { MentionsInput } from "react-mentions";
import {
  DoxleFont,
  DoxleThemeColor,
} from "../../DoxleGeneralStore/useDoxleThemeStore";
import { TRgbaFormat, editRgbaAlpha } from "../../Utilities/FunctionUtilities";
export const StyledEditDocketSideScreenContainer = styled(motion.div)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  width: 100%;
  height: 100%;
  background-color: ${(p) => p.$themeColor.primaryBackgroundColor};
  box-shadow: 0px -2px 8px 2px ${(p) => p.$themeColor.primaryBoxShadowColor};
  display: flex;
  flex-direction: column;
  overflow: hidden !important;
`;
export const StyledEditDocketTopMenuContainer = styled(motion.div)`
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  display: flex;
  flex-direction: row;
`;
export const StyledModifiedTimeText = styled.span<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  color: ${(p) =>
    editRgbaAlpha({
      rgbaColor: p.$themeColor.primaryFontColor as TRgbaFormat,
      alpha: "0.3",
    })};
  font-size: 1.4rem;
  font-family: ${(p) => p.$doxleFont.primaryTitleFont};
  font-style: normal;
  font-weight: 600;

  letter-spacing: -0.032rem;
  text-transform: none;
`;
export const StyledEditDocketGeneralInfoSectionContiner = styled(motion.div)`
  width: calc(100%);

  display: flex;
  flex-direction: column;
  margin-bottom: 3rem;
`;
export const StyledBudgetStatusDot = styled.div<{ $statusColor: string }>`
  width: 7px;
  height: 7px;
  border-radius: 7px;
  background-color: ${(p) => p.$statusColor};
`;

export const StyledDocketDetailSection = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  display: flex;
  width: calc(100% - 90px);
  align-items: center;
  padding: 0px 45px;
  height: 50px;
  background-color: ${(p) => p.theme.staticMenuColor.staticBg};
  .docket-name-status-section {
    flex: 1;
    display: flex;
    align-items: center;
    overflow: hidden;
    text-transform: capitalize;
    padding-right: 14px;
    .docket-title {
      color: ${(p) => p.theme.staticMenuColor.staticFontColor};
      font-size: 16px;

      font-style: normal;
      font-weight: 500;
      font-family: ${(p) => p.$doxleFont.subTitleFont};
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      flex-shrink: 1;
      .faded-text {
        color: ${(p) =>
          editRgbaAlpha({
            rgbaColor: p.theme.staticMenuColor.staticFontColor,
            alpha: "0.7",
          })};
      }
    }
  }

  .right-side-menu {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 8px;
    height: 100%;
  }
`;

export const StyledEditDocketLabelWithIconContainer = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  width: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 110px;
  max-width: 144px;
  p {
    flex: 1;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: ${(p) => p.$themeColor.primaryFontColor};
    font-size: 12px;
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.24px;
    text-transform: capitalize;
  }
`;

export const StyledEditDocketInfoField = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  width: 100%;
  display: flex;

  align-items: center;

  span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: ${(p) => p.$themeColor.primaryFontColor};
    font-size: 12px;
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.24px;
    text-transform: capitalize;
  }
`;
export const StyledEditDocketFunctionMenuContainer = styled.div<{
  $themeColor: DoxleThemeColor;
}>`
  margin-top: 30px;
  width: calc(100% - 28px);
  padding: 0px 14px 0px 14px;
  display: flex;
  height: 28px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${(p) => p.$themeColor.primaryDividerColor};
`;
export const StyledEditDocketFunctionMenuButtonContainer = styled(motion.div)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  display: flex;
  flex-direction: row;
  min-width: 80px;
  height: calc(100% - 1px);
  padding-bottom: 1px;
  justify-content: center;
  overflow-y: visible;
  position: relative;
  align-items: center;
  margin: 0px 4px;
  cursor: pointer;
  span {
    color: ${(p) => p.$themeColor.primaryFontColor};
    font-size: 1.4rem;
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-style: normal;

    line-height: 118.9%;
    text-transform: capitalize;
  }
`;
export const StyledMenuContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  padding: 0px 14px 0px 0px;
  height: 100%;
  overflow-x: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;
export const StyledFocusMenuIndicator = styled(motion.div)<{
  $themeColor: DoxleThemeColor;
}>`
  position: absolute;
  bottom: 0px;
  height: 1px;
  width: 100%;
  left: 0;
  z-index: 10;
  background-color: ${(p) => p.$themeColor.primaryFontColor};
`;
export const StyledAddDocketFunctionMenu = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  display: flex;
  flex-direciton: row;
  align-items: center;
  width: 67px;
  height: 26px;
  border-radius: 3px;
  background-color: ${(p) => p.$themeColor.doxleColor};
  cursor: pointer;
  span {
    color: ${(p) => p.$themeColor.primaryReverseFontColor};
    font-size: 14px;
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-style: normal;
    font-weight: 600;
    line-height: 118.9%;
    flex: 1;
    text-transform: capitalize;
    text-align: center;
  }
  &:hover {
    opacity: 0.9;
    transition: 0.4s;
  }
`;

export const StyledEditDocketHalfScreenContentSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: calc(100% - 2rem);
  overflow: hidden;
  padding: 0px 1rem;
`;
export const StyledCommentDisplaySectionContainer = styled(motion.div)<{
  $themeColor: DoxleThemeColor;
}>`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
  padding-bottom: 8px;
  .comment-list-wrapper {
    flex: 1;
    display: flex;
    width: 100%;
    padding-bottom: 1.4rem;
    position: relative;
  }
`;

export const StyledCommentInputSection = styled.div`
  width: 100%;
`;

export const StyledAddProjectDocketContainer = styled(motion.div)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  width: 92px;
  height: 20px;
  margin-left: 50px;
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  padding: 0px 4px;
  align-items: center;
  cursor: pointer;
  background-color: ${(p) => p.$themeColor.hoverColor};
  position: relative;
  overflow: visible;
  span {
    color: ${(p) => p.$themeColor.primaryFontColor};
    font-size: 12px;
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.6px;
    text-transform: capitalize;
  }
`;

export const StyledAddProjectTextField = styled(motion(TextField))<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  && {
    width: 230px;
    height: 20px;
    margin-top: 20px;
    margin-left: 40px;
    border-bottom: 1px solid ${(p) => p.$themeColor.primaryFontColor};

    padding-left: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  input {
    color: ${(p) => p.$themeColor.primaryFontColor};
    font-size: 12px;
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.6px;
    &::placeholder {
      color: ${(p) => p.$themeColor.primaryInputPlaceholderColor};
      font-size: 12px;
      font-family: ${(p) => p.$doxleFont.primaryFont};
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.6px;
    }
  }
  & .MuiOutlinedInput-input {
    padding: 0px !important;
    width: 100%;
  }
  & .MuiOutlinedInput-notchedOutline {
    border: none !important;
    width: 100%;
  }
  & .MuiInputBase-root {
    width: 100% !important;
    padding-right: 0px !important;
  }
`;
export const StyledSelectProjectLabelText = styled.span<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  color: ${(p) => p.$themeColor.bolderDividerColor};
  font-size: 12px;
  font-family: ${(p) => p.$doxleFont.primaryFont};
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.6px;
  margin-bottom: 4px;
  margin-left: 4px;
`;
export const StyledCommentTextField = styled(MentionsInput)<{}>`
  textarea:focus,
  input:focus {
    outline: none;
  }
  input {
    border: none !important;
    border-width: 0px;

    max-height: 144px;
    min-height: 20px;
  }
  textarea {
    border: none !important;
    border-width: 0px;
    height: fit-content;
    max-height: 144px;
    min-height: 20px;
  }
`;

export const StyledCommentListContainer = styled.div`
  width: 100%;
  display: flex;
`;
export const StyledDocketInfoContainer = styled(motion.div)`
  width: calc(100%);
  margin: 14px 0px;
  padding: 0px;
  flex-direction: column;
  height: calc(100% - 28px);
  min-width: 480px;

  display: flex;
`;

export const StyledDiscussionMention = styled(motion.div)<{
  $themeColor: DoxleThemeColor;
}>`
  height: 204px;
  width: 200px;
  position: relative;
  z-index: 10;
  background-color: ${(p) => p.$themeColor.primaryContainerColor};
  top: 0px;
  overflow-y: scroll;
  border-radius: 4px 4px 0px 0px;
  box-shadow: 0px 0px 4px 4px ${(p) => p.$themeColor.primaryBoxShadowColor};
  border: 1px solid ${(p) => p.$themeColor.primaryDividerColor};
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${(p) => p.$themeColor.primaryFontColor};
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

export const StyledRemoveAddedFileBtnContainer = styled.div`
  position: absolute;
  top: -10px;
  right: -5px;
  z-index: 10;
`;

export const StyledDiscussionAttachmentDisplayerContainer = styled.div<{}>`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 4px;
  width: calc(100%);
  overflow: hidden;
  padding: 0px;
  border-radius: 5px;
  cursor: pointer;
  .attachment-list-wrapper {
    max-width: 80%;
    display: flex;
    flex-direction: row;
    margin-bottom: 1.4rem;
    flex-wrap: wrap;
    border-radius: 0px 0.9rem;
  }
`;

export const StyledAttachmentImageQuantity = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 10;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(p) => p.$themeColor.primaryBackdropColor};
  color: ${(p) => p.$themeColor.primaryFontColor};
  font-size: 16px;
  font-family: ${(p) => p.$doxleFont.secondaryTitleFont};
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  @media (max-width: 767px) {
    font-size: 12px;
  }
  @media (min-width: 768px) {
    font-size: 12px;
  }
  @media (min-width: 1024px) {
    font-size: 14px;
  }
  @media (min-width: 1280px) {
    font-size: 16px;
  }
`;

export const StyledProjectFetchingLoadingContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledProjectFetchingLoadingText = styled(motion.span)<{
  $doxleFont: DoxleFont;
}>`
  font-size: 16px;
  font-family: ${(p) => p.$doxleFont.primaryFont};
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  @media (max-width: 767px) {
    font-size: 12px;
  }
  @media (min-width: 768px) {
    font-size: 12px;
  }
  @media (min-width: 1024px) {
    font-size: 14px;
  }
  @media (min-width: 1280px) {
    font-size: 16px;
  }
`;

export const StyledEditDocketFullScreenModeContainer = styled(motion.div)<{
  $themeColor: DoxleThemeColor;
}>`
  width: 100%;
  height: 100%;
  background-color: ${(p) => p.$themeColor.primaryBackgroundColor};
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  min-width: 480px;
`;
export const StyledInitialGreetingScreen = styled(motion.div)<{
  $themeColor: DoxleThemeColor;
}>`
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  z-index: 10;
  background-color: ${(p) => p.$themeColor.primaryBackgroundColor};
`;
export const StyledInitialGreetingScreenHalfContainer = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  width: calc(50% - 40px);
  height: calc(100% - 40px);
  padding: 20px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  display: flex;
  span {
    color: ${(p) => p.$themeColor.primaryFontColor};
    font-size: 27px;
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    letter-spacing: 1.35px;
    text-align: left;
    text-transform: none;
    p {
      text-transform: none;
      color: ${(p) =>
        editRgbaAlpha({
          rgbaColor: p.$themeColor.primaryFontColor as TRgbaFormat,
          alpha: "0.6",
        })};
      font-size: 16px;
      font-family: ${(p) => p.$doxleFont.secondaryTitleFont};
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.8px;
      text-align: left;
    }
  }
`;
export const StyledInitialGreetingScreenImage = styled.img`
  width: 668px;
  height: 629.36px;

  @media (max-width: 767px) {
    width: 100%;
    height: auto;
  }
  @media (min-width: 768px) {
    width: 100%;
    height: auto;
  }
  @media (min-width: 1024px) {
    width: 628px;
    height: 589.36px;
  }
  @media (min-width: 1280px) {
    width: 628px;
    height: 589.36px;

    width: 668px;
    height: 629.36px;
  }
`;

export const StyledTopGeneralInfoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledDocketNameSection = styled.div<{
  $doxleFont: DoxleFont;
  $themeColor: DoxleThemeColor;
}>`
  display: flex;
  align-items: center;
  width: 100%;
  background-color: ${(p) => p.$themeColor.primaryContainerColor};

  span {
    color: ${(p) => p.$themeColor.primaryFontColor};
    font-size: 16px;
    font-family: ${(p) => p.$doxleFont.secondaryFont};
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.8px;
    text-transform: uppercase;
  }
`;

export const StyledDocketNameText = styled.span<{
  $doxleFont: DoxleFont;
  $themeColor: DoxleThemeColor;
}>`
  color: ${(p) => p.$themeColor.primaryFontColor};
  font-size: 2.7rem;
  font-family: ${(p) => p.$doxleFont.primaryFont};
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  letter-spacing: 1.35px;
  text-transform: capitalize;
  margin-top: 0.8rem;
`;
export const StyledFullScreenFunctionMenuButton = styled(motion.div)<{
  $doxleFont: DoxleFont;
  $themeColor: DoxleThemeColor;
  $selected: boolean;
}>`
  border-radius: 12px;
  margin: 0px 6px;
  padding: 1px 4px;
  position: relative;
  overflow: visible;
  align-items: center;
  display: flex;
  height: fit-content;
  white-space: nowrap;
  cursor: pointer;
  min-width: min-content;
  span {
    color: ${(p) => p.$themeColor.primaryFontColor};

    font-size: 14px;
    font-family: ${(p) => p.$doxleFont.subTitleFont};
    font-style: normal;
    font-weight: ${(p) => (p.$selected ? 600 : 400)};
    line-height: normal;
    z-index: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    z-index: 1;
  }
  &:hover {
    opacity: 0.9;
    ${(p) => (p.$selected ? "" : "transform: translateY(-2px)")};
    transition: 0.2s;
  }
  .selected-effect {
    position: absolute;
    background-color: ${(p) => p.$themeColor.primaryFontColor};

    bottom: -5px;
    height: 1px;
    left: 0px;
    right: 0px;
    z-index: 0;
  }
`;
export const StyledSelectedMenuMaskEffect = styled(motion.div)<{
  $themeColor: DoxleThemeColor;
}>`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
  background-color: ${(p) => p.$themeColor.doxleColor};
  top: 0;
  left: 0;
  border-radius: 1.3rem;
`;

export const StyledFunctionMenuCollapseButton = styled(motion.div)<{
  $doxleFont: DoxleFont;
  $themeColor: DoxleThemeColor;
}>`
  border-radius: 12px;
  margin: 0px 4px;
  padding: 4px 12px;
  position: relative;
  align-items: center;
  display: flex;
  height: fit-content;
  cursor: pointer;
  font-family: ${(p) => p.$doxleFont.subTitleFont};
  color: ${(p) => p.$themeColor.primaryReverseFontColor};
  background-color: ${(p) => p.$themeColor.primaryFontColor};
  font-size: max(1.4rem, 14px);

  &:hover {
    opacity: 0.9;
    transform: translateY(-2px);
    transition: 0.2s;
  }
`;

export const StyledMenuButtonSectionContainer = styled.div`
  width: calc(100% - 120px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  padding: 0px 60px;
`;
export const StyledEditDocketFullScreenContentSectionContainer = styled.div`
  flex: 1;
  width: calc(100% - 40px);
  display: flex;
  overflow: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
  margin: 0px 20px;

  max-width: 1280px;
`;

export const StyledMenuButtonWrapper = styled(motion.div)`
  display: flex;
  align-items: center;
`;

export const StyledEditDescriptionField = styled(motion(TextField))<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  && {
    width: 230px;
    height: 20px;
    margin-top: 20px;
    margin-left: 40px;
    border-bottom: 1px solid ${(p) => p.$themeColor.primaryFontColor};

    padding-left: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  input {
    color: ${(p) => p.$themeColor.primaryFontColor};
    font-size: 12px;
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.6px;
    &::placeholder {
      color: ${(p) => p.$themeColor.primaryInputPlaceholderColor};
      font-size: 12px;
      font-family: ${(p) => p.$doxleFont.primaryFont};
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.6px;
    }
  }
  & .MuiOutlinedInput-input {
    padding: 0px !important;
    width: 100%;
  }
  & .MuiOutlinedInput-notchedOutline {
    border: none !important;
    width: 100%;
  }
  & .MuiInputBase-root {
    width: 100% !important;
    padding-right: 0px !important;
  }
`;
export const StyledDocketFunctionMenuSection = styled(motion.div)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  height: 30px !important;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
  display: flex;
  flex: 1;
  align-items: center;
  flex-wrap: nowrap;
  overflow-x: hidden;
`;
export const StyledCustomiseFunctionalMenu = styled.div<{
  $themeColor: DoxleThemeColor;
}>`
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;

  padding-right: 14px;
`;
export const StyledCustomiseFunctionCloseBtn = styled(motion(Button))<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  && {
    min-width: 0 !important;
    color: ${(p) => p.$themeColor.primaryContainerColor};
    font-size: 1.2rem;
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.06rem;
    padding: 0.4rem 0.8rem !important;
    border-radius: 0.4rem !important;
    margin-right: 0.8rem;
    background-color: ${(p) => p.$themeColor.primaryFontColor};
    box-shadow: 0px 0px 0.4rem 0px ${(p) => p.$themeColor.primaryBoxShadowColor};
    text-transform: capitalize;
  }
  &:hover {
    background-color: ${(p) => p.$themeColor.primaryFontColor} !important;
    opacity: 0.8;
  }
`;

export const StyledCustomiseQuotesFunctionBtn = styled(motion(Button))<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  && {
    display: flex;
    align-items: center;
    min-width: 0 !important;
    color: ${(p) => p.$themeColor.primaryFontColor};
    font-size: 1.2rem;
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.06rem;
    padding: 0.4rem 0.8rem !important;
    border-radius: 0.4rem !important;
    margin-right: 0.8rem;
    background-color: ${(p) => p.$themeColor.primaryContainerColor};
    box-shadow: 0px 0px 0.4rem 0px ${(p) => p.$themeColor.primaryBoxShadowColor};
    text-transform: capitalize;
    border: 1px solid ${(p) => p.$themeColor.rowBorderColor};
    &:hover {
      background-color: ${(p) =>
        editRgbaAlpha({
          rgbaColor: p.$themeColor.primaryContainerColor,
          alpha: "0.2",
        })} !important;
      transform: scale(0.95);
      transition: 0.4s;
    }
  }
`;

export const StyledCustomiseOrderFunctionBtn = styled(motion(Button))`
  && {
    display: flex;
    align-items: center;
    color: ${(p) => p.theme.color.primaryFontColor};
    font-size: 13px;
    font-family: ${(p) => p.theme.font.primaryFont};
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.6px;
    padding: 4px 8ox !important;
    border-radius: 4px !important;
    margin-right: 8px;
    background-color: ${(p) => p.theme.color.primaryContainerColor};
    height: 25px;
    position: relative;
    bottom: 5px;
    min-width: 88px !important;
    border: 1px solid ${(p) => p.theme.color.primaryDividerColor};
    text-transform: capitalize;
    border-radius: 4px !important;
    margin: 0px 2px;
    transition: all 0.2s ease;
    &:hover {
      background-color: ${(p) =>
        editRgbaAlpha({
          rgbaColor: p.theme.color.primaryFontColor,
          alpha: "0.7",
        })} !important;
      transform: scale(0.95);

      color: ${(p) => p.theme.color.primaryContainerColor};
    }
  }
`;

export const StyledDocketStatusDisplayer = styled(motion.div)<{
  $statusColor: string;
  $statusSize?: number;
}>`
  color: ${(p) => p.theme.color.primaryFontColor};
  white-space: nowrap;
  font-family: ${(p) => p.theme.font.primaryFont};
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  padding: 10px 14px;
  border-radius: 4px;
  display: flex;
  align-self: flex-start;
  align-items: center;
  text-transform: capitalize;
  cursor: pointer;
  min-width: 140px;
  max-width: calc(100% - 28px);
  justify-content: space-between;
  border: 1px solid ${(p) => p.theme.color.primaryDividerColor};
  .status-name-wrapper {
    display: flex;
    align-items: center;
  }
  .status-dot {
    width: ${(p) => p.$statusSize ?? 10}px;
    height: ${(p) => p.$statusSize ?? 10}px;
    border-radius: ${(p) => (p.$statusSize ? p.$statusSize / 3 : 10 / 3)}px;
    border: 2px solid ${(p) => p.$statusColor};
    flex-shrink: 0;

    margin-right: 8px;
  }

  .icon-dropdown {
    margin-left: 4px;
    transform: translateY(0) rotate(0);
    transition: transform 0.3s ease; /* Smooth transition for hover effect */
  }

  &:hover .icon-dropdown {
    animation: hoverAnimation 0.3s ease forwards;
  }

  @keyframes hoverAnimation {
    0%,
    100% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-3px);
    }
  }
`;

export const StyledEditDocketMenuWrapper = styled(motion.div)<{
  $themeColor: DoxleThemeColor;
}>`
  display: flex;

  justify-content: space-between;
  width: calc(100% - 40px - 16px);
  align-items: center;
  margin: 0px 20px;
  margin-top: 14px;
  padding: 0px 4px;
  max-width: 1280px;

  overflow: hidden;
  border-bottom: 1px solid ${(p) => p.theme.color.primaryDividerColor};
`;
interface IDocketInfoContainerProps
  extends Omit<
    PaperProps,
    "onDragStart" | "onAnimationStart" | "onDragEnd" | "onDrag"
  > {
  $themeColor: DoxleThemeColor;
}
const AnimatedPaper = motion(Paper);
const projectListAnimatedVariants = {
  entering: {
    y: [-10, 0],
    opacity: [0, 1],
    transition: {
      duration: 0.4,
    },
  },
  exiting: {
    y: [0, -10],
    opacity: [1, 0],
    transition: {
      duration: 0.4,
    },
  },
};
export const StyledDocketInfoPopover = styled(
  ({ $themeColor, ...rest }: IDocketInfoContainerProps) => (
    <AnimatedPaper
      {...rest}
      variants={projectListAnimatedVariants}
      animate="entering"
      exit="exiting"
      initial="initial"
      layout
      elevation={4}
    />
  )
)`
  && {
    background-color: ${(p) => p.$themeColor.primaryContainerColor};
    width: max(33rem, 330px);
    height: max(19rem, 190px);
    border-radius: 12px 0px 12px 12px;
    position: relative;
    padding: 1rem;
    box-shadow: 0px 0px 8px 0px ${(p) => p.$themeColor.primaryBoxShadowColor};
    display: flex;
    border: 1px solid ${(p) => p.$themeColor.primaryDividerColor};
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;
export const StyledDocketInfoTitleText = styled.span<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  color: ${(p) => p.$themeColor.primaryFontColor};
  font-family: ${(p) => p.$doxleFont.primaryFont};
  font-size: max(1.4rem, 14px);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 1rem;
  text-transform: capitalize;

  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 14px;
  width: 100%;
`;

export const StyledIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 41px;
  height: 100%;
  padding-left: 2px;
  padding-right: 2px;
  &:hover {
    background-color: ${(p) => p.theme.staticMenuColor.staticHover};
  }
`;

export const StyledDocketShareUserDisplay = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  cursor: pointer;
  .plus-text {
    font-size: 14px;
    font-family: ${(p) => p.theme.font.primaryFont};
    font-style: normal;
    font-weight: 500;
    color: ${(p) => p.theme.staticMenuColor.staticFontColor};
    margin-left: 4px;
  }
`;
export const StyledDocketShareUserItem = styled(motion.div)<{
  $bgColor: string;
  $size?: number;
}>`
  font-size: ${(p) => (p.$size ? p.$size / 2 : 12)}px;
  font-family: ${(p) => p.theme.font.primaryFont};
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #000;
  width: ${(p) => p.$size ?? 25}px;
  height: ${(p) => p.$size ?? 25}px;
  border-radius: 50%;
  background-color: ${(p) => p.$bgColor};
  margin: 0px 2px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
