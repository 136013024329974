import {ImportDialogOptions} from "./ProjectFilter";
import React from "react";
import ImportProjectDialog from "./ImportProjectDialog";
import ExportOptionDialog from "../ShareProjectScreen/ExportOptionDialog";

interface Props {
  displayImportDialog: ImportDialogOptions
  setDisplayImportDialog:
    React.Dispatch<React.SetStateAction<ImportDialogOptions>>
}
const ImportExportDialogDisplayer = (props: Props) => {
  const {setDisplayImportDialog} = props
  if (props.displayImportDialog === 'export') return <ExportOptionDialog closeDialog={() => setDisplayImportDialog(false)}/>
  else if (props.displayImportDialog === 'import') return <ImportProjectDialog {...props} />
  else return null
}
export default ImportExportDialogDisplayer
