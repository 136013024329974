import React, { useRef } from "react";
import { useDoxleThemeStore } from "../../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import {
  StyledBookingHeaderText,
  StyledBookingTableHeaderCell,
  StyledBookingTableHeaderContainer,
} from "./StyledComponentBookingTable";
import { BOOKING_PROJECT_TABLE_FIELD } from "../../../store/useBookingProjectStore";
import useRefComponentDimension from "../../../../CoreContent/UtilityHooks/useRefComponentDimension";
import { useShallow } from "zustand/react/shallow";

type Props = { containerWidth: number };

const BookingProjectTableHeader = ({ containerWidth }: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    useShallow((state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }))
  );

  return (
    <StyledBookingTableHeaderContainer $themeColor={doxleThemeColor}>
      {BOOKING_PROJECT_TABLE_FIELD.map((header, idx) => {
        if (header === "Days" && containerWidth < 1100) return null;
        if (header === "Due" && containerWidth < 900) return null;
        return (
          <StyledBookingTableHeaderCell
            $themeColor={doxleThemeColor}
            $widthRatio={
              header === "Title"
                ? null
                : header === "Due" || header === "Days"
                ? "10%"
                : header === "Share"
                ? "5%"
                : header === "Progress"
                ? "15%"
                : `12%`
            }
            $horizontalAlign={header === "Title" ? "flex-start" : "center"}
            key={`header#${idx}`}
          >
            <StyledBookingHeaderText
              sx={{
                paddingLeft: header === "Title" ? "5rem" : 0,
              }}
            >
              {header !== "Share" ? header : ""}
            </StyledBookingHeaderText>
          </StyledBookingTableHeaderCell>
        );
      })}
    </StyledBookingTableHeaderContainer>
  );
};

export default BookingProjectTableHeader;
