import React from "react";
import { Project } from "../../Models/project";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import {
  StyledProjectItemSelectionContainer,
  StyledProjectTitle,
} from "./StyledComponentsProjectSelectWithDropdown";

type Props = {
  projectItem: Project;
  selectedProject?: Project | undefined;
  handleSelectProject: (item: Project) => void;
};

const ProjectItemSelection = (props: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }),
    shallow
  );

  const rootContainerAnimatedVariants = {
    entering: {
      y: [-5, 0],
      opacity: [0, 1],
      transition: {
        delay: 0.15,
        duration: 0.2,
      },
    },
  };
  return (
    <StyledProjectItemSelectionContainer
      $themeColor={doxleThemeColor}
      $doxleFont={doxleFont}
      $selected={
        props.selectedProject
          ? props.selectedProject.projectId === props.projectItem.projectId
            ? true
            : false
          : false
      }
      variants={rootContainerAnimatedVariants}
      initial={false}
      animate="entering"
      onClick={(event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();

        props.handleSelectProject(props.projectItem);
      }}
    >
      <StyledProjectTitle $themeColor={doxleThemeColor} $doxleFont={doxleFont}>
        {props.projectItem.siteAddress}
      </StyledProjectTitle>
    </StyledProjectItemSelectionContainer>
  );
};

export default React.memo(ProjectItemSelection);
