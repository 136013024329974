export type TEstimatesTableHeaders =
  |"#"
  |"Description"
  |"Qty"
  |"Item Cost"
  |"Unit"
  |"Line Cost"
  |"Docket"
  |"Allowance"


export interface IEstimatesTableHeaders {
  headerName: TEstimatesTableHeaders;
  isDisplayed: boolean;
}

export const CONTACTS_TABLE_HEADER_LIST: IEstimatesTableHeaders[] = [
  {
    headerName: "#",
    isDisplayed: true,
  },
  {
    headerName: "Description",
    isDisplayed: true,
  },
  {
    headerName: "Qty",
    isDisplayed: true,
  },
  {
    headerName: "Unit",
    isDisplayed: true,
  },
  {
    headerName: "Item Cost",
    isDisplayed: true,
  },
  {
    headerName: "Line Cost",
    isDisplayed: false,
  },
  {
    headerName: "Docket",
    isDisplayed: false,
  },
];

export type TQuickEstimatesTableHeaders =
  |"#"
  |"Description"
  |"Qty"
  |"Item Cost"
  |"Unit"
  |"Line Cost"
  |"Docket"
  |"Allowance"


export interface IQuickEstimatesTableHeaders {
  headerName: TQuickEstimatesTableHeaders;
  isDisplayed: boolean;
}


export const QUICK_ESTIMATE_TABLE_HEADER_LIST: IQuickEstimatesTableHeaders[] = [
  {
    headerName: "#",
    isDisplayed: true,
  },
  {
    headerName: "Description",
    isDisplayed: true,
  },
  {
    headerName: "Qty",
    isDisplayed: true,
  },
  {
    headerName: "Unit",
    isDisplayed: true,
  },
  {
    headerName: "Item Cost",
    isDisplayed: true,
  },
  {
    headerName: "Line Cost",
    isDisplayed: true,
  },
  {
    headerName: "Docket",
    isDisplayed: false,
  },
  {
    headerName: "Allowance",
    isDisplayed: false,
  },
]