import { useQueryClient } from "@tanstack/react-query";

import { produce } from "immer";
import { QA, QAComment, QAWithFirstImg } from "../Models/qa";
import { useDoxleCurrentContextStore } from "../../DoxleGeneralStore/useDoxleCurrentContext";
import { shallow } from "zustand/shallow";
import {
  formQACommentQKey,
  formQAItemListQKey,
  PatchQAComment,
} from "../QueryAPI/qaQueryAPI";
import { InfiniteAxiosQueryData } from "../../Models/axiosReturn";

type Props = {
  qaItem?: QA;
};
interface SetQaCommentQueryData {
  handleAddQAComment: (addedComment: QAComment) => void;
  handleAddQACommentToDefectItemList: (addedComment: QAComment) => void;
  handleDeleteQAComment: (commentId: string) => void;
  handlePatchQAComment: (val: PatchQAComment) => void;
}
const useSetQaCommentQueryData = ({ qaItem }: Props): SetQaCommentQueryData => {
  const queryClient = useQueryClient();
  const { company } = useDoxleCurrentContextStore(
    (state) => ({
      company: state.currentCompany,
    }),
    shallow
  );
  const qKey = qaItem ? formQACommentQKey(qaItem, company) : ["qa-comment"];
  const listQKey = formQAItemListQKey(qaItem?.defectList ?? "", company);
  const handleAddQAComment = (addedComment: QAComment) => {
    const oldServerData = queryClient.getQueryData(qKey);
    if (oldServerData) {
      queryClient.setQueryData<InfiniteAxiosQueryData<QAComment>>(qKey, (old) =>
        old
          ? produce(old, (draftOld) => {
              draftOld.pages[0].data.results.push(addedComment);
            })
          : old
      );
    } else queryClient.invalidateQueries(qKey);
  };

  const handleAddQACommentToDefectItemList = (addedComment: QAComment) => {
    queryClient.setQueryData<InfiniteAxiosQueryData<QAWithFirstImg>>(
      listQKey,
      (old) => {
        if (old) {
          return {
            ...old,
            pages: old.pages.map((page) => {
              return {
                ...page,
                data: {
                  ...page.data,
                  results: [
                    ...page.data.results.map((item) => {
                      if (item.defectId === addedComment.defect) {
                        return { ...item, lastComment: addedComment };
                      } else {
                        return item;
                      }
                    }),
                  ],
                },
              };
            }),
          };
        }
        return old;
      }
    );
  };

  const handleDeleteQAComment = (commentId: string) => {
    const oldServerData = queryClient.getQueryData(qKey);
    if (oldServerData) {
      queryClient.setQueryData<InfiniteAxiosQueryData<QAComment>>(qKey, (old) =>
        old
          ? produce(old, (draftOld) => {
              const pageIdxMatch = draftOld.pages.findIndex((page) =>
                page.data.results.find((cmt) => cmt.commentId === commentId)
              );
              if (pageIdxMatch !== -1)
                draftOld.pages[pageIdxMatch].data.results = draftOld.pages[
                  pageIdxMatch
                ].data.results.filter((cmt) => cmt.commentId !== commentId);
            })
          : old
      );
    } else queryClient.invalidateQueries(qKey);
  };

  const handlePatchQAComment = ({
    commentId,
    commentText,
    isOfficial,
  }: PatchQAComment) => {
    const oldServerData = queryClient.getQueryData(qKey);
    if (oldServerData) {
      queryClient.setQueryData<InfiniteAxiosQueryData<QAComment>>(qKey, (old) =>
        old
          ? produce(old, (draftOld) => {
              let itemPos:
                | {
                    pageIdx: number;
                    itemIdx: number;
                  }
                | undefined = undefined;
              for (let i = 0; i < draftOld.pages.length; i++) {
                const matchItemIdx = draftOld.pages[i].data.results.findIndex(
                  (cmt) => cmt.commentId === commentId
                );
                if (matchItemIdx !== -1) {
                  itemPos = {
                    pageIdx: i,
                    itemIdx: matchItemIdx,
                  };
                  break;
                } else continue;
              }
              if (itemPos) {
                if (commentText !== undefined)
                  draftOld.pages[itemPos.pageIdx].data.results[
                    itemPos.itemIdx
                  ].commentText = commentText;
                if (isOfficial !== undefined)
                  draftOld.pages[itemPos.pageIdx].data.results[
                    itemPos.itemIdx
                  ].isOfficial = isOfficial;
              }
            })
          : old
      );
    } else queryClient.invalidateQueries(qKey);
  };

  return {
    handleAddQAComment,
    handleAddQACommentToDefectItemList,
    handleDeleteQAComment,
    handlePatchQAComment,
  };
};

export default useSetQaCommentQueryData;
