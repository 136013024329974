import {
  StyledQAEditFieldWrapper,
  StyledQaAssigneeDisplayer,
} from "./StyledComponentQAItemDetail";
import { useDoxleThemeStore } from "../../../../DoxleGeneralStore/useDoxleThemeStore";
import useEditQaRoom from "./Hooks/useEditQaRoom";
import CircularProgress from "@mui/material/CircularProgress";
import { useProjectQAStore } from "../../Store/useProjectQAStore";
import { RiCloseCircleFill } from "react-icons/ri";
import DoxleIconButton from "../../../../DoxleDesignPattern/DoxleButtons/DoxleIconButton";
import { useShallow } from "zustand/react/shallow";
import DoxleSelectLocationDropdown from "../../../../DoxleDesignPattern/DoxleSelectLocationDropdown/DoxleSelectLocationDropdown";
type Props = {};

const EditQaRoom = (props: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    useShallow((state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }))
  );
  const { selectedQAItem } = useProjectQAStore(
    useShallow((state) => ({
      selectedQAItem: state.selectedQAItem,
    }))
  );
  const {
    anchorRoomList,
    setAnchorRoomList,
    handleUpdateRoom,
    handleCloseRoomList,
    isUpdatingRoom,
    isHovering,
    setIsHovering,
  } = useEditQaRoom({});
  return (
    <StyledQAEditFieldWrapper className="field-wrapper">
      <span className="field-label-text">Room</span>
      <StyledQaAssigneeDisplayer
        onClick={(e) => setAnchorRoomList(e.currentTarget)}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
      >
        {selectedQAItem?.room ? (
          selectedQAItem?.roomName
        ) : (
          <span className="null-text">Select Room</span>
        )}

        {isUpdatingRoom ? (
          <CircularProgress
            style={{ color: doxleThemeColor.primaryFontColor }}
            size={14}
          />
        ) : (
          selectedQAItem?.room &&
          isHovering && (
            <DoxleIconButton
              iconSource={
                <RiCloseCircleFill
                  style={{ color: doxleThemeColor.primaryFontColor }}
                  size={20}
                />
              }
              onClick={(e) => {
                e.stopPropagation();
                handleUpdateRoom(undefined);
              }}
              buttonSize={18}
              buttonWrapperStyle={{
                padding: "0px !important",
                margin: "0px !important",
              }}
            />
          )
        )}
      </StyledQaAssigneeDisplayer>

      <DoxleSelectLocationDropdown
        popoverAnchor={anchorRoomList}
        onClosePopover={handleCloseRoomList}
        variant="room"
        selectedRoomId={selectedQAItem?.room ?? null}
        onSelectRoom={handleUpdateRoom}
        containerStyle={{
          borderRadius: 9,
        }}
        floorFilter={selectedQAItem?.floor ?? undefined}
      />
    </StyledQAEditFieldWrapper>
  );
};

export default EditQaRoom;
