import { create } from "zustand";
import { InventoryItem } from "../Models/Inventory";


export interface InventoryQueryFilterProp {
    search?: string;
}

interface InventoryStore {
    displayAddInventoryScreen: boolean;
    setDisplayAddInventoryScreen: (flag: boolean) => void;
    inventoryForDelete: InventoryItem | undefined;
    setInventoryForDelete: (item: InventoryItem | undefined) => void;
    currentEditInventory: InventoryItem | undefined;
    setCurrentEditInventory: (item: InventoryItem | undefined) => void;
    isDeletingItem: boolean;
    setIsDeletingItem: (flag: boolean) => void;
    imageIdForDelete: string | undefined;
    setImageIdForDelete: (id: string) => void;
    previewImageInventory: InventoryItem | undefined;
    setPreviewImageInventory: (item: InventoryItem | undefined) => void;

    filterInventoryQuery: InventoryQueryFilterProp;
    setFilterInventoryQuery: (item: InventoryQueryFilterProp) => void;
}

export const useInventoryStore = create<InventoryStore>((set, get) => ({
    displayAddInventoryScreen: false,
    inventoryForDelete: undefined,
    currentEditInventory: undefined,
    imageIdForDelete: undefined,
    isDeletingItem: false,
    previewImageInventory:undefined,
    setDisplayAddInventoryScreen: (flag: boolean) => {
        set({displayAddInventoryScreen: flag});
    },
    setInventoryForDelete: (item: InventoryItem | undefined) => {
        set({inventoryForDelete: item});
        if(item === undefined){
            set({isDeletingItem: false});
        }
    },
    setCurrentEditInventory: (item: InventoryItem | undefined) => {
        set({currentEditInventory: item});
    },
    setIsDeletingItem: (flag: boolean) => {
        set({isDeletingItem: flag});
    },
    setImageIdForDelete: (id: string) => {
        set({imageIdForDelete: id});
    },
    setPreviewImageInventory: (item: InventoryItem | undefined) => {
        set({previewImageInventory: item});
    },

    filterInventoryQuery: {},
    setFilterInventoryQuery: (
      filter: Partial<InventoryQueryFilterProp>
    ) => {
      const newFilter: InventoryQueryFilterProp = {
        ...get().filterInventoryQuery,
        ...filter,
      };
      set({ filterInventoryQuery: newFilter });
    },
}));