import { styled } from "styled-components";
import {
  DoxleFont,
  DoxleTheme,
  DoxleThemeColor,
} from "../../DoxleGeneralStore/useDoxleThemeStore";
import { FullMailThemeColor } from "../Store/mailStore";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { motion } from "framer-motion";
import { Popover, TextField } from "@mui/material";
import { editRgbaAlpha } from "../../Utilities/FunctionUtilities";

export const StyledMailRootContainer = styled.div<{
  $doxleFont?: DoxleFont;
  $themeColor?: FullMailThemeColor;
  $currentTheme?: DoxleTheme;
}>`
  background-color: ${(p) =>
    p?.$themeColor?.primaryBackgroundColor ?? "rgb(252, 252, 253)"};
  width: 100%;
  height: 100%;
`;
export const StyledMailHeader = styled.header<{
  $themeColor?: FullMailThemeColor;
}>`
  border-bottom: 1px solid
    ${(p) => p?.$themeColor?.rowBorderColor ?? "rgb(239, 240, 244)"};
  display: flex;
  align-items: center;
  padding: 3px 10px;
`;
export const StyledMailHeaderList = styled.ul`
  display: flex;
  align-items: center;
  margin: 0;
`;
export const StyledMailHeaderListItem = styled.li<{
  $themeColor?: FullMailThemeColor;
}>`
  display: block;
  padding: 0px 10px;
  a {
    font-size: 12px;
    font-weight: 600;
    text-decoration: none;
    color: ${(p) => p?.$themeColor?.primaryFontColor};
    padding: 3px 10px;
    line-height: 15px;
    letter-spacing: 1px;
    &.active {
      background-color: ${(p) => p?.$themeColor?.doxleColor};
      color: ${(p) => p?.$themeColor?.primaryReverseFontColor};
      border-radius: 13px;
      border: 1px solid ${(p) => p?.$themeColor?.primaryBackgroundColor};
    }
  }
`;
export const StyledDoxleMailScreenContainer = styled.div<{
  $themeColor?: FullMailThemeColor;
  $project?: boolean;
  $docket?: true;
}>`
  height: 100%;
  display: flex;
  padding-top: ${(p) => (p.$project ? 0 : "100px")};
  flex-direction: column;
  justify-content: center;
  position: relative;
  margin: 0px auto;
  ${(p) => (!(p.$project || p.$docket) ? "max-width: 1280px;" : "")}
`;
export const StyledMailHead = styled.div<{
  $themeColor?: FullMailThemeColor;
}>`
  font-size: 30px;
  font-weight: 600;
  line-height: 36px;
  padding-bottom: 10px;
  margin-bottom: 20px;
  color: ${(p) => p?.$themeColor?.primaryFontColor};
  display: flex;
  flex-direction: row;
  width: 100%;
  border-bottom: ${(p) => p?.$themeColor?.primaryDividerColor} 2px solid;
  justify-content: space-between;
  align-items: end;
  margin-top: 10px;
`;
export const StyledMailList = styled.ul<{
  $themeColor?: FullMailThemeColor;
}>`
  border-radius: 0px 12px 12px 12px;
  padding: 0;
  margin-top: 0px;
  border: 1px solid ${(p) => p?.$themeColor?.primaryBackgroundColor};
  height: calc(100vh - 250px);
  width: 100%;
`;

export const StyledMailTitleSection = styled.h3<{
  $themeColor?: FullMailThemeColor;
}>`
  font-size: 30px;
  font-weight: 600;
  line-height: 30px;
  color: ${(p) => p?.$themeColor?.primaryFontColor};
  display: flex;
  flex-direction: row;
  width: Calc(100% - 25px);
  // border-bottom: ${(p) => p?.$themeColor?.primaryDividerColor} 2px solid;
  justify-content: space-between;
  align-items: end;
  margin: 0;
`;

export const StyledMailListItem = styled.div<{
  $themeColor: FullMailThemeColor;
  $themeFont: DoxleFont;
  $currentTheme: DoxleTheme;
  $transparentMode: boolean;
}>`
  min-height: 73px;

  border-bottom: 1px solid ${(p) => p.$themeColor.primaryDividerColor};
  cursor: pointer;
  display: flex;
  padding-bottom: 5px;
  padding-top: 10px;
  font-family: ${(p) => p.$themeFont.secondaryTitleFont};
  &:hover {
    background-color: ${(p) =>
      editRgbaAlpha({ rgbaColor: p.$themeColor.doxleColor, alpha: "0.1" })};
  }
  @media (max-width: 500px) {
    display: block;
  }
  
  background-color: ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.$themeColor.primaryContainerColor,
        alpha: p.$transparentMode
          ? "0"
          : p.$currentTheme === "light"
          ? "0.3"
          : "0.5",
      })};
`;
export const StyledMailListHead = styled.div<{
  $themeColor?: FullMailThemeColor;
}>`
  display: flex;
  width: 16%;
  word-break: break-word;
  @media (max-width: 850px) {
    width: 20%;
    word-break: unset;
  }
  @media (max-width: 500px) {
    width: 100%;
    margin-bottom: 10px;
  }
`;
export const StyledMailListHeading = styled.div<{
  $themeColor?: FullMailThemeColor;
}>`
  font-size: 16px;
  font-weight: 700;
  padding-left: 10px;
  margin: 0px;
  line-height: 16px;
  width: calc(100% - 20px);
  color: ${(p) => p?.$themeColor?.primaryFontColor};
`;

export const StyledMailListInfo = styled.div<{
  $themeColor?: FullMailThemeColor;
}>`
  padding-left: 35px;
  width: 84%;
  @media (max-width: 850px) {
    width: 80%;
  }
  @media (max-width: 500px) {
    width: 100%;
    padding-left: 0px;
  }
`;
export const StyledMailListInfoLabel = styled.div<{
  $themeColor?: FullMailThemeColor;
}>`
  font-size: 12px;
  font-weight: 600;
  color: ${(p) => p?.$themeColor?.mailsToLabelColor};
  span {
    font-size: 14px;
    font-weight: 700;
    color: ${(p) => p?.$themeColor?.mailsNameLabelColor};
  }
`;
export const StyledMailListInfoPara = styled.div<{
  $themeColor?: FullMailThemeColor;
}>`
  display: flex;
  justify-content: space-between;
  p {
    color: ${(p) => p?.$themeColor?.primaryFontColor};
    font-size: 12px;
    line-height: 12.12px;
    margin: 5px 0px 0px;
    font-weight: 700;
    padding-right: 35px;
    word-wrap: break-word;
    @media (max-width: 500px) {
      width: 70%;
    }
  }
  label {
    font-size: 7px;
    font-weight: 400;
    display: block;
    color: ${(p) => p?.$themeColor?.primaryFontColor};
  }
`;
export const StyledMailListInfoPDF = styled.div<{
  $themeColor?: FullMailThemeColor;
}>`
  display: flex;
  align-items: center;
  @media (max-width: 500px) {
    width: 30%;
  }
  span {
    border: 1px solid ${(p) => p?.$themeColor?.mailsToLabelColor};
    border-radius: 50%;
    padding: 5px;
    color: ${(p) => p?.$themeColor?.primaryFontColor};
    margin-left: 5px;
    font-weight: 500;
    font-size: 11px;
  }
`;
export const StyledMailStatus = styled.div<{
  statuscolor?: string;
}>`
  width: 20px;
  height: 20px;
  box-shadow: inset 0px 0px 0px 4px ${(p) => p?.statuscolor};
  border-radius: 9px;
`;
export const StyledMailFormDatePickerView = styled(DesktopDatePicker)<{
  $themeColor: DoxleThemeColor;
}>`
  & .MuiDropzoneArea-root {
    height: 80px;
  }
`;

interface Coordinates {
  x: number;
  y: number;
}
export const StyledUserDropdownListContainer = styled(motion.div)<{
  $themeColor: DoxleThemeColor;
  $coordinates: null | Coordinates;
}>`
  height: calc(30vh - 28px);
  background-color: ${(p) => p.$themeColor.primaryContainerColor};
  box-shadow: 1px 4px 4px ${(p) => p.$themeColor.primaryBoxShadowColor};
  width: 8vw;
  border-radius: 8px;
  position: absolute;
  left: ${(p) => p.$coordinates?.x ?? 0};
  top: ${(p) => p.$coordinates?.y ?? 0};
  padding: 14px 0px;
  min-width: 180px;
  min-height: 200px;
  overflow: hidden;
  z-index: 50;
`;
export const StyledSearchContactTextField = styled(TextField)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  && {
    width: 100%;
    height: 30px;
    &:before {
      border-bottom: none;
    }
  }
  input {
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-weight: 500;
    font-size: 1.4rem;
    line-height: normal;
    letter-spacing: 0.0075em;

    color: ${(p) => p.$themeColor.primaryFontColor};
    padding-left: 8px;
  }
  & .MuiInput-root:after {
    border-bottom: none;
  }
  & .MuiInput-root {
    border-bottom: none;
  }
  & .MuiInput-root:hover {
    border-bottom: none;
  }
  & .MuiInput-root:before {
    border-bottom: none;
  }
  & .MuiInput-root:focus {
    border-bottom: none;
  }
`;
export const StyledContactListScroller = styled.div`
  flex-grow: 1;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

export const StyledUserListItem = styled(motion.div)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  cursor: pointer;
  width: calc(100% - 16px);
  height: 40px;
  font-family: ${(p) => p.$doxleFont.primaryFont};
  color: ${(p) => p.$themeColor.primaryFontColor};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.05em;
  text-transform: capitalize;
  padding: 0px 8px;
  align-items: center;

  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: flex;
  border-bottom: 1px solid ${(p) => p.$themeColor.primaryDividerColor};
`;

export const StyledMailSearchBar = styled(TextField)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  && {
    flex: 1;
    height: 3rem;
    border-radius: 0px 4px 4px 4px;
    background-color: ${(p) => p.$themeColor.primaryTextFieldBackgroundColor};
    //margin: 10px 8px 10px 0px;
    padding: 0px 8px;
    min-width: 300px;
    max-width: 45%;
  }
  input {
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 0px !important;
    color: ${(p) => p.$themeColor.primaryFontColor};

    &::placeholder {
      font-family: ${(p) => p.$doxleFont.primaryFont};
      font-size: 1.4rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      color: ${(p) => p.$themeColor.primaryInputPlaceholderColor};
    }
  }
  & .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }
  & .MuiInputBase-root {
    height: 100%;
  }
`;

export const StyledDocketMailHeader = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  font-size: 14px;
  font-weight: 500;
  line-height: 10px;
  padding-bottom: 10px;
  margin-top: -120px;
  color: ${(p) => p?.$themeColor?.primaryFontColor};
  display: flex;
  flex-direction: row;
  width: 100%;
  border-bottom: ${(p) => p?.$themeColor?.primaryDividerColor} 2px solid;
  justify-content: space-between;
  align-items: end;
`;
export const StyledMailListScroller = styled.div`
  width: 100%;
  height: 100% !important;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledAttachmentContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 5%;
  min-width: 40px;
  height: 100%;
`;

export const StyledMailMainContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 80%;
`;

export const StyledMailInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  white-space: nowrap;
`;

export const StyledName = styled.div<{
  $themeColor: DoxleThemeColor;
}>`
  color: ${(p) => p.$themeColor.doxleColor};
  font-size: max(1.4rem, 14px);
  width: 13rem;
  font-weight: 500;
  min-width: 80px;
  margin-right: 10px;
  overflow-x: hidden;
  text-overflow: ellipsis;
`;

export const StyledSubject = styled.div<{
  $themeColor: DoxleThemeColor;
}>`
  font-size: max(1.3rem, 13px);
  color: ${(p) => p.$themeColor.primaryFontColor};
  font-weight: 500;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
  width: 80%;
`;

export const StyledMailContent = styled.div<{
  $themeColor: DoxleThemeColor;
}>`
  font-size: max(1.2rem, 12px);
  color: ${(p) =>
    editRgbaAlpha({ rgbaColor: p.$themeColor.primaryFontColor, alpha: "0.7" })};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
  width: 90%;
`;

export const StyledMailContainer = styled.div`
  display: flex;
  width: 100%;
`;

export const StyledDateContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 15%;
  min-width: 12rem;
  height: 100%;
  flex-direction: column;
  align-items: center;
`;

export const StyledMonth = styled.div<{
  $themeColor: DoxleThemeColor;
}>`
  font-size: max(1rem, 10px);
  color: ${(p) =>
    editRgbaAlpha({ rgbaColor: p.$themeColor.primaryFontColor, alpha: "0.6" })};
`;

export const StyledDay = styled.div<{
  $themeColor: DoxleThemeColor;
}>`
  font-size: max(1.2rem, 12px);
  color: ${(p) => p.$themeColor.primaryFontColor};
  width: fit-content;
`;

export const StyledDateInnerWrapper = styled.div``;

export const StyledMailFilterContainer = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  width: 27rem;
  min-height: 20rem;
  padding-left: 20px;
  padding-right: 20px;
  background-color: ${(p) => p.$themeColor.primaryBackgroundColor};
`;

export const StyledMailFilterPopup = styled(motion.div)``;

export const StyledFilterTitle = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  color: ${(p) => p.$themeColor.primaryFontColor};
  font-size: 1.5rem;
  margin-top: 5px;
  margin-bottom: 10px;
  font-weight: 500;
`;

export const StyledDatePickerInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledFilterLabel = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  font-size: 1.2rem;
  color: ${(p) =>
    editRgbaAlpha({ rgbaColor: p.$themeColor.primaryFontColor, alpha: "0.4" })};
  margin-bottom: 4px;
  margin-top: 4px;
`;

export const StyledMailFilterInput = styled.input<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  background-color: ${(p) => p.$themeColor.primaryContainerColor};
  border: 1px solid ${(p) => p.$themeColor.rowBorderColor};
  width: 100%;
  height: 2rem;
  color: ${(p) => p.$themeColor.primaryFontColor};
  &:focus {
    outline: none;
  }
  &::placeholder {
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    color: ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.$themeColor.primaryFontColor,
        alpha: "0.3",
      })};
  }
`;

export const StyledDatePickerContainer = styled.div`
  display: flex;
  margin-top: 0%.5;
`;

export const StyledMailFilterPopover = styled(Popover)`
  && {
  }

  & .MuiPopover-paper {
    background-color: transparent;
  }
`;
