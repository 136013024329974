import {
  IFilterGetFolderQueryFilter,
  getFolderQKey,
} from "../QueryHooks/FileStorageQueryAPI";
import { useDoxleCurrentContextStore } from "../../DoxleGeneralStore/useDoxleCurrentContext";
import { useQueryClient } from "@tanstack/react-query";
import { DoxleFolder } from "../../Models/files";
import { produce } from "immer";
import { DefiniteAxiosQueryData } from "../../Models/axiosReturn";

type Props = {
  filter: IFilterGetFolderQueryFilter;
  appendPos?: "start" | "end";
  overwrite?: boolean;
};
interface SetRootFolderQueryData {
  handleAddFolder: (newItem: DoxleFolder) => void;
  handleEditFolder: (newItem: DoxleFolder) => void;
  handleDeleteMultipleFolders: (deletedIds: string[]) => void;
}
const useSetRootFolderQueryData = ({
  filter,
  appendPos = "end",
  overwrite = true,
}: Props): SetRootFolderQueryData => {
  const { company } = useDoxleCurrentContextStore((state) => ({
    company: state.currentCompany,
  }));
  const queryClient = useQueryClient();

  const handleAddFolder = (newItem: DoxleFolder) => {
    const qKey = getFolderQKey(
      {
        projectId: newItem.project ?? undefined,
        docketId: newItem.docket ?? undefined,
      },
      company
    );
    const folderListCacheActive = queryClient.getQueryCache().findAll({
      predicate: (query) =>
        qKey.every((key) => query.queryKey.includes(key)) && query.isActive(),
    });
    const folderListCacheInactive = queryClient.getQueryCache().findAll({
      predicate: (query) =>
        qKey.every((key) => query.queryKey.includes(key)) && !query.isActive(),
    });
    folderListCacheActive.forEach((query) => {
      queryClient.setQueryData<DefiniteAxiosQueryData<DoxleFolder[]>>(
        query.queryKey,
        (old) => {
          if (old) {
            return produce(old, (draftOld) => {
              if (appendPos === "start") draftOld.data.unshift(newItem);
              else draftOld.data.push(newItem);
              return draftOld;
            });
          } else return old;
        }
      );
    });
    folderListCacheInactive.forEach((query) => {
      queryClient.invalidateQueries(query.queryKey);
    });
  };

  const handleEditFolder = (newItem: DoxleFolder) => {
    const qKey = getFolderQKey(
      {
        projectId: newItem.project ?? undefined,
        docketId: newItem.docket ?? undefined,
      },
      company
    );
    const folderListCacheActive = queryClient.getQueryCache().findAll({
      predicate: (query) =>
        qKey.every((key) => query.queryKey.includes(key)) && query.isActive(),
    });
    const folderListCacheInactive = queryClient.getQueryCache().findAll({
      predicate: (query) =>
        qKey.every((key) => query.queryKey.includes(key)) && !query.isActive(),
    });
    folderListCacheActive.forEach((query) => {
      queryClient.setQueryData<DefiniteAxiosQueryData<DoxleFolder[]>>(
        query.queryKey,
        (old) => {
          if (old) {
            return produce(old, (draftOld) => {
              const item = (draftOld.data as DoxleFolder[]).find(
                (item) => item.folderId === newItem.folderId
              );
              if (item) Object.assign(item, newItem);
              return draftOld;
            });
          } else return old;
        }
      );
    });
    folderListCacheInactive.forEach((query) => {
      queryClient.invalidateQueries(query.queryKey);
    });
  };
  const handleDeleteMultipleFolders = (deletedIds: string[]) => {
    const qKey = getFolderQKey({}, company);
    const folderListCacheActive = queryClient.getQueryCache().findAll({
      predicate: (query) =>
        qKey.every((key) => query.queryKey.includes(key)) && query.isActive(),
    });
    const folderListCacheInactive = queryClient.getQueryCache().findAll({
      predicate: (query) =>
        qKey.every((key) => query.queryKey.includes(key)) && !query.isActive(),
    });
    folderListCacheActive.forEach((query) => {
      queryClient.setQueryData<DefiniteAxiosQueryData<DoxleFolder[]>>(
        query.queryKey,
        (old) => {
          if (old) {
            return produce(old, (draftOld) => {
              draftOld.data = draftOld.data.filter(
                (oriItem) =>
                  !deletedIds.some(
                    (deletedId) => deletedId === oriItem.folderId
                  )
              );
              return draftOld;
            });
          } else return old;
        }
      );
    });
    folderListCacheInactive.forEach((query) => {
      queryClient.invalidateQueries(query.queryKey);
    });
  };
  return { handleAddFolder, handleEditFolder, handleDeleteMultipleFolders };
};

export default useSetRootFolderQueryData;
