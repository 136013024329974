import { create } from "zustand";
import { User } from "../Models/user";
import CookieService from "../Services/cookieService";
import DoxleAPI from "../Services/DoxleAPI";
import axios from "axios";

interface LoginDetails {
  user: string;
  password: string;
}
interface AuthLoginResponse {
  type: "success" | "error" | undefined;
  message: string;
}

const getInitialUser = () => {
  try {
    const userStr = localStorage.getItem("currentUser") || "";
    const user: User | undefined = userStr ? JSON.parse(userStr) : undefined;
    console.log("USER GET:", user);
    return user;
  } catch (e) {
    console.log(e);
    return undefined;
  }
};
export interface DoxleAuthStore {
  loginWithDetails: (login: LoginDetails) => Promise<AuthLoginResponse>;
  loginWithLink: (
    apiKey: string,
    oobCode: string,
    req_email?: string
  ) => Promise<AuthLoginResponse>;
  sendEmailLink: (email: string) => Promise<AuthLoginResponse>;
  firstAuth: boolean;
  setFirstAuth: (value: boolean) => void;
  loggedIn: boolean | undefined;
  setLoggedIn: (value: boolean | undefined) => void;
  user: User | undefined;
  setUser: (user: User | undefined) => void;
  getUser: () => Promise<User | undefined>;
  handleLogOut: () => void;
  // getInitialUser:()=>void
}

export const useDoxleAuthStore = create<DoxleAuthStore>((set, get) => ({
  firstAuth: false,
  setFirstAuth: (value: boolean) => set({ firstAuth: value }),
  loggedIn: undefined,
  setLoggedIn: (value: boolean | undefined) => set({ loggedIn: value }),
  user: undefined, // No longer getting default from local storage - forces it to get new data from backend with updated settings
  setUser: (user: User | undefined) => {
    set({ user: user });
  },
  getUser: async () => {
    try {
      const response = await DoxleAPI.get<User>("/user/self/");
      set({ user: response.data, loggedIn: true });
      localStorage.setItem("currentUser", JSON.stringify(response.data));
      return response.data;
    } catch (error: any) {
      set({ loggedIn: false });
      console.log("Error Getting User");
      return undefined;
    }
  },
  handleLogOut: async () => {
    await DoxleAPI.get("/logout/");
    await CookieService.remove("access_token");
    await CookieService.remove("refresh_token");
    set({
      loggedIn: false,
    });
    window.open(
      `${window.location.protocol}/${window.location.hostname}/`,
      "_self"
    );
  },
  loginWithDetails: async (login: LoginDetails) => {
    let returnResponse: AuthLoginResponse = { type: undefined, message: "" };
    try {
      const requestBody: any = {
        grant_type: "password",
        client_id: "b5Y0MqZdwi3NMdaEcJSJWIPSGBm3hr0NTMQT4RUK",
        client_secret:
          "TDOIue9kSmQUXV9JVe4cUHWcRnN7CZdflDGuNir4khFrhwI43pBpYbn3ZM4w2xfY4TK91QApEGT91oeDcz8UVOjYIOVVAKsb2KgzOwYTLwE3AzZdeI5Jh6RnOijeb3tp",
        username: login.user,
        password: login.password,
      };
      let formBody: string[] = [];
      for (let property in requestBody) {
        let encodedKey = encodeURIComponent(property);
        let encodedValue = encodeURIComponent(requestBody[property]);
        formBody.push(encodedKey + "=" + encodedValue);
      }
      const formBodyStr: string = formBody.join("&");
      const response = await DoxleAPI.post("/token/", formBodyStr, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Accept: "application/json",
        },
      });
      if (response && response.data) {
        // debugger;
        returnResponse.type = "success";
        set({
          user: response?.data?.user as User,
          loggedIn: true,
        });
        console.log(response?.data?.storageSignature);
        localStorage.setItem(
          "currentUser",
          JSON.stringify(response?.data?.user)
        );
        if (response?.data?.user?.lastCompany)
          localStorage.setItem(
            "currentCompanyId",
            response?.data?.user?.lastCompany
          );
        if (response?.data?.user?.lastApp)
          localStorage.setItem("lastUsedApp", response?.data?.user?.lastApp);
        returnResponse.type = "success";
        returnResponse.message =
          "Welcome " + String(response?.data?.user?.firstName);
      } else {
        returnResponse.type = undefined;
        returnResponse.message =
          response.status + ": " + String(response?.data);
      }
    } catch (err: any) {
      set({ loggedIn: false });
      console.log(err.response);
      returnResponse.type = "error";
      if (typeof err === "string") returnResponse.message = err;
      else if (typeof err?.response === "string")
        returnResponse.message = err.response;
      else if (typeof err?.response?.data === "string")
        returnResponse.message = err.response.data;
      else returnResponse.message = String(err);
    }
    return returnResponse;
  },
  // exchangeRefreshToken: async () => {
  //   console.log("exchangeRefreshToken");
  //   if (!get().refreshToken || CookieService.get("refreshToken")) {
  //     try {
  //       const requestBody: any = {
  //         grant_type: "refresh_token",
  //         client_id: "b5Y0MqZdwi3NMdaEcJSJWIPSGBm3hr0NTMQT4RUK",
  //         client_secret:
  //           "TDOIue9kSmQUXV9JVe4cUHWcRnN7CZdflDGuNir4khFrhwI43pBpYbn3ZM4w2xfY4TK91QApEGT91oeDcz8UVOjYIOVVAKsb2KgzOwYTLwE3AzZdeI5Jh6RnOijeb3tp",
  //         refresh_token:
  //           get().refreshToken ?? CookieService.get("refreshToken"),
  //       };
  //       let formBody: string[] = [];
  //       for (let property in requestBody) {
  //         let encodedKey = encodeURIComponent(property);
  //         let encodedValue = encodeURIComponent(requestBody[property]);
  //         formBody.push(encodedKey + "=" + encodedValue);
  //       }
  //       const formBodyStr: string = formBody.join("&");
  //       const response = await DoxleAPI.post(
  //         "/token/",
  //         formBodyStr,
  //         {
  //           headers: {
  //             "Content-Type": "application/x-www-form-urlencoded",
  //             Accept: "*/*",
  //           },
  //           withCredentials: true,
  //         }
  //       );
  //       if (
  //         response &&
  //         response.data
  //       ) {
  //         // debugger;
  //         set({
  //           loggedIn: true,
  //           firstAuth: false,
  //           user: response?.data?.user as User,
  //         });
  //         if (response?.data?.user as User) {
  //           localStorage.setItem(
  //             "currentUser",
  //             JSON.stringify(response?.data?.user)
  //           );
  //         }
  //         console.log(response);
  //         return true;
  //       } else {
  //         console.log(response);
  //         throw response;
  //       }
  //     } catch (error) {
  //       console.error("ERROR", error);
  //       set({
  //         loggedIn: false,
  //       });
  //
  //       CookieService.remove("access_token");
  //       CookieService.remove("refresh_token");
  //
  //       return false;
  //     }
  //   } else {
  //     CookieService.remove("access_token");
  //     CookieService.remove("refresh_token");
  //     return false;
  //   }
  // },
  sendEmailLink: async (email: string) => {
    let returnResponse: AuthLoginResponse = { type: undefined, message: "" };
    try {
      const requestBody: any = {
        grant_type: "email",
        client_id: "b5Y0MqZdwi3NMdaEcJSJWIPSGBm3hr0NTMQT4RUK",
        client_secret:
          "TDOIue9kSmQUXV9JVe4cUHWcRnN7CZdflDGuNir4khFrhwI43pBpYbn3ZM4w2xfY4TK91QApEGT91oeDcz8UVOjYIOVVAKsb2KgzOwYTLwE3AzZdeI5Jh6RnOijeb3tp",
        email: email,
      };
      let formBody: string[] = [];
      for (let property in requestBody) {
        let encodedKey = encodeURIComponent(property);
        let encodedValue = encodeURIComponent(requestBody[property]);
        formBody.push(encodedKey + "=" + encodedValue);
      }
      const formBodyStr: string = formBody.join("&");
      const response = await DoxleAPI.post(`/token/`, formBodyStr, {
        withCredentials: true,
      });
      let date = new Date();
      date.setTime(date.getTime() + 300000);
      CookieService.set("loginEmail", email, {
        path: "/",
        expires: date,
      });
      returnResponse.type = response.status === 200 ? "success" : "error";
      returnResponse.message =
        response.status === 200
          ? "A Magic Link has been sent to your email"
          : "Unable to send Magic Link";
    } catch (err: any) {
      console.log(err.response);
      returnResponse.type = "error";
      if (typeof err === "string") returnResponse.message = err;
      else if (typeof err?.response === "string")
        returnResponse.message = err.response;
      else if (typeof err?.response?.data === "string")
        returnResponse.message = err.response.data;
      else returnResponse.message = String(err);
    }
    return returnResponse;
  },
  loginWithLink: async (
    apiKey: string,
    oobCode: string,
    req_email?: string
  ) => {
    let returnResponse: AuthLoginResponse = { type: undefined, message: "" };
    try {
      const email = CookieService.get("loginEmail");
      console.log(email);
      const response = await axios.post(
        "https://www.googleapis.com/identitytoolkit/v3/relyingparty/emailLinkSignin",
        { oobCode: oobCode, email: req_email ?? email },
        {
          headers: { "Content-Type": "application/json", Accept: "*/*" },
          params: { key: apiKey },
        }
      );
      if (
        response?.data?.idToken &&
        response?.data?.refreshToken &&
        response?.data?.localId
      ) {
        let userText = "";
        try {
          const userResponse = await DoxleAPI.post(`/complete_login/`, {
            accessToken: response?.data?.idToken,
            refreshToken: response?.data?.refreshToken,
          });
          if (userResponse?.data) {
            set({ user: userResponse?.data as User });
            localStorage.setItem(
              "currentUser",
              JSON.stringify(userResponse?.data)
            );
            if (userResponse?.data?.lastCompany)
              localStorage.setItem(
                "currentCompanyId",
                userResponse?.data?.lastCompany
              );
            if (userResponse?.data?.lastApp)
              localStorage.setItem("lastUsedApp", userResponse?.data?.lastApp);
            // if (userResponse?.data?.lastTheme) setCurrentTheme(userResponse?.data?.lastTheme)
            // if (userResponse?.data?.lastFont) setFontMode(userResponse?.data?.lastFont)
            userText = " " + userResponse.data.firstName;
          }
        } catch (err: any) {
          console.error("unable to complete login");
          console.log(err);
        }
        console.log("returning success");
        returnResponse.type = "success";
        returnResponse.message = "Welcome" + userText;
      }
      returnResponse.type = "error";
      returnResponse.message = response?.status + ": " + String(response?.data);
    } catch (err: any) {
      console.log(err.response);
      returnResponse.type = "error";
      if (typeof err === "string") returnResponse.message = err;
      else if (typeof err?.response === "string")
        returnResponse.message = err.response;
      else if (typeof err?.response?.data === "string")
        returnResponse.message = err.response.data;
      else returnResponse.message = String(err);
    }
    return returnResponse;
  },
}));
