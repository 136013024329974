import React, { MouseEvent, useMemo, useState } from "react";
import { shallow } from "zustand/shallow";
import {
  StyledBoldHeader,
  StyledContactEmail,
  StyledContactName,
  StyledSemiBoldHeader,
  StyledSharedContactInfo,
  StyledSharedContactRow,
  StyledShareDocketFullScreenContainer,
  StyledShareDocketTabContainer,
  StyledShareDocketTitleRow,
  StyledShareLabel,
  StyledSubtitleContainer,
  StyledButtonContainer,
  StyledShareDocketFullScreenBorder,
  StyledBottomBanner,
  StyledBottomBannerBreadcrumbs,
  StyledBottomBannerEmail,
  StyledLeftScreenShareableTitle,
  StyledShareLogoSection,
  ShareableLink,
  StyleLeftScreenCopyLinkText,
  StyledLink,
  StyledBackButton,
} from "./SyledComponents";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import { Contact } from "../../Models/addressBook";
import { useDoxleAuthStore } from "../../DoxleGeneralStore/useDoxleAuthStore";
import { useDoxleCurrentContextStore } from "../../DoxleGeneralStore/useDoxleCurrentContext";
import useDoxleNotificationStore from "../../DoxleGeneralStore/useDoxleNotificationStore";
import PermissionsAPI from "../../Services/QueryHooks/permissionsAPI";
import { ProjectPermission } from "../../Models/permissions";
import DoxleContactInviteSection, {
  NewContact,
} from "../../DoxleDesignPattern/DoxleAutoComplete/DoxleContactInviteSection";
import { Virtuoso } from "react-virtuoso";
import SharedContactRow from "./SharedContactRow";
import DropDownPermissions from "./DropDownPermissions";
import { useNavigate, useParams } from "react-router-dom";
import {
  CopyIcon,
  DoxleShareLogo,
  ProjectIcon,
  ShareProjectImage,
} from "./icons";
import ProjectQueryAPI from "../../Services/QueryHooks/projectQueryAPI";
import { Project } from "../../Models/project";
import ExportPDFButton from "./ExportPDFButton";
import ExportExcelButton from "./ExportExcelButton";
import { useIsMutating } from "@tanstack/react-query";
import LinearProgress from "@mui/material/LinearProgress";
import { editRgbaAlpha } from "../../Utilities/FunctionUtilities";
import { useDoxleWindowStore } from "../../DoxleGeneralStore/useDoxleWindowSizeStore";
import ExportOptionDialog from "./ExportOptionDialog";
import CloseIcon from "@mui/icons-material/Close";

interface RelatedPermissions {
  companyPermissionsTotal: number;
  companyPermissions: Contact[];
}

interface Props {}
const ShareProjectScreen = ({}: Props) => {
  const [exportDialog, setExportDialog] = useState<undefined | "excel" | "pdf">(
    undefined
  );
  const { projectId } = useParams();
  const { isMobile, displayWidth, displayHeight } = useDoxleWindowStore(
    (state) => ({
      isMobile: state.isMobile,
      displayWidth: state.innerWidth,
      displayHeight: state.innerHeight,
    }),
    shallow
  );

  const { company, currentProject } = useDoxleCurrentContextStore(
    (state) => ({
      company: state.currentCompany,
      currentProject: state.currentProject,
    }),
    shallow
  );
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );
  const styleProps = useDoxleThemeStore(
    (state) => ({
      $themeColor: state.doxleThemeColor,
      $doxleFont: state.doxleFont,
      $currentTheme: state.currentTheme,
    }),
    shallow
  );
  const projectQuery = ProjectQueryAPI.useRetrieveProjectDetailQuery({
    projectId,

    company,
    showNotification,
    enabled: projectId !== currentProject?.projectId,
  });

  const project = useMemo(() => {
    if (currentProject?.projectId === projectId) return currentProject;
    if (projectQuery.isSuccess && projectQuery.data?.data)
      return projectQuery.data?.data as Project;
    return undefined;
  }, [projectQuery.isSuccess, projectQuery.data, projectId, currentProject]);

  const contactPermissionQuery =
    PermissionsAPI.useRetrieveContactsPermissionsQuery({
      company,
      showNotification,
      filter: { project: projectId },
    });

  const permissions = useMemo(
    () =>
      contactPermissionQuery.isSuccess
        ? contactPermissionQuery.data?.pages
          ? contactPermissionQuery.data?.pages.flatMap(
              (page) => page?.data?.results ?? []
            ) ?? []
          : []
        : [],
    [contactPermissionQuery.isSuccess, contactPermissionQuery.data]
  );

  const permittedContactIds: string[] = useMemo(
    () =>
      permissions.reduce((acc, data) => {
        if (data.contact) return acc.concat(data.contact.contactId);
        else return acc;
      }, [] as string[]),
    [permissions]
  );

  const shareProjectQuery = ProjectQueryAPI.useShareProjectLink({
    company,
    showNotification,
  });
  const handleGetPDF = (e: MouseEvent<HTMLDivElement>) => {
    setExportDialog("pdf");
  };
  const handleGetExcel = (e: MouseEvent<HTMLDivElement>) => {
    setExportDialog("excel");
  };
  const closeExportDialog = () => setExportDialog(undefined);

  const isExporting = useIsMutating({
    mutationKey: ["exporting-project"],
  });
  const handleAddAndShareNewContact = (contact: NewContact) => {
    if (projectId)
      shareProjectQuery.emailLink.mutate({
        projectId,
        newContacts: [contact],
      });
  };

  const handleShareProject = (contact: Contact) => {
    if (projectId)
      shareProjectQuery.emailLink.mutate({
        projectId: projectId,
        contactIds: [contact.contactId],
      });
  };
  const navigate = useNavigate();
  return (
    <StyledShareDocketFullScreenBorder {...styleProps}>
      {Boolean(isExporting) && (
        <>
          <LinearProgress
            className="loader-bar"
            sx={{
              "&.MuiLinearProgress-root": {
                backgroundColor: editRgbaAlpha({
                  rgbaColor: styleProps.$themeColor.doxleColor,
                  alpha: "0.3",
                }),
              },
              "& .MuiLinearProgress-bar": {
                backgroundColor: styleProps.$themeColor.doxleColor,
              },
            }}
          />
        </>
      )}
      {Boolean(exportDialog) && (
        <ExportOptionDialog
          type={exportDialog}
          closeDialog={closeExportDialog}
        />
      )}
      <StyledShareDocketFullScreenContainer
        {...styleProps}
        $isMobile={isMobile}
      >
        <StyledBackButton {...styleProps} onClick={() => navigate(-1)}>
          <CloseIcon style={{ transform: "scale(2.5)" }} />
        </StyledBackButton>
        <StyledShareDocketTabContainer
          {...styleProps}
          $percentageWidth={isMobile ? 100 : 40}
          $alignCentre={true}
        >
          <StyledShareLogoSection>
            <DoxleShareLogo currentTheme={styleProps.$currentTheme} />
          </StyledShareLogoSection>
          <ShareProjectImage
            currentTheme={styleProps.$currentTheme}
            isMobile={isMobile}
            displayHeight={displayHeight}
          />
          <StyledShareDocketTitleRow {...styleProps}>
            <StyledBoldHeader {...styleProps} $displayWidth={displayWidth}>
              Sharing Project{" "}
            </StyledBoldHeader>
            <StyledSemiBoldHeader {...styleProps} $displayWidth={displayWidth}>
              <div style={{ paddingRight: 8 }}>
                <ProjectIcon currentTheme={styleProps.$currentTheme} />
              </div>
              {project?.siteAddress ?? "Unknown Project"}
            </StyledSemiBoldHeader>
          </StyledShareDocketTitleRow>
          <StyledSubtitleContainer $displayWidth={displayWidth} {...styleProps}>
            Please note: The link will expire in 14 days, your guest can access
            via login.doxle.com
          </StyledSubtitleContainer>
          <StyledLeftScreenShareableTitle>
            <div style={{ display: "flex" }}>
              <ShareableLink>Shareable link</ShareableLink>
              <CopyIcon currentTheme={styleProps.$currentTheme} />
              <StyleLeftScreenCopyLinkText>
                Copy link
              </StyleLeftScreenCopyLinkText>
            </div>
            <StyledLink>https://www.doxle.com/this/goes/nowhere</StyledLink>
          </StyledLeftScreenShareableTitle>
          <StyledButtonContainer {...styleProps}>
            <ExportExcelButton onClick={handleGetExcel} selected={false} />
            <div style={{ width: "5em" }}> </div>
            <ExportPDFButton onClick={handleGetPDF} selected={false} />
          </StyledButtonContainer>
        </StyledShareDocketTabContainer>
        <StyledShareDocketTabContainer
          {...styleProps}
          $percentageWidth={displayWidth >= 1020 ? 45 : 100}
        >
          {/*<AddContactOrEmailSection/>*/}
          <DoxleContactInviteSection
            addNewContactFn={handleAddAndShareNewContact}
            addExistingContactFn={handleShareProject}
            permittedContactIds={permittedContactIds}
          />
          <StyledShareLabel {...styleProps}>
            Who has access ({permissions.length})
          </StyledShareLabel>
          {/*<StyledSharedContactRow>*/}
          {/*  <StyledSharedContactInfo {...styleProps} $fullWidth={true}>*/}
          {/*    <StyledContactName {...styleProps}>*/}
          {/*      {relatedPermissions.companyPermissionsTotal} Users can see view*/}
          {/*      the project by company permissions*/}
          {/*    </StyledContactName>*/}
          {/*    <StyledContactEmail {...styleProps}>*/}
          {/*      {companyPermissionsContacts}*/}
          {/*    </StyledContactEmail>*/}
          {/*  </StyledSharedContactInfo>*/}
          {/*</StyledSharedContactRow>*/}
          {/*<StyledSharedContactRow>*/}
          {/*  <StyledSharedContactInfo {...styleProps} $fullWidth={true}>*/}
          {/*    <StyledContactName {...styleProps}>*/}
          {/*      {isNaN(relatedPermissions.projectPermissionsTotal)} Users can see view*/}
          {/*      the docket by project permissions*/}
          {/*    </StyledContactName>*/}
          {/*    <StyledContactEmail {...styleProps}>*/}
          {/*      {projectPermissionsContacts}*/}
          {/*    </StyledContactEmail>*/}
          {/*  </StyledSharedContactInfo>*/}
          {/*</StyledSharedContactRow>*/}
          {/*{permissions.map(item => (<SharedContactRow project={project} projectPermission={item} />))}*/}
          <Virtuoso
            data={permissions}
            itemContent={(index: number, item) =>
              item.contact ? (
                <SharedContactRow project={project} projectPermission={item} />
              ) : null
            }
          />
        </StyledShareDocketTabContainer>
      </StyledShareDocketFullScreenContainer>
      <StyledBottomBanner {...styleProps}>
        <StyledBottomBannerBreadcrumbs {...styleProps}>
          {company?.name} / {project?.siteAddress}
        </StyledBottomBannerBreadcrumbs>
        <StyledBottomBannerEmail {...styleProps}>
          dockets@doxle.com
        </StyledBottomBannerEmail>
      </StyledBottomBanner>
      <DropDownPermissions />
    </StyledShareDocketFullScreenBorder>
  );
};

export default ShareProjectScreen;
