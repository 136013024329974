import React, { useMemo } from "react";
import { useDoxleCurrentContextStore } from "../../DoxleGeneralStore/useDoxleCurrentContext";
import useDoxleNotificationStore from "../../DoxleGeneralStore/useDoxleNotificationStore";
import ScopeOfWorkAPI from "../QueryAPI/scopeOfWorkQueryAPI";
import { ScopeOfWorkImage } from "../Model/scopeOfWorks";

type Props = {
  scopeItemId: string;
  enabled?: boolean;
};

interface IGetScopeImage {
  scopeImageList: ScopeOfWorkImage[];
  isFetchingScopeImgList: boolean;
  isErrorFetchingScopeImgList: boolean;
  isSuccessFetchingScopeImgList: boolean;
  handleFetchNextPageImgList: () => void;
  isFetchingNextPageImgList: boolean;
  refetchNextPageImgList: () => void;
}
const useGetScopeImage = ({
  scopeItemId,
  enabled = true,
}: Props): IGetScopeImage => {
  const { company } = useDoxleCurrentContextStore((state) => ({
    company: state.currentCompany,
  }));
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification
  );
  const getScopeItemImageQuery = ScopeOfWorkAPI.useRetrieveScopeItemImage({
    showNotification,
    company,
    scopeItemId,
    enabled,
  });

  const scopeImageList = useMemo(
    () =>
      getScopeItemImageQuery.isSuccess
        ? getScopeItemImageQuery.data.pages.reduce((acc, data) => {
            return acc.concat(data.data.results);
          }, [] as ScopeOfWorkImage[])
        : [],
    [getScopeItemImageQuery.data]
  );

  const handleFetchNextPageImgList = () => {
    if (getScopeItemImageQuery.hasNextPage)
      getScopeItemImageQuery.fetchNextPage();
  };

  const refetchNextPageImgList = () => {
    getScopeItemImageQuery.refetch();
  };
  return {
    scopeImageList,
    isFetchingScopeImgList: getScopeItemImageQuery.isLoading,
    isErrorFetchingScopeImgList: getScopeItemImageQuery.isError,
    isSuccessFetchingScopeImgList: getScopeItemImageQuery.isSuccess,
    handleFetchNextPageImgList,
    isFetchingNextPageImgList: getScopeItemImageQuery.isFetchingNextPage,
    refetchNextPageImgList,
  };
};

export default useGetScopeImage;
