import React from "react";
import { useNoteStore } from "../Store/useNoteStore";
import { shallow } from "zustand/shallow";
import { useDoxleCurrentContextStore } from "../../DoxleGeneralStore/useDoxleCurrentContext";
import useDoxleNotificationStore from "../../DoxleGeneralStore/useDoxleNotificationStore";
import { NoteQueryAPI } from "../QueryAPI/notesQueryAPI";

type Props = {};

interface IDeleteNoteConfirm {
  handleDeleteNote: () => void;
}
const useDeleteNoteConfirm = (props: Props): IDeleteNoteConfirm => {
  const { filterNoteQuery, setDeletedNote, deletedNote } = useNoteStore(
    (state) => ({
      filterNoteQuery: state.filterNoteQuery,

      setDeletedNote: state.setDeletedNote,
      deletedNote: state.deletedNote,
    }),
    shallow
  );
  const { company } = useDoxleCurrentContextStore(
    (state) => ({
      company: state.currentCompany,
    }),
    shallow
  );
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification
  );

  const { destroy } = NoteQueryAPI.useMutateNoteQuery({
    company,
    filter: filterNoteQuery,
    showNotification,
  });
  const handleDeleteNote = () => {
    if (deletedNote) destroy.mutate(deletedNote.noteId);
    setDeletedNote(undefined);
  };
  return { handleDeleteNote };
};

export default useDeleteNoteConfirm;
