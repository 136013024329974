import React, { useCallback, useRef } from "react";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import useDoxleNotificationStore from "../../../DoxleGeneralStore/useDoxleNotificationStore";
import { shallow } from "zustand/shallow";
import { Button } from "@mui/material";
import { ReactComponent as FileUploadIcon } from "../../Assets/fileIcon.svg";
import { DropEvent, FileRejection, useDropzone } from "react-dropzone";
import { motion } from "framer-motion";
type Props = {
  attachments: File[];
  setAttachments: React.Dispatch<React.SetStateAction<File[]>>;
};

const QuoteAttachments = ({ attachments, setAttachments }: Props) => {
  const { THEME_COLOR, currentTheme } = useDoxleThemeStore((state) => ({
    THEME_COLOR: state.doxleThemeColor,
    currentTheme: state.currentTheme,
  }));

  const onDrop = useCallback(
    <T extends File>(
      acceptedFiles: T[],
      fileRejections: FileRejection[],
      event: DropEvent
    ) => {
      setAttachments((prev) => [...prev, ...acceptedFiles]);
    },
    [attachments]
  );

  const { getRootProps } = useDropzone({
    onDrop,
    accept: {
      "image/*": [".png", ".jpg", ".jpeg"],
      "application/pdf": [".pdf"],
      "application/msword": [".doc"],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        [".docx"],
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
        ".xlsx",
      ],
    },
    multiple: true,
    // noClick: addedScopeImages.length > 0 ? true : false,
  });

  return (
    <motion.div layout>
      <Button
        {...getRootProps({
          variant: "outlined",
          startIcon: (
            <FileUploadIcon style={{ color: THEME_COLOR.primaryFontColor }} />
          ),
          sx: {
            borderRadius: "0px 0.9rem 0.9rem 1.2rem",
            border: `1px solid ${
              currentTheme === "light"
                ? THEME_COLOR.primaryTableRowColor
                : THEME_COLOR.primaryDividerColor
            }`,
            color: THEME_COLOR.primaryFontColor,
            fontSize: "1.4rem",
            textTransform: "none",
            marginTop: "2rem",
          },
        })}
      >
        Choose File
      </Button>
    </motion.div>
  );
};
export default QuoteAttachments;
