import React from "react";
import { useAddQuoteResponseContext } from "../AddQuoteResponse";
import { produce } from "immer";

type Props = { itemIndex: number };
interface AddedQuoteLineItem {
  handleLineDescriptionChange: (value: string) => void;
  handleLineQTyChange: (value: string) => void;
  handleLineUnitChange: (value: string) => void;
  handleUnitPriceChange: (value: string) => void;
  handleRemoveLine: () => void;
}
const useAddedQuoteLineItem = ({ itemIndex }: Props): AddedQuoteLineItem => {
  const { setNewQuoteResponse } = useAddQuoteResponseContext();
  const handleLineDescriptionChange = (value: string) =>
    setNewQuoteResponse((prev) =>
      produce(prev, (draft) => {
        draft.lines[itemIndex].description = value;
        return draft;
      })
    );

  const handleLineQTyChange = (value: string) =>
    setNewQuoteResponse((prev) =>
      produce(prev, (draft) => {
        draft.lines[itemIndex].quantity = value;
        draft.lines[itemIndex].lineCost = (
          Number(prev.lines[itemIndex].itemCost) * Number(value)
        ).toString();
        return draft;
      })
    );

  const handleLineUnitChange = (value: string) =>
    setNewQuoteResponse((prev) =>
      produce(prev, (draft) => {
        draft.lines[itemIndex].unit = value;
        return draft;
      })
    );
  const handleUnitPriceChange = (value: string) =>
    setNewQuoteResponse((prev) =>
      produce(prev, (draft) => {
        draft.lines[itemIndex].itemCost = value;
        draft.lines[itemIndex].lineCost = (
          Number(prev.lines[itemIndex].quantity) * Number(value)
        ).toString();
        return draft;
      })
    );

  const handleRemoveLine = () =>
    setNewQuoteResponse((prev) =>
      produce(prev, (draft) => {
        draft.lines.splice(itemIndex, 1);
        return draft;
      })
    );
  return {
    handleLineDescriptionChange,
    handleLineQTyChange,
    handleLineUnitChange,
    handleUnitPriceChange,
    handleRemoveLine,
  };
};

export default useAddedQuoteLineItem;
