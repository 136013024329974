import React, { useCallback, useMemo } from "react";
import { Docket } from "../../../Models/dockets";
import useDoxleNotificationStore from "../../../DoxleGeneralStore/useDoxleNotificationStore";
import DocketQuery from "../../../Services/QueryHooks/docketQueryAPI";
import { useDoxleCurrentContextStore } from "../../../DoxleGeneralStore/useDoxleCurrentContext";
import { shallow } from "zustand/shallow";
import { useShallow } from "zustand/react/shallow";

type Props = {
  docketPk?: string;
};

interface IGetDocketFullDetails {
  docketDetail: Docket | undefined;
  isFetchingDocketDetail: boolean;
  isSuccessFetchingDocketDetail: boolean;
  isErrorFetchingDocketDetail: boolean;
  isRefetchingDocketDetail: boolean;
  refetchDocketDetail: () => void;
}
const useGetDocketFullDetails = ({
  docketPk,
}: Props): IGetDocketFullDetails => {
  const { company } = useDoxleCurrentContextStore(
    useShallow((state) => ({
      company: state.currentCompany,
    }))
  );
  const { showNotification } = useDoxleNotificationStore();
  //handle show notification

  const docketDetailQuery = DocketQuery.useRetrieveDocketDetail({
    docketPk: docketPk ?? "",
    company,
    showNotification,
    enable: Boolean(docketPk),
  });

  const docketDetail = useMemo(
    () =>
      docketDetailQuery.isSuccess ? docketDetailQuery.data.data : undefined,
    [docketDetailQuery.data]
  );

  const refetchDocketDetail = () => {
    docketDetailQuery.refetch();
  };

  return {
    docketDetail,
    isFetchingDocketDetail: docketDetailQuery.isLoading,
    isSuccessFetchingDocketDetail: docketDetailQuery.isSuccess,
    isErrorFetchingDocketDetail: docketDetailQuery.isError,
    isRefetchingDocketDetail: docketDetailQuery.isRefetching,
    refetchDocketDetail,
  };
};

export default useGetDocketFullDetails;
