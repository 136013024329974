import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Contact } from "../../Models/addressBook";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import { AnimatePresence } from "framer-motion";
import {
  StyledAddContactButton,
  StyledContactCardText,
  StyledContactEmailCard,
  StyledContactsTextField,
  StyledFoundContactsColumn,
  StyledHeaderText,
  StyledInputColumn,
  StyledInputRow,
  StyledInviteContainer,
  StyledLowerIconSection, StyledOverlayContainer,
} from "./StyledComponentDoxleAutocomplete";
import { useDoxleAuthStore } from "../../DoxleGeneralStore/useDoxleAuthStore";
import { shallow } from "zustand/shallow";
import { useDoxleCurrentContextStore } from "../../DoxleGeneralStore/useDoxleCurrentContext";
import ContactsAPI from "../../Services/QueryHooks/contactsAPI";
import {
  LowerAddContactIcon,
  LowerSeachContactIcon,
} from "../DoxleIcons/CommonIcons";
import {ContactsFilters} from "../../Services/QueryHooks/contactsFilters";
import {useIsMutating} from "@tanstack/react-query";
import Loading from "../../Utilities/Lottie/Loading";
import CircularProgress from "@mui/material/CircularProgress";

export interface NewContact {
  name: string;
  email: string;
}

type Props = {
  addExistingContactFn: (contact: Contact) => void;
  addNewContactFn: (contact: NewContact) => void;
  permittedContactIds: string[]
};

const DoxleContactInviteSection = ({
  addExistingContactFn,
  addNewContactFn,
  permittedContactIds
}: Props) => {
  const [selectedContactId, setSelectedContactId] = useState<string>("");
  const [searchInput, setSearchInput] = useState<string>("");
  const [emailInput, setEmailInput] = useState<string>("");
  const [searchTextQuery, setSearchTextQuery] = useState<string>("");
  // const [displayEmailField, setDisplayEmailField] = useState<boolean>(false);
  const inviteContainerRef = useRef<HTMLDivElement>(null)
  const styleProps = useDoxleThemeStore((state) => ({
    $themeColor: state.doxleThemeColor,
    $doxleFont: state.doxleFont,
    $currentTheme: state.currentTheme,
  }), shallow);
  const company = useDoxleCurrentContextStore(
    (state) => state.currentCompany,
    shallow
  );
  const filterRetrieveContactListQuery: ContactsFilters = useMemo(
    () => ({
      search: searchInput,
    }),
    [searchInput]
  );

  function isContact(object: any): object is Contact {
    return "contactId" in object;
  }
  // useEffect(()=> console.log('searchInput', searchInput), [searchInput])
  // useEffect(()=> console.log('filterRetrieveContactListQuery', filterRetrieveContactListQuery), [filterRetrieveContactListQuery])
  const getContactQuery = ContactsAPI.useRetrieveContactsQuery({
    company,
    filter: filterRetrieveContactListQuery,
    enable: true,
  });

  const mutating: boolean = Boolean(useIsMutating())

  const handleClickInvite = (contact: Contact) =>{
    addExistingContactFn(contact);
  }
  const contactList: Contact[] = useMemo(
    () =>
      getContactQuery.isSuccess
        ? getContactQuery.data.pages.flatMap(
            (page) => selectedContactId
              ? (page.data.results ?? []).filter(contact=> contact.contactId === selectedContactId)
              : page.data.results.filter(contact=> !permittedContactIds.includes(contact.contactId)) ?? []
          ) ?? []
        : [],
    [getContactQuery.data, selectedContactId]
  );
  const nameTextfieldRef = useRef<any>(null);
  const emailTextfieldRef = useRef<any>(null);

  useEffect(() => {
    setSelectedContactId("")
    setSearchInput("")
    setEmailInput("")
  }, [permittedContactIds]);

  useEffect(() => {
    const timeout = setTimeout(() => setSearchInput(searchTextQuery), 400);
    return () => clearTimeout(timeout);
  }, [searchTextQuery]);

  const checkValidEmail = useCallback(() => {
    const emailRegex = /.+@.+\..+/;
    return emailRegex.test(emailInput);
  }, [emailInput]);

  const handleAddNewContact = () => {
    console.log("handleAddNewContact");
    let error: boolean = false;
    if (nameTextfieldRef.current)
      nameTextfieldRef.current.style.border =
        styleProps.$themeColor.primaryDividerColor + " 1px solid !important";
    if (emailTextfieldRef.current)
      emailTextfieldRef.current.style.border =
        styleProps.$themeColor.primaryDividerColor + " 1px solid !important";
    if (!searchInput) {
      error = true;
      console.log("invalid search");
      if (nameTextfieldRef.current) {
        nameTextfieldRef.current.style.border =
          "rgba(255,0,0,1) 1px solid !important";
        nameTextfieldRef.current.focus();
      }
    }
    console.log("email", emailInput);
    if (!checkValidEmail()) {
      error = true;
      console.log("invalid email");
      if (emailTextfieldRef.current) {
        emailTextfieldRef.current.style.border =
          "rgba(255,0,0,1) 1px solid !important";
        emailTextfieldRef.current.focus();
      }
    }
    if (!error) {
      addNewContactFn({ name: searchInput, email: emailInput });
      setSearchInput("");
      setEmailInput("");
    }
  };

  //* animation
  const errorHelperTextVariants = {
    entering: {
      x: [-5, 0],
      opacity: [0, 1],
      transition: {
        duration: 0.3,
      },
    },
    exiting: {
      x: [0, -5],
      opacity: [1, 0],
      transition: {
        duration: 0.3,
      },
    },
  };
  return (
    <StyledInviteContainer className={searchInput ? "focussed" : ""}{...styleProps} ref={inviteContainerRef}>
      {mutating &&
        <StyledOverlayContainer
          {...styleProps}
          $top={inviteContainerRef?.current?.getBoundingClientRect().top ?? 0}
          $left={inviteContainerRef?.current?.getBoundingClientRect().left ?? 0}
          $height={inviteContainerRef?.current?.getBoundingClientRect().height ?? 0}
          $width={inviteContainerRef?.current?.getBoundingClientRect().width ?? 0}
        >
          <CircularProgress />
        </StyledOverlayContainer>
      }
      <StyledHeaderText {...styleProps}>Add people to this project</StyledHeaderText>
      <StyledInputRow {...styleProps}>
        <StyledInputColumn {...styleProps}>
          <StyledContactsTextField
            {...styleProps}
            $hidden={false}
            variant={"standard"}
            placeholder={"Enter name or email"}
            ref={nameTextfieldRef}
            value={searchInput}
            $bottomMargin={3}
            onChange={(e) => {
              setSearchInput(e.target.value);
              setSelectedContactId("");
            }}
          />

          <AnimatePresence>
            {/*{isError && (*/}
            {/*  <StyledErrorHelperText*/}
            {/*    {...styleProps}*/}
            {/*    variants={errorHelperTextVariants}*/}
            {/*    initial={false}*/}
            {/*    animate="entering"*/}
            {/*    exit="exiting"*/}
            {/*  >*/}
            {/*    Please Select A User*/}
            {/*  </StyledErrorHelperText>*/}
            {/*)}*/}
          </AnimatePresence>
          {
            <StyledContactsTextField
              {...styleProps}
              $hidden={
                !Boolean(searchInput.length > 1 && contactList.length === 0)
              }
              variant={"standard"}
              placeholder={"email ..."}
              ref={emailTextfieldRef}
              value={emailInput}
              onChange={(e) => {
                setEmailInput(e.target.value);
              }}
            />
          }
        </StyledInputColumn>
        <StyledAddContactButton
          {...styleProps}
          $wide={searchInput.length > 1 && contactList.length === 0}
          disabled={Boolean(contactList.length > 1 && searchInput)}
          onClick={(e) => {
            if (!searchInput) return
            if (searchInput.length > 1 && contactList.length === 0)
              handleAddNewContact();
            else if (contactList.length === 1) handleClickInvite(contactList[0]);
          }}
        >
          {!searchInput ? "Add" :contactList.length === 0 ? "Add & Invite" : "Invite"}
        </StyledAddContactButton>
      </StyledInputRow>

      {Boolean(searchInput) && <StyledLowerIconSection
        {...styleProps}
        $emailShown={Boolean(
          searchInput.length > 1 && contactList.length === 0
        )}
      >
        {searchInput.length > 1
          ? contactList.length
            ? `(${contactList.length}) Contact${
              contactList.length === 1 ? "" : "s"
            } found ...`
            : "No contact found, add and invite a new contact"
          : "Search or add people"}
        {!Boolean(contactList.length) && (
          <LowerAddContactIcon {...styleProps.$themeColor} />
        )}
        {searchInput.length < 2 && Boolean(contactList.length) && (
          <LowerSeachContactIcon {...styleProps.$themeColor} />
        )}
      </StyledLowerIconSection>}

      <StyledFoundContactsColumn {...styleProps}>
        {searchInput.length > 1 &&
          contactList
            .slice(0, Math.min(contactList.length, 4))
            .map((contact, i) => (
              <StyledContactEmailCard
                {...styleProps}
                key={contact.contactId}
                onClick={() => {
                  setSearchInput(contact.firstName + " " + contact.lastName)
                  setSelectedContactId(contact.contactId)
                }}
              >
                <StyledContactCardText>{`${contact.firstName} ${contact.lastName}`}</StyledContactCardText>
                <StyledContactCardText>{`<${contact.email}>`}</StyledContactCardText>
              </StyledContactEmailCard>
            ))}
      </StyledFoundContactsColumn>
    </StyledInviteContainer>
  );
};

export default DoxleContactInviteSection;
