import React from "react";
import { usePricebookStore } from "../Store/usePricebookStore";
import { useShallow } from "zustand/react/shallow";
import { useDoxleCurrentContextStore } from "../../DoxleGeneralStore/useDoxleCurrentContext";
import useDoxleNotificationStore from "../../DoxleGeneralStore/useDoxleNotificationStore";
import PricebookQueryAPI from "../QueryHooks/PricebookQueryAPI";
import useSetPricebookQueryData from "../QueryHooks/useSetPricebookQueryData";

type Props = {};

interface IPricebookDeleteConfirmDialog {
  isDialogOpen: boolean;
  dialogDescription: string;
  handleDeleteSupplierRate: () => void;
  handleCloseDeleteDialog: () => void;
}
const usePricebookDeleteConfirmDialog = (
  props?: Props
): IPricebookDeleteConfirmDialog => {
  const {
    setDeletePricebookRateItem,
    deletedPricebookRateItem,
    pricebookRateQueryFilter,
  } = usePricebookStore(
    useShallow((state) => ({
      deletedPricebookRateItem: state.deletedPricebookRateItem,
      setDeletePricebookRateItem: state.setDeletePricebookRateItem,
      pricebookRateQueryFilter: state.pricebookRateQueryFilter,
    }))
  );
  const isDialogOpen = !!deletedPricebookRateItem;
  const dialogDescription = `All data belong to Pricebook "${deletedPricebookRateItem?.pricebookItemDescription}" will be deleted permanently, are you sure to proceed?`;
  const company = useDoxleCurrentContextStore(
    useShallow((state) => state.currentCompany)
  );

  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification
  );

  const { handleRemovePricebookRate } = useSetPricebookQueryData({
    itemId: deletedPricebookRateItem?.pricebookItem ?? "",
  });
  const mutateSupplierRate = PricebookQueryAPI.useSupplierRateMutationQuery({
    company,
    showNotification,
    filter: pricebookRateQueryFilter,
    onSuccessRemoveCb: (deletedRateId) => {
      handleRemovePricebookRate(deletedRateId);
    },
  });

  const handleDeleteSupplierRate = () => {
    if (deletedPricebookRateItem) {
      mutateSupplierRate.deleteSupplierRate.mutate(
        deletedPricebookRateItem.rateId
      );

      handleCloseDeleteDialog();
    }
  };

  const handleCloseDeleteDialog = () => {
    setDeletePricebookRateItem(undefined);
  };

  return {
    isDialogOpen,
    dialogDescription,
    handleDeleteSupplierRate,
    handleCloseDeleteDialog,
  };
};

export default usePricebookDeleteConfirmDialog;
