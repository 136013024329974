//! TO USE THIS GALLERY, pass in the image list

import { useDoxleImageGalleryStore } from "./Store/useDoxleImageGalleryStore";
import { shallow } from "zustand/shallow";
import { Dialog, Fade } from "@mui/material";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import {
  StyledDoxleGalleryRootContainer,
  StyledGalleryContentContainer,
  StyledGalleryImageHolder,
} from "./StyledComponentDoxleGallery";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import "./gallery.css";
import { editRgbaAlpha } from "../../Utilities/FunctionUtilities";
import DoxleGalleryControlTab from "./DoxleGalleryControlTab";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import DoxleEmptyPlaceHolder from "../DoxleEmptyPlaceHolder/DoxleEmptyPlaceHolder";
import { Error404Banner } from "../DoxleIcons/CommonIcons";
import React from "react";
import { TransitionProps } from "@mui/material/transitions";
import DoxleVideoPlayer from "./DoxleVideoPlayer";
import { useShallow } from "zustand/react/shallow";

type Props = {};

const DoxleGallery = (props: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore((state) => ({
    doxleThemeColor: state.doxleThemeColor,
    doxleFont: state.doxleFont,
  }));

  const {
    imageList,
    setImageList,
    setCurrentImageIndex,
    currentImageIndex,
    resetGallery,
    videoUrl,
  } = useDoxleImageGalleryStore(
    useShallow((state) => ({
      imageList: state.imageList,
      setImageList: state.setImageList,
      setCurrentImageIndex: state.setCurrentImageIndex,
      currentImageIndex: state.currentImageIndex,
      videoUrl: state.videoUrl,
      resetGallery: state.resetGallery,
    }))
  );

  const isModalOpen: boolean = Boolean(imageList.length > 0 || videoUrl);

  return (
    <Dialog
      open={isModalOpen}
      TransitionComponent={Transition}
      onClose={resetGallery}
      aria-describedby="add-drawing-dialog-slide"
      slotProps={{
        backdrop: {
          sx: {
            bgcolor: editRgbaAlpha({
              rgbaColor: doxleThemeColor.primaryBackgroundColor,
              alpha: "0.8",
            }),
          },
        },
      }}
      PaperProps={{
        sx: {
          bgcolor: doxleThemeColor.primaryContainerColor,
          display: "flex !important",
          width: "100vw",
          height: "100vh",
          borderRadius: "0.9rem",
          border: `1px solid ${doxleThemeColor.primaryDividerColor}`,
          margin: "0px !important",
          maxWidth: "100vw !important",
          maxHeight: "100vh !important",
        },
        elevation: 4,
      }}
    >
      {videoUrl && <DoxleVideoPlayer videoUrl={videoUrl} />}

      {imageList.length > 0 && (
        <TransformWrapper
          smooth
          centerOnInit
          maxScale={14}
          minScale={0.05}
          wheel={{
            smoothStep: 0.002,
            step: 0.25,
          }}
          alignmentAnimation={{
            disabled: true,
          }}
        >
          <StyledDoxleGalleryRootContainer>
            <DoxleGalleryControlTab />

            {imageList[currentImageIndex] ? (
              <StyledGalleryContentContainer>
                <TransformComponent
                  wrapperStyle={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                  }}
                  contentStyle={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                  }}
                >
                  <StyledGalleryImageHolder
                    src={imageList[currentImageIndex].uri}
                  />
                </TransformComponent>
              </StyledGalleryContentContainer>
            ) : (
              <DoxleEmptyPlaceHolder
                headTitleText="Failed to load image"
                containerBgColor="transparent"
                headTitleTextStyle={{
                  fontSize: 20,
                  color: "red",
                }}
                illustration={
                  <Error404Banner
                    themeColor={doxleThemeColor}
                    containerStyle={{
                      width: "100%",
                      maxWidth: 350,
                      marginBottom: 14,
                    }}
                  />
                }
              />
            )}
          </StyledDoxleGalleryRootContainer>
        </TransformWrapper>
      )}
    </Dialog>
  );
};

export default DoxleGallery;

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Fade ref={ref} {...props} />;
});
