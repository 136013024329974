import { shallow } from "zustand/shallow";
import { useDoxleGridLayoutStore } from "../Store/useDoxleGridLayoutStore";
import { useEffect } from "react";

type DoxleGridLayoutProps<TItem> = {
  data: TItem[];

  extractItemKeyProp: keyof TItem;
};

interface DoxleGridLayout<TItem> {}
export function useDoxleGridLayout<TItem>({
  data,
  extractItemKeyProp,
}: DoxleGridLayoutProps<TItem>): DoxleGridLayout<TItem> {
  const { setInitialDataList, setExtractItemKeyProp, resetStore } =
    useDoxleGridLayoutStore(
      (state) => ({
        setInitialDataList: state.setInitialDataList,
        setExtractItemKeyProp: state.setExtractItemKeyProp,
        resetStore: state.resetStore,
      }),
      shallow
    );
  useEffect(() => {
    setInitialDataList(data);
  }, [data]);
  useEffect(() => {
    setExtractItemKeyProp(extractItemKeyProp);
  }, [extractItemKeyProp]);
  useEffect(() => {
    return () => resetStore();
  }, []);

  return {};
}
