import { useCallback, useEffect, useMemo } from "react";

import { shallow } from "zustand/shallow";
import { IBooking } from "../../Models/booking";
import useBookingProjectStore from "../../store/useBookingProjectStore";
import { useDoxleAuthStore } from "../../../DoxleGeneralStore/useDoxleAuthStore";
import { useDoxleCurrentContextStore } from "../../../DoxleGeneralStore/useDoxleCurrentContext";
import {
  BookingQueryAPI,
  IFilterBookingQueryProps,
} from "../../QueryAPI/bookingQueryAPI";
import useDoxleNotificationStore from "../../../DoxleGeneralStore/useDoxleNotificationStore";

type Props = {};

interface GetBookingList {
  bookingProjectList: IBooking[];
  isFetchingBookingProjectList: boolean;
  isSuccessFetchingBookingProjectList: boolean;
  isErrorFetchingBookingProjectList: boolean;
  isRefetchingBookingProjectList: boolean;
  isFetchingMoreBookingProjectList: boolean;
  refetchBookingProjectList: () => void;
  fetchMoreBookingProjectList: () => void;
}
const useGetProjectBookingList = (props: Props): GetBookingList => {
  const { filter, setFilterBookingProjectQuery } = useBookingProjectStore(
    (state) => ({
      setFilterBookingProjectQuery: state.setFilterBookingProjectQuery,
      filter: state.filterBookingProjectQuery
    }),
    shallow
  );

  const { company, currentProject, currentSearchText } =
    useDoxleCurrentContextStore(
      (state) => ({
        company: state.currentCompany,
        currentProject: state.currentProject,
        currentSearchText: state.currentSearchText,
      }),
      shallow
    );
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );
  const filterBookingProjectQuery: IFilterBookingQueryProps = useMemo(
    () => ({
      project: currentProject?.projectId ?? "",
      search: currentSearchText,
      ...filter
    }),
    [currentProject, currentSearchText, filter]
  );
  useEffect(() => {
    setFilterBookingProjectQuery({
      project: currentProject?.projectId ?? "",
      search: currentSearchText,
    });
  }, [currentProject, currentSearchText]);
  const getBookingQuery = BookingQueryAPI.useRetrieveBookingList({
    company,
    showNotification,
    filter: filterBookingProjectQuery,
    enableQuery: Boolean(currentProject),
  });

  const bookingProjectList = useMemo(
    () =>
      getBookingQuery.isSuccess
        ? getBookingQuery.data.pages.reduce((acc, data) => {
            return acc.concat(data.data.results);
          }, [] as IBooking[])
        : [],
    [getBookingQuery.data]
  );

  const refetchBookingProjectList = () => {
    getBookingQuery.refetch();
  };
  const fetchMoreBookingProjectList = () => {
    if (getBookingQuery.hasNextPage) getBookingQuery.fetchNextPage();
  };

  return {
    bookingProjectList,
    isFetchingBookingProjectList: getBookingQuery.isLoading,
    isSuccessFetchingBookingProjectList: getBookingQuery.isSuccess,
    isErrorFetchingBookingProjectList: getBookingQuery.isError,
    isRefetchingBookingProjectList: getBookingQuery.isRefetching,
    isFetchingMoreBookingProjectList: getBookingQuery.isFetchingNextPage,
    refetchBookingProjectList,
    fetchMoreBookingProjectList,
  };
};

export default useGetProjectBookingList;
