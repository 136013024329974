import React, { useCallback, useEffect, useMemo } from "react";
import {
  StyledBookingDocketOrderContainer,
  StyledDocketOrderTitleSection,
  StyledOrderRowWrapper,
} from "./StyledComponentBookingDocketDetail";
import { Order } from "../../../Models/orders";
import { useDoxleThemeStore } from "../../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import { AnimatePresence, Variants, motion } from "framer-motion";
import SelectedOrderView from "./SelectedOrderView";
import useBookingDocketOrder from "./Hooks/useBookingDocketOrder";
import DoxleListSkeleton from "../../../../DoxleDesignPattern/DoxleSkeleton/DoxleListSkeleton";
import { Components, Virtuoso } from "react-virtuoso";

import DoxleEmptyPlaceHolder from "../../../../DoxleDesignPattern/DoxleEmptyPlaceHolder/DoxleEmptyPlaceHolder";
import BookingOrderRow from "./BookingOrderRow";
import ListLoadingMore from "../../../../Utilities/Lottie/ListLoadingMore";
import BookingOrderNumber from "../../../CommonComponents/BookingOrderNumber";
import { AiFillCloseCircle } from "react-icons/ai";
import EmptyOrderScreen from "./EmptyOrderScreen";
import Loading from "../../../../Utilities/Lottie/Loading";
import useBookingProjectStore from "../../../store/useBookingProjectStore";
import { StyledVirtuosoListScroller } from "../../../CommonComponents/StyledComponentBookings";
import useBookingViewStore from "../../../store/useBookingViewStore";
type Props = {
  docketId?: string;
  onSelectOrder?: (order: Order | undefined) => void;
  selectedOrder?: Order;
  mode: "add" | "edit";
};

interface OrderListContext {
  isErrorFetchingOrderList: boolean;
}
const BookingDocketOrder = ({
  docketId,
  onSelectOrder,
  selectedOrder,
  mode,
}: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }),
    shallow
  );
  const { currentMenuTab, setCurrentMenuTab, currentScreenDisplay } =
    useBookingViewStore(
      (state) => ({
        setCurrentMenuTab: state.setCurrentMenuTab,
        currentMenuTab: state.currentMenuTab,
        currentScreenDisplay: state.currentScreenDisplay,
      }),
      shallow
    );
  const {
    ordersList,
    isFetchingOrderList,
    isSuccessFetchingOrderList,
    isErrorFetchingOrderList,
    refetchOrderList,
    isRefetchingOrderList,
    fetchMoreOrder,
    isFetchingMoreOrder,
    contentWrapperRef,
    contentWrapperHeight,
    orderDetail,
    isFetchingOrderDetail,
  } = useBookingDocketOrder({ docketId, onSelectOrder, selectedOrder, mode });
  const { edittedBooking } = useBookingProjectStore(
    (state) => ({
      edittedBooking: state.edittedBooking,
    }),
    shallow
  );
  //*render order list
  //render list
  const rowAnimatedVariants: Variants = {
    initial: {
      x: -5,
      opacity: 0,
    },
    entering: {
      x: 0,
      opacity: 1,
    },
  };
  const orderListStyle: React.CSSProperties = {
    width: "100%",
    height: "100%",
  };
  const components: Components<Order, OrderListContext> = useMemo(
    () => ({
      Item: (props) => (
        <StyledOrderRowWrapper
          key={props.item.orderId}
          $themeColor={doxleThemeColor}
          {...props}
          initial="initial"
          animate="entering"
          variants={rowAnimatedVariants}
          transition={{
            duration: 0.2,
          }}
          onClick={() => {
            if (onSelectOrder) onSelectOrder(props.item);
          }}
        />
      ),
      Scroller: React.forwardRef(({ ...props }, ref) => (
        <StyledVirtuosoListScroller ref={ref} {...props} />
      )),

      EmptyPlaceholder: (props) =>
        props.context?.isErrorFetchingOrderList ? (
          <DoxleEmptyPlaceHolder
            headTitleText="Something wrong!"
            subTitleText="Failed to get order data..."
            containerBgColor="transparent"
            headTitleTextStyle={{
              fontSize: "3rem",
            }}
            subTitleTextStyle={{ fontSize: "2.5rem" }}
          />
        ) : (
          <EmptyOrderScreen />
        ),
    }),
    [onSelectOrder]
  );

  const itemContent = useCallback(
    (index: number, item: Order, context?: OrderListContext) => (
      <BookingOrderRow order={item} />
    ),
    []
  );

  const containerAnimatedVariants: Variants = {
    initial: {
      x: -5,
      opacity: 0,
    },
    entering: {
      x: 0,
      opacity: 1,
    },
  };
  useEffect(() => {
    console.log("RENDERR:", docketId);
  }, [docketId]);

  return (
    <StyledBookingDocketOrderContainer
      $currentScreenDisplay={currentScreenDisplay}
      variants={containerAnimatedVariants}
      initial="initial"
      animate="entering"
    >
      {mode === "add" && (
        <>
          <StyledDocketOrderTitleSection
            $themeColor={doxleThemeColor}
            $doxleFont={doxleFont}
          >
            <AnimatePresence>
              {selectedOrder && (
                <BookingOrderNumber
                  order={selectedOrder}
                  orderIconSize="5rem"
                  orderNumTextStyle={{ fontSize: "1.4rem" }}
                  orderSignTextStyle={{ fontSize: "1.4rem" }}
                  initial={{
                    x: -5,
                    opacity: 0,
                  }}
                  animate={{ x: 0, opacity: 1 }}
                  exit={{ x: -5, opacity: 0 }}
                />
              )}
            </AnimatePresence>

            <motion.span layout className="order-title-text">
              Purchase Order
            </motion.span>

            {selectedOrder && (
              <AiFillCloseCircle
                color={doxleThemeColor.primaryFontColor}
                fontSize="2rem"
                onClick={() => {
                  if (onSelectOrder) onSelectOrder(undefined);
                }}
                className="icon-style"
              />
            )}
          </StyledDocketOrderTitleSection>

          <motion.div className="order-content-wrapper" ref={contentWrapperRef}>
            {selectedOrder ? (
              <SelectedOrderView
                selectedOrder={selectedOrder}
                contentWrapperHeight={contentWrapperHeight}
              />
            ) : (
              <>
                {isFetchingOrderList && (
                  <DoxleListSkeleton
                    skeletonType="orderRow"
                    containerBgColor="transparent"
                  />
                )}

                {!isFetchingOrderList && (
                  <Virtuoso
                    style={orderListStyle}
                    data={ordersList}
                    components={components}
                    context={{ isErrorFetchingOrderList }}
                    itemContent={itemContent}
                  />
                )}

                {isFetchingMoreOrder && (
                  <ListLoadingMore
                    containerStyle={{
                      position: "absolute",
                      bottom: 0,
                      left: 0,
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      zIndex: 10,
                    }}
                    animationSize={120}
                  />
                )}
              </>
            )}
          </motion.div>
        </>
      )}

      {mode === "edit" && (
        <>
          <StyledDocketOrderTitleSection
            $themeColor={doxleThemeColor}
            $doxleFont={doxleFont}
          >
            <AnimatePresence>
              {orderDetail && (
                <BookingOrderNumber
                  order={orderDetail}
                  orderIconSize="5rem"
                  orderNumTextStyle={{ fontSize: "1.4rem" }}
                  orderSignTextStyle={{ fontSize: "1.4rem" }}
                  initial={{
                    x: -5,
                    opacity: 0,
                  }}
                  animate={{ x: 0, opacity: 1 }}
                  exit={{ x: -5, opacity: 0 }}
                />
              )}
            </AnimatePresence>

            <motion.span layout className="order-title-text">
              Purchase Order
            </motion.span>
          </StyledDocketOrderTitleSection>

          <motion.div className="order-content-wrapper" ref={contentWrapperRef}>
            {edittedBooking?.order && (
              <>
                {isFetchingOrderDetail && (
                  <Loading
                    containerStyle={{
                      backgroundColor: "transparent",
                    }}
                  />
                )}

                {!isFetchingOrderDetail && (
                  <>
                    {!orderDetail ? (
                      <EmptyOrderScreen />
                    ) : (
                      <SelectedOrderView
                        selectedOrder={orderDetail}
                        contentWrapperHeight={contentWrapperHeight}
                      />
                    )}
                  </>
                )}
              </>
            )}
            {!edittedBooking?.order && <EmptyOrderScreen />}
          </motion.div>
        </>
      )}
    </StyledBookingDocketOrderContainer>
  );
};

export default BookingDocketOrder;
