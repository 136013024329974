import React, { useRef } from "react";
import { shallow } from "zustand/shallow";
import { AnimatePresence } from "framer-motion";
import { CircularProgress } from "@mui/material";
import { useDropzone } from "react-dropzone";
import { useDoxleThemeStore } from "../../../../DoxleGeneralStore/useDoxleThemeStore";
import { VirtuosoHandle } from "react-virtuoso";
import { ReplyMail } from "../../../Models/mail";
import useEditMailResponseInputSection from "../../Hooks/useEditMailResponseInputSection";
import DoxleIconButton from "../../../../DoxleDesignPattern/DoxleButtons/DoxleIconButton";
import DoxleSendButton from "../../../../DoxleDesignPattern/DoxleButtons/DoxleSendButton";
import AttachmentPickerDisplayer from "../AttachmentPickerDisplayer";
import { StyledEditMailResponseInputContainer } from "./StyledMailReply";
import { StyledInfoTitle, StyledReplyInput } from "../StyledMailSideScreen";
import { DoxleAttachmentIcon } from "../../../../DoxleDesignPattern/DoxleGeneralIcons";

type Props = {
  replyListRef: React.RefObject<VirtuosoHandle>;
  mailReply: ReplyMail[];
};

const MailResponseInputSection = ({ replyListRef, mailReply }: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }),
    shallow
  );
  const {
    onDrop,
    addedAttachments,
    setAddedAttachments,
    handleClickSendButton,
    commentText,
    setCommentText,
    isAddingResponse,
    onKeyDown,
  } = useEditMailResponseInputSection({ replyListRef, mailReply });

  const { getRootProps } = useDropzone({
    onDrop,
    accept: {
      "image/*": [".png", ".jpg", ".jpeg"],
      "application/pdf": [".pdf"],
      "application/msword": [".doc"],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        [".docx"],
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
        ".xlsx",
      ],
    },
  });

  const suggestionRef = useRef<HTMLDivElement>(null);

  return (
    <div style={{ display: "flex", width: "100%", flexDirection: "column" }}>
      <AnimatePresence>
        {addedAttachments.length > 0 && (
          <AttachmentPickerDisplayer
            addedAttachments={addedAttachments}
            setAddedAttachments={setAddedAttachments}
          />
        )}
      </AnimatePresence>

      <StyledInfoTitle $themeColor={doxleThemeColor}>
        WRITE RESPONSE
      </StyledInfoTitle>

      <StyledEditMailResponseInputContainer
        $themeColor={doxleThemeColor}
        layout="position"
        ref={suggestionRef}
      >
        <div className="input-wrapper">
          <StyledReplyInput
            $doxleFont={doxleFont}
            $themeColor={doxleThemeColor}
            placeholder="Write something here ..."
            onChange={(e) => {
              setCommentText(e.target.value);
            }}
            onKeyDown={(
              event:
                | React.KeyboardEvent<HTMLTextAreaElement>
                | React.KeyboardEvent<HTMLInputElement>
            ) => {
              if (event.nativeEvent.key === "Enter") {
                if (!isAddingResponse) {
                  handleClickSendButton();
                }
              }
              if (event.nativeEvent.key === "Escape") {
                setCommentText("");
              }
            }}
            value={commentText}
          />
        </div>
        {isAddingResponse && (
          <CircularProgress
            sx={{ color: doxleThemeColor.doxleColor, marginInline: 2 }}
            size={14}
          />
        )}
        <DoxleIconButton
          {...getRootProps({
            iconSource: (
              <DoxleAttachmentIcon
                themeColor={doxleThemeColor}
                isFocused={true}
              />
            ),
            buttonSize: 25,
            margin: 0,
          })}
        />

        <DoxleSendButton
          disabled={Boolean(
            (!commentText && addedAttachments.length === 0) || isAddingResponse
          )}
          sx={{ marginTop: "2px", marginLeft: "4px" }}
          onClick={handleClickSendButton}
        />
      </StyledEditMailResponseInputContainer>
    </div>
  );
};

export default MailResponseInputSection;
