import { ContactCompany } from "../../../Models/addressBook";
import { useState } from "react";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import { useContactsStore } from "../Store/useContactsStore";
import { useEditContactsStore } from "../Store/useEditOrderStore";
import { CONTACT_COMPANY_TABLE_HEADER_LIST } from "../../Models/contactsTable";
import {
  StyledContactTableCell,
  StyledContactTableRow,
} from "../StyledComponents";
import { IconButton } from "@mui/material";
import { DeleteContactIcon } from "../ContactsIcons";
import OrdersQueryAPI from "../../QueryHooks/ContactsQueryAPI";
import useDoxleNotificationStore from "../../../DoxleGeneralStore/useDoxleNotificationStore";
import { MouseEvent } from "react";
import { useDoxleCurrentContextStore } from "../../../DoxleGeneralStore/useDoxleCurrentContext";

interface Props {
  contactCompany: ContactCompany;
}

const ContactCompanyTableRow = ({ contactCompany }: Props) => {
  const [isHover, setIsHover] = useState<boolean>(false);
  const queryFilter = useContactsStore(
    (state) => state.contactCompanyQueryFilter,
    shallow
  );
  const { setEdittedCompany, setAddDialogType } = useContactsStore(
    (state) => ({
      setEdittedCompany: state.setEdittedCompany,
      setAddDialogType: state.setAddDialogType,
    }),
    shallow
  );
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );
  const company = useDoxleCurrentContextStore(
    (state) => state.currentCompany,
    shallow
  );
  const { doxleThemeColor, doxleFont, currentTheme, transparentMode } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
      currentTheme: state.currentTheme,
      transparentMode: state.transparentMode,
    }),
    shallow
  );

  const deleteContactCompanyQuery = OrdersQueryAPI.useRemoveContactsCompanyQuery({
    showNotification,
    company,
  });

  const handleDeleteContactCompany = (
    e: MouseEvent<HTMLButtonElement>,
    contactCompanyId: string | undefined
  ) => {
    e.preventDefault();
    e.stopPropagation();
    if(contactCompanyId){
      deleteContactCompanyQuery.mutate({contactCompanyId});
    }
  };

  const handleContactCompanyRowClick = () => {
    setEdittedCompany(contactCompany);
    setAddDialogType("ContactCompany");
  };


  return (
    <StyledContactTableRow
      $transparentMode={transparentMode}
      $currentTheme={currentTheme}
      $themeColor={doxleThemeColor}
      onMouseOver={() => setIsHover(true)}
      onMouseOut={() => setIsHover(false)}
      onClick={handleContactCompanyRowClick}
    >
      {CONTACT_COMPANY_TABLE_HEADER_LIST.map((header, idx) => {
        if (header.isDisplayed) {
          if (header.headerName === "ABN") {
            return (
              <StyledContactTableCell
                key={`docketCell#${contactCompany.contactCompanyId}#${idx}`}
                $widthInRem={null}
                $horizontalAlign="flex-start"
                $doxleFont={doxleFont}
                $themeColor={doxleThemeColor}
              >
                <span> {contactCompany.abn}</span>
              </StyledContactTableCell>
            );
          }
          if (header.headerName === "Phone") {
            return (
              <StyledContactTableCell
                key={`docketCell#${contactCompany.contactCompanyId}#${idx}`}
                $widthInRem={null}
                $horizontalAlign="flex-start"
                $doxleFont={doxleFont}
                $themeColor={doxleThemeColor}
              >
                <span> {contactCompany.phone}</span>
              </StyledContactTableCell>
            );
          }
          if (header.headerName === "Email") {
            return (
              <StyledContactTableCell
                key={`docketCell#${contactCompany.contactCompanyId}#${idx}`}
                $widthInRem={null}
                $horizontalAlign="flex-start"
                $doxleFont={doxleFont}
                $themeColor={doxleThemeColor}
              >
                <span> {contactCompany.email}</span>
              </StyledContactTableCell>
            );
          }
          if (header.headerName === "Company Name") {
            return (
              <StyledContactTableCell
                key={`docketCell#${contactCompany.contactCompanyId}#${idx}`}
                $widthInRem={null}
                $horizontalAlign="flex-start"
                $doxleFont={doxleFont}
                $themeColor={doxleThemeColor}
              >
                <span> {contactCompany.name}</span>
              </StyledContactTableCell>
            );
          }
          if (header.headerName === "Address") {
            return (
              <StyledContactTableCell
                key={`docketCell#${contactCompany.contactCompanyId}#${idx}`}
                $widthInRem={null}
                $horizontalAlign="flex-start"
                $doxleFont={doxleFont}
                $themeColor={doxleThemeColor}
              >
                <span>{contactCompany.address}</span>
              </StyledContactTableCell>
            );
          }
          if (header.headerName === "Contacts") {
            return (
              <StyledContactTableCell
                key={`docketCell#${contactCompany.contactCompanyId}#${idx}`}
                $widthInRem={"10rem"}
                $horizontalAlign="center"
                $doxleFont={doxleFont}
                $themeColor={doxleThemeColor}
              >
                <span> {contactCompany.contacts?.length}</span>
              </StyledContactTableCell>
            );
          }
        }
      })}
        <StyledContactTableCell
        key={`docketCell#${contactCompany.contactCompanyId}#delete`}
        $widthInRem={"1rem"}
        $horizontalAlign="center"
        $doxleFont={doxleFont}
        $themeColor={doxleThemeColor}
      >
        <IconButton onClick={(e) => handleDeleteContactCompany(e, contactCompany.contactCompanyId)}>
          <DeleteContactIcon themeColor={doxleThemeColor} />
        </IconButton>
      </StyledContactTableCell>
    </StyledContactTableRow>
  );
};

export default ContactCompanyTableRow;
