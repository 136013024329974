import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { StyledCommentsListItem, StyledCommentUserName, StyledCommentsDateLabel,StyleReplyMarkedofficial, StyleCommentTextBody,StyleCommentPDF } from "./styledCompnents"
import { shallow } from "zustand/shallow";
import { Mail, MailComments, ReplyMail } from "../../Models/mail";
import moment from "moment";
import { StyledMailListInfoPDF } from "../styledCompnents";
import {useMailStore} from "../../Store/mailStore";
interface Props {
    conversation: MailComments | Mail | undefined
}
const MailCommentList = ({ conversation }: Props) => {
  const mailTheme = useMailStore(state => state.mailTheme, shallow)
  const styleProps = useDoxleThemeStore(
        (state) => ({
            $themeColor: {...state.doxleThemeColor, ...mailTheme},
            $doxleFont: state.doxleFont,
        }),
        shallow
    );

    return <StyledCommentsListItem {...styleProps}>
        <StyledCommentUserName {...styleProps}>Message from {conversation?.fromString} <StyledCommentsDateLabel  {...styleProps}>{moment(conversation?.timeStamp).format('MMMM DD, YYYY - h:MM a')}</StyledCommentsDateLabel></StyledCommentUserName>
        <StyleCommentTextBody {...styleProps}>{conversation?.textBody}</StyleCommentTextBody>
        {conversation?.attachments?.length ? <StyledMailListInfoPDF $themeColor={styleProps.$themeColor}>
                {conversation?.attachments?.map(item=>
                  <StyleCommentPDF onClick={()=> window.open(item.url, "_blank")} {...styleProps}>
                    <img height='38px'
                         src={ item.fileType.toLowerCase().includes("application/pdf")
                           ? "/assets/icons/adobe-pdf-icon.svg"
                           : item.fileType.toLowerCase().includes("image/jpeg")
                             ? "/assets/icons/jpeg.png"
                             : item.fileType.toLowerCase().includes("image/png")
                               ? "/assets/icons/png.png"
                               : item.fileType.includes('word')
                                 ? "/assets/icons/word.png"
                                 : item.fileType.includes('excel')
                                   ? "/assets/icons/excel.png"
                                   : "/assets/icons/file.png"
                         }
                    />
                    {item?.fileName}
                  </StyleCommentPDF>)
                }
        </StyledMailListInfoPDF> : null}
    </StyledCommentsListItem>
}
export default MailCommentList
