import React from "react";
import { OrderLine } from "../../../Models/orders";
import { useDoxleThemeStore } from "../../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import {
  StyledOrderLineCellData,
  StyledOrderLineItemContainer,
} from "./StyledComponentBookingDocketDetail";
import { ORDER_LINE_FIELDS_LIST } from "../../../store/useBookingProjectStore";
import { formatter } from "../../../../Utilities/FunctionUtilities";

type Props = { lineItem: OrderLine };

const OrderLineItem = ({ lineItem }: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }),
    shallow
  );
  return (
    <StyledOrderLineItemContainer
      $themeColor={doxleThemeColor}
      $doxleFont={doxleFont}
    >
      {ORDER_LINE_FIELDS_LIST.map((field, index) => (
        <StyledOrderLineCellData
          $hasBorderRight={index !== ORDER_LINE_FIELDS_LIST.length - 1}
          $themeColor={doxleThemeColor}
          $doxleFont={doxleFont}
          $cellWidth={
            field.fieldName === "Qty"
              ? 10
              : field.fieldName === "Price" || field.fieldName === "Subtotal"
              ? 20
              : undefined
          }
          key={`${lineItem.orderLineId}#${field.fieldName}`}
          $horizontalAlign={
            field.keyLine === "description" ? "flex-start" : "center"
          }
        >
          {field.keyLine === "description" && (
            <span className="data-text"> {lineItem.description} </span>
          )}

          {field.keyLine === "quantity" && (
            <span className="data-text">
              {`${Number(lineItem.quantity).toFixed(0)} ${
                lineItem.quantityFormula
              }`}
            </span>
          )}

          {field.keyLine === "itemCost" && (
            <span className="data-text">
              {formatter.format(Number(lineItem.itemCost))}{" "}
            </span>
          )}

          {field.keyLine === "lineCost" && (
            <span className="data-text">
              {formatter.format(Number(lineItem.lineCost))}{" "}
            </span>
          )}
        </StyledOrderLineCellData>
      ))}
    </StyledOrderLineItemContainer>
  );
};

export default OrderLineItem;
