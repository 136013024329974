import React from "react";
import {
  TDoxleFontMode,
  useDoxleThemeStore,
} from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { useShallow } from "zustand/react/shallow";
import { useDoxleCurrentContextStore } from "../../../DoxleGeneralStore/useDoxleCurrentContext";
import DoxleNestedPopover, {
  useRootNavBarPopoverMenuContext,
} from "../../../DoxleDesignPattern/DoxleNestedPopover";
import { usePopupState } from "material-ui-popup-state/hooks";
import {
  StyledMainMenuPopover,
  StyledNavMenuItem,
} from "./StyledNavBarEllipsis";
import { bindHover, bindFocus } from "material-ui-popup-state";
import { FaAngleRight, FaCheck } from "react-icons/fa6";
import { DoxleFontSwitchIcon } from "../../../DoxleDesignPattern/DoxleGeneralIcons";
import { StyledFontMenuItem } from "../MenuToggledApp/StyledMenuToggledApp";
import { FontPopoverIcon } from "../DoxleTopMenuIcon";

type Props = { popupId: string };

const NavFontSubMenu = ({ popupId }: Props) => {
  const { staticMenuColor, doxleThemeColor, setFontMode, fontMode } =
    useDoxleThemeStore(
      useShallow((state) => ({
        staticMenuColor: state.staticMenuColor,
        doxleThemeColor: state.doxleThemeColor,
        setFontMode: state.setFontMode,
        fontMode: state.fontMode,
      }))
    );

  const { parentPopupState, rootPopupState } =
    useRootNavBarPopoverMenuContext();
  const popupState = usePopupState({
    popupId,
    variant: "popover",
    parentPopupState,
  });
  if (!rootPopupState) throw new Error("must be used inside a CascadingMenu");
  const handleClickItem = React.useCallback(
    (font: TDoxleFontMode) => {
      rootPopupState.close();
      setFontMode(font);
    },
    [rootPopupState]
  );
  return (
    <StyledNavMenuItem
      layout="position"
      transition={{
        duration: 0.2,
        damping: 16,
      }}
      {...bindHover(popupState)}
      {...bindFocus(popupState)}
    >
      <DoxleFontSwitchIcon
        themeColor={doxleThemeColor}
        containerStyle={{
          marginRight: 3,
        }}
      />
      <span className="menu-text" style={{ flex: 1 }}>
        {" "}
        Font
      </span>

      <FaAngleRight
        className="icon-expand"
        color={staticMenuColor.staticFontColor}
        size={14}
      />
      <DoxleNestedPopover
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        popupState={popupState}
        sx={{
          "& .MuiPopover-paper": {
            backgroundColor: `${staticMenuColor.staticBg} !important`,
            overflow: "visible",
            padding: "0px !important",
          },
        }}
      >
        <StyledMainMenuPopover style={{ padding: "0px" }}>
          <StyledFontMenuItem onClick={() => handleClickItem("Default")}>
            {fontMode === "Default" && (
              <FaCheck
                color={staticMenuColor.staticFontColor}
                size={20}
                style={{
                  position: "absolute",
                  alignSelf: "center",
                  left: 13,
                  top: "50%",
                  transform: "translateY(-50%)",
                }}
              />
            )}
            <FontPopoverIcon
              themeColor={doxleThemeColor}
              containerStyle={{ width: 24 }}
            />

            <span className="menu-text">Barlow</span>
          </StyledFontMenuItem>

          <StyledFontMenuItem onClick={() => handleClickItem("Serif")}>
            {fontMode === "Serif" && (
              <FaCheck
                color={staticMenuColor.staticFontColor}
                size={20}
                style={{
                  position: "absolute",
                  alignSelf: "center",
                  left: 13,
                  top: "50%",
                  transform: "translateY(-50%)",
                }}
              />
            )}
            <FontPopoverIcon
              themeColor={doxleThemeColor}
              containerStyle={{ width: 24 }}
            />
            <span className="menu-text">Serif</span>
          </StyledFontMenuItem>

          <StyledFontMenuItem onClick={() => handleClickItem("Mono")}>
            {fontMode === "Mono" && (
              <FaCheck
                color={staticMenuColor.staticFontColor}
                size={20}
                style={{
                  position: "absolute",
                  alignSelf: "center",
                  left: 13,
                  top: "50%",
                  transform: "translateY(-50%)",
                }}
              />
            )}
            <FontPopoverIcon
              themeColor={doxleThemeColor}
              containerStyle={{ width: 24 }}
            />
            <span className="menu-text">Mono</span>
          </StyledFontMenuItem>

          <StyledFontMenuItem onClick={() => handleClickItem("Inter")}>
            {fontMode === "Inter" && (
              <FaCheck
                color={staticMenuColor.staticFontColor}
                size={20}
                style={{
                  position: "absolute",
                  alignSelf: "center",
                  left: 13,
                  top: "50%",
                  transform: "translateY(-50%)",
                }}
              />
            )}
            <FontPopoverIcon
              themeColor={doxleThemeColor}
              containerStyle={{ width: 24 }}
            />
            <span className="menu-text">Inter</span>
          </StyledFontMenuItem>
        </StyledMainMenuPopover>
      </DoxleNestedPopover>
    </StyledNavMenuItem>
  );
};

export default NavFontSubMenu;
