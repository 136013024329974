import React, { useCallback, useEffect, useState } from "react";
import { useFileStore } from "../Store/useFileStore";
import { shallow } from "zustand/shallow";
import useDoxleNotificationStore from "../../DoxleGeneralStore/useDoxleNotificationStore";
import FilesQueryAPI, {
  IAddFileMutateProps,
} from "../QueryHooks/FileStorageQueryAPI";
import { useDoxleCurrentContextStore } from "../../DoxleGeneralStore/useDoxleCurrentContext";
import { DoxleFolder } from "../../Models/files";
import { useEditDocketSideScreenStore } from "../../CoreContent/EditDocketSideScreen/EditDocketSideScreenStore/useEditDocketSideScreenStore";
import { DropEvent, FileRejection } from "react-dropzone";
import { useProjectStore } from "../../DoxleGeneralStore/useprojectStore";
import { useIsMutating } from "@tanstack/react-query";
import { useShallow } from "zustand/react/shallow";

type Props = {};

interface AddFilePopover {
  anchorAddPopover: HTMLElement | null;
  onDrop: <T extends File>(
    acceptedFiles: T[],
    fileRejections: FileRejection[],
    event: DropEvent
  ) => void;
  handleClosePopper: () => void;
  addFolderClicked: () => void;
  addFileClicked: () => void;
}
const useAddFilePopover = (props: Props): AddFilePopover => {
  const [anchorAddPopover, setAnchorAddPopover] = useState<HTMLElement | null>(
    null
  );

  const { company, currentProject, addCurrentErrorFiles } =
    useDoxleCurrentContextStore(
      useShallow((state) => ({
        company: state.currentCompany,
        currentProject: state.currentProject,
        addCurrentErrorFiles: state.addCurrentErrorFiles,
      }))
    );
  const {
    currentFolder,
    setCurrentFolder,
    filterRootFolderQuery,
    filterRootFileQuery,
    currentLevel,
  } = useFileStore(
    useShallow((state) => ({
      currentFolder: state.currentFolder,
      setCurrentFolder: state.setCurrentFolder,
      filterRootFolderQuery: state.filterRootFolderQuery,
      filterRootFileQuery: state.filterRootFileQuery,
      currentLevel: state.currentLevel,
    }))
  );
  const { edittedDocket, setDocketAddButtonFn } = useEditDocketSideScreenStore(
    useShallow((state) => ({
      edittedDocket: state.edittedDocket,
      setDocketAddButtonFn: state.setHandleAddButtonFn,
    }))
  );

  const setProjectAddButtonFn = useProjectStore(
    useShallow((state) => state.setFunctionNewButton)
  );
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification
  );

  const addFileQuery = FilesQueryAPI.useAddFileQuery({
    company,
    showNotification,
    filter: currentFolder
      ? {
          folderId: currentFolder.folderId,
        }
      : filterRootFileQuery,
  });

  const onAddFolderSuccessCallback = (newFolder?: DoxleFolder) => {
    if (newFolder) {
      sessionStorage.setItem("currentFolderId", newFolder.folderId);

      setCurrentFolder(newFolder);
    }
  };
  const addFolderQuery = FilesQueryAPI.useAddFolderQuery({
    company,
    showNotification,
    onAddFolderSuccessCallback,
    filter: filterRootFolderQuery,
  });

  const handleShowPopover = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorAddPopover(event.currentTarget);
  };

  const handleClosePopper = () => {
    setAnchorAddPopover(null);
  };

  const addFolderClicked = () => {
    setAnchorAddPopover(null);
    //* NEED TO ADD NEW FOLDER AND RERENDER UI
    const addFolderObject: DoxleFolder = {
      folderId: "",
      folderName: "New Folder",
      lastModified: "",
      docket:
        currentLevel === "Docket" && edittedDocket
          ? edittedDocket.docketPk
          : null,
      project:
        currentLevel === "Project" && currentProject
          ? currentProject.projectId
          : currentLevel === "Docket" && edittedDocket
          ? edittedDocket.project
          : null,
      company: company?.companyId,
    };
    addFolderQuery.mutate(addFolderObject);
  };

  const addFileClicked = () => {
    setAnchorAddPopover(null);
  };

  const onDrop = useCallback(
    <T extends File>(
      acceptedFiles: T[],
      fileRejections: FileRejection[],
      event: DropEvent
    ) => {
      const uploadedData: IAddFileMutateProps = { files: acceptedFiles };
      if (currentFolder) uploadedData.folderId = currentFolder.folderId;
      else if (currentLevel === "Docket" && edittedDocket)
        uploadedData.docketId = edittedDocket.docketPk;
      else if (currentLevel === "Project" && currentProject)
        uploadedData.projectId = currentProject.projectId;
      addFileQuery.mutate(uploadedData);
      if (fileRejections.length > 0) {
        addCurrentErrorFiles(fileRejections);
      }
    },
    [currentFolder, currentLevel, edittedDocket, currentProject]
  );

  // const isAddingFolder = useIsMutating({mutationKey: ["add-folder"]});

  useEffect(() => {
    if (currentLevel === "Project") {
      setProjectAddButtonFn([
        {
          btnFunction: handleShowPopover,
        },
      ]);
    } else if (currentLevel === "Docket") {
      setDocketAddButtonFn([
        {
          btnFunction: handleShowPopover,
          isLoading: addFolderQuery.isLoading || addFileQuery.isLoading,
        },
      ]);
    }
  }, [currentLevel, addFolderQuery.isLoading, addFileQuery.isLoading]);

  useEffect(() => {
    return () => {
      setDocketAddButtonFn([]);
      setProjectAddButtonFn([]);
    };
  }, []);

  return {
    anchorAddPopover,
    onDrop,
    handleClosePopper,
    addFolderClicked,
    addFileClicked,
  };
};

export default useAddFilePopover;
