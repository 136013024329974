import { useDoxleCurrentContextStore } from "../../DoxleGeneralStore/useDoxleCurrentContext";
import { shallow } from "zustand/shallow";
import { useDoxleAuthStore } from "../../DoxleGeneralStore/useDoxleAuthStore";
import useDoxleNotificationStore from "../../DoxleGeneralStore/useDoxleNotificationStore";
import DoxleAPI from "../../Services/DoxleAPI";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useMemo } from "react";
import { DrawingSet, Sheet } from "../Models/takeOffs";
import { AxiosError, AxiosResponse, isAxiosError } from "axios";
import {
  AxiosBackendErrorReturn,
  IApiPaginatedData,
} from "../../Models/axiosReturn";

interface UseRetrieveDrawingsListProps {
  enabled: boolean;
}
const useRetrieveDrawingsList = ({ enabled }: UseRetrieveDrawingsListProps) => {
  const { company, project } = useDoxleCurrentContextStore(
    (state) => ({
      company: state.currentCompany,
      project: state.currentProject,
    }),
    shallow
  );

  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );
  let params: any = { view: "MEASURE" };
  if (project) params.project = project.projectId;

  return useQuery(
    ["measure-drawings", company?.companyId ?? "", project?.projectId ?? ""],
    () =>
      DoxleAPI.get<any>(`/drawings/`, {
        headers: {
          "User-Company": company!.companyId,
        },
        params,
      }),
    {
      enabled: Boolean(company && project && enabled),
      retry: 1,
      // staleTime: 10 * 60 * 1000,
      refetchInterval: false,
      refetchIntervalInBackground: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      onError: (error) => {
        if (showNotification) {
          if (isAxiosError<AxiosBackendErrorReturn>(error)) {
            showNotification(
              `${error?.response?.status ?? "ERROR"}: ${
                error.response?.data.detail ?? "UNKNOWN ERROR"
              }`,
              "error",
              String(
                error?.response?.data?.detail ?? "Error Fetching Drawings"
              ).substring(0, 300)
            );
          } else {
            showNotification(
              "Something Wrong!",
              "error",
              "Error Fetching Drawings"
            );
          }
        }
      },
    }
  );
};
interface UpdateScaleProps {
  id: string;
  scale: number;
}

const useUpdateDrawingsSet = () => {
  const queryClient = useQueryClient();
  const { company, project } = useDoxleCurrentContextStore(
    (state) => ({
      company: state.currentCompany,
      project: state.currentProject,
    }),
    shallow
  );

  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );
  return useMutation({
    mutationFn: ({ id, scale }: UpdateScaleProps) =>
      DoxleAPI.post<DrawingSet>(
        `/drawings/scale/${id}/`,
        { scale: scale.toPrecision(13) },
        {
          headers: {
            "User-Company": company?.companyId ?? "",
          },
        }
      ),
    onSuccess: (data, variables) => {
      // showNotification(`Successfully updated scales`, "success");
      queryClient.setQueryData(
        [
          "measure-drawings",
          company?.companyId ?? "",
          project?.projectId ?? "",
        ],
        (old: any) => ({
          ...old,
          data: old.data.map((oldDrawing: DrawingSet) => {
            if (oldDrawing?.setId === data.data.setId) return data.data;
            else return oldDrawing;
          }),
        })
      );
    },
    onError: (error) => {
      if (showNotification) {
        if (isAxiosError<AxiosBackendErrorReturn>(error)) {
          showNotification(
            `${error?.response?.status ?? "ERROR"}: ${
              error.response?.data.detail ?? "UNKNOWN ERROR"
            }`,
            "error",
            String(
              error?.response?.data?.detail ?? "Error Updating Scales"
            ).substring(0, 300)
          );
        } else {
          showNotification(
            "Something Wrong!",
            "error",
            "Error Updating Scales"
          );
        }
      }
    },
  });
};

const useUpdateSheet = () => {
  const queryClient = useQueryClient();
  const { company, project } = useDoxleCurrentContextStore(
    (state) => ({
      company: state.currentCompany,
      project: state.currentProject,
    }),
    shallow
  );

  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );
  return useMutation({
    mutationFn: ({ id, scale }: UpdateScaleProps) =>
      DoxleAPI.patch(
        `/sheet/${id}/`,
        { scale: scale.toPrecision(13) },
        {
          headers: {
            "User-Company": company?.companyId ?? "",
          },
        }
      ),
    onSuccess: (data: AxiosResponse<Sheet>, variables) => {
      // showNotification(`Successfully updated scales`, "success");
      queryClient.setQueryData(
        [
          "measure-drawings",
          company?.companyId ?? "",
          project?.projectId ?? "",
        ],
        (old: any) => ({
          ...old,
          data: old.data.map((oldDrawing: DrawingSet) => {
            if (oldDrawing.setId !== data.data.drawingSet) return oldDrawing;
            return {
              ...oldDrawing,
              sheets: oldDrawing.sheets.map((sheet) => {
                if (sheet.sheetId !== data.data.sheetId) return sheet;
                else return data.data;
              }),
            };
          }),
        })
      );
    },
    onError: (error) => {
      if (showNotification) {
        if (isAxiosError<AxiosBackendErrorReturn>(error)) {
          showNotification(
            `${error?.response?.status ?? "ERROR"}: ${
              error.response?.data.detail ?? "UNKNOWN ERROR"
            }`,
            "error",
            String(
              error?.response?.data?.detail ?? "Error Updating Scales"
            ).substring(0, 300)
          );
        } else {
          showNotification(
            "Something Wrong!",
            "error",
            "Error Updating Scales"
          );
        }
      }
    },
  });
};

const DrawingsQueryAPI = {
  useRetrieveDrawingsList,
  useUpdateSheet,
  useUpdateDrawingsSet,
};
export default DrawingsQueryAPI;
