import React from "react";
import { useDoxleThemeStore } from "../../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import {
  StyledQAListHeaderContainer,
  StyledQAListTableHeaderCell,
} from "./StyledComponentQAProjectList";
import { QA_LIST_TABLE_FIELD } from "../../Store/useProjectQAStore";
import DoxleRefetchButton from "../../../../DoxleDesignPattern/DoxleButtons/DoxleRefetchButton";
import useGetProjectQAList from "./Hooks/useGetProjectQAList";

type Props = {
  shouldShowRefetch?: boolean;
};

const QAListHeader = ({ shouldShowRefetch }: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }),
    shallow
  );
  // const { isErrorFetchingQAList, isRefetchingQAList, handleRefetchQAList } =
  //   useGetProjectQAList({});
  return (
    <>
      <StyledQAListHeaderContainer
        layout="position"
        transition={{
          duration: 0.2,
          damping: 16,
        }}
      >
        {QA_LIST_TABLE_FIELD.map((header, idx) => (
          <StyledQAListTableHeaderCell
            key={`header#${idx}#${header.headerName}`}
            $widthRatio={header.cellSize}
            $horizontalAlign={
              header.headerName === "Title" ? "flex-start" : "center"
            }
          >
            <span
              className="cell-header-text"
              style={{ marginLeft: header.headerName === "Title" ? "3rem" : 0 }}
            >
              {header.headerName}
            </span>
          </StyledQAListTableHeaderCell>
        ))}
      </StyledQAListHeaderContainer>
    </>
  );
};

export default QAListHeader;
