import { useInfiniteQuery, useMutation, useQuery } from "@tanstack/react-query";
import { Company } from "../../Models/company";
import DoxleAPI from "../../Services/DoxleAPI";
import {
  AddQuoteRequests,
  QuoteRequest,
  QuoteRequestLink,
  QuoteResponse,
  QuoteStorage,
} from "../models/quote";
import {
  AxiosBackendErrorReturn,
  IApiPaginatedData,
} from "../../Models/axiosReturn";
import { BaseAPIProps } from "../../Models/baseAPIProps";
import useSetQuoteRequestQueryData from "../CustomQueryHooks/useSetQuoteRequestQueryData";
import { formatTISODate } from "../../Utilities/FunctionUtilities";
import { isEmpty } from "lodash";
import useSetQuoteResponseData from "../CustomQueryHooks/useSetQuoteResponseData";
import { isAxiosError } from "axios";

interface GetQuoteRequestQueryProps extends BaseAPIProps {
  filter: IFilterQuoteRequestQuery;
}
export interface IFilterQuoteRequestQuery {
  docketId?: string;
  projectId?: string;
  searchText?: string;
  withAttachment?: boolean;
}
const useRetrieveQuoteRequestList = ({
  company,
  filter,
  showNotification,
}: GetQuoteRequestQueryProps) => {
  const qKey = getQuoteRequestQKey(filter, company);
  let url = "/quote_req/?page=1";
  const { projectId, docketId, searchText, withAttachment } = filter;
  const getParams: any = {
    page_size: 20,
  };
  if (docketId) getParams.docket = docketId;
  if (projectId) getParams.project = projectId;
  if (searchText) getParams.search = searchText;
  if (withAttachment) getParams.with_attachment = true;
  const quoteRequestQuery = useInfiniteQuery(
    qKey,
    ({ pageParam = url }) =>
      DoxleAPI.get<IApiPaginatedData<QuoteRequest>>(pageParam, {
        headers: {
          "User-Company": company!.companyId,
        },
        params: getParams,
      }),
    {
      enabled: Boolean(company && (projectId || docketId)),
      retry: 1,
      staleTime: 5 * 60 * 1000,
      cacheTime: 6 * 60 * 1000,
      refetchInterval: 6 * 60 * 1000,
      refetchIntervalInBackground: true,
      refetchOnMount: false,
      getNextPageParam: (prevData) => prevData.data.next,
      onError: (error) => {
        if (showNotification) {
          if (isAxiosError<AxiosBackendErrorReturn>(error)) {
            showNotification(
              `${error?.response?.status ?? "ERROR"}: ${
                error.response?.data.detail ?? "UNKNOWN ERROR"
              }`,
              "error",
              String(
                error?.response?.data?.detail ??
                  "Failed to get quote request list"
              ).substring(0, 300)
            );
          } else {
            showNotification(
              "Something Wrong!",
              "error",
              "Failed to get quote request list"
            );
          }
        }
      },
    }
  );
  return quoteRequestQuery;
};

interface UpdateQuoteRequestQueryProps extends Omit<BaseAPIProps, "company"> {
  onSuccessCb?: (item?: QuoteRequest) => void;
}
export interface UpdateQuoteRequestParams {
  quoteRequestId: string;
  updateBody: Partial<QuoteRequest>;
  company: Company | undefined;
}
const useUpdateQuoteRequest = ({
  showNotification,
  onSuccessCb,
}: UpdateQuoteRequestQueryProps) => {
  const mutation = useMutation({
    mutationKey: getQRequestMutationKey("update"),
    mutationFn: async ({
      company,
      quoteRequestId,
      updateBody,
    }: UpdateQuoteRequestParams) => {
      let body: any = {};
      if (updateBody.status) body.status = updateBody.status;
      if (updateBody.supplier !== undefined)
        body.supplier = updateBody.supplier;
      if (updateBody.supplierContacts)
        body.supplierContacts = updateBody.supplierContacts;
      if (updateBody.toEmails) body.toEmails = updateBody.toEmails;
      if (updateBody.ccEmails) body.ccEmails = updateBody.ccEmails;
      if (updateBody.emailSubject) body.emailSubject = updateBody.emailSubject;
      if (updateBody.emailBody !== undefined)
        body.emailBody = updateBody.emailBody;
      // if (updateBody.attachments) body.attachments = updateBody.attachments;
      if (updateBody.scopeGroups) body.scopeGroups = updateBody.scopeGroups;
      // if (updateBody.taskGroups) body.taskGroups = updateBody.taskGroups;
      if (updateBody.dueDate !== undefined) body.dueDate = updateBody.dueDate;

      return DoxleAPI.patch<QuoteRequest>(
        "/quote_req/" + quoteRequestId + "/",
        body,
        {
          headers: {
            "User-Company": company?.companyId ?? "",
          },
        }
      );
    },
    onSuccess: (result, variables, context) => {
      // if (showNotification)
      //   showNotification(
      //     "Item Updated",
      //     "success",
      //     "SUCCESSFULLY Updated Quote Request"
      //   );
      if (onSuccessCb) onSuccessCb(result.data);
    },
    onError: (error, variables, context) => {
      if (showNotification)
        if (showNotification) {
          if (isAxiosError<AxiosBackendErrorReturn>(error)) {
            showNotification(
              `${error?.response?.status ?? "ERROR"}: ${
                error.response?.data.detail ?? "UNKNOWN ERROR"
              }`,
              "error",
              String(
                error?.response?.data?.detail ??
                  "Failed to update quote request"
              ).substring(0, 300)
            );
          } else {
            showNotification(
              "Something Wrong!",
              "error",
              "Failed to update quote request"
            );
          }
        }
    },
  });

  return mutation;
};

interface AddQuoteQueryProps extends GetQuoteRequestQueryProps {
  onSuccessCb?: Function;
}
interface AddManyQuoteRequestResponse {
  created: QuoteRequest[];
  errors: any;
}
const useAddManyQuoteRequests = ({
  company,
  showNotification,

  filter,
  onSuccessCb,
}: AddQuoteQueryProps) => {
  const { handleMultiAddQuoteRequest } = useSetQuoteRequestQueryData({
    filter,
  });
  const mutation = useMutation({
    mutationKey: ["add-quote-request"],
    mutationFn: async ({
      docket,
      coreDocket,
      suppliers,
      supplierContacts,
      emailBody,
      attachments,
      dueDate,
      docketSpecifications,
      taskGroups,
      emailNow,
    }: AddQuoteRequests) => {
      const formData = new FormData();
      attachments?.forEach((file) => formData.append("attachments", file));
      if (docket) formData.append("docket", docket);
      suppliers?.forEach((supplier) => formData.append("suppliers", supplier));
      formData.append("emailBody", emailBody);
      if (dueDate) formData.append("dueDate", formatTISODate(dueDate));
      if (taskGroups)
        taskGroups.forEach((taskGroup) =>
          formData.append("scopeGroups", taskGroup)
        );
      return DoxleAPI.post<AddManyQuoteRequestResponse>(
        "/quote_req/",
        formData,
        {
          headers: {
            "User-Company": company?.companyId,
          },
        }
      );
    },
    onSuccess: (result, variables, context) => {
      if (result.data.created.length > 0)
        handleMultiAddQuoteRequest(result.data.created);

      if (onSuccessCb) onSuccessCb();
    },
    // if (handleScrollToBottomBudgetTable) handleScrollToBottomBudgetTable()
    onError: (error, variables, context) => {
      if (showNotification) {
        if (isAxiosError<AxiosBackendErrorReturn>(error)) {
          showNotification(
            `${error?.response?.status ?? "ERROR"}: ${
              error.response?.data.detail ?? "UNKNOWN ERROR"
            }`,
            "error",
            String(
              error?.response?.data?.detail ?? "Failed to add quote request"
            ).substring(0, 300)
          );
        } else {
          showNotification(
            "Something Wrong!",
            "error",
            "Failed to add quote request"
          );
        }
      }
    },
  });

  return mutation;
};

interface DeleteMultipleQuotesProps extends GetQuoteRequestQueryProps {}
const useDeleteMultipleQuoteRequests = ({
  company,
  filter,
  showNotification,
}: DeleteMultipleQuotesProps) => {
  const { handleRemoveMultiQuoteRequest } = useSetQuoteRequestQueryData({
    filter,
  });
  const mutation = useMutation({
    mutationFn: async (quoteRequestIds: string[]) => {
      return DoxleAPI.post(
        "/quote_req/delete/",
        { quoteRequestIds: quoteRequestIds },
        {
          headers: {
            "User-Company": company?.companyId ?? "",
          },
        }
      );
    },
    onSuccess: (result, variables, context) => {
      handleRemoveMultiQuoteRequest(variables);
    },
    onError: (error, variables, context) => {
      if (showNotification) {
        if (isAxiosError<AxiosBackendErrorReturn>(error)) {
          showNotification(
            `${error?.response?.status ?? "ERROR"}: ${
              error.response?.data.detail ?? "UNKNOWN ERROR"
            }`,
            "error",
            String(
              error?.response?.data?.detail ?? "Failed to delete quote request"
            ).substring(0, 300)
          );
        } else {
          showNotification(
            "Something Wrong!",
            "error",
            "Failed to delete quote request"
          );
        }
      }
    },
  });

  return mutation;
};

interface QuoteRequestEmailQueryProps extends BaseAPIProps {
  onSuccessCb?: () => void;
}
export interface QuoteRequestEmailQueryParams {
  quoteRequestIds?: string[];
  dockets?: string[];
  projects?: string[];
}
const useEmailQuoteRequests = ({
  company,
  showNotification,
  onSuccessCb,
}: QuoteRequestEmailQueryProps) => {
  const mutation = useMutation({
    mutationKey: ["email-quote-request"],
    mutationFn: async ({
      quoteRequestIds,
      dockets,
      projects,
    }: QuoteRequestEmailQueryParams) => {
      let body: any = {};
      if (quoteRequestIds) body.requests = quoteRequestIds;
      if (dockets) body.dockets = dockets;
      if (projects) body.project = projects;
      return DoxleAPI.post("/quote_req/send/", body, {
        headers: {
          "User-Company": company?.companyId ?? "",
        },
      });
    },
    onSuccess: (result, variables, context) => {
      if (onSuccessCb) onSuccessCb();
      // if (showNotification)
      //   showNotification(
      //     "Quotes Emailed",
      //     "success",
      //     "SUCCESSFULLY Emailing all quotes"
      //   );
      // const successfulDeletedEstimate: string[] = result.data.successful as string[];
      //!IMPORTANT: THIS IS OVERWRITE CACHE DATA FROM REACT QUERY, NEED TO STRICTLY FOLLOW THE DATA STRUCTURE
      // queryClient.setQueryData(["quote-req", docketId], (old: any) =>
      //   old
      //     ? {
      //       ...old,
      //       data: {
      //         ...old.data,
      //         results: [
      //           ...(old.data.results as QuoteRequest[]).filter(
      //             (quoteRequest) =>
      //               !successfulDeletedEstimate.includes(quoteRequest.requestId)
      //           ),
      //         ],
      //       },
      //     }
      //     : old
      // );
    },
    onError: (error, variables, context) => {
      if (showNotification) {
        if (isAxiosError<AxiosBackendErrorReturn>(error)) {
          showNotification(
            `${error?.response?.status ?? "ERROR"}: ${
              error.response?.data.detail ?? "UNKNOWN ERROR"
            }`,
            "error",
            String(
              error?.response?.data?.detail ?? "Failed to email quotes"
            ).substring(0, 300)
          );
        } else {
          showNotification(
            "Something Wrong!",
            "error",
            "Failed to email quotes"
          );
        }
      }
    },
  });

  return mutation;
};

const useQuoteLink = (
  quoteAccessId: string,
  showNotification: (
    message: string,
    messageType: "success" | "error",
    extraMessage?: string
  ) => void
) => {
  const qKey = ["quote-req", quoteAccessId];
  let url = "/quote_link/" + quoteAccessId + "/";
  return useQuery(qKey, () => DoxleAPI.get<QuoteRequestLink>(url), {
    enabled: Boolean(quoteAccessId),
    retry: (failureCount, error) => {
      if (error?.response?.status === 408 || error?.response?.status === 410)
        return false;
      else if (failureCount < 2) return true;
      return false;
    },
    onSuccess: (res) => {},
    staleTime: 10 * 60 * 1000,
    cacheTime: 11 * 60 * 1000,
    refetchOnMount: false,

    onError: (error: any) => {
      if (showNotification) {
        if (isAxiosError<AxiosBackendErrorReturn>(error)) {
          showNotification(
            `${error?.response?.status ?? "ERROR"}: ${
              error.response?.data.detail ?? "UNKNOWN ERROR"
            }`,
            "error",
            String(
              error?.response?.data?.detail ?? "Error fetching quote data"
            ).substring(0, 300)
          );
        } else {
          showNotification(
            "Something Wrong!",
            "error",
            "Error fetching quote data"
          );
        }
      }
    },
    refetchInterval: false,
  });
};

export interface AddQuoteRequestStorageResult {
  createdFiles: QuoteStorage[];
  errorFiles: any[];
}
interface AddQuoteRequestStorage extends BaseAPIProps {
  onSuccessCb?: (data?: AddQuoteRequestStorageResult) => void;
}

export interface AddQuoteRequestStorageParams {
  requestId: string;
  files: File[];
}
const useAddQuoteRequestStorage = ({
  company,
  showNotification,

  onErrorCb,
  onSuccessCb,
}: AddQuoteRequestStorage) => {
  const mutation = useMutation({
    mutationKey: getQStorageMutationKey("add"),
    mutationFn: async ({ requestId, files }: AddQuoteRequestStorageParams) => {
      const formData = new FormData();
      formData.append("request", requestId);
      if (files.length > 0)
        files.forEach((file) => formData.append("attachments", file));
      return DoxleAPI.post<AddQuoteRequestStorageResult>(
        "/quote_req_storage/",
        formData,
        {
          headers: {
            "User-Company": company?.companyId,
          },
        }
      );
    },
    onSuccess: (result, variables, context) => {
      // if (showNotification)
      //   showNotification(
      //     "Item Updated",
      //     "success",
      //     "SUCCESSFULLY Added Quote Request"
      //   );
      if (onSuccessCb) onSuccessCb(result.data);
    },

    onError: (error, variables, context) => {
      if (showNotification) {
        if (isAxiosError<AxiosBackendErrorReturn>(error)) {
          showNotification(
            `${error?.response?.status ?? "ERROR"}: ${
              error.response?.data.detail ?? "UNKNOWN ERROR"
            }`,
            "error",
            String(
              error?.response?.data?.detail ?? "Fail To add quote request"
            ).substring(0, 300)
          );
        } else {
          showNotification(
            "Something Wrong!",
            "error",
            "Fail To add quote request"
          );
        }
      }
    },
  });

  return mutation;
};
//* QUOTE RESPONSE SECTION
export interface MutationProps {
  quoteResponse: QuoteResponse;
  attachment?: File[];
}
interface AddQuoteResponseProps {
  showNotification?: (
    message: string,
    messageType: "success" | "error",
    extraMessage?: string
  ) => void;
  onSuccessCb?: (newResponse?: QuoteResponse) => void;
}
const useAddQuoteResponse = ({
  showNotification,
  onSuccessCb,
}: AddQuoteResponseProps) => {
  return useMutation({
    mutationKey: getQResponseMutationKey("add"),
    mutationFn: async ({
      quoteResponse,

      attachment,
    }: MutationProps) => {
      const formData = new FormData();
      for (const [key, value] of Object.entries(quoteResponse)) {
        if (key === "lines") formData.append(key, JSON.stringify(value));
        else if (value !== undefined && value !== null)
          formData.append(key, value);
      }
      if (!isEmpty(attachment)) {
        attachment?.forEach((file) => formData.append("file", file));
      }
      return DoxleAPI.post("/quote_res/", formData, {
        headers: {
          "User-Company": quoteResponse?.company ?? "",
        },
      });
    },
    onSuccess: (result, variables, context) => {
      if (onSuccessCb) onSuccessCb(result.data);
      // if (showNotification)
      //   showNotification(
      //     "Item Updated",
      //     "success",
      //     "SUCCESSFULLY Updated TakeOff"
      //   );
    },
    onError: (error, variables, context) => {
      if (showNotification) {
        if (isAxiosError<AxiosBackendErrorReturn>(error)) {
          showNotification(
            `${error?.response?.status ?? "ERROR"}: ${
              error.response?.data.detail ?? "UNKNOWN ERROR"
            }`,
            "error",
            String(
              error?.response?.data?.detail ?? "Fail To add quote response"
            ).substring(0, 300)
          );
        } else {
          showNotification(
            "Something Wrong!",
            "error",
            "Fail To add quote response"
          );
        }
      }
    },
  });
};
interface RetrieveQuoteResponseQueryProps extends BaseAPIProps {
  requestId: string;
  enabled: boolean;
}

const useRetrieveQuoteResponseDetail = ({
  company,
  requestId,
  enabled,
  showNotification,
}: RetrieveQuoteResponseQueryProps) => {
  const qKey = ["quote-res-detail", requestId];
  let url = "/quote_res/";
  return useQuery(
    qKey,
    () =>
      DoxleAPI.get<IApiPaginatedData<QuoteResponse>>(url, {
        headers: {
          "User-Company": company?.companyId ?? "",
        },
        params: {
          request: requestId,
        },
      }),
    {
      enabled: Boolean(company && enabled),
      retry: 1,
      onError: (error: any) => {
        if (showNotification) {
          if (isAxiosError<AxiosBackendErrorReturn>(error)) {
            showNotification(
              `${error?.response?.status ?? "ERROR"}: ${
                error.response?.data.detail ?? "UNKNOWN ERROR"
              }`,
              "error",
              String(
                error?.response?.data?.detail ?? "Error fetching quote data"
              ).substring(0, 300)
            );
          } else {
            showNotification(
              "Something Wrong!",
              "error",
              "Error fetching quote data"
            );
          }
        }
      },
      staleTime: 2 * 60 * 1000,
      cacheTime: 3 * 60 * 1000,
    }
  );
};

export interface IFilterQuoteResponseQuery {
  docketId?: string;
  projectId?: string;
  requestId?: string;
  userAdded?: boolean;
}
interface RetrieveQuoteResponseListQueryProps extends BaseAPIProps {
  filter: IFilterQuoteResponseQuery;
}
const useRetrieveQuoteResponseList = ({
  company,
  filter,
  showNotification,
}: RetrieveQuoteResponseListQueryProps) => {
  const qKey = getQuoteResponseQKey(filter, company);
  const { docketId, projectId, requestId, userAdded } = filter;
  let url = "/quote_res/?page=1";
  const getParams: any = {
    page_size: 20,
  };
  if (userAdded) {
    getParams.user_added = true;
  }
  if (projectId) getParams.project = projectId;
  if (docketId) getParams.docket = docketId;
  if (requestId) getParams.request = requestId;
  const quoteResponseQuery = useInfiniteQuery(
    qKey,
    ({ pageParam = url }) =>
      DoxleAPI.get<IApiPaginatedData<QuoteResponse>>(pageParam, {
        headers: {
          "User-Company": company!.companyId,
        },
        params: getParams,
      }),
    {
      enabled: Boolean(
        company?.companyId && (docketId || projectId || requestId || userAdded)
      ),
      retry: 1,
      staleTime: 5 * 60 * 1000,
      cacheTime: 6 * 60 * 1000,
      refetchOnMount: false,
      refetchInterval: 6 * 60 * 1000,
      getNextPageParam: (prevData) => prevData.data?.next,
      onError: (error) => {
        if (showNotification) {
          if (isAxiosError<AxiosBackendErrorReturn>(error)) {
            showNotification(
              `${error?.response?.status ?? "ERROR"}: ${
                error.response?.data.detail ?? "UNKNOWN ERROR"
              }`,
              "error",
              String(
                error?.response?.data?.detail ??
                  "Failed to get quote response list"
              ).substring(0, 300)
            );
          } else {
            showNotification(
              "Something Wrong!",
              "error",
              "Failed to get quote response list"
            );
          }
        }
      },
    }
  );
  return quoteResponseQuery;
};

interface DeleteMultipleQuoteResponseProps
  extends RetrieveQuoteResponseListQueryProps {}
const useDeleteMultipleQuoteResponse = ({
  company,
  filter,
  showNotification,
}: DeleteMultipleQuoteResponseProps) => {
  const { handleRemoveMultiQuoteResponse } = useSetQuoteResponseData({
    filter,
  });
  const mutation = useMutation({
    mutationKey: getQResponseMutationKey("delete-multi"),
    mutationFn: async (quoteResponseIds: string[]) => {
      return DoxleAPI.post(
        "/quote_res/delete/",
        { quoteResponseIds: quoteResponseIds },
        {
          headers: {
            "User-Company": company?.companyId ?? "",
          },
        }
      );
    },
    onSuccess: (result, variables, context) => {
      handleRemoveMultiQuoteResponse(variables);
      // if (showNotification)
      //   showNotification(
      //     "Item Updated",
      //     "success",
      //     "SUCCESSFULLY Updated TakeOff"
      //   );
    },
    onError: (error, variables, context) => {
      if (showNotification) {
        if (isAxiosError<AxiosBackendErrorReturn>(error)) {
          showNotification(
            `${error?.response?.status ?? "ERROR"}: ${
              error.response?.data.detail ?? "UNKNOWN ERROR"
            }`,
            "error",
            String(
              error?.response?.data?.detail ?? "Fail To Delete Quote Responses"
            ).substring(0, 300)
          );
        } else {
          showNotification(
            "Something Wrong!",
            "error",
            "Fail To Delete Quote Responses"
          );
        }
      }
    },
  });

  return mutation;
};

//* QUOTE STORAGE
interface DeleteQuoteRequestAttachment extends BaseAPIProps {
  onSuccessDeleteCb?: (deletedId?: string) => void;
}
const useDeleteQuoteRequestAttachment = ({
  company,

  showNotification,
  onSuccessDeleteCb,
}: DeleteQuoteRequestAttachment) => {
  const mutation = useMutation({
    mutationKey: getQStorageMutationKey("delete"),
    mutationFn: async (attachmentId: string) => {
      return DoxleAPI.delete("/quote_storage/" + attachmentId + "/", {
        headers: {
          "User-Company": company?.companyId ?? "",
        },
      });
    },
    onSuccess: (result, variables, context) => {
      if (onSuccessDeleteCb) onSuccessDeleteCb(variables);
    },
    onError: (error, variables, context) => {
      if (showNotification) {
        if (isAxiosError<AxiosBackendErrorReturn>(error)) {
          showNotification(
            `${error?.response?.status ?? "ERROR"}: ${
              error.response?.data.detail ?? "UNKNOWN ERROR"
            }`,
            "error",
            String(
              error?.response?.data?.detail ??
                "Fail To Delete Quote Request Attachment"
            ).substring(0, 300)
          );
        } else {
          showNotification(
            "Something Wrong!",
            "error",
            "Fail To Delete Quote Request Attachment"
          );
        }
      }
    },
  });

  return mutation;
};
//# HELLPER FUNCTIONS
export const getQuoteRequestQKey = (
  filter: IFilterQuoteRequestQuery,
  company: Company | undefined
) => {
  let baseQKey = ["quote-req"];
  if (company) baseQKey.push(company.companyId);
  const { docketId, projectId, searchText, withAttachment } = filter;
  if (projectId) baseQKey.push(projectId);
  if (docketId) baseQKey.push(docketId);
  if (searchText) baseQKey.push(searchText);
  if (withAttachment) baseQKey.push("attachments");
  return baseQKey;
};

export const getQuoteResponseQKey = (
  filter: IFilterQuoteResponseQuery,
  company: Company | undefined
) => {
  const qKey = ["quote-res"];
  if (company) qKey.push(company.companyId);
  const { projectId, docketId, requestId, userAdded } = filter;
  if (projectId) qKey.push(projectId);
  if (docketId) qKey.push(docketId);
  if (requestId) qKey.push(requestId);
  if (userAdded) qKey.push("user_added");

  return qKey;
};

//*MUTATION KEY
export const getQStorageMutationKey = (action: "delete" | "update" | "add") => [
  `${action}-quote-storage`,
];
export const getQRequestMutationKey = (action: "delete" | "update" | "add") => [
  `${action}--quote-request`,
];
export const getQResponseMutationKey = (
  action: "delete" | "update" | "add" | "delete-multi"
) => [`${action}--quote-response`];
const QuotesQueryAPI = {
  useRetrieveQuoteRequestList,
  useAddManyQuoteRequests,
  useUpdateQuoteRequest,
  useDeleteMultipleQuoteRequests,
  useEmailQuoteRequests,
  useQuoteLink,
  useRetrieveQuoteResponseDetail,
  useAddQuoteResponse,
  useRetrieveQuoteResponseList,
  useDeleteMultipleQuoteResponse,
  useDeleteQuoteRequestAttachment,
  useAddQuoteRequestStorage,
  // useUpdateQuoteResponse,
  // useDeleteQuotes,
};

export default QuotesQueryAPI;
