import { Accordion } from "@mui/material";
import styled from "styled-components";
import {DoxleThemeColor} from "../../../../../DoxleGeneralStore/useDoxleThemeStore";

type StyledAccordionProps = {
  displayBorderTop: boolean;
  themeColor: DoxleThemeColor;
};

export const StyledAccordion = styled(Accordion)<{
  $displayBorderTop: boolean;
  $themeColor: DoxleThemeColor;
}>`
  && {
    margin: 0;
    box-shadow: none;
    
  }

  font-size: 1.8rem;

  &.MuiAccordion-root.Mui-expanded {
    background-color: ${(p) => p.$themeColor.primaryContainerColor};
    margin: 0;
  }

  &.MuiAccordion-root {
    margin: 0;
    box-shadow: none;
    max-width: 447px;
    border-bottom: solid 2px ${(p) => p.$themeColor.borderColor};
    border-left: solid 2px ${(p) => p.$themeColor.borderColor};
    border-right: solid 2px ${(p) => p.$themeColor.borderColor};
    //background-color: ${(p) => p.$themeColor.primaryContainerColor};
    background: transparent;
    ${(p) => p.$displayBorderTop && `border-top:solid 2px #e6e6e6;`}
    
  }

  & .MuiAccordionSummary-root {
    padding: 16px 30px;
  }

  &.MuiAccordion-root:before {
    opacity: 0;
    
  }
`;
