import React from "react";
import {
  FilterRetrieveScopeOfWorkQuery,
  formScopeOfWorkGroupQkey,
} from "../QueryAPI/scopeOfWorkQueryAPI";
import { useDoxleCurrentContextStore } from "../../DoxleGeneralStore/useDoxleCurrentContext";
import { useQueryClient } from "@tanstack/react-query";
import { ScopeOfWorkGroup } from "../Model/scopeOfWorks";
import { produce } from "immer";
import {
  IApiPaginatedData,
  InfiniteAxiosQueryData,
} from "../../Models/axiosReturn";

type Props = {
  filter: FilterRetrieveScopeOfWorkQuery;
  overwrite?: boolean;
  appendPos?: "start" | "end";
};

interface SetScopeOfWorkGroupQueryData {
  handleRemoveScopeGroup: (deletedGroupId: string) => void;
  handleUpdateSOWGroup: (updatedScopeGroup: ScopeOfWorkGroup) => void;
  handleAddSOWGroup: (newBudget: ScopeOfWorkGroup) => void;
}
const useSetScopeOfWorkGroupQueryData = ({
  filter,
  overwrite = true,
  appendPos = "end",
}: Props): SetScopeOfWorkGroupQueryData => {
  const { company } = useDoxleCurrentContextStore((state) => ({
    company: state.currentCompany,
  }));
  const queryClient = useQueryClient();
  const qKey = formScopeOfWorkGroupQkey(filter, company);

  const handleRemoveScopeGroup = (deletedGroupId: string) => {
    const queryData = queryClient.getQueryData(qKey);
    if (overwrite && queryData) {
      queryClient.setQueryData<InfiniteAxiosQueryData<ScopeOfWorkGroup>>(
        qKey,
        (old) => {
          if (old) {
            return produce(old, (draft) => {
              let pageIndexContainItem: number = old.pages.findIndex((page) =>
                Boolean(
                  page.data.results.find(
                    (group: ScopeOfWorkGroup) =>
                      group.scopeGroupId === deletedGroupId
                  ) !== undefined
                )
              );
              if (pageIndexContainItem !== -1)
                draft.pages[pageIndexContainItem].data.results = draft.pages[
                  pageIndexContainItem
                ].data.results.filter(
                  (group) => group.scopeGroupId !== deletedGroupId
                );
            });
          } else queryClient.invalidateQueries(qKey);
        }
      );
    } else queryClient.invalidateQueries(qKey);
  };

  const handleUpdateSOWGroup = (updatedScopeGroup: ScopeOfWorkGroup) => {
    const queryData = queryClient.getQueryData(qKey);
    if (overwrite && queryData) {
      queryClient.setQueryData<InfiniteAxiosQueryData<ScopeOfWorkGroup>>(
        qKey,
        (old) => {
          if (old) {
            let pageIndexContainItem: number = old.pages.findIndex((page) =>
              Boolean(
                page.data.results.find(
                  (group) =>
                    group.scopeGroupId === updatedScopeGroup.scopeGroupId
                ) !== undefined
              )
            );
            if (pageIndexContainItem !== -1) {
              return produce(old, (draft) => {
                const matchedItem = draft.pages[
                  pageIndexContainItem
                ].data.results.find(
                  (group) =>
                    group.scopeGroupId === updatedScopeGroup.scopeGroupId
                );
                if (matchedItem) {
                  Object.assign(matchedItem, updatedScopeGroup);
                  return draft;
                } else queryClient.invalidateQueries(qKey);
              });
            } else queryClient.invalidateQueries(qKey);
          } else queryClient.invalidateQueries(qKey);
        }
      );
    } else queryClient.invalidateQueries(qKey);
  };

  const handleAddSOWGroup = (newBudget: ScopeOfWorkGroup) => {
    const sowGroupData = queryClient.getQueryData(qKey);
    if (sowGroupData && overwrite) {
      queryClient.setQueryData<InfiniteAxiosQueryData<ScopeOfWorkGroup>>(
        qKey,
        (old) => {
          if (old) {
            const lengthOfPages = old.pages.length;
            return produce(old, (draftOld) => {
              draftOld.pages = produce(draftOld.pages, (draftPages: any) => {
                if (appendPos === "start") {
                  draftPages[0].data.results.unshift(newBudget);
                } else {
                  draftPages[lengthOfPages - 1].data.results.push(newBudget);
                }

                return draftPages;
              });

              return draftOld;
            });
          } else queryClient.invalidateQueries(qKey);
        }
      );
    } else queryClient.invalidateQueries(qKey);
  };
  return {
    handleRemoveScopeGroup,
    handleUpdateSOWGroup,
    handleAddSOWGroup,
  };
};

export default useSetScopeOfWorkGroupQueryData;
