import { useCallback, useMemo, useState } from "react";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";

import { AddMultiScopeImageData } from "../../QueryAPI/scopeOfWorkQueryAPI";
import { useDropzone } from "react-dropzone";
import {
  StyledAddScopeItemImageSectionContainer,
  StyledScopeImgList,
  StyledScopeImgWrapper,
} from "./StyledComponentAddScopeImage";
import { ScopeOfWorkImage, ScopeOfWorkItem } from "../../Model/scopeOfWorks";
import useAddScopeItemImageSection from "./Hooks/useAddScopeItemImageSection";
import useGetScopeImage from "../../Hooks/useGetScopeImage";
import { GridComponents, GridItemContent, VirtuosoGrid } from "react-virtuoso";
import React from "react";
import ScopeImageItem from "./ScopeImageItem";
import ScopeImageListskeleton from "./ScopeImageListskeleton";
import DoxleEmptyPlaceHolder from "../../../DoxleDesignPattern/DoxleEmptyPlaceHolder/DoxleEmptyPlaceHolder";
import { DoxleImageGalleryIcon } from "../../../DoxleDesignPattern/DoxleGeneralIcons";
import {
  DoxleImageGalleryItem,
  useDoxleImageGalleryStore,
} from "../../../DoxleDesignPattern/DoxleGallery/Store/useDoxleImageGalleryStore";
import { shallow } from "zustand/shallow";
import { uniqueId } from "lodash";

type Props = {
  scopeItem: ScopeOfWorkItem;
  handleCloseAddScopeImgSection: () => void;
};
interface ScopeImgListContext {}
const AddScopeItemImageSection = ({
  scopeItem,
  handleCloseAddScopeImgSection,
}: Props) => {
  const { doxleThemeColor } = useDoxleThemeStore((state) => ({
    doxleThemeColor: state.doxleThemeColor,
    doxleFont: state.doxleFont,
  }));

  const { onDrop } = useAddScopeItemImageSection({
    scopeItem,
  });

  const dummySOWImgData: ScopeOfWorkImage = useMemo(
    () => ({
      imageId: uniqueId("dummy"),
      scopeItem: scopeItem.scopeItemId,
      scopeGroup: scopeItem.scopeGroup,
      scopeImageTitle: "DUMMY",
      scopeImageDescription: "DUMMY",
      path: "",
      thumbPath: "",
      index: 0,
      isDummy: true,
    }),
    []
  );
  const {
    isFetchingScopeImgList,
    scopeImageList,
    isErrorFetchingScopeImgList,
  } = useGetScopeImage({
    scopeItemId: scopeItem.scopeItemId,
    enabled: scopeItem.imageCount > 0,
  });
  const { setImageList, setCurrentImageIndex } = useDoxleImageGalleryStore(
    (state) => ({
      setImageList: state.setImageList,
      setCurrentImageIndex: state.setCurrentImageIndex,
    }),
    shallow
  );
  const galleryImageList: DoxleImageGalleryItem[] = useMemo(
    () =>
      scopeImageList
        .filter((item) => !item.isDummy)
        .map((image) => ({
          uri: image.url ?? "",
          thumbnailUrl: image.thumbUrl ?? "",
          name: image.scopeImageTitle,
          size: "0.5",
        })),
    [scopeImageList]
  );
  const handleClickImage = useCallback(
    (imgIndex: number) => {
      setImageList(galleryImageList);
      setCurrentImageIndex(imgIndex);
    },
    [galleryImageList]
  );
  const { getRootProps, open: openPickFile } = useDropzone({
    onDrop,
    accept: {
      "image/png": [".png"],
      "image/jpg": [".jpg"],
      "image/jpeg": [".jpeg"],
    },
    noClick: scopeImageList.length > 0,
  });

  const components: GridComponents<ScopeImgListContext> = useMemo(
    () => ({
      Item: (props) => (
        <StyledScopeImgWrapper
          layout="position"
          {...props}
          transition={{
            duration: 0.2,
            damping: 12,
          }}
        />
      ),
      List: React.forwardRef((props, ref) => (
        <StyledScopeImgList {...props} ref={ref} />
      )),

      // Scroller: React.forwardRef((props, ref) => (
      //   <StyledScopeImgListScroller ref={ref} {...props} />
      // )),
    }),
    []
  );

  const itemContent: GridItemContent<ScopeOfWorkImage, ScopeImgListContext> =
    useCallback(
      (index, item) => (
        <ScopeImageItem
          imgItem={item}
          key={`scopeItem#${item.imageId}`}
          handleClickImage={handleClickImage}
          imgIndex={index}
          openPickFile={item.isDummy ? openPickFile : undefined}
        />
      ),
      [handleClickImage, openPickFile]
    );

  const gridListStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
  return (
    <StyledAddScopeItemImageSectionContainer
      {...getRootProps({
        $themeColor: doxleThemeColor,
      })}
      // $themeColor={doxleThemeColor}
    >
      {(!isFetchingScopeImgList ||
        (isFetchingScopeImgList && scopeItem.imageCount === 0)) && (
        <>
          {scopeImageList.length > 0 && (
            <VirtuosoGrid
              style={gridListStyle}
              data={[...scopeImageList, dummySOWImgData]}
              components={components}
              itemContent={itemContent}
            />
          )}

          {scopeImageList.length === 0 && (
            <DoxleEmptyPlaceHolder
              headTitleText="Add Images To This Task!"
              subTitleText="Drop files or click in this zone to add"
              headTitleTextStyle={{
                fontSize: "25px",
              }}
              subTitleTextStyle={{
                fontSize: "14px",
              }}
              containerBgColor="transparent"
              illustration={
                <DoxleImageGalleryIcon
                  themeColor={doxleThemeColor}
                  containerStyle={{
                    maxWidth: 160,
                    width: "80%",
                    marginBottom: 14,
                  }}
                />
              }
            />
          )}
        </>
      )}

      {isFetchingScopeImgList && scopeItem.imageCount > 0 && (
        <ScopeImageListskeleton />
      )}

      {/* {addedScopeImages.length > 0 && (
        <>
          <AddedScopeImageDisplayer
            addedScopeImages={addedScopeImages}
            setAddedScopeImages={setAddedScopeImages}
          />

          <DoxleIconButton
            iconSource={
              <FileUploadIcon
                htmlColor={doxleThemeColor.primaryFontColor}
                style={{ fontSize: "3.5rem" }}
              />
            }
            buttonWrapperStyle={{
              position: "absolute",
              top: 4,
              right: 4,
              zIndex: 10,
            }}
            buttonSize={40}
            onClick={handleAddScopeImages}
          />
        </>
      )} */}
    </StyledAddScopeItemImageSectionContainer>
  );
};

export default AddScopeItemImageSection;
