import React, { useState } from "react";
import { useAddQuoteResponseContext } from "../AddQuoteResponse";
import { produce } from "immer";
import { QuoteLine } from "../../../models/quote";
import { useDoxleCurrentContextStore } from "../../../../DoxleGeneralStore/useDoxleCurrentContext";
import { shallow } from "zustand/shallow";

type Props = {};
interface AddResponseLineSection {
  newQuoteLineDescription: string;
  setNewQuoteLineDescription: React.Dispatch<React.SetStateAction<string>>;
  handleKeydownDescription: (
    event: React.KeyboardEvent<HTMLDivElement>
  ) => void;
  handleAddNewLine: () => void;
}
const useAddResponseLineSection = (props: Props): AddResponseLineSection => {
  const [newQuoteLineDescription, setNewQuoteLineDescription] = useState("");
  const { setNewQuoteResponse } = useAddQuoteResponseContext();
  const company = useDoxleCurrentContextStore(
    (state) => state.currentCompany,
    shallow
  );
  const handleAddNewLine = () => {
    setNewQuoteResponse((prev) =>
      produce(prev, (draft) => {
        const newOrderLine: QuoteLine = {
          lineId: "",
          company: company?.companyId,

          index: prev.lines.length + 1,
          description: "Untitled line",
          itemCost: "0",
          quantity: "0",
          lineCost: "0",
          unit: "each",
        };
        draft.lines.push(newOrderLine);
        return draft;
      })
    );
  };
  const handleKeydownDescription = (
    event: React.KeyboardEvent<HTMLDivElement>
  ) => {
    if (event.nativeEvent.key === "Enter") {
      event.preventDefault();
      if (newQuoteLineDescription) {
        setNewQuoteResponse((prev) =>
          produce(prev, (draft) => {
            const newOrderLine: QuoteLine = {
              lineId: "",
              company: company?.companyId,

              index: prev.lines.length + 1,
              description: newQuoteLineDescription,
              itemCost: "0",
              quantity: "0",
              lineCost: "0",
              unit: "each",
            };
            draft.lines.push(newOrderLine);
            return draft;
          })
        );
        setNewQuoteLineDescription("");
      } else event.currentTarget.blur();
    }

    if (event.nativeEvent.key === "Escape") {
      event.preventDefault();
      setNewQuoteLineDescription("");
      event.currentTarget.blur();
    }
  };
  return {
    newQuoteLineDescription,
    setNewQuoteLineDescription,
    handleKeydownDescription,
    handleAddNewLine,
  };
};

export default useAddResponseLineSection;
