import React from "react";
import { TSelectContactMode } from "./Hooks/useDoxleSelectContactDropdown";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import useAddContactForm from "./Hooks/useAddContactForm";
import {
  StyledAddContactFieldWrapper,
  StyledAddContactForm,
  StyledAddContactSubmitButton,
  StyledAddContactTextfield,
} from "./StyledDoxleSelectContactDropdown";
import { produce } from "immer";
import { AnimatePresence, motion } from "framer-motion";
import {
  checkEmailValid,
  checkPhoneValid,
  checkValidABN,
  editRgbaAlpha,
} from "../../Utilities/FunctionUtilities";
import { FiPlus } from "react-icons/fi";
import ProgressScreen from "../../Utilities/Lottie/ProgressScreen";

type Props = {
  variants?: Exclude<TSelectContactMode, "both">;
  handleCloseForm: () => void;
};

const AddContactForm = ({ variants, handleCloseForm }: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }),
    shallow
  );
  const {
    shouldShowError,
    handleAddContactCompany,
    newCompanyContact,
    setNewCompanyContact,
    isAddingContact,
    newContact,
    setNewContact,
    handleAddContact,
  } = useAddContactForm({ handleCloseForm });

  return (
    <StyledAddContactForm
      animate={{
        y: [10, 0],
        opacity: [0, 1],
      }}
    >
      {variants === "company" && (
        <>
          <StyledAddContactFieldWrapper
            $themeColor={doxleThemeColor}
            $doxleFont={doxleFont}
            layout="position"
          >
            <span className="field-label">Contact name</span>
            <StyledAddContactTextfield
              $themeColor={doxleThemeColor}
              $doxleFont={doxleFont}
              variant="standard"
              placeholder="Enter contact name..."
              value={newCompanyContact.name}
              onChange={(e) =>
                setNewCompanyContact(
                  produce((draft) => {
                    draft.name = e.target.value;
                    return draft;
                  })
                )
              }
            />
            <AnimatePresence>
              {shouldShowError && !newCompanyContact.name && (
                <motion.span
                  className="error-text"
                  animate={{
                    x: [-5, 0],
                    opacity: [0, 1],
                  }}
                  exit={{ x: [0, -5], opacity: [1, 0] }}
                >
                  Please enter contact name!
                </motion.span>
              )}
            </AnimatePresence>
          </StyledAddContactFieldWrapper>

          <StyledAddContactFieldWrapper
            $themeColor={doxleThemeColor}
            $doxleFont={doxleFont}
            layout="position"
          >
            <span className="field-label">Email</span>
            <StyledAddContactTextfield
              $themeColor={doxleThemeColor}
              $doxleFont={doxleFont}
              variant="standard"
              placeholder="Enter email..."
              value={newCompanyContact.email}
              onChange={(e) =>
                setNewCompanyContact(
                  produce((draft) => {
                    draft.email = e.target.value;
                    return draft;
                  })
                )
              }
            />
            <AnimatePresence>
              {shouldShowError &&
                newCompanyContact.email &&
                !checkEmailValid(newCompanyContact.email) && (
                  <motion.span
                    className="error-text"
                    animate={{
                      x: [-5, 0],
                      opacity: [0, 1],
                    }}
                    exit={{ x: [0, -5], opacity: [1, 0] }}
                  >
                    Invalid email
                  </motion.span>
                )}
            </AnimatePresence>
          </StyledAddContactFieldWrapper>

          <StyledAddContactFieldWrapper
            $themeColor={doxleThemeColor}
            $doxleFont={doxleFont}
            layout="position"
          >
            <span className="field-label">Phone</span>
            <StyledAddContactTextfield
              $themeColor={doxleThemeColor}
              $doxleFont={doxleFont}
              variant="standard"
              placeholder="Enter phone..."
              value={newCompanyContact.phone}
              onChange={(e) =>
                setNewCompanyContact(
                  produce((draft) => {
                    draft.phone = e.target.value;
                    return draft;
                  })
                )
              }
            />
            {shouldShowError &&
              (!newCompanyContact.phone ||
                (newCompanyContact.phone &&
                  !checkPhoneValid(newCompanyContact.phone))) && (
                <motion.span
                  className="error-text"
                  animate={{
                    x: [-5, 0],
                    opacity: [0, 1],
                  }}
                  exit={{ x: [0, -5], opacity: [1, 0] }}
                >
                  {!newCompanyContact.phone
                    ? "Please enter phone number"
                    : "Invalid phone"}
                </motion.span>
              )}
          </StyledAddContactFieldWrapper>

          <StyledAddContactFieldWrapper
            $themeColor={doxleThemeColor}
            $doxleFont={doxleFont}
            layout="position"
          >
            <span className="field-label">Address</span>
            <StyledAddContactTextfield
              $themeColor={doxleThemeColor}
              $doxleFont={doxleFont}
              variant="standard"
              placeholder="Enter address..."
              value={newCompanyContact.address}
              onChange={(e) =>
                setNewCompanyContact(
                  produce((draft) => {
                    draft.address = e.target.value;
                    return draft;
                  })
                )
              }
            />
            {shouldShowError && !newCompanyContact.address && (
              <motion.span
                className="error-text"
                animate={{
                  x: [-5, 0],
                  opacity: [0, 1],
                }}
                exit={{ x: [0, -5], opacity: [1, 0] }}
              >
                Please enter address
              </motion.span>
            )}
          </StyledAddContactFieldWrapper>

          <StyledAddContactFieldWrapper
            $themeColor={doxleThemeColor}
            $doxleFont={doxleFont}
            layout="position"
          >
            <span className="field-label">Abn</span>
            <StyledAddContactTextfield
              $themeColor={doxleThemeColor}
              $doxleFont={doxleFont}
              variant="standard"
              placeholder="Enter ABN..."
              value={newCompanyContact.abn}
              onChange={(e) =>
                setNewCompanyContact(
                  produce((draft) => {
                    draft.abn = e.target.value;
                    return draft;
                  })
                )
              }
            />
            {shouldShowError &&
              newCompanyContact.abn &&
              !checkValidABN(newCompanyContact.abn) && (
                <motion.span
                  className="error-text"
                  animate={{
                    x: [-5, 0],
                    opacity: [0, 1],
                  }}
                  exit={{ x: [0, -5], opacity: [1, 0] }}
                >
                  Invalid ABN
                </motion.span>
              )}
          </StyledAddContactFieldWrapper>
        </>
      )}

      {variants === "contact" && (
        <>
          <StyledAddContactFieldWrapper
            $themeColor={doxleThemeColor}
            $doxleFont={doxleFont}
            layout="position"
          >
            <span className="field-label">First Name *</span>
            <StyledAddContactTextfield
              $themeColor={doxleThemeColor}
              $doxleFont={doxleFont}
              variant="standard"
              placeholder="Enter first name..."
              value={newContact.firstName}
              onChange={(e) =>
                setNewContact(
                  produce((draft) => {
                    draft.firstName = e.target.value;
                    return draft;
                  })
                )
              }
            />
            <AnimatePresence>
              {shouldShowError && !newContact.firstName && (
                <motion.span
                  className="error-text"
                  animate={{
                    x: [-5, 0],
                    opacity: [0, 1],
                  }}
                  exit={{ x: [0, -5], opacity: [1, 0] }}
                >
                  Please enter first name!
                </motion.span>
              )}
            </AnimatePresence>
          </StyledAddContactFieldWrapper>
          <StyledAddContactFieldWrapper
            $themeColor={doxleThemeColor}
            $doxleFont={doxleFont}
            layout="position"
          >
            <span className="field-label">Last Name *</span>
            <StyledAddContactTextfield
              $themeColor={doxleThemeColor}
              $doxleFont={doxleFont}
              variant="standard"
              placeholder="Enter last name..."
              value={newContact.lastName}
              onChange={(e) =>
                setNewContact(
                  produce((draft) => {
                    draft.lastName = e.target.value;
                    return draft;
                  })
                )
              }
            />
            <AnimatePresence>
              {shouldShowError && !newContact.lastName && (
                <motion.span
                  className="error-text"
                  animate={{
                    x: [-5, 0],
                    opacity: [0, 1],
                  }}
                  exit={{ x: [0, -5], opacity: [1, 0] }}
                >
                  Please enter last name!
                </motion.span>
              )}
            </AnimatePresence>
          </StyledAddContactFieldWrapper>
          <StyledAddContactFieldWrapper
            $themeColor={doxleThemeColor}
            $doxleFont={doxleFont}
            layout="position"
          >
            <span className="field-label">Email</span>
            <StyledAddContactTextfield
              $themeColor={doxleThemeColor}
              $doxleFont={doxleFont}
              variant="standard"
              placeholder="Enter email..."
              value={newContact.email}
              onChange={(e) =>
                setNewContact(
                  produce((draft) => {
                    draft.email = e.target.value;
                    return draft;
                  })
                )
              }
            />
            <AnimatePresence>
              {shouldShowError &&
                ((newContact.email && !checkEmailValid(newContact.email)) ||
                  !newContact.email) && (
                  <motion.span
                    className="error-text"
                    animate={{
                      x: [-5, 0],
                      opacity: [0, 1],
                    }}
                    exit={{ x: [0, -5], opacity: [1, 0] }}
                  >
                    {!newContact.email
                      ? "Please enter an email"
                      : "Invalid email"}
                  </motion.span>
                )}
            </AnimatePresence>
          </StyledAddContactFieldWrapper>

          <StyledAddContactFieldWrapper
            $themeColor={doxleThemeColor}
            $doxleFont={doxleFont}
            layout="position"
          >
            <span className="field-label">Phone</span>
            <StyledAddContactTextfield
              $themeColor={doxleThemeColor}
              $doxleFont={doxleFont}
              variant="standard"
              placeholder="Enter phone..."
              value={newContact.phone}
              onChange={(e) =>
                setNewContact(
                  produce((draft) => {
                    draft.phone = e.target.value;
                    return draft;
                  })
                )
              }
            />
            {shouldShowError &&
              newContact.phone &&
              !checkPhoneValid(newContact.phone) && (
                <motion.span
                  className="error-text"
                  animate={{
                    x: [-5, 0],
                    opacity: [0, 1],
                  }}
                  exit={{ x: [0, -5], opacity: [1, 0] }}
                >
                  Invalid phone
                </motion.span>
              )}
          </StyledAddContactFieldWrapper>
        </>
      )}

      <StyledAddContactSubmitButton
        $themeColor={doxleThemeColor}
        $doxleFont={doxleFont}
        layout="position"
        transition={{
          damping: 16,
        }}
        onClick={(e) => {
          if (variants === "company") handleAddContactCompany();
          else handleAddContact(e);
        }}
      >
        <FiPlus
          color="inherit"
          size="max(1.8rem,18px)"
          style={{ marginRight: 4 }}
        />
        Add
      </StyledAddContactSubmitButton>

      {isAddingContact && (
        <ProgressScreen
          progressType="progress"
          progressText="Adding contact..."
          animationSize={70}
          containerStyle={{
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            position: "absolute",
            zIndex: 10,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: editRgbaAlpha({
              rgbaColor: doxleThemeColor.primaryContainerColor,
              alpha: "0.8",
            }),
          }}
          textStyle={{
            fontSize: "max(1.6rem,16px)",
            color: doxleThemeColor.primaryFontColor,
            marginTop: 8,
          }}
          hasBackDrop={true}
        />
      )}
    </StyledAddContactForm>
  );
};

export default AddContactForm;
