import React from "react";
import PDFIcon from "../../Assets/adobePdfIcon.svg";
import MicrosoftIcon from "../../Assets/microsoftIcon.svg";
import ExcelIcon from "../../Assets/excelIcon.svg";
import {
  StyledAddedAttachmentItem,
  StyledAddedResponseAttachmentItem,
  StyledAttachmentName,
  StyledFileImage,
  StyledImageContainer,
} from "./StyledComponentsAddQuoteResponse";
import { AiFillCloseCircle } from "react-icons/ai";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { ImageOutlined } from "@mui/icons-material";
import { useAddQuoteResponseContext } from "./AddQuoteResponse";
import { produce } from "immer";
import DoxleIconButton from "../../../DoxleDesignPattern/DoxleButtons/DoxleIconButton";
import {
  getFileIconUrl,
  isImageFile,
} from "../../../Utilities/FunctionUtilities";
type Props = {
  attachment: File;
  index: number;
};
export const FILETYPE = {
  pdf: PDFIcon,
  docx: MicrosoftIcon,
  xlsx: ExcelIcon,
};
const AddedAttachmentResponse = ({ attachment, index }: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore((state) => ({
    doxleThemeColor: state.doxleThemeColor,
    doxleFont: state.doxleFont,
  }));
  const { setAddedAttachments } = useAddQuoteResponseContext();
  return (
    <StyledAddedAttachmentItem
      layout
      initial={false}
      animate={{ y: [10, 0], opacity: [0, 1] }}
      exit={{
        y: [0, 10],
        opacity: [1, 0],
      }}
      transition={{
        duration: 0.2,
      }}
    >
      <DoxleIconButton
        iconSource={
          <AiFillCloseCircle
            size={"2rem"}
            color={doxleThemeColor.primaryFontColor}
          />
        }
        buttonSize={30}
        disableAnimation
        onClick={() =>
          setAddedAttachments((prevState) =>
            produce(prevState, (draft) => {
              draft.splice(index, 1);
              return draft;
            })
          )
        }
        buttonWrapperStyle={{
          position: "absolute",
          zIndex: 10,
          top: "-1.5rem",
          right: "-0.5rem",
        }}
      />

      <StyledImageContainer>
        <StyledFileImage
          src={
            isImageFile(attachment.name)
              ? URL.createObjectURL(attachment)
              : getFileIconUrl(attachment.name)
          }
        />
      </StyledImageContainer>

      <StyledAttachmentName>{attachment.name}</StyledAttachmentName>
    </StyledAddedAttachmentItem>
  );
};

export default AddedAttachmentResponse;
