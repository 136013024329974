import {
  StyledButtonContainer,
  StyledCancelButton,
  StyledFormContainer,
  StyledFormHeader,
  StyledFormSubHeader,
  StyledSubTitleContainer,
  StyledSyncButton,
  StyledSyncButtonContainer,
  StyledSyncControllerContainer,
  StyledSyncDoxleLogoContainer,
  StyledSyncText,
  StyledSyncXeroLogoContainer,
  StyledTitleContainer,
} from "./styledComponents";
import DoxleCheckbox from "../../../DoxleDesignPattern/DoxleCheckbox/DoxleCheckbox";
import { ReactComponent as XeroIcon } from "../../../Assets/Images/xeroCircle.svg";
import React, { useEffect, useMemo, useState } from "react";
import EastIcon from "@mui/icons-material/East";
import WestIcon from "@mui/icons-material/West";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
import SyncDisabledIcon from "@mui/icons-material/SyncDisabled";
import ContactsQueryAPI, {
  DuplicateValueOptions,
  RequiredValues,
  SyncDirections,
} from "../../QueryHooks/ContactsQueryAPI";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import { useContactsStore } from "../Store/useContactsStore";
import { CheckboxProps } from "@mui/material";
import { useIsMutating } from "@tanstack/react-query";
import Loading from "../../../Utilities/Lottie/Loading";
import { DoxleTopMenuIcon } from "../../../CoreContent/DoxleSideMenu/DoxleSideMenuIcons";
import CompanyQueryAPI from "../../../Services/QueryHooks/companyQueryAPI";
import { CompanySettings } from "../../../Models/company";

const defaultRequiredValues: RequiredValues = {
  email: false,
  phone: false,
  address: false,
  abn: false,
  bank: false,
};

const SyncXeroContactsDialog = ({}) => {
  const [requiredValues, setRequiredValues] = useState<RequiredValues>(
    defaultRequiredValues
  );
  const [duplicateValues, setDuplicateValues] =
    useState<DuplicateValueOptions>("Merge");
  const [direction, setDirection] = useState<SyncDirections>("BI");
  const styleProps = useDoxleThemeStore(
    (state) => ({
      $themeColor: state.doxleThemeColor,
      $doxleFont: state.doxleFont,
      $currentTheme: state.currentTheme,
    }),
    shallow
  );
  const setAddDialog = useContactsStore(
    (state) => state.setAddDialogType,
    shallow
  );
  const isSyncingContacts = useIsMutating({
    mutationKey: ["xero-sync-contacts"],
  });
  const handleCloseDialog = () => setAddDialog(null);
  const checkboxLabelStyle: CheckboxProps["sx"] = {
    "& .MuiTypography-root": { fontWeight: 400, fontSize: 13 },
  };
  const iconStyle = {
    color: styleProps.$themeColor.doxleColor,
    transform: "scale(7.5) translateY(6px)",
  };

  const updateRequiredValues = (key: keyof RequiredValues, value: boolean) =>
    setRequiredValues((prevState) => {
      const newState: RequiredValues = { ...prevState };
      newState[key] = value;
      return newState;
    });
  const syncContactsQuery = ContactsQueryAPI.useSyncContacts({
    onSuccessCb: handleCloseDialog,
  });
  const getCompanySettingsQuery = CompanyQueryAPI.useRetrieveCompanySettings();
  const updateCompanySettingsQuery = CompanyQueryAPI.useUpdateCompanySettings();

  const handleSync = () => {
    syncContactsQuery.mutate({
      required: requiredValues,
      direction,
      duplicates: duplicateValues,
    });
  };

  const toggleDirection = () =>
    setDirection((prevState) => {
      let newDirection: SyncDirections = "D2X";
      if (prevState === "D2X") newDirection = "X2D";
      if (prevState === "X2D") newDirection = "BI";
      if (prevState === "BI") newDirection = "NONE";
      updateCompanySettingsQuery.mutate({
        contactSyncDirection: newDirection,
      });
      return newDirection;
    });

  const syncText = useMemo(
    () =>
      direction === "X2D"
        ? "After importing, changes made in Xero will update your Doxle contact company"
        : direction === "D2X"
        ? "After importing, changes made in Doxle will update your Xero contact"
        : direction === "BI"
        ? "After importing, any changes made in Doxle will update your to Xero contact, and vice versa."
        : "After importing, any changes made in Doxle will not effect Xero Contacts, and vice versa.",
    [direction]
  );

  useEffect(() => {
    if (getCompanySettingsQuery.isSuccess) {
      const settings = getCompanySettingsQuery.data?.data as CompanySettings;
      setDirection(settings.contactSyncDirection);
    }
  }, [getCompanySettingsQuery.isSuccess, getCompanySettingsQuery.data]);

  return (
    <>
      {Boolean(isSyncingContacts) && (
        <Loading text={"This may take a few minutes, please be patient"} />
      )}
      <StyledTitleContainer {...styleProps}>
        Import Contacts
      </StyledTitleContainer>
      <StyledSubTitleContainer {...styleProps}>
        Bring your contacts in from xero. You choose which contacts to bring
        into Doxle, once imported your contacts will be synced to stay up to
        date
      </StyledSubTitleContainer>
      <StyledFormContainer {...styleProps}>
        <StyledFormHeader {...styleProps}>Required Values:</StyledFormHeader>
        <StyledFormSubHeader {...styleProps}>
          Only yourXero contacts with these values set will be imported
        </StyledFormSubHeader>
        <DoxleCheckbox
          labelText={"Email"}
          labelStyle={checkboxLabelStyle}
          style={{ marginBottom: 2 }}
          onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
            updateRequiredValues("email", (e.target as any)?.checked ?? false)
          }
        />
        <DoxleCheckbox
          labelText={"Address"}
          labelStyle={checkboxLabelStyle}
          style={{ marginBottom: 2 }}
          onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
            updateRequiredValues("address", (e.target as any)?.checked ?? false)
          }
        />
        <DoxleCheckbox
          labelText={"Phone Number"}
          labelStyle={checkboxLabelStyle}
          style={{ marginBottom: 2 }}
          onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
            updateRequiredValues("phone", (e.target as any)?.checked ?? false)
          }
        />
        <DoxleCheckbox
          labelText={"ABN"}
          labelStyle={checkboxLabelStyle}
          style={{ marginBottom: 2 }}
          onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
            updateRequiredValues("abn", (e.target as any)?.checked ?? false)
          }
        />
        <DoxleCheckbox
          labelText={"Bank Details"}
          labelStyle={checkboxLabelStyle}
          style={{ marginBottom: 2 }}
          onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
            updateRequiredValues("bank", (e.target as any)?.checked ?? false)
          }
        />

        <StyledFormHeader {...styleProps}>Duplicate Values:</StyledFormHeader>
        <StyledFormSubHeader {...styleProps}>
          Choose what to do if Doxle thinks it has found a matching contact in
          your existing contacts
        </StyledFormSubHeader>
        <DoxleCheckbox
          labelText={"Merge & link"}
          labelStyle={checkboxLabelStyle}
          style={{ marginBottom: 2 }}
          checked={duplicateValues === "Merge"}
          onClick={() => setDuplicateValues("Merge")}
        />
        <DoxleCheckbox
          labelText={"Override Doxle Contact"}
          labelStyle={checkboxLabelStyle}
          style={{ marginBottom: 2 }}
          checked={duplicateValues === "Override"}
          onClick={() => setDuplicateValues("Override")}
        />
        <DoxleCheckbox
          labelText={"Create a Duplicate"}
          labelStyle={checkboxLabelStyle}
          style={{ marginBottom: 2 }}
          checked={duplicateValues === "Duplicate"}
          onClick={() => setDuplicateValues("Duplicate")}
        />
        <DoxleCheckbox
          labelText={"Ignore the Xero Contact"}
          labelStyle={checkboxLabelStyle}
          style={{ marginBottom: 2 }}
          checked={duplicateValues === "Ignore"}
          onClick={() => setDuplicateValues("Ignore")}
        />

        <StyledFormHeader {...styleProps}>Syncing Values:</StyledFormHeader>
        <StyledFormSubHeader {...styleProps}>
          Once imported what do you want us to do when you update your data
          here, or in Xero
        </StyledFormSubHeader>
        <StyledSyncControllerContainer {...styleProps}>
          <StyledSyncDoxleLogoContainer {...styleProps}>
            <DoxleTopMenuIcon
              {...styleProps.$themeColor}
              style={{
                transform: "scale(2.5) translateY(14px) translateX(-14px)",
              }}
              displayVersion={false}
            />
          </StyledSyncDoxleLogoContainer>
          <StyledSyncButtonContainer {...styleProps} onClick={toggleDirection}>
            {direction === "D2X" && <EastIcon style={iconStyle} />}
            {direction === "X2D" && <WestIcon style={iconStyle} />}
            {direction === "BI" && <SyncAltIcon style={iconStyle} />}
            {direction === "NONE" && <SyncDisabledIcon style={iconStyle} />}
          </StyledSyncButtonContainer>
          <StyledSyncXeroLogoContainer {...styleProps}>
            <XeroIcon />
          </StyledSyncXeroLogoContainer>
        </StyledSyncControllerContainer>
        <StyledSyncText {...styleProps}>{syncText}</StyledSyncText>

        <StyledButtonContainer>
          <StyledCancelButton onClick={handleCloseDialog}>
            Cancel
          </StyledCancelButton>
          <StyledSyncButton onClick={handleSync}>Start Sync</StyledSyncButton>
        </StyledButtonContainer>
      </StyledFormContainer>
    </>
  );
};
export default SyncXeroContactsDialog;
