import React, { useEffect, useState } from "react";
import useQRLogStore from "../../../Store/useQRLogStore";
import { useShallow } from "zustand/react/shallow";
import dayjs from "dayjs";
import { formatTISODate } from "../../../../Utilities/FunctionUtilities";
import { produce } from "immer";
type Props = {
  setShowDatePicker: React.Dispatch<React.SetStateAction<boolean>>;
};

const useQRLogFilterDate = ({ setShowDatePicker }: Props) => {
  const [isPickDateRange, setisPickDateRange] = useState(false);
  const [rangePickedDate, setRangePickedDate] = useState<{
    start: Date | null;
    end: Date | null;
  }>({
    start: null,
    end: null,
  });
  const { setFilterQRUserLog, filterQRUserLog } = useQRLogStore(
    useShallow((state) => ({
      setFilterQRUserLog: state.setFilterQRUserLog,
      filterQRUserLog: state.filterQRUserLog,
    }))
  );

  const onDateChange = (
    dateRange: Date | [Date | null, Date | null] | null,
    e: React.SyntheticEvent<any, Event> | undefined
  ) => {
    if (!isPickDateRange) {
      if (dateRange) {
        setFilterQRUserLog({
          search_date: formatTISODate(dateRange as Date),
          search_date_range: undefined,
        });
        setShowDatePicker(false);
      }
    } else {
      setRangePickedDate(
        produce((draft) => {
          draft.start = (dateRange as [Date | null, Date | null])[0];
          draft.end = (dateRange as [Date | null, Date | null])[1];

          return draft;
        })
      );
    }
  };
  useEffect(() => {
    if (rangePickedDate.start && rangePickedDate.end) {
      setFilterQRUserLog({
        search_date_range: [
          formatTISODate(rangePickedDate.start),
          formatTISODate(rangePickedDate.end),
        ],
        search_date: undefined,
      });
      setShowDatePicker(false);
    }
  }, [rangePickedDate]);

  const dateSelectDisplayText = filterQRUserLog.search_date
    ? `${dayjs(filterQRUserLog.search_date).format("DD MMM YYYY")}`
    : filterQRUserLog.search_date_range
    ? `${dayjs(filterQRUserLog.search_date_range[0]).format(
        "DD MMM YYYY"
      )} - ${dayjs(filterQRUserLog.search_date_range[1]).format("DD MMM YYYY")}`
    : undefined;

  const selectedStartDate = isPickDateRange
    ? rangePickedDate.start
    : filterQRUserLog.search_date
    ? new Date(filterQRUserLog.search_date)
    : null;
  const selectedEndDate = isPickDateRange ? rangePickedDate.end : null;
  return {
    dateSelectDisplayText,

    onDateChange,
    isPickDateRange,
    setisPickDateRange,
    selectedStartDate,
    selectedEndDate,
  };
};

export default useQRLogFilterDate;
