import React, { useMemo } from "react";
import {
  StyledContactsAutocompleteTextField,
  StyledEditOrderLabelField,
  StyledEditOrderWrapperField,
} from "./StyledComponentEditOrder";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import { useEditOrderStore } from "../../OrdersStore/useEditOrderStore";

type Props = {};

const EditContrractorOrder = (props: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }),
    shallow
  );
  const { editedOrder } = useEditOrderStore(
    (state) => ({
      editedOrder: state.editedOrder,
    }),
    shallow
  );

  const wrapStyle: React.CSSProperties = useMemo(
    () => ({ flex: 1, paddingRight: 30 }),
    []
  );
  return (
    <StyledEditOrderWrapperField style={wrapStyle}>
      <StyledEditOrderLabelField
        $themeColor={doxleThemeColor}
        $doxleFont={doxleFont}
      >
        Contractor
      </StyledEditOrderLabelField>

      <StyledContactsAutocompleteTextField
        $themeColor={doxleThemeColor}
        $doxleFont={doxleFont}
        variant="standard"
        placeholder="Select Contractor"
        disabled
        value={editedOrder?.supplier ?? ""}
      />
    </StyledEditOrderWrapperField>
  );
};

export default EditContrractorOrder;
