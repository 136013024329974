import {
  DoxleFont,
  DoxleThemeColor,
  DoxleTheme,
} from "../../DoxleGeneralStore/useDoxleThemeStore";
import styled from "styled-components";
import { motion } from "framer-motion";
import { Button, InputAdornment, TextField, Typography } from "@mui/material";
import { editRgbaAlpha } from "../../Utilities/FunctionUtilities";

export const StyledDocketHeader = styled.div<{
  heightInPixel: number;
  themecolor: DoxleThemeColor;
}>`
  width: calc(100% - 8px);
  min-height: ${(p) => p.heightInPixel}px;
  padding: 0 4px 0 4px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: ${(p) => p.themecolor.primaryContainerColor};
`;
export const StyledDocketHeaderIdNumberContainer = styled.div<{
  themecolor: DoxleThemeColor;
  doxleFont: DoxleFont;
}>`
  font-family: ${(p) => p.doxleFont.secondaryFont};
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.05em;
  color: ${(p) => p.themecolor.primaryFontColor};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
export const StyledDocketIdDisplayer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-left: 20px;
`;
export const StyledDocketMenuContainer = styled.div`
  display: flex;
  height: 100%;
  flex-direction: row;
  align-items: center;
  flex: 1;
  justify-content: flex-start;
  padding-left: 50px;
`;
export const StyledDocketMenuItemBtn = styled(motion(Button))<{
  themecolor: DoxleThemeColor;
  selected: boolean;
}>`
  && {
    font-family: "IBM Plex Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.05em;
    min-width: 0 !important;
    padding: 4px 14px !important;
    margin: 0 4px;
    border-radius: 20px !important;
    color: ${(p) => p.themecolor.primaryFontColor};

    text-transform: capitalize;
  }
`;

export const StyledDoxleIconContainer = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  width: 100%;

  display: flex;
  align-items: center;
  padding: 10px 0px;
  justify-content: center;

  .icon-title {
    color: ${(p) => p.$themeColor.primaryFontColor};

    font-family: ${(p) => p.$doxleFont.secondaryTitleFont};
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.6px;
  }
`;
export const StyledDoxleIconTextSpan = styled.span<{
  $doxleFont: DoxleFont;
  $themeColor: DoxleThemeColor;
}>`
  font-family: ${(p) => p.$doxleFont.secondaryTitleFont};
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: normal;
  letter-spacing: 0.05em;
  text-transform: none;
  color: ${(p) => p.$themeColor.primaryFontColor};
`;

export const StyledDocketBody = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
`;
export const StyledDocketTabPanel = styled(motion.div)`
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  min-width: 800px;
  overflow: hidden;
`;
export const StyledQuoteScreenSubTitle = styled(Typography)<{
  themeColor: DoxleThemeColor;
  doxleFont: DoxleFont;
}>`
  &.MuiTypography-root {
    font-family: ${(p) => p.doxleFont.secondaryFont};
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.05em;
    color: ${(p) => p.themeColor.primaryFontColor};
  }
`;

export const StyledQuoteRequestAttachmentItem = styled(motion.div)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.4rem 0.8rem;

  margin: 0.4rem 0.7rem;
  cursor: pointer;
  position: relative;
  color: ${(p) => p.$themeColor.primaryFontColor};
  background-color: ${(p) => p.$themeColor.primaryContainerColor};
  box-shadow: 0px 0px 4px ${(p) => p.$themeColor.primaryBoxShadowColor};
  border-radius: 1.4rem;
  font-family: ${(p) => p.$doxleFont.primaryFont};
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  .img-wrapper {
    width: 2rem;
    aspect-ratio: 1;
    margin-right: 1rem;
  }
`;
export const StyledQuoteResponseLineSection = styled.div<{
  $themeColor: DoxleThemeColor;
}>`
  padding-top: 0.8rem;
  width: 100%;
  display: flex;
  flex-direction: column;

  margin-top: 1.4rem;
`;
export const StyledQuoteResponseLineHeader = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  width: calc(100% - 1.6rem);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 0.8rem;
  margin-bottom: 0.4rem;
  .header-field-wrapper {
    color: ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.$themeColor.primaryFontColor,
        alpha: "0.7",
      })};
    font-family: ${(p) => p.$doxleFont.primaryTitleFont};
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    text-align: center;
  }
  .icon-space-header {
    width: 2rem;
  }
`;
export const StyledAddQuoteLineTextField = styled(TextField)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  && {
    overflow: hidden;
    min-width: 100px;
    width: calc(100% - 1.6rem);
    padding: 0.8rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-top: 1px solid ${(p) => p.$themeColor.primaryDividerColor};
    border-bottom: 1px solid ${(p) => p.$themeColor.primaryDividerColor};
  }
  input {
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-weight: 400;
    font-size: 1.3rem;
    line-height: normal;
    width: 100% !important;
    color: ${(p) => p.$themeColor.primaryFontColor};
    padding: 0px !important;
    &::placeholder {
      font-family: ${(p) => p.$doxleFont.primaryFont};
      font-weight: 400;
      font-size: 1.3rem;
      line-height: normal;
      color: ${(p) => p.$themeColor.primaryInputPlaceholderColor};
      padding: 0px !important;
      opacity: 1;
    }
  }
  & .MuiInput-root:after {
    border-bottom: none !important;
  }
  & .MuiInput-root:before {
    border-bottom: none !important;
  }
  & .MuiInput-root {
    border-bottom: none !important;
  }
  & .MuiInput-root:hover:not(.Mui-disabled, .Mui-error):before {
    border-bottom: none !important;
  }
  & .MuiInputBase-root {
    width: 100%;
  }
`;
export const StyledAddedQuoteLineItem = styled(motion.div)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  width: calc(100% - 1.6rem);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 0.8rem;
  background-color: ${(p) =>
    editRgbaAlpha({ rgbaColor: p.$themeColor.doxleColor, alpha: "0.08" })};
  margin-bottom: 0.4rem;
  .line-field-wrapper {
    color: ${(p) => p.$themeColor.primaryFontColor};
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0.8rem 0px;
  }
  .icon-close-wrapper {
    width: 2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;
export const StyledQuoteLineItemTextField = styled(TextField)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
  $textAlign?: "center" | "left";
}>`
  && {
    overflow: hidden;

    width: calc(100% - 0.8rem);
    padding: 0px 0.4rem;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  input {
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-weight: 400;
    font-size: 1.4rem;
    line-height: normal;
    width: 100% !important;
    color: ${(p) => p.$themeColor.primaryFontColor};
    padding: 0px !important;
    text-align: ${(p) => p.$textAlign ?? "center"};
    &::placeholder {
      font-family: ${(p) => p.$doxleFont.primaryFont};
      font-weight: 400;
      font-size: 1.4rem;
      line-height: normal;
      color: ${(p) => p.$themeColor.primaryInputPlaceholderColor};
      padding: 0px !important;
      opacity: 1;
    }
  }
  & .MuiInput-root:after {
    border-bottom: none !important;
  }
  & .MuiInput-root:before {
    border-bottom: none !important;
  }
  & .MuiInput-root {
    border-bottom: none !important;
  }
  & .MuiInput-root:hover:not(.Mui-disabled, .Mui-error):before {
    border-bottom: none !important;
  }
  & .MuiInputBase-root {
    width: 100%;
  }
`;
export const StyledQuoteLineInputAdornment = styled(InputAdornment)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  && {
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-weight: 400;
    font-size: 1.4rem;
    line-height: normal;

    color: ${(p) => p.$themeColor.primaryFontColor};
  }
`;
export const StyledQuoteResponseTotal = styled(motion.div)`
  padding: 2rem 3rem;
  width: calc(100% - 6rem);
  align-items: flex-end;

  display: flex;
  flex-direction: column;
`;

export const StyledQuoteTotalFieldWrapper = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: center;
  padding: 0.8rem 0px;
  width: 18rem;
  .total-label {
    color: ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.$themeColor.primaryFontColor,
        alpha: "0.7",
      })};
    font-family: ${(p) => p.$doxleFont.primaryTitleFont};
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.05rem;
    display: flex;
    justify-content: flex-end;
    text-transform: uppercase;
    width: 8rem;
  }
  .total-value {
    color: ${(p) => p.$themeColor.primaryFontColor};
    font-family: ${(p) => p.$doxleFont.primaryTitleFont};
    font-size: 1.3rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.05rem;
    margin-left: 8px;
    flex: 1;
    display: flex;
  }
`;
export const StyledAddQuotesButton = styled(Button)<{
  $doxleFont: DoxleFont;
  $bgColor: string;
  $textColor: string;
  $borderColor?: string;
}>`
  && {
    min-width: 7.2rem;
    height: 2.3rem;
    ${(p) => p.$borderColor && `border:1px solid ${p.$borderColor};`}
    text-transform: capitalize;
    background-color: ${(p) => p.$bgColor};
    color: ${(p) => p.$textColor};
    font-family: ${(p) => p.$doxleFont.primaryFont};
    letter-spacing: 0.06rem;
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: center;
    padding: 0 0.5rem;
    cursor: pointer;
    border-radius: 0.4rem !important;
    font-size: 1.4rem;
    font-weight: 600;
    margin: 0px 4px;

    &:hover {
      background-color: ${(p) => p.$bgColor} !important;
      opacity: 0.8;
    }
  }
  &.MuiButton-root.Mui-disabled {
    background-color: ${(p) =>
      editRgbaAlpha({ rgbaColor: p.$bgColor as any, alpha: "0.3" })} !important;
  }
`;
