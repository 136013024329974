import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
interface RootContainerStatic {
  widthSize: number;
  heightSize: number;
  numOfCol: number;
  maxItemPerPage: number;
  maxItemPerCol: number;
}
const INITIAL_ROOT_CONTAINER_STATIC: RootContainerStatic = {
  widthSize: 0,
  heightSize: 0,
  numOfCol: 0,
  maxItemPerPage: 0,
  maxItemPerCol: 0,
};
export type GridItem<TItem> = {
  itemStyle: React.CSSProperties;
  itemData: TItem;
};
export type ColumnGridItem<TItem> = {
  children: GridItem<TItem>[];
  columnStyle: React.CSSProperties;
};
export type GridPage<TItem> = {
  column: ColumnGridItem<TItem>[];
};
interface DoxleGridLayoutStore<TItem> {
  initialDataList: TItem[];
  setInitialDataList: (list: TItem[]) => void;

  //*ROOT CONTAINER STATIC
  rootContainerSize: RootContainerStatic;
  setRootContainerSize: (data: Partial<RootContainerStatic>) => void;

  extractItemKeyProp: keyof TItem;
  setExtractItemKeyProp: (key: keyof TItem) => void;

  dataPages: GridPage<TItem>[];
  addDataPage: (data: GridPage<TItem>) => void;
  editPageData: (data: GridPage<TItem>, index: number) => void;
  clearPageData: () => void;

  currentPage: number;
  setCurrentPage: (pageNum: number) => void;

  currentPageData: () => GridPage<TItem> | undefined;

  resetStore: () => void;
}
export const useDoxleGridLayoutStore = create(
  immer<DoxleGridLayoutStore<any>>((set, get) => ({
    initialDataList: [],
    setInitialDataList: (list: any[]) => set({ initialDataList: list }),

    rootContainerSize: INITIAL_ROOT_CONTAINER_STATIC,
    setRootContainerSize: (data: Partial<RootContainerStatic>) =>
      set((state) => {
        // const rootContainerSize = { ...state.rootContainerSize, ...data };
        state.rootContainerSize = { ...state.rootContainerSize, ...data };
        // return { ...state, rootContainerSize };
      }),

    extractItemKeyProp: "",
    setExtractItemKeyProp: (key: keyof any) => set({ extractItemKeyProp: key }),

    dataPages: [],
    addDataPage: (data: GridPage<any>) =>
      set((state) => {
        state.dataPages = [...state.dataPages, data];
        // const dataPages = [...state.dataPages, data];
        // return { ...state, dataPages };
      }),
    editPageData: (data: GridPage<any>, index: number) =>
      set((state) => {
        state.dataPages[index] = data;
        // let prevDataPages = [...state.dataPages];
        // prevDataPages[index] = data;

        // return { ...state, dataPages: prevDataPages };
      }),
    clearPageData: () =>
      set((state) => {
        state.dataPages = [];
      }),

    currentPage: 0,
    setCurrentPage: (pageNum: number) =>
      set((state) => {
        state.currentPage = pageNum;
      }),

    currentPageData: () => {
      if (get().dataPages) return get().dataPages[get().currentPage];
      else return undefined;
    },

    resetStore: () =>
      set((state) => {
        state.initialDataList = [];
        state.rootContainerSize = INITIAL_ROOT_CONTAINER_STATIC;
        state.extractItemKeyProp = "";
        state.dataPages = [];
        state.currentPage = 0;
        // return {
        //   ...state,
        //   initialDataList: [],
        //   rootContainerSize: INITIAL_ROOT_CONTAINER_STATIC,
        //   extractItemKeyProp: "",
        //   dataPages: [],
        //   currentPage: 0,
        // };
      }),
  }))
);
