import { Box, Divider, Menu, Typography } from "@mui/material";
import { BackupTable, MoreHoriz, TextRotationNone } from "@mui/icons-material";
import React from "react";
import { shallow } from "zustand/shallow";
import DescriptionIcon from "@mui/icons-material/Description";
import StartIcon from "@mui/icons-material/Start";

import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";

const ViewFilter = () => {
  // Anchor Element for Main Filter Menu Dropdown
  const [viewFilterAnchor, setViewFilterAnchor] =
    React.useState<null | HTMLElement>(null);

  // Anchor Element for View Menu Dropdown
  const viewOptions = [
    {
      id: 1,
      label: "Notepad View",
      icon: DescriptionIcon,
    },
    {
      id: 2,
      label: "Table View",
      icon: BackupTable,
    },
    {
      id: 3,
      label: "Fonts",
      icon: TextRotationNone,
    },
  ];

  const { doxleThemeColor, doxleFont, currentTheme, setTheme } =
    useDoxleThemeStore(
      (state) => ({
        doxleThemeColor: state.doxleThemeColor,
        doxleFont: state.doxleFont,
        currentTheme: state.currentTheme,
        setTheme: state.setTheme,
      }),
      shallow
    );

  return (
    <>
      <Box
        onClick={(event: React.MouseEvent<HTMLDivElement>) =>
          setViewFilterAnchor(event.currentTarget)
        }
        id="position-menu"
      >
        <MoreHoriz
          color="inherit"
          style={{
            color:
              currentTheme === "dark"
                ? doxleThemeColor.primaryFontColor
                : "rgba(112, 112, 112, 1)",
            marginRight: "50px",
            cursor: "pointer",
          }}
        />
      </Box>

      <Menu
        id="position-menu"
        anchorReference="anchorPosition"
        anchorEl={viewFilterAnchor}
        anchorPosition={{ top: 155, left: 1500 }}
        open={Boolean(viewFilterAnchor)}
        // anchorOrigin={{
        //   vertical: 'top',
        //   horizontal: 'left',
        // }}

        onClose={() => setViewFilterAnchor(null)}
        slotProps={{
          paper: {
            style: {
              display: "flex",
              width: "250px",
              height: "415px",
              justifyContent: "center",
              backgroundColor: doxleThemeColor.primaryBackgroundColor,
            },
            sx: {
              "& .MuiMenuItem-root.Mui-selected": {
                backgroundColor: doxleThemeColor.primaryDividerColor,
              },
              "& .MuiMenuItem-root:hover": {
                backgroundColor: doxleThemeColor.hoverColor,
              },
              // "& .MuiMenuItem-root.Mui-selected:hover": {
              //   backgroundColor: doxleThemeColor.bolderDividerColor,
              // },
              width: "100%",
            },
          },
        }}
      >
        <Box display="flex" flexDirection={"column"}>
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: "13px",
              m: "20px 10px 10px 25px",
              p: "0px 5px",
            }}
          >
            View Options
          </Typography>

          <Box
            width="230px"
            display={"flex"}
            flexDirection={"column"}
            marginLeft={"25px"}
            marginTop={"10px"}
            marginRight={"25px"}
            padding={"0 10px"}
          >
            {viewOptions.map((view, idx) => (
              <Box
                key={`filter#${view.id}#${idx}`}
                display={"flex"}
                justifyContent={"space-between"}
                sx={{
                  "&:hover": {
                    fontWeight: "bold",
                    cursor: "pointer",
                    bgcolor: "rgba(112, 112, 239, 0.1)",
                  },
                  paddingLeft: "10px",
                  // paddingRight: "10px",
                  marginRight: "10px",
                  paddingTop: "5px",
                }}
              >
                <Box marginBottom="5px" display={"flex"}>
                  <view.icon />
                  <Typography sx={{ fontSize: "13px", marginLeft: "7px" }}>
                    {view.label}
                  </Typography>
                </Box>

                {view.id === 3 && (
                  <StartIcon
                    sx={{ marginRight: "5px", color: "rgba(33, 33, 33, 0.5)" }}
                  />
                )}
              </Box>
            ))}
          </Box>
        </Box>
        <Divider variant="middle" sx={{ mt: "10px" }} />
      </Menu>
    </>
  );
};

export default ViewFilter;
