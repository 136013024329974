import { styled } from "styled-components";
import { DoxleThemeColor } from "./DoxleGeneralStore/useDoxleThemeStore";

export const StyledAppContainer = styled.div<{
  $themeColor: DoxleThemeColor;
}>`
  width: 100vw;
  height: 100vh;
  border: none;

  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: ${(p) => p.$themeColor.primaryBackgroundColor};
`;

export const StyledAppContentSection = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;
