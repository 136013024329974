import React, { useEffect, useState } from "react";
import { Circle, Group, Line, Rect, Text } from "react-konva";
import { Html } from "react-konva-utils";
import {
  TQAMarkupShape,
  TAnnotationTools,
  useQAMarkupStore,
} from "./Store/useQAMarkupStore";
import { shallow } from "zustand/shallow";
import { TEXT_FIELD_WIDTH } from "./Hooks/useQAMarkup";
import { useDoxleThemeStore } from "../../../../DoxleGeneralStore/useDoxleThemeStore";

interface props {
  shape: TQAMarkupShape;
  editable?: boolean;
}

const QAMarkupShape: React.FC<props> = ({ shape, editable }) => {
  const { zoom } = useQAMarkupStore(
    (state) => ({
      zoom: state.stageState.stageScale,
    }),
    shallow
  );
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }),
    shallow
  );
  const shapeType: TAnnotationTools = shape.type;
  let points: number[] = [];
  let x: number = 0;
  let y: number = 0;
  let width: number = 0;
  let height: number = 0;
  let radius: number = 0;
  if (shape.type === "StraightLine") {
    points = [shape.x1, shape.y1, shape.x2, shape.y2];
  } else if (shape.type === "Rectangle") {
    x = shape.x;
    y = shape.y;
    width = shape.w;
    height = shape.h;
  } else if (shape.type === "Circle") {
    x = shape.x;
    y = shape.y;
    radius = shape.r;
  } else if (shape.type === "Arrow") {
    points =
      drawArrowHead({
        x0: shape.x1,
        y0: shape.y1,
        x1: shape.x2,
        y1: shape.y2,
        h: 18,
        w: 14,
      }) ?? [];
  }

  return (
    <>
      {shape.type === "Circle" && (
        <Circle
          x={shape.x}
          y={shape.y}
          strokeWidth={4 / zoom}
          stroke={shape.color}
          radius={shape.r}
        />
      )}

      {shape.type === "StraightLine" && (
        <Line
          points={[shape.x1, shape.y1, shape.x2, shape.y2]}
          stroke={shape.color}
          strokeWidth={4 / zoom}
          lineCap="square"
        />
      )}

      {shape.type === "Rectangle" && (
        <Rect
          x={shape.x}
          y={shape.y}
          width={shape.w}
          height={shape.h}
          stroke={shape.color}
          strokeWidth={4 / zoom}
        />
      )}

      {shape.type === "Arrow" && (
        <>
          <Line
            points={[shape.x1, shape.y1, shape.x2, shape.y2]}
            stroke={shape.color}
            strokeWidth={4 / zoom}
            lineCap="square"
          />
          <Line
            points={points}
            fill={shape.color}
            lineCap="square"
            stroke={shape.color}
            strokeWidth={4 / zoom}
          />
        </>
      )}

      {shape.type === "Text" && !editable && (
        <>
          <Text
            text={shape.text}
            x={shape.x}
            width={TEXT_FIELD_WIDTH - 20}
            y={shape.y}
            fill={shape.color}
            fontFamily={doxleFont.primaryFont}
            fontSize={14}
          />
        </>
      )}
    </>
  );
};

export default React.memo(QAMarkupShape);
const drawArrowHead = (props: {
  x0: number;
  y0: number;
  x1: number;
  y1: number;
  h: number;
  w: number;
}) => {
  let { x0, y0, x1, y1, h, w } = props;

  let L = Math.sqrt(Math.pow(x0 - x1, 2) + Math.pow(y0 - y1, 2));

  if (L <= 0) return;

  //first base point coordinates
  let base_x0 = x1 + ((w / 2) * (y1 - y0)) / L;
  let base_y0 = y1 + ((w / 2) * (x0 - x1)) / L;

  //second base point coordinates
  let base_x1 = x1 - ((w / 2) * (y1 - y0)) / L;
  let base_y1 = y1 - ((w / 2) * (x0 - x1)) / L;

  let dx = 0;
  let dy = 0;
  let head_x = 0;
  let head_y = 0;

  if (x1 === x0) {
    dx = 0;
    dy = h;
    if (y1 < y0) {
      dy = -h;
    } else {
      dy = h;
    }
  } else {
    if (x1 < x0) {
      h = -h;
    }
    let slope = (y1 - y0) / (x1 - x0);
    dx = h / Math.sqrt(1 + Math.abs(slope ^ 2));
    dy = dx * slope;
  }

  //head_points
  head_x = x1 + dx;
  head_y = y1 + dy;

  return [base_x0, base_y0, base_x1, base_y1, head_x, head_y, base_x0, base_y0];
};
