import React, { useCallback, useState } from "react";
import useAddProjectUser from "./Hooks/useAddProjectUser";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import {
  StyledAddProjectUserContainer,
  StyledAddProjectUserDisplay,
  StyledUserItemContainer,
  StyledUserListContainer,
} from "./StyledComponentAddProjectScreen";
import { AnimatePresence, motion } from "framer-motion";
import { editRgbaAlpha } from "../../Utilities/FunctionUtilities";
import { User } from "../../Models/user";
import { ClickAwayListener, Grow, Popper } from "@mui/material";
import { useDoxleCurrentContextStore } from "../../DoxleGeneralStore/useDoxleCurrentContext";
import ListLoadingMore from "../../Utilities/Lottie/ListLoadingMore";
import DoxleEmptyPlaceHolder from "../../DoxleDesignPattern/DoxleEmptyPlaceHolder/DoxleEmptyPlaceHolder";
import { Virtuoso } from "react-virtuoso";
import { original, produce } from "immer";
import CancelIcon from "@mui/icons-material/Cancel";
import DoxleFilterTag from "../../DoxleDesignPattern/DoxleFilterTag/DoxleFilterTag";

type Props = {};

const AddProjectUser = (props: Props) => {
  const [selectedUser, setSelectedUser] = useState<User[]>([]);
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }),
    shallow
  );
  const { currentCompany } = useDoxleCurrentContextStore(
    (state) => ({
      currentCompany: state.currentCompany,
    }),
    shallow
  );
  const {
    userList,
    showUserList,
    anchorUserListEl,
    handleOpenUserList,
    handleCloseUserList,
    isFetchingUserList,
    isSuccessFetchingUserList,
    isErrorFetchingUserList,
    displayList,
  } = useAddProjectUser({});
  const canBeOpen = showUserList && Boolean(anchorUserListEl);
  const id = canBeOpen ? "user-list-popper" : undefined;

  const handleSelectUser = useCallback(
    (user: User) => {
      setSelectedUser(
        produce((draft) => {
          const matchedUserIndex = original(draft)!.findIndex(
            (selectedUser) => selectedUser.userId === user.userId
          );

          if (matchedUserIndex === -1) draft.push(user);
          else draft.splice(matchedUserIndex, 1);
        })
      );
    },
    [setSelectedUser]
  );
  const handleRemoveSelectedUser = (user: User) => {
    setSelectedUser(
      produce((draft) => {
        const removedIndex = original(draft)!.findIndex(
          (selectedUser) => selectedUser.userId === user.userId
        );
        draft.splice(removedIndex, 1);
        return draft;
      })
    );
  };
  //* render list
  const itemContent = useCallback(
    (index: number, item: User) => (
      <UserItem
        user={item}
        handleSelectUser={handleSelectUser}
        key={item.userId}
        selected={
          selectedUser.findIndex((user) => user.userId === item.userId) !== -1
        }
      />
    ),
    [handleSelectUser, selectedUser]
  );

  //* animation
  const labelAnimatedVariants = {
    initial: {
      color: editRgbaAlpha({
        rgbaColor: doxleThemeColor.primaryFontColor,
        alpha: "0.4",
      }),
    },
    focused: {
      color: doxleThemeColor.primaryFontColor,
      transition: {
        duration: 0.4,
      },
    },
  };
  return (
    <StyledAddProjectUserContainer $doxleFont={doxleFont}>
      <motion.span
        className="textfield-label"
        variants={labelAnimatedVariants}
        initial="initial"
        animate={canBeOpen ? "focused" : "initial"}
      >
        Project User
      </motion.span>

      <StyledAddProjectUserDisplay
        $themeColor={doxleThemeColor}
        $doxleFont={doxleFont}
        onClick={handleOpenUserList}
      >
        {selectedUser.length === 0 &&
          "Select all users you want to share this project"}
        <AnimatePresence>
          {selectedUser.length > 0 &&
            selectedUser.map((user) => (
              <DoxleFilterTag
                itemData={user}
                itemDisplayText={`${user.firstName} ${user.lastName}`}
                removeItemFunction={handleRemoveSelectedUser}
                key={`selectedUser#${user.userId}`}
                tagStyle={{
                  margin: "4px 4px",
                }}
              />
            ))}
        </AnimatePresence>
      </StyledAddProjectUserDisplay>

      <Popper
        id={id}
        open={showUserList}
        anchorEl={anchorUserListEl}
        transition
        placement="bottom-start"
        modifiers={[
          {
            name: "arrow",
            enabled: true,
          },
          {
            name: "preventOverflow",
            enabled: true,
            options: {
              altAxis: true,
              altBoundary: true,
              tether: true,
              rootBoundary: "document",
              padding: 8,
            },
          },
        ]}
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={handleCloseUserList}>
            <Grow {...TransitionProps} timeout={350}>
              <StyledUserListContainer
                $doxleFont={doxleFont}
                $themeColor={doxleThemeColor}
                elevation={4}
                layout
              >
                <div className="user-list-header">
                  {currentCompany && `${currentCompany.name}'s`} Users
                  <div onClick={handleCloseUserList}>
                    <CancelIcon
                      style={{
                        fontSize: "2rem",
                        color: "inherit",
                        cursor: "pointer",
                      }}
                    />
                  </div>
                </div>

                <div className="list-content">
                  {isFetchingUserList && (
                    <ListLoadingMore
                      animationSize={100}
                      containerStyle={{
                        width: "100%",
                        height: "100%",
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                      }}
                    />
                  )}

                  {isErrorFetchingUserList && (
                    <DoxleEmptyPlaceHolder
                      headTitleText="Something Wrong!"
                      subTitleText="Failed to get status list..."
                      headTitleTextStyle={{
                        fontSize: "1.5rem",
                      }}
                      subTitleTextStyle={{
                        fontSize: "1.1rem",
                      }}
                    />
                  )}

                  {isSuccessFetchingUserList &&
                    (displayList ? (
                      <Virtuoso
                        style={{ height: "100%", width: "100%" }}
                        data={userList}
                        itemContent={itemContent}
                      />
                    ) : (
                      <ListLoadingMore
                        animationSize={100}
                        containerStyle={{
                          width: "100%",
                          height: "100%",
                          justifyContent: "center",
                          alignItems: "center",
                          display: "flex",
                        }}
                      />
                    ))}
                </div>
              </StyledUserListContainer>
            </Grow>
          </ClickAwayListener>
        )}
      </Popper>
    </StyledAddProjectUserContainer>
  );
};

export default AddProjectUser;

const UserItem = React.memo(
  (props: {
    user: User;
    handleSelectUser: (user: User) => void;
    selected: boolean;
  }) => {
    const { user, handleSelectUser, selected } = props;
    const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
      (state) => ({
        doxleThemeColor: state.doxleThemeColor,
        doxleFont: state.doxleFont,
      }),
      shallow
    );
    return (
      <StyledUserItemContainer
        $themeColor={doxleThemeColor}
        $doxleFont={doxleFont}
        $selected={selected}
        onClick={(event) => {
          event.stopPropagation();
          handleSelectUser(user);
        }}
        initial={{
          x: -2,
          opacity: 0,
        }}
        animate={{
          x: 0,
          opacity: 1,
        }}
        transition={{
          duration: 0.2,
        }}
      >
        {user.firstName} {user.lastName}
      </StyledUserItemContainer>
    );
  }
);
