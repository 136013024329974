import React from "react";
import {
  StyledBookingContentTextField,
  StyledBookingFooterContainer,
  StyledBookingRowContainer,
} from "./StyledBookingCalendar";
import useBookingSectionFooter from "../Hooks/useBookingSectionFooter";

type Props = {
  dateValue: Date;
};

const BookingSectionFooter = ({ dateValue }: Props) => {
  const { newTitle, setNewTitle, handleKeyDown, isAdding } =
    useBookingSectionFooter({ date: dateValue });
  return (
    <StyledBookingFooterContainer
      layout="position"
      transition={{
        damping: 20,
        mass: 0.1,
        stiffness: 120,
      }}
    >
      <StyledBookingContentTextField
        placeholder="Add..."
        multiline={true}
        value={newTitle}
        onChange={(e) => setNewTitle(e.target.value)}
        onKeyDown={handleKeyDown}
      />
    </StyledBookingFooterContainer>
  );
};

export default BookingSectionFooter;
