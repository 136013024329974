import OrderTabHeader from "./OrderTabHeader";
import OrdersTable from "../OrderPage/OrdersTable";
import { shallow } from "zustand/shallow";
import { useOrdersStore } from "../../OrdersStore/useOrdersStore";
import { useEditOrderStore } from "../../OrdersStore/useEditOrderStore";
import { StyledDocketOrdersTabContainer } from "../EditOrderSideScreen/StyledComponents";
import EditOrder from "../EditOrder/EditOrder";
import useDocketOrderTab from "./Hooks/useDocketOrderTab";
import OrderActionDialog from "./OrderActionDialog";

const DocketOrdersTab = () => {
  const { editedOrder, resetStore } = useEditOrderStore(
    (state) => ({
      editedOrder: state.editedOrder,
      setEditedOrder: state.setEditedOrder,
      resetStore: state.resetStore,
    }),
    shallow
  );
  const { view } = useOrdersStore(
    (state) => ({
      view: state.view,
    }),
    shallow
  );

  useDocketOrderTab();
  const orderTabVaritants = {
    initial: {
      x: -10,
      opacity: 0,
    },
    entering: {
      x: 0,
      opacity: 1,
    },
  };
  if (editedOrder) return <EditOrder />;
  else
    return (
      <StyledDocketOrdersTabContainer
        variants={orderTabVaritants}
        initial="initial"
        animate="entering"
      >
        <OrderActionDialog />
        <OrderTabHeader />

        {view === "Orders" && <OrdersTable />}
      </StyledDocketOrdersTabContainer>
    );
};

export default DocketOrdersTab;
