import styled from "styled-components";
import {DoxleFont, DoxleThemeColor} from "../../DoxleGeneralStore/useDoxleThemeStore";
import {Button, Menu, TableCell, TableHead, TableRow, TextField, Typography} from "@mui/material";
import {motion} from "framer-motion";
import {editRgbaAlpha, TRgbaFormat} from "../../Utilities/FunctionUtilities";

export const StyledPageContainer = styled.div<{
  themeColor: DoxleThemeColor;
}>`
  width: 100%;
  height: 100%;
  background-color: ${(p) => p.themeColor.primaryBackgroundColor};
  display: flex;
  flex-direction: column;
  min-width: 500px;
`;

export const StyledEstimatePageHeaderTitle= styled(Typography)<{
  themeColor: DoxleThemeColor;
  font: DoxleFont;
}>`
  && {
    color: ${(p) => p.themeColor.primaryFontColor};
    font-family: ${(p) => p.font.primaryTitleFont};
    font-size: 30px;
    font-weight: 600;
  }
`

export const StyledEstimatePageHeaderSubTitle= styled.div<{
  themeColor: DoxleThemeColor;
  font: DoxleFont;
}>`
  text-align: left;
  color: ${(p) => p.themeColor.primaryFontColor};
  font-family: ${(p) => p.font.primaryTitleFont};
  font-size: 11px;
  width: 500px;
  align-self: center;
`

export const StyledEstimatePageSearchField = styled(TextField)<{

}>`
  && {
    width: 361px;
    height: 30px;
    background: #e3e5eb;
    border-radius: 13px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    align-self: center;
    padding: 0 8px;
    margin: 19px;
  }
  input {
    padding: 0 !important;
    height: 100%;
    font-family: "IBM Plex Mono";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.05em;
    color: #000000;
  }
  & .MuiOutlinedInput-notchedOutline {
    border: none;
  }
`;

export const StyledEstimatePageHeaderContainer = styled.div<{
  themeColor: DoxleThemeColor;
}>`
  width: 100%;
  height: 262px;
  background-color: ${(p) => p.themeColor.primaryBackgroundColor};
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  min-width: 500px;
`;

export const StyledEstimateTableRow = styled(TableRow)<{
  themeColor: DoxleThemeColor;
}>`
  && {
    display: flex;
    flex-direction: row;
    width: 100%;
    border-bottom: 1px solid ${(p) => p.themeColor.primaryDividerColor};
    height: 44px;
  }
`;

export const StyledEstimateTableCell = styled(TableCell)<{
  widthInPixel: `${number}px` | null;
  hoverStatus: boolean;
  horizontalAlign?: "flex-start" | "center" | "flex-end";
  letterSpacing?: string;
  paddingLeft?: string;
  fontWeight?: string;
  fontColor?: string;
  themeColor: DoxleThemeColor;
  doxleFont: DoxleFont;
  currentTheme: string;
}>`
  && {
    ${(p) => (p.widthInPixel ? `width: ${p.widthInPixel};` : "flex:1;")}
    display: flex;
    height: 44px;
    border-bottom: 2px solid
      ${(p) => p.currentTheme === "light"
  ? "rgba(239, 240, 244, 1)"
  : p.themeColor.primaryBackgroundColor
};
    justify-content: ${(p) => p.horizontalAlign ? p.horizontalAlign : "center"};
    align-items: center;
    font-family: ${(p) => p.doxleFont.primaryFont};
    font-size: 14px;
    font-style: normal;
    font-weight: ${(p) => (p.fontWeight ? p.fontWeight : "500")};
    line-height: normal;
    letter-spacing: ${(p) => (p.letterSpacing ? p.letterSpacing : "0.7px")};
    color: ${(p) => p.fontColor ? p.fontColor : p.themeColor.primaryFontColor};
    text-transform: capitalize;
    padding: 0px 30px;
    padding-left: ${(p) => (p.paddingLeft ? p.paddingLeft : "30px")};
    background-color: ${(p) => p.hoverStatus
  ? p.currentTheme === "dark"
    ? ""
    : "#F5F6F7"
  : p.themeColor.primaryBackdropColor};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const StyledEstimateTableHeaderRow = styled(TableRow)<{
  themeColor: DoxleThemeColor;
}>`
  && {
    width: 100% !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: ${(p) => p.themeColor.primaryBackgroundColor};
    height: 44px;
  }
`;

export const StyledEstimateTableHeaderCell = styled(TableHead)<{
  doxleFont: DoxleFont
  widthInPixel: `${number}px` | null;
  horizontalAlign?: "flex-start" | "center" | "flex-end";
  themeColor: DoxleThemeColor;
  paddingLeft?: number
}>`
  && {
    ${(p) => (p.widthInPixel ? `width: ${p.widthInPixel};` : "flex:1;")}
    display: flex;
    justify-content: ${(p) => p.horizontalAlign ? p.horizontalAlign : "center"};
    align-items: center;
    padding: 0px 30px;
    color: ${(p) => p.themeColor.primaryFontColor};
    text-transform: capitalize;
    font-family: ${(p)=> p.doxleFont.primaryTitleFont};
  }
`;

export const StyledEstimateTableHeaderText = styled(Typography)<{
  fontWeight?: number
}>`
  && {
    padding-left: 5px;
    font-size: 14px;
    font-style: normal;
    font-weight: 200;
    line-height: normal;
    letter-spacing: -0.28px;
    font-family: "IBMPlexSans-Light";
    font-weight: ${(p) => p.fontWeight ?? 400};
    cursor: pointer;
  }
`;

export const RootEstimateTabMenu = styled(motion.div)`
  display: flex;
  flex-direction: row;
  margin: 20px 0px 20px 0px;
  height: 30px;
  align-self: center;
`;

export const StyledEstimateMenuItemBtn = styled(motion(Button))<{
  themecolor: DoxleThemeColor;
  selected: boolean;
  doxleFont: DoxleFont;
}>`
  && {
    font-family: ${(p) => p.doxleFont.primaryTitleFont};
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.05em;
    min-width: 0 !important;
    padding: 5px 14px !important;
    margin: 0 4px;
    border-radius: 20px !important;
    color: ${(p) => p.selected ? "white" : p.themecolor.primaryFontColor};
    background-color: ${(p) => p.selected ? p.themecolor.doxleColor : p.themecolor.primaryContainerColor};
    text-transform: capitalize;
  }
`;

export const StyledEstimateKebabMenu = styled(Menu)`
  & .MuiMenu-list {
    background-color: white;
    opacity: 1;
    transform-origin: 168.188px 0px;
    right: 10px;
  }
  & .MuiMenuItem-root {
    font-family: "IBM Plex Sans";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.05em;
  }
`;

export const StyledQuickEstimateTextField = styled(TextField)<{
  themeColor: DoxleThemeColor;
  doxleFont: DoxleFont;
}>`
  && {
    width: 100% !important;
    flex-direction: row;
  }
  & .MuiInputBase-input {
    border: none !important;
    font-family: ${(p) => p.doxleFont.primaryFont};
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.05em;
    text-transform: capitalize;
    color: ${(p) => p.themeColor.primaryFontColor} !important;
    caret-color: ${(p) => p.themeColor.primaryFontColor} !important;
  }
  & .MuiInputBase-root:after {
    border: 0 !important;
  }
  & .MuiInputLabel-root {
    font-family: ${(p) => p.doxleFont.primaryFont};
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.05em;
    text-transform: capitalize;
    color: ${(p) => p.themeColor.primaryReverseBackdropColor};
    opacity: 0.8;
  }
  & .MuiInputLabel-root.Mui-focused {
    color: ${(p) => p.themeColor.doxleColor};
  }
  & .MuiInputBase-root {
    width: 100% !important;
    border: 0 !important;
  }
  & .MuiOutlinedInput-notchedOutline {
    border: 0 !important;
  }
`;

export const StyledQuickEstimateEstimateRow = styled(TableRow)<{
  themeColor: DoxleThemeColor;
}>`
  && {
    display: flex;
    flex-direction: row;
    width: 100%;
    border-bottom: 1px solid ${(p) => p.themeColor.primaryDividerColor};
    height: 44px;
    background-color: ${(p) => editRgbaAlpha({
      rgbaColor: p.themeColor.primaryContainerColor as TRgbaFormat,
      alpha: "0.5",
    })
    }
  }
`;
