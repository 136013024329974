import React, { useCallback, useState } from "react";
import { Contact } from "../../../../../Models/addressBook";
import { useDoxleCurrentContextStore } from "../../../../../DoxleGeneralStore/useDoxleCurrentContext";
import { useEditDocketSideScreenStore } from "../../../EditDocketSideScreenStore/useEditDocketSideScreenStore";
import { shallow } from "zustand/shallow";
import { Docket } from "../../../../../Models/dockets";
import DocketQuery from "../../../../../Services/QueryHooks/docketQueryAPI";
import useSetDocketDetailQueryData from "../../../../QueryDataHooks/SetQueryDataHooks/useSetDocketDetailQueryData";

type Props = {};

interface IWatcherSection {
  handleRemoveWatcher: (item: Contact) => void;
  handleAddWatchers: (item: Contact) => void;
  handleOpenWatcherList: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  handleCloseWatcherList: () => void;
  anchorWatcherList: HTMLElement | null;
}
const useWatcherSection = (props: Props): IWatcherSection => {
  const [anchorWatcherList, setAnchorWatcherList] =
    useState<HTMLElement | null>(null);
  const { company } = useDoxleCurrentContextStore((state) => ({
    company: state.currentCompany,
  }));
  const {
    edittedDocket,
    updateEdittedDocket,
    filterDocketListQuery,
    handlePostUpdateDocket,
  } = useEditDocketSideScreenStore(
    (state) => ({
      edittedDocket: state.edittedDocket,
      updateEdittedDocket: state.updateEdittedDocket,
      filterDocketListQuery: state.filterDocketListQuery,
      handlePostUpdateDocket: state.handlePostUpdateDocket,
    }),
    shallow
  );
  const { handleUpdateDocket } = useSetDocketDetailQueryData({
    docketPk: edittedDocket?.docketPk ?? "",
  });
  const onSuccessUpdate = (newDocket?: Docket) => {
    if (newDocket) {
      if (handlePostUpdateDocket) handlePostUpdateDocket(newDocket);

      handleUpdateDocket(newDocket);
    }
  };
  const updateDocketQuery = DocketQuery.useUpdateDocketQuery({
    filter: filterDocketListQuery,

    company,
    onSuccessCb: onSuccessUpdate,
  });

  const handleAddWatchers = (item: Contact) => {
    if (edittedDocket) {
      if (!edittedDocket.watching.includes(item.contactId)) {
        updateEdittedDocket({
          watching: [...edittedDocket.watching, item.contactId],
          watcherJson: edittedDocket.watcherJson
            ? [...edittedDocket.watcherJson, item]
            : [item],
        });
        updateDocketQuery.mutate({
          docketPk: edittedDocket.docketPk,
          watching: [...edittedDocket.watching, item.contactId],
        });
      }
    }
  };
  const handleRemoveWatcher = (item: Contact) => {
    if (edittedDocket) {
      updateEdittedDocket({
        watching: edittedDocket.watching.filter((id) => id !== item.contactId),
        watcherJson: edittedDocket.watcherJson
          ? [
              ...edittedDocket.watcherJson.filter(
                (watcher) => watcher.contactId !== item.contactId
              ),
            ]
          : [],
      });
      updateDocketQuery.mutate({
        docketPk: edittedDocket.docketPk,
        watching: edittedDocket.watching.filter((id) => id !== item.contactId),
      });
    }
  };
  const handleOpenWatcherList = useCallback(
    (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      setAnchorWatcherList(e.currentTarget);
    },
    []
  );
  const handleCloseWatcherList = () => {
    setAnchorWatcherList(null);
  };
  return {
    handleRemoveWatcher,
    handleAddWatchers,
    handleOpenWatcherList,
    handleCloseWatcherList,
    anchorWatcherList,
  };
};

export default useWatcherSection;
