import dayjs from "dayjs";
import React from "react";
import { useProjectQAStore } from "../../../Store/useProjectQAStore";
import { shallow } from "zustand/shallow";
import useDoxleNotificationStore from "../../../../../DoxleGeneralStore/useDoxleNotificationStore";
import { useDoxleAuthStore } from "../../../../../DoxleGeneralStore/useDoxleAuthStore";
import { useDoxleCurrentContextStore } from "../../../../../DoxleGeneralStore/useDoxleCurrentContext";
import useSetQAListQueryData from "../../../../CustomQueryHooks/useSetQAListQueryData";
import { QAList } from "../../../../Models/qa";
import QAQueryAPI from "../../../../QueryAPI/qaQueryAPI";
import { formatTISODate } from "../../../../../Utilities/FunctionUtilities";

type Props = {};

interface EditQAListDueDate {
  handleUpdateDueDate: (value: dayjs.Dayjs | null) => void;
  isUpdatingDueDate: boolean;
}
const useEditQAListDueDate = (props: Props): EditQAListDueDate => {
  const { edittedQAListItem, filterQAListQuery, updateEdittedQAListItem } =
    useProjectQAStore(
      (state) => ({
        edittedQAListItem: state.edittedQAListItem,
        filterQAListQuery: state.filterQAListQuery,
        updateEdittedQAListItem: state.updateEdittedQAListItem,
      }),
      shallow
    );
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );
  const { company } = useDoxleCurrentContextStore(
    (state) => ({
      company: state.currentCompany,
    }),
    shallow
  );
  const { handleEditQAList } = useSetQAListQueryData({
    filter: filterQAListQuery,
  });

  const onUpdateSuccessCb = (newQAList?: QAList) => {
    if (newQAList) {
      handleEditQAList(newQAList);
      updateEdittedQAListItem({ dueDate: newQAList.dueDate });
    }
  };
  const updateQAListQuery = QAQueryAPI.useUpdateQAListQuery({
    company,
    showNotification,
    onSuccessCB: onUpdateSuccessCb,
  });

  const handleUpdateDueDate = (value: dayjs.Dayjs | null) => {
    if (edittedQAListItem)
      updateQAListQuery.mutate({
        qaList: edittedQAListItem,
        updateParams: {
          dueDate: value ? formatTISODate(value.toDate()) : null,
        },
      });
  };
  return {
    handleUpdateDueDate,
    isUpdatingDueDate: updateQAListQuery.isLoading,
  };
};

export default useEditQAListDueDate;
