import React, { useCallback, useRef, useState } from "react";
import {
  StyledProjectDrawerTabText,
  StyledProjectMenuRoot,
} from "../Style/StyledProjectDrawer";
import { useDoxleThemeStore } from "../../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import { useProjectStore } from "../../../../DoxleGeneralStore/useprojectStore";
import { motion } from "framer-motion";
import useRefComponentDimension from "../../../UtilityHooks/useRefComponentDimension";
import ProjectPopoverMenu from "../../ProjectPopoverMenu";
import { StyledCollapseMenuButton } from "../../StyledProjectComponents";
import { StyledProjectMenuExpandIcon } from "../../ProjectIcon";

const ProjectDrawerMenu = () => {
  const {
    projectTabs,
    selectedProjectTab,
    setSelectedProjectTab,
    permissions,
  } = useProjectStore((state) => ({
    projectTabs: state.projectTabs,
    selectedProjectTab: state.selectedProjectTab,
    setSelectedProjectTab: state.setSelectedProjectTab,
    permissions: state.projectPermissions,
  }));

  const styleProps = useDoxleThemeStore(
    (state) => ({
      $themeColor: state.doxleThemeColor,
      $doxleFont: state.doxleFont,
      $currentTheme: state.currentTheme,
    }),
    shallow
  );
  const menuContainerRef = useRef<HTMLDivElement>(null);
  const menuContainerRefWrapper = useRef<HTMLDivElement>(null);
  const [menuCollapse, setMenuCollapse] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const projectTabsRef: React.MutableRefObject<any[]> = useRef(
    new Array(projectTabs.length)
  );
  const [menuWidth, setMenuWidth] = useState<number>(0);

  const { width: rootMenuWidth, height } = useRefComponentDimension({
    componentRef: menuContainerRef,
  });

  const { width: menuWrapper, height: heightWrapper } =
    useRefComponentDimension({
      componentRef: menuContainerRefWrapper,
    });

  const isOverflow = rootMenuWidth <= menuWrapper;

  const tabDisabled = useCallback(
    (tab: string) => {
      if (tab === "Drawings" && permissions?.viewDrawings === false)
        return true;

      if (tab === "Budgets" && permissions?.viewDrawings === false) return true;

      if (tab === "Bookings" && permissions?.viewBookings === false)
        return true;

      if (tab === "Files" && permissions?.viewFiles === false) return true;
      return false;
    },
    [permissions]
  );

  return (
    <StyledProjectMenuRoot
      {...styleProps}
      $isOverflow={isOverflow}
      ref={menuContainerRef}
    >
      <StyledCollapseMenuButton
        className="shrink-btn"
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        {selectedProjectTab}
        <StyledProjectMenuExpandIcon themeColor={styleProps.$themeColor} />
      </StyledCollapseMenuButton>

      <div className="full-menu" ref={menuContainerRefWrapper}>
        {projectTabs.map((tab, idx) => (
          <StyledProjectDrawerTabText
            key={idx}
            $selected={tab === selectedProjectTab}
            $disabled={tabDisabled(tab)}
            onClick={() => {
              if (!tabDisabled(tab)) setSelectedProjectTab(tab);
            }}
          >
            {tab}
            {tab === selectedProjectTab && (
              <motion.div
                className="underline-effect"
                layoutId="selectedUnderlineEffect"
              />
            )}
          </StyledProjectDrawerTabText>
        ))}
      </div>

      {isOverflow && (
        <ProjectPopoverMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
      )}
    </StyledProjectMenuRoot>
  );
};

export default ProjectDrawerMenu;
