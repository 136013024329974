import styled from "styled-components";
import {
  DoxleFont,
  DoxleThemeColor,
} from "../../DoxleGeneralStore/useDoxleThemeStore";
import {
  Button,
  FormControl,
  IconButton,
  TextField,
  Tooltip,
} from "@mui/material";
import { TRgbaFormat, editRgbaAlpha } from "../../Utilities/FunctionUtilities";

export const StyledAddCompanyScreenContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
export const StyledAddCompanyTitleText = styled.span<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  color: ${(p) => p.$themeColor.primaryFontColor};
  font-size: max(5px, 5.5rem);
  font-family: ${(p) => p.$doxleFont.primaryTitleFont};
  font-style: normal;
  font-weight: 700;
  line-height: 118.9%;
  letter-spacing: -1.8px;
  text-transform: none;
  margin-bottom: 8px;
`;
export const StyledAddCompanySubTitleText = styled.span<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  color: ${(p) => p.$themeColor.primaryFontColor};
  font-size: 16px;
  font-family: ${(p) => p.$doxleFont.secondaryTitleFont};
  font-style: normal;
  font-weight: 400;
  line-height: 118.9%;
  text-transform: none;
  max-width: 60%;
  text-align: center;
  @media (min-width: 768px) {
    font-size: 14px;
  }
  @media (max-width: 767px) {
    font-size: 14px;
  }
  @media (min-width: 1024px) {
    font-size: 15px;
  }
  @media (min-width: 1280px) {
    font-size: 16px;
  }
`;
export const StyledAddCompanyImg = styled.img`
  width: 249px;
  height: 378px;
  position: absolute;
  z-index: 0;
  bottom: 0;
  right: -10px;
  @media (max-width: 767px) {
    width: 200px;
    height: 329px;
  }
  @media (min-width: 768px) {
    width: 200px;
    height: 329px;
  }
  @media (min-width: 1024px) {
    width: 219px;
    height: 348px;
  }
  @media (min-width: 1280px) {
    width: 249px;
    height: 378px;
  }
`;
export const StyledAddCompanyFormContainer = styled(FormControl)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
  $height: number;
}>`
  && {
    margin-top: 14px;
    width: 409px;
    height: ${(p) => p.$height}px;
    border-radius: 9px;
    border: 1px solid ${(p) => p.$themeColor.primaryDividerColor};
    background-color: ${(p) => p.$themeColor.primaryContainerColor};
    color: ${(p) => p.$themeColor.primaryFontColor};
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
    overflow: visible;
    @media (max-width: 767px) {
      width: 300px;
      height: ${(p) => p.$height - 20}px;
    }
    @media (min-width: 768px) {
      width: 300px;
      height: ${(p) => p.$height - 20}px;
    }
    @media (min-width: 1024px) {
      width: 350px;
      height: ${(p) => p.$height - 10}px;
    }
    @media (min-width: 1280px) {
      width: 409px;
      height: ${(p) => p.$height}px;
    }
  }
`;
export const StyledAddCompanyErrorToolTip = styled(Tooltip)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  &.MuiTooltip-tooltip {
    font-family: ${(p) => p.$doxleFont.primaryTitleFont};
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: ${(p) => p.$themeColor.errorColor} !important;
    text-transform: none;
    background-color: white !important;
    box-shadow: 1px 2px 4px grey !important;
    border-radius: 4px;
  }
`;
export const StyledAddCompanyLoginTextField = styled(TextField)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
  $hasBorderBottom?: boolean;
  $heightRatio?: `${number}%`;
}>`
  color: ${(p) => p.$themeColor.primaryFontColor};
  font-size: 18px !important;
  && {
    width: 100%;
    height: ${(p) => p?.$heightRatio ?? "35px"};
    display: flex;
    justify-content: center;
    border-bottom: ${(p) => (p.$hasBorderBottom ? 1 : 0)}px solid
      ${(p) => p.$themeColor.primaryDividerColor};
    color: ${(p) => p.$themeColor.primaryFontColor};
    font-size: 18px !important;
    font-family: ${(p) => p.$doxleFont.primaryTitleFont};
    font-style: normal;
    font-weight: 400;
    text-transform: none;
    @media (max-width: 767px) {
      font-size: 14px;
    }
    @media (min-width: 768px) {
      font-size: 14px;
    }
    @media (min-width: 1024px) {
      font-size: 16px;
    }
    @media (min-width: 1280px) {
      font-size: 18px;
    }
    &::placeholder {
      color: ${(p) => p.$themeColor.primaryInputPlaceholderColor};
      font-size: 18px !important;
      font-family: ${(p) => p.$doxleFont.primaryTitleFont};
      font-style: normal;
      font-weight: 400;
      text-transform: none;
      @media (min-width: 768px) {
        font-size: 14px;
      }
      @media (max-width: 767px) {
        font-size: 14px;
      }
      @media (min-width: 1024px) {
        font-size: 16px;
      }
      @media (min-width: 1280px) {
        font-size: 18px;
      }
    }
  }

  & .MuiOutlinedInput-input {
    padding: 0px 14px !important;
    font-size: 14px !important;
    color: ${(p) => p.$themeColor.primaryFontColor};
  }
  & .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }
`;
export const StyledAddCompanyButton = styled(Button)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
  $delete?: true;
}>`
  && {
    min-width: 120px !important;
    padding: 0px 10px !important;
    background-color: ${(p) =>
      p.$delete ? "red" : p.$themeColor.primaryReverseContainerColor};
    color: ${(p) => p.$themeColor.primaryReverseFontColor};
    font-size: 18px;
    font-family: ${(p) => p.$doxleFont.primaryTitleFont};
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: none;
    height: 30px;
    &:hover {
      background-color: ${(p) =>
        editRgbaAlpha({
          rgbaColor: p.$delete
            ? "rgba(255, 0, 0, 0)"
            : (p.$themeColor.primaryReverseContainerColor as TRgbaFormat),
          alpha: "0.8",
        })} !important;
      transition: 0.2s;
      transform: scale(1.05);
    }
    @media (min-width: 768px) {
      font-size: 14px;
    }
    @media (max-width: 767px) {
      font-size: 14px;
    }
    @media (min-width: 1024px) {
      font-size: 16px;
    }
    @media (min-width: 1280px) {
      font-size: 18px;
    }
  }
`;

export const StyledCancelButton = styled(Button)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  && {
    min-width: 120px !important;
    padding: 0px 10px !important;
    background-color: ${(p) => p.$themeColor.primaryTextFieldBackgroundColor};
    color: ${(p) => p.$themeColor.primaryFontColor};
    font-size: 18px;
    font-family: ${(p) => p.$doxleFont.primaryTitleFont};
    border: 1px solid ${(p) => p.$themeColor.primaryDividerColor};
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: none;
    height: 31px;
    &:hover {
      background-color: ${(p) =>
        editRgbaAlpha({
          rgbaColor: p.$themeColor.primaryContainerColor,
          alpha: "0.8",
        })} !important;
      transition: 0.2s;
      transform: scale(1.05);
    }
    @media (min-width: 768px) {
      font-size: 14px;
    }
    @media (max-width: 767px) {
      font-size: 14px;
    }
    @media (min-width: 1024px) {
      font-size: 16px;
    }
    @media (min-width: 1280px) {
      font-size: 18px;
    }
  }
`;

export const StyledShowMoreButton = styled(IconButton)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  & {
    color: ${(p) => p.$themeColor.doxleColor2} !important;
    border-radius: 4px !important;
  }
  font-size: 12px;
  font-family: ${(p) => p.$doxleFont.secondaryTitleFont};
  font-weight: 500;
  height: 20px;
  position: relative;
  top: -35px;
`;

export const StyledAddLogoButton = styled(IconButton)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  & {
    color: ${(p) => p.$themeColor.doxleColor2} !important;
    border-radius: 4px !important;
  }
  font-size: 12px;
  font-family: ${(p) => p.$doxleFont.secondaryTitleFont};
  font-weight: 500;
`;
