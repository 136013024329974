import React, { useEffect, useMemo } from "react";
import { useProjectQAStore } from "../Store/useProjectQAStore";
import { shallow } from "zustand/shallow";
import { useDoxleAuthStore } from "../../../DoxleGeneralStore/useDoxleAuthStore";
import { useDoxleCurrentContextStore } from "../../../DoxleGeneralStore/useDoxleCurrentContext";
import QAQueryAPI from "../../QueryAPI/qaQueryAPI";
import { QAList } from "../../Models/qa";

type Props = {};

interface QAListToggleMenu {
  qaItemDetail: QAList | undefined;
  isFetchingQAListDetail: boolean;
  isSuccessFetchingQAListDetail: boolean;
  isErrorFetchingQAListDetail: boolean;
}
const useQAListToggleMenu = (props: Props): QAListToggleMenu => {
  const { selectedQAList, setSelectedQAListStatus } = useProjectQAStore(
    (state) => ({
      selectedQAList: state.selectedQAList,
      setSelectedQAListStatus: state.setSelectedQAListStatus,
      filter: state.filterQAItemQuery
    }),
    shallow
  );

  const { company } = useDoxleCurrentContextStore(
    (state) => ({
      company: state.currentCompany,
    }),
    shallow
  );
  const getQAListDetail = QAQueryAPI.useRetrieveQAListDetailQuery({
    company,
    defectListId: selectedQAList?.defectListId ?? "",
    enableQuery: Boolean(selectedQAList),
  });

  const qaItemDetail = useMemo(
    () =>
      getQAListDetail.isSuccess
        ? (getQAListDetail.data.data as QAList)
        : selectedQAList,
    [getQAListDetail.data, selectedQAList]
  );

  //useefect set the filter status back to unattended by default whenever selectedQalist  change
  useEffect(() => {
    if (!selectedQAList) setSelectedQAListStatus("unattended");
  }, [selectedQAList]);

  return {
    qaItemDetail,
    isFetchingQAListDetail: getQAListDetail.isLoading,
    isSuccessFetchingQAListDetail: getQAListDetail.isSuccess,
    isErrorFetchingQAListDetail: getQAListDetail.isError,
  };
};

export default useQAListToggleMenu;
