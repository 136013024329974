import DoxleListSkeleton from "../../../DoxleDesignPattern/DoxleSkeleton/DoxleListSkeleton";
import React, { useEffect, useMemo } from "react";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import ContactsTable from "./ContactsTable";
// import ContactsSidePeek from "../ContactsSidePeek/ContactsSidePeek";
import { StyledContactsPageContainer } from "../StyledComponents";
import useGetContactList from "../../Hooks/useGetContactList";
import DoxleEmptyPlaceHolder from "../../../DoxleDesignPattern/DoxleEmptyPlaceHolder/DoxleEmptyPlaceHolder";
import ListLoadingMore from "../../../Utilities/Lottie/ListLoadingMore";
import { Error404Banner } from "../../../DoxleDesignPattern/DoxleIcons/CommonIcons";

const ContactsPage = () => {
  const {
    isFetchingContactList,
    isSuccessFetchingContactList,
    isErrorFetchingContactList,
    isFetchingNextPageContactList,
  } = useGetContactList({});
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }),
    shallow
  );

  return (
    <StyledContactsPageContainer layout>
      {isErrorFetchingContactList && (
        <DoxleEmptyPlaceHolder
          headTitleText="Something Wrong!"
          subTitleText="Failed to get contacts..."
          illustration={
              <Error404Banner themeColor={doxleThemeColor} containerStyle={{width: "30%"}} />
          }
        />
      )}
      {isFetchingContactList && <DoxleListSkeleton skeletonType={"orderRow"} />}
      {isSuccessFetchingContactList && <ContactsTable />}

      {isFetchingNextPageContactList && (
        <ListLoadingMore
          animationSize={144}
          containerStyle={{
            position: "absolute",
            bottom: 0,
            width: "100%",
            left: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 100,
          }}
        />
      )}
      {/*<ContactsSidePeek />*/}
    </StyledContactsPageContainer>
  );
};

export default ContactsPage;
