import {
  // StyledIconImage,
  StyledNoticeBoardRowContainer,
  StyledCircle,
  StyledNoticeBoardRowContainerContainer,
} from "./StyledNoticeBoardContent";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { LightDocket, UpdateDocketParams } from "../../../Models/dockets";
import { shallow } from "zustand/shallow";
import { NB_ROW_SIZE, useInboxStore } from "../../InboxStore/useInboxStore";
import { AnimatePresence, easeInOut, motion } from "framer-motion";
import { useIsMutating } from "@tanstack/react-query";
import { getDocketMutationKey } from "../../../Services/QueryHooks/docketQueryAPI";
import { CircularProgress } from "@mui/material";
import React from "react";
import { NBCommentIcon } from "../InboxIcon";
import BallInCourtTag from "./BallInCourtTag";
import {
  DoxleArchiveIcon,
  DoxleDeleteIcon,
  DoxlePinIcon,
  DoxleUnarchiveIcon,
  DoxleUnpinIcon,
} from "../../../DoxleDesignPattern/DoxleGeneralIcons";
import useNoticeBoardRow from "./Hooks/useNoticeBoardRow";
interface Props {
  data: LightDocket;
  handleClickStatusNbItem: (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    item: LightDocket
  ) => void;
}
const NoticeBoardRow = ({ data, handleClickStatusNbItem }: Props) => {
  const { doxleThemeColor, doxleFont, currentTheme } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
      currentTheme: state.currentTheme,
    }),
    shallow
  );
  const { setDeletedDocket } = useInboxStore(
    (state) => ({
      setDeletedDocket: state.setDeletedDocket,
    }),
    shallow
  );
  const {
    handlePinNb,
    isHover,
    setIsHover,
    handleClickRow,
    handleToggleArchiveNb,
  } = useNoticeBoardRow({ item: data });
  const isDeletingNb =
    useIsMutating({
      mutationKey: getDocketMutationKey("delete"),
      predicate: (query) => Boolean(query.state.variables === data.docketPk),
    }) > 0;
  const isUpdatingNb =
    useIsMutating({
      mutationKey: getDocketMutationKey("update"),
      predicate: (query) =>
        Boolean(
          (query.state.variables as UpdateDocketParams).docketPk ===
            data.docketPk
        ),
    }) > 0;
  return (
    <StyledNoticeBoardRowContainer
      $rowHeight={NB_ROW_SIZE}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      layout="position"
    >
      <StyledNoticeBoardRowContainerContainer
        $currentTheme={currentTheme}
        $themeColor={doxleThemeColor}
        $doxleFont={doxleFont}
      >
        {isDeletingNb || isUpdatingNb ? (
          <CircularProgress
            size="12px"
            style={{
              color: isDeletingNb ? "red" : doxleThemeColor.primaryFontColor,
              marginRight: 4,
            }}
          />
        ) : (
          <StyledCircle
            style={{ backgroundColor: data.statusColor }}
            onClick={(e) => {
              e.stopPropagation();
              handleClickStatusNbItem(e, data);
            }}
            animate={
              data.statusAnimateIcon
                ? {
                    scale: [0.7, 1.4],
                    opacity: [1, 0.5],
                    transition: {
                      duration: 0.5,
                      repeat: Infinity,
                      repeatType: "reverse",
                      repeatDelay: 0.1,
                    },
                  }
                : { scale: 1 }
            }
          />
        )}
        <div className="main-info-wrapper" onClick={(e) => handleClickRow()}>
          <AnimatePresence>
            {data.isSticky && (
              <motion.div
                className="pin-effect"
                animate={{ scaleX: [0, 1], opacity: [0, 1] }}
                exit={{ scaleX: [1, 0], opacity: [1, 0] }}
                transition={{ duration: 0.4, ease: easeInOut }}
                viewport={{ once: true }}
              />
            )}
          </AnimatePresence>
          {data.commentCount > 0 && (
            <div className="comment-icon-wrapper">
              <NBCommentIcon
                themeColor={doxleThemeColor}
                iconColor={
                  currentTheme === "dark" && data.isSticky ? "#000" : undefined
                }
                onClick={(e) => {
                  e.stopPropagation();
                  handleClickRow("comment");
                }}
              />
              <span
                className="comment-count"
                style={
                  currentTheme === "dark" && data.isSticky
                    ? { color: "#000" }
                    : undefined
                }
              >
                {data.commentCount}
              </span>
            </div>
          )}
          <span
            style={
              currentTheme === "dark" && data.isSticky
                ? { color: "#000" }
                : undefined
            }
          >
            {data.docketName}
          </span>

          {data.ballInCourt && (
            <BallInCourtTag bicName={data.ballInCourtName} />
          )}
        </div>
        {isHover && (
          <motion.div
            className="delete-edit-icon-wrapper"
            initial={{
              x: 10,
              opacity: 0,
            }}
            animate={{
              x: 0,
              opacity: 1,
            }}
            exit={{ x: 10, opacity: 0 }}
          >
            {!data.isSticky ? (
              <DoxlePinIcon
                themeColor={doxleThemeColor}
                containerStyle={{ marginRight: 8, width: 15 }}
                onClick={handlePinNb}
              />
            ) : (
              <DoxleUnpinIcon
                themeColor={doxleThemeColor}
                containerStyle={{ marginRight: 8, width: 15 }}
                onClick={handlePinNb}
              />
            )}

            {!data.isArchived ? (
              <DoxleArchiveIcon
                themeColor={doxleThemeColor}
                containerStyle={{ marginRight: 8, width: 15 }}
                onClick={handleToggleArchiveNb}
              />
            ) : (
              <DoxleUnarchiveIcon
                themeColor={doxleThemeColor}
                containerStyle={{ marginRight: 8, width: 15 }}
                onClick={handleToggleArchiveNb}
              />
            )}
            <DoxleDeleteIcon
              themeColor={doxleThemeColor}
              onClick={() => {
                setDeletedDocket(data);
              }}
            />
          </motion.div>
        )}
      </StyledNoticeBoardRowContainerContainer>
    </StyledNoticeBoardRowContainer>
  );
};

export default React.memo(NoticeBoardRow);
