import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ContactCompanyFilters } from "../../../../Services/QueryHooks/contactsFilters";
import { useDoxleCurrentContextStore } from "../../../../DoxleGeneralStore/useDoxleCurrentContext";
import { shallow } from "zustand/shallow";
import ContactsAPI from "../../../../Services/QueryHooks/contactsAPI";
import { ContactCompany } from "../../../../Models/addressBook";
import { useEditOrderStore } from "../../../OrdersStore/useEditOrderStore";
import { produce } from "immer";
import { useEditOrderContext } from "../../EditOrder/EditOrder";

type Props = { handleClosePopoverContractor: () => void };

interface ISelectContractorPopover {
  showAddContactForm: boolean;
  setShowAddContactForm: React.Dispatch<React.SetStateAction<boolean>>;
  showContactList: boolean;
  setShowContactList: React.Dispatch<React.SetStateAction<boolean>>;
  companiesList: ContactCompany[];

  isFetchingCompanyList: boolean;
  isSuccessFetchingCompanyList: boolean;
  isErrorFetchingCompanyList: boolean;
  searchText: string;
  setSearchText: React.Dispatch<React.SetStateAction<string>>;
  handleFetchNextPage: () => void;
  isFetchingNExtPageCompanyList: boolean;
  handleSelectContractor: (contractor: ContactCompany) => void;
}
const useSelectContractorPopover = ({
  handleClosePopoverContractor,
}: Props): ISelectContractorPopover => {
  const [showAddContactForm, setShowAddContactForm] = useState(false);
  const [showContactList, setShowContactList] = useState(false);

  const [searchText, setSearchText] = useState("");
  const [filterContact, setfilterContact] = useState<ContactCompanyFilters>({});

  const company = useDoxleCurrentContextStore(
    (state) => state.currentCompany,
    shallow
  );
  const { editedOrder, setEditedOrder, updateEditedOrder } = useEditOrderStore(
    (state) => ({
      editedOrder: state.editedOrder,
      setEditedOrder: state.setEditedOrder,
      updateEditedOrder: state.updateEditedOrder,
    }),
    shallow
  );
  const editOrderContext = useEditOrderContext();
  const { setUpdatedOrder } = editOrderContext
    ? editOrderContext : {setUpdatedOrder: (x: any)=>{}}

  const retrieveContactCompanyQuery =
    ContactsAPI.useRetrieveContactsCompanyQuery({
      filter: filterContact,
      onSuccessCb: () => {},
      enable: true,

      company,
    });
  const companiesList = useMemo(
    () =>
      retrieveContactCompanyQuery.isSuccess
        ? retrieveContactCompanyQuery.data.pages.flatMap(
            (page) => (page.data.results as ContactCompany[]) ?? []
          ) ?? []
        : [],
    [retrieveContactCompanyQuery.data]
  );
  const handleFetchNextPage = () => {
    if (retrieveContactCompanyQuery.hasNextPage)
      retrieveContactCompanyQuery.fetchNextPage();
  };

  const handleSelectContractor = useCallback(
    (contractor: ContactCompany) => {
      if (editedOrder) {
        setUpdatedOrder(
          produce((draft) => {
            if (draft) {
              draft.contactCompany = contractor.contactCompanyId!;
              draft.supplier = contractor.name;
            } else
              draft = {
                contactCompany: contractor.contactCompanyId!,
                supplier: contractor.name,
              };
            return draft;
          })
        );
        // patchOrder.mutate({
        //   orderId: editedOrder.orderId,
        //   order: {
        //     contactCompany: contractor.contactCompanyId!,
        //     supplier: contractor.name,
        //   },
        // });
        updateEditedOrder({
          contactCompany: contractor.contactCompanyId!,
          supplier: contractor.name,
          contactCompanyJson: contractor,
        });
      }
      handleClosePopoverContractor();
    },
    [handleClosePopoverContractor, updateEditedOrder]
  );
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (searchText) setfilterContact({ search: searchText });
      else setfilterContact({});
    }, 300);

    return () => clearTimeout(timeout);
  }, [searchText]);

  return {
    showAddContactForm,
    setShowAddContactForm,
    showContactList,
    setShowContactList,
    companiesList,
    isFetchingCompanyList: retrieveContactCompanyQuery.isLoading,
    isSuccessFetchingCompanyList: retrieveContactCompanyQuery.isSuccess,
    isErrorFetchingCompanyList: retrieveContactCompanyQuery.isError,
    searchText,
    setSearchText,
    handleFetchNextPage,
    isFetchingNExtPageCompanyList:
      retrieveContactCompanyQuery.isFetchingNextPage,
    handleSelectContractor,
  };
};

export default useSelectContractorPopover;
