import React, { useCallback, useMemo, useRef } from "react";
import {
  StyledBookingListScroller,
  StyledBookingTable,
  StyledBookingTableBody,
  StyledBookingTableContainer,
  StyledBookingTableHead,
  StyledBookingTableRow,
} from "./StyledComponentBookingTable";
import useGetProjectBookingList from "../../Hooks/useGetProjectBookingList";
import { IBooking } from "../../../Models/booking";
import { TableComponents, TableVirtuoso } from "react-virtuoso";
import TableContainer from "@mui/material/TableContainer";
import { useDoxleThemeStore } from "../../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import DoxleEmptyPlaceHolder from "../../../../DoxleDesignPattern/DoxleEmptyPlaceHolder/DoxleEmptyPlaceHolder";
import ListLoadingMore from "../../../../Utilities/Lottie/ListLoadingMore";
import BookingProjectTableHeader from "./BookingProjectTableHeader";
import BookingTableRow from "./BookingTableRow";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import DoxleListSkeleton from "../../../../DoxleDesignPattern/DoxleSkeleton/DoxleListSkeleton";
import { Error404Banner } from "../../../../DoxleDesignPattern/DoxleIcons/CommonIcons";
import { EmptyBookingBanner } from "./BookingIcon";
import ScrollSeekerRow from "./ScrollSeekerRow";
import useRefComponentDimension from "../../../../CoreContent/UtilityHooks/useRefComponentDimension";

type Props = {};

interface BookingTableContext {
  isErrorFetchingBookingProjectList: boolean;
  bookingLength: number;
  containerWidth: number;
}
const BookingProjectTable = (props: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }),
    shallow
  );
  const {
    bookingProjectList,
    isFetchingBookingProjectList,
    isErrorFetchingBookingProjectList,
    isFetchingMoreBookingProjectList,
    fetchMoreBookingProjectList,
  } = useGetProjectBookingList({});

  const bookingConatinerRef = useRef<HTMLDivElement>(null);
  const { width } = useRefComponentDimension({
    componentRef: bookingConatinerRef,
  });

  const tableVirtuosoContext: BookingTableContext = {
    isErrorFetchingBookingProjectList,
    bookingLength: bookingProjectList.length,
    containerWidth: width,
  };
  const tableComponents: TableComponents<IBooking, BookingTableContext> =
    useMemo(
      () => ({
        Table: (props) => (
          <StyledBookingTable
            {...props}
            style={props.context?.bookingLength === 0 ? { height: "100%" } : {}}
          />
        ),

        Scroller: React.forwardRef((props, ref) => (
          <TableContainer
            ref={ref}
            {...props}
            component={StyledBookingListScroller}
          />
        )),
        TableHead: StyledBookingTableHead,
        TableBody: React.forwardRef((props, ref) => (
          <StyledBookingTableBody {...props} ref={ref} />
        )),
        TableRow: (props) => (
          <StyledBookingTableRow
            {...props}
            // layout="position"
            // transition={{
            //   duration: 0.2,
            //   damping: 16,
            // }}
            key={props.item.bookingId}
          />
        ),
        EmptyPlaceholder: (props) => (
          <DoxleEmptyPlaceHolder
            headTitleText={
              props.context?.isErrorFetchingBookingProjectList
                ? "Something Wrong"
                : "No Bookings"
            }
            subTitleText={
              props.context?.isErrorFetchingBookingProjectList
                ? "Failed to get data, please try to reload the page"
                : "Add a booking to schedule your work!"
            }
            containerStyle={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
            containerBgColor="transparent"
            illustration={
              props.context?.isErrorFetchingBookingProjectList ? (
                <Error404Banner themeColor={doxleThemeColor} />
              ) : (
                <EmptyBookingBanner
                  themeColor={doxleThemeColor}
                  containerStyle={{ marginBottom: 12 }}
                />
              )
            }
          />
        ),
        ScrollSeekPlaceholder: ({ context, ...props }) => (
          <ScrollSeekerRow {...props} />
        ),
      }),
      []
    );

  const itemContent = useCallback(
    (index: number, item: IBooking, context: BookingTableContext) => (
      <BookingTableRow
        bookingItem={item}
        containerWidth={context.containerWidth}
      />
    ),
    []
  );
  const tableStyle: React.CSSProperties = {
    width: "100%",
    height: "100%",
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <StyledBookingTableContainer ref={bookingConatinerRef}>
        {isFetchingBookingProjectList && (
          <DoxleListSkeleton skeletonType="budgetRow" />
        )}
        {!isFetchingBookingProjectList && (
          <TableVirtuoso
            context={tableVirtuosoContext}
            data={bookingProjectList}
            style={tableStyle}
            components={tableComponents}
            endReached={fetchMoreBookingProjectList}
            // fixedFooterContent={() => <BudgetFooter />}
            fixedHeaderContent={() => {
              return <BookingProjectTableHeader containerWidth={width} />;
            }}
            itemContent={itemContent}
            scrollSeekConfiguration={{
              enter: (velocity) => Math.abs(velocity) > 50,
              exit: (velocity) => {
                const shouldExit = Math.abs(velocity) < 10;

                return shouldExit;
              },
            }}
          />
        )}
        {isFetchingMoreBookingProjectList && (
          <ListLoadingMore
            containerStyle={{
              position: "absolute",
              bottom: 0,
              left: 0,
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 10,
            }}
            animationSize={120}
          />
        )}
      </StyledBookingTableContainer>
    </LocalizationProvider>
  );
};

export default BookingProjectTable;
