import React, { useEffect, useState } from "react";
import { useDoxleCurrentContextStore } from "../../../../DoxleGeneralStore/useDoxleCurrentContext";
import { shallow } from "zustand/shallow";
import { useOrdersStore } from "../../../OrdersStore/useOrdersStore";
import OrdersQueryAPI from "../../../QueryHooks/OrdersQueryAPI";
import useDoxleNotificationStore from "../../../../DoxleGeneralStore/useDoxleNotificationStore";
import { useEditDocketSideScreenStore } from "../../../../CoreContent/EditDocketSideScreen/EditDocketSideScreenStore/useEditDocketSideScreenStore";

type Props = {};

interface OrderActionDialog {
  orderAction: "delete" | "sign" | "unsign" | undefined;
  setOrderAction: React.Dispatch<
    React.SetStateAction<"delete" | "sign" | "unsign" | undefined>
  >;
}
const useOrderActionDialog = (props: Props): OrderActionDialog => {
  const [orderAction, setOrderAction] = useState<
    "delete" | "sign" | "unsign" | undefined
  >(undefined);
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );
  const company = useDoxleCurrentContextStore(
    (state) => state.currentCompany,
    shallow
  );
  const { setOrderActionBtns } = useEditDocketSideScreenStore(
    (state) => ({
      setOrderActionBtns: state.setOrderActionBtns,
    }),
    shallow
  );
  const { orderFilter, selectedOrders } = useOrdersStore(
    (state) => ({
      orderFilter: state.queryFilter,
      selectedOrders: state.selectedOrders,
    }),
    shallow
  );
  const mutateOrderQuery = OrdersQueryAPI.useMutateOrderQuery({
    filter: orderFilter,

    company,
    showNotification,
  });

  useEffect(() => {
    if (selectedOrders.length > 0) {
      setOrderActionBtns([
        {
          btnText: `Sign (${selectedOrders.length})`,
          btnFunction: () => setOrderAction("sign"),
        },
        // {
        //   btnText: `Unsign (${selectedOrders.length})`,
        //   btnFunction: () => setOrderAction("unsign"),
        // },
        {
          btnText: `Delete (${selectedOrders.length})`,
          btnFunction: () => setOrderAction("delete"),
        },
      ]);
    } else setOrderActionBtns([]);
  }, [selectedOrders]);

  return {
    orderAction,
    setOrderAction,
  };
};

export default useOrderActionDialog;
