import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import DoxleAPI from "../../Services/DoxleAPI";
import { TISODate, TMonthDate } from "../../Models/dateFormat";
import { BaseAPIProps } from "../../Models/baseAPIProps";
import {
  AxiosBackendErrorReturn,
  IApiPaginatedData,
} from "../../Models/axiosReturn";
import { IBooking } from "../Models/booking";
import useSetBookingQueryData from "../Hooks/useSetBookingQueryData";
import { Company } from "../../Models/company";
import { isAxiosError } from "axios";

export interface IFilterBookingQueryProps {
  project?: string;
  docket?: string;
  startDate?: TISODate;
  endDate?: TISODate;
  search?: string;
  due?: "today" | "week" | "fortnight" | "month" | number;
  completed?: boolean;
  pagination?: "none";
  currentMonthDate?: TMonthDate;
  orderBy?: string[];
}

interface RetriveBookingListQuery extends BaseAPIProps {
  filter: IFilterBookingQueryProps;
  enableQuery?: boolean;
}
const useRetrieveBookingList = ({
  company,
  showNotification,
  filter,
  onErrorCb,
  enableQuery,
}: RetriveBookingListQuery) => {
  const { project, docket, startDate, endDate, search, due, completed } =
    filter;
  const qKey = getBookingListQKey(filter, company);

  let docketURL = `/bookings/?page=1`;
  let filterParam: any = {};
  if (project) filterParam.project = project;
  if (docket) filterParam.docket = docket;
  if (startDate) filterParam.start_date = startDate;
  if (endDate) filterParam.end_date = endDate;
  if (search) filterParam.search = search;
  if (due) filterParam.due = due;
  if (completed) filterParam.completed = completed;
  const docketQuery = useInfiniteQuery(
    qKey,
    ({ pageParam = docketURL }) =>
      DoxleAPI.get<IApiPaginatedData<IBooking>>(pageParam, {
        headers: {
          "User-Company": company!.companyId,
        },
        params: filterParam,
      }),
    {
      enabled: company !== undefined && enableQuery,

      retry: 1,
      refetchInterval: 4 * 60 * 1000,
      staleTime: 4 * 60 * 1000,
      cacheTime: 5 * 60 * 1000,
      refetchOnMount: true,
      refetchIntervalInBackground: true,
      refetchOnWindowFocus: true,
      onSuccess: (res) => {
        // console.log('DOCKET RESULT:', res.pages);
      },
      getNextPageParam: (prevData, pages) => prevData.data.next,
      onError: (error) => {
        if (onErrorCb) onErrorCb();
        if (showNotification)
          if (isAxiosError<AxiosBackendErrorReturn>(error)) {
            showNotification(
              `${error?.response?.status ?? "ERROR"}: ${
                error.response?.data.detail ?? "UNKNOWN ERROR"
              }`,
              "error",
              String(
                error?.response?.data?.detail ?? "Fail to get docket list"
              ).substring(0, 300)
            );
          } else {
            showNotification(
              "SOMETHING WRONG",
              "error",
              "Failed to get docket list"
            );
          }
      },
    }
  );
  return docketQuery;
};

const useRetrieveBookingListWithoutPagination = ({
  company,
  showNotification,
  filter,
  onErrorCb,
  enableQuery,
}: RetriveBookingListQuery) => {
  const {
    project,
    docket,
    startDate,
    endDate,
    search,
    due,
    completed,
    pagination,
    currentMonthDate,
  } = filter;
  const qKey = getBookingListWithoutPaginationQKey(filter, company);

  let docketURL = `/bookings/`;
  let filterParam: any = {};
  if (project) filterParam.project = project;
  if (docket) filterParam.docket = docket;
  if (startDate) filterParam.start_date = startDate;
  if (endDate) filterParam.end_date = endDate;
  if (search) filterParam.search = search;
  if (due) filterParam.due = due;
  if (completed) filterParam.completed = completed;
  if (pagination) filterParam.pagination = pagination;
  if (currentMonthDate) filterParam.currentMonthDate = currentMonthDate;

  const bookingQuery = useQuery(
    qKey,
    () =>
      DoxleAPI.get<IBooking[]>(docketURL, {
        headers: {
          "User-Company": company!.companyId,
        },
        params: filterParam,
      }),
    {
      enabled: company !== undefined && (enableQuery ?? false),

      retry: 2,
      retryDelay: 3 * 1000,
      refetchInterval: 7 * 60 * 1000,
      staleTime: 60 * 1000,
      cacheTime: 10 * 60 * 1000,
      refetchOnMount: true,
      refetchIntervalInBackground: true,
      refetchOnWindowFocus: false,
      onError: (error) => {
        if (onErrorCb) onErrorCb();
        // if (showNotification)
        //   showNotification(
        //     'SOMETHING WRONG',
        //     'error',
        //     'fail to get docket list',
        //   );
      },
    }
  );
  return bookingQuery;
};

interface PrefetchBookingListProps extends BaseAPIProps {
  filter: IFilterBookingQueryProps;
}

const usePrefetchBookingList = () => {
  const queryClient = useQueryClient();

  const prefetchBookingList = async ({
    company,
    filter,
  }: PrefetchBookingListProps) => {
    const {
      project,
      docket,
      startDate,
      endDate,
      search,
      due,
      completed,
      pagination,
      currentMonthDate,
    } = filter;
    const qKey = getBookingListQKey(filter, company);
    let filterParam: any = {};
    if (project) filterParam.project = project;
    if (docket) filterParam.docket = docket;
    if (startDate) filterParam.start_date = startDate;
    if (endDate) filterParam.end_date = endDate;
    if (search) filterParam.search = search;
    if (due) filterParam.due = due;
    if (completed) filterParam.completed = completed;
    if (pagination) filterParam.pagination = pagination;
    if (currentMonthDate) filterParam.currentMonthDate = currentMonthDate;
    let docketURL = `/bookings/`;

    try {
      await queryClient.prefetchQuery(
        qKey,
        () =>
          DoxleAPI.get<IBooking[]>(docketURL, {
            headers: {
              "User-Company": company?.companyId,
            },
            params: filterParam,
          }),
        {
          retry: 2,
          retryDelay: 3 * 1000,
          staleTime: 6 * 60 * 1000,
          cacheTime: 8 * 60 * 1000,
        }
      );
    } catch (error) {}
  };

  return prefetchBookingList;
};
interface RetrieveBookingDetailsQuery extends BaseAPIProps {
  bookingId: string;
}
const useRetrieveBookingDetail = ({
  company,
  showNotification,
  onErrorCb,
  bookingId,
}: RetrieveBookingDetailsQuery) => {
  const docketQuery = useQuery(
    getBookingDetailQkey(bookingId, company),
    () =>
      DoxleAPI.get<IBooking>("/bookings/" + bookingId + "/", {
        headers: {
          "User-Company": company!.companyId,
        },
      }),
    {
      enabled: company !== undefined,

      retry: 1,
      refetchInterval: 4 * 60 * 1000,
      staleTime: 4 * 60 * 1000,
      cacheTime: 5 * 60 * 1000,
      refetchOnMount: true,
      refetchIntervalInBackground: true,
      refetchOnWindowFocus: true,
      onSuccess: (res) => {
        // console.log('DOCKET RESULT:', res.pages);
      },

      onError: (error) => {
        if (onErrorCb) onErrorCb();
        if (showNotification)
          if (isAxiosError<AxiosBackendErrorReturn>(error)) {
            showNotification(
              `${error?.response?.status ?? "ERROR"}: ${
                error.response?.data.detail ?? "UNKNOWN ERROR"
              }`,
              "error",
              String(
                error?.response?.data?.detail ?? "Fail to get docket list"
              ).substring(0, 300)
            );
          } else {
            showNotification(
              "SOMETHING WRONG",
              "error",
              "Fail to get docket list"
            );
          }
      },
    }
  );
  return docketQuery;
};

interface MutateBookingQueryProps extends BaseAPIProps {
  filter: IFilterBookingQueryProps;
  onSuccessAddCb?: (newNote?: IBooking) => void;
  onSuccessDeleteCb?: (deletedId?: string) => void;
  onSuccessUpdateCb?: (newNote?: IBooking) => void;
  onSuccessShareCb?: (newNote?: IBooking) => void;
}
export interface AddBookingQueryParams
  extends Partial<
    Pick<
      IBooking,
      | "docket"
      | "title"
      | "startDate"
      | "endDate"
      | "order"
      | "percentageCompleted"
      | "project"
      | "company"
    >
  > {}
export interface UpdateBookingQueryParams {
  bookingId: string;
  updateData: Partial<
    Pick<
      IBooking,
      | "percentageCompleted"
      | "title"
      | "docket"
      | "order"
      | "commenced"
      | "completed"
      | "startDate"
      | "endDate"
      | "confirmed"
    >
  >;
}
export interface IShareBookingMutationProps {
  bookingId: string;
  toRecipients: string[];
  attachments: File[];
  ccRecipients: string[];
  subject: string;
  textBody: string;
}
const useMutateBookingQuery = ({
  company,
  filter,
  showNotification,
  onErrorCb,
  onSuccessAddCb,
  onSuccessDeleteCb,
  onSuccessUpdateCb,
  onSuccessShareCb,
}: MutateBookingQueryProps) => {
  const queryClient = useQueryClient();
  const {
    handleAddBookingQueryData,
    handleDeleteBookingQueryData,
    handleEditBookingQueryData,
    handleAddBookingQueryDataWithoutPagination,
    handleEditBookingQueryDataWithoutPagination,
    handleDeleteBookingQueryDataWithoutPagination,
  } = useSetBookingQueryData({ filter });
  const update = useMutation({
    mutationKey: getBookingMutationKey("update"),
    mutationFn: async ({ bookingId, updateData }: UpdateBookingQueryParams) => {
      return DoxleAPI.patch<IBooking>(
        "/bookings/" + bookingId + "/",
        { ...updateData },
        {
          headers: {
            "User-Company": company?.companyId ?? "",
          },
        }
      );
    },
    onSuccess: (result, variables, context) => {
      handleEditBookingQueryDataWithoutPagination(result.data);
      // handleEditBookingQueryData(result.data);
      if (onSuccessUpdateCb) onSuccessUpdateCb(result.data);
    },
    onError: (error, variables, context) => {
      if (onErrorCb) onErrorCb();
      if (showNotification)
        if (isAxiosError<AxiosBackendErrorReturn>(error)) {
          showNotification(
            `${error?.response?.status ?? "ERROR"}: ${
              error.response?.data.detail ?? "UNKNOWN ERROR"
            }`,
            "error",
            String(
              error?.response?.data?.detail ?? "Fail To update takeoff"
            ).substring(0, 300)
          );
        } else {
          showNotification(
            "Fail To update note!",
            "error",
            "Fail To update takeoff"
          );
        }
    },
    onSettled: () => {
      queryClient.invalidateQueries(baseBookingListQKey);
    },
  });

  const add = useMutation({
    mutationKey: getBookingMutationKey("add"),
    mutationFn: async (data: AddBookingQueryParams) => {
      return DoxleAPI.post<IBooking>("/bookings/", data, {
        headers: {
          "User-Company": company?.companyId ?? "",
        },
      });
    },
    onSuccess: (result, variables, context) => {
      handleAddBookingQueryDataWithoutPagination(result.data);
      // handleAddBookingQueryData({ ...result.data, isNew: true });
      if (onSuccessAddCb) onSuccessAddCb(result.data);
    },

    onError: (error, variables, context) => {
      if (onErrorCb) onErrorCb();
      if (showNotification)
        if (isAxiosError<AxiosBackendErrorReturn>(error)) {
          showNotification(
            `${error?.response?.status ?? "ERROR"}: ${
              error.response?.data.detail ?? "UNKNOWN ERROR"
            }`,
            "error",
            String(
              error?.response?.data?.detail ?? "Fail To add estimate"
            ).substring(0, 300)
          );
        } else
          showNotification(
            "Failed To Add Item!",
            "error",
            "Fail To add estimate"
          );
    },
    onSettled: () => {
      queryClient.invalidateQueries(baseBookingListQKey);
    },
  });

  const destroy = useMutation({
    mutationKey: getBookingMutationKey("delete"),
    mutationFn: async (deletedId: string) => {
      return DoxleAPI.delete(
        "/bookings/" + deletedId + "/",

        {
          headers: {
            "User-Company": company?.companyId ?? "",
          },
        }
      );
    },
    onSuccess: (result, variables, context) => {
      handleDeleteBookingQueryDataWithoutPagination(variables);
      // handleDeleteBookingQueryData(variables);
      if (onSuccessDeleteCb) onSuccessDeleteCb(result.data);
    },
    onError: (error, variables, context) => {
      if (onErrorCb) onErrorCb();
      if (showNotification)
        if (isAxiosError<AxiosBackendErrorReturn>(error)) {
          showNotification(
            `${error?.response?.status ?? "ERROR"}: ${
              error.response?.data.detail ?? "UNKNOWN ERROR"
            }`,
            "error",
            String(
              error?.response?.data?.detail ?? "Fail To Delete Bookings"
            ).substring(0, 300)
          );
        } else
          showNotification(
            "Failed To Delete Item!",
            "error",
            "Fail To Delete Bookings"
          );
    },
    onSettled: () => {
      queryClient.invalidateQueries(baseBookingListQKey);
    },
  });

  const share = useMutation({
    mutationFn: async ({
      bookingId,
      toRecipients,
      attachments,
      ccRecipients,
      subject,
      textBody,
    }: IShareBookingMutationProps) => {
      const formData = new FormData();
      formData.append("subject", subject);
      formData.append("textBody", textBody);
      toRecipients.forEach((contactId) =>
        formData.append("toRecipients", contactId)
      );
      ccRecipients.forEach((contactId) =>
        formData.append("ccRecipients", contactId)
      );
      attachments.forEach((file) => formData.append("attachments", file));
      return DoxleAPI.post("/bookings/" + bookingId + "/share/", formData, {
        headers: {
          "User-Company": company?.companyId ?? "",
        },
      });
    },
    onSuccess: (result, variables, context) => {
      if (onSuccessShareCb) onSuccessShareCb();
      if (showNotification)
        showNotification("Successfully shared booking", "success");
    },
    onError: (error: any, variables, context) => {
      if (onErrorCb) onErrorCb();
      if (showNotification)
        if (isAxiosError<AxiosBackendErrorReturn>(error)) {
          showNotification(
            `${error?.response?.status ?? "ERROR"}: ${
              error.response?.data.detail ?? "UNKNOWN ERROR"
            }`,
            "error",
            String(
              error?.response?.data?.detail ?? "Error Sending Email"
            ).substring(0, 300)
          );
        } else
          showNotification(
            "Failed To Delete Item!",
            "error",
            "Error Sending Email"
          );
    },
    onSettled: () => {
      queryClient.invalidateQueries(baseBookingListQKey);
    },
  });
  interface SMSBookingMutationProps {
    bookingId: string;
    recipient: string;
    message: string;
  }
  const sms = useMutation({
    mutationFn: async ({
      bookingId,
      recipient,
      message,
    }: SMSBookingMutationProps) => {
      return DoxleAPI.post(
        "/bookings/" + bookingId + "/sms/",
        { recipient, message },
        {
          headers: {
            "User-Company": company?.companyId ?? "",
          },
        }
      );
    },
    onSuccess: (result, variables, context) => {
      if (onSuccessShareCb) onSuccessShareCb();
    },
    onError: (error: any, variables, context) => {
      if (onErrorCb) onErrorCb();
      if (showNotification)
        if (isAxiosError<AxiosBackendErrorReturn>(error)) {
          showNotification(
            `${error?.response?.status ?? "ERROR"}: ${
              error.response?.data.detail ?? "UNKNOWN ERROR"
            }`,
            "error",
            String(
              error?.response?.data?.detail ?? "Error Fetching Contacts List"
            ).substring(0, 300)
          );
        } else
          showNotification(
            "Failed To Delete Item!",
            "error",
            "Error Fetching Contacts List"
          );
    },
  });
  return {
    add: { ...add, mutate: (data: AddBookingQueryParams) => add.mutate(data) },
    destroy: {
      ...destroy,
      mutate: (deletedId: string) => destroy.mutate(deletedId),
    },
    update: {
      ...update,
      mutate: (data: UpdateBookingQueryParams) => update.mutate(data),
    },
    share,
    sms,
  };
};
//# HELPER FUNCTIONS
export const baseBookingListQKey = ["booking-list"];
export const getBookingListQKey = (
  filter: IFilterBookingQueryProps,
  company: Company | undefined
) => {
  let baseQKey = ["booking-list"];
  if (company) baseQKey.push(company.companyId);
  const { project, docket, startDate, endDate, due, search, completed } =
    filter;
  if (project) baseQKey.push(project);
  if (docket) baseQKey.push(docket);
  if (startDate) baseQKey.push(`start:${startDate}`);
  if (endDate) baseQKey.push(`end:${endDate}`);
  if (due) baseQKey.push(`due:${due}`);
  if (completed) baseQKey.push("completed");
  if (search) baseQKey.push(`search:${search}`);
  return baseQKey;
};
export const baseBookingListWithoutPaginationQKey = [
  "booking-list-without-pagination",
  `pagination:none`,
];
export const getBookingListWithoutPaginationQKey = (
  filter: IFilterBookingQueryProps,
  company: Company | undefined
) => {
  let baseQKey = ["booking-list-without-pagination"];
  if (company) baseQKey.push(company.companyId);
  const {
    project,
    docket,
    startDate,
    endDate,
    due,
    search,
    completed,
    pagination,
    currentMonthDate,
  } = filter;
  if (project) baseQKey.push(`project:${project}`);
  if (docket) baseQKey.push(docket);
  if (startDate) baseQKey.push(`start:${startDate}`);
  if (endDate) baseQKey.push(`end:${endDate}`);
  if (due) baseQKey.push(`due:${due}`);
  if (completed) baseQKey.push("completed");
  if (search) baseQKey.push(`search:${search}`);
  if (pagination) baseQKey.push(`pagination:${pagination}`);
  if (currentMonthDate) baseQKey.push(`currentMonthDate:${currentMonthDate}`);
  return baseQKey;
};
export const getBookingDetailQkey = (
  bookingId: string,
  company: Company | undefined
) => {
  let baseQKey = ["booking-details"];
  if (company) baseQKey.push(company.companyId);
  baseQKey.push(bookingId);

  return baseQKey;
};
export const getBookingMutationKey = (action: "add" | "update" | "delete") => {
  return [`${action}-booking-docket`];
};
export const BookingQueryAPI = {
  useRetrieveBookingList,
  useMutateBookingQuery,
  useRetrieveBookingDetail,
  usePrefetchBookingList,
  useRetrieveBookingListWithoutPagination,
};
