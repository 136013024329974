import { motion } from "framer-motion";
import styled from "styled-components";
import {
  DoxleFont,
  DoxleThemeColor,
} from "../../../../DoxleGeneralStore/useDoxleThemeStore";
import { editRgbaAlpha } from "../../../../Utilities/FunctionUtilities";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Button, { ButtonProps } from "@mui/material/Button";

export const StyledQAItemDetailContainer = styled(motion.div)`
  width: 100%;

  display: flex;
  flex-direction: column;

  .assignee-dueDate-wrapper {
    width: 100%;
    max-width: 700px;

    display: flex;
    justify-content: space-between;

    .field-wrapper {
      width: 45%;
    }
  }
`;
export const StyledQAEditFieldWrapper = styled.div`
  width: 100%;
  max-width: 700px;
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;

  .field-label-text {
    color: ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.theme.color.primaryFontColor,
        alpha: "0.6",
      })};
    font-family: ${(p) => p.theme.font.primaryFont};
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    text-transform: capitalize;
    margin-left: 2px;
    margin-bottom: 4px;
  }
`;
interface ICustomTextFieldProps
  extends Omit<
    TextFieldProps<"standard">,
    "onDragStart" | "onAnimationStart" | "onDragEnd" | "onDrag"
  > {
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}
const AnimatedTextField = motion(TextField);
export const StyledEditQATextField = styled(
  ({ $themeColor, $doxleFont, ...props }: ICustomTextFieldProps) => (
    <AnimatedTextField {...props} layout />
  )
)`
  && {
    width: calc(100% - 1.6rem);
    background-color: ${(p) => p.$themeColor.primaryContainerColor};

    padding: 0.4rem 0.8rem;
    border: 1px solid
      ${(p) =>
        editRgbaAlpha({
          rgbaColor: p.$themeColor.primaryDividerColor,
          alpha: "0.5",
        })};
  }
  input {
    color: ${(p) => p.$themeColor.primaryFontColor};
    font-size: 1.2rem;
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-style: normal;
    font-weight: 500;
    line-height: 118.9%;

    &::placeholder {
      color: ${(p) => p.$themeColor.primaryInputPlaceholderColor};
      font-size: 1.2rem;
      opacity: 1;
      font-family: ${(p) => p.$doxleFont.primaryFont};
      font-style: normal;
      font-weight: 500;
      line-height: 118.9%;

      padding: 0px !important;
    }
  }
  & .MuiOutlinedInput-root {
    padding: 0px !important;
  }
  & .MuiOutlinedInput-input {
    padding: 0px !important;
  }
  & .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }

  & .MuiInput-root:before {
    border: none !important;
  }
  & .MuiInput-root:after {
    border: none !important;
  }
`;

export const StyledDescriptionInputEndornment = styled(InputAdornment)`
  && {
    margin-left: 0.8rem;
    justify-content: center;
    align-items: center;
    margin-right: 0.8rem;
  }
`;
export const StyledQAContactOptionItem = styled.li<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
  $selected: boolean;
}>`
  height: 3rem;
  display: flex;
  align-items: center;
  font-family: ${(p) => p.$doxleFont.primaryFont};
  font-weight: 500;
  font-size: 1.2rem;
  line-height: normal;

  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  transition: 0.4s;
  color: ${(p) =>
    p.$selected ? p.$themeColor.doxleColor : p.$themeColor.primaryFontColor};
  &:hover {
    background-color: ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.$themeColor.doxleColor,
        alpha: "0.4",
      })} !important;
    color: white;
    transition: 0.4s;
  }
`;

export const StyledQaAssigneeDisplayer = styled.div<{}>`
  width: calc(100% - 16px);
  background-color: ${(p) => p.theme.color.primaryContainerColor};

  padding: 5px 8px;
  border: 1px solid
    ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.theme.color.primaryDividerColor,
        alpha: "0.5",
      })};
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  color: ${(p) => p.theme.color.primaryFontColor};
  font-size: 16px;
  font-family: ${(p) => p.theme.font.primaryFont};
  font-style: normal;
  font-weight: 500;
  line-height: 110%;
  text-transform: capitalize;

  .null-text {
    color: ${(p) => p.theme.color.primaryInputPlaceholderColor};
    font-size: 16px;

    font-family: ${(p) => p.theme.font.primaryFont};
    font-style: normal;
    font-weight: 500;
    line-height: 110%;
  }
`;

export const StyledQACommentSection = styled.div`
  width: 100%;
  display: flex;
  max-width: 70rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 4rem;
`;

export const StyledQACommentItemContainer = styled(motion.div)<{
  $official?: boolean;
}>`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  .comment-info {
    font-family: ${(p) => p.theme.font.secondaryTitleFont};
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.theme.color.primaryFontColor,
        alpha: "0.5",
      })};

    margin-bottom: 4px;
  }

  .comment-text-wrapper {
    align-self: flex-start;
    flex-shrink: 1;
    min-width: calc(45% - 16px);
    padding: 4px 8px;
    border: 1px solid
      ${(p) =>
        editRgbaAlpha({
          rgbaColor: p.theme.color.primaryDividerColor,
          alpha: "0.5",
        })};
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: ${(p) => p.theme.font.primaryFont};
    background-color: ${(p) =>
      p.$official
        ? editRgbaAlpha({ rgbaColor: p.theme.color.doxleColor, alpha: "0.3" })
        : p.theme.color.primaryContainerColor};
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: ${(p) => p.theme.color.primaryFontColor};
    white-space: pre-line;
    word-break: break-word;
  }

  .comment-checkbox-wrapper {
    flex-direction: row;
    display: flex;
    height: 100%;
    color: ${(p) => p.theme.color.doxleColor};
    font-family: ${(p) => p.theme.font.subTitleFont};
    font-size: 12px;
    svg {
      margin: auto 0 auto 5px;
      cursor: pointer;
    }
    .official-text {
      display: flex;
      margin: auto 0 auto 5px;
      cursor: pointer;
      transform: translateX(30%);
      opacity: 0;
      transition: transform 0.2s ease, opacity 0.5s ease;
      margin: auto 0 auto 5px;
      cursor: pointer;
      white-space: nowrap;
    }
    &:hover {
      .official-text {
        transform: translateX(0);
        opacity: 1;
      }
    }
  }

  .comment-edit-icons {
    margin-left: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    svg {
      transform: translateY(-30%);
      opacity: 0;
      transition: transform 0.2s ease, opacity 0.5s ease;
    }
  }
  &:hover {
    .comment-edit-icons {
      svg {
        transform: translateY(0);
        opacity: 1;
      }
    }
  }
`;

export const StyledQACommentInitialWrapper = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  min-width: 20px;
  min-height: 20px;
  border-radius: 50%;
  background-color: ${(p) => p.$themeColor.doxleColor};
  font-family: ${(p) => p.$doxleFont.primaryFont};
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  white-space: nowrap;
`;

export const StyledQAImageSection = styled(motion.div)`
  width: 100%;
  display: flex;
  height: calc(100% - 12px);
  align-items: center;
  flex-direction: column;
  padding-top: 12px;
  .loader-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 14px 0px;
    .loader-bar {
      width: 100%;
      max-width: 50rem;
    }
    .loader-text {
      font-family: ${(p) => p.theme.font.primaryFont};
      font-style: normal;
      font-weight: 500;
      font-size: 1.4rem;
      color: ${(p) => p.theme.color.primaryFontColor};
      display: flex;
      margin-top: 8px;
    }
  }
`;

export const StyledQAImgListContainer = styled(motion.div)`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

export const StyledQAImageWrapper = styled(motion.div)<{
  $numOfCol: number;
}>`
  width: calc(${(p) => 100 / p.$numOfCol}%);
  aspect-ratio: 1;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledQAImageItemContainer = styled(motion.div)<{}>`
  width: calc(100% - 2rem);
  height: calc(100% - 2rem);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  cursor: pointer;
  .menu-wrapper {
    bottom: 8px;
    right: 4px;
    display: flex;
    align-items: center;
    position: absolute;
    z-index: 2;
  }
  .img-holder {
    width: 100%;
    height: 100%;
    z-index: 0;
    object-fit: cover;
  }
  .img-loader {
    position: absolute;
    z-index: 10;
    color: ${(p) => p.theme.color.primaryFontColor};
  }
  .markup-icon-wrapper {
    position: absolute;
    top: 4px;
    right: 4px;
    z-index: 3;
    background-color: rgba(255, 255, 255, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    width: 2rem;
    height: 2rem;
  }
  .play-icon {
    position: absolute;
    z-index: 3;
    color: ${(p) => p.theme.color.doxleColor};
    font-size: 30px;
  }
`;

export const StyledEmptyQAImageBanner = styled(motion.div)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  width: calc(100% - 40px - 2px);

  display: flex;
  flex-direction: column;
  border: 1px dashed ${(p) => p.$themeColor.primaryDividerColor};
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  padding: 20px;
  .banner-text {
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    color: ${(p) => p.$themeColor.primaryFontColor};
    display: flex;
    margin-top: 8px;
  }
  .description-text {
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: ${(p) => p.$themeColor.primaryFontColor};
    display: flex;
    margin-top: 8px;
  }
`;
type Props = Omit<
  ButtonProps,
  "onDragStart" | "onAnimationStart" | "onDragEnd" | "onDrag"
> & {
  btnText?: string;
  btnTextStyle?: React.CSSProperties;
  isRefetching?: boolean;
};

export const StyledAddQAImgButton = styled(motion(Button))`
  && {
    justify-content: center;
    min-width: 20px !important;
    align-items: center;
    min-height: 20px;
    margin-bottom: 14px;
    display: flex;
    padding: 4px 14px;
    align-self: center;
    width: fit-content;
    background-color: ${(p) => p.theme.color.primaryContainerColor};
    display: flex;
    flex-direction: row;
    border: 1px solid ${(p) => p.theme.color.primaryDividerColor};
  }
  .btn-text {
    font-family: ${(p) => p.theme.font.primaryFont};
    font-style: normal;
    font-weight: 500;
    font-size: 14px;

    color: ${(p) => p.theme.color.primaryFontColor};
    text-transform: capitalize;
    margin-left: 4px;
  }
`;

export const StyledCommentTextField = styled(TextField)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  && {
    width: 100%;
    max-width: 100%;
    min-width: 0px;
    height: 100%;
    min-height: 0px;
    border-radius: 0px;
    border: none;
    margin: 0px;
  }
  & .MuiOutlinedInput-notchedOutline {
    border-color: ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.theme.color.primaryFontColor,
        alpha: "0.4",
      })} !important;
    transition: 0.4s ease all;
  }
  & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${(p) => p.theme.color.doxleColor} !important;
    transition: 0.2s ease all;
    border-width: 1.5px;
  }
  & .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    transition: 0.2s ease all;
    border-color: ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.theme.color.doxleColor,
        alpha: "0.4",
      })} !important;
  }
  & .MuiOutlinedInput-root {
    padding: 0px !important;
  }

  textarea {
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-weight: 400;
    font-size: 14px;
    line-height: normal;

    color: ${(p) => p.$themeColor.primaryFontColor};
    padding: 0 10px;
    &::placeholder {
      font-family: ${(p) => p.$doxleFont.primaryFont};
      font-weight: 400;
      font-size: 14px;
      line-height: normal;
      color: ${(p) => p.$themeColor.primaryInputPlaceholderColor};
      padding: 0px !important;
      opacity: 1;
    }
    &::-webkit-scrollbar {
      display: none;
    }
  }
  & .MuiInput-root:after {
    border-bottom: none !important;
  }
  & .MuiInput-root:before {
    border-bottom: none !important;
  }
  & .MuiInput-root {
    border-bottom: none !important;
  }
  & .MuiInput-root:hover:not(.Mui-disabled, .Mui-error):before {
    border-bottom: none !important;
  }
  & .MuiInputBase-root {
    width: 100%;
  }
`;
export const StyledFetchMoreCmtButton = styled(motion(Button))`
  && {
    justify-content: center;
    min-width: 20px !important;
    align-items: center;
    min-height: 20px;
    margin-bottom: 14px;
    display: flex;
    padding: 2px 7px;
    align-self: center;
    width: fit-content;
    background-color: ${(p) => p.theme.color.primaryContainerColor};
    display: flex;
    flex-direction: row;
  }
  .fetch-more-btn-text {
    font-family: ${(p) => p.theme.font.primaryFont};
    font-style: normal;
    font-weight: 500;
    font-size: 12px;

    color: ${(p) => p.theme.color.primaryFontColor};
    text-transform: none;
  }
`;
