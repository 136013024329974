import React from "react";
import {
  StyledQuoteResponseContractorContainer,
  StyledQuoteResponseFieldWrapper,
  StyledQuoteResponseLabelFieldText,
} from "./StyledComponentQuoteResponse";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { useQuoteContext } from "./QuoteResponse";

type Props = {};

const QuoteResponseSupplier = (props: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore((state) => ({
    doxleThemeColor: state.doxleThemeColor,
    doxleFont: state.doxleFont,
  }));
  const { quoteResponse } = useQuoteContext();
  return (
    <StyledQuoteResponseFieldWrapper>
      <StyledQuoteResponseLabelFieldText
        $themeColor={doxleThemeColor}
        $doxleFont={doxleFont}
      >
        Contractor
      </StyledQuoteResponseLabelFieldText>

      <StyledQuoteResponseContractorContainer
        $themeColor={doxleThemeColor}
        $doxleFont={doxleFont}
      >
        {quoteResponse.supplier ? quoteResponse.supplierName : "No Contractor"}
      </StyledQuoteResponseContractorContainer>
    </StyledQuoteResponseFieldWrapper>
  );
};

export default QuoteResponseSupplier;
