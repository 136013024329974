import React, { useState } from "react";
import {DoxleFont, DoxleThemeColor, useDoxleThemeStore} from "../../../../DoxleGeneralStore/useDoxleThemeStore";

import { MainContainer, NextBtn, PromptArea, SelectArea, SelectRoleContainer, StyledDescription, WelcomeDoxle} from "./StyledFirstLogin";
import { emptyUserData, useWelcomeContext } from "../../pages/Welcome";

export default function SelectRolePage() {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore((state) => ({
    doxleThemeColor: state.doxleThemeColor,
    doxleFont: state.doxleFont
  }));

  const {stage,setStage, userFormData, setUserFormData}=useWelcomeContext();

  const [role, setRole] = useState((userFormData.role !== "")?userFormData.role:"Architect");
  const onOptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRole(e.target.value);
  };

  const handleClick = () => {
    const userData = {...userFormData};
    userData['role'] = role;
    setUserFormData(userData);
    setStage(stage + 1);
  }

  return (
    <MainContainer>
        <SelectRoleContainer>
        <PromptArea>
          <WelcomeDoxle $themeColor={doxleThemeColor}>Welcome to Doxle</WelcomeDoxle>
          <StyledDescription $themeColor={doxleThemeColor}>What best describes you?</StyledDescription>
        </PromptArea>

        <SelectArea $themeColor={doxleThemeColor} $doxleFont={doxleFont}>
          <label className="container">
            Architect
            <input
              type="radio"
              checked={role === "Architect"}
              value="Architect"
              onChange={onOptionChange}
            />
            <span className="checkmark"></span>
          </label>

          <label className="container">
            Builder
            <input
              type="radio"
              checked={role === "Builder"}
              value="Builder"
              onChange={onOptionChange}
            />
            <span className="checkmark"></span>
          </label>

          <label className="container">
            Developer
            <input
              type="radio"
              checked={role === "Developer"}
              value="Developer"
              onChange={onOptionChange}
            />
            <span className="checkmark"></span>
          </label>

          <label className="container">
            Engineer
            <input
              type="radio"
              checked={role === "Enginner"}
              value="Enginner"
              onChange={onOptionChange}
            />
            <span className="checkmark"></span>
          </label>

          <label className="container">
            Inspector
            <input
              type="radio"
              checked={role === "Inspector"}
              value="Inspector"
              onChange={onOptionChange}
            />
            <span className="checkmark"></span>
          </label>

          <label className="container">
            House Owner
            <input
              type="radio"
              checked={role === "House Owner"}
              value="House Owner"
              onChange={onOptionChange}
            />
            <span className="checkmark"></span>
          </label>
          <NextBtn $themeColor={doxleThemeColor} onClick={handleClick}>Next</NextBtn>
        </SelectArea>
        </SelectRoleContainer>
    </MainContainer>
  );
}

