import { useEditOrderStore } from "../OrdersStore/useEditOrderStore";
import { shallow } from "zustand/shallow";
import Order, { NEW_ORDER_TEMPLATE } from "../Models/order";
import OrdersQueryAPI from "../QueryHooks/OrdersQueryAPI";
import { useEffect, useMemo } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { useOrdersStore } from "../OrdersStore/useOrdersStore";
import useDoxleNotificationStore from "../../DoxleGeneralStore/useDoxleNotificationStore";
import { useDoxleCurrentContextStore } from "../../DoxleGeneralStore/useDoxleCurrentContext";
import { useEditDocketSideScreenStore } from "../../CoreContent/EditDocketSideScreen/EditDocketSideScreenStore/useEditDocketSideScreenStore";
import useGetOrderList from "./useGetOrderList";

const useOrdersTable = () => {
  // const [currentGroupCounts, setCurrentGroupCounts] = useState([])
  // const loadedItems = useRef(0)

  const company = useDoxleCurrentContextStore(
    (state) => state.currentCompany,
    shallow
  );
  const { edittedDocket, setHandleAddButtonFn } = useEditDocketSideScreenStore(
    (state) => ({
      edittedDocket: state.edittedDocket,
      setHandleAddButtonFn: state.setHandleAddButtonFn,
    }),
    shallow
  );
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );
  const { filter, selectedStatus } = useOrdersStore(
    (state) => ({
      filter: state.queryFilter,
      selectedStatus: state.selectedStatus,
    }),
    shallow
  );
  const { editedOrder, setEditedOrder } = useEditOrderStore(
    (state) => ({
      editedOrder: state.editedOrder,
      setEditedOrder: state.setEditedOrder,
    }),
    shallow
  );

  const {
    ordersList,
    isFetchingOrder,
    isSuccessFetchingOrder,
    isErrorFetchingOrder,

    handleFetchNextPageOrder,
    isFetchingNextPageOrder,
  } = useGetOrderList({});

  const filteredOrderList = useMemo(
    () =>
      selectedStatus
        ? ordersList.filter((order) => order.status === selectedStatus.statusId)
        : ordersList,
    [selectedStatus, ordersList]
  );
  interface SupplierGroup {
    supplier: string;
    orderTotal: number;
    totalPayments: number;
    totalBalance: number;
  }
  const { sortedOrderList, groupCounts, groups } = useMemo(() => {
    const sortedOrderList = filteredOrderList.sort((a, b) => {
      return a.supplier === b.supplier ? 0 : a.supplier > b.supplier ? 1 : -1;
    });
    let supplierName: string | undefined = undefined;
    let currentGroupOrderTotal: number = 0;
    let currentGroupPaymentTotal: number = 0;
    let currentGroupCount: number = 0;
    let groupCounts: number[] = [];
    let groups: SupplierGroup[] = [];
    sortedOrderList.forEach((order, i) => {
      if (order.supplier === supplierName) {
        currentGroupCount += 1;
        currentGroupOrderTotal += isNaN(Number(order.subTotal))
          ? 0
          : Number(order.subTotal);
        currentGroupPaymentTotal += isNaN(Number(order.totalPaymentsMade))
          ? 0
          : Number(order.totalPaymentsMade);
      } else {
        if (i !== 0) {
          groupCounts.push(currentGroupCount);
          groups.push({
            supplier: supplierName ?? "",
            orderTotal: currentGroupOrderTotal,
            totalPayments: currentGroupPaymentTotal,
            totalBalance: currentGroupOrderTotal - currentGroupPaymentTotal,
          });
        }
        currentGroupCount = 1;
        supplierName = order.supplier;
        currentGroupOrderTotal = isNaN(Number(order.subTotal))
          ? 0
          : Number(order.subTotal);
        currentGroupPaymentTotal = isNaN(Number(order.totalPaymentsMade))
          ? 0
          : Number(order.totalPaymentsMade);
      }
    });
    groupCounts.push(currentGroupCount);
    groups.push({
      supplier: supplierName ?? "",
      orderTotal: currentGroupOrderTotal,
      totalPayments: currentGroupPaymentTotal,
      totalBalance: currentGroupOrderTotal - currentGroupPaymentTotal,
    });

    return {
      sortedOrderList,
      groupCounts,
      groups,
    };
  }, [filteredOrderList]);

  const queryClient = useQueryClient();
  const handleAddNewOrderSuccess = (result: Order | undefined) => {
    if (result?.orderId) {
      queryClient.setQueryData(
        ["order-detail", company?.companyId],
        (old: any) => {
          if (!old) return old;
          return {
            ...old,
            data: result,
          };
        }
      );
      setEditedOrder(result);
    }
  };
  const mutateOrderQuery = OrdersQueryAPI.useMutateOrderQuery({
    filter,

    company,
    showNotification,
    onAddSuccessCb: handleAddNewOrderSuccess,
  });

  const handleCreateNewOrder = () => {
    if (edittedDocket) {
      const newOrder = NEW_ORDER_TEMPLATE({
        docket: edittedDocket,
        defaultStatus: selectedStatus,
      });
      mutateOrderQuery.addOrder.mutate(newOrder);
      // if (edittedDocket) setEditedOrder(newOrder);
    }
  };

  useEffect(() => {
    setHandleAddButtonFn([
      {
        btnFunction: handleCreateNewOrder,
        isLoading: mutateOrderQuery.addOrder.isLoading,
      },
    ]);
    return () => setHandleAddButtonFn([]);
  }, [mutateOrderQuery.addOrder.isLoading]);

  return {
    groupCounts,
    groups,
    sortedOrderList,
    isFetchingOrder,
    isSuccessFetchingOrder,
    isErrorFetchingOrder,
    handleFetchNextPageOrder,
    isFetchingNextPageOrder,
  };
};

export default useOrdersTable;
