import CircularProgress from "@mui/material/CircularProgress";
import useQACommentSection from "./Hooks/useQACommentSection";
import {
  StyledFetchMoreCmtButton,
  StyledQAEditFieldWrapper,
} from "./StyledComponentQAItemDetail";
import { useDoxleThemeStore } from "../../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import QACommentItem from "./QACommentItem";
import QACommentInputsection from "./QACommentInputsection";
import EditCommentTextDialog from "./EditCommentTextDialog";
import { useShallow } from "zustand/react/shallow";

type Props = {};

const QACommentSection = (props: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    useShallow((state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }))
  );
  const {
    commentList,
    isFetchingComment,
    editComment,
    setEditComment,
    isFetchingMoreComment,
    fetchMoreComment,
    hasNextPageComment,
    isErrorFetchingComment,
  } = useQACommentSection({});
  return (
    <StyledQAEditFieldWrapper>
      <span
        className="field-label-text"
        style={{
          marginBottom: 14,
        }}
      >
        Comment
      </span>
      {isFetchingComment ? (
        <CircularProgress
          style={{ color: doxleThemeColor.primaryFontColor }}
          size={16}
        />
      ) : !isErrorFetchingComment && hasNextPageComment ? (
        <StyledFetchMoreCmtButton
          onClick={fetchMoreComment}
          animate={{ scaleX: [0, 1] }}
          transition={{
            duration: 0.2,
            damping: 16,
          }}
        >
          <span className="fetch-more-btn-text">
            {!isFetchingMoreComment ? "Load more..." : "Loading..."}
          </span>

          {isFetchingMoreComment && (
            <CircularProgress
              size={14}
              sx={{ color: doxleThemeColor.doxleColor }}
            />
          )}
        </StyledFetchMoreCmtButton>
      ) : undefined}

      {!isFetchingComment &&
        commentList.map((comment, idx) => (
          <QACommentItem
            commentItem={comment}
            key={comment.commentId}
            setEditCommentDialog={setEditComment}
          />
        ))}
      <EditCommentTextDialog
        comment={editComment}
        setEditComment={setEditComment}
      />
      <QACommentInputsection />
    </StyledQAEditFieldWrapper>
  );
};

export default QACommentSection;
