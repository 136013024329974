import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
} from "@mui/material";
import styled from "styled-components";

import { motion } from "framer-motion";
import {
  DoxleFont,
  DoxleThemeColor,
} from "../../DoxleGeneralStore/useDoxleThemeStore";

export const StyledDoxleDialogHelperContainer = styled(Dialog)<{
  $themeColor: DoxleThemeColor;
}>`
  && {
    background-color: ${(p) => p.$themeColor.primaryBackdropColor};
    box-shadow: 1px 1px 2px ${(p) => p.$themeColor.primaryBoxShadowColor};
  }
  & .MuiDialog-paper {
    background-color: ${(p) => p.$themeColor.primaryBackdropColor};
    box-shadow: 0px 0px 14px 0px ${(p) => p.$themeColor.primaryBoxShadowColor};
  }
`;
export const StyledDoxleDialogHelperTitle = styled(DialogTitle)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  && {
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-style: normal;
    font-weight: 600;
    font-size: 20px;

    color: ${(p) => p.$themeColor.primaryFontColor};
    border-bottom: 1px solid ${(p) => p.$themeColor.primaryDividerColor};
    background-color: ${(p) => p.$themeColor.primaryContainerColor};
    padding: 7px 14px !important;
  }
`;

export const StyledDialogHelperPaper = styled(Paper)<{
  $themeColor: DoxleThemeColor;
}>`
  && {
    border-radius: 0.9rem;
    background-color: ${(p) => p.$themeColor.primaryContainerColor};
  }
`;
export const StyledDoxleDialogHelperContent = styled(DialogContent)<{
  $themeColor: DoxleThemeColor;
}>`
  && {
    background-color: ${(p) => p.$themeColor.primaryContainerColor};
    box-shadow: 1px 1px 2px ${(p) => p.$themeColor.primaryBoxShadowColor};

    word-break: break-word;
    padding: 8px 14px !important;
  }
`;
export const StyledDoxleDialogHelperText = styled(DialogContentText)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  && {
    span {
      font-family: ${(p) => p.$doxleFont.primaryFont};
      font-style: normal;
      font-weight: 400;
      font-size: 13px;

      letter-spacing: 0.05em;

      color: ${(p) => p.$themeColor.primaryFontColor};
    }
  }
`;
export const StyledDoxleDialogHelperActions = styled(DialogActions)<{
  $themeColor: DoxleThemeColor;
}>`
  && {
    background-color: ${(p) => p.$themeColor.primaryContainerColor};
    box-shadow: 1px 1px 2px ${(p) => p.$themeColor.primaryBoxShadowColor};
  }
`;
export const StyledDoxleDialogHelperEditButton = styled(motion(Button))<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
  $bgColor?: string | undefined;
  $buttonIndex: number;
  $dialogDescription?: string;
}>`
  && {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0px 4px;
    border-radius: 4px !important;
    text-transform: capitalize;
    background-color: ${(p) =>
      p.$bgColor ? p.$bgColor : p.$themeColor.primaryFontColor};
    &:hover {
      background-color: ${(p) =>
        p.$bgColor ? p.$bgColor : p.$themeColor.primaryFontColor} !important;
      opacity: 0.9;
      transform: translateY(-1px) scale(1.05);
      transition: 0.4s;
    }
    .button-text {
      font-family: ${(p) => p.$doxleFont.primaryFont};
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: normal;
      letter-spacing: 0.05em;
      color: ${(p) => p.$themeColor.primaryReverseFontColor};
    }
  }
`;
