import { useQueryClient } from "@tanstack/react-query";
import React from "react";
import { useDoxleCurrentContextStore } from "../../DoxleGeneralStore/useDoxleCurrentContext";
import { formQAImageQKey } from "../QueryAPI/qaQueryAPI";
import { shallow } from "zustand/shallow";
import { QAImage } from "../Models/qa";
import { produce } from "immer";
import { useShallow } from "zustand/react/shallow";

type Props = { qaId: string; appendPos?: "start" | "end"; overwrite?: boolean };

interface SetQaImageQueryData {
  handleAddQAImgQueryData: (addedQAImg: QAImage) => void;
  handleDeleteQAImgQueryData: (deletedQAImgId: string) => void;
  handleAddMultiQAImgQueryData: (addedQAImgList: QAImage[]) => void;
  handleUpdateQAImgQueryData: (
    qaImageId: string,
    updatedData: Partial<QAImage>
  ) => void;
}
const useSetQaImageQueryData = ({
  qaId,
  appendPos = "start",
  overwrite = true,
}: Props): SetQaImageQueryData => {
  const queryClient = useQueryClient();
  const { company } = useDoxleCurrentContextStore(
    useShallow((state) => ({
      company: state.currentCompany,
    }))
  );
  const qKey = formQAImageQKey(qaId, company);

  const handleAddQAImgQueryData = (addedQAImg: QAImage) => {
    const oldServerData = queryClient.getQueryData(qKey);
    if (oldServerData && overwrite) {
      queryClient.setQueryData(qKey, (old: any) => {
        if (old) {
          return produce(old, (draftOld: any) => {
            if (appendPos === "start") {
              (draftOld.data.results as QAImage[]).unshift(addedQAImg);
            } else {
              (draftOld.data.results as QAImage[]).push(addedQAImg);
            }

            return draftOld;
          });
        } else return old;
      });
    } else queryClient.invalidateQueries(qKey);
  };

  const handleAddMultiQAImgQueryData = (addedQAImgList: QAImage[]) => {
    const oldServerData = queryClient.getQueryData(qKey);
    if (oldServerData && overwrite) {
      queryClient.setQueryData(qKey, (old: any) => {
        if (old) {
          return produce(old, (draftOld: any) => {
            if (appendPos === "start") {
              (draftOld.data.results as QAImage[]).unshift(...addedQAImgList);
            } else {
              (draftOld.data.results as QAImage[]).push(...addedQAImgList);
            }

            return draftOld;
          });
        } else return old;
      });
    } else queryClient.invalidateQueries(qKey);
  };

  const handleDeleteQAImgQueryData = (deletedQAImgId: string) => {
    const oldServerData = queryClient.getQueryData(qKey);
    if (oldServerData && overwrite) {
      queryClient.setQueryData(qKey, (old: any) => {
        return produce(old, (draftOld: any) => {
          draftOld.data.results = produce(
            draftOld.data.results,
            (draftQA: QAImage[]) => {
              draftQA = draftQA.filter((img) => img.imageId !== deletedQAImgId);
              return draftQA;
            }
          );
          return draftOld;
        });
      });
    } else queryClient.invalidateQueries(qKey);
  };

  const handleUpdateQAImgQueryData = (
    qaImageId: string,
    updatedData: Partial<QAImage>
  ) => {
    const oldServerData = queryClient.getQueryData(qKey);
    if (oldServerData && overwrite) {
      queryClient.setQueryData(qKey, (old: any) => {
        return produce(old, (draftOld: any) => {
          draftOld.data.results = produce(
            draftOld.data.results,
            (draftQA: QAImage[]) => {
              const imageItem = draftQA.find(
                (img) => img.imageId === qaImageId
              );

              if (imageItem) Object.assign(imageItem, updatedData);
              return draftQA;
            }
          );
          return draftOld;
        });
      });
    } else queryClient.invalidateQueries(qKey);
  };
  return {
    handleAddQAImgQueryData,
    handleDeleteQAImgQueryData,
    handleAddMultiQAImgQueryData,
    handleUpdateQAImgQueryData,
  };
};

export default useSetQaImageQueryData;
