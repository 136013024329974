import { PricebookItem } from "../../Models/Pricebook";
import {
  StyledPriceRateSection,
  StyledPricebookCardBottomMenu,
  StyledPricebookCard,
  StyledPricebookCardImgSection,
  StyledSwatchDropzone,
  StyledCardMenuHoverButton,
  StyledPricebookNameText,
} from "./StyledComponents";
import PricebookSupplierRateLine from "./PricebookSupplierRateLine";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { AttachmentUploadIcon } from "../../../CoreContent/EditDocketSideScreen/EditDocketSideScreenIcons";
import usePricebookCard from "./Hooks/usePricebookCard";
import { useDropzone } from "react-dropzone";
import { usePricebookStore } from "../../Store/usePricebookStore";
import { useShallow } from "zustand/react/shallow";
import { FaAngleLeft } from "react-icons/fa6";
import { FaAngleRight } from "react-icons/fa6";
import {
  DoxleDeleteIcon,
  DoxleEditIcon,
} from "../../../DoxleDesignPattern/DoxleGeneralIcons";
import DoxleExpandableDeleteBtn from "../../../DoxleDesignPattern/DoxleButtons/DoxleExpandableDeleteBtn";

type Props = {
  pricebook: PricebookItem;
};

const PricebookCard = ({ pricebook }: Props) => {
  const {
    currentViewSwatchIdx,
    switchNextSwatch,
    switchPrevSwatch,
    onDrop,
    handleClickSwatchImg,

    isPrevSwatchAvailable,
    isNextSwatchAvailable,
    handleDeletePricebookItem,
    isDeletingPricebook,
  } = usePricebookCard({ pricebookItem: pricebook });
  const { setEditDialogItemId } = usePricebookStore(
    useShallow((state) => ({
      setEditDialogItemId: state.setEditedPricebookItemId,
    }))
  );
  // useDropzone for Dropzone
  const { getRootProps } = useDropzone({
    accept: {
      "image/png": [".png"],
      "image/jpg": [".jpg"],
      "image/jpeg": [".jpeg"],
    },
    onDrop,
  });
  const { doxleThemeColor } = useDoxleThemeStore((state) => ({
    doxleThemeColor: state.doxleThemeColor,
  }));

  return (
    <StyledPricebookCard>
      <StyledPricebookCardImgSection>
        {pricebook.swatches.length > 0 && (
          <>
            {isPrevSwatchAvailable && (
              <FaAngleLeft
                size={30}
                color={"white"}
                className="icon-prev"
                onClick={switchPrevSwatch}
              />
            )}
            <img
              onClick={handleClickSwatchImg}
              className="image-wrapper"
              src={pricebook.swatches[currentViewSwatchIdx].thumbUrl}
            />
            {isNextSwatchAvailable && (
              <FaAngleRight
                size={30}
                color={"white"}
                className="icon-next"
                onClick={switchNextSwatch}
              />
            )}
          </>
        )}

        {pricebook.swatches.length === 0 && (
          <StyledSwatchDropzone {...getRootProps()}>
            <AttachmentUploadIcon
              themeColor={doxleThemeColor}
              containerStyle={{
                width: "40%",
                maxWidth: 80,
                marginBottom: 14,
              }}
            />
            Click to image
          </StyledSwatchDropzone>
        )}
      </StyledPricebookCardImgSection>
      <StyledPricebookNameText>{pricebook.description}</StyledPricebookNameText>
      <StyledPriceRateSection>
        {pricebook.supplierRates.map((rate, index) => (
          <PricebookSupplierRateLine
            index={index}
            supplierRates={rate}
            pricebookId={pricebook.pricebookId}
            key={rate.rateId}
          />
        ))}
      </StyledPriceRateSection>

      <StyledPricebookCardBottomMenu
        className="bottom-menu"
        style={isDeletingPricebook ? { opacity: 1 } : undefined}
      >
        <StyledCardMenuHoverButton
          onClick={() => setEditDialogItemId(pricebook.pricebookId)}
        >
          <DoxleEditIcon
            themeColor={doxleThemeColor}
            containerStyle={{
              width: 20,
            }}
          />
          <span className="btn-text">Edit</span>
        </StyledCardMenuHoverButton>
        <DoxleExpandableDeleteBtn
          deleteIcon={
            <DoxleDeleteIcon
              themeColor={doxleThemeColor}
              containerStyle={{
                width: 20,
              }}
              iconColor="rgba(255,0,0,1)"
            />
          }
          deleteTextStyle={{
            color: "red",
          }}
          onConfirmDelete={handleDeletePricebookItem}
          isDeleting={isDeletingPricebook}
        />
      </StyledPricebookCardBottomMenu>
    </StyledPricebookCard>
  );
};

export default PricebookCard;
