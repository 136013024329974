import React, { createContext, useContext } from "react";
import {
  StyledQRLogSummaryBtn,
  StyledQRSummaryDialog,
  StyledQRSummaryDialogContent,
  StyledQRSummaryDialogTitle,
  StyledSummaryTitleSection,
} from "./StyledQRLogSummary";
import useQRLogSummary, {
  IQRLogSummaryContextValue,
} from "./Hooks/useQRLogSummary";
import { TbReport } from "react-icons/tb";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import DoxleCloseBtn from "../../../DoxleDesignPattern/DoxleButtons/DoxleCloseBtn";
import { IoClose } from "react-icons/io5";
import { useDoxleCurrentContextStore } from "../../../DoxleGeneralStore/useDoxleCurrentContext";
import { useShallow } from "zustand/react/shallow";
import dayjs from "dayjs";
import Loading from "../../../Utilities/Lottie/Loading";
import QRSummaryList from "./QRSummaryList";
import QRLogSummaryFilter from "./QRLogSummaryFilter";
import DoxleFilterTag from "../../../DoxleDesignPattern/DoxleFilterTag/DoxleFilterTag";

type Props = {};
const QRLogSummaryContext = createContext<IQRLogSummaryContextValue | null>(
  null
);
const QRLogSummary = (props: Props) => {
  const { doxleThemeColor, staticMenuColor } = useDoxleThemeStore((state) => ({
    doxleThemeColor: state.doxleThemeColor,
    staticMenuColor: state.staticMenuColor,
  }));
  const { currentProject } = useDoxleCurrentContextStore(
    useShallow((state) => ({ currentProject: state.currentProject }))
  );
  const {
    showQRSummaryDialog,
    setShowQRSummaryDialog,
    dateFilterRange,
    setDateFilterRange,
    contextValue,

    isFetchingQRUserLogList,
    closeSummaryDialog,
    filteredUser,
    setFilteredUser,
  } = useQRLogSummary();
  return (
    <QRLogSummaryContext.Provider value={contextValue}>
      <StyledQRLogSummaryBtn onClick={() => setShowQRSummaryDialog(true)}>
        <TbReport size={25} color={doxleThemeColor.primaryFontColor} />
      </StyledQRLogSummaryBtn>

      <StyledQRSummaryDialog
        open={showQRSummaryDialog}
        onClose={closeSummaryDialog}
        slotProps={{
          backdrop: {
            sx: {
              bgcolor: staticMenuColor.staticBackdrop,
            },
          },
        }}
      >
        <StyledQRSummaryDialogTitle>
          <div className="title-wrapper">Log Report</div>

          <DoxleCloseBtn
            sx={{
              padding: "0px !important",
            }}
            onClick={(e) => {
              e.stopPropagation();
              closeSummaryDialog();
            }}
            closeIcon={
              <IoClose color={doxleThemeColor.primaryFontColor} size={30} />
            }
          />
        </StyledQRSummaryDialogTitle>
        <StyledQRSummaryDialogContent>
          <StyledSummaryTitleSection>
            <div className="title-section">
              <span className="site-address-text">
                {currentProject?.siteAddress ?? "-"}
              </span>
              <span className="date-filter-text">
                ({dayjs(dateFilterRange.start).format("DD MMM")} -{" "}
                {dayjs(dateFilterRange.end).format("DD MMM YY")})
              </span>
            </div>
            {filteredUser && (
              <DoxleFilterTag
                itemData={filteredUser}
                itemDisplayText={`${filteredUser.firstName} ${filteredUser.lastName}`}
                removable={true}
                removeItemFunction={() => setFilteredUser(undefined)}
                tagStyle={{ marginInline: 12, alignSelf: "center" }}
              />
            )}
            <QRLogSummaryFilter />
          </StyledSummaryTitleSection>

          {isFetchingQRUserLogList && (
            <Loading
              containerStyle={{
                width: "100%",

                justifyContent: "center",
                alignItems: "center",
                position: "absolute",
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                background: doxleThemeColor.primaryContainerColor,
              }}
              textStyle={{
                fontSize: 18,
              }}
            />
          )}
          {!isFetchingQRUserLogList && <QRSummaryList />}
        </StyledQRSummaryDialogContent>
      </StyledQRSummaryDialog>
    </QRLogSummaryContext.Provider>
  );
};
export const useQRLogSummaryContext = () =>
  useContext(QRLogSummaryContext) as IQRLogSummaryContextValue;
export default QRLogSummary;
