import React, { useEffect, useRef, useState } from "react";

import { shallow } from "zustand/shallow";
import { useScopeOfWorkStore } from "../Store/useScopeOfWorkStore";
import { ScopeOfWorkGroup } from "../Model/scopeOfWorks";
import ScopeOfWorkAPI, {
  UpdateScopeGroupData,
  getSOWGroupMutationKey,
} from "../QueryAPI/scopeOfWorkQueryAPI";
import { useDoxleCurrentContextStore } from "../../DoxleGeneralStore/useDoxleCurrentContext";
import useDoxleNotificationStore from "../../DoxleGeneralStore/useDoxleNotificationStore";
import { Contact, ContactCompany } from "../../Models/addressBook";
import useSetScopeOfWorkGroupQueryData from "./useSetScopeOfWorkGroupQueryData";
import useSetSOWItemQueryData from "./useSetSOWItemQueryData";
import { useIsMutating } from "@tanstack/react-query";

interface Props {
  scopeGroup: ScopeOfWorkGroup;
}

interface ScopeGroupTitle {
  handleClickGroupTitle: (event: React.MouseEvent<HTMLElement>) => void;
  onEditMode: boolean;
  setOnEditMode: React.Dispatch<React.SetStateAction<boolean>>;
  newTitle: string;
  setNewTitle: React.Dispatch<React.SetStateAction<string>>;
  titleFieldRef: React.RefObject<HTMLInputElement>;
  onKeyDownTitleField: (event: React.KeyboardEvent<HTMLDivElement>) => void;
  handleClickAssigneeBtn: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
  handleClosePopover: () => void;
  anchorAssigneePopover: HTMLElement | null;
  handleUpdateContactAssignee: (item: Contact) => void;
  handleUpdateCompanyAssignee: (item: ContactCompany) => void;
  handleClickDeleteBtn: () => void;
  handleBlurTitleTextField: () => void;
  handleCheckComplete: (value: boolean) => void;
  isUpdatingCheck: boolean;
  handleToggleExpandSOWGroup: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
  isGroupExpanded: boolean;
  handleRemoveContactAssignee: (item: Contact) => void;
  handleRemoveCompanyAssignee: (item: ContactCompany) => void;
  handleDoubleClickTextField: () => void;
  hiddenTextRef: React.RefObject<HTMLSpanElement>;
  textFieldWidth: number | "auto";
}
export const useScopeGroupTitle = ({ scopeGroup }: Props): ScopeGroupTitle => {
  const [anchorAssigneePopover, setAnchorAssigneePopover] =
    useState<HTMLElement | null>(null);
  const [onEditMode, setOnEditMode] = useState(scopeGroup.isNew ?? false);
  const [newTitle, setNewTitle] = useState(scopeGroup.scopeGroupTitle);
  const [textFieldWidth, setTextFieldWidth] = useState<"auto" | number>("auto");

  const { company } = useDoxleCurrentContextStore((state) => ({
    company: state.currentCompany,
  }));
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification
  );
  const {
    filterScopeOfWorkGroup,
    setDeletedSOWGroup,
    toggleExpandedSOWGroup,
    expandedSOWGroupIds,
  } = useScopeOfWorkStore(
    (state) => ({
      filterScopeOfWorkGroup: state.filterScopeOfWorkGroup,
      setDeletedSOWGroup: state.setDeletedSOWGroup,
      toggleExpandedSOWGroup: state.toggleExpandedSOWGroup,
      expandedSOWGroupIds: state.expandedSOWGroupIds,
    }),
    shallow
  );
  const handleClickGroupTitle = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setNewTitle(scopeGroup.scopeGroupTitle);
    setOnEditMode(true);
  };
  const { handleToggleCompleteAllSOW, handleEditSOWItem } =
    useSetSOWItemQueryData({
      filter: {
        scopeGroupId: scopeGroup.scopeGroupId,
      },
    });

  const onUpdateSuccessCb = (
    updatedItem?: ScopeOfWorkGroup,
    lastPayload?: UpdateScopeGroupData
  ) => {
    if (lastPayload && lastPayload.completed !== undefined) {
      handleToggleCompleteAllSOW(lastPayload.completed);
    }
  };

  const updateSOWGroupQuery = ScopeOfWorkAPI.useUpdateScopeGroupQuery({
    showNotification,

    company,
    filter: filterScopeOfWorkGroup,
    onSuccessCb: onUpdateSuccessCb,
  });
  const titleFieldRef = useRef<HTMLInputElement>(null);
  const hiddenTextRef = useRef<HTMLSpanElement>(null);
  useEffect(() => {
    if (hiddenTextRef.current) {
      const textWidth = hiddenTextRef.current.getBoundingClientRect();
      if (textWidth) setTextFieldWidth(textWidth.width); // Add a little extra space for padding
    }
  }, [newTitle]);
  const handleDoubleClickTextField = () => {
    setOnEditMode(true);
    // Focus the text field after enabling edit mode
    setTimeout(() => {
      titleFieldRef.current?.focus();
    }, 0);
  };
  const handleUpdateGrouptTitle = () => {
    if (newTitle) {
      updateSOWGroupQuery.mutate({
        scopeGroupTitle: newTitle,
        scopeGroupId: scopeGroup.scopeGroupId,
      });
    } else {
      setNewTitle(scopeGroup.scopeGroupTitle);
    }

    setOnEditMode(false);
  };
  const onKeyDownTitleField = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (
      event.nativeEvent.key === "Enter" &&
      !(event.shiftKey || event.ctrlKey || event.metaKey || event.altKey)
    ) {
      event.preventDefault();
      handleUpdateGrouptTitle();
      titleFieldRef.current?.blur();
    } else if (
      event.nativeEvent.key === "Enter" &&
      (event.ctrlKey || event.metaKey || event.altKey)
    ) {
      setNewTitle((prev) => prev + "\n");
    } else if (event.nativeEvent.key === "Escape") {
      setNewTitle("");
    }
  };

  const handleClickAssigneeBtn = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setAnchorAssigneePopover(e.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorAssigneePopover(null);
  };

  const handleUpdateContactAssignee = (item: Contact) => {
    if (scopeGroup.assignedContacts) {
      const isExist = scopeGroup.assignedContacts.includes(item.contactId);
      if (!isExist)
        updateSOWGroupQuery.mutate({
          scopeGroupId: scopeGroup.scopeGroupId,
          assignedContacts: [...scopeGroup.assignedContacts, item.contactId],
        });
      else
        updateSOWGroupQuery.mutate({
          scopeGroupId: scopeGroup.scopeGroupId,
          assignedContacts: scopeGroup.assignedContacts.filter(
            (selectedItem) => selectedItem !== item.contactId
          ),
        });
    } else {
      updateSOWGroupQuery.mutate({
        scopeGroupId: scopeGroup.scopeGroupId,
        assignedContacts: [item.contactId],
      });
    }
  };

  const handleUpdateCompanyAssignee = (item: ContactCompany) => {
    if (scopeGroup.assignedContactCompany) {
      updateSOWGroupQuery.mutate({
        scopeGroupId: scopeGroup.scopeGroupId,
        assignedContactCompany: null,
      });
    } else {
      updateSOWGroupQuery.mutate({
        scopeGroupId: scopeGroup.scopeGroupId,
        assignedContactCompany: item.contactCompanyId,
      });
    }
  };

  const handleRemoveContactAssignee = (item: Contact) => {
    if (scopeGroup.assignedContacts) {
      updateSOWGroupQuery.mutate({
        scopeGroupId: scopeGroup.scopeGroupId,
        assignedContacts: scopeGroup.assignedContacts.filter(
          (selectedItem) => selectedItem !== item.contactId
        ),
      });
    }
  };

  const handleRemoveCompanyAssignee = (item: ContactCompany) => {
    if (scopeGroup.assignedContactCompany) {
      updateSOWGroupQuery.mutate({
        scopeGroupId: scopeGroup.scopeGroupId,
        assignedContactCompany: null,
      });
    }
  };
  const handleClickDeleteBtn = () => {
    setDeletedSOWGroup(scopeGroup);
  };

  const { handleUpdateSOWGroup } = useSetScopeOfWorkGroupQueryData({
    filter: filterScopeOfWorkGroup,
  });

  const handleBlurTitleTextField = () => {
    if (newTitle) handleUpdateSOWGroup({ ...scopeGroup, isNew: false });
    else {
      setNewTitle(scopeGroup.scopeGroupTitle);
    }
    setOnEditMode(false);
  };

  const handleCheckComplete = (value: boolean) => {
    updateSOWGroupQuery.mutate({
      scopeGroupId: scopeGroup.scopeGroupId,
      completed: value,
    });
  };

  const handleToggleExpandSOWGroup = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.stopPropagation();
    toggleExpandedSOWGroup(scopeGroup.scopeGroupId);
  };

  const isGroupExpanded = expandedSOWGroupIds.includes(scopeGroup.scopeGroupId);
  const isUpdatingCheck =
    useIsMutating({
      mutationKey: getSOWGroupMutationKey("update"),
      predicate: (query) =>
        Boolean(
          (query.state.variables as UpdateScopeGroupData).scopeGroupId ===
            scopeGroup.scopeGroupId &&
            (query.state.variables as UpdateScopeGroupData).completed !==
              undefined
        ),
    }) > 0;

  useEffect(() => {
    if (scopeGroup.isNew) {
      setOnEditMode(true);
      titleFieldRef.current?.focus();
    }
  }, [scopeGroup.isNew, titleFieldRef.current]);
  return {
    handleClickGroupTitle,
    onEditMode,
    setOnEditMode,
    newTitle,
    setNewTitle,
    titleFieldRef,
    onKeyDownTitleField,
    handleClickAssigneeBtn,
    handleClosePopover,
    anchorAssigneePopover,
    handleUpdateContactAssignee,
    handleUpdateCompanyAssignee,
    handleClickDeleteBtn,
    handleBlurTitleTextField,
    handleCheckComplete,
    isUpdatingCheck,
    handleToggleExpandSOWGroup,
    isGroupExpanded,
    handleRemoveContactAssignee,
    handleRemoveCompanyAssignee,
    handleDoubleClickTextField,
    hiddenTextRef,
    textFieldWidth,
  };
};
