import { motion } from "framer-motion";
import styled from "styled-components";
import {
  DoxleFont,
  DoxleThemeColor,
} from "../../DoxleGeneralStore/useDoxleThemeStore";
import Button from "@mui/material/Button";
import { editRgbaAlpha } from "../../Utilities/FunctionUtilities";

export const StyledQAProjectContainer = styled(motion.div)`
  width: 100%;
  height: calc(100% - 2rem);
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: 2rem;
  .qa-content-wrapper {
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    min-height: 0px;
    overflow-y: auto;
  }
`;

export const StyledQAProjectNavContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 45px;
  .nav-wrapper {
    flex: 1;
    display: flex;
    flex-direction: row;
  }
`;

export const StyledBreadCrumbQANavItem = styled(motion.div)<{
  $isSubMenu?: boolean;
}>`
  width: 10rem;
  height: 2.4rem;
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  text-transform: capitalize;
  // margin-left: ${(p) => (p.$isSubMenu ? "-0.8rem" : 0)};
  // clip-path: polygon(0 0, 85% 0, 100% 50%, 85% 100%, 0 100%, 15% 50%, 0 0);
  border-radius: 3px;
  margin: 0px 2px;
  border: 1px solid ${(p) => p.theme.color.primaryDividerColor};
  .nav-text {
    font-family: ${(p) => p.theme.font.primaryFont};
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 110%;
    transition: 0.4s;
    text-decoration: underline;
    color: inherit;
    max-width: 70%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-left: 4px;
  }

  &:hover {
    opacity: 0.8;
    transition: 0.4s;
  }
`;

export const StyledQAListToggleMenu = styled(motion.div)<{
  $themeColor: DoxleThemeColor;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;

  .loader-spinner {
    color: ${(p) => p.$themeColor.primaryFontColor};
    margin-right: 8px;
  }
`;

export const StyledQAListStatusItem = styled(Button)<{
  $selected: boolean;
}>`
  && {
    min-width: 0px !important;
    display: flex;
    flex-direction: row;
    align-items: center;

    position: relative;
    text-transform: capitalize;

    .text-status {
      color: ${(p) => p.theme.color.primaryFontColor};
      font-family: ${(p) => p.theme.font.primaryFont};
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      padding: 0.5px 8px;
      background-color: ${(p) =>
        p.$selected
          ? editRgbaAlpha({
              rgbaColor: p.theme.color.doxleColor,
              alpha: "0.15",
            })
          : "transparent"};
    }
  }
`;
