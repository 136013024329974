import React, { useCallback, useMemo } from "react";
import { useQRLogSummaryContext } from "./QRLogSummary";
import { IQRUserLog, IQRUserLogUserGroup } from "../../Models/qrLog";
import { groupBy } from "lodash";
import {
  Components,
  GroupContent,
  GroupItemContent,
  GroupedVirtuoso,
} from "react-virtuoso";
import { IQRSummaryListContext } from "../../Models/QRListTypes";
import DoxleEmptyPlaceHolder from "../../../DoxleDesignPattern/DoxleEmptyPlaceHolder/DoxleEmptyPlaceHolder";
import { Error404Banner } from "../../../DoxleDesignPattern/DoxleIcons/CommonIcons";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { EmptyQRLogBanner } from "../QRIcons";
import QRSummaryItem from "./QRSummaryItem";
import QRSummaryGroupHeader from "./QRSummaryGroupHeader";
import { StyledWrapperWithFooter } from "./StyledQRLogSummary";
import QRSummaryGroupFooter from "./QRSummaryGroupFooter";

type Props = {};

const QRSummaryList = (props: Props) => {
  const { doxleThemeColor, staticMenuColor } = useDoxleThemeStore((state) => ({
    doxleThemeColor: state.doxleThemeColor,
    staticMenuColor: state.staticMenuColor,
  }));
  const {
    userLogList,
    isErrorFetchingQRUserLogList,
    isRefetchingQRUserLogList,
    refetchQRUserLogList,
    fetchNextPageQRUserLog,
    hiddenUser,
  } = useQRLogSummaryContext();
  const dataUserGroup: IQRUserLogUserGroup = useMemo(() => {
    const dateSet = groupBy(userLogList, (log) => log.user); // group by pricebookItem
    return Object.keys(dateSet)
      .sort((a, b) =>
        dateSet[a][0].userName.localeCompare(dateSet[b][0].userName)
      )
      .reduce(
        (acc, userId) => {
          const isShrink = hiddenUser.includes(userId);
          return (
            acc.userList.push({
              userId,
              userName: dateSet[userId][0].userName,
            }),
            acc.datalogList.push(
              ...(isShrink
                ? []
                : dateSet[userId].sort(
                    (a, b) =>
                      new Date(a.entryTime).getTime() -
                      new Date(b.entryTime).getTime()
                  ))
            ),
            acc.itemCount.push(isShrink ? 0 : dateSet[userId].length),
            acc
          );
        },
        {
          userList: [],
          datalogList: [],
          itemCount: [],
        } as IQRUserLogUserGroup
      );
  }, [userLogList, hiddenUser]);

  //*render group list
  const groupListComponents: Components<IQRUserLog, IQRSummaryListContext> =
    useMemo(
      () => ({
        EmptyPlaceholder: ({ context, ...rest }) => (
          <DoxleEmptyPlaceHolder
            headTitleText={
              context?.isErrorFetchingQRUserLogList
                ? "Something Wrong"
                : "No Logs Found"
            }
            subTitleText={
              context?.isErrorFetchingQRUserLogList
                ? "Failed to get data, please try to reload the page"
                : ""
            }
            containerStyle={{
              width: "100%",
              flexGrow: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
            containerBgColor="transparent"
            illustration={
              context?.isErrorFetchingQRUserLogList ? (
                <Error404Banner themeColor={doxleThemeColor} />
              ) : (
                <EmptyQRLogBanner themeColor={doxleThemeColor} />
              )
            }
          />
        ),
      }),
      []
    );
  const itemContent: GroupItemContent<IQRUserLog, IQRSummaryListContext> =
    useCallback(
      (index, groupIdx, data, context) => {
        const item = dataUserGroup.datalogList[index];
        const isShrink = hiddenUser.includes(
          dataUserGroup.userList[groupIdx].userId
        );
        const lastIdxOfGroup =
          dataUserGroup.itemCount.reduce((acc, count, idx) => {
            if (idx <= groupIdx) return acc + count;
            return acc;
          }, 0) - 1;

        if (item)
          return index !== lastIdxOfGroup ? (
            <QRSummaryItem key={item.userLogId} logItem={item} />
          ) : (
            <StyledWrapperWithFooter key={item.userLogId}>
              <QRSummaryItem logItem={item} />
              <QRSummaryGroupFooter userId={item.user} />
            </StyledWrapperWithFooter>
          );
      },
      [dataUserGroup, hiddenUser]
    );

  const groupLogItemContent: GroupContent<IQRSummaryListContext> = useCallback(
    (groupIdx, context) => {
      const item = dataUserGroup.userList[groupIdx];
      const isShrink = hiddenUser.includes(
        dataUserGroup.userList[groupIdx].userId
      );
      if (isShrink)
        return (
          <StyledWrapperWithFooter key={item.userId}>
            <QRSummaryGroupHeader {...item} />
            <QRSummaryGroupFooter userId={item.userId} />
          </StyledWrapperWithFooter>
        );
      return <QRSummaryGroupHeader {...item} key={item.userId} />;
    },
    [dataUserGroup.userList, hiddenUser]
  );
  const tableStyle: React.CSSProperties = {
    width: "100%",
    flex: 1,
  };
  return (
    <GroupedVirtuoso
      style={tableStyle}
      context={{
        isErrorFetchingQRUserLogList,
      }}
      groupCounts={dataUserGroup.itemCount}
      components={groupListComponents}
      itemContent={itemContent}
      groupContent={groupLogItemContent}
      endReached={() => {
        fetchNextPageQRUserLog();
      }}
      increaseViewportBy={300}
    />
  );
};

export default QRSummaryList;
