import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import { useDoxleCurrentContextStore } from "../../../DoxleGeneralStore/useDoxleCurrentContext";
import { useDoxleAuthStore } from "../../../DoxleGeneralStore/useDoxleAuthStore";
import useDoxleNotificationStore from "../../../DoxleGeneralStore/useDoxleNotificationStore";
import { useContactsStore } from "../Store/useContactsStore";
import ContactsQueryAPI from "../../QueryHooks/ContactsQueryAPI";
import { ContactCompany } from "../../../Models/addressBook";
import React, { useMemo } from "react";
import DoxleListSkeleton from "../../../DoxleDesignPattern/DoxleSkeleton/DoxleListSkeleton";
import {
  StyledContactCompanyPageContainer,
  StyledPageContainer,
} from "../StyledComponents";
import ContactCompaniesSidePeek from "../ContactCompaniesSidePeek/ContactCompaniesSidePeek";
import ContactCompanyTable from "./ContactCompanyTable";
import useGetContactCompanyList from "../../Hooks/useGetContactCompanyList";

const ContactCompanyPage = () => {
  const doxleThemeColor = useDoxleThemeStore(
    (state) => state.doxleThemeColor,
    shallow
  );
  const { isFetchingContactCompanyList } = useGetContactCompanyList({});

  return (
    <StyledContactCompanyPageContainer>
      {isFetchingContactCompanyList && (
        <DoxleListSkeleton skeletonType={"orderRow"} />
      )}
      {!isFetchingContactCompanyList && <ContactCompanyTable />}
      <ContactCompaniesSidePeek />
    </StyledContactCompanyPageContainer>
  );
};

export default ContactCompanyPage;
