import { useMemo } from "react";
import { IStageAPIQueryFilter, IStageBudget } from "../../Models/stage";
import { useDoxleCurrentContextStore } from "../../../DoxleGeneralStore/useDoxleCurrentContext";
import { shallow } from "zustand/shallow";
import useDoxleNotificationStore from "../../../DoxleGeneralStore/useDoxleNotificationStore";
import StageQueryAPI from "../../../Services/QueryHooks/stageQueryAPI";

type Props = {};

type IGetStageList = {
  stageList: IStageBudget[];
  isFetchingStageList: boolean;
  isSuccessFetchingStageList: boolean;
  isErrorFetchingStageList: boolean;
  refetchStageList: () => void;
  isRefetchingStageList: boolean;
  handleFetchNextPageStage: () => void;
  isFetchingNextPageStage: boolean;
};
const useGetStageList = (props?: Props): IGetStageList => {
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );
  const { currentProject, currentCompany } = useDoxleCurrentContextStore(
    (state) => ({
      currentProject: state.currentProject,
      currentCompany: state.currentCompany,
    }),
    shallow
  );
  const filterStageList: IStageAPIQueryFilter = useMemo(
    () => ({
      projectId: currentProject?.projectId ?? "",

      order_by: "index",
    }),
    [currentProject]
  );
  const stageListQuery = StageQueryAPI.useRetrieveStageListQuery({
    filter: filterStageList,
    company: currentCompany,
    showNotification,
    enable: Boolean(filterStageList.projectId),
  });

  const stageList = useMemo(
    () => stageListQuery.data?.pages.flatMap((data) => data.data.results) ?? [],
    [stageListQuery.data]
  );

  const refetchStageList = () => {
    stageListQuery.refetch();
  };

  const handleFetchNextPageStage = () => {
    if (stageListQuery.hasNextPage) {
      stageListQuery.fetchNextPage();
    }
  };
  return {
    stageList,
    isFetchingStageList: stageListQuery.isLoading,
    isSuccessFetchingStageList: stageListQuery.isSuccess,
    isErrorFetchingStageList: stageListQuery.isError,
    refetchStageList,
    isRefetchingStageList: stageListQuery.isRefetching,
    handleFetchNextPageStage,
    isFetchingNextPageStage: stageListQuery.isFetchingNextPage,
  };
};

export default useGetStageList;
