import React, { useState } from "react";
import PermissionsAPI from "../../../../Services/QueryHooks/permissionsAPI";
import { useDoxleCurrentContextStore } from "../../../../DoxleGeneralStore/useDoxleCurrentContext";
import { shallow } from "zustand/shallow";
import useDoxleNotificationStore from "../../../../DoxleGeneralStore/useDoxleNotificationStore";
import { useShareProjectDialogContext } from "../ShareProjectDialog";
import { ProjectPermission } from "../../../../Models/permissions";
import { produce } from "immer";

type Props = {};

type ProjectPermissionEditableProps = Partial<
  Omit<ProjectPermission, "user" | "company" | "project" | "docket" | "contact">
>;

export type TProjectEditablePermission =
  | "tasks"
  | "files"
  | "budgets"
  | "orders"
  | "bookings"
  //   | "mail"
  | "quotes"
  | "qa";

export const Toggle_Permission_Fields: TProjectEditablePermission[] = [
  "tasks",
  "files",
  "budgets",
  "orders",
  "bookings",
  //   "mail",
  "quotes",
  "qa",
];
interface IEditPermissionPopoverForm {
  handleUpdateTogglePermission: (
    field: TProjectEditablePermission,
    value: boolean,
    action: "edit" | "view"
  ) => void;
  isFieldChecked: (
    field: TProjectEditablePermission,
    action: "edit" | "view"
  ) => boolean | undefined;
  handleClosePopover: () => void;
}
const useEditPermissionPopoverForm = (
  props: Props
): IEditPermissionPopoverForm => {
  const [updatedPermission, setUpdatedPermission] =
    useState<ProjectPermissionEditableProps>({});
  const company = useDoxleCurrentContextStore(
    (state) => state.currentCompany,
    shallow
  );
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );
  const {
    editedPermissionItem,
    setEditedPermissionItem,
    anchorPopoverForm,
    setAnchorPopoverForm,
  } = useShareProjectDialogContext();
  const handleClosePopover = () => {
    setEditedPermissionItem(undefined);
    setAnchorPopoverForm(null);
    if (Object.keys(updatedPermission).length > 0 && editedPermissionItem) {
      useMutatePermission.updatePermission.mutate({
        ...editedPermissionItem,
        ...updatedPermission,
      });
    }
    setUpdatedPermission({});
  };
  const useMutatePermission = PermissionsAPI.useMutatePermission({
    company,

    showNotification,
    onSuccessCb: () => {
      handleClosePopover();
    },
  });

  const handleLimited = () => {
    if (anchorPopoverForm === null || !editedPermissionItem) {
      setEditedPermissionItem(undefined);
      setAnchorPopoverForm(null);
      return;
    }
    let updatedPermission: Partial<ProjectPermission> = {};
    for (const [key, value] of Object.entries(editedPermissionItem)) {
      const keyProp = key as keyof ProjectPermission;
      if (
        (keyProp === "company" ||
          keyProp === "project" ||
          keyProp === "docket" ||
          keyProp === "user") &&
        value !== undefined
      )
        updatedPermission[keyProp] = value;
      else if (
        keyProp !== "contact" &&
        keyProp !== "company" &&
        keyProp !== "project" &&
        keyProp !== "docket" &&
        keyProp !== "user"
      )
        updatedPermission[keyProp] = false;
    }
    useMutatePermission.updatePermission.mutate(updatedPermission);
  };

  const handleUpdateTogglePermission = (
    field: TProjectEditablePermission,
    value: boolean,
    action: "edit" | "view"
  ) => {
    if (action === "edit") {
      setEditedPermissionItem(
        produce((draft) => {
          if (draft) {
            if (field === "tasks") {
              draft.addTasks = value;
              draft.deleteTasks = value;
              draft.viewTasks = value || draft.viewTasks;
            } else if (field === "bookings") {
              draft.addBookings = value;
              draft.deleteBookings = value;
              draft.viewBookings = value || draft.viewBookings;
            } else if (field === "budgets") {
              draft.addEstimate = value;
              draft.deleteEstimate = value;
              draft.viewEstimate = value || draft.viewEstimate;
            } else if (field === "files") {
              draft.addFiles = value;
              draft.deleteFiles = value;
              draft.viewFiles = value || draft.viewFiles;
            } else if (field === "orders") {
              draft.addOrders = value;
              draft.deleteOrders = value;
              draft.viewOrders = value || draft.viewOrders;
            } else if (field === "qa") {
              draft.addDefects = value;
              draft.deleteDefects = value;
              draft.viewDefects = value || draft.viewDefects;
            } else if (field === "quotes") {
              draft.addQuotes = value;
              draft.deleteQuotes = value;
              draft.viewQuotes = value || draft.viewQuotes;
            }
          }

          return draft;
        })
      );

      setUpdatedPermission(
        produce((draft) => {
          if (draft) {
            if (field === "tasks") {
              draft.addTasks = value;
              draft.deleteTasks = value;
              draft.viewTasks = value || draft.viewTasks;
            } else if (field === "bookings") {
              draft.addBookings = value;
              draft.deleteBookings = value;
              draft.viewBookings = value || draft.viewBookings;
            } else if (field === "budgets") {
              draft.addEstimate = value;
              draft.deleteEstimate = value;
              draft.viewEstimate = value || draft.viewEstimate;
            } else if (field === "files") {
              draft.addFiles = value;
              draft.deleteFiles = value;
              draft.viewFiles = value || draft.viewFiles;
            } else if (field === "orders") {
              draft.addOrders = value;
              draft.deleteOrders = value;
              draft.viewOrders = value || draft.viewOrders;
            } else if (field === "qa") {
              draft.addDefects = value;
              draft.deleteDefects = value;
              draft.viewDefects = value || draft.viewDefects;
            } else if (field === "quotes") {
              draft.addQuotes = value;
              draft.deleteQuotes = value;
              draft.viewQuotes = value || draft.viewQuotes;
            }
          }

          return draft;
        })
      );
    } else {
      setEditedPermissionItem(
        produce((draft) => {
          if (draft) {
            if (field === "tasks") {
              draft.viewTasks = value;
            } else if (field === "bookings") {
              draft.viewBookings = value;
            } else if (field === "budgets") {
              draft.viewEstimate = value;
            } else if (field === "files") {
              draft.viewFiles = value;
            } else if (field === "orders") {
              draft.viewOrders = value;
            } else if (field === "qa") {
              draft.viewDefects = value;
            } else if (field === "quotes") {
              draft.viewQuotes = value;
            }
          }

          return draft;
        })
      );

      setUpdatedPermission(
        produce((draft) => {
          if (draft) {
            if (field === "tasks") {
              draft.viewSpecifications = value;
            } else if (field === "bookings") {
              draft.viewBookings = value;
            } else if (field === "budgets") {
              draft.viewEstimate = value;
            } else if (field === "files") {
              draft.viewFiles = value;
            } else if (field === "orders") {
              draft.viewOrders = value;
            } else if (field === "qa") {
              draft.viewDefects = value;
            } else if (field === "quotes") {
              draft.viewQuotes = value;
            }
          }

          return draft;
        })
      );
    }
  };

  const isFieldChecked = (
    field: TProjectEditablePermission,
    action: "edit" | "view"
  ) => {
    if (editedPermissionItem) {
      if (action === "edit")
        return field === "qa"
          ? editedPermissionItem.addDefects
          : field === "bookings"
          ? editedPermissionItem.addBookings
          : field === "budgets"
          ? editedPermissionItem.addEstimate
          : field === "files"
          ? editedPermissionItem.addFiles
          : field === "orders"
          ? editedPermissionItem.addOrders
          : field === "quotes"
          ? editedPermissionItem.addQuotes
          : editedPermissionItem.addTasks;
      else
        return field === "qa"
          ? editedPermissionItem.viewDefects
          : field === "bookings"
          ? editedPermissionItem.viewBookings
          : field === "budgets"
          ? editedPermissionItem.viewEstimate
          : field === "files"
          ? editedPermissionItem.viewFiles
          : field === "orders"
          ? editedPermissionItem.viewOrders
          : field === "quotes"
          ? editedPermissionItem.viewQuotes
          : editedPermissionItem.viewTasks;
    }

    return false;
  };
  return { handleUpdateTogglePermission, isFieldChecked, handleClosePopover };
};

export default useEditPermissionPopoverForm;
