import React, { useCallback, useMemo } from "react";
import { usePricebookStore } from "../Store/usePricebookStore";
import { shallow } from "zustand/shallow";
import PricebookQueryAPI from "../QueryHooks/PricebookQueryAPI";
import { useDoxleCurrentContextStore } from "../../DoxleGeneralStore/useDoxleCurrentContext";
import useDoxleNotificationStore from "../../DoxleGeneralStore/useDoxleNotificationStore";
import { PricebookItem } from "../Models/Pricebook";
import { useShallow } from "zustand/react/shallow";

type Props = {};

interface IGetPricebookList {
  pricebookList: PricebookItem[];
  isFetchingPricebookList: boolean;
  isSuccessFetchingPricebookList: boolean;
  isErrorFetchingPricebookList: boolean;
  totalPage: number;
  totalItem: number;
  removeQueryPricebookList: () => void;
  currentPage: number;
  handleSetPage: (pageNum: number) => void;
  handleSetPageSize: (pageSize: number) => void;
  fetchNextRateList: () => void;
  fetchPreviousRateList: () => void;
  hasNextPage: boolean;
  hasPreviousPage: boolean;

  currentPageSize: number;
}
const useGetPricebookList = (props: Props): IGetPricebookList => {
  const company = useDoxleCurrentContextStore(
    useShallow((state) => state.currentCompany)
  );
  const showNotification = useDoxleNotificationStore(
    useShallow((state) => state.showNotification)
  );
  const { pricebookQueryFilter, setPricebookItemQueryFilter } =
    usePricebookStore(
      useShallow((state) => ({
        pricebookQueryFilter: state.pricebookItemQueryFilter,
        setPricebookItemQueryFilter: state.setPricebookItemQueryFilter,
      }))
    );

  const itemListQuery = PricebookQueryAPI.useRetrievePricebookItemList({
    company,
    showNotification,
    filter: pricebookQueryFilter,
  });

  const pricebookList: PricebookItem[] = useMemo(
    () => itemListQuery.data?.data.results ?? [],
    [itemListQuery.data]
  );
  const totalPage = useMemo(
    () => itemListQuery.data?.data.pages ?? 0,
    [itemListQuery.data]
  );
  const totalItem = useMemo(
    () => (itemListQuery.isSuccess ? itemListQuery.data.data.count : 0),
    [itemListQuery.data]
  );
  const removeQueryPricebookList = () => {
    itemListQuery.remove();
  };

  const currentPage = pricebookQueryFilter.page;
  const currentPageSize = pricebookQueryFilter.page_size;

  const hasPreviousPage = () => {
    return currentPage > 1;
  };

  const hasNextPage = () => {
    return currentPage < totalPage;
  };

  const fetchNextRateList = () => {
    if (hasNextPage())
      setPricebookItemQueryFilter({ page: pricebookQueryFilter.page + 1 });
  };
  const fetchPreviousRateList = () => {
    if (hasPreviousPage())
      setPricebookItemQueryFilter({ page: pricebookQueryFilter.page - 1 });
  };

  const handleSetPage = useCallback(
    (pageNum: number) => {
      if (pageNum > 0 && pageNum <= totalPage) {
        setPricebookItemQueryFilter({
          page: pageNum,
        });
      }
    },
    [totalPage]
  );

  const handleSetPageSize = (pageSize: number) => {
    itemListQuery.remove();
    setPricebookItemQueryFilter({
      page_size: pageSize,
      page: 1,
    });
  };
  return {
    pricebookList,
    isFetchingPricebookList: itemListQuery.isLoading,
    isSuccessFetchingPricebookList: itemListQuery.isSuccess,
    isErrorFetchingPricebookList: itemListQuery.isError,
    totalPage,
    totalItem,
    removeQueryPricebookList,
    currentPage,
    handleSetPage,
    handleSetPageSize,
    currentPageSize,
    fetchNextRateList,
    fetchPreviousRateList,
    hasNextPage: hasNextPage(),
    hasPreviousPage: hasPreviousPage(),
  };
};

export default useGetPricebookList;
