import React from "react";
import {
  StyledAddedAttachmentItem,
  StyledAttachmentName,
  StyledFileImage,
  StyledImageContainer,
} from "./AddStyledComponents";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import {
  StyledQuoteAttachments,
  StyledQuoteDetailLabelText,
} from "../QuoteRequestStyledComponents";
import PDFIcon from "../../Assets/adobePdfIcon.svg";
import MicrosoftIcon from "../../Assets/microsoftIcon.svg";
import ExcelIcon from "../../Assets/excelIcon.svg";
import { ImageOutlined } from "@mui/icons-material";
import { AiFillCloseCircle } from "react-icons/ai";
import { produce } from "immer";
import { shallow } from "zustand/shallow";
import {
  getFileIconUrl,
  isImageFile,
} from "../../../Utilities/FunctionUtilities";
import DoxleIconButton from "../../../DoxleDesignPattern/DoxleButtons/DoxleIconButton";
type Props = {
  attachment: File;
  index: number;
  setAttachments: React.Dispatch<React.SetStateAction<File[]>>;
};
export const FILETYPE = {
  pdf: PDFIcon,
  docx: MicrosoftIcon,
  xlsx: ExcelIcon,
};

const AddedAttachmentItem = ({ attachment, index, setAttachments }: Props) => {
  const { doxleThemeColor } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }),
    shallow
  );
  return (
    <StyledAddedAttachmentItem
      layout
      initial={false}
      animate={{ y: [10, 0], opacity: [0, 1] }}
      exit={{
        y: [0, 10],
        opacity: [1, 0],
      }}
      transition={{
        duration: 0.2,
      }}
    >
      <DoxleIconButton
        iconSource={
          <AiFillCloseCircle
            size={"2rem"}
            color={doxleThemeColor.primaryFontColor}
          />
        }
        buttonSize={30}
        disableAnimation
        onClick={() =>
          setAttachments((prevState) =>
            produce(prevState, (draft) => {
              draft.splice(index, 1);
              return draft;
            })
          )
        }
        buttonWrapperStyle={{
          position: "absolute",
          zIndex: 10,
          top: "-1.5rem",
          right: "-0.5rem",
        }}
      />

      <StyledImageContainer>
        <StyledFileImage
          src={
            isImageFile(attachment.name)
              ? URL.createObjectURL(attachment)
              : getFileIconUrl(attachment.name)
          }
        />
      </StyledImageContainer>

      <StyledAttachmentName>{attachment.name}</StyledAttachmentName>
    </StyledAddedAttachmentItem>
  );
};

export default React.memo(AddedAttachmentItem);
