import React, { useCallback, useMemo } from "react";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import {
  StyledProjectDropdownContainer,
  StyledProjectDropdownMenuBtn,
  StyledProjectListScroller,
  StyledProjectSelectionItem,
  StyledProjectStatusText,
  StyledSearchProjectDropdownTextField,
} from "./StyledProjectComponents";
import { useDoxleCurrentContextStore } from "../../DoxleGeneralStore/useDoxleCurrentContext";
import useProjectDropdownList from "./Hooks/useProjectDropdownList";
import { Project } from "../../Models/project";
import DoxleAddItemsBtn from "../../DoxleDesignPattern/DoxleButtons/DoxleAddItemsBtn";
import { Skeleton } from "@mui/material";
import DoxleEmptyPlaceHolder from "../../DoxleDesignPattern/DoxleEmptyPlaceHolder/DoxleEmptyPlaceHolder";
import {
  Components,
  GroupContent,
  GroupItemContent,
  GroupedVirtuoso,
  ItemContent,
  Virtuoso,
} from "react-virtuoso";
import { groupBy } from "lodash";
import ListLoadingMore from "../../Utilities/Lottie/ListLoadingMore";
import useGetProjectList from "./Hooks/useGetProjectList";
import { Error404Banner } from "../../DoxleDesignPattern/DoxleIcons/CommonIcons";
import { EmptyProjectListBanner } from "./ProjectIcon";
import { useShallow } from "zustand/react/shallow";
import ProjectStatusList from "./ProjectStatusList";
import { IoIosAdd } from "react-icons/io";
import { IoClose, IoSearch } from "react-icons/io5";
import { AnimatePresence } from "framer-motion";

type Props = {
  handleSelectProject: (item: Project) => void;
  handleCloseProjectDropdown: () => void;
};

interface ProjectListContext {
  isErrorFetchingProject: boolean;
}
const ProjectDropdownList = ({
  handleSelectProject,
  handleCloseProjectDropdown,
}: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    useShallow((state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }))
  );
  const {
    currentProject,
    currentCompany,

    currentProjectStatusId,
  } = useDoxleCurrentContextStore(
    useShallow((state) => ({
      currentProject: state.currentProject,
      currentCompany: state.currentCompany,
      currentProjectStatusId: state.currentProjectStatusId,
    }))
  );

  const {
    searchProjectInputText,
    setSearchProjectInputText,

    handleClickAddProject,

    isFetchingProject,
    isSuccessFetchingProject,
    isErrorFetchingProject,
    projectList,

    hasNextPageProject,
    handleFetchNextPageProject,
    isFetchingNextPage,
    showSearchInput,
    setshowSearchInput,
  } = useProjectDropdownList({});
  const filteredProjectList = useMemo(
    () =>
      currentProjectStatusId && !searchProjectInputText
        ? projectList.filter(
            (project) => project.projectStatus === currentProjectStatusId
          )
        : projectList,
    [projectList, currentProjectStatusId, searchProjectInputText]
  );

  //* render list
  const itemContent: ItemContent<Project, ProjectListContext> = useCallback(
    (index, item) => (
      <ProjectDropdownItem
        projectItem={item}
        handleSelectProject={handleSelectProject}
      />
    ),
    [handleSelectProject]
  );

  const components: Components<Project, ProjectListContext> = useMemo(
    () => ({
      Scroller: React.forwardRef(({ style, ...props }, ref) => (
        <StyledProjectListScroller
          style={{
            ...style,
          }}
          ref={ref}
          {...props}
        />
      )),
      EmptyPlaceholder: (props) => (
        <DoxleEmptyPlaceHolder
          headTitleText={
            props.context?.isErrorFetchingProject
              ? "Something wrong"
              : "No Project Found"
          }
          headTitleTextStyle={{
            fontSize: "20px",
          }}
          subTitleText={
            props.context?.isErrorFetchingProject
              ? "Please try to refresh page, we are sorry for this!"
              : "Add a project to start your journey!"
          }
          subTitleTextStyle={{
            fontSize: "14px",
          }}
          illustration={
            props.context?.isErrorFetchingProject ? (
              <Error404Banner
                themeColor={doxleThemeColor}
                containerStyle={{ marginBottom: 8, width: "50%" }}
              />
            ) : (
              <EmptyProjectListBanner
                themeColor={doxleThemeColor}
                containerStyle={{ marginBottom: 8, width: "50%" }}
              />
            )
          }
        />
      ),
    }),
    []
  );
  return (
    <>
      <div className="textfield-section-wrapper">
        <AnimatePresence>
          {!showSearchInput && <ProjectStatusList />}

          {showSearchInput && (
            <StyledSearchProjectDropdownTextField
              value={searchProjectInputText}
              onChange={(event) =>
                setSearchProjectInputText(event.target.value)
              }
              autoFocus
              placeholder="Search Project ..."
              animate={{
                y: [50, 0],
                opacity: [0, 1],
              }}
              exit={{
                y: [0, 50],
                opacity: [1, 0],
              }}
            />
          )}
        </AnimatePresence>

        {/* <DoxleAddItemsBtn
          buttonText="Add Project"
          onClick={() => {
            handleClickAddProject();
            handleCloseProjectDropdown();
          }}
        /> */}

        <StyledProjectDropdownMenuBtn
          onClick={() => {
            setshowSearchInput((prev) => !prev);
            setSearchProjectInputText("");
          }}
        >
          {!showSearchInput ? (
            <IoSearch size={18} className="icon" />
          ) : (
            <IoClose size={18} className="icon" />
          )}
        </StyledProjectDropdownMenuBtn>
        <StyledProjectDropdownMenuBtn
          onClick={() => {
            handleClickAddProject();
            handleCloseProjectDropdown();
          }}
        >
          <IoIosAdd size={23} className="icon" />
        </StyledProjectDropdownMenuBtn>
      </div>
      <div className="divider" />
      {isFetchingProject &&
        Array(10)
          .fill("projectSkel")
          .map((skel, idx) => (
            <Skeleton
              variant="rounded"
              width="100%"
              height="1.6rem"
              style={{ marginBottom: 8 }}
              key={skel + idx}
            />
          ))}

      {isErrorFetchingProject && (
        <DoxleEmptyPlaceHolder
          headTitleText="Something Wrong!"
          headTitleTextStyle={{
            fontSize: "2rem",
          }}
          subTitleText="Failed to get project list..."
          containerStyle={{
            width: "100%",
            height: "14rem",
          }}
        />
      )}

      {isSuccessFetchingProject && (
        <Virtuoso
          data={filteredProjectList}
          overscan={200}
          style={{ flex: 1 }}
          context={{ isErrorFetchingProject }}
          components={components}
          endReached={() => {
            handleFetchNextPageProject();
          }}
          itemContent={itemContent}
        />
      )}

      {isFetchingNextPage && (
        <ListLoadingMore
          containerStyle={{
            bottom: 14,
            left: 0,
            right: 0,
            position: "absolute",
            zIndex: 10,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          animationSize={"6rem"}
        />
      )}
    </>
  );
};

export default ProjectDropdownList;

const ProjectDropdownItem = React.memo(
  (props: {
    projectItem: Project;
    handleSelectProject: (item: Project) => void;
  }) => {
    const { projectItem, handleSelectProject } = props;

    const { currentProject } = useDoxleCurrentContextStore(
      useShallow((state) => ({
        currentProject: state.currentProject,
      }))
    );
    return (
      <StyledProjectSelectionItem
        initial={{
          opacity: 0,
          y: -5,
        }}
        animate={{
          opacity: 1,
          y: 0,
          transition: {
            duration: 0.2,
            times: 1,
          },
        }}
        $selected={Boolean(currentProject?.projectId === projectItem.projectId)}
        onClick={() => handleSelectProject(projectItem)}
      >
        {projectItem.siteAddress}
      </StyledProjectSelectionItem>
    );
  }
);
