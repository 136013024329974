import React from "react";
import { useOrdersStore } from "../OrdersStore/useOrdersStore";
import { shallow } from "zustand/shallow";
import { OrderStatus } from "../Models/order";
import { useDoxleAuthStore } from "../../DoxleGeneralStore/useDoxleAuthStore";
import { useDoxleCurrentContextStore } from "../../DoxleGeneralStore/useDoxleCurrentContext";
import useDoxleNotificationStore from "../../DoxleGeneralStore/useDoxleNotificationStore";
import OrdersQueryAPI from "../QueryHooks/OrdersQueryAPI";

type Props = { onGetStatusSuccessCb?: (list: OrderStatus[]) => void };

interface IGetOrderStatus {
  orderStatuses: OrderStatus[];
  isFetchingOrderStatus: boolean;
  isSuccessFetchingOrderStatus: boolean;
  isErrorFetchingOrderStatus: boolean;
}
const useGetOrderStatus = ({
  onGetStatusSuccessCb,
}: Props): IGetOrderStatus => {
  const { view } = useOrdersStore(
    (state) => ({
      view: state.view,
    }),
    shallow
  );
  
  const company = useDoxleCurrentContextStore(
    (state) => state.currentCompany,
    shallow
  );
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );

  const orderStatusQuery = OrdersQueryAPI.useRetrieveOrderStatusList({

    company,
    showNotification,
    view: "Orders",
    onSuccessCb: onGetStatusSuccessCb,
  });
  const orderStatuses: OrderStatus[] = orderStatusQuery.isSuccess
    ? (orderStatusQuery.data.data as OrderStatus[])
    : [];

  return {
    orderStatuses,
    isFetchingOrderStatus: orderStatusQuery.isLoading,
    isSuccessFetchingOrderStatus: orderStatusQuery.isSuccess,
    isErrorFetchingOrderStatus: orderStatusQuery.isError,
  };
};

export default useGetOrderStatus;
