import { Company } from "./company";
import { TISODate } from "./dateFormat";
import { DoxleFile } from "./files";
import { User } from "./user";

export interface Discussion {
  commentId: string;
  commentText: string;
  pinned: boolean;
  taggedUsers: string[]; //userIds
  hasAttachments: boolean;
  author: string; //userId
  authorJson?: User; //USER INFO IN JSON
  docket: string;
  project: string | null;
  rfi: string | null;
  company: string;
  attachments?: DoxleFile[];
  timeStamp?: TISODate;
}

interface GetNewDiscussionTemplateParams {
  commentText: string;
  taggedUsers?: string[];
  docket: string;
  project?: string;
  rfi?: string;
  company: Company | undefined;
  author?: string;
}

export const getNewTemplateDiscussion = ({
  commentText,
  taggedUsers,
  docket,
  project,
  company,
  rfi,
  author,
}: GetNewDiscussionTemplateParams) => {
  let newDiscussion: Discussion = {
    commentId: "",
    commentText: commentText,
    pinned: false,
    hasAttachments: false,
    taggedUsers: [],
    author: "",

    company: company?.companyId || "",
    docket: docket,
    project: null,
    rfi: null,
  };

  if (taggedUsers) newDiscussion.taggedUsers = taggedUsers;

  if (project) newDiscussion.project = project;
  if (rfi) newDiscussion.rfi = rfi;
  if (author) newDiscussion.author = author;
  return newDiscussion;
};
