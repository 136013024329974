import React from "react";
import {
  StyledEditBookingContentWrapper,
  StyledEditBookingTitleTextField,
} from "./StyledEditBookingDialog";
import { useEditBookingContext } from "./EditBookingDialog";
import { produce } from "immer";
import { AnimatePresence, motion } from "framer-motion";

type Props = {};

const EditBookingTitleSection = (props: Props) => {
  const { shouldShowError, editBookingData, setEditBookingData } =
    useEditBookingContext();
  return (
    <StyledEditBookingContentWrapper>
      <span className="label-text">Title *</span>
      <StyledEditBookingTitleTextField
        multiline={true}
        placeholder="Booking title..."
        value={editBookingData?.title}
        onChange={(e) =>
          setEditBookingData(
            produce((draft) => {
              if (draft) draft.title = e.target.value;
              return draft;
            })
          )
        }
        onFocus={(e) => e.target.select()}
        autoFocus
      />
      <AnimatePresence>
        {shouldShowError && editBookingData && !editBookingData.title && (
          <motion.span
            className="error-text"
            animate={{
              x: [-10, 0],
              opacity: [0, 1],
            }}
            exit={{
              x: 10,
              opacity: 0,
            }}
          >
            Title is required
          </motion.span>
        )}
      </AnimatePresence>
    </StyledEditBookingContentWrapper>
  );
};

export default EditBookingTitleSection;
