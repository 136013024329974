import {
  StyledNBFilterListContainer,
  StyledNBFilterMenuBtn,
  StyledNBMenuButton,
  StyledNoticeBoardMenuContainer,
  StyledStatusFilterMenuBtn,
} from "./StyledNoticeBoardContent";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import { NBMenu, useInboxStore } from "../../InboxStore/useInboxStore";
import { motion } from "framer-motion";
import useNoticeBoardMenu from "./Hooks/useNoticeBoardMenu";
import DoxleAddNewButton from "../../../DoxleDesignPattern/DoxleButtons/DoxleAddNewButton";
import Popover from "@mui/material/Popover";
import {
  DueThisMonth,
  DueThisWeekIcon,
  DueTodayIcon,
  NBFilterIcon,
  NBGroupUserIcon,
} from "../InboxIcon";
import DoxleFilterTag from "../../../DoxleDesignPattern/DoxleFilterTag/DoxleFilterTag";
import { DoxleNBProjectIcon } from "../../../DoxleDesignPattern/DoxleGeneralIcons";
import useGetNBStatus from "../NBStatusPopover/Hooks/useGetNBStatus";
import DoxleStatus from "../../../DoxleDesignPattern/DoxleStatus/DoxleStatus";
import { TRgbaFormat } from "../../../Utilities/FunctionUtilities";

type Props = {};

const NB_MENU: NBMenu[] = ["My Notices", "All Notices", "Archived"];

const NoticeBoardMenu = (props: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }),
    shallow
  );
  const {
    inboxViewMode,
    removeInboxFilter,
    inboxFilterOptions,
    inboxGroupMode,
    statusFilterValue,
    setStatusFilterValue,
  } = useInboxStore((state) => ({
    inboxViewMode: state.inboxViewMode,
    removeInboxFilter: state.removeInboxFilter,
    inboxFilterOptions: state.inboxFilterOptions,
    inboxGroupMode: state.inboxGroupMode,
    statusFilterValue: state.statusFilterValue,
    setStatusFilterValue: state.setStatusFilterValue,
  }));

  const {
    handleClickViewMode,
    handleAddNewNoticeBoardDocket,
    isAddingNotice,
    handleClickHashIcon,
    handleCloseFilterMenu,
    anchorFilterPopperList,
    handleClickFilter,
    handleRemoveFilter,
    handleSetGroupMode,
    handleRemoveStatusFilter,
  } = useNoticeBoardMenu({});
  const canBeOpen = Boolean(anchorFilterPopperList);
  const id = canBeOpen ? "notice-filter-menu-popper" : undefined;
  const { nbStatusList } = useGetNBStatus({});

  return (
    <StyledNoticeBoardMenuContainer $themeColor={doxleThemeColor}>
      <div className="menu-btn-wrapper">
        {NB_MENU.map((menu, idx) => (
          <StyledNBMenuButton
            key={`${menu}#${idx}`}
            $themeColor={doxleThemeColor}
            $doxleFont={doxleFont}
            onClick={() => handleClickViewMode(menu)}
            $selected={menu === inboxViewMode}
          >
            {menu}

            {inboxViewMode === menu && (
              <motion.div className="menu-selected" layoutId="nbSelectedMenu" />
            )}
          </StyledNBMenuButton>
        ))}
      </div>
      <div className="filter-tag-wrapper">
        {inboxFilterOptions.map((filter, idx) => (
          <DoxleFilterTag
            itemData={filter}
            itemDisplayText={filter}
            key={`${filter}#${idx}`}
            removeItemFunction={handleRemoveFilter}
          />
        ))}
        {statusFilterValue.map((filter, idx) => (
          <DoxleFilterTag
            itemData={filter}
            itemDisplayText={filter}
            key={`${filter}#{idx}`}
            removeItemFunction={handleRemoveStatusFilter}
          />
        ))}
      </div>
      <div className="filter-new-wrapper">
        <NBFilterIcon
          themeColor={doxleThemeColor}
          containerStyle={{ cursor: "pointer", marginRight: 4, width: 20 }}
          onClick={handleClickHashIcon}
        />

        <DoxleAddNewButton
          isLoading={isAddingNotice}
          onClick={handleAddNewNoticeBoardDocket}
          iconOnly={true}
          tooltipText="Add new notice"
        />
      </div>

      <Popover
        id={id}
        open={canBeOpen}
        anchorEl={anchorFilterPopperList}
        disableScrollLock
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        onClose={handleCloseFilterMenu}
        sx={{
          "& .MuiPopover-paper": {
            backgroundColor: "transparent",
            overflow: "visible",
            boxShadow: "none",
          },
        }}
        elevation={8}
      >
        <StyledNBFilterListContainer
          $themeColor={doxleThemeColor}
          $doxleFont={doxleFont}
        >
          <StyledNBFilterMenuBtn
            $themeColor={doxleThemeColor}
            $doxleFont={doxleFont}
            onClick={() => handleClickFilter("Due Today")}
            $selected={Boolean(inboxFilterOptions.includes("Due Today"))}
          >
            <DueTodayIcon {...doxleThemeColor} />
            <span>Due Today</span>
          </StyledNBFilterMenuBtn>

          <StyledNBFilterMenuBtn
            $themeColor={doxleThemeColor}
            $doxleFont={doxleFont}
            onClick={() => handleClickFilter("Due This Week")}
            $selected={Boolean(inboxFilterOptions.includes("Due This Week"))}
          >
            <DueThisWeekIcon {...doxleThemeColor} />
            <span> Due This Week </span>
          </StyledNBFilterMenuBtn>

          <StyledNBFilterMenuBtn
            $themeColor={doxleThemeColor}
            $doxleFont={doxleFont}
            onClick={() => handleClickFilter("Due This Month")}
            $selected={Boolean(inboxFilterOptions.includes("Due This Month"))}
          >
            <DueThisMonth {...doxleThemeColor} />
            <span> Due This Month </span>
          </StyledNBFilterMenuBtn>

          {inboxViewMode !== "My Notices" && (
            <>
              <div className="divider" />
              <span className="group-title">Group Mode</span>

              <StyledNBFilterMenuBtn
                $themeColor={doxleThemeColor}
                $doxleFont={doxleFont}
                onClick={() => handleSetGroupMode("Projects")}
                $selected={Boolean(inboxGroupMode === "Projects")}
              >
                <DoxleNBProjectIcon
                  themeColor={doxleThemeColor}
                  containerStyle={{ width: 16, marginRight: 5, marginLeft: 5 }}
                />
                <span
                  style={
                    inboxGroupMode === "Projects"
                      ? { color: doxleThemeColor.doxleColor, fontWeight: 500 }
                      : undefined
                  }
                >
                  Group by project
                </span>
              </StyledNBFilterMenuBtn>

              <StyledNBFilterMenuBtn
                $themeColor={doxleThemeColor}
                $doxleFont={doxleFont}
                onClick={() => handleSetGroupMode("Assignee")}
                $selected={Boolean(inboxGroupMode === "Assignee")}
              >
                <NBGroupUserIcon
                  themeColor={doxleThemeColor}
                  containerStyle={{ width: 16, marginRight: 5, marginLeft: 5 }}
                />
                <span>Group by assignee</span>
              </StyledNBFilterMenuBtn>
            </>
          )}

          <div className="divider" />
          <span className="group-title">Filter By Status</span>

          {nbStatusList.map((status) => {
            return (
              <StyledStatusFilterMenuBtn
                $themeColor={doxleThemeColor}
                $doxleFont={doxleFont}
                $statusColor={status.color}
                $selected={statusFilterValue.includes(status.statusName)}
                onClick={() => {
                  setStatusFilterValue(status.statusName);
                  handleCloseFilterMenu();
                }}
                key={status.statusId}
              >
                <DoxleStatus
                  statusColor={status.color as TRgbaFormat}
                  style={{ marginRight: 4 }}
                  size={14}
                />
                <span>{status.statusName}</span>
              </StyledStatusFilterMenuBtn>
            );
          })}
        </StyledNBFilterListContainer>
      </Popover>
    </StyledNoticeBoardMenuContainer>
  );
};

export default NoticeBoardMenu;
