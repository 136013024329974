import React from "react";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import {
  StyledErrorActionButton,
  StyledErrorDroppedFileWarningContainer,
  StyledErrorFilesContainer,
  StyledErrorWarningActions,
  StyledErrorWarningContent,
  StyledErrorWarningTitle,
} from "./StyledComponentScopeOfWorkFullScreen";
import { editRgbaAlpha } from "../../Utilities/FunctionUtilities";
import { useScopeOfWorkStore } from "../Store/useScopeOfWorkStore";
import { checkSupportedFileType } from "../../Utilities/MimeFileType";
import {
  DoxleCSVIcon,
  DoxleExcelIcon,
  DoxlePDFIcon,
  DoxleWordIcon,
} from "../../DoxleDesignPattern/DoxleGeneralIcons";
import { DoxleTextIcon } from "../../CoreContent/DoxleTopMenu/DoxleTopMenuIcon";
import { useShallow } from "zustand/react/shallow";

type Props = {};

const ErrorDroppedFileWarning = (props: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore((state) => ({
    doxleThemeColor: state.doxleThemeColor,
    doxleFont: state.doxleFont,
  }));
  const { clearErrorDroppedFiles, errorDroppedFiles } = useScopeOfWorkStore(
    useShallow((state) => ({
      clearErrorDroppedFiles: state.clearErrorDroppedFiles,
      errorDroppedFiles: state.errorDroppedFiles,
    }))
  );
  return (
    <StyledErrorDroppedFileWarningContainer
      $themeColor={doxleThemeColor}
      open={Boolean(errorDroppedFiles.length > 0)}
      onClose={clearErrorDroppedFiles}
      PaperProps={{
        sx: {
          bgColor: doxleThemeColor.primaryContainerColor,
          borderRadius: "0.9rem",
          border: `1px solid ${doxleThemeColor.primaryDividerColor}`,
          minWidth: "500px !important",
          maxWidth: "800px !important",
          maxHeight: "500px !important",
        },
        elevation: 2,
      }}
      slotProps={{
        backdrop: {
          sx: {
            bgcolor: editRgbaAlpha({
              rgbaColor: doxleThemeColor.primaryBackgroundColor,
              alpha: "0.8",
            }),
          },
        },
      }}
    >
      <StyledErrorWarningTitle
        $themeColor={doxleThemeColor}
        $doxleFont={doxleFont}
      >
        {errorDroppedFiles.length} Error File
        {errorDroppedFiles.length > 1 ? "s" : ""}
      </StyledErrorWarningTitle>

      <StyledErrorWarningContent $themeColor={doxleThemeColor}>
        {errorDroppedFiles.map((file, idx) => {
          const supportedFileType = checkSupportedFileType({
            type: file.file.type,
          });

          return (
            <StyledErrorFilesContainer
              key={`key#${idx}`}
              $themeColor={doxleThemeColor}
              $doxleFont={doxleFont}
            >
              {supportedFileType === "pdf" ? (
                <DoxlePDFIcon
                  themeColor={doxleThemeColor}
                  containerStyle={{
                    width: 35,
                  }}
                />
              ) : supportedFileType === "doc" ? (
                <DoxleWordIcon
                  themeColor={doxleThemeColor}
                  containerStyle={{
                    width: 35,
                  }}
                />
              ) : supportedFileType === "xls" ? (
                <DoxleExcelIcon
                  themeColor={doxleThemeColor}
                  containerStyle={{
                    width: 35,
                  }}
                />
              ) : supportedFileType === "csv" ? (
                <DoxleCSVIcon
                  themeColor={doxleThemeColor}
                  containerStyle={{
                    width: 35,
                  }}
                />
              ) : supportedFileType === "text" ? (
                <DoxleTextIcon
                  themeColor={doxleThemeColor}
                  containerStyle={{
                    width: 35,
                  }}
                />
              ) : (
                supportedFileType === "image" && (
                  <img
                    className="image-holder"
                    src={file.file.webkitRelativePath}
                  />
                )
              )}
              <div className="error-wrapper">
                <span className="file-name">{file.file.name}</span>
                {file.errors.map((error, idx) => (
                  <span key={`error#${idx}`} className="text">
                    * {error.message}
                  </span>
                ))}
              </div>
            </StyledErrorFilesContainer>
          );
        })}
      </StyledErrorWarningContent>

      <StyledErrorWarningActions $themeColor={doxleThemeColor}>
        <StyledErrorActionButton
          $themeColor={doxleThemeColor}
          $doxleFont={doxleFont}
          onClick={clearErrorDroppedFiles}
        >
          <span className="button-text">Ok</span>
        </StyledErrorActionButton>
      </StyledErrorWarningActions>
    </StyledErrorDroppedFileWarningContainer>
  );
};

export default ErrorDroppedFileWarning;
