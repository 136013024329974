import { useFileStore } from "../Store/useFileStore";
import { useShallow } from "zustand/react/shallow";

const useListViewLeftHeader = () => {
  const { currentFolder, setCurrentFolder } = useFileStore(
    useShallow((state) => ({
      currentFolder: state.currentFolder,
      setCurrentFolder: state.setCurrentFolder,
    }))
  );

  const fileHeaderClicked = () => {
    sessionStorage.removeItem("currentFolderId");
    setCurrentFolder(undefined);
  };
  return {
    currentFolder,

    fileHeaderClicked,
  };
};

export default useListViewLeftHeader;
