import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import ProjectSelectDropDown from "./ProjectSelectDropDown";
import {
  StyledProjectRootContainer,
  StyledProjectTabDisplayer,
} from "./StyledProjectComponents";
import ProjectMenu from "./ProjectMenu";
import {
  defaultProjectTabs,
  useProjectStore,
} from "../../DoxleGeneralStore/useprojectStore";
import { useDoxleAuthStore } from "../../DoxleGeneralStore/useDoxleAuthStore";
import { useParams } from "react-router-dom";
import { useEffect, useMemo } from "react";
import { useDoxleCurrentContextStore } from "../../DoxleGeneralStore/useDoxleCurrentContext";
import ProjectQueryAPI from "../../Services/QueryHooks/projectQueryAPI";

//!!! EXTERNAL REPOS !!!//
import DrawingsTabDisplayer from "../../Drawings/Content/DrawingsTabDisplayer";
import BudgetsProjectTab from "../../Budgets/Table/BudgetsProjectTab";

import BookingProject from "../../Bookings/BookingProject/Components/BookingProject";
import { Helmet } from "react-helmet";
import { useShallow } from "zustand/react/shallow";

const SharedProject = () => {
  const { projectLinkId, tab } = useParams();
  const { selectedProjectTab, setSelectedProjectTab, setProjectPermission } =
    useProjectStore(
      useShallow((state) => ({
        selectedProjectTab: state.selectedProjectTab,
        setSelectedProjectTab: state.setSelectedProjectTab,
        setProjectPermission: state.setProjectPermissions,
      }))
    );
  const { setCurrentCompany, setCurrentProject } = useDoxleCurrentContextStore(
    useShallow((state) => ({
      setCurrentCompany: state.setCurrentCompany,
      setCurrentProject: state.setCurrentProject,
    }))
  );
  const { setUser } = useDoxleAuthStore(
    useShallow((state) => ({
      setUser: state.setUser,
    }))
  );
  const projectQuery =
    ProjectQueryAPI.useRetrieveSharedProjectQuery(projectLinkId);

  const { project, permissions, company, token, user } = useMemo(() => {
    const noResult = {
      project: undefined,
      permissions: undefined,
      company: undefined,
      token: undefined,
      user: undefined,
    };
    if (projectQuery.isSuccess) return projectQuery.data?.data ?? noResult;
    return noResult;
  }, [projectQuery.isSuccess, projectQuery.data]);

  // Set tab on initial load if URL contains tab
  useEffect(() => {
    if (tab && defaultProjectTabs.includes(tab) && selectedProjectTab !== tab)
      setSelectedProjectTab(tab);
  }, []);
  // Set tab on initial load if URL contains tab
  useEffect(() => {
    if (projectQuery.isSuccess) {
      setCurrentCompany(company);
      setCurrentProject(project);
      setProjectPermission(permissions);
      setUser(user);
      if (
        (selectedProjectTab === "Drawings" && !permissions?.viewDrawings) ||
        (selectedProjectTab === "Budgets" &&
          !(permissions?.viewEstimate || permissions?.viewOrders)) ||
        (selectedProjectTab === "Bookings" && !permissions?.viewBookings) ||
        (selectedProjectTab === "Files" && !permissions?.viewFiles)
      )
        setSelectedProjectTab("Actions");
    }
  }, [
    projectQuery.isSuccess,
    project,
    permissions,
    company,
    token,
    user,
    selectedProjectTab,
  ]);
  return (
    <div
      style={{
        width: "100%",
        height: "calc(100% - 55px)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: 55,
      }}
    >
      <StyledProjectRootContainer>
        <Helmet>
          <title>Doxle - Projects</title>
        </Helmet>
        <ProjectSelectDropDown />
        {/*<ProjectSearchBar />*/}
        <ProjectMenu />
        <StyledProjectTabDisplayer>
          {/*!!! EXTERNAL REPOS !!!*/}
          {selectedProjectTab === "Drawings" && <DrawingsTabDisplayer />}

          {selectedProjectTab === "Budgets" && <BudgetsProjectTab />}
          {selectedProjectTab === "Bookings" && <BookingProject />}
        </StyledProjectTabDisplayer>
      </StyledProjectRootContainer>
    </div>
  );
};

export default SharedProject;
