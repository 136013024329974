import React, { memo } from "react";
import { IBooking } from "../../Models/booking";
import { StyledBookingDateCell } from "./StyledBookingCalendar";
import BookingDateHeader from "./BookingDateHeader";
import BookingSectionFooter from "./BookingSectionFooter";
import BookingItem from "./BookingItem";

type Props = {
  dateValue: Date;
  bookingItems: IBooking[];
};

const BookingDateCell: React.FC<Props> = memo(
  ({ dateValue, bookingItems }: Props) => {
    return (
      <StyledBookingDateCell>
        <BookingDateHeader date={dateValue} />
        <div className="item-wrapper">
          {bookingItems.map((bookingItem) => (
            <BookingItem
              bookingItem={bookingItem}
              dateCellValue={dateValue}
              key={bookingItem.bookingId}
            />
          ))}
        </div>
        <BookingSectionFooter dateValue={dateValue} />
      </StyledBookingDateCell>
    );
  }
);

export default BookingDateCell;
