

import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import { StyledAssigneeDisplay } from "./BudgetStyleComponent";

type Props = {
  assigneeText: string;
};

const AssigneeDisplay = ({ assigneeText }: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }),
    shallow
  );

  const splitName = assigneeText.split(" ");
  return (
    <StyledAssigneeDisplay $themeColor={doxleThemeColor} $doxleFont={doxleFont}>
      {splitName[0][0] ? splitName[0][0] : ""}
      {splitName[1][0] ? splitName[1][0] : ""}
    </StyledAssigneeDisplay>
  );
};

export default AssigneeDisplay;
