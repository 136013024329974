//! TO USE THIS EDIT ORDER SIDE SCREEN, import useEditOrderStore and extract only setEditedOrder prop from the store, and assign it with the order
//! orderQueryFilter from this store is compulsory to overwrite query data result correctly, set it together at them same time with the setEditOrder, this orderQueryFilter should match with the filter of the query key when calling query to fetch order list

import { useCallback, useMemo, useState } from "react";
import { useEditOrderStore } from "../../OrdersStore/useEditOrderStore";
import { shallow } from "zustand/shallow";

import EditOrderHalfScreenMode from "./EditOrderHalfScreenMode";
import EditOrderFullScreenMode from "./EditOrderFullScreenMode";
import DoxleSideScreen from "../../../DoxleDesignPattern/DoxlleSideScreen/DoxleSideScreen";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import EditOrder from "../EditOrder/EditOrder";

type Props = {};

const EditDocketSideScreen = (props: Props) => {
  const { editedOrder, resetStore } = useEditOrderStore(
    (state) => ({
      editedOrder: state.editedOrder,
      setEditedOrder: state.setEditedOrder,
      resetStore: state.resetStore,
    }),
    shallow
  );
  const themeColor = useDoxleThemeStore(
    (state) => state.doxleThemeColor,
    shallow
  );
  const isModalOpen = useMemo(() => Boolean(editedOrder), [editedOrder]);
  const handleCloseModal = useCallback(() => {
    resetStore();
  }, []);


  return (
    <DoxleSideScreen
      openSideScreen={isModalOpen}
      handleCloseSideScreen={handleCloseModal}
      containerMinWidthInPixel="400px"
      containerWidthInRatio="50%"
      contentWrapperStyled={{
        padding: "1.4rem",
        width: "calc(100% - 2.8rem)",
        height: "calc(100% - 2.8rem)",
        backgroundColor: themeColor.primaryBackgroundColor,
        boxShadow: `0px -2px 14px 4px ${themeColor.primaryBoxShadowColor}`,
      }}
      children={
        // !isModalFullScreen ? (
        //   <EditOrderHalfScreenMode />
        // ) : (
        //   <EditOrderFullScreenMode />
        // )
        editedOrder ? <EditOrder /> : null
      }
    />
  );
};

export default EditDocketSideScreen;
