import React from "react";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import Skeleton from "@mui/material/Skeleton";

type Props = {};

const ScopeImageListskeleton = (props: Props) => {
  const { doxleThemeColor } = useDoxleThemeStore((state) => ({
    doxleThemeColor: state.doxleThemeColor,
    doxleFont: state.doxleFont,
  }));
  return (
    <ul
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexWrap: "wrap",
        padding: "0px",
      }}
    >
      {Array(8)
        .fill("imgList")
        .map((_, idx) => (
          <li
            key={`skel#${idx}`}
            style={{
              padding: "8px",
              width: "calc(20% - 16px)",
              aspectRatio: 1,
              display: "flex",
            }}
          >
            <Skeleton
              animation="pulse"
              variant="rounded"
              sx={{
                width: "100%",
                height: "100%",

                borderRadius: "4px",
                backgroundColor: doxleThemeColor.skeletonColor,
              }}
            />
          </li>
        ))}
    </ul>
  );
};

export default ScopeImageListskeleton;
