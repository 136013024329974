import React, { useState } from "react";

import dayjs, { Dayjs } from "dayjs";
import { DatePicker, DatePickerProps } from "@mui/x-date-pickers/DatePicker";
import {
  BaseSingleInputFieldProps,
  DateValidationError,
  FieldSection,
} from "@mui/x-date-pickers/models";

import { shallow } from "zustand/shallow";
import {
  DoxleFont,
  DoxleThemeColor,
  useDoxleThemeStore,
} from "../../DoxleGeneralStore/useDoxleThemeStore";
import { customDatePickerStyle } from "../../Models/modelHelper";
import { motion } from "framer-motion";
import styled from "styled-components";
import { editRgbaAlpha } from "../../Utilities/FunctionUtilities";

interface ButtonFieldProps
  extends BaseSingleInputFieldProps<
    Dayjs | null,
    Dayjs,
    FieldSection,
    DateValidationError
  > {
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  containerStyle?: React.CSSProperties;
}

function ButtonField(props: ButtonFieldProps) {
  const {
    setOpen,
    label,
    InputProps: { ref } = {},
    inputProps: { "aria-label": ariaLabel } = {},
    containerStyle,
    value,
  } = props;
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore((state) => ({
    doxleThemeColor: state.doxleThemeColor,
    doxleFont: state.doxleFont,
  }));

  return (
    <StyledCustomDatePicker
      $themeColor={doxleThemeColor}
      $doxleFont={doxleFont}
      onClick={() => setOpen?.((prev) => !prev)}
      ref={ref}
      aria-label={ariaLabel}
      layout="position"
      transition={{
        duration: 0.2,
        damping: 12,
      }}
      $isNull={Boolean(!value)}
      style={containerStyle}
    >
      {label || "Select Date"}
      {/* <CgCalendarDates
        style={{ marginLeft: 4 }}
        size={"2.2rem"}
        color={doxleThemeColor.primaryFontColor}
      /> */}
    </StyledCustomDatePicker>
  );
}

function ButtonDatePicker(
  props: Omit<DatePickerProps<Dayjs>, "open" | "onOpen" | "onClose"> & {
    containerStyle?: React.CSSProperties;
  }
) {
  const [open, setOpen] = React.useState(false);
  const { doxleThemeColor } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
    }),
    shallow
  );
  const { containerStyle } = props;

  const { field, ...rest } = customDatePickerStyle<any>(doxleThemeColor);

  return (
    <DatePicker
      slots={{ field: ButtonField, ...props.slots }}
      slotProps={{
        field: { setOpen, containerStyle } as any,

        ...rest,
      }}
      {...props}
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
    />
  );
}

export default function CustomDatePicker({
  value,
  onChange,
  containerStyle,
}: {
  value: string | null;
  onChange?: (value: dayjs.Dayjs | null) => void;
  containerStyle?: React.CSSProperties;
}) {
  return (
    <ButtonDatePicker
      label={value === null ? null : `${dayjs(value).format("DD MMMM YYYY")}`}
      value={value ? dayjs(value) : null}
      onChange={(newValue) => {
        onChange && onChange(newValue);
      }}
      containerStyle={containerStyle}
    />
  );
}

const StyledCustomDatePicker = styled(motion.div)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
  $isNull: boolean;
}>`
  border-radius: 8px;
  background-color: ${(p) => p.$themeColor.primaryTextFieldBackgroundColor};
  cursor: pointer;
  min-width: 144px;
  justify-content: space-between;
  font-size: 1.4rem;
  font-family: ${(p) => p.$doxleFont.primaryFont};
  color: ${(p) =>
    editRgbaAlpha({
      rgbaColor: p.$themeColor.primaryFontColor,
      alpha: p.$isNull ? "0.4" : "1",
    })} !important;
  font-weight: 400;
  width: calc(100% - 16px);
  padding: 1rem 8px;
  display: flex;
  align-items: center;
`;
