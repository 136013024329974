import React, { useState } from "react";
import { useDoxleCurrentContextStore } from "../../DoxleGeneralStore/useDoxleCurrentContext";
import { shallow } from "zustand/shallow";
import useDoxleNotificationStore from "../../DoxleGeneralStore/useDoxleNotificationStore";
import { useDoxleAuthStore } from "../../DoxleGeneralStore/useDoxleAuthStore";
import { useDrawingStore } from "../Store/useDrawingStore";
import DrawingQueryAPI from "../QueryHooks/drawingQueryAPI";
import { DrawingSet, Sheet } from "../Models/drawings";

type Props = {
  sheet: Sheet;
  drawing: DrawingSet;
};

interface SheetRowHook {
  newSheetTitle: string;
  setNewSheetTitle: React.Dispatch<React.SetStateAction<string>>;
  handleUpdateSheetTitle: () => void;
  isUpdatingSheet: boolean;
  anchorSheetPoperMenu: HTMLButtonElement | null;
  handleClickMoreIcon: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
  handleClosePoperMenu: () => void;
  handleDeleteSheet: () => void;
  handleToggleCheckBox: (value: boolean) => void;
  isDrawingChecked: boolean;
}
const useSheetRow = ({ sheet, drawing }: Props): SheetRowHook => {
  const [newSheetTitle, setNewSheetTitle] = useState(sheet.title);
  const [anchorSheetPoperMenu, setAnchorSheetPoperMenu] =
    useState<HTMLButtonElement | null>(null);
  const { company, selectedProject } = useDoxleCurrentContextStore(
    (state) => ({
      company: state.currentCompany,
      selectedProject: state.currentProject,
    }),
    shallow
  );
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );
  
  const {
    setViewedDrawingSet,
    setSelectedSheetForDelete,
    selectedSheetIds,
    handleAddSheetId,
    handleRemoveSheetId,
  } = useDrawingStore(
    (state) => ({
      setViewedDrawingSet: state.setViewedDrawingSet,
      setSelectedSheetForDelete: state.setSelectedSheetForDelete,
      selectedSheetIds: state.selectedSheetIds,
      handleAddSheetId: state.handleAddSheetId,
      handleRemoveSheetId: state.handleRemoveSheetId,
    }),
    shallow
  );

  const onSuccessUpdateSheet = (newSheet?: Sheet) => {
    if (newSheet) {
      const newDrawingSheetsList = [
        ...drawing.sheets.map((oldSheet) =>
          oldSheet.sheetId === newSheet.sheetId ? newSheet : oldSheet
        ),
      ];
      setViewedDrawingSet({ ...drawing, sheets: newDrawingSheetsList });
    }
  };
  const updateSheetQuery = DrawingQueryAPI.useUpdateSheetQuery({
    showNotification,
  
    company,
    projectId: selectedProject?.projectId || "",
    onSuccessUpdateCb: onSuccessUpdateSheet,
  });

  const handleUpdateSheetTitle = () => {
    if (newSheetTitle !== sheet.title) {
      updateSheetQuery.mutate({
        sheetId: sheet.sheetId!,
        title: newSheetTitle,
      });
    }
  };
  const handleClickMoreIcon = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.stopPropagation();

    setAnchorSheetPoperMenu(event.currentTarget);
  };

  const handleClosePoperMenu = () => setAnchorSheetPoperMenu(null);

  const handleDeleteSheet = () => {
    setSelectedSheetForDelete(sheet);
    handleClosePoperMenu();
  };

  const handleToggleCheckBox = (value: boolean) => {
    if (!value) handleRemoveSheetId(sheet.sheetId!);
    else handleAddSheetId(sheet.sheetId!);
  };

  const isDrawingChecked = selectedSheetIds.includes(sheet.sheetId!);
  return {
    newSheetTitle,
    setNewSheetTitle,
    handleUpdateSheetTitle,
    isUpdatingSheet: updateSheetQuery.isLoading,
    anchorSheetPoperMenu,
    handleClickMoreIcon,
    handleClosePoperMenu,
    handleDeleteSheet,
    handleToggleCheckBox,
    isDrawingChecked,
  };
};

export default useSheetRow;
