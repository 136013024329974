import { useEffect, useMemo } from "react";
import { useDoxleCurrentContextStore } from "../../DoxleGeneralStore/useDoxleCurrentContext";
import useDoxleNotificationStore from "../../DoxleGeneralStore/useDoxleNotificationStore";
import FilesQueryAPI, {
  IFilterGetFileQueryFilter,
} from "../QueryHooks/FileStorageQueryAPI";
import { useFileStore } from "../Store/useFileStore";
import { shallow } from "zustand/shallow";
import { DoxleFile } from "../../Models/files";
import { useShallow } from "zustand/react/shallow";

type Props = {};

const useGetFileInsideFolder = (props: Props) => {
  const { company, currentSearchText } = useDoxleCurrentContextStore(
    (state) => ({
      company: state.currentCompany,
      currentSearchText: state.currentSearchText,
    })
  );
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification
  );
  const { currentFolder } = useFileStore(
    useShallow((state) => ({
      currentFolder: state.currentFolder,
    }))
  );
  const filterGetFileInsideFolder: IFilterGetFileQueryFilter = useMemo(
    () => ({
      folderId: currentFolder ? currentFolder.folderId : undefined,
    }),
    [currentFolder, company]
  );

  const getFilesInsideFolderQuery = FilesQueryAPI.useGetFilesInsideFolderQuery({
    company,
    showNotification,
    filter: filterGetFileInsideFolder,
  });

  const filesInsideFolderList = useMemo(
    () =>
      getFilesInsideFolderQuery.isSuccess
        ? getFilesInsideFolderQuery.data?.pages.reduce((acc, data) => {
            return acc.concat(data.data.results);
          }, [] as DoxleFile[])
        : [],
    [getFilesInsideFolderQuery.data, getFilesInsideFolderQuery.isSuccess]
  );

  const fetchNextFile = () => {
    if (getFilesInsideFolderQuery.hasNextPage)
      getFilesInsideFolderQuery.fetchNextPage();
  };
  return {
    filesInsideFolderList,
    filterGetFileInsideFolder,
    isFetchingFileInsideFolder: getFilesInsideFolderQuery.isLoading,
    isSuccessFetchingFileInsideFolder: getFilesInsideFolderQuery.isSuccess,
    isErrorFetchingFileInsideFolder: getFilesInsideFolderQuery.isError,
    isRefetchingFileInsideFolder: getFilesInsideFolderQuery.isRefetching,
    handleFetchNextFileInsideFolder: () => {
      if (getFilesInsideFolderQuery.hasNextPage)
        getFilesInsideFolderQuery.fetchNextPage();
    },
    isFetchingNextFile: getFilesInsideFolderQuery.isFetchingNextPage,
    fetchNextFile,
  };
};

export default useGetFileInsideFolder;
