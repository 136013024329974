import {
  StyledHeaderTitle,
  StyledHeaderSubtitle,
  StyledSubHeaderFirstRow,
  StyledSubHeaderSecondRow,
  StyledSubHeaderThirdRow,
  StyledSubHeaderThirdRowLeft,
  StyledSubHeaderThirdRowRight,
} from "./StyledFilesHeaderComponent";
import folderImage from "../Assets/folder.svg";
import FileLeftSubheader from "./FileLeftSubheader";
import FileRightSubheader from "./FileRightSubheader";
import useFileSubheader from "../Hooks/useFileSubheader";

function FileSubheader() {
  const { headTitleText, subTitleText, currentLevel } = useFileSubheader();

  return (
    <>
      <StyledSubHeaderFirstRow>
        <img
          style={{ width: currentLevel === "Company" ? "70px" : "20px" }}
          src={folderImage}
        />
        <StyledHeaderTitle $reduced={currentLevel !== "Company"}>
          {headTitleText}
        </StyledHeaderTitle>
      </StyledSubHeaderFirstRow>
      <StyledSubHeaderSecondRow>
        <StyledHeaderSubtitle>{subTitleText}</StyledHeaderSubtitle>
      </StyledSubHeaderSecondRow>
      <StyledSubHeaderThirdRow>
        <StyledSubHeaderThirdRowLeft>
          <FileLeftSubheader />
        </StyledSubHeaderThirdRowLeft>
        <StyledSubHeaderThirdRowRight>
          <FileRightSubheader />
        </StyledSubHeaderThirdRowRight>
      </StyledSubHeaderThirdRow>
    </>
  );
}

export default FileSubheader;
