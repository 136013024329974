import {
  StyledDoxleLoginScreenContainer,
  StyledLoginTitleText,
} from "../DoxleLoginScreen/StyledComponentDoxleLoginScreen";
import React from "react";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";

const Error404 = () => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }),
    shallow
  );
  return (
    <>
      <StyledDoxleLoginScreenContainer $themeColor={doxleThemeColor} $mobile>
        <StyledLoginTitleText
          $themeColor={doxleThemeColor}
          $doxleFont={doxleFont}
        >
          404
        </StyledLoginTitleText>
      </StyledDoxleLoginScreenContainer>
    </>
  );
};
export default Error404;
