import { shallow } from "zustand/shallow";
import { useDoxleCurrentContextStore } from "../../DoxleGeneralStore/useDoxleCurrentContext";
import { Note } from "../Models/note";
import { useNoteStore } from "../Store/useNoteStore";
import { useEditDocketSideScreenStore } from "../../CoreContent/EditDocketSideScreen/EditDocketSideScreenStore/useEditDocketSideScreenStore";
import { useEffect, useMemo } from "react";
import { NoteQueryAPI } from "../QueryAPI/notesQueryAPI";
import useDoxleNotificationStore from "../../DoxleGeneralStore/useDoxleNotificationStore";
import { useShallow } from "zustand/react/shallow";

type Props = {};

interface GetNoteList {
  noteList: Note[];
  isFetchingNoteList: boolean;
  isSuccessFetchingNoteList: boolean;
  isErrorFetchingNoteList: boolean;
  isRefetchingNoteList: boolean;
  isFetchingMoreNoteList: boolean;
  refetchNoteList: () => void;
  fetchMoreNoteList: () => void;
}
const useGetNoteList = (props: Props): GetNoteList => {
  const { company } = useDoxleCurrentContextStore(
    useShallow((state) => ({ company: state.currentCompany }))
  );
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification
  );
  const { filterNoteQuery } = useNoteStore(
    useShallow((state) => ({
      filterNoteQuery: state.filterNoteQuery,
    }))
  );

  const getNoteQuery = NoteQueryAPI.useRetrieveNoteListQuery({
    showNotification,
    company,
    filter: filterNoteQuery,
  });

  const noteList = useMemo(
    () =>
      getNoteQuery.isSuccess
        ? getNoteQuery.data.pages.reduce((acc, data) => {
            return acc.concat(data.data.results);
          }, [] as Note[])
        : [],
    [getNoteQuery.data]
  );

  const refetchNoteList = () => {
    getNoteQuery.refetch();
  };
  const fetchMoreNoteList = () => {
    if (getNoteQuery.hasNextPage) getNoteQuery.fetchNextPage();
  };

  return {
    noteList,
    isFetchingNoteList: getNoteQuery.isLoading,
    isSuccessFetchingNoteList: getNoteQuery.isSuccess,
    isErrorFetchingNoteList: getNoteQuery.isError,
    isRefetchingNoteList: getNoteQuery.isRefetching,
    isFetchingMoreNoteList: getNoteQuery.isFetchingNextPage,
    refetchNoteList,
    fetchMoreNoteList,
  };
};

export default useGetNoteList;
