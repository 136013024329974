import React, { useState } from "react";
import { QAImage } from "../../../../Models/qa";
import { useIsMutating } from "@tanstack/react-query";
import { getQAImageMutationKey } from "../../../../QueryAPI/qaQueryAPI";
import { useProjectQAStore } from "../../../Store/useProjectQAStore";
import { shallow } from "zustand/shallow";

type Props = {
  imageItem: QAImage;
};

interface QAImageItem {
  isHoverRow: boolean;
  setIsHoverRow: React.Dispatch<React.SetStateAction<boolean>>;
  isLoadingImg: boolean;
  isImgError: boolean;
  onLoadImgStart: () => void;
  onLoadImgEnd: () => void;
  onLoadImgError: () => void;
  isDeletingImg: boolean;
  handleClickEditIcon: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
}
const useQAImageItem = ({ imageItem }: Props): QAImageItem => {
  const [isHoverRow, setIsHoverRow] = useState(false);
  const [isLoadingImg, setIsLoadingImg] = useState(false);
  const [isImgError, setIsImgError] = useState(false);

  const onLoadImgStart = () => {
    setIsLoadingImg(true);
  };
  const onLoadImgEnd = () => {
    setIsLoadingImg(false);
  };
  const onLoadImgError = () => {
    setIsLoadingImg(false);
    setIsImgError(true);
  };
  const { markupQAImage, setMarkupQAImage } = useProjectQAStore(
    (state) => ({
      markupQAImage: state.markupQAImage,
      setMarkupQAImage: state.setMarkupQAImage,
    }),
    shallow
  );

  const handleClickEditIcon = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.stopPropagation();
    setMarkupQAImage({
      qaImage: imageItem,
      markupList: imageItem.markup || [],
    });
  };
  const isDeletingImg =
    useIsMutating({
      mutationKey: getQAImageMutationKey("delete"),
      predicate: (query) =>
        (query.state.variables as string) === imageItem.imageId,
    }) > 0;
  return {
    isHoverRow,
    setIsHoverRow,
    isLoadingImg,
    isImgError,
    onLoadImgStart,
    onLoadImgEnd,
    onLoadImgError,
    isDeletingImg,
    handleClickEditIcon,
  };
};

export default useQAImageItem;
