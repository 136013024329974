import styled from "styled-components";
import {
  DoxleFont,
  DoxleTheme,
  DoxleThemeColor,
} from "../../../../DoxleGeneralStore/useDoxleThemeStore";
import { editRgbaAlpha } from "../../../../Utilities/FunctionUtilities";

//===========================JoinFormPage===============================
//======================================================================
//======================================================================

export const RequiredWarning = styled.div<{
  $doxleFont: DoxleFont;
}>`
  color: #ea1515;
  font-size: 1.2rem;
  font-family: ${(p) => p.$doxleFont.subTitleFont};
  position: relative;
  top: 2px;
  height: 3px;
  left: 20px;
`;

export const FormArea = styled.div`
  padding: 20px 30px;
`;

export const StyledInput = styled.input<{
  $themeColor: DoxleThemeColor;
  $currentTheme: DoxleTheme;
}>`
  width: 100%;
  padding: 12px 20px;
  margin: 10px 0;
  box-sizing: border-box;
  border: none;
  border-bottom: 2px solid
    ${(p) =>
      p.$currentTheme !== "dark"
        ? "rgba(242,242,242,1)"
        : p.$themeColor.primaryDividerColor};
  background: transparent;
  height: 30px;
  color: ${(p) => p.$themeColor.primaryFontColor};

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: ${(p) => p.$themeColor.primaryFontColor};
    opacity: 1; /* Firefox */
    font-size: 1.4rem;
  }

  &::-ms-input-placeholder {
    /* Edge 12-18 */
    color: ${(p) => p.$themeColor.primaryFontColor};
    font-size: 1.4rem;
  }
`;

export const JoinFormContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  @media (max-width: 600px){
    flex-direction: column;
    overflow-y: scroll;
    overflow-x: hidden;
  }
`;

export const JoinFormLeft = styled.div`
  width: calc(50% - 4rem);
  padding: 0 2rem;
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: center;
  @media (max-width: 600px){
    width: calc(100% - 4rem);
    align-items: center;
    margin-bottom: 10px;
  }
`;

export const JoinFormRight = styled.div`
  width: calc(50% - 4rem);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 2rem;
  @media (max-width: 600px){
    width: calc(100% - 4rem);
  }
`;

export const JoinFormRightInnerContainer = styled.div<{
  $themeColor: DoxleThemeColor;
  $currentTheme: DoxleTheme;
}>`
  border: 1px solid
    ${(p) =>
      p.$currentTheme !== "dark"
        ? "rgba(242,242,242,1)"
        : p.$themeColor.primaryDividerColor};
  max-width: 39rem;
`;

export const ApplyBtn = styled.button<{
  $themeColor: DoxleThemeColor;
}>`
  background-color: ${(p) => p.$themeColor.primaryFontColor};
  color: ${(p) => p.$themeColor.primaryBackgroundColor};
  width: 8rem;
  height: 3rem;
  border-radius: 3px;
  border: none;
  cursor: pointer;
  margin-top: 5rem;

  &:hover {
    transform: scale(1.03) translateY(-0.3rem);
    opacity: 0.9;
    transition: 0.2s all;
  }
  &:active {
    transform: scale(0.98) translateY(0.2rem);
    opacity: 1;
    transition: 0.2s all;
  }
`;

//============================SelectRolePage=============================
//=======================================================================
//=======================================================================

export const PromptArea = styled.div`
  border-bottom: 1px solid #e6e6e6;
  padding-bottom: 20px;
`;

export const JoinDoxle = styled.h2<{
  $themeColor: DoxleThemeColor;
}>`
  font-size: 1.6rem;
  color: ${(p) => p.$themeColor.doxleColor};
  margin-left: 4rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
`;

export const StyledDescription = styled.div<{
  $themeColor: DoxleThemeColor;
}>`
  font-size: 1.4rem;
  color: ${(p) =>
    editRgbaAlpha({ rgbaColor: p.$themeColor.primaryFontColor, alpha: "0.7" })};
  margin-left: 4rem;
`;

export const SelectArea = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  padding: 30px 40px;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  .container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 1.4rem;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-family: ${(p) => p.$doxleFont.subTitleFont};
    color: ${(p) => p.$themeColor.primaryFontColor};
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 1.4rem;
    width: 1.4rem;
    border: solid 1px ${(p) => p.$themeColor.borderColor};
    border-radius: 50%;
  }

  /* On mouse-over, add a grey background color */
  .container:hover input ~ .checkmark {
  }

  /* When the radio button is checked, add a blue background */
  .container input:checked ~ .checkmark {
    background-color: #ffffff;
  }

  /* Create the indicator (the dot/circle - hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the indicator (dot/circle) when checked */
  .container input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the indicator (dot/circle) */
  .container .checkmark:after {
    top: 4px;
    left: 4px;
    width: 0.6rem;
    height: 0.6rem;
    border-radius: 50%;
    background: #000000;
  }
`;

export const SelectRoleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
   @media (max-width: 600px){
    flex-direction: column;
    overflow-y: scroll;
    overflow-x: hidden;
  }
`;

export const SelectRoleLeft = styled.div`
  width: calc(50% - 4rem);
  padding: 0 2rem;
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: center;

  @media (max-width: 600px){
    width: calc(100% - 4rem);
    align-items: center;
  }

  img {
    width: 80%;
    aspect-ratio: ${342 / 292};
    min-width: 150px;
    position: relative;
    top: 25px;
    max-width: 392px;
  }
`;

export const SelectRoleRight = styled.div`
  width: calc(50% - 4rem);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 2rem;
  @media (max-width: 600px){
    width: calc(100% - 4rem);
    align-items: center;
  }
`;

export const SelectRoleRightInnerContainer = styled.div`
  border: 1px solid #e6e6e6;
  max-width: 39rem;
  height: 44.7rem;
  width: 100%;
`;

export const NextBtn = styled.button<{
  $themeColor: DoxleThemeColor;
}>`
  background-color: ${(p) => p.$themeColor.primaryFontColor};
  color: ${(p) => p.$themeColor.primaryBackgroundColor};
  width: 8rem;
  height: 3rem;
  border-radius: 3px;
  border: none;
  cursor: pointer;
  margin-top: 5rem;

  &:hover {
    transform: scale(1.03) translateY(-0.3rem);
    opacity: 0.9;
    transition: 0.2s all;
  }
  &:active {
    transform: scale(0.98) translateY(0.2rem);
    opacity: 1;
    transition: 0.2s all;
  }
`;

export const JoinSuccessPageContainer = styled.div`
  width: 100%;
  height: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
