import { SVGProps } from "react";
import { DoxleThemeColor } from "../DoxleGeneralStore/useDoxleThemeStore";
import { DesktopDatePickerSlotsComponentsProps } from "@mui/x-date-pickers";
import { editRgbaAlpha } from "../Utilities/FunctionUtilities";

export type PropsFrom<TComponent> = TComponent extends React.FC<infer Props>
  ? Props
  : never; //get prop types from a functional component

//* Function to get all the props from an object
export const extractKeyPropsFromObject = <TObject extends Object>(
  obj: TObject
): (keyof TObject)[] => {
  return Object.keys(obj) as (keyof TObject)[];
};

//* turn optional props inside a model to required
export type WithRequiredProperty<Type, Key extends keyof Type> = Type & {
  [Property in Key]-?: Type[Property];
};

export interface ISVGResponsiveCustom extends SVGProps<SVGSVGElement> {
  themeColor: DoxleThemeColor;
  containerStyle?: React.CSSProperties;
}

export function customDatePickerStyle<T>(
  themeColor: DoxleThemeColor
): DesktopDatePickerSlotsComponentsProps<T> {
  const result: DesktopDatePickerSlotsComponentsProps<T> = {
    desktopPaper: {
      sx: {
        bgcolor: `${themeColor.primaryTextFieldBackgroundColor} !important`,
        border: `1px solid ${themeColor.primaryDividerColor} !important`,
        fontSize: "max(1.4rem,14px)",
        color: `${themeColor.primaryFontColor} !important`,
        "& .MuiDayCalendar-weekDayLabel": {
          color: `${themeColor.primaryFontColor} !important`,
          fontSize: "max(1.1rem,11px)",
        },
        "& .MuiPickersYear-yearButton.Mui-selected": {
          bgcolor: `${editRgbaAlpha({
            rgbaColor: themeColor.doxleColor,
            alpha: "0.4",
          })} !important`,
        },
        "& .MuiPickersCalendarHeader-labelContainer": {
          fontSize: "max(1.2rem,12px)",
        },
        "& .MuiPickersDay-today": {
          border: `1px solid ${themeColor.primaryFontColor} !important`,
        },
      },
    },
    day: {
      sx: {
        color: `${themeColor.primaryFontColor} !important`,
        fontSize: "max(1.2rem,12px)",
        "&.Mui-selected": {
          bgcolor: `${editRgbaAlpha({
            rgbaColor: themeColor.doxleColor,
            alpha: "0.4",
          })} !important`,
        },
      },
    },

    rightArrowIcon: {
      sx: {
        color: `${themeColor.primaryFontColor} !important`,
      },
    },
    leftArrowIcon: {
      sx: {
        color: `${themeColor.primaryFontColor} !important`,
      },
    },
    switchViewButton: {
      sx: {
        color: `${themeColor.primaryFontColor} !important`,
      },
    },
  };
  return result;
}
