import React, { useEffect } from "react";
import { useDoxleQuoteStore } from "../../store/useDoxleQuoteStore";
import { shallow } from "zustand/shallow";
import { ClickAwayListener, Fade, Popover, Popper } from "@mui/material";
import { DoxleFile } from "../../../Models/files";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import {
  StyledAttachmentPopperItem,
  StyledQuotesAttachmentTitle,
  StyledQuotesAttachmentsItem,
  StyledQuotesAttachmentsPopup,
  StyledQuotesAttachmentsPopupContainer,
  StyledQuotesAttachmentsPopupTitle,
} from "../MainStyledComponents";
import { VscChromeClose } from "react-icons/vsc";
import PDFIcon from "../../Assets/adobePdfIcon.svg";
import ExcelIcon from "../../Assets/excelIcon.svg";
import DocIcon from "../../Assets/microsoftIcon.svg";
import AutocompleteList from "../../../DoxleDesignPattern/DoxleAutoComplete/AutocompleteList";
import { QuoteStorage } from "../../models/quote";
type Props = {};

const QuoteAttachmentPopper = (props: Props) => {
  const {
    attachmentPopperList,

    anchorAttachmentList,
    handleCloseAttachmentPopper,
  } = useDoxleQuoteStore(
    (state) => ({
      attachmentPopperList: state.attachmentPopperList,

      anchorAttachmentList: state.anchorAttachmentList,
      handleCloseAttachmentPopper: state.handleCloseAttachmentPopper,
    }),
    shallow
  );
  const isOpenAttachmentPopper = Boolean(
    attachmentPopperList && anchorAttachmentList
  );
  const id = isOpenAttachmentPopper ? "attachment-popper" : undefined;

  return (
    <Popover
      id={id}
      open={Boolean(attachmentPopperList)}
      anchorEl={anchorAttachmentList}
      disableScrollLock
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      onClose={handleCloseAttachmentPopper}
      sx={{
        backgroundColor: "transparent !important",
        "& .MuiPopover-paper": {
          backgroundColor: "transparent !important",
          overflow: "visible",
        },
      }}
      elevation={4}
    >
      <QuoteAttachmentsPopup
        attachments={attachmentPopperList ?? []}
        handleClosePopper={handleCloseAttachmentPopper}
      />
    </Popover>
  );
};

export default QuoteAttachmentPopper;

interface QuoteAttachmentListProps {
  attachments: QuoteStorage[];
  handleClosePopper: () => void;
}
const QuoteAttachmentsPopup: React.FC<QuoteAttachmentListProps> = ({
  attachments,
  handleClosePopper,
}) => {
  const { THEME_COLOR, DOXLE_FONT } = useDoxleThemeStore((state) => ({
    THEME_COLOR: state.doxleThemeColor,
    DOXLE_FONT: state.doxleFont,
  }));
  const attachmentIconSource = (attachment: QuoteStorage) =>
    attachment.fileType.toLowerCase().includes("pdf")
      ? PDFIcon
      : attachment.fileType.toLowerCase().includes("doc") ||
        attachment.fileType.toLowerCase().includes("docx")
      ? DocIcon
      : attachment.fileType.toLowerCase().includes("xlsx")
      ? ExcelIcon
      : attachment.url;
  return (
    <StyledQuotesAttachmentsPopupContainer
      $themeColor={THEME_COLOR}
      $doxleFont={DOXLE_FONT}
    >
      <StyledQuotesAttachmentsPopupTitle
        $themeColor={THEME_COLOR}
        $doxleFont={DOXLE_FONT}
      >
        <span>Attachments</span>
        <VscChromeClose
          size="1.6rem"
          style={{ color: THEME_COLOR.primaryFontColor, cursor: "pointer" }}
          onClick={handleClosePopper}
        />
      </StyledQuotesAttachmentsPopupTitle>
      <div className="attachment-wrapper">
        {attachments.map((item, idx) => {
          return (
            <StyledAttachmentPopperItem
              $themeColor={THEME_COLOR}
              key={item.attachmentId + idx.toString()}
              onClick={() => window.open(item.url, "_blank")} // render attachments
            >
              <img
                src={attachmentIconSource(item)}
                alt="pdf"
                className="img-holder"
              />
              <StyledQuotesAttachmentTitle
                $themeColor={THEME_COLOR}
                $doxleFont={DOXLE_FONT}
              >
                {item.fileName}
              </StyledQuotesAttachmentTitle>
            </StyledAttachmentPopperItem>
          );
        })}
      </div>
    </StyledQuotesAttachmentsPopupContainer>
  );
};
