//! TO USE THIS EDIT DOCKET SIDE SCREEN, import useEditDocketSideScreenStore and extract only setEdittedDocket prop from the store, and assign it with the editted docket
//! filterDocketListQuery from this store is compulsory to overwrite query data result correctly, set it together at them same time with the setEdittedDocket, this filterDocketListQuery should match with the filter of the query key when calling query to fetch docket list

//! COMPONENT PLACEMENT: this component should be placed at the same level with the docket list
import { useCallback, useEffect, useMemo } from "react";
import { useEditDocketSideScreenStore } from "./EditDocketSideScreenStore/useEditDocketSideScreenStore";
import { shallow } from "zustand/shallow";
import DoxleSideScreen from "../../DoxleDesignPattern/DoxlleSideScreen/DoxleSideScreen";
import EditDocketFullScreenMode from "./EditDocketFullScreenMode/EditDocketFullScreenMode";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import useWindowDimensions from "../UtilityHooks/useWindowDimensions";
import { TRgbaFormat, editRgbaAlpha } from "../../Utilities/FunctionUtilities";
import Loading from "../../Utilities/Lottie/Loading";
import useGetDocketFullDetails from "../QueryDataHooks/GetQueryDataHooks/useGetDocketFullDetails";
import DoxleEmptyPlaceHolder from "../../DoxleDesignPattern/DoxleEmptyPlaceHolder/DoxleEmptyPlaceHolder";
import { Error404Banner } from "../../DoxleDesignPattern/DoxleIcons/CommonIcons";
import { TfiClose } from "react-icons/tfi";
import { LuMaximize2 } from "react-icons/lu";
import { LuMinimize2 } from "react-icons/lu";
import { useShallow } from "zustand/react/shallow";
type Props = {};

const EditDocketSideScreen = () => {
  const { doxleThemeColor, staticMenuColor } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
      staticMenuColor: state.staticMenuColor,
    }),
    shallow
  );
  const {
    edittedDocket,
    setEdittedDocket,
    resetStore,

    setIsFullScreenMode,
    setFilterDiscussionList,
    initialEditedDocket,
  } = useEditDocketSideScreenStore(
    useShallow((state) => ({
      edittedDocket: state.edittedDocket,
      setEdittedDocket: state.setEdittedDocket,
      initialEditedDocket: state.initialEditedDocket,

      resetStore: state.resetStore,

      setIsFullScreenMode: state.setIsFullScreenMode,
      setFilterDiscussionList: state.setFilterDiscussionList,
    }))
  );
  useEffect(() => {
    if (initialEditedDocket) {
      setFilterDiscussionList({
        docketId: initialEditedDocket?.docketPk,
      });
    }
  }, [initialEditedDocket]);
  const { docketDetail, isFetchingDocketDetail } = useGetDocketFullDetails({
    docketPk: initialEditedDocket?.docketPk || "",
  });

  useEffect(() => {
    if (docketDetail) setEdittedDocket(docketDetail);
  }, [docketDetail]);

  const isModalOpen = useMemo(
    () => Boolean(initialEditedDocket !== undefined),
    [initialEditedDocket]
  );
  const handleCloseModal = useCallback(() => {
    resetStore();
  }, []);

  const onModalExpanded = (value: boolean) => {
    setIsFullScreenMode(value);
  };
  const { width: viewPortWidth } = useWindowDimensions();

  return (
    <DoxleSideScreen
      openSideScreen={isModalOpen}
      handleCloseSideScreen={handleCloseModal}
      containerMinWidthInPixel="480px"
      fullScreenInitial={true}
      containerWidthInRatio={
        viewPortWidth <= 900
          ? "85%"
          : viewPortWidth <= 1280
          ? "80%"
          : viewPortWidth <= 1480
          ? "75%"
          : "50%"
      }
      contentWrapperStyled={{
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        backgroundColor: doxleThemeColor.primaryBackgroundColor,
      }}
      hasBackDrop={true}
      backdropStyle={{
        backgroundColor: editRgbaAlpha({
          rgbaColor: doxleThemeColor.primaryBackdropColor as TRgbaFormat,
          alpha: "0.4",
        }),
      }}
      expandBtnStyle={{
        top: "10px",
      }}
      closeBtnStyled={{
        top: "10px",
      }}
      closeIcon={<TfiClose color={staticMenuColor.staticFontColor} size={20} />}
      maximizeIcon={
        <LuMaximize2 color={staticMenuColor.staticFontColor} size={20} />
      }
      minimizeIcon={
        <LuMinimize2 color={staticMenuColor.staticFontColor} size={20} />
      }
      onExpanded={onModalExpanded}
      children={
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
          }}
        >
          {isFetchingDocketDetail ? (
            <Loading
              text="Getting docket details..."
              containerStyle={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
              }}
            />
          ) : (
            <>
              {!docketDetail && (
                <DoxleEmptyPlaceHolder
                  headTitleText="Something wrong!"
                  subTitleText="Failed to get docket details, please try to reload the page, we are sorry!"
                  illustration={
                    <Error404Banner
                      themeColor={doxleThemeColor}
                      containerStyle={{
                        width: "70%",
                      }}
                    />
                  }
                />
              )}
              {docketDetail && <EditDocketFullScreenMode />}
            </>
          )}
        </div>
      }
    />
  );
};

export default EditDocketSideScreen;
