import { useMemo, useState } from "react";
import { DoxleFile } from "../../Models/files";
import { useFileStore } from "../Store/useFileStore";
import { useShallow } from "zustand/react/shallow";
import { checkSupportedFileType } from "../../Utilities/MimeFileType";
import { useIsMutating } from "@tanstack/react-query";
import {
  IDeleteFileQueryParams,
  getFileMutateKey,
} from "../QueryHooks/FileStorageQueryAPI";
import { useDoxleImageGalleryStore } from "../../DoxleDesignPattern/DoxleGallery/Store/useDoxleImageGalleryStore";

type Props = {
  file: DoxleFile;
};

const useFilesRowRight = ({ file }: Props) => {
  const [onFileRowHover, setOnFileRowHover] = useState<boolean>(false);
  const { setSelectedFile, setUnselectedFile, selectedFiles, currentFolder } =
    useFileStore(
      useShallow((state) => ({
        setSelectedFile: state.setSelectedFile,
        setUnselectedFile: state.setUnselectedFile,
        selectedFiles: state.selectedFiles,
        currentFolder: state.currentFolder,
      }))
    );
  const { setImageList, setVideoUrl } = useDoxleImageGalleryStore(
    useShallow((state) => ({
      setImageList: state.setImageList,

      setVideoUrl: state.setVideoUrl,
    }))
  );
  const openFile = () => {
    if (file.fileType.toLowerCase().includes("image") && file.testUrl)
      setImageList([{ uri: file.testUrl, name: file.fileName }]);
    else if (file.fileType.toLowerCase().includes("video") && file.url)
      setVideoUrl(file.url);
    else window.open(file.testUrl);
  };

  const supportedFileType = useMemo(() => {
    return checkSupportedFileType({ type: file.fileType });
  }, [file.fileType]);

  const onSelectCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log(
      "Checkbox selected for " + file.fileName + " " + e.target.checked
    );
    if (e.target.checked === true) setSelectedFile(file);
    else setUnselectedFile(file);
  };
  const isChecked = Boolean(
    selectedFiles.find((selectedFile) => selectedFile.fileId === file.fileId)
  );

  const isDeletingFileInsideFolder =
    useIsMutating({
      mutationKey: getFileMutateKey("delete"),
      predicate: (query) =>
        Boolean(
          currentFolder &&
            (query.state.variables as IDeleteFileQueryParams).files.find(
              (deletedFile) => file.fileId === deletedFile.fileId
            )
        ),
    }) > 0;
  return {
    onFileRowHover,
    setOnFileRowHover,
    supportedFileType,
    openFile,
    isDeletingFileInsideFolder,
  };
};

export default useFilesRowRight;
