import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import QAImageSection from "./QAImageSection";
import {
  DoxleThemeColor,
  useDoxleThemeStore,
} from "../../../../DoxleGeneralStore/useDoxleThemeStore";
import { useCallback, useMemo, useRef } from "react";
import useRefComponentDimension from "../../../../CoreContent/UtilityHooks/useRefComponentDimension";
import useQAImageSection from "./Hooks/useQAImageSection";
import { useDropzone } from "react-dropzone";
import { GridComponents, GridItemContent, VirtuosoGrid } from "react-virtuoso";
import {
  StyledAddQAImgButton,
  StyledQAImageSection,
  StyledQAImageWrapper,
  StyledQAImgListContainer,
  StyledQAItemDetailContainer,
} from "./StyledComponentQAItemDetail";
import React from "react";
import { AnimatePresence, LayoutGroup, motion } from "framer-motion";
import { StyledQAListScroller } from "../CommonComponents/StyledComponentCommonQA";
import EditQADescription from "./EditQADescription";
import EditQaAssignee from "./EditQaAssignee";
import EditQaDueDate from "./EditQaDueDate";
import QACommentSection from "./QACommentSection";
import LinearProgress from "@mui/material/LinearProgress";
import { editRgbaAlpha } from "../../../../Utilities/FunctionUtilities";
import { ImImages } from "react-icons/im";
import { QAImage, QAFile } from "../../../Models/qa";
import QAImageItem from "./QAImageItem";
import EmptyQAImageBanner from "./EmptyQAImageBanner";

import DoxleDialogHelper from "../../../../DoxleDesignPattern/DoxleDialogHelper/DoxleDialogHelper";
import { Error404Banner } from "../../../../DoxleDesignPattern/DoxleIcons/CommonIcons";
import DoxleEmptyPlaceHolder from "../../../../DoxleDesignPattern/DoxleEmptyPlaceHolder/DoxleEmptyPlaceHolder";
import QAFileItem from "./QAFileItem";
import useQAFileList from "./Hooks/useQAVideoList";
import { MdOutlinePermMedia } from "react-icons/md";
import dayjs from "dayjs";
import EditQaFloor from "./EditQaFloor";
import EditQaRoom from "./EditQaRoom";
import QAVideoItem from "./QAVideoItem";

type Props = {};

interface QAImageListContext {
  isErrorFetchingImgList: boolean;
  numOfCol: number;
  isAddingImg: boolean;
  isFetchingImgList: boolean;
  qaListLength: number;
  doxleThemeColor: DoxleThemeColor;
}
const QAItemDetail = (props: Props) => {
  const { doxleThemeColor } = useDoxleThemeStore((state) => ({
    doxleThemeColor: state.doxleThemeColor,
    doxleFont: state.doxleFont,
  }));
  const wrapperRef = useRef<HTMLDivElement>(null);
  const { width: wrapperWidth, height } = useRefComponentDimension({
    componentRef: wrapperRef,
  });

  const {
    qaImageList,
    isFetchingImgList,
    isErrorFetchingImgList,
    handleRefetchQAImageList,
    handleClickImage,
    setdeletedQAImg,
    deletedQAImg,
    handleCloseDeleteDialog,
    handleDeleteImg,
    onDrop,
    isAddingImg,
    deletedQAFile,
    setDeletedQAFile,
  } = useQAImageSection({});
  const { videoList } = useQAFileList({});
  const { getRootProps } = useDropzone({
    onDrop,
    accept: {
      "image/png": [".png"],
      "image/jpg": [".jpg"],
      "image/jpeg": [".jpeg"],
      "video/*": [".mp4", ".mpeg", ".avi"],
    },
    noClick: true,
    maxSize: 15000000,
  });
  const { getRootProps: buttonDropProps } = useDropzone({
    onDrop,
    accept: {
      "image/png": [".png"],
      "image/jpg": [".jpg"],
      "image/jpeg": [".jpeg"],
      "video/*": [".mp4", ".mpeg", ".avi"],
      "application/pdf": [".pdf"],
      "application/msword": [".doc"],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"],
      "application/vnd.ms-excel": [".xls"],
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"],
      "text/csv": [".csv"],
      "text/plain": [".txt"],
      "text/rtf": [".rtf"]
    },
    noDrag: true,
    noDragEventsBubbling: true,
    noKeyboard: true,
    maxSize: 15000000,
  });
  //*render list
  const numOfCol = useMemo(
    () =>
      wrapperWidth < 300
        ? 2
        : wrapperWidth < 700
        ? 3
        : wrapperWidth < 1000
        ? 4
        : 5,
    [wrapperWidth]
  );
  const components: GridComponents<QAImageListContext> = useMemo(
    () => ({
      Item: ({ context, ...props }) => (
        <StyledQAImageWrapper {...props} $numOfCol={context?.numOfCol ?? 3} />
      ),
      List: React.forwardRef(({ context, children, ...props }, ref) => (
        <StyledQAImgListContainer
          {...props}
          ref={ref}
          layout="position"
          transition={{
            duration: 0.2,
            damping: 16,
          }}
        >
          <LayoutGroup>{children}</LayoutGroup>
        </StyledQAImgListContainer>
      )),

      Scroller: React.forwardRef(({ context, ...props }, ref) => (
        <StyledQAListScroller ref={ref} {...props} />
      )),
      Header: (props) => (
        <StyledQAItemDetailContainer
          initial={{ x: 100, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
        >
          <EditQADescription />

          <div className="assignee-dueDate-wrapper">
            <EditQaAssignee />
            <EditQaDueDate />
          </div>

          <div className="assignee-dueDate-wrapper">
            <EditQaFloor />
            <EditQaRoom />
          </div>

          <QACommentSection />

          {props.context?.isAddingImg && (
            <motion.div
              className="loader-wrapper"
              animate={{ scaleX: [0, 1], opacity: [0, 1] }}
            >
              <LinearProgress
                className="loader-bar"
                sx={{
                  "&.MuiLinearProgress-root": {
                    backgroundColor: editRgbaAlpha({
                      rgbaColor: props.context!.doxleThemeColor.doxleColor,
                      alpha: "0.3",
                    }),
                  },
                  "& .MuiLinearProgress-bar": {
                    backgroundColor: props.context!.doxleThemeColor.doxleColor,
                  },
                  width: "50% !important",
                }}
              />
              <span className="loader-text">Uploading Image...</span>
            </motion.div>
          )}

          {props.context?.isFetchingImgList ? (
            <div className="loader-wrapper">
              <LinearProgress
                className="loader-bar"
                sx={{
                  "&.MuiLinearProgress-root": {
                    backgroundColor: editRgbaAlpha({
                      rgbaColor: doxleThemeColor.doxleColor,
                      alpha: "0.3",
                    }),
                  },
                  "& .MuiLinearProgress-bar": {
                    backgroundColor: doxleThemeColor.doxleColor,
                  },
                }}
              />

              <span className="loader-text">
                Loading QA Image, please wait...
              </span>
            </div>
          ) : (
            <StyledAddQAImgButton {...buttonDropProps({})}>
              <MdOutlinePermMedia
                size={20}
                color={props.context!.doxleThemeColor.primaryFontColor}
              />
              <span className="btn-text">Add Images & Files</span>
            </StyledAddQAImgButton>
          )}
        </StyledQAItemDetailContainer>
      ),
      Footer: (props) =>
        props.context?.qaListLength === 0 ? (
          <EmptyQAImageBanner onDrop={onDrop} />
        ) : props.context?.isErrorFetchingImgList ? (
          <DoxleEmptyPlaceHolder
            illustration={<Error404Banner themeColor={doxleThemeColor} />}
            headTitleText="Something wrong!"
            subTitleText="Failed to get qa images, please reload the page!"
            containerBgColor="transparent"
          />
        ) : undefined,
    }),
    [onDrop]
  );

  const itemContent: GridItemContent<QAImage | QAFile, QAImageListContext> =
    useCallback(
      (index, item) =>
        "imageId" in item ? (
          <QAImageItem
            imageItem={item}
            key={`qaImg#${item.imageId}`}
            imgIndex={index}
            handleClickImage={handleClickImage}
            setdeletedQAImg={setdeletedQAImg}
          />
        ) : item.fileType.includes("video") ? (
          <QAVideoItem
            videoItem={item}
            key={`QAFile#${item.fileId}`}
            setDeletedQAFile={setDeletedQAFile}
          />
        ) : (
          <QAFileItem
            fileItem={item}
            key={`QAFile#${item.fileId}`}
            setDeletedQAFile={setDeletedQAFile}
          />
        ),
      [handleClickImage]
    );

  const gridListStyle = useMemo(
    () => ({
      height: "100%",
      width: "100%",
    }),
    []
  );
  const itemType = useMemo(() => deletedQAImg
    ? "image"
    : deletedQAFile?.fileType.includes('video')
      ? "video"
      : 'file'
  , [deletedQAImg, deletedQAFile?.fileType])

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <StyledQAImageSection
        {...getRootProps({})}
        ref={wrapperRef}
        layout="position"
        transition={{
          duration: 0.2,
          damping: 16,
        }}
      >
        <VirtuosoGrid
          context={{
            isErrorFetchingImgList,
            numOfCol,
            isAddingImg,
            isFetchingImgList,
            qaListLength: qaImageList.length,
            doxleThemeColor,
          }}
          style={gridListStyle}
          data={[...qaImageList, ...videoList].sort((a, b) =>
            a.createdOn
              ? dayjs(a.createdOn).isAfter(dayjs(b.createdOn))
                ? -1
                : 1
              : -1
          )}
          components={components}
          itemContent={itemContent}
        />

        <DoxleDialogHelper
          open={Boolean(deletedQAImg || deletedQAFile)}
          onClose={handleCloseDeleteDialog}
          title={`Deleting ${itemType}!`}
          description={`This ${itemType} item will be deleted permanently,are you sure to proceed?`}
          dialogButtons={[
            {
              buttonText: "Cancel",
              buttonFunction: handleCloseDeleteDialog,
              buttonColor: doxleThemeColor.primaryContainerColor,
              btnStyleSx: {
                border: `1px solid ${doxleThemeColor.primaryDividerColor}`,
              },
              buttonTextStyle: {
                color: doxleThemeColor.primaryFontColor,
              },
            },
            {
              buttonText: "Confirm",
              buttonFunction: handleDeleteImg,
              btnStyleSx: {
                border: `1px solid ${doxleThemeColor.primaryDividerColor}`,
              },
            },
          ]}
        />
      </StyledQAImageSection>
    </LocalizationProvider>
  );
};

export default QAItemDetail;
