import { memo } from "react";
import {
  StyledBudgetHeaderTitleText,
  StyledBudgetTableHeaderCell,
  StyledBudgetTableHeaderRootContainer,
} from "./BudgetStyleComponent";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import {
  BUDGET_TABLE_HEADER_LIST,
  IBudgetTableHeaders,
} from "../Models/BudgetDocket";
import { useBudgetStore } from "../Store/useBudgetStore";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

type Props = {};

const BudgetTableHeader = (props: Props) => {
  //Stores
  const { doxleThemeColor, doxleFont, staticMenuColor } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
      staticMenuColor: state.staticMenuColor,
    }),
    shallow
  );
  const { orderBy, setFilter } = useBudgetStore(
    (state) => ({
      orderBy: state.filterDocketListQuery.order_by,
      setFilter: state.setFilterDocketListQuery,
    }),
    shallow
  );

  const checkHeaderMatch = (header: IBudgetTableHeaders) => {
    return (
      (header.headerName === "Name" && orderBy?.includes("docket_name")) ||
      (header.headerName === "Person" && orderBy?.includes("ball_in_court")) ||
      (header.headerName === "Budget" && orderBy?.includes("cost_budget")) ||
      (header.headerName === "Order" && orderBy?.includes("cost_actual")) ||
      (header.headerName === "Xero" && orderBy?.includes("cost_xero")) ||
      (header.headerName === "Final" && orderBy?.includes("cost_running"))
    );
  };

  const handleOrder = (header: IBudgetTableHeaders) => {
    if (header.headerName === "Name") {
      if (orderBy?.includes("docket_name"))
        setFilter({ order_by: "-docket_name" });
      else setFilter({ order_by: "docket_name" });
    } else if (header.headerName === "Person") {
      if (orderBy === "ball_in_court")
        setFilter({ order_by: "-ball_in_court" });
      else setFilter({ order_by: "ball_in_court" });
    } else if (header.headerName === "Budget") {
      if (orderBy === "cost_budget") setFilter({ order_by: "cost_budget" });
      else setFilter({ order_by: "cost_budget" });
    } else if (header.headerName === "Order") {
      if (orderBy === "cost_actual") setFilter({ order_by: "cost_actual" });
      else setFilter({ order_by: "cost_actual" });
    } else if (header.headerName === "Xero") {
      if (orderBy === "cost_xero") setFilter({ order_by: "cost_xero" });
      else setFilter({ order_by: "cost_xero" });
    } else if (header.headerName === "Final") {
      if (orderBy === "cost_running") setFilter({ order_by: "cost_running" });
      else setFilter({ order_by: "cost_running" });
    }
  };

  return (
    <StyledBudgetTableHeaderRootContainer>
      {BUDGET_TABLE_HEADER_LIST.map(
        (header, idx) =>
          header.isDisplayed && (
            <StyledBudgetTableHeaderCell
              onClick={() => handleOrder(header)}
              $themeColor={doxleThemeColor}
              $widthRatio={header.headerName === "Name" ? null : `16%`}
              $horizontalAlign={
                header.headerName === "Name" ? "flex-start" : "center"
              }
              key={`header#${idx}`}
            >
              <StyledBudgetHeaderTitleText
                sx={{
                  paddingLeft: header.headerName === "Name" ? "5rem" : 0,
                }}
              >
                {header.headerName !== "Person" &&
                  (header.headerName === "Name" ? "Docket" : header.headerName)}
              </StyledBudgetHeaderTitleText>
              {checkHeaderMatch(header) &&
                !Array.isArray(orderBy) &&
                orderBy?.startsWith("-") && (
                  <ArrowDropDownIcon
                    sx={{ color: staticMenuColor.staticFontColor }}
                  />
                )}
              {checkHeaderMatch(header) &&
                !Array.isArray(orderBy) &&
                !orderBy?.startsWith("-") && (
                  <ArrowDropUpIcon
                    sx={{ color: staticMenuColor.staticFontColor }}
                  />
                )}
            </StyledBudgetTableHeaderCell>
          )
      )}
    </StyledBudgetTableHeaderRootContainer>
  );
};

export default memo(BudgetTableHeader);
