import {
  StyledEditRateHeaderCell,
  StyledEditRateHeaderRow,
} from "../StyledComponents";

interface Props {}
const SupplierRateHeader = ({}: Props) => {
  return (
    <StyledEditRateHeaderRow>
      <StyledEditRateHeaderCell $width={"50%"}>
        Supplier
      </StyledEditRateHeaderCell>
      <StyledEditRateHeaderCell $width={"28%"}>
        Item Price
      </StyledEditRateHeaderCell>
      <StyledEditRateHeaderCell $width={"18%"}>UOM</StyledEditRateHeaderCell>
    </StyledEditRateHeaderRow>
  );
};
export default SupplierRateHeader;
