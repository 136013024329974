import React, { useState } from "react";
import { useEditOrderStore } from "../../../OrdersStore/useEditOrderStore";
import { shallow } from "zustand/shallow";
import { useEditOrderContext } from "../EditOrder";
import { produce } from "immer";

type Props = {};
interface EditDescriptionOrder {
  handleBlurDescriptionTextField: () => void;
  descriptionInput: string;
  setDescriptionInput: React.Dispatch<React.SetStateAction<string>>;
  handleOrderDescription: (value: string) => void;
}
const useEditDescriptionOrder = (props: Props): EditDescriptionOrder => {
  const { updateEditedOrder, editedOrder } = useEditOrderStore(
    (state) => ({
      updateEditedOrder: state.updateEditedOrder,
      editedOrder: state.editedOrder,
    }),
    shallow
  );
  const [descriptionInput, setDescriptionInput] = useState(
    editedOrder?.description ?? ""
  );

  const editOrderContext = useEditOrderContext();
  const { setUpdatedOrder } = editOrderContext
    ? editOrderContext : {setUpdatedOrder: (x: any)=>{}}
  const handleBlurDescriptionTextField = () => {
    if (editedOrder)
      if (descriptionInput) {
        if (descriptionInput !== editedOrder?.description) {
          // patchOrder.mutate({
          //   orderId: editedOrder.orderId,
          //   order: { description: descriptionInput },
          // });
          setUpdatedOrder(
            produce((draft) => {
              if (draft) {
                draft.description = descriptionInput;
              } else
                draft = {
                  description: descriptionInput,
                };
              return draft;
            })
          );
        }
      } else {
        setDescriptionInput(editedOrder.description);
        setUpdatedOrder(
          produce((draft) => {
            if (draft) {
              draft.description = undefined;
            } else
              draft = {
                description: undefined,
              };
            return draft;
          })
        );
      }
  };

  const handleOrderDescription = (value: string) => {
    setUpdatedOrder(
      produce((draft) => {
        if (draft) {
          draft.description = descriptionInput;
        } else
          draft = {
            description: descriptionInput,
          };
        return draft;
      })
    );
    setDescriptionInput(value);
  };
  return {
    handleBlurDescriptionTextField,
    descriptionInput,
    setDescriptionInput,
    handleOrderDescription,
  };
};

export default useEditDescriptionOrder;
