import React, { useState } from "react";
import { IBooking } from "../../../../Models/booking";
import { useIsMutating } from "@tanstack/react-query";
import {
  BookingQueryAPI,
  UpdateBookingQueryParams,
  getBookingMutationKey,
} from "../../../../QueryAPI/bookingQueryAPI";
import useBookingProjectStore from "../../../../store/useBookingProjectStore";
import { shallow } from "zustand/shallow";
import { useDoxleAuthStore } from "../../../../../DoxleGeneralStore/useDoxleAuthStore";
import { useDoxleCurrentContextStore } from "../../../../../DoxleGeneralStore/useDoxleCurrentContext";
import useDoxleNotificationStore from "../../../../../DoxleGeneralStore/useDoxleNotificationStore";
import dayjs from "dayjs";
import { formatTISODate } from "../../../../../Utilities/FunctionUtilities";
import useBookingViewStore from "../../../../store/useBookingViewStore";

type Props = { bookingItem: IBooking };

interface BookingTableRow {
  isUpdatingBookingRow: boolean;
  isDeletingBookingRow: boolean;
  handleClickRow: () => void;
  isHoverRow: boolean;
  setisHoverRow: React.Dispatch<React.SetStateAction<boolean>>;
  handleClickDeleteIcon: (
    e: React.MouseEvent<HTMLImageElement, MouseEvent>
  ) => void;
  handleUpdateStartDate: (value: dayjs.Dayjs | null) => void;
  handleUpdateEndDate: (value: dayjs.Dayjs | null) => void;
  handleSendBookingAlert: (event: React.MouseEvent<HTMLDivElement>) => void;
}
const useBookingTableRow = ({ bookingItem }: Props): BookingTableRow => {
  const [isHoverRow, setisHoverRow] = useState(false);
  const {
    setEdittedBooking,
    setDeletedProjectBooking,
    filterBookingProjectQuery,
  } = useBookingProjectStore(
    (state) => ({
      setEdittedBooking: state.setEdittedBooking,
      setDeletedProjectBooking: state.setDeletedProjectBooking,
      filterBookingProjectQuery: state.filterBookingProjectQuery,
    }),
    shallow
  );
  const setSendBooking = useBookingViewStore(
    (state) => state.setSendBooking,
    shallow
  );

  const { company } = useDoxleCurrentContextStore(
    (state) => ({
      company: state.currentCompany,
    }),
    shallow
  );
  const showNotification = useDoxleNotificationStore(
    (state) => state.showNotification,
    shallow
  );

  const { update } = BookingQueryAPI.useMutateBookingQuery({
    company,
    showNotification,
    filter: filterBookingProjectQuery,
  });

  const handleUpdateStartDate = (value: dayjs.Dayjs | null) => {
    update.mutate({
      bookingId: bookingItem.bookingId,
      updateData: {
        startDate: value ? formatTISODate(value.toDate()) : null,
      },
    });
  };

  const handleUpdateEndDate = (value: dayjs.Dayjs | null) => {
    update.mutate({
      bookingId: bookingItem.bookingId,
      updateData: {
        endDate: value ? formatTISODate(value.toDate()) : null,
      },
    });
  };
  const handleClickRow = () => {
    setEdittedBooking(bookingItem);
  };
  const handleClickDeleteIcon = (
    e: React.MouseEvent<HTMLImageElement, MouseEvent>
  ) => {
    e.stopPropagation();
    e.preventDefault();
    setDeletedProjectBooking(bookingItem);
  };
  const isUpdatingBookingRow =
    useIsMutating({
      mutationKey: getBookingMutationKey("update"),
      predicate: (query) =>
        (query.state.variables as UpdateBookingQueryParams).bookingId ===
        bookingItem.bookingId,
    }) > 0;
  const isDeletingBookingRow =
    useIsMutating({
      mutationKey: getBookingMutationKey("delete"),
      predicate: (query) => query.state.variables === bookingItem.bookingId,
    }) > 0;

  const handleSendBookingAlert = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    event.preventDefault();
    if (event.currentTarget.classList.contains("sms"))
      setSendBooking({
        booking: bookingItem,
        type: "sms",
      });
    else if (event.currentTarget.classList.contains("email"))
      setSendBooking({
        booking: bookingItem,
        type: "email",
      });
  };

  return {
    isUpdatingBookingRow,
    isDeletingBookingRow,
    handleClickRow,
    isHoverRow,
    setisHoverRow,
    handleClickDeleteIcon,
    handleUpdateStartDate,
    handleUpdateEndDate,
    handleSendBookingAlert,
  };
};

export default useBookingTableRow;
