import React, { useMemo } from "react";
import { Order } from "../../../../Models/orders";
import { useDoxleAuthStore } from "../../../../../DoxleGeneralStore/useDoxleAuthStore";
import { shallow } from "zustand/shallow";
import { useDoxleCurrentContextStore } from "../../../../../DoxleGeneralStore/useDoxleCurrentContext";
import OrdersQueryAPI from "../../../../QueryAPI/OrdersQueryAPI";

type Props = {
  orderItem: Order;
};

interface SelectedOrderView {
  orderDetail: Order;
  isFetchingOrderDetail: boolean;
  isSuccessFetchingOrderDetail: boolean;
  isErrorFetchingOrderDetail: boolean;
  refetchOrderDetail: () => void;
  isRefetchingOrderDetail: boolean;
}
const useSelectedOrderView = ({ orderItem }: Props): SelectedOrderView => {
  
  const { company } = useDoxleCurrentContextStore(
    (state) => ({
      company: state.currentCompany,
    }),
    shallow
  );

  const getOrderDetailsQuery = OrdersQueryAPI.useRetrieveOrderDetail({

    company,
    filter: { is_invoice: false },
    orderId: orderItem.orderId,
    enabled: true,
  });

  const orderDetail: Order = useMemo(
    () =>
      getOrderDetailsQuery.isSuccess
        ? getOrderDetailsQuery.data.data
        : orderItem,
    [getOrderDetailsQuery.data, getOrderDetailsQuery.isSuccess, orderItem]
  );
  return {
    orderDetail,
    isFetchingOrderDetail: getOrderDetailsQuery.isLoading,
    isSuccessFetchingOrderDetail: getOrderDetailsQuery.isSuccess,
    isErrorFetchingOrderDetail: getOrderDetailsQuery.isError,
    refetchOrderDetail: () => {
      getOrderDetailsQuery.refetch();
    },
    isRefetchingOrderDetail: getOrderDetailsQuery.isRefetching,
  };
};

export default useSelectedOrderView;
