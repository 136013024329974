import React, { useCallback, useEffect, useMemo } from "react";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import { Contact, ContactCompany } from "../../Models/addressBook";
import { shallow } from "zustand/shallow";
import useDoxleSelectContactDropdown, {
  TSelectContactMode,
} from "./Hooks/useDoxleSelectContactDropdown";
import Popover, { PopoverProps } from "@mui/material/Popover";
import {
  StyledAddContactButton,
  StyledContactListScroller,
  StyledContactModeSwitchWrapper,
  StyledContactPopover,
  StyledSearchContactTextfield,
  StyledSearchContactWrapper,
} from "./StyledDoxleSelectContactDropdown";
import AddContactForm from "./AddContactForm";
import ListLoadingMore from "../../Utilities/Lottie/ListLoadingMore";
import { motion } from "framer-motion";
import { Components, ItemContent, Virtuoso } from "react-virtuoso";
import DoxleEmptyPlaceHolder from "../DoxleEmptyPlaceHolder/DoxleEmptyPlaceHolder";
import ContactItem from "./ContactItem";
import { Error404Banner } from "../DoxleIcons/CommonIcons";
import { EmptyContactListBanner } from "./Icons";
import { PopupState as IPopupState } from "material-ui-popup-state/hooks";
import { useShallow } from "zustand/react/shallow";
//! PLEASE DONT EDIT THIS COMPONENTS BY ANY CHANCE!!!!! ASK PETER FOR THE UPDATE TO ENSURE STABILITY

export type TContactPopoverWithAnchor = {
  //! COMPOSULRY PROPS TO SET ANCHOR FOR THE POPOVER
  popoverAnchor: HTMLElement | null;

  // --- overwrite popover props
  popoverProps?: Omit<PopoverProps, "id" | "open" | "anchorEl" | "onClose">;
};
export type TContactPopoverWithPopoverState = Omit<
  PopoverProps,
  keyof IPopupState
> & {
  popupState: IPopupState; //!COMPULSORY TO USE THE popupState
};

export type TContactPopoverGeneralCustom = {
  // //! COMPOSULRY PROPS TO SET ANCHOR FOR THE POPOVER
  // popoverAnchor: HTMLElement | null;

  //! pass this function in to control closing the popover externally
  onClosePopover?: () => void;

  variants?: TSelectContactMode; //*variant can choose between all kinds of contact, Contact or ContactCompany or both, if choose "both", there will be switch menu appear
  //!<----

  //---- function to select contact, will be passed in onClick function each Contact item
  onSelectContact?: (item: Contact) => void;

  //---- function to select contact, will be passed in onClick function each Contact item
  onSelectContactCompany?: (item: ContactCompany) => void;

  // ---- function passed in to click on the remove item, the remove icon in each item only appear when the item is selected, and the onRemoveContact/onRemoveContactCompany is passed in the component
  onRemoveContact?: (item: Contact) => void;
  onRemoveContactCompany?: (item: ContactCompany) => void;

  // ---- list of selected contact items
  selectedContactItemIds?: string[];

  // ---- list of selected contat company items
  selectedCompanyItemIds?: string[];

  // // --- overwrite popover props
  // popoverProps?: PopoverProps;

  // ---- to close the popover whenever you trigger or select each item, true by default but when pass false, the popover still remains open only when click outside the popover only trigger closing it
  closeOnSelect?: boolean;

  // --- styling of the list container
  containerStyle?: React.CSSProperties;
};
type Props = TContactPopoverWithAnchor & TContactPopoverGeneralCustom;

interface ContactListContext {
  isFetchingCompanyList: boolean;
  isErrorFetchingCompanyList: boolean;
  contactMode: "company" | "contact";
  isErrorFetchingContactList: boolean;
  isFetchingContactList: boolean;
}

const DoxleSelectContactDropdown = ({
  popoverAnchor,
  onClosePopover,
  onSelectContact,
  onSelectContactCompany,
  variants = "both",
  popoverProps,
  selectedContactItemIds,
  selectedCompanyItemIds,
  closeOnSelect = true,
  containerStyle,
  onRemoveContact,
  onRemoveContactCompany,
}: Props) => {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore(
    useShallow((state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
    }))
  );

  const {
    contactMode,
    setContactMode,
    showAddContactForm,
    setShowAddContactForm,
    showContactList,
    setShowContactList,
    companiesList,
    isFetchingCompanyList,
    isErrorFetchingCompanyList,
    searchText,
    setSearchText,
    isFetchingNextPageCompanyList,
    contactList,
    isFetchingContactList,
    isErrorFetchingContactList,
    isFetchingNextPageContactList,
    handleSelectCompany,
    handleSelectContact,
    handleFetchNextPage,
  } = useDoxleSelectContactDropdown({
    variants,
    onClosePopover,
    onSelectContact,
    onSelectContactCompany,
    closeOnSelect,
  });
  const canBeOpen = Boolean(popoverAnchor);
  const id = canBeOpen ? "contact-menu-popper" : undefined;

  //* render list
  const components: Components<ContactCompany | Contact, ContactListContext> =
    useMemo(
      () => ({
        Scroller: React.forwardRef((props, ref) => (
          <StyledContactListScroller
            ref={ref}
            {...props}
            animate={{ y: [10, 0], opacity: [0, 1] }}
          />
        )),
        EmptyPlaceholder: (props) => (
          <DoxleEmptyPlaceHolder
            headTitleText={
              props.context?.isErrorFetchingCompanyList ||
              props.context?.isErrorFetchingContactList
                ? "Something wrong!"
                : `No contact found!`
            }
            subTitleText={
              props.context?.isErrorFetchingCompanyList ||
              props.context?.isErrorFetchingContactList
                ? "Failed to get contacts, we are sorry for this!"
                : "Add more contacts to your list..."
            }
            containerBgColor="transparent"
            headTitleTextStyle={{
              fontSize: "20px",
            }}
            subTitleTextStyle={{
              fontSize: "14px",
            }}
            illustration={
              props.context?.isErrorFetchingCompanyList ||
              props.context?.isErrorFetchingContactList ? (
                <Error404Banner
                  themeColor={doxleThemeColor}
                  containerStyle={{
                    width: "50%",
                    marginBottom: 14,
                  }}
                />
              ) : (
                <EmptyContactListBanner
                  themeColor={doxleThemeColor}
                  containerStyle={{
                    width: "50%",
                    marginBottom: 14,
                  }}
                />
              )
            }
          />
        ),
      }),
      [doxleThemeColor]
    );

  const itemContent: ItemContent<ContactCompany | Contact, ContactListContext> =
    useCallback(
      (_index, item, context) => {
        if (context.contactMode === "company" && "contactCompanyId" in item)
          return (
            <ContactItem
              key={`contractor_${item.contactCompanyId}`}
              company={item}
              selected={Boolean(
                selectedCompanyItemIds &&
                  selectedCompanyItemIds.includes(item.contactCompanyId!)
              )}
              onSelect={handleSelectCompany}
              variants="company"
              onRemoveContactCompany={onRemoveContactCompany}
            />
          );
        else if (context.contactMode === "contact" && "contactId" in item)
          return (
            <ContactItem
              key={`contractor_${item.contactId}`}
              contact={item}
              selected={Boolean(
                selectedContactItemIds &&
                  selectedContactItemIds.includes(item.contactId!)
              )}
              onSelect={handleSelectContact}
              variants="contact"
              onRemoveContact={onRemoveContact}
            />
          );
      },
      [
        selectedContactItemIds,
        selectedCompanyItemIds,
        handleSelectCompany,
        handleSelectContact,
        onRemoveContactCompany,
        onRemoveContact,
      ]
    );

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (popoverAnchor) setShowContactList(true);
      else setShowContactList(false);
    }, 300);

    return () => clearTimeout(timeout);
  }, [popoverAnchor]);
  const listStyle = {
    flex: 1,
    width: "100%",
  };
  return (
    <Popover
      id={id}
      open={canBeOpen}
      anchorEl={popoverAnchor}
      disableScrollLock
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      onClose={() => {
        setSearchText("");
        if (onClosePopover) onClosePopover();
      }}
      sx={{
        "& .MuiPopover-paper": {
          backgroundColor: "transparent",
          overflow: "visible",
          boxShadow: "none",
        },
      }}
      transitionDuration={{ appear: 100, exit: 200 }}
      elevation={4}
      {...popoverProps}
    >
      <StyledContactPopover
        $themeColor={doxleThemeColor}
        style={containerStyle}
      >
        <StyledSearchContactWrapper
          $themeColor={doxleThemeColor}
          $doxleFont={doxleFont}
        >
          {!showAddContactForm ? (
            <StyledSearchContactTextfield
              variant="standard"
              value={searchText}
              // autoFocus
              onChange={(e) => setSearchText(e.target.value)}
              placeholder="Search contact..."
            />
          ) : (
            <span className="form-title">Add Contact</span>
          )}

          <StyledAddContactButton
            onClick={() => {
              setShowAddContactForm((prev) => !prev);
              setSearchText("");
            }}
          >
            {!showAddContactForm ? "Add contact" : "Close"}
          </StyledAddContactButton>
        </StyledSearchContactWrapper>

        {variants === "both" && (
          <StyledContactModeSwitchWrapper
            $themeColor={doxleThemeColor}
            $doxleFont={doxleFont}
          >
            <div
              className="switch-btn"
              style={{ fontWeight: contactMode === "contact" ? 600 : 400 }}
              onClick={() => setContactMode("contact")}
            >
              Contact
              {contactMode === "contact" && (
                <motion.div
                  className="selected-effect"
                  layoutId="contactModeLayoutId"
                />
              )}
            </div>
            <div
              className="switch-btn"
              style={{ fontWeight: contactMode === "company" ? 600 : 400 }}
              onClick={() => setContactMode("company")}
            >
              Company
              {contactMode === "company" && (
                <motion.div
                  className="selected-effect"
                  layoutId="contactModeLayoutId"
                />
              )}
            </div>
          </StyledContactModeSwitchWrapper>
        )}
        {!showAddContactForm && (
          <>
            {showContactList && (
              <Virtuoso
                style={listStyle}
                itemContent={itemContent}
                data={contactMode === "company" ? companiesList : contactList}
                components={components}
                context={{
                  isErrorFetchingCompanyList,
                  isFetchingCompanyList,
                  contactMode,
                  isErrorFetchingContactList,
                  isFetchingContactList,
                }}
                endReached={handleFetchNextPage}
                atBottomThreshold={40}
              />
            )}
          </>
        )}

        {showAddContactForm && (
          <AddContactForm
            handleCloseForm={() => setShowAddContactForm(false)}
            variants={contactMode}
          />
        )}

        {isFetchingNextPageCompanyList ||
          (isFetchingNextPageContactList && (
            <ListLoadingMore
              animationSize={50}
              containerStyle={{
                bottom: 0,
                left: "50%",
                translate: "-50%",
                display: "flex",
                justifyContent: "center",
                position: "absolute",
                zIndex: 10,
              }}
            />
          ))}
      </StyledContactPopover>
    </Popover>
  );
};

export default DoxleSelectContactDropdown;
