import React, { useMemo } from "react";
import PricebookQueryAPI from "../QueryHooks/PricebookQueryAPI";
import { PricebookItem } from "../Models/Pricebook";

type Props = {
  pricebookId: string;
};

interface IGetPricebookDetail {
  pricebookItem: PricebookItem | undefined;
  isFetchingPricebookItem: boolean;
  isErrorFetchingPricebookItem: boolean;
  isSuccessFetchingPricebookItem: boolean;
}
const useGetPricebookDetail = ({ pricebookId }: Props): IGetPricebookDetail => {
  const supplierPricebookItemDetail =
    PricebookQueryAPI.useRetrievePricebookItemDetail(pricebookId);

  const pricebookItem: PricebookItem | undefined = useMemo(() => {
    if (
      supplierPricebookItemDetail.isSuccess &&
      supplierPricebookItemDetail.data?.data
    )
      return supplierPricebookItemDetail.data?.data as PricebookItem;
    return undefined;
  }, [supplierPricebookItemDetail.isSuccess, supplierPricebookItemDetail.data]);
  return {
    pricebookItem,
    isFetchingPricebookItem: supplierPricebookItemDetail.isLoading,
    isErrorFetchingPricebookItem: supplierPricebookItemDetail.isError,
    isSuccessFetchingPricebookItem: supplierPricebookItemDetail.isSuccess,
  };
};

export default useGetPricebookDetail;
